import { Form, Select } from 'antd';
import React from 'react';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import {
  reportParameterLabelNameKeyConfigs,
  ReportParametersLabelNameKeys,
  translations,
} from '../../constants/translations';
import { ReportParameters } from '../../graph/types';
import { getRequiredRule } from '../../util/forms';
import {
  getDataFunctionForParameter,
  getKeysForParameterData,
  getOptionsForReportParameter,
} from './runReportWithParametersUtil';

interface SelectForReportParameterProps {
  reportParameter: ReportParameters;
  organizationId: string;
}

export const SelectForReportParameter: React.FC<SelectForReportParameterProps> = ({
  reportParameter,
  organizationId,
}) => {
  const useDataHook = getDataFunctionForParameter(reportParameter.input_type);
  const [dataKey, dataLoadingKey] = getKeysForParameterData(reportParameter.input_type);

  const data = useDataHook(organizationId);
  const { setSearchTerm } = data;

  return (
    <Form.Item
      initialValue={reportParameter.value}
      rules={[getRequiredRule(translations.runReportModal.field)]}
      name={reportParameter.parameter}
      label={reportParameterLabelNameKeyConfigs[reportParameter?.name_key as ReportParametersLabelNameKeys]}
    >
      <Select
        {...searchableSelectParams}
        onSearch={setSearchTerm}
        loading={data[dataLoadingKey]}
        options={getOptionsForReportParameter(data[dataKey], reportParameter.input_type)}
      />
    </Form.Item>
  );
};
