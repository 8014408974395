import { StageConfig } from './config';

const stageConfig: StageConfig = {
  PUBLIC_API_URL:
    (!process.env.JEST_WORKER_ID && process.env.REACT_APP_PUBLIC_API_URL) || 'https://public-api-staging.software.vet',
  APPSYNC_URL: (!process.env.JEST_WORKER_ID && process.env.REACT_APP_APPSYNC_URL) || 'https://api-staging.software.vet',
  COGNITO_USER_POOL_ID: 'us-east-2_iYmImjxrm',
  COGNITO_APP_CLIENT_ID: '3fbo9b2pfbsjgctagj3cn10hh5',
  LAUNCHDARKLY_CLIEND_ID: '61bbd48cd98d930d9059a0fe',
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51OXRWpJYH9WeaXSNkL3HwI73jXJ0cbKnypjnkncubEuIcZrwJOKf28xJmkAXFfA11d3khwK2dMdNeRPiCniLA4iK002JTjRQU8',
  STRIPE_PUBLISHABLE_KEY_CAD:
    'pk_test_51OeihzCSvt6Fm7nCFkJdrPoTaobmYhCImBECwdRiwjgd53rcwMAwVQNRBnS4W2e3S030pVIFw6xAIr2QwgirHJIa00XEWNlEvC',
  APP_DATADOG_APPLICATION_ID: '9d9bad77-a6e6-4dd2-bfc5-bd13a3e5cdb0',
  APP_DATADOG_CLIENT_TOKEN: 'pub1fc3842e3d84f217fe3f1364936fbf8b',
};

export default stageConfig;
