import React, { useEffect, useState } from 'react';
import { OptionsWithSpeciesNumber } from '../../../components/DependentDropdown/DependentDropdown';
import { TabLoading } from '../../../components/Loading/Loading';
import { translations } from '../../../constants/translations';
import { useGetLabBreedMapping, useGetLabRefBreeds } from '../../../hooks/ajax/lab/labHooks';
import { TaxonomyTabProps, taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { TaxonomyTable } from './TaxonomyTable';

export const TaxonomyBreeds: React.FC<TaxonomyTabProps> = ({ labId, organizationId, autoMapLoading }) => {
  const { labBreedMapping, loading, refetch } = useGetLabBreedMapping(organizationId, labId);
  const {
    labRefBreeds,
    loading: labRefBreedsLoading,
    refetch: refetchLabRefBreeds,
  } = useGetLabRefBreeds(organizationId, labId);

  const [breedOptions, setBreedOptions] = useState<OptionsWithSpeciesNumber[]>([]);

  useEffect(() => {
    refetch({ organizationId, labDeviceId: labId });
    refetchLabRefBreeds({ organizationId, labRefFilter: { lab_device_id: labId } });
  }, [labId, organizationId, refetch, refetchLabRefBreeds]);

  useEffect(() => {
    if (labRefBreeds?.length) {
      setBreedOptions(
        labRefBreeds?.map(({ lab_breed_number, lab_breed_name, lab_species_number }) => ({
          label: lab_breed_name,
          value: lab_breed_number,
          speciesnumber: lab_species_number,
        }))
      );
    }
  }, [labRefBreeds]);

  if (loading || labRefBreedsLoading || autoMapLoading) {
    return <TabLoading labelText={translations.shared.communicatingWithLabMessage} />;
  }

  return (
    <TaxonomyTable
      options={breedOptions}
      dataSource={labBreedMapping ?? []}
      type={TaxonomyType.Breed}
      typeTranslations={taxonomyTranslations.tabs.breeds}
      deviceId={labId}
    />
  );
};
