import Color from 'color';
import React, { CSSProperties, Key } from 'react';
import { TagWithNavigation } from '../components/TagWithNavigation/TagWithNavigation';
import { contactTypeConfigs, ContactTypeNameKey } from '../constants/referenceData/contactReferenceData';
import { routes } from '../constants/routes';
import { TagColor } from '../constants/tagColor';
import { Contact, ContactType, Patient, ServiceRendered3pApproval, UserOrganizationUserType } from '../graph/types';
import { withContactIdParameter } from '../hooks/route/navigationHooks';

interface RelatedContactDisplay {
  contactTypeColor: string;
  contactTypeLabel: string;
  contactName: string;
}

export function getContactTags(contact: Contact) {
  return contact.type_name_keys
    ?.filter((type) => Object.values(ContactTypeNameKey).includes(type as ContactTypeNameKey))
    .map((type) => getContactTypeTag(type as ContactTypeNameKey));
}

export function getCardTags(typeNameKeys: string[] | undefined) {
  return typeNameKeys
    ?.filter((type) => Object.values(ContactTypeNameKey).includes(type as ContactTypeNameKey))
    .map((type) => getContactTypeTag(type as ContactTypeNameKey));
}

export function getContactPatientRelationTags(patient: Patient, contactTypes: ContactType[], contactName: string) {
  return getPatientRelationTags(patient, contactTypes, contactName);
}

export function getPatientRelationTags(
  patient: Patient | ServiceRendered3pApproval,
  contactTypes: ContactType[],
  contactName?: string
) {
  const patientRelationTags: JSX.Element[] = [];

  if (contactName && patient.owner_names?.includes(contactName)) {
    patientRelationTags.push(getContactTypeTag(ContactTypeNameKey.Owner, contactName));
  }
  if (!patient.related_types || !patient.related_names) {
    return patientRelationTags;
  }
  const contactTypesMap: { [typeId: string]: ContactType } = {};
  contactTypes.forEach((contactType) => {
    contactTypesMap[contactType.type_id] = contactType;
  });

  let patientRelationTypeIds: string[] = [];
  if (contactName) {
    patient.related_names.forEach((name, index) => {
      if (name === contactName && patient.related_types?.[index]) {
        patientRelationTypeIds.push(patient.related_types?.[index]);
      }
    });
  } else {
    patientRelationTypeIds = [...patient.related_types];
  }

  patientRelationTypeIds.forEach((type: string, i) => {
    const contactType = contactTypesMap[type];
    if (contactType) {
      patientRelationTags.push(
        getContactTypeTag(
          contactType.name_key as ContactTypeNameKey,
          i,
          contactName ? undefined : patient.related_names?.[i],
          200,
          contactName ? undefined : patient.related_ids?.[i]
        )
      );
    }
  });

  return patientRelationTags;
}

export function getContactTypeTag(
  contactType: ContactTypeNameKey,
  key?: Key,
  label?: string,
  tagWidth?: number,
  navigationId?: string
) {
  const contactTypeConfig = contactTypeConfigs[contactType];
  return getTag(contactTypeConfig.color, label ?? contactTypeConfig.label, key ?? contactType, tagWidth, navigationId);
}

export function getUserTypeTags(userTypes: UserOrganizationUserType[]) {
  return userTypes.map((type) => getUserTypeTag(type));
}

export function getUserTypeTag(userType: UserOrganizationUserType) {
  const tagColors = Object.values(TagColor);
  const userTypeConfig = {
    label: userType.type_name,
    color: tagColors[+userType.type_id % tagColors.length],
  };
  return getTag(userTypeConfig.color, userTypeConfig.label, userType.type_name);
}

export function getTag(color: string, displayText: string, key?: Key, tagWidth?: number, navigationId?: string) {
  const backgroundColor = Color(color).lightness(96).hex();
  const borderColor = Color(color).lightness(80).hex();
  const colorStyle: CSSProperties = { backgroundColor, color, borderColor };
  return (
    <TagWithNavigation
      key={key}
      navigationRoute={navigationId && routes.viewContact}
      navigationParameter={navigationId ? withContactIdParameter(navigationId) : undefined}
      displayText={displayText}
      width={tagWidth}
      style={{ marginBottom: '2px', marginTop: '2px', fontWeight: 'normal', ...colorStyle }}
    />
  );
}

export function getRelatedContactFromArrays(contactNames: string[], typesNameKeys: string[]): RelatedContactDisplay[] {
  if (contactNames.length !== typesNameKeys.length) {
    return [];
  }
  return contactNames
    .map((contactName, index) => ({ contactType: typesNameKeys[index], contactName }))
    .filter(({ contactType }) => Object.values(ContactTypeNameKey).includes(contactType as any))
    .map(({ contactType, contactName }) => ({
      contactTypeColor: contactTypeConfigs[contactType as ContactTypeNameKey].color,
      contactTypeLabel: contactTypeConfigs[contactType as ContactTypeNameKey].label,
      contactName,
    }));
}
