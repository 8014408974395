import { Note, NoteUpsert } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaTypeWithUpsert } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';

export type RxNote = RxSchemaTypeWithUpsert<Partial<Note>, NoteUpsert, 'note'>;

export const noteSchema: RxJsonSchema<RxNote> = {
  version: 6,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    patient_id: RxScalars.ID,
    contact_id: RxScalars.ID,
    type_name: RxScalars.String,
    type_id: RxScalars.ID,
    preview: Maybe(RxScalars.String),
    value: RxScalars.String,
    locked: RxScalars.Boolean,
    hidden: RxScalars.Boolean,
    date: RxScalars.String,
    created: RxScalars.String,
    created_user_name: RxScalars.String,
    updated: RxScalars.String,
    updated_user_name: Maybe(RxScalars.String),
    offline_id: Maybe(RxScalars.String),
    offline_patient_id: Maybe(RxScalars.String),
    offline_contact_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
  },
  required: ['id'],
  indexes: [
    'updated',
    'patient_id',
    'contact_id',
    ['patient_id', 'offline_patient_id', 'hidden'],
    ['contact_id', 'offline_contact_id', 'hidden'],
  ],
};
