import { Phone } from '../../graph/types';
import { ID_FOR_OBJECT_CREATION } from '../../classes/upsertGenerators/commonUpsertConstants';
import { useCallback, useContext } from 'react';
import { phoneTableActions, PhoneTableContext } from './phoneTableState';
import { PhoneTableEvent } from './phoneTableEvent';
import { translations } from '../../constants/translations';
import { ViewSubscriptionContext } from '../ViewSubscription/store/state';
import ViewSubscriptionActions from '../ViewSubscription/store/actions';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const getNewEntryRowForPhoneTable = (firstEntry = false): Phone => ({
  id: ID_FOR_OBJECT_CREATION,
  phone: '',
  description: '',
  extension: '',
  primary: firstEntry,
});

export const useHandlePhoneTableRowEdit = (phone: Phone) => {
  const { dispatch } = useContext(PhoneTableContext);
  const { dispatch: dispatchViewContact } = useContext(ViewSubscriptionContext);

  return useCallback(() => {
    dispatchViewContact(ViewSubscriptionActions.setEditing(true));
    dispatch(phoneTableActions.setCurrentEditing({ isNew: false, phone: { ...phone } }));
  }, [dispatch, dispatchViewContact, phone]);
};

export const validatePhone = (phone: Phone) => {
  const { errors } = translations.phoneTable.inputs;
  if (!phone.phone) {
    PhoneTableEvent.inputError.emit({ inputName: 'phone', message: errors.phone.required });
    return false;
  }

  if (!isPossiblePhoneNumber(phone.phone)) {
    PhoneTableEvent.inputError.emit({ inputName: 'phone', message: errors.phone.invalid });
    return false;
  }

  return true;
};
