import { Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { CreditCard } from '../../../../graph/types';
import { paymentTypeConfigs, PaymentTypeNameKey } from '../../../../constants/referenceData/paymentReferenceData';
import React, { ReactNode } from 'react';

export function getCreditCardTypeTag(creditCard: CreditCard) {
  const paymentTypeConfig = paymentTypeConfigs[creditCard.card_type_name_key as PaymentTypeNameKey];
  if (paymentTypeConfig) {
    return getTag(paymentTypeConfig.color, paymentTypeConfig.text);
  }
  return '';
}

function getTag(color: PresetColorType, displayText: ReactNode) {
  return (
    <Tag style={{ marginBottom: '2px', marginTop: '2px' }} color={color}>
      {displayText}
    </Tag>
  );
}
