import { Core, PdfExport } from '@grapecity/activereports';
import { ActiveReportViewerProps } from '../components/ActiveReportViewer/activeReportViewerUtils';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';
import { getReportWithData } from './reportUtils';
import config from '../config/config';

export type GetActiveReportUtilProps = {
  displayCurrency: (value: number) => string;
  displayDate: (value: string) => string;
};

export const getActiveReport = async ({
  reportName,
  data,
  nestedHeader,
  displayCurrency,
  displayDate,
}: ActiveReportViewerProps & GetActiveReportUtilProps) => {
  const customCode = [
    {
      name: 'DisplayCurrency',
      body: displayCurrency,
    },
    {
      name: 'DisplayDate',
      body: displayDate,
    },
  ];

  const pdfExportSettings: PdfExport.PdfSettings = {
    info: {
      title: translations.reports.info.title,
      author: translations.reports.info.author,
      keywords: 'export, report',
      subject: translations.reports.info.subject,
    },
    pdfVersion: '1.4',
  };

  try {
    Core.setLicenseKey(config.ACTIVEREPORTS_LICENSE_KEY);
    Core.CustomCode.registerFunctions(customCode);
    const reportLoader = new (Core as any).PageReport();
    const report: any = await getReportWithData({ reportName, data, nestedHeader });
    await reportLoader.load(report);
    const doc = await reportLoader.run();
    const result = await PdfExport.exportDocument(doc, pdfExportSettings);
    return result.data;
  } catch (e) {
    showErrorMessage(translations.reports.errorOpeningReport + ': ' + (e as Error).message);
  }

  return null;
};
