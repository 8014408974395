import { StageConfig } from './config';

const stageConfig: StageConfig = {
  PUBLIC_API_URL:
    (!process.env.JEST_WORKER_ID && process.env.REACT_APP_PUBLIC_API_URL) || 'https://public-api-int.software.vet',
  APPSYNC_URL: (!process.env.JEST_WORKER_ID && process.env.REACT_APP_APPSYNC_URL) || 'https://api-int.software.vet',
  COGNITO_USER_POOL_ID: 'us-east-2_UomxFFpzJ',
  COGNITO_APP_CLIENT_ID: '570uscr0v364jkbjul46ktih1t',
  LAUNCHDARKLY_CLIEND_ID: '61bbd47861809914fdb6775d',
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51LxVapDNJ04mZs1PK15b4SQ1AmJNKsFZs5pOWYSMH8IyxaOGVOIaP1CnhcXUH6L6tqX737vTqVSTHsLke3x705VW00EpN2ByBQ',
  STRIPE_PUBLISHABLE_KEY_CAD:
    'pk_test_51LxVapDNJ04mZs1PK15b4SQ1AmJNKsFZs5pOWYSMH8IyxaOGVOIaP1CnhcXUH6L6tqX737vTqVSTHsLke3x705VW00EpN2ByBQ',
  APP_DATADOG_APPLICATION_ID: '9d9bad77-a6e6-4dd2-bfc5-bd13a3e5cdb0',
  APP_DATADOG_CLIENT_TOKEN: 'pub1fc3842e3d84f217fe3f1364936fbf8b',
};

export default stageConfig;
