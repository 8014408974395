import { Form, Input, Select } from 'antd';
import { translations } from '../../constants/translations';
import { getRequiredRule, getValidListOfEmailsRule } from '../../util/forms';
import React from 'react';
import { TextEditor } from '../TextEditor/TextEditor';
import { useGetContactsWithSearch } from '../../hooks/ajax/contact/contactHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getEmailOptions } from '../../pages/Invoices/invoiceReportUtils';
import { uniqBy } from 'lodash';

export const recipientsFieldName = 'recipients';
export const subjectFieldName = 'subject';
export const bodyFieldName = 'body';

interface StandardEmailFormFieldsProps {
  staticContactOptions?: { value: string; label: string }[];
  bodyText: string;
  setBodyText: (text: string) => void;
  attachment?: string;
}

export const StandardEmailFormFields: React.FC<StandardEmailFormFieldsProps> = ({
  staticContactOptions = [],
  bodyText,
  setBodyText,
  attachment,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { contacts, contactsLoading, setSearchTerm } = useGetContactsWithSearch(organizationId);
  const searchContactEmailOptions = getEmailOptions(contacts);
  const contactEmailOptions = uniqBy([...staticContactOptions, ...searchContactEmailOptions], 'value');

  return (
    <>
      <Form.Item
        label={translations.emailModal.fields.to.label}
        name={recipientsFieldName}
        rules={[getRequiredRule(translations.emailModal.fields.to.label), getValidListOfEmailsRule()]}
      >
        <Select
          mode={'tags'}
          options={contactEmailOptions}
          loading={contactsLoading}
          filterOption={false}
          onSearch={setSearchTerm}
          onBlur={() => setSearchTerm('')}
        />
      </Form.Item>
      <Form.Item
        label={translations.emailModal.fields.subject.label}
        name={subjectFieldName}
        rules={[getRequiredRule(translations.emailModal.fields.subject.label)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={translations.emailModal.fields.body.label} name={bodyFieldName}>
        <TextEditor editMode textContent={bodyText} setTextContent={setBodyText} />
      </Form.Item>
      {attachment && <Form.Item label={translations.emailModal.fixedAttachmentLabel}>{attachment}</Form.Item>}
    </>
  );
};
