import gql from 'graphql-tag';
import { ElectronicPaymentResultFields } from '../fragments/paymentGatewayFragments';

export const OnElectronicPaymentStatusChange = gql`
  subscription onElectronicPaymentStatusChange($clientToken: String!) {
    onElectronicPaymentStatusChange(client_token: $clientToken) {
      ...ElectronicPaymentResultFields
    }
  }
  ${ElectronicPaymentResultFields}
`;
