import { Patient } from '../../../graph/types';
import { PatientsAction, PatientsActionType } from './actions';

export interface PatientsState {
  patientsList: Patient[];
  editingIndex: number;
}

export const initialState: PatientsState = { patientsList: [], editingIndex: -1 };

export const patientsReducer = (state: PatientsState, action: PatientsAction): PatientsState => {
  switch (action.type) {
    case PatientsActionType.setPatientsList:
      return {
        ...state,
        patientsList: action.patientsList,
      };
    case PatientsActionType.setCurrentlyEditingIndex:
      return {
        ...state,
        editingIndex: action.editingIndex,
      };
    default:
      return state;
  }
};
