import gql from 'graphql-tag';

export const ReportFields = gql`
  fragment ReportFields on Report {
    id
    name
    category_id
    category_name_key
    description
    file_name
    publish
    sort_order
    runner
    runner_function
  }
`;

export const ReportParameterFields = gql`
  fragment ReportParameterFields on ReportParameters {
    id
    parameter
    input_type
    name_key
    value
    sort_order
  }
`;

const RptPracticeHeaderFields = gql`
  fragment RptPracticeHeaderFields on RptPracticeHeaderInterface {
    organization_id
    practice_id
    practice_name
    practice_address_1
    practice_address_2
    practice_address_3
    practice_city
    practice_provstate
    practice_postal_zip
    practice_logo
    practice_phone
    practice_tax_id
  }
`;

const RptHeaderFields = gql`
  fragment RptHeaderFields on RptHeader {
    organization_id
    practice_id
    practice_name
    practice_address_1
    practice_address_2
    practice_address_3
    practice_city
    practice_provstate
    practice_postal_zip
    practice_logo
    practice_phone
    practice_country
    practice_tax_id
    start_date
    end_date
  }
`;

export const RptProductionDetailFields = gql`
  fragment RptProductionDetailFields on RptProductionDetail {
    doctor_id
    service_id
    doctor_name
    service_name
    billed_quantity
    total_billed
  }
`;

export const ProductionReportFields = gql`
  fragment ProductionReportFields on RptProduction {
    report_header {
      ...RptHeaderFields
    }
    production_details {
      ...RptProductionDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptProductionDetailFields}
`;

export const StatementReportFields = gql`
  fragment StatementReportFields on RptStatement {
    report_header(reportParameters: $reportParameters) {
      ...RptPracticeHeaderFields
      start_date
      end_date
      contact_ids
      footer
    }
    contact_detail(reportParameters: $reportParameters) {
      contact_id
      contact_name
      contact_number
      contact_address_1
      contact_address_2
      contact_address_3
      contact_city
      contact_provstate
      contact_country
      contact_postal_zip
      contact_balance_forward
      contact_balance_ending
    }
    ledger_detail(reportParameters: $reportParameters) {
      contact_id
      ledger_id
      organization_id
      practice_id
      invoice_id
      patient_id
      financial_date
      type_name_key
      payment_type_name_key
      check_number
      ledger_type_name_key
      ledger_number
      ledger_payment_payer
      financial_amount
      reversal
      invoice_number
      percentage
      patient_name
      patient_trainer
      invoice_tax_detail {
        invoice_id
        tax_type_id
        tax_type_code
        total
      }
    }
    service_rendered_detail(reportParameters: $reportParameters) {
      contact_id
      ledger_id
      organization_id
      practice_id
      invoice_id
      patient_id
      service_rendered_date
      service_rendered_created
      doctor_name
      doctor_abbreviation
      service_rendered_name
      service_rendered_quantity
      service_rendered_unit_price
      service_rendered_unit_name
      service_rendered_total
      service_note
    }
  }
  ${RptPracticeHeaderFields}
`;

export const PatientVaccineReportFields = gql`
  fragment PatientVaccineReportFields on RptPatientVaccine {
    report_header {
      ...RptHeaderFields
    }
    patient_vaccine_details {
      patient_number
      patient_name
      patient_species
      patient_breed
      patient_gender
      patient_color
      patient_microchip
      invoice_number
      doctor_name
      quantity
      manufacturer_name
      lot_number
      serial_number
      expiry_date
      service_date
      reminder_due_date
      service_name
    }
    ownership_details {
      contact_name
      percentage
    }
  }
  ${RptHeaderFields}
`;

export const RptInvoiceDetailFields = gql`
  fragment RptInvoiceDetailFields on RptInvoiceDetail {
    invoice_id
    patient_id
    doctor_id
    invoice_number
    patient_name
    service_rendered_created
    service_rendered_date
    doctor
    service_rendered_name
    service_rendered_quantity
    service_rendered_unit_price
    service_rendered_unit_name
    service_rendered_total
    service_rendered_list_unit_price
    service_rendered_list_total
    service_rendered_list_discount
    service_rendered_list_discount_percent
    service_note
    service_sort_order
    patient_trainer
    hide_discount
    footer
  }
`;

export const RptCommunicationLogDetailFields = gql`
  fragment RptCommunicationLogDetailFields on RptCommunicationLogDetail {
    communication_log_id
    description
    recipients
    cc
    bcc
    subject
    body
    failed
    error_message
    created
    message_sent
    contact_name
    patient_name
  }
`;

export const RptControlledDrugLogDetailFields = gql`
  fragment RptControlledDrugLogDetailFields on RptControlledDrugLogDetail {
    service_rendered_id
    service_id
    patient_id
    invoice_id
    patient_number
    patient_name
    invoice_number
    doctor_name
    service_name
    quantity
    unit_name
    bottle_number
    date
    primary_owner_name
    contact_address_1
    contact_address_2
    contact_address_3
    contact_city
    contact_provstate
    contact_country
    contact_postal_zip
  }
`;

export const RptInvoiceActivityInvoiceDetailFields = gql`
  fragment RptInvoiceActivityInvoiceDetailFields on RptInvoiceActivityInvoiceDetail {
    invoice_id
    invoice_number
    invoice_date
    patient_name
    service_rendered
    service_date
    doctor_name
    quantity
    list_unit_price
    unit_name
    service_total
    invoice_total
    total_no_tax
    total_tax
    patient_trainer
    service_created
    service_sort_order
  }
`;

export const RptGeneralLedgerDetailFields = gql`
  fragment RptGeneralLedgerDetailFields on RptGeneralLedgerDetail {
    doctor_id
    service_id
    doctor_name
    service_name
    billed_qty
    total_billed
    general_ledger_name
    general_ledger_number
  }
`;

export const RptInvoiceActivityInvoiceOwnershipDetailFields = gql`
  fragment RptInvoiceActivityInvoiceOwnershipDetailFields on RptInvoiceActivityInvoiceOwnershipDetail {
    invoice_id
    name
    percentage
  }
`;

export const RptLedgerEntryDetailFields = gql`
  fragment RptLedgerEntryDetailFields on RptLedgerEntryDetail {
    ledger_id
    contact_id
    type_id
    financial_date
    ledger_number
    contact_number
    contact_name
    ledger_type_name
    total_no_tax
    total_tax
    total
    ledger_note
    financial_amount
    reversal
  }
`;

export const RptPaymentsDetailFields = gql`
  fragment RptPaymentsDetailFields on RptPaymentsDetail {
    ledger_id
    financial_date
    created_user_name
    contact_name
    contact_number
    total
    payment_type
    reversal
    check_number
    card_display_number
    ledger_payer
    ledger_note
  }
`;

export const RptSubscriptionDetailFields = gql`
  fragment RptSubscriptionDetailFields on RptSubscriptionDetail {
    organization_id
    organization
    start_date
    user_count
    owner
    email
    last_billed_date
    next_bill_date
    email_setup
    card_connect_setup
  }
`;

export const RptTaxDetailFields = gql`
  fragment RptTaxDetailFields on RptTaxDetail {
    invoice_id
    tax_type_id
    taxable_amount
    tax_name
    code
    percentage
    financial_date
    invoice_number
    contact_id
    primary_contact
    total_tax
  }
`;

export const RptVaccineActivityDetailFields = gql`
  fragment RptVaccineActivityDetailFields on RptVaccineActivityDetail {
    service_rendered_id
    service_id
    patient_id
    invoice_id
    patient_number
    patient_name
    invoice_number
    doctor_name
    service_name
    quantity
    manufacturer_name
    lot_number
    serial_number
    expiry_date
    date
  }
`;

export const RptARDetailFields = gql`
  fragment RptARDetailFields on RptARDetail {
    contact_id
    contact_name
    contact_number
    contact_status_name
    bucket0
    bucket31
    bucket61
    bucket91
    bucket121
    total
    effective_date
    last_payment_date
  }
`;

export const RptSubscriptionPaymentsDetailFields = gql`
  fragment RptSubscriptionPaymentsDetailFields on RptSubscriptionPaymentsDetail {
    organization_id
    practice_name
    payment_id
    payment_date
    description
    quantity
    unit_price
    total
    amount
    currency_id
    display_number
    name_on_card
    start_date
    end_date
  }
`;

export const RptReminderByContactDetailFields = gql`
  fragment RptReminderByContactDetailFields on RptReminderByContactDetail {
    patient_id
    status_id
    reminder_status_name
    contact_name
    patient_name
    reminder_name
    reminder_due_date
    reminder_note
  }
`;

export const RptPatientProcedureByContactDetailFields = gql`
  fragment RptPatientProcedureByContactDetailFields on RptPatientProcedureByContactDetail {
    service_rendered_id
    service_id
    patient_id
    invoice_id
    patient_number
    patient_name
    invoice_number
    doctor_name
    service_name
    quantity
    service_date
    contact_name
    contact_type
  }
`;

export const RptPatientProcedureDetailFields = gql`
  fragment RptPatientProcedureDetailFields on RptPatientProcedureDetail {
    service_rendered_id
    service_id
    patient_id
    invoice_id
    patient_number
    patient_name
    invoice_number
    doctor_name
    service_name
    quantity
    service_date
    contact_name
    contact_type
  }
`;

export const RptNewContactsAndPatientsDetailFields = gql`
  fragment RptNewContactsAndPatientsDetailFields on RptNewContactsAndPatientsDetail {
    record_type
    patient_id
    patient_name
    patient_name_2
    patient_name_3
    species_name
    breed_name
    dob
    primary_owner_name
    deceased
    deceased_date
    inactive
    patient_created
    contact_id
    number
    contact_name
    contact_status_name
    contact_number
    email
    contact_created
    send_type
    statement_ignore
    interest_ignore
  }
`;

export const RptInvoiceContactDetailFields = gql`
  fragment RptInvoiceContactDetailFields on RptInvoiceContactDetail {
    contact_name
    contact_number
    contact_percentage
    contact_address_1
    contact_address_2
    contact_address_3
    contact_city
    contact_provstate
    contact_country
    contact_postal_zip
    invoice_number
    invoice_date
    ledger_total_no_tax
    ledger_total_tax
    ledger_total
  }
`;

export const RptContactPaymentDetailFields = gql`
  fragment RptContactPaymentDetailFields on RptContactPaymentDetail {
    contact_name
    ledger_number
    payment_type_name
    total
    financial_date
    total_reversed
    total_assigned
    card_display_number
    check_number
    payer
    note
  }
`;

export const RptVaccineByContactDetailFields = gql`
  fragment RptVaccineByContactDetailFields on RptVaccineByContactDetail {
    service_rendered_id
    service_id
    patient_id
    invoice_id
    patient_number
    patient_name
    invoice_number
    doctor_name
    service_name
    quantity
    manufacturer_name
    lot_number
    serial_number
    expiry_date
    contact_name
    contact_type
    date
  }
`;

export const RptTopSalesDetailFields = gql`
  fragment RptTopSalesDetailFields on RptTopSalesDetail {
    contact_name
    total_not_tax
    total_tax
    total
  }
`;

export const InvoiceReportFields = gql`
  fragment InvoiceReportFields on RptInvoice {
    report_header {
      ...RptHeaderFields
    }
    invoice_details {
      ...RptInvoiceDetailFields
    }
    contact_details {
      ...RptInvoiceContactDetailFields
    }
    split_details {
      invoice_id
      invoice_number
      contact_name
      contact_percentage
    }
    tax_details {
      invoice_number
      invoice_date
      ledger_total_no_tax
      ledger_total_tax
      ledger_total
      tax_name
      tax_name_total
    }
  }
  ${RptHeaderFields}
  ${RptInvoiceDetailFields}
  ${RptInvoiceContactDetailFields}
`;

export const CommunicationLogReportFields = gql`
  fragment CommunicationLogReportFields on RptCommunicationLog {
    report_header {
      ...RptHeaderFields
    }
    communication_log_details {
      ...RptCommunicationLogDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptCommunicationLogDetailFields}
`;

export const ControlledDrugLogReportFields = gql`
  fragment ControlledDrugLogReportFields on RptControlledDrugLog {
    report_header {
      ...RptHeaderFields
    }
    controlled_drug_log_details {
      ...RptControlledDrugLogDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptControlledDrugLogDetailFields}
`;

export const DailyInvoiceActivityReportFields = gql`
  fragment DailyInvoiceActivityReportFields on RptDailyInvoiceActivity {
    report_header(reportParameters: $reportParameters) {
      ...RptHeaderFields
    }
    daily_invoice_activity_details(reportParameters: $reportParameters) {
      ...RptInvoiceActivityInvoiceDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptInvoiceActivityInvoiceDetailFields}
`;

export const GeneralLedgerReportFields = gql`
  fragment GeneralLedgerReportFields on RptGeneralLedger {
    report_header {
      ...RptHeaderFields
    }
    general_ledger_details {
      ...RptGeneralLedgerDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptGeneralLedgerDetailFields}
`;

export const InvoiceActivityReportFields = gql`
  fragment InvoiceActivityReportFields on RptInvoiceActivity {
    report_header {
      ...RptHeaderFields
    }
    invoice_details {
      ...RptInvoiceActivityInvoiceDetailFields
    }
    invoice_ownership_details {
      ...RptInvoiceActivityInvoiceOwnershipDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptInvoiceActivityInvoiceDetailFields}
  ${RptInvoiceActivityInvoiceOwnershipDetailFields}
`;

export const InvoiceActivityByTrainerReportFields = gql`
  fragment InvoiceActivityByTrainerReportFields on RptInvoiceActivityByTrainer {
    report_header {
      ...RptHeaderFields
    }
    invoice_activity_by_trainer_details {
      ...RptInvoiceActivityInvoiceDetailFields
    }
    invoice_ownership_details {
      ...RptInvoiceActivityInvoiceOwnershipDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptInvoiceActivityInvoiceDetailFields}
  ${RptInvoiceActivityInvoiceOwnershipDetailFields}
`;

export const LedgerEntryReportFields = gql`
  fragment LedgerEntryReportFields on RptLedgerEntry {
    report_header {
      ...RptHeaderFields
    }
    ledger_details {
      ...RptLedgerEntryDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptLedgerEntryDetailFields}
`;

export const PaymentsReportFields = gql`
  fragment PaymentsReportFields on RptPayments {
    report_header {
      ...RptHeaderFields
    }
    payments_details {
      ...RptPaymentsDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptPaymentsDetailFields}
`;

export const SubscriptionsReportFields = gql`
  fragment SubscriptionsReportFields on RptSubscriptions {
    report_header {
      ...RptHeaderFields
    }
    subscription_details {
      ...RptSubscriptionDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptSubscriptionDetailFields}
`;

export const TaxReportFields = gql`
  fragment TaxReportFields on RptTax {
    report_header {
      ...RptHeaderFields
    }
    tax_details {
      ...RptTaxDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptTaxDetailFields}
`;

export const VaccineActivityReportFields = gql`
  fragment VaccineActivityReportFields on RptVaccineActivity {
    report_header {
      ...RptHeaderFields
    }
    vaccine_activity_details {
      ...RptVaccineActivityDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptVaccineActivityDetailFields}
`;

export const ARReportFields = gql`
  fragment ARReportFields on RptAR {
    report_header {
      ...RptHeaderFields
    }
    ar_details {
      ...RptARDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptARDetailFields}
`;

export const ContactPaymentDetailReportFields = gql`
  fragment ContactPaymentDetailReportFields on RptContactPayment {
    report_header {
      ...RptHeaderFields
    }
    contact_payment_details {
      ...RptContactPaymentDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptContactPaymentDetailFields}
`;

export const SubscriptionPaymentsReportFields = gql`
  fragment SubscriptionPaymentsReportFields on RptSubscriptionPayments {
    subscription_payments_details {
      ...RptSubscriptionPaymentsDetailFields
    }
  }
  ${RptSubscriptionPaymentsDetailFields}
`;

export const RemindersByContactReportFields = gql`
  fragment RemindersByContactReportFields on RptReminderByContact {
    report_header {
      ...RptHeaderFields
    }
    reminder_by_contact_details {
      ...RptReminderByContactDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptReminderByContactDetailFields}
`;

export const PatientProcedureByContactReportFields = gql`
  fragment PatientProcedureByContactReportFields on RptPatientProcedureByContact {
    report_header {
      ...RptHeaderFields
    }
    patient_procedure_by_contact_details {
      ...RptPatientProcedureByContactDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptPatientProcedureByContactDetailFields}
`;

export const PatientProcedureReportFields = gql`
  fragment PatientProcedureReportFields on RptPatientProcedure {
    report_header {
      ...RptHeaderFields
    }
    patient_procedure_details {
      ...RptPatientProcedureDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptPatientProcedureDetailFields}
`;

export const NewContactsAndPatientsReportFields = gql`
  fragment NewContactsAndPatientsReportFields on RptNewContactsAndPatients {
    report_header {
      ...RptHeaderFields
    }
    new_contacts_and_patients_details {
      ...RptNewContactsAndPatientsDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptNewContactsAndPatientsDetailFields}
`;

export const VaccineByContactReportFields = gql`
  fragment VaccineByContactReportFields on RptVaccineByContact {
    report_header {
      ...RptHeaderFields
    }
    vaccine_by_contact_details {
      ...RptVaccineByContactDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptVaccineByContactDetailFields}
`;

export const RptTopSalesFields = gql`
  fragment RptTopSalesFields on RptTopSales {
    report_header {
      ...RptHeaderFields
    }
    top_sales_details {
      ...RptTopSalesDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptTopSalesDetailFields}
`;

export const RptSubscriptionInvoiceDetailFields = gql`
  fragment RptSubscriptionInvoiceDetailFields on RptSubscriptionInvoiceDetail {
    payment_id
    invoice_date
    description
    quantity
    unit_price
    total
    amount
    currency_id
    display_number
    name_on_card
  }
`;

export const RptSubscriptionsInvoiceFields = gql`
  fragment RptSubscriptionsInvoiceFields on RptSubscriptionsInvoice {
    report_header {
      ...RptHeaderFields
    }
    subscription_invoice_details {
      ...RptSubscriptionInvoiceDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptSubscriptionInvoiceDetailFields}
`;

export const PatientHistoryReportFields = gql`
  fragment PatientHistoryReportFields on RptPatientHistory {
    report_header {
      ...RptHeaderFields
    }
    patient_details {
      patient_id
      organization_id
      patient_number
      patient_name
      patient_dob
      patient_species
      patient_breed
      patient_gender
      patient_color
    }
    history_details {
      history_type
      organization_id
      practice_id
      patient_id
      date
      service_name
      service_quantity
      service_unit
      service_note
      service_doctor
      note_type
      note_value
      file_type
      file_name
      file_description
    }
    ownership_details(reportParameters: $reportParameters) {
      patient_id
      contact_id
      contact_name
      percentage
      effective_date
      end_date
      syndicate_id
    }
  }
  ${RptHeaderFields}
`;

export const RptContactReceiptDetailsFields = gql`
  fragment RptContactReceiptDetailsFields on RptContactReceiptDetail {
    ledger_id
    ledger_number
    contact_id
    contact_number
    contact_name
    financial_date
    total
    card_display_number
    check_number
    ledger_type_name
    payment_type_name
    payment_payer
    ledger_note
    contact_address_1
    contact_address_2
    contact_address_3
    contact_city
    contact_stateprov
    contact_postal_zip
  }
`;

export const ContactReceiptFields = gql`
  fragment ContactReceiptFields on RptContactReceipt {
    report_header {
      ...RptHeaderFields
    }
    contact_receipt_details {
      ...RptContactReceiptDetailsFields
    }
  }
  ${RptHeaderFields}
  ${RptContactReceiptDetailsFields}
`;

export const RptReminderLetterDetailFields = gql`
  fragment RptReminderLetterDetailFields on RptReminderLetterDetail {
    reminder_id
    patient_id
    service_rendered_id
    contact_id
    patient_name
    contact_name
    reminder
    due_date
    note
  }
`;

export const RptReminderLetterFields = gql`
  fragment RptReminderLetterFields on RptReminderLetter {
    report_header {
      ...RptHeaderFields
    }
    reminder_letter_details {
      ...RptReminderLetterDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptReminderLetterDetailFields}
`;

export const RptDepositDetailFields = gql`
  fragment RptDepositDetailFields on RptDepositDetail {
    deposit_run_id
    deposit_run_number
    deposit_run_begin_date
    deposit_run_end_date
    deposit_run_send_date
    payment_date
    ledger_number
    financial_amount
    contact_name
    payment_type
  }
`;

export const RptDepositFields = gql`
  fragment RptDepositFields on RptDeposit {
    report_header {
      ...RptHeaderFields
    }
    deposit_details {
      ...RptDepositDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptDepositDetailFields}
`;

export const RptJournalEntryDetailFields = gql`
  fragment RptJournalEntryDetailFields on RptJournalEntryDetail {
    journal_id
    journal_date
    journal_note
    send_status_description
    sent_date
    journal_number
    line_number
    description
    general_ledger_name
    general_ledger_number
    amount
  }
`;

export const RptGetJournalEntryFields = gql`
  fragment RptGetJournalEntryFields on RptJournalEntry {
    report_header {
      ...RptHeaderFields
    }
    journal_details {
      ...RptJournalEntryDetailFields
    }
  }
  ${RptHeaderFields}
  ${RptJournalEntryDetailFields}
`;
