import React, { useEffect, useState } from 'react';
import { PaymentModalFormFields } from '../PaymentForm/PaymentForm';
import { Button } from 'antd';
import { translations } from '../../../../constants/translations';
import { Contact, LedgerUpsert, PaymentStatusUpdate } from '../../../../graph/types';
import {
  useUpsertCardConnectTransaction,
  useUpsertElectronicPaymentResult,
} from '../../../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { showErrorMessage, showSuccessMessage } from '../../../Notification/notificationUtil';
import { ModalContentAndFooter } from '../ModalContentAndFooter';
import { CreditCardSwipeWaitingStatus } from './CreditCardSwipeWaitingStatus';
import { PaymentSummary } from '../PaymentSummary/PaymentSummary';
import { getSubscriptionContactRecordUpsert, getCardTransactionRecordUpsert } from '../creditCardMappingUtil';

interface CreditCardSwipeProps {
  organizationId: string;
  practiceId: string;
  formValues: PaymentModalFormFields;
  contact: Contact;
  goBack: () => void;
  closeModal: () => void;
  payment: LedgerUpsert;
  clientRequestToken: string;
  setClientRequestToken: (clientRequestToken: string) => void;
  onSuccess?: () => void;
}

export const CreditCardSwipe: React.FC<CreditCardSwipeProps> = ({
  organizationId,
  practiceId,
  formValues,
  contact,
  goBack,
  closeModal,
  payment,
  clientRequestToken,
  setClientRequestToken,
  onSuccess,
}) => {
  const [upsertCardConnectTransaction] = useUpsertCardConnectTransaction(organizationId, contact.id);
  const [upsertElectronicPaymentResult] = useUpsertElectronicPaymentResult();
  const [loadingMessage, setLoadingMessage] = useState<string>(translations.paymentModal.swiping.waiting);

  useEffect(() => {
    const startSwipe = async () => {
      try {
        const transactionUpsert = getCardTransactionRecordUpsert(formValues, 'EMV');
        const contactUpsert = getSubscriptionContactRecordUpsert(contact);

        await upsertCardConnectTransaction({
          variables: {
            organizationId,
            practiceId,
            transaction: transactionUpsert,
            contact: contactUpsert,
            clientToken: clientRequestToken,
            payment,
          },
        });
      } catch (err) {
        if (err?.message !== 'Execution timed out.') {
          showErrorMessage(err.message ? err.message : err);
        }
      }
    };

    if (clientRequestToken) {
      startSwipe();
    }
  }, [clientRequestToken, contact, formValues, organizationId, practiceId, payment, upsertCardConnectTransaction]);

  const onCancel = () => {
    if (clientRequestToken) {
      cancelTransaction();
    } else {
      goBack();
    }
  };

  const cancelTransaction = () => {
    setLoadingMessage(translations.paymentModal.swiping.cancelling);
    upsertElectronicPaymentResult({
      variables: {
        organizationId,
        practiceId,
        clientToken: clientRequestToken,
        status: PaymentStatusUpdate.Cancel,
      },
    });
  };

  const onComplete = () => {
    showSuccessMessage(translations.paymentModal.swiping.completeSuccess);
    onSuccess?.();
    closeModal();
  };

  const onError = (errorMessage?: string) => {
    showErrorMessage(errorMessage || translations.shared.generalErrorMessage);
    goBack();
  };

  return (
    <ModalContentAndFooter footer={<Button onClick={onCancel}>{translations.shared.cancelButtonText}</Button>}>
      <PaymentSummary formValues={formValues} />
      {clientRequestToken && (
        <CreditCardSwipeWaitingStatus
          setClientRequestToken={setClientRequestToken}
          clientRequestToken={clientRequestToken}
          onComplete={onComplete}
          onError={onError}
          loadingMessage={loadingMessage}
        />
      )}
    </ModalContentAndFooter>
  );
};
