import React, { PropsWithChildren } from 'react';
import { NotePreview } from '../../../graph/types';
import { RecordItem } from '../../Records/recordUtils';
import { useGetNote } from '../../../hooks/ajax/note/noteHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { Loading } from '../../Loading/Loading';
import { translations } from '../../../constants/translations';
import { RecordSingleViewNote } from './RecordSingleViewNote';
import { useHighlightedText } from '../../../hooks/useHighlightedText';

interface RecordSingleViewNoteLoaderProps extends PropsWithChildren<unknown> {
  record?: RecordItem;
  recordId: string;
  notePreview: NotePreview;
  linkedIcon?: React.ReactNode;
  onClose: () => void;
  contactId?: string;
  patientId?: string;
  setCurrentRecords: (setter: (currentRecords: RecordItem[]) => RecordItem[]) => void;
  setEditing?: (editing: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  isFullscreenMode?: boolean;
}

export const RecordSingleViewNoteLoader: React.FC<RecordSingleViewNoteLoaderProps> = ({
  record,
  recordId,
  notePreview,
  linkedIcon,
  onClose,
  contactId,
  patientId,
  setCurrentRecords,
  setEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  isFullscreenMode,
}: RecordSingleViewNoteLoaderProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { note, noteLoading } = useGetNote({
    organizationId,
    noteId: notePreview.id,
    options: { nextFetchPolicy: 'cache-only' },
  });
  useHighlightedText([noteLoading], '.ql-editor');

  if (noteLoading) {
    if (isFullscreenMode) {
      return null;
    }
    return <Loading />;
  }

  return !note ? (
    <p>{translations.shared.errorLoadingPage}</p>
  ) : (
    <RecordSingleViewNote
      record={record}
      recordId={recordId}
      note={note}
      onClose={onClose}
      setCurrentRecords={setCurrentRecords}
      patientId={patientId}
      contactId={contactId}
      setEditing={setEditing}
      linkedIcon={linkedIcon}
      setShouldResetOnTabChange={setShouldResetOnTabChange}
      shouldResetOnTabChange={shouldResetOnTabChange}
      fullScreenMode={!!isFullscreenMode}
    />
  );
};
