import React from 'react';

interface ModalContentAndFooterProps {
  footer?: React.ReactNode;
}

export const ModalContentAndFooter: React.FC<React.PropsWithChildren<ModalContentAndFooterProps>> = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: '24px' }}>{props.children}</div>
      <div className={'ant-modal-footer'}>{props.footer}</div>
    </div>
  );
};
