import React, { useContext, useEffect, useMemo, useState } from 'react';
import { translations } from '../../../constants/translations';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import { Button, Col, Form, Row, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ServiceForm, ServiceType, ServiceUpsertFormFields } from '../ServiceForm/ServiceForm';
import { Loading } from '../../../components/Loading/Loading';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { useGetService, useGetServiceText, useUpsertService } from '../../../hooks/ajax/service/serviceHooks';
import { SaveAndResetButton } from '../../../components/SaveAndResetButton/SaveAndResetButton';
import { isEqual, isMatch } from '../../../util/objectComparisons';
import { ServiceUpsertGenerator } from '../../../classes/upsertGenerators/ServiceUpsertGenerator';
import { ComponentWithPracticeProps } from '../../../components/WithPractice/WithPractice';
import { findServiceTextIds, formatServiceText, generateTaxUpsert } from '../serviceUtils';
import { routes } from '../../../constants/routes';
import { useGetOrganizationIdFromRoute, useGetServiceIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { InfoTextUpsert, Service } from '../../../graph/types';
import TabWrapper, { TabWrapperProps } from '../../../components/TabWrapper/TabWrapper';
import { NoteField, ServiceNote } from '../ServiceNote';
import { useGetNoteRefData } from '../../../components/RecordSingleView/Notes/noteUtils';
import { getRefDataWithVoid } from '../../../util/refDataUtil';
import { ButtonWithPopconfirm } from '../../../components/ButtonWithPopconfirm/ButtonWithPopconfirm';
import { ServiceBundleTab } from '../ServiceBundle/ServiceBundleTab';
import { ServicesContext } from '../store/state';
import { getFormattedDecimalString } from '../../../util/displaying';
import { ActiveFormItem } from '../ServiceForm/ServiceForm.style';
import { LabLink } from '../LabLink/LabLink';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { ServiceThirdPartyId } from '../../../constants/referenceData/serviceReferenceData';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { upsertDateFormat } from '../../../constants/formats';
import { setBundlePromptActive } from '../store/actions';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export type NoteState = { text: string; plaintext: string };
export type VaccinationState = { manufacturer: string; lotNumber: string; serialNumber: string; expiryDate: Date };

export const ViewService: React.FC<ComponentWithPracticeProps> = ({ practice }) => {
  const serviceId = useGetServiceIdFromRoute();
  const { navigateTo } = useNavigationToRoute();
  const [form] = Form.useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.ServiceReferenceDataAndAddOn
  );
  const { service, serviceLoading } = useGetService(serviceId, organizationId);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);
  const [note, setNote] = useState<NoteState>({ text: '', plaintext: '' });
  const [prescriptionNote, setPrescriptionNote] = useState<NoteState>({ text: '', plaintext: '' });
  const [tabKey, setTabKey] = useState<string>('0');
  const [defaultNoteChecked, setDefaultNoteChecked] = useState<boolean>(false);
  const [defaultPrescriptionInstructions, setDefaultPrescriptionInstructions] = useState<boolean>(false);
  const [vaccination, setVaccination] = useState<VaccinationState>({
    manufacturer: '',
    lotNumber: '',
    serialNumber: '',
    expiryDate: new Date(),
  });
  const { noteRefData } = useGetNoteRefData(true);
  const {
    textTypeId: serviceTextId,
    prescriptionTypeId: servicePrescriptionId,
    intfoTypeId: serviceInfoTypeId,
    vaccinationTypeId: serviceVaccinationTypeId,
  } = useMemo(() => findServiceTextIds(service?.text ?? []), [service]);
  const [upsertService] = useUpsertService(organizationId);
  const { serviceText } = useGetServiceText(serviceTextId, organizationId);
  const { serviceText: prescriptionServiceText } = useGetServiceText(servicePrescriptionId, organizationId);
  const {
    serviceText: infoServiceText,
    serviceTextLoading,
    refetchServiceText,
  } = useGetServiceText(serviceInfoTypeId, organizationId);
  const { serviceText: serviceVaccinationText } = useGetServiceText(serviceVaccinationTypeId, organizationId, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const {
    textTypeId: orgTextTypeId,
    prescriptionTypeId: orgPrescriptionTypeId,
    intfoTypeId: orgInfoTypeId,
    vaccinationTypeId: orgVaccinationTypeId,
  } = useMemo(() => findServiceTextIds(organization?.ref_service?.info_type ?? []), [organization]);
  const enabledLabLink = useLDFlag(LDFlagNames.LabLink);

  const { state, dispatch } = useContext(ServicesContext);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  useEffect(() => {
    dispatch(setBundlePromptActive(service?.bundle_prompt || false));
  }, [dispatch, service]);

  const serviceNote = useMemo(() => {
    if (serviceText) {
      try {
        return JSON.parse(serviceText.value || '{}');
      } catch {
        return {};
      }
    }
    return undefined;
  }, [serviceText]);

  const initialTaxSelections = service?.tax?.map((tax) => tax.tax_type_id);
  const serviceReminder = service?.reminder?.[0];
  const initialData = useMemo(() => {
    if (!service) {
      return {
        tax_ids: initialTaxSelections,
      };
    }

    return {
      ...service,
      price: getFormattedDecimalString(service.price),
      material_cost: getFormattedDecimalString(service.material_cost ?? ''),
      type: getType(service),
      active: !service.inactive,
      measurement: service.unit_name,
      tax_ids: initialTaxSelections,
      generalLedgerId: service.general_ledger_id,
      default: !!serviceTextId,
      defaultPrescription: !!servicePrescriptionId,
      reminder: !!serviceReminder,
      duration: serviceReminder?.duration ?? 365,
      quantity_default: service.quantity_default === '0' ? null : service.quantity_default,
      enable_emr: !!service.third_party?.find(({ third_party_id }) => third_party_id === ServiceThirdPartyId.EMR),
      info: infoServiceText?.value,
      hisa_category: service.third_party?.find(({ third_party_id }) => third_party_id === ServiceThirdPartyId.HISA)
        ?.ref_data_id,
    };
  }, [service, infoServiceText, initialTaxSelections, servicePrescriptionId, serviceReminder, serviceTextId]);

  const prescriptionServiceNote = useMemo(() => {
    if (prescriptionServiceText) {
      try {
        return JSON.parse(prescriptionServiceText.value || '{}');
      } catch {
        return {};
      }
    }
    return undefined;
  }, [prescriptionServiceText]);

  const serviceVaccination = useMemo(() => {
    if (serviceVaccinationText) {
      try {
        const serviceVaccinationTextValue = JSON.parse(serviceVaccinationText.value || '{ vaccinationDefaults: {} }');
        return serviceVaccinationTextValue.vaccinationDefaults;
      } catch {
        return { vaccinationDefaults: {} };
      }
    }
    return undefined;
  }, [serviceVaccinationText]);

  const refDataWithVoid = useMemo(() => {
    if (serviceNote?.noteTypeName) {
      return getRefDataWithVoid(
        { id: serviceNote.noteTypeId, name: serviceNote.noteTypeName, sort_order: 0 },
        noteRefData
      );
    }
    return noteRefData;
  }, [noteRefData, serviceNote]);

  useEffect(() => {
    if (serviceNote) {
      setNote({ text: serviceNote.noteText, plaintext: serviceNote.notePreview });
      if (serviceNote.noteTypeId) {
        form.setFieldsValue({ type_id: serviceNote.noteTypeId });
        form.setFieldsValue({ default: true });
        setDefaultNoteChecked(true);
      }
    }

    if (prescriptionServiceNote) {
      form.setFieldsValue({ defaultPrescription: true });
      setDefaultPrescriptionInstructions(true);
      setPrescriptionNote({ text: prescriptionServiceNote.noteText, plaintext: prescriptionServiceNote.plaintext });
    }
  }, [serviceNote, prescriptionServiceNote, form]);

  useEffect(() => {
    if (serviceVaccination) {
      setVaccination({
        manufacturer: serviceVaccination.manufacturer,
        lotNumber: serviceVaccination.lot_number,
        serialNumber: serviceVaccination.serial,
        expiryDate: serviceVaccination.expiry_date,
      });
      form.setFieldsValue({
        manufacturer: serviceVaccination.manufacturer,
        lotNumber: serviceVaccination.lot_number,
        serialNumber: serviceVaccination.serial,
        expiryDate: serviceVaccination.expiry_date ? dayjs(serviceVaccination.expiry_date) : null,
      });
    }
  }, [serviceVaccination, form]);

  useEffect(() => {
    if (service && infoServiceText) {
      refetchServiceText();
    }
  }, [service, infoServiceText, refetchServiceText]);

  if (organizationLoading || serviceLoading) {
    return <Loading />;
  }

  if (!service) {
    return <p>{translations.viewServicePage.missingService}</p>;
  }

  let initialTaxValues = service.tax;

  function getType(service: Service) {
    if (service.controlled) {
      return ServiceType.controlled;
    } else if (service.vaccine) {
      return ServiceType.vaccine;
    }
    return ServiceType.standard;
  }

  const title = `#${service?.id} ${service?.name}`;

  const onFinish = async (values: Store) => {
    if (values.default && !note.plaintext) {
      form.setFields([
        {
          name: 'note',
          errors: [translations.shared.getIsRequiredMessage(translations.viewServicePage.fields.content)],
        },
      ]);
      setTabKey('1');
      return;
    }
    if (values.defaultPrescription && !prescriptionNote.plaintext) {
      form.setFields([
        {
          name: 'prescriptionNote',
          errors: [translations.shared.getIsRequiredMessage(translations.viewServicePage.fields.content)],
        },
      ]);
      setTabKey('1');
      return;
    }
    setIsSaving(true);
    try {
      // Incase field onBlur is not called for some reason, call it again here to make sure
      values.price = getFormattedDecimalString(values.price);
      if (values.quantity_default) {
        values.quantity_default = getFormattedDecimalString(values.quantity_default);
      }

      const serviceValues: ServiceUpsertFormFields = {
        ...(values as ServiceUpsertFormFields),
        inactive: !values.active,
        tax: generateTaxUpsert(values.tax_ids, initialTaxValues),
        generalLedgerId: values.generalLedgerId,
      };

      let serviceUpsert = new ServiceUpsertGenerator(service, practice.id).generateFromUpdatedValues(serviceValues);

      if (serviceValues.name === null || serviceValues.name === undefined) {
        serviceUpsert = new ServiceUpsertGenerator(service, practice.id).generateFromServiceValues(
          service,
          serviceValues
        );
      }

      const text: InfoTextUpsert[] = [];

      if (infoServiceText?.value !== values.info) {
        if (serviceInfoTypeId !== '') {
          const serviceInfoTextVoid = {
            id: serviceInfoTypeId,
            record: {
              type_id: orgInfoTypeId,
              value: values.info,
            },
            void: true,
          };
          text.push(serviceInfoTextVoid);
        }
        if (values.info && values.info.trim() !== '') {
          const serviceInfoTextUpsert = {
            record: {
              type_id: orgInfoTypeId,
              value: values.info,
            },
          };
          text.push(serviceInfoTextUpsert);
        }
      }

      if ((serviceText && !defaultNoteChecked) || (note.text && note.text !== serviceNote?.noteText)) {
        const serviceTextUpsert = {
          id: serviceTextId,
          record: {
            type_id: orgTextTypeId,
            value: formatServiceText(
              values.type_id,
              noteRefData.find((type) => type.id === values.type_id)?.name ?? serviceNote?.noteTypeName,
              note.text,
              note.plaintext
            ),
          },
          void: false,
        };

        serviceTextUpsert.void = !values.default;
        text.push(serviceTextUpsert as InfoTextUpsert);
      }

      if (
        (prescriptionServiceText && !defaultPrescriptionInstructions) ||
        (prescriptionNote.text && prescriptionNote.text !== prescriptionServiceNote?.noteText)
      ) {
        const serviceTextUpsert = {
          id: servicePrescriptionId,
          record: {
            type_id: orgPrescriptionTypeId,
            value: JSON.stringify({ noteText: prescriptionNote.text, plaintext: prescriptionNote.plaintext }),
          },
          void: false,
        };

        serviceTextUpsert.void = !values.defaultPrescription;
        text.push(serviceTextUpsert as InfoTextUpsert);
      }

      if (values.type !== ServiceType.vaccine) {
        if (serviceVaccinationTypeId) {
          const serviceVaccinationTextVoid = {
            id: serviceVaccinationTypeId,
            record: {
              type_id: orgVaccinationTypeId,
              value: JSON.stringify({
                vaccinationDefaults: {
                  manufacturer: vaccination.manufacturer,
                  serial: vaccination.serialNumber,
                  lot_number: vaccination.lotNumber,
                  expiry_date: vaccination.expiryDate,
                },
              }),
            },
            void: true,
          };
          text.push(serviceVaccinationTextVoid);
        }
      } else if (
        vaccination.manufacturer !== values.manufacturer ||
        vaccination.lotNumber !== values.lotNumber ||
        vaccination.serialNumber !== values.serialNumber ||
        vaccination.expiryDate !== values.expiryDate
      ) {
        const serviceInfoTextUpsert = {
          id: serviceVaccinationTypeId,
          record: {
            type_id: orgVaccinationTypeId,
            value: JSON.stringify({
              vaccinationDefaults: {
                manufacturer: values.manufacturer,
                serial: values.serialNumber,
                lot_number: values.lotNumber,
                expiry_date: values.expiryDate ? dayjs(values.expiryDate).format(upsertDateFormat) : null,
              },
            }),
          },
        };
        text.push(serviceInfoTextUpsert as InfoTextUpsert);
      }
      serviceUpsert.text = text;

      if (state.bundleUpsert) {
        serviceUpsert.bundle = state.bundleUpsert;
      }

      if (state.bundlePromptActive !== service.bundle_prompt) {
        serviceUpsert.bundlingRecord = {
          bundle_prompt: state.bundlePromptActive,
        };
      }

      if (serviceReminder) {
        serviceUpsert.reminder = [
          {
            id: serviceReminder.id,
            void: !values.reminder,
            record: { duration: values.duration },
          },
        ];
      } else if (values.reminder) {
        serviceUpsert.reminder = [
          {
            record: { duration: values.duration },
          },
        ];
      }

      const { data } = await upsertService({
        variables: {
          organizationId: organization?.id,
          service: serviceUpsert,
        },
      });

      if (data?.upsertService) {
        setUnsavedData(false);
        initialTaxValues = data.upsertService.tax;

        if (!data.upsertService.text?.find(({ type_id }) => type_id === '1')) {
          form.setFieldsValue({ type_id: null });
          setNote({ text: '', plaintext: '' });
        }

        if (!data.upsertService.text?.find(({ type_id }) => type_id === '2')) {
          setPrescriptionNote({ text: '', plaintext: '' });
        }

        showSuccessMessage(translations.viewServicePage.getSuccessMessage(service.name));

        if (state.setRemovedServices) {
          state.setRemovedServices([]);
        }
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
  };

  const onBack = () => {
    // Always redirect to service grid list, instead of back to empty form
    navigateTo(routes.services);
  };

  const handleReset = () => {
    form.resetFields();
    setDefaultNoteChecked(!!serviceNote);
    setDefaultPrescriptionInstructions(!!orgPrescriptionTypeId);
    form.setFieldsValue({ type_id: serviceNote?.noteTypeId });
    form.setFieldsValue({ default: !!serviceNote });
    form.setFieldsValue({ defaultPrescription: !!servicePrescriptionId });
    setNote({
      text: serviceNote?.noteText || '',
      plaintext: serviceNote?.notePreview || '',
    });
    setPrescriptionNote({
      text: prescriptionServiceNote?.noteText || '',
      plaintext: prescriptionServiceNote?.notePreview || '',
    });
    setUnsavedData(false);
    if (state.resetBundleList && state.setRemovedServices) {
      state.resetBundleList();
      state.setRemovedServices([]);
    }
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    // isMatch is not catching differences in tax_ids, so check that directly
    setUnsavedData(!isMatch(initialData, allValues) || !isEqual(initialData.tax_ids, allValues.tax_ids));
    if (!allValues?.prescription_prompt && defaultPrescriptionInstructions) {
      setDefaultPrescriptionInstructions(false);
    }
  };

  const handleNoteTextChange =
    (setStateFn: (values: NoteState) => void, name: string) => (text: string, plaintext: string) => {
      plaintext = plaintext.replace('\n', '');
      setStateFn({
        text,
        plaintext,
      });

      const unsavedData = name === 'note' ? text !== serviceNote?.noteText : text !== prescriptionServiceNote?.noteText;

      setUnsavedData(unsavedData);

      if (plaintext !== '') {
        form.setFields([
          {
            name,
            errors: [],
          },
        ]);
      }
    };

  const tabContents: TabWrapperProps['tabContents'] = [
    {
      title: translations.servicePage.tabs.details,
      content: (
        <ServiceForm
          service={service}
          form={form}
          organization={organization}
          setUnsavedData={setUnsavedData}
          serviceInfoUpdateLine={
            infoServiceText?.updated_user_name
              ? `${infoServiceText?.updated_user_name} - ${dayjs(infoServiceText?.updated).format(dateFormat)}`
              : undefined
          }
        />
      ),
    },
    {
      title: translations.servicePage.tabs.notes,
      content: (
        <ServiceNote
          noteComponent={
            <>
              {defaultNoteChecked && (
                <NoteField
                  name='note'
                  noteTypes={refDataWithVoid}
                  setText={handleNoteTextChange(setNote, 'note')}
                  text={note.text}
                />
              )}
            </>
          }
          prescriptionNoteComponent={
            <>
              {defaultPrescriptionInstructions && form.getFieldValue('prescription_prompt') && (
                <NoteField
                  name='prescriptionNote'
                  setText={handleNoteTextChange(setPrescriptionNote, 'prescriptionNote')}
                  text={prescriptionNote.text}
                />
              )}
            </>
          }
          onDefaultChange={setDefaultNoteChecked}
          onDefaultPrescriptionChange={setDefaultPrescriptionInstructions}
          isPrescriptionEnabled={form.getFieldValue('prescription_prompt')}
        />
      ),
    },
    {
      title: translations.servicePage.tabs.bundle,
      content: <ServiceBundleTab service={service} setUnsavedData={setUnsavedData} />,
    },
    ...(enabledLabLink
      ? [
          {
            title: translations.servicePage.tabs.labLink,
            content: <LabLink practiceId={practice.id} service={service} />,
          },
        ]
      : []),
    /*
        TEMPORARILY DISABLED

    ...(enabledHisa
      ? [
          {
            title: translations.servicePage.tabs.hisa,
            content: <HisaSetup service={service} organization={organization} />,
          },
        ]
      : []),
    */
  ];

  const handleFinishFailed = (errorInfo: Store) => {
    const fieldsWithErrors = errorInfo.errorFields.reduce((i: string[], j: any) => [...i, j.name[0]], []);

    for (let i = 0; i < fieldsWithErrors.length; i++) {
      if (fieldsWithErrors[i] === 'type_id') {
        setTabKey('1');
        return;
      }
    }
    setTabKey('0');
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving || serviceTextLoading}
      showNavigationWarning={unsavedData}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.viewServicePage.entity)}
    >
      <PageHeader onBack={onBack} title={title} />
      {service && !serviceTextLoading && (
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          initialValues={initialData}
          onValuesChange={handleValueChange}
          onFinishFailed={handleFinishFailed}
          autoComplete='off'
        >
          <TabWrapper
            extra={
              <ActiveFormItem
                labelCol={{ span: 12 }}
                name='active'
                label={translations.addServicePage.fields.active}
                valuePropName='checked'
              >
                <Switch defaultChecked />
              </ActiveFormItem>
            }
            tabContents={tabContents}
            activeKey={tabKey}
            onTabClick={setTabKey}
          />
          {unsavedData && (
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                {!defaultNoteChecked && serviceTextId ? (
                  <Form.Item wrapperCol={{ offset: layout.labelCol.span }}>
                    <ButtonWithPopconfirm
                      buttonText={translations.shared.saveChangesButtonText}
                      popconfirmTitle={translations.viewServicePage.noteDeleteConfirmation}
                      onClickCallback={() => form.submit()}
                      buttonStyle={{ marginRight: '10px' }}
                      buttonType={'primary'}
                    />
                    <Button htmlType='button' onClick={handleReset}>
                      {translations.shared.resetButtonText}
                    </Button>
                  </Form.Item>
                ) : (
                  <SaveAndResetButton onReset={handleReset} offset={layout.labelCol.span} />
                )}
              </Col>
            </Row>
          )}
        </Form>
      )}
    </SaveSpinnerAndNavigationWarning>
  );
};
