import React, { PropsWithChildren } from 'react';
import { PlusOutlined } from '@ant-design/icons/lib';
import { translations } from '../../../../constants/translations';
import { StyledAddOwnershipButton } from './PatientOwnership.style';

interface AddOwnershipButtonProps extends PropsWithChildren<unknown> {
  changeModalVisibility: () => void;
  disabled: boolean;
  title: string;
}

export const ownerShipButtonTestId = 'set-new-ownership-button';

export const AddOwnershipButton: React.FC<AddOwnershipButtonProps> = ({
  changeModalVisibility,
  disabled,
  title,
}: AddOwnershipButtonProps) => {
  return (
    <StyledAddOwnershipButton
      title={title}
      onClick={changeModalVisibility}
      block
      disabled={disabled}
      data-testid={'setOwnershipButton'}
    >
      <span>
        <PlusOutlined />
        <span style={{ position: 'relative', paddingLeft: '12px' }} data-testid={ownerShipButtonTestId}>
          {translations.patientPage.ownership.addOwnershipModal.openModalButton}
        </span>
      </span>
    </StyledAddOwnershipButton>
  );
};
