import { AddressType } from '../../classes/upsertGenerators/AddressUpsertGenerator';
import { PropsWithChildren } from 'react';
import { Maybe, Scalars } from '../../graph/types';

export interface AddressProps extends PropsWithChildren<unknown> {
  address?: Maybe<{ address_type_id?: Maybe<Scalars['ID']> }[]>;
}

function getUnusedAddressIndex(
  initialPracticeUpsert: AddressProps,
  numberOfAddressesNotInInitialPracticeUpsert: number
) {
  return (initialPracticeUpsert?.address?.length || 0) + numberOfAddressesNotInInitialPracticeUpsert;
}

export function getIndicesOfAddressTypes(initialPracticeUpsert: AddressProps) {
  let numberOfAddressesNotInInitialPracticeUpsert = 0;
  let indexOfPhysicalAddress = findAddressIndexOfType(AddressType.Physical, initialPracticeUpsert);
  if (indexOfPhysicalAddress === -1) {
    indexOfPhysicalAddress = getUnusedAddressIndex(initialPracticeUpsert, numberOfAddressesNotInInitialPracticeUpsert);
    numberOfAddressesNotInInitialPracticeUpsert++;
  }
  let indexOfMailingAddress = findAddressIndexOfType(AddressType.Mailing, initialPracticeUpsert);
  if (indexOfMailingAddress === -1) {
    indexOfMailingAddress = getUnusedAddressIndex(initialPracticeUpsert, numberOfAddressesNotInInitialPracticeUpsert);
    numberOfAddressesNotInInitialPracticeUpsert++;
  }
  return { indexOfPhysicalAddress, indexOfMailingAddress };
}

export function hasInitialMailingAddress(initialPracticeUpsert: AddressProps) {
  return findAddressIndexOfType(AddressType.Mailing, initialPracticeUpsert) !== -1;
}

function findAddressIndexOfType(addressType: Scalars['ID'], practiceUpsert: AddressProps) {
  if (!practiceUpsert?.address) {
    return -1;
  }
  return practiceUpsert.address.findIndex((address) => address?.address_type_id === addressType);
}
