import { Store } from 'antd/es/form/interface';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import ModalWithCloseConfirm from '../../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { showErrorMessage, showSuccessMessage } from '../../../../components/Notification/notificationUtil';
import { TabLoading } from '../../../../components/Loading/Loading';
import {
  BasicColumnType,
  TableWithCustomFiltering,
} from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { translations } from '../../../../constants/translations';
import { Contact, Patient, PracticeDto } from '../../../../graph/types';
import {
  useGetContactPatientsWithSearch,
  useInsertPatient,
  usePatientOfflineInsert,
} from '../../../../hooks/ajax/patients/patientHooks';
import { useGetContactIdFromRoute, useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useSetWarningIfTooManyResults } from '../../../../hooks/setWarningIfTooManyResults';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { isMatchIgnoringEmptyStrings } from '../../../../util/objectComparisons';
import { createPatientUpsertDto } from '../../../Patients/AddPatient/AddPatient';
import { AddPatientForm } from '../../../Patients/AddPatientForm';
import { TableKey, getDefaultColumnKeysForPatients, useTableColumnDisplayFilter } from '../../../../hooks/tableHooks';
import { useContactPatientColumnsHelper } from './contactPatientColumns';
import { ColumnDisplayFilterContext } from '../../../../components/ColumnDisplayFilter/store/state';
import { setColumnDisplayFilterProps } from '../../../../components/ColumnDisplayFilter/store/actions';
import { isUuid, useOffline } from '../../../../util/offline/offlineUtil';
import { useContactSubscription } from '../../../../hooks/contactSubscriptionHook';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import {
  FilterValue,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { upsertDateFormat } from '../../../../constants/formats';
import { flushSync } from 'react-dom';
import { useOrganizationContext } from '../../../../contexts/organization/state';
import { useGetContactType } from '../../../../hooks/ajax/contact/contactHooks';

export interface ContactPatientProps {
  contact: Contact;
  searchValue?: string;
  setShowTooManyPatientResultsWarning: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  closeModal: () => void;
  practice: PracticeDto;
  setContactPatientTableColumns: (columns: BasicColumnType<Patient>[]) => void;
  filteredValue: Record<string, FilterValue | null>;
  sortOrderMap: { [key: string]: SortOrder | undefined };
  tableChangeHandler: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
}

export const ContactPatients: React.FC<ContactPatientProps> = ({
  contact,
  searchValue,
  setShowTooManyPatientResultsWarning,
  isModalOpen,
  closeModal,
  practice,
  setContactPatientTableColumns,
  filteredValue,
  sortOrderMap,
  tableChangeHandler,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [form] = useForm();

  const organizationId = useGetOrganizationIdFromRoute();
  const contactId = useGetContactIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const {
    patients,
    patientsLoading,
    setSearchTerm,
    refetchCurrentSearch: refetchPatients,
  } = useGetContactPatientsWithSearch(contact.id, organizationId, searchValue);
  useContactSubscription(contact.id, refetchPatients);
  const { contactTypes } = useGetContactType();
  const { windowWidth } = useWindowDimensions();
  const [insertPatient] = useInsertPatient();
  const patientReferenceData = useMemo(() => organization?.ref_patient, [organization]);

  const { optionalColumns, getAllContactPatientColumns } = useContactPatientColumnsHelper(
    contact.name,
    filteredValue,
    sortOrderMap,
    contactTypes,
    patients,
    patientReferenceData
  );

  const defaultColumnKeys = useMemo(() => getDefaultColumnKeysForPatients(optionalColumns), [optionalColumns]);

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter<Patient>(TableKey.ContactPatients, optionalColumns, defaultColumnKeys);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => getAllContactPatientColumns(displayedColumns), [displayedColumns]);
  setContactPatientTableColumns(columns);

  const { enabledAndOffline } = useOffline();
  const offlineInsert = usePatientOfflineInsert();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const { dispatch } = useContext(ColumnDisplayFilterContext);

  useEffect(() => {
    setSearchTerm(searchValue ?? '');
  }, [searchValue, setSearchTerm]);

  useEffect(
    () =>
      dispatch(
        setColumnDisplayFilterProps({
          columnKeyAndTitleList,
          setDisplayedColumns: setDisplayedColumnKeys,
          initiallyDisplayedColumns: displayedColumnKeys,
          resetDisplayedColumnsToDefault,
        })
      ),
    [columnKeyAndTitleList, dispatch, displayedColumnKeys, resetDisplayedColumnsToDefault, setDisplayedColumnKeys]
  );

  useSetWarningIfTooManyResults(patients, setShowTooManyPatientResultsWarning);

  if (patientsLoading) {
    return <TabLoading />;
  }

  if (!organization) {
    return <p>{translations.shared.missingOrganization}</p>;
  }

  const modalWidth = windowWidth * 0.5;

  const initialValues = {
    status: 'active',
  };

  const onFinish = async () => {
    const isContactIdUuid = isUuid(contactId);
    const values = form.getFieldsValue();
    setIsSaving(true);
    const ownership = [
      {
        owner: [
          {
            related_contact_id: !isContactIdUuid ? contactId : undefined,
            offline_related_contact_id: isContactIdUuid ? contactId : undefined,
            percentage: '100',
            primary: true,
            void: false,
          },
        ],
        effective_date: dayjs().format(upsertDateFormat),
        void: false,
      },
    ];

    const patientUpsert = createPatientUpsertDto({ ...values, ownership }, practice, upsertDateFormat);

    try {
      let patient: Patient | undefined | null;
      if (!enabledAndOffline) {
        const { data } = await insertPatient({
          variables: {
            patient: patientUpsert,
            organizationId: practice.organization_id,
          },
        });
        patient = data?.upsertPatient;
      } else {
        patient = await offlineInsert?.(patientUpsert);
      }

      if (patient) {
        flushSync(() => setHasChanged(false));
        showSuccessMessage(translations.addPatientPage.getSuccessMessage(patient.number || patient.name || ''));
        closeModal();
        form.resetFields();
      }
    } catch (e) {
      showErrorMessage((e as Error).message || translations.shared.saveErrorMessage);
    }

    setIsSaving(false);
    refetchPatients();
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    flushSync(() => setHasChanged(!isMatchIgnoringEmptyStrings(allValues, initialValues)));
  };

  return (
    <>
      <TableWithCustomFiltering
        tableKey={TableKey.ContactPatients}
        dataSource={patients}
        columns={columns}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
      <ModalWithCloseConfirm
        isSaving={isSaving}
        open={isModalOpen}
        width={modalWidth}
        onCancel={() => {
          form.resetFields();
          closeModal();
          flushSync(() => setHasChanged(false));
        }}
        onOk={async () => {
          await form.validateFields();
          onFinish();
        }}
        cancelConfirmMessage={translations.shared.getCreationNavigationWarning(
          translations.addPatientPage.createdEntity
        )}
        changesMade={hasChanged}
        title={translations.addPatientPage.title}
      >
        {!organization ? (
          <p>{translations.shared.missingOrganization}</p>
        ) : (
          <AddPatientForm
            form={form}
            handleValueChange={handleValueChange}
            initialValues={initialValues}
            dateFormat={dateFormat}
          />
        )}
      </ModalWithCloseConfirm>
    </>
  );
};
