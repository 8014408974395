import React, { useMemo } from 'react';
import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { ContactInterestRunEntry, interestRunPropertyNames } from '../../constants/propertyNames';
import { translations } from '../../constants/translations';
import { ContactInterestEntry } from '../../graph/types';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';
import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';

const { columns } = translations.interestRuns;

export const useBasicInterestRunColumns = (): Record<
  ContactInterestRunEntry,
  CustomColumnType<ContactInterestEntry>
> => {
  return useMemo(
    () => ({
      contact_name: {
        title: columns.contact,
        dataIndex: interestRunPropertyNames.contact_name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.contact),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.contact_name),
        sorter: getStringCompareFunctionFor(interestRunPropertyNames.contact_name),
      },
      total: {
        title: columns.amountDue,
        dataIndex: interestRunPropertyNames.total,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.amountDue),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.total, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.total),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
      contact_status_name: {
        title: columns.status,
        dataIndex: interestRunPropertyNames.contact_status_name,
        ...generalFilteringAndSortingSettings,
        onFilter: (value, record) => record.contact_status_id.includes(value.toString()),
        sorter: getStringCompareFunctionFor(interestRunPropertyNames.contact_status_name),
      },
      contact_email: {
        title: columns.email,
        dataIndex: interestRunPropertyNames.contact_email,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.email),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.contact_email),
        sorter: getStringCompareFunctionFor(interestRunPropertyNames.contact_email),
      },
      bucket0: {
        title: columns.bucket0,
        dataIndex: interestRunPropertyNames.bucket0,
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.bucket0),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.bucket0, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.bucket0),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
      bucket31: {
        title: columns.bucket31,
        dataIndex: interestRunPropertyNames.bucket31,
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.bucket31),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.bucket31, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.bucket31),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
      bucket61: {
        title: columns.bucket61,
        dataIndex: interestRunPropertyNames.bucket61,
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.bucket61),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.bucket61, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.bucket61),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
      bucket91: {
        title: columns.bucket91,
        dataIndex: interestRunPropertyNames.bucket91,
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.bucket91),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.bucket91, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.bucket91),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
      bucket121: {
        title: columns.bucket121,
        dataIndex: interestRunPropertyNames.bucket121,
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(columns.bucket121),
        onFilter: getOnFilterFunctionFor(interestRunPropertyNames.bucket121, true),
        sorter: getNumberCompareFunctionFor(interestRunPropertyNames.bucket121),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
      },
    }),
    []
  );
};
