import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { UpsertInterestRun, GetInterestRunsPg } from '../../../graph/queries/interestRuns';
import { MutationUpsertInterestRunArgs } from '../../../graph/types';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useUpsertInterestRun = (organizationId?: string, practiceId?: string) => {
  const hasOrganizationAndPractice = organizationId && practiceId;

  return useOfflineErrorSkipMutation<'upsertInterestRun', MutationUpsertInterestRunArgs>(UpsertInterestRun, {
    onError: (error) => showErrorMessage(error.message),
    onCompleted: () => hasOrganizationAndPractice && showSuccessMessage(translations.shared.saveSuccessMessage),
  });
};

export const useGetInterestRunsPg = (organizationId: string, practiceId?: string, id?: string) => {
  practiceId = practiceId ? practiceId : '';

  const filter = { practice_id: practiceId, id };

  const { data, loading } = useOfflineErrorSkipQuery<'getInterestRunsPg'>(
    GetInterestRunsPg,
    {
      variables: { organizationId, filter },
      skip: filter.practice_id === '',
    },
    true,
    true
  );
  return {
    interestRuns: data?.getInterestRunsPg,
    interestRunsLoading: loading,
  };
};
