import { Button } from 'antd';
import styled from 'styled-components';

// TODO add proper type
export const StyledAddOwnershipButton: any = styled(Button)`
  height: 50px;
  text-align: left;
  &:not(:hover) {
    background: #fafafa;
    border-color: #fafafa;
  }
  &:hover {
    background: #fafafa;
  }
`;
