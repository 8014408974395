import { Input, Space, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { translations } from '../../constants/translations';
import { setTextConfirmAction, UtilsAction } from '../UtilsProvider/store/actions';

export interface TextConfirmModalProps {
  show: boolean;
  onConfirm?: () => Promise<void> | void;
  title?: string;
  validationText?: string;
  description?: string | React.ReactNode;
}

export const TextConfirmModal: React.FC<TextConfirmModalProps & { dispatchUtils: React.Dispatch<UtilsAction> }> = ({
  show,
  onConfirm,
  title,
  description,
  validationText = translations.textConfirmModal.validationText,
  dispatchUtils,
}) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setConfirmed(e.target.value.toLowerCase() === validationText.toLowerCase());
  };

  const handleConfirm = () => {
    handleClose();
    onConfirm?.();
  };

  const handleClose = () => {
    setValue('');
    setConfirmed(false);
    dispatchUtils(setTextConfirmAction({ show: false }));
  };

  return (
    <Modal
      title={title ?? translations.textConfirmModal.title}
      onOk={handleConfirm}
      onCancel={handleClose}
      open={show}
      okText={translations.textConfirmModal.okText}
      okButtonProps={{
        disabled: !confirmed,
      }}
      cancelText={translations.shared.cancelButtonText}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <Typography.Text>{description ?? translations.textConfirmModal.description}</Typography.Text>
        <Typography.Text>{translations.textConfirmModal.actionText(validationText)}</Typography.Text>
        <Input value={value} onChange={handleInputChange} data-testid='confirm-input' />
      </Space>
    </Modal>
  );
};
