/* eslint-disable no-console */
import { Table, Select, Button, Tooltip } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import {
  DropdownButtonWithMenu,
  DropdownButtonWithMenuProps,
} from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { translations } from '../../../constants/translations';
import { StyledOptionContent } from '../../../globalStyles.style';
import { GetLabRefServices } from '../../../graph/queries/labLink';
import { LabServiceMap, Query, Service } from '../../../graph/types';
import { useGetLabDevices } from '../../../hooks/ajax/lab/labHooks';
import { useUpsertService } from '../../../hooks/ajax/service/serviceHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import { getLabOptions } from '../../../util/labUtil';
import { Container } from '../../ReferenceData/RefDataTable/RefDataTable.styles';

type LabLinkProps = {
  practiceId: string;
  service: Service;
};

type EditableLabMap = {
  id: string;
  organization_id: string;
  service_id: string;
  service_name: string;
  lab_device_id?: string | null;
  lab_device_name?: string;
  lab_service_name?: string;
  lab_service_number?: string;
};
const MinWidthSelect = styled(Select<string>)<SelectProps<any>>`
  && {
    width: 300px;
  }
`;

const { Option } = Select;

export const LabLink: React.FC<LabLinkProps> = ({ practiceId, service }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { labDevices, loading } = useGetLabDevices(organizationId, practiceId);
  const [serviceOptions, setServiceOptions] = useState<JSX.Element[]>();
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeviceLoading, setIsDeviceLoading] = useState(false);
  const [currentEditing, setCurrentEditing] = useState<string>();
  const [labMap, setLabMap] = useState<EditableLabMap[]>(service.lab_mapping ?? []);
  const [serviceUpsert] = useUpsertService(organizationId);
  const foundLab = labMap.find((lab) => lab.id === currentEditing);

  useEffect(() => {
    const hasChanges = JSON.stringify(labMap) !== JSON.stringify(service.lab_mapping);
    setHasChanged(hasChanges);
  }, [service.lab_mapping, labMap]);

  useEffect(() => {
    setLabMap(service.lab_mapping ?? []);
  }, [service.lab_mapping]);

  const onNewLab = () => {
    setLabMap([
      ...labMap,
      {
        id: ID_FOR_OBJECT_CREATION,
        organization_id: organizationId,
        service_id: service.id,
        service_name: service.name,
        lab_device_id: undefined,
      },
    ]);
    setCurrentEditing(ID_FOR_OBJECT_CREATION);
  };

  const handleDeviceChange = async (value: string, entry: any) => {
    const updatedLabMap = labMap.map((lab) => {
      if (lab.id === currentEditing) {
        return {
          ...lab,
          lab_device_id: value,
          lab_device_name: entry.label,
          lab_service_number: undefined,
        } as EditableLabMap;
      }
      return lab;
    });
    setIsDeviceLoading(true);
    await handleServiceGet(value);
    setIsDeviceLoading(false);
    setLabMap(updatedLabMap);
  };

  const handleServiceChange = (value: string, entry: any) => {
    const updatedLabMap = labMap.map((lab) => {
      if (lab.id === currentEditing) {
        return { ...lab, lab_service_number: value, lab_service_name: entry?.label } as LabServiceMap;
      }
      return lab;
    });

    setLabMap(updatedLabMap);
  };

  const handleServiceGet = async (value: SelectValue) => {
    try {
      const { data } = await AppSyncService.client.query<Pick<Query, 'getLabRefServices'>>({
        query: GetLabRefServices,
        variables: {
          organizationId,
          labRefFilter: {
            lab_device_id: value,
          },
        },
      });

      if (data) {
        const serviceOptions = data.getLabRefServices?.map((s) => (
          <Option key={s.lab_service_number} value={s.lab_service_number} label={s.lab_service_name}>
            <Tooltip title={s.lab_service_name} placement='right'>
              <StyledOptionContent>{s.lab_service_name}</StyledOptionContent>
            </Tooltip>
          </Option>
        ));
        setServiceOptions(serviceOptions);
      }
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.loadErrorMessage);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    const mappingUpsertRecord = {
      id: currentEditing,
      record: {
        lab_device_id: foundLab?.lab_device_id,
        lab_service_number: foundLab?.lab_service_number,
        lab_service_name: foundLab?.lab_service_name,
      },
    };

    await serviceUpsert({
      variables: {
        organizationId,
        service: {
          id: service.id,
          lab_mapping: mappingUpsertRecord,
        },
      },
    });
    setCurrentEditing(undefined);
    setIsLoading(false);
  };

  const onCancel = () => {
    setLabMap(service.lab_mapping ?? []);
    setCurrentEditing(undefined);
  };

  const handleSetEdit = async (id: string, deviceId: string) => {
    await handleServiceGet(deviceId);
    setCurrentEditing(id);
  };

  const handleOnDelete = async (id: string) => {
    setIsLoading(true);
    try {
      await serviceUpsert({
        variables: {
          organizationId,
          service: {
            id: service.id,
            lab_mapping: {
              id,
              void: true,
            },
          },
        },
      });
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsLoading(false);
  };

  const data: EditableLabMap[] = labMap;

  const labOptions = getLabOptions(labDevices ?? []);

  const getMenuItemProps: (id: string, deviceId: string) => DropdownButtonWithMenuProps['menuItemProps'] = (
    id: string,
    deviceId: string
  ) => {
    return [
      {
        title: translations.shared.editButtonText,
        onClick: () => handleSetEdit(id, deviceId),
      },
      {
        title: translations.shared.deleteButtonText,
        onClick: () => handleOnDelete(id),
        popconfirmProps: {
          title: translations.labLinkPage.deleteEntryConfirm,
          okText: translations.shared.popconfirm.no,
          cancelText: translations.shared.popconfirm.ok,
        },
        executeOnClickOnPopconfirmCancelInsteadOfConfirm: true,
      },
    ];
  };

  const btnAddIsDisabled = Array.isArray(labMap) && labMap.length > 0;
  const isComplete = !!foundLab?.lab_device_id && !!foundLab?.lab_service_number;

  const columns = [
    {
      title: translations.labLinkPage.fields.lab.title,
      key: 'lab_device_id',
      dataIndex: 'lab_device_id',
      width: 300,
      render: (_: string, { id, lab_device_name, lab_device_id }: EditableLabMap) => (
        <>
          {currentEditing === id ? (
            <MinWidthSelect
              options={labOptions}
              placeholder={translations.labLinkPage.fields.lab.placeholder}
              onChange={handleDeviceChange}
              defaultValue={lab_device_id ?? undefined}
              data-testid='lab-select'
            />
          ) : (
            <div onClick={() => handleSetEdit(id, lab_device_id!)}>{lab_device_name}</div>
          )}
        </>
      ),
    },
    {
      title: translations.labLinkPage.fields.test.title,
      key: 'lab_service_number',
      dataIndex: 'lab_service_number',
      width: 300,
      render: (_: string, { id, lab_service_name, lab_device_id }: EditableLabMap) => {
        return (
          <>
            {currentEditing === id ? (
              <MinWidthSelect
                {...searchableSelectParams}
                placeholder={translations.labLinkPage.fields.test.placeholder}
                disabled={!lab_device_id}
                defaultValue={lab_service_name ?? undefined}
                onChange={handleServiceChange}
                data-testid='test-select'
              >
                {serviceOptions}
              </MinWidthSelect>
            ) : (
              <div onClick={() => handleSetEdit(id, lab_device_id!)}>{lab_service_name}</div>
            )}
          </>
        );
      },
    },
    {
      title: translations.labLinkPage.fields.actions.title,
      key: translations.labLinkPage.fields.actions.title,
      render: (_: string, { id, lab_device_id }: EditableLabMap) => {
        return (
          <>
            {currentEditing === id ? (
              <>
                {hasChanged && isComplete && (
                  <TableCellLink onClick={onSave} dataTestId={`${lab_device_id}_saveButton`}>
                    {translations.shared.saveButtonText}
                  </TableCellLink>
                )}
                <TableCellLink onClick={onCancel} dataTestId={`${lab_device_id}_cancelButton`}>
                  {translations.shared.cancelButtonText}
                </TableCellLink>
              </>
            ) : (
              <DropdownButtonWithMenu menuItemProps={getMenuItemProps(id, lab_device_id!)} />
            )}
          </>
        );
      },
    },
  ];

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={loading || isLoading || isDeviceLoading}
      showNavigationWarning={hasChanged}
      savingMessage={isDeviceLoading ? translations.shared.communicatingWithLabMessage : undefined}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.labLinkPage.fields.lab.title)}
    >
      <Container>
        <Button
          onClick={onNewLab}
          title={btnAddIsDisabled ? translations.labLinkPage.addNewEntryDisabled : translations.labLinkPage.addNewEntry}
          disabled={btnAddIsDisabled}
        >
          {translations.labLinkPage.addNewEntry}
        </Button>
      </Container>
      <Table dataSource={data} columns={columns} rowKey='id' pagination={false} />
    </SaveSpinnerAndNavigationWarning>
  );
};
