import { User } from '../../graph/types';
import { UserAction, setUserType } from './action';

export interface UserState {
  user?: User;
}

export const initialState: UserState = {
  user: undefined,
};

export const userReducer = (state: UserState = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case setUserType:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};
