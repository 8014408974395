import { useEffect } from 'react';
import { MAX_NUMBER_OF_LIST_ITEMS } from '../constants/queryConstants';
import { useOffline } from '../util/offline/offlineUtil';

export const shouldShowTooManyResultsWarning = (results: unknown[] | undefined) => {
  return !!results && results.length >= MAX_NUMBER_OF_LIST_ITEMS;
};

export const useShouldShowTooManyResultsWarning = () => {
  const { enabledAndOffline } = useOffline();

  return (results: unknown[] | undefined) => !enabledAndOffline && shouldShowTooManyResultsWarning(results);
};

export const useSetWarningIfTooManyResults = (
  results: unknown[] | undefined,
  setShowTooManyResultsWarning: (showWarning: boolean) => void
) => {
  useEffect(() => {
    setShowTooManyResultsWarning(shouldShowTooManyResultsWarning(results));
  }, [results, setShowTooManyResultsWarning]);
};
