import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Popover from 'antd/lib/popover';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { Note } from '../../graph/types';
import { dummyNoteIdForRapidBilling, useGetNote } from '../../hooks/ajax/note/noteHooks';
import { Loading } from '../Loading/Loading';
import { translations } from '../../constants/translations';
import ClickableIcon, { IconType } from '../Icons/ClickableIcon';

interface PopoverNoteContentProps {
  noteId: string;
  existingNote?: Note | null;
  haveNoteValuesChanged?: boolean;
}

interface PopoverContainerProps extends PopoverNoteContentProps {
  testId?: string;
  onClick?: () => void;
}

const ConstrainedDiv = styled.div`
  max-height: 200px;
  max-width: 350px;
  overflow-y: auto;
  font-size: 12px;
  white-space: pre;
`;

export function PopoverNoteContent({ noteId, existingNote, haveNoteValuesChanged }: PopoverNoteContentProps) {
  const organizationId = useGetOrganizationIdFromRoute();
  const { note: noteFromDB, noteLoading, noteRefetch } = useGetNote({ organizationId, noteId });
  const note = useMemo(
    () => (noteId === dummyNoteIdForRapidBilling ? existingNote : noteFromDB),
    [existingNote, noteFromDB, noteId]
  );

  useEffect(() => {
    async function refetch() {
      if (noteId !== dummyNoteIdForRapidBilling) {
        await noteRefetch();
      }
    }
    refetch();
  }, [noteId, haveNoteValuesChanged, noteRefetch]);

  if (noteLoading) {
    return <Loading />;
  }

  if (!note) {
    return <p>{translations.shared.note.loadingError}</p>;
  }

  return <ConstrainedDiv className='TextEditor_viewMode' dangerouslySetInnerHTML={{ __html: note.value }} />;
}

const PopoverContainer = ({ testId, onClick, ...props }: PopoverContainerProps) => {
  return (
    <Popover arrowPointAtCenter content={<PopoverNoteContent {...props} />}>
      <span>
        <ClickableIcon iconType={IconType.ServiceNote} data-testid={testId} onClick={onClick} />
      </span>
    </Popover>
  );
};

export default PopoverContainer;
