import { useParams } from 'react-router-dom';

export interface PatientIdParam {
  patientId: string;
}

export const useGetPatientIdFromRoute = () => {
  const { patientId } = useParams<PatientIdParam>();
  return patientId;
};

interface OrganizationIdParam {
  organizationId: string;
}

export const useGetOrganizationIdFromRoute = () => {
  const { organizationId } = useParams<OrganizationIdParam>();
  return organizationId;
};

export interface ServiceIdParam {
  serviceId: string;
}

export const useGetServiceIdFromRoute = () => {
  const { serviceId } = useParams<ServiceIdParam>();
  return serviceId;
};

interface InvoiceIdParam {
  invoiceId: string;
}

export const useGetInvoiceIdFromRoute = () => {
  const { invoiceId } = useParams<InvoiceIdParam>();
  return invoiceId;
};

interface SetNewPasswordParams {
  encodedUsername: string;
  code: string;
}

export const useGetSetNewPasswordDataFromRoute = (): SetNewPasswordParams => {
  const { encodedUsername: uriEncodedEncodedUsername, code } = useParams<SetNewPasswordParams>();
  const encodedUsername = decodeURIComponent(uriEncodedEncodedUsername);
  return { encodedUsername, code };
};

export interface ContactIdParam {
  contactId: string;
}

export const useGetContactIdFromRoute = () => {
  const { contactId } = useParams<ContactIdParam>();
  return contactId;
};

export const useGetReferenceDataTypeFromRoute = () => {
  const { referenceDataType } = useParams<{ referenceDataType: string }>();
  return referenceDataType;
};
