import React, { useCallback, useMemo, useState } from 'react';
import { Organization } from '../../../graph/types';
import {
  OrganizationSubscriptionLevelNameKeys,
  organizationSubscriptionLevelConfigs,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import { AddOnCardQuickBooks } from './AddOnCardQuickBooks';
import { QuickBooksConfigurationModal } from '../../../components/QuickBooksConfigurationModal/QuickBooksConfigurationModal';

interface AddOnCardQuickBooksContainerProps {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
}

const AddOnCardQuickBooksContainer: React.FC<AddOnCardQuickBooksContainerProps> = ({
  organizationId,
  organization,
  refetchOrganization,
}) => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
  const [showConfigureModal, setShowConfigureModal] = useState<boolean>(false);

  const quickBooksAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.QuickBooksLevel].level_id
      ),
    [organization]
  );

  const addOnEnabled = useMemo(() => (quickBooksAddon?.enabled ? true : false), [quickBooksAddon]);
  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const handleConfigure = () => {
    setShowConfigureModal(true);
  };

  const handleCloseConfigure = useCallback(() => {
    setShowConfigureModal(false);
  }, []);

  const handleSowSubscriptionModal = () => {
    setShowSubscriptionModal(true);
  };

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    refetchOrganization();
  };

  return (
    <>
      <AddOnCardQuickBooks
        isAddOnEnabled={addOnEnabled}
        price={quickBooksAddon?.cost}
        onEnable={handleSowSubscriptionModal}
        onConfigure={handleConfigure}
        countryId={countryId}
      />
      {showSubscriptionModal && (
        <SubscriptionUpdateModal
          onClose={handleCloseSubscriptionModal}
          onSuccess={handleCloseSubscriptionModal}
          canReduceUserCount
          showQuickBooks
        />
      )}
      {showConfigureModal && (
        <QuickBooksConfigurationModal organizationId={organizationId} onClose={handleCloseConfigure} />
      )}
    </>
  );
};

export default AddOnCardQuickBooksContainer;
