import { Button, Form, Modal } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { useEffect, useState } from 'react';
import { ContactTypeNameKey } from '../../constants/referenceData/contactReferenceData';
import { translations } from '../../constants/translations';
import { Contact } from '../../graph/types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { AddContact } from '../../pages/Contacts/AddContact/AddContact';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { TitleWithSearchBox } from '../TitleWithSearchBox/TitleWithSearchBox';
import { SelectContactModalTable } from './SelectContactModalTable';
import { useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { mapKeysToColumnTitle } from '../../util/mapUtil';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import styled from 'styled-components';

const RightFloatingButton = styled(Button)`
  margin-top: 14px;
  margin-right: 2rem;
  float: right;
`;

export interface SelectPersonModalProps {
  title: string;
  onCancel: () => void;
  excludedIds?: string[];
}

interface SelectContactModalProps extends SelectPersonModalProps {
  selectContact?: (contact: Contact) => void;
  visible: boolean;
  canSelectMultiple?: boolean;
  initialSelectedContacts?: Contact[];
  finishSelectContacts?: (contacts: Contact[]) => void;
  isSaving?: boolean;
  selectedRelationshipFilter?: ContactTypeNameKey[];
}

export const SelectContactModal: React.FC<SelectContactModalProps> = ({
  title,
  visible,
  onCancel,
  selectContact,
  excludedIds: excludedContactIds,
  finishSelectContacts,
  canSelectMultiple,
  initialSelectedContacts,
  isSaving = false,
  selectedRelationshipFilter,
}: SelectContactModalProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedContacts, setSelectedContacts] = useState(initialSelectedContacts ?? []);
  const { windowWidth } = useWindowDimensions();
  const [showTooManyResultsWarning, setShowTooManyResultsWarning] = useState(false);
  const [showCreateContactForm, setShowCreateContactForm] = useState(false);
  const [form] = Form.useForm();
  const [contactTableColumns, setContactTableColumns] = useState<CustomColumnType<Contact>[]>([]);

  const filterAndSortTableValues = useTableResetFilterAndSort();
  const { modifiedFields, resetFiltersAndSort } = filterAndSortTableValues;

  useEffect(() => {
    if (!canSelectMultiple && selectedContacts?.length && !showCreateContactForm) {
      finishSelectContacts?.(selectedContacts);
    }
  }, [selectedContacts, finishSelectContacts, canSelectMultiple, showCreateContactForm]);

  const handleCreatedContact = (contact: Contact) => {
    if (canSelectMultiple) {
      setSelectedContacts([...selectedContacts, contact]);
    } else {
      selectContact?.(contact);
    }
    setShowCreateContactForm(false);
  };

  const handleCancel = () => {
    setShowCreateContactForm(false);
    onCancel();
    form.resetFields();
  };

  const onFinish = () => {
    if (showCreateContactForm) {
      form.submit();
    }
    if (canSelectMultiple && finishSelectContacts && !showCreateContactForm) {
      finishSelectContacts(selectedContacts);
    }
  };

  const handleOnClear = (key?: string) => {
    setSearchValue('');
    resetFiltersAndSort(key);
  };

  const setContactColumns = (columns: CustomColumnType<Contact>[]) => {
    setContactTableColumns(columns);
  };

  const selectPersonModalFooter = () => {
    return [
      <Button key='back' onClick={handleCancel}>
        {translations.shared.selectContactModal.modalCancelButton}
      </Button>,
      <Button key='submit' type='primary' onClick={onFinish}>
        {canSelectMultiple ? translations.addContactPage.buttons.select : translations.addContactPage.buttons.save}
      </Button>,
    ];
  };

  return (
    <Modal
      title={
        showCreateContactForm ? (
          <PageHeader
            style={{ padding: 0 }}
            onBack={() => setShowCreateContactForm(false)}
            title={translations.addContactPage.title}
          />
        ) : (
          <>
            <TitleWithSearchBox
              title={title}
              searchBoxPlaceholder={translations.contactsPage.searchPlaceholder}
              onSearchValueChange={(event) => setSearchValue(event.target.value)}
              onClear={handleOnClear}
              showTooManyResultsWarning={showTooManyResultsWarning}
              tags={mapKeysToColumnTitle(modifiedFields, contactTableColumns)}
            />

            <RightFloatingButton onClick={() => setShowCreateContactForm(true)} type='primary'>
              {translations.shared.selectContactModal.createContact}
            </RightFloatingButton>
          </>
        )
      }
      open={visible}
      onCancel={handleCancel}
      width={windowWidth >= 1200 ? 1200 : 1000}
      style={{ height: '90vh' }}
      footer={selectPersonModalFooter()}
      centered
      maskClosable={false}
    >
      <SaveSpinner isSaving={isSaving}>
        {showCreateContactForm ? (
          <AddContact form={form} setCreatedContact={handleCreatedContact} isModalView />
        ) : (
          <SelectContactModalTable
            selectContact={selectContact}
            searchValue={searchValue}
            excludedIds={excludedContactIds}
            setShowTooManyResultsWarning={setShowTooManyResultsWarning}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            canSelectMultiple={canSelectMultiple}
            selectedRelationshipFilter={selectedRelationshipFilter}
            filterAndSortTableValues={filterAndSortTableValues}
            setContactTableColumns={setContactColumns}
          />
        )}
      </SaveSpinner>
    </Modal>
  );
};
