import { countryProvStateTranslations, countryTranslations } from '../../constants/countryTranslations';
import { Address, BillingAddress } from '../../graph/types';

export class CreditCardUpsertGenerator {
  static mapToSubscriptionContactRecordUpsert(contactId: string, contactName: string, address: Address) {
    return {
      contact_id: contactId,
      name: contactName,
      address_1: address.address_1 || '',
      address_2: address.address_2,
      city: address.city,
      country_id: address.country_id,
      country_name: CreditCardUpsertGenerator.getCountry(address.country_name_key),
      country_prov_state_id: address.country_prov_state_id,
      country_prov_state_name: CreditCardUpsertGenerator.getCountryProvState(address.country_prov_state_name_key),
      postal_zip: address.postal_zip,
    };
  }

  static mapFromBillingAddressToSubscriptionContactRecordUpsert(
    contactId: string,
    contactName: string,
    address: BillingAddress
  ) {
    return {
      contact_id: contactId,
      name: contactName,
      address_1: address.address_1 || '',
      address_2: address.address_2,
      city: address.city,
      country_id: address.country_id,
      country_name: address.country_name,
      country_prov_state_id: address.country_prov_state_id,
      country_prov_state_name: address.country_prov_state_name,
      postal_zip: address.postal_zip,
      email: address.email,
      phone: address.phone,
    };
  }

  private static getCountry(nameKey: string | null | undefined) {
    if (nameKey) {
      return countryTranslations[nameKey as keyof typeof countryTranslations];
    }
    return null;
  }

  private static getCountryProvState(nameKey: string | null | undefined) {
    if (nameKey) {
      return countryProvStateTranslations[nameKey as keyof typeof countryProvStateTranslations];
    }
    return null;
  }
}
