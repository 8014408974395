import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../../util/filterAndSorting';
import { CustomColumnType } from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { displayAsDate } from '../../../../constants/formats';
import { prescriptionPropertyNames } from '../../../../constants/propertyNames';
import { translations } from '../../../../constants/translations';
import { Prescription } from '../../../../graph/types';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { getSimpleCheckbox } from '../../../../util/checkboxUtil';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';

const formatQuantity = (quantity: string) =>
  Number(quantity) % 1 === 0 ? Number(quantity).toFixed(0) : Number(quantity).toFixed(2);

export const useColumns = (): CustomColumnType<Prescription>[] => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const showRefills = useLDFlag(LDFlagNames.PrescriptionRefills);

  return [
    {
      title: translations.patientPage.prescriptions.columns.number,
      key: prescriptionPropertyNames.number,
      dataIndex: prescriptionPropertyNames.number,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.number),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.number),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.number
      ),
      width: 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.date,
      key: prescriptionPropertyNames.prescribed_date,
      dataIndex: prescriptionPropertyNames.prescribed_date,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.prescribed_date),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.prescribed_date),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.date
      ),
      render: (date) => displayAsDate(date, dateFormat),
      width: 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.expiryDate,
      key: prescriptionPropertyNames.prescription_expiry_date,
      dataIndex: prescriptionPropertyNames.prescription_expiry_date,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.prescription_expiry_date),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.prescription_expiry_date),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.expiryDate
      ),
      render: (date) => displayAsDate(date, dateFormat),
      width: 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.description,
      key: prescriptionPropertyNames.item_description,
      dataIndex: prescriptionPropertyNames.item_description,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.item_description),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.item_description),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.description
      ),
      width: 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.quantity,
      key: prescriptionPropertyNames.item_quantity,
      dataIndex: prescriptionPropertyNames.item_quantity,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.item_quantity),
      sorter: getNumberCompareFunctionFor(prescriptionPropertyNames.item_quantity),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.quantity
      ),
      width: 200,
      render: formatQuantity,
    },
    {
      title: translations.patientPage.prescriptions.columns.unit,
      key: prescriptionPropertyNames.item_unit,
      dataIndex: prescriptionPropertyNames.item_unit,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.item_unit),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.item_unit),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.unit
      ),
      width: 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.filled,
      key: prescriptionPropertyNames.is_filled,
      dataIndex: prescriptionPropertyNames.is_filled,
      ...generalFilteringAndSortingSettings,
      filters: [
        { text: translations.patientPage.prescriptions.filters.filled, value: true },
        { text: translations.patientPage.prescriptions.filters.unfilled, value: false },
      ],
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.is_filled),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.is_filled),
      render: getSimpleCheckbox,
      width: showRefills ? 100 : 200,
    },
    {
      title: translations.patientPage.prescriptions.columns.refills,
      key: prescriptionPropertyNames.refills,
      dataIndex: prescriptionPropertyNames.refills,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.refills),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.refills),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.refills
      ),
      width: 100,
      hidden: !showRefills,
    },
    {
      title: translations.patientPage.prescriptions.columns.remaining_fills,
      key: prescriptionPropertyNames.remaining_fills,
      dataIndex: prescriptionPropertyNames.remaining_fills,
      ...generalFilteringAndSortingSettings,
      onFilter: getOnFilterFunctionFor(prescriptionPropertyNames.remaining_fills),
      sorter: getStringCompareFunctionFor(prescriptionPropertyNames.remaining_fills),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.patientPage.prescriptions.columns.remaining_fills
      ),
      width: 100,
      hidden: !showRefills,
    },
    {
      title: translations.patientPage.prescriptions.columns.actions,
      key: 'actions',
    },
  ];
};
