import { invoicesInitialState, invoicesReducer, InvoicesState } from './reducer';
import { InvoicesAction } from './actions';
import React from 'react';

export const InvoicesContext = React.createContext<{ state: InvoicesState; dispatch: React.Dispatch<InvoicesAction> }>(
  {} as any
);

export const InvoicesContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const fullInitialState = {
    ...invoicesInitialState,
  };
  const [state, dispatch] = React.useReducer(invoicesReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <InvoicesContext.Provider value={value}>{props.children}</InvoicesContext.Provider>;
};
