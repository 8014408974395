import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Select, Switch, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { StyledOptionContent, w12 } from '../../../../globalStyles.style';
import { translations } from '../../../../constants/translations';
import {
  getPositiveValueRule,
  getRequiredRule,
  getRequiredRuleWithCustomMessage,
  getValidRangeRule,
  getValidRegexRule,
} from '../../../../util/forms';
import { SelectOptions } from '../../../DependentDropdown/DependentDropdown';
import {
  CreditCard,
  InvoiceContact,
  Ledger,
  LedgerPaymentTypeDto,
  PaymentDeviceType,
  PaymentOption,
} from '../../../../graph/types';
import { paymentTypeConfigs, PaymentTypeNameKey } from '../../../../constants/referenceData/paymentReferenceData';
import { CreditCardTable, CreditCardTableProps } from './CreditCardTable';
import { LedgerPaymentTypeValue } from '../../../../constants/referenceData/ledgerReferenceData';
import dayjs from 'dayjs';
import { SelectProps } from 'antd/lib/select';
import { joinNameAndNumber } from '../../../../util/displaying';
import { isNil } from 'lodash';
import { MaxLengthFormItem } from '../../../MaxLengthFormItem/MaxLengthFormItem';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import styled from 'styled-components';

const WarningIcon = styled(ExclamationCircleFilled)`
  margin-right: 12px;
  color: #faad14;
  font-size: 16pt;
`;

const ConfirmMessage = styled(Col)`
  margin-bottom: 12px;
`;

const PermissionText = styled.span`
  margin-left: 10px;
`;

export interface PaymentModalFormFields {
  date: dayjs.Dayjs;
  payer: string;
  amount: number;
  method: string;
  check?: number;
  device?: string;
  saveCard?: boolean;
  cardNote?: string;
  paymentOption?: PaymentOption;
}

export interface PaymentOptionItem {
  value: PaymentOption;
  label: string;
  tooltip: () => JSX.Element;
}

export const PaymentOptions: PaymentOptionItem[] = [
  {
    label: translations.paymentModal.cardHolderTransactionLabel,
    value: PaymentOption.Arranged,
    tooltip: () => (
      <div>
        <p>{translations.paymentModal.cardHolderTransactionTooltip.title}</p>
        <p>{translations.paymentModal.cardHolderTransactionTooltip.bullet1}</p>
        <p>{translations.paymentModal.cardHolderTransactionTooltip.bullet2}</p>
      </div>
    ),
  },
  {
    label: translations.paymentModal.merchantTransactionLabel,
    value: PaymentOption.Phone,
    tooltip: () => (
      <div>
        <p>{translations.paymentModal.merchantTransactionTooltip.title}</p>
        <p>{translations.paymentModal.merchantTransactionTooltip.bullet1}</p>
        <p>{translations.paymentModal.merchantTransactionTooltip.bullet2}</p>
      </div>
    ),
  },
];

interface PaymentFormProps extends CreditCardTableProps {
  form: FormInstance;
  initialFormData?: Store | undefined;
  handleFormValueChange: (_: Store, allValues: Store) => void;
  paymentType: LedgerPaymentTypeDto | undefined;
  paymentMethods: LedgerPaymentTypeDto[];
  paymentDeviceTypes: PaymentDeviceType[];
  isElectronicPayment?: boolean;
  isInEditModal?: boolean;
  ledgers?: Ledger[];
  contacts?: InvoiceContact[];
  setSelectedContact?: (contact: InvoiceContact) => void;
}

const getMethodOption = (paymentType: LedgerPaymentTypeDto): SelectOptions | undefined => {
  const config = paymentTypeConfigs[paymentType.name_key as PaymentTypeNameKey];
  return config ? { value: paymentType.id, label: config.text } : undefined;
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
  contactId,
  form,
  initialFormData,
  handleFormValueChange,
  paymentType,
  setCreditCard,
  creditCard,
  paymentMethods,
  paymentDeviceTypes,
  isElectronicPayment = false,
  isInEditModal = false,
  ledgers = [],
  contacts = [],
  setSelectedContact,
  setIsSaving,
  isStripeProcessor,
}) => {
  const [saveCardChecked, setSaveCardChecked] = useState<boolean>(initialFormData?.saveCard ?? false);
  const [showSaveCardConfirmModal, setShowSaveCardConfirmModal] = useState<boolean>(false);
  const [modalForm] = useForm();

  const layout = useMemo(() => {
    if (!contacts || contacts.length === 1) {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
      };
    }
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
    };
  }, [contacts]);

  const handleContactChange: SelectProps<string>['onChange'] = useCallback(
    (value: string) => {
      const ledger = ledgers?.find((l) => l.contact_id === value);
      const contact = contacts?.find((c) => c.contact_id === value);
      if (contact && ledger) {
        const financialOutstanding = ledger.financial_outstanding;
        const outstandingAmount = isNil(financialOutstanding) ? undefined : Number(financialOutstanding);
        form.setFieldsValue({ amount: outstandingAmount });
        setSelectedContact?.(contact);
      }
    },
    [contacts, form, setSelectedContact, ledgers]
  );

  const handleSetSaveCardCheck = useCallback(
    (value: boolean) => {
      setSaveCardChecked(value);
      form.setFieldsValue({ saveCard: value });
      form.setFieldsValue({ saveCardConfirm: value });
      modalForm.setFieldsValue({ saveCardConfirmModal: value });
    },
    [form, modalForm]
  );

  const handleSaveCardConfirm = useCallback(() => {
    handleSetSaveCardCheck(true);
    setShowSaveCardConfirmModal(false);
  }, [handleSetSaveCardCheck]);

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const renderedContactSelect = useMemo(() => {
    if (!contacts || contacts.length <= 1) {
      return null;
    }
    const contactsOptions: SelectProps<string>['options'] = contacts.map((contact) => ({
      label: joinNameAndNumber({ name: contact.contact_name, number: contact.contact_number }),
      value: contact.contact_id,
    }));
    return (
      <Form.Item name='contact' label={translations.paymentModal.fields.contact}>
        <Select options={contactsOptions} onChange={handleContactChange} />
      </Form.Item>
    );
  }, [contacts, handleContactChange]);

  const parsedInitialFormData = useMemo(
    () =>
      contacts?.length
        ? {
            ...initialFormData,
            contact: contactId,
          }
        : initialFormData,
    [contactId, contacts, initialFormData]
  );

  const paymentMethodOptions = paymentMethods
    .map((paymentMethod) => getMethodOption(paymentMethod))
    .filter((paymentMethodOption: SelectOptions | undefined) => paymentMethodOption) as SelectOptions[];

  const paymentDeviceOptions = paymentDeviceTypes.map((deviceType) => ({
    label: deviceType.name,
    value: deviceType.id,
  }));

  const { Option } = Select;
  const paymentOptionsWithTooltip = PaymentOptions.map((option) => (
    <Option key={option.value} value={option.value}>
      <Tooltip title={option.tooltip} placement='right'>
        <StyledOptionContent>{option.label}</StyledOptionContent>
      </Tooltip>
    </Option>
  ));

  function isElectronicPaymentAndNoCreditCardSelected(paymentType?: LedgerPaymentTypeDto, creditCard?: CreditCard) {
    return paymentType && paymentType.electronic_type_id !== 0 && !creditCard;
  }

  function isPaymentTypeNotCard(paymentType?: LedgerPaymentTypeDto) {
    return ![
      LedgerPaymentTypeValue.Visa,
      LedgerPaymentTypeValue.MasterCard,
      LedgerPaymentTypeValue.Amex,
      LedgerPaymentTypeValue.Debit,
      LedgerPaymentTypeValue.Discover,
      LedgerPaymentTypeValue.Credit,
    ].includes(paymentType?.id as LedgerPaymentTypeValue);
  }

  const handleConfirmModalOK = async () => {
    try {
      await modalForm.validateFields();
    } catch (e) {
      return;
    }
    handleSaveCardConfirm();
  };

  const handleConfirmModalCancel = () => {
    setShowSaveCardConfirmModal(false);
    modalForm.setFieldsValue({ saveCardConfirmModal: false });
  };

  const handleHasPersmissionChange = (e: any) => {
    modalForm.setFieldsValue({ saveCardConfirmModal: e.target.checked });
  };

  const handleModalHasPersmissionChange = (e: any) => {
    form.setFieldsValue({ saveCardConfirm: e.target.checked });
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        initialValues={parsedInitialFormData}
        onValuesChange={handleFormValueChange}
        autoComplete='off'
      >
        {renderedContactSelect}
        <Form.Item
          name='date'
          label={translations.paymentModal.fields.date}
          rules={[getRequiredRule(translations.paymentModal.fields.date)]}
          data-testid={'EffectiveDateRangePicker'}
        >
          <DatePicker
            format={dateFormat}
            disabled={isElectronicPayment}
            style={w12}
            disabledDate={(date) => date.isAfter(dayjs())}
          />
        </Form.Item>
        <MaxLengthFormItem name='payer' label={translations.paymentModal.fields.payer} maxLength={255}>
          <Input autoComplete='new-password' />
        </MaxLengthFormItem>
        <Form.Item
          name='amount'
          label={translations.paymentModal.fields.amount}
          shouldUpdate={(prevValues, nextValues) => prevValues.contact !== nextValues.contact}
          rules={[
            getRequiredRule(translations.paymentModal.fields.amount),
            getPositiveValueRule(translations.paymentModal.fields.amount),
          ]}
        >
          <InputNumber precision={2} style={w12} disabled={isElectronicPayment} />
        </Form.Item>
        <Form.Item
          name='method'
          label={translations.paymentModal.fields.method}
          rules={[getRequiredRule(translations.paymentModal.fields.method)]}
        >
          <Select
            style={w12}
            data-testid={'methodSelector'}
            placeholder={translations.paymentModal.methodPlaceholder}
            disabled={isInEditModal}
            options={paymentMethodOptions}
          />
        </Form.Item>
        <Form.Item
          name='paymentOption'
          rules={[
            {
              required: paymentType !== undefined && paymentType.id === LedgerPaymentTypeValue.Credit && !!creditCard,
              message: translations.shared.getIsRequiredMessage(translations.paymentModal.fields.paymentOption),
            },
          ]}
          label={translations.paymentModal.fields.paymentOption}
          hidden={
            isElectronicPaymentAndNoCreditCardSelected(paymentType, creditCard) ||
            paymentType?.id !== LedgerPaymentTypeValue.Credit
          }
        >
          <Select placeholder={translations.paymentModal.paymentOptionPlaceHolder}>{paymentOptionsWithTooltip}</Select>
        </Form.Item>
        <MaxLengthFormItem
          name='check'
          label={translations.paymentModal.fields.check}
          rules={[
            {
              required: paymentType !== undefined && paymentType.id === LedgerPaymentTypeValue.Check,
              message: translations.shared.getIsRequiredMessage(translations.paymentModal.fields.check),
            },
            getValidRangeRule(3, 10, translations.paymentModal.validation.checkFieldLength),
            getValidRegexRule(new RegExp(/^[a-zA-Z\d]*$/), translations.paymentModal.validation.checkFieldNumbersOnly),
          ]}
          hidden={!paymentType || paymentType.id !== LedgerPaymentTypeValue.Check}
          maxLength={255}
        >
          <Input />
        </MaxLengthFormItem>
        <Form.Item
          name='device'
          label={translations.paymentModal.fields.device}
          hidden={!isElectronicPaymentAndNoCreditCardSelected(paymentType, creditCard) || isStripeProcessor}
        >
          <Select
            data-testid={'deviceSelector'}
            options={paymentDeviceOptions}
            placeholder={translations.paymentModal.devicePlaceholder}
            disabled={isElectronicPayment}
          />
        </Form.Item>
        <Form.Item
          name='saveCard'
          label={translations.paymentModal.fields.saveCard}
          hidden={!isElectronicPaymentAndNoCreditCardSelected(paymentType, creditCard) || isInEditModal}
        >
          <Switch
            checked={saveCardChecked ?? false}
            onClick={() => {
              saveCardChecked ? handleSetSaveCardCheck(false) : setShowSaveCardConfirmModal(true);
            }}
          />
        </Form.Item>
        {saveCardChecked && !isPaymentTypeNotCard(paymentType) && (
          <MaxLengthFormItem maxLength={100} name='cardNote' label={translations.paymentModal.fields.cardNote}>
            <Input />
          </MaxLengthFormItem>
        )}
        {saveCardChecked && !isPaymentTypeNotCard(paymentType) && (
          <>
            <ConfirmMessage offset={7}>{translations.paymentModal.fields.saveCardConfirmMessage}</ConfirmMessage>
            <Form.Item
              label={' '}
              colon={false}
              labelAlign={'right'}
              name='saveCardConfirm'
              valuePropName='checked'
              requiredMark
              rules={[
                getRequiredRuleWithCustomMessage(
                  translations.paymentModal.fields.saveCardConfirmCheckboxValidationMessage
                ),
              ]}
            >
              <Checkbox onChange={handleHasPersmissionChange}>
                {translations.paymentModal.fields.saveCardConfirmCheckboxMessage}
              </Checkbox>
            </Form.Item>
          </>
        )}
      </Form>
      {!isInEditModal && !isPaymentTypeNotCard(paymentType) && (
        <CreditCardTable
          contactId={contactId}
          setCreditCard={setCreditCard}
          creditCard={creditCard}
          setIsSaving={setIsSaving}
          isStripeProcessor={isStripeProcessor}
        />
      )}
      <Modal
        onOk={handleConfirmModalOK}
        onCancel={handleConfirmModalCancel}
        closable={false}
        open={showSaveCardConfirmModal}
        okText={translations.shared.okButtonText}
        cancelText={translations.shared.cancelButtonText}
      >
        <p>
          <span>
            <WarningIcon />
          </span>
          <span>{translations.paymentModal.fields.saveCardConfirmMessage}</span>
        </p>
        <Form form={modalForm}>
          <Form.Item
            label={''}
            colon={false}
            labelAlign={'right'}
            name='saveCardConfirmModal'
            valuePropName='checked'
            requiredMark
            rules={[
              getRequiredRuleWithCustomMessage(
                translations.paymentModal.fields.saveCardConfirmCheckboxValidationMessage
              ),
            ]}
          >
            <Checkbox onChange={handleModalHasPersmissionChange}>
              <PermissionText>{translations.paymentModal.fields.saveCardConfirmCheckboxMessage}</PermissionText>
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
