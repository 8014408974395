import { SubscriptionDetailsProperty, subscriptionPropertyNames } from '../../constants/propertyNames';
import { translations } from '../../constants/translations';
import { OrganizationSubscriptionDetailCharge } from '../../graph/types';
import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import React, { useMemo } from 'react';
import SubscriptionCurrencyFormatter from '../CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export const useSubscriptionUpdateColumn = (
  countryId?: string | null
): Record<SubscriptionDetailsProperty | 'addQty', CustomColumnType<OrganizationSubscriptionDetailCharge>> => {
  return useMemo(
    () => ({
      level_name_key: {
        title: translations.subscriptionUpdateModal.tableColumns.subscription,
        dataIndex: subscriptionPropertyNames.level_name_key,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.subscriptionUpdateModal.tableColumns.subscription
        ),
        onFilter: getOnFilterFunctionFor(subscriptionPropertyNames.level_name_key),
        sorter: getStringCompareFunctionFor(subscriptionPropertyNames.level_name_key),
        width: 200,
        render: (levelNameKey: string) =>
          organizationSubscriptionLevelConfigs[levelNameKey as OrganizationSubscriptionLevelNameKeys]?.text ||
          levelNameKey,
      },
      currentQty: {
        title: translations.subscriptionUpdateModal.tableColumns.currentQty,
        ...generalFilteringAndSortingSettings,
        sorter: getNumberCompareFunctionFor(subscriptionPropertyNames.currentQty),
        width: 100,
      },
      addQty: {
        title: translations.subscriptionUpdateModal.tableColumns.additionalQty,
        ...generalFilteringAndSortingSettings,
        width: 100,
      },
      cost: {
        title: translations.subscriptionUpdateModal.tableColumns.price,
        ...generalFilteringAndSortingSettings,
        dataIndex: subscriptionPropertyNames.cost,
        render: (value) =>
          value ? (
            <>
              x <SubscriptionCurrencyFormatter total={Number(value)} countryId={countryId} />
            </>
          ) : (
            '-'
          ),
        sorter: getNumberCompareFunctionFor(subscriptionPropertyNames.cost),
        width: 100,
      },
      total: {
        title: translations.subscriptionUpdateModal.tableColumns.total,
        ...generalFilteringAndSortingSettings,
        dataIndex: subscriptionPropertyNames.total,
        render: (value) =>
          value ? <SubscriptionCurrencyFormatter total={Number(value)} countryId={countryId} /> : '-',
        sorter: getNumberCompareFunctionFor(subscriptionPropertyNames.total),
        width: 100,
      },
    }),
    [countryId]
  );
};
