import { Button, Space, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { OptionsWithSpeciesNumber } from '../../../components/DependentDropdown/DependentDropdown';
import { DropdownButtonWithMenu } from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { TableWithCustomFiltering } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { CombinedTaxonomy, getDeviceUpsert, getTypeProperties, taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { translations } from '../../../constants/translations';
import { useUpsertLabDevice } from '../../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { TableFilterAndSortKey, TableKey, useTableResetFilterAndSort } from '../../../hooks/tableHooks';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { LabBreedMapping, LabGenderMapping } from '../../../graph/types';
import { StyledOptionContent } from '../../../globalStyles.style';
import { LabManagementContext } from '../store/state';
import { setChangesMade } from '../store/actions';

type TaxonomyTableProps = {
  dataSource: CombinedTaxonomy[];
  type: TaxonomyType;
  options: OptionsWithSpeciesNumber[];
  typeTranslations: Record<string, string>;
  deviceId: string;
};

const WidthSelect = styled(Select<string>)`
  width: 200px;
`;

const { Option } = Select;

export const taxMappedTestId = 'tax-mapped-test-id';

export const TaxonomyTable: React.FC<TaxonomyTableProps> = ({
  dataSource,
  type,
  options,
  typeTranslations,
  deviceId,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [editingId, setEditingId] = useState<string>();
  const [selectedSpecies, setSelectedSpecies] = useState<OptionsWithSpeciesNumber>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [updateLabDevice] = useUpsertLabDevice(organizationId, type);
  const { filteredValue, sortOrderMap, tableChangeHandler } = useTableResetFilterAndSort(
    TableFilterAndSortKey.TaxonomyTable
  );

  const { labTypeName, labTypeNumber, cassadolTypeId, typeMap, cassadolTypeName } = getTypeProperties(type);

  const editingIdForEntry = useCallback(
    (entry: CombinedTaxonomy) => `${entry[cassadolTypeId]}+${entry.id}`,
    [cassadolTypeId]
  );
  const editingIdForUpsert = useMemo(() => editingId?.split('+')?.[0], [editingId]);

  const { dispatch } = useContext(LabManagementContext);

  const handleSave = async (entry: CombinedTaxonomy) => {
    if (!selectedSpecies) {
      return setError(true);
    }

    setLoading(true);
    try {
      const deviceUpsert = getDeviceUpsert(
        deviceId,
        typeMap,
        entry,
        cassadolTypeId,
        labTypeName,
        labTypeNumber,
        selectedSpecies,
        editingIdForUpsert,
        type
      );

      await updateLabDevice({
        variables: {
          organizationId,
          labDevice: deviceUpsert,
        },
      });
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      showErrorMessage(e.message);
    }
    setEditingId(undefined);
    setIsTableEditing(false);

    dispatch(setChangesMade(false));

    return setLoading(false);
  };

  const onSelectChange = (value: SelectValue, entry: any) => {
    setSelectedSpecies({ value: value?.toString() ?? '', label: entry.label });
  };

  const onTableCellClick = (entry?: CombinedTaxonomy) => {
    if (!editingId && !isTableEditing && entry !== undefined) {
      setEditingId(editingIdForEntry(entry) as string);
      setIsTableEditing(true);
      dispatch(setChangesMade(true));
    } else if (entry === undefined) {
      setEditingId(undefined);
      setIsTableEditing(false);
      dispatch(setChangesMade(false));
    }
  };

  const columns = [
    {
      ...generalFilteringAndSortingSettings,
      sorter: getStringCompareFunctionFor(cassadolTypeName),
      onFilter: getOnFilterFunctionFor(cassadolTypeName),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(typeTranslations.title),
      title: typeTranslations.title,
      dataIndex: cassadolTypeName,
      key: cassadolTypeName,
      filteredValue: filteredValue[cassadolTypeName] ?? null,
      sortOrder: sortOrderMap[cassadolTypeName],
      width: 400,
    },
    ...(type !== TaxonomyType.Species && type !== TaxonomyType.Service
      ? [
          {
            ...generalFilteringAndSortingSettings,
            sorter: getStringCompareFunctionFor('lab_species_name'),
            onFilter: getOnFilterFunctionFor('lab_species_name'),
            filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
              taxonomyTranslations.tabs.species.labType
            ),
            title: taxonomyTranslations.tabs.species.labType,
            dataIndex: 'lab_species_name',
            key: 'lab_species_name',
            filteredValue: filteredValue.lab_species_name ?? null,
            sortOrder: sortOrderMap.lab_species_name,
            width: 400,
          },
        ]
      : []),
    {
      ...generalFilteringAndSortingSettings,
      filteredValue: filteredValue[labTypeName] ?? null,
      sortOrder: sortOrderMap[labTypeName],
      onFilter: getOnFilterFunctionFor(labTypeName),
      sorter: getStringCompareFunctionFor(labTypeName),
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(taxonomyTranslations.tabs.species.labType),
      title: typeTranslations.labType,
      dataIndex: labTypeName,
      key: labTypeName,
      render: (value: string, entry: CombinedTaxonomy) => {
        const testId = `${labTypeName}_${entry[cassadolTypeId]}`;
        const filteredOptions =
          type !== TaxonomyType.Species && type !== TaxonomyType.Service
            ? options.filter(
                ({ speciesnumber }) =>
                  speciesnumber === (entry as LabBreedMapping | LabGenderMapping).lab_species_number ||
                  speciesnumber === null
              )
            : options;
        const optionsWithTooltip = filteredOptions?.map((s) => (
          <Option key={s.value} value={s.value} label={s.label} title={s.label}>
            <StyledOptionContent>{s.label}</StyledOptionContent>
          </Option>
        ));
        return (
          <>
            {editingId === editingIdForEntry(entry) ? (
              <>
                <WidthSelect
                  {...searchableSelectParams}
                  placeholder={typeTranslations.selectPlaceholder}
                  onChange={onSelectChange}
                  defaultValue={entry[labTypeNumber] as string}
                  data-testid={testId}
                >
                  {optionsWithTooltip}
                </WidthSelect>
                {error && <ErrorMessage message={translations.shared.getIsRequiredMessage(type)} />}
              </>
            ) : (
              <span onClick={() => onTableCellClick(entry)} data-testid={taxMappedTestId}>
                {value ?? ''}
              </span>
            )}
          </>
        );
      },
      width: 300,
    },
    {
      title: taxonomyTranslations.tabs.action,
      key: 'action',
      render: (entry: CombinedTaxonomy) => {
        return (
          <>
            {editingId === editingIdForEntry(entry) ? (
              <Space direction={'horizontal'}>
                <Button type='link' onClick={() => handleSave(entry)}>
                  {translations.shared.saveButtonText}
                </Button>
                <Button type='link' onClick={() => onTableCellClick(undefined)}>
                  {translations.shared.cancelButtonText}
                </Button>
              </Space>
            ) : (
              <DropdownButtonWithMenu
                menuItemProps={[
                  {
                    title: translations.shared.editButtonText,
                    onClick: (e) => {
                      e?.stopPropagation();
                      onTableCellClick(entry);
                    },
                  },
                ]}
              />
            )}
          </>
        );
      },
      width: 200,
    },
  ];

  return (
    <TableWithCustomFiltering
      tableKey={TableKey.Taxonomy}
      dataSource={dataSource}
      columns={columns}
      rowKey={(record) => `${record[cassadolTypeId]}-${record.id}`}
      onChange={tableChangeHandler}
      loading={loading}
      pagination={dataSource.length > 10}
    />
  );
};
