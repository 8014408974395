import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Space } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { translations } from '../../constants/translations';
import { Patient } from '../../graph/types';
import { useTableResetFilterAndSort } from '../../hooks/tableHooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { AddPatient, AddPatientProps } from '../../pages/Patients/AddPatient/AddPatient';
import { PatientsContext } from '../../pages/Patients/store/state';
import { PatientOwnership } from '../../pages/Patients/ViewPatient/PatientOwnership/PatientOwnership';
import { mapKeysToColumnTitle } from '../../util/mapUtil';
import { ButtonWithPopconfirm } from '../ButtonWithPopconfirm/ButtonWithPopconfirm';
import ColumnDisplayFilter from '../ColumnDisplayFilter/ColumnDisplayFilter';
import { ColumnDisplayFilterContext, ColumnDisplayFilterContextProvider } from '../ColumnDisplayFilter/store/state';
import { StyledPageHeaderWithMargin } from '../PageHeader/PageHeader.style';
import { SelectPersonModalProps } from '../SelectContactModal/SelectContactModal';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../TitleWithSearchBox/TitleWithSearchBox';
import { ViewSubscriptionContextProvider } from '../ViewSubscription/store/state';
import { WithPracticeDto } from '../WithPractice/WithPractice';
import { SelectContactPatientTableContainer } from './SelectContactPatientTableContainer';
import { SelectOrganizationPatientTableContainer } from './SelectOrganizationPatientTableContainer';

const StyledColumnDisplayFilter = styled(ColumnDisplayFilter)`
  margin-left: 0.5rem;
`;

const RightAlignedSpace = styled(Space)`
  float: right;
  padding-top: 14px;
  margin-right: 2rem;
`;

enum ModalStep {
  SelectPatient = 'select patient',
  CreatePatient = 'create patient',
  CreateOwnership = 'create ownership',
}

interface SelectPatientModalProps extends SelectPersonModalProps {
  finishSelectPatients: (patient: Patient[]) => void;
  contactId?: string;
  canSelectMultiple?: boolean;
  initialSelectedPatients?: Patient[];
  excludedPatientId?: string;
}

const OwnershipModalConfirmButton = ({
  onClickCallback,
  disabled,
  content,
  isEditing,
}: {
  onClickCallback: () => void;
  disabled: boolean;
  content: string;
  isEditing: boolean;
}) => {
  return (
    <>
      {isEditing ? (
        <ButtonWithPopconfirm
          key='save'
          onClickCallback={onClickCallback}
          isDisabled={disabled}
          buttonText={content}
          popconfirmTitle={translations.shared.selectPatientModal.currentlyEditingOwnersPopConfirm}
          buttonType='primary'
        />
      ) : (
        <Button key='save' onClick={onClickCallback} disabled={disabled} type='primary'>
          {content}
        </Button>
      )}
    </>
  );
};

const SelectPatientModal: React.FC<SelectPatientModalProps> = ({
  title,
  contactId,
  onCancel,
  finishSelectPatients,
  canSelectMultiple,
  initialSelectedPatients,
  excludedPatientId,
}: SelectPatientModalProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [modalState, setModalState] = useState<ModalStep>(ModalStep.SelectPatient);
  const { windowWidth } = useWindowDimensions();
  const [selectedPatients, setSelectedPatients] = useState<Patient[]>(initialSelectedPatients || []);
  const [showTooManyResultsWarning, setShowTooManyResultsWarning] = useState(false);
  const functionRef = useRef<(() => void) | undefined>();
  const {
    state: { editingIndex },
  } = useContext(PatientsContext);

  const {
    state: { columnDisplayFilterProps },
  } = useContext(ColumnDisplayFilterContext);

  const filterAndSortTableValues = useTableResetFilterAndSort();
  const [patientTableColumns, setPatientTableColumns] = useState<CustomColumnType<Patient>[]>([]);

  useEffect(() => {
    if (
      !canSelectMultiple &&
      selectedPatients &&
      selectedPatients.length > 0 &&
      modalState === ModalStep.SelectPatient
    ) {
      finishSelectPatients(selectedPatients);
    }
  }, [selectedPatients, finishSelectPatients, canSelectMultiple, modalState]);

  const onFinishedSelections = () => {
    finishSelectPatients(selectedPatients);
  };

  const getModalFooter = () => {
    return (
      <>
        {modalState === ModalStep.SelectPatient && (
          <>
            <Button key='back' onClick={onCancel}>
              {translations.shared.selectPatientModal.modalCancelButton}
            </Button>
            {canSelectMultiple && (
              <Button
                key='select'
                onClick={onFinishedSelections}
                disabled={selectedPatients.length === 0}
                type='primary'
              >
                {translations.shared.selectPatientModal.modalSelectButton}
              </Button>
            )}
          </>
        )}
        {modalState === ModalStep.CreateOwnership && (
          <>
            <ButtonWithPopconfirm
              key='back'
              onClickCallback={onCancel}
              buttonText={translations.shared.selectPatientModal.modalCancelButton}
              popconfirmTitle={translations.invoicesPage.addInvoiceModal.ownershipCancelText}
            />
            <OwnershipModalConfirmButton
              onClickCallback={
                canSelectMultiple
                  ? () => {
                      setSelectedPatients(selectedPatients);
                      setModalState(ModalStep.SelectPatient);
                    }
                  : onFinishedSelections
              }
              disabled={selectedPatients.length === 0}
              content={
                canSelectMultiple
                  ? translations.shared.selectPatientModal.modalSelectButton
                  : translations.invoicesPage.addInvoiceModal.title
              }
              isEditing={editingIndex !== -1}
            />
          </>
        )}
        {modalState === ModalStep.CreatePatient && (
          <>
            <Button key='back' onClick={onCancel}>
              {translations.shared.selectPatientModal.modalCancelButton}
            </Button>
            <Button
              key='next'
              onClick={async () => {
                await functionRef?.current?.();
              }}
              type='primary'
            >
              {translations.shared.nextButtonText}
            </Button>
          </>
        )}
      </>
    );
  };

  const handleOnClear = (key?: string) => {
    setSearchValue('');
    filterAndSortTableValues.resetFiltersAndSort(key);
    columnDisplayFilterProps?.resetDisplayedColumnsToDefault();
  };

  const addOwnershipToNewPatient = useCallback(
    (patient: Patient) => {
      const updatedPatients = selectedPatients.map((pat) => {
        if (pat.id === patient.id || pat.offline_id === patient.offline_id) {
          return patient;
        }
        return pat;
      });

      setSelectedPatients(updatedPatients);
    },
    [selectedPatients]
  );

  const getTitle = () => {
    switch (modalState) {
      case ModalStep.SelectPatient:
        return (
          <>
            <TitleWithSearchBox
              key={'titleWithSearchBox'}
              title={title}
              searchBoxPlaceholder={translations.shared.selectPatientModal.searchPlaceholder}
              onSearchValueChange={(event) => setSearchValue(event.target.value)}
              onClear={handleOnClear}
              showTooManyResultsWarning={showTooManyResultsWarning}
              tags={mapKeysToColumnTitle(filterAndSortTableValues.modifiedFields, patientTableColumns)}
            />
            <RightAlignedSpace align='end'>
              {columnDisplayFilterProps && (
                <StyledColumnDisplayFilter key={'columnDisplayFilter'} {...columnDisplayFilterProps} />
              )}
              <Button
                key={'createPatientButton'}
                onClick={() => setModalState(ModalStep.CreatePatient)}
                style={{ margin: '0 0.5rem' }}
                type='primary'
              >
                {translations.shared.selectPatientModal.createPatient}
              </Button>
            </RightAlignedSpace>
          </>
        );
      case ModalStep.CreateOwnership:
        return <StyledPageHeaderWithMargin title={translations.patientPage.ownership.addOwnershipModal.title} />;
      default:
        return null;
    }
  };

  const addPatientCallback = useCallback(
    (props: AddPatientProps) => (
      <AddPatient
        {...props}
        onBack={() => setModalState(ModalStep.SelectPatient)}
        onSuccess={(patient) => {
          setSelectedPatients([...selectedPatients, patient]);
          setModalState(ModalStep.CreateOwnership);
        }}
        functionRef={functionRef}
        isModal
      />
    ),
    [functionRef, selectedPatients]
  );

  const getContent = useCallback(() => {
    switch (modalState) {
      case ModalStep.CreatePatient:
        return <WithPracticeDto Component={addPatientCallback} />;
      case ModalStep.SelectPatient:
        return contactId ? (
          <SelectContactPatientTableContainer
            setSelectedPatients={setSelectedPatients}
            selectedPatients={selectedPatients}
            searchValue={searchValue}
            contactId={contactId}
            canSelectMultiple={canSelectMultiple}
            setShowTooManyResultsWarning={setShowTooManyResultsWarning}
            filterAndSortTableValues={filterAndSortTableValues}
            excludedPatientId={excludedPatientId}
            setPatientTableColumns={setPatientTableColumns}
          />
        ) : (
          <SelectOrganizationPatientTableContainer
            setSelectedPatients={setSelectedPatients}
            selectedPatients={selectedPatients}
            searchValue={searchValue}
            canSelectMultiple={canSelectMultiple}
            setShowTooManyResultsWarning={setShowTooManyResultsWarning}
            filterAndSortTableValues={filterAndSortTableValues}
            excludedPatientId={excludedPatientId}
            setPatientTableColumns={setPatientTableColumns}
          />
        );
      case ModalStep.CreateOwnership:
        return (
          <ViewSubscriptionContextProvider>
            <PatientOwnership
              patient={selectedPatients[selectedPatients.length - 1]}
              onSuccess={addOwnershipToNewPatient}
            />
          </ViewSubscriptionContextProvider>
        );
      default:
        return null;
    }
  }, [
    modalState,
    addOwnershipToNewPatient,
    canSelectMultiple,
    contactId,
    filterAndSortTableValues,
    searchValue,
    selectedPatients,
    excludedPatientId,
    addPatientCallback,
  ]);

  const getCloseIcon = () => {
    if (modalState === ModalStep.CreateOwnership) {
      return (
        <Popconfirm
          icon={<ExclamationCircleOutlined />}
          title={translations.invoicesPage.addInvoiceModal.ownershipCancelText}
          okText={translations.shared.popconfirm.ok}
          cancelText={translations.shared.selectPatientModal.modalCancelButton}
          placement={'bottomRight'}
          onConfirm={onCancel}
        >
          <CloseOutlined />
        </Popconfirm>
      );
    }
    return <CloseOutlined />;
  };

  return (
    <Modal
      title={getTitle()}
      open
      onCancel={modalState !== ModalStep.CreateOwnership ? onCancel : undefined}
      style={{ height: '90vh', minHeight: '90vh' }}
      width={windowWidth <= 1200 ? 1000 : 1200}
      className={`SelectPatientModal ${canSelectMultiple ? 'SelectPatientModal--multiple' : ''}`}
      footer={getModalFooter()}
      maskClosable={false}
      centered
      closeIcon={getCloseIcon()}
    >
      {getContent()}
    </Modal>
  );
};

const SelectPatientModalWithContext = (props: SelectPatientModalProps) => (
  <ColumnDisplayFilterContextProvider>
    <SelectPatientModal {...props} />
  </ColumnDisplayFilterContextProvider>
);

export default SelectPatientModalWithContext;
