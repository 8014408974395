import OwnershipTag from './OwnershipTag/OwnershipTag';
import React from 'react';
import { PriceValue } from '../../../../../components/PriceValue/PriceValue';
import styled from 'styled-components';

const PriceTrailer = styled.span`
  margin-top: 2px;
`;

export const totalWithOwnerTagsTestId = 'totalWithOwnerTagsTestId';

export function getOwnerTagsFromArrays(
  names: string[],
  percentages: string[],
  ids: string[],
  tagWidth = 200,
  totals?: string[]
) {
  if (names.length !== percentages.length) {
    return [];
  }

  const containerStyle = totals
    ? {
        display: 'flex',
        justifyContent: 'flex-end',
      }
    : undefined;

  return names.map((contactName, index) => (
    <div style={containerStyle} key={index}>
      <OwnershipTag
        owner={{
          name: contactName,
          percentage: percentages[index],
          primary: index === 0,
        }}
        navigationId={ids[index]}
        tagWidth={tagWidth}
      />
      {totals && (
        <PriceTrailer data-testid={totalWithOwnerTagsTestId}>
          <PriceValue value={+totals[index]} />
        </PriceTrailer>
      )}
    </div>
  ));
}
