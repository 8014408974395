import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import React from 'react';
import { translations } from '../../../constants/translations';

interface RapidBillingFooterProps {
  deleteRapidBilling: () => void;
  rapidBillingAction: () => Promise<void>;
  saveDisabled: boolean;
  disabled: boolean;
  saveButtonLabel: string;
}

export const RapidBillingFooter: React.FC<RapidBillingFooterProps> = ({
  deleteRapidBilling,
  rapidBillingAction,
  saveDisabled,
  disabled,
  saveButtonLabel,
}) => {
  return (
    <Space direction='horizontal' style={{ marginLeft: '10px', marginTop: 10, marginRight: 20 }}>
      <Popconfirm
        title={translations.rapidBilling.deleteBatchConfirm}
        onCancel={deleteRapidBilling}
        cancelText={translations.shared.popconfirm.ok}
        okText={translations.shared.popconfirm.no}
      >
        <Button type={'primary'} icon={<DeleteOutlined />} danger disabled={disabled}>
          {translations.rapidBilling.deleteButtonText}
        </Button>
      </Popconfirm>
      <Button type={'primary'} onClick={rapidBillingAction} disabled={saveDisabled || disabled}>
        {saveButtonLabel}
      </Button>
    </Space>
  );
};
