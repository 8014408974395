import React, { PropsWithChildren, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, InputNumber, Modal, Row } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons/lib';
import {
  getPdfState,
  getScaleFactor,
  modalLoadingDiv,
  previewLoadingDiv,
  setNewPage,
  getPageNumberWithinBounds,
} from './pdfPreviewUtils';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

interface PdfPreviewProps extends PropsWithChildren<unknown> {
  url: string;
  icon?: React.ReactElement;
}

export interface PdfState {
  rawPdf: Uint8Array | Int8Array | undefined;
  pdfProxy: pdfjs.PDFDocumentProxy | undefined;
  selectedPage: number;
  pageCount: number;
  pageSize: [number, number];
}

const initialState: PdfState = {
  rawPdf: undefined,
  pdfProxy: undefined,
  selectedPage: 1,
  pageCount: 0,
  pageSize: [0, 0],
};

export const PdfPreview: React.FC<PdfPreviewProps> = ({ url, icon }: PdfPreviewProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfState, setPdfState] = useState<PdfState>(initialState);

  const onLoadSuccess = async (pdfProxy: pdfjs.PDFDocumentProxy) => {
    const newState = await getPdfState(pdfProxy);
    setPdfState(newState);
  };

  async function updateForNewPage(newPageNum: number) {
    const newState = await setNewPage(newPageNum, pdfState);
    setPdfState(newState);
  }

  const pageUp = async () => {
    const newPageNum = pdfState.selectedPage + 1;
    await updateForNewPage(newPageNum);
  };

  const pageDown = async () => {
    const newPageNum = pdfState.selectedPage - 1;
    await updateForNewPage(newPageNum);
  };

  const selectPageNumber = async (pageNum: number) => {
    const pageNumberWithinBounds = getPageNumberWithinBounds(pageNum, pdfState.pageCount);
    await updateForNewPage(pageNumberWithinBounds);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const modalFooter = (
    <Row justify={'center'}>
      <Button onClick={pageDown} disabled={pdfState.selectedPage === 1}>
        <CaretLeftOutlined />
      </Button>
      <span style={{ paddingLeft: '10px' }}>
        <InputNumber
          min={1}
          max={pdfState.pageCount}
          value={pdfState.selectedPage}
          onChange={(value) => selectPageNumber(Number(value))}
          style={{ width: '60px' }}
        />
        <span style={{ paddingLeft: '5px', paddingRight: '10px' }}>{`/ ${pdfState.pageCount}`}</span>
      </span>
      <Button onClick={pageUp} disabled={pdfState.selectedPage === pdfState.pageCount}>
        <CaretRightOutlined />
      </Button>
    </Row>
  );

  const scaleFactor = getScaleFactor(pdfState.pageSize, [window.innerWidth, window.innerHeight]);

  const getModalWidth = () => {
    return scaleFactor * pdfState.pageSize[0] + 72;
  };

  const getModalHeight = () => {
    return scaleFactor * pdfState.pageSize[1] + 48;
  };

  const getModalLoadingDiv = () => {
    return modalLoadingDiv(scaleFactor * pdfState.pageSize[0], scaleFactor * pdfState.pageSize[1]);
  };

  return (
    <>
      <Document file={url} onLoadSuccess={onLoadSuccess} loading={previewLoadingDiv}>
        <div onClick={showModal}>
          {icon ? (
            <span style={{ cursor: 'pointer' }}>{icon} Click to view file </span>
          ) : (
            <Page height={200} pageNumber={1} loading={previewLoadingDiv} />
          )}
        </div>
      </Document>
      <Modal
        open={modalVisible}
        onCancel={closeModal}
        width={getModalWidth()}
        footer={modalFooter}
        bodyStyle={{ height: getModalHeight(), maxWidth: '100%' }}
        centered
      >
        <div style={{ padding: '20px' }}>
          <Document file={{ data: pdfState.rawPdf }} loading={getModalLoadingDiv()}>
            <Page pageNumber={pdfState.selectedPage} loading={getModalLoadingDiv()} scale={scaleFactor} />
          </Document>
        </div>
      </Modal>
    </>
  );
};
