import { Spin } from 'antd';
import React, { PropsWithChildren } from 'react';
import './LoadingStyle.css';
import { translations } from '../../constants/translations';

// Label text is options, defaults to loading if not passed
interface LoadingProps extends PropsWithChildren<unknown> {
  labelText?: string;
  height?: string | number;
}

export const tabLoadingHeight = '50vh';
export const TabLoading: React.FC<{ labelText?: string }> = ({ labelText }) => (
  <Loading height={tabLoadingHeight} labelText={labelText} />
);

export const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const labelText = props.labelText ?? translations.loadingComponent.loading;

  return (
    <div className='loadingDiv' style={props.height ? { height: props.height } : {}}>
      <Spin tip={labelText} size='large' />
    </div>
  );
};
