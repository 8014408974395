import React, { useContext, useEffect } from 'react';
import { SelectPatientModalTable } from './SelectPatientModalTable';
import { Patient } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetContactPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useSetWarningIfTooManyResults } from '../../hooks/setWarningIfTooManyResults';
import { useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { useGetFilteredPatients } from './selectPatientModalTableUtil';
import { PatientsContext } from '../../pages/Patients/store/state';
import { setPatientsList } from '../../pages/Patients/store/actions';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';

interface SelectContactPatientTableContainerProps {
  setSelectedPatients: (patient: Patient[]) => void;
  selectedPatients: Patient[];
  contactId: string;
  searchValue?: string;
  canSelectMultiple?: boolean;
  setShowTooManyResultsWarning: (showWarning: boolean) => void;
  filterAndSortTableValues: ReturnType<typeof useTableResetFilterAndSort>;
  excludedPatientId?: string;
  setPatientTableColumns: (columns: CustomColumnType<Patient>[]) => void;
}

export const SelectContactPatientTableContainer: React.FC<SelectContactPatientTableContainerProps> = ({
  searchValue,
  contactId,
  setSelectedPatients,
  selectedPatients,
  canSelectMultiple,
  setShowTooManyResultsWarning,
  filterAndSortTableValues,
  excludedPatientId,
  setPatientTableColumns,
}: SelectContactPatientTableContainerProps) => {
  const organizationId = useGetOrganizationIdFromRoute();

  const { state, dispatch } = useContext(PatientsContext);
  const { patients, patientsLoading } = useGetContactPatientsWithSearch(contactId, organizationId, searchValue);

  const filteredPatients = useGetFilteredPatients(patients, excludedPatientId);

  useEffect(() => {
    dispatch(setPatientsList(filteredPatients));
  }, [filteredPatients, dispatch]);

  useSetWarningIfTooManyResults(patients, setShowTooManyResultsWarning);

  return (
    <SelectPatientModalTable
      patients={state.patientsList}
      patientsLoading={patientsLoading}
      setSelectedPatients={setSelectedPatients}
      selectedPatients={selectedPatients}
      canSelectMultiple={canSelectMultiple}
      filterAndSortTableValues={filterAndSortTableValues}
      setPatientTableColumns={setPatientTableColumns}
    />
  );
};
