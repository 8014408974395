import { DocumentNode } from 'apollo-link';
import { OperationDefinitionNode } from 'graphql';
import { translations } from '../constants/translations';

export const getJobRecordUpsert = (rawUpsert: DocumentNode, upsertVariables: any) => {
  const query = rawUpsert.loc?.source.body;
  const type_name = (rawUpsert.definitions[0] as OperationDefinitionNode).name?.value;

  if (!type_name) {
    throw new Error(translations.jobStatusModal.noTypeNameError);
  }

  return {
    type_name,
    instruction: JSON.stringify({
      operationName: type_name,
      query,
      variables: upsertVariables,
    }),
  };
};
