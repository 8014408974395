import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { ServiceRendered } from '../../../graph/types';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { translations } from '../../../constants/translations';
import { SubTotal } from '../../../pages/Invoices/ViewInvoice/TotalsAndTaxes/TotalsAndTaxes';
import ClickableIcon, { IconType } from '../../Icons/ClickableIcon';
import PopoverNote from '../../PopoverNote/PopoverNote';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import { TableCellLink } from '../../TableLink/TableCellLink';

interface ServiceActivityGridProps extends PropsWithChildren<unknown> {
  services: ServiceRendered[];
  isFullscreen?: boolean;
}

const renderNoteIcon = (serviceRendered: ServiceRendered) => {
  return serviceRendered.note_id ? (
    <PopoverNote noteId={serviceRendered.note_id} existingNote={serviceRendered.note} />
  ) : (
    <ClickableIcon iconType={IconType.ServiceNote} opaque />
  );
};

export const ServiceActivityGrid = (props: ServiceActivityGridProps) => {
  const { navigateTo } = useNavigationToRoute();
  const isFullscreen = props.isFullscreen;

  const navigateToViewInvoice = useCallback(
    (invoiceId: string) => () => {
      navigateTo(routes.viewInvoice, withInvoiceIdParameter(invoiceId));
    },
    [navigateTo]
  );

  const renderServiceDescription = useCallback(
    (serviceRendered: ServiceRendered) => {
      return serviceRendered.invoice_id ? (
        <TableCellLink onClick={navigateToViewInvoice(serviceRendered.invoice_id)}>
          {serviceRendered.name}
        </TableCellLink>
      ) : (
        <>{serviceRendered.name}</>
      );
    },
    [navigateToViewInvoice]
  );

  const columns: ColumnsType<ServiceRendered> = useMemo(
    () => [
      {
        title: translations.renderedServicesTable.columns.doctor,
        key: 'doctor_name',
        dataIndex: 'doctor_name',
        width: isFullscreen ? 200 : 150,
      },
      {
        title: translations.renderedServicesTable.columns.description,
        key: 'name',
        width: isFullscreen ? 300 : 200,
        render: (serviceRendered: ServiceRendered) => {
          return renderServiceDescription(serviceRendered);
        },
      },
      {
        title: translations.renderedServicesTable.columns.quantity,
        key: 'quantity',
        align: isFullscreen ? 'center' : 'right',
        width: isFullscreen ? 200 : 100,
        render: (serviceRendered: ServiceRendered) => {
          if (serviceRendered.unit_name) {
            return serviceRendered.quantity + ' ' + serviceRendered.unit_name;
          }
          return serviceRendered.quantity;
        },
      },
      {
        title: translations.renderedServicesTable.columns.price,
        key: 'unit_price',
        align: isFullscreen ? 'center' : 'right',
        width: isFullscreen ? 200 : 100,
        render: (serviceRendered: ServiceRendered) => {
          return <CurrencyFormatter total={Number(serviceRendered.unit_price)} />;
        },
      },
      {
        title: translations.renderedServicesTable.columns.total,
        key: 'total',
        align: isFullscreen ? 'center' : 'right',
        width: isFullscreen ? 200 : 100,
        render: (serviceRendered: ServiceRendered) => {
          return <CurrencyFormatter total={Number(serviceRendered.total)} />;
        },
      },
      {
        title: translations.renderedServicesTable.columns.note,
        key: 'note',
        align: isFullscreen ? 'center' : undefined,
        render: (serviceRendered: ServiceRendered) => {
          return renderNoteIcon(serviceRendered);
        },
      },
    ],
    [renderServiceDescription, isFullscreen]
  );

  return (
    <>
      <Table<ServiceRendered>
        className={'ServicesRenderedTable'}
        dataSource={props.services}
        columns={columns}
        rowKey={'id'}
        scroll={{ x: true }}
        footer={
          props.isFullscreen
            ? undefined
            : () => <SubTotal servicesRendered={props.services} isFullscreenView={props.isFullscreen} />
        }
        pagination={false}
      />
    </>
  );
};
