import { gql } from '@apollo/client';

export const FinancialTaxTypeFields = gql`
  fragment FinancialTaxTypeFields on FinancialReferenceData {
    tax_type {
      id
      name
      code
      percentage
      default
      compounded
      sort_order
      external_tax_id
      general_ledger_id
    }
  }
`;

export const ServiceRefTypeFields = gql`
  fragment ServiceRefTypeFields on ServiceReferenceData {
    info_type {
      id
      category
      name_key
      sort_order
    }
  }
`;

export const FinancialGeneralLedgerFields = gql`
  fragment FinancialGeneralLedgerFields on FinancialReferenceData {
    general_ledger {
      id
      practice_id
      gl_number
      name
      sort_order
      external_gl_id
      is_receivables_account
    }
  }
`;

export const PaymentTypeFields = gql`
  fragment PaymentTypeFields on FinancialReferenceData {
    ledger_payment_type {
      id
      name_key
      general_ledger_id
      external_id
      electronic_type_id
    }
  }
`;

export const PaymentDeviceTypeFields = gql`
  fragment PaymentDeviceTypeFields on FinancialReferenceData {
    payment_device_type {
      id
      name
      serial_number
      practice_id
    }
  }
`;

export const GeneralLedgerTypeFields = gql`
  fragment GeneralLedgerTypeFields on FinancialReferenceData {
    general_ledger {
      id
      gl_number
      name
      practice_id
      is_receivables_account
    }
  }
`;

export const LedgerTypeFields = gql`
  fragment LedgerTypeFields on FinancialReferenceData {
    ledger_type {
      id
      name_key
      sort_order
      general_ledger_id
    }
  }
`;
