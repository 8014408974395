import { noop } from 'lodash';
import { useState } from 'react';
import { TabChangeWarningProps } from '../components/TabChangeWarning/TabChangeWarning';

export const useTabChangeWarningProps = (hasChanges: boolean) => {
  const initialChangeWarningProps: TabChangeWarningProps = {
    showTabChangeWarning: false,
    onOk: () => noop,
    closeModal: () =>
      setTabChangeWarningProps({
        ...tabChangeWarningProps,
        showTabChangeWarning: false,
      }),
  };
  const [tabChangeWarningProps, setTabChangeWarningProps] = useState(initialChangeWarningProps);

  const handleTabClick = (onOkCallback: () => void) => {
    if (hasChanges) {
      setTabChangeWarningProps({
        ...tabChangeWarningProps,
        showTabChangeWarning: hasChanges,
        onOk: () => {
          onOkCallback();
          setTabChangeWarningProps(initialChangeWarningProps);
        },
      });
    } else {
      onOkCallback();
    }
  };

  return { handleTabClick, tabChangeWarningProps };
};
