import React from 'react';
import { ServicesAction } from './actions';
import { initialState, servicesReducer, ServicesState } from './reducer';

export const ServicesContext = React.createContext<{
  state: ServicesState;
  dispatch: React.Dispatch<ServicesAction>;
}>({} as any);

interface ServiceContextProviderProps {
  state?: Partial<ServicesState>;
}

export const ServicesContextProvider: React.FC<React.PropsWithChildren<ServiceContextProviderProps>> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };
  const [state, dispatch] = React.useReducer(servicesReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <ServicesContext.Provider value={value}>{props.children}</ServicesContext.Provider>;
};
