import React, { useMemo, useState } from 'react';
import { BaseCardProps } from '../SharedProps';
import { translations } from '../../../constants/translations';
import { getPaddedSpans, RecordItem, RecordType } from '../../Records/recordUtils';
import { ServiceActivity } from '../../../graph/types';
import dayjs from 'dayjs';
import { Card, Collapse } from 'antd';
import { CloseButton } from '../../CloseButton/CloseButton';
import { displayDateAsString } from '../../../constants/formats';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useGetPatientServicesForDay } from '../../../hooks/ajax/patients/patientHooks';
import { Loading } from '../../Loading/Loading';
import { ServiceActivityGrid } from './ServiceActivityGrid';
import { useHighlightedText } from '../../../hooks/useHighlightedText';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { SubTotal } from '../../../pages/Invoices/ViewInvoice/TotalsAndTaxes/TotalsAndTaxes';
import './ServiceActivityCard.css';
import styled from 'styled-components';

const StyledSubtotal = styled.div`
  padding: 16px 16px;
  background: #fafafa;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
`;

export const getRecordFromServiceActivity = (serviceActivity: ServiceActivity, offline = false): RecordItem => {
  const renderBottomLine = () => {
    const userDisplay = translations.recordList.doctors + Array(serviceActivity.doctor_names).join(', ');
    return getPaddedSpans(userDisplay);
  };

  return {
    originalObject: serviceActivity,
    recordId: serviceActivity.__typename + serviceActivity.date,
    type: RecordType.typeServiceRenderedActivity,
    typeName: translations.recordList.serviceActivity(offline),
    date: dayjs(serviceActivity.date),
    created: dayjs(serviceActivity.date),
    renderBottomLine,
    renderDescription: () => <div />,
  };
};

interface ServiceActivityCardProps extends BaseCardProps {
  serviceActivityDate: string;
  activityTypeName: string;
  extraFullscreenContent?: React.ReactNode;
}

export const ServiceActivityCard = (props: ServiceActivityCardProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const date = useMemo(() => dayjs(props.serviceActivityDate), [props.serviceActivityDate]);
  const { serviceRecords, serviceRecordsLoading } = useGetPatientServicesForDay({
    patientId: props.patientId || '',
    organizationId,
    date,
  });
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  useHighlightedText([serviceRecords], '.ant-table-cell');

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  if (serviceRecordsLoading || !serviceRecords) {
    if (props.extraFullscreenContent) {
      return null;
    }
    return <Loading />;
  }

  const serviceActivityGridContent = () => (
    <ServiceActivityGrid services={serviceRecords} isFullscreen={!!props.extraFullscreenContent} />
  );

  const headerWithCollapseControl = props.extraFullscreenContent
    ? React.cloneElement(props.extraFullscreenContent as React.ReactElement, {
        onToggleCollapse: toggleCollapse,
        isCollapsed,
      })
    : null;

  return props.extraFullscreenContent ? (
    <>
      {headerWithCollapseControl}
      <Collapse bordered={false} activeKey={isCollapsed ? [] : ['1']} onChange={() => setIsCollapsed(!isCollapsed)}>
        <Collapse.Panel className='CollapsePanel' header='' key='1' showArrow={false}>
          {serviceActivityGridContent()}
        </Collapse.Panel>
      </Collapse>
      <StyledSubtotal className='service-records-sub-total'>
        <SubTotal servicesRendered={serviceRecords} isFullscreenView={!!props.extraFullscreenContent} isLeftAligned />
      </StyledSubtotal>
    </>
  ) : (
    <Card
      title={props.activityTypeName + ': ' + displayDateAsString(date, dateFormat)}
      extra={<CloseButton onClick={() => props.onClose()} />}
    >
      {serviceActivityGridContent()}
    </Card>
  );
};
