import { Reducer } from 'react';
import { ViewSubscriptionActionsTypes, ViewSubscriptionActionType } from './actions';

export interface ViewSubscriptionState {
  renderKey: number;
  editing: boolean;
  isInitiator: boolean;
  isActive: boolean;
}

export const initialState: ViewSubscriptionState = {
  renderKey: 1,
  editing: false,
  isInitiator: false,
  isActive: true,
};

export const viewSubscriptionReducer: Reducer<ViewSubscriptionState, ViewSubscriptionActionsTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ViewSubscriptionActionType.setRenderKey:
      return { ...state, renderKey: action.renderKey };
    case ViewSubscriptionActionType.incrementRenderKey:
      return { ...state, renderKey: state.renderKey + 1 };
    case ViewSubscriptionActionType.setEditing:
      return { ...state, editing: action.editing };
    case ViewSubscriptionActionType.setIsInitiator:
      return { ...state, isInitiator: action.isInitiator };
    case ViewSubscriptionActionType.setIsActive:
      return { ...state, isActive: action.isActive };
    default:
      return state;
  }
};
