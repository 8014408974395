import { ContactsOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { ReactNode } from 'react';
import { AbbreviateToWidth } from '../../../../components/AbbreviateToWidth/AbbreviateToWidth';
import { CloseButton } from '../../../../components/CloseButton/CloseButton';
import { translations } from '../../../../constants/translations';
import { FlexContainer } from '../../../../globalStyles.style';
import { UserOrganizationContactCard, UserOrganizationPatientCard } from '../../../../graph/types';
import { useNavigationToRoute } from '../../../../hooks/route/navigationHooks';
import { getCardTags } from '../../../../util/tags';
import { getOwnerTagsFromArrays } from '../../../Patients/ViewPatient/PatientOwnership/OwnershipTags/ownershipTagsUtil';
import { getProfileImagePlaceholder } from '../../../Patients/ViewPatient/PatientProfileImage/profileImagePlaceholders/profileImagePlaceholders';
import '../../DoctorOffice.less';
import { getContactDescription, getPatientDescription, getRelationshipTags } from '../../doctorOfficeDisplayUtil';
import { invoicesSubRoute } from '../../DoctorOfficePatientTab/doctorOfficePatientTabUtil';
import {
  EntityCard,
  getContactCardRouteWithFilledInContactIdParameter,
  getContactPageRouteWithFilledInContactIdParameter,
  getPatientCardRouteWithFilledInPatientIdParameter,
  joinCardNameAndNumber,
  OfficeType,
} from '../../doctorOfficeUtil';
import { CardTags } from '../DoctorOfficeOverview.styles';
import { AddressLabel, AddressPostal, CardStyles, CardTitleContainer, CloseButtonWrapper } from './OverviewCard.styles';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';

interface OverviewCardProps {
  card: EntityCard;
  closeCard: (cardId: string) => Promise<void>;
  officeType: OfficeType;
}

const tagWidthInCard = 150;
const maxNumberOfTagsPerLine = 2;
const tagLineHeight = 29;
const availableSpaceForTitle = 260;

const showTagLine = (tags: ReactNode[], tooltip: string, placeholder: string) => {
  if (tags.length === 0) {
    return (
      <div style={{ textAlign: 'center', height: tagLineHeight }}>
        <i style={{ color: 'grey' }}>{placeholder}</i>
      </div>
    );
  }
  return (
    <div style={{ marginLeft: -15, marginRight: -15, height: tagLineHeight, display: 'flex' }} title={tooltip}>
      {tags.length > maxNumberOfTagsPerLine ? <>{tags.slice(0, maxNumberOfTagsPerLine)}...</> : tags}
    </div>
  );
};

export const getAddress = ({
  address,
  city,
  country,
  provState,
  postal_zip,
}: {
  address: string;
  city: string;
  country: string;
  provState: string;
  postal_zip: string;
}) => {
  return (
    <FlexContainer direction='column'>
      <AddressLabel>Address:</AddressLabel>
      <span>{address}</span>
      <span>{city}</span>
      <span>{`${provState} ${country}`}</span>
      <AddressPostal>{postal_zip}</AddressPostal>
    </FlexContainer>
  );
};

export const OverviewCard: React.FC<OverviewCardProps> = ({ card, closeCard, officeType }) => {
  const isPatient = officeType === OfficeType.patients;
  const { navigateTo } = useNavigationToRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const cardTitleText = joinCardNameAndNumber(card);
  const cardTitle = (
    <CardTitleContainer
      onClick={() => navigateTo(isPatient ? patientCardRoute : contactCardRoute)}
      key={'contactRecord'}
    >
      <AbbreviateToWidth text={cardTitleText} width={availableSpaceForTitle} />
    </CardTitleContainer>
  );

  const cardTags = card ? getCardTags((card as UserOrganizationContactCard)?.contact_type_name_keys ?? []) : null;

  const patientCard = card as UserOrganizationPatientCard;

  const descriptionText = getPatientDescription(patientCard, dateFormat);
  const descriptionLine = (
    <Paragraph style={{ height: 40 }} ellipsis={{ rows: 2 }}>
      {descriptionText}
    </Paragraph>
  );

  const ownershipNames = patientCard.patient_owner_names ?? [];
  const percentages = patientCard.patient_owner_percentages ?? [];
  const ids = patientCard.patient_owner_ids ?? [];
  const ownershipTags = getOwnerTagsFromArrays(ownershipNames, percentages, ids, tagWidthInCard);
  const ownershipTooltip = ownershipNames.map((name, index) => `${percentages?.[index]}% ${name}`).join(', ');
  const ownershipTagsRestrictedToOneLine = showTagLine(
    ownershipTags,
    ownershipTooltip,
    translations.doctorOffice.ownershipPlaceholder
  );

  const { relatedContactsTags, relatedContactsTooltip } = getRelationshipTags(patientCard, tagWidthInCard);

  const relatedContactTagsRestrictedToOneLine = showTagLine(
    relatedContactsTags,
    relatedContactsTooltip,
    translations.doctorOffice.contactsPlaceholder
  );

  const patientCardRoute = getPatientCardRouteWithFilledInPatientIdParameter(patientCard);
  const contactCardRoute = getContactCardRouteWithFilledInContactIdParameter(card as UserOrganizationContactCard);
  const contactRoutePage = getContactPageRouteWithFilledInContactIdParameter(card as UserOrganizationContactCard);

  const actions = isPatient
    ? [
        <Button onClick={() => navigateTo(patientCardRoute + invoicesSubRoute)} key={'invoicing'} type={'text'}>
          {translations.doctorOffice.invoicingButtonText}
        </Button>,
        <Button onClick={() => navigateTo(patientCardRoute + '/records')} key={'records'} type={'text'}>
          {translations.doctorOffice.medicalRecordsButtonText}
        </Button>,
      ]
    : [
        <Button onClick={() => navigateTo(contactRoutePage)} key={'contactRoute'} type={'text'}>
          {translations.doctorOffice.contactRecord}
        </Button>,
        <Button onClick={() => navigateTo(contactCardRoute)} key={'contactTab'} type={'text'}>
          {translations.doctorOffice.contactTab}
        </Button>,
      ];

  return (
    <CardStyles
      className={'DoctorOffice__OverviewCard'}
      actions={actions}
      onDoubleClick={() => navigateTo(isPatient ? patientCardRoute : contactCardRoute)}
    >
      <CloseButtonWrapper>
        <CloseButton onClick={() => closeCard(card.id)} />
      </CloseButtonWrapper>
      <Card.Meta
        avatar={
          isPatient ? (
            <Avatar
              src={getProfileImagePlaceholder('patient_species_name' in card ? card.patient_species_name : null)}
              shape={'square'}
            />
          ) : (
            <ContactsOutlined style={{ fontSize: '30px' }} />
          )
        }
        title={cardTitle}
        description={isPatient && descriptionLine}
      />

      {isPatient ? (
        <>
          <Divider style={{ margin: '12px 0' }} />
          {ownershipTagsRestrictedToOneLine}
          <Divider style={{ margin: '12px 0' }} />
          {relatedContactTagsRestrictedToOneLine}
        </>
      ) : (
        getAddress(getContactDescription(card as UserOrganizationContactCard))
      )}
      {!!cardTags?.length && <CardTags>{cardTags}</CardTags>}
    </CardStyles>
  );
};
