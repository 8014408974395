import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useUpsertQuickBooksAuth } from '../../hooks/ajax/quickBooks/quickBooksHooks';
import { Loading } from '../Loading/Loading';
import { translations } from '../../constants/translations';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import ReactDOM from 'react-dom';
import AlertModal from '../AlertModal/AlertModal';

interface QuickBooksAuthProps {
  organizationId: string;
  onFinish?: () => void;
  onClose?: () => void;
}

export const QuickBooksAuth: React.FC<QuickBooksAuthProps> = ({ organizationId, onFinish, onClose }) => {
  const quickBooksAuth = useUpsertQuickBooksAuth();
  const [authUrl, setAuthUrl] = useState<string>();
  const [error, setError] = useState<string>();

  const tryQuickBooksAuth = useCallback(
    async (organizationId: string) => {
      setError(undefined);

      const authResponse = await quickBooksAuth(organizationId);

      if (authResponse.errorMessage) {
        setError(authResponse.errorMessage);
      } else if (authResponse.redirectUrl) {
        setAuthUrl(authResponse.redirectUrl);
      } else {
        onFinish?.();
      }
    },
    [onFinish, quickBooksAuth]
  );

  useEffect(() => {
    tryQuickBooksAuth(organizationId);
  }, [organizationId, tryQuickBooksAuth]);

  useEffect(() => {
    if (authUrl) {
      const quickBooksAuthWindowProps = {
        width: 300,
        height: 700,
        left: window.screen.width ? (window.screen.width - 300) / 2 : 0,
        top: window.screen.height ? (window.screen.height - 700) / 2 : 0,
        resizable: 'yes',
        scrollbars: 'yes',
        status: 'yes',
        toolbar: 'no',
        menubar: 'no',
        location: 'no',
        directories: 'no',
      };
      const openQbPopup = () =>
        window.open(
          authUrl,
          'quickbooksAuthWindow',
          Object.entries(quickBooksAuthWindowProps)
            .map((entry) => `${entry[0]}:${entry[1]}`)
            .join(',')
        ) || undefined;

      const quickbooksAuthWindow = openQbPopup();

      if (quickbooksAuthWindow) {
        const windowCheckerLoop = setInterval(() => {
          if (quickbooksAuthWindow.closed) {
            clearInterval(windowCheckerLoop);
            onFinish?.();
          }
        }, 1000);
      } else {
        ReactDOM.render(
          <AlertModal
            message={translations.popupDetectionMessage.general}
            okText={translations.popupAlert.retry}
            onOk={openQbPopup}
          />,
          document.getElementById('alert-container')
        );
      }
    }
  }, [authUrl, onFinish]);

  return (
    <>
      <Modal
        title={translations.shared.quickBooks.auth.title}
        open
        okButtonProps={{
          hidden: true,
        }}
        onCancel={onClose}
        width={840}
      >
        {error ? (
          <ErrorMessage message={translations.shared.quickBooks.auth.urlErrorMessage(error)} />
        ) : (
          <Loading
            labelText={
              authUrl ? translations.shared.quickBooks.auth.urlLoaded : translations.shared.quickBooks.auth.urlLoading
            }
          />
        )}
      </Modal>
    </>
  );
};
