import styled from 'styled-components';
import { CustomFlexContainerMixin } from '../../globalStyles.style';

export const StyledCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-template-rows: 310px 310px;
  grid-gap: 0.5rem;
  padding: 0.5rem 2rem;
`;

export const SwitchLabel = styled.span`
  margin-right: 4px;
`;

export const SwitchContainer = styled.div`
  ${CustomFlexContainerMixin}
  align-items: center;
  height: 32px;
`;

export const FooterContainer = styled.div`
  ${CustomFlexContainerMixin}
  background-color: whitesmoke;
  padding: 0.5rem 1rem;
`;

export const CardContainer = styled.div`
  flex-grow: 1;
  padding: 1rem 4rem;
`;

export const TitleContainer = styled.div`
  font-size: 1.2em;
  margin-bottom: 0.2rem;
`;

export const PopConfirmText = styled.div`
  word-wrap: break-word;
  max-width: 340px;
`;

export const CardAction = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: initial;
  width: 50%;
`;

export const CardActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: whitesmoke;

  & > div:not(:last-child) {
    border-right: 1px solid white;
  }
`;

export const TextWithMargin = styled.span<{ isBold?: boolean }>`
  margin-bottom: 1em;
  font-weight: ${({ isBold = false }) => (isBold ? 'bold' : 'normal')};
`;
