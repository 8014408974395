import { DatePicker, Form, InputNumber, Switch } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { translations } from '../../constants/translations';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { getRequiredRule } from '../../util/forms';

type InterestSettingsFormProps = {
  isCreating?: boolean;
  offset: number;
};

export enum InterestSettingsFields {
  interestRate = 'interestRate',
  isCompounded = 'isCompounded',
  chargeFreePeriod = 'chargeFreePeriod',
  outstandingSinceDate = 'outstandingSinceDate',
}

export interface InterestRunConfigFormFields {
  [InterestSettingsFields.interestRate]: number;
  [InterestSettingsFields.isCompounded]?: boolean;
  [InterestSettingsFields.chargeFreePeriod]?: number;
  [InterestSettingsFields.outstandingSinceDate]?: Dayjs;
}

export interface InterestSettingValue {
  interestRate: number;
  isCompounded: boolean;
  chargeFreePeriod: number;
}

export const InterestSettingsForm: React.FC<InterestSettingsFormProps> = ({ isCreating, offset }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return (
    <>
      <Form.Item
        name={InterestSettingsFields.interestRate}
        label={translations.interestSettings.fields.getInterestRateTitle(isCreating)}
        rules={[getRequiredRule(translations.interestSettings.fields.getInterestRateTitle(isCreating))]}
      >
        <InputNumber formatter={(value) => `${value}%`} min={0.001} max={1000} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset }} name={InterestSettingsFields.isCompounded} valuePropName='checked'>
        <Switch
          checkedChildren={translations.interestSettings.fields.isCompounded}
          unCheckedChildren={translations.interestSettings.fields.isCompounded}
        />
      </Form.Item>
      {isCreating ? (
        <Form.Item
          name={InterestSettingsFields.outstandingSinceDate}
          label={translations.interestSettings.fields.outstandingDate}
          rules={[getRequiredRule(translations.interestSettings.fields.outstandingDate)]}
        >
          <DatePicker format={dateFormat} disabledDate={(date) => date.isAfter(dayjs().startOf('day'))} />
        </Form.Item>
      ) : (
        <Form.Item
          name={InterestSettingsFields.chargeFreePeriod}
          label={translations.interestSettings.fields.chargeFreePeriod}
          rules={[getRequiredRule(translations.interestSettings.fields.chargeFreePeriod)]}
        >
          <InputNumber precision={0} max={9999} min={1} />
        </Form.Item>
      )}
    </>
  );
};
