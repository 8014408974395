export enum ElectronicPaymentType {
  CardConnect = 1,
  Stripe = 2,
}

export enum StripeActionType {
  CHARGE = 'charge',
  REFUND = 'refund',
  CAPTURE = 'capture',
  VOID = 'void',
  AUTHORIZE = 'authorize',
  UPDATE = 'update',
  RETRIEVE = 'retrieve',
}
