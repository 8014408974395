import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, Row, theme } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { translations } from '../../constants/translations';
import { useOutsideClickHandler } from '../../hooks/outsideClickHandler';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';
import { LinkButton } from '../../globalStyles.style';

const PaddedWrapper = styled.div`
  width: 300px;
  padding: 1rem;
`;

const RightAlignWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const RowWithMaxHeight = styled(Row)`
  max-height: 200px;
  overflow: auto;
  & > ul {
    width: 100%;
  }
`;

const { useToken } = theme;

export interface ColumnDisplayFilterProps {
  initiallyDisplayedColumns: string[];
  setDisplayedColumns: (colKeys: string[]) => void;
  columnKeyAndTitleList: { key: string; title: string }[];
  resetDisplayedColumnsToDefault: () => void;
}

const ColumnDisplayFilter: FunctionComponent<ColumnDisplayFilterProps & { className?: string }> = ({
  initiallyDisplayedColumns,
  setDisplayedColumns,
  columnKeyAndTitleList,
  resetDisplayedColumnsToDefault,
  className,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  const [columnList, setColumnList] = useState(
    columnKeyAndTitleList.map((colItem) => ({
      ...colItem,
      checked: initiallyDisplayedColumns.includes(colItem.key),
    }))
  );

  const { token } = useToken();

  const filteredColumnKeyAndTitleList = useMemo(
    () => columnList.filter((colItem) => colItem.title.toLowerCase().includes(searchValue.toLowerCase())),
    [columnList, searchValue]
  );

  useOutsideClickHandler(dropdownRef, () => {
    setDropdownVisible(false);
  });

  const resetValues = useCallback(() => {
    setColumnList(
      columnKeyAndTitleList.map((colItem) => ({
        ...colItem,
        checked: initiallyDisplayedColumns.includes(colItem.key),
      }))
    );
    setSearchValue('');
  }, [columnKeyAndTitleList, initiallyDisplayedColumns]);

  useEffect(() => {
    if (dropdownVisible) {
      resetValues();
    }
  }, [dropdownVisible, resetValues]);

  const handleFormOnFinish = useCallback(() => {
    setDisplayedColumns(columnList.filter((colItem) => colItem.checked).map((colItem) => colItem.key));
    setDropdownVisible(false);
  }, [columnList, setDisplayedColumns]);

  const handleOnResetToDefaults = useCallback(() => {
    setDropdownVisible(false);
    resetDisplayedColumnsToDefault();
  }, [resetDisplayedColumnsToDefault]);

  const handleOnCheckChange = ({ target }: CheckboxChangeEvent) => {
    const { checked, name } = target;
    setColumnList((prevState) =>
      prevState.map((colItem) => {
        if (colItem.key === name) {
          return { ...colItem, checked };
        }
        return colItem;
      })
    );
  };

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const items: MenuProps['items'] = filteredColumnKeyAndTitleList.map((colItem) => ({
    key: colItem.key,
    label: (
      <Col span={24} key={colItem.key}>
        <Checkbox name={colItem.key} checked={colItem.checked} onChange={handleOnCheckChange}>
          {colItem.title}
        </Checkbox>
      </Col>
    ),
  }));

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      open={dropdownVisible}
      className={className}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <PaddedWrapper ref={dropdownRef}>
            <h3>{translations.columnDisplayFilter.title}</h3>
            <Input.Search
              placeholder={translations.columnDisplayFilter.search}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <LinkButton type={'text'} onClick={handleOnResetToDefaults}>
              {translations.columnDisplayFilter.restoreDefaults}
            </LinkButton>
            <Form initialValues={{ columns: initiallyDisplayedColumns }} onFinish={handleFormOnFinish}>
              <RowWithMaxHeight>{React.cloneElement(menu as React.ReactElement)}</RowWithMaxHeight>
              <RightAlignWrapper>
                <LinkButton type={'text'} htmlType={'submit'}>
                  {translations.columnDisplayFilter.done}
                </LinkButton>
                <Button type={'text'} onClick={() => setDropdownVisible(false)}>
                  {translations.shared.cancelButtonText}
                </Button>
              </RightAlignWrapper>
            </Form>
          </PaddedWrapper>
        </div>
      )}
    >
      <Button onClick={() => setDropdownVisible(!dropdownVisible)}>
        {translations.columnDisplayFilter.title} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ColumnDisplayFilter;
