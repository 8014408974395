import dayjs from 'dayjs';
import { upsertDateFormat as defaultLocaleFormat } from '../constants/formats';

export const ContactNameTemplate = '{ContactName}';
export const StartDateTemplate = '{StartDate}';
export const EndDateTemplate = '{EndDate}';

export const InvoiceDateTemplate = '{InvoiceDate}';

const ContactNameRegex = new RegExp(ContactNameTemplate, 'g');
const StartDateRegex = new RegExp(StartDateTemplate, 'g');
const EndDateRegex = new RegExp(EndDateTemplate, 'g');

const InvoiceDateRegex = new RegExp(InvoiceDateTemplate, 'g');

export const formatStringWithTemplatedFieldsForStatements = (
  message?: string,
  startDate?: string,
  endDate?: string,
  contactName?: string,
  dateFormat?: string
): string | undefined => {
  if (!message) {
    return undefined;
  }
  if (message.includes(ContactNameTemplate) && contactName) {
    message = message.replace(ContactNameRegex, contactName);
  }
  if (message.includes(StartDateTemplate) && startDate) {
    message = message.replace(StartDateRegex, dayjs(startDate).format(dateFormat || defaultLocaleFormat));
  }
  if (message.includes(EndDateTemplate) && endDate) {
    message = message.replace(EndDateRegex, dayjs(endDate).format(dateFormat || defaultLocaleFormat));
  }
  return message;
};

export const formatStringWithTemplatedFieldsForInvoices = (
  message?: string,
  contactName?: string,
  invoiceDate?: string,
  dateFormat?: string
): string | undefined => {
  if (!message) {
    return undefined;
  }
  if (message.includes(ContactNameTemplate) && contactName) {
    message = message.replace(ContactNameRegex, contactName);
  }
  if (message.includes(InvoiceDateTemplate) && invoiceDate) {
    message = message.replace(InvoiceDateRegex, dayjs(invoiceDate).format(dateFormat || defaultLocaleFormat));
  }
  return message;
};
