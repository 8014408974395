import React, { PropsWithChildren, useContext } from 'react';
import { ContactTypeDto, PatientRelatedUpsert, RelatedContactEntryCurrent } from '../../../../../../graph/types';
import { translations } from '../../../../../../constants/translations';
import { Popconfirm, Table } from 'antd';
import { TableCellLink } from '../../../../../../components/TableLink/TableCellLink';
import dayjs from 'dayjs';
import { getContactTypeTag } from '../../../../../../util/tags';
import { RelationshipContext } from '../store/state';
import { addRelationshipAction, editRelationshipAction } from '../store/actions';
import { noop } from 'lodash';
import { mapToRemoveRelationshipUpsert } from '../patientRelationshipUtil';
import { routes } from '../../../../../../constants/routes';
import { useNavigationToRoute, withContactIdParameter } from '../../../../../../hooks/route/navigationHooks';
import { useUserLocaleData } from '../../../../../../hooks/useUserLocale';
import { ContactTypeNameKey } from '../../../../../../constants/referenceData/contactReferenceData';
import { upsertDateFormat } from '../../../../../../constants/formats';

export interface PatientRelationshipTableProps extends PropsWithChildren<unknown> {
  relationshipTableEntries: RelationshipTableEntry[];
  contactTypeRefs: ContactTypeDto[];
  sendRelationshipUpsert: (relationshipUpsert: PatientRelatedUpsert) => Promise<void>;
  setIsSaving: (isSaving: boolean) => void;
}

export type RelationshipEmptyEntry = { contact_type_id: string };
export type RelationshipTableEntry = RelatedContactEntryCurrent | RelationshipEmptyEntry;

export const PatientRelationshipTable: React.FC<PatientRelationshipTableProps> = ({
  relationshipTableEntries,
  contactTypeRefs,
  sendRelationshipUpsert,
  setIsSaving,
}) => {
  const { dispatch } = useContext(RelationshipContext);
  const { navigateTo } = useNavigationToRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const handleRemoveRelationship = async (value: RelatedContactEntryCurrent) => {
    setIsSaving(true);
    const patientRelatedUpsert = mapToRemoveRelationshipUpsert(value, upsertDateFormat);
    await sendRelationshipUpsert(patientRelatedUpsert);
    setIsSaving(false);
  };

  const getEditAndRemoveButton = (contactTypeId: string, value: RelatedContactEntryCurrent) => {
    return (
      <>
        <TableCellLink onClick={() => dispatch(editRelationshipAction(contactTypeId, value))}>
          {translations.patientPage.relationships.buttons.edit}
        </TableCellLink>
        <Popconfirm
          title={translations.patientPage.relationships.popconfirm.title}
          okText={translations.patientPage.relationships.popconfirm.okText}
          cancelText={translations.patientPage.relationships.popconfirm.cancelText}
          onConfirm={() => handleRemoveRelationship(value)}
        >
          <TableCellLink onClick={noop}>{translations.patientPage.relationships.buttons.remove}</TableCellLink>
        </Popconfirm>
      </>
    );
  };

  const navigateToViewContact = (contactId: string) => () => {
    navigateTo(routes.viewContact, withContactIdParameter(contactId));
  };

  const columns = [
    {
      title: translations.patientPage.relationships.columns.type,
      key: 'type',
      render: (value: RelationshipTableEntry) => {
        const contactTypeRef = contactTypeRefs.find((typeRef) => typeRef.type_id === value.contact_type_id);
        if (
          contactTypeRef &&
          Object.values(ContactTypeNameKey).includes(contactTypeRef.name_key as ContactTypeNameKey)
        ) {
          return getContactTypeTag(contactTypeRef.name_key as ContactTypeNameKey);
        }
        return '';
      },
    },
    {
      title: translations.patientPage.relationships.columns.contact,
      key: 'contact',
      render: (value: RelatedContactEntryCurrent) =>
        value.contact_id && (
          <TableCellLink onClick={navigateToViewContact(value.contact_id)}>{value.contact_name}</TableCellLink>
        ),
    },
    {
      title: translations.patientPage.relationships.columns.date,
      key: 'effective_date',
      render: (value: RelationshipTableEntry) => {
        if ((value as RelatedContactEntryCurrent).effective_date) {
          return dayjs((value as RelatedContactEntryCurrent).effective_date).format(dateFormat);
        }
        return '';
      },
    },
    {
      title: translations.patientPage.relationships.columns.action,
      key: 'action',
      render: (value: RelationshipTableEntry) => {
        const contactTypeId = value.contact_type_id;
        if ((value as RelatedContactEntryCurrent).contact_id) {
          return getEditAndRemoveButton(contactTypeId, value as RelatedContactEntryCurrent);
        }

        return (
          <TableCellLink onClick={() => dispatch(addRelationshipAction(contactTypeId))}>
            {translations.patientPage.relationships.buttons.add}
          </TableCellLink>
        );
      },
    },
  ];

  return (
    <Table<RelationshipTableEntry>
      style={{ minWidth: '600px' }}
      dataSource={relationshipTableEntries}
      columns={columns}
      rowKey={'contact_type_id'}
      pagination={false}
    />
  );
};
