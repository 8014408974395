import React, { useEffect, useState } from 'react';
import { translations } from '../../constants/translations';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Loading } from '../../components/Loading/Loading';
import { useGetStripePayouts } from '../../hooks/stripeHooks';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { Organization, StripePayoutDetails } from '../../graph/types';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStatusFilters,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { stripePayoutDetailsPropertyNames } from '../../constants/propertyNames';
import { StripePayoutStatusNameKey, stripePayoutStatusConfig } from '../../constants/referenceData/stripeReferenceData';
import { getTag } from '../../util/tags';
import { Card, Col, Divider, Row, Space } from 'antd';
import { PriceValue } from '../../components/PriceValue/PriceValue';
import { TableCellLink } from '../../components/TableLink/TableCellLink';
import { StripePayoutDetailsModal } from './StripePayoutDetailsModal';

export const SummaryCard = styled(Card)`
  margin: 10px 50px;
`;

export const SummaryDivider = styled(Divider)`
  margin: 10px 0px;
`;

export const BolderCol = styled(Col)`
  padding: 0px 15px;
  font-weight: 400;
  font-size: 12pt;
`;

interface ConnectPayoutsProps {
  organization?: Organization;
}

export const ConnectPayouts: React.FC<ConnectPayoutsProps> = ({ organization }) => {
  const { stripePayoutsSummary, payoutsLoading, refetchPayoutsSummary } = useGetStripePayouts(
    organization?.id ?? '',
    organization?.default_practice_id ?? ''
  );
  const [selectedPayout, setSelectedPayout] = useState<StripePayoutDetails>();

  const { filteredValue, sortOrderMap, tableChangeHandler } = useTableResetFilterAndSort();

  useEffect(() => {
    refetchPayoutsSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (payoutsLoading) {
    return <Loading />;
  }

  const openDetailsModal = (details: StripePayoutDetails) => {
    setSelectedPayout(details);
  };

  const { columns: payoutsCols } = translations.reportPaymentsPage.tabs.payouts;

  const columns: CustomColumnType<StripePayoutDetails>[] = [
    {
      title: payoutsCols.arrival_date,
      key: stripePayoutDetailsPropertyNames.arrival_date,
      dataIndex: stripePayoutDetailsPropertyNames.arrival_date,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap[stripePayoutDetailsPropertyNames.arrival_date],
      filteredValue: filteredValue.arrival_date ?? null,
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(payoutsCols.arrival_date),
      onFilter: getOnFilterFunctionFor(stripePayoutDetailsPropertyNames.arrival_date),
      sorter: getDateCompareFunctionFor(stripePayoutDetailsPropertyNames.arrival_date),
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
      width: 200,
    },
    {
      title: payoutsCols.destination,
      key: stripePayoutDetailsPropertyNames.destination,
      dataIndex: stripePayoutDetailsPropertyNames.destination,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap[stripePayoutDetailsPropertyNames.destination],
      filteredValue: filteredValue[stripePayoutDetailsPropertyNames.destination],
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(payoutsCols.destination),
      onFilter: getOnFilterFunctionFor(stripePayoutDetailsPropertyNames.destination),
      sorter: getStringCompareFunctionFor(stripePayoutDetailsPropertyNames.destination),
      width: 500,
    },
    {
      title: payoutsCols.amount,
      key: stripePayoutDetailsPropertyNames.amount,
      dataIndex: stripePayoutDetailsPropertyNames.amount,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap.amount,
      filteredValue: filteredValue.amount ?? null,
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(payoutsCols.amount),
      isForNumber: true,
      onFilter: getOnFilterFunctionFor(stripePayoutDetailsPropertyNames.amount, true),
      sorter: getNumberCompareFunctionFor(stripePayoutDetailsPropertyNames.amount),
      render: (amount: string) => <PriceValue value={Number(amount)} />,
      width: 200,
    },
    {
      title: payoutsCols.status,
      key: stripePayoutDetailsPropertyNames.status,
      dataIndex: stripePayoutDetailsPropertyNames.status,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap.status,
      filteredValue: filteredValue.status ?? null,
      filters: getStatusFilters(stripePayoutStatusConfig),
      onFilter: (value, record) => record.status === value,
      sorter: (a: StripePayoutDetails, b: StripePayoutDetails) =>
        (a.status_text ?? '').localeCompare(b.status_text ?? ''),
      render: (status: string) => {
        const statusConfig = stripePayoutStatusConfig[status as StripePayoutStatusNameKey];
        return getTag(statusConfig.color, statusConfig.text);
      },
      width: 150,
    },
    {
      title: payoutsCols.openDetails,
      key: 'actions',
      render: (record: StripePayoutDetails) => (
        <TableCellLink onClick={() => openDetailsModal(record)}>
          {translations.reportPaymentsPage.tabs.payouts.actions.viewDetails}
        </TableCellLink>
      ),
    },
  ];

  const summary = stripePayoutsSummary?.payout_details_list ?? [];

  return (
    <Space direction='vertical' style={{ display: 'flex' }}>
      <SummaryCard>
        <Row>
          <BolderCol span={16}>Balance:</BolderCol>
          <BolderCol span={8}>
            <PriceValue value={Number(stripePayoutsSummary?.balance)} />
          </BolderCol>
        </Row>
        <SummaryDivider />
        <Row>
          <BolderCol span={16}>Available:</BolderCol>
          <BolderCol span={8}>
            <PriceValue value={Number(stripePayoutsSummary?.available)} />
          </BolderCol>
        </Row>
        <Row>
          <BolderCol span={16}>Sent to Payout:</BolderCol>
          <BolderCol span={8}>
            <PriceValue value={Number(stripePayoutsSummary?.sent_to_payout_account)} />
          </BolderCol>
        </Row>
      </SummaryCard>
      <TableWithCustomFiltering<StripePayoutDetails>
        tableKey={TableKey.StripePayouts}
        dataSource={summary as StripePayoutDetails[]}
        columns={columns}
        loading={payoutsLoading}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
      {selectedPayout?.id && organization?.default_practice_id && (
        <StripePayoutDetailsModal
          title={
            <>
              <PriceValue value={Number(selectedPayout.amount ?? 0)} />
              {` ${selectedPayout.currency} - ${translations.stripePayoutsModal.sentTo(
                selectedPayout.destination || ''
              )}`}
            </>
          }
          organizationId={organization.id}
          practiceId={organization?.default_practice_id}
          payoutId={selectedPayout.id}
          onClose={() => setSelectedPayout(undefined)}
        />
      )}
    </Space>
  );
};
