import { Reference, useMutation } from '@apollo/client';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { ReminderBatchListFields, ReminderFields } from '../../../graph/fragments';
import {
  GetReminders,
  GetRemindersBatch,
  GetRemindersBatchList,
  UpsertReminder,
  UpsertReminderBatch,
} from '../../../graph/queries/reminders';
import {
  Mutation,
  MutationUpsertReminderArgs,
  MutationUpsertReminderBatchArgs,
  QueryGetReminderBatchArgs,
  QueryGetReminderBatchListArgs,
  QueryGetRemindersArgs,
  ReminderFilter,
  ReminderUpsert,
} from '../../../graph/types';
import { getReminderInsert, getReminderUpdate } from '../../../services/LocalDatabaseService/queries/reminderQueries';
import { RxPatient } from '../../../services/LocalDatabaseService/schemas/patientSchema';
import { RxReminder } from '../../../services/LocalDatabaseService/schemas/reminderSchema';
import { useOfflineDelete, useOfflineInsert, useOfflineUpdate } from '../../localDatabaseHooks';
import { useGetOrganizationIdFromRoute } from '../../route/routeParameterHooks';
import { OwnerType } from '../recordHooks';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useOfflineQuery } from '../useOfflineQuery';
import { WatchQueryFetchPolicy } from 'apollo-client';

export const useGetReminders = (
  organizationId: string,
  options?: {
    params?: { id: string };
    type?: OwnerType;
    filter?: ReminderFilter;
    skip?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
  }
) => {
  const selector = useMemo(
    () => (options?.params && options?.type ? { [`${options.type}_id`]: options.params.id } : undefined),
    [options?.params, options?.type]
  );
  const variables: QueryGetRemindersArgs = { organizationId };

  if (options?.params && options?.type) {
    const entityKey = options?.type === OwnerType.Contact ? 'contactId' : 'patientId';
    variables[entityKey] = options?.params.id;
  }

  if (options?.filter) {
    variables.filter = options?.filter;
  }

  const { data, loading, refetch } = useOfflineQuery<'getReminders'>({
    query: GetReminders,
    collection: 'reminder',
    options: {
      skip: organizationId === '' || options?.params?.id === '' || options?.skip,
      fetchPolicy: options?.fetchPolicy ? options.fetchPolicy : 'cache-first',
      nextFetchPolicy: 'cache-first',
      variables,
    },
    selector,
    expectList: true,
    sortBy: 'date',
  });

  return {
    reminders: data,
    remindersLoading: loading,
    refetch,
  };
};

export const useUpsertReminder = () => {
  return useMutation<Mutation, MutationUpsertReminderArgs>(UpsertReminder, {
    update: (cache: any, { data: { upsertReminder } }: any) => {
      cache.modify({
        fields: {
          getReminders(reminders: Reference[] = []) {
            const newReminder = cache.writeFragment({
              id: upsertReminder.id,
              data: upsertReminder,
              fragment: ReminderFields,
              fragmentName: 'ReminderFields',
            });
            return [newReminder, ...reminders.filter((r) => r.__ref !== `Reminder:${upsertReminder.id}`)];
          },
        },
      });
    },
  });
};

export const useDeleteReminder = (organizationId: string) => {
  const [deleteReminder] = useMutation<Mutation, MutationUpsertReminderArgs>(UpsertReminder);

  return (reminderId: string) =>
    deleteReminder({
      variables: {
        organizationId,
        reminder: { id: reminderId, void: true },
      },
    });
};

export const useReminderOfflineMutation = (params: { patientId: string }) => {
  const [patient, setPatient] = useState<RxPatient>();
  const organizationId = useGetOrganizationIdFromRoute();
  const patientCollection = useRxCollection<RxPatient>('patient');
  const reminderCollection = useRxCollection<RxReminder>('reminder');
  const offlineInsert = useOfflineInsert<RxReminder>('reminder');
  const offlineUpdate = useOfflineUpdate<RxReminder>('reminder');
  const offlineDelete = useOfflineDelete<RxReminder>('reminder');

  useEffect(() => {
    if (!params.patientId) {
      return;
    }

    const getPatient = async () => {
      const rxPatient = await patientCollection?.findOne(params.patientId)?.exec();

      if (rxPatient) {
        setPatient(rxPatient);
      }
    };

    getPatient();
  }, [params.patientId, patientCollection, setPatient]);

  return useCallback(
    async (upsert: ReminderUpsert) => {
      if (upsert.id === ID_FOR_OBJECT_CREATION) {
        const obj = getReminderInsert(upsert, { patient, organizationId });
        await offlineInsert(obj);
      } else if (reminderCollection && upsert.id) {
        const reminder = (await reminderCollection?.findOne(upsert.id).exec()) as RxReminder | null;
        if (reminder) {
          if (upsert.void && reminder.is_new) {
            await offlineDelete(reminder.id);
            return;
          }
          const obj = getReminderUpdate(reminder, upsert);
          await offlineUpdate(upsert.id, obj);
        }
      }
    },
    [offlineUpdate, offlineInsert, patient, offlineDelete, organizationId, reminderCollection]
  );
};

export const useGetRemindersBatch = (organizationId: string, reminderBatchId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getReminderBatch', QueryGetReminderBatchArgs>(
    GetRemindersBatch,
    {
      skip: organizationId === '' || reminderBatchId === '',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { organizationId, reminderBatchId },
    }
  );

  return {
    data: data?.getReminderBatch,
    loading,
    refetch,
  };
};

export const useGetRemindersBatchList = (organizationId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getReminderBatchList', QueryGetReminderBatchListArgs>(
    GetRemindersBatchList,
    {
      skip: organizationId === '',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { organizationId },
    }
  );

  return {
    data: data?.getReminderBatchList,
    loading,
    refetch,
  };
};

export const useUpsertReminderBatch = () => {
  return useOfflineErrorSkipMutation<'upsertReminderBatch', MutationUpsertReminderBatchArgs>(UpsertReminderBatch, {
    update: (cache: any, { data: { upsertReminderBatch } }: any) => {
      cache.modify({
        fields: {
          getReminderBatchList(remindersBatchList: Reference[] = []) {
            const newReminder = cache.writeFragment({
              id: upsertReminderBatch?.id,
              data: upsertReminderBatch,
              fragment: ReminderBatchListFields,
              fragmentName: 'ReminderBatchListFields',
            });
            return [
              ...remindersBatchList.filter((r) => r.__ref !== `ReminderBatch:${upsertReminderBatch?.id}`),
              newReminder,
            ];
          },
        },
      });
    },
  });
};

export const useDeleteReminderBatch = () => {
  const [upsertReminderBatch] = useUpsertReminderBatch();

  return async ({ organizationId, batchId }: { organizationId: string; batchId?: string }) => {
    try {
      await upsertReminderBatch({
        variables: {
          organizationId,
          reminderBatch: { id: batchId, void: true },
        },
        update: (cache: any) => {
          cache.modify({
            fields: {
              getReminderBatchList(remindersBatchList: Reference[] = []) {
                return [...remindersBatchList.filter((r) => r.__ref !== `ReminderBatch:${batchId}`)];
              },
            },
          });
        },
      });
    } catch (e) {
      showErrorMessage((e as Error).message);
    }
  };
};
