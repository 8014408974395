import gql from 'graphql-tag';

export const JobFields = gql`
  fragment JobFields on Job {
    id
    percent
    status_name_key
    error_message
    result
  }
`;
