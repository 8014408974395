import React, { CSSProperties, useState } from 'react';
import { ButtonProps, Popconfirm, Select } from 'antd';
import { translations } from '../../constants/translations';
import { RenderFunction } from 'antd/es/tooltip';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { DefaultOptionType } from 'antd/es/select';

interface SelectWithPopconfirmProps {
  popconfirmTitle: React.ReactNode | RenderFunction;
  options: DefaultOptionType[];
  onConfirm?: () => void;
  onSelectCallback?: (value: string) => void;
  onCancel?: () => void;
  disabled?: boolean;
  selectTestId?: string;
  popconfirmOkText?: string;
  popconfirmCancelText?: string;
  selectPlaceholder?: string;
  selectStyle?: CSSProperties;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  shouldShowPopover?: boolean;
}

export const SelectWithPopconfirm: React.FC<React.PropsWithChildren<SelectWithPopconfirmProps>> = ({
  popconfirmTitle,
  options,
  onConfirm,
  onSelectCallback,
  onCancel,
  disabled,
  selectTestId,
  popconfirmOkText,
  popconfirmCancelText,
  selectPlaceholder,
  selectStyle,
  okButtonProps,
  cancelButtonProps,
  shouldShowPopover = true,
}: SelectWithPopconfirmProps) => {
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <Popconfirm
      placement={'right'}
      title={popconfirmTitle}
      okText={popconfirmOkText ?? translations.shared.popconfirm.ok}
      cancelText={popconfirmCancelText ?? translations.shared.popconfirm.cancel}
      onConfirm={() => {
        onConfirm?.();
        setPopupVisible(false);
      }}
      onCancel={() => {
        onCancel?.();
        setPopupVisible(false);
      }}
      defaultOpen={false}
      open={popupVisible}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <Select
        onSelect={(value: any) => {
          if (value) {
            if (shouldShowPopover) {
              setPopupVisible(true);
            }
            onSelectCallback?.(value);
          }
        }}
        options={options}
        data-testid={selectTestId}
        placeholder={selectPlaceholder}
        style={selectStyle}
        disabled={disabled}
        dropdownStyle={{ minWidth: 'fit-content' }}
        {...searchableSelectParams}
      />
    </Popconfirm>
  );
};
