import React from 'react';
import { ContextProviderFC } from '../../../util/ContextProvider';
import { RunsAction } from './actions';
import { initialState, runsReducer, RunsState } from './reducer';

export const InterestRunsContext = React.createContext<{
  state: RunsState;
  dispatch: React.Dispatch<RunsAction>;
}>({} as any);

interface RunsContextProviderProps {
  state?: Partial<RunsState>;
}

export const InterestRunsContextProvider: ContextProviderFC<RunsContextProviderProps> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };
  const [state, dispatch] = React.useReducer(runsReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <InterestRunsContext.Provider value={value}>{props.children}</InterestRunsContext.Provider>;
};
