import React from 'react';
import { getCurrencySettingsFromSubscriptionBillingAddress } from '../../../pages/SubscriptionSettings/subscriptionSettingsUtil';
import BaseCurrencyFormatter from '../BaseCurrencyFormatter/BaseCurrencyFormatter';

interface Props {
  total: number | string;
  countryId?: string | null;
}

export default function SubscriptionCurrencyFormatter({ total, countryId }: Props) {
  return (
    <BaseCurrencyFormatter
      total={total}
      currencySettings={getCurrencySettingsFromSubscriptionBillingAddress(countryId)}
    />
  );
}
