import React from 'react';
import dayJs from 'dayjs';
import { useGetStripePaymentIntentDetails } from '../../hooks/stripeHooks';
import ModalWithCloseConfirm from '../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { CustomFlexContainerMixin, CustomFlexContainerType } from '../../globalStyles.style';
import { translations } from '../../constants/translations';
import {
  StripeStatusNameKey,
  stripePaymentIntentStatusConfig,
} from '../../constants/referenceData/stripeReferenceData';
import { getTag } from '../../util/tags';
import { PriceValue } from '../../components/PriceValue/PriceValue';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';

type StripePaymentDetailsModalProps = {
  organizationId: string;
  practiceId: string;
  intentId: string;
  onClose: () => void;
  title: React.ReactElement;
};

const FlexContainerWithMargin = styled.div<{ noMargin?: boolean } & CustomFlexContainerType>`
  ${CustomFlexContainerMixin}
  margin: ${({ noMargin }) => (noMargin ? '0' : '6px 0')};
`;

export const StripePaymentDetailsModal: React.FC<StripePaymentDetailsModalProps> = ({
  organizationId,
  practiceId,
  intentId,
  onClose,
  title,
}) => {
  const { detailsLoading, paymentDetails } = useGetStripePaymentIntentDetails(organizationId, practiceId, intentId);
  const statusConfig = stripePaymentIntentStatusConfig[paymentDetails?.status as StripeStatusNameKey];

  const modalTranslations = translations.stripeDetailsModal;

  const paymentDetailsInfo = !detailsLoading
    ? [
        {
          label: modalTranslations.status,
          value: getTag(statusConfig.color, statusConfig.text),
          noMargin: true,
        },
        {
          label: modalTranslations.dateReceived,
          value: dayJs(paymentDetails?.created).format('MMM DD, HH:mm A'),
        },
        {
          label: modalTranslations.amount,
          value: <PriceValue value={Number(paymentDetails?.amount)} />,
        },
        ...(paymentDetails?.amount_refunded && paymentDetails?.amount_refunded !== '0'
          ? [
              {
                label: modalTranslations.amountRefunded,
                value: <PriceValue value={Number(paymentDetails?.amount_refunded)} />,
              },
            ]
          : []),
        {
          label: modalTranslations.processingFee,
          value: <PriceValue value={Number(paymentDetails?.application_fee_amount)} />,
        },
        {
          label: modalTranslations.net,
          value: <PriceValue value={Number(paymentDetails?.net_amount)} />,
        },
      ]
    : [];

  return (
    <ModalWithCloseConfirm
      open
      title={title}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      isSaving={detailsLoading}
      savingMessage={translations.shared.loading}
    >
      <Title level={5}>{translations.stripeDetailsModal.paymentDetails}</Title>
      {paymentDetailsInfo.map(({ label, value, noMargin }) => (
        <FlexContainerWithMargin key={label} direction='row' justifyContent='space-between' noMargin={noMargin}>
          <span>{label}</span>
          <span>{value}</span>
        </FlexContainerWithMargin>
      ))}
    </ModalWithCloseConfirm>
  );
};
