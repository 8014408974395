import React, { useCallback } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Button, Popconfirm, Row } from 'antd';
import { SaveSpinnerAndNavigationWarning } from '../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { translations } from '../../constants/translations';
import {
  TitleContainer,
  TitleWrapper,
  ButtonWithSpaces,
  ColWithTextAligning,
  ModalWithStyles,
} from './ModalWithCloseConfirm.styles';

export interface ModalWithCloseConfirmProps extends Omit<ModalProps, 'closable' | 'maskClosable'> {
  changesMade?: boolean;
  isSaving?: boolean;
  cancelConfirmMessage?: string;
  warningMessageOnRouteChange?: string;
  savingMessage?: string;
  hideCloseIcon?: boolean;
  popConfirmSaveMessage?: string | React.ReactNode;
  progress?: number;
  isNavWarningRedundant?: boolean;
}

export const modalWithCloseConfirmTestId = {
  closeBtn: 'modalWithCloseConfirmCloseBtn',
  okBtn: 'modalWithCloseConfirmConfirmOkBtn',
  cancelBtn: 'modalWithCloseConfirmCancelBtn',
};

export const ModalFooterButtonsForReset = ({
  hasDataChanged,
  handleOnReset,
  disableButton,
  hasCancelOption,
  isSaving,
  handleOnCancel,
}: {
  hasDataChanged: boolean;
  handleOnReset: () => void;
  disableButton?: boolean;
  hasCancelOption?: boolean;
  isSaving?: boolean;
  handleOnCancel?: () => void;
}) => (
  <Row>
    <ColWithTextAligning span={24}>
      {hasDataChanged && (
        <ButtonWithSpaces htmlType='button' onClick={handleOnReset}>
          {translations.shared.resetButtonText}
        </ButtonWithSpaces>
      )}
      {hasCancelOption && (
        <ButtonWithSpaces
          onClick={handleOnCancel}
          key='back'
          disabled={isSaving}
          data-testid={modalWithCloseConfirmTestId.cancelBtn}
        >
          {translations.shared.modalClosePopConfirm.footer.cancelText}
        </ButtonWithSpaces>
      )}
      <Button
        type='primary'
        htmlType='submit'
        data-testid={modalWithCloseConfirmTestId.okBtn}
        disabled={disableButton && hasCancelOption}
      >
        {translations.shared.saveButtonText}
      </Button>
    </ColWithTextAligning>
  </Row>
);

const ModalWithCloseConfirm: React.FC<ModalWithCloseConfirmProps> = ({
  title,
  onOk,
  onCancel,
  cancelButtonProps,
  hideCloseIcon,
  okButtonProps,
  cancelText,
  okText,
  footer,
  changesMade,
  cancelConfirmMessage,
  isSaving,
  warningMessageOnRouteChange,
  savingMessage,
  children,
  popConfirmSaveMessage,
  progress,
  isNavWarningRedundant = false,
  ...otherProps
}) => {
  const handleOnCancel = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isSaving) {
        return;
      }
      if (e) {
        onCancel?.(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      }
    },
    [isSaving, onCancel]
  );

  const handleOnOk = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isSaving) {
        return;
      }
      if (e) {
        onOk?.(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      }
    },
    [isSaving, onOk]
  );

  const getRenderedTitle = useCallback(() => {
    const titleContainer = <TitleContainer className='modal-title'>{title}</TitleContainer>;
    if (hideCloseIcon) {
      return <div>{titleContainer}</div>;
    }
    return changesMade ? (
      <TitleWrapper>
        {titleContainer}
        <Popconfirm
          icon={<ExclamationCircleOutlined />}
          title={cancelConfirmMessage || translations.shared.modalClosePopConfirm.confirmMessage}
          okText={translations.shared.popconfirm.no}
          cancelText={translations.shared.popconfirm.ok}
          placement='bottomRight'
          onCancel={handleOnCancel}
        >
          <CloseOutlined data-testid={modalWithCloseConfirmTestId.closeBtn} />
        </Popconfirm>
      </TitleWrapper>
    ) : (
      <TitleWrapper>
        {titleContainer}
        <CloseOutlined onClick={handleOnCancel} data-testid={modalWithCloseConfirmTestId.closeBtn} />
      </TitleWrapper>
    );
  }, [cancelConfirmMessage, handleOnCancel, changesMade, title, hideCloseIcon]);

  const getFooter = useCallback(() => {
    if (footer !== undefined) {
      return footer;
    }

    const getCancelButton = (onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
      return (
        <Button
          onClick={onClick}
          key='back'
          disabled={isSaving}
          {...cancelButtonProps}
          data-testid={modalWithCloseConfirmTestId.cancelBtn}
        >
          {cancelText || translations.shared.modalClosePopConfirm.footer.cancelText}
        </Button>
      );
    };

    const getSaveButton = (onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
      return (
        <Button
          key='submit'
          type='primary'
          htmlType='submit'
          disabled={isSaving}
          onClick={onClick}
          {...okButtonProps}
          data-testid={modalWithCloseConfirmTestId.okBtn}
        >
          {okText || translations.shared.modalClosePopConfirm.footer.okText}
        </Button>
      );
    };

    return (
      <>
        <Popconfirm
          icon={<ExclamationCircleOutlined />}
          title={cancelConfirmMessage || translations.shared.modalClosePopConfirm.confirmMessage}
          okText={translations.shared.popconfirm.no}
          cancelText={translations.shared.popconfirm.ok}
          placement={'topRight'}
          onCancel={handleOnCancel}
          key='back-popconfirm'
          disabled={!changesMade}
        >
          {getCancelButton(changesMade ? undefined : handleOnCancel)}
        </Popconfirm>

        {popConfirmSaveMessage && onOk ? (
          <Popconfirm
            icon={<ExclamationCircleOutlined />}
            title={popConfirmSaveMessage}
            okText={translations.shared.popconfirm.ok}
            cancelText={translations.shared.popconfirm.no}
            onConfirm={handleOnOk}
            placement={'topRight'}
            disabled={okButtonProps?.disabled}
            key='ok-popconfirm'
          >
            {getSaveButton()}
          </Popconfirm>
        ) : (
          getSaveButton(onOk as (e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
        )}
      </>
    );
  }, [
    cancelButtonProps,
    cancelConfirmMessage,
    cancelText,
    changesMade,
    footer,
    handleOnCancel,
    isSaving,
    okButtonProps,
    okText,
    onOk,
    handleOnOk,
    popConfirmSaveMessage,
  ]);

  return (
    <ModalWithStyles
      title={getRenderedTitle()}
      closable={false}
      maskClosable={false}
      footer={getFooter()}
      style={{ top: 50 }}
      {...otherProps}
    >
      <SaveSpinnerAndNavigationWarning
        isSaving={!!isSaving}
        showNavigationWarning={!!changesMade && !isNavWarningRedundant}
        warningMessage={warningMessageOnRouteChange}
        savingMessage={savingMessage}
        progress={progress}
      >
        {children}
      </SaveSpinnerAndNavigationWarning>
    </ModalWithStyles>
  );
};

export default ModalWithCloseConfirm;
