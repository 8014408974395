import { NoteUpsertValues } from '../RecordSingleView/Notes/noteUtils';
import { Note, NoteUpsertRecord } from '../../graph/types';

export const createUpsertRecordForNewNote = (updatedValues: NoteUpsertValues, dateFormat: string): NoteUpsertRecord => {
  return {
    date: updatedValues.date.format(dateFormat),
    type_id: updatedValues.type_id,
    value: updatedValues.textContent,
    preview: createPreviewTextForNote(updatedValues.plainTextContent),
    hidden: false,
    locked: false,
    draft: false,
  };
};

export const createUpsertRecordForExistingNote = (
  existingNote: Note,
  updatedValues: NoteUpsertValues,
  dateFormat: string
): NoteUpsertRecord => {
  return {
    date: updatedValues.date.format(dateFormat),
    type_id: updatedValues.type_id,
    preview: createPreviewTextForNote(updatedValues.plainTextContent),
    value: updatedValues.textContent,
    hidden: existingNote.hidden,
    locked: existingNote.locked,
    draft: false,
  };
};

export const createPreviewTextForNote = (value?: string | null) =>
  value && (value.length > 255 || (value.match(/\n/g) || '').length > 3)
    ? value.split(/\n/g).slice(0, 3).join('\n').substring(0, 252) + '...'
    : value;
