import gql from 'graphql-tag';
import { DepositRunFields, DepositRunResultFields } from '../fragments/depositRunFragments';

export const UpsertDepositRun = gql`
  mutation UpsertDepositRun($depositRun: DepositRunUpsert!, $organizationId: ID!) {
    upsertDepositRun(depositRun: $depositRun, organizationId: $organizationId) {
      ...DepositRunFields
    }
  }
  ${DepositRunFields}
`;

export const SendDepositRunToQuickbooks = gql`
  mutation SendDepositRunToQuickbooks(
    $organizationId: ID!
    $practiceId: ID
    $actionInput: QuickbooksSendDepositRunRequest!
  ) {
    sendDepositRunQuickbooks(organizationId: $organizationId, practiceId: $practiceId, actionInput: $actionInput) {
      ...DepositRunResultFields
    }
  }
  ${DepositRunResultFields}
`;

export const GetDepositRun = gql`
  query getDepositRun($organizationId: ID!, $practiceId: ID!, $filter: DepositRunFilter) {
    getDepositRun(organizationId: $organizationId, practiceId: $practiceId, filter: $filter) {
      ...DepositRunFields
    }
  }
  ${DepositRunFields}
`;

export const DeleteDepositRunFromQuickbooks = gql`
  mutation deleteDepositRunQuickbooks(
    $organizationId: ID!
    $practiceId: ID!
    $actionInput: QuickbooksDeleteDepositRunRequest!
  ) {
    deleteDepositRunQuickbooks(organizationId: $organizationId, practiceId: $practiceId, actionInput: $actionInput) {
      ...DepositRunResultFields
    }
  }
  ${DepositRunResultFields}
`;
