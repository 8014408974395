import { ServicesRenderedState } from '../store/reducer';
import { ServiceRendered } from '../../../graph/types';
import { useEffect } from 'react';
import { noop } from 'lodash';

export const useAddServiceKeydownHandling = (
  state: ServicesRenderedState,
  saveService: (serviceRendered: ServiceRendered | undefined) => void,
  cancelEditing: () => void,
  isUnchargedServices?: boolean
) => {
  useEffect(() => {
    if (state.editingServiceRenderedId) {
      const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          const currentServicesRendered = isUnchargedServices
            ? state.unchargedServicesRendered
            : state.servicesRendered;
          saveService(
            currentServicesRendered.find((serviceRendered) => serviceRendered.id === state.editingServiceRenderedId)
          );
        } else if (event.key === 'Escape') {
          event.preventDefault();
          cancelEditing();
        }
      };

      document.addEventListener('keydown', handleKeydown);
      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    }
    return noop;
  });
};
