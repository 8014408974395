import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import {
  GetApprovals,
  GetHistory,
  GetThirdPartyTypes,
  GetThirdPartyValidation,
} from '../../../graph/queries/thirdPartyData';

export const useGetApprovals = (organizationId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getServicesRendered3pApprovals'>(GetApprovals, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    approvals: data?.getServicesRendered3pApprovals,
    approvalsLoading: loading,
    approvalsError: error,
    refetch,
  };
};

export const useGetHistory = (organizationId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getServicesRendered3pHistory'>(GetHistory, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    history: data?.getServicesRendered3pHistory,
    historyLoading: loading,
    historyError: error,
  };
};

export const useGetThirdPartyTypes = (organizationId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getOrganization'>(GetThirdPartyTypes, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    thirdPartyTypes: data?.getOrganization?.ref_system?.third_party,
    loading,
    error,
  };
};

export const useGetThirdPartyValidation = (
  organizationId: string,
  practiceId: string,
  thirdPartyId?: string,
  emailAddress?: string
) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getThirdPartyValidation'>(GetThirdPartyValidation, {
    variables: {
      organizationId,
      practiceId,
      thirdPartyValidationFilter: {
        third_party_id: thirdPartyId,
        email_address: emailAddress,
      },
    },
    skip: !thirdPartyId || !emailAddress,
  });

  return {
    emailValidated: data?.getThirdPartyValidation?.email_address_valid,
    validationLoading: loading,
    validationError: error,
  };
};
