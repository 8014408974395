import { Contact, ContactUpsert } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaTypeWithUpsert, RxSchemaWithDocument } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';

type ContactOfflineFields = Omit<Contact, 'note' | 'file' | 'ledger'>;
export type RxContact = RxSchemaTypeWithUpsert<ContactOfflineFields, ContactUpsert, 'contact'> & RxSchemaWithDocument;

export const contactSchema: RxJsonSchema<RxContact> = {
  version: 9,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    updated: RxScalars.String,
    number: Maybe(RxScalars.String),
    name: RxScalars.String,
    contact_status_id: RxScalars.ID,
    contact_status_name: RxScalars.String,
    email: Maybe(RxScalars.String),
    dob: Maybe(RxScalars.String),
    send_type: RxScalars.Int,
    statement_ignore: RxScalars.Boolean,
    interest_ignore: RxScalars.Boolean,
    balance_unposted: RxScalars.String,
    balance_posted: RxScalars.String,
    type_name_keys: Maybe(RxScalars.Array),
    tag_names: Maybe(RxScalars.Array),
    address: Maybe(RxScalars.Array),
    phone: Maybe(RxScalars.Array),
    info: Maybe(RxScalars.Array),
    text: Maybe(RxScalars.Array),
    type: Maybe(RxScalars.Array),
    tag: Maybe(RxScalars.Array),
    credit_card: Maybe(RxScalars.Array),
    tax_exempt: Maybe(RxScalars.Array),
    is_new: RxScalars.Boolean,
    offline_id: Maybe(RxScalars.String),
    hidden: RxScalars.Boolean,
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    document: RxScalars.String,
    communication: Maybe(RxScalars.Array),
    third_party: Maybe(RxScalars.Array),
    alert: Maybe(RxScalars.Array),
  },
  required: ['id'],
  indexes: ['updated', ['id', 'hidden'], ['id, offline_id']],
};
