import React, { useCallback, useEffect, useState } from 'react';
import { AddressDto, Maybe, Patient, PrescriptionFlattened } from '../graph/types';
import { Form, Select } from 'antd';
import { PracticeSettingsNameKey, translations } from '../constants/translations';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { displayAsDate } from '../constants/formats';
import ModalWithCloseConfirm from '../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useUserLocaleData } from './useUserLocale';
import { useLDFlag } from './useLDHooks';
import { LDFlagNames } from '../constants/launchDarkly';
import { defaultPractice } from '../contexts/organization/selectors';
import { useOrganizationContext } from '../contexts/organization/state';
import { PrescriptionLabelNameKeys, prescriptionLabelNameKeyConfig } from '../constants/prescriptions';
import { getPracticeSetting } from '../pages/Contacts/ViewContact/statementUtils';
import { getLabelFromTranslation } from '../components/InfoTable/infoTableUtil';
import { countryProvStateTranslations } from '../constants/countryTranslations';

const fitStringToLength = (label: string, maxLength: number) => {
  let cleanLabel = label.replace(/<br\s*\/?>/gi, '\n');
  cleanLabel = cleanLabel.replace(/<[^>]+>/g, '');
  let wrappedText = '';
  const words = cleanLabel.split(' ');

  let line = '';
  let lineCount = 0;
  words.forEach((word) => {
    if ((line + word).length > maxLength) {
      if (lineCount < 4) {
        wrappedText += line.trim() + '\n';
        lineCount++;
      }
      line = '';
    }
    line += word + ' ';
  });

  if (lineCount < 4) {
    wrappedText += line.trim();
  } else {
    wrappedText = wrappedText.trim() + '...';
  }

  return wrappedText;
};

export const usePrescriptionPrint = (patient?: Maybe<Patient>) => {
  const labelsEnabled = useLDFlag(LDFlagNames.PrescriptionLabels);
  const { state } = useOrganizationContext();
  const practice = defaultPractice(state);
  const [dymo] = useState((window as any).dymo);
  const [selectedPrescription, setSelectedPrescription] = useState<PrescriptionFlattened>();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const getFormattedAddress = (address?: AddressDto) => {
    if (!address) {
      return '';
    }
    return `${address.address_1}, ${address.address_2 ? `${address.address_2},` : ''} ${
      address.city
    }, ${getLabelFromTranslation(countryProvStateTranslations, address.country_prov_state_name_key ?? '')} ${
      address.postal_zip
    }`;
  };

  useEffect(() => {
    if (labelsEnabled && dymo.label.framework.init) {
      dymo.label.framework.init();
    }
  }, [dymo, labelsEnabled]);

  const initialPrinterProps = {
    visible: false,
    content: null,
  };
  const [printModalProps, setPrintModalProps] = useState<{
    visible: boolean;
    content: React.ReactElement | null;
    label?: string;
  }>(initialPrinterProps);
  const [selectedPrinter, setSelectedPrinter] = useState<string | undefined>();

  const openPrinterModal = useCallback(
    async (item: PrescriptionFlattened) => {
      setSelectedPrescription(item);
      const isDymoSetup = dymo.label.framework.checkEnvironment();
      if (isDymoSetup.isBrowserSupported || isDymoSetup.isWebServicePresent) {
        <p>{translations.patientPage.prescriptions.print.browserNotSupported}</p>;
      }
      if (isDymoSetup.isFrameworkInstalled) {
        const printers = await dymo.label.framework.getLabelWriterPrintersAsync();
        const printerOptions = printers.map((p: any) => ({ label: p.name, value: p.name }));

        const content = (
          <Form.Item label={translations.patientPage.prescriptions.print.selectPrinter}>
            <Select options={printerOptions} onChange={setSelectedPrinter} />
          </Form.Item>
        );
        setPrintModalProps((printModalProps) => ({ ...printModalProps, visible: true, content }));
      } else {
        const content = (
          <div
            dangerouslySetInnerHTML={{
              __html: translations.patientPage.prescriptions.print.printerSoftwareNotConfigured,
            }}
            onClick={(e) => {
              if (e.target instanceof HTMLAnchorElement) {
                setPrintModalProps({ visible: false, content: null });
              }
            }}
          />
        );
        setPrintModalProps({ visible: true, content });
      }
    },
    [dymo.label.framework]
  );

  const printPrescription = async () => {
    const labelJson = getPracticeSetting(PracticeSettingsNameKey.PrescriptionLabel, practice).value;
    const labelName = JSON.parse(labelJson).name_key;
    if (selectedPrescription && selectedPrinter && labelName) {
      const formatDate = (date?: Maybe<string>) => (date ? displayAsDate(date, dateFormat) : '');
      const labelData = [
        { field: 'RxNumber', value: selectedPrescription.number },
        { field: 'PracticeName', value: practice?.name },
        { field: 'PracticeAddress', value: getFormattedAddress(practice?.address[0]) },
        { field: 'PracticePhone', value: practice?.phone[0].phone },
        { field: 'DoctorName', value: selectedPrescription.prescribed_user_name },
        { field: 'PatientName', value: selectedPrescription.patient_name },
        { field: 'Species', value: patient?.species_name },
        { field: 'ContactName', value: selectedPrescription.contact_name },
        { field: 'PrescriptionDate', value: formatDate(selectedPrescription.prescribed_date) },
        {
          field: 'PrescriptionExpiryDate',
          value: formatDate(selectedPrescription.prescription_expiry_date),
        },
        {
          field: 'ProductExpiryDate',
          value: formatDate(selectedPrescription.product_expiry_date),
        },
        { field: 'FillDate', value: formatDate(selectedPrescription.filled_date) },
        { field: 'ServiceName', value: selectedPrescription.item_description },
        { field: 'Quantity', value: selectedPrescription.item_quantity },
        { field: 'Unit', value: selectedPrescription.item_unit },
        { field: 'Refills', value: selectedPrescription.refills },
        { field: 'AvailableRefills', value: selectedPrescription.remaining_fills ?? 0 },
        {
          field: 'Instructions',
          value: fitStringToLength(
            selectedPrescription.filled_item_instructions ?? '',
            prescriptionLabelNameKeyConfig[labelName as PrescriptionLabelNameKeys].instructionsMaxLength
          ),
        },
      ];

      const response = await fetch(
        `${process.env.PUBLIC_URL}/labels/${
          prescriptionLabelNameKeyConfig[labelName as PrescriptionLabelNameKeys].fileName
        }.dymo`
      );
      const labelXml = await response.text();
      const label = dymo.label.framework.openLabelXml(labelXml);

      const labelNames: string[] = label.getObjectNames();

      labelData.forEach((data) => {
        if (labelNames.findIndex((name) => name === data.field) >= 0) {
          label.setObjectText(data.field, data.value ?? '');
        }
      });

      dymo.label.framework.printLabel(selectedPrinter, '', label);
      setPrintModalProps({ ...initialPrinterProps });
    } else {
      showErrorMessage(translations.shared.generalErrorMessage);
    }
  };

  const renderPrintModal = () => (
    <>
      {labelsEnabled && (
        <ModalWithCloseConfirm
          open={printModalProps.visible}
          title={translations.patientPage.prescriptions.print.title}
          onCancel={() => setPrintModalProps(initialPrinterProps)}
          onOk={printPrescription}
          okText={translations.shared.print}
          okButtonProps={{ disabled: !selectedPrinter }}
        >
          {printModalProps.content}
        </ModalWithCloseConfirm>
      )}
    </>
  );

  return {
    renderPrintModal,
    openPrinterModal: labelsEnabled && openPrinterModal,
  };
};
