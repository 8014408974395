import React, { useState } from 'react';
import { Contact, ContactAlert, ContactAlertTypeDto, ContactAlertUpsert } from '../../../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { translations } from '../../../../constants/translations';
import { contactAlertPropertyNames } from '../../../../constants/propertyNames';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../../util/filterAndSorting';
import { TableKey, useTableResetFilterAndSort } from '../../../../hooks/tableHooks';
import { Input } from 'antd';
import {
  ColumnWithEditableField,
  TableWithInlineEdit,
} from '../../../../components/TableWithInlineEdit/TableWithInlineEdit';
import { SelectWithWidth } from '../../../../globalStyles.style';
import { mapRefDataForSelect } from '../../../../util/refDataUtil';
import { useContactOfflineUpdate, useUpdateContact } from '../../../../hooks/ajax/contact/contactHooks';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { ErrorMessage } from '../../../../components/ErrorMessage/ErrorMessage';
import { validateAlert } from '../../../../util/validationsUtil';

// styles
import './ContactAlerts.less';

type ContactAlertsProps = {
  contact: Contact;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  alertReferenceData: ContactAlertTypeDto[];
};

export const alertNoteTestId = 'alert-note';

export const ContactAlerts: React.FC<ContactAlertsProps> = ({
  contact,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  alertReferenceData,
}) => {
  const [upsertContact] = useUpdateContact();
  const { enabledAndOffline } = useOffline();
  const offlineUpsert = useContactOfflineUpdate(contact.id);
  const organizationId = useGetOrganizationIdFromRoute();
  const { filteredValue, sortOrderMap, tableChangeHandler } = useTableResetFilterAndSort();
  const [isSaving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const contactAlerts = contact.alert ?? [];

  const alertTypeOptions = alertReferenceData.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const columns: ColumnWithEditableField<ContactAlert>[] = [
    {
      title: translations.viewContactPage.alerts.columns.alert,
      key: contactAlertPropertyNames.type_id,
      dataIndex: contactAlertPropertyNames.type_id,
      ...generalFilteringAndSortingSettings,
      filteredValue: filteredValue[contactAlertPropertyNames.type_id],
      sortOrder: sortOrderMap[contactAlertPropertyNames.type_id],
      onFilter: getOnFilterFunctionFor(contactAlertPropertyNames.type_id),
      sorter: getStringCompareFunctionFor(contactAlertPropertyNames.type_id),
      render: (value: string) => {
        const name = alertReferenceData.find(({ id }) => id === value)?.name;
        return name;
      },
      renderEdit: (onChange: (key: string, value: string) => void) => (typeId: string, alert: ContactAlert) => {
        if (!typeId) {
          // force set a default statusId
          onChange(contactAlertPropertyNames.type_id, alertTypeOptions[0].value);
        }
        return (
          <SelectWithWidth
            key={typeId}
            options={alertTypeOptions}
            defaultValue={
              typeId
                ? mapRefDataForSelect(
                    {
                      id: alert.type_id,
                      name: alert.note ?? '',
                    },
                    alertReferenceData
                  )
                : alertTypeOptions[0].value
            }
            onChange={(value: any) => onChange(contactAlertPropertyNames.type_id, value)}
          />
        );
      },
    },
    {
      title: translations.viewContactPage.alerts.columns.note,
      key: contactAlertPropertyNames.note,
      dataIndex: contactAlertPropertyNames.note,
      ...generalFilteringAndSortingSettings,
      filteredValue: filteredValue[contactAlertPropertyNames.note],
      sortOrder: sortOrderMap[contactAlertPropertyNames.note],
      onFilter: getOnFilterFunctionFor(contactAlertPropertyNames.note),
      sorter: getStringCompareFunctionFor(contactAlertPropertyNames.note),
      renderEdit: (onChange: (key: string, value: string) => void) => (note: string) =>
        (
          <>
            <Input
              placeholder={translations.viewContactPage.alerts.columns.note}
              defaultValue={note}
              onChange={({ target: { value } }) => onChange(contactAlertPropertyNames.note, value)}
              data-testid={alertNoteTestId}
            />
            {errors.note && (
              <ErrorMessage
                message={translations.shared.getIsRequiredMessage(translations.viewContactPage.alerts.columns.note)}
              />
            )}
          </>
        ),
    },
  ];

  const handleDeleteAlert = async (alert: ContactAlert) => {
    setSaving(true);
    const alertUpsert: ContactAlertUpsert = {
      id: alert.id,
      void: true,
    };

    const contactUpsert = {
      id: contact.id,
      alert: [alertUpsert],
    };
    try {
      if (!enabledAndOffline) {
        await upsertContact({ variables: { organizationId, contact: contactUpsert } });
      } else {
        await offlineUpsert?.(contactUpsert);
      }
    } finally {
      setSaving(false);
    }
  };

  const handleSaveAlert = async (alert: ContactAlert) => {
    if (!validateAlert(alert, setErrors)) {
      return false;
    }
    setSaving(true);
    const alertUpsert: ContactAlertUpsert = {
      id: alert.id,
      record: {
        type_id: alert.type_id,
        note: alert.note,
      },
    };
    const contactUpsert = {
      id: contact.id,
      alert: [alertUpsert],
    };
    try {
      if (!enabledAndOffline) {
        await upsertContact({ variables: { organizationId, contact: contactUpsert } });
      } else {
        await offlineUpsert?.(contactUpsert);
      }
    } finally {
      setSaving(false);
    }
    return true;
  };

  return (
    <TableWithInlineEdit
      tableKey={TableKey.ContactAlerts}
      dataSource={contactAlerts}
      columns={columns}
      loading={isSaving}
      rowKey={'id'}
      onChange={tableChangeHandler}
      onSave={handleSaveAlert}
      onDelete={handleDeleteAlert}
      onEditModeChange={() => setErrors({})}
      hideAddButton={false}
      className='alerts-table row-vertical-align-top'
      handleIsEditing={handleIsEditing}
      setShouldResetOnTabChange={setShouldResetOnTabChange}
      shouldResetOnTabChange={shouldResetOnTabChange}
      deleteConfirmationMessage={translations.viewContactPage.alerts.deleteAlertText}
    />
  );
};
