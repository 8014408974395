import React, { useMemo, useState } from 'react';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { Organization } from '../../../graph/types';
import { useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { useGetCurrentUserLazy } from '../../../hooks/ajax/user/userHooks';
import { AddonCardOffline } from './AddonCardOffline';

type Props = {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
};

const AddonCardOfflineContainer: React.FC<Props> = ({ organization, organizationId, refetchOrganization }) => {
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const { getUser } = useGetCurrentUserLazy(organizationId);
  const [upsertLoading, setUpsertState] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const offlineAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id
      ),
    [organization]
  );

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const onClickToggle = async () => {
    const newValue = !offlineAddon?.enabled;

    if (newValue) {
      setShowSubscriptionModal(newValue);
    } else {
      await onUpsertSubscription(newValue);
      closeModalWithSyncData();
    }
  };

  const onUpsertSubscription = async (isToggled: boolean) => {
    setUpsertState(true);
    await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: !isToggled,
          ...(isToggled && {
            detail: [
              {
                level_id:
                  organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id,
              },
            ],
          }),
          ...(!isToggled && { fnDisableAddOn: [offlineAddon?.id ?? ''] }),
        },
      },
    });
    setUpsertState(false);
  };

  const closeModalWithSyncData = () => {
    setShowSubscriptionModal(false);
    getUser();
    refetchOrganization();
  };

  const onClose = () => {
    setShowSubscriptionModal(false);
  };

  const handleConfigureClick = () => {
    setShowSubscriptionModal(true);
  };

  return (
    <SaveSpinner isSaving={upsertLoading}>
      <AddonCardOffline
        isChecked={offlineAddon?.enabled}
        price={offlineAddon?.cost}
        onClick={onClickToggle}
        onConfigureClick={handleConfigureClick}
        countryId={countryId}
      />
      {showSubscriptionModal && (
        <SubscriptionUpdateModal onClose={onClose} onSuccess={closeModalWithSyncData} canReduceUserCount showOffline />
      )}
    </SaveSpinner>
  );
};

export default AddonCardOfflineContainer;
