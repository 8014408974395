import { NoteUpsert, ServiceRendered, ServiceRenderedUpsert } from '../../graph/types';
import dayjs from 'dayjs';
import { createPreviewTextForNote } from '../Records/noteUpsertUtils';
import { isUuid } from '../../util/offline/offlineUtil';

export const clearDummyId = (id: string) => {
  return id?.startsWith('dummy-id') ? undefined : id;
};

export const getUpsertForUpdatedServiceRendered = (
  updatedServiceRendered: ServiceRendered,
  dateFormat: string
): ServiceRenderedUpsert => {
  return {
    id: clearDummyId(updatedServiceRendered.id),
    record: {
      name: updatedServiceRendered.name,
      unit_name: updatedServiceRendered.unit_name,
      hidden: updatedServiceRendered.hidden,
      manufacturer_name: updatedServiceRendered.manufacturer_name,
      lot_number: updatedServiceRendered.lot_number,
      serial_number: updatedServiceRendered.serial_number,
      expiry_date: updatedServiceRendered.expiry_date,
      bottle_number: updatedServiceRendered.bottle_number,
      roa_id: updatedServiceRendered.roa_id,
      roa_other: updatedServiceRendered.roa_other,
      reason: updatedServiceRendered.reason,
      withdrawal_period: updatedServiceRendered.withdrawal_period,
    },
    orderRecord:
      updatedServiceRendered.sort_order === undefined
        ? undefined
        : {
            sort_order: updatedServiceRendered.sort_order,
          },
    financialRecord: {
      service_id: updatedServiceRendered.service_id,
      doctor_id: updatedServiceRendered.doctor_id,
      date: dayjs(updatedServiceRendered.date).format(dateFormat),

      business_center_id: updatedServiceRendered.business_center_id,
      location_id: updatedServiceRendered.location_id,

      quantity: updatedServiceRendered.quantity,
      list_unit_price: updatedServiceRendered.list_unit_price,
      unit_price: updatedServiceRendered.unit_price,
      discount: updatedServiceRendered.discount,
      hide_discount: updatedServiceRendered.hide_discount,
    },
    reminder:
      updatedServiceRendered.reminder?.[0]?.name && updatedServiceRendered.reminder?.[0]?.date
        ? [
            {
              id: updatedServiceRendered.reminder?.[0].id,
              record: {
                name: updatedServiceRendered.reminder[0].name,
                date: updatedServiceRendered.reminder[0].date,
                note: updatedServiceRendered.reminder[0].note,
              },
            },
          ]
        : undefined,
    prescriptions:
      updatedServiceRendered.prescriptions?.[0]?.contact_id &&
      updatedServiceRendered.prescriptions?.[0]?.patient_id &&
      updatedServiceRendered.prescriptions?.[0]?.prescribed_user_id &&
      updatedServiceRendered.prescriptions?.[0]?.prescribed_date &&
      updatedServiceRendered.prescriptions?.[0]?.prescription_expiry_date &&
      updatedServiceRendered.prescriptions?.[0]?.item_quantity &&
      updatedServiceRendered.prescriptions?.[0]?.practice_id
        ? [
            {
              id: updatedServiceRendered.prescriptions[0].id,
              record: {
                contact_id: updatedServiceRendered.prescriptions[0].contact_id,
                patient_id: updatedServiceRendered.prescriptions[0].patient_id,
                prescribed_user_id: updatedServiceRendered.prescriptions[0].prescribed_user_id,
                prescribed_date: updatedServiceRendered.prescriptions[0].prescribed_date,
                prescription_expiry_date: updatedServiceRendered.prescriptions[0].prescription_expiry_date,
                product_expiry_date: updatedServiceRendered.prescriptions[0].product_expiry_date,
                refills: updatedServiceRendered.prescriptions[0].refills ?? 0,
                item_description: updatedServiceRendered.prescriptions[0].item_description,
                item_quantity: updatedServiceRendered.prescriptions[0].item_quantity,
                item_unit: updatedServiceRendered.prescriptions[0].item_unit,
                instructions: updatedServiceRendered.prescriptions[0].instructions,
                practice_id: updatedServiceRendered.prescriptions[0].practice_id,
                fill_externally: updatedServiceRendered.prescriptions[0].fill_externally,
                created_type_id: updatedServiceRendered.prescriptions[0].created_type_id,
              },
              fills: [
                {
                  id: updatedServiceRendered.prescriptions?.[0]?.fill_id,
                  record: {
                    filled_item_instructions: updatedServiceRendered.prescriptions?.[0]?.fill_id
                      ? updatedServiceRendered.prescriptions?.[0]?.filled_item_instructions
                      : updatedServiceRendered.prescriptions[0].instructions,
                    filled_date: updatedServiceRendered.prescriptions[0].prescribed_date,
                    filled_user_id: updatedServiceRendered.prescriptions[0].prescribed_user_id,
                  },
                },
              ],
            },
          ]
        : undefined,
    info: updatedServiceRendered.info?.map((record) => ({
      id: record.id,
      record: { type_id: record.type_id, value: record.value ?? '' },
      void: false,
    })),
    text: updatedServiceRendered.text?.map((record) => ({
      id: record.id,
      record: { type_id: record.type_id, value: record.value ?? '' },
      void: false,
    })),
  };
};

export const getUpsertForNewServiceRenderedForBatchCreation = (
  updatedServiceRendered: ServiceRendered,
  dateFormat: string
): ServiceRenderedUpsert => {
  const createNoteUpsert = (): NoteUpsert | null => {
    if (!updatedServiceRendered.note) {
      return null;
    }
    return {
      record: {
        value: updatedServiceRendered.note.value,
        preview: createPreviewTextForNote(updatedServiceRendered.note.preview),
        type_id: updatedServiceRendered.note.type_id,
        date: updatedServiceRendered.date,
        patient_id: updatedServiceRendered.patient_id,
        hidden: updatedServiceRendered.note.hidden,
        locked: updatedServiceRendered.note.locked,
        draft: false,
      },
    };
  };

  return {
    note: createNoteUpsert(),
    record: {
      name: updatedServiceRendered.name,
      unit_name: updatedServiceRendered.unit_name,
      manufacturer_name: updatedServiceRendered.manufacturer_name,
      lot_number: updatedServiceRendered.lot_number,
      serial_number: updatedServiceRendered.serial_number,
      expiry_date: updatedServiceRendered.expiry_date,
      bottle_number: updatedServiceRendered.bottle_number,
      roa_id: updatedServiceRendered.roa_id,
      roa_other: updatedServiceRendered.roa_other,
      reason: updatedServiceRendered.reason,
      withdrawal_period: updatedServiceRendered.withdrawal_period,
    },
    orderRecord:
      updatedServiceRendered.sort_order === undefined
        ? undefined
        : {
            sort_order: updatedServiceRendered.sort_order,
          },
    financialRecord: {
      service_id: updatedServiceRendered.service_id,
      doctor_id: updatedServiceRendered.doctor_id,
      date: dayjs(updatedServiceRendered.date).format(dateFormat),

      business_center_id: updatedServiceRendered.business_center_id,
      location_id: updatedServiceRendered.location_id,

      quantity: updatedServiceRendered.quantity,
      list_unit_price: updatedServiceRendered.list_unit_price,
      unit_price: updatedServiceRendered.unit_price,
      hide_discount: updatedServiceRendered.hide_discount,
    },
    doctor: [
      {
        record: {
          doctor_id: updatedServiceRendered.doctor_id!,
          percentage: '100',
          primary: true,
        },
      },
    ],
    reminder:
      updatedServiceRendered.reminder?.[0].name && updatedServiceRendered.reminder?.[0].date
        ? [
            {
              id: updatedServiceRendered.reminder?.[0].id || undefined,
              record: {
                name: updatedServiceRendered.reminder?.[0].name,
                date: updatedServiceRendered.reminder?.[0].date,
                note: updatedServiceRendered.reminder?.[0].note,
              },
            },
          ]
        : undefined,
    groupRecord: updatedServiceRendered.grouping_id
      ? {
          is_parent: updatedServiceRendered.is_parent ?? false,
          grouping_id: updatedServiceRendered.grouping_id,
        }
      : undefined,
    prescriptions:
      updatedServiceRendered.prescriptions?.[0]?.contact_id &&
      updatedServiceRendered.prescriptions?.[0]?.patient_id &&
      updatedServiceRendered.prescriptions?.[0]?.prescribed_user_id &&
      updatedServiceRendered.prescriptions?.[0]?.prescribed_date &&
      updatedServiceRendered.prescriptions?.[0]?.prescription_expiry_date &&
      updatedServiceRendered.prescriptions?.[0]?.item_quantity &&
      updatedServiceRendered.prescriptions?.[0]?.practice_id
        ? [
            {
              ...(isUuid(updatedServiceRendered.prescriptions[0].id)
                ? undefined
                : { id: updatedServiceRendered.prescriptions[0].id }),
              record: {
                contact_id: updatedServiceRendered.prescriptions[0].contact_id,
                patient_id: updatedServiceRendered.prescriptions[0].patient_id,
                prescribed_user_id: updatedServiceRendered.prescriptions[0].prescribed_user_id,
                prescribed_date: updatedServiceRendered.prescriptions[0].prescribed_date,
                prescription_expiry_date: updatedServiceRendered.prescriptions[0].prescription_expiry_date,
                product_expiry_date: updatedServiceRendered.prescriptions[0].product_expiry_date,
                refills: updatedServiceRendered.prescriptions[0].refills ?? 0,
                item_description: updatedServiceRendered.prescriptions[0].item_description,
                item_quantity: updatedServiceRendered.prescriptions[0].item_quantity,
                item_unit: updatedServiceRendered.prescriptions[0].item_unit,
                instructions: updatedServiceRendered.prescriptions[0].instructions,
                practice_id: updatedServiceRendered.prescriptions[0].practice_id,
                fill_externally: updatedServiceRendered.prescriptions[0].fill_externally,
                created_type_id: updatedServiceRendered.prescriptions[0].created_type_id,
              },
              fills: [
                {
                  id: updatedServiceRendered.prescriptions?.[0]?.fill_id,
                  record: {
                    filled_item_instructions: updatedServiceRendered.prescriptions[0].instructions,
                    filled_date: updatedServiceRendered.prescriptions[0].prescribed_date,
                    filled_user_id: updatedServiceRendered.prescriptions[0].prescribed_user_id,
                  },
                },
              ],
            },
          ]
        : undefined,
    text: updatedServiceRendered.text?.map((t) => ({
      id: clearDummyId(t.id),
      record: {
        value: t.value ?? '',
        type_id: t.type_id,
      },
    })),
    info: updatedServiceRendered.info?.map((i) => ({
      id: clearDummyId(i.id),
      record: {
        value: i.value ?? '',
        type_id: i.type_id,
      },
    })),
  };
};
