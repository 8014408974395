import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Loading } from '../Loading/Loading';
import { routes } from '../../constants/routes';
import { isUserActive } from '../../util/loginUtil';
import { useOffline } from '../../util/offline/offlineUtil';
import { SignInLocationState } from '../../pages/SignIn/SignInPage';
import { useUserContext } from '../../contexts/user/state';

export const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [isLoggedInAndActive, setIsLoggedInAndActive] = useState<boolean>(false);
  const [hasAuthenticationLoaded, setHasAuthenticationLoaded] = useState<boolean>(false);
  const { isOnline } = useOffline();
  const {
    state: { user },
  } = useUserContext();

  useEffect(() => {
    const setIsUserLoggedInFromAmplify = async () => {
      try {
        if (isOnline) {
          await Auth.currentAuthenticatedUser();
          await isUserActive(user);
        }
        setIsLoggedInAndActive(true);
      } catch (_) {
        setIsLoggedInAndActive(false);
      }
      setHasAuthenticationLoaded(true);
    };

    setIsUserLoggedInFromAmplify();
  }, [setIsLoggedInAndActive, setHasAuthenticationLoaded, isOnline, user]);

  if (!hasAuthenticationLoaded) {
    return <Loading height={'90vh'} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedInAndActive ? (
          (children as React.ReactNode)
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location, reload: true } as SignInLocationState,
            }}
          />
        )
      }
    />
  );
};
