import { translations } from '../../constants/translations';
import {
  Info,
  OrganizationReferenceData,
  OrganizationUserType,
  OrganizationUserTypeDto,
  User,
} from '../../graph/types';
import { getUserTypeTag } from '../../util/tags';
import { mapRefDataName, mapToRefData } from '../../util/refDataUtil';
import { UserInfoType } from '../../constants/referenceData/userReferenceData';

export const getUserTypeTagsWithVoid = (refData: OrganizationUserType[], organizationId: string) => (user: User) => {
  const userOrganization = user.organization?.find((o) => o.organization_id === organizationId);

  return userOrganization?.type?.map((type) =>
    getUserTypeTag({
      ...type,
      type_name: mapRefDataName(mapToRefData(type), refData),
    })
  );
};

export const getUserTypeDtoTagsWithVoid =
  (refData: OrganizationUserTypeDto[], organizationId: string) => (user: User) => {
    const userOrganization = user.organization?.find((o) => o.organization_id === organizationId);

    return userOrganization?.type?.map((type) =>
      getUserTypeTag({
        ...type,
        type_name: mapRefDataName(mapToRefData(type), refData),
      })
    );
  };

export function getUserTypesFilterOptions(refData: OrganizationUserType[], users: User[] = [], organizationId: string) {
  const filterOptions: { [value: string]: string } = {};

  refData.forEach((species) => {
    filterOptions[species.id] = species.name;
  });

  users.forEach((user) => {
    const userOrganization = user.organization?.find((o) => o.organization_id === organizationId);

    userOrganization?.type?.forEach((type) => {
      if (!filterOptions[type.type_id]) {
        filterOptions[type.type_id] = translations.shared.getVoidRecord(type.type_name);
      }
    });
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}

export function getUserTypesFilterOptionsDto(
  refData: OrganizationUserTypeDto[],
  users: User[] = [],
  organizationId: string
) {
  const filterOptions: { [value: string]: string } = {};

  refData.forEach((species) => {
    filterOptions[species.id] = species.name;
  });

  users.forEach((user) => {
    const userOrganization = user.organization?.find((o) => o.organization_id === organizationId);

    userOrganization?.type?.forEach((type) => {
      if (!filterOptions[type.type_id]) {
        filterOptions[type.type_id] = translations.shared.getVoidRecord(type.type_name);
      }
    });
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}

export function getIfUserIsDoctor(refData: (OrganizationReferenceData & { doctor?: boolean })[], typeIds: string[]) {
  for (const typeId of typeIds) {
    if (refData.find((refItem) => refItem.id === typeId && refItem.doctor)) {
      return true;
    }
  }
  return false;
}

export function getUserInfoMap(userInfo: Info[]) {
  const infoMap: { [key in UserInfoType]?: Info } = {};

  userInfo.forEach((infoItem) => {
    infoMap[infoItem.type_id as UserInfoType] = infoItem;
  });

  return infoMap;
}
