import React, { useEffect, useState } from 'react';
import { useGetCurrentUser } from '../../hooks/ajax/user/userHooks';
import { Loading } from '../../components/Loading/Loading';
import { translations } from '../../constants/translations';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import styled from 'styled-components';
import { Button } from 'antd';
import {
  ChatBotQuestionRequest,
  sendChatBotQuestion,
  useGetChatBotAuthToken,
} from '../../hooks/ajax/chatBot/chatBotHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import TextArea from 'antd/lib/input/TextArea';

const Container = styled.div`
  padding: 0 1rem;
`;

const ChatContainer = styled.div`
  padding: 0 5rem;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const ConversationContainer = styled.div`
  border: 1px solid;
  border-radius: 2px;
  border-color: #dbdada;
  width: 100%;
  height: 35rem;
  margin-bottom: 10px;
  margin-top: 30px;
  overflow: auto;
`;

const ConversationDiv = styled.div`
  white-space: pre-wrap;
  padding: 5px;
`;

interface IConversationModel {
  question: string;
  response: string;
}

export const btnSubmitTestId = 'btn-submit';

export const Support: React.FC = () => {
  const practiceId = useDefaultPracticeId();
  const organizationId = useGetOrganizationIdFromRoute();
  const { user, userLoading } = useGetCurrentUser();
  const { chatBotAuthToken, chatBotAuthTokenLoading } = useGetChatBotAuthToken({ organizationId, practiceId });

  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [conversation, setConversation] = useState<IConversationModel[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const enabledSupportPage = useLDFlag(LDFlagNames.PageSupport);

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const scrollToBottom = () => {
    const conversationBox = document.querySelector('#conversation-container');
    if (conversationBox) {
      conversationBox.scrollTop = conversationBox.scrollHeight;
    }
  };

  if (!enabledSupportPage) {
    return <p>{translations.support.supportNotEnabledText}</p>;
  }

  if (userLoading || chatBotAuthTokenLoading) {
    return <Loading />;
  }

  if (!user) {
    return <p>{translations.viewUserPage.missingUser}</p>;
  }

  const submitClick = async () => {
    const request = {
      question: inputValue,
      user_id: user.id,
      user_email: user.email,
      user_name: user.name,
    } as ChatBotQuestionRequest;
    setIsRequesting(true);
    const response = await sendChatBotQuestion(
      chatBotAuthToken?.chatBotEndpoint ?? '',
      chatBotAuthToken?.jwt ?? '',
      request
    );
    setIsRequesting(false);
    const newConversationItem: IConversationModel = {
      question: inputValue,
      response,
    };
    setConversation((prevConversation) => [...prevConversation, newConversationItem]);
    setInputValue('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const getLoadingText = () => {
    if (isRequesting) {
      return translations.support.requestMessage;
    }
    return translations.shared.loading;
  };

  return (
    <SaveSpinner isSaving={userLoading || chatBotAuthTokenLoading || isRequesting} savingMessage={getLoadingText()}>
      <StyledPageHeaderWithMargin title={translations.support.title} />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: translations.support.pageDescription }} />
        <ChatContainer>
          <ConversationContainer id='conversation-container'>
            {conversation.map((item, index) => (
              <ConversationDiv key={index}>
                <p>
                  <strong>{user.name}</strong>: {item.question}
                </p>
                <p>
                  <strong>Cassy</strong>: {item.response}
                </p>
              </ConversationDiv>
            ))}
          </ConversationContainer>
          <FlexDiv>
            <TextArea
              id={translations.support.fields.chatInput}
              name={translations.support.fields.chatInput}
              autoSize={{ minRows: 1, maxRows: 8 }}
              style={{ width: '100%' }}
              maxLength={1000}
              onChange={handleInputChange}
              value={inputValue}
            />
            <Button type={'primary'} htmlType={'submit'} data-testid={btnSubmitTestId} onClick={submitClick}>
              {translations.support.submitText}
            </Button>
          </FlexDiv>
          <FlexDiv>{inputValue.length}/1000</FlexDiv>
        </ChatContainer>
      </Container>
    </SaveSpinner>
  );
};
