import React from 'react';
import './OwnershipTag.less';
import { TagWithNavigation } from '../../../../../../components/TagWithNavigation/TagWithNavigation';
import { routes } from '../../../../../../constants/routes';
import { withContactIdParameter } from '../../../../../../hooks/route/navigationHooks';

interface Owner {
  name?: string | null;
  percentage?: string | null;
  primary?: boolean | null;
}

interface OwnershipTagProps {
  owner: Owner;
  tagWidth?: number;
  navigationId: string;
}

const OwnershipTag: React.FC<OwnershipTagProps> = ({ owner, tagWidth, navigationId }) => (
  <TagWithNavigation
    displayText={`${owner.percentage}% ${owner.name}`}
    navigationParameter={navigationId ? withContactIdParameter(navigationId) : undefined}
    navigationRoute={navigationId && routes.viewContact}
    style={{ marginBottom: 2, marginTop: 2 }}
    className={owner.primary ? 'OwnershipTag_primary' : undefined}
    width={tagWidth ?? 200}
  />
);

export default OwnershipTag;
