import React from 'react';
import { DatePicker, Form, Input } from 'antd';
import { translations } from '../../constants/translations';
import dayjs from 'dayjs';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { useUserLocaleData } from '../../hooks/useUserLocale';

export interface VaccineModalFormValues {
  manufacturer?: string;
  lotNumber?: string;
  serialNumber?: string;
  expiryDate?: dayjs.Dayjs;
}

export interface VaccineDefaultData {
  manufacturer?: string;
  lot_number?: string;
  serial?: string;
  expiry_date?: dayjs.Dayjs;
}

export interface VaccineModalProps {
  autofocus?: boolean;
}

export const vaccineModalFormTestId = 'vaccineModalForm';
export const vaccineModalFormExpiryDateTestId = 'vaccineModalFormExpiryDatePicker';

export const VaccineModalContent: React.FC<VaccineModalProps> = ({ autofocus }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return (
    <>
      <MaxLengthFormItem
        maxLength={25}
        label={translations.shared.vaccineModal.fields.manufacturer}
        name='manufacturer'
      >
        <Input autoFocus={autofocus} />
      </MaxLengthFormItem>
      <MaxLengthFormItem maxLength={25} label={translations.shared.vaccineModal.fields.lotNumber} name='lotNumber'>
        <Input />
      </MaxLengthFormItem>
      <MaxLengthFormItem
        maxLength={25}
        label={translations.shared.vaccineModal.fields.serialNumber}
        name='serialNumber'
      >
        <Input />
      </MaxLengthFormItem>
      <Form.Item
        label={translations.shared.vaccineModal.fields.expiryDate}
        name='expiryDate'
        data-testid={vaccineModalFormExpiryDateTestId}
      >
        <DatePicker format={dateFormat} />
      </Form.Item>
    </>
  );
};
