import React, { PropsWithChildren } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { translations } from '../../constants/translations';
import { Store } from 'antd/lib/form/interface';
import { getMinLengthRule, getRequiredRule } from '../../util/forms';
import { useUpsertUser } from '../../hooks/ajax/user/userHooks';
import { UserUpsertGenerator } from '../../classes/upsertGenerators/UserUpsertGenerator';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import { useUserContext } from '../../contexts/user/state';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};

const currentPinField = 'current-pin';
const newPinField = 'new-pin';
const confirmPinField = 'confirm-pin';

interface ChangePinProps extends PropsWithChildren<unknown> {
  setIsSaving: (isSaving: boolean) => void;
  setHasUnsavedChanges: (hasUnsavedData: boolean) => void;
  hasUnsavedChanges: boolean;
}

export const ChangePin: React.FC<ChangePinProps> = ({ setIsSaving, hasUnsavedChanges, setHasUnsavedChanges }) => {
  const [form] = Form.useForm();
  const {
    state: { user },
  } = useUserContext();
  const organizationId = useGetOrganizationIdFromRoute();
  const [updateUser] = useUpsertUser();

  const handleValueChange = (_: Store, allValues: Store) => {
    const hasUnsavedChanges = !!allValues[currentPinField] || !!allValues[newPinField] || !!allValues[confirmPinField];
    setHasUnsavedChanges(hasUnsavedChanges);
  };

  const onFinish = async (values: Store) => {
    setIsSaving(true);
    const upsert = new UserUpsertGenerator({ id: user?.id }).addPinRecordToUpsert(btoa(values[newPinField]));
    await updateUser({
      variables: {
        user: upsert,
        organizationId,
      },
    })
      .then(() => {
        showSuccessMessage(translations.auth.savePinMessage);
      })
      .catch(() => {
        showErrorMessage(translations.auth.errorSavingPinMessage);
      });
    setIsSaving(false);
    resetForm();
  };

  const resetForm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
  };

  return (
    <Form {...layout} form={form} onFinish={onFinish} onValuesChange={handleValueChange} validateTrigger='onBlur'>
      <Row>
        <Col span={24}>
          <Form.Item
            label={translations.auth.newPin.label}
            name={newPinField}
            rules={[getRequiredRule(translations.auth.newPin.label), getMinLengthRule(4)]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name={confirmPinField}
            label={translations.auth.confirmPin.label}
            dependencies={[newPinField]}
            hasFeedback
            rules={[
              getRequiredRule(translations.auth.confirmPin.label),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(newPinField) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(translations.auth.confirmPin.nonMatchingErrorMessage);
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={5} span={24}>
          <Form.Item validateStatus={'error'}>
            {hasUnsavedChanges ? (
              <>
                <Button style={{ marginRight: '10px' }} type='primary' htmlType='submit'>
                  {translations.auth.changePin}
                </Button>
                <Button htmlType='button' onClick={resetForm}>
                  {translations.shared.cancelButtonText}
                </Button>
              </>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
