import React, { ReactNode, useContext, useMemo, useRef } from 'react';
import { useGetInvoiceContext } from '../../hooks/ajax/invoice/invoiceHooks';
import { translations } from '../../constants/translations';
import { Loading } from '../Loading/Loading';
import { RefSelectProps } from 'antd';
import { getDoctor, getDoctorOptions } from '../../pages/Invoices/AddInvoice/addInvoiceUtil';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { ServicesRenderedTable, UpdateServiceRenderedType } from './ServicesRenderedTable/ServicesRenderedTable';
import { ServicesRenderedContext } from './store/state';
import { useGetCurrentUserBasicFields } from '../../hooks/ajax/user/userHooks';
import { Caregiver, InvoiceContact, PrescriptionFlattened, ServiceRendered } from '../../graph/types';
import { setClickedColumnAction, setEditingServiceRenderedIdAction } from './store/actions';
import { getDefaultDoctorId } from '../../pages/Invoices/ViewInvoice/viewInvoiceUtil';
import {
  AddServiceForm,
  AddServiceFormProps,
  AddServiceFormValues,
  doctorFieldName,
  NewService,
} from './AddServiceForm/AddServiceForm';
import { NoteChangeRequest } from './NoteView/noteChangeUtil';
import { PatientBackupFunctions } from '../../pages/RapidBilling/RapidBillingServices/RapidBillingServices';
import { MicrochipModalFormValues } from '../MicrochipModalContent/MicrochipModalContent';

export interface ModifyAndViewServicesRenderedProps {
  practiceId: string;
  initialServicesRendered: ServiceRendered[];
  saveNewService?: (newServiceProperties: {
    services: NewService[];
    doctor: Caregiver;
    businessCenterId: string;
    locationId: string;
    microchipValues?: MicrochipModalFormValues;
  }) => Promise<ServiceRendered | undefined> | ServiceRendered;
  updateServiceRendered: UpdateServiceRenderedType;
  deleteServiceRendered: (serviceRendered: ServiceRendered) => void;
  patientBackupFunctions?: PatientBackupFunctions;
  activeServiceId?: string | null;
  setActiveServiceId: (serviceId: string | null) => void;
  tableFooter?: () => ReactNode;
  editable: boolean;
  hideAddServiceForm?: boolean;
  hideTable?: boolean;
  tableHeight: number;
  onlyAllowOnCellClickEditingOfActiveRow?: boolean;
  changeNote: (noteRequest: NoteChangeRequest) => void;
  invoiceContacts?: InvoiceContact[];
  patientId?: string;
  removedForRapidBilling?: boolean;
  prescriptions?: PrescriptionFlattened[];
  refetchPrescriptions?: () => void;
  formProps?: {
    disabled?: boolean;
    disabledMessage?: string;
  };
  isUnchargedServices?: boolean;
  showHeader?: boolean;
  onRowMove?: (oldIndex: number, newIndex: number) => void;
  labIconCallback?: () => void;
  onChargeDuplicationCheck?: (formValues: AddServiceFormValues) => boolean;
  hasHISAWarningAlreadyShown?: boolean;
  setHasHISAWarningAlreadyShown: (value: boolean) => void;
  isForHISAPatient?: boolean;
}

export const ModifyAndViewServicesRendered: React.FC<ModifyAndViewServicesRenderedProps> = ({
  practiceId,
  tableFooter,
  saveNewService,
  initialServicesRendered,
  editable,
  hideAddServiceForm,
  hideTable,
  updateServiceRendered,
  deleteServiceRendered,
  patientBackupFunctions,
  activeServiceId,
  setActiveServiceId,
  tableHeight,
  onlyAllowOnCellClickEditingOfActiveRow,
  changeNote,
  patientId,
  invoiceContacts,
  removedForRapidBilling,
  prescriptions,
  refetchPrescriptions,
  formProps,
  isUnchargedServices,
  showHeader,
  onRowMove,
  labIconCallback,
  onChargeDuplicationCheck,
  hasHISAWarningAlreadyShown,
  setHasHISAWarningAlreadyShown,
  isForHISAPatient,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { invoiceContext, invoiceContextLoading } = useGetInvoiceContext(organizationId, practiceId);
  const { user, userLoading } = useGetCurrentUserBasicFields();
  const { dispatch, state } = useContext(ServicesRenderedContext);
  const services = useMemo(() => state.cashedServices, [state.cashedServices]);

  const serviceSelectReference = useRef<RefSelectProps>(null);

  if (invoiceContextLoading || userLoading) {
    return <Loading height={'30vh'} />;
  }

  if (!invoiceContext || !user) {
    return <p>{translations.invoicePage.loadingError}</p>;
  }

  if (!invoiceContext || invoiceContext.business_center.length === 0 || invoiceContext.location.length === 0) {
    return <p>{translations.invoicePage.errors.businessCenterLocationNotSet}</p>;
  }

  const addNewServiceRendered: AddServiceFormProps['saveNewService'] = async (services: NewService[]) => {
    const doctor = getDoctor(invoiceContext, services[0]?.formValues?.[doctorFieldName])!;
    const newServiceRendered = await saveNewService?.({
      doctor,
      businessCenterId: invoiceContext.business_center[0].id,
      locationId: invoiceContext.location[0].id,
      services,
    });
    setActiveServiceId(newServiceRendered?.id ?? null);
    refetchPrescriptions?.();
  };

  const jumpToEditColumnOfActiveService = (column: keyof ServiceRendered) => {
    if (activeServiceId) {
      dispatch(setClickedColumnAction(column));
      dispatch(setEditingServiceRenderedIdAction(activeServiceId));
    }
  };

  const doctorOptions = getDoctorOptions(invoiceContext);
  const initialDoctor = getDefaultDoctorId(doctorOptions, user.id, initialServicesRendered);

  return (
    <>
      {!hideAddServiceForm && editable && (
        <AddServiceForm
          practiceId={practiceId}
          initialDoctor={initialDoctor}
          doctorOptions={doctorOptions}
          servicesList={services ?? []}
          saveNewService={addNewServiceRendered}
          jumpToEditColumnOfActiveService={jumpToEditColumnOfActiveService}
          serviceSelectReference={serviceSelectReference}
          invoiceContacts={invoiceContacts}
          patientId={patientId}
          removedForRapidBilling={removedForRapidBilling}
          onChargeDuplicationCheck={onChargeDuplicationCheck}
          userId={user.id}
          hasHISAWarningAlreadyShown={hasHISAWarningAlreadyShown}
          setHasHISAWarningAlreadyShown={setHasHISAWarningAlreadyShown}
          isForHISAPatient={isForHISAPatient}
          {...formProps}
        />
      )}
      {!hideTable && (
        <ServicesRenderedTable
          initialServicesRendered={initialServicesRendered}
          doctorOptions={doctorOptions}
          servicesList={services ?? []}
          invoiceContacts={invoiceContacts}
          addServiceFocusCallback={() => serviceSelectReference.current?.focus()}
          tableFooter={tableFooter}
          isTableEditable={editable}
          updateServiceRendered={updateServiceRendered}
          deleteServiceRendered={deleteServiceRendered}
          patientBackupFunctions={patientBackupFunctions}
          activeServiceId={activeServiceId}
          setActiveServiceId={setActiveServiceId}
          tableHeight={tableHeight}
          onlyAllowOnCellClickEditingOfActiveRow={onlyAllowOnCellClickEditingOfActiveRow}
          changeNote={changeNote}
          patientId={patientId}
          removedForRapidBilling={removedForRapidBilling}
          prescriptions={prescriptions}
          isUnchargedServices={isUnchargedServices}
          showHeader={showHeader}
          onRowMove={onRowMove}
          labIconCallback={labIconCallback}
        />
      )}
    </>
  );
};
