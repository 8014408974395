import React, { useMemo } from 'react';
import { translations } from '../../constants/translations';
import { StyledCardGrid } from '../../components/SettingsCard/SettingsCard.style';
import SubscriptionCurrentBillingCycleEstimateCard from './Cards/SubscriptionCurrentBillingCycleEstimateCard';
import SubscriptionBillingDetailsCard from './Cards/SubscriptionBillingDetailsCard';
import { Loading } from '../../components/Loading/Loading';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import SubscriptionBillingHistoryCard from './Cards/SubscriptionBillingHistoryCard';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';

export const linkColor = '#1890ff';
const SubscriptionSettings: React.FC = () => {
  const { state } = useOrganizationContext();
  const organization = useMemo(() => state.organization, [state.organization]);
  const { referenceData, dataLoading } = useGetReferenceData(organization?.id ?? '');

  const practice = organization?.practice.find(({ id }) => id === organization.default_practice_id);

  if (!organization || dataLoading) {
    return <Loading />;
  }

  return (
    <>
      <StyledPageHeader title={translations.subscriptionSettings.title} />
      <StyledCardGrid>
        <SubscriptionCurrentBillingCycleEstimateCard organization={organization} />
        <SubscriptionBillingDetailsCard practice={practice} organization={organization} referenceData={referenceData} />
        <SubscriptionBillingHistoryCard organization={organization} />
      </StyledCardGrid>
    </>
  );
};

export default SubscriptionSettings;
