import React from 'react';
import { PatientDetails } from '../../../Patients/ViewPatient/PatientDetails/PatientDetails';
import { OrganizationDto, Patient } from '../../../../graph/types';

interface DoctorOfficePatientDetailsProps {
  patient: Patient;
  organization: OrganizationDto;
  patientLoading: boolean;
  renderKey: number | undefined;
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
}

const DoctorOfficePatientDetails: React.FC<DoctorOfficePatientDetailsProps> = ({
  organization,
  patient,
  patientLoading,
  renderKey,
  patientRefetch,
}) => {
  return (
    <div style={{ paddingTop: '1.5rem' }}>
      <PatientDetails
        organizationId={organization.id}
        practiceId={organization.default_practice_id!}
        patientRef={organization.ref_patient}
        patient={patient}
        patientLoading={patientLoading}
        renderKey={renderKey}
        patientRefetch={patientRefetch}
      />
    </div>
  );
};

export default DoctorOfficePatientDetails;
