import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';
import { translations } from '../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { Store } from 'antd/es/form/interface';
import { isMatch } from '../../util/objectComparisons';
import {
  useDefaultPracticeId,
  useGetPractice,
  useUpsertPracticeCardConnectSettings,
} from '../../hooks/ajax/practice/practiceHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { Loading } from '../../components/Loading/Loading';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveAndResetButton } from '../../components/SaveAndResetButton/SaveAndResetButton';
import { PracticeUpsert } from '../../graph/types';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { CardConnectFormItems, CardConnectFormValues } from './CardConnectFormItems';
import { useGetCardConnectLink, useGetHideCardConnectSettings } from './cardConnectSettingsUtil';
import { CardConnectDevicesTable } from './CardConnectDevicesTable/CardConnectDevicesTable';
import styled from 'styled-components';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../../graph/queries/organizations';
import { useUpsertCardConnectTransaction } from '../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { isIntOrStaging } from '../../util/checkStageUtil';
import { CardConnectLogo } from '../../images/images';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { Message } from '../EmailSettings/EmailSettings.styles';
import { useUserPermissions } from '../../hooks/permissionsHooks';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { routes } from '../../constants/routes';
import { flushSync } from 'react-dom';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const PaymentDeviceOptionsRow = styled(Row)`
  margin: 24px;
`;

const ButtonWithMargin = styled(Button)`
  margin-right: 12px;
`;

const DivWithPadding = styled.div`
  padding: 0 2rem 0 2rem;
`;

const BoldDiv = styled.div`
  font-weight: bold;
`;

const UnavailableMessage = styled.h1`
  font-weight: bold;
`;

const SupportInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SupportInfoBox = styled.div`
  width: 50%;
  padding-bottom: 1rem;
`;

const CardConnectSettings: React.FC = () => {
  const [upsertPracticeCardConnectSettings] = useUpsertPracticeCardConnectSettings();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { practice, practiceLoading } = useGetPractice(organizationId, practiceId);
  const [isSaving, setIsSaving] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  // const [redirectToAddOns, setRedirectToAddOns] = useState(false);
  const [form] = Form.useForm();
  const cardConnectLink = useGetCardConnectLink(practice?.address);
  const hideCardConnectSettings = useGetHideCardConnectSettings(practice?.address);
  const [refreshDevices, { data: refreshDevicesData, loading: devicesLoading }] =
    useUpsertCardConnectTransaction(organizationId);
  const { organization, organizationLoading, organizationRefetch } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.PaymentMethods,
    practiceId
  );
  const paymentDevices = organization?.ref_financial?.payment_device_type ?? [];
  const { isGlobalAdmin } = useUserPermissions();

  const { navigateBack, navigateTo } = useNavigationToRoute();

  useEffect(() => {
    if (refreshDevicesData?.upsertCardConnectTransaction?.success) {
      organizationRefetch();
    }
  }, [refreshDevicesData, organizationRefetch]);

  if (practiceLoading) {
    return <Loading />;
  }

  if (!practice) {
    return <p>{translations.practiceSettings.noPractice}</p>;
  }

  const initialFormValues = {
    merchantId: practice.card_connect?.merchantId || '',
    userId: practice.card_connect?.userId || '',
    password: '',
  };

  const handleFormValuesChange = (_: Store, values: CardConnectFormValues) => {
    setChangesMade(!isMatch(values, initialFormValues));
  };

  const handleOnReset = () => {
    form.resetFields();
    setChangesMade(false);
  };

  const handleOnFinish = async (values: CardConnectFormValues) => {
    const practiceUpsert: PracticeUpsert = {
      id: practice.id,
      card_connect: {
        ...values,
      },
    };
    setIsSaving(true);
    try {
      await upsertPracticeCardConnectSettings({
        variables: {
          practice: practiceUpsert,
          organizationId: practice.organization_id,
        },
      });
      showSuccessMessage(translations.shared.saveSuccessMessage);
      flushSync(() => {
        setChangesMade(false);
      });
      setIsSaving(false);
      navigateTo(routes.addOnSettings);
    } catch (e) {
      showErrorMessage(e.message || translations.shared.saveErrorMessage);
      setIsSaving(false);
    }
  };

  const handleCardConnectRedirect = () =>
    isIntOrStaging()
      ? showErrorMessage(translations.shared.cardConnectEnvRedirectError)
      : cardConnectLink && openTabWithPopupDetection(cardConnectLink);

  const handleRefresh = () => {
    refreshDevices({
      variables: {
        organizationId,
        practiceId,
        transaction: {
          is_device_request: true,
          save_card_record: false,
        },
      },
    });
  };

  return (
    <>
      <StyledPageHeaderWithMargin
        onBack={navigateBack}
        title={<CardConnectLogo width={300} />}
        extra={
          <Button
            type='primary'
            onClick={handleCardConnectRedirect}
            disabled={hideCardConnectSettings || !cardConnectLink}
          >
            {translations.registration.cardConnect.setupCardConnect}
          </Button>
        }
      />
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving}
        showNavigationWarning={changesMade}
        warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.cardConnectSettings.title)}
      >
        <DivWithPadding>
          {hideCardConnectSettings && (
            <UnavailableMessage>{translations.cardConnectSettings.unavailableMessage}</UnavailableMessage>
          )}
          {translations.cardConnectSettings.cardConnectInstructions.map((instruction, i) => (
            <Message key={i}>{instruction}</Message>
          ))}
        </DivWithPadding>

        <Divider />

        <DivWithPadding>
          <h1>{translations.cardConnectSettings.cardConnectSupportTitle}</h1>
          <SupportInfoContainer>
            {translations.cardConnectSettings.cardConnectSupportInfo.map((entry, i) => (
              <SupportInfoBox key={i}>
                <BoldDiv>{entry.title}</BoldDiv>
                <div>{entry.description}</div>
                {entry.website && (
                  <div>
                    <a href={`https://${entry.website}`} target='_blank' rel='noopener noreferrer'>
                      {entry.website}
                    </a>
                  </div>
                )}
                {entry.email && (
                  <div>
                    e:{' '}
                    <a href={`mailto://${entry.email}`} target='_blank' rel='noopener noreferrer'>
                      {entry.email}
                    </a>
                  </div>
                )}
                {entry.phone && <div>p: {entry.phone}</div>}
              </SupportInfoBox>
            ))}
          </SupportInfoContainer>
        </DivWithPadding>

        <Divider />

        <Form
          {...layout}
          form={form}
          initialValues={initialFormValues}
          onValuesChange={handleFormValuesChange}
          onFinish={handleOnFinish}
          autoComplete='off'
        >
          <DivWithPadding>
            <h1>{translations.cardConnectSettings.title}</h1>
            <h4>{translations.cardConnectSettings.subtitle}</h4>
          </DivWithPadding>
          <CardConnectFormItems disabled={hideCardConnectSettings || !isGlobalAdmin} />
          {changesMade && !hideCardConnectSettings && <SaveAndResetButton onReset={handleOnReset} offset={3} />}
          <Divider />
          {!hideCardConnectSettings && (
            <>
              <PaymentDeviceOptionsRow justify={'space-between'}>
                <Col>
                  <h1>{translations.registration.cardConnect.tableTitle}</h1>
                </Col>
                <Col>
                  <ButtonWithMargin type='default' onClick={handleRefresh} disabled={devicesLoading}>
                    {translations.registration.cardConnect.refreshButton}
                  </ButtonWithMargin>
                </Col>
              </PaymentDeviceOptionsRow>
              <CardConnectDevicesTable devices={paymentDevices} loading={organizationLoading || devicesLoading} />
            </>
          )}
        </Form>
      </SaveSpinnerAndNavigationWarning>
    </>
  );
};

export default CardConnectSettings;
