import { gql } from '@apollo/client';

export const BasicInterestRunFields = gql`
  fragment BasicInterestRunFields on InterestRunContacts {
    id
    run_date
    has_charge
    rate
    number
  }
`;

export const InterestRunContactFields = gql`
  fragment InterestRunContactFields on ContactInterestEntry {
    ledger_id
    contact_id
    contact_name
    contact_number
    contact_status_id
    contact_status_name
    contact_email
    total
    bucket0
    bucket31
    bucket61
    bucket91
    bucket121
    interest
  }
`;
