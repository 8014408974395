import React, { PropsWithChildren } from 'react';
import { Patient, InfoUpsert, PatientUpsert, PatientReferenceDataDto, InfoDto } from '../../../../../graph/types';
import { InfoTable, InfoTableEntry } from '../../../../../components/InfoTable/InfoTable';
import { translations } from '../../../../../constants/translations';
import { usePatientOfflineUpdate, useUpdatePatient } from '../../../../../hooks/ajax/patients/patientHooks';
import { useOffline } from '../../../../../util/offline/offlineUtil';
import { showErrorMessage, showSuccessMessage } from '../../../../../components/Notification/notificationUtil';
import { getInfoUpsert } from '../../../../../classes/upsertGenerators/infoMappingUtil';

interface PatientIdentificationProps extends PropsWithChildren<unknown> {
  organizationId: string;
  practiceId: string;
  patient: Patient;
  patientRef: PatientReferenceDataDto;
  setIsSaving: (isSaving: boolean) => void;
  setHasChanged: (hasChanged: boolean) => void;
}

function generatePatientUpsert(upsert: InfoUpsert[], patient: Patient): PatientUpsert {
  return {
    id: patient.id,
    info: upsert,
  };
}

export const PatientIdentification: React.FC<PatientIdentificationProps> = ({
  organizationId,
  practiceId,
  patient,
  patientRef,
  setIsSaving,
  setHasChanged,
}) => {
  const idInfoTypes = patientRef.info_type.filter((type) => type.category === 'ID');
  const { enabledAndOffline } = useOffline();
  const [updatePatient] = useUpdatePatient();
  const offlineUpdate = usePatientOfflineUpdate(patient.id);

  const upsertPatientInfoValue = async (info: InfoTableEntry, onSuccess: () => void) => {
    setIsSaving(true);
    const infoUpsert = getInfoUpsert(info.type_id, info.value ?? '', patient.info ?? []);
    const patientUpsert = generatePatientUpsert(infoUpsert ?? [], patient);
    if (!enabledAndOffline) {
      await updatePatient({
        variables: {
          organizationId: patient.organization_id,
          patient: patientUpsert,
        },
        onCompleted: () => {
          setHasChanged(false);
          showSuccessMessage(translations.shared.saveSuccessMessage);
          onSuccess();
        },
      });
    } else {
      try {
        await offlineUpdate?.({ info: infoUpsert });
        setHasChanged(false);
        onSuccess();
        showSuccessMessage(translations.shared.saveSuccessMessage);
      } catch (e) {
        showErrorMessage((e as Error).message || translations.shared.saveErrorMessage);
      }
    }
    setIsSaving(false);
  };

  return (
    <InfoTable
      organizationId={organizationId}
      practiceId={practiceId}
      refTypes={idInfoTypes}
      values={(patient.info as InfoDto[]) ?? []}
      namesToDisplay={translations.patientPage.identification.types}
      mainColumnTitle={translations.shared.identification.title}
      setHasChanged={setHasChanged}
      upsertInfoValue={upsertPatientInfoValue}
      patientName={patient.name!}
    />
  );
};
