import { translations } from '../../../constants/translations';
import { Button } from 'antd';
import { Invoice, InvoiceUpsert } from '../../../graph/types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDeleteInvoice, useGetInvoicesWithSearch, useUpdateInvoice } from '../../../hooks/ajax/invoice/invoiceHooks';
import { routes } from '../../../constants/routes';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { DropdownButtonWithMenu } from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { addSortingPriorityTo, getStatusFilters } from '../../../util/filterAndSorting';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { InvoicesContext } from './store/state';
import { showSelectPatientModalAction } from './store/actions';
import { AddInvoiceModalContainer } from '../AddInvoice/AddInvoiceModalContainer';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../hooks/route/navigationHooks';
import { AbbreviateToWidth } from '../../../components/AbbreviateToWidth/AbbreviateToWidth';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { PADDING_COMPENSATION_WIDTH_TEXT } from '../../../constants/layout';
import { InvoicePaymentModal } from '../../../components/PaymentModal/InvoicePaymentModal';
import {
  invoiceCanTakePayment,
  invoiceIsDeletable,
  invoiceStatusConfigs,
  InvoiceStatusId,
  InvoiceStatusNameKey,
  isCompletedInvoiceStatus,
} from '../../../constants/referenceData/invoiceReferenceData';
import { useDeleteMutationWithMessages, useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { changeInvoiceStatus, getMenuItemsForInvoiceActions } from '../invoicesUtils';
import { useBasicInvoiceColumns } from './invoiceColumns';
import { useShouldShowTooManyResultsWarning } from '../../../hooks/setWarningIfTooManyResults';
import { PrintInvoiceReportModal } from '../PrintInvoiceReportModal/PrintInvoiceReportModal';
import { EmailModalForInvoiceReport } from '../../../components/EmailModal/EmailModalForInvoiceReport';
import {
  TableFilterAndSortKey,
  TableKey,
  useTableColumnDisplayFilter,
  useTableResetFilterAndSort,
} from '../../../hooks/tableHooks';
import { invoicePropertyNames } from '../../../constants/propertyNames';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';
import { hasValidEmailSettingsDto } from '../../../util/email';
import { ButtonWithPopconfirm } from '../../../components/ButtonWithPopconfirm/ButtonWithPopconfirm';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { isNil } from 'lodash';
import { useTaskWithProgress } from '../../../hooks/taskHooks';
import { ColumnFilterItem, RowSelectMethod, TableRowSelection } from 'antd/lib/table/interface';
import { useOffline } from '../../../util/offline/offlineUtil';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const shouldShowTakePaymentAction = (invoice: Invoice) => {
  return (
    invoiceCanTakePayment(invoice.status_id) && Number(invoice.total) > 0 && (invoice.contact_names ?? []).length > 0
  );
};

export const getCheckboxTestId = (id: string) => {
  return `invoice-checkbox-${id}`;
};

export const selectAllInvoicesTestId = 'invoice-checkbox-all';

export const InvoicesOverview: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    invoices,
    invoicesLoading,
    refetchInvoicesWithCurrentSearch,
    setSearchTerm: searchInvoices,
    setStatusFilter,
  } = useGetInvoicesWithSearch(organizationId);

  const { navigateTo } = useNavigationToRoute();
  const { dispatch } = useContext(InvoicesContext);
  const deleteInvoice = useDeleteMutationWithMessages(useDeleteInvoice, organizationId);
  const [updateInvoice] = useMutationWithMessages(useUpdateInvoice);
  const [isSaving, setIsSaving] = useState(false);
  const [isBatching, setIsBatching] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const { isOnline } = useOffline();
  const [currentTableDataSource, setCurrentTableDataSource] = useState<Invoice[]>([]);
  const shouldShowTooManyResultsWarning = useShouldShowTooManyResultsWarning();

  const [updateInvoiceStatus] = useUpdateInvoice();
  const { filteredValue, sortOrderMap, tableChangeHandler, resetFiltersAndSort, modifiedFields } =
    useTableResetFilterAndSort(TableFilterAndSortKey.InvoicesOverview);
  const basicInvoiceColumns = useBasicInvoiceColumns();
  const [rowSelectedMethod, setRowSelectedMethod] = useState<RowSelectMethod>();

  useEffect(() => {
    if (rowSelectedMethod === 'all' && currentTableDataSource) {
      setSelectedInvoices(currentTableDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValue]);

  const handleEndBatchClose = () => {
    resetFiltersAndSort();
    setSelectedInvoices([]);
    setStatusFilter('');
    setIsBatching(false);
  };

  const handleInvoiceComplete = async (id: string, onFinish: () => void) => {
    try {
      const invoiceUpsert: InvoiceUpsert = {
        id,
        statusRecord: {
          status_id: InvoiceStatusId.Closed,
        },
      };
      await updateInvoiceStatus({
        variables: { organizationId, invoice: invoiceUpsert },
      });
      onFinish();
    } catch (e) {
      showErrorMessage(translations.invoicesPage.errorClosing);
      handleEndBatchClose();
    }
  };

  const onBatchCloseComplete = () => {
    refetchInvoicesWithCurrentSearch?.();
    handleEndBatchClose();
  };

  const {
    progress: batchCloseProgress,
    stop: stopBatchDelete,
    start: startBatchClose,
  } = useTaskWithProgress<string>(handleInvoiceComplete, onBatchCloseComplete);

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState<string>('1');

  const [invoiceIdForPrintModal, setInvoiceIdForPrintModal] = useState<string>();
  const [invoiceIdForEmailModal, setInvoiceIdForEmailModal] = useState<string>();

  const {
    state: { organization },
  } = useOrganizationContext();
  const smtpSettings = organization?.smtp;

  const navigateToViewInvoice = (invoiceId: string) => () => {
    navigateTo(routes.viewInvoice, withInvoiceIdParameter(invoiceId));
  };

  const linkToViewInvoice = (text: string, invoice: Invoice) => {
    return <TableCellLink onClick={navigateToViewInvoice(invoice.id)}>{text}</TableCellLink>;
  };

  const openNewPayment = (invoice: Invoice) => () => {
    setPaymentInvoiceId(invoice.id);
    setPaymentModalVisible(true);
  };

  const handleDeleteInvoice = async (invoice: Invoice) => {
    setIsSaving(true);
    await deleteInvoice({
      entityId: invoice.id,
      successMessage: translations.invoicePage.deleteInvoiceSuccessMessage,
    });
    setIsSaving(false);
  };

  const handleInvoiceCompletion = async (invoice: Invoice) => {
    await changeInvoiceStatus(setIsSaving, updateInvoice, invoice.id, InvoiceStatusId.Closed, organizationId);
  };

  const linkInvoiceMenu = (invoice: Invoice) => {
    const isCompletable = invoice.status_id && !isCompletedInvoiceStatus(invoice.status_id);

    const invoiceActions = getMenuItemsForInvoiceActions({
      editInvoiceAction: navigateToViewInvoice(invoice.id),
      showNewPaymentAction: shouldShowTakePaymentAction(invoice),
      newPaymentAction: openNewPayment(invoice),
      showDeleteInvoice: invoiceIsDeletable(invoice.status_id),
      deleteInvoiceAction: () => handleDeleteInvoice(invoice),
      emailInvoiceAction: () => setInvoiceIdForEmailModal(invoice.id),
      printInvoiceAction: () => setInvoiceIdForPrintModal(invoice.id),
      completeInvoiceAction: isCompletable ? () => handleInvoiceCompletion(invoice) : undefined,
      disableEmailOption: !hasValidEmailSettingsDto(smtpSettings),
    });
    return <DropdownButtonWithMenu menuItemProps={invoiceActions} />;
  };

  const addInvoice = () => {
    dispatch(showSelectPatientModalAction());
  };

  const optionalColumns: CustomColumnType<Invoice>[] = useMemo(
    () => [
      {
        ...basicInvoiceColumns.status_name_key,
        filteredValue: filteredValue[invoicePropertyNames.status_name_key] ?? null,
        sortOrder: sortOrderMap[invoicePropertyNames.status_name_key],
        filters: isBatching
          ? ([
              {
                text: invoiceStatusConfigs[InvoiceStatusNameKey.InProgress].text,
                value: InvoiceStatusNameKey.InProgress,
              },
            ] as ColumnFilterItem[])
          : getStatusFilters(invoiceStatusConfigs),
        width: 100,
      },
      {
        ...basicInvoiceColumns.date,
        filteredValue: filteredValue[invoicePropertyNames.date] ?? null,
        sortOrder: sortOrderMap[invoicePropertyNames.date],
        width: 120,
      },
      {
        ...basicInvoiceColumns.patient_name,
        filteredValue: filteredValue[invoicePropertyNames.patient_name] ?? null,
        sortOrder: sortOrderMap[invoicePropertyNames.patient_name],
        render: (patientName: string) => (
          <AbbreviateToWidth width={200} text={patientName} paddingCompensation={PADDING_COMPENSATION_WIDTH_TEXT} />
        ),
        width: 200,
      },
      {
        ...basicInvoiceColumns.contact_names,
        filteredValue: filteredValue[invoicePropertyNames.contact_names] ?? null,
        sortOrder: sortOrderMap[invoicePropertyNames.contact_names],
        width: 500,
      },
      {
        ...basicInvoiceColumns.total,
        filteredValue: filteredValue[invoicePropertyNames.total] ?? null,
        sortOrder: sortOrderMap[invoicePropertyNames.total],
        width: 140,
      },
    ],
    [filteredValue, sortOrderMap, isBatching, basicInvoiceColumns]
  );

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter(TableKey.InvoicesOverview, optionalColumns);

  const columns: CustomColumnType<Invoice>[] = [
    {
      ...basicInvoiceColumns.number,
      filteredValue: filteredValue[invoicePropertyNames.number] ?? null,
      sortOrder: sortOrderMap[invoicePropertyNames.number],
      render: (name: string, record: Invoice) => linkToViewInvoice(name, record),
      width: 100,
    },
    ...displayedColumns,
  ];

  if (isOnline) {
    columns.push({
      title: translations.invoicesPage.columns.actions,
      key: 'actions',
      render: (record: Invoice) => linkInvoiceMenu(record),
    });
  }

  addSortingPriorityTo(columns);

  const handleOnClear = (key?: string) => {
    searchInvoices('');
    resetFiltersAndSort(key);
    resetDisplayedColumnsToDefault();
  };

  const handleIsBatchingInitition = () => {
    tableChangeHandler(
      undefined as any,
      {
        status_name_key: [InvoiceStatusNameKey.InProgress],
      },
      {
        columnKey: 'status_name_key',
      },
      undefined as any
    );
    setStatusFilter(InvoiceStatusNameKey.InProgress);
    setIsBatching(true);
  };

  const handleCancelBatchClose = () => {
    stopBatchDelete();
    handleEndBatchClose();
  };

  const getSelectedRowKeys = useCallback(() => {
    return selectedInvoices.map((i) => i.id);
  }, [selectedInvoices]);

  const selectedRowKeys = getSelectedRowKeys();

  const handleOnRowCheckedChange: TableRowSelection<Invoice>['onChange'] = (_, selectedRows, info) => {
    setRowSelectedMethod(info.type);
    if (info.type === 'all' && invoices) {
      setSelectedInvoices(selectedRowKeys.length !== currentTableDataSource.length ? currentTableDataSource : []);
    } else {
      setSelectedInvoices(selectedRows);
    }
  };

  const handleOnAllRowsSelect: TableRowSelection<Invoice>['onSelectAll'] = (selected) => {
    setSelectedInvoices(selected && currentTableDataSource ? currentTableDataSource : []);
  };

  return (
    <SaveSpinner
      isSaving={isSaving || !isNil(batchCloseProgress)}
      savingMessage={translations.loadingComponent.saving}
      position={'fixed'}
      progress={batchCloseProgress}
      onCancel={handleCancelBatchClose}
    >
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.invoicesPage.title}
            searchBoxPlaceholder={translations.invoicesPage.searchPlaceholder}
            onSearchValueChange={(event) => searchInvoices(event.target.value)}
            onClear={handleOnClear}
            loading={invoicesLoading}
            showTooManyResultsWarning={shouldShowTooManyResultsWarning(invoices) && !isBatching}
            tags={mapKeysToColumnTitle(modifiedFields, columns)}
          />
        }
        extra={[
          isOnline &&
            (!isBatching ? (
              <Button key='batch-button' onClick={handleIsBatchingInitition}>
                {translations.invoicesPage.buttons.startBatch}
              </Button>
            ) : (
              <>
                <Button key='cancel-batch-button' onClick={handleEndBatchClose}>
                  {translations.invoicesPage.buttons.cancel}
                </Button>
                <ButtonWithPopconfirm
                  key='confirm-batch-button'
                  onClickCallback={() => startBatchClose(selectedInvoices.map(({ id }) => id))}
                  isDisabled={selectedInvoices.length === 0}
                  buttonText={translations.invoicesPage.buttons.closeBatch}
                  popconfirmTitle={translations.invoicesPage.buttons.confirmation}
                />
              </>
            )),
          <ColumnDisplayFilter
            key={'columnDisplayFilter'}
            initiallyDisplayedColumns={displayedColumnKeys}
            setDisplayedColumns={setDisplayedColumnKeys}
            columnKeyAndTitleList={columnKeyAndTitleList}
            resetDisplayedColumnsToDefault={resetDisplayedColumnsToDefault}
          />,
          <Button key='addInvoice' type='primary' onClick={addInvoice}>
            {translations.invoicesPage.buttons.addInvoice}
          </Button>,
        ]}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.InvoicesOverview}
        dataSource={invoices}
        columns={columns}
        loading={invoicesLoading}
        rowKey={'id'}
        onChange={tableChangeHandler}
        rowSelection={
          isBatching
            ? {
                type: 'checkbox',
                onChange: handleOnRowCheckedChange,
                onSelectAll: handleOnAllRowsSelect,
                selectedRowKeys: getSelectedRowKeys(),
              }
            : undefined
        }
        setCurrentDataSource={setCurrentTableDataSource}
      />
      <AddInvoiceModalContainer />
      {paymentModalVisible ? (
        <InvoicePaymentModal
          onClose={() => setPaymentModalVisible(false)}
          onSuccess={() => refetchInvoicesWithCurrentSearch()}
          invoiceId={paymentInvoiceId}
        />
      ) : null}
      {invoiceIdForPrintModal && (
        <PrintInvoiceReportModal
          invoiceId={invoiceIdForPrintModal}
          onClose={() => setInvoiceIdForPrintModal(undefined)}
        />
      )}
      {invoiceIdForEmailModal && (
        <EmailModalForInvoiceReport
          invoiceId={invoiceIdForEmailModal}
          onClose={() => setInvoiceIdForEmailModal(undefined)}
        />
      )}
    </SaveSpinner>
  );
};
