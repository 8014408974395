import React, { useMemo } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { translations } from '../../../constants/translations';
import { OrganizationDto } from '../../../graph/types';
import { FlexContainer } from '../../../globalStyles.style';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { routes } from '../../../constants/routes';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

const SpacedDiv = styled.div`
  margin: 2em 0;
`;

interface Props {
  organization: OrganizationDto;
}

const SubscriptionBillingHistoryCard: React.FC<Props> = ({ organization }) => {
  const recentCharges = organization.subscription?.invoice?.slice(0, 3);
  const { navigateTo } = useNavigationToRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const onViewFullHistoryClick = () => {
    navigateTo(
      routes.billingHistoryPage,
      {},
      {
        hasBackNavigation: true,
      }
    );
  };

  return (
    <SettingsCard
      title={translations.subscriptionSettings.billingHistory.title}
      leftButton={{
        label: translations.subscriptionSettings.billingHistory.content.viewFullHistory,
        onClick: onViewFullHistoryClick,
      }}
    >
      <SpacedDiv>
        {recentCharges?.map(({ created, amount }) => (
          <FlexContainer key={created} justifyContent='space-between'>
            <div>{dayjs(created).format(dateFormat)}</div>
            <div>
              <SubscriptionCurrencyFormatter total={Number(amount)} countryId={countryId} />
            </div>
          </FlexContainer>
        ))}
      </SpacedDiv>
    </SettingsCard>
  );
};

export default SubscriptionBillingHistoryCard;
