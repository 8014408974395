import React, { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { LabErrorNameKeyTranslation, translations } from '../../constants/translations';
import { LabSettingsVetConnect, OrganizationSubscriptionResult, Query } from '../../graph/types';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useUpsertLabSettingsVetConnect } from '../../hooks/ajax/lab/labHooks';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { ErrorText } from '../../globalStyles.style';
import { SubscriptionChargesInfo } from '../SubscriptionChargesInfo/SubscriptionChargesInfo';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { getRequiredRule } from '../../util/forms';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetIvlsDevices } from '../../graph/queries/labLink';
import { SelectOptions } from '../DependentDropdown/DependentDropdown';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { ButtonWithPopconfirm } from '../ButtonWithPopconfirm/ButtonWithPopconfirm';
import { flushSync } from 'react-dom';
import { showErrorMessage } from '../Notification/notificationUtil';
import styled from 'styled-components';

const layout = {
  labelCol: { lg: 8 },
  wrapperCol: { span: 24 },
};

export interface VetConnectLabConfigurationModalProps {
  addOnEnabled: boolean;
  organizationId: string;
  subscription?: OrganizationSubscriptionResult;
  currentSettings?: LabSettingsVetConnect;
  onSave: () => void;
  onClose: () => void;
}

const PopConfirmContainer = styled.div`
  width: 700px;
`;

export const VetConnectLabConfigurationModal: React.FC<VetConnectLabConfigurationModalProps> = ({
  addOnEnabled,
  organizationId,
  subscription,
  currentSettings,
  onSave,
  onClose,
}: VetConnectLabConfigurationModalProps) => {
  const [form] = useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const upsertLabSettingsVetConnect = useUpsertLabSettingsVetConnect();
  const [serialNumberEnabled, setSNEnabled] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState<SelectOptions[]>();

  const setOptions = async (deviceId: string) => {
    setIsSaving(true);
    setLoadingMessage(translations.shared.loading);
    try {
      const deviceResponse = await AppSyncService.client.query<Pick<Query, 'getIvlsDevices'>>({
        query: GetIvlsDevices,
        variables: { organizationId, filter: { lab_device_id: deviceId } },
      });
      const deviceOptions = (deviceResponse?.data?.getIvlsDevices ?? []).map((device) => ({
        label: `${device.deviceSerialNumber}${device.displayName && ` - ${device.displayName}`}`,
        value: device.deviceSerialNumber,
        key: device.deviceSerialNumber,
      }));
      flushSync(() => {
        setDeviceOptions(deviceOptions);
      });
    } catch (e) {
      showErrorMessage(e.message);
    } finally {
      setErrorMessage(undefined);
      setLoadingMessage(undefined);
      setIsSaving(false);
    }
  };

  const handleSave = async (values: any) => {
    try {
      setIsSaving(true);
      setErrorMessage(undefined);

      const curatedValues = serialNumberEnabled ? values : { ...values, in_house: false, serial_number: undefined };
      const settingsResponse = await upsertLabSettingsVetConnect(organizationId, curatedValues);

      if (settingsResponse && !serialNumberEnabled && form.getFieldValue('in_house')) {
        setSNEnabled(true);
        setOptions(settingsResponse.lab_device_id);
      } else {
        onSave();
      }

      setIsSaving(false);
    } catch (err) {
      if ((err as any).message === 'Network Error') {
        setErrorMessage(translations.shared.networkError);
      } else {
        setErrorMessage(LabErrorNameKeyTranslation((err as any).message));
      }
      setIsSaving(false);
    }
  };

  const handleCheckChange = (checked: boolean) => {
    if (!checked) {
      setSNEnabled(false);
    }
  };

  const handleValuesChange = () => {
    const { lab_account_id, username, password } = form.getFieldsValue();
    const hasChanged =
      lab_account_id !== currentSettings?.lab_account_id ||
      username !== currentSettings?.username ||
      password !== currentSettings?.password;

    if (hasChanged && !!currentSettings?.serial_number) {
      setSNEnabled(false);
    }
    setHasChanges(hasChanged);
  };

  const handleReset = () => {
    form.resetFields();
    setHasChanges(false);
  };

  return (
    <ModalWithCloseConfirm
      title={translations.vetConnectLabConfigurationModal.title}
      open
      onCancel={onClose}
      onOk={() => form.submit()}
      okText={translations.vetConnectLabConfigurationModal.saveButtonText}
      okButtonProps={{ disabled: isSaving }}
      isSaving={isSaving}
      savingMessage={loadingMessage}
      width={800}
    >
      {errorMessage && (
        <Row>
          <Col>
            <ErrorText>{errorMessage}</ErrorText>
          </Col>
        </Row>
      )}

      <Form {...layout} form={form} onFinish={handleSave} autoComplete='off'>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <MaxLengthFormItem
              name='lab_account_id'
              label={translations.vetConnectLabConfigurationModal.fields.labAccountId}
              rules={[getRequiredRule(translations.vetConnectLabConfigurationModal.fields.labAccountId)]}
              initialValue={currentSettings?.lab_account_id}
              maxLength={255}
            >
              <Input autoComplete='new-password' onChange={handleValuesChange} disabled={serialNumberEnabled} />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='username'
              label={translations.vetConnectLabConfigurationModal.fields.username}
              rules={[getRequiredRule(translations.vetConnectLabConfigurationModal.fields.username)]}
              initialValue={currentSettings?.username}
              maxLength={255}
            >
              <Input autoComplete='new-password' disabled={serialNumberEnabled} onChange={handleValuesChange} />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='password'
              label={translations.vetConnectLabConfigurationModal.fields.password}
              rules={[getRequiredRule(translations.vetConnectLabConfigurationModal.fields.password)]}
              initialValue={currentSettings?.password}
              maxLength={255}
            >
              <Input.Password
                autoComplete='new-password'
                disabled={serialNumberEnabled}
                onChange={handleValuesChange}
              />
            </MaxLengthFormItem>
            <Form.Item
              name='in_house'
              valuePropName='checked'
              label={translations.vetConnectLabConfigurationModal.fields.inHouse}
              initialValue={currentSettings?.in_house}
              hidden={!!currentSettings?.serial_number}
            >
              <Checkbox onChange={(e) => handleCheckChange(e.target.value)} />
            </Form.Item>
            <MaxLengthFormItem
              name='serial_number'
              label={translations.vetConnectLabConfigurationModal.fields.serialNumber}
              initialValue={currentSettings?.serial_number}
              maxLength={255}
              extra={
                !serialNumberEnabled &&
                !!currentSettings?.serial_number && (
                  <>
                    {hasChanges ? (
                      <ButtonWithPopconfirm
                        buttonType='link'
                        buttonText={translations.vetConnectLabConfigurationModal.editSerialNumberText}
                        popconfirmTitle={() => (
                          <PopConfirmContainer>
                            {translations.vetConnectLabConfigurationModal.resetText}
                          </PopConfirmContainer>
                        )}
                        onClickCallback={async () => {
                          handleReset();
                          if (currentSettings?.lab_device_id) {
                            await setOptions(currentSettings.lab_device_id);
                          }
                          setSNEnabled(true);
                        }}
                      />
                    ) : (
                      <Button
                        type='link'
                        title={translations.vetConnectLabConfigurationModal.editSerialNumberText}
                        onClick={async () => {
                          if (currentSettings?.lab_device_id) {
                            await setOptions(currentSettings.lab_device_id);
                          }
                          setSNEnabled(true);
                        }}
                      >
                        {translations.vetConnectLabConfigurationModal.editSerialNumberText}{' '}
                      </Button>
                    )}
                  </>
                )
              }
            >
              <Select {...searchableSelectParams} options={deviceOptions} disabled={!serialNumberEnabled} />
            </MaxLengthFormItem>
          </Col>
        </Row>
      </Form>

      {!addOnEnabled && (
        <Row>
          <Col offset={2} span={20}>
            <SubscriptionChargesInfo subscription={subscription} />
          </Col>
        </Row>
      )}
    </ModalWithCloseConfirm>
  );
};
