import React, { useEffect } from 'react';
import { RecordsContextProvider } from '../../../components/Records/store/state';
import { ViewPatientContent } from './ViewPatientContent';
import { useGetPatientIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute, withPatientIdParameter } from '../../../hooks/route/navigationHooks';
import { ViewSubscriptionContextProvider } from '../../../components/ViewSubscription/store/state';
import { isUuid, useOffline, useUnsyncedData } from '../../../util/offline/offlineUtil';
import { Loading } from '../../../components/Loading/Loading';
import { useRedirectAfterOfflineInsertSync } from '../../../event/RxDb/RxDbEvent';
import { routes } from '../../../constants/routes';
import { useGetPatient } from '../../../hooks/ajax/patients/patientHooks';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const ViewPatient: React.FC = () => {
  const patientId = useGetPatientIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const organizationId = organization?.id ?? '';
  const { navigateBack } = useNavigationToRoute();
  const { isOnline, initialized } = useOffline();
  const { hasUnsyncedData } = useUnsyncedData();
  const { patient, patientLoading, patientRefetch, renderKey } = useGetPatient(patientId, organizationId);

  useRedirectAfterOfflineInsertSync(
    'patient',
    isUuid(patientId) ? patientId : undefined,
    routes.viewPatient,
    withPatientIdParameter
  );

  useEffect(() => {
    // We need to refetch the patient after updating the patient alerts when the replication is ready
    // so we have a new patient alert and we can get the updated patient
    if (!hasUnsyncedData && initialized && patientRefetch) {
      patientRefetch();
    }
  }, [initialized, hasUnsyncedData, patientRefetch]);

  if (!patientId) {
    navigateBack();
  }

  if ((isOnline && isUuid(patientId)) || patientLoading) {
    return <Loading />;
  }

  return (
    <RecordsContextProvider>
      <ViewSubscriptionContextProvider>
        <ViewPatientContent
          patient={patient}
          patientRefetch={patientRefetch}
          renderKey={renderKey}
          navigateBack={navigateBack}
        />
      </ViewSubscriptionContextProvider>
    </RecordsContextProvider>
  );
};
