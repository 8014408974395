import { CollectionType } from '../../services/LocalDatabaseService/collections';
import { useLayoutEffect } from 'react';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { getEventListeners } from '../index';

export enum RxDbEventTypes {
  INSERT = 'RXDB_EVENT_INSERT',
}

export type RxDbInsertArg = { collection: CollectionType; recordId: string; offlineId: string };

export const RxDbEvent = {
  insert: getEventListeners<RxDbInsertArg>(RxDbEventTypes.INSERT),
};

/* HOOKS */
export const useRedirectAfterOfflineInsertSync = (
  collection: CollectionType,
  offlineId: string | null | undefined,
  route: string,
  getParameters: (recordId: string) => Record<string, string>
) => {
  const { navigateTo } = useNavigationToRoute();

  useLayoutEffect(() => {
    RxDbEvent.insert.on(({ collection: innerCollection, recordId, offlineId: innerOfflineId }) => {
      if (collection === innerCollection && offlineId && offlineId === innerOfflineId) {
        navigateTo(route, getParameters(recordId), null, true);
      }
    });
    return () => void RxDbEvent.insert.off();
  }, [collection, getParameters, navigateTo, offlineId, route]);
};
