import React, { PropsWithChildren, useMemo } from 'react';
import { Button, Card, Popconfirm, Switch, Tooltip } from 'antd';
import { translations } from '../../constants/translations';
import {
  CardContainer,
  CardActionsWrapper,
  PopConfirmText,
  SwitchContainer,
  SwitchLabel,
  TitleContainer,
  CardAction,
} from './SettingsCard.style';
import { PopconfirmProps } from 'antd/lib/popconfirm';

export const SETTINGS_CARD_SWITCH_DATA_TEST_ID = 'settingsCardSwitchDataTestId';

export interface SettingsCardProps {
  title: string;
  leftButton?: {
    label: string;
    onClick: () => void;
    isToggle?: boolean;
    isChecked?: boolean;
    popconfirm?: PopconfirmProps;
    disabled?: boolean;
  };
  rightButton?: {
    label: string;
    onClick: () => void;
    confirmMessage?: string;
    disabled?: boolean;
  };
  displayTooltip?: boolean;
  tooltipMessage?: string;
}

const SettingsCard: React.FC<PropsWithChildren<SettingsCardProps>> = ({
  title,
  leftButton,
  rightButton,
  children,
  displayTooltip,
  tooltipMessage,
}) => {
  const getRenderedFooter = useMemo(() => {
    const buttons = [];

    if (leftButton) {
      buttons.push(
        <CardAction key={`left-btn-${leftButton.label}`}>
          <Popconfirm
            title={leftButton?.popconfirm?.title}
            open={leftButton?.popconfirm?.open ?? false}
            {...leftButton?.popconfirm}
          >
            <>
              {leftButton.isToggle ? (
                <SwitchContainer justifyContent='space-around'>
                  <SwitchLabel>{`${leftButton.label}:`}</SwitchLabel>
                  {displayTooltip && (
                    <Tooltip title={tooltipMessage}>
                      <Switch
                        data-testid={SETTINGS_CARD_SWITCH_DATA_TEST_ID}
                        checked={leftButton.isChecked}
                        onClick={leftButton.onClick}
                        disabled={leftButton.disabled}
                      />
                    </Tooltip>
                  )}
                  {!displayTooltip && (
                    <Switch
                      data-testid={SETTINGS_CARD_SWITCH_DATA_TEST_ID}
                      checked={leftButton.isChecked}
                      onClick={leftButton.onClick}
                      disabled={leftButton.disabled}
                    />
                  )}
                </SwitchContainer>
              ) : (
                <Button type='text' onClick={leftButton.onClick} disabled={leftButton.disabled}>
                  {leftButton.label}
                </Button>
              )}
            </>
          </Popconfirm>
        </CardAction>
      );
    }
    if (rightButton) {
      buttons.push(
        <CardAction key={`right-btn-${rightButton.label}`}>
          {rightButton?.confirmMessage ? (
            <Popconfirm
              title={<PopConfirmText>{rightButton.confirmMessage}</PopConfirmText>}
              okText={translations.shared.popconfirm.no}
              cancelText={translations.shared.popconfirm.ok}
              onCancel={rightButton.onClick}
            >
              <Button data-testid={`right-btn-${rightButton.label}`} type='text' disabled={rightButton.disabled}>
                {rightButton.label}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type='text'
              onClick={rightButton?.onClick}
              data-testid={`right-btn-${rightButton.label}`}
              disabled={rightButton.disabled}
            >
              {rightButton?.label}
            </Button>
          )}
        </CardAction>
      );
    }
    return <CardActionsWrapper>{buttons}</CardActionsWrapper>;
  }, [displayTooltip, leftButton, rightButton, tooltipMessage]);

  return (
    <Card
      bodyStyle={{
        padding: 0,
        height: '100%',
        minHeight: '310px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContainer>
        <TitleContainer>{title}</TitleContainer>
        {children}
      </CardContainer>
      {getRenderedFooter}
    </Card>
  );
};

export default SettingsCard;
