import {
  UserOrganizationCards,
  UserOrganizationContactCardUpsert,
  UserOrganizationPatientCardUpsert,
  UserUpsert,
} from '../../graph/types';
import { OfficeType } from './doctorOfficeUtil';

const getNamePropertyKey = (officeType: OfficeType) => {
  let upsertPropertyKey: keyof UserOrganizationCards;
  if (officeType === OfficeType.patients) {
    upsertPropertyKey = 'patient_card';
  } else {
    upsertPropertyKey = 'contact_card';
  }
  return upsertPropertyKey;
};

export const getUserUpsertForAddCard = (userOrgId: string, entityId: string, officeType: OfficeType) => {
  const patientCardUpserts = [getUpsertForAddedCard(entityId, officeType)];
  const upsertPropertyKey = getNamePropertyKey(officeType);
  return getCardUpsert(userOrgId, patientCardUpserts, upsertPropertyKey);
};

export const getUserUpsertForRemoveEntityCard = (userOrgId: string, cardId: string, officeType: OfficeType) => {
  const patientCardUpserts = [getUpsertForRemovedCard(cardId)];
  const upsertPropertyKey = getNamePropertyKey(officeType);
  return getCardUpsert(userOrgId, patientCardUpserts, upsertPropertyKey);
};

export const getUpsertForAddedAndRemovedCards = (
  userOrgId: string,
  newIds: string[],
  removedCardIds: string[],
  officeType: OfficeType
) => {
  const upsertNamePropertyKey = getNamePropertyKey(officeType);
  const cardInserts = newIds.map((patientId) => getUpsertForAddedCard(patientId, officeType));
  const cardRemovals = removedCardIds.map((cardId) => getUpsertForRemovedCard(cardId));
  const cardUpserts = [...cardInserts, ...cardRemovals];
  return getCardUpsert(userOrgId, cardUpserts, upsertNamePropertyKey);
};

const getCardUpsert = (
  userOrgId: string,
  cardUpserts: (UserOrganizationPatientCardUpsert | UserOrganizationContactCardUpsert)[],
  upsertPropertyKey: keyof UserOrganizationCards
): UserUpsert => ({
  id: userOrgId,
  [upsertPropertyKey]: cardUpserts,
});

const getUpsertForAddedCard = (id: string, officeType: OfficeType) => {
  if (officeType === OfficeType.patients) {
    return {
      record: {
        patient_id: id,
      },
    };
  }
  return {
    record: {
      contact_id: id,
    },
  };
};

const getUpsertForRemovedCard = (cardId: string) => ({
  id: cardId,
  void: true,
});
