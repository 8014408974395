import { User, UserOrganization, UserOrganizationPermissionUpsert } from '../graph/types';
import { userPermissionIdMap, UserPermissionNameKey } from '../constants/referenceData/userPermissionReferenceData';
import { useUserPermissions } from '../hooks/permissionsHooks';

export type UserPermissionType = {
  [UserPermissionNameKey.PERM_RPT_ALL]: { allowed: boolean };
  [UserPermissionNameKey.PERM_SET_ALL]: { allowed: boolean };
  [UserPermissionNameKey.PERM_SET_SUBSCRIPTION]: { allowed: boolean };
  [UserPermissionNameKey.PERM_DSB_ALL]: { allowed: boolean };
};

export const getUserPermissions = (
  organizationId: string,
  user?: User | null,
  superUser?: ReturnType<typeof useUserPermissions>
): UserPermissionType => {
  if (superUser?.isOrgOwner || superUser?.isOrgAdmin || user?.global_admin) {
    return {
      [UserPermissionNameKey.PERM_SET_ALL]: { allowed: true },
      [UserPermissionNameKey.PERM_RPT_ALL]: { allowed: true },
      [UserPermissionNameKey.PERM_SET_SUBSCRIPTION]: { allowed: true },
      [UserPermissionNameKey.PERM_DSB_ALL]: { allowed: true },
    };
  }
  const userPermission: UserPermissionType = {
    [UserPermissionNameKey.PERM_RPT_ALL]: { allowed: false },
    [UserPermissionNameKey.PERM_SET_ALL]: { allowed: false },
    [UserPermissionNameKey.PERM_SET_SUBSCRIPTION]: { allowed: false },
    [UserPermissionNameKey.PERM_DSB_ALL]: { allowed: false },
  };

  const userOrganization = user?.organization?.find((org) => org.organization_id === organizationId);
  userOrganization?.permission?.forEach((permission) => {
    const key = permission.name_key as UserPermissionNameKey;
    if (userPermissionIdMap[key]) {
      userPermission[key].allowed = !permission.denied;
    }
  });

  return userPermission;
};

export const getUserOrganizationPermissionUpsert = (value: boolean, permissionName: UserPermissionNameKey) => {
  return {
    permission_id: userPermissionIdMap[permissionName],
    denied: value,
  };
};

export const getUserUpsertWithPermission = (
  organizationId: string,
  user: User,
  userOrganization: UserOrganization,
  permission: UserOrganizationPermissionUpsert[],
  organizationAdministrator = false
) => ({
  options: {
    variables: {
      organizationId,
      user: {
        id: user.id,
        organization: [
          {
            id: userOrganization?.id,
            permission,
            record: {
              organization_id: userOrganization.id,
              organization_administrator: organizationAdministrator,
            },
          },
        ],
      },
    },
  },
});
