import { isCompletedInvoiceStatus } from '../../constants/referenceData/invoiceReferenceData';
import {
  getMenuItemPropsWithOnCancelPopconfirm,
  MenuItemProps,
} from '../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { translations } from '../../constants/translations';
import { InvoiceUpsert, Mutation, QueryRptGetInvoiceArgs } from '../../graph/types';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetInvoiceReport } from '../../graph/queries/reports';
import { Reports } from '../../util/reportUtils';
import { getActiveReport } from '../../util/activeReportUtils';
import { fetchS3UploadUrl } from '../../util/fileUtils';

export const getMenuItemsForInvoiceActions = ({
  editInvoiceAction,
  showNewPaymentAction,
  newPaymentAction,
  showDeleteInvoice,
  deleteInvoiceAction,
  emailInvoiceAction,
  printInvoiceAction,
  completeInvoiceAction,
  showRemoveAssignment = false,
  removeAssignmentAction,
  disableEmailOption,
}: {
  editInvoiceAction: () => void;
  showNewPaymentAction: boolean;
  newPaymentAction: () => void;
  showDeleteInvoice: boolean;
  deleteInvoiceAction: () => void;
  emailInvoiceAction: () => void;
  printInvoiceAction: () => void;
  completeInvoiceAction?: () => void;
  showRemoveAssignment?: boolean;
  removeAssignmentAction?: () => void;
  disableEmailOption: boolean;
}) => {
  const values = Array<MenuItemProps>();
  values.push({
    title: translations.shared.editButtonText,
    onClick: editInvoiceAction,
  });
  if (showNewPaymentAction) {
    values.push({
      title: translations.shared.takePaymentButtonText,
      onClick: newPaymentAction,
    });
  }
  if (showDeleteInvoice) {
    values.push(
      getMenuItemPropsWithOnCancelPopconfirm(
        {
          title: translations.shared.deleteButtonText,
          onClick: deleteInvoiceAction,
        },
        translations.invoicePage.deleteInvoiceConfirm
      )
    );
  }
  if (completeInvoiceAction) {
    values.push({
      title: translations.invoicePage.complete,
      onClick: completeInvoiceAction,
    });
  }
  if (showRemoveAssignment && removeAssignmentAction) {
    values.push({
      title: translations.shared.removeAssignment,
      onClick: removeAssignmentAction,
    });
  }
  values.push(
    { title: translations.shared.email, onClick: emailInvoiceAction, disabled: disableEmailOption },
    {
      title: translations.shared.print,
      onClick: printInvoiceAction,
    }
  );
  return values;
};

export const changeInvoiceStatus = async (
  setIsLoading: (state: boolean) => void,
  updateInvoice: (upsert: {
    options: any;
    successMessage?: any;
    onSuccess?: any;
  }) => Promise<{ data: Pick<Mutation, 'upsertInvoice'> | null | undefined } | { data?: undefined }>,
  invoiceId: string,
  targetInvoiceStatus: number,
  organizationId: string
) => {
  setIsLoading(true);
  const invoiceUpsert: InvoiceUpsert = {
    id: invoiceId,
    statusRecord: {
      status_id: targetInvoiceStatus,
    },
  };

  await updateInvoice({
    options: { variables: { organizationId, invoice: invoiceUpsert } },
    successMessage: isCompletedInvoiceStatus(targetInvoiceStatus)
      ? translations.invoicePage.invoiceCompleted
      : translations.invoicePage.invoiceOpened,
  });

  setIsLoading(false);
};

export const generateInvoiceReportAttachment = async (
  variables: QueryRptGetInvoiceArgs,
  fileName: string,
  displayCurrency: (value: number) => string,
  displayDate: (value: string) => string
) => {
  const { data } = await AppSyncService.client.query({
    query: GetInvoiceReport,
    variables,
    fetchPolicy: 'no-cache',
  });

  const reportName = Reports.Invoice;
  const reportBlob = await getActiveReport({
    reportName,
    data,
    nestedHeader: true,
    displayCurrency,
    displayDate,
  });
  if (!reportBlob) {
    return undefined;
  }

  const uploadUrl = await fetchS3UploadUrl(variables.organizationId);
  await fetch(uploadUrl.url, { method: 'PUT', body: new File([reportBlob], fileName) });

  return {
    file_pointer: uploadUrl.filePointer,
    file_name: fileName,
  };
};
