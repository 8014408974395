import { CountryId } from '../../constants/countries';
import { countryCurrency, countryCurrencySettings } from '../../constants/countryCurrencies';

export const getCurrencyFromSubscriptionBillingAddress = (countryId?: string | null) => {
  if (!countryId || !Object.values(CountryId).includes(countryId as CountryId)) {
    return countryCurrency[CountryId.USA];
  }

  return countryCurrency[countryId as CountryId];
};

export const getCurrencySettingsFromSubscriptionBillingAddress = (countryId?: string | null) => {
  return !countryId || !Object.values(CountryId).includes(countryId as CountryId)
    ? countryCurrencySettings[CountryId.USA]
    : countryCurrencySettings[countryId as CountryId];
};
