import { Reference } from '@apollo/client';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { GetFinancialPeriods, UpsertFinancialPeriod } from '../../../graph/queries/financialPeriods';
import {
  FinancialPeriodFilter,
  MutationUpsertFinancialPeriodArgs,
  QueryGetFinancialPeriodsArgs,
} from '../../../graph/types';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { FinancialPeriodFields } from '../../../graph/fragments/financialFragments';

export const useUpsertFinancialPeriod = () => {
  return useOfflineErrorSkipMutation<'upsertFinancialPeriod', MutationUpsertFinancialPeriodArgs>(
    UpsertFinancialPeriod,
    {
      update(cache, { data }) {
        const insertedFinancialPeriod = data?.upsertFinancialPeriod;
        cache.modify({
          fields: {
            GetFinancialPeriods(financialPeriods: Reference[] = []) {
              const newFinancialPeriod = cache.writeFragment({
                data: insertedFinancialPeriod,
                fragment: FinancialPeriodFields,
                fragmentName: 'FinancialPeriodFields',
              });
              return [newFinancialPeriod, ...financialPeriods];
            },
          },
        });
      },
      onError: (error) => {
        showErrorMessage(
          error.message === 'CANT_VOID_PRIOR_THAN_LAST_PERIOD_EXCEPTION'
            ? translations.financialPeriods.cantVoidPriorThanLastPeriodException
            : error.message
        );
      },
      onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
    }
  );
};

export const useGetFinancialPeriods = (organizationId: string, practiceId: string, filter?: FinancialPeriodFilter) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<
    'getFinancialPeriods',
    QueryGetFinancialPeriodsArgs
  >(GetFinancialPeriods, {
    variables: { organizationId, practiceId, filter },
  });

  return {
    financialPeriods: data?.getFinancialPeriods,
    financialPeriodsLoading: loading,
    financialPeriodsError: error,
    financialPeriodRefetch: refetch,
  };
};
