import React, { PropsWithChildren } from 'react';
import dayjs from 'dayjs';
import { translations } from '../../../../constants/translations';
import { DatePicker, Form } from 'antd';

interface AddOwnershipModalContentProps extends PropsWithChildren<unknown> {
  setEffectiveDate: (date: dayjs.Dayjs | null) => void;
  effectiveDate: dayjs.Dayjs | null;
  dateFormat: string;
}

export const AddOwnershipModalContent: React.FC<AddOwnershipModalContentProps> = ({
  effectiveDate,
  setEffectiveDate,
  dateFormat,
}) => {
  return (
    <>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        {translations.patientPage.ownership.addOwnershipModal.description}
      </div>
      <Form.Item
        style={{ paddingTop: '12px' }}
        label={translations.patientPage.ownership.addOwnershipModal.effectiveDateLabel}
        required
      >
        <DatePicker
          data-testid={'AddOwnershipModalDatePicker'}
          value={effectiveDate}
          onChange={(date) => {
            setEffectiveDate(date);
          }}
          placeholder={translations.patientPage.ownership.addOwnershipModal.effectiveDatePlaceholder}
          disabledDate={(date) => date.isAfter(dayjs())}
          format={dateFormat}
        />
      </Form.Item>
    </>
  );
};
