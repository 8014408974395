import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../constants/routes';
import AuthService from '../services/AuthService/AuthService';
import { v4 as uuid } from 'uuid';
import { SignInLocationState } from '../pages/SignIn/SignInPage';
import { setInitialLocalReplications } from '../util/offline/offlineUtil';
import { USER_KEY } from '../constants/sessionStorageKeys';

export const useLogout = () => {
  const history = useHistory();

  return async () => {
    await AuthService.logout();
    localStorage.removeItem(USER_KEY);
    history.push(routes.login, { from: { pathname: '/' }, reload: true } as SignInLocationState);
  };
};

export const useClearSession = () => {
  const history = useHistory();

  return async () => {
    setInitialLocalReplications();
    await AuthService.logout();
    localStorage.removeItem(USER_KEY);
    history.push(routes.login, { from: { pathname: '/' }, reload: true } as SignInLocationState);
  };
};

const connectionIdKey = 'cassadol-connection';
export const useGetConnectionId = (): string => {
  const [connectionId, setConnectionId] = useState<string>('');

  useEffect(() => {
    const existingConnectionId = getConnectionId();
    if (!existingConnectionId) {
      const newConnectionId = uuid();
      localStorage.setItem(connectionIdKey, newConnectionId);
      setConnectionId(newConnectionId);
    } else {
      setConnectionId(existingConnectionId);
    }
  }, []);

  return connectionId;
};

export const getConnectionId = () => localStorage.getItem(connectionIdKey);
