import { TagColor } from '../tagColor';
import { Invoice } from '../../graph/types';
import { StatusConfig } from '../../util/filterAndSorting';

export enum InvoiceStatusNameKey {
  New = 'INVS_NEW',
  InProgress = 'INVS_INPROGRESS',
  Closed = 'INVS_CLOSED',
  PartiallyPaid = 'INVS_PAIDSOME',
  PaidInFull = 'INVS_PAID',
  Reversed = 'INVS_REVERSED',
}

export enum InvoiceStatusId {
  New = 0,
  InProgress = 10,
  Closed = 20,
  PartiallyPaid = 30,
  PaidInFull = 40,
  Reversed = 50,
}

export const invoiceCanTakePayment = (invoiceStatusId: number | null | undefined) => {
  return (
    Number(invoiceStatusId) >= Number(InvoiceStatusId.Closed) &&
    Number(invoiceStatusId) < Number(InvoiceStatusId.PaidInFull)
  );
};

export const invoiceIsDeletable = (invoiceStatusId: number | null | undefined) => {
  return Number(invoiceStatusId) < Number(InvoiceStatusId.Closed);
};

export const isCompletedInvoiceStatus = (invoiceStatusId: number) =>
  Number(invoiceStatusId) >= Number(InvoiceStatusId.Closed);

export const invoiceStatusConfigs: { [nameKey in InvoiceStatusNameKey]: StatusConfig } = {
  [InvoiceStatusNameKey.New]: {
    text: 'New',
    color: TagColor.Color2,
  },
  [InvoiceStatusNameKey.InProgress]: {
    text: 'In-Progress',
    color: TagColor.Color4,
  },
  [InvoiceStatusNameKey.Closed]: {
    text: 'Closed',
    color: TagColor.Color6,
  },
  [InvoiceStatusNameKey.PartiallyPaid]: {
    text: 'Partially Paid',
    color: TagColor.Color3,
  },
  [InvoiceStatusNameKey.PaidInFull]: {
    text: 'Paid',
    color: TagColor.Color1,
  },
  [InvoiceStatusNameKey.Reversed]: {
    text: 'Reversed',
    color: TagColor.Color5,
  },
};

export const getInvoiceStatusText = (invoice: Invoice): string => {
  return invoiceStatusConfigs[invoice.status_name_key as InvoiceStatusNameKey]?.text || '';
};

export const getInvoiceStatusNameKeyFromId = (statusId: number): string => {
  switch (statusId) {
    case InvoiceStatusId.New:
      return InvoiceStatusNameKey.New;
    case InvoiceStatusId.InProgress:
      return InvoiceStatusNameKey.InProgress;
    case InvoiceStatusId.Closed:
      return InvoiceStatusNameKey.Closed;
    case InvoiceStatusId.PartiallyPaid:
      return InvoiceStatusNameKey.PartiallyPaid;
    case InvoiceStatusId.PaidInFull:
      return InvoiceStatusNameKey.PaidInFull;
    case InvoiceStatusId.Reversed:
      return InvoiceStatusNameKey.Reversed;
    default:
      return '';
  }
};
