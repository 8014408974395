import React, { useEffect } from 'react';
import { TabLoading } from '../../../components/Loading/Loading';
import { TaxonomyTabProps, taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { translations } from '../../../constants/translations';
import { useGetLabRefSpecies, useGetLabSpeciesMapping } from '../../../hooks/ajax/lab/labHooks';
import { TaxonomyTable } from './TaxonomyTable';

export const TaxonomySpecies: React.FC<TaxonomyTabProps> = ({ labId, organizationId, autoMapLoading }) => {
  const { labSpeciesMapping, loading, refetch } = useGetLabSpeciesMapping(organizationId, labId);
  const {
    labRefSpecies,
    loading: labRefSpeciesLoading,
    refetch: refetchLabRefSpecies,
  } = useGetLabRefSpecies(organizationId, labId);

  useEffect(() => {
    refetch({ organizationId, labDeviceId: labId });
    refetchLabRefSpecies({ organizationId, labRefFilter: { lab_device_id: labId } });
  }, [labId, organizationId, refetch, refetchLabRefSpecies]);

  if (loading || labRefSpeciesLoading || autoMapLoading) {
    return <TabLoading labelText={translations.shared.communicatingWithLabMessage} />;
  }

  const speciesOptions =
    labRefSpecies?.map(({ lab_species_number, lab_species_name }) => ({
      label: lab_species_name,
      value: lab_species_number,
    })) ?? [];

  return (
    <TaxonomyTable
      options={speciesOptions}
      dataSource={labSpeciesMapping ?? []}
      type={TaxonomyType.Species}
      typeTranslations={taxonomyTranslations.tabs.species}
      deviceId={labId}
    />
  );
};
