import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, Divider, Form, Input, Radio, Space, Spin } from 'antd';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { translations } from '../../../constants/translations';
import { getRequiredRule } from '../../../util/forms';
import { useForm } from 'antd/lib/form/Form';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import {
  useAcceptEula,
  useFetchSubscriptionPricing,
  useUpdateSubscription,
} from '../../../hooks/ajax/subscription/subscriptionHooks';
import { Loading } from '../../../components/Loading/Loading';
import {
  OrganizationSubscriptionLevelConfig,
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';

import { useGetCurrentUserWithOrganization } from '../../../hooks/ajax/user/userHooks';
import {
  MAX_NUMBER_OF_SUBSCRIPTION_USERS,
  MIN_NUMBER_OF_SUBSCRIPTION_USERS,
  SubscriptionUpsertGenerator,
} from '../../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import { CardConnectTokenizer, reformatEmvExpiry } from '../../../components/CardConnectTokenizer/CardConnectTokenizer';
import { eulaContent } from './eula-content';
import { PrinterOutlined } from '@ant-design/icons';
import './RegisterSubscriptionSettings.styles.css';
import { RegistrationButton } from '../RegistrationButton';
import { getSubscriptionUpsertErrorMessage } from '../../SubscriptionSettings/subscriptionErrorUtil';
import { AddressFields } from '../RegisterPracticeForm/AddressFields';
import { BillingAddress } from '../../../graph/types';
import { RuleObject } from 'antd/lib/form';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';
import { getCurrencyFromSubscriptionBillingAddress } from '../../SubscriptionSettings/subscriptionSettingsUtil';
import { ElectronicPaymentType } from '../../../constants/referenceData/paymentProcessorTypeReferenceData';
import StripeTokenizer from '../../../components/StripeTokenizer/StripeTokenizer';
import { CreditCardTokenInfo, useGetStripeTokenUtils } from '../../../hooks/stripeHooks';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { useLDFlag } from '../../../hooks/useLDHooks';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 16, span: 16 },
};

export interface SubscriptionFormFields {
  payer: string;
  numberOfUsers: number;
  address: (BillingAddress | undefined)[];
  couponCode?: string;
}

interface OrganizationSubscriptionSettingsProps {
  setIsSaving: (value: boolean) => void;
  goToNextStep: (organizationId?: string) => void;
  useStripeCreditCard: boolean;
}

export const acceptChargesFieldName = 'acceptCharges';

export const registerSubscriptionSettingsTest = {
  acceptChargesCheckbox: 'acceptChargesCheckbox',
  eulaRadioGroup: 'eulaRadioGroup',
};

export const RegisterSubscriptionSettings: React.FC<OrganizationSubscriptionSettingsProps> = ({
  setIsSaving,
  goToNextStep,
  useStripeCreditCard,
}) => {
  const { user, userLoading } = useGetCurrentUserWithOrganization('network-only');
  const organizationId = user?.organization?.[0]?.organization_id || '';
  const { referenceData } = useGetReferenceData(organizationId);
  const {
    tokenizerExtraData,
    setTokenizerExtraData,
    organization,
    organizationLoading,
    practice,
    practiceLoading,
    practiceAddress,
    setCreditCard: setManualCreditCard,
    creditCard: manualCreditCard,
  } = useGetStripeTokenUtils(organizationId);
  const [form] = useForm();
  const [formAcceptCharges] = useForm();
  const [hasCalledInitialLoadPriceData, setHasCalledInitialLoadPriceData] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [eulaAcceptedSuccessful, setEulaAcceptedSuccessful] = useState(false);

  const [couponCode, setCouponCode] = useState('');

  const [upsertAcceptEula] = useAcceptEula();

  const [upsertSubscription] = useUpdateSubscription(organizationId);
  const {
    data: subscriptionCharges,
    loading: subscriptionChargesLoading,
    fetchOrganizationSubscriptionLevelPrice: fetchSubscriptionCharges,
  } = useFetchSubscriptionPricing(organizationId, true);

  const levelId = (
    organizationSubscriptionLevelConfigs[
      OrganizationSubscriptionLevelNameKeys.SubLevelAdmin
    ] as OrganizationSubscriptionLevelConfig
  ).level_id;

  const initialSubscriptionData: SubscriptionFormFields = {
    payer: organization?.subscription?.name_on_card ?? '',
    numberOfUsers: organization?.subscription?.detail?.[0]?.qty_used ?? MIN_NUMBER_OF_SUBSCRIPTION_USERS,
    address: [{ ...practiceAddress, phone: practice?.phone?.[0]?.phone, email: user?.email } as BillingAddress],
  };

  const [subscriptionCountryId, setSubscriptionCountryId] = useState<string | null>(null);
  const showCouponCode = useLDFlag(LDFlagNames.Coupon);

  useEffect(() => {
    setSubscriptionCountryId(practiceAddress?.country_id ?? null);
  }, [practiceAddress]);

  useEffect(() => {
    if (!hasCalledInitialLoadPriceData && organization) {
      fetchSubscriptionCharges({
        level_id: levelId,
        set_qty: initialSubscriptionData.numberOfUsers,
      });
      setHasCalledInitialLoadPriceData(true);
    }
  }, [
    initialSubscriptionData.numberOfUsers,
    hasCalledInitialLoadPriceData,
    fetchSubscriptionCharges,
    organization,
    subscriptionCharges,
    levelId,
  ]);

  const handleAcceptEulaUpsert = useCallback(async () => {
    if (!eulaAccepted || eulaAcceptedSuccessful) {
      return;
    }
    await upsertAcceptEula({
      variables: {
        organizationId,
        agreement: {
          eula_text: eulaContent,
          eula_type: 'cassadol_eula',
        },
      },
    });
    setEulaAcceptedSuccessful(true);
  }, [eulaAccepted, eulaAcceptedSuccessful, organizationId, upsertAcceptEula]);

  if (userLoading || practiceLoading || organizationLoading) {
    return <Loading height={500} />;
  }

  if (!organizationId || !practiceAddress) {
    return <p>{translations.organizationSubscriptionSettings.loadingError}</p>;
  }

  const saveSubscription = async (formValues: SubscriptionFormFields, creditCard: CreditCardTokenInfo) => {
    setIsSaving(true);

    const { country_id, country_prov_state_id } = formValues.address[0]!;

    const country_name_key = referenceData?.country?.find(({ id }) => id === country_id)?.name_key;
    const country_prov_state_name_key = referenceData?.prov_state?.find(
      ({ id }) => id === country_prov_state_id
    )?.name_key;

    const upsertAddress = {
      ...formValues.address[0]!,
      country_id,
      country_name_key,
      country_prov_state_id,
      country_prov_state_name_key,
    };

    const subscriptionUpsert = SubscriptionUpsertGenerator.mapToSubscriptionUpsert(
      {
        payer: formValues.payer,
        address: upsertAddress,
      },
      formValues.numberOfUsers,
      {
        token: creditCard.token,
        expiryDate: useStripeCreditCard ? creditCard.expiryDate : reformatEmvExpiry(creditCard.expiryDate),
        processorType: useStripeCreditCard ? ElectronicPaymentType.Stripe : ElectronicPaymentType.CardConnect,
      },
      formValues.couponCode
    );

    try {
      await handleAcceptEulaUpsert();
      const { data } = await upsertSubscription({ variables: { organizationId, subscription: subscriptionUpsert } });
      if (data?.updateOrganizationSubscription) {
        const error = getSubscriptionUpsertErrorMessage(data.updateOrganizationSubscription);
        if (error) {
          setErrorMessage(error);
        } else {
          goToNextStep(organizationId);
        }
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
  };

  const handleValueChange = (changedValues: Store) => {
    if (changedValues.address?.[0]?.country_id) {
      setSubscriptionCountryId(changedValues.address[0].country_id);
    }
    if (changedValues.numberOfUsers) {
      fetchSubscriptionCharges({
        level_id: levelId,
        set_qty: changedValues.numberOfUsers,
      });
    }
    if (changedValues.address || changedValues.payer) {
      setTokenizerExtraData({
        payer: form?.getFieldValue('payer'),
        addressLine1: form?.getFieldValue('address')?.[0]?.address_1,
        addressLine2: form?.getFieldValue('address')?.[0]?.address_2,
        city: form?.getFieldValue('address')?.[0]?.city,
        countryId: form?.getFieldValue('address')?.[0]?.country_id,
        provStateId: form?.getFieldValue('address')?.[0]?.country_prov_state_id,
        postalZip: form?.getFieldValue('address')?.[0]?.postal_zip,
      });
    }
  };

  const onFinish = async (values: SubscriptionFormFields) => {
    formAcceptCharges.submit();
    if (!formAcceptCharges.getFieldValue(acceptChargesFieldName)) {
      return;
    }
    if (!eulaAccepted) {
      showErrorMessage(translations.organizationSubscriptionSettings.eula.errMsg);
      return;
    }
    if (manualCreditCard?.token && manualCreditCard?.expiryDate) {
      await saveSubscription(values, manualCreditCard);
    } else {
      showErrorMessage(translations.organizationSubscriptionSettings.invalidCreditCard);
    }
  };

  const onEulaPrint = () => {
    const printContainer = document.getElementById('print-container');
    if (printContainer) {
      printContainer.innerHTML = eulaContent;
      window.print();
      printContainer.innerHTML = '';
    }
  };

  const acceptCheckboxValidator = async (_: RuleObject, value: StoreValue) => {
    if (!value) {
      throw new Error(translations.organizationSubscriptionSettings.error.acceptChargesValidationMsg);
    }
    await Promise.resolve();
  };

  return (
    <>
      <Form
        {...layout}
        name='basic'
        initialValues={initialSubscriptionData}
        onFinish={onFinish}
        onValuesChange={handleValueChange}
        scrollToFirstError
        form={form}
        autoComplete='off'
      >
        <MaxLengthFormItem
          name='payer'
          className='registration-field'
          label={translations.organizationSubscriptionSettings.nameOnCard}
          rules={[getRequiredRule(translations.organizationSubscriptionSettings.nameOnCard)]}
          maxLength={50}
        >
          <Input placeholder={translations.organizationSubscriptionSettings.nameOnCard} />
        </MaxLengthFormItem>

        {organization && (
          <AddressFields
            referenceData={referenceData}
            index={0}
            form={form}
            disableCountry
            isBillingAddress
            includeEmailAndPhone
          />
        )}

        <Form.Item
          name='numberOfUsers'
          className='registration-field'
          label={translations.organizationSubscriptionSettings.numberOfUsers}
          rules={[getRequiredRule(translations.organizationSubscriptionSettings.numberOfUsers)]}
        >
          <Input
            type='number'
            min={MIN_NUMBER_OF_SUBSCRIPTION_USERS}
            max={MAX_NUMBER_OF_SUBSCRIPTION_USERS}
            step='any'
          />
        </Form.Item>
        {showCouponCode && (
          <MaxLengthFormItem
            name='couponCode'
            className='registration-field'
            label={translations.organizationSubscriptionSettings.couponCode}
            maxLength={50}
          >
            <Input
              placeholder={translations.organizationSubscriptionSettings.couponCode}
              onChange={(evt) => setCouponCode(evt.target.value)}
            />
          </MaxLengthFormItem>
        )}
      </Form>
      <div>
        <Form.Item
          className='registration-field'
          required
          label={translations.organizationSubscriptionSettings.creditCardInfo}
          labelCol={{ span: 8 }}
        >
          {useStripeCreditCard ? (
            <StripeTokenizer
              stripeTokenizerCountryId={practiceAddress?.country_id || undefined}
              setCreditCard={setManualCreditCard}
              tokenizerExtraData={tokenizerExtraData}
              addressDemographics={organization?.ref_demographics || undefined}
            />
          ) : (
            <CardConnectTokenizer
              site={practice?.card_connect_manual?.manualCardSite ?? ''}
              port={practice?.card_connect_manual?.manualCardPort ?? ''}
              setCreditCard={setManualCreditCard}
            />
          )}
        </Form.Item>
        <Divider type='vertical' style={{ margin: '5rem 0' }} />
        <Form.Item {...tailLayout}>
          <div style={{ padding: '2rem' }}>
            <p>{translations.organizationSubscriptionSettings.totalChargeForUsers}:</p>
            {subscriptionCharges?.monthlyTotal && (
              <Spin spinning={subscriptionChargesLoading}>
                <p style={{ fontWeight: 'bold' }}>
                  {getCurrencyFromSubscriptionBillingAddress(subscriptionCountryId)}{' '}
                  <SubscriptionCurrencyFormatter
                    total={Number(subscriptionCharges.monthlyTotal)}
                    countryId={subscriptionCountryId}
                  />
                </p>
              </Spin>
            )}
          </div>
        </Form.Item>
        {couponCode && (
          <>
            <div>{translations.organizationSubscriptionSettings.couponCodeExplanation}</div>
            <p></p>
          </>
        )}
        {!couponCode && <div>{translations.organizationSubscriptionSettings.textAboveCharge}</div>}
        <Form
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          form={formAcceptCharges}
          initialValues={{ acceptCharges: false }}
          scrollToFirstError
        >
          <Form.Item
            label={' '}
            colon={false}
            labelAlign={'right'}
            name={acceptChargesFieldName}
            requiredMark
            rules={[
              getRequiredRule(translations.organizationSubscriptionSettings.accept),
              {
                validator: acceptCheckboxValidator,
              },
            ]}
            valuePropName={'checked'}
          >
            <Checkbox data-testid={registerSubscriptionSettingsTest.acceptChargesCheckbox}>
              {translations.organizationSubscriptionSettings.accept}
            </Checkbox>
          </Form.Item>
        </Form>
        <Form.Item>
          <span>{translations.organizationSubscriptionSettings.textBelowCharge}</span>
        </Form.Item>
        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <Collapse>
            <Collapse.Panel
              key='1'
              header={translations.organizationSubscriptionSettings.eula.label}
              extra={
                <span style={{ color: eulaAccepted ? 'green' : 'red' }}>
                  {translations.organizationSubscriptionSettings.eula.getStatus(eulaAccepted)}
                </span>
              }
            >
              <div style={{ width: '100%', maxHeight: '400px', overflow: 'auto', padding: '0 0.2rem' }}>
                <div
                  className='eula-content'
                  dangerouslySetInnerHTML={{ __html: eulaContent }}
                  style={{ marginBottom: '0.5rem' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Space data-testid={registerSubscriptionSettingsTest.eulaRadioGroup}>
                    <Radio.Group
                      options={[
                        {
                          label: translations.organizationSubscriptionSettings.eula.accept,
                          value: true,
                        },
                        {
                          label: translations.organizationSubscriptionSettings.eula.doNotAccept,
                          value: false,
                        },
                      ]}
                      onChange={(e) => setEulaAccepted(e.target.value)}
                      buttonStyle='solid'
                    />
                  </Space>
                  <Button onClick={onEulaPrint}>
                    <PrinterOutlined /> {translations.organizationSubscriptionSettings.eula.printBtnText}
                  </Button>
                </div>
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
        <p className='Registration_errorMessage'>{errorMessage}</p>
        <RegistrationButton
          onClick={() => {
            form.submit();
          }}
          disabled={!eulaAccepted}
          text={translations.organizationSubscriptionSettings.completeSignup}
        />
      </div>
    </>
  );
};
