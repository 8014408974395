import React, { useContext, useState } from 'react';
import { DefaultNote, Note, Patient, PrescriptionFlattened, ServiceRendered } from '../../../graph/types';
import {
  ModifyAndViewServicesRendered,
  ModifyAndViewServicesRenderedProps,
} from '../../../components/ServiceRendered/ModifyAndViewServicesRendered';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
  heightMainMenu,
  heightOfScrollbar,
  heightTableHeader,
} from '../../../components/ServiceRendered/ServicesRenderedTable/servicesRenderedTableConstants';
import { createNewServiceForListView } from '../rapidBillingMappingUtil';
import {
  NoteChangeRequest,
  NoteChangeRequestType,
  NoteCreateRequest,
  NoteUpdateRequest,
} from '../../../components/ServiceRendered/NoteView/noteChangeUtil';
import { ServicesRenderedContext } from '../../../components/ServiceRendered/store/state';
import { getHeightOfElementWithId } from '../../../util/documentUtil';
import { getServiceText } from './rapidBillingServiceUtil';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import dayjs from 'dayjs';
import { createPreviewTextForNote } from '../../../components/Records/noteUpsertUtils';
import { PracticeSettingsNameKey, translations } from '../../../constants/translations';
import { dummyNoteIdForRapidBilling } from '../../../hooks/ajax/note/noteHooks';
import { useOffline } from '../../../util/offline/offlineUtil';
import { useRxCollection } from 'rxdb-hooks';
import { RxInfoText } from '../../../services/LocalDatabaseService/schemas/infoTextSchema';
import { getPracticeSetting } from '../../Contacts/ViewContact/statementUtils';
import { v4 as uuid } from 'uuid';
import { upsertDateFormat } from '../../../constants/formats';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { getPatientLinkedToHisa } from '../../../components/ServiceRendered/serviceRenderedUtils';

const heightPageHeader = 73;

export interface PatientBackupFunctions {
  backup: () => void;
  restore: () => void;
}

export type RapidBillingServiceRendered = ServiceRendered & { patients?: Patient[] };

export interface RapidBillingServicesProps {
  practiceId: string;
  setPatientsOfService: (serviceID: string, patients: Patient[]) => void;
  servicesRendered: ServiceRendered[];
  setServicesRendered: (action: 'add' | 'update' | 'delete', serviceRendered: RapidBillingServiceRendered) => void;
  activeServiceId?: string | null;
  setActiveServiceId: (serviceId: string | null) => void;
  selectedPatients: Patient[];
  prescriptions: PrescriptionFlattened[];
}

export const RapidBillingServices: React.FC<RapidBillingServicesProps> = ({
  practiceId,
  setPatientsOfService,
  servicesRendered,
  setServicesRendered,
  activeServiceId,
  setActiveServiceId,
  selectedPatients,
  prescriptions,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();

  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice.find(({ id }) => organization.default_practice_id === id);

  const [hasHISAWarningAlreadyShown, setHasHISAWarningAlreadyShown] = useState(false);

  const { canUseCollection } = useOffline('upsert');
  const serviceTextCollection = useRxCollection<RxInfoText>('service_text');
  const { state } = useContext(ServicesRenderedContext);
  const { windowHeight } = useWindowDimensions();
  const practiceHideDiscount = getPracticeSetting(PracticeSettingsNameKey.InvoiceHideDiscount, practice);
  const practiceHideDiscountValue = practiceHideDiscount?.value === 'true';

  const [backupOfPatientsDuringEdit, setBackupOfPatientsDuringEdit] = useState<Patient[]>([]);

  const addService: ModifyAndViewServicesRenderedProps['saveNewService'] = async ({
    doctor,
    businessCenterId,
    locationId,
    services,
  }) => {
    const grouping_id = uuid();
    const newServices = services.map(
      ({
        formValues,
        service,
        vaccineSpecificValues,
        controlledDrugSpecificValues,
        reminderSpecificValues,
        prescriptionSpecificValues,
        quantity,
        roaId,
        roaOther,
        reason,
        withdrawal_period,
      }) => {
        const bundleParentId = services.find(({ service: s }) => s.bundled_service_ids?.length)?.service.id;

        const bundleProperties = bundleParentId
          ? {
              is_parent: bundleParentId === service.id,
              grouping_id,
            }
          : undefined;

        return createNewServiceForListView(
          formValues,
          service,
          doctor,
          businessCenterId,
          locationId,
          upsertDateFormat,
          service.vaccine ? vaccineSpecificValues : controlledDrugSpecificValues,
          reminderSpecificValues,
          prescriptionSpecificValues,
          quantity,
          roaId,
          roaOther,
          reason,
          withdrawal_period,
          practiceHideDiscountValue,
          bundleProperties
        );
      }
    );

    for (const i in services) {
      const service = services[i]?.service;
      if (service?.default_note_id) {
        const fillNote = (defaultNote: DefaultNote) => {
          // verify if defaultNote is a valid DefaultNote type value
          if (defaultNote.noteTypeId && defaultNote.noteText && defaultNote.noteTypeName) {
            newServices[i].note_id = dummyNoteIdForRapidBilling;
            newServices[i].note = getNote({
              requestType: NoteChangeRequestType.CREATE,
              noteUpsertValues: {
                type_id: defaultNote.noteTypeId,
                textContent: defaultNote.noteText,
                plainTextContent: defaultNote.notePreview,
                date: dayjs(),
              },
              service: newServices[i],
            });
          }
        };

        if (!canUseCollection) {
          await getServiceText({ id: service.default_note_id, organizationId }, (infoText) => {
            if (!infoText.value) {
              return;
            }
            try {
              const defaultNote: DefaultNote = JSON.parse(infoText?.value);
              fillNote(defaultNote);
            } catch {}
          });
        } else {
          try {
            await serviceTextCollection
              ?.findOne(service.default_note_id)
              .exec()
              .then((text) => {
                if (!text?.value) {
                  return;
                }
                const defaultNote: DefaultNote = JSON.parse(text?.value);
                fillNote(defaultNote);
              });
          } catch {}
        }
      }
    }

    newServices.forEach((service) => {
      const parsedService = {
        ...service,
        patients: selectedPatients,
      };

      setServicesRendered('add', parsedService);
      setPatientsOfService(parsedService.id, selectedPatients);
      setActiveServiceId(parsedService.id);
    });
    return newServices[0];
  };

  const updateServiceRendered = (updatedServiceRendered: RapidBillingServiceRendered) => {
    setServicesRendered('update', updatedServiceRendered);
  };

  const deleteServiceRendered = (serviceRendered: RapidBillingServiceRendered) => {
    setServicesRendered('delete', serviceRendered);
    setActiveServiceId(null);
  };

  const patientBackupFunctions: PatientBackupFunctions = {
    backup: () => setBackupOfPatientsDuringEdit(selectedPatients),
    restore: () => {
      if (state.editingServiceRenderedId) {
        setPatientsOfService(state.editingServiceRenderedId, backupOfPatientsDuringEdit);
      }
    },
  };

  const heightAddService = getHeightOfElementWithId('AddServiceForm');
  const heightAboveTable = heightMainMenu + heightPageHeader + heightAddService + heightTableHeader;
  const heightBelowTable = getHeightOfElementWithId('RapidBilling__BelowTable');
  const heightNotUsedByTable = heightAboveTable + heightBelowTable + heightOfScrollbar;
  const tableHeight = windowHeight - heightNotUsedByTable;

  const getNote = (noteChangeRequest: NoteCreateRequest | NoteUpdateRequest) =>
    ({
      id: dummyNoteIdForRapidBilling,
      type_id: noteChangeRequest.noteUpsertValues.type_id,
      date: noteChangeRequest.noteUpsertValues.date.format(upsertDateFormat),
      hidden: false,
      locked: false,
      value: noteChangeRequest.noteUpsertValues.textContent,
      preview: createPreviewTextForNote(noteChangeRequest.noteUpsertValues.plainTextContent),
    } as Note);

  const changeNote = (noteChangeRequest: NoteChangeRequest) => {
    const newService: RapidBillingServiceRendered = { ...noteChangeRequest.service };
    switch (noteChangeRequest.requestType) {
      case NoteChangeRequestType.CREATE:
      case NoteChangeRequestType.UPDATE:
        newService.note_id = dummyNoteIdForRapidBilling;
        newService.note = getNote(noteChangeRequest);
        break;
      case NoteChangeRequestType.DELETE:
        newService.note_id = undefined;
        newService.note = undefined;
        break;
    }

    setServicesRendered('update', newService);
  };

  const hasAtLeatOneHISAPatient = selectedPatients.some(getPatientLinkedToHisa);

  return (
    <ModifyAndViewServicesRendered
      practiceId={practiceId}
      initialServicesRendered={servicesRendered}
      saveNewService={addService}
      updateServiceRendered={updateServiceRendered}
      deleteServiceRendered={deleteServiceRendered}
      patientBackupFunctions={patientBackupFunctions}
      activeServiceId={activeServiceId}
      setActiveServiceId={setActiveServiceId}
      tableHeight={tableHeight}
      editable
      onlyAllowOnCellClickEditingOfActiveRow
      changeNote={changeNote}
      removedForRapidBilling
      prescriptions={prescriptions}
      hasHISAWarningAlreadyShown={hasHISAWarningAlreadyShown}
      setHasHISAWarningAlreadyShown={setHasHISAWarningAlreadyShown}
      isForHISAPatient={hasAtLeatOneHISAPatient}
      formProps={{
        disabled: selectedPatients?.length === 0,
        disabledMessage: translations.rapidBilling.selectPatientMessage,
      }}
    />
  );
};
