import { SmtpSettings, SmtpSettingsDto } from '../graph/types';

export const hasValidEmailSettings = (smtp?: SmtpSettings | SmtpSettingsDto | null) =>
  !!(
    (!smtp?.useSES &&
      smtp?.accountName &&
      smtp?.displayName &&
      smtp?.serverName &&
      smtp?.portNumber &&
      smtp?.fromAddress) ||
    (smtp?.useSES && smtp?.fromAddress && smtp?.displayName && smtp?.replyToAddress)
  );

export const hasValidEmailSettingsDto = (smtp?: SmtpSettingsDto | null) =>
  !!(
    (!smtp?.useSES &&
      smtp?.accountName &&
      smtp?.displayName &&
      smtp?.serverName &&
      smtp?.portNumber &&
      smtp?.fromAddress) ||
    (smtp?.useSES && smtp?.fromAddress && smtp?.displayName && smtp?.replyToAddress)
  );
