import { translations } from '../../../constants/translations';
import { ServiceRendered3pApproval, ServiceRenderedUpdate, ServiceRenderedUpsert } from '../../../graph/types';
import React, { useState } from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { useGetApprovals } from '../../../hooks/ajax/thirdPartyData/thirdPartyDataHooks';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { AbbreviateToWidth } from '../../../components/AbbreviateToWidth/AbbreviateToWidth';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../../constants/layout';
import { Loading } from '../../../components/Loading/Loading';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { basicApprovalsColumns } from './approvalsColumns';
import { TableKey } from '../../../hooks/tableHooks';
import {
  DropdownButtonWithMenu,
  MenuItemProps,
} from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import {
  useNavigationToRoute,
  withInvoiceIdParameter,
  withPatientIdParameter,
} from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import { getPatientRelationTags } from '../../../util/tags';
import { useUpdateServiceRendered } from '../../../hooks/ajax/invoice/invoiceHooks';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { useTaskWithProgress } from '../../../hooks/taskHooks';
import { isNil } from 'lodash';
import { EditValidationDetailsModal } from '../EditValidationDetailsModal/EditValidationDetailsModal';
import {
  ThirdPartyApprovalTypeConfigs,
  ThirdPartyApprovalTypeNameKey,
} from '../../../constants/referenceData/thirdPartyReferenceData';
import { displayAsDate } from '../../../constants/formats';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';

export type ApprovalRequest = {
  id: string;
  item: ServiceRenderedUpsert[];
};

export const ApprovalsOverview: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { approvals, approvalsLoading, refetch } = useGetApprovals(organizationId);
  const { navigateTo } = useNavigationToRoute();
  const { referenceData } = useGetReferenceData(organizationId);
  const [selectedApprovals, setSelectedApprovals] = useState<ServiceRendered3pApproval[]>([]);
  const [currentTableDataSource, setCurrentTableDataSource] = useState<ServiceRendered3pApproval[]>([]);
  const [updateServiceRendered] = useUpdateServiceRendered();
  const [editValidationDetailsModalIsOpen, setEditValidationDetailsModalIsOpen] = useState(false);
  const [selectedApproval, setSelectedApproval] = useState<ServiceRendered3pApproval>();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const handleApproval = async (serviceRendered: ServiceRenderedUpdate, onSuccess: () => void) => {
    try {
      await updateServiceRendered({
        variables: { organizationId, serviceRendered },
      });

      onSuccess();
    } catch (e) {
      showErrorMessage(translations.approvalsPage.errorApproving);
    }
  };

  const handleApprovals = () => {
    const serviceRenderedUpserts = selectedApprovals.map(({ id }) => ({ id, approvedRecord: { approved: true } }));
    startApproval(serviceRenderedUpserts);
  };

  const onApprovalComplete = () => {
    refetch();
    setSelectedApprovals([]);
  };

  const {
    progress: approvalProgress,
    stop: stopApproval,
    start: startApproval,
  } = useTaskWithProgress<ServiceRenderedUpdate>(handleApproval, onApprovalComplete);

  const handleCancelApproval = () => {
    stopApproval();
    refetch();
  };

  const handleEditValidationDetailsClick = (approval: ServiceRendered3pApproval) => {
    setSelectedApproval(approval);
    setEditValidationDetailsModalIsOpen(true);
  };

  const handleCloseEditValidationDetailsModal = (needRefetchData: boolean) => {
    if (needRefetchData) {
      refetch();
    }
    setEditValidationDetailsModalIsOpen(false);
  };

  if (approvalsLoading) {
    return <Loading />;
  }

  const linkApprovalMenu = (serviceRendered: ServiceRendered3pApproval) => {
    const invoiceActions = [
      {
        title: translations.shared.editButtonText,
        onClick: () => navigateTo(routes.viewInvoice, withInvoiceIdParameter(serviceRendered.invoice_id ?? '')),
      },
    ] as MenuItemProps[];

    if (serviceRendered.validation_failed) {
      invoiceActions.push({
        title: translations.approvalsPage.buttons.editValidationDetails,
        onClick: () => handleEditValidationDetailsClick(serviceRendered),
        tooltip: translations.approvalsPage.buttons.editValidationDetailsTooltip,
      });
    }

    return <DropdownButtonWithMenu menuItemProps={invoiceActions} />;
  };

  const navigateToViewPatient = (patientId: string) => () => {
    navigateTo(routes.viewPatient, withPatientIdParameter(patientId));
  };

  const linkToViewPatient = (approval: ServiceRendered3pApproval) => {
    const patientId = approval.patient_id ?? '';
    const patientName = approval.patient_name ?? '';
    return (
      <TableCellLink onClick={navigateToViewPatient(patientId)}>
        <AbbreviateToWidth width={250} text={patientName} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
      </TableCellLink>
    );
  };

  const returnFormattedQuantity = (approval: ServiceRendered3pApproval) => {
    return parseFloat(approval.quantity).toFixed(2);
  };

  const renderValidationError = (approval: ServiceRendered3pApproval) => {
    return (
      approval.validation_failed && (
        <div title='Validation Error'>
          <ExclamationCircleOutlined style={{ color: 'red' }} />
        </div>
      )
    );
  };

  const columns: CustomColumnType<ServiceRendered3pApproval>[] = [
    {
      ...basicApprovalsColumns.validation_failed,
      render: (_, approval: ServiceRendered3pApproval) => renderValidationError(approval),
      width: 50,
    },
    {
      ...basicApprovalsColumns.date,
      render: (_, approval: ServiceRendered3pApproval) =>
        approval?.date ? displayAsDate(approval?.date, dateFormat) : '',
      width: 120,
    },
    {
      ...basicApprovalsColumns.doctor_name,
      width: 200,
    },
    {
      ...basicApprovalsColumns.patient_name,
      render: (_, approval: ServiceRendered3pApproval) => linkToViewPatient(approval),
      width: 200,
    },
    {
      ...basicApprovalsColumns.owner_names,
      width: 200,
    },
    {
      ...basicApprovalsColumns.related_names,
      render: (_, approval: ServiceRendered3pApproval) =>
        getPatientRelationTags(approval, referenceData?.contact_type || []),
      width: 200,
    },
    {
      ...basicApprovalsColumns.service_name,
      width: 200,
    },
    {
      ...basicApprovalsColumns.quantity,
      width: 100,
      render: (_, approval: ServiceRendered3pApproval) => returnFormattedQuantity(approval),
    },
    {
      ...basicApprovalsColumns.info,
      width: 100,
      render: (record: ServiceRendered3pApproval) => {
        const key = record.info as ThirdPartyApprovalTypeNameKey;
        return <div>{ThirdPartyApprovalTypeConfigs[key] ?? record.info}</div>;
      },
    },
    {
      title: translations.contactsPage.columns.actions,
      key: 'actions',
      render: (record: ServiceRendered3pApproval) => linkApprovalMenu(record),
    },
  ];

  return (
    <SaveSpinner
      isSaving={!isNil(approvalProgress)}
      savingMessage={translations.loadingComponent.saving}
      progress={approvalProgress}
      onCancel={handleCancelApproval}
    >
      <StyledPageHeader
        title={translations.approvalsPage.title}
        extra={[
          <Button
            key='btnApproval'
            type='primary'
            disabled={!selectedApprovals || selectedApprovals.length === 0}
            onClick={handleApprovals}
          >
            {translations.approvalsPage.buttons.approve}
          </Button>,
        ]}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.ApprovalsOverview}
        columns={columns}
        dataSource={approvals || undefined}
        rowKey={'id'}
        rowSelection={{
          type: 'checkbox',
          onChange: (_, records) => setSelectedApprovals(records.filter((x) => !x.validation_failed)),
          onSelectAll: (selected) =>
            selected
              ? setSelectedApprovals(currentTableDataSource.filter((x) => !x.validation_failed))
              : setSelectedApprovals([]),
          selectedRowKeys: selectedApprovals.map((i) => i.id),
        }}
        setCurrentDataSource={setCurrentTableDataSource}
      />
      {editValidationDetailsModalIsOpen && (
        <EditValidationDetailsModal approval={selectedApproval} onClose={handleCloseEditValidationDetailsModal} />
      )}
    </SaveSpinner>
  );
};
