import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { DEFAULT_LOCALE, LOCALES, LocaleType } from '../util/locales';
import { CountryNameKey } from '../constants/countries';
import { useCustomizedDateFormat } from './useCustomizedDateFormat';
import { useOrganizationContext } from '../contexts/organization/state';
dayjs.extend(updateLocale);

type HookReturnData = {
  localeData: LocaleType;
};

export const useUserLocaleData = (): HookReturnData => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice.find(({ id }) => id === organization.default_practice_id);
  const { dateFormat: customizedDateFormat } = useCustomizedDateFormat();

  const country: CountryNameKey = useMemo(() => {
    const address = practice?.address;
    return (address?.[0]?.country_name_key as CountryNameKey) ?? DEFAULT_LOCALE;
  }, [practice]);

  const localeData = useMemo(() => LOCALES[country], [country]);
  const dateFormat = useMemo(
    () => customizedDateFormat ?? localeData.dateFormat,
    [customizedDateFormat, localeData.dateFormat]
  );

  useEffect(() => {
    if (localeData.momentLocale) {
      if (localeData.momentLocale === 'en') {
        dayjs.locale('en');
        return;
      }

      (async () => {
        try {
          const momentLocale = await import(`moment/locale/${localeData.momentLocale}`);
          dayjs.updateLocale(localeData.momentLocale, momentLocale);
        } catch (err) {
          // eslint-disable-next-line
          console.error(err);
        }
      })();
    }
  }, [localeData]);

  return useMemo(() => ({ localeData: { ...localeData, dateFormat } }), [localeData, dateFormat]);
};

export const useLocale = (country?: string) =>
  useMemo(() => LOCALES[(country as CountryNameKey) ?? DEFAULT_LOCALE], [country]);
