import React, { createContext, Dispatch, PropsWithChildren, Reducer, useReducer } from 'react';

type GenericState = Record<string, any>;

export const createContextFor = <State extends GenericState, Action>() =>
  createContext<{
    state: State;
    dispatch: Dispatch<Action>;
  }>({} as any);

interface PropsWithState<State extends GenericState> extends PropsWithChildren {
  state?: Partial<State>;
}

export type ContextProviderFC<State extends GenericState = Record<string, unknown>> = React.FC<PropsWithState<State>>;

interface ContextProviderProps<State extends GenericState, Action> {
  Context: React.Context<{ state: State; dispatch: Dispatch<Action> }>;
  initialState: State;
  reducer: Reducer<State, Action>;
  props: React.PropsWithChildren<PropsWithState<State>>;
}

export const ContextProvider = <State extends GenericState, Action>(props: ContextProviderProps<State, Action>) => {
  const fullInitialState = {
    ...props.initialState,
    ...(props.props?.state && props.props.state),
  } as State;

  const [state, dispatch] = useReducer(props.reducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };

  return <props.Context.Provider value={value}>{props.props.children}</props.Context.Provider>;
};

export type Action<Type extends string | number, Payload> = Payload extends undefined
  ? { type: Type; payload?: Payload }
  : { type: Type; payload: Payload };
