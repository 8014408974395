import React, { Fragment, PropsWithChildren, useCallback, useState, useEffect } from 'react';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useGetContact, useUpdateContact } from '../../../hooks/ajax/contact/contactHooks';
import { useGetPatient, useUpdatePatient } from '../../../hooks/ajax/patients/patientHooks';
import { useGetInvoice, useUpdateInvoice } from '../../../hooks/ajax/invoice/invoiceHooks';
import { Button, Form, Row, Input } from 'antd';
import { Loading } from '../../../components/Loading/Loading';
import { translations } from '../../../constants/translations';
import { getValidEmailRule } from '../../../util/forms';
import { ownerRelationTypeId, PatientInfoType } from '../../../constants/referenceData/patientReferenceData';
import {
  ContactUpsert,
  PatientUpsert,
  ServiceRendered3pApproval,
  ThirdPartyGenderMapUpsert,
} from '../../../graph/types';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { TitleDiv } from '../../../globalStyles.style';
import {
  PatientFieldsRequirement,
  PatientModalContent,
  PatientModalFormValues,
} from '../../../components/PatientModalContent/PatientModalContent';
import {
  MicrochipModalContent,
  MicrochipModalFormValues,
} from '../../../components/MicrochipModalContent/MicrochipModalContent';
import { RoaModalContent, RoaModalFormValues } from '../../../components/RoaModalContent/RoaModalContent';
import { ReasonModalContent, ReasonModalFormValues } from '../../../components/ReasonModalContent/ReasonModalContent';
import { getInfoUpsert } from '../../../classes/upsertGenerators/infoMappingUtil';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { getInvoiceUpsertForUpdatingRenderedService } from '../../Invoices/ViewInvoice/getInvoiceUpsertServiceRendered';
import { showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { ThirdPartyTaxonomyMapRow } from '../../../components/TaxonomyMappingModalContent/ThirdPartyTaxonomyMapRow';
import { getThirdPartyUpsert, ThirdPartyTaxonomyData } from '../../../util/thirdPartyUtil';
import { useForm } from 'antd/lib/form/Form';
import { upsertDateFormat } from '../../../constants/formats';
import { successMessageOption } from '../../../components/ServiceRendered/AddServiceForm/AddServiceForm';

export type EditValidationDetailsModalFormValues = PatientModalFormValues &
  MicrochipModalFormValues &
  RoaModalFormValues &
  ReasonModalFormValues & { email?: string };

interface EditValidationDetailsModalProps extends PropsWithChildren<unknown> {
  approval?: ServiceRendered3pApproval;
  onClose: (needRefetchData: boolean) => void;
}

export const EditValidationDetailsModal: React.FC<EditValidationDetailsModalProps> = ({ approval, onClose }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { patient, patientLoading } = useGetPatient(approval?.patient_id ?? '', organizationId, {
    additionalPatientField: 'all',
  });
  const { invoice, invoiceLoading } = useGetInvoice(
    {
      invoiceId: approval?.invoice_id ?? '',
      organizationId,
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      subscribe: true,
    }
  );
  const { contact, contactLoading } = useGetContact({
    contactId: invoice?.contact_id ?? '',
    organizationId,
  });
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.PatientDemographics
  );

  const [isLoading, setIsLoading] = useState(patientLoading || contactLoading || organizationLoading || invoiceLoading);
  const [taxonomyByThirdParty, setTaxonomyByThirdParty] = useState<Record<string, ThirdPartyTaxonomyData[]>>();

  const [updatePatient] = useUpdatePatient();
  const [updateInvoice] = useUpdateInvoice(invoice?.id);
  const [updateContact] = useUpdateContact();

  const speciesId = patient?.species_id ?? '';

  const missingPatientFields: PatientFieldsRequirement = {
    requiresBreed: false,
    requiresGender: approval?.missing_gender || false,
    requiresDOB: approval?.missing_dob || false,
    requiresMicrochip: false,
  };

  const hasMissingPatientFields =
    missingPatientFields.requiresBreed ||
    missingPatientFields.requiresGender ||
    missingPatientFields.requiresDOB ||
    missingPatientFields.requiresMicrochip;

  useEffect(() => {
    setIsLoading(patientLoading || contactLoading || organizationLoading);
  }, [patientLoading, contactLoading, organizationLoading]);

  const [form] = useForm();
  const handleFinish = useCallback(
    async (values: EditValidationDetailsModalFormValues) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      const serviceRendered = invoice?.item?.find((x) => x.service_id === approval?.service_id);

      if ((approval?.missing_dob || approval?.missing_gender || approval?.missing_microchip) && patient) {
        const patientUpsert = {
          id: patient.id,
          defaultBillToRecord: {
            default_bill_to_id: patient.default_bill_to_id ?? ownerRelationTypeId,
          },
          record: {
            name: patient.name,
            name_2: patient.name_2,
            name_3: patient.name_3,
            species_id: patient.species_id,
            gender_id: values.gender_id ?? patient.gender_id,
            breed_id: patient.breed_id,
            color_id: patient.color_id,
            dob: values.dob ? values.dob?.format(upsertDateFormat) : patient.dob,
            deceased: patient.deceased,
            deceased_date: patient.deceased_date,
            inactive: patient.inactive,
            created_practice_id: patient.created_practice_id,
            offline_id: patient.offline_id,
          },
        } as PatientUpsert;

        if (approval?.missing_microchip) {
          const microchipInfoType = organization?.ref_patient.info_type.find(
            ({ name_key }) => name_key === PatientInfoType.Microchip
          );
          patientUpsert.info = getInfoUpsert(microchipInfoType?.id ?? '', values.microchip ?? '', patient?.info ?? []);
        }

        await updatePatient({
          ...successMessageOption,
          variables: {
            organizationId,
            patient: patientUpsert,
          },
        });
      }

      if (approval?.missing_contact_email && contact) {
        const contactUpsert = {
          id: contact.id,
          record: {
            name: contact.name,
            contact_status_id: contact.contact_status_id,
            email: values.email ? values.email : contact.email,
            dob: contact.dob,
            created_practice_id: '',
            send_type: contact.send_type,
            statement_ignore: contact.statement_ignore,
            interest_ignore: contact.interest_ignore,
            tax_exempt: contact.tax_exempt,
          },
        } as ContactUpsert;

        await updateContact({
          variables: {
            organizationId,
            contact: contactUpsert,
          },
        });
      }

      if ((approval?.missing_roa || approval?.missing_reason || approval?.missing_gender_map) && serviceRendered) {
        let thirdPartyUpserts: ThirdPartyGenderMapUpsert[] = [];
        const serviceUpsert = getInvoiceUpsertForUpdatingRenderedService(
          {
            ...serviceRendered,
            roa_id: approval?.missing_roa ? values.roa_id : serviceRendered.roa_id,
            roa_other: approval?.missing_roa ? values.roa_other : serviceRendered.roa_other,
            reason: approval?.missing_reason ? values.reason : serviceRendered.reason,
          },
          upsertDateFormat
        );

        if (taxonomyByThirdParty) {
          thirdPartyUpserts = getThirdPartyUpsert(values, Object.keys(taxonomyByThirdParty));
        }

        await updateInvoice({
          variables: {
            organizationId,
            invoice: {
              ...serviceUpsert,
              ...(thirdPartyUpserts.length > 0 && {
                thirdPartyTaxonomyMapping: {
                  organization_id: organizationId,
                  third_party_gender_map: thirdPartyUpserts,
                },
              }),
            },
          },
        });
      }

      showSuccessMessage(translations.shared.saveSuccessMessage);
      setIsLoading(false);
      onClose(true);
    },
    [
      onClose,
      organization,
      patient,
      approval,
      isLoading,
      updateInvoice,
      updatePatient,
      contact,
      invoice,
      organizationId,
      updateContact,
      taxonomyByThirdParty,
    ]
  );

  const getModalContent = () => {
    const content = [];
    if (approval?.missing_contact_email) {
      content.push(
        <Fragment key={'contact'}>
          <TitleDiv>{translations.approvalsPage.editValidationModal.fields.email.title(contact?.name ?? '')}</TitleDiv>
          <MaxLengthFormItem
            validateTrigger='onBlur'
            name='email'
            label={translations.approvalsPage.editValidationModal.fields.email.label}
            rules={[getValidEmailRule()]}
            maxLength={1000}
            required
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
        </Fragment>
      );
    }
    if (hasMissingPatientFields) {
      content.push(
        <Fragment key={'patient'}>
          <TitleDiv>{translations.shared.servicePatientModal.title(patient?.name ?? '')}</TitleDiv>
          <PatientModalContent
            missingFields={missingPatientFields}
            speciesId={speciesId}
            patientRef={organization?.ref_patient}
            form={form}
            patientDOB={patient?.dob || undefined}
          />
        </Fragment>
      );
    }
    if (approval?.missing_microchip) {
      content.push(
        <Fragment key={'microchip'}>
          <TitleDiv>{translations.shared.microchipModal.title}</TitleDiv>
          <MicrochipModalContent />
        </Fragment>
      );
    }
    if (approval?.missing_roa) {
      content.push(
        <Fragment key={'roa_id'}>
          <TitleDiv>{translations.shared.roaModal.title}</TitleDiv>
          <RoaModalContent />
        </Fragment>
      );
    }
    if (approval?.missing_reason) {
      content.push(
        <Fragment key={'reason'}>
          <TitleDiv>{translations.shared.reasonModal.title}</TitleDiv>
          <ReasonModalContent />
        </Fragment>
      );
    }
    if (approval?.missing_gender_map) {
      content.push(
        <Fragment key={'gender'}>
          <ThirdPartyTaxonomyMapRow
            thirdPartyTaxonomy={invoice?.thirdPartyTaxonomyMapping ?? []}
            form={form}
            setTaxonomyByThirdParty={setTaxonomyByThirdParty}
          />
        </Fragment>
      );
    }
    return content;
  };

  return (
    <ModalWithCloseConfirm
      open
      onCancel={() => onClose(false)}
      title={translations.approvalsPage.editValidationModal.title}
      footer={null}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Form onFinish={handleFinish} autoComplete='off' labelCol={{ span: 9 }} form={form}>
            {getModalContent()}
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button onClick={() => onClose(false)} style={{ marginRight: 10 }}>
                {translations.shared.closeButtonText}
              </Button>
              <Button type={'primary'} htmlType='submit' loading={isLoading}>
                {translations.approvalsPage.editValidationModal.confirm}
              </Button>
            </Row>
          </Form>
        </>
      )}
    </ModalWithCloseConfirm>
  );
};
