import gql from 'graphql-tag';
import { SyncErrorFields } from '../fragments/syncErrorFragments';

export const GetSyncErrors = gql`
  query GetSyncErrors($organizationId: ID!, $fromDate: String) {
    getOfflineUpsertErrors(organizationId: $organizationId, fromDate: $fromDate) {
      ...SyncErrorFields
    }
  }
  ${SyncErrorFields}
`;

export const UpsertOfflineSnapshot = gql`
  mutation UpsertOfflineSnapshot($organizationId: ID!, $offlineSnapshot: OfflineSnapshotUpsert!) {
    upsertOfflineSnapshot(organizationId: $organizationId, offlineSnapshot: $offlineSnapshot) {
      id
      connection_id
      json_data
      created
      created_user_id
    }
  }
`;
