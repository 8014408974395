import gql from 'graphql-tag';

export const GetStatementReport = gql`
  query getStatementReport(
    $contactId: [ID!]!
    $request: ReportRequest!
    $startDate: String!
    $endDate: String!
    $footer: String
  ) {
    getStatementReport(
      contactId: $contactId
      request: $request
      startDate: $startDate
      endDate: $endDate
      footer: $footer
    ) {
      pointer
      error
    }
  }
`;
