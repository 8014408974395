export enum UserPermissionNameKey {
  PERM_RPT_ALL = 'PERM_RPT_ALL',
  PERM_SET_ALL = 'PERM_SET_ALL',
  PERM_SET_SUBSCRIPTION = 'PERM_SET_SUBSCRIPTION',
  PERM_DSB_ALL = 'PERM_DSB_ALL',
}

export const userPermissionIdMap = {
  [UserPermissionNameKey.PERM_RPT_ALL]: '1',
  [UserPermissionNameKey.PERM_SET_ALL]: '2',
  [UserPermissionNameKey.PERM_SET_SUBSCRIPTION]: '3',
  [UserPermissionNameKey.PERM_DSB_ALL]: '4',
};
