import gql from 'graphql-tag';
import { StatementRunFields, BasicStatementRunFields } from '../fragments/statementFragments';

export const GetStatementRuns = gql`
  query getStatementRuns($organizationId: ID!, $practiceId: ID!) {
    getStatementRuns(organizationId: $organizationId, practiceId: $practiceId) {
      ...BasicStatementRunFields
    }
  }
  ${BasicStatementRunFields}
`;

export const GetStatementRun = gql`
  query getStatementRun($organizationId: ID!, $statementRunId: ID!) {
    getStatementRun(organizationId: $organizationId, statementRunId: $statementRunId) {
      ...StatementRunFields
    }
  }
  ${StatementRunFields}
`;

export const UpsertStatementRun = gql`
  mutation upsertStatementRun($organizationId: ID!, $statementRun: StatementRunUpsert!) {
    upsertStatementRun(organizationId: $organizationId, statementRun: $statementRun) {
      ...BasicStatementRunFields
    }
  }
  ${BasicStatementRunFields}
`;
