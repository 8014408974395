import { Phone, PhoneDto } from '../graph/types';

export function getPrimaryPhoneNumber(phones: Phone[]): string {
  return getPrimaryPhone(phones)?.phone || '';
}

export function getPrimaryPhoneNumberDto(phones: PhoneDto[]): string {
  return getPrimaryPhoneDto(phones)?.phone || '';
}

export function getPrimaryPhone(phones?: Phone[]): Phone | undefined {
  const primaryPhones = phones?.filter((phone) => phone.primary);
  return primaryPhones?.[0];
}

export function getPrimaryPhoneDto(phones?: PhoneDto[]): PhoneDto | undefined {
  const primaryPhones = phones?.filter((phone) => phone.primary);
  return primaryPhones?.[0];
}
