import Modal from 'antd/lib/modal/Modal';
import dayjs from 'dayjs';
import React from 'react';
import { PaymentSummary } from '../../components/PaymentModal/PaymentModal/PaymentSummary/PaymentSummary';
import { translations } from '../../constants/translations';

interface PaymentConfirmationProps {
  amount: number;
  cardNumber: string;
  open: boolean;
  onOk?: () => void;
  onCancel: () => void;
}

export const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({ amount, cardNumber, ...modalProps }) => {
  return (
    <Modal {...translations.dataConversion.paymentConfirmationModal} {...modalProps}>
      <PaymentSummary
        formValues={{
          date: dayjs(),
          amount,
        }}
        additionalFields={[{ label: translations.paymentModal.fields.card, value: cardNumber }]}
      />
    </Modal>
  );
};
