import gql from 'graphql-tag';
import { InfoFields } from '../fragments';
import {
  CurrentOwnershipFields,
  OfflinePatientFields,
  PatientFields,
  PatientForDropdownFields,
  PatientInvoiceOwnershipIssueFields,
  PatientOverviewFields,
  RelatedContactFields,
} from '../fragments/patientFragments';

export const GetPatients = gql`
  query getPatients(
    $organizationId: ID!
    $take: Int!
    $sort: ElasticQuerySort!
    $queryString: ElasticQueryString
    $source: ElasticSourceOptions
  ) {
    getPatientsEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      queryString: $queryString
      source: $source
      returnHidden: false
    ) {
      ...PatientOverviewFields
    }
  }
  ${PatientOverviewFields}
`;

export const GetPatientsForDropdown = gql`
  query getPatients(
    $organizationId: ID!
    $take: Int!
    $sort: ElasticQuerySort!
    $queryString: ElasticQueryString
    $source: ElasticSourceOptions
  ) {
    getPatientsEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      queryString: $queryString
      source: $source
      returnHidden: false
    ) {
      ...PatientForDropdownFields
    }
  }
  ${PatientForDropdownFields}
`;

export const GetPatientsForOffline = gql`
  query getPatients($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $searchAfter: [ID!]!) {
    getPatientsEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      searchAfter: $searchAfter
      returnHidden: true
    ) {
      ...OfflinePatientFields
      offline_id
    }
  }
  ${OfflinePatientFields}
`;

export const GetContactPatients = gql`
  query getContactPatients($organizationId: ID!, $contactId: ID!) {
    getContactPatientsEs(organizationId: $organizationId, contactId: $contactId) {
      ...PatientOverviewFields
    }
  }
  ${PatientOverviewFields}
`;

export const UpsertPatient = gql`
  mutation upsertPatient($organizationId: ID!, $patient: PatientUpsert!) {
    upsertPatient(organizationId: $organizationId, patient: $patient) {
      ...PatientFields
    }
  }
  ${PatientFields}
`;

export const SyncPatient = gql`
  mutation syncPatientInvoice($organizationId: ID!, $patient: PatientSyncRequest!) {
    syncPatientInvoice(organizationId: $organizationId, patient: $patient) {
      invoice_issue {
        ...PatientInvoiceOwnershipIssueFields
      }
    }
  }
  ${PatientInvoiceOwnershipIssueFields}
`;

export const GetPatient = gql`
  query getPatient($organizationId: ID!, $patientId: ID!) {
    getPatientEs(organizationId: $organizationId, patientId: $patientId) {
      ...PatientFields
    }
  }
  ${PatientFields}
`;

export const GetPatientInfo = gql`
  query getPatient($organizationId: ID!, $patientId: ID!) {
    getPatientEs(organizationId: $organizationId, patientId: $patientId) {
      id
      info {
        ...InfoFields
      }
    }
  }
  ${InfoFields}
`;

export const GetPatientCurrentOwnershipAndRelationship = gql`
  query getPatient($organizationId: ID!, $patientId: ID!) {
    getPatientEs(organizationId: $organizationId, patientId: $patientId) {
      id
      ownership_current {
        ...CurrentOwnershipFields
      }
      related_current {
        ...RelatedContactFields
      }
    }
  }
  ${CurrentOwnershipFields}
  ${RelatedContactFields}
`;

export const GetPatientHistoryReport = gql`
  query getPatientHistoryReport($patientId: ID!, $request: ReportRequest!, $startDate: String!, $endDate: String!) {
    getPatientHistoryReport(patientId: $patientId, request: $request, startDate: $startDate, endDate: $endDate) {
      pointer
      error
    }
  }
`;

export const GetPatientVaccineReport = gql`
  query getPatientVaccineReport($patientId: ID!, $request: ReportRequest!) {
    getPatientVaccineReport(patientId: $patientId, request: $request) {
      pointer
      error
    }
  }
`;

export const GetHISAPatients = gql`
  query getHISAPatients($organizationId: ID!, $practiceId: ID!, $filter: HISAPatientFilter!) {
    getHISAPatients(organizationId: $organizationId, practiceId: $practiceId, filter: $filter) {
      hisaHorseId
      name
    }
  }
`;
