import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  BatchGroupUpsert,
  BatchSubGroupOfflinePatientOwnerUpsert,
  BatchSubGroupOfflinePatientUpsert,
  BatchSubGroupUpsert,
  Patient,
  PatientInvoiceOwnershipIssue,
  RelatedOwnershipEntryAll,
} from '../../../../graph/types';
import { translations } from '../../../../constants/translations';
import { Button, Collapse, DatePicker, Modal, Space } from 'antd';
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from '../../../../components/Notification/notificationUtil';
import {
  usePatientOfflineUpdate,
  useUpdatePatientRelevantForDoctorOfficeCard,
} from '../../../../hooks/ajax/patients/patientHooks';
import {
  useBatchGroupUpsertOfflineList,
  useBatchGroupUpsertOfflineUpdate,
} from '../../../../hooks/ajax/rapidBilling/rapidBillingHooks';
import { ownershipNumber, OwnershipPanelContent } from './OwnershipPanelContent';
import dayjs from 'dayjs';
import { AddOwnershipModalContent } from './AddOwnershipModalContent';
import { orderBy } from 'lodash';
import { buildPatientUpsert, buildPatientUpsertForDeletingOwnership } from './ownershipUpsertUtils';
import { SaveSpinnerAndNavigationWarning } from '../../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { AddOwnershipButton } from './AddOwnershipButton';
import './PatientOwnership.css';
import { RangeValue } from 'rc-picker/lib/interface';
import { OwnershipTagsForPanelHeader } from './OwnershipTags/OwnershipTagsForPanelHeader/OwnershipTagsForPanelHeader';
import { unsavedChangesMessageIfDisabled } from './ownershipUtils';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { PAGE_SIDER_WIDTH } from '../../../../constants/layout';
import { ownershipTitleRowPadding, rangePickerWidth } from './PatientOwnershipConstants';
import { displayOneDayEarlierIfSet, showDateIfSet } from '../../patientUtils';
import { ModalProps } from 'antd/lib/modal';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isUuid, useOffline } from '../../../../util/offline/offlineUtil';
import { ViewSubscriptionContext } from '../../../../components/ViewSubscription/store/state';
import ViewSubscriptionActions from '../../../../components/ViewSubscription/store/actions';
import { useRxCollection } from 'rxdb-hooks';
import { PatientsContext } from '../../store/state';
import { setCurrentlyEditingIndex } from '../../store/actions';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { RxUpsert } from '../../../../services/LocalDatabaseService/schemas/upsertSchema';
import { upsertDateFormat } from '../../../../constants/formats';

enum ModalType {
  Create = 'create',
  Prompt = 'prompt',
}
interface PatientOwnershipProps extends PropsWithChildren<unknown> {
  patient: Patient;
  onSuccess?: (patient: Patient) => void;
  getInvoiceIssues?: () => Promise<Maybe<PatientInvoiceOwnershipIssue[]> | undefined>;
  openSyncModal?: () => void;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const PatientOwnership: React.FC<PatientOwnershipProps> = ({
  patient,
  onSuccess,
  getInvoiceIssues,
  openSyncModal,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}: PatientOwnershipProps) => {
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const { windowWidth } = useWindowDimensions();
  const [updatePatient] = useUpdatePatientRelevantForDoctorOfficeCard();
  const { dispatch: dispatchViewPatient } = useContext(ViewSubscriptionContext);
  const {
    dispatch: dispatchPatient,
    state: { editingIndex: currentlyEditingIndex },
  } = useContext(PatientsContext);
  const patientCollection = useRxCollection<Patient>('patient');
  const [modalProps, setModalProps] = useState<ModalProps>({ visible: false });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { isOnline, canUseCollection, enabledAndOffline } = useOffline('patient');
  const offlineUpdate = usePatientOfflineUpdate(patient.id, patient.offline_id);
  const listOfflineRapidBillingBatches = useBatchGroupUpsertOfflineList();
  const batchGroupUpsertOfflineUpdate = useBatchGroupUpsertOfflineUpdate();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const isPatientUpdated = patient && (!isOnline || !isUuid(patient.id));

  const isOwnershipLoading = isOnline && !!patient?.ownership_all?.find((p) => isUuid(p.syndicate_id ?? ''));

  const [effectiveDateInModal, setEffectiveDateInModal] = useState<dayjs.Dayjs | null>(null);

  const ownershipsFromPropsOldToNew = orderBy(
    patient?.ownership_all ? patient.ownership_all : [],
    'effective_date',
    'asc'
  );
  const [ownershipsOldToNew, setOwnerships] = useState<RelatedOwnershipEntryAll[]>(ownershipsFromPropsOldToNew);

  const [effectiveDates, setEffectiveDates] = useState<(string | undefined | null)[]>(
    ownershipsOldToNew.map((ownership) => ownership.effective_date)
  );

  const [isCurrentlyEditing, setIsCurrentlyEditing] = useState(false);
  const lastOwnershipIndex = ownershipsOldToNew.length - 1;
  const [openPanelKeys, setOpenPanelKeys] = useState<string | string[]>([lastOwnershipIndex.toString()]);

  useEffect(() => {
    dispatchViewPatient(ViewSubscriptionActions.setEditing(modalProps.visible || isCurrentlyEditing));
  }, [dispatchViewPatient, isCurrentlyEditing, modalProps.visible]);

  useEffect(() => {
    if (canUseCollection && patient && !isCurrentlyEditing) {
      patientCollection?.findOne(patient.id)?.$?.subscribe((p: Patient | null) => {
        if (p) {
          setOwnerships(orderBy(p?.ownership_all ?? [], 'effective_date', 'asc'));
        }
      });
    }
  }, [canUseCollection, patientCollection, patient, isCurrentlyEditing]);

  useEffect(() => {
    if (patient && !isCurrentlyEditing) {
      dispatchPatient(setCurrentlyEditingIndex(-1));
    }
  }, [dispatchPatient, patient, isCurrentlyEditing]);

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(isCurrentlyEditing);
    }
  }, [handleIsEditing, isCurrentlyEditing]);

  useEffect(() => {
    setIsCurrentlyEditing(currentlyEditingIndex !== -1);
  }, [currentlyEditingIndex]);

  const updateEffectiveDate = (ownershipIndex: number, date: string | undefined | null) => {
    const updatedEffectiveDates = [...effectiveDates];
    updatedEffectiveDates[ownershipIndex] = date;
    setEffectiveDates(updatedEffectiveDates);
  };

  const updatePageWithResponse = (patient?: Patient | null) => {
    const sortedOwnershipsFromResponse = orderBy(patient?.ownership_all || [], 'effective_date', 'asc');
    setOwnerships(sortedOwnershipsFromResponse);
    setEffectiveDates(sortedOwnershipsFromResponse.map((ownership) => ownership.effective_date));
    dispatchPatient(setCurrentlyEditingIndex(-1));
  };

  function getPatientUpsert(ownership: RelatedOwnershipEntryAll, ownershipIndex: number, isDelete: boolean) {
    const ownershipToSave: RelatedOwnershipEntryAll = {
      ...ownership,
      effective_date: effectiveDates[ownershipIndex],
    };
    return isDelete
      ? buildPatientUpsertForDeletingOwnership(ownershipToSave, patient.id)
      : buildPatientUpsert(ownershipToSave, patient.id);
  }

  const updateRapidBillingBatchUpsert = async (batch: RxUpsert, patient: Patient) => {
    const upsert = batch.upsert as BatchGroupUpsert;
    const newUpsert: BatchGroupUpsert = {
      ...upsert,
      subBatch:
        upsert?.subBatch?.map(
          (subBatch: BatchSubGroupUpsert): BatchSubGroupUpsert => ({
            ...subBatch,
            offline_patient: subBatch.offline_patient?.map(
              (offlinePatient: BatchSubGroupOfflinePatientUpsert): BatchSubGroupOfflinePatientUpsert => {
                return patient.id === offlinePatient.offline_patient_id || patient.id === offlinePatient.patient_id
                  ? {
                      ...offlinePatient,
                      owners:
                        patient.ownership_current?.owner?.map(
                          (owner): BatchSubGroupOfflinePatientOwnerUpsert => ({
                            contact_id: owner.contact_id === owner.offline_contact_id ? undefined : owner.contact_id,
                            offline_contact_id:
                              owner.contact_id === owner.offline_contact_id ? owner.offline_contact_id : undefined,
                            primary: owner.primary ?? false,
                            percentage: owner.percentage ?? '0',
                          })
                        ) ?? [],
                    }
                  : offlinePatient;
              }
            ),
          })
        ) ?? [],
    };

    await batchGroupUpsertOfflineUpdate?.(batch, newUpsert);
  };

  const updateRapidBillingBatches = async (batches: RxUpsert[], patient: Patient) => {
    await batches.forEach(async (batch) => {
      await updateRapidBillingBatchUpsert(batch, patient);
    });
  };

  async function updateData(ownership: RelatedOwnershipEntryAll, ownershipIndex: number, isDelete: boolean) {
    setIsSaving(true);
    let updatedPatient: Patient | undefined | null;
    const editedIndex = currentlyEditingIndex;
    const patientUpsert = getPatientUpsert(ownership, ownershipIndex, isDelete);
    if (!enabledAndOffline) {
      dispatchPatient(setCurrentlyEditingIndex(-1));
      const { data } = await updatePatient({
        variables: {
          patient: patientUpsert,
          organizationId: patient?.organization_id ?? '',
        },
        onCompleted: (data) => {
          onPatientOwnershipUpdate(data?.upsertPatient);
          showSuccessMessage(translations.shared.saveSuccessMessage);
        },
      });
      updatedPatient = data?.upsertPatient;
    } else {
      updatedPatient = await offlineUpdate?.(patientUpsert);

      // Update batches
      const batches = await listOfflineRapidBillingBatches?.();
      const mappedBatches = batches?.map((b) => b?.toJSON() as RxUpsert) ?? [];
      if (updatedPatient) {
        await updateRapidBillingBatches(mappedBatches, updatedPatient);
      }

      onPatientOwnershipUpdate(updatedPatient);
    }

    if (!updatedPatient) {
      dispatchPatient(setCurrentlyEditingIndex(editedIndex));
      setIsSaving(false);
      return;
    }

    if (isOnline) {
      const invoiceIssues = await getInvoiceIssues?.();

      if (invoiceIssues?.length) {
        changeModalVisibility(ModalType.Prompt);
      }
    } else {
      showWarningMessage(translations.patientPage.ownership.offlineWarning);
    }

    setIsSaving(false);
  }

  const onPatientOwnershipUpdate = (patient: Patient | undefined | null) => {
    updatePageWithResponse(patient);
    if (patient) {
      onSuccess?.(patient);
    }
  };

  const handleUpsert = (ownership: RelatedOwnershipEntryAll, ownershipIndex: number) => {
    updateData(ownership, ownershipIndex, false);
  };

  const handleDelete = (ownership: RelatedOwnershipEntryAll, ownershipIndex: number) => {
    updateData(ownership, ownershipIndex, true);
  };

  const handleReset = () => {
    setOwnerships(ownershipsFromPropsOldToNew);
    setEffectiveDates(ownershipsFromPropsOldToNew.map((ownership) => ownership.effective_date));
    dispatchPatient(setCurrentlyEditingIndex(-1));
  };

  const displayDate = (ownershipIndex: number) => {
    const handleEffectiveDateChange = (dates: RangeValue<dayjs.Dayjs>) => {
      updateEffectiveDate(ownershipIndex, dates?.[0]?.format(upsertDateFormat));
    };

    const disabledDate = (date: dayjs.Dayjs) => {
      if (ownershipIndex < 1) {
        return false;
      }
      const previousEffectiveDate = ownershipsOldToNew[ownershipIndex - 1]?.effective_date;
      const earliestPossibleEffectiveDate = dayjs(previousEffectiveDate).add(1, 'day').format(upsertDateFormat);
      return date.isBefore(earliestPossibleEffectiveDate);
    };

    return (
      <div
        onClick={(event) => {
          if (!openPanelKeys.includes(ownershipIndex.toString())) {
            setOpenPanelKeys([...openPanelKeys, ownershipIndex.toString()]);
          }
          // prevent the panel from closing again
          event.stopPropagation();
        }}
        title={
          isPanelDisabled(ownershipIndex)
            ? translations.patientPage.ownership.tooltipToIndicateEditMode
            : translations.patientPage.ownership.panels.dateRangePickerTooltip
        }
      >
        {/* Unfortunately, this RangePicker gives a console warning about 'disabled' with empty 'value'.
        But this is a false positive, since having the right-hand side of the range-picker disabled and showing the
        placeholder there (if end_date is not set) is exactly what we want. */}
        <RangePicker
          disabled={[isPanelDisabled(ownershipIndex), true]}
          disabledDate={disabledDate}
          placeholder={[
            translations.patientPage.ownership.panels.effective_date_undefined,
            translations.patientPage.ownership.panels.end_date_today,
          ]}
          className='Ownership__EffectiveDateRangePicker'
          data-testid={'EffectiveDateRangePicker'}
          style={{ background: 'white' }}
          allowEmpty={[true, true]}
          allowClear={false}
          value={[
            showDateIfSet(effectiveDates[ownershipIndex]),
            displayOneDayEarlierIfSet(effectiveDates[ownershipIndex + 1]),
          ]}
          onChange={handleEffectiveDateChange}
          format={dateFormat}
        />
      </div>
    );
  };

  function isCurrentOwnership(ownershipIndex: number) {
    return ownershipIndex === lastOwnershipIndex;
  }

  const panelHeader = (ownershipIndex: number) => {
    const isInEditMode = currentlyEditingIndex === ownershipIndex;
    const panelHeaderTextWidth = document.getElementById('OwnershipPanelHeaderTitle')?.offsetWidth ?? 0;
    const maxWidthForTags =
      windowWidth - PAGE_SIDER_WIDTH - rangePickerWidth - ownershipTitleRowPadding - panelHeaderTextWidth;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Space style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <div id={'OwnershipPanelHeaderTitle'}>
            {translations.patientPage.ownership.panels.header(
              ownershipNumber(ownershipIndex),
              isCurrentOwnership(ownershipIndex)
            )}
            {isInEditMode ? (
              <i
                style={{
                  color: '#bfbfbf',
                  marginLeft: 8,
                }}
              >
                {translations.patientPage.ownership.changeIndicator}
              </i>
            ) : null}
          </div>
          <OwnershipTagsForPanelHeader
            owners={ownershipsOldToNew[ownershipIndex].owner || []}
            maxWidth={maxWidthForTags}
          />
        </Space>
        {displayDate(ownershipIndex)}
      </div>
    );
  };

  const changeModalVisibility = (type?: ModalType) => {
    setEffectiveDateInModal(dayjs());
    if (!type) {
      return setModalProps({
        visible: false,
      });
    }
    let title;
    switch (type) {
      case ModalType.Create:
        title = translations.patientPage.ownership.addOwnershipModal.title;
        break;
      case ModalType.Prompt:
        title = translations.patientPage.ownership.promptForSync.title;
        break;
      default:
        title = undefined;
    }

    return setModalProps({
      visible: true,
      title,
    });
  };

  const createOwnership = () => {
    const effectiveDate = effectiveDateInModal?.format(upsertDateFormat);
    const indexOfOwnershipBefore = ownershipsOldToNew.length - 1;

    const previousOwnership = ownershipsOldToNew[indexOfOwnershipBefore];
    const effectiveDateOfPreviousOwnership = previousOwnership?.effective_date;
    if (
      effectiveDateOfPreviousOwnership &&
      effectiveDateInModal &&
      isBefore(effectiveDateInModal, dayjs(effectiveDateOfPreviousOwnership))
    ) {
      showErrorMessage(
        translations.patientPage.ownership.addOwnershipModal.effectiveDateBeforePreviousOwnershipErrorMessage
      );
      return;
    }

    const newCurrentOwnership: RelatedOwnershipEntryAll = {
      owner: previousOwnership ? previousOwnership.owner : [],
      effective_date: effectiveDate,
    };

    const newOwnerships = [...ownershipsOldToNew, newCurrentOwnership];
    if (previousOwnership) {
      newOwnerships[indexOfOwnershipBefore] = previousOwnership;
    }

    setOwnerships(newOwnerships);
    setEffectiveDates([...effectiveDates, effectiveDate]);

    changeModalVisibility();

    setOpenPanelKeys([...openPanelKeys, (newOwnerships.length - 1).toString()]);
    setEffectiveDateInModal(null);

    dispatchPatient(setCurrentlyEditingIndex(indexOfOwnershipBefore + 1));
  };

  function isBefore(date1: dayjs.Dayjs, date2: dayjs.Dayjs) {
    return date1 <= date2;
  }

  const modalFooterCancelButton = [
    <Button key={'cancel'} onClick={() => changeModalVisibility()}>
      {translations.patientPage.ownership.addOwnershipModal.cancelButton}
    </Button>,
  ];

  const ownershipModalFooter = [
    ...modalFooterCancelButton,
    <Button
      data-testid={'createOwnershipButton'}
      key={'create'}
      onClick={createOwnership}
      type={'primary'}
      disabled={effectiveDateInModal === null}
    >
      {translations.patientPage.ownership.addOwnershipModal.createButton}
    </Button>,
  ];

  useEffect(() => {
    if (modalProps.title === translations.patientPage.ownership.addOwnershipModal.title) {
      setModalProps({ ...modalProps, footer: ownershipModalFooter });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveDateInModal]);

  const promptModalFooter = [
    ...modalFooterCancelButton,
    <Button
      data-testid={'OK button'}
      key={'OK'}
      onClick={() => {
        changeModalVisibility();
        openSyncModal?.();
      }}
      type={'primary'}
    >
      {translations.shared.okButtonText}
    </Button>,
  ];

  const getContentAndFooter = () => {
    let footer;
    let content;
    switch (modalProps.title) {
      case translations.patientPage.ownership.addOwnershipModal.title:
        footer = ownershipModalFooter;
        content = (
          <AddOwnershipModalContent
            effectiveDate={effectiveDateInModal}
            setEffectiveDate={setEffectiveDateInModal}
            dateFormat={dateFormat}
          />
        );
        break;
      case translations.patientPage.ownership.promptForSync.title:
        footer = promptModalFooter;
        content = <span>{translations.patientPage.ownership.promptForSync.description}</span>;
        break;
      default:
        content = null;
        footer = null;
    }
    return { content, footer };
  };

  const handleCollapseChange = (panelKeysToOpen: string | string[]) => {
    setOpenPanelKeys(panelKeysToOpen);
  };

  const handleEditClick = (ownershipIndex: number) => {
    dispatchPatient(setCurrentlyEditingIndex(ownershipIndex));
  };

  const isPanelDisabled = (ownershipIndex: number) => {
    return currentlyEditingIndex !== ownershipIndex;
  };

  const { content, footer } = getContentAndFooter();

  return (
    <SaveSpinnerAndNavigationWarning
      showNavigationWarning={isCurrentlyEditing}
      savingMessage={!isPatientUpdated ? translations.shared.loading : undefined}
      isSaving={isSaving || !isPatientUpdated || isOwnershipLoading}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.patientPage.entity)}
    >
      <AddOwnershipButton
        title={unsavedChangesMessageIfDisabled(isCurrentlyEditing)}
        changeModalVisibility={() => changeModalVisibility(ModalType.Create)}
        disabled={isCurrentlyEditing}
      />
      <Collapse activeKey={openPanelKeys} onChange={handleCollapseChange}>
        {ownershipsOldToNew
          .map((ownershipInState, ownershipIndex) => (
            <Panel key={ownershipIndex} header={panelHeader(ownershipIndex)}>
              <OwnershipPanelContent
                key={ownershipIndex}
                originalOwnership={ownershipInState}
                isCurrentOwnership={isCurrentOwnership(ownershipIndex)}
                ownershipIndex={ownershipIndex}
                upsertOwnershipCallback={handleUpsert}
                deleteOwnershipCallback={handleDelete}
                handleEditClick={handleEditClick}
                handleResetCallback={handleReset}
                currentlyEditingIndex={currentlyEditingIndex}
                showContacts={ownershipsOldToNew.length > 0}
                setShouldResetOnTabChange={setShouldResetOnTabChange}
                shouldResetOnTabChange={shouldResetOnTabChange}
              />
            </Panel>
          ))
          .reverse()}
      </Collapse>
      <Modal
        title={modalProps.title}
        open={modalProps.visible}
        onCancel={() => changeModalVisibility()}
        footer={footer}
        maskClosable={false}
        width={modalProps.width}
      >
        <>{content}</>
      </Modal>
    </SaveSpinnerAndNavigationWarning>
  );
};
