import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { translations } from '../../constants/translations';

interface AlertModalProps {
  onOk?: () => void;
  okText?: string;
  message?: string;
  title?: string;
}

const AlertModal: React.FC<AlertModalProps> = ({ onOk, okText, message, title }) => {
  const handleOnOk = () => {
    onOk?.();
    ReactDOM.unmountComponentAtNode(document.getElementById('alert-container')!);
  };
  const handleOnCancel = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('alert-container')!);
  };
  return (
    <Modal
      title={title}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      closable={false}
      open
      okText={okText ?? translations.shared.okButtonText}
      cancelText={translations.shared.cancelButtonText}
    >
      {message ?? null}
    </Modal>
  );
};

export default AlertModal;
