import { Country } from 'react-phone-number-input';
import { CountryNameKey } from '../constants/countries';

export type LocaleType = {
  locale: string;
  currency: string;
  momentLocale: string;
  dateFormat: string;
  phoneFormat: Country;
  hidePostalZip: boolean;
  hideCardConnectSettings: boolean;
};

export const LOCALES: Record<CountryNameKey, LocaleType> = {
  [CountryNameKey.AFG]: {
    locale: 'en-US',
    currency: 'AFN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ALA]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AX',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ALB]: {
    locale: 'en-US',
    currency: 'ALL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DZA]: {
    locale: 'en-US',
    currency: 'DZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ASM]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AND]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AD',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AGO]: {
    locale: 'en-US',
    currency: 'AOA',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AIA]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AI',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ATA]: {
    locale: 'en-US',
    currency: 'AAD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GB',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ATG]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AG',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ARG]: {
    locale: 'en-US',
    currency: 'ARS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ARM]: {
    locale: 'en-US',
    currency: 'AMD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ABW]: {
    locale: 'en-US',
    currency: 'AWG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AW',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AUS]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AUT]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.AZE]: {
    locale: 'en-US',
    currency: 'AZN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BHR]: {
    locale: 'en-US',
    currency: 'BHD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BGD]: {
    locale: 'en-US',
    currency: 'BDT',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BD',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BRB]: {
    locale: 'en-US',
    currency: 'BBD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BB',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BLR]: {
    locale: 'en-US',
    currency: 'BYN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BEL]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BLZ]: {
    locale: 'en-US',
    currency: 'BZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BZ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BEN]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BJ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BMU]: {
    locale: 'en-US',
    currency: 'BMD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BTN]: {
    locale: 'en-US',
    currency: 'BTN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BOL]: {
    locale: 'en-US',
    currency: 'BOB',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BES]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BQ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BIH]: {
    locale: 'en-US',
    currency: 'BAM',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BWA]: {
    locale: 'en-US',
    currency: 'BWP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BW',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BVT]: {
    locale: 'en-US',
    currency: 'NOK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BRA]: {
    locale: 'en-US',
    currency: 'BRL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IOT]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BRN]: {
    locale: 'en-US',
    currency: 'BND',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BGR]: {
    locale: 'en-US',
    currency: 'BGN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BFA]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BF',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BDI]: {
    locale: 'en-US',
    currency: 'BIF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BI',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KHM]: {
    locale: 'en-US',
    currency: 'KHR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KH',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CMR]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CM',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.Canada]: {
    locale: 'en-CA',
    currency: 'CAD',
    momentLocale: 'en-ca',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CA',
    hidePostalZip: false,
    hideCardConnectSettings: false,
  },
  [CountryNameKey.CPV]: {
    locale: 'en-US',
    currency: 'CVE',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CV',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CYM]: {
    locale: 'en-US',
    currency: 'KYD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CAF]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CF',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TCD]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TD',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CHL]: {
    locale: 'en-US',
    currency: 'CLP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CHN]: {
    locale: 'en-US',
    currency: 'CNY',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CXR]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CX',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CCK]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.COL]: {
    locale: 'en-US',
    currency: 'COP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.COM]: {
    locale: 'en-US',
    currency: 'KMF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KM',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.COG]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CG',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.COK]: {
    locale: 'en-US',
    currency: 'NZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CK',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CRI]: {
    locale: 'en-US',
    currency: 'CRC',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CIV]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CI',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HRV]: {
    locale: 'en-US',
    currency: 'HRK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'HR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CUB]: {
    locale: 'en-US',
    currency: 'CUP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CUW]: {
    locale: 'en-US',
    currency: 'ANG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CW',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CYP]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CZE]: {
    locale: 'en-US',
    currency: 'CZK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.COD]: {
    locale: 'en-US',
    currency: 'CDF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CD',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DNK]: {
    locale: 'en-US',
    currency: 'DKK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DJI]: {
    locale: 'en-US',
    currency: 'DJF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DJ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DMA]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DM',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DOM]: {
    locale: 'en-US',
    currency: 'DOP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TLS]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TL',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ECU]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'EC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.EGY]: {
    locale: 'en-US',
    currency: 'EGP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'EG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SLV]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SV',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GNQ]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GQ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ERI]: {
    locale: 'en-US',
    currency: 'ERN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ER',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.EST]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'EE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ETH]: {
    locale: 'en-US',
    currency: 'ETB',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ET',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FLK]: {
    locale: 'en-US',
    currency: 'FKP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FK',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FRO]: {
    locale: 'en-US',
    currency: 'DKK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FJI]: {
    locale: 'en-US',
    currency: 'FJD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FJ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FIN]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FI',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FRA]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GUF]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PYF]: {
    locale: 'en-US',
    currency: 'XPF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ATF]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FR',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GAB]: {
    locale: 'en-US',
    currency: 'XAF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GA',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GMB]: {
    locale: 'en-US',
    currency: 'GMD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GM',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GEO]: {
    locale: 'en-US',
    currency: 'GEL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.DEU]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'DE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GHA]: {
    locale: 'en-US',
    currency: 'GHS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GIB]: {
    locale: 'en-US',
    currency: 'GIP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GI',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GRC]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GRL]: {
    locale: 'en-US',
    currency: 'DKK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GRD]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GD',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GLP]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GP',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GUM]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GTM]: {
    locale: 'en-US',
    currency: 'GTQ',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GGY]: {
    locale: 'en-US',
    currency: 'GBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GIN]: {
    locale: 'en-US',
    currency: 'GNF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GNB]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GW',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GUY]: {
    locale: 'en-US',
    currency: 'GYD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GY',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HTI]: {
    locale: 'en-US',
    currency: 'HTG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'HT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HMD]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AU',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HND]: {
    locale: 'en-US',
    currency: 'HNL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'HN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HKG]: {
    locale: 'en-US',
    currency: 'HKD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'HK',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.HUN]: {
    locale: 'en-US',
    currency: 'HUF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'HU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ISL]: {
    locale: 'en-US',
    currency: 'ISK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IND]: {
    locale: 'en-US',
    currency: 'INR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IDN]: {
    locale: 'en-US',
    currency: 'IDR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ID',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IRN]: {
    locale: 'en-US',
    currency: 'IRR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IRQ]: {
    locale: 'en-US',
    currency: 'IQD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IQ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IRL]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ISR]: {
    locale: 'en-US',
    currency: 'ILS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ITA]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.JAM]: {
    locale: 'en-US',
    currency: 'JMD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'JM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.JPN]: {
    locale: 'en-US',
    currency: 'JPY',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'JP',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.JEY]: {
    locale: 'en-US',
    currency: 'GBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'JE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.JOR]: {
    locale: 'en-US',
    currency: 'JOD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'JO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KAZ]: {
    locale: 'en-US',
    currency: 'KZT',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KEN]: {
    locale: 'en-US',
    currency: 'KES',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KIR]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KI',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.XKX]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'XK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KWT]: {
    locale: 'en-US',
    currency: 'KWD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KW',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KGZ]: {
    locale: 'en-US',
    currency: 'KGS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LAO]: {
    locale: 'en-US',
    currency: 'LAK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LVA]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LV',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LBN]: {
    locale: 'en-US',
    currency: 'LBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LB',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LSO]: {
    locale: 'en-US',
    currency: 'LSL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LBR]: {
    locale: 'en-US',
    currency: 'LRD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LBY]: {
    locale: 'en-US',
    currency: 'LYD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LY',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LIE]: {
    locale: 'en-US',
    currency: 'CHF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LI',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LTU]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LUX]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MAC]: {
    locale: 'en-US',
    currency: 'MOP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MKD]: {
    locale: 'en-US',
    currency: 'MKD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MDG]: {
    locale: 'en-US',
    currency: 'MGA',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MWI]: {
    locale: 'en-US',
    currency: 'MWK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MW',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MYS]: {
    locale: 'en-US',
    currency: 'MYR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MDV]: {
    locale: 'en-US',
    currency: 'MVR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MV',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MLI]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ML',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MLT]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.IMN]: {
    locale: 'en-US',
    currency: 'GBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'IM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MHL]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MTQ]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MQ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MRT]: {
    locale: 'en-US',
    currency: 'MRO',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MR',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MUS]: {
    locale: 'en-US',
    currency: 'MUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MYT]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'YT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MEX]: {
    locale: 'en-US',
    currency: 'MXN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MX',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.FSM]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'FM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MDA]: {
    locale: 'en-US',
    currency: 'MDL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MD',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MCO]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MNG]: {
    locale: 'en-US',
    currency: 'MNT',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MNE]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ME',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MSR]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MAR]: {
    locale: 'en-US',
    currency: 'MAD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MOZ]: {
    locale: 'en-US',
    currency: 'MZN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MMR]: {
    locale: 'en-US',
    currency: 'MMK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NAM]: {
    locale: 'en-US',
    currency: 'NAD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NRU]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NR',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NPL]: {
    locale: 'en-US',
    currency: 'NPR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NP',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NLD]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NCL]: {
    locale: 'en-US',
    currency: 'XPF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NZL]: {
    locale: 'en-US',
    currency: 'NZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NIC]: {
    locale: 'en-US',
    currency: 'NIO',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NI',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NER]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NGA]: {
    locale: 'en-US',
    currency: 'NGN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NIU]: {
    locale: 'en-US',
    currency: 'NZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NU',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NFK]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PRK]: {
    locale: 'en-US',
    currency: 'KPW',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KP',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MNP]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MP',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.NOR]: {
    locale: 'en-US',
    currency: 'NOK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.OMN]: {
    locale: 'en-US',
    currency: 'OMR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'OM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PAK]: {
    locale: 'en-US',
    currency: 'PKR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PLW]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PW',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PSE]: {
    locale: 'en-US',
    currency: 'ILS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PAN]: {
    locale: 'en-US',
    currency: 'PAB',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PNG]: {
    locale: 'en-US',
    currency: 'PGK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PRY]: {
    locale: 'en-US',
    currency: 'PYG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PER]: {
    locale: 'en-US',
    currency: 'PEN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PHL]: {
    locale: 'en-US',
    currency: 'PHP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PCN]: {
    locale: 'en-US',
    currency: 'NZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'NZ',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.POL]: {
    locale: 'en-US',
    currency: 'PLN',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PRT]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.PRI]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.QAT]: {
    locale: 'en-US',
    currency: 'QAR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'QA',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.REU]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'RE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ROU]: {
    locale: 'en-US',
    currency: 'RON',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'RO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.RUS]: {
    locale: 'en-US',
    currency: 'RUB',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'RU',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.RWA]: {
    locale: 'en-US',
    currency: 'RWF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'RW',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SHN]: {
    locale: 'en-US',
    currency: 'SHP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KNA]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LCA]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SPM]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'PM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VCT]: {
    locale: 'en-US',
    currency: 'XCD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VC',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BLM]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BL',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.MAF]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'MF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.WSM]: {
    locale: 'en-US',
    currency: 'WST',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'WS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SMR]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.STP]: {
    locale: 'en-US',
    currency: 'STD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ST',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SAU]: {
    locale: 'en-US',
    currency: 'SAR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SEN]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SRB]: {
    locale: 'en-US',
    currency: 'RSD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'RS',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SYC]: {
    locale: 'en-US',
    currency: 'SCR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SC',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SLE]: {
    locale: 'en-US',
    currency: 'SLL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SL',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SGP]: {
    locale: 'en-US',
    currency: 'SGD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SXM]: {
    locale: 'en-US',
    currency: 'ANG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SX',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SVK]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SVN]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SI',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SLB]: {
    locale: 'en-US',
    currency: 'SBD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SB',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SOM]: {
    locale: 'en-US',
    currency: 'SOS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SO',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ZAF]: {
    locale: 'en-ZA',
    currency: 'ZAR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ZA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SGS]: {
    locale: 'en-US',
    currency: 'GBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GB',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.KOR]: {
    locale: 'en-US',
    currency: 'KRW',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'KR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SSD]: {
    locale: 'en-US',
    currency: 'SSP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SS',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ESP]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ES',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.LKA]: {
    locale: 'en-US',
    currency: 'LKR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'LK',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SDN]: {
    locale: 'en-US',
    currency: 'SDG',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SD',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SUR]: {
    locale: 'en-US',
    currency: 'SRD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SR',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SJM]: {
    locale: 'en-US',
    currency: 'NOK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SJ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SWZ]: {
    locale: 'en-US',
    currency: 'SZL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SWE]: {
    locale: 'en-US',
    currency: 'SEK',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.CHE]: {
    locale: 'en-US',
    currency: 'CHF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'CH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.SYR]: {
    locale: 'en-US',
    currency: 'SYP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'SY',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TWN]: {
    locale: 'en-US',
    currency: 'TWD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TW',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TJK]: {
    locale: 'en-US',
    currency: 'TJS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TJ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TZA]: {
    locale: 'en-US',
    currency: 'TZS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.THA]: {
    locale: 'en-US',
    currency: 'THB',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TH',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.BHS]: {
    locale: 'en-US',
    currency: 'BSD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'BS',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TGO]: {
    locale: 'en-US',
    currency: 'XOF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TG',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TKL]: {
    locale: 'en-US',
    currency: 'NZD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TK',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TON]: {
    locale: 'en-US',
    currency: 'TOP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TO',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TTO]: {
    locale: 'en-US',
    currency: 'TTD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TT',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TUN]: {
    locale: 'en-US',
    currency: 'TND',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TUR]: {
    locale: 'en-US',
    currency: 'TRY',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TR',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TKM]: {
    locale: 'en-US',
    currency: 'TMT',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TCA]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TC',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.TUV]: {
    locale: 'en-US',
    currency: 'AUD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'TV',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.UGA]: {
    locale: 'en-US',
    currency: 'UGX',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'UG',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.UKR]: {
    locale: 'en-US',
    currency: 'UAH',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'UA',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.UAE]: {
    locale: 'ar-AE',
    currency: 'AED',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'AE',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.GBR]: {
    locale: 'en-US',
    currency: 'GBP',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'GB',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.USA]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'MM-DD-YYYY',
    phoneFormat: 'US',
    hidePostalZip: false,
    hideCardConnectSettings: false,
  },
  [CountryNameKey.UMI]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'US',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.URY]: {
    locale: 'en-US',
    currency: 'UYU',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'UY',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.UZB]: {
    locale: 'en-US',
    currency: 'UZS',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'UZ',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VUT]: {
    locale: 'en-US',
    currency: 'VUV',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VU',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VAT]: {
    locale: 'en-US',
    currency: 'EUR',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VA',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VEN]: {
    locale: 'en-US',
    currency: 'VEF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VE',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VNM]: {
    locale: 'en-US',
    currency: 'VND',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VN',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VGB]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VG',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.VIR]: {
    locale: 'en-US',
    currency: 'USD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'VI',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.WLF]: {
    locale: 'en-US',
    currency: 'XPF',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'WF',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ESH]: {
    locale: 'en-US',
    currency: 'MAD',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'EH',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.YEM]: {
    locale: 'en-US',
    currency: 'YER',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'YE',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ZMB]: {
    locale: 'en-US',
    currency: 'ZMW',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ZM',
    hidePostalZip: false,
    hideCardConnectSettings: true,
  },
  [CountryNameKey.ZWE]: {
    locale: 'en-US',
    currency: 'ZWL',
    momentLocale: 'en',
    dateFormat: 'YYYY-MM-DD',
    phoneFormat: 'ZW',
    hidePostalZip: true,
    hideCardConnectSettings: true,
  },
};

export const DEFAULT_LOCALE = CountryNameKey.Canada;
