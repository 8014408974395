import { ChangeServiceRendered, ServicesRenderedAction, ServicesRenderedActionType } from './actions';
import { Service, ServiceRendered } from '../../../graph/types';

export interface SavingState {
  isSaving: boolean;
  customSavingMessage?: string;
}

export interface ServicesRenderedState {
  servicesRendered: ServiceRendered[];
  unchargedServicesRendered: ServiceRendered[];
  editingServiceRenderedId?: string;
  clickedColumn?: keyof ServiceRendered;
  savingState: SavingState;
  contactId?: string;
  deceasePatientInfo: DeceasePatientsInfo;
  cashedServices: Service[];
  conditionTreated?: string;
  editingServiceRendered?: ServiceRendered;
}

export interface DeceasePatientsInfo {
  patientId: string;
  deceaseDate: string;
}

export const initialState: ServicesRenderedState = {
  servicesRendered: [],
  unchargedServicesRendered: [],
  savingState: { isSaving: false },
  deceasePatientInfo: {} as DeceasePatientsInfo,
  cashedServices: [],
  editingServiceRendered: undefined,
};

function updateServiceRendered(action: ChangeServiceRendered, service: ServiceRendered) {
  if (action.fieldName === 'total' && (action.updatedValue || action.updatedValue === 0)) {
    const updatedUnitPrice = Number(service.quantity) === 0 ? 0 : action.updatedValue / Number(service.quantity ?? 1);
    return { ...service, total: action.updatedValue?.toString(), unit_price: updatedUnitPrice.toString() };
  } else if (action.fieldName === 'quantity' && service.unit_price) {
    const updatedTotal = (action.updatedValue ?? 1) * Number(service.unit_price);
    return { ...service, quantity: action.updatedValue?.toString(), total: updatedTotal.toString() };
  }
  return { ...service, [action.fieldName]: action.updatedValue?.toString() };
}

export const servicesRenderedReducer = (
  state: ServicesRenderedState,
  action: ServicesRenderedAction
): ServicesRenderedState => {
  switch (action.type) {
    case ServicesRenderedActionType.SetServicesRendered:
      return {
        ...state,
        servicesRendered: action.servicesRendered || [],
      };
    case ServicesRenderedActionType.SetUnchargedServicesRendered:
      return {
        ...state,
        unchargedServicesRendered: action.servicesRendered || [],
      };
    case ServicesRenderedActionType.ChangeServiceRendered:
      return {
        ...state,
        servicesRendered: state.servicesRendered.map((service) => {
          if (service.id === action.serviceId) {
            return updateServiceRendered(action, service);
          }
          return service;
        }),
        unchargedServicesRendered: state.unchargedServicesRendered.map((service) => {
          if (service.id === action.serviceId) {
            return updateServiceRendered(action, service);
          }
          return service;
        }),
      };
    case ServicesRenderedActionType.SetClickedColumn: {
      return { ...state, clickedColumn: action.clickedColumn };
    }
    case ServicesRenderedActionType.SetEditingServiceRenderedId: {
      return { ...state, editingServiceRenderedId: action.editingServiceRenderedId };
    }
    case ServicesRenderedActionType.SetIsSaving: {
      return { ...state, savingState: { isSaving: true, customSavingMessage: action.savingMessage } };
    }
    case ServicesRenderedActionType.ClearIsSaving: {
      return { ...state, savingState: { isSaving: false } };
    }
    case ServicesRenderedActionType.SetContactId: {
      return { ...state, contactId: action.contactId };
    }
    case ServicesRenderedActionType.AddPatientDeceaseInfo: {
      return { ...state, deceasePatientInfo: action.patientDeceaseInfo };
    }
    case ServicesRenderedActionType.SetCashedServices:
      return {
        ...state,
        cashedServices: action.services || [],
      };
    case ServicesRenderedActionType.SetConditionTreated:
      return {
        ...state,
        conditionTreated: action.conditionTreated,
      };
    case ServicesRenderedActionType.SetEditingServiceRendered:
      return {
        ...state,
        editingServiceRendered: action.editingServiceRendered,
      };
  }
  return state;
};
