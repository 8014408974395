import {
  BatchGroupOptions,
  BatchGroupUpsert,
  BatchSubGroupOfflinePatientOwnerUpsert,
  BatchSubGroupOfflinePatientUpsert,
  BatchSubGroupUpsert,
  Caregiver,
  Note,
  Patient,
  PrescriptionFlattened,
  Reminder,
  Service,
  ServiceRendered,
  ServiceRenderedGroupRecordUpsert,
  ServiceRenderedUpsert,
} from '../../graph/types';
import { BatchGroupData, HisaValuesByServiceAndPatient, PatientsByServiceRenderedId } from './RapidBilling';
import { getUpsertForNewServiceRenderedForBatchCreation } from '../../components/ServiceRendered/serviceRenderedUpsertUtil';
import { AddServiceFormValues } from '../../components/ServiceRendered/AddServiceForm/AddServiceForm';
import { translations } from '../../constants/translations';
import { VaccineModalFormValues } from '../../components/VaccineModalContent/VaccineModalContent';
import { ControlledDrugModalFormValue } from '../../components/ControlledDrugContent/ControlledDrugContent';
import { ReminderFormValues } from '../../components/ReminderFormFields/ReminderFormFields';
import { PrescriptionModalFormValues } from '../../components/PrescriptionModalContent/PrescriptionModalContent.types';
import { PrescriptionType } from '../../constants/prescriptions';
import { dummyNoteIdForRapidBilling } from '../../hooks/ajax/note/noteHooks';
import { RxPatient } from '../../services/LocalDatabaseService/schemas/patientSchema';
import dayjs from 'dayjs';
import {
  buildHisaFormValuesFromUpsert,
  HisaFormField,
  HisaFormFieldsValues,
  HisaRequirementsTypeId,
} from '../../constants/hisaRequirements';
import { Store } from 'antd/es/form/interface';
import { RapidBillingServiceRendered } from './RapidBillingServices/RapidBillingServices';
import { getHisaValuesAndText } from '../Invoices/ViewInvoice/viewInvoiceUtil';
import _ from 'lodash';

let counter = 0;

export const createNewServiceForListView = (
  formValues: AddServiceFormValues,
  service: Service,
  doctor: Caregiver,
  businessCenterId: string,
  locationId: string,
  dateFormat: string,
  modalFormValues?: VaccineModalFormValues & ControlledDrugModalFormValue,
  reminderFormValues?: ReminderFormValues,
  prescriptionFormValues?: PrescriptionModalFormValues,
  quantity?: string,
  roaId?: string,
  roaOther?: string,
  reason?: string,
  withdrawal_period?: number,
  hide_discount?: boolean,
  bundleProperties?: ServiceRenderedGroupRecordUpsert
): ServiceRendered => {
  const dummyId = `dummy-id-${counter}`;
  counter++;
  const newQuantity = Number(quantity ?? (service.quantity_default || 1)) || 1;
  const total = (newQuantity * Number(service.price)).toString();
  return {
    id: dummyId,
    date: formValues.date.format(dateFormat),
    doctor_id: doctor.id,
    doctor_name: doctor.name,
    name: service.name,
    quantity: newQuantity.toString(),
    unit_name: service.unit_name,
    unit_price: service.price,
    total,
    business_center_id: businessCenterId,
    location_id: locationId,
    service_id: service.id,
    list_unit_price: service.price,
    list_total: total,
    vaccine: service.vaccine,
    controlled: service.controlled,
    manufacturer_name: modalFormValues?.manufacturer,
    lot_number: modalFormValues?.lotNumber,
    serial_number: modalFormValues?.serialNumber,
    expiry_date: modalFormValues?.expiryDate?.format(dateFormat),
    bottle_number: modalFormValues?.bottle_number,
    roa_id: roaId,
    roa_other: roaOther,
    reason,
    withdrawal_period,
    hide_discount,
    ...bundleProperties,
    reminder:
      reminderFormValues?.reminderName && reminderFormValues?.date
        ? [
            {
              name: reminderFormValues?.reminderName,
              date: reminderFormValues?.date?.format(dateFormat),
              note: reminderFormValues?.note,
            },
          ]
        : null,
    prescriptions:
      prescriptionFormValues?.prescribedDate &&
      prescriptionFormValues?.prescriptionExpiryDate &&
      prescriptionFormValues?.itemQuantity &&
      prescriptionFormValues?.practiceId &&
      prescriptionFormValues?.prescribedUserId
        ? [
            {
              contact_id: dummyId,
              patient_id: dummyId,
              prescribed_user_id: prescriptionFormValues?.prescribedUserId,
              prescribed_date: prescriptionFormValues?.prescribedDate.format(dateFormat),
              prescription_expiry_date: prescriptionFormValues?.prescriptionExpiryDate.format(dateFormat),
              product_expiry_date: prescriptionFormValues?.productExpiryDate?.format(dateFormat),
              refills: prescriptionFormValues?.refills,
              item_description: prescriptionFormValues?.itemDescription,
              item_quantity: prescriptionFormValues?.itemQuantity,
              item_unit: prescriptionFormValues?.itemUnit,
              instructions: prescriptionFormValues?.instructions,
              practice_id: prescriptionFormValues?.practiceId,
              fill_externally: false,
              created_type_id: PrescriptionType.Patient,
            },
          ]
        : undefined,
  } as ServiceRendered;
};

export const validateRapidBatch = (
  servicesRendered: ServiceRendered[],
  patientsByServiceRenderedId: PatientsByServiceRenderedId
): { error?: string; erroneousServiceIds?: string[] } => {
  const servicesWithMissingPatients = servicesRendered.filter((servicesRendered) => {
    const patients = patientsByServiceRenderedId[servicesRendered.id];
    return !(patients && patients.length > 0);
  });

  if (servicesWithMissingPatients.length === 0) {
    return {};
  }

  const serviceNames = servicesWithMissingPatients.map((service) => service.name).join(', ');
  const errorMessage = translations.rapidBilling.getSelectPatientsErrorMessage(serviceNames);
  const erroneousServiceIds = servicesWithMissingPatients.map((service) => service.id);
  return { error: errorMessage, erroneousServiceIds };
};

export const mapToRapidBillingUpsert = (
  servicesRendered: ServiceRendered[],
  patientsByServiceRenderedId: PatientsByServiceRenderedId,
  batchGroupOptions: BatchGroupOptions,
  practiceId: string,
  dateFormat: string,
  isOnline = true,
  hisaValues?: HisaValuesByServiceAndPatient
): BatchGroupUpsert => {
  let updatedServicesRendered = servicesRendered;
  let updatedPatientsByServiceRenderedId = patientsByServiceRenderedId;

  if (hisaValues && Object.keys(hisaValues).length > 0) {
    const { requiredPatientsByServiceRenderedIds, updatedServicesRendered: updatedServicesRenderedState } =
      getSRsAndPatientsForRequirements(hisaValues, servicesRendered, patientsByServiceRenderedId);
    updatedServicesRendered = updatedServicesRenderedState;
    updatedPatientsByServiceRenderedId = requiredPatientsByServiceRenderedIds;
  }

  const batchSubGroupUpserts: BatchSubGroupUpsert[] = updatedServicesRendered.map((serviceRendered, i) => {
    serviceRendered.sort_order = -((i + 1) * 10000);
    const serviceRenderedUpsert = getUpsertForNewServiceRenderedForBatchCreation(serviceRendered, dateFormat);

    const patientIds = isOnline
      ? updatedPatientsByServiceRenderedId[serviceRendered.id].map((patient) => patient.id)
      : [];
    const offlinePatients: BatchSubGroupOfflinePatientUpsert[] = isOnline
      ? []
      : updatedPatientsByServiceRenderedId[serviceRendered.id]?.map((patient) => ({
          patient_id: patient.id === patient.offline_id ? undefined : patient.id,
          offline_patient_id: patient.id === patient.offline_id ? patient.offline_id : undefined,
          owners:
            patient.ownership_current?.owner?.map(
              (owner): BatchSubGroupOfflinePatientOwnerUpsert => ({
                contact_id: owner.contact_id === owner.offline_contact_id ? undefined : owner.contact_id,
                offline_contact_id:
                  owner.contact_id === owner.offline_contact_id ? owner.offline_contact_id : undefined,
                primary: owner.primary ?? false,
                percentage: owner.percentage ?? '0',
              })
            ) ?? [],
        }));
    const batchSubGroupUpsert: BatchSubGroupUpsert = {
      item: [serviceRenderedUpsert],
      patient_id: patientIds,
      offline_patient: offlinePatients,
    };
    return batchSubGroupUpsert;
  });

  const combinedBatchSubGroupUpserts = batchSubGroupUpserts.reduce((acc: BatchSubGroupUpsert[], current) => {
    const patientIdProperty = isOnline ? 'patient_id' : 'offline_patient';
    const existingIndex = acc.findIndex(
      (item) => JSON.stringify(item[patientIdProperty]) === JSON.stringify(current[patientIdProperty])
    );

    if (existingIndex > -1) {
      const existingItem = acc[existingIndex];
      existingItem.item = [...existingItem.item, ...current.item];
    } else {
      acc.push(current);
    }

    return acc;
  }, []);

  return { subBatch: combinedBatchSubGroupUpserts, options: batchGroupOptions, practice_id: practiceId };
};

export const buildServiceRendered = (
  upsert: ServiceRenderedUpsert,
  index: number,
  services: Service[] | undefined,
  doctors: Caregiver[],
  dateFormat: string
): ServiceRendered => {
  const serviceRenderedId = `dummy-id-${index}`;
  const service = services?.find((s) => s.id === upsert.financialRecord?.service_id);
  const doctorName = doctors.find((d) => d.id === upsert.financialRecord?.doctor_id)?.name;

  const serviceRendered = {
    id: serviceRenderedId,
    name: upsert.record?.name,
    unit_name: upsert.record?.unit_name,
    manufacturer_name: upsert.record?.manufacturer_name,
    lot_number: upsert.record?.lot_number,
    serial_number: upsert.record?.serial_number,
    expiry_date: upsert.record?.expiry_date,
    bottle_number: upsert.record?.bottle_number,
    roa_id: upsert.record?.roa_id,
    roa_other: upsert.record?.roa_other,
    reason: upsert.record?.reason,
    withdrawal_period: upsert.record?.withdrawal_period,
    sort_order: upsert.orderRecord?.sort_order,
    service_id: upsert.financialRecord?.service_id,
    doctor_id: upsert.financialRecord?.doctor_id,
    doctor_name: doctorName ?? '',
    date: dayjs(upsert.financialRecord?.date).format(dateFormat),
    business_center_id: upsert.financialRecord?.business_center_id,
    location_id: upsert.financialRecord?.location_id,
    quantity: upsert.financialRecord?.quantity,
    list_unit_price: upsert.financialRecord?.list_unit_price,
    unit_price: upsert.financialRecord?.unit_price,
    total: String(Number(upsert.financialRecord?.quantity) * Number(upsert.financialRecord?.unit_price)),
    vaccine: service?.vaccine,
    controlled: service?.controlled,
    reminder: upsert.reminder?.[0]
      ? [
          {
            id: upsert.reminder?.[0]?.id,
            name: upsert.reminder?.[0]?.record?.name,
            date: upsert.reminder?.[0]?.record?.date,
            note: upsert.reminder?.[0]?.record?.note,
          } as Reminder,
        ]
      : undefined,
    prescriptions: upsert.prescriptions?.[0]
      ? [
          {
            id: upsert.prescriptions?.[0]?.id,
            filled_service_rendered_id: serviceRenderedId,
            contact_id: upsert.prescriptions?.[0]?.record?.contact_id,
            patient_id: upsert.prescriptions?.[0]?.record?.patient_id,
            prescribed_user_id: upsert.prescriptions?.[0]?.record?.prescribed_user_id,
            prescribed_date: upsert.prescriptions?.[0]?.record?.prescribed_date,
            prescription_expiry_date: upsert.prescriptions?.[0]?.record?.prescription_expiry_date,
            product_expiry_date: upsert.prescriptions?.[0]?.record?.product_expiry_date,
            item_description: upsert.prescriptions?.[0]?.record?.item_description,
            item_quantity: upsert.prescriptions?.[0]?.record?.item_quantity,
            item_unit: upsert.prescriptions?.[0]?.record?.item_unit,
            instructions: upsert.prescriptions?.[0]?.record?.instructions,
            practice_id: upsert.prescriptions?.[0]?.record?.practice_id,
            fill_externally: upsert.prescriptions?.[0]?.record?.fill_externally,
            created_type_id: upsert.prescriptions?.[0]?.record?.created_type_id,
          } as PrescriptionFlattened,
        ]
      : undefined,
    info: upsert.info?.map((i) => i.record),
    text: upsert.text?.map((t) => t.record),
  } as ServiceRendered;

  if (upsert.note) {
    serviceRendered.note = {
      id: dummyNoteIdForRapidBilling,
      value: upsert.note?.record?.value,
      preview: upsert.note?.record?.preview,
      type_id: upsert.note?.record?.type_id,
      hidden: upsert.note?.record?.hidden,
      locked: upsert.note?.record?.locked,
    } as Note;
    serviceRendered.note_id = dummyNoteIdForRapidBilling;
  }

  return serviceRendered;
};

export const buildBatchGroupData = (
  upsert: BatchGroupUpsert,
  offlinePatients: RxPatient[] | undefined,
  services: Service[] | undefined,
  doctors: Caregiver[],
  dateFormat: string
): BatchGroupData => {
  const subBatch = upsert.subBatch;

  const patientsId: string[] = [];
  const servicesRendered: ServiceRendered[] = [];
  const servicesRenderedByServiceId: Record<string, RapidBillingServiceRendered[]> = {};
  const patientsByServiceRenderedIds: PatientsByServiceRenderedId = {};
  const prescriptions: PrescriptionFlattened[] = [];
  const hisaValues: HisaValuesByServiceAndPatient = {};

  subBatch.forEach((batch, batchIndex) => {
    batch.item.forEach((item, itemIndex) => {
      const serviceRendered = buildServiceRendered(
        item,
        Number(`${batchIndex + 1}00${itemIndex}`),
        services,
        doctors,
        dateFormat
      );

      const batchOfflinePatientIds =
        batch.offline_patient?.map((p) => p.patient_id ?? p.offline_patient_id ?? '') ?? [];

      const patientIds = [...(batch.patient_id ?? []), ...batchOfflinePatientIds];

      // we are trying to combine the separated hisa services rendered back into the same line item
      // so we give it the same id as the first service rendered id for that service id
      if (servicesRenderedByServiceId[serviceRendered.service_id]) {
        serviceRendered.id = servicesRenderedByServiceId[serviceRendered.service_id][0].id;
      } else {
        servicesRenderedByServiceId[serviceRendered.service_id] = [serviceRendered];
      }

      // We need to return the same line item for duplicates when hisa info present, but hide them
      // in the batch viewer
      const hasHisaInfo = item.info?.find((i) =>
        Object.values(HisaRequirementsTypeId).includes(i.record?.type_id as any)
      );

      if (hasHisaInfo && item.info) {
        // pulling values from upsert and setting them to hisa form values based on the service rendered id followed by the first element
        // of the offline patient ids as for hisa batches there will only ever be one patient
        if (!hisaValues[serviceRendered.service_id]) {
          hisaValues[serviceRendered.service_id] = {};
        }

        hisaValues[serviceRendered.service_id][batchOfflinePatientIds[0]] = buildHisaFormValuesFromUpsert(item.info);
      }

      patientIds.forEach((id) => {
        const patient = offlinePatients?.find((p) => p.id === id) as unknown as Patient;
        if (patient && !patientsId.includes(id)) {
          patientsId.push(id);
        }
        if (patientsByServiceRenderedIds[serviceRendered.id]) {
          patientsByServiceRenderedIds[serviceRendered.id].push(patient);
        } else {
          patientsByServiceRenderedIds[serviceRendered.id] = [patient];
        }
      });

      if (!servicesRendered.some((sr) => sr.id === serviceRendered.id)) {
        servicesRendered.push(serviceRendered);
      }

      if (serviceRendered.prescriptions) {
        prescriptions.push(...serviceRendered.prescriptions);
      }
    });
  });

  const patients = patientsId.map((id) => offlinePatients?.find((p) => p.id === id) as unknown as Patient);

  return {
    patients,
    servicesRendered,
    patientsByServiceRenderedIds,
    prescriptions,
    batchGroupOptions: upsert.options,
    servicesRenderedByServiceId,
    hisaValues,
  } as BatchGroupData;
};

export const getRequiredHisaValuesByServiceAndPatient = (values: Store) => {
  const requiredHisaValuesByServiceAndPatient: Record<string, Record<string, HisaFormFieldsValues>> = {};
  for (const key of Object.keys(values)) {
    const [requirementField, patientId, serviceId] = key.split('_');
    if (Object.values(HisaFormField).includes(requirementField as HisaFormField)) {
      if (!requiredHisaValuesByServiceAndPatient[serviceId]) {
        requiredHisaValuesByServiceAndPatient[serviceId] = { [patientId]: { [requirementField]: values[key] } };
      }

      if (!requiredHisaValuesByServiceAndPatient[serviceId][patientId]) {
        requiredHisaValuesByServiceAndPatient[serviceId][patientId] = { [requirementField]: values[key] };
      }

      requiredHisaValuesByServiceAndPatient[serviceId][patientId] = {
        ...requiredHisaValuesByServiceAndPatient[serviceId][patientId],
        [requirementField]: values[key],
      };
    }
  }
  return requiredHisaValuesByServiceAndPatient;
};

const getSRsAndPatientsForRequirements = (
  requiredHisaValuesByServiceAndPatient: HisaValuesByServiceAndPatient,
  servicesRendered: RapidBillingServiceRendered[],
  patientsByServiceRenderedId: PatientsByServiceRenderedId
) => {
  const updatedServicesRendered: RapidBillingServiceRendered[] = [];
  let requiredPatientsByServiceRenderedIds = { ...patientsByServiceRenderedId };
  servicesRendered.forEach((service) => {
    const requiredService = requiredHisaValuesByServiceAndPatient[service.service_id];
    if (requiredService) {
      const hisaRequiredPatientIds = Object.keys(requiredService).map((p) => p);
      const nonHisaRequiredPatientIds = patientsByServiceRenderedId[service.id]?.filter(
        (p) => !hisaRequiredPatientIds.includes(p.id)
      );
      if (!!nonHisaRequiredPatientIds?.length) {
        updatedServicesRendered.push({
          ...service,
          patients: nonHisaRequiredPatientIds,
        });
      }
      Object.keys(requiredService).forEach((patientId, i) => {
        const updatedServiceRenderedId = `${service.id}_${i}`;
        const { hisaText, hisaInfo } = getHisaValuesAndText(updatedServiceRenderedId, requiredService[patientId]);
        const patientForService = patientsByServiceRenderedId[service.id].find((p) => p.id === patientId);
        updatedServicesRendered.push({
          ...service,
          id: updatedServiceRenderedId,
          patients: patientForService ? [patientForService] : undefined,
          info: hisaInfo,
          text: hisaText,
        });
        const { foundPatient, remainingPatients } = requiredPatientsByServiceRenderedIds[service.id].reduce<{
          foundPatient: Patient | null;
          remainingPatients: Patient[];
        }>(
          (acc, p) => {
            if (p.id === patientId) {
              acc.foundPatient = p;
            } else {
              acc.remainingPatients.push(p);
            }
            return acc;
          },
          { foundPatient: null, remainingPatients: [] }
        );

        if (foundPatient) {
          requiredPatientsByServiceRenderedIds = {
            ...requiredPatientsByServiceRenderedIds,
            [service.id]: remainingPatients,
            [updatedServiceRenderedId]: [foundPatient],
          };
        }
      });
    } else {
      updatedServicesRendered.push(service);
    }
  });
  return { updatedServicesRendered, requiredPatientsByServiceRenderedIds };
};
