import {
  ServiceRendered3pApprovalProperty,
  serviceRendered3pApprovalPropertyNames,
} from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { ServiceRendered3pApproval } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { renderPatientOwnerTags } from '../../Invoices/InvoicesOverview/util/patientOwnerTags';
import { relationshipFilter } from '../../Patients/PatientsOverview/patientColumns';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { ThirdPartyApprovalTypeNameKey } from '../../../constants/referenceData/thirdPartyReferenceData';

export function getValidationFailedFilters(): ColumnFilterItem[] {
  return [
    {
      text: translations.approvalsPage.validationError,
      value: true,
    },
  ];
}

export const validationFailedFilter = (value: string | number | boolean, approval: ServiceRendered3pApproval) =>
  approval.validation_failed === value;

export const basicApprovalsColumns: Record<
  ServiceRendered3pApprovalProperty,
  CustomColumnType<ServiceRendered3pApproval>
> = {
  date: {
    title: translations.approvalsPage.columns.date,
    dataIndex: serviceRendered3pApprovalPropertyNames.date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.approvalsPage.columns.date),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.date),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.date),
  },
  doctor_name: {
    title: translations.approvalsPage.columns.doctor,
    dataIndex: serviceRendered3pApprovalPropertyNames.doctor_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.approvalsPage.columns.doctor),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.doctor_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.doctor_name),
  },
  patient_name: {
    title: translations.approvalsPage.columns.patient,
    dataIndex: serviceRendered3pApprovalPropertyNames.patient_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.approvalsPage.columns.patient),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.patient_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.patient_name),
  },
  owner_names: {
    title: translations.approvalsPage.columns.ownership,
    dataIndex: serviceRendered3pApprovalPropertyNames.owner_names,
    ...generalFilteringAndSortingSettings,
    render: (_, approval: ServiceRendered3pApproval) => renderPatientOwnerTags(approval, true),
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.approvalsPage.columns.ownership),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.owner_names),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.owner_names),
  },
  service_name: {
    title: translations.approvalsPage.columns.service_name,
    dataIndex: serviceRendered3pApprovalPropertyNames.service_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.approvalsPage.columns.service_name
    ),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.service_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.service_name),
  },
  quantity: {
    title: translations.approvalsPage.columns.quantity,
    dataIndex: serviceRendered3pApprovalPropertyNames.quantity,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.approvalsPage.columns.quantity),
    onFilter: getOnFilterFunctionFor(serviceRendered3pApprovalPropertyNames.quantity),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.quantity),
  },
  related_names: {
    title: translations.approvalsPage.columns.relationship,
    key: serviceRendered3pApprovalPropertyNames.related_names,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.approvalsPage.columns.relationship
    ),
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.related_names),
    onFilter: relationshipFilter,
  },
  info: {
    title: translations.approvalsPage.columns.info,
    key: serviceRendered3pApprovalPropertyNames.info,
    ...generalFilteringAndSortingSettings,
    filters: [
      { text: translations.approvalsPage.approvalTypeName.add, value: ThirdPartyApprovalTypeNameKey.Add },
      { text: translations.approvalsPage.approvalTypeName.update, value: ThirdPartyApprovalTypeNameKey.Update },
      { text: translations.approvalsPage.approvalTypeName.delete, value: ThirdPartyApprovalTypeNameKey.Delete },
    ],
    sorter: getStringCompareFunctionFor(serviceRendered3pApprovalPropertyNames.info),
    onFilter: (value, record) => record.info === value,
  },
  validation_failed: {
    title: '',
    key: serviceRendered3pApprovalPropertyNames.validation_failed,
    ...generalFilteringAndSortingSettings,
    filters: getValidationFailedFilters(),
    onFilter: validationFailedFilter,
  },
};
