import dayjs from 'dayjs';
import { useContext, useRef, useState } from 'react';
import { showErrorMessage, showSuccessMessage } from '../components/Notification/notificationUtil';
import ViewSubscriptionActions from '../components/ViewSubscription/store/actions';
import { ViewSubscriptionContext } from '../components/ViewSubscription/store/state';
import { translations } from '../constants/translations';
import { GetContactText } from '../graph/queries/contacts';
import { Contact, ContactUpsert, Practice, PracticeDto, Query } from '../graph/types';
import { useOffline } from '../util/offline/offlineUtil';
import { useContactOfflineUpdate, useUpdateContact } from './ajax/contact/contactHooks';
import { useGetCurrentUser } from './ajax/user/userHooks';
import { useGetOrganizationIdFromRoute } from './route/routeParameterHooks';
import { upsertDateFormat } from '../constants/formats';
import { useUserContext } from '../contexts/user/state';
import { GetUserOrganizationCardsPg } from '../graph/queries/users';
import { Reference } from '@apollo/client';
import { ContactCardFields } from '../graph/fragments/userFragments';

export const textTypeId = '1';
export const textCategory = 'TEXT';
export const textNameKey = 'CTEXT_NOTE';

export const useUpdateContactFromContactPage = (practice: Practice, contact: Contact) => {
  const [updateContact] = useUpdateContact();
  const offlineUpdate = useContactOfflineUpdate(contact.id);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { user, userLoading } = useGetCurrentUser(practice.organization_id);
  const { dispatch: dispatchViewContact } = useContext(ViewSubscriptionContext);
  const { canUseCollection } = useOffline('contact');
  const shouldUpdateFormValues = useRef(true);
  const organizationId = useGetOrganizationIdFromRoute();
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const handleContactUpsert = async (contactUpsert: ContactUpsert, isUpdatingOptions = false) => {
    setIsSaving(true);

    try {
      dispatchViewContact(ViewSubscriptionActions.setEditing(false));
      shouldUpdateFormValues.current = !isUpdatingOptions;
      let updatedContact: Contact | undefined | null;
      if (!canUseCollection) {
        const { data } = await updateContact({
          variables: {
            organizationId: practice.organization_id,
            contact: contactUpsert,
          },
          update: (cache, result) => {
            const upsertedText = result?.data?.upsertContact?.text?.[0];
            const upsertedTextId = upsertedText?.id;

            const text = contactUpsert.text?.find((t) => !t.void)?.record;
            if (text) {
              cache.writeQuery({
                query: GetContactText,
                data: {
                  getContactText: upsertedTextId
                    ? {
                        __typename: 'InfoText',
                        id: upsertedTextId,
                        value: text?.value,
                        type_id: textTypeId,
                        category: textCategory,
                        name_key: textNameKey,
                        updated: dayjs().format(upsertDateFormat),
                        updated_user_name: user.name,
                        offline_id: null,
                      }
                    : null,
                },
                variables: {
                  id: upsertedTextId,
                  organizationId,
                },
              });
            }

            const upsertedContact = result.data?.upsertContact;
            const currentCards = cache.readQuery<Pick<Query, 'getUserOrganizationCardsPg'>>({
              query: GetUserOrganizationCardsPg,
              variables: { organizationId, filter: { user_id: user.id } },
            });
            const card = currentCards?.getUserOrganizationCardsPg.contact_card.find(
              ({ contact_id }) => contact_id === result.data?.upsertContact?.id
            );

            if (card && upsertedContact) {
              const updatedContactCard = {
                ...card,
                contact_name: upsertedContact.name,
                contact_status_id: upsertedContact.contact_status_id,
                contact_status_name: upsertedContact.contact_status_name,
                contact_email: upsertedContact.email,
                contact_dob: upsertedContact.dob,
                contact_tag_names: upsertedContact.tag_names,
                contact_type_name_keys: upsertedContact.type_name_keys,
                contact_balance_unposted: upsertedContact.balance_unposted,
                contact_balance_posted: upsertedContact.balance_posted,
                contact_send_type: upsertedContact.send_type,
                contact_statement_ignore: upsertedContact.statement_ignore,
                contact_hidden: upsertedContact.hidden,
                contact_address: upsertedContact.address,
                contact_phone: upsertedContact.phone,
              };
              cache.modify({
                fields: {
                  getUserOrganizationCardsPg(
                    currentCardData: { contact_card: Reference[]; patient_card: Reference[] } = {
                      contact_card: [],
                      patient_card: [],
                    }
                  ) {
                    const cardReference = cache.writeFragment({
                      data: updatedContactCard,
                      fragment: ContactCardFields,
                      fragmentName: 'ContactCardFields',
                    });
                    return { ...currentCardData, patient_card: [cardReference, ...currentCardData.patient_card] };
                  },
                },
              });
            }
          },
        });
        updatedContact = data?.upsertContact;
      } else {
        updatedContact = await offlineUpdate?.(contactUpsert);
      }

      if (updatedContact) {
        const contactName = updatedContact.name;
        showSuccessMessage(translations.viewContactPage.getSuccessMessage(contactName));
        if (!isUpdatingOptions) {
          setHasChanged(false);
        }
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
    shouldUpdateFormValues.current = true;
  };
  return { handleContactUpsert, isSaving, setIsSaving, userLoading, hasChanged, setHasChanged, shouldUpdateFormValues };
};

export const useUpdateContactFromContactPageDto = (practice: PracticeDto, contact: Contact) => {
  const [updateContact] = useUpdateContact();
  const offlineUpdate = useContactOfflineUpdate(contact.id);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {
    state: { user },
  } = useUserContext();
  const { dispatch: dispatchViewContact } = useContext(ViewSubscriptionContext);
  const { canUseCollection } = useOffline('contact');
  const shouldUpdateFormValues = useRef(true);
  const organizationId = useGetOrganizationIdFromRoute();
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const handleContactUpsert = async (contactUpsert: ContactUpsert, isUpdatingOptions = false) => {
    setIsSaving(true);

    try {
      dispatchViewContact(ViewSubscriptionActions.setEditing(false));
      shouldUpdateFormValues.current = !isUpdatingOptions;
      let updatedContact: Contact | undefined | null;
      if (!canUseCollection) {
        const { data } = await updateContact({
          variables: {
            organizationId: practice.organization_id,
            contact: contactUpsert,
          },
          update: (cache, result) => {
            const upsertedText = result?.data?.upsertContact?.text?.[0];
            const upsertedTextId = upsertedText?.id;

            const text = contactUpsert.text?.find((t) => !t.void)?.record;
            if (text) {
              cache.writeQuery({
                query: GetContactText,
                data: {
                  getContactText: upsertedTextId
                    ? {
                        __typename: 'InfoText',
                        id: upsertedTextId,
                        value: text?.value,
                        type_id: textTypeId,
                        category: textCategory,
                        name_key: textNameKey,
                        updated: dayjs().format(upsertDateFormat),
                        updated_user_name: user?.name,
                        offline_id: null,
                      }
                    : null,
                },
                variables: {
                  id: upsertedTextId,
                  organizationId,
                },
              });
            }
          },
        });
        updatedContact = data?.upsertContact;
      } else {
        updatedContact = await offlineUpdate?.(contactUpsert);
      }

      if (updatedContact) {
        const contactName = updatedContact.name;
        showSuccessMessage(translations.viewContactPage.getSuccessMessage(contactName));
        if (!isUpdatingOptions) {
          setHasChanged(false);
        }
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
    shouldUpdateFormValues.current = true;
  };
  return { handleContactUpsert, isSaving, setIsSaving, hasChanged, setHasChanged, shouldUpdateFormValues };
};
