import { Patient, RelatedContactEntryCurrent, ServiceRendered3pApproval } from '../../../../graph/types';
import React from 'react';
import { getOwnerTagsFromArrays } from '../../../Patients/ViewPatient/PatientOwnership/OwnershipTags/ownershipTagsUtil';
import { PatientOwnerTagsParagraph } from './patientOwnerTags.styles';

export function renderPatientOwnerTags(patient: Patient | ServiceRendered3pApproval, isSearch = false) {
  return (
    <PatientOwnerTagsParagraph display={isSearch ? 'flex' : 'box'} ellipsis={{ rows: 2 }}>
      {getOwnerTagsForPatient(patient)}
    </PatientOwnerTagsParagraph>
  );
}

export function getOwnerTagsForPatient(patient: Patient | ServiceRendered3pApproval) {
  const names = patient.owner_names || [];
  const percentages = patient.owner_percentages || [];
  const ownerIds = patient.owner_ids || [];
  return getOwnerTagsFromArrays(names, percentages, ownerIds);
}

export function getContactTagForPatient(relatedCurrent: RelatedContactEntryCurrent | undefined) {
  if (!relatedCurrent) {
    return [];
  }
  const names = [relatedCurrent.contact_name] || [];
  const percentages = ['100'];
  const ids = [relatedCurrent.contact_id ?? ''];
  return getOwnerTagsFromArrays(names, percentages, ids);
}
