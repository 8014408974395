import { ContactLedgerAction, ContactLedgerActionType } from './actions';

export interface ContactLedgerState {
  paymentModalVisible: boolean;
  creditModalVisible: boolean;
  accountChargeModalVisible: boolean;
  noteEditModalVisible: boolean;
  reversePaymentModalVisible: boolean;
  isAssignAllEnabled: boolean;
  shouldRefetchLedger: boolean;
  stripeDetailsModalVisible: boolean;
}

export const initialState: ContactLedgerState = {
  paymentModalVisible: false,
  creditModalVisible: false,
  accountChargeModalVisible: false,
  noteEditModalVisible: false,
  reversePaymentModalVisible: false,
  isAssignAllEnabled: false,
  shouldRefetchLedger: false,
  stripeDetailsModalVisible: false,
};

export const contactLedgerReducer = (state: ContactLedgerState, action: ContactLedgerAction): ContactLedgerState => {
  switch (action.type) {
    case ContactLedgerActionType.SetPaymentModalVisible:
      return { ...state, paymentModalVisible: action.paymentModalVisible };
    case ContactLedgerActionType.SetCreditModalVisible:
      return { ...state, creditModalVisible: action.creditModalVisible };
    case ContactLedgerActionType.SetAccountChargeModalVisible:
      return { ...state, accountChargeModalVisible: action.accountChargeModalVisible };
    case ContactLedgerActionType.SetNoteEditModalVisible:
      return { ...state, noteEditModalVisible: action.noteEditModalVisible };
    case ContactLedgerActionType.SetReversePaymentModalVisible:
      return { ...state, reversePaymentModalVisible: action.reversePaymentModalVisible };
    case ContactLedgerActionType.SetShouldEnableAssignAllBtn:
      return { ...state, isAssignAllEnabled: action.isAssignAllEnabled };
    case ContactLedgerActionType.SetShouldRefetchLedger:
      return { ...state, shouldRefetchLedger: action.shouldRefetchLedger };
    case ContactLedgerActionType.SetStripeDetailsModalVisible:
      return { ...state, stripeDetailsModalVisible: action.stripeDetailsModalVisible };
  }
  return state;
};
