import { Form } from 'antd';
import React from 'react';
import { displayDateAsString } from '../../../../constants/formats';
import { translations } from '../../../../constants/translations';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import CurrencyFormatter from '../../../CurrencyFormatter/CurrencyFormatter';
import dayjs from 'dayjs';

interface PaymentSummaryProps {
  formValues: { date: dayjs.Dayjs; amount: number };
  additionalFields?: { label: string; value: string }[];
  showDate?: boolean;
  showAmount?: boolean;
}

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  formValues,
  additionalFields,
  showDate = true,
  showAmount = true,
}) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return (
    <Form labelCol={{ span: 6 }} autoComplete='off'>
      {showDate && (
        <Form.Item style={{ margin: '0' }} label={translations.paymentModal.fields.date}>
          {displayDateAsString(formValues.date, dateFormat)}
        </Form.Item>
      )}
      {showAmount && (
        <Form.Item style={{ margin: '0' }} label={translations.paymentModal.fields.amount}>
          <CurrencyFormatter total={formValues.amount} />
        </Form.Item>
      )}
      {additionalFields?.map((additionalField) => (
        <Form.Item key={additionalField.label} style={{ margin: '0' }} label={additionalField.label}>
          {additionalField.value}
        </Form.Item>
      ))}
    </Form>
  );
};
