import { Col, Form, Input, Select } from 'antd';
import { pick } from 'lodash';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { getLabelFromTranslation } from '../../../components/InfoTable/infoTableUtil';
import { translations } from '../../../constants/translations';
import { Maybe, SystemReferenceDataTypes } from '../../../graph/types';
import { getRequiredRule, getValidEmailRule } from '../../../util/forms';
import { SelectOptions } from '../../../components/DependentDropdown/DependentDropdown';
import { FormInstance } from 'antd/lib/form';
import { getCountryKeyFromId } from '../../../util/countrySelectorUtil';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { LOCALES } from '../../../util/locales';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { CountryFormKey, countryFormValues } from '../../../constants/countryFormValues';
import { countryProvStateTranslations, countryTranslations } from '../../../constants/countryTranslations';
import { InfoCircleOutlined } from '@ant-design/icons';

const addressColumnSpan = 24;

type AddressFieldsProps = {
  referenceData?: Maybe<SystemReferenceDataTypes> | undefined;
  index: number;
  form: FormInstance;
  isBillingAddress?: boolean;
  mustBePhysicalAddress?: boolean;
  includeEmailAndPhone?: boolean;
  disableCountry?: boolean;
};

export const AddressFields: React.FC<AddressFieldsProps> = ({
  referenceData,
  index,
  form,
  isBillingAddress = false,
  mustBePhysicalAddress = false,
  includeEmailAndPhone = false,
  disableCountry = false,
}: AddressFieldsProps) => {
  const countryName = ['address', index, 'country_id'];
  const provStateName = ['address', index, 'country_prov_state_id'];
  const postalZipName = ['address', index, 'postal_zip'];
  const [provStateOptions, setStateProvOptions] = useState<SelectOptions[]>();
  const [countryId, setCountryId] = useState(form.getFieldValue(countryName));
  const countryKey = getCountryKeyFromId(referenceData?.country ?? [], countryId);
  const contryFormValue = countryKey ? countryFormValues[countryKey as CountryFormKey] : countryFormValues.CTRY_USA;

  const hidePostalZip = useMemo(() => LOCALES[countryKey as CountryFormKey]?.hidePostalZip, [countryKey]);
  const hideIfNoProvince =
    provStateOptions?.some(({ label }) => label === 'No Province') && provStateOptions?.length === 1;

  const getCountriesWithStateProvOptions = useCallback(
    () =>
      referenceData?.country?.map((country) => {
        const stateProvByCountryOptions = referenceData?.prov_state
          ?.map(({ id, country_id, name_key }) => ({
            value: id,
            label: getLabelFromTranslation(countryProvStateTranslations, name_key),
            countryId: country_id,
          }))
          .filter(({ countryId, label }) => label && countryId === country.id);

        return {
          value: country.id,
          label: getLabelFromTranslation(countryTranslations, country.name_key),
          provStateOptions: stateProvByCountryOptions,
        };
      }),
    [referenceData?.country, referenceData?.prov_state]
  );

  useEffect(() => {
    const initialCountryId = form.getFieldValue(['address', 0, 'country_id']);
    if (initialCountryId) {
      const initialCountry = getCountriesWithStateProvOptions()?.find(({ value }) => value === initialCountryId);
      setStateProvOptions(initialCountry?.provStateOptions);
    }
  }, [form, getCountriesWithStateProvOptions]);

  const countriesWithStateProvOptions = getCountriesWithStateProvOptions();

  const onCountryChange = (countryId: string) => {
    const selectedCountry = countriesWithStateProvOptions?.find(({ value }) => countryId === value);
    form.setFields([
      { name: provStateName, value: undefined },
      { name: postalZipName, value: undefined, errors: [] },
    ]);

    setCountryId(countryId);
    setStateProvOptions(selectedCountry?.provStateOptions?.map((item) => pick(item, 'value', 'label')));
  };

  const billingAddressTranslation = mustBePhysicalAddress
    ? translations.organizationSubscriptionSettings.physicalAddress
    : translations.organizationSubscriptionSettings.billingAddress;

  const address1Label = isBillingAddress
    ? billingAddressTranslation
    : translations.registration.addPracticeInfoStep.labels.address1;

  const addressTooltip = mustBePhysicalAddress && {
    icon: <InfoCircleOutlined />,
    title: translations.organizationSubscriptionSettings.physicalAddressInfo,
    color: 'black',
  };

  return (
    <>
      <Col span={addressColumnSpan}>
        <MaxLengthFormItem
          name={['address', index, 'address_1']}
          label={address1Label}
          rules={[getRequiredRule(address1Label)]}
          className='registration-field'
          maxLength={100}
          tooltip={addressTooltip}
        >
          <Input placeholder={address1Label} autoComplete='new-password' />
        </MaxLengthFormItem>
      </Col>
      {includeEmailAndPhone && (
        <>
          <Col span={addressColumnSpan}>
            <MaxLengthFormItem
              name={['address', index, 'email']}
              label={translations.registration.addPracticeInfoStep.labels.email}
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.email), getValidEmailRule()]}
              className='registration-field'
              maxLength={50}
            >
              <Input
                placeholder={translations.registration.addPracticeInfoStep.labels.email}
                autoComplete='new-password'
              />
            </MaxLengthFormItem>
          </Col>
          <Col span={addressColumnSpan}>
            <MaxLengthFormItem
              name={['address', index, 'phone']}
              label={translations.registration.addPracticeInfoStep.labels.phone}
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.phone)]}
              className='registration-field'
              maxLength={50}
            >
              <Input
                placeholder={translations.registration.addPracticeInfoStep.labels.phone}
                autoComplete='new-password'
              />
            </MaxLengthFormItem>
          </Col>
        </>
      )}
      {!isBillingAddress && (
        <Col span={addressColumnSpan}>
          <MaxLengthFormItem
            name={['address', index, 'address_2']}
            label={translations.registration.addPracticeInfoStep.labels.address2}
            className='registration-field'
            maxLength={100}
          >
            <Input
              placeholder={translations.registration.addPracticeInfoStep.labels.address2}
              autoComplete='new-password'
            />
          </MaxLengthFormItem>
        </Col>
      )}
      <Col span={addressColumnSpan}>
        <MaxLengthFormItem
          name={['address', index, 'city']}
          label={translations.registration.addPracticeInfoStep.labels.city}
          rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.city)]}
          className='registration-field'
          maxLength={50}
        >
          <Input placeholder={translations.registration.addPracticeInfoStep.labels.city} autoComplete='new-password' />
        </MaxLengthFormItem>
      </Col>
      <Col span={addressColumnSpan}>
        <Form.Item
          name={countryName}
          label={translations.registration.addPracticeInfoStep.labels.country}
          rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.country)]}
          className='registration-field'
        >
          <Select
            {...searchableSelectParams}
            options={countriesWithStateProvOptions?.map((item) => pick(item, 'value', 'label'))}
            placeholder={translations.addressBlockComponent.placeholder.country}
            onChange={(selectValue) => onCountryChange(selectValue as string)}
            disabled={disableCountry}
          />
        </Form.Item>
      </Col>
      {!hideIfNoProvince && (
        <Col span={addressColumnSpan}>
          <Form.Item
            name={provStateName}
            label={contryFormValue.provState.label}
            rules={[getRequiredRule(contryFormValue.provState.label)]}
            className='registration-field'
            shouldUpdate
          >
            <Select
              {...searchableSelectParams}
              options={provStateOptions}
              placeholder={contryFormValue.provState.placeholder}
            />
          </Form.Item>
        </Col>
      )}

      {!hidePostalZip && (
        <Col span={addressColumnSpan}>
          <MaxLengthFormItem
            name={postalZipName}
            label={contryFormValue.postalZip.label}
            rules={[
              getRequiredRule(translations.registration.addPracticeInfoStep.labels.zip),
              {
                pattern: contryFormValue.postalZip.pattern,
                message: contryFormValue.postalZip.message,
              },
            ]}
            className='registration-field'
            maxLength={15}
          >
            <Input placeholder={contryFormValue.postalZip.label} autoComplete='new-password' />
          </MaxLengthFormItem>
        </Col>
      )}
    </>
  );
};
