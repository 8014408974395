import { Button } from 'antd';
import React, { useState } from 'react';
import { Patient, UnassignedLabResults } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import { TableKey } from '../../../hooks/tableHooks';
import { getSaveErrorMessageFromError } from '../../../hooks/ajax/generalMutationHooks';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { displayAsDate } from '../../../constants/formats';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { useGetUnassignedLabResults, useUpsertLabAssignments } from '../../../hooks/ajax/lab/labHooks';
import { StyledPageHeader } from '../../ReminderRuns/ReminderRuns.styles';
import {
  DropdownButtonWithMenu,
  MenuItemProps,
} from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { basicUnassignedLabsColumns } from './UnassignedLabColumns';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import SelectPatientModal from '../../../components/SelectPatientModal/SelectPatientModal';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';

export enum UnassignedLabModalTypes {
  Raw,
  Align,
  Bulk,
}

export const UnassignedLabsTab: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { labResults, labResultsLoading } = useGetUnassignedLabResults(organizationId);
  const [upsertAssignments] = useUpsertLabAssignments(organizationId);
  const [selectedLabResults, setSelectedLabResults] = useState<{
    results: UnassignedLabResults[];
    type: UnassignedLabModalTypes;
    isPatientSelectionOpen?: boolean;
  }>();
  const [currentTableDataSource, setCurrentTableDataSource] = useState<UnassignedLabResults[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const handleBulkAlign = () => {
    if (selectedLabResults) {
      setSelectedLabResults({ ...selectedLabResults, isPatientSelectionOpen: true });
    }
  };

  const linkActionMenu = (result: UnassignedLabResults) => {
    const actions = [
      {
        title: translations.labManagementPage.screens.unassignedLabs.alignButtonLabel,
        onClick: () =>
          setSelectedLabResults({
            results: [result],
            type: UnassignedLabModalTypes.Align,
            isPatientSelectionOpen: true,
          }),
        disabled: selectedLabResults && selectedLabResults.results.length > 0,
      },
      {
        title: translations.labManagementPage.screens.unassignedLabs.view,
        onClick: () => setSelectedLabResults({ results: [result], type: UnassignedLabModalTypes.Raw }),
        disabled: selectedLabResults && selectedLabResults.results.length > 0,
      },
    ] as MenuItemProps[];

    return <DropdownButtonWithMenu menuItemProps={actions} />;
  };

  const columns: CustomColumnType<UnassignedLabResults>[] = [
    {
      ...basicUnassignedLabsColumns.result_date,
      render: (date: string) => displayAsDate(date, dateFormat),
      width: 100,
    },
    {
      ...basicUnassignedLabsColumns.contact_name,
      width: 200,
    },
    {
      ...basicUnassignedLabsColumns.patient_name,
      width: 200,
    },
    {
      ...basicUnassignedLabsColumns.lab_tests,
      width: 200,
    },
    {
      title: translations.labManagementPage.screens.unassignedLabs.columns.actions,
      key: 'actions',
      width: 100,
      render: (record: UnassignedLabResults) => linkActionMenu(record),
    },
  ];

  const handleAlignment = async (patients: Patient[]) => {
    if (!selectedLabResults) {
      return;
    }

    setSelectedLabResults({ ...selectedLabResults, isPatientSelectionOpen: false });

    setLoading(true);
    try {
      await upsertAssignments({
        variables: {
          organizationId,
          labAssignments: {
            patient_id: patients[0].id,
            lab_request_ids: selectedLabResults.results.map((result) => result.lab_request_id),
          },
        },
        update: (cache) => {
          selectedLabResults.results.forEach((result) => {
            cache.evict({
              id: cache.identify({
                __typename: 'UnassignedLabResults',
                lab_request_id: result.lab_request_id,
              }),
            });
          });
          cache.gc();
        },
      });

      setSelectedLabResults(undefined);
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      if (selectedLabResults.type === UnassignedLabModalTypes.Align) {
        setSelectedLabResults(undefined);
      }
      showErrorMessage(getSaveErrorMessageFromError(e));
    }
    setLoading(false);
  };

  return (
    <SaveSpinner isSaving={loading}>
      <StyledPageHeader
        title=''
        extra={[
          <Button
            key='btnApproval'
            type='primary'
            disabled={!selectedLabResults || selectedLabResults.results.length === 0}
            onClick={handleBulkAlign}
          >
            {translations.labManagementPage.screens.unassignedLabs.bulkAlignLabel}
          </Button>,
        ]}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.UnassignedLabs}
        columns={columns}
        loading={labResultsLoading}
        dataSource={labResults || undefined}
        rowKey={'lab_request_id'}
        rowSelection={{
          type: 'checkbox',
          onChange: (_, records) => {
            setSelectedLabResults({ type: UnassignedLabModalTypes.Bulk, results: records });
          },
          onSelectAll: (selected) =>
            selected
              ? setSelectedLabResults({ type: UnassignedLabModalTypes.Bulk, results: currentTableDataSource })
              : setSelectedLabResults({ type: UnassignedLabModalTypes.Bulk, results: [] }),
          selectedRowKeys:
            selectedLabResults?.type === UnassignedLabModalTypes.Bulk
              ? selectedLabResults?.results.map((i) => i.lab_request_id)
              : [],
        }}
        setCurrentDataSource={setCurrentTableDataSource}
      />
      {selectedLabResults?.type === UnassignedLabModalTypes.Raw && (
        <ModalWithCloseConfirm
          onOk={() => setSelectedLabResults(undefined)}
          open
          title={translations.labManagementPage.screens.unassignedLabs.view}
          okText={translations.shared.okButtonText}
          cancelButtonProps={{ hidden: true }}
          hideCloseIcon
        >
          {selectedLabResults.results[0].result_data}
        </ModalWithCloseConfirm>
      )}
      {selectedLabResults?.isPatientSelectionOpen && (
        <SelectPatientModal
          title={translations.shared.selectPatientModal.title}
          finishSelectPatients={handleAlignment}
          onCancel={() =>
            selectedLabResults.type === UnassignedLabModalTypes.Align
              ? setSelectedLabResults(undefined)
              : setSelectedLabResults({ ...selectedLabResults, isPatientSelectionOpen: false })
          }
        />
      )}
    </SaveSpinner>
  );
};
