import React, { useState } from 'react';
import { useGetCurrentUser, useGetUserOrganizationWithTypes } from '../../../hooks/ajax/user/userHooks';
import { Loading } from '../../../components/Loading/Loading';
import { UserDetails } from '../UserDetails/UserDetails';
import { translations } from '../../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { WidthRestrictor } from '../../../components/Restrictors/WidthRestrictor';
import { Divider } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ChangePassword } from '../../../components/ChangePassword/ChangePassword';
import { ChangePin } from '../../../components/ChangePin/ChangePin';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';

export const UserSettings: React.FC = () => {
  const { user, userLoading } = useGetCurrentUser();
  const organizationId = useGetOrganizationIdFromRoute();
  const { userOrganization: userOrganizations, userOrganizationLoading } = useGetUserOrganizationWithTypes(
    organizationId,
    user.id
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasUnsavedGeneralData, setHasUnsavedGeneralData] = useState(false);
  const [hasUnsavedChangesOnPasswordChange, setHasUnsavedChangesOnPasswordChange] = useState(false);
  const [hasUnsavedChangesOnPinChange, setHasUnsavedChangesOnPinChange] = useState(false);

  if (userLoading || userOrganizationLoading) {
    return <Loading />;
  }

  if (!user) {
    return <p>{translations.viewUserPage.missingUser}</p>;
  }

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasUnsavedGeneralData || hasUnsavedChangesOnPasswordChange}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.viewUserPage.createdEntity)}
    >
      <PageHeader title={translations.userSettings.title} />
      <WidthRestrictor>
        <UserDetails
          user={user}
          userOrganizations={userOrganizations ?? undefined}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          hasUnsavedData={hasUnsavedGeneralData}
          setHasUnsavedData={setHasUnsavedGeneralData}
        />
        <Divider orientation='left' plain>
          {translations.auth.changePassword}
        </Divider>
        <ChangePassword
          hasUnsavedChanges={hasUnsavedChangesOnPasswordChange}
          setHasUnsavedChanges={setHasUnsavedChangesOnPasswordChange}
          setIsSaving={setIsSaving}
        />
        <Divider orientation='left' plain>
          {translations.auth.changePin}
        </Divider>
        <ChangePin
          hasUnsavedChanges={hasUnsavedChangesOnPinChange}
          setHasUnsavedChanges={setHasUnsavedChangesOnPinChange}
          setIsSaving={setIsSaving}
        />
      </WidthRestrictor>
    </SaveSpinnerAndNavigationWarning>
  );
};
