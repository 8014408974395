import gql from 'graphql-tag';
import {
  BreedFields,
  ColorFields,
  ContactAlertTypeFields,
  ContactStatusTypeFields,
  ContactTypeFields,
  FileTypeFields,
  GenderFields,
  InfoTypeFields,
  NoteTypeFields,
  PatientAlertTypeFields,
  ReferenceDataFields,
  ReminderStatusFields,
  SpeciesFields,
  UserTypeFields,
} from '../fragments';
import {
  FinancialGeneralLedgerFields,
  FinancialTaxTypeFields,
  GeneralLedgerTypeFields,
  LedgerTypeFields,
  PaymentTypeFields,
} from '../fragments/organizationReferenceDataFragments';

export const UpsertOrganizationReferenceData = gql`
  mutation UpsertOrganizationReferenceData($organizationId: ID!, $organization: OrganizationUpsert!) {
    upsertOrganization(organizationId: $organizationId, organization: $organization) {
      id
      ref_contact {
        id
        type {
          ...ContactTypeFields
        }
        status_type {
          ...ContactStatusTypeFields
        }
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        alert_type {
          ...ContactAlertTypeFields
        }
      }
      ref_patient {
        id
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        species {
          ...SpeciesFields
        }
        breed {
          ...BreedFields
        }
        gender {
          ...GenderFields
        }
        color {
          ...ColorFields
        }
        reminder_status {
          ...ReminderStatusFields
        }
        alert_type {
          ...PatientAlertTypeFields
        }
        info_type {
          ...InfoTypeFields
        }
      }
      ref_financial {
        id
        ...FinancialTaxTypeFields
        ...GeneralLedgerTypeFields
        ...FinancialGeneralLedgerFields
        ...PaymentTypeFields
        ...LedgerTypeFields
      }
      ref_user {
        type {
          ...UserTypeFields
        }
      }
    }
  }
  ${ContactTypeFields}
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${ContactAlertTypeFields}
  ${SpeciesFields}
  ${BreedFields}
  ${GenderFields}
  ${ColorFields}
  ${ReminderStatusFields}
  ${PatientAlertTypeFields}
  ${InfoTypeFields}
  ${FinancialTaxTypeFields}
  ${GeneralLedgerTypeFields}
  ${FinancialGeneralLedgerFields}
  ${PaymentTypeFields}
  ${LedgerTypeFields}
  ${UserTypeFields}
`;

export const GetReferenceData = gql`
  query getReferenceData($organizationId: ID!) {
    getReferenceData(organizationId: $organizationId) {
      ...ReferenceDataFields
    }
  }
  ${ReferenceDataFields}
`;
