import { Button } from 'antd';
import React from 'react';
import { translations } from '../../../../constants/translations';

export const CreditCardProcessFooter: React.FC<{ onProcess: () => void; onCancel: () => void }> = ({
  onProcess,
  onCancel,
}) => {
  return (
    <>
      <Button key='back' onClick={onCancel}>
        {translations.shared.backButtonText}
      </Button>
      <Button key='submit' type='primary' htmlType='submit' onClick={onProcess}>
        {translations.shared.confirmButtonText}
      </Button>
    </>
  );
};
