import styled from 'styled-components';
import { Input, Tag } from 'antd';

export const TitleListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TitleListHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledTag = styled(Tag)`
  font-weight: 400;
  margin-bottom: 5px;
`;

export const DateContainer = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
`;

export const StickyDiv = styled.div`
  position: sticky;
  top: 30px;
`;

export const StickyDivWrapper = styled.div`
  flex-grow: 1;
  margin: 0 20px;
  min-width: 50%;
`;

export const SearchInput = styled(Input)`
  width: 300px;
  margin: 14px 0 14px 14px;
`;

export const DivDirection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FloatRightWrapper = styled.div`
  display: flex;
  padding: 13px;
  float: right;
`;
