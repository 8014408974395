import { SERVICE_DATE, SERVICE_DOCTOR } from '../../../constants/sessionStorageKeys';
import dayjs from 'dayjs';
import {
  Caregiver,
  Invoice,
  InvoiceContact,
  Mutation,
  Service,
  ServiceRendered,
  ServiceRenderedGroupRecordUpsert,
} from '../../../graph/types';
import { SelectOptions } from '../../../components/DependentDropdown/DependentDropdown';
import { maxBy, omit, orderBy } from 'lodash';
import { AddServiceFormValues } from '../../../components/ServiceRendered/AddServiceForm/AddServiceForm';
import { VaccineModalFormValues } from '../../../components/VaccineModalContent/VaccineModalContent';
import { ControlledDrugModalFormValue } from '../../../components/ControlledDrugContent/ControlledDrugContent';
import { ReminderFormValues } from '../../../components/ReminderFormFields/ReminderFormFields';
import { formats } from '../../../constants/formats';
import { PrescriptionType } from '../../../constants/prescriptions';
import { PrescriptionModalFormValues } from '../../../components/PrescriptionModalContent/PrescriptionModalContent.types';
import { ApolloCache, Reference } from '@apollo/client';
import { BasicServicesRenderedFields } from '../../../graph/fragments/invoiceFragments';
import {
  HisaFormFieldsValues,
  HisaRequirementsNameKey,
  HisaRequirementsTypeId,
  hisaFormFieldsConfig,
} from '../../../constants/hisaRequirements';
import { v4 as uuidv4 } from 'uuid';

export interface ServiceRenderedWithLocalFields extends ServiceRendered {
  cacheLoading: boolean;
}

export const getDefaultDoctorId = (
  doctorOptions: SelectOptions[],
  currentUserId: string,
  servicesRendered: ServiceRendered[]
): string | null | undefined => {
  const doctorIdOfLatestService = maxBy(servicesRendered, 'id')?.doctor_id;
  const lastUsedDoctorId = sessionStorage.getItem(SERVICE_DOCTOR);
  return (
    validateOptionValue(currentUserId, doctorOptions) ??
    validateOptionValue(doctorIdOfLatestService, doctorOptions) ??
    validateOptionValue(lastUsedDoctorId, doctorOptions)
  );
};

const validateOptionValue = (optionValue: string | null | undefined, options: SelectOptions[]) => {
  return options.find(({ value }) => value === optionValue)?.value;
};

export const getDefaultServiceDate = (rememberDate = true) => {
  const dateInStorage = rememberDate ? sessionStorage.getItem(SERVICE_DATE) : undefined;
  return dateInStorage ? dayjs(dateInStorage) : dayjs();
};

export const findNewServiceRendered = (
  updatedInvoice: Invoice | null | undefined,
  previousServicesRendered: ServiceRendered[]
) => {
  const existingServiceRenderedIds = previousServicesRendered.map((service) => service.id);
  return updatedInvoice?.item?.find((service) => !existingServiceRenderedIds.includes(service.id));
};

export const getContactUpsert = ({ contact_id, primary }: Partial<InvoiceContact>, percentage: number) => {
  return {
    record: {
      contact_id,
      offline_contact_id: undefined,
      percentage: (percentage ?? 0).toString(),
      primary: primary ?? false,
    },
  };
};

export const getHisaValuesAndText = (dummyId: string, hisaValues?: HisaFormFieldsValues) => {
  const hisaText = !!hisaValues?.hisaResult
    ? [
        {
          id: dummyId,
          type_id: HisaRequirementsTypeId.Results as string,
          name_key: HisaRequirementsNameKey.Results as string,
          value: hisaValues.hisaResult,
        },
      ]
    : undefined;

  const hisaInfo =
    hisaValues &&
    Object.keys(omit(hisaValues, 'hisaResult'))
      .filter((key) => !!hisaValues[key as keyof typeof hisaValues])
      .map((key) => {
        const [requirementField] = key.split('_');
        const formConfig = hisaFormFieldsConfig[requirementField as keyof typeof hisaValues];
        return {
          id: dummyId + formConfig.typeId,
          type_id: formConfig.typeId as string,
          name_key: formConfig.nameKey as string,
          value: hisaValues[key as keyof typeof hisaValues],
        };
      });
  return { hisaText, hisaInfo };
};

export const createNewServiceForListView = (
  formValues: AddServiceFormValues,
  service: Service,
  doctor: Caregiver,
  businessCenterId: string,
  locationId: string,
  patientId: string,
  modalFormValues?: VaccineModalFormValues & ControlledDrugModalFormValue,
  reminderFormValues?: ReminderFormValues,
  prescriptionFormValues?: PrescriptionModalFormValues,
  quantity?: string,
  roaId?: string,
  roaOther?: string,
  microchip?: boolean,
  rabiesTag?: boolean,
  default_note_id?: string,
  reason?: string,
  withdrawal_period?: number,
  hide_discount?: boolean,
  bundleProperties?: ServiceRenderedGroupRecordUpsert,
  hisaValues?: HisaFormFieldsValues
): ServiceRendered => {
  const dummyId = uuidv4();
  const newQuantity = Number(quantity ?? service.quantity_default) || 1;
  const total = (newQuantity * Number(service.price)).toString();

  const { hisaInfo, hisaText } = getHisaValuesAndText(dummyId, hisaValues);

  return {
    id: dummyId,
    date: formValues.date.format(formats.date),
    doctor_id: doctor.id,
    doctor_name: doctor.name,
    name: service.name,
    quantity: newQuantity.toString(),
    unit_name: service.unit_name,
    unit_price: service.price,
    total,
    business_center_id: businessCenterId,
    location_id: locationId,
    service_id: service.id,
    list_unit_price: service.price,
    list_total: total,
    vaccine: service.vaccine,
    controlled: service.controlled,
    manufacturer_name: modalFormValues?.manufacturer,
    lot_number: modalFormValues?.lotNumber,
    serial_number: modalFormValues?.serialNumber,
    expiry_date: modalFormValues?.expiryDate?.format(formats.date),
    bottle_number: modalFormValues?.bottle_number,
    patient_id: patientId,
    roa_id: roaId,
    roa_other: roaOther,
    microchip,
    rabies_tag: rabiesTag,
    note_id: default_note_id,
    reason,
    withdrawal_period,
    withdrawal_prompt: service.withdrawal_prompt,
    hide_discount,
    ...bundleProperties,
    reminder:
      reminderFormValues?.reminderName && reminderFormValues?.date
        ? [
            {
              name: reminderFormValues?.reminderName,
              date: reminderFormValues?.date?.format(formats.date),
              note: reminderFormValues?.note,
            },
          ]
        : null,
    prescriptions:
      prescriptionFormValues?.prescribedDate &&
      prescriptionFormValues?.prescriptionExpiryDate &&
      prescriptionFormValues?.itemQuantity &&
      prescriptionFormValues?.practiceId &&
      prescriptionFormValues?.prescribedUserId
        ? [
            {
              contact_id: prescriptionFormValues?.contactId,
              patient_id: prescriptionFormValues?.prescriptionPatientId,
              prescribed_user_id: prescriptionFormValues?.prescribedUserId,
              prescribed_date: prescriptionFormValues?.prescribedDate.format(formats.date),
              prescription_expiry_date: prescriptionFormValues?.prescriptionExpiryDate.format(formats.date),
              product_expiry_date: prescriptionFormValues?.productExpiryDate?.format(formats.date),
              refills: prescriptionFormValues?.refills,
              item_description: prescriptionFormValues?.itemDescription,
              item_quantity: prescriptionFormValues?.itemQuantity,
              item_unit: prescriptionFormValues?.itemUnit,
              instructions: prescriptionFormValues?.instructions,
              practice_id: prescriptionFormValues?.practiceId,
              fill_externally: false,
              created_type_id: PrescriptionType.Invoice,
            },
          ]
        : undefined,
    text: hisaText,
    info: hisaInfo,
  } as ServiceRendered;
};

export const sortServicesByDateAndSortOrder = (updatedServices: ServiceRendered[]): ServiceRendered[] => {
  return orderBy(updatedServices, ['date', 'sort_order'], ['asc', 'desc']);
};

export const isItemReorderAllowed = (servicesRendered: ServiceRendered[], oldIndex: number, newIndex: number) => {
  const serviceRenderedDate = servicesRendered[oldIndex].date;
  const filtered = servicesRendered.filter((sr) => sr.date === serviceRenderedDate);

  // If there are not at least 2 records it cannot be moved
  if (filtered.length < 2) {
    return false;
  }

  const allowedInNewIndex = servicesRendered[newIndex].date === serviceRenderedDate;

  if (newIndex === 0 || newIndex === servicesRendered.length - 1) {
    return allowedInNewIndex;
  }

  if (oldIndex < newIndex) {
    return allowedInNewIndex || servicesRendered[newIndex + 1].date === serviceRenderedDate;
  }

  return allowedInNewIndex || servicesRendered[newIndex - 1].date === serviceRenderedDate;
};

export const updateServiceRenderedToCache = (
  cache: ApolloCache<Pick<Mutation, 'upsertInvoice'>>,
  unsavedServicesRendered: ServiceRendered[],
  previousItems: ServiceRendered[]
) => {
  const updatedItems = [...previousItems];
  unsavedServicesRendered.forEach((sR) => {
    const data: ServiceRenderedWithLocalFields = {
      __typename: 'ServiceRendered',
      ...sR,
      is_parent: sR.is_parent ?? false,
      roa_id: sR.roa_id ?? null,
      roa_other: sR.roa_other ?? null,
      note_id: sR.note_id ?? null,
      unit_name: sR.unit_name ?? null,
      bottle_number: sR.bottle_number ?? null,
      reason: sR.reason ?? null,
      discount: sR.discount ?? null,
      discount_percent: sR.discount_percent ?? null,
      prescriptions: sR.prescriptions ?? null,
      lab_request: sR.lab_request ?? null,
      lab_status: sR.lab_status ?? null,
      grouping_id: sR.grouping_id ?? null,
      invoice_id: sR.invoice_id ?? null,
      service_name: sR.service_name ?? null,
      hidden: sR.hidden ?? false,
      withdrawal_period: sR.withdrawal_period ?? null,
      expiry_date: sR.expiry_date ?? null,
      serial_number: sR.serial_number ?? null,
      lot_number: sR.lot_number ?? null,
      manufacturer_name: sR.lot_number ?? null,
      cacheLoading: true,
      third_party: sR.third_party ?? null,
    };

    const fragment = {
      data,
      fragment: BasicServicesRenderedFields,
      fragmentName: 'BasicServicesRenderedFields',
      id: data.id,
      void: data.hidden,
    };

    cache.writeFragment(fragment) as Reference;
    const foundItemIndex = previousItems.findIndex(({ id }) => id === sR.id);
    if (foundItemIndex >= 0) {
      updatedItems[foundItemIndex] = data;
    } else {
      updatedItems.push(data);
    }
  });
  return updatedItems.filter(({ hidden }) => !hidden);
};

export const getOptimisticResponseForServiceRenderedChanges = (
  cache: ApolloCache<Pick<Mutation, 'upsertInvoice'>>,
  unsavedServicesRendered: ServiceRendered[],
  invoice: Invoice
): Pick<Mutation, 'upsertInvoice'> => {
  const normalizedServicesRendered = updateServiceRenderedToCache(cache, unsavedServicesRendered, invoice.item ?? []);
  return {
    upsertInvoice: {
      ...invoice,
      id: invoice.id,
      __typename: 'Invoice',
      item: normalizedServicesRendered,
    },
  };
};
