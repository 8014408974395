import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetReport } from '../../graph/queries/reports';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { QueryGetReportArgs } from '../../graph/types';
import { translations } from '../../constants/translations';
import { Attachment } from '../../components/EmailModal/EmailModal';
import { openTabWithPopupDetection } from '../../util/popupUtil';

export const getGetReportVariables = (
  parameters: Record<string, string>,
  reportName: string,
  practiceId: string,
  organizationId: string,
  fileName: string,
  returnPreSignedUrl?: boolean
): QueryGetReportArgs => ({
  parameters: JSON.stringify(parameters, Object.keys(parameters).sort()),
  reportName,
  request: {
    practiceId,
    organizationId,
    fileName,
    returnPreSignedUrl: returnPreSignedUrl ? 'true' : undefined,
  },
});

export const getReportAsEmailAttachment = async (variables: QueryGetReportArgs) => {
  let attachment: Attachment | undefined = undefined;
  await getReportUrl(variables, (url) => {
    attachment = {
      file_pointer: url,
      file_name: variables.request.fileName ?? '',
    };
  });
  return attachment;
};

export const printReport = async (variables: QueryGetReportArgs) => {
  await getReportUrl(variables, (url) =>
    openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport)
  );
};

const getReportUrl = async (variables: QueryGetReportArgs, onSuccess: (url: string) => void) => {
  try {
    const { data } = await AppSyncService.client.query({
      query: GetReport,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.getReport?.pointer) {
      const pointer = data.getReport.pointer;

      onSuccess(pointer);
    } else {
      showErrorMessage(translations.reports.errorGeneratingReport);
    }
  } catch (err) {
    showErrorMessage(err.message ? err.message : translations.reports.errorGeneratingReport);
  }
};
