import { translations } from '../translations';

export enum OrganizationSubscriptionLevelNameKeys {
  SubLevelAdmin = 'SUBLVL_ADMIN',
  OfflineLevel = 'USER_ADD_OFFLINE',
  PaymentPortalLevel = 'ORG_ADD_PAYMENT_PORTAL',
  AntechLabLevel = 'ORG_ADD_ANTECH',
  EMRLevel = 'ORG_ADD_EMR',
  VetConnectLabLevel = 'ORG_ADD_VETCONNECT',
  QuickBooksLevel = 'ORG_ADD_QUICKBOOKS',
  HisaLevel = 'ORG_ADD_HISA',
}

export interface OrganizationSubscriptionLevelConfig {
  text?: string;
  level_id: string;
}

export const organizationSubscriptionLevelConfigs: {
  [nameKey in OrganizationSubscriptionLevelNameKeys]: OrganizationSubscriptionLevelConfig;
} = {
  [OrganizationSubscriptionLevelNameKeys.SubLevelAdmin]: {
    text: translations.subscriptionUpdateModal.admin,
    level_id: '1',
  },
  [OrganizationSubscriptionLevelNameKeys.VetConnectLabLevel]: {
    text: translations.subscriptionUpdateModal.vetConnect,
    level_id: '100',
  },
  [OrganizationSubscriptionLevelNameKeys.OfflineLevel]: {
    text: translations.subscriptionUpdateModal.allowOffline,
    level_id: '200',
  },
  [OrganizationSubscriptionLevelNameKeys.PaymentPortalLevel]: {
    text: translations.subscriptionUpdateModal.paymentPortal,
    level_id: '300',
  },
  [OrganizationSubscriptionLevelNameKeys.AntechLabLevel]: {
    text: translations.subscriptionUpdateModal.antechLab,
    level_id: '400',
  },
  [OrganizationSubscriptionLevelNameKeys.EMRLevel]: {
    text: translations.subscriptionUpdateModal.emr,
    level_id: '500',
  },
  [OrganizationSubscriptionLevelNameKeys.QuickBooksLevel]: {
    text: translations.subscriptionUpdateModal.quickbooks,
    level_id: '600',
  },
  [OrganizationSubscriptionLevelNameKeys.HisaLevel]: {
    text: translations.subscriptionUpdateModal.hisa,
    level_id: '700',
  },
};

export enum OrganizationSubscriptionTypeNameKeys {
  SubTypeCassadol = 'SUBTYPE_CASSADOL',
}

export interface OrganizationSubscriptionTypeConfig {
  text: string;
  type_id: string;
}
