import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { Container } from '../ReminderRuns/ReminderRuns.styles';
import { financialPeriodsStatusNameKeyTranslation, translations } from '../../constants/translations';
import { FlexContainer, TitleContainer } from '../../globalStyles.style';
import { Button, DatePicker, Form, FormProps, Table, TableColumnsType } from 'antd';
import { getRequiredRule } from '../../util/forms';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { useUpsertFinancialPeriod, useGetFinancialPeriods } from '../../hooks/ajax/journals/journalHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { FinancialPeriod, Journal } from '../../graph/types';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { displayAsDate, upsertDateFormat } from '../../constants/formats';
import { Loading } from '../../components/Loading/Loading';
import { SpaceBetweenWrapper } from '../StatementRuns/StatementRunsTabContent.style';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import { basicFinancialPeriodColumns } from './financialPeriodColumns';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useLDFlag } from '../../hooks/useLDHooks';
import styled from 'styled-components';
import {
  OrganizationSubscriptionLevelNameKeys,
  organizationSubscriptionLevelConfigs,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import { useOrganizationContext } from '../../contexts/organization/state';
import {
  useDeleteJournalQuickbooks,
  useUpsertCreateFinancialCloseJournal,
  useUpsertQuickBooksAuth,
  useUpsertSendJournalQuickbooks,
} from '../../hooks/ajax/quickBooks/quickBooksHooks';
import { DropdownButtonWithMenu, MenuItemProps } from '../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import './FinancialPeriods.css';
import useQuickBooksAuth from '../../components/QuickBooksAuth/QuickBooksAuthUtils';
import { JournalModal } from './JournalModal';
import { QuickBooksAuth } from '../../components/QuickBooksAuth/QuickBooksAuth';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const ExplainerWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
`;

const PeriodsListItem = styled.li`
  margin-left: 1.3em;
  margin-bottom: 0.5em;

  &:last-child {
    margin-top: 1.5em;
  }
`;

const PeriodsItem = styled.p`
  &:last-child {
    margin-top: 1.5em;
  }
`;
export interface FinancialsDateForm {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

const JournalEntriesTitleContainer = styled(TitleContainer)`
  padding-left: 50px;
`;

export const endDateTestId = 'endDateTestId';

export const FinancialPeriods: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm<{ date: dayjs.Dayjs }>();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const {
    state: { organization },
  } = useOrganizationContext();

  const practiceId = useDefaultPracticeId();
  const organizationId = useGetOrganizationIdFromRoute();

  const { needsQbAuth, qbAuthCancelled, cancelQuickBooksAuth, setNeedsQbAuth, setQbAuthCancelled } =
    useQuickBooksAuth();
  const [sendToQBRequired, setSendToQBRequired] = useState(false);
  const [journalToSend, setJournalToSend] = useState<Journal | undefined>(undefined);

  const [isCreateAndSendAction, setCreateAndSendAction] = useState(false);
  const [financialPeriodToSend, setFinancialPeriodToSend] = useState<FinancialPeriod | undefined>(undefined);

  const [isDeleteAction, setDeleteAction] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState<Journal | undefined>(undefined);
  const [selectedJournalEntry, setSelectedJournalEntry] = useState<Journal>();

  const [upsertFinancialPeriod] = useUpsertFinancialPeriod();
  const quickBooksAuth = useUpsertQuickBooksAuth();
  const createFinancialCloseJournal = useUpsertCreateFinancialCloseJournal();
  const sendJournalQuickbooks = useUpsertSendJournalQuickbooks();
  const deleteJournalQuickbooks = useDeleteJournalQuickbooks();
  const [handleFinish, setHandleFinish] = useState(false);

  const { financialPeriods, financialPeriodsLoading, financialPeriodRefetch } = useGetFinancialPeriods(
    organizationId,
    practiceId,
    {}
  );

  const enabledFinancialsPage = useLDFlag(LDFlagNames.Financials);
  const isQuickBooksFlagOn = useLDFlag(LDFlagNames.QuickBooks);
  const quickbooksAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.QuickBooksLevel].level_id
      ),
    [organization]
  );
  const isQuickBooksAligned = useMemo(
    () => (isQuickBooksFlagOn && quickbooksAddon?.enabled) ?? false,
    [isQuickBooksFlagOn, quickbooksAddon?.enabled]
  );

  const initialValues = {
    date: dayjs(),
  };

  const handleErrors = (error: Error) => {
    if (error.message.startsWith('DATA_MISSING_GL_ACCOUNTS')) {
      const accountsCount = error.message.replace('DATA_MISSING_GL_ACCOUNTS', ' ');
      showErrorMessage(translations.financialPeriods.DATA_MISSING_GL_ACCOUNTS + accountsCount);
    } else if (error.message.startsWith('DATA_MISSING_TAX_GL_ACCOUNTS')) {
      const taxAccountsCount = error.message.replace('DATA_MISSING_TAX_GL_ACCOUNTS', ' ');
      showErrorMessage(translations.financialPeriods.DATA_MISSING_TAX_GL_ACCOUNTS + taxAccountsCount);
    } else if (error.message === 'MISSING_GL_WITH_AR_FLAG' || error.message === 'FINANCIAL_PERIOD_JOURNAL_NO_ROWS') {
      showErrorMessage(translations.financialPeriods[error.message]);
    } else {
      showErrorMessage(error.message ?? error);
    }
  };

  const isDateOverlap = (selectedDate: dayjs.Dayjs): boolean => {
    const hasLaterDate = (period: FinancialPeriod) => dayjs.utc(period.end_date).isSameOrAfter(selectedDate, 'day');
    return !!financialPeriods && financialPeriods.some(hasLaterDate);
  };

  const handleNewPeriod: FormProps['onFinish'] = async (values: FinancialsDateForm) => {
    setIsSaving(true);
    if (isDateOverlap(values.endDate)) {
      setIsSaving(false);
      showErrorMessage(translations.financialPeriods.overlap);
      return;
    }

    if (financialPeriods && financialPeriods.length === 0) {
      await upsertFinancialPeriod({
        variables: {
          organizationId,
          financialPeriod: {
            record: {
              practice_id: practiceId,
              end_date: values.startDate.subtract(1, 'day').format(upsertDateFormat),
            },
          },
        },
      });
    }

    await upsertFinancialPeriod({
      variables: {
        organizationId,
        financialPeriod: {
          record: {
            practice_id: practiceId,
            end_date: values.endDate.format(upsertDateFormat),
          },
        },
      },
    });

    financialPeriodRefetch();

    setIsSaving(false);
  };

  const handleDeletePeriod = useCallback(
    async (record: FinancialPeriod) => {
      setIsSaving(true);
      await upsertFinancialPeriod({
        variables: {
          organizationId,
          financialPeriod: {
            id: record.id,
            void: true,
          },
        },
      });

      financialPeriodRefetch();

      setIsSaving(false);
    },
    [financialPeriodRefetch, organizationId, upsertFinancialPeriod]
  );

  const internalCreate = useCallback(
    async (record: FinancialPeriod) => {
      setIsSaving(true);
      try {
        const journal = await createFinancialCloseJournal(organizationId, record.id);

        if (!journal) {
          showErrorMessage(translations.shared.generalErrorMessage);
          setIsSaving(false);
          return;
        }

        showSuccessMessage(translations.shared.saveSuccessMessage);

        await financialPeriodRefetch();
      } catch (err) {
        const error = err as Error;
        handleErrors(error);
      }
      setIsSaving(false);
    },
    [createFinancialCloseJournal, financialPeriodRefetch, organizationId]
  );

  const internalSendJournalToQuickbooks = useCallback(
    async (journal?: Journal) => {
      if (!journal) {
        showErrorMessage(translations.shared.generalErrorMessage);
        return;
      }

      setIsSaving(true);

      try {
        const sendJournalResult = await sendJournalQuickbooks(organizationId, practiceId, {
          journalId: journal?.id,
          note: '',
          postingDate: dayjs(new Date()).format(upsertDateFormat),
        });

        if (sendJournalResult.errorMessage) {
          setSendToQBRequired(false);
          showErrorMessage(sendJournalResult.errorMessage);
        } else if (sendJournalResult.redirectUrl) {
          setCreateAndSendAction(false);
          setFinancialPeriodToSend(undefined);

          setNeedsQbAuth(true);
          setSendToQBRequired(true);
          setJournalToSend(journal);
        } else {
          setSendToQBRequired(false);
          setJournalToSend(undefined);

          setCreateAndSendAction(false);
          setFinancialPeriodToSend(undefined);

          showSuccessMessage(translations.shared.saveSuccessMessage);
          await financialPeriodRefetch();
        }
      } catch (err) {
        showErrorMessage((err as Error).message ?? err);
      }
      setIsSaving(false);
    },
    [financialPeriodRefetch, organizationId, practiceId, sendJournalQuickbooks, setNeedsQbAuth]
  );

  const internalCreateAndSend = useCallback(
    async (record: FinancialPeriod) => {
      setIsSaving(true);
      try {
        const journal = await createFinancialCloseJournal(organizationId, record.id);

        if (journal) {
          internalSendJournalToQuickbooks(journal);
        } else {
          showErrorMessage(translations.shared.generalErrorMessage);
          return;
        }

        showSuccessMessage(translations.shared.saveSuccessMessage);

        await financialPeriodRefetch();
      } catch (err) {
        const error = err as Error;
        handleErrors(error);
      }
      setIsSaving(false);
    },
    [createFinancialCloseJournal, financialPeriodRefetch, internalSendJournalToQuickbooks, organizationId]
  );

  const handleCreateAndSendClick = useCallback(
    async (period: FinancialPeriod) => {
      setQbAuthCancelled(false);
      setCreateAndSendAction(true);
      setFinancialPeriodToSend(period);

      setIsSaving(true);
      const qbAuthResponse = await quickBooksAuth(organizationId);
      if (qbAuthResponse.redirectUrl) {
        setNeedsQbAuth(true);
        setIsSaving(false);
      } else {
        internalCreateAndSend(period);
      }
    },
    [internalCreateAndSend, organizationId, quickBooksAuth, setNeedsQbAuth, setQbAuthCancelled]
  );

  const internalDeleteJournal = useCallback(
    async (record?: Journal) => {
      if (!record) {
        showErrorMessage(translations.shared.generalErrorMessage);
        return;
      }

      setIsSaving(true);
      try {
        const result = await deleteJournalQuickbooks(organizationId, practiceId, {
          journalId: record.id,
        });

        if (result.errorMessage) {
          setDeleteAction(false);
          setJournalToDelete(undefined);
          showErrorMessage(result.errorMessage);
        } else if (result.redirectUrl) {
          setDeleteAction(true);
          setJournalToDelete(record);
          setNeedsQbAuth(true);
        } else {
          setDeleteAction(false);
          setJournalToDelete(undefined);

          showSuccessMessage(translations.shared.saveSuccessMessage);
          await financialPeriodRefetch();
        }
      } catch (err) {
        showErrorMessage((err as Error).message ?? err);
      }
      setIsSaving(false);
    },
    [deleteJournalQuickbooks, organizationId, practiceId, setNeedsQbAuth, financialPeriodRefetch]
  );

  const handleDeleteJournalClick = useCallback(
    async (record?: Journal) => {
      setQbAuthCancelled(false);
      setDeleteAction(true);
      setJournalToDelete(record);

      setIsSaving(true);
      const qbAuthResponse = await quickBooksAuth(organizationId);
      if (qbAuthResponse.redirectUrl) {
        setNeedsQbAuth(true);
        setIsSaving(false);
      } else {
        internalDeleteJournal(record);
      }
    },
    [setQbAuthCancelled, quickBooksAuth, organizationId, setNeedsQbAuth, internalDeleteJournal]
  );

  const handleCloseQbAuthModal = useCallback(() => {
    cancelQuickBooksAuth();

    setCreateAndSendAction(false);
    setFinancialPeriodToSend(undefined);

    setDeleteAction(false);
    setJournalToDelete(undefined);

    setSendToQBRequired(false);
    setJournalToSend(undefined);
  }, [cancelQuickBooksAuth]);

  const handleFinishQbAuth = useCallback(() => {
    setHandleFinish(true);
  }, []);

  const QuickBooksAuthComponent = useMemo(
    () => (
      <QuickBooksAuth organizationId={organizationId} onClose={handleCloseQbAuthModal} onFinish={handleFinishQbAuth} />
    ),
    [handleCloseQbAuthModal, handleFinishQbAuth, organizationId]
  );

  useEffect(() => {
    if (handleFinish && !qbAuthCancelled) {
      if (isCreateAndSendAction && financialPeriodToSend) {
        internalCreateAndSend(financialPeriodToSend);
      }
      if (sendToQBRequired && journalToSend) {
        internalSendJournalToQuickbooks(journalToSend);
      }
      if (isDeleteAction && journalToDelete) {
        internalDeleteJournal(journalToDelete);
      } else {
        handleCloseQbAuthModal();
      }
    }
    setHandleFinish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFinish, qbAuthCancelled]);

  const renderActionCell = useCallback(
    (record: FinancialPeriod) => {
      const buttons: MenuItemProps[] = [
        {
          title: translations.financialPeriods.createJournal,
          onClick: () => internalCreate(record),
        },
        {
          title: translations.financialPeriods.createAndSend,
          disabled: !isQuickBooksAligned,
          onClick: () => handleCreateAndSendClick(record),
        },
        {
          title: translations.financialPeriods.deletePeriod,
          disabled: !!record?.journal && record.journal?.length > 0,
          tooltip:
            !!record?.journal && record.journal?.length > 0
              ? translations.financialPeriods.deletePeriodDisabled
              : undefined,
          onClick: () => handleDeletePeriod(record),
          popconfirmProps: {
            title: translations.financialPeriods.deletePeriodConfirm,
            okText: translations.shared.popconfirm.ok,
            cancelText: translations.shared.popconfirm.no,
          },
        },
      ];

      return <DropdownButtonWithMenu menuItemProps={buttons} />;
    },
    [handleCreateAndSendClick, handleDeletePeriod, internalCreate, isQuickBooksAligned]
  );

  const renderInternalActionCell = useCallback(
    (record: Journal) => {
      const buttons: MenuItemProps[] = [
        {
          title: translations.financialPeriods.viewJournal,
          onClick: () => setSelectedJournalEntry(record),
          disabled: !record.details || record.details.length === 0,
        },
        {
          title: translations.financialPeriods.deleteJournal,
          disabled: !isQuickBooksAligned || !record,
          onClick: () => handleDeleteJournalClick(record),
          tooltip: !isQuickBooksAligned || !record ? translations.financialPeriods.deleteJournalDisabled : undefined,
          popconfirmProps: {
            title: record?.sent_date
              ? translations.financialPeriods.deleteJournalPopConfirm.sent
              : translations.financialPeriods.deleteJournalPopConfirm.pending,
            okText: translations.shared.popconfirm.ok,
            cancelText: translations.shared.popconfirm.no,
          },
        },
        {
          title: translations.financialPeriods.sendJournal,
          disabled: !isQuickBooksAligned || !record || !!record?.sent_date,
          onClick: () => internalSendJournalToQuickbooks(record),
        },
      ];

      return <DropdownButtonWithMenu menuItemProps={buttons} />;
    },
    [handleDeleteJournalClick, internalSendJournalToQuickbooks, isQuickBooksAligned]
  );

  if (!enabledFinancialsPage) {
    return <p>{translations.financialPeriods.pageNotEnabled}</p>;
  }

  if (financialPeriodsLoading) {
    return <Loading />;
  }

  const handleExpandedRowRender = (financialPeriod: FinancialPeriod) => {
    const journalEntries =
      financialPeriod.journal
        ?.filter((journalEntry) => !!journalEntry)
        ?.map((journalEntry) => journalEntry as Journal) ?? [];

    const journalColumns: TableColumnsType<Journal> = [
      { width: 50, render: () => '' },
      {
        title: translations.financialPeriods.journalEntries.columns.endDate,
        key: 'end_date',
        width: 200,
        render: () => displayAsDate(financialPeriod.end_date ?? '', dateFormat),
      },
      {
        title: translations.financialPeriods.journalEntries.columns.number,
        key: 'number',
        width: 200,
        dataIndex: 'number',
      },
      { title: translations.financialPeriods.journalEntries.columns.note, key: 'note', width: 300, dataIndex: 'note' },
      {
        title: translations.financialPeriods.journalEntries.columns.status,
        key: 'send_status_description',
        width: 250,
        dataIndex: 'send_status_description',
      },
      {
        title: translations.financialPeriods.journalEntries.columns.sentDate,
        key: 'sent_date',
        width: 200,
        render: (_, record) => record?.sent_date && displayAsDate(record.sent_date ?? '', dateFormat),
      },
      {
        title: translations.financialPeriods.journalEntries.columns.actions,
        key: 'actions',
        render: (_, record) => renderInternalActionCell(record),
      },
    ];

    return (
      <>
        <JournalEntriesTitleContainer>
          {translations.financialPeriods.journalEntries.title}
        </JournalEntriesTitleContainer>
        <Table dataSource={journalEntries} columns={journalColumns} rowKey={'id'} pagination={false} />
      </>
    );
  };

  const columns: CustomColumnType<FinancialPeriod>[] = [
    {
      ...basicFinancialPeriodColumns.begin_date,
      render: (date: string) => displayAsDate(date, dateFormat),
      width: 500,
    },
    {
      ...basicFinancialPeriodColumns.end_date,
      render: (date: string) => displayAsDate(date, dateFormat),
      width: 500,
    },
    {
      ...basicFinancialPeriodColumns.status_name_key,
      render: (data: string) => financialPeriodsStatusNameKeyTranslation(data),
      width: 450,
      hidden: true,
    },
    {
      title: translations.financialPeriods.columns.actions,
      key: 'actions',
      render: (record: FinancialPeriod) => renderActionCell(record),
    },
  ];

  return (
    <SaveSpinner isSaving={isSaving || financialPeriodsLoading}>
      <StyledPageHeaderWithMargin title={translations.financialPeriods.title} />
      <ExplainerWrapper>
        <ul>
          {translations.financialPeriods.explainerVerbiageParagraphs.map((line, index) => (
            <>
              {index < 2 || index === translations.financialPeriods.explainerVerbiageParagraphs.length - 1 ? (
                <PeriodsItem key={index}>{line}</PeriodsItem>
              ) : (
                <PeriodsListItem key={index}> {line} </PeriodsListItem>
              )}
            </>
          ))}
        </ul>
      </ExplainerWrapper>
      <Container>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleNewPeriod}
          layout='inline'
          style={{ marginBottom: '0.5rem' }}
          autoComplete='off'
        >
          <FlexContainer>
            {(!financialPeriods || financialPeriods.length === 0) && (
              <Form.Item
                label={translations.financialPeriods.startDateLabel}
                name='startDate'
                rules={[getRequiredRule(translations.financialPeriods.selectDate)]}
              >
                <DatePicker
                  format={dateFormat}
                  placeholder={translations.financialPeriods.selectDate}
                  disabledDate={(date: dayjs.Dayjs) => date.isAfter(dayjs())}
                />
              </Form.Item>
            )}
            <Form.Item
              label={translations.financialPeriods.endDateLabel}
              name='endDate'
              rules={[getRequiredRule(translations.financialPeriods.selectDate)]}
              data-testid={endDateTestId}
            >
              <DatePicker
                format={dateFormat}
                placeholder={translations.financialPeriods.selectDate}
                disabledDate={(date: dayjs.Dayjs) => date.isAfter(dayjs())}
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isSaving || financialPeriodsLoading} htmlType={'submit'} type={'primary'}>
                {translations.financialPeriods.createPeriod}
              </Button>
            </Form.Item>
          </FlexContainer>
        </Form>
      </Container>
      <SpaceBetweenWrapper>
        <TableWithCustomFiltering<FinancialPeriod>
          tableKey={TableKey.FinancialPeriods}
          columns={columns}
          dataSource={financialPeriods || undefined}
          rowKey={'id'}
          style={{ width: '100%' }}
          expandable={{
            rowExpandable: (record: FinancialPeriod) => !!record.journal,
            expandedRowRender: handleExpandedRowRender,
            columnWidth: 50,
            expandedRowClassName: () => 'journalEntriesExpandableRow',
          }}
        />
      </SpaceBetweenWrapper>
      {needsQbAuth && QuickBooksAuthComponent}
      {selectedJournalEntry?.details && selectedJournalEntry.details.length > 0 && (
        <JournalModal
          details={selectedJournalEntry.details}
          journalNumber={selectedJournalEntry.number ?? ''}
          onClose={() => setSelectedJournalEntry(undefined)}
        />
      )}
    </SaveSpinner>
  );
};
