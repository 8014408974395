import React from 'react';
import { ComponentWithPracticeProps } from '../../../components/WithPractice/WithPractice';
import { serviceMappingTranslations } from '../../../util/labUtil';
import { MappingTabHeader } from '../MappingTabHeader';
import { ServiceMappingTable } from './ServiceMappingTable';

export const labSelectTestId = 'lab-select-test-id';

export const ServiceMapTab: React.FC<ComponentWithPracticeProps> = ({ practice }) => {
  return (
    <>
      <MappingTabHeader
        practice={practice}
        instructions={serviceMappingTranslations.instructions}
        render={(selectedLab: string, organizationId: string) => (
          <ServiceMappingTable labId={selectedLab} organizationId={organizationId} />
        )}
      />
    </>
  );
};
