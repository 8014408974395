import React, { UIEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Empty } from 'antd';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import VisibilitySensor from 'react-visibility-sensor';

import { RecordItem, filterRecords, findIndexForStartingDate } from '../recordUtils';
import { Loading } from '../../Loading/Loading';
import { translations } from '../../../constants/translations';
import { RecordsContext } from '../store/state';

import { StickyDiv } from '../RecordListItem.styles';
import { FlexContainer, FlexItem } from '../../../globalStyles.style';
import './FullscreenRecordList.less';
import { Filter, Filters } from './Filters';

const FULLSCREEN_LOAD_COUNT = 2;
const AVERAGE_LIST_ITEM_HEIGHT = 200;

const initalFullScreenItemCount = Math.ceil(window.innerHeight / AVERAGE_LIST_ITEM_HEIGHT);

const SearchBarContainer = styled(FlexContainer)`
  margin: 8px;
`;

export const FullscreenRecordList = ({
  currentRecords,
  getRecordSingleView,
  loading,
}: {
  currentRecords: RecordItem[];
  getRecordSingleView: (record?: RecordItem) => React.ReactNode | null;
  loading?: boolean;
}) => {
  const [fullScreenRecords, setFullScreenRecords] = useState<RecordItem[]>([]);
  const lastScrollPosition = useRef(0);
  const [initialFullscreenLoaded, setInitialFullscreenLoaded] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const [filters, setFilters] = useState<Filter>({});
  const { state } = useContext(RecordsContext);
  const [filteredRecords, setFilteredRecords] = useState<RecordItem[]>([]);
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState<number>(initalFullScreenItemCount);
  const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setFilteredRecords(filterRecords(currentRecords, filters));
  }, [currentRecords, filters]);

  const handleFilterUpdates = useCallback(
    (updatedFilters: Partial<Filter>) => {
      setFilters((prev) => {
        return { ...prev, ...updatedFilters };
      });
    },
    [setFilters]
  );

  const loadRecords = useCallback(() => {
    setFullScreenRecords(filteredRecords.slice(0, currentDisplayIndex));
    if (!initialFullscreenLoaded) {
      setInitialFullscreenLoaded(true);
    }
  }, [filteredRecords, currentDisplayIndex, initialFullscreenLoaded]);

  const scrollToView = useCallback(
    (date: Dayjs) => {
      const elementToScroll = document.querySelectorAll(`li[data-date='${date?.format('YYYY-MM-DD')}']`)[0];
      elementToScroll?.scrollIntoView({ behavior: 'smooth' });
      handleFilterUpdates({ jumpToDate: undefined });
    },
    [handleFilterUpdates]
  );

  useEffect(() => {
    let startingIndex = -1;
    if (filters?.jumpToDate) {
      startingIndex = findIndexForStartingDate(filteredRecords, filters?.jumpToDate);
      setCurrentDisplayIndex(startingIndex + 1);
    }
    loadRecords();
    const mainContent = document.getElementsByTagName('main')[0];
    if (mainContent) {
      mainContent.setAttribute('style', 'overflow: hidden');
    }
    if (currentDisplayIndex >= startingIndex && startingIndex > -1) {
      setTimeout(() => scrollToView(filteredRecords[startingIndex]?.date), 1000);
    }
  }, [filters?.jumpToDate, filteredRecords, currentDisplayIndex, loadRecords, scrollToView]);

  const handleScroll = useCallback(
    (event: UIEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      const isScrollingDown = scrollTop > lastScrollPosition.current;
      lastScrollPosition.current = scrollTop;
      if (isScrollingDown && scrollHeight - (scrollTop + clientHeight) < 120) {
        const nextEndIndex = fullScreenRecords.length + FULLSCREEN_LOAD_COUNT;
        setCurrentDisplayIndex(nextEndIndex);
      }
    },
    [fullScreenRecords]
  );

  const toggleAllCollapses = (expand: boolean) => {
    const toggles = document.querySelectorAll('.ant-collapse-header');

    toggles.forEach((toggle) => {
      const element = toggle as HTMLElement;
      const parent = element.parentElement;

      const isExpanded = parent?.classList.contains('ant-collapse-item-active');

      if (expand && !isExpanded) {
        element.click();
      } else if (!expand && isExpanded) {
        element.click();
      }
    });
  };

  const onVisisbilityChange = (isVisible: boolean, recordId: string) => {
    setVisibilityMap((prev) => ({ ...prev, [recordId]: isVisible }));
  };

  const isJumpToDate = useCallback(
    (date: Dayjs) => {
      return filters?.jumpToDate && date.isSame(filters.jumpToDate, 'date');
    },
    [filters?.jumpToDate]
  );

  const availableDates = useMemo(() => {
    return filteredRecords.map((record) => record.date.format('YYYY-MM-DD'));
  }, [filteredRecords]);

  const fullscreenContent = useMemo(
    () => (
      <>
        <StickyDiv
          style={{
            borderBottom: '1px solid #fafafa',
          }}
        >
          {getRecordSingleView()}
        </StickyDiv>
        <ul onScroll={handleScroll} ref={listRef} className='recordList'>
          {fullScreenRecords.map((item) => (
            <li className='recordListItem' key={item.recordId} data-date={item.date.format('YYYY-MM-DD')}>
              <VisibilitySensor
                intervalDelay={1000}
                scrollCheck
                partialVisibility
                scrollDelay={800}
                active={!visibilityMap[item.recordId]}
                onChange={(isVisible: boolean) => onVisisbilityChange(isVisible, item.recordId)}
              >
                {({ isVisible }: { isVisible: boolean }) =>
                  isVisible || visibilityMap[item.recordId] || isJumpToDate(item.date) ? (
                    getRecordSingleView(item)
                  ) : (
                    <Loading height='200px' />
                  )
                }
              </VisibilitySensor>
            </li>
          ))}
          {!state.searchValue && fullScreenRecords.length !== filteredRecords.length && (
            <div>
              <Loading />
            </div>
          )}
          {(initialFullscreenLoaded || !filteredRecords.length) &&
            fullScreenRecords.length === filteredRecords.length &&
            !loading &&
            (filteredRecords.length ? (
              <li style={{ textAlign: 'center' }}>
                <strong>{translations.patientPage.medicalRecords.endOfHistory}</strong>
              </li>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ))}
        </ul>
      </>
    ),
    [
      filteredRecords,
      fullScreenRecords,
      getRecordSingleView,
      handleScroll,
      state.searchValue,
      initialFullscreenLoaded,
      loading,
      visibilityMap,
      isJumpToDate,
    ]
  );

  return (
    <>
      <SearchBarContainer direction='row' justifyContent='space-between'>
        <FlexItem flexGrow={1}>
          <Filters setFilters={handleFilterUpdates} availableDates={availableDates} />
        </FlexItem>
        <FlexContainer direction='row'>
          <Button style={{ marginRight: '8px' }} onClick={() => toggleAllCollapses(true)}>
            {translations.patientPage.medicalRecords.expandAll}
          </Button>
          <Button onClick={() => toggleAllCollapses(false)}>
            {translations.patientPage.medicalRecords.collapseAll}
          </Button>
        </FlexContainer>
      </SearchBarContainer>
      {fullscreenContent}
    </>
  );
};
