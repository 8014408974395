import { CountryId } from './countries';

export const countryCurrency = {
  [CountryId.AFG]: 'USD',
  [CountryId.ALA]: 'USD',
  [CountryId.ALB]: 'USD',
  [CountryId.DZA]: 'USD',
  [CountryId.ASM]: 'USD',
  [CountryId.AND]: 'USD',
  [CountryId.AGO]: 'USD',
  [CountryId.AIA]: 'USD',
  [CountryId.ATA]: 'USD',
  [CountryId.ATG]: 'USD',
  [CountryId.ARG]: 'USD',
  [CountryId.ARM]: 'USD',
  [CountryId.ABW]: 'USD',
  [CountryId.AUS]: 'USD',
  [CountryId.AUT]: 'USD',
  [CountryId.AZE]: 'USD',
  [CountryId.BHR]: 'USD',
  [CountryId.BGD]: 'USD',
  [CountryId.BRB]: 'USD',
  [CountryId.BLR]: 'USD',
  [CountryId.BEL]: 'USD',
  [CountryId.BLZ]: 'USD',
  [CountryId.BEN]: 'USD',
  [CountryId.BMU]: 'USD',
  [CountryId.BTN]: 'USD',
  [CountryId.BOL]: 'USD',
  [CountryId.BES]: 'USD',
  [CountryId.BIH]: 'USD',
  [CountryId.BWA]: 'USD',
  [CountryId.BVT]: 'USD',
  [CountryId.BRA]: 'USD',
  [CountryId.IOT]: 'USD',
  [CountryId.BRN]: 'USD',
  [CountryId.BGR]: 'USD',
  [CountryId.BFA]: 'USD',
  [CountryId.BDI]: 'USD',
  [CountryId.KHM]: 'USD',
  [CountryId.CMR]: 'USD',
  [CountryId.Canada]: 'CAD',
  [CountryId.CPV]: 'USD',
  [CountryId.CYM]: 'USD',
  [CountryId.CAF]: 'USD',
  [CountryId.TCD]: 'USD',
  [CountryId.CHL]: 'USD',
  [CountryId.CHN]: 'USD',
  [CountryId.CXR]: 'USD',
  [CountryId.CCK]: 'USD',
  [CountryId.COL]: 'USD',
  [CountryId.COM]: 'USD',
  [CountryId.COG]: 'USD',
  [CountryId.COK]: 'USD',
  [CountryId.CRI]: 'USD',
  [CountryId.CIV]: 'USD',
  [CountryId.HRV]: 'USD',
  [CountryId.CUB]: 'USD',
  [CountryId.CUW]: 'USD',
  [CountryId.CYP]: 'USD',
  [CountryId.CZE]: 'USD',
  [CountryId.COD]: 'USD',
  [CountryId.DNK]: 'USD',
  [CountryId.DJI]: 'USD',
  [CountryId.DMA]: 'USD',
  [CountryId.DOM]: 'USD',
  [CountryId.TLS]: 'USD',
  [CountryId.ECU]: 'USD',
  [CountryId.EGY]: 'USD',
  [CountryId.SLV]: 'USD',
  [CountryId.GNQ]: 'USD',
  [CountryId.ERI]: 'USD',
  [CountryId.EST]: 'USD',
  [CountryId.ETH]: 'USD',
  [CountryId.FLK]: 'USD',
  [CountryId.FRO]: 'USD',
  [CountryId.FJI]: 'USD',
  [CountryId.FIN]: 'USD',
  [CountryId.FRA]: 'USD',
  [CountryId.GUF]: 'USD',
  [CountryId.PYF]: 'USD',
  [CountryId.ATF]: 'USD',
  [CountryId.GAB]: 'USD',
  [CountryId.GMB]: 'USD',
  [CountryId.GEO]: 'USD',
  [CountryId.DEU]: 'USD',
  [CountryId.GHA]: 'USD',
  [CountryId.GIB]: 'USD',
  [CountryId.GRC]: 'USD',
  [CountryId.GRL]: 'USD',
  [CountryId.GRD]: 'USD',
  [CountryId.GLP]: 'USD',
  [CountryId.GUM]: 'USD',
  [CountryId.GTM]: 'USD',
  [CountryId.GGY]: 'USD',
  [CountryId.GIN]: 'USD',
  [CountryId.GNB]: 'USD',
  [CountryId.GUY]: 'USD',
  [CountryId.HTI]: 'USD',
  [CountryId.HMD]: 'USD',
  [CountryId.HND]: 'USD',
  [CountryId.HKG]: 'USD',
  [CountryId.HUN]: 'USD',
  [CountryId.ISL]: 'USD',
  [CountryId.IND]: 'USD',
  [CountryId.IDN]: 'USD',
  [CountryId.IRN]: 'USD',
  [CountryId.IRQ]: 'USD',
  [CountryId.IRL]: 'USD',
  [CountryId.ISR]: 'USD',
  [CountryId.ITA]: 'USD',
  [CountryId.JAM]: 'USD',
  [CountryId.JPN]: 'USD',
  [CountryId.JEY]: 'USD',
  [CountryId.JOR]: 'USD',
  [CountryId.KAZ]: 'USD',
  [CountryId.KEN]: 'USD',
  [CountryId.KIR]: 'USD',
  [CountryId.XKX]: 'USD',
  [CountryId.KWT]: 'USD',
  [CountryId.KGZ]: 'USD',
  [CountryId.LAO]: 'USD',
  [CountryId.LVA]: 'USD',
  [CountryId.LBN]: 'USD',
  [CountryId.LSO]: 'USD',
  [CountryId.LBR]: 'USD',
  [CountryId.LBY]: 'USD',
  [CountryId.LIE]: 'USD',
  [CountryId.LTU]: 'USD',
  [CountryId.LUX]: 'USD',
  [CountryId.MAC]: 'USD',
  [CountryId.MKD]: 'USD',
  [CountryId.MDG]: 'USD',
  [CountryId.MWI]: 'USD',
  [CountryId.MYS]: 'USD',
  [CountryId.MDV]: 'USD',
  [CountryId.MLI]: 'USD',
  [CountryId.MLT]: 'USD',
  [CountryId.IMN]: 'USD',
  [CountryId.MHL]: 'USD',
  [CountryId.MTQ]: 'USD',
  [CountryId.MRT]: 'USD',
  [CountryId.MUS]: 'USD',
  [CountryId.MYT]: 'USD',
  [CountryId.MEX]: 'USD',
  [CountryId.FSM]: 'USD',
  [CountryId.MDA]: 'USD',
  [CountryId.MCO]: 'USD',
  [CountryId.MNG]: 'USD',
  [CountryId.MNE]: 'USD',
  [CountryId.MSR]: 'USD',
  [CountryId.MAR]: 'USD',
  [CountryId.MOZ]: 'USD',
  [CountryId.MMR]: 'USD',
  [CountryId.NAM]: 'USD',
  [CountryId.NRU]: 'USD',
  [CountryId.NPL]: 'USD',
  [CountryId.NLD]: 'USD',
  [CountryId.NCL]: 'USD',
  [CountryId.NZL]: 'USD',
  [CountryId.NIC]: 'USD',
  [CountryId.NER]: 'USD',
  [CountryId.NGA]: 'USD',
  [CountryId.NIU]: 'USD',
  [CountryId.NFK]: 'USD',
  [CountryId.PRK]: 'USD',
  [CountryId.MNP]: 'USD',
  [CountryId.NOR]: 'USD',
  [CountryId.OMN]: 'USD',
  [CountryId.PAK]: 'USD',
  [CountryId.PLW]: 'USD',
  [CountryId.PSE]: 'USD',
  [CountryId.PAN]: 'USD',
  [CountryId.PNG]: 'USD',
  [CountryId.PRY]: 'USD',
  [CountryId.PER]: 'USD',
  [CountryId.PHL]: 'USD',
  [CountryId.PCN]: 'USD',
  [CountryId.POL]: 'USD',
  [CountryId.PRT]: 'USD',
  [CountryId.PRI]: 'USD',
  [CountryId.QAT]: 'USD',
  [CountryId.REU]: 'USD',
  [CountryId.ROU]: 'USD',
  [CountryId.RUS]: 'USD',
  [CountryId.RWA]: 'USD',
  [CountryId.SHN]: 'USD',
  [CountryId.KNA]: 'USD',
  [CountryId.LCA]: 'USD',
  [CountryId.SPM]: 'USD',
  [CountryId.VCT]: 'USD',
  [CountryId.BLM]: 'USD',
  [CountryId.MAF]: 'USD',
  [CountryId.WSM]: 'USD',
  [CountryId.SMR]: 'USD',
  [CountryId.STP]: 'USD',
  [CountryId.SAU]: 'USD',
  [CountryId.SEN]: 'USD',
  [CountryId.SRB]: 'USD',
  [CountryId.SYC]: 'USD',
  [CountryId.SLE]: 'USD',
  [CountryId.SGP]: 'USD',
  [CountryId.SXM]: 'USD',
  [CountryId.SVK]: 'USD',
  [CountryId.SVN]: 'USD',
  [CountryId.SLB]: 'USD',
  [CountryId.SOM]: 'USD',
  [CountryId.ZAF]: 'USD',
  [CountryId.SGS]: 'USD',
  [CountryId.KOR]: 'USD',
  [CountryId.SSD]: 'USD',
  [CountryId.ESP]: 'USD',
  [CountryId.LKA]: 'USD',
  [CountryId.SDN]: 'USD',
  [CountryId.SUR]: 'USD',
  [CountryId.SJM]: 'USD',
  [CountryId.SWZ]: 'USD',
  [CountryId.SWE]: 'USD',
  [CountryId.CHE]: 'USD',
  [CountryId.SYR]: 'USD',
  [CountryId.TWN]: 'USD',
  [CountryId.TJK]: 'USD',
  [CountryId.TZA]: 'USD',
  [CountryId.THA]: 'USD',
  [CountryId.BHS]: 'USD',
  [CountryId.TGO]: 'USD',
  [CountryId.TKL]: 'USD',
  [CountryId.TON]: 'USD',
  [CountryId.TTO]: 'USD',
  [CountryId.TUN]: 'USD',
  [CountryId.TUR]: 'USD',
  [CountryId.TKM]: 'USD',
  [CountryId.TCA]: 'USD',
  [CountryId.TUV]: 'USD',
  [CountryId.UGA]: 'USD',
  [CountryId.UKR]: 'USD',
  [CountryId.UAE]: 'USD',
  [CountryId.GBR]: 'USD',
  [CountryId.USA]: 'USD',
  [CountryId.UMI]: 'USD',
  [CountryId.URY]: 'USD',
  [CountryId.UZB]: 'USD',
  [CountryId.VUT]: 'USD',
  [CountryId.VAT]: 'USD',
  [CountryId.VEN]: 'USD',
  [CountryId.VNM]: 'USD',
  [CountryId.VGB]: 'USD',
  [CountryId.VIR]: 'USD',
  [CountryId.WLF]: 'USD',
  [CountryId.ESH]: 'USD',
  [CountryId.YEM]: 'USD',
  [CountryId.ZMB]: 'USD',
  [CountryId.ZWE]: 'USD',
};

const defaultDollarCurrencySetting = {
  decimalSymbol: '.',
  currencySymbol: '$',
  isPrefix: true,
  decimalDigits: 2,
  groupingSymbol: ',',
  negativeSymbol: '-',
};

export const countryCurrencySettings = {
  [CountryId.AFG]: defaultDollarCurrencySetting,
  [CountryId.ALA]: defaultDollarCurrencySetting,
  [CountryId.ALB]: defaultDollarCurrencySetting,
  [CountryId.DZA]: defaultDollarCurrencySetting,
  [CountryId.ASM]: defaultDollarCurrencySetting,
  [CountryId.AND]: defaultDollarCurrencySetting,
  [CountryId.AGO]: defaultDollarCurrencySetting,
  [CountryId.AIA]: defaultDollarCurrencySetting,
  [CountryId.ATA]: defaultDollarCurrencySetting,
  [CountryId.ATG]: defaultDollarCurrencySetting,
  [CountryId.ARG]: defaultDollarCurrencySetting,
  [CountryId.ARM]: defaultDollarCurrencySetting,
  [CountryId.ABW]: defaultDollarCurrencySetting,
  [CountryId.AUS]: defaultDollarCurrencySetting,
  [CountryId.AUT]: defaultDollarCurrencySetting,
  [CountryId.AZE]: defaultDollarCurrencySetting,
  [CountryId.BHR]: defaultDollarCurrencySetting,
  [CountryId.BGD]: defaultDollarCurrencySetting,
  [CountryId.BRB]: defaultDollarCurrencySetting,
  [CountryId.BLR]: defaultDollarCurrencySetting,
  [CountryId.BEL]: defaultDollarCurrencySetting,
  [CountryId.BLZ]: defaultDollarCurrencySetting,
  [CountryId.BEN]: defaultDollarCurrencySetting,
  [CountryId.BMU]: defaultDollarCurrencySetting,
  [CountryId.BTN]: defaultDollarCurrencySetting,
  [CountryId.BOL]: defaultDollarCurrencySetting,
  [CountryId.BES]: defaultDollarCurrencySetting,
  [CountryId.BIH]: defaultDollarCurrencySetting,
  [CountryId.BWA]: defaultDollarCurrencySetting,
  [CountryId.BVT]: defaultDollarCurrencySetting,
  [CountryId.BRA]: defaultDollarCurrencySetting,
  [CountryId.IOT]: defaultDollarCurrencySetting,
  [CountryId.BRN]: defaultDollarCurrencySetting,
  [CountryId.BGR]: defaultDollarCurrencySetting,
  [CountryId.BFA]: defaultDollarCurrencySetting,
  [CountryId.BDI]: defaultDollarCurrencySetting,
  [CountryId.KHM]: defaultDollarCurrencySetting,
  [CountryId.CMR]: defaultDollarCurrencySetting,
  [CountryId.Canada]: defaultDollarCurrencySetting,
  [CountryId.CPV]: defaultDollarCurrencySetting,
  [CountryId.CYM]: defaultDollarCurrencySetting,
  [CountryId.CAF]: defaultDollarCurrencySetting,
  [CountryId.TCD]: defaultDollarCurrencySetting,
  [CountryId.CHL]: defaultDollarCurrencySetting,
  [CountryId.CHN]: defaultDollarCurrencySetting,
  [CountryId.CXR]: defaultDollarCurrencySetting,
  [CountryId.CCK]: defaultDollarCurrencySetting,
  [CountryId.COL]: defaultDollarCurrencySetting,
  [CountryId.COM]: defaultDollarCurrencySetting,
  [CountryId.COG]: defaultDollarCurrencySetting,
  [CountryId.COK]: defaultDollarCurrencySetting,
  [CountryId.CRI]: defaultDollarCurrencySetting,
  [CountryId.CIV]: defaultDollarCurrencySetting,
  [CountryId.HRV]: defaultDollarCurrencySetting,
  [CountryId.CUB]: defaultDollarCurrencySetting,
  [CountryId.CUW]: defaultDollarCurrencySetting,
  [CountryId.CYP]: defaultDollarCurrencySetting,
  [CountryId.CZE]: defaultDollarCurrencySetting,
  [CountryId.COD]: defaultDollarCurrencySetting,
  [CountryId.DNK]: defaultDollarCurrencySetting,
  [CountryId.DJI]: defaultDollarCurrencySetting,
  [CountryId.DMA]: defaultDollarCurrencySetting,
  [CountryId.DOM]: defaultDollarCurrencySetting,
  [CountryId.TLS]: defaultDollarCurrencySetting,
  [CountryId.ECU]: defaultDollarCurrencySetting,
  [CountryId.EGY]: defaultDollarCurrencySetting,
  [CountryId.SLV]: defaultDollarCurrencySetting,
  [CountryId.GNQ]: defaultDollarCurrencySetting,
  [CountryId.ERI]: defaultDollarCurrencySetting,
  [CountryId.EST]: defaultDollarCurrencySetting,
  [CountryId.ETH]: defaultDollarCurrencySetting,
  [CountryId.FLK]: defaultDollarCurrencySetting,
  [CountryId.FRO]: defaultDollarCurrencySetting,
  [CountryId.FJI]: defaultDollarCurrencySetting,
  [CountryId.FIN]: defaultDollarCurrencySetting,
  [CountryId.FRA]: defaultDollarCurrencySetting,
  [CountryId.GUF]: defaultDollarCurrencySetting,
  [CountryId.PYF]: defaultDollarCurrencySetting,
  [CountryId.ATF]: defaultDollarCurrencySetting,
  [CountryId.GAB]: defaultDollarCurrencySetting,
  [CountryId.GMB]: defaultDollarCurrencySetting,
  [CountryId.GEO]: defaultDollarCurrencySetting,
  [CountryId.DEU]: defaultDollarCurrencySetting,
  [CountryId.GHA]: defaultDollarCurrencySetting,
  [CountryId.GIB]: defaultDollarCurrencySetting,
  [CountryId.GRC]: defaultDollarCurrencySetting,
  [CountryId.GRL]: defaultDollarCurrencySetting,
  [CountryId.GRD]: defaultDollarCurrencySetting,
  [CountryId.GLP]: defaultDollarCurrencySetting,
  [CountryId.GUM]: defaultDollarCurrencySetting,
  [CountryId.GTM]: defaultDollarCurrencySetting,
  [CountryId.GGY]: defaultDollarCurrencySetting,
  [CountryId.GIN]: defaultDollarCurrencySetting,
  [CountryId.GNB]: defaultDollarCurrencySetting,
  [CountryId.GUY]: defaultDollarCurrencySetting,
  [CountryId.HTI]: defaultDollarCurrencySetting,
  [CountryId.HMD]: defaultDollarCurrencySetting,
  [CountryId.HND]: defaultDollarCurrencySetting,
  [CountryId.HKG]: defaultDollarCurrencySetting,
  [CountryId.HUN]: defaultDollarCurrencySetting,
  [CountryId.ISL]: defaultDollarCurrencySetting,
  [CountryId.IND]: defaultDollarCurrencySetting,
  [CountryId.IDN]: defaultDollarCurrencySetting,
  [CountryId.IRN]: defaultDollarCurrencySetting,
  [CountryId.IRQ]: defaultDollarCurrencySetting,
  [CountryId.IRL]: defaultDollarCurrencySetting,
  [CountryId.ISR]: defaultDollarCurrencySetting,
  [CountryId.ITA]: defaultDollarCurrencySetting,
  [CountryId.JAM]: defaultDollarCurrencySetting,
  [CountryId.JPN]: defaultDollarCurrencySetting,
  [CountryId.JEY]: defaultDollarCurrencySetting,
  [CountryId.JOR]: defaultDollarCurrencySetting,
  [CountryId.KAZ]: defaultDollarCurrencySetting,
  [CountryId.KEN]: defaultDollarCurrencySetting,
  [CountryId.KIR]: defaultDollarCurrencySetting,
  [CountryId.XKX]: defaultDollarCurrencySetting,
  [CountryId.KWT]: defaultDollarCurrencySetting,
  [CountryId.KGZ]: defaultDollarCurrencySetting,
  [CountryId.LAO]: defaultDollarCurrencySetting,
  [CountryId.LVA]: defaultDollarCurrencySetting,
  [CountryId.LBN]: defaultDollarCurrencySetting,
  [CountryId.LSO]: defaultDollarCurrencySetting,
  [CountryId.LBR]: defaultDollarCurrencySetting,
  [CountryId.LBY]: defaultDollarCurrencySetting,
  [CountryId.LIE]: defaultDollarCurrencySetting,
  [CountryId.LTU]: defaultDollarCurrencySetting,
  [CountryId.LUX]: defaultDollarCurrencySetting,
  [CountryId.MAC]: defaultDollarCurrencySetting,
  [CountryId.MKD]: defaultDollarCurrencySetting,
  [CountryId.MDG]: defaultDollarCurrencySetting,
  [CountryId.MWI]: defaultDollarCurrencySetting,
  [CountryId.MYS]: defaultDollarCurrencySetting,
  [CountryId.MDV]: defaultDollarCurrencySetting,
  [CountryId.MLI]: defaultDollarCurrencySetting,
  [CountryId.MLT]: defaultDollarCurrencySetting,
  [CountryId.IMN]: defaultDollarCurrencySetting,
  [CountryId.MHL]: defaultDollarCurrencySetting,
  [CountryId.MTQ]: defaultDollarCurrencySetting,
  [CountryId.MRT]: defaultDollarCurrencySetting,
  [CountryId.MUS]: defaultDollarCurrencySetting,
  [CountryId.MYT]: defaultDollarCurrencySetting,
  [CountryId.MEX]: defaultDollarCurrencySetting,
  [CountryId.FSM]: defaultDollarCurrencySetting,
  [CountryId.MDA]: defaultDollarCurrencySetting,
  [CountryId.MCO]: defaultDollarCurrencySetting,
  [CountryId.MNG]: defaultDollarCurrencySetting,
  [CountryId.MNE]: defaultDollarCurrencySetting,
  [CountryId.MSR]: defaultDollarCurrencySetting,
  [CountryId.MAR]: defaultDollarCurrencySetting,
  [CountryId.MOZ]: defaultDollarCurrencySetting,
  [CountryId.MMR]: defaultDollarCurrencySetting,
  [CountryId.NAM]: defaultDollarCurrencySetting,
  [CountryId.NRU]: defaultDollarCurrencySetting,
  [CountryId.NPL]: defaultDollarCurrencySetting,
  [CountryId.NLD]: defaultDollarCurrencySetting,
  [CountryId.NCL]: defaultDollarCurrencySetting,
  [CountryId.NZL]: defaultDollarCurrencySetting,
  [CountryId.NIC]: defaultDollarCurrencySetting,
  [CountryId.NER]: defaultDollarCurrencySetting,
  [CountryId.NGA]: defaultDollarCurrencySetting,
  [CountryId.NIU]: defaultDollarCurrencySetting,
  [CountryId.NFK]: defaultDollarCurrencySetting,
  [CountryId.PRK]: defaultDollarCurrencySetting,
  [CountryId.MNP]: defaultDollarCurrencySetting,
  [CountryId.NOR]: defaultDollarCurrencySetting,
  [CountryId.OMN]: defaultDollarCurrencySetting,
  [CountryId.PAK]: defaultDollarCurrencySetting,
  [CountryId.PLW]: defaultDollarCurrencySetting,
  [CountryId.PSE]: defaultDollarCurrencySetting,
  [CountryId.PAN]: defaultDollarCurrencySetting,
  [CountryId.PNG]: defaultDollarCurrencySetting,
  [CountryId.PRY]: defaultDollarCurrencySetting,
  [CountryId.PER]: defaultDollarCurrencySetting,
  [CountryId.PHL]: defaultDollarCurrencySetting,
  [CountryId.PCN]: defaultDollarCurrencySetting,
  [CountryId.POL]: defaultDollarCurrencySetting,
  [CountryId.PRT]: defaultDollarCurrencySetting,
  [CountryId.PRI]: defaultDollarCurrencySetting,
  [CountryId.QAT]: defaultDollarCurrencySetting,
  [CountryId.REU]: defaultDollarCurrencySetting,
  [CountryId.ROU]: defaultDollarCurrencySetting,
  [CountryId.RUS]: defaultDollarCurrencySetting,
  [CountryId.RWA]: defaultDollarCurrencySetting,
  [CountryId.SHN]: defaultDollarCurrencySetting,
  [CountryId.KNA]: defaultDollarCurrencySetting,
  [CountryId.LCA]: defaultDollarCurrencySetting,
  [CountryId.SPM]: defaultDollarCurrencySetting,
  [CountryId.VCT]: defaultDollarCurrencySetting,
  [CountryId.BLM]: defaultDollarCurrencySetting,
  [CountryId.MAF]: defaultDollarCurrencySetting,
  [CountryId.WSM]: defaultDollarCurrencySetting,
  [CountryId.SMR]: defaultDollarCurrencySetting,
  [CountryId.STP]: defaultDollarCurrencySetting,
  [CountryId.SAU]: defaultDollarCurrencySetting,
  [CountryId.SEN]: defaultDollarCurrencySetting,
  [CountryId.SRB]: defaultDollarCurrencySetting,
  [CountryId.SYC]: defaultDollarCurrencySetting,
  [CountryId.SLE]: defaultDollarCurrencySetting,
  [CountryId.SGP]: defaultDollarCurrencySetting,
  [CountryId.SXM]: defaultDollarCurrencySetting,
  [CountryId.SVK]: defaultDollarCurrencySetting,
  [CountryId.SVN]: defaultDollarCurrencySetting,
  [CountryId.SLB]: defaultDollarCurrencySetting,
  [CountryId.SOM]: defaultDollarCurrencySetting,
  [CountryId.ZAF]: defaultDollarCurrencySetting,
  [CountryId.SGS]: defaultDollarCurrencySetting,
  [CountryId.KOR]: defaultDollarCurrencySetting,
  [CountryId.SSD]: defaultDollarCurrencySetting,
  [CountryId.ESP]: defaultDollarCurrencySetting,
  [CountryId.LKA]: defaultDollarCurrencySetting,
  [CountryId.SDN]: defaultDollarCurrencySetting,
  [CountryId.SUR]: defaultDollarCurrencySetting,
  [CountryId.SJM]: defaultDollarCurrencySetting,
  [CountryId.SWZ]: defaultDollarCurrencySetting,
  [CountryId.SWE]: defaultDollarCurrencySetting,
  [CountryId.CHE]: defaultDollarCurrencySetting,
  [CountryId.SYR]: defaultDollarCurrencySetting,
  [CountryId.TWN]: defaultDollarCurrencySetting,
  [CountryId.TJK]: defaultDollarCurrencySetting,
  [CountryId.TZA]: defaultDollarCurrencySetting,
  [CountryId.THA]: defaultDollarCurrencySetting,
  [CountryId.BHS]: defaultDollarCurrencySetting,
  [CountryId.TGO]: defaultDollarCurrencySetting,
  [CountryId.TKL]: defaultDollarCurrencySetting,
  [CountryId.TON]: defaultDollarCurrencySetting,
  [CountryId.TTO]: defaultDollarCurrencySetting,
  [CountryId.TUN]: defaultDollarCurrencySetting,
  [CountryId.TUR]: defaultDollarCurrencySetting,
  [CountryId.TKM]: defaultDollarCurrencySetting,
  [CountryId.TCA]: defaultDollarCurrencySetting,
  [CountryId.TUV]: defaultDollarCurrencySetting,
  [CountryId.UGA]: defaultDollarCurrencySetting,
  [CountryId.UKR]: defaultDollarCurrencySetting,
  [CountryId.UAE]: defaultDollarCurrencySetting,
  [CountryId.GBR]: defaultDollarCurrencySetting,
  [CountryId.USA]: defaultDollarCurrencySetting,
  [CountryId.UMI]: defaultDollarCurrencySetting,
  [CountryId.URY]: defaultDollarCurrencySetting,
  [CountryId.UZB]: defaultDollarCurrencySetting,
  [CountryId.VUT]: defaultDollarCurrencySetting,
  [CountryId.VAT]: defaultDollarCurrencySetting,
  [CountryId.VEN]: defaultDollarCurrencySetting,
  [CountryId.VNM]: defaultDollarCurrencySetting,
  [CountryId.VGB]: defaultDollarCurrencySetting,
  [CountryId.VIR]: defaultDollarCurrencySetting,
  [CountryId.WLF]: defaultDollarCurrencySetting,
  [CountryId.ESH]: defaultDollarCurrencySetting,
  [CountryId.YEM]: defaultDollarCurrencySetting,
  [CountryId.ZMB]: defaultDollarCurrencySetting,
  [CountryId.ZWE]: defaultDollarCurrencySetting,
};
