import React from 'react';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { Form, Input } from 'antd';
import { translations } from '../../constants/translations';
import { TextEditor } from '../TextEditor/TextEditor';
import { QuickNoteModalProps } from './useQuickNoteModal';

export const QuickNoteModal = ({
  form,
  isSaving,
  changesMade,
  handleOnFinish,
  handleOnClose,
  handleValueChange,
  quickNote,
  setQuickNote,
  defaultNoteType,
}: QuickNoteModalProps) => (
  <ModalWithCloseConfirm
    title={`${translations.quickNoteModal.title} - ${defaultNoteType}`}
    onOk={form.submit}
    onCancel={handleOnClose}
    open
    isSaving={isSaving}
    changesMade={changesMade}
    warningMessageOnRouteChange={translations.shared.getUnsavedDataNavigationWarning(translations.quickNoteModal.title)}
    width={800}
    destroyOnClose
  >
    <Form
      layout='horizontal'
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 12 }}
      labelAlign='left'
      form={form}
      onFinish={handleOnFinish}
      onValuesChange={handleValueChange}
      autoComplete='off'
    >
      <Form.Item required label={translations.quickNoteModal.quickNoteName} name='noteName'>
        <Input placeholder={translations.quickNoteModal.quickNoteName} />
      </Form.Item>
      <Form.Item required label={translations.quickNoteModal.sortOrder} name='sortOrder'>
        <Input placeholder={translations.quickNoteModal.sortOrder} defaultValue={0} type='number' />
      </Form.Item>
      <TextEditor
        textContent={quickNote}
        setTextContent={setQuickNote}
        editMode
        placeholder={translations.quickNoteModal.descriptionPlaceholder}
      />
    </Form>
  </ModalWithCloseConfirm>
);
