import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isMatch } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Maybe } from '../../graph/types';
import ModalWithCloseConfirm, { ModalFooterButtonsForReset } from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { ServiceOptionsFormValues, ServicePromptModalProps } from '../ServiceRendered/AddServiceForm/AddServiceForm';

export type ServiceSingleOptionModalProps = ServicePromptModalProps & {
  title: string;
  testId: string;
  children: Maybe<JSX.Element>;
  labelColSpan?: number;
  isNavWarningRedundant?: boolean;
  form: FormInstance<ServiceOptionsFormValues>;
  hasCancelOption?: boolean;
};

export const ServiceSingleOptionModal = ({
  initialValues,
  isSaving = false,
  onSave,
  onClose,
  visible,
  title,
  testId,
  children,
  form,
  labelColSpan = 9,
  isNavWarningRedundant = false,
  hasCancelOption = false,
}: ServiceSingleOptionModalProps) => {
  const [hasDataChanged, setHasDataChanged] = useState(false);

  const handleFieldReset = useCallback(() => {
    form.resetFields();
    setHasDataChanged(false);
  }, [form]);

  // clear the values by resetting them when the form closes
  useEffect(() => {
    handleFieldReset();
  }, [handleFieldReset, visible]);

  const handleOnCancel = () => {
    if (isSaving) {
      return;
    }
    onClose?.();
  };

  const handleOnReset = () => {
    if (isSaving) {
      return;
    }
    handleFieldReset();
  };

  const handleOnValueChange = (_: any, values: ServiceOptionsFormValues) => {
    setHasDataChanged(!isMatch(initialValues as ServiceOptionsFormValues, values));
  };

  return (
    <ModalWithCloseConfirm
      title={title}
      open={visible}
      onCancel={handleOnCancel}
      footer={null}
      isSaving={isSaving}
      changesMade={hasDataChanged}
      isNavWarningRedundant={isNavWarningRedundant}
    >
      <Form<ServiceOptionsFormValues>
        form={form}
        labelCol={{ span: labelColSpan }}
        initialValues={initialValues as ServiceOptionsFormValues}
        onFinish={onSave}
        onValuesChange={handleOnValueChange}
        data-testid={testId}
        autoComplete='off'
      >
        {children && React.cloneElement(children, { form })}
        <ModalFooterButtonsForReset
          hasDataChanged={hasDataChanged}
          handleOnReset={handleOnReset}
          disableButton={!hasDataChanged || isSaving}
          hasCancelOption={hasCancelOption}
          handleOnCancel={handleOnCancel}
          isSaving={isSaving}
        />
      </Form>
    </ModalWithCloseConfirm>
  );
};
