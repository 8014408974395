import gql from 'graphql-tag';
import { OrganizationDTOFields } from '../fragments';

export const GetOrganizationDtoEs = gql`
  query getOrganizationDTOEs($organizationId: ID!) {
    getOrganizationDTOEs(organizationId: $organizationId) {
      ...OrganizationDTOFields
    }
  }
  ${OrganizationDTOFields}
`;

export const GetOrganizationDtoPg = gql`
  query getOrganizationDTOPg($organizationId: ID!) {
    getOrganizationDTOPg(organizationId: $organizationId) {
      ...OrganizationDTOFields
    }
  }
  ${OrganizationDTOFields}
`;
