import { Maybe } from 'graphql/jsutils/Maybe';
import { Info, InfoDto, InfoUpsert } from '../../graph/types';

export function getInfoUpsert(
  infoTypeId: string,
  newValue: string | undefined,
  existingInfo: Maybe<Info[]> = []
): InfoUpsert[] | undefined {
  const oldInfo = existingInfo?.filter((info) => info.type_id === infoTypeId);
  if (oldInfo && oldInfo.length > 0) {
    if (newValue) {
      const infoValueChanged = oldInfo.filter((info) => info.value === newValue).length === 0;
      if (infoValueChanged) {
        const deleteOldInfoUpsert = getUpsertToDeleteValues(oldInfo);
        const newInfoUpsert = getNewInfoUpsert(newValue, infoTypeId);
        return deleteOldInfoUpsert.concat(newInfoUpsert);
      }
    } else {
      return getUpsertToDeleteValues(oldInfo);
    }
  } else {
    if (newValue) {
      return [getNewInfoUpsert(newValue, infoTypeId)];
    }
  }
  return undefined;
}

export function getInfoUpsertDto(
  infoTypeId: string,
  newValue: string | undefined,
  existingInfo: InfoDto[] = []
): InfoUpsert[] | undefined {
  const oldInfo = existingInfo.filter((info) => info.type_id === infoTypeId);
  if (oldInfo && oldInfo.length > 0) {
    if (newValue) {
      const infoValueChanged = oldInfo.filter((info) => info.value === newValue).length === 0;
      if (infoValueChanged) {
        const deleteOldInfoUpsert = getUpsertToDeleteValuesDto(oldInfo);
        const newInfoUpsert = getNewInfoUpsert(newValue, infoTypeId);
        return deleteOldInfoUpsert.concat(newInfoUpsert);
      }
    } else {
      return getUpsertToDeleteValuesDto(oldInfo);
    }
  } else {
    if (newValue) {
      return [getNewInfoUpsert(newValue, infoTypeId)];
    }
  }
  return undefined;
}

function getNewInfoUpsert(value: string, typeId: string): InfoUpsert {
  return {
    record: {
      type_id: typeId,
      value,
    },
    void: false,
  };
}

function getUpsertToDeleteValues(infos: Info[]): InfoUpsert[] {
  return infos.map((info) => ({ id: info.id, void: true }));
}

function getUpsertToDeleteValuesDto(infos: InfoDto[]): InfoUpsert[] {
  return infos.map((info) => ({ id: info.id, void: true }));
}
