import React, { PropsWithChildren } from 'react';
import { RelatedOwner } from '../../../../../../graph/types';
import Paragraph from 'antd/lib/typography/Paragraph';
import OwnershipTag from '../OwnershipTag/OwnershipTag';

interface OwnershipTagsForPanelHeaderProps extends PropsWithChildren<unknown> {
  owners: RelatedOwner[];
  maxWidth?: number;
}

export const OwnershipTagsForPanelHeader: React.FC<OwnershipTagsForPanelHeaderProps> = ({ owners, maxWidth }) => {
  const tags = owners.map((owner, index) => (
    <OwnershipTag navigationId={owner.contact_id ?? ''} owner={owner} key={index} />
  ));

  return (
    <Paragraph
      style={{
        marginBottom: 0,
        maxWidth,
      }}
      ellipsis={{ rows: 1 }}
    >
      {tags}
    </Paragraph>
  );
};
