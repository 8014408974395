import gql from 'graphql-tag';
import { NoteFields, NotePreviewFields } from '../fragments';

export const UpsertNote = gql`
  mutation upsertNote($organizationId: ID!, $note: NoteUpsert!) {
    upsertNote(organizationId: $organizationId, note: $note) {
      ...NoteFields
    }
  }
  ${NoteFields}
`;

export const GetNote = gql`
  query GetNote($organizationId: ID!, $noteId: ID!) {
    getNote(organizationId: $organizationId, noteId: $noteId) {
      id
      note {
        ...NoteFields
      }
    }
  }
  ${NoteFields}
`;

export const GetNotes = gql`
  query getNotesOffline($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: HistoryFilter) {
    getNotes(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...NoteFields
    }
  }
  ${NoteFields}
`;

export const GetNotesPreview = gql`
  query getNotesPreview($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: HistoryFilter) {
    getNotes(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...NotePreviewFields
    }
  }
  ${NotePreviewFields}
`;
