import React, { useEffect, useMemo, useState } from 'react';
import { AntechLabConfigurationModal } from '../../../components/AntechLabConfigurationModal/AntechLabConfigurationModal';
import { showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { translations } from '../../../constants/translations';
import { LabSettingsAntech, Organization, OrganizationSubscriptionResult } from '../../../graph/types';
import { useGetLabSettingsAntech } from '../../../hooks/ajax/lab/labHooks';
import { useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { AddOnCardAntechLab } from './AddOnCardAntechLab';

export interface AddOnAntechContainerProps {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
}

export const AddOnCardAntechContainer: React.FC<AddOnAntechContainerProps> = ({
  organizationId,
  organization,
  refetchOrganization,
}: AddOnAntechContainerProps) => {
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const [tempSubscription, setTempSubscription] = useState<OrganizationSubscriptionResult>();
  const [currentSettings, setCurrentSetting] = useState<LabSettingsAntech | undefined>();

  const [showConfigurationModal, setShowConfigurationModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { labSettingsAntech, refetch: refetchSettingsAntech } = useGetLabSettingsAntech(organizationId);

  useEffect(() => {
    setCurrentSetting(labSettingsAntech ?? undefined);
  }, [labSettingsAntech]);

  const antechLabAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.AntechLabLevel].level_id
      ),
    [organization]
  );

  const addOnEnabled = useMemo(() => (antechLabAddon?.enabled ? true : false), [antechLabAddon]);

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const activateAntechLabSubscription = async () => {
    await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: true,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.AntechLabLevel].level_id,
            },
          ],
        },
      },
    });
  };

  const getFutureChargesWithAntechLab = async () => {
    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: false,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.AntechLabLevel].level_id,
            },
          ],
        },
      },
    });
    return data?.updateOrganizationSubscription;
  };

  const handleOpenConfigurationModal = async () => {
    setIsSaving(true);
    if (!addOnEnabled) {
      const subscriptionData = await getFutureChargesWithAntechLab();
      setTempSubscription(subscriptionData);
    } else {
      await refetchSettingsAntech();
    }
    setIsSaving(false);
    setShowConfigurationModal(true);
  };

  const handleCloseConfigurationModal = () => {
    setShowConfigurationModal(false);
  };

  const handleSaveConfiguration = async () => {
    setShowConfigurationModal(false);
    setIsSaving(true);
    await activateAntechLabSubscription();
    refetchOrganization();
    setIsSaving(false);
    showSuccessMessage(translations.addOns.cards.antechLab.successMessage);
  };

  return (
    <SaveSpinner isSaving={isSaving} savingMessage={translations.loadingComponent.loading}>
      <AddOnCardAntechLab
        isAddOnEnabled={addOnEnabled}
        price={antechLabAddon?.cost}
        onClick={handleOpenConfigurationModal}
        onConfigureClick={handleOpenConfigurationModal}
        countryId={countryId}
      />
      {showConfigurationModal && (
        <AntechLabConfigurationModal
          addOnEnabled={addOnEnabled}
          organizationId={organizationId}
          subscription={tempSubscription}
          currentSettings={currentSettings}
          onSave={handleSaveConfiguration}
          onClose={handleCloseConfigurationModal}
        />
      )}
    </SaveSpinner>
  );
};
