import React, { PropsWithChildren } from 'react';
import { translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';

export interface TabChangeWarningProps extends PropsWithChildren<unknown> {
  showTabChangeWarning: boolean;
  warningMessage?: string;
  onOk: () => void;
  closeModal: () => void;
}

export const TabChangeWarning: React.FC<TabChangeWarningProps> = (props: TabChangeWarningProps) => {
  return (
    <ModalWithCloseConfirm
      title={translations.tabChangeWarning.title}
      open={props.showTabChangeWarning}
      onCancel={props.closeModal}
      onOk={props.onOk}
      okText={translations.tabChangeWarning.confirmButton}
    >
      {props.warningMessage ?? translations.tabChangeWarning.message}
    </ModalWithCloseConfirm>
  );
};
