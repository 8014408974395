import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubscriptionUpsertGenerator } from '../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useFetchSubscriptionPricing, useUpdateSubscription } from '../../hooks/ajax/subscription/subscriptionHooks';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { getTranslatedSubscriptionErrorMessage, translations } from '../../constants/translations';
import { showErrorMessage } from '../Notification/notificationUtil';
import { SubscriptionUpdateTable } from './SubscriptionUpdateTable';
import { Typography } from 'antd';
import styled from 'styled-components';
import { displayAsDate } from '../../constants/formats';
import { getSubscriptionUpsertErrorMessage } from '../../pages/SubscriptionSettings/subscriptionErrorUtil';
import { OrganizationSubscriptionDetailCharge, OrganizationSubscriptionResult, TaxAmount } from '../../graph/types';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { getCurrencyFromSubscriptionBillingAddress } from '../../pages/SubscriptionSettings/subscriptionSettingsUtil';
import OfflineUsersTable from './OfflineUsersTable/OfflineUsersTable';
import { OfflineUser, TrackOfflineUsers } from './util';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import SubscriptionCurrencyFormatter from '../CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

interface SubscriptionUpdateModalProps {
  onClose: (disableAddon?: boolean) => void;
  onSuccess: () => void;
  canReduceUserCount?: boolean;
  showOffline?: boolean;
  showQuickBooks?: boolean;
  showHisa?: boolean;
}

const SubscriptionInfoContainer = styled.div`
  display: flex;
  margin: 1rem;
`;
const MonthlyCharges = styled.div`
  flex-grow: 1;
`;
const ProratedCharges = styled.div`
  flex-grow: 1;
`;
const { Title } = Typography;

const hasChargesChanges = (charges: OrganizationSubscriptionDetailCharge[] = []) => {
  for (let i = 0; i < charges.length; i++) {
    if (!isNil(charges[i].newQty)) {
      return true;
    }
  }
  return false;
};

export const getSubTotal = (taxes?: TaxAmount[] | null, totalString?: string | null) => {
  let subTotalValue = Number(totalString ?? 0);
  taxes?.forEach((tax) => {
    subTotalValue -= Number(tax.amount);
  });
  return subTotalValue;
};

export const subscriptionUpdateModalCycleTest = {
  taxTotal: 'subscriptionUpdateModalCycleTest_taxTotal',
};

export const SubscriptionUpdateModal: React.FC<SubscriptionUpdateModalProps> = ({
  onClose,
  onSuccess,
  canReduceUserCount,
  showOffline,
  showQuickBooks,
  showHisa,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [changedUsers, setChangedUsers] = useState<Record<string, TrackOfflineUsers>>({});
  const [subscription, setSubscription] = useState<OrganizationSubscriptionResult>();

  const shouldLoadOffline = useMemo(
    () => !!(showOffline || Object.keys(changedUsers).length),
    [changedUsers, showOffline]
  );

  const organizationId = useGetOrganizationIdFromRoute();
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const {
    data: subscriptionCharges,
    loading: subscriptionChargesLoading,
    fetchOrganizationSubscriptionLevelPrice: fetchSubscriptionCharges,
  } = useFetchSubscriptionPricing(organizationId, false, shouldLoadOffline, showQuickBooks, showHisa);

  const charges = useMemo(() => subscriptionCharges?.charge ?? [], [subscriptionCharges]);

  const nextBillingDate = subscriptionCharges?.organization?.subscription?.next_bill_date;
  const loading = subscriptionChargesLoading || isLoading;
  const loadingError = getTranslatedSubscriptionErrorMessage(subscriptionCharges?.error_name_key);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  useEffect(() => {
    setSubscription(subscriptionCharges);
  }, [subscriptionCharges]);

  useEffect(() => {
    setHasChanges(hasChargesChanges(charges) || !!Object.values(changedUsers).some((user) => !user.isSubmitted));
  }, [charges, changedUsers]);

  const upsertSubscription = useCallback(
    async (fnProcess: boolean) => {
      if (hasChanges) {
        setIsLoading(true);
        const { data } = await updateSubscription({
          variables: {
            organizationId,
            subscription: SubscriptionUpsertGenerator.generateUpsert({
              detail: subscriptionCharges?.charge?.map((charge) =>
                SubscriptionUpsertGenerator.mapChargeToUpsert(charge, {
                  level_id: charge.level_id,
                  set_qty: charge.newQty,
                  ...(charge.level_id ===
                    organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id &&
                    shouldLoadOffline && {
                      users: Object.values(changedUsers)
                        .filter((user) => (user.enableOffline && user.userId) || (!user.enableOffline && user?.id))
                        .map((user) => ({
                          ...(!user.enableOffline && { void: true, id: user.id }),
                          ...(user.enableOffline && { record: { user_id: user.userId } }),
                        })),
                    }),
                })
              ),
              fnProcess,
            }),
          },
        });
        setIsLoading(false);
        setSubscription(data?.updateOrganizationSubscription);
        return data;
      }
      return null;
    },
    // eslint-disable-next-line
    [changedUsers, hasChanges, organizationId, updateSubscription]
  );

  useEffect(() => {
    if (Object.keys(changedUsers).length) {
      upsertSubscription(false);
    }
  }, [changedUsers, upsertSubscription]);

  useEffect(() => {
    if (!Object.keys(changedUsers).length) {
      const subscriptionUsers: Record<string, TrackOfflineUsers> =
        subscription?.organization?.subscription?.detail
          ?.find(
            (detail) =>
              detail.level_id ===
              organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id
          )
          ?.users?.reduce(
            (res, user) => ({
              ...res,
              [user.user_id]: { ...user, userId: user.user_id, enableOffline: true, isSubmitted: true },
            }),
            {}
          ) ?? {};

      setChangedUsers({ ...changedUsers, ...subscriptionUsers });
    }
    // eslint-disable-next-line
  }, [subscription]);

  const countryId = useMemo(
    () => subscriptionCharges?.organization?.subscription?.billing_address?.country_id,
    [subscriptionCharges]
  );

  const handleFinish = async () => {
    const data = await upsertSubscription(true);

    if (data) {
      const errorMessage = getSubscriptionUpsertErrorMessage(data!.updateOrganizationSubscription);
      if (errorMessage) {
        showErrorMessage(errorMessage);
      } else {
        onSuccess();
      }
    }
  };

  const onAddQty = (level_id: string, add_qty: number) => {
    setHasChanges(false);
    fetchSubscriptionCharges({
      level_id,
      add_qty,
    });
  };

  const onSetQty = (level_id: string, set_qty: number) => {
    setHasChanges(false);
    fetchSubscriptionCharges({
      level_id,
      set_qty,
    });
  };

  const onToggleUser = (user: OfflineUser, enableOffline: boolean) => {
    if (changedUsers[user.userId] && !enableOffline && !changedUsers[user.userId]?.isSubmitted) {
      setChangedUsers(omit(changedUsers, user.userId));
    } else {
      setChangedUsers({ ...changedUsers, [user.userId]: { ...user, enableOffline } });
    }
  };

  const onCloseModal = () => {
    onClose(
      !subscription?.organization?.subscription?.detail?.find(
        (detail) =>
          detail.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id
      )?.users?.length && !Object.values(changedUsers).some((user) => user.enableOffline)
    );
  };

  return (
    <ModalWithCloseConfirm
      title={translations.subscriptionUpdateModal.title}
      open
      onCancel={onCloseModal}
      popConfirmSaveMessage={
        <>
          <div>{translations.subscriptionUpdateModal.upgradeConfirmWarningText[0]}</div>
          <div>{translations.subscriptionUpdateModal.upgradeConfirmWarningText[1]}</div>
        </>
      }
      onOk={handleFinish}
      okText={translations.subscriptionUpdateModal.saveButtonText}
      okButtonProps={{ disabled: !hasChanges }}
      isSaving={loading}
      savingMessage={translations.loadingComponent.loading}
      width={1000}
    >
      <SubscriptionUpdateTable
        charges={charges ?? []}
        onAddQty={onAddQty}
        onSetQty={onSetQty}
        canOnlyAddQty={!canReduceUserCount}
        offlineTable={<OfflineUsersTable subscription={subscription} onToggleUser={onToggleUser} />}
        countryId={countryId}
        changedUsers={changedUsers}
      />
      {loadingError && <p style={{ color: 'red' }}>{loadingError}</p>}

      <SubscriptionInfoContainer>
        <MonthlyCharges>
          <Title level={5}>{translations.subscriptionUpdateModal.nextBillCycleText}:</Title>
          {(subscription?.monthlyTax?.length ?? 0) > 0 && (
            <div data-testid={subscriptionUpdateModalCycleTest.taxTotal}>
              {translations.subscriptionSettings.currentBillingCycleEstimate.subTotal}:{' '}
              <SubscriptionCurrencyFormatter
                total={Number(getSubTotal(subscription?.monthlyTax, subscription?.monthlyTotal))}
                countryId={countryId}
              />
            </div>
          )}
          {subscription?.monthlyTax?.map((tax) => (
            <div key={tax.name_key}>
              {translations.subscriptionSettings.currentBillingCycleEstimate.taxTotal} {tax.name_key}:{' '}
              <SubscriptionCurrencyFormatter total={Number(tax.amount)} countryId={countryId} />
            </div>
          ))}
          <Title level={4}>
            {getCurrencyFromSubscriptionBillingAddress(
              subscription?.organization?.subscription?.billing_address?.country_id
            )}{' '}
            <SubscriptionCurrencyFormatter total={Number(subscription?.monthlyTotal ?? 0)} countryId={countryId} />
          </Title>
          <Title level={5} style={{ marginTop: '0.5rem' }}>
            {translations.subscriptionUpdateModal.nextChargeText}:{' '}
            {nextBillingDate && displayAsDate(nextBillingDate, dateFormat)}
          </Title>
        </MonthlyCharges>
        <ProratedCharges>
          <Title level={5}>{translations.subscriptionUpdateModal.proratedChargeTodayText}:</Title>
          {subscription?.discount && (
            <div>
              {translations.subscriptionSettings.currentBillingCycleEstimate.discount}:{' '}
              <SubscriptionCurrencyFormatter total={Number(subscription.discount)} countryId={countryId} />
            </div>
          )}
          {Number(subscription?.cardCharge ?? 0) > 0 && (subscription?.cardChargeTax?.length ?? 0) > 0 && (
            <>
              <div>
                {translations.subscriptionSettings.currentBillingCycleEstimate.subTotal}:{' '}
                <SubscriptionCurrencyFormatter
                  total={Number(getSubTotal(subscription?.cardChargeTax, subscription?.cardCharge))}
                  countryId={countryId}
                />
              </div>
              {subscription?.cardChargeTax?.map((tax) => (
                <div key={tax.name_key}>
                  {translations.subscriptionSettings.currentBillingCycleEstimate.taxTotal} {tax.name_key}:{' '}
                  <SubscriptionCurrencyFormatter total={Number(tax.amount)} countryId={countryId} />
                </div>
              ))}
            </>
          )}
          <Title level={4}>
            {getCurrencyFromSubscriptionBillingAddress(
              subscription?.organization?.subscription?.billing_address?.country_id
            )}{' '}
            <SubscriptionCurrencyFormatter total={Number(subscription?.cardCharge ?? 0)} countryId={countryId} />
          </Title>
        </ProratedCharges>
      </SubscriptionInfoContainer>
    </ModalWithCloseConfirm>
  );
};
