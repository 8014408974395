import { Form, Table } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { uniqBy } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { translations } from '../../constants/translations';
import { FlexContainer, NoBottomMarginFormItem, SelectWithWidth, TitleDiv } from '../../globalStyles.style';
import { ThirdPartyTaxonomy } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getRequiredRule } from '../../util/forms';
import { TaxonomyType } from '../../util/taxonomyUtil';
import { fillThirdPartyOptionsMap, mapTaxonomyByThirdParty, ThirdPartyTaxonomyData } from '../../util/thirdPartyUtil';
import { OptionsWithSpeciesNumber } from '../DependentDropdown/DependentDropdown';

interface TaxonomyMapRowProps {
  thirdPartyTaxonomy: ThirdPartyTaxonomy[];
  setTaxonomyByThirdParty?: (taxonomyData: Record<string, ThirdPartyTaxonomyData[]>) => void;
  form: FormInstance;
  showTaxonomyModalTitle?: boolean;
}

const getThirdPartyFieldName = (entry: ThirdPartyTaxonomyData, type?: TaxonomyType) => {
  return `${entry.thirdPartyId}_${type ?? entry.type}_${entry.cassadolValue}_third_party`;
};

export const ThirdPartyTaxonomyMapRow: React.FC<TaxonomyMapRowProps> = ({
  thirdPartyTaxonomy,
  form,
  setTaxonomyByThirdParty,
  showTaxonomyModalTitle = true,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { current: taxonomyByThirdParty }: MutableRefObject<Record<string, ThirdPartyTaxonomyData[]>> = useRef({});
  const [thirdPartyOptionsMap, setThirdPartyOptionsMap] = useState<
    Record<string, Record<string, OptionsWithSpeciesNumber[]>>
  >({});

  //#region Third Party related functions
  let thirdPartyName = '';

  uniqBy(thirdPartyTaxonomy, 'third_party_id').forEach((tax) => {
    taxonomyByThirdParty[tax.third_party_id] = [];
    thirdPartyName = thirdPartyName.length
      ? `${thirdPartyName}, ${tax.third_party_name_key}`
      : tax.third_party_name_key;
  });

  mapTaxonomyByThirdParty(thirdPartyTaxonomy, taxonomyByThirdParty);

  const thirdPartyTableData = Object.keys(taxonomyByThirdParty).reduce<ThirdPartyTaxonomyData[]>((acc, key) => {
    return acc.concat(taxonomyByThirdParty[key]);
  }, []);

  //#endregion

  const on3PSelectChange = (option: any, fieldName: string) => {
    form.setFields([
      {
        name: `${fieldName}_name`,
        value: option.label,
      },
    ]);
  };

  useEffect(() => {
    if (Object.keys(thirdPartyOptionsMap).length === 0) {
      fillThirdPartyOptionsMap(thirdPartyTableData, organizationId, setThirdPartyOptionsMap, () => ({}));
    }
  }, [thirdPartyOptionsMap, thirdPartyTableData, organizationId]);

  useEffect(() => {
    if (setTaxonomyByThirdParty) {
      setTaxonomyByThirdParty(taxonomyByThirdParty);
    }
  }, [setTaxonomyByThirdParty, taxonomyByThirdParty]);

  const thirdPartyColumns = [
    {
      title: translations.taxonomyModal.columns.cassadol.title,
      dataIndex: 'cassadolValueName',
      render: (value: string) => <span key={value}>{value}</span>,
    },
    {
      title: translations.taxonomyModal.columns.thirdPartyValue(thirdPartyName),
      dataIndex: 'requiresMap',
      render: (_: boolean, entry: ThirdPartyTaxonomyData) => {
        const fieldName = getThirdPartyFieldName(entry);
        const options3p = thirdPartyOptionsMap?.[entry.thirdPartyKey]?.[entry.type] ?? [];

        return (
          <>
            <NoBottomMarginFormItem
              key={fieldName}
              name={fieldName}
              rules={[getRequiredRule(translations.taxonomyModal.mapping)]}
            >
              <SelectWithWidth
                {...searchableSelectParams}
                placeholder={translations.taxonomyModal.dropdownPlaceholder(entry.type)}
                options={options3p}
                data-testid={fieldName}
                onChange={(_: any, option: any) => on3PSelectChange(option, fieldName)}
              />
            </NoBottomMarginFormItem>
            {/* noStyle item was a workaround as it seems trying to set the value for a field that doesn't exist when the form is first created doesn't work */}
            <Form.Item noStyle name={`${fieldName}_name`} />
          </>
        );
      },
    },
  ];

  return thirdPartyTableData.length > 0 ? (
    <>
      {showTaxonomyModalTitle && <TitleDiv>{translations.taxonomyModal.title(thirdPartyName)}</TitleDiv>}
      <FlexContainer direction='column'>
        <TitleDiv>{translations.taxonomyModal.description3p}</TitleDiv>
        <TitleDiv>{translations.taxonomyModal.instruction3p}</TitleDiv>
      </FlexContainer>
      <Table dataSource={thirdPartyTableData} columns={thirdPartyColumns} pagination={false} />
    </>
  ) : null;
};
