import { UserOrganizationCards } from '../graph/types';

export const getUserOrganizationWithoutHiddenCards = (
  organizationCards: UserOrganizationCards
): UserOrganizationCards => {
  const filteredPatientCards = organizationCards.patient_card.filter(({ patient_hidden }) => !patient_hidden);
  const filteredContactCards = organizationCards.contact_card.filter(({ contact_hidden }) => !contact_hidden);

  return { ...organizationCards, patient_card: filteredPatientCards, contact_card: filteredContactCards };
};
