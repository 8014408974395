import {
  CloudSyncOutlined,
  DisconnectOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { List, Menu, Modal, Popover, Switch } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Loading } from '../../../components/Loading/Loading';
import { MaxWidthListItem } from '../../../components/ServiceRendered/ServicesRenderedTable/ServicesRenderedTable';
import { routes } from '../../../constants/routes';
import { USER_KEY } from '../../../constants/sessionStorageKeys';
import { translations } from '../../../constants/translations';
import { MaintenanceMessage } from '../../../graph/types';
import { useLogout } from '../../../hooks/authHooks';
import { useIsReadyForOffline, useReplicationError } from '../../../hooks/localDatabaseHooks';
import { useUserPermissions } from '../../../hooks/permissionsHooks';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useRedirectRoute } from '../../../hooks/useRedirectRoute';
import { isIntOrStaging } from '../../../util/checkStageUtil';
import {
  newOrgEmptyBulkFileName,
  hasBeenOffline24Hours,
  setInitialLocalReplications,
  useOffline,
  useUnsyncedData,
} from '../../../util/offline/offlineUtil';
import { getUserPermissions } from '../../../util/userPermissionUtil';
import CassadolLogoInverse from './CassadolLogoInverse';
import { IntStagingWarningBanner } from './IntStagingWarningBanner/IntStagingWarningBanner';
import { StyledFlexParent, StyledHeader, StyledHomeButton } from './MainHeader.style';
import { MaintenanceWarningBanner } from './MaintenanceWarningBanner/MaintenanceWindowBanner';
import SearchContainer from './Search/SearchContainer';
import TestSwitch from './TestSwitch';
import { UnclosableAlert } from './UnclosableAlert';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { BULK_FILE_POLLING_TIME_INTERVAL, OFFLINE_POLLING_TIME_INTERVAL } from '../../../constants/queryConstants';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { UtilsContext } from '../../../components/UtilsProvider/store/state';
import { setTextConfirmAction } from '../../../components/UtilsProvider/store/actions';
import { useRxDB } from 'rxdb-hooks';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../../contexts/user/state';

const { SubMenu } = Menu;

const SubMenuWithMaxWidthTitle = styled(SubMenu)`
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;

    span {
      display: block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const mainLogoTestId = 'btn-main-logo-test-id';

interface MainHeaderProps {
  maintenance?: MaintenanceMessage;
}

const localIsOfflineAble = (): boolean | undefined => {
  try {
    return JSON.parse(localStorage.getItem(USER_KEY) || '{}')?.isOfflineAble;
  } catch (e) {
    return undefined;
  }
};

type ModalProps = {
  visible: boolean;
  title?: string;
  message?: string;
  onOk?: () => void;
};

const initialModalProps = { visible: false };
const emptyCloudIconItemTitleOffset = '-10px';

export const MainHeader: React.FC<MainHeaderProps> = ({ maintenance }) => {
  const logout = useLogout();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { user },
  } = useUserContext();

  const superUser = useUserPermissions();
  const { navigateTo } = useNavigationToRoute();
  const {
    enabled: offlineEnabled,
    setOfflineEnabled,
    initialized,
    isOnline,
    enabledAndOffline,
    showOfflineWarningBanner,
    setShowOfflineWarningBanner,
    setFile,
    file,
    resetOfflineContext,
  } = useOffline();
  const isOfflineReady = useIsReadyForOffline();
  const hasReplicationError = useReplicationError();
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const db = useRxDB();
  const history = useHistory();

  const [modalProps, setModalProps] = useState<ModalProps>(initialModalProps);
  const isOfflineAble = localIsOfflineAble() ?? !!superUser.isOfflineAble;
  const { hasUnsyncedData } = useUnsyncedData();
  const redirectRoute = useRedirectRoute(organizationId);
  const enabledBulkLoad = useLDFlag(LDFlagNames.BulkLoad);
  const enabledSupportPage = useLDFlag(LDFlagNames.PageSupport);
  const { organization, organizationLoading, stopPolling } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.BulkLoadInfo,
    undefined,
    {
      skip: !offlineEnabled || file || !enabledBulkLoad,
      fetchPolicy: 'no-cache',
      pollInterval: BULK_FILE_POLLING_TIME_INTERVAL,
    }
  );
  const bulkLoadStamp = organization?.bulk_load_stamp;

  const offlineStatusColor = useMemo(() => {
    let color = '';

    if (!offlineEnabled) {
      return color;
    }

    if (initialized) {
      color = '#196F41';
    }

    if (!isOfflineReady) {
      color = 'yellow';
    }

    if (hasUnsyncedData) {
      color = '#274684';
    }

    if (hasReplicationError) {
      color = 'red';
    }

    return color;
  }, [hasUnsyncedData, offlineEnabled, initialized, isOfflineReady, hasReplicationError]);

  const checkOfflineTiming = useCallback(() => {
    if (!isOnline && hasBeenOffline24Hours() && !showOfflineWarningBanner) {
      setShowOfflineWarningBanner(true);
    }
  }, [isOnline, setShowOfflineWarningBanner, showOfflineWarningBanner]);

  useEffect(() => {
    checkOfflineTiming();
  }, [checkOfflineTiming]);

  useEffect(() => {
    const timer = setInterval(() => {
      checkOfflineTiming();
    }, OFFLINE_POLLING_TIME_INTERVAL);

    return () => clearInterval(timer);
  }, [isOnline, checkOfflineTiming]);

  const setBulkLoadFile = useCallback(async () => {
    try {
      if (!organization?.bulk_load_file || !bulkLoadStamp || !enabledBulkLoad) {
        return;
      }

      const res = await fetch(organization.bulk_load_file.url);
      const blob = await res.blob();
      const file = new File([blob], organization.bulk_load_file.filePointer, {
        type: 'application/x-zip-compressed',
      });
      setFile(file);
    } catch (e) {
      showErrorMessage(translations.shared.loadErrorMessage);
    }
  }, [bulkLoadStamp, enabledBulkLoad, organization, setFile]);

  useEffect(() => {
    if (offlineEnabled && !file && bulkLoadStamp) {
      setBulkLoadFile();
    }
    // below is for new organizations who would not yet have a bulk file or stamp in order to skip the bulk load step in replication
    if (!file && offlineEnabled && bulkLoadStamp === null && enabledBulkLoad) {
      setFile(
        new File([JSON.stringify({})], newOrgEmptyBulkFileName, {
          type: 'application/x-zip-compressed',
        })
      );
    }
  }, [offlineEnabled, bulkLoadStamp, file, setBulkLoadFile, enabledBulkLoad, setFile]);

  if (!user) {
    return <p>{translations.mainHeader.error.failedToLoadUser}</p>;
  }

  if (organizationLoading) {
    return <Loading height='90vh' />;
  }

  const {
    PERM_SET_ALL: { allowed: settingsAllowed },
  } = getUserPermissions(organizationId, user, superUser);

  const handleHomeButtonClick = () => {
    if (enabledAndOffline) {
      navigateTo(routes.contacts);
    } else {
      navigateTo(redirectRoute);
    }
  };

  const statusPopoverContent = (
    <List>
      {Object.values(translations.shared.statusInformation).map(({ color, display }) => (
        <MaxWidthListItem key={color}>
          <b>{color}</b>: {display}
        </MaxWidthListItem>
      ))}
    </List>
  );

  const handleStartOffline = () => {
    setModalProps(initialModalProps);
    setOfflineEnabled(!offlineEnabled, user?.id ?? '');
  };

  const handleDisableOffline = async () => {
    setInitialLocalReplications();
    await db.remove();
    resetOfflineContext();
    setOfflineEnabled(!offlineEnabled, user?.id ?? '');
    history.go(0);
  };

  if (bulkLoadStamp) {
    stopPolling();
  }

  const iconStyle = { color: offlineStatusColor, fontSize: '1.5em', marginRight: emptyCloudIconItemTitleOffset };

  return (
    <>
      {maintenance && <MaintenanceWarningBanner maintenance={maintenance} />}
      {isIntOrStaging() && <IntStagingWarningBanner />}
      {showOfflineWarningBanner && (
        <UnclosableAlert
          mainText={translations.offlineWarning.mainText}
          subText={[translations.offlineWarning.subText]}
          closedText={translations.offlineWarning.subText}
          type='warning'
        />
      )}
      <StyledHeader>
        <StyledFlexParent>
          <StyledHomeButton data-testid={mainLogoTestId} ghost onClick={handleHomeButtonClick}>
            <CassadolLogoInverse />
          </StyledHomeButton>
          <SearchContainer />
          <TestSwitch />
          <Menu theme='dark' mode='horizontal' selectedKeys={[]} disabledOverflow>
            {enabledSupportPage && (
              <Menu.Item
                key={'support'}
                icon={<QuestionCircleOutlined style={{ marginRight: emptyCloudIconItemTitleOffset }} />}
                onClick={() => {
                  navigateTo(routes.support);
                }}
                title={null}
                disabled={!isOnline}
              />
            )}
            {settingsAllowed && (
              <Menu.Item
                key={'practice'}
                icon={<SettingOutlined style={{ marginRight: emptyCloudIconItemTitleOffset }} />}
                onClick={() => {
                  navigateTo(routes.practiceSettings);
                }}
                title={null}
                disabled={!isOnline}
              />
            )}
            <SubMenuWithMaxWidthTitle key='user' icon={<UserOutlined />} title={user?.name}>
              <Menu.Item
                key={'userSettings'}
                onClick={() => {
                  navigateTo(routes.userSettings);
                }}
                disabled={!isOnline}
              >
                {translations.mainPage.userSettings}
              </Menu.Item>
              <Menu.Item
                key={'logout'}
                onClick={
                  offlineEnabled
                    ? () =>
                        setModalProps({
                          visible: true,
                          onOk: () => {
                            setOfflineEnabled(!offlineEnabled, user?.id ?? '');
                            logout();
                          },
                          title: translations.mainPage.logout,
                          message: translations.shared.logoutWithOffline,
                        })
                    : () => logout()
                }
              >
                {translations.mainPage.logout}
              </Menu.Item>
              {isOfflineAble && (
                <Menu.Item key={'offlineToggle'}>
                  <Switch
                    data-testid='switch-offline-mode-test-id'
                    checkedChildren={translations.shared.offlineModeOn}
                    unCheckedChildren={translations.shared.offlineModeOff}
                    checked={offlineEnabled}
                    style={{ width: '100%' }}
                    disabled={!isOnline}
                    onClick={() =>
                      !offlineEnabled
                        ? setModalProps({
                            visible: true,
                            onOk: handleStartOffline,
                            title: offlineEnabled
                              ? translations.shared.offlineModeOn
                              : translations.shared.offlineModeOff,
                            message: offlineEnabled
                              ? translations.shared.offlineDisableDescription
                              : translations.shared.offlineEnableDescription,
                          })
                        : dispatchUtils(
                            setTextConfirmAction({
                              show: true,
                              onConfirm: handleDisableOffline,
                              title: translations.shared.offlineModeOn,
                              description: (
                                <span
                                  dangerouslySetInnerHTML={{ __html: translations.shared.offlineDisableDescription }}
                                />
                              ),
                            })
                          )
                    }
                  />
                </Menu.Item>
              )}
            </SubMenuWithMaxWidthTitle>
            {isOfflineAble && (
              <Menu.Item
                key={'offlineCapable'}
                icon={
                  <Popover content={statusPopoverContent}>
                    {isOnline ? <CloudSyncOutlined style={iconStyle} /> : <DisconnectOutlined style={iconStyle} />}
                  </Popover>
                }
                style={{ backgroundColor: '#798f8c' }}
                title={null}
              />
            )}
          </Menu>
        </StyledFlexParent>
      </StyledHeader>
      <Modal
        title={modalProps.title}
        onOk={modalProps.onOk}
        onCancel={() => setModalProps(initialModalProps)}
        open={modalProps.visible}
      >
        {modalProps.message && <span dangerouslySetInnerHTML={{ __html: modalProps.message }} />}
      </Modal>
    </>
  );
};
