import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useGetInvoiceWithAllContacts } from '../../../hooks/ajax/invoice/invoiceHooks';
import { Button, Form, Row, Select } from 'antd';
import { Loading } from '../../../components/Loading/Loading';
import { PracticeSettingsNameKey, translations } from '../../../constants/translations';
import { getInvoiceContactOptionsAndPrimaryContact, printInvoiceReport } from '../invoiceReportUtils';
import { useDefaultPracticeId } from '../../../hooks/ajax/practice/practiceHooks';
import { getRequiredRule } from '../../../util/forms';
import { useGetPracticeSettings } from '../../Contacts/ViewContact/statementUtils';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { Reports, getReportData } from '../../../util/reportUtils';
import { QueryRptGetInvoiceArgs } from '../../../graph/types';
import { GetInvoiceReport } from '../../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../../hooks/ajax/report/reportHooks';

interface PrintInvoiceReportModalProps extends PropsWithChildren<unknown> {
  invoiceId: string;
  onClose: () => void;
  footer?: string;
}

export const PrintInvoiceReportModal: React.FC<PrintInvoiceReportModalProps> = ({
  invoiceId,
  onClose,
  footer: customFooter,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { invoice, invoiceLoading } = useGetInvoiceWithAllContacts({ invoiceId, organizationId });
  const [isReportLoading, setIsReportLoading] = useState(false);
  const useActiveReportsVersion = useLDFlag(LDFlagNames.ActiveReportsInvoice);
  const { settings, settingsLoading } = useGetPracticeSettings(
    organizationId,
    practiceId,
    PracticeSettingsNameKey.InvoiceFooter
  );
  const footer = (customFooter ?? settings?.value) || ' ';

  const { contactOptions, primaryContactId } = getInvoiceContactOptionsAndPrimaryContact(invoice);

  const navigateToReportViewer = useNavigateToReportViewer();

  const handleFinish = useCallback(
    async (values: { contactId: string }) => {
      if (!invoice || !practiceId) {
        return;
      }
      setIsReportLoading(true);
      if (useActiveReportsVersion) {
        const reportData = await getReportData<'rptGetInvoice', QueryRptGetInvoiceArgs>(GetInvoiceReport, {
          organizationId,
          practiceId,
          invoiceId,
          contactId: values.contactId,
          footer,
        });
        navigateToReportViewer({
          reportName: Reports.Invoice,
          data: reportData,
          nestedHeader: true,
        });
      } else {
        await printInvoiceReport({
          invoiceId: invoice.id,
          contactId: values.contactId,
          request: {
            practiceId,
            organizationId,
            fileName: `Invoice_${invoice.number}.pdf`,
            returnPreSignedUrl: 'true',
          },
          footer,
        });
      }
      setIsReportLoading(false);
      onClose();
    },
    [invoice, onClose, organizationId, practiceId, footer, invoiceId, navigateToReportViewer, useActiveReportsVersion]
  );

  useEffect(() => {
    if (invoice?.contact && invoice?.contact.length === 1) {
      handleFinish({ contactId: invoice.contact[0].contact_id });
    }
  }, [invoice, handleFinish]);

  return (
    <ModalWithCloseConfirm
      open
      onCancel={onClose}
      title={translations.invoiceReport.printInvoiceReportModal.title}
      footer={null}
    >
      {invoiceLoading || settingsLoading ? (
        <Loading />
      ) : (
        <>
          <Form onFinish={handleFinish} autoComplete='off'>
            <Form.Item
              name={'contactId'}
              label={translations.invoiceReport.printInvoiceReportModal.contactFieldLabel}
              rules={[getRequiredRule(translations.invoiceReport.printInvoiceReportModal.contactFieldLabel)]}
              initialValue={contactOptions?.find((option) => option.value === primaryContactId)?.value}
            >
              <Select options={contactOptions} />
            </Form.Item>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button onClick={onClose} style={{ marginRight: 10 }}>
                {translations.shared.closeButtonText}
              </Button>
              <Button type={'primary'} htmlType='submit' loading={isReportLoading}>
                {translations.invoiceReport.printInvoiceReportModal.getPdfButton}
              </Button>
            </Row>
          </Form>
        </>
      )}
    </ModalWithCloseConfirm>
  );
};
