import gql from 'graphql-tag';
import {
  BasicServiceRenderedFields,
  FragmentServiceRenderedFieldsWithNote,
  InfoTextFields,
  ServiceActivityFields,
  ServiceFieldsWithTax,
  ServiceListViewFields,
  ServiceOfflineFields,
  ServiceRenderedFieldsWithInfoAndText,
} from '../fragments';

export const GetService = gql`
  query getServicesPg($organizationId: ID!, $filter: QueryServiceFilter!) {
    getServicesPg(organizationId: $organizationId, filter: $filter) {
      ...ServiceFieldsWithTax
    }
  }
  ${ServiceFieldsWithTax}
`;

export const GetServices = gql`
  query getServicesEs($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $queryString: ElasticQueryString) {
    getServicesEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      queryString: $queryString
      source: { excludes: ["document.item"] }
    ) {
      ...ServiceListViewFields
    }
  }
  ${ServiceListViewFields}
`;

export const GetServicesForOffline = gql`
  query getServicesEsForOffline($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $searchAfter: [ID!]!) {
    getServicesEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      searchAfter: $searchAfter
      returnHidden: true
    ) {
      ...ServiceOfflineFields
    }
  }
  ${ServiceOfflineFields}
`;

export const GetServiceText = gql`
  query getServiceText($organizationId: ID!, $id: ID!) {
    getServiceText(organizationId: $organizationId, id: $id) {
      ...InfoTextFields
    }
  }
  ${InfoTextFields}
`;

export const GetServiceTexts = gql`
  query getServiceTexts($organizationId: ID!, $filter: HistorySimpleFilter) {
    getServiceTexts(organizationId: $organizationId, filter: $filter) {
      ...InfoTextFields
    }
  }
  ${InfoTextFields}
`;

export const GetServicesWithBundles = gql`
  query getServicesWithBundles($organizationId: ID!, $filter: QueryServiceFilter!) {
    getServicesPg(organizationId: $organizationId, filter: $filter) {
      id
      name
      bundle {
        id
        name
        bundled_service_id
        quantity
        prompt_mode
        unit_name
        sort_order
      }
    }
  }
`;

export const GetServicesFromBundle = gql`
  query getBundledServicesPg($organizationId: ID!, $filter: QueryBundledServiceFilter) {
    getBundledServicesPg(organizationId: $organizationId, filter: $filter) {
      ...ServiceFieldsWithTax
    }
  }
  ${ServiceFieldsWithTax}
`;

export const GetServiceReminder = gql`
  query getServiceReminder($organizationId: ID!, $filter: QueryServiceFilter!) {
    getServicesPg(organizationId: $organizationId, filter: $filter) {
      id
      reminder {
        id
        duration
        service_id
      }
    }
  }
`;

export const UpsertService = gql`
  mutation upsertService($organizationId: ID!, $service: ServiceUpsert!) {
    upsertService(organizationId: $organizationId, service: $service) {
      ...ServiceOfflineFields
    }
  }
  ${ServiceOfflineFields}
`;

export const GetServicesRendered = gql`
  query getServicesRendered($organizationId: ID!, $filter: ServicesRenderedFilter) {
    getServicesRendered(organizationId: $organizationId, filter: $filter) {
      ...BasicServiceRenderedFields
    }
  }
  ${BasicServiceRenderedFields}
`;

export const GetServicesRenderedFragmentWithNote = gql`
  query getServicesRendered($organizationId: ID!, $filter: ServicesRenderedFilter) {
    getServicesRendered(organizationId: $organizationId, filter: $filter) {
      ...FragmentServiceRenderedFieldsWithNote
    }
  }
  ${FragmentServiceRenderedFieldsWithNote}
`;

export const GetServiceActivities = gql`
  query getServiceActivities($organizationId: ID!, $patientId: ID, $filter: HistoryFilter) {
    getServiceActivities(organizationId: $organizationId, patientId: $patientId, filter: $filter) {
      ...ServiceActivityFields
    }
  }
  ${ServiceActivityFields}
`;

export const UpdateServiceRendered = gql`
  mutation updateServiceRendered($organizationId: ID!, $serviceRendered: ServiceRenderedUpdate!) {
    updateServiceRendered(organizationId: $organizationId, serviceRendered: $serviceRendered) {
      ...BasicServiceRenderedFields
    }
  }
  ${BasicServiceRenderedFields}
`;

export const GetHisaCategories = gql`
  query getHISABillingCategories($organizationId: ID!, $category: String) {
    getHISABillingCategories(organizationId: $organizationId, category: $category) {
      ref_3p_id
      ref_3p_ref_data_id
      category
      ref_number
      ref_value
      ref_3p_ref_data_sort_order
      requires_dob
      requires_gender
      requires_microchip
      requires_email
      requires_roa
      requires_reason
      requires_results
      requires_limb_treated
      requires_dosage
      requires_condition_treated
      requires_tests_and_diagnostics
      requires_treatment_type
      requires_time
      requires_medical_name
      requires_drug_name
      requires_description
      requires_vaccine_name
    }
  }
`;

export const GetServiceRenderedInfoAndText = gql`
  query getServiceRendered($organizationId: ID!, $serviceRenderedId: ID!) {
    getServiceRendered(organizationId: $organizationId, serviceRenderedId: $serviceRenderedId) {
      ...ServiceRenderedFieldsWithInfoAndText
    }
  }
  ${ServiceRenderedFieldsWithInfoAndText}
`;
