import { StatementContactInterface } from '../../../graph/types';
import { setSelectedStatementsType, setActionLoadingType, setRefetchStatementType, StatementsAction } from './actions';

export interface StatementsState {
  selectedStatements: StatementContactInterface[];
  actionLoading: boolean;
  refetchStatement: (() => void) | undefined;
}

export const initialState: StatementsState = {
  selectedStatements: [],
  actionLoading: false,
  refetchStatement: undefined,
};

export const statementsReducer = (state: StatementsState = initialState, action: StatementsAction): StatementsState => {
  switch (action.type) {
    case setSelectedStatementsType:
      return {
        ...state,
        selectedStatements: action.statements,
      };
    case setActionLoadingType:
      return {
        ...state,
        actionLoading: action.loading,
      };
    case setRefetchStatementType:
      return {
        ...state,
        refetchStatement: action.refetchStatement,
      };
    default:
      return state;
  }
};
