import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Provider } from 'rxdb-hooks';
import { addRxPlugin, createRxDatabase, RxDatabase, getRxStoragePouch, addPouchPlugin, hash } from 'rxdb';
import { collections } from './collections';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import {
  RxDBReplicationGraphQLPlugin,
  getChangesSinceLastPushSequence,
  getLastPullDocument,
  getLastPushSequence,
} from 'rxdb/plugins/replication-graphql';
import * as IDBAdapter from 'pouchdb-adapter-idb';
import { useOffline } from '../../util/offline/offlineUtil';
import { Loading } from '../../components/Loading/Loading';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { translations } from '../../constants/translations';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import { useClearSession } from '../../hooks/authHooks';
import { OfflineModeActions } from '../../util/offline/store/actions';
import { OfflineModeContext } from '../../util/offline/store/state';
import config from '../../config/config';

addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBReplicationGraphQLPlugin);
addPouchPlugin(IDBAdapter);

const dbName = 'cassadoldb';
const newDbName = 'cassdb';
const storageDatabaseName = 'storage_database';

export let db: RxDatabase;
export let storageDb: IDBDatabase;

export const LocalDatabaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [db_, setDB] = useState<RxDatabase>();
  const { initialized: offlineModeInitialized } = useOffline();
  const [error, setError] = useState(false);
  const clearSession = useClearSession();
  const { dispatch } = useContext(OfflineModeContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 60000);

    (async () => {
      try {
        const indexedDatabases = await window.indexedDB.databases();
        indexedDatabases.forEach(({ name }) => {
          if (name?.includes(dbName)) {
            window.indexedDB.deleteDatabase(name);
          }
        });
      } catch (e) {
        // eslint-disable-next-line
        console.error((e as Error).message ?? e);
      }

      try {
        const storageDbRequest = await window.indexedDB.open(storageDatabaseName, 1);
        // eslint-disable-next-line
        storageDbRequest.onerror = console.error;
        storageDbRequest.onsuccess = (event: any) => {
          storageDb = event.target?.result;
          dispatch(OfflineModeActions.setStorageDb(storageDb));
        };

        storageDbRequest.onupgradeneeded = (event: any) => {
          storageDb = event.target?.result;
          storageDb.createObjectStore('storage_upsert', { keyPath: 'id' });
        };
      } catch (e) {
        // eslint-disable-next-line
        console.error((e as Error).message ?? e);
      }

      try {
        const database = await createRxDatabase({
          name: newDbName,
          storage: getRxStoragePouch('idb'),
          ignoreDuplicate: true,
        });

        db = database;
        (window as any).db = db;
        (window as any).endPointHash = hash(config.APPSYNC_URL);
        (window as any).getLastPullDocument = getLastPullDocument;
        (window as any).getLastPushSequence = getLastPushSequence;
        (window as any).getChangesSinceLastPushSequence = getChangesSinceLastPushSequence;

        try {
          await database.addCollections(collections);
          clearTimeout(timer);
        } catch (err) {
          // eslint-disable-next-line
          console.error((err as Error).message ?? err);
          showErrorMessage(translations.offline.errorMessage, 30);
        }

        setDB(database);
      } catch (err) {
        // eslint-disable-next-line
        console.error((err as Error).message ?? err);
        showErrorMessage(translations.offline.errorMessage, 30);
      }
    })();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Result
        status='warning'
        title={translations.mainPage.errorPageTitle}
        subTitle={translations.mainPage.errorPageSubtitle}
        extra={<Button onClick={clearSession}>{translations.mainPage.logout}</Button>}
      />
    );
  }

  if (!offlineModeInitialized || !db_) {
    return <Loading />;
  }

  return <Provider db={db_}>{children}</Provider>;
};
