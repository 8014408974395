import { Button, Modal, Row } from 'antd';
import React from 'react';
import { AbbreviateToWidth } from '../../../../../components/AbbreviateToWidth/AbbreviateToWidth';
import { SaveSpinner } from '../../../../../components/SaveSpinner/SaveSpinner';
import { SelectEntityModalTable } from '../../../../../components/SelectEntityModalTable/SelectEntityModalTable';
import { TableCellLink } from '../../../../../components/TableLink/TableCellLink';
import { CustomColumnType } from '../../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { displayAsDate } from '../../../../../constants/formats';
import { PADDING_COMPENSATION_WIDTH_BUTTON, PADDING_COMPENSATION_WIDTH_TEXT } from '../../../../../constants/layout';
import { invoicePropertyNames, invoiceSyncPropertyNames } from '../../../../../constants/propertyNames';
import { invoiceStatusConfigs } from '../../../../../constants/referenceData/invoiceReferenceData';
import {
  InvoiceSyncActions,
  invoiceSyncActionsLabel,
  invoiceSyncActionsColor,
  translations,
} from '../../../../../constants/translations';
import { Invoice, PatientInvoiceOwnershipIssue, PatientOwnershipContactInvoice } from '../../../../../graph/types';
import { useTableResetFilterAndSort } from '../../../../../hooks/tableHooks';
import { useUserLocaleData } from '../../../../../hooks/useUserLocale';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { getDateCompareFunctionFor, getStatusFilters } from '../../../../../util/filterAndSorting';
import OwnershipTag from '../OwnershipTags/OwnershipTag/OwnershipTag';
import { useBasicInvoiceSyncColumns } from './invoiceSyncColumns';

type InvoiceSyncProps = {
  invoiceIssues: PatientInvoiceOwnershipIssue[];
  selectedInvoices: Invoice[];
  setSelectedInvoices: (invoices: Invoice[]) => void;
  isLoading: boolean;
  closeModal: () => void;
  syncInvoices: () => void;
};

export type InvoiceSyncInvoice = Invoice & InvoiceSyncIssueColumns;

export type InvoiceSyncIssueColumns = Invoice & {
  current: PatientOwnershipContactInvoice[];
  expected: PatientOwnershipContactInvoice[];
  currentOwners: string[];
  expectedOwners: string[];
};

type IssueInfo = { action: InvoiceSyncActions; name: string; percent?: string | null };

export const InvoiceSync: React.FC<InvoiceSyncProps> = ({
  invoiceIssues,
  selectedInvoices,
  setSelectedInvoices,
  isLoading,
  closeModal,
  syncInvoices,
}) => {
  const filterAndSortTableValues = useTableResetFilterAndSort();
  const { filteredValue, sortOrderMap } = filterAndSortTableValues;
  const { windowWidth } = useWindowDimensions();
  const basicInvoiceSyncColumns = useBasicInvoiceSyncColumns();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const invoicesWithIssues = invoiceIssues.map(({ invoice, issue, current, expected }) => {
    const currentOwners: string[] = (current || []).map(({ name }) => name);
    const expectedOwners: string[] = (expected || []).map(({ name }) => name);
    const actions: IssueInfo[] = [];
    for (const { issue_name_key, name, percent_new } of issue) {
      actions.push({ action: issue_name_key as InvoiceSyncActions, name, percent: percent_new });
    }

    return { ...invoice, current: current ?? [], expected: expected ?? [], actions, currentOwners, expectedOwners };
  });

  const getModalWidth = () => {
    if (windowWidth > 1600) {
      return 1600;
    }
    if (windowWidth > 1200) {
      return 1200;
    }
    return 1000;
  };

  const getInvoiceSyncActionString = ({ action, name, percent }: IssueInfo) => {
    const actionString = `${invoiceSyncActionsLabel[action]}:`;
    const percentAndNameString = `${name} ${percent ? `(${percent}%)` : ''}`;
    return (
      <>
        <span style={{ color: invoiceSyncActionsColor[action] }}>{actionString}&nbsp;</span>
        <span>{percentAndNameString}</span>
      </>
    );
  };

  const toggleInvoice = (invoice: Invoice) => {
    const alreadySelectedInvoice = selectedInvoices.find(({ id }) => id === invoice.id);
    if (!alreadySelectedInvoice) {
      setSelectedInvoices([...selectedInvoices, invoice]);
    } else {
      const filteredInvoices = selectedInvoices.filter(({ id }) => id !== invoice.id);
      setSelectedInvoices(filteredInvoices);
    }
  };

  const columns: CustomColumnType<InvoiceSyncInvoice>[] = [
    {
      ...basicInvoiceSyncColumns.number,
      filteredValue: filteredValue[invoicePropertyNames.number] ?? null,
      sortOrder: sortOrderMap[invoicePropertyNames.number],
      render: (text: string, invoice: Invoice) => (
        <TableCellLink onClick={() => toggleInvoice(invoice)}>
          <AbbreviateToWidth width={100} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
        </TableCellLink>
      ),
      width: 50,
    },
    {
      ...basicInvoiceSyncColumns.status_name_key,
      filteredValue: filteredValue[invoicePropertyNames.status_name_key] ?? null,
      sortOrder: sortOrderMap[invoicePropertyNames.status_name_key],
      filters: getStatusFilters(invoiceStatusConfigs),
      width: 100,
    },
    {
      title: translations.invoicesPage.columns.date,
      key: invoicePropertyNames.date,
      dataIndex: invoicePropertyNames.date,
      render: (date: string) => displayAsDate(date, dateFormat),
      sorter: getDateCompareFunctionFor(invoicePropertyNames.date),
      sortOrder: sortOrderMap[invoicePropertyNames.date],
      width: 120,
    },
    {
      ...basicInvoiceSyncColumns.patient_name,
      filteredValue: filteredValue[invoicePropertyNames.patient_name] ?? null,
      sortOrder: sortOrderMap[invoicePropertyNames.patient_name],
      render: (patientName: string) => (
        <AbbreviateToWidth width={200} text={patientName} paddingCompensation={PADDING_COMPENSATION_WIDTH_TEXT} />
      ),
      width: 150,
    },
    {
      ...basicInvoiceSyncColumns.total,
      filteredValue: filteredValue[invoicePropertyNames.total] ?? null,
      sortOrder: sortOrderMap[invoicePropertyNames.total],
      width: 100,
    },
    {
      ...basicInvoiceSyncColumns.current,
      render: (contacts: PatientOwnershipContactInvoice[]) => {
        return contacts.map(({ name, percent, id }) => (
          <OwnershipTag key={id} owner={{ name, percentage: percent }} tagWidth={90} navigationId={id} />
        ));
      },
      filteredValue: filteredValue[invoiceSyncPropertyNames.current] ?? null,
      sortOrder: sortOrderMap[invoiceSyncPropertyNames.current] ?? null,
      width: 150,
    },
    {
      ...basicInvoiceSyncColumns.expected,
      render: (contacts: PatientOwnershipContactInvoice[]) => {
        return contacts.map(({ name, percent, id }) => (
          <OwnershipTag key={id} owner={{ name, percentage: percent }} tagWidth={90} navigationId={id} />
        ));
      },
      filteredValue: filteredValue[invoiceSyncPropertyNames.expected] ?? null,
      sortOrder: sortOrderMap[invoiceSyncPropertyNames.expected] ?? null,
      width: 150,
    },
    {
      title: translations.patientsPage.columns.actions,
      key: 'actions',
      render: (record) => {
        return record.actions.map((info: IssueInfo) => {
          return <Row key={`${info.action}-${info.name}`}>{getInvoiceSyncActionString(info)}</Row>;
        });
      },
    },
  ];

  const footer = [
    <Button key={'cancel'} onClick={closeModal}>
      {translations.patientPage.ownership.addOwnershipModal.cancelButton}
    </Button>,
    <Button
      disabled={!selectedInvoices.length}
      data-testid={'save button'}
      key={'save'}
      onClick={syncInvoices}
      type={'primary'}
    >
      {translations.shared.saveButtonText}
    </Button>,
  ];

  return (
    <SaveSpinner isSaving={isLoading}>
      <Modal
        title={translations.patientPage.ownership.invoiceSync.title}
        open
        onCancel={closeModal}
        footer={footer}
        maskClosable={false}
        width={getModalWidth()}
      >
        <SelectEntityModalTable<InvoiceSyncInvoice>
          canSelectMultiple
          selectedEntities={selectedInvoices as InvoiceSyncInvoice[]}
          setSelectedEntities={setSelectedInvoices}
          filterAndSortTableValues={filterAndSortTableValues}
          columns={columns}
          entities={invoicesWithIssues}
          entity={translations.shared.selectEntityModal.entities.invoices}
        />
      </Modal>
    </SaveSpinner>
  );
};
