import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { translations } from '../../constants/translations';
import { flexWrapContainer, w24 } from '../../globalStyles.style';
import { getRequiredRule } from '../../util/forms';
import { DependentDropdown, OptionsWithParentId } from '../DependentDropdown/DependentDropdown';
import { FormInstance } from 'antd/lib/form';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { LOCALES } from '../../util/locales';
import { CountryFormKey, countryFormValues } from '../../constants/countryFormValues';
import { useOrganizationContext } from '../../contexts/organization/state';
import {
  getCountryKeyFromId,
  getSelectableCountries,
  getSelectableProvincesStates,
} from '../../util/countrySelectorUtil';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';

interface AddressBlockProps extends PropsWithChildren<unknown> {
  addressNumber: number;
  form: FormInstance;
  allFieldsOptional?: boolean;
  isBilling?: boolean;
  disableCountry?: boolean;
}

export interface AddressFormFields {
  address_1: string;
  address_2: string;
  address_3: string;
  city: string;
  country_id: string;
  country_prov_state_id: string;
  postal_zip: string;
}

export interface AddressForm {
  address: AddressFormFields[];
}

export const AddressBlock: React.FC<AddressBlockProps> = ({
  addressNumber,
  form,
  allFieldsOptional,
  isBilling,
  disableCountry = false,
}) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const { referenceData } = useGetReferenceData(organization?.id ?? '');
  const countryName = ['address', addressNumber, 'country_id'];
  const provStateName = ['address', addressNumber, 'country_prov_state_id'];
  const postalZipName = ['address', addressNumber, 'postal_zip'];
  const countryId = form.getFieldValue(countryName);
  const countryKey = referenceData && getCountryKeyFromId(referenceData?.country ?? [], countryId);
  const contryFormValue = countryKey ? countryFormValues[countryKey as CountryFormKey] : countryFormValues.CTRY_USA;
  const [, setDummyStateToForceUpdate] = useState({});

  const hidePostalZip = useMemo(() => LOCALES[countryKey as CountryFormKey]?.hidePostalZip, [countryKey]);

  function onCountryChange() {
    form.setFields([
      { name: provStateName, value: undefined, errors: [] },
      { name: postalZipName, value: undefined, errors: [] },
    ]);
    // force update so that the dependent dropdowns are re-rendered with correct values
    setDummyStateToForceUpdate({});
  }

  if (!organization?.ref_demographics) {
    return <p>{translations.missingAddress}</p>;
  }

  const isBillingProps = isBilling ? { labelCol: { span: 10 } } : {};

  const selectableCountries = getSelectableCountries(referenceData?.country ?? []);

  const selectableProvincesStates: OptionsWithParentId[] = getSelectableProvincesStates(
    referenceData?.prov_state ?? []
  );

  const address1 = isBilling
    ? translations.organizationSubscriptionSettings.billingAddress
    : translations.addressBlockComponent.fields.address_1;

  return (
    <div style={flexWrapContainer}>
      <MaxLengthFormItem
        style={w24}
        label={address1}
        name={['address', addressNumber, 'address_1']}
        rules={allFieldsOptional ? [] : [getRequiredRule(address1)]}
        maxLength={100}
        {...isBillingProps}
      >
        <Input autoComplete='new-password' />
      </MaxLengthFormItem>
      {!isBilling && (
        <>
          <MaxLengthFormItem
            style={w24}
            label={translations.addressBlockComponent.fields.address_2}
            name={['address', addressNumber, 'address_2']}
            maxLength={100}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <MaxLengthFormItem
            style={w24}
            label={translations.addressBlockComponent.fields.address_3}
            name={['address', addressNumber, 'address_3']}
            maxLength={100}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
        </>
      )}
      <MaxLengthFormItem
        name={['address', addressNumber, 'city']}
        style={w24}
        label={translations.addressBlockComponent.fields.city}
        rules={allFieldsOptional ? [] : [getRequiredRule(translations.addressBlockComponent.fields.city)]}
        {...isBillingProps}
        maxLength={50}
      >
        <Input autoComplete='new-password' />
      </MaxLengthFormItem>
      <Form.Item
        name={countryName}
        style={w24}
        label={translations.addressBlockComponent.fields.country}
        rules={allFieldsOptional ? [] : [getRequiredRule(translations.addressBlockComponent.fields.country)]}
        {...isBillingProps}
      >
        <Select
          {...searchableSelectParams}
          options={selectableCountries}
          placeholder={translations.addressBlockComponent.placeholder.country}
          onChange={onCountryChange}
          disabled={disableCountry}
        />
      </Form.Item>
      <DependentDropdown
        name={provStateName}
        parentId={countryId}
        options={selectableProvincesStates}
        label={contryFormValue.provState.label}
        placeholder={contryFormValue.provState.placeholder}
        parentPlaceholder={translations.missingCountryPlaceholder}
        rules={allFieldsOptional ? [] : [getRequiredRule(contryFormValue.provState.label)]}
        isBillingProps={isBillingProps}
        selectParams={searchableSelectParams}
      />

      {!hidePostalZip && (
        <MaxLengthFormItem
          name={postalZipName}
          style={w24}
          label={contryFormValue.postalZip.label}
          rules={
            allFieldsOptional
              ? [{ pattern: contryFormValue.postalZip.pattern, message: contryFormValue.postalZip.message }]
              : [
                  { pattern: contryFormValue.postalZip.pattern, message: contryFormValue.postalZip.message },
                  getRequiredRule(contryFormValue.postalZip.label),
                ]
          }
          maxLength={15}
          {...isBillingProps}
        >
          <Input autoComplete='new-password' />
        </MaxLengthFormItem>
      )}
    </div>
  );
};
