import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

type Props = {
  isChecked?: boolean;
  price?: string;
  onClick: () => void;
  onConfigureClick?: () => void;
  countryId?: string | null;
};

export const AddonCardOffline: React.FC<Props> = ({ isChecked, price, onClick, onConfigureClick, countryId }) => {
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);

  const onClickToggle = () => {
    if (isChecked) {
      setVisiblePopconfirm(true);
    } else {
      onClick();
    }
  };

  const buttonProps = {
    label: translations.addOns.cards.offline.label,
    onClick: onClickToggle,
    isToggle: true,
    isChecked,
    popconfirm: {
      title: translations.addOns.cards.offline.disableMessage,
      okText: translations.shared.popconfirm.ok,
      cancelText: translations.shared.popconfirm.cancel,
      placement: 'right',
      open: visiblePopconfirm,
      onConfirm: () => {
        setVisiblePopconfirm(false);
        onClick();
      },
      onCancel: () => {
        setVisiblePopconfirm(false);
      },
    } as PopconfirmProps,
  };

  const configureButtonProps = isChecked
    ? {
        label: translations.addOns.cards.offline.configure,
        onClick: onConfigureClick || (() => undefined),
      }
    : undefined;

  return (
    <SettingsCard
      title={translations.addOns.cards.offline.cardTitle}
      leftButton={buttonProps}
      rightButton={configureButtonProps}
    >
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.offline.text}</TextWithMargin>
        {price && (
          <TextWithMargin isBold>
            {translations.addOns.cards.offline.subText1}{' '}
            <SubscriptionCurrencyFormatter total={Number(price)} countryId={countryId} />{' '}
            {translations.addOns.cards.offline.subText2}
          </TextWithMargin>
        )}
      </FlexContainer>
    </SettingsCard>
  );
};
