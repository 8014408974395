export enum SERVICE_NOTE_NAME {
  TEXT = 'STEXT_NOTE',
  PRESCRIPTION_INSTRUCTIONS = 'SRX_INSTR',
  INFO = 'STEXT_INFO',
  VACCINATION_DEFAULTS = 'BILLING_DEFAULT',
}

export enum ServiceNoteId {
  TEXT = '1',
  PRESCRIPTION_INSTRUCTIONS = '2',
  INFO = '3',
  VACCINATION_DEFAULTS = '4',
}

export enum ServiceThirdPartyId {
  EMR = '1',
  HISA = '2',
}

export const ThirdPartyLabels: { [nameKey in ServiceThirdPartyId]: string } = {
  [ServiceThirdPartyId.EMR]: 'EMR',
  [ServiceThirdPartyId.HISA]: 'HISA',
};
