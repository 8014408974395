import React from 'react';
import { Modal, ModalProps } from 'antd';
import { translations } from '../../constants/translations';

export const AlertsModal: React.FC<ModalProps> = ({ onOk, okText, children, title }) => {
  return (
    <Modal
      title={title}
      onOk={onOk}
      open
      okText={okText ?? translations.shared.okButtonText}
      cancelButtonProps={{ hidden: true }}
      onCancel={onOk}
    >
      {children ?? null}
    </Modal>
  );
};
