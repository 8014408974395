import { Invoice, Ledger } from '../../graph/types';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { getOwnerTagsFromArrays } from '../Patients/ViewPatient/PatientOwnership/OwnershipTags/ownershipTagsUtil';

export const renderInvoiceContactTags = (invoice: Invoice) => (
  <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
    {getInvoiceContactTags(invoice)}
  </Paragraph>
);

export function getInvoiceContactTags(invoice: Invoice, invoiceLedgers?: Ledger[]) {
  const names = invoice.contact_names ?? [];
  const percentages = invoice.contact_percentages ?? [];
  const contactIds = invoice.contact_ids ?? [];
  let contactTotals: string[] | undefined;
  if (invoiceLedgers) {
    const sortedLedgers = invoiceLedgers.sort((a, b) => {
      if (!b.contact_id) {
        return -1;
      }
      if (!a.contact_id) {
        return 1;
      }
      return contactIds.indexOf(a.contact_id) - contactIds.indexOf(b.contact_id);
    });
    contactTotals = sortedLedgers.map((ledger) => ledger.total);
  }

  return getOwnerTagsFromArrays(names, percentages, contactIds, 200, contactTotals);
}
