import { Reference } from '@apollo/client';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { GetStatementRun, GetStatementRuns, UpsertStatementRun } from '../../../graph/queries/statementRuns';
import { MutationUpsertStatementRunArgs } from '../../../graph/types';
import { BasicStatementRunFields } from '../../../graph/fragments/statementFragments';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useUpsertStatementRun = () => {
  return useOfflineErrorSkipMutation<'upsertStatementRun', MutationUpsertStatementRunArgs>(UpsertStatementRun, {
    update(cache, { data }) {
      const insertedStatementRun = data?.upsertStatementRun;
      cache.modify({
        fields: {
          getStatementRuns(statementRuns: Reference[] = []) {
            const newStatementRun = cache.writeFragment({
              data: insertedStatementRun,
              fragment: BasicStatementRunFields,
              fragmentName: 'BasicStatementRunFields',
            });
            return [newStatementRun, ...statementRuns];
          },
        },
      });
    },
    onError: (error) => showErrorMessage(error.message),
  });
};

export const useUpsertStatementRunAction = (statementRunId: string, organizationId: string) => {
  return useOfflineErrorSkipMutation<'upsertStatementRun', MutationUpsertStatementRunArgs>(UpsertStatementRun, {
    onError: (error) => showErrorMessage(error.message),
    refetchQueries: [{ query: GetStatementRun, variables: { statementRunId, organizationId } }],
  });
};

export const useDeleteStatementRun = (organizationId: string) => {
  const [deleteStatementRun] = useOfflineErrorSkipMutation<'upsertStatementRun', MutationUpsertStatementRunArgs>(
    UpsertStatementRun
  );
  return (statementRunId: string) =>
    deleteStatementRun({
      variables: {
        organizationId,
        statementRun: { id: statementRunId, void: true },
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: 'StatementRun',
            id: statementRunId,
          }),
        });
        cache.gc();
      },
    });
};

export const useGetStatementRuns = (organizationId: string, practiceId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getStatementRuns'>(GetStatementRuns, {
    variables: { organizationId, practiceId },
    skip: practiceId === '',
    onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
  });

  return {
    statements: data?.getStatementRuns ?? undefined,
    statementsLoading: loading,
    statementsError: error,
  };
};

export const useGetStatementRun = (organizationId: string, statementRunId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getStatementRun'>(GetStatementRun, {
    variables: { organizationId, statementRunId },
    fetchPolicy: 'no-cache',
    onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
  });

  return {
    statement: data?.getStatementRun ?? undefined,
    statementLoading: loading,
    statementError: error,
    refetchStatement: refetch,
  };
};
