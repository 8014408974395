export type LabManagementAction = SetChangesMade;

export enum LabManagementActionType {
  setChangesMade = 'LABMANAGEMENT__SET_CHANGES_MADE',
}

export interface SetChangesMade {
  type: LabManagementActionType.setChangesMade;
  hasChanges: boolean;
}

export const setChangesMade = (hasChanges: boolean): SetChangesMade => ({
  type: LabManagementActionType.setChangesMade,
  hasChanges,
});
