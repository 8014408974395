import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { Loading } from '../../components/Loading/Loading';
import { useGetLabResultStatus } from '../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';

export const VetConnectResultHandler: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const organizationId = useGetOrganizationIdFromRoute();

  const searchParams = new URLSearchParams(location.search);

  const { labResultStatus } = useGetLabResultStatus(
    organizationId,
    searchParams.get('deviceId') || '',
    searchParams.get('orderNumber') || undefined,
    searchParams.get('accessionNumber') || undefined,
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    if (labResultStatus?.url) {
      window.location.href = labResultStatus.url;
    }
  }, [labResultStatus]);

  return <Loading />;
};
