import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='150'
      height='40'
      version='1.1'
      viewBox='0 0 1622.765 431.048'
      xmlSpace='preserve'
    >
      <defs>
        <clipPath clipPathUnits='userSpaceOnUse'>
          <path d='M0 1500h1500V0H0z'></path>
        </clipPath>
      </defs>
      <g transform='matrix(1.33333 0 0 -1.33333 -387.35 1187.18)'>
        <g transform='translate(102.369 20.657)'>
          <g transform='matrix(.81407 0 0 .81407 1240.038 630.222)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h-7.897c-3.729 0-7.154.731-10.179 2.175-2.919 1.392-5.341 3.385-7.197 5.921-1.858 2.536-3.058 5.491-3.568 8.784-.529 3.412-.291 7.032.708 10.761l7.406 27.64c7.27 0 13.164 0 9.636-13.162l-3.879-14.478c-2.142-7.996 2.603-14.479 10.599-14.479h7.897c7.996 0 16.216 6.483 18.359 14.479l3.879 14.478c3.527 13.162 9.42 13.162 16.689 13.162l-7.406-27.64c-.999-3.729-2.701-7.349-5.059-10.761a40.514 40.514 0 00-8.274-8.784c-3.216-2.536-6.705-4.529-10.37-5.921C7.545.731 3.729 0 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 1196.396 645.02)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-.572-2.131-.535-4.212.106-6.019l2.121-5.96c1.781-5 4.964-6.199 9.507-6.199h8.207L16.27-7.849 13.47.024c3.408 4.108 5.87 8.748 7.147 13.49 1.002 3.721 1.242 7.336.712 10.744-.51 3.279-1.707 6.227-3.559 8.763-1.871 2.559-4.315 4.571-7.263 5.978-3.053 1.456-6.506 2.195-10.263 2.195h-7.748c-3.715 0-7.517-.726-11.301-2.159-3.652-1.382-7.131-3.359-10.34-5.878a40.57 40.57 0 01-8.267-8.726c-2.36-3.39-4.073-6.99-5.091-10.7-1.022-3.725-1.275-7.352-.751-10.78.503-3.29 1.696-6.252 3.548-8.804 1.868-2.573 4.309-4.597 7.255-6.016 3.056-1.473 6.513-2.219 10.271-2.219h16.827C-1.826-.926-7.719-.926-14.988-.926h-3.664c-3.916 0-7.195 1.545-9.231 4.352-2.006 2.762-2.531 6.392-1.48 10.221 2.176 7.932 10.399 14.384 18.332 14.384h7.748c3.931 0 7.215-1.531 9.247-4.311 1.998-2.735 2.529-6.353 1.496-10.188-.722-2.678-2.159-5.289-4.159-7.552C1.709 4.179.567 2.11 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 1325.833 633.111)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0l-22.065 34.649-6.709-25.036c-3.527-13.162-9.42-13.162-16.69-13.162l11.936 44.541c2.628 9.811 15.962 14.466 20.593 7.192l22.066-34.65L15.84 38.57c3.526 13.162 9.42 13.162 16.688 13.162L20.594 7.191C17.966-2.618 4.632-7.274 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 1269.42 630.222)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0l11.286 42.119c3.527 13.162 9.42 13.162 16.689 13.162L16.689 13.162C13.162 0 7.27 0 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 1138.823 664.51)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h15.795c7.269 0 13.163 0 16.689 13.162H3.527c-3.728 0-7.546-.731-11.343-2.175-3.666-1.392-7.156-3.383-10.371-5.921a40.543 40.543 0 01-8.275-8.784c-2.357-3.411-4.059-7.032-5.059-10.761-.999-3.728-1.237-7.349-.708-10.76.51-3.293 1.71-6.248 3.568-8.784 1.856-2.537 4.278-4.529 7.197-5.921 3.026-1.444 6.449-2.175 10.179-2.175h28.957c3.526 13.162-2.366 13.162-9.636 13.162H-7.759c-5.615 0-9.633 3.213-10.778 7.897H-.378c7.27 0 13.163 0 16.69 13.163h-31.323C-11.354-3.213-5.615 0 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 1378.774 664.51)'>
            <path
              fill='#74908d'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h15.795c7.269 0 13.163 0 16.689 13.162H3.527c-3.728 0-7.546-.731-11.343-2.175-3.666-1.392-7.156-3.383-10.371-5.921a40.543 40.543 0 01-8.275-8.784c-2.357-3.411-4.059-7.032-5.059-10.761-.999-3.728-1.237-7.349-.708-10.76.51-3.293 1.71-6.248 3.568-8.784 1.856-2.537 4.278-4.529 7.197-5.921 3.026-1.444 6.449-2.175 10.179-2.175h28.957c3.526 13.162-2.366 13.162-9.636 13.162H-7.759c-5.615 0-9.633 3.213-10.778 7.897H-.378c7.27 0 13.163 0 16.69 13.163h-31.323C-11.354-3.213-5.615 0 0 0'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 724.821 764.998)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h64.223c14.214 0 25.736 0 32.631 25.736H6.779c-8.937 0-18.271-3.48-26.284-9.799-8.012-6.319-13.743-14.721-16.138-23.658-2.394-8.936-1.166-17.338 3.46-23.657 4.626-6.319 12.096-9.8 21.033-9.8h56.502c4.236 0 6.871-3.364 5.8-7.6-1.089-4.312-5.524-7.841-9.821-7.841h-64.34c-14.214 0-25.736 0-32.632-25.736h89.829c9.008 0 18.639 3.524 26.691 9.926 7.958 6.328 13.642 14.716 16.004 23.629 2.382 8.986 1.031 17.615-3.729 23.883-4.641 6.114-12.007 9.476-20.79 9.476H-4.255c-4.297 0-6.84 3.529-5.619 7.84C-8.674-3.364-4.237 0 0 0'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 834.548 764.998)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h64.223c14.214 0 25.736 0 32.631 25.736H6.778c-8.936 0-18.27-3.48-26.283-9.799-8.012-6.319-13.743-14.721-16.138-23.658-2.394-8.936-1.166-17.338 3.46-23.657 4.626-6.319 12.095-9.8 21.033-9.8h56.502c4.236 0 6.871-3.364 5.799-7.6-1.088-4.312-5.523-7.841-9.82-7.841h-64.34c-14.214 0-25.736 0-32.633-25.736h89.83c9.008 0 18.639 3.524 26.691 9.926C68.837-66.101 74.52-57.713 76.883-48.8c2.382 8.986 1.03 17.615-3.729 23.883-4.641 6.114-12.008 9.476-20.79 9.476H-4.255c-4.297 0-6.84 3.529-5.619 7.84C-8.674-3.364-4.237 0 0 0'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 1184.866 764.998)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-15.609 0-31.712-12.699-35.894-28.31-4.183-15.609 5.114-28.309 20.723-28.309H.271c15.61 0 31.712 12.7 35.895 28.309C40.349-12.699 31.052 0 15.442 0zm-6.625-82.355h-15.441c-7.291 0-13.987 1.431-19.902 4.252-5.708 2.723-10.443 6.619-14.073 11.578-3.631 4.959-5.977 10.737-6.976 17.174-1.034 6.672-.567 13.751 1.386 21.041 1.953 7.291 5.281 14.371 9.891 21.041 4.447 6.438 9.89 12.216 16.179 17.175 6.288 4.96 13.11 8.855 20.277 11.577 7.428 2.823 14.889 4.253 22.18 4.253h15.442c7.29 0 13.986-1.43 19.901-4.253 5.708-2.722 10.443-6.617 14.074-11.577 3.629-4.959 5.976-10.737 6.974-17.175 1.034-6.67.568-13.75-1.385-21.041-1.954-7.29-5.281-14.369-9.89-21.041-4.449-6.437-9.892-12.215-16.18-17.174s-13.11-8.855-20.276-11.578C8.128-80.924.665-82.355-6.625-82.355'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 1324.476 697.955)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h-97.797c-7.107 0-11.324 5.762-9.42 12.868l25.515 95.224c14.214 0 25.736 0 18.84-25.737l-15.171-56.619h59.192C-4.627 25.736 6.896 25.736 0 0'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 1069.044 697.955)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h-33.456c6.895 25.736 18.418 25.736 32.632 25.736h7.475c15.564 0 31.959 12.733 36.137 28.296 4.191 15.616-5.106 28.323-20.721 28.323h-43.751l-15.17-56.619C-43.751 0-55.273 0-69.487 0l25.515 95.224c1.905 7.106 9.209 12.868 16.316 12.868h56.619c7.291 0 13.986-1.431 19.902-4.253 5.707-2.723 10.443-6.617 14.073-11.577 3.63-4.959 5.977-10.738 6.975-17.175 1.034-6.671.567-13.75-1.386-21.041-1.953-7.29-5.281-14.369-9.89-21.041-4.448-6.438-9.891-12.216-16.179-17.175-6.288-4.959-13.11-8.854-20.277-11.577C14.754 1.432 7.291 0 0 0'
            ></path>
          </g>
          <g transform='matrix(.81407 0 0 .81407 552.823 697.955)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0h-56.619c-7.29 0-13.986 1.432-19.902 4.253-5.708 2.723-10.442 6.618-14.073 11.577-3.631 4.959-5.977 10.737-6.974 17.175-1.035 6.672-.569 13.751 1.384 21.041 1.955 7.291 5.282 14.37 9.891 21.041 4.447 6.437 9.891 12.216 16.179 17.175 6.289 4.96 13.111 8.854 20.277 11.577 7.428 2.822 14.891 4.253 22.181 4.253h56.619C22.067 82.355 10.545 82.355-3.669 82.355h-30.639c-15.562 0-31.958-12.732-36.135-28.294-4.193-15.618 5.105-28.325 20.719-28.325h30.884c14.214 0 25.736 0 18.84-25.736'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 637.36 785.95)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-7.291 0-14.753-1.431-22.181-4.253-7.167-2.723-13.988-6.617-20.277-11.577-6.288-4.959-11.731-10.737-16.179-17.175-4.609-6.671-7.936-13.75-9.89-21.041l-14.482-54.046c14.214 0 25.737 0 32.632 25.737l7.586 28.309c4.189 15.635 20.26 28.31 35.895 28.31h43.751l-7.585-28.31H-6.761c-14.214 0-25.736 0-32.632-25.736h61.767l-7.586-28.31c14.214 0 25.736 0 32.632 25.737l18.619 69.487C67.943-5.762 63.726 0 56.619 0z'
            ></path>
          </g>
          <g fill='#fff' fillOpacity='1' transform='matrix(.81407 0 0 .81407 966.322 785.95)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-7.29 0-14.753-1.431-22.181-4.253-7.166-2.723-13.988-6.617-20.276-11.577-6.289-4.959-11.732-10.737-16.18-17.175-4.609-6.671-7.936-13.75-9.89-21.041l-14.482-54.046c14.214 0 25.737 0 32.632 25.737l7.586 28.309c4.189 15.635 20.26 28.31 35.895 28.31h43.751l-7.585-28.31H-6.761c-14.214 0-25.736 0-32.632-25.736h61.767l-7.586-28.31c14.214 0 25.736 0 32.632 25.737l18.619 69.487C67.943-5.762 63.727 0 56.619 0z'
            ></path>
          </g>
        </g>
        <g fill='#fff' fillOpacity='1' transform='translate(33.369 17.427)'>
          <g transform='translate(435.645 758.972)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-.109 2.772-.734 5.401-1.663 7.854-1.873 4.919-4.91 9.121-8.326 12.767-.424.461-.87.898-1.304 1.346l-1.331 1.29c-.394.392-.793.771-1.187 1.173l-1.173 1.246a124.394 124.394 0 00-4.502 5.351c-2.928 3.679-5.758 7.475-8.55 11.3a849.817 849.817 0 00-8.3 11.558l-8.163 11.68c-5.415 7.811-10.73 15.696-16.049 23.581l-.501-.31c4.704-8.275 9.575-16.45 14.496-24.602a730.759 730.759 0 017.491-12.164c2.541-4.031 5.094-8.057 7.733-12.034 2.644-3.975 5.331-7.927 8.159-11.816 1.422-1.941 2.868-3.876 4.409-5.782l1.186-1.428c.407-.475.841-.945 1.263-1.418l1.149-1.271c.364-.432.741-.854 1.094-1.293 2.84-3.487 5.19-7.316 6.661-11.319.742-1.992 1.249-4.038 1.401-6.018.082-.986.046-1.95-.078-2.836-.128-.88-.404-1.672-.727-2.195l-1.564-2.535 1.12.184s-.001.012.002.003l.01-.035.021-.075.044-.175a10.734 10.734 0 00.205-1.181 12.02 12.02 0 00.082-1.629 9.811 9.811 0 00-.522-3.104 7.946 7.946 0 00-1.565-2.644c-.698-.79-1.587-1.464-2.592-2.039-1.015-.565-2.158-.991-3.34-1.364a30.753 30.753 0 00-3.727-.789c-2.56-.375-5.215-.48-7.9-.525l-.078-.583c.654-.174 1.314-.33 1.976-.494.329-.089.664-.149.999-.209l1.008-.184c.671-.137 1.354-.214 2.037-.294.684-.095 1.375-.126 2.071-.17 1.39-.094 2.802-.033 4.232.099 1.426.2 2.876.47 4.301 1.011 1.422.538 2.819 1.311 4.052 2.37 1.237 1.053 2.262 2.406 2.992 3.884.714 1.487 1.126 3.081 1.308 4.662a17.018 17.018 0 01.004 3.525c.64 1.114 1.017 2.257 1.27 3.368C-.047-2.813.037-1.387 0 0'
            ></path>
          </g>
          <g transform='translate(340.676 776.324)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0l-.658.345c-.435.236-.893.444-1.311.697-.824.524-1.7.993-2.449 1.601l-.585.43-.293.215c-.094.076-.18.159-.269.238-.355.323-.722.631-1.086.946a22.541 22.541 0 00-3.595 4.437l-.699 1.261c-.214.433-.394.884-.607 1.321l-.153.332-.125.344-.254.691c-.187.455-.312.934-.447 1.413-1.1 3.823-1.323 8.042-.974 12.287.164 2.128.475 4.267.886 6.407.424 2.138.909 4.287 1.555 6.42-1.29-1.826-2.368-3.807-3.302-5.858a41.95 41.95 0 01-2.326-6.401c-1.21-4.409-1.695-9.172-.907-13.969.407-2.386 1.107-4.78 2.229-7.01 1.104-2.236 2.592-4.283 4.29-6.108.443-.434.885-.873 1.338-1.299.113-.106.224-.217.341-.319l.36-.289.723-.578c.946-.807 1.989-1.435 3.006-2.12.251-.178.521-.312.787-.457l.798-.431.801-.429.401-.214.409-.184 1.642-.725.822-.359.777-.305a250.177 250.177 0 016.267-2.313c2.107-.72 4.214-1.434 6.337-2.091 4.242-1.322 8.514-2.503 12.738-3.637l3.146-.866a178.17 178.17 0 003.095-.915 57.11 57.11 0 003.017-1.019c.503-.172.978-.379 1.47-.568.476-.21.963-.411 1.429-.638.956-.429 1.849-.95 2.743-1.492a23.954 23.954 0 002.538-1.869c1.612-1.382 3.072-3.025 4.426-4.799-.721 2.107-1.704 4.172-3.064 6.045-.674.94-1.451 1.813-2.278 2.646a25.325 25.325 0 01-2.69 2.259c-1.896 1.382-3.937 2.487-5.986 3.48-1.024.504-2.06.952-3.094 1.399-1.034.439-2.066.873-3.104 1.276l-3.101 1.205-3.097 1.156-6.163 2.282-3.071 1.135c-1.02.387-2.043.762-3.059 1.158A326.092 326.092 0 003.521-1.5l-.762.289-.696.297-1.387.592-.346.149z'
            ></path>
          </g>
          <g transform='translate(279.47 828.353)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0a83.618 83.618 0 005.074 6c1.764 1.935 3.659 3.742 5.617 5.474 1.951 1.742 4.01 3.359 6.131 4.897 4.254 3.059 8.835 5.68 13.646 7.88 4.813 2.192 9.861 3.95 15.06 5.283a95.232 95.232 0 0011.939 2.276c1.351.181 2.71.302 4.082.387-2.706.512-5.476.711-8.243.751a67.993 67.993 0 01-8.322-.405 72.739 72.739 0 01-16.35-3.748 70.336 70.336 0 01-7.791-3.313 68.967 68.967 0 01-7.369-4.233c-2.375-1.557-4.637-3.285-6.815-5.11a74.903 74.903 0 01-6.163-5.881 85.853 85.853 0 01-5.432-6.545c-1.655-2.297-3.227-4.65-4.653-7.085-.354-.61-.689-1.231-1.033-1.845l-.514-.924-.483-.938c-.32-.626-.649-1.248-.959-1.879l-.887-1.911-.441-.956-.412-.967-.82-1.936-.745-1.962c-.249-.653-.499-1.306-.719-1.969a104.263 104.263 0 01-5.724-32.572c.381 1.325.712 2.652 1.068 3.973l1.093 3.946 1.151 3.913c.418 1.293.8 2.591 1.224 3.876 1.681 5.141 3.572 10.176 5.758 15.046A109.506 109.506 0 00-4.526-6.462 96.187 96.187 0 000 0'
            ></path>
          </g>
          <g transform='translate(410.018 643.301)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0c-2.017 6.92-5.294 13.415-9.264 19.306a90.41 90.41 0 01-6.49 8.393 105.828 105.828 0 01-7.262 7.594c-5.067 4.795-10.435 9.176-16 13.206a260.993 260.993 0 01-17.067 11.35 476.03 476.03 0 01-8.737 5.224c-2.922 1.712-5.873 3.362-8.817 5.013-2.949 1.643-5.89 3.285-8.847 4.89l-2.218 1.204-1.108.601-1.075.598-2.143 1.199-2.116 1.242c-.71.407-1.398.846-2.094 1.273-.695.43-1.394.854-2.076 1.301l-2.058 1.326-2.025 1.371a122.568 122.568 0 00-7.806 5.86c-5.052 4.09-9.688 8.663-13.751 13.697a77.198 77.198 0 00-5.563 7.939c-1.691 2.759-3.143 5.659-4.416 8.643a60.985 60.985 0 00-4.594 18.961 59.816 59.816 0 00.068 9.915c.289 3.314.859 6.624 1.85 9.842-.754-1.51-1.351-3.099-1.875-4.711a52.887 52.887 0 01-1.332-4.921 55.88 55.88 0 01-1.257-10.176c-.24-6.872.785-13.835 2.914-20.464a65.567 65.567 0 013.98-9.663 74.085 74.085 0 015.366-8.985c3.98-5.739 8.708-10.923 13.837-15.6 5.155-4.656 10.69-8.855 16.491-12.595l2.181-1.392 2.208-1.345 1.104-.669 1.083-.645 2.163-1.289 8.646-5.131c11.475-6.877 22.85-13.803 33.457-21.568a216.15 216.15 0 007.781-5.99c2.526-2.06 4.989-4.183 7.343-6.398 4.719-4.414 9.085-9.143 12.738-14.316a67.503 67.503 0 004.966-8.028 61.973 61.973 0 003.682-8.617 54.406 54.406 0 002.239-9.096 58.99 58.99 0 00.757-9.41c.007-3.168-.234-6.354-.648-9.536a86.77 86.77 0 00-.795-4.763 116.763 116.763 0 00-1.072-4.74c-.8-3.149-1.756-6.277-2.862-9.367a123.84 123.84 0 00-3.646-9.186c-2.643-6.066-5.712-11.987-9.013-17.803a282.814 282.814 0 00-5.13-8.644c-1.74-2.874-3.575-5.691-5.397-8.529 2.273 2.491 4.445 5.07 6.601 7.672a193.87 193.87 0 016.208 8.023 174.33 174.33 0 015.778 8.383 156.453 156.453 0 015.245 8.78 128.822 128.822 0 014.59 9.21 107.322 107.322 0 013.756 9.668 86.592 86.592 0 012.68 10.127 82.26 82.26 0 01.856 5.216 70.11 70.11 0 01.46 5.296c.18 3.548.089 7.135-.323 10.699A63.009 63.009 0 010 0'
            ></path>
          </g>
          <g transform='translate(375.687 870.571)'>
            <path
              fill='#fff'
              fillOpacity='1'
              fillRule='nonzero'
              stroke='none'
              d='M0 0a.274.274 0 00.021.053L.009.008l-.048-.17.002.013c.012.054.019.1.037.149m-23.879-10.262c.519.008 1.038.03 1.555.065.516.038 1.037.068 1.553.125.515.053 1.029.123 1.546.184.516.065 1.025.161 1.538.247.513.087 1.028.169 1.533.291A49.459 49.459 0 01-4.372-5.237 47.115 47.115 0 01-.921-3.326l-1.527-5.556c-.534-1.948-1.073-3.881-1.703-5.859.732.74 1.388 1.556 1.994 2.4.613.841 1.164 1.718 1.702 2.603C.607-7.963 1.543-6.127 2.408-4.266c.429.933.843 1.872 1.229 2.822l.074.178.018.044.046.145A2.622 2.622 0 013.691.711 2.711 2.711 0 011.485 2.37a2.77 2.77 0 01-1.754-.395l-.095-.054-.162-.089-.645-.354C-2.034 1.016-2.854.48-3.739.06l-1.298-.683-.646-.347-.661-.318C-9.841-3.04-13.438-4.601-17.128-6.03a144.025 144.025 0 00-5.613-2.05c-1.9-.655-3.818-1.29-5.797-1.905a21.253 21.253 0 013.102-.273c.52-.019 1.039-.016 1.557-.004'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
