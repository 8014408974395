import { StatementContactInterface } from '../../graph/types';
import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { translations } from '../../constants/translations';
import { StatementRunProperty, statementRunPropertyNames } from '../../constants/propertyNames';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';

export const basicStatementRunColumns: Record<StatementRunProperty, CustomColumnType<StatementContactInterface>> = {
  name: {
    title: translations.statementRun.columns.contact,
    dataIndex: statementRunPropertyNames.name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.statementRun.columns.contact),
    onFilter: getOnFilterFunctionFor(statementRunPropertyNames.name),
    sorter: getStringCompareFunctionFor(statementRunPropertyNames.name),
  },
  contact_status_name: {
    title: translations.statementRun.columns.status,
    dataIndex: statementRunPropertyNames.contact_status_name,
    ...generalFilteringAndSortingSettings,
    onFilter: (value, record) => record.contact_status_id.includes(value.toString()),
    sorter: getStringCompareFunctionFor(statementRunPropertyNames.contact_status_name),
  },
};
