import React, { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { translations } from '../../../constants/translations';
import { useGetLabRefServices, useGetLabServiceMapping } from '../../../hooks/ajax/lab/labHooks';
import { taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { TaxonomyTable } from '../TaxonomyTab/TaxonomyTable';

interface ServiceMappingTableProps {
  labId: string;
  organizationId: string;
}

export const ServiceMappingTable: React.FC<ServiceMappingTableProps> = ({ labId, organizationId }) => {
  const {
    labServicesMapping,
    loading: loadingLabServiceMapping,
    refetch: refetchLabServiceMapping,
  } = useGetLabServiceMapping(organizationId, labId);
  const {
    labServices,
    loading: labRefServicesLoading,
    refetch: refetchLabRefServices,
  } = useGetLabRefServices(organizationId, labId);

  useEffect(() => {
    refetchLabServiceMapping({ organizationId, labDeviceId: labId });
    refetchLabRefServices({ organizationId, labRefFilter: { lab_device_id: labId } });
  }, [labId, organizationId, refetchLabServiceMapping, refetchLabRefServices]);

  if (loadingLabServiceMapping || labRefServicesLoading) {
    return <Loading labelText={translations.shared.communicatingWithLabMessage} />;
  }

  const servicesOptions =
    labServices?.map(({ lab_service_number, lab_service_name }) => ({
      label: lab_service_name,
      value: lab_service_number,
    })) ?? [];

  return (
    <TaxonomyTable
      options={servicesOptions}
      dataSource={labServicesMapping ?? []}
      type={TaxonomyType.Service}
      typeTranslations={taxonomyTranslations.tabs.service}
      deviceId={labId}
    />
  );
};
