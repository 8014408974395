import { Store } from 'antd/lib/form/interface';
import { OptionsWithSpeciesNumber } from '../components/DependentDropdown/DependentDropdown';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';
import { GetThirdPartyRefGenders } from '../graph/queries/thirdPartyData';
import { OrganizationDto, Query, ThirdPartyGenderMapUpsert, ThirdPartyTaxonomy } from '../graph/types';
import AppSyncService from '../services/AppSyncService/AppSyncService';
import { TaxonomyType } from '../util/taxonomyUtil';
import { LDFlagNames } from '../constants/launchDarkly';
import { useLDFlag } from '../hooks/useLDHooks';
import { useMemo } from 'react';
import {
  OrganizationSubscriptionLevelNameKeys,
  organizationSubscriptionLevelConfigs,
} from '../constants/referenceData/organizationSubscriptionReferenceData';

export type ThirdPartyTaxonomyData = {
  cassadolValue: string;
  cassadolValueName: string;
  requiresMap: boolean;
  type: TaxonomyType;
  thirdPartyId: string;
  thirdPartyKey: string;
};

export const mapTaxonomyByThirdParty = (
  taxonomy: ThirdPartyTaxonomy[],
  taxonomyBy3P: Record<string, ThirdPartyTaxonomyData[]>
) => {
  taxonomy.forEach((tax) => {
    const thirdPartyArray = taxonomyBy3P[tax.third_party_id];
    const typesInData = thirdPartyArray.map((data) => data.type);
    if (!typesInData.includes(TaxonomyType.Gender) && tax.requires_gender_map) {
      thirdPartyArray.push({
        cassadolValue: tax.gender_id ?? '',
        cassadolValueName: `${translations.taxonomyModal.columns.cassadol.gender} ${tax.gender_name ?? ''}`,
        requiresMap: true,
        type: TaxonomyType.Gender,
        thirdPartyId: tax.third_party_id,
        thirdPartyKey: tax.third_party_name_key,
      });
    }
  });
};

export const fillThirdPartyOptionsMap = async (
  tableData: ThirdPartyTaxonomyData[],
  organizationId: string,
  setOptionsMap: (initialOptionsMap: Record<string, Record<string, OptionsWithSpeciesNumber[]>>) => void,
  handleCloseModal: () => void
) => {
  let initialOptionsMap: Record<string, Record<string, OptionsWithSpeciesNumber[]>> = {};
  for (const taxonomy of tableData) {
    if (taxonomy.type === TaxonomyType.Gender) {
      try {
        const genders = await AppSyncService.client.query<Pick<Query, 'getThirdPartyRefGenders'>>({
          query: GetThirdPartyRefGenders,
          variables: { organizationId, thirdPartyRefFilter: { third_party_id: taxonomy.thirdPartyId } },
        });

        initialOptionsMap = {
          ...initialOptionsMap,
          [taxonomy.thirdPartyKey]: {
            ...initialOptionsMap[taxonomy.thirdPartyKey],
            [TaxonomyType.Gender]:
              genders.data?.getThirdPartyRefGenders?.map(({ third_party_gender_name, third_party_gender_number }) => ({
                label: third_party_gender_name,
                value: third_party_gender_number,
                key: third_party_gender_number,
              })) ?? [],
          },
        };
      } catch (e) {
        showErrorMessage(e.message);
        handleCloseModal();
      }
    }
  }
  if (Object.keys(initialOptionsMap).length) {
    setOptionsMap(initialOptionsMap);
  }
};

export const getThirdPartyUpsert = (values: Store, thirdPartyIds: string[]) => {
  const thirdPartyUpserts = thirdPartyIds.reduce<ThirdPartyGenderMapUpsert[]>((acc, thirdPartyId) => {
    const thirdPartyUpsert: ThirdPartyGenderMapUpsert = {};
    Object.keys(values)
      .filter((k) => k.includes('third_party') && !k.includes('third_party_name'))
      .forEach((key) => {
        const [thirdId, taxonomyType, cassadolId] = key.split('_');
        if (thirdId === thirdPartyId) {
          if (taxonomyType === TaxonomyType.Gender) {
            thirdPartyUpsert.record = {
              third_party_id: thirdPartyId,
              cassadol_gender_id: cassadolId,
              third_party_gender_name: values[`${key}_name`],
              third_party_gender_number: values[key],
            };
          }
        }
      });

    acc.push(thirdPartyUpsert);
    return acc;
  }, []);

  return thirdPartyUpserts;
};

export const useQuickBooksAligned = (organization?: OrganizationDto) => {
  const isQuickBooksFlagOn = useLDFlag(LDFlagNames.QuickBooks);
  const quickbooksAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.QuickBooksLevel].level_id
      ),
    [organization]
  );
  const isQuickBooksAligned = useMemo(
    () => (isQuickBooksFlagOn && quickbooksAddon?.enabled) ?? false,
    [isQuickBooksFlagOn, quickbooksAddon?.enabled]
  );

  return { isQuickBooksAligned, isQuickBooksFlagOn };
};
