import React, { CSSProperties } from 'react';
import { Collapse } from 'antd';
import './ExpandableSection.css';

const { Panel } = Collapse;

export const ExpandableSection = ({
  header,
  expandedContent,
  containerStyle,
  contentContainerStyle,
  bordered = true,
  contentKey,
}: {
  header: React.ReactNode | string;
  expandedContent: React.ReactNode | string;
  containerStyle?: CSSProperties;
  contentContainerStyle?: CSSProperties;
  bordered?: boolean;
  contentKey: string;
}) => {
  return (
    <Collapse style={containerStyle ?? { background: 'white', borderRadius: '8px' }} bordered={bordered}>
      <Panel
        style={contentContainerStyle ?? { background: 'white', borderRadius: '8px' }}
        header={header}
        key={contentKey}
      >
        {expandedContent}
      </Panel>
    </Collapse>
  );
};
