import { gql } from '@apollo/client';
import { AddressFields, InfoFields, PhoneFields } from '../fragments';

export const ContactFieldsForListView = gql`
  fragment ContactFieldsForListView on Contact {
    id
    number
    updated
    name
    contact_status_id
    contact_status_name
    email
    dob
    tag_names
    organization_id
    type_name_keys
    balance_posted
    balance_unposted
    offline_id
    hidden
  }
`;

export const BasicContactFields = gql`
  fragment BasicContactFields on Contact {
    ...ContactFieldsForListView
    send_type
    statement_ignore
    interest_ignore
    tax_exempt {
      id
    }
  }
  ${ContactFieldsForListView}
`;

export const ContactCreditCardFields = gql`
  fragment ContactCreditCardFields on Contact {
    credit_card {
      id
      card_type_id
      card_type_name_key
      display_number
      card_token
      expiry_month
      expiry_year
      nick_name
      processor_type_id
      primary
    }
  }
`;

export const ContactCommunicationfields = gql`
  fragment ContactCommunicationFields on ContactCommunication {
    id
    communication_type_id
    send_type
    primary_address
    secondary_address
  }
`;

export const OfflineContactFields = gql`
  fragment OfflineContactFields on Contact {
    ...BasicContactFields
    address {
      ...AddressFields
    }
    phone {
      ...PhoneFields
    }
    info {
      ...InfoFields
    }
    text {
      id
    }
    communication {
      ...ContactCommunicationFields
    }
    third_party {
      id
      contact_id
      third_party_id
      region_id
    }
    tag {
      type_id
      name
      sort_order
    }
    type {
      type_id
      name_key
      invoice_ownership
      selectable
      contact_toggle
    }
    alert {
      id
      note
      type_id
    }
    ...ContactCreditCardFields
  }
  ${BasicContactFields}
  ${AddressFields}
  ${PhoneFields}
  ${InfoFields}
  ${ContactCreditCardFields}
  ${ContactCommunicationfields}
`;

export const ContactFields = gql`
  fragment ContactFields on Contact {
    ...OfflineContactFields
  }
  ${OfflineContactFields}
`;

export const ContactTaxExemptFields = gql`
  fragment ContactTaxExemptFields on Contact {
    tax_exempt {
      id
      code
      compounded
      default
      name
      percentage
      sort_order
    }
  }
`;

export const ContactCommunicationLogFields = gql`
  fragment ContactCommunicationLogFields on ContactCommunicationLog {
    id
    description
    recipients
    cc
    bcc
    subject
    body
    message_sent
  }
`;
