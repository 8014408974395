export const nameFieldName = 'name';
export const phoneFieldName = 'phone';
export const emailFieldName = 'email';
export const licenseFieldName = 'license';
export const abbreviationFieldName = 'abbreviation';
export const inactiveFieldName = 'inactive';
export const typesFieldName = 'typeIds';
export const rememberDateFieldName = 'rememberDate';
export const hisaIdFieldName = 'hisaId';
export const hisaFirstNameFieldName = 'firstName';
export const hisaLastNameFieldName = 'lastName';
