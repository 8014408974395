import { MutationUpsertServiceArgs, ServiceTaxType } from '../../../graph/types';
import {
  GetHisaCategories,
  GetService,
  GetServiceRenderedInfoAndText,
  GetServices,
  GetServicesRenderedFragmentWithNote,
  GetServiceText,
  UpsertService,
} from '../../../graph/queries/services';
import { ServiceListViewFields } from '../../../graph/fragments';
import { Reference } from '@apollo/client/utilities';
import { GetInvoiceContextWithServicesRenderedWithFilter } from '../../../graph/queries/invoices';
import { findServiceTextIds } from '../../../pages/Services/serviceUtils';
import { useEffect, useMemo, useState } from 'react';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useOfflineQuery, useQueryWithElasticSearch } from '../useOfflineQuery';
import { filterByName } from '../../../util/searchUtil';
import { useOffline } from '../../../util/offline/offlineUtil';
import { QueryHookOptions } from '@apollo/client';

export const useGetServicesWithSearch = (organizationId: string, searchValue = '') => {
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const { enabledAndOffline } = useOffline();
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const searchParams = useMemo(
    () => ({
      fields: ['document.name'],
      query: searchTerm,
    }),
    [searchTerm]
  );

  const { data, loading, refetch } = useQueryWithElasticSearch<'getServicesEs'>({
    query: GetServices,
    collection: 'service',
    searchParams,
    variables,
    take: 10000,
    options: {
      fetchPolicy: 'cache-and-network',
    },
  });

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  const services = useMemo(() => {
    if (enabledAndOffline && searchTerm) {
      return data?.filter(filterByName(searchTerm));
    }
    return data;
  }, [data, enabledAndOffline, searchTerm]);

  return {
    services,
    servicesLoading: loading,
    refetchServices: refetch,
    setSearchTerm,
  };
};

export const useGetService = (serviceId: string, organizationId: string) => {
  const selector = useMemo(() => ({ _id: serviceId }), [serviceId]);
  const filter = useMemo(() => ({ id: serviceId }), [serviceId]);
  const options = useMemo(
    () => ({
      variables: { organizationId, filter },
    }),
    [organizationId, filter]
  );
  const { data, loading, error } = useOfflineQuery<'getServicesPg'>({
    query: GetService,
    collection: 'service',
    options,
    selector,
    expectList: true,
  });

  return {
    service: data?.[0],
    serviceLoading: loading,
    serviceError: error,
  };
};

export const useGetServiceText = (id: string, organizationId: string, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getServiceText'>(GetServiceText, {
    variables: { organizationId, id },
    skip: !id,
    fetchPolicy: options?.fetchPolicy,
    nextFetchPolicy: options?.nextFetchPolicy,
  });

  return {
    serviceText: data?.getServiceText ?? undefined,
    serviceTextLoading: loading,
    serviceTextError: error,
    refetchServiceText: refetch,
  };
};

export const useUpsertService = (organizationId: string) => {
  return useOfflineErrorSkipMutation<'upsertService'>(UpsertService, {
    update(cache, { data }) {
      const service = data?.upsertService;
      cache.modify({
        id: cache.identify({ __typename: 'Service', id: service?.id }),
        fields: {
          tax_code_names() {
            return service?.tax?.map((tax: ServiceTaxType) => tax.code) ?? [];
          },
          lab_mapping() {
            return service?.lab_mapping;
          },
        },
      });
    },
    refetchQueries: ({ data }) => {
      // TODO: TEST FUNCTIONALITY
      const refetchQueries = [];
      if (data?.upsertService.text) {
        const { textTypeId, prescriptionTypeId } = findServiceTextIds(data?.upsertService?.text);
        if (textTypeId) {
          refetchQueries.push({ query: GetServiceText, variables: { id: textTypeId, organizationId } });
        }

        if (prescriptionTypeId) {
          refetchQueries.push({ query: GetServiceText, variables: { id: prescriptionTypeId, organizationId } });
        }
      }
      return refetchQueries;
    },
  });
};

export const useInsertService = (params: { organizationId: string; practiceId: string }) => {
  return useOfflineErrorSkipMutation<'upsertService', MutationUpsertServiceArgs>(UpsertService, {
    update(cache, { data }) {
      const upsertService = data?.upsertService;
      cache.modify({
        id: cache.identify({ __typename: 'Organization', id: params.organizationId }),
        fields: {
          service(serviceReferences: Reference[] = []) {
            const newServiceReference = cache.writeFragment({
              data: upsertService,
              fragment: ServiceListViewFields,
              fragmentName: 'ServiceListViewFields',
            });
            return [newServiceReference, ...serviceReferences];
          },
        },
      });
    },
    refetchQueries: [{ query: GetInvoiceContextWithServicesRenderedWithFilter, variables: params }],
  });
};

export const useGetServiceRenderedFragment = ({
  patientId,
  organizationId,
}: {
  patientId: string;
  organizationId: string;
}) => {
  const selector = useMemo(() => ({ patient_id: patientId }), [patientId]);
  const filter = useMemo(() => ({ patientId, forNotes: true }), [patientId]);
  const options = useMemo(
    () => ({
      variables: { organizationId, patientId, filter },
      queryOptions: {
        skip: organizationId === '',
        fetchPolicy: 'cache-and-network',
      },
    }),
    [organizationId, patientId, filter]
  );
  const { data } = useOfflineQuery<'getServicesRendered'>({
    query: GetServicesRenderedFragmentWithNote,
    collection: 'service_rendered',
    options,
    selector,
    expectList: true,
  });

  return {
    data,
  };
};

export const useGetHisaCategories = (organizationId?: string, isHisaEnabled?: boolean, category?: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getHISABillingCategories'>(GetHisaCategories, {
    variables: { organizationId, category },
    skip: !organizationId || !isHisaEnabled,
  });

  return { hisaCategories: data?.getHISABillingCategories, categoriesLoading: loading, categoriesError: error };
};

export const useGetServiceRenderedWithInfoAndText = (organizationId?: string, serviceRenderedId?: string) => {
  const { data, loading } = useOfflineQuery<'getServiceRendered'>({
    query: GetServiceRenderedInfoAndText,
    collection: 'service_rendered',
    options: {
      variables: { organizationId, serviceRenderedId },
      skip: !serviceRenderedId || organizationId === '' || serviceRenderedId === '',
      fetchPolicy: 'cache-and-network',
    },
  });

  return {
    data,
    loading,
  };
};
