import {
  Contact,
  ContactInterestEntry,
  Invoice,
  Ledger,
  OfflineUpsertResponse,
  OrganizationSubscriptionDetailCharge,
  OrganizationSubscriptionInvoice,
  Patient,
  PaymentDeviceType,
  Reminder,
  ReminderBatchDetail,
  Service,
  StatementContactInterface,
  User,
  ServiceRendered3pApproval,
  ServiceRendered3pHistory,
  LabResultRecord,
  StatementContactSent,
  StatementContactPending,
  UnassignedLabResults,
  PatientAlert,
  ContactAlert,
  StripePaymentIntent,
  StripePayoutDetails,
  FinancialPeriod,
  DepositRun,
  Prescription,
} from '../graph/types';
import { InvoiceSyncIssueColumns } from '../pages/Patients/ViewPatient/PatientOwnership/InvoiceSync/InvoiceSync';

export type ContactProperty = 'name' | 'number' | 'email' | 'contact_status_name' | 'balance_posted';

export const contactPropertyNames: Record<ContactProperty, keyof Contact> = {
  name: 'name',
  number: 'number',
  email: 'email',
  contact_status_name: 'contact_status_name',
  balance_posted: 'balance_posted',
};

export type OrganizationSubscriptionProperty = 'start_date' | 'next_bill_date' | 'last_billed_date' | 'frequency';

export const organizationSubscriptionPropertyNames: Record<
  OrganizationSubscriptionProperty,
  OrganizationSubscriptionProperty
> = {
  start_date: 'start_date',
  next_bill_date: 'next_bill_date',
  last_billed_date: 'last_billed_date',
  frequency: 'frequency',
};

export type UserTypesProperty = 'roles';

export type UserProperty = 'name' | 'email' | 'phone' | 'inactive';

export const userPropertyNames: Record<UserProperty, keyof User> = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  inactive: 'inactive',
};

export type ServiceProperty = 'name' | 'unit_name' | 'price' | 'inactive' | 'tax_code_names' | 'general_ledger_name';

export const servicePropertyNames: Record<ServiceProperty, keyof Service> = {
  name: 'name',
  unit_name: 'unit_name',
  price: 'price',
  inactive: 'inactive',
  tax_code_names: 'tax_code_names',
  general_ledger_name: 'general_ledger_name',
};

export type PatientProperty =
  | 'name'
  | 'name_2'
  | 'name_3'
  | 'related_names'
  | 'number'
  | 'species_name'
  | 'breed_name'
  | 'gender_name'
  | 'owner_names';

export const patientPropertyNames: Record<PatientProperty, keyof Patient> = {
  name: 'name',
  name_2: 'name_2',
  name_3: 'name_3',
  number: 'number',
  related_names: 'related_names',
  species_name: 'species_name',
  breed_name: 'breed_name',
  gender_name: 'gender_name',
  owner_names: 'owner_names',
};

export type LabResultsProperty =
  | 'request_date'
  | 'contact_name'
  | 'patient_name'
  | 'lab_device_name'
  | 'service_list'
  | 'lab_created_accession_id'
  | 'pims_created_accession_id'
  | 'lab_created_order_number'
  | 'pims_created_order_number'
  | 'status_id';

export const labResultsPropertyNames: Record<LabResultsProperty, keyof LabResultRecord> = {
  request_date: 'request_date',
  contact_name: 'contact_name',
  patient_name: 'patient_name',
  lab_device_name: 'lab_device_name',
  service_list: 'service_list',
  lab_created_accession_id: 'lab_created_accession_id',
  pims_created_accession_id: 'pims_created_accession_id',
  lab_created_order_number: 'lab_created_order_number',
  pims_created_order_number: 'pims_created_order_number',
  status_id: 'status_id',
};

export type UnassignedLabResultsProperty =
  | 'result_date'
  | 'contact_name'
  | 'patient_name'
  | 'lab_device_name'
  | 'lab_tests'
  | 'lab_created_accession_id'
  | 'status_id';

export const unassignedLabResultsPropertyNames: Record<UnassignedLabResultsProperty, keyof UnassignedLabResults> = {
  result_date: 'result_date',
  contact_name: 'contact_name',
  patient_name: 'patient_name',
  lab_device_name: 'lab_device_name',
  lab_tests: 'lab_tests',
  lab_created_accession_id: 'lab_created_accession_id',
  status_id: 'status_id',
};

export type InvoiceProperty = 'number' | 'date' | 'total' | 'contact_names' | 'status_name_key' | 'patient_name';

export const invoicePropertyNames: Record<InvoiceProperty, keyof Invoice> = {
  number: 'number',
  date: 'date',
  total: 'total',
  contact_names: 'contact_names',
  status_name_key: 'status_name_key',
  patient_name: 'patient_name',
};

export type InvoiceServiceTableColumn =
  | 'date'
  | 'doctor_name'
  | 'name'
  | 'quantity'
  | 'unit_name'
  | 'unit_price'
  | 'total';

export type InvoiceServiceProperty = InvoiceServiceTableColumn | 'doctor_id';

export enum invoiceServicePropertyNames {
  date = 'date',
  doctor_name = 'doctor_name',
  doctor_id = 'doctor_id',
  name = 'name',
  quantity = 'quantity',
  unit_name = 'unit_name',
  unit_price = 'unit_price',
  total = 'total',
}

export type ServiceRendered3pApprovalProperty =
  | 'date'
  | 'doctor_name'
  | 'patient_name'
  | 'quantity'
  | 'owner_names'
  | 'service_name'
  | 'owner_names'
  | 'related_names'
  | 'validation_failed'
  | 'info';

export const serviceRendered3pApprovalPropertyNames: Record<
  ServiceRendered3pApprovalProperty,
  keyof ServiceRendered3pApproval
> = {
  date: 'date',
  doctor_name: 'doctor_name',
  patient_name: 'patient_name',
  service_name: 'service_name',
  owner_names: 'owner_names',
  related_names: 'related_names',
  quantity: 'quantity',
  validation_failed: 'validation_failed',
  info: 'info',
};

export type ServiceRendered3pHistoryProperty =
  | 'third_party'
  | 'date_sent'
  | 'doctor_name'
  | 'patient_name'
  | 'service_name'
  | 'info'
  | 'quantity'
  | 'status';

export const serviceRendered3pHistoryPropertyNames: Record<
  ServiceRendered3pHistoryProperty,
  keyof ServiceRendered3pHistory
> = {
  third_party: 'third_party_id',
  date_sent: 'sent',
  doctor_name: 'doctor_name',
  patient_name: 'patient_name',
  service_name: 'service_name',
  info: 'info',
  quantity: 'quantity',
  status: 'status_id',
};

export type LedgerProperty =
  | 'number'
  | 'financial_date'
  | 'total'
  | 'type_name_key'
  | 'financial_outstanding'
  | 'note_exists'
  | 'invoice_status_name_key'
  | 'patient_name'
  | 'contact_name'
  | 'payment_type_name_key';

export const ledgerPropertyNames: Record<LedgerProperty, keyof Ledger> = {
  number: 'number',
  financial_date: 'financial_date',
  total: 'total',
  type_name_key: 'type_name_key',
  financial_outstanding: 'financial_outstanding',
  note_exists: 'note_exists',
  invoice_status_name_key: 'invoice_status_name_key',
  patient_name: 'patient_name',
  contact_name: 'contact_name',
  payment_type_name_key: 'payment_type_name_key',
};

export type SubscriptionDetailsProperty = 'level_name_key' | 'currentQty' | 'cost' | 'total';

export const subscriptionPropertyNames: Record<
  SubscriptionDetailsProperty,
  keyof OrganizationSubscriptionDetailCharge
> = {
  level_name_key: 'level_name_key',
  currentQty: 'currentQty',
  cost: 'cost',
  total: 'total',
};

export type PaymentDeviceProperty = 'name' | 'serial_number';

export const paymentDevicePropertyNames: Record<PaymentDeviceProperty, keyof PaymentDeviceType> = {
  name: 'name',
  serial_number: 'serial_number',
};

export type StatementRunProperty = 'name' | 'contact_status_name';

export const statementRunPropertyNames: Record<StatementRunProperty, keyof StatementContactInterface> = {
  name: 'name',
  contact_status_name: 'contact_status_name',
};

export type StatementContactPendingProperty = 'ending_balance' | 'email' | 'send_type';

export const statementContactPendingPropertyNames: Record<
  StatementContactPendingProperty,
  keyof StatementContactPending
> = {
  ending_balance: 'ending_balance',
  email: 'email',
  send_type: 'send_type',
};

export type StatementContactSentProperty = 'last_send_status';

export const statementContactSentPropertyNames: Record<StatementContactSentProperty, keyof StatementContactSent> = {
  last_send_status: 'last_send_status',
};

export type ContactInterestRunEntry =
  | 'contact_name'
  | 'total'
  | 'contact_status_name'
  | 'contact_email'
  | 'bucket0'
  | 'bucket31'
  | 'bucket61'
  | 'bucket91'
  | 'bucket121';

export const interestRunPropertyNames: Record<ContactInterestRunEntry, keyof ContactInterestEntry> = {
  contact_name: 'contact_name',
  total: 'total',
  contact_status_name: 'contact_status_name',
  contact_email: 'contact_email',
  bucket0: 'bucket0',
  bucket31: 'bucket31',
  bucket61: 'bucket61',
  bucket91: 'bucket91',
  bucket121: 'bucket121',
};

export type ServiceBundleProperty = 'name' | 'unit_name' | 'price';

export const serviceBundlePropertyNames: Record<ServiceBundleProperty, keyof Service> = {
  name: 'name',
  unit_name: 'unit_name',
  price: 'price',
};

export type BillingHistoryProperty = 'number' | 'amount' | 'created';

export const billingHistoryPropertyNames: Record<BillingHistoryProperty, keyof OrganizationSubscriptionInvoice> = {
  number: 'number',
  amount: 'amount',
  created: 'created',
};

export type InvoiceSyncColumnProperty = InvoiceProperty | 'current' | 'expected' | 'currentOwners' | 'expectedOwners';

export const invoiceSyncPropertyNames: Record<InvoiceSyncColumnProperty, keyof InvoiceSyncIssueColumns> = {
  ...invoicePropertyNames,
  current: 'current',
  expected: 'expected',
  currentOwners: 'currentOwners',
  expectedOwners: 'expectedOwners',
};

export type OfflineUpsertResponseProperty = 'type_name' | 'error_message' | 'created' | 'instruction';

export const offlineUpsertResponsePropertyNames: Record<OfflineUpsertResponseProperty, keyof OfflineUpsertResponse> = {
  type_name: 'type_name',
  error_message: 'error_message',
  created: 'created',
  instruction: 'instruction',
};

export type ReminderProperty = 'name' | 'date' | 'note' | 'status_id';

export const ReminderPropertyNames: Record<ReminderProperty, keyof Reminder> = {
  name: 'name',
  date: 'date',
  note: 'note',
  status_id: 'status_id',
};

export type RemindersRunProperty =
  | 'contact_name'
  | 'patient_name'
  | 'contact_send_type_name'
  | 'note'
  | 'status_name'
  | 'name'
  | 'date';

export const remindersRunPropertyNames: Record<RemindersRunProperty, keyof Reminder> = {
  contact_name: 'contact_name',
  patient_name: 'patient_name',
  contact_send_type_name: 'contact_send_type_name',
  name: 'name',
  note: 'note',
  status_name: 'status_name',
  date: 'date',
};

export type ReminderBatchDetailProperty =
  | 'contact_name'
  | 'patient_name'
  | 'reminder_name'
  | 'reminder_date'
  | 'reminder_note'
  | 'reminder_status_name'
  | 'reminder_sent_type_name';

export const reminderBatchPropertyNames: Record<ReminderBatchDetailProperty, keyof ReminderBatchDetail> = {
  contact_name: 'contact_name',
  patient_name: 'patient_name',
  reminder_name: 'reminder_name',
  reminder_date: 'reminder_date',
  reminder_note: 'reminder_note',
  reminder_status_name: 'reminder_status_name',
  reminder_sent_type_name: 'reminder_sent_type_name',
};

export type PrescriptionProperty =
  | 'number'
  | 'prescribed_date'
  | 'prescription_expiry_date'
  | 'item_description'
  | 'item_unit'
  | 'is_filled'
  | 'refills'
  | 'item_quantity'
  | 'remaining_fills';

export const prescriptionPropertyNames: Record<PrescriptionProperty, keyof Prescription> = {
  number: 'number',
  prescribed_date: 'prescribed_date',
  prescription_expiry_date: 'prescription_expiry_date',
  item_description: 'item_description',
  item_unit: 'item_unit',
  is_filled: 'is_filled',
  refills: 'refills',
  remaining_fills: 'remaining_fills',
  item_quantity: 'item_quantity',
};

export type AlertProperty = 'type_id' | 'note';

export const alertPropertyNames: Record<AlertProperty, keyof PatientAlert> = {
  note: 'note',
  type_id: 'type_id',
};

export const contactAlertPropertyNames: Record<AlertProperty, keyof ContactAlert> = {
  note: 'note',
  type_id: 'type_id',
};

export type StripePaymentIntentProperty = 'amount' | 'created' | 'customer_name' | 'created' | 'status';

export const stripePaymentIntentPropertyNames: Record<StripePaymentIntentProperty, keyof StripePaymentIntent> = {
  amount: 'amount',
  created: 'created',
  customer_name: 'customer_name',
  status: 'status',
};

export type FinancialPeriodProperty = 'begin_date' | 'end_date' | 'status_name_key';

export const financialPeriodPropertyNames: Record<FinancialPeriodProperty, keyof FinancialPeriod> = {
  begin_date: 'begin_date',
  end_date: 'end_date',
  status_name_key: 'status_name_key',
};

export type DepositRunProperty = 'number' | 'end_date' | 'status_id' | 'paymentType';

export const depositRunPropertyNames: Record<DepositRunProperty, keyof DepositRun> = {
  number: 'number',
  end_date: 'end_date',
  status_id: 'status_id',
  paymentType: 'paymentType',
};

export type StripePayoutDetailsProperty = 'arrival_date' | 'destination' | 'amount' | 'status';

export const stripePayoutDetailsPropertyNames: Record<StripePayoutDetailsProperty, keyof StripePayoutDetails> = {
  arrival_date: 'arrival_date',
  destination: 'destination',
  amount: 'amount',
  status: 'status',
};
