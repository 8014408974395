import { useCallback, useMemo } from 'react';
import { shouldShowRequest } from '../../components/RecordSingleView/LabRequest/LabRequestCard';
import { LabRequest } from '../../graph/types';
import { useOffline } from '../../util/offline/offlineUtil';
import { useGetOrganizationIdFromRoute } from '../route/routeParameterHooks';
import { useGetFilesWithSearch } from './file/fileHooks';
import { useGetLabRequestsForPatient } from './lab/labHooks';
import { useGetNotesWithSearch } from './note/noteHooks';
import { useGetServiceActivitiesWithSearch } from './patients/patientHooks';

export enum OwnerType {
  Contact = 'contact',
  Patient = 'patient',
}

export const useGetRecords = (params: { id: string }, type: OwnerType, searchValue?: string) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const typeName = useMemo(() => (type === OwnerType.Patient ? 'patient' : 'contact'), [type]);
  const selector = useMemo(() => ({ [`${typeName}_id`]: params.id }), [params.id, typeName]);
  const { isOnline } = useOffline();

  const {
    notes,
    notesLoading,
    setSearchTerm: setNotesSearchTerm,
    refetchCurrentSearch: refetchNotes,
  } = useGetNotesWithSearch({
    [`${typeName}Id`]: params.id,
    organizationId,
    searchValue: searchValue ?? '',
    selector,
  });

  const {
    files,
    filesLoading,
    setSearchTerm: setFilesSearchTerm,
    refetchCurrentSearch: refetchFiles,
  } = useGetFilesWithSearch({
    [`${typeName}Id`]: params.id,
    organizationId,
    searchValue: searchValue ?? '',
    selector,
  });

  const {
    services,
    servicesLoading,
    setSearchTerm: setServicesSearchTerm,
    refetchCurrentSearch: refetchServices,
  } = useGetServiceActivitiesWithSearch({
    patientId: params.id,
    organizationId,
    searchValue: searchValue ?? '',
    selector,
    queryOptions: { skip: type === OwnerType.Contact },
  });

  const {
    labRequests,
    setSearchTerm: setRequestsSearchTerm,
    refetchCurrentSearch: refetchRequests,
  } = useGetLabRequestsForPatient(
    organizationId,
    params.id,
    { skip: type === OwnerType.Contact || !isOnline },
    searchValue ?? ''
  );

  // grouping the lab requests by group_id
  const requests = useMemo(() => {
    return labRequests
      ?.reduce<LabRequest[]>((acc, request) => {
        const foundIndex = acc.findIndex(({ request_group_id }) => request_group_id === request.request_group_id);
        if (foundIndex < 0) {
          acc.push(request);
        } else {
          // if request group_id is already part of accumulator, we add
          // the next request's service name to the original
          // as the other required info is the same between grouped requests
          const foundRequest = acc[foundIndex];
          acc[foundIndex] = {
            ...foundRequest,
            lab_service_name: `${foundRequest?.lab_service_name}, ${request.lab_service_name}`,
          };
        }
        return acc;
      }, [])
      .filter(shouldShowRequest);
  }, [labRequests]);

  const setSearchValue = useMemo(
    () => (value: string) => {
      setNotesSearchTerm(value);
      setFilesSearchTerm(value);
      setServicesSearchTerm(value);
      setRequestsSearchTerm(value);
    },
    [setNotesSearchTerm, setFilesSearchTerm, setServicesSearchTerm, setRequestsSearchTerm]
  );

  const refetch = useCallback(() => {
    refetchNotes();
    refetchFiles();
    refetchServices();
    refetchRequests();
  }, [refetchNotes, refetchFiles, refetchServices, refetchRequests]);

  /* 
    labRequestsLoading option removed from loading
    Even with the skip added to useGetLabRequestsForPatient, 
    if offline the loading option stay = true forever
    when the user type something into the search box on Records component
  */
  return {
    notes,
    files,
    services,
    labRequests: requests,
    loading: notesLoading || filesLoading || servicesLoading,
    setSearchValue,
    refetch,
  };
};
