import React, { useState } from 'react';
import { Button, Dropdown, Popconfirm, Spin, Tooltip } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { translations } from '../../constants/translations';
import './DropdownButtonWithMenu.css';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import { LoadingOutlined } from '@ant-design/icons';

export const dropdownButtonTestId = 'dropdownButton';

export interface MenuItemProps extends SimpleMenuItemProps {
  popconfirmProps?: PopconfirmProps;
  executeOnClickOnPopconfirmCancelInsteadOfConfirm?: boolean;
  dropdownDisabled?: boolean;
  hidden?: boolean;
}

export interface SimpleMenuItemProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  title: string;
  disabled?: boolean;
  tooltip?: string;
}

export const getMenuItemPropsWithOnCancelPopconfirm = (
  simpleMenuItemProps: SimpleMenuItemProps,
  popconfirmTitle: string
): MenuItemProps => {
  return {
    ...simpleMenuItemProps,
    executeOnClickOnPopconfirmCancelInsteadOfConfirm: true,
    dropdownDisabled: true,
    popconfirmProps: {
      title: popconfirmTitle,
      okText: translations.shared.popconfirm.no,
      cancelText: translations.shared.popconfirm.ok,
    },
  };
};

export interface DropdownButtonWithMenuProps {
  menuItemProps: MenuItemProps[];
  simpleItemWrapperText?: string;
  isLoading?: boolean;
}

export const DropdownButtonWithMenu: React.FC<DropdownButtonWithMenuProps> = ({
  menuItemProps,
  simpleItemWrapperText,
  isLoading = false,
}: DropdownButtonWithMenuProps) => {
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const getMenuItemContent = (menuItemProps: MenuItemProps) => {
    //force close menu dropdown and popconfirm as on some browsers under specific window sizes they seem to stay open and clickable
    const onClickWithForcedCloseOfMenuAndPopconfirm = (e?: React.MouseEvent<HTMLElement>) => {
      if (!menuItemProps.disabled) {
        setPopconfirmVisible(false);
        setDropdownVisible(false);
        menuItemProps.onClick(e);
      }
    };

    if (menuItemProps.popconfirmProps && !menuItemProps.disabled) {
      const confirmAction: Partial<PopconfirmProps> = getConfirmAction(
        menuItemProps.executeOnClickOnPopconfirmCancelInsteadOfConfirm,
        onClickWithForcedCloseOfMenuAndPopconfirm
      );
      return (
        <Popconfirm
          {...menuItemProps.popconfirmProps}
          {...confirmAction}
          onOpenChange={(visible) => setPopconfirmVisible(visible)}
          overlayStyle={{ width: '200px' }}
        >
          <Button
            className={'menuItemButtonInTextStyle'}
            type={'text'}
            style={{ padding: 0 }}
            data-testid={menuItemProps.title}
            title={menuItemProps.tooltip ?? ''}
            disabled={menuItemProps.disabled}
          >
            {menuItemProps.title}
          </Button>
        </Popconfirm>
      );
    }
    return (
      <Button
        className={'menuItemButtonInTextStyle'}
        type={'text'}
        style={{ padding: 0 }}
        onClick={onClickWithForcedCloseOfMenuAndPopconfirm}
        title={menuItemProps.tooltip ?? ''}
        disabled={menuItemProps.disabled}
      >
        {menuItemProps.title}
      </Button>
    );
  };

  const getMenuItems = (menuActions: MenuItemProps[]) => {
    return menuActions
      .filter(({ hidden }) => !hidden)
      .map((menuItemProps, index) => {
        return {
          key: index,
          label: getMenuItemContent(menuItemProps),
        };
      });
  };

  const getDropdownMenu = (props?: any) => (
    <Dropdown.Button
      {...props}
      menu={{ items: getMenuItems(menuItemProps.slice(1)) }}
      placement='bottom'
      buttonsRender={([leftButton, rightButton]): React.ReactNode[] => [
        React.cloneElement(leftButton as React.ReactElement<DropdownButtonProps>, {
          disabled: primaryMenuItemProps.disabled,
          title: primaryMenuItemProps.tooltip ?? '',
        }),
        React.cloneElement(rightButton as React.ReactElement<DropdownButtonProps>, {
          disabled: primaryMenuItemProps.dropdownDisabled,
          onClick: (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
        }),
      ]}
      data-testid={dropdownButtonTestId}
    >
      {isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : primaryMenuItemProps.title}
    </Dropdown.Button>
  );

  if (menuItemProps.length === 0) {
    return null;
  }

  const primaryMenuItemProps = menuItemProps[0];
  const simpleButton = (
    <Button
      onClick={primaryMenuItemProps.onClick}
      disabled={primaryMenuItemProps.disabled}
      data-testid={primaryMenuItemProps.title}
      title={primaryMenuItemProps.tooltip ?? ''}
    >
      {isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : primaryMenuItemProps.title}
    </Button>
  );
  const simplePrimaryMenuItemButton = simpleItemWrapperText ? (
    <Tooltip title={translations.referenceData.creditCardCannotEditWarning}>{simpleButton}</Tooltip>
  ) : (
    simpleButton
  );
  if (primaryMenuItemProps.popconfirmProps) {
    const confirmAction: Partial<PopconfirmProps> = getConfirmAction(
      primaryMenuItemProps.executeOnClickOnPopconfirmCancelInsteadOfConfirm,
      primaryMenuItemProps.popconfirmProps.onConfirm ?? primaryMenuItemProps.onClick
    );
    return (
      <Popconfirm {...primaryMenuItemProps.popconfirmProps} {...confirmAction}>
        {menuItemProps.length === 1 ? simplePrimaryMenuItemButton : getDropdownMenu()}
      </Popconfirm>
    );
  }

  return menuItemProps.length === 1
    ? simplePrimaryMenuItemButton
    : getDropdownMenu({
        onClick: primaryMenuItemProps.onClick,
        open: dropdownVisible || popconfirmVisible,
        onOpenChange: (visible: boolean) => setDropdownVisible(visible),
      });
};

const getConfirmAction = (
  executeOnClickOnPopconfirmCancelInsteadOfConfirm: boolean | null | undefined,
  onClick: (e?: React.MouseEvent<HTMLElement>) => void
) => (executeOnClickOnPopconfirmCancelInsteadOfConfirm ? { onCancel: onClick } : { onConfirm: onClick });
