import gql from 'graphql-tag';
import {
  BreedFields,
  ColorFields,
  ContactStatusTypeFields,
  CountryFields,
  FileTypeFields,
  GenderFields,
  InfoTypeFields,
  NoteTypeFields,
  OrganizationFields,
  OrganizationOfflineFields,
  OrganizationSubscriptionFields,
  OrganizationSubscriptionInvoiceFields,
  PatientDemographicFields,
  PhoneFields,
  PhoneTypeFields,
  PracticeFields,
  ProvStateFields,
  ReminderStatusFields,
  SpeciesFields,
  UserTypeFields,
  OrganizationAddOnFields,
  OrganizationAddressFields,
  ThirdPartyTypeFields,
  ContactTypeFields,
  AddressFields,
  PatientAlertTypeFields,
  ContactAlertTypeFields,
} from '../fragments';
import {
  FinancialGeneralLedgerFields,
  FinancialTaxTypeFields,
  GeneralLedgerTypeFields,
  PaymentDeviceTypeFields,
  PaymentTypeFields,
  ServiceRefTypeFields,
} from '../fragments/organizationReferenceDataFragments';
import { getOrganizationSettings, getOrganizationSettingsAndGeneralLedgerRefData } from './system';

export const UpsertOrganization = gql`
  mutation upsertOrganization($organizationId: ID!, $organization: OrganizationUpsert!) {
    upsertOrganization(organizationId: $organizationId, organization: $organization) {
      id
      ref_demographics {
        id
        phone_type {
          ...PhoneTypeFields
        }
      }
      phone {
        ...PhoneFields
      }
    }
  }
  ${PhoneTypeFields}
  ${PhoneFields}
`;

export const GetOrganizations = gql`
  query getOrganizations($filter: FilterOptions) {
    getOrganizations(filter: $filter) {
      id
      name
      default_practice_id
      default_practice_name
      created
      updated
      subscription {
        start_date
        frequency
        last_billed_date
        next_bill_date
        problem
      }
      practice {
        id
        name
        address {
          ...AddressFields
        }
        phone {
          ...PhoneFields
        }
      }
      ref_demographics {
        id
        country {
          ...CountryFields
        }
        prov_state {
          ...ProvStateFields
        }
      }
    }
  }
  ${AddressFields}
  ${PhoneFields}
  ${CountryFields}
  ${ProvStateFields}
`;

export const GetOrganizationsList = gql`
  query getOrganizationsList {
    getOrganizationsList {
      id
      name
      default_practice_id
      created
      updated
      start_date
      frequency
      last_billed_date
      next_bill_date
      failed
    }
  }
`;

export const GetOrganizationsOffline = gql`
  query getOrganizations($filter: FilterOptions) {
    getOrganizations(filter: $filter) {
      ...OrganizationOfflineFields
    }
  }
  ${OrganizationOfflineFields}
`;

export enum AdditionalOrganizationField {
  AddressDemographics = 1,
  ContactReferenceData,
  ContactReferenceDataAndPatientDemographics,
  ContactTypes,
  UserTypes,
  PatientDemographics,
  PatientDemographicsWithContactAlerts,
  Practices,
  PracticesAndAddressDemographics,
  ServiceWithTax,
  UsersAndUserTypes,
  PatientNoteTypes,
  PatientFileTypes,
  ContactNoteTypes,
  ContactFileTypes,
  FinancialTaxTypes,
  ServiceReferenceData,
  PaymentMethods,
  AllReferenceData,
  Subscription,
  Maintenance,
  FinancialGeneralLedger,
  Offline,
  SMTP,
  BillingHistory,
  PhoneDemographicsAndThirdParty,
  PhoneTableDate,
  AddOn,
  Address,
  LabState,
  PhoneDemographicsThirdPartyAndAddOnAndRefContactAlerts,
  ServiceReferenceDataAndAddOn,
  PaymentMethodsAndAddressDemographics,
  BulkLoadInfo,
  AddonAndProcessorType,
  Settings,
  SettingsAndGeneralLedgerRefData,
}

export const getGetOrganizationQuery = (additionalOrganizationField?: AdditionalOrganizationField) => {
  if (!additionalOrganizationField) {
    return GetOrganization;
  }

  switch (additionalOrganizationField) {
    case AdditionalOrganizationField.AddressDemographics:
      return GetOrganizationWithAddressDemographics;
    case AdditionalOrganizationField.ContactReferenceData:
      return GetOrganizationWithContactReferenceData;
    case AdditionalOrganizationField.ContactReferenceDataAndPatientDemographics:
      return GetOrganizationWithContactReferenceDataAndPatientDemographics;
    case AdditionalOrganizationField.ContactTypes:
      return GetOrganizationWithContactTypes;
    case AdditionalOrganizationField.UserTypes:
      return GetOrganizationWithUserTypes;
    case AdditionalOrganizationField.PatientDemographics:
      return GetOrganizationWithPatientDemographics;
    case AdditionalOrganizationField.PatientDemographicsWithContactAlerts:
      return GetOrganizationWithPatientDemographicsWithContactAlerts;
    case AdditionalOrganizationField.Practices:
      return GetOrganizationWithPractices;
    case AdditionalOrganizationField.PracticesAndAddressDemographics:
      return GetOrganizationWithPracticesAndAddressDemographics;
    case AdditionalOrganizationField.UsersAndUserTypes:
      return GetOrganizationWithUserTypes;
    case AdditionalOrganizationField.PatientNoteTypes:
      return GetOrganizationWithPatientNoteTypes;
    case AdditionalOrganizationField.PatientFileTypes:
      return GetOrganizationWithPatientFileTypes;
    case AdditionalOrganizationField.ContactNoteTypes:
      return GetOrganizationWithContactNoteTypes;
    case AdditionalOrganizationField.ContactFileTypes:
      return GetOrganizationWithContactFileTypes;
    case AdditionalOrganizationField.FinancialTaxTypes:
      return GetOrganizationWithFinancialTaxTypeRef;
    case AdditionalOrganizationField.ServiceReferenceData:
      return GetOrganizationWithServiceRef;
    case AdditionalOrganizationField.PaymentMethods:
      return GetOrganizationWithPaymentTypes;
    case AdditionalOrganizationField.AllReferenceData:
      return GetOrganizationWithAllReferenceData;
    case AdditionalOrganizationField.Subscription:
      return GetOrganizationWithSubscription;
    case AdditionalOrganizationField.Maintenance:
      return GetOrganizationWithMaintenance;
    case AdditionalOrganizationField.FinancialGeneralLedger:
      return GetOrganizationWithFinancialGeneralLedgerRef;
    case AdditionalOrganizationField.Offline:
      return GetOrganizationForOffline;
    case AdditionalOrganizationField.SMTP:
      return GetOrganizationWithSmtpSettings;
    case AdditionalOrganizationField.BillingHistory:
      return GetOrganizationBillingHistory;
    case AdditionalOrganizationField.PhoneDemographicsAndThirdParty:
      return GetOrganizationWithPhoneDemographicsAndThirdParty;
    case AdditionalOrganizationField.PhoneTableDate:
      return getOrganizationPhoneData;
    case AdditionalOrganizationField.AddOn:
      return getOrganizationAddOnData;
    case AdditionalOrganizationField.Address:
      return getOrganizationAddressData;
    case AdditionalOrganizationField.LabState:
      return GetOrganizationWithLabState;
    case AdditionalOrganizationField.PhoneDemographicsThirdPartyAndAddOnAndRefContactAlerts:
      return getOrganizationWithPhoneDemographicsThirdPartyAndAddOnAndRefContactAlerts;
    case AdditionalOrganizationField.ServiceReferenceDataAndAddOn:
      return getOrganizationWithServiceReferenceDataAndAddOn;
    case AdditionalOrganizationField.PaymentMethodsAndAddressDemographics:
      return getOrganizationWithPaymentMethodsAndAddressDemographics;
    case AdditionalOrganizationField.BulkLoadInfo:
      return GetOrganizationForBulkDownload;
    case AdditionalOrganizationField.AddonAndProcessorType:
      return getOrganizationWithAddonAndProcessorType;
    case AdditionalOrganizationField.Settings:
      return getOrganizationSettings;
    case AdditionalOrganizationField.SettingsAndGeneralLedgerRefData:
      return getOrganizationSettingsAndGeneralLedgerRefData;
    default:
      throw new Error('GraphQL query does not exist');
  }
};

export const GetOrganization = gql`
  query getPlainOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      ...OrganizationFields
    }
  }
  ${OrganizationFields}
`;

const GetOrganizationForOffline = gql`
  query getOrganizationForOffline($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      ...OrganizationOfflineFields
    }
  }
  ${OrganizationOfflineFields}
`;

export const GetOrganizationBillingHistory = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      subscription {
        id
        invoice {
          ...OrganizationSubscriptionInvoiceFields
        }
      }
    }
  }
  ${OrganizationSubscriptionInvoiceFields}
`;

export const GetOrganizationSubscriptionInvoiceReport = gql`
  query getOrganizationSubscriptionInvoiceReport($request: ReportRequest!, $electronicPaymentId: ID!) {
    getOrganizationSubscriptionInvoiceReport(request: $request, electronicPaymentId: $electronicPaymentId) {
      pointer
      error
    }
  }
`;

const GetOrganizationWithAddressDemographics = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      ...OrganizationFields
      ref_demographics {
        id
        country {
          ...CountryFields
        }
        prov_state {
          ...ProvStateFields
        }
      }
    }
  }
  ${OrganizationFields}
  ${CountryFields}
  ${ProvStateFields}
`;

const GetOrganizationWithPhoneDemographicsAndThirdParty = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_demographics {
        id
        phone_type {
          ...PhoneTypeFields
        }
      }
      ref_system {
        id
        third_party {
          ...ThirdPartyTypeFields
        }
      }
    }
  }
  ${PhoneTypeFields}
  ${ThirdPartyTypeFields}
`;

const GetOrganizationWithContactTypes = gql`
  query getOrganizationWithContactTypes($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_contact {
        id
        type {
          name_key
          type_id
          invoice_ownership
        }
      }
    }
  }
`;

const GetOrganizationWithUserTypes = gql`
  query getOrganizationWithUserTypes($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      default_practice_id
      ref_user {
        type {
          id
          name
          doctor
        }
      }
    }
  }
`;

const GetOrganizationWithPatientDemographics = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ...OrganizationFields
      ref_patient {
        ...PatientDemographicFields
      }
    }
  }
  ${OrganizationFields}
  ${PatientDemographicFields}
`;

const GetOrganizationWithPatientDemographicsWithContactAlerts = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_patient {
        ...PatientDemographicFields
      }
      ref_contact {
        alert_type {
          ...ContactAlertTypeFields
        }
      }
    }
  }
  ${PatientDemographicFields}
  ${ContactAlertTypeFields}
`;

const GetOrganizationWithPatientNoteTypes = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_patient {
        note_type {
          ...NoteTypeFields
        }
      }
    }
  }
  ${NoteTypeFields}
`;

const GetOrganizationWithPatientFileTypes = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_patient {
        file_type {
          ...FileTypeFields
        }
      }
    }
  }
  ${FileTypeFields}
`;

const GetOrganizationWithContactNoteTypes = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_contact {
        note_type {
          ...NoteTypeFields
        }
      }
    }
  }
  ${NoteTypeFields}
`;

const GetOrganizationWithContactFileTypes = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_contact {
        file_type {
          ...FileTypeFields
        }
      }
    }
  }
  ${FileTypeFields}
`;

const GetOrganizationWithPractices = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      default_practice_id
      practice {
        ...PracticeFields
      }
      subscription {
        start_date
        frequency
        last_billed_date
        next_bill_date
        problem
      }
    }
  }
  ${PracticeFields}
`;

const GetOrganizationWithPracticesAndAddressDemographics = gql`
  query getOrganizationWithPracticesAndAddress($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      default_practice_id
      default_practice_name
      practice {
        ...PracticeFields
      }
      subscription {
        start_date
        frequency
        last_billed_date
        next_bill_date
        problem
      }
      ref_demographics {
        id
        country {
          ...CountryFields
        }
        prov_state {
          ...ProvStateFields
        }
      }
    }
  }
  ${PracticeFields}
  ${CountryFields}
  ${ProvStateFields}
`;

const GetOrganizationWithSubscription = gql`
  query getOrganizationWithSubscription($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      default_practice_id
      subscription {
        ...OrganizationSubscriptionFields
      }
      ref_system {
        subscription_type {
          id
          name_key
          level {
            id
            cost
            frequency
            name_key
            sort_order
          }
        }
      }
    }
  }
  ${OrganizationSubscriptionFields}
`;

const GetOrganizationWithContactReferenceData = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_contact {
        id
        status_type {
          ...ContactStatusTypeFields
        }
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        info_type {
          ...InfoTypeFields
        }
      }
    }
  }
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${InfoTypeFields}
`;

const GetOrganizationWithContactReferenceDataAndPatientDemographics = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ...OrganizationFields
      ref_patient {
        ...PatientDemographicFields
      }
      ref_contact {
        id
        status_type {
          ...ContactStatusTypeFields
        }
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        info_type {
          ...InfoTypeFields
        }
      }
    }
  }
  ${OrganizationFields}
  ${PatientDemographicFields}
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${InfoTypeFields}
`;

const GetOrganizationWithAllReferenceData = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      default_practice_id
      subscription {
        id
        processor_type_id
        addon {
          ...OrganizationAddOnFields
        }
      }
      ref_contact {
        id
        type {
          ...ContactTypeFields
        }
        status_type {
          ...ContactStatusTypeFields
        }
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        alert_type {
          ...ContactAlertTypeFields
        }
      }
      ref_patient {
        id
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        species {
          ...SpeciesFields
        }
        breed {
          ...BreedFields
        }
        gender {
          ...GenderFields
        }
        color {
          ...ColorFields
        }
        reminder_status {
          ...ReminderStatusFields
        }
        alert_type {
          ...PatientAlertTypeFields
        }
      }
      ref_financial {
        id
        ...FinancialTaxTypeFields
        ...GeneralLedgerTypeFields
        ...FinancialGeneralLedgerFields
        ...PaymentTypeFields
      }
      ref_user {
        type {
          ...UserTypeFields
        }
      }
    }
  }
  ${OrganizationAddOnFields}
  ${ContactTypeFields}
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${SpeciesFields}
  ${BreedFields}
  ${GenderFields}
  ${ColorFields}
  ${FinancialTaxTypeFields}
  ${GeneralLedgerTypeFields}
  ${FinancialGeneralLedgerFields}
  ${PaymentTypeFields}
  ${UserTypeFields}
  ${ReminderStatusFields}
  ${PatientAlertTypeFields}
  ${ContactAlertTypeFields}
`;

export const GetOrganizationWithFinancialTaxTypeRef = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...FinancialTaxTypeFields
      }
    }
  }
  ${FinancialTaxTypeFields}
`;

export const GetOrganizationWithServiceRef = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...FinancialTaxTypeFields
        ...GeneralLedgerTypeFields
      }
      ref_service {
        id
        ...ServiceRefTypeFields
      }
    }
  }
  ${FinancialTaxTypeFields}
  ${GeneralLedgerTypeFields}
  ${ServiceRefTypeFields}
`;

export const GetOrganizationWithFinancialGeneralLedgerRef = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...FinancialGeneralLedgerFields
      }
    }
  }
  ${FinancialGeneralLedgerFields}
`;

export const GetOrganizationWithPaymentTypes = gql`
  query getOrganization($organizationId: ID!, $practiceId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...PaymentTypeFields
        ...PaymentDeviceTypeFields
      }
      practice(filter: { practiceId: $practiceId }) {
        id
        card_connect {
          merchantId
          userId
        }
        stripe {
          accountId
        }
      }
      subscription {
        processor_type_id
      }
    }
  }
  ${PaymentTypeFields}
  ${PaymentDeviceTypeFields}
`;

const GetOrganizationWithMaintenance = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      maintenance {
        start_date
        end_date
        redirect_url
        message_key
      }
    }
  }
`;

const GetOrganizationWithSmtpSettings = gql`
  query getSmtpSettings($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      smtp {
        accountName
        displayName
        fromAddress
        portNumber
        replyToAddress
        requiresSSL
        serverName
        useSES
      }
    }
  }
`;

const getOrganizationPhoneData = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_demographics {
        id
        phone_type {
          ...PhoneTypeFields
        }
      }
      phone {
        ...PhoneFields
      }
    }
  }
  ${PhoneTypeFields}
  ${PhoneFields}
`;

const getOrganizationAddOnData = gql`
  query getOrganizationAddOnData($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      subscription {
        id
        processor_type_id
        addon {
          ...OrganizationAddOnFields
        }
      }
    }
  }
  ${OrganizationAddOnFields}
`;

const getOrganizationAddressData = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      address {
        ...OrganizationAddressFields
      }
    }
  }
  ${OrganizationAddressFields}
`;

export const GetOrganizationWithLabState = gql`
  query getOrganizationWithLabState($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      lab_state {
        has_unassigned
        unassigned_count
      }
    }
  }
`;

const getOrganizationWithPhoneDemographicsThirdPartyAndAddOnAndRefContactAlerts = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_demographics {
        id
        phone_type {
          ...PhoneTypeFields
        }
      }
      ref_system {
        id
        third_party {
          ...ThirdPartyTypeFields
        }
      }
      subscription {
        id
        addon {
          ...OrganizationAddOnFields
        }
      }
      ref_contact {
        id
        alert_type {
          ...ContactAlertTypeFields
        }
      }
    }
  }
  ${PhoneTypeFields}
  ${ThirdPartyTypeFields}
  ${OrganizationAddOnFields}
  ${ContactAlertTypeFields}
`;

const getOrganizationWithServiceReferenceDataAndAddOn = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...FinancialTaxTypeFields
        ...GeneralLedgerTypeFields
      }
      ref_service {
        id
        ...ServiceRefTypeFields
      }
      subscription {
        id
        addon {
          ...OrganizationAddOnFields
        }
      }
    }
  }
  ${FinancialTaxTypeFields}
  ${GeneralLedgerTypeFields}
  ${ServiceRefTypeFields}
  ${OrganizationAddOnFields}
`;

export const getOrganizationWithPaymentMethodsAndAddressDemographics = gql`
  query getOrganization($organizationId: ID!, $practiceId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      ref_financial {
        id
        ...PaymentTypeFields
        ...PaymentDeviceTypeFields
      }
      practice(filter: { practiceId: $practiceId }) {
        id
        card_connect {
          merchantId
          userId
        }
        stripe {
          accountId
        }
      }
      subscription {
        processor_type_id
      }
      ref_demographics {
        id
        country {
          ...CountryFields
        }
        prov_state {
          ...ProvStateFields
        }
      }
    }
  }
  ${PaymentTypeFields}
  ${PaymentDeviceTypeFields}
  ${CountryFields}
  ${ProvStateFields}
`;

export const GetOrganizationForBulkDownload = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      bulk_load_file {
        url
        filePointer
      }
      bulk_load_stamp
    }
  }
`;

const getOrganizationWithAddonAndProcessorType = gql`
  query getOrganizationWithAddonAndProcessorType($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      subscription {
        id
        addon {
          ...OrganizationAddOnFields
        }
        processor_type_id
      }
    }
  }
  ${OrganizationAddOnFields}
`;
