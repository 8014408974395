import { useCallback, useState } from 'react';

// Define the type for the custom hook
type UseQuickBooksAuth = {
  qbAuthCancelled: boolean;
  needsQbAuth: boolean;
  setNeedsQbAuth: React.Dispatch<React.SetStateAction<boolean>>;
  setQbAuthCancelled: React.Dispatch<React.SetStateAction<boolean>>;
  cancelQuickBooksAuth: () => void;
  handleQBAuthFailed: () => void;
};

// Define the custom hook
const useQuickBooksAuth = (): UseQuickBooksAuth => {
  const [qbAuthCancelled, setQbAuthCancelled] = useState(false);
  const [needsQbAuth, setNeedsQbAuth] = useState(false);

  const cancelQuickBooksAuth = useCallback(() => {
    setNeedsQbAuth(false);
    setQbAuthCancelled(true);
  }, []);

  const handleQBAuthFailed = useCallback(() => {
    if (!qbAuthCancelled) {
      setNeedsQbAuth(true);
    }
  }, [qbAuthCancelled]);

  return {
    qbAuthCancelled,
    needsQbAuth,
    setNeedsQbAuth,
    cancelQuickBooksAuth,
    handleQBAuthFailed,
    setQbAuthCancelled,
  };
};

export default useQuickBooksAuth;
