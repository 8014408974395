import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Patient } from '../../../../graph/types';
import { RecordItem } from '../../../../components/Records/recordUtils';
import { Records } from '../../../../components/Records/Records';
import { getRecordFromServiceActivity } from '../../../../components/RecordSingleView/ServiceActivity/ServiceActivityCard';
import { getRecordFromNote } from '../../../../components/RecordSingleView/Notes/noteUtils';
import { getRecordFromFile } from '../../../../util/fileUtils';
import { RecordsContext } from '../../../../components/Records/store/state';
import { selectHasUnsavedChanges, selectSearchValue } from '../../../../components/Records/store/selectors';
import './PatientRecords.less';
import { useGetRecords, OwnerType } from '../../../../hooks/ajax/recordHooks';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { setServiceRenderedAction } from '../../../../components/Records/store/actions';
import { ServiceRenderedFragment } from '../../../../components/Records/store/reducer';
import { useGetServiceRenderedFragment } from '../../../../hooks/ajax/service/serviceHooks';
import { SaveSpinner } from '../../../../components/SaveSpinner/SaveSpinner';
import { getRecordFromLabRequest } from '../../../../components/RecordSingleView/LabRequest/LabRequestCard';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { translations } from '../../../../constants/translations';

interface RecordListProps extends PropsWithChildren<unknown> {
  patient: Patient;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  fullscreenMode?: boolean;
}

export const PatientRecords: React.FC<RecordListProps> = ({
  patient,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  fullscreenMode,
}: RecordListProps) => {
  const { state, dispatch } = useContext(RecordsContext);
  const searchValue = selectSearchValue(state);
  const { notes, files, services, labRequests, loading, setSearchValue, refetch } = useGetRecords(
    { id: patient.id },
    OwnerType.Patient,
    searchValue
  );
  const organizationId = useGetOrganizationIdFromRoute();

  const { data } = useGetServiceRenderedFragment({ patientId: patient.id, organizationId });
  const { isOnline } = useOffline();
  const hasUnsavedChanges = selectHasUnsavedChanges(state);

  useEffect(() => {
    if (data) {
      dispatch(setServiceRenderedAction(data.filter((service) => !!service.note_id) as ServiceRenderedFragment[]));
    }
  }, [data, dispatch]);

  useEffect(() => {
    setSearchValue(searchValue ?? '');
  }, [searchValue, setSearchValue]);

  useEffect(() => {
    if (isOnline && !hasUnsavedChanges) {
      refetch();
    }
  }, [refetch, isOnline, hasUnsavedChanges]);

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(hasUnsavedChanges);
    }
  }, [handleIsEditing, hasUnsavedChanges]);

  const records = useMemo(() => {
    const records: RecordItem[] = [];
    records.push(...(notes?.map((notePreview) => getRecordFromNote(notePreview)) ?? []));
    records.push(...(files?.map((file) => getRecordFromFile(file)) ?? []));
    records.push(...(services?.map((activity) => getRecordFromServiceActivity(activity)) ?? []));
    records.push(...(labRequests?.map((request) => getRecordFromLabRequest(request)) ?? []));
    return records;
  }, [notes, files, services, labRequests]);

  return (
    <SaveSpinner isSaving={loading} savingMessage={translations.shared.loading}>
      <Records
        records={records}
        patient={patient}
        setShouldResetOnTabChange={setShouldResetOnTabChange}
        shouldResetOnTabChange={shouldResetOnTabChange}
        fullscreenMode={fullscreenMode}
        loading={loading}
      />
    </SaveSpinner>
  );
};
