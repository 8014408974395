import { RecordsContextProvider } from '../../../components/Records/store/state';
import { ViewContact } from './ViewContact';
import React from 'react';
import { ComponentWithPracticeDtoProps } from '../../../components/WithPractice/WithPractice';
import { ContactLedgerContextProvider } from './ContactLedger/store/state';
import { InvoicesContextProvider } from '../../Invoices/InvoicesOverview/store/state';
import { ColumnDisplayFilterContextProvider } from '../../../components/ColumnDisplayFilter/store/state';
import { ViewSubscriptionContextProvider } from '../../../components/ViewSubscription/store/state';

export const ViewContactContainer: React.FC<ComponentWithPracticeDtoProps> = ({ practice }) => {
  return (
    <RecordsContextProvider>
      <InvoicesContextProvider>
        <ContactLedgerContextProvider>
          <ColumnDisplayFilterContextProvider>
            <ViewSubscriptionContextProvider>
              <ViewContact practice={practice} />
            </ViewSubscriptionContextProvider>
          </ColumnDisplayFilterContextProvider>
        </ContactLedgerContextProvider>
      </InvoicesContextProvider>
    </RecordsContextProvider>
  );
};
