import Tiff from 'tiff.js';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';

export const getTiffImageSrc = async (url) => {
  const response = await makeXMLRequest(url).catch(showErrorMessage);

  if(!response){
    return '';
  }

  const tiff = new Tiff({buffer: response});
  return tiff.toDataURL();
}

const makeXMLRequest = (url) => {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(translations.recordList.errorOnTiffRequest);
        }
      };
      xhr.onerror = () => {
        reject(translations.recordList.errorOnTiffRequest);
      };
      xhr.send();
  });
}