import { Button, DatePicker, Form, Select, Space } from 'antd';
import React from 'react';
import { displayAsDate } from '../../../constants/formats';
import { translations } from '../../../constants/translations';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { FormChangeProp, ReminderForm, RUN_MODE_OPTIONS } from '../types';
import {
  DateSentContainer,
  DateSentLabel,
  DateSentValue,
  FormItemLessMargin,
  Row,
  TopInputControlsContainer,
  FormItemSelect,
} from './TopInputControls.styles';

const { Option } = Select;

interface Props extends ReminderForm {
  runButton: React.ReactNode;
  disabledConfigure?: boolean;
  sentDate?: string | null;
  onClickConfigure: () => void;
  onChange: (changedProp: FormChangeProp) => void;
}

const TopInputControls = ({
  afterDate,
  beforeDate,
  runMode,
  disabledConfigure,
  sentDate,
  runButton,
  onClickConfigure,
  onChange,
}: Props) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const onValuesChange = (changedValue: any) => {
    onChange(changedValue);
  };

  return (
    <TopInputControlsContainer>
      {sentDate ? (
        <DateSentContainer>
          <DateSentLabel>{translations.reminderRuns.labels.sent}</DateSentLabel> {' - '}
          <DateSentValue>{displayAsDate(sentDate, dateFormat)}</DateSentValue>
        </DateSentContainer>
      ) : (
        <Form
          initialValues={{ afterDate, beforeDate, runMode }}
          labelAlign='left'
          onValuesChange={onValuesChange}
          autoComplete='off'
        >
          <FormItemLessMargin
            label={translations.reminderRuns.labels.afterDate}
            name='afterDate'
            rules={[
              {
                required: true,
                message: translations.errorMessages.required,
              },
            ]}
          >
            <DatePicker value={afterDate} format={dateFormat} />
          </FormItemLessMargin>
          <FormItemLessMargin
            label={translations.reminderRuns.labels.beforeDate}
            name='beforeDate'
            rules={[
              {
                required: true,
                message: translations.errorMessages.required,
              },
            ]}
          >
            <DatePicker value={beforeDate} format={dateFormat} />
          </FormItemLessMargin>
          <Row>
            <FormItemLessMargin
              name='runMode'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <FormItemSelect value={runMode}>
                {RUN_MODE_OPTIONS.map((option) => (
                  <Option value={option.value} key={option.value}>
                    {option.label}
                  </Option>
                ))}
              </FormItemSelect>
            </FormItemLessMargin>
            <FormItemLessMargin>
              <Space>
                {runButton}
                <Button disabled={disabledConfigure} onClick={onClickConfigure}>
                  {translations.reminderRuns.actions.configure}
                </Button>
              </Space>
            </FormItemLessMargin>
          </Row>
        </Form>
      )}
    </TopInputControlsContainer>
  );
};

export default TopInputControls;
