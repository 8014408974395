import React from 'react';
import { Loading } from '../Loading/Loading';
import { useGetLedgerNote } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';

interface PopoverWithNoteContentProps {
  ledgerId: string;
}
export const PopoverWithNoteContent: React.FC<PopoverWithNoteContentProps> = ({ ledgerId }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { ledgerNote, ledgerNoteLoading } = useGetLedgerNote({ ledgerId, organizationId });

  return ledgerNoteLoading ? <Loading /> : <>{ledgerNote?.value}</>;
};
