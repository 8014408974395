import { Form, Select } from 'antd';
import styled from 'styled-components';
import { defaultFont } from '../../../theme';

export const PromptSwitch = styled(Form.Item)`
  && {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const FixedWidthSelect = styled(Select<string | number | undefined>)`
  min-width: 150px;
`;

export const StyledSpan = styled.span`
  display: block;
  font-family: ${defaultFont};
`;
