export const offset = 7;
export const fieldsSpan = 7;
export const formLayout = {
  labelCol: { span: offset },
  wrapperCol: { span: fieldsSpan },
};

export enum Fields {
  Name = 'name',
  Email = 'email',
  SoftwareID = 'software_id',
  SoftwareName = 'software_name',
  VersionNumber = 'software_version',
  Attachments = 'attachments',
  SpecialInstructions = 'instructions',
  GeneralNotes = 'notes',
}
