import React from 'react';
import { Button, message, notification } from 'antd';
import { ConfigOptions } from 'antd/es/message/interface';

export const showSuccessMessage = (messageText: string) => {
  message.config({ top: 75, duration: 3 });
  message.open({ type: 'success', content: messageText });
};

export const showWarningMessage = (
  messageText: string,
  { top = 75, duration = 3 }: ConfigOptions = {
    top: 75,
    duration: 3,
  },
  key?: string
) => {
  message.destroy();
  message.config({ top, duration });
  message.open({ type: 'warning', content: messageText, key });
};

export const showErrorMessage = (errorMsg: string, duration = 3) => {
  message.config({ top: 75, duration });
  message.open({ type: 'error', content: errorMsg });
};
// TODO test functionality
export const clearMessages = (key: string) => message.destroy(key);

export const showNotification = (config: {
  key?: string;
  message: string;
  description: string;
  btn: {
    text: string;
    action?: () => void;
  };
}) => {
  const key = config.key ?? `notification-${Date.now()}`;
  notification.open({
    key,
    ...config,
    duration: 0, // to prevent auto close
    btn: (
      <Button
        type='link'
        onClick={() => {
          notification.destroy(key);
          config.btn.action?.();
        }}
      >
        {config.btn.text}
      </Button>
    ),
  });
};

export const closeNotification = (key: string) => notification.destroy(key);

const notificationUtil = {
  showSuccessMessage,
  showWarningMessage,
  showErrorMessage,
  showNotification,
};

export default notificationUtil;
