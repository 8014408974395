import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Dictionary } from 'lodash';
import { translations } from '../../constants/translations';
import { UserOutlined } from '@ant-design/icons';
import { SignInContainer } from './SignInContainer';
import { Auth } from 'aws-amplify';
import { showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { routes } from '../../constants/routes';
import { getRequiredRule, getValidEmailRule } from '../../util/forms';

export const ResetPasswordPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { navigateTo } = useNavigationToRoute();
  const emailTemplateName = 'email';

  const onFinish = async ({ email }: Dictionary<string>) => {
    setIsSaving(true);
    try {
      await Auth.forgotPassword(email);
      showSuccessMessage(translations.passwordReset.successMessage);
      navigateTo(routes.root);
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage(translations.passwordReset.errorMessage);
      }
    }
    setIsSaving(false);
  };

  return (
    <SaveSpinner isSaving={isSaving}>
      <SignInContainer>
        <Form onFinish={onFinish} validateTrigger='onBlur' wrapperCol={{ span: 24 }}>
          <h2 style={{ marginBottom: 20 }}>{translations.passwordReset.title}</h2>
          <Form.Item
            name={emailTemplateName}
            rules={[getRequiredRule(translations.login.fields.email.placeholder), getValidEmailRule()]}
          >
            <Input prefix={<UserOutlined />} placeholder={translations.login.fields.email.placeholder} />
          </Form.Item>
          <Form.Item validateStatus={'error'} help={errorMessage}>
            <Button onClick={() => navigateTo(routes.root)}>{translations.shared.back}</Button>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              {translations.shared.submit}
            </Button>
          </Form.Item>
        </Form>
      </SignInContainer>
    </SaveSpinner>
  );
};
