import { StageConfig } from './config';

const stageConfig: StageConfig = {
  PUBLIC_API_URL:
    (!process.env.JEST_WORKER_ID && process.env.REACT_APP_PUBLIC_API_URL) || 'https://public-api.software.vet',
  APPSYNC_URL: (!process.env.JEST_WORKER_ID && process.env.REACT_APP_APPSYNC_URL) || 'https://api.software.vet',
  COGNITO_USER_POOL_ID: 'us-east-2_EU6xjTIvb',
  COGNITO_APP_CLIENT_ID: '1qoas5lpcj2pg91bj11hr4nksg',
  LAUNCHDARKLY_CLIEND_ID: '61708182cbe628228d9c5d99',
  STRIPE_PUBLISHABLE_KEY:
    'pk_live_51OXRWpJYH9WeaXSN0SegsJ2HCXTC7GJ6kfoNf1ycR8IQZXMF5unWux8Ck9c7zqeQiYZO4IPxvW41tsQsNB7kOi6f00M3eN98qx',
  STRIPE_PUBLISHABLE_KEY_CAD:
    'pk_live_51OeihzCSvt6Fm7nCQ41u0GxTBwwR6RDGJUwdXNhoNOrX3qEf93QegEjOChYRa8KiGB1oi9lplqi1Niue3PAKTP5200X054sZ5c',
  APP_DATADOG_APPLICATION_ID: '9d9bad77-a6e6-4dd2-bfc5-bd13a3e5cdb0',
  APP_DATADOG_CLIENT_TOKEN: 'pub1fc3842e3d84f217fe3f1364936fbf8b',
};

export default stageConfig;
