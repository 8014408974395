import gql from 'graphql-tag';
import { RecordChangedFields } from '../fragments/recordChangedFragments';

export const OnInvoiceUpsert = gql`
  subscription onInvoiceUpsert($id: ID!) {
    onInvoiceUpsert(id: $id) {
      ...RecordChangedFields
    }
  }
  ${RecordChangedFields}
`;
