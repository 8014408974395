import { accentColor, linkColor, mainColor, redColor, secondColor, subMenuColor } from './colorScheme';
import { theme as antTheme } from 'antd';

export const defaultFont =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const theme = {
  ...antTheme.defaultConfig,
  algorithm: antTheme.defaultAlgorithm,
  token: {
    colorPrimary: accentColor,
    colorError: redColor,
    colorHighlight: redColor,
    colorLink: linkColor,
    fontFamily: defaultFont,
    borderRadius: 2,
  },
  components: {
    Layout: {
      colorBgTrigger: mainColor,
      colorBgHeader: mainColor,
    },
    Button: {
      colorBgBase: accentColor,
    },
    Menu: {
      colorSubItemBg: subMenuColor,
      colorItemBg: mainColor,
      colorItemText: secondColor,
      colorItemBgHover: undefined,
      colorText: secondColor,
    },
    Tabs: {
      colorHighlight: linkColor,
    },
  },
};
