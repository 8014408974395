import React from 'react';
import { Tooltip } from 'antd';
import LinkOutlined from '@ant-design/icons/lib/icons/LinkOutlined';
import { translations } from '../../constants/translations';

type Props = {
  onClick: () => void;
};

const LinkedServiceRenderedTooltip = ({ onClick }: Props) => {
  return (
    <Tooltip title={translations.patientPage.medicalRecords.serviceRenderedTooltip}>
      <LinkOutlined
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
    </Tooltip>
  );
};

export default LinkedServiceRenderedTooltip;
