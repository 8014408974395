import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export interface AddOnCardAntechLabProps {
  isAddOnEnabled?: boolean;
  price?: string;
  onClick: () => void;
  onConfigureClick?: () => void;
  countryId?: string | null;
}

export const AddOnCardAntechLab: React.FC<AddOnCardAntechLabProps> = ({
  isAddOnEnabled,
  price,
  onClick,
  onConfigureClick,
  countryId,
}: AddOnCardAntechLabProps) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const handleToggle = () => {
    isAddOnEnabled ? setDisplayAlert(true) : onClick();
  };

  const buttonProps = {
    label: translations.addOns.cards.antechLab.label,
    onClick: handleToggle,
    isToggle: true,
    isChecked: isAddOnEnabled,
    popconfirm: {
      title: translations.addOns.cards.antechLab.cannotDisableMessage,
      okText: translations.shared.okButtonText,
      cancelText: translations.shared.popconfirm.cancel,
      placement: 'top',
      open: displayAlert,
      onConfirm: () => {
        setDisplayAlert(false);
      },
      onCancel: () => {
        setDisplayAlert(false);
      },
    } as PopconfirmProps,
  };

  const configureButtonProps = isAddOnEnabled
    ? {
        label: translations.addOns.cards.antechLab.configure,
        onClick: onConfigureClick || (() => undefined),
      }
    : undefined;

  return (
    <SettingsCard
      title={translations.addOns.cards.antechLab.cardTitle}
      leftButton={buttonProps}
      rightButton={configureButtonProps}
    >
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.antechLab.text}</TextWithMargin>
        {price && (
          <TextWithMargin isBold>
            {translations.addOns.cards.antechLab.subText1}{' '}
            <SubscriptionCurrencyFormatter total={Number(price)} countryId={countryId} />{' '}
            {translations.addOns.cards.antechLab.subText2}
          </TextWithMargin>
        )}
      </FlexContainer>
    </SettingsCard>
  );
};
