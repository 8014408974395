import { useMemo } from 'react';
import { GetContacts } from '../../../graph/queries/contacts';
import { GlobalSearch } from '../../../graph/queries/globalSearch';
import { GetInvoices } from '../../../graph/queries/invoices';
import { GetPatients } from '../../../graph/queries/patients';
import {
  getNormalizedContact,
  getNormalizedGlobalSearchData,
  getNormalizedInvoice,
  getNormalizedPatient,
} from '../../../pages/Main/MainHeader/Search/searchUtils';
import { SearchOptionType } from '../../../pages/Main/MainHeader/Search/types';
import { useOffline } from '../../../util/offline/offlineUtil';
import { filterByDocument } from '../../../util/searchUtil';
import { useUserLocaleData } from '../../useUserLocale';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useElasticQueryVariables, useOfflineQuery } from '../useOfflineQuery';
import { useGetReferenceData } from '../referenceData/referenceDataHooks';

export const useGlobalSearch = (organizationId: string, searchString = '') => {
  const { enabledAndOffline, isOnline } = useOffline();
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const searchParams = useMemo(() => ({ query: searchString ?? '' }), [searchString]);
  const offlineOptions = useMemo(
    () => ({ skip: !searchString || !enabledAndOffline, variables }),
    [searchString, enabledAndOffline, variables]
  );
  const { referenceData } = useGetReferenceData(organizationId);

  const elasticVariables = useElasticQueryVariables(searchString ?? '', searchParams);

  const { localeData } = useUserLocaleData();

  const queryVariables = useMemo(
    () => ({
      ...elasticVariables,
      ...variables,
    }),
    [variables, elasticVariables]
  );

  const { data, loading } = useOfflineErrorSkipQuery<'globalSearch'>(GlobalSearch, {
    skip: !searchString || !isOnline,
    variables: queryVariables,
  });

  const { data: patientData, loading: patientLoading } = useOfflineQuery<'getPatientsEs'>({
    query: GetPatients,
    collection: 'patient',
    options: offlineOptions,
  });

  const { data: invoiceData, loading: invoiceLoading } = useOfflineQuery<'getInvoicesEs'>({
    query: GetInvoices,
    collection: 'invoice',
    options: offlineOptions,
  });

  const { data: contactData, loading: contactLoading } = useOfflineQuery<'getContactsEs'>({
    query: GetContacts,
    collection: 'contact',
    options: offlineOptions,
  });

  const normalizedContactData = useMemo(
    () =>
      contactData?.filter(filterByDocument(searchString))?.map(({ id, name, address, type_name_keys, phone }) => {
        const primaryPhone = phone?.find(({ primary }) => primary);
        return getNormalizedContact({ id, name, address: address?.[0], type_name_keys, phone: primaryPhone });
      }) ?? [],
    [contactData, searchString]
  );

  const normalizedPatientData = useMemo(
    () =>
      patientData
        ?.filter(filterByDocument(searchString))
        ?.map((patient) => getNormalizedPatient(patient, referenceData?.contact_type ?? [])) ?? [],
    [patientData, searchString, referenceData?.contact_type]
  );

  const normalizedInvoiceData = useMemo(
    () =>
      invoiceData
        ?.filter(filterByDocument(searchString))
        ?.map((invoice) => getNormalizedInvoice(invoice, invoice.total, localeData.dateFormat)) ?? [],
    [invoiceData, searchString, localeData]
  );

  const normalizedData: SearchOptionType[] = !enabledAndOffline
    ? getNormalizedGlobalSearchData(data?.globalSearch, referenceData?.contact_type ?? [], localeData.dateFormat)
    : [...normalizedPatientData, ...normalizedContactData, ...normalizedInvoiceData];

  return {
    searchResults: searchString ? normalizedData : undefined,
    searchLoading: loading || contactLoading || invoiceLoading || patientLoading,
  };
};
