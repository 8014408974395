import { DatePicker, Form, FormInstance, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { translations } from '../../constants/translations';
import { getRequiredRule } from '../../util/forms';
import { NoteTypeDto, OrganizationReferenceData } from '../../graph/types';
import { getTypeDropdown } from './RecordTypeDropdown';
import styled from 'styled-components';
import { QuickNoteDropdown } from './QuickNoteDropdown';

export const HeaderRow = ({
  types,
  typePlaceholder,
  dateFormat,
  showDateField,
  disabled,
  linkedIcon,
  setTextContent,
  textContent,
  form,
}: {
  types: OrganizationReferenceData[];
  typePlaceholder: string;
  dateFormat: string;
  showDateField?: boolean;
  disabled?: boolean;
  linkedIcon?: React.ReactNode;
  textContent?: string;
  setTextContent?: (textContent: string, plainText: string, source: 'api' | 'user' | 'silent') => void;
  form?: FormInstance;
}) => {
  const selectedTypeId = form?.getFieldValue('type_id');

  const [noteType, setSelectedNoteType] = useState<string>();
  const quickNotesTypes = types as NoteTypeDto[];
  const quickNotes = quickNotesTypes.find((t) => t.id === noteType)?.quick_note;

  useEffect(() => {
    if (selectedTypeId) {
      setSelectedNoteType(selectedTypeId);
    }
  }, [selectedTypeId]);

  return (
    <Row>
      {showDateField && (
        <StyledFormItem name={'date'} rules={[getRequiredRule(translations.recordList.dateFieldName)]}>
          <DatePicker format={dateFormat} disabled={disabled} />
        </StyledFormItem>
      )}
      {getTypeDropdown(types, typePlaceholder, disabled, setSelectedNoteType)}
      {setTextContent && (
        <QuickNoteDropdown
          textContent={textContent}
          quickNotes={quickNotes}
          disabled={disabled || !noteType}
          setTextContent={setTextContent}
        />
      )}
      {linkedIcon && <LinkedWrapper>{linkedIcon}</LinkedWrapper>}
    </Row>
  );
};

const LinkedWrapper = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-right: 8px;
`;
