import { Button, Form, Input, Radio, Switch } from 'antd';
import { ColProps } from 'antd/es/grid';
import { RadioGroupProps } from 'antd/es/radio';
import { Store } from 'antd/lib/form/interface';
import { omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { EmailModal } from '../../components/EmailModal/EmailModal';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import {
  SaveAndResetButton,
  SaveAndResetButtonPlaceholder,
} from '../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { translations } from '../../constants/translations';
import { useGetSmtpSettings, useUpsertSmtpSettings } from '../../hooks/ajax/email/emailHooks';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { hasValidEmailSettings } from '../../util/email';
import { getRequiredRule, getValidEmailRule } from '../../util/forms';
import { isMatch } from '../../util/objectComparisons';
import { Message, MessageWrapper, MessageWrapperText } from './EmailSettings.styles';
import { EmailSettingsInstructions } from './EmailSettingsInstructions';

const layout: Record<string, ColProps> = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const fromAddressFieldName = 'fromAddress';
const displayNameFieldName = 'displayName';
const replyToAddressFieldName = 'replyToAddress';
const serverNameFieldName = 'serverName';
const portNumberFieldName = 'portNumber';
const accountNameSSLFieldName = 'accountName';
const passwordSSLFieldName = 'password';
const requiresSSLFieldName = 'requiresSSL';

interface EmailSettingsInputFields {
  [fromAddressFieldName]: string;
  [displayNameFieldName]: string;
  [replyToAddressFieldName]?: string;
  [serverNameFieldName]: string;
  [portNumberFieldName]: string;
  [requiresSSLFieldName]: boolean;
  [accountNameSSLFieldName]: string;
  [passwordSSLFieldName]: string;
}

export const emailSuffix = '@software.vet';

const useSesRadioOptions: RadioGroupProps['options'] = [
  { value: false, label: translations.emailSettingsPage.useSESRadioOptions.yourSmtpSettings },
  { value: true, label: translations.emailSettingsPage.useSESRadioOptions.ourSmtpSettings },
];

export const EmailSettings: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasFormValuesChanged, setHasFormValuesChanged] = useState<boolean>(false);
  const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);
  const [useSesValue, setUseSesValue] = useState(true);
  const [form] = Form.useForm();
  const smtpSettings = useGetSmtpSettings();
  const [upsertSmtpSettings] = useMutationWithMessages(useUpsertSmtpSettings);
  const hasChanges = useMemo(
    () => hasFormValuesChanged || useSesValue !== !!smtpSettings?.useSES,
    [hasFormValuesChanged, smtpSettings, useSesValue]
  );
  const initialData = omit(smtpSettings, ['useSES']);

  useEffect(() => {
    if (smtpSettings) {
      setUseSesValue(!!smtpSettings.useSES);
    }
  }, [smtpSettings]);

  useEffect(() => {
    if (smtpSettings) {
      form.resetFields();
    }
  }, [form, smtpSettings]);

  const handleValueChange = (_: Store, values: Store) => {
    setHasFormValuesChanged(!isMatch(values, smtpSettings) || !!values.password);
  };

  const onFinish = async (smtp: EmailSettingsInputFields) => {
    setIsSaving(true);
    await upsertSmtpSettings({
      options: {
        variables: {
          organizationId,
          smtp: { ...smtp, useSES: useSesValue },
        },
      },
      onSuccess: () => setHasFormValuesChanged(false),
    });
    setIsSaving(false);
  };

  const handleReset = () => {
    form.resetFields();
    setUseSesValue(!!smtpSettings?.useSES);
    setHasFormValuesChanged(false);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasChanges}
      warningMessage={translations.shared.getCreationNavigationWarning(translations.emailSettingsPage.createdEntity)}
    >
      <StyledPageHeaderWithMargin
        title={translations.emailSettingsPage.title}
        extra={[
          <Radio.Group
            key={'useses-opt'}
            optionType={'button'}
            buttonStyle={'solid'}
            options={useSesRadioOptions}
            value={useSesValue}
            onChange={(e) => setUseSesValue(e.target.value)}
          />,
          <Button
            key={'send-email'}
            htmlType='button'
            type='primary'
            onClick={() => setEmailModalVisible(true)}
            disabled={!hasValidEmailSettings(smtpSettings) || hasChanges}
          >
            {translations.emailSettingsPage.testButtonText}
          </Button>,
        ]}
      />
      <MessageWrapper>
        {translations.emailSettingsPage.message.map((messageParagraph, index) => (
          <Message key={index}>{messageParagraph}</Message>
        ))}
        <MessageWrapperText>
          <Message>{translations.emailSettingsPage.recommendation.title}</Message>
        </MessageWrapperText>
        <EmailSettingsInstructions />
      </MessageWrapper>
      <Form
        {...layout}
        initialValues={initialData}
        name='basic'
        onFinish={onFinish}
        onValuesChange={handleValueChange}
        form={form}
        autoComplete='off'
      >
        <Form.Item
          label={translations.emailSettingsPage.fields.from.label}
          name={fromAddressFieldName}
          rules={[
            getRequiredRule(translations.emailSettingsPage.fields.from.label),
            getValidEmailRule(!useSesValue ? undefined : emailSuffix),
          ]}
        >
          <Input {...(useSesValue && { addonAfter: emailSuffix })} />
        </Form.Item>
        <Form.Item
          label={translations.emailSettingsPage.fields.displayName.label}
          name={displayNameFieldName}
          rules={[getRequiredRule(translations.emailSettingsPage.fields.displayName.label)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translations.emailSettingsPage.fields.replyToEmail.label}
          name={replyToAddressFieldName}
          rules={
            !useSesValue
              ? [getValidEmailRule()]
              : [getValidEmailRule(), getRequiredRule(translations.emailSettingsPage.fields.replyToEmail.label)]
          }
        >
          <Input />
        </Form.Item>
        {!useSesValue && (
          <>
            <Form.Item
              label={translations.emailSettingsPage.fields.serverName.label}
              name={serverNameFieldName}
              rules={[getRequiredRule(translations.emailSettingsPage.fields.serverName.label)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translations.emailSettingsPage.fields.portNumber.label}
              name={portNumberFieldName}
              rules={[getRequiredRule(translations.emailSettingsPage.fields.portNumber.label)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={requiresSSLFieldName}
              label={translations.emailSettingsPage.fields.requiresSSL.label}
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={translations.emailSettingsPage.fields.username.label}
              name={accountNameSSLFieldName}
              rules={[getRequiredRule(translations.emailSettingsPage.fields.username.label)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translations.emailSettingsPage.fields.password.label}
              name={passwordSSLFieldName}
              rules={[getRequiredRule(translations.emailSettingsPage.fields.password.label)]}
            >
              <Input.Password autoComplete={'new-password'} placeholder={'••••••••••••'} />
            </Form.Item>
          </>
        )}
        {hasChanges ? <SaveAndResetButton onReset={handleReset} offset={4} /> : <SaveAndResetButtonPlaceholder />}
      </Form>
      <EmailModal
        visible={emailModalVisible}
        onClose={() => setEmailModalVisible(false)}
        layout={{
          labelCol: { span: 4 },
          wrapperCol: { span: 16 },
        }}
      />
    </SaveSpinnerAndNavigationWarning>
  );
};
