import { print } from 'graphql';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { GetPatientsForOffline } from '../../../graph/queries/patients';
import {
  Maybe,
  Patient,
  PatientOwnershipUpsert,
  PatientRelatedUpsert,
  PatientUpsert,
  RelatedContactEntryCurrent,
  RelatedOwner,
  RelatedOwnershipEntryAll,
  RelatedOwnershipEntryCurrent,
  Info,
  OrganizationDto,
  ContactTypeDto,
} from '../../../graph/types';
import { excludeExisting } from '../../../util/filterUtil';
import { db } from '../LocalDatabaseProvider';
import { v4 as uuid } from 'uuid';
import { OfflineUpsert } from '../../../graph/queries/general';
import { getConnectionId } from '../../../hooks/authHooks';
import { RxPatient } from '../schemas/patientSchema';
import {
  elasticQueryBuilder,
  getOfflineId,
  getUpdatedDate,
  OfflineUpsertProps,
  replaceExistingRecords,
} from './queryUtils';
import { patientInfoTypeId } from '../../../constants/referenceData/patientReferenceData';
import { addOfflinePatientProperties, getPatientDocument } from '../../../util/offline/offlineQueryUtils';
import { isUuid } from '../../../util/offline/offlineUtil';
import { mapToUUID } from './contactQueries';

export const patientQueryBuilder = (organizationId: string) =>
  elasticQueryBuilder<Patient>(organizationId, GetPatientsForOffline);

export const getPatientPushVariables = (organizationId: string, patient: RxPatient) => {
  return {
    variables: {
      organizationId,
      upsert: {
        record: {
          type_name: 'upsertPatient',
          offline_id: patient.upsert_offline_id,
          connection_id: getConnectionId(),
          instruction: JSON.stringify({
            ...cleanUpsert(patient.upsert ?? {}),
            id: patient.is_new ? ID_FOR_OBJECT_CREATION : patient.id,
          }),
        },
      },
    },
  };
};

export const patientPushBuilder = (organizationId: string) => (patient: RxPatient) => {
  return {
    query: print(OfflineUpsert),
    ...getPatientPushVariables(organizationId, patient),
  };
};

const cleanUpsert = (upsert: PatientUpsert) => ({
  ...upsert,
  ownership: upsert.ownership?.map((o) => ({
    ...o,
    syndicate_id: !o.syndicate_id || isNaN(+o.syndicate_id) ? undefined : o.syndicate_id,
  })),
  alert: upsert.alert?.map((a) => ({
    ...a,
    id: !a.id || isNaN(+a.id) ? ID_FOR_OBJECT_CREATION : a.id,
  })),
});

export const onPatientUpdate = async (patient: Patient): Promise<RxPatient> => {
  await replaceExistingRecords(patient, 'patient');
  return addOfflinePatientProperties(patient);
};

export const getPatientInsert = async (
  patientUpsert: PatientUpsert,
  organization: OrganizationDto
): Promise<RxPatient> => {
  const offlineId = getOfflineId();
  const upsert = { ...patientUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
  }
  const currentDate = getUpdatedDate();

  const patientRefData = organization.ref_patient;
  const ownerships = upsert.ownership ? await mapOwnershipUpsertToOwnership(upsert.ownership) : [];
  const ownership_current = ownerships && ownerships.length > 0 ? ownerships?.[ownerships.length - 1] : undefined;

  const patient: RxPatient = {
    id: offlineId,
    offline_id: offlineId,
    upsert,
    upsert_offline_id: uuid(),
    organization_id: organization?.id,
    name: upsert.record?.name,
    name_2: upsert.record?.name_2,
    name_3: upsert.record?.name_3,
    species_id: upsert.record?.species_id ?? '',
    species_name: patientRefData?.species?.find((value) => value.id === upsert.record?.species_id)?.name,
    breed_id: upsert.record?.breed_id,
    breed_name: patientRefData?.breed?.find((value) => value.id === upsert.record?.breed_id)?.name,
    gender_id: upsert.record?.gender_id,
    gender_name: patientRefData?.gender?.find((value) => value.id === upsert.record?.gender_id)?.name,
    color_id: upsert.record?.color_id,
    color_name: patientRefData?.color?.find((value) => value.id === upsert.record?.color_id)?.name,
    deceased: !!upsert.record?.deceased,
    deceased_date: upsert.record?.deceased_date,
    inactive: !!upsert.record?.inactive,
    created_practice_id: organization.default_practice_id,
    dob: upsert.record?.dob,
    related_current: [],
    info: [],
    hidden: false,
    tag: [],
    related_all: [],
    ownership_all: ownerships,
    ...(ownership_current
      ? {
          ownership_current: {
            syndicate_id: ownership_current.syndicate_id,
            effective_date: ownership_current.effective_date,
            owner: ownership_current.owner,
          },
        }
      : undefined),
    ...getOwnershipFields(ownerships?.[0] ?? {}),
    image: [],
    text: [],
    updated: currentDate,
    is_new: true,
    document: '',
  };

  patient.document = getPatientDocument(patient);

  return patient;
};

export const getPatientUpdate = async (
  rxPatient: RxPatient,
  patientUpsert: PatientUpsert,
  organization: OrganizationDto
) => {
  const { ownership, related, info, alert, ...patientUpsertRest } = patientUpsert;
  const newUpsert = { ...rxPatient.upsert, ...patientUpsertRest, id: rxPatient.id };
  const contactTypes = organization.ref_contact?.type ?? [];

  const currentDate = getUpdatedDate();

  const propsToUpdate: OfflineUpsertProps<RxPatient> = {
    ...newUpsert.record,
    id: rxPatient.id,
    hidden: !!newUpsert.void,
    upsert_offline_id: uuid(),
    default_bill_to_id: patientUpsert.defaultBillToRecord?.default_bill_to_id,
    updated: currentDate,
  };

  if (newUpsert.record) {
    newUpsert.record.offline_id = rxPatient.upsert?.record?.offline_id;
  }

  if (alert) {
    if (newUpsert.alert) {
      newUpsert.alert = [...excludeExisting(alert, newUpsert.alert), ...alert].reverse().map(mapToUUID);
    } else {
      newUpsert.alert = alert.map(mapToUUID);
    }
  }

  if (related) {
    if (newUpsert.related) {
      newUpsert.related = [...excludeExisting(related, newUpsert.related, 'related_contact_type_id'), ...related]
        .filter((r) => r.id || !r.end_date)
        .map((r) => ({
          ...r,
          id: r.id === '' ? undefined : r.id,
        }));
    } else {
      newUpsert.related = related;
    }

    const relatedCurrentNew = await mapRelatedUpsertToRelated(related, contactTypes);
    const relatedCurrentOld = excludeRemovedRelatedContact(rxPatient.related_current ?? [], related);
    propsToUpdate.related_current = [
      ...excludeExisting(relatedCurrentNew, relatedCurrentOld ?? [], 'contact_type_id'),
      ...relatedCurrentNew,
    ];
    const relatedFields = getRelatedFields(propsToUpdate.related_current);
    propsToUpdate.related_ids = relatedFields.related_ids;
    propsToUpdate.related_names = relatedFields.related_names;
    propsToUpdate.related_types = relatedFields.related_types;
  }

  if (ownership) {
    const newOwnershipUpsert = getOwnershipUpserWithUpdatedSyndicateId(ownership);
    const existingOwnershipUpsert = newUpsert.ownership;
    if (existingOwnershipUpsert) {
      newUpsert.ownership = joinOwnershipUpserts(existingOwnershipUpsert, newOwnershipUpsert);
    } else {
      newUpsert.ownership = newOwnershipUpsert;
    }

    const ownershipAllNew = await mapOwnershipUpsertToOwnership(newOwnershipUpsert);
    const ownershipAllOld = excludeExisting(
      newOwnershipUpsert.filter((item: PatientOwnershipUpsert) => item.void),
      rxPatient.ownership_all ?? [],
      'syndicate_id'
    );
    propsToUpdate.ownership_all = [...ownershipAllOld, ...ownershipAllNew];
    propsToUpdate.ownership_current = ownershipAllNew[ownershipAllNew.length - 1] as RelatedOwnershipEntryCurrent;
    const ownershipFields = getOwnershipFields(propsToUpdate.ownership_all?.[0] ?? {});
    propsToUpdate.owner_ids = ownershipFields.owner_ids;
    propsToUpdate.owner_names = ownershipFields.owner_names;
    propsToUpdate.owner_percentages = ownershipFields.owner_percentages;
  }

  propsToUpdate.upsert = newUpsert;

  if (info) {
    const updatedInfoUpsert = info.find(({ void: voided }) => !voided);
    const voidedInfoUpsert = info.find(({ void: voided }) => voided);
    const voidedInfo = rxPatient.info?.find(({ id }) => voidedInfoUpsert?.id === id);

    // below is removing all voids for offline created info properties as any voids
    // for online created info won't have uuids for id
    const cleanInfo = info.filter(({ id }) => !isUuid(id ?? ''));

    const priorUpdateWithInfoTypeRemoved = newUpsert.info?.filter((infoUpsert) => {
      // when an offline created infotypes are deleted, we need to remove the upsert
      if (!updatedInfoUpsert && voidedInfo?.type_id === infoUpsert.record?.type_id) {
        return false;
      }
      // filter out infotype upserts that have previously been updated offline
      // the updated upsert is being added below
      if (infoUpsert.record?.type_id !== updatedInfoUpsert?.record?.type_id) {
        return true;
      }

      // keep voids for online created infotypes
      if (!isUuid(infoUpsert.id ?? '') && infoUpsert.void) {
        return true;
      }
      return false;
    });

    newUpsert.info = priorUpdateWithInfoTypeRemoved ? [...priorUpdateWithInfoTypeRemoved, ...cleanInfo] : cleanInfo;
    propsToUpdate.upsert.info = newUpsert.info.length ? newUpsert.info : undefined;

    let infoProperty: Info[] = [...(rxPatient.info ?? [])];

    if (updatedInfoUpsert) {
      const infoUpdates = info.filter(({ void: voided }) => !voided);
      infoUpdates.forEach((update) => {
        const newInfoObject = {
          id: getOfflineId(),
          value: update?.record?.value,
          type_id: update?.record?.type_id ?? '',
          isNew: true,
          name_key: Object.keys(patientInfoTypeId).find(
            (key) => patientInfoTypeId[key as keyof typeof patientInfoTypeId] === update?.record?.type_id
          ),
        };

        if (!rxPatient.info?.length) {
          infoProperty = [...infoProperty, newInfoObject];
        } else {
          const foundIdx = rxPatient.info?.findIndex(({ type_id }) => type_id === update.record?.type_id);

          if (foundIdx !== -1) {
            infoProperty[foundIdx] = newInfoObject;
          } else {
            infoProperty.push(newInfoObject);
          }
        }
      });
    } else {
      // if no updatedInfoUpsert, that means only a delete upsert was sent, below we're
      // filtering out the deleted one
      infoProperty = rxPatient.info?.filter(({ id }) => id !== info[0].id) ?? [];
    }

    propsToUpdate.info = infoProperty;
  }

  if (newUpsert.alert?.length) {
    const existingAlerts = rxPatient.alert ?? [];

    if (newUpsert.alert?.[0].void) {
      propsToUpdate.alert = existingAlerts.filter(({ id }) => id !== newUpsert.alert?.[0].id);
    } else {
      propsToUpdate.alert = existingAlerts
        .filter(({ id }) => id !== newUpsert.alert?.[0].id)
        .concat([
          {
            id: newUpsert.alert[0].id ?? '',
            type_id: newUpsert.alert[0].record?.type_id ?? '',
            note: newUpsert.alert[0].record?.note,
            patient_id: rxPatient.id,
            organization_id: rxPatient.organization_id,
          },
        ]);
    }
  }

  propsToUpdate.document = getPatientDocument({ ...rxPatient, ...propsToUpdate });

  return propsToUpdate;
};

const excludeRemovedRelatedContact = (
  existing: RelatedContactEntryCurrent[],
  relatedUpsert: PatientRelatedUpsert[]
) => {
  const idToExclude = new Set(
    relatedUpsert.filter((item) => item.end_date).map((item) => item.related_contact_type_id)
  );
  return existing.filter((item) => !idToExclude.has(item.contact_type_id));
};

const mapRelatedUpsertToRelated = async (relatedUpsert: PatientRelatedUpsert[], contactTypes: ContactTypeDto[]) => {
  const related: RelatedContactEntryCurrent[] = [];
  for (const item of relatedUpsert) {
    const contact = await getContact({
      id: item.related_contact_id ?? null,
      offline_id: item.offline_related_contact_id,
    });

    if (!item.end_date) {
      related.push({
        id: item.id ?? '',
        contact_type_id: item.related_contact_type_id,
        contact_type_name_key:
          contactTypes.find((type) => type.type_id === item.related_contact_type_id)?.name_key ?? '',
        contact_id: contact.id,
        contact_name: contact.name,
        contact_number: contact.number,
        effective_date: item.effective_date,
        primary: item.primary,
      });
    }
  }
  return related;
};

const getOwnershipUpserWithUpdatedSyndicateId = (upsert: PatientOwnershipUpsert[]): PatientOwnershipUpsert[] =>
  upsert.map((o) => ({ ...o, syndicate_id: o.syndicate_id ?? getOfflineId() }));

export const joinOwnershipUpserts = (
  upsertOld: PatientOwnershipUpsert[],
  upsertNew: PatientOwnershipUpsert[]
): PatientOwnershipUpsert[] => {
  const removeIds = new Set();
  upsertNew.forEach((ownership) => {
    if (ownership.syndicate_id && isNaN(+ownership.syndicate_id) && ownership.void) {
      upsertOld.forEach((o) => {
        if (o.syndicate_id === ownership.syndicate_id) {
          removeIds.add(o.syndicate_id);
        }
      });
    }
  });
  upsertNew = upsertNew.filter((o) => !removeIds.has(o.syndicate_id));
  upsertOld = upsertOld.filter((o) => !removeIds.has(o.syndicate_id));

  return [...excludeExisting(upsertNew, upsertOld, 'syndicate_id'), ...upsertNew];
};

const mapOwnershipUpsertToOwnership = async (ownershipUpsert: PatientOwnershipUpsert[]) => {
  const ownerships: RelatedOwnershipEntryAll[] = [];
  for (const i in ownershipUpsert) {
    const item = ownershipUpsert[i];
    if (item.void) {
      continue;
    }

    const owner: RelatedOwner[] = [];
    for (const o in item.owner) {
      const contact = await getContact({
        id: item.owner[+o].related_contact_id ?? null,
        offline_id: item.owner[+o].offline_related_contact_id,
      });

      if (!contact) {
        continue;
      }

      owner.push({
        id: getOfflineId(),
        contact_id: contact.id,
        offline_contact_id: contact.offline_id,
        name: contact.name,
        number: contact.number,
        percentage: item.owner[+o].percentage,
        email: contact.email,
        primary: item.owner[+o].primary,
      });
    }

    ownerships.push({
      ...item,
      owner,
    });
  }

  return ownerships;
};

const getOwnershipFields = (ownership: RelatedOwnershipEntryAll) => ({
  owner_ids: ownership.owner?.map((owner) => owner.contact_id ?? owner.offline_contact_id ?? '') ?? [],
  owner_names: ownership.owner?.map((owner) => owner.name ?? '') ?? [],
  owner_percentages: ownership.owner?.map((owner) => owner.percentage ?? '100') ?? [],
});

const getRelatedFields = (related: RelatedContactEntryCurrent[]) => ({
  related_ids: related.map((r) => r.contact_id ?? '') ?? [],
  related_names: related.map((r) => r.contact_name ?? '') ?? [],
  related_types: related.map((r) => r.contact_type_id ?? '') ?? [],
});

const getContact = ({ id, offline_id }: { id: Maybe<string>; offline_id?: Maybe<string> }) => {
  const selector: Record<string, string> = {};
  if (id) {
    selector._id = id;
  }
  if (offline_id) {
    selector.offline_id = offline_id;
  }
  return db.contact.findOne({ selector }).exec();
};
