import { KeyFunctionMap, MigrationStrategies, RxCollectionCreator } from 'rxdb/dist/types/types';
import { organizationSchema, RxOrganization } from './schemas/organizationSchema';
import { patientSchema, RxPatient } from './schemas/patientSchema';
import { practiceSchema, RxPractice } from './schemas/practiceSchema';
import { contactSchema, RxContact } from './schemas/contactSchema';
import { noteSchema, RxNote } from './schemas/noteSchema';
import { RxServiceRendered, serviceRenderedSchema } from './schemas/serviceRenderedSchema';
import { RxServiceActivity, serviceActivitySchema } from './schemas/serviceActivititySchema';
import { fileSchema, RxFile } from './schemas/fileSchema';
import { ledgerSchema, RxLedger } from './schemas/ledgerSchema';
import { reminderSchema, RxReminder } from './schemas/reminderSchema';
import { invoiceSchema, RxInvoice } from './schemas/invoiceSchema';
import { invoiceContextSchema, RxInvoiceContext } from './schemas/invoiceContextSchema';
import { infoTextSchema, RxInfoText } from './schemas/infoTextSchema';
import { prescriptionSchema, RxPrescription } from './schemas/prescriptionSchema';
import { prescriptionFlattenedSchema, RxPrescriptionFlattened } from './schemas/prescriptionFlattenedSchema';
import { RxService, serviceSchema } from './schemas/serviceSchema';
import { RxUpsert, upsertSchema } from './schemas/upsertSchema';
import { RxReferenceData, referenceDataSchema } from './schemas/referenceDataSchema';

const buildMigrationStrategies = <T>(version: number, customMigrations: KeyFunctionMap = {}) => {
  /* Whenever field is deleted from schema, add it to customMigrations. Example:
   buildMigrationStrategies<RxOrganization>(organizationSchema.version, {
     5: (organization: RxOrganization) => {
       delete organization.phone;  // phone field is deleted from organization
       return organization;
     }
   })
  */
  const migrationStrategies: MigrationStrategies = {};
  for (let i = 1; i <= version; i++) {
    migrationStrategies[i] = (_: T) => _;
  }
  return { ...migrationStrategies, ...customMigrations };
};

export enum ReplicationCollection {
  Organization = 'organization',
  Patient = 'patient',
  Contact = 'contact',
  Note = 'note',
  ServiceRendered = 'service_rendered',
  ServiceActivity = 'service_activity',
  File = 'file',
  Ledger = 'ledger',
  Reminder = 'reminder',
  Invoice = 'invoice',
  ContactText = 'contact_text',
  Prescription = 'prescription',
  Service = 'service',
  ServiceText = 'service_text',
  Upsert = 'upsert',
  PrescriptionFlattened = 'prescription_flattened',
}

export type CollectionType =
  | 'organization'
  | 'patient'
  | 'practice'
  | 'contact'
  | 'note'
  | 'service_rendered'
  | 'service_activity'
  | 'file'
  | 'invoice'
  | 'invoice_context'
  | 'ledger'
  | 'reminder'
  | 'contact_text'
  | 'prescription'
  | 'service'
  | 'service_text'
  | 'upsert'
  | 'reference_data'
  | 'prescription_flattened';

export const collections: Record<CollectionType, RxCollectionCreator> = {
  organization: {
    schema: organizationSchema,
    migrationStrategies: buildMigrationStrategies<RxOrganization>(organizationSchema.version),
  },
  patient: {
    schema: patientSchema,
    migrationStrategies: buildMigrationStrategies<RxPatient>(patientSchema.version),
  },
  practice: {
    schema: practiceSchema,
    migrationStrategies: buildMigrationStrategies<RxPractice>(practiceSchema.version, {
      2: (practice: RxPractice) => {
        delete (practice as Record<string, any>).interest_run;
        return practice;
      },
    }),
  },
  contact: {
    schema: contactSchema,
    migrationStrategies: buildMigrationStrategies<RxContact>(contactSchema.version),
  },
  note: {
    schema: noteSchema,
    migrationStrategies: buildMigrationStrategies<RxNote>(noteSchema.version, {
      5: (note: RxNote) => {
        note.patient_id = note.patient_id || '';
        note.contact_id = note.contact_id || '';
        return note;
      },
    }),
  },
  service_rendered: {
    schema: serviceRenderedSchema,
    migrationStrategies: buildMigrationStrategies<RxServiceRendered>(serviceRenderedSchema.version),
  },
  service_activity: {
    schema: serviceActivitySchema,
    migrationStrategies: buildMigrationStrategies<RxServiceActivity>(serviceActivitySchema.version),
  },
  file: {
    schema: fileSchema,
    migrationStrategies: buildMigrationStrategies<RxFile>(fileSchema.version, {
      4: (file: RxFile) => {
        file.patient_id = file.patient_id || '';
        file.contact_id = file.contact_id || '';
        return file;
      },
    }),
  },
  ledger: {
    schema: ledgerSchema,
    migrationStrategies: buildMigrationStrategies<RxLedger>(ledgerSchema.version),
  },
  reminder: {
    schema: reminderSchema,
    migrationStrategies: buildMigrationStrategies<RxReminder>(reminderSchema.version),
  },
  invoice: {
    schema: invoiceSchema,
    migrationStrategies: buildMigrationStrategies<RxInvoice>(invoiceSchema.version),
  },
  invoice_context: {
    schema: invoiceContextSchema,
    migrationStrategies: buildMigrationStrategies<RxInvoiceContext>(invoiceContextSchema.version),
  },
  contact_text: {
    schema: infoTextSchema,
    migrationStrategies: buildMigrationStrategies<RxInfoText>(infoTextSchema.version),
  },
  prescription: {
    schema: prescriptionSchema,
    migrationStrategies: buildMigrationStrategies<RxPrescription>(prescriptionSchema.version, {
      2: (prescription: RxPrescription) => {
        delete (prescription as Record<string, any>).filled_invoice_id;
        return prescription;
      },
      4: (prescription: RxPrescription) => {
        delete (prescription as Record<string, any>).externally_filled;
        delete (prescription as Record<string, any>).parent_id;
        return prescription;
      },
      5: (prescription: RxPrescription) => {
        delete (prescription as Record<string, any>).filled_service_rendered_id;
        delete (prescription as Record<string, any>).filled_item_din;
        delete (prescription as Record<string, any>).filled_date;
        delete (prescription as Record<string, any>).filled_item_lot_number;
        delete (prescription as Record<string, any>).filled_item_serial_number;
        delete (prescription as Record<string, any>).filled_item_bottle_number;
        delete (prescription as Record<string, any>).filled_item_manufacturer;
        delete (prescription as Record<string, any>).filled_user_id;
        delete (prescription as Record<string, any>).filled_user_name;
        return prescription;
      },
    }),
  },
  prescription_flattened: {
    schema: prescriptionFlattenedSchema,
    migrationStrategies: buildMigrationStrategies<RxPrescriptionFlattened>(prescriptionFlattenedSchema.version),
  },
  service: {
    schema: serviceSchema,
    migrationStrategies: buildMigrationStrategies<RxService>(serviceSchema.version, {
      9: (service: RxService) => {
        delete (service as Record<string, any>).lab_validation;
        return service;
      },
    }),
  },
  service_text: {
    schema: infoTextSchema,
    migrationStrategies: buildMigrationStrategies<RxInfoText>(infoTextSchema.version),
  },
  upsert: {
    schema: upsertSchema,
    migrationStrategies: buildMigrationStrategies<RxUpsert>(upsertSchema.version),
  },
  reference_data: {
    schema: referenceDataSchema,
    migrationStrategies: buildMigrationStrategies<RxReferenceData>(referenceDataSchema.version),
  },
};
