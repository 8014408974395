import React from 'react';
import { QueryGetPatientVaccineReportArgs, QueryRptGetPatientVaccineArgs } from '../../graph/types';
import { translations } from '../../constants/translations';
import { Menu } from 'antd';
import { DeleteOutlined, MailOutlined, MergeCellsOutlined, PrinterOutlined, SyncOutlined } from '@ant-design/icons';
import { Reports } from '../../util/reportUtils';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useLDFlag } from '../../hooks/useLDHooks';
import { useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';
import { ModalTypes, printPatientVaccineReport } from './patientUtils';

type ActionButtonsForPatientProps = {
  setIsModalOpen: (value: ModalTypes) => void;
  disableMailOption: boolean;
  handlePatientDelete: () => Promise<void> | void;
  handlePatientMerge: () => void;
  handlePatientSync: () => void;
  vaccineReportVariables: QueryGetPatientVaccineReportArgs | QueryRptGetPatientVaccineArgs;
};

export const ActionButtonsForPatient: React.FC<ActionButtonsForPatientProps> = ({
  setIsModalOpen,
  disableMailOption,
  handlePatientDelete,
  handlePatientMerge,
  handlePatientSync,
  vaccineReportVariables,
}) => {
  const usesActiveReports = useLDFlag(LDFlagNames.ARPatientVaxReport);
  const navigateToReportViewer = useNavigateToReportViewer();
  return (
    <Menu>
      <Menu.Item
        key='1'
        icon={<MailOutlined />}
        onClick={() => setIsModalOpen(ModalTypes.EmailReport)}
        disabled={disableMailOption}
      >
        {translations.patientsPage.emailHistoryReport}
      </Menu.Item>
      <Menu.Item key='2' icon={<PrinterOutlined />} onClick={() => setIsModalOpen(ModalTypes.PrintReport)}>
        {translations.patientsPage.printHistoryReport}
      </Menu.Item>
      <Menu.Item key='3' icon={<MergeCellsOutlined />} onClick={handlePatientMerge}>
        {translations.patientsPage.mergeButton}
      </Menu.Item>
      <Menu.Item key='4' icon={<DeleteOutlined />} onClick={handlePatientDelete}>
        {translations.patientsPage.deleteButton}
      </Menu.Item>
      <Menu.Item key='5' icon={<SyncOutlined />} onClick={handlePatientSync}>
        {translations.patientsPage.syncInvoices}
      </Menu.Item>
      <Menu.Item
        key='6'
        icon={<MailOutlined />}
        onClick={() => setIsModalOpen(ModalTypes.EmailVaccineReport)}
        disabled={disableMailOption}
      >
        {translations.patientsPage.emailVaccineReport}
      </Menu.Item>
      <Menu.Item
        key='7'
        icon={<PrinterOutlined />}
        onClick={() =>
          printPatientVaccineReport(
            vaccineReportVariables,
            usesActiveReports,
            Reports.PatientVax,
            navigateToReportViewer
          )
        }
      >
        {translations.patientsPage.printVaccineReport}
      </Menu.Item>
    </Menu>
  );
};
