import { Button } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import React, { useMemo, useState } from 'react';
import { AbbreviateToWidth } from '../../../components/AbbreviateToWidth/AbbreviateToWidth';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';
import { DropdownButtonWithMenu } from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../../constants/layout';
import { patientPropertyNames } from '../../../constants/propertyNames';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { Patient } from '../../../graph/types';
import { useGetPatientsWithSearch } from '../../../hooks/ajax/patients/patientHooks';
import { useNavigationToRoute, withPatientIdParameter } from '../../../hooks/route/navigationHooks';
import { useShouldShowTooManyResultsWarning } from '../../../hooks/setWarningIfTooManyResults';
import {
  TableFilterAndSortKey,
  TableKey,
  getDefaultColumnKeysForPatients,
  useTableColumnDisplayFilter,
  useTableResetFilterAndSort,
} from '../../../hooks/tableHooks';
import { addSortingPriorityTo } from '../../../util/filterAndSorting';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { getSpeciesFilterOptions } from '../../../util/patientFilterAndSorting';
import { mapRefDataName } from '../../../util/refDataUtil';
import { getStatus, getStatusTag } from '../../../util/statusUtils';
import { getPatientRelationTags } from '../../../util/tags';
import { getActionsForPatientList } from '../patientUtils';
import { basicPatientColumns } from './patientColumns';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';

export const PatientsOverview: React.FC = () => {
  const {
    state: { organization },
  } = useOrganizationContext();

  const [searchValue, setSearchValue] = useState<string>();
  const { patients, patientsLoading, refetchPatients } = useGetPatientsWithSearch(organization?.id ?? '', searchValue);
  const shouldShowTooManyResultsWarning = useShouldShowTooManyResultsWarning();
  const { referenceData } = useGetReferenceData(organization?.id ?? '');

  const { navigateTo } = useNavigationToRoute();

  const { filteredValue, sortOrderMap, tableChangeHandler, resetFiltersAndSort, modifiedFields } =
    useTableResetFilterAndSort(TableFilterAndSortKey.PatientsOverview);

  const patientReferenceData = useMemo(() => organization?.ref_patient, [organization]);
  const speciesFilterOptions: ColumnFilterItem[] = useMemo(
    () => (patientReferenceData ? getSpeciesFilterOptions(patientReferenceData, patients ?? []) : []),
    [patientReferenceData, patients]
  );

  const optionalColumns: CustomColumnType<Patient>[] = useMemo(
    () => [
      {
        ...basicPatientColumns.name_2,
        filteredValue: filteredValue[patientPropertyNames.name_2] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name_2],
        width: 200,
      },
      {
        ...basicPatientColumns.name_3,
        filteredValue: filteredValue[patientPropertyNames.name_3] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name_3],
        width: 200,
      },
      {
        ...basicPatientColumns.number,
        filteredValue: filteredValue[patientPropertyNames.number] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.number],
        width: 100,
      },
      {
        ...basicPatientColumns.species_name,
        render: (p: Patient) =>
          mapRefDataName({ id: p.species_id || '', name: p.species_name || '' }, patientReferenceData?.species),
        filters: speciesFilterOptions,
        filteredValue: filteredValue[patientPropertyNames.species_name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.species_name],
        width: 150,
      },
      {
        ...basicPatientColumns.breed_name,
        filteredValue: filteredValue[patientPropertyNames.breed_name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.breed_name],
        render: (p: Patient) =>
          p.breed_id && mapRefDataName({ id: p.breed_id || '', name: p.breed_name || '' }, patientReferenceData?.breed),
        width: 150,
      },
      {
        ...basicPatientColumns.gender_name,
        filteredValue: filteredValue[patientPropertyNames.gender_name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.gender_name],
        render: (p: Patient) =>
          p.gender_id &&
          mapRefDataName({ id: p.gender_id || '', name: p.gender_name || '' }, patientReferenceData?.gender),
        width: 100,
      },
      {
        ...basicPatientColumns.owner_names,
        filteredValue: filteredValue[patientPropertyNames.owner_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.owner_names],
        width: 275,
      },
      {
        ...basicPatientColumns.related_names,
        filteredValue: filteredValue[patientPropertyNames.related_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.related_names],
        render: (patient: Patient) => getPatientRelationTags(patient, referenceData?.contact_type || []),
        width: 300,
      },
      {
        ...basicPatientColumns.status,
        filteredValue: filteredValue.status ?? null,
        sortOrder: sortOrderMap.status,
        render: (record: Patient) => <>{getStatusTag(getStatus(record))}</>,
        width: 100,
      },
    ],
    [referenceData?.contact_type, filteredValue, patientReferenceData, sortOrderMap, speciesFilterOptions]
  );

  const defaultColumnKeys = useMemo(() => getDefaultColumnKeysForPatients(optionalColumns), [optionalColumns]);

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter<Patient>(TableKey.PatientsOverview, optionalColumns, defaultColumnKeys);

  const navigateToAddPatient = () => {
    navigateTo(routes.addPatient);
  };
  const navigateToViewPatient = (patientId: string) => () => {
    navigateTo(routes.viewPatient, withPatientIdParameter(patientId));
  };

  const linkToViewPatient = (text: string, patient: Patient) => {
    return (
      <TableCellLink onClick={navigateToViewPatient(patient.id)}>
        <AbbreviateToWidth width={250} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
      </TableCellLink>
    );
  };

  const linkPatientMenu = (patient: Patient) => {
    const actions = getActionsForPatientList({
      editPatientAction: navigateToViewPatient(patient.id),
    });

    return <DropdownButtonWithMenu menuItemProps={actions} />;
  };

  const columns: CustomColumnType<Patient>[] = [
    {
      ...basicPatientColumns.name,
      render: (text: string, values: Patient) => linkToViewPatient(text, values),
      filteredValue: filteredValue[patientPropertyNames.name] ?? null,
      sortOrder: sortOrderMap[patientPropertyNames.name],
      width: 250,
    },
    ...displayedColumns,
    {
      title: translations.patientsPage.columns.actions,
      key: 'actions',
      render: (values: Patient) => linkPatientMenu(values),
    },
  ];
  addSortingPriorityTo(columns);

  const handleOnClear = (key?: string) => {
    if (!key) {
      setSearchValue('');
    }
    resetFiltersAndSort(key);
    resetDisplayedColumnsToDefault();
  };

  return (
    <>
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.patientsPage.title}
            searchBoxPlaceholder={translations.patientsPage.searchPlaceholder}
            onSearchValueChange={(event) => setSearchValue(event.target.value)}
            onClear={handleOnClear}
            loading={patientsLoading}
            onSearch={refetchPatients}
            showTooManyResultsWarning={shouldShowTooManyResultsWarning(patients ?? [])}
            tags={mapKeysToColumnTitle(modifiedFields, columns)}
          />
        }
        extra={[
          <ColumnDisplayFilter
            key='columnDisplayFilter'
            initiallyDisplayedColumns={displayedColumnKeys}
            columnKeyAndTitleList={columnKeyAndTitleList}
            setDisplayedColumns={setDisplayedColumnKeys}
            resetDisplayedColumnsToDefault={resetDisplayedColumnsToDefault}
          />,
          <Button key='addPatient' type='primary' onClick={navigateToAddPatient}>
            {translations.patientsPage.buttons.addPatient}
          </Button>,
        ]}
      />
      <TableWithCustomFiltering<Patient>
        tableKey={TableKey.PatientsOverview}
        dataSource={patients ?? []}
        columns={columns}
        loading={patientsLoading}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
    </>
  );
};
