import { RxJsonSchema } from 'rxdb';
import { Patient, PatientUpsert } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaTypeWithUpsert, RxSchemaWithDocument } from '../databaseUtils';

type PatientOfflineFields = Omit<Patient, 'file' | 'note' | 'service_activity' | 'service' | 'reminder'>;
export type RxPatient = RxSchemaTypeWithUpsert<PatientOfflineFields, PatientUpsert, 'patient'> & RxSchemaWithDocument;

export const patientSchema: RxJsonSchema<RxPatient> = {
  version: 9,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.String,
    number: Maybe(RxScalars.String),
    name: RxScalars.String,
    name_2: Maybe(RxScalars.String),
    name_3: Maybe(RxScalars.String),
    species_id: RxScalars.String,
    species_name: Maybe(RxScalars.String),
    gender_id: Maybe(RxScalars.ID),
    gender_name: Maybe(RxScalars.String),
    breed_id: Maybe(RxScalars.ID),
    breed_name: Maybe(RxScalars.String),
    color_id: Maybe(RxScalars.ID),
    color_name: Maybe(RxScalars.String),
    dob: Maybe(RxScalars.String),
    deceased: RxScalars.Boolean,
    deceased_date: Maybe(RxScalars.String),
    inactive: RxScalars.Boolean,
    created_practice_id: Maybe(RxScalars.ID),
    primary_owner_id: Maybe(RxScalars.ID),
    primary_owner_number: Maybe(RxScalars.String),
    primary_owner_name: Maybe(RxScalars.String),
    default_bill_to_id: Maybe(RxScalars.ID),
    tag_names: Maybe(RxScalars.Array),
    owner_ids: Maybe(RxScalars.Array),
    owner_names: Maybe(RxScalars.Array),
    owner_percentages: Maybe(RxScalars.Array),
    related_ids: Maybe(RxScalars.Array),
    related_names: Maybe(RxScalars.Array),
    related_types: Maybe(RxScalars.Array),
    info: Maybe(RxScalars.Array),
    image: RxScalars.Array,
    text: Maybe(RxScalars.Array),
    related_all: Maybe(RxScalars.Array),
    updated: RxScalars.String,
    ownership_all: Maybe(RxScalars.Array),
    ownership_current: Maybe(RxScalars.Object),
    related_current: Maybe(RxScalars.Array),
    profile_image: Maybe(RxScalars.Object),
    tag: Maybe(RxScalars.Array),
    offline_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
    hidden: RxScalars.Boolean,
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    document: RxScalars.String,
    alert: Maybe(RxScalars.Array),
    contact_alert: Maybe(RxScalars.Array),
    contact_invoice_alert: Maybe(RxScalars.Array),
  },
  required: ['id'],
  indexes: ['updated', ['id', 'hidden'], ['offline_id', 'hidden']],
};
