import React, { PropsWithChildren, useEffect } from 'react';
import { useGetUrlForFileDownload } from '../../../hooks/ajax/fileUrl/fileUrlHooks';
import { Loading } from '../../Loading/Loading';
import { Button, Row } from 'antd';
import { PdfPreview } from './PdfPreview';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { translations } from '../../../constants/translations';
import { DocxPreview } from './DocxPreview';
import { FileData } from '../../../util/fileUpload';
import { TiffPreview } from './TiffPreview';
import { ImagePreview } from '../../../globalStyles.style';

interface FilePreviewProps extends PropsWithChildren<unknown> {
  organizationId: string;
  metadata: FileData;
  replaceFileButton: JSX.Element;
  setFileUrl?: (url: string) => void;
  isfullScreenMode?: boolean;
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  organizationId,
  metadata,
  replaceFileButton,
  setFileUrl,
  isfullScreenMode,
}: FilePreviewProps) => {
  function getFileType(metadata: FileData) {
    const nameParts = metadata.name.split('.');
    return nameParts[nameParts.length - 1];
  }

  const fileType = getFileType(metadata);
  let contentType;
  switch (fileType) {
    case 'bmp':
      contentType = 'image/bmp;';
      break;
    case 'gif':
      contentType = 'image/gif;';
      break;
    case 'jpg':
    case 'jpeg':
      contentType = 'image/jpeg;';
      break;
    case 'png':
      contentType = 'image/png;';
      break;
    case 'svg':
      contentType = 'image/svg+xml;';
      break;
    case 'pdf':
      contentType = 'application/pdf;';
      break;
    case 'docx':
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;';
      break;
    case 'tiff':
      contentType = 'image/tiff;';
      break;
    case 'tif':
      contentType = 'image/tif;';
      break;
    default:
      contentType = undefined;
  }

  const { fileUrl, fileUrlLoading, fileUrlError } = useGetUrlForFileDownload({
    organizationId,
    filePointer: metadata.pointer,
    fileName: metadata.name,
    contentType,
  });

  useEffect(() => {
    if (fileUrl) {
      setFileUrl?.(fileUrl.url);
    }
  }, [fileUrl, setFileUrl]);

  if (fileUrlLoading) {
    return <Loading />;
  }

  if (!fileUrl || fileUrlError) {
    return <p>Error loading file</p>;
  }

  let filePreview;
  switch (fileType) {
    case 'bmp':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'png':
      filePreview = <ImagePreview src={fileUrl.url} />;
      break;
    case 'svg':
      filePreview = <ImagePreview src={fileUrl.url} height={'200px'} />;
      break;
    case 'pdf':
      filePreview = (
        <PdfPreview
          url={fileUrl.url}
          icon={isfullScreenMode ? <FilePdfOutlined style={{ fontSize: '30px' }} /> : undefined}
        />
      );
      break;
    case 'docx':
      filePreview = (
        <DocxPreview
          url={fileUrl.url}
          icon={isfullScreenMode ? <FileWordOutlined style={{ fontSize: '30px' }} /> : undefined}
        />
      );
      break;
    case 'tiff':
    case 'tif':
      filePreview = <TiffPreview url={fileUrl.url} />;
      break;
    default:
      filePreview = <p>{metadata.name}</p>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: isfullScreenMode ? '70%' : '100%',
      }}
    >
      {filePreview}
      <Row style={{ margin: '12px 0' }}>
        {replaceFileButton}
        <Button target='blank' href={fileUrl.url} icon={<DownloadOutlined />}>
          {translations.recordList.downloadFile}
        </Button>
      </Row>
    </div>
  );
};
