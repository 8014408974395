import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Contact,
  ContactAlert,
  Invoice,
  InvoiceUpsert,
  OrganizationDto,
  Patient,
  Query,
  RelatedContactEntryCurrent,
  RelatedOwner,
} from '../../../graph/types';
import { translations } from '../../../constants/translations';
import { SearchOutlined } from '@ant-design/icons/lib';
import './AddInvoiceModal.css';
import { useInsertInvoice, useInvoiceOfflineInsert } from '../../../hooks/ajax/invoice/invoiceHooks';
import { buildInvoiceUpsert, buildInvoiceUpsertForContact } from './addInvoiceUtil';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { useDefaultPracticeId } from '../../../hooks/ajax/practice/practiceHooks';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';

import { PatientContactRelationTypeNameKey } from '../../../constants/referenceData/patientReferenceData';
import { calculateTotalPercentage } from '../../Patients/ViewPatient/PatientOwnership/ownershipUtils';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { joinPatientOrContactNameAndNumber } from '../../../util/displaying';
import { BillTo } from '../../../components/BillTo/BillTo';
import { Loading } from '../../../components/Loading/Loading';
import { useOffline } from '../../../util/offline/offlineUtil';
import { flushSync } from 'react-dom';
import { upsertDateFormat } from '../../../constants/formats';
import { useAlerts } from '../../../hooks/alertHooks';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import { GetContact } from '../../../graph/queries/contacts';

const otherLabel = translations.invoicesPage.addInvoiceModal.other;

export interface SaveInvoiceProps {
  patientWithOwnershipAndRelationship: Patient;
  billTo: string;
  patientContactRelationMap: {
    [key: string]: RelatedContactEntryCurrent;
  };
  invoiceStakeholders: RelatedOwner[];
  billToId: string;
}

interface AddInvoiceModalProps {
  visible: boolean;
  closeModal: () => void;
  selectedPatient: Patient;
  onSuccess?: () => Promise<any>;
  changePatient?: () => void;
  redirectionRoute?: (invoiceId: string) => string;
  organization: OrganizationDto;
}

export const AddInvoiceModal: React.FC<AddInvoiceModalProps> = ({
  visible,
  closeModal,
  selectedPatient,
  changePatient,
  redirectionRoute,
  onSuccess,
  organization,
}: AddInvoiceModalProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { navigateTo } = useNavigationToRoute();
  const [insertInvoice] = useInsertInvoice();
  const offlineInsert = useInvoiceOfflineInsert();
  const { enabledAndOffline } = useOffline();
  const [saveProps, setSaveProps] = useState<SaveInvoiceProps>();
  const [changesMade, setChangesMade] = useState(false);
  const {
    renderPatientsAlertsModal,
    usesAlerts,
    contactMap,
    getContactAlerts,
    setAlertsModalOpen,
    renderContactAlertsModal,
    alertsModalOpen,
  } = useAlerts('prompt_invoice', selectedPatient, organization as any as OrganizationDto);
  const [contactAlerts, setContactAlerts] = useState<ContactAlert[]>();
  const [selectedContact, setSelectedContact] = useState<Contact>();

  useEffect(() => {
    setChangesMade(false);
  }, [visible]);

  const changePatientButton = changePatient ? <SearchOutlined onClick={changePatient} /> : undefined;

  const addNewInvoice = async (invoice: InvoiceUpsert) => {
    try {
      setIsSaving(true);
      let createdInvoice: Invoice | undefined | null;
      if (!enabledAndOffline) {
        const { data } = await insertInvoice({
          variables: {
            invoice,
            organizationId,
          },
        });
        createdInvoice = data?.upsertInvoice;
      } else {
        createdInvoice = await offlineInsert?.(invoice);
      }
      if (createdInvoice) {
        flushSync(() => {
          setChangesMade(false);
        });
        showSuccessMessage(translations.invoicesPage.addInvoiceModal.getSuccessMessage(createdInvoice.number));
        await onSuccess?.();
        setIsSaving(false);
        closeModal();
        redirectionRoute
          ? navigateTo(redirectionRoute(createdInvoice.id))
          : navigateTo(routes.viewInvoice, withInvoiceIdParameter(createdInvoice.id));
      }
    } catch (err) {
      showErrorMessage(err.message ? err.message : err);
      setIsSaving(false);
    }
  };

  const handleSave = async () => {
    if (saveProps) {
      const { patientWithOwnershipAndRelationship, billTo, invoiceStakeholders, patientContactRelationMap } = saveProps;

      const ownershipCurrent = patientWithOwnershipAndRelationship?.ownership_current;
      if (billTo === PatientContactRelationTypeNameKey.Owner) {
        if (!ownershipCurrent) {
          showErrorMessage(translations.invoicesPage.addInvoiceModal.noOwnershipError);
          return;
        }
        await addNewInvoice(
          buildInvoiceUpsert(
            selectedPatient.id,
            practiceId,
            ownershipCurrent.owner,
            upsertDateFormat,
            ownershipCurrent.syndicate_id,
            selectedPatient.offline_id
          )
        );
      } else if (billTo === otherLabel) {
        const totalPercentage = calculateTotalPercentage(invoiceStakeholders);

        if (totalPercentage !== 100) {
          return;
        }

        await addNewInvoice(
          buildInvoiceUpsert(
            selectedPatient.id,
            practiceId,
            invoiceStakeholders,
            upsertDateFormat,
            undefined,
            selectedPatient.offline_id
          )
        );
      } else {
        const billToRelatedContact = patientContactRelationMap[billTo];
        await addNewInvoice(
          buildInvoiceUpsertForContact(selectedPatient.id, practiceId, billToRelatedContact, upsertDateFormat)
        );
      }
    }
  };

  const onSelectContact = async (contact: Contact) => {
    setSelectedContact(contact);
    if (usesAlerts && contact.id && !Object.keys(contactMap).includes(contact.id)) {
      const { data } = await AppSyncService.client.query<Pick<Query, 'getContactEs'>>({
        query: GetContact,
        variables: { organizationId, contactId: contact.id },
      });

      const contactInvoiceAlerts = getContactAlerts(data.getContactEs);
      if (contactInvoiceAlerts && contactInvoiceAlerts.length > 0) {
        setContactAlerts(contactInvoiceAlerts);
        setAlertsModalOpen(true);
      }
    }
  };

  return (
    <ModalWithCloseConfirm
      title={translations.invoicesPage.addInvoiceModal.title}
      changesMade={changesMade}
      isSaving={isSaving}
      open={visible}
      onCancel={closeModal}
      onOk={handleSave}
      okText={translations.shared.okButtonText}
      okButtonProps={{
        disabled:
          (saveProps &&
            saveProps.billTo === otherLabel &&
            calculateTotalPercentage(saveProps.invoiceStakeholders) !== 100) ||
          !practiceId,
      }}
    >
      {!practiceId ? (
        <Loading />
      ) : (
        <SaveSpinnerAndNavigationWarning isSaving={isSaving} showNavigationWarning={false}>
          <Form layout={'vertical'} autoComplete='off'>
            <Form.Item label={translations.invoicesPage.addInvoiceModal.fields.patient}>
              {changePatientButton ? (
                <Input
                  className={'AddInvoiceModel__PatientField'}
                  disabled
                  value={selectedPatient.name || ''}
                  addonAfter={changePatientButton}
                  autoComplete='new-password'
                />
              ) : (
                <div style={{ marginLeft: '12px' }}>{joinPatientOrContactNameAndNumber(selectedPatient)}</div>
              )}
            </Form.Item>
            <BillTo
              selectedPatient={selectedPatient}
              setSaveProps={setSaveProps}
              editableTabName={otherLabel}
              setChangesMade={setChangesMade}
              onContactSelect={onSelectContact}
            />
          </Form>
          {selectedContact && contactAlerts && alertsModalOpen
            ? renderContactAlertsModal(contactAlerts, selectedContact, () => {
                setSelectedContact(undefined);
                setAlertsModalOpen(undefined);
              })
            : renderPatientsAlertsModal()}
        </SaveSpinnerAndNavigationWarning>
      )}
    </ModalWithCloseConfirm>
  );
};
