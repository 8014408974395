import React, { ReactElement, useContext, useEffect } from 'react';
import { HisaConfig, InputType, hisaRequirementsConfig, hisaSelectFields } from '../../../constants/hisaRequirements';
import { Service } from '../../../graph/types';
import { RequirementsComponent } from '../../RequirementsComponent/RequirementsComponent';
import { ServicesRenderedContext } from '../store/state';
import { setConditionTreated, setEditingServiceRendered } from '../store/actions';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { Loading } from '../../Loading/Loading';
import { SelectProps } from 'antd';
import { omit } from 'lodash';
import { useGetServiceRenderedWithInfoAndText } from '../../../hooks/ajax/service/serviceHooks';
import { FormInstance } from 'antd/lib';
import { getInitialHisaValuesFromServiceRendered } from '../ServicesRenderedTable/servicesRenderedUtil';
import { getNameKey } from '../serviceRenderedUtils';

type HisaRequirementsContentProps = {
  missingFields: HisaFieldsRequirement;
  service: Service;
  serviceRenderedId?: string;
  form?: FormInstance;
  patientId?: string;
  isForRapidBilling?: boolean;
};

export interface HisaFieldsRequirement {
  requiresResults: boolean;
  requiresLimbTreated: boolean;
  requiresDosage: boolean;
  requiresConditionTreated: boolean;
  requiresTestsAndDiagnostics: boolean;
  requiresTreatmentType: boolean;
  requiresTime: boolean;
  requiresMedicalName: boolean;
  requiresDrugName: boolean;
  requiresDescription: boolean;
  requiresVaccineName: boolean;
  requiresStructure: boolean;
  requiresModality: boolean;
}

export const HisaRequirementsContent: React.FC<HisaRequirementsContentProps> = ({
  missingFields,
  service,
  serviceRenderedId,
  form,
  patientId,
  isForRapidBilling = false,
}) => {
  const {
    dispatch,
    state: { conditionTreated },
  } = useContext(ServicesRenderedContext);
  const organizationId = useGetOrganizationIdFromRoute();
  const { referenceData, dataLoading } = useGetReferenceData(organizationId ?? '');

  const { data: serviceRendered, loading: serviceRenderedLoading } = useGetServiceRenderedWithInfoAndText(
    organizationId,
    serviceRenderedId
  );

  useEffect(() => {
    if (serviceRendered) {
      dispatch(setEditingServiceRendered(serviceRendered));
      const initialValues = {};
      getInitialHisaValuesFromServiceRendered(initialValues, serviceRendered);
      if (!form?.isFieldsTouched(Object.keys(initialValues))) {
        form?.setFieldsValue(initialValues);
      }
    }
  }, [serviceRendered, form, dispatch]);

  if (dataLoading || serviceRenderedLoading) {
    return <Loading />;
  }

  const getContent = () => {
    const content: ReactElement[] = [];
    let selectProps: SelectProps;
    Object.keys(missingFields ?? {})
      ?.filter((key) => (missingFields as HisaFieldsRequirement)[key as keyof HisaFieldsRequirement])
      .forEach((key: string) => {
        let typeConfig = hisaRequirementsConfig[key as keyof HisaFieldsRequirement];
        if (typeof typeConfig === 'function') {
          if (key === 'requiresConditionTreated') {
            typeConfig = typeConfig(conditionTreated, (condition) => dispatch(setConditionTreated(condition)));
          } else {
            typeConfig = typeConfig(service);
          }
        }

        if (typeConfig.inputtype === InputType.Select) {
          const options = referenceData?.ref_service_rendered_info_type_data
            ?.filter(
              ({ service_rendered_info_id }) =>
                String(service_rendered_info_id) === String((typeConfig as HisaConfig).typeId)
            )
            .map(({ id, name_key }) => ({
              value: id,
              label: hisaSelectFields[name_key as keyof typeof hisaSelectFields],
            }));

          selectProps = {
            options,
          };
        }

        if (typeConfig) {
          const { onUpdate, placeholder, ...itemProps } = typeConfig;
          const itemPropsForComponent = {
            ...omit(itemProps, ['nameKey', 'typeId']),
            name: getNameKey(itemProps.name, patientId, isForRapidBilling ? service.id : undefined),
          };
          content.push(
            <RequirementsComponent
              placeholder={placeholder}
              key={key}
              itemProps={itemPropsForComponent}
              onInputChange={onUpdate}
              selectProps={selectProps}
            />
          );
        }
      });
    return content;
  };

  return <>{getContent()}</>;
};
