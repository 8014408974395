import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  font-size: 16px;
`;

export const AccountBalance = styled.div`
  margin-left: auto;
  display: flex;
  margin-right: 10px;
`;

export const AccountBalanceLabel = styled.span`
  margin-right: 5px;
  margin-bottom: unset;
`;
