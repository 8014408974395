import { Tag } from 'antd';
import React from 'react';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { AbbreviateToWidth } from '../AbbreviateToWidth/AbbreviateToWidth';

interface TagWithNavigationProps {
  displayText: string;
  style?: React.CSSProperties;
  width?: number;
  navigationRoute?: string;
  navigationParameter?: Record<string, string>;
  className?: string;
}

export const TagWithNavigation: React.FC<TagWithNavigationProps> = ({
  displayText,
  style,
  navigationRoute,
  navigationParameter,
  className,
  width = 200,
}) => {
  const { navigateTo } = useNavigationToRoute();
  const handleTagClick = () => {
    if (navigationRoute) {
      navigateTo(navigationRoute, navigationParameter);
    }
  };
  return (
    <Tag
      onClick={handleTagClick}
      style={{ cursor: navigationRoute ? 'pointer' : undefined, ...style }}
      className={className}
    >
      <AbbreviateToWidth width={width} text={displayText} />
    </Tag>
  );
};
