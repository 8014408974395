import { Store } from 'antd/lib/form/interface';
import { useState } from 'react';
import { getLabDeviceUpsert, LabTaxonomyData } from '../util/labUtil';
import { useUpdateInvoice } from './ajax/invoice/invoiceHooks';
import { getThirdPartyUpsert, ThirdPartyTaxonomyData } from '../util/thirdPartyUtil';
import { showErrorMessage, showSuccessMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';
import { Invoice } from '../graph/types';
import { useUpsertLabDevice } from './ajax/lab/labHooks';

export const useGetDeviceTaxonomyUpsert = (organizationId: string, invoiceId?: string) => {
  const [isSaving, setIsSaving] = useState(false);
  const [updateInvoice] = useUpdateInvoice();
  const [upsertLabDevice] = useUpsertLabDevice(organizationId);

  const updateTaxonomy = async (
    values: Store,
    taxonomyByDevice: Record<string, LabTaxonomyData[]>,
    taxonomyByThirdParty: Record<string, ThirdPartyTaxonomyData[]>,
    onSuccess: (r?: Invoice) => void
  ) => {
    setIsSaving(true);
    const labDeviceUpserts = getLabDeviceUpsert(values, Object.keys(taxonomyByDevice));
    const thirdPartyUpserts = getThirdPartyUpsert(values, Object.keys(taxonomyByThirdParty));

    try {
      if (invoiceId) {
        const response = await updateInvoice({
          variables: {
            organizationId,
            invoice: {
              id: invoiceId,
              ...(labDeviceUpserts.length > 0 && {
                labTaxonomyMapping: {
                  organization_id: organizationId,
                  lab_device: labDeviceUpserts,
                },
              }),
              ...(thirdPartyUpserts.length > 0 && {
                thirdPartyTaxonomyMapping: {
                  organization_id: organizationId,
                  third_party_gender_map: thirdPartyUpserts,
                },
              }),
            },
          },
        });
        if (response.data?.upsertInvoice) {
          onSuccess(response.data.upsertInvoice);
        }
      } else {
        const promises = [];
        for (const labDevice of labDeviceUpserts) {
          promises.push(
            upsertLabDevice({
              variables: {
                organizationId,
                labDevice,
              },
            })
          );
        }
        await Promise.all(promises);
      }
      showSuccessMessage(translations.shared.saveSuccessMessage);
      onSuccess();
    } catch (e) {
      showErrorMessage(e.message);
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  return { updateTaxonomy, isSaving };
};
