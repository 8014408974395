import dayjs from 'dayjs';

export const isBeforeToday = (date?: dayjs.Dayjs) => {
  if (!date) {
    return false;
  }

  const theDate = date.format('MM/DD/YYYY');
  const today = dayjs().format('MM/DD/YYYY');

  return dayjs(theDate).isBefore(dayjs(today));
};
