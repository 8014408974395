import { useMutation } from '@apollo/client/react';
import { PracticeSettingsId } from '../../../constants/translations';
import { UpsertSetting } from '../../../graph/queries/system';
import { Mutation, Setting, SettingUpsert } from '../../../graph/types';

export const getSettingUpsert = (
  updatedValue: any,
  settingId: PracticeSettingsId,
  id?: string,
  practiceId?: string,
  userId?: string
): SettingUpsert => {
  return {
    id,
    record: {
      setting_id: settingId,
      value: updatedValue,
      practice_id: practiceId,
      user_id: userId,
    },
  };
};

export const getSettingUpsertVoid = (setting: Setting): SettingUpsert => {
  return {
    id: setting.id,
    void: true,
  };
};

export const useUpsertSetting = (onUpdateSetting?: (newSetting?: Setting) => void) => {
  return useMutation<Mutation>(UpsertSetting, {
    update: (_cache, result, _context) => result?.data?.upsertSetting && onUpdateSetting?.(result.data.upsertSetting),
  });
};
