import React, { useState } from 'react';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../constants/translations';
import { TextEditor } from '../TextEditor/TextEditor';
import { Maybe } from 'graphql/jsutils/Maybe';

type EditFillInstructionsModalProps = {
  onSave?: (instructionsText: string) => void;
  onClose?: () => void;
  invoiceText?: string;
  isSaving?: boolean;
  fillInstructions?: Maybe<string>;
};

export const EditFillInstructionsModal = ({
  invoiceText,
  onClose,
  onSave,
  isSaving,
  fillInstructions,
}: EditFillInstructionsModalProps) => {
  const [instructions, setInstructions] = useState(fillInstructions);
  const setFillInstructions = (textContent: string, _: string) => {
    setInstructions(textContent);
  };
  return (
    <ModalWithCloseConfirm
      title={`${translations.editFillInstructionsModal.title} - ${invoiceText}`}
      onOk={() => onSave?.(instructions || '')}
      onCancel={onClose}
      open
      isSaving={isSaving}
      warningMessageOnRouteChange={translations.shared.getUnsavedDataNavigationWarning(
        translations.editFillInstructionsModal.title
      )}
      width={800}
      destroyOnClose
    >
      <TextEditor textContent={instructions || ''} setTextContent={setFillInstructions} editMode />
    </ModalWithCloseConfirm>
  );
};
