import React from 'react';
import { CardConnectTransactionRecordUpsert, CreditCard, StripeTransactionRecordUpsert } from '../../../../graph/types';
import { PaymentModalFormFields } from '../PaymentForm/PaymentForm';
import { translations } from '../../../../constants/translations';
import { ModalContentAndFooter } from '../ModalContentAndFooter';
import { showErrorMessage } from '../../../Notification/notificationUtil';
import { PaymentSummary } from '../PaymentSummary/PaymentSummary';
import { CreditCardProcessFooter } from './CreditCardProcessFooter';
import { getCardTransactionRecordUpsert } from '../creditCardMappingUtil';
import { useCountryBasedCurrencyCode } from '../../../../hooks/currencyAndDateHooks';
import { ElectronicPaymentType } from '../../../../constants/referenceData/paymentProcessorTypeReferenceData';

interface CreditCardProcessProps {
  creditCard: CreditCard;
  formValues: PaymentModalFormFields;
  setIsSaving: (isLoading: boolean) => void;
  onCancel: () => void;
  processTransaction: (
    formValues: PaymentModalFormFields,
    transactionRecordUpsert: CardConnectTransactionRecordUpsert | StripeTransactionRecordUpsert
  ) => Promise<void>;
}

const getExpiry = (creditCard: CreditCard): string => {
  return padStringForTwoDigits(creditCard.expiry_month) + padStringForTwoDigits(creditCard.expiry_year);
};

function padStringForTwoDigits(value: string): string {
  return value.padStart(2, '0');
}

export const CreditCardProcess: React.FC<CreditCardProcessProps> = ({
  creditCard,
  formValues,
  setIsSaving,
  onCancel,
  processTransaction,
}) => {
  const currencyCode = useCountryBasedCurrencyCode();
  const processCreditCard = async () => {
    setIsSaving(true);
    const creditCardToken = creditCard.card_token;

    if (creditCardToken) {
      const creditCardExpiry = getExpiry(creditCard);
      const transactionUpsert = getCardTransactionRecordUpsert(
        formValues,
        'TOKEN',
        creditCardToken,
        creditCardExpiry,
        creditCard.processor_type_id === ElectronicPaymentType.Stripe,
        currencyCode,
        true
      );

      await processTransaction(formValues, transactionUpsert);
    } else {
      showErrorMessage(translations.paymentModal.invalidTransaction);
    }
    setIsSaving(false);
  };

  return (
    <ModalContentAndFooter footer={<CreditCardProcessFooter onProcess={processCreditCard} onCancel={onCancel} />}>
      <PaymentSummary
        formValues={formValues}
        additionalFields={[{ label: translations.paymentModal.fields.card, value: creditCard.display_number }]}
      />
    </ModalContentAndFooter>
  );
};
