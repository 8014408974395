import { UpsertSmtpEmail, UpsertSmtpSettings } from '../../../graph/queries/system';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { SmtpSettings } from '../../../graph/types';

export const useSendEmail = () => {
  return useOfflineErrorSkipMutation<'sendEmail'>(UpsertSmtpEmail);
};

const defaultSmtpSettings: SmtpSettings = {
  accountName: '',
  displayName: '',
  serverName: '',
  fromAddress: '',
  portNumber: '587',
  replyToAddress: '',
  requiresSSL: true,
  useSES: null,
};

export const useGetSmtpSettings = () => {
  const {
    state: { organization },
  } = useOrganizationContext();

  return organization?.smtp ?? defaultSmtpSettings;
};

export const useUpsertSmtpSettings = () => {
  return useOfflineErrorSkipMutation<'upsertOrganization'>(UpsertSmtpSettings);
};
