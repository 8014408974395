import { Service, ServiceRendered } from '../../../graph/types';
import { DeceasePatientsInfo } from './reducer';

export type ServicesRenderedAction =
  | SetServicesRendered
  | SetUnchargedServicesRendered
  | ChangeServiceRendered
  | SetClickedColumn
  | SetEditingServiceRenderedId
  | SetIsSaving
  | ClearIsSaving
  | SetContactId
  | AddPatientDeceaseInfo
  | SetCashedServices
  | SetConditionTreated
  | SetEditingServiceRendered;

export enum ServicesRenderedActionType {
  SetServicesRendered = 'SET_SERVICES_RENDERED',
  SetUnchargedServicesRendered = 'SET_UNCHARGED_SERVICES_RENDERED',
  ChangeServiceRendered = 'CHANGE_SERVICES_RENDERED',
  SetClickedColumn = 'SET_CLICKED_COLUMN',
  SetEditingServiceRenderedId = 'SET_EDITING_SERVICE_RENDERED_ID',
  SetIsSaving = 'SET_IS_SAVING',
  ClearIsSaving = 'CLEAR_IS_SAVING',
  SetContactId = 'SET_CONTACT_ID',
  AddPatientDeceaseInfo = 'ADD_PATIENT_DECEASE_INFO',
  SetCashedServices = 'SET_CACHED_SERVICES',
  SetConditionTreated = 'SET_CONDITION_TREATED',
  SetEditingServiceRendered = 'SET_EDITING_SERVICE_RENDERED',
}

interface SetServicesRendered {
  type: ServicesRenderedActionType.SetServicesRendered;
  servicesRendered?: ServiceRendered[];
}

interface SetUnchargedServicesRendered {
  type: ServicesRenderedActionType.SetUnchargedServicesRendered;
  servicesRendered?: ServiceRendered[];
}

export interface ChangeServiceRendered {
  type: ServicesRenderedActionType.ChangeServiceRendered;
  serviceId: string;
  updatedValue: any;
  fieldName: keyof ServiceRendered;
}

interface SetClickedColumn {
  type: ServicesRenderedActionType.SetClickedColumn;
  clickedColumn: keyof ServiceRendered | undefined;
}

interface SetEditingServiceRenderedId {
  type: ServicesRenderedActionType.SetEditingServiceRenderedId;
  editingServiceRenderedId: string | undefined;
}

interface SetIsSaving {
  type: ServicesRenderedActionType.SetIsSaving;
  savingMessage?: string;
}

interface ClearIsSaving {
  type: ServicesRenderedActionType.ClearIsSaving;
}

interface SetContactId {
  type: ServicesRenderedActionType.SetContactId;
  contactId: string | undefined;
}

interface AddPatientDeceaseInfo {
  type: ServicesRenderedActionType.AddPatientDeceaseInfo;
  patientDeceaseInfo: DeceasePatientsInfo;
}

interface SetCashedServices {
  type: ServicesRenderedActionType.SetCashedServices;
  services: Service[];
}

interface SetConditionTreated {
  type: ServicesRenderedActionType.SetConditionTreated;
  conditionTreated: string;
}

interface SetEditingServiceRendered {
  type: ServicesRenderedActionType.SetEditingServiceRendered;
  editingServiceRendered: ServiceRendered | undefined;
}

export const setServicesRenderedAction = (servicesRendered: ServiceRendered[]): SetServicesRendered => ({
  type: ServicesRenderedActionType.SetServicesRendered,
  servicesRendered,
});

export const setUnchargedServicesRenderedAction = (
  servicesRendered: ServiceRendered[]
): SetUnchargedServicesRendered => ({
  type: ServicesRenderedActionType.SetUnchargedServicesRendered,
  servicesRendered,
});

export const changeServicesRenderedAction = (
  serviceId: string,
  updatedValue: any,
  fieldName: keyof ServiceRendered
): ChangeServiceRendered => ({
  type: ServicesRenderedActionType.ChangeServiceRendered,
  serviceId,
  updatedValue,
  fieldName,
});

export const setClickedColumnAction = (clickedColumn: keyof ServiceRendered | undefined): SetClickedColumn => ({
  type: ServicesRenderedActionType.SetClickedColumn,
  clickedColumn,
});

export const setEditingServiceRenderedIdAction = (
  editingServiceRenderedId: string | undefined
): SetEditingServiceRenderedId => ({
  type: ServicesRenderedActionType.SetEditingServiceRenderedId,
  editingServiceRenderedId,
});

export const setIsSavingAction = (savingMessage?: string): SetIsSaving => ({
  type: ServicesRenderedActionType.SetIsSaving,
  savingMessage,
});

export const clearIsSavingAction = (): ClearIsSaving => ({
  type: ServicesRenderedActionType.ClearIsSaving,
});

export const setContactId = (contactId: string | undefined): SetContactId => ({
  type: ServicesRenderedActionType.SetContactId,
  contactId,
});

export const addPatientDeceaseInfo = (patientDeceaseInfo: DeceasePatientsInfo): AddPatientDeceaseInfo => ({
  type: ServicesRenderedActionType.AddPatientDeceaseInfo,
  patientDeceaseInfo,
});

export const setCashedServices = (services: Service[]): SetCashedServices => ({
  type: ServicesRenderedActionType.SetCashedServices,
  services,
});

export const setConditionTreated = (conditionTreated: string): SetConditionTreated => ({
  type: ServicesRenderedActionType.SetConditionTreated,
  conditionTreated,
});

export const setEditingServiceRendered = (editingServiceRendered?: ServiceRendered): SetEditingServiceRendered => ({
  type: ServicesRenderedActionType.SetEditingServiceRendered,
  editingServiceRendered,
});
