import { RxJsonSchema } from 'rxdb';
import { Service } from '../../../graph/types';
import { Maybe, RxScalars } from '../databaseUtils';

export type RxService = Service;

export const serviceSchema: RxJsonSchema<RxService> = {
  version: 12,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    name: RxScalars.String,
    practice_id: Maybe(RxScalars.ID),
    unit_name: Maybe(RxScalars.String),
    price: RxScalars.String,
    controlled: RxScalars.Boolean,
    vaccine: RxScalars.Boolean,
    inactive: RxScalars.Boolean,
    microchip: RxScalars.Boolean,
    quantity_prompt: RxScalars.Boolean,
    quantity_default: Maybe(RxScalars.String),
    tax_code_names: Maybe(RxScalars.Array),
    tax: Maybe(RxScalars.Array),
    info: Maybe(RxScalars.Array),
    text: Maybe(RxScalars.Array),
    tag: Maybe(RxScalars.Array),
    tag_names: Maybe(RxScalars.Array),
    bundle: Maybe(RxScalars.Array),
    bundled_service_ids: Maybe(RxScalars.Array),
    reminder: Maybe(RxScalars.Array),
    reminder_ids: Maybe(RxScalars.Array),
    general_ledger_id: Maybe(RxScalars.ID),
    general_ledger_name: Maybe(RxScalars.String),
    general_ledger_number: Maybe(RxScalars.String),
    default_note_id: Maybe(RxScalars.ID),
    updated: RxScalars.String,
    bundle_prompt: RxScalars.Boolean,
    hidden: RxScalars.Boolean,
    roa_prompt: RxScalars.Boolean,
    prescription_prompt: RxScalars.Boolean,
    din: Maybe(RxScalars.String),
    rabies_tag_prompt: RxScalars.Boolean,
    lab_mapping: Maybe(RxScalars.Array),
    patient_validation: Maybe(RxScalars.Array),
    reason_prompt: Maybe(RxScalars.Boolean),
    third_party: Maybe(RxScalars.Array),
    withdrawal_prompt: Maybe(RxScalars.Boolean),
    decease_prompt: Maybe(RxScalars.Boolean),
    material_cost: Maybe(RxScalars.String),
    markup_percentage: Maybe(RxScalars.String),
  },
  required: ['id'],
  indexes: ['updated'],
};
