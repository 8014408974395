import React, { CSSProperties, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { MenuInfo } from 'rc-menu/es/interface';
import { mainPageSiderRoutes, menuKeyPermissionMap, settingRoutingPaths } from '../../constants/routes';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getUserPermissions } from '../../util/userPermissionUtil';
import { useUserPermissions } from '../../hooks/permissionsHooks';
import { useOffline } from '../../util/offline/offlineUtil';
import { useLDFlag } from '../../hooks/useLDHooks';
import { useGetLabState } from '../../hooks/ajax/organization/organizationHooks';
import { translations } from '../../constants/translations';
import { AntechImmersive } from '../../components/AntechImmersive/AntechImmersive';
import { useGetAntechUrl } from '../../hooks/ajax/lab/labHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useUserContext } from '../../contexts/user/state';
const { Sider } = Layout;

export interface route {
  order: number | null;
  path?: string;
  menu?: { [key: string]: route };
  label: string;
  icon: any;
  adminOnly?: boolean;
  disabledOffline?: boolean;
  ldFlags?: string[];
  id?: string;
  isAntech?: boolean;
}

interface Dictionary<T> {
  [Key: string]: T;
}

interface PageSiderProps extends PropsWithChildren<unknown> {
  routeKey: string;
}

export const PageSider: React.FC<PageSiderProps> = ({ routeKey }: PageSiderProps) => {
  const location = useLocation();
  const flagsLD = useLDFlag();
  const { navigateTo } = useNavigationToRoute();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const {
    state: { user },
  } = useUserContext();

  const { labState, labStateLoading } = useGetLabState(organizationId);
  const superUser = useUserPermissions();
  const userPermissions = getUserPermissions(organizationId, user, superUser);
  const { antechUrl } = useGetAntechUrl(organizationId || '');
  const isAntechFFEnabled = useLDFlag(LDFlagNames.AddonsAntechLab);
  const antechLabAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.AntechLabLevel].level_id
      ),
    [organization]
  );

  const antechAddonEnabled = useMemo(() => (antechLabAddon?.enabled ? true : false), [antechLabAddon]);
  let routingPaths: Dictionary<route>;
  routingPaths = mainPageSiderRoutes;
  if (routeKey) {
    switch (routeKey) {
      case 'practice':
        routingPaths = settingRoutingPaths;
        break;
      case 'main':
      default:
        routingPaths = mainPageSiderRoutes;
        break;
    }
  }

  const onMenuClick = ({ keyPath }: MenuInfo) => {
    const menuPath = keyPath.reverse().join('.menu.');
    const clickedMenu = get(routingPaths, menuPath);
    if (clickedMenu?.path) {
      navigateTo(clickedMenu.path);
    }
  };

  const [selectedKey, setSelectedKey] = useState<string>(flagsLD.Page_Dashboard ? 'dashboard' : 'doctorOffice');
  const [reRenderKey, setReRenderKey] = useState<number>(0);

  const { isOnline } = useOffline();
  const showAntechInmersive = useMemo(
    () => isOnline && antechAddonEnabled && isAntechFFEnabled,
    [isOnline, antechAddonEnabled, isAntechFFEnabled]
  );
  const practice = organization?.practice.find((p) => p.id === organization?.default_practice_id);
  const isStripeProcessor = practice?.stripe?.accountId != null;

  useEffect(() => {
    setReRenderKey((prevState) => prevState + 1);
  }, [setReRenderKey]);

  const selectMenuItemAccordingToUrl = () => {
    const getMatchingPath = (): string => {
      if (
        location.pathname.includes('cardConnectSettings') ||
        location.pathname.includes('stripeConfirmation') ||
        location.pathname.includes('stripeRegistration')
      ) {
        return 'addOns';
      }
      for (const routingPathsKey in routingPaths) {
        if (routingPaths[routingPathsKey]?.path?.includes('referenceData')) {
          if (matchPath(location.pathname, { path: routingPaths[routingPathsKey].path })) {
            return routingPathsKey;
          }
        } else if (matchPath(location.pathname, { path: routingPaths[routingPathsKey].path, exact: true })) {
          return routingPathsKey;
        } else if (routingPaths[routingPathsKey].menu) {
          for (const routingPathsKeyKey in routingPaths[routingPathsKey].menu) {
            if (
              matchPath(location.pathname, {
                path: routingPaths[routingPathsKey].menu?.[routingPathsKeyKey].path,
              })
            ) {
              return routingPathsKeyKey;
            }
          }
        }
      }

      return '';
    };

    const matchingPath = getMatchingPath();
    setSelectedKey(matchingPath);
  };

  const buildLabel = (valueLabel: string) => {
    if (translations.mainPage.reportPayments === valueLabel && !isStripeProcessor) {
      return <Tooltip title={translations.referenceData.stripePaymentsNotEnabledWarning}>{valueLabel}</Tooltip>;
    }
    if (translations.mainPage.labManagement === valueLabel && labState?.has_unassigned) {
      return (
        <>
          {valueLabel} <ExclamationCircleOutlined style={{ color: 'yellow', fontSize: '12px' }} />
        </>
      );
    }
    return valueLabel;
  };
  const bindMargaEvents = (window as { [key: string]: any }).bindMargaEvents as () => void;
  const showDockableComponent = (window as { [key: string]: any }).showDockableComponent as () => void;
  const renderedMenuItems = (mRoutingPaths = routingPaths, isFirstMainItem = true, disabled = false) => {
    const filteredRoutes = Object.entries(mRoutingPaths).filter(([, value]) => {
      if (value.ldFlags) {
        return value.ldFlags.some((flag) => flagsLD[flag]);
      }
      return true;
    });

    return sortBy(filteredRoutes, ([_, v]) => v.order).map(([key, value], index) => {
      if (
        (value.adminOnly && !user?.global_admin) ||
        (menuKeyPermissionMap[key] && !userPermissions[menuKeyPermissionMap[key]].allowed)
      ) {
        return null;
      }

      const style: CSSProperties | undefined = isFirstMainItem && index === 0 ? { marginTop: 0 } : undefined;
      const isDisabled =
        disabled ||
        (value.isAntech && !antechUrl) ||
        (!isOnline && value.disabledOffline) ||
        (value.path?.includes('payments') && !isStripeProcessor);
      const isVisible = value.isAntech ? antechAddonEnabled && isAntechFFEnabled : true;

      return !value.menu ? (
        isVisible && (
          <Menu.Item
            id={value.id}
            disabled={isDisabled}
            key={key}
            icon={value.icon && <Icon component={value.icon} style={{ width: '24px' }} />}
            onClick={() => {
              if (value.id === 'dockable-button') {
                bindMargaEvents();
                showDockableComponent();
              }
            }}
            style={style}
          >
            {buildLabel(value.label)}
          </Menu.Item>
        )
      ) : (
        <Menu.SubMenu
          disabled={isDisabled}
          key={key}
          title={buildLabel(value.label)}
          icon={value.icon && <Icon component={value.icon} style={{ width: '24px' }} />}
          style={style}
        >
          {renderedMenuItems(value.menu, false, isDisabled)}
        </Menu.SubMenu>
      );
    });
  };

  useEffect(
    selectMenuItemAccordingToUrl,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  if (labStateLoading) {
    return null;
  }

  return (
    <Sider collapsible width='220px'>
      <Menu
        key={reRenderKey}
        theme='dark'
        defaultSelectedKeys={['1']}
        mode='inline'
        onClick={onMenuClick}
        selectedKeys={[selectedKey]}
      >
        {renderedMenuItems()}
      </Menu>
      {showAntechInmersive && <AntechImmersive isDockable url={antechUrl || ''} />}
    </Sider>
  );
};
