import gql from 'graphql-tag';
import { GlobalSearchFields } from '../fragments/searchFragments';

export const GlobalSearch = gql`
  query globalSearch($organizationId: ID!, $take: Int!, $queryString: ElasticQueryString) {
    globalSearch(organizationId: $organizationId, take: $take, queryString: $queryString) {
      ...GlobalSearchFields
    }
  }
  ${GlobalSearchFields}
`;
