import React, { useContext } from 'react';
import { translations } from '../../../constants/translations';
import {
  selectSelectedPatient,
  selectShowAddInvoiceModal,
  selectShowSelectPatientModal,
} from '../InvoicesOverview/store/selectors';
import SelectPatientModal from '../../../components/SelectPatientModal/SelectPatientModal';
import { InvoicesContext } from '../InvoicesOverview/store/state';
import {
  closeModalsAction,
  selectPatientAction,
  showAddInvoiceModalAction,
  showSelectPatientModalAction,
} from '../InvoicesOverview/store/actions';
import { Patient } from '../../../graph/types';
import { AddInvoiceModal } from './AddInvoiceModal';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { useGetPatient } from '../../../hooks/ajax/patients/patientHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { Loading } from '../../../components/Loading/Loading';
import { useOrganizationContext } from '../../../contexts/organization/state';

interface AddInvoiceModalContainerProps {
  contactId?: string;
}
export const AddInvoiceModalContainer: React.FC<AddInvoiceModalContainerProps> = ({ contactId }) => {
  const { state, dispatch } = useContext(InvoicesContext);
  const selectPatientVisible = selectShowSelectPatientModal(state);
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();

  const selectedPatient = selectSelectedPatient(state);
  const { patient, patientLoading } = useGetPatient(selectedPatient?.id ?? '', organizationId);

  const selectPatientCancel = () => {
    if (selectedPatient) {
      dispatch(showAddInvoiceModalAction());
    } else {
      dispatch(closeModalsAction());
    }
  };

  const selectPatient = (patients: Patient[]) => {
    const patient = patients[0];
    if (patient.owner_names && patient.owner_names.length > 0) {
      dispatch(selectPatientAction(patient));
    } else {
      showErrorMessage(translations.invoicesPage.addInvoiceModal.noOwnershipError);
    }
  };

  if (patientLoading) {
    return <Loading />;
  }

  return (
    <>
      {selectPatientVisible && (
        <SelectPatientModal
          title={translations.shared.selectPatientModal.title}
          onCancel={selectPatientCancel}
          finishSelectPatients={selectPatient}
          contactId={contactId}
        />
      )}
      {selectedPatient && patient && organization && (
        <AddInvoiceModal
          visible={selectShowAddInvoiceModal(state)}
          closeModal={() => dispatch(closeModalsAction())}
          selectedPatient={patient}
          changePatient={() => dispatch(showSelectPatientModalAction())}
          organization={organization}
        />
      )}
    </>
  );
};
