import gql from 'graphql-tag';

import { LabResultsFields } from '../fragments/labLinkFragments';

export const GetLabResults = gql`
  query getLabResults($organizationId: ID!) {
    getLabResults(organizationId: $organizationId) {
      ...LabResultsFields
    }
  }
  ${LabResultsFields}
`;
