import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { DepositRunProperty, depositRunPropertyNames } from '../../constants/propertyNames';
import { DepositRunStatusId, depositRunStatusIdTranslation, translations } from '../../constants/translations';
import { DepositRun } from '../../graph/types';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';

export const basicDepositRunColumns: Record<DepositRunProperty, CustomColumnType<DepositRun>> = {
  number: {
    title: translations.depositRuns.columns.number,
    dataIndex: depositRunPropertyNames.number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.depositRuns.columns.number),
    onFilter: getOnFilterFunctionFor(depositRunPropertyNames.number),
    sorter: getStringCompareFunctionFor(depositRunPropertyNames.number),
  },
  end_date: {
    title: translations.depositRuns.columns.endDate,
    dataIndex: depositRunPropertyNames.end_date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.depositRuns.columns.endDate),
    onFilter: getOnFilterFunctionFor(depositRunPropertyNames.end_date),
    sorter: getStringCompareFunctionFor(depositRunPropertyNames.end_date),
  },
  paymentType: {
    title: translations.depositRuns.columns.paymentTypes,
    dataIndex: depositRunPropertyNames.paymentType,
  },
  status_id: {
    title: translations.depositRuns.columns.status,
    dataIndex: depositRunPropertyNames.status_id,
    ...generalFilteringAndSortingSettings,
    filters: [
      {
        text: depositRunStatusIdTranslation(DepositRunStatusId.Pending),
        value: DepositRunStatusId.Pending,
      },
      {
        text: depositRunStatusIdTranslation(DepositRunStatusId.Closed),
        value: DepositRunStatusId.Closed,
      },
      {
        text: depositRunStatusIdTranslation(DepositRunStatusId.Sent),
        value: DepositRunStatusId.Sent,
      },
    ],
    onFilter: (value, record) => record.status_id === value,
    sorter: getStringCompareFunctionFor(depositRunPropertyNames.status_id),
  },
};
