import { DocumentNode } from 'apollo-link';
import { print } from 'graphql';
import { Maybe, Scalars } from '../../../graph/types';
import { CollectionType } from '../collections';
import { CollectionWithUpsert, RxSchemaTypeWithUpsert } from '../databaseUtils';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../LocalDatabaseProvider';
import { RxDbEvent } from '../../../event/RxDb/RxDbEvent';
import dayjs from 'dayjs';
import { isUuid } from '../../../util/offline/offlineUtil';

export const BATCH_SIZE = 500;
export const queryBuilder =
  <T extends { updated?: Maybe<Scalars['String']>; id?: Maybe<Scalars['String']> } & Record<string, any>>(
    organizationId: string,
    query: DocumentNode,
    {
      batchSize = BATCH_SIZE,
      withoutHidden,
      sortByUpdated,
    }: {
      batchSize?: number;
      withoutHidden?: boolean;
      sortByUpdated?: boolean;
    } = {}
  ) =>
  (previousRecord?: T) => ({
    query: print(query),
    variables: {
      organizationId,
      filter: {
        updatedAfter: previousRecord?.updated,
        lastId: previousRecord?.id,
        batchSize,
        returnHidden: withoutHidden ? undefined : true,
        sortByUpdated,
      },
    },
  });

export const elasticQueryBuilder =
  <T extends { updated?: Maybe<Scalars['String']>; id?: Maybe<Scalars['String']> } & Record<string, any>>(
    organizationId: string,
    query: DocumentNode,
    batchSize = BATCH_SIZE
  ) =>
  (previousRecord?: T) => ({
    query: print(query),
    variables: {
      organizationId,
      take: batchSize,
      sort: { field: 'updated', direction: 'asc' },
      searchAfter: [getDateTimestamp(previousRecord?.updated), +(previousRecord?.id ?? '0')],
      returnHidden: true,
    },
  });

const getDateTimestamp = (date?: string | null) => {
  if (!date) {
    return 0;
  }
  return new Date(dayjs.utc(date).toISOString()).getTime();
};

type RecordType = { id: Scalars['ID']; offline_id?: Maybe<Scalars['String']> } & Record<string, any>;
const recordExists = async (record: RecordType, collection: CollectionType) =>
  (await db?.[collection].find({ selector: { id: record.id } }).exec()).length > 0;

export const replaceExistingRecords = async <T extends RecordType>(record: T, collection: CollectionType) => {
  if (!record.offline_id) {
    return;
  }

  if (await recordExists(record, collection)) {
    return;
  }

  try {
    await db[collection].find({ selector: { offline_id: record.offline_id, is_new: true } }).remove();
  } catch (_) {}

  RxDbEvent.insert.emit({ collection, recordId: record.id, offlineId: record.offline_id });
};

export const replaceExistingLinkedRecords = async <T extends RecordType>(
  record: T,
  collection: CollectionType,
  linkedKey: string,
  linkedValueToReplace?: string
) => {
  if (!record.offline_id) {
    return;
  }

  if (await recordExists(record, collection)) {
    return;
  }

  try {
    await db[collection]
      .find({
        selector: {
          [linkedKey]: linkedValueToReplace ? record[linkedValueToReplace] : record.offline_id,
          is_new: true,
        },
      })
      .remove();
  } catch (_) {}

  RxDbEvent.insert.emit({ collection, recordId: record.id, offlineId: record.offline_id });
};

export const cleanUpOfflineRecords = async (byKey: string, collection: CollectionType) => {
  try {
    const records = await db[collection].find().exec();
    for (const record of records) {
      if (isUuid(record[byKey])) {
        await record.remove();
      }
    }
  } catch (_) {}
};

export const getStorageObjectStore = (storageDb: IDBDatabase) => {
  return storageDb.transaction(['storage_upsert'], 'readwrite').objectStore('storage_upsert');
};

export const removeFromStorage = <T extends RecordType>(
  record: T,
  collection: CollectionType,
  localDb?: IDBDatabase
) => {
  try {
    if (!localDb) {
      return;
    }
    const objectStore = getStorageObjectStore(localDb);
    const request = objectStore.get(collection + record.id);
    request.onsuccess = (event: any) => {
      if (event.target.result) {
        objectStore.delete(collection + record.id);
      } else {
        objectStore.delete(collection + record.offline_id);
      }
    };
  } catch (_) {}
};

export type OfflineUpsertProps<T extends RxSchemaTypeWithUpsert<any, any, CollectionWithUpsert>> = Partial<
  Omit<T, '__typename'> & Record<string, any>
>;

export const getOfflineId = () => uuidv4();

export const getUpdatedDate = () => {
  const date = new Date();
  let result = '';
  result += date.getUTCFullYear() + '-';
  result += `${date.getUTCMonth() + 1}`.padStart(2, '0') + '-';
  result += `${date.getUTCDate()}`.padStart(2, '0') + ' ';
  result += `${date.getUTCHours()}`.padStart(2, '0') + ':';
  result += `${date.getUTCMinutes()}`.padStart(2, '0') + ':';
  result += `${date.getUTCSeconds()}`.padStart(2, '0');
  return result;
};

export const getClearedRecord = ({
  patient_id,
  contact_id,
  offline_patient_id,
  offline_contact_id,
}: {
  patient_id?: Maybe<string>;
  offline_patient_id?: Maybe<string>;
  contact_id?: Maybe<string>;
  offline_contact_id?: Maybe<string>;
}) => ({
  offline_patient_id: offline_patient_id ?? (patient_id && isNaN(+patient_id) ? patient_id : null),
  offline_contact_id: offline_contact_id ?? (contact_id && isNaN(+contact_id) ? contact_id : null),
  patient_id: !patient_id || isNaN(+patient_id) ? null : patient_id,
  contact_id: !contact_id || isNaN(+contact_id) ? null : contact_id,
});
