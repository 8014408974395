import React, { useMemo, useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { translations } from '../../../constants/translations';
import { Form, Input } from 'antd';
import { useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { useForm } from 'antd/lib/form/Form';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import {
  DemographicReferenceData,
  Maybe,
  OrganizationDto,
  OrganizationSubscriptionDto,
  PracticeDto,
  SystemReferenceDataTypes,
} from '../../../graph/types';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { getRequiredRule, getValidEmailRule } from '../../../util/forms';
import { CardConnectTokenizer, reformatEmvExpiry } from '../../../components/CardConnectTokenizer/CardConnectTokenizer';
import { TransactionType } from '../../../components/PaymentModal/PaymentModal/creditCardMappingUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import Visa from 'payment-icons/min/flat/visa.svg';
import Discover from 'payment-icons/min/flat/discover.svg';
import Amex from 'payment-icons/min/flat/amex.svg';
import Mastercard from 'payment-icons/min/flat/mastercard.svg';
import { paymentTypeConfigs, PaymentTypeNameKey } from '../../../constants/referenceData/paymentReferenceData';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { AddressBlock, AddressFormFields } from '../../../components/AddressBlock/AddressBlock';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { BillingDetailsContent, NoDataSpan, StyledImage } from '../SubscriptionSettings.styles';
import { FlexContainer } from '../../../globalStyles.style';
import { countryProvStateTranslations, countryTranslations } from '../../../constants/countryTranslations';
import { ElectronicPaymentType } from '../../../constants/referenceData/paymentProcessorTypeReferenceData';
import StripeTokenizer from '../../../components/StripeTokenizer/StripeTokenizer';
import { Store } from 'antd/lib/form/interface';
import { useGetStripeTokenUtilsDto } from '../../../hooks/stripeHooks';

export const billingCardTranslations = translations.subscriptionSettings.cardDetails;

type CardFormValues = {
  nameOnCard: string;
  cardNumber: string;
  expiry: string;
  address: AddressFormFields[];
  phone: string;
  email: string;
};

interface SubscriptionBillingDetailsCardProps {
  practice?: PracticeDto;
  organization: OrganizationDto;
  referenceData?: Maybe<SystemReferenceDataTypes> | undefined;
}

const SubscriptionBillingDetailsCard: React.FC<SubscriptionBillingDetailsCardProps> = ({
  practice,
  organization,
  referenceData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = useMemo(() => {
    return {
      nameOnCard: organization?.subscription?.name_on_card,
      address: [organization.subscription?.billing_address],
      phone: organization.subscription?.billing_address?.phone,
      email: organization.subscription?.billing_address?.email,
    };
  }, [organization]);

  const {
    tokenizerExtraData,
    setTokenizerExtraData,
    organization: organizationWithDemographicData,
    creditCard,
    setCreditCard,
    practiceAddress,
  } = useGetStripeTokenUtilsDto(organization);

  const [form] = useForm();

  const [upsertSubscription] = useUpdateSubscription(organization?.id);

  const handleValueChange = (changedValues: Store) => {
    if (changedValues.address || changedValues.nameOnCard) {
      setTokenizerExtraData({
        payer: form?.getFieldValue('nameOnCard'),
        addressLine1: form?.getFieldValue('address')?.[0]?.address_1,
        addressLine2: organization?.subscription?.billing_address?.address_2 || '',
        city: form?.getFieldValue('address')?.[0]?.city,
        countryId: form?.getFieldValue('address')?.[0]?.country_id,
        provStateId: form?.getFieldValue('address')?.[0]?.country_prov_state_id,
        postalZip: form?.getFieldValue('address')?.[0]?.postal_zip,
      });
    }
  };

  const handleCardUpdate = async ({ nameOnCard, address, phone, email }: CardFormValues) => {
    const { address_1, city, country_id, country_prov_state_id, postal_zip } = address[0];

    const countryName = referenceData?.country?.find(({ id }) => country_id === id)?.name_key;
    const provName = referenceData?.prov_state?.find(({ id }) => country_prov_state_id === id)?.name_key;

    if (organization) {
      const variables = {
        organizationId: organization.id,
        subscription: {
          contact: {
            contact_id: ID_FOR_OBJECT_CREATION,
            name: nameOnCard,
            address_1,
            city,
            country_prov_state_id,
            country_id,
            postal_zip,
            country_name: countryTranslations[countryName! as keyof typeof countryTranslations],
            country_prov_state_name:
              countryProvStateTranslations[provName! as keyof typeof countryProvStateTranslations],
            phone,
            email,
          },
          ...(creditCard && {
            card: {
              type_id: TransactionType.AuthCapture.toString(),
              card_token: creditCard.token,
              expiry:
                organization?.subscription?.processor_type_id === ElectronicPaymentType.Stripe
                  ? creditCard.expiryDate
                  : reformatEmvExpiry(creditCard.expiryDate),
              processor_type_id: organization?.subscription?.processor_type_id ?? ElectronicPaymentType.CardConnect,
            },
          }),
          fnProcess: true,
        },
      };

      setIsSaving(true);
      try {
        await upsertSubscription({ variables });
      } catch (e) {
        showErrorMessage(e.message);
      }
      setIsSaving(false);
      setIsModalOpen(false);
    }
  };

  const getCardInfo = ({
    display_number,
    name_on_card,
    expiry_month,
    expiry_year,
    card_type_id,
  }: OrganizationSubscriptionDto) => {
    const paymentTypeArray = Object.values(paymentTypeConfigs);
    const ccIconType = paymentTypeArray.find(({ value }) => value === parseFloat(card_type_id ?? ''))?.text;

    let icon = '';

    switch (ccIconType) {
      case paymentTypeConfigs[PaymentTypeNameKey.Visa].text: {
        icon = Visa;
        break;
      }
      case paymentTypeConfigs[PaymentTypeNameKey.Mastercard].text: {
        icon = Mastercard;
        break;
      }
      case paymentTypeConfigs[PaymentTypeNameKey.Discover].text: {
        icon = Discover;
        break;
      }
      case paymentTypeConfigs[PaymentTypeNameKey.Amex].text: {
        icon = Amex;
        break;
      }
      default: {
        return <p>{translations.shared.errorLoadingPage}</p>;
      }
    }

    return (
      <>
        <StyledImage src={icon} alt={`${ccIconType} logo`} />
        <FlexContainer direction='column'>
          <span>{display_number}</span>
          <span>{name_on_card}</span>
          <span>{`${billingCardTranslations.expires}: ${expiry_month}/${expiry_year}`}</span>
        </FlexContainer>
      </>
    );
  };

  const isSubscriptionEditable = (organization: OrganizationDto, practice?: PracticeDto) =>
    organization?.subscription?.processor_type_id === ElectronicPaymentType.CardConnect
      ? Boolean(practice?.card_connect_manual)
      : Boolean(practice?.stripe);

  return (
    <SettingsCard
      title={translations.subscriptionSettings.cardDetails.title}
      leftButton={
        isSubscriptionEditable(organization, practice)
          ? {
              label: translations.subscriptionSettings.cardDetails.buttons.editCard,
              onClick: () => setIsModalOpen(true),
            }
          : undefined
      }
    >
      <BillingDetailsContent justifyContent={'space-around'}>
        {organization?.subscription ? (
          getCardInfo(organization.subscription)
        ) : (
          <NoDataSpan>{billingCardTranslations.noData}</NoDataSpan>
        )}
      </BillingDetailsContent>

      <ModalWithCloseConfirm
        open={isModalOpen}
        title={billingCardTranslations.modal.title}
        onCancel={() => setIsModalOpen(false)}
        onOk={form.submit}
      >
        <SaveSpinner isSaving={isSaving}>
          <Form
            form={form}
            onFinish={handleCardUpdate}
            initialValues={initialValues}
            autoComplete='off'
            onValuesChange={handleValueChange}
          >
            <MaxLengthFormItem
              name='nameOnCard'
              label={billingCardTranslations.modal.labels.nameOnCard}
              rules={[getRequiredRule(billingCardTranslations.modal.labels.nameOnCard)]}
              labelCol={{ span: 10 }}
              maxLength={50}
            >
              <Input placeholder={billingCardTranslations.modal.labels.nameOnCard} />
            </MaxLengthFormItem>
            <MaxLengthFormItem
              labelCol={{ span: 10 }}
              maxLength={50}
              name='email'
              label={billingCardTranslations.modal.labels.email}
              rules={[getRequiredRule(billingCardTranslations.modal.labels.email), getValidEmailRule()]}
            >
              <Input />
            </MaxLengthFormItem>
            <MaxLengthFormItem
              rules={[getRequiredRule(billingCardTranslations.modal.labels.phone)]}
              labelCol={{ span: 10 }}
              maxLength={50}
              name='phone'
              label={billingCardTranslations.modal.labels.phone}
            >
              <Input />
            </MaxLengthFormItem>
            <AddressBlock addressNumber={0} form={form} isBilling disableCountry />
          </Form>
          {organization?.subscription?.processor_type_id === ElectronicPaymentType.Stripe ? (
            <StripeTokenizer
              stripeTokenizerCountryId={practiceAddress?.country_id || undefined}
              setCreditCard={setCreditCard}
              tokenizerExtraData={tokenizerExtraData}
              addressDemographics={{ ...organizationWithDemographicData?.ref_demographics } as DemographicReferenceData}
            />
          ) : (
            <CardConnectTokenizer
              setCreditCard={setCreditCard}
              site={practice?.card_connect_manual?.manualCardSite ?? ''}
              port={practice?.card_connect_manual?.manualCardPort ?? ''}
            />
          )}
        </SaveSpinner>
      </ModalWithCloseConfirm>
    </SettingsCard>
  );
};

export default SubscriptionBillingDetailsCard;
