import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { PaymentDeviceProperty, paymentDevicePropertyNames } from '../../../constants/propertyNames';
import { translations } from '../../../constants/translations';
import { PaymentDeviceType } from '../../../graph/types';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';

export const paymentDeviceRenderedColumns: Record<PaymentDeviceProperty, CustomColumnType<PaymentDeviceType>> = {
  name: {
    title: translations.cardConnectSettings.columns.name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.cardConnectSettings.columns.name
    ),
    onFilter: getOnFilterFunctionFor(paymentDevicePropertyNames.name),
    sorter: getStringCompareFunctionFor(paymentDevicePropertyNames.name),
  },
  serial_number: {
    title: translations.cardConnectSettings.columns.serialNumber,
    dataIndex: paymentDevicePropertyNames.serial_number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.cardConnectSettings.columns.serialNumber
    ),
    onFilter: getOnFilterFunctionFor(paymentDevicePropertyNames.serial_number),
    sorter: getStringCompareFunctionFor(paymentDevicePropertyNames.serial_number),
  },
};
