import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import {
  GetDataConversion,
  GetDataConversionFileTypes,
  GetSoftwareTypes,
  RequestDataConversion,
} from '../../../graph/queries/dataConversion';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useRequestDataConversion = () => {
  return useOfflineErrorSkipMutation<'requestDataConversion'>(RequestDataConversion, {
    update(cache, { data }) {
      const dataConversion = data?.requestDataConversion;

      cache.modify({
        fields: {
          getDataConversion: () => dataConversion,
        },
      });
    },
    onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
  });
};

export const useGetSoftwareTypes = () => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getSoftwareTypes'>(GetSoftwareTypes);

  return {
    softwareTypes: data?.getSoftwareTypes,
    softwareTypesLoading: loading,
    softwareTypesError: error,
  };
};

export const useGetDataConversionFileTypes = () => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getDataConversionFileTypes'>(GetDataConversionFileTypes);

  return {
    fileTypes: data?.getDataConversionFileTypes,
    fileTypesLoading: loading,
    fileTypesError: error,
  };
};

export const useGetDataConversion = (organizationId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getDataConversion'>(GetDataConversion, {
    variables: { organizationId },
    fetchPolicy: 'cache-and-network',
  });

  return {
    dataConversion: data?.getDataConversion,
    dataConversionLoading: loading,
    dataConversionError: error,
  };
};
