import { GetOrganization } from '../../../graph/queries/organizations';
import { Query } from '../../../graph/types';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';

export const fetchAvailableSubscriptions = async (organizationId: string) => {
  const { data } = await AppSyncService.client.query<Pick<Query, 'getOrganization'>>({
    query: GetOrganization,
    variables: {
      organizationId,
    },
  });
  const detail = data?.getOrganization.subscription?.detail;
  if (!detail) {
    return 0;
  }
  return detail.reduce((acc, { qty_available }) => acc + qty_available, 0);
};
