import { Col, Form, Row, Switch } from 'antd';
import React, { useState } from 'react';
import { UserPermissionNameKey } from '../../../constants/referenceData/userPermissionReferenceData';
import { translations } from '../../../constants/translations';
import { FormWithFlexWrap } from '../../../globalStyles.style';
import { User, UserOrganizationPermissionUpsert } from '../../../graph/types';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useUpsertUserPermissions } from '../../../hooks/ajax/user/userHooks';
import { useUserPermissions } from '../../../hooks/permissionsHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import {
  getUserOrganizationPermissionUpsert,
  getUserPermissions,
  getUserUpsertWithPermission,
} from '../../../util/userPermissionUtil';

export const organizationAdministrator = 'organizationAdministrator';
export const settingsField = 'settings';
export const subscriptionField = 'subscription';
export const generalReportsField = 'generalReports';
export const dashboardField = 'dashboard';

export interface UserPermissionsFields {
  [organizationAdministrator]: boolean;
  [settingsField]: boolean;
  [subscriptionField]: boolean;
  [generalReportsField]: boolean;
}

const fieldTranslations = translations.userSettings.tabs.permissions.fields;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

type UserPermissionsProps = {
  user: User;
  setIsSaving: (isSaving: boolean) => void;
};

export const UserPermissions: React.FC<UserPermissionsProps> = ({ user, setIsSaving }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { isOrgOwner: isCurrentUserOrgOwner, isGlobalAdmin } = useUserPermissions();
  const { isOrgAdmin } = useUserPermissions(user);
  const [form] = Form.useForm();
  const [updateUser] = useMutationWithMessages(useUpsertUserPermissions);
  const {
    PERM_RPT_ALL: generalReportsPermission,
    PERM_SET_ALL: settingsPermission,
    PERM_SET_SUBSCRIPTION: subscriptionPermission,
    PERM_DSB_ALL: dashboardPermission,
  } = getUserPermissions(organizationId, user);
  const userOrganization = user.organization?.find(({ organization_id }) => organizationId === organization_id);

  const fullAccess = !!isOrgAdmin;

  const [isFullAccess, setIsFullAccess] = useState(fullAccess);

  const initialData = {
    [organizationAdministrator]: fullAccess,
    [settingsField]: settingsPermission.allowed,
    [subscriptionField]: subscriptionPermission.allowed,
    [generalReportsField]: generalReportsPermission.allowed,
    [dashboardField]: dashboardPermission.allowed,
  };

  const handleValueChange = async (value: boolean, fieldName: string) => {
    let permission: UserOrganizationPermissionUpsert[] = [];
    let isOrganizationAdministrator = !!isOrgAdmin;
    if (!userOrganization) {
      return;
    }

    setIsSaving(true);

    switch (fieldName) {
      case organizationAdministrator:
        setIsFullAccess(value);
        isOrganizationAdministrator = value;
        permission = [
          getUserOrganizationPermissionUpsert(false, UserPermissionNameKey.PERM_RPT_ALL),
          getUserOrganizationPermissionUpsert(false, UserPermissionNameKey.PERM_SET_ALL),
          getUserOrganizationPermissionUpsert(false, UserPermissionNameKey.PERM_SET_SUBSCRIPTION),
          getUserOrganizationPermissionUpsert(false, UserPermissionNameKey.PERM_DSB_ALL),
        ];
        break;
      case settingsField:
        permission = [getUserOrganizationPermissionUpsert(!value, UserPermissionNameKey.PERM_SET_ALL)];
        break;
      case subscriptionField:
        permission = [getUserOrganizationPermissionUpsert(!value, UserPermissionNameKey.PERM_SET_SUBSCRIPTION)];
        break;
      case generalReportsField:
        permission = [getUserOrganizationPermissionUpsert(!value, UserPermissionNameKey.PERM_RPT_ALL)];
        break;
      case dashboardField:
        permission = [getUserOrganizationPermissionUpsert(!value, UserPermissionNameKey.PERM_DSB_ALL)];
        break;
    }

    await updateUser(
      getUserUpsertWithPermission(organizationId, user, userOrganization, permission, isOrganizationAdministrator)
    );

    if (fieldName === organizationAdministrator && value) {
      form.setFieldsValue({ [settingsField]: value, [subscriptionField]: value, [generalReportsField]: value });
    }
    setIsSaving(false);
  };

  return (
    <FormWithFlexWrap {...layout} initialValues={initialData} form={form} autoComplete='off'>
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          {(isCurrentUserOrgOwner || isGlobalAdmin) && (
            <Form.Item label={fieldTranslations.administrator} name={organizationAdministrator} valuePropName='checked'>
              <Switch
                data-testid={organizationAdministrator}
                onChange={(value) => handleValueChange(value, organizationAdministrator)}
              />
            </Form.Item>
          )}
          <Form.Item label={fieldTranslations.settings} name={settingsField} valuePropName='checked'>
            <Switch
              data-testid={settingsField}
              onChange={(value) => handleValueChange(value, settingsField)}
              disabled={isFullAccess}
            />
          </Form.Item>
          <Form.Item label={fieldTranslations.subscription} name={subscriptionField} valuePropName='checked'>
            <Switch
              data-testid={subscriptionField}
              onChange={(value) => handleValueChange(value, subscriptionField)}
              disabled={isFullAccess}
            />
          </Form.Item>
          <Form.Item label={fieldTranslations.generalReports} name={generalReportsField} valuePropName='checked'>
            <Switch
              data-testid={generalReportsField}
              onChange={(value) => handleValueChange(value, generalReportsField)}
              disabled={isFullAccess}
            />
          </Form.Item>
          <Form.Item label={fieldTranslations.dashboard} name={dashboardField} valuePropName='checked'>
            <Switch
              data-testid={dashboardField}
              onChange={(value) => handleValueChange(value, dashboardField)}
              disabled={isFullAccess}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormWithFlexWrap>
  );
};
