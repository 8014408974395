import {
  Contact,
  ContactRecordUpsert,
  ContactUpsert,
  CreditCardUpsert,
  InfoTextUpsert,
  Phone,
} from '../../graph/types';
import { AddressUpsertGenerator, AddressUpsertInfo, mapAddressToAddressUpsert } from './AddressUpsertGenerator';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import { ContactFormFields, ContactPropertiesFormFields } from '../../pages/Contacts/ContactForm/ContactForm';
import { PhoneUpsertGenerator } from './PhoneUpsertGenerator';
import {
  defaultInterestIgnore,
  defaultSendType,
  defaultStatementIgnore,
  defaultTaxExempt,
} from '../../constants/referenceData/statementRunReferenceData';
import { isMatch } from '../../util/objectComparisons';
import { pick } from 'lodash';

export class ContactUpsertGenerator {
  private readonly initialContact: Partial<Contact>;
  private generatedContactUpsert: ContactUpsert;
  private readonly practiceId: string | undefined;

  constructor(initialContact: Partial<Contact>, practiceId?: string) {
    this.initialContact = initialContact;
    this.practiceId = practiceId;
  }

  static generateNewEntry(
    formValues: ContactFormFields,
    addressUpsertInfos: AddressUpsertInfo[],
    practiceId: string,
    phone: Phone[],
    text?: InfoTextUpsert[]
  ) {
    return new ContactUpsertGenerator({ id: ID_FOR_OBJECT_CREATION }, practiceId).generateFromUpdatedValues(
      formValues,
      {
        addressUpsertInfos,
        phone: phone.map((phoneItem) => ({ ...phoneItem, id: ID_FOR_OBJECT_CREATION })),
        text,
      }
    );
  }

  private didRecordValuesChange(formValues: ContactFormFields) {
    const { checkBoxSameAddresses, address, chargeInterest, statementIgnore, sendType, tax_exempt, note, ...props } =
      formValues;
    const initialRecordValues = pick(this.initialContact, 'name', 'email', 'contact_status_id');
    return !isMatch(initialRecordValues, props);
  }

  generateFromUpdatedValues(
    formValues: ContactFormFields,
    mappingConfiguration: { addressUpsertInfos: AddressUpsertInfo[]; phone?: Phone[]; text?: InfoTextUpsert[] }
  ): ContactUpsert {
    this.generatedContactUpsert = {
      id: this.initialContact.id,
    };
    if (this.didRecordValuesChange(formValues)) {
      this.addRecordToUpsert(formValues);
    }
    this.addAddressesToUpsertIfNeeded(formValues, mappingConfiguration.addressUpsertInfos);
    this.addPhoneUpsert(mappingConfiguration.phone);
    if (mappingConfiguration.text) {
      this.generatedContactUpsert.text = mappingConfiguration.text;
    }
    return this.generatedContactUpsert;
  }

  generateUpsertFromPhoneOnly(phone?: Phone, phoneId?: string) {
    this.generatedContactUpsert = {
      id: this.initialContact.id,
    };
    this.generatedContactUpsert.phone = new PhoneUpsertGenerator(
      this.initialContact?.phone ?? []
    ).generateFromUpdatedValues(phone, phoneId);
    return this.generatedContactUpsert;
  }

  addPhoneUpsert(phone?: Phone[]) {
    this.generatedContactUpsert.phone = phone?.map((phoneItem) => {
      const { __typename, phone_type_name, ...phoneUsertProps } = phoneItem;
      return phoneUsertProps;
    });
  }

  generateUpdatedStatementRunConfig(contactProperties: ContactPropertiesFormFields): ContactUpsert {
    this.generatedContactUpsert = {
      id: this.initialContact.id,
    };
    const upsertRecord = this.mapExistingContactToRecordUpsert();
    upsertRecord.statement_ignore = contactProperties.statementIgnore;
    this.generatedContactUpsert.record = upsertRecord;
    return this.generatedContactUpsert;
  }

  mapExistingContactToRecordUpsert(): ContactRecordUpsert {
    return {
      name: this.initialContact.name!,
      contact_status_id: this.initialContact.contact_status_id!,
      email: this.initialContact.email,
      dob: this.initialContact.dob,
      created_practice_id: this.practiceId,
      send_type: this.initialContact.send_type ?? defaultSendType,
      statement_ignore: this.initialContact.statement_ignore ?? defaultStatementIgnore,
      interest_ignore: this.initialContact.interest_ignore ?? defaultInterestIgnore,
    };
  }

  private addRecordToUpsert(updatedValues: ContactFormFields) {
    this.generatedContactUpsert.record = {
      name: updatedValues.name,
      contact_status_id: updatedValues.contact_status_id,
      email: updatedValues.email,
      dob: this.initialContact.dob,
      created_practice_id: this.practiceId,
      send_type: updatedValues.sendType ?? this.initialContact.send_type ?? defaultSendType,
      statement_ignore: updatedValues.statementIgnore ?? this.initialContact.statement_ignore ?? defaultStatementIgnore,
      interest_ignore: updatedValues.chargeInterest ?? this.initialContact.interest_ignore ?? defaultInterestIgnore,
      tax_exempt: updatedValues.tax_exempt ?? !!this.initialContact.tax_exempt?.length ?? defaultTaxExempt,
    };
  }

  private addAddressesToUpsertIfNeeded(updatedValues: ContactFormFields, addressUpsertInfos: AddressUpsertInfo[]) {
    const initialAddressUpserts = this.initialContact.address
      ? mapAddressToAddressUpsert(this.initialContact.address)
      : this.initialContact.address;
    this.generatedContactUpsert.address = new AddressUpsertGenerator(initialAddressUpserts).generateFromUpdatedValues(
      updatedValues?.address,
      addressUpsertInfos
    );
  }

  getCreditCardUpsert(upsert: CreditCardUpsert) {
    this.generatedContactUpsert = {
      id: this.initialContact.id,
      credit_card: [upsert],
    };
    return this.generatedContactUpsert;
  }
}
