import escapeRegExp from 'lodash/escapeRegExp';

export const adjustSearchString = (searchInput: string) => {
  const trimmedInput = searchInput.trim();

  if (!trimmedInput) {
    return '';
  }

  return (
    trimmedInput
      .replace(/[:.*&()|!\0]/g, '\\$&')
      .split(/\s+/)
      .join(':* & ') + ':*'
  );
};

const escapeElasticReservedChars = (query: string) => {
  // Check if the input looks like a phone number
  const isPhoneNumber = /^[\d\s()+\-*#]+$/.test(query);

  if (isPhoneNumber) {
    // If it looks like a phone number, replace the escaped characters with an empty string and remove spaces
    // Allow the user to pass in * for AND, allowing an out-of-order search in the number as well.
    return query
      .replace(/([!+&|()[\]{}^~?:"/\\\-\s])/g, '')
      .replace(/\*/g, '* *')
      .replace('@', '//@');
  }

  // If it doesn't look like a phone number, replace the escaped characters with a space
  return query.replace(/([!*+&|()[\]{}^~?:"/\\-])/g, ' ').replace('@', '//@');
};

export const adjustElasticSearchString = (searchInput: string) => {
  const trimmedInput = searchInput.trim();

  if (!trimmedInput) {
    return '*';
  }

  return '*' + escapeElasticReservedChars(trimmedInput) + '*';
};

export const filterByDocument = (value: string) => (record: unknown) =>
  (record as { document: string })?.document?.match(new RegExp(escapeRegExp(value) || '.*', 'i'));

export const filterByName = (value: string) => (record: unknown) =>
  (record as { name: string })?.name?.match(new RegExp(escapeRegExp(value) || '.*', 'i'));
