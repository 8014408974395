import { translations } from './translations';

export enum PrescriptionType {
  Invoice = 1,
  Patient = 2,
}

export enum PrescriptionLabelNameKeys {
  Small = 'PRESC_LABEL_SM',
  Medium = 'PRESC_LABEL_MD',
}

enum DymoFileName {
  Small = 'Dymo_2187328',
  Medium = 'Dymo_30323',
}

export const prescriptionLabelNameKeyConfig = {
  [PrescriptionLabelNameKeys.Small]: {
    label: translations.reportSettingsPage.prescriptionLabelTitles.small,
    fileName: DymoFileName.Small,
    instructionsMaxLength: 46,
  },
  [PrescriptionLabelNameKeys.Medium]: {
    label: translations.reportSettingsPage.prescriptionLabelTitles.medium,
    fileName: DymoFileName.Medium,
    instructionsMaxLength: 76,
  },
};
