import { translations } from '../constants/translations';
import { Patient, Service, User } from '../graph/types';
import { Tag } from 'antd';
import React from 'react';

export enum Status {
  Active,
  Inactive,
  Deceased,
}

export enum ThirdPartyStatus {
  NotApproved = 0,
  Queued = 1,
  Sent = 2,
  Error = 3,
}

export const getStatus = (record: Patient | User | Service) => {
  if ('deceased' in record && record.deceased) {
    return Status.Deceased;
  }
  return record.inactive ? Status.Inactive : Status.Active;
};

export const getStatusText = (status: Status) => {
  switch (status) {
    case Status.Active:
      return translations.patientsPage.status.active;
    case Status.Inactive:
      return translations.patientsPage.status.inactive;
    case Status.Deceased:
      return translations.patientsPage.status.deceased;
    default:
      throw new Error('unhandled status');
  }
};

export const getThirdPartyStatusText = (status: ThirdPartyStatus) => {
  switch (status) {
    case ThirdPartyStatus.NotApproved:
      return translations.historyPage.status.notApproved;
    case ThirdPartyStatus.Queued:
      return translations.historyPage.status.queued;
    case ThirdPartyStatus.Sent:
      return translations.historyPage.status.sent;
    case ThirdPartyStatus.Error:
      return translations.historyPage.status.error;
    default:
      throw new Error('unhandled status');
  }
};

const getTagColor = (status: Status) => {
  switch (status) {
    case Status.Active:
      return 'green';
    case Status.Inactive:
      return 'volcano';
    case Status.Deceased:
      return '#bfbfbf';
    default:
      throw new Error('unhandled status');
  }
};

const getThirdPartyTagColor = (status: ThirdPartyStatus) => {
  switch (status) {
    case ThirdPartyStatus.NotApproved:
      return 'gray';
    case ThirdPartyStatus.Queued:
      return 'volcano';
    case ThirdPartyStatus.Sent:
      return 'green';
    case ThirdPartyStatus.Error:
      return '#bfbfbf';
    default:
      throw new Error('unhandled status');
  }
};

export const getStatusTag = (status: Status) => {
  return <Tag color={getTagColor(status)}>{getStatusText(status)}</Tag>;
};

export const getThirdPartyStatusTag = (status_id: number) => {
  const status = ThirdPartyStatus[ThirdPartyStatus[status_id] as keyof typeof ThirdPartyStatus];
  return <Tag color={getThirdPartyTagColor(status)}>{getThirdPartyStatusText(status)}</Tag>;
};
