import React, { PropsWithChildren } from 'react';
import { translations } from '../../constants/translations';
import { Button, Progress, Spin } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 1rem auto;
`;

export interface SavingProps extends PropsWithChildren<unknown> {
  isSaving: boolean;
  savingMessage?: string;
  progress?: number;
  position?: 'fixed';
  onCancel?: () => void;
  testId?: string;
}

export const SaveSpinner: React.FC<SavingProps> = ({ progress, position, onCancel, testId, ...props }) => {
  const isProgress = progress !== undefined;
  const tip = isProgress ? undefined : props.savingMessage || translations.loadingComponent.saving;
  const indicator = isProgress ? (
    <>
      <Progress percent={progress} type='circle' width={50} />
      {onCancel && <StyledButton onClick={onCancel}>{translations.shared.cancelButtonText}</StyledButton>}
    </>
  ) : undefined;

  let style: React.CSSProperties = { maxHeight: 'unset', position };
  if (isProgress) {
    style = {
      maxHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      position,
    };
  }

  return (
    <Spin spinning={props.isSaving} tip={tip} size='large' indicator={indicator} style={style} data-testid={testId}>
      {props.children}
    </Spin>
  );
};
