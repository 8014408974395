import {
  CreditCardOutlined,
  DeleteOutlined,
  DownOutlined,
  MailOutlined,
  MergeCellsOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translations } from '../../constants/translations';
import { Contact, Job, UserOrganizationContactCard } from '../../graph/types';
import { useDeleteContact, useGetContact } from '../../hooks/ajax/contact/contactHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { PrintContactStatementModal } from '../../pages/Contacts/ViewContact/PrintContactStatementModal/PrintContactStatementModal';
import { viewContactTestIds } from '../../pages/Contacts/ViewContact/ViewContact';
import { useOffline } from '../../util/offline/offlineUtil';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import EmailModalForContactStatement from '../EmailModal/EmailModalForContactStatement';
import { JobStatus } from '../JobStatus/JobStatus';
import { MergeContacts } from '../MergeContacts/MergeContacts';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../Notification/notificationUtil';
import { PriceValue } from '../PriceValue/PriceValue';
import { setSaveSpinnerAction, setTextConfirmAction } from '../UtilsProvider/store/actions';
import { UtilsContext } from '../UtilsProvider/store/state';
import { BalanceText, BalanceTextWrapper, BalanceWrapper, StyledBalance } from './ActionButtonOptions.styles';
import { menuItemsStyle } from './ActionButtonOptionsUtils';
import StoredCardsModal from '../StoredCardsModal/StoredCardsModal';
import { useOrganizationContext } from '../../contexts/organization/state';

enum ModalTypes {
  AddPatient = 'addPatient',
  PrintStatement = 'printStatement',
  EmailStatement = 'emailStatement',
  DeleteContact = 'deleteContact',
  DeleteConflict = 'deleteConflict',
}

interface DoctorOfficeContactTabHeaderProps {
  contactCard: UserOrganizationContactCard;
  closeEntityCard?: (cardId: string) => Promise<void>;
}

export const ActionButtonOptions: React.FC<DoctorOfficeContactTabHeaderProps> = ({ contactCard, closeEntityCard }) => {
  const { isOnline, canUseCollection } = useOffline();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const practice = organization?.practice?.find((practice) => practice.id === organization.default_practice_id);
  const deleteContact = useDeleteContact(organizationId);
  const history = useHistory();
  const [contactMergeVisible, setContactMergeVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [storedCardsVisible, setStoredCardsVisible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<ModalTypes>();

  const [jobId, setJobId] = useState<string>();

  const { contact } = useGetContact({
    contactId: contactCard.contact_id ?? '',
    organizationId: practice?.organization_id ?? '',
  });

  const showMergeContactPopup = () => {
    setDropdownVisible(false);
    setContactMergeVisible(true);
  };

  const printStatementHandler = () => {
    setDropdownVisible(false);
    setIsModalOpen(ModalTypes.PrintStatement);
  };

  const emailStatementHandler = () => {
    setDropdownVisible(false);
    setIsModalOpen(ModalTypes.EmailStatement);
  };

  const showStoredCardsPopup = () => {
    setDropdownVisible(false);
    setStoredCardsVisible(true);
  };

  const handleContactDelete = useCallback(async () => {
    if (contact) {
      await deleteContact({
        contactId: contact.id,
        onSuccess: async () => {
          await closeEntityCard?.(contactCard.id);
          history.goBack();
        },
        onConflictError: () => showWarningMessage(translations.viewContactPage.conflictWarningMessage(contact.name)),
        successMessage: translations.viewContactPage.deleteSuccessMessage,
      });
      dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
    }
  }, [contact, deleteContact, dispatchUtils, history, closeEntityCard, contactCard.id]);

  const closeJobStatusModal = (errorMessage?: string) => {
    setJobId(undefined);
    if (errorMessage) {
      showErrorMessage(errorMessage);
    } else {
      showSuccessMessage(translations.shared.saveSuccessMessage);
    }
  };

  const handleJobfinish = async ({ result }: Job) => {
    if (result) {
      const { reportPointer } = await JSON.parse(result);
      if (reportPointer) {
        openTabWithPopupDetection(reportPointer, translations.popupDetectionMessage.printReport);
      }
    }
  };

  const handleOpenDeleteConfirm = useCallback(() => {
    setDropdownVisible(false);
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: handleContactDelete,
        title: translations.viewContactPage.deleteContactModalConfirmTitle,
        description: translations.viewContactPage.deleteContactConfirm(contact?.name ?? undefined),
      })
    );
  }, [handleContactDelete, contact, dispatchUtils, setDropdownVisible]);

  const renderActionMenu = useMemo(() => {
    const actionItems: MenuProps['items'] = [
      {
        key: '1',
        label: translations.viewContactPage.emailStatement,
        icon: <MailOutlined />,
        onClick: emailStatementHandler,
        style: menuItemsStyle.menuItem,
      },
      {
        key: '2',
        label: translations.viewContactPage.printStatement,
        icon: <PrinterOutlined />,
        onClick: printStatementHandler,
        style: menuItemsStyle.menuItem,
      },
      {
        key: '3',
        label: translations.viewContactPage.deleteButton,
        icon: <DeleteOutlined />,
        onClick: handleOpenDeleteConfirm,
        style: menuItemsStyle.menuItem,
      },
      {
        key: '4',
        label: translations.viewContactPage.mergeStatement,
        icon: <MergeCellsOutlined />,
        onClick: showMergeContactPopup,
        style: menuItemsStyle.menuItem,
      },
      {
        key: '5',
        label: translations.viewContactPage.storedCards,
        icon: <CreditCardOutlined />,
        onClick: showStoredCardsPopup,
        style: menuItemsStyle.menuItem,
      },
    ];

    return (
      <StyledBalance>
        <Space>
          <BalanceWrapper>
            <BalanceTextWrapper>
              <BalanceText>{translations.viewContactPage.accountBalance}: </BalanceText>
              <PriceValue
                component='h2'
                value={+(contactCard?.contact_balance_posted || 0)}
                colorNegative='green'
                colorPositive='red'
              />
            </BalanceTextWrapper>
            {isOnline && (
              <Dropdown menu={{ items: actionItems }} open={dropdownVisible}>
                <Button onMouseEnter={() => setDropdownVisible(true)}>
                  {translations.viewContactPage.actions}
                  <DownOutlined data-testid={viewContactTestIds.actionDropdown} />
                </Button>
              </Dropdown>
            )}
          </BalanceWrapper>
        </Space>
      </StyledBalance>
    );
  }, [isOnline, contactCard, dropdownVisible, handleOpenDeleteConfirm]);

  return (
    <>
      {renderActionMenu}

      {isModalOpen === ModalTypes.EmailStatement && (
        <EmailModalForContactStatement contact={contact as Contact} onClose={() => setIsModalOpen(undefined)} />
      )}

      {isModalOpen === ModalTypes.PrintStatement && (
        <PrintContactStatementModal
          contact={contact as Contact}
          practiceId={practice?.id ?? ''}
          onClose={() => setIsModalOpen(undefined)}
          canUseCollection={canUseCollection}
          setJobId={setJobId}
        />
      )}

      {jobId && (
        <JobStatus
          jobId={jobId}
          onError={showErrorMessage}
          closeModal={closeJobStatusModal}
          onCompletion={handleJobfinish}
        />
      )}

      {contact && (
        <MergeContacts contact={contact} visible={contactMergeVisible} onClose={() => setContactMergeVisible(false)} />
      )}

      {contact && practice && organization && (
        <StoredCardsModal
          organization={organization}
          practice={practice}
          contact={contact}
          visible={storedCardsVisible}
          onClose={() => setStoredCardsVisible(false)}
        />
      )}
    </>
  );
};

export default ActionButtonOptions;
