import { Button, Row } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';

type RegistrationButtonProps = {
  onClick: () => void;
  additionalContent?: React.ReactNode;
  text?: string;
  disabled?: boolean;
};

export const RegistrationButton: React.FC<RegistrationButtonProps> = ({
  onClick,
  text,
  additionalContent,
  disabled,
}) => {
  return (
    <Row style={{ justifyContent: 'flex-end', marginTop: '32px' }}>
      {additionalContent}
      <Button type='primary' onClick={onClick} disabled={disabled}>
        {text || translations.registration.nextButton}
      </Button>
    </Row>
  );
};
