import { gql } from '@apollo/client';

export const LedgerFields = gql`
  fragment LedgerFields on Ledger {
    id
    type_id
    type_name_key
    payment_type_id
    payment_type_name_key
    payment_electronic_id
    processor_type_id
    number
    check_number
    financial_outstanding
    financial_amount
    posted
    updated
    contact_id
    contact_name
    total
    total_assigned
    total_reversed
    financial_date
    invoice_status_name_key
    invoice_id
    note_exists
    hidden
    patient_name
    invoice_number
    reversal
    deposited
    info {
      value
      type_id
    }
  }
`;

export const LedgerFieldsForOffline = gql`
  fragment LedgerFieldsForOffline on Ledger {
    ...LedgerFields
    organization_id
    updated
    practice_id
    invoice_id
    invoice_status_id
    invoice_id
    invoice_status_id
    invoice_status_name_key
    invoice_contact_id
    type_id
    type_name_key
    sub_type_id
    sub_type_name
    payment_type_id
    payment_type_name_key
    number
    total_no_tax
    total_tax
    total
    posted
    posted_date
    financial_date
    financial_amount
    financial_outstanding
    total_assigned
    total_reversed
    reversing_ledger_id
    reversal
    tx_reference_number
    card_expiry_month
    card_expiry_year
    card_display_number
    check_number
  }
  ${LedgerFields}
`;

export const LedgerNoteFields = gql`
  fragment LedgerNoteFields on Ledger {
    note {
      created
      created_user_id
      created_user_name
      value
    }
  }
`;

export const LedgerTaxFields = gql`
  fragment LedgerTaxFields on LedgerTax {
    tax_type_id
    tax_type_code
    tax_type_name
    total
    sort_order
  }
`;
