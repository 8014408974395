import {
  UpsertCardConnectTransaction,
  UpsertElectronicPaymentResult,
  UpsertStripeTransaction,
} from '../../../graph/queries/paymentGateway';
import { OnElectronicPaymentStatusChange } from '../../../graph/subscription/electronicPaymentStatusChanges';
import { GetContactCreditCards } from '../../../graph/queries/contacts';
import { UpsertCardConnectDevice } from '../../../graph/queries/system';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import { useSubscription } from '../subscription/subscriptionHooks';

export const useUpsertCardConnectTransaction = (organizationId: string, contactId?: string) => {
  let options;
  if (contactId) {
    options = {
      refetchQueries: [{ query: GetContactCreditCards, variables: { organizationId, contactId } }],
    };
  }
  return useOfflineErrorSkipMutation(UpsertCardConnectTransaction, options);
};

export const useUpsertStripeTransaction = (organizationId: string, contactId?: string) => {
  let options;
  if (contactId) {
    options = {
      refetchQueries: [{ query: GetContactCreditCards, variables: { organizationId, contactId } }],
    };
  }

  return useOfflineErrorSkipMutation(UpsertStripeTransaction, options);
};

export const useUpsertElectronicPaymentResult = () => {
  return useOfflineErrorSkipMutation(UpsertElectronicPaymentResult);
};
export const useElectronicPaymentStatusChangeSubscription = (clientRequestToken: string) => {
  const { loading, data } = useSubscription(OnElectronicPaymentStatusChange, {
    variables: { clientToken: clientRequestToken },
    shouldResubscribe: true,
  });
  return {
    paymentStatusChange: data?.onElectronicPaymentStatusChange,
    paymentStatusChangeLoading: loading,
  };
};

export const useUpsertCardConnectDevice = () => {
  return useOfflineErrorSkipMutation(UpsertCardConnectDevice);
};

export const useGetStripeEmbeddedSecret = () => {
  return useOfflineErrorSkipMutation(UpsertStripeTransaction);
};
