import { PatientProperty, patientPropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Patient, ServiceRendered3pApproval } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getIdNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { renderPatientOwnerTags } from '../../Invoices/InvoicesOverview/util/patientOwnerTags';
import { getStatus, Status } from '../../../util/statusUtils';

export const relationshipFilter = (value: string | number | boolean, patient: Patient | ServiceRendered3pApproval) =>
  !!patient.related_names?.find((relatedName) => relatedName.toLowerCase().includes(value.toString().toLowerCase()));

export const statusProperty = 'status';

export const basicPatientColumns: Record<PatientProperty | 'status', CustomColumnType<Patient>> = {
  name: {
    title: translations.patientsPage.columns.name,
    key: patientPropertyNames.name,
    dataIndex: patientPropertyNames.name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.name),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.name),
    sorter: getStringCompareFunctionFor(patientPropertyNames.name),
  },
  name_2: {
    title: translations.patientsPage.columns.name_2,
    key: patientPropertyNames.name_2,
    dataIndex: patientPropertyNames.name_2,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.name_2),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.name_2),
    sorter: getStringCompareFunctionFor(patientPropertyNames.name_2),
  },
  name_3: {
    title: translations.patientsPage.columns.name_3,
    key: patientPropertyNames.name_3,
    dataIndex: patientPropertyNames.name_3,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.name_3),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.name_3),
    sorter: getStringCompareFunctionFor(patientPropertyNames.name_3),
  },
  related_names: {
    title: translations.patientsPage.columns.relationship,
    key: patientPropertyNames.related_names,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.patientsPage.columns.relationship
    ),
    sorter: getStringCompareFunctionFor(patientPropertyNames.related_names),
    onFilter: relationshipFilter,
  },
  number: {
    title: translations.patientsPage.columns.number,
    key: patientPropertyNames.number,
    dataIndex: patientPropertyNames.number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.number),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.number),
    sorter: getIdNumberCompareFunctionFor(patientPropertyNames.number),
  },
  species_name: {
    title: translations.patientsPage.columns.species,
    key: patientPropertyNames.species_name,
    ...generalFilteringAndSortingSettings,
    onFilter: (value, record) => record.species_id === value,
    sorter: getStringCompareFunctionFor(patientPropertyNames.species_name),
  },
  breed_name: {
    title: translations.patientsPage.columns.breed,
    key: patientPropertyNames.breed_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.breed),
    sorter: getStringCompareFunctionFor(patientPropertyNames.breed_name),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.breed_name),
  },
  gender_name: {
    title: translations.patientsPage.columns.gender,
    key: patientPropertyNames.gender_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.gender),
    sorter: getStringCompareFunctionFor(patientPropertyNames.gender_name),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.gender_name),
  },
  owner_names: {
    title: translations.patientsPage.columns.owner,
    key: patientPropertyNames.owner_names,
    render: (patient: Patient) => renderPatientOwnerTags(patient),
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.patientsPage.columns.owner),
    onFilter: getOnFilterFunctionFor(patientPropertyNames.owner_names),
    sorter: getStringCompareFunctionFor(patientPropertyNames.owner_names),
  },
  status: {
    title: translations.patientsPage.columns.status,
    key: statusProperty,
    ...generalFilteringAndSortingSettings,
    filters: [
      { text: 'Active', value: Status.Active },
      { text: 'Inactive', value: Status.Inactive },
      { text: 'Deceased', value: Status.Deceased },
    ],
    onFilter: (value, record) => getStatus(record) === value,
    sorter: (a: Patient, b: Patient) => getStatus(a) - getStatus(b),
  },
};
