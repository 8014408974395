import { Button } from 'antd';
import React from 'react';
import { translations } from '../../../../constants/translations';

export const CreditCardManualFooter: React.FC<{
  onProcess: () => void;
  okText?: string;
  onCancel: () => void;
  processDisabled: boolean;
  showCancel?: boolean;
}> = ({ onProcess, onCancel, processDisabled, okText, showCancel }) => {
  return (
    <>
      {showCancel && (
        <Button key='back' onClick={onCancel}>
          {translations.shared.backButtonText}
        </Button>
      )}
      <Button key='submit' type='primary' htmlType='submit' onClick={onProcess} disabled={processDisabled}>
        {okText ?? translations.paymentModal.buttons.processButton}
      </Button>
    </>
  );
};
