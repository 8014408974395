import { File, LabRequest, Note, NotePreview, ServiceActivity } from '../../graph/types';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { translations } from '../../constants/translations';
import { sortBy } from 'lodash';
import { selectHasUnsavedChanges } from './store/selectors';
import { showWarningMessage } from '../Notification/notificationUtil';
import { RecordsAction, selectRecordAction } from './store/actions';
import { RecordsState, selectedRecordIdType } from './store/reducer';
import { Filter } from './FullscreenRecords/Filters';

export interface RecordItem {
  originalObject: NotePreview | File | ServiceActivity | LabRequest | Note;
  recordId: string;
  type: RecordType;
  typeName: string;
  date: Dayjs;
  created: Dayjs;
  renderBottomLine: () => JSX.Element;
  renderDescription: () => JSX.Element;
  getTag?: () => JSX.Element;
}

export enum RecordType {
  typeNote,
  typeFile,
  typeServiceRenderedActivity,
  typeLabRequest,
}

export function sortRecordsByDate(records: RecordItem[]) {
  return sortBy(records, ['date', 'created']).reverse();
}

export function filterRecordByType(record: RecordItem, byRecordTypes: RecordType[] = []) {
  if (byRecordTypes.length === 0) {
    return true;
  }
  return byRecordTypes.includes(record.type);
}

export function findIndexForStartingDate(records: RecordItem[], jumpToDate?: string) {
  const givenDate = dayjs(jumpToDate);
  if (!jumpToDate || !givenDate.isValid()) {
    return -1;
  }
  return records.findIndex((record: RecordItem) => {
    return record.date.isSameOrBefore(givenDate);
  });
}

export function filterRecords(records: RecordItem[], filters: Filter) {
  return records.filter((record: RecordItem) => {
    return filterRecordByType(record, filters?.byType); // && filterBySomthing(record, filters?.bySomthing) //keep adding any other condition that you want to append for the filter
  });
}

export function getPaddedSpans(...inputStrings: string[]) {
  const isService = inputStrings[0].includes(translations.recordList.doctors);
  const paddedSpans = inputStrings.map((inputString, index) => {
    return (
      <span id={isService ? 'ServiceRecord' : undefined} key={index} style={{ paddingLeft: index === 0 ? '' : '20px' }}>
        {inputString}
      </span>
    );
  });
  return <>{paddedSpans}</>;
}

export const selectRecordIfNoUnsavedChanges = (
  state: RecordsState,
  dispatch: React.Dispatch<RecordsAction>,
  recordId?: selectedRecordIdType
) => {
  const hasUnsavedChanges = selectHasUnsavedChanges(state);
  if (hasUnsavedChanges) {
    showWarningMessage(translations.recordList.unsavedChangesWarning);
  } else {
    dispatch(selectRecordAction(recordId));
  }
};

export const renderBottomLineForNotesAndFiles = (noteOrFile: NotePreview | File) => {
  const userDisplay = translations.recordList.created_by + noteOrFile.created_user_name;
  if (!noteOrFile.updated_user_name) {
    return getPaddedSpans(userDisplay);
  }
  const updatedBy = translations.recordList.updated_by + noteOrFile.updated_user_name;
  return getPaddedSpans(userDisplay, updatedBy);
};
