import { LedgerPaymentElectronicTypeValue } from '../constants/referenceData/ledgerReferenceData';
import { OrganizationDto } from '../graph/types';
import { getIsStripeProcessor } from './stripeConnectUtils';

export const getAvailablePaymentMethods = (organization: OrganizationDto | undefined, practiceId: string) => {
  const practice = organization?.practice.find((practice) => practice.id === practiceId);
  if (practice?.card_connect?.merchantId || getIsStripeProcessor(organization)) {
    return (
      organization?.ref_financial?.ledger_payment_type?.filter(
        (paymentMethod) => paymentMethod.electronic_type_id < LedgerPaymentElectronicTypeValue.CreditCard
      ) || []
    );
  }
  return (
    organization?.ref_financial?.ledger_payment_type?.filter(
      (paymentMethod) => paymentMethod.electronic_type_id === 0
    ) || []
  );
};

export const getAvailablePaymentTypes = (organization: OrganizationDto | undefined, practiceId: string) => {
  const practice = organization?.practice.find((practice) => practice.id === practiceId);
  if (practice?.card_connect?.merchantId || getIsStripeProcessor(organization)) {
    return organization?.ref_financial?.ledger_payment_type || [];
  }
  return (
    organization?.ref_financial?.ledger_payment_type?.filter(
      (paymentMethod) => paymentMethod.electronic_type_id === 0
    ) || []
  );
};
