import { ApolloCache, Reference } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { PrescriptionFields, PrescriptionFlattenedFields } from '../../../graph/fragments';
import {
  GetPrescriptions,
  GetPrescriptionsFlattened,
  UpsertPrescription,
  UpsertPrescriptionFill,
} from '../../../graph/queries/prescriptions';
import {
  Mutation,
  MutationUpsertPrescriptionArgs,
  MutationUpsertPrescriptionFillArgs,
  PrescriptionFilter,
  PrescriptionFlattened,
  PrescriptionFlattenedFilter,
  PrescriptionUpsert,
  Query,
  QueryGetPrescriptionsArgs,
} from '../../../graph/types';
import {
  getPrescriptionInsert,
  getPrescriptionUpdate,
} from '../../../services/LocalDatabaseService/queries/prescriptionQueries';
import { RxContact } from '../../../services/LocalDatabaseService/schemas/contactSchema';
import { RxPatient } from '../../../services/LocalDatabaseService/schemas/patientSchema';
import { RxPrescription } from '../../../services/LocalDatabaseService/schemas/prescriptionSchema';
import { useOfflineDelete, useOfflineInsert, useOfflineUpdate } from '../../localDatabaseHooks';
import { useGetOrganizationIdFromRoute } from '../../route/routeParameterHooks';
import { OwnerType } from '../recordHooks';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import { useOfflineQuery } from '../useOfflineQuery';

export const useGetPrescriptions = (
  organizationId: string,
  params: { id: string },
  type: OwnerType,
  filter?: PrescriptionFilter
) => {
  const selector = useMemo(() => ({ [`${type}_id`]: params.id }), [params, type]);
  const variables: QueryGetPrescriptionsArgs = { organizationId };

  const entityKey = type === OwnerType.Contact ? 'contactId' : 'patientId';
  variables[entityKey] = params.id;

  if (filter) {
    variables.filter = filter;
  }

  const { data, loading, refetch } = useOfflineQuery<'getPrescriptions'>({
    query: GetPrescriptions,
    collection: 'prescription',
    options: {
      skip: organizationId === '' || params?.id === '',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables,
    },
    selector,
    expectList: true,
    sortBy: 'prescribed_date',
  });

  return {
    prescriptions: data,
    prescriptionsLoading: loading,
    refetch,
  };
};

export const useGetPrescriptionsFlattened = (
  organizationId: string,
  params: { id: string },
  type: OwnerType,
  filter?: PrescriptionFlattenedFilter
) => {
  const selector = useMemo(() => ({ [`${type}_id`]: params.id }), [params, type]);
  const variables: QueryGetPrescriptionsArgs = { organizationId };

  const entityKey = type === OwnerType.Contact ? 'contactId' : 'patientId';
  variables[entityKey] = params.id;

  if (filter) {
    variables.filter = filter;
  }

  const { data, loading, refetch } = useOfflineQuery<'getPrescriptionsFlattened'>({
    query: GetPrescriptionsFlattened,
    collection: 'prescription_flattened',
    options: {
      skip: organizationId === '' || params?.id === '',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables,
    },
    selector,
    expectList: true,
    sortBy: 'prescribed_date',
  });

  return {
    prescriptionsFlattened: data,
    prescriptionsFlattenedLoading: loading,
    refetch,
  };
};

export const useUpdatePrescription = () => {
  return useOfflineErrorSkipMutation<'upsertPrescription', MutationUpsertPrescriptionArgs>(UpsertPrescription, {
    update(cache: any, { data }: any) {
      const upsertPrescription = data?.upsertPrescription;

      cache.modify({
        fields: {
          getPrescriptions(references: Reference[] = []) {
            const newPresriptionReference: Reference = cache.writeFragment({
              data: { ...upsertPrescription, __typename: 'Prescription' },
              fragment: PrescriptionFields,
              fragmentName: 'PrescriptionFields',
            });

            if (!references.find((reference) => reference.__ref === newPresriptionReference.__ref)) {
              return [newPresriptionReference, ...references];
            }
            return references;
          },
        },
      });
    },
  });
};

export const useDeletePrescription = (organizationId: string, patientId: string) => {
  const [deletePrescription] = useOfflineErrorSkipMutation<'upsertPrescription', MutationUpsertPrescriptionArgs>(
    UpsertPrescription
  );

  return useCallback(
    (prescriptionId: string) => {
      return deletePrescription({
        variables: {
          organizationId,
          prescription: { id: prescriptionId, void: true },
        },
        refetchQueries: [
          { query: GetPrescriptions, variables: { organizationId, patientId, filter: { returnHidden: false } } },
        ],
      });
    },
    [deletePrescription, organizationId, patientId]
  );
};

export const usePrescritionOfflineMutation = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const patientCollection = useRxCollection<RxPatient>('patient');
  const contactCollection = useRxCollection<RxContact>('contact');
  const prescriptionCollection = useRxCollection<RxPrescription>('prescription');
  const offlineInsert = useOfflineInsert<RxPrescription>('prescription');
  const offlineUpdate = useOfflineUpdate<RxPrescription>('prescription');
  const offlineDelete = useOfflineDelete<RxPrescription>('prescription');

  return useCallback(
    async (upsert: PrescriptionUpsert) => {
      if (upsert.id === ID_FOR_OBJECT_CREATION) {
        const patient = upsert.record?.patient_id
          ? await patientCollection?.findOne(upsert.record?.patient_id)?.exec()
          : null;
        const contact = upsert.record?.contact_id
          ? await contactCollection?.findOne(upsert.record?.contact_id)?.exec()
          : null;
        const obj = await getPrescriptionInsert(upsert, { patient, contact, organizationId });
        await offlineInsert(obj);
      } else if (prescriptionCollection && upsert.id) {
        const prescription = (await prescriptionCollection?.findOne(upsert.id).exec()) as RxPrescription | null;
        if (prescription) {
          if (upsert.void && prescription.is_new) {
            await offlineDelete(prescription.id);
            return;
          }
          const obj = getPrescriptionUpdate(prescription, upsert);
          await offlineUpdate(upsert.id, obj);
        }
      }
    },
    [
      offlineUpdate,
      offlineInsert,
      offlineDelete,
      organizationId,
      prescriptionCollection,
      patientCollection,
      contactCollection,
    ]
  );
};

export const useUpdatePrescriptionFill = (patientId: string) => {
  return useOfflineErrorSkipMutation<'upsertPrescriptionFill', MutationUpsertPrescriptionFillArgs>(
    UpsertPrescriptionFill,
    {
      update(cache: ApolloCache<Pick<Mutation, 'upsertPrescriptionFill'>>, { data }: any) {
        const upsertPrescriptionFill = data?.upsertPrescriptionFill;

        const existingPrescriptions = cache.readQuery<Pick<Query, 'getPrescriptionsFlattened'>>({
          query: GetPrescriptionsFlattened,
          variables: {
            organizationId: upsertPrescriptionFill?.organization_id,
            patientId,
            filter: { returnHidden: false },
          },
        });

        const existingPrescription = existingPrescriptions?.getPrescriptionsFlattened.find(
          (prescription: PrescriptionFlattened) => prescription.fill_id === upsertPrescriptionFill.id
        );

        if (existingPrescription) {
          const updatedPrescription = {
            ...existingPrescription,
            filled_item_instructions: upsertPrescriptionFill.filled_item_instructions,
          };

          cache.modify({
            fields: {
              getPrescriptionsFlattened(references: Reference[] = []) {
                const prescriptionReference = cache.writeFragment({
                  data: updatedPrescription,
                  fragment: PrescriptionFlattenedFields,
                  fragmentName: 'PrescriptionFlattenedFields',
                });
                if (!references.find((reference) => reference.__ref === prescriptionReference?.__ref)) {
                  return [prescriptionReference, ...references];
                }
                return references;
              },
            },
          });
        }
      },
    }
  );
};
