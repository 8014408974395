import { User } from '../../graph/types';

export type UserAction = SetUser;

export const setUserType = 'USER__SET_USER';

export interface SetUser {
  type: typeof setUserType;
  user: User;
}

export const setUser = (user: User): SetUser => ({
  type: setUserType,
  user,
});
