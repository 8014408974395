import { CardConnectFormValues } from './CardConnectFormItems';
import { useMemo } from 'react';
import { Address } from '../../graph/types';
import { cardConnectLinks } from './cardConnectConstants';
import { CountryNameKey } from '../../constants/countries';
import { LOCALES } from '../../util/locales';

export const getCardConnectUpsert = (values: CardConnectFormValues, practiceId: string, organizationId: string) => {
  return {
    practice: {
      id: practiceId,
      card_connect: {
        ...values,
      },
    },
    organizationId,
  };
};

export const useGetCardConnectLink = (practiceAddressWithCountry?: Address[]) => {
  return useMemo(() => {
    if (!practiceAddressWithCountry?.length) {
      return cardConnectLinks.CTRY_USA;
    }
    const ctryNameKey = (practiceAddressWithCountry[0].country_name_key || 'CTRY_USA') as CountryNameKey;
    if (ctryNameKey === 'CTRY_USA') {
      return cardConnectLinks.CTRY_USA;
    }
    if (ctryNameKey === 'CTRY_CANADA') {
      return cardConnectLinks.CTRY_CANADA;
    }
    return undefined;
  }, [practiceAddressWithCountry]);
};

export const useGetHideCardConnectSettings = (practiceAddressWithCountry?: Address[]) => {
  return useMemo(() => {
    if (!practiceAddressWithCountry?.length) {
      return LOCALES[CountryNameKey.USA].hideCardConnectSettings;
    }
    const ctryNameKey = (practiceAddressWithCountry[0].country_name_key || 'CTRY_USA') as CountryNameKey;
    return LOCALES[ctryNameKey].hideCardConnectSettings || LOCALES[CountryNameKey.USA].hideCardConnectSettings;
  }, [practiceAddressWithCountry]);
};
