import { Input } from 'antd';
import React, { PropsWithChildren } from 'react';
import { translations } from '../../../constants/translations';
import './FileDescriptionTextArea.css';

interface FileDescriptionTextAreaProps extends PropsWithChildren<unknown> {
  editMode: boolean;
  textContent: string | undefined;
  setTextContent: (textContent: string) => void;
}

function handleFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
  event.target.select();
}

export const FileDescriptionTextArea: React.FC<FileDescriptionTextAreaProps> = ({
  editMode,
  setTextContent,
  textContent,
}: FileDescriptionTextAreaProps) => {
  return (
    <Input.TextArea
      id={'FileDescriptionTextArea'}
      className={'FileDescriptionTextArea'}
      value={textContent}
      onChange={(e) => setTextContent(e.target.value)}
      placeholder={translations.recordList.textFieldPlaceholder}
      bordered={editMode}
      autoSize
      maxLength={255}
      onFocus={handleFocus}
    />
  );
};
