import { Phone } from '../../graph/types';

export type PhoneTableProperties =
  | keyof Pick<Phone, 'primary' | 'phone' | 'phone_type_name' | 'description' | 'extension'>
  | 'actions';

export const phoneTablePropertyNames: Readonly<{ [K in PhoneTableProperties]: K }> = {
  phone: 'phone',
  description: 'description',
  extension: 'extension',
  primary: 'primary',
  phone_type_name: 'phone_type_name',
  actions: 'actions',
};
