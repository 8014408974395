import { Patient } from '../../../../graph/types';
import {
  closeModalsType,
  InvoicesAction,
  selectPatientType,
  showAddInvoiceModalType,
  showSelectPatientModalType,
} from './actions';

export interface InvoicesState {
  showSelectPatientModal: boolean;
  showAddInvoiceModal: boolean;
  selectedPatient?: Patient;
}

export const invoicesInitialState: InvoicesState = {
  showSelectPatientModal: false,
  showAddInvoiceModal: false,
};

export const invoicesReducer = (state: InvoicesState, action: InvoicesAction): InvoicesState => {
  switch (action.type) {
    case closeModalsType:
      return {
        ...state,
        showAddInvoiceModal: false,
        showSelectPatientModal: false,
        selectedPatient: undefined,
      };
    case selectPatientType:
      return {
        ...state,
        showSelectPatientModal: false,
        showAddInvoiceModal: true,
        selectedPatient: action.selectedPatient,
      };
    case showSelectPatientModalType:
      return {
        ...state,
        showSelectPatientModal: true,
        showAddInvoiceModal: false,
      };
    case showAddInvoiceModalType:
      return {
        ...state,
        showSelectPatientModal: false,
        showAddInvoiceModal: true,
      };
  }
  return state;
};
