import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  useDeleteInvoice,
  useDeleteServiceRendered,
  useGetInvoice,
  useInvoiceOfflineUpdate,
  useUpdateInvoice,
  useUpdateInvoiceForServiceNotes,
  useUpdateInvoiceWithContactData,
} from '../../../hooks/ajax/invoice/invoiceHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import {
  getInvoiceUpsertForAddServices,
  getInvoiceUpsertForUpdatingMultipleRenderedService,
  getInvoiceUpsertForUpdatingRenderedService,
} from './getInvoiceUpsertServiceRendered';
import {
  Caregiver,
  DefaultNote,
  Invoice,
  InvoiceContactUpsert,
  InvoiceUpsert,
  LabRequest,
  Note,
  PatientUpsert,
  PrescriptionFlattened,
  RelatedOwner,
  ServiceRendered,
  ServiceRenderedUpsert,
} from '../../../graph/types';
import {
  createNewServiceForListView,
  getOptimisticResponseForServiceRenderedChanges,
  isItemReorderAllowed,
  sortServicesByDateAndSortOrder,
} from './viewInvoiceUtil';
import {
  ModifyAndViewServicesRendered,
  ModifyAndViewServicesRenderedProps,
} from '../../../components/ServiceRendered/ModifyAndViewServicesRendered';
import { TotalsAndTaxes } from './TotalsAndTaxes/TotalsAndTaxes';
import { ServicesRenderedContext } from '../../../components/ServiceRendered/store/state';
import { ViewInvoiceHeader } from './ViewInvoiceHeader/ViewInvoiceHeader';
import { PracticeSettingsNameKey, translations } from '../../../constants/translations';
import {
  invoiceCanTakePayment,
  invoiceIsDeletable,
  InvoiceStatusId,
  isCompletedInvoiceStatus,
} from '../../../constants/referenceData/invoiceReferenceData';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { useDeleteMutationWithMessages, useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { routes } from '../../../constants/routes';
import { ViewInvoiceFooter } from './ViewInvoiceFooter/ViewInvoiceFooter';
import {
  addPatientDeceaseInfo,
  clearIsSavingAction,
  setEditingServiceRenderedIdAction,
  setIsSavingAction,
} from '../../../components/ServiceRendered/store/actions';
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from '../../../components/Notification/notificationUtil';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
  heightMainMenu,
  heightOfScrollbar,
  heightTableHeader,
} from '../../../components/ServiceRendered/ServicesRenderedTable/servicesRenderedTableConstants';
import { getInvoiceUpsertForNoteChange } from './invoiceUpsertNoteUtils';
import {
  NoteChangeRequest,
  NoteChangeRequestType,
  NoteCreateRequest,
  NoteUpdateRequest,
} from '../../../components/ServiceRendered/NoteView/noteChangeUtil';
import { getHeightOfElementWithId } from '../../../util/documentUtil';
import { Loading } from '../../../components/Loading/Loading';
import { SaveInvoiceProps } from '../AddInvoice/AddInvoiceModal';
import { PatientContactRelationTypeNameKey } from '../../../constants/referenceData/patientReferenceData';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { calculateTotalPercentage } from '../../Patients/ViewPatient/PatientOwnership/ownershipUtils';
import { Button, Card, Col, Form, Row } from 'antd';
import { BillTo } from '../../../components/BillTo/BillTo';
import { PrintInvoiceReportModal } from '../PrintInvoiceReportModal/PrintInvoiceReportModal';
import { EmailModalForInvoiceReport } from '../../../components/EmailModal/EmailModalForInvoiceReport';
import { InvoicePaymentModal } from '../../../components/PaymentModal/InvoicePaymentModal';
import styled from 'styled-components';
import { getInvoiceContactTags } from '../invoiceContactTags';
import { FlexContainer } from '../../../globalStyles.style';
import { useGetPatient, usePatientOfflineUpdate, useUpdatePatient } from '../../../hooks/ajax/patients/patientHooks';
import { useGetInvoiceLedger } from '../../../hooks/ajax/ledger/ledgerHooks';
import {
  AddServiceFormValues,
  dateFieldName,
  NewService,
  serviceFieldName,
} from '../../../components/ServiceRendered/AddServiceForm/AddServiceForm';
import { useOffline } from '../../../util/offline/offlineUtil';
import { InvoiceFooter } from './InvoiceFooter';
import { OwnerType } from '../../../hooks/ajax/recordHooks';
import { flatten, isEmpty, uniqBy } from 'lodash';
import { PrescriptionType } from '../../../constants/prescriptions';
import { formats, upsertDateFormat } from '../../../constants/formats';
import dayjs from 'dayjs';
import { dummyNoteIdForRapidBilling } from '../../../hooks/ajax/note/noteHooks';
import { createPreviewTextForNote } from '../../../components/Records/noteUpsertUtils';
import { getServiceText } from '../../RapidBilling/RapidBillingServices/rapidBillingServiceUtil';
import { useGetReminders } from '../../../hooks/ajax/reminders/reminderHooks';
import { RxInvoice } from '../../../services/LocalDatabaseService/schemas/invoiceSchema';
import { useRxCollection } from 'rxdb-hooks';
import { RxInfoText } from '../../../services/LocalDatabaseService/schemas/infoTextSchema';
import { moveItemsWithSortOrder } from '../../../util/filterAndSorting';
import { LabRequestModal } from '../../../components/LabRequestModal/LabRequestModal';
import { LabRequestStatusId } from '../../../constants/referenceData/labReferenceData';
import { TaxonomyMappingModal } from '../../../components/TaxonomyMappingModalContent/TaxonomyMappingModalContent';
import { DeceasePatientsInfo } from '../../../components/ServiceRendered/store/reducer';
import { getPracticeSetting } from '../../Contacts/ViewContact/statementUtils';
import { v4 as uuid } from 'uuid';
import { flushSync } from 'react-dom';
import { ViewInvoiceContext } from './store/state';
import { getIsBillToOpen } from './store/selectors';
import { setIsBillToOpen } from './store/actions';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { MutationHookOptions } from '@apollo/client';
import { UpdateServiceRenderedType } from '../../../components/ServiceRendered/ServicesRenderedTable/ServicesRenderedTable';
import { useGetPrescriptions } from '../../../hooks/ajax/prescriptionHooks/prescriptionHooks';

const currentTabLabel = translations.invoicePage.editBillingModal.current;
const footerLineHeight = 22;
const footerPadding = 3;

const heightPageHeader = 101;

const FlexBox = styled.div`
  display: flex;
`;
const SubTitle = styled.h1`
  padding-left: 2%;
  font-weight: bold;
`;
const SaveChargesButton = styled(Button)`
  margin-top: 3%;
`;

export const saveChargesButtonDataTestId = 'saveChargesButton';

export interface ViewInvoiceProps {
  invoiceId: string;
  showHeader?: boolean;
  onDeleteNavigateToRoute?: string;
  afterInvoiceDeletedCallback?: () => void;
}

export const ViewInvoice: React.FC<ViewInvoiceProps> = ({
  invoiceId,
  showHeader = true,
  onDeleteNavigateToRoute,
  afterInvoiceDeletedCallback,
}) => {
  const { navigateBack, navigateTo } = useNavigationToRoute();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();

  const [updateInvoice, { client }] = useUpdateInvoice(invoiceId);
  const [updateInvoiceServiceNote] = useUpdateInvoiceForServiceNotes();
  const [updateInvoiceWithMessage] = useMutationWithMessages(useUpdateInvoice);
  const [updateInvoiceWithContactWithMessage] = useMutationWithMessages(useUpdateInvoiceWithContactData);

  const deleteInvoice = useDeleteMutationWithMessages(useDeleteInvoice, organizationId);
  const deleteServiceRendered = useDeleteServiceRendered(organizationId);

  const { state, dispatch } = useContext(ServicesRenderedContext);
  const { windowHeight } = useWindowDimensions();
  const { invoice, invoiceLoading, invoiceRefetch } = useGetInvoice(
    {
      invoiceId,
      organizationId,
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      subscribe: true,
    }
  );
  const prescriptions = useMemo(
    () => flatten(invoice?.item?.map((item) => [...(item.prescriptions ?? [])])),
    [invoice?.item]
  );

  const { ledgers, refetchLedgers } = useGetInvoiceLedger({ invoiceId, organizationId });
  const { patient, patientLoading } = useGetPatient(invoice?.patient_id ?? '', organizationId);

  const { prescriptions: patientPresciptions } = useGetPrescriptions(
    organizationId,
    { id: patient?.id || '' },
    OwnerType.Patient,
    {
      returnHidden: false,
    }
  );

  const practice = organization?.practice.find(({ id }) => id === organization.default_practice_id);

  const [activeUnsavedServiceId, setActiveUnsavedServiceId] = useState<string | null>(null);
  const [activeServiceId, setActiveServiceId] = useState<string | null>(null);
  const [saveProps, setSaveProps] = useState<SaveInvoiceProps>();
  const [changesMade, setChangesMade] = useState(false);
  const [reportFooter, setReportFooter] = useState<string | undefined>();

  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [printInvoiceReportModalVisible, setPrintInvoiceReportModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const { isOnline, canUseCollection } = useOffline('invoice');
  const offlineUpdate = useInvoiceOfflineUpdate(invoiceId);

  const servicesRendered = useMemo(() => sortServicesByDateAndSortOrder([...(invoice?.item || [])]), [invoice]);
  const [unsavedServicesRendered, setUnsavedServicesRendered] = useState<ServiceRendered[]>([]);
  const [hideUnsavedServicesChargesSection, setHideUnsavedServicesChargesSection] = useState(false);
  const [parentDeletedServiceRendered, setParentDeletedServiceRendered] = useState<ServiceRendered>();
  const showUnsavedChargesSection = !isEmpty(unsavedServicesRendered) && !hideUnsavedServicesChargesSection;
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [taxonomyModalVisible, setTaxonomyModalVisible] = useState(false);
  const [skipUseGetInvoiceLedgerCall, setSkipUseGetInvoiceLedgerCall] = useState(true);

  const [hasHISAWarningAlreadyShown, setHasHISAWarningAlreadyShown] = useState(false);

  const params = useMemo(() => ({ id: invoice?.patient_id ?? '' }), [invoice]);
  const [skipGetReminders, setSkipGetReminders] = useState(true);
  const { refetch: refetchReminders } = useGetReminders(organizationId, {
    params,
    type: OwnerType.Patient,
    skip: skipGetReminders,
  });
  const [unsavedPrescriptions, setUnsavedPrescriptions] = useState<PrescriptionFlattened[]>([]);
  const [labRequests, setLabRequests] = useState<LabRequest[]>();
  const serviceTextCollection = useRxCollection<RxInfoText>('service_text');
  const offlineServices = useMemo(() => (invoice as RxInvoice)?.offline_services ?? [], [invoice]);

  const offlinePatientUpdate = usePatientOfflineUpdate(invoice?.patient_id ?? '');
  const [updatePatient] = useUpdatePatient();

  const { state: ViewInvoiceState, dispatch: dispatchViewInvoice } = useContext(ViewInvoiceContext);
  const isBillToOpen = getIsBillToOpen(ViewInvoiceState);

  const refreshUnsavedPrescriptions = (services: ServiceRendered[]) => {
    const servicesWithPrescription = services.filter((service) => service.prescriptions);
    const newPrescriptions: PrescriptionFlattened[] = servicesWithPrescription.map((service) => ({
      filled_service_rendered_id: service.id,
      id: service.id,
      organization_id: organizationId,
      practice_id: service.prescriptions?.[0].practice_id || '',
      patient_id: service.prescriptions?.[0].patient_id || '',
      contact_id: service.prescriptions?.[0].contact_id || '',
      item_quantity: service.prescriptions?.[0].item_quantity || '0',
      fill_externally: service.prescriptions?.[0].fill_externally || false,
      created_type_id: service.prescriptions?.[0].created_type_id || PrescriptionType.Invoice,
      prescription_expiry_date: service.prescriptions?.[0].prescription_expiry_date || dayjs().format(formats.date),
      prescribed_date: service.prescriptions?.[0].prescribed_date || dayjs().format(formats.date),
      prescribed_user_id: service.prescriptions?.[0].prescribed_user_id || '',
      created: service.prescriptions?.[0].created || dayjs().format(formats.date),
      created_user_id: service.prescriptions?.[0].created_user_id || '',
      updated: service.prescriptions?.[0].updated || dayjs().format(formats.date),
      hidden: service.prescriptions?.[0].hidden || false,
      is_filled: service.prescriptions?.[0].is_filled || false,
      available_fills: service.prescriptions?.[0].available_fills || 0,
      filled_fills: service.prescriptions?.[0].filled_fills || 0,
      remaining_fills: service.prescriptions?.[0].remaining_fills || 0,
      ...service.prescriptions?.[0],
    }));
    setUnsavedPrescriptions(newPrescriptions);
  };

  useEffect(() => {
    if (!isOnline && !prescriptions.length) {
      refreshUnsavedPrescriptions(offlineServices);
    }
    // eslint-disable-next-line
  }, [prescriptions, isOnline, offlineServices]);

  useEffect(() => {
    if (!isOnline) {
      setUnsavedServicesRendered((previous) => uniqBy([...previous, ...offlineServices], 'id'));
    } else {
      const offlineServiceIds = offlineServices.map(({ id }) => id);
      setUnsavedServicesRendered((previous) => previous.filter(({ id }) => !offlineServiceIds.includes(id)));
    }
  }, [isOnline, offlineServices]);

  useEffect(() => {
    if (parentDeletedServiceRendered) {
      const updatedServices = unsavedServicesRendered.map((sr) => {
        if (sr.grouping_id === parentDeletedServiceRendered.grouping_id && !sr.is_parent) {
          return { ...sr, grouping_id: null };
        }
        return sr;
      });
      setUnsavedServicesRendered(updatedServices);
    }
    setParentDeletedServiceRendered(undefined);
  }, [parentDeletedServiceRendered, unsavedServicesRendered]);

  const onRowMove = useCallback(
    async (oldIndex: number, newIndex: number) => {
      if (oldIndex === newIndex) {
        return;
      }

      if (!isItemReorderAllowed(servicesRendered, oldIndex, newIndex)) {
        showWarningMessage(translations.invoicePage.invalidItemMove);
        return;
      }

      const servicesRenderedWithSameDate = servicesRendered.filter((sr) => sr.date === servicesRendered[oldIndex].date);
      const indexOfFirstItemWithSameDate = servicesRendered.findIndex(
        (sr) => sr.date === servicesRendered[oldIndex].date
      );
      const mappedOldIndex = oldIndex - indexOfFirstItemWithSameDate;
      const mappedNewIndex = newIndex - indexOfFirstItemWithSameDate;

      let servicesRenderedWithUpdatedSortOrder = moveItemsWithSortOrder(
        servicesRenderedWithSameDate,
        mappedOldIndex,
        mappedNewIndex
      ) as ServiceRendered[];

      servicesRenderedWithUpdatedSortOrder = servicesRenderedWithUpdatedSortOrder.filter((newServiceRendered) => {
        const existingServiceRendered = servicesRendered.find(({ id }) => newServiceRendered.id === id);
        return existingServiceRendered?.sort_order !== newServiceRendered.sort_order;
      });

      const invoiceUpsert = getInvoiceUpsertForUpdatingMultipleRenderedService(
        invoiceId,
        servicesRenderedWithUpdatedSortOrder,
        upsertDateFormat
      );
      if (!canUseCollection) {
        await updateInvoice({
          variables: {
            organizationId,
            invoice: invoiceUpsert,
          },
          optimisticResponse: () => {
            return getOptimisticResponseForServiceRenderedChanges(
              client.cache,
              servicesRenderedWithUpdatedSortOrder,
              invoice!
            );
          },
        });
      } else {
        await offlineUpdate?.(invoiceUpsert, []);
      }
    },
    [servicesRendered, updateInvoice, canUseCollection, offlineUpdate, invoiceId, organizationId, client.cache, invoice]
  );

  useEffect(() => {
    if (unsavedServicesRendered.length === 0) {
      setUnsavedChanges(false);
    }
  }, [unsavedServicesRendered]);

  if (invoiceLoading || patientLoading) {
    return <Loading />;
  }

  if (!invoice) {
    return (
      <FlexContainer alignItems='center' direction='column'>
        <p>{translations.invoicePage.loadingError}</p>
        <Button type='primary' onClick={() => window.location.replace(window.location.href)}>
          {translations.invoicePage.refresh}
        </Button>
      </FlexContainer>
    );
  }

  const { contact: invoiceContacts } = invoice;
  const practiceHideDiscount = getPracticeSetting(PracticeSettingsNameKey.InvoiceHideDiscount, practice);
  const practiceHideDiscountValue = practiceHideDiscount?.value === 'true';

  const addServices = async () => {
    const invoiceUpsert = getInvoiceUpsertForAddServices(invoice!.id, unsavedServicesRendered, upsertDateFormat);
    try {
      const shouldDeceasePatient = state.deceasePatientInfo.patientId !== undefined && patient !== undefined;
      const patientUpsert = {
        id: state.deceasePatientInfo.patientId,
        record: {
          name: patient?.name,
          name_2: patient?.name_2,
          name_3: patient?.name_3,
          species_id: patient?.species_id,
          gender_id: patient?.gender_id,
          breed_id: patient?.breed_id,
          color_id: patient?.color_id,
          dob: patient?.dob,
          deceased: true,
          deceased_date: state.deceasePatientInfo.deceaseDate
            ? state.deceasePatientInfo.deceaseDate
            : patient?.deceased_date,
          inactive: patient?.inactive,
          created_practice_id: patient?.created_practice_id,
          offline_id: patient?.offline_id,
        },
      } as PatientUpsert;

      if (!canUseCollection) {
        if (shouldDeceasePatient) {
          await updatePatient({
            variables: {
              organizationId,
              patient: patientUpsert,
            },
          });
        }

        const { data } = await updateInvoice({
          variables: {
            organizationId,
            invoice: invoiceUpsert,
          },
          optimisticResponse: () => {
            setHideUnsavedServicesChargesSection(true);
            return getOptimisticResponseForServiceRenderedChanges(client.cache, unsavedServicesRendered, invoice);
          },
          onError: (e) => {
            showErrorMessage((e as Error).message ?? translations.shared.saveErrorMessage);
            setHideUnsavedServicesChargesSection(false);
          },
          onCompleted: () => {
            setSkipGetReminders(false);
            dispatch(setIsSavingAction());
            refetchReminders();
            setUnsavedServicesRendered([]);
            setUnsavedPrescriptions([]);
          },
        });
        if (data?.upsertInvoice) {
          runLabCheck(data.upsertInvoice);
        }
      } else {
        if (shouldDeceasePatient) {
          await offlinePatientUpdate?.(patientUpsert);
        }
        const rxInvoice = await offlineUpdate?.(invoiceUpsert, unsavedServicesRendered, 'add');
        setUnsavedServicesRendered((rxInvoice as RxInvoice)?.offline_services ?? []);
        refreshUnsavedPrescriptions((rxInvoice as RxInvoice)?.offline_services ?? []);
      }
      dispatch(addPatientDeceaseInfo({} as DeceasePatientsInfo));
      setActiveUnsavedServiceId(null);
      const consumedPresciptions = patientPresciptions
        ?.filter((p) =>
          unsavedServicesRendered.some(
            (s) =>
              s.service_id === p.service_id &&
              !p.is_filled &&
              p.remaining_fills &&
              s.prescriptions?.some((sp) => Number(sp.item_quantity) === Number(p.item_quantity))
          )
        )
        .map((p) => p.number);
      let successMessage = translations.shared.saveSuccessMessage;
      if (consumedPresciptions?.length) {
        const messageRxs = Array.from(new Set(consumedPresciptions));
        successMessage += translations.invoicePage.fillConsumed + messageRxs.join(', ');
      }
      showSuccessMessage(successMessage);
      setUnsavedChanges(false);
    } catch (e) {
      showErrorMessage((e as Error).message ?? translations.shared.saveErrorMessage);
    } finally {
      dispatch(clearIsSavingAction());
      setHideUnsavedServicesChargesSection(false);
    }
  };

  const updateServiceRendered: UpdateServiceRenderedType = async (
    serviceRendered: ServiceRendered,
    options?: Partial<MutationHookOptions>
  ) => {
    const upsert = getInvoiceUpsertForUpdatingRenderedService(serviceRendered, upsertDateFormat);
    const result = await updateInvoice({
      variables: {
        organizationId,
        invoice: upsert,
      },
      optimisticResponse: () => {
        return getOptimisticResponseForServiceRenderedChanges(client.cache, [serviceRendered], invoice);
      },
      onCompleted: (data) => {
        options?.onCompleted?.(data);
        showSuccessMessage(translations.shared.saveSuccessMessage);
      },
      onError: (e) => {
        options?.onError?.(e);
        showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
        dispatch(clearIsSavingAction());
      },
    });
    return result;
  };

  const handleDeleteServiceRendered = async (serviceRendered: ServiceRendered) => {
    try {
      const ungroupedServicesRenderedUpserts: ServiceRenderedUpsert[] = [];
      const ungroupedServicesRendered: ServiceRendered[] = [{ ...serviceRendered, hidden: true }];
      if (serviceRendered.is_parent) {
        servicesRendered.forEach(({ id, grouping_id, is_parent }) => {
          if (grouping_id === serviceRendered.grouping_id && !is_parent) {
            ungroupedServicesRenderedUpserts.push({ id, groupRecord: { grouping_id: null, is_parent: false } });
          }
        });
      }
      await deleteServiceRendered(invoiceId, serviceRendered.id, ungroupedServicesRenderedUpserts, {
        optimisticResponse: () => {
          return getOptimisticResponseForServiceRenderedChanges(client.cache, ungroupedServicesRendered, invoice);
        },
      });
      showSuccessMessage(translations.renderedServicesTable.deleteSuccessMessage(serviceRendered.name));
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
  };

  const handleDeleteInvoice = async () => {
    dispatch(setEditingServiceRenderedIdAction(undefined));
    dispatch(setIsSavingAction(translations.invoicePage.deletingInvoice));
    await deleteInvoice({
      entityId: invoice.id,
      successMessage: translations.invoicePage.deleteInvoiceSuccessMessage,
      onSuccess: () => {
        flushSync(() => {
          dispatch(clearIsSavingAction());
        });
        navigateTo(onDeleteNavigateToRoute || routes.invoices);
        afterInvoiceDeletedCallback?.();
      },
    });
    dispatch(clearIsSavingAction());
  };

  const toggleInvoiceCompleted = async (checked: boolean) => {
    dispatch(setIsSavingAction());

    const invoiceUpsert: InvoiceUpsert = {
      id: invoice.id,
      statusRecord: {
        status_id: checked ? InvoiceStatusId.Closed : InvoiceStatusId.InProgress,
      },
    };

    const successMessage = checked ? translations.invoicePage.invoiceCompleted : translations.invoicePage.invoiceOpened;
    await updateInvoiceWithMessage({
      options: { variables: { organizationId, invoice: invoiceUpsert } },
      successMessage,
    });

    await refetchLedgers();

    dispatch(clearIsSavingAction());
  };

  const runLabCheck = (invoice: Invoice) => {
    const labs: LabRequest[] = [];

    for (const item of invoice?.item ?? []) {
      let requiresTaxonomyMap = false;

      for (const map of invoice.labTaxonomyMapping ?? []) {
        if (map?.requires_breed_map || map?.requires_gender_map || map?.requires_species_map) {
          requiresTaxonomyMap = true;
          setTaxonomyModalVisible(true);
          break;
        }
      }

      for (const map of invoice.thirdPartyTaxonomyMapping ?? []) {
        if (map.requires_gender_map) {
          requiresTaxonomyMap = true;
          setTaxonomyModalVisible(true);
          break;
        }
      }

      if (requiresTaxonomyMap) {
        break;
      }

      if (item.lab_request) {
        for (const request of item.lab_request) {
          if (request.status_id === LabRequestStatusId.Draft && request.lab_request_url) {
            labs.push(request);
          }
        }
      }
    }

    if (labs.length) {
      setLabRequests(labs);
    }
  };

  const isCompleted = invoice ? isCompletedInvoiceStatus(invoice.status_id) : false;

  const tableFooterHeight = 2 * footerPadding + ((invoice.tax ?? []).length + 2) * footerLineHeight;
  const tableFooter = () => (
    <FlexBox>
      <FlexContainer direction='column'>
        <ViewInvoiceFooter
          handleDeleteInvoice={handleDeleteInvoice}
          deleteInvoiceDisabled={!invoiceIsDeletable(invoice.status_id)}
          isCompleted={isCompleted}
          toggleInvoiceCompleted={toggleInvoiceCompleted}
          completeInvoiceDisabled={servicesRendered.length === 0}
        />
        {(invoice.contact_ids ?? []).length > 1 && isEmpty(unsavedServicesRendered) && !isNaN(+invoice.id) && (
          <Card style={{ marginTop: '4px' }} title={translations.invoicePage.splitTotalsHeader}>
            {getInvoiceContactTags(invoice, ledgers ?? [])}
          </Card>
        )}
      </FlexContainer>
      <TotalsAndTaxes
        isEditing={!!state.editingServiceRenderedId}
        hasPendingCharges={!isEmpty(unsavedServicesRendered)}
        servicesRendered={state.servicesRendered}
        unsavedServicesRendered={unsavedServicesRendered}
        invoice={invoice}
        onEmailClick={() => setEmailModalVisible(true)}
        onPrintClick={() => setPrintInvoiceReportModalVisible(true)}
        onPaymentClick={
          invoiceCanTakePayment(invoice.status_id) && Number(invoice.total) > 0
            ? () => {
                setPaymentModalVisible(true);
                setSkipUseGetInvoiceLedgerCall(false);
              }
            : undefined
        }
      />
    </FlexBox>
  );

  const heightAddService = getHeightOfElementWithId('AddServiceForm');
  const heightBelowTable = tableFooterHeight;
  const heightAboveTable = heightMainMenu + heightPageHeader + heightAddService + heightTableHeader;
  const heightNotUsedByTable = heightAboveTable + heightBelowTable + heightOfScrollbar;
  const tableHeight = windowHeight - heightNotUsedByTable;

  const sendNoteUpsert = async (noteRequest: NoteChangeRequest) => {
    const invoiceUpsert = getInvoiceUpsertForNoteChange(noteRequest, upsertDateFormat);
    await updateInvoiceServiceNote({
      variables: {
        organizationId,
        invoice: invoiceUpsert,
      },
    });
    showSuccessMessage(
      noteRequest.requestType === NoteChangeRequestType.DELETE
        ? translations.shared.deleteSuccessMessage
        : translations.shared.saveSuccessMessage
    );
  };

  const handleSave = async () => {
    dispatch(setIsSavingAction());
    if (saveProps) {
      const { patientWithOwnershipAndRelationship, billTo, invoiceStakeholders, patientContactRelationMap } = saveProps;
      const ownershipCurrent = patientWithOwnershipAndRelationship?.ownership_current;

      let normalizedSelectedContacts: RelatedOwner[];
      let selectedContactIds: string[];
      switch (billTo) {
        case PatientContactRelationTypeNameKey.Owner:
          if (!ownershipCurrent) {
            showErrorMessage(translations.invoicesPage.addInvoiceModal.noOwnershipError);
            return;
          }
          normalizedSelectedContacts = ownershipCurrent?.owner;
          selectedContactIds = ownershipCurrent.owner.map(({ contact_id }) => contact_id ?? '');
          break;

        case currentTabLabel:
          normalizedSelectedContacts = invoiceStakeholders;
          selectedContactIds = invoiceStakeholders.map(({ contact_id }) => contact_id ?? '');
          break;

        default:
          const billToRelatedContact = patientContactRelationMap[billTo];
          normalizedSelectedContacts = [
            {
              contact_id: billToRelatedContact.contact_id,
              percentage: '100',
              primary: true,
            },
          ];
          selectedContactIds = [billToRelatedContact.contact_id ?? ''];
          break;
      }

      const contactUpserts: InvoiceContactUpsert[] = [];

      invoiceContacts?.forEach(({ id, contact_id, percentage, primary }) => {
        // voids original contacts that were not found in the selectedContacts
        if (!selectedContactIds.includes(contact_id)) {
          return contactUpserts.push({ id, void: true });
        }

        const changedStakeholder = normalizedSelectedContacts.find(
          ({ contact_id: cId, percentage: pct, primary: prim }) => {
            return contact_id === cId && (percentage !== pct || primary !== prim);
          }
        );

        if (changedStakeholder) {
          contactUpserts.push({ id, void: true });
        }
        return undefined;
      });

      if (contactUpserts.length > 0) {
        normalizedSelectedContacts.forEach(({ id, contact_id, offline_contact_id, percentage, primary }) => {
          if (!contactUpserts.find((x) => x.id === id) && billTo === currentTabLabel) {
            if (id !== undefined) {
              contactUpserts.push({ id, void: true });
            }
          }

          contactUpserts.push({
            record: {
              contact_id,
              offline_contact_id,
              percentage: percentage ?? '0',
              primary: primary ?? false,
            },
          });
        });

        if (!canUseCollection) {
          await updateInvoiceWithContactWithMessage({
            options: {
              variables: {
                organizationId,
                invoice: {
                  id: invoice.id,
                  contact: contactUpserts,
                },
              },
            },
            successMessage: translations.shared.saveSuccessMessage,
          });
        } else {
          await offlineUpdate?.(
            {
              id: invoice.id,
              contact: contactUpserts,
            },
            unsavedServicesRendered
          );
        }
      }
    }
    dispatch(clearIsSavingAction());
    dispatchViewInvoice(setIsBillToOpen(false));
  };

  const updateServicesRendered = (unsavedServiceRendered: ServiceRendered) => {
    return unsavedServicesRendered.map((existingService) => {
      if (existingService.id === unsavedServiceRendered.id) {
        return unsavedServiceRendered;
      }
      return existingService;
    });
  };

  const handleSetUnsavedServicesRendered = async (action: string, unsavedServiceRendered: ServiceRendered) => {
    switch (action) {
      case 'add':
        setUnsavedServicesRendered((prevState) => {
          const updatedServicesRendered = sortServicesByDateAndSortOrder([...prevState, unsavedServiceRendered]);
          refreshUnsavedPrescriptions(updatedServicesRendered);
          return updatedServicesRendered;
        });
        break;
      case 'update': {
        const updatedServicesRendered = sortServicesByDateAndSortOrder(updateServicesRendered(unsavedServiceRendered));
        refreshUnsavedPrescriptions(updatedServicesRendered);
        setUnsavedServicesRendered(updatedServicesRendered);
        break;
      }
      default: {
        let updatedServicesRendered = unsavedServicesRendered.filter(
          (existingService) => existingService.id !== unsavedServiceRendered.id
        );
        refreshUnsavedPrescriptions(updatedServicesRendered);
        if (canUseCollection) {
          if (unsavedServiceRendered.is_parent) {
            updatedServicesRendered = updatedServicesRendered.map((updated) => {
              if (updated.grouping_id === unsavedServiceRendered.grouping_id) {
                return { ...updated, grouping_id: undefined };
              }
              return updated;
            });
          }
          const invoiceUpsert = getInvoiceUpsertForAddServices(invoice!.id, updatedServicesRendered, upsertDateFormat);
          await offlineUpdate?.(invoiceUpsert, updatedServicesRendered, action, unsavedServiceRendered);
          setUnsavedChanges(false);
        }
        setUnsavedServicesRendered(updatedServicesRendered);
      }
    }
  };

  const addUnsavedService: ModifyAndViewServicesRenderedProps['saveNewService'] = async ({
    doctor,
    services,
    businessCenterId,
    locationId,
  }: {
    doctor: Caregiver;
    services: NewService[];
    businessCenterId: string;
    locationId: string;
  }) => {
    const grouping_id = uuid();
    const newServices = services.map(
      ({
        formValues,
        service,
        vaccineSpecificValues,
        controlledDrugSpecificValues,
        reminderSpecificValues,
        prescriptionSpecificValues,
        quantity,
        roaId,
        roaOther,
        reason,
        withdrawal_period,
        hisaValues,
      }) => {
        const bundleParentId = services.find(({ service: s }) => s.bundled_service_ids?.length)?.service.id;

        const bundleProperties = bundleParentId
          ? {
              is_parent: bundleParentId === service.id,
              grouping_id,
            }
          : undefined;

        return createNewServiceForListView(
          formValues,
          service,
          doctor,
          businessCenterId,
          locationId,
          invoice.patient_id ?? '',
          service.vaccine ? vaccineSpecificValues : controlledDrugSpecificValues,
          reminderSpecificValues,
          prescriptionSpecificValues,
          quantity,
          roaId,
          roaOther,
          service.microchip,
          service.rabies_tag_prompt,
          service.default_note_id ?? undefined,
          reason,
          withdrawal_period,
          practiceHideDiscountValue,
          bundleProperties,
          hisaValues
        );
      }
    );

    const newServiceDate = newServices[0].date;

    let minSortOrderForDate = [...unsavedServicesRendered, ...servicesRendered]
      .filter((sr) => sr.date === newServiceDate)
      .reduce((minSortOrder, { sort_order }) => Math.min(minSortOrder, sort_order), 0);

    for (const i in services) {
      const service = services[i]?.service;
      minSortOrderForDate -= 10000;
      newServices[i].sort_order = minSortOrderForDate;
      if (service?.default_note_id) {
        const fillNote = (defaultNote: DefaultNote) => {
          // verify if defaultNote is a valid DefaultNote type value
          if (defaultNote.noteTypeId && defaultNote.noteText && defaultNote.noteTypeName) {
            newServices[i].note_id = dummyNoteIdForRapidBilling;
            newServices[i].note = getNote({
              requestType: NoteChangeRequestType.CREATE,
              noteUpsertValues: {
                type_id: defaultNote.noteTypeId,
                textContent: defaultNote.noteText,
                plainTextContent: defaultNote.notePreview,
                date: dayjs(),
              },
              service: newServices[i],
            });
          }
        };

        if (!canUseCollection) {
          await getServiceText({ id: service.default_note_id, organizationId }, (infoText) => {
            if (!infoText.value) {
              return;
            }
            try {
              const defaultNote: DefaultNote = JSON.parse(infoText?.value);
              fillNote(defaultNote);
            } catch {}
          });
        } else {
          try {
            await serviceTextCollection
              ?.findOne(service.default_note_id)
              .exec()
              .then((text) => {
                if (!text?.value) {
                  return;
                }
                const defaultNote: DefaultNote = JSON.parse(text?.value);
                fillNote(defaultNote);
              });
          } catch {}
        }
      }
    }

    newServices.forEach((service) => {
      handleSetUnsavedServicesRendered('add', service);
      setActiveUnsavedServiceId(service.id);
    });
    setUnsavedChanges(true);
    return newServices[0];
  };

  const updateUnsavedServiceRendered = (updatedServiceRendered: ServiceRendered) => {
    handleSetUnsavedServicesRendered('update', updatedServiceRendered);
    setUnsavedChanges(true);
  };

  const deleteUnsavedServiceRendered = (serviceRendered: ServiceRendered) => {
    if (serviceRendered.is_parent) {
      setParentDeletedServiceRendered(serviceRendered);
    }
    handleSetUnsavedServicesRendered('delete', serviceRendered);
    setActiveUnsavedServiceId(null);
  };

  const getNote = (noteChangeRequest: NoteCreateRequest | NoteUpdateRequest) =>
    ({
      id: dummyNoteIdForRapidBilling,
      type_id: noteChangeRequest.noteUpsertValues.type_id,
      date: noteChangeRequest.noteUpsertValues.date.format(formats.date),
      hidden: false,
      locked: false,
      value: noteChangeRequest.noteUpsertValues.textContent,
      preview: createPreviewTextForNote(noteChangeRequest.noteUpsertValues.plainTextContent),
    } as Note);

  const changeUnsavedNote = (noteChangeRequest: NoteChangeRequest) => {
    const newService: ServiceRendered = { ...noteChangeRequest.service };
    switch (noteChangeRequest.requestType) {
      case NoteChangeRequestType.CREATE:
      case NoteChangeRequestType.UPDATE:
        newService.note_id = dummyNoteIdForRapidBilling;
        newService.note = getNote(noteChangeRequest);
        break;
      case NoteChangeRequestType.DELETE:
        newService.note_id = undefined;
        newService.note = undefined;
        break;
    }

    handleSetUnsavedServicesRendered('update', newService);
    if (canUseCollection) {
      setUnsavedChanges(true);
    }
  };

  const handleChargeDuplicationCheck = (formValues: AddServiceFormValues) => {
    const existingCharges: ServiceRendered[] = [...servicesRendered, ...unsavedServicesRendered];
    const formService = formValues[serviceFieldName];
    const formDate = formValues[dateFieldName]?.format(upsertDateFormat);
    return existingCharges.some((charge) => charge.date === formDate && charge.service_id === formService);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={state.savingState.isSaving}
      showNavigationWarning={!!state.editingServiceRenderedId || unsavedChanges || state.savingState.isSaving}
      savingMessage={state.savingState.customSavingMessage}
    >
      {showHeader && (
        <ViewInvoiceHeader
          onBack={navigateBack}
          invoice={invoice}
          onModalOpen={() => dispatchViewInvoice(setIsBillToOpen(true))}
        />
      )}
      {showUnsavedChargesSection && <SubTitle>{translations.invoicePage.unsavedCharges}</SubTitle>}
      {!isCompleted && (
        <ModifyAndViewServicesRendered
          practiceId={practice?.id || ''}
          initialServicesRendered={unsavedServicesRendered}
          saveNewService={addUnsavedService}
          updateServiceRendered={updateUnsavedServiceRendered}
          deleteServiceRendered={deleteUnsavedServiceRendered}
          activeServiceId={activeUnsavedServiceId}
          setActiveServiceId={setActiveUnsavedServiceId}
          tableHeight={tableHeight}
          editable
          hideTable={!showUnsavedChargesSection}
          changeNote={changeUnsavedNote}
          patientId={invoice.patient_id ?? ''}
          invoiceContacts={invoiceContacts ?? []}
          prescriptions={unsavedPrescriptions}
          isUnchargedServices
          onChargeDuplicationCheck={handleChargeDuplicationCheck}
          hasHISAWarningAlreadyShown={hasHISAWarningAlreadyShown}
          setHasHISAWarningAlreadyShown={setHasHISAWarningAlreadyShown}
        />
      )}
      {((showUnsavedChargesSection && isOnline) || (unsavedChanges && !hideUnsavedServicesChargesSection)) && (
        <>
          <Row>
            <Col offset={11} span={2}>
              <SaveChargesButton
                disabled={!!state.editingServiceRenderedId}
                type={'primary'}
                onClick={addServices}
                data-testid={saveChargesButtonDataTestId}
              >
                {translations.invoicePage.saveCharges}
              </SaveChargesButton>
            </Col>
          </Row>
          <hr />
        </>
      )}
      {showUnsavedChargesSection && <SubTitle>{translations.invoicePage.existingCharges}</SubTitle>}
      <ModifyAndViewServicesRendered
        practiceId={practice?.id || ''}
        initialServicesRendered={servicesRendered}
        updateServiceRendered={updateServiceRendered}
        deleteServiceRendered={handleDeleteServiceRendered}
        tableFooter={tableFooter}
        editable={!isCompleted && isOnline}
        hideAddServiceForm
        tableHeight={tableHeight}
        activeServiceId={activeServiceId}
        setActiveServiceId={setActiveServiceId}
        changeNote={sendNoteUpsert}
        patientId={invoice.patient_id ?? ''}
        invoiceContacts={invoiceContacts ?? []}
        prescriptions={prescriptions}
        refetchPrescriptions={invoiceRefetch}
        showHeader={!showUnsavedChargesSection}
        onRowMove={onRowMove}
        labIconCallback={() => runLabCheck(invoice)}
        hasHISAWarningAlreadyShown={hasHISAWarningAlreadyShown}
        setHasHISAWarningAlreadyShown={setHasHISAWarningAlreadyShown}
      />
      {isOnline && <InvoiceFooter invoiceId={invoice.id} onChange={setReportFooter} />}

      {patient ? (
        <ModalWithCloseConfirm
          title={translations.invoicePage.editBillingModal.title}
          changesMade={changesMade}
          open={isBillToOpen}
          onCancel={() => dispatchViewInvoice(setIsBillToOpen(false))}
          onOk={handleSave}
          okText={translations.shared.okButtonText}
          okButtonProps={{
            disabled:
              saveProps &&
              saveProps.billTo === currentTabLabel &&
              calculateTotalPercentage(saveProps.invoiceStakeholders) !== 100,
          }}
        >
          <SaveSpinnerAndNavigationWarning
            isSaving={state.savingState.isSaving}
            showNavigationWarning={state.savingState.isSaving}
          >
            <Form layout={'vertical'} autoComplete='off'>
              <BillTo
                selectedPatient={patient}
                setSaveProps={setSaveProps}
                editableTabName={currentTabLabel}
                invoiceContacts={invoiceContacts ?? []}
                setChangesMade={setChangesMade}
                context='invoice'
              />
            </Form>
          </SaveSpinnerAndNavigationWarning>
        </ModalWithCloseConfirm>
      ) : null}
      {printInvoiceReportModalVisible && (
        <PrintInvoiceReportModal
          invoiceId={invoice.id}
          onClose={() => setPrintInvoiceReportModalVisible(false)}
          footer={reportFooter}
        />
      )}
      {emailModalVisible && (
        <EmailModalForInvoiceReport
          invoiceId={invoice.id}
          onClose={() => setEmailModalVisible(false)}
          footer={reportFooter}
        />
      )}
      {paymentModalVisible && (
        <InvoicePaymentModal
          onClose={() => {
            setPaymentModalVisible(false);
            setSkipUseGetInvoiceLedgerCall(true);
          }}
          invoiceId={invoice.id}
          onSuccess={() => invoiceRefetch()}
          skipUseGetInvoiceLedgerCall={skipUseGetInvoiceLedgerCall}
        />
      )}
      {labRequests && <LabRequestModal labRequests={labRequests} onClose={() => setLabRequests(undefined)} />}
      {taxonomyModalVisible && patient && (invoice.labTaxonomyMapping || invoice.thirdPartyTaxonomyMapping) && (
        <TaxonomyMappingModal
          labTaxonomy={invoice.labTaxonomyMapping ?? []}
          thirdPartyTaxonomy={invoice.thirdPartyTaxonomyMapping ?? []}
          handleCloseModal={(invoice?: Invoice) => {
            setTaxonomyModalVisible(false);
            if (invoice) {
              runLabCheck(invoice);
            }
          }}
          invoiceId={invoice.id}
        />
      )}
    </SaveSpinnerAndNavigationWarning>
  );
};
