import { gql } from '@apollo/client';

export const StatementContactSentFields = gql`
  fragment StatementContactSentFields on StatementContactSent {
    id
    name
    number
    contact_status_id
    contact_status_name
    contact_id
    email
    send_type
    sent
    stale
    last_send_error
    last_send_status
  }
`;

export const StatementContactPendingFields = gql`
  fragment StatementContactPendingFields on StatementContactPending {
    id
    name
    number
    contact_status_id
    contact_status_name
    contact_id
    email
    send_type
    starting_balance
    ending_balance
    transactions
  }
`;

export const StatementRunFields = gql`
  fragment StatementRunFields on StatementRun {
    id
    number
    start_date
    end_date
    pending {
      ...StatementContactPendingFields
    }
    sent {
      ...StatementContactSentFields
    }
  }
  ${StatementContactPendingFields}
  ${StatementContactSentFields}
`;

export const BasicStatementRunFields = gql`
  fragment BasicStatementRunFields on StatementRun {
    id
    start_date
    end_date
  }
`;
