import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { InvoicesOverviewContainer } from './InvoicesOverview/InvoicesOverviewContainer';
import { ServicesRenderedContextProvider } from '../../components/ServiceRendered/store/state';
import { ViewInvoiceContainer } from './ViewInvoice/ViewInvoiceContainer';
import { ViewSubscriptionContextProvider } from '../../components/ViewSubscription/store/state';
import { ViewInvoiceContextProvider } from './ViewInvoice/store/state';

export const Invoices: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={routes.viewInvoice}>
          <ServicesRenderedContextProvider>
            <ViewSubscriptionContextProvider>
              <ViewInvoiceContextProvider>
                <ViewInvoiceContainer />
              </ViewInvoiceContextProvider>
            </ViewSubscriptionContextProvider>
          </ServicesRenderedContextProvider>
        </Route>
        <Route path={`${match.path}`} component={InvoicesOverviewContainer} />
      </Switch>
    </>
  );
};
