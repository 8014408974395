import { Button, DatePicker, Form, Input, InputNumber, List, Popover, Select, Space, Table } from 'antd';
import React, { CSSProperties, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { InvoiceContact, PrescriptionFlattened, Service, ServiceRendered } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import { displayAsDate, upsertDateFormat } from '../../../constants/formats';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { SelectOptions } from '../../DependentDropdown/DependentDropdown';
import dayjs from 'dayjs';
import { ServicesRenderedContext } from '../store/state';
import {
  changeServicesRenderedAction,
  clearIsSavingAction,
  setClickedColumnAction,
  setConditionTreated,
  setEditingServiceRenderedIdAction,
  setServicesRenderedAction,
  setUnchargedServicesRenderedAction,
} from '../store/actions';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { isUpdatedServiceRenderedValid } from '../../../pages/Invoices/ViewInvoice/getInvoiceUpsertServiceRendered';
import { red } from '@ant-design/colors';
import { ColumnsType } from 'antd/lib/table';
import { ServiceRenderedTableActionCell } from './ActionCell/ServiceRenderedTableActionCell';
import { MenuItemProps } from '../../DropdownButtonWithMenu/DropdownButtonWithMenu';
import './ServicesRenderedTable.css';
import { marginFixForInlineEditing, paddingInInputField, tableCellPadding } from '../../../constants/tables';
import { NoteViewWrapper } from '../NoteView/NoteViewWrapper';
import { useAddServiceKeydownHandling } from './servicesRenderedTableHooks';
import { serviceRenderedColumns } from './serviceRenderedColumns';
import { InvoiceServiceProperty, invoiceServicePropertyNames } from '../../../constants/propertyNames';
import { VaccineModalContent, vaccineModalFormTestId } from '../../VaccineModalContent/VaccineModalContent';
import { NoteChangeRequest } from '../NoteView/noteChangeUtil';
import {
  ControlledDrugModalContent,
  controlledDrugModalFormTestId,
} from '../../ControlledDrugContent/ControlledDrugContent';
import { PatientBackupFunctions } from '../../../pages/RapidBilling/RapidBillingServices/RapidBillingServices';
import ClickableIcon, { IconType } from '../../Icons/ClickableIcon';
import { MAX_INPUT_AMOUNT, MIN_INPUT_QTY } from '../../../constants/referenceData/ledgerReferenceData';
import {
  ServiceOptionsFormValues,
  ServicePromptModalProps,
  successMessageOption,
} from '../AddServiceForm/AddServiceForm';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { MicrochipModalContent, microchipModalFormTestId } from '../../MicrochipModalContent/MicrochipModalContent';
import { useGetPatient, usePatientOfflineUpdate, useUpdatePatient } from '../../../hooks/ajax/patients/patientHooks';
import { PatientInfoType } from '../../../constants/referenceData/patientReferenceData';
import {
  getMicrochipUpsert,
  getServicePatientRequirements,
  getPatientMicrochipUpsertOptions,
  getPatientRabiesTagUpsertOptions,
  getRabiesTagUpsert,
  ROAOptions,
} from '../serviceRenderedUtils';
import styled from 'styled-components';
import PopoverNote from '../../PopoverNote/PopoverNote';
import { PriceValue } from '../../PriceValue/PriceValue';
import ModalWithCloseConfirm from '../../ModalWithCloseConfirm/ModalWithCloseConfirm';
import {
  ServiceSingleOptionModal,
  ServiceSingleOptionModalProps,
} from '../../ServiceSingleOptionModal/ServiceSingleOptionModal';
import { RoaModalContent, roaModalFormTestId } from '../../RoaModalContent/RoaModalContent';
import { ReminderFormFields, reminderFormTestId } from '../../ReminderFormFields/ReminderFormFields';
import {
  PrescriptionModalContent,
  prescriptionModalFormTestId,
} from '../../PrescriptionModalContent/PrescriptionModalContent';
import { useGetReminders } from '../../../hooks/ajax/reminders/reminderHooks';
import { OwnerType } from '../../../hooks/ajax/recordHooks';
import { TextEditor } from '../../TextEditor/TextEditor';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { draggableColumns, getDraggableComponents } from '../../Draggable/Draggable';
import { useOffline } from '../../../util/offline/offlineUtil';
import { RabiesTagModalContent, rabiesTagModalFormTestId } from '../../RabiesTagModalContent/RabiesTagModalContent';
import { LabRequestStatusId, labRequestStatusConfig } from '../../../constants/referenceData/labReferenceData';
import { ReasonModalContent, reasonModalFormTestId } from '../../ReasonModalContent/ReasonModalContent';
import {
  WithdrawalPeriodModalContent,
  withdrawalPeriodModalFormTestId,
} from '../../WithdrawalPeriodModalContent/WithdrawalPeriodModalContent';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import {
  discountAmountField,
  DiscountModalContent,
  discountModalFormTestId,
  discountPercentField,
  hideDiscountField,
  totalWithDiscountField,
} from '../../DiscountModalContent/DiscountModalContent';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { MutationHookOptions } from '@apollo/client';
import { ServiceRenderedWithLocalFields } from '../../../pages/Invoices/ViewInvoice/viewInvoiceUtil';
import { HisaRequirementsContent } from '../HisaRequirementsModal/HisaRequirementsContent';
import { ServiceThirdPartyId } from '../../../constants/referenceData/serviceReferenceData';
import {
  HisaFormField,
  HisaRequirementsNameKey,
  hisaFormFieldsConfig,
  HisaNameKeys,
} from '../../../constants/hisaRequirements';
import { PopoverWithHisaInfo } from '../../PopoverWithHisaInfo/PopoverWithHisaInfo';
import { getInitialHisaValuesFromServiceRendered } from './servicesRenderedUtil';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { usePrescriptionPrint } from '../../../hooks/usePrescriptionPrint';
import { useGetPrescriptions } from '../../../hooks/ajax/prescriptionHooks/prescriptionHooks';

export type UpdateServiceRenderedType = (
  serviceRendered: ServiceRendered,
  options?: Partial<MutationHookOptions>
) => Promise<any> | void;
export interface ServicesRenderedTableProps {
  initialServicesRendered: ServiceRendered[];
  doctorOptions: SelectOptions[];
  servicesList: Service[];
  addServiceFocusCallback: () => void;
  activeServiceId?: string | null;
  isTableEditable?: boolean;
  tableFooter?: () => ReactNode;
  updateServiceRendered: UpdateServiceRenderedType;
  deleteServiceRendered: (serviceRendered: ServiceRendered) => void;
  patientBackupFunctions?: PatientBackupFunctions;
  setActiveServiceId: (serviceId: string | null) => void;
  tableHeight: number;
  onlyAllowOnCellClickEditingOfActiveRow?: boolean;
  changeNote: (noteRequest: NoteChangeRequest) => void;
  patientId?: string;
  invoiceContacts?: InvoiceContact[];
  removedForRapidBilling?: boolean;
  prescriptions?: PrescriptionFlattened[];
  isUnchargedServices?: boolean;
  showHeader?: boolean;
  onRowMove?: (oldIndex: number, newIndex: number) => void;
  labIconCallback?: () => void;
}

const dateColumnWidth = 130;
const doctorColumnWidth = 150;
const descriptionColumnWidth = 300;
const unitColumnWidth = 80;
const quantityColumnWidth = 70;
const unitPriceColumnWidth = 110;
const totalColumnWidth = 110;
const actionsColumnWidth = 55;
const totalColumnMargins = 6 * 8;

export const widthUntilTotalColumn =
  dateColumnWidth +
  doctorColumnWidth +
  descriptionColumnWidth +
  unitColumnWidth +
  quantityColumnWidth +
  unitPriceColumnWidth +
  totalColumnWidth +
  actionsColumnWidth +
  totalColumnMargins;

export const TotalWidth = widthUntilTotalColumn + totalColumnWidth + totalColumnMargins;

const classNameForHighlightedRow = 'ServicesRenderedTable__Row--highlighted';
export const serviceRenderedVaccinationIconTestId = 'vaccinationIcon';
export const serviceRenderedControlledIconTestId = 'controlledIcon';
export const serviceReminderIconTestId = 'reminderIcon';
export const servicePrescriptionIconTestId = 'prescriptionIcon';
export const serviceMicrochipIconTestId = 'microchipIcon';
export const serviceRabiesTagIconTestId = 'rabiesTagIcon';
export const serviceNoteIconTestId = 'serviceNoteIcon';
export const roaIconTestId = 'roaIcon';
export const labIconTestId = 'labIcon';
export const reasonIconTestId = 'reasonIcon';
export const withdrawalIconTestId = 'withdrawalIcon';
export const discountIconTestId = 'discountIcon';

export const hisaRequirementsModalTestId = 'hisaRequirementsModalTestId';

export const servicesRenderedTableId = 'ServicesRenderedTable';
export const unchargedServicesRenderedTableId = 'UnchargedServicesRenderedTable';

export const MaxWidthListItem = styled(List.Item)`
  max-width: 300px;
  overflow-x: hidden;
  white-space: nowrap;
`;

const editActionCellStyle = {
  padding: 0,
};

const fixClassnameChangeOnPopover = (element: JSX.Element[] | JSX.Element) => {
  return <div style={{ display: 'inline-block' }}>{element}</div>;
};

export const ServicesRenderedTable: React.FC<ServicesRenderedTableProps> = ({
  initialServicesRendered,
  doctorOptions,
  servicesList,
  addServiceFocusCallback,
  activeServiceId,
  isTableEditable,
  tableFooter,
  updateServiceRendered,
  deleteServiceRendered,
  patientBackupFunctions,
  setActiveServiceId,
  tableHeight,
  onlyAllowOnCellClickEditingOfActiveRow,
  changeNote,
  patientId,
  invoiceContacts,
  removedForRapidBilling,
  prescriptions,
  isUnchargedServices,
  showHeader,
  onRowMove,
  labIconCallback,
}) => {
  const showRefills = useLDFlag(LDFlagNames.PrescriptionRefills);
  const { isOnline } = useOffline();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const { patient } = useGetPatient(patientId || '', organizationId);
  const [updatePatient] = useUpdatePatient();
  const { state, dispatch } = useContext(ServicesRenderedContext);
  const [showingNoteWithId, setShowingNoteWithId] = useState<string>();
  const [haveNoteValuesChanged, setHaveNoteValuesChanged] = useState<boolean>(false);
  const [serviceOfShownNote, setServiceOfShownNote] = useState<ServiceRendered>();
  const params = useMemo(() => ({ id: patientId ?? '' }), [patientId]);
  const { reminders } = useGetReminders(organizationId, { params, type: OwnerType.Patient });
  const { prescriptions: patientPresciptions } = useGetPrescriptions(organizationId, params, OwnerType.Patient, {
    returnHidden: false,
  });
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const { canUseCollection } = useOffline('patient');
  const offlinePatientUpdate = usePatientOfflineUpdate(patientId ?? '');
  const [form] = Form.useForm();

  const currentlyEditingServiceRendered = useRef(state.editingServiceRendered);

  const { openPrinterModal, renderPrintModal } = usePrescriptionPrint(patient);

  useEffect(() => {
    currentlyEditingServiceRendered.current = state.editingServiceRendered;
    const initialValues = {};
    getInitialHisaValuesFromServiceRendered(initialValues, currentlyEditingServiceRendered.current);
    setModalProps((prevState) => ({ ...prevState, initialValues }));
  }, [state]);

  const patientLinkedToHisa = useMemo(() => patient?.info?.find((i) => i.name_key === PatientInfoType.HISA), [patient]);

  const initialModalProps = {
    visible: false,
    initialValues: {},
    title: '',
    testId: '',
    children: null,
    form,
  };

  const [modalProps, setModalProps] = useState<ServicePromptModalProps & ServiceSingleOptionModalProps>(
    initialModalProps
  );

  const microchip = useMemo(
    () => patient?.info?.find((infoItem) => infoItem.name_key === PatientInfoType.Microchip),
    [patient]
  );

  const rabiesTag = useMemo(
    () => patient?.info?.find((infoItem) => infoItem.name_key === PatientInfoType.RabiesTag),
    [patient]
  );

  const currentServicesRendered = useMemo(
    () => (isUnchargedServices ? state.unchargedServicesRendered : state.servicesRendered),
    [state.servicesRendered, state.unchargedServicesRendered, isUnchargedServices]
  );

  const contacts = useMemo(
    () =>
      invoiceContacts?.map((contact) => ({
        value: contact.contact_id,
        label: contact.contact_name,
      })),
    [invoiceContacts]
  );

  const defaultReminderStatus = organization?.ref_patient?.reminder_status?.find(({ is_default }) => is_default);

  useEffect(() => {
    const servicesRendered = initialServicesRendered.map((service) =>
      service.id === state.editingServiceRenderedId ? getServiceWithUnsavedData(service) : service
    );
    handleSetServicesRendered(servicesRendered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialServicesRendered]);

  const handleSetServicesRendered = (servicesRendered: ServiceRendered[]) => {
    const action = isUnchargedServices ? setUnchargedServicesRenderedAction : setServicesRenderedAction;
    dispatch(action(servicesRendered));
  };

  const getServiceWithUnsavedData = (initialService: ServiceRendered): ServiceRendered => {
    const serviceInEditing = currentServicesRendered.find(
      (initialService) => initialService.id === state.editingServiceRenderedId
    );
    if (!serviceInEditing) {
      return initialService;
    }
    const { date, doctor_id, doctor_name, name, quantity, total, unit_name } = serviceInEditing;
    return { ...initialService, date, doctor_id, doctor_name, name, quantity, total, unit_name };
  };

  useEffect(() => {
    if (activeServiceId) {
      const elementToScrollTo: HTMLElement | null = document.querySelector(`.${classNameForHighlightedRow}`);
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    }
  }, [activeServiceId]);

  const checkIsTotalValid = (total: number) => {
    if (total < -MAX_INPUT_AMOUNT) {
      showErrorMessage(translations.renderedServicesTable.minTotalErrorMessage);
      return false;
    }

    if (total > MAX_INPUT_AMOUNT) {
      showErrorMessage(translations.renderedServicesTable.maxTotalErrorMessage);
      return false;
    }
    return true;
  };

  const saveService = async (serviceRendered: ServiceRendered | undefined) => {
    if (!serviceRendered) {
      return;
    }

    const total = +serviceRendered.total;
    if (total >= Number(serviceRendered.quantity) * Number(serviceRendered.list_unit_price)) {
      serviceRendered.discount = '0';
      serviceRendered.discount_percent = '0';
    }

    const isTotalValid = checkIsTotalValid(total);

    if (!isTotalValid) {
      return;
    }

    if (!isUpdatedServiceRenderedValid(serviceRendered)) {
      showErrorMessage(translations.renderedServicesTable.missingValuesErrorMessage);
      return;
    }

    try {
      dispatch(setEditingServiceRenderedIdAction(undefined));
      await updateServiceRendered(serviceRendered, {
        onError: () => {
          dispatch(setEditingServiceRenderedIdAction(serviceRendered.id));
        },
      });
      // addServiceFocusCallback needs to be called after resetting the is saving state, otherwise the service field will not be focused
      addServiceFocusCallback();
    } catch (e) {
      showErrorMessage((e as Error).message ?? translations.shared.saveErrorMessage);
      dispatch(clearIsSavingAction());
    }
  };

  const saveModalData = async (serviceRendered: ServiceRendered): Promise<{ success: boolean }> => {
    try {
      await updateServiceRendered(serviceRendered);

      // addServiceFocusCallback needs to be called after resetting the is saving state, otherwise the service field will not be focused
      addServiceFocusCallback();
      return { success: true };
    } catch (e) {
      showErrorMessage((e as Error).message ?? translations.shared.saveErrorMessage);
      return { success: false };
    }
  };

  const setInitialProps = () => {
    setModalProps(initialModalProps);
    form.setFieldValue('instructions', undefined);
    dispatch(setConditionTreated(''));
  };

  const sharedModalProps = {
    visible: true,
    onClose: setInitialProps,
    isNavWarningRedundant: isUnchargedServices,
    form,
    hasCancelOption: false,
  };

  const handleVaccineIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: {
        manufacturer: service.manufacturer_name || undefined,
        lotNumber: service.lot_number || undefined,
        serialNumber: service.serial_number || undefined,
        expiryDate: service.expiry_date ? dayjs(service.expiry_date, upsertDateFormat) : undefined,
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          manufacturer_name: value.manufacturer,
          lot_number: value.lotNumber,
          serial_number: value.serialNumber,
          expiry_date: value.expiryDate?.format(upsertDateFormat),
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.vaccineModal.title,
      testId: vaccineModalFormTestId,
      children: <VaccineModalContent />,
    });
  };

  const handleMicrochipIconClick = () => {
    setModalProps({
      ...sharedModalProps,
      initialValues: { microchip: microchip?.value || '' },
      onSave: async ({ microchip: microchipValue }) => {
        const options = getPatientMicrochipUpsertOptions(microchipValue, organization, patient);
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        try {
          if (!canUseCollection) {
            await updatePatient({ ...options, ...successMessageOption });
          } else {
            const patientUpsert = getMicrochipUpsert(microchipValue, organization, patient);
            offlinePatientUpdate?.(patientUpsert);
          }
          setInitialProps();
        } catch (e) {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.microchipModal.title,
      testId: microchipModalFormTestId,
      children: <MicrochipModalContent />,
    });
  };

  const handleRabiesTagIconClick = () => {
    setModalProps({
      ...sharedModalProps,
      initialValues: { rabiesTag: rabiesTag?.value || '' },
      onSave: async ({ rabiesTag: rabiesTagValue }) => {
        const options = getPatientRabiesTagUpsertOptions(rabiesTagValue, organization, patient);
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        try {
          if (!canUseCollection) {
            await updatePatient({ ...options, ...successMessageOption });
          } else {
            const patientUpsert = getRabiesTagUpsert(rabiesTagValue, organization, patient);
            offlinePatientUpdate?.(patientUpsert);
          }
          setInitialProps();
        } catch (e) {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.rabiesTagModal.title,
      testId: rabiesTagModalFormTestId,
      children: <RabiesTagModalContent />,
    });
  };

  const handleRoaIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: {
        roa_id: service.roa_id ?? '',
        roa_other: service.roa_other ?? '',
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          roa_id: value.roa_id,
          roa_other: value.roa_other,
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.roaModal.title,
      testId: roaModalFormTestId,
      children: <RoaModalContent visible />,
    });
  };

  const handleReasonIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: {
        reason: service.reason ?? '',
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          reason: value.reason,
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.reasonModal.title,
      testId: reasonModalFormTestId,
      children: <ReasonModalContent />,
    });
  };

  const handleWithdrawalIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: {
        withdrawal_period: service.withdrawal_period ?? 0,
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          withdrawal_period: value.withdrawal_period,
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.withdrawalModal.title,
      testId: withdrawalPeriodModalFormTestId,
      children: <WithdrawalPeriodModalContent />,
    });
  };

  const handleHisaRequirementsIconClick = (serviceRendered?: ServiceRendered) => {
    if (!serviceRendered) {
      return;
    }
    const service = servicesList.find((service) => serviceRendered.service_id === service.id);
    if (!service) {
      return;
    }
    const { missingHisaFields } = getServicePatientRequirements(service, patient);

    const initialValues: any = {};
    getInitialHisaValuesFromServiceRendered(initialValues, serviceRendered);
    setModalProps({
      ...sharedModalProps,
      initialValues,
      onSave: async (value) => {
        const editingServiceRendered: ServiceRendered | undefined =
          currentlyEditingServiceRendered.current || serviceRendered;
        if (editingServiceRendered) {
          const updatedInfo = editingServiceRendered.info?.map((info) => {
            const updatedValueKey = Object.keys(value).find(
              (key) => info.name_key === hisaFormFieldsConfig[key as HisaFormField].nameKey
            );
            if (updatedValueKey) {
              return {
                ...info,
                value: String(value[updatedValueKey as keyof ServiceOptionsFormValues]),
              };
            }
            return info;
          });
          const updatedText = editingServiceRendered.text?.map((text) => {
            const updatedValueKey = Object.keys(value).find(
              (key) => text.name_key === hisaFormFieldsConfig[key as HisaFormField].nameKey
            );
            if (updatedValueKey) {
              return {
                ...text,
                value: String(value[updatedValueKey as keyof ServiceOptionsFormValues]),
              };
            }
            return text;
          });
          setModalProps((prevState) => ({ ...prevState, isSaving: true }));
          const { success } = await saveModalData({
            ...editingServiceRendered,
            info: updatedInfo,
            text: updatedText,
          });
          if (success) {
            setInitialProps();
          } else {
            setModalProps((prevState) => ({ ...prevState, isSaving: false, visible: false, initialValues: {} }));
          }
        }
      },
      title: translations.shared.hisaRequirementsModal.title,
      testId: hisaRequirementsModalTestId,
      onClose: setInitialProps,
      children:
        (missingHisaFields && (
          <HisaRequirementsContent
            missingFields={missingHisaFields}
            service={service}
            serviceRenderedId={Object.values(initialValues).length ? '' : serviceRendered.id}
            form={form}
            isForRapidBilling={removedForRapidBilling}
          />
        )) ??
        null,
    });
  };

  const handleReminderClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    const tempServiceReminder = reminders?.find((r) => r.service_rendered_id === tempService?.id);
    if (!tempService) {
      return;
    }
    const serviceReminder = reminders?.find((r) => r.service_rendered_id === service.id) ?? service.reminder?.[0];
    setModalProps({
      ...sharedModalProps,
      initialValues: {
        reminderName: serviceReminder?.name,
        date: dayjs(serviceReminder?.date),
        note: serviceReminder?.note || undefined,
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          reminder:
            value.reminderName && value.date
              ? [
                  {
                    id: tempServiceReminder?.id || '',
                    organization_id: tempServiceReminder?.organization_id || organizationId,
                    name: value.reminderName,
                    date: value.date?.format(upsertDateFormat),
                    note: value.note,
                    status_id: defaultReminderStatus?.id ?? '',
                    status_name: defaultReminderStatus?.name ?? '',
                  },
                ]
              : undefined,
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false, visible: false, initialValues: {} }));
        }
      },
      title: translations.shared.reminderModal.title,
      testId: reminderFormTestId,
      children: <ReminderFormFields visible />,
    });
  };

  const handlePrescriptionClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    const tempServicePrescription = prescriptions?.find((p) => p.filled_service_rendered_id === tempService?.id);
    if (!tempService) {
      return;
    }
    const servicePrescription = prescriptions?.find((p) => p.filled_service_rendered_id === service.id);
    setModalProps({
      ...sharedModalProps,
      initialValues: {
        contactId: servicePrescription?.contact_id ?? undefined,
        prescriptionPatientId: servicePrescription?.patient_id ?? undefined,
        prescribedUserId: servicePrescription?.prescribed_user_id,
        practiceId: servicePrescription?.practice_id,
        prescribedDate: dayjs(servicePrescription?.prescribed_date),
        prescriptionExpiryDate: dayjs(servicePrescription?.prescription_expiry_date),
        productExpiryDate: servicePrescription?.product_expiry_date
          ? dayjs(servicePrescription?.product_expiry_date)
          : undefined,
        refills: servicePrescription?.refills || 0,
        itemDescription: servicePrescription?.item_description || undefined,
        itemQuantity: servicePrescription?.item_quantity,
        itemUnit: servicePrescription?.item_unit || undefined,
        instructions: servicePrescription?.filled_item_instructions || servicePrescription?.instructions || undefined,
      },
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({
          ...tempService,
          prescriptions:
            (removedForRapidBilling || value.contactId) &&
            value.prescribedUserId &&
            value.prescribedDate &&
            value.prescriptionExpiryDate &&
            value.itemQuantity &&
            (removedForRapidBilling || tempServicePrescription?.patient_id) &&
            tempServicePrescription?.practice_id
              ? [
                  {
                    id: tempServicePrescription?.id || '',
                    organization_id: tempServicePrescription?.organization_id || organizationId,
                    contact_id: value.contactId || '',
                    patient_id: tempServicePrescription.patient_id,
                    prescribed_user_id: value.prescribedUserId,
                    prescribed_date: value.prescribedDate.format(upsertDateFormat),
                    prescription_expiry_date: value.prescriptionExpiryDate.format(upsertDateFormat),
                    product_expiry_date: value.productExpiryDate?.format(upsertDateFormat),
                    refills: value.refills,
                    item_description: value.itemDescription,
                    item_quantity: value.itemQuantity,
                    item_unit: value.itemUnit,
                    instructions: tempServicePrescription?.fill_id
                      ? tempServicePrescription?.instructions
                      : value.instructions,
                    practice_id: tempServicePrescription.practice_id,
                    fill_externally: tempServicePrescription?.fill_externally,
                    created_type_id: tempServicePrescription?.created_type_id,
                    created: tempServicePrescription?.created,
                    created_user_id: tempServicePrescription?.created_user_id,
                    updated: tempServicePrescription?.updated,
                    hidden: tempServicePrescription?.hidden,
                    available_fills: tempServicePrescription?.available_fills,
                    filled_fills: tempServicePrescription?.filled_fills,
                    remaining_fills: tempServicePrescription?.remaining_fills,
                    is_filled: tempServicePrescription?.is_filled,
                    fill_id: tempServicePrescription?.fill_id,
                    filled_item_instructions: value.instructions,
                  },
                ]
              : undefined,
        });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false, visible: false, initialValues: {} }));
        }
      },
      title: translations.shared.prescriptionModal.title,
      testId: prescriptionModalFormTestId,
      children: (
        <PrescriptionModalContent
          contacts={contacts}
          servicesList={servicesList}
          patientName={servicePrescription?.patient_name || undefined}
          doctorOptions={doctorOptions}
          rxNumber={servicePrescription?.number || undefined}
          removedForRapidBilling={removedForRapidBilling}
          prescriptions={patientPresciptions}
          serviceId={service.service_id}
          isEditingServiceRenderedLineItem
          isConsumingFill={patientPresciptions?.some(
            (p) =>
              p.service_id === service.service_id &&
              p.remaining_fills &&
              !p.is_filled &&
              Number(p.item_quantity) === Number(tempServicePrescription?.item_quantity)
          )}
          showMultiPatientRefillsMessage={removedForRapidBilling}
        />
      ),
    });
  };

  const handleDiscountIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: {
        [totalWithDiscountField]: tempService.list_total,
        [discountAmountField]: tempService.discount ? Number(tempService.discount) : 0,
        [discountPercentField]: tempService.discount ? (+tempService.discount / +tempService.list_total) * 100 : 0,
        [hideDiscountField]: !!tempService.hide_discount,
      },
      hasCancelOption: true,
      onSave: async (value) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const updatedService = {
          ...tempService,
          unit_price: value[totalWithDiscountField]
            ? (Number(value[totalWithDiscountField] ?? 0) / +tempService.quantity).toString()
            : tempService.unit_price,
          total: value[totalWithDiscountField] ?? tempService.total,
          list_total: (+tempService.list_unit_price * +tempService.quantity).toString(),
          discount: value[discountAmountField] ? value[discountAmountField]?.toString() : undefined,
          discount_percent: value[discountPercentField]?.toString(),
          hide_discount: !!value[hideDiscountField],
        };

        if (tempService.id.includes('dummy-id')) {
          saveService(updatedService);
          setInitialProps();
        } else {
          const { success } = await saveModalData(updatedService);
          if (success) {
            setInitialProps();
          } else {
            setModalProps((prevState) => ({ ...prevState, isSaving: false }));
          }
        }
      },
      title: translations.shared.discountModal.title(service.name, service.date),
      testId: discountModalFormTestId,
      children: <DiscountModalContent service={tempService} form={form} />,
    });
  };

  const activateEditModeForService = (serviceRenderedId: string) => {
    patientBackupFunctions?.backup();
    dispatch(setEditingServiceRenderedIdAction(serviceRenderedId));
  };

  const handleControlledDrugIconClick = (service: ServiceRendered) => {
    const tempService = initialServicesRendered.find((initialService) => service.id === initialService.id);
    if (!tempService) {
      return;
    }

    setModalProps({
      ...sharedModalProps,
      initialValues: { bottle_number: service.bottle_number || undefined },
      onSave: async ({ bottle_number }) => {
        setModalProps((prevState) => ({ ...prevState, isSaving: true }));
        const { success } = await saveModalData({ ...tempService, bottle_number });
        if (success) {
          setInitialProps();
        } else {
          setModalProps((prevState) => ({ ...prevState, isSaving: false }));
        }
      },
      title: translations.shared.controlledDrugModal.title,
      testId: controlledDrugModalFormTestId,
      children: <ControlledDrugModalContent />,
    });
  };

  const cancelEditing = () => {
    dispatch(setClickedColumnAction(undefined));
    handleSetServicesRendered(initialServicesRendered);
    dispatch(setEditingServiceRenderedIdAction(undefined));
    patientBackupFunctions?.restore();
    addServiceFocusCallback();
  };

  useAddServiceKeydownHandling(state, saveService, cancelEditing, isUnchargedServices);

  const onChangeServiceValue = (
    serviceRendered: ServiceRendered,
    updatedValue: any,
    fieldName: keyof ServiceRendered
  ) => {
    // this sets the discounts for the uncharged services rendered
    if (isUnchargedServices) {
      if (fieldName === invoiceServicePropertyNames.quantity) {
        dispatch(
          changeServicesRenderedAction(
            serviceRendered.id,
            (+serviceRendered.list_unit_price * +updatedValue).toString(),
            'list_total'
          )
        );
      }
      if (fieldName === invoiceServicePropertyNames.total) {
        const isTotalValid = checkIsTotalValid(updatedValue);
        if (!isTotalValid) {
          return;
        }
        const listTotal = serviceRendered.list_total;
        const discountAmount = +listTotal - +serviceRendered.total.toString();
        const discountPercent = ((discountAmount / +listTotal) * 100).toString();
        dispatch(changeServicesRenderedAction(serviceRendered.id, discountAmount, 'discount'));
        dispatch(changeServicesRenderedAction(serviceRendered.id, discountPercent, 'discount_percent'));
      }
    }
    dispatch(changeServicesRenderedAction(serviceRendered.id, updatedValue, fieldName));
  };

  const onTableCellClick = (serviceRenderedId: string, columnName?: keyof ServiceRendered) => {
    return () => {
      if (
        !state.editingServiceRenderedId &&
        isTableEditable &&
        (onlyAllowOnCellClickEditingOfActiveRow ? activeServiceId === serviceRenderedId : true)
      ) {
        dispatch(setClickedColumnAction(columnName));
        activateEditModeForService(serviceRenderedId);
      }
    };
  };

  const getDisplayWidth = (columnWidth: number) => columnWidth - 2 * tableCellPadding;
  const getInputWidth = (columnWidth: number) => columnWidth - 2 * (tableCellPadding - paddingInInputField);

  function isInEditMode(serviceRendered: ServiceRendered) {
    return state.editingServiceRenderedId === serviceRendered.id;
  }

  const getTextInputRenderFunction =
    (fieldName: InvoiceServiceProperty, columnWidth: number, mandatory = false, maxLength?: number) =>
    (serviceRendered: ServiceRendered) => {
      if (isInEditMode(serviceRendered)) {
        const style: CSSProperties = { width: getInputWidth(columnWidth), ...marginFixForInlineEditing };
        return (
          <Input
            autoFocus={state.clickedColumn === fieldName}
            style={mandatory && !serviceRendered[fieldName] ? { borderColor: red.primary, ...style } : style}
            value={serviceRendered[fieldName]?.toString() || ''}
            onChange={(event) => onChangeServiceValue(serviceRendered, event.target.value, fieldName)}
            data-testid={`${fieldName}_input`}
            maxLength={maxLength}
          />
        );
      }
      return (
        <div style={{ width: getDisplayWidth(columnWidth) }} onClick={onTableCellClick(serviceRendered.id, fieldName)}>
          {serviceRendered[fieldName]}
        </div>
      );
    };

  function getInputNumberRenderFunction(
    fieldName: keyof ServiceRendered,
    inputProps: { precision?: number; minValue?: number; maxValue?: number },
    columnWidth: number,
    isPrice = false
  ) {
    return (serviceRendered: ServiceRendered) => {
      const fieldValue = Number(serviceRendered[fieldName]);
      if (isInEditMode(serviceRendered)) {
        const style: CSSProperties = { width: getInputWidth(columnWidth), ...marginFixForInlineEditing };
        return (
          <InputNumber
            autoFocus={state.clickedColumn === fieldName}
            style={style}
            value={isNaN(fieldValue) ? 0 : fieldValue}
            precision={inputProps.precision}
            onChange={(value) => onChangeServiceValue(serviceRendered, Number(value), fieldName)}
            data-testid={`${fieldName}_input`}
          />
        );
      }
      return (
        <div style={{ width: getDisplayWidth(columnWidth) }} onClick={onTableCellClick(serviceRendered.id, fieldName)}>
          {isPrice ? <PriceValue value={fieldValue} /> : fieldValue}
        </div>
      );
    };
  }

  const hasVaccinationData = (service: ServiceRendered) => {
    return service.manufacturer_name || service.lot_number || service.serial_number || service.expiry_date;
  };

  const hasControlledData = (service: ServiceRendered) => {
    return !!service.bottle_number;
  };

  const optionalListItem = (label: string, value?: string | number | JSX.Element | null) => {
    if (Number.isInteger(value) || value) {
      return (
        <MaxWidthListItem data-testid={value}>
          <b>{label}</b>: {value}
        </MaxWidthListItem>
      );
    }
    return undefined;
  };

  const renderNoteAndVaccinationIcon = (service: ServiceRendered) => {
    const noteId = service.note_id;
    const vaccinationPopoverContent = (
      <List>
        {optionalListItem(
          translations.renderedServicesTable.vaccinationPopover.manufacturerName,
          service.manufacturer_name
        )}
        {optionalListItem(translations.renderedServicesTable.vaccinationPopover.lotNumber, service.lot_number)}
        {optionalListItem(translations.renderedServicesTable.vaccinationPopover.serialNumber, service.serial_number)}
        {service.expiry_date
          ? optionalListItem(
              translations.renderedServicesTable.vaccinationPopover.expiryDate,
              displayAsDate(service.expiry_date, dateFormat)
            )
          : undefined}
      </List>
    );

    const controlledDrugPopoverContent = (
      <List>
        {optionalListItem(translations.renderedServicesTable.controlledDrugPopover.bottleNumber, service.bottle_number)}
      </List>
    );

    const serviceReminder = reminders?.find((r) => r.service_rendered_id === service.id);
    const reminderPopoverContent = serviceReminder ? (
      <List>
        {optionalListItem(translations.renderedServicesTable.reminderPopover.name, serviceReminder.name)}
        {optionalListItem(translations.renderedServicesTable.reminderPopover.date, serviceReminder.date)}
        {optionalListItem(translations.renderedServicesTable.reminderPopover.note, serviceReminder.note)}
      </List>
    ) : (
      <List>
        {optionalListItem(translations.renderedServicesTable.reminderPopover.name, service.reminder?.[0]?.name)}
        {optionalListItem(translations.renderedServicesTable.reminderPopover.date, service.reminder?.[0]?.date)}
        {optionalListItem(translations.renderedServicesTable.reminderPopover.note, service.reminder?.[0]?.note)}
      </List>
    );

    const servicePrescription = prescriptions?.find((p) => p.filled_service_rendered_id === service.id);

    const prescriptionPopoverContent = servicePrescription && (
      <List>
        {optionalListItem(translations.renderedServicesTable.prescriptionPopover.number, servicePrescription.number)}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.contact,
          servicePrescription.contact_name
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.patient,
          servicePrescription.patient_name
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.prescribedUser,
          servicePrescription.prescribed_user_name
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.prescribedDate,
          servicePrescription.prescribed_date
            ? dayjs(servicePrescription.prescribed_date).format(dateFormat)
            : undefined
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.prescriptionExpiryDate,
          servicePrescription.prescription_expiry_date
            ? dayjs(servicePrescription.prescription_expiry_date).format(dateFormat)
            : undefined
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.productExpiryDate,
          servicePrescription.product_expiry_date
            ? dayjs(servicePrescription.product_expiry_date).format(dateFormat)
            : undefined
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.itemDescription,
          servicePrescription.item_description
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.itemQuantity,
          servicePrescription.item_quantity
        )}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.itemUnit,
          servicePrescription.item_unit
        )}
        {showRefills &&
          optionalListItem(translations.renderedServicesTable.prescriptionPopover.refills, servicePrescription.refills)}
        {optionalListItem(
          translations.renderedServicesTable.prescriptionPopover.instructions,
          <TextEditor
            textContent={servicePrescription.filled_item_instructions || servicePrescription.instructions || undefined}
            setTextContent={() => null}
            editMode={false}
          />
        )}
      </List>
    );

    const clickableNoteIcon = (
      <ClickableIcon
        iconType={IconType.ServiceNote}
        opaque={!noteId}
        onClick={() => {
          setServiceOfShownNote(service);
          setShowingNoteWithId(noteId || '');
        }}
        data-testid={serviceNoteIconTestId}
      />
    );

    const microchipPopoverContent = (
      <List>{optionalListItem(translations.shared.microchipModal.fields.microchip, microchip?.value)}</List>
    );

    const rabiesTagPopoverContent = (
      <List>{optionalListItem(translations.shared.rabiesTagModal.fields.rabiesTag, rabiesTag?.value)}</List>
    );

    const roaPopoverContent = (
      <List>
        {optionalListItem(
          translations.shared.roaModal.title,
          service.roa_other ?? ROAOptions.find(({ value }) => value === service.roa_id)?.description
        )}
      </List>
    );

    const requiresTaxonomyMap = service.lab_request?.find(
      (request) => request.requires_breed_map || request?.requires_gender_map || request?.requires_species_map
    );

    const labRequestPopoverContent = requiresTaxonomyMap ? (
      <List>
        <MaxWidthListItem data-testid={translations.labRequestModal.requiresTaxonomyMap}>
          <b>{translations.labRequestModal.requiresTaxonomyMap}</b>
        </MaxWidthListItem>
      </List>
    ) : (
      service.lab_status?.map((requestStatus) => (
        <List key={requestStatus.request_group_id}>
          {optionalListItem(
            translations.labRequestModal.status,
            requestStatus.lab_device_name +
              ' - ' +
              (labRequestStatusConfig[requestStatus.status_id as LabRequestStatusId]?.text ??
                translations.shared.unknown)
          )}
        </List>
      ))
    );

    const reasonPopoverContent = (
      <List>{optionalListItem(translations.shared.reasonModal.fields.reason, service.reason)}</List>
    );

    const withdrawalPopoverContent = (
      <List>
        {optionalListItem(translations.shared.withdrawalModal.fields.withdrawal, service.withdrawal_period?.toString())}
      </List>
    );

    const discountPopoverContent = (
      <List>
        {optionalListItem(
          translations.shared.discountPopover.originalPrice,
          <CurrencyFormatter total={service.list_total} isPriceValue />
        )}
        {service?.discount &&
          optionalListItem(
            translations.shared.discountPopover.discountAmount,
            <CurrencyFormatter total={service?.discount} isPriceValue />
          )}
        {optionalListItem(translations.shared.discountPopover.discountPercent, service.discount_percent + '%')}
      </List>
    );
    const hasHisaInfoOnService =
      service?.info?.some(
        (info) => info?.name_key && HisaNameKeys.includes(info.name_key as HisaRequirementsNameKey)
      ) ||
      service?.text?.some((text) => text?.name_key && HisaNameKeys.includes(text.name_key as HisaRequirementsNameKey));
    return (
      <>
        {noteId ? (
          <PopoverNote
            noteId={noteId}
            existingNote={service.note}
            testId={serviceNoteIconTestId}
            haveNoteValuesChanged={haveNoteValuesChanged}
            onClick={() => {
              setServiceOfShownNote(service);
              setShowingNoteWithId(noteId || '');
            }}
          />
        ) : (
          clickableNoteIcon
        )}
        {service.vaccine &&
          (hasVaccinationData(service) ? (
            <Popover content={vaccinationPopoverContent}>
              {fixClassnameChangeOnPopover(
                <ClickableIcon
                  iconType={IconType.Vaccine}
                  data-testid={serviceRenderedVaccinationIconTestId}
                  onClick={() => isTableEditable && handleVaccineIconClick(service)}
                />
              )}
            </Popover>
          ) : (
            <ClickableIcon
              iconType={IconType.Vaccine}
              opaque
              data-testid={serviceRenderedVaccinationIconTestId}
              onClick={() => isTableEditable && handleVaccineIconClick(service)}
            />
          ))}
        {service.controlled &&
          (hasControlledData(service) ? (
            <Popover content={controlledDrugPopoverContent}>
              {fixClassnameChangeOnPopover(
                <ClickableIcon
                  iconType={IconType.Vaccine}
                  data-testid={serviceRenderedControlledIconTestId}
                  onClick={() => isTableEditable && handleControlledDrugIconClick(service)}
                />
              )}
            </Popover>
          ) : (
            <ClickableIcon
              iconType={IconType.Vaccine}
              opaque
              data-testid={serviceRenderedControlledIconTestId}
              onClick={() => isTableEditable && handleControlledDrugIconClick(service)}
            />
          ))}
        {serviceReminder || service.reminder?.[0] ? (
          <Popover content={reminderPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Reminder}
                data-testid={serviceReminderIconTestId}
                onClick={() => isTableEditable && handleReminderClick(service)}
              />
            )}
          </Popover>
        ) : null}
        {servicePrescription && (
          <Popover content={prescriptionPopoverContent} placement='left'>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Prescription}
                data-testid={servicePrescriptionIconTestId}
                onClick={() => isTableEditable && handlePrescriptionClick(service)}
              />
            )}
          </Popover>
        )}
        {service.microchip && patientId && (
          <Popover content={microchipPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Microchip}
                data-testid={serviceMicrochipIconTestId}
                onClick={() => isTableEditable && handleMicrochipIconClick()}
              />
            )}
          </Popover>
        )}
        {service.rabies_tag && patientId && (
          <Popover content={rabiesTagPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.RabiesTag}
                data-testid={serviceRabiesTagIconTestId}
                onClick={() => isTableEditable && handleRabiesTagIconClick()}
              />
            )}
          </Popover>
        )}
        {service.roa_id && (
          <Popover content={roaPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Roa}
                data-testid={roaIconTestId}
                onClick={() => isTableEditable && handleRoaIconClick(service)}
              />
            )}
          </Popover>
        )}
        {isOnline && service.lab_request && (
          <Popover content={labRequestPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Lab}
                data-testid={labIconTestId}
                onClick={labIconCallback}
                color={requiresTaxonomyMap ? 'red' : undefined}
              />
            )}
          </Popover>
        )}
        {service.reason && (
          <Popover content={reasonPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Reason}
                data-testid={reasonIconTestId}
                onClick={() => isTableEditable && handleReasonIconClick(service)}
              />
            )}
          </Popover>
        )}
        {service.withdrawal_prompt &&
          (service.withdrawal_period && service.withdrawal_period > 0 ? (
            <Popover content={withdrawalPopoverContent}>
              {fixClassnameChangeOnPopover(
                <ClickableIcon
                  iconType={IconType.Withdrawal}
                  data-testid={withdrawalIconTestId}
                  onClick={() => isTableEditable && handleWithdrawalIconClick(service)}
                />
              )}
            </Popover>
          ) : (
            <ClickableIcon
              iconType={IconType.Withdrawal}
              data-testid={withdrawalIconTestId}
              onClick={() => isTableEditable && handleWithdrawalIconClick(service)}
              opaque
            />
          ))}
        {service.discount ? (
          <Popover content={discountPopoverContent}>
            {fixClassnameChangeOnPopover(
              <ClickableIcon
                iconType={IconType.Discount}
                data-testid={discountIconTestId}
                onClick={() => isTableEditable && handleDiscountIconClick(service)}
              />
            )}
          </Popover>
        ) : (
          <ClickableIcon
            iconType={IconType.Discount}
            onClick={() => isTableEditable && handleDiscountIconClick(service)}
            opaque
          />
        )}
        {(service?.third_party?.some(
          (thirdPartyService) => thirdPartyService.third_party_id === ServiceThirdPartyId.HISA
        ) ||
          hasHisaInfoOnService) &&
          patientLinkedToHisa && (
            <PopoverWithHisaInfo
              serviceRenderedId={hasHisaInfoOnService ? '' : service.id}
              onClick={handleHisaRequirementsIconClick}
              isEditable={isTableEditable}
              unsavedServiceRendered={service}
            />
          )}
      </>
    );
  };

  function renderEditableDate(serviceRendered: ServiceRendered, dateFormat: string) {
    return (
      <DatePicker
        autoFocus={state.clickedColumn === invoiceServicePropertyNames.date}
        format={dateFormat}
        value={dayjs(serviceRendered.date)}
        allowClear={false}
        style={{ width: getInputWidth(dateColumnWidth), ...marginFixForInlineEditing }}
        onChange={(date) => {
          return onChangeServiceValue(serviceRendered, date?.format('YYYY-MM-DD'), invoiceServicePropertyNames.date);
        }}
      />
    );
  }

  function renderDate(serviceRendered: ServiceRendered, dateFormat: string) {
    if (isInEditMode(serviceRendered)) {
      return renderEditableDate(serviceRendered, dateFormat);
    }
    return (
      <>
        {serviceRendered.id === activeServiceId ? <div id={'activeRowIndicator'} /> : null}
        <div
          style={{ width: getDisplayWidth(dateColumnWidth) }}
          onClick={onTableCellClick(serviceRendered.id, invoiceServicePropertyNames.date)}
        >
          {displayAsDate(serviceRendered.date, dateFormat)}
        </div>
      </>
    );
  }

  function renderEditableDoctorName(serviceRendered: ServiceRendered) {
    return (
      <Select
        style={{ width: getInputWidth(doctorColumnWidth), ...marginFixForInlineEditing }}
        value={serviceRendered.doctor_id || undefined}
        {...searchableSelectParams}
        options={doctorOptions}
        onChange={(_, option: any) => {
          onChangeServiceValue(serviceRendered, option.value, invoiceServicePropertyNames.doctor_id);
          onChangeServiceValue(serviceRendered, option.label, invoiceServicePropertyNames.doctor_name);
        }}
      />
    );
  }

  function renderDoctorName(serviceRendered: ServiceRendered) {
    if (isInEditMode(serviceRendered)) {
      return renderEditableDoctorName(serviceRendered);
    }
    return (
      <div style={{ width: getDisplayWidth(doctorColumnWidth) }} onClick={onTableCellClick(serviceRendered.id)}>
        {serviceRendered.doctor_name}
      </div>
    );
  }

  function renderEditingActionCell(serviceRendered: ServiceRendered) {
    return (
      <Space direction={'horizontal'}>
        <Button type='link' style={editActionCellStyle} onClick={() => saveService(serviceRendered)}>
          {translations.shared.saveButtonText}
        </Button>
        <Button type='link' style={editActionCellStyle} onClick={cancelEditing}>
          {translations.shared.cancelButtonText}
        </Button>
      </Space>
    );
  }

  function renderActionCell(serviceRendered: ServiceRendered) {
    if (isInEditMode(serviceRendered)) {
      return renderEditingActionCell(serviceRendered);
    }
    const editButtonMenu: MenuItemProps = {
      title: translations.shared.editButtonText,
      onClick: () => activateEditModeForService(serviceRendered.id),
      disabled: !!state.editingServiceRenderedId,
    };

    return (
      <ServiceRenderedTableActionCell
        serviceRendered={serviceRendered as ServiceRenderedWithLocalFields}
        editButtonMenu={editButtonMenu}
        deleteServiceRendered={deleteServiceRendered}
        printPrescription={openPrinterModal}
      />
    );
  }

  const columns: ColumnsType<ServiceRendered> = [
    {
      ...serviceRenderedColumns.date,
      render: (serviceRendered) => renderDate(serviceRendered, dateFormat),
      width: dateColumnWidth,
    },
    {
      ...serviceRenderedColumns.doctor_name,
      render: renderDoctorName,
      width: doctorColumnWidth,
    },
    {
      ...serviceRenderedColumns.name,
      width: descriptionColumnWidth,
      render: getTextInputRenderFunction(invoiceServicePropertyNames.name, descriptionColumnWidth, true, 100),
    },
    {
      ...serviceRenderedColumns.quantity,
      width: quantityColumnWidth,
      render: getInputNumberRenderFunction(
        invoiceServicePropertyNames.quantity,
        { minValue: MIN_INPUT_QTY, maxValue: MAX_INPUT_AMOUNT },
        quantityColumnWidth
      ),
    },
    {
      ...serviceRenderedColumns.unit_name,
      width: unitColumnWidth,
      render: getTextInputRenderFunction(invoiceServicePropertyNames.unit_name, unitColumnWidth, false, 10),
    },
    {
      ...serviceRenderedColumns.unit_price,
      width: unitPriceColumnWidth,
      render: (serviceRendered: ServiceRendered) => {
        return <PriceValue value={+serviceRendered.unit_price} />;
      },
    },
    {
      ...serviceRenderedColumns.total,
      width: totalColumnWidth,
      render: getInputNumberRenderFunction(
        invoiceServicePropertyNames.total,
        { precision: 2, minValue: -MAX_INPUT_AMOUNT, maxValue: MAX_INPUT_AMOUNT },
        totalColumnWidth,
        true
      ),
    },
    ...(isTableEditable
      ? [
          {
            title: translations.renderedServicesTable.columns.actions,
            key: 'action',
            render: renderActionCell,
            width: 120,
          },
        ]
      : []),
    {
      title: <div style={{ minWidth: 40 }}>{translations.renderedServicesTable.columns.note}</div>,
      key: 'note',
      render: (serviceRendered: ServiceRendered) => {
        return renderNoteAndVaccinationIcon(serviceRendered);
      },
    },
  ];

  const modalVisible = showingNoteWithId !== undefined;
  const hideModal = () => {
    setShowingNoteWithId(undefined);
  };

  const modalTitle = () => {
    if (!serviceOfShownNote) {
      return translations.renderedServicesTable.titleError;
    }
    return showingNoteWithId
      ? translations.renderedServicesTable.editNoteModalTitle(serviceOfShownNote)
      : translations.renderedServicesTable.addNoteModalTitle(serviceOfShownNote);
  };

  const components = useMemo(
    () =>
      onRowMove &&
      getDraggableComponents({
        data: currentServicesRendered,
        onSort: onRowMove,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentServicesRendered.length, onRowMove]
  );

  return (
    <>
      <Table<ServiceRendered>
        className={'ServicesRenderedTable'}
        dataSource={currentServicesRendered}
        columns={onRowMove ? draggableColumns(columns) : columns}
        showHeader={showHeader}
        rowKey={'id'}
        scroll={{ x: TotalWidth, y: tableHeight }}
        footer={tableFooter}
        rowClassName={(record) => (record.id === activeServiceId ? classNameForHighlightedRow : '')}
        pagination={false}
        components={components}
        onRow={(service) => ({
          onClick: () => {
            if (!state.editingServiceRenderedId) {
              setActiveServiceId(service.id);
            }
          },
        })}
        id={isUnchargedServices ? unchargedServicesRenderedTableId : servicesRenderedTableId}
      />
      {modalVisible && (
        <ModalWithCloseConfirm
          onCancel={hideModal}
          width={600}
          title={modalTitle()}
          bodyStyle={{ maxHeight: 750 }}
          footer={null}
          changesMade={haveNoteValuesChanged}
          open
          isNavWarningRedundant={isUnchargedServices}
        >
          <NoteViewWrapper
            noteId={showingNoteWithId || ''}
            closeModal={hideModal}
            serviceRendered={serviceOfShownNote}
            changeNote={changeNote}
            existingNote={serviceOfShownNote?.note}
            haveNoteValuesChanged={haveNoteValuesChanged}
            setHaveNoteValuesChanged={setHaveNoteValuesChanged}
            isEditable={isOnline || !!isTableEditable}
          />
        </ModalWithCloseConfirm>
      )}
      {modalProps.visible && <ServiceSingleOptionModal {...modalProps} />}
      {renderPrintModal()}
    </>
  );
};
