import { RxJsonSchema } from 'rxdb';
import { Scalars } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaWithUpdated, RxSchemaTypeWithUpsert } from '../databaseUtils';

type Upsert = {
  id: Scalars['ID'];
  type_name: Scalars['String'];
};
export type RxUpsert = RxSchemaTypeWithUpsert<Upsert, Record<string, any>, 'upsert'> & RxSchemaWithUpdated;
export const upsertSchema: RxJsonSchema<RxUpsert> = {
  version: 1,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    type_name: RxScalars.String,
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
    updated: RxScalars.String,
  },
  required: ['id'],
  indexes: ['type_name'],
};
