import { MutationPreLoadDataArgs, MutationSignUpArgs } from '../../../graph/types';
import { PreloadData, SignUpOrganization } from '../../../graph/queries/registration';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';

export const useSignUpOrganization = () => {
  return useOfflineErrorSkipMutation<'signUp', MutationSignUpArgs>(SignUpOrganization);
};

export const usePreloadData = () => {
  return useOfflineErrorSkipMutation<'preLoadData', MutationPreLoadDataArgs>(PreloadData);
};
