import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { List } from 'antd';
import { RecordItem, RecordType, selectRecordIfNoUnsavedChanges, sortRecordsByDate } from './recordUtils';
import { RecordListItem } from './RecordListItem';
import { RecordsContext } from './store/state';
import { selectSelectedRecordId, selectSelectedServiceRender, selectServicesRender } from './store/selectors';
import { Contact, File, LabRequest, NotePreview, Patient, ServiceActivity } from '../../graph/types';
import { AddNoteRecord } from '../RecordSingleView/Notes/AddNoteRecord';
import { AddFileRecord } from '../RecordSingleView/Files/AddFileRecord';
import { RecordSingleViewFile } from '../RecordSingleView/Files/RecordSingleViewFile';
import { ServiceActivityCard } from '../RecordSingleView/ServiceActivity/ServiceActivityCard';
import { RecordSingleViewNoteLoader } from '../RecordSingleView/Notes/RecordSingleViewNoteLoader';
import { useHighlightedText } from '../../hooks/useHighlightedText';
import { ViewSubscriptionContext } from '../ViewSubscription/store/state';
import ViewSubscriptionActions from '../ViewSubscription/store/actions';
import { translations } from '../../constants/translations';
import LinkedServiceRenderedTooltip from '../RecordSingleView/LinkedServiceRenderedTooltip';
import { selectRecordAction, setSearchValueAction, setServiceRenderAction } from './store/actions';
import { DivDirection, SearchInput, StickyDiv, StickyDivWrapper } from './RecordListItem.styles';
import { LabRequestCard } from '../RecordSingleView/LabRequest/LabRequestCard';
import { ListItemHeader } from './FullscreenRecords/ListItemHeader';
import _ from 'lodash';
import { FullscreenRecordList } from './FullscreenRecords/FullscreenRecordList';

interface RecordListContentProps extends PropsWithChildren<unknown> {
  records: RecordItem[];
  contact?: Contact;
  patient?: Patient;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  fullscreenMode?: boolean;
  loading?: boolean;
}

export const Records: React.FC<RecordListContentProps> = ({
  records,
  patient,
  contact,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  fullscreenMode,
  loading,
}: RecordListContentProps) => {
  const { dispatch: dispatchSubscription } = useContext(ViewSubscriptionContext);
  const { state, dispatch } = useContext(RecordsContext);
  const selectedRecordId = selectSelectedRecordId(state);
  const selectedServiceRender = selectSelectedServiceRender(state);
  const servicesRender = selectServicesRender(state);
  const [currentRecords, setCurrentRecords] = useState<RecordItem[]>(sortRecordsByDate(records));
  const selectedRecord = currentRecords.find((record: RecordItem) => record.recordId === selectedRecordId);

  const isExistingRecord = typeof selectedRecordId === 'string';
  const isNewRecord = selectedRecordId?.hasOwnProperty('newRecord');
  const isNewNoteRecord = isNewRecord && (selectedRecordId as any).newRecord === 'note';
  const isNewFileRecord = isNewRecord && (selectedRecordId as any).newRecord === 'file';

  useHighlightedText([currentRecords], '.ant-list-item-meta-description, #ServiceRecord');

  useEffect(() => {
    if (fullscreenMode) {
      dispatch(selectRecordAction(undefined));
    }
  }, [fullscreenMode, dispatch]);

  useEffect(() => {
    setCurrentRecords(sortRecordsByDate(records));
  }, [records]);

  const onClose = useCallback(() => {
    selectRecordIfNoUnsavedChanges(state, dispatch, undefined);
  }, [dispatch, state]);

  const insertNewRecordInState = useCallback(
    (recordItem: RecordItem) => {
      setCurrentRecords(sortRecordsByDate([...currentRecords, recordItem]));
    },
    [currentRecords]
  );

  const setEditing = useCallback(
    (editing: boolean) => {
      dispatchSubscription(ViewSubscriptionActions.setEditing(editing));
    },
    [dispatchSubscription]
  );

  const { dispatch: dispatchRecords } = useContext(RecordsContext);

  const setSearchValue = (value: string) => {
    dispatchRecords(setSearchValueAction(value));
  };

  const getRecordSingleView = useCallback(
    (record?: RecordItem) => {
      if ((selectedRecord && isExistingRecord) || record) {
        const viewingRecord = selectedRecord || record;
        const extraFullscreenContent = record ? <ListItemHeader item={record} /> : null;
        switch (viewingRecord?.type) {
          case RecordType.typeNote: {
            const notePreview = viewingRecord?.originalObject as NotePreview;
            const serviceRenderedNote = servicesRender?.find((service) => service.note_id === notePreview.id);

            return (
              <RecordSingleViewNoteLoader
                record={record}
                recordId={viewingRecord?.recordId}
                onClose={onClose}
                notePreview={notePreview}
                patientId={patient?.id}
                contactId={contact?.id}
                setCurrentRecords={setCurrentRecords}
                setEditing={record ? undefined : setEditing}
                linkedIcon={
                  serviceRenderedNote && (
                    <LinkedServiceRenderedTooltip
                      onClick={() => dispatch(setServiceRenderAction(serviceRenderedNote))}
                    />
                  )
                }
                setShouldResetOnTabChange={setShouldResetOnTabChange}
                shouldResetOnTabChange={shouldResetOnTabChange}
                isFullscreenMode={fullscreenMode}
              />
            );
          }
          case RecordType.typeFile: {
            const file = viewingRecord?.originalObject as File;
            return (
              <RecordSingleViewFile
                recordId={viewingRecord?.recordId}
                onClose={onClose}
                file={file}
                patientId={patient?.id}
                contactId={contact?.id}
                setCurrentRecords={setCurrentRecords}
                setEditing={record ? undefined : setEditing}
                setShouldResetOnTabChange={setShouldResetOnTabChange}
                shouldResetOnTabChange={shouldResetOnTabChange}
                isFullscreenMode={fullscreenMode}
                record={record}
              />
            );
          }
          case RecordType.typeServiceRenderedActivity: {
            const serviceActivity = viewingRecord?.originalObject as ServiceActivity;

            return (
              <ServiceActivityCard
                serviceActivityDate={serviceActivity?.date}
                onClose={onClose}
                patientId={patient?.id}
                activityTypeName={viewingRecord?.typeName}
                extraFullscreenContent={extraFullscreenContent}
              />
            );
          }
          case RecordType.typeLabRequest: {
            return (
              <LabRequestCard
                labRequest={viewingRecord?.originalObject as LabRequest}
                onClose={onClose}
                isFullscreenMode={fullscreenMode}
                record={record}
              />
            );
          }
          default:
            return null;
        }
      } else if (selectedServiceRender) {
        return (
          <ServiceActivityCard
            serviceActivityDate={selectedServiceRender?.date}
            onClose={onClose}
            patientId={patient?.id}
            activityTypeName={translations.recordList.serviceActivity(false)}
          />
        );
      } else if (isNewNoteRecord) {
        return (
          <AddNoteRecord
            patientId={patient?.id}
            contactId={contact?.id}
            insertNewRecordInState={insertNewRecordInState}
            setEditing={setEditing}
            setShouldResetOnTabChange={setShouldResetOnTabChange}
            shouldResetOnTabChange={shouldResetOnTabChange}
          />
        );
      } else if (isNewFileRecord) {
        return (
          <AddFileRecord
            patientId={patient?.id}
            contactId={contact?.id}
            insertNewRecordInState={insertNewRecordInState}
            setEditing={setEditing}
            setShouldResetOnTabChange={setShouldResetOnTabChange}
            shouldResetOnTabChange={shouldResetOnTabChange}
          />
        );
      }
      return null;
    },
    [
      contact,
      dispatch,
      insertNewRecordInState,
      isExistingRecord,
      isNewFileRecord,
      isNewNoteRecord,
      onClose,
      patient,
      selectedRecord,
      selectedServiceRender,
      servicesRender,
      setEditing,
      setShouldResetOnTabChange,
      shouldResetOnTabChange,
      fullscreenMode,
    ]
  );

  useEffect(() => {
    if (!fullscreenMode) {
      const mainContent = document.getElementsByTagName('main')[0];
      if (mainContent) {
        mainContent.setAttribute('style', 'overflow: auto');
      }
    }
  }, [fullscreenMode]);

  return (
    <>
      <SearchInput
        placeholder={translations.patientPage.medicalRecords.search}
        onChange={({ target: { value } }) => setSearchValue(value)}
      />
      {fullscreenMode ? (
        <FullscreenRecordList
          loading={loading}
          currentRecords={currentRecords}
          getRecordSingleView={getRecordSingleView}
        />
      ) : (
        <DivDirection>
          <List
            style={{ flexGrow: 1, maxWidth: '600px', minWidth: '30%' }}
            dataSource={currentRecords}
            renderItem={(item) => <RecordListItem record={item} />}
            pagination={{ defaultPageSize: 10 }}
            itemLayout={'vertical'}
          />
          {selectedRecordId || selectedServiceRender ? (
            <StickyDivWrapper>
              <StickyDiv>{getRecordSingleView()}</StickyDiv>
            </StickyDivWrapper>
          ) : null}
        </DivDirection>
      )}
    </>
  );
};
