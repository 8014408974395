import gql from 'graphql-tag';

import {
  ServicesRendered3pApprovalsFields,
  ServicesRendered3pHistoryFields,
  ThirdPartyTypeFields,
  ThirdPartyTaxonomyMappingFields,
  ThirdPartyGenderMapFields,
} from '../fragments/thirdPartyDataFragments';

export const GetApprovals = gql`
  query getServicesRendered3pApprovals($organizationId: ID!) {
    getServicesRendered3pApprovals(organizationId: $organizationId) {
      ...ServicesRendered3pApprovalsFields
    }
  }
  ${ServicesRendered3pApprovalsFields}
`;

export const GetHistory = gql`
  query getServicesRendered3pHistory($organizationId: ID!) {
    getServicesRendered3pHistory(organizationId: $organizationId) {
      ...ServicesRendered3pHistoryFields
    }
  }
  ${ServicesRendered3pHistoryFields}
`;

export const GetThirdPartyTypes = gql`
  query getOrganizations($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      ref_system {
        third_party {
          ...ThirdPartyTypeFields
        }
      }
    }
  }
  ${ThirdPartyTypeFields}
`;

export const GetThirdPartyRefGenders = gql`
  query getThirdPartyRefGenders($organizationId: ID!, $thirdPartyRefFilter: QueryThirdPartyRefFilter) {
    getThirdPartyRefGenders(organizationId: $organizationId, filter: $thirdPartyRefFilter) {
      ...ThirdPartyTaxonomyMappingFields
    }
  }
  ${ThirdPartyTaxonomyMappingFields}
`;

export const UpsertThirdPartyGenderMap = gql`
  mutation upsertThirdPartyGenderMap($genderMap: ThirdPartyGenderMap!) {
    upsertThirdPartyGenderMap(genderMap: $genderMap) {
      ...ThirdPartyGenderMapFields
    }
  }
  ${ThirdPartyGenderMapFields}
`;

export const GetThirdPartyValidation = gql`
  query getThirdPartyValidation(
    $organizationId: ID!
    $practiceId: ID!
    $thirdPartyValidationFilter: QueryThirdPartyValidationFilter
  ) {
    getThirdPartyValidation(
      organizationId: $organizationId
      practiceId: $practiceId
      filter: $thirdPartyValidationFilter
    ) {
      email_address_valid
    }
  }
`;
