import { useMemo } from 'react';
import { PracticeSettingsNameKey } from '../constants/translations';
import { getPracticeSetting } from '../pages/Contacts/ViewContact/statementUtils';
import { useOrganizationContext } from '../contexts/organization/state';

export const useCustomizedDateFormat = () => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice.find(({ id }) => id === organization.default_practice_id);

  const dateFormatSetting = useMemo(() => getPracticeSetting(PracticeSettingsNameKey.LocaleDate, practice), [practice]);

  const dateFormat = useMemo(
    () => (dateFormatSetting?.practice_level_set ? dateFormatSetting?.value : undefined),
    [dateFormatSetting?.practice_level_set, dateFormatSetting?.value]
  );

  return { dateFormat };
};
