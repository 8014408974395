import React, { FunctionComponent, useMemo, useState } from 'react';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { DatePicker, Form } from 'antd';
import { Contact, Job, JobUpsert } from '../../graph/types';
import { bodyFieldName, recipientsFieldName, subjectFieldName } from './StandardEmailFormFields';
import { getRequiredRule } from '../../util/forms';
import dayjs from 'dayjs';
import {
  generateStatementReportAttachment,
  getFileNameForContactStatements,
  getPracticeSetting,
  getStatementReportVariables,
} from '../../pages/Contacts/ViewContact/statementUtils';
import { Store } from 'antd/lib/form/interface';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { getJobRecordUpsert } from '../../util/jobUtil';
import { GetStatementReport } from '../../graph/queries/reports';
import { useUpsertJob } from '../../hooks/ajax/job/jobHooks';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import { useOffline } from '../../util/offline/offlineUtil';
import { EmailModalWithJob } from './EmailModalWithJob';
import { Attachment, EmailModal } from './EmailModal';
import { upsertDateFormat } from '../../constants/formats';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';
import { formatStringWithTemplatedFieldsForStatements } from '../../util/emailUtil';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const startEndDatesFieldName = 'startEndDates';

interface EmailModalForContactStatementProps {
  contact: Contact;
  onClose: () => void;
}

export const emailModalForContactsStatementTest = {
  dateRangePicker: 'rangePicker',
};

const EmailModalForContactStatement: FunctionComponent<EmailModalForContactStatementProps> = ({ contact, onClose }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const usesActiveReports = useLDFlag(LDFlagNames.ActiveReports);
  const practiceId = useDefaultPracticeId();
  const { dateFormat, displayCurrency, displayDate } = useCurrencyAndDateSetting();

  const [upsertJob] = useUpsertJob();
  const { canUseCollection } = useOffline();

  const [dates, setDates] = useState<{ start: string; end: string }>({ start: '', end: '' });

  const [sendEmailHandler, setSendEmailHandler] = useState<(values: Store | undefined, result: Attachment[]) => void>();

  const [emailFormStore, setEmailFormStore] = useState<Store | undefined>();

  const [jobEmailId, setEmailJobId] = useState<string>();

  const fileName = useMemo(
    () => getFileNameForContactStatements(contact.number, dates.start, dates.end),
    [contact, dates.end, dates.start]
  );

  const { state } = useOrganizationContext();

  const practice = defaultPractice(state);

  const subjectSetting = getPracticeSetting(PracticeSettingsNameKey.StatementSubject, practice);
  const bodySetting = getPracticeSetting(PracticeSettingsNameKey.StatementBody, practice);

  const subject = useMemo(
    () =>
      formatStringWithTemplatedFieldsForStatements(
        subjectSetting?.value,
        dates.start,
        dates.end,
        contact.name,
        dateFormat
      ),
    [subjectSetting, dates, dateFormat, contact]
  );

  const body = useMemo(
    () =>
      formatStringWithTemplatedFieldsForStatements(
        bodySetting?.value,
        dates.start,
        dates.end,
        contact.name,
        dateFormat
      ),
    [bodySetting, dates, dateFormat, contact]
  );

  const initialValues = {
    [subjectFieldName]: subject ?? `${translations.emailModalForContactStatement.subject} ${contact.name}`,
    [bodyFieldName]: body,
    [recipientsFieldName]: contact.email ? [contact.email] : [],
  };

  const handleValueChange = (changedValue: Store) => {
    if (changedValue[startEndDatesFieldName]?.length) {
      setDates({
        start: changedValue[startEndDatesFieldName][0].format(dateFormat),
        end: changedValue[startEndDatesFieldName][1]?.format(dateFormat) ?? '',
      });
    }
  };

  const fetchAttachments = async (
    formValues: Store,
    onFinishCallback: (values: Store | undefined, result: Attachment[]) => void
  ) => {
    setSendEmailHandler(onFinishCallback);
    setEmailFormStore(formValues);

    const variables = await getStatementReportVariables(
      contact,
      formValues[startEndDatesFieldName][0].format(upsertDateFormat),
      formValues[startEndDatesFieldName][1].format(upsertDateFormat),
      practiceId,
      organizationId,
      false
    );

    if (!canUseCollection) {
      const jobRecord = getJobRecordUpsert(GetStatementReport, {
        organizationId,
        practiceId,
        reportParameters: {
          start_date: variables.startDate,
          end_date: variables.endDate,
          contact_id: variables.contactId,
          footer: variables.footer,
          returnPreSignedUrl: variables.request.returnPreSignedUrl,
        },
      });
      const job: JobUpsert = {
        record: jobRecord,
      };
      const jobVariables = {
        organizationId,
        job,
      };
      try {
        const { data } = await upsertJob({
          variables: jobVariables,
        });
        setEmailJobId(data?.upsertJob.id);
      } catch (e) {
        showErrorMessage(translations.shared.generalErrorMessage);
      }
    }
  };

  const fetchAttachmentsForActiveReports = async (formValues: Store) => {
    const variables = await getStatementReportVariables(
      contact,
      formValues[startEndDatesFieldName][0].format(dateFormat),
      formValues[startEndDatesFieldName][1].format(dateFormat),
      practiceId,
      organizationId,
      false
    );
    const statementReportAsEmailAttachment = await generateStatementReportAttachment(
      {
        organizationId,
        practiceId,
        reportParameters: {
          start_date: variables.startDate,
          end_date: variables.endDate,
          contact_id: variables.contactId,
          footer: variables.footer,
        },
      },
      variables.request.fileName,
      displayCurrency,
      displayDate
    );

    if (!statementReportAsEmailAttachment) {
      return [];
    }
    return [statementReportAsEmailAttachment];
  };

  const handleCloseJobStatusModal = (errorMessage?: string) => {
    setEmailJobId(undefined);
    if (errorMessage) {
      showErrorMessage(errorMessage);
    } else {
      showSuccessMessage(translations.shared.saveSuccessMessage);
    }
  };

  const handleJobFinish = async ({ result }: Job) => {
    if (result) {
      const { reportPointer } = await JSON.parse(result);
      if (reportPointer) {
        sendEmailHandler?.(emailFormStore, [mapFileData(reportPointer)]);
      }
    }
  };

  const mapFileData = (reportPointer: string) => {
    return {
      file_name: fileName,
      file_pointer: reportPointer,
    };
  };

  const sharedProperties = {
    title: translations.emailModalForContactStatement.title,
    layout,
    visible: true,
    onClose,
    extraFormFields: (
      <Form.Item
        label={translations.emailModalForContactStatement.fields.date}
        name={startEndDatesFieldName}
        rules={[getRequiredRule(translations.emailModalForContactStatement.fields.date)]}
        data-testid={emailModalForContactsStatementTest.dateRangePicker}
      >
        <DatePicker.RangePicker
          format={dateFormat}
          placeholder={[
            translations.emailModalForContactStatement.fields.startDate,
            translations.emailModalForContactStatement.fields.endDate,
          ]}
          disabledDate={(date) => date.isAfter(dayjs())}
        />
      </Form.Item>
    ),
    attachments: [fileName],
    initialValues,
  };

  return (
    <>
      {usesActiveReports ? (
        <EmailModal
          {...sharedProperties}
          onAttachmentsFetch={fetchAttachmentsForActiveReports}
          dates={dates}
          contactName={contact.name}
          onChange={handleValueChange}
        />
      ) : (
        <EmailModalWithJob
          {...sharedProperties}
          onAttachmentsFetch={fetchAttachments}
          jobId={jobEmailId}
          handleCloseJobStatusModal={handleCloseJobStatusModal}
          handleJobFinish={handleJobFinish}
          dates={dates}
          contactName={contact.name}
          onChange={handleValueChange}
        />
      )}
    </>
  );
};

export default EmailModalForContactStatement;
