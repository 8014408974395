import { Info, User, UserOrganization, UserOrganizationUserTypeUpsert, UserUpsert } from '../../graph/types';
import { PhoneUpsertGenerator } from './PhoneUpsertGenerator';
import { UserDetailsFields } from '../../pages/Users/UserDetails/UserDetails';
import { difference } from 'lodash';
import {
  AddressType,
  AddressUpsertGenerator,
  AddressUpsertInfo,
  mapAddressToAddressUpsert,
} from './AddressUpsertGenerator';
import { indexOfMailingAddress, indexOfPhysicalAddress } from '../../constants/address';
import { checkBoxSameAddressesName } from '../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import { getInfoUpsert } from './infoMappingUtil';
import { UserInfoType } from '../../constants/referenceData/userReferenceData';
import { abbreviationFieldName, licenseFieldName } from '../../constants/fields/userFields';

interface ExistingData {
  idOfPhone?: string;
  userOrganization?: UserOrganization;
  initiallyHasMailingAddress?: boolean;
  info?: Info[];
}

export class UserUpsertGenerator {
  private readonly initialUser: Partial<User>;
  private generatedUserUpsert: UserUpsert;

  constructor(initialUser: Partial<User>) {
    this.initialUser = initialUser;
    this.generatedUserUpsert = { id: this.initialUser.id };
  }

  generateFromUpdatedValues(formValues: UserDetailsFields, existingData: ExistingData): UserUpsert {
    this.generatedUserUpsert = { id: this.initialUser.id };
    this.addRecordToUpsert(formValues);
    this.addPhoneToUpsert(formValues.phone || '', existingData.idOfPhone);
    this.addAddressesToUpsertIfNeeded(formValues, [
      { index: indexOfPhysicalAddress, type: AddressType.Physical },
      {
        index: indexOfMailingAddress,
        type: AddressType.Mailing,
        deleteAddress: existingData.initiallyHasMailingAddress && formValues[checkBoxSameAddressesName],
      },
    ]);
    this.addStatusRecordToUpsert(formValues);
    if (existingData.userOrganization) {
      this.addUserTypesToUpsert(formValues.typeIds || [], existingData.userOrganization);
    }
    const licenseUpsert =
      getInfoUpsert(UserInfoType.UINFO_LICNUM, formValues[licenseFieldName], existingData.info) || [];
    const abbreviationUpsert =
      getInfoUpsert(UserInfoType.UINFO_ABBREV, formValues[abbreviationFieldName], existingData.info) || [];
    this.generatedUserUpsert.info = [...licenseUpsert, ...abbreviationUpsert];
    return this.generatedUserUpsert;
  }

  generateForNewUser(formValues: Partial<UserDetailsFields>): UserUpsert {
    this.generatedUserUpsert = { id: this.initialUser.id };
    if (formValues.phone) {
      this.addPhoneToUpsert(formValues.phone);
    }
    return this.generatedUserUpsert;
  }

  private addRecordToUpsert(updatedValues: UserDetailsFields) {
    // Use existing values for email / cognito as they are not currently editable
    this.generatedUserUpsert.record = {
      name: updatedValues.name,
      email: this.initialUser.email!,
      cognito_id: this.initialUser.cognito_id,
    };
  }

  private addStatusRecordToUpsert(updatedValues: UserDetailsFields) {
    this.generatedUserUpsert.statusRecord = {
      inactive: updatedValues.inactive,
    };
  }

  addPinRecordToUpsert(pin: string) {
    this.generatedUserUpsert.pinRecord = { pin };
    return this.generatedUserUpsert;
  }

  private addPhoneToUpsert(updatedPhone: string, existingPhoneId?: string) {
    this.generatedUserUpsert.phone = new PhoneUpsertGenerator(this.initialUser.phone ?? []).generateFromUpdatedValues(
      updatedPhone,
      existingPhoneId
    );
  }

  private addUserTypesToUpsert(typeIdsFromForm: string[], userOrganization: UserOrganization) {
    const existingTypes = userOrganization.type;
    const existingTypeIds = (existingTypes ?? []).map((existingType) => existingType.type_id);
    const typeIdsToAdd = difference(typeIdsFromForm, existingTypeIds);
    const typeIdsToRemove = difference(existingTypeIds, typeIdsFromForm);
    const userTypeIdsToRemove = (existingTypes ?? [])
      .filter((existingType) => typeIdsToRemove.includes(existingType.type_id))
      .map((existingType) => existingType.id);
    this.generatedUserUpsert.organization = [
      {
        id: userOrganization.id,
        type: UserUpsertGenerator.buildUserOrganizationUserTypeUpserts(typeIdsToAdd, userTypeIdsToRemove),
      },
    ];
  }

  private addAddressesToUpsertIfNeeded(updatedValues: UserDetailsFields, addressUpsertInfos: AddressUpsertInfo[]) {
    const initialAddressUpserts = this.initialUser.address
      ? mapAddressToAddressUpsert(this.initialUser.address)
      : this.initialUser.address;
    this.generatedUserUpsert.address = new AddressUpsertGenerator(initialAddressUpserts).generateFromUpdatedValues(
      updatedValues?.address || [],
      addressUpsertInfos
    );
  }

  public static buildUserOrganizationUserTypeUpserts(typeIdsToAdd: string[], userTypeIdsToRemove: string[]) {
    const result: UserOrganizationUserTypeUpsert[] = [];
    typeIdsToAdd.forEach((typeId) => {
      result.push({ record: { type_id: typeId } });
    });
    userTypeIdsToRemove.forEach((userTypeId) => {
      result.push({ id: userTypeId, void: true });
    });
    return result;
  }
}
