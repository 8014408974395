import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { translations } from '../../../constants/translations';
import { flexWrapContainer } from '../../../globalStyles.style';
import '../../../forms.style.css';
import { routes } from '../../../constants/routes';
import { useContactOfflineInsert, useInsertContact } from '../../../hooks/ajax/contact/contactHooks';
import { AddressType, AddressUpsertInfo } from '../../../classes/upsertGenerators/AddressUpsertGenerator';
import { ContactUpsertGenerator } from '../../../classes/upsertGenerators/ContactUpsertGenerator';
import { checkBoxSameAddressesName } from '../../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { StyledPageHeaderWithMargin } from '../../../components/PageHeader/PageHeader.style';
import { Store } from 'antd/lib/form/interface';
import { chargeInterestFieldName, ContactForm, ContactFormFields } from '../ContactForm/ContactForm';
import { isMatchIgnoringEmptyStrings } from '../../../util/objectComparisons';
import { cloneDeepWithExclude } from '../../../util/cloneDeepWithExclude';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useNavigationToRoute, withContactIdParameter } from '../../../hooks/route/navigationHooks';
import { Contact, ContactStatusType, OrganizationDto, PhoneType, PracticeDto } from '../../../graph/types';
import { FormInstance } from 'antd/lib/form';
import {
  phoneTableActions,
  PhoneTableContextProvider,
  usePhoneTableContextWithDefaults,
} from '../../../components/PhoneTable/phoneTableState';
import { useOffline } from '../../../util/offline/offlineUtil';
import { getOfflineId } from '../../../services/LocalDatabaseService/queries/queryUtils';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { textTypeId } from '../../../hooks/useUpdateContactFromContactPage';
import { CountryId } from '../../../constants/countries';
import { flushSync } from 'react-dom';
import { useOrganizationContext } from '../../../contexts/organization/state';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

const defaultContactStatus = 'active';

interface AddContactPropsParent {
  setCreatedContact?: (contact: Contact) => void;
  form?: FormInstance;
  isModalView?: boolean;
}

const AddContactComp: React.FC<AddContactPropsParent & { practice: PracticeDto; organization: OrganizationDto }> = ({
  organization,
  practice,
  setCreatedContact,
  form,
  isModalView,
}) => {
  const organizationId = practice.organization_id;
  const indexOfPhysicalAddress = 0;
  const indexOfMailingAddress = 1;
  const initiallyHasMailingAddress = false;
  const {
    state: { phoneTableData },
    dispatch: dispatchPhoneTableContext,
  } = usePhoneTableContextWithDefaults();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const contactReferenceData = organization.ref_contact;
  const [insertContact] = useInsertContact();
  const [hideMailingAddress, setHideMailingAddress] = useState<boolean>(!initiallyHasMailingAddress);
  const [unsavedData, setUnsavedData] = useState(false);
  const { navigateBack, navigateTo } = useNavigationToRoute();
  const offlineInsert = useContactOfflineInsert();
  const { enabledAndOffline } = useOffline();
  const physicalCountryId =
    practice.address.find((a) => a.address_type_id === AddressType.Physical)?.country_id ?? CountryId.USA;
  const mailingCountryId =
    practice.address.find((a) => a.address_type_id === AddressType.Mailing)?.country_id ?? physicalCountryId;

  useEffect(() => {
    dispatchPhoneTableContext(
      phoneTableActions.setPhoneTypeRefData(organization?.ref_demographics?.phone_type as PhoneType[])
    );
  }, [contactReferenceData, dispatchPhoneTableContext, organization]);

  [form] = Form.useForm(form);

  const contactStatuses = contactReferenceData?.status_type;
  if (!contactStatuses) {
    return <p>{translations.addContactPage.contactStatusesNotLoaded}</p>;
  }

  const activeStatus = contactStatuses.find((status) => status.name.toLowerCase() === defaultContactStatus);
  const initialFormValues = {
    contact_status_id: activeStatus?.id,
    [checkBoxSameAddressesName]: !initiallyHasMailingAddress,
    [chargeInterestFieldName]: true,
    address: {
      [indexOfPhysicalAddress]: {
        country_id: physicalCountryId,
      },
      [indexOfMailingAddress]: {
        country_id: mailingCountryId,
      },
    },
  };

  const onFinish = async (values: ContactFormFields) => {
    setIsSaving(true);
    const addressUpsertInfos: AddressUpsertInfo[] = [
      { index: indexOfPhysicalAddress, type: AddressType.Physical },
      { index: indexOfMailingAddress, type: AddressType.Mailing },
    ];

    const normalizedText = [
      {
        id: ID_FOR_OBJECT_CREATION,
        record: {
          value: values.note,
          type_id: textTypeId,
          offline_id: enabledAndOffline ? getOfflineId() : null,
        },
      },
    ];

    const contactUpsert = ContactUpsertGenerator.generateNewEntry(
      values,
      addressUpsertInfos,
      practice.id,
      phoneTableData,
      values.note ? normalizedText : undefined
    );

    try {
      let insertedContact: Contact | undefined | null;
      if (!enabledAndOffline) {
        const { data } = await insertContact({
          variables: {
            organizationId,
            contact: contactUpsert,
          },
        });
        insertedContact = data?.upsertContact;
      } else {
        insertedContact = await offlineInsert?.(contactUpsert);
      }
      if (insertedContact) {
        flushSync(() => {
          setUnsavedData(false);
        });

        const contactName = insertedContact.name;
        showSuccessMessage(translations.addContactPage.getSuccessMessage(contactName));

        if (!isModalView) {
          const contactId = insertedContact.id;
          navigateTo(routes.viewContact, withContactIdParameter(contactId));
        }
        if (setCreatedContact) {
          setCreatedContact(insertedContact);
        }
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    const isMailingAddressSame = allValues[checkBoxSameAddressesName];
    setHideMailingAddress(isMailingAddressSame);
    // Don't want to check inital values here
    const checkValues = cloneDeepWithExclude(allValues, [checkBoxSameAddressesName, 'contact_status_id']);
    setUnsavedData(!isMatchIgnoringEmptyStrings(checkValues, null));
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={unsavedData}
      warningMessage={translations.shared.getCreationNavigationWarning(translations.addContactPage.createdEntity)}
    >
      <div>
        {!isModalView && <StyledPageHeaderWithMargin onBack={navigateBack} title={translations.addContactPage.title} />}
        <Form
          {...layout}
          form={form}
          initialValues={initialFormValues}
          onFinish={onFinish}
          onValuesChange={handleValueChange}
          style={{ ...flexWrapContainer, ...{ padding: '0 2rem' } }}
          autoComplete='new-password'
        >
          <ContactForm
            indexOfPhysicalAddress={indexOfPhysicalAddress}
            indexOfMailingAddress={indexOfMailingAddress}
            hideMailingAddress={hideMailingAddress}
            contactStatuses={contactStatuses as ContactStatusType[]}
            form={form}
            isModalView={isModalView}
            newContact
          />

          {!isModalView && (
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <Form.Item wrapperCol={{ offset: 3 }}>
                  <Button type='primary' htmlType='submit'>
                    {translations.addContactPage.buttons.save}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </SaveSpinnerAndNavigationWarning>
  );
};

export const AddContact: React.FC<AddContactPropsParent> = (props) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice?.find((practice) => practice.id === organization.default_practice_id);

  if (!organization || !practice) {
    return <p>{translations.practiceSettings.noPractice}</p>;
  }

  return (
    <PhoneTableContextProvider>
      <AddContactComp {...props} practice={practice} organization={organization} />
    </PhoneTableContextProvider>
  );
};
