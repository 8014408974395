import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { LabErrorNameKeyTranslation, translations } from '../../constants/translations';
import { LabSettingsAntech, OrganizationSubscriptionResult } from '../../graph/types';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useForm } from 'antd/lib/form/Form';
import { getRequiredRule } from '../../util/forms';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { useUpsertLabSettingsAntech } from '../../hooks/ajax/lab/labHooks';
import { ErrorText, InstructionContainer } from '../../globalStyles.style';
import { SubscriptionChargesInfo } from '../SubscriptionChargesInfo/SubscriptionChargesInfo';

const layout = {
  labelCol: { lg: 8 },
  wrapperCol: { span: 24 },
};

export interface AntechLabConfigurationModalProps {
  addOnEnabled: boolean;
  organizationId: string;
  subscription?: OrganizationSubscriptionResult;
  currentSettings?: LabSettingsAntech;
  onSave: () => void;
  onClose: () => void;
}

export const AntechLabConfigurationModal: React.FC<AntechLabConfigurationModalProps> = ({
  addOnEnabled,
  organizationId,
  subscription,
  currentSettings,
  onSave,
  onClose,
}: AntechLabConfigurationModalProps) => {
  const [form] = useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const upsertLabSettingsAntech = useUpsertLabSettingsAntech();

  const handleSave = async (values: any) => {
    try {
      setIsSaving(true);
      setErrorMessage(undefined);

      await upsertLabSettingsAntech(organizationId, values);

      onSave();

      setIsSaving(false);
    } catch (err) {
      if ((err as any).message === 'Network Error') {
        setErrorMessage(translations.shared.networkError);
      } else {
        setErrorMessage(LabErrorNameKeyTranslation((err as any).message));
      }
      setIsSaving(false);
    }
  };

  return (
    <ModalWithCloseConfirm
      title={translations.antechLabConfigurationModal.title}
      open
      onCancel={onClose}
      onOk={() => form.submit()}
      okText={translations.antechLabConfigurationModal.saveButtonText}
      okButtonProps={{ disabled: isSaving }}
      isSaving={isSaving}
      savingMessage={translations.shared.communicatingWithLabMessage}
      width={500}
    >
      {errorMessage && (
        <Row>
          <Col>
            <ErrorText>{errorMessage}</ErrorText>
          </Col>
        </Row>
      )}
      <>
        <InstructionContainer>{translations.antechLabConfigurationModal.instructionMessage1}</InstructionContainer>
        <InstructionContainer
          dangerouslySetInnerHTML={{ __html: translations.antechLabConfigurationModal.instructionMessage2 }}
        />
        <InstructionContainer
          dangerouslySetInnerHTML={{ __html: translations.antechLabConfigurationModal.instructionMessage3 }}
        />
      </>

      <Form {...layout} form={form} onFinish={handleSave} autoComplete='off'>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <MaxLengthFormItem
              name='account_id'
              label={translations.antechLabConfigurationModal.fields.accountId}
              rules={[getRequiredRule(translations.antechLabConfigurationModal.fields.accountId)]}
              initialValue={currentSettings?.account_id}
              maxLength={255}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='clinic_id'
              label={translations.antechLabConfigurationModal.fields.clinicId}
              rules={[getRequiredRule(translations.antechLabConfigurationModal.fields.clinicId)]}
              initialValue={currentSettings?.clinic_id}
              maxLength={255}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='user_id'
              label={translations.antechLabConfigurationModal.fields.username}
              rules={[getRequiredRule(translations.antechLabConfigurationModal.fields.username)]}
              initialValue={currentSettings?.user_id}
              maxLength={255}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='password'
              label={translations.antechLabConfigurationModal.fields.password}
              rules={[getRequiredRule(translations.antechLabConfigurationModal.fields.password)]}
              initialValue={currentSettings?.password}
              maxLength={255}
            >
              <Input.Password autoComplete='new-password' />
            </MaxLengthFormItem>

            <MaxLengthFormItem
              name='antech_lab_id'
              label={translations.antechLabConfigurationModal.fields.antechLabId}
              rules={[getRequiredRule(translations.antechLabConfigurationModal.fields.antechLabId)]}
              initialValue={currentSettings?.antech_lab_id}
              maxLength={255}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>
          </Col>
        </Row>
      </Form>

      {!addOnEnabled && (
        <Row>
          <Col offset={2} span={20}>
            <SubscriptionChargesInfo subscription={subscription} />
          </Col>
        </Row>
      )}
    </ModalWithCloseConfirm>
  );
};
