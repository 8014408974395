import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import Input from 'antd/lib/input';

export const StyledPhoneInput = styled(PhoneInput)`
  max-width: 185px;

  & .PhoneInputInput {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px;
  }

  & .PhoneInputInput:focus {
    border-color: #929c9a;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(121 143 140 / 20%);
  }
`;

export const StyledInput = styled(Input)`
  min-width: 70px;
`;
