import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loading } from '../../components/Loading/Loading';
import { ComponentWithPracticeProps } from '../../components/WithPractice/WithPractice';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { translations } from '../../constants/translations';
import { useGetLabDevices } from '../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getLabOptions, labTabsHeadingTranslations } from '../../util/labUtil';
import { selectHasChanges } from './store/selectors';
import { LabManagementContext } from './store/state';

const StyledSelect = styled(Select<string>)`
  margin-bottom: 1em;
`;

export const labSelectTestId = 'lab-select-test-id';

interface MappingTabHeaderProps extends ComponentWithPracticeProps {
  render: (lab: string, organizationId: string) => void;
  instructions: string;
}

export const MappingTabHeader: React.FC<MappingTabHeaderProps> = ({ practice, instructions, render }) => {
  const [selectedLab, setSelectedLab] = useState<string>();
  const organizationId = useGetOrganizationIdFromRoute();

  const { labDevices, loading } = useGetLabDevices(organizationId, practice.id);

  const { state } = useContext(LabManagementContext);
  const hasChanges = selectHasChanges(state);

  useEffect(() => {
    if (!selectedLab) {
      setSelectedLab(labDevices?.[0]?.id);
    }
  }, [labDevices, selectedLab, setSelectedLab]);

  if (loading) {
    return <Loading labelText={translations.shared.communicatingWithLabMessage} />;
  }

  if (!selectedLab) {
    return <p>{labTabsHeadingTranslations.noLabsMessage}</p>;
  }

  const labOptions = getLabOptions(labDevices ?? []);
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: instructions }} />
      <StyledSelect
        {...searchableSelectParams}
        options={labOptions}
        placeholder={labTabsHeadingTranslations.labSelectPlaceholder}
        onChange={setSelectedLab}
        data-testid={labSelectTestId}
        value={selectedLab}
        disabled={hasChanges}
      />
      {render(selectedLab, organizationId)}
    </>
  );
};
