import React, { ReactNode } from 'react';
import { translations } from '../../../constants/translations';
import { Patient } from '../../../graph/types';
import { joinNameAndNumber } from '../../../util/displaying';
import DoctorOfficeContactPatientTab from './DoctorOfficeContactPatientTab';
import { RapidBillingContainer } from '../../RapidBilling/RapidBillingContainer';
import { ServicesRenderedContextProvider } from '../../../components/ServiceRendered/store/state';

export interface TabDefinition {
  key: string;
  route: string;
  title: string;
  content: ReactNode;
  onClick?: () => void;
}

export const patientsSubRoute = '/patient';
export const getTabs = (organizationId: string, contactId: string, contactCardRoute: string, patients?: Patient[]) => {
  return [
    ...getStandardTabs(contactCardRoute, contactId),
    ...getPatientsTabs(organizationId, patients ?? [], contactCardRoute),
  ];
};

export const getPatientsTabs = (organizationId: string, patients: Patient[], contactCardRoute: string) => {
  const contactPatientRoute = (id: string) => `${contactCardRoute}/patient/${id}`;
  return patients.map((patient) => ({
    key: `patient-${patient.id}`,
    route: contactPatientRoute(patient.id),
    title: joinNameAndNumber({ name: patient.name, number: patient.number }),
    content: (
      <DoctorOfficeContactPatientTab
        patientId={patient.id}
        organizationId={organizationId}
        contactCardRoute={contactPatientRoute(patient.id)}
      />
    ),
  }));
};

export const getStandardTabs = (contactCardRoute: string, contactId: string) => [
  {
    key: 'rapidBilling',
    route: `${contactCardRoute}/rapidBilling`,
    title: translations.rapidBilling.pageTitle,
    content: (
      <ServicesRenderedContextProvider contactId={contactId}>
        <RapidBillingContainer contactId={contactId} />
      </ServicesRenderedContextProvider>
    ),
  },
];
