import { DatePicker, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import ModalWithCloseConfirm from '../../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { SaveSpinner } from '../../../../components/SaveSpinner/SaveSpinner';
import { translations } from '../../../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { getRequiredRule } from '../../../../util/forms';
import { Contact, JobUpsert, QueryRptGetStatementsArgs } from '../../../../graph/types';
import { getReportData, Reports } from '../../../../util/reportUtils';
import { GetStatementReport } from '../../../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../../../hooks/ajax/report/reportHooks';
import { getStatementReportVariables } from '../statementUtils';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';
import { getJobRecordUpsert } from '../../../../util/jobUtil';
import { useUpsertJob } from '../../../../hooks/ajax/job/jobHooks';
import { showErrorMessage } from '../../../../components/Notification/notificationUtil';
import { upsertDateFormat } from '../../../../constants/formats';

interface PrintContactStatementModalProps {
  contact: Contact;
  onClose: () => void;
  practiceId: string;
  canUseCollection: boolean;
  setJobId: (value: string | undefined) => void;
}

const startEndDatesFieldName = 'startEndDates';

export const printContactStatementModalTest = {
  dateRangePicker: 'dateRangePicker',
  startDatePicker: 'startDatePicker',
  endDatePicker: 'endDatePicker',
};

export const PrintContactStatementModal: React.FC<PrintContactStatementModalProps> = ({
  contact,
  onClose,
  practiceId,
  canUseCollection,
  setJobId,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [form] = useForm();
  const usesActiveReports = useLDFlag(LDFlagNames.ActiveReports);
  const navigateToReportViewer = useNavigateToReportViewer();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const [upsertJob] = useUpsertJob();

  const handleFinish = async ({ startEndDates }: { [startEndDatesFieldName]: Dayjs[] }) => {
    setIsReportLoading(true);
    const [startDate, endDate] = startEndDates;
    const params = await getStatementReportVariables(
      contact,
      startDate.format(upsertDateFormat),
      endDate.format(upsertDateFormat),
      practiceId,
      organizationId
    );
    if (usesActiveReports) {
      const reportData = await getReportData<'rptGetStatements', QueryRptGetStatementsArgs>(GetStatementReport, {
        organizationId,
        practiceId,
        reportParameters: {
          start_date: params.startDate,
          end_date: params.endDate,
          contact_id: params.contactId,
          footer: params.footer,
        },
      });
      navigateToReportViewer({
        reportName: Reports.Statement,
        data: reportData,
        nestedHeader: true,
      });
    } else {
      if (!canUseCollection) {
        const jobRecord = getJobRecordUpsert(GetStatementReport, {
          organizationId,
          practiceId,
          reportParameters: {
            start_date: params.startDate,
            end_date: params.endDate,
            contact_id: params.contactId,
            footer: params.footer,
          },
        });

        const job: JobUpsert = {
          record: jobRecord,
        };

        const jobVariables = {
          organizationId,
          job,
        };

        try {
          const { data } = await upsertJob({
            variables: jobVariables,
          });

          setJobId(data?.upsertJob.id);
        } catch (e) {
          showErrorMessage(translations.shared.generalErrorMessage);
        }
      }
    }

    setIsReportLoading(false);
    onClose();
  };

  return (
    <ModalWithCloseConfirm
      open
      onCancel={onClose}
      title={translations.printStatementModal.title}
      onOk={form.submit}
      okText={translations.printStatementModal.printButton}
      okButtonProps={{ disabled: isReportLoading }}
      cancelText={translations.shared.cancelButtonText}
    >
      <SaveSpinner isSaving={isReportLoading}>
        <Form onFinish={handleFinish} form={form} autoComplete='off'>
          <Form.Item
            label={translations.printStatementModal.labels.date}
            name={startEndDatesFieldName}
            labelCol={{ span: 6 }}
            rules={[getRequiredRule(translations.printStatementModal.labels.date)]}
            data-testid={printContactStatementModalTest.dateRangePicker}
          >
            <DatePicker.RangePicker
              format={dateFormat}
              placeholder={[
                translations.printStatementModal.labels.startDate,
                translations.printStatementModal.labels.endDate,
              ]}
              disabledDate={(date) => date.isAfter(dayjs())}
            />
          </Form.Item>
        </Form>
      </SaveSpinner>
    </ModalWithCloseConfirm>
  );
};
