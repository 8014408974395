import React, { useContext, useMemo } from 'react';
import { Phone } from '../../../graph/types';
import { phoneTableActions, PhoneTableContext } from '../phoneTableState';
import { useHandlePhoneTableRowEdit } from '../phoneTableUtil';
import { Select } from 'antd';
import { translations } from '../../../constants/translations';
import { SelectProps } from 'antd/es/select';

type PhoneTableTypeCellProps = {
  phone: Phone;
};

export const phoneTableTypeInputTestId = 'PHONE_TABLE_TYPE_INPUT_TEST_ID';

export default function PhoneTableTypeCell({ phone }: PhoneTableTypeCellProps) {
  const {
    state: { currentEditingPhone, phoneTypeRefData },
    dispatch,
  } = useContext(PhoneTableContext);

  const typeName = useMemo(() => {
    return phone.phone_type_name
      ? phone.phone_type_name
      : phoneTypeRefData.find((refData) => refData.id === phone.phone_type_id)?.name;
  }, [phone.phone_type_id, phone.phone_type_name, phoneTypeRefData]);

  const handleEdit = useHandlePhoneTableRowEdit(phone);

  const selectOptions: SelectProps<string>['options'] = useMemo(
    () =>
      phoneTypeRefData.map((refData) => ({
        label: refData.name,
        value: refData.id,
      })),
    [phoneTypeRefData]
  );

  const handleSelectInputChange: SelectProps<string>['onChange'] = (value) => {
    if (!currentEditingPhone) {
      return;
    }
    dispatch(
      phoneTableActions.setCurrentEditing({
        ...currentEditingPhone,
        phone: { ...currentEditingPhone.phone, phone_type_id: value },
      })
    );
  };

  return currentEditingPhone?.phone?.id === phone.id ? (
    <Select
      placeholder={translations.phoneTable.inputs.type}
      value={currentEditingPhone.phone.phone_type_id ?? ''}
      options={selectOptions}
      onChange={handleSelectInputChange}
      style={{ minWidth: '80px' }}
      data-testid={phoneTableTypeInputTestId}
    />
  ) : (
    <div onClick={handleEdit}>{typeName}</div>
  );
}
