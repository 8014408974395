import { Tabs } from 'antd';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Loading } from '../../../components/Loading/Loading';
import TabsWithRouting from '../../../components/TabsWithRouting/TabsWithRouting';
import { getRouteWithOrganizationId } from '../../../constants/routes';
import { UserOrganizationContactCard } from '../../../graph/types';
import { useGetContactPatientsWithSearch } from '../../../hooks/ajax/patients/patientHooks';
import { useContactSubscription } from '../../../hooks/contactSubscriptionHook';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { getOnClickPerTabKey, getRoutePerTabKey } from '../DoctorOfficePatientTab/doctorOfficePatientTabUtil';
import { EntityCard, getContactCardRouteWithFilledInContactIdParameter } from '../doctorOfficeUtil';
import { maxHeight, borderTop } from './DoctorOfficeContactPatientTab';
import './DoctorOfficeContactTab.less';
import DoctorOfficeContactTabHeader from './DoctorOfficeContactTabHeader';
import { getTabs, patientsSubRoute } from './doctorOfficeContactTabUtil';

const { TabPane } = Tabs;

interface DoctorOfficeContactTabProps {
  contactCard: UserOrganizationContactCard;
  card?: EntityCard;
  closeEntityCard?: (cardId: string) => Promise<void>;
}

const ConstrainedContainer = styled.div`
  max-height: ${maxHeight};
  overflow: auto;
`;

export const DoctorOfficeContactTab: React.FC<DoctorOfficeContactTabProps> = ({ contactCard, closeEntityCard }) => {
  const contactCardRoute = getContactCardRouteWithFilledInContactIdParameter(contactCard);
  const organizationId = useGetOrganizationIdFromRoute();
  const { patients, patientsLoading, refetchCurrentSearch } = useGetContactPatientsWithSearch(
    contactCard.contact_id,
    organizationId
  );
  useContactSubscription(contactCard.contact_id, refetchCurrentSearch);

  if (patientsLoading) {
    return <Loading height={'60vh'} />;
  }

  const tabs = getTabs(organizationId, contactCard.contact_id, contactCardRoute, patients);

  const patientsRedirectRoute = getRouteWithOrganizationId(
    patients?.[0] ? `${contactCardRoute}${patientsSubRoute}/${patients[0].id}` : contactCardRoute,
    organizationId
  );

  return (
    <>
      <Switch>
        <Route exact path={`${contactCardRoute}${patientsSubRoute}`}>
          <Redirect to={patientsRedirectRoute} />
        </Route>
        <Route path={contactCardRoute}>
          <DoctorOfficeContactTabHeader contactCard={contactCard} closeEntityCard={closeEntityCard} />
          <TabsWithRouting
            className={'DoctorOfficeContactSubTabs'}
            tabPosition={'left'}
            routePerTabKey={getRoutePerTabKey(tabs, false)}
            onClickPerTabKey={getOnClickPerTabKey(tabs)}
            forcedRouteIfNoTabSelected={{ path: `${contactCardRoute}/rapidBilling`, exact: true }}
            tabBarStyle={{ width: 250 }}
            style={{ maxHeight, borderTop }}
          >
            {tabs.map((tab) => (
              <TabPane tab={tab.title} key={tab.key}>
                <ConstrainedContainer>{tab.content}</ConstrainedContainer>
              </TabPane>
            ))}
          </TabsWithRouting>
        </Route>
      </Switch>
    </>
  );
};
