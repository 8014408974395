import { useMemo } from 'react';

export type MaybeHasNameAndNumber = {
  name?: string | null;
  number?: string | null;
};

type HasId = {
  id: string;
};

type MaybeValueType<ValueType> = ValueType | null | undefined;

// all keys of ObjectType whose value has the ValueType or null or undefined
type KeysWithMaybeValueType<ObjectType, ValueType> = {
  [K in keyof ObjectType]: ObjectType[K] extends MaybeValueType<ValueType> ? K : never;
}[keyof ObjectType];

function filterStringProperties<T>(
  searchInput: string | undefined,
  arrayToFilter: T[] | undefined,
  propertiesToFilterIn: KeysWithMaybeValueType<T, string>[]
): T[] | undefined {
  if (!searchInput || !arrayToFilter) {
    return arrayToFilter;
  }
  const searchInputs = searchInput.split(' ').map((input) => input.toLowerCase());
  return arrayToFilter.filter((element) =>
    searchInputs.every((input) =>
      propertiesToFilterIn.some((property) =>
        (element[property] as unknown as MaybeValueType<string>)?.toLowerCase().includes(input)
      )
    )
  );
}

export function excludeById<T extends HasId>(
  excludedIds: string[] | undefined,
  objectsToFilter: T[] | undefined
): T[] | undefined {
  if (!excludedIds) {
    return objectsToFilter;
  }
  return objectsToFilter?.filter((object) => !excludedIds.includes(object.id));
}

export function useFilterByKeysWithStringValues<T>(
  arrayToFilter: T[] | undefined,
  searchValue: string | undefined,
  keysToFilterBy: KeysWithMaybeValueType<T, string>[]
) {
  return useMemo(() => {
    const filteredArray = filterStringProperties<T>(searchValue, arrayToFilter, keysToFilterBy);
    return filteredArray || [];
  }, [arrayToFilter, keysToFilterBy, searchValue]);
}

export function excludeExisting(arrayWithIdsToExclude: any[], arrayToExcludeFrom: any[], field = 'id') {
  const set = new Set(arrayWithIdsToExclude.map((item) => item[field]));
  return arrayToExcludeFrom.filter((item) => !set.has(item[field]));
}
