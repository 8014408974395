import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Contact,
  File,
  InfoText,
  Invoice,
  Ledger,
  Note,
  Patient,
  Prescription,
  PrescriptionFlattened,
  Query,
  Reminder,
  Service,
  ServiceActivity,
  ServiceRendered,
  User,
} from '../../graph/types';
import { OfflineModeContext } from './store/state';
import { OfflineModeActions } from './store/actions';
import { CollectionType, ReplicationCollection } from '../../services/LocalDatabaseService/collections';
import { Subscription } from 'rxjs';
import { USER_KEY } from '../../constants/sessionStorageKeys';
import { addRxPlugin, hash, RxDocumentBase, RxJsonSchema } from 'rxdb';
import { useRxDB } from 'rxdb-hooks';
import { collectionsWithUpserts } from '../../services/LocalDatabaseService/databaseUtils';
import JSZip from 'jszip';
import {
  RxDBReplicationGraphQLPlugin,
  setLastPullDocument,
  setLastPushSequence,
} from 'rxdb/plugins/replication-graphql';
import config from '../../config/config';
import {
  addOfflineContactProperties,
  addOfflineFileProperties,
  addOfflineInvoiceProperties,
  addOfflineNoteProperties,
  addOfflinePatientProperties,
  addOfflinePrescriptionProperties,
  addOfflineReminderProperties,
  addOfflineServiceActivityProperties,
} from './offlineQueryUtils';
import { patientSchema, RxPatient } from '../../services/LocalDatabaseService/schemas/patientSchema';
import { pick } from 'lodash';
import { contactSchema, RxContact } from '../../services/LocalDatabaseService/schemas/contactSchema';
import { noteSchema, RxNote } from '../../services/LocalDatabaseService/schemas/noteSchema';
import {
  RxServiceRendered,
  serviceRenderedSchema,
} from '../../services/LocalDatabaseService/schemas/serviceRenderedSchema';
import { fileSchema, RxFile } from '../../services/LocalDatabaseService/schemas/fileSchema';
import { ledgerSchema, RxLedger } from '../../services/LocalDatabaseService/schemas/ledgerSchema';
import { invoiceSchema, RxInvoice } from '../../services/LocalDatabaseService/schemas/invoiceSchema';
import { prescriptionSchema, RxPrescription } from '../../services/LocalDatabaseService/schemas/prescriptionSchema';
import {
  RxServiceActivity,
  serviceActivitySchema,
} from '../../services/LocalDatabaseService/schemas/serviceActivititySchema';
import { reminderSchema, RxReminder } from '../../services/LocalDatabaseService/schemas/reminderSchema';
import { infoTextSchema, RxInfoText } from '../../services/LocalDatabaseService/schemas/infoTextSchema';
import { RxService, serviceSchema } from '../../services/LocalDatabaseService/schemas/serviceSchema';
import { RxDatabaseBaseExtended } from 'rxdb-hooks/dist/plugins';
import { useUserContext } from '../../contexts/user/state';
import { Auth } from 'aws-amplify';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetCurrentUser } from '../../graph/queries/users';
import {
  RxPrescriptionFlattened,
  prescriptionFlattenedSchema,
} from '../../services/LocalDatabaseService/schemas/prescriptionFlattenedSchema';

addRxPlugin(RxDBReplicationGraphQLPlugin);

export type ReplicationReady = {
  initialLoaded: boolean;
  indexesBuilt: boolean;
  error: boolean | string;
};

export type LastUpdatedCollectionData = { lastDate: string; lastId: string };

export type LastUpdatedData = { [collection: string]: LastUpdatedCollectionData };

export type ReplicationData = {
  unsyncedData: RxDocumentBase<unknown, Record<string, unknown>>[] | undefined;
  initialLoaded: boolean;
  indexesBuilt: boolean;
  error: string | boolean;
  collection: string;
};

const offlineModeKey = 'cassadol-offline';
export const localStorageCollectionReplications = 'cassadol-collections-initial-replications';
export const offlineTimer = 'cassadol-offline-timer';
export const localStorageBulkLoaded = 'cassadol-collections-bulk-loaded';

export const replicationsStateChangedEvent = new CustomEvent('replicationStateChanged');
export const replicationErrorEvent = new CustomEvent('replicationErrorEvent');

const onlineStates = ['online', 'offline'];

const isOfflineModeEnabled = (userId: string) => localStorage.getItem(`${offlineModeKey}-${userId}`) === 'true';

const { setOnline, setOfflineModeEnabled, setOfflineModeInitialized, resetOfflineModeContext, setOfflineFile } =
  OfflineModeActions;

const setLocalOfflineEnabled = (userId: string, enabled: boolean) =>
  localStorage.setItem(`${offlineModeKey}-${userId}`, enabled.toString());

export const useOffline = (collection: CollectionType | '' = '') => {
  const { state, dispatch } = useContext(OfflineModeContext);
  const setIsOnline = useCallback((online: boolean) => dispatch(setOnline(online)), [dispatch]);
  const setEnabled = useCallback((enabled: boolean) => dispatch(setOfflineModeEnabled(enabled)), [dispatch]);
  const setInitialized = useCallback(
    (initialized: boolean) => dispatch(setOfflineModeInitialized(initialized)),
    [dispatch]
  );
  const resetContext = useCallback(() => dispatch(resetOfflineModeContext()), [dispatch]);
  const [showOfflineWarningBanner, setShowOfflineWarningBanner] = useState(false);
  const setFile = useCallback((file: any) => dispatch(setOfflineFile(file)), [dispatch]);

  const {
    state: { user: currentUser },
  } = useUserContext();

  useEffect(() => {
    if (state.initialized) {
      return;
    }

    const init = async () => {
      let user: User | null | undefined;
      if (state.isOnline) {
        try {
          await Auth.currentAuthenticatedUser();
          const { data } = await AppSyncService.client.query<Pick<Query, 'getCurrentUser'>>({
            query: GetCurrentUser,
          });
          user = data?.getCurrentUser;
        } catch (_) {}
      } else {
        user = JSON.parse(localStorage.getItem(USER_KEY) ?? '{}');
      }

      setEnabled(isOfflineModeEnabled(user?.id ?? ''));
      setInitialized(true);
    };
    init();
  }, [state.initialized, state.isOnline, setInitialized, setEnabled, currentUser]);

  useEffect(() => {
    if (!state.isOnline && !localStorage.getItem(offlineTimer)) {
      setOfflineTimer();
    }
    if (state.isOnline) {
      clearOfflineTimer();
    }
    setShowOfflineWarningBanner(false);
  }, [state.isOnline]);

  useEffect(() => {
    const onOnlineStateChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    onlineStates.forEach((state) => window.addEventListener(state, onOnlineStateChange));

    return () => onlineStates.forEach((state) => window.removeEventListener(state, onOnlineStateChange));
  }, [setIsOnline, state.enabled]);

  const setOfflineEnabled = useCallback(
    (enabled: boolean, userId: string) => {
      setEnabled(enabled);
      setLocalOfflineEnabled(userId, enabled);
    },
    [setEnabled]
  );

  const enabledAndOffline = !state.isOnline && state.enabled;
  const canUseCollection = useMemo(() => {
    const replications = getLocalReplications();
    return !!(
      (['practice', 'invoice_context', 'reference_data'].indexOf(collection) >= 0 ||
        replications[collection]?.initialLoaded) &&
      enabledAndOffline
    );
  }, [enabledAndOffline, collection]);

  return {
    ...state,
    enabledAndOffline,
    canUseCollection,
    setOfflineEnabled,
    resetOfflineContext: resetContext,
    showOfflineWarningBanner,
    setShowOfflineWarningBanner,
    setIsOnline,
    setFile,
  };
};

export const isUuid = (id: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);
};

export const useTriggerReplicationWhenOnline = () => {
  const {
    state: { replications },
  } = useContext(OfflineModeContext);
  const { isOnline } = useOffline();
  const [initialOnline, setInitialOnline] = useState<boolean>(isOnline);

  useEffect(() => {
    if (isOnline && !initialOnline) {
      Object.values(replications).forEach((replication) => {
        replication.state?.run();
      });
    }

    setInitialOnline(isOnline);
  }, [replications, isOnline, initialOnline, setInitialOnline]);
};

export const useUnsyncedData = () => {
  const [unsyncedData, setUnsyncedData] =
    useState<Record<string, RxDocumentBase<unknown, Record<string, unknown>>[] | undefined>>();
  const { enabled: offlineEnabled } = useOffline();
  const db = useRxDB();

  useEffect(() => {
    const subscriptions: (Subscription | undefined)[] = [];
    if (offlineEnabled) {
      Object.keys(collectionsWithUpserts).forEach(async (collection) => {
        const sub = await db?.collections[collection]
          ?.find({ selector: { upsert: { $ne: null } } })
          .$.subscribe((data: RxDocumentBase<unknown, Record<string, unknown>>[] | undefined) => {
            if (data?.length) {
              setUnsyncedData((d) => ({ ...d, [collection]: data }));
            } else {
              setUnsyncedData((d) => ({ ...d, [collection]: undefined }));
            }
          });

        subscriptions.push(sub);
      });
    }
    return () => {
      return subscriptions.forEach((s) => s?.unsubscribe());
    };
  }, [offlineEnabled, db]);

  return { hasUnsyncedData: !!Object.values(unsyncedData ?? {}).find((hasData) => hasData), unsyncedData };
};

export const getInitialReplications = () => {
  const initialReplications: Record<string, ReplicationReady> = {};
  Object.values(ReplicationCollection).forEach((collectionName) => {
    initialReplications[collectionName] = {
      initialLoaded: false,
      indexesBuilt: false,
      error: false,
    };
  });
  return initialReplications;
};

const getInitialReplicationsJson = () => {
  return JSON.stringify(getInitialReplications());
};

export const setInitialLocalReplications = () => {
  localStorage.setItem(localStorageBulkLoaded, 'false');
  localStorage.setItem(localStorageCollectionReplications, getInitialReplicationsJson());
  window.dispatchEvent(replicationsStateChangedEvent);
};

export const setLocalReplications = (collection: string, value: boolean | string, property: keyof ReplicationReady) => {
  const replications = JSON.parse(
    localStorage.getItem(localStorageCollectionReplications) ?? getInitialReplicationsJson()
  );
  if (!replications[collection]) {
    replications[collection] = { initialLoaded: false, indexesBuilt: false, error: false };
  }

  const updatedReplications = {
    ...replications,
    [collection]: Object.assign(replications[collection], { [property]: value }),
  };
  localStorage.setItem(localStorageCollectionReplications, JSON.stringify(updatedReplications));
};

export const getLocalReplications = (): Record<string, ReplicationReady> => {
  const json = localStorage.getItem(localStorageCollectionReplications);
  return JSON.parse(json ?? getInitialReplicationsJson());
};

export const setOfflineTimer = () => {
  localStorage.setItem(offlineTimer, Date.now().toString());
};

export const clearOfflineTimer = () => {
  localStorage.removeItem(offlineTimer);
};

export const getOfflineTimer = () => {
  return parseFloat(localStorage.getItem(offlineTimer) ?? Date.now().toString());
};

export const hasBeenOffline24Hours = () => {
  const difference = Date.now() - getOfflineTimer();
  return difference > 1000 * 60 * 60 * 24;
};

export const setBulkLoaded = () => {
  localStorage.setItem(localStorageBulkLoaded, 'true');
};

export const getBulkLoaded = () => {
  const bulkLoaded = localStorage.getItem(localStorageBulkLoaded);
  return bulkLoaded === 'true';
};

const getPropertiesFromSchemaObject = <T, R>(entry: T, schema: RxJsonSchema<R>) => {
  const offlineKeys = Object.keys(schema.properties);
  const offlineEntry = pick(entry, offlineKeys);
  return offlineEntry as T;
};

export const newOrgEmptyBulkFileName = 'new_org_empty_bulk_file';

export const handleBulkLoad = async (file: any, db: RxDatabaseBaseExtended) => {
  if (file.name === newOrgEmptyBulkFileName) {
    return;
  }

  const zip = new JSZip();
  const zipFile = await zip.loadAsync(file);

  for (const collection of Object.keys(zipFile.files)) {
    const json = await zipFile.file(collection)?.async('string');
    const data = JSON.parse(json ?? '');
    const normalizedData = data.map((entry: any) => {
      switch (collection) {
        case ReplicationCollection.Patient:
          const patient = getPropertiesFromSchemaObject<Patient, RxPatient>(entry, patientSchema);
          return addOfflinePatientProperties(patient);
        case ReplicationCollection.Contact:
          const contact = getPropertiesFromSchemaObject<Contact, RxContact>(entry, contactSchema);
          return addOfflineContactProperties(contact);
        case ReplicationCollection.Note:
          const note = getPropertiesFromSchemaObject<Note, RxNote>(entry, noteSchema);
          return addOfflineNoteProperties(note);
        case ReplicationCollection.ServiceRendered:
          const serviceRendered = getPropertiesFromSchemaObject<ServiceRendered, RxServiceRendered>(
            entry,
            serviceRenderedSchema
          );
          return serviceRendered;
        case ReplicationCollection.File:
          const file = getPropertiesFromSchemaObject<File, RxFile>(entry, fileSchema);
          return addOfflineFileProperties(file);
        case ReplicationCollection.Ledger:
          const ledger = getPropertiesFromSchemaObject<Ledger, RxLedger>(entry, ledgerSchema);
          return ledger;
        case ReplicationCollection.Invoice:
          const invoice = getPropertiesFromSchemaObject<Invoice, RxInvoice>(entry, invoiceSchema);
          return addOfflineInvoiceProperties(invoice);
        case ReplicationCollection.Prescription:
          const prescription = getPropertiesFromSchemaObject<Prescription, RxPrescription>(entry, prescriptionSchema);
          return addOfflinePrescriptionProperties(prescription);
        case ReplicationCollection.ServiceActivity:
          const serviceActivity = getPropertiesFromSchemaObject<ServiceActivity, RxServiceActivity>(
            entry,
            serviceActivitySchema
          );
          return addOfflineServiceActivityProperties(serviceActivity);
        case ReplicationCollection.Reminder:
          const reminder = getPropertiesFromSchemaObject<Reminder, RxReminder>(entry, reminderSchema);
          return addOfflineReminderProperties(reminder);
        case ReplicationCollection.ContactText:
        case ReplicationCollection.ServiceText:
          const text = getPropertiesFromSchemaObject<InfoText, RxInfoText>(entry, infoTextSchema);
          return text;
        case ReplicationCollection.Service:
          const service = getPropertiesFromSchemaObject<Service, RxService>(entry, serviceSchema);
          return service;
        case ReplicationCollection.PrescriptionFlattened:
          const prescriptionFlattened = getPropertiesFromSchemaObject<PrescriptionFlattened, RxPrescriptionFlattened>(
            entry,
            prescriptionFlattenedSchema
          );
          return prescriptionFlattened;
        default:
          return entry;
      }
    });

    const res = await db.collections[collection].bulkInsert(normalizedData);

    const lastSuccessfulEntry = res.success[normalizedData.length - 1]?.toJSON();
    const endpointHash = hash(config.APPSYNC_URL);
    await setLastPullDocument(db.collections[collection], endpointHash, lastSuccessfulEntry);
    const changes = await db.collections[collection].storageInstance.getChangedDocuments({
      sinceSequence: 0,
      direction: 'after',
    });
    await setLastPushSequence(db.collections[collection], endpointHash, changes.lastSequence);
  }
};

export const getReplicationDataWithUnsyncedData = (
  unsyncedData?: Record<string, RxDocumentBase<unknown, Record<string, unknown>>[] | undefined>
) => {
  const replications = getLocalReplications();
  const replicationDataSource: ReplicationData[] = Object.keys(replications).map((collection) => ({
    collection,
    ...replications[collection],
    unsyncedData: unsyncedData?.[collection],
  }));
  return replicationDataSource;
};
