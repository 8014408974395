import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import { AbbreviateToWidth } from '../../../components/AbbreviateToWidth/AbbreviateToWidth';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';
import { EmailModal } from '../../../components/EmailModal/EmailModal';
import { recipientsFieldName } from '../../../components/EmailModal/StandardEmailFormFields';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { PriceValue } from '../../../components/PriceValue/PriceValue';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../../constants/layout';
import { contactPropertyNames } from '../../../constants/propertyNames';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { Contact } from '../../../graph/types';
import { useGetContactsWithSearch } from '../../../hooks/ajax/contact/contactHooks';
import { useNavigationToRoute, withContactIdParameter } from '../../../hooks/route/navigationHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useShouldShowTooManyResultsWarning } from '../../../hooks/setWarningIfTooManyResults';
import {
  TableFilterAndSortKey,
  TableKey,
  useTableColumnDisplayFilter,
  useTableResetFilterAndSort,
} from '../../../hooks/tableHooks';
import { getAvailableContactStatusTypeFiltersDto, useGetContactTypeFilters } from '../../../util/contactFilterUtil';
import { addSortingPriorityTo } from '../../../util/filterAndSorting';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { basicContactColumns } from './contactColumns';
import { ContactStatusHelperDto } from './contactStatusUtil';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const ContactsOverview: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const [searchValue, setSearchValue] = useState<string>();
  const { contacts, contactsLoading } = useGetContactsWithSearch(organizationId, searchValue);
  const shouldShowTooManyResultsWarning = useShouldShowTooManyResultsWarning();
  const contactReferenceData = organization?.ref_contact;
  const contactStatusHelper = useMemo(
    () => new ContactStatusHelperDto(contactReferenceData?.status_type),
    [contactReferenceData]
  );
  const { navigateTo } = useNavigationToRoute();

  const [selectedEmail, setSelectedEmail] = useState<string | undefined>();

  const { filteredValue, sortOrderMap, tableChangeHandler, resetFiltersAndSort, modifiedFields } =
    useTableResetFilterAndSort(TableFilterAndSortKey.ContactsOverview);

  const contactStatusTypeFilters = useMemo(
    () => getAvailableContactStatusTypeFiltersDto(contactReferenceData?.status_type ?? [], contacts ?? []),
    [contactReferenceData, contacts]
  );
  const contactTypeFilters = useGetContactTypeFilters(contacts);

  const navigateToViewContact = (contactId: string) => () => {
    navigateTo(routes.viewContact, withContactIdParameter(contactId));
  };

  const linkToViewContact = (text: string, contact: Contact) => {
    return (
      <TableCellLink onClick={navigateToViewContact(contact.id)}>
        {text === translations.shared.editButtonText ? (
          <Button>
            <AbbreviateToWidth width={300} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
          </Button>
        ) : (
          <AbbreviateToWidth width={300} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
        )}
      </TableCellLink>
    );
  };

  const addContact = () => {
    navigateTo(routes.addContact);
  };

  const handleEmailClick = (email: string) => {
    setSelectedEmail(email);
  };

  const handleOnEmailModalClose = () => {
    setSelectedEmail(undefined);
  };

  const optionalColumns: CustomColumnType<Contact>[] = useMemo(
    () => [
      {
        ...basicContactColumns.contact_status_name,
        filteredValue: filteredValue[contactPropertyNames.contact_status_name] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.contact_status_name],
        filters: contactStatusTypeFilters,
        render: (_, record) => contactStatusHelper.getTag(record, contactReferenceData?.status_type ?? []),
        width: 200,
        sorter: contactStatusHelper.sorter,
      },
      {
        ...basicContactColumns.number,
        filteredValue: filteredValue[contactPropertyNames.number] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.number],
        width: 100,
      },
      {
        ...basicContactColumns.email,
        filteredValue: filteredValue[contactPropertyNames.email] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.email],
        width: 300,
        render: (value) => <TableCellLink onClick={() => handleEmailClick(value)}>{value}</TableCellLink>,
      },
      {
        ...basicContactColumns.balance_posted,
        filteredValue: filteredValue[contactPropertyNames.balance_posted] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.balance_posted],
        width: 100,
        render: (record: Contact) => <PriceValue value={+record.balance_posted} />,
      },
      {
        ...basicContactColumns.tags,
        filteredValue: filteredValue.tags ?? null,
        sortOrder: sortOrderMap.tags,
        filters: contactTypeFilters,
        width: 300,
      },
    ],
    [
      contactReferenceData,
      contactStatusHelper,
      contactStatusTypeFilters,
      contactTypeFilters,
      filteredValue,
      sortOrderMap,
    ]
  );

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter(TableKey.ContactsOverview, optionalColumns);

  const columns: CustomColumnType<Contact>[] = [
    {
      ...basicContactColumns.name,
      filteredValue: filteredValue[contactPropertyNames.name] ?? null,
      sortOrder: sortOrderMap[contactPropertyNames.name],
      render: (name: string, record: Contact) => linkToViewContact(name, record),
      width: 300,
    },
    ...displayedColumns,
    {
      title: translations.contactsPage.columns.actions,
      key: 'actions',
      render: (record: Contact) => linkToViewContact(translations.shared.editButtonText, record),
    },
  ];
  addSortingPriorityTo(columns);

  const handleOnClear = (key?: string) => {
    setSearchValue('');
    resetFiltersAndSort(key);
    resetDisplayedColumnsToDefault();
  };

  return (
    <>
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.contactsPage.title}
            loading={contactsLoading}
            searchBoxPlaceholder={translations.contactsPage.searchPlaceholder}
            onSearchValueChange={(event) => setSearchValue(event.target.value)}
            onClear={handleOnClear}
            showTooManyResultsWarning={shouldShowTooManyResultsWarning(contacts)}
            tags={mapKeysToColumnTitle(modifiedFields, columns)}
          />
        }
        extra={[
          <ColumnDisplayFilter
            key={'columnDisplayFilter'}
            initiallyDisplayedColumns={displayedColumnKeys}
            setDisplayedColumns={setDisplayedColumnKeys}
            columnKeyAndTitleList={columnKeyAndTitleList}
            resetDisplayedColumnsToDefault={resetDisplayedColumnsToDefault}
          />,
          <Button key='addContact' type='primary' onClick={addContact}>
            {translations.contactsPage.buttons.addContact}
          </Button>,
        ]}
      />
      {selectedEmail && (
        <EmailModal
          visible
          onClose={handleOnEmailModalClose}
          initialValues={{ [recipientsFieldName]: [selectedEmail] }}
        />
      )}
      <TableWithCustomFiltering
        tableKey={TableKey.ContactsOverview}
        dataSource={contacts}
        loading={contactsLoading}
        columns={columns}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
    </>
  );
};
