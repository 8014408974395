import { RxJsonSchema } from 'rxdb';
import { ServiceActivity } from '../../../graph/types';
import { RxScalars } from '../databaseUtils';

export type RxServiceActivity = ServiceActivity & { id: string };

export const serviceActivitySchema: RxJsonSchema<RxServiceActivity> = {
  version: 2,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    patient_id: RxScalars.ID,
    doctor_names: RxScalars.Array,
    service_names: RxScalars.Array,
    date: RxScalars.String,
    performed: RxScalars.Int,
    updated: RxScalars.String,
  },
  indexes: ['updated', 'patient_id', ['patient_id', 'offline_patient_id']],
};
