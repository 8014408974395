import React from 'react';
import Select, { SelectValue } from 'antd/lib/select';
import { SearchOptionType } from './types';
import { translations } from '../../../../constants/translations';
import { FullWidthSearch } from './Search.styles';
import { Loading } from '../../../../components/Loading/Loading';
import { SearchOption } from './SearchOption';

const { Option } = Select;

type Props = {
  isLoading: boolean;
  options: SearchOptionType[];
  onSelect: (item: SelectValue, option: any) => void;
  onChange: (str: string) => void;
  searchValue?: string;
};

const Search = ({ isLoading, options, onSelect, onChange, searchValue }: Props) => {
  return (
    <FullWidthSearch
      showSearch
      placeholder={translations.mainHeader.search.placeholder}
      loading={isLoading}
      onChange={onSelect}
      onSearch={onChange}
      filterOption={false}
      clearIcon
      showArrow={false}
      notFoundContent={isLoading ? <Loading /> : translations.shared.noResults}
      value={searchValue}
    >
      {!isLoading &&
        options.map(({ id, disabled, rowKey, ...option }) => {
          const testId = `search-${option.type}-${option.header}`;
          return (
            <Option value={rowKey} disabled={disabled} key={rowKey} onClick={onSelect} data-testid={testId}>
              <SearchOption id={id} rowKey={rowKey} {...option} />
            </Option>
          );
        })}
    </FullWidthSearch>
  );
};

export default Search;
