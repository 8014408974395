import gql from 'graphql-tag';
import { DataConversionFields } from '../fragments';

export const GetSoftwareTypes = gql`
  query getSoftwareTypes {
    getSoftwareTypes {
      id
      name
      conversion_cost
    }
  }
`;

export const GetDataConversionFileTypes = gql`
  query getDataConversionFileTypes {
    getDataConversionFileTypes {
      id
      multiple
      name_key
      type
    }
  }
`;

export const RequestDataConversion = gql`
  mutation requestDataConversion($organizationId: ID!, $record: DataConversionUpsert!) {
    requestDataConversion(organizationId: $organizationId, record: $record) {
      ...DataConversionFields
    }
  }
  ${DataConversionFields}
`;

export const GetDataConversion = gql`
  query getDataConversion($organizationId: ID!) {
    getDataConversion(organizationId: $organizationId) {
      ...DataConversionFields
    }
  }
  ${DataConversionFields}
`;
