/**
 * holds all the common validations that is being utilized accross the application.
 */

import { ContactAlert, PatientAlert } from '../graph/types';

type setError = (setErrorObject: Record<string, boolean>) => void;

export const validateAlert = (alert: PatientAlert | ContactAlert, onError: setError): boolean => {
  if (!alert.note) {
    onError({
      note: !alert.note,
    });
    return false;
  }
  return true;
};
