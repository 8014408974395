import { PracticeSettingsNameKey } from '../../constants/translations';
import { PracticeDto } from '../../graph/types';
import { getPracticeSetting } from '../Contacts/ViewContact/statementUtils';
import { ConfigureButton, ReminderPreference, RunMode, SelectedContact } from './types';

export const getUpsertVariablesByContacts = (runMode: RunMode, contacts: SelectedContact[]) => {
  const fnPrint: string[] = [];
  const fnCancel: string[] = [];
  const fnComplete: string[] = [];
  const fnSendEmails: string[] = [];

  switch (runMode) {
    case RunMode.PRINT: {
      fnPrint.push(...contacts.map((contact) => contact.reminderId));
      break;
    }
    case RunMode.PRINT_AND_EMAIL: {
      contacts.forEach((contact) => {
        fnPrint.push(contact.reminderId);
        fnSendEmails.push(contact.reminderId);
      });
      break;
    }
    case RunMode.PRINT_OR_EMAIL: {
      contacts.forEach((contact) => {
        if (contact.preference === ReminderPreference.EMAIL) {
          fnSendEmails.push(contact.reminderId);
        }
        if (contact.preference === ReminderPreference.PRINT) {
          fnPrint.push(contact.reminderId);
        }
      });
      break;
    }
    case RunMode.CANCEL: {
      fnCancel.push(...contacts.map((contact) => contact.reminderId));
      break;
    }
    case RunMode.COMPLETE: {
      fnComplete.push(...contacts.map((contact) => contact.reminderId));
      break;
    }
  }

  return {
    fnPrint,
    fnCancel,
    fnComplete,
    fnSendEmails,
  };
};

export const getConfigurationByPractice = (practice?: PracticeDto): ConfigureButton => {
  const subjectSetting = getPracticeSetting(PracticeSettingsNameKey.ReminderSubject, practice);
  const subject = subjectSetting?.value;
  const reminderBodySetting = getPracticeSetting(PracticeSettingsNameKey.ReminderBody, practice);
  const reminderBody = reminderBodySetting?.value;

  return {
    reminderBody,
    subject,
    subjectSetting,
    reminderBodySetting,
  };
};
