import dogProfileImagePlaceholder from './dog.png';
import horseProfileImagePlaceholder from './horse.png';
import catProfileImagePlaceholder from './cat.png';
import generalProfileImagePlaceholder from './general.png';

export const getProfileImagePlaceholder = (patientSpecies?: string | null) => {
  switch (patientSpecies) {
    case 'Canine':
      return dogProfileImagePlaceholder;
    case 'Equine':
      return horseProfileImagePlaceholder;
    case 'Feline':
      return catProfileImagePlaceholder;
    default:
      return generalProfileImagePlaceholder;
  }
};
