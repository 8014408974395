import { Maybe, RxScalars } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';
import { File } from '../../../graph/types';

export type RxFile = File;

export const fileSchema: RxJsonSchema<RxFile> = {
  version: 5,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    patient_id: RxScalars.ID,
    contact_id: RxScalars.ID,
    type_id: RxScalars.ID,
    type_name: RxScalars.String,
    date: RxScalars.String,
    name: RxScalars.String,
    pointer: RxScalars.String,
    size: RxScalars.Int,
    description: Maybe(RxScalars.String),
    hidden: RxScalars.Boolean,
    locked: RxScalars.Boolean,
    updated: RxScalars.String,
    updated_user_name: Maybe(RxScalars.String),
    offline_id: Maybe(RxScalars.String),
    offline_patient_id: Maybe(RxScalars.String),
    created: RxScalars.String,
    created_user_id: RxScalars.String,
    created_user_name: RxScalars.String,
  },
  required: ['id'],
  indexes: [
    'updated',
    'patient_id',
    'contact_id',
    ['patient_id', 'offline_patient_id', 'hidden'],
    ['contact_id', 'offline_contact_id', 'hidden'],
  ],
};
