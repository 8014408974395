import { ReportContextParameters } from '../../../graph/types';
import { GetReportParameterData, GetReportParameters, GetReports } from '../../../graph/queries/reports';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useNavigationToRoute } from '../../route/navigationHooks';
import { useCallback } from 'react';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { ReportData } from '../../../components/ActiveReportViewer/activeReportViewerUtils';

export const useGetReports = (organizationId: string, practiceId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getReports'>(GetReports, {
    variables: {
      organizationId,
      practiceId,
    },
  });

  return {
    reports: data?.getReports,
    reportsLoading: loading,
    reportsError: error,
  };
};

export const useGetReportParameters = (organizationId: string, reportId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getReportParameters'>(GetReportParameters, {
    variables: {
      organizationId,
      reportId,
    },
  });

  return {
    reportParameters: data?.getReportParameters,
    reportParametersLoading: loading,
    reportParametersError: error,
  };
};

export const useGetReportParameterData = (
  organizationId: string,
  reportParameterId: string,
  reportContextParameters: ReportContextParameters
) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getReportParameterData'>(GetReportParameterData, {
    variables: {
      organizationId,
      reportParameterId,
      reportContextParameters,
    },
  });

  return {
    reportParameterData: data?.getReportParameterData,
    reportParameterDataLoading: loading,
    reportParameterDataError: error,
  };
};

export const useNavigateToReportViewer = () => {
  const { navigateTo } = useNavigationToRoute();

  return useCallback(
    ({
      reportName,
      data,
      nestedHeader,
      showSettingsSidebar,
    }: {
      reportName: string;
      data?: ReportData;
      nestedHeader?: boolean;
      showSettingsSidebar?: boolean;
    }) => {
      if (data) {
        navigateTo(showSettingsSidebar ? routes.reportViewerPractice : routes.reportViewer, undefined, {
          reportName,
          data,
          nestedHeader,
        });
      } else {
        showErrorMessage(translations.reports.errorOpeningReport);
      }
    },
    [navigateTo]
  );
};
