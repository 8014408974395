import React from 'react';
import { translations } from '../../constants/translations';
import { OffsetContainer, OffsetTitle } from '../../globalStyles.style';

type ReportTagsProps = {
  tagList: string[];
  titleOffset?: number;
  contentOffset?: number;
};

export const ReportTags: React.FC<ReportTagsProps> = ({ tagList, titleOffset: offset = 0, contentOffset = 0 }) => (
  <>
    <OffsetTitle offset={offset} level={4}>
      {translations.shared.tagsHeader}
    </OffsetTitle>
    <OffsetContainer offset={contentOffset}>
      <ul>
        {tagList.map((tag) => (
          <li key={tag}>{`{${tag}}`}</li>
        ))}
      </ul>
    </OffsetContainer>
  </>
);
