import { Patient } from '../graph/types';
import { MaybeHasNameAndNumber } from './filterUtil';

export const joinNameAndNumber = ({
  name,
  number,
}: {
  name: string | null | undefined;
  number: string | null | undefined;
}) => {
  if (name && number) {
    return `${name} - ${number}`;
  }
  return name || number || '';
};

export const joinPatientOrContactNameAndNumber = (patientOrContact: MaybeHasNameAndNumber) =>
  joinNameAndNumber({
    name: patientOrContact.name,
    number: patientOrContact.number,
  });

export function getFormattedDecimalString(value: string | number): string {
  if (value === '') {
    return '';
  }
  const valueAsNumber = Number(value);
  if (isNaN(valueAsNumber)) {
    return '';
  }
  // Language shouldn't matter for digits, just min/max decimal places
  return valueAsNumber
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 3 })
    .replace(/,/g, '');
}

export const joinPatientNameAndPrimaryOwnerName = (patient: Patient) => {
  let ownerName = '';
  if (patient?.owner_names && patient?.owner_names.length > 0) {
    ownerName = patient?.owner_names[0];
  }
  return joinNameAndNumber({
    name: patient.name,
    number: ownerName,
  });
};
