import { RxJsonSchema } from 'rxdb';
import { Practice } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaWithUpdated } from '../databaseUtils';

export type RxPractice = Practice & RxSchemaWithUpdated;

export const practiceSchema: RxJsonSchema<RxPractice> = {
  version: 3,
  type: 'object',
  primaryKey: 'id',

  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.String,
    name: RxScalars.String,
    name_short: Maybe(RxScalars.String),
    address: RxScalars.Array,
    phone: RxScalars.Array,
    setting: RxScalars.Array,
    logo: Maybe(RxScalars.Object),
    card_connect: Maybe(RxScalars.Object),
    card_connect_manual: Maybe(RxScalars.Object),
    info: RxScalars.Array,
    updated: RxScalars.String,
    text: RxScalars.Array,
  },
  required: ['id'],
  indexes: ['updated'],
};
