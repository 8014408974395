import { PdfState } from './PdfPreview';
import { pdfjs } from 'react-pdf';
import { Loading } from '../../Loading/Loading';
import React from 'react';

export async function getPdfState(pdfProxy: pdfjs.PDFDocumentProxy): Promise<PdfState> {
  const firstPage = await pdfProxy.getPage(1);
  const viewport = firstPage.getViewport({ scale: 1 });
  const pageSize: [number, number] = firstPage ? [viewport.width, viewport.height] : [0, 0];
  const rawPdf = await pdfProxy.getData();
  return {
    selectedPage: 1,
    pageSize,
    rawPdf: rawPdf as Int8Array,
    pdfProxy,
    pageCount: pdfProxy.numPages,
  };
}

export async function setNewPage(newPageNum: number, previousState: PdfState): Promise<PdfState> {
  const page = await previousState.pdfProxy?.getPage(newPageNum);
  const viewport = page?.getViewport({ scale: 1 });
  const pageSize: [number, number] = viewport ? [viewport.width, viewport.height] : [0, 0];
  return { ...previousState, pageSize, selectedPage: newPageNum };
}

export function getPageNumberWithinBounds(pageNum: number, pageCount: number): number {
  let newPageNum = pageNum;
  if (pageNum < 1) {
    newPageNum = 1;
  } else if (pageNum > pageCount) {
    newPageNum = pageCount;
  }
  return newPageNum;
}

const centerItems = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const previewLoadingDiv = (
  <div
    style={{
      height: 200,
      width: 'auto',
      ...centerItems,
    }}
  >
    <Loading />
  </div>
);

export function modalLoadingDiv(width: number, height: number) {
  return (
    <div
      style={{
        width,
        height,
        ...centerItems,
      }}
    >
      <Loading />
    </div>
  );
}

export function getScaleFactor(pdfPageSize: [number, number], windowSize: [number, number]) {
  const widthFactor = pdfPageSize[0] + 72 > 0.9 * windowSize[0] ? (0.9 * windowSize[0] - 72) / pdfPageSize[0] : 1;
  const heightFactor = pdfPageSize[1] + 48 > 0.85 * windowSize[1] ? (0.85 * windowSize[1] - 48) / pdfPageSize[1] : 1;
  return Math.min(widthFactor, heightFactor);
}
