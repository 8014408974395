// TODO: write type definitions file for mammoth
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mammoth from 'mammoth';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Loading } from '../../Loading/Loading';
import { Modal } from 'antd';

interface DocxPreviewProps extends PropsWithChildren<unknown> {
  url: string;
  icon?: React.ReactElement;
}

const fetchFile = async (url: string) => {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  const conversionResult = await mammoth.convertToHtml({
    arrayBuffer,
  });
  return conversionResult.value;
};

const outerContainerStyle = {
  height: 200,
  width: 'auto',
  maxWidth: '100%',
  overflow: 'hidden',
};

export const DocxPreview: React.FC<DocxPreviewProps> = ({ url, icon }: DocxPreviewProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [docx, setDocx] = useState<any>(undefined);

  useEffect(() => {
    const setDocxFromUrl = async () => {
      const fileContent = await fetchFile(url);
      setDocx(fileContent);
    };
    setDocxFromUrl();
  }, [url]);

  const docxLoaded = docx !== undefined;

  const closeModal = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  return docxLoaded ? (
    <>
      <div onClick={showModal} style={icon ? {} : outerContainerStyle}>
        {icon ? (
          <span style={{ cursor: 'pointer' }}>{icon} Click to view file </span>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: docx }}
            style={{ transform: 'scale(0.5)', transformOrigin: 'top left' }}
          />
        )}
      </div>
      <Modal open={modalVisible} onCancel={closeModal} width={'95%'} centered footer={null}>
        <div dangerouslySetInnerHTML={{ __html: docx }} />
      </Modal>
    </>
  ) : (
    <Loading />
  );
};
