import { useContext, useEffect, useRef } from 'react';
import { SelectOptions } from '../../components/DependentDropdown/DependentDropdown';
import {
  Patient,
  QueryGetPatientHistoryReportArgs,
  QueryGetPatientVaccineReportArgs,
  QueryRptGetPatientHistoryArgs,
  QueryRptGetPatientVaccineArgs,
  RelatedOwner,
  RelatedOwnershipEntryAll,
} from '../../graph/types';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { joinPatientOrContactNameAndNumber } from '../../util/displaying';
import { GetPatientHistoryReport, GetPatientVaccineReport } from '../../graph/queries/patients';
import { closeNotification, showErrorMessage, showNotification } from '../../components/Notification/notificationUtil';
import { GeneralNames, translations } from '../../constants/translations';
import dayjs from 'dayjs';
import { Attachment } from '../../components/EmailModal/EmailModal';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { usePatientUpsertSubscription } from '../../hooks/ajax/patients/patientHooks';
import ViewSubscriptionActions from '../../components/ViewSubscription/store/actions';
import { ViewSubscriptionContext } from '../../components/ViewSubscription/store/state';
import { useUserContext } from '../../contexts/user/state';
import { useGetConnectionId } from '../../hooks/authHooks';
import { Reports, getReportData } from '../../util/reportUtils';
import { DocumentNode } from 'graphql';
import {
  ReportData,
  ReportDataQueryParam,
  ReportQueryKeys,
} from '../../components/ActiveReportViewer/activeReportViewerUtils';
import { getActiveReport } from '../../util/activeReportUtils';
import { fetchS3UploadUrl } from '../../util/fileUtils';
import { GetPatientHistoryReportAR, GetPatientVaccineReportAR } from '../../graph/queries/reports';

export enum ModalTypes {
  AddPatient = 'addPatient',
  PrintReport = 'printReport',
  EmailReport = 'emailReport',
  SyncPatient = 'syncPatient',
  AddInvoice = 'addInvoice',
  EmailVaccineReport = 'emailVaccineReport',
  PatientAlerts = 'patientAlerts',
}

export const getDayJSFromDateString = (format: string, date?: string | null) => (date ? dayjs(date, format) : dayjs());

export const getFileNameForPatientHistory = (
  patientNumber: string | null | undefined,
  startDate: string,
  endDate: string
) => `Patient_${patientNumber ?? ''}_${startDate}_${endDate}.pdf`;

export const getFileNameForPatientVaccine = (name: string) => `Patient_Vaccine_${name}.pdf`;

export const getActionsForPatientList = ({ editPatientAction }: { editPatientAction: () => void }) => [
  {
    title: translations.shared.editButtonText,
    onClick: editPatientAction,
  },
];

export const getReportForContactOptions = (owners: RelatedOwner[]): SelectOptions[] =>
  owners.map((owner) => {
    return { value: owner.contact_id ?? '', label: joinPatientOrContactNameAndNumber(owner) };
  });

export const buildPatientVaxRptVariables = ({
  patient,
  practiceId,
  organizationId,
  usesActiveReports,
  returnPreSignedUrl,
}: {
  patient: Patient;
  practiceId: string;
  organizationId: string;
  usesActiveReports: boolean;
  returnPreSignedUrl?: boolean;
}): QueryGetPatientVaccineReportArgs | QueryRptGetPatientVaccineArgs =>
  usesActiveReports
    ? ({
        patientId: patient.id,
        practiceId,
        organizationId,
      } as QueryRptGetPatientVaccineArgs)
    : ({
        patientId: patient.id,
        request: {
          practiceId,
          organizationId,
          fileName: getFileNameForPatientVaccine(patient.name ?? ''),
          returnPreSignedUrl: returnPreSignedUrl ? 'true' : undefined,
        },
      } as QueryGetPatientVaccineReportArgs);

export const printPatientVaccineReport = async (
  variables: QueryGetPatientVaccineReportArgs | QueryRptGetPatientVaccineArgs,
  usesActiveReports: boolean,
  reportName?: Reports,
  navigateToReportViewer?: (args: { reportName: string; data?: ReportData; nestedHeader?: boolean }) => void
) => {
  if (usesActiveReports && reportName) {
    const reportData = await getReportData<'rptGetPatientVaccine', QueryRptGetPatientVaccineArgs>(
      GetPatientVaccineReportAR,
      variables as QueryRptGetPatientVaccineArgs
    );
    navigateToReportViewer?.({
      reportName,
      data: reportData,
      nestedHeader: true,
    });
  } else {
    await getPatientReportUrl(variables as QueryGetPatientVaccineReportArgs, (url) =>
      openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport)
    );
  }
};

export const buildPatientHistoryRptVariables = ({
  patient,
  startDate,
  endDate,
  practiceId,
  organizationId,
  usesActiveReports,
  returnPreSignedUrl,
}: {
  patient: Patient;
  startDate: string;
  endDate: string;
  practiceId: string;
  organizationId: string;
  usesActiveReports: boolean;
  returnPreSignedUrl?: boolean;
}): QueryGetPatientHistoryReportArgs | QueryRptGetPatientHistoryArgs =>
  usesActiveReports
    ? ({
        practiceId,
        organizationId,
        reportParameters: { start_date: startDate, end_date: endDate, patient_id: patient.id },
      } as QueryRptGetPatientHistoryArgs)
    : ({
        patientId: patient.id,
        startDate,
        endDate,
        request: {
          organizationId,
          practiceId,
          fileName: getFileNameForPatientHistory(patient.number ?? '', startDate, endDate),
          returnPreSignedUrl: returnPreSignedUrl ? 'true' : undefined,
        },
      } as QueryGetPatientHistoryReportArgs);

export const printPatientHistoryReport = async (
  variables: QueryGetPatientHistoryReportArgs | QueryRptGetPatientHistoryArgs,
  usesActiveReports: boolean,
  reportName?: Reports,
  navigateToReportViewer?: (args: { reportName: string; data?: ReportData; nestedHeader?: boolean }) => void
) => {
  if (usesActiveReports && reportName) {
    const reportData = await getReportData<'rptGetPatientHistory', QueryRptGetPatientHistoryArgs>(
      GetPatientHistoryReportAR,
      variables as QueryRptGetPatientHistoryArgs
    );
    navigateToReportViewer?.({
      reportName,
      data: reportData,
      nestedHeader: true,
    });
  } else {
    await getPatientReportUrl(variables as QueryGetPatientHistoryReportArgs, (url) =>
      openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport)
    );
  }
};

export const getPatientReportAsEmailAttachment = async (
  variables: QueryGetPatientHistoryReportArgs | QueryGetPatientVaccineReportArgs
) => {
  let attachment: Attachment | undefined = undefined;
  await getPatientReportUrl(variables, (url) => {
    attachment = {
      file_pointer: url,
      file_name: variables.request.fileName ?? '',
    };
  });
  return attachment;
};

export const getPatientReportUrl = async (
  variables: QueryGetPatientHistoryReportArgs | QueryGetPatientVaccineReportArgs,
  onSuccess: (url: string) => void
) => {
  try {
    const { data } = await AppSyncService.client.query({
      query: 'startDate' in variables && variables.startDate ? GetPatientHistoryReport : GetPatientVaccineReport,
      variables,
      fetchPolicy: 'no-cache',
    });

    const dataKeys = Object.keys(data);
    if (data?.[dataKeys[0]].pointer) {
      const pointer = data[dataKeys[0]].pointer;
      onSuccess(pointer);
    } else {
      showErrorMessage(translations.patientReport.errorGeneratingReport);
    }
  } catch (err) {
    showErrorMessage((err as Error).message || translations.patientReport.errorGeneratingReport);
  }
};

export const displayOneDayEarlierIfSet = (dateAsString: string | null | undefined) => {
  return showDateIfSet(dateAsString, -1);
};

export const showDateIfSet = (dateAsString: string | null | undefined, offsetInDays = 0) => {
  return dateAsString ? dayjs(dateAsString).add(offsetInDays, 'day') : null;
};

export const getOwnershipStart = (ownershipAll: RelatedOwnershipEntryAll[], value: string) => {
  return ownershipAll.find((ownership) => ownership.owner.some((owner) => owner.contact_id === value));
};

export const getOwnershipEnd = (ownershipAll: RelatedOwnershipEntryAll[], value: string) => {
  return ownershipAll
    .slice()
    .reverse()
    .find((ownership) => ownership.owner.some((owner) => owner.contact_id === value));
};

const updateNotificationKey = 'update-notification-key';
export const openUpdateReceivedNotification = (onRefresh: () => void, entityString: GeneralNames) => {
  closeNotification(updateNotificationKey);
  showNotification({
    key: updateNotificationKey,
    message: translations.shared.updateNotification.refresh.message,
    description: translations.shared.updateNotification.refresh.description(entityString),
    btn: {
      text: translations.shared.refreshButtonText,
      action: onRefresh,
    },
  });
};

export const usePatientSubscription = (patient?: Patient | null, refetch?: () => any) => {
  const {
    state: { renderKey, editing },
    dispatch: dispatchViewPatient,
  } = useContext(ViewSubscriptionContext);
  const connectionId = useGetConnectionId();

  const { record } = usePatientUpsertSubscription(patient?.id || '');
  const {
    state: { user },
  } = useUserContext();
  const editingRef = useRef(editing);

  useEffect(() => {
    editingRef.current = editing;
  }, [editing]);

  useEffect(() => {
    if (record && record.connectionId !== connectionId) {
      const fetchData = async () => {
        if (refetch) {
          await refetch();
        }
        dispatchViewPatient(ViewSubscriptionActions.incrementRenderKey());
      };
      if (!editingRef.current) {
        fetchData();
      } else {
        openUpdateReceivedNotification(fetchData, GeneralNames.Patient);
      }
    }
  }, [dispatchViewPatient, record, refetch, user?.id, connectionId]);

  return { state: { renderKey, editing }, dispatchViewPatient };
};

export const generateReportAttachment = async <T extends ReportQueryKeys, TVariables extends ReportDataQueryParam>(
  query: DocumentNode,
  variables: TVariables,
  fileName: string,
  displayCurrency: (value: number) => string,
  displayDate: (value: string) => string,
  reportName: Reports
) => {
  if (!('organizationId' in variables)) {
    return undefined;
  }
  const data = await getReportData<T, TVariables>(query, variables);

  const reportBlob = await getActiveReport({
    reportName,
    data,
    nestedHeader: true,
    displayCurrency,
    displayDate,
  });
  if (!reportBlob) {
    return undefined;
  }

  const uploadUrl = await fetchS3UploadUrl(variables.organizationId);
  await fetch(uploadUrl.url, { method: 'PUT', body: new File([reportBlob], fileName) });

  return {
    file_pointer: uploadUrl.filePointer,
    file_name: fileName,
  };
};
