import React, { useEffect, useMemo, useState } from 'react';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import AddOnCardStripe from './AddOnCardStripe';
import { PaymentStripeAccountStatus } from '../../../constants/referenceData/paymentReferenceData';
import { translations } from '../../../constants/translations';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { useGetStripeAccountStatus } from '../../../hooks/useGetStripeAccountStatus';
import { Practice } from '../../../graph/types';

interface AddOnCardStripeContainerProps {
  organizationId: string;
  practice: Practice;
}

const AddOnCardStripeContainer: React.FC<AddOnCardStripeContainerProps> = ({ organizationId, practice }) => {
  const { status: stripeStatus, loading, checkStatus } = useGetStripeAccountStatus(organizationId, practice.id);
  const { navigateTo } = useNavigationToRoute();
  const [firstFetch, setFirstFetch] = useState(true);

  useEffect(() => {
    if (!stripeStatus && firstFetch) {
      checkStatus();
      setFirstFetch(false);
    }
  }, [checkStatus, firstFetch, stripeStatus]);

  const enabled = useMemo(
    () => (stripeStatus ? stripeStatus !== PaymentStripeAccountStatus.NotFound : false),
    [stripeStatus]
  );

  const handleConfigure = () => {
    if (stripeStatus === PaymentStripeAccountStatus.NotFound) {
      navigateTo(routes.stripeRegistration);
    } else {
      navigateTo(routes.stripeConfirmation);
    }
  };

  const handleEnable = () => {
    navigateTo(routes.stripeRegistration);
  };

  return (
    <SaveSpinner isSaving={loading} savingMessage={translations.loadingComponent.loading}>
      <AddOnCardStripe
        enabled={enabled}
        onConfigureClick={handleConfigure}
        onEnable={handleEnable}
        practice={practice}
      />
    </SaveSpinner>
  );
};

export default AddOnCardStripeContainer;
