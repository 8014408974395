import { Button, Form } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';
import { SaveAndResetButton } from '../SaveAndResetButton/SaveAndResetButton';
import { ButtonWithPopconfirm } from '../ButtonWithPopconfirm/ButtonWithPopconfirm';
import { DeleteOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import { FlexCenteredDiv, FlexItemContainer } from '../../globalStyles.style';
import { openTabWithPopupDetection } from '../../util/popupUtil';

export const getButtonRowForExistingRecord = (
  hasUnsavedChanges: boolean,
  onReset: () => void,
  typeName: string,
  activateEditMode: () => void,
  handleDeleteRecord: () => void,
  disabled = false
) => {
  return hasUnsavedChanges ? (
    <SaveAndResetButton onReset={onReset} offset={0} />
  ) : (
    <Form.Item>{getEditAndDeleteButtons(typeName, activateEditMode, handleDeleteRecord, disabled)}</Form.Item>
  );
};

export const getButtonRowForNewRecord = (onCancel: () => void) => (
  <Form.Item>
    <Button style={{ marginRight: '10px' }} type={'primary'} htmlType='submit'>
      {translations.recordList.saveButtonText}
    </Button>
    <Button onClick={() => onCancel()}>{translations.recordList.cancelButtonText}</Button>
  </Form.Item>
);

const getEditAndDeleteButtons = (
  typeName: string,
  activateEditMode: () => void,
  handleDeleteRecord: () => void,
  disabled = false
) => {
  return (
    <>
      <Button style={{ marginRight: '10px' }} type={'primary'} onClick={activateEditMode} disabled={disabled}>
        {translations.shared.editButtonText}
      </Button>
      <ButtonWithPopconfirm
        danger
        buttonText={translations.shared.deleteButtonText}
        popconfirmTitle={translations.recordList.deleteConfirmation(typeName)}
        onClickCallback={handleDeleteRecord}
        buttonStyle={{ marginRight: '10px' }}
        buttonType={'primary'}
        isDisabled={disabled}
      />
    </>
  );
};

interface EditAndDeleteIconsProps {
  typeName: string;
  activateEditMode: () => void;
  handleDeleteRecord: () => void;
  disabled?: boolean;
  fileUrl?: string;
}

export const EditAndDeleteIcons = ({
  typeName,
  activateEditMode,
  handleDeleteRecord,
  disabled = false,
  fileUrl,
}: EditAndDeleteIconsProps) => {
  return (
    <FlexCenteredDiv>
      {typeName === 'File' && (
        <FlexItemContainer>
          <Button
            onClick={() => {
              if (fileUrl) {
                openTabWithPopupDetection(fileUrl);
              }
            }}
            icon={<ExportOutlined />}
          />
        </FlexItemContainer>
      )}
      <FlexItemContainer>
        <Button onClick={activateEditMode} icon={<EditOutlined />} disabled={disabled} />
      </FlexItemContainer>
      <FlexItemContainer>
        <ButtonWithPopconfirm
          buttonIcon={<DeleteOutlined />}
          popconfirmTitle={translations.recordList.deleteConfirmation(typeName)}
          onClickCallback={handleDeleteRecord}
          disabled={disabled}
        />
      </FlexItemContainer>
    </FlexCenteredDiv>
  );
};
