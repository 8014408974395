import React, { useState, useMemo } from 'react';
import { useGetReminders } from '../../../hooks/ajax/reminders/reminderHooks';
import { Dayjs } from 'dayjs';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Reminder } from '../../../graph/types';
import { useBasicRemindersRunColumns } from './columns';
import { TableKey } from '../../../hooks/tableHooks';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useNavigationToRoute, withContactIdParameter } from '../../../hooks/route/navigationHooks';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { routes } from '../../../constants/routes';
import { SelectedContact, SEND_TYPES_OPTIONS } from '../types';
import { upsertDateFormat } from '../../../constants/formats';

interface Props {
  organizationId: string;
  afterDate: Dayjs;
  beforeDate: Dayjs;
  selectedRows: SelectedContact[];
  onSelect: (selected: SelectedContact[]) => void;
}

const RemindersTable = ({ afterDate, beforeDate, organizationId, selectedRows, onSelect }: Props) => {
  const [dataSource, setDataSource] = useState<Reminder[]>([]);
  const { navigateTo } = useNavigationToRoute();
  const basicRemindersRunColumns = useBasicRemindersRunColumns();

  const { reminders, remindersLoading } = useGetReminders(organizationId, {
    filter: {
      beginDate: afterDate?.format(upsertDateFormat)?.toString(),
      endDate: beforeDate?.format(upsertDateFormat)?.toString(),
      removeInactivePatients: true,
    },
    fetchPolicy: 'cache-and-network',
  });

  const getColumns: () => CustomColumnType<Reminder>[] = () => {
    const allColumns = Array<CustomColumnType<Reminder>>();
    const { patient_name, contact_name, note, name, status_name, contact_send_type_name, date } =
      basicRemindersRunColumns;
    const owingColumns = [contact_name, patient_name, name, date, note, status_name, contact_send_type_name];

    for (const col of owingColumns) {
      if (col.dataIndex === 'contact_name') {
        allColumns.push({
          ...col,
          width: 200,
          render: (contactName: string, record: Reminder) => (
            <TableCellLink
              onClick={() => {
                navigateTo(routes.viewContact, withContactIdParameter(record.contact_id ?? ''));
              }}
            >
              {contactName}
            </TableCellLink>
          ),
        });
      } else if (col.dataIndex === 'contact_send_type_name') {
        allColumns.push({
          ...col,
          filters: SEND_TYPES_OPTIONS,
          onFilter: (value, record) => value === String(record.contact_send_type_name),
          width: 200,
        });
      } else if (col.dataIndex === 'status_name') {
        allColumns.push({
          ...col,
          filters: statuses,
          onFilter: (value, record) => value === String(record.status_name),
          width: 200,
        });
      } else {
        allColumns.push({
          ...col,
          width: 200,
        });
      }
    }

    return allColumns;
  };

  const statuses = useMemo(() => {
    return [...new Set(dataSource.map((item) => item.status_name))]
      .filter((status) => status)
      .map((status) => ({
        text: status as string,
        value: status as string,
      }));
  }, [dataSource]);

  const handleOnRowCheckedChange: TableRowSelection<Reminder>['onChange'] = (_, rows, info) => {
    if (info.type === 'all' && reminders) {
      const selected =
        selectedRows.length !== reminders.length
          ? reminders.map(({ id, contact_send_type_name }) => ({
              id,
              reminderId: id,
              preference: contact_send_type_name,
            }))
          : [];

      onSelect(selected);
    } else {
      onSelect(rows.map((row) => ({ id: row.id, reminderId: row.id, preference: row.contact_send_type_name })));
    }
  };

  const handleOnAllRowsSelect: TableRowSelection<Reminder>['onSelectAll'] = (selected) => {
    onSelect(
      selected
        ? dataSource.map((row) => ({ id: row.id, reminderId: row.id, preference: row.contact_send_type_name }))
        : []
    );
  };

  return (
    <TableWithCustomFiltering
      tableKey={TableKey.RemindersRun}
      columns={getColumns()}
      dataSource={reminders}
      loading={remindersLoading}
      rowKey='id'
      rowSelection={{
        type: 'checkbox',
        onChange: handleOnRowCheckedChange,
        selectedRowKeys: selectedRows.map((row) => row.id),
        onSelectAll: handleOnAllRowsSelect,
      }}
      setCurrentDataSource={setDataSource}
    />
  );
};

export default RemindersTable;
