import React, { useState } from 'react';
import { translations } from '../../constants/translations';
import { SelectWithPopconfirm } from '../SelectWithPopconfirm/SelectWithPopconfirm';
import { Form } from 'antd';
import { appendOrReplaceNote } from './Notes/noteUtils';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Maybe, QuickNoteDto } from '../../graph/types';

export const QuickNoteDropdown = ({
  setTextContent,
  textContent,
  disabled,
  quickNotes,
}: {
  setTextContent: (textContent: string, plainText: string, source: 'api' | 'user' | 'silent') => void;
  textContent?: string;
  disabled?: boolean;
  quickNotes?: Maybe<Maybe<QuickNoteDto>[]> | undefined;
}) => {
  const quickNotesEnabled = useLDFlag(LDFlagNames.QuickNotes);

  const onClickPopover = (shouldAppend = false, value?: string) =>
    appendOrReplaceNote({
      textToAdd: value || selectedValue,
      plainTextToAdd: value || selectedValue,
      setText: (text, plainText) => setTextContent(text, plainText, 'silent'),
      existingText: textContent,
      existingPlainText: textContent,
      shouldAppend,
    });

  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    if (!textContent || textContent === '') {
      onClickPopover(false, value);
    }
  };

  if (!quickNotesEnabled) {
    return null;
  }

  const isDisabled = disabled || !quickNotes?.length;

  return (
    <Form.Item shouldUpdate name={'quick_note'} style={{ minWidth: 150, marginLeft: '8px' }}>
      <SelectWithPopconfirm
        popconfirmTitle={translations.recordList.replaceOrAppendPopover}
        options={quickNotes?.map((qN) => ({ value: qN?.note, label: qN?.name })) || []}
        popconfirmOkText={translations.recordList.append}
        popconfirmCancelText={translations.recordList.replace}
        onConfirm={() => onClickPopover(true)}
        disabled={isDisabled}
        onCancel={onClickPopover}
        onSelectCallback={handleSelect}
        selectPlaceholder={translations.recordList.selectQuickNoteTypePlaceholder}
        shouldShowPopover={!!textContent}
        selectTestId='quickNoteDropdownTestId'
      />
    </Form.Item>
  );
};
