import { useMutation } from '@apollo/client';
import { noop } from 'lodash';
import { GetJob, UpsertJob } from '../../../graph/queries/job';
import { OnJobStatusChange } from '../../../graph/subscription/jobStatusChange';
import {
  Mutation,
  MutationUpsertJobArgs,
  QueryGetJobArgs,
  Subscription,
  SubscriptionOnJobStatusChangeArgs,
} from '../../../graph/types';
import { useSubscription } from '../subscription/subscriptionHooks';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useUpsertJob = () => {
  return useMutation<Mutation, MutationUpsertJobArgs>(UpsertJob);
};

export const useJobStatusChangeSubscription = (jobId: string) => {
  const { data, loading, error } = useSubscription<
    Pick<Subscription, 'onJobStatusChange'>,
    SubscriptionOnJobStatusChangeArgs
  >(OnJobStatusChange, {
    variables: { id: jobId },
    shouldResubscribe: true,
  });

  return { job: data?.onJobStatusChange, loading, error };
};

export const useGetJob = (organizationId: string, jobId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getJob', QueryGetJobArgs>(GetJob, {
    variables: { organizationId, jobId },
    fetchPolicy: 'no-cache',
    onError: noop,
  });

  return {
    job: data?.getJob,
    loading,
    error,
  };
};
