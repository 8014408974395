import { Replication } from './reducer';

export type OfflineModeAction =
  | SetOnline
  | SetOfflineModeEnabled
  | SetOfflineModeInitialized
  | SetOfflineModeReplications
  | ResetOfflineModeContext
  | SetRequirePin
  | SetFile
  | SetStorageDb;

export enum OfflineModeActionType {
  setOnline = 'OFFLINE_MODE_ONLINE',
  setEnabled = 'OFFLINE_MODE_SET_ENABLED',
  setInitialized = 'OFFLINE_MODE_SET_INITIALIZED',
  setReplications = 'OFFLINE_MODE_SET_REPLICATIONS',
  resetOfflineContext = 'OFFLINE_MODE_RESET_CONTEXT',
  setRequirePin = 'OFFLINE_MODE_SET_REQUIRE_PIN',
  setFile = 'OFFLINE_MODE_SET_FILE',
  setStorageDb = 'OFFLINE_MODE_SET_STORAGE_DB',
}

export interface SetOnline {
  type: OfflineModeActionType.setOnline;
  payload: boolean;
}

export interface SetOfflineModeEnabled {
  type: OfflineModeActionType.setEnabled;
  payload: boolean;
}

export interface SetOfflineModeInitialized {
  type: OfflineModeActionType.setInitialized;
  payload: boolean;
}

export interface SetOfflineModeReplications {
  type: OfflineModeActionType.setReplications;
  payload: Record<string, Replication>;
}

export interface ResetOfflineModeContext {
  type: OfflineModeActionType.resetOfflineContext;
}

export interface SetRequirePin {
  type: OfflineModeActionType.setRequirePin;
  payload: boolean;
}

export interface SetFile {
  type: OfflineModeActionType.setFile;
  payload: any;
}

export interface SetStorageDb {
  type: OfflineModeActionType.setStorageDb;
  payload: IDBDatabase;
}

const setOnline = (payload: boolean): SetOnline => ({
  type: OfflineModeActionType.setOnline,
  payload,
});

const setOfflineModeEnabled = (payload: boolean): SetOfflineModeEnabled => ({
  type: OfflineModeActionType.setEnabled,
  payload,
});

const setOfflineModeInitialized = (payload: boolean): SetOfflineModeInitialized => ({
  type: OfflineModeActionType.setInitialized,
  payload,
});

const setOfflineModeReplications = (payload: Record<string, Replication>): SetOfflineModeReplications => ({
  type: OfflineModeActionType.setReplications,
  payload,
});

const setRequirePin = (payload = true): SetRequirePin => ({
  type: OfflineModeActionType.setRequirePin,
  payload,
});

const setOfflineFile = (payload: any): SetFile => ({
  type: OfflineModeActionType.setFile,
  payload,
});

const setStorageDb = (payload: IDBDatabase): SetStorageDb => ({
  type: OfflineModeActionType.setStorageDb,
  payload,
});

const resetOfflineModeContext = (): ResetOfflineModeContext => ({ type: OfflineModeActionType.resetOfflineContext });

export const OfflineModeActions = {
  setOnline,
  setOfflineModeEnabled,
  setOfflineModeInitialized,
  setOfflineModeReplications,
  resetOfflineModeContext,
  setRequirePin,
  setOfflineFile,
  setStorageDb,
};
