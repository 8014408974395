import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useUpsertQuickBooksCallbackCode } from '../../hooks/ajax/quickBooks/quickBooksHooks';
import styled from 'styled-components';
import { CassadolLogo } from '../../images/images';
import { translations } from '../../constants/translations';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { Loading } from '../../components/Loading/Loading';

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 400px;
  text-align: center;
`;

const SpacerDiv = styled.div`
  margin-bottom: 40px;
`;

const QuickBooksCallback: React.FC = () => {
  const location = useLocation();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [remainingTime, setRemainingTime] = useState(5);
  const quickBooksCallbackCode = useUpsertQuickBooksCallbackCode();

  const { organizationId, queryString, quickBooksError } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      organizationId: searchParams.get('state'),
      queryString: location.search,
      quickBooksError: searchParams.get('error'),
    };
  }, [location.search]);

  const tryQuickBooksCallbackCode = useCallback(
    async (organizationId: string, queryString: string) => {
      setError(undefined);
      const callbackCodeResponse = await quickBooksCallbackCode(organizationId, queryString);
      if (callbackCodeResponse.errorMessage) {
        setError(callbackCodeResponse.errorMessage);
      } else {
        setSuccess(true);
      }
    },
    [quickBooksCallbackCode]
  );

  useEffect(() => {
    if (quickBooksError) {
      setError(quickBooksError);
    }
    if (!quickBooksError && organizationId && queryString) {
      tryQuickBooksCallbackCode(organizationId, queryString);
    }
  }, [organizationId, queryString, quickBooksError, tryQuickBooksCallbackCode]);

  useEffect(() => {
    let countdown: any;
    if (success) {
      countdown = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [success]);

  useEffect(() => {
    if (remainingTime < 0) {
      window.close();
    }
  }, [remainingTime]);

  return (
    <Container>
      <SpacerDiv>
        <CassadolLogo />
      </SpacerDiv>
      {error && <ErrorMessage message={translations.shared.quickBooks.auth.callbackCodeErrorMessage(error)} />}
      {!error && !success && <Loading labelText={translations.shared.quickBooks.auth.loadingMessage} />}
      {!error && success && (
        <>
          <h3>{translations.shared.quickBooks.auth.tokenSent}</h3>
          <h3>{translations.shared.quickBooks.auth.closingIn(remainingTime)}</h3>
        </>
      )}
    </Container>
  );
};

export default QuickBooksCallback;
