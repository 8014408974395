import gql from 'graphql-tag';
import { ElectronicPaymentResultFields } from '../fragments/paymentGatewayFragments';
import {
  ElectronicContactRecordUpsert,
  CardConnectTransactionRecordUpsert,
  LedgerUpsert,
  StripePaymentRecordUpsert,
  StripeSetupRecordUpsert,
  StripeAccountLinkRequestRecord,
  StripeStatusRequestRecord,
  StripeCardRecordUpsert,
  CardConnectCardRecordUpsert,
} from '../types';

export interface UpsertCardConnectVariables {
  organizationId: string;
  practiceId: string;
  clientToken?: string;
  transaction?: CardConnectTransactionRecordUpsert;
  contact?: ElectronicContactRecordUpsert;
  payment?: LedgerUpsert;
  card?: CardConnectCardRecordUpsert;
}

export interface UpsertStripeTransactionVariables {
  organizationId: string;
  practiceId: string;
  paymentRecord?: StripePaymentRecordUpsert;
  setupRecord?: StripeSetupRecordUpsert;
  accountLinkRequestRecord?: StripeAccountLinkRequestRecord;
  statusRequestRecord?: StripeStatusRequestRecord;
  cardRecord?: StripeCardRecordUpsert;
}

export type UpsertTransactionVariables = UpsertCardConnectVariables | UpsertStripeTransactionVariables;

export const UpsertCardConnectTransaction = gql`
  mutation UpsertCardConnectTransaction(
    $organizationId: ID!
    $practiceId: ID!
    $clientToken: String
    $transaction: CardConnectTransactionRecordUpsert
    $contact: ElectronicContactRecordUpsert
    $payment: LedgerUpsert
    $card: CardConnectCardRecordUpsert
  ) {
    upsertCardConnectTransaction(
      organizationId: $organizationId
      practiceId: $practiceId
      clientToken: $clientToken
      transaction: $transaction
      contact: $contact
      payment: $payment
      card: $card
    ) {
      ...ElectronicPaymentResultFields
    }
  }
  ${ElectronicPaymentResultFields}
`;

export const UpsertStripeTransaction = gql`
  mutation UpsertStripeTransaction(
    $organizationId: ID!
    $practiceId: ID!
    $paymentRecord: StripePaymentRecordUpsert
    $setupRecord: StripeSetupRecordUpsert
    $accountLinkRequestRecord: StripeAccountLinkRequestRecord
    $statusRequestRecord: StripeStatusRequestRecord
    $embeddedAccountSessionRequestRecord: StripeEmbeddedAccountSessionRequestRecord
    $cardRecord: StripeCardRecordUpsert
  ) {
    upsertStripeTransaction(
      organizationId: $organizationId
      practiceId: $practiceId
      paymentRecord: $paymentRecord
      setupRecord: $setupRecord
      accountLinkRequestRecord: $accountLinkRequestRecord
      statusRequestRecord: $statusRequestRecord
      embeddedAccountSessionRequestRecord: $embeddedAccountSessionRequestRecord
      cardRecord: $cardRecord
    ) {
      ...ElectronicPaymentResultFields
    }
  }
  ${ElectronicPaymentResultFields}
`;

export const UpsertElectronicPaymentResult = gql`
  mutation UpsertElectronicPaymentResult(
    $organizationId: ID!
    $practiceId: ID!
    $clientToken: String!
    $status: PaymentStatusUpdate!
  ) {
    upsertElectronicPaymentResult(
      organizationId: $organizationId
      practiceId: $practiceId
      clientToken: $clientToken
      status: $status
    ) {
      ...ElectronicPaymentResultFields
    }
  }
  ${ElectronicPaymentResultFields}
`;

export const GetElectronicPaymentResult = gql`
  query GetElectronicPaymentResult($organizationId: ID!, $practiceId: ID!, $id: ID!) {
    getElectronicPaymentResult(organizationId: $organizationId, practiceId: $practiceId, id: $id) {
      ...ElectronicPaymentResultFields
    }
  }
  ${ElectronicPaymentResultFields}
`;
