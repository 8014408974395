import { LabManagementAction, LabManagementActionType } from './actions';

export interface LabManagementState {
  hasChanges: boolean;
}

export const initialState: LabManagementState = { hasChanges: false };

export const labManagementReducer = (
  state: LabManagementState = initialState,
  action: LabManagementAction
): LabManagementState => {
  switch (action.type) {
    case LabManagementActionType.setChangesMade:
      return {
        ...state,
        hasChanges: action.hasChanges,
      };
    default:
      return state;
  }
};
