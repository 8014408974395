import React, { CSSProperties, PropsWithChildren } from 'react';
import { Button, Form } from 'antd';
import { translations } from '../../constants/translations';

interface SaveAndResetButtonProps extends PropsWithChildren<unknown> {
  onReset: () => void;
  offset: number;
  style?: CSSProperties;
  saveButtonText?: string;
}

export const SaveAndResetButton: React.FC<SaveAndResetButtonProps> = ({ offset, onReset, style, saveButtonText }) => {
  return (
    <Form.Item style={style || {}} wrapperCol={{ offset }}>
      <Button style={{ marginRight: '10px' }} type='primary' htmlType='submit'>
        {saveButtonText ?? translations.shared.saveChangesButtonText}
      </Button>
      <Button htmlType='button' onClick={onReset}>
        {translations.shared.resetButtonText}
      </Button>
    </Form.Item>
  );
};

export const SaveAndResetButtonPlaceholder = () => {
  return <div style={{ height: '32px', marginBottom: '24px' }} />;
};
