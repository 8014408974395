import styled from 'styled-components';
import { Col, Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { ColProps } from 'antd/es/col';

export const SContactForm = {
  OptionsCol: styled(Col)<ColProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  OptionsFormItem: styled(Form.Item)<FormItemProps>`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    :not(:last-child) {
      margin-right: 1.5rem;
    }
    .ant-form-item-label {
      display: inline-block;
      overflow: visible;
      max-width: initial;
      flex: 1;
    }
    .ant-form-item-control {
      display: inline-block;
      max-width: initial;
    }
  `,
};
