import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Radio } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { RadioGroupProps } from 'antd/lib/radio';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LabResultModal } from '../../../components/LabResultModal/LabResultModal';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { ComponentWithPracticeProps, WithPractice } from '../../../components/WithPractice/WithPractice';
import { displayAsDate } from '../../../constants/formats';
import { translations } from '../../../constants/translations';
import { LabResultRecord } from '../../../graph/types';
import { useGetLabResults } from '../../../hooks/ajax/lab/labHooks';
import { useGetLabState } from '../../../hooks/ajax/organization/organizationHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { TableFilterAndSortKey, TableKey, useTableResetFilterAndSort } from '../../../hooks/tableHooks';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { filterFunction } from '../../../util/filterAndSorting';
import { ServiceMapTab } from '../ServiceMapTab/ServiceMapTab';
import { TaxonomyTab } from '../TaxonomyTab/TaxonomyTab';
import { UnassignedLabsTab } from '../UnassignedLabsTab/UnassignedLabsTab';
import { basicLabManagementColumns } from './LabManagementColumns';
import './LabManagementOverview.less';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { LabManagementContext } from '../store/state';
import { selectHasChanges } from '../store/selectors';
import { setChangesMade } from '../store/actions';
import { useTabChangeWarningProps } from '../../../hooks/tabChangeWarning';
import { TabChangeWarning } from '../../../components/TabChangeWarning/TabChangeWarning';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { labResultsPropertyNames } from '../../../constants/propertyNames';
import { HeaderSpan } from '../../../components/TitleWithSearchBox/TitleWithSearchBox.style';

const screensTranslations = translations.labManagementPage.screens;

enum TabPane {
  LabReports = 'labReports',
  UnassignedLabs = 'unassignedLabs',
  TaxonomyMapping = 'taxonomyMapping',
  ServiceMapping = 'serviceMapping',
}

export const LabManagementOverview: React.FC<ComponentWithPracticeProps> = () => {
  const [currentPane, setCurrentPane] = useState<TabPane>(TabPane.LabReports);
  const organizationId = useGetOrganizationIdFromRoute();
  const { labResults, labResultsLoading, refetch } = useGetLabResults(organizationId);
  const [selectedLabResult, setSelectedLabResult] = useState<LabResultRecord>();

  const [searchValue, setSearchValue] = useState<string>('');

  const { labState } = useGetLabState(organizationId);

  const { state, dispatch } = useContext(LabManagementContext);
  const hasChanges = selectHasChanges(state);
  const { tabChangeWarningProps, handleTabClick } = useTabChangeWarningProps(hasChanges);

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const {
    filteredValue,
    sortOrderMap,
    tableChangeHandler,
    resetFiltersAndSort,
    modifiedFields,
    setFilterAndSortOrder,
  } = useTableResetFilterAndSort(TableFilterAndSortKey.LabManagementOverview);

  const labManagementRadioOptions: RadioGroupProps['options'] = useMemo(() => {
    const unassingnedLabLabel = labState?.has_unassigned ? (
      <>
        {screensTranslations.unassignedLabs.radioTitle}{' '}
        <ExclamationCircleOutlined style={{ color: 'yellow', fontSize: '12px' }} />
      </>
    ) : (
      screensTranslations.unassignedLabs.radioTitle
    );
    return [
      { value: TabPane.LabReports, label: screensTranslations.labReports.radioTitle },
      { value: TabPane.UnassignedLabs, label: unassingnedLabLabel },
      { value: TabPane.TaxonomyMapping, label: screensTranslations.taxonomyMapping.radioTitle },
      { value: TabPane.ServiceMapping, label: screensTranslations.serviceMapping.radioTitle },
    ];
  }, [labState]);

  useEffect(() => {
    setFilterAndSortOrder((prev) => ({
      ...prev,
      sortOrderMap: {
        ...prev.sortOrderMap,
        request_date: 'descend',
      },
    }));
  }, [setFilterAndSortOrder]);

  const renderPane = () => {
    switch (currentPane) {
      case TabPane.LabReports:
        const filteredResults =
          labResults?.filter((result) => {
            const documentString = `${result.contact_name},${result.patient_name},${result.service_list}`;
            return filterFunction(searchValue, documentString);
          }) ?? [];
        return (
          <TableWithCustomFiltering
            tableKey={TableKey.LabManagementOverview}
            columns={columns}
            loading={labResultsLoading}
            dataSource={filteredResults}
            onChange={tableChangeHandler}
            rowKey={'id'}
          />
        );
      case TabPane.UnassignedLabs:
        return <WithPractice Component={UnassignedLabsTab} />;
      case TabPane.TaxonomyMapping:
        return <WithPractice Component={TaxonomyTab} />;
      case TabPane.ServiceMapping:
        return <WithPractice Component={ServiceMapTab} />;
      default:
        return null;
    }
  };

  const labFilters: ColumnFilterItem[] | undefined = useMemo(() => {
    const labDevicesNames = labResults?.map((value) => value.lab_device_name);
    const uniqueNames = labDevicesNames?.filter((c, index) => {
      return labDevicesNames?.indexOf(c) === index;
    });
    return uniqueNames?.map((name) => ({ value: name, text: name }));
  }, [labResults]);

  const columns: CustomColumnType<LabResultRecord>[] = [
    {
      ...basicLabManagementColumns.request_date,
      filteredValue: filteredValue[labResultsPropertyNames.request_date],
      sortOrder: sortOrderMap[labResultsPropertyNames.request_date],
      render: (date: string) => displayAsDate(date, dateFormat),
      width: 100,
    },
    {
      ...basicLabManagementColumns.contact_name,
      filteredValue: filteredValue[labResultsPropertyNames.contact_name],
      sortOrder: sortOrderMap[labResultsPropertyNames.contact_name],
      width: 120,
    },
    {
      ...basicLabManagementColumns.patient_name,
      filteredValue: filteredValue[labResultsPropertyNames.patient_name],
      sortOrder: sortOrderMap[labResultsPropertyNames.patient_name],
      width: 120,
    },
    {
      ...basicLabManagementColumns.lab_device_name,
      filteredValue: filteredValue[labResultsPropertyNames.lab_device_name],
      sortOrder: sortOrderMap[labResultsPropertyNames.lab_device_name],
      filters: labFilters || [],
      width: 120,
    },
    {
      ...basicLabManagementColumns.service_list,
      filteredValue: filteredValue[labResultsPropertyNames.service_list],
      sortOrder: sortOrderMap[labResultsPropertyNames.service_list],
      width: 120,
    },
    {
      ...basicLabManagementColumns.lab_created_accession_id,
      filteredValue: filteredValue[labResultsPropertyNames.lab_created_accession_id],
      sortOrder: sortOrderMap[labResultsPropertyNames.lab_created_accession_id],
      width: 100,
    },
    {
      ...basicLabManagementColumns.lab_created_order_number,
      filteredValue: filteredValue[labResultsPropertyNames.lab_created_order_number],
      sortOrder: sortOrderMap[labResultsPropertyNames.lab_created_order_number],
      width: 100,
    },
    {
      ...basicLabManagementColumns.pims_created_order_number,
      filteredValue: filteredValue[labResultsPropertyNames.pims_created_order_number],
      sortOrder: sortOrderMap[labResultsPropertyNames.pims_created_order_number],
      width: 100,
    },
    {
      ...basicLabManagementColumns.status_id,
      filteredValue: filteredValue[labResultsPropertyNames.status_id],
      sortOrder: sortOrderMap[labResultsPropertyNames.status_id],
      width: 100,
    },
    {
      title: translations.labManagementPage.columns.view,
      render: (_, entry: LabResultRecord) => (
        <TableCellLink onClick={() => setSelectedLabResult(entry)}>
          {translations.labManagementPage.columns.view}
        </TableCellLink>
      ),
      width: 100,
    },
  ];

  const handleOnClear = (key?: string) => {
    if (!key) {
      setSearchValue('');
    }
    resetFiltersAndSort(key);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={false}
      showNavigationWarning={hasChanges}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.labManagementPage.entity)}
    >
      <PageHeader
        title={
          <>
            {'searchPlaceholder' in screensTranslations[currentPane] ? (
              <TitleWithSearchBox
                title={screensTranslations[currentPane].title}
                searchBoxPlaceholder={
                  (screensTranslations[currentPane] as { searchPlaceholder: string }).searchPlaceholder
                }
                onSearchValueChange={({ target: { value } }) => setSearchValue(value)}
                onClear={handleOnClear}
                tags={mapKeysToColumnTitle(modifiedFields, columns)}
              />
            ) : (
              <Space style={{ margin: '14px 0' }} align={'baseline'}>
                <HeaderSpan>{screensTranslations[currentPane].title}</HeaderSpan>
              </Space>
            )}
          </>
        }
      />

      <Space direction='vertical' size='middle' style={{ display: 'flex', padding: '0 0 20px 24px ' }}>
        <Radio.Group
          key='lab-management-options'
          optionType='button'
          buttonStyle='solid'
          options={labManagementRadioOptions}
          value={currentPane}
          onChange={({ target: { value } }) => {
            handleTabClick(() => {
              dispatch(setChangesMade(false));
              setCurrentPane(value);
            });
          }}
          className='lab-radio-group'
        />
        {renderPane()}
      </Space>
      {selectedLabResult && (
        <LabResultModal
          onOk={() => setSelectedLabResult(undefined)}
          labResult={selectedLabResult}
          open
          okText={translations.shared.okButtonText}
          cancelButtonProps={{ hidden: true }}
          refetchResults={refetch}
          hideCloseIcon
        />
      )}
      <TabChangeWarning {...tabChangeWarningProps} />
    </SaveSpinnerAndNavigationWarning>
  );
};
