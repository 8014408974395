import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Col, Form, Row } from 'antd';
import { translations } from '../../../../constants/translations';
import {
  useContactOfflineUpdate,
  useGetContactText,
  useUpdateContact,
} from '../../../../hooks/ajax/contact/contactHooks';
import { Contact, InfoTextUpsert, OrganizationDto, Phone, PhoneType, PracticeDto } from '../../../../graph/types';
import { checkBoxSameAddressesName } from '../../../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import {
  getIndicesOfAddressTypes,
  hasInitialMailingAddress,
} from '../../../../components/PhysicalAndMailingAddress/physicalAndMailingAddressUtil';
import { SaveAndResetButton } from '../../../../components/SaveAndResetButton/SaveAndResetButton';
import { Store } from 'antd/lib/form/interface';
import { isMatch } from '../../../../util/objectComparisons';
import { ContactUpsertGenerator } from '../../../../classes/upsertGenerators/ContactUpsertGenerator';
import { AddressType, AddressUpsertInfo } from '../../../../classes/upsertGenerators/AddressUpsertGenerator';
import { showErrorMessage, showSuccessMessage } from '../../../../components/Notification/notificationUtil';
import { ContactForm, ContactFormFields, noteFieldName } from '../../ContactForm/ContactForm';
import { SaveSpinnerAndNavigationWarning } from '../../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { Loading } from '../../../../components/Loading/Loading';
import { getRefDataWithVoid } from '../../../../util/refDataUtil';
import { FormWithFlexWrap } from '../../../../globalStyles.style';
import {
  phoneTableActions,
  PhoneTableContext,
  PhoneTableContextProvider,
} from '../../../../components/PhoneTable/phoneTableState';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { ViewSubscriptionContext } from '../../../../components/ViewSubscription/store/state';
import ViewSubscriptionActions from '../../../../components/ViewSubscription/store/actions';
import { ID_FOR_OBJECT_CREATION } from '../../../../classes/upsertGenerators/commonUpsertConstants';
import dayjs from 'dayjs';
import { getOfflineId } from '../../../../services/LocalDatabaseService/queries/queryUtils';
import { useUserLocaleData, useLocale } from '../../../../hooks/useUserLocale';
import { textTypeId, useUpdateContactFromContactPageDto } from '../../../../hooks/useUpdateContactFromContactPage';

interface ContactDetailsContentProps {
  practice: PracticeDto;
  contact: Contact;
  organization: OrganizationDto;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

const labelWidth = 6;
export const layout = {
  labelCol: { span: labelWidth },
  wrapperCol: { span: 24 },
};

const ContactDetailsComp: React.FC<ContactDetailsContentProps> = ({
  practice,
  contact,
  organization,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}) => {
  const { canUseCollection } = useOffline('contact');
  const { dispatch: dispatchPhoneTableContext } = useContext(PhoneTableContext);

  const [form] = Form.useForm<ContactFormFields>();

  const [updateContact] = useUpdateContact();
  const { indexOfPhysicalAddress, indexOfMailingAddress } = getIndicesOfAddressTypes(contact);

  const initiallyHasMailingAddress = hasInitialMailingAddress(contact);
  const [hideMailingAddress, setHideMailingAddress] = useState<boolean>(!initiallyHasMailingAddress);
  const organizationId = useGetOrganizationIdFromRoute();
  const textId = contact.text?.[0]?.id;
  const { contactText, contactTextLoading } = useGetContactText(organizationId, textId ?? null);
  const {
    state: { renderKey },
    dispatch: dispatchViewContact,
  } = useContext(ViewSubscriptionContext);
  const offlineUpdate = useContactOfflineUpdate(contact.id);

  const { handleContactUpsert, isSaving, setIsSaving, hasChanged, setHasChanged, shouldUpdateFormValues } =
    useUpdateContactFromContactPageDto(practice, contact);

  const contactReferenceData = organization.ref_contact;

  const refDataWithVoid = getRefDataWithVoid(
    { id: contact.contact_status_id, name: contact.contact_status_name, sort_order: 0 },
    contactReferenceData?.status_type ?? []
  );
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const [phoneLength, setPhoneLength] = useState<number>(contact?.phone?.length || 0);

  useEffect(() => setPhoneLength(contact.phone?.length || 0), [contact.phone, setPhoneLength]);

  useEffect(() => {
    dispatchPhoneTableContext(phoneTableActions.setPhoneTableData(contact.phone ?? []));
  }, [contact, phoneLength, dispatchPhoneTableContext]);

  useEffect(() => {
    dispatchPhoneTableContext(
      phoneTableActions.setPhoneTypeRefData(organization?.ref_demographics?.phone_type as PhoneType[])
    );
  }, [contactReferenceData, dispatchPhoneTableContext, organization]);

  useEffect(() => {
    const upsertContactPhone = async (phone: Phone, isDelete = false) => {
      const contactUpsert = new ContactUpsertGenerator(contact, practice.id).generateUpsertFromPhoneOnly(
        !isDelete ? phone : undefined,
        phone.id
      );
      try {
        setIsSaving(true);
        if (!canUseCollection) {
          await updateContact({
            variables: {
              organizationId: practice.organization_id,
              contact: contactUpsert,
            },
          });
        } else {
          const contact = await offlineUpdate?.(contactUpsert);
          dispatchPhoneTableContext(phoneTableActions.setPhoneTableData(contact?.phone ?? []));
        }
        showSuccessMessage(
          !isDelete ? translations.viewContactPage.phoneUpdateSuccess : translations.viewContactPage.phoneDeleteSuccess
        );
      } catch (e) {
        showErrorMessage((e as Error).message ?? e);
        throw e;
      } finally {
        dispatchViewContact(ViewSubscriptionActions.setEditing(false));
        setIsSaving(false);
      }
    };
    dispatchPhoneTableContext(phoneTableActions.setOnAddSave(upsertContactPhone));
    dispatchPhoneTableContext(phoneTableActions.setOnEditSave(upsertContactPhone));
    dispatchPhoneTableContext(phoneTableActions.setOnDelete((phone) => upsertContactPhone(phone, true)));
    return () => {
      dispatchPhoneTableContext(phoneTableActions.setOnAddSave());
      dispatchPhoneTableContext(phoneTableActions.setOnEditSave());
      dispatchPhoneTableContext(phoneTableActions.setOnDelete());
    };
  }, [
    contact,
    dispatchPhoneTableContext,
    dispatchViewContact,
    practice.id,
    practice.organization_id,
    updateContact,
    canUseCollection,
    offlineUpdate,
    setIsSaving,
    shouldUpdateFormValues,
  ]);

  useEffect(() => {
    // Page does not reload when offline and thus doesn't trigger an initialization of the form
    // and since we changed the useOfflineErrorSkipQuery nextFetchPolicy to avoid issues going online/offline
    // when you are offline and update the contact info after updating the contact and get back online
    // the form remains with the cached data
    // so the fields needs to be set manually
    if (!hasChanged) {
      form.setFields([
        { name: noteFieldName, value: contactText?.value },
        { name: 'name', value: contact.name },
        { name: 'contact_status_id', value: contact.contact_status_id },
        { name: 'contact_status_name', value: contact.contact_status_name },
        { name: 'email', value: contact.email },
        { name: 'send_type', value: contact.send_type },
        { name: 'tax_exempt', value: contact.tax_exempt },
        { name: 'interest_ignore', value: contact.interest_ignore },
        { name: 'address', value: contact.address },
      ]);
    }
  }, [contactText, canUseCollection, form, contact, hasChanged]);

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(hasChanged);
    }
  }, [handleIsEditing, hasChanged]);

  const initialValues = {
    ...contact,
    name: contact.name,
    contact_status_id: contact.contact_status_id,
    contact_status_name: contact.contact_status_name,
    email: contact.email,
    send_type: contact.send_type,
    tax_exempt: contact.tax_exempt,
    interest_ignore: contact.interest_ignore,
    address: contact.address,
    note: contactText?.value,
    [checkBoxSameAddressesName]: !initiallyHasMailingAddress,
  };

  useEffect(() => setHasChanged(false), [renderKey, setHasChanged]);

  useEffect(() => {
    if (shouldUpdateFormValues.current && !hasChanged) {
      form.resetFields();
      setHideMailingAddress(!initiallyHasMailingAddress);
    }
  }, [contact, form, initiallyHasMailingAddress, hasChanged, shouldUpdateFormValues]);

  const handleValueChange = (_: Store, allValues: ContactFormFields) => {
    updateHideMailingAddressState(allValues);
    setHasChanged(!isMatch(initialValues, allValues));
    dispatchViewContact(ViewSubscriptionActions.setEditing(!isMatch(initialValues, allValues)));
  };

  const updateHideMailingAddressState = (allValues: ContactFormFields) => {
    setHideMailingAddress(allValues[checkBoxSameAddressesName]);
  };

  const handleReset = useCallback(() => {
    shouldUpdateFormValues.current = true;
    form.resetFields();
    updateHideMailingAddressState(form.getFieldsValue());
    setHasChanged(false);
    dispatchViewContact(ViewSubscriptionActions.setEditing(false));
  }, [dispatchViewContact, form, setHasChanged, shouldUpdateFormValues]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      handleReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, handleReset, setShouldResetOnTabChange]);

  if (contactTextLoading) {
    return <Loading />;
  }

  const onFinish = (values: ContactFormFields) => {
    const addressUpsertInfos: AddressUpsertInfo[] = [
      { index: indexOfPhysicalAddress, type: AddressType.Physical },
      {
        index: indexOfMailingAddress,
        type: AddressType.Mailing,
        deleteAddress: initiallyHasMailingAddress && values[checkBoxSameAddressesName],
      },
    ];

    const normalizedText: InfoTextUpsert[] = [];

    if (contactText?.value !== values.note) {
      if (contactText && contactText?.value?.trim() !== '') {
        normalizedText.push({
          id: textId ?? '',
          void: true,
        });
      }
      if (values.note.trim() !== '') {
        normalizedText.push({
          id: ID_FOR_OBJECT_CREATION,
          record: {
            value: values.note,
            type_id: textTypeId,
            offline_id: canUseCollection ? getOfflineId() : undefined,
          },
        });
      }
    }

    const contactUpsert = new ContactUpsertGenerator(contact, practice.id).generateFromUpdatedValues(values, {
      addressUpsertInfos,
      text: normalizedText.length ? normalizedText : undefined,
    });

    handleContactUpsert(contactUpsert);
  };

  const emailRequiredEnabledThirdPartyData = organization.ref_system?.third_party
    .filter(({ requires_email, enabled }) => enabled && requires_email)
    .map(({ id }) => id);

  const isEmailRequired = !!contact.third_party?.some(({ third_party_id }) =>
    emailRequiredEnabledThirdPartyData?.includes(third_party_id)
  );
  return (
    <>
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving}
        showNavigationWarning={hasChanged}
        warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.viewContactPage.entity)}
      >
        <FormWithFlexWrap
          {...layout}
          form={form}
          onFinish={onFinish}
          className='paddedForm'
          initialValues={initialValues}
          onValuesChange={handleValueChange}
          autoComplete='off'
        >
          <ContactForm
            indexOfPhysicalAddress={indexOfPhysicalAddress}
            indexOfMailingAddress={indexOfMailingAddress}
            hideMailingAddress={hideMailingAddress}
            form={form}
            contactStatuses={refDataWithVoid}
            contactTextUpdateLine={
              contactText?.updated_user_name
                ? `${contactText?.updated_user_name} - ${dayjs(contactText?.updated).format(dateFormat)}`
                : undefined
            }
            isEmailRequired={isEmailRequired}
          />

          {hasChanged ? (
            <Row style={{ width: '100%' }}>
              <Col span={12}>
                <SaveAndResetButton onReset={handleReset} offset={labelWidth} />
              </Col>
            </Row>
          ) : null}
        </FormWithFlexWrap>
      </SaveSpinnerAndNavigationWarning>
    </>
  );
};

export const ContactDetails: React.FC<ContactDetailsContentProps> = (props) => {
  const { contact } = props;
  const countryNameKey = contact?.address?.[0]?.country_name_key || undefined;
  const { phoneFormat } = useLocale(countryNameKey);

  return (
    <PhoneTableContextProvider state={{ defaultPhoneCountry: countryNameKey ? phoneFormat : undefined }}>
      <ContactDetailsComp {...props} />
    </PhoneTableContextProvider>
  );
};
