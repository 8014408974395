import { WarningOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

const PaddedAlert = styled(Alert)`
  padding: 0.1em 0;
`;

const MainTextSmall = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: smaller;
`;

const MainText = styled.div`
  text-align: center;
  font-weight: bold;
`;

const SubText = styled.div`
  text-align: center;
`;

const ClosedWarningOutlined = styled(WarningOutlined)`
  top: 0.2rem;
`;

const OpenWarningOutlined = styled(WarningOutlined)`
  padding: 0.75rem;
`;

interface UnclosableAlertProps {
  mainText: string;
  subText: string[];
  closedText?: string;
  type: 'error' | 'warning';
}

export const UnclosableAlert: React.FC<UnclosableAlertProps> = ({ mainText, subText, closedText = mainText, type }) => {
  const [isClosed, setIsClosed] = useState(false);

  return isClosed ? (
    <PaddedAlert
      type={type}
      showIcon
      icon={<ClosedWarningOutlined />}
      message={<MainTextSmall>{closedText}</MainTextSmall>}
      onClick={() => setIsClosed(false)}
    />
  ) : (
    <Alert
      message={<MainText>{mainText}</MainText>}
      description={subText.map((sText) => (
        <SubText key={sText}>{sText}</SubText>
      ))}
      type={type}
      showIcon
      icon={<OpenWarningOutlined />}
      closable
      onClose={() => setIsClosed(true)}
    />
  );
};
