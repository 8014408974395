import React, { useContext, useEffect } from 'react';
import { useGetContactPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { setPatientsList } from '../Patients/store/actions';
import { PatientsContext } from '../Patients/store/state';
import { PatientMultiSelect, PatientMultiSelectProps } from './PatientMultiSelect';

type ContactPatientMultiSelectProps = PatientMultiSelectProps & {
  contactId: string;
};

export const ContactPatientMultiSelect: React.FC<ContactPatientMultiSelectProps> = ({ contactId, ...props }) => {
  const { dispatch } = useContext(PatientsContext);
  const organizationId = useGetOrganizationIdFromRoute();
  const { patients, ...search } = useGetContactPatientsWithSearch(contactId, organizationId);

  const selectProps = { ...props, ...search };

  useEffect(() => {
    dispatch(setPatientsList(patients));
  }, [dispatch, patients]);

  return <PatientMultiSelect {...selectProps} />;
};
