import { useMemo } from 'react';
import { GetReferenceData } from '../../../graph/queries/referenceData';
import { useOfflineQuery } from '../useOfflineQuery';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useGetReferenceData = (organizationId?: string, skip?: boolean) => {
  const selector = useMemo(() => ({ organization_id: organizationId }), [organizationId]);
  const { data, loading, error, refetch } = useOfflineQuery<'getReferenceData'>({
    query: GetReferenceData,
    collection: 'reference_data',
    options: {
      variables: { organizationId },
      skip: skip || !organizationId,
    },
    selector,
  });

  return {
    referenceData: data,
    dataLoading: loading,
    dataError: error,
    dataRefetch: refetch,
  };
};

export const useGetReferenceDataForOffline = (organizationId: string) => {
  const { data, loading } = useOfflineErrorSkipQuery<'getReferenceData'>(GetReferenceData, {
    variables: { organizationId },
    skip: !organizationId,
  });

  return {
    referenceData: data?.getReferenceData,
    referenceDataLoading: loading,
  };
};
