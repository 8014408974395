import { ColumnFilterItem } from 'antd/lib/table/interface';
import { translations } from '../constants/translations';
import { Service } from '../graph/types';

export const getGLAccountFilterOptions = (services: Service[]) => {
  const glAccountsList: ColumnFilterItem[] = services
    ?.filter((s) => !!s.general_ledger_id)
    .map((s) => {
      return {
        value: s.general_ledger_id,
        text: `${s.general_ledger_number} - ${s.general_ledger_name}`,
      } as ColumnFilterItem;
    });

  return [{ value: -1, text: translations.shared.none }, ...new Set(glAccountsList)];
};
