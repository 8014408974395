import { Service, ServiceUpsert } from '../../graph/types';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import { ServiceType, ServiceUpsertFormFields } from '../../pages/Services/ServiceForm/ServiceForm';
import { ServiceThirdPartyId } from '../../constants/referenceData/serviceReferenceData';

export class ServiceUpsertGenerator {
  private readonly initialService: Partial<Service>;
  private generatedServiceUpsert: ServiceUpsert;
  private readonly practiceId: string;

  constructor(initialService: Partial<Service>, practiceId: string) {
    this.initialService = initialService;
    this.practiceId = practiceId;
  }

  static generateNewEntry(formValues: ServiceUpsertFormFields, practiceId: string) {
    return new ServiceUpsertGenerator({ id: ID_FOR_OBJECT_CREATION }, practiceId).generateFromUpdatedValues({
      ...formValues,
      inactive: false,
    });
  }

  generateFromUpdatedValues(formValues: ServiceUpsertFormFields): ServiceUpsert {
    this.generatedServiceUpsert = { id: this.initialService.id };
    this.addRecordToUpsert(formValues);
    this.generatedServiceUpsert.tax = formValues.tax;
    this.handleEMR(formValues);
    this.handleHISA(formValues);
    if (formValues.duration) {
      this.generatedServiceUpsert.reminder = [{ record: { duration: formValues.duration } }];
    }
    return this.generatedServiceUpsert;
  }

  generateFromServiceValues(service: Service, formValues: ServiceUpsertFormFields): ServiceUpsert {
    const serviceReminder = service?.reminder?.[0];
    this.generatedServiceUpsert = { id: this.initialService.id };
    this.addRecordToUpsertFromService(service);
    this.generatedServiceUpsert.tax = formValues.tax;
    this.handleEMRFromService(service);
    this.handleHISAFromService(service);
    if (serviceReminder) {
      this.generatedServiceUpsert.reminder = [{ record: { duration: serviceReminder?.duration ?? 365 } }];
    }
    return this.generatedServiceUpsert;
  }

  generateForBooleanChanges(value: boolean, formField: keyof ServiceUpsertFormFields, service: Service) {
    this.generatedServiceUpsert = { id: this.initialService.id };
    this.generatedServiceUpsert.record = {
      name: service.name,
      price: service.price,
      controlled: service.controlled,
      vaccine: service.vaccine,
      microchip: service.microchip,
      inactive: service.inactive,
      quantity_prompt: service.quantity_prompt,
      roa_prompt: service.roa_prompt,
      prescription_prompt: service.prescription_prompt,
      rabies_tag_prompt: service.rabies_tag_prompt,
      reason_prompt: service.reason_prompt ?? false,
      decease_prompt: service.decease_prompt ?? false,
      withdrawal_prompt: service.withdrawal_prompt ?? false,
      [formField]: value,
    };
    return this.generatedServiceUpsert;
  }

  private addRecordToUpsert(updatedValues: ServiceUpsertFormFields) {
    this.generatedServiceUpsert.record = {
      practice_id: this.practiceId,
      name: updatedValues.name,
      unit_name: updatedValues.unit_name,
      quantity_default: updatedValues.quantity_default || undefined,
      price: updatedValues.price,
      controlled: updatedValues.type === ServiceType.controlled,
      vaccine: updatedValues.type === ServiceType.vaccine,
      microchip: !!updatedValues?.microchip,
      quantity_prompt: !!updatedValues?.quantity_prompt,
      inactive: updatedValues.inactive,
      general_ledger_id: updatedValues.generalLedgerId,
      prescription_prompt: !!updatedValues?.prescription_prompt,
      roa_prompt: !!updatedValues?.roa_prompt,
      rabies_tag_prompt: !!updatedValues?.rabies_tag_prompt,
      reason_prompt: !!updatedValues.reason_prompt,
      decease_prompt: !!updatedValues.decease_prompt,
      withdrawal_prompt: !!updatedValues.withdrawal_prompt,
      material_cost: updatedValues.material_cost,
      markup_percentage: updatedValues.markup_percentage,
    };
  }

  private addRecordToUpsertFromService(service: Service) {
    this.generatedServiceUpsert.record = {
      practice_id: this.practiceId,
      name: service.name,
      unit_name: service.unit_name,
      quantity_default: service.quantity_default || undefined,
      price: service.price,
      controlled: this.getType(service) === ServiceType.controlled,
      vaccine: this.getType(service) === ServiceType.vaccine,
      microchip: !!service?.microchip,
      quantity_prompt: !!service?.quantity_prompt,
      inactive: service.inactive,
      general_ledger_id: service.general_ledger_id,
      prescription_prompt: !!service?.prescription_prompt,
      roa_prompt: !!service?.roa_prompt,
      rabies_tag_prompt: !!service?.rabies_tag_prompt,
      reason_prompt: !!service.reason_prompt,
      decease_prompt: !!service.decease_prompt,
      withdrawal_prompt: !!service.withdrawal_prompt,
    };
  }

  private getType(service: Service) {
    if (service.controlled) {
      return ServiceType.controlled;
    } else if (service.vaccine) {
      return ServiceType.vaccine;
    }
    return ServiceType.standard;
  }

  handleEMRFromService = (service: Service) => {
    const enable_emr = !!service.third_party?.find(({ third_party_id }) => third_party_id === ServiceThirdPartyId.EMR);
    const initialEmrEntry = this.initialService.third_party?.find(
      ({ third_party_id }) => third_party_id === ServiceThirdPartyId.EMR
    );

    if (!initialEmrEntry && !enable_emr) {
      return;
    }

    const previousThirdPartyUpsert = this.generatedServiceUpsert.third_party ?? [];

    this.generatedServiceUpsert.third_party =
      enable_emr !== !!initialEmrEntry
        ? [
            ...previousThirdPartyUpsert,
            {
              id: initialEmrEntry?.id ?? ID_FOR_OBJECT_CREATION,
              record: {
                third_party_id: ServiceThirdPartyId.EMR,
              },
              void: !!initialEmrEntry && !enable_emr,
            },
          ]
        : undefined;
  };

  handleEMR = (values: ServiceUpsertFormFields) => {
    const initialEmrEntry = this.initialService.third_party?.find(
      ({ third_party_id }) => third_party_id === ServiceThirdPartyId.EMR
    );

    if (!initialEmrEntry && !values.enable_emr) {
      return;
    }

    const previousThirdPartyUpsert = this.generatedServiceUpsert.third_party ?? [];

    this.generatedServiceUpsert.third_party =
      values.enable_emr !== !!initialEmrEntry
        ? [
            ...previousThirdPartyUpsert,
            {
              id: initialEmrEntry?.id ?? ID_FOR_OBJECT_CREATION,
              record: {
                third_party_id: ServiceThirdPartyId.EMR,
              },
              void: !!initialEmrEntry && !values.enable_emr,
            },
          ]
        : undefined;
  };

  handleHISA = (values: ServiceUpsertFormFields) => {
    const initialHISAEntry = this.initialService.third_party?.find(
      ({ third_party_id }) => third_party_id === ServiceThirdPartyId.HISA
    );

    if (!initialHISAEntry && !values.hisa_category) {
      return;
    }

    const previousThirdPartyUpsert = this.generatedServiceUpsert.third_party ?? [];

    const hisaRecordUpsert = { third_party_id: ServiceThirdPartyId.HISA, ref_data_id: values.hisa_category };

    this.generatedServiceUpsert.third_party =
      values.hisa_category !== initialHISAEntry
        ? [
            ...previousThirdPartyUpsert,
            ...(initialHISAEntry
              ? [
                  {
                    id: ID_FOR_OBJECT_CREATION,
                    record: hisaRecordUpsert,
                  },
                  {
                    id: initialHISAEntry?.id,
                    void: true,
                  },
                ]
              : [
                  {
                    id: ID_FOR_OBJECT_CREATION,
                    record: hisaRecordUpsert,
                  },
                ]),
          ]
        : undefined;
  };

  handleHISAFromService = (service: Service) => {
    const hisa_category = service.third_party?.find(
      ({ third_party_id }) => third_party_id === ServiceThirdPartyId.HISA
    )?.ref_data_id;
    const initialHISAEntry = this.initialService.third_party?.find(
      ({ third_party_id }) => third_party_id === ServiceThirdPartyId.HISA
    );

    if (!initialHISAEntry && !hisa_category) {
      return;
    }

    const previousThirdPartyUpsert = this.generatedServiceUpsert.third_party ?? [];

    const hisaRecordUpsert = { third_party_id: ServiceThirdPartyId.HISA, ref_data_id: hisa_category };

    this.generatedServiceUpsert.third_party =
      hisa_category !== initialHISAEntry
        ? [
            ...previousThirdPartyUpsert,
            ...(initialHISAEntry
              ? [
                  {
                    id: ID_FOR_OBJECT_CREATION,
                    record: hisaRecordUpsert,
                  },
                  {
                    id: initialHISAEntry?.id,
                    void: true,
                  },
                ]
              : [
                  {
                    id: ID_FOR_OBJECT_CREATION,
                    record: hisaRecordUpsert,
                  },
                ]),
          ]
        : undefined;
  };
}
