import React from 'react';
import { Input } from 'antd';
import { translations } from '../../constants/translations';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';

export interface ControlledDrugModalFormValue {
  bottle_number?: string;
}

export interface ControlledDrugModalProps {
  autofocus?: boolean;
}

export const controlledDrugModalFormTestId = 'controlledDrugModalForm';

export const ControlledDrugModalContent: React.FC<ControlledDrugModalProps> = ({ autofocus }) => (
  <>
    <MaxLengthFormItem
      label={translations.shared.controlledDrugModal.fields.bottle_number}
      name='bottle_number'
      maxLength={25}
    >
      <Input autoFocus={autofocus} />
    </MaxLengthFormItem>
  </>
);
