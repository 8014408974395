import React, { useContext, useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { translations } from '../../constants/translations';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import {
  BatchGroup,
  BatchGroupUpsert,
  Job,
  JobUpsert,
  LabDeviceTaxonomy,
  LabRequest,
  Patient,
  PrescriptionFlattened,
  Query,
  Service,
  ServiceRendered,
} from '../../graph/types';
import { patientTagWidth } from './PatientMultiSelect';
import {
  RapidBillingServiceRendered,
  RapidBillingServices,
  RapidBillingServicesProps,
} from './RapidBillingServices/RapidBillingServices';
import { servicesRenderedTableId } from '../../components/ServiceRendered/ServicesRenderedTable/ServicesRenderedTable';
import { ServicesRenderedContext } from '../../components/ServiceRendered/store/state';
import { RapidBillingFooter } from './RapidBillingFooter/RapidBillingFooter';
import {
  getRequiredHisaValuesByServiceAndPatient,
  mapToRapidBillingUpsert,
  validateRapidBatch,
} from './rapidBillingMappingUtil';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { BatchOptions, defaultBatchGroupOptions, RapidBillingOptions } from './RapidBillingOptions/RapidBillingOptions';
import { joinPatientOrContactNameAndNumber } from '../../util/displaying';
import { ContactPatientMultiSelect } from './ContactPatientMultiSelect';
import { AllPatientsMultiSelect } from './AllPatientsMultiSelect';
import { setConditionTreated, setContactId } from '../../components/ServiceRendered/store/actions';
import { UpsertServiceBatchGroup } from '../../graph/queries/rapidBilling';
import { useUpsertJob } from '../../hooks/ajax/job/jobHooks';
import { getJobRecordUpsert } from '../../util/jobUtil';
import { JobStatus } from '../../components/JobStatus/JobStatus';
import { PrescriptionType } from '../../constants/prescriptions';
import dayjs from 'dayjs';
import { PatientOptionsModal, PatientOptionsModalProps } from './RapidBillingPatientOptions/PatientOptionsModal';
import { ErrorsModal, ErrorsModalProps } from './RapidBillingPatientOptions/ErrorsModal/ErrorsModal';
import { Store } from 'antd/lib/form/interface';
import { getPatientUpserts } from '../../util/patientUtil';
import { usePatientOfflineUpdateForRapidBilling, useUpdatePatient } from '../../hooks/ajax/patients/patientHooks';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetBatchLabRequests } from '../../graph/queries/labLink';
import { LabRequestModal } from '../../components/LabRequestModal/LabRequestModal';
import { getServicePatientRequirements } from '../../components/ServiceRendered/serviceRenderedUtils';
import { PatientFieldsRequirement } from '../../components/PatientModalContent/PatientModalContent';
import { TaxonomyMappingModal } from '../../components/TaxonomyMappingModalContent/TaxonomyMappingModalContent';
import { useLabResubmission } from '../../hooks/ajax/lab/labHooks';
import { sortServicesByDateAndSortOrder } from '../Invoices/ViewInvoice/viewInvoiceUtil';
import { upsertDateFormat } from '../../constants/formats';
import { Loading } from '../../components/Loading/Loading';
import { useOrganizationContext } from '../../contexts/organization/state';
import { successMessageOption } from '../../components/ServiceRendered/AddServiceForm/AddServiceForm';
import { HisaFormFieldsValues } from '../../constants/hisaRequirements';
import { HisaFieldsRequirement } from '../../components/ServiceRendered/HisaRequirementsModal/HisaRequirementsContent';

const SelectPatientModal = lazy(() => import('../../components/SelectPatientModal/SelectPatientModal'));

export type PatientsByServiceRenderedId = Record<string, Patient[]>;

const filterForValidPatients = (patients: Patient[]) => {
  const validPatients: Patient[] = [];
  const inValidPatients: Patient[] = [];
  patients.forEach((patient) => {
    const isValid = patient.owner_names && patient.owner_names.length > 0;
    isValid ? validPatients.push(patient) : inValidPatients.push(patient);
  });
  if (inValidPatients.length > 0) {
    showErrorMessage(
      translations.rapidBilling.invalidPatientsErrorMessage(
        inValidPatients.map((patient) => joinPatientOrContactNameAndNumber(patient)).join(', ')
      )
    );
  }
  return validPatients;
};

export type BatchGroupData = {
  patients: Patient[];
  servicesRendered: ServiceRendered[];
  prescriptions: PrescriptionFlattened[];
  patientsByServiceRenderedIds: PatientsByServiceRenderedId;
  batchGroupOptions: BatchOptions;
  servicesRenderedByServiceId: Record<string, ServiceRendered[]>;
  hisaValues: HisaValuesByServiceAndPatient;
};

export interface RapidBillingProps {
  practiceId: string;
  offlineBatchGroupData?: BatchGroupData;
  isOnline?: boolean;
  canUseUpsertCollection?: boolean;
  hideHeader?: boolean;
  hideContent?: boolean;
  onOfflineBatchDelete?: () => void;
  onOfflineBatchSave?: (upsert: BatchGroupUpsert) => Promise<void>;
  showQueuedBatches: boolean;
  onToggleShowQueuedBatches?: () => void;
  disableShowQueuedBachesButton?: boolean;
  onBatchPendingChangesStatusChange?: (hasPending: boolean) => void;
  hasUnsavedChanges?: boolean;
}

export type HisaValuesByServiceAndPatient = Record<string, Record<string, HisaFormFieldsValues>>;

export const RapidBilling: React.FC<RapidBillingProps> = ({
  practiceId,
  offlineBatchGroupData,
  isOnline = true,
  canUseUpsertCollection,
  hideHeader,
  hideContent,
  onOfflineBatchDelete,
  onOfflineBatchSave,
  showQueuedBatches,
  onToggleShowQueuedBatches,
  disableShowQueuedBachesButton,
  onBatchPendingChangesStatusChange,
  hasUnsavedChanges = false,
}: RapidBillingProps) => {
  const history = useHistory();
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertJob] = useUpsertJob();
  const [resubmitLab] = useLabResubmission();

  const [selectedPatientModalVisible, setSelectedPatientModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [defaultSelectedPatients, setDefaultSelectedPatients] = useState<Patient[]>([]);
  const [patientsEdited, setPatientsEdited] = useState<boolean>(false);

  const [servicesRendered, setServicesRendered] = useState<RapidBillingServiceRendered[]>([]);
  const [hisaValues, setHisaValues] = useState<HisaValuesByServiceAndPatient>();
  const [patientsByServiceRenderedIds, setPatientsByServiceRenderedIds] = useState<PatientsByServiceRenderedId>({});
  const [activeServiceId, setActiveServiceId] = useState<string | null>(null);
  const [batchGroupOptions, setBatchGroupOptions] = useState<BatchOptions>(defaultBatchGroupOptions);
  const [jobId, setJobId] = useState<string>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const [prescriptions, setPrescriptions] = useState<PrescriptionFlattened[]>([]);

  const { state, dispatch } = useContext(ServicesRenderedContext);

  const {
    state: { organization },
  } = useOrganizationContext();

  const [updatePatient] = useUpdatePatient();
  const offlinePatientUpdate = usePatientOfflineUpdateForRapidBilling(organization);
  const [patientModalProps, setPatientModalProps] = useState<PatientOptionsModalProps>();
  const [errorModalProps, setErrorModalProps] = useState<ErrorsModalProps>();
  const [percentage, setPercentage] = useState<number | undefined>(undefined);
  const [serviceMicrochip, setServiceMicrochip] = useState<boolean>(false);
  const [serviceRabiesTag, setServiceRabiesTag] = useState<boolean>(false);
  const [patientFieldsRequired, setPatientFieldsRequired] = useState<Record<string, PatientFieldsRequirement>>();
  const [hisaFieldsRequired, setHisaFieldsRequired] = useState<Record<string, Record<string, HisaFieldsRequirement>>>();
  const [serviceDeceasedDate, setServiceDeceasedDate] = useState<boolean>(false);
  const [fireRapidBilling, setFireRapidBillingSave] = useState<boolean>(false);
  const [patientsWithErrorOnUpsert, setPatientsWithErrorOnUpsert] = useState<Patient[]>([]);
  const [labRequests, setLabRequests] = useState<LabRequest[]>();
  const [labDeviceTaxonomyMapping, setLabDeviceTaxonomyMapping] = useState<LabDeviceTaxonomy[]>();
  const [batchGroup, setBatchGroup] = useState<BatchGroup>();
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);

  const services = useMemo(() => state.cashedServices, [state.cashedServices]);

  useEffect(() => {
    return () => {
      dispatch(setContactId(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOnline) {
      setBatchGroupOptions(defaultBatchGroupOptions);
    } else {
      setBatchGroupOptions({
        ...defaultBatchGroupOptions,
        closeInvoices: false,
      });
    }
  }, [isOnline, setBatchGroupOptions]);

  const resetPage = () => {
    setDefaultSelectedPatients([]);
    setServicesRendered([]);
    setPrescriptions([]);
    setActiveServiceId(null);
    setPatientsByServiceRenderedIds({});
    setPatientsEdited(false);
    onBatchPendingChangesStatusChange?.(false);
    dispatch(setConditionTreated(''));
    setFireRapidBillingSave(false);
    setHisaValues(undefined);
    setHisaFieldsRequired(undefined);
    setPatientFieldsRequired(undefined);
  };

  useEffect(() => {
    if (!isOnline && offlineBatchGroupData && !isInitialized) {
      setDefaultSelectedPatients(offlineBatchGroupData.patients);
      setServicesRendered(offlineBatchGroupData.servicesRendered);
      setPrescriptions(offlineBatchGroupData.prescriptions);
      setActiveServiceId(null);
      setPatientsByServiceRenderedIds(offlineBatchGroupData.patientsByServiceRenderedIds);
      setPatientsEdited(false);
      setBatchGroupOptions(offlineBatchGroupData.batchGroupOptions);
      setIsInitialized(true);
      setHisaValues(offlineBatchGroupData.hisaValues);
    }
  }, [isOnline, offlineBatchGroupData, isInitialized]);

  useEffect(() => {
    if (services) {
      const filteredServices = services.filter((s) => servicesRendered.some((sr) => sr.service_id === s.id));
      setServiceMicrochip(filteredServices.some((x) => x.microchip));
      setServiceRabiesTag(filteredServices.some((x) => x.rabies_tag_prompt));
      setServiceDeceasedDate(filteredServices.some((x) => x.decease_prompt));
      setFilteredServices(filteredServices);
    }
  }, [servicesRendered, services]);

  useEffect(() => {
    if (fireRapidBilling) {
      saveRapidBilling();
    }
    // eslint-disable-next-line
  }, [fireRapidBilling]);

  const handleBatchDelete = () => {
    if (canUseUpsertCollection) {
      onOfflineBatchDelete?.();
    }

    resetPage();
  };

  const setValidPatientsOfService = (serviceId: string, patients: Patient[]) => {
    const validPatients = filterForValidPatients(patients);
    setPatientsByServiceRenderedIds((prevState) => ({ ...prevState, [serviceId]: validPatients }));
  };

  const setValidDefaultPatients = (patients: Patient[]) => {
    setPatientsEdited(true);
    const validPatients = filterForValidPatients(patients);
    setDefaultSelectedPatients(validPatients);
  };
  const setPatientsOfActiveService = (patients: Patient[]) => {
    setPatientsEdited(true);
    if (activeServiceId) {
      setValidPatientsOfService(activeServiceId, patients);
    }
    setDefaultSelectedPatients(patients);
  };
  const [selectedPatients, setSelectedPatients]: [Patient[], (patients: Patient[]) => void] = activeServiceId
    ? [patientsByServiceRenderedIds[activeServiceId] ?? [], setPatientsOfActiveService]
    : [defaultSelectedPatients, setValidDefaultPatients];

  const setServiceRequirements = (serviceRendered: RapidBillingServiceRendered) => {
    const serviceRenderedService = services.find((s) => s.id === serviceRendered.service_id);
    if (serviceRenderedService) {
      setServiceMicrochip(serviceRenderedService?.microchip);
      setServiceRabiesTag(serviceRenderedService.rabies_tag_prompt);
      const requirementsLookup: Record<string, PatientFieldsRequirement> = patientFieldsRequired ?? {};
      const hisaRequirementsLookup: Record<string, Record<string, HisaFieldsRequirement>> = hisaFieldsRequired ?? {};

      selectedPatients.forEach((patient) => {
        const { hasMissingPatientFields, missingPatientFields, hasMissingHisaFields, missingHisaFields } =
          getServicePatientRequirements(serviceRenderedService, patient);

        if (hasMissingPatientFields) {
          requirementsLookup[patient.id] = { ...(requirementsLookup[patient.id] || {}), ...missingPatientFields };
        }
        if (hasMissingHisaFields) {
          if (!hisaRequirementsLookup[patient.id]) {
            hisaRequirementsLookup[patient.id] = {};
          }
          hisaRequirementsLookup[patient.id][serviceRenderedService.id] = {
            ...(hisaRequirementsLookup[patient.id][serviceRenderedService.id] || {}),
            ...missingHisaFields,
          };
        }
      });

      if (requirementsLookup && Object.keys(requirementsLookup).length > 0) {
        setPatientFieldsRequired(requirementsLookup);
      }
      if (hisaRequirementsLookup && Object.keys(hisaRequirementsLookup).length > 0) {
        setHisaFieldsRequired(hisaRequirementsLookup);
      }
    }
  };

  const saveRapidBilling = async () => {
    setIsSaving(true);
    const { error, erroneousServiceIds } = validateRapidBatch(servicesRendered, patientsByServiceRenderedIds);
    if (error) {
      showErrorMessage(error);
      setIsSaving(false);
      if (erroneousServiceIds && erroneousServiceIds.length > 0) {
        setActiveServiceId(erroneousServiceIds[0]);
      }
      setFireRapidBillingSave(false);
      return;
    }

    const upsert: BatchGroupUpsert = mapToRapidBillingUpsert(
      servicesRendered,
      patientsByServiceRenderedIds,
      batchGroupOptions,
      practiceId,
      upsertDateFormat,
      !canUseUpsertCollection,
      hisaValues
    );

    if (canUseUpsertCollection) {
      await onOfflineBatchSave?.(upsert);
      if (!showQueuedBatches) {
        resetPage();
      }

      setFireRapidBillingSave(false);
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } else {
      const batchVariables = { organizationId, batch: upsert };
      const jobRecord = getJobRecordUpsert(UpsertServiceBatchGroup, batchVariables);

      const job: JobUpsert = {
        record: jobRecord,
      };

      const jobVariables = {
        organizationId,
        job,
      };

      try {
        const { data } = await upsertJob({
          variables: jobVariables,
        });
        setJobId(data?.upsertJob.id);
      } catch (e) {
        showErrorMessage(translations.shared.generalErrorMessage);
      }
    }

    onBatchPendingChangesStatusChange?.(false);
    setIsSaving(false);
  };

  const savePatients = async (values: Store) => {
    if (organization) {
      setFireRapidBillingSave(false);
      const patientUpserts = getPatientUpserts(values, allPatients(), organization, upsertDateFormat);
      if (patientUpserts.length > 0) {
        for await (const upsert of patientUpserts) {
          const currentIndex = patientUpserts.indexOf(upsert);
          setPercentage((currentIndex * 100) / patientUpserts.length);
          try {
            if (!canUseUpsertCollection) {
              await updatePatient({
                ...successMessageOption,
                variables: {
                  organizationId,
                  patient: upsert,
                },
              });
            } else {
              await offlinePatientUpdate?.(upsert);
            }
          } catch (e) {
            const patientWithError = allPatients().find((x) => x.id === upsert.id);
            if (patientWithError !== undefined) {
              const newPatienWithErrorArray = patientsWithErrorOnUpsert;
              newPatienWithErrorArray.push(patientWithError);
              setPatientsWithErrorOnUpsert(newPatienWithErrorArray);
            }
          }
        }
        if (patientsWithErrorOnUpsert.length > 0) {
          setErrorModalProps({
            visible: true,
            onClose: () => {
              setErrorModalProps({ ...errorModalProps!, visible: false });
              setFireRapidBillingSave(true);
            },
            patients: patientsWithErrorOnUpsert,
          });
        } else {
          setFireRapidBillingSave(true);
        }
      } else {
        setFireRapidBillingSave(true);
      }
    }
  };

  const openPatientOptionsModal = (patients: Patient[] | undefined) => {
    setPatientModalProps({
      visible: true,
      onClose: () => {
        setPatientModalProps({ ...patientModalProps!, visible: false });
      },
      onSave: async (values) => {
        if (hisaFieldsRequired) {
          setFireRapidBillingSave(false);
          const requiredHisaValuesByServiceAndPatient: Record<
            string,
            Record<string, HisaFormFieldsValues>
          > = getRequiredHisaValuesByServiceAndPatient(values);

          if (Object.keys(requiredHisaValuesByServiceAndPatient).length > 0) {
            setHisaValues(requiredHisaValuesByServiceAndPatient);
          }
        }
        await savePatients(values);
      },
      patients,
      progress: percentage,
      patientFieldsRequired,
      organization,
      patientsByServiceRenderedIds,
      services: filteredServices,
      servicesRendered,
      hisaFieldsRequired,
    });
  };

  const allPatients = () => {
    return Object.values(patientsByServiceRenderedIds).reduce((accumulator, servicePatients) => {
      servicePatients.forEach((patient) => {
        if (!accumulator.some((p) => p.id === patient.id)) {
          accumulator.push(patient);
        }
      });
      return accumulator;
    });
  };

  const handleRapidBillingAction = async () => {
    if (
      serviceMicrochip ||
      serviceRabiesTag ||
      patientFieldsRequired ||
      hisaFieldsRequired ||
      (serviceDeceasedDate && selectedPatients.some((p) => !p.deceased))
    ) {
      openPatientOptionsModal(allPatients());
    } else {
      await saveRapidBilling();
    }
  };

  const handleClick = (event: MouseEvent) => {
    const servicesRenderedTableBodyNode = document.querySelector(`#${servicesRenderedTableId} .ant-table-body`);
    const eventTargetNode = event.target as Node;
    if (
      !servicesRenderedTableBodyNode?.contains(eventTargetNode) &&
      state.editingServiceRenderedId !== activeServiceId
    ) {
      setActiveServiceId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });

  const isPatientSelectionDisabled = !!activeServiceId && !state.editingServiceRenderedId;

  const heightBelowServicesTable = document.getElementById('RapidBilling__BelowTable')?.offsetHeight ?? 0;

  const refreshPrescriptions = (services: ServiceRendered[]) => {
    const servicesWithPrescription = services.filter((service) => service.prescriptions);
    const newPrescriptions: PrescriptionFlattened[] = servicesWithPrescription.map((service) => ({
      filled_service_rendered_id: service.id,
      id: service.id,
      organization_id: organizationId,
      practice_id: service.prescriptions?.[0].practice_id || '',
      patient_id: service.prescriptions?.[0].patient_id || '',
      contact_id: service.prescriptions?.[0].contact_id || '',
      item_quantity: service.prescriptions?.[0].item_quantity || '0',
      fill_externally: service.prescriptions?.[0].fill_externally || false,
      created_type_id: service.prescriptions?.[0].created_type_id || PrescriptionType.Patient,
      prescription_expiry_date: service.prescriptions?.[0].prescription_expiry_date || dayjs().format(upsertDateFormat),
      prescribed_date: service.prescriptions?.[0].prescribed_date || dayjs().format(upsertDateFormat),
      prescribed_user_id: service.prescriptions?.[0].prescribed_user_id || '',
      created: service.prescriptions?.[0].created || dayjs().format(upsertDateFormat),
      created_user_id: service.prescriptions?.[0].created_user_id || '',
      updated: service.prescriptions?.[0].updated || dayjs().format(upsertDateFormat),
      hidden: service.prescriptions?.[0].hidden || false,
      is_filled: service.prescriptions?.[0].is_filled || false,
      available_fills: service.prescriptions?.[0].available_fills || 0,
      filled_fills: service.prescriptions?.[0].filled_fills || 0,
      remaining_fills: service.prescriptions?.[0].remaining_fills || 0,
      ...service.prescriptions?.[0],
    }));
    setPrescriptions(newPrescriptions);
  };

  const handleSetServicesRendered: RapidBillingServicesProps['setServicesRendered'] = (action, serviceRendered) => {
    switch (action) {
      case 'add':
        setServicesRendered((prevState) => {
          const updatedServicesRendered = sortServicesByDateAndSortOrder([...prevState, serviceRendered]);
          refreshPrescriptions(updatedServicesRendered);
          return updatedServicesRendered;
        });
        setServiceRequirements(serviceRendered);
        break;
      case 'update': {
        const updatedServicesRendered = sortServicesByDateAndSortOrder(
          servicesRendered.map((existingService) => {
            if (existingService.id === serviceRendered.id) {
              return serviceRendered;
            }
            return existingService;
          })
        );
        refreshPrescriptions(updatedServicesRendered);
        setServicesRendered(updatedServicesRendered);
        break;
      }
      default: {
        const updatedServicesRendered = servicesRendered.filter(
          (existingService) => existingService.id !== serviceRendered.id
        );
        refreshPrescriptions(updatedServicesRendered);
        setServicesRendered(updatedServicesRendered);
      }
    }
    onBatchPendingChangesStatusChange?.(true);
  };

  const checkForLabs = async (batchGroup: BatchGroup) => {
    const batchGroupId = batchGroup.id;

    setIsSaving(true);
    const { data } = await AppSyncService.client.query<Pick<Query, 'getBatchLabRequests'>>({
      query: GetBatchLabRequests,
      variables: { organizationId, batchGroupId },
    });
    const batchLabRequests = data?.getBatchLabRequests;
    batchLabRequests && setLabRequests(batchLabRequests);
    setIsSaving(false);
  };

  const checkForDeviceMapping = async (job: Job) => {
    if (job.result) {
      const batchGroupResult = (await JSON.parse(job.result).result) as BatchGroup;
      setBatchGroup(batchGroupResult);
      const batchLabTaxonomyMapping = batchGroupResult.batchLabTaxonomyMapping;

      const requiresMapping = batchLabTaxonomyMapping?.find(
        ({ requires_breed_map, requires_gender_map, requires_species_map }) =>
          requires_breed_map || requires_gender_map || requires_species_map
      );
      if (!!requiresMapping) {
        setLabDeviceTaxonomyMapping(batchLabTaxonomyMapping!);
      } else {
        checkForLabs(batchGroupResult);
      }
    }
  };

  const closeJobStatusModal = (errorMessage?: string) => {
    setJobId(undefined);
    if (errorMessage) {
      showErrorMessage(errorMessage);
    } else {
      resetPage();
      showSuccessMessage(translations.shared.saveSuccessMessage);
    }
  };

  const showUnsavedChangesWarning = () => {
    if (!showQueuedBatches) {
      return (
        (servicesRendered && servicesRendered.length > 0) || (defaultSelectedPatients.length > 0 && patientsEdited)
      );
    }
    return hasUnsavedChanges || !!state.editingServiceRenderedId;
  };

  const getSaveButtonText = () => {
    if (isOnline) {
      return translations.rapidBilling.sendButtonText;
    }

    return hasUnsavedChanges
      ? translations.rapidBilling.saveChangesButtonText
      : translations.rapidBilling.sendButtonOfflineText;
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving || state.savingState.isSaving}
      savingMessage={state.savingState.customSavingMessage}
      showNavigationWarning={showUnsavedChangesWarning()}
    >
      {!hideHeader && (
        <StyledPageHeader
          title={translations.rapidBilling.pageTitle}
          onBack={!state.contactId ? history.goBack : undefined}
          extra={
            !isOnline && (
              <Button
                size={'small'}
                style={{ margin: 10, width: patientTagWidth }}
                type={'primary'}
                onClick={() => onToggleShowQueuedBatches?.()}
                disabled={disableShowQueuedBachesButton}
              >
                {showQueuedBatches
                  ? translations.rapidBilling.hideQueuedBatchesButton
                  : translations.rapidBilling.viewQueuedBatchesButton}
              </Button>
            )
          }
        />
      )}
      {!hideContent && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              marginLeft: 10,
              marginTop: 10,
              marginBottom: heightBelowServicesTable,
              borderRight: '1px solid #f0f0f0',
            }}
          >
            <div style={{ width: patientTagWidth, display: 'flex', justifyContent: 'center', margin: '5px 0' }}>
              {activeServiceId
                ? translations.rapidBilling.servicePatientsText
                : translations.rapidBilling.defaultPatientsText}
            </div>
            <Button
              size={'small'}
              style={{ marginLeft: 4, width: patientTagWidth }}
              type={'primary'}
              onClick={() => setSelectedPatientModalVisible(true)}
              disabled={isPatientSelectionDisabled}
            >
              {translations.rapidBilling.searchPatientsButton}
            </Button>
            {state.contactId ? (
              <ContactPatientMultiSelect
                contactId={state.contactId}
                selectedPatients={selectedPatients}
                setSelectedPatients={setSelectedPatients}
                disabled={isPatientSelectionDisabled}
                isRappidBilling
              />
            ) : (
              <AllPatientsMultiSelect
                selectedPatients={selectedPatients}
                setSelectedPatients={setSelectedPatients}
                disabled={isPatientSelectionDisabled}
                isRappidBilling
              />
            )}
          </div>
          {selectedPatientModalVisible && (
            <Suspense fallback={<Loading />}>
              <SelectPatientModal
                title={translations.shared.selectPatientModal.title}
                canSelectMultiple
                initialSelectedPatients={selectedPatients}
                finishSelectPatients={(patients: Patient[]) => {
                  setSelectedPatients(patients);
                  setSelectedPatientModalVisible(false);
                }}
                onCancel={() => setSelectedPatientModalVisible(false)}
                contactId={state.contactId}
              />
            </Suspense>
          )}
          <div style={{ overflow: 'hidden' }}>
            <RapidBillingServices
              practiceId={practiceId}
              servicesRendered={servicesRendered}
              setServicesRendered={handleSetServicesRendered}
              setPatientsOfService={setValidPatientsOfService}
              activeServiceId={activeServiceId}
              setActiveServiceId={setActiveServiceId}
              selectedPatients={selectedPatients}
              prescriptions={prescriptions}
            />
            <div id={'RapidBilling__BelowTable'}>
              <RapidBillingFooter
                deleteRapidBilling={handleBatchDelete}
                rapidBillingAction={handleRapidBillingAction}
                saveDisabled={
                  (showQueuedBatches && !hasUnsavedChanges) || !servicesRendered || servicesRendered.length === 0
                }
                disabled={!!state.editingServiceRenderedId}
                saveButtonLabel={getSaveButtonText()}
              />
              <RapidBillingOptions
                batchGroupOptions={batchGroupOptions}
                setBatchGroupOptions={setBatchGroupOptions}
                hideCompleteInvoices={!isOnline}
              />
            </div>
          </div>
        </div>
      )}
      {patientModalProps?.visible && <PatientOptionsModal {...patientModalProps} progress={percentage} />}
      {errorModalProps?.visible && <ErrorsModal {...errorModalProps} />}
      {jobId && (
        <JobStatus
          jobId={jobId}
          onError={showErrorMessage}
          closeModal={closeJobStatusModal}
          onCompletion={checkForDeviceMapping}
        />
      )}
      {labRequests?.length && <LabRequestModal labRequests={labRequests} onClose={() => setLabRequests(undefined)} />}
      {labDeviceTaxonomyMapping && (
        <TaxonomyMappingModal
          labTaxonomy={labDeviceTaxonomyMapping}
          thirdPartyTaxonomy={[]}
          handleCloseModal={async () => {
            setLabDeviceTaxonomyMapping(undefined);
            if (batchGroup) {
              setIsSaving(true);
              await resubmitLab({
                variables: {
                  organizationId,
                  groupBatchResubmit: { batchGroupId: batchGroup.id },
                },
              });
              setIsSaving(false);
              checkForLabs(batchGroup);
            }
          }}
        />
      )}
    </SaveSpinnerAndNavigationWarning>
  );
};
