import { translations } from '../../../constants/translations';
import { tabKeys } from '../refDataUtils';

export const sortOrderHeader = 'sort_order';
const fileTypeColumns = [{ header: 'patient' }, { header: 'contact' }];

const getTypeDependentRefDataColumns = (type: string) => {
  switch (type) {
    case tabKeys.breeds:
    case tabKeys.genders:
      return [{ header: 'species_id', mandatory: true }];

    case tabKeys.taxTypes:
      return [
        { header: 'code', mandatory: true },
        {
          header: 'percentage',
          mandatory: true,
        },
        { header: 'compounded' },
        { header: 'default' },
        { header: 'general_ledger_id' },
        { header: 'external_tax_id' },
      ];
    case tabKeys.ledgerTypes:
      return [{ header: 'general_ledger_id' }];
    case tabKeys.generalLedger:
      return [
        { header: 'gl_number', mandatory: true },
        { header: 'external_gl_id' },
        { header: 'is_receivables_account' },
      ];

    case tabKeys.fileTypes:
      return fileTypeColumns;

    case tabKeys.noteTypes:
      return [
        ...fileTypeColumns,
        { header: 'patient_print' },
        { header: 'contact_print' },
        { header: 'invoice_print' },
      ];

    case tabKeys.quickNotes:
      return [{ header: 'note' }];

    case tabKeys.userTypes:
      return [{ header: 'doctor' }, { header: 'caregiver' }];

    case tabKeys.contactStatus:
      return [{ header: 'active' }, { header: 'warning' }, { header: 'stop' }];

    case tabKeys.reminderStatus:
      return [{ header: 'system_status_id', mandatory: true }, { header: 'is_default' }];

    case tabKeys.contactTypes:
      return [{ header: 'enabled' }];

    case tabKeys.paymentTypes:
      return [{ header: 'enabled' }, { header: 'general_ledger_id' }];

    case tabKeys.patientAlerts:
      return [{ header: 'prompt_invoice' }, { header: 'prompt_patient' }];

    case tabKeys.contactAlerts:
      return [{ header: 'prompt_contact' }, { header: 'prompt_invoice' }, { header: 'prompt_patient' }];

    case tabKeys.species:
    case tabKeys.colors:
    default:
      return [];
  }
};

export const getRefDataColumnHeadersByType = (
  type: string
): { header: string; mandatory?: boolean; hidden?: boolean }[] => {
  const withNoSortOrder =
    type === tabKeys.contactTypes || type === tabKeys.paymentTypes || type === tabKeys.ledgerTypes;
  const typeDependentColumns = getTypeDependentRefDataColumns(type);

  return [
    { header: 'name', mandatory: true },
    ...typeDependentColumns,
    ...(!withNoSortOrder ? [{ header: sortOrderHeader, mandatory: false }] : []),
  ];
};

export const getPlaceholderForSelect = (columnName: string) => {
  return (
    translations.referenceData.placeholders[columnName as keyof typeof translations.referenceData.placeholders] ?? ''
  );
};
