import styled from 'styled-components';
import { Button, Layout, Menu } from 'antd';
import * as React from 'react';
import { ButtonProps } from 'antd/lib/button/button';

const { Header } = Layout;

export const StyledHeader = styled(Header)({
  padding: '0 !important',
  position: 'sticky',
  top: '0',
  width: '100%',
});

export const StyledHomeButton = styled(
  Button as React.ForwardRefExoticComponent<ButtonProps & React.RefAttributes<HTMLElement>>
)({
  margin: '0 23px',
  height: 40,
  padding: 0,
  'border-width': 0,
});

export const StyledCreateButton = styled(
  Button as React.ForwardRefExoticComponent<ButtonProps & React.RefAttributes<HTMLElement>>
)({
  'border-width': 0,
});

export const StyledFlexParent = styled.div({
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'space-between',
});

export const HeaderMenu = styled(Menu);
