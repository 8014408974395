import { RxJsonSchema } from 'rxdb';
import { InvoiceContext } from '../../../graph/types';
import { RxScalars, RxSchemaWithUpdated } from '../databaseUtils';

export type RxInvoiceContext = InvoiceContext & RxSchemaWithUpdated;

export const invoiceContextSchema: RxJsonSchema<RxInvoiceContext> = {
  version: 1,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    practice_id: RxScalars.ID,
    status: RxScalars.Array,
    business_center: RxScalars.Array,
    location: RxScalars.Array,
    service: RxScalars.Array,
    caregiver: RxScalars.Array,
    updated: RxScalars.String,
  },
  required: ['id'],
  indexes: ['updated'],
};
