import { Button, Form, Select } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { sendTypes } from '../../constants/referenceData/statementRunReferenceData';
import { translations } from '../../constants/translations';
import { Job, JobUpsert, QueryRptGetStatementsArgs, StatementContactInterface, StatementRun } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { setStatementActionLoading, setSelectedStatements } from './store/actions';
import { StatementsContext } from './store/state';
import { useGetSmtpSettings } from '../../hooks/ajax/email/emailHooks';
import { hasValidEmailSettings } from '../../util/email';
import { getJobRecordUpsert } from '../../util/jobUtil';
import { UpsertStatementRun } from '../../graph/queries/statementRuns';
import { useUpsertJob } from '../../hooks/ajax/job/jobHooks';
import { JobStatus } from '../../components/JobStatus/JobStatus';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useLDFlag } from '../../hooks/useLDHooks';
import { getReportData, Reports } from '../../util/reportUtils';
import { GetStatementReport } from '../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';
import { ReportData } from '../../components/ActiveReportViewer/activeReportViewerUtils';
import { getPracticeFooter } from '../../pages/Contacts/ViewContact/statementUtils';

export const optionsFieldName = 'statement-run-method';

export const options = {
  print: { label: translations.statementRun.print, value: '1' },
  printOrEmail: { label: translations.statementRun.printOrEmail, value: '2' },
  printAndEmail: { label: translations.statementRun.printAndEmail, value: '3' },
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getStatementRunUpsert = (
  statements: StatementContactInterface[],
  option: string,
  selectedStatementRunId?: string
) => {
  const fnPrint: string[] = [];
  const fnSendEmails: string[] = [];
  const contactIds: string[] = [];

  statements.forEach((statement) => {
    if (option === options.print.value) {
      fnPrint.push(statement.contact_id);
      contactIds.push(statement.contact_id);
    } else if (option === options.printAndEmail.value) {
      fnPrint.push(statement.contact_id);
      if (statement.email && statement.send_type === sendTypes.email.value) {
        fnSendEmails.push(statement.contact_id);
      }
      contactIds.push(statement.contact_id);
    } else {
      if (statement.send_type === sendTypes.print.value) {
        fnPrint.push(statement.contact_id);
        contactIds.push(statement.contact_id);
      } else {
        if (statement.send_type === sendTypes.email.value) {
          fnSendEmails.push(statement.contact_id);
        } else {
          fnPrint.push(statement.contact_id);
          contactIds.push(statement.contact_id);
        }
      }
    }
  });

  return {
    statementRun: {
      id: selectedStatementRunId,
      fnPrint,
      fnSendEmails,
    },
    contactIds,
  };
};

interface StatementRunActionsProps {
  practiceId: string;
  selectedStatementRun: StatementRun;
  isProcessedTab: boolean;
  refreshFilters: () => void;
  currentData: StatementContactInterface[];
}

export const StatementRunsActions: React.FC<StatementRunActionsProps> = ({
  practiceId,
  selectedStatementRun,
  isProcessedTab,
  refreshFilters,
  currentData,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const usesActiveReports = useLDFlag(LDFlagNames.ActiveReports);
  const navigateToReportViewer = useNavigateToReportViewer();

  const { state, dispatch } = useContext(StatementsContext);
  const [selectedOption, setSelectedOption] = useState<string>(options.print.value);
  const smtpSettings = useGetSmtpSettings();

  const [upsertJob] = useUpsertJob();
  const [jobId, setJobId] = useState<string>();

  const [showReportViewer, setShowReportViewer] = useState<boolean>(false);
  const [reportData, setReportData] = useState<ReportData | undefined>();

  const selectedContacts = useMemo(() => state.selectedStatements.length, [state.selectedStatements]);

  const handleRun = async () => {
    dispatch(setStatementActionLoading(true));
    const selectedRowKeys = state.selectedStatements.map(({ id }) => id);
    const updatedSelections: StatementContactInterface[] = [];
    currentData.forEach((selection) => {
      if (selectedRowKeys.includes(selection.id)) {
        updatedSelections.push(selection);
      }
    });

    const { statementRun } = getStatementRunUpsert(updatedSelections, selectedOption, selectedStatementRun.id);

    if (statementRun.fnPrint.length === 0 && statementRun.fnSendEmails.length === 0) {
      return;
    }

    const jobRecord = getJobRecordUpsert(UpsertStatementRun, {
      organizationId,
      statementRun,
    });

    const job: JobUpsert = {
      record: jobRecord,
    };

    const jobVariables = {
      organizationId,
      job,
    };

    const viewActiveReport = usesActiveReports && statementRun.fnPrint.length !== 0;
    setShowReportViewer(viewActiveReport);

    try {
      if (viewActiveReport) {
        const footer = await getPracticeFooter(organizationId, practiceId);

        setReportData(
          await getReportData<'rptGetStatements', QueryRptGetStatementsArgs>(GetStatementReport, {
            organizationId,
            practiceId,
            reportParameters: {
              start_date: selectedStatementRun.start_date ?? '',
              end_date: selectedStatementRun.end_date,
              contact_id: statementRun.fnPrint,
              footer,
            },
          })
        );
      }

      const { data } = await upsertJob({
        variables: jobVariables,
      });

      setJobId(data?.upsertJob?.id);
    } catch (e) {
      showErrorMessage(e.message || translations.jobStatusModal.error());
    }

    dispatch(setStatementActionLoading(false));
  };

  const closeJobStatusModal = () => {
    setJobId(undefined);
    dispatch(setStatementActionLoading(true));
    dispatch(setSelectedStatements([]));

    if (state.refetchStatement) {
      state.refetchStatement();
    }

    dispatch(setStatementActionLoading(false));
    showSuccessMessage(translations.statementRun.runSuccessMessage);
    refreshFilters();
  };

  const handleJobfinish = async ({ result }: Job) => {
    if (showReportViewer) {
      navigateToReportViewer({
        reportName: Reports.Statement,
        data: reportData,
        nestedHeader: true,
      });
    } else {
      if (result) {
        const { reportPointer } = await JSON.parse(result);
        if (reportPointer) {
          openTabWithPopupDetection(reportPointer, translations.popupDetectionMessage.printReport);
        }
      }
    }
  };

  return (
    <>
      <Container>
        <Form layout='inline' initialValues={{ [optionsFieldName]: options.print.value }} autoComplete='off'>
          <Form.Item style={{ minWidth: '130px' }} name={optionsFieldName}>
            <Select onChange={(value) => setSelectedOption(value as string)}>
              {Object.values(options).map((option) => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  disabled={
                    (option.value === options.printOrEmail.value || option.value === options.printAndEmail.value) &&
                    !hasValidEmailSettings(smtpSettings)
                  }
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ padding: 0 }}>
            <Button
              htmlType='submit'
              type='primary'
              onClick={handleRun}
              disabled={state.selectedStatements.length === 0}
            >
              {isProcessedTab ? translations.statementRun.resend : translations.statementRun.run}
            </Button>
          </Form.Item>
        </Form>
      </Container>
      {jobId && (
        <JobStatus
          jobId={jobId}
          jobCount={selectedContacts}
          onError={(error) => showErrorMessage(error || translations.jobStatusModal.error())}
          closeModal={closeJobStatusModal}
          onCompletion={handleJobfinish}
          noProgress
        />
      )}
    </>
  );
};
