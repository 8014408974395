import React, { PropsWithChildren, useState } from 'react';
import { Form, Input, Row, Select } from 'antd';
import { translations } from '../../constants/translations';
import { OrganizationReferenceData, PatientReferenceData, PatientReferenceDataDto } from '../../graph/types';
import { FormInstance } from 'antd/lib/form';
import { getRequiredRule } from '../../util/forms';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { TaxonomyType } from '../../util/taxonomyUtil';
import { ClickableParagraph, RightMarginPlusOutlined } from '../../pages/Services/ServiceForm/ServiceForm.style';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import {
  buildReferenceDataUpsert,
  getPluralTaxonomyType,
} from '../../pages/ReferenceData/RefDataTable/refDataTableUpsertUtils';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useUpdateOrganizationReferenceData } from '../../hooks/ajax/organization/organizationHooks';
import { useOffline } from '../../util/offline/offlineUtil';

interface PatientRefSelectionProps extends PropsWithChildren<unknown> {
  patientRef: PatientReferenceDataDto;
  form: FormInstance;
}

interface PatientRefData {
  id: string;
  name: string;
}

type PatientRefDataWithSpeciesId = PatientRefData & { species_id: string };

const addNewRefValue = 'add-new-ref-value';

export const PatientRefSelection: React.FC<PatientRefSelectionProps> = ({ patientRef, form }) => {
  const speciesName = 'species_id';
  const breedName = 'breed_id';
  const genderName = 'gender_id';
  const colorName = 'color_id';
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertOrganization] = useMutationWithMessages(useUpdateOrganizationReferenceData);

  const speciesOptions = getOptionsFromData(patientRef.species);
  const breedOptions = getOptionsWithParentId(patientRef.breed);
  const genderOptions = getOptionsWithParentId(patientRef.gender);
  const colorOptions = getOptionsFromData(patientRef.color);
  const speciesValue = form.getFieldValue(speciesName);

  const [, setDummyStateToForceUpdate] = useState({});
  const [newRef, setNewRef] = useState<string>();
  const [newRefType, setNewRefType] = useState<TaxonomyType>();
  const [isSaving, setIsSaving] = useState(false);
  const { enabledAndOffline } = useOffline();

  const speciesMatch = patientRef.species.some((r) => r.name.toLowerCase() === newRef?.toLowerCase());
  const breedMatch = patientRef.breed.some(
    (r) => r.name.toLowerCase() === newRef?.toLowerCase() && r.species_id === speciesValue
  );
  const genderMatch = patientRef.gender.some(
    (r) => r.name.toLowerCase() === newRef?.toLowerCase() && r.species_id === speciesValue
  );
  const colorMatch = patientRef.color.some((r) => r.name.toLowerCase() === newRef?.toLowerCase());

  function onSpeciesChange(value: any) {
    if (value === addNewRefValue) {
      setNewRefType(TaxonomyType.Species);
      form.setFields([{ name: TaxonomyType.Species, value: newRef }]);
    }

    form.setFields([
      { name: breedName, value: undefined },
      { name: genderName, value: undefined },
    ]);
    // force update so that the dependent dropdowns are re-rendered with correct values
    setDummyStateToForceUpdate({});
  }

  function onBreedChange(value: any) {
    if (value === addNewRefValue) {
      setNewRefType(TaxonomyType.Breed);
      form.setFields([{ name: TaxonomyType.Breed, value: newRef }]);
    }
  }

  function onGenderChange(value: any) {
    if (value === addNewRefValue) {
      setNewRefType(TaxonomyType.Gender);
      form.setFields([{ name: TaxonomyType.Gender, value: newRef }]);
    }
  }

  function onColorChange(value: any) {
    if (value === addNewRefValue) {
      setNewRefType(TaxonomyType.Color);
      form.setFields([{ name: TaxonomyType.Color, value: newRef }]);
    }
  }

  function getOptionsFromData(data: PatientRefData[]) {
    return data.map((item: PatientRefData) => (
      <Select.Option key={item.id} value={item.id} label={item.name}>
        {item.name}
      </Select.Option>
    ));
  }

  function getOptionsWithParentId(options: PatientRefDataWithSpeciesId[]) {
    const speciesId = form.getFieldValue(speciesName);
    return options
      .filter((option: PatientRefDataWithSpeciesId) => option.species_id === speciesId)
      .map((option: PatientRefDataWithSpeciesId) => (
        <Select.Option key={option.id} value={option.id} label={option.name} parentId={option.species_id}>
          {option.name}
        </Select.Option>
      ));
  }

  function getModalContent() {
    switch (newRefType) {
      case TaxonomyType.Species:
        return (
          <Form.Item
            name='species'
            label={translations.patientPage.details.species}
            rules={[getRequiredRule(translations.patientPage.details.species)]}
          >
            <Input defaultValue={newRef} />
          </Form.Item>
        );
      case TaxonomyType.Breed:
        return (
          <>
            {getSpeciesSelect(true)}
            <Form.Item
              name='breed'
              label={translations.patientPage.details.breed}
              rules={[getRequiredRule(translations.patientPage.details.breed)]}
            >
              <Input defaultValue={newRef} />
            </Form.Item>
          </>
        );
      case TaxonomyType.Gender:
        return (
          <>
            {getSpeciesSelect(true)}
            <Form.Item
              name='gender'
              label={translations.patientPage.details.gender}
              rules={[getRequiredRule(translations.patientPage.details.gender)]}
            >
              <Input defaultValue={newRef} />
            </Form.Item>
          </>
        );
      case TaxonomyType.Color:
        return (
          <Form.Item
            name='color'
            label={translations.patientPage.details.color}
            rules={[getRequiredRule(translations.patientPage.details.color)]}
          >
            <Input defaultValue={newRef} />
          </Form.Item>
        );
      default:
        return null;
    }
  }

  async function handleTaxonomyUpdate() {
    if (!newRefType) {
      return;
    }

    await form.validateFields();

    const name = form.getFieldValue([newRefType]);

    setIsSaving(true);

    const refData = {
      id: '0',
      name,
      species_id: newRefType !== TaxonomyType.Species && speciesValue,
      sort_order: 0,
    };

    const upsert = buildReferenceDataUpsert(organizationId, refData, getPluralTaxonomyType(newRefType));

    await upsertOrganization({
      options: {
        variables: { organizationId, organization: upsert },
      },
      onSuccess: (data) => {
        const found = (
          data?.upsertOrganization.ref_patient[
            newRefType as keyof Omit<PatientReferenceData, 'id' | '__typename'>
          ] as OrganizationReferenceData[]
        )?.find((ref) => name === ref.name);
        form.setFields([{ name: `${newRefType}_id`, value: found?.id }]);
      },
    });

    setNewRefType(undefined);
    setNewRef(undefined);
    setIsSaving(false);
  }

  const addNewOption = (type: TaxonomyType) => {
    return !enabledAndOffline ? (
      <Select.Option value={addNewRefValue}>
        <ClickableParagraph
          onClick={() => {
            setNewRefType(type);
            form.setFields([{ name: type, value: newRef }]);
          }}
        >
          <RightMarginPlusOutlined />
          {translations.addServicePage.addEntry}
        </ClickableParagraph>
      </Select.Option>
    ) : undefined;
  };

  const getSpeciesSelect = (isDisabled: boolean) => (
    <Form.Item
      shouldUpdate
      name={speciesName}
      label={translations.patientPage.details.species}
      style={{ width: '100%' }}
      rules={[getRequiredRule(translations.patientPage.details.species)]}
    >
      <Select
        onSearch={setNewRef}
        {...searchableSelectParams}
        onChange={onSpeciesChange}
        placeholder={translations.patientPage.details.speciesPlaceholder}
        filterOption={(input: string, option: any) => {
          return option.value === addNewRefValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        disabled={isDisabled}
      >
        {!speciesMatch && addNewOption(TaxonomyType.Species)}
        {speciesOptions}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Row>{getSpeciesSelect(false)}</Row>
      <Row>
        <Form.Item
          shouldUpdate
          name={breedName}
          style={{ width: '100%' }}
          label={translations.patientPage.details.breed}
        >
          <Select
            {...searchableSelectParams}
            onSearch={setNewRef}
            onChange={onBreedChange}
            allowClear
            placeholder={
              speciesValue
                ? translations.patientPage.details.breed
                : translations.patientPage.details.speciesFirstPlaceholder
            }
            disabled={!speciesValue}
            filterOption={(input: string, option: any) => {
              return option.value === addNewRefValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {!breedMatch && addNewOption(TaxonomyType.Breed)}
            {breedOptions}
          </Select>
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          shouldUpdate
          name={genderName}
          style={{ width: '100%' }}
          label={translations.patientPage.details.gender}
        >
          <Select
            {...searchableSelectParams}
            onSearch={setNewRef}
            onChange={onGenderChange}
            allowClear
            placeholder={
              speciesValue
                ? translations.patientPage.details.gender
                : translations.patientPage.details.speciesFirstPlaceholder
            }
            disabled={!speciesValue}
            filterOption={(input: string, option: any) => {
              return option.value === addNewRefValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {!genderMatch && addNewOption(TaxonomyType.Gender)}
            {genderOptions}
          </Select>
        </Form.Item>
      </Row>
      <Row>
        <Form.Item name={colorName} label={translations.patientPage.details.color} style={{ width: '100%' }}>
          <Select
            onSearch={setNewRef}
            onChange={onColorChange}
            {...searchableSelectParams}
            placeholder={translations.patientPage.details.colorPlaceholder}
            filterOption={(input: string, option: any) => {
              return option.value === addNewRefValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {!colorMatch && addNewOption(TaxonomyType.Color)}
            {colorOptions}
          </Select>
        </Form.Item>
      </Row>
      {newRefType && !enabledAndOffline && (
        <ModalWithCloseConfirm
          title={translations.patientPage.newRefModal.title(newRefType)}
          open
          onCancel={() => {
            setNewRefType(undefined);
            setNewRef(undefined);
            form.setFields([{ name: `${newRefType}_id`, value: undefined }]);
          }}
          isSaving={isSaving}
          onOk={handleTaxonomyUpdate}
        >
          {getModalContent()}
        </ModalWithCloseConfirm>
      )}
    </>
  );
};
