import * as React from 'react';
import { Patient } from '../../../graph/types';
import { PatientsAction } from './actions';
import { initialState, patientsReducer, PatientsState } from './reducer';

export const PatientsContext = React.createContext<{
  state: PatientsState;
  dispatch: React.Dispatch<PatientsAction>;
}>({} as any);

type PatientsContextProviderProps = {
  patientsList?: Patient[];
  editingIndex?: number;
};

export const PatientsContextProvider: React.FC<React.PropsWithChildren<PatientsContextProviderProps>> = (props) => {
  const fullInitialState = {
    ...initialState,
    patientsList: props.patientsList ?? [],
  };
  const [state, dispatch] = React.useReducer(patientsReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <PatientsContext.Provider value={value}>{props.children}</PatientsContext.Provider>;
};
