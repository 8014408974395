import { DocumentNode, QueryHookOptions } from '@apollo/client';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { DEBOUNCE_TIME_FOR_SEARCH } from '../../constants/queryConstants';
import { ElasticQueryString, ElasticSourceOptions, Maybe, Query } from '../../graph/types';
import { CollectionType } from '../../services/LocalDatabaseService/collections';
import { isUuid, useOffline } from '../../util/offline/offlineUtil';
import { useQueryWithBackendSearch } from './useQueryWithBackendSearch';
import { RxDocumentBase } from 'rxdb';
import { Subscription } from 'rxjs';
import { adjustElasticSearchString } from '../../util/searchUtil';
import { useOfflineErrorSkipQuery } from './useOfflineErrorSkip';

const updatedDateSort = (a: any, b: any) => Date.parse(b.updated) - Date.parse(a.updated);

export const useOfflineQuery = <T extends keyof Query>({
  query,
  collection,
  options,
  selector,
  expectList = false,
  sortBy: sortProperty = 'updated',
}: {
  query: DocumentNode;
  collection: CollectionType;
  options?: QueryHookOptions<Pick<Query, T>>;
  selector?: Record<string, any>;
  expectList?: boolean;
  sortBy?: string;
}) => {
  const querySelector = useMemo(() => selector ?? {}, [selector]);
  const { canUseCollection } = useOffline(collection);
  const rxCollection = useRxCollection(collection);
  const { data, loading, refetch, error, stopPolling } = useOfflineErrorSkipQuery<T>(query, {
    ...options,
    skip: canUseCollection || options?.skip,
  });

  const [result, setResult] = useState<{
    data: Query[T] | undefined;
    loading: boolean;
    renderKey: number;
  }>({
    data: undefined,
    loading: true,
    renderKey: 0,
  });

  useEffect(() => {
    if (canUseCollection) {
      return;
    }

    let dataResult = data as any;
    if (!isEmpty(dataResult)) {
      dataResult = getFinalData(dataResult[Object.keys(dataResult)[0]]);
    }

    setResult((prev) => ({
      data: dataResult,
      loading,
      renderKey: prev.renderKey + 1,
    }));
    // eslint-disable-next-line
  }, [data, loading, canUseCollection]);

  useEffect(() => {
    let subscription: Subscription | undefined;
    if (canUseCollection) {
      subscription = rxCollection
        ?.find({
          selector: { ...querySelector, hidden: { $ne: true } },
        })
        .$.subscribe((data: RxDocumentBase<unknown, Record<string, unknown>>[] | undefined) => {
          const sortedData: any = orderBy(data, sortProperty, 'desc');
          const newData =
            isEmpty(querySelector) || expectList
              ? getFinalData(
                  sortedData?.map((d: any) => {
                    return d.toJSON();
                  })
                )
              : (getFinalData(sortedData?.[0]?.toJSON()) as Query[T]);

          setResult((prev) => {
            if (isEqual(prev.data, newData)) {
              if (!prev.data && !newData) {
                return { ...prev, loading: false, renderKey: prev.renderKey + 1 };
              }
              return prev;
            }
            return {
              data: newData,
              loading: false,
              renderKey: prev.renderKey + 1,
            };
          });
        });
    }
    return () => subscription?.unsubscribe();
    // eslint-disable-next-line
  }, [collection, setResult, canUseCollection, rxCollection, querySelector, expectList, sortProperty]);

  const handleRefetch = useCallback(
    async (variables?: Record<string, any>) => {
      if (canUseCollection) {
        return;
      }

      try {
        await refetch?.(variables);
      } catch (_) {}
    },
    [refetch, canUseCollection]
  );

  const queryString = options?.variables?.queryString;

  const getFinalData = useCallback(
    (data: Maybe<Query[T]>) => {
      if (!data) {
        return undefined;
      }

      if (Array.isArray(data) && !queryString && sortProperty === 'updated') {
        const recordsCopy = Object.assign([], data as any);
        return recordsCopy.sort(updatedDateSort) as Query[T];
      }

      return data as any as Query[T];
    },
    [queryString, sortProperty]
  );

  const finalData = useMemo(
    () => getFinalData((data as any)?.[Object.keys(data as any)[0]]) || result.data,
    [data, result.data, getFinalData]
  );

  return {
    data: finalData,
    loading: loading || result?.loading,
    refetch: handleRefetch,
    renderKey: result.renderKey,
    error,
    stopPolling,
  };
};

const performSearch = (data: Record<string, any>[], searchTerm: string, keys?: string[]) => {
  if (!searchTerm || !keys) {
    return data;
  }
  const parsedSearchTerm = searchTerm.trim().toLowerCase();

  const valueContainsSearchTerm = (value: any): boolean => {
    if (typeof value === 'string' && value.toLowerCase().includes(parsedSearchTerm)) {
      return true;
    } else if (Array.isArray(value)) {
      for (const valueItem of value as any[]) {
        if (valueContainsSearchTerm(valueItem)) {
          return true;
        }
      }
    }
    return false;
  };

  return data.filter((value) => {
    for (const key of keys) {
      if (valueContainsSearchTerm(value[key])) {
        return true;
      }
    }
    return false;
  });
};

export const useOfflineQueryWithBackendSearch = <T extends keyof Query>({
  query,
  collection,
  variables,
  searchOptions,
  queryOptions,
  selector,
  shouldNotAdjustString,
  sortBy: sortProperty = 'updated',
}: {
  query: DocumentNode;
  collection: CollectionType;
  variables: Record<string, any>;
  searchOptions?: { value?: string; keys: string[] };
  queryOptions?: QueryHookOptions<Pick<Query, T>>;
  selector?: Record<string, any>;
  shouldNotAdjustString?: boolean;
  sortBy?: string;
}) => {
  const querySelector = useMemo(() => selector ?? {}, [selector]);
  const { canUseCollection } = useOffline(collection);
  const rxCollection = useRxCollection(collection);
  const options = useMemo(
    () => ({ ...queryOptions, skip: canUseCollection || queryOptions?.skip }),
    [canUseCollection, queryOptions]
  );
  const { data, loading, refetchSearch, refetchCurrentSearch, setSearchTerm } = useQueryWithBackendSearch<T>(
    query,
    variables,
    searchOptions?.value,
    options,
    shouldNotAdjustString
  );
  const [result, setResult] = useState<{
    data: Query[T] | undefined;
    loading: boolean;
  }>({
    data: undefined,
    loading: true,
  });
  const [searchText, setSearchText] = useState('');

  const finalData = useMemo(() => {
    const records = Object.assign([], result.data);
    if (!Array.isArray(records) || !canUseCollection) {
      return Array.isArray(records) ? records.sort(updatedDateSort) : records;
    }
    return performSearch(records as Record<string, any>[], searchText, searchOptions?.keys).sort(
      updatedDateSort
    ) as Query[T];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, searchText, canUseCollection]);

  useEffect(() => {
    if (canUseCollection) {
      return;
    }
    let result = data as any;
    if (!isEmpty(result)) {
      result = result[Object.keys(result)[0]];
    }
    setResult({
      data: result,
      loading,
    });
  }, [data, loading, canUseCollection]);

  useEffect(() => {
    let subscription: Subscription | undefined;
    if (canUseCollection) {
      setResult({ data: result.data, loading: true });
      subscription = rxCollection
        ?.find({ selector: { ...querySelector, hidden: { $ne: true } } })
        .$.subscribe((data: RxDocumentBase<unknown, Record<string, unknown>>[] | undefined) => {
          const sortedData = orderBy(data, sortProperty, 'desc');
          setResult((prev) => ({
            ...prev,
            data: sortedData?.map((d) => d.toJSON()) as Query[T],
            loading: false,
          }));
        });
    }
    return () => subscription?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, canUseCollection, setResult, rxCollection, querySelector, sortProperty]);

  return {
    data: finalData,
    loading: result.loading,
    refetchSearch,
    refetchCurrentSearch,
    setSearchTerm: canUseCollection ? setSearchText : setSearchTerm,
  };
};

const useQueryWithDebounceSearch = <T extends keyof Query>({
  query,
  collection,
  variables,
  options = {},
  selector,
}: {
  query: DocumentNode;
  collection: CollectionType;
  variables: Record<string, any>;
  options?: QueryHookOptions<Pick<Query, T>>;
  selector?: Record<string, any>;
}) => {
  const [queryVariables, setQueryVariables] = useState<Record<string, any>>();
  const { data, loading, refetch } = useOfflineQuery({
    query,
    collection,
    options: {
      ...options,
      variables: queryVariables,
      skip: options.skip || !queryVariables,
      notifyOnNetworkStatusChange: true,
    },
    selector,
    expectList: true,
  });

  const [displayedLoading, setDisplayedLoading] = useState<boolean>(loading);

  useEffect(() => {
    if (!variables) {
      return undefined;
    }

    if (!queryVariables) {
      setQueryVariables(variables);
      return undefined;
    }

    setDisplayedLoading(true);
    const timeout = setTimeout(async () => {
      await refetch(variables);
      setDisplayedLoading(false);
    }, DEBOUNCE_TIME_FOR_SEARCH);
    return () => {
      setDisplayedLoading(false);
      clearTimeout(timeout);
    };
  }, [variables, queryVariables, refetch]);

  useEffect(() => {
    setDisplayedLoading(loading);
  }, [loading]);

  const refetchQuery = useCallback(async () => {
    setDisplayedLoading(true);
    await refetch(variables);
  }, [variables, refetch]);

  return {
    data,
    loading: displayedLoading,
    refetch: refetchQuery,
  };
};

export const useElasticQueryVariables = (
  searchString: string,
  queryOptions: ElasticQueryString,
  adjustQuery = true,
  source?: ElasticSourceOptions,
  take = 1000
) =>
  useMemo(
    () => ({
      take,
      sort: { field: 'updated', direction: 'desc' },
      queryString: {
        ...queryOptions,
        query: adjustQuery ? adjustElasticSearchString(searchString || queryOptions.query) : queryOptions.query,
      },
      source,
    }),
    [searchString, queryOptions, adjustQuery, source, take]
  );

export const useQueryWithElasticSearch = <T extends keyof Query>({
  query,
  collection,
  variables,
  searchParams,
  options,
  selector,
  adjustQuery = true,
  source,
  take,
}: {
  query: DocumentNode;
  collection: CollectionType;
  variables: Record<string, any>;
  searchParams: ElasticQueryString;
  options?: QueryHookOptions<Pick<Query, T>>;
  showHidden?: boolean;
  selector?: Record<string, any>;
  adjustQuery?: boolean;
  source?: ElasticSourceOptions;
  take?: number;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(searchParams.query);
  const elasticVariables = useElasticQueryVariables(searchTerm, searchParams, adjustQuery, source, take);
  const queryVariables = useMemo(
    () => ({
      ...elasticVariables,
      ...variables,
    }),
    [variables, elasticVariables]
  );

  const { data, loading, refetch } = useQueryWithDebounceSearch({
    query,
    collection,
    variables: queryVariables,
    options,
    selector,
  });

  return {
    data,
    loading,
    setSearchTerm,
    refetch,
  };
};

export const useGetOfflineSelector = (id: string) => {
  return useMemo(() => (isUuid(id) ? { offline_id: id } : { _id: id }), [id]);
};
