import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Organization } from '../../../graph/types';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import {
  OrganizationSubscriptionLevelNameKeys,
  organizationSubscriptionLevelConfigs,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { AddOnCardHisa } from './AddOnCardHisa';
import { setOrganization } from '../../../contexts/organization/action';
import { useLazyQuery } from '@apollo/client';
import { OrganizationContext } from '../../../contexts/organization/state';
import { GetOrganizationDtoEs } from '../../../graph/queries/organizationDTOs';

export interface AddOnCardHisaContainerProps {
  organization?: Organization;
  organizationId: string;
  refetchOrganization: () => void;
}

export const AddOnCardHisaContainer: React.FC<AddOnCardHisaContainerProps> = ({
  organization,
  organizationId,
  refetchOrganization,
}: AddOnCardHisaContainerProps) => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
  const hisaAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.HisaLevel].level_id
      ),
    [organization]
  );
  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const handleOpenSubscriptionModal = () => {
    setShowSubscriptionModal(true);
  };

  const [fetchOrganization] = useLazyQuery(GetOrganizationDtoEs, {
    variables: { organizationId },
    fetchPolicy: 'network-only',
  });
  const { dispatch } = useContext(OrganizationContext);

  const updateOrganizationContext = useCallback(async () => {
    const { data } = await fetchOrganization();
    dispatch(setOrganization(data.getOrganizationDTOEs));
  }, [dispatch, fetchOrganization]);

  const handleCloseSubscriptionModal = async () => {
    setShowSubscriptionModal(false);
    await updateOrganizationContext();
    refetchOrganization();
  };

  return (
    <>
      <AddOnCardHisa
        isAddOnEnabled={hisaAddon?.enabled}
        price={hisaAddon?.cost}
        onEnable={handleOpenSubscriptionModal}
        onConfigure={handleOpenSubscriptionModal}
        countryId={countryId}
      />
      {showSubscriptionModal && (
        <SubscriptionUpdateModal
          onClose={handleCloseSubscriptionModal}
          onSuccess={handleCloseSubscriptionModal}
          canReduceUserCount
          showHisa
        />
      )}
    </>
  );
};
