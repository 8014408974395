import gql from 'graphql-tag';

export const ServicesRendered3pApprovalsFields = gql`
  fragment ServicesRendered3pApprovalsFields on ServiceRendered3pApproval {
    contact_id
    contact_name
    contact_number
    date
    discount
    doctor_id
    doctor_name
    id
    invoice_id
    invoice_number
    name
    organization_id
    owner_ids
    owner_names
    owner_percentages
    patient_id
    patient_name
    patient_number
    quantity
    related_ids
    related_names
    related_types
    service_id
    service_name
    total
    unit_name
    missing_roa
    missing_reason
    missing_microchip
    missing_gender
    missing_gender_map
    missing_dob
    missing_contact_email
    validation_failed
    info
  }
`;

export const ServicesRendered3pHistoryFields = gql`
  fragment ServicesRendered3pHistoryFields on ServiceRendered3pHistory {
    contact_id
    contact_name
    contact_number
    date
    discount
    doctor_id
    doctor_name
    failed_reason
    id
    info
    invoice_id
    invoice_number
    name
    organization_id
    patient_id
    patient_name
    patient_number
    quantity
    sent
    service_id
    service_name
    service_rendered_id
    status_id
    third_party_id
    total
    unit_name
    updated
  }
`;

export const ThirdPartyTypeFields = gql`
  fragment ThirdPartyTypeFields on ThirdPartyType {
    id
    name_key
    desc_key
    service_queue_handler
    enabled
    sort_order
    requires_dob
    requires_gender
    requires_microchip
    requires_email
  }
`;

export const ThirdPartyTaxonomyMappingFields = gql`
  fragment ThirdPartyTaxonomyMappingFields on ThirdPartyGender {
    third_party_gender_name
    third_party_gender_number
    third_party_species_number
  }
`;

export const ThirdPartyGenderMapFields = gql`
  fragment ThirdPartyGenderMapFields on ThirdPartyGenderMap {
    cassadol_gender_id
    id
    third_party_gender_name
    third_party_gender_number
    third_party_id
  }
`;
