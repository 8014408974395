import React, { useMemo } from 'react';
import { ServiceProperty, servicePropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Service } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { getStatus, getStatusTag, Status } from '../../../util/statusUtils';
import { getTaxTags } from '../../../util/taxTags';
import CurrencyFormatter from '../../../components/CurrencyFormatter/CurrencyFormatter';

export const useBasicServiceColumns = (): Record<ServiceProperty, CustomColumnType<Service>> => {
  return useMemo(
    () => ({
      name: {
        title: translations.servicesPage.columns.name,
        key: servicePropertyNames.name,
        dataIndex: servicePropertyNames.name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.servicesPage.columns.name),
        onFilter: getOnFilterFunctionFor(servicePropertyNames.name),
        sorter: getStringCompareFunctionFor(servicePropertyNames.name),
      },
      unit_name: {
        title: translations.servicesPage.columns.unit_name,
        key: servicePropertyNames.unit_name,
        dataIndex: servicePropertyNames.unit_name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.servicesPage.columns.unit_name
        ),
        onFilter: getOnFilterFunctionFor(servicePropertyNames.unit_name),
        sorter: getStringCompareFunctionFor(servicePropertyNames.unit_name),
      },
      price: {
        title: translations.servicesPage.columns.price,
        key: servicePropertyNames.price,
        dataIndex: servicePropertyNames.price,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.servicesPage.columns.price),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(servicePropertyNames.price, true),
        sorter: getNumberCompareFunctionFor(servicePropertyNames.price),
        render: (value: string) => {
          return <CurrencyFormatter total={Number(value)} />;
        },
      },
      general_ledger_name: {
        title: translations.servicesPage.columns.glAccount,
        key: servicePropertyNames.general_ledger_name,
        dataIndex: servicePropertyNames.general_ledger_name,
        ...generalFilteringAndSortingSettings,
        onFilter: (value, record) =>
          value === -1 ? record.general_ledger_id === null : record.general_ledger_id === value,
        sorter: getStringCompareFunctionFor(servicePropertyNames.general_ledger_name),
        render: (value: string, srv: Service) => {
          return value ? `${srv.general_ledger_number} - ${value}` : '';
        },
      },
      inactive: {
        title: translations.servicesPage.columns.inactive,
        key: servicePropertyNames.inactive,
        dataIndex: servicePropertyNames.inactive,
        ...generalFilteringAndSortingSettings,
        filters: [
          { text: translations.servicesPage.statuses.active, value: Status.Active },
          { text: translations.servicesPage.statuses.inactive, value: Status.Inactive },
        ],
        onFilter: (value, record) => {
          const inactiveValue = record.inactive ? Status.Inactive : Status.Active;
          return inactiveValue === value;
        },
        render: (...args) => getStatusTag(getStatus(args[1])),
      },
      // Fill in tax info
      tax_code_names: {
        title: translations.servicesPage.columns.tax,
        key: servicePropertyNames.tax_code_names,
        dataIndex: servicePropertyNames.tax_code_names,
        ...generalFilteringAndSortingSettings,
        render: getTaxTags,
        sorter: getStringCompareFunctionFor(servicePropertyNames.tax_code_names),
      },
    }),
    []
  );
};
