import gql from 'graphql-tag';
import { CountryFields, PhoneFields, PracticeFields, ProvStateFields } from '../fragments';

export const SignUpResultFields = gql`
  fragment SignUpResultFields on SignUpResult {
    organization {
      id
      ref_demographics {
        id
        country {
          ...CountryFields
        }
        prov_state {
          ...ProvStateFields
        }
      }
    }
    practice {
      ...PracticeFields
    }
    user {
      id
      phone {
        ...PhoneFields
      }
    }
  }
  ${CountryFields}
  ${ProvStateFields}
  ${PracticeFields}
  ${PhoneFields}
`;

export const SignUpOrganization = gql`
  mutation signUp($email: String!, $defaults: SignUpDefaults!, $name: String) {
    signUp(email: $email, defaults: $defaults, name: $name) {
      ...SignUpResultFields
    }
  }
  ${SignUpResultFields}
`;

export const PreloadData = gql`
  mutation preloadData($organizationId: ID!, $demographics: PreLoadDataContext!) {
    preLoadData(organizationId: $organizationId, demographics: $demographics) {
      organization {
        id
      }
    }
  }
`;
