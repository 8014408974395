import { Tooltip } from 'antd';
import React from 'react';
import {
  Container,
  IconContainer,
  ContainerDescription,
  Header,
  Description,
  IconSider,
  HeaderContainer,
  TagContainer,
} from './Search.styles';

export enum GlobalSearchOptionResultType {
  Invoice = 'Invoice',
  Contact = 'Contact',
  Patient = 'Patient',
}
export interface SelectOptionProps {
  id: string;
  rowKey: string;
  header: string;
  icon: any;
  route: string;
  parameter: Record<string, any>;
  type: GlobalSearchOptionResultType;
  description?: string | JSX.Element;
  tags?: JSX.Element[];
}

export const SearchOption = ({ header, description, tags, rowKey, icon, type }: SelectOptionProps) => {
  return (
    <Tooltip title={type} placement='right'>
      <Container key={rowKey}>
        <IconContainer>
          <IconSider component={icon} />
        </IconContainer>
        <ContainerDescription>
          <HeaderContainer>
            <Header>{header}</Header>
            {type === GlobalSearchOptionResultType.Contact && <div>{tags}</div>}
          </HeaderContainer>
          <Description>{description}</Description>
          {type !== GlobalSearchOptionResultType.Contact && <TagContainer>{tags}</TagContainer>}
        </ContainerDescription>
      </Container>
    </Tooltip>
  );
};
