import { RxJsonSchema } from 'rxdb';
import { ServiceRendered } from '../../../graph/types';
import { Maybe, RxScalars } from '../databaseUtils';

type ServiceRenderedOfflineFields = Omit<ServiceRendered, 'reminder' | 'doctor'>;
export type RxServiceRendered = ServiceRenderedOfflineFields;

export const serviceRenderedSchema: RxJsonSchema<RxServiceRendered> = {
  version: 9,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    practice_id: Maybe(RxScalars.ID),
    service_id: RxScalars.ID,
    service_name: Maybe(RxScalars.String),
    patient_id: Maybe(RxScalars.ID),
    patient_number: Maybe(RxScalars.String),
    patient_name: Maybe(RxScalars.String),
    contact_id: Maybe(RxScalars.ID),
    contact_number: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    invoice_id: Maybe(RxScalars.ID),
    invoice_number: Maybe(RxScalars.String),
    doctor_id: Maybe(RxScalars.ID),
    doctor_name: Maybe(RxScalars.String),
    business_center_id: Maybe(RxScalars.ID),
    business_center_name: Maybe(RxScalars.String),
    location_id: Maybe(RxScalars.ID),
    location_name: Maybe(RxScalars.String),
    date: RxScalars.String,
    name: RxScalars.String,
    quantity: RxScalars.String,
    list_unit_price: RxScalars.String,
    unit_price: RxScalars.String,
    unit_name: Maybe(RxScalars.String),
    total: RxScalars.String,
    discount: Maybe(RxScalars.String),
    controlled: RxScalars.Boolean,
    vaccine: RxScalars.Boolean,
    microchip: RxScalars.Boolean,
    manufacturer_name: Maybe(RxScalars.String),
    lot_number: Maybe(RxScalars.String),
    serial_number: Maybe(RxScalars.String),
    bottle_number: Maybe(RxScalars.String),
    expiry_date: Maybe(RxScalars.String),
    sort_order: RxScalars.Int,
    parent_id: Maybe(RxScalars.ID),
    child_sort_order: Maybe(RxScalars.Int),
    hidden: Maybe(RxScalars.Boolean),
    note_id: Maybe(RxScalars.String),
    note: Maybe(RxScalars.Object),
    updated: RxScalars.String,
    updated_user_id: Maybe(RxScalars.ID),
    updated_user_name: Maybe(RxScalars.String),
    doctor_names: Maybe(RxScalars.Array),
    reminder_ids: Maybe(RxScalars.Array),
    created: RxScalars.String,
    created_user_id: RxScalars.String,
    created_user_name: RxScalars.String,
    offline_id: Maybe(RxScalars.String),
    prescription: RxScalars.Boolean,
    rabies_tag: RxScalars.Boolean,
    reason: Maybe(RxScalars.String),
    withdrawal_period: Maybe(RxScalars.Int),
    withdrawal_prompt: Maybe(RxScalars.Boolean),
    list_total: RxScalars.String,
    hide_discount: Maybe(RxScalars.Boolean),
    is_parent: Maybe(RxScalars.Boolean),
    grouping_id: Maybe(RxScalars.String),
    info: Maybe(RxScalars.Array),
    text: Maybe(RxScalars.Array),
  },
  required: ['id'],
  indexes: ['updated', ['patient_id', 'date']],
};
