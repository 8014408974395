import React from 'react';
import { translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { Patient, QueryGetPatientVaccineReportArgs, QueryRptGetPatientVaccineArgs } from '../../graph/types';
import { subjectFieldName } from './StandardEmailFormFields';
import {
  generateReportAttachment,
  getFileNameForPatientVaccine,
  getPatientReportAsEmailAttachment,
  buildPatientVaxRptVariables,
} from '../../pages/Patients/patientUtils';
import { EmailModal } from './EmailModal';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { GetPatientVaccineReportAR } from '../../graph/queries/reports';
import { Reports } from '../../util/reportUtils';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';

interface EmailModalForPatientHistoryProps {
  onClose: () => void;
  patient: Patient;
}

export const EmailModalForPatientVaccine: React.FC<EmailModalForPatientHistoryProps> = ({ onClose, patient }) => {
  const usesActiveReports = useLDFlag(LDFlagNames.ARPatientVaxReport);
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { displayCurrency, displayDate } = useCurrencyAndDateSetting();

  const initialValues = {
    [subjectFieldName]: `Patient Vaccine - ${patient?.name}`,
  };

  const fileName = getFileNameForPatientVaccine(patient.name ?? '');

  const fetchAttachments = async () => {
    if (!patient) {
      return [];
    }

    const patientVaccineVariables = buildPatientVaxRptVariables({
      patient,
      practiceId,
      organizationId,
      usesActiveReports,
    });

    let patientVaccineReport;
    if (usesActiveReports) {
      patientVaccineReport = await generateReportAttachment(
        GetPatientVaccineReportAR,
        patientVaccineVariables as QueryRptGetPatientVaccineArgs,
        fileName,
        displayCurrency,
        displayDate,
        Reports.PatientVax
      );
    } else {
      patientVaccineReport = await getPatientReportAsEmailAttachment(
        patientVaccineVariables as QueryGetPatientVaccineReportArgs
      );
    }

    if (!patientVaccineReport) {
      return [];
    }

    return [patientVaccineReport];
  };

  return (
    <EmailModal
      title={translations.emailModalForPatientVaccine.title}
      visible
      onClose={onClose}
      attachments={[fileName]}
      initialValues={initialValues}
      onAttachmentsFetch={fetchAttachments}
    />
  );
};
