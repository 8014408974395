import styled from 'styled-components';

export const StatusWrapper = styled.div`
  margin-right: 10px;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;
