import React, { useContext, useEffect, useState } from 'react';
import PhoneTable from '../../components/PhoneTable/PhoneTable';
import {
  PhoneTableContext,
  phoneTableActions,
  PhoneTableContextProvider,
} from '../../components/PhoneTable/phoneTableState';
import { Phone, PhoneType, PhoneUpsert, PracticeDto } from '../../graph/types';
import styled from 'styled-components';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import omit from 'lodash/omit';
import { useUpsertPractice } from '../../hooks/ajax/practice/practiceHooks';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { useOrganizationContext } from '../../contexts/organization/state';
import { setOrganization } from '../../contexts/organization/action';

type Props = {
  practice: PracticeDto;
};

const PracticeSettingsPhoneTable = ({ practice }: Props) => {
  const {
    state: { organization },
    dispatch,
  } = useOrganizationContext();
  const { dispatch: dispatchPhoneTableContext } = useContext(PhoneTableContext);
  const [upsertPractice] = useUpsertPractice(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (organization) {
      dispatchPhoneTableContext(
        phoneTableActions.setPhoneTableData(practice?.phone?.map((phone) => ({ ...phone } as Phone)) ?? [])
      );
      dispatchPhoneTableContext(
        phoneTableActions.setPhoneTypeRefData(
          organization?.ref_demographics?.phone_type?.map((phoneType) => ({ ...phoneType } as PhoneType))
        )
      );
      const upsert = async (phone: Phone, isDelete?: boolean) => {
        const reqPhone: PhoneUpsert = isDelete
          ? { id: phone.id, phone: phone.phone, void: true }
          : omit(phone, ['__typename', 'phone_type_name']);
        setIsSaving(true);
        try {
          const upsertResponse = await upsertPractice({
            variables: {
              organizationId: practice.organization_id,
              practice: {
                id: practice.id,
                phone: [reqPhone],
              },
            },
          });
          const updatedPracticeWithPhone = { ...practice, phone: upsertResponse.data.upsertPractice.phone };
          dispatch(
            setOrganization({
              ...organization,
              practice: organization.practice.map((p) =>
                p.id === updatedPracticeWithPhone.id ? updatedPracticeWithPhone : p
              ),
            })
          );
          showSuccessMessage(translations.shared.saveSuccessMessage);
        } catch (e) {
          showErrorMessage(e);
        }
        setIsSaving(false);
      };
      dispatchPhoneTableContext(phoneTableActions.setOnAddSave(upsert));
      dispatchPhoneTableContext(phoneTableActions.setOnEditSave(upsert));
      dispatchPhoneTableContext(phoneTableActions.setOnDelete((phone) => upsert(phone, true)));
    }
  }, [organization, dispatchPhoneTableContext, upsertPractice, practice, dispatch]);

  return (
    <SaveSpinner isSaving={isSaving}>
      <PracticeSettingsPhoneTableWrapper>
        <PhoneTable />
      </PracticeSettingsPhoneTableWrapper>
    </SaveSpinner>
  );
};

const PracticeSettingsPhoneTableWithProviders = (props: Props) => {
  return (
    <PhoneTableContextProvider>
      <PracticeSettingsPhoneTable {...props} />
    </PhoneTableContextProvider>
  );
};
export default PracticeSettingsPhoneTableWithProviders;

const PracticeSettingsPhoneTableWrapper = styled.div`
  margin-top: 10px;
`;
