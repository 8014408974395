import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { HistoryOverview } from './HistoryOverview/HistoryOverview';

export const ThirdPartyHistory: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={`${match.path}`} component={HistoryOverview} />
      </Switch>
    </>
  );
};
