import React, { useContext, useMemo, useState } from 'react';
import { translations } from '../../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { isMatchIgnoringEmptyStrings } from '../../../util/objectComparisons';
import { Store } from 'antd/lib/form/interface';
import { Button, Form } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ServiceForm, ServiceType, ServiceUpsertFormFields } from '../ServiceForm/ServiceForm';
import { offset_4, w24 } from '../../../globalStyles.style';
import { Loading } from '../../../components/Loading/Loading';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { ServiceUpsertGenerator } from '../../../classes/upsertGenerators/ServiceUpsertGenerator';
import { ComponentWithPracticeProps } from '../../../components/WithPractice/WithPractice';
import { useInsertService } from '../../../hooks/ajax/service/serviceHooks';
import { routes } from '../../../constants/routes';
import { InfoTextUpsert, ServiceTaxTypeUpsert } from '../../../graph/types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { useMutationWithParameterWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useDefaultPracticeId } from '../../../hooks/ajax/practice/practiceHooks';
import TabWrapper, { TabWrapperProps } from '../../../components/TabWrapper/TabWrapper';
import { NoteField, ServiceNote } from '../ServiceNote';
import { useGetNoteRefData } from '../../../components/RecordSingleView/Notes/noteUtils';
import { findServiceTextIds, formatServiceText } from '../serviceUtils';
import { ServiceBundleTab } from '../ServiceBundle/ServiceBundleTab';
import { ServicesContext } from '../store/state';
import { getFormattedDecimalString } from '../../../util/displaying';
import { NoteState } from '../ViewService/ViewService';
import { flushSync } from 'react-dom';
import dayjs from 'dayjs';
import { upsertDateFormat } from '../../../constants/formats';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export const AddService: React.FC<ComponentWithPracticeProps> = ({ practice }) => {
  const { navigateBack, navigateTo } = useNavigationToRoute();
  const [form] = Form.useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.ServiceReferenceDataAndAddOn
  );
  const [insertService] = useMutationWithParameterWithMessages(useInsertService, { organizationId, practiceId });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [defaultNoteChecked, setDefaultNoteChecked] = useState<boolean>(false);
  const [defaultPrescriptionInstructions, setDefaultPrescriptionInstructions] = useState<boolean>(false);
  const { noteRefData } = useGetNoteRefData(true);
  const [note, setNote] = useState<NoteState>({ text: '', plaintext: '' });
  const [prescriptionNote, setPrescriptionNote] = useState<NoteState>({ text: '', plaintext: '' });
  const {
    textTypeId: orgTextTypeId,
    prescriptionTypeId: orgPrescriptionTypeId,
    intfoTypeId: orgInfoTypeId,
    vaccinationTypeId: orgVaccinationTypeId,
  } = useMemo(() => findServiceTextIds(organization?.ref_service?.info_type ?? []), [organization]);

  const [tabKey, setTabKey] = useState<string>('0');
  const { state } = useContext(ServicesContext);

  // Triggers before form submit, so will redirect correctly
  function assignCreateAnother() {
    setCreateAnother(true);
  }

  if (organizationLoading) {
    return <Loading />;
  }

  const onFinish = async (values: Store) => {
    if (values.default && !note.plaintext) {
      form.setFields([
        {
          name: 'note',
          errors: [translations.shared.getIsRequiredMessage(translations.viewServicePage.fields.content)],
        },
      ]);
      setTabKey('1');
      return;
    }
    if (values.defaultPrescription && !prescriptionNote.plaintext) {
      form.setFields([
        {
          name: 'prescriptionNote',
          errors: [translations.shared.getIsRequiredMessage(translations.viewServicePage.fields.content)],
        },
      ]);
      setTabKey('1');
      return;
    }
    setIsSaving(true);

    // Incase field onBlur is not called for some reason, call it again here to make sure
    values.price = getFormattedDecimalString(values.price);
    if (values.quantity_default) {
      values.quantity_default = getFormattedDecimalString(values.quantity_default);
    }

    const taxs: ServiceTaxTypeUpsert[] = values.tax_ids.map((tax: string) => ({
      record: {
        tax_type_id: tax,
      },
    }));
    const serviceValues: ServiceUpsertFormFields = {
      ...(values as ServiceUpsertFormFields),
      inactive: !values.active,
      tax: taxs,
    };
    const serviceUpsert = ServiceUpsertGenerator.generateNewEntry(serviceValues, practice.id);
    serviceUpsert.text = [];

    const text: InfoTextUpsert[] = [];
    if (values.info) {
      const servieInfoTextUpsert = {
        record: {
          type_id: orgInfoTypeId,
          value: values.info,
        },
      };
      text.push(servieInfoTextUpsert as InfoTextUpsert);
    }

    if (note.plaintext) {
      if (values.default) {
        const serviceTextUpsert = {
          record: {
            type_id: orgTextTypeId,
            value: formatServiceText(
              values.type_id,
              noteRefData.find((type) => type.id === values.type_id)?.name || '',
              note.text,
              note.plaintext
            ),
          },
        };

        text.push(serviceTextUpsert as InfoTextUpsert);
      }

      if (values.defaultPrescription) {
        const serviceTextUpsert = {
          record: {
            type_id: orgPrescriptionTypeId,
            value: JSON.stringify({ noteText: note.text, plaintext: note.plaintext }),
          },
          void: false,
        };

        serviceTextUpsert.void = !values.defaultPrescription;
        text.push(serviceTextUpsert as InfoTextUpsert);
      }
    }

    if (values.manufacturer || values.lotNumber || values.serialNumber || values.expiryDate) {
      const servieInfoTextUpsert = {
        record: {
          type_id: orgVaccinationTypeId,
          value: JSON.stringify({
            vaccinationDefaults: {
              manufacturer: values.manufacturer,
              serial: values.serialNumber,
              lot_number: values.lotNumber,
              expiry_date: values.expiryDate ? dayjs(values.expiryDate).format(upsertDateFormat) : null,
            },
          }),
        },
      };
      text.push(servieInfoTextUpsert as InfoTextUpsert);
    }
    serviceUpsert.text = text;

    if (state.bundleUpsert) {
      serviceUpsert.bundle = state.bundleUpsert;
    }

    serviceUpsert.bundlingRecord = {
      bundle_prompt: state.bundlePromptActive,
    };

    await insertService({
      options: {
        variables: {
          organizationId,
          service: serviceUpsert,
        },
      },
      successMessage: (data) => translations.addServicePage.getSuccessMessage(data?.upsertService.name || ''),
      onSuccess: () => {
        flushSync(() => {
          setUnsavedData(false);
        });
        if (state.setRemovedServices) {
          state.setRemovedServices([]);
        }
        if (createAnother) {
          form.resetFields();
          setCreateAnother(false);
          setNote({ text: '', plaintext: '' });
          setPrescriptionNote({ text: '', plaintext: '' });
          setDefaultNoteChecked(false);
          setDefaultPrescriptionInstructions(false);
          setTabKey('0');
        } else {
          navigateTo(routes.services);
        }
      },
    });

    setIsSaving(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    setUnsavedData(!isMatchIgnoringEmptyStrings(allValues, null));
    if (!allValues.prescription_prompt && defaultPrescriptionInstructions) {
      setDefaultPrescriptionInstructions(false);
    }
  };

  const handleNoteTextChange =
    (setStateFn: (values: NoteState) => void, name: string) => (text: string, plaintext: string) => {
      plaintext = plaintext.replace('\n', '');
      setStateFn({
        text,
        plaintext,
      });

      if (plaintext !== '') {
        setUnsavedData(true);
        form.setFields([
          {
            name,
            errors: [],
          },
        ]);
      }
    };

  // Set default selected tax checkboxs
  const initalTaxSelections: CheckboxValueType[] = [];
  organization?.ref_financial?.tax_type.forEach((tax) => {
    if (tax.default) {
      initalTaxSelections.push(tax.id);
    }
  });

  const initialValues = {
    active: true,
    quantity_prompt: false,
    type: ServiceType.standard,
    tax_ids: initalTaxSelections,
  };

  const tabContents: TabWrapperProps['tabContents'] = [
    {
      title: translations.servicePage.tabs.details,
      content: <ServiceForm form={form} organization={organization} setUnsavedData={setUnsavedData} />,
    },
    {
      title: translations.servicePage.tabs.notes,
      content: (
        <ServiceNote
          noteComponent={
            <>
              {defaultNoteChecked && (
                <NoteField
                  name='note'
                  noteTypes={noteRefData}
                  setText={handleNoteTextChange(setNote, 'note')}
                  text={note.text}
                />
              )}
            </>
          }
          prescriptionNoteComponent={
            <>
              {defaultPrescriptionInstructions && (
                <NoteField
                  name='prescriptionNote'
                  setText={handleNoteTextChange(setPrescriptionNote, 'prescriptionNote')}
                  text={prescriptionNote.text}
                />
              )}
            </>
          }
          onDefaultChange={setDefaultNoteChecked}
          onDefaultPrescriptionChange={setDefaultPrescriptionInstructions}
          isPrescriptionEnabled={form.getFieldValue('prescription_prompt')}
        />
      ),
    },
    {
      title: translations.servicePage.tabs.bundle,
      content: <ServiceBundleTab setUnsavedData={setUnsavedData} />,
    },
  ];

  const handleFinishFailed = (errorInfo: Store) => {
    const fieldsWithErrors = errorInfo.errorFields.reduce((i: string[], j: any) => [...i, j.name[0]], []);

    for (let i = 0; i < fieldsWithErrors.length; i++) {
      if (fieldsWithErrors[i] === 'type_id') {
        setTabKey('1');
        return;
      }
    }
    setTabKey('0');
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={unsavedData}
      warningMessage={translations.shared.getCreationNavigationWarning(translations.addServicePage.createdEntity)}
    >
      <PageHeader onBack={navigateBack} title={translations.addServicePage.title} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        onValuesChange={handleValueChange}
        initialValues={initialValues}
        onFinishFailed={handleFinishFailed}
        autoComplete='off'
      >
        <TabWrapper tabContents={tabContents} activeKey={tabKey} onTabClick={setTabKey} />
        <Form.Item style={{ ...w24, ...offset_4 }}>
          <Button type='primary' htmlType='submit' style={{ marginRight: '10px' }}>
            {translations.addServicePage.save}
          </Button>
          <Button type='primary' onClick={assignCreateAnother} htmlType='submit'>
            {translations.addServicePage.saveAnother}
          </Button>
        </Form.Item>
      </Form>
    </SaveSpinnerAndNavigationWarning>
  );
};
