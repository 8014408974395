import { gql } from '@apollo/client';

export const UpsertQuickBooksAuth = gql`
  mutation authQuickbooks($organizationId: ID!) {
    authQuickbooks(actionInput: { auth: true }, organizationId: $organizationId) {
      redirectUrl
      status
      errorMessage
    }
  }
`;

export const UpsertQuickBooksCallbackCode = gql`
  mutation callbackCodeQuickbooks($organizationId: ID!, $queryString: String!) {
    callbackCodeQuickbooks(actionInput: { queryString: $queryString }, organizationId: $organizationId) {
      redirectUrl
      status
      errorMessage
    }
  }
`;

export const UpsertCreateFinancialCloseJournal = gql`
  mutation createFinancialCloseJournal($organizationId: ID!, $financialClose: FinancialCloseJournalEntryUpsert!) {
    createFinancialCloseJournal(organizationId: $organizationId, financialClose: $financialClose) {
      id
      external_journal_id
      external_journal_number
      financial_period_id
      note
      number
      organization_id
      practice_id
      send_status_description
      sent_date
    }
  }
`;

export const UpsertSendJournalQuickbooks = gql`
  mutation sendJournalQuickbooks($organizationId: ID!, $practiceId: ID, $actionInput: QuickbooksSendJournalRequest!) {
    sendJournalQuickbooks(actionInput: $actionInput, organizationId: $organizationId, practiceId: $practiceId) {
      errorMessage
      redirectUrl
      status
    }
  }
`;

export const DeleteJournalQuickbooks = gql`
  mutation deleteJournalQuickbooks(
    $organizationId: ID!
    $practiceId: ID
    $actionInput: QuickbooksDeleteJournalRequest!
  ) {
    deleteJournalQuickbooks(actionInput: $actionInput, organizationId: $organizationId, practiceId: $practiceId) {
      errorMessage
      redirectUrl
      status
    }
  }
`;

export const GetQuickBooksPaymentTypes = gql`
  query getQuickbooksPaymentTypes($organizationId: ID!, $practiceId: ID!) {
    getQuickbooksPaymentTypes(
      getPaymentTypes: { getPayment: true }
      organizationId: $organizationId
      practiceId: $practiceId
    ) {
      errorMessage
      redirectUrl
      status
      data {
        id
        name
      }
    }
  }
`;

export const GetQuickBooksTaxRates = gql`
  query getQuickbooksTaxRates($organizationId: ID!, $practiceId: ID!) {
    getQuickbooksTaxRates(actionInput: { getTaxes: true }, organizationId: $organizationId, practiceId: $practiceId) {
      errorMessage
      redirectUrl
      status
      data {
        id
        name
        description
        value
      }
    }
  }
`;

export const GetQuickbooksAccounts = gql`
  query getQuickbooksAccounts($organizationId: ID!, $practiceId: ID!) {
    getQuickbooksAccounts(
      organizationId: $organizationId
      practiceId: $practiceId
      actionInput: { filter: { searchText: "" } }
    ) {
      redirectUrl
      status
      errorMessage
      data {
        id
        name
        number
        displayValue
      }
    }
  }
`;
