import { createPreviewTextForNote } from '../../components/Records/noteUpsertUtils';
import { SERVICE_NOTE_NAME, ServiceThirdPartyId } from '../../constants/referenceData/serviceReferenceData';
import { InfoTextId, InfoType, Service, ServiceTaxType, ServiceTaxTypeUpsert } from '../../graph/types';

export function generateTaxUpsert(
  formTaxIds: string[],
  initialTaxValues: ServiceTaxType[] | null | undefined
): ServiceTaxTypeUpsert[] {
  const taxs: ServiceTaxTypeUpsert[] = [];
  const formTaxIdSet = new Set(formTaxIds);
  const initTaxMap: { [taxTypeId: string]: ServiceTaxType } = {};

  initialTaxValues?.forEach((initialTax) => {
    initTaxMap[initialTax.tax_type_id] = initialTax;

    // void tax that were unchecked, i.e., not present in formTaxIds but present in initialTaxValues
    if (!formTaxIdSet.has(initialTax.tax_type_id)) {
      taxs.push({
        id: initialTax.id,
        void: true,
      });
    }
  });

  formTaxIds?.forEach((taxTypeId) => {
    // only add the tax that were newly added to checked taxes, i.e., present in formTaxIds but not present in initialTaxValues.
    if (!initTaxMap[taxTypeId]) {
      taxs.push({
        record: {
          tax_type_id: taxTypeId,
        },
        void: false,
      });
    }
  });

  return taxs;
}

export const formatServiceText = (noteTypeId: string, noteTypeName: string, noteText: string, plaintext: string) =>
  JSON.stringify({
    noteTypeId,
    noteTypeName,
    noteText,
    notePreview: createPreviewTextForNote(plaintext),
  });

export const findServiceTextIds = (arr: (InfoType | InfoTextId)[] = []) => {
  let textTypeId = '';
  let prescriptionTypeId = '';
  let intfoTypeId = '';
  let vaccinationTypeId = '';

  arr?.forEach((serviceText) => {
    if (serviceText.name_key === SERVICE_NOTE_NAME.TEXT) {
      textTypeId = serviceText.id ?? '';
    }
    if (serviceText.name_key === SERVICE_NOTE_NAME.PRESCRIPTION_INSTRUCTIONS) {
      prescriptionTypeId = serviceText.id ?? '';
    }
    if (serviceText.name_key === SERVICE_NOTE_NAME.INFO) {
      intfoTypeId = serviceText.id ?? '';
    }
    if (serviceText.name_key === SERVICE_NOTE_NAME.VACCINATION_DEFAULTS) {
      vaccinationTypeId = serviceText.id ?? '';
    }
  });

  return { textTypeId, prescriptionTypeId, intfoTypeId, vaccinationTypeId };
};

export const getThirdPartyEntry = (service: Service | undefined, thirdPartyId: ServiceThirdPartyId) => {
  return service?.third_party?.find(({ third_party_id }) => third_party_id === thirdPartyId);
};
