import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useUpdateOrganizationReferenceData } from '../../hooks/ajax/organization/organizationHooks';
import { getOrganizationSetting } from '../../pages/Contacts/ViewContact/statementUtils';
import { QuickBooksAuth } from '../QuickBooksAuth/QuickBooksAuth';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { buildReferenceDataUpsert } from '../../pages/ReferenceData/RefDataTable/refDataTableUpsertUtils';
import { tabKeys } from '../../pages/ReferenceData/refDataUtils';
import { showErrorMessage } from '../Notification/notificationUtil';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useLazyQuery } from '@apollo/client';
import { GetOrganizationDtoEs } from '../../graph/queries/organizationDTOs';
import { setOrganization } from '../../contexts/organization/action';

const layout = {
  labelCol: { lg: 8 },
  wrapperCol: { span: 10 },
};

export interface QuickBooksConfigurationModalProps {
  organizationId: string;
  onClose: () => void;
}

type QuickBooksConfigurationModalFormType = {
  receivablesAccount?: string;
};

export const QuickBooksConfigurationModal: React.FC<QuickBooksConfigurationModalProps> = ({
  organizationId,
  onClose,
}: QuickBooksConfigurationModalProps) => {
  const {
    state: { organization },
    dispatch,
  } = useOrganizationContext();

  const [upsertOrganization] = useMutationWithMessages(useUpdateOrganizationReferenceData);
  const [form] = useForm();
  const realmIdSetting = getOrganizationSetting(PracticeSettingsNameKey.QuickBooksRealmId, organization);
  const realmId = realmIdSetting?.value ?? '';
  const [showQuickBooksAuth, setShowQuickBooksAuth] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveChangesVisible, setSaveChangesVisible] = useState<boolean>(false);
  const receivablesAccounts = useMemo(() => {
    return organization?.ref_financial?.general_ledger?.map((gl) => ({
      id: gl.id,
      name: gl.name,
      sort_order: gl.sort_order,
      gl_number: gl.gl_number,
      is_receivables_account: gl.is_receivables_account,
    }));
  }, [organization?.ref_financial?.general_ledger]);
  const currentReceivablesAccount = useMemo(
    () => receivablesAccounts?.find((glAccount) => glAccount.is_receivables_account),
    [receivablesAccounts]
  );
  const initialValues: QuickBooksConfigurationModalFormType = {
    receivablesAccount: currentReceivablesAccount?.id,
  };
  const saveButtonDisabled = useMemo(() => isSaving || !saveChangesVisible, [isSaving, saveChangesVisible]);

  useEffect(() => {
    form.setFieldValue('receivablesAccount', currentReceivablesAccount?.id);
  }, [currentReceivablesAccount?.id, form]);

  const handleAuthClick = () => {
    setShowQuickBooksAuth(true);
  };

  const [fetchOrganization] = useLazyQuery(GetOrganizationDtoEs, {
    variables: { organizationId },
    fetchPolicy: 'network-only',
  });
  const updateOrganizationContext = useCallback(async () => {
    const { data } = await fetchOrganization();
    dispatch(setOrganization(data.getOrganizationDTOEs));
  }, [dispatch, fetchOrganization]);

  const onAuthFinish = useCallback(async () => {
    setIsSaving(true);
    await updateOrganizationContext();
    setShowQuickBooksAuth(false);
    if (!!form.getFieldsValue().receivablesAccount) {
      onClose();
    }
    setIsSaving(false);
  }, [form, onClose, updateOrganizationContext]);

  const QuickBooksAuthComponent = useMemo(
    () => <QuickBooksAuth organizationId={organizationId} onClose={onAuthFinish} onFinish={onAuthFinish} />,
    [onAuthFinish, organizationId]
  );

  const handleSave = () => {
    form.submit();
  };

  const handleValuesChange = () => {
    setSaveChangesVisible(true);
  };

  const handleFormSubmit = async ({ receivablesAccount }: QuickBooksConfigurationModalFormType) => {
    setIsSaving(true);
    const receivablesAccountData = receivablesAccounts?.find((ra) => ra.id === receivablesAccount);
    if (!receivablesAccountData) {
      showErrorMessage(translations.quickbooksConfigurationModal.errors.receivableAccountNotFound);
      return;
    }
    const upsert = buildReferenceDataUpsert(
      organizationId,
      {
        id: receivablesAccountData.id,
        sort_order: receivablesAccountData.sort_order,
        practice_id: null,
        gl_number: receivablesAccountData.gl_number,
        name: receivablesAccountData.name,
        is_receivables_account: true,
      },
      tabKeys.generalLedger,
      false,
      receivablesAccounts
    );

    await upsertOrganization({
      options: {
        variables: { organizationId, organization: upsert },
      },
      onSuccess: () => {
        // TODO - FIGURE OUT WHAT WAS BEING ORGREFETCHED HERE
        // await organizationRefetch();
        setIsSaving(false);
        setSaveChangesVisible(false);
        onClose();
      },
      onError: () => {
        setIsSaving(false);
      },
    });
  };

  return (
    <ModalWithCloseConfirm
      title={translations.quickbooksConfigurationModal.title}
      open
      onOk={handleSave}
      onCancel={onClose}
      okText={translations.quickbooksConfigurationModal.saveButtonText}
      okButtonProps={{ disabled: saveButtonDisabled }}
      isSaving={isSaving}
      savingMessage={isSaving ? translations.loadingComponent.saving : translations.shared.loading}
      width={650}
      cancelText={translations.shared.close}
    >
      {showQuickBooksAuth && QuickBooksAuthComponent}
      <Form
        {...layout}
        initialValues={initialValues}
        form={form}
        onValuesChange={handleValuesChange}
        onFinish={handleFormSubmit}
        autoComplete='off'
      >
        <Row>
          <Col span={24}>
            <p>{translations.quickbooksConfigurationModal.description}</p>
          </Col>
        </Row>
        <Form.Item label={translations.quickbooksConfigurationModal.fields.account}>
          <Button type='primary' onClick={handleAuthClick}>
            {translations.quickbooksConfigurationModal.authenticateButtonText}
          </Button>
        </Form.Item>
        <Form.Item label={translations.quickbooksConfigurationModal.fields.realmId}>
          <Input autoComplete='new-password' value={realmId} disabled />
        </Form.Item>
        <Form.Item
          name='receivablesAccount'
          label={translations.quickbooksConfigurationModal.fields.receivablesAccount}
        >
          <Select {...searchableSelectParams}>
            {receivablesAccounts?.map((glAccount) => (
              <Select.Option key={glAccount.id} value={glAccount.id} label={glAccount.name}>
                {glAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </ModalWithCloseConfirm>
  );
};
