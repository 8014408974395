import React, { PropsWithChildren, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import {
  newPasswordConfirmTemplateName,
  NewPasswordFormItems,
  newPasswordTemplateName,
} from '../NewPasswordFormItems/NewPasswordFormItems';
import { translations } from '../../constants/translations';
import { Dictionary } from 'lodash';
import { Auth } from 'aws-amplify';
import { showSuccessMessage } from '../Notification/notificationUtil';
import { Store } from 'antd/lib/form/interface';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};

interface ChangePasswordProps extends PropsWithChildren<unknown> {
  setIsSaving: (isSaving: boolean) => void;
  setHasUnsavedChanges: (hasUnsavedData: boolean) => void;
  hasUnsavedChanges: boolean;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  setIsSaving,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const [form] = Form.useForm();

  const [errorMessage, setErrorMessage] = useState<string>();
  const currentPasswordTemplateName = 'currentPassword';

  const handleValueChange = (_: Store, allValues: Store) => {
    const hasUnsavedChanges =
      !!allValues[newPasswordTemplateName] ||
      !!allValues[currentPasswordTemplateName] ||
      !!allValues[newPasswordConfirmTemplateName];
    setHasUnsavedChanges(hasUnsavedChanges);
  };

  const onFinish = async (values: Dictionary<string>) => {
    setIsSaving(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      const newPassword = values[newPasswordTemplateName];
      const oldPassword = values[currentPasswordTemplateName];
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      resetForm();
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage(translations.auth.passwordChangeErrorMessage);
      }
    }
    setIsSaving(false);
  };

  const resetForm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    setErrorMessage(undefined);
  };

  return (
    <Form {...layout} form={form} onFinish={onFinish} onValuesChange={handleValueChange} validateTrigger='onBlur'>
      <Row>
        <Col span={24}>
          <Form.Item
            label={translations.auth.currentPassword.label}
            name={currentPasswordTemplateName}
            rules={[{ required: true, message: translations.auth.currentPassword.errorMessage }]}
          >
            <Input.Password />
          </Form.Item>
          <NewPasswordFormItems />
        </Col>
      </Row>
      <Row>
        <Col offset={5} span={24}>
          <Form.Item validateStatus={'error'} help={errorMessage}>
            {hasUnsavedChanges ? (
              <>
                <Button style={{ marginRight: '10px' }} type='primary' htmlType='submit'>
                  {translations.auth.changePassword}
                </Button>
                <Button htmlType='button' onClick={resetForm}>
                  {translations.shared.cancelButtonText}
                </Button>
              </>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
