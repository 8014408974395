import dayjs from 'dayjs';
import { DEFAULT_LOCALE, LOCALES } from '../util/locales';

// DO NOT use for displaying dates. Use dateFormat from useUserLocaleData
export const formats = { date: 'YYYY-MM-DD', upsertDate: 'YYYY-MM-DD' };

export const dateFormats = [
  { format: 'YYYY-MM-DD', display: 'yyyy-mm-dd' },
  { format: 'MM-DD-YYYY', display: 'mm-dd-yyyy' },
  { format: 'DD-MM-YYYY', display: 'dd-mm-yyyy' },
  { format: 'MMM-DD-YYYY', display: 'Mon-dd-yyyy' },
  { format: 'YYYY-MMM-DD', display: 'yyyy-Mon-dd' },
];

export const upsertDateFormat = LOCALES[DEFAULT_LOCALE].dateFormat;

export const displayTodayAsDate = (format: string) => {
  return dayjs().format(format);
};

export const displayAsDate = (dateStr: string, format: string) => {
  return dayjs(dateStr).format(format);
};

export const displayDateAsString = (date: dayjs.Dayjs, format: string) => {
  return date ? date.format(format) : '';
};
