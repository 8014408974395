import React from 'react';
import { routes } from '../../constants/routes';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { WithPracticeDto } from '../../components/WithPractice/WithPractice';
import { ContactsOverview } from './ContactsOverview/ContactsOverview';
import { AddContact } from './AddContact/AddContact';
import { ViewContactContainer } from './ViewContact/ViewContactContainer';

export const Contacts: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={routes.addContact}>
          <AddContact />
        </Route>
        <Route path={routes.viewContact}>
          <WithPracticeDto Component={ViewContactContainer} />
        </Route>
        <Route path={`${match.path}`} component={ContactsOverview} />
      </Switch>
    </>
  );
};
