import { OptionsWithParentId } from '../components/DependentDropdown/DependentDropdown';
import { getLabelFromTranslation } from '../components/InfoTable/infoTableUtil';
import { countryProvStateTranslations, countryTranslations } from '../constants/countryTranslations';
import { Country, DemographicReferenceData, ProvState } from '../graph/types';

export const getSelectableCountries = (countries: Country[]) => {
  return countries
    ?.slice()
    ?.sort((c1, c2) => c2.sort_order - c1.sort_order)
    ?.map((item) => ({
      value: item.id,
      label: getLabelFromTranslation(countryTranslations, item.name_key),
    }))
    ?.filter((country) => country.label);
};

export const getCountryNameKey = (countries: Country[], countryId: string) =>
  countries.find((countryItem) => countryItem.id === countryId)?.name_key;

export const getProvinceNameKey = (refDemographics: DemographicReferenceData, provinceId: string) =>
  refDemographics.prov_state?.find((prov) => prov.id === provinceId)?.name_key;

export const getCountryKeyFromId = (countries: Country[], countryId: string) => {
  return countries.find((item) => item.id === countryId)?.name_key;
};

export const getSelectableProvincesStates = (states: ProvState[]): OptionsWithParentId[] => {
  return (
    states
      ?.map((item) => ({
        value: item.id,
        label: getLabelFromTranslation(countryProvStateTranslations, item.name_key),
        parentId: item.country_id,
      }))
      .filter((provState) => provState.label) || []
  );
};
