import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetContactsWithSearch } from '../../hooks/ajax/contact/contactHooks';
import { Contact } from '../../graph/types';
import { TableCellLink } from '../TableLink/TableCellLink';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { useGetContactTypeFilters } from '../../util/contactFilterUtil';
import { addSortingPriorityTo } from '../../util/filterAndSorting';
import { excludeById } from '../../util/filterUtil';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { AbbreviateToWidth } from '../AbbreviateToWidth/AbbreviateToWidth';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../constants/layout';
import { basicContactColumns, tagsProperty } from '../../pages/Contacts/ContactsOverview/contactColumns';
import { useSetWarningIfTooManyResults } from '../../hooks/setWarningIfTooManyResults';
import { useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { SelectEntityModalTable } from '../SelectEntityModalTable/SelectEntityModalTable';
import { noop } from 'lodash';
import { translations } from '../../constants/translations';
import { ContactTypeNameKey } from '../../constants/referenceData/contactReferenceData';
import { contactPropertyNames } from '../../constants/propertyNames';

export interface SelectPersonModalTableProps {
  searchValue?: string;
  excludedIds?: string[];
}

interface SelectContactModalTableProps extends SelectPersonModalTableProps {
  selectContact?: (contact: Contact) => void;
  setShowTooManyResultsWarning: (showWarning: boolean) => void;
  canSelectMultiple?: boolean;
  selectedContacts?: Contact[];
  setSelectedContacts?: (contacts: Contact[]) => void;
  finishSelectContacts?: (contacts: Contact[]) => void;
  selectedRelationshipFilter?: ContactTypeNameKey[];
  filterAndSortTableValues: ReturnType<typeof useTableResetFilterAndSort>;
  setContactTableColumns: (columns: CustomColumnType<Contact>[]) => void;
}

export const SelectContactModalTable: React.FC<SelectContactModalTableProps> = ({
  selectContact = noop,
  searchValue,
  excludedIds: excludedContactIds,
  setShowTooManyResultsWarning,
  canSelectMultiple,
  setSelectedContacts = noop,
  selectedContacts = [],
  selectedRelationshipFilter,
  filterAndSortTableValues,
  setContactTableColumns,
}: SelectContactModalTableProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { contacts, contactsLoading } = useGetContactsWithSearch(organizationId, searchValue);
  const { filteredValue, sortOrderMap, setFilterAndSortOrder } = filterAndSortTableValues;

  useEffect(() => {
    if (!canSelectMultiple && selectedContacts && selectedContacts.length > 0) {
      selectContact(selectedContacts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContacts, canSelectMultiple]);

  useEffect(() => {
    setFilterAndSortOrder((prev) => ({
      ...prev,
      filteredValue: { tags: selectedRelationshipFilter ?? null },
    }));
  }, [selectedRelationshipFilter, setFilterAndSortOrder]);

  useSetWarningIfTooManyResults(contacts, setShowTooManyResultsWarning);

  const filteredContacts = useMemo(() => {
    return excludeById(excludedContactIds, contacts) || [];
  }, [excludedContactIds, contacts]);

  const contactTypeFilters = useGetContactTypeFilters(contacts);

  const onContactClick = useCallback(
    (contact: Contact) => {
      if (canSelectMultiple && selectedContacts && setSelectedContacts) {
        if (selectedContacts.filter((selected) => selected.id === contact.id).length === 0) {
          const newSelectedContacts = selectedContacts.concat(contact);
          setSelectedContacts(newSelectedContacts);
        } else {
          const newSelectedContacts = selectedContacts.filter((selected) => selected.id !== contact.id);
          setSelectedContacts(newSelectedContacts);
        }
      } else if (selectContact) {
        selectContact(contact);
      }
    },
    [selectedContacts, canSelectMultiple, setSelectedContacts, selectContact]
  );

  const columns: CustomColumnType<Contact>[] = useMemo(
    () => [
      {
        ...basicContactColumns.number,
        filteredValue: filteredValue[contactPropertyNames.number] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.number],
        width: 150,
      },
      {
        ...basicContactColumns.name,
        filteredValue: filteredValue[contactPropertyNames.name] ?? null,
        sortOrder: sortOrderMap[contactPropertyNames.name],
        render: (text: string, contact: Contact) => (
          <TableCellLink onClick={() => onContactClick(contact)}>
            <AbbreviateToWidth width={400} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
          </TableCellLink>
        ),
        width: 400,
        ellipsis: true,
      },
      {
        ...basicContactColumns.tags,
        filters: contactTypeFilters,
        width: 250,
        filteredValue: filteredValue[tagsProperty] ?? null,
        sortOrder: sortOrderMap[tagsProperty],
      },
    ],
    [contactTypeFilters, filteredValue, sortOrderMap, onContactClick]
  );

  addSortingPriorityTo(columns);

  setContactTableColumns(columns);

  return (
    <SelectEntityModalTable<Contact>
      setSelectedEntities={setSelectedContacts}
      selectedEntities={selectedContacts}
      entities={filteredContacts}
      canSelectMultiple={canSelectMultiple}
      filterAndSortTableValues={filterAndSortTableValues}
      columns={columns}
      loading={contactsLoading}
      entity={translations.shared.selectEntityModal.entities.contacts}
    />
  );
};
