import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { keyBy, mapValues, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loading } from '../../components/Loading/Loading';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import TabsWithRouting from '../../components/TabsWithRouting/TabsWithRouting';
import { settingRoutingPaths } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute, useGetReferenceDataTypeFromRoute } from '../../hooks/route/routeParameterHooks';
import { RefDataTable } from './RefDataTable/RefDataTable';
import { FilteredBy, getCombinedNoteAndFileTypes, ReferenceDataType, tabKeys } from './refDataUtils';
import { contactTypeConfigs, ContactTypeNameKey } from '../../constants/referenceData/contactReferenceData';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';
import { getPaymentTypesReferenceData } from '../../constants/referenceData/paymentReferenceData';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useGetQuickbooksAccounts, useGetQuickBooksTaxRates } from '../../hooks/ajax/quickBooks/quickBooksHooks';
import { QuickBooksAuth } from '../../components/QuickBooksAuth/QuickBooksAuth';
import { flushSync } from 'react-dom';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useQuickBooksAligned } from '../../util/thirdPartyUtil';
import { getLedgerTypesReferenceData } from '../../constants/referenceData/ledgerReferenceData';
import useQuickBooksAuth from '../../components/QuickBooksAuth/QuickBooksAuthUtils';

const tabTitles = translations.referenceData.tabs;

type ReferenceDataTabs = {
  key: string;
  tab: string;
  route: string;
  data: ReferenceDataType[];
  filteredBy?: FilteredBy[];
  unchangeable?: boolean;
};

export const ReferenceData: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { qbAuthCancelled, needsQbAuth, cancelQuickBooksAuth, setNeedsQbAuth, handleQBAuthFailed } =
    useQuickBooksAuth();
  const organizationId = useGetOrganizationIdFromRoute();
  const referenceDataType = useGetReferenceDataTypeFromRoute();

  const {
    state: { organization },
  } = useOrganizationContext();

  const practiceId = organization?.default_practice_id;

  const { referenceData, dataLoading } = useGetReferenceData(organizationId);
  const showAlertSystemTab = useLDFlag(LDFlagNames.AlertSystem);
  const showQuickNotes = useLDFlag(LDFlagNames.QuickNotes);
  const { isQuickBooksAligned, isQuickBooksFlagOn } = useQuickBooksAligned(organization);
  const getSkipProperty = (tabKey: string) => !(referenceDataType === tabKey && isQuickBooksAligned);
  const [handleFinish, setHandleFinish] = useState(false);

  const [currentNoteTypeId, setCurrentNoteTypeId] = useState<string | undefined>(undefined);

  const quickNotes = useMemo(
    () => [
      ...(organization?.ref_contact?.note_type
        ?.filter((n) => n.id === currentNoteTypeId && n.quick_note)
        .reduce<ReferenceDataType[]>((acc, cnt) => acc.concat((cnt?.quick_note as ReferenceDataType[]) || []), []) ||
        []),
      ...(organization?.ref_patient?.note_type
        ?.filter((n) => n.id === currentNoteTypeId && n.quick_note)
        .reduce<ReferenceDataType[]>((acc, cnt) => acc.concat((cnt?.quick_note as ReferenceDataType[]) || []), []) ||
        []),
    ],
    [currentNoteTypeId, organization?.ref_contact?.note_type, organization?.ref_patient?.note_type]
  );

  useEffect(() => {
    if (!qbAuthCancelled && handleFinish) {
      flushSync(() => setNeedsQbAuth(false));
      switch (referenceDataType) {
        case tabKeys.generalLedger:
          refetchQBAccounts();
          break;
        case tabKeys.taxTypes:
          qbTaxRatesRefetch();
          break;
        default:
          break;
      }
    }
    setHandleFinish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFinish, qbAuthCancelled]);

  const retryGetQBReferenceTypes = useCallback(() => {
    setHandleFinish(true);
  }, []);

  const QuickBooksAuthComponent = useMemo(
    () => (
      <QuickBooksAuth
        organizationId={organizationId}
        onClose={cancelQuickBooksAuth}
        onFinish={retryGetQBReferenceTypes}
      />
    ),
    [organizationId, cancelQuickBooksAuth, retryGetQBReferenceTypes]
  );

  const { quickbooksAccounts, accountsLoading, refetchQBAccounts } = useGetQuickbooksAccounts(
    organizationId,
    getSkipProperty(tabKeys.generalLedger),
    practiceId ?? undefined,
    handleQBAuthFailed
  );

  const { qbTaxRates, qbTaxRatesError, qbTaxRatesLoading, qbTaxRatesRefetch } = useGetQuickBooksTaxRates(
    organizationId,
    getSkipProperty(tabKeys.taxTypes),
    practiceId ?? undefined,
    handleQBAuthFailed
  );

  useEffect(() => {
    if (qbTaxRatesError) {
      showErrorMessage(qbTaxRatesError.message);
    }
  }, [qbTaxRatesError]);

  if (dataLoading || qbTaxRatesLoading || accountsLoading) {
    return <Loading />;
  }

  if (
    !organization?.ref_contact ||
    !organization?.ref_patient ||
    !organization?.ref_financial ||
    !organization?.ref_user
  ) {
    return <p>{translations.shared.errorLoadingPage}</p>;
  }

  const {
    ref_contact: {
      status_type: contactStatuses,
      file_type: contactFileTypes,
      note_type: contactNoteTypes,
      alert_type: contactAlertTypes,
      type: contactTypes,
    },
    ref_patient: {
      reminder_status,
      breed,
      file_type: patientFileTypes,
      gender,
      note_type: patientNoteTypes,
      species,
      color,
      alert_type: patientAlertTypes,
    },
    ref_financial: {
      tax_type: taxTypes,
      general_ledger: generalLedger,
      ledger_type: ledgerTypes,
      ledger_payment_type: ledgerPaymentTypes,
    },
    ref_user: { type: userTypes },
  } = organization;

  const generalLedgerValues =
    generalLedger?.map(({ id, gl_number, name }) => ({
      value: id,
      label: `${gl_number} - ${name}`,
    })) ?? [];

  const { combinedFileTypes, combinedNoteTypes } = getCombinedNoteAndFileTypes(
    patientNoteTypes,
    contactNoteTypes,
    patientFileTypes,
    contactFileTypes
  );
  const tabs: ReferenceDataTabs[] = [
    {
      key: tabKeys.species,
      tab: tabTitles.species,
      route: tabKeys.species,
      data: species,
    },
    {
      key: tabKeys.breeds,
      tab: tabTitles.breeds,
      route: tabKeys.breeds,
      data: breed,
      filteredBy: [
        {
          key: 'species_id' as keyof ReferenceDataType,
          values:
            species?.map((t) => ({
              value: t.id,
              label: t.name,
            })) ?? [],
          placeholderText: translations.referenceData.tabs.species,
        },
      ],
    },
    {
      key: tabKeys.colors,
      tab: tabTitles.colors,
      route: tabKeys.colors,
      data: color,
    },
    {
      key: tabKeys.genders,
      tab: tabTitles.genders,
      route: tabKeys.genders,
      data: gender,
      filteredBy: [
        {
          key: 'species_id' as keyof ReferenceDataType,
          values:
            species?.map(({ id, name }) => ({
              value: id,
              label: name,
            })) ?? [],
          placeholderText: translations.referenceData.tabs.species,
        },
      ],
    },
    {
      key: tabKeys.taxTypes,
      tab: tabTitles.taxTypes,
      route: tabKeys.taxTypes,
      data: taxTypes,
      filteredBy: [
        {
          key: 'general_ledger_id' as keyof ReferenceDataType,
          values: generalLedgerValues,
          placeholderText: translations.referenceData.columnNames.general_ledger_id,
        },
        {
          key: 'external_tax_id' as keyof ReferenceDataType,
          values:
            qbTaxRates?.map(({ id, name, value }) => ({
              value: id,
              label: `${name} ${value}%`,
            })) ?? [],
          placeholderText: translations.referenceData.columnNames.external_tax_id,
        },
      ],
    },
    {
      key: tabKeys.userTypes,
      tab: tabTitles.userTypes,
      route: tabKeys.userTypes,
      data: userTypes,
    },
    {
      key: tabKeys.contactStatus,
      tab: tabTitles.contactStatus,
      route: tabKeys.contactStatus,
      data: contactStatuses,
    },
    {
      key: tabKeys.fileTypes,
      tab: tabTitles.fileTypes,
      route: tabKeys.fileTypes,
      data: uniqBy(combinedFileTypes, 'id'),
    },
    {
      key: tabKeys.noteTypes,
      tab: tabTitles.noteTypes,
      route: tabKeys.noteTypes,
      data: uniqBy(combinedNoteTypes, 'id'),
    },
    ...(showQuickNotes
      ? [
          {
            key: tabKeys.quickNotes,
            tab: tabTitles.quickNotes,
            route: tabKeys.quickNotes,
            data: uniqBy(quickNotes, 'id'),
            filteredBy: [
              {
                key: 'note_type_id' as keyof ReferenceDataType,
                values:
                  uniqBy(combinedNoteTypes, 'id')?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? [],
                placeholderText: translations.referenceData.tabs.noteTypes,
              },
            ],
          },
        ]
      : []),
    {
      key: tabKeys.generalLedger,
      tab: tabTitles.generalLedger,
      route: tabKeys.generalLedger,
      data: generalLedger,
      filteredBy: [
        {
          key: 'external_gl_id' as keyof ReferenceDataType,
          values:
            quickbooksAccounts?.data?.map(({ id, number, displayValue }) => ({
              value: id as string,
              label: `${number} - ${displayValue}`,
            })) ?? [],
          placeholderText: translations.referenceData.columnNames.external_gl_id,
        },
      ],
    },
    {
      key: tabKeys.ledgerTypes,
      tab: tabTitles.ledgerTypes,
      route: tabKeys.ledgerTypes,
      data: getLedgerTypesReferenceData(ledgerTypes),
      filteredBy: [
        {
          key: 'general_ledger_id' as keyof ReferenceDataType,
          values: generalLedgerValues,
          placeholderText: translations.referenceData.columnNames.general_ledger_id,
        },
      ],
      unchangeable: true,
    },
    {
      key: tabKeys.reminderStatus,
      tab: tabTitles.reminderStatus,
      route: tabKeys.reminderStatus,
      data: reminder_status,
      filteredBy: [
        {
          key: 'system_status_id' as keyof ReferenceDataType,
          values:
            translations.systemStatusFilteredOption?.map((t) => ({
              value: t.id,
              label: t.name,
            })) ?? [],
          placeholderText: translations.referenceData.columnNames.system_status_id,
        },
      ],
    },
    {
      key: tabKeys.contactTypes,
      tab: tabTitles.contactTypes,
      route: tabKeys.contactTypes,
      data:
        referenceData?.contact_type
          ?.filter(({ selectable }) => selectable)
          .map((data) => {
            return {
              id: data.type_id,
              name: contactTypeConfigs[data.name_key as ContactTypeNameKey]
                ? contactTypeConfigs[data.name_key as ContactTypeNameKey].label
                : '',
              sort_order: 0,
              enabled: contactTypes.findIndex(({ type_id }) => data.type_id === type_id) > -1,
            };
          }) ?? [],
      unchangeable: true,
    },
    {
      key: tabKeys.paymentTypes,
      tab: tabTitles.paymentTypes,
      route: tabKeys.paymentTypes,
      data: getPaymentTypesReferenceData(
        ledgerPaymentTypes,
        referenceData?.ledger_payment_type || [],
        isQuickBooksAligned,
        isQuickBooksFlagOn
      ),
      filteredBy: [
        {
          key: 'general_ledger_id' as keyof ReferenceDataType,
          values: generalLedgerValues,
          placeholderText: translations.referenceData.columnNames.general_ledger_id,
        },
      ],
      unchangeable: true,
    },
  ];

  if (showAlertSystemTab) {
    tabs.push({
      key: tabKeys.patientAlerts,
      tab: tabTitles.patientAlerts,
      route: tabKeys.patientAlerts,
      data: patientAlertTypes,
    });
    tabs.push({
      key: tabKeys.contactAlerts,
      tab: tabTitles.contactAlerts,
      route: tabKeys.contactAlerts,
      data: contactAlertTypes,
    });
  }

  const activeTab = tabs.find(({ route }) => {
    return route === referenceDataType;
  });

  const renderReferenceContent = () => {
    if (!activeTab) {
      return <Loading />;
    }

    const { data } = activeTab;

    return (
      <RefDataTable
        values={data}
        setIsSaving={setIsSaving}
        type={activeTab.key ?? ''}
        filterOptions={activeTab?.filteredBy}
        unchangeable={activeTab.unchangeable}
        isQuickBooksAligned={isQuickBooksAligned && !qbAuthCancelled}
        onFilterChange={setCurrentNoteTypeId}
        currentNoteTypeId={currentNoteTypeId}
      />
    );
  };

  const routePerTabKey = mapValues(keyBy(tabs, 'key'), (o) => settingRoutingPaths.referenceData.path + '/' + o.route);

  return (
    <>
      <PageHeader title={translations.settingsPage.referenceData} />
      <SaveSpinner isSaving={isSaving}>
        <TabsWithRouting type='card' routePerTabKey={routePerTabKey} activeKey={activeTab?.key}>
          {tabs.map(({ key, tab }) => (
            <Tabs.TabPane tab={tab} key={key}>
              {activeTab?.key === key && renderReferenceContent()}
            </Tabs.TabPane>
          ))}
        </TabsWithRouting>
      </SaveSpinner>
      {needsQbAuth && QuickBooksAuthComponent}
    </>
  );
};
