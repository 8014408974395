import { MutationUpsertFileArgs } from '../../../graph/types';
import { GetFiles, UpsertFile } from '../../../graph/queries/files';
import { useMemo } from 'react';
import { useOfflineQueryWithBackendSearch } from '../useOfflineQuery';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';

export const useUpsertFile = () => {
  return useOfflineErrorSkipMutation<'upsertFile', MutationUpsertFileArgs>(UpsertFile);
};

export const useGetFilesWithSearch = ({
  contactId,
  patientId,
  organizationId,
  searchValue,
  selector = {},
}: {
  contactId?: string;
  patientId?: string;
  organizationId: string;
  searchValue: string;
  selector?: { [key: string]: any };
}) => {
  const variables = useMemo(() => ({ organizationId, patientId, contactId }), [organizationId, patientId, contactId]);
  const { data, loading, setSearchTerm, refetchCurrentSearch } = useOfflineQueryWithBackendSearch<'getFiles'>({
    query: GetFiles,
    collection: 'file',
    variables,
    searchOptions: { value: searchValue, keys: ['description', 'name'] },
    queryOptions: {
      skip: organizationId === '',
      fetchPolicy: 'cache-first',
    },
    selector,
    shouldNotAdjustString: true,
  });

  return {
    files: data,
    filesLoading: loading,
    setSearchTerm,
    refetchCurrentSearch,
  };
};
