import gql from 'graphql-tag';

export const FinancialPeriodFields = gql`
  fragment FinancialPeriodFields on FinancialPeriod {
    id
    organization_id
    practice_id
    begin_date
    end_date
    status_id
    status_name_key
    journal {
      id
      note
      number
      sent_date
      date
      send_status_description
      details {
        id
        general_ledger_id
        external_gl_id
        description
        amount
        line_number
      }
    }
  }
`;
