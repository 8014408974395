import { Form, InputNumber, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { MAX_PERCENT_AMOUNT, MIN_INPUT_AMOUNT } from '../../constants/referenceData/ledgerReferenceData';
import { translations } from '../../constants/translations';
import { ServiceRendered } from '../../graph/types';
import { getPositiveValueRule, getRequiredRule, getValidMinMaxRule } from '../../util/forms';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import { ServiceOptionsFormValues } from '../ServiceRendered/AddServiceForm/AddServiceForm';

type DiscountModalContentProps = {
  service: ServiceRendered;
  form: FormInstance<ServiceOptionsFormValues>;
};

export const discountAmountField = 'discountAmount';
export const discountPercentField = 'discountPercentage';
export const totalWithDiscountField = 'totalWithDiscount';
export const hideDiscountField = 'hideDiscount';
export interface DiscountModalFormValues {
  [discountAmountField]?: number;
  [discountPercentField]?: number;
  [totalWithDiscountField]?: string;
  [hideDiscountField]?: boolean;
}

export const discountModalFormTestId = 'discountModalForm';

export const DiscountModalContent: React.FC<DiscountModalContentProps> = ({ service, form }) => {
  const [totalWithDiscount, setTotalWithDiscount] = useState(service.total);

  useEffect(() => {
    setTotalWithDiscount(service.total);
  }, [service.total]);

  useEffect(() => {
    const discountAmount = Math.abs(Number(service.discount ?? 0))?.toFixed(2);
    const discountPercent = Number(service.discount_percent ?? 0)?.toFixed(2);

    form.setFields([
      { name: discountAmountField, value: discountAmount },
      { name: discountPercentField, value: discountPercent },
      { name: hideDiscountField, value: service.hide_discount },
      { name: totalWithDiscountField, value: service.total },
    ]);
  }, [service, form]);

  const handleChange = (value: string | number, type: string) => {
    let serviceTotal;
    if (type === discountPercentField) {
      const discountAmount = (+service.list_total * +value) / 100;
      serviceTotal = (+service.list_total - discountAmount).toFixed(2);
      form.setFields([
        { name: discountAmountField, value: discountAmount.toFixed(2) },
        { name: totalWithDiscountField, value: serviceTotal },
      ]);
    } else {
      serviceTotal = (+service.list_total - +value).toFixed(2);
      form.setFields([
        { name: discountPercentField, value: ((+value / +service.list_total) * 100).toFixed(2) },
        { name: totalWithDiscountField, value: serviceTotal },
      ]);
    }
    setTotalWithDiscount(serviceTotal);
  };

  return (
    <>
      <Form.Item label={translations.shared.discountModal.fields.listPrice}>
        <CurrencyFormatter total={service.list_total} />
      </Form.Item>
      <Form.Item
        name={discountAmountField}
        label={translations.shared.discountModal.fields.discountAmount}
        rules={[
          getRequiredRule(translations.shared.discountModal.fields.discountAmount),
          getPositiveValueRule(translations.shared.discountModal.fields.discountAmount),
          getValidMinMaxRule(
            MIN_INPUT_AMOUNT,
            +service.list_total,
            translations.shared.discountModal.discountMaxWarning(service.list_total)
          ),
        ]}
      >
        <InputNumber
          precision={2}
          min={MIN_INPUT_AMOUNT}
          max={Number(service.list_total)}
          onChange={(value) => handleChange(value ?? '0', discountAmountField)}
        />
      </Form.Item>
      <Form.Item
        name={discountPercentField}
        label={translations.shared.discountModal.fields.discountPercentage}
        rules={[
          getRequiredRule(translations.shared.discountModal.fields.discountPercentage),
          getPositiveValueRule(translations.shared.discountModal.fields.discountPercentage),
          getValidMinMaxRule(MIN_INPUT_AMOUNT, 100, translations.shared.discountModal.discountPercentWarning),
        ]}
      >
        <InputNumber
          precision={2}
          min={MIN_INPUT_AMOUNT}
          max={MAX_PERCENT_AMOUNT}
          onChange={(value) => handleChange(value ?? '0', discountPercentField)}
        />
      </Form.Item>
      <Form.Item name={totalWithDiscountField} label={translations.shared.discountModal.fields.calculation}>
        <CurrencyFormatter total={totalWithDiscount ?? service.total} />
      </Form.Item>
      <Form.Item
        name={hideDiscountField}
        label={translations.shared.discountModal.fields.hideDiscount}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </>
  );
};
