import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { NewPasswordForm } from './NewPasswordForm/NewPasswordForm';
import { SignInForm } from './SignInForm/SignInForm';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { SignInContainer } from './SignInContainer';
import { isUserActive } from '../../util/loginUtil';
import { useOffline } from '../../util/offline/offlineUtil';
import { routes } from '../../constants/routes';

export type SignInLocationState = {
  from: Location;
  reload?: boolean;
};

export const SignInPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<SignInLocationState>();
  const { resetOfflineContext, isOnline } = useOffline();

  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | any>();

  const redirectToIndentedPage = () => {
    const { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
  };

  useEffect(() => {
    const { reload, ...rest } = location.state || {};
    if (reload) {
      history.replace(routes.login, rest); // clear the "reload" location state to prevent infinite loop
      window.location.reload();
    }
  }, [history, location.state]);

  const onLogin = async (email: string, password: string) => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      setCognitoUser(user);
      setIsNewPasswordRequired(true);
    } else {
      await isUserActive(user);
      resetOfflineContext();
      redirectToIndentedPage();
    }
  };

  const onSubmitNewPassword = async (newPassword: string): Promise<void> => {
    if (cognitoUser) {
      await Auth.completeNewPassword(cognitoUser, newPassword, cognitoUser.challengeParam?.requiredAttributes);
      redirectToIndentedPage();
    } else {
      setIsNewPasswordRequired(false);
    }
  };

  return isOnline ? (
    <SignInContainer>
      {isNewPasswordRequired ? <NewPasswordForm onSubmit={onSubmitNewPassword} /> : <SignInForm onLogin={onLogin} />}
    </SignInContainer>
  ) : (
    <Redirect to={{ pathname: routes.root }} />
  );
};
