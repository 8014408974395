import gql from 'graphql-tag';
import { RecordChangedFields } from '../fragments/recordChangedFragments';

export const OfflineUpsert = gql`
  mutation OfflineUpsert($organizationId: ID!, $upsert: OfflineUpsert!) {
    upsertOfflineUpsert(organizationId: $organizationId, upsert: $upsert) {
      id
    }
  }
`;

export const OnReplicationComplete = gql`
  subscription onReplicationComplete($id: ID!) {
    onReplicationComplete(id: $id) {
      id
    }
  }
`;

export const OnOrganizationDataChanged = gql`
  subscription onOrganizationDataChanged($id: ID!) {
    onOrganizationDataChanged(id: $id) {
      id
    }
  }
`;

export const OnOrganizationDTOChanged = gql`
  subscription onOrganizationDTOChanged($id: ID!) {
    onOrganizationDTOChanged(id: $id) {
      ...RecordChangedFields
    }
  }
  ${RecordChangedFields}
`;
