import * as React from 'react';
import { ContextProviderFC } from '../../../util/ContextProvider';
import { LabManagementAction } from './actions';
import { initialState, labManagementReducer, LabManagementState } from './reducer';

export const LabManagementContext = React.createContext<{
  state: LabManagementState;
  dispatch: React.Dispatch<LabManagementAction>;
}>({} as any);

type LabManagementContextProviderProps = {
  state?: Partial<LabManagementState>;
};

export const LabManagementContextProvider: ContextProviderFC<LabManagementContextProviderProps> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };
  const [state, dispatch] = React.useReducer(labManagementReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <LabManagementContext.Provider value={value}>{props.children}</LabManagementContext.Provider>;
};
