import React, { useMemo, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { getTranslatedSubscriptionErrorMessage, translations } from '../../constants/translations';
import { getRequiredRule, getValidEmailRule } from '../../util/forms';
import { DataConversionUpsert, SoftwareType } from '../../graph/types';
import { useGetSoftwareTypes } from '../../hooks/ajax/dataConversion/dataConversionHooks';
import { PaymentConfirmation } from './PaymentConfirmation';
import { SaveAndResetButton } from '../../components/SaveAndResetButton/SaveAndResetButton';
import { useFetchSubscriptionPricing, useUpdateSubscription } from '../../hooks/ajax/subscription/subscriptionHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { AdditionalOrganizationField } from '../../graph/queries/organizations';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { Fields, fieldsSpan, formLayout, offset } from './dataConversionUtils';
import { Store } from 'antd/lib/form/interface';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { SubscriptionUpsertGenerator } from '../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { MaxLengthFormItem } from '../../components/MaxLengthFormItem/MaxLengthFormItem';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';

const otherSoftwareKey = 'other';
const defaultSoftwarePrice = 499;

export const DataConversionInitialStage: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { softwareTypes } = useGetSoftwareTypes();
  const [softwareID, setSoftwareID] = useState<string>();
  const { organization } = useGetOrganization(organizationId, AdditionalOrganizationField.Subscription);
  const [modalProps, setModalProps] = useState<{ visible: boolean; onOk?: () => Promise<void> }>({ visible: false });
  const { data: subscriptionCharges, fetchDataConversionPrice } = useFetchSubscriptionPricing(organizationId, true);
  const [updateSubscription] = useUpdateSubscription(organizationId, true);

  const softwareTypesWithOther = useMemo(() => {
    return [
      ...(softwareTypes ?? []).map((type: SoftwareType) => ({ value: type.id, label: type.name })),
      { value: otherSoftwareKey, label: translations.dataConversion.otherSoftware },
    ];
  }, [softwareTypes]);

  const softwarePrice = useMemo(() => {
    if (softwareID) {
      return +(softwareTypes?.find((type) => type.id === softwareID)?.conversion_cost ?? defaultSoftwarePrice);
    }

    return null;
  }, [softwareID, softwareTypes]);

  const processPayment = async (dataConversionProperties: DataConversionUpsert) => {
    setIsSaving(true);

    setModalProps({
      visible: false,
    });

    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: SubscriptionUpsertGenerator.generateUpsert({
          dataConversionProperties,
        }),
      },
    });
    const subscriptionError = getTranslatedSubscriptionErrorMessage(
      data?.updateOrganizationSubscription?.error_name_key
    );

    if (subscriptionError) {
      showErrorMessage(subscriptionError);
    }

    setIsSaving(false);
  };

  const handleFinish = (values: Store) => {
    const dataConversionProperties = {
      ...values,
      organization_id: organizationId,
      [Fields.SoftwareID]: values[Fields.SoftwareID] === otherSoftwareKey ? null : values[Fields.SoftwareID],
      [Fields.SoftwareName]:
        softwareTypes?.find((type: SoftwareType) => type.id === softwareID)?.name ?? values[Fields.SoftwareName],
    } as DataConversionUpsert;
    setModalProps({ visible: true, onOk: () => processPayment(dataConversionProperties) });
  };

  const handleReset = () => {
    setSoftwareID(undefined);
    form.resetFields();
  };

  const handleSoftwareChange = (value: string) => {
    setSoftwareID(value);
    fetchDataConversionPrice({
      name: '',
      email: '',
      organization_id: organizationId,
      software_id: value === otherSoftwareKey ? null : value,
      software_name: '',
    });
  };

  return (
    <>
      <SaveSpinner isSaving={isSaving}>
        <Form {...formLayout} onFinish={handleFinish} form={form} autoComplete='off'>
          <MaxLengthFormItem
            label={translations.dataConversion.fields.name}
            name={Fields.Name}
            rules={[getRequiredRule(translations.dataConversion.fields.name)]}
            maxLength={100}
            tooltip={translations.dataConversion.fields.nameOfContactAtPracticeTooltip}
          >
            <Input />
          </MaxLengthFormItem>

          <MaxLengthFormItem
            label={translations.dataConversion.fields.email}
            name={Fields.Email}
            rules={[getRequiredRule(translations.dataConversion.fields.email), getValidEmailRule()]}
            maxLength={100}
            tooltip={translations.dataConversion.fields.contactEmailTooltip}
          >
            <Input />
          </MaxLengthFormItem>

          <Form.Item
            label={translations.dataConversion.fields.nameOfPreviousSoftware}
            rules={[getRequiredRule(translations.dataConversion.fields.nameOfPreviousSoftware)]}
            name={Fields.SoftwareID}
          >
            <Select options={softwareTypesWithOther} onChange={handleSoftwareChange} />
          </Form.Item>

          {softwareID === otherSoftwareKey && (
            <>
              <MaxLengthFormItem
                wrapperCol={{ offset, span: fieldsSpan }}
                name={Fields.SoftwareName}
                rules={[getRequiredRule(translations.dataConversion.fields.nameOfPreviousSoftware)]}
                maxLength={100}
              >
                <Input />
              </MaxLengthFormItem>
              <Row>
                <Col offset={offset} span={fieldsSpan}>
                  <p>{translations.dataConversion.otherSoftwareMessage}</p>
                </Col>
              </Row>
            </>
          )}
          {softwarePrice && (
            <Form.Item label={translations.dataConversion.total}>
              <b>
                <CurrencyFormatter total={softwarePrice} />
              </b>
            </Form.Item>
          )}

          <Row>
            <Col span={24}>
              <SaveAndResetButton
                onReset={handleReset}
                offset={offset}
                saveButtonText={translations.dataConversion.requestDataConversion}
              />
            </Col>
          </Row>
        </Form>
        <PaymentConfirmation
          amount={+(subscriptionCharges?.cardCharge ?? '0')}
          cardNumber={organization?.subscription?.display_number ?? ''}
          onCancel={() => setModalProps({ visible: false })}
          open={modalProps.visible}
          onOk={modalProps.onOk}
        />
      </SaveSpinner>
    </>
  );
};
