import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ApprovalsOverview } from './ApprovalsOverview/ApprovalsOverview';

export const Approvals: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={`${match.path}`} component={ApprovalsOverview} />
      </Switch>
    </>
  );
};
