import { useContext, useEffect, useRef } from 'react';
import { openUpdateReceivedNotification } from '../pages/Patients/patientUtils';
import { ViewSubscriptionContext } from '../components/ViewSubscription/store/state';
import { useContactUpsertSubscription, useGetContact } from './ajax/contact/contactHooks';
import { GeneralNames } from '../constants/translations';
import { useGetOrganizationIdFromRoute } from './route/routeParameterHooks';
import ViewSubscriptionActions from '../components/ViewSubscription/store/actions';
import { useUserContext } from '../contexts/user/state';
import { useGetConnectionId } from './authHooks';

export const useContactSubscription = (contactId: string, refetch?: () => void) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const connectionId = useGetConnectionId();
  const {
    state: { editing },
    dispatch,
  } = useContext(ViewSubscriptionContext);
  const { refetchContact } = useGetContact({
    contactId,
    organizationId,
  });

  const { record } = useContactUpsertSubscription(contactId);
  const editingRef = useRef(editing);
  const {
    state: { user },
  } = useUserContext();

  useEffect(() => {
    editingRef.current = editing;
  }, [editing]);

  useEffect(() => {
    if (record && record.connectionId !== connectionId) {
      const fetchData = async () => {
        if (refetchContact) {
          await refetchContact();
        }
        refetch?.();
        dispatch(ViewSubscriptionActions.incrementRenderKey());
      };

      if (!editingRef.current) {
        fetchData();
      } else {
        openUpdateReceivedNotification(() => {
          dispatch(ViewSubscriptionActions.setEditing(false));
          fetchData();
        }, GeneralNames.Contact);
      }
    }
  }, [record, refetch, dispatch, refetchContact, user, connectionId]);
};
