import { Progress, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { JobStatusNameKey, JobStatusTranslations } from '../../constants/referenceData/jobReferenceData';
import { translations } from '../../constants/translations';
import { GetJob } from '../../graph/queries/job';
import { Job } from '../../graph/types';
import { useGetJob, useJobStatusChangeSubscription } from '../../hooks/ajax/job/jobHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import AppSyncService from '../../services/AppSyncService/AppSyncService';

const WORKING_TIME_PER_CONTACT = 1.25;
const MAX_CALC_CONTACTS = 250;

type JobStatusProps = {
  jobId: string;
  jobCount?: number;
  closeModal: () => void;
  onError: (errorMessage: string) => void;
  noProgress?: boolean;
  onCompletion?: (job: Job) => Promise<void>;
};

const StyledSpin = styled(Spin)`
  width: 100%;
`;

export const JobStatus: React.FC<JobStatusProps> = ({
  jobId,
  jobCount,
  closeModal,
  onError,
  noProgress,
  onCompletion,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { job: subscriptionJob, error: subscriptionError } = useJobStatusChangeSubscription(jobId);
  const { job: jobFetchJob, error: jobFetchError } = useGetJob(organizationId, jobId);

  const [job, setJob] = useState<Job>();
  const statusNameKey = job?.status_name_key;

  useEffect(() => {
    setJob(subscriptionJob || jobFetchJob);
    if (subscriptionError || jobFetchError) {
      onError(jobFetchError?.message || translations.jobStatusModal.error('fetching'));
    }
  }, [subscriptionJob, subscriptionError, jobFetchError, jobFetchJob, onError]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    if (!statusNameKey || statusNameKey !== JobStatusNameKey.JobComplete) {
      timer = setTimeout(async () => {
        const getJobResult = await AppSyncService.client.query({
          query: GetJob,
          variables: {
            organizationId,
            jobId,
          },
          fetchPolicy: 'no-cache',
        });

        const fetchedJob = getJobResult.data?.getJob;

        if (fetchedJob) {
          setJob(getJobResult.data.getJob);
        }

        if (!fetchedJob.status_name_key) {
          onError(translations.shared.networkError);
        }

        if (fetchedJob.error_message || getJobResult.error) {
          onError(fetchedJob.error_message ?? translations.jobStatusModal.error());
        }
      }, 30000);
    }

    if (statusNameKey && statusNameKey === JobStatusNameKey.JobComplete) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [job, onError, jobId, organizationId, statusNameKey]);

  useEffect(() => {
    const runEffect = async () => {
      if (job && statusNameKey === JobStatusNameKey.JobComplete) {
        if (onCompletion) {
          await onCompletion(job);
        }
        closeModal();
      }
    };
    runEffect();
  }, [job, closeModal, onCompletion, statusNameKey]);

  useEffect(() => {
    if (job?.error_message) {
      onError(job.error_message ?? translations.jobStatusModal.error);
      closeModal();
    }
  }, [subscriptionError, jobFetchError, onError, job, closeModal]);

  const title = useMemo(() => {
    if (jobCount) {
      return jobCount > MAX_CALC_CONTACTS
        ? translations.jobStatusModal.estimatingTime(Math.ceil((jobCount * WORKING_TIME_PER_CONTACT) / 60))
        : translations.jobStatusModal.estimatingTime5Min;
    }

    return job?.status_name_key
      ? JobStatusTranslations[job?.status_name_key as JobStatusNameKey]
      : translations.jobStatusModal.defaultJobStatusNameKey;
  }, [jobCount, job]);

  const startedTime = useMemo(() => dayjs().format('h:mm A'), []);

  return (
    <Modal footer={[]} title={translations.jobStatusModal.title} closable={false} open>
      <h4>
        {title} {translations.jobStatusModal.startedTime(startedTime)}
      </h4>
      {noProgress ? <StyledSpin /> : <Progress percent={Math.round(job?.percent ?? 0)} status='active' />}
    </Modal>
  );
};
