import { Maybe, RxScalars, RxSchemaTypeWithUpsert } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';
import { Reminder, ReminderUpsert } from '../../../graph/types';

export type RxReminder = RxSchemaTypeWithUpsert<Reminder, ReminderUpsert, 'reminder'>;

export const reminderSchema: RxJsonSchema<RxReminder> = {
  version: 7,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    offline_id: Maybe(RxScalars.ID),
    organization_id: RxScalars.ID,
    practice_id: Maybe(RxScalars.ID),
    patient_id: Maybe(RxScalars.ID),
    offline_patient_id: Maybe(RxScalars.String),
    contact_id: Maybe(RxScalars.ID),
    offline_contact_id: Maybe(RxScalars.String),
    user_id: Maybe(RxScalars.ID),
    service_rendered_id: Maybe(RxScalars.ID),
    name: RxScalars.String,
    date: RxScalars.String,
    note: Maybe(RxScalars.String),
    status_id: RxScalars.String,
    status_name: RxScalars.String,
    updated: RxScalars.String,
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    hidden: RxScalars.Boolean,
    is_new: RxScalars.Boolean,
    patient_name: Maybe(RxScalars.String),
    contact_send_type_name: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    contact_number: Maybe(RxScalars.String),
  },
  required: ['id'],
  indexes: ['updated', 'date'],
};
