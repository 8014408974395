import React, { useMemo, useState } from 'react';
import { showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { VetConnectLabConfigurationModal } from '../../../components/VetConnectLabConfigurationModal/VetConnectLabConfigurationModal';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { translations } from '../../../constants/translations';
import { Organization, OrganizationSubscriptionResult } from '../../../graph/types';
import { useGetLabDevices, useGetLabSettingsVetConnect } from '../../../hooks/ajax/lab/labHooks';
import { useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { AddOnCardVetConnectLab } from './AddOnCardVetConnectLab';
import { flushSync } from 'react-dom';
import { LabDeviceName } from '../../../constants/referenceData/labReferenceData';

export interface AddOnVetConnectContainerProps {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
  practiceId: string;
}

export const AddOnCardVetConnectContainer: React.FC<AddOnVetConnectContainerProps> = ({
  organizationId,
  organization,
  refetchOrganization,
  practiceId,
}: AddOnVetConnectContainerProps) => {
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const [tempSubscription, setTempSubscription] = useState<OrganizationSubscriptionResult>();
  const { labDevices, refetch: refetchDevices } = useGetLabDevices(organizationId, practiceId, undefined);

  const [showConfigurationModal, setShowConfigurationModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { labSettingsVetConnect, getLabSettings } = useGetLabSettingsVetConnect(organizationId);

  const vetConnectLabAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.VetConnectLabLevel].level_id
      ),
    [organization]
  );

  const addOnEnabled = useMemo(() => (vetConnectLabAddon?.enabled ? true : false), [vetConnectLabAddon]);

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const activateVetConnectLabSubscription = async () => {
    await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: true,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.VetConnectLabLevel].level_id,
            },
          ],
        },
      },
    });
  };

  const getFutureChargesWithVetConnectLab = async () => {
    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: false,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.VetConnectLabLevel].level_id,
            },
          ],
        },
      },
    });
    return data?.updateOrganizationSubscription;
  };

  const handleOpenConfigurationModal = async () => {
    setIsSaving(true);
    if (!addOnEnabled) {
      const subscriptionData = await getFutureChargesWithVetConnectLab();
      setTempSubscription(subscriptionData);
    } else {
      const onSiteDevice = labDevices?.find(({ name }) => name === LabDeviceName.VetConnectVetLabStation);
      await getLabSettings({
        variables: { organizationId, filter: { lab_device_id: onSiteDevice?.id } },
      });
    }
    flushSync(() => {
      setIsSaving(false);
    });
    setShowConfigurationModal(true);
  };

  const handleCloseConfigurationModal = () => {
    setShowConfigurationModal(false);
  };

  const handleSaveConfiguration = async () => {
    setShowConfigurationModal(false);
    setIsSaving(true);
    await activateVetConnectLabSubscription();
    refetchOrganization();
    refetchDevices();
    setIsSaving(false);
    showSuccessMessage(translations.addOns.cards.vetConnectLab.successMessage);
  };

  return (
    <SaveSpinner isSaving={isSaving} savingMessage={translations.loadingComponent.loading}>
      <AddOnCardVetConnectLab
        isAddOnEnabled={addOnEnabled}
        price={vetConnectLabAddon?.cost}
        onClick={handleOpenConfigurationModal}
        onConfigureClick={handleOpenConfigurationModal}
        countryId={countryId}
      />
      {showConfigurationModal && (
        <VetConnectLabConfigurationModal
          addOnEnabled={addOnEnabled}
          organizationId={organizationId}
          subscription={tempSubscription}
          currentSettings={labSettingsVetConnect ?? undefined}
          onSave={handleSaveConfiguration}
          onClose={handleCloseConfigurationModal}
        />
      )}
    </SaveSpinner>
  );
};
