import { Collapse } from 'antd';
import React from 'react';
import { emailSettings } from './EmailSettingsInstructionsUtils';
const { Panel } = Collapse;

export const EmailSettingsInstructions = () => {
  return (
    <Collapse>
      {emailSettings.map((emailSetting) => (
        <Panel header={emailSetting.title} key={emailSetting.key}>
          <p
            dangerouslySetInnerHTML={{
              __html: emailSetting.description,
            }}
          />
        </Panel>
      ))}
    </Collapse>
  );
};
