import React from 'react';

interface AbbreviateToWidthProps {
  width?: number;
  text: string;
  paddingCompensation?: number;
  noTitle?: boolean;
}

export const AbbreviateToWidth: React.FC<AbbreviateToWidthProps> = ({
  width,
  text,
  paddingCompensation,
  noTitle,
}: AbbreviateToWidthProps) => {
  if (!width) {
    return <>{text}</>;
  }

  const compensationWidth = paddingCompensation || 0;
  return (
    <div
      title={noTitle ? undefined : text}
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: width - compensationWidth,
      }}
    >
      {text}
    </div>
  );
};
