import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { HisaPatient, InfoDto, InfoTypeDto } from '../../graph/types';
import { translations } from '../../constants/translations';
import { Button, Input, Modal, Table } from 'antd';
import { TableCellLink } from '../TableLink/TableCellLink';
import { marginFixForInlineEditing, paddingInInputField } from '../../constants/tables';
import { getLabelFromTranslation } from './infoTableUtil';
import { ViewSubscriptionContext } from '../ViewSubscription/store/state';
import ViewSubscriptionActions from '../ViewSubscription/store/actions';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useGetHISAPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useOffline } from '../../util/offline/offlineUtil';
import { TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { PatientInfoType, patientInfoTypeId } from '../../constants/referenceData/patientReferenceData';
import { TitleWithSearchBox } from '../TitleWithSearchBox/TitleWithSearchBox';
import { SearchOutlined } from '@ant-design/icons';
import { flushSync } from 'react-dom';
import { DEBOUNCE_TIME_FOR_SEARCH } from '../../constants/queryConstants';

export const maxWidthOfInfoTable = 650;
interface InfoTableProps extends PropsWithChildren<unknown> {
  organizationId: string;
  practiceId: string;
  refTypes: InfoTypeDto[];
  values: InfoDto[];
  namesToDisplay: { [key: string]: string };
  mainColumnTitle: string;
  setHasChanged: (hasChanged: boolean) => void;
  upsertInfoValue: (updatedInfo: InfoTableEntry, onSuccess: () => void) => void;
  patientName: string;
}

export interface InfoTableEntry {
  id?: string;
  type_id: string;
  name: string;
  value?: string | null;
}

const getInfoTableContents = (
  refTypes: InfoTypeDto[],
  values: InfoDto[],
  namesToDisplay: { [key: string]: string },
  hisaEnabled: boolean
) => {
  const rows = Array<InfoTableEntry>();
  refTypes.forEach((type) => {
    const info = values.find((info) => info.type_id === type.id);
    if (type.name_key === PatientInfoType.HISA && !hisaEnabled) {
      return;
    }
    rows.push({
      id: info?.id,
      type_id: type.id,
      name: getLabelFromTranslation(namesToDisplay, type.name_key),
      value: info?.value,
    });
  });
  return rows;
};

export const InfoTable: React.FC<InfoTableProps> = ({
  organizationId,
  practiceId,
  mainColumnTitle,
  refTypes,
  values,
  namesToDisplay,
  setHasChanged,
  upsertInfoValue,
  patientName,
}: InfoTableProps) => {
  const { dispatch: dispatchViewPatient } = useContext(ViewSubscriptionContext);
  const [editingInfoTypeId, setEditingInfoTypeId] = useState<string>();
  const [pendingInfo, setPendingInfo] = useState<InfoTableEntry | null>();
  const [infoHasChanged, setInfoHasChanged] = useState<boolean>();
  const [hisaModalOpen, setHisaModalOpen] = useState(false);
  const [hisaSearchText, setHisaSearchText] = useState(patientName);
  const [saveHisaNumber, setSaveHisaNumber] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const hisaEnabled = useLDFlag(LDFlagNames.Hisa);
  const { getHisaPatients, hisaPatients, hisaPatientsLoading } = useGetHISAPatientsWithSearch(
    organizationId,
    practiceId,
    hisaSearchText
  );
  const infoTableContents = getInfoTableContents(refTypes, values, namesToDisplay, hisaEnabled);
  const { filteredValue, sortOrderMap } = useTableResetFilterAndSort();

  const { isOnline } = useOffline();

  useEffect(() => {
    dispatchViewPatient(ViewSubscriptionActions.setEditing(!!editingInfoTypeId));
  }, [dispatchViewPatient, editingInfoTypeId]);

  const clearState = () => {
    setEditingInfoTypeId(undefined);
    setPendingInfo(undefined);
    setHisaModalOpen(false);
  };

  const onCancel = () => {
    clearState();
    setInfoHasChanged(false);
    setHasChanged(false);
    setHisaModalOpen(false);
  };

  const onSave = async () => {
    const editedInfoTypeId = editingInfoTypeId;
    if (pendingInfo == null) {
      onCancel();
      return;
    }
    setIsSaving(true);
    try {
      await upsertInfoValue(pendingInfo, () => setPendingInfo(undefined));
      onCancel();
    } catch (e) {
      setEditingInfoTypeId(editedInfoTypeId);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (saveHisaNumber && pendingInfo != null) {
      onSave();
      setSaveHisaNumber(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInfo, saveHisaNumber]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getHisaPatients({
        variables: { organizationId, practiceId, filter: { patientNames: [hisaSearchText] } },
      });
    }, DEBOUNCE_TIME_FOR_SEARCH);
    return () => clearTimeout(timer);
  }, [hisaSearchText, getHisaPatients, organizationId, practiceId]);

  const handleFocus = (event: any) => event.target.select();

  const onChangeInfoTypeValue = (info: InfoTableEntry, updatedValue: string) => {
    setHasChanged(true);
    setInfoHasChanged(true);
    setPendingInfo({ ...info, value: updatedValue });
  };

  function getDisplayColumnWidth(columnWidth: number) {
    return `${columnWidth - 2 * paddingInInputField}px`;
  }

  const onValueCellClick = (info: InfoTableEntry) => {
    return () => {
      if (!editingInfoTypeId) {
        setEditingInfoTypeId(info.type_id);
      }
    };
  };

  const onSelectHisaPatient = (info: InfoTableEntry, value: string) => {
    onChangeInfoTypeValue(info, value);
    setSaveHisaNumber(true);
  };

  const onSearchHisaPatientButtonClick = () => {
    setHisaModalOpen(true);
  };

  const getValueInputRenderFunction = (columnWidth: number) => (info: InfoTableEntry) => {
    const displayColumnWidth = getDisplayColumnWidth(columnWidth);
    if (
      editingInfoTypeId === info.type_id &&
      info.name === getLabelFromTranslation(namesToDisplay, PatientInfoType.HISA)
    ) {
      const buttonWidith = 32;
      return (
        <div style={{ display: 'flex' }}>
          <Input
            onPressEnter={onSave}
            style={{ width: columnWidth - buttonWidith, ...marginFixForInlineEditing }}
            autoFocus
            defaultValue={info.value || ''}
            onFocus={handleFocus}
            onChange={(event) => onChangeInfoTypeValue(info, event.target.value)}
            data-testid={`${info.type_id}_value`}
            maxLength={255}
            placeholder={translations.patientPage.identification.hisaInputPlaceholder}
            disabled={!isOnline}
          />
          <Button
            style={{ marginLeft: 0, width: buttonWidith }}
            icon={<SearchOutlined />}
            type={'primary'}
            onClick={onSearchHisaPatientButtonClick}
            data-testid={'set-search-hisa-patient'}
            disabled={!isOnline}
          />
        </div>
      );
    }
    if (editingInfoTypeId === info.type_id) {
      return (
        <Input
          onPressEnter={onSave}
          style={{ width: columnWidth, ...marginFixForInlineEditing }}
          autoFocus
          defaultValue={info.value || ''}
          onFocus={handleFocus}
          onChange={(event) => onChangeInfoTypeValue(info, event.target.value)}
          data-testid={`${info.type_id}_value`}
          maxLength={255}
        />
      );
    }
    return (
      <div style={{ width: displayColumnWidth }} onClick={onValueCellClick(info)}>
        {info.value || '< ' + translations.patientPage.identification.notSet + ' >'}
      </div>
    );
  };

  const columns = [
    {
      title: mainColumnTitle,
      key: 'type',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: translations.shared.identification.value,
      key: 'value',
      width: 250,
      render: getValueInputRenderFunction(250),
    },
    {
      title: translations.shared.identification.action,
      key: 'action',
      render: (info: InfoTableEntry) => {
        if (editingInfoTypeId === info.type_id) {
          return (
            <>
              {infoHasChanged && (
                <TableCellLink onClick={onSave} dataTestId={`${info.type_id}_saveButton`}>
                  {translations.shared.saveButtonText}
                </TableCellLink>
              )}
              <TableCellLink onClick={onCancel} dataTestId={`${info.type_id}_cancelButton`}>
                {translations.shared.cancelButtonText}
              </TableCellLink>
            </>
          );
        } else if (editingInfoTypeId) {
          return <div />;
        }
        return (
          <>
            <TableCellLink
              dataTestId={`${info.type_id}_editButton`}
              onClick={async () => {
                if (info.type_id === patientInfoTypeId[PatientInfoType.HISA]) {
                  await getHisaPatients({
                    variables: { organizationId, practiceId, filter: { patientNames: [patientName] } },
                  });
                }
                setEditingInfoTypeId(info.type_id);
              }}
              disabled={info.type_id === patientInfoTypeId[PatientInfoType.HISA] && !isOnline}
            >
              {translations.shared.editButtonText}
            </TableCellLink>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table<InfoTableEntry>
        style={{ maxWidth: maxWidthOfInfoTable }}
        dataSource={infoTableContents}
        columns={columns}
        rowKey={'type_id'}
        pagination={false}
      />
      {hisaModalOpen && (
        <Modal
          title={
            <TitleWithSearchBox
              title={translations.shared.selectPatientModal.title}
              searchBoxPlaceholder={translations.patientsPage.searchPlaceholder}
              onSearchValueChange={(event) => {
                flushSync(() => {
                  setHisaSearchText(event.target.value);
                });
              }}
              onClear={() => setHisaSearchText('')}
              loading={hisaPatientsLoading}
            />
          }
          open
          onCancel={clearState}
          style={{ height: '90vh', minHeight: '90vh' }}
          footer={
            <Button key='back' onClick={clearState}>
              {translations.shared.selectPatientModal.modalCancelButton}
            </Button>
          }
          maskClosable={false}
          centered
        >
          <TableWithCustomFiltering<HisaPatient>
            dataSource={hisaPatients ?? []}
            columns={[
              {
                title: translations.patientPage.details.hisaTable.name,
                filteredValue: filteredValue.name ?? null,
                sortOrder: sortOrderMap.name,
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: translations.patientPage.details.hisaTable.number,
                dataIndex: 'hisaHorseId',
                filteredValue: filteredValue.hisaHorseId ?? null,
                sortOrder: sortOrderMap.hisaHorseId,
                key: 'hisaHorseId',
              },
              {
                title: translations.patientPage.details.hisaTable.action,
                key: 'actions',
                render: (entity: HisaPatient) => (
                  <TableCellLink
                    onClick={() => {
                      const infoType = infoTableContents.find(
                        (info) => info.type_id === patientInfoTypeId[PatientInfoType.HISA]
                      );
                      if (infoType) {
                        onSelectHisaPatient(infoType, entity.hisaHorseId ?? '');
                      }
                    }}
                  >
                    {translations.shared.selectContactModal.select}
                  </TableCellLink>
                ),
                width: 90,
              },
            ]}
            tableKey={TableKey.HisaPatients}
            loading={hisaPatientsLoading || isSaving}
          />
        </Modal>
      )}
    </>
  );
};
