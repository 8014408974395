import { HisaRequirementsNameKey, hisaSelectFields, selectableInputTypes } from '../../constants/hisaRequirements';
import { translations } from '../../constants/translations';
import { Info, InfoText, RefServiceRenderedInfoTypeData, ServiceRendered } from '../../graph/types';
import { useOffline } from '../../util/offline/offlineUtil';

export interface HisaContent {
  label: string;
  value: string;
  keyAndTestId: string;
}

export interface HisaPopoverContentProps {
  content: HisaContent[];
  contentLoading: boolean;
}
const hisaNameKeys = Object.values(HisaRequirementsNameKey);

const populateContent = (
  object: Info | InfoText,
  content: HisaContent[],
  serviceReferenceData?: RefServiceRenderedInfoTypeData[] | null
) => {
  if (hisaNameKeys.includes(object.name_key as HisaRequirementsNameKey)) {
    const key = object.name_key as HisaRequirementsNameKey;
    const value = selectableInputTypes.includes(key)
      ? hisaSelectFields[serviceReferenceData?.find((refData) => refData.id === object.value)?.name_key || '']
      : object.value;
    content.push({ label: translations.hisaNameKeys[key], value, keyAndTestId: key });
  }
};

export const useHisaPopoverContent = (
  loading: boolean,
  serviceRendered?: ServiceRendered,
  serviceReferenceData?: RefServiceRenderedInfoTypeData[] | null
): HisaPopoverContentProps => {
  const { isOnline } = useOffline();
  const popoverContent: HisaContent[] = [];
  serviceRendered?.text?.forEach((text) => {
    populateContent(text, popoverContent, serviceReferenceData);
  });
  serviceRendered?.info?.forEach((info) => {
    populateContent(info, popoverContent, serviceReferenceData);
  });

  return {
    content: popoverContent,
    contentLoading: loading || (isOnline && !(serviceRendered?.info?.length || serviceRendered?.text?.length)),
  };
};
