import { RouteType } from '../../components/TabsWithRouting/TabsWithRouting';
import { contactIdParameter, patientIdParameter, routes } from '../../constants/routes';
import { UserOrganizationContactCard, UserOrganizationPatientCard } from '../../graph/types';
import {
  getRouteWithParameters,
  withContactIdParameter,
  withPatientIdParameter,
} from '../../hooks/route/navigationHooks';
import { joinNameAndNumber } from '../../util/displaying';

export type EntityCard = UserOrganizationContactCard | UserOrganizationPatientCard;

export enum OfficeType {
  contacts = 'Contact',
  patients = 'Patient',
}

export const patientCardGeneralRoute = `${routes.doctorOffice}/patient/${patientIdParameter}`;
export const contactCardGeneralRoute = `${routes.doctorOffice}/contact/${contactIdParameter}`;
export const contactCardPageRoute = `${routes.viewContact}`;

export const getPatientCardRouteWithFilledInPatientIdParameter = (card: UserOrganizationPatientCard) => {
  return getRouteWithParameters(patientCardGeneralRoute, withPatientIdParameter(card.patient_id));
};

export const getContactCardRouteWithFilledInContactIdParameter = (card: UserOrganizationContactCard) => {
  return getRouteWithParameters(contactCardGeneralRoute, withContactIdParameter(card.contact_id));
};

export const getContactPageRouteWithFilledInContactIdParameter = (card: UserOrganizationContactCard) => {
  return getRouteWithParameters(contactCardPageRoute, withContactIdParameter(card.contact_id));
};

// card key has to be the card id, for the closing of tabs to work correctly
export const getCardTabKey = (card: EntityCard) => card.id;

export const getRoutePerTabKeyForCards = (cards: EntityCard[]) => {
  const routePerTabKey: { [tabKey: string]: RouteType } = {};
  cards.forEach(
    (card) =>
      (routePerTabKey[getCardTabKey(card)] =
        'patient_id' in card
          ? getPatientCardRouteWithFilledInPatientIdParameter(card)
          : getContactCardRouteWithFilledInContactIdParameter(card))
  );
  return routePerTabKey;
};

export const joinCardNameAndNumber = (card: EntityCard) => {
  if ('patient_name' in card) {
    return joinNameAndNumber({
      name: card.patient_name,
      number: card.patient_number,
    });
  }
  return joinNameAndNumber({
    name: (card as UserOrganizationContactCard).contact_name,
    number: (card as UserOrganizationContactCard).contact_number,
  });
};

const localStorageOfficeTypeKey = 'DOCTOR_OFFICE_TYPE';

export const getDoctorOfficeType = () => {
  const officeType = localStorage.getItem(localStorageOfficeTypeKey) as OfficeType;
  if (Object.values(OfficeType).includes(officeType)) {
    return officeType;
  }
  return null;
};

export const setDoctorOfficeType = (doctorOfficeType: OfficeType | null) =>
  doctorOfficeType
    ? localStorage.setItem(localStorageOfficeTypeKey, doctorOfficeType)
    : localStorage.removeItem(localStorageOfficeTypeKey);
