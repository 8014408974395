import { Store } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { isMatch } from 'lodash';
import { useCallback, useState } from 'react';
import { flushSync } from 'react-dom';

export const useFormChanges = (form: FormInstance, initialValues: Store, isSaving?: boolean) => {
  const [hasDataChanged, setHasDataChanged] = useState(false);

  const handleFieldReset = useCallback(() => {
    form.resetFields();
    flushSync(() => {
      setHasDataChanged(false);
    });
  }, [form]);

  const handleOnReset = useCallback(() => {
    if (isSaving) {
      return;
    }
    handleFieldReset();
  }, [handleFieldReset, isSaving]);

  const handleOnValuesChange = (_: any, updatedValue: typeof initialValues) => {
    return setHasDataChanged(!isMatch(initialValues, updatedValue));
  };

  return { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange };
};
