import gql from 'graphql-tag';
import {
  CreateUserFields,
  UserFields,
  UserOrganizationCards,
  UserOrganizationWithPermissionAndType,
} from '../fragments/userFragments';
import { SettingFields, ValidateVetFields } from '../fragments';

export const GetUsers = gql`
  query getUsers($organizationId: ID!) {
    getUsersPg(organizationId: $organizationId, filter: {}) {
      ...UserFields
      ...UserOrganizationWithPermissionAndType
    }
  }
  ${UserFields}
  ${UserOrganizationWithPermissionAndType}
`;

export const GetUserWithPermissions = gql`
  query getUsersPgWithPermissions($organizationId: ID!, $filter: QueryUserOrganizationFilter) {
    getUsersPg(organizationId: $organizationId, filter: $filter) {
      ...UserFields
      ...UserOrganizationWithPermissionAndType
    }
  }
  ${UserFields}
  ${UserOrganizationWithPermissionAndType}
`;

export const GetUserOrganizationCardsPg = gql`
  query getUserOrganizationCardsPg($organizationId: ID!, $filter: QueryUserOrganizationCardFilter!) {
    getUserOrganizationCardsPg(organizationId: $organizationId, filter: $filter) {
      ...UserOrganizationCards
    }
  }
  ${UserOrganizationCards}
`;

export const GetUserSettings = gql`
  query getUserSettings($organizationId: ID!, $practiceId: ID!, $userId: ID!) {
    getUserSettings(organizationId: $organizationId, practiceId: $practiceId, userId: $userId) {
      ...SettingFields
    }
  }
  ${SettingFields}
`;

export const GetHISAVetValidation = gql`
  query getHISAVetValidation($organizationId: ID!, $practiceId: ID!, $validate: HISAValidateVet) {
    getHISAVetValidation(organizationId: $organizationId, practiceId: $practiceId, validate: $validate) {
      ...ValidateVetFields
    }
  }
  ${ValidateVetFields}
`;

export const CreateUser = gql`
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      ...CreateUserFields
    }
  }
  ${CreateUserFields}
`;

export const SetUserStatus = gql`
  mutation setUserStatus($user: UserStatus!) {
    setUserStatus(user: $user) {
      id
    }
  }
`;

export const UpsertUser = gql`
  mutation upsertUser($user: UserUpsert!, $organizationId: ID!) {
    upsertUser(user: $user, organizationId: $organizationId) {
      ...UserFields
      ...UserOrganizationWithPermissionAndType
    }
  }
  ${UserFields}
  ${UserOrganizationWithPermissionAndType}
`;

export const UpsertUserOrganizationCards = gql`
  mutation upsertUserOrganizationCards($organizationId: ID!, $cards: UserOrganizationCardsUpsert!) {
    upsertUserOrganizationCards(organizationId: $organizationId, cards: $cards) {
      ...UserOrganizationCards
    }
  }
  ${UserOrganizationCards}
`;

export const UpsertUserPermissions = gql`
  mutation upsertUser($user: UserUpsert!, $organizationId: ID!) {
    upsertUser(user: $user, organizationId: $organizationId) {
      ...UserOrganizationWithPermissionAndType
    }
  }
  ${UserOrganizationWithPermissionAndType}
`;

export const GetCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      ...UserFields
    }
  }
  ${UserFields}
`;

export const GetCurrentUserWithOrganizationPermission = gql`
  query getCurrentUserWithOrgPermissions {
    getCurrentUser {
      ...UserFields
      ...UserOrganizationWithPermissionAndType
    }
  }
  ${UserFields}
  ${UserOrganizationWithPermissionAndType}
`;

export const GetCurrentUserBasicFields = gql`
  query getCurrentUserBasicFields {
    getCurrentUser {
      id
      name
      email
      cognito_id
      global_admin
    }
  }
`;

export const GetCurrentUserWithOrganization = gql`
  query getCurrentUserWithOrganization {
    getCurrentUser {
      ...UserFields
      organization {
        id
        organization_id
        organization_administrator
        enable_offline
        permission {
          id
          category_name_key
          name_key
          denied
        }
      }
    }
  }
  ${UserFields}
`;
