import React, { useState } from 'react';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { Form, Input } from 'antd';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { hisaIdFieldName, hisaFirstNameFieldName, hisaLastNameFieldName } from '../../../constants/fields/userFields';
import { translations } from '../../../constants/translations';
import { Info, Organization } from '../../../graph/types';
import { UserInfoType } from '../../../constants/referenceData/userReferenceData';
import { Store } from 'antd/es/form/interface';
import { useGetHisaValidation } from '../../../hooks/ajax/user/userHooks';
import { ErrorText } from '../../../globalStyles.style';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { getRequiredRule } from '../../../util/forms';
import { isMatch } from 'lodash';

export type HisaValidationData = {
  id: string;
  firstName: string;
  lastName: string;
};

type HisaModalProps = {
  setHisaModalOpen: (isOpen: boolean) => void;
  initialInfoValues: Record<number, Info | undefined>;
  organization: Organization;
  handleFinish: (hisaValidationData: HisaValidationData) => void;
};

export const HisaModal: React.FC<HisaModalProps> = ({
  setHisaModalOpen,
  initialInfoValues,
  organization,
  handleFinish,
}) => {
  const { validation, validationLoading, getValidation } = useGetHisaValidation();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const initialValues = {
    [hisaFirstNameFieldName]: initialInfoValues[UserInfoType.UINFO_HISAFNAME]?.value,
    [hisaLastNameFieldName]: initialInfoValues[UserInfoType.UINFO_HISALNAME]?.value,
    [hisaIdFieldName]: initialInfoValues[UserInfoType.UINFO_HISAID]?.value,
  };

  const handleHisaValidation = async (values: Store) => {
    const hisaValidationData = {
      id: values[hisaIdFieldName].replace(/-/g, ''),
      firstName: values[hisaFirstNameFieldName],
      lastName: values[hisaLastNameFieldName],
    };
    setIsSaving(true);

    try {
      const { data } = await getValidation({
        variables: {
          organizationId: organization.id,
          practiceId: organization?.default_practice_id,
          validate: hisaValidationData,
        },
      });
      if (data?.getHISAVetValidation.isValidName && data.getHISAVetValidation.isValidNumber) {
        await handleFinish(hisaValidationData);
        setHisaModalOpen(false);
      }
    } catch (e) {
      showErrorMessage(e.message || translations.shared.saveErrorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  function handleValueChange(_: Store, allValues: Store) {
    setHasChanged(!isMatch(initialValues, allValues));
  }

  return (
    <ModalWithCloseConfirm
      open
      title={translations.viewUserPage.hisaModal.title}
      onOk={form.submit}
      onCancel={() => setHisaModalOpen(false)}
      isSaving={validationLoading || isSaving}
      okButtonProps={{
        disabled: !hasChanged || validationLoading || isSaving,
      }}
    >
      <Form
        initialValues={initialValues}
        onFinish={handleHisaValidation}
        form={form}
        autoComplete='off'
        labelCol={{ span: 5 }}
        onValuesChange={handleValueChange}
      >
        <MaxLengthFormItem
          maxLength={255}
          label={translations.viewUserPage.hisaModal.fields.id}
          name={hisaIdFieldName}
          rules={[getRequiredRule(translations.viewUserPage.hisaModal.fields.id)]}
          extra={
            <>
              {validation && !validation.isValidNumber && (
                <ErrorText>{translations.viewUserPage.hisaModal.errors.id}</ErrorText>
              )}
            </>
          }
        >
          <Input />
        </MaxLengthFormItem>
        <MaxLengthFormItem
          maxLength={255}
          label={translations.viewUserPage.hisaModal.fields.firstName}
          name={hisaFirstNameFieldName}
          rules={[getRequiredRule(translations.viewUserPage.hisaModal.fields.firstName)]}
          extra={
            <>
              {validation && !validation.isValidName && (
                <ErrorText>{translations.viewUserPage.hisaModal.errors.name}</ErrorText>
              )}
            </>
          }
        >
          <Input />
        </MaxLengthFormItem>
        <MaxLengthFormItem
          maxLength={255}
          label={translations.viewUserPage.hisaModal.fields.lastName}
          name={hisaLastNameFieldName}
          rules={[getRequiredRule(translations.viewUserPage.hisaModal.fields.lastName)]}
          extra={
            <>
              {validation && !validation.isValidName && (
                <ErrorText>{translations.viewUserPage.hisaModal.errors.name}</ErrorText>
              )}
            </>
          }
        >
          <Input />
        </MaxLengthFormItem>
      </Form>
    </ModalWithCloseConfirm>
  );
};
