import React, { ReactNode, useEffect, useState } from 'react';
import { Form } from 'antd';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { FileData } from '../../util/fileUpload';
import { FileUpload } from '../FileUpload/FileUpload';
import { recipientsFieldName, StandardEmailFormFields, subjectFieldName } from './StandardEmailFormFields';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import { Loading } from '../Loading/Loading';
import { Store } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { ColProps } from 'antd/lib/col';
import { defaults } from 'lodash';
import { Attachment, AttachmentItem } from './EmailModal';
import { JobStatus } from '../JobStatus/JobStatus';
import { Job } from '../../graph/types';
import { useSendEmail } from '../../hooks/ajax/email/emailHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { formatStringWithTemplatedFieldsForStatements } from '../../util/emailUtil';
import { getPracticeSetting } from '../../pages/Contacts/ViewContact/statementUtils';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface EmailModalWithJobProps {
  layout?: { labelCol: ColProps; wrapperCol: ColProps };
  title?: string;
  visible: boolean;
  onClose: () => void;
  initialValues?: Store;
  attachments?: string[];
  loading?: boolean;
  extraFormFields?: ReactNode;
  onAttachmentsFetch?: (
    values: Store,
    onFinishCallback: (values: Store | undefined, result: Attachment[]) => void
  ) => void;
  staticContactOptions?: { value: string; label: string }[];
  onChange?: (changedValue: Store, formInstance: FormInstance) => void;
  dates?: { start: string; end: string };
  jobId?: string;
  handleCloseJobStatusModal: (message?: string) => void;
  handleJobFinish?: (job: Job) => Promise<void>;
  contactName?: string;
}

const attachmentsFieldName = 'attachment_list';

export const EmailModalWithJob: React.FC<EmailModalWithJobProps> = ({
  onClose,
  onAttachmentsFetch,
  jobId,
  handleCloseJobStatusModal,
  handleJobFinish,
  dates,
  contactName,
  ...props
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [bodyText, setBodyText] = useState<string>('');
  const [attachmentsList, setAttachmentsList] = useState<FileData[]>([]);
  const [sendEmail] = useSendEmail();
  const [form] = Form.useForm();
  const { state } = useOrganizationContext();
  const practice = defaultPractice(state);
  const dateFormatSetting = getPracticeSetting(PracticeSettingsNameKey.LocaleDate, practice);

  const handleValueChange = (changedValue: Store) => {
    setHasChanges(true);
    props.onChange?.(changedValue, form);
  };

  useEffect(() => {
    if (form) {
      const subject = form.getFieldValue(subjectFieldName);
      if (subject && dates?.start && dates?.end) {
        const subjectValue = formatStringWithTemplatedFieldsForStatements(
          subject,
          dates.start,
          dates.end,
          contactName,
          dateFormatSetting?.value
        );
        form.setFieldValue(subjectFieldName, subjectValue);
      }
      if (bodyText && dates?.start && dates?.end) {
        const bodyValue = formatStringWithTemplatedFieldsForStatements(
          bodyText,
          dates.start,
          dates.end,
          contactName,
          dateFormatSetting?.value
        );
        setBodyText(bodyValue || bodyText);
      }
    }
  }, [form, dates, bodyText, contactName, dateFormatSetting]);

  const sendEmailHandler = async (values: Store, attachment: Attachment[]) => {
    try {
      const { data } = await sendEmail({
        variables: {
          organizationId,
          practiceId,
          email: {
            recipients: values[recipientsFieldName].join(';'),
            subject: values[subjectFieldName],
            body: bodyText,
            mailFormat: 'html',
            [attachmentsFieldName]: attachment,
          },
        },
      });
      if (data?.sendEmail?.success) {
        showSuccessMessage(translations.emailModal.successMessage);
        handleClose();
      } else {
        showErrorMessage(data?.sendEmail?.message || translations.emailSettingsPage.sendingErrorMessage);
        setIsSaving(false);
      }
    } catch (e) {
      showErrorMessage(translations.emailSettingsPage.sendingErrorMessage);
      setIsSaving(false);
    }
  };

  const onFinish = (values: Store) => {
    if (onAttachmentsFetch) {
      setIsSaving(true);
      onAttachmentsFetch(values, () => sendEmailHandler);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    setHasChanges(false);
    form.resetFields();
    setBodyText('');
    setAttachmentsList([]);
    onClose();
  };

  const onAttachmentRemove = (filePointer: string) => {
    setAttachmentsList(attachmentsList.filter((attachment) => attachment.pointer !== filePointer));
  };

  const onAttachmentUpload = (fileData: FileData) => {
    setHasChanges(true);
    setAttachmentsList([...attachmentsList, fileData]);
  };

  return (
    <ModalWithCloseConfirm
      width={700}
      title={props.title ?? translations.emailModal.title}
      onCancel={handleClose}
      open={props.visible}
      isSaving={isSaving}
      changesMade={hasChanges}
      okText={translations.emailModal.sendButtonText}
      onOk={form.submit}
    >
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <Form
            {...(props.layout ?? layout)}
            onFinish={onFinish}
            onValuesChange={handleValueChange}
            form={form}
            initialValues={defaults(props.initialValues, { [recipientsFieldName]: [] })}
            validateTrigger={'onBlur'}
          >
            {props.extraFormFields}

            <StandardEmailFormFields
              bodyText={bodyText}
              setBodyText={setBodyText}
              staticContactOptions={props.staticContactOptions}
            />

            {props.attachments ? (
              props.attachments.length > 0 && (
                <Form.Item label={translations.emailModal.fixedAttachmentLabel}>
                  {props.attachments.map((attachment, key) => (
                    <AttachmentItem key={key}>{attachment}</AttachmentItem>
                  ))}
                </Form.Item>
              )
            ) : (
              <Form.Item name={attachmentsFieldName} wrapperCol={{ offset: 4 }}>
                <FileUpload
                  organizationId={organizationId}
                  onRemove={onAttachmentRemove}
                  onUpload={onAttachmentUpload}
                  multiple
                />
              </Form.Item>
            )}
          </Form>
          {jobId && (
            <JobStatus
              jobId={jobId}
              onError={(message) => {
                setIsSaving(false);
                showErrorMessage(message);
              }}
              closeModal={handleCloseJobStatusModal}
              onCompletion={handleJobFinish}
            />
          )}
        </>
      )}
    </ModalWithCloseConfirm>
  );
};
