import React, { useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import dayjs from 'dayjs';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import { getRequiredRule } from '../../util/forms';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetInvoiceWithAllContacts } from '../../hooks/ajax/invoice/invoiceHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import {
  getInvoiceContactOptionsAndPrimaryContact,
  getInvoiceReportAsEmailAttachment,
} from '../../pages/Invoices/invoiceReportUtils';
import { bodyFieldName, recipientsFieldName, subjectFieldName } from './StandardEmailFormFields';
import { Store } from 'antd/lib/form/interface';
import { EmailModal } from './EmailModal';
import { FormInstance } from 'antd/lib/form';
import { getPracticeSetting } from '../../pages/Contacts/ViewContact/statementUtils';
import { upsertDateFormat } from '../../constants/formats';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';
import { formatStringWithTemplatedFieldsForInvoices } from '../../util/emailUtil';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';
import { generateInvoiceReportAttachment } from '../../pages/Invoices/invoicesUtils';

interface EmailModalForInvoiceReportProps {
  onClose: () => void;
  invoiceId: string;
  footer?: string;
}

const contactIdFieldName = 'contactId';

export const EmailModalForInvoiceReport: React.FC<EmailModalForInvoiceReportProps> = ({
  onClose,
  invoiceId,
  footer: customFooter,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();

  const { invoice, invoiceLoading } = useGetInvoiceWithAllContacts({ invoiceId, organizationId });

  const useActiveReportsVersion = useLDFlag(LDFlagNames.ActiveReportsInvoice);

  const { contactOptions, primaryContactId, invoiceContactEmailOptions } = useMemo(
    () => getInvoiceContactOptionsAndPrimaryContact(invoice),
    [invoice]
  );
  const { state } = useOrganizationContext();

  const practice = defaultPractice(state);
  const { displayCurrency, displayDate } = useCurrencyAndDateSetting();

  const bodySetting = getPracticeSetting(PracticeSettingsNameKey.InvoiceBody, practice);
  const subjectSetting = getPracticeSetting(PracticeSettingsNameKey.InvoiceSubject, practice);
  const footerSetting = getPracticeSetting(PracticeSettingsNameKey.InvoiceFooter, practice);
  const dateFormatSetting = getPracticeSetting(PracticeSettingsNameKey.LocaleDate, practice);

  const footer = (customFooter ?? footerSetting?.value) || ' '; // The crystal report is expecting this empty space, don't remove it!
  const subject = useMemo(
    () =>
      formatStringWithTemplatedFieldsForInvoices(
        subjectSetting?.value,
        invoice?.contact_names?.[0],
        invoice?.date,
        dateFormatSetting?.value
      ) || '',
    [invoice, subjectSetting, dateFormatSetting]
  );
  const body = useMemo(
    () =>
      formatStringWithTemplatedFieldsForInvoices(
        bodySetting?.value,
        invoice?.contact_names?.[0],
        invoice?.date,
        dateFormatSetting?.value
      ) || '',
    [invoice, bodySetting, dateFormatSetting]
  );

  const fileName = `Invoice_${invoice?.number}.pdf`;

  const getEmailFromInvoiceContact = useCallback(
    (contactId?: string) =>
      invoice?.contact?.find((invoiceContact) => invoiceContact.contact_id === contactId)?.contact_email,
    [invoice]
  );

  const initialValues = {
    [subjectFieldName]: subject ?? `Invoice - ${invoice?.number}`,
    [recipientsFieldName]: getEmailFromInvoiceContact(primaryContactId)
      ? [getEmailFromInvoiceContact(primaryContactId)]
      : [],
    [contactIdFieldName]: contactOptions?.find((option) => option.value === primaryContactId)?.value,
    [bodyFieldName]: body ?? '',
  };

  const handleValueChange = (changedValue: Store, form: FormInstance) => {
    if (changedValue[contactIdFieldName]) {
      const selectedContactEmail = getEmailFromInvoiceContact(changedValue[contactIdFieldName]);
      form.setFieldsValue({ [recipientsFieldName]: selectedContactEmail ? [selectedContactEmail] : undefined });
    }
  };

  const replaceTags = (str: string) => {
    const formattedDate = dayjs(invoice?.date).format(upsertDateFormat);
    let replacement;
    replacement = str.replace('{InvoiceDate}', formattedDate);
    replacement = replacement.replace('{ContactName}', invoice?.contact_name ?? '');
    return replacement;
  };

  const fetchAttachments = async (formValues: Store) => {
    if (!invoice) {
      throw new Error(translations.shared.missingInvoice);
    }

    try {
      const invoiceReportAsEmailAttachment = useActiveReportsVersion
        ? await generateInvoiceReportAttachment(
            {
              organizationId,
              practiceId,
              invoiceId,
              contactId: formValues[contactIdFieldName],
              footer,
            },
            fileName,
            displayCurrency,
            displayDate
          )
        : await getInvoiceReportAsEmailAttachment({
            invoiceId: invoice.id,
            contactId: formValues[contactIdFieldName],
            request: { practiceId, organizationId, fileName },
            footer,
          });

      return [invoiceReportAsEmailAttachment];
    } catch (e) {
      throw new Error(e.message ?? translations.shared.missingInvoice);
    }
  };

  return (
    <EmailModal
      title={translations.emailModalForInvoiceReport.title}
      loading={invoiceLoading}
      visible
      onClose={onClose}
      extraFormFields={
        <Form.Item
          name={contactIdFieldName}
          label={translations.invoiceReport.printInvoiceReportModal.contactFieldLabel}
          rules={[getRequiredRule(translations.invoiceReport.printInvoiceReportModal.contactFieldLabel)]}
        >
          <Select options={contactOptions} />
        </Form.Item>
      }
      attachments={[fileName]}
      initialValues={initialValues}
      onAttachmentsFetch={fetchAttachments}
      onChange={handleValueChange}
      staticContactOptions={invoiceContactEmailOptions}
      replaceTags={replaceTags}
    />
  );
};
