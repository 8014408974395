import { Col, Collapse, Input, Row } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { translations } from '../../constants/translations';
import { Maybe, OrganizationSubscriptionResult } from '../../graph/types';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { SubscriptionChargesInfo } from '../SubscriptionChargesInfo/SubscriptionChargesInfo';

const ErrorRow = styled(Row)`
  font-weight: bold;
  font-size: smaller;
  color: red;
`;

const SuccessRow = styled(Row)`
  font-weight: bold;
  font-size: smaller;
  color: green;
`;

const UrlRow = styled(Row)`
  margin-top: 20px;
`;

const HowToUseTitle = styled.p`
  font-weight: bold;
  font-size: smaller;
`;

const HowToUsePanelHeader = styled.span`
  font-weight: bold;
  font-size: smaller;
`;

const HowToUsePanelDescription = styled.p`
  font-size: smaller;
  white-space: pre-wrap;
`;

const { Panel } = Collapse;

export const INPUT_DATA_TEST_ID = 'inputDataTestId';

export interface PaymentPortalConfigurationModalProps {
  disabled: boolean;
  currentUrlSuffix?: string;
  subscription?: OrganizationSubscriptionResult;
  onClose: () => void;
  onSaveSuffix: (suffix: string) => Promise<Maybe<boolean> | undefined>;
  onValidateSuffix: (suffix: string) => Promise<Maybe<boolean> | undefined>;
}

export const PaymentPortalConfigurationModal: React.FC<PaymentPortalConfigurationModalProps> = ({
  disabled,
  currentUrlSuffix,
  subscription,
  onSaveSuffix,
  onValidateSuffix,
  onClose,
}: PaymentPortalConfigurationModalProps) => {
  const [urlSuffix, setUrlSuffix] = useState<string>(currentUrlSuffix ?? '');
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayValidationError, setDisplayValidationError] = useState<boolean>(false);
  const [displayValidationSuccess, setDisplayValidationSuccess] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    // Reset validation result when changing input
    setDisplayValidationError(false);
    setDisplayValidationSuccess(false);
    setValidated(false);
    setUrlSuffix(e.target.value);
  };

  const resolvePortalUrl = () =>
    urlSuffix ? `pmt-${urlSuffix}.software.vet` : translations.paymentPortalConfigurationModal.noSuffixMessage;

  const resolvePortalHref = () => `https://pmt-${urlSuffix ? urlSuffix : ''}.software.vet`;

  const handleValidate = async () => {
    setLoading(true);
    setDisplayValidationError(false);
    setDisplayValidationSuccess(false);
    const isValid = await onValidateSuffix(urlSuffix);
    if (!isValid) {
      setDisplayValidationError(true);
    } else {
      setDisplayValidationSuccess(true);
    }
    setValidated(isValid || false);
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    setDisplayValidationError(false);
    setDisplayValidationSuccess(false);
    const isValid = await onSaveSuffix(urlSuffix);
    if (!isValid) {
      setDisplayValidationError(true);
    }
    setValidated(isValid || false);
    setLoading(false);
  };

  return (
    <ModalWithCloseConfirm
      title={translations.paymentPortalConfigurationModal.title}
      open
      onCancel={onClose}
      onOk={validated ? handleSave : handleValidate}
      okText={
        validated
          ? translations.paymentPortalConfigurationModal.saveButtonText
          : translations.paymentPortalConfigurationModal.validateButtonText
      }
      okButtonProps={{ disabled: loading || !urlSuffix || disabled }}
      isSaving={loading}
      savingMessage={translations.loadingComponent.loading}
      width={500}
    >
      {displayValidationError && (
        <ErrorRow>
          <Col offset={2} span={20}>
            <p>{translations.paymentPortal.messages.errors.validationError}</p>
          </Col>
        </ErrorRow>
      )}
      {displayValidationSuccess && (
        <SuccessRow>
          <Col offset={2} span={20}>
            <p>{translations.paymentPortal.messages.validationSuccess}</p>
          </Col>
        </SuccessRow>
      )}
      <Row>
        <Col offset={2} span={20}>
          <Input
            data-testid={INPUT_DATA_TEST_ID}
            addonBefore='pmt-'
            addonAfter='.software.vet'
            value={urlSuffix}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </Col>
      </Row>
      <UrlRow>
        <Col offset={2} span={20}>
          <p>
            {translations.paymentPortalConfigurationModal.url}: {resolvePortalUrl()}
          </p>
        </Col>
      </UrlRow>
      <Row>
        <Col offset={2} span={20}>
          <p>{translations.paymentPortalConfigurationModal.availabilityMessage}</p>
        </Col>
      </Row>
      <Row>
        <Col offset={2} span={20}>
          <HowToUseTitle>{translations.paymentPortalConfigurationModal.howToUse.title}:</HowToUseTitle>
          <Collapse accordion>
            <Panel
              header={
                <HowToUsePanelHeader>
                  {translations.paymentPortalConfigurationModal.howToUse.iframe.header}
                </HowToUsePanelHeader>
              }
              key='iframe-howto'
            >
              <HowToUsePanelDescription>
                {translations.paymentPortalConfigurationModal.howToUse.iframe.description(resolvePortalHref())}
              </HowToUsePanelDescription>
            </Panel>
            <Panel
              header={
                <HowToUsePanelHeader>
                  {translations.paymentPortalConfigurationModal.howToUse.html.header}
                </HowToUsePanelHeader>
              }
              key='html-howto'
            >
              <HowToUsePanelDescription>
                {translations.paymentPortalConfigurationModal.howToUse.html.description(resolvePortalHref())}
              </HowToUsePanelDescription>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      {validated && (
        <Row>
          <Col offset={2} span={20}>
            <SubscriptionChargesInfo subscription={subscription} />
          </Col>
        </Row>
      )}
    </ModalWithCloseConfirm>
  );
};
