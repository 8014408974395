import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import { RunMode } from '../types';
import { translations } from '../../../constants/translations';
import { displayDateAsString } from '../../../constants/formats';
import { RedWarning } from './TopInputControls.styles';

type Props = {
  afterDate: Dayjs;
  beforeDate: Dayjs;
  contacts: number;
  runMode: RunMode;
};

const DATE_FORMAT = 'MMMM Do YYYY';

const RunMessage = ({ runMode, afterDate, beforeDate, contacts }: Props) => {
  const mainText: { mainMessage: string; additionalMessage?: string } = useMemo(() => {
    const isRunGreaterThanNinetyDaysInFuture =
      beforeDate.diff(new Date(), 'days') > 90 &&
      (afterDate.isSame(new Date(), 'day') || beforeDate.isAfter(new Date()));

    const mappedRunModes = {
      [RunMode.PRINT]: {
        mainMessage: translations.reminderRuns.popconfirm.run.print(
          displayDateAsString(afterDate, DATE_FORMAT),
          displayDateAsString(beforeDate, DATE_FORMAT),
          contacts
        ),
        additionalMessage: isRunGreaterThanNinetyDaysInFuture
          ? translations.reminderRuns.popconfirm.run.warning
          : undefined,
      },
      [RunMode.PRINT_AND_EMAIL]: {
        mainMessage: translations.reminderRuns.popconfirm.run.printAndEmail(
          displayDateAsString(afterDate, DATE_FORMAT),
          displayDateAsString(beforeDate, DATE_FORMAT),
          contacts
        ),
        additionalMessage: isRunGreaterThanNinetyDaysInFuture
          ? translations.reminderRuns.popconfirm.run.warning
          : undefined,
      },
      [RunMode.PRINT_OR_EMAIL]: {
        mainMessage: translations.reminderRuns.popconfirm.run.printOrEmail(
          displayDateAsString(afterDate, DATE_FORMAT),
          displayDateAsString(beforeDate, DATE_FORMAT),
          contacts
        ),
        additionalMessage: isRunGreaterThanNinetyDaysInFuture
          ? translations.reminderRuns.popconfirm.run.warning
          : undefined,
      },
      [RunMode.CANCEL]: {
        mainMessage: translations.reminderRuns.popconfirm.run.cancel(
          displayDateAsString(afterDate, DATE_FORMAT),
          displayDateAsString(beforeDate, DATE_FORMAT),
          contacts
        ),
        additionalMessage: isRunGreaterThanNinetyDaysInFuture
          ? translations.reminderRuns.popconfirm.run.cancelAdditional
          : undefined,
      },
      [RunMode.COMPLETE]: {
        mainMessage: translations.reminderRuns.popconfirm.run.complete(
          displayDateAsString(afterDate, DATE_FORMAT),
          displayDateAsString(beforeDate, DATE_FORMAT),
          contacts
        ),
        additionalMessage: isRunGreaterThanNinetyDaysInFuture
          ? translations.reminderRuns.popconfirm.run.completeAdditional
          : undefined,
      },
    };
    return mappedRunModes[runMode];
  }, [runMode, afterDate, beforeDate, contacts]);

  return (
    <Wrapper>
      {mainText?.mainMessage && <p>{mainText?.mainMessage}</p>}
      {mainText?.additionalMessage && <RedWarning>{mainText?.additionalMessage}</RedWarning>}
    </Wrapper>
  );
};

export default RunMessage;

const Wrapper = styled.div`
  max-width: 22rem;
`;
