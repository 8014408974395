import { Button, Popconfirm, Space } from 'antd';
import { RenderedCell } from 'rc-table/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { ID_FOR_OBJECT_CREATION } from '../../classes/upsertGenerators/commonUpsertConstants';
import { translations } from '../../constants/translations';
import { DropdownButtonWithMenu } from '../DropdownButtonWithMenu/DropdownButtonWithMenu';
import {
  BasicColumnType,
  TableWithCustomFiltering,
  TableWithCustomFilteringType,
} from '../TableWithCustomFiltering/TableWithCustomFiltering';
import './TableWithInlineEdit.less';

interface TableWithInlineEditType<T> extends TableWithCustomFilteringType<T> {
  onSave: (data: T) => Promise<boolean> | boolean;
  onDelete?: (data: T) => void;
  onEditModeChange?: () => void;
  columns: ColumnWithEditableField<T>[];
  hideAddButton: boolean;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  deleteConfirmationMessage?: string;
}

export interface ColumnWithEditableField<T> extends BasicColumnType<T> {
  renderEdit?: (
    onChange: (key: string, value: string) => void
  ) => (value: any, record: T, index: number) => React.ReactNode | RenderedCell<T>;
}

export const TableWithInlineEdit = <T extends Record<string, unknown>>({
  dataSource: initialDataSource,
  columns: initialColumns,
  onSave,
  onDelete,
  onEditModeChange,
  hideAddButton = false,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  deleteConfirmationMessage,
  ...props
}: TableWithInlineEditType<T>) => {
  const [editingEntry, setEditingEntry] = useState<T | null>(null);
  const [deletableId, setDeletableId] = useState<string | null>(null);

  const handleRowClick = (row: T) => {
    if (!editingEntry && !deletableId) {
      setEditable(row);
    }
  };

  const setEditable = useCallback(
    (entry: T | null) => {
      setEditingEntry(entry);
      onEditModeChange?.();
    },
    [onEditModeChange, setEditingEntry]
  );

  const handleEdit = useCallback(
    async (dataEntry: T) => {
      const success = await onSave(dataEntry);
      if (success) {
        setEditable(null);
      }
    },
    [onSave, setEditable]
  );

  const isNewEntry = (data: T) => data.id === ID_FOR_OBJECT_CREATION;

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(editingEntry !== null);
    }
  }, [handleIsEditing, editingEntry]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      setEditable(null);
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, setEditable, setShouldResetOnTabChange]);

  const renderActionCell = (_: any, dataEntry: T) => {
    if (editingEntry && editingEntry.id === dataEntry.id) {
      return (
        <Space direction={'horizontal'}>
          <Button type='link' onClick={() => handleEdit(editingEntry)}>
            {isNewEntry(dataEntry) ? translations.shared.addButtonText : translations.shared.saveButtonText}
          </Button>
          <Button type='link' onClick={() => setEditable(null)}>
            {translations.shared.cancelButtonText}
          </Button>
        </Space>
      );
    }

    const onEditClick = () => {
      setEditable(dataEntry);
    };

    const onDeleteClick = (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      setDeletableId(dataEntry.id as string);
    };

    const editButtonMenu = {
      title: translations.shared.editButtonText,
      onClick: onEditClick,
      disabled: !!editingEntry,
    };

    const deleteButtonMenu = {
      title: translations.shared.deleteButtonText,
      onClick: onDeleteClick,
      disabled: !!editingEntry,
    };

    const actionProps = [editButtonMenu];

    if (onDelete) {
      actionProps.push(deleteButtonMenu);
    }

    return (
      <Popconfirm
        open={dataEntry.id === deletableId}
        onOpenChange={(_, e) => {
          e?.stopPropagation();
          setDeletableId(null);
        }}
        placement={'topLeft'}
        title={deleteConfirmationMessage ?? translations.referenceData.deleteReminderText}
        okText={translations.shared.popconfirm.ok}
        cancelText={translations.shared.popconfirm.cancel}
        onConfirm={() => onDelete?.(dataEntry)}
      >
        <DropdownButtonWithMenu menuItemProps={actionProps} />
      </Popconfirm>
    );
  };

  const actionsColumn = {
    title: translations.patientsPage.columns.actions,
    dataIndex: 'actions',
    key: 'actions',
    render: renderActionCell,
    columnName: 'actions',
    width: 200,
  };

  const columns = initialColumns
    .map((c) => ({
      ...c,
      render: (value: string, data: T, index: number) => {
        if (data.id === editingEntry?.id && c.renderEdit) {
          return c.renderEdit((key: string, value: string) => {
            setEditingEntry(
              (prev) =>
                ({
                  ...prev,
                  [key]: value,
                } as T)
            );
          })(value, data, index);
        }

        return c.render?.(value, data, index) || value;
      },
    }))
    .concat([actionsColumn]);

  const dataSource =
    editingEntry && isNewEntry(editingEntry) ? [editingEntry, ...(initialDataSource ?? [])] : initialDataSource;

  return (
    <>
      <div className='cas-table-with-inline-edit header'>
        {!hideAddButton && (
          <Button
            onClick={() => {
              if (!editingEntry) {
                setEditable({ id: ID_FOR_OBJECT_CREATION } as unknown as T);
              }
            }}
            disabled={!!editingEntry}
          >
            {translations.referenceData.addNewEntry}
          </Button>
        )}
      </div>
      <TableWithCustomFiltering
        {...props}
        tableKey={props.tableKey}
        dataSource={dataSource}
        columns={columns}
        loading={props.loading}
        rowKey={props.rowKey}
        onChange={props.onChange}
        onRowClick={handleRowClick}
      />
    </>
  );
};
