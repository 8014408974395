import {
  Maybe,
  Mutation,
  MutationUpsertPatientArgs,
  OrganizationDto,
  Patient,
  PatientServiceValidation,
  Service,
} from '../../graph/types';
import { PatientInfoType } from '../../constants/referenceData/patientReferenceData';
import { getInfoUpsert } from '../../classes/upsertGenerators/infoMappingUtil';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import { ROATypeId, translations } from '../../constants/translations';
import { PatientFieldsRequirement } from '../PatientModalContent/PatientModalContent';
import { hisaRequirementsConfig, hisaValidationPropertyNames } from '../../constants/hisaRequirements';
import { HisaFieldsRequirement } from './HisaRequirementsModal/HisaRequirementsContent';

export const getMicrochipUpsert = (
  newMicrochipValue = '',
  organization?: OrganizationDto | null,
  patient?: Patient | null
) => {
  const microchipInfoType = organization?.ref_patient?.info_type.find(
    ({ name_key }) => name_key === PatientInfoType.Microchip
  );

  return {
    id: patient?.id,
    info: getInfoUpsert(microchipInfoType?.id ?? '', newMicrochipValue, patient?.info ?? undefined),
  };
};

export function getPatientMicrochipUpsertOptions(
  newMicrochipValue = '',
  organization?: OrganizationDto | null,
  patient?: Patient | null
): MutationFunctionOptions<Pick<Mutation, 'upsertPatient'>, MutationUpsertPatientArgs> {
  const patientUpsert = getMicrochipUpsert(newMicrochipValue, organization, patient);

  return {
    variables: { organizationId: organization?.id || '', patient: patientUpsert },
  };
}

export const getRabiesTagUpsert = (
  newRabiesTagValue = '',
  organization?: OrganizationDto | null,
  patient?: Patient | null
) => {
  const rabiesTagInfoType = organization?.ref_patient?.info_type.find(
    ({ name_key }) => name_key === PatientInfoType.RabiesTag
  );

  return {
    id: patient?.id,
    info: getInfoUpsert(rabiesTagInfoType?.id ?? '', newRabiesTagValue, patient?.info ?? undefined),
  };
};
export function getPatientRabiesTagUpsertOptions(
  newRabiesTagValue = '',
  organization?: OrganizationDto | null,
  patient?: Patient | null
): MutationFunctionOptions<Pick<Mutation, 'upsertPatient'>, MutationUpsertPatientArgs> {
  const patientUpsert = getRabiesTagUpsert(newRabiesTagValue, organization, patient);

  return {
    variables: { organizationId: organization?.id || '', patient: patientUpsert },
  };
}

export const ROAOptions = [
  { description: translations.shared.roaModal.intravenous, value: ROATypeId.Intravenous },
  { description: translations.shared.roaModal.intramuscular, value: ROATypeId.Intramuscular },
  { description: translations.shared.roaModal.oral, value: ROATypeId.Oral },
  { description: translations.shared.roaModal.topical, value: ROATypeId.Topical },
  { description: translations.shared.roaModal.other, value: ROATypeId.Other },
];

const isServicePropertyRequired = (
  property: keyof PatientServiceValidation,
  validation?: Maybe<PatientServiceValidation[]>
) => validation?.some((v) => v[property]) ?? false;

export const getPatientLinkedToHisa = (patient?: Maybe<Patient>) =>
  patient?.info?.find((i) => i.name_key === PatientInfoType.HISA);

const getRequiredFields = (validation?: Maybe<PatientServiceValidation[]>, patient?: Maybe<Patient>) => {
  const microchip = patient?.info?.find((infoItem) => infoItem.name_key === PatientInfoType.Microchip);
  const breedRequired = isServicePropertyRequired('requires_breed', validation);
  const genderRequired = isServicePropertyRequired('requires_gender', validation);
  const dobRequired = isServicePropertyRequired('requires_dob', validation);
  const microchipRequired = isServicePropertyRequired('requires_microchip', validation);

  return {
    requiresBreed: breedRequired && !patient?.breed_id,
    requiresGender: genderRequired && !patient?.gender_id,
    requiresDOB: dobRequired && !patient?.dob,
    requiresMicrochip: microchipRequired && !microchip,
  };
};

export const getRequiredHisaFields = (
  validation?: Maybe<PatientServiceValidation[]>
): Record<keyof typeof hisaRequirementsConfig, boolean> => {
  const results = Object.values(hisaValidationPropertyNames).reduce(
    (acc, key) => ({ ...acc, [key]: isServicePropertyRequired(key, validation) }),
    {} as Record<keyof typeof hisaValidationPropertyNames, boolean>
  );

  return {
    requiresResults: results.requires_results,
    requiresLimbTreated: results.requires_limb_treated,
    requiresDosage: results.requires_dosage,
    requiresConditionTreated: results.requires_condition_treated,
    requiresTestsAndDiagnostics: results.requires_tests_and_diagnostics,
    requiresTreatmentType: results.requires_treatment_type,
    requiresTime: results.requires_time,
    requiresMedicalName: results.requires_medical_name,
    requiresDrugName: results.requires_drug_name,
    requiresDescription: results.requires_description,
    requiresVaccineName: results.requires_vaccine_name,
    requiresStructure: results.requires_structure,
    requiresModality: results.requires_modality,
  };
};

export const getServicePatientRequirements = (service: Service, patient?: Maybe<Patient>, isHisaPatient = false) => {
  const requirements: PatientFieldsRequirement = getRequiredFields(service.patient_validation, patient);

  const hisaRequirements: HisaFieldsRequirement = getRequiredHisaFields(service.patient_validation);

  const hasMissingPatientFields = Object.values(requirements).some(Boolean);

  const patientLinkedToHisa = getPatientLinkedToHisa(patient) || isHisaPatient;

  const hasMissingHisaFields = patientLinkedToHisa && Object.values(hisaRequirements).some(Boolean);

  return {
    missingPatientFields: requirements,
    missingHisaFields: hisaRequirements,
    hasMissingPatientFields,
    hasMissingHisaFields,
  };
};
export const getNameKey = (key: string, patientId?: string, serviceId?: string) =>
  `${key}${patientId ? `_${patientId}` : ''}${serviceId ? `_${serviceId}` : ''}`;
