import { useMemo } from 'react';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { displayAsDate } from '../../../constants/formats';
import {
  RemindersRunProperty,
  remindersRunPropertyNames,
  ReminderBatchDetailProperty,
  reminderBatchPropertyNames,
} from '../../../constants/propertyNames';
import { translations } from '../../../constants/translations';
import { Reminder, ReminderBatchDetail } from '../../../graph/types';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';

const {
  table: { row },
} = translations.reminderRuns;

export const useBasicRemindersRunColumns = (): Record<RemindersRunProperty, CustomColumnType<Reminder>> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(
    () => ({
      contact_name: {
        ...generalFilteringAndSortingSettings,
        title: row.contact,
        dataIndex: remindersRunPropertyNames.contact_name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.contact),
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.contact_name),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.contact_name),
      },
      patient_name: {
        ...generalFilteringAndSortingSettings,
        title: row.patient,
        dataIndex: remindersRunPropertyNames.patient_name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.patient),
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.patient_name),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.patient_name),
      },
      name: {
        ...generalFilteringAndSortingSettings,
        title: row.reminder,
        dataIndex: remindersRunPropertyNames.name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.reminder),
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.name),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.name),
      },
      note: {
        ...generalFilteringAndSortingSettings,
        title: row.note,
        dataIndex: remindersRunPropertyNames.note,
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.note),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.note),
      },
      status_name: {
        ...generalFilteringAndSortingSettings,
        title: row.status,
        dataIndex: remindersRunPropertyNames.status_name,
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.status_name),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.status_name),
      },
      contact_send_type_name: {
        ...generalFilteringAndSortingSettings,
        title: row.preference,
        dataIndex: remindersRunPropertyNames.contact_send_type_name,
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.contact_send_type_name),
        sorter: getStringCompareFunctionFor(remindersRunPropertyNames.contact_send_type_name),
      },
      date: {
        ...generalFilteringAndSortingSettings,
        title: row.dueDate,
        dataIndex: remindersRunPropertyNames.date,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.dueDate),
        onFilter: getOnFilterFunctionFor(remindersRunPropertyNames.date),
        sorter: getDateCompareFunctionFor(remindersRunPropertyNames.date),
        render: (date: string) => displayAsDate(date, dateFormat),
      },
    }),
    [dateFormat]
  );
};

export const useBasicRemindersBatchColumns = (): Record<
  ReminderBatchDetailProperty,
  CustomColumnType<ReminderBatchDetail>
> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(
    () => ({
      contact_name: {
        ...generalFilteringAndSortingSettings,
        title: row.contact,
        dataIndex: reminderBatchPropertyNames.contact_name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.contact),
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.contact_name),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.contact_name),
      },
      patient_name: {
        ...generalFilteringAndSortingSettings,
        title: row.patient,
        dataIndex: reminderBatchPropertyNames.patient_name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.patient),
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.patient_name),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.patient_name),
      },
      reminder_name: {
        ...generalFilteringAndSortingSettings,
        title: row.reminder,
        dataIndex: reminderBatchPropertyNames.reminder_name,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.reminder),
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.reminder_name),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.reminder_name),
      },
      reminder_date: {
        ...generalFilteringAndSortingSettings,
        title: row.dueDate,
        dataIndex: reminderBatchPropertyNames.reminder_date,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(row.dueDate),
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.reminder_date),
        sorter: getDateCompareFunctionFor(reminderBatchPropertyNames.reminder_date),
        render: (date: string) => displayAsDate(date, dateFormat),
      },
      reminder_note: {
        ...generalFilteringAndSortingSettings,
        title: row.note,
        dataIndex: reminderBatchPropertyNames.reminder_note,
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.reminder_note),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.reminder_note),
      },
      reminder_status_name: {
        ...generalFilteringAndSortingSettings,
        title: row.status,
        dataIndex: reminderBatchPropertyNames.reminder_status_name,
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.reminder_status_name),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.reminder_status_name),
      },
      reminder_sent_type_name: {
        ...generalFilteringAndSortingSettings,
        title: row.preference,
        dataIndex: reminderBatchPropertyNames.reminder_sent_type_name,
        onFilter: getOnFilterFunctionFor(reminderBatchPropertyNames.reminder_sent_type_name),
        sorter: getStringCompareFunctionFor(reminderBatchPropertyNames.reminder_sent_type_name),
      },
    }),
    [dateFormat]
  );
};
