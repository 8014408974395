import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export interface AddOnQuickBooksProps {
  isAddOnEnabled?: boolean;
  price?: string;
  onEnable: () => void;
  onConfigure: () => void;
  countryId?: string | null;
}

export const AddOnCardQuickBooks: React.FC<AddOnQuickBooksProps> = ({
  isAddOnEnabled,
  price,
  onEnable,
  onConfigure,
  countryId,
}: AddOnQuickBooksProps) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const handleToggle = () => {
    isAddOnEnabled ? setDisplayAlert(true) : onEnable();
  };

  const buttonProps = {
    label: translations.addOns.cards.quickBooks.label,
    onClick: handleToggle,
    isToggle: true,
    isChecked: isAddOnEnabled,
    popconfirm: {
      title: translations.addOns.cards.quickBooks.cannotDisableMessage,
      okText: translations.shared.okButtonText,
      cancelText: translations.shared.popconfirm.cancel,
      placement: 'top',
      open: displayAlert,
      onConfirm: () => {
        setDisplayAlert(false);
      },
      onCancel: () => {
        setDisplayAlert(false);
      },
    } as PopconfirmProps,
  };

  const configureButtonProps = isAddOnEnabled
    ? {
        label: translations.addOns.cards.quickBooks.configure,
        onClick: onConfigure || (() => undefined),
      }
    : undefined;

  return (
    <SettingsCard
      title={translations.addOns.cards.quickBooks.cardTitle}
      leftButton={buttonProps}
      rightButton={configureButtonProps}
    >
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.quickBooks.text}</TextWithMargin>
        {price && (
          <TextWithMargin isBold>
            {translations.addOns.cards.quickBooks.subText1}{' '}
            <SubscriptionCurrencyFormatter total={Number(price)} countryId={countryId} />{' '}
            {translations.addOns.cards.quickBooks.subText2}
          </TextWithMargin>
        )}
      </FlexContainer>
    </SettingsCard>
  );
};
