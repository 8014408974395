import React, { PropsWithChildren } from 'react';
import './TextEditorPreview.css';

interface TextEditorToPlainTextProps extends PropsWithChildren<unknown> {
  textContent: string;
}

export const TextEditorPreview: React.FC<TextEditorToPlainTextProps> = ({ textContent }) => {
  return (
    <div className={'TextEditorPreview_container'}>
      <div
        className={'TextEditorPreview_text'}
        style={{
          overflowWrap: 'anywhere',
          whiteSpace: 'pre-wrap',
        }}
      >
        {textContent}
      </div>
      <div className={'TextEditorPreview_gradient'} />
    </div>
  );
};
