import React from 'react';
import { translations } from '../../constants/translations';
import { SettingsTable } from './SettingsTable/SettingsTable';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';

export const CurrencySettings = () => {
  return (
    <>
      <StyledPageHeaderWithMargin title={translations.currencySettingsPage.title} />
      <SettingsTable />
    </>
  );
};
