import React, { useContext, useEffect } from 'react';
import { SelectPatientModalTable } from './SelectPatientModalTable';
import { Patient } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useSetWarningIfTooManyResults } from '../../hooks/setWarningIfTooManyResults';
import { useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { getFilteredPatients } from './selectPatientModalTableUtil';
import { PatientsContext } from '../../pages/Patients/store/state';
import { setPatientsList } from '../../pages/Patients/store/actions';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';

interface SelectOrganizationPatientTableContainerProps {
  setSelectedPatients: (patient: Patient[]) => void;
  selectedPatients: Patient[];
  searchValue?: string;
  canSelectMultiple?: boolean;
  setShowTooManyResultsWarning: (showWarning: boolean) => void;
  filterAndSortTableValues: ReturnType<typeof useTableResetFilterAndSort>;
  excludedPatientId?: string;
  setPatientTableColumns: (columns: CustomColumnType<Patient>[]) => void;
}

export const SelectOrganizationPatientTableContainer: React.FC<SelectOrganizationPatientTableContainerProps> = ({
  searchValue,
  setSelectedPatients,
  selectedPatients,
  canSelectMultiple,
  setShowTooManyResultsWarning,
  filterAndSortTableValues,
  excludedPatientId,
  setPatientTableColumns,
}: SelectOrganizationPatientTableContainerProps) => {
  const organizationId = useGetOrganizationIdFromRoute();

  const { state, dispatch } = useContext(PatientsContext);
  const { patients, patientsLoading } = useGetPatientsWithSearch(organizationId, searchValue);

  useEffect(() => {
    const filteredPatients = getFilteredPatients(patients ?? [], excludedPatientId);
    dispatch(setPatientsList(filteredPatients));
  }, [patients, dispatch, excludedPatientId]);

  useSetWarningIfTooManyResults(patients ?? [], setShowTooManyResultsWarning);

  return (
    <SelectPatientModalTable
      patients={state.patientsList}
      patientsLoading={patientsLoading}
      setSelectedPatients={setSelectedPatients}
      selectedPatients={selectedPatients}
      canSelectMultiple={canSelectMultiple}
      filterAndSortTableValues={filterAndSortTableValues}
      setPatientTableColumns={setPatientTableColumns}
    />
  );
};
