import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Reference,
  WatchQueryFetchPolicy,
  QueryHookOptions,
  MutationFunctionOptions,
  FetchResult,
  MutationTuple,
} from '@apollo/client';
import escapeRegExp from 'lodash/escapeRegExp';
import {
  Info,
  InfoText,
  Invoice,
  InvoiceUpsert,
  Mutation,
  MutationUpdateServiceRenderedArgs,
  MutationUpsertInvoiceArgs,
  Patient,
  Prescription,
  PrescriptionFlattened,
  ServiceRendered,
  ServiceRenderedUpsert,
  Subscription,
  SubscriptionOnPatientUpsertArgs,
} from '../../../graph/types';
import {
  GetInvoice,
  GetInvoiceContext,
  GetInvoiceContextWithServicesRenderedWithFilter,
  GetInvoices,
  GetInvoicesForPatient,
  GetInvoiceText,
  GetInvoiceWithAllContacts,
  GetPaymentInvoice,
  UpsertInvoice,
  UpsertInvoiceFooter,
  UpsertInvoiceForBillTo,
  UpsertInvoiceForServiceNotes,
  UpsertNewInvoice,
} from '../../../graph/queries/invoices';
import { useDefaultPracticeId } from '../practice/practiceHooks';
import { InvoiceListFields } from '../../../graph/fragments/invoiceFragments';
import { useQueryWithBackendSearch } from '../useQueryWithBackendSearch';
import { useOfflineQuery, useQueryWithElasticSearch } from '../useOfflineQuery';
import { OnInvoiceUpsert } from '../../../graph/subscription/invoice';
import { InvoiceStatusId } from '../../../constants/referenceData/invoiceReferenceData';
import { GetLedgersWithContactTotals } from '../../../graph/queries/ledgers';
import { useGetOrganizationIdFromRoute } from '../../route/routeParameterHooks';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useOfflineAtomicUpdate, useOfflineDelete, useOfflineInsert, useOfflineUpdate } from '../../localDatabaseHooks';
import { RxInvoice } from '../../../services/LocalDatabaseService/schemas/invoiceSchema';
import { getInvoiceInsert, getInvoiceUpdate } from '../../../services/LocalDatabaseService/queries/invoiceQueries';
import { useRxCollection } from 'rxdb-hooks';
import { RxPatient } from '../../../services/LocalDatabaseService/schemas/patientSchema';
import { useSubscription } from '../subscription/subscriptionHooks';
import { UpdateServiceRendered } from '../../../graph/queries/services';
import { useUserContext } from '../../../contexts/user/state';
import { useGetConnectionId } from '../../authHooks';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { ServiceRenderedFieldsWithInfoAndText } from '../../../graph/fragments';
import { RxPrescription } from '../../../services/LocalDatabaseService/schemas/prescriptionSchema';
import { RxPrescriptionFlattened } from '../../../services/LocalDatabaseService/schemas/prescriptionFlattenedSchema';
import { getOfflineId } from '../../../services/LocalDatabaseService/queries/queryUtils';
import { uniqueId } from 'lodash';
import { isUuid } from '../../../util/offline/offlineUtil';
import dayjs from 'dayjs';

const invoiceSort = (a: Invoice, b: Invoice) => {
  if (a.status_id <= InvoiceStatusId.InProgress && b.status_id <= InvoiceStatusId.InProgress) {
    return Date.parse(b.changed_date) - Date.parse(a.changed_date);
  } else if (a.status_id <= InvoiceStatusId.InProgress) {
    return -1;
  } else if (b.status_id <= InvoiceStatusId.InProgress) {
    return 1;
  }

  return Date.parse(b.changed_date) - Date.parse(a.changed_date);
};

export const useGetInvoicesWithSearch = (organizationId: string) => {
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const selector = useMemo(
    () => ({ document: { $regex: new RegExp(escapeRegExp(searchValue) || '.*', 'i') } }),
    [searchValue]
  );
  const searchParams = useMemo(() => {
    let query = '';

    if (statusFilter) {
      query += `document.status_name_key:${statusFilter}`;
    }

    if (searchValue && statusFilter) {
      query += ` AND *${searchValue}*`;
    } else {
      query += searchValue;
    }

    return {
      fields: [
        'document.number',
        'document.status_name_key',
        'document.date',
        'document.patient_name',
        'document.contact_names',
        'document.total',
      ],
      query,
    };
  }, [searchValue, statusFilter]);

  const {
    data,
    loading,
    refetch,
    setSearchTerm: defaultSetSearchTerm,
  } = useQueryWithElasticSearch<'getInvoicesEs'>({
    query: GetInvoices,
    collection: 'invoice',
    variables,
    searchParams,
    options: {
      fetchPolicy: 'cache-and-network',
    },
    adjustQuery: !statusFilter,
    selector: searchValue ? selector : undefined,
    take: statusFilter ? 10000 : undefined,
  });

  const invoices = useMemo(() => data && [...data].sort(invoiceSort), [data]);
  const setSearchTerm = useCallback(
    (s: string) => {
      setSearchValue(s);
      defaultSetSearchTerm(s);
    },
    [defaultSetSearchTerm]
  );

  const onSetFilter = (filter: string) => {
    setStatusFilter(filter);
  };

  return {
    invoices,
    invoicesLoading: loading,
    refetchInvoicesWithCurrentSearch: refetch,
    setSearchTerm,
    setStatusFilter: onSetFilter,
  };
};

export const useGetInvoicesForPatient = (organizationId: string, patientId: string, statusIdBelow: number) => {
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const searchParams = useMemo(
    () => ({
      query: `document.status_id:<${statusIdBelow} AND document.patient_id:${patientId}`,
    }),
    [patientId, statusIdBelow]
  );

  const { data, loading, refetch } = useQueryWithElasticSearch<'getInvoicesEs'>({
    query: GetInvoicesForPatient,
    collection: 'invoice',
    variables,
    searchParams,
    options: {
      fetchPolicy: 'cache-and-network',
    },
    adjustQuery: false,
  });

  const invoices = useMemo(() => data && [...data].sort(invoiceSort), [data]);

  return {
    invoices,
    invoicesLoading: loading,
    refetchInvoices: refetch,
  };
};
export const useGetInvoice = (
  {
    invoiceId,
    organizationId,
  }: {
    invoiceId: string;
    organizationId: string;
  },
  options: QueryHookOptions & {
    subscribe?: boolean;
  }
) => {
  const variables = useMemo(() => ({ filter: { id: invoiceId }, organizationId }), [invoiceId, organizationId]);
  const selector = useMemo(() => ({ _id: invoiceId }), [invoiceId]);
  const connectionId = useGetConnectionId();
  const { data, loading, error, refetch } = useOfflineQuery<'getInvoicesPg'>({
    query: GetInvoice,
    collection: 'invoice',
    options: {
      variables,
      fetchPolicy: options.fetchPolicy,
      nextFetchPolicy: options.nextFetchPolicy,
    },
    selector,
    expectList: true,
  });
  const { record } = useInvoiceUpsertSubscription(invoiceId, !options.subscribe);
  const {
    state: { user },
  } = useUserContext();

  useEffect(() => {
    if (record?.connectionId !== connectionId && options.subscribe) {
      refetch();
    }
  }, [record, options.subscribe, user, refetch, connectionId]);

  return {
    invoice: data?.[0],
    invoiceLoading: loading,
    invoiceError: error,
    invoiceRefetch: refetch,
  };
};

export const useGetInvoiceWithAllContacts = ({
  invoiceId,
  organizationId,
}: {
  invoiceId: string;
  organizationId: string;
}) => {
  const selector = useMemo(() => ({ _id: invoiceId }), [invoiceId]);
  const { data, loading, error } = useOfflineQuery<'getInvoicesPg'>({
    query: GetInvoiceWithAllContacts,
    collection: 'invoice',
    options: {
      variables: { filter: { id: invoiceId }, organizationId },
    },
    selector,
    expectList: true,
  });

  return {
    invoice: data?.[0],
    invoiceLoading: loading,
    invoiceError: error,
  };
};

export const useGetInvoiceContactInfo = (
  {
    invoiceId,
    organizationId,
  }: {
    invoiceId: string;
    organizationId: string;
  },
  options?: { fetchPolicy?: WatchQueryFetchPolicy }
) => {
  const selector = useMemo(() => ({ _id: invoiceId }), [invoiceId]);
  const { data, loading, error, refetch } = useOfflineQuery<'getInvoicesPg'>({
    query: GetPaymentInvoice,
    collection: 'invoice',
    options: {
      variables: { filter: { id: invoiceId }, organizationId },
      fetchPolicy: options?.fetchPolicy,
    },
    selector,
    expectList: true,
  });

  return {
    invoice: data?.[0],
    invoiceLoading: loading,
    invoiceError: error,
    refetchInvoice: refetch,
  };
};

export const useGetInvoiceContext = (organizationId: string, practiceId: string) => {
  const practiceIdNotYetLoaded = practiceId === '';
  const selector = useMemo(
    () => ({ organization_id: organizationId, practice_id: practiceId }),
    [organizationId, practiceId]
  );
  const { data, loading, error } = useOfflineQuery<'getInvoiceContext'>({
    query: GetInvoiceContext,
    collection: 'invoice_context',
    options: {
      variables: { organizationId, practiceId },
      skip: practiceIdNotYetLoaded,
    },
    selector,
  });

  return {
    invoiceContext: data,
    invoiceContextLoading: loading || practiceIdNotYetLoaded,
    invoiceContextError: error,
  };
};

export const useGetInvoiceContextForOffline = (organizationId: string) => {
  const practiceId = useDefaultPracticeId();
  const practiceIdNotYetLoaded = practiceId === '';

  const { data, loading } = useOfflineErrorSkipQuery<'getInvoiceContext'>(GetInvoiceContext, {
    variables: { organizationId, practiceId },
    skip: !organizationId || !practiceId,
  });

  return {
    invoiceContext: data?.getInvoiceContext,
    invoiceContextLoading: loading || practiceIdNotYetLoaded,
  };
};

export const useGetInvoiceContextWithServicesWithSearch = (organizationId: string, searchValue?: string) => {
  const practiceId = useDefaultPracticeId();
  const practiceIdNotYetLoaded = practiceId === '';
  const variables = useMemo(() => ({ organizationId, practiceId }), [organizationId, practiceId]);

  const { data, loading, setSearchTerm } = useQueryWithBackendSearch<'getInvoiceContext'>(
    GetInvoiceContextWithServicesRenderedWithFilter,
    variables,
    searchValue,
    { skip: practiceIdNotYetLoaded }
  );

  return {
    services: data?.getInvoiceContext?.service,
    servicesLoading: loading || practiceIdNotYetLoaded,
    setSearchTerm,
  };
};

export const useInsertInvoice = () => {
  return useOfflineErrorSkipMutation<'upsertInvoice', MutationUpsertInvoiceArgs>(UpsertNewInvoice, {
    update(cache: any, { data }: any) {
      const createdInvoice = data?.upsertInvoice;
      cache.modify({
        fields: {
          getInvoicesPg(invoiceReferences: Reference[] = []) {
            const newInvoiceReference = cache.writeFragment({
              data: createdInvoice,
              fragment: InvoiceListFields,
              fragmentName: 'InvoiceListFields',
            });
            return [newInvoiceReference, ...invoiceReferences];
          },
        },
      });
    },
  });
};

export const useDeleteInvoice = (organizationId: string) => {
  const [deleteInvoice] = useUpdateInvoice();
  return useCallback(
    (invoiceId: string) =>
      deleteInvoice({
        variables: {
          organizationId,
          invoice: { id: invoiceId, void: true },
        },
        update: (cache: any) => {
          cache.evict({
            id: cache.identify({
              __typename: 'Invoice',
              id: invoiceId,
            }),
          });
          cache.gc();
        },
      }),
    [organizationId, deleteInvoice]
  );
};

export const useDeleteServiceRendered = (organizationId: string) => {
  const [deleteInvoice] = useUpdateInvoice();
  const connectionId = useGetConnectionId();
  return useCallback(
    async (
      invoiceId: string,
      serviceRenderedId: string,
      bundledItems?: ServiceRenderedUpsert[],
      options?: Pick<MutationFunctionOptions, 'optimisticResponse'>
    ) => {
      await deleteInvoice({
        variables: {
          organizationId,
          invoice: {
            id: invoiceId,
            item: [
              {
                id: serviceRenderedId,
                void: true,
              },
              ...(bundledItems ? bundledItems : []),
            ],
            connection_id: connectionId,
          },
        },
        optimisticResponse: options?.optimisticResponse,
      });
    },
    [organizationId, deleteInvoice, connectionId]
  );
};

const upsertInvoice =
  (
    upsertInvoiceMutation: (
      options?: MutationFunctionOptions<Pick<Mutation, 'upsertInvoice'>, MutationUpsertInvoiceArgs>
    ) => Promise<FetchResult<Pick<Mutation, 'upsertInvoice'>>>,
    connectionId: string
  ) =>
  (options: MutationFunctionOptions<Pick<Mutation, 'upsertInvoice'>, MutationUpsertInvoiceArgs>) => {
    const upsertWithConnectionId: MutationFunctionOptions<
      Pick<Mutation, 'upsertInvoice'>,
      MutationUpsertInvoiceArgs
    > = {
      ...options,
      onError: (e) => showErrorMessage(e.message || translations.shared.saveErrorMessage),
      variables: {
        ...options?.variables,
        invoice: {
          ...options?.variables?.invoice,
          connection_id: connectionId,
        },
      } as MutationUpsertInvoiceArgs,
    };

    return upsertInvoiceMutation(upsertWithConnectionId);
  };

export const useUpdateInvoice = (invoiceId?: string) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const connectionId = useGetConnectionId();
  const [invoiceUpsert, options] = useOfflineErrorSkipMutation<'upsertInvoice', MutationUpsertInvoiceArgs>(
    UpsertInvoice,
    {
      refetchQueries: (result) =>
        invoiceId && (result.data?.upsertInvoice?.contact_ids ?? []).length > 1
          ? [{ query: GetLedgersWithContactTotals, variables: { organizationId, filter: { invoice_id: invoiceId } } }]
          : [],
      update: (cache: any, { data }: any) => {
        data?.upsertInvoice?.contact_ids?.forEach((contactId: string) => {
          cache.evict({
            id: cache.identify({ __typename: 'Contact', id: contactId }),
          });
        });
        data?.upsertInvoice?.item?.forEach((serviceRendered: ServiceRendered) => {
          const cachedServiceRendered = cache.readFragment({
            id: cache.identify({ __typename: 'ServiceRendered', id: serviceRendered.id }),
            fragment: ServiceRenderedFieldsWithInfoAndText,
            fragmentName: 'ServiceRenderedFieldsWithInfoAndText',
          });
          cachedServiceRendered?.info?.forEach((info: Info) => {
            cache.evict({
              id: cache.identify({ __typename: info.__typename, id: info.id }),
            });
          });
          cachedServiceRendered?.text?.forEach((text: InfoText) => {
            cache.evict({
              id: cache.identify({ __typename: text.__typename, id: text.id }),
            });
          });
        });
      },
    }
  );

  const returnFunction = useCallback(
    (options: MutationFunctionOptions<Pick<Mutation, 'upsertInvoice'>, MutationUpsertInvoiceArgs>) =>
      upsertInvoice(invoiceUpsert, connectionId)(options),
    [connectionId, invoiceUpsert]
  );
  return [returnFunction, options] as MutationTuple<Pick<Mutation, 'upsertInvoice'>, MutationUpsertInvoiceArgs>;
};

export const useUpdateServiceRendered = () => {
  return useOfflineErrorSkipMutation<'updateServiceRendered', MutationUpdateServiceRenderedArgs>(UpdateServiceRendered);
};

export const useUpdateInvoiceWithContactData = () => {
  const connectionId = useGetConnectionId();
  const [invoiceUpsert, options] = useOfflineErrorSkipMutation<'upsertInvoice', MutationUpsertInvoiceArgs>(
    UpsertInvoiceForBillTo
  );
  const returnFunction = upsertInvoice(invoiceUpsert, connectionId);
  return [returnFunction, options] as MutationTuple<Pick<Mutation, 'upsertInvoice'>, MutationUpsertInvoiceArgs>;
};

export const useUpdateInvoiceForServiceNotes = () => {
  const connectionId = useGetConnectionId();
  const [invoiceUpsert] = useOfflineErrorSkipMutation<'upsertInvoice', MutationUpsertInvoiceArgs>(
    UpsertInvoiceForServiceNotes
  );
  const returnFunction = upsertInvoice(invoiceUpsert, connectionId);
  return [returnFunction];
};

export const useUpdateInvoiceFooter = () => {
  const connectionId = useGetConnectionId();
  const [invoiceUpsert] = useOfflineErrorSkipMutation<'upsertInvoice', MutationUpsertInvoiceArgs>(UpsertInvoiceFooter);
  const returnFunction = upsertInvoice(invoiceUpsert, connectionId);
  return [returnFunction];
};

export const useInvoiceUpsertSubscription = (invoiceId: string, skip?: boolean) => {
  const { data, loading, error } = useSubscription<Subscription, SubscriptionOnPatientUpsertArgs>(OnInvoiceUpsert, {
    variables: { id: invoiceId },
    skip: skip || !invoiceId,
  });
  return { record: data?.onInvoiceUpsert, loading, error };
};

export const useGetInvoiceText = (organizationId: string, textId?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getInvoiceText'>(GetInvoiceText, {
    variables: {
      organizationId,
      id: textId,
    },
    skip: !textId,
  });

  return {
    invoiceText: data?.getInvoiceText,
    invoiceTextLoading: loading,
    invoiceTextRefetch: refetch,
  };
};

export const useInvoiceOfflineInsert = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const offlineInsert = useOfflineInsert<RxInvoice>('invoice');
  const patientCollection = useRxCollection<RxPatient>('patient');

  if (!organizationId || !offlineInsert || !patientCollection) {
    return undefined;
  }

  return async (upsert: InvoiceUpsert) => {
    const patient = await patientCollection.findOne(upsert.record?.patient_id ?? '').exec();
    if (!patient) {
      return undefined;
    }
    const offlineContactId =
      upsert.contact?.find((contact) => contact.record?.primary)?.record?.offline_contact_id ?? '';
    const obj = await getInvoiceInsert(upsert, {
      organizationId,
      patient: patient.toJSON() as unknown as Patient,
      offlineContactId,
    });
    return (await offlineInsert(obj)) as Invoice;
  };
};

export const useInvoiceOfflineUpdate = (invoiceId: string) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [invoice, setInvoice] = useState<RxInvoice | null>();
  const invoiceCollection = useRxCollection<RxInvoice>('invoice');
  const offlineUpdate = useOfflineUpdate<RxInvoice>('invoice');
  const prescriptionCollection = useRxCollection<RxPrescription>('prescription');
  const offlinePrescriptionUpdate = useOfflineAtomicUpdate<RxPrescription>('prescription');
  const offlinePrescriptionFlattenedInsert = useOfflineInsert<RxPrescriptionFlattened>('prescription_flattened');
  const offlinePrescriptionInsert = useOfflineInsert<RxPrescription>('prescription');
  const prescriptionFlattenedCollection = useRxCollection<RxPrescriptionFlattened>('prescription_flattened');
  const offlinePrescriptionFlattenedDelete = useOfflineDelete<RxPrescriptionFlattened>('prescription_flattened');
  const offlinePrescriptionDelete = useOfflineDelete<RxPrescription>('prescription');

  useEffect(() => {
    if (!invoiceCollection) {
      return;
    }

    const getRecord = async () => {
      setInvoice(await invoiceCollection.findOne(invoiceId).exec());
    };

    getRecord();
  }, [invoiceCollection, setInvoice, invoiceId]);

  const createOfflinePrescription = useCallback(
    async (service: ServiceRendered) => {
      const offline_id = getOfflineId();
      const remaining_fills = service?.prescriptions?.[0].refills || 0;
      const is_filled = remaining_fills === 0;
      const prescription = {
        ...service?.prescriptions?.[0],
        id: offline_id,
        offline_id,
        created: dayjs().toISOString(),
        is_new: true,
        remaining_fills,
        number: uniqueId('Temp-'),
        is_filled,
      } as Prescription;

      await offlinePrescriptionInsert({
        ...prescription,
        upsert: null,
        is_new: true,
        upsert_offline_id: null,
      });
      await offlinePrescriptionFlattenedInsert({
        ...(prescription as PrescriptionFlattened),
        remaining_fills,
        is_filled,
        upsert_offline_id: null,
        upsert: null,
        is_new: true,
        fill_id: getOfflineId(),
        filled_item_instructions: prescription.instructions,
        filled_service_rendered_id: service.id,
        invoice_id: invoiceId,
        filled_date: prescription.prescribed_date,
        filled_user_id: prescription.prescribed_user_id,
      });
    },
    [offlinePrescriptionFlattenedInsert, offlinePrescriptionInsert, invoiceId]
  );

  const updateExistingPrescription = useCallback(
    async (service: ServiceRendered, prescription: RxPrescription) => {
      const parsedPrescription = JSON.parse(JSON.stringify(prescription));
      const is_filled = prescription.remaining_fills - 1 === 0;
      const remaining_fills = prescription.remaining_fills - 1;
      await offlinePrescriptionFlattenedInsert({
        ...parsedPrescription,
        remaining_fills,
        is_filled,
        upsert: null,
        is_new: true,
        fill_id: getOfflineId(),
        filled_item_instructions: service?.prescriptions?.[0].instructions,
        filled_service_rendered_id: service.id,
        created: dayjs().toISOString(),
        invoice_id: invoiceId,
        filled_date: service?.prescriptions?.[0].prescribed_date,
        filled_user_id: service?.prescriptions?.[0].prescribed_user_id,
      });
      await offlinePrescriptionUpdate(prescription.id, {
        remaining_fills,
        offline_id: getOfflineId(),
        is_filled,
      });
    },
    [offlinePrescriptionFlattenedInsert, offlinePrescriptionUpdate, invoiceId]
  );

  const deleteOfflinePrescription = useCallback(
    async (deletingServiceRendered?: ServiceRendered) => {
      if (deletingServiceRendered?.prescriptions?.length) {
        const prescriptionsFlattened = await prescriptionFlattenedCollection?.find().exec();
        const prescription = prescriptionsFlattened?.find(
          (p) => p.filled_service_rendered_id === deletingServiceRendered.id
        );
        if (prescription?.filled_service_rendered_id === deletingServiceRendered?.id) {
          await offlinePrescriptionFlattenedDelete(prescription?.fill_id || '');
          if (isUuid(prescription.id)) {
            offlinePrescriptionDelete(prescription.id);
          } else {
            await offlinePrescriptionUpdate(prescription.id, {
              remaining_fills: prescription.remaining_fills + 1,
              is_filled: false,
            });
          }
        }
      }
    },
    [
      offlinePrescriptionFlattenedDelete,
      offlinePrescriptionDelete,
      offlinePrescriptionUpdate,
      prescriptionFlattenedCollection,
    ]
  );

  const update = useCallback(
    async (
      upsert: InvoiceUpsert,
      services: ServiceRendered[],
      action?: string,
      deletingServiceRendered?: ServiceRendered
    ) => {
      if (invoice) {
        const obj = await getInvoiceUpdate(invoice, upsert, {
          services,
          organizationId,
        });
        const prescriptions = await prescriptionCollection
          ?.find()
          .where('remaining_fills')
          .gt(0)
          .where('is_filled')
          .eq(false)
          .exec();
        if (action === 'add') {
          for (const service of services.filter((s) => s?.prescriptions?.length)) {
            const prescription = prescriptions?.find((p) => {
              const prescription = JSON.parse(JSON.stringify(p));
              return (
                prescription.service_id === service.service_id &&
                Number(prescription.item_quantity) === Number(service?.prescriptions?.[0].item_quantity) &&
                prescription.patient_id === service.patient_id
              );
            });
            if (prescription) {
              await updateExistingPrescription(service, prescription);
            } else {
              const alreadyFilled = await prescriptionFlattenedCollection
                ?.findOne()
                .where('filled_service_rendered_id')
                .eq(service.id)
                .exec();
              if (!alreadyFilled) {
                await createOfflinePrescription(service);
              }
            }
          }
        } else if (action === 'delete') {
          await deleteOfflinePrescription(deletingServiceRendered);
        }
        return (await offlineUpdate(invoiceId, obj)) as Invoice;
      }

      return undefined;
    },
    [
      invoice,
      offlineUpdate,
      invoiceId,
      organizationId,
      createOfflinePrescription,
      updateExistingPrescription,
      deleteOfflinePrescription,
      prescriptionCollection,
      prescriptionFlattenedCollection,
    ]
  );

  if (!invoice || !offlineUpdate) {
    return undefined;
  }

  return update;
};
