import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DropdownButtonWithMenu } from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { translations } from '../../../constants/translations';
import { PaymentDeviceType } from '../../../graph/types';
import { useUpsertCardConnectDevice } from '../../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { TableKey } from '../../../hooks/tableHooks';
import { paymentDeviceRenderedColumns } from './paymentDevicesTableColumns';

const ActionButton = styled(Button)`
  padding: 0;
`;

interface CardConnectDevicesTableProps {
  devices: PaymentDeviceType[];
  loading?: boolean;
}

export const inputTestIdSuffix = '_name_input';

export const CardConnectDevicesTable: React.FC<CardConnectDevicesTableProps> = ({ devices, loading }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [updatePaymentDevice, { loading: updatingDevice }] = useUpsertCardConnectDevice();
  const [editableDevice, setEditableDevice] = useState<{ id: string; newName?: string } | null>(null);

  const getNameRender = (device: PaymentDeviceType) => {
    if (editableDevice?.id === device.id) {
      return (
        <Input
          defaultValue={device.name}
          data-testid={`${device.id}${inputTestIdSuffix}`}
          onChange={(event) => setEditableDevice({ id: device.id, newName: event.target.value })}
          maxLength={50}
        />
      );
    }
    return device.name;
  };

  const handleDeviceUpdate = () => {
    if (editableDevice?.newName === undefined) {
      setEditableDevice(null);
      return;
    }

    updatePaymentDevice({
      variables: {
        organizationId,
        devices: [
          {
            id: editableDevice.id,
            name: editableDevice.newName,
            sort_order: 0,
          },
        ],
      },
    });
    setEditableDevice(null);
  };

  const renderActionCell = (device: PaymentDeviceType) => {
    if (device.id === editableDevice?.id) {
      return (
        <Space direction={'horizontal'}>
          <ActionButton type='link' onClick={handleDeviceUpdate}>
            {translations.shared.saveButtonText}
          </ActionButton>
          <ActionButton type='link' onClick={() => setEditableDevice(null)}>
            {translations.shared.cancelButtonText}
          </ActionButton>
        </Space>
      );
    }

    return (
      <DropdownButtonWithMenu
        menuItemProps={[
          {
            title: translations.shared.editButtonText,
            onClick: () => !editableDevice?.id && setEditableDevice({ id: device.id }),
          },
        ]}
      />
    );
  };

  const columns: CustomColumnType<PaymentDeviceType>[] = [
    {
      ...paymentDeviceRenderedColumns.name,
      render: getNameRender,
      width: 200,
    },
    {
      ...paymentDeviceRenderedColumns.serial_number,
      width: 200,
    },
    {
      title: translations.cardConnectSettings.columns.actions,
      width: 200,
      render: renderActionCell,
    },
  ];

  return (
    <TableWithCustomFiltering<PaymentDeviceType>
      tableKey={TableKey.CardConnectDevices}
      dataSource={devices}
      columns={columns}
      loading={loading || updatingDevice}
      onRowClick={(device) => {
        if (!editableDevice?.id) {
          setEditableDevice({ id: device.id });
        }
      }}
      rowKey={'id'}
    />
  );
};
