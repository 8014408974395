import gql from 'graphql-tag';
import { LedgerFields, LedgerFieldsForOffline, LedgerNoteFields } from '../fragments/ledgerFragments';
import { InfoFields } from '../fragments';

export const UpsertLedger = gql`
  mutation upsertLedger($ledger: LedgerUpsert!, $organizationId: ID!) {
    upsertLedger(ledger: $ledger, organizationId: $organizationId) {
      ...LedgerFields
    }
  }
  ${LedgerFields}
`;

export const GetLedgerNote = gql`
  query getLedgersPg($organizationId: ID!, $filter: QueryLedgerFilter!) {
    getLedgersPg(organizationId: $organizationId, filter: $filter) {
      id
      ...LedgerNoteFields
    }
  }
  ${LedgerNoteFields}
`;

export const GetLedgerDataForPaymentModal = gql`
  query getLedgersPg($organizationId: ID!, $filter: QueryLedgerFilter!) {
    getLedgersPg(organizationId: $organizationId, filter: $filter) {
      id
      total
      financial_date
      payment_type_id
      payment_electronic_id
      info {
        ...InfoFields
      }
    }
  }
  ${InfoFields}
`;

export const ReverseLedger = gql`
  mutation reverseLedger($organizationId: ID!, $ledger: LedgerReversal!) {
    reverseLedger(organizationId: $organizationId, ledger: $ledger) {
      ...LedgerFields
    }
  }
  ${LedgerFields}
`;

export const GetLedgerReceipt = gql`
  query getPaymentReceiptReport($ledgerId: ID!, $contactId: ID!, $request: ReportRequest!) {
    getPaymentReceiptReport(ledgerId: $ledgerId, contactId: $contactId, request: $request) {
      pointer
      error
    }
  }
`;

export const GetLedgers = gql`
  query getLedgersPg($organizationId: ID!, $filter: QueryLedgerFilter!) {
    getLedgersPg(organizationId: $organizationId, filter: $filter) {
      ...LedgerFields
    }
  }
  ${LedgerFields}
`;

export const GetLedgersForOffline = gql`
  query getLedgers($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $searchAfter: [ID!]!) {
    getLedgersEs(organizationId: $organizationId, take: $take, sort: $sort, searchAfter: $searchAfter) {
      ...LedgerFieldsForOffline
    }
  }
  ${LedgerFieldsForOffline}
`;

export const GetLedgersWithContactTotals = gql`
  query getLedgersWithContactTotals($organizationId: ID!, $filter: QueryLedgerFilter!) {
    getLedgersPg(organizationId: $organizationId, filter: $filter) {
      id
      contact_id
      total
      hidden
      financial_outstanding
    }
  }
`;

export const GetLedgerDataForDepositRunDetail = gql`
  query getLedgers($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $queryString: ElasticQueryString) {
    getLedgersEs(organizationId: $organizationId, take: $take, sort: $sort, queryString: $queryString) {
      id
      number
      contact_name
      total
      financial_date
      payment_type_id
      payment_type_name_key
    }
  }
`;
