import gql from 'graphql-tag';
import { RecordChangedFields } from '../fragments/recordChangedFragments';

export const OnPatientUpsert = gql`
  subscription onPatientUpsert($id: ID!) {
    onPatientUpsert(id: $id) {
      ...RecordChangedFields
    }
  }
  ${RecordChangedFields}
`;
