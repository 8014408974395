import { InvoiceUpsert, Note, NoteUpsert, ServiceRendered, ServiceRenderedUpsert } from '../../../graph/types';
import { NoteUpsertValues } from '../../../components/RecordSingleView/Notes/noteUtils';
import {
  createUpsertRecordForExistingNote,
  createUpsertRecordForNewNote,
} from '../../../components/Records/noteUpsertUtils';
import { NoteChangeRequest, NoteChangeRequestType } from '../../../components/ServiceRendered/NoteView/noteChangeUtil';

export const getInvoiceUpsertForNoteUpdate = (
  serviceRendered: ServiceRendered,
  existingNote: Note,
  noteValues: NoteUpsertValues,
  dateFormat: string
): InvoiceUpsert => {
  const noteUpsertForUpdate = {
    id: existingNote.id,
    record: createUpsertRecordForExistingNote(existingNote, noteValues, dateFormat),
  };
  return getInvoiceUpsertForRenderedServiceNote(serviceRendered, noteUpsertForUpdate);
};

export const getInvoiceUpsertForNoteChange = (noteRequest: NoteChangeRequest, dateFormat: string): InvoiceUpsert => {
  switch (noteRequest.requestType) {
    case NoteChangeRequestType.CREATE:
      return getInvoiceUpsertForRenderedServiceNote(noteRequest.service, {
        record: createUpsertRecordForNewNote(noteRequest.noteUpsertValues, dateFormat),
      });
    case NoteChangeRequestType.UPDATE:
      return getInvoiceUpsertForRenderedServiceNote(noteRequest.service, {
        id: noteRequest.existingNote.id,
        record: createUpsertRecordForExistingNote(noteRequest.existingNote, noteRequest.noteUpsertValues, dateFormat),
      });
    case NoteChangeRequestType.DELETE:
      return getInvoiceUpsertForRenderedServiceNote(noteRequest.service, {
        id: noteRequest.noteId,
        void: true,
      });
  }
  throw new Error('Unhandled note request type');
};

export const getInvoiceUpsertForNewNote = (
  serviceRendered: ServiceRendered,
  noteValues: NoteUpsertValues,
  dateFormat: string
): InvoiceUpsert => {
  const noteUpsertForAdd = {
    record: createUpsertRecordForNewNote(noteValues, dateFormat),
  };
  return getInvoiceUpsertForRenderedServiceNote(serviceRendered, noteUpsertForAdd);
};

export const getInvoiceUpsertForNoteDeletion = (serviceRendered: ServiceRendered): InvoiceUpsert => {
  const noteUpsertForDelete = {
    id: serviceRendered.note_id,
    void: true,
  };
  return getInvoiceUpsertForRenderedServiceNote(serviceRendered, noteUpsertForDelete);
};

const getInvoiceUpsertForRenderedServiceNote = (
  serviceRendered: ServiceRendered,
  noteUpsert: NoteUpsert
): InvoiceUpsert => {
  const serviceRenderedUpsert: ServiceRenderedUpsert = {
    id: serviceRendered.id,
    note: noteUpsert,
  };
  return {
    id: serviceRendered.invoice_id,
    item: [serviceRenderedUpsert],
  };
};
