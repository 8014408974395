import { QueryHookOptions, useMutation, useSubscription } from '@apollo/client';
import {
  Mutation,
  MutationUpsertOrganizationArgs,
  OrganizationList,
  Subscription,
  SubscriptionOnOrganizationDtoChangedArgs,
} from '../../../graph/types';
import {
  AdditionalOrganizationField,
  getGetOrganizationQuery,
  GetOrganizations,
  GetOrganizationsList,
  UpsertOrganization,
} from '../../../graph/queries/organizations';
import { useMemo, useState } from 'react';
import { FETCH_POLLING_TIME_INTERVAL } from '../../../constants/queryConstants';
import { UpsertOrganizationReferenceData } from '../../../graph/queries/referenceData';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { filterFunction } from '../../../util/filterAndSorting';
import { useOfflineQuery } from '../useOfflineQuery';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { GetOrganizationDtoEs, GetOrganizationDtoPg } from '../../../graph/queries/organizationDTOs';
import { OnOrganizationDTOChanged } from '../../../graph/queries/general';
import { getOrgDTOFromOrg } from '../../../util/organizationUtils';
import { setOrganization } from '../../../contexts/organization/action';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const useGetOrganization = (
  organizationId: string,
  additionalOrganizationField?: AdditionalOrganizationField,
  practiceId?: string,
  options?: QueryHookOptions
) => {
  const selector = useMemo(() => ({ _id: organizationId }), [organizationId]);
  const { data, loading, refetch, error, stopPolling } = useOfflineQuery<'getOrganization'>({
    query: getGetOrganizationQuery(additionalOrganizationField),
    collection: 'organization',
    options: {
      ...options,
      variables: { organizationId, practiceId },
      skip: practiceId === '' || !organizationId || options?.skip,
    },
    selector,
  });

  return {
    organization: data,
    organizationLoading: loading,
    organizationRefetch: refetch,
    organizationError: error,
    stopPolling,
  };
};

export const useGetOrganizationDTO = (organizationId: string, options?: QueryHookOptions) => {
  const [queryVersion, setQueryVersion] = useState<'getOrganizationDTOEs' | 'getOrganizationDTOPg'>(
    'getOrganizationDTOEs'
  );
  const selector = useMemo(() => ({ _id: organizationId }), [organizationId]);
  const { data, loading, refetch, error, stopPolling } = useOfflineQuery<
    'getOrganizationDTOEs' | 'getOrganizationDTOPg'
  >({
    query: queryVersion === 'getOrganizationDTOEs' ? GetOrganizationDtoEs : GetOrganizationDtoPg,
    collection: 'organization',
    options: {
      ...options,
      variables: { organizationId },
      skip: !organizationId || options?.skip,
      onError: () => {
        if (queryVersion === 'getOrganizationDTOEs') {
          setQueryVersion('getOrganizationDTOPg');
        }
      },
    },
    selector,
  });

  return {
    organization: data,
    organizationLoading: loading,
    organizationRefetch: refetch,
    organizationError: error,
    stopPolling,
  };
};

export const useOrganizationWithUserTypes = (organizationId: string) => {
  const { data, loading } = useOfflineErrorSkipQuery<'getOrganization'>(
    getGetOrganizationQuery(AdditionalOrganizationField.UserTypes),
    {
      variables: { organizationId },
      skip: !organizationId,
    }
  );
  return {
    organization: data?.getOrganization,
    organizationLoading: loading,
  };
};

export const useGetOrganizations = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data, loading, error } = useOfflineQuery<'getOrganizations'>({
    query: GetOrganizations,
    collection: 'organization',
    options: { fetchPolicy },
  });
  return {
    organizations: data,
    organizationsLoading: loading,
    organizationsError: error,
  };
};

export const useGetOrganizationsList = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getOrganizationsList'>(GetOrganizationsList, {
    fetchPolicy,
  });
  return {
    organizations: data?.getOrganizationsList,
    organizationsLoading: loading,
    organizationsError: error,
  };
};

export const useGetMaintenance = (organizationId: string) => {
  const { organization, organizationLoading, organizationError } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.Maintenance,
    undefined,
    {
      pollInterval: FETCH_POLLING_TIME_INTERVAL,
    }
  );

  return {
    maintenanceMessage: organization?.maintenance,
    maintenanceLoading: organizationLoading,
    maintenanceError: organizationError,
  };
};

export const useGetLabState = (organizationId: string) => {
  const { organization, organizationLoading, organizationError } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.LabState,
    undefined,
    { pollInterval: FETCH_POLLING_TIME_INTERVAL, fetchPolicy: 'cache-and-network' }
  );

  return {
    labState: organization?.lab_state,
    labStateLoading: organizationLoading,
    labStateError: organizationError,
  };
};

export const useOrganizationsSearch = (organizations: OrganizationList[]) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOrganizations = useMemo(
    () => organizations?.filter((organization) => filterFunction(searchValue, organization.name)) ?? [],
    [organizations, searchValue]
  );

  return { organizations: filteredOrganizations, searchOrganization: setSearchValue };
};

export const useUpdateOrganization = () => {
  const {
    state: { organization },
    dispatch,
  } = useOrganizationContext();
  return useMutation<Mutation, MutationUpsertOrganizationArgs>(UpsertOrganization, {
    onCompleted: (data) => {
      if (data?.upsertOrganization && organization) {
        const updatedOrganization = getOrgDTOFromOrg(data?.upsertOrganization, organization);
        dispatch(setOrganization({ ...organization, ...updatedOrganization }));
      }
    },
  });
};

export const useUpdateOrganizationReferenceData = () => {
  const {
    state: { organization },
    dispatch,
  } = useOrganizationContext();
  return useMutation<Mutation, MutationUpsertOrganizationArgs>(UpsertOrganizationReferenceData, {
    onCompleted: (data) => {
      if (data?.upsertOrganization && organization) {
        const updatedOrganization = getOrgDTOFromOrg(data?.upsertOrganization, organization);
        dispatch(setOrganization({ ...organization, ...updatedOrganization }));
      }
    },
  });
};

const organizationHooks = {
  useGetOrganization,
};

export const useOrganizationDTOUpsertSubscription = (organizationId: string) => {
  const { data, loading, error } = useSubscription<Subscription, SubscriptionOnOrganizationDtoChangedArgs>(
    OnOrganizationDTOChanged,
    {
      variables: { id: organizationId },
      skip: !organizationId,
    }
  );
  return { record: data?.onOrganizationDTOChanged, loading, error };
};

export default organizationHooks;
