import { defaults } from 'lodash';
import {
  BillingAddress,
  OrganizationSubscriptionDetailCharge,
  OrganizationSubscriptionLevelUpsert,
  OrganizationSubscriptionUpsert,
} from '../../graph/types';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import { CreditCardUpsertGenerator } from './CreditCardUpsertGenerator';

export const MIN_NUMBER_OF_SUBSCRIPTION_USERS = 1;
export const MAX_NUMBER_OF_SUBSCRIPTION_USERS = 1000;

export class SubscriptionUpsertGenerator {
  public static generateUpsert(initialValues: Partial<OrganizationSubscriptionUpsert> = {}) {
    return defaults(initialValues, {
      fnProcess: true,
      detail: [],
    });
  }

  public static generatePriceFetchUpsert(
    initialValues?: Partial<OrganizationSubscriptionUpsert>
  ): OrganizationSubscriptionUpsert {
    return this.generateUpsert({ ...initialValues, fnProcess: false });
  }

  public static mapChargeToUpsert(
    charge: OrganizationSubscriptionDetailCharge,
    { level_id, add_qty, set_qty, users }: OrganizationSubscriptionLevelUpsert = { level_id: '' }
  ): OrganizationSubscriptionLevelUpsert {
    let newQty = charge.currentQty;
    if (charge.level_id === level_id) {
      newQty = set_qty ?? newQty + (add_qty ?? 0);
    }

    return {
      level_id: charge.level_id,
      set_qty: Math.min(newQty, MAX_NUMBER_OF_SUBSCRIPTION_USERS),
      ...(users && { users }),
    };
  }

  public static mapToSubscriptionUpsert(
    contact: { payer: string; address: BillingAddress },
    numberOfUsers: number,
    card: { token: string; expiryDate: string; processorType: number },
    coupon?: string
  ): OrganizationSubscriptionUpsert {
    return {
      contact: CreditCardUpsertGenerator.mapFromBillingAddressToSubscriptionContactRecordUpsert(
        ID_FOR_OBJECT_CREATION,
        contact.payer,
        contact.address
      ),
      detail: [
        {
          level_id: organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.SubLevelAdmin].level_id,
          set_qty: numberOfUsers,
        },
      ],
      card: {
        card_token: card.token,
        expiry: card.expiryDate,
        processor_type_id: card.processorType,
      },
      ...(coupon && coupon !== '' ? { discount: [{ discount_code: coupon }] } : {}),
      fnProcess: true,
    };
  }
}
