import {
  GetLedgerDataForDepositRunDetail,
  GetLedgerDataForPaymentModal,
  GetLedgerNote,
  GetLedgers,
  GetLedgersWithContactTotals,
  ReverseLedger,
  UpsertLedger,
} from '../../../graph/queries/ledgers';
import {
  DepositRun,
  Ledger,
  MutationReverseLedgerArgs,
  MutationUpsertLedgerArgs,
  Query,
  QueryGetLedgersEsArgs,
  QueryGetLedgersPgArgs,
} from '../../../graph/types';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useMemo } from 'react';
import { OperationVariables, QueryHookOptions, WatchQueryFetchPolicy } from '@apollo/client';
import { useOfflineQuery } from '../useOfflineQuery';
import { isEmpty } from 'lodash';

type LedgerHookArgs = { ledgerId: string; organizationId: string };
type DepositRunHookArgs = { depositRun: DepositRun; organizationId: string };

const financialDateSort = (a: Ledger, b: Ledger) => {
  if (!a.financial_date && !b.financial_date) {
    // If both items don't have financial_date, compare by update
    return Date.parse(b.updated) - Date.parse(a.updated);
  } else if (!a.financial_date) {
    return -1;
  } else if (!b.financial_date) {
    return 1;
  }
  return Date.parse(b.financial_date.replace(/-/g, '/')) - Date.parse(a.financial_date.replace(/-/g, '/'));
};

export const useGetContactLedger = ({ contactId, organizationId }: { contactId: string; organizationId: string }) => {
  const selector = useMemo(() => ({ contact_id: contactId }), [contactId]);
  const variables = useMemo(() => ({ filter: { contact_id: contactId }, organizationId }), [contactId, organizationId]);
  const options: QueryHookOptions<Pick<Query, 'getLedgersPg'>, OperationVariables> = useMemo(
    () => ({
      variables,
      skip: !contactId || !organizationId,
      fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
      nextFetchPolicy: 'cache-first',
    }),
    [contactId, organizationId, variables]
  );

  const { data, loading, error, refetch } = useOfflineQuery<'getLedgersPg'>({
    query: GetLedgers,
    collection: 'ledger',
    options,
    selector,
    expectList: true,
    sortBy: '-id',
  });

  const contactLedger = useMemo(() => data && [...data]?.sort(financialDateSort), [data]);

  return {
    contactLedger,
    contactLedgerLoading: loading,
    contactLedgerError: error,
    refetchContactLedger: refetch,
  };
};

export const useGetInvoiceLedger = ({
  invoiceId,
  organizationId,
  skip = false,
}: {
  invoiceId: string;
  organizationId: string;
  skip?: boolean;
}) => {
  const variables = useMemo(() => ({ organizationId, filter: { invoice_id: invoiceId } }), [organizationId, invoiceId]);
  const selector = useMemo(() => ({ invoice_id: invoiceId }), [invoiceId]);
  const { data, loading, refetch } = useOfflineQuery<'getLedgersPg'>({
    query: GetLedgersWithContactTotals,
    collection: 'ledger',
    options: {
      variables,
      skip: !invoiceId || !organizationId || skip,
      fetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
      nextFetchPolicy: 'cache-first',
    },
    expectList: true,
    selector,
  });

  return {
    ledgers: data,
    ledgersLoading: loading,
    refetchLedgers: refetch,
  };
};

export const useGetLedgerNote = ({ ledgerId, organizationId }: LedgerHookArgs) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getLedgersPg', QueryGetLedgersPgArgs>(GetLedgerNote, {
    variables: { filter: { id: ledgerId }, organizationId },
    skip: ledgerId === '',
    fetchPolicy: 'no-cache',
  });
  return {
    ledgerNote: data?.getLedgersPg?.[0]?.note,
    ledgerNoteLoading: loading,
    ledgerNoteError: error,
  };
};

export const useGetLedgerDataForPaymentModal = ({ ledgerId, organizationId }: LedgerHookArgs) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getLedgersPg', QueryGetLedgersPgArgs>(
    GetLedgerDataForPaymentModal,
    {
      fetchPolicy: 'no-cache',
      variables: { filter: { id: ledgerId }, organizationId },
      skip: ledgerId === '',
    }
  );
  return {
    ledger: data?.getLedgersPg?.[0],
    ledgerLoading: loading,
    ledgerError: error,
  };
};

export const useGetLedgerDataForDepositRunDetail = ({ depositRun, organizationId }: DepositRunHookArgs) => {
  const depositRunDetailLedgerIds = depositRun.detail?.map((detail) => detail.payment_ledger_id);
  const ledgerIdsQueryString = depositRunDetailLedgerIds?.join(' OR ') ?? '';
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getLedgersEs', QueryGetLedgersEsArgs>(
    GetLedgerDataForDepositRunDetail,
    {
      fetchPolicy: 'no-cache',
      variables: {
        organizationId,
        take: 1000,
        sort: {
          field: 'id',
          direction: 'desc',
        },
        queryString: {
          fields: ['id'],
          query: ledgerIdsQueryString,
        },
      },
      skip: isEmpty(depositRunDetailLedgerIds),
    }
  );

  return {
    ledgers: data?.getLedgersEs,
    ledgersLoading: loading,
    ledgersError: error,
    ledgersRefetch: refetch,
  };
};

export const useDeleteLedger = (organizationId: string) => {
  const [deleteLedger] = useUpsertLedger();
  return (ledgerId: string) =>
    deleteLedger({
      variables: {
        organizationId,
        ledger: { id: ledgerId, void: true },
      },
      update: (cache: any) => {
        cache.evict({
          id: cache.identify({ __typename: 'Ledger', id: ledgerId }),
        });
        cache.evict({ fieldName: 'getLedgersPg' });
        cache.gc();
      },
    });
};

export const useUpsertLedger = () => {
  return useOfflineErrorSkipMutation<'upsertLedger', MutationUpsertLedgerArgs>(UpsertLedger);
};

export const useReverseLedger = () => {
  return useOfflineErrorSkipMutation<'reverseLedger', MutationReverseLedgerArgs>(ReverseLedger);
};
