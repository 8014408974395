import React, { useContext } from 'react';
import _ from 'lodash';
import { DatePicker, Select } from 'antd';
// import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';

import { RecordType } from '../recordUtils';

// import { translations } from '../../../constants/translations';
import { RecordsContext } from '../store/state';

import { FlexContainer, FlexItem } from '../../../globalStyles.style';
import './FullscreenRecordList.less';

const selectOptions: { label: string; value: string }[] = [];
for (const rType in RecordType) {
  if (isNaN(Number(rType))) {
    const label = rType
      .replace('type', '')
      .replace(/([A-Z])/g, ' $1')
      .trim();
    const value = RecordType[rType];
    selectOptions.push({ label, value });
  }
}

export interface Filter {
  byType?: RecordType[];
  jumpToDate?: string; //2024-06-13
}

export interface FilterProps extends Filter {
  setFilters: (filter: Filter) => void;
  availableDates?: string[];
}

export const Filters = ({ setFilters, availableDates }: FilterProps) => {
  const { state } = useContext(RecordsContext);

  const handleFilterChange = (value: any) => {
    setFilters({ byType: value });
  };

  const handleDateChange = (_date: any, dateString: string) => {
    setFilters({ jumpToDate: dateString });
  };

  const debouncedHandleFilterChange = _.debounce(handleFilterChange, 1000);

  const isDisabled = (date: Dayjs) => {
    return !date.isSame(dayjs()) && !availableDates?.includes(date.format('YYYY-MM-DD'));
  };
  return (
    <>
      <FlexContainer direction='row'>
        <FlexItem>
          <Select
            mode='multiple'
            allowClear
            style={{ width: '200px' }}
            options={selectOptions}
            placeholder={'Filter by type'}
            dropdownStyle={{ width: 'fit-content' }}
            onChange={debouncedHandleFilterChange}
            disabled={!!state.searchValue}
          />
        </FlexItem>
        <FlexItem>
          <DatePicker onChange={handleDateChange} disabledDate={isDisabled} />
        </FlexItem>
      </FlexContainer>
    </>
  );
};
