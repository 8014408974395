import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import { UploadDeletePopover } from '../../../../components/UploadDeletePopover/UploadDeletePopover';
import { ImageWithPlaceholder } from '../../../../components/ImageWithPlaceholder/ImageWithPlaceholder';
import { useUpdatePatient } from '../../../../hooks/ajax/patients/patientHooks';
import { FileData, getUploadRequestToS3 } from '../../../../util/fileUpload';
import { translations } from '../../../../constants/translations';
import { Patient, PatientUpsert } from '../../../../graph/types';
import { useGetUrlForFileDownload } from '../../../../hooks/ajax/fileUrl/fileUrlHooks';
import './PatientProfileImage.css';
import { Spin } from 'antd';
import { getProfileImagePlaceholder } from './profileImagePlaceholders/profileImagePlaceholders';
import { imageFileExtensions } from '../../../../constants/fileExtensions';
import { showSuccessMessage } from '../../../../components/Notification/notificationUtil';

const profileImageStyle: CSSProperties = {
  marginTop: '-50px',
  marginRight: '10px',
  marginLeft: '10px',
  display: 'inline-block',
  verticalAlign: 'bottom',
};

interface PatientProfileImageProps extends PropsWithChildren<unknown> {
  patient: Patient;
}

export const PatientProfileImage: React.FC<PatientProfileImageProps> = ({ patient }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [updatePatient] = useUpdatePatient();

  const { fileUrl, fileUrlLoading } = useGetUrlForFileDownload({
    organizationId: patient.organization_id,
    fileName: patient?.profile_image?.name || '',
    filePointer: patient?.profile_image?.pointer || '',
  });

  async function upsertPatientProfileImageInformation(patientUpsert: PatientUpsert, successMessage: string) {
    setIsSaving(true);
    await updatePatient({
      variables: {
        patient: patientUpsert,
        organizationId: patient.organization_id,
      },
      onCompleted: () => showSuccessMessage(successMessage),
    });
    setIsSaving(false);
  }

  const setNewProfileImageData = async (fileData: FileData) => {
    const patientUpsert: PatientUpsert = {
      id: patient.id,
      profile_image: {
        id: patient.profile_image?.id,
        record: { name: fileData.name, size: fileData.size, pointer: fileData.pointer },
      },
    };
    await upsertPatientProfileImageInformation(
      patientUpsert,
      translations.patientPage.profileImage.successfullyUploaded
    );
  };

  const removeProfileImage = async () => {
    if (!patient.profile_image?.id) {
      throw new Error('Cannot remove profile image without id!');
    }

    const patientUpsert: PatientUpsert = {
      id: patient.id,
      profile_image: { id: patient.profile_image.id, void: true },
    };
    await upsertPatientProfileImageInformation(
      patientUpsert,
      translations.patientPage.profileImage.successfullyRemoved
    );
  };

  return (
    <div className={'patientProfileImage'} style={profileImageStyle}>
      {fileUrlLoading ? (
        <Spin tip={translations.loadingComponent.loading} size='large' />
      ) : (
        <UploadDeletePopover
          accept={imageFileExtensions.join(',')}
          uploadRequest={getUploadRequestToS3(patient.organization_id, setIsSaving, setNewProfileImageData, 4)}
          deleteFunction={removeProfileImage}
          showDeleteButton={!!fileUrl}
          deleteButtonText={translations.patientPage.profileImage.delete}
          deleteConfirmQuestion={translations.patientPage.profileImage.deleteConfirm}
          uploadButtonText={
            fileUrl
              ? translations.patientPage.profileImage.uploadNew
              : translations.patientPage.profileImage.uploadFirst
          }
          isSaving={isSaving}
        >
          <ImageWithPlaceholder
            imageProps={{ src: fileUrl?.url, alt: translations.patientPage.profileImage.alt }}
            placeholderSrc={getProfileImagePlaceholder(patient.species_name)}
          />
        </UploadDeletePopover>
      )}
    </div>
  );
};
