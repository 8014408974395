import gql from 'graphql-tag';

export const DepositRunFields = gql`
  fragment DepositRunFields on DepositRun {
    id
    number
    end_date
    paymentType {
      id
      deposit_id
      organization_id
      payment_type_id
    }
    detail {
      id
      deposit_id
      external_payment_id
      external_payment_number
      payment_ledger_id
      sent_date
      status_id
    }
    status_id
  }
`;

export const DepositRunResultFields = gql`
  fragment DepositRunResultFields on QuickbooksResult {
    errorMessage
    redirectUrl
    status
  }
`;
