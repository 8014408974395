import {
  CreateUser,
  GetCurrentUser,
  GetCurrentUserBasicFields,
  GetCurrentUserWithOrganization,
  GetCurrentUserWithOrganizationPermission,
  GetHISAVetValidation,
  GetUserOrganizationCardsPg,
  GetUserSettings,
  GetUserWithPermissions,
  GetUsers,
  SetUserStatus,
  UpsertUser,
  UpsertUserOrganizationCards,
  UpsertUserPermissions,
} from '../../../graph/queries/users';
import { ApolloCache, ApolloError, DocumentNode } from '@apollo/client';
import {
  Maybe,
  MutationCreateUserArgs,
  MutationSetUserStatusArgs,
  MutationUpsertUserArgs,
  MutationUpsertUserOrganizationCardsArgs,
  Query,
  User,
} from '../../../graph/types';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { useEffect, useMemo } from 'react';
import { useFindUserOrganization } from '../../useFindUserOrganization';
import { USER_KEY } from '../../../constants/sessionStorageKeys';
import {
  useOfflineErrorSkipLazyQuery,
  useOfflineErrorSkipMutation,
  useOfflineErrorSkipQuery,
} from '../useOfflineErrorSkip';
import { getUserOrganizationWithoutHiddenCards } from '../../../util/userOrganizationUtil';
import { GetOrganizationDtoEs } from '../../../graph/queries/organizationDTOs';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { setOrganization } from '../../../contexts/organization/action';

type UseCurrentUserType = {
  user: User;
  userLoading: boolean;
  userError?: ApolloError;
};

export const useGetUsers = (organizationId?: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getUsersPg'>(
    GetUsers,
    {
      variables: { organizationId },
      onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
      skip: !organizationId,
    },
    true
  );

  return {
    users: data?.getUsersPg,
    usersLoading: loading,
    usersError: error,
  };
};

export const useCurrentUser = (
  query: DocumentNode,
  { fetchPolicy, variables }: { fetchPolicy?: WatchQueryFetchPolicy; variables?: Record<string, any> } = {}
): UseCurrentUserType => {
  const localUser = useMemo(() => localStorage.getItem(USER_KEY), []);
  const user = useMemo(() => localUser && JSON.parse(localUser), [localUser]);
  const { data, loading, error } = useOfflineErrorSkipQuery<'getCurrentUser'>(query, {
    variables,
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
  });

  const userOrganization = useFindUserOrganization(data?.getCurrentUser);

  useEffect(() => {
    if (data?.getCurrentUser) {
      const enableOfflineFlag = userOrganization?.enable_offline;

      const localUser = JSON.parse(localStorage.getItem(USER_KEY) ?? '{}');

      let entry = {
        ...localUser,
        ...data.getCurrentUser,
        isOfflineAble: localUser.isOfflineAble,
        pin: data.getCurrentUser.pin !== undefined ? data.getCurrentUser.pin : localUser.pin,
      };

      if (enableOfflineFlag !== undefined && enableOfflineFlag !== null) {
        entry = { ...entry, isOfflineAble: enableOfflineFlag };
      }

      localStorage.setItem(USER_KEY, JSON.stringify(entry));
    }
  }, [data, userOrganization]);

  return {
    user: data?.getCurrentUser ?? user,
    userLoading: loading,
    userError: error,
  };
};

export const useGetUserWithPermissions = (organizationId: string, userId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getUsersPg'>(
    GetUserWithPermissions,
    {
      variables: { organizationId, filter: { user_id: userId } },
      onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
    },
    true
  );

  return {
    user: data?.getUsersPg?.[0],
    userLoading: loading,
    userError: error,
    userRefetch: refetch,
  };
};

export const useGetCurrentUser = (organizationId?: string) => {
  const query = organizationId ? GetCurrentUserWithOrganizationPermission : GetCurrentUser;
  const options: { fetchPolicy?: WatchQueryFetchPolicy; variables?: Record<string, any> } = organizationId
    ? {
        variables: { organizationId },
        fetchPolicy: 'cache-and-network',
      }
    : {};
  return useCurrentUser(query, options);
};

export const useGetCurrentUserBasicFields = () => useCurrentUser(GetCurrentUserBasicFields);

export const useGetCurrentUserWithOrganization = (fetchPolicy?: WatchQueryFetchPolicy) =>
  useCurrentUser(GetCurrentUserWithOrganization, { fetchPolicy });

export const useGetUserOrganizationCards = (organizationId: string, userId?: string, userOrganizationId?: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getUserOrganizationCardsPg'>(
    GetUserOrganizationCardsPg,
    {
      variables: { organizationId, filter: { user_id: userId, user_organization_id: userOrganizationId } },
      onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
      skip: !userId,
    },
    true
  );

  const cards = getUserOrganizationWithoutHiddenCards(
    data?.getUserOrganizationCardsPg ?? { patient_card: [], contact_card: [] }
  );

  return {
    userOrganizationCards: cards,
    cardsLoading: loading,
    cardsError: error,
    cardsRefetch: refetch,
  };
};

export const useGetUserOrganizationWithTypes = (organizationId: string, userId: string) => {
  const { user, userLoading, userError } = useGetUserWithPermissions(organizationId, userId);

  return {
    userOrganization: user?.organization,
    userOrganizationLoading: userLoading,
    userOrganizationError: userError,
  };
};

export const useGetUserSettings = (organizationId: string, practiceId?: Maybe<string>, userId?: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getUserSettings'>(GetUserSettings, {
    variables: { organizationId, practiceId, userId },
    skip: !userId || !practiceId,
  });

  return {
    userSettings: data?.getUserSettings,
    userSettingsLoading: loading,
    userSettingsError: error,
  };
};

export const useGetHisaValidation = () => {
  const [getValidation, { data, loading, error }] =
    useOfflineErrorSkipLazyQuery<'getHISAVetValidation'>(GetHISAVetValidation);

  return {
    validation: data?.getHISAVetValidation,
    validationLoading: loading,
    validationError: error,
    getValidation,
  };
};

export const useCreateUser = (organizationId: string) => {
  const { dispatch } = useOrganizationContext();
  return useOfflineErrorSkipMutation<'createUser', MutationCreateUserArgs>(CreateUser, {
    onCompleted: async (_, options) => {
      const result = await options?.client?.query<Pick<Query, 'getOrganizationDTOEs'>>({
        query: GetOrganizationDtoEs,
        variables: { organizationId },
      });
      if (result?.data.getOrganizationDTOEs) {
        dispatch(setOrganization(result?.data.getOrganizationDTOEs));
      }
    },
  });
};

export const useSetUserStatus = () => {
  return useOfflineErrorSkipMutation<'setUserStatus', MutationSetUserStatusArgs>(SetUserStatus);
};

export const useUpsertUser = () => {
  return useOfflineErrorSkipMutation(UpsertUser);
};

export const useUpsertUserOrganizationCards = () => {
  return useOfflineErrorSkipMutation<'upsertUserOrganizationCards', MutationUpsertUserOrganizationCardsArgs>(
    UpsertUserOrganizationCards,
    {
      update(cache: ApolloCache<any>, { data }) {
        if (data?.upsertUserOrganizationCards) {
          cache.modify({
            fields: {
              getUserOrganizationCardsPg() {
                return data.upsertUserOrganizationCards;
              },
            },
          });
        }
      },
    }
  );
};

export const useUpsertUserPermissions = () => {
  return useOfflineErrorSkipMutation<'upsertUser', MutationUpsertUserArgs>(UpsertUserPermissions);
};

export const useGetCurrentUserLazy = (organizationId?: string) => {
  const query = organizationId ? GetCurrentUserWithOrganizationPermission : GetCurrentUser;
  const options: { fetchPolicy?: WatchQueryFetchPolicy; variables?: Record<string, any> } = organizationId
    ? {
        variables: { organizationId },
        fetchPolicy: 'cache-and-network',
      }
    : {};
  return useCurrentUserLazy(query, options);
};

export const useCurrentUserLazy = (
  query: DocumentNode,
  { fetchPolicy, variables }: { fetchPolicy?: WatchQueryFetchPolicy; variables?: Record<string, any> } = {}
) => {
  const [getUser, { data, loading, error }] = useOfflineErrorSkipLazyQuery<'getCurrentUser'>(query, {
    variables,
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
  });

  return {
    user: data?.getCurrentUser,
    userLoading: loading,
    userError: error,
    getUser,
  };
};
