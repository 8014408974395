import { Note, ServiceRendered } from '../../../graph/types';
import { NoteUpsertValues } from '../../RecordSingleView/Notes/noteUtils';

export enum NoteChangeRequestType {
  CREATE,
  UPDATE,
  DELETE,
}

interface HasServiceRendered {
  service: ServiceRendered;
}

export interface NoteCreateRequest extends HasServiceRendered {
  requestType: NoteChangeRequestType.CREATE;
  noteUpsertValues: NoteUpsertValues;
}

export interface NoteUpdateRequest extends HasServiceRendered {
  requestType: NoteChangeRequestType.UPDATE;
  existingNote: Note;
  noteUpsertValues: NoteUpsertValues;
}

interface NoteDeleteRequest extends HasServiceRendered {
  requestType: NoteChangeRequestType.DELETE;
  noteId: string;
}

export type NoteChangeRequest = NoteCreateRequest | NoteUpdateRequest | NoteDeleteRequest;
