import React, { useState } from 'react';
import { Col, Descriptions, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Loading } from '../../components/Loading/Loading';
import { translations } from '../../constants/translations';
import { useGetMetrics } from '../../hooks/ajax/dashboard/dashboardHooks';
import { Bar, Cell, TooltipProps } from 'recharts';
import { StyledToolTip, StyledParagraph, StyledChartContainer, LastUpdatedDateElement } from './Dashboard.style';
import { InvoicedChart } from './InvoicedChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
  currentPeriod,
  DashboardColors,
  getArBreakdown,
  getContactsAndPatients,
  getMonthlyBreakdown,
  getMonthlyPayments,
  getYearlyBreakdown,
  prevPeriod,
} from './dashboardUtils';
import { useOffline } from '../../util/offline/offlineUtil';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';
import { DASHBOARD_FETCH_POLLING_TIME_INTERVAL } from '../../constants/queryConstants';
import dayjs from 'dayjs';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import ClickableIcon, { IconType } from '../../components/Icons/ClickableIcon';
import { useOrganizationContext } from '../../contexts/organization/state';

const sizingProps = { xl: 12, lg: 24, md: 24, sm: 24 };

export const refreshIconTestId = 'refresh-icon-test-id';

export const Dashboard: React.FC = () => {
  const organizationContext = useOrganizationContext();
  const organization = organizationContext.state.organization;
  const { metrics, loading, error, metricsRefetch } = useGetMetrics(
    organization?.organization_id,
    organization?.default_practice_id,
    {
      fetchPolicy: 'cache-first',
      pollInterval: DASHBOARD_FETCH_POLLING_TIME_INTERVAL,
    }
  );
  const { isOnline } = useOffline();

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const [lastUpdated, setLastUpdated] = useState(dayjs().format(`${dateFormat} @ hh:mm:ssa`));
  const [isRefetching, setIsRefetching] = useState(false);

  if (!isOnline) {
    return <p>{translations.dashboard.offline}</p>;
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{translations.dashboard.missingMetrics}</p>;
  }

  const monthlyBreakdown = getMonthlyBreakdown(metrics?.monthly_invoiced);

  const yearlyBreakdown = getYearlyBreakdown(metrics?.yearly_invoiced);

  const arBreakdown = getArBreakdown(metrics?.accounts_receivable);

  const monthlyPayments = getMonthlyPayments(metrics?.monthly_payments);

  const contactsAndPatients = getContactsAndPatients(metrics?.contacts_patients);

  const refreshMetrics = async () => {
    setIsRefetching(true);
    try {
      await metricsRefetch();
      setLastUpdated(dayjs().format(`${dateFormat} @ hh:mm:ssa`));
    } catch (e) {
      showErrorMessage(translations.shared.loadErrorMessage);
    } finally {
      setIsRefetching(false);
    }
  };

  const paymentTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      const data = payload?.[0].payload;
      return (
        <StyledToolTip>
          <p>
            {data.label} : <CurrencyFormatter total={data.value} />
          </p>
          <p>
            {translations.dashboard.payments.ytd} <CurrencyFormatter total={data.ytd} />
          </p>
        </StyledToolTip>
      );
    }

    return null;
  };

  const monthlyBreakdownTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      const data = payload?.[0].payload;
      const [firstYear, secondYear] = data.years;
      return (
        <StyledToolTip>
          <span>{data.name}</span>
          <StyledParagraph color={DashboardColors.GREEN}>
            {firstYear}: <CurrencyFormatter total={data[prevPeriod]} />
          </StyledParagraph>
          <StyledParagraph color={DashboardColors.BLUE}>
            {secondYear}: <CurrencyFormatter total={data[currentPeriod]} />
          </StyledParagraph>
        </StyledToolTip>
      );
    }

    return null;
  };

  return (
    <SaveSpinner isSaving={isRefetching} savingMessage={translations.dashboard.refetch}>
      <PageHeader
        title={translations.mainPage.dashboard}
        subTitle={
          <ClickableIcon
            data-testid={refreshIconTestId}
            iconType={IconType.RefetchDashboard}
            onClick={refreshMetrics}
          />
        }
      >
        <Descriptions size='small'>
          <Descriptions.Item
            label={<LastUpdatedDateElement>{translations.dashboard.lastUpdated}</LastUpdatedDateElement>}
          >
            <LastUpdatedDateElement> {lastUpdated}</LastUpdatedDateElement>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <StyledChartContainer>
        <Row gutter={16}>
          <Col {...sizingProps}>
            <InvoicedChart
              title={translations.dashboard.monthly.title}
              data={monthlyBreakdown}
              bar={
                <>
                  <Bar barSize={20} dataKey={prevPeriod} fill={DashboardColors.GREEN} />
                  <Bar barSize={20} dataKey={currentPeriod} fill={DashboardColors.BLUE} />
                </>
              }
              customToolTip={monthlyBreakdownTooltip}
            />
          </Col>
          <Col {...sizingProps}>
            <InvoicedChart
              title={translations.dashboard.yearly.title}
              data={yearlyBreakdown}
              bar={<Bar barSize={60} dataKey='value' fill={DashboardColors.BLUE} />}
              includeLegend={false}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...sizingProps}>
            <InvoicedChart
              title={translations.dashboard.accountsReceivable.title}
              data={arBreakdown}
              bar={
                <Bar barSize={40} dataKey='value' fill={DashboardColors.BLUE}>
                  {arBreakdown.map((entry) => (
                    <Cell key={entry.name} fill={entry.color} />
                  ))}
                </Bar>
              }
              layout='vertical'
              legendPayload={arBreakdown.map(({ name, color }) => ({
                value: name,
                color,
                type: 'circle',
              }))}
            />
          </Col>
          <Col {...sizingProps}>
            <InvoicedChart
              title={translations.dashboard.payments.title}
              data={monthlyPayments ?? []}
              bar={<Bar barSize={60} dataKey='value' fill={DashboardColors.GREY} />}
              includeLegend={false}
              customToolTip={paymentTooltip}
            />
          </Col>
        </Row>
        <Row>
          <Col {...sizingProps}>
            <InvoicedChart
              title={translations.dashboard.contactsAndPatients.title}
              data={contactsAndPatients ?? []}
              showTooltipAsText
              bar={
                <>
                  <Bar barSize={20} dataKey={'Contacts'} fill={DashboardColors.GREY} />
                  <Bar barSize={20} dataKey={'Patients'} fill={DashboardColors.YELLOW} />
                </>
              }
            />
          </Col>
        </Row>
      </StyledChartContainer>
    </SaveSpinner>
  );
};
