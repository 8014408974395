import config from '../config/config';

const operationWithRetry = (operation: () => any, retries = 3) => {
  let lastError = undefined;
  for (let i = 0; i < retries; i++) {
    try {
      return operation();
    } catch (e) {
      lastError = e;
    }
  }
  throw lastError;
};

export const checkConnection = (): boolean => {
  const operation = () => {
    const request = new XMLHttpRequest();
    request.open('POST', config.PUBLIC_API_URL, false);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.send(JSON.stringify({ operation: 'Ping' }));

    if (request.status === 0 || request.status >= 400) {
      throw new Error('Ping not working');
    }
    return request;
  };
  try {
    operationWithRetry(operation);
    return true;
  } catch (_) {
    return false;
  }
};
