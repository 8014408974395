import gql from 'graphql-tag';
import {
  PatientHistoryReportFields,
  PatientVaccineReportFields,
  ProductionReportFields,
  ReportFields,
  ReportParameterFields,
  StatementReportFields,
  InvoiceReportFields,
  ContactReceiptFields,
  CommunicationLogReportFields,
  ControlledDrugLogReportFields,
  DailyInvoiceActivityReportFields,
  GeneralLedgerReportFields,
  InvoiceActivityReportFields,
  LedgerEntryReportFields,
  PaymentsReportFields,
  SubscriptionsReportFields,
  TaxReportFields,
  VaccineActivityReportFields,
  ARReportFields,
  ContactPaymentDetailReportFields,
  SubscriptionPaymentsReportFields,
  RemindersByContactReportFields,
  PatientProcedureByContactReportFields,
  PatientProcedureReportFields,
  NewContactsAndPatientsReportFields,
  InvoiceActivityByTrainerReportFields,
  VaccineByContactReportFields,
  RptTopSalesFields,
  RptSubscriptionsInvoiceFields,
  RptReminderLetterFields,
  RptGetJournalEntryFields,
  RptDepositFields,
} from '../fragments/reportFragments';

export const GetReports = gql`
  query getReports($organizationId: ID!, $practiceId: ID!) {
    getReports(organizationId: $organizationId, practiceId: $practiceId) {
      ...ReportFields
    }
  }
  ${ReportFields}
`;

export const GetReportParameters = gql`
  query getReportParameters($organizationId: ID!, $reportId: ID!) {
    getReportParameters(organizationId: $organizationId, reportId: $reportId) {
      ...ReportParameterFields
    }
  }
  ${ReportParameterFields}
`;

export const GetReportParameterData = gql`
  query getReportParameterData(
    $organizationId: ID!
    $reportParameterId: ID!
    $reportContextParameters: ReportContextParameters!
  ) {
    getReportParameterData(
      organizationId: $organizationId
      reportParameterId: $reportParameterId
      reportContextParameters: $reportContextParameters
    )
  }
`;

export const GetReport = gql`
  query getReport($request: ReportRequest!, $parameters: String!, $reportName: String!) {
    getReport(request: $request, parameters: $parameters, reportName: $reportName) {
      pointer
      error
    }
  }
`;

export const GetProductionReport = gql`
  query getProductionReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetProduction(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...ProductionReportFields
    }
  }
  ${ProductionReportFields}
`;

export const GetStatementReport = gql`
  query getStatementReport($organizationId: ID!, $practiceId: ID!, $reportParameters: RptStatementParameters!) {
    rptGetStatements(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...StatementReportFields
    }
  }
  ${StatementReportFields}
`;

export const GetPatientVaccineReportAR = gql`
  query getPatientVaccineReport($organizationId: ID!, $practiceId: ID!, $patientId: ID!) {
    rptGetPatientVaccine(organizationId: $organizationId, practiceId: $practiceId, patientId: $patientId) {
      ...PatientVaccineReportFields
    }
  }
  ${PatientVaccineReportFields}
`;

export const GetInvoiceReport = gql`
  query getInvoiceReport($organizationId: ID!, $practiceId: ID!, $invoiceId: ID!, $contactId: ID!, $footer: String) {
    rptGetInvoice(
      organizationId: $organizationId
      practiceId: $practiceId
      invoiceId: $invoiceId
      contactId: $contactId
      footer: $footer
    ) {
      ...InvoiceReportFields
    }
  }
  ${InvoiceReportFields}
`;

export const GetCommunicationLogReport = gql`
  query getCommunicationLogReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetCommunicationLog(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...CommunicationLogReportFields
    }
  }
  ${CommunicationLogReportFields}
`;

export const GetControlledDrugLogReport = gql`
  query getControlledDrugLogReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetControlledDrugLog(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...ControlledDrugLogReportFields
    }
  }
  ${ControlledDrugLogReportFields}
`;

export const GetDailyInvoiceActivityReport = gql`
  query getDailyInvoiceActivityReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetDailyInvoiceActivity(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...DailyInvoiceActivityReportFields
    }
  }
  ${DailyInvoiceActivityReportFields}
`;

export const GetGeneralLedgerReport = gql`
  query getGeneralLedgerReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetGeneralLedger(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...GeneralLedgerReportFields
    }
  }
  ${GeneralLedgerReportFields}
`;

export const GetInvoiceActivityReport = gql`
  query getInvoiceActivityReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetInvoiceActivity(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...InvoiceActivityReportFields
    }
  }
  ${InvoiceActivityReportFields}
`;

export const GetInvoiceActivityByTrainerReport = gql`
  query getInvoiceActivityByTrainerReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptInvoiceActivityByTrainerParameters!
  ) {
    rptGetInvoiceActivityByTrainer(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...InvoiceActivityByTrainerReportFields
    }
  }
  ${InvoiceActivityByTrainerReportFields}
`;

export const GetLedgerEntryReport = gql`
  query getLedgerEntryReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetLedgerEntry(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...LedgerEntryReportFields
    }
  }
  ${LedgerEntryReportFields}
`;

export const GetPaymentsReport = gql`
  query getPaymentsReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetPayments(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...PaymentsReportFields
    }
  }
  ${PaymentsReportFields}
`;

export const GetSubscriptionsReport = gql`
  query getSubscriptionsReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetSubscriptions(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...SubscriptionsReportFields
    }
  }
  ${SubscriptionsReportFields}
`;

export const GetTaxReport = gql`
  query getTaxReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetTax(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...TaxReportFields
    }
  }
  ${TaxReportFields}
`;

export const GetVaccineActivityReport = gql`
  query getVaccineActivityReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetVaccineActivity(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...VaccineActivityReportFields
    }
  }
  ${VaccineActivityReportFields}
`;

export const GetARReport = gql`
  query getARReport($organizationId: ID!, $practiceId: ID!, $effectiveDate: String!) {
    rptGetAR(organizationId: $organizationId, practiceId: $practiceId, effectiveDate: $effectiveDate) {
      ...ARReportFields
    }
  }
  ${ARReportFields}
`;

export const GetContactPaymentARReport = gql`
  query getContactPaymentARReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptByContactOrPatientAndServiceParameters!
  ) {
    rptGetContactPayment(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...ContactPaymentDetailReportFields
    }
  }
  ${ContactPaymentDetailReportFields}
`;

export const GetSubscriptionPaymentsReport = gql`
  query getSubscriptionPaymentsReport($reportParameters: DateRange!) {
    rptGetSubscriptionPayments(reportParameters: $reportParameters) {
      ...SubscriptionPaymentsReportFields
    }
  }
  ${SubscriptionPaymentsReportFields}
`;

export const GetRemindersByContactReport = gql`
  query getRemindersByContactReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptReminderByContactParameters!
  ) {
    rptGetRemindersByContact(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...RemindersByContactReportFields
    }
  }
  ${RemindersByContactReportFields}
`;

export const GetPatientProcedureByContactReport = gql`
  query getPatientProcedureByContactReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptByContactOrPatientAndServiceParameters!
  ) {
    rptGetPatientProcedureByContact(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...PatientProcedureByContactReportFields
    }
  }
  ${PatientProcedureByContactReportFields}
`;

export const GetPatientProcedureReport = gql`
  query getPatientProcedureReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptByContactOrPatientAndServiceParameters!
  ) {
    rptGetPatientProcedure(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...PatientProcedureReportFields
    }
  }
  ${PatientProcedureReportFields}
`;

export const GetNewContactsAndPatientsReport = gql`
  query getNewContactsAndPatientsReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetNewContactsAndPatients(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...NewContactsAndPatientsReportFields
    }
  }
  ${NewContactsAndPatientsReportFields}
`;

export const GetVaccineByContactReport = gql`
  query getVaccineByContactReport(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptByContactOrPatientAndServiceParameters!
  ) {
    rptGetVaccineByContact(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...VaccineByContactReportFields
    }
  }
  ${VaccineByContactReportFields}
`;

export const GetTopSalesReport = gql`
  query getTopSalesReport($organizationId: ID!, $practiceId: ID!, $reportParameters: DateRange!) {
    rptGetTopSales(organizationId: $organizationId, practiceId: $practiceId, reportParameters: $reportParameters) {
      ...RptTopSalesFields
    }
  }
  ${RptTopSalesFields}
`;

export const GetSubscriptionInvoice = gql`
  query getSubscriptionInvoice(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptSubscriptionInvoiceParameters!
  ) {
    rptGetSubscriptionInvoice(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...RptSubscriptionsInvoiceFields
    }
  }
  ${RptSubscriptionsInvoiceFields}
`;

export const GetPatientHistoryReportAR = gql`
  query rptGetPatientHistory(
    $organizationId: ID!
    $practiceId: ID!
    $reportParameters: RptByContactOrPatientAndServiceParameters!
  ) {
    rptGetPatientHistory(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...PatientHistoryReportFields
    }
  }
  ${PatientHistoryReportFields}
`;

export const GetContactReceiptReportAR = gql`
  query rptGetContactReceipt($organizationId: ID!, $practiceId: ID!, $reportParameters: RptContactReceiptParameters!) {
    rptGetContactReceipt(
      organizationId: $organizationId
      practiceId: $practiceId
      reportParameters: $reportParameters
    ) {
      ...ContactReceiptFields
    }
  }
  ${ContactReceiptFields}
`;

export const GetReminderLetter = gql`
  query getReminderLetter($organizationId: ID!, $practiceId: ID!, $reminderIds: [ID!]!) {
    rptGetReminderLetter(organizationId: $organizationId, practiceId: $practiceId, reminderIds: $reminderIds) {
      ...RptReminderLetterFields
    }
  }
  ${RptReminderLetterFields}
`;

export const GetJournalEntryReport = gql`
  query getJournalEntry($organizationId: ID!, $practiceId: ID!, $journalId: ID!) {
    rptGetJournalEntry(organizationId: $organizationId, practiceId: $practiceId, journalId: $journalId) {
      ...RptGetJournalEntryFields
    }
  }
  ${RptGetJournalEntryFields}
`;

export const GetDepositRunReport = gql`
  query getRptDepositRun($organizationId: ID!, $practiceId: ID!, $depositRunId: ID!) {
    rptGetDeposit(organizationId: $organizationId, practiceId: $practiceId, depositRunId: $depositRunId) {
      ...RptDepositFields
    }
  }
  ${RptDepositFields}
`;
