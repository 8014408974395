import { print } from 'graphql';
import { GetReminders } from '../../../graph/queries/reminders';
import { Reminder, ReminderUpsert } from '../../../graph/types';
import { RxReminder } from '../schemas/reminderSchema';
import { RxPatient } from '../schemas/patientSchema';
import { getOfflineId, getUpdatedDate, OfflineUpsertProps, queryBuilder, replaceExistingRecords } from './queryUtils';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash';
import { OfflineUpsert } from '../../../graph/queries/general';
import { getConnectionId } from '../../../hooks/authHooks';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { addOfflineReminderProperties } from '../../../util/offline/offlineQueryUtils';

export const reminderQueryBuilder = (organizationId: string) => queryBuilder<Reminder>(organizationId, GetReminders);

export const getReminderUpdate = (rxReminder: RxReminder, reminderUpsert: ReminderUpsert) => {
  const newUpsert = { ...rxReminder.upsert, ...reminderUpsert };

  if (newUpsert.record) {
    newUpsert.record.contact_id = rxReminder.contact_id ?? rxReminder.upsert?.record?.contact_id;
    newUpsert.record.patient_id = rxReminder.patient_id ?? rxReminder.upsert?.record?.patient_id;
  }

  const propsToUpdate: OfflineUpsertProps<RxReminder> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    ...omit(newUpsert.record ?? {}, 'contact_id', 'patient_id'),
    ...newUpsert.status_record,
    hidden: !!newUpsert.void,
    updated: getUpdatedDate(),
  };

  return propsToUpdate;
};

export const getReminderInsert = (
  reminderUpsert: ReminderUpsert,
  additionalFields: {
    patient?: RxPatient;
    organizationId: string;
  }
) => {
  const offlineId = getOfflineId();
  const upsert = { ...reminderUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
    if (additionalFields?.patient?.is_new) {
      upsert.record.patient_id = undefined;
      upsert.record.offline_patient_id = additionalFields.patient.offline_id;
    }
  }

  return {
    id: offlineId,
    offline_id: offlineId,
    organization_id: additionalFields.organizationId,
    practice_id: reminderUpsert.record?.practice_id,
    patient_id: additionalFields?.patient?.id || '',
    offline_patient_id: additionalFields?.patient?.offline_id,
    contact_id: reminderUpsert.record?.contact_id,
    name: reminderUpsert.record?.name ?? '',
    date: reminderUpsert.record?.date ?? '',
    note: reminderUpsert.record?.note ?? '',
    status_id: reminderUpsert.status_record?.status_id ?? '',
    status_name: '',
    updated: getUpdatedDate(),
    upsert,
    upsert_offline_id: uuid(),
    hidden: false,
    is_new: true,
  } as RxReminder;
};

export const getReminderPushVariables = (organizationId: string, reminder: RxReminder) => ({
  variables: {
    organizationId,
    upsert: {
      record: {
        type_name: 'upsertReminder',
        offline_id: reminder.offline_id,
        connection_id: getConnectionId(),
        instruction: JSON.stringify({
          ...reminder.upsert,
          id: reminder.is_new ? ID_FOR_OBJECT_CREATION : reminder.id,
        }),
      },
    },
  },
});

export const reminderPushBuilder = (organizationId: string) => (reminder: RxReminder) => ({
  query: print(OfflineUpsert),
  ...getReminderPushVariables(organizationId, reminder),
});

export const onReminderUpdate = async (reminder: Reminder): Promise<RxReminder> => {
  await replaceExistingRecords(reminder, 'reminder');
  return addOfflineReminderProperties(reminder);
};
