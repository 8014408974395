import React, { useEffect } from 'react';
import './App.less';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Main } from './pages/Main/Main';
import { ApolloProvider } from '@apollo/client';
import AppSyncService from './services/AppSyncService/AppSyncService';
import { SignInPage } from './pages/SignIn/SignInPage';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { Registration } from './pages/Registration/Registration';
import { routes } from './constants/routes';
import { SetNewPasswordPage } from './pages/SignIn/SetNewPasswordPage';
import { ResetPasswordPage } from './pages/SignIn/ResetPasswordPage';
import { MaintenancePage } from './pages/Maintenance/MaintenancePage';
import { useCacheBuster } from './hooks/useCacheBuster';
import { UtilsContextProvider } from './components/UtilsProvider/store/state';
import { LocalDatabaseProvider } from './services/LocalDatabaseService/LocalDatabaseProvider';
import { PatientsContextProvider } from './pages/Patients/store/state';
import { PinPage } from './pages/PinPage/PinPage';
import config from './config/config';
import { Logout } from './components/Logout/Logout';
import { ConfigProvider } from 'antd';
import { VetConnectResultHandler } from './pages/VetConnectResultHandler/VetConnectResultHandler';
import QuickBooksCallback from './pages/QuickBooksCallback/QuickBooksCallback';
import { OrganizationContextProvider } from './contexts/organization/state';
import { theme } from './theme';
import { datadogRum } from '@datadog/browser-rum';
import { LDFlagNames } from './constants/launchDarkly';
import { useLDFlag } from './hooks/useLDHooks';

const App: React.FC = () => {
  useCacheBuster();
  const dataDogRUMEnabled = useLDFlag(LDFlagNames.DataDogRUM);

  useEffect(() => {
    if (dataDogRUMEnabled) {
      datadogRum.init({
        applicationId: String(config.APP_DATADOG_APPLICATION_ID),
        clientToken: String(config.APP_DATADOG_CLIENT_TOKEN),
        site: 'datadoghq.com',
        service: 'cassadol',
        env: `cassadol-${process.env.REACT_APP_STAGE}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }, [dataDogRUMEnabled]);

  return (
    <ApolloProvider client={AppSyncService.client}>
      <UtilsContextProvider>
        <LocalDatabaseProvider>
          <Switch>
            <Route path={routes.login}>
              <SignInPage />
            </Route>
            <Route path={routes.logout}>
              <Logout />
            </Route>
            <Route path={routes.signup}>
              <OrganizationContextProvider>
                <Registration />
              </OrganizationContextProvider>
            </Route>
            <Route path={routes.setNewPassword}>
              <SetNewPasswordPage />
            </Route>
            <Route path={routes.resetPassword}>
              <ResetPasswordPage />
            </Route>
            <Route path={routes.maintenance}>
              <MaintenancePage />
            </Route>
            <Route path={routes.pin}>
              <PinPage />
            </Route>
            <Route path={routes.vetConnectResultHandler} component={VetConnectResultHandler} />
            <Route path={routes.quickBooksCallback} component={QuickBooksCallback} />
            <ProtectedRoute path={routes.root}>
              <OrganizationContextProvider>
                <PatientsContextProvider>
                  <Main />
                </PatientsContextProvider>
              </OrganizationContextProvider>
            </ProtectedRoute>
          </Switch>
        </LocalDatabaseProvider>
      </UtilsContextProvider>
    </ApolloProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </Router>
);

export default withLDProvider({
  clientSideID: config.LAUNCHDARKLY_CLIEND_ID ?? '',
  options: {
    streaming: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  context: {
    kind: 'user',
    key: 'anonymous_key',
    name: 'anonymous_user_name',
  },
})(AppWithRouter);
