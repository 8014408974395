import { Button, Form, Space } from 'antd';
import React, { PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';
import { translations } from '../../constants/translations';
import {
  FilterField,
  FilterTag,
  FilterText,
  FilterWrapper,
  HeaderSpan,
  InputSearchStyle,
} from './TitleWithSearchBox.style';

interface TitleWithSearchBoxProps extends PropsWithChildren<unknown> {
  title: string;
  searchBoxPlaceholder: string;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: (key?: string) => void;
  onSearch?: (searchValue: string) => void;
  loading?: boolean;
  containerStyle?: CSSProperties;
  showTooManyResultsWarning?: boolean;
  tags?: {
    key: string;
    name: string;
  }[];
}

export const TitleWithSearchBox: React.FC<TitleWithSearchBoxProps> = ({
  title,
  searchBoxPlaceholder,
  onSearchValueChange,
  loading,
  onSearch,
  onClear,
  containerStyle,
  showTooManyResultsWarning,
  tags,
}) => {
  const [form] = Form.useForm();

  const handleOnClear = () => {
    form.resetFields();
    onClear();
  };

  return (
    <>
      <Space direction='vertical' style={containerStyle} align={'start'}>
        <Space>
          <HeaderSpan>{title}</HeaderSpan>
          <Form form={form} layout={'inline'} style={{ display: 'flex', alignItems: 'center' }} autoComplete='off'>
            <Form.Item name='searchInput' style={{ marginRight: '2px' }}>
              <InputSearchStyle
                placeholder={searchBoxPlaceholder}
                autoFocus
                loading={loading}
                onChange={onSearchValueChange}
                onSearch={onSearch}
              />
            </Form.Item>
            {tags && (
              <>
                <Button onClick={handleOnClear} style={{ width: 80 }}>
                  {translations.shared.clearAllButtonText}
                </Button>
                <FilterWrapper>
                  <FilterText>{translations.shared.filterText}</FilterText>
                  <FilterField>
                    {tags.map(({ key, name }) => (
                      <FilterTag key={key} closable onClose={() => onClear(key)}>
                        {name}
                      </FilterTag>
                    ))}
                  </FilterField>
                </FilterWrapper>
              </>
            )}
          </Form>
        </Space>
        {showTooManyResultsWarning && (
          <Space>
            <div
              style={{
                fontWeight: 'initial',
                fontSize: 'initial',
                lineHeight: 'initial',
                color: 'red',
              }}
            >
              {translations.shared.tooManyResults}
            </div>
          </Space>
        )}
      </Space>
    </>
  );
};
