import React, { useMemo, useState, useCallback } from 'react';
import { TableWithCustomFiltering } from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import { translations } from '../../../../constants/translations';
import { FlexContainer } from '../../../../globalStyles.style';
import {
  Patient,
  PracticeDto,
  Prescription,
  PrescriptionFillUpsert,
  PrescriptionFlattened,
  PrescriptionUpsert,
} from '../../../../graph/types';
import {
  useDeletePrescription,
  useGetPrescriptions,
  useGetPrescriptionsFlattened,
  usePrescritionOfflineMutation,
  useUpdatePrescriptionFill,
} from '../../../../hooks/ajax/prescriptionHooks/prescriptionHooks';
import { OwnerType } from '../../../../hooks/ajax/recordHooks';
import { useGetOrganizationIdFromRoute, useGetPatientIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { TableKey, useTableResetFilterAndSort } from '../../../../hooks/tableHooks';
import UpdateItemModal from './UpdateItemModal';
import { useColumns } from './columns';
import { Container } from './PatientPrescription.styles';
import {
  DropdownButtonWithMenu,
  getMenuItemPropsWithOnCancelPopconfirm,
} from '../../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../../hooks/route/navigationHooks';
import { routes } from '../../../../constants/routes';
import { Button } from 'antd';
import { displayAsDate } from '../../../../constants/formats';
import styled from 'styled-components';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { isEmpty } from 'lodash';
import { usePrescriptionPrint } from '../../../../hooks/usePrescriptionPrint';
import { ExpandableSection } from '../../../../components/ExpandableSection/ExpandableSection';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { EditFillInstructionsModal } from '../../../../components/EditFillInstructionsModal/EditFillInstructionsModal';
import { useOfflineAtomicUpdate } from '../../../../hooks/localDatabaseHooks';
import { RxPrescriptionFlattened } from '../../../../services/LocalDatabaseService/schemas/prescriptionFlattenedSchema';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';
import dayjs from 'dayjs';

type Props = {
  patient: Patient;
  practice?: PracticeDto;
};

const InlineInfo = styled.div`
  display: flex;
  background: transparent;
  button {
    margin: -4px 8px;
    padding: 0;
  }
  .dateSpan {
    margin-right: 8px;
    padding: 0;
  }
`;

export const PatientPrescriptions: React.FC<Props> = ({ patient, practice }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const patientId = useGetPatientIdFromRoute();
  const variables = useMemo(() => ({ id: patientId }), [patientId]);
  const { prescriptions, prescriptionsLoading } = useGetPrescriptions(organizationId, variables, OwnerType.Patient, {
    returnHidden: false,
  });
  const { prescriptionsFlattened } = useGetPrescriptionsFlattened(organizationId, variables, OwnerType.Patient, {
    returnHidden: false,
  });
  const filteredPrescriptions = prescriptions
    ?.filter((prescription) => !prescription.hidden)
    .sort((a, b) => (dayjs(b.created).isAfter(dayjs(a.created)) ? 1 : -1));

  const columns = useColumns();
  const { navigateTo } = useNavigationToRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const { filteredValue, tableChangeHandler } = useTableResetFilterAndSort();
  const deletePrescription = useDeletePrescription(organizationId, patientId);
  const offlineUpdate = usePrescritionOfflineMutation();
  const { enabledAndOffline } = useOffline();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState<Prescription | undefined>();
  const { openPrinterModal, renderPrintModal } = usePrescriptionPrint(patient);

  const showFills = useLDFlag(LDFlagNames.PrescriptionRefills);

  const [editingFillInstructionsId, setEditingFillInstructionsId] = useState<string>();
  const [updatePrescriptionFill, { loading: isSavingPrescriptionFill }] = useUpdatePrescriptionFill(patient.id);

  const offlinePrescriptionFlattenedUpdate = useOfflineAtomicUpdate<RxPrescriptionFlattened>('prescription_flattened');

  const onDeletePrescription = useCallback(
    async (item: Prescription) => {
      setLoading(true);
      if (!enabledAndOffline) {
        await deletePrescription(item.id);
      } else {
        await offlineUpdate({ id: item.id, void: true });
      }
      setLoading(false);
    },
    [deletePrescription, offlineUpdate, enabledAndOffline]
  );

  const toggleModal = useCallback(() => {
    setShowUpdateModal(!showUpdateModal);
  }, [showUpdateModal]);

  const updatedColumns = useMemo(
    () =>
      columns.map((column) => {
        switch (column.key) {
          case 'actions': {
            return {
              ...column,
              render: (item: Prescription) => (
                <DropdownButtonWithMenu
                  menuItemProps={[
                    {
                      title: translations.shared.editButtonText,
                      onClick: () => {
                        toggleModal();
                        setSelectedPrescription(item);
                      },
                      disabled: isNaN(Number(item.id)),
                      tooltip: isNaN(Number(item.id))
                        ? translations.patientPage.prescriptions.editPrescriptionButtonDisabledTooltip
                        : '',
                    },
                    getMenuItemPropsWithOnCancelPopconfirm(
                      {
                        title: translations.shared.deleteButtonText,
                        onClick: () => onDeletePrescription(item),
                      },
                      translations.patientPage.prescriptions.deleteMessage
                    ),
                  ]}
                />
              ),
            };
          }
          default:
            return { ...column, filteredValue: filteredValue[column?.key ?? ''] };
        }
      }),
    [filteredValue, onDeletePrescription, toggleModal, columns]
  );

  const renderExpandableSection = useCallback(
    ({ id, contact_name, instructions }: Prescription) => {
      const prescriptionFills = prescriptionsFlattened
        ?.filter((p) => p.fill_id && p.id === id)
        .sort((a, b) => (dayjs(b.created).isAfter(dayjs(a.created)) ? 1 : -1));

      return (
        <FlexContainer direction='column'>
          <span>
            <b>{translations.patientPage.prescriptions.contact}</b> {contact_name}
          </span>
          <span>
            <b>{translations.patientPage.prescriptions.instructions}</b>
            <TextEditor textContent={instructions || undefined} setTextContent={() => null} editMode={false} readOnly />
          </span>
          {!isEmpty(prescriptionFills) && showFills && (
            <>
              <span style={{ marginBottom: 8 }}>
                <b>{translations.patientPage.prescriptions.fills}</b>
              </span>
              {prescriptionFills?.map((rx: PrescriptionFlattened, index) => (
                <ExpandableSection
                  key={rx.filled_service_rendered_id}
                  containerStyle={{
                    background: 'white',
                    borderTopLeftRadius: index !== 0 ? 0 : '8px',
                    borderTopRightRadius: index !== 0 ? 0 : '8px',
                    borderBottomRightRadius: index === prescriptionFills.length - 1 ? '8px' : 0,
                    borderBottomLeftRadius: index === prescriptionFills.length - 1 ? '8px' : 0,
                    borderBottomWidth: index === prescriptionFills.length - 1 ? 1 : 0,
                  }}
                  contentContainerStyle={{
                    background: 'white',
                    borderTopLeftRadius: index !== 0 ? 0 : '8px',
                    borderTopRightRadius: index !== 0 ? 0 : '8px',
                    borderBottomRightRadius: index === prescriptionFills.length - 1 ? '8px' : 0,
                    borderBottomLeftRadius: index === prescriptionFills.length - 1 ? '8px' : 0,
                    borderBottomWidth: index === prescriptionFills.length - 1 ? 1 : 0,
                  }}
                  contentKey={(rx.filled_service_rendered_id || '') + index}
                  header={
                    <InlineInfo>
                      <span className='dateSpan'>{displayAsDate(rx.filled_date || '', dateFormat)}</span>-
                      <Button
                        type='link'
                        onClick={() => {
                          navigateTo(routes.viewInvoice, withInvoiceIdParameter(rx.invoice_id!));
                        }}
                      >
                        {translations.patientPage.prescriptions.invoice + ' ' + rx.invoice_id!}
                      </Button>
                      <Button
                        type='text'
                        shape='circle'
                        style={{ padding: 5, position: 'absolute', right: 30 }}
                        icon={<EditOutlined />}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setEditingFillInstructionsId(rx?.fill_id || '');
                        }}
                        disabled={isNaN(Number(rx.fill_id))}
                        title={
                          isNaN(Number(rx.fill_id))
                            ? translations.patientPage.prescriptions.editFillButtonDisabledTooltip
                            : ''
                        }
                      ></Button>
                      <Button
                        type='text'
                        shape='circle'
                        style={{ padding: 5, position: 'absolute', right: 0 }}
                        icon={<PrinterOutlined />}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          openPrinterModal(rx);
                        }}
                      ></Button>
                    </InlineInfo>
                  }
                  expandedContent={
                    <>
                      <b>{translations.patientPage.prescriptions.fillsInstructions}</b>
                      {rx.filled_item_instructions && (
                        <TextEditor
                          textContent={rx.filled_item_instructions || undefined}
                          setTextContent={() => null}
                          editMode={false}
                          readOnly
                        />
                      )}
                    </>
                  }
                />
              ))}
            </>
          )}
        </FlexContainer>
      );
    },
    [dateFormat, navigateTo, openPrinterModal, prescriptionsFlattened, showFills]
  );

  if (!practice) {
    return <p>{translations.practiceSettings.noPractice}</p>;
  }

  const handleSaveFillInstruction = async (instructionsText: string) => {
    const editedFill = prescriptionsFlattened?.find((p) => p.fill_id === editingFillInstructionsId);
    if (editedFill) {
      const fillUpsert: PrescriptionFillUpsert = {
        id: editedFill.fill_id,
        record: {
          prescription_id: editedFill.id,
          filled_item_instructions: instructionsText,
          filled_item_din: editedFill.filled_item_din,
          filled_item_lot_number: editedFill.filled_item_lot_number,
          filled_item_serial_number: editedFill.filled_item_serial_number,
          filled_item_bottle_number: editedFill.filled_item_bottle_number,
          filled_item_manufacturer: editedFill.filled_item_manufacturer,
          filled_date: editedFill.filled_date,
          filled_user_id: editedFill.filled_user_id,
          filled_service_rendered_id: editedFill.filled_service_rendered_id,
        },
      };
      if (!enabledAndOffline) {
        await updatePrescriptionFill({
          variables: {
            organizationId,
            prescriptionFill: fillUpsert,
          },
        });
      } else {
        await offlinePrescriptionFlattenedUpdate(editedFill.fill_id || '', {
          filled_item_instructions: instructionsText,
        });
        const prescriptionUpsert: PrescriptionUpsert = {
          id: editedFill.id,
          fills: [fillUpsert],
        };
        await offlineUpdate(prescriptionUpsert);
      }
    }
    setEditingFillInstructionsId(undefined);
  };

  return (
    <Container>
      <UpdateItemModal
        practiceId={practice?.id}
        patient={patient}
        prescription={selectedPrescription}
        showModal={showUpdateModal}
        onToggleModal={() => {
          toggleModal();
          setSelectedPrescription(undefined);
        }}
      />
      <TableWithCustomFiltering<Prescription>
        columns={updatedColumns}
        tableKey={TableKey.Prescriptions}
        dataSource={filteredPrescriptions}
        rowKey='id'
        loading={prescriptionsLoading || loading}
        onChange={tableChangeHandler}
        expandable={{
          expandedRowRender: renderExpandableSection,
        }}
      />
      {renderPrintModal()}
      {editingFillInstructionsId ? (
        <EditFillInstructionsModal
          fillInstructions={
            prescriptionsFlattened?.find((p) => p.fill_id === editingFillInstructionsId)?.filled_item_instructions
          }
          invoiceText={
            translations.patientPage.prescriptions.invoice +
            ' ' +
            prescriptionsFlattened?.find((p) => p.fill_id === editingFillInstructionsId)?.invoice_id
          }
          onClose={() => setEditingFillInstructionsId(undefined)}
          onSave={handleSaveFillInstruction}
          isSaving={isSavingPrescriptionFill}
        />
      ) : null}
    </Container>
  );
};
