import { FinancialPeriod } from '../../graph/types';
import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import {
  FinancialPeriodStatusNameKeys,
  financialPeriodsStatusNameKeyTranslation,
  translations,
} from '../../constants/translations';
import { FinancialPeriodProperty, financialPeriodPropertyNames } from '../../constants/propertyNames';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { SortOrder } from 'antd/es/table/interface';

export const columnKeys = {
  begin_date: financialPeriodPropertyNames.begin_date,
  end_date: financialPeriodPropertyNames.end_date,
  status_name_key: financialPeriodPropertyNames.status_name_key,
  actions: 'actions',
};

export const basicFinancialPeriodColumns: Record<FinancialPeriodProperty, CustomColumnType<FinancialPeriod>> = {
  begin_date: {
    key: columnKeys.begin_date,
    title: translations.financialPeriods.columns.begin_date,
    dataIndex: financialPeriodPropertyNames.begin_date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.financialPeriods.columns.begin_date
    ),
    onFilter: getOnFilterFunctionFor(financialPeriodPropertyNames.begin_date),
    sorter: getStringCompareFunctionFor(financialPeriodPropertyNames.begin_date),
  },
  end_date: {
    key: columnKeys.end_date,
    title: translations.financialPeriods.columns.end_date,
    dataIndex: financialPeriodPropertyNames.end_date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.financialPeriods.columns.end_date
    ),
    onFilter: getOnFilterFunctionFor(financialPeriodPropertyNames.end_date),
    sorter: getStringCompareFunctionFor(financialPeriodPropertyNames.end_date),
    defaultSortOrder: 'desc' as SortOrder,
  },
  status_name_key: {
    key: columnKeys.status_name_key,
    title: translations.financialPeriods.columns.status_name_key,
    dataIndex: financialPeriodPropertyNames.status_name_key,
    ...generalFilteringAndSortingSettings,
    filters: [
      {
        text: financialPeriodsStatusNameKeyTranslation(FinancialPeriodStatusNameKeys.Closed),
        value: FinancialPeriodStatusNameKeys.Closed,
      },
      {
        text: financialPeriodsStatusNameKeyTranslation(FinancialPeriodStatusNameKeys.Pending),
        value: FinancialPeriodStatusNameKeys.Pending,
      },
    ],
    onFilter: (value, record) => record.status_name_key === value,
    sorter: getStringCompareFunctionFor(financialPeriodPropertyNames.status_name_key),
  },
};
