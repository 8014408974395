import { SavingProps } from '../../SaveSpinner/SaveSpinner';
import { TextConfirmModalProps } from '../../TextConfirmModal/TextConfirmModal';

export type UtilsAction = SetSaveSpinnerProps | SetTextConfirmModalProps | SetOrganizationIdProps;

export const setSaveSpinnerType = 'UTILS__SAVE_SPINNER';
export const setTextConfirmType = 'UTILS__SET_TEXT_CONFIRM';
export const setOrganizationIdType = 'UTILS__SET_ORG_ID';
export interface SetSaveSpinnerProps {
  type: typeof setSaveSpinnerType;
  payload: SavingProps;
}

export const setSaveSpinnerAction = (payload: SavingProps): SetSaveSpinnerProps => ({
  type: setSaveSpinnerType,
  payload,
});

export interface SetTextConfirmModalProps {
  type: typeof setTextConfirmType;
  payload: TextConfirmModalProps;
}

export const setTextConfirmAction = (payload: TextConfirmModalProps): SetTextConfirmModalProps => ({
  type: setTextConfirmType,
  payload,
});

export interface SetOrganizationIdProps {
  type: typeof setOrganizationIdType;
  payload: string;
}

export const setOrganizationIdAction = (payload: string): SetOrganizationIdProps => ({
  type: setOrganizationIdType,
  payload,
});
