import { gql } from '@apollo/client';

export const ElectronicPaymentResultFields = gql`
  fragment ElectronicPaymentResultFields on ElectronicPaymentResult {
    id
    success
    status
    message
    cancel_error
    ledger_id
    client_token
    url
    eventually_due
    processor_customer_id
    processor_record_id
  }
`;
