import React from 'react';
import { translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { recipientsFieldName, subjectFieldName } from './StandardEmailFormFields';
import { EmailModal } from './EmailModal';
import { Contact, Ledger, QueryRptGetContactReceiptArgs } from '../../graph/types';
import { getLedgerReceiptAsEmailAttachment } from '../../pages/Contacts/contactLedgerUtils';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetContactReceiptReportAR } from '../../graph/queries/reports';
import { Reports } from '../../util/reportUtils';
import { getActiveReport } from '../../util/activeReportUtils';
import { fetchS3UploadUrl } from '../../util/fileUtils';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';

interface EmailModalForLedgerReceiptProps {
  onClose: () => void;
  ledger: Ledger;
  contact: Contact;
}

export const EmailModalForLedgerReceipt: React.FC<EmailModalForLedgerReceiptProps> = ({ onClose, ledger, contact }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { displayCurrency, displayDate } = useCurrencyAndDateSetting();
  const useActiveReportsContactReceiptVersion = useLDFlag(LDFlagNames.ActiveReportsContactReceipt);

  const fileName = `${ledger.number}_Receipt.pdf`;

  const initialValues = {
    [subjectFieldName]: `${ledger.number} Receipt`,
    [recipientsFieldName]: contact.email ? [contact.email] : [],
  };

  const generateARReportAttachment = async (
    variables: QueryRptGetContactReceiptArgs,
    fileName: string,
    displayCurrency: (value: number) => string,
    displayDate: (value: string) => string
  ) => {
    const { data } = await AppSyncService.client.query({
      query: GetContactReceiptReportAR,
      variables,
      fetchPolicy: 'no-cache',
    });

    const reportName = Reports.ContactReceipt;
    const reportBlob = await getActiveReport({
      reportName,
      data,
      nestedHeader: true,
      displayCurrency,
      displayDate,
    });
    if (!reportBlob) {
      return undefined;
    }

    const uploadUrl = await fetchS3UploadUrl(variables.organizationId);
    await fetch(uploadUrl.url, { method: 'PUT', body: new File([reportBlob], fileName) });

    return {
      file_pointer: uploadUrl.filePointer,
      file_name: fileName,
    };
  };

  const fetchAttachments = async () => {
    const invoiceReportAsEmailAttachment = await getLedgerReceiptAsEmailAttachment({
      ledgerId: ledger.id,
      contactId: ledger.contact_id ?? '',
      request: { practiceId, organizationId, fileName },
    });

    if (!invoiceReportAsEmailAttachment) {
      return [];
    }

    return [invoiceReportAsEmailAttachment];
  };

  const fetchActiveReportsAttachments = async () => {
    const contactReceiptReportAsEmailAttachment = await generateARReportAttachment(
      {
        organizationId,
        practiceId,
        reportParameters: {
          ledger_id: ledger.id,
          contact_id: contact.id,
        },
      },
      fileName,
      displayCurrency,
      displayDate
    );

    if (!contactReceiptReportAsEmailAttachment) {
      return [];
    }

    return [contactReceiptReportAsEmailAttachment];
  };
  return (
    <EmailModal
      title={translations.emailModalForLedgerReceipt.title}
      visible
      onClose={onClose}
      attachments={[fileName]}
      initialValues={initialValues}
      onAttachmentsFetch={useActiveReportsContactReceiptVersion ? fetchActiveReportsAttachments : fetchAttachments}
    />
  );
};
