import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import { translations } from '../../constants/translations';
import { CheckOutlined, CreditCardOutlined, LinkOutlined, ShopOutlined, UserAddOutlined } from '@ant-design/icons';
import './Registration.less';
import { RegisterUserForm } from './RegisterUserForm/RegisterUserForm';
import { Confirmation } from './Confirmation/Confirmation';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { RegisterPracticeForm } from './RegisterPracticeForm/RegisterPracticeForm';
import { RegistrationStep } from './RegistrationStep/RegistrationStep';
import { RegisterSubscriptionSettings } from './RegisterSubscriptionSettings/RegisterSubscriptionSettings';
import { RegistrationCardConnect } from './RegisterCardConnect/RegistrationCardConnect';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Loading } from '../../components/Loading/Loading';
import { RegistrationStripe } from './RegistrationStripe/RegistrationStripe';
import { useGetOrganizations } from '../../hooks/ajax/organization/organizationHooks';
import { Practice } from '../../graph/types';

const { Step } = Steps;

export enum RegistrationSteps {
  User,
  Confirmation,
  Practice,
  Subscription,
  PaymentProcessor,
}

interface RegistrationLocationState {
  initialStep?: number;
  email?: string;
  password?: string;
}

export const Registration: React.FC = () => {
  const location = useLocation<RegistrationLocationState>();
  const [currentStep, setCurrentStep] = useState(RegistrationSteps.User);
  const [isSaving, setIsSaving] = useState(false);
  const [isOrganizationSetup, setIsOrganizationSetup] = useState(false);
  const [credentials, setCredentials] = useState<{ email: string; password: string }>();
  const useStripeCreditCard = useLDFlag(LDFlagNames.StripeSubscriptions);
  const isStripePracticePayment = useLDFlag(LDFlagNames.StripePracticePayment);

  const query = new URLSearchParams(location.search);
  const username = query.get('username') ? atob(query.get('username')!) : undefined;
  const code = query.get('code');

  useEffect(() => {
    const { state } = location;
    if (state?.initialStep) {
      setCurrentStep(state.initialStep);
    }
    if (state?.email && state?.password) {
      setCredentials({ email: state.email, password: state.password });
    }
  }, [location, location.state]);

  useEffect(() => {
    if (username || code) {
      setCurrentStep(RegistrationSteps.Confirmation);
    }
  }, [username, code]);

  const steps = [
    {
      title: translations.registration.registerUserStep.title,
      content: (
        <GoogleReCaptchaProvider reCaptchaKey='6LcH7-IZAAAAAAyY3pBlzjUQNo9Dc27QcNCd7t18'>
          <RegisterUserForm
            goToNextStep={() => setCurrentStep(RegistrationSteps.Confirmation)}
            setIsSaving={setIsSaving}
            setCredentials={setCredentials}
          />
        </GoogleReCaptchaProvider>
      ),
      icon: <UserAddOutlined />,
      iconTitle: translations.registration.registerUserStep.cardTitle,
    },
    {
      title: translations.registration.confirmationStep.title,
      content: (
        <Confirmation
          goToNextStep={() => setCurrentStep(RegistrationSteps.Practice)}
          code={code}
          email={credentials?.email || username!}
          password={credentials?.password}
          setIsSaving={setIsSaving}
        />
      ),
      icon: <CheckOutlined />,
      iconTitle: translations.registration.confirmationStep.title,
    },
    {
      title: translations.registration.addPracticeInfoStep.title,
      content: (
        <RegisterPracticeForm
          goToNextStep={() => setCurrentStep(RegistrationSteps.Subscription)}
          setIsSaving={setIsSaving}
          setIsOrganizationSetup={setIsOrganizationSetup}
        />
      ),
      icon: <ShopOutlined />,
      iconTitle: translations.registration.addPracticeInfoStep.labels.cardTitle,
    },
    {
      title: translations.registration.subscriptionStep.title,
      content: (
        <RegisterSubscriptionSettings
          goToNextStep={() => setCurrentStep(RegistrationSteps.PaymentProcessor)}
          setIsSaving={setIsSaving}
          useStripeCreditCard={useStripeCreditCard}
        />
      ),
      icon: <CreditCardOutlined />,
      iconTitle: translations.registration.subscriptionStep.labels.cardTitle,
    },
    {
      title: isStripePracticePayment
        ? translations.registration.stripe.title
        : translations.registration.cardConnect.title,
      content: <RegistrationContent isStripeProcessor={isStripePracticePayment} />,
      icon: <LinkOutlined />,
      iconTitle: isStripePracticePayment
        ? translations.registration.stripe.title
        : translations.registration.cardConnect.title,
    },
  ];

  const { content, icon, iconTitle, title } = steps[currentStep];

  return (
    <SaveSpinner
      isSaving={isSaving}
      savingMessage={
        isOrganizationSetup
          ? translations.registration.addPracticeInfoStep.organizationSetupMessage
          : translations.loadingComponent.saving
      }
    >
      <div style={{ height: '100vh', position: 'relative' }}>
        <Steps
          type={'navigation'}
          current={currentStep}
          style={{ backgroundColor: '#fff', borderBottom: '1px solid #f0f0f0', position: 'absolute', zIndex: 100 }}
        >
          {steps.map(({ title }) => (
            <Step key={title} title={title} />
          ))}
        </Steps>

        <RegistrationStep icon={icon} iconTitle={iconTitle} content={content} cardTitle={title} />
      </div>
    </SaveSpinner>
  );
};

const RegistrationContent: React.FC<{ isStripeProcessor?: boolean }> = ({ isStripeProcessor }) => {
  const { organizations, organizationsLoading } = useGetOrganizations('network-only');

  if (organizationsLoading) {
    return <Loading height={200} />;
  }

  const organization = organizations?.[0];
  const organizationId = organization?.id;
  const practiceId = organizations?.[0]?.default_practice_id;
  const practice = organization?.practice.find((p) => p.id === practiceId);

  if (!organizationId || !practiceId) {
    return <p>{translations.registration.cardConnect.loadingError}</p>;
  }

  return isStripeProcessor ? (
    <RegistrationStripe<Practice> practice={practice} organizationId={organizationId} canSkipRegistration />
  ) : (
    <RegistrationCardConnect organizationId={organizationId} practiceId={practiceId} />
  );
};
