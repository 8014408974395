import { Phone, PhoneUpsert } from '../../graph/types';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import { cloneDeepWithExclude } from '../../util/cloneDeepWithExclude';

export const defaultPhoneType = null;

export const mapPhoneUpsertToPhone = (phoneUpsert: PhoneUpsert[], existingPhones?: Phone[]) => {
  let phone: Phone[] = existingPhones ?? [];
  phoneUpsert.forEach((phoneUpsertItem) => {
    phone = phone.filter((phone) => phone.id !== phoneUpsertItem.id);
    if (!phoneUpsertItem.void) {
      phone.push({
        ...cloneDeepWithExclude(phoneUpsertItem, ['void']),
        id: phoneUpsertItem.id || ID_FOR_OBJECT_CREATION,
      });
    }
  });
  return phone;
};

export const generateNewPhoneUpsert = (phoneValue: string) => {
  return [
    {
      id: ID_FOR_OBJECT_CREATION,
      phone_type_id: defaultPhoneType,
      phone: phoneValue,
      primary: true,
      void: false,
    },
  ];
};

export class PhoneUpsertGenerator {
  private readonly initialPhones: Phone[] | undefined;
  private generatedPhoneUpserts: PhoneUpsert[] | null | undefined;

  constructor(initialPhones?: Phone[]) {
    this.initialPhones = initialPhones;
  }

  generateFromUpdatedValues(updatedPhoneValue?: Phone | string, idOfPhone?: string): PhoneUpsert[] | null | undefined {
    if (!idOfPhone && !updatedPhoneValue) {
      return undefined;
    }
    if (updatedPhoneValue) {
      if (typeof updatedPhoneValue === 'string') {
        if (!idOfPhone && updatedPhoneValue) {
          this.createNewPhone(updatedPhoneValue);
        } else if (idOfPhone && updatedPhoneValue) {
          this.updateExistingPhoneIfChanged(idOfPhone, updatedPhoneValue);
        }
      } else {
        const { __typename, phone_type_name, ...phoneUpsertProps } = updatedPhoneValue;
        this.generatedPhoneUpserts = [phoneUpsertProps];
      }
    } else if (!idOfPhone) {
      return undefined;
    } else {
      this.deletePhone(idOfPhone);
    }
    return this.generatedPhoneUpserts;
  }

  private deletePhone(idOfPhone: string) {
    if (this.initialPhones) {
      const phoneToUpdate = this.initialPhones.find((phone) => phone.id === idOfPhone);
      if (phoneToUpdate) {
        this.generatedPhoneUpserts = [
          {
            id: phoneToUpdate.id,
            phone_type_id: phoneToUpdate.phone_type_id,
            phone: phoneToUpdate.phone,
            primary: phoneToUpdate.primary,
            void: true,
          },
        ];
      }
    }
  }

  private updateExistingPhoneIfChanged(idOfPhone: string, updatedPhoneValue: string) {
    if (this.initialPhones) {
      const phoneToUpdate = this.initialPhones.find((phone) => phone.id === idOfPhone);
      if (phoneToUpdate && phoneToUpdate.phone !== updatedPhoneValue) {
        this.generatedPhoneUpserts = [
          {
            id: phoneToUpdate.id,
            phone_type_id: phoneToUpdate.phone_type_id,
            phone: updatedPhoneValue,
            primary: phoneToUpdate.primary,
            void: false,
          },
        ];
      }
    }
  }

  private createNewPhone(updatedPhoneValue: string) {
    this.generatedPhoneUpserts = generateNewPhoneUpsert(updatedPhoneValue);
  }
}
