import gql from 'graphql-tag';
import { JobFields } from '../fragments/jobFragments';

export const OnJobStatusChange = gql`
  subscription onJobStatusChange($id: ID!) {
    onJobStatusChange(id: $id) {
      ...JobFields
    }
  }
  ${JobFields}
`;
