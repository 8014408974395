import { Dispatch, SetStateAction } from 'react';
import { BundleServiceUpsert } from '../../../graph/types';

export type ServicesAction =
  | SetServiceBundleUpsertProps
  | SetSetRemovedServicesProps
  | SetResetBundleListProps
  | SetBundlePromptActiveProps;

export const setBundleUpsertType = 'BUNDLE_UPSERT__SET';
export const setSetRemovedServicesType = 'SET_REMOVED_SERVICES__SET';
export const setResetBundleListType = 'RESET_BUNDLE_LIST__SET';
export const setBundlePromptActiveType = 'SET_BUNDLE_PROMPT_ACTIVE';

export interface SetServiceBundleUpsertProps {
  type: typeof setBundleUpsertType;
  bundleUpsert: BundleServiceUpsert[];
}

export interface SetSetRemovedServicesProps {
  type: typeof setSetRemovedServicesType;
  setRemovedServices: Dispatch<SetStateAction<string[]>>;
}

export interface SetResetBundleListProps {
  type: typeof setResetBundleListType;
  resetBundleList: (() => void) | undefined;
}

export interface SetBundlePromptActiveProps {
  type: typeof setBundlePromptActiveType;
  payload: boolean;
}

export const setBundleUpsert = (bundleUpsert: BundleServiceUpsert[]): SetServiceBundleUpsertProps => ({
  type: setBundleUpsertType,
  bundleUpsert,
});

export const setSetRemovedServices = (
  setRemovedServices: Dispatch<SetStateAction<string[]>>
): SetSetRemovedServicesProps => ({
  type: setSetRemovedServicesType,
  setRemovedServices,
});

export const setResetBundleList = (resetBundleList: (() => void) | undefined): SetResetBundleListProps => ({
  type: setResetBundleListType,
  resetBundleList,
});

export const setBundlePromptActive = (payload: boolean): SetBundlePromptActiveProps => ({
  type: setBundlePromptActiveType,
  payload,
});
