import { InputNumber } from 'antd';
import React from 'react';
import { OrganizationSubscriptionDetailCharge } from '../../graph/types';
import { CustomColumnType, TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { useSubscriptionUpdateColumn } from './subscriptionUpdateColumns';
import { subscriptionPropertyNames } from '../../constants/propertyNames';
import { TableKey } from '../../hooks/tableHooks';
import {
  MAX_NUMBER_OF_SUBSCRIPTION_USERS,
  MIN_NUMBER_OF_SUBSCRIPTION_USERS,
} from '../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../constants/referenceData/organizationSubscriptionReferenceData';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { TrackOfflineUsers } from './util';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetUsers } from '../../hooks/ajax/user/userHooks';
import SubscriptionCurrencyFormatter from '../CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export const userNumberInputTestId = 'user-input-test-id';

interface SubscriptionUpdateTableProps {
  charges: OrganizationSubscriptionDetailCharge[];
  onAddQty: (detailId: string, addQty: number) => void;
  onSetQty: (detailId: string, setQty: number) => void;
  canOnlyAddQty: boolean;
  offlineTable: React.ReactNode;
  countryId?: string | null;
  changedUsers?: Record<string, TrackOfflineUsers>;
}

export const SubscriptionUpdateTable: React.FC<SubscriptionUpdateTableProps> = ({
  charges,
  onAddQty,
  onSetQty,
  canOnlyAddQty,
  offlineTable,
  countryId,
  changedUsers = {},
}) => {
  const subscriptionUpdateColumns = useSubscriptionUpdateColumn(countryId);

  const organizationId = useGetOrganizationIdFromRoute();
  const { users } = useGetUsers(organizationId);

  const getQtyValue = (detail: OrganizationSubscriptionDetailCharge) => {
    let updatedQty = 0;
    Object.values(changedUsers).forEach((user) => {
      const existingUser = users
        ?.find((existingUser) => existingUser?.id === user.userId)
        ?.organization?.find((o) => o.organization_id === organizationId);
      const isUserUpdated = !user.isSubmitted && existingUser?.enable_offline !== user.enableOffline;

      if (!isUserUpdated) {
        return;
      }
      if (user.enableOffline && isUserUpdated) {
        updatedQty += 1;
      } else if (isUserUpdated) {
        updatedQty -= 1;
      }
    });
    if (
      detail.level_id ===
      organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id
    ) {
      return detail.currentQty + updatedQty;
    }
    return detail.newQty ?? detail.currentQty;
  };

  const renderTotal = (value: any, record: OrganizationSubscriptionDetailCharge) => {
    let total = 0;
    if (
      record.level_id ===
      organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id
    ) {
      total = getQtyValue(record) * Number(record.cost);
    } else {
      total = value;
    }

    return <SubscriptionCurrencyFormatter total={total} countryId={countryId} />;
  };

  const columnsForAddingUsers: CustomColumnType<OrganizationSubscriptionDetailCharge>[] = [
    subscriptionUpdateColumns.level_name_key,
    {
      ...subscriptionUpdateColumns.currentQty,
      dataIndex: subscriptionPropertyNames.currentQty,
    },
    {
      ...subscriptionUpdateColumns.addQty,
      render: (detail: OrganizationSubscriptionDetailCharge) => (
        <InputNumber
          type='number'
          min={0}
          max={MAX_NUMBER_OF_SUBSCRIPTION_USERS - detail.currentQty}
          defaultValue={0}
          onChange={(value) => onAddQty(detail.level_id, +(value ?? 0))}
          data-testid={`${userNumberInputTestId}-${detail.level_id}`}
        />
      ),
    },
    subscriptionUpdateColumns.cost,
    {
      ...subscriptionUpdateColumns.total,
      render: renderTotal,
    },
  ];
  const columnsForEditingQuantity: CustomColumnType<OrganizationSubscriptionDetailCharge>[] = [
    subscriptionUpdateColumns.level_name_key,
    {
      ...subscriptionUpdateColumns.currentQty,
      render: (detail: OrganizationSubscriptionDetailCharge) => {
        const isOfflineCharge =
          detail.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id;

        const isPaymentPortalCharge =
          detail.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.PaymentPortalLevel].level_id;

        return (
          <InputNumber
            type='number'
            min={isOfflineCharge ? 0 : MIN_NUMBER_OF_SUBSCRIPTION_USERS}
            disabled={isOfflineCharge || isPaymentPortalCharge}
            max={MAX_NUMBER_OF_SUBSCRIPTION_USERS}
            defaultValue={detail.currentQty}
            value={getQtyValue(detail)}
            onChange={(value) => onSetQty(detail.level_id, +(value ?? 0))}
            data-testid={`${userNumberInputTestId}-${detail.level_id}`}
          />
        );
      },
    },
    subscriptionUpdateColumns.cost,
    {
      ...subscriptionUpdateColumns.total,
      render: renderTotal,
    },
  ];
  const columns = canOnlyAddQty ? columnsForAddingUsers : columnsForEditingQuantity;

  return (
    <TableWithCustomFiltering
      expandable={{
        expandIcon: ({ expanded, onExpand, record, expandable }) => {
          if (expandable) {
            return expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            );
          }

          return null;
        },
        expandedRowRender: () => offlineTable,
        rowExpandable: (record) =>
          record.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id,
      }}
      tableKey={TableKey.SubscriptionUpdate}
      columns={columns}
      dataSource={charges}
      rowKey={'level_id'}
      pagination={false}
    />
  );
};
