import { QueryHookOptions, useMutation } from '@apollo/client';
import { Mutation, MutationUpsertNoteArgs, Note, NotePreview, NoteType, NoteUpsert } from '../../../graph/types';
import { GetNote, GetNotesPreview, UpsertNote } from '../../../graph/queries/notes';
import { useEffect, useMemo, useState } from 'react';
import { useOfflineQueryWithBackendSearch, useOfflineQuery } from '../useOfflineQuery';
import { getNoteInsert, getNoteUpdate } from '../../../services/LocalDatabaseService/queries/noteQueries';
import { useOfflineDelete, useOfflineInsert, useOfflineUpdate } from '../../localDatabaseHooks';
import { RxNote } from '../../../services/LocalDatabaseService/schemas/noteSchema';
import { RxPatient } from '../../../services/LocalDatabaseService/schemas/patientSchema';
import { useRxCollection } from 'rxdb-hooks';
import { useGetCurrentUser } from '../user/userHooks';
import { RxContact } from '../../../services/LocalDatabaseService/schemas/contactSchema';

export const dummyNoteIdForRapidBilling = 'dummyIdForRapidBilling';

export const useGetNote = ({
  organizationId,
  noteId,
  options,
}: {
  organizationId: string;
  noteId: string;
  options?: QueryHookOptions;
}) => {
  const skip = noteId === '' || noteId === dummyNoteIdForRapidBilling;
  const selectorNoteId = skip ? 'non-existing-id' : noteId; // Avoid to find things if note id is invalid
  const selector = useMemo(() => ({ _id: selectorNoteId }), [selectorNoteId]);

  const { data, loading, error, refetch } = useOfflineQuery<'getNote'>({
    query: GetNote,
    collection: 'note',
    options: {
      ...options,
      variables: { organizationId, noteId },
      skip,
      fetchPolicy: 'no-cache',
    },
    selector,
  });

  return {
    note: data && 'note' in data ? data.note : data,
    noteLoading: loading,
    noteError: error,
    noteRefetch: refetch,
  };
};

export const useUpsertNote = () => {
  return useMutation<Mutation, MutationUpsertNoteArgs>(UpsertNote);
};

export const useGetNotesWithSearch = ({
  contactId,
  patientId,
  organizationId,
  searchValue,
  selector = {},
}: {
  contactId?: string;
  patientId?: string;
  organizationId: string;
  searchValue: string;
  selector?: { [key: string]: any };
}) => {
  const variables = useMemo(() => ({ organizationId, patientId, contactId }), [organizationId, patientId, contactId]);
  const { data, loading, setSearchTerm, refetchCurrentSearch } = useOfflineQueryWithBackendSearch<'getNotes'>({
    query: GetNotesPreview,
    collection: 'note',
    variables,
    searchOptions: { value: searchValue, keys: ['value'] },
    queryOptions: {
      skip: organizationId === '',
      fetchPolicy: 'cache-first',
    },
    selector,
    shouldNotAdjustString: true,
  });

  return {
    notes: data as NotePreview[],
    notesLoading: loading,
    setSearchTerm,
    refetchCurrentSearch,
  };
};

export const useNoteOfflineInsert = (params: { patientId: string; contactId: string }) => {
  const offlineInsert = useOfflineInsert<RxNote>('note');
  const [patient, setPatient] = useState<RxPatient>();
  const [contact, setContact] = useState<RxContact>();
  const patientCollection = useRxCollection<RxPatient>('patient');
  const contactCollection = useRxCollection<RxContact>('contact');
  const { user, userLoading } = useGetCurrentUser();

  useEffect(() => {
    if (!params.patientId) {
      return;
    }

    const getPatient = async () => {
      const rxPatient = await patientCollection?.findOne(params.patientId)?.exec();

      if (rxPatient) {
        setPatient(rxPatient);
      }
    };

    getPatient();
  }, [params.patientId, patientCollection, setPatient]);

  useEffect(() => {
    if (!params.contactId) {
      return;
    }

    const getContact = async () => {
      const rxContact = await contactCollection?.findOne(params.contactId)?.exec();

      if (rxContact) {
        setContact(rxContact);
      }
    };

    getContact();
  }, [params.contactId, contactCollection, setContact]);

  if (!offlineInsert || userLoading) {
    return undefined;
  }

  return async (upsert: NoteUpsert, refData?: NoteType[]) => {
    const obj = getNoteInsert(upsert, refData, { patient, contact, createdUserName: user?.name });
    return (await offlineInsert(obj)) as Note;
  };
};

export const useNoteOfflineUpdate = (noteId: string) => {
  const [note, setNote] = useState<RxNote>();
  const noteCollection = useRxCollection<RxNote>('note');
  const offlineUpdate = useOfflineUpdate<RxNote>('note');
  const offlineDelete = useOfflineDelete<RxNote>('note');

  useEffect(() => {
    if (!noteCollection) {
      return;
    }

    const getRecord = async () => {
      const rxNote = await noteCollection.findOne(noteId).exec();
      if (rxNote) {
        setNote(rxNote);
      }
    };

    getRecord();
  }, [noteCollection, setNote, noteId]);

  if (!note || !offlineUpdate) {
    return undefined;
  }

  return async (upsert: NoteUpsert) => {
    if (upsert.void && note.is_new) {
      await offlineDelete(noteId);
      return null;
    }
    const obj = getNoteUpdate(note, upsert);
    return (await offlineUpdate(noteId, obj)) as Note | null;
  };
};
