import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1rem;
`;

export const AddButton = styled(Button)`
  margin-bottom: 1rem;
`;
