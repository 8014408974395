import gql from 'graphql-tag';
import { PaymentPortalResultFields } from '../fragments';

export const UpsertPaymentPortal = gql`
  mutation createPaymentPortal($organizationId: ID!, $practiceId: ID!, $paymentPortalInfo: PaymentPortalInfo!) {
    createPaymentPortal(
      organizationId: $organizationId
      practiceId: $practiceId
      paymentPortalInfo: $paymentPortalInfo
    ) {
      ...PaymentPortalResultFields
    }
  }
  ${PaymentPortalResultFields}
`;
