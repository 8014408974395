import { WarningOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { translations } from '../../../../constants/translations';

export const IntStagingWarningBanner: React.FC = () => {
  return (
    <Alert
      message={<MainText>{translations.nonProdWarning.title}</MainText>}
      description={<SubText dangerouslySetInnerHTML={{ __html: translations.nonProdWarning.message }} />}
      type={'warning'}
      showIcon
      icon={<WarningOutlined style={{ padding: '0.75rem' }} />}
      closable
    />
  );
};

const MainText = styled.div`
  text-align: center;
  font-weight: bold;
`;

const SubText = styled.div`
  text-align: center;
`;
