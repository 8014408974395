import { gql } from '@apollo/client';
import { AddressFields, InfoFields, PhoneFields } from '../fragments';

export const UserFields = gql`
  fragment UserFields on User {
    id
    name
    email
    cognito_id
    global_admin
    inactive
    address {
      ...AddressFields
    }
    phone {
      ...PhoneFields
    }
    info {
      ...InfoFields
    }
    created
    pin
  }
  ${AddressFields}
  ${PhoneFields}
  ${InfoFields}
`;

export const PatientCardFields = gql`
  fragment PatientCardFields on UserOrganizationPatientCard {
    id
    organization_id
    patient_breed_name
    patient_color_name
    patient_deceased
    patient_dob
    patient_gender_name
    patient_id
    patient_inactive
    patient_hidden
    patient_name
    patient_number
    patient_owner_names
    patient_owner_ids
    patient_owner_percentages
    patient_related_names
    patient_related_ids
    patient_related_type_name_keys
    patient_species_name
    sort_order
    type_name_key
    user_id
  }
`;

export const ContactCardFields = gql`
  fragment ContactCardFields on UserOrganizationContactCard {
    id
    contact_id
    type_name_key
    sort_order
    contact_number
    contact_name
    contact_status_id
    contact_status_name
    contact_email
    contact_dob
    contact_tag_names
    contact_type_name_keys
    contact_balance_unposted
    contact_balance_posted
    contact_send_type
    contact_statement_ignore
    contact_hidden
    contact_address {
      ...AddressFields
    }
    contact_phone {
      ...PhoneFields
    }
  }
  ${AddressFields}
  ${PhoneFields}
`;

export const UserOrganizationCards = gql`
  fragment UserOrganizationCards on UserOrganizationCards {
    patient_card {
      ...PatientCardFields
    }
    contact_card {
      ...ContactCardFields
    }
  }
  ${PatientCardFields}
  ${ContactCardFields}
`;

export const UserOrganization = gql`
  fragment UserOrganizationWithPatientCard on User {
    id
    name
    organization(filter: { organization_id: $organizationId }) {
      id
      organization_id
      doctor
    }
  }
`;

export const UserOrganizationWithPermissionAndType = gql`
  fragment UserOrganizationWithPermissionAndType on User {
    organization {
      id
      organization_id
      permission {
        id
        category_name_key
        name_key
        denied
      }
      doctor
      type {
        id
        type_id
        type_name
      }
      organization_administrator
      enable_offline
    }
  }
`;

export const CreateUserFields = gql`
  fragment CreateUserFields on User {
    id
    name
    email
  }
`;
