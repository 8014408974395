import Collapse from 'antd/lib/collapse/Collapse';
import Form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { SaveAndResetButton } from '../../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { PracticeSettingsNameKey, translations } from '../../../constants/translations';
import { InvoiceUpsert } from '../../../graph/types';
import { useGetInvoiceText, useUpdateInvoiceFooter } from '../../../hooks/ajax/invoice/invoiceHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { defaultPractice } from '../../../contexts/organization/selectors';
import { getPracticeSetting } from '../../Contacts/ViewContact/statementUtils';

const INV_FOOTER_TYPE = '1';

export const InvoiceFooter = ({ invoiceId, onChange }: { invoiceId: string; onChange: (value?: string) => void }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const { invoiceText, invoiceTextLoading, invoiceTextRefetch } = useGetInvoiceText(organizationId, invoiceId);
  const { state } = useOrganizationContext();
  const practice = defaultPractice(state);
  const footerSetting = getPracticeSetting(PracticeSettingsNameKey.InvoiceFooter, practice);
  const originalValue = invoiceText?.value ?? footerSetting?.value ?? undefined;
  const [form] = Form.useForm();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [udpateInvoice] = useUpdateInvoiceFooter();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => onChange(originalValue), [originalValue, onChange]);

  const handleChange = (value?: string) => {
    setHasChanges(value !== originalValue);
  };

  const handleFinish = async ({ footer }: { footer: string }) => {
    setLoading(true);
    const invoiceUpsert: InvoiceUpsert = {
      id: invoiceId,
      text: [
        {
          record: {
            type_id: INV_FOOTER_TYPE,
            value: footer,
          },
        },
      ],
    };

    if (invoiceText) {
      invoiceUpsert.text?.push({ id: invoiceText.id, void: true });
    }

    await udpateInvoice({
      variables: {
        organizationId: organization?.id ?? '',
        invoice: invoiceUpsert,
      },
    });
    await invoiceTextRefetch();

    onChange(footer);
    setHasChanges(false);
    setLoading(false);
  };

  if (invoiceTextLoading) {
    return <Loading />;
  }

  return (
    <Collapse>
      <Collapse.Panel key='footer' header={translations.invoicePage.inoviceFooter.title}>
        <SaveSpinner isSaving={loading}>
          <Form form={form} onFinish={handleFinish} initialValues={{ footer: originalValue }} autoComplete='off'>
            <Form.Item name='footer'>
              <TextArea
                placeholder={translations.invoicePage.inoviceFooter.placeholder}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Form.Item>
            {hasChanges && (
              <SaveAndResetButton
                offset={0}
                onReset={() => {
                  handleChange(originalValue);
                  form.resetFields();
                }}
                saveButtonText={translations.shared.saveButtonText}
              />
            )}
          </Form>
        </SaveSpinner>
      </Collapse.Panel>
    </Collapse>
  );
};
