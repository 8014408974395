import { Row } from 'antd';
import React, { useEffect } from 'react';
import { translations } from '../../../constants/translations';
import { EntityCard, OfficeType } from '../doctorOfficeUtil';
import { OverviewCardWrapper } from './DoctorOfficeOverview.styles';
import { OverviewCard } from './OverviewCard/OverviewCard';

interface DoctorOfficeOverviewProps {
  entityCards: EntityCard[];
  onActiveChange: (active: boolean) => void;
  closeEntityCard: (cardId: string) => Promise<void>;
  officeType: OfficeType;
}

export const DoctorOfficeOverview: React.FC<DoctorOfficeOverviewProps> = ({
  entityCards,
  onActiveChange,
  closeEntityCard,
  officeType,
}) => {
  useEffect(() => {
    onActiveChange(true);
    return () => {
      onActiveChange(false);
    };
  }, [onActiveChange]);

  if (entityCards.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <i>{translations.doctorOffice.noCardsFound}</i>
      </div>
    );
  }

  return (
    <Row>
      {entityCards.map((card) => (
        <OverviewCardWrapper key={card.id}>
          <OverviewCard card={card} key={card.id} closeCard={closeEntityCard} officeType={officeType} />
        </OverviewCardWrapper>
      ))}
    </Row>
  );
};
