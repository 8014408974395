import React, { PropsWithChildren, useEffect, useState } from 'react';
import { PracticeDto, PracticeUpsert } from '../../../graph/types';
import { useGetUrlForFileDownload } from '../../../hooks/ajax/fileUrl/fileUrlHooks';
import { translations } from '../../../constants/translations';
import { FileData, getUploadRequestToS3 } from '../../../util/fileUpload';
import { useUpsertPractice } from '../../../hooks/ajax/practice/practiceHooks';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { Loading } from '../../../components/Loading/Loading';
import './PracticeLogo.css';
import practiceLogoPlaceholder from './practiceLogoPlaceholder.png';
import { ImageWithPlaceholder } from '../../../components/ImageWithPlaceholder/ImageWithPlaceholder';
import { UploadDeletePopover } from '../../../components/UploadDeletePopover/UploadDeletePopover';
import { imageFileExtensions } from '../../../constants/fileExtensions';

interface PracticeLogoProps extends PropsWithChildren<unknown> {
  practice: PracticeDto;
}

export const PracticeLogo: React.FC<PracticeLogoProps> = ({ practice }) => {
  const [upsertPractice] = useUpsertPractice();
  const [isSaving, setIsSaving] = useState(false);

  const { fileUrl, fileUrlLoading } = useGetUrlForFileDownload({
    organizationId: practice.organization_id,
    fileName: practice.logo?.name || '',
    filePointer: practice.logo?.pointer || '',
  });

  const [fileUrlSrc, setFileUrlSrc] = useState(fileUrl?.url);

  useEffect(() => {
    setFileUrlSrc(fileUrl?.url);
  }, [fileUrl]);

  if (fileUrlLoading) {
    return <Loading />;
  }

  const upsertPracticeLogoInformation = async (practiceUpsert: PracticeUpsert, successMessage: string) => {
    setIsSaving(true);
    try {
      await upsertPractice({ variables: { practice: practiceUpsert, organizationId: practice.organization_id } });
      showSuccessMessage(successMessage);
    } catch (e) {
      showErrorMessage(e.message ? e.message : translations.shared.saveErrorMessage);
    }
    setIsSaving(false);
  };

  const setNewLogoData = async (fileData: FileData) => {
    const practiceUpsert: PracticeUpsert = {
      id: practice.id,
      logo: {
        id: practice.logo?.id,
        record: { name: fileData.name, pointer: fileData.pointer, size: fileData.size },
      },
    };
    await upsertPracticeLogoInformation(practiceUpsert, translations.practiceSettings.logoSuccessfullyUploaded);
  };

  const removeLogo = async () => {
    const practiceUpsert: PracticeUpsert = {
      id: practice.id,
      logo: { id: practice.logo?.id, void: true },
    };
    await upsertPracticeLogoInformation(practiceUpsert, translations.practiceSettings.logoSuccessfullyRemoved);
    setFileUrlSrc('');
  };

  return (
    <div style={{ marginBottom: '24px', marginTop: '-80px', float: 'right', height: '120px' }}>
      <UploadDeletePopover
        accept={imageFileExtensions.join(',')}
        uploadRequest={getUploadRequestToS3(practice.organization_id, setIsSaving, setNewLogoData, 4)}
        deleteFunction={removeLogo}
        showDeleteButton={!!practice.logo}
        deleteButtonText={translations.practiceSettings.removeLogo}
        deleteConfirmQuestion={translations.practiceSettings.removeLogoConfirm}
        uploadButtonText={
          practice.logo ? translations.practiceSettings.logoUpload : translations.practiceSettings.firstLogoUpload
        }
        isSaving={isSaving}
      >
        <div className={'practiceLogo'}>
          <ImageWithPlaceholder
            imageProps={{ src: fileUrlSrc, alt: translations.practiceSettings.practiceLogo }}
            placeholderSrc={practiceLogoPlaceholder}
          />
        </div>
      </UploadDeletePopover>
    </div>
  );
};
