import { translations } from '../../constants/translations';

const googleUrl = 'https://support.google.com/accounts/answer/185833?hl=en';
const outlookUrl =
  'https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944';
const yahooUrl = 'https://help.yahoo.com/kb/SLN15241.html?guccounter=1';
const aolUrl = 'https://help.aol.com/articles/Create-and-manage-app-password';

const office365Link1 =
  'https://learn.microsoft.com/en-us/exchange/clients-and-mobile-in-exchange-online/authenticated-client-smtp-submission';
const office365Link2 =
  'https://support.microsoft.com/en-us/account-billing/manage-app-passwords-for-two-step-verification-d6dc8c6d-4bf7-4851-ad95-6d07799387e9';
const office365Link3 =
  'https://learn.microsoft.com/en-us/exchange/mail-flow-best-practices/how-to-set-up-a-multifunction-device-or-application-to-send-email-using-microsoft-365-or-office-365#option-1-authenticate-your-device-or-application-directly-with-a-microsoft-365-or-office-365-mailbox-and-send-mail-using-smtp-auth-client-submission';

const google = {
  key: 1,
  title: translations.emailSettingsPage.gmail.title,
  description: translations.emailSettingsPage.gmail.description(googleUrl).join('<br>'),
};

const outlook = {
  key: 2,
  title: translations.emailSettingsPage.outlook.title,
  description: translations.emailSettingsPage.outlook.description(outlookUrl).join('<br>'),
};

const yahoo = {
  key: 3,
  title: translations.emailSettingsPage.yahoo.title,
  description: translations.emailSettingsPage.yahoo.description(yahooUrl).join('<br>'),
};

const aol_mail = {
  key: 4,
  title: translations.emailSettingsPage.aol_mail.title,
  description: translations.emailSettingsPage.aol_mail.description(aolUrl).join('<br>'),
};

const office365 = {
  key: 5,
  title: translations.emailSettingsPage.office365.title,
  description: translations.emailSettingsPage.office365
    .description(office365Link1, office365Link2, office365Link3)
    .join('<br>'),
};

const custom_domains = {
  key: 6,
  title: translations.emailSettingsPage.custom_domains.title,
  description: translations.emailSettingsPage.custom_domains.description.join(''),
};

export const emailSettings = [google, outlook, yahoo, aol_mail, office365, custom_domains];
