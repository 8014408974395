import React from 'react';
import { FormItemProps } from 'antd';
import { translations } from '../../constants/translations';
import { PatientReferenceData, Service } from '../../graph/types';
import { getRequiredRule } from '../../util/forms';
import dayjs, { Dayjs } from 'dayjs';
import { TitleDiv } from '../../globalStyles.style';
import { MicrochipModalContent } from '../MicrochipModalContent/MicrochipModalContent';
import { DeceasedDatePickerFormItem } from '../../pages/Patients/ViewPatient/PatientDetails/DeceasedDatePickerFormItem/DeceasedDatePickerFormItem';
import { FormInstance } from 'antd/lib/form';
import { RequirementsComponent } from '../RequirementsComponent/RequirementsComponent';
import {
  HisaFieldsRequirement,
  HisaRequirementsContent,
} from '../ServiceRendered/HisaRequirementsModal/HisaRequirementsContent';
import { getNameKey } from '../ServiceRendered/serviceRenderedUtils';

export interface PatientModalFormValues {
  breed_id?: string;
  gender_id?: string;
  dob?: Dayjs;
  deceased_date?: Dayjs;
}

type PatientModalContentType = {
  patientRef?: PatientReferenceData;
  missingFields?: Partial<PatientFieldsRequirement>;
  speciesId?: string;
  patientId?: string;
  deceasedDateVisible?: boolean;
  form: FormInstance<any>;
  patientDOB?: string;
  shouldAutofocus?: boolean;
  service?: Service;
  requiredHisaFields?: HisaFieldsRequirement;
  isForRapidBilling?: boolean;
};

export interface PatientFieldsRequirement {
  requiresBreed: boolean;
  requiresGender: boolean;
  requiresDOB: boolean;
  requiresMicrochip: boolean;
}

export type PatientFormItemProps = FormItemProps & {
  testid?: string;
  inputtype?: 'select' | 'datePicker' | 'input' | 'checkbox' | 'switch';
  autoFocus?: boolean;
};

export const PatientModalContent: React.FC<PatientModalContentType> = ({
  patientRef,
  missingFields,
  speciesId,
  patientId,
  deceasedDateVisible,
  form,
  patientDOB,
  shouldAutofocus,
  service,
  requiredHisaFields,
  isForRapidBilling,
}) => {
  const breedOptions = patientRef?.breed
    .filter((b) => b.species_id === speciesId)
    .map((b) => ({ value: b.id, label: b.name }));
  const genderOptions = patientRef?.gender
    .filter((g) => g.species_id === speciesId)
    .map((g) => ({ value: g.id, label: g.name }));

  const getDateOfBirth = () => {
    if (missingFields?.requiresDOB) {
      return form.getFieldValue('dob');
    }
    return patientDOB ? dayjs(patientDOB) : undefined;
  };

  const getModalForm = () => {
    const content = [];
    let utuofocusSet = false;
    let autoFocus = false;

    if (missingFields) {
      content.push(
        <>
          <TitleDiv>{translations.shared.servicePatientModal.description}</TitleDiv>
          <TitleDiv>{translations.shared.servicePatientModal.formTitle}</TitleDiv>
        </>
      );
    }
    if (missingFields?.requiresBreed) {
      if (!utuofocusSet && shouldAutofocus) {
        utuofocusSet = true;
        autoFocus = true;
      }
      const nameKey = getNameKey('breed_id', patientId);
      content.push(
        <RequirementsComponent
          itemProps={{
            name: nameKey,
            label: translations.patientPage.details.breed,
            rules: [getRequiredRule(translations.patientPage.details.breed)],
            testid: 'patientBreedTestId',
            inputtype: 'select',
          }}
          selectProps={{ options: breedOptions, placeholder: translations.patientPage.details.breedPlaceholder }}
          key={nameKey}
        />
      );
      autoFocus = false;
    }
    if (missingFields?.requiresGender) {
      if (!utuofocusSet && shouldAutofocus) {
        utuofocusSet = true;
        autoFocus = true;
      }
      const nameKey = getNameKey('gender_id', patientId);
      content.push(
        <RequirementsComponent
          itemProps={{
            name: nameKey,
            label: translations.patientPage.details.gender,
            rules: [getRequiredRule(translations.patientPage.details.gender)],
            testid: 'patientGenderTestId',
            inputtype: 'select',
          }}
          selectProps={{ options: genderOptions, placeholder: translations.patientPage.details.genderPlaceholder }}
          key={nameKey}
        />
      );
      autoFocus = false;
    }
    if (missingFields?.requiresDOB) {
      if (!utuofocusSet && shouldAutofocus) {
        utuofocusSet = true;
        autoFocus = true;
      }
      const nameKey = getNameKey('dob', patientId);
      content.push(
        <RequirementsComponent
          itemProps={{
            name: nameKey,
            label: translations.patientPage.details.dateOfBirth,
            rules: [getRequiredRule(translations.patientPage.details.dateOfBirth)],
            testid: 'patientDOBTestId',
            autoFocus,
            inputtype: 'datePicker',
          }}
          key={nameKey}
        />
      );
      autoFocus = false;
    }
    if (missingFields?.requiresMicrochip) {
      if (!utuofocusSet && shouldAutofocus) {
        utuofocusSet = true;
        autoFocus = true;
      }
      content.push(
        <MicrochipModalContent patientId={patientId} autofocus={autoFocus} key={`microchip-${patientId}`} />
      );
      autoFocus = false;
    }
    if (deceasedDateVisible) {
      if (!utuofocusSet && shouldAutofocus) {
        utuofocusSet = true;
        autoFocus = true;
      }
      content.push(
        <DeceasedDatePickerFormItem getDateOfBirth={getDateOfBirth} autofocus={autoFocus} key='deceased-date' />
      );
      autoFocus = false;
    }

    if (service && requiredHisaFields) {
      content.push(
        <HisaRequirementsContent
          key={'hisaRequirements'}
          missingFields={requiredHisaFields}
          service={service}
          patientId={patientId}
          isForRapidBilling={isForRapidBilling}
        />
      );
    }
    return content;
  };

  return <>{getModalForm()}</>;
};
