import { PlusOutlined } from '@ant-design/icons';
import { Form, Radio } from 'antd';
import styled from 'styled-components';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { CustomMarginMixin, CustomWidthMixin } from '../../../globalStyles.style';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${CustomWidthMixin}
`;

export const ClickableParagraph = styled.p`
  cursor: pointer;
  color: black;
  margin: 0;
`;

export const CustomWidthMaxLengthFormItem = styled(MaxLengthFormItem)`
  ${CustomWidthMixin}
`;

export const CustomWidthFormItem = styled(Form.Item)`
  ${CustomWidthMixin}
`;

export const RightMarginPlusOutlined = styled(PlusOutlined)`
  margin-right: 0.5rem;
`;

export const CustomWidthContainer = styled.div`
  ${CustomWidthMixin}
`;

export const CustomWidthContainerWithLeftMargin = styled(CustomWidthContainer)`
  margin-left: 2rem;
`;

export const CustomWidthFormItemWithMarginBottom = styled(CustomWidthFormItem)`
  ${CustomWidthMixin}
`;

export const CustomWidthFormItemWithNoMarginBottom = styled(CustomWidthFormItem)`
  margin-bottom: 0;
  ${CustomWidthMixin}
`;

export const FlexRadioGroup = styled(Radio.Group)`
  display: flex;
  text-align: center;
  ${CustomMarginMixin}
`;

export const FlexedRadioButton = styled(Radio.Button)`
  flex: 1;
`;

export const LessBottomMargin = styled(Form.Item)`
  margin-bottom: 5px;
`;

export const ActiveFormItem = styled(Form.Item)`
  min-width: 100px;
  margin: 0 8px;
`;

export const DurationFormItem = styled(Form.Item)`
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
`;
