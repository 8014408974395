import { Dayjs } from 'dayjs';
import { translations } from '../../constants/translations';
import { Maybe } from '../../graph/types';

export interface ReminderForm {
  afterDate: Dayjs;
  beforeDate: Dayjs;
  runMode: RunMode;
}

export interface ConfigureButton {
  subject: any;
  reminderBody: any;
  subjectSetting: any;
  reminderBodySetting: any;
}

export type FormChangeProp = { [key: string]: string | Dayjs };

export enum RunMode {
  PRINT = 'print',
  PRINT_OR_EMAIL = 'printOrEmail',
  PRINT_AND_EMAIL = 'printAndEmail',
  CANCEL = 'cancel',
  COMPLETE = 'complete',
}

export type SelectedContact = { id: string; reminderId: string; preference: Maybe<string> | undefined };

export const RUN_MODE_OPTIONS = [
  { value: RunMode.PRINT, label: translations.reminderRuns.statusSelect.print },
  { value: RunMode.PRINT_OR_EMAIL, label: translations.reminderRuns.statusSelect.printOrEmail },
  { value: RunMode.PRINT_AND_EMAIL, label: translations.reminderRuns.statusSelect.printAndEmail },
  { value: RunMode.COMPLETE, label: translations.reminderRuns.statusSelect.Complete },
  { value: RunMode.CANCEL, label: translations.reminderRuns.statusSelect.Cancel },
];

export enum ReminderPreference {
  EMAIL = 'Email',
  PRINT = 'Print',
}

export const SEND_TYPES_OPTIONS = Object.values(ReminderPreference).map((label) => ({ text: label, value: label }));
