import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Dropdown, Popconfirm, Space, Switch, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  PatientDeleteNameKeyTranslation,
  patientInvoiceSyncErrorNameKeyTranslation,
  translations,
} from '../../../constants/translations';
import { useDeletePatient, useSyncPatient } from '../../../hooks/ajax/patients/patientHooks';
import { PatientOwnership } from './PatientOwnership/PatientOwnership';
import { Loading } from '../../../components/Loading/Loading';
import { PatientRecords } from './PatientRecords/PatientRecords';
import { AddRecordButtons } from '../../../components/Records/AddRecordButtons/AddRecordButtons';
import { PatientProfileImage } from './PatientProfileImage/PatientProfileImage';
import { DownOutlined } from '@ant-design/icons';
import { EmailModalForPatientHistory } from '../../../components/EmailModal/EmailModalForPatientHistory';
import { EmailModalForPatientVaccine } from '../../../components/EmailModal/EmailModalForPatientVaccine';
import { buildPatientVaxRptVariables, ModalTypes, usePatientSubscription } from '../patientUtils';
import { PrintPatientHistoryReportModal } from '../PrintPatientHistoryModal';
import { hasValidEmailSettings } from '../../../util/email';
import { RecordsContext } from '../../../components/Records/store/state';
import { setHasUnsavedChangesAction, setSearchValueAction } from '../../../components/Records/store/actions';
import { useDeleteMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { organizationIdParameter, routes } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { UtilsContext } from '../../../components/UtilsProvider/store/state';
import { setSaveSpinnerAction, setTextConfirmAction } from '../../../components/UtilsProvider/store/actions';
import { MergePatients } from '../../../components/MergePatients/MergePatients';
import { useUpsertJob } from '../../../hooks/ajax/job/jobHooks';
import { getJobRecordUpsert } from '../../../util/jobUtil';
import { SyncPatient } from '../../../graph/queries/patients';
import { Invoice, Job, JobUpsert, Maybe, Patient, PatientInvoiceOwnershipIssue } from '../../../graph/types';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { JobStatus } from '../../../components/JobStatus/JobStatus';
import { InvoiceSync } from './PatientOwnership/InvoiceSync/InvoiceSync';
import { useOffline } from '../../../util/offline/offlineUtil';
import { AddInvoiceModal } from '../../Invoices/AddInvoice/AddInvoiceModal';
import { PatientReminders } from './PatientReminders/PatientReminders';
import { PatientPrescriptions } from './PatientPrescriptions/PatientPrescriptions';
import { CreateInvoiceButton } from './ViewPatientContent.styles';
import { EditPatientForm } from './PatientDetails/EditPatientForm/EditPatientForm';
import { RelationshipContextProvider } from './PatientDetails/PatientRelationship/store/state';
import { PatientRelationship } from './PatientDetails/PatientRelationship/PatientRelationship';
import { ContactTypeId, ContactTypeNameKey } from '../../../constants/referenceData/contactReferenceData';
import { WidthRestrictor } from '../../../components/Restrictors/WidthRestrictor';
import { PatientIdentification } from './PatientDetails/PatientIdentificationForm/PatientIdentification';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import styled from 'styled-components';
import { TabChangeWarning } from '../../../components/TabChangeWarning/TabChangeWarning';
import { PatientAlerts } from './PatientAlerts/PatientAlerts';
import { useAlerts } from '../../../hooks/alertHooks';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { ActionButtonsForPatient } from '../ActionButtonsForPatient';

const { TabPane } = Tabs;

const tabKeys = {
  patientDetails: 'patientDetails',
  patientOwnership: 'patientOwnership',
  patientRecords: 'patientRecords',
  reminders: 'reminders',
  prescriptions: 'prescriptions',
  alerts: 'Alerts',
};

interface ViewPatientContentProps {
  navigateBack?: () => void;
  patient?: Maybe<Patient>;
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
  renderKey?: number;
}

export const StickyHeader = styled(PageHeader)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const PREFERS_FULLSCREEN_LOCALSTORAGE_KEY = 'PREFERS_FULLSCREEN';

export const ViewPatientContent: React.FC<ViewPatientContentProps> = ({
  patient,
  renderKey,
  patientRefetch,
  navigateBack,
}) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const {
    dispatch: dispatchRecords,
    state: { hasUnsavedChanges },
  } = useContext(RecordsContext);
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const history = useHistory();
  const [syncLoading, setSyncLoading] = useState(false);
  const [upsertJob] = useUpsertJob();
  const [jobId, setJobId] = useState<string>();
  const { showAlertModal, patientReferenceData, usesAlerts, renderPatientsAlertsModal } = useAlerts(
    'prompt_patient',
    patient,
    organization
  );
  const [isModalOpen, setIsModalOpen] = useState<ModalTypes | undefined>(
    showAlertModal ? ModalTypes.PatientAlerts : undefined
  );
  const organizationId = organization?.id ?? '';
  const practiceId = organization?.default_practice_id ?? '';
  const smtpSettings = organization?.smtp;
  const practice = organization?.practice?.find((practice) => practice.id === practiceId);

  useEffect(() => {
    return () => {
      dispatchRecords(setSearchValueAction(''));
    };
  }, [dispatchRecords]);

  const [activeTab, setActiveTab] = useState<string>(tabKeys.patientDetails);
  const [tabToBeActiveTab, setTabToBeActiveTab] = useState<string>('');
  const [patientsMergeVisible, setPatientsMergeVisible] = useState<boolean>(false);

  const [fullscreenMode, setFullscreenMode] = useState<boolean>(
    localStorage.getItem(PREFERS_FULLSCREEN_LOCALSTORAGE_KEY) === 'true'
  );

  const enablePatientHxV2 = useLDFlag(LDFlagNames.PatientHxV2);

  useEffect(() => {
    localStorage.setItem(PREFERS_FULLSCREEN_LOCALSTORAGE_KEY, String(fullscreenMode));
  }, [fullscreenMode]);

  const deletePatient = useDeleteMutationWithMessages(useDeletePatient, organizationId);
  usePatientSubscription(patient, patientRefetch);

  useEffect(() => {
    if (!patient) {
      showErrorMessage(translations.patientPage.unknownPatient);
    }
  }, [patient]);

  const [syncPatient] = useSyncPatient();
  const [invoiceIssues, setInvoiceIssues] = useState<PatientInvoiceOwnershipIssue[]>();
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const { enabledAndOffline } = useOffline();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [infoHasChanged, setInfoHasChanged] = useState(false);
  const [displayWarningOnChangeTab, setDisplayWarningOnChangeTab] = useState(false);
  const [changesOnTab, setChangesOnTab] = useState(false);

  const [shouldResetPatientDetails, setShouldResetPatientDetails] = useState(false);
  const [shouldResetOwnership, setShouldResetOwnership] = useState(false);
  const [shouldResetPatientRecords, setShouldResetPatientRecords] = useState(false);
  const [shouldResetReminders, setShouldResetReminders] = useState(false);
  const [shouldResetAlerts, setShouldResetAlerts] = useState(false);
  const [shouldDisplaySyncPatientModal, setShouldDisplaySyncPatientModal] = useState(false);

  const [isGeneralSync, setIsGeneralSync] = useState(false);
  const usesActiveReports = useLDFlag(LDFlagNames.ARPatientVaxReport);

  useEffect(() => {
    if (shouldDisplaySyncPatientModal) {
      setIsModalOpen(ModalTypes.SyncPatient);
    }
  }, [shouldDisplaySyncPatientModal]);

  const getInvoiceIssues = useCallback(async () => {
    let invoiceIssues: PatientInvoiceOwnershipIssue[] | null | undefined;
    setSyncLoading(true);
    try {
      const { data: syncData } = await syncPatient({
        variables: {
          organizationId,
          patient: {
            id: patient?.id,
          },
        },
      });

      invoiceIssues = syncData?.syncPatientInvoice?.invoice_issue;

      if (invoiceIssues?.length) {
        setInvoiceIssues(invoiceIssues);
      }
    } catch (e) {
      showErrorMessage(patientInvoiceSyncErrorNameKeyTranslation((e as Error).message));
    } finally {
      setSyncLoading(false);
    }
    return invoiceIssues;
  }, [organizationId, patient, syncPatient]);

  const relationshipFilters = useMemo(() => {
    return {
      relationshipId: ContactTypeId.EMR,
      relationshipName: ContactTypeNameKey.EMR,
    };
  }, []);

  if (!patientReferenceData || !patient || !organization) {
    return <Loading />;
  }

  const title = `${patient?.number ? '#' + patient.number + ' ' : ''} ${patient?.name} (${
    translations.patientPage.patient
  })`;

  const handleTabClick = (key: string) => {
    if (formHasChanged || infoHasChanged || changesOnTab) {
      setDisplayWarningOnChangeTab(true);
      setTabToBeActiveTab(key);
    } else {
      setDisplayWarningOnChangeTab(false);
      setActiveTab(key);
    }
  };

  const handleOnOkTabChangeWarningModal = () => {
    setDisplayWarningOnChangeTab(false);
    switch (activeTab) {
      case tabKeys.patientDetails:
        setShouldResetPatientDetails(true);
        break;
      case tabKeys.patientOwnership:
        setShouldResetOwnership(true);
        break;
      case tabKeys.patientRecords:
        setShouldResetPatientRecords(true);
        break;
      case tabKeys.reminders:
        setShouldResetReminders(true);
        break;
    }
    setActiveTab(tabToBeActiveTab);
  };

  const handlePatientDelete = async () => {
    dispatchUtils(setSaveSpinnerAction({ isSaving: true }));
    await deletePatient({
      entityId: patient.id,
      successMessage: translations.patientsPage.deleteSuccessMessage,
      onSuccess: () => history.push(routes.patients.replace(organizationIdParameter, organizationId)),
      onError: (err: any) => showErrorMessage(PatientDeleteNameKeyTranslation((err as any).message)),
    });
    dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
  };

  const handlePatientDeleteSelect = () => {
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: handlePatientDelete,
        title: translations.patientPage.deletePatientModalConfirmationTitle,
        description: translations.patientPage.deletePatientConfirm(patient.name ?? undefined),
      })
    );
  };

  const closeJobStatusModal = () => {
    setJobId(undefined);
    setIsModalOpen(undefined);
    showSuccessMessage(translations.shared.saveSuccessMessage);
  };

  const handlePatientSync = async () => {
    const selectedInvoiceIds = selectedInvoices.map(({ id }) => id);
    const variables = {
      organizationId,
      patient: { id: patient.id, fnInvoiceId: selectedInvoiceIds },
    };
    const jobRecord = getJobRecordUpsert(SyncPatient, variables);
    const job: JobUpsert = {
      record: jobRecord,
    };
    setSyncLoading(true);
    try {
      const { data } = await upsertJob({
        variables: {
          organizationId,
          job,
        },
      });

      setJobId(data?.upsertJob?.id);
      setIsGeneralSync(false);
    } catch (e) {
      showErrorMessage((e as Error).message);
    }
    setSyncLoading(false);
  };

  const handleJobFinish = async ({ result }: Job) => {
    if (result && isGeneralSync) {
      const invoiceIssues = await JSON.parse(result).invoice_issue;
      if (invoiceIssues?.length) {
        setInvoiceIssues(invoiceIssues as PatientInvoiceOwnershipIssue[]);
        setShouldDisplaySyncPatientModal(true);
      }
      setSyncLoading(false);
    }
  };

  const tabBarExtraContent = (
    <Space>
      {activeTab === tabKeys.patientRecords && (
        <>
          {hasUnsavedChanges ? (
            <Popconfirm
              placement={'topLeft'}
              title={translations.patientPage.medicalRecords.searchWarning}
              onConfirm={() => dispatchRecords(setHasUnsavedChangesAction(false))}
            />
          ) : null}
        </>
      )}
      {activeTab === tabKeys.patientRecords && enablePatientHxV2 && (
        <Div>
          <span style={{ marginRight: 8 }}>{translations.patientPage.fullscreenMode}</span>
          <Switch
            defaultChecked={fullscreenMode}
            data-testid={'fullscreenModeToggleTestId'}
            onChange={(checked) => setFullscreenMode(checked)}
          />
        </Div>
      )}
      <CreateInvoiceButton type={'primary'} onClick={() => setIsModalOpen(ModalTypes.AddInvoice)}>
        {translations.invoicesPage.buttons.addInvoice}
      </CreateInvoiceButton>
      {!enabledAndOffline && (
        <>
          <Dropdown
            overlay={
              <ActionButtonsForPatient
                setIsModalOpen={setIsModalOpen}
                disableMailOption={!hasValidEmailSettings(smtpSettings)}
                handlePatientDelete={handlePatientDeleteSelect}
                handlePatientMerge={() => setPatientsMergeVisible(true)}
                handlePatientSync={async () => {
                  const variables = {
                    organizationId,
                    patient: { id: patient.id },
                  };
                  const jobRecord = getJobRecordUpsert(SyncPatient, variables);
                  const job: JobUpsert = {
                    record: jobRecord,
                  };
                  setSyncLoading(true);
                  try {
                    const { data } = await upsertJob({
                      variables: {
                        organizationId,
                        job,
                      },
                    });

                    setJobId(data?.upsertJob?.id);
                    setIsGeneralSync(true);
                  } catch (e) {
                    showErrorMessage((e as Error).message);
                  }
                }}
                vaccineReportVariables={buildPatientVaxRptVariables({
                  patient,
                  organizationId,
                  practiceId,
                  usesActiveReports,
                  returnPreSignedUrl: true,
                })}
              />
            }
          >
            <Button>
              {translations.patientsPage.actions} <DownOutlined />
            </Button>
          </Dropdown>
        </>
      )}
      {activeTab === tabKeys.patientRecords ? <AddRecordButtons /> : null}
      <PatientProfileImage patient={patient} />
    </Space>
  );

  const handleModalClose = () => {
    setIsModalOpen(undefined);
    setShouldDisplaySyncPatientModal(false);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={syncLoading || isSaving}
      savingMessage={syncLoading ? translations.patientPage.ownership.invoiceSync.syncing : translations.shared.loading}
      showNavigationWarning={formHasChanged || infoHasChanged || changesOnTab}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.patientPage.entity)}
    >
      <StickyHeader title={title} {...(navigateBack && { onBack: navigateBack })} />
      <Tabs
        activeKey={activeTab}
        type='card'
        onTabClick={handleTabClick}
        tabBarExtraContent={tabBarExtraContent}
        style={{ overflow: 'visible' }}
      >
        <TabPane tab={translations.patientPage.details.title} key={tabKeys.patientDetails}>
          <WidthRestrictor>
            <EditPatientForm
              patient={patient}
              renderKey={renderKey}
              patientRef={patientReferenceData}
              setIsSaving={setIsSaving}
              setHasChanged={setFormHasChanged}
              hasChanged={formHasChanged}
              setShouldResetOnTabChange={setShouldResetPatientDetails}
              shouldResetOnTabChange={shouldResetPatientDetails}
            />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div style={{ flex: 'auto', paddingRight: 20 }}>
                <Divider orientation='left' plain style={{ marginTop: '5px' }}>
                  {translations.patientPage.details.relationships}
                </Divider>
                <RelationshipContextProvider>
                  <PatientRelationship
                    key={renderKey}
                    patient={patient}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    patientRefetch={patientRefetch}
                    relationshipFilters={relationshipFilters}
                  />
                </RelationshipContextProvider>
              </div>
              <div style={{ flex: 'auto' }}>
                <Divider orientation='left' plain style={{ marginTop: '5px' }}>
                  {translations.patientPage.details.identifications}
                </Divider>
                <PatientIdentification
                  organizationId={organizationId}
                  practiceId={practiceId}
                  patient={patient}
                  patientRef={patientReferenceData}
                  setIsSaving={setIsSaving}
                  setHasChanged={setInfoHasChanged}
                />
              </div>
            </div>
          </WidthRestrictor>
        </TabPane>
        <TabPane tab={translations.patientPage.ownership.title} key={tabKeys.patientOwnership}>
          <PatientOwnership
            patient={patient}
            getInvoiceIssues={getInvoiceIssues}
            openSyncModal={() => setIsModalOpen(ModalTypes.SyncPatient)}
            handleIsEditing={setChangesOnTab}
            setShouldResetOnTabChange={setShouldResetOwnership}
            shouldResetOnTabChange={shouldResetOwnership}
          />
        </TabPane>
        <TabPane tab={translations.patientPage.medicalRecords.title} key={tabKeys.patientRecords}>
          <PatientRecords
            patient={patient}
            handleIsEditing={setChangesOnTab}
            setShouldResetOnTabChange={setShouldResetPatientRecords}
            shouldResetOnTabChange={shouldResetPatientRecords}
            fullscreenMode={fullscreenMode}
          />
        </TabPane>
        <TabPane tab={translations.patientPage.reminders.title} key={tabKeys.reminders}>
          <PatientReminders
            patientId={patient.id}
            handleIsEditing={setChangesOnTab}
            setShouldResetOnTabChange={setShouldResetReminders}
            shouldResetOnTabChange={shouldResetReminders}
          />
        </TabPane>
        <TabPane tab={translations.patientPage.prescriptions.title} key={tabKeys.prescriptions}>
          <PatientPrescriptions practice={practice} patient={patient} />
        </TabPane>
        {usesAlerts && (
          <TabPane tab={translations.patientPage.alerts.title} key={tabKeys.alerts}>
            <PatientAlerts
              patient={patient}
              handleIsEditing={setChangesOnTab}
              setShouldResetOnTabChange={setShouldResetAlerts}
              shouldResetOnTabChange={shouldResetAlerts}
              alertReferenceData={patientReferenceData.alert_type}
            />
          </TabPane>
        )}
      </Tabs>
      {isModalOpen === ModalTypes.EmailReport && (
        <EmailModalForPatientHistory onClose={handleModalClose} patient={patient} />
      )}
      {isModalOpen === ModalTypes.PrintReport && (
        <PrintPatientHistoryReportModal patient={patient} onClose={handleModalClose} />
      )}
      {isModalOpen === ModalTypes.SyncPatient && invoiceIssues && (
        <InvoiceSync
          invoiceIssues={invoiceIssues}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
          isLoading={syncLoading}
          closeModal={handleModalClose}
          syncInvoices={handlePatientSync}
        />
      )}
      {isModalOpen === ModalTypes.AddInvoice && (
        <AddInvoiceModal visible closeModal={handleModalClose} selectedPatient={patient} organization={organization} />
      )}
      {isModalOpen === ModalTypes.EmailVaccineReport && (
        <EmailModalForPatientVaccine patient={patient} onClose={handleModalClose} />
      )}
      {renderPatientsAlertsModal()}
      {jobId && (
        <JobStatus
          jobId={jobId}
          onError={showErrorMessage}
          closeModal={closeJobStatusModal}
          onCompletion={handleJobFinish}
        />
      )}
      {displayWarningOnChangeTab && (
        <TabChangeWarning
          showTabChangeWarning={displayWarningOnChangeTab}
          onOk={handleOnOkTabChangeWarningModal}
          closeModal={() => setDisplayWarningOnChangeTab(false)}
        />
      )}
      <MergePatients visible={patientsMergeVisible} patient={patient} onClose={() => setPatientsMergeVisible(false)} />
    </SaveSpinnerAndNavigationWarning>
  );
};
