import React, { useEffect } from 'react';
import { Loading } from '../../../Loading/Loading';
import { useElectronicPaymentStatusChangeSubscription } from '../../../../hooks/ajax/paymentGateway/paymentGatewayHooks';

export const CreditCardSwipeWaitingStatus: React.FC<{
  setClientRequestToken: (clientRequestToken: string) => void;
  clientRequestToken: string;
  onComplete: () => void;
  onError: (errorMessage?: string) => void;
  loadingMessage: string;
}> = ({ setClientRequestToken, clientRequestToken, onComplete, onError, loadingMessage }) => {
  const { paymentStatusChangeLoading, paymentStatusChange } =
    useElectronicPaymentStatusChangeSubscription(clientRequestToken);

  useEffect(() => {
    if (paymentStatusChange) {
      if (paymentStatusChange.status) {
        setClientRequestToken('');
        if (paymentStatusChange.success) {
          onComplete();
        } else if (!paymentStatusChange.success) {
          onError(paymentStatusChange.message || paymentStatusChange.cancel_error);
        }
      }
    }
  }, [paymentStatusChange, onComplete, onError, setClientRequestToken]);

  return <>{paymentStatusChangeLoading && <Loading labelText={loadingMessage} />}</>;
};
