import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../constants/routes';
import { MaintenanceMessageTextKeys, translations } from '../../constants/translations';
import { useGetMaintenance } from '../../hooks/ajax/organization/organizationHooks';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { CassadolLogo } from '../../images/images';
import dayjs from 'dayjs';
import { camelCase } from 'lodash';
import { Loading } from '../../components/Loading/Loading';

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 400px;
  text-align: center;
`;

const SpacerDiv = styled.div`
  margin-bottom: 40px;
`;

interface MaintenanceLocationState {
  organizationId: string;
}

const countdownPlaceholder = '-:-:-';
const padNumberWithZero = (value: number) => value.toString().padStart(2, '0');

const formatTimeLeft = (endTime: string) => {
  const difference = dayjs(endTime).diff(dayjs());
  if (difference <= 0) {
    return null;
  }

  const hours = padNumberWithZero(Math.floor((difference / (1000 * 60 * 60)) % 24));
  const minutes = padNumberWithZero(Math.floor((difference / 1000 / 60) % 60));
  const seconds = padNumberWithZero(Math.floor((difference / 1000) % 60));

  return `${hours}:${minutes}:${seconds}`;
};

export const MaintenancePage = () => {
  const location = useLocation<MaintenanceLocationState>();
  const { maintenanceMessage, maintenanceLoading, maintenanceError } = useGetMaintenance(
    location?.state?.organizationId
  );
  const [timeLeft, setTimeLeft] = useState<string>(countdownPlaceholder);
  const { navigateTo } = useNavigationToRoute();

  useEffect(() => {
    if (maintenanceLoading || maintenanceError) {
      return undefined;
    }

    const endTime = maintenanceMessage?.end_date;
    if (!endTime) {
      navigateTo(routes.root);
      return undefined;
    }

    const updateTimeLeft = (endTime: string) => {
      const countdown = formatTimeLeft(endTime);
      if (countdown) {
        setTimeLeft(countdown);
      } else {
        navigateTo(routes.root);
      }
    };

    if (timeLeft === countdownPlaceholder) {
      updateTimeLeft(endTime);
    }

    const timeout = setTimeout(() => {
      updateTimeLeft(endTime);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [timeLeft, navigateTo, setTimeLeft, maintenanceMessage, maintenanceLoading, maintenanceError]);

  if (!location?.state) {
    navigateTo(routes.root);
    return null;
  }

  if (!maintenanceMessage) {
    return <Loading />;
  }

  const maintenanceMessageTranslation =
    translations.maintenance.maintenancePage.messageText[
      camelCase(maintenanceMessage?.message_key) as keyof MaintenanceMessageTextKeys
    ];
  const maintenanceMessages = maintenanceMessageTranslation.split('\n');
  const isCloud = maintenanceMessage?.message_key?.endsWith('_CLOUD');

  return (
    <Container>
      <SpacerDiv>
        <CassadolLogo />
      </SpacerDiv>
      {isCloud ? (
        <>
          {maintenanceMessages.map((mm) => (
            <h2 key={mm}>{mm}</h2>
          ))}
        </>
      ) : (
        <>
          <h1>{maintenanceMessages[0]}</h1>
          <span>{maintenanceMessages[1]}:</span>
          <h1>{timeLeft}</h1>
        </>
      )}
    </Container>
  );
};
