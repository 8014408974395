import React, { ReactElement, useCallback, useContext } from 'react';
import { Select, Tag, Popconfirm, Button, Tooltip } from 'antd';
import { translations } from '../../constants/translations';
import { Patient } from '../../graph/types';
import { AbbreviateToWidth } from '../../components/AbbreviateToWidth/AbbreviateToWidth';
import { Loading } from '../../components/Loading/Loading';
import './PatientMultiSelect.less';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { joinPatientNameAndPrimaryOwnerName, joinPatientOrContactNameAndNumber } from '../../util/displaying';
import { CloseCircleFilled } from '@ant-design/icons';
import { PatientsContext } from '../Patients/store/state';
import { isString } from 'lodash';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';

export interface PatientMultiSelectProps {
  selectedPatients: Patient[];
  setSelectedPatients: (patients: Patient[]) => void;
  disabled?: boolean;
  isRappidBilling?: boolean;
}

type QueryProps = {
  patientsLoading: boolean | undefined;
  setSearchTerm: (term: string) => void;
};

export const patientTagWidth = 220;

export const patientMultiSelectId = 'PatientMultiSelect';

const patientTagStyle = { marginTop: 3, width: patientTagWidth, display: 'flex', justifyContent: 'space-between' };

const { Option } = Select;

export const PatientMultiSelect: React.FC<PatientMultiSelectProps & QueryProps> = ({
  selectedPatients,
  setSelectedPatients,
  disabled,
  patientsLoading,
  setSearchTerm: searchPatient,
  isRappidBilling,
}) => {
  const { state } = useContext(PatientsContext);
  const patients = state.patientsList;
  const getPatientLabel = (patient?: Patient, isRappidBilling?: boolean) => {
    if (!patient) {
      return '';
    }
    return isRappidBilling ? joinPatientNameAndPrimaryOwnerName(patient) : joinPatientOrContactNameAndNumber(patient);
  };
  const getPatientOptions = useCallback(
    () =>
      patients?.map((patient) => {
        const label = getPatientLabel(patient, isRappidBilling);
        return (
          <Option key={patient.id} value={patient.id}>
            <Tooltip title={label} placement='right'>
              <div>{label}</div>
            </Tooltip>
          </Option>
        );
      }),
    [patients, isRappidBilling]
  );

  const patientOptions = getPatientOptions();

  const handlePatientChange = (selectedPatientIds: string[]) => {
    const previouslySelectedPatientIds = selectedPatients.map((patient) => patient.id);
    const addedPatients =
      patients?.filter(
        (patient) => selectedPatientIds.includes(patient?.id) && !previouslySelectedPatientIds.includes(patient?.id)
      ) ?? [];
    setSelectedPatients([...selectedPatients, ...addedPatients]);
  };

  const handlePatientDeselect = (patientIdToDeselect: string) => {
    const remainingPatients = selectedPatients.filter((patient) => patient.id !== patientIdToDeselect);
    setSelectedPatients(remainingPatients);
  };

  const tagRender = ({ label, closable, onClose }: CustomTagProps) => {
    const patientLabel = isString(label)
      ? getPatientLabel(
          selectedPatients?.find((p) => p.id === label),
          isRappidBilling
        )
      : (label as ReactElement).props?.title;
    return (
      <Tag
        className={'RapidBilling__patientTag'}
        closable={closable && !disabled}
        onClose={onClose}
        style={disabled ? { ...patientTagStyle, backgroundColor: '#f5f5f5' } : patientTagStyle}
        title={patientLabel}
        data-testid={'tag' + patientLabel}
      >
        <AbbreviateToWidth text={patientLabel} width={200} noTitle />
      </Tag>
    );
  };

  function clearPatients() {
    setSelectedPatients([]);
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select
        {...searchableSelectParams}
        className={'RapidBilling__patientSelect'}
        mode={'multiple'}
        bordered={false}
        style={{ width: 240, marginTop: 10, display: 'flex', flexDirection: 'column' }}
        tagRender={tagRender}
        filterOption={false}
        notFoundContent={patientsLoading ? <Loading /> : null}
        placeholder={translations.rapidBilling.patientSelectPlaceholder}
        onChange={handlePatientChange}
        onDeselect={handlePatientDeselect}
        onSearch={searchPatient}
        onBlur={() => searchPatient('')}
        autoClearSearchValue={false}
        value={selectedPatients.map((patient) => patient.id)}
        data-testid={'patient-select'}
        id={patientMultiSelectId}
        disabled={disabled}
      >
        {patientOptions}
      </Select>
      {selectedPatients.length > 0 ? (
        <Popconfirm
          title={translations.shared.selectPatientModal.clearPopConfirmTitle}
          cancelText={translations.shared.popconfirm.no}
          okText={translations.shared.popconfirm.ok}
          onConfirm={clearPatients}
        >
          <Button
            icon={<CloseCircleFilled />}
            className={'PatientMultiSelect__ClearAllButton'}
            size={'small'}
            type='text'
            style={{ margin: 'auto 1px auto -15px' }}
          />
        </Popconfirm>
      ) : (
        <div style={{ width: 10 }} />
      )}
    </div>
  );
};
