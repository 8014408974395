import { useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentNode, OperationVariables } from '@apollo/client';
import { DEBOUNCE_TIME_FOR_SEARCH } from '../../constants/queryConstants';
import { adjustSearchString } from '../../util/searchUtil';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { isEmpty } from 'lodash';
import { Query } from '../../graph/types';
import { PGQueryFilter, useOfflineErrorSkipQuery } from './useOfflineErrorSkip';

export const useQueryWithBackendSearch = <TData extends keyof Query, TVariables = OperationVariables>(
  query: DocumentNode,
  variables: TVariables & PGQueryFilter,
  searchValue?: string,
  options?: QueryHookOptions<Pick<Query, TData>, TVariables & PGQueryFilter>,
  shouldNotAdjustString?: boolean
) => {
  const queryOptions = useMemo(
    () => ({
      ...options,
      variables: {
        ...variables,
        filter: {
          searchText: shouldNotAdjustString
            ? (searchValue ?? '').replace(/['"]/g, '')
            : adjustSearchString((searchValue ?? '').replace(/['"]/g, '')),
        },
      },
      notifyOnNetworkStatusChange: true,
    }),
    [options, searchValue, shouldNotAdjustString, variables]
  );
  const { data, loading, refetch, subscribeToMore } = useOfflineErrorSkipQuery<TData, TVariables>(query, queryOptions);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(searchValue);
  const [displayedData, setDisplayedData] = useState<Pick<Query, TData>>();
  const [displayedLoading, setDisplayedLoading] = useState<boolean>(loading);

  const skip = options?.skip;
  const refetchSearch = useCallback(
    (value: string) => {
      // replace ' and " with empty string. Doing so prevents the search string from breaking the search in the Backend
      const parsedValue = value.replace(/['"]/g, '');
      !skip &&
        refetch({
          ...variables,
          filter: { searchText: shouldNotAdjustString ? parsedValue : adjustSearchString(parsedValue) },
        });
    },
    [refetch, variables, skip, shouldNotAdjustString]
  );

  useEffect(() => {
    if (searchTerm !== undefined) {
      setDisplayedLoading(true);
      const timeout = setTimeout(() => refetchSearch(searchTerm), DEBOUNCE_TIME_FOR_SEARCH);
      return () => {
        setDisplayedLoading(false);
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [searchTerm, refetchSearch]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setDisplayedData(data);
    }
  }, [data]);

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setDisplayedLoading(loading);
  }, [loading]);

  const refetchCurrentSearch = useCallback(() => refetchSearch(searchTerm || ''), [refetchSearch, searchTerm]);

  return {
    loading: displayedLoading,
    refetchSearch,
    refetchCurrentSearch,
    data: displayedData,
    setSearchTerm,
    subscribeToMore,
  };
};
