import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import ModalWithCloseConfirm, {
  ModalFooterButtonsForReset,
} from '../../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { PrescriptionModalContent } from '../../../../components/PrescriptionModalContent/PrescriptionModalContent';
import { FormValues } from '../../../../components/PrescriptionModalContent/PrescriptionModalContent.types';
import { useFormChanges } from '../../../../hooks/useFormChanges';
import { useGetInvoiceContext } from '../../../../hooks/ajax/invoice/invoiceHooks';
import {
  usePrescritionOfflineMutation,
  useUpdatePrescription,
} from '../../../../hooks/ajax/prescriptionHooks/prescriptionHooks';
import { getDoctorOptions } from '../../../Invoices/AddInvoice/addInvoiceUtil';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { translations } from '../../../../constants/translations';
import { Patient, Prescription, PrescriptionUpsert } from '../../../../graph/types';
import dayjs from 'dayjs';
import { formats } from '../../../../constants/formats';
import { showErrorMessage } from '../../../../components/Notification/notificationUtil';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { ID_FOR_OBJECT_CREATION } from '../../../../classes/upsertGenerators/commonUpsertConstants';
import { useGetServicesWithSearch } from '../../../../hooks/ajax/service/serviceHooks';
import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles.style';
import { useOrganizationContext } from '../../../../contexts/organization/state';

const FlexContainerWithMarginBottom = styled(FlexContainer)`
  margin-bottom: 1rem;
`;

type Props = {
  practiceId: string;
  patient: Patient;
  showModal: boolean;
  prescription?: Prescription;
  onToggleModal: () => void;
};

const UpdateItemModal = ({ practiceId, patient, prescription, showModal, onToggleModal }: Props) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const [form] = Form.useForm<FormValues>();

  const [loading, setLoading] = useState(false);

  const patientName = patient.name ?? '';

  const [updatePrescription] = useUpdatePrescription();
  const offlineUpdate = usePrescritionOfflineMutation();
  const { enabledAndOffline } = useOffline();
  const organizationId = useGetOrganizationIdFromRoute();
  const { invoiceContext } = useGetInvoiceContext(organizationId, practiceId);
  const { handleOnValuesChange, hasDataChanged, handleOnReset } = useFormChanges(form, {});
  const practiceData = organization?.practice.find(({ id }) => id === organization.default_practice_id);

  const { services, servicesLoading } = useGetServicesWithSearch(organizationId);

  const doctorOptions = useMemo(() => getDoctorOptions(invoiceContext), [invoiceContext]);

  const contacts = useMemo(
    () =>
      patient?.ownership_current?.owner.map((owner) => ({ label: owner?.name ?? '', value: owner?.contact_id ?? '' })),
    [patient]
  );

  const rxExpiryOffset = useMemo(
    () => practiceData?.setting?.find((setting) => setting.setting_name_key === 'RX_EXPIRY_OFFSET')?.value,
    [practiceData]
  );

  useEffect(() => {
    if (prescription) {
      const {
        contact_id,
        prescribed_user_id,
        prescribed_date,
        prescription_expiry_date,
        product_expiry_date,
        item_description,
        item_quantity,
        item_unit,
        instructions,
        refills,
        service_id,
      } = prescription;

      form.setFieldsValue({
        contactId: contact_id || undefined,
        prescribedUserId: prescribed_user_id,
        prescribedDate: prescribed_date ? dayjs(prescribed_date) : null,
        prescriptionExpiryDate: prescription_expiry_date ? dayjs(prescription_expiry_date) : null,
        productExpiryDate: product_expiry_date ? dayjs(product_expiry_date) : null,
        itemDescription: item_description ?? '',
        itemQuantity: +item_quantity,
        itemUnit: item_unit ?? '',
        instructions: instructions ?? '',
        refills: refills ?? 0,
        service_id: service_id ?? '',
      });
    }
    // eslint-disable-next-line
  }, [prescription]);

  useEffect(() => {
    if (!prescription) {
      const primaryOwner = patient?.ownership_current?.owner.find((patient) => patient.primary);

      form.setFieldsValue({
        prescribedDate: dayjs(),
        ...(primaryOwner && { contactId: primaryOwner?.contact_id ?? '' }),
        ...(rxExpiryOffset && { prescriptionExpiryDate: dayjs().add(Number(rxExpiryOffset) ?? 0, 'days') }),
        refills: 0,
      });
    }
    // eslint-disable-next-line
  }, [patient, prescription]);

  const onFinish = async (values: FormValues) => {
    try {
      setLoading(true);
      const upsert: PrescriptionUpsert = {
        id: prescription?.id ?? ID_FOR_OBJECT_CREATION,
        record: {
          prescription_expiry_date:
            values.prescriptionExpiryDate?.format(formats.upsertDate).toString() ??
            prescription?.prescription_expiry_date ??
            '',
          prescribed_user_id: values.prescribedUserId ?? prescription?.prescribed_user_id,
          prescribed_date:
            values.prescribedDate?.format(formats.upsertDate).toString() ?? prescription?.prescribed_date ?? '',
          practice_id: practiceId,
          patient_id: patient.id,
          fill_externally: false,
          item_quantity: String(values.itemQuantity) ?? prescription?.item_quantity,
          created_type_id: prescription?.created_type_id ?? 2,
          contact_id: values.contactId ?? prescription?.contact_id,
          product_expiry_date:
            values.productExpiryDate?.format(formats.upsertDate).toString() ?? prescription?.product_expiry_date,
          refills: values.refills ?? prescription?.refills ?? 0,
          item_description: values.itemDescription ?? prescription?.item_description,
          item_unit: values.itemUnit ?? prescription?.item_unit,
          instructions: values.instructions ?? prescription?.instructions ?? '',
          ...(values.service_id && { service_id: values.service_id }),
          ...(prescription && {
            offline_id: prescription.offline_id,
          }),
        },
      };

      if (!enabledAndOffline) {
        await updatePrescription({
          variables: {
            organizationId,
            prescription: upsert,
          },
        });
      } else {
        await offlineUpdate(upsert);
      }
      setLoading(false);
      form.resetFields();
      onToggleModal();
    } catch (e) {
      setLoading(false);
      showErrorMessage(e.message);
    }
  };

  const onCloseModal = () => {
    form.resetFields();
    onToggleModal();
  };

  return (
    <FlexContainerWithMarginBottom alignItems='center'>
      <Button onClick={onToggleModal}>{translations.patientPage.prescriptions.create}</Button>
      <ModalWithCloseConfirm
        width={700}
        isSaving={loading || servicesLoading}
        title={
          prescription
            ? translations.patientPage.prescriptions.editModal
            : translations.patientPage.prescriptions.addModal
        }
        open={showModal}
        onCancel={onCloseModal}
        footer={null}
        destroyOnClose
      >
        <Form
          labelAlign='right'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          form={form}
          onValuesChange={handleOnValuesChange}
          onFinish={onFinish}
          autoComplete='off'
        >
          {showModal && (
            <PrescriptionModalContent
              form={form}
              rxNumber={prescription?.number ?? ''}
              patientName={patientName}
              contacts={contacts}
              doctorOptions={doctorOptions}
              servicesList={services ?? []}
              isEditingPrescription={!!prescription}
            />
          )}
          <ModalFooterButtonsForReset handleOnReset={handleOnReset} hasDataChanged={hasDataChanged} />
        </Form>
      </ModalWithCloseConfirm>
    </FlexContainerWithMarginBottom>
  );
};

export default UpdateItemModal;
