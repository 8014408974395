import React, { PropsWithChildren } from 'react';
import { StatementsAction } from './actions';
import { initialState, statementsReducer, StatementsState } from './reducer';

export const StatementsContext = React.createContext<{
  state: StatementsState;
  dispatch: React.Dispatch<StatementsAction>;
}>({} as any);

interface StatementsContextProviderProps {
  state?: Partial<StatementsState>;
}

export const StatementsContextProvider: React.FC<PropsWithChildren<StatementsContextProviderProps>> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };
  const [state, dispatch] = React.useReducer(statementsReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <StatementsContext.Provider value={value}>{props.children}</StatementsContext.Provider>;
};
