import { translations } from '../../../../constants/translations';
import {
  Contact,
  Ledger,
  LedgerUpsert,
  PracticeDto,
  Query,
  QueryRptGetContactReceiptArgs,
} from '../../../../graph/types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDeleteLedger, useGetContactLedger, useUpsertLedger } from '../../../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { routes } from '../../../../constants/routes';
import { Button, Space } from 'antd';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../../hooks/route/navigationHooks';
import { TabLoading } from '../../../../components/Loading/Loading';
import {
  DropdownButtonWithMenu,
  getMenuItemPropsWithOnCancelPopconfirm,
  MenuItemProps,
} from '../../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { TableCellLink } from '../../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { addSortingPriorityTo } from '../../../../util/filterAndSorting';
import { AddInvoiceModalContainer } from '../../../Invoices/AddInvoice/AddInvoiceModalContainer';
import { ContactPaymentModal } from '../../../../components/PaymentModal/ContactPaymentModal';
import { PriceValue } from '../../../../components/PriceValue/PriceValue';
import { ledgerEntryConfigs, LedgerEntryNameKey } from '../../../../constants/referenceData/ledgerReferenceData';
import { SaveSpinner } from '../../../../components/SaveSpinner/SaveSpinner';
import { useDeleteInvoice, useUpdateInvoice } from '../../../../hooks/ajax/invoice/invoiceHooks';
import { useDeleteMutationWithMessages, useMutationWithMessages } from '../../../../hooks/ajax/generalMutationHooks';
import { InvoicePaymentModal } from '../../../../components/PaymentModal/InvoicePaymentModal';
import {
  invoiceCanTakePayment,
  invoiceIsDeletable,
  InvoiceStatusId,
  isCompletedInvoiceStatus,
} from '../../../../constants/referenceData/invoiceReferenceData';
import { ContactLedgerContext } from './store/state';
import {
  setAccountChargeModalVisible,
  setCreditModalVisible,
  setNoteEditModalVisible,
  setPaymentModalVisible,
  setReversePaymentModalVisible,
  setShouldEnableAssignAll,
  setShouldRefetchLedger,
  setStripeDetailsModalVisible,
} from './store/actions';
import { changeInvoiceStatus, getMenuItemsForInvoiceActions } from '../../../Invoices/invoicesUtils';
import {
  getLedgerEntryType,
  getMenuItemsForCreditOrAccountChargeOrPaymentActions,
  getPrintEmailAndStripeDetailsMenuItems,
  mapLedgerToReportQueryArgs,
  printLedgerReceipt,
} from '../../contactLedgerUtils';
import { LedgerChargeModal } from '../../../../components/LedgerChargeModal/LedgerChargeModal';
import { useBasicLedgerColumns } from './ledgerColumns';
import { showErrorMessage } from '../../../../components/Notification/notificationUtil';
import EditPaymentModal from '../../../../components/PaymentModal/EditPaymentModal';
import { PopoverWithNote } from '../../../../components/PopoverWithNote/PopoverWithNote';
import { NoteEditModal } from '../../../../components/NoteEditModal/NoteEditModal';
import { ReversePaymentModal } from '../../../../components/ReversePaymentModal/ReversePaymentModal';
import { PrintInvoiceReportModal } from '../../../Invoices/PrintInvoiceReportModal/PrintInvoiceReportModal';
import { EmailModalForInvoiceReport } from '../../../../components/EmailModal/EmailModalForInvoiceReport';
import { TableKey } from '../../../../hooks/tableHooks';
import { hasValidEmailSettingsDto } from '../../../../util/email';
import { useDefaultPracticeId } from '../../../../hooks/ajax/practice/practiceHooks';
import { EmailModalForLedgerReceipt } from '../../../../components/EmailModal/EmailModalForLedgerReceipt';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { useContactSubscription } from '../../../../hooks/contactSubscriptionHook';
import { PayerPopover } from '../../../../components/PayerPopover/PayerPopover';
import { ConnectComponentsProvider, ConnectPaymentDetails } from '@stripe/react-connect-js';
import AppSyncService from '../../../../services/AppSyncService/AppSyncService';
import { GetElectronicPaymentResult } from '../../../../graph/queries/paymentGateway';
import { useOrganizationContext } from '../../../../contexts/organization/state';
import { useGetStripeConnectInstance } from '../../../../hooks/stripeHooks';
import { flushSync } from 'react-dom';
import { Reports, getReportData } from '../../../../util/reportUtils';
import { GetContactReceiptReportAR } from '../../../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../../../hooks/ajax/report/reportHooks';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';
interface ContactLedgerContentProps {
  contact: Contact;
  refetchContact: () => void;
  practice: PracticeDto;
}

type RollingTotalMap = { [ledgerId: string]: number };

export const contactLedgerContainerTestId = 'contact-ledger-container-testid';

export const ContactLedger: React.FC<ContactLedgerContentProps> = ({ contact, refetchContact, practice }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { contactLedger, contactLedgerLoading, refetchContactLedger } = useGetContactLedger({
    contactId: contact.id,
    organizationId,
  });
  useContactSubscription(contact.id, refetchContactLedger);
  const deleteInvoice = useDeleteMutationWithMessages(useDeleteInvoice, organizationId);
  const deleteLedger = useDeleteMutationWithMessages(useDeleteLedger, organizationId);
  const [showTable, setShowTable] = useState(true);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const { stripeConnectInstance } = useGetStripeConnectInstance();
  const [stripeChargeId, setStripeChargeId] = useState<string>();

  const [assignmentChangeLoading, setAssignmentChangeLoading] = useState(false);

  const [updateLedger] = useMutationWithMessages(useUpsertLedger);

  const [noOutstandingFinancialAmount, setNoOutstandingFinancialAmount] = useState(false);

  const { navigateTo } = useNavigationToRoute();
  const { dispatch, state } = useContext(ContactLedgerContext);
  const [updateInvoice] = useMutationWithMessages(useUpdateInvoice);

  const [rollingTotalMap, setRollingTotalMap] = useState<RollingTotalMap>({});

  const [currentEditingLedger, setCurrentEditingLedger] = useState<Ledger>();

  const [invoiceIdForEmailModal, setInvoiceIdForEmailModal] = useState<string>();
  const [invoiceIdForPrintModal, setInvoiceIdForPrintModal] = useState<string>();

  const useActiveReportsContactReceiptVersion = useLDFlag(LDFlagNames.ActiveReportsContactReceipt);
  const navigateToReportViewer = useNavigateToReportViewer();

  const {
    state: { organization },
  } = useOrganizationContext();
  const smtpSettings = organization?.smtp;
  const [ledgerForEmail, setLedgerForEmail] = useState<Ledger>();
  const disableEmailOption = useMemo(() => !hasValidEmailSettingsDto(smtpSettings), [smtpSettings]);
  const { isOnline } = useOffline();
  const basicLedgerColumns = useBasicLedgerColumns();

  useEffect(() => {
    dispatch(setShouldEnableAssignAll(false));
    for (const ledger of contactLedger || []) {
      if (Number(ledger.financial_outstanding) < 0 && !noOutstandingFinancialAmount) {
        dispatch(setShouldEnableAssignAll(true));
        return;
      }
    }
  }, [contactLedger, dispatch, noOutstandingFinancialAmount]);

  useEffect(() => {
    async function execute() {
      if (state.shouldRefetchLedger) {
        dispatch(setShouldRefetchLedger(false));
        await refetchContactLedger();
      }
    }
    execute();
  }, [dispatch, refetchContactLedger, state.shouldRefetchLedger]);

  const handleContactRefetch = () => {
    refetchContact();
    refetchContactLedger();
  };

  const calculateRollingTotalMap = (ledgers?: Ledger[] | null) => {
    const newRollingTotalMap: RollingTotalMap = {};
    let accumulated = 0;
    const copyOfLedgers = ledgers ? [...ledgers] : [];
    copyOfLedgers.reverse().forEach((ledgerEntry: Ledger) => {
      if (ledgerEntry.posted) {
        accumulated += Number(ledgerEntry?.financial_amount || 0);
        accumulated = Number(accumulated.toFixed(2));
      }
      newRollingTotalMap[ledgerEntry.id] = accumulated;
    });
    setRollingTotalMap(newRollingTotalMap);
  };

  const checkOutstandingFinancialAmounts = (ledger?: Ledger[] | null) => {
    let noOutstandingFinancialAmount = true;
    if (!ledger) {
      return setNoOutstandingFinancialAmount(noOutstandingFinancialAmount);
    }
    ledger.forEach((entry) => {
      if (Number(entry?.financial_outstanding || 0) > 0) {
        noOutstandingFinancialAmount = false;
      }
    });
    return setNoOutstandingFinancialAmount(noOutstandingFinancialAmount);
  };

  useEffect(() => {
    calculateRollingTotalMap(contactLedger);
    checkOutstandingFinancialAmounts(contactLedger);
  }, [contactLedger]);

  if (contactLedgerLoading) {
    return <TabLoading />;
  }

  const shouldShowTakePaymentAction = (ledger: Ledger) => {
    const financialOutstanding = Number(ledger.financial_outstanding || 0);
    return invoiceCanTakePayment(ledger.invoice_status_id!) && Number(ledger.total) > 0 && financialOutstanding > 0;
  };

  const navigateToViewInvoice = (invoiceId: string) => () => {
    navigateTo(routes.viewInvoice, withInvoiceIdParameter(invoiceId));
  };

  const applyOrUnapplyPayment = async (id: string, number: string, apply: boolean) => {
    setAssignmentChangeLoading(true);
    const applyOrUnapplyPropertyName: keyof LedgerUpsert = apply ? 'fnRunAutoApply' : 'fnRunUnApply';
    try {
      const ledgerUpsert = {
        id,
        [applyOrUnapplyPropertyName]: true,
      };

      await updateLedger({
        options: {
          variables: {
            organizationId,
            ledger: ledgerUpsert,
          },
        },
        onSuccess: () => {
          handleContactRefetch();
          setAssignmentChangeLoading(false);
        },
        successMessage: translations.viewContactLedgerPage.getLedgerUpdateSuccessMessage(number),
      });
    } catch (err) {
      showErrorMessage(err.message ?? err);
      setAssignmentChangeLoading(false);
    }
  };

  const autoApplyPayment = async (id: string, number: string) => {
    await applyOrUnapplyPayment(id, number, true);
  };

  const removeAssignment = async (id: string, number: string) => {
    await applyOrUnapplyPayment(id, number, false);
  };

  const openNewPayment = (invoiceId: string) => () => {
    setPaymentInvoiceId(invoiceId);
    dispatch(setPaymentModalVisible(true));
  };

  const openReversePaymentModal = (ledger: Ledger) => {
    setCurrentEditingLedger(ledger);
    dispatch(setReversePaymentModalVisible(true));
  };

  const handleDeleteInvoice = async (invoiceId: string) => {
    setIsSaving(true);
    await deleteInvoice({
      entityId: invoiceId,
      successMessage: translations.invoicePage.deleteInvoiceSuccessMessage,
      onSuccess: handleContactRefetch,
    });
    setIsSaving(false);
  };

  const handlePrintLedger = async (ledger: Ledger) => {
    setIsSaving(true);
    if (useActiveReportsContactReceiptVersion && ledger.type_name_key === LedgerEntryNameKey.Payment) {
      const reportData = await getReportData<'rptGetContactReceipt', QueryRptGetContactReceiptArgs>(
        GetContactReceiptReportAR,
        {
          organizationId,
          practiceId,
          reportParameters: {
            ledger_id: ledger.id,
            contact_id: contact.id,
          },
        }
      );
      navigateToReportViewer({
        reportName: Reports.ContactReceipt,
        data: reportData,
        nestedHeader: true,
      });
    } else {
      await printLedgerReceipt(mapLedgerToReportQueryArgs(ledger, organizationId, practiceId));
    }
    setIsSaving(false);
  };

  const handleInvoiceCompletion = async (invoiceId: string) => {
    await changeInvoiceStatus(setIsSaving, updateInvoice, invoiceId, InvoiceStatusId.Closed, organizationId);
  };

  const handleDeleteLedgerEntry = async (ledgerEntry: Ledger) => {
    setIsSaving(true);
    await deleteLedger({
      entityId: ledgerEntry.id,
      successMessage: translations.viewContactLedgerPage.deleteLedgerEntrySuccessMessage(
        ledgerEntry.type_name_key as LedgerEntryNameKey
      ),
      onSuccess: handleContactRefetch,
    });
    setIsSaving(false);
  };

  const editLedgerEntry = (ledgerEntry: Ledger) => {
    setCurrentEditingLedger(ledgerEntry);
    if (ledgerEntry.type_name_key === LedgerEntryNameKey.Credit) {
      dispatch(setCreditModalVisible(true));
    } else if (ledgerEntry.type_name_key === LedgerEntryNameKey.AccountCharge) {
      dispatch(setAccountChargeModalVisible(true));
    } else if (ledgerEntry.type_name_key === LedgerEntryNameKey.Payment) {
      dispatch(setPaymentModalVisible(true));
    }
  };

  const getMenuItemsFor = (ledgerEntry: Ledger): MenuItemProps[] => {
    if (ledgerEntry.type_name_key === LedgerEntryNameKey.Invoice) {
      const invoiceIsCompletable =
        ledgerEntry.invoice_id &&
        ledgerEntry.invoice_status_id &&
        !isCompletedInvoiceStatus(ledgerEntry.invoice_status_id);
      return getMenuItemsForInvoiceActions({
        editInvoiceAction: navigateToViewInvoice(ledgerEntry.invoice_id || ''),
        showNewPaymentAction: shouldShowTakePaymentAction(ledgerEntry),
        newPaymentAction: openNewPayment(ledgerEntry.invoice_id ?? ''),
        showDeleteInvoice: invoiceIsDeletable(ledgerEntry.invoice_status_id),
        deleteInvoiceAction: () => handleDeleteInvoice(ledgerEntry.invoice_id!),
        emailInvoiceAction: () => setInvoiceIdForEmailModal(ledgerEntry.invoice_id ?? undefined),
        printInvoiceAction: () => setInvoiceIdForPrintModal(ledgerEntry.invoice_id ?? undefined),
        completeInvoiceAction: invoiceIsCompletable
          ? () => handleInvoiceCompletion(ledgerEntry.invoice_id!)
          : undefined,
        showRemoveAssignment: Number(ledgerEntry.total_assigned) !== 0,
        removeAssignmentAction: () => removeAssignment(ledgerEntry.id, ledgerEntry.number),
        disableEmailOption,
      });
    } else if (
      [LedgerEntryNameKey.Credit, LedgerEntryNameKey.AccountCharge, LedgerEntryNameKey.Payment].includes(
        ledgerEntry.type_name_key as LedgerEntryNameKey
      )
    ) {
      return getMenuItemsForCreditOrAccountChargeOrPaymentActions({
        ledgerEntry,
        editLedgerEntry,
        removeAssignment: () => removeAssignment(ledgerEntry.id, ledgerEntry.number),
        handleDeleteLedgerEntry,
        openReversePaymentModal,
        emailLedgerEntry: setLedgerForEmail,
        printLedgerEntry: handlePrintLedger,
        disableEmailOption,
        openDetailsModal,
      });
    }
    return [];
  };

  const openDetailsModal = async (ledger: Ledger) => {
    flushSync(() => setLoadingDetails(true));
    const { data } = await AppSyncService.client.query<Pick<Query, 'getElectronicPaymentResult'>>({
      query: GetElectronicPaymentResult,
      variables: {
        organizationId,
        practiceId: practice.id,
        id: ledger.payment_electronic_id,
      },
    });
    if (data.getElectronicPaymentResult?.processor_record_id) {
      setStripeChargeId(data.getElectronicPaymentResult.processor_record_id);
    }
    dispatch(setStripeDetailsModalVisible(true));
    setLoadingDetails(false);
  };

  const actionDropdownMenu = (ledgerEntry: Ledger) => {
    return <DropdownButtonWithMenu menuItemProps={getMenuItemsFor(ledgerEntry)} />;
  };

  const linkToViewInvoice = (text: string, ledgerEntry: Ledger, invoice?: string) => {
    return (
      <TableCellLink onClick={navigateToViewInvoice(ledgerEntry.invoice_id || '')}>
        {invoice} {text}
      </TableCellLink>
    );
  };

  const checkLedgerAssignments = (ledgerEntry: Ledger) => {
    const financialOutstanding = Number(ledgerEntry.financial_outstanding);
    const totalAssigned = Number(ledgerEntry.total_assigned);
    const totalReversed = Number(ledgerEntry.total_reversed);

    const isEditableEntry = totalAssigned === totalReversed;
    const showAssignButton = financialOutstanding < 0;
    const showUnassignButton = totalAssigned > totalReversed;

    return { isEditableEntry, showAssignButton, showUnassignButton };
  };

  const getUnassignButton = (ledgerEntry: Ledger) => (
    <DropdownButtonWithMenu
      menuItemProps={[
        {
          title: translations.shared.unassign,
          onClick: () => removeAssignment(ledgerEntry.id, ledgerEntry.number),
        },
        ...getPrintEmailAndStripeDetailsMenuItems({
          ledgerEntry,
          emailLedgerEntry: setLedgerForEmail,
          printLedgerEntry: handlePrintLedger,
          disableEmailOption,
          openDetailsModal,
        }),
      ]}
    />
  );

  const getAssignButton = (ledgerEntry: Ledger) => (
    <Button
      disabled={noOutstandingFinancialAmount}
      onClick={() => autoApplyPayment(ledgerEntry.id, ledgerEntry.number)}
      type='primary'
      key='assign'
    >
      {translations.shared.assign}
    </Button>
  );

  const getPaymentOrCreditLedgerActions = (
    ledgerEntry: Ledger,
    {
      isEditableEntry,
      showAssignButton,
      showUnassignButton,
    }: {
      isEditableEntry: boolean;
      showAssignButton: boolean;
      showUnassignButton: boolean;
    }
  ) => (
    <Space>
      {isEditableEntry && actionDropdownMenu(ledgerEntry)}
      {showAssignButton && getAssignButton(ledgerEntry)}
      {showUnassignButton && getUnassignButton(ledgerEntry)}
    </Space>
  );

  const showReversalLedgerAction = (ledgerEntry: Ledger) => (
    <DropdownButtonWithMenu
      menuItemProps={[
        getMenuItemPropsWithOnCancelPopconfirm(
          {
            title: translations.shared.deleteButtonText,
            onClick: () => handleDeleteLedgerEntry(ledgerEntry),
          },
          translations.viewContactLedgerPage.deleteLedgerEntryConfirmationMessage(
            ledgerEntry.type_name_key as LedgerEntryNameKey
          )
        ),
        ...getPrintEmailAndStripeDetailsMenuItems({
          ledgerEntry,
          emailLedgerEntry: setLedgerForEmail,
          printLedgerEntry: handlePrintLedger,
          disableEmailOption,
          openDetailsModal,
        }),
      ]}
    />
  );

  const onPushpinClick = (ledgerEntry: Ledger) => {
    setCurrentEditingLedger(ledgerEntry);
    dispatch(setNoteEditModalVisible(true));
  };

  const onPayerIconClick = (ledgerEntry: Ledger) => {
    setCurrentEditingLedger(ledgerEntry);
    dispatch(setPaymentModalVisible(true));
  };

  const renderLedgerNotePinWithPopover = (ledgerEntry: Ledger) => {
    return ledgerEntry.note_exists ? (
      <PopoverWithNote
        id={ledgerEntry.id}
        note_exists={ledgerEntry.note_exists ?? false}
        onClick={() => onPushpinClick(ledgerEntry)}
      />
    ) : (
      <PayerPopover onClick={() => onPayerIconClick(ledgerEntry)} ledger={ledgerEntry} />
    );
  };

  const renderActionColumn = (ledgerEntry: Ledger) => {
    if (ledgerEntry.reversal) {
      return showReversalLedgerAction(ledgerEntry);
    }

    if (
      Number(ledgerEntry.financial_outstanding) < 0 ||
      Number(ledgerEntry.total_assigned) > Number(ledgerEntry.total_reversed)
    ) {
      const { isEditableEntry, showAssignButton, showUnassignButton } = checkLedgerAssignments(ledgerEntry);

      return getPaymentOrCreditLedgerActions(ledgerEntry, {
        isEditableEntry,
        showAssignButton,
        showUnassignButton,
      });
    }

    return actionDropdownMenu(ledgerEntry);
  };

  const columns: CustomColumnType<Ledger>[] = [
    {
      ...basicLedgerColumns.number,
      render: (name: string, ledgerEntry: Ledger) => {
        if (ledgerEntry.type_name_key === LedgerEntryNameKey.Invoice && isOnline) {
          return linkToViewInvoice(name, ledgerEntry);
        }
        return name;
      },
      width: 130,
    },
    {
      ...basicLedgerColumns.financial_date,
      width: 120,
    },
    {
      ...basicLedgerColumns.patient_name,
      width: 150,
    },
    {
      ...basicLedgerColumns.type_name_key,
      render: (ledgerEntry: Ledger) => {
        if (ledgerEntry.type_name_key === LedgerEntryNameKey.Invoice && isOnline) {
          return linkToViewInvoice(
            ledgerEntry?.invoice_number ?? '',
            ledgerEntry,
            translations.viewContactLedgerPage.invoiceNumber
          );
        }
        return getLedgerEntryType(ledgerEntry);
      },
      width: 200,
    },
    {
      ...basicLedgerColumns.invoice_status_name_key,
      width: 150,
    },
    {
      ...basicLedgerColumns.total,
      render: ({ total, reversal }: Ledger) => {
        return <PriceValue colorNegative={reversal ? 'red' : 'green'} value={Number(total)} />;
      },
      width: 150,
    },
    {
      ...basicLedgerColumns.financial_outstanding,
      render: (financial_outstanding: string) => <PriceValue value={Math.abs(Number(financial_outstanding))} />,
      width: 150,
    },
    {
      title: translations.viewContactLedgerPage.columns.rollingTotal,
      render: (ledgerEntry: Ledger) => (
        <PriceValue value={rollingTotalMap[ledgerEntry.id] || 0} colorNegative={'green'} colorPositive={'red'} />
      ),
      align: 'right',
      width: 150,
    },
    {
      ...basicLedgerColumns.note_exists,
      width: isOnline ? 80 : undefined,
      render: renderLedgerNotePinWithPopover,
      align: !isOnline ? 'left' : undefined,
    },
    ...(isOnline
      ? [
          {
            title: translations.invoicesPage.columns.actions,
            key: 'actions',
            render: (ledgerEntry: Ledger) => renderActionColumn(ledgerEntry),
          },
        ]
      : [{}]),
  ];
  addSortingPriorityTo(columns);

  const onSuccess = () => {
    setShowTable(false);
    handleContactRefetch();
    setShowTable(true);
  };

  function closePaymentModel() {
    dispatch(setPaymentModalVisible(false));
    setPaymentInvoiceId('');
  }

  function getPaymentModal() {
    if (paymentInvoiceId !== '') {
      return (
        <InvoicePaymentModal
          onClose={closePaymentModel}
          invoiceId={paymentInvoiceId}
          contactId={contact.id}
          onSuccess={onSuccess}
        />
      );
    }

    if (currentEditingLedger) {
      const handleOnSuccess = () => {
        handleContactRefetch();
        setCurrentEditingLedger(undefined);
      };
      const handleOnClose = () => {
        dispatch(setPaymentModalVisible(false));
        setCurrentEditingLedger(undefined);
      };
      return (
        <EditPaymentModal
          onClose={handleOnClose}
          contactId={contact.id}
          onSuccess={handleOnSuccess}
          ledgerEntry={currentEditingLedger}
        />
      );
    }

    return (
      <ContactPaymentModal
        onClose={() => dispatch(setPaymentModalVisible(false))}
        contactId={contact.id}
        onSuccess={onSuccess}
      />
    );
  }

  const handleOnClose = () => {
    setCurrentEditingLedger(undefined);
    dispatch(setCreditModalVisible(false));
    dispatch(setAccountChargeModalVisible(false));
    dispatch(setNoteEditModalVisible(false));
    dispatch(setReversePaymentModalVisible(false));
  };

  const savingSpinnerMessage =
    (loadingDetails && translations.viewContactLedgerPage.detailsLoading) ||
    (isSaving && translations.loadingComponent.saving) ||
    translations.shared.loading;

  return (
    <SaveSpinner
      testId={contactLedgerContainerTestId}
      isSaving={isSaving || assignmentChangeLoading || loadingDetails}
      savingMessage={savingSpinnerMessage}
    >
      {state.paymentModalVisible && getPaymentModal()}
      {state.stripeDetailsModalVisible && stripeConnectInstance && stripeChargeId && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPaymentDetails
            chargeId={stripeChargeId}
            onClose={() => dispatch(setStripeDetailsModalVisible(false))}
          />
        </ConnectComponentsProvider>
      )}
      {state.creditModalVisible && (
        <LedgerChargeModal
          onClose={handleOnClose}
          contactId={contact.id}
          editingLedger={currentEditingLedger}
          onSuccess={handleContactRefetch}
          title={
            currentEditingLedger
              ? translations.creditModal.getEditingTitleForCredit(currentEditingLedger.number)
              : translations.creditModal.title
          }
          successMessage={
            currentEditingLedger
              ? translations.creditModal.getEditingSuccessMessageForCredit(currentEditingLedger.number)
              : translations.creditModal.succcessMessage
          }
          ledgerType={ledgerEntryConfigs.LGR_CREDIT}
        />
      )}
      {state.accountChargeModalVisible && (
        <LedgerChargeModal
          onClose={handleOnClose}
          contactId={contact.id}
          onSuccess={handleContactRefetch}
          editingLedger={currentEditingLedger}
          title={
            currentEditingLedger
              ? translations.chargeModal.getEditingTitleForCharge(currentEditingLedger.number)
              : translations.chargeModal.title
          }
          successMessage={
            currentEditingLedger
              ? translations.chargeModal.getEditingSuccessMessageForCharge(currentEditingLedger.number)
              : translations.chargeModal.succcessMessage
          }
          ledgerType={ledgerEntryConfigs.LGR_CHARGE}
        />
      )}
      {state.noteEditModalVisible && currentEditingLedger && (
        <NoteEditModal
          title={translations.noteEditModal.getEditNoteTitle(currentEditingLedger.number)}
          onClose={handleOnClose}
          onSuccess={handleContactRefetch}
          successMessage={translations.noteEditModal.getEditingSuccessMessageForNoteUpdate(currentEditingLedger.number)}
          ledgerId={currentEditingLedger.id}
        />
      )}
      {state.reversePaymentModalVisible && currentEditingLedger && (
        <ReversePaymentModal
          onClose={handleOnClose}
          onSuccess={onSuccess}
          contact={contact}
          ledgerEntry={currentEditingLedger}
        />
      )}

      {showTable && (
        <TableWithCustomFiltering
          tableKey={TableKey.ContactLedger}
          dataSource={contactLedger ?? []}
          columns={columns}
          rowKey={'id'}
        />
      )}
      <AddInvoiceModalContainer contactId={contact.id} />
      {invoiceIdForPrintModal && (
        <PrintInvoiceReportModal
          invoiceId={invoiceIdForPrintModal}
          onClose={() => setInvoiceIdForPrintModal(undefined)}
        />
      )}
      {invoiceIdForEmailModal && (
        <EmailModalForInvoiceReport
          invoiceId={invoiceIdForEmailModal}
          onClose={() => setInvoiceIdForEmailModal(undefined)}
        />
      )}
      {ledgerForEmail && (
        <EmailModalForLedgerReceipt
          contact={contact}
          ledger={ledgerForEmail}
          onClose={() => setLedgerForEmail(undefined)}
        />
      )}
    </SaveSpinner>
  );
};
