import React, { useCallback, useContext, useMemo } from 'react';
import { phoneTableActions, PhoneTableContext } from '../phoneTableState';
import { Button, Space } from 'antd';
import { translations } from '../../../constants/translations';
import {
  DropdownButtonWithMenu,
  DropdownButtonWithMenuProps,
} from '../../DropdownButtonWithMenu/DropdownButtonWithMenu';
import { ArrayElement } from '../../../util/typeUtil';
import { Phone } from '../../../graph/types';
import { useHandlePhoneTableRowEdit, validatePhone } from '../phoneTableUtil';
import { showErrorMessage } from '../../Notification/notificationUtil';

type PhoneTableActionsCellsProps = {
  phone: Phone;
};

export const phoneTableActionCellTestIds = {
  saveBtn: 'PHONE_TABLE_ACTION_CELL_SAVE_BTN',
  cancelBtn: 'PHONE_TABLE_ACTION_CELL_CANCEL_BTN',
};

export default function PhoneTableActionsCells({ phone }: PhoneTableActionsCellsProps) {
  const { state, dispatch } = useContext(PhoneTableContext);
  const handleOnEdit = useHandlePhoneTableRowEdit(phone);
  const currentlyEditing = useMemo(
    () => state.currentEditingPhone?.phone?.id === phone.id,
    [phone.id, state.currentEditingPhone]
  );

  const handleOnDelete = useCallback(() => {
    if (state.phoneTableData.length > 1 && phone.primary) {
      showErrorMessage(translations.phoneTable.cannotDeletePrimary);
      return;
    }
    state.onDelete?.(phone);
  }, [phone, state]);

  const menuItemProps: DropdownButtonWithMenuProps['menuItemProps'] = useMemo(() => {
    const editButtonMenu: ArrayElement<DropdownButtonWithMenuProps['menuItemProps']> = {
      title: translations.shared.editButtonText,
      onClick: handleOnEdit,
      disabled: !!state.currentEditingPhone,
    };
    const deleteButtonMenu: ArrayElement<DropdownButtonWithMenuProps['menuItemProps']> = {
      title: translations.shared.deleteButtonText,
      onClick: handleOnDelete,
      popconfirmProps: {
        title: translations.phoneTable.deletePhoneConfirm,
        okText: translations.shared.popconfirm.no,
        cancelText: translations.shared.popconfirm.ok,
      },
      executeOnClickOnPopconfirmCancelInsteadOfConfirm: true,
    };
    return [editButtonMenu, deleteButtonMenu];
  }, [handleOnDelete, handleOnEdit, state.currentEditingPhone]);

  const handleOnSave = useCallback(async () => {
    if (state.currentEditingPhone?.phone && validatePhone(state.currentEditingPhone.phone)) {
      try {
        if (state.currentEditingPhone.isNew) {
          await state.onAddSave?.(state.currentEditingPhone.phone);
        } else {
          await state.onEditSave?.(state.currentEditingPhone.phone);
        }
        dispatch(phoneTableActions.setCurrentEditing());
      } catch {}
    }
  }, [dispatch, state]);

  const handleOnCancel = useCallback(() => {
    dispatch(phoneTableActions.setCurrentEditing());
  }, [dispatch]);

  if (state.currentEditingPhone) {
    if (currentlyEditing) {
      return (
        <Space direction={'horizontal'}>
          <Button type='link' onClick={handleOnSave} data-testid={phoneTableActionCellTestIds.saveBtn}>
            {translations.shared.saveButtonText}
          </Button>
          <Button type='link' onClick={handleOnCancel} data-testid={phoneTableActionCellTestIds.cancelBtn}>
            {translations.shared.cancelButtonText}
          </Button>
        </Space>
      );
    }
    return null;
  }
  return <DropdownButtonWithMenu menuItemProps={menuItemProps} />;
}
