import { Button } from 'antd';
import React, { PropsWithChildren } from 'react';

interface TableCellLinkProps extends PropsWithChildren<unknown> {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export const TableCellLink: React.FC<TableCellLinkProps> = (props: TableCellLinkProps) => {
  return (
    <Button
      style={{ marginLeft: -15 }}
      type='link'
      disabled={props.disabled}
      title={props.title}
      onClick={props.onClick}
      data-testid={props.dataTestId}
    >
      {props.children}
    </Button>
  );
};
