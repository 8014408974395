import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { translations } from '../../constants/translations';
import { ReportSettingsForm } from './ReportSettingsForm/ReportSettingsForm';

export const ReportSettings = () => {
  const { TabPane } = Tabs;

  return (
    <>
      <PageHeader title={translations.reportSettingsPage.title} />
      <Tabs type='card'>
        <TabPane tab={translations.reportSettingsPage.tabs.settings} key={'invoiceFooter'}>
          <ReportSettingsForm />
        </TabPane>
      </Tabs>
    </>
  );
};
