import React from 'react';
import ModalWithCloseConfirm from '../../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../../../constants/translations';
import { Patient } from '../../../../graph/types';

export interface ErrorsModalProps {
  visible: boolean;
  onClose?: () => void;
  patients?: Patient[];
}

export const ErrorsModal: React.FC<ErrorsModalProps> = ({ visible, onClose, patients }) => {
  return (
    <ModalWithCloseConfirm
      title={translations.serviceErrorsModal.title}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <p>{translations.serviceErrorsModal.message}</p>
      <div>
        {patients?.map((patient) => {
          return <p key={patient.id}>{patient.name}</p>;
        })}
      </div>
    </ModalWithCloseConfirm>
  );
};
