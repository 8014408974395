import { Form, Input, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { ReportTags } from '../../../components/ReportTags/ReportTags';
import { SaveAndResetButton } from '../../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { PracticeSettingsId, PracticeSettingsNameKey, translations } from '../../../constants/translations';
import { OffsetTitle } from '../../../globalStyles.style';
import { Setting, SettingDto, SettingUpsert } from '../../../graph/types';
import { useDefaultPracticeId } from '../../../hooks/ajax/practice/practiceHooks';
import { getSettingUpsert, getSettingUpsertVoid, useUpsertSetting } from '../../../hooks/ajax/setting/settingHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useFormChanges } from '../../../hooks/useFormChanges';
import { getRequiredRule } from '../../../util/forms';
import { getPracticeSetting } from '../../Contacts/ViewContact/statementUtils';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { setPracticeSetting } from '../../../contexts/organization/action';
import { defaultPractice } from '../../../contexts/organization/selectors';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { usePrescriptionLabelDefaultOptions } from '../../../hooks/usePrescriptionDefaultOptions';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { InfoCircleOutlined } from '@ant-design/icons';

type ReportSettingsFormType = {
  invoiceFooter: string;
  invoiceHideDiscount: boolean;
  invoiceBody: string;
  invoiceSubject: string;
  prescriptionLabel?: string;
};

export const ReportSettingsForm = () => {
  const [form] = Form.useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { dispatch: orgContextDispatch, state } = useOrganizationContext();
  const practice = defaultPractice(state);
  const labelsEnabled = useLDFlag(LDFlagNames.PrescriptionLabels);
  const { prescriptionLabelDefaultOptions, prescriptionLabelDefaults } = usePrescriptionLabelDefaultOptions(
    !labelsEnabled
  );

  const handleUpdateContext = (newSetting?: Setting) => {
    if (practice && newSetting) {
      orgContextDispatch(setPracticeSetting(practice.id, { ...newSetting, __typename: 'SettingDTO' } as SettingDto));
    }
  };

  const [upsertSetting] = useUpsertSetting(handleUpdateContext);

  const practiceInvoiceFooter = getPracticeSetting(PracticeSettingsNameKey.InvoiceFooter, practice);
  const practiceHideDiscount = getPracticeSetting(PracticeSettingsNameKey.InvoiceHideDiscount, practice);
  const practiceHideDiscountValue = practiceHideDiscount?.value === 'true';
  const practiceInvoiceSubject = getPracticeSetting(PracticeSettingsNameKey.InvoiceSubject, practice);
  const practiceInvoiceBody = getPracticeSetting(PracticeSettingsNameKey.InvoiceBody, practice);
  const practicePrescriptionLabel = JSON.parse(
    getPracticeSetting(PracticeSettingsNameKey.PrescriptionLabel, practice)?.value ?? '{}'
  );

  const initialValues = {
    invoiceFooter: practiceInvoiceFooter?.value,
    invoiceHideDiscount: practiceHideDiscountValue,
    invoiceSubject: practiceInvoiceSubject?.value,
    invoiceBody: practiceInvoiceBody?.value,
    prescriptionLabel: practicePrescriptionLabel?.id,
  };

  const [loading, setLoading] = useState<boolean>(false);

  const { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange } = useFormChanges(
    form,
    initialValues
  );

  if (!practice) {
    return <p>{translations.shared.errorLoadingPage}</p>;
  }

  const handleHideDiscountToggle = async (invoiceHideDiscount: boolean) => {
    setLoading(true);

    let upsert;

    if (invoiceHideDiscount) {
      upsert = getSettingUpsert(
        invoiceHideDiscount,
        PracticeSettingsId.InvoiceHideDiscount,
        practiceHideDiscount?.id,
        practiceId
      );
    } else {
      upsert = practiceHideDiscount && getSettingUpsertVoid(practiceHideDiscount);
    }

    try {
      await upsertSetting({
        variables: {
          organizationId,
          setting: upsert,
        },
      });
      if (practiceHideDiscount && !invoiceHideDiscount) {
        // In this case the upsert response data is null so we need to call the context update manually
        await handleUpdateContext({
          ...practiceHideDiscount,
          value: 'false',
        });
      }
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
    }

    setHasDataChanged(false);
    setLoading(false);
  };

  const handleFinish = async ({
    invoiceFooter,
    invoiceBody,
    invoiceSubject,
    prescriptionLabel,
  }: ReportSettingsFormType) => {
    setLoading(true);

    let upsert;
    let bodySettingUpsert;
    let subjectSettingUpsert;
    const promises: Promise<any>[] = [];

    const getUpsert = (upsert: SettingUpsert) => ({
      variables: {
        organizationId,
        setting: upsert,
      },
    });

    if (invoiceFooter !== practiceInvoiceFooter?.value) {
      if (invoiceFooter !== '') {
        upsert = getSettingUpsert(
          invoiceFooter,
          PracticeSettingsId.InvoiceFooter,
          practiceInvoiceFooter?.id,
          practiceId
        );
        promises.push(upsertSetting(getUpsert(upsert)));
      } else {
        upsert = practiceInvoiceFooter && getSettingUpsertVoid(practiceInvoiceFooter);
        upsert && promises.push(upsertSetting(getUpsert(upsert)));
      }
    }

    if (invoiceBody !== practiceInvoiceBody?.value) {
      if (invoiceBody !== '') {
        bodySettingUpsert = getSettingUpsert(
          invoiceBody,
          PracticeSettingsId.InvoiceBody,
          practiceInvoiceBody?.id,
          practiceId
        );
        promises.push(upsertSetting(getUpsert(bodySettingUpsert)));
      } else {
        bodySettingUpsert = practiceInvoiceBody && getSettingUpsertVoid(practiceInvoiceBody);
        bodySettingUpsert && promises.push(upsertSetting(getUpsert(bodySettingUpsert)));
      }
    }

    if (invoiceSubject !== practiceInvoiceSubject?.value) {
      if (invoiceSubject !== '') {
        subjectSettingUpsert = getSettingUpsert(
          invoiceSubject,
          PracticeSettingsId.InvoiceSubject,
          practiceInvoiceSubject?.id,
          practiceId
        );
        promises.push(upsertSetting(getUpsert(subjectSettingUpsert)));
      } else {
        subjectSettingUpsert = practiceInvoiceSubject && getSettingUpsertVoid(practiceInvoiceSubject);
        subjectSettingUpsert && promises.push(upsertSetting(getUpsert(subjectSettingUpsert)));
      }
    }

    if (prescriptionLabel !== practicePrescriptionLabel?.id) {
      const label = prescriptionLabelDefaults?.find((label) => label.id === prescriptionLabel);
      if (label) {
        const prescriptionLabelSetting = getSettingUpsert(
          JSON.stringify(label),
          PracticeSettingsId.PrescriptionLabel,
          practicePrescriptionLabel?.id,
          practiceId
        );
        promises.push(upsertSetting(getUpsert(prescriptionLabelSetting)));
      }
    }

    try {
      await Promise.all(promises);
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      showErrorMessage(translations.shared.saveErrorMessage);
    }

    setHasDataChanged(false);
    setLoading(false);
  };

  return (
    <SaveSpinner isSaving={loading}>
      <Form<ReportSettingsFormType>
        form={form}
        wrapperCol={{ span: 7 }}
        labelCol={{ span: 5 }}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleFinish}
        autoComplete='off'
      >
        <OffsetTitle offset={5} level={4}>
          {translations.reportSettingsPage.optionsHeader}
        </OffsetTitle>
        <Form.Item
          name='invoiceHideDiscount'
          label={translations.shared.discountModal.fields.hideDiscount}
          valuePropName='checked'
        >
          <Switch onChange={handleHideDiscountToggle} />
        </Form.Item>
        <Form.Item
          name='prescriptionLabel'
          label={translations.reportSettingsPage.prescriptionLabel}
          hidden={!labelsEnabled}
          tooltip={{
            icon: <InfoCircleOutlined />,
            title: translations.reportSettingsPage.prescriptionLabelTooltip,
            color: 'black',
          }}
        >
          <Select
            {...searchableSelectParams}
            options={prescriptionLabelDefaultOptions}
            placeholder={translations.reportSettingsPage.placeholders.label}
          />
        </Form.Item>
        <Form.Item
          name='invoiceSubject'
          label={translations.reportSettingsPage.invoiceSubjectLabel}
          rules={[getRequiredRule(translations.reportSettingsPage.invoiceSubjectLabel)]}
        >
          <Input placeholder={translations.reportSettingsPage.placeholders.subject} />
        </Form.Item>
        <Form.Item
          name='invoiceBody'
          label={translations.reportSettingsPage.invoiceBodyLabel}
          rules={[getRequiredRule(translations.reportSettingsPage.invoiceBodyLabel)]}
        >
          <TextArea placeholder={translations.reportSettingsPage.placeholders.body} />
        </Form.Item>
        <Form.Item name='invoiceFooter' label={translations.reportSettingsPage.invoiceFooterLabel}>
          <TextArea autoSize={{ minRows: 8 }} placeholder={translations.reportSettingsPage.placeholders.footer} />
        </Form.Item>
        <ReportTags tagList={['InvoiceDate', 'ContactName']} titleOffset={5} contentOffset={7} />
        {hasDataChanged && <SaveAndResetButton onReset={handleOnReset} offset={5} />}
      </Form>
    </SaveSpinner>
  );
};
