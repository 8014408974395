import React, { PropsWithChildren } from 'react';
import { Button, Row, Upload } from 'antd';
import { translations } from '../../../constants/translations';
import { UploadOutlined } from '@ant-design/icons/lib';
import { FileData, getUploadRequestToS3 } from '../../../util/fileUpload';

interface FileFieldsProps extends PropsWithChildren<unknown> {
  organizationId: string;
  setIsSaving: (isSaving: boolean) => void;
  setFileData: (fileData: FileData) => void;
  filename?: string;
}

export const FileFieldsForNewFile: React.FC<FileFieldsProps> = ({
  organizationId,
  setIsSaving,
  setFileData,
  filename,
}: FileFieldsProps) => {
  const uploadButtonText = filename
    ? translations.recordList.changeFileButtonText
    : translations.recordList.uploadButtonText;
  return (
    <Row style={{ marginBottom: 10, alignItems: 'center' }}>
      <Upload customRequest={getUploadRequestToS3(organizationId, setIsSaving, setFileData)} showUploadList={false}>
        <Button icon={<UploadOutlined />}>{uploadButtonText}</Button>
      </Upload>
      <div style={{ marginLeft: 20 }}>{filename}</div>
    </Row>
  );
};
