import gql from 'graphql-tag';
import { FileS3UrlFields } from '../fragments';

export const GetUrlForFileDownload = gql`
  query getUrlForFileDownload($organizationId: ID!, $filePointer: String!, $fileName: String!, $contentType: String) {
    getUrlForFileDownload(
      organizationId: $organizationId
      filePointer: $filePointer
      fileName: $fileName
      contentType: $contentType
    ) {
      ...FileS3UrlFields
    }
  }
  ${FileS3UrlFields}
`;

export const GetUrlForFileUpload = gql`
  query getUrlForFileUpload($organizationId: ID!, $filePath: String) {
    getUrlForFileUpload(organizationId: $organizationId, filePath: $filePath) {
      ...FileS3UrlFields
    }
  }
  ${FileS3UrlFields}
`;
