import { LabResultRecord, UnassignedLabResults } from '../../graph/types';
import { StatusConfig } from '../../util/filterAndSorting';
import { TagColor } from '../tagColor';

export enum LabDeviceId {
  Antech = '399',
}

export enum LabDeviceName {
  Antech = 'Antech',
  VetConnect = 'IDEXX Reference Lab',
  VetConnectVetLabStation = 'IDEXX VetLab Station',
}

export enum LabClassId {
  Antech = '1',
  VetConnect = '2',
}

export enum LabRequestStatusId {
  Pending = 1,
  Submitted = 2,
  Complete = 3,
  Cancelled = 4,
  Draft = 5,
  Accessioned = 6,
  PendingDelete = 7,
  DeletedAtLab = 8,
  SubmissionFailed = 9,
  PendingDeleteFailed = 10,
  TaxonomyRequired = 11,
  InProgress = 12,
}

export const labRequestStatusConfig: { [status in LabRequestStatusId]: StatusConfig } = {
  [LabRequestStatusId.Pending]: {
    text: 'Pending',
    color: TagColor.Color4,
  },
  [LabRequestStatusId.Submitted]: {
    text: 'Submitted',
    color: TagColor.Color6,
  },
  [LabRequestStatusId.Complete]: {
    text: 'Complete',
    color: TagColor.Color1,
  },
  [LabRequestStatusId.Cancelled]: {
    text: 'Cancelled',
    color: TagColor.Color5,
  },
  [LabRequestStatusId.Draft]: {
    text: 'Draft',
    color: TagColor.Color2,
  },
  [LabRequestStatusId.Accessioned]: {
    text: 'Accessioned',
    color: TagColor.Color3,
  },
  [LabRequestStatusId.PendingDelete]: {
    text: 'Pending Delete',
    color: TagColor.Color7,
  },
  [LabRequestStatusId.DeletedAtLab]: {
    text: 'Deleted at Lab',
    color: TagColor.Color8,
  },
  [LabRequestStatusId.SubmissionFailed]: {
    text: 'Submission Failed',
    color: TagColor.Color9,
  },
  [LabRequestStatusId.PendingDeleteFailed]: {
    text: 'Pending Delete Failed',
    color: TagColor.Color11,
  },
  [LabRequestStatusId.TaxonomyRequired]: {
    text: 'Taxonomy Required',
    color: TagColor.Color10,
  },
  [LabRequestStatusId.InProgress]: {
    text: 'In Progress',
    color: TagColor.Color12,
  },
};

export const getResultStatusText = (result: LabResultRecord | UnassignedLabResults): string => {
  return labRequestStatusConfig[result.status_id as LabRequestStatusId]?.text || '';
};
