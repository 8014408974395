import React, { PropsWithChildren } from 'react';
import { translations } from '../../../constants/translations';
import { SelectContactStatus } from '../SelectContactStatus/SelectContactStatus';
import { PhysicalAndMailingAddress } from '../../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import { ContactStatusType } from '../../../graph/types';
import { Col, Grid, Input, Row } from 'antd';
import { getRequiredRule, getValidEmailRule } from '../../../util/forms';
import { AddressForm } from '../../../components/AddressBlock/AddressBlock';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import PhoneTable from '../../../components/PhoneTable/PhoneTable';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import TextArea from 'antd/lib/input/TextArea';
import { NoBottomMarginFormItem } from '../../../globalStyles.style';

const MetadataSpan = styled.span`
  size: 10px;
  color: #c0c0c0;
`;

const FullWidthRow = styled(Row)`
  width: 100%;
`;

export const sizingProps = { lg: 12, md: 24, sm: 24 };

const offsetSizingProps = { ...sizingProps, lg: 10, offset: 2 };

export const statementIgnoreFieldName = 'statementIgnore';
export const taxExemptFieldName = 'tax_exempt';
export const chargeInterestFieldName = 'interest_ignore';
export const noteFieldName = 'note';
export const emrFieldName = 'third_party';

export interface ContactPropertiesFormFields {
  [statementIgnoreFieldName]: boolean;
  [taxExemptFieldName]: boolean;
  [chargeInterestFieldName]: boolean;
}

// If new property is added to this type make sure the didRecordValuesChange method in ContactUpsertGenerator still
// works as intended
export interface ContactFormBasicFields {
  name: string;
  email: string;
  contact_status_id: string;
  checkBoxSameAddresses: boolean;
  sendType: number;
  statementIgnore: boolean;
  tax_exempt: boolean;
  chargeInterest: boolean;
  note: string;
}

export type ContactFormFields = ContactFormBasicFields & AddressForm;

export interface ContactFormProps extends PropsWithChildren<unknown> {
  indexOfPhysicalAddress: number;
  indexOfMailingAddress: number;
  hideMailingAddress: boolean;
  contactStatuses?: ContactStatusType[];
  form: FormInstance;
  isModalView?: boolean;
  newContact?: boolean;
  contactTextUpdateLine?: string;
  isEmailRequired?: boolean;
}

export const ContactForm: React.FC<ContactFormProps> = ({
  indexOfPhysicalAddress,
  indexOfMailingAddress,
  hideMailingAddress,
  form,
  contactStatuses,
  newContact,
  contactTextUpdateLine,
  isEmailRequired,
}) => {
  const { useBreakpoint } = Grid;

  const { lg } = useBreakpoint();

  return (
    <>
      <FullWidthRow>
        <Col {...sizingProps}>
          <MaxLengthFormItem
            name='name'
            label={translations.viewContactPage.details.name}
            rules={[getRequiredRule(translations.addContactPage.fields.name)]}
            maxLength={100}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <SelectContactStatus contactStatuses={contactStatuses || []} />
          <MaxLengthFormItem
            validateTrigger='onBlur'
            name='email'
            label={translations.viewContactPage.details.email}
            rules={[
              ...(isEmailRequired ? [getRequiredRule(translations.viewContactPage.details.email)] : []),
              getValidEmailRule(),
            ]}
            maxLength={1000}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <Col offset={4}>
            <h3>{translations.viewContactPage.headers.note}</h3>
          </Col>
          <NoBottomMarginFormItem name={noteFieldName} wrapperCol={{ offset: 4 }}>
            <TextArea autoSize={{ minRows: 3 }} maxLength={2000} placeholder={translations.shared.note.placeholder} />
          </NoBottomMarginFormItem>
          <Col offset={4}>
            <MetadataSpan>
              {translations.viewContactPage.details.updated_by}
              {contactTextUpdateLine}
            </MetadataSpan>
          </Col>
        </Col>
        <Col {...(lg ? offsetSizingProps : sizingProps)}>
          <PhoneTable newContact={newContact} />
        </Col>
      </FullWidthRow>
      <PhysicalAndMailingAddress
        indexOfPhysicalAddress={indexOfPhysicalAddress}
        indexOfMailingAddress={indexOfMailingAddress}
        hideMailingAddress={hideMailingAddress}
        form={form}
        allFieldsOptional
      />
    </>
  );
};
