import React, { useState } from 'react';
import { useGetPatientsWithSearch } from '../../../hooks/ajax/patients/patientHooks';
import { translations } from '../../../constants/translations';
import { joinPatientOrContactNameAndNumber, joinPatientNameAndPrimaryOwnerName } from '../../../util/displaying';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { SelectForSearch } from '../../../components/SelectForSearch/SelectForSearch';
import { useGetContactsWithSearch } from '../../../hooks/ajax/contact/contactHooks';
import { Contact, Patient } from '../../../graph/types';
import { OfficeType } from '../doctorOfficeUtil';

interface DoctorOfficeSelectProps {
  handleChangeInSelect: (patientId: string) => Promise<void>;
  officeType: OfficeType;
}

export const DoctorOfficeSelect: React.FC<DoctorOfficeSelectProps> = ({ handleChangeInSelect, officeType }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [searchValue, setSearchValue] = useState<string>();
  const isPatient = officeType === OfficeType.patients;

  const { patients, patientsLoading } = useGetPatientsWithSearch(organizationId, isPatient ? searchValue : undefined);
  const { contacts, contactsLoading } = useGetContactsWithSearch(organizationId, !isPatient ? searchValue : undefined);

  let options: { id: string; value: string }[];

  const getOptionContact = (contact: Contact) => {
    const nameAndNumber = joinPatientOrContactNameAndNumber(contact);
    return { id: contact.id, value: nameAndNumber };
  };

  const getOptionPatient = (patient: Patient) => {
    const nameAndOwnerName = joinPatientNameAndPrimaryOwnerName(patient);
    return { id: patient.id, value: nameAndOwnerName };
  };

  if (isPatient) {
    options = patients?.map(getOptionPatient) ?? [];
  } else {
    options = contacts?.map(getOptionContact) ?? [];
  }

  return (
    <SelectForSearch
      isLoading={patientsLoading || contactsLoading}
      placeholder={translations.doctorOffice.getSelectPlaceholder(officeType)}
      setSearchValue={setSearchValue}
      options={options}
      onSelect={handleChangeInSelect}
      dataTestId={'patient-select'}
    />
  );
};
