import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { SelectPersonModalTableProps } from '../SelectContactModal/SelectContactModalTable';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { Patient } from '../../graph/types';
import { TableCellLink } from '../TableLink/TableCellLink';
import { addSortingPriorityTo } from '../../util/filterAndSorting';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { getStatus, getStatusTag } from '../../util/statusUtils';
import { getSpeciesFilterOptions } from '../../util/patientFilterAndSorting';
import './SelectPatientModalTable.css';
import { AbbreviateToWidth } from '../AbbreviateToWidth/AbbreviateToWidth';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../constants/layout';
import { basicPatientColumns } from '../../pages/Patients/PatientsOverview/patientColumns';
import { translations } from '../../constants/translations';
import { getPatientRelationTags } from '../../util/tags';
import { mapRefDataName } from '../../util/refDataUtil';
import { TableKey, useTableColumnDisplayFilter, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { patientPropertyNames } from '../../constants/propertyNames';
import { ColumnDisplayFilterContext } from '../ColumnDisplayFilter/store/state';
import { setColumnDisplayFilterProps } from '../ColumnDisplayFilter/store/actions';
import { SelectEntityModalTable } from '../SelectEntityModalTable/SelectEntityModalTable';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';

interface SelectPatientModalTableProps extends SelectPersonModalTableProps {
  setSelectedPatients: (patient: Patient[]) => void;
  selectedPatients: Patient[];
  patients?: Patient[];
  patientsLoading?: boolean;
  canSelectMultiple?: boolean;
  filterAndSortTableValues: ReturnType<typeof useTableResetFilterAndSort>;
  setPatientTableColumns: (columns: CustomColumnType<Patient>[]) => void;
}

export const SelectPatientModalTable: React.FC<SelectPatientModalTableProps> = ({
  setSelectedPatients,
  selectedPatients,
  patients,
  patientsLoading,
  canSelectMultiple,
  filterAndSortTableValues,
  setPatientTableColumns,
}: SelectPatientModalTableProps) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const { referenceData } = useGetReferenceData(organization?.id ?? '');
  const { filteredValue, sortOrderMap } = filterAndSortTableValues;

  const patientReferenceData = useMemo(() => organization?.ref_patient, [organization]);

  const speciesFilterOptions: ColumnFilterItem[] = useMemo(
    () => (patientReferenceData ? getSpeciesFilterOptions(patientReferenceData, patients) : []),
    [patientReferenceData, patients]
  );

  const optionalColumns: CustomColumnType<Patient>[] = useMemo(
    () => [
      {
        ...basicPatientColumns.number,
        filteredValue: filteredValue[patientPropertyNames.number] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.number],
        width: 100,
      },
      {
        ...basicPatientColumns.species_name,
        filteredValue: filteredValue[patientPropertyNames.species_name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.species_name],
        render: (p: Patient) =>
          mapRefDataName({ id: p.species_id || '', name: p.species_name || '' }, patientReferenceData?.species),
        filters: speciesFilterOptions,
        width: 100,
      },
      {
        ...basicPatientColumns.owner_names,
        filteredValue: filteredValue[patientPropertyNames.owner_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.owner_names],
        width: 300,
      },
      {
        ...basicPatientColumns.related_names,
        filteredValue: filteredValue[patientPropertyNames.related_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.related_names],
        render: (patient: Patient) => getPatientRelationTags(patient, referenceData?.contact_type || []),
        width: 200,
      },
      {
        ...basicPatientColumns.status,
        filteredValue: filteredValue.status ?? null,
        sortOrder: sortOrderMap.status,
        render: (record: Patient) => <>{getStatusTag(getStatus(record))}</>,
        width: 90,
      },
    ],
    [referenceData?.contact_type, filteredValue, patientReferenceData, sortOrderMap, speciesFilterOptions]
  );

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter(TableKey.SelectPatientModal, optionalColumns);

  const { dispatch } = useContext(ColumnDisplayFilterContext);

  useEffect(() => {
    dispatch(
      setColumnDisplayFilterProps({
        initiallyDisplayedColumns: displayedColumnKeys,
        columnKeyAndTitleList,
        setDisplayedColumns: setDisplayedColumnKeys,
        resetDisplayedColumnsToDefault,
      })
    );
  }, [columnKeyAndTitleList, dispatch, displayedColumnKeys, resetDisplayedColumnsToDefault, setDisplayedColumnKeys]);

  const onPatientClick = useCallback(
    (patient: Patient) => {
      if (canSelectMultiple) {
        if (selectedPatients.filter((selected) => selected.id === patient.id).length === 0) {
          const newSelectedPatients = selectedPatients.concat(patient);
          setSelectedPatients(newSelectedPatients);
        } else {
          const newSelectedPatients = selectedPatients.filter((selected) => selected.id !== patient.id);
          setSelectedPatients(newSelectedPatients);
        }
      } else {
        setSelectedPatients([patient]);
      }
    },
    [setSelectedPatients, canSelectMultiple, selectedPatients]
  );

  const columns: CustomColumnType<Patient>[] = useMemo(
    () => [
      {
        ...basicPatientColumns.name,
        filteredValue: filteredValue[patientPropertyNames.name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name],
        width: 200,
        render: (text: string, patient: Patient) => (
          <TableCellLink onClick={() => onPatientClick(patient)}>
            <AbbreviateToWidth width={200} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
          </TableCellLink>
        ),
        ellipsis: true,
      },
      ...displayedColumns,
    ],
    [displayedColumns, filteredValue, sortOrderMap, onPatientClick]
  );

  setPatientTableColumns(columns);

  if (!organization) {
    return <p>{translations.shared.missingOrganization}</p>;
  }

  addSortingPriorityTo(columns);

  return (
    <SelectEntityModalTable<Patient>
      setSelectedEntities={setSelectedPatients}
      selectedEntities={selectedPatients}
      entities={patients}
      canSelectMultiple={canSelectMultiple}
      filterAndSortTableValues={filterAndSortTableValues}
      columns={columns}
      loading={patientsLoading}
    />
  );
};
