import { gql } from '@apollo/client';

export const SyncErrorFields = gql`
  fragment SyncErrorFields on OfflineUpsertResponse {
    id
    offline_id
    error_message
    created
    type_name
    instruction
  }
`;
