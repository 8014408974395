import { Form, Input } from 'antd';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { ROATypeId, translations } from '../../../constants/translations';
import { InvoiceContact, PatientReferenceData, PatientReferenceDataDto, Service } from '../../../graph/types';
import { useFormChanges } from '../../../hooks/useFormChanges';
import { ControlledDrugModalContent } from '../../ControlledDrugContent/ControlledDrugContent';
import { MicrochipModalContent } from '../../MicrochipModalContent/MicrochipModalContent';
import { RabiesTagModalContent } from '../../RabiesTagModalContent/RabiesTagModalContent';
import ModalWithCloseConfirm, { ModalFooterButtonsForReset } from '../../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { PrescriptionModalContent } from '../../PrescriptionModalContent/PrescriptionModalContent';
import { ReminderFormFields, TitleDiv } from '../../ReminderFormFields/ReminderFormFields';
import { VaccineModalContent } from '../../VaccineModalContent/VaccineModalContent';
import { ServiceOptionsFormValues } from '../AddServiceForm/AddServiceForm';
import { ServicesRenderedContext } from '../store/state';
import { MAX_INPUT_AMOUNT, MIN_INPUT_QTY } from '../../../constants/referenceData/ledgerReferenceData';
import { RoaModalContent } from '../../RoaModalContent/RoaModalContent';
import { SelectOptions } from '../../DependentDropdown/DependentDropdown';
import { PatientFieldsRequirement, PatientModalContent } from '../../PatientModalContent/PatientModalContent';
import { ReasonModalContent } from '../../ReasonModalContent/ReasonModalContent';
import { WithdrawalPeriodModalContent } from '../../WithdrawalPeriodModalContent/WithdrawalPeriodModalContent';
import { getRequiredRule } from '../../../util/forms';
import { HisaFieldsRequirement, HisaRequirementsContent } from '../HisaRequirementsModal/HisaRequirementsContent';
import { useGetPrescriptions } from '../../../hooks/ajax/prescriptionHooks/prescriptionHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { OwnerType } from '../../../hooks/ajax/recordHooks';

export type ServiceOptionsOnClose = {
  roaId?: string;
  roaOther?: string;
  discontinueSave?: boolean;
};

export interface ServiceOptionsModalProps {
  service: Service;
  visible: boolean;
  onClose?: (options: ServiceOptionsOnClose) => void;
  initialValues: ServiceOptionsFormValues;
  isSaving?: boolean;
  onSave?: (value: ServiceOptionsFormValues) => void;
  removedForRapidBilling?: boolean;
  invoiceContacts?: InvoiceContact[];
  doctorOptions?: SelectOptions[];
  patientName?: string;
  patientId?: string;
  patientSpecies?: string;
  hasMissingPatientFields?: boolean;
  requiredPatientFields?: PatientFieldsRequirement;
  patientRefData?: PatientReferenceDataDto;
  deceasedDateVisible?: boolean;
  patientDOB?: string;
  servicesList: Service[];
  hasMissingHisaFields?: boolean;
  requiredHisaFields?: HisaFieldsRequirement;
  isAddingNewServiceRendered?: boolean;
}

export const ServiceOptionsModal: React.FC<ServiceOptionsModalProps> = ({
  service,
  visible,
  onClose,
  initialValues,
  isSaving,
  onSave,
  removedForRapidBilling,
  invoiceContacts,
  doctorOptions,
  patientName,
  patientSpecies,
  hasMissingPatientFields,
  requiredPatientFields,
  patientRefData,
  deceasedDateVisible,
  patientDOB,
  servicesList,
  hasMissingHisaFields,
  requiredHisaFields,
  patientId,
  isAddingNewServiceRendered,
}) => {
  const [form] = Form.useForm();

  const { handleOnValuesChange, hasDataChanged, handleOnReset } = useFormChanges(form, initialValues);
  const parsedTitle = translations.serviceOptionsModal.title(service.name);
  const { state } = useContext(ServicesRenderedContext);

  const organizationId = useGetOrganizationIdFromRoute();

  const { prescriptions, prescriptionsLoading } = useGetPrescriptions(
    organizationId,
    { id: service.prescription_prompt ? patientId || '' : '' },
    OwnerType.Patient,
    {
      returnHidden: false,
    }
  );

  const handleOnCancel = async () => {
    try {
      await form.validateFields();

      if (isSaving) {
        return;
      }

      const roaId = form.getFieldValue('roa_id');
      const roaOther = roaId === ROATypeId.Other ? form.getFieldValue('roa_other') : undefined;
      onClose?.({ roaId, roaOther });
    } catch ({ errorFields }) {
      const erroredFields: string[] = errorFields.map(({ name }: { name: string }) => name[0]);
      const hisaErroredFields = erroredFields.map((field) => field.substring(0, 4)).filter((field) => field === 'hisa');
      if (
        erroredFields.includes('roa_id') ||
        erroredFields.includes('roa_other') ||
        erroredFields.includes('reason') ||
        hisaErroredFields.length > 0
      ) {
        onClose?.({ discontinueSave: true });
      }
    }
  };

  // clear the values by resetting them when the form closes
  useEffect(() => {
    handleOnReset();
  }, [visible, handleOnReset]);

  const getModalContent = () => {
    const content = [];
    let utuofocusSet = false;
    let autofocus = false;
    if ((hasMissingPatientFields || deceasedDateVisible) && !removedForRapidBilling) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'patient'}>
          <TitleDiv>{translations.shared.servicePatientModal.title(patientName ?? '')}</TitleDiv>
          <PatientModalContent
            missingFields={requiredPatientFields}
            patientRef={patientRefData as PatientReferenceData}
            speciesId={patientSpecies}
            form={form}
            patientDOB={patientDOB}
            deceasedDateVisible={deceasedDateVisible}
            shouldAutofocus={autofocus}
            service={service}
            requiredHisaFields={requiredHisaFields}
            isForRapidBilling={removedForRapidBilling}
          />
        </Fragment>
      );
      autofocus = false;
    }
    if (hasMissingHisaFields && requiredHisaFields && !removedForRapidBilling) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'hisa'}>
          <TitleDiv>{translations.shared.hisaRequirementsModal.title}</TitleDiv>
          <HisaRequirementsContent
            missingFields={requiredHisaFields}
            service={service}
            isForRapidBilling={removedForRapidBilling}
          />
        </Fragment>
      );
    }
    if (service.quantity_prompt) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Form.Item
          rules={[
            { max: MAX_INPUT_AMOUNT },
            { min: MIN_INPUT_QTY },
            getRequiredRule(translations.serviceOptionsModal.fields.quantity),
          ]}
          key={'quantity'}
          label={translations.serviceOptionsModal.fields.quantity}
          name='quantity'
        >
          <Input type='number' min={MIN_INPUT_QTY} max={MAX_INPUT_AMOUNT} step='any' autoFocus={autofocus} />
        </Form.Item>
      );
      autofocus = false;
    }
    if (service.vaccine) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'vaccine'}>
          <TitleDiv>{translations.shared.vaccineModal.title}</TitleDiv>
          <VaccineModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.controlled) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'controlled'}>
          <TitleDiv>{translations.shared.controlledDrugModal.title}</TitleDiv>
          <ControlledDrugModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.reminder_ids?.length) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'reminder'}>
          <TitleDiv>{translations.shared.reminderModal.title}</TitleDiv>
          <ReminderFormFields visible={visible} autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.microchip && !requiredPatientFields?.requiresMicrochip && !removedForRapidBilling) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'microchip'}>
          <TitleDiv>{translations.shared.microchipModal.title}</TitleDiv>
          <MicrochipModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.rabies_tag_prompt && !removedForRapidBilling) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'rabies_tag'}>
          <TitleDiv>{translations.shared.rabiesTagModal.title}</TitleDiv>
          <RabiesTagModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.roa_prompt) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'roa_id'}>
          <TitleDiv>{translations.shared.roaModal.title}</TitleDiv>
          <RoaModalContent form={form} />
        </Fragment>
      );
    }
    if (service.prescription_prompt) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'prescription'}>
          <TitleDiv>{translations.shared.prescriptionModal.title}</TitleDiv>
          <PrescriptionModalContent
            form={form}
            contacts={contacts}
            servicesList={servicesList}
            patientName={patientName}
            doctorOptions={doctorOptions}
            removedForRapidBilling={removedForRapidBilling}
            autofocus={autofocus}
            prescriptions={prescriptions}
            serviceId={service.id}
            isAddingNewServiceRendered={isAddingNewServiceRendered}
            isConsumingFill={prescriptions?.some(
              (p) =>
                p.service_id === service.id &&
                p.remaining_fills &&
                !p.is_filled &&
                Number(p.item_quantity) === Number(form.getFieldValue('itemQuantity'))
            )}
            showMultiPatientRefillsMessage={removedForRapidBilling}
          />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.reason_prompt) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'reason'}>
          <TitleDiv>{translations.shared.reasonModal.title}</TitleDiv>
          <ReasonModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    if (service.withdrawal_prompt) {
      if (!utuofocusSet) {
        utuofocusSet = true;
        autofocus = true;
      }
      content.push(
        <Fragment key={'withdrawal_period'}>
          <TitleDiv>{translations.shared.withdrawalModal.title}</TitleDiv>
          <WithdrawalPeriodModalContent autofocus={autofocus} />
        </Fragment>
      );
      autofocus = false;
    }
    return content;
  };

  const contacts = useMemo(
    () =>
      invoiceContacts?.map((contact) => ({
        value: contact.contact_id,
        label: contact.contact_name,
      })),
    [invoiceContacts]
  );

  return (
    <ModalWithCloseConfirm
      title={parsedTitle}
      open={visible}
      onCancel={handleOnCancel}
      isSaving={state.savingState.isSaving || prescriptionsLoading}
      savingMessage={prescriptionsLoading ? translations.shared.loading : undefined}
      changesMade={hasDataChanged}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 9 }}
        initialValues={initialValues}
        onFinish={onSave}
        onValuesChange={handleOnValuesChange}
        autoComplete='off'
      >
        {getModalContent()}
        <ModalFooterButtonsForReset handleOnReset={handleOnReset} hasDataChanged={hasDataChanged} />
      </Form>
    </ModalWithCloseConfirm>
  );
};
