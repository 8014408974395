import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import { ReplicationCollection } from '../../../services/LocalDatabaseService/collections';
import { OfflineModeAction, OfflineModeActionType } from './actions';

export interface Replication {
  state?: RxGraphQLReplicationState<ReplicationCollection>;
}
export interface OfflineModeState {
  initialized: boolean;
  isOnline: boolean;
  enabled: boolean;
  replications: Record<string, Replication>;
  requirePin: boolean;
  isReplicationCompleted: boolean;
  file?: any;
  storageDatabase?: IDBDatabase;
}

export const initialState: OfflineModeState = {
  initialized: false,
  isOnline: window.navigator.onLine,
  enabled: false,
  replications: {},
  requirePin: false,
  isReplicationCompleted: false,
};

export const offlineModeReducer = (
  state: OfflineModeState = initialState,
  action: OfflineModeAction
): OfflineModeState => {
  switch (action.type) {
    case OfflineModeActionType.setOnline:
      return {
        ...state,
        isOnline: action.payload,
      };
    case OfflineModeActionType.setEnabled:
      return {
        ...state,
        enabled: action.payload,
      };
    case OfflineModeActionType.setInitialized:
      return {
        ...state,
        initialized: action.payload,
      };
    case OfflineModeActionType.setReplications:
      return {
        ...state,
        replications: {
          ...state.replications,
          ...action.payload,
        },
      };
    case OfflineModeActionType.resetOfflineContext:
      return {
        ...initialState,
        isOnline: window.navigator.onLine,
        storageDatabase: state.storageDatabase,
      };
    case OfflineModeActionType.setRequirePin:
      return {
        ...state,
        requirePin: action.payload,
      };
    case OfflineModeActionType.setFile:
      return {
        ...state,
        file: action.payload,
      };
    case OfflineModeActionType.setStorageDb:
      return {
        ...state,
        storageDatabase: action.payload,
      };
    default:
      return state;
  }
};
