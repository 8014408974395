import React, { useContext, useEffect } from 'react';
import { useGetPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { setPatientsList } from '../Patients/store/actions';
import { PatientsContext } from '../Patients/store/state';
import { PatientMultiSelect, PatientMultiSelectProps } from './PatientMultiSelect';

export const AllPatientsMultiSelect: React.FC<PatientMultiSelectProps> = (props) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { dispatch } = useContext(PatientsContext);
  const { patients, ...search } = useGetPatientsWithSearch(organizationId, '', true);
  useEffect(() => {
    dispatch(setPatientsList(patients ?? []));
  }, [patients, dispatch]);
  const selectProps = { ...props, ...search };

  return <PatientMultiSelect {...selectProps} />;
};
