import { RelatedOwner } from '../../../../graph/types';
import React, { PropsWithChildren } from 'react';
import { Button, Table, Tag } from 'antd';
import { translations } from '../../../../constants/translations';
import { calculateTotalPercentage, hasExactlyOnePrimaryOwner } from './ownershipUtils';

export interface OwnerTableSummaryDisplayText {
  percentageCheckFailed: string;
  primaryCheckFailed: string;
  validationPassed: string;
  totalMessage: string;
}

interface OwnerTableSummaryProps extends PropsWithChildren<unknown> {
  owners: readonly RelatedOwner[];
  setModalVisible?: (visibility: boolean) => void;
  displayText: OwnerTableSummaryDisplayText;
}

export const OwnerTableSummary: React.FC<OwnerTableSummaryProps> = ({
  owners,
  setModalVisible,
  displayText,
}: OwnerTableSummaryProps) => {
  const totalPercentage = calculateTotalPercentage(owners);

  const totalPercentageWarning = (totalPercentage: number) => {
    if (totalPercentage !== 100) {
      return <Tag color={'volcano'}>{displayText.percentageCheckFailed}</Tag>;
    }
    return <Tag color={'green'}>{displayText.validationPassed}</Tag>;
  };

  const primaryOwnershipWarning = (owners: readonly RelatedOwner[]) => {
    if (!hasExactlyOnePrimaryOwner(owners)) {
      return <Tag color={'volcano'}>{displayText.primaryCheckFailed}</Tag>;
    }
    return null;
  };

  return (
    <>
      {setModalVisible && (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={4}>
            <Button
              style={{ color: 'mediumvioletred' }}
              onClick={() => setModalVisible(true)}
              data-testid={'addOwnerButton'}
            >
              {translations.patientPage.ownership.addOwnerModal.openModalButtonText}
            </Button>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>{displayText.totalMessage}</Table.Summary.Cell>
        <Table.Summary.Cell index={1}>{totalPercentage}</Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={2}>
          {totalPercentageWarning(totalPercentage)}
          {primaryOwnershipWarning(owners)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
