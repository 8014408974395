import React, { PropsWithChildren, useEffect } from 'react';
import { translations } from '../../constants/translations';
import { Prompt } from 'react-router-dom';

interface NavigationWarningProps extends PropsWithChildren<unknown> {
  showNavigationWarning: boolean;
  warningMessage?: string;
}

export const NavigationWarning: React.FC<NavigationWarningProps> = (props: NavigationWarningProps) => {
  const message = props.warningMessage ?? translations.navigation.unsaved;

  const browserNavigationBlockerEvent = (event: BeforeUnloadEvent) => {
    if (props.showNavigationWarning) {
      event.returnValue = '';
      event.preventDefault();
    }
    return event;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', browserNavigationBlockerEvent);

    return () => {
      window.removeEventListener('beforeunload', browserNavigationBlockerEvent);
    };
  });

  return <Prompt when={props.showNavigationWarning} message={message} />;
};
