import { Button, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { StyledPageHeaderWithMargin } from '../../../components/PageHeader/PageHeader.style';
import { ComponentWithPracticeDtoProps } from '../../../components/WithPractice/WithPractice';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { Patient, PatientUpsert, PracticeDto } from '../../../graph/types';
import { useInsertPatient, usePatientOfflineInsert } from '../../../hooks/ajax/patients/patientHooks';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { isMatchIgnoringEmptyStrings } from '../../../util/objectComparisons';
import { WidthContainer } from '../../../globalStyles.style';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useNavigationToRoute, withPatientIdParameter } from '../../../hooks/route/navigationHooks';
import { AddPatientForm, statusDeceased, statusInactive } from '../AddPatientForm';
import { useForm } from 'antd/lib/form/Form';
import { useOffline } from '../../../util/offline/offlineUtil';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { flushSync } from 'react-dom';
import { upsertDateFormat } from '../../../constants/formats';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const createPatientUpsert = (formValues: Store, practice: PracticeDto, dateFormat: string): PatientUpsert => {
  return {
    id: ID_FOR_OBJECT_CREATION,
    record: {
      name: formValues.name,
      species_id: formValues.species_id,
      breed_id: formValues.breed_id,
      gender_id: formValues.gender_id,
      color_id: formValues.color_id,
      deceased: formValues.status === statusDeceased,
      inactive: formValues.status === statusInactive,
      created_practice_id: practice.id,
      dob: formValues.dateOfBirth?.format(dateFormat),
    },
    ownership: formValues.ownership,
  };
};

export const createPatientUpsertDto = (formValues: Store, practice: PracticeDto, dateFormat: string): PatientUpsert => {
  return {
    id: ID_FOR_OBJECT_CREATION,
    record: {
      name: formValues.name,
      species_id: formValues.species_id,
      breed_id: formValues.breed_id,
      gender_id: formValues.gender_id,
      color_id: formValues.color_id,
      deceased: formValues.status === statusDeceased,
      inactive: formValues.status === statusInactive,
      created_practice_id: practice.id,
      dob: formValues.dateOfBirth?.format(dateFormat),
    },
    ownership: formValues.ownership,
  };
};

export interface AddPatientProps extends ComponentWithPracticeDtoProps {
  onBack?: () => void;
  onSuccess?: (patient: Patient) => void;
  isModal?: boolean;
  functionRef?: React.MutableRefObject<(() => void) | undefined>;
}

export const AddPatient: React.FC<AddPatientProps> = ({ practice, onBack, onSuccess, isModal, functionRef }) => {
  const initialFormValues = { status: 'active' };
  const {
    state: { organization },
  } = useOrganizationContext();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const [insertPatient] = useInsertPatient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);

  const { navigateBack, navigateTo } = useNavigationToRoute();
  const { enabledAndOffline } = useOffline();
  const offlineInsert = usePatientOfflineInsert();

  const [form] = useForm();

  const onFinish = useCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    setIsSaving(true);
    const patientUpsert = createPatientUpsert(values, practice, upsertDateFormat);
    if (!enabledAndOffline) {
      try {
        const { data } = await insertPatient({
          variables: {
            patient: patientUpsert,
            organizationId: practice.organization_id,
          },
        });

        if (data?.upsertPatient) {
          flushSync(() => {
            setUnsavedData(false);
          });
          setIsSaving(false);
          showSuccessMessage(translations.addPatientPage.getSuccessMessage(data.upsertPatient.number || ''));
          if (onSuccess) {
            onSuccess(data.upsertPatient);
          } else {
            navigateTo(routes.viewPatient, withPatientIdParameter(data.upsertPatient.id));
          }
        }
      } catch (e) {
        setIsSaving(false);
        showErrorMessage((e as Error).message || translations.shared.saveErrorMessage);
      }
    } else {
      const patient = await offlineInsert?.(patientUpsert);
      if (!patient) {
        showErrorMessage(translations.shared.saveErrorMessage);
      } else {
        flushSync(() => {
          setUnsavedData(false);
        });
        setIsSaving(false);
        showSuccessMessage(translations.addPatientPage.getSuccessMessage(patient.name || ''));
        if (onSuccess) {
          onSuccess(patient);
        } else {
          navigateTo(routes.viewPatient, withPatientIdParameter(patient.id));
        }
      }
    }
  }, [form, insertPatient, navigateTo, onSuccess, practice, enabledAndOffline, offlineInsert]);

  useEffect(() => {
    if (functionRef) {
      functionRef.current = onFinish;
    }
    return () => {
      if (functionRef) {
        functionRef.current = undefined;
      }
    };
  }, [onFinish, functionRef]);

  if (!organization) {
    return <p>{translations.shared.missingOrganization}</p>;
  }

  const handleValueChange = (_: Store, allValues: Store) => {
    setUnsavedData(!isMatchIgnoringEmptyStrings(allValues, initialFormValues));
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={unsavedData}
      warningMessage={translations.shared.getCreationNavigationWarning(translations.addPatientPage.createdEntity)}
    >
      <StyledPageHeaderWithMargin onBack={onBack ?? navigateBack} title={translations.addPatientPage.title} />
      <AddPatientForm
        form={form}
        handleValueChange={handleValueChange}
        initialValues={initialFormValues}
        dateFormat={dateFormat}
      />
      {!isModal && (
        <WidthContainer width={100}>
          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button type='primary' htmlType='submit' disabled={isSaving} onClick={onFinish}>
              {translations.addPatientPage.buttons.submit}
            </Button>
          </Form.Item>
        </WidthContainer>
      )}
    </SaveSpinnerAndNavigationWarning>
  );
};
