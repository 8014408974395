import { LabResultsProperty, labResultsPropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { LabResultRecord } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStatusFilters,
  getStatusTag,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { getResultStatusText, labRequestStatusConfig } from '../../../constants/referenceData/labReferenceData';

export const basicLabManagementColumns: Record<LabResultsProperty, CustomColumnType<LabResultRecord>> = {
  request_date: {
    title: translations.labManagementPage.columns.request_date,
    key: labResultsPropertyNames.request_date,
    dataIndex: labResultsPropertyNames.request_date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.request_date
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.request_date),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.request_date),
  },
  contact_name: {
    title: translations.labManagementPage.columns.contact_name,
    key: labResultsPropertyNames.contact_name,
    dataIndex: labResultsPropertyNames.contact_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.contact_name
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.contact_name),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.contact_name),
  },
  patient_name: {
    title: translations.labManagementPage.columns.patient_name,
    key: labResultsPropertyNames.patient_name,
    dataIndex: labResultsPropertyNames.patient_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.patient_name
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.patient_name),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.patient_name),
  },
  lab_device_name: {
    title: translations.labManagementPage.columns.lab_device_name,
    key: labResultsPropertyNames.lab_device_name,
    dataIndex: labResultsPropertyNames.lab_device_name,
    ...generalFilteringAndSortingSettings,
    onFilter: (value, record) => record.lab_device_name === value,
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.lab_device_name),
  },
  service_list: {
    title: translations.labManagementPage.columns.service_list,
    key: labResultsPropertyNames.service_list,
    dataIndex: labResultsPropertyNames.service_list,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.service_list
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.service_list),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.service_list),
  },
  lab_created_accession_id: {
    title: translations.labManagementPage.columns.lab_created_accession_id,
    key: labResultsPropertyNames.lab_created_accession_id,
    dataIndex: labResultsPropertyNames.lab_created_accession_id,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.lab_created_accession_id
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.lab_created_accession_id),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.lab_created_accession_id),
  },
  pims_created_accession_id: {
    title: translations.labManagementPage.columns.pims_created_accession_id,
    key: labResultsPropertyNames.pims_created_accession_id,
    dataIndex: labResultsPropertyNames.pims_created_accession_id,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.pims_created_accession_id
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.pims_created_accession_id),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.pims_created_accession_id),
  },
  lab_created_order_number: {
    title: translations.labManagementPage.columns.lab_created_order_number,
    key: labResultsPropertyNames.lab_created_order_number,
    dataIndex: labResultsPropertyNames.lab_created_order_number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.lab_created_order_number
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.lab_created_order_number),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.lab_created_order_number),
  },
  pims_created_order_number: {
    title: translations.labManagementPage.columns.pims_created_order_number,
    key: labResultsPropertyNames.pims_created_order_number,
    dataIndex: labResultsPropertyNames.pims_created_order_number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.columns.pims_created_order_number
    ),
    onFilter: getOnFilterFunctionFor(labResultsPropertyNames.pims_created_order_number),
    sorter: getStringCompareFunctionFor(labResultsPropertyNames.pims_created_order_number),
  },
  status_id: {
    title: translations.labManagementPage.columns.status_id,
    key: labResultsPropertyNames.status_id,
    render: (result: LabResultRecord) => getStatusTag(result.status_id, labRequestStatusConfig),
    ...generalFilteringAndSortingSettings,
    filters: getStatusFilters(labRequestStatusConfig),
    onFilter: (value, record) => record.status_id.toString() === value,
    sorter: (a: LabResultRecord, b: LabResultRecord) => getResultStatusText(a).localeCompare(getResultStatusText(b)),
  },
};
