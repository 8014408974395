import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import { Store } from 'antd/lib/form/interface';
import {
  usePatientOfflineUpdate,
  useUpdatePatientRelevantForDoctorOfficeCard,
} from '../../../../../hooks/ajax/patients/patientHooks';
import {
  SaveAndResetButton,
  SaveAndResetButtonPlaceholder,
} from '../../../../../components/SaveAndResetButton/SaveAndResetButton';
import { TOTAL_NUMBER_OF_COLUMNS } from '../../../../../constants/layout';
import { isMatch } from '../../../../../util/objectComparisons';
import { getRequiredRule } from '../../../../../util/forms';
import {
  Patient,
  PatientBreedDto,
  PatientColorDto,
  PatientGenderDto,
  PatientReferenceDataDto,
  PatientSpeciesDto,
  PatientUpsert,
} from '../../../../../graph/types';
import { translations } from '../../../../../constants/translations';
import { DeceasedDatePickerFormItem } from '../DeceasedDatePickerFormItem/DeceasedDatePickerFormItem';
import { PatientRefSelection } from '../../../../../components/PatientRefSelection/PatientRefSelection';
import { useLocation } from 'react-router-dom';
import { BillTo } from '../../../../../components/BillTo/BillTo';
import { SaveInvoiceProps } from '../../../../Invoices/AddInvoice/AddInvoiceModal';
import { noop } from 'lodash';
import { getDefaultRelationship } from '../../PatientOwnership/ownershipUtils';
import { ownerRelationTypeId } from '../../../../../constants/referenceData/patientReferenceData';
import { mapRefData } from '../../../../../util/refDataUtil';
import { useOffline } from '../../../../../util/offline/offlineUtil';
import { ViewSubscriptionContext } from '../../../../../components/ViewSubscription/store/state';
import ViewSubscriptionActions from '../../../../../components/ViewSubscription/store/actions';
import { Loading } from '../../../../../components/Loading/Loading';
import { MaxLengthFormItem } from '../../../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { useUserLocaleData } from '../../../../../hooks/useUserLocale';
import { NoBottomMarginFormItem } from '../../../../../globalStyles.style';
import styled from 'styled-components';
import { upsertDateFormat } from '../../../../../constants/formats';
import { useGetConnectionId } from '../../../../../hooks/authHooks';
import { showSuccessMessage } from '../../../../../components/Notification/notificationUtil';

const labelWidth = 8;
const halfWidth = TOTAL_NUMBER_OF_COLUMNS / 2;

const generalLayout = {
  labelCol: { span: labelWidth },
  wrapperCol: { span: TOTAL_NUMBER_OF_COLUMNS - labelWidth - 1 },
};
const spacing = {
  gutter: 0,
};
const MetadataSpan = styled.span`
  size: 10px;
`;

interface ViewPatientFormProps extends PropsWithChildren<unknown> {
  patient: Patient;
  renderKey: number | undefined;
  patientRef: PatientReferenceDataDto;
  setIsSaving: (isSaving: boolean) => void;
  setHasChanged: (hasChanged: boolean) => void;
  hasChanged: boolean;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

function generatePatientUpsert(values: Store, patient: Patient, billToId: string, dateFormat: string): PatientUpsert {
  return {
    id: patient?.id,
    defaultBillToRecord: {
      default_bill_to_id: billToId,
    },
    record: {
      ...values,
      created_practice_id: patient?.created_practice_id,
      deceased: !!values?.deceased,
      deceased_date: values.deceased_date?.format(dateFormat),
      dob: values.dob?.format(dateFormat),
      inactive: !!values?.inactive,
      name: values?.name,
      species_id: values?.species_id,
    },
  };
}

const checkVoidAndPush = (
  record: { id: string; name: string; species_id?: string },
  records: (PatientBreedDto | PatientColorDto | PatientGenderDto | PatientSpeciesDto)[] = []
) => {
  if (!record.id || mapRefData(record.id, records)) {
    return records;
  }

  return [
    ...records,
    {
      ...record,
      name: translations.shared.getVoidRecord(record.name),
      sort_order: 0,
    },
  ];
};

export const EditPatientForm: React.FC<ViewPatientFormProps> = ({
  patient,
  renderKey,
  patientRef,
  setIsSaving,
  setHasChanged,
  hasChanged,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { dispatch: dispatchViewPatient } = useContext(ViewSubscriptionContext);
  const connectionId = useGetConnectionId();
  const [updatePatient] = useUpdatePatientRelevantForDoctorOfficeCard();
  const [deceasedDateVisibilityInitialized, setDeceasedDateVisibilityInitialized] = useState(false);
  const [deceasedDateVisible, setDeceasedDateVisible] = useState(false);
  const [billToReset, setBillToReset] = useState<number>(0);
  const [saveProps, setSaveProps] = useState<SaveInvoiceProps>();
  const { canUseCollection } = useOffline('patient');
  const offlineUpdate = usePatientOfflineUpdate(patient.id);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const [age, setAge] = useState('');

  useEffect(() => {
    if (!hasChanged) {
      form.resetFields();
    }
  }, [renderKey, form, hasChanged]);

  useEffect(() => {
    handleDobChange(patient?.dob ? patient?.dob : '');
  }, [patient]);

  const patientRefDataWithVoid = {
    ...patientRef,
    species: checkVoidAndPush(
      { id: patient?.species_id || '', name: patient?.species_name || '' },
      patientRef.species
    ) as PatientSpeciesDto[],
    breed: checkVoidAndPush(
      { id: patient?.breed_id || '', name: patient?.breed_name || '', species_id: patient?.species_id || '' },
      patientRef.breed
    ) as PatientBreedDto[],
    gender: checkVoidAndPush(
      { id: patient?.gender_id || '', name: patient?.gender_name || '', species_id: patient?.species_id || '' },
      patientRef.gender
    ) as PatientGenderDto[],
    color: checkVoidAndPush(
      { id: patient?.color_id || '', name: patient?.color_name || '', species_id: patient?.species_id || '' },
      patientRef.color
    ) as PatientColorDto[],
  };

  const initialFormValues = {
    name: patient?.name,
    name_2: patient?.name_2,
    name_3: patient?.name_3,
    species_id: patient?.species_id,
    breed_id: patient?.breed_id,
    gender_id: patient?.gender_id,
    color_id: patient?.color_id,
    dob: patient?.dob ? dayjs(patient?.dob, upsertDateFormat) : null,
    deceased: patient?.deceased != null ? patient?.deceased : false,
    deceased_date: patient?.deceased_date ? dayjs(patient?.deceased_date, upsertDateFormat) : null,
    inactive: !!patient?.inactive,
    billTo: getDefaultRelationship(patient?.default_bill_to_id ?? ownerRelationTypeId, patient?.related_current ?? []),
  };

  const onReset = useCallback(() => {
    form.resetFields();
    setHasChanged(false);
    setDeceasedDateVisible(initialFormValues.deceased);
    // Trigger BillTo details update by continously changing the trigger value
    setBillToReset((billToReset) => billToReset + 1);
  }, [form, initialFormValues.deceased, setHasChanged]);

  useEffect(() => {
    onReset();
  }, [location, onReset]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      onReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, onReset, setShouldResetOnTabChange]);

  if (!deceasedDateVisibilityInitialized) {
    setDeceasedDateVisible(initialFormValues.deceased);
    setDeceasedDateVisibilityInitialized(true);
  }

  const deceasedColumnName = 'deceased';

  function handleValueChange(changedValues: Store, allValues: Store) {
    if (changedValues[deceasedColumnName] != null) {
      setDeceasedDateVisible(changedValues[deceasedColumnName]);
    }
    const hasChanged = !isMatch(initialFormValues, allValues);
    setHasChanged(hasChanged);
    dispatchViewPatient(ViewSubscriptionActions.setEditing(true));
  }

  const handleFinish = async (values: Store) => {
    if (!patient) {
      return;
    }
    setIsSaving(true);
    dispatchViewPatient(ViewSubscriptionActions.setEditing(false));
    delete values.billTo;
    // Owner is not in RelatedContacts, so use that if id not found
    const billToId = saveProps?.billToId ?? ownerRelationTypeId;
    const patientUpsert = generatePatientUpsert(values, patient, billToId, upsertDateFormat);
    if (!canUseCollection) {
      await updatePatient({
        variables: {
          organizationId: patient?.organization_id,
          patient: { ...patientUpsert, connection_id: connectionId },
        },
        onCompleted: () => {
          setHasChanged(false);
          showSuccessMessage(translations.shared.saveSuccessMessage);
        },
        onError: () => dispatchViewPatient(ViewSubscriptionActions.setEditing(true)),
      });
    } else {
      await offlineUpdate?.(patientUpsert);
      setHasChanged(false);
    }
    setIsSaving(false);
  };

  const handleDobChange = (dateString: string) => {
    if (dateString.length > 0) {
      const today = new Date();
      const birthDate = new Date(dateString);

      const m = today.getUTCMonth() - birthDate.getUTCMonth();
      const y = today.getUTCFullYear() - birthDate.getUTCFullYear();

      let ageYear = y;
      let ageMonth = m;

      if (m < 0) {
        ageYear = y - 1;
        ageMonth = 12 + m;
      }

      setAge(`${ageYear}y ${ageMonth}m`);
    } else {
      setAge('');
    }
  };

  if (!patient) {
    return <Loading />;
  }

  return (
    <Form
      {...generalLayout}
      key={renderKey}
      form={form}
      initialValues={initialFormValues}
      onValuesChange={handleValueChange}
      onFinish={handleFinish}
      name={`patient-form-${patient?.id}`}
      autoComplete='off'
    >
      <Row {...spacing}>
        <Col span={halfWidth}>
          <MaxLengthFormItem
            label={translations.patientPage.details.name}
            name='name'
            rules={[getRequiredRule(translations.patientPage.details.name)]}
            maxLength={50}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <MaxLengthFormItem maxLength={50} label={translations.patientPage.details.name_2} name='name_2'>
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <MaxLengthFormItem maxLength={50} label={translations.patientPage.details.name_3} name='name_3'>
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <NoBottomMarginFormItem label={translations.patientPage.details.dateOfBirth} name='dob'>
            <DatePicker
              format={dateFormat}
              disabledDate={(date) => date.isAfter(dayjs())}
              onChange={(_, dateString) => handleDobChange(dateString)}
            />
          </NoBottomMarginFormItem>
          <Col offset={8}>
            <div style={{ marginBottom: age && age.length > 0 ? '5px' : '24px', marginTop: '5px' }}>
              <MetadataSpan>{age}</MetadataSpan>
            </div>
          </Col>
          {deceasedDateVisible ? <DeceasedDatePickerFormItem getDateOfBirth={() => form.getFieldValue('dob')} /> : null}
          <BillTo
            selectedPatient={patient}
            setSaveProps={setSaveProps}
            setChangesMade={noop}
            fieldName={'billTo'}
            reset={billToReset}
          />
        </Col>

        <Col span={halfWidth}>
          <PatientRefSelection patientRef={patientRefDataWithVoid} form={form} />
          <Row>
            <Col span={labelWidth} />
            <Col span={TOTAL_NUMBER_OF_COLUMNS - labelWidth}>
              <Space style={{ marginLeft: '-66.32px' }}>
                <Form.Item
                  labelCol={{ span: 15 }}
                  wrapperCol={{ span: 9 }}
                  label={translations.patientPage.details.inactive}
                  name='inactive'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  style={{ marginLeft: '10px' }}
                  labelCol={{ span: 15 }}
                  wrapperCol={{ span: 9 }}
                  label={translations.patientPage.details.deceased}
                  name={deceasedColumnName}
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      {hasChanged ? (
        <Row {...spacing}>
          <Col span={halfWidth}>
            <SaveAndResetButton onReset={onReset} offset={labelWidth} />
          </Col>
        </Row>
      ) : (
        <SaveAndResetButtonPlaceholder />
      )}
    </Form>
  );
};
