import React from 'react';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';
import BaseCurrencyFormatter from './BaseCurrencyFormatter/BaseCurrencyFormatter';

interface Props {
  total: number | string;
  chartAxis?: boolean;
  isPriceValue?: boolean;
}

export default function CurrencyFormatter({ total, chartAxis, isPriceValue }: Props) {
  return (
    <BaseCurrencyFormatter
      total={total}
      chartAxis={chartAxis}
      isPriceValue={isPriceValue}
      currencySettings={useCurrencyAndDateSetting()}
    />
  );
}
