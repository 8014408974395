import { Radio, Space, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { translations } from '../../../constants/translations';
import { BatchGroupOptions } from '../../../graph/types';

export type BatchOptions = Required<BatchGroupOptions>;

export const defaultBatchGroupOptions: BatchOptions = {
  closeInvoices: true,
  alwaysNewInvoice: true,
  allowMultiPatientInvoices: false,
};

interface RapidBillingOptionsProps {
  batchGroupOptions: BatchOptions;
  hideCompleteInvoices?: boolean;
  setBatchGroupOptions: (options: BatchOptions) => void;
}
enum InvoiceOption {
  NewInvoice,
  AddToExistingInvoice,
}

export const RapidBillingOptions: React.FC<RapidBillingOptionsProps> = ({
  batchGroupOptions,
  hideCompleteInvoices,
  setBatchGroupOptions,
}) => {
  const onCloseInvoicesChange = (value: boolean) => {
    setBatchGroupOptions({ ...batchGroupOptions, closeInvoices: value });
  };

  const onInvoiceRadioOptionChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    if (InvoiceOption.NewInvoice === value) {
      setBatchGroupOptions({ ...batchGroupOptions, alwaysNewInvoice: true });
    } else {
      setBatchGroupOptions({ ...batchGroupOptions, alwaysNewInvoice: false });
    }
  };

  return (
    <Space style={{ height: '100%', marginTop: 10 }}>
      <Radio.Group
        onChange={onInvoiceRadioOptionChange}
        value={batchGroupOptions.alwaysNewInvoice ? InvoiceOption.NewInvoice : InvoiceOption.AddToExistingInvoice}
      >
        <Radio value={InvoiceOption.NewInvoice}>{translations.rapidBilling.options.newInvoices}</Radio>
        <Radio value={InvoiceOption.AddToExistingInvoice}>{translations.rapidBilling.options.addToInvoices}</Radio>
      </Radio.Group>
      {!hideCompleteInvoices && (
        <Switch
          checkedChildren={translations.rapidBilling.options.completeInvoices}
          unCheckedChildren={translations.rapidBilling.options.completeInvoices}
          checked={batchGroupOptions.closeInvoices || false}
          onChange={onCloseInvoicesChange}
        />
      )}
    </Space>
  );
};
