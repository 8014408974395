import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetInvoiceReport } from '../../graph/queries/invoices';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { Invoice, QueryGetInvoiceReportArgs } from '../../graph/types';
import { joinPatientOrContactNameAndNumber } from '../../util/displaying';
import { translations } from '../../constants/translations';
import { Attachment } from '../../components/EmailModal/EmailModal';
import { openTabWithPopupDetection } from '../../util/popupUtil';

export const getEmailOptions = (contacts?: { name: string; email?: string | null }[]) => {
  return (
    contacts
      ?.map((contact) => ({ value: contact.email ?? '', label: `${contact.name} - ${contact.email}` }))
      .filter((option) => option.value) ?? []
  );
};

export const getInvoiceContactOptionsAndPrimaryContact = (invoice?: Invoice) => {
  const contactOptions =
    invoice?.contact?.map((invoiceContact) => ({
      value: invoiceContact.contact_id,
      label: joinPatientOrContactNameAndNumber({
        name: invoiceContact.contact_name,
        number: invoiceContact.contact_number,
      }),
    })) ?? [];
  const primaryContactId = invoice?.contact?.find((invoiceContact) => invoiceContact.primary)?.contact_id;
  const invoiceContactEmailOptions = getEmailOptions(
    invoice?.contact?.map((invoiceContact) => ({
      email: invoiceContact.contact_email,
      name: invoiceContact.contact_name,
    }))
  );

  return { contactOptions, primaryContactId, invoiceContactEmailOptions };
};

export const getInvoiceReportAsEmailAttachment = async (variables: QueryGetInvoiceReportArgs) => {
  let attachment: Attachment | undefined = undefined;
  await getInvoiceReportUrl(variables, (url) => {
    attachment = {
      file_pointer: url,
      file_name: variables.request.fileName ?? '',
    };
  });
  return attachment;
};

export const printInvoiceReport = async (variables: QueryGetInvoiceReportArgs) => {
  await getInvoiceReportUrl(variables, (url) =>
    openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport)
  );
};

const getInvoiceReportUrl = async (variables: QueryGetInvoiceReportArgs, onSuccess: (url: string) => void) => {
  try {
    const { data } = await AppSyncService.client.query({
      query: GetInvoiceReport,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.getInvoiceReport?.pointer) {
      const pointer = data.getInvoiceReport.pointer;

      onSuccess(pointer);
    } else {
      showErrorMessage(translations.invoiceReport.errorGeneratingReport);
    }
  } catch (err) {
    showErrorMessage(err.message ? err.message : translations.invoiceReport.errorGeneratingReport);
  }
};
