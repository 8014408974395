import Mark from 'mark.js';
import { useContext, useEffect } from 'react';
import { selectSearchValue } from '../components/Records/store/selectors';
import { RecordsContext } from '../components/Records/store/state';

export const useHighlightedText = (dependencies: any[], classes: string) => {
  const { state } = useContext(RecordsContext);
  const searchValue = selectSearchValue(state);
  useEffect(() => {
    const markInstance = new Mark(document.querySelectorAll(classes));
    markInstance.unmark({
      done: () => {
        markInstance.mark(searchValue ?? '', {
          className: 'highlightedText',
          element: 'strong',
        });
      },
    });
  }, [searchValue, classes, dependencies]);
};
