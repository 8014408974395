import React, { PropsWithChildren } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { translations } from '../../../../../constants/translations';
import { DatePicker, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { getRequiredRule } from '../../../../../util/forms';
import { useUserLocaleData } from '../../../../../hooks/useUserLocale';

interface DeceasedDatePickerProps extends PropsWithChildren<unknown> {
  getDateOfBirth: () => Dayjs | undefined;
  deceaseDateFieldName?: string;
  autofocus?: boolean;
}

export const DeceasedDatePickerFormItem: React.FC<DeceasedDatePickerProps> = ({
  getDateOfBirth,
  deceaseDateFieldName = 'deceased_date',
  autofocus,
}: DeceasedDatePickerProps) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const isDeceaseDateInvalid = (date: Dayjs): boolean => {
    // cannot select days before birth date
    const dateOfBirth = getDateOfBirth();
    return (dateOfBirth && date && date < dateOfBirth?.startOf('day')) || date?.isAfter(dayjs());
  };

  function getDeceaseDateValidator(): Rule {
    return {
      validator(_rule, value) {
        if (!isDeceaseDateInvalid(value)) {
          return Promise.resolve();
        }
        return Promise.reject(translations.patientPage.details.deceasedDateBeforeDateOfBirth);
      },
    };
  }

  return (
    <Form.Item
      label={translations.patientPage.details.deceasedDate}
      name={deceaseDateFieldName}
      rules={[getDeceaseDateValidator(), getRequiredRule(translations.patientPage.details.deceasedDate)]}
      data-testid={`${deceaseDateFieldName}TestId`}
    >
      <DatePicker format={dateFormat} disabledDate={isDeceaseDateInvalid} autoFocus={autofocus} />
    </Form.Item>
  );
};
