import { translations } from '../../constants/translations';
import React, { useState } from 'react';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { Contact, ElectronicPaymentResult, Ledger, LedgerReversal } from '../../graph/types';
import { DatePicker, Form, Input, InputNumber } from 'antd';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';
import { w12 } from '../../globalStyles.style';
import { Store } from 'antd/lib/form/interface';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useReverseLedger } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useForm } from 'antd/lib/form/Form';
import './ReversePaymentModal.css';
import {
  useUpsertCardConnectTransaction,
  useUpsertStripeTransaction,
} from '../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import {
  createLedgerUpsertForPaymentReversal,
  getRefundToDisplay,
  getTransactionRecordUpsertForReversal,
  getTransactionVariablesForReversal,
} from './reversePaymentUtil';
import { getRequiredRule } from '../../util/forms';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { upsertDateFormat } from '../../constants/formats';
import { useCountryBasedCurrencyCode } from '../../hooks/currencyAndDateHooks';
import { getIsStripeProcessor } from '../../util/stripeConnectUtils';
import { useOrganizationContext } from '../../contexts/organization/state';

interface ReversePaymentModalProps {
  onClose: () => void;
  onSuccess: () => void;
  contact: Contact;
  ledgerEntry: Ledger;
}

export const ReversePaymentModal: React.FC<ReversePaymentModalProps> = ({
  onClose,
  onSuccess,
  contact,
  ledgerEntry,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const currencyCode = useCountryBasedCurrencyCode();
  const {
    state: { organization },
  } = useOrganizationContext();
  const isElectronicPayment = !isNil(ledgerEntry.payment_electronic_id);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);

  const [form] = useForm();
  const [reverseLedger] = useMutationWithMessages(useReverseLedger);
  const [upsertCardConnectTransaction] = useUpsertCardConnectTransaction(organizationId, contact.id);
  const [upsertStripeTransaction] = useUpsertStripeTransaction(organizationId, contact.id);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const maxPossibleReversal = Number(ledgerEntry.total) - Number(ledgerEntry.total_reversed);
  const initialValues = {
    date: dayjs(),
    amount: maxPossibleReversal,
  };

  const isStripeProcessor = getIsStripeProcessor(organization);
  const upsertCardTransaction = isStripeProcessor ? upsertStripeTransaction : upsertCardConnectTransaction;

  const reverseElectronicPayment = async (values: Store) => {
    const transactionRecordUpsert = getTransactionRecordUpsertForReversal(
      values,
      ledgerEntry,
      upsertDateFormat,
      currencyCode,
      isStripeProcessor
    );

    const transactionVariables = getTransactionVariablesForReversal(
      isStripeProcessor,
      organizationId,
      practiceId,
      transactionRecordUpsert
    );

    try {
      const { data } = await upsertCardTransaction({
        variables: transactionVariables,
      });
      const result = isStripeProcessor
        ? data?.upsertStripeTransaction
        : (data?.upsertCardConnectTransaction as ElectronicPaymentResult);

      if (result?.success) {
        showSuccessMessage(translations.reversePaymentModal.successMessage);
        onSuccess();
        onClose();
      } else {
        let errorMessage = result?.message ?? translations.paymentModal.electronicTransactionUnknownError;
        if (result?.message === 'Txn not settled') {
          errorMessage = translations.paymentModal.electronicTransactionNotSettledError;
        }
        showErrorMessage(errorMessage, 9);
        setIsSaving(false);
      }
    } catch (err) {
      showErrorMessage(err.message ? err.message : err);
      setIsSaving(false);
    }
  };

  const reverseNonElectronicPayment = async (values: Store) => {
    const ledgerReversal: LedgerReversal = createLedgerUpsertForPaymentReversal(values, ledgerEntry, upsertDateFormat);
    await reverseLedger({
      options: {
        variables: {
          organizationId,
          ledger: ledgerReversal,
        },
      },
      successMessage: translations.reversePaymentModal.successMessage,
      onSuccess: () => {
        setUnsavedData(false);
        onSuccess();
        onClose();
      },
    });
    setIsSaving(false);
  };

  const handleFinish = async (values: Store) => {
    setIsSaving(true);
    if (isElectronicPayment) {
      await reverseElectronicPayment(values);
    } else {
      await reverseNonElectronicPayment(values);
    }
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    const sameAsInitialValues =
      dayjs(initialValues.date).isSame(allValues.date, 'day') &&
      allValues.amount === initialValues.amount &&
      !allValues.note;

    setUnsavedData(!sameAsInitialValues);
  };

  const refundDisplay = getRefundToDisplay(ledgerEntry);

  return (
    <ModalWithCloseConfirm
      title={translations.reversePaymentModal.title}
      onCancel={onClose}
      open
      isSaving={isSaving}
      changesMade={unsavedData}
      onOk={() => form.submit()}
      popConfirmSaveMessage={isElectronicPayment ? translations.reversePaymentModal.savePopConfirmMessage : undefined}
    >
      <Form
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={handleValueChange}
        onFinish={handleFinish}
        form={form}
        className={'ReversePaymentModal__Form'}
        autoComplete='off'
      >
        <Form.Item label={translations.reversePaymentModal.fields.originalAmount}>
          <InputNumber disabled value={Number(ledgerEntry.total)} style={w12} />
        </Form.Item>
        <Form.Item
          label={translations.reversePaymentModal.fields.availableForReversal}
          hidden={Number(ledgerEntry.total_reversed) === 0}
        >
          <InputNumber disabled value={maxPossibleReversal} precision={2} style={w12} />
        </Form.Item>
        {refundDisplay && (
          <Form.Item label={translations.reversePaymentModal.fields.refundTo} hidden={!isElectronicPayment}>
            <Input disabled value={refundDisplay} />
          </Form.Item>
        )}
        <Form.Item name={'date'} label={translations.reversePaymentModal.fields.date}>
          <DatePicker disabled={isElectronicPayment} style={w12} format={dateFormat} />
        </Form.Item>
        <Form.Item
          name={'amount'}
          label={translations.reversePaymentModal.fields.amount}
          rules={[getRequiredRule(translations.reversePaymentModal.fields.amount)]}
        >
          <InputNumber precision={2} min={0} max={maxPossibleReversal} style={w12} />
        </Form.Item>
        <Form.Item name={'note'} label={translations.reversePaymentModal.fields.note}>
          <TextArea autoSize={{ maxRows: 8 }} />
        </Form.Item>
      </Form>
    </ModalWithCloseConfirm>
  );
};
