import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { useState } from 'react';
import { translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import styled from 'styled-components';
import { Loading } from '../../components/Loading/Loading';
import { ConnectPayouts } from './ConnectPayouts';
import { ConnectPayments } from './ConnectPayments';

export const EmbeddedContainer = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

type TabTypes = 'ConnectPayments' | 'ConnectPayouts';

export const ReportPayments = () => {
  const { TabPane } = Tabs;
  const organizationId = useGetOrganizationIdFromRoute();
  const { organization, organizationLoading } = useGetOrganization(organizationId);
  const [activeTab, setActiveTab] = useState<TabTypes>('ConnectPayments');

  if (organizationLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title={translations.reportPaymentsPage.title} />
      <Tabs activeKey={activeTab} type='card' onTabClick={(key) => setActiveTab(key as TabTypes)}>
        <TabPane tab={translations.reportPaymentsPage.tabs.all.title} key={'ConnectPayments'}>
          <ConnectPayments organization={organization}></ConnectPayments>
        </TabPane>
        <TabPane tab={translations.reportPaymentsPage.tabs.payouts.title} key={'ConnectPayouts'}>
          <ConnectPayouts organization={organization}></ConnectPayouts>
        </TabPane>
      </Tabs>
    </>
  );
};
