import React from 'react';
import { Button, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { translations } from '../../../constants/translations';
import { FilterDropdownContainer, FilterDropdownActions, StyledInput } from './RefDataTable.styles';

const FilterDropdown =
  (inputEl: React.RefObject<InputRef>, filterInputPlaceholder: string) =>
  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) =>
    (
      <FilterDropdownContainer>
        <div>
          <StyledInput
            ref={inputEl}
            placeholder={filterInputPlaceholder}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => confirm()}
          />
        </div>
        <FilterDropdownActions>
          <Button type='link' onClick={clearFilters} size='small'>
            {translations.shared.clearButtonText}
          </Button>
          <Button type='primary' onClick={() => confirm()} size='small'>
            {translations.shared.okButtonText}
          </Button>
        </FilterDropdownActions>
      </FilterDropdownContainer>
    );

export default FilterDropdown;
