import { Query, QueryGetOrganizationSubscriptionInvoiceReportArgs } from '../../graph/types';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetOrganizationSubscriptionInvoiceReport } from '../../graph/queries/organizations';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { openTabWithPopupDetection } from '../../util/popupUtil';

export const printOrganizationSubscriptionInvoiceReport = async (
  variables: QueryGetOrganizationSubscriptionInvoiceReportArgs
) => {
  await getOrganizationSubscriptionInvoiceReportUrl(variables, (url) => {
    openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport);
  });
};

export const getOrganizationSubscriptionInvoiceReportUrl = async (
  variables: QueryGetOrganizationSubscriptionInvoiceReportArgs,
  onSuccess: (url: string) => void
) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getOrganizationSubscriptionInvoiceReport'>>({
      query: GetOrganizationSubscriptionInvoiceReport,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.getOrganizationSubscriptionInvoiceReport?.pointer) {
      const pointer = data.getOrganizationSubscriptionInvoiceReport.pointer;

      onSuccess(pointer);
    } else {
      showErrorMessage(translations.billingHistoryPage.report.errorMsg);
    }
  } catch (e) {
    showErrorMessage(e.message ? e.message : translations.billingHistoryPage.report.errorMsg);
  }
};
