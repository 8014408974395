import { useCallback } from 'react';
import {
  GetQuickBooksPaymentTypes,
  GetQuickbooksAccounts,
  GetQuickBooksTaxRates,
  UpsertQuickBooksAuth,
  UpsertQuickBooksCallbackCode,
  UpsertSendJournalQuickbooks,
  UpsertCreateFinancialCloseJournal,
  DeleteJournalQuickbooks,
} from '../../../graph/queries/quickBooks';
import {
  FinancialCloseJournalEntryUpsert,
  Journal,
  Query,
  QuickbooksAuthResult,
  QuickbooksCallbackCodeResult,
  QuickbooksDeleteJournalRequest,
  QuickbooksDeleteJournalResult,
  QuickbooksSendJournalRequest,
  QuickbooksSendJournalResult,
} from '../../../graph/types';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export interface AuthQuickbooksArgs {
  organizationId: string;
}

export interface CallbackCodeQuickbooksArgs {
  organizationId: string;
  queryString: string;
}

export interface CreateFinancialCloseJournalArgs {
  organizationId: string;
  financialClose: FinancialCloseJournalEntryUpsert;
}

export interface SendJournalQuickbooksArgs {
  organizationId: string;
  practiceId: string;
  actionInput: QuickbooksSendJournalRequest;
}

export interface DeleteJournalQuickbooksArgs {
  organizationId: string;
  practiceId: string;
  actionInput: QuickbooksDeleteJournalRequest;
}

export interface GetQuickBooksPaymentTypesArgs {
  organizationId: string;
  practiceId: string;
}

export interface GetQuickBooksTaxRatesArgs {
  organizationId: string;
  practiceId: string;
}

export const useUpsertQuickBooksAuth = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'authQuickbooks', AuthQuickbooksArgs>(UpsertQuickBooksAuth);

  const quickBooksAuth = useCallback(
    async (organizationId: string) => {
      const response = await mutateFunction({
        variables: {
          organizationId,
        },
      });

      return response?.data?.authQuickbooks as QuickbooksAuthResult;
    },
    [mutateFunction]
  );

  return quickBooksAuth;
};

export const useUpsertQuickBooksCallbackCode = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'callbackCodeQuickbooks', CallbackCodeQuickbooksArgs>(
    UpsertQuickBooksCallbackCode
  );

  const quickBooksCallbackCode = useCallback(
    async (organizationId: string, queryString: string) => {
      const response = await mutateFunction({
        variables: {
          organizationId,
          queryString,
        },
      });

      return response?.data?.callbackCodeQuickbooks as QuickbooksCallbackCodeResult;
    },
    [mutateFunction]
  );

  return quickBooksCallbackCode;
};

export const useUpsertCreateFinancialCloseJournal = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'createFinancialCloseJournal', CreateFinancialCloseJournalArgs>(
    UpsertCreateFinancialCloseJournal
  );

  const createFinancialCloseJournal = useCallback(
    async (organizationId: string, financialPeriodId: string) => {
      const response = await mutateFunction({
        variables: {
          organizationId,
          financialClose: { financial_period_id: financialPeriodId },
        },
      });

      return response?.data?.createFinancialCloseJournal as Journal;
    },
    [mutateFunction]
  );

  return createFinancialCloseJournal;
};

export const useUpsertSendJournalQuickbooks = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'sendJournalQuickbooks', SendJournalQuickbooksArgs>(
    UpsertSendJournalQuickbooks
  );

  const sendJournalQuickbooks = useCallback(
    async (organizationId: string, practiceId: string, actionInput: QuickbooksSendJournalRequest) => {
      const response = await mutateFunction({
        variables: {
          organizationId,
          practiceId,
          actionInput,
        },
      });

      return response?.data?.sendJournalQuickbooks as QuickbooksSendJournalResult;
    },
    [mutateFunction]
  );

  return sendJournalQuickbooks;
};

export const useDeleteJournalQuickbooks = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'deleteJournalQuickbooks', DeleteJournalQuickbooksArgs>(
    DeleteJournalQuickbooks
  );

  const deleteJournalQuickbooks = useCallback(
    async (organizationId: string, practiceId: string, actionInput: QuickbooksDeleteJournalRequest) => {
      const response = await mutateFunction({
        variables: {
          organizationId,
          practiceId,
          actionInput,
        },
      });

      return response?.data?.deleteJournalQuickbooks as QuickbooksDeleteJournalResult;
    },
    [mutateFunction]
  );

  return deleteJournalQuickbooks;
};

export const useGetQuickbooksAccounts = (
  organizationId: string,
  skip: boolean,
  practiceId?: string,
  onFailed?: () => void
) => {
  const handleCompleted = useCallback(
    (data?: Pick<Query, 'getQuickbooksAccounts'>) => {
      (!data?.getQuickbooksAccounts?.data || data?.getQuickbooksAccounts?.status === 'failed') && onFailed?.();
    },
    [onFailed]
  );

  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getQuickbooksAccounts'>(GetQuickbooksAccounts, {
    variables: {
      organizationId,
      practiceId,
    },
    fetchPolicy: 'cache-first',
    skip: !practiceId || skip,
    onCompleted: handleCompleted,
  });

  const handleRefetch = useCallback(async () => {
    const response = await refetch();
    handleCompleted(response?.data);
  }, [handleCompleted, refetch]);

  return {
    quickbooksAccounts: data?.getQuickbooksAccounts,
    error,
    accountsLoading: loading,
    refetchQBAccounts: handleRefetch,
  };
};

export const useGetQuickBooksPaymentTypes = (
  organizationId: string,
  skip: boolean,
  practiceId?: string,
  onFailed?: () => void
) => {
  const handleCompleted = (data?: Pick<Query, 'getQuickbooksPaymentTypes'>) => {
    (!data?.getQuickbooksPaymentTypes?.data || data?.getQuickbooksPaymentTypes?.status === 'failed') && onFailed?.();
  };

  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<
    'getQuickbooksPaymentTypes',
    GetQuickBooksPaymentTypesArgs
  >(GetQuickBooksPaymentTypes, {
    variables: {
      organizationId,
      practiceId: practiceId ?? '',
    },
    skip: !practiceId || skip,
    onCompleted: handleCompleted,
  });

  const handleRefetch = async () => {
    const response = await refetch();
    handleCompleted(response?.data);
  };

  return {
    qbPaymentTypes: data?.getQuickbooksPaymentTypes?.data,
    qbPaymentTypesError: error,
    qbPaymentTypesLoading: loading,
    qbPaymentTypesRefetch: handleRefetch,
  };
};

export const useGetQuickBooksTaxRates = (
  organizationId: string,
  skip: boolean,
  practiceId?: string,
  onFailed?: () => void
) => {
  const handleCompleted = (data?: Pick<Query, 'getQuickbooksTaxRates'>) => {
    (!data?.getQuickbooksTaxRates?.data || data?.getQuickbooksTaxRates?.status === 'failed') && onFailed?.();
  };

  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<
    'getQuickbooksTaxRates',
    GetQuickBooksTaxRatesArgs
  >(GetQuickBooksTaxRates, {
    variables: {
      organizationId,
      practiceId: practiceId ?? '',
    },
    skip: !practiceId || skip,
    onCompleted: handleCompleted,
  });

  const handleRefetch = async () => {
    const response = await refetch();
    handleCompleted(response?.data);
  };

  return {
    qbTaxRates: data?.getQuickbooksTaxRates?.data,
    qbTaxRatesError: error,
    qbTaxRatesLoading: loading,
    qbTaxRatesRefetch: handleRefetch,
  };
};
