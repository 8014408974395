import React, { useCallback, useMemo } from 'react';
import { DepositRun, Ledger } from '../../graph/types';
import { Modal } from 'antd';
import { DepositRunStatusId, translations } from '../../constants/translations';
import { useGetLedgerDataForDepositRunDetail } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { Loading } from '../Loading/Loading';
import { isEmpty, groupBy, sumBy } from 'lodash';
import styled from 'styled-components';
import { PriceValue } from '../PriceValue/PriceValue';
import { PaymentTypeNameKey, paymentTypeConfigs } from '../../constants/referenceData/paymentReferenceData';
import { CustomColumnType, TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import { useBasicLedgerColumns } from '../../pages/Contacts/ViewContact/ContactLedger/ledgerColumns';
import { ButtonWithPopconfirm } from '../ButtonWithPopconfirm/ButtonWithPopconfirm';

const SummarySection = styled.div`
  margin-left: 15px;
  padding-bottom: 15px;
`;

const DeleteDetailPopconfirmDescription = styled.div`
  width: 200px;
`;

export interface DepositRunDetailsModalProps {
  depositRun: DepositRun;
  isSaving?: boolean;
  onDelete?: (depositRun: DepositRun, ledgerEntry: Ledger) => void;
  onClose?: () => void;
}

export const DepositRunDetailsModal: React.FC<DepositRunDetailsModalProps> = ({
  depositRun,
  isSaving = false,
  onDelete,
  onClose,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { ledgers, ledgersError, ledgersLoading } = useGetLedgerDataForDepositRunDetail({ depositRun, organizationId });
  const basicLedgerColumns = useBasicLedgerColumns();

  const renderActionCell = useCallback(
    (ledgerEntry: Ledger) => {
      return (
        <ButtonWithPopconfirm
          buttonText={translations.depositRuns.detailsModal.buttons.delete}
          onClickCallback={() => onDelete?.(depositRun, ledgerEntry)}
          popconfirmTitle={
            <DeleteDetailPopconfirmDescription>
              {translations.depositRuns.detailsModal.buttons.deleteDetailText}
            </DeleteDetailPopconfirmDescription>
          }
          popconfirmCancelText={translations.shared.popconfirm.no}
          popconfirmOkText={translations.shared.popconfirm.ok}
          isDisabled={depositRun.status_id !== DepositRunStatusId.Pending}
        />
      );
    },
    [depositRun, onDelete]
  );

  const detailColumns: CustomColumnType<Ledger>[] = useMemo(
    () => [
      {
        ...basicLedgerColumns.financial_date,
        title: translations.depositRuns.detailsModal.columns.date,
        width: 50,
      },
      {
        ...basicLedgerColumns.number,
        title: translations.depositRuns.detailsModal.columns.number,
        width: 50,
      },
      {
        ...basicLedgerColumns.contact_name,
        title: translations.depositRuns.detailsModal.columns.contact,
        width: 50,
        render: (value) => <div style={{ overflowWrap: 'anywhere' }}>{value}</div>,
      },
      {
        ...basicLedgerColumns.payment_type_name_key,
        title: translations.depositRuns.detailsModal.columns.paymentType,
        width: 50,
      },
      {
        ...basicLedgerColumns.total,
        title: translations.depositRuns.detailsModal.columns.total,
        render: ({ total, reversal }: Ledger) => {
          return <PriceValue colorNegative={reversal ? 'red' : 'green'} value={Number(total)} />;
        },
        width: 50,
      },
      {
        title: translations.depositRuns.detailsModal.columns.actions,
        key: 'actions',
        render: (ledgerEntry: Ledger) => renderActionCell(ledgerEntry),
        width: 50,
      },
    ],
    [
      basicLedgerColumns.contact_name,
      basicLedgerColumns.financial_date,
      basicLedgerColumns.number,
      basicLedgerColumns.payment_type_name_key,
      basicLedgerColumns.total,
      renderActionCell,
    ]
  );

  const renderModalDetails = useCallback(() => {
    if (ledgersLoading || isSaving) {
      return (
        <Loading labelText={isSaving ? translations.loadingComponent.saving : translations.loadingComponent.loading} />
      );
    }

    if (ledgersError) {
      return translations.depositRuns.detailsModal.loadLedgersError(ledgersError.message);
    }

    if (isEmpty(ledgers)) {
      return translations.depositRuns.detailsModal.noLedgersText;
    }

    const ledgersTotal = sumBy(ledgers, (l) => Number(l.total));
    const groupedLedgers = groupBy(ledgers, 'payment_type_name_key');

    return (
      <>
        <SummarySection>
          {Object.keys(groupedLedgers).map((paymentTypeNameKey) => (
            <div key={paymentTypeNameKey}>
              {paymentTypeConfigs[paymentTypeNameKey as PaymentTypeNameKey].text}:{' '}
              <PriceValue value={sumBy(groupedLedgers[paymentTypeNameKey], (l) => Number(l.total))} />
            </div>
          ))}
          <div>
            {translations.depositRuns.detailsModal.total}: <PriceValue value={ledgersTotal} />
          </div>
        </SummarySection>
        <TableWithCustomFiltering<Ledger>
          tableKey={TableKey.DepositRunDetails}
          columns={detailColumns}
          dataSource={ledgers || undefined}
          rowKey={'id'}
          style={{ width: '100%' }}
        />
      </>
    );
  }, [detailColumns, isSaving, ledgers, ledgersError, ledgersLoading]);

  return (
    <Modal
      title={translations.depositRuns.detailsModal.title(depositRun.number)}
      open
      onCancel={onClose}
      okButtonProps={{
        hidden: true,
      }}
      width={800}
    >
      {renderModalDetails()}
    </Modal>
  );
};
