import { RxJsonSchema } from 'rxdb';
import { SystemReferenceDataTypes } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaWithUpdated } from '../databaseUtils';

export type RxReferenceData = SystemReferenceDataTypes & RxSchemaWithUpdated & { organization_id: string };

export const referenceDataSchema: RxJsonSchema<RxReferenceData> = {
  version: 1,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.ID,
    ledger_payment_type: Maybe(RxScalars.Array),
    contact_type: Maybe(RxScalars.Array),
    address_type: Maybe(RxScalars.Array),
    country: Maybe(RxScalars.Array),
    prov_state: Maybe(RxScalars.Array),
    invoice_status: Maybe(RxScalars.Array),
    setting: Maybe(RxScalars.Array),
    subscription_type: Maybe(RxScalars.Array),
    ref_currency_defaults: Maybe(RxScalars.Array),
    ref_service_rendered_info_type_data: Maybe(RxScalars.Array),
    updated: RxScalars.String,
    organization_id: RxScalars.String,
  },
  required: ['id'],
  indexes: ['updated'],
};
