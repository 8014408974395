import { Invoice, InvoiceUpsert, ServiceRendered } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaTypeWithUpsert, RxSchemaWithDocument } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';

type InvoiceOfflineFields = Omit<Invoice, 'text'>;
export type RxInvoice = RxSchemaTypeWithUpsert<InvoiceOfflineFields, InvoiceUpsert, 'invoice'> &
  RxSchemaWithDocument & { offline_services?: ServiceRendered[] };

export const invoiceSchema: RxJsonSchema<RxInvoice> = {
  version: 5,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    updated: RxScalars.String,
    number: Maybe(RxScalars.String),
    practice_id: RxScalars.ID,
    contact_id: Maybe(RxScalars.ID),
    contact_number: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    contact_ids: Maybe(RxScalars.Array),
    contact_names: Maybe(RxScalars.Array),
    contact_percentages: Maybe(RxScalars.Array),
    split: RxScalars.Boolean,
    hidden: RxScalars.Boolean,
    date: RxScalars.String,
    status_id: RxScalars.Int,
    status_name_key: RxScalars.String,
    total_no_tax: RxScalars.String,
    total_tax: RxScalars.String,
    total: RxScalars.String,
    patient_count: RxScalars.Int,
    patient_id: Maybe(RxScalars.ID),
    patient_number: Maybe(RxScalars.String),
    patient_name: Maybe(RxScalars.String),
    patient_syndicate_id: Maybe(RxScalars.ID),
    changed_date: RxScalars.String,
    contact: Maybe(RxScalars.Array),
    item: Maybe(RxScalars.Array),
    tax: Maybe(RxScalars.Array),
    doctor_names: Maybe(RxScalars.Array),
    patient: Maybe(RxScalars.Object),
    document: RxScalars.String,
    offline_id: Maybe(RxScalars.String),
    offline_patient_id: Maybe(RxScalars.String),
    offline_contact_id: Maybe(RxScalars.String),
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
    offline_services: Maybe(RxScalars.Array),
  },
  required: ['id'],
  indexes: ['updated', ['id', 'hidden'], ['id', 'offline_id']],
};
