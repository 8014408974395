import {
  LabBreedMapping,
  LabDevice,
  LabGenderMapping,
  LabServiceMapping,
  LabSettingsAntech,
  LabSettingsAntechUpsert,
  LabSettingsVetConnect,
  LabSettingsVetConnectUpsert,
  LabSpeciesMapping,
  Mutation,
  MutationAutoMapLabTaxonomyArgs,
  MutationUpsertLabAssignmentsArgs,
  MutationUpsertLabDeviceArgs,
  MutationUpsertLabRequestArgs,
  MutationUpsertLabSettingsAntechArgs,
  MutationUpsertLabSettingsVetConnectArgs,
  MutationUpsertServiceBatchLabResubmitArgs,
  Query,
} from '../../../graph/types';
import {
  useOfflineErrorSkipQuery,
  useOfflineErrorSkipMutation,
  useOfflineErrorSkipLazyQuery,
} from '../useOfflineErrorSkip';
import { OperationVariables, QueryHookOptions, useMutation } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import {
  AutoMapLabTaxonomy,
  GetLabBreedMapping,
  GetLabClass,
  GetLabDevices,
  GetLabGenderMapping,
  GetLabManagementAntech,
  GetLabRefBreeds,
  GetLabRefGenders,
  GetLabRefServices,
  GetLabRefSpecies,
  GetLabRequestsForPatient,
  GetLabResultStatus,
  GetLabServiceMapping,
  GetLabSettingsAntech,
  GetLabSettingsVetConnect,
  GetLabSpeciesMapping,
  UpsertServiceBatchLabResubmit,
  UpsertLabDevice,
  UpsertLabRequest,
  UpsertLabSettingsAntech,
  UpsertLabSettingsVetConnect,
} from '../../../graph/queries/labLink';
import { useQueryWithBackendSearch } from '../useQueryWithBackendSearch';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { GetLabResults } from '../../../graph/queries/labResults';
import { GetUnassignedLabResults, UpsertLabAssignments } from '../../../graph/queries/unassignedLabResults';
import { GetOrganizationWithLabState } from '../../../graph/queries/organizations';
import { FETCH_POLLING_TIME_INTERVAL } from '../../../constants/queryConstants';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const useGetLabRefServices = (organizationId: string, labDeviceId?: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getLabRefServices'>(GetLabRefServices, {
    variables: { organizationId, labRefFilter: { lab_device_id: labDeviceId } },
    skip: !labDeviceId,
  });

  return {
    labServices: data?.getLabRefServices,
    loading,
    error,
    refetch,
  };
};

export const useGetLabServiceMapping = (organizationId: string, labDeviceId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabServiceMapping'>(GetLabServiceMapping, {
    variables: { organizationId, labDeviceId },
  });

  return {
    labServicesMapping: data?.getLabServiceMapping,
    loading,
    refetch,
  };
};

export const useGetLabClass = (organizationId: string, labClassId: string) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getLabClass'>(GetLabClass, {
    variables: { organizationId, labClassId },
  });

  return {
    labClass: data?.getLabClass[0],
    loading,
    error,
  };
};

export const useGetLabDevices = (organizationId: string, practiceId: string, labClassId?: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getLabDevices'>(GetLabDevices, {
    variables: { organizationId, practiceId, labClassId },
  });
  return {
    labDevices: data?.getLabDevices,
    loading,
    error,
    refetch,
  };
};

export const useGetLabRequestsForPatient = (
  organizationId: string,
  patientId: string,
  options: QueryHookOptions,
  searchValue?: string
) => {
  const variables = useMemo(() => ({ organizationId, patientId }), [organizationId, patientId]);
  const { data, loading, setSearchTerm, refetchCurrentSearch } = useQueryWithBackendSearch<'getLabRequests'>(
    GetLabRequestsForPatient,
    variables,
    searchValue,
    options
  );

  return {
    labRequests: data?.getLabRequests,
    loading,
    setSearchTerm,
    refetchCurrentSearch,
  };
};

export const useGetLabSpeciesMapping = (organizationId: string, labDeviceId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabSpeciesMapping'>(GetLabSpeciesMapping, {
    variables: { organizationId, labDeviceId },
  });

  return {
    labSpeciesMapping: data?.getLabSpeciesMapping,
    loading,
    refetch,
  };
};

export const useGetLabRefSpecies = (organizationId: string, labDeviceId?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabRefSpecies'>(GetLabRefSpecies, {
    variables: { organizationId, labRefFilter: { lab_device_id: labDeviceId } },
    skip: !labDeviceId,
  });

  return {
    labRefSpecies: data?.getLabRefSpecies,
    loading,
    refetch,
  };
};

export const useGetLabBreedMapping = (organizationId: string, labDeviceId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabBreedMapping'>(GetLabBreedMapping, {
    variables: { organizationId, labDeviceId },
  });

  return {
    labBreedMapping: data?.getLabBreedMapping,
    loading,
    refetch,
  };
};

export const useGetLabRefBreeds = (organizationId: string, labDeviceId?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabRefBreeds'>(GetLabRefBreeds, {
    variables: { organizationId, labRefFilter: { lab_device_id: labDeviceId } },
    skip: !labDeviceId,
  });

  return {
    labRefBreeds: data?.getLabRefBreeds,
    loading,
    refetch,
  };
};

export const useGetLabGenderMapping = (organizationId: string, labDeviceId: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabGenderMapping'>(GetLabGenderMapping, {
    variables: { organizationId, labDeviceId },
  });

  return {
    labGenderMapping: data?.getLabGenderMapping,
    loading,
    refetch,
  };
};

export const useGetLabRefGenders = (organizationId: string, labDeviceId?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabRefGenders'>(GetLabRefGenders, {
    variables: { organizationId, labRefFilter: { lab_device_id: labDeviceId } },
    skip: !labDeviceId,
  });

  return {
    labRefGenders: data?.getLabRefGenders,
    loading,
    refetch,
  };
};

export const useGetLabResultStatus = (
  organizationId: string,
  deviceId: string,
  orderNumber?: string,
  // This accesionNumber property could be refered to the pims_created_accesion_id or lab_created_accesion_id, depending on the lab we try to get results from
  accessionNumber?: string,
  options?: QueryHookOptions<Pick<Query, 'getLabResultStatus'>, OperationVariables>
) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabResultStatus'>(GetLabResultStatus, {
    ...options,
    variables: {
      organizationId,
      labResultFilter: {
        lab_device_id: deviceId,
        order_number: orderNumber,
        accession_number: accessionNumber,
      },
    },
  });

  return {
    labResultStatus: data?.getLabResultStatus,
    loading,
    refetch,
  };
};

export const useGetLabResults = (organizationId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getLabResults'>(GetLabResults, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    labResults: data?.getLabResults,
    labResultsLoading: loading,
    labResultsError: error,
    refetch,
  };
};

export const useGetUnassignedLabResults = (organizationId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getUnassignedLabs'>(GetUnassignedLabResults, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: FETCH_POLLING_TIME_INTERVAL,
  });

  return {
    labResults: data?.getUnassignedLabs,
    labResultsLoading: loading,
    labResultsError: error,
    refetch,
  };
};

export const useUpdateLabRequest = () => {
  return useOfflineErrorSkipMutation<'upsertLabRequest', MutationUpsertLabRequestArgs>(UpsertLabRequest);
};

export const useUpsertLabDevice = (organizationId: string, type?: TaxonomyType) => {
  return useOfflineErrorSkipMutation<'upsertLabDevice', MutationUpsertLabDeviceArgs>(UpsertLabDevice, {
    update(cache, { data }) {
      let previousMaps;
      let labDevice: LabDevice | undefined;
      let updatedMap: LabSpeciesMapping[] | LabBreedMapping[] | LabGenderMapping[] | LabServiceMapping[] | undefined;
      switch (type) {
        case TaxonomyType.Species:
          previousMaps = cache.readQuery<Pick<Query, 'getLabSpeciesMapping'>>({
            query: GetLabSpeciesMapping,
            variables: { organizationId, labDeviceId: data?.upsertLabDevice.id },
          });

          labDevice = data?.upsertLabDevice;

          updatedMap = previousMaps?.getLabSpeciesMapping.map((m) => {
            const found = labDevice?.lab_species_map?.find((d) => d?.cassadol_species_id === m.cassadol_species_id);
            if (found) {
              return { ...m, lab_species_name: found.lab_species_name, lab_species_number: found.lab_species_number };
            }
            return m;
          });

          cache.modify({
            fields: {
              getLabSpeciesMapping: () => updatedMap,
            },
          });
          cache.evict({ fieldName: 'getLabBreedMapping' });
          cache.evict({ fieldName: 'getLabGenderMapping' });
          cache.gc();
          break;

        case TaxonomyType.Breed:
          previousMaps = cache.readQuery<Pick<Query, 'getLabBreedMapping'>>({
            query: GetLabBreedMapping,
            variables: { organizationId, labDeviceId: data?.upsertLabDevice.id },
          });

          labDevice = data?.upsertLabDevice;

          updatedMap = previousMaps?.getLabBreedMapping.map((m) => {
            const found = labDevice?.lab_breed_map?.find((d) => d?.cassadol_breed_id === m.cassadol_breed_id);
            if (found) {
              return { ...m, lab_breed_name: found.lab_breed_name, lab_breed_number: found.lab_breed_number };
            }
            return m;
          });

          cache.modify({
            fields: {
              getLabBreedMapping: () => updatedMap,
            },
          });
          break;

        case TaxonomyType.Gender:
          previousMaps = cache.readQuery<Pick<Query, 'getLabGenderMapping'>>({
            query: GetLabGenderMapping,
            variables: { organizationId, labDeviceId: data?.upsertLabDevice.id },
          });

          labDevice = data?.upsertLabDevice;

          updatedMap = previousMaps?.getLabGenderMapping?.map((m) => {
            const found = labDevice?.lab_gender_map?.find((d) => d?.cassadol_gender_id === m.cassadol_gender_id);
            if (found) {
              return { ...m, lab_gender_name: found.lab_gender_name, lab_gender_number: found.lab_gender_number };
            }
            return m;
          });

          cache.modify({
            fields: {
              getLabGenderMapping: () => updatedMap,
            },
          });
          break;

        case TaxonomyType.Service:
          previousMaps = cache.readQuery<Pick<Query, 'getLabServiceMapping'>>({
            query: GetLabServiceMapping,
            variables: { organizationId, labDeviceId: data?.upsertLabDevice.id },
          });

          labDevice = data?.upsertLabDevice;

          updatedMap = previousMaps?.getLabServiceMapping?.map((m) => {
            const found = labDevice?.lab_service_map?.find((d) => {
              const sameServiceId = d?.service_id === m.cassadol_service_id;
              const sameId = d?.id === m.id;
              return m.id ? sameId && sameServiceId : sameServiceId;
            });
            if (found) {
              return { ...m, lab_service_name: found.lab_service_name, lab_service_number: found.lab_service_number };
            }
            return m;
          });

          cache.modify({
            fields: {
              getLabServiceMapping: () => updatedMap,
            },
          });
          break;

        default:
          break;
      }
    },
  });
};

export const useAutoMapTaxonomy = () => {
  return useOfflineErrorSkipMutation<'autoMapLabTaxonomy', MutationAutoMapLabTaxonomyArgs>(AutoMapLabTaxonomy);
};

export const useUpsertLabSettingsAntech = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'upsertLabSettingsAntech', MutationUpsertLabSettingsAntechArgs>(
    UpsertLabSettingsAntech
  );

  const labSettings = async (organizationId: string, labSetting: LabSettingsAntechUpsert) => {
    const response = await mutateFunction({
      variables: {
        organizationId,
        labSetting,
      },
    });

    return response?.data?.upsertLabSettingsAntech as LabSettingsAntech;
  };

  return labSettings;
};

export const useUpsertLabSettingsVetConnect = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<
    'upsertLabSettingsVetConnect',
    MutationUpsertLabSettingsVetConnectArgs
  >(UpsertLabSettingsVetConnect);

  const labSettings = async (organizationId: string, labSetting: LabSettingsVetConnectUpsert) => {
    const response = await mutateFunction({
      variables: {
        organizationId,
        labSetting,
      },
      refetchQueries(result) {
        const deviceId = result.data?.upsertLabSettingsVetConnect.lab_device_id;
        return [
          { query: GetLabSettingsVetConnect, variables: { organizationId, filter: { lab_device_id: deviceId } } },
        ];
      },
    });

    return response?.data?.upsertLabSettingsVetConnect as LabSettingsVetConnect;
  };

  return labSettings;
};

export const useGetLabSettingsAntech = (organizationId: string, labDeviceId?: string) => {
  const variables = useMemo(() => ({ organizationId, labDeviceId }), [organizationId, labDeviceId]);
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabSettingsAntech'>(GetLabSettingsAntech, {
    variables,
  });

  return {
    labSettingsAntech: data?.getLabSettingsAntech,
    loading,
    refetch,
  };
};

export const useGetLabSettingsVetConnect = (organizationId: string, labDeviceId?: string) => {
  const variables = useMemo(() => ({ organizationId, labDeviceId }), [organizationId, labDeviceId]);
  const [getLabSettings, { data, loading, refetch }] = useOfflineErrorSkipLazyQuery<'getLabSettingsVetConnect'>(
    GetLabSettingsVetConnect,
    {
      variables,
    }
  );

  return {
    labSettingsVetConnect: data?.getLabSettingsVetConnect,
    loading,
    refetch,
    getLabSettings,
  };
};

export const useGetLabManagementAntech = (organizationId: string, labDeviceId?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getLabManagementAntech'>(GetLabManagementAntech, {
    variables: { organizationId, labFilter: { lab_device_id: labDeviceId } },
    skip: !labDeviceId,
  });

  return {
    labManagementAntech: data?.getLabManagementAntech,
    loading,
    refetch,
  };
};

export const useGetAntechUrl = (organizationId: string) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const { labDevices, loading: labDevicesLoading } = useGetLabDevices(
    organizationId,
    organization?.default_practice_id || '',
    '1'
  );
  const { labManagementAntech, loading: managementLoading } = useGetLabManagementAntech(
    organizationId,
    labDevices?.[0]?.id
  );
  const [antechUrl, setAntechUrl] = useState<string>();

  useEffect(() => {
    if (!labDevicesLoading && !managementLoading && labManagementAntech) {
      const url = `${labManagementAntech?.management_url}?accessToken=${labManagementAntech?.account_token}`;
      setAntechUrl(url);
    }
  }, [labDevices, labDevicesLoading, labManagementAntech, managementLoading]);

  return { antechUrl };
};

export const useUpsertLabAssignments = (organizationId: string) =>
  useMutation<Mutation, MutationUpsertLabAssignmentsArgs>(UpsertLabAssignments, {
    refetchQueries: () => {
      return [{ query: GetOrganizationWithLabState, variables: { organizationId } }];
    },
  });

export const useLabResubmission = () =>
  useMutation<Mutation, MutationUpsertServiceBatchLabResubmitArgs>(UpsertServiceBatchLabResubmit);
