import { gql } from '@apollo/client';

export const MetricFields = gql`
  fragment MetricFields on Metrics {
    organization_id
    practice_id
    monthly_invoiced {
      invoiced_amount
      month
      month_number
      year
    }
    yearly_invoiced {
      invoiced_amount
      year
    }
    accounts_receivable {
      bucket0
      bucket31
      bucket61
      bucket91
      bucket121
    }
    monthly_payments {
      month
      total
    }
    contacts_patients {
      month
      contact_count
      patient_count
    }
  }
`;
