import styled from 'styled-components';

export const StyledChartContainer = styled.div`
  padding: 0;
  margin: 0;
`;

export const ChartContainer = styled.div`
  height: 100%;
  min-height: 350px;
  width: 100%;
  min-width: 420px;
  padding: 1rem 3rem;
`;

export const StyledToolTip = styled.div`
  background: white;
  padding: 8px;

  &:focus-visible {
    outline: none;
  }
`;

export const StyledParagraph = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

export const LastUpdatedDateElement = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;
