import { Form } from 'antd';
import React from 'react';
import {
  reportParameterLabelNameKeyConfigs,
  ReportParametersLabelNameKeys,
  translations,
} from '../../constants/translations';
import { ReportParameters } from '../../graph/types';
import { useGetReportParameterData } from '../../hooks/ajax/report/reportHooks';
import { getRequiredRule } from '../../util/forms';
import { Loading } from '../Loading/Loading';
import { parameterInputMap, ParameterInputMapKeys } from './runReportWithParametersUtil';
import { useUserLocaleData } from '../../hooks/useUserLocale';

export const reportParameterWithDataTestId = 'reportParameterWithDataTestId';
interface ReportParameterItemWithDataProps {
  reportParameter: ReportParameters;
  practiceId: string;
  organizationId: string;
  userId: string;
}

export const ReportParameterItemWithData: React.FC<ReportParameterItemWithDataProps> = ({
  reportParameter,
  practiceId,
  organizationId,
  userId,
}) => {
  const { reportParameterData, reportParameterDataLoading } = useGetReportParameterData(
    organizationId,
    reportParameter.id,
    { userId, practiceId }
  );
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  let options: { value: string; label: string }[] = [];
  if (reportParameterData) {
    const parsedJson = JSON.parse(reportParameterData);
    options = parsedJson.map((row: any) => ({ value: row.id, label: row.label_key }));
  }
  let initialValues: string[] = [];
  if (reportParameter.value) {
    if (reportParameter.input_type === ParameterInputMapKeys.MultiSelect) {
      initialValues = initialValues.concat(JSON.parse(reportParameter.value));
    } else {
      initialValues.push(reportParameter.value);
    }
  }

  return (
    <Form.Item
      data-testid={reportParameterWithDataTestId}
      initialValue={initialValues}
      rules={[getRequiredRule(translations.runReportModal.field)]}
      name={reportParameter.parameter}
      label={reportParameterLabelNameKeyConfigs[reportParameter?.name_key as ReportParametersLabelNameKeys]}
    >
      {reportParameterDataLoading ? (
        <Loading />
      ) : (
        React.cloneElement(parameterInputMap(dateFormat)[reportParameter.input_type as ParameterInputMapKeys], {
          options,
        })
      )}
    </Form.Item>
  );
};
