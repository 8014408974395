import { useContext, useEffect } from 'react';
import { useIdleTimer as reactUseIdleTimer } from 'react-idle-timer';
import { OfflineModeActions } from '../util/offline/store/actions';
import { OfflineModeContext } from '../util/offline/store/state';

const IDLE_TIMEOUT = 1000 * 60 * 60;
export const lastActiveKey = 'cassadol-last-active';

export const refreshLastActive = () => localStorage.setItem(lastActiveKey, `${new Date().getTime()}`);

export const useIdleTimer = () => {
  const {
    state: { isOnline },
    dispatch,
  } = useContext(OfflineModeContext);
  const onIdle = () => dispatch(OfflineModeActions.setRequirePin(!isOnline));

  reactUseIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle,
    onAction: refreshLastActive,
    debounce: 500,
  });

  useEffect(() => {
    const lastActive = +(localStorage.getItem(lastActiveKey) ?? 0);
    dispatch(OfflineModeActions.setRequirePin(!isOnline && new Date().getTime() - lastActive >= IDLE_TIMEOUT));
  }, [dispatch, isOnline]);
};
