import { Button, Col, Modal } from 'antd';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWithSpaces = styled(Button)`
  margin-right: 0.5rem;
`;

export const ColWithTextAligning = styled(Col)`
  text-align: right;
`;

export const ModalWithStyles = styled(Modal)`
  top: 50;
`;
