import React, { PropsWithChildren } from 'react';
import { ImageProps } from 'rc-image';
import { Image } from 'antd';
import { translations } from '../../constants/translations';

interface ImageWithPlaceholderProps extends PropsWithChildren<unknown> {
  imageProps: ImageProps;
  placeholderSrc: string;
}

export const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = ({ imageProps, placeholderSrc }) => {
  if (!imageProps.src) {
    return (
      <Image
        preview={false}
        src={placeholderSrc}
        alt={imageProps.alt ? translations.shared.getPlaceholderAlt(imageProps.alt) : undefined}
      />
    );
  }
  return <Image {...imageProps} />;
};
