import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Table, Form, Button, Space, InputNumber, Switch, Radio, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, KeyboardEvent, useEffect, useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { MAX_INPUT_AMOUNT } from '../../../constants/referenceData/ledgerReferenceData';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { BundleChildPromptModeId, translations } from '../../../constants/translations';
import { BundleService, BundleServiceUpsert, Service } from '../../../graph/types';
import { useGetServicesWithSearch } from '../../../hooks/ajax/service/serviceHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { getRequiredRule } from '../../../util/forms';
import { isNonZeroNumber } from '../../Invoices/ViewInvoice/getInvoiceUpsertServiceRendered';
import { ServicesContext } from '../store/state';
import { setBundlePromptActive, setBundleUpsert, setResetBundleList, setSetRemovedServices } from '../store/actions';
import { ServiceBundleActionCell } from './ServiceBundleActionCell';
import { serviceBundleListColumns } from './serviceBundleColumns';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { FixedWidthSelect, PromptSwitch, StyledSpan } from './ServiceBundle.style';
import { LDFlagNames } from '../../../constants/launchDarkly';
import CurrencyFormatter from '../../../components/CurrencyFormatter/CurrencyFormatter';
import { LabelsAboveItem } from '../../../components/ServiceRendered/AddServiceForm/AddServiceForm.style';
import { draggableColumns, getDraggableComponents } from '../../../components/Draggable/Draggable';
import { moveItemsWithSortOrder, SORT_ORDER_STEP } from '../../../util/filterAndSorting';
import './ServiceBundleTab.css';

const serviceFieldName = 'addService';

export interface AddServicetoBundleFormValues {
  [serviceFieldName]: string;
}

export const addServiceToBundleTestId = 'addServiceToBundleTestId';
export const saveBundleTestId = 'saveBundleTestId';
export const addToBundleTestId = 'addToBundleTestId';
export const bundleBehaviorTestId = 'bundleBehaviorTestId';

const getServicesMap = (services?: Service[]): Record<string, Service> | undefined => {
  const servicesMap: Record<string, Service> = {};
  if (services) {
    services.forEach((service) => {
      servicesMap[service.id] = service;
    });
  }

  return servicesMap;
};

export interface BundleServiceType {
  id: string;
  name: string;
  unit_name?: string | null;
  price: string;
  quantity: number;
  bundleId?: string;
  prompt_mode: number;
  sort_order: number;
  package?: boolean | null;
  print?: boolean | null;
}

const editActionCellStyle = {
  padding: 0,
};

const marginTop = '27px';
const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
interface ServiceBundleTabProps {
  service?: Service;
  setUnsavedData: (hasUnsavedData: boolean) => void;
}

const getBundleListFromBundle = (bundle: BundleService[]) => {
  return bundle.map((serviceBundle) => {
    return {
      id: serviceBundle.bundled_service_id,
      name: serviceBundle.name,
      price: serviceBundle.price,
      unit_name: serviceBundle.unit_name,
      bundleId: serviceBundle.id,
      quantity: Number(serviceBundle.quantity),
      prompt_mode: serviceBundle.prompt_mode,
      sort_order: serviceBundle.sort_order || 0,
      package: serviceBundle.package,
      print: serviceBundle.print,
    };
  });
};

export const bundlePromptOptions = [
  {
    title: translations.serviceBundlePage.tooltips.unchecked,
    value: BundleChildPromptModeId.Unchecked,
    description: translations.serviceBundlePage.radioButtonTitles.unchecked,
  },
  {
    title: translations.serviceBundlePage.tooltips.checked,
    value: BundleChildPromptModeId.Checked,
    description: translations.serviceBundlePage.radioButtonTitles.checked,
  },
  {
    title: translations.serviceBundlePage.tooltips.force,
    value: BundleChildPromptModeId.Forced,
    description: translations.serviceBundlePage.radioButtonTitles.force,
  },
];

enum BundleBehaviorType {
  NotPackaged = 0,
  Package = 1,
  PackageAndPrint = 2,
}

const bundleBehaviorOptions = [
  { label: translations.serviceBundlePage.behaviorOptions.notPackaged, value: BundleBehaviorType.NotPackaged },
  { label: translations.serviceBundlePage.behaviorOptions.package, value: BundleBehaviorType.Package },
  { label: translations.serviceBundlePage.behaviorOptions.packageAndPrint, value: BundleBehaviorType.PackageAndPrint },
];

const bundleBehaviorTooltipTextMap = {
  [BundleBehaviorType.NotPackaged]: translations.serviceBundlePage.tooltips.notPackagedBehavior,
  [BundleBehaviorType.Package]: translations.serviceBundlePage.tooltips.packageBehavior,
  [BundleBehaviorType.PackageAndPrint]: translations.serviceBundlePage.tooltips.packageAndPrintBehavior,
};

export const ServiceBundleTab: React.FC<ServiceBundleTabProps> = ({ service, setUnsavedData }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [editServiceId, setEditServiceId] = useState<string | undefined>();
  const [form] = useForm();
  const [editQuantity, setEditQuantity] = useState<number | undefined>();
  const { services, servicesLoading, setSearchTerm } = useGetServicesWithSearch(organizationId);
  const allServices = services?.filter((serv) => serv.id !== service?.id) ?? [];
  const serviceOptions = allServices?.map((service) => ({ value: service.id, label: service.name }));
  const servicesMap = getServicesMap(allServices);
  const [bundleServiceList, setBundleServiceList] = useState<BundleServiceType[]>([]);
  const [removedServices, setRemovedServices] = useState<string[]>([]);
  const {
    dispatch,
    state: { bundlePromptActive },
  } = useContext(ServicesContext);
  const promptForItemEnabled = useLDFlag(LDFlagNames.ToggleBundle);
  const bundleBehaviorEnabled = useLDFlag(LDFlagNames.BundlePackage);
  const bundle = service?.bundle;

  useEffect(() => {
    dispatch(setSetRemovedServices(setRemovedServices));
    if (bundle) {
      dispatch(setResetBundleList(() => setBundleServiceList(getBundleListFromBundle(bundle))));
    }
  }, [dispatch, setRemovedServices, bundle]);

  const setBundlePrompt = useCallback(
    (active: boolean) => {
      dispatch(setBundlePromptActive(active));
      if (service?.bundle_prompt !== active) {
        setUnsavedData(true);
      }
    },
    [dispatch, service, setUnsavedData]
  );

  useEffect(() => {
    setBundlePrompt(service?.bundle_prompt ?? false);
  }, [service, setBundlePrompt]);

  useEffect(() => {
    const removeBundleUpsert: BundleServiceUpsert[] = removedServices.map((id) => ({ id, void: true }));
    const bundleUpsert: BundleServiceUpsert[] = bundleServiceList.map((serviceBundle) => ({
      id: serviceBundle.bundleId ?? ID_FOR_OBJECT_CREATION,
      record: {
        quantity: String(serviceBundle.quantity),
        bundled_service_id: serviceBundle.id,
      },
      bundlingRecord: promptForItemEnabled
        ? {
            prompt_mode: serviceBundle.prompt_mode,
          }
        : undefined,
      orderRecord: {
        sort_order: serviceBundle?.sort_order,
      },
      packageRecord: bundleBehaviorEnabled
        ? {
            package: serviceBundle?.package || false,
          }
        : undefined,
      printRecord: bundleBehaviorEnabled
        ? {
            print: serviceBundle?.print || false,
          }
        : undefined,
    }));
    dispatch(setBundleUpsert(bundleUpsert.concat(removeBundleUpsert)));
  }, [bundleServiceList, dispatch, removedServices, bundleBehaviorEnabled, promptForItemEnabled]);

  useEffect(() => {
    if (bundle) {
      setBundleServiceList(getBundleListFromBundle(bundle));
    }
  }, [setBundleServiceList, bundle]);

  const addServiceToBundle = (values: AddServicetoBundleFormValues) => {
    if (servicesMap) {
      const addedServiceId = values[serviceFieldName];
      const newBundleServiceList = [...bundleServiceList];
      const service = newBundleServiceList.find((service) => service.id === addedServiceId);
      if (!service) {
        newBundleServiceList.push({
          ...servicesMap[addedServiceId],
          quantity:
            servicesMap[addedServiceId].quantity_default && Number(servicesMap[addedServiceId].quantity_default) !== 0
              ? Number(servicesMap[addedServiceId].quantity_default)
              : 1,
          prompt_mode: BundleChildPromptModeId.Checked,
          sort_order:
            bundleServiceList.length === 0
              ? SORT_ORDER_STEP
              : bundleServiceList[bundleServiceList.length - 1].sort_order + SORT_ORDER_STEP,
        });
      } else {
        service.quantity += 1;
      }
      setBundleServiceList(newBundleServiceList);
      setUnsavedData(true);
    }
    form.resetFields();
    setSearchTerm('');
  };
  const handleCancelEdit = () => {
    setEditServiceId(undefined);
    setEditQuantity(undefined);
  };

  const renderActionColumn = (service: Service) =>
    editServiceId === service.id ? (
      <Space direction={'horizontal'}>
        <Button type='link' style={editActionCellStyle} onClick={() => updateServiceQuantity(service.id)}>
          {translations.shared.saveButtonText}
        </Button>
        <Button type='link' style={editActionCellStyle} onClick={handleCancelEdit}>
          {translations.shared.cancelButtonText}
        </Button>
      </Space>
    ) : (
      <ServiceBundleActionCell
        serviceId={service.id}
        setEditServiceId={setEditServiceId}
        removeServiceFromBundle={removeServiceFromBundle}
      />
    );

  const renderBehaviorColumn = (record: BundleServiceType) => {
    let value;
    if (record?.package && record?.print) {
      value = BundleBehaviorType.PackageAndPrint;
    } else if (record?.package) {
      value = BundleBehaviorType.Package;
    } else {
      value = BundleBehaviorType.NotPackaged;
    }
    return (
      <FixedWidthSelect
        data-testid={bundleBehaviorTestId + record.id}
        value={value}
        onChange={(value) => {
          setBundleServiceList(
            bundleServiceList.map((r) => {
              if (r.id === record.id) {
                if (value === BundleBehaviorType.NotPackaged) {
                  return { ...r, package: false, print: false };
                } else if (value === BundleBehaviorType.Package) {
                  return { ...r, package: true, print: false };
                } else if (value === BundleBehaviorType.PackageAndPrint) {
                  return { ...r, package: true, print: true };
                }
              }
              return r;
            })
          );
          setUnsavedData(true);
        }}
      >
        {bundleBehaviorOptions.map((option) => (
          <Select.Option value={option.value} title={option.label} label={option.label} key={option.value}>
            <Tooltip
              key={option.value + 'tooltip'}
              title={bundleBehaviorTooltipTextMap[option.value]}
              placement='rightBottom'
            >
              <StyledSpan>{option.label}</StyledSpan>
            </Tooltip>
          </Select.Option>
        ))}
      </FixedWidthSelect>
    );
  };

  const columns: ColumnsType<BundleServiceType> = [
    {
      ...serviceBundleListColumns.name,
      width: 200,
    },
    {
      title: translations.serviceBundlePage.columns.quantity,
      render: (record: BundleServiceType) =>
        editServiceId === record.id ? (
          <InputNumber
            autoFocus
            precision={2}
            required
            style={!isNonZeroNumber(editQuantity ?? 1) ? { borderColor: 'red' } : undefined}
            value={editQuantity ?? record.quantity}
            min={0}
            max={MAX_INPUT_AMOUNT}
            onChange={(value) => setEditQuantity(Number(value))}
            onFocus={() => setEditQuantity(bundleServiceList.find((service) => service.id === editServiceId)?.quantity)}
          />
        ) : (
          record.quantity
        ),
      width: 70,
    },
    {
      ...serviceBundleListColumns.unit_name,
      width: 70,
    },
    {
      title: translations.serviceBundlePage.columns.total,
      render: (record: BundleServiceType) => <CurrencyFormatter total={record.quantity * Number(record.price)} />,
      width: 100,
    },
    ...(bundleBehaviorEnabled
      ? [
          {
            title: translations.serviceBundlePage.columns.behavior,
            render: renderBehaviorColumn,
            width: 100,
          },
        ]
      : []),
    ...(bundlePromptActive
      ? [
          {
            title: translations.serviceBundlePage.columns.prompt,
            render: (_: any, bundledItem: BundleServiceType) => (
              <Radio.Group value={bundledItem.prompt_mode} buttonStyle='solid'>
                {bundlePromptOptions.map(({ title, value, description }) => (
                  <Tooltip key={value} title={title}>
                    <Radio.Button onClick={() => updateBundleServicePrompt(value, bundledItem)} value={value}>
                      {description}
                    </Radio.Button>
                  </Tooltip>
                ))}
              </Radio.Group>
            ),
            width: 150,
          },
        ]
      : []),
    {
      title: translations.serviceBundlePage.columns.actions,
      key: 'action',
      render: renderActionColumn,
      width: 120,
    },
  ];

  const onRowMove = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (oldIndex === newIndex) {
        return;
      }

      const bundleServicesWithUpdatedSortOrder = moveItemsWithSortOrder(
        [...bundleServiceList],
        oldIndex,
        newIndex
      ) as BundleServiceType[];

      setBundleServiceList(bundleServicesWithUpdatedSortOrder);
      setUnsavedData(true);
    },
    [bundleServiceList, setBundleServiceList, setUnsavedData]
  );

  const components = useMemo(
    () =>
      getDraggableComponents({
        data: bundleServiceList,
        onSort: onRowMove,
      }),
    [bundleServiceList, onRowMove]
  );

  const updateServiceQuantity = (serviceId: string) => {
    const newBundleServiceList = [...bundleServiceList];
    const service = newBundleServiceList.find((service) => service.id === serviceId);
    const originalQuantity = service?.quantity;
    if (service?.quantity || service?.quantity === Number(0)) {
      service.quantity = editQuantity ?? 1;
    }
    if (originalQuantity !== service?.quantity) {
      setUnsavedData(true);
    }
    setBundleServiceList(newBundleServiceList);
    handleCancelEdit();
  };

  const updateBundleServicePrompt = (bundlePrompt: BundleChildPromptModeId, service: BundleServiceType) => {
    const newBundles = bundleServiceList.map((bundledService) => {
      if (bundledService.id === service.id && bundledService?.prompt_mode !== bundlePrompt) {
        bundledService.prompt_mode = bundlePrompt;
        setUnsavedData(true);
      }
      return bundledService;
    });
    setBundleServiceList(newBundles);
  };

  const removeServiceFromBundle = (removedServiceId: string) => {
    const bundle = bundleServiceList.find((service) => service.id === removedServiceId);
    if (bundle?.bundleId) {
      setRemovedServices(removedServices.concat(bundle.bundleId));
    }
    setBundleServiceList(bundleServiceList.filter((service) => service.id !== removedServiceId));
    setUnsavedData(true);
  };

  const handleKeyDownInForm = async (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await addServiceToBundle(form.getFieldsValue());
    } else if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  return (
    <Space direction={'vertical'} style={{ width: 'inherit' }}>
      <SpaceBetweenWrapper>
        <Form
          form={form}
          onKeyDown={handleKeyDownInForm}
          requiredMark={false}
          labelAlign={'left'}
          layout='inline'
          colon
          className={'labelsAboveItems'}
          onFinish={addServiceToBundle}
          id={'AddServiceForm'}
          autoComplete='off'
        >
          <LabelsAboveItem
            name={serviceFieldName}
            rules={[getRequiredRule(translations.invoicePage.fields.addService)]}
            label={translations.invoicePage.fields.addService}
          >
            <Select
              {...searchableSelectParams}
              loading={servicesLoading}
              onSearch={setSearchTerm}
              options={serviceOptions}
              filterOption={false}
              placeholder={translations.invoicePage.serviceFieldPlaceholder}
              autoFocus
              style={{ width: 275 }}
              suffixIcon={servicesLoading ? <LoadingOutlined /> : <SearchOutlined />}
              data-testid={addServiceToBundleTestId}
              dropdownMatchSelectWidth={false}
            />
          </LabelsAboveItem>
          <Form.Item name='addServiceButton'>
            <Button
              data-testid={addToBundleTestId}
              type='primary'
              htmlType='submit'
              style={{ marginTop }}
              onClick={(e) => {
                e.preventDefault();
                form.submit();
              }}
            >
              {translations.invoicePage.serviceFieldPlaceholder}
            </Button>
          </Form.Item>
          {promptForItemEnabled && (
            <PromptSwitch label={translations.serviceBundlePage.promptForItems} labelAlign='left' name='promptToggle'>
              <Switch checked={bundlePromptActive} onChange={setBundlePrompt} />
            </PromptSwitch>
          )}
        </Form>
      </SpaceBetweenWrapper>
      <Table
        pagination={false}
        dataSource={bundleServiceList}
        columns={draggableColumns(columns)}
        loading={servicesLoading}
        rowKey={'id'}
        components={components}
      />
    </Space>
  );
};
