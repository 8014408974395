import React from 'react';
import { Form, Input } from 'antd';
import { translations } from '../../constants/translations';
import { getValidPasswordRule } from '../../util/forms';

export const newPasswordTemplateName = 'newPassword';
export const newPasswordConfirmTemplateName = 'confirm';

export const NewPasswordFormItems: React.FC = () => {
  return (
    <>
      <Form.Item
        label={translations.auth.newPassword.label}
        name={newPasswordTemplateName}
        rules={[{ required: true, message: translations.auth.newPassword.errorMessage }, getValidPasswordRule()]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name='confirm'
        label={translations.auth.confirmPassword.label}
        dependencies={[newPasswordTemplateName]}
        hasFeedback
        rules={[
          {
            required: true,
            message: translations.auth.confirmPassword.errorMessage,
          },
          ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (!value || getFieldValue(newPasswordTemplateName) === value) {
                return Promise.resolve();
              }
              return Promise.reject(translations.auth.confirmPassword.nonMatchingErrorMessage);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};
