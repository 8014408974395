import { useMemo } from 'react';
import { Practice, PracticeDto } from '../../../graph/types';

export const useGetStripeEnabledForPractice = (practice: Practice | PracticeDto | undefined) => {
  const practiceAddress = practice?.address;
  return useMemo(() => {
    if (!practiceAddress) {
      return false;
    }
    if (practiceAddress[0].country_name_key === 'CTRY_USA' || practiceAddress[0].country_name_key === 'CTRY_CANADA') {
      return true;
    }
    return false;
  }, [practiceAddress]);
};
