import gql from 'graphql-tag';

export const GetStripePaymentIntents = gql`
  query getStripePaymentIntents(
    $organizationId: ID!
    $practiceId: ID!
    $transactionsFilter: StripeTransactionsFilter!
  ) {
    getStripePaymentIntents(
      organizationId: $organizationId
      practiceId: $practiceId
      transactionsFilter: $transactionsFilter
    ) {
      id
      amount
      created
      customer_email
      customer_name
      description
      currency
      status
      status_text
    }
  }
`;

export const GetStripePaymentIntentDetails = gql`
  query getStripePaymentIntentDetails($organizationId: ID!, $practiceId: ID!, $paymentIntentId: ID!) {
    getStripePaymentIntentDetails(
      organizationId: $organizationId
      practiceId: $practiceId
      paymentIntentId: $paymentIntentId
    ) {
      id
      amount
      amount_capturable
      amount_received
      amount_refunded
      application_fee_amount
      net_amount
      canceled_at
      cancellation_reason
      capture_method
      capture_method_text
      created
      currency
      description
      receipt_email
      status
      status_text
      customer_name
      customer_email
      payment_method {
        type
        billing_details {
          address {
            city
            country
            line1
            line2
            postal_code
          }
          email
          name
          phone
        }
        card {
          brand
          funding
          checks {
            address_line1_check
            address_postal_code_check
            cvc_check
          }
          country
          description
          exp_month
          exp_year
          last4
        }
      }
      payment_error {
        code
        code_text
        message
        type
        type_text
      }
      time_line {
        order_number
        date
        code
        text
        value
      }
    }
  }
`;

export const GetStripePayouts = gql`
  query getStripePayouts($organizationId: ID!, $practiceId: ID!) {
    getStripePayouts(organizationId: $organizationId, practiceId: $practiceId) {
      balance
      available
      sent_to_payout_account
      payout_details_list {
        amount
        arrival_date
        bank_name
        description
        destination
        id
        last4
        routing_number
        status
        currency
      }
    }
  }
`;

export const GetStripePayoutDetails = gql`
  query getStripePayoutDetails($organizationId: ID!, $practiceId: ID!, $payoutId: ID!) {
    getStripePayoutDetails(organizationId: $organizationId, practiceId: $practiceId, payoutId: $payoutId) {
      id
      payout_details {
        amount
        arrival_date
        bank_name
        created
        currency
        description
        destination
        destination_id
        id
        last4
        routing_number
        source_type
        source_type_text
        status
        status_text
        type
        type_text
      }
      transaction_list {
        amount
        available_on
        created
        currency
        description
        exchange_rate
        fee
        id
        net
        object
        object_text
        status
        status_text
        type
        type_text
        contact_email
        contact_id
        contact_name
        contact_number
        electronic_payment_id
        ledger_id
        ledger_number
        processor_record_id
        ledger_financial_date
      }
    }
  }
`;
