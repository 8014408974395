import { TagColor } from '../../constants/tagColor';
import { Organization, User } from '../../graph/types';
import { stringCompareFunction } from '../../util/filterAndSorting';

export type OfflineUser = {
  id?: string;
  userId: string;
  name: string;
  roles: string[];
  enableOffline: boolean;
};

export type TrackOfflineUsers = OfflineUser & {
  isSubmitted?: boolean;
};

export const getOfflineUserDataByOrganizationSubscription = (organization: Organization, users: User[]) => {
  const usersList: OfflineUser[] = [];

  if (!organization) {
    return usersList;
  }

  const offlineUsers: Record<string, { id: string; name: string }> = {};
  organization?.subscription?.detail?.forEach((detail) => {
    if (detail?.user_type) {
      detail.users?.forEach((user) => {
        offlineUsers[user.user_id] = { id: user.id, name: user.name };
      });
    }
  });

  users?.forEach((user) => {
    if (!user.inactive) {
      const detailUser = offlineUsers[user.id];
      const currentOrg = user.organization?.find((org) => org.organization_id === organization.id);
      const roles = currentOrg?.type?.map((type) => type.type_name) ?? [];

      usersList.push({
        id: detailUser?.id,
        userId: user.id,
        enableOffline: !!detailUser,
        name: user.name,
        roles,
      });
    }
  });
  return usersList;
};

export const getUserRolesColors = (roles: string[]) => {
  const uniqueRoles = [...new Set(roles)].sort(stringCompareFunction);

  const tagColors = Object.values(TagColor);

  const res: Record<string, string> = {};

  let tagIndex = 0;

  uniqueRoles.forEach((role) => {
    res[role] = tagColors[tagIndex];
    tagIndex++;
    if (!tagColors[tagIndex]) {
      tagIndex = 0;
    }
  });

  return res;
};
