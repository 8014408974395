import { Tag } from 'antd';
import React from 'react';

export function getTaxTags(taxCodes: string[]) {
  return taxCodes?.map((tax) => getTaxTag(tax));
}

export function getTaxTag(tax: string) {
  return (
    <Tag style={{ marginBottom: '2px', marginTop: '2px' }} key={tax} color={'green'}>
      {tax}
    </Tag>
  );
}
