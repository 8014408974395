import { gql } from '@apollo/client';

export const LabClassFields = gql`
  fragment LabClassFields on LabClass {
    id
    name
    can_cancel_submit
    group_requests
    group_caption
    requires_dob
    requires_species_map
    requires_breed_map
    requires_gender_map
    requires_sample_map
    is_bidirectional
    created
    created_user_id
    created_user_name
    updated
    updated_user_id
    updated_user_name
    lab_device
  }
`;

export const LabDeviceFields = gql`
  fragment LabDeviceFields on LabDevice {
    id
    organization_id
    practice_id
    lab_class_id
    lab_class_name
    name
  }
`;

export const LabStatusFields = gql`
  fragment LabStatusFields on LabStatus {
    lab_device_id
    lab_device_name
    request_group_id
    status_id
    status_name_key
  }
`;

export const LabRequestFields = gql`
  fragment LabRequestFields on LabRequest {
    id
    service_id
    service_name
    lab_service_name
    lab_service_number
    patient_id
    patient_name
    contact_id
    contact_name
    doctor_id
    doctor_name
    lab_device_id
    service_rendered_id
    lab_device_name
    lab_request_number
    request_group_id
    status_id
    status_name_key
    description
    created_from_lab
    lab_request_data
    lab_request_url
    send_log
    send_error_log
    practice_id
    lab_service_map_id
    requires_breed_map
    requires_gender_map
    requires_species_map
    lab_status_value
    lab_created_accession_id
    pims_created_accession_id
    lab_created_order_number
    pims_created_order_number
  }
`;

export const LabResultsFields = gql`
  fragment LabResultsFields on LabResultRecord {
    service_list
    contact_id
    contact_name
    invoice_id
    lab_device_id
    lab_device_name
    lab_request_url
    lab_result_url
    organization_id
    patient_id
    patient_name
    practice_id
    request_date
    request_group_id
    status_id
    status_name_key
    lab_created_accession_id
    pims_created_accession_id
    lab_created_order_number
    pims_created_order_number
    lab_class_id
  }
`;

export const UnassignedLabResultsFields = gql`
  fragment UnassignedLabResultsFields on UnassignedLabResults {
    lab_tests
    patient_name
    contact_name
    result_data
    result_date
    lab_created_accession_id
    lab_device_id
    lab_device_name
    lab_request_id
    result_url
    status_name_key
    status_id
  }
`;

export const LabAssignmentResultFields = gql`
  fragment LabAssignmentResultFields on LabAssignmentResult {
    results_updated
  }
`;

export const LabDeviceFieldsWithTaxonomy = gql`
  fragment LabDeviceFieldsWithTaxonomy on LabDevice {
    id
    organization_id
    practice_id
    lab_class_id
    lab_class_name
    name
    lab_breed_map {
      id
      lab_breed_name
      lab_breed_number
      cassadol_breed_id
      cassadol_breed_name
    }
    lab_gender_map {
      id
      lab_gender_name
      lab_gender_number
      cassadol_gender_id
      cassadol_gender_name
    }
    lab_species_map {
      id
      lab_species_name
      lab_species_number
      cassadol_species_id
      cassadol_species_name
    }
    lab_service_map {
      id
      lab_service_name
      lab_service_number
      service_id
      service_name
    }
  }
`;

export const LabTaxonomyMapResultFields = gql`
  fragment LabTaxonomyMapResultFields on LabTaxonomyMapResult {
    added_species
    added_breeds
    added_genders
  }
`;
