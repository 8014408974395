import { cloneDeep } from 'lodash';

export function cloneDeepWithExclude<T extends Record<string, any>>(object: T, excludeKeys: (keyof T | string)[]): T {
  const clone: T = cloneDeep(object);
  deleteEntriesWithKeys(clone, excludeKeys);
  return clone;
}

function deleteEntriesWithKeys<T extends Record<string, any>, U extends keyof T>(
  object: T,
  keys: (keyof T | string)[]
) {
  const valueKeys: U[] = object != null ? (Object.keys(object) as U[]) : [];
  for (const key of valueKeys) {
    if (keys.includes(key.toString())) {
      delete object[key];
    } else if (object[key] != null && typeof object[key] === 'object') {
      deleteEntriesWithKeys(object[key], keys);
    }
  }
}
