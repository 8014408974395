import React from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';

const TestSwitch = () => {
  const enabled = useLDFlag(LDFlagNames.Test);

  if (!enabled) {
    return null;
  }

  return (
    <Container>
      <Switch />
    </Container>
  );
};

export default TestSwitch;

const Container = styled.div`
  margin-left: auto;
`;
