import { CardElementComponent, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreateTokenCardData } from '@stripe/stripe-js';
import { useGetOrganization } from './ajax/organization/organizationHooks';
import { useGetPractice } from './ajax/practice/practiceHooks';
import { AdditionalOrganizationField } from '../graph/queries/organizations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StripeTokenizerExtraDataProps } from '../components/StripeTokenizer/StripeTokenizer';
import { AddressType } from '../classes/upsertGenerators/AddressUpsertGenerator';
import { LedgerPaymentTypeValue } from '../constants/referenceData/ledgerReferenceData';
import { useOfflineErrorSkipQuery } from './ajax/useOfflineErrorSkip';
import {
  GetStripePaymentIntentDetails,
  GetStripePaymentIntents,
  GetStripePayoutDetails,
  GetStripePayouts,
} from '../graph/queries/stripe';
import { QueryHookOptions } from '@apollo/client';
import { OrganizationDto } from '../graph/types';
import { useUpsertStripeTransaction } from './ajax/paymentGateway/paymentGatewayHooks';
import { StripeConnectInstance, loadConnect } from '@stripe/connect-js';
import { getEmbeddedComponentsAppearance, getIsStripeProcessor } from '../util/stripeConnectUtils';
import { useOrganizationContext } from '../contexts/organization/state';
import { translations } from '../constants/translations';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { getStripePublishableKey } from '../config/config';

export type CreditCardTokenInfo = {
  token: string;
  expiryDate: string;
  expiryYear: string;
  expiryMonth: string;
  cardType: LedgerPaymentTypeValue;
  displayNumber: string;
};

export const useGetStripeToken = () => {
  const stripe = useStripe();
  const elements = useElements();

  const getToken = async (cardElements: CardElementComponent, createTokenData?: CreateTokenCardData) => {
    const cardElement = elements?.getElement(cardElements);
    if (cardElement) {
      const token = await stripe?.createToken(cardElement, createTokenData);
      if (token?.error) {
        return { error: token.error };
      }
      const tokenId = token?.token?.id;
      const expMonth = token?.token?.card?.exp_month;
      const expYear = token?.token?.card?.exp_year;
      const cardId = token?.token?.card?.id;
      const last4 = token?.token?.card?.last4;
      const nameOnCard = token?.token?.card?.name;
      const brand = token?.token?.card?.brand;
      return { tokenId, expMonth, expYear, cardId, last4, nameOnCard, brand };
    }
    return undefined;
  };

  return getToken;
};

export const useGetStripeTokenUtils = (organizationId: string) => {
  const [creditCard, setCreditCard] = useState<CreditCardTokenInfo>();
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.AddressDemographics,
    undefined,
    { fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-only' }
  );
  const practiceId = organization?.default_practice_id ?? '';
  const { practice, practiceLoading } = useGetPractice(organizationId, practiceId);
  const subscription = organization?.subscription;
  const practiceAddress = practice?.address?.find((address) => address?.address_type_id === AddressType.Physical);

  const [tokenizerExtraData, setTokenizerExtraData] = useState<StripeTokenizerExtraDataProps | undefined>({
    payer: subscription?.name_on_card ?? '',
    addressLine1: practiceAddress?.address_1 ?? '',
    addressLine2: practiceAddress?.address_2 ?? '',
    city: practiceAddress?.city ?? '',
    countryId: practiceAddress?.country_id ?? '',
    provStateId: practiceAddress?.country_prov_state_id ?? '',
    postalZip: practiceAddress?.postal_zip ?? '',
  });

  useEffect(() => {
    if (practice && subscription && practiceAddress) {
      setTokenizerExtraData({
        payer: subscription.name_on_card ?? '',
        addressLine1: practiceAddress.address_1 ?? '',
        addressLine2: practiceAddress.address_2 ?? '',
        city: practiceAddress.city ?? '',
        countryId: practiceAddress.country_id ?? '',
        provStateId: practiceAddress.country_prov_state_id ?? '',
        postalZip: practiceAddress.postal_zip ?? '',
      });
    }
  }, [practice, subscription, practiceAddress]);

  return {
    tokenizerExtraData,
    setTokenizerExtraData,
    organization,
    organizationLoading,
    practice,
    practiceLoading,
    practiceAddress,
    creditCard,
    setCreditCard,
  };
};

export const useGetStripeTokenUtilsDto = (organization?: OrganizationDto) => {
  const [creditCard, setCreditCard] = useState<CreditCardTokenInfo>();
  const practiceId = organization?.default_practice_id ?? '';
  const { practice, practiceLoading } = useGetPractice(organization?.id ?? '', practiceId);
  const subscription = organization?.subscription;
  const practiceAddress = practice?.address.find((address) => address?.address_type_id === AddressType.Physical);

  const [tokenizerExtraData, setTokenizerExtraData] = useState<StripeTokenizerExtraDataProps | undefined>({
    payer: subscription?.name_on_card ?? '',
    addressLine1: practiceAddress?.address_1 ?? '',
    addressLine2: practiceAddress?.address_2 ?? '',
    city: practiceAddress?.city ?? '',
    countryId: practiceAddress?.country_id ?? '',
    provStateId: practiceAddress?.country_prov_state_id ?? '',
    postalZip: practiceAddress?.postal_zip ?? '',
  });

  useEffect(() => {
    if (practice && subscription && practiceAddress) {
      setTokenizerExtraData({
        payer: subscription.name_on_card ?? '',
        addressLine1: practiceAddress.address_1 ?? '',
        addressLine2: practiceAddress.address_2 ?? '',
        city: practiceAddress.city ?? '',
        countryId: practiceAddress.country_id ?? '',
        provStateId: practiceAddress.country_prov_state_id ?? '',
        postalZip: practiceAddress.postal_zip ?? '',
      });
    }
  }, [practice, subscription, practiceAddress]);

  return {
    tokenizerExtraData,
    setTokenizerExtraData,
    organization,
    practice,
    practiceLoading,
    practiceAddress,
    creditCard,
    setCreditCard,
  };
};

export const useGetStripePaymentIntents = (organizationId: string, practiceId?: string) => {
  const queryOptions: QueryHookOptions = useMemo(
    () => ({
      variables: {
        organizationId,
        practiceId,
        transactionsFilter: {
          lookPracticeLevel: true,
          batchSize: 100,
        },
      },
      skip: !practiceId,
    }),
    [organizationId, practiceId]
  );
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery(GetStripePaymentIntents, queryOptions);

  return {
    paymentIntents: data?.getStripePaymentIntents,
    intentsLoading: loading,
    error,
    refetchPaymentIntents: refetch,
  };
};

export const useGetStripePaymentIntentDetails = (
  organizationId: string,
  practiceId: string,
  paymentIntentId: string
) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery(GetStripePaymentIntentDetails, {
    variables: {
      organizationId,
      practiceId,
      paymentIntentId,
    },
  });

  return {
    paymentDetails: data?.getStripePaymentIntentDetails,
    detailsLoading: loading,
    error,
    refetchPaymentDetails: refetch,
  };
};

export const useGetStripePayouts = (organizationId: string, practiceId: string) => {
  const queryOptions: QueryHookOptions = useMemo(
    () => ({
      variables: {
        organizationId,
        practiceId,
      },
      skip: !practiceId,
    }),
    [organizationId, practiceId]
  );
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery(GetStripePayouts, queryOptions);

  return {
    stripePayoutsSummary: data?.getStripePayouts,
    payoutsLoading: loading,
    error,
    refetchPayoutsSummary: refetch,
  };
};

export const useGetStripePayoutDetails = (organizationId: string, practiceId: string, payoutId: string) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery(GetStripePayoutDetails, {
    variables: {
      organizationId,
      practiceId,
      payoutId,
    },
  });

  return {
    payoutDetails: data?.getStripePayoutDetails,
    detailsLoading: loading,
    error,
    refetchPayoutDetails: refetch,
  };
};

export const useGetStripeConnectInstance = () => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice.find(({ id }) => id === organization.default_practice_id);
  const practiceAddress = practice?.address.find((address) => address?.address_type_id === AddressType.Physical);
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | undefined>(undefined);
  const [upsertStripeTransaction] = useUpsertStripeTransaction(organization?.id ?? '');
  const isStripeProcessor = getIsStripeProcessor(organization);
  const fetchClientSecret = useCallback(async () => {
    try {
      const result = await upsertStripeTransaction({
        variables: {
          organizationId: organization?.id || '',
          practiceId: practice?.id || '',
          embeddedAccountSessionRequestRecord: { request: true },
        },
      });
      if (!result.data?.upsertStripeTransaction.success) {
        return undefined;
      }

      return result.data.upsertStripeTransaction.message!;
    } catch (e) {
      showErrorMessage(translations.stripeEmbedded.error);
      return undefined;
    }
  }, [practice?.id, organization?.id, upsertStripeTransaction]);

  useEffect(() => {
    let stripeConnect;
    if (isStripeProcessor) {
      (async () => {
        try {
          stripeConnect = await loadConnect();
        } catch (error) {
          showErrorMessage(translations.stripeEmbedded.loadError);
          return;
        }
        const clientSecret = await fetchClientSecret();
        if (clientSecret && practiceAddress?.country_id) {
          const instance = stripeConnect.initialize({
            publishableKey: getStripePublishableKey(practiceAddress.country_id),
            clientSecret,
            appearance: getEmbeddedComponentsAppearance(),
            uiConfig: {
              overlay: 'dialog',
            },
            refreshClientSecret: async () => {
              return (await fetchClientSecret()) || '';
            },
          });
          setStripeConnectInstance(instance);
        } else {
          showErrorMessage(translations.stripeEmbedded.error);
        }
      })();
    }
  }, [fetchClientSecret, organization, isStripeProcessor, practiceAddress?.country_id]);

  return { stripeConnectInstance };
};
