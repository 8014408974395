import { Input } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';

export interface RabiesTagModalFormValues {
  rabiesTag?: string;
}

export interface RabiesTagModalProps {
  autofocus?: boolean;
}

export const rabiesTagModalFormTestId = 'rabiesTagModalForm';

export const RabiesTagModalContent: React.FC<RabiesTagModalProps> = ({ autofocus }) => (
  <>
    <MaxLengthFormItem label={translations.shared.rabiesTagModal.fields.rabiesTag} name='rabiesTag' maxLength={255}>
      <Input data-testid='rabiesTagInput' autoFocus={autofocus} />
    </MaxLengthFormItem>
  </>
);
