import React, { CSSProperties } from 'react';
import { Button, ButtonProps, Popconfirm } from 'antd';
import { translations } from '../../constants/translations';
import { ButtonType } from 'antd/lib/button';
import { RenderFunction } from 'antd/es/tooltip';

interface ButtonWithPopconfirmProps extends ButtonProps {
  buttonTestId?: string;
  buttonText?: string;
  buttonTitle?: (isDisabled: boolean | undefined) => string;
  danger?: boolean;
  isDisabled?: boolean;
  popconfirmTitle: React.ReactNode | RenderFunction;
  popconfirmOkText?: string;
  popconfirmCancelText?: string;
  onClickCallback: () => void;
  buttonStyle?: CSSProperties;
  buttonIcon?: React.ReactNode;
  buttonType?: ButtonType;
}

export const ButtonWithPopconfirm: React.FC<React.PropsWithChildren<ButtonWithPopconfirmProps>> = ({
  buttonTestId,
  buttonText,
  buttonTitle,
  danger,
  isDisabled,
  popconfirmTitle,
  popconfirmOkText,
  popconfirmCancelText,
  onClickCallback,
  buttonStyle,
  buttonIcon,
  buttonType,
}: ButtonWithPopconfirmProps) => {
  const button = (
    <Button
      type={buttonType}
      style={buttonStyle}
      danger={danger}
      disabled={isDisabled}
      title={buttonTitle ? buttonTitle(isDisabled) : ''}
      data-testid={buttonTestId}
      icon={buttonIcon}
    >
      {buttonText}
    </Button>
  );
  return isDisabled ? (
    button
  ) : (
    <Popconfirm
      placement={'topLeft'}
      title={popconfirmTitle}
      okText={popconfirmOkText ?? translations.shared.popconfirm.ok}
      onConfirm={onClickCallback}
      cancelText={popconfirmCancelText ?? translations.shared.popconfirm.cancel}
    >
      {button}
    </Popconfirm>
  );
};
