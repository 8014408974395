import React from 'react';
import { MaintenanceMessageTextKeys, translations } from '../../../../constants/translations';
import { MaintenanceMessage } from '../../../../graph/types';
import dayjs from 'dayjs';
import { UnclosableAlert } from '../UnclosableAlert';
import { camelCase } from 'lodash';

interface MaintenanceWarningBannerProps {
  maintenance: MaintenanceMessage;
}

export const MaintenanceWarningBanner: React.FC<MaintenanceWarningBannerProps> = ({ maintenance }) => {
  const isInMaintenance = dayjs(maintenance.start_date).isBefore(dayjs());
  const maintenanceMainText = translations.maintenance.maintenanceBanner.getWarningMainText(
    maintenance.start_date,
    maintenance.end_date
  );
  const maintenanceMessageTranslation =
    translations.maintenance.maintenanceBanner.messageText[
      camelCase(maintenance.message_key) as keyof MaintenanceMessageTextKeys
    ];
  const type = isInMaintenance ? 'error' : 'warning';
  const subText = maintenanceMessageTranslation.split('\n');

  return <UnclosableAlert mainText={maintenanceMainText} subText={subText} type={type} />;
};
