import { Col, Form, Input, Row, Select, Switch, Table } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { SelectProps } from 'antd/lib/select';
import { isMatch, sortBy, uniqBy } from 'lodash';
import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SaveAndResetButton } from '../../../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinnerAndNavigationWarning } from '../../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import ViewSubscriptionActions from '../../../../components/ViewSubscription/store/actions';
import { ViewSubscriptionContext } from '../../../../components/ViewSubscription/store/state';
import {
  communicationTypes,
  contactTypeConfigs,
  ContactTypeNameKey,
  ContactTypeId,
} from '../../../../constants/referenceData/contactReferenceData';
import { sendTypes } from '../../../../constants/referenceData/statementRunReferenceData';
import { PracticeSettingsId, translations } from '../../../../constants/translations';
import { FormWithFlexWrap } from '../../../../globalStyles.style';
import {
  Contact,
  ContactCommunication,
  ContactCommunicationUpsert,
  ContactType,
  ContactUpsert,
  OrganizationDto,
  PracticeDto,
  Query,
  ThirdPartyContactUpsert,
} from '../../../../graph/types';
import { statementIgnoreFieldName, taxExemptFieldName, chargeInterestFieldName } from '../../ContactForm/ContactForm';
import { SContactForm } from '../../ContactForm/Styles';
import { layout } from '../ContactDetails/ContactDetails';
import { useUpdateContactFromContactPageDto } from '../../../../hooks/useUpdateContactFromContactPage';
import { getRequiredRule, getValidEmailRule } from '../../../../util/forms';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';
import { ContactUpsertGenerator } from '../../../../classes/upsertGenerators/ContactUpsertGenerator';
import ModalWithCloseConfirm from '../../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { MaxLengthFormItem } from '../../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { GetThirdPartyValidation } from '../../../../graph/queries/thirdPartyData';
import AppSyncService from '../../../../services/AppSyncService/AppSyncService';
import { showErrorMessage } from '../../../../components/Notification/notificationUtil';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../../constants/referenceData/organizationSubscriptionReferenceData';
import { useGetReferenceData } from '../../../../hooks/ajax/referenceData/referenceDataHooks';
import { EMRRegionModal } from '../../../../components/EMRConfigurationModal/EMRRegionModal';
import { searchableSelectParams } from '../../../../constants/searchableSelectParams';
import { EMRRegionNameKeyTranslationMap } from '../../../../components/EMRConfigurationModal/emrUtil';

const sendTypeFieldName = 'sendType';
export const emailToTestId = 'emailToTestId';
export const emailCCTestId = 'emailCCTestId';
export const FittedSelect = styled(Select)<SelectProps<number>>`
  && {
    width: fit-content;
  }
`;

type ContactOptionsProps = {
  contact: Contact;
  practice: PracticeDto;
  organization: OrganizationDto;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
};

export interface ContactOptionsFormFields {
  sendType: number;
  statementIgnore: boolean;
  tax_exempt: boolean;
  chargeInterest: boolean;
  communication: ContactCommunication[];
  email?: string;
  defaultRegion?: string;
  EMR: boolean;
}

interface CommunicationTableData extends ContactCommunication {
  communication_type_label: string;
  index: number;
}

const labelWidth = 3;

const Instructions = styled.div`
  margin: 0 1em 1em;
`;

const enabledContact3pToggles = ['EMR'];

export const ContactOptions: React.FC<ContactOptionsProps> = ({
  contact,
  practice,
  organization,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}) => {
  const { dispatch: dispatchViewContact } = useContext(ViewSubscriptionContext);
  const [form] = Form.useForm<ContactOptionsFormFields>();

  const emrFlagEnabled = useLDFlag(LDFlagNames.EMR);

  const emrAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id
      ),
    [organization]
  );

  const emrEnabled = useMemo(() => emrAddon?.enabled && emrFlagEnabled, [emrAddon, emrFlagEnabled]);

  const [regionOptions, setRegionOptions] = useState<{ label: string; value: string }[]>();

  const { referenceData, dataLoading: referenceDataLoading } = useGetReferenceData(organization?.id);
  const togglableContactTypes = useMemo(
    () => referenceData?.contact_type?.filter((type) => type.contact_toggle) || [],
    [referenceData]
  );

  useEffect(() => {
    if (referenceData) {
      setRegionOptions(
        referenceData?.ref_3p_region?.map((r) => ({
          label: EMRRegionNameKeyTranslationMap[r.name_key],
          value: r.id,
        }))
      );
    }
  }, [referenceData]);

  const initialModalProps = {
    visible: false,
    checked: false,
    nameKey: '',
  };

  const [modalProps, setModalProps] = useState(initialModalProps);
  const [emrRegionModalVisible, setEmrRegionModalVisible] = useState(false);

  const enabledThirdPartyData = organization.ref_system?.third_party.filter(({ name_key }) =>
    enabledContact3pToggles.includes(name_key)
  );

  const { handleContactUpsert, isSaving, hasChanged, setHasChanged, setIsSaving } = useUpdateContactFromContactPageDto(
    practice,
    contact
  );

  const defaultOrgRegionSetting = organization.practice
    .find((p) => p.id === organization.default_practice_id)
    ?.setting?.find((s) => s.setting_id === PracticeSettingsId.EMRHomeRegion)?.value;

  const sendTypeOptions = Object.values(sendTypes).map((option) => ({
    ...option,
    'data-testid': `${option.label}-option`,
  }));

  const contactCommunication = uniqBy(contact?.communication, 'communication_type_id');

  const [selectedEmrRegionFromModal, setSelectedEmrRegionFromModal] = useState();
  const emrRegionModalValue = form.getFieldValue('defaultRegion');

  useEffect(() => {
    if (emrRegionModalValue) {
      setSelectedEmrRegionFromModal(emrRegionModalValue);
    }
  }, [emrRegionModalValue]);

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(hasChanged);
    }
  }, [handleIsEditing, hasChanged]);

  const handleOnToggleChange = (checked: boolean, title: string) => {
    const contactUpsert: ContactUpsert = {
      id: contact.id,
      record: {
        name: contact.name,
        contact_status_id: contact.contact_status_id,
        dob: contact.dob,
        created_practice_id: practice.id,
        email: contact.email,
        send_type: contact.send_type,
        statement_ignore: contact.statement_ignore,
        interest_ignore: contact.interest_ignore,
        [title]: checked,
      },
    };

    handleContactUpsert(contactUpsert, true);
  };

  const handleThirdPartyToggleChange = async (checked: boolean, nameKey: string, email?: string) => {
    const selectedThirdParty = enabledThirdPartyData?.find(({ name_key }) => name_key === nameKey);
    if (!selectedThirdParty) {
      return false;
    }

    if (checked && selectedThirdParty?.requires_email && !contact.email && !email) {
      return setModalProps({ visible: true, checked: true, nameKey });
    }

    if (checked) {
      setIsSaving(true);
      const { data } = await AppSyncService.client.query<Pick<Query, 'getThirdPartyValidation'>>({
        query: GetThirdPartyValidation,
        variables: {
          organizationId: organization.id,
          practiceId: practice.id,
          thirdPartyValidationFilter: {
            third_party_id: selectedThirdParty.id,
            email_address: email ?? contact.email,
          },
        },
      });
      if (!data?.getThirdPartyValidation?.email_address_valid) {
        setIsSaving(false);
        form.setFieldsValue({ [nameKey]: false, defaultRegion: contactEmr?.region_id || undefined });
        return showErrorMessage(translations.viewContactPage.thirdPartyEmailValidationFailed(nameKey));
      }
    }

    let thirdPartyUpsert: ThirdPartyContactUpsert[] = [];
    if (checked) {
      thirdPartyUpsert.push({
        record: {
          third_party_id: selectedThirdParty.id,
          region_id: form.getFieldValue('defaultRegion') || selectedEmrRegionFromModal,
        },
      });
    } else {
      const selectedContactThirdParty = contact.third_party?.find(
        ({ third_party_id }) => third_party_id === selectedThirdParty.id
      );
      thirdPartyUpsert = [{ id: selectedContactThirdParty?.id, void: true }] ?? [];
    }

    const contactUpsert: ContactUpsert = {
      id: contact.id,
      third_party: thirdPartyUpsert,
    };

    if (email) {
      const contactUpsertRecord = new ContactUpsertGenerator(
        { ...contact, email },
        practice.id
      ).mapExistingContactToRecordUpsert();
      contactUpsert.record = contactUpsertRecord;
    }

    await handleContactUpsert(contactUpsert, true);
    setEmrRegionModalVisible(false);
    return setModalProps(initialModalProps);
  };

  const handleContactTypeToggleChange = async (checked: boolean, contactType: ContactType) => {
    setIsSaving(true);

    const typeUpserts = [
      {
        type_id: contactType.type_id,
        void: !checked,
      },
    ];

    if (
      checked &&
      contactType.name_key === ContactTypeNameKey.Doctor &&
      !contact.type_name_keys?.includes(ContactTypeNameKey.Staff)
    ) {
      typeUpserts.push({
        type_id: ContactTypeId.Staff,
        void: false,
      });

      form.setFieldValue(ContactTypeNameKey.Staff.toString(), true);
    }

    const contactUpsert: ContactUpsert = {
      id: contact.id,
      type: typeUpserts,
    };

    await handleContactUpsert(contactUpsert, true);

    setIsSaving(false);
  };

  const handleEmailClose = (nameKey?: string) => {
    form.resetFields(['email']);

    if (nameKey) {
      form.setFieldsValue({ [nameKey]: false });
    }
    setModalProps(initialModalProps);
  };
  const options = {
    statementIgnoreValue: contact.statement_ignore,
    taxExemptValue: !!contact.tax_exempt?.length,
    chargeInterestValue: !contact.interest_ignore,
  };

  const initialCommunication = contactCommunication
    ? sortBy(contactCommunication, 'communication_type_id').map((entry) => {
        if (!entry.primary_address) {
          return { ...entry, primary_address: contact.email };
        }
        return entry;
      })
    : [
        {
          communication_type_id: communicationTypes.statementRun.value,
          send_type: 1,
          primary_address: contact.email,
        },
        {
          communication_type_id: communicationTypes.reminderRun.value,
          send_type: 1,
          primary_address: contact.email,
        },
      ];

  const thirdPartyInitialValues =
    enabledThirdPartyData?.map((data) => {
      return {
        [data.name_key]: !!contact?.third_party?.find((c) => c.third_party_id === data.id),
      };
    }) ?? [];

  const emrThirdPartyData = enabledThirdPartyData.find((data) => data.name_key === 'EMR');
  const contactEmr = contact?.third_party?.find((c) => c.third_party_id === emrThirdPartyData?.id);
  const defaultRegionValue = JSON.parse(defaultOrgRegionSetting || '{}').home_region_id;

  const initialValues = {
    [statementIgnoreFieldName]: contact.statement_ignore,
    tax_exempt: !!contact.tax_exempt?.length,
    interest_ignore: !contact.interest_ignore,
    communication: initialCommunication,
    ...thirdPartyInitialValues,
  };

  const onFinish = (values: ContactOptionsFormFields) => {
    const { communication } = values;
    let upsertCommunication: ContactCommunicationUpsert[];
    if (contactCommunication) {
      upsertCommunication = contactCommunication.map(({ id, communication_type_id }) => {
        const foundValue = values.communication.find((value) => value.communication_type_id === communication_type_id);
        return {
          id,
          record: {
            communication_type_id,
            send_type: foundValue?.send_type ?? 1,
            primary_address: foundValue?.primary_address,
            secondary_address: foundValue?.secondary_address,
          },
        };
      });
    } else {
      upsertCommunication = communication
        .filter(({ send_type }) => send_type)
        .map((value, index) => {
          return {
            record: {
              communication_type_id: data[index].communication_type_id,
              send_type: value.send_type,
              primary_address: value.primary_address,
              secondary_address: value.secondary_address,
            },
          };
        });
    }

    const contactUpsert: ContactUpsert = {
      id: contact.id,
      communication: upsertCommunication,
    };

    handleContactUpsert(contactUpsert);
  };

  const handleValueChange = (fieldValue: Store, allValues: ContactOptionsFormFields) => {
    if (
      Object.keys(fieldValue)
        .map((v) => v)
        .includes('communication')
    ) {
      setHasChanged(!isMatch(initialValues, allValues));
      dispatchViewContact(ViewSubscriptionActions.setEditing(!isMatch(initialValues, allValues)));
    }
  };

  const columns = [
    {
      title: translations.viewContactPage.communicationTable.headers.commType,
      key: 'communication_type_id',
      dataIndex: 'communication_type_id',
      width: 100,
      render: (_: string, { index, communication_type_label }: CommunicationTableData) => (
        <Form.Item name={['communication', index, 'communication_type_id']}>
          <span>{communication_type_label}</span>
        </Form.Item>
      ),
    },
    {
      title: translations.viewContactPage.communicationTable.headers.method,
      key: 'send_type',
      dataIndex: 'send_type',
      width: 100,
      render: (_: number, { index }: CommunicationTableData) => (
        <Form.Item name={['communication', index, 'send_type']} htmlFor={sendTypeFieldName}>
          <FittedSelect id={sendTypeFieldName} options={sendTypeOptions} placeholder={translations.shared.select} />
        </Form.Item>
      ),
    },
    {
      title: translations.viewContactPage.communicationTable.headers.to,
      key: 'primary_address',
      dataIndex: 'primary_address',
      width: 200,
      render: (_: string, { communication_type_id, index }: CommunicationTableData) => {
        const communicationValue = form
          .getFieldValue('communication')
          .find((value: ContactCommunication) => value.communication_type_id === communication_type_id);
        return (
          <>
            {communicationValue?.send_type === sendTypes.email.value && (
              <Form.Item
                rules={[getRequiredRule(translations.viewContactPage.communicationTable.headers.to)]}
                name={['communication', index, 'primary_address']}
                htmlFor={sendTypeFieldName}
              >
                <Input data-testid={emailToTestId} />
              </Form.Item>
            )}
          </>
        );
      },
    },
    {
      title: translations.viewContactPage.communicationTable.headers.cc,
      key: 'secondary_address',
      dataIndex: 'secondary_address',
      width: 200,
      render: (_: string, { index, communication_type_id }: CommunicationTableData) => {
        const communicationValue = form
          .getFieldValue('communication')
          .find((value: ContactCommunication) => value.communication_type_id === communication_type_id);
        return (
          <>
            {communicationValue?.send_type === sendTypes.email.value && (
              <Form.Item name={['communication', index, 'secondary_address']} htmlFor={sendTypeFieldName}>
                <Input data-testid={emailCCTestId} />
              </Form.Item>
            )}
          </>
        );
      },
    },
  ];

  const data: CommunicationTableData[] = Object.keys(communicationTypes).map((key, index) => {
    const communicationEntry = contact?.communication?.find(
      ({ communication_type_id }) =>
        communication_type_id === communicationTypes[key as keyof typeof communicationTypes].value
    );
    return {
      id: communicationEntry?.id ?? `${key}`,
      communication_type_label: communicationTypes[key as keyof typeof communicationTypes].label,
      communication_type_id: communicationTypes[key as keyof typeof communicationTypes].value,
      send_type: communicationEntry?.send_type ?? 1,
      primary_address: communicationEntry?.primary_address,
      secondary_address: communicationEntry?.secondary_address,
      index,
    };
  });

  const handleReset = useCallback(() => {
    form.resetFields();
    setHasChanged(false);
    dispatchViewContact(ViewSubscriptionActions.setEditing(false));
  }, [form, setHasChanged, dispatchViewContact]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      handleReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, handleReset, setShouldResetOnTabChange]);

  return (
    <>
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving || referenceDataLoading}
        savingMessage={isSaving ? translations.loadingComponent.saving : translations.loadingComponent.loading}
        showNavigationWarning={hasChanged}
        warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.viewContactPage.entity)}
      >
        <FormWithFlexWrap
          {...layout}
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          className='paddedForm'
          onValuesChange={handleValueChange}
          autoComplete='off'
        >
          <Col xs={24}>
            <Col offset={labelWidth}>
              <h3>{translations.viewContactPage.headers.options}</h3>
            </Col>
            <SContactForm.OptionsCol offset={labelWidth}>
              <SContactForm.OptionsFormItem
                label={translations.viewContactPage.properties.statementIgnore}
                name={statementIgnoreFieldName}
                valuePropName='checked'
              >
                <Switch
                  data-testid={statementIgnoreFieldName}
                  onChange={(checked) => handleOnToggleChange(checked, 'statement_ignore')}
                  defaultChecked={options.statementIgnoreValue}
                />
              </SContactForm.OptionsFormItem>
              <SContactForm.OptionsFormItem
                label={translations.viewContactPage.properties.taxExempt}
                name={taxExemptFieldName}
                valuePropName='checked'
              >
                <Switch
                  data-testid={taxExemptFieldName}
                  onChange={(checked) => handleOnToggleChange(checked, 'tax_exempt')}
                  defaultChecked={options.taxExemptValue}
                />
              </SContactForm.OptionsFormItem>
              <SContactForm.OptionsFormItem
                label={translations.viewContactPage.properties.chargeInterest}
                name={chargeInterestFieldName}
                valuePropName='checked'
              >
                <Switch
                  data-testid={chargeInterestFieldName}
                  onChange={(checked) => handleOnToggleChange(!checked, 'interest_ignore')}
                  defaultChecked={options.chargeInterestValue}
                />
              </SContactForm.OptionsFormItem>
              {emrEnabled &&
                thirdPartyInitialValues?.map((data) => {
                  const fieldName = Object.keys(data)[0];
                  const valueName = Object.values(data)[0];
                  return (
                    <SContactForm.OptionsFormItem
                      key={fieldName}
                      label={fieldName}
                      name={fieldName}
                      valuePropName='checked'
                    >
                      <Switch
                        data-testid={`${fieldName}`}
                        onChange={
                          fieldName === 'EMR' && !valueName
                            ? () => setEmrRegionModalVisible(true)
                            : (checked) => handleThirdPartyToggleChange(checked, fieldName)
                        }
                        defaultChecked={valueName}
                      />
                    </SContactForm.OptionsFormItem>
                  );
                })}
              {togglableContactTypes?.map((contactType) => (
                <SContactForm.OptionsFormItem
                  key={contactType.name_key}
                  label={contactTypeConfigs[contactType.name_key as ContactTypeNameKey].label}
                  name={contactType.name_key}
                  valuePropName='checked'
                >
                  <Switch
                    data-testid={`${contactType.name_key}`}
                    onChange={(checked) => handleContactTypeToggleChange(checked, contactType)}
                    disabled={
                      ContactTypeNameKey.Staff === contactType.name_key &&
                      contact.type_name_keys?.includes(ContactTypeNameKey.Doctor)
                    }
                    defaultChecked={contact.type_name_keys?.includes(contactType.name_key)}
                  />
                </SContactForm.OptionsFormItem>
              ))}
            </SContactForm.OptionsCol>
            <div>
              {contactEmr && (
                <Form.Item
                  labelCol={{ offset: 3 }}
                  name={'defaultRegion'}
                  label={translations.emrRegionModal.defaultRegionLabel}
                >
                  <Select
                    {...searchableSelectParams}
                    data-testid='defaultRegionTestId'
                    defaultValue={contactEmr.region_id ?? String(defaultRegionValue)}
                    style={{ maxWidth: 300 }}
                    placeholder={translations.emrRegionModal.selectPlaceholder}
                    onChange={(value) => {
                      form.setFieldsValue({ defaultRegion: value });
                      handleThirdPartyToggleChange(true, 'EMR');
                    }}
                  >
                    {regionOptions?.map((o) => (
                      <Select.Option key={o.value} label={o.label} value={o.value}>
                        <span>{o.label}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </Col>
          <Col xs={24}>
            <Col offset={labelWidth}>
              <h3>{translations.viewContactPage.headers.communication}</h3>
            </Col>
            <Col offset={labelWidth} span={12}>
              <Table dataSource={data} columns={columns} rowKey='id' pagination={false} />
            </Col>
          </Col>
          {hasChanged ? (
            <Row style={{ width: '100%' }}>
              <Col span={12}>
                <SaveAndResetButton onReset={handleReset} offset={labelWidth} />
              </Col>
            </Row>
          ) : null}
          <ModalWithCloseConfirm
            title={translations.viewContactPage.emailModal.title}
            open={modalProps.visible}
            onCancel={() => handleEmailClose(modalProps.nameKey)}
            isSaving={isSaving}
            onOk={async () => {
              try {
                await form.validateFields();
              } catch (e) {
                return;
              }

              handleThirdPartyToggleChange(modalProps.checked, modalProps.nameKey, form.getFieldValue('email'));
            }}
          >
            <Instructions>{translations.viewContactPage.emailModal.instruction}</Instructions>
            <MaxLengthFormItem
              validateTrigger='onBlur'
              name='email'
              label={translations.viewContactPage.details.email}
              rules={[getValidEmailRule()]}
              maxLength={1000}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>
          </ModalWithCloseConfirm>
        </FormWithFlexWrap>
      </SaveSpinnerAndNavigationWarning>
      {emrRegionModalVisible && (
        <EMRRegionModal
          onSave={() => handleThirdPartyToggleChange(true, 'EMR')}
          onClose={() => {
            form.setFieldsValue({ EMR: false, defaultRegion: undefined });
            setEmrRegionModalVisible(false);
          }}
          defaultRegionValue={String(defaultRegionValue)}
          form={form}
        />
      )}
    </>
  );
};
