import React, { useState } from 'react';
import { Button } from 'antd';
import { translations } from '../../constants/translations';
import { Contact } from '../../graph/types';
import { useGetContactsWithSearch } from '../../hooks/ajax/contact/contactHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { SelectForSearch } from '../SelectForSearch/SelectForSearch';
import { showErrorMessage } from '../Notification/notificationUtil';
import { joinPatientOrContactNameAndNumber } from '../../util/displaying';

export type ContactSelectProps = {
  onSelect: (contact: Contact) => void;
};

export const ContactSelect: React.FC<ContactSelectProps> = ({ onSelect }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [searchValue, setSearchValue] = useState<string>();
  const { contacts, contactsLoading } = useGetContactsWithSearch(organizationId, searchValue);

  const options = contacts?.map((contact) => ({ id: contact.id, value: joinPatientOrContactNameAndNumber(contact) }));

  const onSelectContact = (contactId: string) => {
    const contact = contacts?.find((contact) => contact.id === contactId);
    if (!contact) {
      showErrorMessage(translations.invoicesPage.addInvoiceModal.errorSelectingContact);
    } else {
      onSelect(contact);
    }
  };
  return (
    <div style={{ display: 'flex', marginBottom: '12px' }}>
      <SelectForSearch
        isLoading={contactsLoading}
        placeholder={translations.contactsPage.searchPlaceholder}
        setSearchValue={setSearchValue}
        options={options}
        onSelect={onSelectContact}
      />
      <Button
        onClick={() => {
          setSearchValue(undefined);
        }}
        style={{ marginLeft: '2px' }}
      >
        {translations.shared.clearButtonText}
      </Button>
    </div>
  );
};
