import { Info, InfoDto, InfoUpsert, PracticeUpsert } from '../../graph/types';
import { Store } from 'antd/lib/form/interface';
import { AddressUpsertGenerator, AddressUpsertInfo } from './AddressUpsertGenerator';
import { getInfoUpsert, getInfoUpsertDto } from './infoMappingUtil';

export class PracticeUpsertGenerator {
  private readonly initialPracticeUpsert: PracticeUpsert;
  private generatedPracticeUpsert: PracticeUpsert;

  constructor(initialPracticeUpsert: PracticeUpsert) {
    this.initialPracticeUpsert = initialPracticeUpsert;
  }

  public generateFromUpdatedValues(
    updatedValues: Store,
    addressUpsertInfos: AddressUpsertInfo[],
    existingInfo: Info[] = []
  ) {
    this.generatedPracticeUpsert = {
      id: this.initialPracticeUpsert.id,
    };
    this.addRecordToUpsertIfNeeded(updatedValues);
    this.addInfoToUpsertIfNeeded(updatedValues, existingInfo);
    this.addAddressesToUpsertIfNeeded(updatedValues, addressUpsertInfos);
    return this.generatedPracticeUpsert;
  }

  public generateFromUpdatedValuesDto(
    updatedValues: Store,
    addressUpsertInfos: AddressUpsertInfo[],
    existingInfo: InfoDto[] = []
  ) {
    this.generatedPracticeUpsert = {
      id: this.initialPracticeUpsert.id,
    };
    this.addRecordToUpsertIfNeeded(updatedValues);
    this.addInfoDtoToUpsertIfNeeded(updatedValues, existingInfo);
    this.addAddressesToUpsertIfNeeded(updatedValues, addressUpsertInfos);
    return this.generatedPracticeUpsert;
  }

  private addRecordToUpsertIfNeeded(updatedValues: Store) {
    if (
      updatedValues.record.name !== this.initialPracticeUpsert.record?.name ||
      updatedValues.record.email !== this.initialPracticeUpsert.record?.email ||
      updatedValues.record.website !== this.initialPracticeUpsert.record?.website
    ) {
      this.generatedPracticeUpsert.record = {
        ...this.initialPracticeUpsert.record,
        name: updatedValues.record.name,
        email: updatedValues.record.email,
        website: updatedValues.record.website,
      };
    }
  }

  private addInfoToUpsertIfNeeded(updatedValues: Store, existingInfo: Info[] = []) {
    if (!updatedValues.info) {
      return;
    }
    const infoUpsert: InfoUpsert[] = [];
    Object.keys(updatedValues.info).forEach((key) => {
      infoUpsert.push(...(getInfoUpsert(key, updatedValues.info[key], existingInfo) || []));
    });
    this.generatedPracticeUpsert.info = infoUpsert;
  }

  private addInfoDtoToUpsertIfNeeded(updatedValues: Store, existingInfo: InfoDto[] = []) {
    if (!updatedValues.info) {
      return;
    }
    const infoUpsert: InfoUpsert[] = [];
    Object.keys(updatedValues.info).forEach((key) => {
      infoUpsert.push(...(getInfoUpsertDto(key, updatedValues.info[key], existingInfo) || []));
    });
    this.generatedPracticeUpsert.info = infoUpsert;
  }

  private addAddressesToUpsertIfNeeded(updatedValues: Store, addressUpsertInfos: AddressUpsertInfo[]) {
    this.generatedPracticeUpsert.address = new AddressUpsertGenerator(
      this.initialPracticeUpsert.address
    ).generateFromUpdatedValues(updatedValues?.address, addressUpsertInfos);
  }
}
