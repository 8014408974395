import { Button, Layout, Modal, Result } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { matchPath, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { getRouteWithOrganizationId, mainPageSiderRoutes, routes, settingRoutingPaths } from '../../constants/routes';
import { PracticeSettings } from '../PracticeSettings/PracticeSettings';
import { MainHeader } from './MainHeader/MainHeader';
import { PageSider } from '../../components/PageSider/PageSider';
import { Patients } from '../Patients/Patients';
import { WithPractice, WithPracticeDto } from '../../components/WithPractice/WithPractice';
import { CreateUser } from '../Users/CreateUser/CreateUser';
import { Users } from '../Users/Users';
import { Contacts } from '../Contacts/Contacts';
import { Services } from '../Services/Services';
import { EmailSettings } from '../EmailSettings/EmailSettings';
import { Invoices } from '../Invoices/Invoices';
import {
  useGetMaintenance,
  useGetOrganization,
  useGetOrganizationDTO,
} from '../../hooks/ajax/organization/organizationHooks';
import { Loading } from '../../components/Loading/Loading';
import { UserSettings } from '../Users/UserSettings/UserSettings';
import { DoctorOffice } from '../DoctorOffice/DoctorOffice';
import { RapidBillingContainer } from '../RapidBilling/RapidBillingContainer';
import { useUserpilot } from '../../hooks/route/userpilotHooks';
import { RegistrationSteps } from '../Registration/Registration';
import { useGetCurrentUserWithOrganization } from '../../hooks/ajax/user/userHooks';
import { AdminRedirection } from '../Admin/AdminRedirection';
import CardConnectSettings from '../CardConnectSettings/CardConnectSettings';
import SubscriptionSettings from '../SubscriptionSettings/SubscriptionSettings';
import { ReferenceData } from '../ReferenceData/ReferenceData';
import { MaintenanceMessage, Organization } from '../../graph/types';
import StatementRuns from '../StatementRuns/StatementRuns';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { translations } from '../../constants/translations';
import { AdminSubscription } from '../Admin/AdminSubscriptions/AdminSubscriptions';
import { AdminRoute } from '../../components/AdminRoute/AdminRoute';
import { DataConversion } from '../DataConversion/DataConversion';
import ReportsPage from '../GeneralReports/GeneralReports';
import InterestRuns from '../InterestRuns/InterestRuns';
import InterestSettings from '../InterestSettings/InterestSettings';
import BillingHistory from '../BillingHistory/BillingHistory';
import ProtectedUserRoute from '../../components/ProtectedUserRoute/ProtectedUserRoute';
import { UserPermissionNameKey } from '../../constants/referenceData/userPermissionReferenceData';
import { LocalDatabaseDataWrapper } from '../../services/LocalDatabaseService/LocalDatabaseDataWrapper';
import { useLogout } from '../../hooks/authHooks';
import { ViewSubscriptionContextProvider } from '../../components/ViewSubscription/store/state';
import { useOrganizationReplication } from '../../hooks/localDatabaseHooks';
import { useOffline } from '../../util/offline/offlineUtil';
import { UtilsContext } from '../../components/UtilsProvider/store/state';
import { setOrganizationIdAction } from '../../components/UtilsProvider/store/actions';
import { PinModal } from '../../components/PinModal/PinModal';
import { useIdleTimer } from '../../hooks/useIdleTimer';
// import { SyncErrorLog } from '../SyncErrorLog/SyncErrorLog';
import { ReportSettings } from '../ReportSettings/ReportSettings';
import ReminderRuns from '../ReminderRuns/ReminderRuns';
import { ReportViewer } from '../../components/ActiveReportViewer/ReportViewer';
import { Approvals } from '../Approvals/Approvals';
import { useLDFlag, useLDIdentify } from '../../hooks/useLDHooks';
import { AddOns } from '../AddOns/AddOns';
import { Dashboard } from '../Dashboard/Dashboard';
import { useRedirectRoute } from '../../hooks/useRedirectRoute';
import { CurrencySettings } from '../CurrencySettings/CurrencySettings';
import { LDFlagNames } from '../../constants/launchDarkly';
import { ThirdPartyHistory } from '../ThirdPartyHistory/ThirdPartyHistory';
import { LabManagementOverview } from '../LabManagement/LabManagementOverview/LabManagementOverview';
import styled from 'styled-components';
import { ButtonProps } from 'antd/es/button';
import { LabManagementContextProvider } from '../LabManagement/store/state';
import { StripeConfirmation } from '../StripeConfirmation/StripeConfirmation';
import StripeRegistration from '../StripeRegistration/StripeRegistration';
import { OfflineDiagnostics } from '../OfflineDiagnostics/OfflineDiagnostics';
import { ReportPayments } from '../ReportPayments/ReportPayments';
import { Support } from '../Support/Support';
import { FinancialPeriods } from '../FinancialPeriods/FinancialPeriods';
import { useUserContext } from '../../contexts/user/state';
import { setUser } from '../../contexts/user/action';
import { OrganizationContext } from '../../contexts/organization/state';
import { setOrganization } from '../../contexts/organization/action';
import { ServicesRenderedContextProvider } from '../../components/ServiceRendered/store/state';
import { useOrganizationSubscription } from '../../hooks/organizationSubscriptionHooks';
import { AdditionalOrganizationField } from '../../graph/queries/organizations';
import { DepositRuns } from '../DepositRuns/DepositRuns';
import { datadogRum } from '@datadog/browser-rum';

dayjs.extend(isSameOrBefore);

const { Content } = Layout;

export const SpacedCenteredButton = styled(Button)<ButtonProps>`
  margin: 1rem auto;
  width: 100%;
`;

const FullLayout = styled(Layout)`
  background: white;
  min-height: 100vh;
  overflow: auto;
`;

const ContentLayout = styled(Layout)`
  transition: margin-left 0.2s ease;
  background: transparent;
`;

const ScrollingContent = styled(Content)`
  overflow: auto;
`;

const checkMaintenance = (maintenance: MaintenanceMessage | null | undefined) => {
  if (!maintenance) {
    return undefined;
  }

  const maintenanceEnd = dayjs(maintenance.end_date);
  if (maintenanceEnd.isAfter(dayjs())) {
    return maintenance;
  }

  return undefined;
};

const hasMaintenanceStarted = (maintenance: MaintenanceMessage) => {
  const maintenanceStart = dayjs(maintenance.start_date);
  return maintenanceStart.isSameOrBefore(dayjs());
};

export function Main() {
  const { enabledAndOffline } = useOffline();
  const replicationComplete = useOrganizationReplication();

  if (enabledAndOffline && !replicationComplete) {
    return <Loading labelText={translations.offline.offlineWithoutReplication} />;
  }

  return <MainComp />;
}

function MainComp() {
  const { dispatch: userContextDispatch } = useUserContext();
  const { dispatch: orgContextDispatch } = useContext(OrganizationContext);

  const history = useHistory();
  const logout = useLogout();
  const { user, userLoading } = useGetCurrentUserWithOrganization();
  const match = matchPath<{ organizationId: string }>(history.location.pathname, { path: routes.baseOrganization });
  const organizationId = match?.params?.organizationId ?? user?.organization?.[0]?.organization_id ?? '';

  const { organization: orgWithSubscription } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.AddressDemographics
  );

  const enabledAddOns = useLDFlag(LDFlagNames.Addons);
  const enabledThirdPartyData = useLDFlag(LDFlagNames.ThirdPartyData);
  const enabledLabManagement = useLDFlag(LDFlagNames.LabManagement);

  const { organization, organizationLoading, organizationError, organizationRefetch } =
    useGetOrganizationDTO(organizationId);
  useOrganizationSubscription(organizationId, organizationRefetch);

  const { dispatch } = useContext(UtilsContext);
  const { enabled: offlineModeEnabled, requirePin, isOnline } = useOffline();
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [showOfflineModal, setOfflineModalOpen] = useState(false);
  const [pathOrganizationId, setPathOrganizationId] = useState<string>();
  useIdleTimer();
  const hasPracticeWithAddress =
    organization?.practice.length && organization.practice.some((practice) => practice.address.length);
  const redirectRoute = useRedirectRoute(organizationId ?? pathOrganizationId);
  const shouldShowRedirectButton =
    offlineModeEnabled &&
    (!!matchPath(history.location.pathname, { path: routes.practice }) ||
      !!matchPath(history.location.pathname, { path: routes.userSettings }));

  useEffect(() => {
    orgContextDispatch(setOrganization(organization!));
  }, [organization, orgContextDispatch]);

  useEffect(() => {
    if (user) {
      userContextDispatch(setUser(user));
    }
  }, [user, userContextDispatch]);

  useEffect(() => {
    dispatch(setOrganizationIdAction(organizationId));
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (user) {
      setShowPinModal(!user.pin && offlineModeEnabled);
    }
  }, [user, offlineModeEnabled]);

  useEffect(() => {
    if (!offlineModeEnabled || (offlineModeEnabled && shouldShowRedirectButton)) {
      setOfflineModalOpen(!isOnline);
    }
  }, [isOnline, offlineModeEnabled, shouldShowRedirectButton]);

  useEffect(() => {
    if (organizationId) {
      setPathOrganizationId(organizationId);
    }
  }, [organizationId]);

  useLDIdentify();

  const dataDogRUMEnabled = useLDFlag(LDFlagNames.DataDogRUM);

  useEffect(() => {
    if (dataDogRUMEnabled) {
      datadogRum.setUser({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        subscriptionLevel: user?.organization?.[0].subscription_level_id,
        organization_id: user?.organization_id,
      });
    }
  }, [dataDogRUMEnabled, user]);

  useUserpilot(organization as any as Organization);

  const { maintenanceMessage } = useGetMaintenance(organization?.id ?? '');

  const userIsGlobalAdmin = user?.global_admin;

  if (organizationError) {
    return (
      <Result
        status='warning'
        title={translations.mainPage.errorPageTitle}
        subTitle={translations.mainPage.errorPageSubtitle}
        extra={<Button onClick={logout}>{translations.mainPage.logout}</Button>}
      />
    );
  }

  if (userLoading || organizationLoading || (!userIsGlobalAdmin && !orgWithSubscription?.subscription)) {
    return <Loading height={'90vh'} />;
  }

  const maintenance = checkMaintenance(maintenanceMessage);

  if (user?.pin && requirePin) {
    history.push({ pathname: routes.pin, state: { from: history.location } });
    return null;
  }

  if (!userIsGlobalAdmin) {
    if (maintenance?.redirect_url && hasMaintenanceStarted(maintenance)) {
      history.push({ pathname: maintenance.redirect_url, state: { organizationId: organization?.id } });
      return null;
    }

    if (!hasPracticeWithAddress && isOnline) {
      history.push({ pathname: routes.signup, state: { initialStep: RegistrationSteps.Practice } });
      return null;
    }

    const organizationSubscriptionProblem = orgWithSubscription?.subscription?.problem ?? true;
    if (organizationSubscriptionProblem) {
      history.push({ pathname: routes.signup, state: { initialStep: RegistrationSteps.Subscription } });
      return null;
    }
  }

  return (
    <Switch>
      {!organizationId && userIsGlobalAdmin ? (
        <Redirect from={routes.root} to={routes.adminRedirection} exact />
      ) : (
        <Redirect from={routes.root} to={redirectRoute} exact />
      )}
      <Route path={routes.baseOrganization}>
        <FullLayout>
          <MainHeader maintenance={maintenance} />
          <LocalDatabaseDataWrapper>
            <ContentLayout>
              <Switch>
                <ProtectedUserRoute permissionNameKey={UserPermissionNameKey.PERM_SET_ALL} path={routes.practice}>
                  <PageSider routeKey='practice' />
                  <ScrollingContent>
                    <Switch>
                      <Route path={settingRoutingPaths.practiceSettings.path}>
                        <WithPracticeDto Component={PracticeSettings} />
                      </Route>
                      <Route path={settingRoutingPaths.user.path} component={Users} />
                      <Route path={settingRoutingPaths.services.path} component={Services} />
                      <Route path={settingRoutingPaths.emailSettings.path} component={EmailSettings} />
                      <Route path={routes.cardConnectSettings} component={CardConnectSettings} />
                      <Route path={routes.referenceDataTypes} component={ReferenceData} />
                      <Redirect from={routes.referenceData} to={`${routes.referenceData}/species`} />
                      <Route path={routes.billingHistoryPage} component={BillingHistory} />
                      <ProtectedUserRoute
                        permissionNameKey={UserPermissionNameKey.PERM_SET_SUBSCRIPTION}
                        path={settingRoutingPaths.subscription.path}
                        component={SubscriptionSettings}
                      />
                      {enabledAddOns && (
                        <ProtectedUserRoute
                          permissionNameKey={UserPermissionNameKey.PERM_SET_SUBSCRIPTION}
                          path={settingRoutingPaths.addOns.path}
                          component={AddOns}
                        />
                      )}
                      <Route path={settingRoutingPaths.referenceData.path} component={ReferenceData} />
                      <AdminRoute path={settingRoutingPaths.adminSubscription.path} component={AdminSubscription} />
                      <Route path={settingRoutingPaths.dataConversion.path} component={DataConversion} />
                      <Route path={settingRoutingPaths.interestSettings.path} component={InterestSettings} />
                      {/*<Route path={settingRoutingPaths.syncErrorLog.path} component={SyncErrorLog} /> */}
                      <Route path={settingRoutingPaths.currencySettings.path} component={CurrencySettings} />
                      <Route
                        path={routes.stripeConfirmation}
                        component={() => <WithPractice withInfoAndPhone Component={StripeConfirmation} />}
                      />
                      <Route
                        path={routes.stripeRegistration}
                        component={() => <WithPractice withInfoAndPhone Component={StripeRegistration} />}
                      />
                      <Route path={routes.reportViewerPractice}>
                        <ReportViewer />
                      </Route>
                    </Switch>
                  </ScrollingContent>
                </ProtectedUserRoute>
                <Route path={routes.support}>
                  <PageSider routeKey='support' />
                  <ScrollingContent>
                    <Support />
                  </ScrollingContent>
                </Route>
                <Route path={routes.userSettings}>
                  <PageSider routeKey='practice' />
                  <ScrollingContent>
                    <UserSettings />
                  </ScrollingContent>
                </Route>
                <Route path={routes.baseOrganization}>
                  <PageSider routeKey='main' />
                  <ScrollingContent>
                    <Switch>
                      <ProtectedUserRoute
                        permissionNameKey={UserPermissionNameKey.PERM_DSB_ALL}
                        path={mainPageSiderRoutes.dashboard.path}
                        component={() => <Dashboard />}
                      />
                      <Route path={mainPageSiderRoutes.doctorOffice.path}>
                        <ViewSubscriptionContextProvider>
                          <DoctorOffice />
                        </ViewSubscriptionContextProvider>
                      </Route>
                      <Route path={mainPageSiderRoutes.contacts.path} component={Contacts} />
                      <Route path={mainPageSiderRoutes.patients.path} component={Patients} />
                      <Route path={mainPageSiderRoutes.invoices.path} component={Invoices} />
                      <Route path={routes.createUser}>
                        <CreateUser />
                      </Route>
                      <Route path={routes.rapidBilling}>
                        <ServicesRenderedContextProvider>
                          <RapidBillingContainer />
                        </ServicesRenderedContextProvider>
                      </Route>
                      <Route path={mainPageSiderRoutes.reports.menu!.statementRuns.path} component={StatementRuns} />
                      <Route path={mainPageSiderRoutes.reports.menu!.interestRuns.path} component={InterestRuns} />
                      <Route path={routes.reportViewer}>
                        <ReportViewer />
                      </Route>
                      <ProtectedUserRoute
                        permissionNameKey={UserPermissionNameKey.PERM_RPT_ALL}
                        path={mainPageSiderRoutes.reports.menu!.generalReports.path}
                        component={ReportsPage}
                      />
                      <Route path={mainPageSiderRoutes.reports.menu!.reportPayments.path} component={ReportPayments} />
                      <Route path={mainPageSiderRoutes.reports.menu!.reportSettings.path} component={ReportSettings} />
                      <Route path={mainPageSiderRoutes.reports.menu?.reminderRuns.path} component={ReminderRuns} />
                      <Route
                        path={mainPageSiderRoutes.thirdPartyData.menu!.approvals.path}
                        component={(props: any) => <>{enabledThirdPartyData && <Approvals {...props} />}</>}
                      />
                      <Route
                        path={mainPageSiderRoutes.thirdPartyData.menu!.history.path}
                        component={(props: any) => <>{enabledThirdPartyData && <ThirdPartyHistory {...props} />}</>}
                      />
                      <Route
                        path={mainPageSiderRoutes.lab.menu!.labManagement.path}
                        component={() => (
                          <>
                            {enabledLabManagement && (
                              <LabManagementContextProvider>
                                <WithPractice Component={LabManagementOverview} />
                              </LabManagementContextProvider>
                            )}
                          </>
                        )}
                      />
                      <Route
                        path={mainPageSiderRoutes.financials.menu!.financialPeriods.path}
                        component={FinancialPeriods}
                      />
                      <Route path={mainPageSiderRoutes.financials.menu!.depositRuns.path} component={DepositRuns} />
                      <Route path={routes.offlineDiagnostics} component={OfflineDiagnostics} />
                    </Switch>
                  </ScrollingContent>
                </Route>
              </Switch>
            </ContentLayout>
            {showPinModal && <PinModal onClose={() => setShowPinModal(false)} />}
            {showOfflineModal && (
              <Modal
                title={translations.shared.offlineModalTitle}
                closable={false}
                maskClosable={false}
                footer={null}
                open
              >
                <span>{translations.shared.offlineModalDescription}</span>
                {shouldShowRedirectButton && (
                  <SpacedCenteredButton
                    type='primary'
                    onClick={() => {
                      history.push({ pathname: getRouteWithOrganizationId(routes.contacts, organizationId) });
                      setOfflineModalOpen(false);
                    }}
                  >
                    {translations.mainPage.redirectToContacts}
                  </SpacedCenteredButton>
                )}
              </Modal>
            )}
          </LocalDatabaseDataWrapper>
        </FullLayout>
      </Route>
      <Route path={routes.adminRedirection}>
        <AdminRedirection />
      </Route>
      <Redirect from='*' to={routes.root} />
    </Switch>
  );
}
