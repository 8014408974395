import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Contact, Patient, PatientRelatedUpsert, Query, RelatedContactEntryCurrent } from '../../../../../graph/types';
import { Loading } from '../../../../../components/Loading/Loading';
import {
  addEmptyEntriesForMissingTypes,
  mapToAddNewRelationshipUpsert,
  mapToEditExistingRelationshipUpsert,
} from './patientRelationshipUtil';
import { PatientRelationshipTable } from './PatientRelationshipTable/PatientRelationshipTable';
import { translations } from '../../../../../constants/translations';
import { SelectContactModal } from '../../../../../components/SelectContactModal/SelectContactModal';
import {
  usePatientOfflineUpdate,
  useUpdatePatientWithRelationshipData,
} from '../../../../../hooks/ajax/patients/patientHooks';
import { showErrorMessage, showSuccessMessage } from '../../../../../components/Notification/notificationUtil';
import { RelationshipContext } from './store/state';
import {
  selectContactTypeId,
  selectModalMode,
  selectPreviousRelationship,
  selectPreviousRelationshipContactId,
  selectShowModal,
} from './store/selectors';
import { closeRelationshipModalAction } from './store/actions';
import { relationshipModalAddMode, relationshipModalEditMode } from './store/reducer';
import { useOffline } from '../../../../../util/offline/offlineUtil';
import { ViewSubscriptionContext } from '../../../../../components/ViewSubscription/store/state';
import ViewSubscriptionActions from '../../../../../components/ViewSubscription/store/actions';
import AppSyncService from '../../../../../services/AppSyncService/AppSyncService';
import { GetThirdPartyValidation } from '../../../../../graph/queries/thirdPartyData';
import {
  contactTypeConfigs,
  ContactTypeId,
  ContactTypeNameKey,
  getContactStatusNameKeyFromId,
  thirdPartyContactTypes,
} from '../../../../../constants/referenceData/contactReferenceData';
import { ServiceThirdPartyId, ThirdPartyLabels } from '../../../../../constants/referenceData/serviceReferenceData';
import { useLDFlag } from '../../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../../constants/launchDarkly';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../../../constants/referenceData/organizationSubscriptionReferenceData';
import { PatientContactRelationTypeNameKey } from '../../../../../constants/referenceData/patientReferenceData';
import { upsertDateFormat } from '../../../../../constants/formats';
import { useOrganizationContext } from '../../../../../contexts/organization/state';
import { useGetContactType } from '../../../../../hooks/ajax/contact/contactHooks';

interface PatientRelationshipProps extends PropsWithChildren<unknown> {
  patient: Patient;
  isSaving?: boolean;
  setIsSaving: (isSaving: boolean) => void;
  patientRefetch?: () => void;
  relationshipFilters?: {
    relationshipId: ContactTypeId;
    relationshipName: ContactTypeNameKey;
  };
}

export type RelationshipEmptyEntry = { contact_type_id: string };
export type RelationshipTableEntry = RelatedContactEntryCurrent | RelationshipEmptyEntry;

export const PatientRelationship: React.FC<PatientRelationshipProps> = ({
  setIsSaving,
  patient,
  isSaving,
  relationshipFilters,
}) => {
  const {
    state: { organization },
  } = useOrganizationContext();

  const organizationId = patient.organization_id;
  const { state, dispatch } = useContext(RelationshipContext);
  const { dispatch: dispatchViewPatient } = useContext(ViewSubscriptionContext);
  const { contactTypes } = useGetContactType();
  const [updatePatient] = useUpdatePatientWithRelationshipData();
  const { enabledAndOffline } = useOffline();
  const offlineUpdate = usePatientOfflineUpdate(patient.id);

  const practiceId = organization?.default_practice_id ?? '';
  const emrFlagEnabled = useLDFlag(LDFlagNames.EMR);

  const emrAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id
      ),
    [organization]
  );

  const emrEnabled = useMemo(() => emrAddon?.enabled && emrFlagEnabled, [emrAddon, emrFlagEnabled]);

  const relationships = useMemo(() => patient.related_current || [], [patient]);

  const [relationshipRows, setRelationshipRows] = useState<RelationshipTableEntry[]>([]);

  useEffect(() => {
    if (contactTypes && contactTypes.length > 0) {
      let filteredContactTypes = contactTypes;
      if (!emrEnabled) {
        filteredContactTypes = contactTypes.filter((x) => x.name_key !== PatientContactRelationTypeNameKey.EMR);
      }
      const newRelationshipRows = addEmptyEntriesForMissingTypes(filteredContactTypes, relationships);
      setRelationshipRows(newRelationshipRows);
    }
  }, [relationships, contactTypes, emrEnabled]);

  useEffect(() => {
    dispatchViewPatient(ViewSubscriptionActions.setEditing(state.showModal));
  }, [dispatchViewPatient, state.showModal]);

  const selectedRelationshipFilter = useMemo(
    () =>
      state.contactTypeId === relationshipFilters?.relationshipId && relationshipFilters
        ? [relationshipFilters?.relationshipName]
        : [],
    [state.contactTypeId, relationshipFilters]
  );

  if (!contactTypes) {
    return <Loading />;
  }

  const closeAndClearModal = () => {
    dispatch(closeRelationshipModalAction());
  };

  const showErrorMessageAndCloseModal = () => {
    setIsSaving(false);
    showErrorMessage(translations.shared.saveErrorMessage);
    closeAndClearModal();
  };

  const sendRelationshipUpsert = async (relationshipUpsert: PatientRelatedUpsert) => {
    try {
      closeAndClearModal();
      const patientUpsert = { id: patient.id, related: [relationshipUpsert] };
      if (!enabledAndOffline) {
        await updatePatient({
          variables: {
            patient: patientUpsert,
            organizationId,
          },
        });
      } else {
        await offlineUpdate?.(patientUpsert);
      }
      showSuccessMessage(translations.shared.saveSuccessMessage);
    } catch (e) {
      showErrorMessage((e as Error).message || translations.shared.saveErrorMessage);
    }
  };

  const onSelectContact = async (contact: Contact) => {
    setIsSaving(true);
    const selectedContactTypeId = selectContactTypeId(state);
    const changeMode = selectModalMode(state);

    if (!selectedContactTypeId) {
      showErrorMessageAndCloseModal();
      return;
    }

    if (thirdPartyContactTypes.includes(selectedContactTypeId as unknown as ContactTypeId)) {
      const nameKey = getContactStatusNameKeyFromId(selectedContactTypeId as unknown as ContactTypeId);
      const thirdPartyId = contactTypeConfigs[nameKey as ContactTypeNameKey].thirdPartyReference;

      const { data } = await AppSyncService.client.query<Pick<Query, 'getThirdPartyValidation'>>({
        query: GetThirdPartyValidation,
        variables: {
          organizationId,
          practiceId,
          thirdPartyValidationFilter: {
            third_party_id: thirdPartyId,
            email_address: contact.email,
          },
        },
      });

      if (!data?.getThirdPartyValidation?.email_address_valid) {
        setIsSaving(false);
        showErrorMessage(
          translations.viewContactPage.thirdPartyEmailValidationFailed(
            ThirdPartyLabels[thirdPartyId as ServiceThirdPartyId]
          )
        );
        return;
      }
    }

    let relationshipUpsert: PatientRelatedUpsert;

    if (changeMode === relationshipModalAddMode) {
      relationshipUpsert = mapToAddNewRelationshipUpsert(contact, selectedContactTypeId, upsertDateFormat);
    } else if (changeMode === relationshipModalEditMode) {
      const previousRelationship = selectPreviousRelationship(state);
      if (!previousRelationship) {
        showErrorMessageAndCloseModal();
        return;
      }
      relationshipUpsert = mapToEditExistingRelationshipUpsert(
        contact,
        selectedContactTypeId,
        previousRelationship,
        upsertDateFormat
      );
    } else {
      showErrorMessageAndCloseModal();
      return;
    }
    await sendRelationshipUpsert(relationshipUpsert);
    setIsSaving(false);
  };

  const contactOfSelectedRelationship = selectPreviousRelationshipContactId(state);
  return (
    <>
      <PatientRelationshipTable
        contactTypeRefs={contactTypes || []}
        relationshipTableEntries={relationshipRows}
        sendRelationshipUpsert={sendRelationshipUpsert}
        setIsSaving={setIsSaving}
      />
      <SelectContactModal
        title={translations.patientPage.relationships.modalTitle}
        visible={selectShowModal(state)}
        onCancel={closeAndClearModal}
        selectContact={onSelectContact}
        excludedIds={contactOfSelectedRelationship ? [contactOfSelectedRelationship] : []}
        isSaving={isSaving}
        selectedRelationshipFilter={selectedRelationshipFilter}
      />
    </>
  );
};
