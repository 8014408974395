/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY DIRECTLY.
 */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ReferenceData = {
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type OrganizationReferenceData = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type NoteInterface = {
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
  date: Scalars['String'];
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  preview?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type ElectronicPaymentResultInterface = {
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['String']>;
};

export type ElasticDocumentProperties = {
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  updated: Scalars['String'];
};

export type RptPracticeHeaderInterface = {
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  practice_name: Scalars['String'];
  practice_address_1: Scalars['String'];
  practice_address_2?: Maybe<Scalars['String']>;
  practice_address_3?: Maybe<Scalars['String']>;
  practice_city: Scalars['String'];
  practice_provstate: Scalars['String'];
  practice_postal_zip: Scalars['String'];
  practice_country?: Maybe<Scalars['String']>;
  practice_phone?: Maybe<Scalars['String']>;
  practice_tax_id?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  currency_setting?: Maybe<OrganizationCurrencySetting>;
};

export type Query = {
  __typename?: 'Query';
  getUsersPg: Array<User>;
  getCurrentUser: User;
  getUserOrganizationCardsPg: UserOrganizationCards;
  getPractice: Practice;
  getInterestRunsPg?: Maybe<Array<InterestRun>>;
  getOrganizations: Array<Organization>;
  getOrganizationsList: Array<OrganizationList>;
  getOrganization: Organization;
  getOrganizationDTOPg: OrganizationDto;
  getOrganizationDTOEs: OrganizationDto;
  getReferenceData?: Maybe<SystemReferenceDataTypes>;
  getContactEs: Contact;
  getContactsEs: Array<Contact>;
  getContactPatientsEs: Array<Patient>;
  getContactText: InfoText;
  getContactCommunicationLog?: Maybe<Array<ContactCommunicationLog>>;
  getContactTexts?: Maybe<Array<InfoText>>;
  getPatientEs: Patient;
  getPatientsEs?: Maybe<Array<Patient>>;
  getPatientText: InfoText;
  getNote: NoteWithHistory;
  getNotes: Array<Note>;
  getFiles: Array<File>;
  getReminders: Array<Reminder>;
  getPrescriptionsFlattened: Array<PrescriptionFlattened>;
  getPrescriptions: Array<Prescription>;
  getPrescriptionFills: Array<PrescriptionFill>;
  getPrescriptionFillsAvailable: Array<PrescriptionFlattened>;
  getServicesPg?: Maybe<Array<Service>>;
  getBundledServicesPg?: Maybe<Array<Service>>;
  getServicesEs: Array<Service>;
  getServiceText: InfoText;
  getServiceTexts?: Maybe<Array<InfoText>>;
  getServicesRendered: Array<ServiceRendered>;
  getServicesRendered3pApprovals?: Maybe<Array<ServiceRendered3pApproval>>;
  getServicesRendered3pHistory?: Maybe<Array<ServiceRendered3pHistory>>;
  getServiceRendered: ServiceRendered;
  getServiceActivities: Array<ServiceActivity>;
  getInvoiceContext: InvoiceContext;
  getInvoicesEs: Array<Invoice>;
  getInvoiceText?: Maybe<InfoText>;
  getInvoicesPg?: Maybe<Array<Invoice>>;
  getLedgersEs?: Maybe<Array<Ledger>>;
  getLedgersPg?: Maybe<Array<Ledger>>;
  getLedgerText: InfoText;
  getUserSettings: Array<Setting>;
  getStatementRuns?: Maybe<Array<StatementRun>>;
  getStatementRun: StatementRun;
  getReminderBatchList: Array<ReminderBatch>;
  getReminderBatch: Array<ReminderBatchDetail>;
  getDataConversion?: Maybe<DataConversion>;
  getSoftwareTypes: Array<SoftwareType>;
  getDataConversionFileTypes: Array<DataConversionFileType>;
  getReports: Array<Maybe<Report>>;
  getReportParameters: Array<Maybe<ReportParameters>>;
  getReportParameterData: Scalars['String'];
  getJob: Job;
  getOfflineUpsertErrors: Array<OfflineUpsertResponse>;
  globalSearch?: Maybe<Array<GlobalSearchResult>>;
  getLabRequests: Array<LabRequest>;
  getLabRequest: Array<LabRequest>;
  getBatchLabRequests?: Maybe<Array<LabRequest>>;
  getLabClass: Array<LabClass>;
  getLabDevices: Array<LabDevice>;
  getLabRefServices?: Maybe<Array<LabRefService>>;
  getLabRefBreeds?: Maybe<Array<LabRefBreed>>;
  getLabRefGenders?: Maybe<Array<LabRefGender>>;
  getLabRefSpecies?: Maybe<Array<LabRefSpecies>>;
  getLabGenderMapping?: Maybe<Array<LabGenderMapping>>;
  getLabSpeciesMapping: Array<LabSpeciesMapping>;
  getLabBreedMapping: Array<LabBreedMapping>;
  getLabServiceMapping: Array<LabServiceMapping>;
  getMetrics: Metrics;
  getLabResults: Array<LabResultRecord>;
  getLabResultStatus?: Maybe<LabResultStatusMsg>;
  getCurrencyDefaults: Array<CurrencyDefaultsReferenceData>;
  getThirdPartyRefGenders?: Maybe<Array<ThirdPartyGender>>;
  getLabSettingsAntech?: Maybe<LabSettingsAntech>;
  getLabManagementAntech: LabManagementAntech;
  getLabSettingsVetConnect?: Maybe<LabSettingsVetConnect>;
  getUnassignedLabs?: Maybe<Array<UnassignedLabResults>>;
  getThirdPartyValidation?: Maybe<ThirdPartyValidation>;
  getIvlsDevices?: Maybe<Array<IvlsDevice>>;
  getElectronicPayments?: Maybe<Array<ElectronicPayment>>;
  getHISAPatients?: Maybe<Array<HisaPatient>>;
  getHISABillingCategories?: Maybe<Array<ThirdPartyReferenceDataRequirements>>;
  getHISAVetValidation: HisaVetValidationResult;
  getFinancialPeriods?: Maybe<Array<FinancialPeriod>>;
  getDepositRun?: Maybe<Array<DepositRun>>;
  getUrlForFileDownload: FileS3Url;
  getUrlForFileUpload: FileS3Url;
  getElectronicPaymentResult: ElectronicPaymentResult;
  getStripePaymentIntents?: Maybe<Array<StripePaymentIntent>>;
  getStripePayouts: StripePayout;
  getStripeBalanceTransactions?: Maybe<Array<StripeBalanceTransaction>>;
  getStripeBalanceSummary: StripeBalance;
  getStripePaymentIntentDetails: StripePaymentIntentDetails;
  getStripePayoutDetails: StripePayoutDetailsResponse;
  getInvoiceReport: ReportResponse;
  getOrganizationSubscriptionInvoiceReport: ReportResponse;
  getReport: ReportResponse;
  getStatementReport: ReportResponse;
  getPatientHistoryReport: ReportResponse;
  getPatientVaccineReport: ReportResponse;
  getPaymentReceiptReport: ReportResponse;
  rptGetDeposit: RptDeposit;
  rptGetProduction: RptProduction;
  rptGetStatements: RptStatement;
  rptGetControlledDrugLog: RptControlledDrugLog;
  rptGetGeneralLedger: RptGeneralLedger;
  rptGetInvoiceActivity: RptInvoiceActivity;
  rptGetJournalEntry: RptJournalEntry;
  rptGetDailyInvoiceActivity: RptDailyInvoiceActivity;
  rptGetInvoiceActivityByTrainer: RptInvoiceActivityByTrainer;
  rptGetLedgerEntry: RptLedgerEntry;
  rptGetAR: RptAr;
  rptGetCommunicationLog: RptCommunicationLog;
  rptGetContactReceipt: RptContactReceipt;
  rptGetNewContactsAndPatients: RptNewContactsAndPatients;
  rptGetPatientProcedureByContact: RptPatientProcedureByContact;
  rptGetContactPayment: RptContactPayment;
  rptGetPatientProcedure: RptPatientProcedure;
  rptGetPayments: RptPayments;
  rptGetSubscriptionPayments: RptSubscriptionPayments;
  rptGetInvoice: RptInvoice;
  rptGetSubscriptions: RptSubscriptions;
  rptGetTax: RptTax;
  rptGetTopSales: RptTopSales;
  rptGetRemindersByContact: RptReminderByContact;
  rptGetPortalReceipt: RptPortalReceipt;
  rptGetVaccineByContact: RptVaccineByContact;
  rptGetVaccineActivity: RptVaccineActivity;
  rptGetPatientVaccine: RptPatientVaccine;
  rptGetSubscriptionInvoice: RptSubscriptionsInvoice;
  rptGetReminderLetter: RptReminderLetter;
  rptGetPatientHistory: RptPatientHistory;
  getQuickbooksAccounts?: Maybe<QuickbooksAccountsResponse>;
  getQuickbooksTaxRates?: Maybe<QuickbooksTaxRatesResponse>;
  getQuickbooksPaymentTypes?: Maybe<QuickbooksGetPaymentTypesResponse>;
  getChatBotAuthToken?: Maybe<ChatBotGetAuthTokenResponse>;
};

export type QueryGetUsersPgArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryUserOrganizationFilter>;
};

export type QueryGetUserOrganizationCardsPgArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryUserOrganizationCardFilter>;
};

export type QueryGetPracticeArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetInterestRunsPgArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<InterestRunFilterOptions>;
};

export type QueryGetOrganizationsArgs = {
  filter?: Maybe<FilterOptions>;
};

export type QueryGetOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetOrganizationDtoPgArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetOrganizationDtoEsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetReferenceDataArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetContactEsArgs = {
  organizationId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type QueryGetContactsEsArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  sort: ElasticQuerySort;
  queryString?: Maybe<ElasticQueryString>;
  searchAfter?: Maybe<Array<Scalars['ID']>>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type QueryGetContactPatientsEsArgs = {
  organizationId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type QueryGetContactTextArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetContactCommunicationLogArgs = {
  organizationId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type QueryGetContactTextsArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<HistorySimpleFilter>;
};

export type QueryGetPatientEsArgs = {
  organizationId: Scalars['ID'];
  patientId: Scalars['ID'];
  filter?: Maybe<HistoryFilter>;
};

export type QueryGetPatientsEsArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  sort: ElasticQuerySort;
  queryString?: Maybe<ElasticQueryString>;
  searchAfter?: Maybe<Array<Scalars['ID']>>;
  returnHidden?: Maybe<Scalars['Boolean']>;
  source?: Maybe<ElasticSourceOptions>;
};

export type QueryGetPatientTextArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetNoteArgs = {
  organizationId: Scalars['ID'];
  noteId: Scalars['ID'];
};

export type QueryGetNotesArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<HistoryFilter>;
};

export type QueryGetFilesArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<HistoryFilter>;
};

export type QueryGetRemindersArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<ReminderFilter>;
};

export type QueryGetPrescriptionsFlattenedArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<PrescriptionFlattenedFilter>;
};

export type QueryGetPrescriptionsArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<PrescriptionFilter>;
};

export type QueryGetPrescriptionFillsArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  prescriptionId: Scalars['ID'];
  excludeFills?: Maybe<Scalars['Boolean']>;
};

export type QueryGetPrescriptionFillsAvailableArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  serviceId: Scalars['ID'];
};

export type QueryGetServicesPgArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryServiceFilter>;
};

export type QueryGetBundledServicesPgArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryBundledServiceFilter>;
};

export type QueryGetServicesEsArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  sort: ElasticQuerySort;
  queryString?: Maybe<ElasticQueryString>;
  searchAfter?: Maybe<Array<Scalars['ID']>>;
  source?: Maybe<ElasticSourceOptions>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type QueryGetServiceTextArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetServiceTextsArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<HistorySimpleFilter>;
};

export type QueryGetServicesRenderedArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<ServicesRenderedFilter>;
};

export type QueryGetServicesRendered3pApprovalsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetServicesRendered3pHistoryArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<ServicesRendered3pHistoryFilter>;
};

export type QueryGetServiceRenderedArgs = {
  organizationId: Scalars['ID'];
  serviceRenderedId: Scalars['ID'];
};

export type QueryGetServiceActivitiesArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  filter?: Maybe<HistoryFilter>;
};

export type QueryGetInvoiceContextArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetInvoicesEsArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  sort: ElasticQuerySort;
  queryString?: Maybe<ElasticQueryString>;
  searchAfter?: Maybe<Array<Scalars['ID']>>;
  source?: Maybe<ElasticSourceOptions>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type QueryGetInvoiceTextArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetInvoicesPgArgs = {
  organizationId: Scalars['ID'];
  filter: QueryInvoiceFilter;
};

export type QueryGetLedgersEsArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  sort: ElasticQuerySort;
  queryString?: Maybe<ElasticQueryString>;
  searchAfter?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
};

export type QueryGetLedgersPgArgs = {
  organizationId: Scalars['ID'];
  filter: QueryLedgerFilter;
};

export type QueryGetLedgerTextArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetUserSettingsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryGetStatementRunsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetStatementRunArgs = {
  organizationId: Scalars['ID'];
  statementRunId: Scalars['ID'];
};

export type QueryGetReminderBatchListArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
};

export type QueryGetReminderBatchArgs = {
  organizationId: Scalars['ID'];
  reminderBatchId: Scalars['ID'];
};

export type QueryGetDataConversionArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetReportsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetReportParametersArgs = {
  organizationId: Scalars['ID'];
  reportId: Scalars['ID'];
};

export type QueryGetReportParameterDataArgs = {
  organizationId: Scalars['ID'];
  reportParameterId: Scalars['ID'];
  reportContextParameters: ReportContextParameters;
};

export type QueryGetJobArgs = {
  organizationId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type QueryGetOfflineUpsertErrorsArgs = {
  organizationId: Scalars['ID'];
  fromDate?: Maybe<Scalars['String']>;
};

export type QueryGlobalSearchArgs = {
  organizationId: Scalars['ID'];
  take: Scalars['Int'];
  queryString?: Maybe<ElasticQueryString>;
};

export type QueryGetLabRequestsArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  filter?: Maybe<LabRequestFilter>;
};

export type QueryGetLabRequestArgs = {
  organizationId: Scalars['ID'];
  labRequestId: Scalars['ID'];
};

export type QueryGetBatchLabRequestsArgs = {
  organizationId: Scalars['ID'];
  batchGroupId: Scalars['ID'];
};

export type QueryGetLabClassArgs = {
  organizationId: Scalars['ID'];
  labClassId?: Maybe<Scalars['ID']>;
};

export type QueryGetLabDevicesArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  labClassId?: Maybe<Scalars['ID']>;
  labDeviceId?: Maybe<Scalars['ID']>;
};

export type QueryGetLabRefServicesArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabRefFilter>;
};

export type QueryGetLabRefBreedsArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabRefFilter>;
};

export type QueryGetLabRefGendersArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabRefFilter>;
};

export type QueryGetLabRefSpeciesArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabRefFilter>;
};

export type QueryGetLabGenderMappingArgs = {
  organizationId: Scalars['ID'];
  labDeviceId: Scalars['ID'];
};

export type QueryGetLabSpeciesMappingArgs = {
  organizationId: Scalars['ID'];
  labDeviceId: Scalars['ID'];
};

export type QueryGetLabBreedMappingArgs = {
  organizationId: Scalars['ID'];
  labDeviceId: Scalars['ID'];
};

export type QueryGetLabServiceMappingArgs = {
  organizationId: Scalars['ID'];
  labDeviceId: Scalars['ID'];
};

export type QueryGetMetricsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  options?: Maybe<MetricsOptions>;
};

export type QueryGetLabResultsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetLabResultStatusArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabResultFilter>;
};

export type QueryGetCurrencyDefaultsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetThirdPartyRefGendersArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryThirdPartyRefFilter>;
};

export type QueryGetLabSettingsAntechArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabSettingFilter>;
};

export type QueryGetLabManagementAntechArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabSettingFilter>;
};

export type QueryGetLabSettingsVetConnectArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabSettingFilter>;
};

export type QueryGetUnassignedLabsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetThirdPartyValidationArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  filter?: Maybe<QueryThirdPartyValidationFilter>;
};

export type QueryGetIvlsDevicesArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<QueryLabSettingFilter>;
};

export type QueryGetElectronicPaymentsArgs = {
  organizationId: Scalars['ID'];
  filter?: Maybe<ElectronicPaymentsFilter>;
};

export type QueryGetHisaPatientsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  filter: HisaPatientFilter;
};

export type QueryGetHisaBillingCategoriesArgs = {
  organizationId: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
};

export type QueryGetHisaVetValidationArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  validate?: Maybe<HisaValidateVet>;
};

export type QueryGetFinancialPeriodsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  filter?: Maybe<FinancialPeriodFilter>;
};

export type QueryGetDepositRunArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  filter?: Maybe<DepositRunFilter>;
};

export type QueryGetUrlForFileDownloadArgs = {
  organizationId: Scalars['ID'];
  filePointer: Scalars['String'];
  fileName: Scalars['String'];
  contentType?: Maybe<Scalars['String']>;
};

export type QueryGetUrlForFileUploadArgs = {
  organizationId: Scalars['ID'];
  filePath?: Maybe<Scalars['String']>;
};

export type QueryGetElectronicPaymentResultArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryGetStripePaymentIntentsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  transactionsFilter: StripeTransactionsFilter;
};

export type QueryGetStripePayoutsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetStripeBalanceTransactionsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  balanceTransactionsFilter?: Maybe<StripeBalanceTransactionsFilter>;
};

export type QueryGetStripeBalanceSummaryArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type QueryGetStripePaymentIntentDetailsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
};

export type QueryGetStripePayoutDetailsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  payoutId: Scalars['ID'];
};

export type QueryGetInvoiceReportArgs = {
  request: ReportRequest;
  invoiceId: Scalars['ID'];
  contactId: Scalars['ID'];
  footer?: Maybe<Scalars['String']>;
};

export type QueryGetOrganizationSubscriptionInvoiceReportArgs = {
  request: ReportRequest;
  electronicPaymentId: Scalars['ID'];
};

export type QueryGetReportArgs = {
  request: ReportRequest;
  reportName: Scalars['String'];
  parameters: Scalars['String'];
};

export type QueryGetStatementReportArgs = {
  request: ReportRequest;
  contactId: Array<Scalars['ID']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  returnPreSignedUrl?: Maybe<Scalars['Boolean']>;
};

export type QueryGetPatientHistoryReportArgs = {
  request: ReportRequest;
  patientId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type QueryGetPatientVaccineReportArgs = {
  request: ReportRequest;
  patientId: Scalars['ID'];
};

export type QueryGetPaymentReceiptReportArgs = {
  request: ReportRequest;
  contactId: Scalars['ID'];
  ledgerId: Scalars['ID'];
};

export type QueryRptGetDepositArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  depositRunId: Scalars['ID'];
};

export type QueryRptGetProductionArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetStatementsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptStatementParameters;
  cognitoId?: Maybe<Scalars['String']>;
};

export type QueryRptGetControlledDrugLogArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetGeneralLedgerArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetInvoiceActivityArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetJournalEntryArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  journalId: Scalars['ID'];
};

export type QueryRptGetDailyInvoiceActivityArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetInvoiceActivityByTrainerArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptInvoiceActivityByTrainerParameters;
};

export type QueryRptGetLedgerEntryArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetArArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  effectiveDate: Scalars['String'];
};

export type QueryRptGetCommunicationLogArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetContactReceiptArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptContactReceiptParameters;
};

export type QueryRptGetNewContactsAndPatientsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetPatientProcedureByContactArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptByContactOrPatientAndServiceParameters;
};

export type QueryRptGetContactPaymentArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptByContactOrPatientAndServiceParameters;
};

export type QueryRptGetPatientProcedureArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptByContactOrPatientAndServiceParameters;
};

export type QueryRptGetPaymentsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetSubscriptionPaymentsArgs = {
  reportParameters: DateRange;
};

export type QueryRptGetInvoiceArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  contactId: Scalars['ID'];
  footer?: Maybe<Scalars['String']>;
};

export type QueryRptGetSubscriptionsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetTaxArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetTopSalesArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetRemindersByContactArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptReminderByContactParameters;
};

export type QueryRptGetPortalReceiptArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptPortalReceiptParameters;
};

export type QueryRptGetVaccineByContactArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptByContactOrPatientAndServiceParameters;
};

export type QueryRptGetVaccineActivityArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: DateRange;
};

export type QueryRptGetPatientVaccineArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type QueryRptGetSubscriptionInvoiceArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptSubscriptionInvoiceParameters;
};

export type QueryRptGetReminderLetterArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reminderIds: Array<Scalars['ID']>;
};

export type QueryRptGetPatientHistoryArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  reportParameters: RptByContactOrPatientAndServiceParameters;
};

export type QueryGetQuickbooksAccountsArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  actionInput?: Maybe<QuickbooksGetAccountsRequest>;
};

export type QueryGetQuickbooksTaxRatesArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  actionInput?: Maybe<QuickbooksGetTaxRatesRequest>;
};

export type QueryGetQuickbooksPaymentTypesArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  getPaymentTypes?: Maybe<QuickbooksGetPaymentTypesRequest>;
};

export type QueryGetChatBotAuthTokenArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  setUserStatus?: Maybe<User>;
  upsertPractice: Practice;
  upsertUser: User;
  upsertUserOrganizationCards: UserOrganizationCards;
  upsertPatient?: Maybe<Patient>;
  notifyPatientChanged?: Maybe<RecordChanged>;
  notifyContactChanged?: Maybe<RecordChanged>;
  notifyInvoiceChanged?: Maybe<RecordChanged>;
  notifyReplicationCompleted?: Maybe<RecordChanged>;
  notifyOrganizationDataChanged?: Maybe<RecordChanged>;
  notifyOrganizationDTOChanged?: Maybe<RecordChanged>;
  syncPatientInvoice: PatientSyncResult;
  upsertContact?: Maybe<Contact>;
  upsertNote?: Maybe<Note>;
  upsertFile?: Maybe<File>;
  upsertFinancialPeriod?: Maybe<FinancialPeriod>;
  upsertOfflineSnapshot: OfflineSnapshot;
  upsertService: Service;
  upsertDepositRun?: Maybe<DepositRun>;
  upsertInvoice?: Maybe<Invoice>;
  upsertLedger?: Maybe<Ledger>;
  upsertServiceBatchGroup: BatchGroup;
  upsertServiceBatchLabResubmit: BatchGroupResubmissionResults;
  upsertSetting?: Maybe<Setting>;
  upsertOrganization: Organization;
  reverseLedger?: Maybe<Ledger>;
  acceptEULA: Organization;
  upsertStatementRun?: Maybe<StatementRun>;
  requestDataConversion: DataConversion;
  upsertGlobalSettings: GlobalSettings;
  upsertInterestRun?: Maybe<InterestRunContacts>;
  upsertJob: Job;
  upsertJobStatus: Job;
  upsertOfflineUpsert?: Maybe<OfflineUpsertResponse>;
  refreshDocuments: RefreshDocumentResult;
  upsertReminder: Reminder;
  runMaintenance: MaintenanceResult;
  upsertReminderBatch?: Maybe<ReminderBatch>;
  upsertPrescription: PrescriptionFlattened;
  upsertPrescriptionFill: PrescriptionFill;
  upsertLabDevice: LabDevice;
  upsertLabRequest: LabRequest;
  autoMapLabTaxonomy: LabTaxonomyMapResult;
  upsertThirdPartyGenderMap: ThirdPartyGenderMap;
  upsertLabSettingsAntech: LabSettingsAntech;
  upsertLabSettingsVetConnect: LabSettingsVetConnect;
  upsertLabAssignments: LabAssignmentResult;
  updateServiceRendered?: Maybe<ServiceRendered>;
  upsertJournal: Journal;
  retrySubscription: RetrySubscriptionResult;
  signUp: SignUpResult;
  preLoadData: SignUpResult;
  upsertCardConnectTransaction: ElectronicPaymentResult;
  upsertStripeTransaction: ElectronicPaymentResult;
  upsertElectronicPaymentResult: ElectronicPaymentResult;
  sendEmail: SmtpMessageResult;
  updateOrganizationSubscription: OrganizationSubscriptionResult;
  createPaymentPortal: PaymentPortalResult;
  authQuickbooks: QuickbooksAuthResult;
  callbackCodeQuickbooks: QuickbooksCallbackCodeResult;
  sendJournalQuickbooks: QuickbooksSendJournalResult;
  deleteJournalQuickbooks: QuickbooksDeleteJournalResult;
  sendDepositRunQuickbooks: QuickbooksSendDepositRunResult;
  deleteDepositRunQuickbooks: QuickbooksDeleteDepositRunResult;
  upsertQuickbooksPaymentType: QuickbooksPaymentTypeUpsertResult;
  createFinancialCloseJournal?: Maybe<Journal>;
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationSetUserStatusArgs = {
  user: UserStatus;
};

export type MutationUpsertPracticeArgs = {
  organizationId: Scalars['ID'];
  practice: PracticeUpsert;
};

export type MutationUpsertUserArgs = {
  organizationId: Scalars['ID'];
  user: UserUpsert;
};

export type MutationUpsertUserOrganizationCardsArgs = {
  organizationId: Scalars['ID'];
  cards: UserOrganizationCardsUpsert;
};

export type MutationUpsertPatientArgs = {
  organizationId: Scalars['ID'];
  patient: PatientUpsert;
};

export type MutationNotifyPatientChangedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationNotifyContactChangedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationNotifyInvoiceChangedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationNotifyReplicationCompletedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationNotifyOrganizationDataChangedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationNotifyOrganizationDtoChangedArgs = {
  record?: Maybe<NotifyRecordChanged>;
};

export type MutationSyncPatientInvoiceArgs = {
  organizationId: Scalars['ID'];
  patient: PatientSyncRequest;
};

export type MutationUpsertContactArgs = {
  organizationId: Scalars['ID'];
  contact: ContactUpsert;
};

export type MutationUpsertNoteArgs = {
  organizationId: Scalars['ID'];
  note: NoteUpsert;
};

export type MutationUpsertFileArgs = {
  organizationId: Scalars['ID'];
  file: FileUpsert;
};

export type MutationUpsertFinancialPeriodArgs = {
  organizationId: Scalars['ID'];
  financialPeriod: FinancialPeriodUpsert;
};

export type MutationUpsertOfflineSnapshotArgs = {
  organizationId: Scalars['ID'];
  offlineSnapshot: OfflineSnapshotUpsert;
};

export type MutationUpsertServiceArgs = {
  organizationId: Scalars['ID'];
  service: ServiceUpsert;
};

export type MutationUpsertDepositRunArgs = {
  organizationId: Scalars['ID'];
  depositRun: DepositRunUpsert;
};

export type MutationUpsertInvoiceArgs = {
  organizationId: Scalars['ID'];
  invoice: InvoiceUpsert;
};

export type MutationUpsertLedgerArgs = {
  organizationId: Scalars['ID'];
  ledger: LedgerUpsert;
};

export type MutationUpsertServiceBatchGroupArgs = {
  organizationId: Scalars['ID'];
  batch: BatchGroupUpsert;
};

export type MutationUpsertServiceBatchLabResubmitArgs = {
  organizationId: Scalars['ID'];
  groupBatchResubmit: BatchGroupResubmissionUpsert;
};

export type MutationUpsertSettingArgs = {
  organizationId: Scalars['ID'];
  setting: SettingUpsert;
};

export type MutationUpsertOrganizationArgs = {
  organizationId: Scalars['ID'];
  organization: OrganizationUpsert;
};

export type MutationReverseLedgerArgs = {
  organizationId: Scalars['ID'];
  ledger: LedgerReversal;
};

export type MutationAcceptEulaArgs = {
  organizationId: Scalars['ID'];
  agreement: SignedEula;
};

export type MutationUpsertStatementRunArgs = {
  organizationId: Scalars['ID'];
  statementRun: StatementRunUpsert;
};

export type MutationRequestDataConversionArgs = {
  organizationId: Scalars['ID'];
  record: DataConversionUpsert;
};

export type MutationUpsertGlobalSettingsArgs = {
  settings: GlobalSettingsUpsert;
};

export type MutationUpsertInterestRunArgs = {
  organizationId: Scalars['ID'];
  interestRun: InterestRunUpsert;
};

export type MutationUpsertJobArgs = {
  organizationId: Scalars['ID'];
  job?: Maybe<JobUpsert>;
};

export type MutationUpsertJobStatusArgs = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
  cognitoId: Scalars['String'];
  status: JobStatusUpsert;
};

export type MutationUpsertOfflineUpsertArgs = {
  organizationId: Scalars['ID'];
  upsert?: Maybe<OfflineUpsert>;
};

export type MutationRefreshDocumentsArgs = {
  organizationId: Scalars['ID'];
  request: DocumentFilter;
};

export type MutationUpsertReminderArgs = {
  organizationId: Scalars['ID'];
  reminder: ReminderUpsert;
};

export type MutationRunMaintenanceArgs = {
  instruction: MaintenanceInstruction;
};

export type MutationUpsertReminderBatchArgs = {
  organizationId: Scalars['ID'];
  reminderBatch: ReminderBatchUpsert;
};

export type MutationUpsertPrescriptionArgs = {
  organizationId: Scalars['ID'];
  prescription: PrescriptionUpsert;
};

export type MutationUpsertPrescriptionFillArgs = {
  organizationId: Scalars['ID'];
  prescriptionFill: PrescriptionFillUpsert;
};

export type MutationUpsertLabDeviceArgs = {
  organizationId: Scalars['ID'];
  labDevice: LabDeviceUpsert;
};

export type MutationUpsertLabRequestArgs = {
  organizationId: Scalars['ID'];
  labRequest: LabRequestUpsert;
};

export type MutationAutoMapLabTaxonomyArgs = {
  organizationId: Scalars['ID'];
  instruction: AutoMapInstruction;
};

export type MutationUpsertThirdPartyGenderMapArgs = {
  organizationId: Scalars['ID'];
  genderMap: ThirdPartyGenderMapUpsert;
};

export type MutationUpsertLabSettingsAntechArgs = {
  organizationId: Scalars['ID'];
  labSetting: LabSettingsAntechUpsert;
};

export type MutationUpsertLabSettingsVetConnectArgs = {
  organizationId: Scalars['ID'];
  labSetting: LabSettingsVetConnectUpsert;
};

export type MutationUpsertLabAssignmentsArgs = {
  organizationId: Scalars['ID'];
  labAssignments: LabRequestAssignmentUpsert;
};

export type MutationUpdateServiceRenderedArgs = {
  organizationId: Scalars['ID'];
  serviceRendered: ServiceRenderedUpdate;
};

export type MutationUpsertJournalArgs = {
  organizationId: Scalars['ID'];
  journal: JournalUpsert;
};

export type MutationRetrySubscriptionArgs = {
  organizationId: Scalars['ID'];
  request: RetrySubscriptionInput;
};

export type MutationSignUpArgs = {
  email: Scalars['String'];
  defaults: SignUpDefaults;
  name?: Maybe<Scalars['String']>;
};

export type MutationPreLoadDataArgs = {
  organizationId: Scalars['ID'];
  demographics: PreLoadDataContext;
};

export type MutationUpsertCardConnectTransactionArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  clientToken?: Maybe<Scalars['String']>;
  transaction?: Maybe<CardConnectTransactionRecordUpsert>;
  card?: Maybe<CardConnectCardRecordUpsert>;
  contact?: Maybe<ElectronicContactRecordUpsert>;
  payment?: Maybe<LedgerUpsert>;
};

export type MutationUpsertStripeTransactionArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  paymentRecord?: Maybe<StripePaymentRecordUpsert>;
  setupRecord?: Maybe<StripeSetupRecordUpsert>;
  accountLinkRequestRecord?: Maybe<StripeAccountLinkRequestRecord>;
  embeddedAccountSessionRequestRecord?: Maybe<StripeEmbeddedAccountSessionRequestRecord>;
  statusRequestRecord?: Maybe<StripeStatusRequestRecord>;
  cardRecord?: Maybe<StripeCardRecordUpsert>;
  loginLinkRequestRecord?: Maybe<StripeLoginLinkRequestRecord>;
};

export type MutationUpsertElectronicPaymentResultArgs = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  clientToken: Scalars['String'];
  status: PaymentStatusUpdate;
};

export type MutationSendEmailArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  email: SmtpMessage;
};

export type MutationUpdateOrganizationSubscriptionArgs = {
  organizationId: Scalars['ID'];
  subscription: OrganizationSubscriptionUpsert;
};

export type MutationCreatePaymentPortalArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  paymentPortalInfo: PaymentPortalInfo;
};

export type MutationAuthQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksAuthRequest;
};

export type MutationCallbackCodeQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksCallbackCodeRequest;
};

export type MutationSendJournalQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksSendJournalRequest;
};

export type MutationDeleteJournalQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksDeleteJournalRequest;
};

export type MutationSendDepositRunQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksSendDepositRunRequest;
};

export type MutationDeleteDepositRunQuickbooksArgs = {
  organizationId: Scalars['ID'];
  practiceId?: Maybe<Scalars['ID']>;
  actionInput: QuickbooksDeleteDepositRunRequest;
};

export type MutationUpsertQuickbooksPaymentTypeArgs = {
  organizationId: Scalars['ID'];
  paymentType: QuickbooksPaymentTypeUpsertRequest;
};

export type MutationCreateFinancialCloseJournalArgs = {
  organizationId: Scalars['ID'];
  financialClose: FinancialCloseJournalEntryUpsert;
};

export type Subscription = {
  __typename?: 'Subscription';
  onElectronicPaymentStatusChange?: Maybe<ElectronicPaymentResult>;
  onJobStatusChange?: Maybe<Job>;
  onPatientUpsert?: Maybe<RecordChanged>;
  onContactUpsert?: Maybe<RecordChanged>;
  onInvoiceUpsert?: Maybe<RecordChanged>;
  onReplicationComplete?: Maybe<RecordChanged>;
  onOrganizationDataChanged?: Maybe<RecordChanged>;
  onOrganizationDTOChanged?: Maybe<RecordChanged>;
};

export type SubscriptionOnElectronicPaymentStatusChangeArgs = {
  client_token: Scalars['String'];
};

export type SubscriptionOnJobStatusChangeArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnPatientUpsertArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnContactUpsertArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnInvoiceUpsertArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnReplicationCompleteArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnOrganizationDataChangedArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnOrganizationDtoChangedArgs = {
  id: Scalars['ID'];
};

export type FilterOptions = {
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type ServicesRenderedFilter = {
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
  forNotes?: Maybe<Scalars['Boolean']>;
};

export type ServicesRendered3pHistoryFilter = {
  service_rendered_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['Int']>;
  third_party_id?: Maybe<Scalars['Int']>;
  batchSize?: Maybe<Scalars['Int']>;
};

export type ElasticQueryString = {
  query: Scalars['String'];
  fields?: Maybe<Array<Scalars['String']>>;
  default_field?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ElasticQuerySort = {
  field: Scalars['String'];
  direction: Scalars['String'];
};

export type ElasticSourceOptions = {
  excludes?: Maybe<Array<Scalars['String']>>;
};

export type PracticesFilter = {
  practiceId?: Maybe<Scalars['ID']>;
};

export type PracticeIdFilter = {
  practiceId: Scalars['ID'];
};

export type HistoryFilter = {
  searchText?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type HistorySimpleFilter = {
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
};

export type ReminderFilter = {
  searchText?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
  removeInactivePatients?: Maybe<Scalars['Boolean']>;
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type PrescriptionFlattenedFilter = {
  searchText?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
  sortByUpdated?: Maybe<Scalars['Boolean']>;
  prescribedUserId?: Maybe<Scalars['ID']>;
  filledUserId?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['ID']>;
  serviceId?: Maybe<Scalars['ID']>;
  serviceRenderedId?: Maybe<Scalars['ID']>;
  beginPrescribedDate?: Maybe<Scalars['String']>;
  endPrescribedDate?: Maybe<Scalars['String']>;
  beginFilledDate?: Maybe<Scalars['String']>;
  endFilledDate?: Maybe<Scalars['String']>;
  beginPrescriptionExpiryDate?: Maybe<Scalars['String']>;
  endPrescriptionExpiryDate?: Maybe<Scalars['String']>;
  beginProductExpiryDate?: Maybe<Scalars['String']>;
  endProductExpiryDate?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  prescriptionNumber?: Maybe<Scalars['String']>;
  filledItemManufacturer?: Maybe<Scalars['String']>;
  filled?: Maybe<Scalars['Boolean']>;
  externallyFilled?: Maybe<Scalars['Boolean']>;
  excludeFills?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionFilter = {
  searchText?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
  sortByUpdated?: Maybe<Scalars['Boolean']>;
  prescribedUserId?: Maybe<Scalars['ID']>;
  serviceId?: Maybe<Scalars['ID']>;
  beginPrescribedDate?: Maybe<Scalars['String']>;
  endPrescribedDate?: Maybe<Scalars['String']>;
  beginPrescriptionExpiryDate?: Maybe<Scalars['String']>;
  endPrescriptionExpiryDate?: Maybe<Scalars['String']>;
  beginProductExpiryDate?: Maybe<Scalars['String']>;
  endProductExpiryDate?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  prescriptionNumber?: Maybe<Scalars['String']>;
  filled?: Maybe<Scalars['Boolean']>;
};

export type LabRequestFilter = {
  searchText?: Maybe<Scalars['String']>;
  requestGroupId?: Maybe<Scalars['String']>;
  labRequestId?: Maybe<Scalars['ID']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type StripeStatusRequestRecord = {
  checkStatus: Scalars['Boolean'];
};

export type ElectronicPaymentsFilter = {
  searchText?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  batchSize?: Maybe<Scalars['Int']>;
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type StripeTransactionsFilter = {
  id?: Maybe<Scalars['String']>;
  lookPracticeLevel: Scalars['Boolean'];
  batchSize?: Maybe<Scalars['Int']>;
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type StripeBalanceTransactionsFilter = {
  batchSize?: Maybe<Scalars['Int']>;
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type HisaPatientFilter = {
  patientNames: Array<Scalars['String']>;
};

export type FinancialPeriodFilter = {
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type DepositRunFilter = {
  id?: Maybe<Scalars['ID']>;
};

export type ReportResponse = {
  __typename?: 'ReportResponse';
  pointer?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  emailBatch?: Maybe<Scalars['String']>;
};

export type ReportRequest = {
  organizationId: Scalars['ID'];
  practiceId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  returnPreSignedUrl?: Maybe<Scalars['String']>;
  fnEmail?: Maybe<SmtpMessage>;
};

export type SignedEula = {
  eula_text: Scalars['String'];
  eula_type: Scalars['String'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  status_name_key?: Maybe<Scalars['String']>;
  percent: Scalars['Int'];
  result?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
};

export type OfflineUpsertResponse = {
  __typename?: 'OfflineUpsertResponse';
  id: Scalars['ID'];
  offline_id: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  type_name: Scalars['String'];
  instruction: Scalars['String'];
  connection_id?: Maybe<Scalars['String']>;
  retry_of_id?: Maybe<Scalars['String']>;
};

export type JobUpsert = {
  id?: Maybe<Scalars['ID']>;
  record: JobUpsertRecord;
  void?: Maybe<Scalars['Boolean']>;
};

export type JobStatusUpsert = {
  id: Scalars['ID'];
  status_name_key: Scalars['String'];
  percent: Scalars['Int'];
};

export type JobUpsertRecord = {
  type_name: Scalars['String'];
  instruction: Scalars['String'];
};

export type OfflineUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OfflineUpsertRecord>;
  void?: Maybe<Scalars['Boolean']>;
  fnRetry?: Maybe<Scalars['Boolean']>;
};

export type OfflineUpsertRecord = {
  offline_id: Scalars['String'];
  connection_id: Scalars['String'];
  type_name: Scalars['String'];
  instruction: Scalars['String'];
  retry_of_id?: Maybe<Scalars['String']>;
};

export type NotifyRecordChanged = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userName: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  connectionId?: Maybe<Scalars['String']>;
};

export type RecordChanged = {
  __typename?: 'RecordChanged';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userName: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  connectionId?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  address_type_id?: Maybe<Scalars['ID']>;
  address_type_other?: Maybe<Scalars['String']>;
  address_type_name_key?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_name_key?: Maybe<Scalars['String']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  country_prov_state_name_key?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
};

export type Phone = {
  __typename?: 'Phone';
  id: Scalars['ID'];
  phone_type_id?: Maybe<Scalars['ID']>;
  phone_type_name?: Maybe<Scalars['String']>;
  phone_type_other?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  phone_digits?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type Info = {
  __typename?: 'Info';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  name_key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InfoText = {
  __typename?: 'InfoText';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  name_key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  updated_user_name?: Maybe<Scalars['String']>;
  offline_id?: Maybe<Scalars['String']>;
};

export type InfoTextStatic = {
  __typename?: 'InfoTextStatic';
  value: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
};

export type InfoTextId = {
  __typename?: 'InfoTextId';
  id?: Maybe<Scalars['ID']>;
  type_id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  name_key?: Maybe<Scalars['String']>;
};

export type InfoType = ReferenceData & {
  __typename?: 'InfoType';
  id: Scalars['ID'];
  category: Scalars['String'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type NoteType = OrganizationReferenceData & {
  __typename?: 'NoteType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  patient_print: Scalars['Boolean'];
  contact_print: Scalars['Boolean'];
  invoice_print: Scalars['Boolean'];
  quick_note?: Maybe<Array<Maybe<QuickNote>>>;
};

export type QuickNote = OrganizationReferenceData & {
  __typename?: 'QuickNote';
  id: Scalars['ID'];
  note_type_id: Scalars['ID'];
  name: Scalars['String'];
  note: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type DefaultNote = {
  __typename?: 'DefaultNote';
  noteTypeId: Scalars['String'];
  noteTypeName: Scalars['String'];
  noteText: Scalars['String'];
  notePreview: Scalars['String'];
};

export type FileType = OrganizationReferenceData & {
  __typename?: 'FileType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PhoneType = OrganizationReferenceData & {
  __typename?: 'PhoneType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ContactAlertType = {
  __typename?: 'ContactAlertType';
  id: Scalars['ID'];
  name: Scalars['String'];
  prompt_contact: Scalars['Boolean'];
  prompt_invoice: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type PatientAlertType = {
  __typename?: 'PatientAlertType';
  id: Scalars['ID'];
  name: Scalars['String'];
  prompt_invoice: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type NoteWithHistory = {
  __typename?: 'NoteWithHistory';
  id: Scalars['ID'];
  note: Note;
  history: Array<NoteHistory>;
};

export type NoteHistory = {
  __typename?: 'NoteHistory';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
  date: Scalars['String'];
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  value: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
};

export type Note = NoteInterface & {
  __typename?: 'Note';
  id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  offline_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
  date: Scalars['String'];
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  value: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type NotePreview = NoteInterface & {
  __typename?: 'NotePreview';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
  date: Scalars['String'];
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  preview?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
  date: Scalars['String'];
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
  offline_id?: Maybe<Scalars['ID']>;
};

export type FileS3Url = {
  __typename?: 'FileS3Url';
  url: Scalars['String'];
  filePointer: Scalars['String'];
};

export type SettingDefinition = {
  __typename?: 'SettingDefinition';
  id: Scalars['ID'];
  category_key: Scalars['String'];
  name_key: Scalars['String'];
  description_key: Scalars['String'];
  value_type: Scalars['Int'];
  valid_values?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  practice_level: Scalars['Boolean'];
  user_level: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type Journal = {
  __typename?: 'Journal';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  financial_period_id?: Maybe<Scalars['ID']>;
  external_journal_number?: Maybe<Scalars['String']>;
  external_journal_id?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  send_status_description?: Maybe<Scalars['String']>;
  details?: Maybe<Array<JournalDetails>>;
  sent_date?: Maybe<Scalars['String']>;
};

export type JournalDetails = {
  __typename?: 'JournalDetails';
  id: Scalars['ID'];
  journal_id: Scalars['ID'];
  line_number: Scalars['Int'];
  external_gl_id?: Maybe<Scalars['String']>;
  general_ledger_id: Scalars['ID'];
  amount: Scalars['String'];
  description: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  setting_id: Scalars['ID'];
  setting_category_key: Scalars['String'];
  setting_name_key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  organization_level_set: Scalars['Boolean'];
  practice_level_set: Scalars['Boolean'];
  user_level_set: Scalars['Boolean'];
};

export type StaticImage = {
  __typename?: 'StaticImage';
  id: Scalars['ID'];
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  category_id: Scalars['ID'];
  category_name_key: Scalars['String'];
  category_type_id?: Maybe<Scalars['ID']>;
  category_type_name?: Maybe<Scalars['String']>;
  category_type_sort_order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  pointer: Scalars['String'];
  size: Scalars['Int'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type ImageCategoryType = OrganizationReferenceData & {
  __typename?: 'ImageCategoryType';
  id: Scalars['ID'];
  category_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ThirdPartyType = ReferenceData & {
  __typename?: 'ThirdPartyType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  desc_key: Scalars['String'];
  service_queue_handler?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  requires_dob: Scalars['Boolean'];
  requires_gender: Scalars['Boolean'];
  requires_microchip: Scalars['Boolean'];
  requires_reason: Scalars['Boolean'];
  requires_roa: Scalars['Boolean'];
  requires_email: Scalars['Boolean'];
};

export type SubscriptionType = ReferenceData & {
  __typename?: 'SubscriptionType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  level: Array<SubscriptionLevel>;
};

export type SubscriptionLevel = ReferenceData & {
  __typename?: 'SubscriptionLevel';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  name_key: Scalars['String'];
  cost: Scalars['String'];
  frequency: Scalars['Int'];
  sort_order: Scalars['Int'];
  user_type: Scalars['Boolean'];
};

export type SubscriptionTax = {
  __typename?: 'SubscriptionTax';
  id: Scalars['ID'];
  country_provstate_id: Scalars['ID'];
  name_key: Scalars['String'];
  percentage: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  category_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  file_name: Scalars['String'];
  publish: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  category_name_key?: Maybe<Scalars['String']>;
  runner: Scalars['String'];
  runner_function?: Maybe<Scalars['String']>;
};

export type ReportParameters = {
  __typename?: 'ReportParameters';
  id: Scalars['ID'];
  parameter: Scalars['String'];
  input_type: Scalars['String'];
  name_key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  sort_order: Scalars['Int'];
};

export type ThirdPartyReferenceDataRequirements = {
  __typename?: 'ThirdPartyReferenceDataRequirements';
  ref_3p_id: Scalars['ID'];
  ref_3p_ref_data_id: Scalars['ID'];
  category: Scalars['String'];
  ref_number?: Maybe<Scalars['String']>;
  ref_value: Scalars['String'];
  ref_3p_ref_data_sort_order: Scalars['Int'];
  requires_dob: Scalars['Boolean'];
  requires_gender: Scalars['Boolean'];
  requires_microchip: Scalars['Boolean'];
  requires_email: Scalars['Boolean'];
  requires_roa: Scalars['Boolean'];
  requires_reason: Scalars['Boolean'];
  requires_results: Scalars['Boolean'];
  requires_limb_treated: Scalars['Boolean'];
  requires_dosage: Scalars['Boolean'];
  requires_condition_treated: Scalars['Boolean'];
  requires_tests_and_diagnostics: Scalars['Boolean'];
  requires_treatment_type: Scalars['Boolean'];
  requires_time: Scalars['Boolean'];
  requires_medical_name: Scalars['Boolean'];
  requires_drug_name: Scalars['Boolean'];
  requires_description: Scalars['Boolean'];
  requires_vaccine_name: Scalars['Boolean'];
  requires_structure: Scalars['Boolean'];
  requires_modality: Scalars['Boolean'];
};

export type ThirdPartyReferenceData = {
  __typename?: 'ThirdPartyReferenceData';
  id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  category: Scalars['String'];
  ref_number?: Maybe<Scalars['String']>;
  ref_value: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ThirdPartyGender = {
  __typename?: 'ThirdPartyGender';
  third_party_gender_number: Scalars['String'];
  third_party_gender_name: Scalars['String'];
  third_party_species_number?: Maybe<Scalars['String']>;
};

export type ThirdPartyGenderMap = {
  __typename?: 'ThirdPartyGenderMap';
  id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  cassadol_gender_id: Scalars['ID'];
  third_party_gender_number?: Maybe<Scalars['String']>;
  third_party_gender_name: Scalars['String'];
};

export type ThirdPartyValidation = {
  __typename?: 'ThirdPartyValidation';
  email_address_valid: Scalars['Boolean'];
};

export type SystemReferenceDataTypes = {
  __typename?: 'SystemReferenceDataTypes';
  id: Scalars['ID'];
  ledger_payment_type?: Maybe<Array<RefLedgerPaymentType>>;
  contact_type?: Maybe<Array<ContactType>>;
  address_type?: Maybe<Array<AddressType>>;
  country?: Maybe<Array<Country>>;
  prov_state?: Maybe<Array<ProvState>>;
  invoice_status?: Maybe<Array<InvoiceStatus>>;
  setting?: Maybe<Array<SettingDefinition>>;
  subscription_type?: Maybe<Array<SubscriptionTypeDto>>;
  ref_currency_defaults?: Maybe<Array<CurrencyDefaultsReferenceData>>;
  ref_service_rendered_info_type_data?: Maybe<Array<RefServiceRenderedInfoTypeData>>;
  heard_about_us?: Maybe<Array<HeardAboutUs>>;
  ref_label?: Maybe<Array<RefLabelData>>;
  ref_3p_region?: Maybe<Array<ThirdPartyRegionReferenceData>>;
};

export type RefLabelData = {
  __typename?: 'RefLabelData';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  label_file_name: Scalars['String'];
};

export type RefServiceRenderedInfoTypeData = ReferenceData & {
  __typename?: 'RefServiceRenderedInfoTypeData';
  id: Scalars['ID'];
  service_rendered_info_id: Scalars['ID'];
  name_key: Scalars['String'];
  value_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type SubscriptionTypeDto = ReferenceData & {
  __typename?: 'SubscriptionTypeDTO';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  level: Array<SubscriptionLevelDto>;
};

export type SubscriptionLevelDto = ReferenceData & {
  __typename?: 'SubscriptionLevelDTO';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  name_key: Scalars['String'];
  cost: Scalars['String'];
  frequency: Scalars['Int'];
  sort_order: Scalars['Int'];
  user_type: Scalars['Boolean'];
};

export type IdUpsert = {
  child_id: Scalars['ID'];
  void?: Maybe<Scalars['Boolean']>;
};

export type IdUpsertWithLedger = {
  child_id: Scalars['ID'];
  void?: Maybe<Scalars['Boolean']>;
  external_id?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type AddressUpsert = {
  id?: Maybe<Scalars['ID']>;
  address_type_id?: Maybe<Scalars['ID']>;
  address_type_other?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  postal_zip?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  void?: Maybe<Scalars['Boolean']>;
};

export type TagUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<TagRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type TagRecordUpsert = {
  type_id: Scalars['ID'];
};

export type InfoUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<InfoRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type InfoRecordUpsert = {
  type_id: Scalars['ID'];
  value: Scalars['String'];
};

export type InfoTextUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<InfoTextRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type InfoTextStaticUpsert = {
  value?: Maybe<Scalars['String']>;
};

export type InfoTextRecordUpsert = {
  type_id: Scalars['ID'];
  value: Scalars['String'];
  offline_id?: Maybe<Scalars['ID']>;
};

export type PhoneUpsert = {
  id?: Maybe<Scalars['ID']>;
  phone_type_id?: Maybe<Scalars['ID']>;
  phone_type_other?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type NoteUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<NoteUpsertRecord>;
  void?: Maybe<Scalars['Boolean']>;
};

export type NoteUpsertRecord = {
  patient_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  date: Scalars['String'];
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  value: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  draft: Scalars['Boolean'];
  offline_id?: Maybe<Scalars['String']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  offline_contact_id?: Maybe<Scalars['String']>;
};

export type FileUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<FileUpsertRecord>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContactTypeUpsert = {
  type_id?: Maybe<Scalars['ID']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type FileUpsertRecord = {
  patient_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  date: Scalars['String'];
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  locked: Scalars['Boolean'];
};

export type SettingUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<SettingRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type SettingRecordUpsert = {
  setting_id: Scalars['ID'];
  value: Scalars['String'];
  practice_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type JournalDetailsUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<JournalDetailsRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type JournalDetailsRecordUpsert = {
  line_number: Scalars['Int'];
  external_gl_id?: Maybe<Scalars['String']>;
  general_ledger_id: Scalars['Int'];
  amount: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type JournalUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<JournalRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Array<JournalDetailsUpsert>>;
};

export type FinancialCloseJournalEntryUpsert = {
  financial_period_id: Scalars['ID'];
};

export type JournalRecordUpsert = {
  practice_id: Scalars['ID'];
  financial_period_id: Scalars['ID'];
  external_journal_number?: Maybe<Scalars['String']>;
  external_journal_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  send_status_description: Scalars['String'];
};

export type ImageUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ImageUpsertRecord>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ImageUpsertRecord = {
  category_id: Scalars['ID'];
  category_type_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  pointer: Scalars['String'];
  size: Scalars['Int'];
};

export type StaticImageUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<StaticImageUpsertRecord>;
  void?: Maybe<Scalars['Boolean']>;
};

export type StaticImageUpsertRecord = {
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
};

export type ThirdPartyTaxonomyUpsert = {
  organization_id: Scalars['ID'];
  third_party_gender_map?: Maybe<Array<Maybe<ThirdPartyGenderMapUpsert>>>;
};

export type ThirdPartyGenderMapUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ThirdPartyGenderMapRecord>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ThirdPartyGenderMapRecord = {
  third_party_id: Scalars['ID'];
  cassadol_gender_id: Scalars['ID'];
  third_party_gender_number?: Maybe<Scalars['String']>;
  third_party_gender_name: Scalars['String'];
};

export type Contact = ElasticDocumentProperties & {
  __typename?: 'Contact';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  updated: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
  statement_ignore: Scalars['Boolean'];
  interest_ignore: Scalars['Boolean'];
  balance_unposted: Scalars['String'];
  balance_posted: Scalars['String'];
  offline_id?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  type_name_keys?: Maybe<Array<Scalars['String']>>;
  tag_names?: Maybe<Array<Scalars['String']>>;
  address?: Maybe<Array<Address>>;
  phone?: Maybe<Array<Phone>>;
  info?: Maybe<Array<Info>>;
  text?: Maybe<Array<InfoTextId>>;
  type?: Maybe<Array<ContactType>>;
  tag?: Maybe<Array<ContactTag>>;
  credit_card?: Maybe<Array<CreditCard>>;
  tax_exempt?: Maybe<Array<ContactTaxType>>;
  note?: Maybe<Array<NotePreview>>;
  file?: Maybe<Array<File>>;
  ledger?: Maybe<Array<Ledger>>;
  communication?: Maybe<Array<ContactCommunication>>;
  third_party?: Maybe<Array<ThirdPartyContact>>;
  alert?: Maybe<Array<ContactAlert>>;
};

export type ThirdPartyContact = {
  __typename?: 'ThirdPartyContact';
  id: Scalars['ID'];
  contact_id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  region_id?: Maybe<Scalars['ID']>;
};

export type VaccinationDefaults = {
  __typename?: 'VaccinationDefaults';
  manufacturer?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
};

export type BillingDefaults = {
  __typename?: 'BillingDefaults';
  vaccinationDefaults?: Maybe<VaccinationDefaults>;
};

export type ContactStatusType = OrganizationReferenceData & {
  __typename?: 'ContactStatusType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  warning?: Maybe<Scalars['Boolean']>;
  stop?: Maybe<Scalars['Boolean']>;
};

export type ContactTag = {
  __typename?: 'ContactTag';
  type_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ContactTagType = OrganizationReferenceData & {
  __typename?: 'ContactTagType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  protected: Scalars['Boolean'];
};

export type ContactType = {
  __typename?: 'ContactType';
  type_id: Scalars['ID'];
  name_key: Scalars['String'];
  invoice_ownership: Scalars['Boolean'];
  selectable?: Maybe<Scalars['Boolean']>;
  contact_toggle?: Maybe<Scalars['Boolean']>;
};

export type BundleService = {
  __typename?: 'BundleService';
  id: Scalars['ID'];
  bundled_service_id: Scalars['ID'];
  quantity: Scalars['String'];
  name: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  quantity_default?: Maybe<Scalars['String']>;
  prompt_mode: Scalars['Int'];
  sort_order?: Maybe<Scalars['Int']>;
  package?: Maybe<Scalars['Boolean']>;
  print?: Maybe<Scalars['Boolean']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  id: Scalars['ID'];
  card_type_id: Scalars['ID'];
  card_type_name_key: Scalars['String'];
  display_number: Scalars['String'];
  card_token?: Maybe<Scalars['String']>;
  expiry_month: Scalars['String'];
  expiry_year: Scalars['String'];
  nick_name?: Maybe<Scalars['String']>;
  processor_type_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type ContactValidationResult = {
  __typename?: 'ContactValidationResult';
  account_balance?: Maybe<Scalars['String']>;
  payment_id?: Maybe<Scalars['ID']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  is_valid: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  processor_type_id: Scalars['String'];
  currency_id?: Maybe<Scalars['String']>;
};

export type PaymentPortalResult = {
  __typename?: 'PaymentPortalResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  is_available: Scalars['Boolean'];
};

export type ContactCommunication = {
  __typename?: 'ContactCommunication';
  id: Scalars['ID'];
  communication_type_id: Scalars['Int'];
  send_type: Scalars['Int'];
  primary_address?: Maybe<Scalars['String']>;
  secondary_address?: Maybe<Scalars['String']>;
};

export type ContactCommunicationLog = {
  __typename?: 'ContactCommunicationLog';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  recipients?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  message_sent?: Maybe<Scalars['String']>;
};

export type ContactTaxType = {
  __typename?: 'ContactTaxType';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  compounded: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  default: Scalars['Boolean'];
};

export type ContactUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<ContactRecordUpsert>;
  address?: Maybe<Array<AddressUpsert>>;
  phone?: Maybe<Array<PhoneUpsert>>;
  info?: Maybe<Array<InfoUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  note?: Maybe<Array<NoteUpsert>>;
  file?: Maybe<Array<FileUpsert>>;
  type?: Maybe<Array<ContactTypeUpsert>>;
  alert?: Maybe<Array<ContactAlertUpsert>>;
  credit_card?: Maybe<Array<CreditCardUpsert>>;
  communication?: Maybe<Array<ContactCommunicationUpsert>>;
  third_party?: Maybe<Array<ThirdPartyContactUpsert>>;
  fnRunAutoApply?: Maybe<Scalars['Boolean']>;
  fnMergeToContactId?: Maybe<Scalars['ID']>;
  void?: Maybe<Scalars['Boolean']>;
  connection_id?: Maybe<Scalars['String']>;
};

export type ContactRecordUpsert = {
  name: Scalars['String'];
  contact_status_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  created_practice_id?: Maybe<Scalars['ID']>;
  send_type: Scalars['Int'];
  statement_ignore: Scalars['Boolean'];
  interest_ignore: Scalars['Boolean'];
  tax_exempt?: Maybe<Scalars['Boolean']>;
  offline_id?: Maybe<Scalars['String']>;
};

export type ThirdPartyContactUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ThirdPartyContactRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ThirdPartyContactRecordUpsert = {
  third_party_id: Scalars['ID'];
  region_id?: Maybe<Scalars['ID']>;
};

export type ContactCommunicationUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ContactCommunicationRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContactCommunicationRecordUpsert = {
  communication_type_id: Scalars['Int'];
  send_type: Scalars['Int'];
  primary_address?: Maybe<Scalars['String']>;
  secondary_address?: Maybe<Scalars['String']>;
};

export type CreditCardUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<CreditCardRecordUpsert>;
  primaryRecord?: Maybe<CreditCardPrimaryRecordUpsert>;
  noteRecord?: Maybe<CreditCardNoteRecordUpsert>;
  processorRecord?: Maybe<CreditCardProcessorRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type CreditCardPrimaryRecordUpsert = {
  primary?: Maybe<Scalars['Boolean']>;
};

export type CreditCardNoteRecordUpsert = {
  notes?: Maybe<Scalars['String']>;
};

export type CreditCardProcessorRecordUpsert = {
  processor_type_id?: Maybe<Scalars['String']>;
  processor_customer_id?: Maybe<Scalars['String']>;
};

export type CreditCardRecordUpsert = {
  card_type_id: Scalars['ID'];
  display_number: Scalars['String'];
  card_token: Scalars['String'];
  expiry_month: Scalars['String'];
  expiry_year: Scalars['String'];
  nick_name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  processor_type_id?: Maybe<Scalars['Int']>;
  processor_customer_id?: Maybe<Scalars['String']>;
};

export type CreditCardPaymentUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<CreditCardPaymentRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type CreditCardPaymentRecordUpsert = {
  card_id: Scalars['ID'];
  ledger_id?: Maybe<Scalars['ID']>;
  transaction_type_id?: Maybe<Scalars['ID']>;
  amount: Scalars['String'];
  fee_amount?: Maybe<Scalars['String']>;
  approval_code?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  reference_number?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['String']>;
  response_text?: Maybe<Scalars['String']>;
  response_data?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  expiry_month?: Maybe<Scalars['String']>;
  expiry_year?: Maybe<Scalars['String']>;
  card_type_id: Scalars['ID'];
  reversal_order_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  subscription_transaction_type_id?: Maybe<Scalars['ID']>;
  currency_id?: Maybe<Scalars['String']>;
  processor_customer_id?: Maybe<Scalars['String']>;
  processor_record_id?: Maybe<Scalars['String']>;
  processor_type_id?: Maybe<Scalars['ID']>;
};

export type ContactValidationInfo = {
  contact_number: Scalars['String'];
  contact_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
};

export type PaymentPortalInfo = {
  subdomain: Scalars['String'];
  confirm_only: Scalars['Boolean'];
};

export type ContactTaxExemptUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ContactTaxExemptRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContactTaxExemptRecordUpsert = {
  contact_id: Scalars['ID'];
  tax_id?: Maybe<Scalars['ID']>;
};

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  card_connect?: Maybe<CardConnectSettings>;
};

export type GlobalSettingsUpsert = {
  card_connect?: Maybe<CardConnectSettingsUpsert>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  locale_id?: Maybe<Scalars['ID']>;
  default_practice_id?: Maybe<Scalars['ID']>;
  default_practice_name?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  account_owner_id?: Maybe<Scalars['ID']>;
  updated: Scalars['String'];
  address?: Maybe<Array<Address>>;
  phone: Array<Phone>;
  practice: Array<Practice>;
  ref_demographics?: Maybe<DemographicReferenceData>;
  ref_user?: Maybe<UserReferenceData>;
  ref_patient: PatientReferenceData;
  ref_contact?: Maybe<ContactReferenceData>;
  ref_financial?: Maybe<FinancialReferenceData>;
  ref_service?: Maybe<ServiceReferenceData>;
  ref_system?: Maybe<SystemReferenceData>;
  service: Array<Service>;
  setting: Array<Setting>;
  logo?: Maybe<StaticImage>;
  smtp?: Maybe<SmtpSettings>;
  subscription?: Maybe<OrganizationSubscription>;
  maintenance?: Maybe<MaintenanceMessage>;
  enable_offline?: Maybe<Scalars['Boolean']>;
  bulk_load_stamp?: Maybe<Scalars['String']>;
  bulk_load_file?: Maybe<FileS3Url>;
  lab_state?: Maybe<OrganizationLabState>;
  text?: Maybe<Array<InfoText>>;
  info?: Maybe<Array<Info>>;
};

export type OrganizationPracticeArgs = {
  filter?: Maybe<PracticesFilter>;
};

export type OrganizationServiceArgs = {
  filter?: Maybe<QueryServiceFilter>;
};

export type OrganizationDto = ElasticDocumentProperties & {
  __typename?: 'OrganizationDTO';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  name: Scalars['String'];
  locale_id?: Maybe<Scalars['ID']>;
  default_practice_id?: Maybe<Scalars['ID']>;
  default_practice_name?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  account_owner_id?: Maybe<Scalars['ID']>;
  updated: Scalars['String'];
  address: Array<AddressDto>;
  phone: Array<PhoneDto>;
  practice: Array<PracticeDto>;
  ref_demographics: DemographicReferenceDataDto;
  ref_user: UserReferenceDataDto;
  ref_patient: PatientReferenceDataDto;
  ref_contact: ContactReferenceDataDto;
  ref_financial: FinancialReferenceDataDto;
  ref_service: ServiceReferenceDataDto;
  ref_system: SystemReferenceDataDto;
  setting: Array<SettingDto>;
  logo?: Maybe<StaticImageDto>;
  smtp?: Maybe<SmtpSettingsDto>;
  subscription?: Maybe<OrganizationSubscriptionDto>;
  enable_offline?: Maybe<Scalars['Boolean']>;
  bulk_load_stamp?: Maybe<Scalars['String']>;
  bulk_load_file?: Maybe<FileS3UrlDto>;
  info: Array<InfoDto>;
  text: Array<InfoTextDto>;
};

export type FileTypeDto = OrganizationReferenceData & {
  __typename?: 'FileTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ContactTypeDto = ReferenceData & {
  __typename?: 'ContactTypeDTO';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  name_key: Scalars['String'];
  invoice_ownership: Scalars['Boolean'];
  selectable?: Maybe<Scalars['Boolean']>;
  contact_toggle?: Maybe<Scalars['Boolean']>;
  sort_order: Scalars['Int'];
};

export type NoteTypeDto = OrganizationReferenceData & {
  __typename?: 'NoteTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  patient_print: Scalars['Boolean'];
  contact_print: Scalars['Boolean'];
  invoice_print: Scalars['Boolean'];
  quick_note?: Maybe<Array<Maybe<QuickNoteDto>>>;
};

export type QuickNoteDto = OrganizationReferenceData & {
  __typename?: 'QuickNoteDTO';
  id: Scalars['ID'];
  note_type_id: Scalars['ID'];
  name: Scalars['String'];
  note: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type InfoTypeDto = ReferenceData & {
  __typename?: 'InfoTypeDTO';
  id: Scalars['ID'];
  category: Scalars['String'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PhoneTypeDto = OrganizationReferenceData & {
  __typename?: 'PhoneTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type StaticImageDto = {
  __typename?: 'StaticImageDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  practice_id?: Maybe<Scalars['ID']>;
};

export type AddressDto = {
  __typename?: 'AddressDTO';
  id: Scalars['ID'];
  address_type_id?: Maybe<Scalars['ID']>;
  address_type_other?: Maybe<Scalars['String']>;
  address_type_name_key?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_name_key?: Maybe<Scalars['String']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  country_prov_state_name_key?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
};

export type PhoneDto = {
  __typename?: 'PhoneDTO';
  id: Scalars['ID'];
  phone_type_id?: Maybe<Scalars['ID']>;
  phone_type_name?: Maybe<Scalars['String']>;
  phone_type_other?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type InfoDto = {
  __typename?: 'InfoDTO';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  name_key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InfoTextDto = {
  __typename?: 'InfoTextDTO';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  name_key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type FileS3UrlDto = {
  __typename?: 'FileS3UrlDTO';
  url: Scalars['String'];
  filePointer: Scalars['String'];
};

export type OrganizationSubscriptionDto = {
  __typename?: 'OrganizationSubscriptionDTO';
  id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  last_billed_date?: Maybe<Scalars['String']>;
  next_bill_date?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  failed_message?: Maybe<Scalars['String']>;
  card_type_id?: Maybe<Scalars['ID']>;
  name_on_card?: Maybe<Scalars['String']>;
  display_number?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  expiry_month?: Maybe<Scalars['String']>;
  expiry_year?: Maybe<Scalars['String']>;
  addon: Array<OrganizationSubscriptionAddOnDto>;
  detail?: Maybe<Array<OrganizationSubscriptionDetailDto>>;
  problem: Scalars['Boolean'];
  billing_address?: Maybe<BillingAddressDto>;
  country_id?: Maybe<Scalars['ID']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  processor_type_id?: Maybe<Scalars['Int']>;
  processor_customer_id?: Maybe<Scalars['String']>;
  invoice?: Maybe<Array<OrganizationSubscriptionInvoiceDto>>;
};

export type BillingAddressDto = {
  __typename?: 'BillingAddressDTO';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  country_prov_state_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_name?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type OrganizationSubscriptionInvoiceDto = {
  __typename?: 'OrganizationSubscriptionInvoiceDTO';
  id: Scalars['ID'];
  number: Scalars['String'];
  amount: Scalars['String'];
  subscription_id: Scalars['ID'];
  subscription_transaction_type_id: Scalars['Int'];
  created: Scalars['String'];
  detail?: Maybe<Array<OrganizationSubscriptionInvoiceDetailDto>>;
};

export type OrganizationSubscriptionInvoiceDetailDto = {
  __typename?: 'OrganizationSubscriptionInvoiceDetailDTO';
  id: Scalars['ID'];
  payment_id: Scalars['ID'];
  name_key: Scalars['String'];
  quantity: Scalars['Int'];
  unit_price: Scalars['String'];
  total: Scalars['String'];
};

export type OrganizationSubscriptionAddOnDto = {
  __typename?: 'OrganizationSubscriptionAddOnDTO';
  id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  level_id: Scalars['ID'];
  level_name_key: Scalars['String'];
  enabled: Scalars['Boolean'];
  user_type: Scalars['Boolean'];
  has_switch: Scalars['Boolean'];
  cost: Scalars['String'];
};

export type OrganizationSubscriptionDetailDto = {
  __typename?: 'OrganizationSubscriptionDetailDTO';
  id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  level_id: Scalars['ID'];
  level_name_key: Scalars['String'];
  qty: Scalars['Int'];
  qty_used: Scalars['Int'];
  qty_available: Scalars['Int'];
  is_new: Scalars['Boolean'];
  user_type: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  users?: Maybe<Array<OrganizationSubscriptionDetailUserDto>>;
};

export type OrganizationSubscriptionDetailUserDto = {
  __typename?: 'OrganizationSubscriptionDetailUserDTO';
  id: Scalars['ID'];
  user_id: Scalars['ID'];
  name: Scalars['String'];
};

export type SmtpSettingsDto = {
  __typename?: 'SMTPSettingsDTO';
  serverName?: Maybe<Scalars['String']>;
  portNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fromAddress: Scalars['String'];
  replyToAddress?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  requiresSSL?: Maybe<Scalars['Boolean']>;
  useSES?: Maybe<Scalars['Boolean']>;
};

export type SystemReferenceDataDto = {
  __typename?: 'SystemReferenceDataDTO';
  id: Scalars['ID'];
  image_category_type: Array<ImageCategoryTypeDto>;
  third_party: Array<ThirdPartyTypeDto>;
};

export type SettingDefinitionDto = {
  __typename?: 'SettingDefinitionDTO';
  id: Scalars['ID'];
  category_key: Scalars['String'];
  name_key: Scalars['String'];
  description_key: Scalars['String'];
  value_type: Scalars['Int'];
  valid_values?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  practice_level: Scalars['Boolean'];
  user_level: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type ImageCategoryTypeDto = OrganizationReferenceData & {
  __typename?: 'ImageCategoryTypeDTO';
  id: Scalars['ID'];
  category_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ThirdPartyTypeDto = ReferenceData & {
  __typename?: 'ThirdPartyTypeDTO';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  desc_key: Scalars['String'];
  service_queue_handler?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  requires_dob: Scalars['Boolean'];
  requires_gender: Scalars['Boolean'];
  requires_microchip: Scalars['Boolean'];
  requires_reason: Scalars['Boolean'];
  requires_roa: Scalars['Boolean'];
  requires_email: Scalars['Boolean'];
};

export type ServiceReferenceDataDto = {
  __typename?: 'ServiceReferenceDataDTO';
  id: Scalars['ID'];
  info_type: Array<InfoTypeDto>;
  tag_type: Array<ServiceTagTypeDto>;
};

export type ServiceTagTypeDto = OrganizationReferenceData & {
  __typename?: 'ServiceTagTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type FinancialReferenceDataDto = {
  __typename?: 'FinancialReferenceDataDTO';
  id: Scalars['ID'];
  tax_type: Array<TaxTypeDto>;
  info_type: Array<InfoTypeDto>;
  ledger_type: Array<LedgerTypeDto>;
  ledger_payment_type: Array<LedgerPaymentTypeDto>;
  ledger_sub_type: Array<LedgerSubTypeDto>;
  ledger_financial_type?: Maybe<Array<LedgerFinancialTypeDto>>;
  general_ledger: Array<GeneralLedgerDto>;
  payment_device_type: Array<PaymentDeviceTypeDto>;
};

export type PaymentDeviceTypeDto = {
  __typename?: 'PaymentDeviceTypeDTO';
  id: Scalars['ID'];
  practice_id: Scalars['ID'];
  name: Scalars['String'];
  serial_number: Scalars['String'];
};

export type GeneralLedgerDto = OrganizationReferenceData & {
  __typename?: 'GeneralLedgerDTO';
  id: Scalars['ID'];
  gl_number: Scalars['String'];
  external_gl_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  practice_id?: Maybe<Scalars['ID']>;
  is_receivables_account: Scalars['Boolean'];
};

export type LedgerSubTypeDto = OrganizationReferenceData & {
  __typename?: 'LedgerSubTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerPaymentTypeDto = ReferenceData & {
  __typename?: 'LedgerPaymentTypeDTO';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  electronic_type_id: Scalars['Int'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
  external_id?: Maybe<Scalars['String']>;
};

export type LedgerTypeDto = ReferenceData & {
  __typename?: 'LedgerTypeDTO';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type TaxTypeDto = OrganizationReferenceData & {
  __typename?: 'TaxTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  compounded: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  default: Scalars['Boolean'];
  external_tax_id?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type ContactDto = {
  __typename?: 'ContactDTO';
  id: Scalars['ID'];
};

export type ContactReferenceDataDto = {
  __typename?: 'ContactReferenceDataDTO';
  id: Scalars['ID'];
  type: Array<ContactTypeDto>;
  note_type: Array<NoteTypeDto>;
  info_type: Array<InfoTypeDto>;
  file_type: Array<FileTypeDto>;
  status_type: Array<ContactStatusTypeDto>;
  tag_type: Array<ContactTagTypeDto>;
  alert_type: Array<ContactAlertTypeDto>;
};

export type ContactAlertTypeDto = {
  __typename?: 'ContactAlertTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  prompt_contact: Scalars['Boolean'];
  prompt_invoice: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type ContactTagTypeDto = OrganizationReferenceData & {
  __typename?: 'ContactTagTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  protected: Scalars['Boolean'];
};

export type ContactStatusTypeDto = OrganizationReferenceData & {
  __typename?: 'ContactStatusTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  warning?: Maybe<Scalars['Boolean']>;
  stop?: Maybe<Scalars['Boolean']>;
};

export type PatientReferenceDataDto = {
  __typename?: 'PatientReferenceDataDTO';
  id: Scalars['ID'];
  note_type: Array<NoteTypeDto>;
  info_type: Array<InfoTypeDto>;
  tag_type: Array<PatientTagTypeDto>;
  file_type: Array<FileTypeDto>;
  species: Array<PatientSpeciesDto>;
  breed: Array<PatientBreedDto>;
  gender: Array<PatientGenderDto>;
  color: Array<PatientColorDto>;
  alert_type: Array<PatientAlertTypeDto>;
  reminder_status: Array<ReminderStatusDto>;
};

export type PatientAlertTypeDto = {
  __typename?: 'PatientAlertTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  prompt_invoice: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type ReminderStatusDto = OrganizationReferenceData & {
  __typename?: 'ReminderStatusDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  system_status_id: Scalars['ID'];
  outstanding: Scalars['Boolean'];
  is_default: Scalars['Boolean'];
};

export type PatientBreedDto = OrganizationReferenceData & {
  __typename?: 'PatientBreedDTO';
  id: Scalars['ID'];
  species_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientGenderDto = OrganizationReferenceData & {
  __typename?: 'PatientGenderDTO';
  id: Scalars['ID'];
  species_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientColorDto = OrganizationReferenceData & {
  __typename?: 'PatientColorDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientSpeciesDto = OrganizationReferenceData & {
  __typename?: 'PatientSpeciesDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientTagTypeDto = OrganizationReferenceData & {
  __typename?: 'PatientTagTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  protected: Scalars['Boolean'];
};

export type UserReferenceDataDto = {
  __typename?: 'UserReferenceDataDTO';
  id: Scalars['ID'];
  info_type: Array<InfoTypeDto>;
  type: Array<OrganizationUserTypeDto>;
};

export type OrganizationUserTypeDto = OrganizationReferenceData & {
  __typename?: 'OrganizationUserTypeDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  doctor: Scalars['Boolean'];
  caregiver: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type DemographicReferenceDataDto = {
  __typename?: 'DemographicReferenceDataDTO';
  id: Scalars['ID'];
  business_center: Array<BusinessCenterDto>;
  location: Array<LocationDto>;
  phone_type: Array<PhoneTypeDto>;
};

export type BusinessCenterDto = OrganizationReferenceData & {
  __typename?: 'BusinessCenterDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type LocationDto = OrganizationReferenceData & {
  __typename?: 'LocationDTO';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PracticeDto = {
  __typename?: 'PracticeDTO';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  name: Scalars['String'];
  name_short?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  address: Array<AddressDto>;
  phone: Array<PhoneDto>;
  setting: Array<SettingDto>;
  logo?: Maybe<StaticImageDto>;
  card_connect?: Maybe<CardConnectSettingsDto>;
  card_connect_manual?: Maybe<CardConnectManualSettingsDto>;
  stripe?: Maybe<StripeSettingsDto>;
  info: Array<InfoDto>;
  text: Array<InfoTextDto>;
};

export type CardConnectSettingsDto = {
  __typename?: 'CardConnectSettingsDTO';
  merchantId: Scalars['String'];
  userId: Scalars['String'];
};

export type CardConnectManualSettingsDto = {
  __typename?: 'CardConnectManualSettingsDTO';
  manualCardSite: Scalars['String'];
  manualCardPort: Scalars['String'];
};

export type StripeSettingsDto = {
  __typename?: 'StripeSettingsDTO';
  accountId?: Maybe<Scalars['String']>;
};

export type SettingDto = {
  __typename?: 'SettingDTO';
  id: Scalars['ID'];
  setting_id: Scalars['ID'];
  setting_category_key: Scalars['String'];
  setting_name_key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  organization_level_set: Scalars['Boolean'];
  practice_level_set: Scalars['Boolean'];
  user_level_set: Scalars['Boolean'];
};

export type OrganizationLabState = {
  __typename?: 'OrganizationLabState';
  has_unassigned: Scalars['Boolean'];
  unassigned_count: Scalars['Int'];
};

export type OrganizationList = {
  __typename?: 'OrganizationList';
  id: Scalars['ID'];
  name: Scalars['String'];
  locale_id?: Maybe<Scalars['ID']>;
  default_practice_id?: Maybe<Scalars['ID']>;
  default_practice_name?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  account_owner_id?: Maybe<Scalars['ID']>;
  account_owner_name?: Maybe<Scalars['String']>;
  account_owner_email?: Maybe<Scalars['String']>;
  updated: Scalars['String'];
  start_date?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  last_billed_date?: Maybe<Scalars['String']>;
  next_bill_date?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  failed_message?: Maybe<Scalars['String']>;
};

export type OrganizationSubscriptionResult = {
  __typename?: 'OrganizationSubscriptionResult';
  cardResult?: Maybe<ElectronicPaymentOrganizationResult>;
  organization: Organization;
  subscription_id?: Maybe<Scalars['ID']>;
  charge?: Maybe<Array<OrganizationSubscriptionDetailCharge>>;
  monthlyTotal: Scalars['String'];
  monthlyTax?: Maybe<Array<TaxAmount>>;
  cardCharge?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  cardChargeTax?: Maybe<Array<TaxAmount>>;
  error_name_key?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
  problem_name_key?: Maybe<Array<Scalars['String']>>;
};

export type TaxAmount = {
  __typename?: 'TaxAmount';
  name_key: Scalars['String'];
  percentage: Scalars['String'];
  amount: Scalars['String'];
};

export type OrganizationSubscriptionAddOn = {
  __typename?: 'OrganizationSubscriptionAddOn';
  id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  level_id: Scalars['ID'];
  level_name_key: Scalars['String'];
  enabled: Scalars['Boolean'];
  user_type: Scalars['Boolean'];
  has_switch: Scalars['Boolean'];
  cost: Scalars['String'];
};

export type OrganizationSubscriptionDetailCharge = {
  __typename?: 'OrganizationSubscriptionDetailCharge';
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  level_id: Scalars['ID'];
  level_name_key: Scalars['String'];
  user_type: Scalars['Boolean'];
  currentQty: Scalars['Int'];
  cost: Scalars['String'];
  newQty?: Maybe<Scalars['Int']>;
  proRatedPercentage?: Maybe<Scalars['String']>;
  proRatedTotal?: Maybe<Scalars['String']>;
  total: Scalars['String'];
};

export type OrganizationSubscription = {
  __typename?: 'OrganizationSubscription';
  id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  last_billed_date?: Maybe<Scalars['String']>;
  next_bill_date?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  failed_message?: Maybe<Scalars['String']>;
  card_type_id?: Maybe<Scalars['ID']>;
  name_on_card?: Maybe<Scalars['String']>;
  display_number?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  expiry_month?: Maybe<Scalars['String']>;
  expiry_year?: Maybe<Scalars['String']>;
  addon: Array<OrganizationSubscriptionAddOn>;
  detail?: Maybe<Array<OrganizationSubscriptionDetail>>;
  problem: Scalars['Boolean'];
  billing_address?: Maybe<BillingAddress>;
  country_id?: Maybe<Scalars['ID']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  processor_type_id?: Maybe<Scalars['Int']>;
  processor_customer_id?: Maybe<Scalars['String']>;
  invoice?: Maybe<Array<OrganizationSubscriptionInvoice>>;
};

export type OrganizationSubscriptionDetail = {
  __typename?: 'OrganizationSubscriptionDetail';
  id?: Maybe<Scalars['ID']>;
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  level_id: Scalars['ID'];
  level_name_key: Scalars['String'];
  qty: Scalars['Int'];
  qty_used: Scalars['Int'];
  qty_available: Scalars['Int'];
  is_new: Scalars['Boolean'];
  user_type: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  users?: Maybe<Array<OrganizationSubscriptionDetailUser>>;
};

export type OrganizationSubscriptionDetailUser = {
  __typename?: 'OrganizationSubscriptionDetailUser';
  id: Scalars['ID'];
  user_id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrganizationSubscriptionInvoice = {
  __typename?: 'OrganizationSubscriptionInvoice';
  id: Scalars['ID'];
  number: Scalars['String'];
  amount: Scalars['String'];
  subscription_id: Scalars['ID'];
  subscription_transaction_type_id: Scalars['Int'];
  created: Scalars['String'];
  detail?: Maybe<Array<OrganizationSubscriptionInvoiceDetail>>;
};

export type OrganizationSubscriptionInvoiceDetail = {
  __typename?: 'OrganizationSubscriptionInvoiceDetail';
  id: Scalars['ID'];
  payment_id: Scalars['ID'];
  name_key: Scalars['String'];
  quantity: Scalars['Int'];
  unit_price: Scalars['String'];
  total: Scalars['String'];
};

export type OrganizationCurrencySetting = {
  __typename?: 'OrganizationCurrencySetting';
  currencyCode?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  isPrefix?: Maybe<Scalars['Boolean']>;
  decimalDigits?: Maybe<Scalars['Int']>;
  decimalSymbol?: Maybe<Scalars['String']>;
  groupingSymbol?: Maybe<Scalars['String']>;
  negativeSymbol?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
};

export type UserReferenceData = {
  __typename?: 'UserReferenceData';
  id: Scalars['ID'];
  info_type: Array<InfoType>;
  type: Array<OrganizationUserType>;
};

export type PatientReferenceData = {
  __typename?: 'PatientReferenceData';
  id: Scalars['ID'];
  note_type: Array<NoteType>;
  info_type: Array<InfoType>;
  tag_type: Array<PatientTagType>;
  file_type: Array<FileType>;
  species: Array<PatientSpecies>;
  breed: Array<PatientBreed>;
  gender: Array<PatientGender>;
  color: Array<PatientColor>;
  alert_type: Array<PatientAlertType>;
  reminder_status: Array<ReminderStatus>;
};

export type ContactReferenceData = {
  __typename?: 'ContactReferenceData';
  id: Scalars['ID'];
  type: Array<ContactType>;
  note_type: Array<NoteType>;
  info_type: Array<InfoType>;
  file_type: Array<FileType>;
  status_type: Array<ContactStatusType>;
  tag_type: Array<ContactTagType>;
  alert_type: Array<ContactAlertType>;
};

export type FinancialReferenceData = {
  __typename?: 'FinancialReferenceData';
  id: Scalars['ID'];
  tax_type: Array<TaxType>;
  info_type: Array<InfoType>;
  ledger_type: Array<LedgerType>;
  ledger_payment_type: Array<LedgerPaymentType>;
  ledger_sub_type: Array<LedgerSubType>;
  ledger_financial_type?: Maybe<Array<LedgerFinancialType>>;
  general_ledger: Array<GeneralLedger>;
  invoice_status: Array<InvoiceStatus>;
  payment_device_type: Array<PaymentDeviceType>;
};

export type FinancialReferenceDataPayment_Device_TypeArgs = {
  filter?: Maybe<PracticeIdFilter>;
};

export type ServiceReferenceData = {
  __typename?: 'ServiceReferenceData';
  id: Scalars['ID'];
  info_type: Array<InfoType>;
  tag_type: Array<ServiceTagType>;
};

export type DemographicReferenceData = {
  __typename?: 'DemographicReferenceData';
  id: Scalars['ID'];
  address_type: Array<AddressType>;
  country: Array<Country>;
  prov_state: Array<ProvState>;
  business_center: Array<BusinessCenter>;
  location: Array<Location>;
  phone_type: Array<PhoneType>;
};

export type SystemReferenceData = {
  __typename?: 'SystemReferenceData';
  id: Scalars['ID'];
  setting: Array<SettingDefinition>;
  subscription_type: Array<SubscriptionType>;
  image_category_type: Array<ImageCategoryType>;
  third_party: Array<ThirdPartyType>;
};

export type SoftwareType = {
  __typename?: 'SoftwareType';
  id: Scalars['ID'];
  name: Scalars['String'];
  conversion_cost: Scalars['String'];
};

export type DataConversionFileType = {
  __typename?: 'DataConversionFileType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  multiple: Scalars['Boolean'];
  type: Scalars['String'];
};

export type OrganizationUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OrganizationRecordUpsert>;
  address?: Maybe<Array<AddressUpsert>>;
  phone?: Maybe<Array<PhoneUpsert>>;
  contact_type?: Maybe<Array<IdUpsert>>;
  contact_info_type?: Maybe<Array<IdUpsert>>;
  ledger_info_type?: Maybe<Array<IdUpsert>>;
  ledger_payment_type?: Maybe<Array<IdUpsertWithLedger>>;
  patient_info_type?: Maybe<Array<IdUpsert>>;
  service_info_type?: Maybe<Array<IdUpsert>>;
  user_info_type?: Maybe<Array<IdUpsert>>;
  ledger_type?: Maybe<Array<IdUpsertWithLedger>>;
  ledger_financial_type?: Maybe<Array<IdUpsertWithLedger>>;
  ref_business_center?: Maybe<Array<RefDataWithPracticeUpsert>>;
  ref_color?: Maybe<Array<RefDataUpsert>>;
  ref_contact_status?: Maybe<Array<RefContactStatusUpsert>>;
  ref_contact_tag_type?: Maybe<Array<RefDataUpsert>>;
  ref_file_type?: Maybe<Array<RefFileTypeUpsert>>;
  ref_image_category_type?: Maybe<Array<RefImageCategoryUpsert>>;
  ref_ledger_sub_type?: Maybe<Array<RefLedgerSubTypeUpsert>>;
  ref_location?: Maybe<Array<RefDataWithPracticeUpsert>>;
  ref_note_type?: Maybe<Array<RefNoteTypeUpsert>>;
  ref_patient_tag_type?: Maybe<Array<RefDataUpsert>>;
  ref_species?: Maybe<Array<RefDataSpeciesUpsert>>;
  ref_tax_type?: Maybe<Array<RefTaxTypeUpsert>>;
  ref_user_type?: Maybe<Array<RefUserTypeUpsert>>;
  ref_payment_device_type?: Maybe<Array<RefPaymentDeviceTypeUpdate>>;
  ref_general_ledger?: Maybe<Array<RefGeneralLedgerUpsert>>;
  ref_phone_type?: Maybe<Array<RefPhoneTypeUpsert>>;
  ref_reminder_status?: Maybe<Array<RefDataReminderStatusUpsert>>;
  ref_service_tag_type?: Maybe<Array<RefDataUpsert>>;
  ref_contact_alert_type?: Maybe<Array<RefContactAlertTypeUpsert>>;
  ref_patient_alert_type?: Maybe<Array<RefPatientAlertTypeUpsert>>;
  smtp?: Maybe<SmtpSettingsUpsert>;
  text?: Maybe<Array<InfoTextUpsert>>;
  info?: Maybe<Array<InfoUpsert>>;
};

export type ReportContextParameters = {
  practiceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type OrganizationSubscriptionUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OrganizationSubscriptionRecordUpsert>;
  detail?: Maybe<Array<OrganizationSubscriptionLevelUpsert>>;
  discount?: Maybe<Array<OrganizationSubscriptionDiscountUpsert>>;
  card?: Maybe<OrganizationSubscriptionCardUpsert>;
  contact?: Maybe<ElectronicContactRecordUpsert>;
  dataConversionProperties?: Maybe<DataConversionUpsert>;
  fnProcess: Scalars['Boolean'];
  fnDisableAddOn?: Maybe<Array<Scalars['ID']>>;
};

export type OrganizationSubscriptionDiscountUpsert = {
  discount_code: Scalars['String'];
};

export type OrganizationSubscriptionRecordUpsert = {
  frequency: Scalars['Int'];
};

export type OrganizationSubscriptionCardUpsert = {
  card_token: Scalars['String'];
  expiry: Scalars['String'];
  display_number?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['String']>;
  processor_type_id: Scalars['Int'];
  processor_customer_id?: Maybe<Scalars['String']>;
};

export type OrganizationRecordUpsert = {
  name: Scalars['String'];
  default_practice_id?: Maybe<Scalars['ID']>;
  locale_id?: Maybe<Scalars['ID']>;
};

export type OrganizationSubscriptionLevelUpsert = {
  level_id: Scalars['ID'];
  set_qty?: Maybe<Scalars['Int']>;
  add_qty?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<OrganizationSubscriptionLevelUserUpsert>>;
};

export type OrganizationSubscriptionLevelUserUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OrganizationSubscriptionLevelUserRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSubscriptionLevelUserRecordUpsert = {
  user_id: Scalars['ID'];
};

export type OrganizationSubscriptionDetailUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OrganizationSubscriptionDetailRecordUpsert>;
  quantityRecord?: Maybe<OrganizationSubscriptionDetailQuantityRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSubscriptionDetailRecordUpsert = {
  subscription_id: Scalars['ID'];
  level_id: Scalars['ID'];
  is_new: Scalars['Boolean'];
  cost: Scalars['String'];
};

export type OrganizationSubscriptionDetailQuantityRecordUpsert = {
  qty: Scalars['Int'];
  qty_delta: Scalars['Int'];
};

export type RefDataSpeciesUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefDataRecordUpsert>;
  ref_species_breed?: Maybe<Array<RefDataUpsert>>;
  ref_species_gender?: Maybe<Array<RefDataUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefDataWithPracticeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefDataWithPracticeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefDataWithPracticeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  practice_id?: Maybe<Scalars['ID']>;
};

export type RefDataUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefDataRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefDataRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type RefContactStatusUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefContactStatusRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefContactStatusRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  active: Scalars['Boolean'];
  warning: Scalars['Boolean'];
  stop: Scalars['Boolean'];
};

export type RefFileTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefFileTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefPaymentDeviceTypeUpdate = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type RefFileTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  patient: Scalars['Boolean'];
  contact: Scalars['Boolean'];
};

export type RefContactAlertTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefContactAlertTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefContactAlertTypeRecordUpsert = {
  name: Scalars['String'];
  prompt_invoice: Scalars['Boolean'];
  prompt_contact: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type RefPatientAlertTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefPatientAlertTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefPatientAlertTypeRecordUpsert = {
  name: Scalars['String'];
  prompt_invoice: Scalars['Boolean'];
  prompt_patient: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type RefImageCategoryUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefImageCategoryRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefImageCategoryRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  category_id: Scalars['ID'];
};

export type RefLedgerSubTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefLedgerSubTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefLedgerSubTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  ledger_type_id: Scalars['ID'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type RefNoteTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefNoteTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
  quick_note?: Maybe<Array<QuickNoteUpsert>>;
};

export type RefNoteTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  patient: Scalars['Boolean'];
  contact: Scalars['Boolean'];
  patient_print: Scalars['Boolean'];
  contact_print: Scalars['Boolean'];
  invoice_print: Scalars['Boolean'];
};

export type QuickNoteUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<QuickNoteRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type QuickNoteRecordUpsert = {
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  note: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type RefTaxTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefTaxTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefTaxTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  percentage: Scalars['String'];
  compounded: Scalars['Boolean'];
  default: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  external_tax_id?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerPaymentTypeUpsert = {
  ledger_payment_type_id: Scalars['ID'];
  external_id?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerFinancialTypeUpsert = {
  ledger_financial_type_id: Scalars['ID'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerTypeUpsert = {
  ledger_type_id: Scalars['ID'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type RefUserTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefUserTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefUserTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  doctor: Scalars['Boolean'];
  caregiver: Scalars['Boolean'];
};

export type RefPhoneTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefPhoneTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefPhoneTypeRecordUpsert = {
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type RefDataReminderStatusUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefReminderStatusRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefReminderStatusRecordUpsert = {
  name: Scalars['String'];
  system_status_id: Scalars['ID'];
  sort_order: Scalars['Int'];
  is_default: Scalars['Boolean'];
};

export type RefGeneralLedgerUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<RefGeneralLedgerRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type RefGeneralLedgerRecordUpsert = {
  name: Scalars['String'];
  gl_number: Scalars['String'];
  external_gl_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['ID']>;
  sort_order: Scalars['Int'];
  is_receivables_account: Scalars['Boolean'];
};

export type PreLoadDataContext = {
  countryId?: Maybe<Scalars['ID']>;
  provStateId?: Maybe<Scalars['ID']>;
};

export type Patient = ElasticDocumentProperties & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_2?: Maybe<Scalars['String']>;
  name_3?: Maybe<Scalars['String']>;
  species_id?: Maybe<Scalars['ID']>;
  species_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['ID']>;
  gender_name?: Maybe<Scalars['String']>;
  breed_id?: Maybe<Scalars['ID']>;
  breed_name?: Maybe<Scalars['String']>;
  color_id?: Maybe<Scalars['ID']>;
  color_name?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  deceased: Scalars['Boolean'];
  deceased_date?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  created_practice_id?: Maybe<Scalars['ID']>;
  primary_owner_id?: Maybe<Scalars['ID']>;
  primary_owner_number?: Maybe<Scalars['String']>;
  primary_owner_name?: Maybe<Scalars['String']>;
  default_bill_to_id?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  tag_names?: Maybe<Array<Scalars['String']>>;
  owner_ids?: Maybe<Array<Scalars['ID']>>;
  owner_names?: Maybe<Array<Scalars['String']>>;
  owner_percentages?: Maybe<Array<Scalars['String']>>;
  related_ids?: Maybe<Array<Scalars['ID']>>;
  related_names?: Maybe<Array<Scalars['String']>>;
  related_types?: Maybe<Array<Scalars['ID']>>;
  info?: Maybe<Array<Info>>;
  text?: Maybe<Array<InfoTextId>>;
  tag?: Maybe<Array<Tag>>;
  related_current?: Maybe<Array<RelatedContactEntryCurrent>>;
  related_all?: Maybe<Array<RelatedContactEntryAll>>;
  ownership_current?: Maybe<RelatedOwnershipEntryCurrent>;
  ownership_all?: Maybe<Array<RelatedOwnershipEntryAll>>;
  profile_image?: Maybe<StaticImage>;
  image?: Maybe<Array<Image>>;
  updated: Scalars['String'];
  note: Array<NotePreview>;
  file: Array<File>;
  service_activity: Array<ServiceActivity>;
  service: Array<ServiceRendered>;
  offline_id?: Maybe<Scalars['String']>;
  reminder: Array<Reminder>;
  alert?: Maybe<Array<PatientAlert>>;
  contact_alert?: Maybe<Array<PatientContactAlert>>;
  contact_invoice_alert?: Maybe<Array<PatientContactAlert>>;
};

export type PatientNoteArgs = {
  filter?: Maybe<HistoryFilter>;
};

export type PatientFileArgs = {
  filter?: Maybe<HistoryFilter>;
};

export type PatientService_ActivityArgs = {
  filter?: Maybe<HistoryFilter>;
};

export type PatientServiceArgs = {
  filter?: Maybe<ServiceFilter>;
};

export type ServiceFilter = {
  id?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
};

export type PatientTagType = OrganizationReferenceData & {
  __typename?: 'PatientTagType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  protected: Scalars['Boolean'];
};

export type ReminderStatus = OrganizationReferenceData & {
  __typename?: 'ReminderStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  system_status_id: Scalars['ID'];
  outstanding: Scalars['Boolean'];
  is_default: Scalars['Boolean'];
};

export type RelatedContactEntryAll = {
  __typename?: 'RelatedContactEntryAll';
  id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  contact_type_id: Scalars['ID'];
  contact_type_name_key: Scalars['String'];
  effective_date: Scalars['String'];
  end_date?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
};

export type RelatedContactEntryCurrent = {
  __typename?: 'RelatedContactEntryCurrent';
  id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  contact_type_id: Scalars['ID'];
  contact_type_name_key: Scalars['String'];
  effective_date: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type RelatedOwner = {
  __typename?: 'RelatedOwner';
  id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type RelatedOwnershipEntryAll = {
  __typename?: 'RelatedOwnershipEntryAll';
  syndicate_id?: Maybe<Scalars['ID']>;
  owner: Array<RelatedOwner>;
  effective_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type RelatedOwnershipEntryCurrent = {
  __typename?: 'RelatedOwnershipEntryCurrent';
  syndicate_id?: Maybe<Scalars['ID']>;
  owner: Array<RelatedOwner>;
  effective_date?: Maybe<Scalars['String']>;
};

export type PatientSpecies = OrganizationReferenceData & {
  __typename?: 'PatientSpecies';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientBreed = OrganizationReferenceData & {
  __typename?: 'PatientBreed';
  id: Scalars['ID'];
  species_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientGender = OrganizationReferenceData & {
  __typename?: 'PatientGender';
  id: Scalars['ID'];
  species_id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientColor = OrganizationReferenceData & {
  __typename?: 'PatientColor';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type PatientSyncResult = {
  __typename?: 'PatientSyncResult';
  invoice_issue?: Maybe<Array<PatientInvoiceOwnershipIssue>>;
};

export type PatientInvoiceOwnershipIssue = {
  __typename?: 'PatientInvoiceOwnershipIssue';
  id: Scalars['ID'];
  date: Scalars['String'];
  invoice: Invoice;
  current?: Maybe<Array<PatientOwnershipContactInvoice>>;
  expected: Array<PatientOwnershipContactInvoice>;
  issue: Array<PatientOwnershipContactInvoiceIssue>;
};

export type PatientOwnershipContactInvoice = {
  __typename?: 'PatientOwnershipContactInvoice';
  id: Scalars['ID'];
  name: Scalars['String'];
  percent?: Maybe<Scalars['String']>;
};

export type PatientOwnershipContactInvoiceIssue = {
  __typename?: 'PatientOwnershipContactInvoiceIssue';
  id: Scalars['ID'];
  name: Scalars['String'];
  percent_old?: Maybe<Scalars['String']>;
  percent_new?: Maybe<Scalars['String']>;
  issue_name_key: Scalars['String'];
};

export type PatientUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<PatientRecordUpsert>;
  info?: Maybe<Array<InfoUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  tag?: Maybe<Array<TagUpsert>>;
  ownership?: Maybe<Array<PatientOwnershipUpsert>>;
  related?: Maybe<Array<PatientRelatedUpsert>>;
  note?: Maybe<Array<NoteUpsert>>;
  file?: Maybe<Array<FileUpsert>>;
  alert?: Maybe<Array<PatientAlertUpsert>>;
  profile_image?: Maybe<StaticImageUpsert>;
  image?: Maybe<Array<ImageUpsert>>;
  defaultBillToRecord?: Maybe<DefaultBillToUpsert>;
  fnMergeToPatientId?: Maybe<Scalars['ID']>;
  void?: Maybe<Scalars['Boolean']>;
  connection_id?: Maybe<Scalars['String']>;
};

export type PatientRecordUpsert = {
  name: Scalars['String'];
  name_2?: Maybe<Scalars['String']>;
  name_3?: Maybe<Scalars['String']>;
  species_id: Scalars['ID'];
  gender_id?: Maybe<Scalars['ID']>;
  breed_id?: Maybe<Scalars['ID']>;
  color_id?: Maybe<Scalars['ID']>;
  dob?: Maybe<Scalars['String']>;
  deceased: Scalars['Boolean'];
  deceased_date?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  created_practice_id?: Maybe<Scalars['ID']>;
  offline_id?: Maybe<Scalars['String']>;
};

export type PatientOwnershipUpsert = {
  syndicate_id?: Maybe<Scalars['ID']>;
  effective_date?: Maybe<Scalars['String']>;
  owner?: Maybe<Array<PatientOwnershipOwnerUpsert>>;
  void: Scalars['Boolean'];
};

export type PatientOwnershipOwnerUpsert = {
  id?: Maybe<Scalars['ID']>;
  related_contact_id?: Maybe<Scalars['ID']>;
  offline_related_contact_id?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
  void: Scalars['Boolean'];
};

export type PatientRelatedUpsert = {
  id?: Maybe<Scalars['ID']>;
  effective_date: Scalars['String'];
  end_date?: Maybe<Scalars['String']>;
  related_contact_id?: Maybe<Scalars['ID']>;
  offline_related_contact_id?: Maybe<Scalars['String']>;
  related_contact_type_id: Scalars['ID'];
  primary: Scalars['Boolean'];
  void: Scalars['Boolean'];
};

export type DefaultBillToUpsert = {
  default_bill_to_id: Scalars['ID'];
};

export type PatientSyncRequest = {
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  fnInvoiceId?: Maybe<Array<Scalars['ID']>>;
};

export type Practice = {
  __typename?: 'Practice';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  name: Scalars['String'];
  name_short?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  address: Array<Address>;
  phone: Array<Phone>;
  setting: Array<Setting>;
  logo?: Maybe<StaticImage>;
  card_connect?: Maybe<CardConnectSettings>;
  card_connect_manual?: Maybe<CardConnectManualSettings>;
  stripe?: Maybe<StripeSettings>;
  info: Array<Info>;
  text: Array<InfoText>;
};

export type PracticeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<PracticeRecordUpsert>;
  address?: Maybe<Array<AddressUpsert>>;
  phone?: Maybe<Array<PhoneUpsert>>;
  info?: Maybe<Array<InfoUpsert>>;
  logo?: Maybe<StaticImageUpsert>;
  fnRunAutoApply?: Maybe<Scalars['Boolean']>;
  void?: Maybe<Scalars['Boolean']>;
  card_connect?: Maybe<CardConnectSettingsUpsert>;
  text?: Maybe<Array<InfoTextUpsert>>;
};

export type PracticeRecordUpsert = {
  name: Scalars['String'];
  name_short?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type User = ElasticDocumentProperties & {
  __typename?: 'User';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  cognito_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  global_admin?: Maybe<Scalars['Boolean']>;
  inactive: Scalars['Boolean'];
  address?: Maybe<Array<Address>>;
  phone?: Maybe<Array<Phone>>;
  info?: Maybe<Array<Info>>;
  organization?: Maybe<Array<UserOrganization>>;
  pin?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type Caregiver = {
  __typename?: 'Caregiver';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  doctor: Scalars['Boolean'];
};

export type OrganizationUserType = OrganizationReferenceData & {
  __typename?: 'OrganizationUserType';
  id: Scalars['ID'];
  name: Scalars['String'];
  doctor: Scalars['Boolean'];
  caregiver: Scalars['Boolean'];
  sort_order: Scalars['Int'];
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  id: Scalars['ID'];
  user_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  doctor?: Maybe<Scalars['Boolean']>;
  caregiver?: Maybe<Scalars['Boolean']>;
  subscription_level_id: Scalars['ID'];
  organization_administrator?: Maybe<Scalars['Boolean']>;
  enable_offline?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<Array<UserOrganizationUserType>>;
  permission: Array<UserPermission>;
};

export type UserOrganizationCards = {
  __typename?: 'UserOrganizationCards';
  patient_card: Array<UserOrganizationPatientCard>;
  contact_card: Array<UserOrganizationContactCard>;
};

export type UserOrganizationUserType = {
  __typename?: 'UserOrganizationUserType';
  id: Scalars['ID'];
  type_id: Scalars['ID'];
  type_name: Scalars['String'];
};

export type UserOrganizationPatientCard = {
  __typename?: 'UserOrganizationPatientCard';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  user_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_species_name?: Maybe<Scalars['String']>;
  patient_breed_name?: Maybe<Scalars['String']>;
  patient_gender_name?: Maybe<Scalars['String']>;
  patient_color_name?: Maybe<Scalars['String']>;
  patient_dob?: Maybe<Scalars['String']>;
  patient_deceased: Scalars['Boolean'];
  patient_inactive: Scalars['Boolean'];
  patient_hidden: Scalars['Boolean'];
  patient_owner_ids?: Maybe<Array<Scalars['ID']>>;
  patient_owner_names?: Maybe<Array<Scalars['String']>>;
  patient_owner_percentages?: Maybe<Array<Scalars['String']>>;
  patient_related_ids?: Maybe<Array<Scalars['ID']>>;
  patient_related_names?: Maybe<Array<Scalars['String']>>;
  patient_related_type_ids?: Maybe<Array<Scalars['ID']>>;
  patient_related_type_name_keys?: Maybe<Array<Scalars['String']>>;
};

export type UserOrganizationContactCard = {
  __typename?: 'UserOrganizationContactCard';
  id: Scalars['ID'];
  contact_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_email?: Maybe<Scalars['String']>;
  contact_dob?: Maybe<Scalars['String']>;
  contact_tag_names?: Maybe<Array<Scalars['String']>>;
  contact_type_name_keys?: Maybe<Array<Scalars['String']>>;
  contact_balance_unposted: Scalars['String'];
  contact_balance_posted: Scalars['String'];
  contact_send_type: Scalars['Int'];
  contact_statement_ignore: Scalars['Boolean'];
  contact_hidden: Scalars['Boolean'];
  contact_address?: Maybe<Array<Address>>;
  contact_phone?: Maybe<Array<Phone>>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  id?: Maybe<Scalars['ID']>;
  category_name_key: Scalars['String'];
  name_key: Scalars['String'];
  denied: Scalars['Boolean'];
};

export type DepositRun = {
  __typename?: 'DepositRun';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  number: Scalars['String'];
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  send_date?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['ID']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  updated: Scalars['String'];
  updated_user_id: Scalars['ID'];
  detail?: Maybe<Array<DepositRunDetail>>;
  paymentType?: Maybe<Array<DepositRunPaymentType>>;
};

export type DepositRunStatus = ReferenceData & {
  __typename?: 'DepositRunStatus';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type DepositRunPaymentType = {
  __typename?: 'DepositRunPaymentType';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  deposit_id: Scalars['ID'];
  payment_type_id: Scalars['ID'];
};

export type DepositRunDetail = {
  __typename?: 'DepositRunDetail';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  deposit_id: Scalars['ID'];
  payment_ledger_id: Scalars['ID'];
  external_payment_id?: Maybe<Scalars['ID']>;
  external_payment_number?: Maybe<Scalars['String']>;
  sent_date?: Maybe<Scalars['String']>;
  status_id: Scalars['ID'];
};

export type DepositRunUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<DepositRunRecordUpsert>;
  statusRecord?: Maybe<DepositRunStatusRecordUpsert>;
  paymentType?: Maybe<Array<DepositRunPaymentTypeUpsert>>;
  fnRemovePaymentLedgerId?: Maybe<Array<Scalars['String']>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type DepositRunRecordUpsert = {
  practice_id?: Maybe<Scalars['ID']>;
  end_date: Scalars['String'];
};

export type DepositRunStatusRecordUpsert = {
  status_id: Scalars['ID'];
};

export type DepositRunPaymentTypeUpsert = {
  payment_type_id?: Maybe<Scalars['ID']>;
};

export type SignUpResult = {
  __typename?: 'SignUpResult';
  organization: Organization;
  practice: Practice;
  user: User;
};

export type SignUpDefaults = {
  defaultPracticeName: Scalars['String'];
};

export type UserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  userTypeRecords: Array<UserOrganizationUserTypeRecordUpsert>;
};

export type UserStatus = {
  userName: Scalars['String'];
  active: Scalars['Boolean'];
};

export type UserUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<UserRecordUpsert>;
  pinRecord?: Maybe<UserPinRecordUpsert>;
  statusRecord?: Maybe<UserStatusRecordUpsert>;
  address?: Maybe<Array<AddressUpsert>>;
  phone?: Maybe<Array<PhoneUpsert>>;
  info?: Maybe<Array<InfoUpsert>>;
  organization?: Maybe<Array<UserOrganizationUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UserRecordUpsert = {
  name: Scalars['String'];
  email: Scalars['String'];
  cognito_id?: Maybe<Scalars['String']>;
};

export type UserPinRecordUpsert = {
  pin: Scalars['String'];
};

export type UserStatusRecordUpsert = {
  inactive: Scalars['Boolean'];
};

export type UserOrganizationUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<UserOrganizationRecordUpsert>;
  type?: Maybe<Array<UserOrganizationUserTypeUpsert>>;
  permission?: Maybe<Array<UserOrganizationPermissionUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationCardsUpsert = {
  id?: Maybe<Scalars['ID']>;
  patient_card?: Maybe<Array<UserOrganizationPatientCardUpsert>>;
  contact_card?: Maybe<Array<UserOrganizationContactCardUpsert>>;
  fnClearAll?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationRecordUpsert = {
  organization_id: Scalars['ID'];
  organization_administrator?: Maybe<Scalars['Boolean']>;
  enable_offline?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationUserTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<UserOrganizationUserTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationUserTypeRecordUpsert = {
  type_id: Scalars['ID'];
};

export type UserOrganizationPatientCardUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<UserOrganizationPatientCardRecordUpsert>;
  orderRecord?: Maybe<UserOrganizationPatientCardOrderRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationPatientCardRecordUpsert = {
  patient_id: Scalars['ID'];
  type_name_key?: Maybe<Scalars['String']>;
};

export type UserOrganizationPatientCardOrderRecordUpsert = {
  sort_order: Scalars['Int'];
};

export type UserOrganizationContactCardUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<UserOrganizationContactCardRecordUpsert>;
  orderRecord?: Maybe<UserOrganizationContactCardOrderRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type UserOrganizationContactCardRecordUpsert = {
  contact_id: Scalars['ID'];
  type_name_key?: Maybe<Scalars['String']>;
};

export type UserOrganizationContactCardOrderRecordUpsert = {
  sort_order: Scalars['Int'];
};

export type UserOrganizationPermissionUpsert = {
  permission_id: Scalars['ID'];
  denied: Scalars['Boolean'];
};

export type TaxType = OrganizationReferenceData & {
  __typename?: 'TaxType';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  compounded: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  default: Scalars['Boolean'];
  external_tax_id?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type ServiceTaxType = OrganizationReferenceData & {
  __typename?: 'ServiceTaxType';
  id: Scalars['ID'];
  service_id: Scalars['ID'];
  tax_type_id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  compounded: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  default: Scalars['Boolean'];
};

export type LedgerType = ReferenceData & {
  __typename?: 'LedgerType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerFinancialType = {
  __typename?: 'LedgerFinancialType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerFinancialTypeDto = {
  __typename?: 'LedgerFinancialTypeDTO';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type LedgerPaymentType = ReferenceData & {
  __typename?: 'LedgerPaymentType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  electronic_type_id: Scalars['Int'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
  external_id?: Maybe<Scalars['String']>;
};

export type RefLedgerPaymentType = ReferenceData & {
  __typename?: 'RefLedgerPaymentType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  electronic_type_id: Scalars['Int'];
  sort_order: Scalars['Int'];
};

export type LedgerSubType = OrganizationReferenceData & {
  __typename?: 'LedgerSubType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  general_ledger_id?: Maybe<Scalars['ID']>;
};

export type InvoiceStatus = ReferenceData & {
  __typename?: 'InvoiceStatus';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type Ledger = ElasticDocumentProperties & {
  __typename?: 'Ledger';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  updated: Scalars['String'];
  practice_id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_status_id?: Maybe<Scalars['Int']>;
  invoice_status_name_key?: Maybe<Scalars['String']>;
  invoice_contact_id?: Maybe<Scalars['ID']>;
  invoice_number?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  type_id: Scalars['ID'];
  type_name_key: Scalars['String'];
  sub_type_id?: Maybe<Scalars['ID']>;
  sub_type_name?: Maybe<Scalars['String']>;
  payment_type_id?: Maybe<Scalars['ID']>;
  payment_type_name_key?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  total_no_tax: Scalars['String'];
  total_tax: Scalars['String'];
  total: Scalars['String'];
  posted?: Maybe<Scalars['Boolean']>;
  posted_date?: Maybe<Scalars['String']>;
  financial_date?: Maybe<Scalars['String']>;
  financial_amount?: Maybe<Scalars['String']>;
  financial_outstanding?: Maybe<Scalars['String']>;
  total_reversed: Scalars['String'];
  total_assigned: Scalars['String'];
  discount_service?: Maybe<Scalars['String']>;
  discount_contact?: Maybe<Scalars['String']>;
  discount_invoice?: Maybe<Scalars['String']>;
  note_exists?: Maybe<Scalars['Boolean']>;
  payment_electronic_id?: Maybe<Scalars['ID']>;
  processor_type_id?: Maybe<Scalars['Int']>;
  reversing_ledger_id?: Maybe<Scalars['String']>;
  reversal?: Maybe<Scalars['Boolean']>;
  tx_reference_number?: Maybe<Scalars['String']>;
  card_expiry_month?: Maybe<Scalars['String']>;
  card_expiry_year?: Maybe<Scalars['String']>;
  card_display_number?: Maybe<Scalars['String']>;
  check_number?: Maybe<Scalars['String']>;
  note?: Maybe<InfoTextStatic>;
  info?: Maybe<Array<Info>>;
  text?: Maybe<Array<InfoTextId>>;
  tax?: Maybe<Array<LedgerTax>>;
  hidden: Scalars['Boolean'];
  deposited?: Maybe<Scalars['Boolean']>;
};

export type LedgerTax = {
  __typename?: 'LedgerTax';
  tax_type_id: Scalars['ID'];
  tax_type_code: Scalars['String'];
  tax_type_name: Scalars['String'];
  total: Scalars['String'];
  sort_order?: Maybe<Scalars['Int']>;
};

export type LedgerAssignment = {
  __typename?: 'LedgerAssignment';
  id: Scalars['ID'];
  number: Scalars['String'];
  financial_date: Scalars['String'];
  type_id: Scalars['ID'];
  sub_type_id?: Maybe<Scalars['ID']>;
  sub_type_name?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
};

export type LedgerTransaction = {
  __typename?: 'LedgerTransaction';
  id: Scalars['ID'];
  ledger_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  business_center_id?: Maybe<Scalars['ID']>;
  location_id?: Maybe<Scalars['ID']>;
  doctor_id?: Maybe<Scalars['ID']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_contact_id?: Maybe<Scalars['ID']>;
  service_rendered_id?: Maybe<Scalars['ID']>;
  service_rendered_doctor_id?: Maybe<Scalars['ID']>;
  tax_type_id?: Maybe<Scalars['ID']>;
  financial_type_id: Scalars['ID'];
  financial_amount: Scalars['String'];
  assigned_ledger_id?: Maybe<Scalars['ID']>;
  assigned_amount: Scalars['String'];
  reversing_ledger_id?: Maybe<Scalars['ID']>;
  discount_type_id?: Maybe<Scalars['ID']>;
  discount_header?: Maybe<Scalars['Boolean']>;
};

export type LedgerTransactionOutstanding = {
  __typename?: 'LedgerTransactionOutstanding';
  ledger_id: Scalars['ID'];
  ledger_number: Scalars['String'];
  ledger_type_name_key: Scalars['String'];
  financial_date: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  business_center_id?: Maybe<Scalars['ID']>;
  business_center_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['ID']>;
  doctor_id?: Maybe<Scalars['ID']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_number?: Maybe<Scalars['String']>;
  invoice_contact_id?: Maybe<Scalars['ID']>;
  service_rendered_id?: Maybe<Scalars['ID']>;
  service_rendered_name?: Maybe<Scalars['String']>;
  service_rendered_doctor_id?: Maybe<Scalars['ID']>;
  tax_type_id?: Maybe<Scalars['ID']>;
  tax_type_name?: Maybe<Scalars['String']>;
  financial_amount: Scalars['String'];
  assigned_amount: Scalars['String'];
  outstanding_amount: Scalars['String'];
  reversal: Scalars['Boolean'];
};

export type CreditCardPayment = {
  __typename?: 'CreditCardPayment';
  id: Scalars['ID'];
  response_status: Scalars['String'];
  response_text: Scalars['String'];
  response_code: Scalars['String'];
  reference_number: Scalars['String'];
  amount: Scalars['String'];
  fee_amount: Scalars['String'];
  approval_code: Scalars['String'];
  response_data: Scalars['String'];
  card_token?: Maybe<Scalars['String']>;
  expiry_month?: Maybe<Scalars['String']>;
  expiry_year?: Maybe<Scalars['String']>;
  card_type_id: Scalars['ID'];
  reversal_order_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['ID']>;
  order_id?: Maybe<Scalars['String']>;
  processor_customer_id?: Maybe<Scalars['String']>;
  processor_record_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
};

export type ElectronicPaymentResult = ElectronicPaymentResultInterface & {
  __typename?: 'ElectronicPaymentResult';
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  eventually_due?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  cancel_error?: Maybe<Scalars['String']>;
  ledger_id?: Maybe<Scalars['ID']>;
  client_token?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  contact_cards?: Maybe<Array<CreditCard>>;
  processor_customer_id?: Maybe<Scalars['String']>;
  processor_record_id?: Maybe<Scalars['String']>;
};

export type ElectronicPaymentOrganizationResult = ElectronicPaymentResultInterface & {
  __typename?: 'ElectronicPaymentOrganizationResult';
  success: Scalars['Boolean'];
  reference_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['String']>;
};

export type ElectronicPaymentSubscriptionDetail = {
  __typename?: 'ElectronicPaymentSubscriptionDetail';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  quantity: Scalars['String'];
  unit_price: Scalars['String'];
  total: Scalars['String'];
  subscription_level_id?: Maybe<Scalars['ID']>;
  subscription_level_name_key?: Maybe<Scalars['String']>;
  tax_id?: Maybe<Scalars['ID']>;
  tax_name_key?: Maybe<Scalars['String']>;
};

export type PaymentDeviceType = {
  __typename?: 'PaymentDeviceType';
  id: Scalars['ID'];
  practice_id: Scalars['ID'];
  name: Scalars['String'];
  serial_number: Scalars['String'];
};

export type CardConnectSettings = {
  __typename?: 'CardConnectSettings';
  merchantId: Scalars['String'];
  userId: Scalars['String'];
};

export type StripeSettings = {
  __typename?: 'StripeSettings';
  accountId?: Maybe<Scalars['String']>;
};

export type CardConnectManualSettings = {
  __typename?: 'CardConnectManualSettings';
  manualCardSite: Scalars['String'];
  manualCardPort: Scalars['String'];
};

export type FinancialPeriod = {
  __typename?: 'FinancialPeriod';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  begin_date: Scalars['String'];
  end_date: Scalars['String'];
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
  journal?: Maybe<Array<Maybe<Journal>>>;
};

export type OfflineSnapshot = {
  __typename?: 'OfflineSnapshot';
  id: Scalars['ID'];
  connection_id: Scalars['String'];
  json_data: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  void: Scalars['Boolean'];
};

export type LedgerUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LedgerRecordUpsert>;
  contextRecord?: Maybe<LedgerContextRecordUpsert>;
  totalRecord?: Maybe<LedgerFinancialTotalUpsert>;
  dateRecord?: Maybe<LedgerFinancialDateRecordUpsert>;
  info?: Maybe<Array<InfoUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  note?: Maybe<InfoTextStaticUpsert>;
  assignment?: Maybe<Array<LedgerPaymentAssignmentUpsert>>;
  fnRunAutoApply?: Maybe<Scalars['Boolean']>;
  fnRunUnApply?: Maybe<Scalars['Boolean']>;
  posted?: Maybe<Scalars['Boolean']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LedgerReversal = {
  id: Scalars['ID'];
  amount: Scalars['String'];
  financial_date: Scalars['String'];
  note?: Maybe<InfoTextStaticUpsert>;
};

export type LedgerRecordUpsert = {
  sub_type_id?: Maybe<Scalars['ID']>;
  payment_type_id?: Maybe<Scalars['ID']>;
};

export type LedgerContextRecordUpsert = {
  type_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_contact_id?: Maybe<Scalars['ID']>;
  payment_electronic_id?: Maybe<Scalars['ID']>;
  reversing_ledger_id?: Maybe<Scalars['ID']>;
  interest_run_id?: Maybe<Scalars['ID']>;
};

export type LedgerFinancialDateRecordUpsert = {
  financial_date: Scalars['String'];
};

export type LedgerFinancialTotalUpsert = {
  total: Scalars['String'];
};

export type LedgerTaxUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LedgerTaxRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LedgerTaxRecordUpsert = {
  tax_type_id: Scalars['ID'];
  total: Scalars['String'];
};

export type LedgerPaymentAssignmentUpsert = {
  charge_ledger_id?: Maybe<Scalars['ID']>;
  record?: Maybe<LedgerPaymentAssignmentRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LedgerPaymentAssignmentRecordUpsert = {
  financial_date: Scalars['ID'];
  amount: Scalars['String'];
};

export type CardConnectTransactionRecordUpsert = {
  method?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['ID']>;
  card_id?: Maybe<Scalars['ID']>;
  reference_number?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['ID']>;
  save_card_record: Scalars['Boolean'];
  card_note?: Maybe<Scalars['String']>;
  reversal_ledger_id?: Maybe<Scalars['String']>;
  reversal_note?: Maybe<Scalars['String']>;
  reversal_date?: Maybe<Scalars['String']>;
  is_device_request: Scalars['Boolean'];
  currency_id?: Maybe<Scalars['String']>;
  portal_payment_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['ID']>;
  payment_option?: Maybe<PaymentOption>;
};

export type CardConnectCardRecordUpsert = {
  contact_id?: Maybe<Scalars['String']>;
  card_type_id: Scalars['ID'];
  card_token: Scalars['String'];
  display_number: Scalars['String'];
  expiry_month: Scalars['String'];
  expiry_year: Scalars['String'];
  nick_name?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type StripePriceRecordUpsert = {
  id?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  is_subscription?: Maybe<Scalars['Boolean']>;
};

export type StripeAddressRecordUpsert = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
};

export type StripePaymentRecordUpsert = {
  action?: Maybe<Scalars['String']>;
  clientToken?: Maybe<Scalars['String']>;
  transaction?: Maybe<StripeTransactionRecordUpsert>;
  contact_id?: Maybe<Scalars['String']>;
  payment?: Maybe<LedgerUpsert>;
  payment_portal_id?: Maybe<Scalars['String']>;
  payment_id?: Maybe<Scalars['String']>;
};

export type StripeCollectRecordUpsert = {
  eventuallyDue?: Maybe<Scalars['Boolean']>;
};

export type StripeCardRecordUpsert = {
  contact_id?: Maybe<Scalars['String']>;
  card_type_id: Scalars['ID'];
  card_token: Scalars['String'];
  display_number: Scalars['String'];
  expiry_month: Scalars['String'];
  expiry_year: Scalars['String'];
  nick_name?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type StripeLoginLinkRequestRecord = {
  request?: Maybe<Scalars['Boolean']>;
};

export type StripeSetupPayoutsScheduleRecordUpsert = {
  delay_days?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
};

export type StripeSetupPayoutsRecordUpsert = {
  schedule?: Maybe<StripeSetupPayoutsScheduleRecordUpsert>;
  statement_descriptor?: Maybe<Scalars['String']>;
};

export type StripeSetupPaymentsRecordUpsert = {
  payments_statement_descriptor: Scalars['String'];
};

export type StripeSetupRecordUpsert = {
  practice?: Maybe<StripeSetupPracticeRecordUpsert>;
  payouts?: Maybe<StripeSetupPayoutsRecordUpsert>;
  payments?: Maybe<StripeSetupPaymentsRecordUpsert>;
};

export type StripeSetupPracticeRecordUpsert = {
  business_type: Scalars['String'];
  business_url?: Maybe<Scalars['String']>;
  name_1: Scalars['String'];
  name_2?: Maybe<Scalars['String']>;
  address_1: Scalars['String'];
  address_2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country_id: Scalars['ID'];
  country_prov_state_id: Scalars['ID'];
  postal_zip: Scalars['String'];
  phone: Scalars['String'];
};

export type StripeAccountLinkRequestRecord = {
  refresh_url: Scalars['String'];
  return_url: Scalars['String'];
  collect?: Maybe<StripeCollectRecordUpsert>;
};

export type StripeEmbeddedAccountSessionRequestRecord = {
  request: Scalars['Boolean'];
};

export type StripeTransactionRecordUpsert = {
  currency_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  card_token?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  card_id?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  fee_amount?: Maybe<Scalars['String']>;
  save_credit_card?: Maybe<Scalars['Boolean']>;
  card_note?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['ID']>;
  charge_id?: Maybe<Scalars['String']>;
  reversal_ledger_id?: Maybe<Scalars['ID']>;
  reversal_date?: Maybe<Scalars['String']>;
  reversal_note?: Maybe<Scalars['String']>;
  set_as_primary?: Maybe<Scalars['Boolean']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  country_prov_state_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_name?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ElectronicContactRecordUpsert = {
  contact_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_prov_state_id?: Maybe<Scalars['ID']>;
  country_prov_state_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_name?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type StripeContactRecordUpsert = {
  organization_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postal_zip?: Maybe<Scalars['String']>;
};

export type CardConnectSettingsUpsert = {
  merchantId: Scalars['String'];
  userId: Scalars['String'];
  password: Scalars['String'];
};

export type FinancialPeriodUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<FinancialPeriodRecordUpsert>;
  statusRecord?: Maybe<FinancialPeriodRecordStatusUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type FinancialPeriodRecordUpsert = {
  practice_id: Scalars['ID'];
  end_date: Scalars['String'];
};

export type FinancialPeriodRecordStatusUpsert = {
  status_id: Scalars['Int'];
};

export type OfflineSnapshotUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<OfflineSnapshotRecordUpsert>;
};

export type OfflineSnapshotRecordUpsert = {
  json_data: Scalars['String'];
  connection_id: Scalars['String'];
};

export enum PaymentStatusUpdate {
  None = 'None',
  Cancel = 'Cancel',
  Complete = 'Complete',
}

export enum PaymentOption {
  Phone = 'Phone',
  Arranged = 'Arranged',
}

export type PaymentDeviceTypeUpsert = {
  id: Scalars['ID'];
  record?: Maybe<PaymentDeviceTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type PaymentDeviceTypeRecordUpsert = {
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  name: Scalars['String'];
  serial_number: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type ElectronicPaymentSubscriptionDetailUpsert = {
  id?: Maybe<Scalars['ID']>;
  record: ElectronicPaymentSubscriptionDetailRecordUpsert;
};

export type ElectronicPaymentSubscriptionDetailRecordUpsert = {
  subscription_id: Scalars['ID'];
  payment_id: Scalars['ID'];
  name_key: Scalars['String'];
  quantity: Scalars['Int'];
  unit_price: Scalars['String'];
  total: Scalars['String'];
  subscription_level_id?: Maybe<Scalars['ID']>;
  tax_id?: Maybe<Scalars['ID']>;
};

export type InvoiceContext = {
  __typename?: 'InvoiceContext';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  status: Array<InvoiceStatus>;
  business_center: Array<BusinessCenter>;
  location: Array<Location>;
  service: Array<Service>;
  caregiver: Array<Caregiver>;
};

export type InvoiceContextServiceArgs = {
  filter?: Maybe<QueryServiceFilter>;
};

export type Invoice = ElasticDocumentProperties & {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  offline_id?: Maybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  updated: Scalars['String'];
  practice_id: Scalars['ID'];
  number: Scalars['String'];
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_ids?: Maybe<Array<Scalars['ID']>>;
  contact_names?: Maybe<Array<Scalars['String']>>;
  contact_percentages?: Maybe<Array<Scalars['String']>>;
  split: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  date: Scalars['String'];
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
  total_no_tax: Scalars['String'];
  total_tax: Scalars['String'];
  total: Scalars['String'];
  patient_count: Scalars['Int'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_syndicate_id?: Maybe<Scalars['ID']>;
  changed_date: Scalars['String'];
  contact?: Maybe<Array<InvoiceContact>>;
  item?: Maybe<Array<ServiceRendered>>;
  tax?: Maybe<Array<LedgerTax>>;
  doctor_names?: Maybe<Array<Scalars['String']>>;
  patient?: Maybe<Patient>;
  text?: Maybe<Array<InfoTextId>>;
  labTaxonomyMapping?: Maybe<Array<LabDeviceTaxonomy>>;
  thirdPartyTaxonomyMapping?: Maybe<Array<ThirdPartyTaxonomy>>;
  discount_amount?: Maybe<Scalars['String']>;
  discount_percent?: Maybe<Scalars['String']>;
  contact_alert?: Maybe<Array<Maybe<ContactAlert>>>;
};

export type InvoiceItemArgs = {
  filter?: Maybe<InvoiceItemFilter>;
};

export type InvoiceItemFilter = {
  id: Scalars['ID'];
};

export type InvoiceContact = {
  __typename?: 'InvoiceContact';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  contact_number: Scalars['String'];
  contact_name: Scalars['String'];
  contact_email?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
  ledger_id?: Maybe<Scalars['String']>;
  discount_amount?: Maybe<Scalars['String']>;
  discount_percent?: Maybe<Scalars['String']>;
};

export type InvoiceUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<InvoiceRecordUpsert>;
  statusRecord?: Maybe<InvoiceStatusRecordUpsert>;
  contact?: Maybe<Array<InvoiceContactUpsert>>;
  item?: Maybe<Array<ServiceRenderedUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  labTaxonomyMapping?: Maybe<LabDeviceTaxonomyUpsert>;
  thirdPartyTaxonomyMapping?: Maybe<ThirdPartyTaxonomyUpsert>;
  fnPrimaryContactId?: Maybe<Scalars['ID']>;
  fnDiscount?: Maybe<ApplyDiscount>;
  void?: Maybe<Scalars['Boolean']>;
  connection_id?: Maybe<Scalars['String']>;
};

export type ApplyDiscount = {
  amount?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
};

export type InvoiceRecordUpsert = {
  practice_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  patient_syndicate_id?: Maybe<Scalars['ID']>;
  date: Scalars['String'];
  created_batch_group_id?: Maybe<Scalars['String']>;
  offline_id?: Maybe<Scalars['String']>;
};

export type InvoiceStatusRecordUpsert = {
  status_id: Scalars['Int'];
};

export type InvoiceContactUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<InvoiceContactRecordUpsert>;
  fnDiscount?: Maybe<ApplyDiscount>;
  void?: Maybe<Scalars['Boolean']>;
};

export type InvoiceContactRecordUpsert = {
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type ServiceTagType = OrganizationReferenceData & {
  __typename?: 'ServiceTagType';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type Service = ElasticDocumentProperties & {
  __typename?: 'Service';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  controlled: Scalars['Boolean'];
  vaccine: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  microchip: Scalars['Boolean'];
  quantity_prompt: Scalars['Boolean'];
  quantity_default?: Maybe<Scalars['String']>;
  tax_code_names?: Maybe<Array<Scalars['String']>>;
  tax?: Maybe<Array<ServiceTaxType>>;
  info?: Maybe<Array<Info>>;
  text?: Maybe<Array<InfoTextId>>;
  tag?: Maybe<Array<Tag>>;
  tag_names?: Maybe<Array<Scalars['String']>>;
  bundle?: Maybe<Array<BundleService>>;
  bundled_service_ids?: Maybe<Array<Scalars['ID']>>;
  reminder?: Maybe<Array<ServiceReminder>>;
  reminder_ids?: Maybe<Array<Scalars['ID']>>;
  general_ledger_id?: Maybe<Scalars['ID']>;
  general_ledger_name?: Maybe<Scalars['String']>;
  general_ledger_number?: Maybe<Scalars['String']>;
  default_note_id?: Maybe<Scalars['ID']>;
  updated: Scalars['String'];
  hidden: Scalars['Boolean'];
  bundle_prompt: Scalars['Boolean'];
  roa_prompt: Scalars['Boolean'];
  prescription_prompt: Scalars['Boolean'];
  reason_prompt?: Maybe<Scalars['Boolean']>;
  withdrawal_prompt?: Maybe<Scalars['Boolean']>;
  din?: Maybe<Scalars['String']>;
  rabies_tag_prompt: Scalars['Boolean'];
  decease_prompt?: Maybe<Scalars['Boolean']>;
  lab_mapping?: Maybe<Array<LabServiceMap>>;
  patient_validation?: Maybe<Array<PatientServiceValidation>>;
  third_party?: Maybe<Array<ThirdPartyService>>;
  service_rendered_info_types?: Maybe<Array<ServiceRenderedInfoTypeService>>;
  material_cost?: Maybe<Scalars['String']>;
  markup_percentage?: Maybe<Scalars['String']>;
};

export type ServiceRenderedInfoTypeService = {
  __typename?: 'ServiceRenderedInfoTypeService';
  id: Scalars['ID'];
  service_id: Scalars['ID'];
  service_rendered_info_type_id: Scalars['ID'];
  required?: Maybe<Scalars['Boolean']>;
};

export type ThirdPartyService = {
  __typename?: 'ThirdPartyService';
  id: Scalars['ID'];
  service_id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  ref_data_id?: Maybe<Scalars['ID']>;
};

export type ServiceReminder = {
  __typename?: 'ServiceReminder';
  id: Scalars['ID'];
  service_id: Scalars['ID'];
  duration?: Maybe<Scalars['Int']>;
};

export type ServiceRendered = {
  __typename?: 'ServiceRendered';
  id: Scalars['ID'];
  offline_id?: Maybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  service_id: Scalars['ID'];
  service_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_number?: Maybe<Scalars['String']>;
  doctor_id?: Maybe<Scalars['ID']>;
  doctor_name?: Maybe<Scalars['String']>;
  business_center_id: Scalars['ID'];
  business_center_name?: Maybe<Scalars['String']>;
  location_id: Scalars['ID'];
  location_name?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['String'];
  list_unit_price: Scalars['String'];
  unit_price: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  total: Scalars['String'];
  list_total: Scalars['String'];
  discount?: Maybe<Scalars['String']>;
  controlled: Scalars['Boolean'];
  vaccine: Scalars['Boolean'];
  microchip: Scalars['Boolean'];
  manufacturer_name?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  bottle_number?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['String']>;
  sort_order: Scalars['Int'];
  parent_id?: Maybe<Scalars['ID']>;
  grouping_id?: Maybe<Scalars['String']>;
  is_parent?: Maybe<Scalars['Boolean']>;
  child_sort_order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  note_id?: Maybe<Scalars['String']>;
  note?: Maybe<Note>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
  roa_id?: Maybe<Scalars['String']>;
  roa_other?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  approved_by_id?: Maybe<Scalars['ID']>;
  approved_on?: Maybe<Scalars['String']>;
  doctor_names?: Maybe<Array<Scalars['String']>>;
  doctor: Array<ServiceRenderedDoctor>;
  reminder?: Maybe<Array<Reminder>>;
  reminder_ids?: Maybe<Array<Scalars['ID']>>;
  prescriptions?: Maybe<Array<PrescriptionFlattened>>;
  prescription_ids?: Maybe<Array<Scalars['ID']>>;
  prescription: Scalars['Boolean'];
  lab_request?: Maybe<Array<LabRequest>>;
  lab_request_ids?: Maybe<Array<Scalars['ID']>>;
  lab_status?: Maybe<Array<LabStatus>>;
  rabies_tag?: Maybe<Scalars['Boolean']>;
  lab_incomplete?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  third_party?: Maybe<Array<ServiceRendered3p>>;
  third_party_ids?: Maybe<Array<Scalars['ID']>>;
  withdrawal_period?: Maybe<Scalars['Int']>;
  withdrawal_prompt?: Maybe<Scalars['Boolean']>;
  discount_percent?: Maybe<Scalars['String']>;
  hide_discount?: Maybe<Scalars['Boolean']>;
  package?: Maybe<Scalars['Boolean']>;
  print?: Maybe<Scalars['Boolean']>;
  info?: Maybe<Array<Info>>;
  text?: Maybe<Array<InfoText>>;
};

export type ServiceRenderedDoctor = {
  __typename?: 'ServiceRenderedDoctor';
  id: Scalars['ID'];
  doctor_id: Scalars['ID'];
  doctor_name: Scalars['String'];
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type ServiceActivity = {
  __typename?: 'ServiceActivity';
  patient_id: Scalars['ID'];
  date: Scalars['String'];
  doctor_names: Array<Scalars['String']>;
  service_names: Array<Scalars['String']>;
  performed: Scalars['Int'];
  updated: Scalars['String'];
};

export type ServiceRendered3p = {
  __typename?: 'ServiceRendered3p';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  service_rendered_id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  status_id: Scalars['Int'];
  sent?: Maybe<Scalars['String']>;
  failed_count?: Maybe<Scalars['Int']>;
  failed_reason?: Maybe<Scalars['String']>;
  requires_gender_map?: Maybe<Scalars['Boolean']>;
};

export enum ServiceRendered3pStatus {
  Pending = 'Pending',
  Queued = 'Queued',
  Sent = 'Sent',
  Failed = 'Failed',
}

export enum ThirdParty {
  None = 'None',
  Emr = 'EMR',
  Hisa = 'HISA',
}

export type ServiceRendered3pApproval = {
  __typename?: 'ServiceRendered3pApproval';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  service_id: Scalars['ID'];
  service_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_number?: Maybe<Scalars['String']>;
  doctor_id?: Maybe<Scalars['ID']>;
  doctor_name?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  total: Scalars['String'];
  discount?: Maybe<Scalars['String']>;
  owner_ids?: Maybe<Array<Scalars['ID']>>;
  owner_names?: Maybe<Array<Scalars['String']>>;
  owner_percentages?: Maybe<Array<Scalars['String']>>;
  related_ids?: Maybe<Array<Scalars['ID']>>;
  related_names?: Maybe<Array<Scalars['String']>>;
  related_types?: Maybe<Array<Scalars['ID']>>;
  missing_dob: Scalars['Boolean'];
  missing_gender: Scalars['Boolean'];
  missing_gender_map: Scalars['Boolean'];
  missing_reason: Scalars['Boolean'];
  missing_roa: Scalars['Boolean'];
  missing_microchip: Scalars['Boolean'];
  missing_contact_email: Scalars['Boolean'];
  validation_failed: Scalars['Boolean'];
  info: Scalars['String'];
};

export type ServiceRendered3pHistory = {
  __typename?: 'ServiceRendered3pHistory';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  service_rendered_id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  status_id: Scalars['Int'];
  sent?: Maybe<Scalars['String']>;
  failed_count?: Maybe<Scalars['Int']>;
  failed_reason?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  service_id: Scalars['ID'];
  service_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  invoice_number?: Maybe<Scalars['String']>;
  doctor_id?: Maybe<Scalars['ID']>;
  doctor_name?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  total: Scalars['String'];
  discount?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ThirdPartyTaxonomy = {
  __typename?: 'ThirdPartyTaxonomy';
  third_party_id: Scalars['ID'];
  third_party_name_key: Scalars['String'];
  third_party_desc_key: Scalars['String'];
  organization_id: Scalars['ID'];
  gender_id?: Maybe<Scalars['ID']>;
  gender_name?: Maybe<Scalars['String']>;
  requires_gender_map: Scalars['Boolean'];
};

export type ServiceUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceRecordUpsert>;
  info?: Maybe<Array<InfoUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  tax?: Maybe<Array<ServiceTaxTypeUpsert>>;
  bundle?: Maybe<Array<BundleServiceUpsert>>;
  reminder?: Maybe<Array<ServiceReminderUpsert>>;
  tag?: Maybe<Array<TagUpsert>>;
  bundlingRecord?: Maybe<ServiceRecordBundlingUpsert>;
  lab_mapping?: Maybe<Array<LabServiceMapUpsert>>;
  third_party?: Maybe<Array<ThirdPartyServiceUpsert>>;
  service_rendered_info?: Maybe<Array<ServiceRenderedInfoUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceRecordUpsert = {
  practice_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  controlled: Scalars['Boolean'];
  vaccine: Scalars['Boolean'];
  microchip: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  quantity_prompt: Scalars['Boolean'];
  quantity_default?: Maybe<Scalars['String']>;
  general_ledger_id?: Maybe<Scalars['ID']>;
  roa_prompt: Scalars['Boolean'];
  prescription_prompt: Scalars['Boolean'];
  din?: Maybe<Scalars['String']>;
  rabies_tag_prompt: Scalars['Boolean'];
  reason_prompt: Scalars['Boolean'];
  withdrawal_prompt: Scalars['Boolean'];
  decease_prompt: Scalars['Boolean'];
  material_cost?: Maybe<Scalars['String']>;
  markup_percentage?: Maybe<Scalars['String']>;
};

export type ServiceRecordBundlingUpsert = {
  bundle_prompt: Scalars['Boolean'];
};

export type BundleServiceRecordOrderUpsert = {
  sort_order?: Maybe<Scalars['Int']>;
};

export type BundleServiceUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<BundleServiceRecordUpsert>;
  bundlingRecord?: Maybe<BundleServiceModeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
  orderRecord?: Maybe<BundleServiceRecordOrderUpsert>;
  packageRecord?: Maybe<BundleServicePackageRecordUpsert>;
  printRecord?: Maybe<BundleServicePrintRecordUpsert>;
};

export type BundleServiceRecordUpsert = {
  bundled_service_id: Scalars['ID'];
  quantity: Scalars['String'];
};

export type BundleServiceModeRecordUpsert = {
  prompt_mode: Scalars['Int'];
};

export type BundleServicePackageRecordUpsert = {
  package: Scalars['Boolean'];
};

export type BundleServicePrintRecordUpsert = {
  print: Scalars['Boolean'];
};

export type ThirdPartyServiceUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ThirdPartyServiceRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ThirdPartyServiceRecordUpsert = {
  third_party_id: Scalars['ID'];
  ref_data_id?: Maybe<Scalars['ID']>;
};

export type ServiceRenderedInfoUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceRenderedInfoRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceRenderedInfoRecordUpsert = {
  service_rendered_info_type_id: Scalars['ID'];
  required?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceReminderRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderRecordUpsert = {
  duration?: Maybe<Scalars['Int']>;
};

export type ServiceTaxTypeUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceTaxTypeRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceTaxTypeRecordUpsert = {
  tax_type_id: Scalars['ID'];
};

export type ServiceRenderedUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceRenderedRecordUpsert>;
  financialRecord?: Maybe<ServiceRenderedFinancialRecordUpsert>;
  doctor?: Maybe<Array<ServiceRenderedDoctorUpsert>>;
  reminder?: Maybe<Array<ReminderUpsert>>;
  prescriptions?: Maybe<Array<PrescriptionUpsert>>;
  labRequest?: Maybe<Array<LabRequestUpsert>>;
  orderRecord?: Maybe<ServiceRenderedOrderRecordUpsert>;
  approvedRecord?: Maybe<ServiceRenderedApprovedRecordUpsert>;
  groupRecord?: Maybe<ServiceRenderedGroupRecordUpsert>;
  packageRecord?: Maybe<ServiceRenderedPackageRecordUpsert>;
  printRecord?: Maybe<ServiceRenderedPrintRecordUpsert>;
  note?: Maybe<NoteUpsert>;
  info?: Maybe<Array<InfoUpsert>>;
  text?: Maybe<Array<InfoTextUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceRenderedPackageRecordUpsert = {
  package: Scalars['Boolean'];
};

export type ServiceRenderedPrintRecordUpsert = {
  print: Scalars['Boolean'];
};

export type ServiceRenderedGroupRecordUpsert = {
  grouping_id?: Maybe<Scalars['String']>;
  is_parent?: Maybe<Scalars['Boolean']>;
};

export type ServiceRenderedUpdate = {
  id: Scalars['ID'];
  reminder?: Maybe<Array<ReminderUpsert>>;
  prescriptions?: Maybe<Array<PrescriptionUpsert>>;
  labRequest?: Maybe<Array<LabRequestUpsert>>;
  approvedRecord?: Maybe<ServiceRenderedApprovedRecordUpsert>;
  note?: Maybe<NoteUpsert>;
};

export type ServiceRenderedApprovedRecordUpsert = {
  approved: Scalars['Boolean'];
};

export type ServiceRenderedOrderRecordUpsert = {
  sort_order: Scalars['Int'];
  child_sort_order?: Maybe<Scalars['Int']>;
};

export type ServiceRenderedRecordUpsert = {
  name: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  manufacturer_name?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  bottle_number?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  roa_id?: Maybe<Scalars['String']>;
  roa_other?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  withdrawal_period?: Maybe<Scalars['Int']>;
};

export type ServiceRenderedFinancialRecordUpsert = {
  practice_id?: Maybe<Scalars['ID']>;
  service_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  doctor_id?: Maybe<Scalars['ID']>;
  business_center_id: Scalars['ID'];
  location_id: Scalars['ID'];
  date: Scalars['String'];
  quantity: Scalars['String'];
  list_unit_price: Scalars['String'];
  unit_price: Scalars['String'];
  discount?: Maybe<Scalars['String']>;
  hide_discount?: Maybe<Scalars['Boolean']>;
};

export type ServiceRenderedDoctorUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceRenderedDoctorRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceRenderedDoctorRecordUpsert = {
  doctor_id: Scalars['ID'];
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type ServiceRendered3pUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ServiceRendered3pRecordUpsert>;
  statusRecord?: Maybe<ServiceRendered3pStatusRecordUpsert>;
  failedRecord?: Maybe<ServiceRendered3pFailedRecordUpsert>;
  sentRecord?: Maybe<ServiceRendered3pSentRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ServiceRendered3pRecordUpsert = {
  service_rendered_id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  third_party_record_id?: Maybe<Scalars['String']>;
  third_party_patient_id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  unit_name?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  is_delete?: Maybe<Scalars['Boolean']>;
  approval_type?: Maybe<Scalars['String']>;
};

export type ServiceRendered3pStatusRecordUpsert = {
  status_id: Scalars['Int'];
};

export type ServiceRendered3pFailedRecordUpsert = {
  failed_count: Scalars['Int'];
  failed_reason: Scalars['String'];
  info?: Maybe<Scalars['String']>;
};

export type ServiceRendered3pSentRecordUpsert = {
  sent: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  third_party_record_id?: Maybe<Scalars['String']>;
  third_party_patient_id?: Maybe<Scalars['String']>;
};

export type BatchGroup = {
  __typename?: 'BatchGroup';
  id: Scalars['ID'];
  subBatch: Array<BatchSubGroup>;
  affected_invoice?: Maybe<Array<Invoice>>;
  affected_patient?: Maybe<Array<Patient>>;
  affected_contact?: Maybe<Array<Contact>>;
  batchLabTaxonomyMapping?: Maybe<Array<LabDeviceTaxonomy>>;
};

export type BatchSubGroup = {
  __typename?: 'BatchSubGroup';
  id: Scalars['ID'];
  item: Array<ServiceRendered>;
};

export type BatchGroupJobResult = {
  __typename?: 'BatchGroupJobResult';
  result: BatchGroup;
};

export type BatchGroupResubmissionResults = {
  __typename?: 'BatchGroupResubmissionResults';
  success: Scalars['Boolean'];
};

export type BatchGroupUpsert = {
  practice_id: Scalars['ID'];
  options?: Maybe<BatchGroupOptions>;
  subBatch: Array<BatchSubGroupUpsert>;
};

export type BatchGroupOptions = {
  alwaysNewInvoice?: Maybe<Scalars['Boolean']>;
  closeInvoices?: Maybe<Scalars['Boolean']>;
  allowMultiPatientInvoices?: Maybe<Scalars['Boolean']>;
};

export type BatchSubGroupUpsert = {
  patient_id?: Maybe<Array<Scalars['ID']>>;
  offline_patient?: Maybe<Array<BatchSubGroupOfflinePatientUpsert>>;
  item: Array<ServiceRenderedUpsert>;
};

export type BatchSubGroupOfflinePatientUpsert = {
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['ID']>;
  owners?: Maybe<Array<BatchSubGroupOfflinePatientOwnerUpsert>>;
};

export type BatchSubGroupOfflinePatientOwnerUpsert = {
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['ID']>;
  percentage: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type BatchGroupResubmissionUpsert = {
  batchGroupId: Scalars['ID'];
};

export type LabClass = {
  __typename?: 'LabClass';
  id: Scalars['ID'];
  name: Scalars['String'];
  can_cancel_submit: Scalars['Boolean'];
  group_requests: Scalars['Boolean'];
  group_caption?: Maybe<Scalars['String']>;
  requires_dob: Scalars['Boolean'];
  requires_species_map: Scalars['Boolean'];
  requires_breed_map: Scalars['Boolean'];
  requires_gender_map: Scalars['Boolean'];
  requires_sample_map: Scalars['Boolean'];
  is_bidirectional: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  url_alternate?: Maybe<Scalars['String']>;
  lab_device?: Maybe<Array<LabDevice>>;
};

export type LabDevice = {
  __typename?: 'LabDevice';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  lab_class_id: Scalars['ID'];
  lab_class_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['String']>;
  updated_user_name?: Maybe<Scalars['String']>;
  lab_breed_map?: Maybe<Array<Maybe<LabBreedMap>>>;
  lab_gender_map?: Maybe<Array<Maybe<LabGenderMap>>>;
  lab_service_map?: Maybe<Array<Maybe<LabServiceMap>>>;
  lab_species_map?: Maybe<Array<Maybe<LabSpeciesMap>>>;
};

export type LabRequest = {
  __typename?: 'LabRequest';
  id: Scalars['ID'];
  organization_id?: Maybe<Scalars['ID']>;
  practice_id?: Maybe<Scalars['ID']>;
  service_id?: Maybe<Scalars['ID']>;
  service_name?: Maybe<Scalars['String']>;
  lab_service_name?: Maybe<Scalars['String']>;
  lab_service_number?: Maybe<Scalars['String']>;
  lab_breed_number?: Maybe<Scalars['String']>;
  lab_gender_number?: Maybe<Scalars['String']>;
  lab_species_number?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_name?: Maybe<Scalars['String']>;
  doctor_id?: Maybe<Scalars['ID']>;
  doctor_name?: Maybe<Scalars['String']>;
  lab_device_id: Scalars['ID'];
  service_rendered_id?: Maybe<Scalars['ID']>;
  lab_device_name?: Maybe<Scalars['String']>;
  lab_request_number?: Maybe<Scalars['String']>;
  request_group_id?: Maybe<Scalars['String']>;
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  created_from_lab: Scalars['Boolean'];
  lab_request_data?: Maybe<Scalars['String']>;
  lab_request_url?: Maybe<Scalars['String']>;
  lab_status_value?: Maybe<Scalars['String']>;
  send_log?: Maybe<Scalars['String']>;
  send_error_log?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
  lab_result_url?: Maybe<Scalars['String']>;
  requires_breed_map?: Maybe<Scalars['Boolean']>;
  requires_gender_map?: Maybe<Scalars['Boolean']>;
  requires_species_map?: Maybe<Scalars['Boolean']>;
  lab_service_map_id?: Maybe<Scalars['ID']>;
  batch_subgroup_id?: Maybe<Scalars['ID']>;
  lab_created_accession_id?: Maybe<Scalars['String']>;
  pims_created_accession_id?: Maybe<Scalars['String']>;
  lab_created_order_number?: Maybe<Scalars['String']>;
  pims_created_order_number?: Maybe<Scalars['String']>;
  lab_class_id?: Maybe<Scalars['ID']>;
};

export type LabStatus = {
  __typename?: 'LabStatus';
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  request_group_id: Scalars['String'];
};

export type LabRequestData = {
  __typename?: 'LabRequestData';
  id: Scalars['ID'];
  lab_request_id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabDataLog = {
  __typename?: 'LabDataLog';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  data: Scalars['String'];
  data_type_id: Scalars['Int'];
  data_type_name_key: Scalars['String'];
  import_log?: Maybe<Scalars['String']>;
  import_error_log?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabDataProcessed = {
  __typename?: 'LabDataProcessed';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  lab_class_id: Scalars['ID'];
  lab_polling_id: Scalars['ID'];
  lab_request_id?: Maybe<Scalars['ID']>;
  stage_id: Scalars['Int'];
  stage_name_key: Scalars['String'];
  processed_data?: Maybe<Scalars['String']>;
  processed_tests?: Maybe<Scalars['String']>;
  lab_identifier?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['String']>;
  processed_log?: Maybe<Scalars['String']>;
  processed_error_log?: Maybe<Scalars['String']>;
  lab_document_url?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabService = {
  __typename?: 'LabService';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  service_name: Scalars['String'];
  service_number: Scalars['String'];
  service_value?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type PatientServiceValidation = {
  __typename?: 'PatientServiceValidation';
  organization_id: Scalars['ID'];
  service_id: Scalars['ID'];
  requires_dob?: Maybe<Scalars['Boolean']>;
  requires_breed?: Maybe<Scalars['Boolean']>;
  requires_gender?: Maybe<Scalars['Boolean']>;
  requires_species?: Maybe<Scalars['Boolean']>;
  requires_microchip?: Maybe<Scalars['Boolean']>;
  requires_email?: Maybe<Scalars['Boolean']>;
  requires_roa?: Maybe<Scalars['Boolean']>;
  requires_reason?: Maybe<Scalars['Boolean']>;
  requires_results?: Maybe<Scalars['Boolean']>;
  requires_limb_treated?: Maybe<Scalars['Boolean']>;
  requires_dosage?: Maybe<Scalars['Boolean']>;
  requires_condition_treated?: Maybe<Scalars['Boolean']>;
  requires_tests_and_diagnostics?: Maybe<Scalars['Boolean']>;
  requires_treatment_type?: Maybe<Scalars['Boolean']>;
  requires_time?: Maybe<Scalars['Boolean']>;
  requires_medical_name?: Maybe<Scalars['Boolean']>;
  requires_drug_name?: Maybe<Scalars['Boolean']>;
  requires_description?: Maybe<Scalars['Boolean']>;
  requires_vaccine_name?: Maybe<Scalars['Boolean']>;
  requires_structure?: Maybe<Scalars['Boolean']>;
  requires_modality?: Maybe<Scalars['Boolean']>;
};

export type LabServiceMap = {
  __typename?: 'LabServiceMap';
  id: Scalars['ID'];
  lab_device_id?: Maybe<Scalars['ID']>;
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  service_id: Scalars['ID'];
  service_name: Scalars['String'];
  lab_service_name: Scalars['String'];
  lab_service_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabBreed = {
  __typename?: 'LabBreed';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  breed_name: Scalars['String'];
  breed_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabBreedMap = {
  __typename?: 'LabBreedMap';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  cassadol_breed_id: Scalars['ID'];
  cassadol_breed_name: Scalars['String'];
  lab_breed_name: Scalars['String'];
  lab_breed_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabGender = {
  __typename?: 'LabGender';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  gender_name: Scalars['String'];
  gender_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabGenderMap = {
  __typename?: 'LabGenderMap';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  cassadol_gender_id: Scalars['ID'];
  cassadol_gender_name: Scalars['String'];
  lab_gender_name: Scalars['String'];
  lab_gender_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabSpecies = {
  __typename?: 'LabSpecies';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  species_name: Scalars['String'];
  species_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabSpeciesMap = {
  __typename?: 'LabSpeciesMap';
  id: Scalars['ID'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  cassadol_species_id: Scalars['ID'];
  cassadol_species_name: Scalars['String'];
  lab_species_name: Scalars['String'];
  lab_species_number: Scalars['String'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  created_user_name: Scalars['String'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  updated_user_name?: Maybe<Scalars['String']>;
};

export type LabRefService = {
  __typename?: 'LabRefService';
  lab_service_number: Scalars['String'];
  lab_service_name: Scalars['String'];
  inHouse: Scalars['Boolean'];
};

export type LabRefBreed = {
  __typename?: 'LabRefBreed';
  lab_breed_number: Scalars['String'];
  lab_breed_name: Scalars['String'];
  lab_species_number?: Maybe<Scalars['String']>;
};

export type LabRefGender = {
  __typename?: 'LabRefGender';
  lab_gender_number: Scalars['String'];
  lab_gender_name: Scalars['String'];
  lab_species_number?: Maybe<Scalars['String']>;
};

export type LabRefSpecies = {
  __typename?: 'LabRefSpecies';
  lab_species_number: Scalars['String'];
  lab_species_name: Scalars['String'];
};

export type LabSpeciesMapping = {
  __typename?: 'LabSpeciesMapping';
  id?: Maybe<Scalars['ID']>;
  cassadol_species_id: Scalars['ID'];
  cassadol_species_name: Scalars['String'];
  lab_species_name?: Maybe<Scalars['String']>;
  lab_species_number?: Maybe<Scalars['String']>;
};

export type LabGenderMapping = {
  __typename?: 'LabGenderMapping';
  id?: Maybe<Scalars['ID']>;
  cassadol_species_id: Scalars['ID'];
  cassadol_species_name: Scalars['String'];
  cassadol_gender_id: Scalars['ID'];
  cassadol_gender_name: Scalars['String'];
  lab_gender_name?: Maybe<Scalars['String']>;
  lab_gender_number?: Maybe<Scalars['String']>;
  lab_species_name?: Maybe<Scalars['String']>;
  lab_species_number?: Maybe<Scalars['String']>;
};

export type LabBreedMapping = {
  __typename?: 'LabBreedMapping';
  id?: Maybe<Scalars['ID']>;
  cassadol_species_id: Scalars['ID'];
  cassadol_species_name: Scalars['String'];
  cassadol_breed_id: Scalars['ID'];
  cassadol_breed_name: Scalars['String'];
  lab_breed_name?: Maybe<Scalars['String']>;
  lab_breed_number?: Maybe<Scalars['String']>;
  lab_species_name?: Maybe<Scalars['String']>;
  lab_species_number?: Maybe<Scalars['String']>;
};

export type LabServiceMapping = {
  __typename?: 'LabServiceMapping';
  id?: Maybe<Scalars['ID']>;
  cassadol_service_id: Scalars['ID'];
  cassadol_service_name: Scalars['String'];
  lab_service_name?: Maybe<Scalars['String']>;
  lab_service_number?: Maybe<Scalars['String']>;
};

export type LabValidationMapping = {
  __typename?: 'LabValidationMapping';
  lab_device_id: Scalars['ID'];
  requires_breed_map: Scalars['Boolean'];
  requires_gender_map: Scalars['Boolean'];
  requires_species_map: Scalars['Boolean'];
};

export type LabDeviceTaxonomy = {
  __typename?: 'LabDeviceTaxonomy';
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  organization_id: Scalars['ID'];
  breed_id?: Maybe<Scalars['ID']>;
  breed_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['ID']>;
  gender_name?: Maybe<Scalars['String']>;
  species_id?: Maybe<Scalars['ID']>;
  species_name?: Maybe<Scalars['String']>;
  lab_species_number?: Maybe<Scalars['String']>;
  requires_breed_map: Scalars['Boolean'];
  requires_gender_map: Scalars['Boolean'];
  requires_species_map: Scalars['Boolean'];
};

export type LabResultRecord = {
  __typename?: 'LabResultRecord';
  request_date: Scalars['String'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_name?: Maybe<Scalars['String']>;
  request_group_id: Scalars['String'];
  patient_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
  lab_request_url?: Maybe<Scalars['String']>;
  lab_result_url?: Maybe<Scalars['String']>;
  service_list?: Maybe<Scalars['String']>;
  lab_created_accession_id?: Maybe<Scalars['String']>;
  pims_created_accession_id?: Maybe<Scalars['String']>;
  lab_created_order_number?: Maybe<Scalars['String']>;
  pims_created_order_number?: Maybe<Scalars['String']>;
  lab_class_id?: Maybe<Scalars['ID']>;
};

export type LabResultStatusMsg = {
  __typename?: 'LabResultStatusMsg';
  has_lab_results: Scalars['Boolean'];
  lab_result_message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LabTaxonomyMapResult = {
  __typename?: 'LabTaxonomyMapResult';
  added_species?: Maybe<Scalars['Int']>;
  added_breeds?: Maybe<Scalars['Int']>;
  added_genders?: Maybe<Scalars['Int']>;
};

export type LabSettingsAntech = {
  __typename?: 'LabSettingsAntech';
  lab_device_id: Scalars['ID'];
  account_id?: Maybe<Scalars['String']>;
  clinic_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  antech_lab_id?: Maybe<Scalars['String']>;
};

export type LabManagementAntech = {
  __typename?: 'LabManagementAntech';
  account_token: Scalars['String'];
  management_url: Scalars['String'];
};

export type LabSettingsVetConnect = {
  __typename?: 'LabSettingsVetConnect';
  lab_device_id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  lab_account_id?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  in_house: Scalars['Boolean'];
};

export type LabAssignmentResult = {
  __typename?: 'LabAssignmentResult';
  results_updated?: Maybe<Scalars['Int']>;
};

export type UnassignedLabResults = {
  __typename?: 'UnassignedLabResults';
  lab_request_id: Scalars['ID'];
  lab_created_accession_id: Scalars['String'];
  result_date?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  lab_tests?: Maybe<Scalars['String']>;
  result_data?: Maybe<Scalars['String']>;
  result_url: Scalars['String'];
  lab_device_id: Scalars['ID'];
  lab_device_name: Scalars['String'];
  status_id: Scalars['Int'];
  status_name_key: Scalars['String'];
};

export type IvlsDevice = {
  __typename?: 'IvlsDevice';
  deviceSerialNumber: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  lastPolledCloudTime: Scalars['String'];
  vcpActivatedStatus: Scalars['String'];
};

export type LabClassUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabClassRecordUpsert>;
  lab_device?: Maybe<Array<LabDeviceUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabClassRecordUpsert = {
  name: Scalars['String'];
  can_cancel_submit: Scalars['Boolean'];
  group_requests: Scalars['Boolean'];
  group_caption?: Maybe<Scalars['String']>;
  requires_dob: Scalars['Boolean'];
  requires_species_map: Scalars['Boolean'];
  requires_breed_map: Scalars['Boolean'];
  requires_gender_map: Scalars['Boolean'];
  requires_sample_map: Scalars['Boolean'];
  is_bidirectional: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type LabDeviceUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabDeviceRecordUpsert>;
  lab_breed_map?: Maybe<Array<LabBreedMapUpsert>>;
  lab_gender_map?: Maybe<Array<LabGenderMapUpsert>>;
  lab_service_map?: Maybe<Array<LabServiceMapByDeviceUpsert>>;
  lab_species_map?: Maybe<Array<LabSpeciesMapUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabDeviceRecordUpsert = {
  lab_class_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LabDeviceTaxonomyUpsert = {
  organization_id: Scalars['ID'];
  lab_device?: Maybe<Array<LabDeviceUpsert>>;
};

export type LabRequestUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabRequestRecordUpsert>;
  data?: Maybe<Array<Maybe<LabRequestDataUpsert>>>;
  statusRecord?: Maybe<LabRequestStatusUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabRequestRecordUpsert = {
  service_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  doctor_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  status_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  created_from_lab: Scalars['Boolean'];
  lab_request_data?: Maybe<Scalars['String']>;
  lab_request_number?: Maybe<Scalars['String']>;
  request_group_id?: Maybe<Scalars['String']>;
  send_log?: Maybe<Scalars['String']>;
  send_error_log?: Maybe<Scalars['String']>;
  lab_status_value?: Maybe<Scalars['String']>;
  lab_service_map_id?: Maybe<Scalars['ID']>;
  lab_result_url?: Maybe<Scalars['String']>;
  lab_created_accession_id?: Maybe<Scalars['String']>;
  pims_created_accession_id?: Maybe<Scalars['String']>;
  lab_created_order_number?: Maybe<Scalars['String']>;
  pims_created_order_number?: Maybe<Scalars['String']>;
};

export type LabRequestDataUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabRequestDataRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabRequestDataRecordUpsert = {
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LabRequestStatusUpsert = {
  status_id: Scalars['Int'];
};

export type LabRequestAssignmentUpsert = {
  patient_id: Scalars['ID'];
  lab_request_ids: Array<Scalars['ID']>;
};

export type LabDataLogUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabDataLogRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabDataLogRecordUpsert = {
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  data: Scalars['String'];
  data_type_id: Scalars['Int'];
  import_log?: Maybe<Scalars['String']>;
  import_error_log?: Maybe<Scalars['String']>;
};

export type LabDataProcessedUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabDataProcessedRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabDataProcessedRecordUpsert = {
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  lab_device_id: Scalars['ID'];
  lab_class_id: Scalars['ID'];
  lab_polling_id: Scalars['ID'];
  lab_request_id?: Maybe<Scalars['ID']>;
  stage_id: Scalars['Int'];
  processed_data?: Maybe<Scalars['String']>;
  processed_tests?: Maybe<Scalars['String']>;
  lab_identifier?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['String']>;
  processed_log?: Maybe<Scalars['String']>;
  processed_error_log?: Maybe<Scalars['String']>;
  lab_document_url?: Maybe<Scalars['String']>;
};

export type LabServiceMapUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabServiceMapRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabServiceMapRecordUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  lab_service_number: Scalars['String'];
  lab_service_name: Scalars['String'];
};

export type LabServiceMapByDeviceUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabServiceMapRecordByDeviceUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabServiceMapRecordByDeviceUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  service_id?: Maybe<Scalars['ID']>;
  lab_service_number: Scalars['String'];
  lab_service_name: Scalars['String'];
};

export type LabBreedMapUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabBreedMapRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabBreedMapRecordUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  cassadol_breed_id: Scalars['ID'];
  lab_breed_name: Scalars['String'];
  lab_breed_number: Scalars['String'];
};

export type LabGenderMapUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabGenderMapRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabGenderMapRecordUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  cassadol_gender_id: Scalars['ID'];
  lab_gender_name: Scalars['String'];
  lab_gender_number: Scalars['String'];
};

export type LabSpeciesMapUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<LabSpeciesMapRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type LabSpeciesMapRecordUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  cassadol_species_id: Scalars['ID'];
  lab_species_name: Scalars['String'];
  lab_species_number: Scalars['String'];
};

export type AutoMapInstruction = {
  lab_device_id: Scalars['ID'];
};

export type LabSettingsAntechUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['String']>;
  clinic_id: Scalars['String'];
  user_id: Scalars['String'];
  password: Scalars['String'];
  antech_lab_id?: Maybe<Scalars['String']>;
};

export type LabSettingsVetConnectUpsert = {
  lab_device_id?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  password: Scalars['String'];
  lab_account_id?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  in_house: Scalars['Boolean'];
};

export type PrescriptionFill = {
  __typename?: 'PrescriptionFill';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  prescription_id: Scalars['ID'];
  invoice_id?: Maybe<Scalars['ID']>;
  filled_service_rendered_id?: Maybe<Scalars['ID']>;
  filled_item_din?: Maybe<Scalars['String']>;
  filled_item_lot_number?: Maybe<Scalars['String']>;
  filled_item_serial_number?: Maybe<Scalars['String']>;
  filled_item_bottle_number?: Maybe<Scalars['String']>;
  filled_item_manufacturer?: Maybe<Scalars['String']>;
  filled_item_instructions?: Maybe<Scalars['String']>;
  filled_date?: Maybe<Scalars['String']>;
  filled_user_id?: Maybe<Scalars['ID']>;
  filled_user_name?: Maybe<Scalars['String']>;
};

export type PrescriptionFlattened = {
  __typename?: 'PrescriptionFlattened';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  refills?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  item_quantity: Scalars['String'];
  item_description?: Maybe<Scalars['String']>;
  item_unit?: Maybe<Scalars['String']>;
  fill_externally: Scalars['Boolean'];
  created_type_id: Scalars['Int'];
  prescription_expiry_date: Scalars['String'];
  product_expiry_date?: Maybe<Scalars['String']>;
  prescribed_date: Scalars['String'];
  prescribed_user_id: Scalars['ID'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  offline_id?: Maybe<Scalars['String']>;
  prescribed_user_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  is_filled: Scalars['Boolean'];
  available_fills: Scalars['Int'];
  filled_fills: Scalars['Int'];
  remaining_fills: Scalars['Int'];
  invoice_id?: Maybe<Scalars['ID']>;
  fill_id?: Maybe<Scalars['ID']>;
  filled_service_rendered_id?: Maybe<Scalars['ID']>;
  filled_item_din?: Maybe<Scalars['String']>;
  filled_item_lot_number?: Maybe<Scalars['String']>;
  filled_item_serial_number?: Maybe<Scalars['String']>;
  filled_item_bottle_number?: Maybe<Scalars['String']>;
  filled_item_manufacturer?: Maybe<Scalars['String']>;
  filled_item_instructions?: Maybe<Scalars['String']>;
  filled_date?: Maybe<Scalars['String']>;
  filled_user_id?: Maybe<Scalars['ID']>;
  filled_user_name?: Maybe<Scalars['String']>;
};

export type Prescription = {
  __typename?: 'Prescription';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  refills?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  item_quantity: Scalars['String'];
  item_description?: Maybe<Scalars['String']>;
  item_unit?: Maybe<Scalars['String']>;
  fill_externally: Scalars['Boolean'];
  created_type_id: Scalars['Int'];
  prescription_expiry_date: Scalars['String'];
  product_expiry_date?: Maybe<Scalars['String']>;
  prescribed_date: Scalars['String'];
  prescribed_user_id: Scalars['ID'];
  created: Scalars['String'];
  created_user_id: Scalars['ID'];
  updated: Scalars['String'];
  updated_user_id?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  offline_id?: Maybe<Scalars['String']>;
  prescribed_user_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  is_filled: Scalars['Boolean'];
  available_fills: Scalars['Int'];
  filled_fills: Scalars['Int'];
  remaining_fills: Scalars['Int'];
  invoice_id?: Maybe<Scalars['ID']>;
};

export type PrescriptionUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<PrescriptionRecordUpsert>;
  fills?: Maybe<Array<PrescriptionFillUpsert>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionRecordUpsert = {
  offline_id?: Maybe<Scalars['ID']>;
  practice_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['ID']>;
  refills: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  item_quantity: Scalars['String'];
  item_description?: Maybe<Scalars['String']>;
  item_unit?: Maybe<Scalars['String']>;
  filled_service_rendered_id?: Maybe<Scalars['ID']>;
  fill_externally: Scalars['Boolean'];
  created_type_id: Scalars['Int'];
  prescription_expiry_date: Scalars['String'];
  product_expiry_date?: Maybe<Scalars['String']>;
  prescribed_date: Scalars['String'];
  prescribed_user_id: Scalars['ID'];
};

export type PrescriptionFillUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<PrescriptionFillRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionFillRecordUpsert = {
  prescription_id?: Maybe<Scalars['ID']>;
  filled_item_din?: Maybe<Scalars['String']>;
  filled_item_lot_number?: Maybe<Scalars['String']>;
  filled_item_serial_number?: Maybe<Scalars['String']>;
  filled_item_bottle_number?: Maybe<Scalars['String']>;
  filled_item_manufacturer?: Maybe<Scalars['String']>;
  filled_item_instructions?: Maybe<Scalars['String']>;
  filled_date?: Maybe<Scalars['String']>;
  filled_user_id?: Maybe<Scalars['ID']>;
  filled_service_rendered_id?: Maybe<Scalars['ID']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  id: Scalars['ID'];
  offline_id?: Maybe<Scalars['ID']>;
  organization_id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
  service_rendered_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  date: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  status_id: Scalars['ID'];
  status_name: Scalars['String'];
  updated?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_send_type_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
};

export type ReminderUpsert = {
  id?: Maybe<Scalars['ID']>;
  retry_offline_id?: Maybe<Scalars['ID']>;
  record?: Maybe<ReminderRecordUpsert>;
  status_record?: Maybe<ReminderStatusRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ReminderRecordUpsert = {
  offline_id?: Maybe<Scalars['ID']>;
  practice_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  offline_patient_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  offline_contact_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  date: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type ReminderStatusRecordUpsert = {
  status_id: Scalars['ID'];
};

export type PatientAlert = {
  __typename?: 'PatientAlert';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  type_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type ContactAlert = {
  __typename?: 'ContactAlert';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  type_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type PatientContactAlert = {
  __typename?: 'PatientContactAlert';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  type_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
};

export type PatientAlertUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<PatientAlertRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type PatientAlertRecordUpsert = {
  note?: Maybe<Scalars['String']>;
  type_id: Scalars['ID'];
};

export type ContactAlertUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ContactAlertRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContactAlertRecordUpsert = {
  type_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type BusinessCenter = OrganizationReferenceData & {
  __typename?: 'BusinessCenter';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type Location = OrganizationReferenceData & {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type GeneralLedger = OrganizationReferenceData & {
  __typename?: 'GeneralLedger';
  id: Scalars['ID'];
  gl_number: Scalars['String'];
  external_gl_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  practice_id?: Maybe<Scalars['ID']>;
  is_receivables_account: Scalars['Boolean'];
};

export type AddressType = ReferenceData & {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type HeardAboutUs = ReferenceData & {
  __typename?: 'HeardAboutUs';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
};

export type Country = ReferenceData & {
  __typename?: 'Country';
  id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  currency_id: Scalars['String'];
  iso_code?: Maybe<Scalars['String']>;
  iso_code2?: Maybe<Scalars['String']>;
};

export type ProvState = ReferenceData & {
  __typename?: 'ProvState';
  id: Scalars['ID'];
  country_id: Scalars['ID'];
  name_key: Scalars['String'];
  sort_order: Scalars['Int'];
  iso_code?: Maybe<Scalars['String']>;
};

export type QuickbooksResult = {
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksCallbackCodeResult = QuickbooksResult & {
  __typename?: 'QuickbooksCallbackCodeResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksSendJournalResult = QuickbooksResult & {
  __typename?: 'QuickbooksSendJournalResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksDeleteJournalResult = QuickbooksResult & {
  __typename?: 'QuickbooksDeleteJournalResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksSendDepositRunResult = QuickbooksResult & {
  __typename?: 'QuickbooksSendDepositRunResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksDeleteDepositRunResult = QuickbooksResult & {
  __typename?: 'QuickbooksDeleteDepositRunResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksPaymentTypeUpsertResult = QuickbooksResult & {
  __typename?: 'QuickbooksPaymentTypeUpsertResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksAuthResult = QuickbooksResult & {
  __typename?: 'QuickbooksAuthResult';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type QuickbooksAccountsResponse = QuickbooksResult & {
  __typename?: 'QuickbooksAccountsResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<QuickBooksAccount>>;
};

export type QuickbooksTaxRatesResponse = QuickbooksResult & {
  __typename?: 'QuickbooksTaxRatesResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<QbTaxRate>>;
};

export type QuickbooksGetPaymentTypesResponse = QuickbooksResult & {
  __typename?: 'QuickbooksGetPaymentTypesResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<QbPaymentType>>;
};

export type QuickBooksActionInput = {
  organizationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  cognitoId?: Maybe<Scalars['String']>;
  action?: Maybe<QuickBooksActionProperties>;
};

export type QuickBooksActionProperties = {
  callbackCodeRequest?: Maybe<QuickbooksCallbackCodeRequest>;
  authRequest?: Maybe<QuickbooksAuthRequest>;
  paymentTypeUpsert?: Maybe<QuickbooksPaymentTypeUpsertRequest>;
  quickbooksAccountsRequest?: Maybe<QuickbooksGetAccountsRequest>;
  getTaxRates?: Maybe<QuickbooksGetTaxRatesRequest>;
  sendJournalRequest?: Maybe<QuickbooksSendJournalRequest>;
  deleteJournalRequest?: Maybe<QuickbooksDeleteJournalRequest>;
  sendDepositRunRequest?: Maybe<QuickbooksSendDepositRunRequest>;
  deleteDepositRunRequest?: Maybe<QuickbooksDeleteDepositRunRequest>;
  getPaymentTypes?: Maybe<QuickbooksGetPaymentTypesRequest>;
};

export type QuickbooksGetTaxRatesRequest = {
  getTaxes: Scalars['Boolean'];
};

export type QuickbooksCallbackCodeRequest = {
  queryString: Scalars['String'];
};

export type QuickbooksPaymentTypeUpsertRequest = {
  ledger_payment_type_id: Scalars['ID'];
  quickbooks_name: Scalars['String'];
};

export type QuickbooksSendJournalRequest = {
  journalId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  postingDate?: Maybe<Scalars['String']>;
};

export type QuickbooksDeleteJournalRequest = {
  journalId?: Maybe<Scalars['ID']>;
};

export type QuickbooksSendDepositRunRequest = {
  depositRunId?: Maybe<Scalars['ID']>;
};

export type QuickbooksDeleteDepositRunRequest = {
  depositRunId?: Maybe<Scalars['ID']>;
};

export type QuickbooksAuthRequest = {
  auth: Scalars['Boolean'];
};

export type QuickbooksGetAccountsRequest = {
  filter?: Maybe<QueryQuickbooksAccountsFilter>;
};

export type QuickbooksGetPaymentTypesRequest = {
  getPayment: Scalars['Boolean'];
};

export type ChatBotGetAuthTokenResponse = {
  __typename?: 'ChatBotGetAuthTokenResponse';
  jwt?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  chatBotEndpoint?: Maybe<Scalars['String']>;
};

export type SmtpMessageResult = {
  __typename?: 'SMTPMessageResult';
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type CommunicationLog = {
  __typename?: 'CommunicationLog';
  id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  recipients: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
  source_log_id?: Maybe<Scalars['ID']>;
  message_type: Scalars['Int'];
  message_format: Scalars['Int'];
};

export type CommunicationLogAttachments = {
  __typename?: 'CommunicationLogAttachments';
  id: Scalars['ID'];
  log_id: Scalars['ID'];
  file_name?: Maybe<Scalars['String']>;
  file_pointer?: Maybe<Scalars['String']>;
};

export type EmailSendQueueMessage = {
  __typename?: 'EmailSendQueueMessage';
  communication_log_id: Scalars['ID'];
};

export type SmtpMessage = {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  recipients: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  mailFormat?: Maybe<Scalars['String']>;
  attachment_list?: Maybe<Array<Maybe<CommunicationLogAttachmentUpsert>>>;
  source_log_id?: Maybe<Scalars['ID']>;
};

export type CommunicationLogUpsert = {
  id: Scalars['ID'];
  record?: Maybe<CommunicationLogRecordUpsert>;
  void?: Maybe<Scalars['Boolean']>;
};

export type CommunicationLogRecordUpsert = {
  contact_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  recipients: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
  source_log_id?: Maybe<Scalars['ID']>;
  message_type: Scalars['Int'];
  message_format: Scalars['Int'];
};

export type CommunicationLogAttachmentUpsert = {
  log_id?: Maybe<Scalars['ID']>;
  file_name?: Maybe<Scalars['String']>;
  file_pointer?: Maybe<Scalars['String']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type SmtpSettingsUpsert = {
  serverName?: Maybe<Scalars['String']>;
  portNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fromAddress: Scalars['String'];
  replyToAddress?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  requiresSSL?: Maybe<Scalars['Boolean']>;
  useSES?: Maybe<Scalars['Boolean']>;
};

export type SmtpSettings = {
  __typename?: 'SMTPSettings';
  serverName?: Maybe<Scalars['String']>;
  portNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fromAddress: Scalars['String'];
  replyToAddress?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  requiresSSL?: Maybe<Scalars['Boolean']>;
  useSES?: Maybe<Scalars['Boolean']>;
};

export type InterestConfig = {
  __typename?: 'InterestConfig';
  interestRate: Scalars['String'];
  isCompounded: Scalars['Boolean'];
  chargeFreePeriod: Scalars['Int'];
};

export type InterestRunRecord = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  run_date: Scalars['String'];
  rate: Scalars['String'];
  compound: Scalars['Boolean'];
  charge_free: Scalars['Int'];
  has_charge: Scalars['Boolean'];
};

export type InterestRunContacts = InterestRunRecord & {
  __typename?: 'InterestRunContacts';
  id: Scalars['ID'];
  practice_id: Scalars['ID'];
  number: Scalars['String'];
  run_date: Scalars['String'];
  rate: Scalars['String'];
  compound: Scalars['Boolean'];
  charge_free: Scalars['Int'];
  has_charge: Scalars['Boolean'];
  contact?: Maybe<Array<ContactInterestEntry>>;
};

export type InterestRunFilterOptions = {
  practice_id: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
};

export type InterestRun = InterestRunRecord & {
  __typename?: 'InterestRun';
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  run_date: Scalars['String'];
  rate: Scalars['String'];
  compound: Scalars['Boolean'];
  charge_free: Scalars['Int'];
  has_charge: Scalars['Boolean'];
};

export type ContactInterestEntry = {
  __typename?: 'ContactInterestEntry';
  ledger_id?: Maybe<Scalars['ID']>;
  contact_id: Scalars['ID'];
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_email?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  bucket0?: Maybe<Scalars['String']>;
  bucket31?: Maybe<Scalars['String']>;
  bucket61?: Maybe<Scalars['String']>;
  bucket91?: Maybe<Scalars['String']>;
  bucket121?: Maybe<Scalars['String']>;
  interest: Scalars['String'];
};

export type InterestRunUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<InterestRunRecordUpsert>;
  configRecord?: Maybe<InterestRunConfigRecordUpsert>;
  fnCharge?: Maybe<Array<Scalars['ID']>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type InterestRunRecordUpsert = {
  practice_id: Scalars['String'];
  run_date: Scalars['String'];
};

export type InterestRunConfigRecordUpsert = {
  rate: Scalars['String'];
  compound: Scalars['Boolean'];
  charge_free: Scalars['Int'];
};

export type StatementRun = {
  __typename?: 'StatementRun';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  number: Scalars['String'];
  start_date?: Maybe<Scalars['String']>;
  end_date: Scalars['String'];
  pending?: Maybe<Array<StatementContactPending>>;
  sent?: Maybe<Array<StatementContactSent>>;
};

export type StatementContactInterface = {
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
};

export type StatementContactPending = StatementContactInterface & {
  __typename?: 'StatementContactPending';
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
  starting_balance: Scalars['String'];
  ending_balance: Scalars['String'];
  transactions: Scalars['Int'];
};

export type StatementContactSent = StatementContactInterface & {
  __typename?: 'StatementContactSent';
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
  sent?: Maybe<Scalars['String']>;
  stale: Scalars['Boolean'];
  last_send_error?: Maybe<Scalars['String']>;
  last_send_status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpsertStatementRunJobResult = {
  __typename?: 'UpsertStatementRunJobResult';
  result: StatementRun;
  reportPointer?: Maybe<Scalars['String']>;
};

export type StatementSendQueueMessage = {
  __typename?: 'StatementSendQueueMessage';
  statement_id: Scalars['ID'];
};

export type StatementRunUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<StatementRunRecordUpsert>;
  fnSendEmails?: Maybe<Array<Scalars['ID']>>;
  fnPrint?: Maybe<Array<Scalars['ID']>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type StatementRunRecordUpsert = {
  practice_id: Scalars['String'];
  end_date: Scalars['String'];
};

export type ReminderBatch = {
  __typename?: 'ReminderBatch';
  id: Scalars['ID'];
  practice_id: Scalars['ID'];
  number: Scalars['String'];
  date_sent?: Maybe<Scalars['String']>;
};

export type ReminderBatchDetail = {
  __typename?: 'ReminderBatchDetail';
  id: Scalars['ID'];
  practice_id?: Maybe<Scalars['ID']>;
  organization_id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  sent_type?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Boolean']>;
  reminder_id: Scalars['ID'];
  reminder_batch_id: Scalars['ID'];
  reminder_status_id?: Maybe<Scalars['ID']>;
  reminder_status_name?: Maybe<Scalars['String']>;
  reminder_name?: Maybe<Scalars['String']>;
  reminder_note?: Maybe<Scalars['String']>;
  reminder_date?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_send_type_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  reminder_sent_type_name?: Maybe<Scalars['String']>;
};

export type ReminderContactInterface = {
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
};

export type ReminderContactPending = ReminderContactInterface & {
  __typename?: 'ReminderContactPending';
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
  last_send_error?: Maybe<Scalars['String']>;
  last_send_status?: Maybe<Scalars['String']>;
};

export type ReminderContactSent = ReminderContactInterface & {
  __typename?: 'ReminderContactSent';
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  contact_status_id: Scalars['ID'];
  contact_status_name: Scalars['String'];
  contact_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_cc?: Maybe<Scalars['String']>;
  send_type: Scalars['Int'];
  sent?: Maybe<Scalars['String']>;
  stale: Scalars['Boolean'];
  last_send_error?: Maybe<Scalars['String']>;
  last_send_status?: Maybe<Scalars['String']>;
};

export type UpsertReminderBatchJobResult = {
  __typename?: 'UpsertReminderBatchJobResult';
  result: ReminderBatch;
  reportPointer?: Maybe<Scalars['String']>;
};

export type ReminderBatchUpsert = {
  id?: Maybe<Scalars['ID']>;
  record?: Maybe<ReminderBatchRecordUpsert>;
  fnSendEmails?: Maybe<Array<Scalars['ID']>>;
  fnPrint?: Maybe<Array<Scalars['ID']>>;
  fnCancel?: Maybe<Array<Scalars['ID']>>;
  fnComplete?: Maybe<Array<Scalars['ID']>>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ReminderBatchRecordUpsert = {
  practice_id: Scalars['String'];
  reminder_body?: Maybe<Scalars['String']>;
};

export type MaintenanceMessage = {
  __typename?: 'MaintenanceMessage';
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  message_key: Scalars['String'];
  redirect_url?: Maybe<Scalars['String']>;
};

export type DataConversion = {
  __typename?: 'DataConversion';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  organization_id: Scalars['String'];
  software_id?: Maybe<Scalars['String']>;
  software_name: Scalars['String'];
  software_version?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<DataConversionAttachment>>>;
  instructions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  closed: Scalars['Boolean'];
};

export type DataConversionAttachment = {
  __typename?: 'DataConversionAttachment';
  id: Scalars['ID'];
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  file_type_id: Scalars['Int'];
};

export type DataConversionAttachmentUpsert = {
  name: Scalars['String'];
  pointer: Scalars['String'];
  size: Scalars['Int'];
  file_type_id: Scalars['Int'];
};

export type DataConversionUpsert = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  organization_id: Scalars['String'];
  software_id?: Maybe<Scalars['String']>;
  software_name: Scalars['String'];
  software_version?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<DataConversionAttachmentUpsert>>>;
  instructions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum ElasticDocumentType {
  Patient = 'Patient',
  Invoice = 'Invoice',
  Ledger = 'Ledger',
  Contact = 'Contact',
  Service = 'Service',
  Organization = 'Organization',
  All = 'All',
}

export enum CompareAction {
  CompareReport = 'CompareReport',
  CompareSync = 'CompareSync',
}

export type DocumentFilter = {
  type: ElasticDocumentType;
  id?: Maybe<Scalars['ID']>;
  max?: Maybe<Scalars['Int']>;
  ignoreNested?: Maybe<Scalars['Boolean']>;
  allOrganizations?: Maybe<Scalars['Boolean']>;
  compare?: Maybe<CompareAction>;
  since?: Maybe<Scalars['String']>;
  useBulkQueue?: Maybe<Scalars['Boolean']>;
};

export type RefreshDocumentResult = {
  __typename?: 'RefreshDocumentResult';
  queuedCount: Scalars['Int'];
  compareResult?: Maybe<Array<DocumentCompareResult>>;
};

export type DocumentCompareResult = {
  __typename?: 'DocumentCompareResult';
  id: Scalars['ID'];
  organization_id: Scalars['ID'];
  document_type: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  hidden_pg?: Maybe<Scalars['Boolean']>;
  updated_pg?: Maybe<Scalars['String']>;
  updated_es?: Maybe<Scalars['String']>;
  action: Scalars['String'];
};

export type QueryPaging = {
  start_id?: Maybe<Scalars['ID']>;
  page_size: Scalars['Int'];
};

export type QueryLedgerFilter = {
  invoice_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  paging?: Maybe<QueryPaging>;
};

export type QueryInvoiceFilter = {
  contact_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  paging?: Maybe<QueryPaging>;
};

export type QueryServiceFilter = {
  id?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  paging?: Maybe<QueryPaging>;
};

export type QueryBundledServiceFilter = {
  id?: Maybe<Scalars['String']>;
  paging?: Maybe<QueryPaging>;
};

export type QueryStatementDetailsFilter = {
  statement_run_id: Scalars['ID'];
};

export type MetricsOptions = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type QueryLabRefFilter = {
  lab_device_id: Scalars['ID'];
  species_id?: Maybe<Scalars['ID']>;
};

export type QueryLabResultFilter = {
  lab_device_id: Scalars['ID'];
  order_number?: Maybe<Scalars['String']>;
  accession_number?: Maybe<Scalars['String']>;
};

export type QueryThirdPartyRefFilter = {
  third_party_id: Scalars['ID'];
  species_id?: Maybe<Scalars['ID']>;
};

export type QueryLabSettingFilter = {
  lab_device_id?: Maybe<Scalars['ID']>;
};

export type QueryThirdPartyValidationFilter = {
  third_party_id: Scalars['ID'];
  email_address?: Maybe<Scalars['String']>;
};

export type QueryQuickbooksAccountsFilter = {
  searchText?: Maybe<Scalars['String']>;
};

export type QueryUserOrganizationFilter = {
  user_id?: Maybe<Scalars['ID']>;
  cognito_id?: Maybe<Scalars['String']>;
  returnHidden?: Maybe<Scalars['Boolean']>;
};

export type QueryUserOrganizationCardFilter = {
  user_id?: Maybe<Scalars['ID']>;
  user_organization_id?: Maybe<Scalars['ID']>;
};

export type DateRange = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
};

export type RptHeader = {
  __typename?: 'RptHeader';
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  practice_name: Scalars['String'];
  practice_address_1: Scalars['String'];
  practice_address_2?: Maybe<Scalars['String']>;
  practice_address_3?: Maybe<Scalars['String']>;
  practice_city?: Maybe<Scalars['String']>;
  practice_provstate?: Maybe<Scalars['String']>;
  practice_postal_zip?: Maybe<Scalars['String']>;
  practice_country?: Maybe<Scalars['String']>;
  practice_phone?: Maybe<Scalars['String']>;
  practice_tax_id?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type RptDeposit = {
  __typename?: 'RptDeposit';
  report_header: RptHeader;
  deposit_details: Array<Maybe<RptDepositDetail>>;
};

export type RptDepositDetail = {
  __typename?: 'RptDepositDetail';
  deposit_run_id: Scalars['ID'];
  deposit_run_number: Scalars['String'];
  deposit_run_begin_date?: Maybe<Scalars['String']>;
  deposit_run_end_date?: Maybe<Scalars['String']>;
  deposit_run_send_date?: Maybe<Scalars['String']>;
  payment_date?: Maybe<Scalars['String']>;
  ledger_number?: Maybe<Scalars['String']>;
  financial_amount?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  payment_type: Scalars['String'];
};

export type RptProductionDetail = {
  __typename?: 'RptProductionDetail';
  doctor_id: Scalars['ID'];
  service_id: Scalars['ID'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  billed_quantity: Scalars['String'];
  total_billed: Scalars['String'];
};

export type RptProduction = {
  __typename?: 'RptProduction';
  report_header: RptHeader;
  production_details?: Maybe<Array<Maybe<RptProductionDetail>>>;
};

export type RptStatementParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_id: Array<Scalars['ID']>;
  footer?: Maybe<Scalars['String']>;
};

export type RptStatement = {
  __typename?: 'RptStatement';
  report_header: RptStatementHeader;
  contact_detail?: Maybe<Array<Maybe<RptStatementContactDetail>>>;
  ledger_detail?: Maybe<Array<Maybe<RptStatementLedgerDetail>>>;
  service_rendered_detail?: Maybe<Array<Maybe<RptStatementServiceRenderedDetail>>>;
};

export type RptStatementReport_HeaderArgs = {
  reportParameters: RptStatementParameters;
};

export type RptStatementContact_DetailArgs = {
  reportParameters: RptStatementParameters;
};

export type RptStatementLedger_DetailArgs = {
  reportParameters: RptStatementParameters;
};

export type RptStatementService_Rendered_DetailArgs = {
  reportParameters: RptStatementParameters;
};

export type RptStatementHeader = RptPracticeHeaderInterface & {
  __typename?: 'RptStatementHeader';
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  practice_name: Scalars['String'];
  practice_address_1: Scalars['String'];
  practice_address_2?: Maybe<Scalars['String']>;
  practice_address_3?: Maybe<Scalars['String']>;
  practice_city: Scalars['String'];
  practice_provstate: Scalars['String'];
  practice_postal_zip: Scalars['String'];
  practice_country?: Maybe<Scalars['String']>;
  practice_phone?: Maybe<Scalars['String']>;
  practice_tax_id?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  currency_setting?: Maybe<OrganizationCurrencySetting>;
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_ids?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
};

export type RptStatementContactDetail = {
  __typename?: 'RptStatementContactDetail';
  contact_id: Scalars['ID'];
  contact_name: Scalars['ID'];
  contact_number: Scalars['String'];
  contact_address_1?: Maybe<Scalars['String']>;
  contact_address_2?: Maybe<Scalars['String']>;
  contact_address_3?: Maybe<Scalars['String']>;
  contact_city?: Maybe<Scalars['String']>;
  contact_provstate?: Maybe<Scalars['String']>;
  contact_country?: Maybe<Scalars['String']>;
  contact_postal_zip?: Maybe<Scalars['String']>;
  contact_balance_forward?: Maybe<Scalars['String']>;
  contact_balance_ending?: Maybe<Scalars['String']>;
};

export type RptStatementLedgerDetail = {
  __typename?: 'RptStatementLedgerDetail';
  contact_id: Scalars['ID'];
  ledger_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  invoice_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  financial_date?: Maybe<Scalars['String']>;
  type_name_key?: Maybe<Scalars['String']>;
  payment_type_name_key?: Maybe<Scalars['String']>;
  check_number?: Maybe<Scalars['String']>;
  ledger_type_name_key?: Maybe<Scalars['String']>;
  ledger_number?: Maybe<Scalars['String']>;
  ledger_payment_payer?: Maybe<Scalars['String']>;
  financial_amount?: Maybe<Scalars['String']>;
  reversal?: Maybe<Scalars['Boolean']>;
  invoice_number?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_trainer?: Maybe<Scalars['String']>;
  invoice_tax_detail?: Maybe<Array<Maybe<RptStatementLedgerInvoiceTaxDetail>>>;
};

export type RptStatementServiceRenderedDetail = {
  __typename?: 'RptStatementServiceRenderedDetail';
  contact_id: Scalars['ID'];
  ledger_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  invoice_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  service_rendered_date?: Maybe<Scalars['String']>;
  service_rendered_created?: Maybe<Scalars['String']>;
  doctor_name?: Maybe<Scalars['String']>;
  doctor_abbreviation?: Maybe<Scalars['String']>;
  service_rendered_name?: Maybe<Scalars['String']>;
  service_rendered_quantity?: Maybe<Scalars['String']>;
  service_rendered_unit_price?: Maybe<Scalars['String']>;
  service_rendered_unit_name?: Maybe<Scalars['String']>;
  service_rendered_total?: Maybe<Scalars['String']>;
  service_rendered_list_unit_price?: Maybe<Scalars['String']>;
  service_rendered_list_total?: Maybe<Scalars['String']>;
  service_rendered_discount?: Maybe<Scalars['String']>;
  service_rendered_discount_percent?: Maybe<Scalars['String']>;
  service_note?: Maybe<Scalars['String']>;
  hide_discount?: Maybe<Scalars['Boolean']>;
};

export type RptStatementLedgerInvoiceTaxDetail = {
  __typename?: 'RptStatementLedgerInvoiceTaxDetail';
  invoice_id: Scalars['ID'];
  tax_type_id: Scalars['ID'];
  tax_type_code: Scalars['String'];
  total?: Maybe<Scalars['String']>;
};

export type RptControlledDrugLog = {
  __typename?: 'RptControlledDrugLog';
  controlled_drug_log_details: Array<Maybe<RptControlledDrugLogDetail>>;
  report_header: RptHeader;
};

export type RptControlledDrugLogDetail = {
  __typename?: 'RptControlledDrugLogDetail';
  service_rendered_id: Scalars['ID'];
  service_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
  invoice_id?: Maybe<Scalars['ID']>;
  patient_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  doctor_name?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  unit_name?: Maybe<Scalars['String']>;
  bottle_number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  primary_owner_name: Scalars['String'];
  contact_address_1?: Maybe<Scalars['String']>;
  contact_address_2?: Maybe<Scalars['String']>;
  contact_address_3?: Maybe<Scalars['String']>;
  contact_city?: Maybe<Scalars['String']>;
  contact_provstate?: Maybe<Scalars['String']>;
  contact_country?: Maybe<Scalars['String']>;
  contact_postal_zip?: Maybe<Scalars['String']>;
};

export type RptGeneralLedger = {
  __typename?: 'RptGeneralLedger';
  general_ledger_details: Array<Maybe<RptGeneralLedgerDetail>>;
  report_header: RptHeader;
};

export type RptGeneralLedgerDetail = {
  __typename?: 'RptGeneralLedgerDetail';
  doctor_id: Scalars['ID'];
  service_id: Scalars['ID'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  billed_qty: Scalars['String'];
  total_billed: Scalars['String'];
  general_ledger_name?: Maybe<Scalars['String']>;
  general_ledger_number?: Maybe<Scalars['String']>;
};

export type RptInvoiceActivity = {
  __typename?: 'RptInvoiceActivity';
  report_header: RptHeader;
  invoice_details: Array<Maybe<RptInvoiceActivityInvoiceDetail>>;
  invoice_ownership_details: Array<Maybe<RptInvoiceActivityInvoiceOwnershipDetail>>;
};

export type RptInvoiceActivityInvoiceOwnershipDetail = {
  __typename?: 'RptInvoiceActivityInvoiceOwnershipDetail';
  invoice_id: Scalars['ID'];
  name: Scalars['String'];
  percentage: Scalars['String'];
};

export type RptInvoiceActivityInvoiceDetail = {
  __typename?: 'RptInvoiceActivityInvoiceDetail';
  invoice_id: Scalars['ID'];
  invoice_number: Scalars['String'];
  invoice_date: Scalars['String'];
  patient_name: Scalars['String'];
  service_rendered: Scalars['String'];
  service_date: Scalars['String'];
  doctor_name: Scalars['String'];
  quantity: Scalars['String'];
  list_unit_price: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  service_total: Scalars['String'];
  invoice_total: Scalars['String'];
  total_no_tax: Scalars['String'];
  total_tax: Scalars['String'];
  patient_trainer?: Maybe<Scalars['String']>;
  service_created: Scalars['String'];
  service_sort_order: Scalars['String'];
};

export type RptDailyInvoiceActivity = {
  __typename?: 'RptDailyInvoiceActivity';
  daily_invoice_activity_details: Array<Maybe<RptInvoiceActivityInvoiceDetail>>;
  report_header: RptHeader;
};

export type RptDailyInvoiceActivityDaily_Invoice_Activity_DetailsArgs = {
  reportParameters: DateRange;
};

export type RptDailyInvoiceActivityReport_HeaderArgs = {
  reportParameters: DateRange;
};

export type RptJournalEntry = {
  __typename?: 'RptJournalEntry';
  report_header: RptHeader;
  journal_details: Array<Maybe<RptJournalEntryDetail>>;
};

export type RptJournalEntryDetail = {
  __typename?: 'RptJournalEntryDetail';
  journal_id: Scalars['ID'];
  journal_date?: Maybe<Scalars['String']>;
  journal_note: Scalars['String'];
  send_status_description?: Maybe<Scalars['String']>;
  sent_date?: Maybe<Scalars['String']>;
  journal_number?: Maybe<Scalars['String']>;
  line_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  general_ledger_name: Scalars['String'];
  general_ledger_number: Scalars['String'];
  amount: Scalars['String'];
};

export type RptReminderByContactParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_id: Scalars['ID'];
};

export type RptSubscriptionInvoiceParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  electronic_payment_id: Scalars['ID'];
};

export type RptPortalReceiptParameters = {
  payment_id: Scalars['ID'];
};

export type RptInvoiceActivityByTrainerParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_id: Scalars['ID'];
};

export type RptInvoiceActivityByTrainer = {
  __typename?: 'RptInvoiceActivityByTrainer';
  report_header: RptHeader;
  invoice_activity_by_trainer_details: Array<Maybe<RptInvoiceActivityInvoiceDetail>>;
  invoice_ownership_details: Array<Maybe<RptInvoiceActivityInvoiceOwnershipDetail>>;
};

export type RptLedgerEntry = {
  __typename?: 'RptLedgerEntry';
  report_header: RptHeader;
  ledger_details: Array<Maybe<RptLedgerEntryDetail>>;
};

export type RptLedgerEntryDetail = {
  __typename?: 'RptLedgerEntryDetail';
  ledger_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  type_id?: Maybe<Scalars['ID']>;
  financial_date: Scalars['String'];
  ledger_number?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  ledger_type_name?: Maybe<Scalars['String']>;
  total_no_tax: Scalars['String'];
  total_tax: Scalars['String'];
  total: Scalars['String'];
  ledger_note?: Maybe<Scalars['String']>;
  financial_amount?: Maybe<Scalars['String']>;
  reversal?: Maybe<Scalars['Boolean']>;
};

export type RptAr = {
  __typename?: 'RptAR';
  report_header: RptHeader;
  ar_details: Array<Maybe<RptArDetail>>;
};

export type RptArDetail = {
  __typename?: 'RptARDetail';
  contact_id: Scalars['ID'];
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  contact_status_name: Scalars['String'];
  bucket0?: Maybe<Scalars['String']>;
  bucket31?: Maybe<Scalars['String']>;
  bucket61?: Maybe<Scalars['String']>;
  bucket91?: Maybe<Scalars['String']>;
  bucket121?: Maybe<Scalars['String']>;
  total: Scalars['String'];
  effective_date: Scalars['String'];
  last_payment_date?: Maybe<Scalars['String']>;
};

export type RptCommunicationLog = {
  __typename?: 'RptCommunicationLog';
  report_header: RptHeader;
  communication_log_details: Array<Maybe<RptCommunicationLogDetail>>;
};

export type RptContactReceipt = {
  __typename?: 'RptContactReceipt';
  report_header: RptHeader;
  contact_receipt_details: Array<Maybe<RptContactReceiptDetail>>;
};

export type RptContactReceiptDetail = {
  __typename?: 'RptContactReceiptDetail';
  ledger_id?: Maybe<Scalars['ID']>;
  ledger_number?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  financial_date?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  card_display_number?: Maybe<Scalars['String']>;
  check_number?: Maybe<Scalars['String']>;
  ledger_type_name?: Maybe<Scalars['String']>;
  payment_type_name?: Maybe<Scalars['String']>;
  payment_payer?: Maybe<Scalars['String']>;
  ledger_note?: Maybe<Scalars['String']>;
  contact_address_1?: Maybe<Scalars['String']>;
  contact_address_2?: Maybe<Scalars['String']>;
  contact_address_3?: Maybe<Scalars['String']>;
  contact_city?: Maybe<Scalars['String']>;
  contact_stateprov?: Maybe<Scalars['String']>;
  contact_postal_zip?: Maybe<Scalars['String']>;
};

export type RptCommunicationLogDetail = {
  __typename?: 'RptCommunicationLogDetail';
  communication_log_id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  recipients?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  message_sent?: Maybe<Scalars['Boolean']>;
  contact_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
};

export type RptNewContactsAndPatients = {
  __typename?: 'RptNewContactsAndPatients';
  report_header: RptHeader;
  new_contacts_and_patients_details: Array<Maybe<RptNewContactsAndPatientsDetail>>;
};

export type RptNewContactsAndPatientsDetail = {
  __typename?: 'RptNewContactsAndPatientsDetail';
  record_type: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_name_2?: Maybe<Scalars['String']>;
  patient_name_3?: Maybe<Scalars['String']>;
  species_name?: Maybe<Scalars['String']>;
  breed_name?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  primary_owner_name?: Maybe<Scalars['String']>;
  deceased?: Maybe<Scalars['Boolean']>;
  deceased_date?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  patient_created?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_status_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contact_created?: Maybe<Scalars['String']>;
  send_type?: Maybe<Scalars['String']>;
  statement_ignore?: Maybe<Scalars['String']>;
  interest_ignore?: Maybe<Scalars['String']>;
};

export type RptByContactAndDateRangeParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_id: Scalars['ID'];
};

export type RptContactReceiptParameters = {
  contact_id: Scalars['ID'];
  ledger_id: Scalars['ID'];
};

export type RptByContactOrPatientAndServiceParameters = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  contact_id?: Maybe<Scalars['ID']>;
  service_id?: Maybe<Scalars['ID']>;
  patient_id?: Maybe<Scalars['ID']>;
};

export type RptPatientProcedureByContact = {
  __typename?: 'RptPatientProcedureByContact';
  report_header: RptHeader;
  patient_procedure_by_contact_details: Array<Maybe<RptPatientProcedureByContactDetail>>;
};

export type RptPatientProcedureByContactDetail = {
  __typename?: 'RptPatientProcedureByContactDetail';
  service_rendered_id: Scalars['ID'];
  service_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  invoice_number: Scalars['String'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  service_date: Scalars['String'];
  contact_name: Scalars['String'];
  contact_type: Scalars['String'];
};

export type RptPatientProcedure = {
  __typename?: 'RptPatientProcedure';
  report_header: RptHeader;
  patient_procedure_details: Array<Maybe<RptPatientProcedureDetail>>;
};

export type RptPatientProcedureDetail = {
  __typename?: 'RptPatientProcedureDetail';
  service_rendered_id: Scalars['ID'];
  service_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  invoice_number: Scalars['String'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  service_date: Scalars['String'];
  contact_name: Scalars['String'];
  contact_type: Scalars['String'];
};

export type RptPayments = {
  __typename?: 'RptPayments';
  report_header: RptHeader;
  payments_details: Array<Maybe<RptPaymentsDetail>>;
};

export type RptPaymentsDetail = {
  __typename?: 'RptPaymentsDetail';
  ledger_id: Scalars['ID'];
  financial_date: Scalars['String'];
  created_user_name: Scalars['String'];
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  total: Scalars['String'];
  payment_type: Scalars['String'];
  reversal?: Maybe<Scalars['Boolean']>;
  check_number?: Maybe<Scalars['String']>;
  card_display_number?: Maybe<Scalars['String']>;
  ledger_payer?: Maybe<Scalars['String']>;
  ledger_note?: Maybe<Scalars['String']>;
};

export type RptSubscriptionPayments = {
  __typename?: 'RptSubscriptionPayments';
  subscription_payments_details: Array<Maybe<RptSubscriptionPaymentsDetail>>;
};

export type RptSubscriptionPaymentsDetail = {
  __typename?: 'RptSubscriptionPaymentsDetail';
  organization_id: Scalars['ID'];
  practice_name: Scalars['String'];
  payment_id?: Maybe<Scalars['ID']>;
  payment_date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  currency_id: Scalars['String'];
  display_number: Scalars['String'];
  name_on_card?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
  end_date: Scalars['String'];
};

export type RptInvoice = {
  __typename?: 'RptInvoice';
  invoiceId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  report_header: RptHeader;
  invoice_details: Array<Maybe<RptInvoiceDetail>>;
  contact_details: Array<Maybe<RptInvoiceContactDetail>>;
  tax_details: Array<Maybe<RptInvoiceTaxDetails>>;
  split_details: Array<Maybe<RptInvoiceSplitDetails>>;
};

export type RptInvoiceDetail = {
  __typename?: 'RptInvoiceDetail';
  invoice_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  doctor_id: Scalars['ID'];
  invoice_number: Scalars['String'];
  patient_name: Scalars['String'];
  service_rendered_created: Scalars['String'];
  service_rendered_date: Scalars['String'];
  doctor: Scalars['String'];
  service_rendered_name: Scalars['String'];
  service_rendered_quantity?: Maybe<Scalars['String']>;
  service_rendered_unit_price?: Maybe<Scalars['String']>;
  service_rendered_unit_name?: Maybe<Scalars['String']>;
  service_rendered_total: Scalars['String'];
  service_rendered_list_total: Scalars['String'];
  service_rendered_list_unit_price?: Maybe<Scalars['String']>;
  service_rendered_list_discount?: Maybe<Scalars['String']>;
  service_rendered_list_discount_percent?: Maybe<Scalars['String']>;
  service_note?: Maybe<Scalars['String']>;
  service_sort_order?: Maybe<Scalars['Int']>;
  patient_trainer?: Maybe<Scalars['String']>;
  hide_discount?: Maybe<Scalars['Boolean']>;
  footer?: Maybe<Scalars['String']>;
};

export type RptInvoiceContactDetail = {
  __typename?: 'RptInvoiceContactDetail';
  contact_name: Scalars['String'];
  contact_number: Scalars['String'];
  contact_percentage: Scalars['String'];
  contact_address_1?: Maybe<Scalars['String']>;
  contact_address_2?: Maybe<Scalars['String']>;
  contact_address_3?: Maybe<Scalars['String']>;
  contact_city?: Maybe<Scalars['String']>;
  contact_provstate?: Maybe<Scalars['String']>;
  contact_country?: Maybe<Scalars['String']>;
  contact_postal_zip?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  invoice_date: Scalars['String'];
  ledger_total_no_tax: Scalars['String'];
  ledger_total_tax: Scalars['String'];
  ledger_total: Scalars['String'];
};

export type RptInvoiceSplitDetails = {
  __typename?: 'RptInvoiceSplitDetails';
  invoice_id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_percentage?: Maybe<Scalars['String']>;
};

export type RptInvoiceTaxDetails = {
  __typename?: 'RptInvoiceTaxDetails';
  invoice_number?: Maybe<Scalars['String']>;
  invoice_date?: Maybe<Scalars['String']>;
  ledger_total_no_tax?: Maybe<Scalars['String']>;
  ledger_total_tax?: Maybe<Scalars['String']>;
  ledger_total?: Maybe<Scalars['String']>;
  tax_name?: Maybe<Scalars['String']>;
  tax_name_total?: Maybe<Scalars['String']>;
};

export type RptSubscriptions = {
  __typename?: 'RptSubscriptions';
  report_header: RptHeader;
  subscription_details: Array<Maybe<RptSubscriptionDetail>>;
};

export type RptSubscriptionDetail = {
  __typename?: 'RptSubscriptionDetail';
  organization_id: Scalars['ID'];
  organization: Scalars['String'];
  start_date: Scalars['String'];
  user_count: Scalars['String'];
  owner: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  last_billed_date?: Maybe<Scalars['String']>;
  next_bill_date?: Maybe<Scalars['String']>;
  email_setup: Scalars['String'];
  card_connect_setup: Scalars['String'];
};

export type RptSubscriptionsInvoice = {
  __typename?: 'RptSubscriptionsInvoice';
  report_header: RptHeader;
  subscription_invoice_details: Array<Maybe<RptSubscriptionInvoiceDetail>>;
};

export type RptSubscriptionInvoiceDetail = {
  __typename?: 'RptSubscriptionInvoiceDetail';
  payment_id?: Maybe<Scalars['ID']>;
  invoice_date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['String']>;
  display_number?: Maybe<Scalars['String']>;
  name_on_card?: Maybe<Scalars['String']>;
};

export type RptTax = {
  __typename?: 'RptTax';
  report_header: RptHeader;
  tax_details: Array<Maybe<RptTaxDetail>>;
};

export type RptTaxDetail = {
  __typename?: 'RptTaxDetail';
  invoice_id: Scalars['ID'];
  tax_type_id: Scalars['ID'];
  taxable_amount: Scalars['String'];
  tax_name: Scalars['String'];
  code: Scalars['String'];
  percentage: Scalars['String'];
  financial_date: Scalars['String'];
  invoice_number: Scalars['String'];
  contact_id: Scalars['ID'];
  primary_contact: Scalars['String'];
  total_tax: Scalars['String'];
};

export type RptTopSales = {
  __typename?: 'RptTopSales';
  report_header: RptHeader;
  top_sales_details: Array<Maybe<RptTopSalesDetail>>;
};

export type RptTopSalesDetail = {
  __typename?: 'RptTopSalesDetail';
  contact_name: Scalars['String'];
  total_not_tax: Scalars['String'];
  total_tax: Scalars['String'];
  total: Scalars['String'];
};

export type RptReminderByContact = {
  __typename?: 'RptReminderByContact';
  report_header: RptHeader;
  reminder_by_contact_details: Array<Maybe<RptReminderByContactDetail>>;
};

export type RptContactPayment = {
  __typename?: 'RptContactPayment';
  report_header: RptHeader;
  contact_payment_details: Array<Maybe<RptContactPaymentDetail>>;
};

export type RptPortalReceipt = {
  __typename?: 'RptPortalReceipt';
  report_header: RptPortalReceiptHeader;
  portal_receipt_details: Array<Maybe<RptPortalReceiptDetail>>;
};

export type RptPortalReceiptHeader = {
  __typename?: 'RptPortalReceiptHeader';
  organization_id?: Maybe<Scalars['ID']>;
  practice_id?: Maybe<Scalars['ID']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_address_1?: Maybe<Scalars['String']>;
  practice_address_2?: Maybe<Scalars['String']>;
  practice_address_3?: Maybe<Scalars['String']>;
  practice_city?: Maybe<Scalars['String']>;
  practice_provstate?: Maybe<Scalars['String']>;
  practice_postal_zip?: Maybe<Scalars['String']>;
  practice_country?: Maybe<Scalars['String']>;
  practice_phone?: Maybe<Scalars['String']>;
  practice_tax_id?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
};

export type RptPortalReceiptDetail = {
  __typename?: 'RptPortalReceiptDetail';
  payment_id?: Maybe<Scalars['ID']>;
  ledger_id?: Maybe<Scalars['ID']>;
  ledger_number?: Maybe<Scalars['String']>;
  payment_date?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  approval_code?: Maybe<Scalars['String']>;
  reference_number?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
};

export type RptVaccineByContact = {
  __typename?: 'RptVaccineByContact';
  report_header: RptHeader;
  vaccine_by_contact_details: Array<Maybe<RptVaccineByContactDetail>>;
};

export type RptVaccineByContactDetail = {
  __typename?: 'RptVaccineByContactDetail';
  service_rendered_id: Scalars['ID'];
  service_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  invoice_number: Scalars['String'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  manufacturer_name?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  contact_name: Scalars['String'];
  contact_type: Scalars['String'];
};

export type RptReminderByContactDetail = {
  __typename?: 'RptReminderByContactDetail';
  patient_id: Scalars['ID'];
  status_id: Scalars['ID'];
  reminder_status_name?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  reminder_name?: Maybe<Scalars['String']>;
  reminder_due_date?: Maybe<Scalars['String']>;
  reminder_note?: Maybe<Scalars['String']>;
};

export type RptContactPaymentDetail = {
  __typename?: 'RptContactPaymentDetail';
  contact_name?: Maybe<Scalars['String']>;
  ledger_number?: Maybe<Scalars['String']>;
  payment_type_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  financial_date?: Maybe<Scalars['String']>;
  total_reversed?: Maybe<Scalars['String']>;
  total_assigned?: Maybe<Scalars['String']>;
  card_display_number?: Maybe<Scalars['String']>;
  check_number?: Maybe<Scalars['String']>;
  payer?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type RptVaccineActivity = {
  __typename?: 'RptVaccineActivity';
  report_header: RptHeader;
  vaccine_activity_details: Array<Maybe<RptVaccineActivityDetail>>;
};

export type RptVaccineActivityDetail = {
  __typename?: 'RptVaccineActivityDetail';
  service_rendered_id: Scalars['ID'];
  service_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  invoice_number: Scalars['String'];
  doctor_name: Scalars['String'];
  service_name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  manufacturer_name?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['String']>;
  date: Scalars['String'];
};

export type RptPatientVaccine = {
  __typename?: 'RptPatientVaccine';
  report_header: RptHeader;
  patient_vaccine_details: Array<Maybe<RptPatientVaccineDetail>>;
  ownership_details: Array<Maybe<RptPatientVaccineOwnershipDetail>>;
};

export type RptPatientVaccineDetail = {
  __typename?: 'RptPatientVaccineDetail';
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  patient_species: Scalars['String'];
  patient_breed?: Maybe<Scalars['String']>;
  patient_gender?: Maybe<Scalars['String']>;
  patient_color?: Maybe<Scalars['String']>;
  patient_microchip?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  doctor_name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  manufacturer_name?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['String']>;
  service_date: Scalars['String'];
  service_name: Scalars['String'];
  reminder_due_date?: Maybe<Scalars['String']>;
};

export type RptReminderLetter = {
  __typename?: 'RptReminderLetter';
  report_header: RptHeader;
  reminder_letter_details: Array<Maybe<RptReminderLetterDetail>>;
};

export type RptReminderLetterDetail = {
  __typename?: 'RptReminderLetterDetail';
  reminder_id: Scalars['ID'];
  patient_id?: Maybe<Scalars['ID']>;
  service_rendered_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  reminder: Scalars['String'];
  due_date: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type RptPatientHistory = {
  __typename?: 'RptPatientHistory';
  report_header: RptHeader;
  patient_details: Array<Maybe<RptPatientHistoryPatientDetail>>;
  history_details: Array<Maybe<RptPatientHistoryDetail>>;
  ownership_details: Array<Maybe<RptPatientHistoryOwnershipDetail>>;
};

export type RptPatientHistoryOwnership_DetailsArgs = {
  reportParameters?: Maybe<RptByContactOrPatientAndServiceParameters>;
};

export type RptPatientHistoryPatientDetail = {
  __typename?: 'RptPatientHistoryPatientDetail';
  patient_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  patient_number: Scalars['String'];
  patient_name: Scalars['String'];
  patient_dob?: Maybe<Scalars['String']>;
  patient_species: Scalars['String'];
  patient_breed?: Maybe<Scalars['String']>;
  patient_gender?: Maybe<Scalars['String']>;
  patient_color?: Maybe<Scalars['String']>;
};

export type RptPatientHistoryDetail = {
  __typename?: 'RptPatientHistoryDetail';
  history_type: Scalars['String'];
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  date: Scalars['String'];
  service_name: Scalars['String'];
  service_quantity?: Maybe<Scalars['String']>;
  service_unit?: Maybe<Scalars['String']>;
  service_note?: Maybe<Scalars['String']>;
  service_doctor: Scalars['String'];
  note_type?: Maybe<Scalars['String']>;
  note_value?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_description?: Maybe<Scalars['String']>;
};

export type RptPatientHistoryOwnershipDetail = {
  __typename?: 'RptPatientHistoryOwnershipDetail';
  patient_id: Scalars['ID'];
  contact_id: Scalars['ID'];
  contact_name: Scalars['String'];
  percentage: Scalars['String'];
  effective_date: Scalars['String'];
  end_date?: Maybe<Scalars['String']>;
  syndicate_id?: Maybe<Scalars['ID']>;
};

export type RptPatientVaccineOwnershipDetail = {
  __typename?: 'RptPatientVaccineOwnershipDetail';
  contact_name: Scalars['String'];
  percentage: Scalars['String'];
};

export type MaintenanceInstruction = {
  fnUpdateElasticStructure?: Maybe<Scalars['Boolean']>;
};

export type MaintenanceResult = {
  __typename?: 'MaintenanceResult';
  actionsPerformed?: Maybe<Array<Scalars['String']>>;
};

export type GlobalSearchResult = {
  __typename?: 'GlobalSearchResult';
  _index: Scalars['String'];
  _id: Scalars['String'];
  _score: Scalars['String'];
  _source: GlobalSearchDocumentSource;
};

export type GlobalSearchDocumentSource = {
  __typename?: 'GlobalSearchDocumentSource';
  organizationId: Scalars['String'];
  updated: Scalars['String'];
  document: GlobalSearchDocument;
};

export type GlobalSearchDocument = {
  __typename?: 'GlobalSearchDocument';
  number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contact_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact_number?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_number?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  status_name_key?: Maybe<Scalars['String']>;
  contact_status_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Array<Maybe<Phone>>>;
  name_2?: Maybe<Scalars['String']>;
  owner_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  species_name?: Maybe<Scalars['String']>;
  breed_name?: Maybe<Scalars['String']>;
  gender_name?: Maybe<Scalars['String']>;
  address?: Maybe<Array<Maybe<Address>>>;
  type_name_keys?: Maybe<Array<Maybe<Scalars['String']>>>;
  color_name?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  contact_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact_percentages?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner_percentages?: Maybe<Array<Maybe<Scalars['String']>>>;
  related_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  related_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  related_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Scalars['String']>;
};

export type SubscriptionProcessInstruction = {
  fnIsTest?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionProcessResult = {
  __typename?: 'SubscriptionProcessResult';
  subscriptionsProcessed: Scalars['Int'];
};

export type SubscriptionQueueMessage = {
  __typename?: 'SubscriptionQueueMessage';
  subscription_id: Scalars['ID'];
  server_date?: Maybe<Scalars['String']>;
};

export type SqsRunnerMessage = {
  __typename?: 'SQSRunnerMessage';
  functionName: Scalars['String'];
  functionBody?: Maybe<Scalars['String']>;
};

export type Metrics = {
  __typename?: 'Metrics';
  organization_id: Scalars['ID'];
  practice_id: Scalars['ID'];
  monthly_invoiced?: Maybe<Array<Maybe<Invoiced>>>;
  yearly_invoiced?: Maybe<Array<Maybe<Invoiced>>>;
  accounts_receivable?: Maybe<ArBuckets>;
  monthly_payments?: Maybe<Array<Maybe<MonthlyPayment>>>;
  contacts_patients?: Maybe<Array<Maybe<ContactsPatientsCount>>>;
};

export type Invoiced = {
  __typename?: 'Invoiced';
  year: Scalars['String'];
  month?: Maybe<Scalars['String']>;
  month_number?: Maybe<Scalars['String']>;
  invoiced_amount: Scalars['String'];
};

export type ArBuckets = {
  __typename?: 'ARBuckets';
  bucket0?: Maybe<Scalars['String']>;
  bucket31?: Maybe<Scalars['String']>;
  bucket61?: Maybe<Scalars['String']>;
  bucket91?: Maybe<Scalars['String']>;
  bucket121?: Maybe<Scalars['String']>;
};

export type MonthlyPayment = {
  __typename?: 'MonthlyPayment';
  month: Scalars['String'];
  total: Scalars['String'];
};

export type ContactsPatientsCount = {
  __typename?: 'ContactsPatientsCount';
  month: Scalars['String'];
  contact_count: Scalars['String'];
  patient_count: Scalars['String'];
};

export type CurrencyDefaultsReferenceData = {
  __typename?: 'CurrencyDefaultsReferenceData';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  decimal_digits: Scalars['Int'];
  decimal_symbol: Scalars['String'];
  grouping_symbol: Scalars['String'];
  negative_symbol: Scalars['String'];
};

export type ThirdPartyRegionReferenceData = {
  __typename?: 'ThirdPartyRegionReferenceData';
  id: Scalars['ID'];
  third_party_id: Scalars['ID'];
  name_key: Scalars['String'];
  setting_json: Scalars['String'];
};

export type ElectronicPayment = {
  __typename?: 'ElectronicPayment';
  id: Scalars['ID'];
  order_id?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  fee_amount?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  response_text?: Maybe<Scalars['String']>;
  response_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
};

export type StripePayout = {
  __typename?: 'StripePayout';
  balance?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['String']>;
  sent_to_payout_account?: Maybe<Scalars['String']>;
  payout_details_list?: Maybe<Array<Maybe<StripePayoutDetails>>>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  id: Scalars['ID'];
  amount: Scalars['String'];
  created: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  status_text: Scalars['String'];
  customer_name: Scalars['String'];
  customer_email?: Maybe<Scalars['String']>;
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCardBillingDetails = {
  __typename?: 'StripePaymentMethodCardBillingDetails';
  address?: Maybe<StripeAddress>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCardChecks = {
  __typename?: 'StripePaymentMethodCardChecks';
  address_line1_check?: Maybe<Scalars['String']>;
  address_postal_code_check?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCard = {
  __typename?: 'StripePaymentMethodCard';
  brand?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  checks?: Maybe<StripePaymentMethodCardChecks>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  type?: Maybe<Scalars['String']>;
  billing_details?: Maybe<StripePaymentMethodCardBillingDetails>;
  card?: Maybe<StripePaymentMethodCard>;
};

export type StripePaymentError = {
  __typename?: 'StripePaymentError';
  code?: Maybe<Scalars['String']>;
  code_text?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_text?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentDetailsTimeLineItem = {
  __typename?: 'StripePaymentIntentDetailsTimeLineItem';
  order_number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentDetails = {
  __typename?: 'StripePaymentIntentDetails';
  id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['String']>;
  amount_capturable?: Maybe<Scalars['String']>;
  amount_received?: Maybe<Scalars['String']>;
  application_fee_amount?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['String']>;
  amount_refunded?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['String']>;
  cancellation_reason?: Maybe<Scalars['String']>;
  capture_method?: Maybe<Scalars['String']>;
  capture_method_text?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receipt_email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  payment_method?: Maybe<StripePaymentMethod>;
  payment_error?: Maybe<StripePaymentError>;
  time_line: Array<StripePaymentIntentDetailsTimeLineItem>;
};

export type StripePayoutDetails = {
  __typename?: 'StripePayoutDetails';
  id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  arrival_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  destination_id?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  routing_number?: Maybe<Scalars['String']>;
  source_type?: Maybe<Scalars['String']>;
  source_type_text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_text?: Maybe<Scalars['String']>;
};

export type StripePayoutBalanceTransaction = {
  __typename?: 'StripePayoutBalanceTransaction';
  id: Scalars['ID'];
  electronic_payment_id?: Maybe<Scalars['ID']>;
  ledger_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  processor_record_id?: Maybe<Scalars['String']>;
  ledger_financial_date?: Maybe<Scalars['String']>;
  ledger_number?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  object_text?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  available_on?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  net?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_text?: Maybe<Scalars['String']>;
};

export type StripePayoutDetailsResponse = {
  __typename?: 'StripePayoutDetailsResponse';
  id?: Maybe<Scalars['ID']>;
  payout_details?: Maybe<StripePayoutDetails>;
  transaction_list?: Maybe<Array<Maybe<StripePayoutBalanceTransaction>>>;
};

export type StripeBalanceTransaction = {
  __typename?: 'StripeBalanceTransaction';
  id: Scalars['ID'];
  object?: Maybe<Scalars['String']>;
  object_text?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  available_on?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  net?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_text?: Maybe<Scalars['String']>;
};

export type StripeBalanceAmountSourceTypes = {
  __typename?: 'StripeBalanceAmountSourceTypes';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type StripeBalanceAmount = {
  __typename?: 'StripeBalanceAmount';
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  source_types?: Maybe<Array<Maybe<StripeBalanceAmountSourceTypes>>>;
};

export type StripeBalanceIssuing = {
  __typename?: 'StripeBalanceIssuing';
  available?: Maybe<Array<Maybe<StripeBalanceAmount>>>;
};

export type StripeBalance = {
  __typename?: 'StripeBalance';
  object?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Array<Maybe<StripeBalanceAmount>>>;
  connect_reserved?: Maybe<Array<Maybe<StripeBalanceAmount>>>;
  instant_available?: Maybe<Array<Maybe<StripeBalanceAmount>>>;
  issuing?: Maybe<StripeBalanceIssuing>;
  pending?: Maybe<Array<Maybe<StripeBalanceAmount>>>;
};

export type QuickBooksAccount = {
  __typename?: 'QuickBooksAccount';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  accountSubType?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  parentRef?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
};

export type HisaPatient = {
  __typename?: 'HISAPatient';
  ownerHisaId: Scalars['String'];
  ownerName: Scalars['String'];
  hisaHorseId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  yearOfBirth: Scalars['String'];
  damName: Scalars['String'];
  responsiblePersonHisaId?: Maybe<Scalars['String']>;
  responsiblePersonName?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  locationName: Scalars['String'];
};

export type HisaValidateVet = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type HisaVetValidationResult = {
  __typename?: 'HISAVetValidationResult';
  isValidNumber: Scalars['Boolean'];
  isValidName: Scalars['Boolean'];
  notFound: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type QbTaxRate = {
  __typename?: 'QBTaxRate';
  id: Scalars['ID'];
  value: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type QbPaymentType = {
  __typename?: 'QBPaymentType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RetrySubscriptionInput = {
  id: Scalars['ID'];
};

export type RetrySubscriptionResult = {
  __typename?: 'RetrySubscriptionResult';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};
