import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Popconfirm, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { translations } from '../../constants/translations';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableFilterAndSortKey, TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { useGetContactReferenceData } from '../../hooks/ajax/contact/contactHooks';
import { getAvailableContactStatusTypeFilters } from '../../util/contactFilterUtil';
import { ContactStatusHelper } from '../Contacts/ContactsOverview/contactStatusUtil';
import { useBasicInterestRunColumns } from './interestRunColumns';
import { TableCellLink } from '../../components/TableLink/TableCellLink';
import { AbbreviateToWidth } from '../../components/AbbreviateToWidth/AbbreviateToWidth';
import { routes } from '../../constants/routes';
import { useNavigationToRoute, withContactIdParameter } from '../../hooks/route/navigationHooks';
import { PADDING_COMPENSATION_WIDTH_TEXT } from '../../constants/layout';
import { RowSelectMethod, TableRowSelection } from 'antd/lib/table/interface';
import { setRefetchRun, setSelectedContactRuns } from './store/actions';
import { InterestRunsContext } from './store/state';
import { ContactInterestEntry, InterestRunContacts } from '../../graph/types';
import { useUpsertInterestRun } from '../../hooks/ajax/interestRuns/interestRunHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
} from '../../util/filterAndSorting';
import { ButtonWithPopconfirm } from '../../components/ButtonWithPopconfirm/ButtonWithPopconfirm';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';
import { interestRunPropertyNames } from '../../constants/propertyNames';

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface InterestRunTabContentProps {
  interestRunId: string;
  isDeletable?: boolean;
  deleteInterestRun: (id: string) => void;
  completeRun: (run: InterestRunContacts) => void;
  hasCompleted: boolean;
}

export const InterestRunTabContent: React.FC<InterestRunTabContentProps> = ({
  interestRunId,
  isDeletable,
  deleteInterestRun,
  completeRun,
  hasCompleted,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [fetchInterestRun, { data: interestRunUpsertResult, loading }] = useUpsertInterestRun();
  const interestRun = interestRunUpsertResult?.upsertInterestRun;
  const interestRunContacts = useMemo(() => interestRun?.contact ?? [], [interestRun]);
  const { state, dispatch } = useContext(InterestRunsContext);
  const [currentDataSource, setCurrentDataSource] = useState<ContactInterestEntry[]>();
  const basicInterestRunColumns = useBasicInterestRunColumns();
  const [rowSelectedMethod, setRowSelectedMethod] = useState<RowSelectMethod>();

  const { filteredValue, tableChangeHandler, resetFiltersAndSort } = useTableResetFilterAndSort(
    TableFilterAndSortKey.InterestRunTabContent
  );

  const handleDeleteRun = () => {
    resetFiltersAndSort();
    deleteInterestRun(interestRunId);
  };

  const handleCompleteRun = () => {
    if (interestRun) {
      resetFiltersAndSort();
      completeRun(interestRun);
    }
  };

  const refetchInterestRun = useCallback(() => {
    fetchInterestRun({
      variables: {
        organizationId,
        interestRun: { id: interestRunId },
      },
    });
  }, [fetchInterestRun, interestRunId, organizationId]);

  useEffect(() => {
    refetchInterestRun();
  }, [refetchInterestRun, hasCompleted]);

  useEffect(() => {
    dispatch(setRefetchRun(refetchInterestRun));
  }, [dispatch, refetchInterestRun]);

  useEffect(() => {
    resetFiltersAndSort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestRunId]);

  useEffect(() => {
    if (rowSelectedMethod === 'all' && currentDataSource) {
      dispatch(setSelectedContactRuns(currentDataSource));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValue]);

  const getSelectedRowKeys = useCallback(() => {
    return state.selectedRuns.map((run) => run.contact_id);
  }, [state]);

  const stateSelectedRowKeys = getSelectedRowKeys();

  const { navigateTo } = useNavigationToRoute();

  const handleOnRowCheckedChange: TableRowSelection<ContactInterestEntry>['onChange'] = (_, selectedRows, info) => {
    setRowSelectedMethod(info.type);
    if (info.type === 'all' && currentDataSource) {
      dispatch(
        setSelectedContactRuns(stateSelectedRowKeys.length !== currentDataSource.length ? currentDataSource : [])
      );
    } else {
      dispatch(setSelectedContactRuns(selectedRows));
    }
  };

  const handleOnAllRowsSelect: TableRowSelection<ContactInterestEntry>['onSelectAll'] = (selected) => {
    dispatch(setSelectedContactRuns(selected && currentDataSource ? currentDataSource : []));
  };

  const { contactReferenceData, contactReferenceDataLoading } = useGetContactReferenceData({ organizationId });
  const contactStatusTypeFilters = getAvailableContactStatusTypeFilters(contactReferenceData?.status_type ?? []);
  const contactStatusHelper = useMemo(
    () => new ContactStatusHelper(contactReferenceData?.status_type),
    [contactReferenceData]
  );

  function getInterestRunColumns(): CustomColumnType<ContactInterestEntry>[] {
    const allColumns = Array<CustomColumnType<ContactInterestEntry>>();
    const { total, bucket0, bucket31, bucket61, bucket91, bucket121 } = basicInterestRunColumns;
    const owingColumns = [total, bucket0, bucket31, bucket61, bucket91, bucket121];

    allColumns.push({
      ...basicInterestRunColumns.contact_name,
      filteredValue: filteredValue[interestRunPropertyNames.contact_name] ?? null,
      width: 200,
      render: (value, record) => (
        <TableCellLink onClick={() => navigateTo(routes.viewContact, withContactIdParameter(record.contact_id))}>
          <AbbreviateToWidth width={200} text={value} paddingCompensation={PADDING_COMPENSATION_WIDTH_TEXT} />
        </TableCellLink>
      ),
    });
    interestRun?.has_charge &&
      allColumns.push({
        title: translations.interestRuns.columns.interest,
        dataIndex: 'interest',
        ...generalFilteringAndSortingSettings,
        isForNumber: true,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.interestRuns.columns.interest
        ),
        onFilter: getOnFilterFunctionFor('interest', true),
        sorter: getNumberCompareFunctionFor('interest'),
        render: (value: string) => <CurrencyFormatter total={Number(value)} />,
        width: 210,
      });
    if (!interestRun?.has_charge) {
      for (const col of owingColumns) {
        allColumns.push({
          ...col,
          filteredValue: filteredValue[col.dataIndex?.toString() ?? ''] ?? null,
          width: 200,
        });
      }
    }

    allColumns.push({
      ...basicInterestRunColumns.contact_status_name,
      filteredValue: filteredValue[interestRunPropertyNames.contact_status_name] ?? null,
      width: 200,
      filters: contactStatusTypeFilters,
      render: (_, record) => contactStatusHelper.getTag(record, contactReferenceData?.status_type ?? []),
    });
    allColumns.push({
      ...basicInterestRunColumns.contact_email,
      filteredValue: filteredValue[interestRunPropertyNames.contact_email] ?? null,
      width: 200,
    });

    return allColumns;
  }

  return (
    <>
      <SpaceBetweenWrapper>
        <Space>
          <PageHeader
            title={
              interestRun?.has_charge
                ? translations.interestRuns.tabs.chargedContacts
                : translations.interestRuns.tabs.selectContacts
            }
          />
          {interestRun && !interestRun?.has_charge && (
            <ButtonWithPopconfirm
              buttonStyle={{ float: 'left' }}
              buttonText={translations.interestRuns.chargeInterest}
              buttonType='primary'
              popconfirmTitle={translations.interestRuns.modal.completeModalMsg}
              onClickCallback={handleCompleteRun}
              isDisabled={!state.selectedRuns.length}
            >
              {translations.interestRuns.chargeInterest}
            </ButtonWithPopconfirm>
          )}
        </Space>
        <Space>
          <Popconfirm
            title={translations.interestRuns.deleteConfirmMessage}
            okText={translations.shared.popconfirm.no}
            cancelText={translations.shared.popconfirm.ok}
            placement={'topRight'}
            disabled={!isDeletable}
            onCancel={handleDeleteRun}
          >
            <Button danger disabled={!isDeletable} type={isDeletable ? 'primary' : 'default'}>
              <DeleteOutlined /> {translations.interestRuns.deleteButtonText}
            </Button>
          </Popconfirm>
        </Space>
      </SpaceBetweenWrapper>
      <TableWithCustomFiltering
        tableKey={TableKey.InterestRunTabContent}
        columns={getInterestRunColumns()}
        dataSource={interestRunContacts}
        loading={contactReferenceDataLoading || loading}
        rowKey={'contact_id'}
        rowSelection={
          !interestRun?.has_charge
            ? {
                type: 'checkbox',
                onChange: handleOnRowCheckedChange,
                selectedRowKeys: getSelectedRowKeys(),
                onSelectAll: handleOnAllRowsSelect,
              }
            : undefined
        }
        setCurrentDataSource={setCurrentDataSource}
        onChange={tableChangeHandler}
      />
    </>
  );
};

export default InterestRunTabContent;
