import { Dispatch, SetStateAction } from 'react';
import { BundleServiceUpsert } from '../../../graph/types';
import {
  setBundleUpsertType,
  setSetRemovedServicesType,
  ServicesAction,
  setResetBundleListType,
  setBundlePromptActiveType,
} from './actions';

export interface ServicesState {
  bundleUpsert: BundleServiceUpsert[] | undefined;
  setRemovedServices: Dispatch<SetStateAction<string[]>> | undefined;
  resetBundleList: (() => void) | undefined;
  bundlePromptActive: boolean;
}

export const initialState: ServicesState = {
  bundleUpsert: undefined,
  setRemovedServices: undefined,
  resetBundleList: undefined,
  bundlePromptActive: false,
};

export const servicesReducer = (state: ServicesState = initialState, action: ServicesAction): ServicesState => {
  switch (action.type) {
    case setBundleUpsertType:
      return {
        ...state,
        bundleUpsert: action.bundleUpsert,
      };
    case setSetRemovedServicesType:
      return {
        ...state,
        setRemovedServices: action.setRemovedServices,
      };
    case setResetBundleListType:
      return {
        ...state,
        resetBundleList: action.resetBundleList,
      };
    case setBundlePromptActiveType:
      return {
        ...state,
        bundlePromptActive: action.payload,
      };
    default:
      return state;
  }
};
