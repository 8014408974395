import { print } from 'graphql';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { OfflineUpsert } from '../../../graph/queries/general';
import { GetInvoicesForOffline } from '../../../graph/queries/invoices';
import {
  Invoice,
  InvoiceContact,
  InvoiceContactUpsert,
  InvoiceUpsert,
  Maybe,
  Patient,
  ServiceRendered,
  ServiceRenderedUpsert,
} from '../../../graph/types';
import { getConnectionId } from '../../../hooks/authHooks';
import { RxInvoice } from '../schemas/invoiceSchema';
import {
  cleanUpOfflineRecords,
  elasticQueryBuilder,
  getClearedRecord,
  getOfflineId,
  getUpdatedDate,
  OfflineUpsertProps,
  replaceExistingLinkedRecords,
  replaceExistingRecords,
} from './queryUtils';
import { v4 as uuid } from 'uuid';
import { getInvoiceStatusNameKeyFromId } from '../../../constants/referenceData/invoiceReferenceData';
import { db } from '../LocalDatabaseProvider';
import { addOfflineInvoiceProperties, getInvoiceDocument } from '../../../util/offline/offlineQueryUtils';

const INVOICE_BATCH_SIZE = 100;
export const invoiceQueryBuilder = (organizationId: string) =>
  elasticQueryBuilder<Invoice>(organizationId, GetInvoicesForOffline, INVOICE_BATCH_SIZE);

export const getInvoicePushVariables = (organizationId: string, invoice: RxInvoice) => ({
  variables: {
    organizationId,
    upsert: {
      record: {
        type_name: 'upsertInvoice',
        offline_id: invoice.upsert_offline_id,
        connection_id: getConnectionId(),
        instruction: JSON.stringify({
          ...cleanUpsert(invoice.upsert ?? {}),
          id: invoice.is_new ? ID_FOR_OBJECT_CREATION : invoice.id,
        }),
      },
    },
  },
});

export const invoicePushBuilder = (organizationId: string) => (invoice: RxInvoice) => ({
  query: print(OfflineUpsert),
  ...getInvoicePushVariables(organizationId, invoice),
});

const cleanUpsert = (upsert: InvoiceUpsert): InvoiceUpsert => ({
  ...upsert,
  item: upsert.item?.map((i) => ({
    ...i,
    note: i.note && {
      ...i.note,
      record: i.note?.record && {
        ...i.note.record,
        ...getClearedRecord(i.note.record),
      },
    },
    prescriptions: i.prescriptions?.map((p) => ({
      ...p,
      record: p.record && {
        ...p.record,
        ...getClearedRecord(p.record),
      },
    })),
    reminder: i.reminder?.map((r) => ({
      ...r,
      record: r.record && {
        ...r.record,
        ...getClearedRecord(r.record),
      },
    })),
    labRequest: i.labRequest?.map((l) => ({
      ...l,
      record: l.record && {
        ...l.record,
        ...getClearedRecord(l.record),
      },
    })),
  })),
  contact: upsert.contact
    ?.filter(({ id, void: recordVoided }) => !(id && isNaN(+id) && recordVoided))
    ?.map((c) => ({
      ...c,
      record: c.record && {
        ...c.record,
        offline_contact_id:
          c.record.offline_contact_id ??
          (c.record.contact_id && isNaN(+c.record.contact_id) ? c.record.contact_id : null),
        contact_id: !c.record.contact_id || isNaN(+c.record.contact_id) ? undefined : c.record.contact_id,
      },
    })),
  record: upsert.record && {
    ...upsert.record,
    patient_id: !upsert.record.patient_id || isNaN(+upsert.record.patient_id) ? undefined : upsert.record.patient_id,
    patient_syndicate_id:
      !upsert.record.patient_syndicate_id || isNaN(+upsert.record.patient_syndicate_id)
        ? undefined
        : upsert.record.patient_syndicate_id,
  },
});

export const getInvoiceInsert = async (
  invoiceUpsert: InvoiceUpsert,
  {
    organizationId,
    patient,
    offlineContactId,
  }: {
    organizationId: string;
    patient: Patient;
    offlineContactId?: Maybe<string>;
  }
): Promise<RxInvoice> => {
  const offlineId = getOfflineId();
  const upsert = invoiceUpsert;
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
  }

  const currentDate = getUpdatedDate();
  const contacts = await mapInvoiceContactUpsertToInvoiceContact(organizationId, upsert.contact ?? []);
  const numberOfOfflineInvoices = (await db.invoice.find({ selector: { upsert: { $ne: null } } }).exec()).length;
  const primaryContact = contacts.find((c) => c.primary);

  const invoice: RxInvoice = {
    id: offlineId,
    upsert,
    upsert_offline_id: uuid(),
    organization_id: organizationId,
    hidden: false,
    is_new: true,
    document: '',
    updated: currentDate,
    offline_id: offlineId,
    practice_id: upsert.record?.practice_id ?? '',
    number: 'Temp-' + String(numberOfOfflineInvoices + 1).padStart(4, '0'),
    split: !!upsert.contact && upsert.contact.length > 1,
    date: upsert.record?.date ?? '',
    status_id: upsert.statusRecord?.status_id ?? 0,
    status_name_key: getInvoiceStatusNameKeyFromId(upsert.statusRecord?.status_id ?? 0),
    total_no_tax: '0',
    total_tax: '0',
    total: '0',
    patient_count: 0,
    patient_id: patient.id,
    patient_number: patient.number,
    patient_name: patient.name,
    changed_date: currentDate,
    contact: contacts,
    contact_number: primaryContact?.contact_number,
    contact_name: primaryContact?.contact_name,
    contact_ids: contacts.map(({ contact_id }) => contact_id),
    contact_names: contacts.map(({ contact_name }) => contact_name),
    contact_percentages: contacts.map(({ percentage }) => percentage),
    item: [],
    patient,
    offline_patient_id: patient.offline_id,
    offline_contact_id: offlineContactId,
  };

  invoice.document = getInvoiceDocument(invoice);

  return invoice;
};

export const getInvoiceUpdate = async (
  rxInvoice: RxInvoice,
  invoiceUpsert: InvoiceUpsert,
  {
    services,
    organizationId,
  }: {
    services?: ServiceRendered[];
    organizationId: string;
  }
) => {
  const newUpsert = { ...rxInvoice.upsert, ...invoiceUpsert };
  let contacts: InvoiceContact[] = [];
  if (newUpsert.contact) {
    contacts = await mapInvoiceContactUpsertToInvoiceContact(organizationId, newUpsert.contact);
  } else if (rxInvoice.contact) {
    contacts = rxInvoice.contact;
  }
  const primaryContact = contacts.find((c) => c.primary);
  const currentDate = getUpdatedDate();

  const propsToUpdate: OfflineUpsertProps<RxInvoice> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    ...(newUpsert.record ?? {}),
    item: updateInvoiceItemFromUpsert(rxInvoice.item ?? [], invoiceUpsert.item ?? []),
    hidden: !!newUpsert.void,
    offline_services: services,
    contact: contacts,
    contact_number: primaryContact?.contact_number,
    contact_name: primaryContact?.contact_name,
    contact_ids: contacts.map(({ contact_id }) => contact_id),
    contact_names: contacts.map(({ contact_name }) => contact_name),
    contact_percentages: contacts.map(({ percentage }) => percentage),
    updated: currentDate,
  };

  return propsToUpdate;
};

export const mapInvoiceContactUpsertToInvoiceContact = async (
  organizationId: string,
  records: InvoiceContactUpsert[]
): Promise<InvoiceContact[]> => {
  const invoiceContacts: InvoiceContact[] = [];

  for (const { record, void: recordVoided } of records) {
    if (recordVoided) {
      continue;
    }
    const contact = await db.contact?.findOne(record?.contact_id ?? '').exec();
    if (!contact) {
      continue;
    }
    invoiceContacts.push({
      id: uuid(),
      organization_id: organizationId,
      contact_id: contact.id,
      contact_number: contact.number ?? '',
      contact_name: contact.name,
      contact_email: contact.email,
      percentage: record?.percentage ?? '100',
      primary: !!record?.primary,
    });
  }

  return invoiceContacts;
};

export const onInvoiceUpdate = async (invoice: Invoice): Promise<RxInvoice> => {
  await cleanUpOfflineRecords('filled_service_rendered_id', 'prescription_flattened');
  await cleanUpOfflineRecords('id', 'prescription');
  await replaceExistingLinkedRecords(invoice, 'prescription_flattened', 'invoice_id');
  await replaceExistingRecords(invoice, 'invoice');
  return addOfflineInvoiceProperties(invoice);
};

const updateInvoiceItemFromUpsert = (items: ServiceRendered[], upsertItems: ServiceRenderedUpsert[]) =>
  items.map((item) => {
    const updatedItem = upsertItems.find(({ id }) => id === item.id);

    if (!updatedItem) {
      return item;
    }

    return {
      ...item,
      sort_order: updatedItem?.orderRecord?.sort_order ?? item.sort_order,
    };
  });
