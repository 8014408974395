import { Reference } from '@apollo/client';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { DepositRunFields } from '../../../graph/fragments/depositRunFragments';
import {
  DeleteDepositRunFromQuickbooks,
  GetDepositRun,
  SendDepositRunToQuickbooks,
  UpsertDepositRun,
} from '../../../graph/queries/depositRun';
import {
  DepositRunFilter,
  MutationDeleteDepositRunQuickbooksArgs,
  MutationSendDepositRunQuickbooksArgs,
  MutationUpsertDepositRunArgs,
  QueryGetDepositRunArgs,
} from '../../../graph/types';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useUpsertDepositRun = () => {
  return useOfflineErrorSkipMutation<'upsertDepositRun', MutationUpsertDepositRunArgs>(UpsertDepositRun, {
    update(cache, { data }) {
      const insertedDepositRun = data?.upsertDepositRun;
      cache.modify({
        fields: {
          GetDepositRun(depositRun: Reference[] = []) {
            const newDepositRun = cache.writeFragment({
              data: insertedDepositRun,
              fragment: DepositRunFields,
              fragmentName: 'DepositRunFields',
            });
            return [newDepositRun, ...depositRun];
          },
        },
      });
    },
    onError: (error) => showErrorMessage(error.message),
    onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
  });
};

export const useSendDepositRunToQuickBooks = () => {
  return useOfflineErrorSkipMutation<'sendDepositRunQuickbooks', MutationSendDepositRunQuickbooksArgs>(
    SendDepositRunToQuickbooks,
    {
      onError: (error) => showErrorMessage(error.message),
      onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
    }
  );
};

export const useDeleteDepositRunFromQuickBooks = () => {
  return useOfflineErrorSkipMutation<'deleteDepositRunQuickbooks', MutationDeleteDepositRunQuickbooksArgs>(
    DeleteDepositRunFromQuickbooks,
    {
      onError: (error) => showErrorMessage(error.message),
      onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
    }
  );
};

export const useGetDepositRun = (organizationId: string, practiceId: string, filter?: DepositRunFilter) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getDepositRun', QueryGetDepositRunArgs>(
    GetDepositRun,
    {
      variables: { organizationId, practiceId, filter },
    }
  );

  return {
    depositRun: data?.getDepositRun,
    depositRunLoading: loading,
    depositRunError: error,
    depositRunRefetch: refetch,
  };
};
