import React from 'react';
import { Select } from 'antd';
import { translations } from '../../constants/translations';
import './SelectForSearch.css';

const { Option } = Select;

interface SelectForSearchProps {
  isLoading?: boolean;
  setSearchValue: (searchValue: string) => void;
  options: { id: string; value: string }[] | undefined;
  placeholder: string;
  onSelect: (id: string) => void;
  dataTestId?: string;
}

export const selectForSearchClassname = 'SelectForSearch';

export const SelectForSearch: React.FC<SelectForSearchProps> = ({
  isLoading,
  setSearchValue,
  options = [],
  placeholder,
  onSelect,
  dataTestId,
}) => {
  return (
    <Select
      showSearch
      style={{ width: 300, opacity: 0.6 }}
      className={selectForSearchClassname}
      value={placeholder}
      defaultActiveFirstOption={false}
      loading={isLoading}
      filterOption={false}
      onSearch={setSearchValue}
      onChange={onSelect}
      notFoundContent={translations.shared.noResults}
      data-testid={dataTestId}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id} title={option.value}>
          {option.value}
        </Option>
      ))}
    </Select>
  );
};
