import styled from 'styled-components';
import { CustomFlexContainerMixin } from '../../globalStyles.style';

export const CurrencyEntryTotal = styled.div`
  font-size: 2em;
`;

export const StyledImage = styled.img`
  height: 4em;
`;

export const BillingDetailsContent = styled.div`
  ${CustomFlexContainerMixin}
  align-items: center;
  padding: 2em 0;
`;

export const NoDataSpan = styled.span`
  align-self: center;
`;
