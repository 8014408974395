import gql from 'graphql-tag';
import { AddressFields, InfoFields, PhoneFields, PracticeFields, SettingFields } from '../fragments';

export const UpsertPractice = gql`
  mutation upsertPractice($organizationId: ID!, $practice: PracticeUpsert!) {
    upsertPractice(organizationId: $organizationId, practice: $practice) {
      ...PracticeFields
    }
  }
  ${PracticeFields}
`;

export const UpsertPracticeWithInfoAndPhone = gql`
  mutation upsertPractice($organizationId: ID!, $practice: PracticeUpsert!) {
    upsertPractice(organizationId: $organizationId, practice: $practice) {
      ...PracticeFields
      info {
        ...InfoFields
      }
      phone {
        ...PhoneFields
      }
    }
  }
  ${PracticeFields}
  ${InfoFields}
  ${PhoneFields}
`;

export const GetPractice = gql`
  query getPractice($organizationId: ID!, $practiceId: ID!) {
    getPractice(organizationId: $organizationId, practiceId: $practiceId) {
      ...PracticeFields
    }
  }
  ${PracticeFields}
`;

export const GetPracticeWithInfoAndPhone = gql`
  query getPractice($organizationId: ID!, $practiceId: ID!) {
    getPractice(organizationId: $organizationId, practiceId: $practiceId) {
      ...PracticeFields
      info {
        ...InfoFields
      }
      phone {
        ...PhoneFields
      }
    }
  }
  ${PracticeFields}
  ${InfoFields}
  ${PhoneFields}
`;

export const GetPracticeSettings = gql`
  query getPracticeSettings($organizationId: ID!, $practiceId: ID!) {
    getPractice(organizationId: $organizationId, practiceId: $practiceId) {
      setting {
        ...SettingFields
      }
    }
  }
  ${SettingFields}
`;

export const UpsertPracticeCardConnectSettings = gql`
  mutation upsertPractice($organizationId: ID!, $practice: PracticeUpsert!) {
    upsertPractice(organizationId: $organizationId, practice: $practice) {
      id
      organization_id
      card_connect {
        merchantId
        userId
      }
    }
  }
`;

export const GetPracticeForOffline = gql`
  query getPractice($organizationId: ID!, $practiceId: ID!) {
    getPractice(organizationId: $organizationId, practiceId: $practiceId) {
      ...PracticeFields
      info {
        ...InfoFields
      }
      setting {
        ...SettingFields
      }
    }
  }
  ${PracticeFields}
  ${InfoFields}
  ${SettingFields}
`;

export const GetPracticeAddress = gql`
  query getPractice($organizationId: ID!, $practiceId: ID!) {
    getPractice(organizationId: $organizationId, practiceId: $practiceId) {
      id
      address {
        ...AddressFields
      }
    }
  }
  ${AddressFields}
`;
