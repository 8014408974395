import React, { useEffect } from 'react';
import { OptionsWithSpeciesNumber } from '../../../components/DependentDropdown/DependentDropdown';
import { TabLoading } from '../../../components/Loading/Loading';
import { translations } from '../../../constants/translations';
import { useGetLabGenderMapping, useGetLabRefGenders } from '../../../hooks/ajax/lab/labHooks';
import { TaxonomyTabProps, taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { TaxonomyTable } from './TaxonomyTable';

export const TaxonomyGenders: React.FC<TaxonomyTabProps> = ({ labId, organizationId, autoMapLoading }) => {
  const { labGenderMapping, loading, refetch } = useGetLabGenderMapping(organizationId, labId);
  const {
    labRefGenders,
    loading: labRefGenderLoading,
    refetch: refetchLabRefGenders,
  } = useGetLabRefGenders(organizationId, labId);

  useEffect(() => {
    refetch({ organizationId, labDeviceId: labId });
    refetchLabRefGenders({ organizationId, labRefFilter: { lab_device_id: labId } });
  }, [labId, organizationId, refetch, refetchLabRefGenders]);

  if (loading || labRefGenderLoading || autoMapLoading) {
    return <TabLoading labelText={translations.shared.communicatingWithLabMessage} />;
  }

  const genderOptions: OptionsWithSpeciesNumber[] =
    labRefGenders?.map(({ lab_gender_number, lab_gender_name, lab_species_number }) => ({
      label: lab_gender_name,
      value: lab_gender_number,
      speciesnumber: lab_species_number,
    })) ?? [];

  return (
    <TaxonomyTable
      options={genderOptions}
      dataSource={labGenderMapping ?? []}
      type={TaxonomyType.Gender}
      typeTranslations={taxonomyTranslations.tabs.gender}
      deviceId={labId}
    />
  );
};
