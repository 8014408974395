import { UserProperty, UserTypesProperty, userPropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { User, UserOrganizationUserType } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { getPrimaryPhoneNumber } from '../../../util/phone';
import { getStatus, Status } from '../../../util/statusUtils';

const phoneFilter = (value: string | number | boolean, user: User) =>
  getPrimaryPhoneNumber(user.phone ?? [])
    .toLowerCase()
    .includes(value.toString().toLowerCase());
const phoneSorter = (user1: User, user2: User) =>
  getPrimaryPhoneNumber(user1?.phone ?? []).localeCompare(getPrimaryPhoneNumber(user2?.phone ?? []));

const getUserRoles = (user: User) => {
  const userOrganization = user.organization?.[0];
  if (!userOrganization) {
    return [];
  }

  return userOrganization.type ?? [];
};
const rolesFilter = (value: string | number | boolean, user: User) =>
  getUserRoles(user)
    .map((role: UserOrganizationUserType) => role.type_id)
    .includes(value.toString());

const rolesSorter = (user1: User, user2: User) =>
  getUserRoles(user1).toString().localeCompare(getUserRoles(user2).toString());

export const basicUserColumns: Record<UserProperty | UserTypesProperty, CustomColumnType<User>> = {
  name: {
    title: translations.userTablePage.columns.name,
    dataIndex: userPropertyNames.name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.userTablePage.columns.name),
    onFilter: getOnFilterFunctionFor(userPropertyNames.name),
    sorter: getStringCompareFunctionFor(userPropertyNames.name),
  },
  roles: {
    title: translations.userTablePage.columns.roles,
    ...generalFilteringAndSortingSettings,
    onFilter: rolesFilter,
    sorter: rolesSorter,
  },
  email: {
    title: translations.userTablePage.columns.email,
    dataIndex: userPropertyNames.email,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.userTablePage.columns.email),
    onFilter: getOnFilterFunctionFor(userPropertyNames.email),
    sorter: getStringCompareFunctionFor(userPropertyNames.email),
  },
  phone: {
    title: translations.userTablePage.columns.phone,
    dataIndex: userPropertyNames.phone,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.userTablePage.columns.phone),
    onFilter: phoneFilter,
    sorter: phoneSorter,
  },
  inactive: {
    title: translations.userTablePage.columns.status,
    ...generalFilteringAndSortingSettings,
    filters: [
      { text: 'Active', value: Status.Active },
      { text: 'Inactive', value: Status.Inactive },
    ],
    onFilter: (value, record) => getStatus(record) === value,
    sorter: (a: User, b: User) => getStatus(a) - getStatus(b),
    width: 50,
  },
};
