import { ContactInterestEntry } from '../../../graph/types';
import { setActionLoadingType, setRefetchRunType, RunsAction, setSelectedRunsType } from './actions';

export interface RunsState {
  selectedRuns: ContactInterestEntry[];
  actionLoading: boolean;
  refetchRun: (() => void) | undefined;
}

export const initialState: RunsState = {
  selectedRuns: [],
  actionLoading: false,
  refetchRun: undefined,
};

export const runsReducer = (state: RunsState = initialState, action: RunsAction): RunsState => {
  switch (action.type) {
    case setSelectedRunsType:
      return {
        ...state,
        selectedRuns: action.runs,
      };
    case setActionLoadingType:
      return {
        ...state,
        actionLoading: action.loading,
      };
    case setRefetchRunType:
      return {
        ...state,
        refetchRun: action.refetchRun,
      };
    default:
      return state;
  }
};
