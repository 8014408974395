import { Checkbox, DatePicker, Select } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';
import dayjs from 'dayjs';
import { useGetContactsWithSearch } from '../../hooks/ajax/contact/contactHooks';
import { useGetPatientsWithSearch } from '../../hooks/ajax/patients/patientHooks';
import { useGetServicesWithSearch } from '../../hooks/ajax/service/serviceHooks';
import { Contact, Patient, Service } from '../../graph/types';

export const reportParameterCheckboxTestId = 'reportParameterCheckboxTestId';
export const reportParameterDatePickerTestId = 'reportParameterDatePickerTestId';

export enum ParameterInputMapKeys {
  Checkbox = 'CHECKBOX',
  MultiSelect = 'MULTI_SELECT',
  Dropdown = 'DROPDOWN',
  DateRange = 'DATE_RANGE',
  Date = 'DATE_SINGLE',
}

export const parameterInputMap = (dateFormat: string): { [nameKey in ParameterInputMapKeys]: JSX.Element } => ({
  [ParameterInputMapKeys.Checkbox]: <Checkbox data-testid={reportParameterCheckboxTestId} />,
  [ParameterInputMapKeys.MultiSelect]: <Select mode='multiple' />,
  [ParameterInputMapKeys.Dropdown]: <Select />,
  [ParameterInputMapKeys.DateRange]: (
    <DatePicker.RangePicker
      format={dateFormat}
      placeholder={[translations.runReportModal.startDate, translations.runReportModal.endDate]}
      disabledDate={(date) => date.isAfter(dayjs())}
    />
  ),
  [ParameterInputMapKeys.Date]: <DatePicker format={dateFormat} />,
});

export enum SelectDataKey {
  SelectContact = 'CONTACT_SELECT',
  SelectPatient = 'PATIENT_SELECT',
  SelectService = 'SERVICE_SELECT',
}

export const selectParameterNameKeyMap: { [nameKey in SelectDataKey]: string[] } = {
  [SelectDataKey.SelectContact]: ['contacts', 'contactsLoading'],
  [SelectDataKey.SelectPatient]: ['patients', 'patientsLoading'],
  [SelectDataKey.SelectService]: ['services', 'servicesLoading'],
};

export const selectParameterFunctionMap: {
  [nameKey in SelectDataKey]: (organizationId: string) => Record<string, any>;
} = {
  [SelectDataKey.SelectContact]: useGetContactsWithSearch,
  [SelectDataKey.SelectPatient]: useGetPatientsWithSearch,
  [SelectDataKey.SelectService]: useGetServicesWithSearch,
};

export const getDataFunctionForParameter = (input_type: string) =>
  selectParameterFunctionMap[input_type as SelectDataKey];

export const getKeysForParameterData = (input_type: string) => selectParameterNameKeyMap[input_type as SelectDataKey];

export const getOptionsForReportParameter = (data: [], input_type: string): { value: string; label: string }[] => {
  if (!data) {
    return [];
  }
  if (input_type === SelectDataKey.SelectContact) {
    return data.map((contact: Contact) => ({ value: contact.id, label: contact.name }));
  } else if (input_type === SelectDataKey.SelectPatient) {
    return data.map((patient: Patient) => ({ value: patient.id, label: patient?.name || '' }));
  }

  return data.map((service: Service) => ({ value: service.id, label: service.name }));
};
