import React from 'react';
import { useGetInvoiceIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../hooks/route/navigationHooks';
import { ViewInvoice } from './ViewInvoice';
import { useRedirectAfterOfflineInsertSync } from '../../../event/RxDb/RxDbEvent';
import { isUuid, useOffline } from '../../../util/offline/offlineUtil';
import { routes } from '../../../constants/routes';
import { Loading } from '../../../components/Loading/Loading';

export const deleteButtonTestId = 'deleteButton';

export const ViewInvoiceContainer: React.FC = () => {
  const { navigateBack } = useNavigationToRoute();
  const { isOnline } = useOffline();

  const invoiceId = useGetInvoiceIdFromRoute();

  useRedirectAfterOfflineInsertSync(
    'invoice',
    isUuid(invoiceId) ? invoiceId : undefined,
    routes.viewInvoice,
    withInvoiceIdParameter
  );

  if (!invoiceId) {
    navigateBack();
  }

  if (isOnline && isUuid(invoiceId)) {
    return <Loading />;
  }

  return <ViewInvoice invoiceId={invoiceId} />;
};
