import gql from 'graphql-tag';
import { FileFields } from '../fragments';

export const UpsertFile = gql`
  mutation upsertFile($organizationId: ID!, $file: FileUpsert!) {
    upsertFile(organizationId: $organizationId, file: $file) {
      ...FileFields
    }
  }
  ${FileFields}
`;

export const GetFiles = gql`
  query getFilesOffline($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: HistoryFilter) {
    getFiles(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...FileFields
      created_user_id
      patient_id
      offline_id
      offline_patient_id
    }
  }
  ${FileFields}
`;
