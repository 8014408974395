import gql from 'graphql-tag';
import { PrescriptionFlattenedFields, PrescriptionFields, PrescriptionFillFields } from '../fragments';

export const GetPrescriptions = gql`
  query getPrescriptions($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: PrescriptionFilter) {
    getPrescriptions(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...PrescriptionFields
    }
  }
  ${PrescriptionFields}
`;

export const GetPrescriptionsFlattened = gql`
  query getPrescriptionsFlattened(
    $organizationId: ID!
    $patientId: ID
    $contactId: ID
    $filter: PrescriptionFlattenedFilter
  ) {
    getPrescriptionsFlattened(
      organizationId: $organizationId
      patientId: $patientId
      contactId: $contactId
      filter: $filter
    ) {
      ...PrescriptionFlattenedFields
    }
  }
  ${PrescriptionFlattenedFields}
`;

export const GetPrescriptionsOffline = gql`
  query getPrescriptionsOffline($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: PrescriptionFilter) {
    getPrescriptions(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...PrescriptionFields
    }
  }
  ${PrescriptionFields}
`;

export const UpsertPrescription = gql`
  mutation upsertPrescription($organizationId: ID!, $prescription: PrescriptionUpsert!) {
    upsertPrescription(organizationId: $organizationId, prescription: $prescription) {
      ...PrescriptionFlattenedFields
    }
  }
  ${PrescriptionFlattenedFields}
`;

export const GetPrescriptionsFills = gql`
  query getPrescriptionFills($organizationId: ID!, $prescriptionId: ID!) {
    getPrescriptionFills(organizationId: $organizationId, prescriptionId: $prescriptionId) {
      ...PrescriptionFill
    }
  }
  ${PrescriptionFillFields}
`;

export const UpsertPrescriptionFill = gql`
  mutation upsertPrescriptionFill($organizationId: ID!, $prescriptionFill: PrescriptionFillUpsert!) {
    upsertPrescriptionFill(organizationId: $organizationId, prescriptionFill: $prescriptionFill) {
      ...PrescriptionFillFields
    }
  }
  ${PrescriptionFillFields}
`;
