import { useState } from 'react';
import { PaymentStripeAccountStatus } from '../constants/referenceData/paymentReferenceData';
import { useUpsertStripeTransaction } from './ajax/paymentGateway/paymentGatewayHooks';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';

export const useGetStripeAccountStatus = (organizationId: string, practiceId: string) => {
  const [upsertStripeTransaction] = useUpsertStripeTransaction(organizationId);
  const [status, setStatus] = useState<PaymentStripeAccountStatus>();
  const [eventuallyDue, setEventuallyDue] = useState<string[]>();
  const [loading, setLoading] = useState(false);

  const checkStatus = async () => {
    setLoading(true);
    try {
      const result = await upsertStripeTransaction({
        variables: {
          organizationId,
          practiceId,
          statusRequestRecord: { checkStatus: true },
        },
      });

      if (result.data?.upsertStripeTransaction.status) {
        setStatus(result.data.upsertStripeTransaction.status! as PaymentStripeAccountStatus);
        setEventuallyDue(result.data.upsertStripeTransaction.eventually_due as string[]);
      }
    } catch (e) {
      showErrorMessage(translations.stripeLandingPage.statusError);
    }
    setLoading(false);
  };

  return {
    checkStatus,
    status,
    loading,
    eventuallyDue,
  };
};
