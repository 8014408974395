import React, { useState } from 'react';
import { translations } from '../../constants/translations';
import { LabRequest, Mutation } from '../../graph/types';
import { useUpdateLabRequest } from '../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { LabRequestStatusId } from '../../constants/referenceData/labReferenceData';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import { LabModal } from '../LabModal/LabModal';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { FetchResult } from '@apollo/client';

type LabRequestModalProps = {
  labRequests: LabRequest[];
  onClose: () => void;
};

export const LabRequestModal: React.FC<LabRequestModalProps> = ({ labRequests, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { windowWidth, windowHeight } = useWindowDimensions();
  const [updateLabRequest] = useUpdateLabRequest();
  const organizationId = useGetOrganizationIdFromRoute();
  const [isSaving, setIsSaving] = useState(false);
  const [requestsConfig, setRequestConfig] = useState(
    labRequests.map((request) => ({
      request,
      hasLoaded: false,
      isSubmitted: false,
    }))
  );
  const lastIndex = labRequests.length - 1;

  const handleLoadEvent = async () => {
    if (requestsConfig[currentIndex].hasLoaded) {
      let updatedRequest: FetchResult<Pick<Mutation, 'upsertLabRequest'>, Record<string, any>, Record<string, any>>;
      const { request } = requestsConfig[currentIndex];
      setIsSaving(true);
      try {
        updatedRequest = await updateLabRequest({
          variables: {
            organizationId,
            labRequest: {
              id: request.id,
              record: {
                service_id: request.service_id,
                patient_id: request.patient_id,
                practice_id: request.practice_id,
                contact_id: request.contact_id,
                doctor_id: request.doctor_id,
                description: request.description,
                lab_request_data: request.lab_request_data,
                lab_request_number: request.lab_request_number,
                request_group_id: request.request_group_id,
                send_log: request.send_log,
                send_error_log: request.send_error_log,
                lab_status_value: request.lab_status_value,
                lab_service_map_id: request.lab_service_map_id,
                organization_id: organizationId,
                status_id: LabRequestStatusId.Submitted,
                lab_device_id: request.lab_device_id,
                created_from_lab: request.created_from_lab,
                lab_created_order_number: request.lab_created_order_number,
                lab_created_accession_id: request.lab_created_accession_id,
                pims_created_order_number: request.pims_created_order_number,
                pims_created_accession_id: request.pims_created_accession_id,
              },
            },
          },
        });
        showSuccessMessage(translations.labRequestModal.successSavingLabRequest);
      } catch (e) {
        showErrorMessage(translations.labRequestModal.errorSavingLabRequest);
      }
      setIsSaving(false);

      const updated = requestsConfig.map((c, i) => {
        if (i === currentIndex) {
          openTabWithPopupDetection(updatedRequest.data?.upsertLabRequest.lab_request_url ?? '');
          currentIndex === lastIndex ? onClose() : setCurrentIndex((prev) => prev + 1);
          return { ...c, isSubmitted: true };
        }
        return c;
      });

      return setRequestConfig(updated);
    }

    const updated = requestsConfig.map((c, i) => {
      if (i === currentIndex) {
        return { ...c, hasLoaded: true };
      }
      return c;
    });
    return setRequestConfig(updated);
  };

  return (
    <LabModal
      title={translations.labRequestModal.title}
      open
      onCancel={onClose}
      cancelConfirmMessage={translations.labRequestModal.cancelMessage}
      okButtonProps={{ hidden: true }}
      width={windowWidth >= 1200 ? 1200 : 1000}
      isSaving={isSaving}
      url={labRequests?.[currentIndex].lab_request_url ?? ''}
      height={windowHeight}
      handleOnLoad={handleLoadEvent}
      changesMade
    />
  );
};
