import { InputNumber, Radio, Table } from 'antd';
import React from 'react';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../constants/layout';
import { routes } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { RelatedOwner } from '../../graph/types';
import { useNavigationToRoute, withContactIdParameter } from '../../hooks/route/navigationHooks';
import {
  setPrimaryOwner,
  tooltipToIndicateEditMode,
  updatePercentageValue,
} from '../../pages/Patients/ViewPatient/PatientOwnership/ownershipUtils';
import {
  OwnerTableSummary,
  OwnerTableSummaryDisplayText,
} from '../../pages/Patients/ViewPatient/PatientOwnership/OwnerTableSummary';
import { AbbreviateToWidth } from '../AbbreviateToWidth/AbbreviateToWidth';
import { TableCellLink } from '../TableLink/TableCellLink';

type PercentageTableProps = {
  setModalVisible?: (visibility: boolean) => void;
  isEditMode: boolean;
  removeOwner: (id: number) => void;
  stakeholders: RelatedOwner[];
  setStakeholders: (stakeholders: RelatedOwner[]) => void;
  displayText: OwnerTableSummaryDisplayText & {
    nameColumn: string;
    primaryColumn: string;
  };
  linkOnContactName: boolean;
};

export const PercentageTable: React.FC<PercentageTableProps> = ({
  setModalVisible,
  isEditMode,
  removeOwner,
  stakeholders,
  setStakeholders,
  displayText,
  linkOnContactName,
}) => {
  const { navigateTo } = useNavigationToRoute();

  const linkToViewOwner = (text: string, owner: RelatedOwner) => (
    <TableCellLink onClick={() => navigateTo(routes.viewContact, withContactIdParameter(owner.contact_id ?? ''))}>
      <AbbreviateToWidth width={300} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
    </TableCellLink>
  );

  const percentageCell = (value: number, ownerIndex: number) => {
    const handlePercentageChange = (input: number | null) => {
      const updatedStakeholders = updatePercentageValue(stakeholders, ownerIndex, input);
      setStakeholders(updatedStakeholders);
    };

    return (
      <InputNumber
        className='Ownership__PercentageCell'
        title={tooltipToIndicateEditMode(!isEditMode)}
        min={0}
        max={100}
        value={value}
        onChange={(val) => handlePercentageChange(val)}
        disabled={!isEditMode}
      />
    );
  };

  const primaryCheckbox = (primary: boolean, ownerIndex: number) => {
    const handleChangePrimary = (ownerIndex: number) => {
      const newOwners = setPrimaryOwner(stakeholders, ownerIndex);
      setStakeholders(newOwners);
    };

    return (
      <span title={tooltipToIndicateEditMode(!isEditMode)}>
        <Radio
          disabled={!isEditMode}
          data-testid={'Radio ' + ownerIndex.toString()}
          checked={primary}
          onChange={() => handleChangePrimary(ownerIndex)}
        />
      </span>
    );
  };

  const deleteOwnerButton = (ownerIndex: number) => {
    return (
      <TableCellLink
        title={tooltipToIndicateEditMode(!isEditMode)}
        disabled={!isEditMode}
        onClick={() => removeOwner(ownerIndex)}
        dataTestId={'Delete ' + ownerIndex.toString()}
      >
        {translations.patientPage.ownership.buttons.delete}
      </TableCellLink>
    );
  };

  const columns = [
    {
      title: displayText.nameColumn,
      dataIndex: 'name',
      render: (text: string, owner: RelatedOwner) =>
        !linkOnContactName ? (
          <AbbreviateToWidth width={170} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
        ) : (
          linkToViewOwner(text, owner)
        ),
    },
    {
      title: translations.patientPage.ownership.columns.percentage,
      dataIndex: 'percentage',
      render: (value: number, owner: RelatedOwner) => percentageCell(value, stakeholders.indexOf(owner)),
    },
    {
      title: displayText.primaryColumn,
      dataIndex: 'primary',
      render: (value: boolean, owner: RelatedOwner) => primaryCheckbox(value, stakeholders.indexOf(owner)),
    },
    {
      title: translations.patientPage.ownership.columns.actions,
      key: 'actions',
      render: (_value: string, owner: RelatedOwner) => deleteOwnerButton(stakeholders.indexOf(owner)),
    },
  ];

  const tableSummaryInEditMode = (ownersOnPage: readonly RelatedOwner[]) => (
    <OwnerTableSummary owners={ownersOnPage} setModalVisible={setModalVisible} displayText={displayText} />
  );

  return (
    <Table<RelatedOwner>
      dataSource={stakeholders}
      columns={columns}
      rowKey={'contact_id'}
      pagination={false}
      summary={isEditMode ? tableSummaryInEditMode : undefined}
    />
  );
};
