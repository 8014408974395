import { ViewInvoiceAction, SetIsBillToOpen } from './actions';

export interface ViewInvoiceState {
  isBillToOpen: boolean;
}

export const initialState: ViewInvoiceState = {
  isBillToOpen: false,
};

export const viewInvoiceReducer = (state: ViewInvoiceState, action: ViewInvoiceAction): ViewInvoiceState => {
  switch (action.type) {
    case SetIsBillToOpen:
      return {
        ...state,
        isBillToOpen: action.isOpen,
      };
    default:
      return state;
  }
  return state;
};
