import { Col, Form, Grid, Row } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import {
  InterestRunConfigFormFields,
  InterestSettingsFields,
  InterestSettingsForm,
  InterestSettingValue,
} from '../../components/InterestSettingsForm/InterestSettingsForm';
import {
  getChargeFreePeriodValue,
  getInitialValues,
} from '../../components/InterestSettingsForm/InterestSettingsFormUtils';
import { Loading } from '../../components/Loading/Loading';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import { SaveAndResetButton } from '../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { PracticeSettingsId, PracticeSettingsNameKey, translations } from '../../constants/translations';
import { Setting, SettingUpsert } from '../../graph/types';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useDefaultPracticeId, useGetPracticeWithSettings } from '../../hooks/ajax/practice/practiceHooks';
import { getSettingUpsert, useUpsertSetting } from '../../hooks/ajax/setting/settingHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useFormChanges } from '../../hooks/useFormChanges';
import { getPracticeSetting } from '../Contacts/ViewContact/statementUtils';

const labelWidth = { xl: 3, lg: 6 };

type InterestSettingsProps = {
  practiceId: string;
  interestSettings: Setting;
  practiceRefetch?: () => void;
};

const InterestSettingsContent: React.FC<InterestSettingsProps> = ({
  practiceId,
  interestSettings,
  practiceRefetch,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertSetting] = useMutationWithMessages(useUpsertSetting);
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();

  const initialValues = getInitialValues(interestSettings);

  const { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange } = useFormChanges(
    form,
    initialValues
  );

  const handleFinish: FormProps<InterestRunConfigFormFields>['onFinish'] = async (value) => {
    const interestSettingValue: InterestSettingValue = {
      interestRate: value[InterestSettingsFields.interestRate],
      isCompounded: !!value[InterestSettingsFields.isCompounded],
      chargeFreePeriod: getChargeFreePeriodValue(value),
    };

    setIsSaving(true);
    const interestUpsert = getSettingUpsert(
      JSON.stringify(interestSettingValue),
      PracticeSettingsId.Interest,
      interestSettings.id,
      practiceId
    );

    const getUpsertWithMessages = (upsert: SettingUpsert) => ({
      options: {
        variables: {
          organizationId,
          setting: upsert,
        },
      },
    });

    await upsertSetting(getUpsertWithMessages(interestUpsert));
    await practiceRefetch?.();
    setIsSaving(false);
    setHasDataChanged(false);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasDataChanged}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.interestSettings.entity)}
    >
      <StyledPageHeader title={translations.interestSettings.title} />
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleFinish}
        labelCol={labelWidth}
        style={{ padding: '2rem 0' }}
        autoComplete='off'
      >
        <InterestSettingsForm offset={xl ? labelWidth.xl : labelWidth.lg} />
        {hasDataChanged && (
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <SaveAndResetButton onReset={handleOnReset} offset={6} />
            </Col>
          </Row>
        )}
      </Form>
    </SaveSpinnerAndNavigationWarning>
  );
};

const InterestSettingsComponent = () => {
  const practiceId = useDefaultPracticeId();
  const organizationId = useGetOrganizationIdFromRoute();
  const { practice, refetch } = useGetPracticeWithSettings(organizationId, practiceId);
  const interestSettings = getPracticeSetting(PracticeSettingsNameKey.Interest, practice);

  if (!practice || !interestSettings) {
    return <Loading />;
  }

  return (
    <InterestSettingsContent practiceId={practiceId} interestSettings={interestSettings} practiceRefetch={refetch} />
  );
};

export default InterestSettingsComponent;
