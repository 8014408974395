import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { UsersOverview } from './UsersOverview/UsersOverview';
import { CreateUser } from './CreateUser/CreateUser';
import { ViewUser } from './ViewUser/ViewUser';

export const Users: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={routes.createUser} component={CreateUser} />
        <Route path={routes.viewUser} component={ViewUser} />
        <Route path={`${match.path}`} component={UsersOverview} />
      </Switch>
    </>
  );
};
