import styled from 'styled-components';
import { Space, Select, Input } from 'antd';

export const FilterDropdownContainer = styled.div`
  padding: 10px;
`;

export const FilterDropdownActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export const Error = styled.span`
  color: red;
`;

export const FixedWidthSelect = styled(Select<string | number | undefined>)`
  min-width: 150px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em 1em;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 8px;
  display: block;
`;
