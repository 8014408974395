import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Popconfirm, Popover, Radio, Space } from 'antd';
import {
  statementRunActionConfigs,
  statementRunActionErrorConfigs,
  StatementRunActionErrors,
  StatementRunActionStatus,
  StatementRunPreference,
  statementRunPreferenceConfigs,
  translations,
} from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { basicStatementRunColumns } from './statementRunColumns';
import { StatementContactInterface, StatementContactPending, StatementContactSent } from '../../graph/types';
import { useGetContactReferenceData } from '../../hooks/ajax/contact/contactHooks';
import { getAvailableContactStatusTypeFilters } from '../../util/contactFilterUtil';
import { ContactStatusHelper } from '../Contacts/ContactsOverview/contactStatusUtil';
import { useGetStatementRun } from '../../hooks/ajax/statementRuns/statementRunHooks';
import { useNavigationToRoute, withContactIdParameter } from '../../hooks/route/navigationHooks';
import { TableCellLink } from '../../components/TableLink/TableCellLink';
import { AbbreviateToWidth } from '../../components/AbbreviateToWidth/AbbreviateToWidth';
import { routes } from '../../constants/routes';
import { PADDING_COMPENSATION_WIDTH_TEXT } from '../../constants/layout';
import { ColumnFilterItem, TableRowSelection } from 'antd/lib/table/interface';
import { StatementsContext } from './store/state';
import { setRefetchStatement, setSelectedStatements } from './store/actions';
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  HourglassOutlined,
  ReloadOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { TableFilterAndSortKey, TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { TagColor } from '../../constants/tagColor';
import {
  generalFilteringAndSortingSettings,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { StatementRunsActions } from './StatementRunsActions';
import { StatusWrapper, SpaceBetweenWrapper, StatusContainer } from './StatementRunsTabContent.style';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { useGetPractice } from '../../hooks/ajax/practice/practiceHooks';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';
import {
  contactPropertyNames,
  statementContactPendingPropertyNames,
  statementContactSentPropertyNames,
  statementRunPropertyNames,
} from '../../constants/propertyNames';

const getActionStatusFilters = (): ColumnFilterItem[] =>
  Object.values(StatementRunActionStatus).map((value) => ({ value, text: statementRunActionConfigs[value] }));
const getSendTypeFilters = (): ColumnFilterItem[] =>
  Object.values(StatementRunPreference).map((value) => ({ value, text: statementRunPreferenceConfigs[value] }));

export interface StatementRunsTabContentProps {
  practiceId: string;
  statementRunId: string;
  onDelete: (statementRunId: string) => void;
  isDeletable?: boolean;
  active: boolean;
}

type SelectedOption = 'outstanding' | 'processed';

const radioOptions = [
  { label: translations.statementRun.tabContent.outstanding, value: 'outstanding' },
  { label: translations.statementRun.tabContent.processed, value: 'processed' },
];

const iconStatusStyle = { fontSize: 'large', marginRight: '5px' };

const StatementRunsTabContent: React.FC<StatementRunsTabContentProps> = ({
  statementRunId,
  onDelete,
  isDeletable,
  active,
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectedOption>('outstanding');
  const organizationId = useGetOrganizationIdFromRoute();
  const { organization } = useGetOrganization(organizationId);
  const { practice } = useGetPractice(organizationId, organization?.default_practice_id || '');
  const { state, dispatch } = useContext(StatementsContext);
  const [currentDataSource, setCurrentDataSource] = useState<StatementContactInterface[]>();

  const getSelectedRowKeys = useCallback(() => {
    return state.selectedStatements.map((selectedStatement) => selectedStatement.id);
  }, [state]);

  const stateSelectedRowKeys = getSelectedRowKeys();

  const { statement, statementLoading, refetchStatement } = useGetStatementRun(organizationId, statementRunId);
  const pendingStatements = useMemo(() => statement?.pending ?? [], [statement]);
  const sentStatements = useMemo(() => statement?.sent ?? [], [statement]);

  const refetchAndSwitch = useCallback(() => {
    refetchStatement();
    setSelectedOption('processed');
  }, [refetchStatement]);

  useEffect(() => {
    dispatch(setRefetchStatement(refetchAndSwitch));
  }, [dispatch, refetchAndSwitch, active]);

  const { navigateTo } = useNavigationToRoute();

  const { contactReferenceData, contactReferenceDataLoading } = useGetContactReferenceData({ organizationId });
  const contactStatusTypeFilters = getAvailableContactStatusTypeFilters(contactReferenceData?.status_type ?? []);
  const contactStatusHelper = useMemo(
    () => new ContactStatusHelper(contactReferenceData?.status_type),
    [contactReferenceData]
  );

  const { resetFiltersAndSort, filteredValue, tableChangeHandler } = useTableResetFilterAndSort(
    TableFilterAndSortKey.StatementRunTabContent
  );

  const handleStatementTypeChange = (value: SelectedOption) => {
    dispatch(setSelectedStatements([]));
    setSelectedOption(value);
    resetFiltersAndSort();
  };

  const handleOnRowCheckedChange: TableRowSelection<StatementContactPending | StatementContactSent>['onChange'] = (
    _,
    selectedRows,
    info
  ) => {
    if (info.type === 'all' && currentDataSource) {
      dispatch(
        setSelectedStatements(stateSelectedRowKeys.length !== currentDataSource.length ? currentDataSource : [])
      );
    } else {
      dispatch(setSelectedStatements(selectedRows));
    }
  };

  const getLastSendErrorComponent = (error: string, includeText?: boolean) => (
    <Popover destroyTooltipOnHide content={statementRunActionErrorConfigs[error as StatementRunActionErrors]}>
      <Space>
        <WarningTwoTone style={iconStatusStyle} twoToneColor={TagColor.Color2} />
        {includeText && statementRunActionConfigs[StatementRunActionStatus.Error]}
      </Space>
    </Popover>
  );

  const getErrorFilter = (value: string, record: StatementContactSent) => {
    return value === StatementRunActionStatus.Error && !!record.last_send_error;
  };

  const renderStatuses = (arr: string[]) => {
    const res: Record<string, { total: number; icon: JSX.Element }> = {};

    arr.forEach((status) => {
      const statusLabel = statementRunActionConfigs[status as StatementRunActionStatus];

      if (res[statusLabel]) {
        res[statusLabel].total = ++res[statusLabel].total;
      } else {
        res[statusLabel] = {
          total: 1,
          icon:
            status === StatementRunActionStatus.GeneratePdf || status === StatementRunActionStatus.Queued ? (
              <HourglassOutlined style={iconStatusStyle} />
            ) : (
              <CheckCircleTwoTone style={iconStatusStyle} twoToneColor={TagColor.Color1} />
            ),
        };
      }
    });

    const labels = Object.keys(res);

    return Object.values(res).map(({ total, icon }, i) => {
      return (
        <StatusWrapper key={i}>
          {icon}
          {labels[i]}
          {total > 1 && ` (${total})`}
          {labels.length && i !== labels.length - 1 ? ',' : ''}
        </StatusWrapper>
      );
    });
  };

  function getStatementRunColumns(): CustomColumnType<StatementContactPending | StatementContactSent>[] {
    const allColumns = Array<CustomColumnType<StatementContactPending | StatementContactSent>>();
    selectedOption === 'outstanding' &&
      allColumns.push({
        dataIndex: 'last_send_error',
        width: 15,
        render: (value) => !!value && getLastSendErrorComponent(value),
      });
    allColumns.push({
      ...(basicStatementRunColumns.name as CustomColumnType<StatementContactPending | StatementContactSent>),
      filteredValue: filteredValue[statementRunPropertyNames.name] ?? null,
      width: 200,
      render: (value, record) => (
        <TableCellLink onClick={() => navigateTo(routes.viewContact, withContactIdParameter(record.contact_id))}>
          <AbbreviateToWidth width={200} text={value} paddingCompensation={PADDING_COMPENSATION_WIDTH_TEXT} />
        </TableCellLink>
      ),
    });
    selectedOption === 'outstanding' &&
      allColumns.push({
        title: translations.statementRun.columns.amountDue,
        dataIndex: 'ending_balance',
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.statementRun.columns.amountDue
        ),
        isForNumber: true,
        filteredValue: filteredValue[statementContactPendingPropertyNames.ending_balance] ?? null,
        onFilter: getOnFilterFunctionFor('ending_balance' as any, true),
        sorter: getNumberCompareFunctionFor('ending_balance' as any),
        render: (value: string) => <CurrencyFormatter total={value} />,
        width: 200,
      });
    selectedOption === 'outstanding' &&
      allColumns.push({
        ...(basicStatementRunColumns.contact_status_name as CustomColumnType<
          StatementContactPending | StatementContactSent
        >),
        width: 200,
        filters: contactStatusTypeFilters,
        filteredValue: filteredValue[contactPropertyNames.contact_status_name] ?? null,
        render: (_, record) => contactStatusHelper.getTag(record, contactReferenceData?.status_type ?? []),
      });
    allColumns.push({
      dataIndex: 'email',
      width: 200,
      title: translations.statementRun.columns.email,
      ...generalFilteringAndSortingSettings,
      filteredValue: filteredValue[statementContactPendingPropertyNames.email] ?? null,
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.statementRun.columns.email),
      onFilter: getOnFilterFunctionFor('email'),
      sorter: getStringCompareFunctionFor('email'),
    });
    allColumns.push({
      dataIndex: 'send_type',
      width: 200,
      title: translations.statementRun.columns.preference,
      render: (value) => statementRunPreferenceConfigs[value as StatementRunPreference],
      ...generalFilteringAndSortingSettings,
      sorter: getStringCompareFunctionFor('send_type'),
      filteredValue: filteredValue[statementContactPendingPropertyNames.send_type] ?? null,
      filters: getSendTypeFilters(),
      onFilter: (value, record) => value === String(record.send_type),
    });
    selectedOption === 'processed' &&
      allColumns.push({
        dataIndex: statementContactSentPropertyNames.last_send_status,
        width: 200,
        title: translations.statementRun.columns.sendStatus,
        render: (_, record) =>
          (record as StatementContactSent).last_send_error ? (
            <>{getLastSendErrorComponent((record as StatementContactSent).last_send_error ?? '', true)}</>
          ) : (
            <StatusContainer>
              {renderStatuses((record as StatementContactSent)?.last_send_status as string[])}
            </StatusContainer>
          ),
        ...generalFilteringAndSortingSettings,
        sorter: getStringCompareFunctionFor(statementContactSentPropertyNames.last_send_status as any),
        filteredValue: filteredValue[statementContactSentPropertyNames.last_send_status] ?? null,
        filters: getActionStatusFilters(),
        onFilter: (value, record) =>
          ((record as StatementContactSent).last_send_status?.includes(value?.toString()) &&
            !(record as StatementContactSent).last_send_error) ||
          getErrorFilter(value as string, record as StatementContactSent),
      });

    return allColumns;
  }

  const handleRefresh = () => {
    refetchStatement();
  };

  return (
    <>
      <SpaceBetweenWrapper>
        <Space>
          <Radio.Group
            optionType='button'
            buttonStyle='solid'
            options={radioOptions}
            value={selectedOption}
            onChange={(e) => handleStatementTypeChange(e.target.value)}
          />
          {statement && currentDataSource && (
            <StatementRunsActions
              practiceId={practice?.id ?? ''}
              selectedStatementRun={statement}
              isProcessedTab={selectedOption === 'processed'}
              refreshFilters={resetFiltersAndSort}
              currentData={currentDataSource}
            />
          )}
        </Space>
        <Space>
          <Button style={{ float: 'right' }} onClick={handleRefresh}>
            <ReloadOutlined /> {translations.statementRun.refresh}
          </Button>
          <Popconfirm
            title={translations.statementRun.deleteConfirmMessage}
            onCancel={() => onDelete(statementRunId)}
            okText={translations.shared.popconfirm.no}
            cancelText={translations.shared.popconfirm.ok}
            placement={'topRight'}
            disabled={!isDeletable}
          >
            <Button danger type={'primary'} disabled={!isDeletable}>
              <DeleteOutlined /> {translations.statementRun.deleteButtonText}
            </Button>
          </Popconfirm>
        </Space>
      </SpaceBetweenWrapper>
      <TableWithCustomFiltering
        tableKey={TableKey.StatementRunTabContent}
        columns={getStatementRunColumns()}
        dataSource={selectedOption === 'outstanding' ? pendingStatements : sentStatements}
        loading={contactReferenceDataLoading || statementLoading}
        rowKey={'id'}
        rowSelection={{
          type: 'checkbox',
          onChange: handleOnRowCheckedChange,
          selectedRowKeys: getSelectedRowKeys(),
        }}
        setCurrentDataSource={setCurrentDataSource}
        onChange={tableChangeHandler}
      />
    </>
  );
};

export default StatementRunsTabContent;
