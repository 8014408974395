import React, { useEffect } from 'react';

interface AntechImmersiveProps {
  isDockable: boolean;
  url: string;
  showComponent?: boolean;
}

export const AntechImmersive: React.FC<AntechImmersiveProps> = ({ isDockable, url, showComponent }) => {
  useEffect(() => {
    const bindMargaEvents = (window as { [key: string]: any }).bindMargaEvents as () => void;
    if (bindMargaEvents) {
      bindMargaEvents();
    }

    if (showComponent) {
      const winObj = window as { [key: string]: any };
      const fnShowComponent = (isDockable ? winObj.showDockableComponent : winObj.showIframeComponent) as () => void;
      if (fnShowComponent) {
        fnShowComponent();
      }
    }
  });

  return (
    <>
      {isDockable ? (
        <div id='pims-immersion-container'>
          <iframe id='pims-immersion-app' className='pims-immersion-app-max' src={url} title='PIMS Immersion' />
        </div>
      ) : (
        <div id='pims-immersion-iframe-container'>
          <iframe
            id='pims-immersion-iframe-app'
            className='pims-immersion-iframe-app'
            src={url}
            title='PIMS Immersion'
          ></iframe>
        </div>
      )}
      <div id='pims-immersion-footer-bar' className='pims-immersion-footer-bar' style={{ display: 'none' }}>
        <p style={{ color: 'white', padding: '8px', marginRight: '25px' }}>Test Orders and Results</p>
        <span id='pims-immersion-footer-bar-max-icon' className='pims-immersion-footer-bar-icon' title='maximize'>
          <img src='/marga-assets/icons/Expand_Static.svg' alt='' />
        </span>
        <span id='pims-immersion-footer-bar-close-icon' className='pims-immersion-footer-bar-icon' title='close'>
          <img src='/marga-assets/icons/Close_Static.svg' alt='' />
        </span>
      </div>
    </>
  );
};
