import { QueryGetUrlForFileDownloadArgs } from '../../../graph/types';
import { GetUrlForFileDownload } from '../../../graph/queries/fileUrls';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useGetUrlForFileDownload = ({
  organizationId,
  filePointer,
  fileName,
  contentType,
}: {
  organizationId: string;
  filePointer: string;
  fileName: string;
  contentType?: string;
}) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getUrlForFileDownload', QueryGetUrlForFileDownloadArgs>(
    GetUrlForFileDownload,
    {
      variables: {
        organizationId,
        filePointer,
        fileName,
        contentType,
      },
      skip: filePointer === '' || fileName === '' || organizationId === '',
      fetchPolicy: 'no-cache',
    }
  );

  return {
    fileUrl: data?.getUrlForFileDownload,
    fileUrlLoading: loading,
    fileUrlError: error,
  };
};
