import { BatchGroupUpsert, MutationUpsertServiceBatchGroupArgs } from '../../../graph/types';
import { UpsertServiceBatchGroup } from '../../../graph/queries/rapidBilling';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import {
  useOfflineDelete,
  useOfflineInsert,
  useOfflineListWithField,
  useOfflineUpdate,
} from '../../localDatabaseHooks';
import { RxUpsert } from '../../../services/LocalDatabaseService/schemas/upsertSchema';
import { getOfflineId, getUpdatedDate } from '../../../services/LocalDatabaseService/queries/queryUtils';
import { useCallback } from 'react';

export const useUpsertRapidBilling = () => {
  return useOfflineErrorSkipMutation<'upsertServiceBatchGroup', MutationUpsertServiceBatchGroupArgs>(
    UpsertServiceBatchGroup
  );
};

export const useBatchGroupUpsertOfflineInsert = () => {
  const offlineInsert = useOfflineInsert<RxUpsert>('upsert');

  return (upsert: BatchGroupUpsert) => {
    const offlineId = getOfflineId();
    const obj: RxUpsert = {
      id: offlineId,
      type_name: 'upsertServiceBatchGroup',
      upsert_offline_id: offlineId,
      is_new: true,
      updated: getUpdatedDate(),
      upsert,
    };
    return offlineInsert?.(obj);
  };
};

export const useBatchGroupUpsertOfflineUpdate = () => {
  const offlineUpdate = useOfflineUpdate<RxUpsert>('upsert');

  return (rxUpsert: RxUpsert, upsert: BatchGroupUpsert) => {
    const obj: RxUpsert = {
      ...rxUpsert,
      updated: getUpdatedDate(),
      upsert,
    };
    return offlineUpdate?.(rxUpsert.id, obj);
  };
};

export const useBatchGroupUpsertOfflineDelete = () => {
  const offlineDelete = useOfflineDelete<RxUpsert>('upsert');

  return (id: string) => {
    return offlineDelete?.(id);
  };
};

export const useBatchGroupUpsertOfflineList = () => {
  const offlineList = useOfflineListWithField<RxUpsert>('upsert');
  const list = useCallback(() => {
    return offlineList?.('type_name', 'upsertServiceBatchGroup');
  }, [offlineList]);
  if (!offlineList) {
    return undefined;
  }
  return list;
};
