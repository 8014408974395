import Amplify, { Auth } from 'aws-amplify';
import _ from 'lodash';
import config from '../../config/config';

class AuthService {
  constructor() {
    Amplify.configure({
      Auth: {
        region: config.REGION,
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
        mandatorySignIn: false,
      },
    });
  }

  async logout() {
    sessionStorage.clear();
    await Auth.signOut();
    // Issues with cache reset not removing all active queries was causing
    // never ending loading states. Opting to reload page to completely clear
    // Apollo cache and all Active Queries.
    window.history.replaceState(null, 'root', '/');
    window.location.reload();
  }
}

const authService = new AuthService();
export default authService;
