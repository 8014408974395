import React, { useContext, useEffect, useState } from 'react';
import { phoneTableActions, PhoneTableContext } from '../phoneTableState';
import { Phone } from '../../../graph/types';
import { useHandlePhoneTableRowEdit } from '../phoneTableUtil';
import { PhoneTableEvent } from '../phoneTableEvent';
import { phoneTablePropertyNames } from '../phoneTableContants';
import 'react-phone-number-input/style.css';
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import { StyledInput, StyledPhoneInput } from '../PhoneTable.style';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';

type PhoneTablePhoneCellProps = {
  phone: Phone;
  property: keyof Pick<Phone, 'phone' | 'description' | 'extension'>;
};

export const getPhoneTableTextInputTestId = (property: PhoneTablePhoneCellProps['property']) =>
  `PHONE_TABLE_TEXT_CELL_TEST_ID-${property}`;

export default function PhoneTableTextCell({ phone, property }: PhoneTablePhoneCellProps) {
  const isPhoneInput = property === phoneTablePropertyNames.phone;
  const {
    state: { currentEditingPhone, defaultPhoneCountry },
    dispatch,
  } = useContext(PhoneTableContext);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    localeData: { phoneFormat },
  } = useUserLocaleData();
  const handleEdit = useHandlePhoneTableRowEdit(phone);
  const handleInputChange = (value: string) => {
    setErrorMessage(null);
    if (!currentEditingPhone) {
      return;
    }
    dispatch(
      phoneTableActions.setCurrentEditing({
        ...currentEditingPhone,
        phone: { ...currentEditingPhone.phone, [property]: value },
      })
    );
  };

  useEffect(() => {
    if (currentEditingPhone?.phone?.id === phone.id) {
      setErrorMessage(null);
    }
  }, [currentEditingPhone, phone.id]);

  useEffect(() => {
    PhoneTableEvent.inputError.on(({ inputName, message }) => {
      if (inputName === property) {
        setErrorMessage(message);
      }
    });
    return () => void PhoneTableEvent.inputError.off();
  }, [property]);

  return currentEditingPhone?.phone?.id === phone.id ? (
    <div>
      {isPhoneInput ? (
        <StyledPhoneInput
          defaultCountry={defaultPhoneCountry ?? phoneFormat ?? 'US'}
          value={currentEditingPhone.phone[property] ?? ''}
          maxLength={25}
          onChange={handleInputChange}
          data-testid={getPhoneTableTextInputTestId(property)}
        />
      ) : (
        <StyledInput
          type={'text'}
          value={currentEditingPhone.phone[property] ?? ''}
          onChange={(e) => handleInputChange(e.target.value)}
          maxLength={25}
          data-testid={getPhoneTableTextInputTestId(property)}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  ) : (
    <div style={isPhoneInput ? { whiteSpace: 'nowrap' } : {}} onClick={handleEdit}>
      {isPhoneInput && isPossiblePhoneNumber(phone[property]) ? formatPhoneNumber(phone[property]) : phone[property]}
    </div>
  );
}
