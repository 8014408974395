import React, { useMemo } from 'react';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { StyledCardGrid } from '../../components/SettingsCard/SettingsCard.style';
import { LDFlagNames } from '../../constants/launchDarkly';
import { translations } from '../../constants/translations';
import { AdditionalOrganizationField } from '../../graph/queries/organizations';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { useDefaultPracticeId, useGetPractice } from '../../hooks/ajax/practice/practiceHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useLDFlag } from '../../hooks/useLDHooks';
import { StyledPageHeader } from '../ReminderRuns/ReminderRuns.styles';
import { AddOnCardAntechContainer } from './Cards/AddOnCardAntechContainer';
import { AddOnCardEMRContainer } from './Cards/AddOnCardEMRContainer';
import AddonCardOfflineContainer from './Cards/AddonCardOfflineContainer';
import { AddOnCardPaymentPortalContainer } from './Cards/AddOnCardPaymentPortalContainer';
import { AddOnCardVetConnectContainer } from './Cards/AddOnCardVetConnectContainer';
import { AddOnCardCloverConnectContainer } from './Cards/AddOnCardCloverConnectContainer';
import AddOnCardStripeContainer from './Cards/AddOnCardStripeContainer';
import AddOnCardQuickBooksContainer from './Cards/AddOnCardQuickBooksContainer';
import { AddOnCardHisaContainer } from './Cards/AddOnCardHisaContainer';
import { useOrganizationContext } from '../../contexts/organization/state';
import { getIsStripeProcessor } from '../../util/stripeConnectUtils';

export const AddOns: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const showOfflineCard = useLDFlag(LDFlagNames.AddonsOffline);
  const showPaymentPortalCard = useLDFlag(LDFlagNames.AddonsPaymentPortal);
  const showAntechCard = useLDFlag(LDFlagNames.AddonsAntechLab);
  const showVetConnectCard = useLDFlag(LDFlagNames.AddonsVetConnectLab);
  const showEmrCard = useLDFlag(LDFlagNames.EMR);
  const showCloverConnectCard = useLDFlag(LDFlagNames.AddOnsCardConnect);
  const showStripeCard = useLDFlag(LDFlagNames.AddOns_Stripe);
  const showQuickBooksCard = useLDFlag(LDFlagNames.QuickBooks);
  const showHisaCard = useLDFlag(LDFlagNames.Hisa);
  const practiceId = useDefaultPracticeId();
  const { organization, organizationLoading, organizationRefetch } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.AddOn
  );

  const { practice, practiceLoading } = useGetPractice(organizationId, practiceId);
  const {
    state: { organization: organizationWithPractice },
  } = useOrganizationContext();

  const canEnablePaymentPortal = useMemo(
    () => getIsStripeProcessor(organizationWithPractice) || !!practice?.card_connect?.merchantId,
    [organizationWithPractice, practice?.card_connect?.merchantId]
  );

  return (
    <SaveSpinner isSaving={organizationLoading || practiceLoading}>
      <StyledPageHeader title={translations.addOns.title} />
      <StyledCardGrid>
        {showOfflineCard && (
          <AddonCardOfflineContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
          />
        )}
        {showPaymentPortalCard && (
          <AddOnCardPaymentPortalContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
            canEnablePaymentPortal={canEnablePaymentPortal}
          />
        )}
        {showAntechCard && (
          <AddOnCardAntechContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
          />
        )}
        {showEmrCard && (
          <AddOnCardEMRContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
          />
        )}
        {showVetConnectCard && (
          <AddOnCardVetConnectContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
            practiceId={practiceId}
          />
        )}
        {showCloverConnectCard && <AddOnCardCloverConnectContainer />}
        {showStripeCard && practice && <AddOnCardStripeContainer organizationId={organizationId} practice={practice} />}
        {showQuickBooksCard && (
          <AddOnCardQuickBooksContainer
            organizationId={organizationId}
            organization={organization}
            refetchOrganization={organizationRefetch}
          />
        )}
        {showHisaCard && (
          <AddOnCardHisaContainer
            organization={organization}
            organizationId={organizationId}
            refetchOrganization={organizationRefetch}
          />
        )}
      </StyledCardGrid>
    </SaveSpinner>
  );
};
