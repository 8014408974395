import React from 'react';
import { RouteProps } from 'react-router';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { translations } from '../../constants/translations';
import { Redirect, Route } from 'react-router-dom';
import { getUserPermissions } from '../../util/userPermissionUtil';
import { UserPermissionNameKey } from '../../constants/referenceData/userPermissionReferenceData';
import { routes } from '../../constants/routes';
import { useUserPermissions } from '../../hooks/permissionsHooks';
import { useUserContext } from '../../contexts/user/state';

interface ProtectedUserRouteProps extends RouteProps {
  permissionNameKey: UserPermissionNameKey;
}

const ProtectedUserRoute: React.FC<ProtectedUserRouteProps> = ({ permissionNameKey, children, ...props }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { user },
  } = useUserContext();
  const superUser = useUserPermissions();

  if (!user) {
    return <p>{translations.protectedUserRoute.error.failedToLoadUser}</p>;
  }

  const userPermissions = getUserPermissions(organizationId, user, superUser);

  return userPermissions[permissionNameKey].allowed || user?.global_admin ? (
    <Route {...props}>{children}</Route>
  ) : (
    <Redirect to={routes.root} />
  );
};

export default ProtectedUserRoute;
