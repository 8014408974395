import { useApolloClient } from '@apollo/client';
import { noop } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RxCollection } from 'rxdb';
import { useRxCollection, useRxDB } from 'rxdb-hooks';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import config from '../config/config';
import { LDFlagNames } from '../constants/launchDarkly';
import { OnOrganizationDataChanged, OnReplicationComplete } from '../graph/queries/general';
import {
  Scalars,
  Subscription,
  SubscriptionOnOrganizationDataChangedArgs,
  SubscriptionOnReplicationCompleteArgs,
} from '../graph/types';
import { CollectionType, ReplicationCollection } from '../services/LocalDatabaseService/collections';
import { offlineIndexBuildsSelectors } from '../services/LocalDatabaseService/databaseUtils';
import { getStorageObjectStore, removeFromStorage } from '../services/LocalDatabaseService/queries/queryUtils';
import {
  getReplications,
  organizationReplication,
  ReplicationType,
} from '../services/LocalDatabaseService/replications';
import { getAccessToken, getJWTToken } from '../util/authUtil';
import { isMobileDevice } from '../util/deviceUtil';
import { isNetworkFetchError, isTimeoutError } from '../util/errorUtil';
import {
  getBulkLoaded,
  getLocalReplications,
  getReplicationDataWithUnsyncedData,
  handleBulkLoad,
  isUuid,
  replicationErrorEvent,
  ReplicationReady,
  replicationsStateChangedEvent,
  setBulkLoaded,
  setLocalReplications,
  useOffline,
  useTriggerReplicationWhenOnline,
  useUnsyncedData,
} from '../util/offline/offlineUtil';
import { OfflineModeActions } from '../util/offline/store/actions';
import { OfflineModeContext } from '../util/offline/store/state';
import { useSubscription } from './ajax/subscription/subscriptionHooks';
import { getConnectionId, useGetConnectionId } from './authHooks';
import { useGetOrganizationIdFromRoute } from './route/routeParameterHooks';
import { useLDFlag } from './useLDHooks';
import { useUpsertOfflineSnapshot } from './ajax/syncErrorHooks/syncErrorHooks';

function getReplicationState(dbProp: RxCollection, auth: string, { pushQuery, pullQuery, onUpdate }: ReplicationType) {
  return dbProp.syncGraphQL({
    url: config.APPSYNC_URL,
    headers: { Authorization: auth },
    push: pushQuery && {
      queryBuilder: pushQuery,
      modifier: (doc: Record<string, any>) => (doc._deleted ? null : doc),
      batchSize: 10000,
    },
    pull: pullQuery && {
      queryBuilder: pullQuery,
      modifier: onUpdate,
    },
    live: true,
    liveInterval: 1000 * 60 * 2,
    deletedFlag: 'hidden',
    retryTime: 1000 * 40,
  });
}

export const useOrganizationReplication = () => {
  const db = useRxDB();
  const { enabled: offlineEnabled, isOnline } = useOffline();
  const { dispatch } = useContext(OfflineModeContext);
  const [replicationDone, setReplicationDone] = useState(false);

  useEffect(() => {
    const replications = getLocalReplications();
    setReplicationDone(replications.organization.initialLoaded);
  }, []);

  useEffect(() => {
    if (!offlineEnabled) {
      return noop;
    }
    let replicationState: RxGraphQLReplicationState<ReplicationCollection>;

    (async () => {
      if (!db.collections.organization) {
        return;
      }
      const jwt = await getJWTToken();

      replicationState = getReplicationState(db.collections.organization, jwt, {
        pullQuery: (doc: any) => {
          return organizationReplication.pullQuery(doc);
        },
      });

      dispatch(
        OfflineModeActions.setOfflineModeReplications({
          organization: {
            state: replicationState,
          },
        })
      );

      let errorTimeout: NodeJS.Timeout;
      // eslint-disable-next-line
      replicationState.error$.subscribe((err) => {
        if (!isNetworkFetchError(err)) {
          const error = JSON.stringify(err.innerErrors);
          // eslint-disable-next-line
          console.error(error ?? err.message ?? err);
          setLocalReplications('organization', error, 'error');
          window.dispatchEvent(replicationErrorEvent);
          clearTimeout(errorTimeout);
        }
      });

      replicationState.initialReplicationComplete$.subscribe((loaded) => {
        if (isOnline && loaded) {
          if (!getLocalReplications().organization.error) {
            setLocalReplications('organization', true, 'initialLoaded');
            window.dispatchEvent(replicationsStateChangedEvent);
            setReplicationDone(true);
          }
        }
      });

      replicationState.active$.subscribe((active) => {
        if (active) {
          errorTimeout = setTimeout(() => {
            setLocalReplications('organization', false, 'error');
            window.dispatchEvent(replicationErrorEvent);
          }, 30 * 1000);
        }
      });
    })();

    return () => void replicationState?.cancel();
  }, [db.collections?.organization, dispatch, offlineEnabled, isOnline]);

  return replicationDone;
};

export const useReplications = () => {
  const db = useRxDB();
  const organizationId = useGetOrganizationIdFromRoute();
  const { enabled: offlineEnabled, isOnline, file, storageDatabase: storageDb } = useOffline();
  const { state, dispatch } = useContext(OfflineModeContext);
  const [tokenExpiry, setTokenExpiry] = useState<number>();
  const [jwt, setJwt] = useState<string>();
  const allLoaded = useIsReadyForOffline();
  const enabledBulkLoad = useLDFlag(LDFlagNames.BulkLoad);
  const runUpsertOfflineSnapshot = useRunUpsertOfflineSnapshot(organizationId);

  useTriggerReplicationWhenOnline();

  const setJwtState = useCallback(async () => {
    const jwt = await getJWTToken();
    setJwt(jwt);
  }, []);

  const setTokenState = useCallback(async () => {
    const accessToken = await getAccessToken();
    setTokenExpiry(accessToken.getExpiration());
  }, []);

  useEffect(() => {
    setTokenState();
    setJwtState();
  }, [setJwtState, setTokenState]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (tokenExpiry && Math.floor(Date.now() / 1000) > tokenExpiry) {
        Object.values(state.replications).forEach((replication) => {
          replication?.state?.cancel();
        });
        setJwtState();
        setTokenState();
      }
    }, 5000);

    return () => clearInterval(timer);
  }, [state.replications, tokenExpiry, setJwtState, setTokenState]);

  useEffect(() => {
    const shouldReturnEarly = !organizationId || !offlineEnabled || !jwt || (enabledBulkLoad && !file);
    if (shouldReturnEarly) {
      return noop;
    }

    const replicationStates: Record<string, RxGraphQLReplicationState<CollectionType>> = {};
    (async () => {
      if (enabledBulkLoad && !getBulkLoaded()) {
        setBulkLoaded();
        await handleBulkLoad(file, db);
      }
      const replications = getReplications(organizationId);
      for (const key in replications) {
        if (!db.collections[key]) {
          continue;
        }
        const replication = replications[key as keyof typeof replications]!;

        const replicationState = getReplicationState(db.collections[key], jwt, replication);

        if (!replicationState) {
          continue;
        }

        dispatch(
          OfflineModeActions.setOfflineModeReplications({
            [key]: {
              state: replicationState,
            },
          })
        );

        let errorTimeout: NodeJS.Timeout;
        // eslint-disable-next-line
        replicationState.error$.subscribe((err) => {
          if (!isNetworkFetchError(err) && !isTimeoutError(err)) {
            const error = JSON.stringify(err.innerErrors);
            // eslint-disable-next-line
            console.error('Error in Collection:', key ?? 'UNKNOWN', error);
            setLocalReplications(key, error, 'error');
            window.dispatchEvent(replicationErrorEvent);
            clearTimeout(errorTimeout);
          }
        });

        replicationState.send$.subscribe(async (record) => {
          // Remove upsert on send
          if (key === 'upsert' && isNaN(record.id)) {
            const rxQuery = db.collections[key].findOne(record.id);
            const doc = await rxQuery.exec();
            if (doc) {
              try {
                await rxQuery.remove();
              } catch (err) {
                // eslint-disable-next-line
                console.error('Could not remove doc (upsert)', err);
              }
            }
          }

          if (isMobileDevice && key === 'note' && isNaN(record.id)) {
            db.collections[key].findOne(record.id).remove();
          }

          removeFromStorage(record, key as CollectionType, storageDb);
        });

        replicationState.initialReplicationComplete$.subscribe((loaded) => {
          if (isOnline && loaded) {
            if (!getLocalReplications()[key]?.error) {
              setLocalReplications(key, true, 'initialLoaded');
              window.dispatchEvent(replicationsStateChangedEvent);
            }
          }
        });

        replicationState.active$.subscribe((active) => {
          if (active) {
            errorTimeout = setTimeout(() => {
              setLocalReplications(key, false, 'error');
              window.dispatchEvent(replicationErrorEvent);
            }, 30 * 1000);
          }
        });

        replicationStates[key] = replicationState;
      }
    })();

    return () => {
      Object.values(replicationStates).forEach((replicationState) => {
        replicationState.cancel();
      });
    };
  }, [db, organizationId, offlineEnabled, isOnline, dispatch, jwt, file, enabledBulkLoad, storageDb]);

  if (allLoaded) {
    runUpsertOfflineSnapshot();
  }
  return allLoaded;
};

export const useOfflineList = <T>(collection: CollectionType) => {
  const rxCollection = useRxCollection<T>(collection);
  return useCallback(() => {
    return rxCollection?.find()?.exec();
  }, [rxCollection]);
};

export const useOfflineListWithField = <T>(collection: CollectionType) => {
  const rxCollection = useRxCollection<T>(collection);
  const list = useCallback(
    (fieldName: string, fieldValue: string) => {
      return rxCollection?.find()?.where(fieldName)?.equals(fieldValue)?.exec();
    },
    [rxCollection]
  );
  if (!rxCollection) {
    return undefined;
  }
  return list;
};

const insertToStorageDB = (collection: CollectionType, upsertObj: any, db?: IDBDatabase) => {
  if (!db) {
    return;
  }

  const upsertUpsert = {
    id: collection + (upsertObj.offline_id ?? upsertObj.id),
    type_name: collection,
    upsert: upsertObj.upsert,
    upsert_offline_id: upsertObj.upsert_offline_id,
    is_new: true,
    updated: Date.now().toString(),
  };

  const objectStore = getStorageObjectStore(db);
  objectStore.add(upsertUpsert);
};

export const useOfflineInsert = <T>(collection: CollectionType) => {
  const { storageDatabase } = useOffline();
  const rxCollection = useRxCollection<T>(collection);

  return useCallback(
    (obj: T) => {
      if (collection !== ReplicationCollection.ContactText) {
        insertToStorageDB(collection, obj, storageDatabase);
      }
      return rxCollection?.insert(obj);
    },
    [rxCollection, collection, storageDatabase]
  );
};

const updateToStorageDB = (collection: CollectionType, upsertObj: any, id: string, db?: IDBDatabase) => {
  if (!db) {
    return;
  }

  const upsertUpsert = {
    id: collection + id,
    type_name: collection,
    upsert: upsertObj.upsert,
    upsert_offline_id: upsertObj.upsert_offline_id,
    is_new: isUuid(id),
    updated: Date.now().toString(),
  };

  const objectStore = getStorageObjectStore(db);
  const request = objectStore.get(collection + id);
  request.onsuccess = (event: any) => {
    if (event.target?.result) {
      objectStore.put(upsertUpsert);
    } else {
      objectStore.add(upsertUpsert);
    }
  };
};

export const useOfflineUpdate = <T>(collection: CollectionType) => {
  const { storageDatabase } = useOffline();
  const rxCollection = useRxCollection<T>(collection);
  return useCallback(
    (id: Scalars['ID'], obj: any) => {
      if (!id) {
        return undefined;
      }
      updateToStorageDB(collection, obj, id, storageDatabase);
      return rxCollection?.findOne(id)?.update({ $set: obj });
    },
    [rxCollection, collection, storageDatabase]
  );
};

export const useOfflineAtomicUpdate = <T>(collection: CollectionType) => {
  const rxCollection = useRxCollection<T>(collection);

  return useCallback(
    async (id: Scalars['ID'], newData: any) => {
      const doc = await rxCollection?.findOne(id).exec();
      if (doc) {
        await doc.atomicUpdate((oldData) => {
          return { ...oldData, ...newData };
        });
      }
    },
    [rxCollection]
  );
};

const deleteFromStorage = (collection: CollectionType, id: string, db?: IDBDatabase) => {
  if (!db) {
    return;
  }

  if (isUuid(id)) {
    const objectStore = getStorageObjectStore(db);
    objectStore.delete(collection + id);
  } else {
    const upsertDeleteUpsert = {
      id: collection + id,
      type_name: collection,
      upsert: {
        id,
        void: true,
      },
      is_new: false,
      updated: Date.now().toString(),
    };
    const objectStore = getStorageObjectStore(db);
    objectStore.add(upsertDeleteUpsert);
  }
};

export const useOfflineDelete = <T>(collection: CollectionType) => {
  const { storageDatabase } = useOffline();
  const rxCollection = useRxCollection<T>(collection);
  return useCallback(
    (id: Scalars['ID']) => {
      if (collection !== ReplicationCollection.ContactText) {
        deleteFromStorage(collection, id, storageDatabase);
      }
      return rxCollection?.findOne(id)?.remove();
    },
    [rxCollection, storageDatabase, collection]
  );
};

export const useReplicationCompletedSubscription = () => {
  const client = useApolloClient();
  const connectionId = useGetConnectionId();
  useSubscription<Pick<Subscription, 'onReplicationComplete'>, SubscriptionOnReplicationCompleteArgs>(
    OnReplicationComplete,
    {
      onSubscriptionData: () => client.reFetchObservableQueries(),
      variables: { id: connectionId },
      skip: !connectionId,
    }
  );
};

export const useTriggerReplicationsOnDataChanges = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { replications },
  } = useContext(OfflineModeContext);
  const { enabled: offlineEnabled } = useOffline();
  const { data } = useSubscription<
    Pick<Subscription, 'onOrganizationDataChanged'>,
    SubscriptionOnOrganizationDataChangedArgs
  >(OnOrganizationDataChanged, {
    variables: { id: organizationId },
    skip: !organizationId || !offlineEnabled,
  });

  useEffect(() => {
    if (data && offlineEnabled) {
      Object.values(replications).forEach((replication) => {
        replication.state?.run();
      });
    }
  }, [data, replications, offlineEnabled]);
};

export const useGetLocalReplications = () => {
  const [replications, setReplications] = useState<Record<string, ReplicationReady>>(getLocalReplications());

  const replicationEventHandler = () => {
    setReplications(getLocalReplications());
  };

  useEffect(() => {
    window.addEventListener('replicationStateChanged', replicationEventHandler);
    window.addEventListener('replicationErrorEvent', replicationEventHandler);

    return () => {
      window.removeEventListener('replicationStateChanged', replicationEventHandler);
      window.removeEventListener('replicationErrorEvent', replicationEventHandler);
    };
  }, []);

  return replications;
};

export const useIsReadyForOffline = () => {
  const db = useRxDB();
  const replications = useGetLocalReplications();
  const replicationStates = useMemo(() => Object.values(replications), [replications]);
  const [count, setCount] = useState(0);
  const allLoaded = useMemo(() => {
    return replicationStates.reduce((i, j) => i && !!j.initialLoaded, true);
  }, [replicationStates]);

  const areIndexesBuilt = useMemo(() => {
    return replicationStates.reduce((i, j) => i && !!j.indexesBuilt, true);
  }, [replicationStates]);

  useEffect(() => {
    if (count === Object.keys(ReplicationCollection).length) {
      window.dispatchEvent(replicationsStateChangedEvent);
    }
  }, [count]);

  useEffect(() => {
    if (allLoaded) {
      Object.values(ReplicationCollection).forEach(async (key) => {
        const indexBuildSelectors = offlineIndexBuildsSelectors[key];
        if (indexBuildSelectors && db.collections[key]) {
          try {
            const selectors = indexBuildSelectors.map(({ selector }) => db.collections[key].find({ selector }).exec());
            await Promise.all(selectors);
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        }
        setCount((count) => count + 1);
        setLocalReplications(key, true, 'indexesBuilt');
      });
    }
  }, [allLoaded, db]);

  return allLoaded && areIndexesBuilt;
};

export const useReplicationError = () => {
  const replications = useGetLocalReplications();
  const replicationStates = useMemo(() => Object.values(replications), [replications]);

  return replicationStates.reduce((i, j) => i || !!j.error, false);
};

export const useRunUpsertOfflineSnapshot = (organizationId: string) => {
  const { unsyncedData, hasUnsyncedData } = useUnsyncedData();
  const replicationError = useReplicationError();
  const [upsertOfflineSnapshot] = useUpsertOfflineSnapshot();
  const [startingLocalStorageReplications, setStartingLocalStorageReplications] = useState(
    JSON.stringify(getReplicationDataWithUnsyncedData(unsyncedData))
  );
  const { isOnline } = useOffline();

  const runUpsertOfflineSnapshot = useCallback(() => {
    const connectionId = getConnectionId();
    const currentReplicationData = JSON.stringify(getReplicationDataWithUnsyncedData(unsyncedData));

    if (connectionId && startingLocalStorageReplications !== currentReplicationData && isOnline) {
      setStartingLocalStorageReplications(currentReplicationData);
      upsertOfflineSnapshot({
        variables: {
          organizationId,
          offlineSnapshot: {
            record: {
              json_data: currentReplicationData,
              connection_id: connectionId,
            },
          },
        },
      });
    }
  }, [organizationId, startingLocalStorageReplications, upsertOfflineSnapshot, unsyncedData, isOnline]);

  if (hasUnsyncedData || replicationError) {
    runUpsertOfflineSnapshot();
  }

  return runUpsertOfflineSnapshot;
};
