import { BillingHistoryProperty, billingHistoryPropertyNames } from '../../constants/propertyNames';
import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { translations } from '../../constants/translations';
import { displayAsDate } from '../../constants/formats';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { OrganizationSubscriptionInvoice } from '../../graph/types';
import React, { useMemo } from 'react';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import SubscriptionCurrencyFormatter from '../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export const useBasicBillingHistoryColumns = (
  countryId?: string | null
): Record<BillingHistoryProperty, CustomColumnType<OrganizationSubscriptionInvoice>> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(
    () => ({
      created: {
        title: translations.billingHistoryPage.columns.date,
        key: billingHistoryPropertyNames.created,
        dataIndex: billingHistoryPropertyNames.created,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.billingHistoryPage.columns.date
        ),
        onFilter: getOnFilterFunctionFor(billingHistoryPropertyNames.created),
        sorter: getDateCompareFunctionFor(billingHistoryPropertyNames.created),
        render: (date: string) => displayAsDate(date, dateFormat),
        width: 120,
      },
      number: {
        title: translations.billingHistoryPage.columns.invoiceNumber,
        key: billingHistoryPropertyNames.number,
        dataIndex: billingHistoryPropertyNames.number,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.billingHistoryPage.columns.invoiceNumber
        ),
        onFilter: getOnFilterFunctionFor(billingHistoryPropertyNames.number),
        sorter: getStringCompareFunctionFor(billingHistoryPropertyNames.number),
        width: 250,
      },
      amount: {
        title: translations.billingHistoryPage.columns.amount,
        key: billingHistoryPropertyNames.amount,
        dataIndex: billingHistoryPropertyNames.amount,
        render: (amount: string) => <SubscriptionCurrencyFormatter total={Number(amount)} countryId={countryId} />,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.billingHistoryPage.columns.amount
        ),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(billingHistoryPropertyNames.amount, true),
        sorter: getNumberCompareFunctionFor(billingHistoryPropertyNames.amount),
        width: 150,
      },
    }),
    [countryId, dateFormat]
  );
};
