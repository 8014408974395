import { ColumnFilterItem, FilterValue, SortOrder } from 'antd/es/table/interface';
import React, { useMemo } from 'react';
import { AbbreviateToWidth } from '../../../../components/AbbreviateToWidth/AbbreviateToWidth';
import { TableCellLink } from '../../../../components/TableLink/TableCellLink';
import { CustomColumnType } from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { PADDING_COMPENSATION_WIDTH_BUTTON } from '../../../../constants/layout';
import { ownerRelationTypeId } from '../../../../constants/referenceData/patientReferenceData';
import { routes } from '../../../../constants/routes';
import { translations } from '../../../../constants/translations';
import { ContactType, ContactTypeDto, Patient, PatientReferenceDataDto } from '../../../../graph/types';
import { useNavigationToRoute, withPatientIdParameter } from '../../../../hooks/route/navigationHooks';
import { generalFilteringAndSortingSettings } from '../../../../util/filterAndSorting';
import { getSpeciesFilterOptionsDto } from '../../../../util/patientFilterAndSorting';
import { mapRefDataName } from '../../../../util/refDataUtil';
import { getContactPatientRelationTags } from '../../../../util/tags';
import { basicPatientColumns, statusProperty } from '../../../Patients/PatientsOverview/patientColumns';
import { getStatus, getStatusTag } from '../../../../util/statusUtils';
import { patientPropertyNames } from '../../../../constants/propertyNames';
import { contactTypeConfigs, ContactTypeNameKey } from '../../../../constants/referenceData/contactReferenceData';

const getContactTypeFilters = (contactTypes: ContactTypeDto[]) => {
  const filters: ColumnFilterItem[] = [];
  contactTypes.forEach((contactType) => {
    filters.push({
      text: contactTypeConfigs[contactType.name_key as ContactTypeNameKey].label,
      value: contactType.type_id,
    });
  });
  return filters;
};

const filterContactPatientRelations = (value: string, patient: Patient, contactName: string) => {
  if (value === ownerRelationTypeId && patient.owner_names?.includes(contactName)) {
    return true;
  }
  if (patient.related_names && patient.related_types) {
    for (const [index, name] of patient.related_names.entries()) {
      if (name === contactName && patient.related_types[index] === value) {
        return true;
      }
    }
  }
  return false;
};

export function useContactPatientColumnsHelper(
  contactName: string,
  filteredValue: Record<string, FilterValue | null>,
  sortOrderMap: { [key: string]: SortOrder | undefined },
  contactTypes?: ContactTypeDto[],
  patients?: Patient[],
  patientReferenceData?: PatientReferenceDataDto
) {
  const { navigateTo } = useNavigationToRoute();

  const speciesFilterOptions: ColumnFilterItem[] = useMemo(
    () => (patientReferenceData ? getSpeciesFilterOptionsDto(patientReferenceData, patients) : []),
    [patientReferenceData, patients]
  );
  const contactTypeFilterOptions: ColumnFilterItem[] = useMemo(
    () => getContactTypeFilters(contactTypes ?? []),
    [contactTypes]
  );

  const optionalColumns: CustomColumnType<Patient>[] = useMemo(
    () => [
      {
        ...basicPatientColumns.name_2,
        width: 200,
        filteredValue: filteredValue[patientPropertyNames.name_2] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name_2],
        key: patientPropertyNames.name_2,
      },
      {
        ...basicPatientColumns.name_3,
        width: 200,
        filteredValue: filteredValue[patientPropertyNames.name_3] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name_3],
        key: patientPropertyNames.name_3,
      },
      {
        ...basicPatientColumns.number,
        filteredValue: filteredValue[patientPropertyNames.number] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.number],
        key: patientPropertyNames.number,
        width: 100,
      },
      {
        ...basicPatientColumns.species_name,
        filteredValue: filteredValue[patientPropertyNames.species_name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.species_name],
        key: patientPropertyNames.species_name,
        render: (p: Patient) =>
          mapRefDataName({ id: p.species_id || '', name: p.species_name || '' }, patientReferenceData?.species),
        filters: speciesFilterOptions,
        width: 100,
      },
      {
        ...basicPatientColumns.owner_names,
        filteredValue: filteredValue[patientPropertyNames.owner_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.owner_names],
        key: patientPropertyNames.owner_names,
        width: 300,
      },
      {
        filteredValue: filteredValue[patientPropertyNames.related_names] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.related_names],
        key: patientPropertyNames.related_names,
        title: translations.viewContactPage.patients.patientRelation,
        render: (patient: Patient) =>
          getContactPatientRelationTags(
            patient,
            contactTypes?.map((ct) => ({ ...ct } as ContactType)) ?? [],
            contactName
          ),
        filters: contactTypeFilterOptions,
        width: 200,
        ...generalFilteringAndSortingSettings,
        onFilter: (value, record: Patient) => filterContactPatientRelations(value as string, record, contactName),
      },
      {
        ...basicPatientColumns.status,
        filteredValue: filteredValue[statusProperty] ?? null,
        sortOrder: sortOrderMap[statusProperty],
        key: statusProperty,
        render: (record: Patient) => <>{getStatusTag(getStatus(record))}</>,
        width: 90,
      },
    ],
    [
      contactName,
      contactTypeFilterOptions,
      contactTypes,
      patientReferenceData,
      speciesFilterOptions,
      sortOrderMap,
      filteredValue,
    ]
  );

  return {
    optionalColumns,
    getAllContactPatientColumns: (optionalColumns: CustomColumnType<Patient>[]) => [
      {
        ...basicPatientColumns.name,
        width: 200,
        filteredValue: filteredValue[patientPropertyNames.name] ?? null,
        sortOrder: sortOrderMap[patientPropertyNames.name],
        render: (text: string, patient: Patient) => (
          <TableCellLink onClick={() => navigateTo(routes.viewPatient, withPatientIdParameter(patient.id))}>
            <AbbreviateToWidth width={200} text={text} paddingCompensation={PADDING_COMPENSATION_WIDTH_BUTTON} />
          </TableCellLink>
        ),
        ellipsis: true,
      },
      ...optionalColumns,
    ],
  };
}
