import { Form, Input, Popconfirm, Radio, Select, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId, useGetPracticeWithSettings } from '../../../hooks/ajax/practice/practiceHooks';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { getSettingUpsert, useUpsertSetting } from '../../../hooks/ajax/setting/settingHooks';
import { PracticeSettingsId, translations } from '../../../constants/translations';
import { Loading } from '../../../components/Loading/Loading';
import { SaveAndResetButton } from '../../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { getRequiredRule } from '../../../util/forms';
import styled from 'styled-components';
import {
  CurrencyAndDateFormValues,
  useCurrencyDefaultOptions,
  useCurrencyAndDateSetting,
} from '../../../hooks/currencyAndDateHooks';
import BaseCurrencyFormatter from '../../../components/CurrencyFormatter/BaseCurrencyFormatter/BaseCurrencyFormatter';
import { dateFormats, displayTodayAsDate } from '../../../constants/formats';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { OffsetTitle } from '../../../globalStyles.style';

const Container = styled('div')`
  padding: 16px 24px;
`;

const PreviewContainer = styled('div')`
  margin-top: 28px;
`;

const PreviewField = styled('div')`
  margin-top: 4px;
  font-size: 14px;
`;

export const SettingsTable: React.FC = () => {
  const [form] = Form.useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { practiceLoading, refetch } = useGetPracticeWithSettings(organizationId, practiceId);
  const [upsertSetting] = useMutationWithMessages(useUpsertSetting);
  const { currencyDefaultOptions, currencyDefaultsLoading, currencyDefaults } = useCurrencyDefaultOptions();
  const {
    practiceCurrencySettings,
    reportCurrencySymbolSetting,
    reportCurrencyIsPrefixSetting,
    dateFormatSetting,
    practiceRememberDateSetting,
    currencySettingsLocked,
    initialValues,
  } = useCurrencyAndDateSetting();
  const {
    localeData: { dateFormat: defaultDateFormat },
  } = useUserLocaleData();

  const [displayValues, setDisplayValues] = useState<{
    currencySymbol: string;
    groupingSymbol: string;
    decimalSymbol: string;
    isPrefix: boolean;
    negativeSymbol: string;
    decimalDigits: number;
    dateFormat: string;
  }>({
    currencySymbol: '$',
    groupingSymbol: '.',
    decimalSymbol: ',',
    isPrefix: true,
    negativeSymbol: '-',
    decimalDigits: 2,
    dateFormat: defaultDateFormat,
  });

  const dateFormatOptions = dateFormats.map((format) => ({ label: format.display, value: format.format }));

  const updateDisplay = useCallback(() => {
    const currencySymbol = form.getFieldValue('currencySymbol');
    const groupingSymbol = form.getFieldValue('groupingSymbol');
    const decimalSymbol = form.getFieldValue('decimalSymbol');
    const isPrefix = form.getFieldValue('isPrefix');
    const negativeSymbol = form.getFieldValue('negativeSymbol');
    const decimalDigits = form.getFieldValue('decimalDigits');
    const dateFormat = form.getFieldValue('dateFormat');
    if (decimalSymbol !== groupingSymbol) {
      setDisplayValues({
        currencySymbol,
        groupingSymbol,
        decimalSymbol,
        isPrefix,
        negativeSymbol,
        decimalDigits: Number(decimalDigits),
        dateFormat,
      });
    }
  }, [form]);

  const [loading, setLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false);
  useEffect(() => {
    if (!practiceLoading && !currencyDefaultsLoading) {
      updateDisplay();
    }
  }, [practiceLoading, currencyDefaultsLoading, updateDisplay]);

  if (practiceLoading || currencyDefaultsLoading) {
    return <Loading />;
  }
  const onChange = () => {
    setHasChanges(true);

    updateDisplay();
  };

  const handleReset = () => {
    form.resetFields();
    setHasChanges(false);
    updateDisplay();
  };

  const onCurrencyChange = (value: string) => {
    setHasChanges(true);
    const practiceCurrencyDefaults = currencyDefaults?.find((currency) => currency.code === value);
    form.setFieldsValue({
      currencySymbol: practiceCurrencyDefaults?.symbol,
      decimalDigits: practiceCurrencyDefaults?.decimal_digits,
      decimalSymbol: practiceCurrencyDefaults?.decimal_symbol,
      groupingSymbol: practiceCurrencyDefaults?.grouping_symbol,
      negativeSymbol: practiceCurrencyDefaults?.negative_symbol,
    });
    updateDisplay();
  };

  const handleFinish = async ({
    locked,
    currencyCode,
    currencySymbol,
    isPrefix,
    decimalDigits,
    decimalSymbol,
    groupingSymbol,
    negativeSymbol,
    dateFormat,
    rememberDate,
  }: CurrencyAndDateFormValues) => {
    setLoading(true);

    if (!currencySettingsLocked) {
      const currencySettingsValue = {
        currencyCode,
        currencySymbol,
        isPrefix,
        decimalDigits,
        decimalSymbol,
        groupingSymbol,
        negativeSymbol,
        locked,
      };

      await upsertSetting({
        options: {
          variables: {
            organizationId,
            setting: getSettingUpsert(
              JSON.stringify(currencySettingsValue),
              PracticeSettingsId.CurrencySettings,
              practiceCurrencySettings?.id,
              practiceId
            ),
          },
        },
      });

      await upsertSetting({
        options: {
          variables: {
            organizationId,
            setting: getSettingUpsert(
              currencySymbol,
              PracticeSettingsId.CurrencyDisplay,
              reportCurrencySymbolSetting?.id,
              practiceId
            ),
          },
        },
      });

      await upsertSetting({
        options: {
          variables: {
            organizationId,
            setting: getSettingUpsert(
              isPrefix ? '1' : '0',
              PracticeSettingsId.CurrencyIsPrefix,
              reportCurrencyIsPrefixSetting?.id,
              practiceId
            ),
          },
        },
      });
    }

    await upsertSetting({
      options: {
        variables: {
          organizationId,
          setting: getSettingUpsert(dateFormat, PracticeSettingsId.LocaleDate, dateFormatSetting?.id, practiceId),
        },
      },
    });

    await upsertSetting({
      options: {
        variables: {
          organizationId,
          setting: getSettingUpsert(
            rememberDate,
            PracticeSettingsId.LocaleRememberDate,
            practiceRememberDateSetting?.id,
            practiceId
          ),
        },
      },
    });

    setLoading(false);
    setHasChanges(false);
    refetch();
  };

  const handleConfirm = () => {
    if (!currencySettingsLocked) {
      form.setFieldsValue({ locked: true });
      form.submit();
    }
    setShowConfirmMessage(false);
  };

  const getConfirmSwitch = () => {
    return (
      <Form.Item
        label={translations.currencySettingsPage.confirm.title}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 7 }}
        name='locked'
      >
        <Popconfirm
          title={
            currencySettingsLocked
              ? translations.currencySettingsPage.confirm.lockedMessage
              : translations.currencySettingsPage.confirm.areYouSureMessage
          }
          open={showConfirmMessage}
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmMessage(false)}
        >
          <Switch
            disabled={currencySettingsLocked}
            checked={currencySettingsLocked ?? false}
            onClick={() => setShowConfirmMessage(true)}
          />
        </Popconfirm>
      </Form.Item>
    );
  };

  const CurrencyPreview = () => {
    return (
      <PreviewContainer>
        <PreviewField>
          {translations.currencySettingsPage.preview.positiveDisplay}
          <BaseCurrencyFormatter total={'1000.50'} currencySettings={displayValues} chartAxis={false} isPriceValue />
        </PreviewField>
        <PreviewField>
          {translations.currencySettingsPage.preview.negativeDisplay}
          <BaseCurrencyFormatter total={'-1000.50'} currencySettings={displayValues} chartAxis={false} isPriceValue />
        </PreviewField>
        <PreviewField>
          {translations.currencySettingsPage.preview.dateDisplay}
          {displayTodayAsDate(displayValues.dateFormat)}
        </PreviewField>
      </PreviewContainer>
    );
  };

  return (
    <SaveSpinnerAndNavigationWarning isSaving={loading} showNavigationWarning={hasChanges}>
      <Form<CurrencyAndDateFormValues>
        form={form}
        wrapperCol={{ span: 7 }}
        labelCol={{ span: 5 }}
        initialValues={initialValues}
        onChange={onChange}
        onFinish={handleFinish}
        autoComplete='off'
      >
        <Container>
          <p>{translations.currencySettingsPage.note1}</p>
          <p>{translations.currencySettingsPage.note2}</p>
          <p>{translations.currencySettingsPage.note3}</p>

          <OffsetTitle offset={5} level={4}>
            {translations.currencySettingsPage.headers.currency}
          </OffsetTitle>
          {getConfirmSwitch()}

          <Form.Item
            label={translations.currencySettingsPage.currency}
            name='currencyCode'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 7 }}
          >
            <Select
              {...searchableSelectParams}
              disabled={currencySettingsLocked}
              options={currencyDefaultOptions}
              onChange={onCurrencyChange}
            />
          </Form.Item>

          <Form.Item
            name='currencySymbol'
            label={translations.currencySettingsPage.currencySymbol}
            rules={[getRequiredRule(translations.currencySettingsPage.currencySymbol)]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 3 }}
          >
            <Input disabled={currencySettingsLocked} maxLength={10} />
          </Form.Item>

          <Form.Item name='isPrefix' label={translations.currencySettingsPage.currencySymbolIsPrefix}>
            <Radio.Group buttonStyle='solid' disabled={currencySettingsLocked}>
              <Radio.Button value={Boolean(true)}>{translations.currencySettingsPage.prefix}</Radio.Button>
              <Radio.Button value={Boolean(false)}>{translations.currencySettingsPage.suffix}</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name='decimalSymbol'
            label={translations.currencySettingsPage.decimalSymbol}
            rules={[
              getRequiredRule(translations.currencySettingsPage.decimalSymbol),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('groupingSymbol') !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translations.currencySettingsPage.decimalAndGroupingSymbolRule));
                },
              }),
            ]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 3 }}
          >
            <Input disabled={currencySettingsLocked} maxLength={10} />
          </Form.Item>

          <Form.Item
            name='decimalDigits'
            label={translations.currencySettingsPage.decimalDigits}
            rules={[getRequiredRule(translations.currencySettingsPage.decimalDigits)]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 3 }}
          >
            <Input disabled readOnly min={0} max={4} />
          </Form.Item>

          <Form.Item
            name='groupingSymbol'
            label={translations.currencySettingsPage.groupingSymbol}
            rules={[
              getRequiredRule(translations.currencySettingsPage.groupingSymbol),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('decimalSymbol') !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translations.currencySettingsPage.decimalAndGroupingSymbolRule));
                },
              }),
            ]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 3 }}
          >
            <Input disabled={currencySettingsLocked} maxLength={10} />
          </Form.Item>

          <Form.Item
            name='negativeSymbol'
            label={translations.currencySettingsPage.negativeSymbol}
            rules={[getRequiredRule(translations.currencySettingsPage.negativeSymbol)]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 3 }}
          >
            <Input disabled={currencySettingsLocked} maxLength={10} />
          </Form.Item>

          <OffsetTitle offset={5} level={4}>
            {translations.currencySettingsPage.headers.date}
          </OffsetTitle>
          <Form.Item
            label={translations.currencySettingsPage.rememberDate.title}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 7 }}
            name='rememberDate'
            valuePropName='checked'
            tooltip={{ title: translations.currencySettingsPage.rememberDate.tooltip }}
          >
            <Switch onClick={onChange} />
          </Form.Item>

          <Form.Item
            label={translations.currencySettingsPage.date}
            name='dateFormat'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 7 }}
          >
            <Select options={dateFormatOptions} onChange={onChange} />
          </Form.Item>

          <Form.Item label={translations.currencySettingsPage.preview.title} name='currencyPreview'>
            <CurrencyPreview />
          </Form.Item>

          {hasChanges && <SaveAndResetButton onReset={handleReset} offset={5} />}
        </Container>
      </Form>
    </SaveSpinnerAndNavigationWarning>
  );
};
