import EventEmitter from 'eventemitter3';

const event = new EventEmitter();

export default event;

export const getEventListeners = <T>(eventName: string) => ({
  emit: (arg: T) => event.emit(eventName, arg),
  on: (fn: (arg: T) => void) => event.on(eventName, fn),
  off: () => event.off(eventName),
  once: (fn: (arg: T) => void) => event.once(eventName, fn),
});
