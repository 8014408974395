import { Auth } from 'aws-amplify';

export const getJWTToken = async () => {
  const currentSession = await getAccessToken();
  return currentSession.getJwtToken();
};

export const getAccessToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession.getAccessToken();
};

export const getRefreshToken = async (setterFn: () => void) => {
  const user = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  user.refreshSession(currentSession.getRefreshToken(), setterFn);
};
