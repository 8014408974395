import React, { useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { FlexContainer } from '../../../globalStyles.style';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { PopconfirmProps } from 'antd';
import { Practice } from '../../../graph/types';
import { useGetStripeEnabledForPractice } from '../../Registration/RegistrationStripe/StripeUtils';

export interface AddOnCardStripeProps {
  enabled: boolean;
  onConfigureClick: () => void;
  onEnable: () => void;
  practice: Practice;
}

const AddOnCardStripe: React.FC<AddOnCardStripeProps> = ({ onConfigureClick, onEnable, practice, enabled = false }) => {
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);
  const addOnEnabled = useGetStripeEnabledForPractice(practice);

  const handleEnableButtonClick = () => {
    if (!enabled) {
      onEnable();
    } else {
      setVisiblePopconfirm(true);
    }
  };

  const enableButtonProps = {
    label: translations.addOns.cards.stripe.enable,
    onClick: handleEnableButtonClick,
    isChecked: enabled,
    isToggle: true,
    popconfirm: {
      title: translations.addOns.cards.stripe.cannotDisableMessage,
      okText: translations.shared.okButtonText,
      placement: 'right',
      open: visiblePopconfirm,
      showCancel: false,
      onConfirm: () => {
        setVisiblePopconfirm(false);
      },
    } as PopconfirmProps,
    disabled: !addOnEnabled,
  };

  const configureButtonProps = enabled
    ? {
        label: translations.addOns.cards.stripe.configure,
        onClick: onConfigureClick,
        disabled: !addOnEnabled,
      }
    : undefined;

  return (
    <SettingsCard
      title={translations.addOns.cards.stripe.cardTitle}
      leftButton={enableButtonProps}
      rightButton={configureButtonProps}
      displayTooltip={!addOnEnabled}
      tooltipMessage={translations.registration.stripe.unavailableMessage}
    >
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.stripe.text}</TextWithMargin>
      </FlexContainer>
    </SettingsCard>
  );
};

export default AddOnCardStripe;
