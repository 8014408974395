import * as React from 'react';
import { ContextProviderFC } from '../../../util/ContextProvider';
import { SaveSpinner } from '../../SaveSpinner/SaveSpinner';
import { TextConfirmModal } from '../../TextConfirmModal/TextConfirmModal';
import { UtilsAction } from './actions';
import { initialState, utilsReducer, UtilsState } from './reducer';
import { selectSaveSpinnerProps, selectTextConfirmModalProps } from './selectors';

export const UtilsContext = React.createContext<{
  state: UtilsState;
  dispatch: React.Dispatch<UtilsAction>;
}>({} as any);

export const UtilsContextProvider: ContextProviderFC = (props) => {
  const fullInitialState = {
    ...initialState,
  };
  const [state, dispatch] = React.useReducer(utilsReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return (
    <UtilsContext.Provider value={value}>
      <SaveSpinner {...selectSaveSpinnerProps(state)}>
        {props.children}
        <TextConfirmModal {...selectTextConfirmModalProps(state)} dispatchUtils={dispatch} />
      </SaveSpinner>
    </UtilsContext.Provider>
  );
};
