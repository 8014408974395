export enum LDFlagNames {
  ActiveReports = 'FE_ActiveReports',
  Addons = 'Page_AddOns',
  AddonsOffline = 'Page_AddOns_Offline',
  AddonsPaymentPortal = 'Page_AddOns_PaymentPortal',
  AddonsAntechLab = 'Page_AddOns_Antech',
  AddonsVetConnectLab = 'Page_AddOns_VetConnect',
  Dashboard = 'Page_Dashboard',
  Test = 'test-flag',
  ToggleBundle = 'Toggle_BundlePrompt',
  ThirdPartyData = 'Page_ThirdPartyData',
  LabManagement = 'Page_LabManagement',
  EMR = 'FE_EMR',
  BulkLoad = 'Repl_Zip',
  LabLink = 'Tab_LabLink',
  StripeSubscriptions = 'Stripe_Subscriptions',
  StripePracticePayment = 'Stripe_PracticePayment',
  AddOnsCardConnect = 'AddOns_CardConnect',
  AlertSystem = 'FE_AlertSystem',
  AddOns_Stripe = 'AddOns_Stripe',
  DefaultVaccineInfo = 'FE_DefaultVaccineInfo',
  Hisa = 'FE_HISA',
  QuickBooks = 'FE_Quickbooks',
  PageSupport = 'Page_Support',
  Financials = 'Page_Financials',
  DepositRun = 'FE_DepositRun',
  Coupon = 'FE_Coupon',
  Markup = 'FE_CostPlusMU',
  BundlePackage = 'FE_Package',
  PatientHxV2 = 'FE_PatientHxv2',
  PrescriptionRefills = 'FE_RxRefills',
  PrescriptionLabels = 'FE_RxLabel',
  QuickNotes = 'FE_QuickNotes',
  ARPatientVaxReport = 'ARJS_PatientVax',
  ARPatientHistoryReport = 'ARJS_PatientHx',
  ActiveReportsInvoice = 'ARJS_Invoice',
  ActiveReportsContactReceipt = 'ARJS_ContactReceipt',
  ActiveReportsSubscriptionInvoice = 'ARJS_SubscriptionInvoice',
  ActiveReportsReminderRun = 'ARJS_ReminderRun',
  DataDogRUM = 'DataDog_RUM',
  ContactCommunicationLog = 'Contact_CommunicationLog',
}
