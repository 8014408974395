import { translations } from '../../constants/translations';
import React, { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { isMatch } from '../../util/objectComparisons';
import { useForm } from 'antd/lib/form/Form';
import { SaveSpinnerAndNavigationWarning } from '../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useGetLedgerNote, useUpsertLedger } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import dayjs from 'dayjs';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { LedgerUpsertGenerator } from '../../classes/upsertGenerators/LedgerUpsertGenerator';
import { LedgerEntryConfig } from '../../constants/referenceData/ledgerReferenceData';
import { Ledger } from '../../graph/types';
import { LedgerChargeForm, LedgerChargeFormFields } from './LedgerChargeForm';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { Loading } from '../Loading/Loading';

interface LedgerChargeProps {
  onClose: () => void;
  onSuccess?: () => void;
  successMessage: string;
  title: string;
  editingLedger?: Ledger;
  contactId: string;
  ledgerType: LedgerEntryConfig;
}

export const LedgerChargeModal: React.FC<LedgerChargeProps> = ({
  onClose,
  onSuccess,
  successMessage,
  title,
  contactId,
  editingLedger,
  ledgerType,
}) => {
  const [form] = useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);
  const [upsertLedger] = useMutationWithMessages(useUpsertLedger);
  const { ledgerNote, ledgerNoteLoading } = useGetLedgerNote({ ledgerId: editingLedger?.id || '', organizationId });

  const initialData: LedgerChargeFormFields = {
    date: dayjs(editingLedger?.financial_date),
    amount: editingLedger ? Math.abs(Number(editingLedger.total)) : undefined,
    note: ledgerNote?.value,
  };

  const mapFormFieldsToLedgerUpsert = (formFields: LedgerChargeFormFields) => {
    return editingLedger
      ? LedgerUpsertGenerator.getExistingLedgerChargeUpsert(formFields, editingLedger.id)
      : LedgerUpsertGenerator.getNewLedgerChargeUpsert(formFields, {
          contactId,
          practiceId,
          ledgerType,
        });
  };

  const onFinish = async (values: LedgerChargeFormFields) => {
    setIsSaving(true);
    const chargeUpsert = mapFormFieldsToLedgerUpsert(values);
    await upsertLedger({
      options: {
        variables: {
          organizationId,
          ledger: chargeUpsert,
        },
      },
      successMessage,
      onSuccess: () => {
        setUnsavedData(false);
        if (onSuccess) {
          onSuccess();
        }
        setIsSaving(false);
        onClose();
      },
    });
    setIsSaving(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    setUnsavedData(!isMatch(null, allValues));
  };

  return (
    <ModalWithCloseConfirm
      title={title}
      onCancel={onClose}
      onOk={() => form.submit()}
      open
      isSaving={isSaving}
      changesMade={unsavedData}
      warningMessageOnRouteChange={translations.shared.getUnsavedDataNavigationWarning(ledgerType.text)}
    >
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving}
        showNavigationWarning={unsavedData}
        warningMessage={translations.shared.getUnsavedDataNavigationWarning(ledgerType.text)}
      >
        {ledgerNoteLoading ? (
          <Loading />
        ) : (
          <LedgerChargeForm
            onFinish={onFinish}
            handleValueChange={handleValueChange}
            initialData={initialData}
            form={form}
          />
        )}
      </SaveSpinnerAndNavigationWarning>
    </ModalWithCloseConfirm>
  );
};
