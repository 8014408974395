import React, { memo, useMemo } from 'react';
import { Tabs, Tooltip } from 'antd';
import { useGetRemindersBatchList } from '../../../hooks/ajax/reminders/reminderHooks';
import TabsWithRouting, { TabsWithRoutingProps } from '../../../components/TabsWithRouting/TabsWithRouting';
import { routes } from '../../../constants/routes';
import { ReminderBatch } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { displayAsDate } from '../../../constants/formats';
import RemindersBatchTable from './RemindersBatchTable';
import dayjs from 'dayjs';
import { SelectedContact } from '../types';
import RemindersTable from './RemindersTable';
import DeleteBatch from './DeleteBatch';
import { useUserLocaleData } from '../../../hooks/useUserLocale';

type Props = {
  organizationId: string;
  afterDate: dayjs.Dayjs;
  beforeDate: dayjs.Dayjs;
  selectedRows: SelectedContact[];
  onSelect: (selection: SelectedContact[]) => void;
  onDeleteBatch: (id: string) => void;
};

interface BatchTab {
  label: string;
  reminderBatch?: ReminderBatch;
  route: string;
}

const DEFAULT_TAB: BatchTab = {
  label: translations.reminderRuns.tabs.reminders,
  route: routes.reminderRuns,
};

const RemindersTableContainer = ({
  organizationId,
  afterDate,
  beforeDate,
  selectedRows,
  onSelect,
  onDeleteBatch,
}: Props) => {
  const { navigateTo } = useNavigationToRoute();
  const { data: remindersBatchList = [] } = useGetRemindersBatchList(organizationId);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const tabs: BatchTab[] = useMemo(
    () => [
      DEFAULT_TAB,
      ...[...remindersBatchList]
        .sort((a, b) => Number(new Date(b?.date_sent ?? '')) - Number(new Date(a?.date_sent ?? '')))
        .map((reminder) => ({
          label: reminder?.number?.replace('R', `${translations.reminderRuns.tabs.run} `),
          route: `${routes.reminderRuns}/${reminder?.id}`,
          reminderBatch: reminder,
        })),
    ],
    [remindersBatchList]
  );

  const tabsObj = useMemo(() => {
    const result: { [key: string]: ReminderBatch | undefined } = {};

    tabs.forEach((tab) => {
      result[tab?.reminderBatch?.id ?? ''] = tab.reminderBatch;
    });
    return result;
  }, [tabs]);

  const routePerTabKey: TabsWithRoutingProps['routePerTabKey'] = useMemo(() => {
    const routeMap: TabsWithRoutingProps['routePerTabKey'] = {};

    tabs.forEach((reminder) => {
      routeMap[reminder?.reminderBatch?.id ?? reminder?.route] = reminder.route;
    });
    return routeMap;
  }, [tabs]);

  const handleTabClick = (key: string) => {
    navigateTo(routePerTabKey[key] as string, { id: key }, { tab: tabsObj[key] });
  };

  return (
    <TabsWithRouting
      activeKey={DEFAULT_TAB.route}
      routePerTabKey={routePerTabKey}
      type='card'
      hideAdd
      forcedRouteIfNoTabSelected={routes.reminderRuns}
      onTabClick={handleTabClick}
    >
      {tabs.map((tab) => (
        <Tabs.TabPane
          key={tab?.reminderBatch?.id ?? tab?.route}
          tab={
            tab.reminderBatch?.date_sent ? (
              <Tooltip
                title={translations.reminderRuns.tabs.tooltip(
                  tab.label,
                  displayAsDate(tab.reminderBatch?.date_sent ?? '', dateFormat)
                )}
              >
                <span>{tab.label}</span>
              </Tooltip>
            ) : (
              tab.label
            )
          }
        >
          {tab.route === DEFAULT_TAB.route ? (
            <RemindersTable
              organizationId={organizationId}
              afterDate={afterDate}
              beforeDate={beforeDate}
              selectedRows={selectedRows}
              onSelect={onSelect}
            />
          ) : (
            <RemindersBatchTable
              deleteButton={<DeleteBatch onSubmit={() => onDeleteBatch(tab.reminderBatch?.id ?? '')} />}
              organizationId={organizationId}
              selectedRows={selectedRows}
              onSelect={onSelect}
              reminderBatchId={tab?.reminderBatch?.id}
            />
          )}
        </Tabs.TabPane>
      ))}
    </TabsWithRouting>
  );
};

export default memo(RemindersTableContainer);
