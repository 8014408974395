import React, { useMemo, useState } from 'react';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import { useBasicBillingHistoryColumns } from './billingHistoryColumns';
import { translations } from '../../constants/translations';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { AdditionalOrganizationField } from '../../graph/queries/organizations';
import { Loading } from '../../components/Loading/Loading';
import { OrganizationSubscriptionInvoice, QueryRptGetSubscriptionInvoiceArgs } from '../../graph/types';
import styled from 'styled-components';
import { printOrganizationSubscriptionInvoiceReport } from './billingHistoryUtil';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { useLocation } from 'react-router-dom';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { LinkMixin } from '../../globalStyles.style';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Reports, getReportData } from '../../util/reportUtils';
import { GetSubscriptionInvoice } from '../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';

const LinkText = styled.span`
  ${LinkMixin}
  cursor: pointer;
`;

interface BillingHistoryLocationState {
  hasBackNavigation?: boolean;
}

const BillingHistory: React.FC = () => {
  const location = useLocation<BillingHistoryLocationState>();
  const { navigateBack } = useNavigationToRoute();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.BillingHistory
  );
  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);
  const basicBillingHistoryColumns = useBasicBillingHistoryColumns(countryId);

  const useActiveReports = useLDFlag(LDFlagNames.ActiveReportsSubscriptionInvoice);
  const navigateToReportViewer = useNavigateToReportViewer();

  const [loading, setLoading] = useState(false);

  if ((!organization && organizationLoading) || loading) {
    return <Loading />;
  }

  if (!organization?.subscription?.invoice) {
    return <p>{translations.billingHistoryPage.errorMessage.organizationLoad}</p>;
  }

  const handleOnDownload = async (record: OrganizationSubscriptionInvoice) => {
    setLoading(true);
    if (useActiveReports) {
      const reportData = await getReportData<'rptGetSubscriptionInvoice', QueryRptGetSubscriptionInvoiceArgs>(
        GetSubscriptionInvoice,
        {
          organizationId,
          practiceId,
          reportParameters: {
            electronic_payment_id: record.id,
            start_date: '',
            end_date: '',
          },
        }
      );
      navigateToReportViewer({
        reportName: Reports.SubscriptionInvoice,
        data: reportData,
        nestedHeader: true,
        showSettingsSidebar: true,
      });
    } else {
      await printOrganizationSubscriptionInvoiceReport({
        electronicPaymentId: record.id,
        request: {
          practiceId,
          organizationId,
          fileName: `Subscription_Invoice_${record.number}.pdf`,
          returnPreSignedUrl: 'true',
        },
      });
    }
    setLoading(false);
  };

  const columns: CustomColumnType<OrganizationSubscriptionInvoice>[] = [
    ...Object.values(basicBillingHistoryColumns),
    {
      title: translations.billingHistoryPage.columns.actions,
      key: 'actions',
      render: (record: OrganizationSubscriptionInvoice) =>
        getRenderedBillingHistoryAction(() => handleOnDownload(record)),
    },
  ];

  return (
    <div>
      <StyledPageHeader
        title={translations.billingHistoryPage.title}
        {...(!!location?.state?.hasBackNavigation ? { onBack: navigateBack } : {})}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.BillingHistory}
        dataSource={organization.subscription!.invoice}
        columns={columns}
        rowKey={'id'}
      />
    </div>
  );
};

function getRenderedBillingHistoryAction(onClick: () => void) {
  return <LinkText onClick={onClick}>{translations.billingHistoryPage.actions.download}</LinkText>;
}

export default BillingHistory;
