import { gql } from '@apollo/client';
import {
  FinancialGeneralLedgerFields,
  FinancialTaxTypeFields,
  GeneralLedgerTypeFields,
  PaymentDeviceTypeFields,
  PaymentTypeFields,
} from './fragments/organizationReferenceDataFragments';
import { ServiceBundleFields } from './fragments/serviceBundleFragments';

export const OrganizationSubscriptionDetailFields = gql`
  fragment OrganizationSubscriptionDetailFields on OrganizationSubscriptionDetail {
    id
    type_id
    type_name_key
    level_id
    level_name_key
    qty
    qty_used
    qty_available
    user_type
    users {
      id
      user_id
      name
    }
  }
`;

export const OrganizationSubscriptionInvoiceFields = gql`
  fragment OrganizationSubscriptionInvoiceFields on OrganizationSubscriptionInvoice {
    id
    number
    amount
    subscription_id
    subscription_transaction_type_id
    created
  }
`;

export const OrganizationSubscriptionInvoiceDetailFields = gql`
  fragment OrganizationSubscriptionInvoiceDetailFields on OrganizationSubscriptionInvoiceDetail {
    id
    payment_id
    name_key
    quantity
    unit_price
    total
  }
`;

export const BillingAddressFields = gql`
  fragment BillingAddressFields on BillingAddress {
    address_1
    address_2
    address_3
    city
    country_prov_state_id
    country_id
    postal_zip
    country_name
    country_prov_state_name
    phone
    email
  }
`;

export const OrganizationSubscriptionFields = gql`
  fragment OrganizationSubscriptionFields on OrganizationSubscription {
    id
    start_date
    frequency
    last_billed_date
    next_bill_date
    failed
    failed_message
    card_type_id
    name_on_card
    display_number
    card_token
    expiry_month
    expiry_year
    problem
    processor_type_id
    billing_address {
      ...BillingAddressFields
    }
    detail {
      ...OrganizationSubscriptionDetailFields
    }
    invoice {
      ...OrganizationSubscriptionInvoiceFields
      detail {
        ...OrganizationSubscriptionInvoiceDetailFields
      }
    }
  }
  ${BillingAddressFields}
  ${OrganizationSubscriptionDetailFields}
  ${OrganizationSubscriptionInvoiceFields}
  ${OrganizationSubscriptionInvoiceDetailFields}
`;

export const OrganizationAddOnFields = gql`
  fragment OrganizationAddOnFields on OrganizationSubscriptionAddOn {
    id
    level_id
    enabled
    cost
  }
`;

export const OrganizationSubscriptionResultFields = gql`
  fragment OrganizationSubscriptionResultFields on OrganizationSubscriptionResult {
    error_message
    error_name_key
    problem_name_key
    charge {
      cost
      level_name_key
      level_id
      total
      newQty
      proRatedPercentage
      proRatedTotal
      currentQty
    }
    monthlyTax {
      amount
      name_key
      percentage
    }
    monthlyTotal
    cardChargeTax {
      amount
      name_key
      percentage
    }
    cardCharge
    discount
    organization {
      id
      subscription {
        addon {
          ...OrganizationAddOnFields
        }
        ...OrganizationSubscriptionFields
      }
    }
  }
  ${OrganizationAddOnFields}
  ${OrganizationSubscriptionFields}
`;

export const StaticImageFields = gql`
  fragment StaticImageFields on StaticImage {
    id
    name
    pointer
    size
    created
    created_user_id
    created_user_name
  }
`;

export const AddressFields = gql`
  fragment AddressFields on Address {
    id
    address_type_id
    address_type_other
    address_type_name_key
    address_1
    address_2
    address_3
    city
    county
    country_id
    country_name_key
    country_prov_state_id
    country_prov_state_name_key
    postal_zip
    primary
  }
`;

export const CardConnectFields = gql`
  fragment CardConnectFields on CardConnectSettings {
    merchantId
    userId
  }
`;

export const CardConnectManualFields = gql`
  fragment CardConnectManualFields on CardConnectManualSettings {
    manualCardSite
    manualCardPort
  }
`;

export const PhoneFields = gql`
  fragment PhoneFields on Phone {
    id
    phone_type_id
    phone_type_name
    phone_type_other
    phone
    description
    extension
    primary
  }
`;

export const PracticeFields = gql`
  fragment PracticeFields on Practice {
    id
    organization_id
    name
    name_short
    email
    website
    address {
      ...AddressFields
    }
    phone {
      ...PhoneFields
    }
    logo {
      ...StaticImageFields
    }
    card_connect {
      ...CardConnectFields
    }
    card_connect_manual {
      ...CardConnectManualFields
    }
    stripe {
      accountId
    }
  }
  ${AddressFields}
  ${PhoneFields}
  ${StaticImageFields}
  ${CardConnectFields}
  ${CardConnectManualFields}
`;

export const InfoFields = gql`
  fragment InfoFields on Info {
    id
    type_id
    category
    name_key
    value
  }
`;

export const InfoTextFields = gql`
  fragment InfoTextFields on InfoText {
    id
    type_id
    category
    name_key
    value
    updated
    updated_user_name
    offline_id
  }
`;

export const TextIdFields = gql`
  fragment TextIdFields on InfoTextId {
    id
    type_id
    category
    name_key
  }
`;

export const ContactStatusTypeFields = gql`
  fragment ContactStatusTypeFields on ContactStatusType {
    id
    name
    sort_order
    active
    warning
    stop
  }
`;

export const FileFields = gql`
  fragment FileFields on File {
    id
    patient_id
    contact_id
    type_id
    type_name
    date
    name
    pointer
    size
    description
    hidden
    locked
    created
    created_user_name
    updated_user_name
    updated
  }
`;

export const NoteFields = gql`
  fragment NoteFields on Note {
    id
    offline_id
    patient_id
    offline_patient_id
    contact_id
    offline_contact_id
    type_id
    type_name
    date
    value
    preview
    hidden
    locked
    created
    updated
    created_user_name
    updated_user_name
  }
`;

export const ReminderFields = gql`
  fragment ReminderFields on Reminder {
    id
    service_rendered_id
    offline_id
    organization_id
    practice_id
    patient_id
    offline_patient_id
    contact_id
    user_id
    name
    date
    note
    status_id
    status_name
    updated
    hidden
    contact_name
    contact_number
    contact_send_type_name
    patient_name
    status_name
  }
`;

export const PrescriptionFillFields = gql`
  fragment PrescriptionFillFields on PrescriptionFill {
    id
    organization_id
    prescription_id
    invoice_id
    filled_service_rendered_id
    filled_item_din
    filled_item_lot_number
    filled_item_serial_number
    filled_item_bottle_number
    filled_item_manufacturer
    filled_item_instructions
    filled_date
    filled_user_id
    filled_user_name
  }
`;

export const PrescriptionFields = gql`
  fragment PrescriptionFields on Prescription {
    id
    organization_id
    practice_id
    patient_id
    offline_patient_id
    contact_id
    offline_contact_id
    service_id
    number
    refills
    instructions
    item_quantity
    item_description
    item_unit
    fill_externally
    created_type_id
    prescription_expiry_date
    product_expiry_date
    prescribed_date
    prescribed_user_id
    created
    created_user_id
    updated
    updated_user_id
    hidden
    offline_id
    prescribed_user_name
    patient_name
    patient_number
    contact_name
    contact_number
    is_filled
    available_fills
    filled_fills
    remaining_fills
    invoice_id
  }
`;

export const PrescriptionFlattenedFields = gql`
  fragment PrescriptionFlattenedFields on PrescriptionFlattened {
    id
    organization_id
    practice_id
    patient_id
    offline_patient_id
    contact_id
    offline_contact_id
    service_id
    number
    refills
    instructions
    item_quantity
    item_description
    item_unit
    fill_externally
    created_type_id
    prescription_expiry_date
    product_expiry_date
    prescribed_date
    prescribed_user_id
    created
    created_user_id
    updated
    updated_user_id
    hidden
    offline_id
    prescribed_user_name
    patient_name
    patient_number
    contact_name
    contact_number
    is_filled
    available_fills
    filled_fills
    remaining_fills
    invoice_id
    fill_id
    filled_service_rendered_id
    filled_item_din
    filled_item_lot_number
    filled_item_serial_number
    filled_item_bottle_number
    filled_item_manufacturer
    filled_item_instructions
    filled_date
    filled_user_id
    filled_user_name
  }
`;

export const NotePreviewFields = gql`
  fragment NotePreviewFields on Note {
    id
    offline_id
    patient_id
    offline_patient_id
    contact_id
    offline_contact_id
    type_id
    type_name
    date
    preview
    hidden
    locked
    created
    created_user_name
    updated_user_name
  }
`;

export const PatientTagFields = gql`
  fragment PatientTagFields on Tag {
    type_id
    name
    sort_order
  }
`;

export const ServiceActivityFields = gql`
  fragment ServiceActivityFields on ServiceActivity {
    date
    doctor_names
    service_names
    performed
    patient_id
    updated
  }
`;

export const OrganizationFields = gql`
  fragment OrganizationFields on Organization {
    id
    name
    updated
    default_practice_id
    default_practice_name
    subscription {
      ...OrganizationSubscriptionFields
    }
    logo {
      ...StaticImageFields
    }
    account_owner_id
    enable_offline
  }
  ${OrganizationSubscriptionFields}
  ${StaticImageFields}
`;

export const FileS3UrlFields = gql`
  fragment FileS3UrlFields on FileS3Url {
    url
    filePointer
  }
`;

export const SpeciesFields = gql`
  fragment SpeciesFields on PatientSpecies {
    id
    name
    sort_order
  }
`;

export const BreedFields = gql`
  fragment BreedFields on PatientBreed {
    id
    name
    species_id
    sort_order
  }
`;

export const GenderFields = gql`
  fragment GenderFields on PatientGender {
    id
    species_id
    name
    sort_order
  }
`;

export const ColorFields = gql`
  fragment ColorFields on PatientColor {
    id
    name
    sort_order
  }
`;

export const QuickNoteTypeFields = gql`
  fragment QuickNoteTypeFields on QuickNote {
    id
    note_type_id
    name
    note
    sort_order
  }
`;

export const NoteTypeFields = gql`
  fragment NoteTypeFields on NoteType {
    id
    name
    sort_order
    patient_print
    contact_print
    invoice_print
    quick_note {
      ...QuickNoteTypeFields
    }
  }
  ${QuickNoteTypeFields}
`;

export const FileTypeFields = gql`
  fragment FileTypeFields on FileType {
    id
    name
    sort_order
  }
`;

export const InfoTypeFields = gql`
  fragment InfoTypeFields on InfoType {
    id
    category
    name_key
    sort_order
  }
`;

export const PhoneTypeFields = gql`
  fragment PhoneTypeFields on PhoneType {
    id
    name
    sort_order
  }
`;

export const ThirdPartyTypeFields = gql`
  fragment ThirdPartyTypeFields on ThirdPartyType {
    id
    name_key
    desc_key
    service_queue_handler
    enabled
    sort_order
    requires_dob
    requires_gender
    requires_microchip
    requires_email
  }
`;

export const ReminderStatusFields = gql`
  fragment ReminderStatusFields on ReminderStatus {
    id
    name
    sort_order
    outstanding
    is_default
    system_status_id
  }
`;

export const PatientAlertTypeFields = gql`
  fragment PatientAlertTypeFields on PatientAlertType {
    id
    name
    sort_order
    prompt_invoice
    prompt_patient
  }
`;

export const ContactAlertTypeFields = gql`
  fragment ContactAlertTypeFields on ContactAlertType {
    id
    name
    sort_order
    prompt_contact
    prompt_invoice
    prompt_patient
  }
`;

export const PatientDemographicFields = gql`
  fragment PatientDemographicFields on PatientReferenceData {
    species {
      ...SpeciesFields
    }
    breed {
      ...BreedFields
    }
    gender {
      ...GenderFields
    }
    color {
      ...ColorFields
    }
    note_type {
      ...NoteTypeFields
    }
    file_type {
      ...FileTypeFields
    }
    info_type {
      ...InfoTypeFields
    }
    reminder_status {
      ...ReminderStatusFields
    }
    alert_type {
      ...PatientAlertTypeFields
    }
  }
  ${SpeciesFields}
  ${BreedFields}
  ${GenderFields}
  ${ColorFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${InfoTypeFields}
  ${ReminderStatusFields}
  ${PatientAlertTypeFields}
`;

export const CountryFields = gql`
  fragment CountryFields on Country {
    id
    name_key
    sort_order
    iso_code
    iso_code2
  }
`;

export const ProvStateFields = gql`
  fragment ProvStateFields on ProvState {
    id
    name_key
    sort_order
    country_id
  }
`;

export const ServiceTaxFields = gql`
  fragment ServiceTaxFields on ServiceTaxType {
    id
    tax_type_id
    name
    code
    compounded
    sort_order
  }
`;

const ServiceTextFields = gql`
  fragment ServiceTextFields on InfoTextId {
    category
    id
    name_key
    type_id
  }
`;

const PatientServiceValidationFields = gql`
  fragment PatientServiceValidationFields on PatientServiceValidation {
    organization_id
    service_id
    requires_dob
    requires_breed
    requires_gender
    requires_species
    requires_microchip
    requires_email
    requires_roa
    requires_reason
    requires_results
    requires_limb_treated
    requires_dosage
    requires_condition_treated
    requires_tests_and_diagnostics
    requires_treatment_type
    requires_time
    requires_medical_name
    requires_drug_name
    requires_description
    requires_vaccine_name
    requires_structure
    requires_modality
  }
`;

export const ServiceListViewFields = gql`
  fragment ServiceListViewFields on Service {
    id
    name
    unit_name
    price
    inactive
    quantity_default
    quantity_prompt
    controlled
    vaccine
    microchip
    rabies_tag_prompt
    prescription_prompt
    tax_code_names
    bundled_service_ids
    default_note_id
    reminder_ids
    bundle_prompt
    roa_prompt
    reason_prompt
    decease_prompt
    withdrawal_prompt
    general_ledger_id
    general_ledger_name
    general_ledger_number
    text {
      ...ServiceTextFields
    }
    patient_validation {
      ...PatientServiceValidationFields
    }
    third_party {
      id
      service_id
      third_party_id
      ref_data_id
    }
  }
  ${ServiceTextFields}
  ${PatientServiceValidationFields}
`;

const ServiceInfoFields = gql`
  fragment ServiceInfoFields on Info {
    category
    id
    name_key
    type_id
    value
  }
`;

const ServiceLabMapFields = gql`
  fragment ServiceLabMapFields on LabServiceMap {
    id
    lab_device_id
    lab_device_name
    organization_id
    service_id
    service_name
    lab_service_name
    lab_service_number
  }
`;

export const ServiceFieldsWithTax = gql`
  fragment ServiceFieldsWithTax on Service {
    id
    name
    unit_name
    price
    material_cost
    markup_percentage
    inactive
    quantity_default
    controlled
    vaccine
    microchip
    quantity_prompt
    tax_code_names
    tax {
      ...ServiceTaxFields
    }
    info {
      ...ServiceInfoFields
    }
    text {
      ...ServiceTextFields
    }
    general_ledger_id
    general_ledger_name
    general_ledger_number
    default_note_id
    bundled_service_ids
    reminder_ids
    reminder {
      id
      duration
    }
    bundle_prompt
    ...ServiceBundleFields
    roa_prompt
    prescription_prompt
    rabies_tag_prompt
    reason_prompt
    decease_prompt
    withdrawal_prompt
    lab_mapping {
      ...ServiceLabMapFields
    }
    patient_validation {
      ...PatientServiceValidationFields
    }
    third_party {
      id
      service_id
      third_party_id
      ref_data_id
    }
  }
  ${ServiceTaxFields}
  ${ServiceInfoFields}
  ${ServiceTextFields}
  ${ServiceBundleFields}
  ${ServiceLabMapFields}
  ${PatientServiceValidationFields}
`;

export const ServiceOfflineFields = gql`
  fragment ServiceOfflineFields on Service {
    id
    practice_id
    name
    unit_name
    price
    material_cost
    markup_percentage
    controlled
    vaccine
    inactive
    microchip
    quantity_prompt
    quantity_default
    tax_code_names
    tax {
      ...ServiceTaxFields
    }
    info {
      ...ServiceInfoFields
    }
    text {
      ...ServiceTextFields
    }
    tag {
      id
      type_id
      name
      sort_order
    }
    tag_names
    bundle {
      id
      bundled_service_id
      name
      quantity
      unit_name
      prompt_mode
      sort_order
    }
    bundled_service_ids
    reminder {
      id
      duration
      service_id
    }
    reminder_ids
    general_ledger_id
    general_ledger_name
    general_ledger_number
    default_note_id
    updated
    hidden
    bundle_prompt
    roa_prompt
    prescription_prompt
    rabies_tag_prompt
    reason_prompt
    decease_prompt
    withdrawal_prompt
    lab_mapping {
      ...ServiceLabMapFields
    }
    patient_validation {
      ...PatientServiceValidationFields
    }
    third_party {
      id
      service_id
      third_party_id
    }
  }
  ${ServiceTaxFields}
  ${ServiceInfoFields}
  ${ServiceTextFields}
  ${ServiceLabMapFields}
  ${PatientServiceValidationFields}
`;

export const ServiceInfoAndText = gql`
  fragment ServiceInfoAndText on Service {
    id
    info {
      ...InfoFields
    }
    text {
      ...TextIdFields
    }
  }
  ${InfoFields}
  ${TextIdFields}
`;

export const InvoiceContactPaymentFields = gql`
  fragment InvoiceContactPaymentFields on InvoiceContact {
    id
    contact_id
    contact_name
    contact_number
    percentage
    primary
  }
`;

export const BasicServiceRenderedFields = gql`
  fragment BasicServiceRenderedFields on ServiceRendered {
    id
    organization_id
    practice_id
    service_id
    service_name
    patient_id
    patient_number
    patient_name
    contact_id
    contact_number
    contact_name
    invoice_id
    invoice_number
    doctor_id
    doctor_name
    business_center_id
    business_center_name
    location_id
    location_name
    date
    name
    quantity
    list_unit_price
    unit_price
    unit_name
    total
    discount
    controlled
    vaccine
    microchip
    rabies_tag
    manufacturer_name
    lot_number
    serial_number
    expiry_date
    bottle_number
    sort_order
    parent_id
    child_sort_order
    hidden
    note_id
    created
    created_user_id
    created_user_name
    updated
    updated_user_id
    updated_user_name
    doctor_names
    reason
    withdrawal_period
    withdrawal_prompt
    hide_discount
    list_total
  }
`;

export const FragmentServiceRenderedFieldsWithNote = gql`
  fragment FragmentServiceRenderedFieldsWithNote on ServiceRendered {
    id
    date
    note_id
  }
`;

export const ContactTypeFields = gql`
  fragment ContactTypeFields on ContactType {
    type_id
    name_key
    invoice_ownership
    selectable
    contact_toggle
  }
`;

export const LedgerPaymentTypeFields = gql`
  fragment LedgerPaymentTypeFields on RefLedgerPaymentType {
    id
    name_key
    electronic_type_id
  }
`;

export const AddressTypeFields = gql`
  fragment AddressTypeFields on AddressType {
    id
    name_key
    sort_order
  }
`;

export const InvoiceStatusFields = gql`
  fragment InvoiceStatusFields on InvoiceStatus {
    id
    name_key
    sort_order
  }
`;

export const SubscriptionTypeDTOFields = gql`
  fragment SubscriptionTypeDTOFields on SubscriptionTypeDTO {
    id
    name_key
    sort_order
    level {
      id
      cost
      frequency
      name_key
      sort_order
      type_id
      type_name_key
      user_type
    }
  }
`;

export const CurrencyDefaultsFields = gql`
  fragment CurrencyDefaultsFields on CurrencyDefaultsReferenceData {
    id
    name
    code
    symbol
    decimal_digits
    decimal_symbol
    grouping_symbol
    negative_symbol
  }
`;

export const ThirdPartyRegionReferenceDataFields = gql`
  fragment ThirdPartyRegionReferenceDataFields on ThirdPartyRegionReferenceData {
    id
    third_party_id
    name_key
    setting_json
  }
`;

export const ContactRefFields = gql`
  fragment ContactRefFields on ContactReferenceData {
    id
    type {
      ...ContactTypeFields
    }
    status_type {
      ...ContactStatusTypeFields
    }
    note_type {
      ...NoteTypeFields
    }
    file_type {
      ...FileTypeFields
    }
    info_type {
      ...InfoTypeFields
    }
    alert_type {
      ...ContactAlertTypeFields
    }
  }
  ${ContactTypeFields}
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${InfoTypeFields}
  ${ContactAlertTypeFields}
`;

export const InvoiceContextFields = gql`
  fragment InvoiceContextFields on InvoiceContext {
    id
    organization_id
    practice_id
    status {
      id
      name_key
      sort_order
    }
    caregiver {
      id
      name
      doctor
    }
    business_center {
      id
      name
    }
    location {
      id
      name
    }
  }
`;

export const SettingDefinitionFields = gql`
  fragment SettingDefinitionFields on SettingDefinition {
    id
    category_key
    name_key
    description_key
    value_type
    valid_values
    default_value
    practice_level
    user_level
    hidden
    sort_order
  }
`;

export const SettingFields = gql`
  fragment SettingFields on Setting {
    id
    setting_id
    value
    organization_level_set
    practice_level_set
    user_level_set
    setting_name_key
    setting_category_key
  }
`;

export const ValidateVetFields = gql`
  fragment ValidateVetFields on HISAVetValidationResult {
    isValidNumber
    isValidName
    notFound
    firstName
    lastName
  }
`;

export const InterestRunFields = gql`
  fragment InterestRunFields on InterestRun {
    id
    number
    run_date
    rate
    compound
    charge_free
    has_charge
  }
`;

export const UserTypeFields = gql`
  fragment UserTypeFields on OrganizationUserType {
    id
    name
    doctor
    caregiver
    sort_order
  }
`;

export const RefServiceRenderedInfoTypeDataFields = gql`
  fragment RefServiceRenderedInfoTypeDataFields on RefServiceRenderedInfoTypeData {
    id
    service_rendered_info_id
    name_key
    value_key
    sort_order
  }
`;

export const RefLabelDataFields = gql`
  fragment RefLabelDataFields on RefLabelData {
    id
    name_key
    label_file_name
  }
`;

export const RefHeardAboutUsFields = gql`
  fragment RefHeardAboutUsFields on HeardAboutUs {
    id
    name_key
    sort_order
  }
`;

export const DataConversionFields = gql`
  fragment DataConversionFields on DataConversion {
    id
    name
    email
    organization_id
    software_id
    software_name
    software_version
    attachments {
      id
      name
      pointer
      size
    }
    instructions
    notes
    closed
  }
`;

export const OrganizationOfflineFields = gql`
  fragment OrganizationOfflineFields on Organization {
    ...OrganizationFields
    practice {
      ...PracticeFields
    }
    ref_demographics {
      id
      country {
        ...CountryFields
      }
      prov_state {
        ...ProvStateFields
      }
      phone_type {
        ...PhoneTypeFields
      }
    }
    ref_contact {
      ...ContactRefFields
    }
    ref_user {
      type {
        id
        name
        doctor
      }
    }
    ref_patient {
      ...PatientDemographicFields
    }
    ref_financial {
      id
      ...FinancialTaxTypeFields
      ...GeneralLedgerTypeFields
      ...FinancialGeneralLedgerFields
      ...PaymentDeviceTypeFields
      ...PaymentTypeFields
    }
    maintenance {
      start_date
      end_date
      redirect_url
      message_key
    }
    phone {
      ...PhoneFields
    }
    ref_system {
      id
      third_party {
        ...ThirdPartyTypeFields
      }
    }
  }
  ${OrganizationFields}
  ${PracticeFields}
  ${CountryFields}
  ${ProvStateFields}
  ${ContactRefFields}
  ${PatientDemographicFields}
  ${PhoneTypeFields}
  ${FinancialTaxTypeFields}
  ${GeneralLedgerTypeFields}
  ${FinancialGeneralLedgerFields}
  ${PaymentDeviceTypeFields}
  ${PaymentTypeFields}
  ${PhoneFields}
  ${ThirdPartyTypeFields}
`;

export const ReminderBatchListFields = gql`
  fragment ReminderBatchListFields on ReminderBatch {
    date_sent
    id
    number
    practice_id
  }
`;

export const ReminderBatchDetailFields = gql`
  fragment ReminderBatchDetailFields on ReminderBatchDetail {
    id
    organization_id
    contact_id
    contact_name
    failed
    patient_id
    patient_name
    practice_id
    reminder_batch_id
    reminder_date
    reminder_id
    reminder_name
    reminder_note
    reminder_status_id
    reminder_status_name
    sent_type
    reminder_status_name
    reminder_sent_type_name
  }
`;

export const OrganizationAddressFields = gql`
  fragment OrganizationAddressFields on Address {
    id
    country_name_key
  }
`;

export const PaymentPortalResultFields = gql`
  fragment PaymentPortalResultFields on PaymentPortalResult {
    is_available
    message
    success
  }
`;

export const ReferenceDataFields = gql`
  fragment ReferenceDataFields on SystemReferenceDataTypes {
    id
    contact_type {
      ...ContactTypeFields
    }
    ledger_payment_type {
      ...LedgerPaymentTypeFields
    }
    address_type {
      ...AddressTypeFields
    }
    country {
      ...CountryFields
    }
    prov_state {
      ...ProvStateFields
    }
    invoice_status {
      ...InvoiceStatusFields
    }
    setting {
      ...SettingDefinitionFields
    }
    subscription_type {
      ...SubscriptionTypeDTOFields
    }
    ref_currency_defaults {
      ...CurrencyDefaultsFields
    }
    ref_service_rendered_info_type_data {
      ...RefServiceRenderedInfoTypeDataFields
    }
    ref_label {
      ...RefLabelDataFields
    }
    heard_about_us {
      ...RefHeardAboutUsFields
    }
    ref_3p_region {
      ...ThirdPartyRegionReferenceDataFields
    }
  }
  ${ContactTypeFields}
  ${LedgerPaymentTypeFields}
  ${AddressTypeFields}
  ${CountryFields}
  ${ProvStateFields}
  ${InvoiceStatusFields}
  ${SettingDefinitionFields}
  ${SubscriptionTypeDTOFields}
  ${CurrencyDefaultsFields}
  ${RefServiceRenderedInfoTypeDataFields}
  ${RefLabelDataFields}
  ${RefHeardAboutUsFields}
  ${ThirdPartyRegionReferenceDataFields}
`;

export const UpsertOrganizationReferenceData = gql`
  mutation UpsertOrganizationReferenceData($organizationId: ID!, $organization: OrganizationUpsert!) {
    upsertOrganization(organizationId: $organizationId, organization: $organization) {
      id
      ref_contact {
        id
        status_type {
          ...ContactStatusTypeFields
        }
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        type {
          ...ContactTypeFields
        }
      }
      ref_patient {
        id
        note_type {
          ...NoteTypeFields
        }
        file_type {
          ...FileTypeFields
        }
        species {
          ...SpeciesFields
        }
        breed {
          ...BreedFields
        }
        gender {
          ...GenderFields
        }
        color {
          ...ColorFields
        }
        reminder_status {
          ...ReminderStatusFields
        }
      }
      ref_financial {
        id
        ...FinancialTaxTypeFields
        ...FinancialGeneralLedgerFields
      }
      ref_user {
        type {
          ...UserTypeFields
        }
      }
    }
  }
  ${ContactStatusTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${ContactTypeFields}
  ${NoteTypeFields}
  ${FileTypeFields}
  ${SpeciesFields}
  ${BreedFields}
  ${GenderFields}
  ${ColorFields}
  ${FinancialTaxTypeFields}
  ${FinancialGeneralLedgerFields}
  ${UserTypeFields}
  ${ReminderStatusFields}
`;

export const PatientContactAlertFields = gql`
  fragment PatientContactAlertFields on PatientContactAlert {
    id
    note
    type_id
    contact_id
    patient_id
    type_name
  }
`;

export const AddressDTOFields = gql`
  fragment AddressDTOFields on AddressDTO {
    id
    address_type_id
    address_type_other
    address_type_name_key
    address_1
    address_2
    address_3
    city
    county
    country_id
    country_name_key
    country_prov_state_id
    country_prov_state_name_key
    postal_zip
    primary
  }
`;

export const FileS3UrlDTOFields = gql`
  fragment FileS3UrlDTOFields on FileS3UrlDTO {
    url
    filePointer
  }
`;

export const StaticImageDTOFields = gql`
  fragment StaticImageDTOFields on StaticImageDTO {
    id
    name
    pointer
    size
    created
    created_user_id
    created_user_name
  }
`;

export const PhoneDTOFields = gql`
  fragment PhoneDTOFields on PhoneDTO {
    id
    phone_type_id
    phone_type_name
    phone_type_other
    phone
    description
    extension
    primary
  }
`;

export const PhoneTypeDTOFields = gql`
  fragment PhoneTypeDTOFields on PhoneTypeDTO {
    id
    name
    sort_order
  }
`;

export const BusinessCenterDTOFields = gql`
  fragment BusinessCenterDTOFields on BusinessCenterDTO {
    id
    name
    sort_order
  }
`;

export const LocationDTOFields = gql`
  fragment LocationDTOFields on LocationDTO {
    id
    name
    sort_order
  }
`;

export const SettingDTOFields = gql`
  fragment SettingDTOFields on SettingDTO {
    id
    setting_id
    value
    organization_level_set
    practice_level_set
    user_level_set
    setting_name_key
    setting_category_key
  }
`;

export const CardConnectDTOFields = gql`
  fragment CardConnectDTOFields on CardConnectSettingsDTO {
    merchantId
    userId
  }
`;

export const CardConnectManualDTOFields = gql`
  fragment CardConnectManualDTOFields on CardConnectManualSettingsDTO {
    manualCardSite
    manualCardPort
  }
`;

export const InfoDTOFields = gql`
  fragment InfoDTOFields on InfoDTO {
    id
    type_id
    category
    name_key
    value
  }
`;

export const ContactTypeDTOFields = gql`
  fragment ContactTypeDTOFields on ContactTypeDTO {
    id
    type_id
    name_key
    invoice_ownership
    selectable
    contact_toggle
    sort_order
  }
`;

export const ContactStatusTypeDTOFields = gql`
  fragment ContactStatusTypeDTOFields on ContactStatusTypeDTO {
    id
    name
    sort_order
    active
    warning
    stop
  }
`;

export const QuickNoteTypeDTOFields = gql`
  fragment QuickNoteTypeDTOFields on QuickNoteDTO {
    id
    note_type_id
    name
    note
    sort_order
  }
`;

export const NoteTypeDTOFields = gql`
  fragment NoteTypeDTOFields on NoteTypeDTO {
    id
    name
    sort_order
    patient_print
    contact_print
    invoice_print
    quick_note {
      ...QuickNoteTypeDTOFields
    }
  }
  ${QuickNoteTypeDTOFields}
`;

export const FileTypeDTOFields = gql`
  fragment FileTypeDTOFields on FileTypeDTO {
    id
    name
    sort_order
  }
`;

export const InfoTypeDTOFields = gql`
  fragment InfoTypeDTOFields on InfoTypeDTO {
    id
    category
    name_key
    sort_order
  }
`;

export const PatientTagTypeDTOFields = gql`
  fragment PatientTagTypeDTOFields on PatientTagTypeDTO {
    id
    name
    sort_order
    protected
  }
`;

export const ContactTagTypeDTOFields = gql`
  fragment ContactTagTypeDTOFields on ContactTagTypeDTO {
    id
    name
    protected
    sort_order
  }
`;

export const ContactAlertTypeDTOFields = gql`
  fragment ContactAlertTypeDTOFields on ContactAlertTypeDTO {
    id
    name
    prompt_contact
    prompt_invoice
    prompt_patient
    sort_order
  }
`;

export const SpeciesDTOFields = gql`
  fragment SpeciesDTOFields on PatientSpeciesDTO {
    id
    name
    sort_order
  }
`;

export const BreedDTOFields = gql`
  fragment BreedDTOFields on PatientBreedDTO {
    id
    name
    species_id
    sort_order
  }
`;

export const GenderDTOFields = gql`
  fragment GenderDTOFields on PatientGenderDTO {
    id
    species_id
    name
    sort_order
  }
`;

export const ColorDTOFields = gql`
  fragment ColorDTOFields on PatientColorDTO {
    id
    name
    sort_order
  }
`;

export const ReminderStatusDTOFields = gql`
  fragment ReminderStatusDTOFields on ReminderStatusDTO {
    id
    name
    sort_order
    outstanding
    is_default
    system_status_id
  }
`;

export const PatientAlertTypeDTOFields = gql`
  fragment PatientAlertTypeDTOFields on PatientAlertTypeDTO {
    id
    name
    sort_order
    prompt_invoice
    prompt_patient
  }
`;

export const ServiceTagTypeDTOFields = gql`
  fragment ServiceTagTypeDTOFields on ServiceTagTypeDTO {
    id
    name
    sort_order
  }
`;

export const TaxTypeDTOFields = gql`
  fragment TaxTypeDTOFields on TaxTypeDTO {
    id
    name
    code
    percentage
    default
    compounded
    sort_order
    external_tax_id
    general_ledger_id
  }
`;

export const LedgerTypeDTOFields = gql`
  fragment LedgerTypeDTOFields on LedgerTypeDTO {
    id
    name_key
    general_ledger_id
    sort_order
  }
`;

export const LedgerPaymentTypeDTOFields = gql`
  fragment LedgerPaymentTypeDTOFields on LedgerPaymentTypeDTO {
    id
    name_key
    electronic_type_id
    sort_order
    general_ledger_id
    external_id
  }
`;

export const LedgerSubTypeDTOFields = gql`
  fragment LedgerSubTypeDTOFields on LedgerSubTypeDTO {
    id
    name
    sort_order
  }
`;

export const GeneralLedgerDTOFields = gql`
  fragment GeneralLedgerDTOFields on GeneralLedgerDTO {
    id
    gl_number
    external_gl_id
    name
    sort_order
    practice_id
    is_receivables_account
  }
`;

export const PaymentDeviceTypeDTOFields = gql`
  fragment PaymentDeviceTypeDTOFields on PaymentDeviceTypeDTO {
    id
    name
    practice_id
    serial_number
  }
`;

export const ImageCategoryTypeDTOFields = gql`
  fragment ImageCategoryTypeDTOFields on ImageCategoryTypeDTO {
    id
    category_id
    name
    sort_order
  }
`;

export const ThirdPartyTypeDTOFields = gql`
  fragment ThirdPartyTypeDTOFields on ThirdPartyTypeDTO {
    id
    desc_key
    enabled
    name_key
    requires_dob
    requires_email
    requires_gender
    requires_microchip
    requires_reason
    requires_roa
    service_queue_handler
    sort_order
  }
`;

export const OrganizationUserTypeDTOFields = gql`
  fragment OrganizationUserTypeDTOFields on OrganizationUserTypeDTO {
    id
    name
    doctor
    caregiver
    sort_order
  }
`;

export const OrganizationSubscriptionAddOnDTOFields = gql`
  fragment OrganizationSubscriptionAddOnDTOFields on OrganizationSubscriptionAddOnDTO {
    id
    type_id
    type_name_key
    level_id
    level_name_key
    enabled
    user_type
    has_switch
    cost
  }
`;

export const BillingAddressDTOFields = gql`
  fragment BillingAddressDTOFields on BillingAddressDTO {
    address_1
    address_2
    address_3
    city
    country_prov_state_id
    country_prov_state_name
    country_id
    country_name
    postal_zip
    phone
    email
  }
`;

export const OrganizationSubscriptionInvoiceDTOFields = gql`
  fragment OrganizationSubscriptionInvoiceDTOFields on OrganizationSubscriptionInvoiceDTO {
    id
    amount
    created
    number
    subscription_id
    subscription_transaction_type_id
    detail {
      id
      name_key
      payment_id
      quantity
      total
      unit_price
    }
  }
`;

export const OrganizationSubscriptionDetailDTOFields = gql`
  fragment OrganizationSubscriptionDetailDTOFields on OrganizationSubscriptionDetailDTO {
    disabled
    id
    is_new
    level_id
    level_name_key
    qty
    qty_available
    qty_used
    type_id
    type_name_key
    user_type
    users {
      id
      name
      user_id
    }
  }
`;

export const PracticeDTOFields = gql`
  fragment PracticeDTOFields on PracticeDTO {
    id
    organization_id
    name
    name_short
    email
    website
    address {
      ...AddressDTOFields
    }
    phone {
      ...PhoneDTOFields
    }
    setting {
      ...SettingDTOFields
    }
    logo {
      ...StaticImageDTOFields
    }
    card_connect {
      ...CardConnectDTOFields
    }
    card_connect_manual {
      ...CardConnectManualDTOFields
    }
    stripe {
      accountId
    }
    info {
      ...InfoDTOFields
    }
  }
  ${AddressDTOFields}
  ${PhoneDTOFields}
  ${SettingDTOFields}
  ${StaticImageDTOFields}
  ${CardConnectDTOFields}
  ${CardConnectManualDTOFields}
  ${InfoDTOFields}
`;

export const ContactReferenceDataDTOFields = gql`
  fragment ContactReferenceDataDTOFields on ContactReferenceDataDTO {
    id
    type {
      ...ContactTypeDTOFields
    }
    status_type {
      ...ContactStatusTypeDTOFields
    }
    note_type {
      ...NoteTypeDTOFields
    }
    file_type {
      ...FileTypeDTOFields
    }
    info_type {
      ...InfoTypeDTOFields
    }
    tag_type {
      ...ContactTagTypeDTOFields
    }
    alert_type {
      ...ContactAlertTypeDTOFields
    }
  }
  ${ContactTypeDTOFields}
  ${ContactStatusTypeDTOFields}
  ${NoteTypeDTOFields}
  ${FileTypeDTOFields}
  ${InfoTypeDTOFields}
  ${ContactTagTypeDTOFields}
  ${ContactAlertTypeDTOFields}
`;

export const PatientReferenceDataDTOFields = gql`
  fragment PatientReferenceDataDTOFields on PatientReferenceDataDTO {
    id
    species {
      ...SpeciesDTOFields
    }
    breed {
      ...BreedDTOFields
    }
    gender {
      ...GenderDTOFields
    }
    color {
      ...ColorDTOFields
    }
    note_type {
      ...NoteTypeDTOFields
    }
    file_type {
      ...FileTypeDTOFields
    }
    info_type {
      ...InfoTypeDTOFields
    }
    tag_type {
      ...PatientTagTypeDTOFields
    }
    reminder_status {
      ...ReminderStatusDTOFields
    }
    alert_type {
      ...PatientAlertTypeDTOFields
    }
  }
  ${SpeciesDTOFields}
  ${BreedDTOFields}
  ${GenderDTOFields}
  ${ColorDTOFields}
  ${NoteTypeDTOFields}
  ${FileTypeDTOFields}
  ${InfoTypeDTOFields}
  ${PatientTagTypeDTOFields}
  ${ReminderStatusDTOFields}
  ${PatientAlertTypeDTOFields}
`;

export const ServiceReferenceDataDTOFields = gql`
  fragment ServiceReferenceDataDTOFields on ServiceReferenceDataDTO {
    id
    info_type {
      ...InfoTypeDTOFields
    }
    tag_type {
      ...ServiceTagTypeDTOFields
    }
  }
  ${InfoTypeDTOFields}
  ${ServiceTagTypeDTOFields}
`;

export const FinancialReferenceDataDTOFields = gql`
  fragment FinancialReferenceDataDTOFields on FinancialReferenceDataDTO {
    id
    tax_type {
      ...TaxTypeDTOFields
    }
    info_type {
      ...InfoTypeDTOFields
    }
    ledger_type {
      ...LedgerTypeDTOFields
    }
    ledger_payment_type {
      ...LedgerPaymentTypeDTOFields
    }
    ledger_sub_type {
      ...LedgerSubTypeDTOFields
    }
    general_ledger {
      ...GeneralLedgerDTOFields
    }
    payment_device_type {
      ...PaymentDeviceTypeDTOFields
    }
  }
  ${TaxTypeDTOFields}
  ${InfoTypeDTOFields}
  ${LedgerTypeDTOFields}
  ${LedgerPaymentTypeDTOFields}
  ${LedgerSubTypeDTOFields}
  ${GeneralLedgerDTOFields}
  ${PaymentDeviceTypeDTOFields}
`;

export const SystemReferenceDataDTOFields = gql`
  fragment SystemReferenceDataDTOFields on SystemReferenceDataDTO {
    id
    image_category_type {
      ...ImageCategoryTypeDTOFields
    }
    third_party {
      ...ThirdPartyTypeDTOFields
    }
  }
  ${ImageCategoryTypeDTOFields}
  ${ThirdPartyTypeDTOFields}
`;

export const UserReferenceDataDTOFields = gql`
  fragment UserReferenceDataDTOFields on UserReferenceDataDTO {
    id
    info_type {
      ...InfoTypeDTOFields
    }
    type {
      ...OrganizationUserTypeDTOFields
    }
  }
  ${InfoTypeDTOFields}
  ${OrganizationUserTypeDTOFields}
`;

export const SMTPSettingsDTOFields = gql`
  fragment SMTPSettingsDTOFields on SMTPSettingsDTO {
    accountName
    displayName
    fromAddress
    portNumber
    replyToAddress
    requiresSSL
    serverName
    useSES
  }
`;

export const OrganizationSubscriptionDTOFields = gql`
  fragment OrganizationSubscriptionDTOFields on OrganizationSubscriptionDTO {
    id
    card_token
    card_type_id
    country_id
    country_prov_state_id
    display_number
    expiry_month
    expiry_year
    failed
    failed_message
    frequency
    last_billed_date
    name_on_card
    next_bill_date
    problem
    processor_customer_id
    processor_type_id
    start_date
    addon {
      ...OrganizationSubscriptionAddOnDTOFields
    }
    billing_address {
      ...BillingAddressDTOFields
    }
    invoice {
      ...OrganizationSubscriptionInvoiceDTOFields
    }
    detail {
      ...OrganizationSubscriptionDetailDTOFields
    }
  }
  ${OrganizationSubscriptionAddOnDTOFields}
  ${BillingAddressDTOFields}
  ${OrganizationSubscriptionInvoiceDTOFields}
  ${OrganizationSubscriptionDetailDTOFields}
`;

export const DemographicReferenceDataDTOFields = gql`
  fragment DemographicReferenceDataDTOFields on DemographicReferenceDataDTO {
    id
    phone_type {
      ...PhoneTypeDTOFields
    }
    business_center {
      ...BusinessCenterDTOFields
    }
    location {
      ...LocationDTOFields
    }
  }
  ${PhoneTypeDTOFields}
  ${BusinessCenterDTOFields}
  ${LocationDTOFields}
`;

export const OrganizationDTOFields = gql`
  fragment OrganizationDTOFields on OrganizationDTO {
    id
    name
    organization_id
    account_owner_id
    default_practice_id
    default_practice_name
    locale_id
    enable_offline
    bulk_load_stamp
    created
    updated
    address {
      ...AddressDTOFields
    }
    bulk_load_file {
      ...FileS3UrlDTOFields
    }
    logo {
      ...StaticImageDTOFields
    }
    phone {
      ...PhoneDTOFields
    }
    practice {
      ...PracticeDTOFields
    }
    ref_contact {
      ...ContactReferenceDataDTOFields
    }
    ref_patient {
      ...PatientReferenceDataDTOFields
    }
    ref_service {
      ...ServiceReferenceDataDTOFields
    }
    ref_financial {
      ...FinancialReferenceDataDTOFields
    }
    ref_system {
      ...SystemReferenceDataDTOFields
    }
    ref_user {
      ...UserReferenceDataDTOFields
    }
    setting {
      ...SettingDTOFields
    }
    smtp {
      ...SMTPSettingsDTOFields
    }
    subscription {
      ...OrganizationSubscriptionDTOFields
    }
    ref_demographics {
      ...DemographicReferenceDataDTOFields
    }
  }
  ${AddressDTOFields}
  ${FileS3UrlDTOFields}
  ${StaticImageDTOFields}
  ${PhoneDTOFields}
  ${PracticeDTOFields}
  ${ContactReferenceDataDTOFields}
  ${PatientReferenceDataDTOFields}
  ${ServiceReferenceDataDTOFields}
  ${FinancialReferenceDataDTOFields}
  ${SystemReferenceDataDTOFields}
  ${UserReferenceDataDTOFields}
  ${SettingDTOFields}
  ${SMTPSettingsDTOFields}
  ${OrganizationSubscriptionDTOFields}
  ${DemographicReferenceDataDTOFields}
`;

export const ServiceRenderedInfoFields = gql`
  fragment ServiceRenderedInfoFields on ServiceRendered {
    info {
      id
      type_id
      name_key
      value
    }
  }
`;

export const ServiceRenderedTextFields = gql`
  fragment ServiceRenderedTextFields on ServiceRendered {
    text {
      id
      type_id
      name_key
      value
    }
  }
`;

export const ServiceRenderedFieldsWithInfoAndText = gql`
  fragment ServiceRenderedFieldsWithInfoAndText on ServiceRendered {
    ...BasicServiceRenderedFields
    ...ServiceRenderedInfoFields
    ...ServiceRenderedTextFields
  }
  ${BasicServiceRenderedFields}
  ${ServiceRenderedInfoFields}
  ${ServiceRenderedTextFields}
`;
