import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/es/form/interface';
import { isMatch } from '../../util/objectComparisons';
import { QuickNoteDto } from '../../graph/types';

interface QuickNoteProps {
  onSave: (name: string, body: string, sortOrder: number, existingId?: string) => Promise<void>;
  onCancel: () => void;
  defaultNoteType: string;
  editingEntry?: QuickNoteDto;
}

export type QuickNoteModalProps = ReturnType<typeof useQuickNoteModal>;

export const useQuickNoteModal = ({ onSave, onCancel, defaultNoteType, editingEntry }: QuickNoteProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [quickNote, setQuickNote] = useState('');

  useEffect(() => {
    setQuickNote(editingEntry?.note || '');
  }, [editingEntry?.note]);

  const [form] = useForm();

  useEffect(() => {
    if (editingEntry) {
      form.setFieldsValue({ sortOrder: editingEntry.sort_order, noteName: editingEntry.name });
    } else {
      form.setFieldsValue({ sortOrder: undefined, noteName: undefined });
    }
  }, [editingEntry, form]);

  const handleValueChange = (values: Store) => {
    setChangesMade(!isMatch(null, values));
  };

  const handleOnFinish = async (values: Store) => {
    setIsSaving(true);
    await onSave(values.noteName, quickNote, values.sortOrder, editingEntry?.id);
    resetForm();
    setIsSaving(false);
  };

  const handleOnClose = () => {
    resetForm();
    onCancel();
  };

  const resetForm = () => {
    form.resetFields();
    setQuickNote('');
    setChangesMade(false);
  };

  return {
    handleOnFinish,
    handleOnClose,
    handleValueChange,
    form,
    isSaving,
    changesMade,
    setQuickNote,
    quickNote,
    defaultNoteType,
  };
};
