import React, { useCallback, useMemo } from 'react';
import NativeStackTokenizer from 'react-cardpointe-gateway';
import { CreditCardTokenInfo } from '../../hooks/stripeHooks';
import { getCardTypeByCardConnectToken } from '../../constants/referenceData/ledgerReferenceData';

type CardConnectTokenizerProps = {
  setCreditCard: (card: CreditCardTokenInfo) => void;
  port: string;
  site: string;
};

export const reformatEmvExpiry = (value: string) =>
  (value.substring(4, value.length) + value.substring(2, 4)).padStart(4, '0');

export const CardConnectTokenizer: React.FC<CardConnectTokenizerProps> = ({ setCreditCard, port, site }) => {
  const handleSetCreditCard = useCallback(
    (cardSecureToken: { token: string; expiryDate: string }) => {
      const creditCardTokenInfo: CreditCardTokenInfo = {
        token: cardSecureToken.token,
        expiryDate: cardSecureToken.expiryDate,
        cardType: getCardTypeByCardConnectToken(cardSecureToken.token),
        displayNumber: cardSecureToken.token.substring(cardSecureToken.token.length - 4),
        expiryYear: cardSecureToken.expiryDate.substring(2, 4),
        expiryMonth: cardSecureToken.expiryDate.substring(4).padStart(2, '0'),
      };
      setCreditCard(creditCardTokenInfo);
    },
    [setCreditCard]
  );
  const tokenProps = useMemo(
    () => ({
      userEmvData: handleSetCreditCard,
      maskfirsttwo: true,
      useexpiry: true,
      usemonthnames: true,
      usecvv: true,
      cardnumbernumericonly: true,
      orientation: 'horizontal',
      invalidinputevent: true,
      tokenizewheninactive: true,
      enhancedresponse: true,
      formatinput: true,
    }),
    [handleSetCreditCard]
  );

  const tokenizerComponent = useMemo(
    () => <NativeStackTokenizer site={site} port={port} tokenProps={tokenProps} />,
    [port, site, tokenProps]
  );

  return (
    <div className='native-stack-payments' style={{ marginLeft: '55px', marginTop: '20px' }}>
      {tokenizerComponent}
    </div>
  );
};
