import { Contact, ContactTypeDto, PatientRelatedUpsert, RelatedContactEntryCurrent } from '../../../../../graph/types';
import { RelationshipEmptyEntry, RelationshipTableEntry } from './PatientRelationship';
import dayjs from 'dayjs';
import { contactTypeConfigs, ContactTypeNameKey } from '../../../../../constants/referenceData/contactReferenceData';

export function addEmptyEntriesForMissingTypes(
  contactTypeRefs: ContactTypeDto[],
  relationships: RelatedContactEntryCurrent[]
): RelationshipTableEntry[] {
  return contactTypeRefs
    .filter(
      (contactTypeRef) =>
        contactTypeTranslationExists(contactTypeRef) && contactTypeRef.name_key !== ContactTypeNameKey.Owner
    )
    .map((contactTypeRef) => {
      const existingRelationship = relationships.find(
        (relationship) => relationship.contact_type_id === contactTypeRef.type_id
      );
      return existingRelationship || ({ contact_type_id: contactTypeRef.type_id } as RelationshipEmptyEntry);
    });
}

function contactTypeTranslationExists(contactTypeRef: ContactTypeDto): boolean {
  return !!contactTypeConfigs[contactTypeRef.name_key as ContactTypeNameKey];
}

export function mapToEditExistingRelationshipUpsert(
  contact: Contact,
  selectedContactTypeId: string,
  previousRelationship: RelatedContactEntryCurrent,
  dateFormat: string
) {
  return {
    id: previousRelationship.id,
    related_contact_id: isNaN(+(contact.id ?? '.')) ? undefined : contact.id,
    related_contact_type_id: selectedContactTypeId,
    offline_related_contact_id: contact.offline_id,
    effective_date: dayjs().format(dateFormat),
    primary: true,
    void: false,
  };
}

export function mapToAddNewRelationshipUpsert(contact: Contact, selectedContactTypeId: string, dateFormat: string) {
  return {
    related_contact_id: isNaN(+(contact.id ?? '.')) ? undefined : contact.id,
    related_contact_type_id: selectedContactTypeId,
    offline_related_contact_id: contact.offline_id,
    effective_date: dayjs().format(dateFormat),
    primary: true,
    void: false,
  };
}

export const mapToRemoveRelationshipUpsert = (
  relationshipToRemove: RelatedContactEntryCurrent,
  dateFormat: string
): PatientRelatedUpsert => {
  return {
    id: relationshipToRemove.id,
    related_contact_id: relationshipToRemove.contact_id,
    related_contact_type_id: relationshipToRemove.contact_type_id,
    effective_date: relationshipToRemove.effective_date,
    end_date: dayjs().format(dateFormat),
    primary: relationshipToRemove.primary,
    void: false,
  };
};
