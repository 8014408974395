import React, { useState } from 'react';
import Input from 'antd/lib/input/Input';
import Form from 'antd/lib/form';
import Table from 'antd/lib/table/Table';
import { Checkbox } from 'antd';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useGetContactCreditCards, useUpdateContact } from '../../../../hooks/ajax/contact/contactHooks';
import { Loading } from '../../../Loading/Loading';
import { translations } from '../../../../constants/translations';
import { CreditCard } from '../../../../graph/types';
import { ColumnType } from 'rc-table/lib/interface';
import { getCreditCardTypeTag } from './CreditCardTableUtils';
import { DropdownButtonWithMenu } from '../../../DropdownButtonWithMenu/DropdownButtonWithMenu';
import { ContactUpsertGenerator } from '../../../../classes/upsertGenerators/ContactUpsertGenerator';
import { StyledFormWithoutMargin } from './CreditCardTable.style';
import { ElectronicPaymentType } from '../../../../constants/referenceData/paymentProcessorTypeReferenceData';
import { toInteger } from 'lodash';

export const editInputTestId = 'edit-card-input';

export interface CreditCardTableProps {
  contactId: string;
  setCreditCard: (creditCard: CreditCard | undefined) => void;
  creditCard: CreditCard | undefined;
  setIsSaving?: (isLoading: boolean) => void;
  isStripeProcessor?: boolean;
  showNoCardsMessage?: boolean;
  hideRowSelection?: boolean;
}

export const CreditCardTable: React.FC<CreditCardTableProps> = ({
  contactId,
  setCreditCard,
  creditCard,
  setIsSaving,
  isStripeProcessor,
  showNoCardsMessage,
  hideRowSelection = false,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { contact: contactCreditCards, contactLoading: contactCreditCardsLoading } = useGetContactCreditCards({
    contactId,
    organizationId,
  });
  const [form] = Form.useForm();
  const [upsertContact] = useUpdateContact();
  const [editingCardId, setEditingCardId] = useState<string>();
  const [primaryChecked, setPrimaryChecked] = useState<boolean>(false);

  if (contactCreditCardsLoading) {
    return <Loading />;
  }

  if (!contactCreditCards) {
    return <p>{translations.paymentModal.creditCards.errorLoadingRecords}</p>;
  }

  if (!contactCreditCards?.credit_card?.length) {
    return showNoCardsMessage ? (
      <i style={{ color: 'grey' }}>{translations.paymentModal.creditCards.noCardsMessage}</i>
    ) : null;
  }

  const columns: ColumnType<CreditCard>[] = [
    {
      title: translations.paymentModal.creditCards.columns.type,
      render: (creditCard: CreditCard) => {
        return <div onClick={() => selectRow(creditCard)}>{getCreditCardTypeTag(creditCard)}</div>;
      },
      width: 90,
    },
    {
      title: translations.paymentModal.creditCards.columns.displayNumber,
      dataIndex: 'display_number',
      width: 150,
    },
    {
      title: translations.paymentModal.creditCards.columns.expiry,
      render: (creditCard: CreditCard) => {
        return <div>{creditCard.expiry_month + '/' + creditCard.expiry_year}</div>;
      },
      width: 70,
    },
    {
      title: translations.paymentModal.creditCards.columns.primary,
      dataIndex: 'primary',
      width: 60,
      render: (primary: boolean, creditCard: CreditCard) => {
        if (editingCardId === creditCard.id) {
          return (
            <StyledFormWithoutMargin name='primary'>
              <Checkbox
                checked={primaryChecked}
                value={primaryChecked}
                onChange={(e) => setPrimaryChecked(e.target.checked)}
              />
            </StyledFormWithoutMargin>
          );
        }
        return primary ? <Checkbox checked={primary} value={primary} /> : '';
      },
    },
    {
      title: translations.paymentModal.creditCards.columns.note,
      dataIndex: 'nick_name',
      width: 150,
      render: (nickName: string, creditCard: CreditCard) => {
        if (editingCardId === creditCard.id) {
          return (
            <StyledFormWithoutMargin name='nick_name'>
              <Input data-testid={editInputTestId} />
            </StyledFormWithoutMargin>
          );
        }
        return nickName;
      },
    },
    {
      title: translations.paymentModal.creditCards.columns.actions,
      key: 'actions',
      render: (creditCard: CreditCard) => (
        <DropdownButtonWithMenu
          menuItemProps={
            editingCardId === creditCard.id
              ? [
                  {
                    title: translations.shared.saveButtonText,
                    onClick: () => onSubmitEditCardNote(creditCard),
                  },
                  {
                    title: translations.shared.cancelButtonText,
                    onClick: onCancelEditCardNote,
                  },
                ]
              : [
                  {
                    title: translations.shared.editButtonText,
                    onClick: () => onClickEdit(creditCard),
                  },
                  {
                    title: translations.shared.deleteButtonText,
                    onClick: (e) => {
                      e?.stopPropagation();
                      handleDelete(creditCard);
                    },
                    popconfirmProps: {
                      title: translations.paymentModal.creditCards.deleteConfirmationText,
                      okText: translations.shared.popconfirm.ok,
                      cancelText: translations.shared.popconfirm.no,
                      onCancel: (e) => e?.stopPropagation(),
                    },
                  },
                ]
          }
        />
      ),
      width: 100,
    },
  ];

  const handleDelete = async (creditCard: CreditCard) => {
    setIsSaving?.(true);

    const upsert = new ContactUpsertGenerator({ id: contactId }).getCreditCardUpsert({
      id: creditCard.id,
      void: true,
    });

    await upsertContact({
      variables: {
        organizationId,
        contact: upsert,
      },
    });

    setIsSaving?.(false);
  };

  const selectOnChange = (newCreditCards: CreditCard[] | undefined) => {
    if (!newCreditCards || newCreditCards.length === 0) {
      setCreditCard(undefined);
    } else {
      setCreditCard(newCreditCards[newCreditCards.length - 1]);
    }
  };

  const onClickEdit = (creditCard: CreditCard) => {
    form.setFieldsValue({ nick_name: creditCard.nick_name });
    setPrimaryChecked(creditCard.primary ?? false);
    setEditingCardId(creditCard.id);
  };

  const onCancelEditCardNote = () => {
    setEditingCardId(undefined);
  };

  const onSubmitEditCardNote = async ({
    id,
    card_type_id,
    display_number,
    card_token,
    expiry_month,
    expiry_year,
    processor_type_id,
  }: CreditCard) => {
    setIsSaving?.(true);

    const upsert = new ContactUpsertGenerator({ id: contactId }).getCreditCardUpsert({
      id,
      record: {
        card_type_id,
        display_number,
        card_token: card_token as string,
        expiry_month,
        expiry_year,
        nick_name: form.getFieldValue('nick_name') ?? '',
        processor_type_id: processor_type_id ?? 1,
        primary: primaryChecked,
      },
    });

    await upsertContact({
      variables: {
        organizationId,
        contact: upsert,
      },
    });

    setEditingCardId(undefined);
    setIsSaving?.(false);
  };

  const selectRow = (newCreditCard: CreditCard) => {
    if (creditCard && newCreditCard.id === creditCard.id) {
      setCreditCard(undefined);
    } else {
      setCreditCard(newCreditCard);
    }
  };

  const filteredCards = contactCreditCards.credit_card
    .filter(({ processor_type_id }) =>
      isStripeProcessor
        ? processor_type_id === ElectronicPaymentType.Stripe
        : processor_type_id !== ElectronicPaymentType.Stripe
    )
    .sort((cc1: CreditCard, cc2: CreditCard) => {
      if (cc1.primary) {
        return -1;
      }
      return cc2.primary ? 1 : toInteger(cc2.id) - toInteger(cc1.id);
    });

  return (
    <Form form={form}>
      {hideRowSelection ? (
        <Table size='small' dataSource={filteredCards} columns={columns} rowKey='id' pagination={{ pageSize: 5 }} />
      ) : (
        <Table
          size='small'
          dataSource={filteredCards}
          columns={columns}
          rowKey='id'
          pagination={{ pageSize: 5 }}
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            selectedRowKeys: [creditCard?.id || ''],
            onChange: (_selectedRowKeys, selectedRows) => selectOnChange(selectedRows),
          }}
        />
      )}
    </Form>
  );
};
