import { Card } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import './RegistrationStep.less';
import { isIntOrStaging } from '../../../util/checkStageUtil';
import { translations } from '../../../constants/translations';
import styled from 'styled-components';

interface RegistrationStepProps {
  icon: React.ReactNode;
  iconTitle: string;
  content: React.ReactNode;
  cardTitle: string;
}
export const RegistrationStep: React.FC<RegistrationStepProps> = ({ icon, iconTitle, cardTitle, content }) => {
  const renderTitle = (title: string) => {
    if (isIntOrStaging()) {
      return (
        <TitleText>
          {title}
          <WarinigText>{translations.registration.warningMessage}</WarinigText>
        </TitleText>
      );
    }
    return title;
  };
  return (
    <div className={'RegistrationStep'}>
      <Card className={'RegistrationStep_icon'}>
        {icon}
        <p>{iconTitle}</p>
      </Card>
      <Card className={'RegistrationStep_content'}>
        <PageHeader className={'RegistrationStep_cardTitle'} title={renderTitle(cardTitle)} />
        {content}
      </Card>
    </div>
  );
};

const TitleText = styled.div`
  font-weight: bold;
`;

const WarinigText = styled.div`
  color: red;
  white-space: break-spaces;
  font-size: 12px;
  line-height: normal;
`;
