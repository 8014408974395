import { useMemo } from 'react';
import { PracticeSettingsNameKey } from '../constants/translations';
import { getPracticeSetting } from '../pages/Contacts/ViewContact/statementUtils';
import { useGetOrganizationIdFromRoute } from './route/routeParameterHooks';
import { useUserLocaleData } from './useUserLocale';
import { useGetUserSettings } from './ajax/user/userHooks';
import { useOrganizationContext } from '../contexts/organization/state';
import { useGetReferenceData } from './ajax/referenceData/referenceDataHooks';
import { CountryId } from '../constants/countries';
import { countryCurrency } from '../constants/countryCurrencies';
import currency from 'currency.js';
import dayjs from 'dayjs';

export type DisplayCurrencyOption = {
  accountingFormat?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  chartAxis?: boolean;
};

export type CurrencyAndDateFormValues = {
  currencyCode: string;
  currencySymbol: string;
  isPrefix: boolean;
  decimalDigits: number;
  decimalSymbol: string;
  groupingSymbol: string;
  negativeSymbol: string;
  locked: boolean;
  dateFormat: string;
  rememberDate: boolean;
};

export const getShortenedValue = (number: number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'B';
  } else if (number > 1000000) {
    return (number / 1000000).toString() + 'M';
  } else if (number > 1000) {
    return (number / 1000).toString() + 'K';
  }
  return number.toString();
};

export const useCurrencyDefaultOptions = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { referenceData, dataLoading } = useGetReferenceData(organizationId);

  const currencyDefaultOptions = useMemo(() => {
    return referenceData?.ref_currency_defaults?.map((currency) => ({
      value: currency.code,
      label: `${currency.code} - ${currency.name}`,
    }));
  }, [referenceData?.ref_currency_defaults]);

  return {
    currencyDefaultOptions,
    currencyDefaultsLoading: dataLoading,
    currencyDefaults: referenceData?.ref_currency_defaults,
  };
};

export const useCurrencyAndDateSetting = (userId?: string) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice?.find(({ id }) => organization.default_practice_id === id);
  const { referenceData } = useGetReferenceData(organizationId);
  const {
    localeData: { dateFormat: defaultDateFormat },
  } = useUserLocaleData();
  const { userSettings } = useGetUserSettings(organizationId, organization?.default_practice_id, userId);

  const currencySettings = useMemo(() => {
    const practiceCurrencySettings = getPracticeSetting(PracticeSettingsNameKey.CurrencySettings, practice);
    const reportCurrencySymbolSetting = getPracticeSetting(PracticeSettingsNameKey.CurrencyDisplay, practice);
    const reportCurrencyIsPrefixSetting = getPracticeSetting(PracticeSettingsNameKey.CurrencyIsPrefix, practice);
    const dateFormatSetting = getPracticeSetting(PracticeSettingsNameKey.LocaleDate, practice);
    const practiceRememberDateSetting = getPracticeSetting(PracticeSettingsNameKey.LocaleRememberDate, practice);
    const userRememberDateSetting = userSettings?.find(
      ({ setting_name_key }) => setting_name_key === PracticeSettingsNameKey.LocaleRememberDate
    );
    const {
      currencyCode,
      currencySymbol,
      isPrefix,
      decimalDigits,
      decimalSymbol,
      groupingSymbol,
      negativeSymbol,
      locked,
    }: CurrencyAndDateFormValues = JSON.parse(practiceCurrencySettings?.value ?? '{}');

    const practiceCurrencyDefaults = referenceData?.ref_currency_defaults?.find(
      (currency) => currency.code === (currencyCode || 'USD')
    );
    const rememberDateSettingValue = userRememberDateSetting?.value ?? practiceRememberDateSetting?.value;

    const initialValues: CurrencyAndDateFormValues = {
      locked: locked ?? false,
      currencyCode: currencyCode ?? practiceCurrencyDefaults?.code,
      currencySymbol: currencySymbol ?? practiceCurrencyDefaults?.symbol,
      isPrefix: isPrefix ?? true,
      decimalDigits: decimalDigits ?? practiceCurrencyDefaults?.decimal_digits,
      decimalSymbol: decimalSymbol ?? practiceCurrencyDefaults?.decimal_symbol,
      groupingSymbol: groupingSymbol ?? practiceCurrencyDefaults?.grouping_symbol,
      negativeSymbol: negativeSymbol ?? practiceCurrencyDefaults?.negative_symbol,
      dateFormat: defaultDateFormat,
      rememberDate: rememberDateSettingValue ? JSON.parse(rememberDateSettingValue) : true,
    };

    const displayCurrency = (value: number) => {
      return currency(value, {
        symbol: currencySymbol,
        separator: groupingSymbol,
        decimal: decimalSymbol,
        precision: decimalDigits,
        pattern: isPrefix ? '! #' : '# !',
        negativePattern: negativeSymbol !== '-' ? `(! #)` : `! #`,
      }).format();
    };

    const displayDate = (date: string) => {
      return dayjs(date).format(initialValues.dateFormat);
    };

    return {
      ...initialValues,
      initialValues,
      practiceCurrencySettings,
      reportCurrencySymbolSetting,
      reportCurrencyIsPrefixSetting,
      dateFormatSetting,
      currencySettingsLocked: Boolean(locked ?? false),
      practiceRememberDateSetting,
      userRememberDateSetting,
      displayCurrency,
      displayDate,
    };
  }, [referenceData?.ref_currency_defaults, defaultDateFormat, practice, userSettings]);

  return currencySettings;
};

export const useCountryBasedCurrencyCode = () => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice?.find(({ id }) => organization.default_practice_id === id);
  const countryId = practice?.address?.[0]?.country_id;

  if (countryId === CountryId.USA || countryId === CountryId.Canada) {
    return countryCurrency[countryId];
  }

  return undefined;
};
