import React, { PropsWithChildren, ReactText } from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { Maybe } from 'graphql/jsutils/Maybe';
import { searchableSelectParams } from '../../constants/searchableSelectParams';

export interface SelectOptions {
  value: string;
  label: string;
}

export interface OptionsWithParentId extends SelectOptions {
  parentId: string;
}

export interface OptionsWithSpeciesNumber extends SelectOptions {
  speciesnumber?: Maybe<string>;
}

interface DependentDropdownProps extends PropsWithChildren<unknown> {
  options: OptionsWithParentId[];
  parentId: string;
  name: string | ReactText[];
  label: string;
  placeholder: string;
  parentPlaceholder: string;
  selectParams: typeof searchableSelectParams;
  isBillingProps?: { labelCol?: { span: number } };
  rules?: Rule[];
}

export const DependentDropdown: React.FC<DependentDropdownProps> = (props: DependentDropdownProps) => {
  const filteredOptions = props.parentId
    ? props.options
        .filter((item: OptionsWithParentId) => item.parentId === props.parentId)
        .map((item: OptionsWithParentId) => ({ value: item.value, label: item.label }))
    : [];
  const hideIfNoProvince = filteredOptions.some(({ label }) => label === 'No Province') && filteredOptions.length === 1;

  return !hideIfNoProvince ? (
    <Form.Item
      {...props.isBillingProps}
      shouldUpdate
      name={props.name}
      label={props.label}
      style={{ width: '100%' }}
      rules={props.rules}
    >
      <Select
        {...props.selectParams}
        allowClear
        options={filteredOptions}
        placeholder={props.parentId ? props.placeholder : props.parentPlaceholder}
        disabled={!props.parentId}
      />
    </Form.Item>
  ) : null;
};
