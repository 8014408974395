import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useUserContext } from '../../contexts/user/state';

export const AdminRoute: React.FC<RouteProps> = ({ component, ...props }) => {
  const {
    state: { user },
  } = useUserContext();

  return <Route {...props} component={user?.global_admin ? component : undefined} />;
};
