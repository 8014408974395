import React, { PropsWithChildren, useState } from 'react';
import { Divider } from 'antd';
import { WidthRestrictor } from '../../../../components/Restrictors/WidthRestrictor';
import { PatientRelationship } from './PatientRelationship/PatientRelationship';
import { PatientIdentification } from './PatientIdentificationForm/PatientIdentification';
import { EditPatientForm } from './EditPatientForm/EditPatientForm';
import { SaveSpinnerAndNavigationWarning } from '../../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { translations } from '../../../../constants/translations';
import { RelationshipContextProvider } from './PatientRelationship/store/state';
import { Patient, PatientReferenceDataDto } from '../../../../graph/types';
import { Loading } from '../../../../components/Loading/Loading';
import { ContactTypeId, ContactTypeNameKey } from '../../../../constants/referenceData/contactReferenceData';

interface PatientDetailsProps extends PropsWithChildren<unknown> {
  organizationId: string;
  practiceId: string;
  patientRef: PatientReferenceDataDto;
  patient: Patient;
  patientLoading: boolean;
  renderKey: number | undefined;
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
}

export const PatientDetails: React.FC<PatientDetailsProps> = ({
  organizationId,
  practiceId,
  patientRef,
  patient,
  patientLoading,
  renderKey,
  patientRefetch,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [infoHasChanged, setInfoHasChanged] = useState(false);

  if (patientLoading) {
    return <Loading />;
  }

  if (!patient) {
    return null;
  }

  const relationshipFilters = {
    relationshipId: ContactTypeId.EMR,
    relationshipName: ContactTypeNameKey.EMR,
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={formHasChanged || infoHasChanged}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.patientPage.entity)}
    >
      <WidthRestrictor>
        <EditPatientForm
          patient={patient}
          renderKey={renderKey}
          patientRef={patientRef}
          setIsSaving={setIsSaving}
          setHasChanged={setFormHasChanged}
          hasChanged={formHasChanged}
        />

        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={{ flex: 'auto', paddingRight: 20 }}>
            <Divider orientation='left' plain style={{ marginTop: '5px' }}>
              {translations.patientPage.details.relationships}
            </Divider>
            <RelationshipContextProvider>
              <PatientRelationship
                key={renderKey}
                patient={patient}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                patientRefetch={patientRefetch}
                relationshipFilters={relationshipFilters}
              />
            </RelationshipContextProvider>
          </div>
          <div style={{ flex: 'auto' }}>
            <Divider orientation='left' plain style={{ marginTop: '5px' }}>
              {translations.patientPage.details.identifications}
            </Divider>
            <PatientIdentification
              organizationId={organizationId}
              practiceId={practiceId}
              patient={patient}
              patientRef={patientRef}
              setIsSaving={setIsSaving}
              setHasChanged={setInfoHasChanged}
            />
          </div>
        </div>
      </WidthRestrictor>
    </SaveSpinnerAndNavigationWarning>
  );
};
