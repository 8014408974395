export enum CountryNameKey {
  Canada = 'CTRY_CANADA',
  USA = 'CTRY_USA',
  UAE = 'CTRY_UAE',
  ZAF = 'CTRY_ZAF',
  AFG = 'CTRY_AFG',
  ALA = 'CTRY_ALA',
  ALB = 'CTRY_ALB',
  DZA = 'CTRY_DZA',
  ASM = 'CTRY_ASM',
  AND = 'CTRY_AND',
  AGO = 'CTRY_AGO',
  AIA = 'CTRY_AIA',
  ATA = 'CTRY_ATA',
  ATG = 'CTRY_ATG',
  ARG = 'CTRY_ARG',
  ARM = 'CTRY_ARM',
  ABW = 'CTRY_ABW',
  AUS = 'CTRY_AUS',
  AUT = 'CTRY_AUT',
  AZE = 'CTRY_AZE',
  BHR = 'CTRY_BHR',
  BGD = 'CTRY_BGD',
  BRB = 'CTRY_BRB',
  BLR = 'CTRY_BLR',
  BEL = 'CTRY_BEL',
  BLZ = 'CTRY_BLZ',
  BEN = 'CTRY_BEN',
  BMU = 'CTRY_BMU',
  BTN = 'CTRY_BTN',
  BOL = 'CTRY_BOL',
  BES = 'CTRY_BES',
  BIH = 'CTRY_BIH',
  BWA = 'CTRY_BWA',
  BVT = 'CTRY_BVT',
  BRA = 'CTRY_BRA',
  IOT = 'CTRY_IOT',
  BRN = 'CTRY_BRN',
  BGR = 'CTRY_BGR',
  BFA = 'CTRY_BFA',
  BDI = 'CTRY_BDI',
  KHM = 'CTRY_KHM',
  CMR = 'CTRY_CMR',
  CPV = 'CTRY_CPV',
  CYM = 'CTRY_CYM',
  CAF = 'CTRY_CAF',
  TCD = 'CTRY_TCD',
  CHL = 'CTRY_CHL',
  CHN = 'CTRY_CHN',
  CXR = 'CTRY_CXR',
  CCK = 'CTRY_CCK',
  COL = 'CTRY_COL',
  COM = 'CTRY_COM',
  COG = 'CTRY_COG',
  COK = 'CTRY_COK',
  CRI = 'CTRY_CRI',
  CIV = 'CTRY_CIV',
  HRV = 'CTRY_HRV',
  CUB = 'CTRY_CUB',
  CUW = 'CTRY_CUW',
  CYP = 'CTRY_CYP',
  CZE = 'CTRY_CZE',
  COD = 'CTRY_COD',
  DNK = 'CTRY_DNK',
  DJI = 'CTRY_DJI',
  DMA = 'CTRY_DMA',
  DOM = 'CTRY_DOM',
  TLS = 'CTRY_TLS',
  ECU = 'CTRY_ECU',
  EGY = 'CTRY_EGY',
  SLV = 'CTRY_SLV',
  GNQ = 'CTRY_GNQ',
  ERI = 'CTRY_ERI',
  EST = 'CTRY_EST',
  ETH = 'CTRY_ETH',
  FLK = 'CTRY_FLK',
  FRO = 'CTRY_FRO',
  FJI = 'CTRY_FJI',
  FIN = 'CTRY_FIN',
  FRA = 'CTRY_FRA',
  GUF = 'CTRY_GUF',
  PYF = 'CTRY_PYF',
  ATF = 'CTRY_ATF',
  GAB = 'CTRY_GAB',
  GMB = 'CTRY_GMB',
  GEO = 'CTRY_GEO',
  DEU = 'CTRY_DEU',
  GHA = 'CTRY_GHA',
  GIB = 'CTRY_GIB',
  GRC = 'CTRY_GRC',
  GRL = 'CTRY_GRL',
  GRD = 'CTRY_GRD',
  GLP = 'CTRY_GLP',
  GUM = 'CTRY_GUM',
  GTM = 'CTRY_GTM',
  GGY = 'CTRY_GGY',
  GIN = 'CTRY_GIN',
  GNB = 'CTRY_GNB',
  GUY = 'CTRY_GUY',
  HTI = 'CTRY_HTI',
  HMD = 'CTRY_HMD',
  HND = 'CTRY_HND',
  HKG = 'CTRY_HKG',
  HUN = 'CTRY_HUN',
  ISL = 'CTRY_ISL',
  IND = 'CTRY_IND',
  IDN = 'CTRY_IDN',
  IRN = 'CTRY_IRN',
  IRQ = 'CTRY_IRQ',
  IRL = 'CTRY_IRL',
  ISR = 'CTRY_ISR',
  ITA = 'CTRY_ITA',
  JAM = 'CTRY_JAM',
  JPN = 'CTRY_JPN',
  JEY = 'CTRY_JEY',
  JOR = 'CTRY_JOR',
  KAZ = 'CTRY_KAZ',
  KEN = 'CTRY_KEN',
  KIR = 'CTRY_KIR',
  XKX = 'CTRY_XKX',
  KWT = 'CTRY_KWT',
  KGZ = 'CTRY_KGZ',
  LAO = 'CTRY_LAO',
  LVA = 'CTRY_LVA',
  LBN = 'CTRY_LBN',
  LSO = 'CTRY_LSO',
  LBR = 'CTRY_LBR',
  LBY = 'CTRY_LBY',
  LIE = 'CTRY_LIE',
  LTU = 'CTRY_LTU',
  LUX = 'CTRY_LUX',
  MAC = 'CTRY_MAC',
  MKD = 'CTRY_MKD',
  MDG = 'CTRY_MDG',
  MWI = 'CTRY_MWI',
  MYS = 'CTRY_MYS',
  MDV = 'CTRY_MDV',
  MLI = 'CTRY_MLI',
  MLT = 'CTRY_MLT',
  IMN = 'CTRY_IMN',
  MHL = 'CTRY_MHL',
  MTQ = 'CTRY_MTQ',
  MRT = 'CTRY_MRT',
  MUS = 'CTRY_MUS',
  MYT = 'CTRY_MYT',
  MEX = 'CTRY_MEX',
  FSM = 'CTRY_FSM',
  MDA = 'CTRY_MDA',
  MCO = 'CTRY_MCO',
  MNG = 'CTRY_MNG',
  MNE = 'CTRY_MNE',
  MSR = 'CTRY_MSR',
  MAR = 'CTRY_MAR',
  MOZ = 'CTRY_MOZ',
  MMR = 'CTRY_MMR',
  NAM = 'CTRY_NAM',
  NRU = 'CTRY_NRU',
  NPL = 'CTRY_NPL',
  NLD = 'CTRY_NLD',
  NCL = 'CTRY_NCL',
  NZL = 'CTRY_NZL',
  NIC = 'CTRY_NIC',
  NER = 'CTRY_NER',
  NGA = 'CTRY_NGA',
  NIU = 'CTRY_NIU',
  NFK = 'CTRY_NFK',
  PRK = 'CTRY_PRK',
  MNP = 'CTRY_MNP',
  NOR = 'CTRY_NOR',
  OMN = 'CTRY_OMN',
  PAK = 'CTRY_PAK',
  PLW = 'CTRY_PLW',
  PSE = 'CTRY_PSE',
  PAN = 'CTRY_PAN',
  PNG = 'CTRY_PNG',
  PRY = 'CTRY_PRY',
  PER = 'CTRY_PER',
  PHL = 'CTRY_PHL',
  PCN = 'CTRY_PCN',
  POL = 'CTRY_POL',
  PRT = 'CTRY_PRT',
  PRI = 'CTRY_PRI',
  QAT = 'CTRY_QAT',
  REU = 'CTRY_REU',
  ROU = 'CTRY_ROU',
  RUS = 'CTRY_RUS',
  RWA = 'CTRY_RWA',
  SHN = 'CTRY_SHN',
  KNA = 'CTRY_KNA',
  LCA = 'CTRY_LCA',
  SPM = 'CTRY_SPM',
  VCT = 'CTRY_VCT',
  BLM = 'CTRY_BLM',
  MAF = 'CTRY_MAF',
  WSM = 'CTRY_WSM',
  SMR = 'CTRY_SMR',
  STP = 'CTRY_STP',
  SAU = 'CTRY_SAU',
  SEN = 'CTRY_SEN',
  SRB = 'CTRY_SRB',
  SYC = 'CTRY_SYC',
  SLE = 'CTRY_SLE',
  SGP = 'CTRY_SGP',
  SXM = 'CTRY_SXM',
  SVK = 'CTRY_SVK',
  SVN = 'CTRY_SVN',
  SLB = 'CTRY_SLB',
  SOM = 'CTRY_SOM',
  SGS = 'CTRY_SGS',
  KOR = 'CTRY_KOR',
  SSD = 'CTRY_SSD',
  ESP = 'CTRY_ESP',
  LKA = 'CTRY_LKA',
  SDN = 'CTRY_SDN',
  SUR = 'CTRY_SUR',
  SJM = 'CTRY_SJM',
  SWZ = 'CTRY_SWZ',
  SWE = 'CTRY_SWE',
  CHE = 'CTRY_CHE',
  SYR = 'CTRY_SYR',
  TWN = 'CTRY_TWN',
  TJK = 'CTRY_TJK',
  TZA = 'CTRY_TZA',
  THA = 'CTRY_THA',
  BHS = 'CTRY_BHS',
  TGO = 'CTRY_TGO',
  TKL = 'CTRY_TKL',
  TON = 'CTRY_TON',
  TTO = 'CTRY_TTO',
  TUN = 'CTRY_TUN',
  TUR = 'CTRY_TUR',
  TKM = 'CTRY_TKM',
  TCA = 'CTRY_TCA',
  TUV = 'CTRY_TUV',
  UGA = 'CTRY_UGA',
  UKR = 'CTRY_UKR',
  GBR = 'CTRY_GBR',
  UMI = 'CTRY_UMI',
  URY = 'CTRY_URY',
  UZB = 'CTRY_UZB',
  VUT = 'CTRY_VUT',
  VAT = 'CTRY_VAT',
  VEN = 'CTRY_VEN',
  VNM = 'CTRY_VNM',
  VGB = 'CTRY_VGB',
  VIR = 'CTRY_VIR',
  WLF = 'CTRY_WLF',
  ESH = 'CTRY_ESH',
  YEM = 'CTRY_YEM',
  ZMB = 'CTRY_ZMB',
  ZWE = 'CTRY_ZWE',
}

export enum CountryId {
  Canada = '1',
  USA = '2',
  UAE = '3',
  ZAF = '4',
  AFG = '5',
  ALA = '6',
  ALB = '7',
  DZA = '8',
  ASM = '9',
  AND = '10',
  AGO = '11',
  AIA = '12',
  ATA = '13',
  ATG = '14',
  ARG = '15',
  ARM = '16',
  ABW = '17',
  AUS = '18',
  AUT = '19',
  AZE = '20',
  BHR = '21',
  BGD = '22',
  BRB = '23',
  BLR = '24',
  BEL = '25',
  BLZ = '26',
  BEN = '27',
  BMU = '28',
  BTN = '29',
  BOL = '30',
  BES = '31',
  BIH = '32',
  BWA = '33',
  BVT = '34',
  BRA = '35',
  IOT = '36',
  BRN = '37',
  BGR = '38',
  BFA = '39',
  BDI = '40',
  KHM = '41',
  CMR = '42',
  CPV = '44',
  CYM = '45',
  CAF = '46',
  TCD = '47',
  CHL = '48',
  CHN = '49',
  CXR = '50',
  CCK = '51',
  COL = '52',
  COM = '53',
  COG = '54',
  COK = '55',
  CRI = '56',
  CIV = '57',
  HRV = '58',
  CUB = '59',
  CUW = '60',
  CYP = '61',
  CZE = '62',
  COD = '63',
  DNK = '64',
  DJI = '65',
  DMA = '66',
  DOM = '67',
  TLS = '68',
  ECU = '69',
  EGY = '70',
  SLV = '71',
  GNQ = '72',
  ERI = '73',
  EST = '74',
  ETH = '75',
  FLK = '76',
  FRO = '77',
  FJI = '78',
  FIN = '79',
  FRA = '80',
  GUF = '81',
  PYF = '82',
  ATF = '83',
  GAB = '84',
  GMB = '85',
  GEO = '86',
  DEU = '87',
  GHA = '88',
  GIB = '89',
  GRC = '90',
  GRL = '91',
  GRD = '92',
  GLP = '93',
  GUM = '94',
  GTM = '95',
  GGY = '96',
  GIN = '97',
  GNB = '98',
  GUY = '99',
  HTI = '100',
  HMD = '101',
  HND = '102',
  HKG = '103',
  HUN = '104',
  ISL = '105',
  IND = '106',
  IDN = '107',
  IRN = '108',
  IRQ = '109',
  IRL = '110',
  ISR = '111',
  ITA = '112',
  JAM = '113',
  JPN = '114',
  JEY = '115',
  JOR = '116',
  KAZ = '117',
  KEN = '118',
  KIR = '119',
  XKX = '120',
  KWT = '121',
  KGZ = '122',
  LAO = '123',
  LVA = '124',
  LBN = '125',
  LSO = '126',
  LBR = '127',
  LBY = '128',
  LIE = '129',
  LTU = '130',
  LUX = '131',
  MAC = '132',
  MKD = '133',
  MDG = '134',
  MWI = '135',
  MYS = '136',
  MDV = '137',
  MLI = '138',
  MLT = '139',
  IMN = '140',
  MHL = '141',
  MTQ = '142',
  MRT = '143',
  MUS = '144',
  MYT = '145',
  MEX = '146',
  FSM = '147',
  MDA = '148',
  MCO = '149',
  MNG = '150',
  MNE = '151',
  MSR = '152',
  MAR = '153',
  MOZ = '154',
  MMR = '155',
  NAM = '156',
  NRU = '157',
  NPL = '158',
  NLD = '159',
  NCL = '160',
  NZL = '161',
  NIC = '162',
  NER = '163',
  NGA = '164',
  NIU = '165',
  NFK = '166',
  PRK = '167',
  MNP = '168',
  NOR = '169',
  OMN = '170',
  PAK = '171',
  PLW = '172',
  PSE = '173',
  PAN = '174',
  PNG = '175',
  PRY = '176',
  PER = '177',
  PHL = '178',
  PCN = '179',
  POL = '180',
  PRT = '181',
  PRI = '182',
  QAT = '183',
  REU = '184',
  ROU = '185',
  RUS = '186',
  RWA = '187',
  SHN = '188',
  KNA = '189',
  LCA = '190',
  SPM = '191',
  VCT = '192',
  BLM = '193',
  MAF = '194',
  WSM = '195',
  SMR = '196',
  STP = '197',
  SAU = '198',
  SEN = '199',
  SRB = '200',
  SYC = '201',
  SLE = '202',
  SGP = '203',
  SXM = '204',
  SVK = '205',
  SVN = '206',
  SLB = '207',
  SOM = '208',
  SGS = '210',
  KOR = '211',
  SSD = '212',
  ESP = '213',
  LKA = '214',
  SDN = '215',
  SUR = '216',
  SJM = '217',
  SWZ = '218',
  SWE = '219',
  CHE = '220',
  SYR = '221',
  TWN = '222',
  TJK = '223',
  TZA = '224',
  THA = '225',
  BHS = '226',
  TGO = '227',
  TKL = '228',
  TON = '229',
  TTO = '230',
  TUN = '231',
  TUR = '232',
  TKM = '233',
  TCA = '234',
  TUV = '235',
  UGA = '236',
  UKR = '237',
  GBR = '239',
  UMI = '241',
  URY = '242',
  UZB = '243',
  VUT = '244',
  VAT = '245',
  VEN = '246',
  VNM = '247',
  VGB = '248',
  VIR = '249',
  WLF = '250',
  ESH = '251',
  YEM = '252',
  ZMB = '253',
  ZWE = '254',
}
