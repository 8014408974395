import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { PushpinTwoTone } from '@ant-design/icons';
import { LedgerInfoType } from '../../constants/referenceData/ledgerReferenceData';
import { Ledger } from '../../graph/types';
import { getLedgerInfoValue } from '../PaymentModal/PaymentModal/PaymentModal';

interface PayerPopoverProps {
  ledger: Ledger;
  onClick?: () => void;
}
export const pushpinTestId = 'pushpinTestId';

export const PayerPopover: React.FC<PayerPopoverProps> = ({ ledger, onClick }) => {
  const [payer, setPayer] = useState<string>();
  useEffect(() => {
    setPayer(getLedgerInfoValue(LedgerInfoType.LGR_PAYER, ledger));
  }, [ledger]);
  return (
    <Popover destroyTooltipOnHide content={payer}>
      {payer ? <PushpinTwoTone onClick={onClick} data-testid={pushpinTestId} /> : <></>}
    </Popover>
  );
};
