import {
  FetchResult,
  MutationFunctionOptions,
  QueryHookOptions,
  Reference,
  useMutation,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { formats } from '../../../constants/formats';
import {
  GetContactPatients,
  GetHISAPatients,
  GetPatient,
  GetPatientCurrentOwnershipAndRelationship,
  GetPatientInfo,
  GetPatients,
  GetPatientsForDropdown,
  SyncPatient,
  UpsertPatient,
} from '../../../graph/queries/patients';
import {
  ElasticSourceOptions,
  Maybe,
  Mutation,
  MutationUpsertPatientArgs,
  OrganizationDto,
  Patient,
  PatientUpsert,
  ServicesRenderedFilter,
  Subscription,
  SubscriptionOnPatientUpsertArgs,
} from '../../../graph/types';
import { PatientFields } from '../../../graph/fragments/patientFragments';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { GetServiceActivities, GetServicesRendered } from '../../../graph/queries/services';
import { OnPatientUpsert } from '../../../graph/subscription/patient';
import { getPatientInsert, getPatientUpdate } from '../../../services/LocalDatabaseService/queries/patientQueries';
import { useOfflineInsert, useOfflineUpdate, useOfflineList } from '../../localDatabaseHooks';
import {
  useGetOfflineSelector,
  useOfflineQuery,
  useOfflineQueryWithBackendSearch,
  useQueryWithElasticSearch,
} from '../useOfflineQuery';
import { useOffline } from '../../../util/offline/offlineUtil';
import { RxPatient } from '../../../services/LocalDatabaseService/schemas/patientSchema';
import { onPatientUpdate } from '../../../util/cacheUtil';
import { useOfflineErrorSkipLazyQuery, useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import { DEBOUNCE_TIME_FOR_SEARCH } from '../../../constants/queryConstants';
import { filterByDocument } from '../../../util/searchUtil';
import escapeRegExp from 'lodash/escapeRegExp';
import { useSubscription } from '../subscription/subscriptionHooks';
import dayjs from 'dayjs';
import { isEqual } from '../../../util/objectComparisons';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { useGetCurrentUser } from '../user/userHooks';
import { useGetConnectionId } from '../../authHooks';

const patientSearchKeys: (keyof Patient)[] = [
  'name',
  'number',
  'species_name',
  'breed_name',
  'gender_name',
  'owner_names',
  'related_names',
];
const patientSearch = (searchTerm: string) => (patient: Patient) => {
  let match = false;
  const regex = new RegExp(escapeRegExp(searchTerm), 'i');
  patientSearchKeys.forEach((key) => {
    if (patient[key]?.toString().match(regex)) {
      match = true;
    }
  });

  return match;
};

const source: ElasticSourceOptions = {
  excludes: [
    'document.default_bill_to_id',
    'document.related_all',
    'organization_id',
    'document.related_current',
    'document.created_practice_id',
    'document.ownership_all',
  ],
};

const patientSearchFields = [
  'document.name',
  'document.name_2',
  'document.name_3',
  'document.number',
  'document.species_name',
  'document.breed_name',
  'document.gender_name',
  'document.owner_names',
  'document.related_names',
];

export const useGetPatientsWithSearch = (organizationId: string, searchValue = '', isForDropdown = false) => {
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const [searchTerm, setSearchTerm] = useState<string>(searchValue);
  const { enabledAndOffline } = useOffline();
  const searchParams = useMemo(
    () => ({
      fields: patientSearchFields,
      query: searchTerm,
    }),
    [searchTerm]
  );

  const { data, loading, refetch } = useQueryWithElasticSearch<'getPatientsEs'>({
    query: isForDropdown ? GetPatientsForDropdown : GetPatients,
    collection: 'patient',
    variables,
    searchParams,
    source,
  });

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  const patients = useMemo(() => {
    if (enabledAndOffline && searchTerm) {
      return data?.filter(filterByDocument(searchTerm));
    }
    return data;
  }, [data, enabledAndOffline, searchTerm]);

  return {
    patients,
    patientsLoading: loading,
    refetchPatients: refetch,
    setSearchTerm,
  };
};

export const useGetContactPatientsWithSearch = (
  contactId: string,
  organizationId: string,
  searchValue?: string,
  options?: {
    fetchPolicy?: WatchQueryFetchPolicy;
  }
) => {
  const variables = useMemo(() => ({ organizationId, contactId }), [organizationId, contactId]);
  const queryOptions = useMemo(() => options ?? { fetchPolicy: 'cache-first' as WatchQueryFetchPolicy }, [options]);
  const selector = useMemo(
    () => ({
      $or: [
        {
          related_ids: {
            $elemMatch: contactId,
          },
        },
        {
          owner_ids: {
            $elemMatch: contactId,
          },
        },
      ],
    }),
    [contactId]
  );

  const [searchTerm, setSearchTerm] = useState<string>(searchValue ?? '');
  const [patients, setPatients] = useState<Patient[]>([]);

  const { data, loading, refetch } = useOfflineQuery<'getContactPatientsEs'>({
    query: GetContactPatients,
    collection: 'patient',
    options: {
      ...queryOptions,
      variables,
    },
    selector,
    expectList: true,
  });

  useEffect(() => setSearchTerm(searchValue ?? ''), [searchValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      let filteredData = data ?? [];
      if (searchTerm) {
        filteredData = (data ?? []).filter(patientSearch(searchTerm));
      }

      if (!isEqual(patients, filteredData)) {
        setPatients(filteredData);
      }
    }, DEBOUNCE_TIME_FOR_SEARCH);

    return () => clearTimeout(timer);
  }, [searchTerm, data, patients]);

  return {
    patients,
    patientsLoading: loading,
    setSearchTerm,
    refetchCurrentSearch: refetch,
  };
};

export const GetPatientWith = {
  all: GetPatient,
  info: GetPatientInfo,
};

export const useGetPatient = (
  patientId: string,
  organizationId: string,
  {
    additionalPatientField = 'all',
    skip,
    fetchPolicy,
  }: {
    additionalPatientField?: keyof typeof GetPatientWith;
    skip?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
  } = {}
) => {
  const selector = useGetOfflineSelector(patientId);

  const { data, loading, error, refetch, renderKey } = useOfflineQuery<'getPatientEs'>({
    query: GetPatientWith[additionalPatientField],
    collection: 'patient',
    options: {
      variables: {
        patientId,
        organizationId,
      },
      skip: skip || !patientId || !organizationId,
      fetchPolicy,
    },
    selector,
  });

  if (data?.hidden) {
    showErrorMessage(translations.patientPage.unknownPatient);
    return {
      patient: null,
    };
  }

  return {
    patient: data,
    patientLoading: loading,
    patientError: error,
    patientRefetch: refetch,
    renderKey,
  };
};

export const useGetPatientCurrentOwnershipAndRelationship = (patientId: string, organizationId: string) => {
  const selector = useMemo(() => ({ _id: patientId }), [patientId]);
  const { data, loading, error } = useOfflineQuery<'getPatientEs'>({
    query: GetPatientCurrentOwnershipAndRelationship,
    collection: 'patient',
    options: {
      variables: { patientId, organizationId },
      skip: !patientId || !organizationId,
      onError: (error: { message: any }) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
    },
    selector,
  });

  return {
    patient: data,
    patientLoading: loading,
    patientError: error,
  };
};

export const useGetPatientServicesForDay = ({
  patientId,
  organizationId,
  date,
}: {
  patientId: string;
  organizationId: string;
  date: dayjs.Dayjs;
}) => {
  const filter: ServicesRenderedFilter = useMemo(
    () => ({
      date: date.format(formats.upsertDate),
      patientId,
    }),
    [patientId, date]
  );

  const selector = useMemo(() => ({ patient_id: patientId, date: date.format(formats.upsertDate) }), [patientId, date]);

  const options = useMemo(
    () => ({
      variables: { organizationId, patientId, filter },
      queryOptions: {
        skip: organizationId === '',
        fetchPolicy: 'network-only',
      },
    }),
    [organizationId, patientId, filter]
  );
  const { data, loading } = useOfflineQuery<'getServicesRendered'>({
    query: GetServicesRendered,
    collection: 'service_rendered',
    options,
    selector,
    expectList: true,
  });

  return {
    serviceRecords: data,
    serviceRecordsLoading: loading,
  };
};

export const useInsertPatient = () => {
  const { user } = useGetCurrentUser();
  return useMutation<Mutation, MutationUpsertPatientArgs>(UpsertPatient, {
    update(cache, { data }) {
      onPatientUpdate(user.id)(cache, { data });
      const upsertedPatient = data?.upsertPatient;
      cache.modify({
        fields: {
          getPatientsEs(patientReferences: Reference[] = []) {
            const newPatientReference = cache.writeFragment({
              data: upsertedPatient,
              fragment: PatientFields,
              fragmentName: 'PatientFields',
            });
            return [newPatientReference, ...patientReferences];
          },
        },
      });
    },
  });
};

const upsertPatient =
  (
    upsertPatientMutation: (
      options?: MutationFunctionOptions<Pick<Mutation, 'upsertPatient'>, MutationUpsertPatientArgs>
    ) => Promise<FetchResult<Pick<Mutation, 'upsertPatient'>>>,
    connectionId: string
  ) =>
  (options: MutationFunctionOptions<Pick<Mutation, 'upsertPatient'>, MutationUpsertPatientArgs>) => {
    const upsertWithConnectionId: MutationFunctionOptions<
      Pick<Mutation, 'upsertPatient'>,
      MutationUpsertPatientArgs
    > = {
      ...options,
      onError: (e) => showErrorMessage(e.message || translations.shared.saveErrorMessage),
      variables: {
        ...options?.variables,
        patient: {
          ...options?.variables?.patient,
          connection_id: connectionId,
        },
      } as MutationUpsertPatientArgs,
    };

    return upsertPatientMutation(upsertWithConnectionId);
  };

export const useUpdatePatient = () => {
  const connectionId = useGetConnectionId();
  const [upsertPatientMutation] = useOfflineErrorSkipMutation<'upsertPatient', MutationUpsertPatientArgs>(
    UpsertPatient,
    {
      update: onPatientUpdate(),
    }
  );

  const returnFunction = upsertPatient(upsertPatientMutation, connectionId);
  return [returnFunction];
};

export const useUpdatePatientRelevantForDoctorOfficeCard = () => {
  const connectionId = useGetConnectionId();
  const { user } = useGetCurrentUser();
  const [upsertPatientMutation] = useOfflineErrorSkipMutation<'upsertPatient', MutationUpsertPatientArgs>(
    UpsertPatient,
    {
      update: onPatientUpdate(user.id),
    }
  );
  const returnFunction = upsertPatient(upsertPatientMutation, connectionId);
  return [returnFunction];
};

export const useUpdatePatientWithRelationshipData = () => {
  const { user } = useGetCurrentUser();
  const connectionId = useGetConnectionId();
  const [upsertPatientMutation] = useOfflineErrorSkipMutation<'upsertPatient', MutationUpsertPatientArgs>(
    UpsertPatient,
    {
      update: onPatientUpdate(user.id),
    }
  );
  const returnFunction = upsertPatient(upsertPatientMutation, connectionId);
  return [returnFunction];
};

export const useDeletePatient = (organizationId: string) => {
  const { user } = useGetCurrentUser();
  const [deletePatient] = useOfflineErrorSkipMutation<'upsertPatient', MutationUpsertPatientArgs>(UpsertPatient, {
    update: onPatientUpdate(user.id),
  });

  return useCallback(
    (patientId: string) => {
      return deletePatient({
        variables: {
          organizationId,
          patient: { id: patientId, void: true },
        },
        update: (cache: any) => {
          cache.evict({
            id: cache.identify({ __typename: 'Patient', id: patientId }),
          });
          cache.evict({ fieldName: 'getCurrentUser' });
          cache.gc();
        },
      });
    },
    [deletePatient, organizationId]
  );
};

export const useSyncPatient = () => {
  return useOfflineErrorSkipMutation<'syncPatientInvoice'>(SyncPatient);
};

export const useGetServiceActivitiesWithSearch = ({
  patientId,
  organizationId,
  searchValue,
  selector = {},
  queryOptions = {},
}: {
  patientId: string;
  organizationId: string;
  searchValue: string;
  selector?: { [key: string]: any };
  queryOptions?: QueryHookOptions;
}) => {
  const variables = useMemo(() => ({ organizationId, patientId }), [organizationId, patientId]);

  const { loading, setSearchTerm, refetchCurrentSearch, data } =
    useOfflineQueryWithBackendSearch<'getServiceActivities'>({
      query: GetServiceActivities,
      collection: 'service_activity',
      variables,
      searchOptions: { value: searchValue, keys: ['date', 'doctor_names', 'service_names'] },
      queryOptions: {
        skip: organizationId === '',
        onError: (error: any) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
        fetchPolicy: 'no-cache',
        ...queryOptions,
      },
      selector,
      shouldNotAdjustString: true,
    });

  return {
    services: data,
    servicesLoading: loading,
    setSearchTerm,
    refetchCurrentSearch,
  };
};

export const usePatientOfflineInsert = () => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const offlineInsert = useOfflineInsert<RxPatient>('patient');

  if (!organization || !offlineInsert) {
    return undefined;
  }

  return async (upsert: PatientUpsert) => {
    const obj = await getPatientInsert(upsert, organization);
    return (await offlineInsert(obj)) as unknown as Patient;
  };
};

export const usePatientOfflineUpdate = (patientId: string, patientOfflineId?: Maybe<string>) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const patient = useOfflinePatient(patientId, patientOfflineId);
  const offlineUpdate = useOfflineUpdate<RxPatient>('patient');

  if (!organization || !patient || !offlineUpdate) {
    return undefined;
  }

  return async (upsert: PatientUpsert) => {
    const obj = await getPatientUpdate(patient, upsert, organization);
    return (await offlineUpdate(patient.id, obj)) as unknown as Patient;
  };
};

export const usePatientOfflineUpdateForRapidBilling = (organization: OrganizationDto | undefined) => {
  const offlineUpdate = useOfflineUpdate<RxPatient>('patient');
  const patientCollection = useRxCollection<RxPatient>('patient');

  if (!organization || !offlineUpdate) {
    return undefined;
  }
  return async (upsert: PatientUpsert) => {
    if (!upsert.id) {
      return showErrorMessage(translations.shared.noResults);
    }
    const patient = await patientCollection?.findOne(upsert.id).exec();
    if (!patient) {
      return showErrorMessage(translations.shared.noResults);
    }

    const obj = await getPatientUpdate(patient, upsert, organization);
    return (await offlineUpdate(patient.id, obj)) as unknown as Patient;
  };
};

export const useOfflinePatient = (id: string, offlineId?: Maybe<string>) => {
  const [patient, setPatient] = useState<RxPatient>();
  const patientCollection = useRxCollection<RxPatient>('patient');

  useEffect(() => {
    if (!patientCollection) {
      return;
    }

    const selector: Record<string, string | null> = { _id: id };
    if (offlineId) {
      selector.offline_id = offlineId;
    }
    patientCollection.findOne({ selector }).$.subscribe((p: RxPatient | null) => {
      if (p) {
        setPatient(p);
      }
    });
  }, [id, offlineId, patientCollection]);

  return patient;
};

export const usePatientOfflineList = () => {
  return useOfflineList<RxPatient>('patient');
};

export const usePatientUpsertSubscription = (patientId: string) => {
  const { data, loading, error } = useSubscription<Subscription, SubscriptionOnPatientUpsertArgs>(OnPatientUpsert, {
    variables: { id: patientId },
    skip: !patientId,
  });
  return { record: data?.onPatientUpsert, loading, error };
};

export const useGetHISAPatientsWithSearch = (
  organizationId: string,
  practiceId: string,
  searchValue = '',
  skip = false
) => {
  const searchParams = useMemo(() => ({ patientNames: [searchValue] ?? [''] }), [searchValue]);
  const variables = useMemo(
    () => ({ organizationId, practiceId, filter: searchParams }),
    [organizationId, practiceId, searchParams]
  );

  const [getHisaPatients, { data, loading, error }] = useOfflineErrorSkipLazyQuery<'getHISAPatients'>(GetHISAPatients, {
    variables,
    fetchPolicy: 'cache-first',
    skip,
  });

  return {
    getHisaPatients,
    hisaPatients: data?.getHISAPatients,
    hisaPatientsLoading: loading,
    hisaPatientsError: error,
  };
};
