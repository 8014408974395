import React from 'react';
import { translations } from '../constants/translations';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export type ConfirmModalProps = {
  title?: string;
  okCallback?: () => void;
  cancelCallback?: () => void;
};

export function showConfirm({ title, okCallback, cancelCallback }: ConfirmModalProps) {
  Modal.confirm({
    title: title ?? translations.navigation.unsaved,
    icon: <ExclamationCircleOutlined />,
    content: '',
    onOk() {
      okCallback?.();
    },
    onCancel() {
      cancelCallback?.();
    },
  });
}
