import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { PracticeSettingCategorykey, PracticeSettingsNameKey, translations } from '../../../constants/translations';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import {
  Contact,
  OrganizationDto,
  Practice,
  PracticeDto,
  Query,
  QueryGetStatementReportArgs,
  QueryRptGetStatementsArgs,
} from '../../../graph/types';
import { GetStatementReport as GetStatementReportForActiveReports } from '../../../graph/queries/reports';
import { GetStatementReport } from '../../../graph/queries/statements';
import { Attachment } from '../../../components/EmailModal/EmailModal';
import { GetPracticeSettings } from '../../../graph/queries/practices';
import { useGetPracticeWithSettings } from '../../../hooks/ajax/practice/practiceHooks';
import { getActiveReport } from '../../../util/activeReportUtils';
import { fetchS3UploadUrl } from '../../../util/fileUtils';
import { Reports } from '../../../util/reportUtils';
import { openTabWithPopupDetection } from '../../../util/popupUtil';

export const useGetPracticeSettings = (
  organizationId: string,
  practiceId: string,
  settingKey: PracticeSettingsNameKey
) => {
  const { practice, practiceLoading, refetch } = useGetPracticeWithSettings(organizationId, practiceId);
  return { settings: getPracticeSetting(settingKey, practice), settingsLoading: practiceLoading, refetch };
};

export const useGetPracticeSettingsByCategory = (
  organizationId: string,
  practiceId: string,
  categoryKeys: PracticeSettingCategorykey[]
) => {
  const { practice, practiceLoading, refetch } = useGetPracticeWithSettings(organizationId, practiceId);
  return {
    settings: getPracticeSettingGroupByCategory(categoryKeys, practice),
    settingsLoading: practiceLoading,
    refetch,
  };
};

export const getPracticeSetting = (
  settingKey: PracticeSettingsNameKey,
  practice: PracticeDto | Practice | undefined
) => {
  return (practice?.setting as any)?.find(
    ({ setting_name_key }: { setting_name_key: string }) => setting_name_key === settingKey
  );
};

export const getOrganizationSetting = (
  settingKey: PracticeSettingsNameKey,
  organization: OrganizationDto | undefined
) => {
  return organization?.setting?.find(({ setting_name_key }) => setting_name_key === settingKey);
};

export const getPracticeSettingGroupByCategory = (
  categoryKeys: PracticeSettingCategorykey[],
  practice: Practice | undefined
) => {
  return practice?.setting.filter(({ setting_category_key }) =>
    categoryKeys.includes(setting_category_key as PracticeSettingCategorykey)
  );
};

export const getPracticeFooter = async (organizationId: string, practiceId: string) => {
  const { data } = await AppSyncService.client.query<Pick<Query, 'getPractice'>>({
    query: GetPracticeSettings,
    variables: { organizationId, practiceId },
  });

  return getPracticeSetting(PracticeSettingsNameKey.StatementFooter, data?.getPractice)?.value;
};

export const getStatementReportVariables = async (
  contact: Contact,
  startDate: string,
  endDate: string,
  practiceId: string,
  organizationId: string,
  returnPreSignedUrl = true
) => {
  const footer = await getPracticeFooter(organizationId, practiceId);
  return {
    contactId: [contact.id],
    startDate,
    endDate,
    footer,
    request: {
      organizationId,
      practiceId,
      fileName: getFileNameForContactStatements(contact.number, startDate, endDate),
      returnPreSignedUrl: returnPreSignedUrl ? 'true' : 'false',
    },
  };
};

export const getFileNameForContactStatements = (
  contactNumber?: string | null,
  startDate?: string | null,
  endDate?: string | null
) => `Statement_${contactNumber}_${startDate}_${endDate}.pdf`;

export const getStatementReportVariablesMultipleContacts = async (
  contactIds: string[],
  startDate: string,
  endDate: string,
  practiceId: string,
  organizationId: string
) => {
  const footer = await getPracticeFooter(organizationId, practiceId);
  return {
    contactId: contactIds,
    startDate,
    endDate,
    footer,
    request: {
      organizationId,
      practiceId,
      fileName: `Statement_${startDate}_${endDate}.pdf`,
      returnPreSignedUrl: 'true',
    },
  };
};

export const printStatementReport = async (variables: QueryGetStatementReportArgs, onSuccess?: () => void) => {
  await getStatementReportUrl(variables, (url) => {
    openTabWithPopupDetection(url, translations.popupDetectionMessage.printReport);
    onSuccess?.();
  });
};

export const generateStatementReportAttachment = async (
  variables: QueryRptGetStatementsArgs,
  fileName: string,
  displayCurrency: (value: number) => string,
  displayDate: (value: string) => string
) => {
  const { data } = await AppSyncService.client.query({
    query: GetStatementReportForActiveReports,
    variables,
    fetchPolicy: 'no-cache',
  });

  const reportName = Reports.Statement;
  const reportBlob = await getActiveReport({
    reportName,
    data,
    nestedHeader: true,
    displayCurrency,
    displayDate,
  });
  if (!reportBlob) {
    return undefined;
  }

  const uploadUrl = await fetchS3UploadUrl(variables.organizationId);
  await fetch(uploadUrl.url, { method: 'PUT', body: new File([reportBlob], fileName) });

  return {
    file_pointer: uploadUrl.filePointer,
    file_name: fileName,
  };
};

export const getStatementReportAsEmailAttachment = async (variables: QueryGetStatementReportArgs) => {
  let attachment: Attachment | undefined = undefined;
  await getStatementReportUrl(variables, (url) => {
    attachment = {
      file_pointer: url,
      file_name: variables.request.fileName ?? '',
    };
  });
  return attachment;
};

export const getStatementReportUrl = async (
  variables: QueryGetStatementReportArgs,
  onSuccess: (url: string) => void
) => {
  try {
    const { data } = await AppSyncService.client.query({
      query: GetStatementReport,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.getStatementReport?.pointer) {
      const pointer = data.getStatementReport.pointer;

      onSuccess(pointer);
    } else {
      showErrorMessage(translations.statementReport.errorGeneratingReport);
    }
  } catch (err) {
    showErrorMessage((err as Error).message ?? translations.statementReport.errorGeneratingReport);
  }
};
