import gql from 'graphql-tag';
import { InvoiceContactPaymentFields, InvoiceContextFields, ServiceListViewFields } from '../fragments';
import {
  BasicSingleInvoiceFields,
  InvoiceFieldsForServiceNotes,
  InvoiceListFields,
  SingleInvoiceFields,
  InvoiceOfflineFields,
} from '../fragments/invoiceFragments';

export const GetInvoices = gql`
  query getInvoices($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $queryString: ElasticQueryString) {
    getInvoicesEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      queryString: $queryString
      source: { excludes: ["document.item"] }
    ) {
      ...InvoiceListFields
    }
  }
  ${InvoiceListFields}
`;

export const GetInvoicesForOffline = gql`
  query getInvoicesOffline($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $searchAfter: [ID!]!) {
    getInvoicesEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      searchAfter: $searchAfter
      returnHidden: true
    ) {
      ...InvoiceOfflineFields
    }
  }
  ${InvoiceOfflineFields}
`;

export const UpsertNewInvoice = gql`
  mutation upsertInvoice($organizationId: ID!, $invoice: InvoiceUpsert!) {
    upsertInvoice(organizationId: $organizationId, invoice: $invoice) {
      ...InvoiceListFields
    }
  }
  ${InvoiceListFields}
`;

export const GetInvoice = gql`
  query getInvoice($organizationId: ID!, $filter: QueryInvoiceFilter!) {
    getInvoicesPg(organizationId: $organizationId, filter: $filter) {
      ...SingleInvoiceFields
      contact {
        ...InvoiceContactPaymentFields
      }
    }
  }
  ${SingleInvoiceFields}
  ${InvoiceContactPaymentFields}
`;

export const GetInvoiceWithAllContacts = gql`
  query getInvoicesWithAllContacts($organizationId: ID!, $filter: QueryInvoiceFilter!) {
    getInvoicesPg(organizationId: $organizationId, filter: $filter) {
      ...BasicSingleInvoiceFields
      contact {
        id
        contact_id
        contact_name
        contact_number
        contact_email
        primary
      }
      contact_name
    }
  }
  ${BasicSingleInvoiceFields}
`;

export const GetPaymentInvoice = gql`
  query getInvoicesPg($organizationId: ID!, $filter: QueryInvoiceFilter!) {
    getInvoicesPg(organizationId: $organizationId, filter: $filter) {
      contact {
        ...InvoiceContactPaymentFields
      }
    }
  }
  ${InvoiceContactPaymentFields}
`;

export const UpsertInvoice = gql`
  mutation upsertInvoice($organizationId: ID!, $invoice: InvoiceUpsert!) {
    upsertInvoice(organizationId: $organizationId, invoice: $invoice) {
      ...SingleInvoiceFields
      contact {
        ...InvoiceContactPaymentFields
      }
    }
  }
  ${SingleInvoiceFields}
  ${InvoiceContactPaymentFields}
`;

export const UpsertInvoiceForServiceNotes = gql`
  mutation upsertInvoice($organizationId: ID!, $invoice: InvoiceUpsert!) {
    upsertInvoice(organizationId: $organizationId, invoice: $invoice) {
      ...InvoiceFieldsForServiceNotes
    }
  }
  ${InvoiceFieldsForServiceNotes}
`;

export const GetInvoiceContext = gql`
  query getInvoiceContext($organizationId: ID!, $practiceId: ID!) {
    getInvoiceContext(organizationId: $organizationId, practiceId: $practiceId) {
      ...InvoiceContextFields
    }
  }
  ${InvoiceContextFields}
`;

export const GetInvoiceContextWithServicesRenderedWithFilter = gql`
  query getInvoiceContext($organizationId: ID!, $practiceId: ID!, $filter: QueryServiceFilter) {
    getInvoiceContext(organizationId: $organizationId, practiceId: $practiceId) {
      id
      service(filter: $filter) {
        ...ServiceListViewFields
      }
    }
  }
  ${ServiceListViewFields}
`;

export const GetInvoiceReport = gql`
  query getInvoiceReport($invoiceId: ID!, $contactId: ID!, $request: ReportRequest!, $footer: String) {
    getInvoiceReport(invoiceId: $invoiceId, contactId: $contactId, request: $request, footer: $footer) {
      pointer
      error
    }
  }
`;

export const GetInvoicesForPatient = gql`
  query getInvoicesForPatient(
    $organizationId: ID!
    $take: Int!
    $sort: ElasticQuerySort!
    $queryString: ElasticQueryString
    $searchAfter: [ID!]
  ) {
    getInvoicesEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      queryString: $queryString
      searchAfter: $searchAfter
      returnHidden: false
    ) {
      id
      number
      hidden
    }
  }
`;

export const UpsertInvoiceForBillTo = gql`
  mutation upsertInvoice($organizationId: ID!, $invoice: InvoiceUpsert!) {
    upsertInvoice(organizationId: $organizationId, invoice: $invoice) {
      ...SingleInvoiceFields
      contact {
        ...InvoiceContactPaymentFields
      }
    }
  }
  ${SingleInvoiceFields}
  ${InvoiceContactPaymentFields}
`;

export const UpsertInvoiceFooter = gql`
  mutation upsertInvoice($organizationId: ID!, $invoice: InvoiceUpsert!) {
    upsertInvoice(organizationId: $organizationId, invoice: $invoice) {
      id
      text {
        id
        type_id
        category
        name_key
      }
    }
  }
`;

export const GetInvoiceText = gql`
  query getInvoiceText($organizationId: ID!, $id: ID!) {
    getInvoiceText(organizationId: $organizationId, id: $id) {
      id
      type_id
      category
      name_key
      value
      updated
      updated_user_name
      offline_id
    }
  }
`;
