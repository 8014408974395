import { useForm } from 'antd/lib/form/Form';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { translations } from '../../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { NoteCardContent } from './NoteCardContent';
import { selectRecordAction, setHasUnsavedChangesAction } from '../../Records/store/actions';
import { RecordsContext } from '../../Records/store/state';
import { Note, NoteType } from '../../../graph/types';
import { Store } from 'antd/lib/form/interface';
import {
  getRecordFromNote,
  getUpdateNoteUpsert,
  NoteUpsertValues,
  useGetNoteRefData,
  useSendNoteUpsertToBackend,
} from './noteUtils';
import { RecordItem } from '../../Records/recordUtils';
import { getButtonRowForNewRecord } from '../ButtonRow';
import { Card, Form } from 'antd';
import dayjs from 'dayjs';
import { CloseButton } from '../../CloseButton/CloseButton';
import { formats } from '../../../constants/formats';

interface AddNoteRecordProps extends PropsWithChildren<unknown> {
  contactId?: string;
  patientId?: string;
  linkedIcon?: React.ReactNode;
  insertNewRecordInState: (recordItem: RecordItem) => void;
  setEditing?: (editing: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const AddNoteRecord: React.FC<AddNoteRecordProps> = ({
  patientId,
  contactId,
  linkedIcon,
  insertNewRecordInState,
  setEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}: AddNoteRecordProps) => {
  const {
    state: { hasUnsavedChanges },
    dispatch,
  } = useContext(RecordsContext);

  const [form] = useForm();
  const [textContent, setTextContent] = useState('');
  const [plainTextContent, setPlainTextContent] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const sendNoteUpsertToBackend = useSendNoteUpsertToBackend({ patientId, contactId });
  const { noteRefData } = useGetNoteRefData(!!patientId);

  const onCancel = () => {
    resetPage();
  };

  useEffect(() => {
    setEditing?.(hasUnsavedChanges);
  }, [hasUnsavedChanges, setEditing]);

  const resetPage = useCallback(() => {
    dispatch(selectRecordAction(undefined));
    dispatch(setHasUnsavedChangesAction(false));
    setEditing?.(false);
  }, [setEditing, dispatch]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      resetPage();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, resetPage, setShouldResetOnTabChange]);

  const defaultNote: Note = {
    id: '',
    type_id: '',
    type_name: '',
    date: dayjs().format(formats.upsertDate),
    created_user_name: '',
    created: dayjs().format(formats.upsertDate),
    created_user_id: '',
    hidden: false,
    locked: false,
    value: '',
  };

  const handleSave = async (values: Store) => {
    setIsSaving(true);

    const noteValues: NoteUpsertValues = {
      type_id: values.type_id,
      date: values.date,
      textContent,
      plainTextContent,
    };

    const upsert = getUpdateNoteUpsert(defaultNote, noteValues, patientId, contactId);

    setEditing?.(false);

    const note = await sendNoteUpsertToBackend(
      upsert,
      resetPage,
      translations.shared.saveSuccessMessage,
      noteRefData as unknown as NoteType[]
    );

    if (note) {
      insertNewRecordInState(getRecordFromNote(note));
    }
  };

  const buttonRow = getButtonRowForNewRecord(onCancel);

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.patientPage.entity)}
    >
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={{
          date: dayjs(),
        }}
        autoComplete='off'
      >
        <Card title={translations.recordList.newNoteTitle} extra={<CloseButton onClick={onCancel} />}>
          <NoteCardContent
            editMode
            onClose={onCancel}
            textContent={textContent}
            setTextContent={(text, plainText) => {
              setTextContent(text);
              setPlainTextContent(plainText);
            }}
            buttonRow={buttonRow}
            noteRefData={noteRefData}
            form={form}
            linkedIcon={linkedIcon}
          />
        </Card>
      </Form>
    </SaveSpinnerAndNavigationWarning>
  );
};
