import { Form, Input } from 'antd';
import { Store } from 'antd/es/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { Loading } from '../../components/Loading/Loading';
import ModalWithCloseConfirm from '../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { ReportTags } from '../../components/ReportTags/ReportTags';
import { PracticeSettingsId, PracticeSettingsNameKey, translations } from '../../constants/translations';
import { Setting, SettingDto, SettingUpsert } from '../../graph/types';
import { getSettingUpsert, useUpsertSetting } from '../../hooks/ajax/setting/settingHooks';
import { useFormChanges } from '../../hooks/useFormChanges';
import { getRequiredRule } from '../../util/forms';
import { getPracticeSetting } from '../Contacts/ViewContact/statementUtils';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';
import { setPracticeSetting } from '../../contexts/organization/action';
import { flushSync } from 'react-dom';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';

type StatementRunConfigModalProps = {
  setModalOpen: (state: boolean) => void;
  organizationId: string;
  isModalOpen: boolean;
};

export const StatementRunConfigModal: React.FC<StatementRunConfigModalProps> = ({
  setModalOpen,
  organizationId,
  isModalOpen,
}) => {
  const handleUpdateContext = (newSetting?: Setting) => {
    if (practice && newSetting) {
      orgContextDispatch(setPracticeSetting(practice.id, { ...newSetting, __typename: 'SettingDTO' } as SettingDto));
    }
  };
  const [upsertSetting] = useUpsertSetting(handleUpdateContext);
  const { state, dispatch: orgContextDispatch } = useOrganizationContext();
  const practice = defaultPractice(state);
  const practiceId = practice?.id;
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const subjectSetting = getPracticeSetting(PracticeSettingsNameKey.StatementSubject, practice);
  const subject = subjectSetting?.value;
  const bodySetting = getPracticeSetting(PracticeSettingsNameKey.StatementBody, practice);
  const body = bodySetting?.value;
  const footerSetting = getPracticeSetting(PracticeSettingsNameKey.StatementFooter, practice);
  const footer = footerSetting?.value;

  const initialValues = {
    subject: subject ?? translations.statementRun.configurationModal.placeholders.subject,
    body: body ?? translations.statementRun.configurationModal.placeholders.emailBody,
    footer: footer ?? translations.statementRun.configurationModal.placeholders.statementFooter,
  };

  const { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange } = useFormChanges(
    form,
    initialValues
  );

  const handleFinish = async (value: Store) => {
    if (!bodySetting || !subjectSetting || !footerSetting) {
      return;
    }

    setIsSaving(true);

    const hasBodyChanged = value.body !== bodySetting.value;
    const hasSubjectChanged = value.subject !== subjectSetting.value;
    const hasFooterChanged = value.footer !== footerSetting.value;

    const bodySettingUpsert = getSettingUpsert(
      value.body,
      PracticeSettingsId.StatementBody,
      bodySetting?.id,
      practiceId
    );
    const subjectSettingUpsert = getSettingUpsert(
      value.subject,
      PracticeSettingsId.StatementSubject,
      subjectSetting?.id,
      practiceId
    );
    const footerSettingUpsert = getSettingUpsert(
      value.footer,
      PracticeSettingsId.StatementFooter,
      footerSetting?.id,
      practiceId
    );

    const getUpsert = (upsert: SettingUpsert) => ({
      variables: {
        organizationId,
        setting: upsert,
      },
    });

    try {
      await Promise.all([
        hasBodyChanged && upsertSetting(getUpsert(bodySettingUpsert)),
        hasSubjectChanged && upsertSetting(getUpsert(subjectSettingUpsert)),
        hasFooterChanged && upsertSetting(getUpsert(footerSettingUpsert)),
      ]);
      showSuccessMessage(translations.shared.saveSuccessMessage);
      setIsSaving(false);
      flushSync(() => {
        setHasDataChanged(false);
      });
      setModalOpen(false);
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
    }
  };

  if (!practice) {
    return <Loading />;
  }

  return (
    <ModalWithCloseConfirm
      title={translations.statementRun.configurationModal.title}
      onCancel={() => {
        if (hasDataChanged) {
          handleOnReset();
        }
        setModalOpen(false);
      }}
      isSaving={isSaving}
      onOk={form.submit}
      okButtonProps={{
        disabled: !hasDataChanged,
      }}
      changesMade={hasDataChanged}
      open={isModalOpen}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleFinish}
        labelCol={{ span: 8 }}
        autoComplete='off'
      >
        <Form.Item
          name='subject'
          label={translations.statementRun.configurationModal.fields.subject}
          rules={[getRequiredRule(translations.statementRun.configurationModal.fields.subject)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='body'
          label={translations.statementRun.configurationModal.fields.emailBody}
          rules={[getRequiredRule(translations.statementRun.configurationModal.fields.emailBody)]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name='footer'
          label={translations.statementRun.configurationModal.fields.statementFooter}
          rules={[getRequiredRule(translations.statementRun.configurationModal.fields.statementFooter)]}
        >
          <TextArea />
        </Form.Item>
        <ReportTags tagList={['StartDate', 'EndDate', 'ContactName']} titleOffset={8} contentOffset={12} />
      </Form>
    </ModalWithCloseConfirm>
  );
};
