import React from 'react';
import { columnDisplayFilterInitialState, columnDisplayFilterReducer, ColumnDisplayFilterState } from './reducer';
import { ColumnDisplayFilterAction } from './actions';
import { ContextProviderFC } from '../../../util/ContextProvider';
export const ColumnDisplayFilterContext = React.createContext<{
  state: ColumnDisplayFilterState;
  dispatch: React.Dispatch<ColumnDisplayFilterAction>;
}>({} as any);

export const ColumnDisplayFilterContextProvider: ContextProviderFC = (props) => {
  const fullInitialState = {
    ...columnDisplayFilterInitialState,
  };
  const [state, dispatch] = React.useReducer(columnDisplayFilterReducer, fullInitialState);
  return (
    <ColumnDisplayFilterContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ColumnDisplayFilterContext.Provider>
  );
};
