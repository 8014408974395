import React from 'react';
import { PaymentModal } from './PaymentModal/PaymentModal';
import { PaymentModalFormFields } from './PaymentModal/PaymentForm/PaymentForm';
import { LedgerUpsertGenerator } from '../../classes/upsertGenerators/LedgerUpsertGenerator';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';

export interface ContactPaymentModalProps {
  onClose: () => void;
  onSuccess?: () => void;
  contactId: string;
}

export const ContactPaymentModal: React.FC<ContactPaymentModalProps> = ({ onClose, onSuccess, contactId }) => {
  const practiceId = useDefaultPracticeId();

  const mapFormFieldsToLedgerUpsert = (formFields: PaymentModalFormFields) => {
    return LedgerUpsertGenerator.getNewPaymentUpsertFromContact(formFields, {
      contactId,
      practiceId,
    });
  };

  return (
    <PaymentModal
      contactId={contactId}
      practiceId={practiceId}
      onClose={onClose}
      onSuccess={onSuccess}
      mapFormFieldsToLedgerUpsert={mapFormFieldsToLedgerUpsert}
    />
  );
};
