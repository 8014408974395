import uniqBy from 'lodash/uniqBy';
import { translations } from '../constants/translations';
import { RefDataReminderStatusUpsert, ReminderStatus } from '../graph/types';

export const hasOneOfEachType = (data: ReminderStatus, allValues: ReminderStatus[], isVoid?: boolean) => {
  if (isVoid) {
    const isLastReminderStatusOfType =
      allValues.filter(({ system_status_id }) => system_status_id === data.system_status_id).length === 1;
    if (isLastReminderStatusOfType) {
      return false;
    }
    return true;
  }

  const uniqueReminders = uniqBy(
    allValues.map((reminder) => {
      if (reminder.id === data.id) {
        return data;
      }
      return reminder;
    }),
    'system_status_id'
  );

  if (uniqueReminders.length < translations.systemStatusFilteredOption.length) {
    return false;
  }

  return true;
};

export const hasOneOfEachDefaultType = (data: ReminderStatus, allValues: ReminderStatus[], isVoid?: boolean) => {
  if (isVoid) {
    if (data.is_default) {
      return false;
    }
    return true;
  }

  if (data.is_default) {
    return true;
  }

  const previousRecord = allValues.find((reminder) => reminder.id === data.id);
  if (previousRecord?.is_default) {
    return false;
  }

  return true;
};

export const getReminderStatusUpsert = (
  { id, is_default, name, sort_order, system_status_id }: ReminderStatus,
  allValues: ReminderStatus[],
  isVoid?: boolean
) => {
  const reminderUpsert: RefDataReminderStatusUpsert[] = [
    {
      id: id ?? '0',
      record: {
        is_default: is_default ?? false,
        name,
        sort_order: sort_order ?? 0,
        system_status_id: system_status_id ?? '0',
      },
      void: isVoid,
    },
  ];

  if (is_default) {
    const previousDefault = allValues?.find(
      (r) => r.system_status_id === system_status_id && r.is_default && r.id !== id
    );
    if (previousDefault) {
      reminderUpsert.push({
        id: previousDefault.id,
        record: {
          is_default: false,
          name: previousDefault.name,
          sort_order: previousDefault.sort_order,
          system_status_id: previousDefault.system_status_id,
        },
      });
    }
  }

  return reminderUpsert;
};
