import { Ledger } from '../../../graph/types';
import { Maybe, RxScalars } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';

export type RxLedger = Ledger;

export const ledgerSchema: RxJsonSchema<RxLedger> = {
  version: 9,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    updated: RxScalars.String,
    practice_id: RxScalars.ID,
    contact_id: Maybe(RxScalars.ID),
    contact_number: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    patient_name: Maybe(RxScalars.String),
    invoice_id: Maybe(RxScalars.ID),
    invoice_status_id: Maybe(RxScalars.Int),
    invoice_status_name_key: Maybe(RxScalars.String),
    invoice_contact_id: Maybe(RxScalars.ID),
    invoice_number: Maybe(RxScalars.String),
    type_id: Maybe(RxScalars.ID),
    type_name_key: Maybe(RxScalars.String),
    sub_type_id: Maybe(RxScalars.ID),
    sub_type_name: Maybe(RxScalars.String),
    payment_type_id: Maybe(RxScalars.ID),
    payment_type_name_key: Maybe(RxScalars.String),
    number: RxScalars.String,
    total_no_tax: RxScalars.String,
    total_tax: RxScalars.String,
    total: RxScalars.String,
    posted: Maybe(RxScalars.Boolean),
    posted_date: Maybe(RxScalars.String),
    financial_date: Maybe(RxScalars.String),
    financial_amount: Maybe(RxScalars.String),
    financial_outstanding: Maybe(RxScalars.String),
    total_assigned: RxScalars.String,
    total_reversed: RxScalars.String,
    note_exists: Maybe(RxScalars.Boolean),
    payment_electronic_id: Maybe(RxScalars.ID),
    reversing_ledger_id: Maybe(RxScalars.String),
    reversal: Maybe(RxScalars.Boolean),
    tx_reference_number: Maybe(RxScalars.String),
    card_expiry_month: Maybe(RxScalars.String),
    card_expiry_year: Maybe(RxScalars.String),
    card_display_number: Maybe(RxScalars.String),
    check_number: Maybe(RxScalars.String),
    note: Maybe(RxScalars.Object),
    info: Maybe(RxScalars.Array),
    text: Maybe(RxScalars.Array),
    tax: Maybe(RxScalars.Array),
    hidden: RxScalars.Boolean,
    processor_type_id: Maybe(RxScalars.Int),
    deposited: Maybe(RxScalars.Boolean),
  },
  required: ['id'],
  indexes: ['updated'],
};
