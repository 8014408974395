import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { UserUpsertGenerator } from '../../classes/upsertGenerators/UserUpsertGenerator';
import { translations } from '../../constants/translations';
import { useUpsertUser } from '../../hooks/ajax/user/userHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getMinLengthRule, getRequiredRule } from '../../util/forms';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useUserContext } from '../../contexts/user/state';

export const newPinField = 'new-pin';
export const confirmPinField = 'confirm-pin';

interface PinModalProps {
  onClose: () => void;
}

export const PinModal: React.FC<PinModalProps> = ({ onClose }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { user },
  } = useUserContext();
  const [updateUser] = useUpsertUser();

  const handleOnFinish = async (values: { [newPinField]: string }) => {
    setIsSaving(true);
    const upsert = new UserUpsertGenerator({ id: user?.id }).addPinRecordToUpsert(btoa(values[newPinField]));
    await updateUser({
      variables: {
        user: upsert,
        organizationId,
      },
    });
    setIsSaving(false);
    onClose();
  };

  if (!user) {
    return null;
  }

  return (
    <ModalWithCloseConfirm
      title={translations.shared.pin.modalTitle}
      open
      onOk={() => form.submit()}
      onCancel={onClose}
      isSaving={isSaving}
    >
      <Form form={form} labelCol={{ span: 5 }} onFinish={handleOnFinish}>
        <Form.Item
          label={translations.shared.pin.fields.pin}
          name={newPinField}
          rules={[getRequiredRule(translations.auth.newPin.label), getMinLengthRule(4)]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name={confirmPinField}
          label={translations.auth.confirmPin.label}
          dependencies={[newPinField]}
          hasFeedback
          rules={[
            getRequiredRule(translations.auth.confirmPin.label),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(newPinField) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(translations.auth.confirmPin.nonMatchingErrorMessage);
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </ModalWithCloseConfirm>
  );
};
