import {
  Query,
  QueryRptGetContactReceiptArgs,
  QueryRptGetArArgs,
  QueryRptGetCommunicationLogArgs,
  QueryRptGetContactPaymentArgs,
  QueryRptGetControlledDrugLogArgs,
  QueryRptGetDailyInvoiceActivityArgs,
  QueryRptGetGeneralLedgerArgs,
  QueryRptGetInvoiceActivityArgs,
  QueryRptGetInvoiceActivityByTrainerArgs,
  QueryRptGetInvoiceArgs,
  QueryRptGetLedgerEntryArgs,
  QueryRptGetNewContactsAndPatientsArgs,
  QueryRptGetPatientProcedureArgs,
  QueryRptGetPatientProcedureByContactArgs,
  QueryRptGetPatientHistoryArgs,
  QueryRptGetPatientVaccineArgs,
  QueryRptGetPaymentsArgs,
  QueryRptGetProductionArgs,
  QueryRptGetRemindersByContactArgs,
  QueryRptGetReminderLetterArgs,
  QueryRptGetStatementsArgs,
  QueryRptGetSubscriptionPaymentsArgs,
  QueryRptGetSubscriptionsArgs,
  QueryRptGetTaxArgs,
  QueryRptGetTopSalesArgs,
  QueryRptGetVaccineActivityArgs,
  QueryRptGetVaccineByContactArgs,
  QueryRptGetSubscriptionInvoiceArgs,
} from '../../graph/types';

// Add the new type for every new report in the following two lines
export type ReportData =
  | Pick<Query, 'rptGetStatements'>
  | Pick<Query, 'rptGetProduction'>
  | Pick<Query, 'rptGetPatientVaccine'>
  | Pick<Query, 'rptGetInvoice'>
  | Pick<Query, 'rptGetCommunicationLog'>
  | Pick<Query, 'rptGetControlledDrugLog'>
  | Pick<Query, 'rptGetDailyInvoiceActivity'>
  | Pick<Query, 'rptGetGeneralLedger'>
  | Pick<Query, 'rptGetInvoiceActivity'>
  | Pick<Query, 'rptGetLedgerEntry'>
  | Pick<Query, 'rptGetPayments'>
  | Pick<Query, 'rptGetSubscriptions'>
  | Pick<Query, 'rptGetTax'>
  | Pick<Query, 'rptGetVaccineActivity'>
  | Pick<Query, 'rptGetAR'>
  | Pick<Query, 'rptGetContactPayment'>
  | Pick<Query, 'rptGetSubscriptionPayments'>
  | Pick<Query, 'rptGetVaccineByContact'>
  | Pick<Query, 'rptGetRemindersByContact'>
  | Pick<Query, 'rptGetPatientProcedureByContact'>
  | Pick<Query, 'rptGetPatientProcedure'>
  | Pick<Query, 'rptGetNewContactsAndPatients'>
  | Pick<Query, 'rptGetTopSales'>
  | Pick<Query, 'rptGetSubscriptionInvoice'>
  | Pick<Query, 'rptGetPatientHistory'>
  | Pick<Query, 'rptGetReminderLetter'>;

export type ReportQueryKeys =
  | 'rptGetStatements'
  | 'rptGetProduction'
  | 'rptGetPatientVaccine'
  | 'rptGetInvoice'
  | 'rptGetCommunicationLog'
  | 'rptGetControlledDrugLog'
  | 'rptGetDailyInvoiceActivity'
  | 'rptGetGeneralLedger'
  | 'rptGetInvoiceActivity'
  | 'rptGetLedgerEntry'
  | 'rptGetPayments'
  | 'rptGetSubscriptions'
  | 'rptGetTax'
  | 'rptGetVaccineActivity'
  | 'rptGetAR'
  | 'rptGetContactPayment'
  | 'rptGetSubscriptionPayments'
  | 'rptGetVaccineByContact'
  | 'rptGetRemindersByContact'
  | 'rptGetPatientProcedureByContact'
  | 'rptGetPatientProcedure'
  | 'rptGetNewContactsAndPatients'
  | 'rptGetInvoiceActivityByTrainer'
  | 'rptGetTopSales'
  | 'rptGetSubscriptionInvoice'
  | 'rptGetPatientHistory'
  | 'rptGetContactReceipt'
  | 'rptGetReminderLetter';

export type ReportDataQueryParam =
  | QueryRptGetProductionArgs
  | QueryRptGetStatementsArgs
  | QueryRptGetPatientVaccineArgs
  | QueryRptGetInvoiceArgs
  | QueryRptGetContactReceiptArgs
  | QueryRptGetCommunicationLogArgs
  | QueryRptGetControlledDrugLogArgs
  | QueryRptGetDailyInvoiceActivityArgs
  | QueryRptGetGeneralLedgerArgs
  | QueryRptGetInvoiceActivityArgs
  | QueryRptGetLedgerEntryArgs
  | QueryRptGetPaymentsArgs
  | QueryRptGetSubscriptionsArgs
  | QueryRptGetTaxArgs
  | QueryRptGetVaccineActivityArgs
  | QueryRptGetArArgs
  | QueryRptGetContactPaymentArgs
  | QueryRptGetSubscriptionPaymentsArgs
  | QueryRptGetVaccineByContactArgs
  | QueryRptGetRemindersByContactArgs
  | QueryRptGetPatientProcedureByContactArgs
  | QueryRptGetPatientProcedureArgs
  | QueryRptGetNewContactsAndPatientsArgs
  | QueryRptGetInvoiceActivityByTrainerArgs
  | QueryRptGetTopSalesArgs
  | QueryRptGetSubscriptionInvoiceArgs
  | QueryRptGetPatientHistoryArgs
  | QueryRptGetReminderLetterArgs;

export interface ActiveReportViewerProps {
  reportName: string;
  data: ReportData;
  nestedHeader?: boolean;
}

export const buildReportVariables = (
  reportQuery: ReportQueryKeys,
  organizationId: string,
  practiceId: string,
  parameters: Record<string, string>
): ReportDataQueryParam => {
  switch (reportQuery) {
    case 'rptGetAR':
      return {
        organizationId,
        practiceId,
        effectiveDate: parameters.effectiveDate,
      };
    case 'rptGetSubscriptionPayments':
      return {
        reportParameters: JSON.parse(JSON.stringify(parameters, Object.keys(parameters).sort())),
      };
    default: {
      return {
        organizationId,
        practiceId,
        reportParameters: JSON.parse(JSON.stringify(parameters, Object.keys(parameters).sort())),
      };
    }
  }
};
