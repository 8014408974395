import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import {
  useTriggerReplicationsOnDataChanges,
  useReplications,
  useReplicationCompletedSubscription,
} from '../../hooks/localDatabaseHooks';
import { getUpdatedDate } from './queries/queryUtils';
import { useOffline } from '../../util/offline/offlineUtil';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { UtilsContext } from '../../components/UtilsProvider/store/state';
import { useGetInvoiceContextForOffline } from '../../hooks/ajax/invoice/invoiceHooks';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useGetReferenceDataForOffline } from '../../hooks/ajax/referenceData/referenceDataHooks';

export const LocalDatabaseDataWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const practiceCollection = useRxCollection('practice');
  const invoiceContextCollection = useRxCollection('invoice_context');
  const referenceDataCollection = useRxCollection('reference_data');
  const {
    state: { organizationId = '' },
  } = useContext(UtilsContext);
  const {
    state: { organization },
  } = useOrganizationContext();
  const practiceId = organization?.default_practice_id ?? '';
  const practice = organization?.practice.find(({ id }) => id === practiceId);
  const { invoiceContext } = useGetInvoiceContextForOffline(organizationId);
  const { referenceData } = useGetReferenceDataForOffline(organizationId);
  const { enabledAndOffline } = useOffline();
  const allReplicationLoaded = useReplications();
  useTriggerReplicationsOnDataChanges();
  useReplicationCompletedSubscription();

  useEffect(() => {
    if (!practice || !practiceCollection) {
      return;
    }

    practiceCollection.upsert({
      id: practice.id,
      organization_id: practice.organization_id,
      name: practice.name,
      name_short: practice.name_short,
      address: practice.address,
      phone: practice.phone,
      setting: practice.setting,
      logo: practice.logo,
      card_connect: practice.card_connect,
      card_connect_manual: practice.card_connect_manual,
      info: practice.info,
      updated: getUpdatedDate(),
    });
  }, [practice, practiceCollection]);

  useEffect(() => {
    if (!invoiceContext || !invoiceContextCollection) {
      return;
    }

    invoiceContextCollection.upsert({
      id: invoiceContext.id,
      organization_id: invoiceContext.organization_id,
      practice_id: invoiceContext.practice_id,
      status: invoiceContext.status,
      business_center: invoiceContext.business_center,
      location: invoiceContext.location,
      caregiver: invoiceContext.caregiver,
      updated: getUpdatedDate(),
    });
  }, [invoiceContext, invoiceContextCollection]);

  useEffect(() => {
    if (!referenceData || !referenceDataCollection || !organizationId) {
      return;
    }

    referenceDataCollection.upsert({
      id: referenceData.id,
      ledger_payment_type: referenceData.ledger_payment_type,
      contact_type: referenceData.contact_type,
      address_type: referenceData.address_type,
      country: referenceData.country,
      prov_state: referenceData.prov_state,
      invoice_status: referenceData.invoice_status,
      setting: referenceData.setting,
      subscription_type: referenceData.subscription_type,
      ref_currency_defaults: referenceData.ref_currency_defaults,
      ref_service_rendered_info_type_data: referenceData.ref_service_rendered_info_type_data,
      updated: getUpdatedDate(),
      organization_id: organizationId,
    });
  }, [referenceData, referenceDataCollection, organizationId]);

  useEffect(() => {
    if (enabledAndOffline && !allReplicationLoaded) {
      showErrorMessage(translations.offline.offlineWithoutReplication, 15);
    }
  }, [enabledAndOffline, allReplicationLoaded]);

  return <>{children}</>;
};
