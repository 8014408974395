import { Button, Col, Form, Input, Row, Switch } from 'antd';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { PracticeSettingsId, translations } from '../../../constants/translations';
import { Info, Maybe, OrganizationReferenceData, User, UserOrganization } from '../../../graph/types';
import { useSetUserStatus, useUpsertUser } from '../../../hooks/ajax/user/userHooks';
import {
  SaveAndResetButton,
  SaveAndResetButtonPlaceholder,
} from '../../../components/SaveAndResetButton/SaveAndResetButton';
import { Store } from 'antd/lib/form/interface';
import { UserUpsertGenerator } from '../../../classes/upsertGenerators/UserUpsertGenerator';
import { getPrimaryPhone } from '../../../util/phone';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { Loading } from '../../../components/Loading/Loading';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { getRequiredRule } from '../../../util/forms';
import { UserRoleSelect } from '../UserRoleSelect';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { mapRefData } from '../../../util/refDataUtil';
import { getIfUserIsDoctor, getUserInfoMap } from '../userUtil';
import {
  checkBoxSameAddressesName,
  PhysicalAndMailingAddress,
} from '../../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import { hasInitialMailingAddress } from '../../../components/PhysicalAndMailingAddress/physicalAndMailingAddressUtil';
import { FormWithFlexWrap, OffsetTitle } from '../../../globalStyles.style';
import { AddressForm } from '../../../components/AddressBlock/AddressBlock';
import { UserInfoType } from '../../../constants/referenceData/userReferenceData';
import { indexOfMailingAddress, indexOfPhysicalAddress } from '../../../constants/address';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import { fetchAvailableSubscriptions } from '../../../components/RecordSingleView/Files/userUtils';
import { useUserPermissions } from '../../../hooks/permissionsHooks';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import {
  abbreviationFieldName,
  emailFieldName,
  hisaIdFieldName,
  inactiveFieldName,
  licenseFieldName,
  nameFieldName,
  phoneFieldName,
  rememberDateFieldName,
  typesFieldName,
} from '../../../constants/fields/userFields';
import { useCurrencyAndDateSetting } from '../../../hooks/currencyAndDateHooks';
import { getSettingUpsert, useUpsertSetting } from '../../../hooks/ajax/setting/settingHooks';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { HisaModal, HisaValidationData } from '../HisaModal/HisaModal';
import { getInfoUpsert } from '../../../classes/upsertGenerators/infoMappingUtil';
import styled from 'styled-components';
import { useUserContext } from '../../../contexts/user/state';
import { useOrganizationContext } from '../../../contexts/organization/state';
import {
  OrganizationSubscriptionLevelNameKeys,
  organizationSubscriptionLevelConfigs,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { GetInvoiceContext } from '../../../graph/queries/invoices';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

interface UserDetailsProps extends PropsWithChildren<unknown> {
  user: User;
  userOrganizations?: UserOrganization[];
  setIsSaving: (isSaving: boolean) => void;
  isSaving?: boolean;
  setHasUnsavedData: (hasUnsavedData: boolean) => void;
  hasUnsavedData: boolean;
}

export interface UserDetailsBasicFields {
  [nameFieldName]: string;
  [phoneFieldName]?: string;
  [typesFieldName]?: string[];
  [licenseFieldName]?: string;
  [abbreviationFieldName]?: string;
  [checkBoxSameAddressesName]: boolean;
  [inactiveFieldName]: boolean;
  [hisaIdFieldName]?: string;
}

export type UserDetailsFields = UserDetailsBasicFields & AddressForm;

const HisaFormItem = styled(Form.Item)`
  .ant-col-12 {
    flex-direction: row;
    justify-content: space-between;

    > .ant-form-item-control-input {
      width: 100%;
    }

    #hisaId_extra {
      margin-right: -5em;
    }
  }
`;

export const UserDetails: React.FC<UserDetailsProps> = ({
  user,
  userOrganizations,
  setIsSaving,
  setHasUnsavedData,
  hasUnsavedData,
  isSaving = false,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();

  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.UserTypes
  );
  const {
    state: { organization: organizationContext },
  } = useOrganizationContext();

  const { rememberDate, userRememberDateSetting } = useCurrencyAndDateSetting(user.id);

  const {
    state: { user: currentUser },
  } = useUserContext();
  const [updateUser] = useMutationWithMessages(useUpsertUser);
  const [updateUserStatus] = useMutationWithMessages(useSetUserStatus);
  const [form] = Form.useForm();
  const [inactivateOnClose, setInactivateOnClose] = useState(false);
  const userTypeRefData = useMemo(
    () => Array.from(organization?.ref_user?.type ?? []) as OrganizationReferenceData[],
    [organization]
  );
  const userOrganization = userOrganizations?.find((organization) => organization.organization_id === organizationId);

  const [subModalOpen, setSubModalOpen] = useState(false);
  const [hisaModalOpen, setHisaModalOpen] = useState(false);

  const [upsertSettings] = useUpsertSetting();
  const hisaAddon = useMemo(
    () =>
      organizationContext?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.HisaLevel].level_id
      ),
    [organizationContext]
  );
  const hisaEnabled = useLDFlag(LDFlagNames.Hisa);

  const initialTypeIds = useMemo(
    () =>
      userOrganization
        ? (userOrganization.type ?? []).map((userType) => {
            if (!mapRefData(userType.type_id, userTypeRefData)) {
              userTypeRefData.push({
                id: userType.type_id,
                name: translations.shared.getVoidRecord(userType.type_name),
                sort_order: 0,
              });
            }

            return userType.type_id;
          })
        : [],
    [userOrganization, userTypeRefData]
  );
  const { isOrgOwner } = useUserPermissions(user);

  const initialInfoValues = useMemo(() => getUserInfoMap(user.info ?? []), [user.info]);

  const [isDoctor, setIsDoctor] = useState(false);

  const initiallyHasMailingAddress = hasInitialMailingAddress(user);

  const [hideMailingAddress, setHideMailingAddress] = useState(!initiallyHasMailingAddress);

  useEffect(() => {
    setIsDoctor(getIfUserIsDoctor(userTypeRefData, initialTypeIds));
  }, [initialTypeIds, userTypeRefData]);

  if (organizationLoading) {
    return <Loading />;
  }

  if (!userOrganization && !isSaving) {
    return <p>{translations.viewUserPage.missingUserTypes}</p>;
  }

  const primaryPhone = getPrimaryPhone(user.phone ?? []);

  const initialData = {
    ...user,
    // We want to show if user is active so we need to reverse this property
    [inactiveFieldName]: !user.inactive,
    [phoneFieldName]: primaryPhone?.phone,
    [typesFieldName]: initialTypeIds,
    [checkBoxSameAddressesName]: !initiallyHasMailingAddress,
    [licenseFieldName]: initialInfoValues[UserInfoType.UINFO_LICNUM]?.value,
    [abbreviationFieldName]: initialInfoValues[UserInfoType.UINFO_ABBREV]?.value,
    [rememberDateFieldName]: rememberDate,
    [hisaIdFieldName]: initialInfoValues[UserInfoType.UINFO_HISAID]?.value,
  };

  const handleToggle = async (value: boolean) => {
    if (value) {
      const availableSubscriptions = await fetchAvailableSubscriptions(organizationId);

      if (availableSubscriptions > 0) {
        await onFinish({ [nameFieldName]: user.name, inactive: value } as UserDetailsFields, false);
        return;
      }
      setInactivateOnClose(true);
      setSubModalOpen(true);
      return;
    }
    await onFinish({ [nameFieldName]: user.name, inactive: value } as UserDetailsFields, false);
    setSubModalOpen(true);
  };

  const handleDateToggle = async (value: boolean) => {
    setIsSaving(true);
    try {
      if (organization?.default_practice_id) {
        const settingUpsert = getSettingUpsert(
          value,
          PracticeSettingsId.LocaleRememberDate,
          userRememberDateSetting?.id,
          organization?.default_practice_id,
          user.id
        );

        await upsertSettings({
          variables: {
            organizationId,
            setting: settingUpsert,
          },
        });
        showSuccessMessage(translations.shared.saveSuccessMessage);
      }
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const onFinish = async (values: UserDetailsFields, shouldResetChanges = true) => {
    setIsSaving(true);

    await updateUserStatus({
      options: {
        variables: {
          user: {
            userName: user.email,
            active: values[inactiveFieldName],
          },
        },
      },
      onError: () => {
        form.setFields([{ name: inactiveFieldName, value: !values[inactiveFieldName] }]);
        setIsSaving(false);
      },
    });

    values[inactiveFieldName] = !values[inactiveFieldName];

    // if toggled inactive to true call cognito inactivate procedure

    const userUpsert = new UserUpsertGenerator(user).generateFromUpdatedValues(values, {
      idOfPhone: primaryPhone?.id,
      userOrganization,
      initiallyHasMailingAddress,
      info: user.info ?? [],
    });

    await updateUser({
      options: {
        variables: {
          user: userUpsert,
          organizationId,
        },
        refetchQueries: [
          {
            query: GetInvoiceContext,
            variables: {
              organizationId,
              practiceId: organizationContext?.default_practice_id,
            },
          },
        ],
      },
      successMessage: translations.viewUserPage.getSuccessMessage(values[nameFieldName]),
      onSuccess: () => {
        if (shouldResetChanges) {
          setHasUnsavedData(false);
        }
      },
    });

    setIsSaving(false);
  };

  const getHisaInfoUpsert = (validationData: HisaValidationData, existingInfo: Maybe<Info[]> = []) => [
    ...(getInfoUpsert(UserInfoType.UINFO_HISAID, validationData.id, existingInfo) ?? []),
    ...(getInfoUpsert(UserInfoType.UINFO_HISAFNAME, validationData.firstName, existingInfo) ?? []),
    ...(getInfoUpsert(UserInfoType.UINFO_HISALNAME, validationData.lastName, existingInfo) ?? []),
  ];

  const handleHisaUpdate = async (validationData: HisaValidationData) => {
    setIsSaving(true);
    await updateUser({
      options: {
        variables: {
          user: {
            id: user.id,
            info: getHisaInfoUpsert(validationData, user.info),
          },
          organizationId,
        },
      },
      onSuccess: (data) => {
        const newHisaNumber = data?.upsertUser?.info?.find?.((i) => i.type_id === UserInfoType.UINFO_HISAID)?.value;
        form.setFields([{ name: hisaIdFieldName, value: newHisaNumber, errors: [] }]);
      },
    });
    setIsSaving(false);
  };

  const handleReset = () => {
    form.resetFields();
    setHasUnsavedData(false);
  };

  const handleValueChange = (field: Store, allValues: Store) => {
    const fieldName = Object.keys(field)[0];
    if (fieldName === 'inactive' || fieldName === 'rememberDate') {
      return;
    }
    const { inactive, rememberDate, ...restInitialData } = initialData;
    const { inactive: allValuesInactive, rememberDate: allValuesRememberDate, ...restAllValues } = allValues;
    setHasUnsavedData(!isEqual(restInitialData, restAllValues));
  };

  const isActiveDisabled = (!!currentUser && user.id === currentUser.id) || isOrgOwner;

  return (
    <>
      <FormWithFlexWrap
        {...layout}
        onValuesChange={handleValueChange}
        onFinish={onFinish}
        initialValues={initialData}
        form={form}
        autoComplete='off'
      >
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <MaxLengthFormItem
              label={translations.viewUserPage.fields.name.label}
              name={nameFieldName}
              rules={[getRequiredRule(translations.viewUserPage.fields.name.label)]}
              maxLength={50}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>

            <Form.Item label={translations.viewUserPage.fields.email.label} name={emailFieldName}>
              <div>{user.email}</div>
            </Form.Item>

            <MaxLengthFormItem
              maxLength={25}
              label={translations.viewUserPage.fields.phone.label}
              name={phoneFieldName}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>
            <UserRoleSelect userTypes={userTypeRefData} setIsDoctor={setIsDoctor} />
            {isDoctor && (
              <MaxLengthFormItem
                maxLength={255}
                label={translations.viewUserPage.fields.license.label}
                name={licenseFieldName}
              >
                <Input autoComplete='new-password' />
              </MaxLengthFormItem>
            )}
            <MaxLengthFormItem
              maxLength={255}
              label={translations.viewUserPage.fields.abbreviation.label}
              name={abbreviationFieldName}
            >
              <Input autoComplete='new-password' />
            </MaxLengthFormItem>
            {hisaEnabled && (
              <>
                <HisaFormItem
                  label={translations.viewUserPage.fields.hisaValidation.label}
                  name={hisaIdFieldName}
                  extra={
                    <Button disabled={!hisaAddon?.enabled} onClick={() => setHisaModalOpen(true)}>
                      {translations.shared.editButtonText}
                    </Button>
                  }
                >
                  <Input disabled />
                </HisaFormItem>
              </>
            )}
          </Col>
          <Col span={12}>
            <OffsetTitle level={5} offset={4}>
              {translations.viewUserPage.options}
            </OffsetTitle>
            <Form.Item
              label={translations.viewUserPage.fields.active.label}
              name={inactiveFieldName}
              valuePropName='checked'
              labelCol={{ span: 10 }}
            >
              <Switch disabled={isActiveDisabled} onChange={handleToggle} />
            </Form.Item>
            <Form.Item
              label={translations.viewUserPage.fields.rememberDate.label}
              name={rememberDateFieldName}
              valuePropName='checked'
              labelCol={{ span: 10 }}
              tooltip={{
                icon: <InfoCircleOutlined />,
                title: translations.viewUserPage.rememberDateTooltip,
                color: 'black',
              }}
            >
              <Switch onChange={handleDateToggle} />
            </Form.Item>
          </Col>
        </Row>
        <PhysicalAndMailingAddress
          indexOfPhysicalAddress={indexOfPhysicalAddress}
          indexOfMailingAddress={indexOfMailingAddress}
          form={form}
          allFieldsOptional
          hideMailingAddress={hideMailingAddress}
          setHideMailingAddress={setHideMailingAddress}
        />
        {hasUnsavedData ? (
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <SaveAndResetButton onReset={handleReset} offset={6} />
            </Col>
          </Row>
        ) : (
          <SaveAndResetButtonPlaceholder />
        )}
      </FormWithFlexWrap>
      {subModalOpen && (
        <SubscriptionUpdateModal
          onClose={() => {
            if (inactivateOnClose) {
              form.setFields([{ name: inactiveFieldName, value: false, errors: [] }]);
              setInactivateOnClose(false);
            }
            setSubModalOpen(false);
          }}
          onSuccess={async () => {
            if (inactivateOnClose) {
              await onFinish({ [nameFieldName]: user.name, inactive: true } as UserDetailsFields, false);
              setInactivateOnClose(false);
            }
            setSubModalOpen(false);
          }}
          canReduceUserCount
        />
      )}
      {hisaModalOpen && organization && (
        <HisaModal
          setHisaModalOpen={setHisaModalOpen}
          initialInfoValues={initialInfoValues}
          organization={organization}
          handleFinish={handleHisaUpdate}
        />
      )}
    </>
  );
};
