import React from 'react';
import {
  ColumnTypeWithFixedOptionsFiltering,
  ColumnTypeWithFreeTextFiltering,
  CustomColumnType,
} from './TableWithCustomFiltering';

export const weaveInBrCallback = () => {
  return (array: JSX.Element[], checkbox: JSX.Element, index: number) => {
    array.push(...(index > 0 ? [<br key={index} />, checkbox] : [checkbox]));
    return array;
  };
};

export function checkValidityOfColumn<T>(column: CustomColumnType<T>, previousColumnsAllHaveFixedWidth: boolean) {
  const columnHasFreeTextFiltering = !!(column as ColumnTypeWithFreeTextFiltering<T>).filterInputPlaceholder;
  const columnHasFixedOptionsFiltering = !!(column as ColumnTypeWithFixedOptionsFiltering<T>).filters;

  if (columnHasFreeTextFiltering && columnHasFixedOptionsFiltering) {
    throw new Error(
      'Either free text filtering or normal filtering can be used. Remove freeTextFiltering or filters property.'
    );
  }

  if ((columnHasFreeTextFiltering || columnHasFixedOptionsFiltering) && !column.width) {
    throw new Error(`Error in definition of column ${column.title}. Columns with filtering should have a fixed width.`);
  }

  if (!previousColumnsAllHaveFixedWidth && (columnHasFreeTextFiltering || columnHasFixedOptionsFiltering)) {
    throw new Error(
      `Error in definition of column ${column.title}. All columns before a column with filters should have a fixed width.`
    );
  }
}

export enum NumberDropdownValue {
  equal = 'E',
  lessThan = 'L',
  greaterThan = 'G',
}

export const getNumberAndOperatorValue = (
  numberWithOperatorValue: string = NumberDropdownValue.equal
): { operatorVal: NumberDropdownValue; value: string } => {
  const numberWithOperatorValList = numberWithOperatorValue.split(',');

  return {
    operatorVal: numberWithOperatorValList[0] as NumberDropdownValue,
    value: numberWithOperatorValList[1] || '',
  };
};
