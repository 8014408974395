import { InfoFields, PatientContactAlertFields, PatientTagFields, StaticImageFields } from '../fragments';
import gql from 'graphql-tag';
import { BasicSingleInvoiceFields } from './invoiceFragments';

export const PatientOverviewFields = gql`
  fragment PatientOverviewFields on Patient {
    id
    organization_id
    number
    name
    name_2
    name_3
    species_id
    breed_id
    breed_name
    gender_id
    gender_name
    color_id
    owner_names
    owner_percentages
    owner_ids
    related_ids
    related_names
    related_types
    updated
    hidden
    dob
    deceased
    created_practice_id
    inactive
    dob
    offline_id
    info {
      ...InfoFields
    }
  }
  ${InfoFields}
`;

export const PatientForDropdownFields = gql`
  fragment PatientForDropdownFields on Patient {
    id
    organization_id
    offline_id
    number
    name
    owner_names
    species_id
    species_name
    gender_id
    gender_name
    breed_id
    breed_name
    color_id
    color_name
    dob
    inactive
    deceased
    deceased_date
    hidden
    info {
      ...InfoFields
    }
  }
  ${InfoFields}
`;

export const PatientListFields = gql`
  fragment PatientListFields on Patient {
    id
    organization_id
    number
    name
    name_2
    name_3
    species_id
    species_name
    breed_id
    breed_name
    gender_id
    gender_name
    color_id
    color_name
    dob
    deceased
    inactive
    deceased_date
    created_practice_id
    tag_names
    owner_names
    owner_percentages
    owner_ids
    related_ids
    related_names
    related_types
    default_bill_to_id
    updated
    hidden
  }
`;

export const OwnershipFields = gql`
  fragment OwnershipFields on RelatedOwnershipEntryAll {
    syndicate_id
    owner {
      id
      contact_id
      name
      number
      percentage
      email
      primary
    }
    effective_date
  }
`;

export const CurrentOwnershipFields = gql`
  fragment CurrentOwnershipFields on RelatedOwnershipEntryCurrent {
    syndicate_id
    owner {
      id
      contact_id
      name
      number
      email
      percentage
      primary
    }
  }
`;

export const RelatedContactFields = gql`
  fragment RelatedContactFields on RelatedContactEntryCurrent {
    id
    contact_id
    contact_name
    contact_type_id
    contact_type_name_key
    effective_date
    primary
  }
`;

export const OfflinePatientFields = gql`
  fragment OfflinePatientFields on Patient {
    ...PatientListFields
    info {
      ...InfoFields
    }
    ownership_all {
      ...OwnershipFields
    }
    ownership_current {
      ...CurrentOwnershipFields
    }
    related_current {
      ...RelatedContactFields
    }
    profile_image {
      ...StaticImageFields
    }
    tag {
      ...PatientTagFields
    }
    related_all {
      id
      contact_id
      offline_contact_id
      contact_name
      contact_number
      contact_type_id
      contact_type_name_key
      effective_date
      end_date
      primary
    }
    primary_owner_id
    primary_owner_name
    primary_owner_number
    alert {
      id
      note
      type_id
    }
    contact_alert {
      ...PatientContactAlertFields
    }
    contact_invoice_alert {
      ...PatientContactAlertFields
    }
  }
  ${PatientListFields}
  ${InfoFields}
  ${OwnershipFields}
  ${CurrentOwnershipFields}
  ${RelatedContactFields}
  ${StaticImageFields}
  ${PatientTagFields}
  ${PatientContactAlertFields}
`;

export const PatientFields = gql`
  fragment PatientFields on Patient {
    ...OfflinePatientFields
  }
  ${OfflinePatientFields}
`;

export const PatientOwnershipContactInvoiceFields = gql`
  fragment PatientOwnershipContactInvoiceFields on PatientOwnershipContactInvoice {
    id
    name
    percent
  }
`;

export const PatientOwnershipContactInvoiceIssueFields = gql`
  fragment PatientOwnershipContactInvoiceIssueFields on PatientOwnershipContactInvoiceIssue {
    id
    name
    percent_old
    percent_new
    issue_name_key
  }
`;

export const PatientInvoiceOwnershipIssueFields = gql`
  fragment PatientInvoiceOwnershipIssueFields on PatientInvoiceOwnershipIssue {
    id
    date
    invoice {
      ...BasicSingleInvoiceFields
    }
    current {
      ...PatientOwnershipContactInvoiceFields
    }
    expected {
      ...PatientOwnershipContactInvoiceFields
    }
    issue {
      ...PatientOwnershipContactInvoiceIssueFields
    }
  }
  ${BasicSingleInvoiceFields}
  ${PatientOwnershipContactInvoiceFields}
  ${PatientOwnershipContactInvoiceIssueFields}
`;

export const HisaPatientFields = gql`
  fragment HisaPatientFields on HisaPatient {
    ownerHisaId
    damName
    hisaHorseId
    locationId
    locationName
    name
    ownerName
    responsiblePersonHisaId
    responsiblePersonName
    yearOfBirth
  }
`;
