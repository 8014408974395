import React from 'react';
import { FileDescriptionTextArea } from './FileDescriptionTextArea';
import { EditableCardProps } from '../SharedProps';
import { File, FileType } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import { Button, Card, Form, Upload } from 'antd';
import { FilePreview } from './FilePreview';
import { FileFieldsForNewFile } from './FileFieldsForNewFile';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { FileData, getUploadRequestToS3 } from '../../../util/fileUpload';
import { UploadOutlined } from '@ant-design/icons/lib';
import dayjs from 'dayjs';
import { CloseButton } from '../../CloseButton/CloseButton';
import { HeaderRow } from '../HeaderRow';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { useHighlightedText } from '../../../hooks/useHighlightedText';
import { useOffline } from '../../../util/offline/offlineUtil';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { ListItemFooter } from '../../Records/FullscreenRecords/ListItemFooter';
import { RecordItem } from '../../Records/recordUtils';
import { CenterAlignFlexDiv } from '../../../globalStyles.style';

type FileCardProps = EditableCardProps & {
  item?: RecordItem;
  file: File;
  fileData: FileData | undefined | null;
  setFileData: (fileData: FileData) => void;
  setIsSaving: (isSaving: boolean) => void;
  isNewRecord?: boolean;
  form: FormInstance;
  onSave: (values: Store) => Promise<void>;
  onValueChanged?: (_: Store, allValues: Store) => void;
  fileRefData: FileType[];
  extra?: React.ReactNode;
  fileUrl?: string;
  setFileUrl?: (url: string) => void;
  isFullScreenMode?: boolean;
};

export const FileCard: React.FC<FileCardProps> = ({
  item,
  file,
  fileData,
  setFileData,
  setIsSaving,
  form,
  onSave,
  editMode,
  onValueChanged,
  activateEditMode,
  isNewRecord,
  textContent,
  setTextContent,
  buttonRow,
  onClose,
  fileRefData,
  extra,
  setFileUrl,
  isFullScreenMode,
}: FileCardProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const title = isNewRecord ? translations.recordList.newFileTitle : fileData?.name;
  useHighlightedText([title, textContent], '#FileDescriptionTextArea, .ant-card-head-title');
  const { isOnline } = useOffline();

  const getFileReplaceButton = (): JSX.Element => {
    return (
      <Upload
        customRequest={getUploadRequestToS3(organizationId, setIsSaving, setFileData)}
        showUploadList={false}
        beforeUpload={activateEditMode}
      >
        <Button style={{ marginRight: '10px' }} icon={<UploadOutlined />}>
          {translations.recordList.changeFileButtonText}
        </Button>
      </Upload>
    );
  };

  const getFileFields = (): JSX.Element => {
    if (isNewRecord || fileData?.isChangedFile) {
      return (
        <FileFieldsForNewFile
          organizationId={organizationId}
          setIsSaving={setIsSaving}
          setFileData={setFileData}
          filename={fileData?.name}
        />
      );
    } else if (fileData) {
      if (isOnline) {
        return (
          <FilePreview
            organizationId={organizationId}
            metadata={fileData}
            replaceFileButton={getFileReplaceButton()}
            setFileUrl={setFileUrl}
            isfullScreenMode={isFullScreenMode}
          />
        );
      }
      return <span>{translations.recordList.offlineFileUnavailable}</span>;
    }
    return <div />;
  };
  const fileCardContent = () => (
    <>
      {(!isFullScreenMode || editMode) && (
        <HeaderRow
          types={fileRefData}
          typePlaceholder={translations.recordList.fileTypeDropdownPlaceholder}
          disabled={!isOnline}
          showDateField
          dateFormat={dateFormat}
        />
      )}
      <div className={isFullScreenMode ? 'flexDiv' : ''}>
        <CenterAlignFlexDiv>
          {editMode ? (
            <FileDescriptionTextArea textContent={textContent} setTextContent={setTextContent} editMode={editMode} />
          ) : (
            <p>{textContent}</p>
          )}
          {getFileFields()}
        </CenterAlignFlexDiv>
      </div>

      {(!isFullScreenMode || editMode) && buttonRow}
    </>
  );
  return (
    <Form
      form={form}
      onFinish={onSave}
      onValuesChange={onValueChanged}
      initialValues={{
        date: dayjs(file.date),
      }}
      autoComplete='off'
    >
      {isFullScreenMode ? (
        <Card bordered={false} size='small'>
          {fileCardContent()}
          <ListItemFooter item={item} />
        </Card>
      ) : (
        <Card
          title={title}
          extra={
            <>
              {extra}
              <CloseButton onClick={onClose} />
            </>
          }
        >
          {fileCardContent()}
        </Card>
      )}
    </Form>
  );
};

export interface FileUpsertValues {
  date: dayjs.Dayjs;
  type_id: string;
  pointer: string;
  name: string;
  size: number;
  description?: string;
}
