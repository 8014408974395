import { ArrowRightOutlined } from '@ant-design/icons';
import { Input, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { translations } from '../../constants/translations';
import { organizationIdParameter, routes } from '../../constants/routes';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';

export const AdminRedirection: React.FC = () => {
  const history = useHistory();
  const showDashboard = useLDFlag(LDFlagNames.Dashboard);

  const navigateToOrganization = (organizationId: string) => {
    history.push(
      showDashboard
        ? routes.dashboard.replace(organizationIdParameter, organizationId)
        : routes.doctorOffice.replace(organizationIdParameter, organizationId)
    );
  };

  return (
    <Result
      title={translations.adminRedirection.title}
      subTitle={translations.adminRedirection.subTitle}
      extra={
        <Input.Search
          style={{ width: '200px' }}
          placeholder={translations.adminRedirection.inputPlaceholder}
          onSearch={navigateToOrganization}
          enterButton={<ArrowRightOutlined />}
        />
      }
    />
  );
};
