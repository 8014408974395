import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { LedgerPaymentTypeDto } from '../../graph/types';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../constants/translations';
import { DatePicker, Form, FormProps } from 'antd';
import { getRequiredRule } from '../../util/forms';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { showErrorMessage } from '../Notification/notificationUtil';
import { sortBy } from 'lodash';
import { PaymentTypeNameKey, paymentTypeConfigs } from '../../constants/referenceData/paymentReferenceData';
import { TableRowSelection } from 'antd/es/table/interface';
import styled from 'styled-components';
import { TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { flushSync } from 'react-dom';

const layout = {
  labelCol: { lg: 12, span: 12 },
  wrapperCol: { span: 12 },
};

export interface CreateDepositRunModalProps {
  paymentTypes: LedgerPaymentTypeDto[];
  isSaving: boolean;
  isDateOverlap: (selectedDate: dayjs.Dayjs) => boolean;
  onCreate: (values: DepositRunFormValues, paymentTypeIds: string[]) => void;
  onClose?: () => void;
}

export interface DepositRunFormValues {
  endDate: dayjs.Dayjs;
}

interface DepositRunPaymentTypeOptions {
  label: string;
  value: string;
}

const SelectPaymentWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;
const RequiredText = styled.span`
  margin-inline-end: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
`;
const LabelText = styled.span`
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
`;
const RequiredErrorText = styled.span`
  margin-inline-end: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: Graphik, sans-serif;
  line-height: 1.5714285714285714;
`;

export const CreateDepositRunModal: React.FC<CreateDepositRunModalProps> = ({
  paymentTypes,
  isSaving,
  isDateOverlap,
  onCreate,
  onClose,
}) => {
  const [form] = Form.useForm<DepositRunFormValues>();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<DepositRunPaymentTypeOptions[]>([]);
  const [paymentTypeError, setPaymentTypeError] = useState<boolean>(false);

  const initialValues: DepositRunFormValues = {
    endDate: dayjs(),
  };

  const paymentTypeOptions = useMemo(
    () =>
      sortBy(paymentTypes, ['sort_order']).map((pt) => ({
        label: paymentTypeConfigs[pt.name_key as PaymentTypeNameKey].text,
        value: pt.id,
      })),
    [paymentTypes]
  );

  const getSelectedRowKeys = useCallback(() => {
    return selectedPaymentTypes.map((i) => i.value);
  }, [selectedPaymentTypes]);

  useEffect(() => {
    if (paymentTypeError) {
      setPaymentTypeError(getSelectedRowKeys().length === 0);
    }
  }, [getSelectedRowKeys, paymentTypeError]);

  const handleCreateDeposit: FormProps['onFinish'] = useCallback(
    (values: DepositRunFormValues) => {
      if (isDateOverlap(values.endDate)) {
        showErrorMessage(translations.depositRuns.overlap);
        return;
      }
      if (selectedPaymentTypes.length === 0) {
        setPaymentTypeError(true);
        return;
      }
      onCreate(values, getSelectedRowKeys());
    },
    [getSelectedRowKeys, isDateOverlap, onCreate, selectedPaymentTypes.length]
  );

  const selectedRowKeys = getSelectedRowKeys();

  const handleOnRowCheckedChange: TableRowSelection<DepositRunPaymentTypeOptions>['onChange'] = (
    _,
    selectedPaymentTypes,
    info
  ) => {
    flushSync(() => {
      if (info.type === 'all') {
        setSelectedPaymentTypes(selectedRowKeys.length !== paymentTypeOptions.length ? paymentTypeOptions : []);
      } else {
        setSelectedPaymentTypes(selectedPaymentTypes);
      }
    });
  };

  const handleOnAllRowsSelect: TableRowSelection<DepositRunPaymentTypeOptions>['onSelectAll'] = (selected) => {
    setSelectedPaymentTypes(selected && paymentTypeOptions ? paymentTypeOptions : []);
  };

  return (
    <ModalWithCloseConfirm
      title={translations.depositRuns.createModal.title}
      open
      onCancel={onClose}
      onOk={() => form.submit()}
      okText={translations.shared.saveButtonText}
      okButtonProps={{ disabled: isSaving }}
      isSaving={isSaving}
      savingMessage={translations.depositRuns.createModal.savingMessage}
      width={500}
    >
      <Form<DepositRunFormValues>
        {...layout}
        form={form}
        initialValues={initialValues}
        onFinish={handleCreateDeposit}
        autoComplete='off'
      >
        <Form.Item
          label={translations.depositRuns.createModal.labels.endDate}
          name='endDate'
          rules={[getRequiredRule(translations.depositRuns.createModal.labels.endDate)]}
        >
          <DatePicker
            format={dateFormat}
            placeholder={translations.financialPeriods.selectDate}
            disabledDate={(date) => date.isAfter(dayjs())}
          />
        </Form.Item>
        <SelectPaymentWrapper>
          <RequiredText>*</RequiredText>
          <LabelText>{translations.depositRuns.createModal.labels.paymentTypes} :</LabelText>
        </SelectPaymentWrapper>
        <TableWithCustomFiltering
          tableKey={TableKey.DepositRunPaymentTypes}
          dataSource={paymentTypeOptions}
          columns={[
            {
              title: translations.depositRuns.createModal.columns.paymentTypes,
              key: 'label',
              ...generalFilteringAndSortingSettings,
              filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
                translations.depositRuns.createModal.columns.paymentTypes
              ),
              onFilter: getOnFilterFunctionFor('label'),
              sorter: getStringCompareFunctionFor('label'),
              render: (pt) => pt.label,
              width: 400,
            },
          ]}
          rowKey='value'
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            onChange: handleOnRowCheckedChange,
            onSelectAll: handleOnAllRowsSelect,
            selectedRowKeys: getSelectedRowKeys(),
          }}
        />
        {paymentTypeError && (
          <RequiredErrorText>
            {translations.shared.getIsRequiredMessage(translations.depositRuns.createModal.labels.paymentTypes)}
          </RequiredErrorText>
        )}
      </Form>
    </ModalWithCloseConfirm>
  );
};
