import React from 'react';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { AddOnCardCloverConnect } from './AddOnCardCloverConnect';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';

export const AddOnCardCloverConnectContainer: React.FC = () => {
  const { navigateTo } = useNavigationToRoute();

  const handleClick = () => {
    navigateTo(routes.cardConnectSettings);
  };

  return (
    <SaveSpinner isSaving={false}>
      <AddOnCardCloverConnect onConfigureClick={handleClick} />
    </SaveSpinner>
  );
};
