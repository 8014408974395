import { print } from 'graphql';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { OfflineUpsert } from '../../../graph/queries/general';
import { getConnectionId } from '../../../hooks/authHooks';
import { RxUpsert } from '../schemas/upsertSchema';

export const upsertPushBuilder = (organizationId: string) => (upsert: RxUpsert) => {
  return {
    query: print(OfflineUpsert),
    variables: {
      organizationId,
      upsert: {
        record: {
          type_name: upsert.type_name,
          offline_id: upsert.upsert_offline_id,
          connection_id: getConnectionId(),
          instruction: JSON.stringify({
            ...upsert.upsert,
            id: upsert.is_new ? ID_FOR_OBJECT_CREATION : upsert.id,
          }),
        },
      },
    },
  };
};
