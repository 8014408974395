import { File, FileS3Url, FileUpsert, Query } from '../graph/types';
import { formats } from '../constants/formats';
import { FileUpsertValues } from '../components/RecordSingleView/Files/FileCard';
import { RecordItem, RecordType, renderBottomLineForNotesAndFiles } from '../components/Records/recordUtils';
import Paragraph from 'antd/lib/typography/Paragraph';
import dayjs from 'dayjs';
import { translations } from '../constants/translations';
import React from 'react';
import { TagColor } from '../constants/tagColor';
import { getTag } from './tags';
import AppSyncService from '../services/AppSyncService/AppSyncService';
import { GetUrlForFileDownload, GetUrlForFileUpload } from '../graph/queries/fileUrls';
import { useGetOrganizationIdFromRoute } from '../hooks/route/routeParameterHooks';
import { useGetOrganization } from '../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../graph/queries/organizations';
import { useMutationWithMessages } from '../hooks/ajax/generalMutationHooks';
import { useUpsertFile } from '../hooks/ajax/file/fileHooks';
import { showErrorMessage } from '../components/Notification/notificationUtil';

export const getRecordFromFile = (file: File): RecordItem => {
  const renderDescription = () => {
    return (
      <>
        <Paragraph style={{ marginBottom: 2 }} ellipsis={{ rows: 1 }}>
          {file.name}
        </Paragraph>
        <Paragraph style={{ marginBottom: 2 }} ellipsis={{ rows: 1 }}>
          {file.description}
        </Paragraph>
      </>
    );
  };

  return {
    originalObject: file,
    recordId: file.__typename + file.id,
    type: RecordType.typeFile,
    typeName: file.type_name,
    date: dayjs(file.date),
    created: dayjs(file.created),
    renderBottomLine: () => renderBottomLineForNotesAndFiles(file),
    renderDescription,
    getTag: () => getTag(TagColor.Color5, translations.recordList.file),
  };
};

export const getVoidFileUpsert = (file: File): FileUpsert => {
  return {
    id: file.id,
    void: true,
  };
};

export const getUpdateFileUpsert = (
  file: File,
  fileValues: FileUpsertValues,
  patientId?: string,
  contactId?: string
): FileUpsert => {
  return {
    id: file.id,
    record: {
      type_id: fileValues.type_id,
      description: fileValues.description,
      date: fileValues.date.format(formats.upsertDate),
      name: fileValues.name,
      pointer: fileValues.pointer,
      size: fileValues.size,
      hidden: file.hidden,
      locked: file.locked,
      contact_id: contactId,
      patient_id: patientId,
    },
  };
};

export const useGetFileRefData = (isPatient: boolean) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    isPatient ? AdditionalOrganizationField.PatientFileTypes : AdditionalOrganizationField.ContactFileTypes
  );
  const patientFileRefData = organization?.ref_patient?.file_type || [];
  const contactFileRefData = organization?.ref_contact?.file_type || [];

  return {
    fileRefData: isPatient ? patientFileRefData : contactFileRefData,
    fileRefDataLoading: organizationLoading,
  };
};

export const useSendFileUpsertToBackend = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertFile] = useMutationWithMessages(useUpsertFile);

  return async (upsert: FileUpsert, onSuccess?: () => void, successMessage?: string) => {
    try {
      const fileResult = await upsertFile({
        options: {
          variables: {
            organizationId,
            file: upsert,
          },
          update: (cache) => {
            if (upsert.void) {
              cache.evict({
                id: cache.identify({
                  __typename: 'File',
                  id: upsert.id,
                }),
              });
            }
          },
        },
        onSuccess,
        successMessage,
      });
      return fileResult.data?.upsertFile as File;
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    return null;
  };
};

export const fetchS3UploadUrl = async (organizationId: string, filePath = ''): Promise<FileS3Url> => {
  const getUploadUrlResult = await AppSyncService.client.query({
    query: GetUrlForFileUpload,
    variables: {
      organizationId,
      filePath,
    },
    fetchPolicy: 'no-cache',
  });
  return getUploadUrlResult.data.getUrlForFileUpload;
};

export const fetchS3DownloadUrl = async ({
  organizationId,
  filePointer,
  fileName,
}: {
  organizationId: string;
  filePointer: string;
  fileName: string;
}): Promise<FileS3Url | undefined> => {
  const { data } = await AppSyncService.client.query<Pick<Query, 'getUrlForFileDownload'>>({
    query: GetUrlForFileDownload,
    variables: {
      organizationId,
      filePointer,
      fileName,
    },
    fetchPolicy: 'no-cache',
  });
  return data?.getUrlForFileDownload;
};

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const res = reader.result as string;
      resolve(res.split(',')[1]);
    };
  });
};
