import React, { useState, useCallback } from 'react';
import { Button, Form, Input, Select, Collapse, Space, Radio } from 'antd';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { translations } from '../../../constants/translations';
import { getMaxLengthRule, getRequiredRule } from '../../../util/forms';
import { AddressFields } from '../RegisterPracticeForm/AddressFields';
import { Address, Maybe, Practice, PracticeDto, StripeSetupRecordUpsert } from '../../../graph/types';
import { RegistrationButton } from '../RegistrationButton';
import { useUpsertStripeTransaction } from '../../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { organizationIdParameter, routes } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { getRouteWithParameters } from '../../../hooks/route/navigationHooks';
import { InfoCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';
import { eulaContent } from './eula-content';
import { useAcceptEula } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { useGetStripeEnabledForPractice } from './StripeUtils';

const UnavailableMessage = styled.h2`
  font-weight: bold;
`;

export const registerSubscriptionSettingsTest = {
  acceptChargesCheckbox: 'acceptChargesCheckbox',
  eulaRadioGroup: 'eulaRadioGroup',
};

const SkipButton = styled(Button)`
  margin-right: 15px;
`;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};

export type RegistrationStripeBusinessType = 'company' | 'individual';

export const RegistrationStripeBusinessAllTypes: RegistrationStripeBusinessType[] = ['company', 'individual'];

interface RegistrationStripeProps<T extends Practice | PracticeDto> {
  practice?: T;
  organizationId: string;
  canSkipRegistration?: boolean;
}

type RegistrationStripeFormType = {
  businessType: string;
  address: Address[];
  practiceName: string;
  phone: string;
};

export const getStripeErrorMessage = (errorMessage?: Maybe<string>) => {
  if (!errorMessage) {
    return translations.shared.saveErrorMessage;
  }

  return errorMessage.split(': ')[1] ?? '';
};

const getBusinessTypeLabel = (businessType: RegistrationStripeBusinessType) => {
  switch (businessType) {
    case 'company':
      return translations.registration.addPracticeInfoStep.stripeBusinessType.types.company;
    case 'individual':
      return translations.registration.addPracticeInfoStep.stripeBusinessType.types.individual;
    default:
      return '';
  }
};

const getSelectableBusinessTypes = () => {
  return RegistrationStripeBusinessAllTypes.map((type) => ({
    value: type,
    label: getBusinessTypeLabel(type),
  }));
};

export const RegistrationStripe = <T extends Practice | PracticeDto>({
  practice,
  organizationId,
  canSkipRegistration = false,
}: RegistrationStripeProps<T>) => {
  const [form] = Form.useForm();
  const { referenceData } = useGetReferenceData(organizationId);
  const registerUserTranslations = translations.registration.registerUserStep;
  const [upsertStripe] = useUpsertStripeTransaction(organizationId);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [eulaAcceptedSuccessful, setEulaAcceptedSuccessful] = useState(false);
  const [upsertAcceptEula] = useAcceptEula();
  const hideUnavailableMessage = useGetStripeEnabledForPractice(practice);

  const handleAcceptEulaUpsert = useCallback(async () => {
    if (!eulaAccepted || eulaAcceptedSuccessful) {
      return;
    }
    await upsertAcceptEula({
      variables: {
        organizationId,
        agreement: {
          eula_text: eulaContent,
          eula_type: 'stripe_eula',
        },
      },
    });
    setEulaAcceptedSuccessful(true);
  }, [eulaAccepted, eulaAcceptedSuccessful, organizationId, upsertAcceptEula]);

  const initialValues = {
    practiceName: practice?.name,
    address: [practice?.address?.[0]],
    phone: practice?.phone?.[0]?.phone ?? '',
  };

  const handleOnSkip = () => {
    history.push(routes.root);
  };

  const submitStripe = async ({ practiceName, address, phone, businessType }: RegistrationStripeFormType) => {
    setIsSaving(true);
    if (!eulaAccepted) {
      showErrorMessage(translations.organizationSubscriptionSettings.eula.errMsg);
      return;
    }
    const { address_1, city, country_id, country_prov_state_id, postal_zip } = address[0];

    const setupRecordUpsert: StripeSetupRecordUpsert = {
      practice: {
        business_type: businessType,
        name_1: practiceName,
        address_1: address_1!,
        city: city!,
        country_id: country_id!,
        country_prov_state_id: country_prov_state_id!,
        postal_zip: postal_zip!,
        phone,
      },
    };

    try {
      await handleAcceptEulaUpsert();
      const { data } = await upsertStripe({
        variables: {
          organizationId,
          practiceId: practice?.id,
          setupRecord: setupRecordUpsert,
        },
      });
      const result = data?.upsertStripeTransaction;

      if (result?.success) {
        const routeWithParameters = getRouteWithParameters(routes.stripeConfirmation, {
          [organizationIdParameter]: organizationId,
        });

        const callbackUrl = window.location.origin + routeWithParameters;
        const response = await upsertStripe({
          variables: {
            organizationId,
            practiceId: practice?.id,
            accountLinkRequestRecord: {
              refresh_url: callbackUrl,
              return_url: callbackUrl,
            },
          },
        });
        if (response.data?.upsertStripeTransaction.url) {
          window.location.replace(response.data.upsertStripeTransaction.url);
        }
      } else {
        showErrorMessage(getStripeErrorMessage(result?.message));
      }
    } catch (e) {
      showErrorMessage(translations.shared.saveErrorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const onEulaPrint = () => {
    const printContainer = document.getElementById('print-container');
    if (printContainer) {
      printContainer.innerHTML = eulaContent;
      window.print();
      printContainer.innerHTML = '';
    }
  };

  return (
    <SaveSpinner isSaving={isSaving}>
      {!hideUnavailableMessage && (
        <UnavailableMessage>{translations.registration.stripe.unavailableMessage}</UnavailableMessage>
      )}
      <div dangerouslySetInnerHTML={{ __html: translations.registration.stripe.marketingText }} />
      <Form {...layout} form={form} initialValues={initialValues} onFinish={submitStripe}>
        {hideUnavailableMessage && (
          <>
            <Form.Item
              name='businessType'
              label={translations.registration.addPracticeInfoStep.stripeBusinessType.title}
              className='registration-field'
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.stripeBusinessType.title)]}
            >
              <Select
                options={getSelectableBusinessTypes()}
                placeholder={translations.registration.addPracticeInfoStep.stripeBusinessType.selectPlaceholder}
              />
            </Form.Item>
            <MaxLengthFormItem
              name='practiceName'
              label={registerUserTranslations.labels.practiceName}
              rules={[getRequiredRule(registerUserTranslations.labels.practiceName), getMaxLengthRule(50)]}
              className='registration-field'
              maxLength={50}
            >
              <Input />
            </MaxLengthFormItem>
            <AddressFields referenceData={referenceData} form={form} index={0} isBillingAddress mustBePhysicalAddress />
            <Form.Item
              name='phone'
              label={translations.registration.addPracticeInfoStep.labels.phone}
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.phone)]}
              className='registration-field'
              tooltip={{
                icon: <InfoCircleOutlined />,
                title: (
                  <div>
                    {translations.registration.stripe.tooltip.map((format) => (
                      <h5 key={format}>{format}</h5>
                    ))}
                  </div>
                ),
                color: 'black',
              }}
            >
              <Input />
            </Form.Item>
            <div style={{ width: '100%', marginBottom: '1rem' }}>
              <Collapse>
                <Collapse.Panel
                  key='1'
                  header={translations.organizationSubscriptionSettings.eula.label}
                  extra={
                    <span style={{ color: eulaAccepted ? 'green' : 'red' }}>
                      {translations.organizationSubscriptionSettings.eula.getStatus(eulaAccepted)}
                    </span>
                  }
                >
                  <div style={{ width: '100%', maxHeight: '400px', overflow: 'auto', padding: '0 0.2rem' }}>
                    <div
                      className='eula-content'
                      dangerouslySetInnerHTML={{ __html: eulaContent }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Space data-testid={registerSubscriptionSettingsTest.eulaRadioGroup}>
                        <Radio.Group
                          options={[
                            {
                              label: translations.organizationSubscriptionSettings.eula.accept,
                              value: true,
                            },
                            {
                              label: translations.organizationSubscriptionSettings.eula.doNotAccept,
                              value: false,
                            },
                          ]}
                          onChange={(e) => setEulaAccepted(e.target.value)}
                          buttonStyle='solid'
                        />
                      </Space>
                      <Button onClick={onEulaPrint}>
                        <PrinterOutlined /> {translations.organizationSubscriptionSettings.eula.printBtnText}
                      </Button>
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        )}
        <RegistrationButton
          onClick={form.submit}
          text={translations.shared.submit}
          disabled={!eulaAccepted || !hideUnavailableMessage}
          additionalContent={
            canSkipRegistration && (
              <SkipButton onClick={handleOnSkip}>{translations.registration.stripe.skipButton}</SkipButton>
            )
          }
        />
      </Form>
    </SaveSpinner>
  );
};
