import { showErrorMessage } from '../Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { Entity } from './SelectEntityModalTable';

export function toggleEntity<T extends Entity>(
  addEntity: boolean,
  entity: T,
  allEntities: T[],
  maxSelections: number
): T[] {
  if (addEntity) {
    const newSelectedEntities = allEntities.concat(entity);
    if (newSelectedEntities.length > maxSelections) {
      showMaxSelectError(maxSelections);
      return allEntities;
    }
    return newSelectedEntities;
  }
  return removeEntity(entity, allEntities);
}

export function removeEntity<T extends Entity>(entity: T, allEntities: T[]): T[] {
  return allEntities.filter((existing) => existing.id !== entity.id);
}

export const showMaxSelectError = (maxSelections: number) => {
  showErrorMessage(translations.shared.selectPatientModal.exceededSelectionsWarning(maxSelections.toString()));
};

export function selectAll<T extends Entity>(
  currentVisibleEntities: T[] | undefined,
  selectedEntities: T[],
  maxSelections: number
): T[] {
  if (!currentVisibleEntities) {
    return selectedEntities;
  }
  const newEntities = currentVisibleEntities.filter(
    (visiblePatient) => !selectedEntities.find((selectedPatient) => selectedPatient.id === visiblePatient.id)
  );
  if (newEntities.length === 0) {
    return selectedEntities;
  }
  const newSelectedEntities = selectedEntities.concat(newEntities);
  if (newSelectedEntities.length > maxSelections) {
    showMaxSelectError(maxSelections);
    return selectedEntities;
  }
  return newSelectedEntities;
}
