import React from 'react';
import { Loading } from '../../components/Loading/Loading';
import { StyledDescription, StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { WidthRestrictor } from '../../components/Restrictors/WidthRestrictor';
import { email } from '../../constants/contactData';
import { translations } from '../../constants/translations';
import { useGetDataConversion } from '../../hooks/ajax/dataConversion/dataConversionHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { DataConversionForm } from './DataConversionForm';
import { DataConversionInitialStage } from './DataConversionInitialStage';

export const DataConversion: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { dataConversion, dataConversionLoading } = useGetDataConversion(organizationId);

  if (dataConversionLoading) {
    return <Loading />;
  }

  return (
    <WidthRestrictor>
      <StyledPageHeaderWithMargin title={`${translations.dataConversion.title}`} />
      <StyledDescription>
        <p>{translations.dataConversion.description}</p>
        <p dangerouslySetInnerHTML={{ __html: translations.dataConversion.desciptionQuestions(email) }} />
      </StyledDescription>
      {dataConversion ? <DataConversionForm dataConversion={dataConversion} /> : <DataConversionInitialStage />}
    </WidthRestrictor>
  );
};
