import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { contactIdParameter, organizationIdParameter, routes } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { Contact } from '../../graph/types';
import { useUpdateContact } from '../../hooks/ajax/contact/contactHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { SelectContactModal } from '../SelectContactModal/SelectContactModal';
import { setSaveSpinnerAction, setTextConfirmAction } from '../UtilsProvider/store/actions';
import { UtilsContext } from '../UtilsProvider/store/state';
import { GetContactPatients } from '../../graph/queries/patients';

interface MergeContactProps {
  visible: boolean;
  contact: Contact;
  onClose: () => void;
}

const getMergeContactsDescription = (contactFrom: Contact, contactInto: Contact) => (
  <>
    {translations.viewContactPage.selectContactModal.mergeContactsModalDescription[0]}{' '}
    <b>
      {contactFrom.number} {contactFrom.name}
    </b>{' '}
    {translations.viewContactPage.selectContactModal.mergeContactsModalDescription[1]}{' '}
    <b>
      {contactInto.number} {contactInto.name}
    </b>
    . {translations.viewContactPage.selectContactModal.mergeContactsModalDescription[2]}
  </>
);

export const MergeContacts: React.FC<MergeContactProps> = ({ visible, contact, onClose }) => {
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const organizationId = useGetOrganizationIdFromRoute();
  const history = useHistory();

  const [updateContact] = useUpdateContact();

  const handleContactsMerge = async (mergeToContactId: string) => {
    dispatchUtils(setSaveSpinnerAction({ isSaving: true }));

    await updateContact({
      variables: {
        organizationId,
        contact: {
          id: contact.id,
          fnMergeToContactId: mergeToContactId,
        },
      },
      refetchQueries: [
        {
          query: GetContactPatients,
          variables: {
            contactId: mergeToContactId,
            organizationId,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: 'Contact',
            id: contact.id,
          }),
        });
        cache.evict({ fieldName: 'getCurrentUser' });
        cache.gc();
      },
    });
    dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
    history.replace(routes.contacts.replace(organizationIdParameter, organizationId));
    history.push(
      routes.viewContact.replace(organizationIdParameter, organizationId).replace(contactIdParameter, mergeToContactId)
    );
  };

  const handleContactsMergeSelect = (contacts: Contact[]) => {
    const mergeToContact = contacts[0];

    onClose();
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: () => handleContactsMerge(mergeToContact.id),
        title: translations.viewContactPage.selectContactModal.mergeContactModalTitle,
        description: getMergeContactsDescription(contact, mergeToContact),
      })
    );
  };

  if (!visible) {
    return null;
  }

  return (
    <SelectContactModal
      title={translations.viewContactPage.selectContactModal.selectSearchTitle}
      onCancel={onClose}
      finishSelectContacts={handleContactsMergeSelect}
      visible={visible}
      excludedIds={[contact.id]}
      selectContact={(contact) => handleContactsMergeSelect([contact])}
    />
  );
};
