import gql from 'graphql-tag';

export const RecordChangedFields = gql`
  fragment RecordChangedFields on RecordChanged {
    id
    connectionId
    time
    userId
    userName
  }
`;
