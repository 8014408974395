import React, { FC, useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { TableKey } from '../../../hooks/tableHooks';
import { CustomColumnType, TableWithCustomFiltering } from '../../TableWithCustomFiltering/TableWithCustomFiltering';
import { getOfflineUserDataByOrganizationSubscription, OfflineUser, getUserRolesColors } from '../util';
import { getTag } from '../../../util/tags';
import columns from './columns';

import { TagWrapper } from './OfflineUsersTable.styles';
import { OrganizationSubscriptionResult } from '../../../graph/types';
import { useGetUsers } from '../../../hooks/ajax/user/userHooks';

type Props = {
  subscription?: OrganizationSubscriptionResult;
  onToggleUser: (user: OfflineUser, toggled: boolean) => void;
};

const OfflineUsersTable: FC<Props> = ({ subscription, onToggleUser }) => {
  const [offlineUsers, setOfflineUsers] = useState<OfflineUser[]>([]);
  const { users } = useGetUsers(subscription?.organization.id);

  useEffect(() => {
    if (subscription && !offlineUsers.length && users) {
      setOfflineUsers(
        getOfflineUserDataByOrganizationSubscription(subscription.organization, users).sort((a, b) => {
          if ((a.enableOffline && b.enableOffline) || (!a.enableOffline && !b.enableOffline)) {
            return a.name.localeCompare(b.name);
          }
          return Number(b.enableOffline) - Number(a.enableOffline);
        })
      );
    }
  }, [subscription, offlineUsers, users]);

  const userRoles = useMemo(
    () => getUserRolesColors(offlineUsers.reduce<string[]>((acc, value) => acc.concat(value.roles), [])),
    [offlineUsers]
  );

  const tableColumns: CustomColumnType<OfflineUser>[] = useMemo(() => {
    const onToggleSwitch = (user: OfflineUser, toggled: boolean) => {
      onToggleUser(user, toggled);
      setOfflineUsers(
        offlineUsers.map((offlineUser) => ({
          ...offlineUser,
          enableOffline: offlineUser.userId === user.userId ? toggled : offlineUser.enableOffline,
        }))
      );
    };

    return Object.values(columns).map((column) => {
      switch (column.key) {
        case 'enableOffline': {
          return {
            ...column,
            render: (value: boolean, record: OfflineUser) => (
              <Switch checked={value} onClick={() => onToggleSwitch(record, !value)} />
            ),
          };
        }
        case 'roles': {
          return {
            ...column,
            render: (value: string[]) => (
              <>
                {value.map((tag, i) => (
                  <TagWrapper key={i}>{getTag(userRoles[tag], tag)}</TagWrapper>
                ))}
              </>
            ),
            filters: Object.keys(userRoles).map((role) => ({ text: role, value: role })),
          };
        }
        default:
          return column;
      }
    });
  }, [userRoles, onToggleUser, offlineUsers]);

  return (
    <TableWithCustomFiltering
      tableKey={TableKey.SubscriptionOfflineUsers}
      rowKey='userId'
      dataSource={offlineUsers}
      columns={tableColumns}
      pagination
    />
  );
};

export default OfflineUsersTable;
