import { Info, InfoDto, Practice, PracticeDto, PracticeUpsert } from '../../graph/types';
import { cloneDeepWithExclude } from '../../util/cloneDeepWithExclude';
import {
  mapAddressToAddressUpsert,
  mapAddressToAddressUpsertDto,
} from '../../classes/upsertGenerators/AddressUpsertGenerator';
import { PracticeInfoType } from '../../constants/referenceData/practiceReferenceData';

export function buildFullPracticeUpsertFromPractice(practice: Practice): PracticeUpsert {
  return {
    id: practice.id,
    record: { name: practice.name, name_short: practice.name_short, email: practice.email, website: practice.website },
    address: mapAddressToAddressUpsert(practice.address),
    phone: cloneDeepWithExclude(practice.phone, ['__typename']),
  };
}

export function buildFullPracticeUpsertFromPracticeDto(practice: PracticeDto): PracticeUpsert {
  return {
    id: practice.id,
    record: { name: practice.name, name_short: practice.name_short, email: practice.email, website: practice.website },
    address: mapAddressToAddressUpsertDto(practice.address),
    phone: cloneDeepWithExclude(practice.phone, ['__typename']),
  };
}

export function getPracticeFormInfoValuesFromPracticeInfo(practiceInfo: Info[] = []) {
  const infoMap: { [key in PracticeInfoType]?: string | null } = {};
  practiceInfo.forEach((info) => {
    if (Object.values(PracticeInfoType).includes(info.type_id as PracticeInfoType)) {
      infoMap[info.type_id as PracticeInfoType] = info.value;
    }
  });
  return infoMap;
}

export function getPracticeFormInfoValuesFromPracticeInfoDto(practiceInfo: InfoDto[] = []) {
  const infoMap: { [key in PracticeInfoType]?: string | null } = {};
  practiceInfo.forEach((info) => {
    if (Object.values(PracticeInfoType).includes(info.type_id as PracticeInfoType)) {
      infoMap[info.type_id as PracticeInfoType] = info.value;
    }
  });
  return infoMap;
}
