import { translations } from '../../../constants/translations';
import { Button } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { showErrorMessage, showSuccessMessage } from '../../Notification/notificationUtil';
import { Store } from 'antd/lib/form/interface';
import { isMatch } from '../../../util/objectComparisons';
import { useForm } from 'antd/lib/form/Form';
import { useUpsertLedger } from '../../../hooks/ajax/ledger/ledgerHooks';
import { useGetContactAddresses } from '../../../hooks/ajax/contact/contactHooks';
import {
  CardConnectTransactionRecordUpsert,
  CreditCard,
  InvoiceContact,
  Ledger,
  LedgerPaymentTypeDto,
  LedgerUpsert,
  Organization,
  StripeTransactionRecordUpsert,
} from '../../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import dayjs from 'dayjs';
import { Loading } from '../../Loading/Loading';
import { PaymentForm, PaymentModalFormFields } from './PaymentForm/PaymentForm';
import { LedgerInfoType, LedgerPaymentTypeValue } from '../../../constants/referenceData/ledgerReferenceData';
import { CreditCardProcess } from './CreditCardProcess/CreditCardProcess';
import { CreditCardManual } from './CreditCardManual/CreditCardManual';
import './PaymentModal.css';
import { CreditCardSwipe } from './CreditCardSwipe/CreditCardSwipe';
import { ModalContentAndFooter } from './ModalContentAndFooter';
import ModalWithCloseConfirm, { ModalWithCloseConfirmProps } from '../../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { v4 as uuid } from 'uuid';
import {
  useUpsertCardConnectTransaction,
  useUpsertStripeTransaction,
} from '../../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { findPaymentType, getPaymentDisplayType, getTransactionVariables } from './paymentModalUtil';
import { ViewSubscriptionContext } from '../../ViewSubscription/store/state';
import ViewSubscriptionActions from '../../ViewSubscription/store/actions';
import { PriceValue } from '../../PriceValue/PriceValue';
import { TitleWrapper, AccountBalance, AccountBalanceLabel } from './PaymentModal.styles';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { getIsStripeProcessor } from '../../../util/stripeConnectUtils';
import { getAvailablePaymentMethods } from '../../../util/paymentsUtils';

const getAvailableDeviceTypes = (organization: Organization | undefined, practiceId: string) =>
  organization?.ref_financial?.payment_device_type?.filter((deviceType) => deviceType.practice_id === practiceId) || [];

interface PaymentModalSharedProps {
  practiceId: string;
  onClose: () => void;
  onSuccess?: () => void;
  defaultPaymentAmount?: number;
  mapFormFieldsToLedgerUpsert: (formFields: PaymentModalFormFields) => LedgerUpsert;
  ledger?: {
    loading: boolean;
    data: Ledger;
  };
  ledgers?: Ledger[];
  contacts?: InvoiceContact[];
  setSelectedContact?: (contact: InvoiceContact) => void;
}

interface PaymentModalProps extends PaymentModalSharedProps {
  contactId: string;
  usingPaymentModalWrapper?: boolean;
  setModalWithCloseConfirmProps?: (props: ModalWithCloseConfirmProps) => void;
}

export enum FormStage {
  Default,
  Process,
  Swipe,
  Manual,
}

export const getLedgerInfoValue = (infoType: LedgerInfoType, ledger?: Ledger) =>
  ledger?.info?.find((info) => info.type_id === infoType)?.value || '';

const loaderHeight = '380px';
const defaultModalWidth = 660;

export const PaymentModal: React.FC<PaymentModalProps> = ({
  contactId,
  practiceId,
  onClose,
  onSuccess,
  defaultPaymentAmount,
  mapFormFieldsToLedgerUpsert,
  ledger,
  ledgers,
  contacts,
  setSelectedContact,
  usingPaymentModalWrapper = false,
  setModalWithCloseConfirmProps,
}) => {
  const [form] = useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();

  const availablePaymentMethods = getAvailablePaymentMethods(organization, practiceId);
  const availableDeviceTypes = getAvailableDeviceTypes(organization as Organization | undefined, practiceId);
  const { dispatch: dispatchViewPatient } = useContext(ViewSubscriptionContext);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState(false);
  const [upsertLedger] = useUpsertLedger();
  const [selectedCreditCard, setSelectedCreditCard] = useState<CreditCard>();
  const [paymentType, setPaymentType] = useState<LedgerPaymentTypeDto | undefined>();
  const [stage, setStage] = useState<FormStage>(FormStage.Default);
  const [formValues, setFormValues] = useState<PaymentModalFormFields>();

  const { contact, contactLoading } = useGetContactAddresses({ contactId, organizationId });

  const isStripeProcessor = getIsStripeProcessor(organization);

  const [upsertCardConnectTransaction] = useUpsertCardConnectTransaction(organizationId, contactId);
  const [upsertStripeTransaction] = useUpsertStripeTransaction(organizationId, contactId);
  const [clientRequestToken, setClientRequestToken] = useState<string>('');

  const upsertCardTransaction = isStripeProcessor ? upsertStripeTransaction : upsertCardConnectTransaction;

  useEffect(() => {
    setClientRequestToken(uuid());
  }, []);

  useEffect(() => {
    if (!paymentType && ledger?.data) {
      const paymentType = getPaymentDisplayType(availablePaymentMethods, ledger.data);
      setPaymentType(paymentType);
      form.setFieldsValue({ method: paymentType?.id || undefined });
    } else if (!paymentType) {
      const creditPaymentType = findPaymentType(availablePaymentMethods, LedgerPaymentTypeValue.Credit);
      if (creditPaymentType) {
        setPaymentType(creditPaymentType);
        form.setFieldsValue({ method: creditPaymentType.id });
      }
    }
  }, [availablePaymentMethods, form, paymentType, ledger]);

  useEffect(() => {
    if (selectedCreditCard && paymentType && paymentType.id !== LedgerPaymentTypeValue.Credit) {
      setSelectedCreditCard(undefined);
    }
  }, [paymentType, selectedCreditCard]);

  const initialData = {
    amount: ledger?.data?.total || defaultPaymentAmount,
    date: ledger?.data ? dayjs(ledger?.data?.financial_date) : dayjs(),
    device: availableDeviceTypes.length === 1 ? availableDeviceTypes[0].id : undefined,
    payer: getLedgerInfoValue(LedgerInfoType.LGR_PAYER, ledger?.data),
    check: getLedgerInfoValue(LedgerInfoType.LGR_CHECKNUM, ledger?.data),
  };

  const onSaveNonCreditCardRecord = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const fieldValues = form.getFieldsValue();
    saveRecord(fieldValues);
  };

  const saveRecord = async (values: PaymentModalFormFields) => {
    setFormValues(values);
    try {
      setIsSaving(true);

      const paymentUpsert = mapFormFieldsToLedgerUpsert(values);

      const { data } = await upsertLedger({
        variables: {
          organizationId,
          ledger: paymentUpsert,
        },
      });

      if (data?.upsertLedger) {
        setUnsavedData(false);
        dispatchViewPatient(ViewSubscriptionActions.setEditing(false));
        showSuccessMessage('Payment Successful');
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      }
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
      setIsSaving(false);
    }
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    const hasChanges = !isMatch(null, allValues);
    setUnsavedData(hasChanges);
    dispatchViewPatient(ViewSubscriptionActions.setEditing(hasChanges));
    const methodValue = form.getFieldValue('method');
    if (methodValue) {
      setPaymentType(availablePaymentMethods.find((ledgerType) => ledgerType.id === methodValue));
    }
  };

  const validateAndSwitchToStage = (stage: FormStage) => async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    setFormValues(form.getFieldsValue());
    setStage(stage);
  };

  const isPaymentType = (type: LedgerPaymentTypeValue) => {
    return paymentType && paymentType.id === type;
  };

  function getFooterForCreditSelected() {
    const isSwipeDisabled = availableDeviceTypes.length < 1 || !form.getFieldValue('device');
    return !selectedCreditCard ? (
      <>
        <Button onClick={validateAndSwitchToStage(FormStage.Manual)} disabled={isSaving}>
          {translations.paymentModal.buttons.manualButton}
        </Button>
        {!isStripeProcessor && (
          <Button
            type='primary'
            onClick={validateAndSwitchToStage(FormStage.Swipe)}
            disabled={isSaving || isSwipeDisabled}
          >
            {translations.paymentModal.buttons.swipeButton}
          </Button>
        )}
      </>
    ) : (
      <Button type='primary' onClick={validateAndSwitchToStage(FormStage.Process)} disabled={isSaving}>
        {translations.paymentModal.buttons.processButton}
      </Button>
    );
  }

  function getPaymentModalFooterForDefaultStage() {
    return (
      <>
        <Button onClick={onClose} disabled={isSaving}>
          {translations.shared.cancelButtonText}
        </Button>
        {isPaymentType(LedgerPaymentTypeValue.Credit) && !ledger?.data ? (
          getFooterForCreditSelected()
        ) : (
          <Button type='primary' htmlType='submit' onClick={onSaveNonCreditCardRecord} disabled={isSaving}>
            {translations.shared.saveButtonText}
          </Button>
        )}
      </>
    );
  }

  const goToDefaultStage = () => {
    setStage(FormStage.Default);
  };

  const getStageContents = () => {
    if (contactLoading) {
      return <Loading height={loaderHeight} />;
    }
    if (!organization || !contact) {
      return <p>{translations.shared.errorLoadingPage}</p>;
    }

    const processCreditCardTransaction = async (
      formValues: PaymentModalFormFields,
      transactionRecordUpsert: CardConnectTransactionRecordUpsert | StripeTransactionRecordUpsert
    ) => {
      try {
        dispatchViewPatient(ViewSubscriptionActions.setEditing(false));
        const payment = mapFormFieldsToLedgerUpsert(formValues);

        const transactionVariables = getTransactionVariables(
          isStripeProcessor,
          organizationId,
          practiceId,
          clientRequestToken,
          transactionRecordUpsert,
          payment,
          contact
        );

        const { data } = await upsertCardTransaction({
          variables: transactionVariables,
        });
        const result = isStripeProcessor ? data?.upsertStripeTransaction : data?.upsertCardConnectTransaction;

        if (result?.success) {
          showSuccessMessage(translations.paymentModal.electronicTransactionComplete);
          onSuccess?.();
          onClose();
        } else {
          showErrorMessage(result?.message ?? translations.paymentModal.electronicTransactionUnknownError);
        }
      } catch (err) {
        dispatchViewPatient(ViewSubscriptionActions.setEditing(true));
        showErrorMessage((err as Error).message ?? err);
      }
    };

    switch (stage) {
      case FormStage.Process: {
        return (
          selectedCreditCard &&
          formValues && (
            <CreditCardProcess
              creditCard={selectedCreditCard}
              formValues={formValues}
              setIsSaving={setIsSaving}
              onCancel={goToDefaultStage}
              processTransaction={processCreditCardTransaction}
            />
          )
        );
      }
      case FormStage.Manual: {
        return (
          formValues && (
            <CreditCardManual
              formValues={formValues}
              setIsSaving={setIsSaving}
              onCancel={goToDefaultStage}
              processTransaction={processCreditCardTransaction}
              practiceId={organization.practice[0].id}
              isStripeProcessor={isStripeProcessor}
              contact={contact}
            />
          )
        );
      }
      case FormStage.Swipe: {
        return (
          formValues &&
          clientRequestToken && (
            <CreditCardSwipe
              organizationId={organizationId}
              practiceId={practiceId}
              formValues={formValues}
              contact={contact}
              goBack={goToDefaultStage}
              closeModal={onClose}
              payment={mapFormFieldsToLedgerUpsert(formValues)}
              clientRequestToken={clientRequestToken}
              setClientRequestToken={setClientRequestToken}
              onSuccess={onSuccess}
            />
          )
        );
      }
      default:
        return (
          <ModalContentAndFooter footer={getPaymentModalFooterForDefaultStage()}>
            <PaymentForm
              contactId={contactId!}
              form={form}
              initialFormData={initialData}
              handleFormValueChange={handleValueChange}
              paymentType={paymentType}
              setCreditCard={setSelectedCreditCard}
              creditCard={selectedCreditCard}
              paymentMethods={availablePaymentMethods}
              paymentDeviceTypes={availableDeviceTypes}
              isElectronicPayment={!!ledger?.data?.payment_electronic_id}
              isInEditModal={!!ledger?.data}
              ledgers={ledgers}
              contacts={contacts}
              setSelectedContact={setSelectedContact}
              setIsSaving={setIsSaving}
              isStripeProcessor={isStripeProcessor}
            />
          </ModalContentAndFooter>
        );
    }
  };

  const getTitle = useCallback(() => {
    switch (stage) {
      case FormStage.Manual:
        return translations.paymentModal.manualTitle;
      case FormStage.Process:
        return translations.paymentModal.processTitle;
      case FormStage.Swipe:
        return translations.paymentModal.swipeTitle;
      default:
        if (ledger) {
          return translations.paymentModal.editTitle;
        }
        return (
          <TitleWrapper>
            <div>{translations.paymentModal.title}</div>
            <AccountBalance>
              <AccountBalanceLabel>{translations.viewContactPage.accountBalance}: </AccountBalanceLabel>
              <PriceValue
                component='span'
                value={+(contact?.balance_posted || 0)}
                colorNegative='green'
                colorPositive='red'
              />
            </AccountBalance>
          </TitleWrapper>
        );
    }
  }, [ledger, stage, contact]);

  const getModalWidth = (currentStage: FormStage) => {
    if (currentStage === FormStage.Default) {
      return defaultModalWidth;
    }
    return 520;
  };

  const getRenderedContent = () => (ledger?.loading ? <Loading height={loaderHeight} /> : getStageContents());

  const modalProps: ModalWithCloseConfirmProps = useMemo(
    () => ({
      className: 'PaymentModal',
      title: getTitle(),
      isSaving,
      changesMade: unsavedData,
      open: true,
      onCancel: onClose,
      footer: null,
      hideCloseIcon: stage === FormStage.Swipe,
      warningMessageOnRouteChange: translations.shared.getUnsavedDataNavigationWarning(
        translations.paymentModal.entity
      ),
      savingMessage:
        stage === FormStage.Default
          ? translations.loadingComponent.saving
          : translations.loadingComponent.processingTransaction,

      width: getModalWidth(stage),
    }),
    [getTitle, isSaving, onClose, stage, unsavedData]
  );

  useEffect(() => {
    setModalWithCloseConfirmProps?.(modalProps);
  }, [modalProps, setModalWithCloseConfirmProps]);

  return !usingPaymentModalWrapper ? (
    <ModalWithCloseConfirm {...modalProps}>{getRenderedContent()}</ModalWithCloseConfirm>
  ) : (
    <>{getRenderedContent()}</>
  );
};

interface PaymentModalWrapperProps extends PaymentModalSharedProps {
  contactId?: string;
}

export const PaymentModalWrapper: React.FC<PaymentModalWrapperProps> = ({ contactId, ...otherProps }) => {
  const [modalProps, setModalProps] = useState<ModalWithCloseConfirmProps>({
    className: 'PaymentModal',
    title: translations.paymentModal.title,
    open: true,
    onCancel: otherProps.onClose,
    footer: null,
    width: defaultModalWidth,
  });

  return (
    <ModalWithCloseConfirm {...modalProps}>
      {!contactId ? (
        <Loading height={loaderHeight} />
      ) : (
        <PaymentModal
          contactId={contactId}
          {...otherProps}
          setModalWithCloseConfirmProps={setModalProps}
          usingPaymentModalWrapper
        />
      )}
    </ModalWithCloseConfirm>
  );
};
