import { Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { translations } from '../../constants/translations';
import { CreditCardTable } from '../PaymentModal/PaymentModal/PaymentForm/CreditCardTable';
import {
  CardConnectManualSettings,
  Contact,
  CreditCard,
  DemographicReferenceData,
  OrganizationDto,
  PracticeDto,
} from '../../graph/types';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import AddCardModal from './AddCardModal';
import { CreditCardTokenInfo } from '../../hooks/stripeHooks';
import {
  useUpsertCardConnectTransaction,
  useUpsertStripeTransaction,
} from '../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { getCreditCardSaveVariablesDto } from '../PaymentModal/PaymentModal/creditCardMappingUtil';
import { getIsStripeProcessor } from '../../util/stripeConnectUtils';

interface StoredCardsProps {
  organization: OrganizationDto;
  practice: PracticeDto;
  contact: Contact;
  visible: boolean;
  onClose: () => void;
}

const StoredCardsModal: React.FC<StoredCardsProps> = ({
  organization,
  practice,
  contact,
  visible,
  onClose,
}: StoredCardsProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState<CreditCard>();
  const [showNewCardModal, setShowNewCardModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [saveSpinnerOn, saveSpinnerText] = useMemo(() => {
    return [isSaving, isSaving ? translations.loadingComponent.saving : translations.loadingComponent.loading];
  }, [isSaving]);

  const isStripeProcessor = getIsStripeProcessor(organization);

  const [upsertCardConnectTransaction] = useUpsertCardConnectTransaction(practice.organization_id, contact.id);
  const [upsertStripeTransaction] = useUpsertStripeTransaction(practice.organization_id, contact.id);
  const upsertCardTransaction = isStripeProcessor ? upsertStripeTransaction : upsertCardConnectTransaction;

  const handleModalClose = () => {
    onClose();
  };

  const handleNewCardModalClose = () => {
    setShowNewCardModal(false);
  };

  const handleShowNewCardModal = () => {
    setShowNewCardModal(true);
  };

  const handleNewCardSave = async (cc: CreditCardTokenInfo, primary: boolean, cardNote?: string) => {
    setIsSaving(true);
    setErrorMessage(undefined);
    const variables = getCreditCardSaveVariablesDto(practice, contact, isStripeProcessor, cc, primary, cardNote);
    const result = await upsertCardTransaction({
      variables,
    });
    const data = isStripeProcessor ? result.data?.upsertStripeTransaction : result.data?.upsertCardConnectTransaction;
    if (!data?.success) {
      setErrorMessage(data?.message ?? '');
    } else {
      setShowNewCardModal(false);
    }
    setIsSaving(false);
  };

  const tokenizerExtraData = {
    countryId: contact.address?.[0]?.country_id ?? '',
    provStateId: contact.address?.[0]?.country_prov_state_id ?? '',
  };

  return (
    <Modal
      open={visible}
      onCancel={handleModalClose}
      title={translations.viewContactPage.storedCardsModal.title}
      okText={translations.viewContactPage.storedCardsModal.addNewCard}
      cancelText={translations.shared.closeButtonText}
      onOk={handleShowNewCardModal}
      width={720}
    >
      <SaveSpinner isSaving={saveSpinnerOn} savingMessage={saveSpinnerText}>
        <CreditCardTable
          contactId={contact.id}
          setCreditCard={setSelectedCreditCard}
          creditCard={selectedCreditCard}
          setIsSaving={setIsSaving}
          isStripeProcessor={isStripeProcessor}
          showNoCardsMessage
          hideRowSelection
        />
        <AddCardModal
          isStripeProcessor={isStripeProcessor}
          visible={showNewCardModal}
          onSave={handleNewCardSave}
          onClose={handleNewCardModalClose}
          onError={(err?: string) => setErrorMessage(err)}
          tokenizerExtraData={tokenizerExtraData}
          cardConnectManual={(practice.card_connect_manual as CardConnectManualSettings) || undefined}
          addressDemographics={(organization?.ref_demographics as DemographicReferenceData) || undefined}
          errorMessage={errorMessage}
        />
      </SaveSpinner>
    </Modal>
  );
};

export default StoredCardsModal;
