import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import { GetServiceText } from '../../../graph/queries/services';
import { InfoText, Query, QueryGetServiceTextArgs } from '../../../graph/types';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';

export const getServiceText = async (variables: QueryGetServiceTextArgs, onSuccess: (infoText: InfoText) => void) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getServiceText'>>({
      query: GetServiceText,
      variables,
    });

    if (data?.getServiceText) {
      onSuccess(data.getServiceText);
    } else {
      showErrorMessage(translations.rapidBilling.error.serviceText);
    }
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.serviceText);
  }
};
