import React from 'react';
import { Form } from 'antd';
import { getMaxLengthRule } from '../../util/forms';
import { FormItemProps } from 'antd/lib/form';

export const MaxLengthFormItem = ({ children, maxLength, ...fieldProps }: { maxLength: number } & FormItemProps) => {
  return (
    <Form.Item {...fieldProps} rules={[...(fieldProps.rules ?? []), getMaxLengthRule(maxLength)]}>
      {children}
    </Form.Item>
  );
};
