import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import Popconfirm from 'antd/lib/popconfirm';
import { translations } from '../../../constants/translations';

const DeleteButton = styled(Button)`
  margin-left: auto;
`;

const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const popconfirmTitle = (
  <>
    {translations.reminderRuns.popconfirm.delete.title1}
    <br />
    {translations.reminderRuns.popconfirm.delete.title2}
  </>
);

type Props = {
  onSubmit: () => void;
};

const DeleteBatch = ({ onSubmit }: Props) => (
  <Wrapper>
    <Popconfirm
      title={popconfirmTitle}
      okText={translations.reminderRuns.actions.yes}
      cancelText={translations.reminderRuns.actions.cancel}
      onConfirm={onSubmit}
      placement='topRight'
    >
      <DeleteButton type='primary' icon={<DeleteOutlined />}>
        {translations.reminderRuns.actions.delete}
      </DeleteButton>
    </Popconfirm>
  </Wrapper>
);

export default DeleteBatch;
