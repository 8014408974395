import { Address, AddressDto, AddressUpsert, Country, Maybe, SystemReferenceDataTypes } from '../../graph/types';
import { isMatch, objectIsEmpty } from '../../util/objectComparisons';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import { AddressFormFields } from '../../components/AddressBlock/AddressBlock';
import { cloneDeepWithExclude } from '../../util/cloneDeepWithExclude';
import { getCountryNameKey } from '../../util/countrySelectorUtil';
import { excludeExisting } from '../../util/filterUtil';

export enum AddressType {
  Physical = '1',
  Mailing = '2',
}

export interface AddressUpsertInfo {
  index: number;
  type: AddressType;
  deleteAddress?: boolean;
}

export function mapAddressToAddressUpsert(addresses: Address[]): AddressUpsert[] {
  return cloneDeepWithExclude(addresses, [
    '__typename',
    'address_type_name_key',
    'country_prov_state_name_key',
    'country_name_key',
  ]);
}

export function mapAddressToAddressUpsertDto(addresses: AddressDto[]): AddressUpsert[] {
  return cloneDeepWithExclude(addresses, [
    '__typename',
    'address_type_name_key',
    'country_prov_state_name_key',
    'country_name_key',
  ]);
}

export function mapAddressUpsertToAddress(
  addressUpsert: AddressUpsert[],
  referenceData?: Maybe<SystemReferenceDataTypes> | undefined,
  existingAddress: Address[] = [],
  excludeVoided = true
): Address[] {
  const address = excludeExisting(addressUpsert, existingAddress);
  const addressUpsertCopy = excludeVoided ? addressUpsert.filter((upsertItem) => !upsertItem.void) : addressUpsert;
  addressUpsertCopy
    .filter((upsertItem) => !upsertItem.void)
    .forEach((addressUpsertItem) => {
      address.push({
        ...cloneDeepWithExclude(addressUpsertItem, ['void']),
        country_name_key: getCountryNameKey(referenceData?.country ?? [], addressUpsertItem.country_id || ''),
        country_prov_state_name_key: getCountryNameKey(
          referenceData?.country ?? [],
          addressUpsertItem.country_prov_state_id || ''
        ),
        id: addressUpsertItem.id ?? ID_FOR_OBJECT_CREATION,
      });
    });
  return address;
}

export function mapAddressUpsertToAddressDto(
  addressUpsert: AddressUpsert[],
  country?: Maybe<Country[]> | undefined,
  existingAddress: Address[] = [],
  excludeVoided = true
): Address[] {
  const address = excludeExisting(addressUpsert, existingAddress);
  const addressUpsertCopy = excludeVoided ? addressUpsert.filter((upsertItem) => !upsertItem.void) : addressUpsert;
  addressUpsertCopy
    .filter((upsertItem) => !upsertItem.void)
    .forEach((addressUpsertItem) => {
      address.push({
        ...cloneDeepWithExclude(addressUpsertItem, ['void']),
        country_name_key: getCountryNameKey(country ?? [], addressUpsertItem.country_id || ''),
        country_prov_state_name_key: getCountryNameKey(country ?? [], addressUpsertItem.country_prov_state_id || ''),
        id: addressUpsertItem.id ?? ID_FOR_OBJECT_CREATION,
      });
    });
  return address;
}

export class AddressUpsertGenerator {
  private readonly initialAddressUpserts: AddressUpsert[] | null | undefined;
  private generatedAddressUpserts: AddressUpsert[] | null | undefined;

  constructor(initialAddressUpserts: AddressUpsert[] | null | undefined) {
    this.initialAddressUpserts = initialAddressUpserts;
  }

  generateFromUpdatedValues(
    updatedAddressValues: AddressFormFields[],
    addressUpsertInfos: AddressUpsertInfo[]
  ): AddressUpsert[] | null | undefined {
    this.generatedAddressUpserts = undefined;
    addressUpsertInfos.forEach(({ index, type, deleteAddress }) => {
      this.addEntryToAddressUpsertsIfNeeded(
        this.initialAddressUpserts?.[index],
        updatedAddressValues[index],
        type,
        deleteAddress ?? false
      );
    });
    return this.generatedAddressUpserts;
  }

  private addEntryToAddressUpsertsIfNeeded(
    initialAddress: AddressUpsert | null | undefined,
    updatedAddressValues: AddressFormFields,
    addressTypeId: AddressType,
    deleteAddress: boolean
  ) {
    if (deleteAddress && initialAddress) {
      this.addAddressDeleteEntry(initialAddress);
    } else if (!objectIsEmpty(updatedAddressValues) && !isMatch(initialAddress, updatedAddressValues)) {
      this.addAddressUpdateOrCreateEntry(initialAddress?.id, addressTypeId, updatedAddressValues);
    } else if (objectIsEmpty(updatedAddressValues) && initialAddress) {
      this.addAddressDeleteEntry(initialAddress);
    }
  }

  private addAddressDeleteEntry(initialAddress: AddressUpsert) {
    if (!this.generatedAddressUpserts) {
      this.generatedAddressUpserts = [];
    }
    this.generatedAddressUpserts.push({
      ...initialAddress,
      primary: true,
      void: true,
    });
  }

  private addAddressUpdateOrCreateEntry(
    initialAddressId: string | null | undefined,
    addressTypeId: AddressType,
    updatedAddressValues: AddressFormFields
  ) {
    if (!this.generatedAddressUpserts) {
      this.generatedAddressUpserts = [];
    }
    this.generatedAddressUpserts.push({
      id: initialAddressId || ID_FOR_OBJECT_CREATION,
      address_type_id: addressTypeId,
      ...updatedAddressValues,
      primary: true,
      void: false,
    });
  }
}
