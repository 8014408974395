import { Query, QueryServiceFilter, Service } from '../../../graph/types';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import {
  GetServicesWithBundles,
  GetServiceReminder,
  GetServicesFromBundle,
  GetServiceText,
} from '../../../graph/queries/services';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { translations } from '../../../constants/translations';
import { VaccineDefaultData } from '../../VaccineModalContent/VaccineModalContent';

export const getServiceWithBundle = async (
  organizationId: string,
  variables: QueryServiceFilter,
  onSuccess: (serviceWithBundle: Service, services: Service[]) => Promise<void> | void
) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getServicesPg'>>({
      query: GetServicesWithBundles,
      variables: {
        organizationId,
        filter: variables,
      },
    });

    const { data: serviceData } = await AppSyncService.client.query<Pick<Query, 'getBundledServicesPg'>>({
      query: GetServicesFromBundle,
      variables: {
        organizationId,
        filter: variables,
      },
    });

    if (data?.getServicesPg && serviceData?.getBundledServicesPg) {
      await onSuccess(data.getServicesPg[0], serviceData?.getBundledServicesPg);
    } else {
      showErrorMessage(translations.rapidBilling.error.service);
    }
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.service);
  }
};

export const getServicesFromServiceWithBundle = async (organizationId: string, variables: QueryServiceFilter) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getBundledServicesPg'>>({
      query: GetServicesFromBundle,
      variables: {
        organizationId,
        filter: variables,
      },
    });

    return data?.getBundledServicesPg;
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.service);
    return undefined;
  }
};

export const getServiceWithReminder = async (organizationId: string, variables: QueryServiceFilter) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getServicesPg'>>({
      query: GetServiceReminder,
      variables: {
        organizationId,
        filter: variables,
      },
    });

    return data?.getServicesPg?.[0];
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.service);
    return undefined;
  }
};

export const getServicePrescriptionInstruction = async (id: string, organizationId: string) => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getServiceText'>>({
      query: GetServiceText,
      variables: {
        organizationId,
        id,
      },
    });

    if (data?.getServiceText?.value) {
      return JSON.parse(data?.getServiceText?.value).noteText;
    }

    return undefined;
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.serviceText);
    return undefined;
  }
};

export const getServiceVaccineDefaults = async (
  id: string,
  organizationId: string
): Promise<VaccineDefaultData | undefined> => {
  try {
    const { data } = await AppSyncService.client.query<Pick<Query, 'getServiceText'>>({
      query: GetServiceText,
      variables: {
        organizationId,
        id,
      },
      fetchPolicy: 'network-only',
    });

    if (data?.getServiceText?.value) {
      return JSON.parse(data?.getServiceText?.value).vaccinationDefaults;
    }

    return undefined;
  } catch (e) {
    showErrorMessage(translations.rapidBilling.error.serviceText);
    return undefined;
  }
};
