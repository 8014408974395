import React from 'react';
import { translations } from '../../constants/translations';
import { CustomIFrame, TitleDiv } from '../../globalStyles.style';
import { Loading } from '../Loading/Loading';
import ModalWithCloseConfirm, { ModalWithCloseConfirmProps } from '../ModalWithCloseConfirm/ModalWithCloseConfirm';

interface LabModalProps extends ModalWithCloseConfirmProps {
  url: string;
  height?: number;
  handleOnLoad?: () => void;
  isLoading?: boolean;
  displayBlurb?: boolean;
}

export const labViewerTestId = 'lab-viewer-test-id';

export const LabModal: React.FC<LabModalProps> = ({
  url,
  height = 600,
  isLoading,
  handleOnLoad,
  displayBlurb = true,
  ...modalProps
}) => {
  return (
    <ModalWithCloseConfirm {...modalProps}>
      {displayBlurb && <TitleDiv>{translations.labRequestModal.instructions}</TitleDiv>}
      {isLoading ? (
        <Loading />
      ) : (
        <CustomIFrame src={url} data-testid={labViewerTestId} height={height} onLoad={handleOnLoad} />
      )}
    </ModalWithCloseConfirm>
  );
};
