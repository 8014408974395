import { Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { PracticeUpsertGenerator } from '../../../classes/upsertGenerators/PracticeUpsertGenerator';
import { UserUpsertGenerator } from '../../../classes/upsertGenerators/UserUpsertGenerator';
import { AddressForm } from '../../../components/AddressBlock/AddressBlock';
import { getIndicesOfAddressTypes } from '../../../components/PhysicalAndMailingAddress/physicalAndMailingAddressUtil';
import { translations } from '../../../constants/translations';
import { OrganizationUpsert, PreLoadDataContext, SignUpResult } from '../../../graph/types';
import { useUpsertPractice } from '../../../hooks/ajax/practice/practiceHooks';
import { useUpsertUser } from '../../../hooks/ajax/user/userHooks';
import { getRequiredRule } from '../../../util/forms';
import { getPracticeUpsertInfos } from '../../../util/practiceUpsertUtils';
import { buildFullPracticeUpsertFromPractice } from '../../PracticeSettings/practiceUtils';
import { AddressFields } from './AddressFields';
import { RegistrationButton } from '../RegistrationButton';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { usePreloadData, useSignUpOrganization } from '../../../hooks/ajax/registration/registrationHooks';
import { generateNewPhoneUpsert } from '../../../classes/upsertGenerators/PhoneUpsertGenerator';
import { useGetReferenceData } from '../../../hooks/ajax/referenceData/referenceDataHooks';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import styled from 'styled-components';
import { useUpdateOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { OrganizationInfoType } from '../../../constants/referenceData/organizationReferenceData';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

type CognitoUserAttributes = {
  attributes: {
    'custom:signupData': string;
    email: string;
  };
};

type SignUpData = { email: string; name: string; practiceName: string };

type PracticeFormProps = { phone: string; sameMailingAddress: boolean; howDidYouHear: string } & AddressForm;

const WrappedFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    white-space: normal;
    line-height: 0.9;
    text-align: left;
  }
`;

interface PracticeInformationFormProps {
  setIsSaving: (value: boolean) => void;
  setIsOrganizationSetup: (value: boolean) => void;
  goToNextStep: () => void;
}

export const RegisterPracticeForm: React.FC<PracticeInformationFormProps> = ({
  setIsSaving,
  goToNextStep,
  setIsOrganizationSetup,
}) => {
  const [form] = useForm();

  const [isSameMailingAddress, setIsSameMailingAddress] = useState(true);
  const [signupResult, setSignupResult] = useState<SignUpResult>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [updateUser] = useUpsertUser();
  const [signUp] = useSignUpOrganization();
  const { referenceData } = useGetReferenceData(signupResult?.organization.id);
  const [preloadData] = usePreloadData();
  const [upsertPractice] = useUpsertPractice();
  const [upsertOrganization] = useUpdateOrganization();

  useEffect(() => {
    const getAwsUserAndSignup = async () => {
      setIsOrganizationSetup(true);
      setIsSaving(true);
      try {
        const awsUser: CognitoUserAttributes = await Auth.currentAuthenticatedUser();
        const { name, practiceName }: SignUpData = JSON.parse(awsUser.attributes['custom:signupData']);
        const email = awsUser.attributes.email;

        const { data } = await signUp({
          variables: {
            email,
            name,
            defaults: { defaultPracticeName: practiceName },
          },
        });
        setSignupResult(data?.signUp);
      } catch (e) {
        showErrorMessage(translations.registration.addPracticeInfoStep.errorLoadingPage);
      }
      setIsOrganizationSetup(false);
      setIsSaving(false);
    };

    getAwsUserAndSignup();
  }, [setIsOrganizationSetup, setIsSaving, signUp]);

  if (!signupResult) {
    return null;
  }

  const { practice, organization, user } = signupResult;
  const initialPracticeUpsert = buildFullPracticeUpsertFromPractice(practice);

  const { indexOfPhysicalAddress, indexOfMailingAddress } = getIndicesOfAddressTypes(initialPracticeUpsert);

  const onFinish = async ({ phone, sameMailingAddress, ...values }: PracticeFormProps) => {
    setIsSaving(true);
    const addressUpsertInfos = getPracticeUpsertInfos(
      indexOfPhysicalAddress,
      indexOfMailingAddress,
      sameMailingAddress
    );

    const upsertValues = {
      ...values,
      record: initialPracticeUpsert.record,
    };

    const phoneUpsert = generateNewPhoneUpsert(phone);

    const practiceUpsert = {
      ...new PracticeUpsertGenerator(initialPracticeUpsert).generateFromUpdatedValues(upsertValues, addressUpsertInfos),
      phone: phoneUpsert,
    };

    const userUpsert = new UserUpsertGenerator(user).generateForNewUser({ phone });

    const preloadDataUpsert: PreLoadDataContext = {
      countryId: values.address[indexOfPhysicalAddress].country_id,
      provStateId: values.address[indexOfPhysicalAddress].country_prov_state_id,
    };

    const organizationUpsert: OrganizationUpsert = {
      id: organization.id,
      info: [
        {
          record: {
            type_id: OrganizationInfoType.HEARD_ABOUT_US,
            value: values.howDidYouHear,
          },
        },
      ],
    };

    try {
      await Promise.all([
        upsertPractice({
          variables: {
            practice: practiceUpsert,
            organizationId: organization.id,
          },
        }),
        updateUser({
          variables: {
            user: userUpsert,
            organizationId: organization.id,
          },
        }),
        preloadData({
          variables: {
            organizationId: organization.id,
            demographics: preloadDataUpsert,
          },
        }),
        upsertOrganization({
          variables: {
            organizationId: organization.id,
            organization: organizationUpsert,
          },
        }),
      ]);
      goToNextStep();
    } catch (e) {
      setErrorMessage(e.message || translations.shared.saveErrorMessage);
    }
    setIsSaving(false);
  };

  return (
    <Form {...layout} onFinish={onFinish} form={form} autoComplete='off'>
      <>
        <Row>
          <Col span={24}>
            <WrappedFormItem
              name='howDidYouHear'
              label={translations.registration.addPracticeInfoStep.labels.howDidYouHear.title}
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.howDidYouHear.title)]}
              className='registration-field'
            >
              <Select
                {...searchableSelectParams}
                options={referenceData?.heard_about_us?.map((option) => ({ label: option.name_key, value: option.id }))}
                placeholder={translations.registration.addPracticeInfoStep.labels.howDidYouHear.placeholder}
              />
            </WrappedFormItem>
            <Form.Item
              name='phone'
              label={translations.registration.addPracticeInfoStep.labels.phone}
              rules={[getRequiredRule(translations.registration.addPracticeInfoStep.labels.phone)]}
              className='registration-field'
              labelCol={{ span: 6 }}
            >
              <Input placeholder={translations.registration.addPracticeInfoStep.labels.phone} />
            </Form.Item>
            <Divider orientation='left' style={{ margin: '32px 0 24px 0' }}>
              {translations.registration.addPracticeInfoStep.subTitle.physicalAddress}
            </Divider>
          </Col>
          <AddressFields referenceData={referenceData} index={0} form={form} />
        </Row>

        <Divider orientation='left' style={{ margin: '32px 0 24px 0' }}>
          {translations.registration.addPracticeInfoStep.subTitle.mailingAddress}
        </Divider>
        <Row>
          <Checkbox
            checked={isSameMailingAddress}
            style={{ marginBottom: '24px' }}
            onChange={() => setIsSameMailingAddress(!isSameMailingAddress)}
          >
            {translations.registration.addPracticeInfoStep.labels.addressCheckbox}
          </Checkbox>
          {!isSameMailingAddress && <AddressFields referenceData={referenceData} index={1} form={form} />}
        </Row>
        <p className='Registration_errorMessage'>{errorMessage}</p>
        <RegistrationButton
          onClick={() => {
            form.getFieldsValue();
            form.submit();
          }}
        />
      </>
    </Form>
  );
};
