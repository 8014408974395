import React, { PropsWithChildren } from 'react';
import { useGetOrganization } from '../../hooks/ajax/organization/organizationHooks';
import { Practice, PracticeDto } from '../../graph/types';
import { Loading } from '../Loading/Loading';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetPractice } from '../../hooks/ajax/practice/practiceHooks';
import { translations } from '../../constants/translations';
import { useOrganizationContext } from '../../contexts/organization/state';

export interface ComponentWithPracticeProps extends PropsWithChildren<unknown> {
  practice: Practice;
}

interface WithPracticeWrapperProps extends PropsWithChildren<unknown> {
  Component: React.FC<ComponentWithPracticeProps>;
  withInfoAndPhone?: boolean;
}

export interface ComponentWithPracticeDtoProps extends PropsWithChildren<unknown> {
  practice: PracticeDto;
}

interface WithPracticeWrapperDtoProps extends PropsWithChildren<unknown> {
  Component: React.FC<ComponentWithPracticeDtoProps>;
}

export const WithPractice: React.FC<WithPracticeWrapperProps> = (props) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { organization, organizationLoading } = useGetOrganization(organizationId);
  //TODO: replace with other implementation when generalizing to more than one practice per organization
  const { practice, practiceLoading } = useGetPractice(organizationId, organization?.default_practice_id || '', {
    withInfoAndPhone: props.withInfoAndPhone,
  });

  if (organizationLoading || practiceLoading) {
    return <Loading />;
  }

  if (!practice) {
    return <p>{translations.practiceSettings.noPractice}</p>;
  }

  return <props.Component practice={practice} />;
};

export const WithPracticeDto: React.FC<WithPracticeWrapperDtoProps> = (props) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  //TODO: replace with other implementation when generalizing to more than one practice per organization
  const practice = organization?.practice?.find((practice) => practice.id === organization.default_practice_id);

  if (!practice) {
    return <p>{translations.practiceSettings.noPractice}</p>;
  }

  return <props.Component practice={practice} />;
};
