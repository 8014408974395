import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { organizationIdParameter, patientIdParameter, routes } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { Patient } from '../../graph/types';
import { useUpdatePatient } from '../../hooks/ajax/patients/patientHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import SelectPatientModal from '../SelectPatientModal/SelectPatientModal';
import { setSaveSpinnerAction, setTextConfirmAction } from '../UtilsProvider/store/actions';
import { UtilsContext } from '../UtilsProvider/store/state';
import { showSuccessMessage } from '../Notification/notificationUtil';

interface MergePatientsProps {
  visible: boolean;
  patient: Patient;
  onClose: () => void;
}

const getMergePatientsDescription = (patientFrom: Patient, patientInto: Patient) => (
  <>
    {translations.patientPage.mergePatientsModalDescription[0]}{' '}
    <b>
      {patientFrom.number} {patientFrom.name}
    </b>{' '}
    {translations.patientPage.mergePatientsModalDescription[1]}{' '}
    <b>
      {patientInto.number} {patientInto.name}
    </b>
    . {translations.patientPage.mergePatientsModalDescription[2]}
  </>
);

export const MergePatients: React.FC<MergePatientsProps> = ({ visible, patient, onClose }) => {
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const organizationId = useGetOrganizationIdFromRoute();
  const history = useHistory();

  const [updatePatient] = useUpdatePatient();

  const handlePatientMerge = async (mergeToPatientId: string) => {
    dispatchUtils(setSaveSpinnerAction({ isSaving: true }));
    await updatePatient({
      variables: {
        organizationId,
        patient: {
          id: patient.id,
          fnMergeToPatientId: mergeToPatientId,
        },
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: 'Patient',
            id: patient.id,
          }),
        });
        cache.evict({ fieldName: 'getCurrentUser' });
        cache.gc();
      },
      onCompleted: () => {
        showSuccessMessage(translations.shared.saveSuccessMessage);
      },
    });
    dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
    history.push(
      routes.viewPatient.replace(organizationIdParameter, organizationId).replace(patientIdParameter, mergeToPatientId)
    );
  };

  const handlePatientsMergeSelect = (patients: Patient[]) => {
    const mergeToPatient = patients[0];
    onClose();
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: () => handlePatientMerge(mergeToPatient.id),
        title: translations.patientPage.mergePatientModalTitle,
        description: getMergePatientsDescription(patient, mergeToPatient),
      })
    );
  };

  if (!visible) {
    return null;
  }

  return (
    <SelectPatientModal
      title={translations.patientPage.selectPatientModal}
      onCancel={onClose}
      finishSelectPatients={handlePatientsMergeSelect}
      excludedPatientId={patient.id}
    />
  );
};
