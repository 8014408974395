import { ApolloCache, Reference } from '@apollo/client';
import { GetPatient } from '../graph/queries/patients';
import { Maybe, Mutation, Query } from '../graph/types';
import { GetUserOrganizationCardsPg } from '../graph/queries/users';
import { PatientCardFields } from '../graph/fragments/userFragments';

export const onPatientUpdate =
  (userId?: string) =>
  (
    cache: ApolloCache<Pick<Mutation, 'upsertPatient'>>,
    { data }: { data?: Maybe<Pick<Mutation, 'upsertPatient'>> }
  ) => {
    const upsertedPatient = data?.upsertPatient;
    const organizationId = upsertedPatient?.organization_id;
    cache.writeQuery({
      data: {
        getPatientEs: upsertedPatient,
      },
      query: GetPatient,
      variables: {
        patientId: upsertedPatient?.id,
        organizationId,
      },
    });

    const currentCards = cache.readQuery<Pick<Query, 'getUserOrganizationCardsPg'>>({
      query: GetUserOrganizationCardsPg,
      variables: { organizationId, filter: { user_id: userId } },
    });
    const card = currentCards?.getUserOrganizationCardsPg.patient_card.find(
      ({ patient_id }) => patient_id === upsertedPatient?.id
    );

    if (card && upsertedPatient) {
      const updatedPatientCard = {
        ...card,
        patient_name: upsertedPatient?.name,
        patient_species_name: upsertedPatient?.species_name,
        patient_breed_name: upsertedPatient?.breed_name,
        patient_gender_name: upsertedPatient?.gender_name,
        patient_color_name: upsertedPatient?.color_name,
        patient_dob: upsertedPatient?.dob,
        patient_deceased: upsertedPatient?.deceased,
        patient_inactive: upsertedPatient?.inactive,
        patient_hidden: upsertedPatient?.hidden,
        patient_owner_ids: upsertedPatient?.owner_ids,
        patient_owner_names: upsertedPatient?.owner_names,
        patient_owner_percentages: upsertedPatient?.owner_percentages,
        patient_related_ids: upsertedPatient?.related_ids,
        patient_related_names: upsertedPatient?.related_names,
      };
      cache.modify({
        fields: {
          getUserOrganizationCardsPg(
            currentCardData: { contact_card: Reference[]; patient_card: Reference[] } = {
              contact_card: [],
              patient_card: [],
            }
          ) {
            const cardReference = cache.writeFragment({
              data: updatedPatientCard,
              fragment: PatientCardFields,
              fragmentName: 'PatientCardFields',
            });
            return { ...currentCardData, patient_card: [cardReference, ...currentCardData.patient_card] };
          },
        },
      });
    }
  };
