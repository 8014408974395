import React, { useEffect, useMemo, useState } from 'react';
import { showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { PaymentPortalConfigurationModal } from '../../../components/PaymentPortalConfigurationModal/PaymentPortalConfigurationModal';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { ElectronicPaymentType } from '../../../constants/referenceData/paymentProcessorTypeReferenceData';
import { PracticeSettingsNameKey, translations } from '../../../constants/translations';
import { Organization, OrganizationSubscriptionResult } from '../../../graph/types';
import { useCreatePaymentPortal, useValidatePaymentPortal } from '../../../hooks/ajax/paymentPortal/paymentPortalHooks';
import { useDefaultPracticeId } from '../../../hooks/ajax/practice/practiceHooks';
import { useFetchSubscriptionPricing, useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { useGetPracticeSettings } from '../../Contacts/ViewContact/statementUtils';
import { AddOnCardPaymentPortal } from './AddOnCardPaymentPortal';

export interface AddOnPaymentPortalContainerProps {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
  canEnablePaymentPortal: boolean;
}

export const AddOnCardPaymentPortalContainer: React.FC<AddOnPaymentPortalContainerProps> = ({
  organizationId,
  organization,
  refetchOrganization,
  canEnablePaymentPortal,
}: AddOnPaymentPortalContainerProps) => {
  const practiceId = useDefaultPracticeId();
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const [showConfigurationModal, setShowConfigurationModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const createPaymentPortal = useCreatePaymentPortal();
  const validatePaymentPortal = useValidatePaymentPortal();
  const { settings, refetch: refetchSettings } = useGetPracticeSettings(
    organizationId,
    practiceId,
    PracticeSettingsNameKey.PaymentPortalUrl
  );
  const [subscription, setSubscription] = useState<OrganizationSubscriptionResult>();
  const { data: subscriptionCharges } = useFetchSubscriptionPricing(organizationId);

  useEffect(() => {
    setSubscription(subscriptionCharges);
  }, [subscriptionCharges]);

  const paymentPortalAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.PaymentPortalLevel].level_id
      ),
    [organization]
  );

  const addOnEnabled = useMemo(() => (paymentPortalAddon?.enabled ? true : false), [paymentPortalAddon]);

  const currentPortalUrl = useMemo(
    () =>
      settings?.value?.startsWith('pmt-') ? settings?.value.substring(4, settings?.value.length) : settings?.value,
    [settings]
  );

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const activatePaymentPortalSubscription = async () => {
    await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: true,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.PaymentPortalLevel].level_id,
            },
          ],
        },
      },
    });
  };

  const getFutureChargesWithPaymentPortal = async () => {
    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: false,
          detail: [
            {
              level_id:
                organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.PaymentPortalLevel].level_id,
            },
          ],
        },
      },
    });
    return data?.updateOrganizationSubscription;
  };

  const resolveSubdomain = (suffix: string) => `pmt-${suffix}`;

  const handleCloseConfigurationModal = () => {
    setShowConfigurationModal(false);
  };

  const handleOpenConfigurationModal = () => {
    setShowConfigurationModal(true);
  };

  const handleValidateConfiguration = async (paymentPortalSuffix: string) => {
    const data = await validatePaymentPortal(organizationId, practiceId, resolveSubdomain(paymentPortalSuffix));
    const isValid = data?.success && data?.is_available;
    if (isValid) {
      setIsSaving(true);
      const subscriptionData = await getFutureChargesWithPaymentPortal();
      setSubscription(subscriptionData);
      setIsSaving(false);
    }
    return isValid ? true : false;
  };

  const handleSaveConfiguration = async (paymentPortalSuffix: string) => {
    const data = await createPaymentPortal(organizationId, practiceId, resolveSubdomain(paymentPortalSuffix));
    const isCreated = data?.success;
    if (isCreated) {
      setShowConfigurationModal(false);
      setIsSaving(true);
      await activatePaymentPortalSubscription();
      await refetchOrganization();
      await refetchSettings();
      setIsSaving(false);
      showSuccessMessage(translations.paymentPortal.messages.success);
    }
    return isCreated;
  };

  return (
    <SaveSpinner isSaving={isSaving}>
      <AddOnCardPaymentPortal
        isChecked={addOnEnabled}
        canEnable={canEnablePaymentPortal}
        price={paymentPortalAddon?.cost}
        onClick={handleOpenConfigurationModal}
        onConfigureClick={handleOpenConfigurationModal}
        countryId={countryId}
        isStripeProcessor={organization?.subscription?.processor_type_id === ElectronicPaymentType.Stripe}
      />
      {showConfigurationModal && (
        <PaymentPortalConfigurationModal
          disabled={addOnEnabled}
          currentUrlSuffix={currentPortalUrl || undefined}
          onClose={handleCloseConfigurationModal}
          onValidateSuffix={handleValidateConfiguration}
          onSaveSuffix={handleSaveConfiguration}
          subscription={subscription}
        />
      )}
    </SaveSpinner>
  );
};
