import { useCallback, useEffect, useState } from 'react';
import { useOrganizationDTOUpsertSubscription } from './ajax/organization/organizationHooks';
import dayjs from 'dayjs';
import { useGetCurrentUser } from './ajax/user/userHooks';
import { debounce } from 'lodash';

export const useOrganizationSubscription = (organizationId: string, refetch: () => any) => {
  const { record } = useOrganizationDTOUpsertSubscription(organizationId);

  const { user: currentUser } = useGetCurrentUser(organizationId);

  const [oldUpdate, setOldUpdate] = useState(dayjs());

  const debounceLoadData = useCallback(() => debounce(refetch, 1000), [refetch]);

  useEffect(() => {
    if (record && oldUpdate < dayjs(record?.time) && currentUser.id !== record?.userId) {
      setOldUpdate(dayjs(record.time));
      debounceLoadData();
    }
  }, [refetch, record, debounceLoadData, currentUser, oldUpdate]);
};
