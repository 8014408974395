import { JsonSchemaTypes } from 'rxdb';

type RxScalarType = {
  type: JsonSchemaTypes;
};

type RxScalarKeys = 'PrimaryID' | 'ID' | 'String' | 'Boolean' | 'Int' | 'Array' | 'Object';

export const RxScalars: Record<RxScalarKeys, RxScalarType> = {
  PrimaryID: { type: 'string' },
  ID: { type: 'string' },
  String: { type: 'string' },
  Boolean: { type: 'boolean' },
  Int: { type: 'number' },
  Array: { type: 'array' },
  Object: { type: 'object' },
};

export const Maybe = (rxScalar: RxScalarType) => ({
  ...rxScalar,
  type: [rxScalar.type, 'null'],
});

export type RetryCollectionWithUpsert =
  | 'patient'
  | 'contact'
  | 'note'
  | 'reminder'
  | 'invoice'
  | 'prescription'
  | 'prescription_flattened';
export type CollectionWithUpsert = RetryCollectionWithUpsert | 'upsert';
export const collectionsWithUpserts: Record<CollectionWithUpsert, null> = {
  patient: null,
  contact: null,
  note: null,
  reminder: null,
  invoice: null,
  prescription: null,
  prescription_flattened: null,
  upsert: null,
};

export type RxSchemaTypeWithUpsert<
  T extends Record<string, any>,
  Upsert extends Record<string, any>,
  CollectionName extends string
> = T &
  (CollectionName extends CollectionWithUpsert
    ? {
        is_new: boolean;
        upsert: Upsert | null;
        upsert_offline_id: string | null;
      }
    : null);

export type RxSchemaWithDocument = {
  document: string;
};
export type RxSchemaWithUpdated = {
  updated: string;
};

export const offlineIndexBuildsSelectors: Record<string, any[]> = {
  patient: [
    { selector: {}, limit: 0 },
    { selector: { hidden: { $ne: true } }, limit: 0 },
  ],
  invoice: [
    { selector: {}, limit: 0 },
    { selector: { hidden: { $ne: true } }, limit: 0 },
  ],
  contact: [
    { selector: {}, limit: 0 },
    { selector: { hidden: { $ne: true } }, limit: 0 },
  ],
  ledger: [
    { selector: { contact_id: '1' }, limit: 0 },
    { selector: { invoice_id: '1' }, limit: 0 },
  ],
  note: [
    { selector: { patient_id: '1', offline_patient_id: '1', hidden: 'false' }, limit: 0 },
    { selector: { contact_id: '1', offline_contact_id: '1', hidden: 'false' }, limit: 0 },
  ],
  file: [
    { selector: { patient_id: '1', offline_patient_id: '1', hidden: 'false' }, limit: 0 },
    { selector: { contact_id: '1', offline_contact_id: '1', hidden: 'false' }, limit: 0 },
  ],
  service_rendered: [{ selector: { patient_id: '1', offline_patient_id: '1' }, limit: 0 }],
  service_activity: [{ selector: { patient_id: '1', date: new Date() }, limit: 0 }],
};
