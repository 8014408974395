import React, { PropsWithChildren } from 'react';
import { ContactStatusType } from '../../../graph/types';
import { Form, Select } from 'antd';
import { translations } from '../../../constants/translations';
import { getRequiredRule } from '../../../util/forms';

interface SelectContactStatusProps extends PropsWithChildren<unknown> {
  contactStatuses: ContactStatusType[];
}

export const SelectContactStatus: React.FC<SelectContactStatusProps> = ({ contactStatuses }) => {
  const statusItems = contactStatuses.map((item) => ({ value: item.id, label: item.name }));

  return (
    <Form.Item
      name='contact_status_id'
      label={translations.addContactPage.fields.status}
      rules={[getRequiredRule(translations.addContactPage.fields.status)]}
    >
      <Select options={statusItems} />
    </Form.Item>
  );
};
