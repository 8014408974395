import { useEffect } from 'react';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { useOffline } from '../util/offline/offlineUtil';
import { LDFlagNames } from '../constants/launchDarkly';
import { useUserContext } from '../contexts/user/state';

export const useLDIdentify = () => {
  const {
    state: { user },
  } = useUserContext();
  const client = useLDClient();
  const { isOnline } = useOffline();

  useEffect(() => {
    if (client && user && isOnline) {
      async function LDLoadingWatcher() {
        try {
          await client?.identify({
            key: user?.id,
            name: user?.name,
            email: user?.email,
            custom: {
              created: user?.created ?? '',
              global_admin: `${user?.global_admin}`,
              phone: `${user?.phone?.[0]?.phone}`,
              organization_id: user?.organization?.[0]?.organization_id ?? '',
              enable_offline: `${user?.organization?.[0]?.enable_offline}`,
              organization_administrator: `${user?.organization?.[0]?.organization_administrator}`,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('LaunchDarkly SDK failed to identify ->', error);
        }
      }

      LDLoadingWatcher();
    }
  }, [client, user, isOnline]);
};

export const useLDFlag = (FF?: LDFlagNames) => {
  const flags = useFlags();

  if (FF) {
    return flags[FF];
  }

  return flags;
};
