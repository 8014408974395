import React, { PropsWithChildren, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Dictionary } from 'lodash';
import { translations } from '../../../constants/translations';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import { getRequiredRule, getValidEmailRule } from '../../../util/forms';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { RegistrationSteps } from '../../Registration/Registration';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import { ExtraLink } from './SignInFrom.styles';

interface SignInFormProps extends PropsWithChildren<unknown> {
  onLogin: (email: string, password: string) => Promise<void>;
}

export const SignInForm: React.FC<SignInFormProps> = ({ onLogin }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { navigateTo } = useNavigationToRoute();
  const location = useLocation<{ email: string }>();
  const redirectedEmail = location.state?.email;

  const emailTemplateName = 'email';
  const passwordTemplateName = 'password';

  const [form] = useForm();

  const onFinish = async (values: Dictionary<string>) => {
    const email = values[emailTemplateName];
    const password = values[passwordTemplateName];
    try {
      setIsLoading(true);
      await onLogin(email, password);
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        if (e.message === 'Unauthorized User Context: User Not Found') {
          history.push({
            pathname: routes.signup,
            state: { email, password, initialStep: RegistrationSteps.Practice },
          });
        }
        if (e.code === 'UserNotConfirmedException') {
          history.push({
            pathname: routes.signup,
            state: { email, password, initialStep: RegistrationSteps.Confirmation },
          });
        }
        setErrorMessage(e.message === 'User is disabled.' ? translations.login.inactiveUserError : e.message);
      } else {
        setErrorMessage(translations.login.generalErrorMessage);
      }
    }
  };

  return (
    <SaveSpinner isSaving={isLoading} savingMessage={translations.login.loadingMessage}>
      <Form onFinish={onFinish} validateTrigger='onBlur' wrapperCol={{ span: 24 }} form={form}>
        <Form.Item
          name={emailTemplateName}
          rules={[getRequiredRule(translations.login.fields.email.placeholder), getValidEmailRule()]}
          initialValue={redirectedEmail ?? ''}
        >
          <Input prefix={<UserOutlined />} placeholder={translations.login.fields.email.placeholder} />
        </Form.Item>
        <Form.Item
          name={passwordTemplateName}
          rules={[getRequiredRule(translations.auth.password.label)]}
          style={{ marginBottom: 5 }}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={translations.auth.password.label} />
        </Form.Item>
        <div style={{ marginBottom: 19, display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ paddingLeft: 0 }} type='link' onClick={() => navigateTo(routes.resetPassword)}>
            {translations.login.resetPasswordButtonText}
          </Button>
        </div>
        <Form.Item validateStatus={'error'} help={errorMessage}>
          <Button onClick={() => navigateTo(routes.signup)}>{translations.login.signUpButtonText}</Button>
          <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
            {translations.login.loginButtonText}
          </Button>
        </Form.Item>
        <ExtraLink>
          <a href='mailto:CassadolReferrals@businessinfusions.com' target='_blank' rel='noopener noreferrer'>
            {translations.login.referral}
          </a>
        </ExtraLink>
        <ExtraLink>
          <a href='https://go.cassadol.vet/learn-more' target='_blank' rel='noopener noreferrer'>
            {translations.login.learnMore}
          </a>
        </ExtraLink>
      </Form>
    </SaveSpinner>
  );
};
