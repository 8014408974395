import { UnassignedLabResultsProperty, unassignedLabResultsPropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { UnassignedLabResults } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStatusFilters,
  getStatusTag,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { getResultStatusText, labRequestStatusConfig } from '../../../constants/referenceData/labReferenceData';

export const basicUnassignedLabsColumns: Record<
  UnassignedLabResultsProperty,
  CustomColumnType<UnassignedLabResults>
> = {
  result_date: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.result_date,
    key: unassignedLabResultsPropertyNames.result_date,
    dataIndex: unassignedLabResultsPropertyNames.result_date,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.result_date
    ),
    onFilter: getOnFilterFunctionFor(unassignedLabResultsPropertyNames.result_date),
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.result_date),
  },
  contact_name: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.contact_name,
    key: unassignedLabResultsPropertyNames.contact_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.contact_name
    ),
    render: (result: UnassignedLabResults) => {
      const data = JSON.parse(result?.result_data ?? '{}');
      return data?.ClientName;
    },
    onFilter: (value, record) => {
      const data = JSON.parse(record?.result_data ?? '{}');
      return data?.ClientName.toLowerCase().includes(value.toString().toLowerCase());
    },
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.contact_name),
  },
  patient_name: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.patient_name,
    key: unassignedLabResultsPropertyNames.patient_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.patient_name
    ),
    render: (result: UnassignedLabResults) => {
      const data = JSON.parse(result?.result_data ?? '{}');
      return data?.PetName;
    },
    onFilter: (value, record) => {
      const data = JSON.parse(record?.result_data ?? '{}');
      return data?.PetName.toLowerCase().includes(value.toString().toLowerCase());
    },
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.patient_name),
  },
  lab_device_name: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.lab_device_name,
    key: unassignedLabResultsPropertyNames.lab_device_name,
    dataIndex: unassignedLabResultsPropertyNames.lab_device_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.lab_device_name
    ),
    onFilter: getOnFilterFunctionFor(unassignedLabResultsPropertyNames.lab_device_name),
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.lab_device_name),
  },
  lab_tests: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.lab_tests,
    key: unassignedLabResultsPropertyNames.lab_tests,
    dataIndex: unassignedLabResultsPropertyNames.lab_tests,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.lab_tests
    ),
    onFilter: getOnFilterFunctionFor(unassignedLabResultsPropertyNames.lab_tests),
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.lab_tests),
  },
  lab_created_accession_id: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.lab_created_accession_id,
    key: unassignedLabResultsPropertyNames.lab_created_accession_id,
    dataIndex: unassignedLabResultsPropertyNames.lab_created_accession_id,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.labManagementPage.screens.unassignedLabs.columns.lab_created_accession_id
    ),
    onFilter: getOnFilterFunctionFor(unassignedLabResultsPropertyNames.lab_created_accession_id),
    sorter: getStringCompareFunctionFor(unassignedLabResultsPropertyNames.lab_created_accession_id),
  },
  status_id: {
    title: translations.labManagementPage.screens.unassignedLabs.columns.status_id,
    key: unassignedLabResultsPropertyNames.status_id,
    render: (result: UnassignedLabResults) => getStatusTag(result.status_id, labRequestStatusConfig),
    ...generalFilteringAndSortingSettings,
    filters: getStatusFilters(labRequestStatusConfig),
    onFilter: (value, record) => record.status_id.toString() === value,
    sorter: (a: UnassignedLabResults, b: UnassignedLabResults) =>
      getResultStatusText(a).localeCompare(getResultStatusText(b)),
  },
};
