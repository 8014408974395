import * as React from 'react';
import { RecordsAction } from './actions';
import { initialState, recordsReducer, RecordsState } from './reducer';

export const RecordsContext = React.createContext<{
  state: RecordsState;
  dispatch: React.Dispatch<RecordsAction>;
}>({} as any);

export const RecordsContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const fullInitialState = {
    ...initialState,
  };
  const [state, dispatch] = React.useReducer(recordsReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <RecordsContext.Provider value={value}>{props.children}</RecordsContext.Provider>;
};
