import { Form, Radio } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Contact, InvoiceContact, Patient, RelatedContactEntryCurrent, RelatedOwner } from '../../graph/types';
import { translations } from '../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { showWarningMessage } from '../../components/Notification/notificationUtil';
import { useGetPatientCurrentOwnershipAndRelationship } from '../../hooks/ajax/patients/patientHooks';

import {
  patientContactRelationTypeConfigs,
  PatientContactRelationTypeNameKey,
} from '../../constants/referenceData/patientReferenceData';
import {
  getContactTagForPatient,
  getOwnerTagsForPatient,
} from '../../pages/Invoices/InvoicesOverview/util/patientOwnerTags';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { PercentageTable } from '../PercentageTable/PercentageTable';
import { ContactSelect } from './ContactSelect';
import {
  addOwnerAndSetDefault,
  ownerExists,
  removeOwnerAndSetDefault,
  getDefaultRelationship,
} from '../../pages/Patients/ViewPatient/PatientOwnership/ownershipUtils';
import { Loading } from '../Loading/Loading';
import { SaveInvoiceProps } from '../../pages/Invoices/AddInvoice/AddInvoiceModal';
import { ViewInvoiceContext } from '../../pages/Invoices/ViewInvoice/store/state';
import { getIsBillToOpen } from '../../pages/Invoices/ViewInvoice/store/selectors';
import './BillTo.css';
import { useGetContactType } from '../../hooks/ajax/contact/contactHooks';

const splitTableDisplayText = {
  percentageCheckFailed: translations.invoicesPage.addInvoiceModal.percentCheck.failed,
  primaryCheckFailed: translations.invoicesPage.addInvoiceModal.primaryCheckFailed,
  validationPassed: translations.invoicesPage.addInvoiceModal.percentCheck.passed,
  totalMessage: translations.invoicesPage.addInvoiceModal.invoiceTotal,
  nameColumn: translations.invoicesPage.addInvoiceModal.stakeholder,
  primaryColumn: translations.invoicesPage.addInvoiceModal.primary,
};

export type BillToContext = 'invoice' | 'other';

interface BillToProps {
  selectedPatient: Patient;
  setSaveProps: (props: SaveInvoiceProps) => void;
  editableTabName?: string;
  invoiceContacts?: InvoiceContact[];
  setChangesMade: (isChangeMade: boolean) => void;
  fieldName?: string | undefined;
  reset?: number;
  onContactSelect?: (contact: Contact) => void;
  context?: BillToContext;
}

type PatientContactRelationType = { [key: string]: RelatedContactEntryCurrent };

export const BillTo: React.FC<BillToProps> = ({
  selectedPatient,
  setSaveProps,
  editableTabName,
  invoiceContacts,
  setChangesMade,
  fieldName,
  reset,
  onContactSelect,
  context = 'other',
}: BillToProps) => {
  const organizationId = useGetOrganizationIdFromRoute();

  const { patient: patientWithOwnershipAndRelationship, patientLoading: ownershipAndRelationshipLoading } =
    useGetPatientCurrentOwnershipAndRelationship(selectedPatient.id, organizationId);
  const relationships = useMemo(
    () => patientWithOwnershipAndRelationship?.related_current,
    [patientWithOwnershipAndRelationship]
  );
  const defaultBillToId = selectedPatient.default_bill_to_id;
  const owner = useMemo(
    () => patientWithOwnershipAndRelationship?.ownership_current?.owner,
    [patientWithOwnershipAndRelationship]
  );
  const { contactTypes } = useGetContactType();
  const isEditable = editableTabName ? editableTabName === translations.invoicePage.editBillingModal.current : false;

  const [billToContactOptions, setBillToContactOptions] = useState<CheckboxOptionType[]>([]);
  const [patientContactRelationMap, setPatientContactRelationMap] = useState<PatientContactRelationType>({});
  const [billTo, setBillTo] = useState<string>(isEditable ? editableTabName! : PatientContactRelationTypeNameKey.Owner);
  const [editableStakeholders, setEditableStakeholders] = useState<RelatedOwner[]>([]);

  const { state } = useContext(ViewInvoiceContext);
  const isBillToOpen = getIsBillToOpen(state);

  useEffect(() => {
    if (context === 'invoice' && isBillToOpen) {
      setBillTo(translations.invoicePage.editBillingModal.current);
    }
  }, [context, isBillToOpen]);

  useEffect(() => {
    if (!invoiceContacts && owner) {
      setEditableStakeholders(owner);
    }
  }, [owner, invoiceContacts]);

  useEffect(() => {
    if (defaultBillToId && relationships) {
      setBillTo(getDefaultRelationship(defaultBillToId, relationships, context));
    }
  }, [defaultBillToId, relationships, reset, context]);

  useEffect(() => {
    if (invoiceContacts) {
      const invoiceContactsAsStakeholders = invoiceContacts.map(
        ({ primary, contact_name, contact_id, id, percentage }) => ({
          id,
          contact_id,
          percentage,
          primary,
          name: contact_name,
        })
      );

      setEditableStakeholders(invoiceContactsAsStakeholders);
    }
  }, [invoiceContacts]);

  useEffect(() => {
    if (relationships && contactTypes) {
      const newPatientContactRelationMap: PatientContactRelationType = {};
      const billToOptions: { label: string; value: PatientContactRelationTypeNameKey | string }[] = [
        {
          label: patientContactRelationTypeConfigs[PatientContactRelationTypeNameKey.Owner],
          value: PatientContactRelationTypeNameKey.Owner,
        },
      ];

      relationships.forEach((related: RelatedContactEntryCurrent) => {
        const matchingContactType = contactTypes?.find((x) => x.type_id === related.contact_type_id);

        if (matchingContactType?.invoice_ownership) {
          newPatientContactRelationMap[related.contact_type_name_key as PatientContactRelationTypeNameKey] = related;

          billToOptions.push({
            label:
              patientContactRelationTypeConfigs[related.contact_type_name_key as PatientContactRelationTypeNameKey],
            value: related.contact_type_name_key as PatientContactRelationTypeNameKey,
          });
        }
      });

      if (editableTabName) {
        billToOptions.push({
          label: editableTabName,
          value: editableTabName,
        });
      }
      setBillToContactOptions(billToOptions);
      setPatientContactRelationMap(newPatientContactRelationMap);
    }
  }, [relationships, editableTabName, contactTypes]);

  useEffect(() => {
    if (patientWithOwnershipAndRelationship && billTo) {
      setSaveProps({
        patientWithOwnershipAndRelationship,
        billTo,
        patientContactRelationMap,
        invoiceStakeholders: editableStakeholders,
        billToId: patientContactRelationMap[billTo]?.contact_type_id,
      });
    }
  }, [setSaveProps, editableStakeholders, billTo, patientWithOwnershipAndRelationship, patientContactRelationMap]);

  const addContactAsStakeholder = (contact: Contact) => {
    if (!ownerExists(editableStakeholders, contact)) {
      const newStakeholders = addOwnerAndSetDefault(editableStakeholders, contact);
      setEditableStakeholders(newStakeholders);
    } else {
      showWarningMessage(translations.patientPage.ownership.addOwnerModal.messageWhenAddingDuplicateContact);
    }
  };

  const removeOwner = (ownerIndex: number) => {
    const newStakeholders = removeOwnerAndSetDefault(editableStakeholders, ownerIndex);
    setEditableStakeholders(newStakeholders);
  };

  const getContactRelationInfo = () => {
    if (billTo === editableTabName) {
      return (
        <>
          <ContactSelect
            onSelect={(contact) => {
              addContactAsStakeholder(contact);
              onContactSelect?.(contact);
            }}
          />
          <PercentageTable
            stakeholders={editableStakeholders}
            setStakeholders={(values) => {
              setChangesMade(true);
              setEditableStakeholders(values);
            }}
            removeOwner={removeOwner}
            isEditMode
            linkOnContactName={false}
            displayText={splitTableDisplayText}
          />
        </>
      );
    }
    return (
      <Form.Item label={translations.invoicesPage.addInvoiceModal.fields.details}>
        {billTo === PatientContactRelationTypeNameKey.Owner
          ? getOwnerTagsForPatient(selectedPatient)
          : getContactTagForPatient(patientContactRelationMap[billTo])}
      </Form.Item>
    );
  };

  if (ownershipAndRelationshipLoading) {
    return <Loading />;
  }
  return (
    <>
      <Form.Item name={fieldName} label={translations.invoicesPage.addInvoiceModal.fields.billTo}>
        <Radio.Group
          className={'BillToRadioGroup'}
          onChange={(event) => {
            setChangesMade(true);
            setBillTo(event.target.value);
          }}
          optionType={'button'}
          value={billTo}
          options={billToContactOptions}
          buttonStyle='solid'
        />
      </Form.Item>
      {getContactRelationInfo()}
    </>
  );
};
