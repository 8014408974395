import { Collapse } from 'antd';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ServicesRenderedContext } from '../../components/ServiceRendered/store/state';
import { TagColor } from '../../constants/tagColor';
import { translations } from '../../constants/translations';
import { BatchGroupUpsert, Patient } from '../../graph/types';
import { useGetInvoiceContext } from '../../hooks/ajax/invoice/invoiceHooks';
import { usePatientOfflineList } from '../../hooks/ajax/patients/patientHooks';
import {
  useBatchGroupUpsertOfflineDelete,
  useBatchGroupUpsertOfflineInsert,
  useBatchGroupUpsertOfflineList,
  useBatchGroupUpsertOfflineUpdate,
} from '../../hooks/ajax/rapidBilling/rapidBillingHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { RxPatient } from '../../services/LocalDatabaseService/schemas/patientSchema';
import { RxUpsert } from '../../services/LocalDatabaseService/schemas/upsertSchema';
import { showConfirm } from '../../util/modalUtil';
import { useOffline } from '../../util/offline/offlineUtil';
import { getTag } from '../../util/tags';
import { RapidBilling } from './RapidBilling';
import { buildBatchGroupData } from './rapidBillingMappingUtil';
import { upsertDateFormat } from '../../constants/formats';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { setServicesRenderedAction } from '../../components/ServiceRendered/store/actions';

export const BatchTitle = styled.span`
  margin-right: 10px;
  font-weight: 600;
`;

export const ChangesNotifier = styled.span`
  margin-right: 10px;
  font-weight: 600;
  color: #ff0000;
`;

type RapidBillingContainerProps = {
  contactId?: string;
};

type BatchGroupState = {
  id: string;
  pendingChanges: boolean;
};

export const RapidBillingContainer: React.FC<RapidBillingContainerProps> = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { invoiceContext } = useGetInvoiceContext(organizationId, practiceId);
  const { isOnline, canUseCollection: canUseUpsertCollection } = useOffline('upsert');
  const [batchGroupChangesStatus, setBatchGroupChangesStatus] = useState<BatchGroupState[]>([]);
  const [showQueuedBatches, setShowQueuedBatches] = useState(false);
  const [batches, setBatches] = useState<RxUpsert[] | undefined>([]);
  const [firstBatchId, setFirstBatchId] = useState<string>('');
  const [offlinePatients, setOfflinePatients] = useState<RxPatient[] | undefined>([]);
  const batchGroupUpsertOfflineInsert = useBatchGroupUpsertOfflineInsert();
  const batchGroupUpsertOfflineUpdate = useBatchGroupUpsertOfflineUpdate();
  const batchGroupUpsertOfflineDelete = useBatchGroupUpsertOfflineDelete();
  const batchGroupUpsertOfflineList = useBatchGroupUpsertOfflineList();
  const patientOfflineList = usePatientOfflineList();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const MAX_PATIENTS_TO_DISPLAY = 5;
  const { state, dispatch } = useContext(ServicesRenderedContext);

  const services = useMemo(() => state.cashedServices, [state.cashedServices]);

  const sortBatchesByDate = (queuedBatches: RxUpsert[]) => {
    return sortBy(queuedBatches, ['updated']).reverse();
  };

  const loadBatches = async () => {
    const upserts = await batchGroupUpsertOfflineList?.();
    const mappedUpserts = upserts?.map((b) => b?.toJSON() as RxUpsert);
    setBatches(sortBatchesByDate(mappedUpserts ?? []));
    const patients = (await patientOfflineList?.())?.map((p) => p.toJSON() as RxPatient);
    setOfflinePatients(patients);
  };

  const handleToggleShowQueuedBatches = () => {
    if (showQueuedBatches) {
      if (batchGroupChangesStatus.some((b) => b.pendingChanges)) {
        showConfirm({
          okCallback: () => {
            setShowQueuedBatches(false);
            setBatchGroupChangesStatus(
              batches?.map((u) => {
                return { id: u.id, pendingChanges: false } as BatchGroupState;
              }) || []
            );
          },
        });
      } else {
        setShowQueuedBatches(false);
      }
    } else {
      setShowQueuedBatches(true);
    }
  };

  const handleBatchGroupChangesState = (batchId: string, hasPending: boolean) => {
    const filtered = batchGroupChangesStatus.filter((b) => b.id !== batchId);
    setBatchGroupChangesStatus([{ id: batchId, pendingChanges: hasPending }, ...filtered]);
  };

  const handleOfflineBatchInsert = async (upsert: BatchGroupUpsert) => {
    await batchGroupUpsertOfflineInsert?.(upsert);
    loadBatches();
  };

  const handleOfflineBatchUpdate = async (rxUpsert: RxUpsert, upsert: BatchGroupUpsert) => {
    await batchGroupUpsertOfflineUpdate?.(rxUpsert, upsert);
    loadBatches();
  };

  const handleOfflineBatchDelete = async (id: string) => {
    await batchGroupUpsertOfflineDelete?.(id);
    loadBatches();
  };

  useEffect(() => {
    if (batchGroupUpsertOfflineList) {
      loadBatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchGroupUpsertOfflineList]);

  useEffect(() => {
    if (isOnline) {
      setShowQueuedBatches(false);
    }
  }, [isOnline, setShowQueuedBatches]);

  useEffect(() => {
    const firstId = batches && batches?.length > 0 ? batches[0].id : '';
    setFirstBatchId(firstId);

    if (!batches || batches?.length === 0) {
      setShowQueuedBatches(false);
    }
  }, [batches]);

  const doctors = useMemo(
    () => (invoiceContext ? invoiceContext.caregiver.filter((caregiver) => caregiver.doctor) : []),
    [invoiceContext]
  );

  const batchGroupData = useMemo(() => {
    const batchData = new Map(
      batches?.map((b) => [
        b.id,
        buildBatchGroupData(b.upsert as BatchGroupUpsert, offlinePatients, services, doctors, upsertDateFormat),
      ])
    );
    return batchData;
  }, [batches, offlinePatients, services, doctors]);

  const getRapidBillingHeaderComponent = (rxUpsert: RxUpsert) => {
    const upsert = rxUpsert.upsert as BatchGroupUpsert;
    const subBatch = upsert?.subBatch;
    const patientIds: string[] = [];
    subBatch.forEach((batch) => {
      patientIds.push(...(batch.patient_id ?? []));
      patientIds.push(...(batch.offline_patient?.map((p) => p.patient_id ?? p.offline_patient_id ?? '') ?? []));
    });
    const patients = [...new Set(patientIds)].map(
      (id) => offlinePatients?.find((p) => p.id === id) as unknown as Patient
    );
    const hasChanges = batchGroupChangesStatus.find((s) => s.id === rxUpsert.id)?.pendingChanges ?? false;
    return (
      <>
        <BatchTitle>Batch Date: {dayjs(Date.parse(rxUpsert.updated)).format(dateFormat)}</BatchTitle>
        {hasChanges && (
          <ChangesNotifier title={translations.rapidBilling.batchWithPendingChanges}>{'**'}</ChangesNotifier>
        )}
        {patients
          .slice(0, Math.min(MAX_PATIENTS_TO_DISPLAY, patients.length))
          .map((patient) => getTag(TagColor.Color5, patient.name || '', patient.id))}
        {patients.length > MAX_PATIENTS_TO_DISPLAY && getTag(TagColor.Color5, ' ... ', 'more-patients-key')}
      </>
    );
  };

  return (
    <>
      <RapidBilling
        practiceId={practiceId}
        isOnline={isOnline}
        onOfflineBatchSave={handleOfflineBatchInsert}
        canUseUpsertCollection={canUseUpsertCollection}
        showQueuedBatches={showQueuedBatches}
        onToggleShowQueuedBatches={handleToggleShowQueuedBatches}
        hideContent={!isOnline && showQueuedBatches}
        disableShowQueuedBachesButton={!batches || batches?.length === 0}
      />

      {!isOnline && showQueuedBatches && (
        <Collapse
          onChange={(key) =>
            setTimeout(
              () => dispatch(setServicesRenderedAction(batchGroupData?.get(key as string)?.servicesRendered || [])),
              225
            )
          }
          accordion
          defaultActiveKey={[`${firstBatchId}`]}
        >
          {batches?.map((b) => (
            <Collapse.Panel header={getRapidBillingHeaderComponent(b)} key={b.id}>
              <RapidBilling
                practiceId={practiceId}
                offlineBatchGroupData={batchGroupData?.get(b.id)}
                isOnline={isOnline}
                onOfflineBatchSave={(upsert: BatchGroupUpsert) => handleOfflineBatchUpdate(b, upsert)}
                onOfflineBatchDelete={() => handleOfflineBatchDelete(b.id)}
                onBatchPendingChangesStatusChange={(hasPending: boolean) => {
                  handleBatchGroupChangesState(b.id, hasPending);
                }}
                hasUnsavedChanges={batchGroupChangesStatus.find((status) => status.id === b.id)?.pendingChanges}
                canUseUpsertCollection={canUseUpsertCollection}
                showQueuedBatches={showQueuedBatches}
                hideHeader
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </>
  );
};
