import { RxJsonSchema } from 'rxdb';
import { InfoText } from '../../../graph/types';
import { Maybe, RxScalars, RxSchemaWithUpdated } from '../databaseUtils';

export type RxInfoText = InfoText & RxSchemaWithUpdated;

export const infoTextSchema: RxJsonSchema<RxInfoText> = {
  version: 1,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    type_id: RxScalars.ID,
    category: RxScalars.String,
    name_key: RxScalars.String,
    value: Maybe(RxScalars.String),
    updated: RxScalars.String,
    updated_user_name: Maybe(RxScalars.String),
    offline_id: Maybe(RxScalars.String),
  },
  required: ['id'],
  indexes: ['updated'],
};
