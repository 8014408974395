import React, { Reducer, useContext, useEffect } from 'react';
import { Action, ContextProvider, ContextProviderFC, createContextFor } from '../../util/ContextProvider';
import { Phone, PhoneType } from '../../graph/types';
import { v4 as uuid } from 'uuid';
import { Country } from 'react-phone-number-input';

/* STATE */
export interface PhoneTableState {
  phoneTableData: Phone[];
  phoneTypeRefData: PhoneType[];
  currentEditingPhone?: { isNew: boolean; phone: Phone };
  onAddSave?: (phone: Phone) => Promise<void> | void;
  onEditSave?: (phone: Phone) => Promise<void> | void;
  onDelete?: (phone: Phone) => Promise<void> | void;
  assignUniqueDummyId: boolean;
  defaultPhoneCountry?: Country;
}

export const initialState: PhoneTableState = {
  phoneTableData: [],
  phoneTypeRefData: [],
  assignUniqueDummyId: false,
};

/* ACTIONS */
enum PhoneTableActionType {
  SetPhoneTableData,
  SetPhoneTypeRefData,
  SetCurrentEditing,
  SetOnEditSave,
  SetOnAddSave,
  SetOnDelete,
  SetAssignUniqueDummyId,
}

type SetPhoneTableData = Action<PhoneTableActionType.SetPhoneTableData, PhoneTableState['phoneTableData']>;
type SetPhoneTypeRefData = Action<PhoneTableActionType.SetPhoneTypeRefData, PhoneTableState['phoneTypeRefData']>;
type SetCurrentEditing = Action<PhoneTableActionType.SetCurrentEditing, PhoneTableState['currentEditingPhone']>;
type SetOnEditSave = Action<PhoneTableActionType.SetOnEditSave, PhoneTableState['onEditSave']>;
type SetOnAddSave = Action<PhoneTableActionType.SetOnAddSave, PhoneTableState['onAddSave']>;
type SetOnDelete = Action<PhoneTableActionType.SetOnDelete, PhoneTableState['onDelete']>;
type SetAssignUniqueDummyId = Action<
  PhoneTableActionType.SetAssignUniqueDummyId,
  PhoneTableState['assignUniqueDummyId']
>;

type PhoneTableAction =
  | SetPhoneTableData
  | SetPhoneTypeRefData
  | SetCurrentEditing
  | SetOnEditSave
  | SetOnDelete
  | SetOnAddSave
  | SetAssignUniqueDummyId;

export const phoneTableActions = {
  setPhoneTableData: (payload: Phone[]): SetPhoneTableData => ({
    type: PhoneTableActionType.SetPhoneTableData,
    payload,
  }),
  setPhoneTypeRefData: (payload: PhoneType[] = []): SetPhoneTypeRefData => ({
    type: PhoneTableActionType.SetPhoneTypeRefData,
    payload,
  }),
  setCurrentEditing: (payload?: PhoneTableState['currentEditingPhone']): SetCurrentEditing => ({
    type: PhoneTableActionType.SetCurrentEditing,
    payload,
  }),
  setOnEditSave: (payload?: PhoneTableState['onEditSave']): SetOnEditSave => ({
    type: PhoneTableActionType.SetOnEditSave,
    payload,
  }),
  setOnAddSave: (payload?: PhoneTableState['onAddSave']): SetOnAddSave => ({
    type: PhoneTableActionType.SetOnAddSave,
    payload,
  }),
  setOnDelete: (payload?: PhoneTableState['onDelete']): SetOnDelete => ({
    type: PhoneTableActionType.SetOnDelete,
    payload,
  }),
  setAssignUniqueDummyId: (payload = false): SetAssignUniqueDummyId => ({
    type: PhoneTableActionType.SetAssignUniqueDummyId,
    payload,
  }),
};

/* REDUCER */
const reducer: Reducer<PhoneTableState, PhoneTableAction> = (state = initialState, action) => {
  switch (action.type) {
    case PhoneTableActionType.SetPhoneTableData:
      return { ...state, phoneTableData: action.payload };
    case PhoneTableActionType.SetPhoneTypeRefData:
      return { ...state, phoneTypeRefData: action.payload };
    case PhoneTableActionType.SetCurrentEditing:
      return { ...state, currentEditingPhone: action.payload };
    case PhoneTableActionType.SetOnAddSave:
      return { ...state, onAddSave: action.payload };
    case PhoneTableActionType.SetOnEditSave:
      return { ...state, onEditSave: action.payload };
    case PhoneTableActionType.SetOnDelete:
      return { ...state, onDelete: action.payload };
    case PhoneTableActionType.SetAssignUniqueDummyId:
      return { ...state, assignUniqueDummyId: action.payload };
    default:
      return state;
  }
};

/* STORE */
export const PhoneTableContext = createContextFor<PhoneTableState, PhoneTableAction>();

export const PhoneTableContextProvider: ContextProviderFC<PhoneTableState> = (props) => (
  <ContextProvider Context={PhoneTableContext} initialState={initialState} reducer={reducer} props={props} />
);

/* HOOKS */
export const usePhoneTableContextWithDefaults = () => {
  const context = useContext(PhoneTableContext);
  const { state, dispatch } = context;

  useEffect(() => {
    dispatch(phoneTableActions.setAssignUniqueDummyId(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      phoneTableActions.setOnAddSave((phone) => {
        const mPhone = state.assignUniqueDummyId ? { ...phone, id: uuid() } : phone;
        if (!mPhone.primary) {
          dispatch(phoneTableActions.setPhoneTableData([mPhone, ...state.phoneTableData]));
        } else {
          dispatch(
            phoneTableActions.setPhoneTableData([
              mPhone,
              ...state.phoneTableData.map((phoneItem) => ({ ...phoneItem, primary: false })),
            ])
          );
        }
      })
    );
    dispatch(
      phoneTableActions.setOnEditSave((phone) => {
        dispatch(
          phoneTableActions.setPhoneTableData(
            state.phoneTableData.map((phoneItem) => {
              if (phoneItem.id === phone.id) {
                return phone;
              }
              return phone.primary ? { ...phoneItem, primary: false } : phoneItem;
            })
          )
        );
      })
    );
    dispatch(
      phoneTableActions.setOnDelete((phone) => {
        dispatch(
          phoneTableActions.setPhoneTableData(state.phoneTableData.filter((phoneItem) => phoneItem.id !== phone.id))
        );
      })
    );
    return () => {
      dispatch(phoneTableActions.setOnAddSave());
      dispatch(phoneTableActions.setOnEditSave());
      dispatch(phoneTableActions.setOnDelete());
    };
  }, [dispatch, state.assignUniqueDummyId, state.phoneTableData]);

  return context;
};
