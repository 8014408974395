import { isMatch as isMatchLodash, isEqual as isEqualLodash, isPlainObject } from 'lodash';
import { Store } from 'antd/lib/form/interface';

export function objToKey<T extends Record<string, any>>(obj: T): T | string {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const sortedObj = Object.keys(obj)
    .sort()
    .reduce((result: Record<string, any>, key) => {
      result[key] = objToKey(obj[key]);
      return result;
    }, {});
  return JSON.stringify(sortedObj);
}

//extends isMatch of lodash to be able to pass null/undefined
// eslint-disable-next-line @typescript-eslint/ban-types
export function isMatch(object: object | null | undefined, source: object) {
  if (object == null) {
    return false;
  }

  return isMatchLodash(object, source);
}

//extends isEqual of lodash to be able to pass null/undefined
// eslint-disable-next-line @typescript-eslint/ban-types
export function isEqual(object: object | null | undefined, source: object) {
  if (object == null) {
    return false;
  }

  return isEqualLodash(object, source);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function objectIsEmpty(object: object | null | undefined): boolean {
  if (object) {
    const nonEmptyValue = Object.values(object).find((value) => !!value);
    return !nonEmptyValue;
  }
  return true;
}

export function isMatchIgnoringEmptyStrings(
  newValues: Store | null | undefined,
  initalValues: Store | null | undefined
): boolean {
  if (!newValues) {
    if (initalValues) {
      return false;
    }
    return true;
  }

  for (const key of Object.keys(newValues)) {
    // If param is obj, recursivly call this (for things like address)
    if (typeof newValues[key] === 'object') {
      const matches = isMatchIgnoringEmptyStrings(newValues[key], initalValues?.[key]);
      if (!matches) {
        return false;
      }
    }
    // If values match with initalValue or is 'empty', then the key is good
    else if (!(initalValues?.[key] === newValues[key] || newValues[key] == null || newValues[key] === '')) {
      return false;
    }
  }

  return true;
}
