import * as React from 'react';
import { ContextProviderFC } from '../../../../../util/ContextProvider';
import { ContactLedgerAction } from './actions';
import { contactLedgerReducer, ContactLedgerState, initialState } from './reducer';

export const ContactLedgerContext = React.createContext<{
  state: ContactLedgerState;
  dispatch: React.Dispatch<ContactLedgerAction>;
}>({} as any);

export const ContactLedgerContextProvider: ContextProviderFC = (props) => {
  const fullInitialState = {
    ...initialState,
  };
  const [state, dispatch] = React.useReducer(contactLedgerReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <ContactLedgerContext.Provider value={value}>{props.children}</ContactLedgerContext.Provider>;
};
