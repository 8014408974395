import { useMemo } from 'react';
import { CustomColumnType } from '../../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { InvoiceSyncColumnProperty, invoiceSyncPropertyNames } from '../../../../../constants/propertyNames';
import { translations } from '../../../../../constants/translations';
import { generalFilteringAndSortingSettings, getOnFilterFunctionFor } from '../../../../../util/filterAndSorting';
import { useBasicInvoiceColumns } from '../../../../Invoices/InvoicesOverview/invoiceColumns';
import { InvoiceSyncInvoice } from './InvoiceSync';

export const useBasicInvoiceSyncColumns = (): Record<
  InvoiceSyncColumnProperty,
  CustomColumnType<InvoiceSyncInvoice>
> => {
  const basicInvoiceColumns = useBasicInvoiceColumns();

  return useMemo(() => {
    return {
      ...(basicInvoiceColumns as Record<InvoiceSyncColumnProperty, CustomColumnType<InvoiceSyncInvoice>>),
      current: {
        title: translations.patientPage.ownership.invoiceSync.columns.current,
        key: invoiceSyncPropertyNames.current,
        dataIndex: invoiceSyncPropertyNames.current,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.patientPage.ownership.invoiceSync.columns.current
        ),
        onFilter: getOnFilterFunctionFor(invoiceSyncPropertyNames.currentOwners),
      },
      expected: {
        title: translations.patientPage.ownership.invoiceSync.columns.new,
        key: invoiceSyncPropertyNames.expected,
        dataIndex: invoiceSyncPropertyNames.expected,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.patientPage.ownership.invoiceSync.columns.new
        ),
        onFilter: getOnFilterFunctionFor(invoiceSyncPropertyNames.expectedOwners),
      },
    };
  }, [basicInvoiceColumns]);
};
