import { useForm } from 'antd/lib/form/Form';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { translations } from '../../../constants/translations';
import { Store } from 'antd/lib/form/interface';
import { RecordsContext } from '../../Records/store/state';
import { selectRecordAction, setHasUnsavedChangesAction } from '../../Records/store/actions';
import { SaveSpinnerAndNavigationWarning } from '../../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { selectHasUnsavedChanges } from '../../Records/store/selectors';
import { File } from '../../../graph/types';
import dayjs from 'dayjs';
import { FileCard, FileUpsertValues } from './FileCard';
import { FileData } from '../../../util/fileUpload';
import { showErrorMessage } from '../../Notification/notificationUtil';
import {
  getRecordFromFile,
  getUpdateFileUpsert,
  getVoidFileUpsert,
  useGetFileRefData,
  useSendFileUpsertToBackend,
} from '../../../util/fileUtils';
import { RecordItem, sortRecordsByDate } from '../../Records/recordUtils';
import { EditAndDeleteIcons, getButtonRowForExistingRecord } from '../ButtonRow';
import { getRefDataWithVoid, mapToRefData } from '../../../util/refDataUtil';
import { useHighlightedText } from '../../../hooks/useHighlightedText';
import { useOffline } from '../../../util/offline/offlineUtil';
import { ExportOutlined } from '@ant-design/icons';
import { openTabWithPopupDetection } from '../../../util/popupUtil';
import { ListItemHeader } from '../../Records/FullscreenRecords/ListItemHeader';

interface RecordSingleViewFileProps extends PropsWithChildren<unknown> {
  recordId: string;
  file: File;
  onClose: () => void;
  contactId?: string;
  patientId?: string;
  setCurrentRecords: (setter: (currentRecords: RecordItem[]) => RecordItem[]) => void;
  setEditing?: (editing: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  isFullscreenMode?: boolean;
  record?: RecordItem;
}

export const RecordSingleViewFile: React.FC<RecordSingleViewFileProps> = ({
  recordId,
  file,
  onClose,
  contactId,
  patientId,
  setCurrentRecords,
  setEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  isFullscreenMode,
  record,
}: RecordSingleViewFileProps) => {
  const { state, dispatch } = useContext(RecordsContext);
  const hasUnsavedChanges = selectHasUnsavedChanges(state);
  const [form] = useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileData | null>(null);
  const [textContent, setTextContent] = useState<string>(file.description || '');
  const sendFileUpsertToBackend = useSendFileUpsertToBackend();
  const { fileRefData } = useGetFileRefData(!!patientId);
  const refDataWithVoid = getRefDataWithVoid(mapToRefData(file), fileRefData);
  useHighlightedText([textContent], '.ant-card');
  const { isOnline } = useOffline();
  const [fileUrl, setFileUrl] = useState<string>();

  const setValuesFromFile = useCallback(() => {
    form.setFields([
      { name: 'date', value: dayjs(file.date) },
      { name: 'type_id', value: file.type_id },
    ]);

    setFileData({
      pointer: file.pointer,
      size: file.size,
      name: file.name,
      isChangedFile: false,
    });

    setEditMode(false);
  }, [form, file]);

  useEffect(() => {
    setValuesFromFile();
  }, [recordId, setValuesFromFile]);

  const onFinishBackendRequest = () => {
    dispatch(setHasUnsavedChangesAction(false));
    setEditing?.(false);
    dispatch(selectRecordAction(undefined));
    setIsSaving(false);
    setEditMode(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    const haveFormFieldsChanged = !dayjs(file.date).isSame(allValues.date, 'day') || allValues.type_id !== file.type_id;
    const hasUnsavedChanges = haveFormFieldsChanged || editMode;
    dispatch(setHasUnsavedChangesAction(hasUnsavedChanges));
    setEditing?.(hasUnsavedChanges);
  };

  const onReset = useCallback(() => {
    setValuesFromFile();
    dispatch(setHasUnsavedChangesAction(false));
    setEditing?.(false);
  }, [setValuesFromFile, setEditing, dispatch]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      onReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, onReset, setShouldResetOnTabChange]);

  const activateEditMode = () => {
    setEditMode(true);
    dispatch(setHasUnsavedChangesAction(true));
    setEditing?.(true);
    return true;
  };

  const handleSave = async (values: Store) => {
    if (!fileData?.pointer) {
      showErrorMessage(translations.recordList.noFileError);
      return;
    }

    setIsSaving(true);

    const fileValues: FileUpsertValues = {
      type_id: values.type_id,
      date: values.date,
      pointer: fileData?.pointer || file.pointer,
      name: fileData?.name || file.name,
      size: fileData?.size || file.size,
      description: textContent === translations.recordList.textFieldPlaceholder ? undefined : textContent,
    };

    const upsert = getUpdateFileUpsert(file, fileValues, patientId, contactId);

    const updatedFile = await sendFileUpsertToBackend(
      upsert,
      onFinishBackendRequest,
      translations.shared.saveSuccessMessage
    );
    if (updatedFile) {
      setCurrentRecords((currentRecords) =>
        sortRecordsByDate([
          ...currentRecords.filter((record) => record.recordId !== recordId),
          getRecordFromFile(updatedFile),
        ])
      );
    }
  };

  const handleDeleteRecord = async () => {
    setIsSaving(true);
    await sendFileUpsertToBackend(
      getVoidFileUpsert(file),
      onFinishBackendRequest,
      translations.shared.deleteSuccessMessage
    );
    setCurrentRecords((currentRecords) => currentRecords.filter((record) => record.recordId !== recordId));
  };

  const buttonRow = getButtonRowForExistingRecord(
    hasUnsavedChanges,
    onReset,
    translations.recordList.file,
    activateEditMode,
    handleDeleteRecord,
    !isOnline
  );

  return (
    <>
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving}
        showNavigationWarning={hasUnsavedChanges}
        warningMessage={translations.shared.getUnsavedDataNavigationWarning('')}
      >
        {isFullscreenMode && record && (
          <ListItemHeader
            item={record}
            editableOptions={
              <EditAndDeleteIcons
                typeName={translations.recordList.file}
                activateEditMode={activateEditMode}
                handleDeleteRecord={handleDeleteRecord}
                disabled={!isOnline}
                fileUrl={fileUrl}
              />
            }
          />
        )}
        <FileCard
          item={record}
          setIsSaving={setIsSaving}
          file={file}
          editMode={editMode}
          activateEditMode={activateEditMode}
          onValueChanged={handleValueChange}
          onSave={handleSave}
          onClose={onClose}
          form={form}
          fileRefData={refDataWithVoid}
          fileData={fileData}
          setFileData={setFileData}
          textContent={textContent}
          setTextContent={setTextContent}
          buttonRow={buttonRow}
          extra={
            <ExportOutlined
              onClick={() => {
                if (fileUrl) {
                  openTabWithPopupDetection(fileUrl);
                }
              }}
            />
          }
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          isFullScreenMode={!!isFullscreenMode}
        />
      </SaveSpinnerAndNavigationWarning>
    </>
  );
};
