import { translations } from '../constants/translations';
import { Rule, RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';

export function getRequiredRule(fieldName: string): Rule {
  return {
    required: true,
    message: translations.shared.getIsRequiredMessage(fieldName),
  };
}

export const getRequiredRuleWithValidator =
  (fieldName: string, isValid: () => boolean, required = true) =>
  () => ({
    required,
    validator: () => {
      if (isValid()) {
        return Promise.resolve();
      }
      return Promise.reject(translations.shared.getIsRequiredMessage(fieldName));
    },
  });

export const getRequiredRuleWithCustomMessage = (message: string) => () => ({
  required: true,
  validator: (_: RuleObject, value: StoreValue) => {
    if (!!value) {
      return Promise.resolve();
    }
    return Promise.reject(message);
  },
});

export function getValidEmailRule(emailSuffix = ''): Rule {
  return {
    type: 'email',
    message: translations.shared.emailIsNotValid,
    transform: (value) => value && `${value}${emailSuffix}`,
  };
}

export function getValidWebsiteRule(): Rule {
  return {
    message: translations.shared.websiteIsNotValid,
    pattern: new RegExp(
      '^(http(s)?:\\/\\/)?(www\\.)?[a-zA-Z0-9%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9%_\\+.~#?&//=]*)$'
    ),
  };
}

export function getValidPasswordRule(): Rule {
  return {
    message: translations.registration.registerUserStep.passwordRequirements,
    pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
  };
}

export function getValidListOfEmailsRule(): Rule {
  return {
    type: 'array',
    defaultField: { type: 'email', message: translations.shared.emailIsNotValid },
  };
}

export function getMinLengthRule(minLength: number): Rule {
  return {
    min: minLength,
    message: translations.shared.getMinInputLengthMessage(minLength),
  };
}

export function getMaxLengthRule(maxLength: number): Rule {
  return {
    max: maxLength,
    message: translations.shared.getMaxInputLengthMessage(maxLength),
  };
}

export function getValidRangeRule(
  min: number,
  max: number,
  message = 'Input must be between ' + min + ' and ' + max
): Rule {
  return {
    min,
    max,
    message,
  };
}

export function getValidMinMaxRule(
  min: number,
  max: number,
  message = 'Input must be between ' + min + ' and ' + max
): Rule {
  return {
    validator: (_, value) => {
      if (value < min || value > max) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  };
}

export function getValidRegexRule(pattern: RegExp, message: string): Rule {
  return {
    pattern,
    message,
  };
}

export function getPositiveValueRule(fieldName: string): Rule {
  return {
    validator: (_, value) => {
      if ((value as number) >= 0) {
        return Promise.resolve();
      }
      return Promise.reject(translations.shared.getPositiveValueMessage(fieldName));
    },
  };
}
