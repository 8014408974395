import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { BundleChildPromptModeId, translations } from '../../../constants/translations';
import { columns } from './columns';
import { BundleService, Service } from '../../../graph/types';
import { getServiceWithBundle } from '../../../components/ServiceRendered/AddServiceForm/addServiceFormUtil';
import { useOffline } from '../../../util/offline/offlineUtil';
import { useRxCollection } from 'rxdb-hooks';
import { isRxDocument, RxDocument } from 'rxdb';

export type ServiceBundleModalProps = {
  organizationId: string;
  serviceId: string;
  show?: boolean;
  isUserAware?: boolean;
  onClose: (service?: Service) => void;
  onSave: (service?: Service) => void;
  isSaving?: boolean;
};

const ServiceBundleModal = ({
  isSaving = false,
  show,
  organizationId,
  serviceId,
  onClose,
  onSave,
}: ServiceBundleModalProps) => {
  const [service, setService] = useState<Service>();
  const { canUseCollection } = useOffline('service');
  const serviceCollection = useRxCollection<Service>('service');

  useEffect(() => {
    const runEffect = async () => {
      if (!canUseCollection) {
        getServiceWithBundle(organizationId, { id: serviceId }, (service) => setService(service));
      } else {
        const service = await serviceCollection?.findOne(serviceId).exec();
        if (service) {
          setService(service);
        }
      }
    };
    runEffect();
  }, [organizationId, serviceId, serviceCollection, canUseCollection]);

  const onChangeCheckboxState = ({ id, prompt_mode }: { id: string; prompt_mode: number }) => {
    const bundle = service?.bundle?.map((bundle) => (bundle.id === id ? { ...bundle, prompt_mode } : bundle)) ?? [];
    setService({
      ...(isRxDocument(service) ? (service as RxDocument<Service>)._data : (service as Service)),
      bundle,
    });
  };

  const tableColumns: ColumnsType<BundleService> = [
    {
      ...columns.name,
    },
    {
      title: translations.serviceBundlePage.columns.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    columns.unit_name,
    {
      title: translations.serviceBundlePage.columns.actions,
      key: 'action',
      render: (bundleService: BundleService) => (
        <Checkbox
          disabled={bundleService.prompt_mode === BundleChildPromptModeId.Forced}
          checked={
            bundleService.prompt_mode === BundleChildPromptModeId.Checked ||
            bundleService.prompt_mode === BundleChildPromptModeId.Forced
          }
          onChange={(e) =>
            onChangeCheckboxState({
              id: bundleService.id,
              prompt_mode: e.target.checked ? BundleChildPromptModeId.Checked : BundleChildPromptModeId.Unchecked,
            })
          }
        />
      ),
    },
  ];
  return (
    <ModalWithCloseConfirm
      isSaving={!service || isSaving}
      title={service ? `${translations.serviceBundlePage.modal.title}: ${service.name}` : ''}
      open={show}
      onCancel={() => onClose(service)}
      onOk={() => onSave(service)}
      okText={translations.serviceBundlePage.modal.submitButton}
    >
      {service ? <Table dataSource={service?.bundle ?? []} columns={tableColumns} rowKey='id' /> : <Spin />}
    </ModalWithCloseConfirm>
  );
};

export default ServiceBundleModal;
