export enum TagColor {
  Color1 = '#52c41a', // green
  Color2 = '#fa541c', // volcano
  Color3 = '#13c2c2', // cyan
  Color4 = '#fa8c16', // orange
  Color5 = '#2f54eb', // geekblue
  Color6 = '#722ed1', // purple
  Color7 = '#8d6e63', // brown
  Color8 = '#c41d7f', // magenta
  Color9 = '#7cb305', // lime
  Color10 = '#cf1322', // red
  Color11 = '#d48806', // gold
  Color12 = '#2db7f5', // lightblue
  Color13 = '#fadb14', // bright yellow
  Color14 = '#af7ac5', // brighter purple
  Color15 = '#27ae60', // dark green
  Color16 = '#16a085', // dark cyan
  Color17 = '#333333', // grey
}
