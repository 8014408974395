import React, { useMemo } from 'react';
import { PaymentModalFormFields } from '../PaymentForm/PaymentForm';
import { translations } from '../../../../constants/translations';
import { ModalContentAndFooter } from '../ModalContentAndFooter';
import { showErrorMessage } from '../../../Notification/notificationUtil';
import { CardConnectTransactionRecordUpsert, Contact, StripeTransactionRecordUpsert } from '../../../../graph/types';
import { PaymentSummary } from '../PaymentSummary/PaymentSummary';
import { CreditCardManualFooter } from './CreditCardManualFooter';
import { CardConnectTokenizer, reformatEmvExpiry } from '../../../CardConnectTokenizer/CardConnectTokenizer';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { Loading } from '../../../Loading/Loading';
import StripeTokenizer from '../../../StripeTokenizer/StripeTokenizer';
import { getCardTransactionRecordUpsert } from '../creditCardMappingUtil';
import { useCountryBasedCurrencyCode } from '../../../../hooks/currencyAndDateHooks';
import { useGetStripeTokenUtils } from '../../../../hooks/stripeHooks';

interface CreditCardManualProps {
  formValues: PaymentModalFormFields;
  setIsSaving: (isLoading: boolean) => void;
  title?: string;
  okText?: string;
  showCancel?: boolean;
  showDate?: boolean;
  showAmount?: boolean;
  onCancel: () => void;
  processTransaction: (
    formValues: PaymentModalFormFields,
    transactionRecordUpsert: CardConnectTransactionRecordUpsert | StripeTransactionRecordUpsert
  ) => void;
  practiceId: string;
  isStripeProcessor: boolean;
  contact: Contact;
}

export const CreditCardManual: React.FC<CreditCardManualProps> = ({
  formValues,
  onCancel,
  setIsSaving,
  processTransaction,
  okText,
  showAmount,
  showCancel,
  showDate,
  isStripeProcessor,
  contact,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const currencyCode = useCountryBasedCurrencyCode();
  const {
    setCreditCard: setManualCreditCard,
    creditCard: manualCreditCard,
    practice,
    practiceLoading,
    organization,
    organizationLoading,
    practiceAddress,
  } = useGetStripeTokenUtils(organizationId);

  const processManualCreditCard = async () => {
    setIsSaving(true);
    if (manualCreditCard) {
      const creditCardToken = manualCreditCard.token;
      const creditCardExpiry = reformatEmvExpiry(manualCreditCard.expiryDate);
      const transactionUpsert = getCardTransactionRecordUpsert(
        formValues,
        'TOKEN',
        creditCardToken,
        creditCardExpiry,
        isStripeProcessor,
        currencyCode
      );

      await processTransaction(formValues, transactionUpsert);
    } else {
      showErrorMessage(translations.paymentModal.invalidTransaction);
    }
    setIsSaving(false);
  };

  const tokenizerExtraData = useMemo(
    () => ({
      payer: contact.name ?? '',
      addressLine1: contact.address?.[0]?.address_1 ?? '',
      addressLine2: contact.address?.[0]?.address_2 ?? '',
      city: contact.address?.[0]?.city ?? '',
      countryId: contact.address?.[0]?.country_id ?? '',
      provStateId: contact.address?.[0]?.country_prov_state_id ?? '',
      postalZip: contact.address?.[0]?.postal_zip ?? '',
    }),
    [contact]
  );

  if (practiceLoading || organizationLoading) {
    return <Loading />;
  }

  if (!practice) {
    return <p>{translations.shared.errorLoadingPage}</p>;
  }

  return (
    <ModalContentAndFooter
      footer={
        <CreditCardManualFooter
          onProcess={processManualCreditCard}
          okText={okText}
          showCancel={showCancel}
          onCancel={onCancel}
          processDisabled={!manualCreditCard}
        />
      }
    >
      <PaymentSummary showAmount={showAmount} showDate={showDate} formValues={formValues} />
      {isStripeProcessor ? (
        <StripeTokenizer
          stripeTokenizerCountryId={practiceAddress?.country_id || undefined}
          setCreditCard={setManualCreditCard}
          tokenizerExtraData={tokenizerExtraData}
          addressDemographics={organization?.ref_demographics ?? undefined}
        />
      ) : (
        practice.card_connect_manual && (
          <CardConnectTokenizer
            setCreditCard={setManualCreditCard}
            site={practice.card_connect_manual.manualCardSite}
            port={practice.card_connect_manual.manualCardPort}
          />
        )
      )}
    </ModalContentAndFooter>
  );
};
