import { Info, InfoUpsert, Ledger, LedgerUpsert } from '../../graph/types';
import { PaymentModalFormFields } from '../../components/PaymentModal/PaymentModal/PaymentForm/PaymentForm';
import { ID_FOR_OBJECT_CREATION } from './commonUpsertConstants';
import { LedgerEntryConfig, LedgerInfoType, LedgerTypeValue } from '../../constants/referenceData/ledgerReferenceData';
import { min } from 'lodash';
import { getInfoUpsert } from './infoMappingUtil';
import { LedgerChargeFormFields } from '../../components/LedgerChargeModal/LedgerChargeForm';
import { upsertDateFormat } from '../../constants/formats';

export interface BasicContactMetadata {
  contactId: string;
  practiceId: string;
}

export interface LedgerContactMetadata extends BasicContactMetadata {
  ledgerType: LedgerEntryConfig;
}

export interface PaymentForInvoiceMetadata extends BasicContactMetadata {
  invoiceContactLedgerId: string;
  invoiceFinancialOutstanding: number;
}

export class LedgerUpsertGenerator {
  static getNewPaymentUpsertFromContact(
    formFields: PaymentModalFormFields,
    metaData: BasicContactMetadata
  ): LedgerUpsert {
    return {
      id: ID_FOR_OBJECT_CREATION,
      ...LedgerUpsertGenerator.mapPaymentModalFormFieldsToLedgerUpsert(formFields),
      fnRunAutoApply: true,
      contextRecord: {
        contact_id: metaData.contactId,
        practice_id: metaData.practiceId,
        type_id: LedgerTypeValue.LGR_PAYMENT,
      },
    };
  }

  static getPaymentUpsertForExistingContactPayment(
    formFields: PaymentModalFormFields,
    ledgerEntry: Ledger
  ): LedgerUpsert {
    if (ledgerEntry.payment_electronic_id) {
      const infoUpsert = getInfoUpsert(LedgerInfoType.LGR_PAYER, formFields.payer, ledgerEntry.info ?? []);
      return {
        id: ledgerEntry.id,
        info: infoUpsert,
        fnRunAutoApply: true,
      };
    }
    return {
      id: ledgerEntry.id,
      dateRecord: formFields.date ? { financial_date: formFields.date.format(upsertDateFormat) } : undefined,
      totalRecord: formFields.amount ? { total: formFields.amount.toString() } : undefined,
      info: LedgerUpsertGenerator.mapInfoFieldsToUpsert(formFields, ledgerEntry.info ?? []),
      fnRunAutoApply: true,
    };
  }

  static getNewPaymentUpsertForInvoice(
    formFields: PaymentModalFormFields,
    metaData: PaymentForInvoiceMetadata
  ): LedgerUpsert {
    return {
      id: ID_FOR_OBJECT_CREATION,
      ...LedgerUpsertGenerator.mapPaymentModalFormFieldsToLedgerUpsert(formFields),
      fnRunAutoApply: true,
      assignment: [
        {
          charge_ledger_id: metaData.invoiceContactLedgerId,
          record: {
            financial_date: formFields.date.format(upsertDateFormat),
            amount: min([formFields.amount, metaData.invoiceFinancialOutstanding])?.toString() ?? '',
          },
        },
      ],
      contextRecord: {
        contact_id: metaData.contactId,
        practice_id: metaData.practiceId,
        type_id: LedgerTypeValue.LGR_PAYMENT,
      },
    };
  }

  static getNewLedgerChargeUpsert(formFields: LedgerChargeFormFields, metaData: LedgerContactMetadata): LedgerUpsert {
    return {
      id: ID_FOR_OBJECT_CREATION,
      ...LedgerUpsertGenerator.mapLedgerChargeFormToLedgerUpsert(formFields),
      fnRunAutoApply: metaData.ledgerType.fnRunAutoApply,
      contextRecord: {
        contact_id: metaData.contactId,
        practice_id: metaData.practiceId,
        type_id: metaData.ledgerType.value.toString(),
      },
    };
  }

  static getExistingLedgerChargeUpsert(formFields: LedgerChargeFormFields, editingLedgerId: string): LedgerUpsert {
    return {
      id: editingLedgerId,
      ...LedgerUpsertGenerator.mapLedgerChargeFormToLedgerUpsert(formFields),
      fnRunAutoApply: true,
    };
  }

  static getExistingLedgerUpsertToUpdateNote(editingLedgerId: string, noteValue?: string): LedgerUpsert {
    return {
      id: editingLedgerId,
      note: {
        value: noteValue,
      },
    };
  }

  private static mapLedgerChargeFormToLedgerUpsert(formFields: LedgerChargeFormFields): Partial<LedgerUpsert> {
    return {
      dateRecord: { financial_date: formFields.date.format(upsertDateFormat) },
      totalRecord: { total: formFields.amount?.toString() ?? '' },
      record: {},
      note: formFields.note
        ? {
            value: formFields.note,
          }
        : undefined,
    };
  }

  private static mapPaymentModalFormFieldsToLedgerUpsert(formFields: PaymentModalFormFields): Partial<LedgerUpsert> {
    const info = LedgerUpsertGenerator.mapInfoFieldsToUpsert(formFields);

    return {
      dateRecord: { financial_date: formFields.date.format(upsertDateFormat) },
      totalRecord: { total: formFields.amount.toString() },
      record: { payment_type_id: formFields.method },
      info,
    };
  }
  private static mapInfoFieldsToUpsert(
    formFields: Partial<PaymentModalFormFields>,
    existingInfo: Info[] = []
  ): InfoUpsert[] {
    const payerUpsert = getInfoUpsert(LedgerInfoType.LGR_PAYER, formFields.payer, existingInfo) || [];
    const checksumUpsert = getInfoUpsert(LedgerInfoType.LGR_CHECKNUM, formFields.check?.toString(), existingInfo) || [];

    return payerUpsert.concat(checksumUpsert);
  }
}
