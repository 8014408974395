import {
  ServiceRendered3pHistoryProperty,
  serviceRendered3pHistoryPropertyNames,
} from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { ServiceRendered3pHistory } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { ThirdPartyApprovalTypeNameKey } from '../../../constants/referenceData/thirdPartyReferenceData';

export const basicHistoryColumns: Record<
  ServiceRendered3pHistoryProperty,
  CustomColumnType<ServiceRendered3pHistory>
> = {
  third_party: {
    title: translations.historyPage.columns.thirdParty,
    dataIndex: serviceRendered3pHistoryPropertyNames.third_party,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.thirdParty),
  },
  date_sent: {
    title: translations.historyPage.columns.date,
    dataIndex: serviceRendered3pHistoryPropertyNames.date_sent,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.date),
    onFilter: getOnFilterFunctionFor(serviceRendered3pHistoryPropertyNames.date_sent),
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.date_sent),
  },
  doctor_name: {
    title: translations.historyPage.columns.doctor,
    dataIndex: serviceRendered3pHistoryPropertyNames.doctor_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.doctor),
    onFilter: getOnFilterFunctionFor(serviceRendered3pHistoryPropertyNames.doctor_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.doctor_name),
  },
  patient_name: {
    title: translations.historyPage.columns.patient,
    dataIndex: serviceRendered3pHistoryPropertyNames.patient_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.patient),
    onFilter: getOnFilterFunctionFor(serviceRendered3pHistoryPropertyNames.patient_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.patient_name),
  },
  info: {
    title: translations.historyPage.columns.info,
    dataIndex: serviceRendered3pHistoryPropertyNames.info,
    ...generalFilteringAndSortingSettings,
    filters: [
      { text: translations.approvalsPage.approvalTypeName.add, value: ThirdPartyApprovalTypeNameKey.Add },
      { text: translations.approvalsPage.approvalTypeName.update, value: ThirdPartyApprovalTypeNameKey.Update },
      { text: translations.approvalsPage.approvalTypeName.delete, value: ThirdPartyApprovalTypeNameKey.Delete },
    ],
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.info),
    onFilter: (value, record) => record.info === value,
  },
  service_name: {
    title: translations.historyPage.columns.service_name,
    dataIndex: serviceRendered3pHistoryPropertyNames.service_name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.historyPage.columns.service_name
    ),
    onFilter: getOnFilterFunctionFor(serviceRendered3pHistoryPropertyNames.service_name),
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.service_name),
  },
  quantity: {
    title: translations.historyPage.columns.quantity,
    dataIndex: serviceRendered3pHistoryPropertyNames.quantity,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.quantity),
    onFilter: getOnFilterFunctionFor(serviceRendered3pHistoryPropertyNames.quantity),
    sorter: getStringCompareFunctionFor(serviceRendered3pHistoryPropertyNames.quantity),
  },
  status: {
    title: translations.historyPage.columns.status,
    dataIndex: serviceRendered3pHistoryPropertyNames.status,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.historyPage.columns.status),
  },
};
