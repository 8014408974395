import { RecordItem, RecordType, selectRecordIfNoUnsavedChanges } from './recordUtils';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { List } from 'antd';
import EyeFilled from '@ant-design/icons/lib/icons/EyeFilled';

import { RecordsContext } from './store/state';
import { selectSelectedRecordId, selectSelectedServiceRender, selectServicesRender } from './store/selectors';

import { translations } from '../../constants/translations';
import './RecordListItem.css';
import { setServiceRenderAction } from './store/actions';
import { NotePreview } from '../../graph/types';
import LinkedServiceRenderedTooltip from '../../components/RecordSingleView/LinkedServiceRenderedTooltip';
import { TitleListContainer, TitleListHeader, StyledTag, DateContainer } from './RecordListItem.styles';
import { useUserLocaleData } from '../../hooks/useUserLocale';

export interface RecordListItemProps extends PropsWithChildren<unknown> {
  record: RecordItem;
}

export const RecordListItem: React.FC<RecordListItemProps> = ({ record }: RecordListItemProps) => {
  const { state, dispatch } = useContext(RecordsContext);
  const servicesRender = selectServicesRender(state);
  const selectedServicesRender = selectSelectedServiceRender(state);

  const serviceRenderedNote = useMemo(() => {
    if (record.type === RecordType.typeNote) {
      return servicesRender?.find((service) => service?.note_id === (record.originalObject as NotePreview)?.id);
    }
    return undefined;
  }, [record, servicesRender]);

  const isRecordSelected =
    selectSelectedRecordId(state) === record.recordId ||
    (serviceRenderedNote && selectedServicesRender?.id === serviceRenderedNote?.id);

  const onListItemClick = (record: RecordItem) => {
    selectRecordIfNoUnsavedChanges(state, dispatch, record.recordId);
  };

  const onClickLinkedIcon = () => {
    if (serviceRenderedNote) {
      dispatch(setServiceRenderAction(serviceRenderedNote));
    }
  };

  return (
    <List.Item
      className={isRecordSelected ? 'RecordListItem RecordListItem_selected' : 'RecordListItem'}
      actions={[record.renderBottomLine()]}
      onClick={() => {
        onListItemClick(record);
      }}
    >
      <List.Item.Meta
        avatar={isRecordSelected ? <EyeFilled /> : undefined}
        title={
          <TitleOfListItem record={record} onClickLinkedIcon={serviceRenderedNote ? onClickLinkedIcon : undefined} />
        }
        description={record.renderDescription()}
      />
    </List.Item>
  );
};

const TitleOfListItem: React.FC<{
  record: RecordItem;
  onClickLinkedIcon?: () => void;
}> = ({ record, onClickLinkedIcon }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const date = record.date.format(dateFormat);
  const dateDisplay = translations.recordList.date + date;
  return (
    <TitleListContainer>
      <TitleListHeader>
        <div>
          <StyledTag>{record.typeName}</StyledTag>
          {onClickLinkedIcon && <LinkedServiceRenderedTooltip onClick={onClickLinkedIcon} />}
        </div>

        <DateContainer>{dateDisplay}</DateContainer>
      </TitleListHeader>
      {record.getTag?.()}
    </TitleListContainer>
  );
};
