import React from 'react';
import { TextEditor } from '../../TextEditor/TextEditor';
import { EditableCardProps } from '../SharedProps';
import { translations } from '../../../constants/translations';
import { NoteTypeDto, OrganizationReferenceData } from '../../../graph/types';
import { HeaderRow } from '../HeaderRow';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { FormInstance } from 'antd';

type NoteCardProps = EditableCardProps & {
  showDateField?: boolean;
  noteRefData: OrganizationReferenceData[];
  linkedIcon?: React.ReactNode;
  form: FormInstance;
  fullScreenMode?: boolean;
};

export const NoteCardContent: React.FC<NoteCardProps> = ({
  editMode,
  activateEditMode,
  textContent,
  setTextContent,
  buttonRow,
  noteRefData,
  showDateField = true,
  linkedIcon,
  form,
  fullScreenMode,
}: NoteCardProps) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return (
    <>
      {(!fullScreenMode || editMode) && (
        <HeaderRow
          types={noteRefData as NoteTypeDto[]}
          typePlaceholder={translations.recordList.noteTypeDropdownPlaceholder}
          showDateField={showDateField}
          linkedIcon={linkedIcon}
          dateFormat={dateFormat}
          disabled={!editMode}
          textContent={textContent}
          setTextContent={setTextContent}
          form={form}
        />
      )}
      <div onClick={activateEditMode}>
        <TextEditor
          editMode={editMode}
          textContent={textContent}
          setTextContent={setTextContent}
          placeholder={translations.recordList.textFieldPlaceholder}
        />
      </div>
      {(!fullScreenMode || editMode) && buttonRow}
    </>
  );
};
