import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Tabs } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButtonOptionsValue,
  menuItemsStyle,
} from '../../../components/ActionButtonOptions/ActionButtonOptionsUtils';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';
import { ColumnDisplayFilterContext } from '../../../components/ColumnDisplayFilter/store/state';
import EmailModalForContactStatement from '../../../components/EmailModal/EmailModalForContactStatement';
import { JobStatus } from '../../../components/JobStatus/JobStatus';
import { Loading } from '../../../components/Loading/Loading';
import { MergeContacts } from '../../../components/MergeContacts/MergeContacts';
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from '../../../components/Notification/notificationUtil';
import { PriceValue } from '../../../components/PriceValue/PriceValue';
import { AddRecordButtons } from '../../../components/Records/AddRecordButtons/AddRecordButtons';
import { TabChangeWarning } from '../../../components/TabChangeWarning/TabChangeWarning';
import { BasicColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { setSaveSpinnerAction, setTextConfirmAction } from '../../../components/UtilsProvider/store/actions';
import { UtilsContext } from '../../../components/UtilsProvider/store/state';
import { ComponentWithPracticeDtoProps } from '../../../components/WithPractice/WithPractice';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { useRedirectAfterOfflineInsertSync } from '../../../event/RxDb/RxDbEvent';
import { Contact, ContactAlertType, ContactUpsert, Job, Maybe, OrganizationDto, Patient } from '../../../graph/types';
import { useDeleteContact, useGetContact, useUpdateContact } from '../../../hooks/ajax/contact/contactHooks';
import { useContactSubscription } from '../../../hooks/contactSubscriptionHook';
import { withContactIdParameter } from '../../../hooks/route/navigationHooks';
import { useGetContactIdFromRoute, useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { TableFilterAndSortKey, useTableResetFilterAndSort } from '../../../hooks/tableHooks';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { isUuid, useOffline, useUnsyncedData } from '../../../util/offline/offlineUtil';
import { openTabWithPopupDetection } from '../../../util/popupUtil';
import { getContactTags } from '../../../util/tags';
import { StickyHeader } from '../../Patients/ViewPatient/ViewPatientContent';
import { ContactDetails } from './ContactDetails/ContactDetails';
import { AddLedgerEntryButtons } from './ContactLedger/AddLedgerEntryButtons';
import { ContactLedger } from './ContactLedger/ContactLedger';
import { setShouldRefetchLedger } from './ContactLedger/store/actions';
import { ContactLedgerContext } from './ContactLedger/store/state';
import { ContactOptions } from './ContactOptions/ContactOptions';
import { ContactPatients } from './ContactPatients/ViewContactPatients';
import { ContactRecords } from './ContactRecords/ContactRecords';
import { PrintContactStatementModal } from './PrintContactStatementModal/PrintContactStatementModal';
import { useAlertsDto } from '../../../hooks/alertHooks';
import { ContactAlerts } from './ContactAlerts/ContactAlerts';
import StoredCardsModal from '../../../components/StoredCardsModal/StoredCardsModal';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { ContactCommunications } from './ContactCommunications/ContactCommunications';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';

const BalanceText = styled.h2`
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SpacedSpace = styled(Space)`
  margin: 0 1rem;
`;

const { TabPane } = Tabs;

const tabKeys = {
  contactDetails: 'contactDetails',
  contactProperties: 'contactProperties',
  contactRecords: 'contactRecords',
  contactLedger: 'contactLedger',
  contactPatients: 'contactPatients',
  contactOptions: 'contactOptions',
  alerts: 'alerts',
  contactCommunications: 'Communication Log',
};

enum ModalTypes {
  AddPatient = 'addPatient',
  PrintStatement = 'printStatement',
  EmailStatement = 'emailStatement',
  DeleteContact = 'deleteContact',
  DeleteConflict = 'deleteConflict',
  ContactAlert = 'contactAlert',
}

export const viewContactTestIds = {
  actionDropdown: 'VIEW_CONTACT_ACTION_DROPDOWN',
};

interface ViewContactProps extends ComponentWithPracticeDtoProps {
  contact?: Maybe<Contact>;
  refetchContact: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
  organization?: Maybe<OrganizationDto>;
}

const ViewContactComp: React.FC<ViewContactProps> = ({ practice, contact, refetchContact, organization }) => {
  const history = useHistory();
  const contactId = useGetContactIdFromRoute();
  const organizationId = useGetOrganizationIdFromRoute();
  const [activeTab, setActiveTab] = useState<string>(tabKeys.contactDetails);
  const [tabToBeActiveTab, setTabToBeActiveTab] = useState<string>('');
  const [contactSearchValue, setContactSearchValue] = useState<string>();
  const [showTooManyPatientResultsWarning, setShowTooManyPatientResultsWarning] = useState(false);
  const [contactMergeVisible, setContactMergeVisible] = useState<boolean>(false);
  const [storedCardsVisible, setStoredCardsVisible] = useState<boolean>(false);
  const { dispatch, state } = useContext(ContactLedgerContext);
  const [updateContact] = useUpdateContact();
  const [upsertContactLoading, setUpsertContactLoading] = useState(false);
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const deleteContact = useDeleteContact(organizationId);
  const { isOnline, canUseCollection } = useOffline();
  const { usesAlerts, getContactAlerts, renderContactAlertsModal } = useAlertsDto<ContactAlertType>(
    'prompt_contact',
    undefined,
    organization as any as OrganizationDto
  );
  const contactAlerts = getContactAlerts(contact);
  const showContactAlertModal = contactAlerts && contactAlerts.length > 0 && usesAlerts;
  const [isModalOpen, setIsModalOpen] = useState<ModalTypes | undefined>(
    showContactAlertModal ? ModalTypes.ContactAlert : undefined
  );

  const showCommunicationLog = useLDFlag(LDFlagNames.ContactCommunicationLog);

  const {
    state: { columnDisplayFilterProps },
  } = useContext(ColumnDisplayFilterContext);

  if (!contactId) {
    history.goBack();
  }

  const [displayWarningOnChangeTab, setDisplayWarningOnChangeTab] = useState(false);
  const [changesOnContactDetailsTab, setChangesOnContactDetailsTab] = useState(false);
  const [changesOnContactOptionsTab, setChangesOnContactOptionsTab] = useState(false);
  const [changesOnContactRecordsTab, setChangesOnContactRecordsTab] = useState(false);
  const [changesOnTab, setChangesOnTab] = useState(false);

  const [shouldResetContactDetails, setShouldResetContactDetails] = useState(false);
  const [shouldResetContactOptions, setShouldResetContactOptions] = useState(false);
  const [shouldResetContactRecords, setShouldResetContactRecords] = useState(false);
  const [shouldResetAlerts, setShouldResetAlerts] = useState(false);

  const [jobId, setJobId] = useState<string>();

  useContactSubscription(contactId);

  const [contactPatientTableColumns, setContactPatientTableColumns] = useState<BasicColumnType<Patient>[]>([]);
  const {
    filteredValue,
    sortOrderMap,
    tableChangeHandler,
    resetFiltersAndSort,
    modifiedFields,
    setFilterAndSortOrder,
  } = useTableResetFilterAndSort(TableFilterAndSortKey.ContactPatients);

  useEffect(() => {
    if (activeTab === tabKeys.contactPatients) {
      setFilterAndSortOrder((prev) => ({
        ...prev,
        filteredValue: {
          name: null,
          number: null,
          owner_names: null,
          related_names: null,
          species_name: null,
          status: [0],
        },
      }));
    }
  }, [activeTab, setFilterAndSortOrder]);

  const setContactPatientColumns = (columns: BasicColumnType<Patient>[]) => {
    setContactPatientTableColumns(columns);
  };

  const handleAssignAll = useCallback(async () => {
    const contactUpsert: ContactUpsert = {
      id: contactId,
      fnRunAutoApply: true,
    };

    setUpsertContactLoading(true);
    try {
      await updateContact({
        variables: {
          organizationId: practice.organization_id,
          contact: contactUpsert,
        },
      });
      dispatch(setShouldRefetchLedger(true));
    } catch (e) {
      showErrorMessage((e as Error).message ?? e);
    }
    setUpsertContactLoading(false);
  }, [contactId, dispatch, practice.organization_id, updateContact]);

  const handleContactDelete = useCallback(async () => {
    if (!contact) {
      showErrorMessage(translations.viewContactPage.unknownContact);
    } else {
      dispatchUtils(setSaveSpinnerAction({ isSaving: true }));
      await deleteContact({
        contactId: contact.id,
        onSuccess: () => history.goBack(),
        onConflictError: () => showWarningMessage(translations.viewContactPage.conflictWarningMessage(contact.name)),
        successMessage: translations.viewContactPage.deleteSuccessMessage,
      });
      dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
    }
  }, [contact, deleteContact, dispatchUtils, history]);

  const handleOpenDeleteConfirm = useCallback(() => {
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: handleContactDelete,
        title: translations.viewContactPage.deleteContactModalConfirmTitle,
        description: translations.viewContactPage.deleteContactConfirm(contact?.name ?? undefined),
      })
    );
  }, [handleContactDelete, contact, dispatchUtils]);

  const showMergeContactPopup = () => {
    setContactMergeVisible(true);
  };

  const printStatementHandler = () => {
    setIsModalOpen(ModalTypes.PrintStatement);
  };

  const emailStatementHandler = () => {
    setIsModalOpen(ModalTypes.EmailStatement);
  };

  const showStoredCardsPopup = () => {
    setStoredCardsVisible(true);
  };

  const renderHeaderExtras = useMemo(
    () => (
      <Space>
        <BalanceText>{translations.viewContactPage.accountBalance}: </BalanceText>
        <PriceValue component='h2' value={+(contact?.balance_posted || 0)} colorNegative='green' colorPositive='red' />
        {isOnline && (
          <Dropdown
            overlay={
              <ActionButtonOptionsValue
                emailStatementHandler={emailStatementHandler}
                printStatementHandler={printStatementHandler}
                handleOpenDeleteConfirm={handleOpenDeleteConfirm}
                showMergeContactPopup={showMergeContactPopup}
                showStoredCardsPopup={showStoredCardsPopup}
              >
                {activeTab === tabKeys.contactLedger && (
                  <Menu.Item
                    key={tabKeys.contactLedger}
                    icon={<CheckOutlined />}
                    onClick={handleAssignAll}
                    disabled={!state.isAssignAllEnabled}
                    style={menuItemsStyle.menuItem}
                  >
                    {translations.viewContactPage.assignAll}
                  </Menu.Item>
                )}
              </ActionButtonOptionsValue>
            }
          >
            <Button>
              {translations.viewContactPage.actions} <DownOutlined data-testid={viewContactTestIds.actionDropdown} />
            </Button>
          </Dropdown>
        )}
      </Space>
    ),
    [contact, isOnline, handleOpenDeleteConfirm, activeTab, handleAssignAll, state.isAssignAllEnabled]
  );

  if (upsertContactLoading) {
    return <Loading />;
  }

  if (!contact) {
    return <p>{translations.viewContactPage.unknownContact}</p>;
  }

  if (!organization) {
    return <p>{translations.shared.missingOrganization}</p>;
  }

  const handleTabClick = (key: string) => {
    if (changesOnContactDetailsTab || changesOnContactOptionsTab || changesOnContactRecordsTab || changesOnTab) {
      setDisplayWarningOnChangeTab(true);
      setTabToBeActiveTab(key);
    } else {
      setDisplayWarningOnChangeTab(false);
      setActiveTab(key);
    }
  };

  const handleOnOkTabChangeWarningModal = () => {
    setDisplayWarningOnChangeTab(false);
    switch (activeTab) {
      case tabKeys.contactDetails:
        setShouldResetContactDetails(true);
        break;
      case tabKeys.contactOptions:
        setShouldResetContactOptions(true);
        break;
      case tabKeys.contactRecords:
        setShouldResetContactRecords(true);
        break;
      case tabKeys.alerts:
        setShouldResetAlerts(true);
        break;
    }
    setActiveTab(tabToBeActiveTab);
  };

  const onBack = () => {
    history.goBack();
  };
  const title = `${contact?.number ? `#${contact.number} ` : ''}${contact?.name}`;

  const closeJobStatusModal = (errorMessage?: string) => {
    setJobId(undefined);
    if (errorMessage) {
      showErrorMessage(errorMessage);
    } else {
      showSuccessMessage(translations.shared.saveSuccessMessage);
    }
  };

  const handleJobfinish = async ({ result }: Job) => {
    if (result) {
      const { reportPointer } = await JSON.parse(result);
      if (reportPointer) {
        openTabWithPopupDetection(reportPointer, translations.popupDetectionMessage.printReport);
      }
    }
  };

  function contactPatientTab() {
    if (activeTab === tabKeys.contactPatients) {
      const handleOnClear = (key?: string) => {
        setContactSearchValue('');
        resetFiltersAndSort(key);
        columnDisplayFilterProps?.resetDisplayedColumnsToDefault();
      };
      return (
        <FlexDiv>
          <TitleWithSearchBox
            title={''}
            searchBoxPlaceholder={translations.shared.selectPatientModal.searchPlaceholder}
            onSearchValueChange={(event) => setContactSearchValue(event.target.value)}
            onClear={handleOnClear}
            showTooManyResultsWarning={showTooManyPatientResultsWarning}
            tags={mapKeysToColumnTitle(modifiedFields, contactPatientTableColumns)}
          />
        </FlexDiv>
      );
    }
    return null;
  }

  function getTabBarExtraContent() {
    if (activeTab === tabKeys.contactRecords) {
      return <AddRecordButtons />;
    }
    if (activeTab === tabKeys.contactLedger && isOnline) {
      return <AddLedgerEntryButtons />;
    }
    if (activeTab === tabKeys.contactPatients) {
      return (
        <FlexDiv>
          <SpacedSpace>
            {columnDisplayFilterProps && <ColumnDisplayFilter {...columnDisplayFilterProps} />}
            <Button key='addPatient' type='primary' onClick={() => setIsModalOpen(ModalTypes.AddPatient)}>
              {translations.viewContactPage.createPatient}
            </Button>
          </SpacedSpace>
        </FlexDiv>
      );
    }
    return null;
  }

  return (
    <>
      <StickyHeader onBack={onBack} title={title} tags={getContactTags(contact)} extra={renderHeaderExtras} />
      {isModalOpen === ModalTypes.EmailStatement && (
        <EmailModalForContactStatement contact={contact} onClose={() => setIsModalOpen(undefined)} />
      )}
      <Tabs
        activeKey={activeTab}
        type='card'
        onTabClick={handleTabClick}
        tabBarExtraContent={getTabBarExtraContent()}
        style={{ overflow: 'visible' }}
      >
        <TabPane tab={translations.viewContactPage.tabs.details} key={tabKeys.contactDetails}>
          <ContactDetails
            contact={contact}
            practice={practice}
            organization={organization}
            handleIsEditing={setChangesOnContactDetailsTab}
            setShouldResetOnTabChange={setShouldResetContactDetails}
            shouldResetOnTabChange={shouldResetContactDetails}
          />
        </TabPane>
        <TabPane tab={translations.viewContactPage.tabs.options} key={tabKeys.contactOptions}>
          <ContactOptions
            contact={contact}
            practice={practice}
            organization={organization}
            handleIsEditing={setChangesOnContactOptionsTab}
            setShouldResetOnTabChange={setShouldResetContactOptions}
            shouldResetOnTabChange={shouldResetContactOptions}
          />
        </TabPane>
        <TabPane tab={translations.viewContactPage.tabs.patients} key={tabKeys.contactPatients}>
          {contactPatientTab()}
          <ContactPatients
            setShowTooManyPatientResultsWarning={setShowTooManyPatientResultsWarning}
            searchValue={contactSearchValue}
            contact={contact}
            practice={practice}
            isModalOpen={isModalOpen === ModalTypes.AddPatient}
            closeModal={() => setIsModalOpen(undefined)}
            filteredValue={filteredValue}
            sortOrderMap={sortOrderMap}
            tableChangeHandler={tableChangeHandler}
            setContactPatientTableColumns={setContactPatientColumns}
          />
        </TabPane>
        <TabPane tab={translations.viewContactPage.tabs.ledger} key={tabKeys.contactLedger}>
          <ContactLedger contact={contact} refetchContact={refetchContact!} practice={practice} />
        </TabPane>
        <TabPane tab={translations.viewContactPage.tabs.records} key={tabKeys.contactRecords}>
          <ContactRecords
            contact={contact}
            handleIsEditing={setChangesOnContactRecordsTab}
            setShouldResetOnTabChange={setShouldResetContactRecords}
            shouldResetOnTabChange={shouldResetContactRecords}
          />
        </TabPane>
        {usesAlerts && (
          <TabPane tab={translations.viewContactPage.tabs.alerts} key={tabKeys.alerts}>
            <ContactAlerts
              contact={contact}
              handleIsEditing={setChangesOnTab}
              setShouldResetOnTabChange={setShouldResetAlerts}
              shouldResetOnTabChange={shouldResetAlerts}
              alertReferenceData={organization?.ref_contact?.alert_type ?? []}
            />
          </TabPane>
        )}
        {showCommunicationLog && (
          <TabPane tab={translations.viewContactPage.tabs.communicationLog} key={tabKeys.contactCommunications}>
            <ContactCommunications contact={contact} />
          </TabPane>
        )}
      </Tabs>

      {isModalOpen === ModalTypes.PrintStatement && (
        <PrintContactStatementModal
          contact={contact}
          practiceId={practice.id}
          onClose={() => setIsModalOpen(undefined)}
          canUseCollection={canUseCollection}
          setJobId={setJobId}
        />
      )}

      {isModalOpen === ModalTypes.ContactAlert &&
        usesAlerts &&
        renderContactAlertsModal(contactAlerts, contact, () => setIsModalOpen(undefined))}

      {contact && (
        <MergeContacts contact={contact} visible={contactMergeVisible} onClose={() => setContactMergeVisible(false)} />
      )}

      {contact && practice && (
        <StoredCardsModal
          organization={organization}
          practice={practice}
          contact={contact}
          visible={storedCardsVisible}
          onClose={() => setStoredCardsVisible(false)}
        />
      )}

      {displayWarningOnChangeTab && (
        <TabChangeWarning
          showTabChangeWarning={displayWarningOnChangeTab}
          onOk={handleOnOkTabChangeWarningModal}
          closeModal={() => setDisplayWarningOnChangeTab(false)}
        />
      )}

      {jobId && (
        <JobStatus
          jobId={jobId}
          onError={showErrorMessage}
          closeModal={closeJobStatusModal}
          onCompletion={handleJobfinish}
        />
      )}
    </>
  );
};

export const ViewContact: React.FC<ComponentWithPracticeDtoProps> = ({ practice }) => {
  const contactId = useGetContactIdFromRoute();
  const { contact, contactLoading, refetchContact } = useGetContact({
    contactId,
    organizationId: practice.organization_id,
  });
  const {
    state: { organization },
  } = useOrganizationContext();

  const { isOnline, initialized } = useOffline();
  const { hasUnsyncedData } = useUnsyncedData();

  useRedirectAfterOfflineInsertSync(
    'contact',
    isUuid(contactId) ? contactId : undefined,
    routes.viewContact,
    withContactIdParameter
  );

  useEffect(() => {
    // We need to refetch the contact after updating the contact text offline when the replication is ready
    // so we have a new contact text Id and we can get the updated contact text
    if (!hasUnsyncedData && initialized && refetchContact) {
      refetchContact();
    }
  }, [initialized, hasUnsyncedData, refetchContact]);

  if ((isOnline && isUuid(contactId)) || contactLoading) {
    return <Loading />;
  }

  return (
    <ViewContactComp
      practice={practice}
      contact={contact}
      refetchContact={refetchContact}
      organization={organization}
    />
  );
};
