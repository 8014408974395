import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select, Tooltip } from 'antd';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { getRequiredRule, getValidMinMaxRule } from '../../util/forms';
import { translations } from '../../constants/translations';
import { MAX_INPUT_AMOUNT, MIN_INPUT_AMOUNT } from '../../constants/referenceData/ledgerReferenceData';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { getFormattedDecimalString } from '../../util/displaying';
import { TextEditor } from '../TextEditor/TextEditor';
import { PrescriptionModalContentProps } from './PrescriptionModalContent.types';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { RightMarginPlusOutlined } from '../../pages/Services/ServiceForm/ServiceForm.style';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetServiceText } from '../../graph/queries/services';
import { Query } from '../../graph/types';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { ServiceNoteId } from '../../constants/referenceData/serviceReferenceData';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';

export const prescriptionModalFormTestId = 'prescriptionModalForm';
export const itemUnitFieldName = 'itemUnit';
export const refillsFieldName = 'refills';
const addNewValue = 'add-new-description';

export const rxNumberTestId = 'rxNumberTestId';
export const addNewTestId = 'add-new-testid';
export const textEditorTestId = 'text-editor-test-id';
export const refillsFieldTestId = 'refills-test-id';
export const itemQuantityTestId = 'item-quantity-test-id';

export const PrescriptionModalContent: React.FC<PrescriptionModalContentProps> = ({
  form,
  servicesList,
  contacts,
  patientName,
  doctorOptions,
  rxNumber,
  removedForRapidBilling,
  autofocus,
  isConsumingFill,
  showMultiPatientRefillsMessage,
  prescriptions,
  serviceId,
  isEditingPrescription,
  isEditingServiceRenderedLineItem,
  isAddingNewServiceRendered,
}) => {
  const [instructionsText, setInstructionsText] = useState<string>('');
  const [instructionsPlainText, setInstructionsPlainText] = useState<string>('');
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const [newDescription, setAddNewDescription] = useState<string>();
  const organizationId = useGetOrganizationIdFromRoute();
  const [loading, setLoading] = useState(false);
  const showRefills = useLDFlag(LDFlagNames.PrescriptionRefills);

  const formInstructions = form?.getFieldValue('instructions');

  const [disableRefillsField, setDisableRefillsField] = useState(isConsumingFill);
  const prescriptionQuantity = form?.getFieldValue('itemQuantity');

  const [itemQuantity, setItemQuantity] = useState(prescriptionQuantity);

  const existingPrescription = prescriptions?.find(
    (p) =>
      p.remaining_fills &&
      !p.is_filled &&
      p.service_id === serviceId &&
      Number(p?.item_quantity) === Number(prescriptionQuantity)
  );

  const [defaultInstructionText, setDefaultInstructionsText] = useState<string>();

  useEffect(() => {
    if (!defaultInstructionText) {
      setDefaultInstructionsText(formInstructions);
    }
  }, [formInstructions, defaultInstructionText]);

  useEffect(() => {
    if (!instructionsText) {
      setInstructionsText(formInstructions);
    }
  }, [formInstructions, instructionsText]);

  useEffect(() => {
    if (existingPrescription) {
      setDisableRefillsField(true);
      form?.setFieldValue(refillsFieldName, 0);
      if (isAddingNewServiceRendered) {
        form?.setFieldValue('instructions', existingPrescription?.instructions);
        setInstructionsText(existingPrescription?.instructions || '');
      }
    } else {
      setDisableRefillsField(false);
      if (isAddingNewServiceRendered) {
        form?.setFieldValue('instructions', defaultInstructionText);
        setInstructionsText(defaultInstructionText || '');
      }
    }
  }, [
    existingPrescription,
    serviceId,
    itemQuantity,
    prescriptionQuantity,
    form,
    isAddingNewServiceRendered,
    defaultInstructionText,
  ]);

  const decimalFieldChange = (fieldName: string) => {
    const value = form?.getFieldValue(fieldName);
    form?.setFields([{ name: fieldName, value: getFormattedDecimalString(value) }]);
  };

  const handleInstructionsTextChange = (textContent: string, plainText: string, _source: 'api' | 'user' | 'silent') => {
    setInstructionsPlainText(plainText);
    setInstructionsText(textContent);
    form?.setFields([{ name: 'instructions', value: textContent }]);
  };

  const serviceOptions = servicesList?.map(({ id, name }) => (
    <Select.Option key={id} value={name} label={name}>
      {name}
    </Select.Option>
  ));

  const handleDescriptionSelect = async (value: string, option: any) => {
    const foundService = servicesList?.find((s) => s.id === option.key);
    const unitName = foundService?.unit_name ?? '';
    let instruction;
    const defaultInstructions = foundService?.text?.find(
      ({ type_id }) => type_id === ServiceNoteId.PRESCRIPTION_INSTRUCTIONS
    );
    const fieldChanges = [
      { name: itemUnitFieldName, value: unitName },
      { name: 'service_id', value: option.key },
    ];

    if (defaultInstructions) {
      setLoading(true);
      try {
        const { data } = await AppSyncService.client.query<Pick<Query, 'getServiceText'>>({
          query: GetServiceText,
          variables: {
            organizationId,
            id: defaultInstructions.id,
          },
        });
        instruction = JSON.parse(data?.getServiceText.value ?? '').noteText;
      } catch (_) {}
      setLoading(false);
    }

    setInstructionsText(instruction);
    fieldChanges.push({ name: 'instructions', value: instruction });
    form?.setFields(fieldChanges);

    if (value === addNewValue && newDescription) {
      form?.setFieldsValue({ itemDescription: newDescription });
      form?.setFieldsValue({ service_id: null });
      serviceOptions?.push(
        <Select.Option key={newDescription} value={newDescription} label={newDescription}>
          {newDescription}
        </Select.Option>
      );
      setAddNewDescription('');
    }
  };

  const getInstructionsExtraText = () => {
    if (isEditingPrescription) {
      return translations.patientPage.prescriptions.editPrescriptionWarning;
    }

    return (isConsumingFill && (instructionsPlainText.length > 1 || formInstructions)) ||
      isEditingServiceRenderedLineItem
      ? translations.shared.prescriptionModal.fillInstructionsWarning
      : undefined;
  };

  return (
    <SaveSpinner isSaving={loading}>
      <MaxLengthFormItem label={translations.shared.prescriptionModal.fields.number} maxLength={50}>
        <Input
          data-testid={rxNumberTestId}
          disabled
          value={rxNumber || translations.shared.prescriptionModal.number_autogenerated_text}
        />
      </MaxLengthFormItem>
      {removedForRapidBilling || (
        <>
          <Form.Item
            name='contactId'
            label={translations.shared.prescriptionModal.fields.contact}
            rules={[getRequiredRule(translations.shared.prescriptionModal.fields.contact)]}
          >
            <Select {...searchableSelectParams} options={contacts} autoFocus={autofocus} />
          </Form.Item>
          <MaxLengthFormItem label={translations.shared.prescriptionModal.fields.patient} maxLength={50}>
            <Input disabled value={patientName} />
          </MaxLengthFormItem>
        </>
      )}
      <Form.Item
        name='prescribedUserId'
        label={translations.shared.prescriptionModal.fields.prescribedUser}
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.prescribedUser)]}
      >
        <Select {...searchableSelectParams} options={doctorOptions} />
      </Form.Item>
      <Form.Item
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.prescribedDate)]}
        label={translations.shared.prescriptionModal.fields.prescribedDate}
        name='prescribedDate'
      >
        <DatePicker format={dateFormat} />
      </Form.Item>
      <Form.Item
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.prescriptionExpiryDate)]}
        label={translations.shared.prescriptionModal.fields.prescriptionExpiryDate}
        name='prescriptionExpiryDate'
      >
        <DatePicker format={dateFormat} />
      </Form.Item>
      <Form.Item label={translations.shared.prescriptionModal.fields.productExpiryDate} name='productExpiryDate'>
        <DatePicker format={dateFormat} />
      </Form.Item>
      <MaxLengthFormItem
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.itemDescription)]}
        label={translations.shared.prescriptionModal.fields.itemDescription}
        name='itemDescription'
        maxLength={255}
      >
        <Select
          onSearch={setAddNewDescription}
          onSelect={handleDescriptionSelect}
          {...searchableSelectParams}
          filterOption={(input: string, option: any) =>
            option.value === addNewValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {newDescription?.length && (
            <Select.Option value={addNewValue}>
              <span data-testid={addNewTestId}>
                <RightMarginPlusOutlined />
                {translations.addServicePage.addEntry}
              </span>
            </Select.Option>
          )}
          {serviceOptions}
        </Select>
      </MaxLengthFormItem>
      <Form.Item hidden name='service_id' />
      <Form.Item
        key={'itemQuantity'}
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.itemQuantity)]}
        label={translations.shared.prescriptionModal.fields.itemQuantity}
        name='itemQuantity'
      >
        <Input
          type='number'
          min={MIN_INPUT_AMOUNT}
          data-testid={itemQuantityTestId}
          max={MAX_INPUT_AMOUNT}
          step='any'
          onSubmit={() => decimalFieldChange('itemQuantity')}
          onBlur={() => decimalFieldChange('itemQuantity')}
          onChange={(value) => setItemQuantity(value)}
        />
      </Form.Item>
      <MaxLengthFormItem
        rules={[getRequiredRule(translations.shared.prescriptionModal.fields.itemUnit)]}
        label={translations.shared.prescriptionModal.fields.itemUnit}
        name={itemUnitFieldName}
        maxLength={50}
      >
        <Input />
      </MaxLengthFormItem>
      <Tooltip
        title={disableRefillsField && showRefills ? translations.shared.prescriptionModal.refillsDisabledMessage : ''}
      >
        <Form.Item
          rules={
            showRefills
              ? [
                  getRequiredRule(translations.shared.prescriptionModal.fields.refills),
                  getValidMinMaxRule(MIN_INPUT_AMOUNT, MAX_INPUT_AMOUNT),
                ]
              : []
          }
          key={refillsFieldName}
          label={translations.shared.prescriptionModal.fields.refills}
          name={refillsFieldName}
          hidden={!showRefills}
          normalize={(value) => Number(value)}
          extra={
            showMultiPatientRefillsMessage
              ? translations.shared.prescriptionModal.multiPatientRefillsCaution
              : undefined
          }
        >
          <Input
            type='number'
            step='1'
            min={MIN_INPUT_AMOUNT}
            max={MAX_INPUT_AMOUNT}
            disabled={disableRefillsField}
            data-testid={refillsFieldTestId}
          />
        </Form.Item>
      </Tooltip>
      <Form.Item
        key={'instructions'}
        label={translations.shared.prescriptionModal.fields.instructions}
        name='instructions'
        extra={getInstructionsExtraText()}
      >
        <TextEditor
          testId={textEditorTestId}
          editMode
          textContent={instructionsText}
          setTextContent={handleInstructionsTextChange}
        />
      </Form.Item>
    </SaveSpinner>
  );
};
