import gql from 'graphql-tag';
import { OrganizationSubscriptionResultFields } from '../fragments';

export const UpsertSubscription = gql`
  mutation updateOrganizationSubscription($organizationId: ID!, $subscription: OrganizationSubscriptionUpsert!) {
    updateOrganizationSubscription(organizationId: $organizationId, subscription: $subscription) {
      ...OrganizationSubscriptionResultFields
    }
  }
  ${OrganizationSubscriptionResultFields}
`;

export const UpsertAcceptEula = gql`
  mutation acceptEula($organizationId: ID!, $agreement: SignedEula!) {
    acceptEULA(organizationId: $organizationId, agreement: $agreement) {
      id
    }
  }
`;

export const UpsertRetrySubscription = gql`
  mutation retrySubscription($organizationId: ID!, $request: RetrySubscriptionInput!) {
    retrySubscription(organizationId: $organizationId, request: $request) {
      id
      message
      success
    }
  }
`;
