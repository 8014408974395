import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { upsertDateFormat } from '../../../constants/formats';
import { translations } from '../../../constants/translations';
import { GetSyncErrors, UpsertOfflineSnapshot } from '../../../graph/queries/syncErrors';
import { MutationUpsertOfflineSnapshotArgs } from '../../../graph/types';
import { date30DaysAgo } from '../../../pages/SyncErrorLog/SyncErrorColumns';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useGetSyncErrors = (organizationId: string, fromDate?: string) => {
  const { data, loading, refetch } = useOfflineErrorSkipQuery<'getOfflineUpsertErrors'>(GetSyncErrors, {
    variables: { organizationId, fromDate: fromDate ?? date30DaysAgo(upsertDateFormat) },
    onError: (error) => showErrorMessage(error.message || translations.shared.loadErrorMessage),
  });

  return {
    errors: data?.getOfflineUpsertErrors,
    loading,
    refetch,
  };
};

export const useUpsertOfflineSnapshot = () => {
  return useOfflineErrorSkipMutation<'upsertOfflineSnapshot', MutationUpsertOfflineSnapshotArgs>(UpsertOfflineSnapshot);
};
