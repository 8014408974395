import { ColumnFilterItem } from 'antd/lib/table/interface';
import { LedgerType, LedgerTypeDto, Scalars } from '../../graph/types';

export type LedgerTypeReferenceDataType = LedgerType & {
  __typename?: 'LedgerTypeReferenceDataType';
  name: Scalars['String'];
};

export const MIN_INPUT_AMOUNT = 0.0;
export const MIN_INPUT_QTY = 0.001;
export const MAX_PERCENT_AMOUNT = 100;
// 1 Billion
export const MAX_INPUT_AMOUNT = 1000000000;

// from ref_ledger_type
export enum LedgerTypeValue {
  LGR_INVOICE = '1',
  LGR_PAYMENT = '2',
  LGR_CREDIT = '3',
  LGR_WRITEOFF = '4',
  LGR_INTEREST = '5',
  LGR_NSFCHARGE = '6',
  LGR_CHARGE = '7',
}

// from ref_ledger_info_type
export enum LedgerInfoType {
  LGR_CHECKNUM = '1',
  LGR_PAYER = '2',
}

// from ref_ledger_payment_type
export enum LedgerPaymentTypeValue {
  Cash = '1',
  Check = '2',
  Visa = '3',
  MasterCard = '4',
  Amex = '5',
  Discover = '6',
  Debit = '7',
  Credit = '8',
  MoneyOrder = '9',
  DirectDeposit = '10',
  EmailTransfer = '11',
  Paypal = '12',
  Venmo = '13',
  WireTransfer = '14',
  Zelle = '15',
  Other = '16',
}

export enum CardConnectCardType {
  Amex = '3',
  Visa = '4',
  MasterCard = '5',
  Discover = '6',
}

export enum StripeCardType {
  Amex = 'American Express',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  Discover = 'Discover',
}

export const getCardTypeByStripeName = (name?: string) => {
  switch (name) {
    case StripeCardType.Visa:
      return LedgerPaymentTypeValue.Visa;
    case StripeCardType.MasterCard:
      return LedgerPaymentTypeValue.MasterCard;
    case StripeCardType.Amex:
      return LedgerPaymentTypeValue.Amex;
    case StripeCardType.Discover:
      return LedgerPaymentTypeValue.Discover;
    default:
      return LedgerPaymentTypeValue.Credit;
  }
};

export const getCardTypeByCardConnectToken = (token?: string) => {
  const creditCardDigit = token?.charAt(1);
  switch (creditCardDigit) {
    case CardConnectCardType.Amex:
      return LedgerPaymentTypeValue.Amex;
    case CardConnectCardType.Visa:
      return LedgerPaymentTypeValue.Visa;
    case CardConnectCardType.MasterCard:
      return LedgerPaymentTypeValue.MasterCard;
    case CardConnectCardType.Discover:
      return LedgerPaymentTypeValue.Discover;
    default:
      return LedgerPaymentTypeValue.Credit;
  }
};

// ref_ledger_payment_electronic_type
export enum LedgerPaymentElectronicTypeValue {
  Not = 0,
  Debit = 10,
  Generic = 20,
  CreditCard = 30,
}

export interface LedgerEntryConfig extends ColumnFilterItem {
  text: string;
  fnRunAutoApply: boolean;
}

export enum LedgerEntryNameKey {
  Invoice = 'LGR_INVOICE',
  WriteOff = 'LGR_WRITEOFF',
  Payment = 'LGR_PAYMENT',
  NSFCharge = 'LGR_NSFCHARGE',
  Credit = 'LGR_CREDIT',
  Interest = 'LGR_INTEREST',
  AccountCharge = 'LGR_CHARGE',
}

export const ledgerEntryConfigs: { [nameKey in LedgerEntryNameKey]: LedgerEntryConfig } = {
  [LedgerEntryNameKey.Invoice]: {
    text: 'Invoice',
    value: 1,
    fnRunAutoApply: false,
  },
  [LedgerEntryNameKey.Payment]: {
    text: 'Payment',
    value: 2,
    fnRunAutoApply: true,
  },
  [LedgerEntryNameKey.Credit]: {
    text: 'Credit',
    value: 3,
    fnRunAutoApply: true,
  },
  [LedgerEntryNameKey.WriteOff]: {
    text: 'Write-Off',
    value: 4,
    fnRunAutoApply: true,
  },
  [LedgerEntryNameKey.Interest]: {
    text: 'Interest',
    value: 5,
    fnRunAutoApply: false,
  },
  [LedgerEntryNameKey.NSFCharge]: {
    text: 'NSF Charge',
    value: 6,
    fnRunAutoApply: false,
  },
  [LedgerEntryNameKey.AccountCharge]: {
    text: 'Account Charge',
    value: 7,
    fnRunAutoApply: false,
  },
};

export const getLedgerTypesReferenceData = (ledgerTypes: LedgerTypeDto[] = []): LedgerTypeReferenceDataType[] => {
  const filteredLedgerTypes = ledgerTypes.map((type) => {
    return {
      id: type.id,
      name_key: type.name_key,
      name: ledgerEntryConfigs[type.name_key as LedgerEntryNameKey].text,
      general_ledger_id: type.general_ledger_id,
      sort_order: type.sort_order,
    } as LedgerTypeReferenceDataType;
  });

  return filteredLedgerTypes;
};
