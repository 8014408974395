import { ExportOutlined } from '@ant-design/icons';
import { Card, Collapse } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  LabRequestStatusId,
  labRequestStatusConfig,
  LabDeviceId,
  LabClassId,
} from '../../../constants/referenceData/labReferenceData';
import { getRouteWithOrganizationId, routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';
import { LabRequest } from '../../../graph/types';
import { useGetLabResultStatus } from '../../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { AntechImmersive } from '../../AntechImmersive/AntechImmersive';
import { CloseButton } from '../../CloseButton/CloseButton';
import { Loading } from '../../Loading/Loading';
import { getPaddedSpans, RecordItem, RecordType } from '../../Records/recordUtils';
import { BaseCardProps } from '../SharedProps';
import { ListItemHeader } from '../../Records/FullscreenRecords/ListItemHeader';

const CustomIFrame = styled.iframe`
  width: 100%;
  height: 600px;
  margin: auto;
`;

const BlackLink = styled(Link)`
  color: black;
`;

const PaddedDiv = styled.div`
  padding: 20px;
`;

const showableStatuses = [
  LabRequestStatusId.Submitted,
  LabRequestStatusId.Complete,
  LabRequestStatusId.Accessioned,
  LabRequestStatusId.Pending,
  LabRequestStatusId.Draft,
];

export const shouldShowRequest = (labRequest: LabRequest) =>
  showableStatuses.includes(labRequest.status_id as LabRequestStatusId);

export const getRecordFromLabRequest = (labRequest: LabRequest): RecordItem => {
  const renderBottomLine = () => {
    const userDisplay = translations.recordList.doctors + labRequest.doctor_name;
    return getPaddedSpans(userDisplay);
  };

  return {
    originalObject: labRequest,
    recordId: labRequest.__typename + labRequest.id,
    type: RecordType.typeLabRequest,
    typeName: translations.recordList.labRequests.labResult,
    date: dayjs(labRequest.updated),
    created: dayjs(labRequest.created),
    renderBottomLine,
    renderDescription: () => (
      <FlexContainer direction='column'>
        <span>{labRequest.lab_service_name}</span>
        <span>{`${translations.recordList.labRequests.status} ${
          labRequestStatusConfig[labRequest.status_id as LabRequestStatusId].text
        }`}</span>
        <span>{`${translations.recordList.labRequests.accession} ${
          (labRequest.lab_class_id === LabClassId.VetConnect
            ? labRequest.lab_created_order_number
            : labRequest.lab_created_accession_id) ?? ''
        }`}</span>
      </FlexContainer>
    ),
  };
};

interface LabRequestCardProps extends BaseCardProps {
  labRequest: LabRequest;
  isFullscreenMode?: boolean;
  record?: RecordItem;
}

const iframeId = 'results';

export const LabRequestCard: React.FC<LabRequestCardProps> = ({ record, labRequest, onClose, isFullscreenMode }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const labDeviceId = labRequest.lab_device_id;
  const route = getRouteWithOrganizationId(routes.vetConnectResultHandler!, organizationId);
  const [labUrl, setLocalLabUrl] = useState<string | null>(labRequest?.lab_result_url || null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isVetConnectRequest = useMemo(
    () => labRequest.lab_class_id === LabClassId.VetConnect,
    [labRequest.lab_class_id]
  );
  const { labResultStatus, loading, refetch } = useGetLabResultStatus(
    organizationId,
    labDeviceId,
    (isVetConnectRequest ? labRequest.lab_created_order_number : labRequest.pims_created_order_number) || undefined,
    // Use pims_created_accession_id to get the results
    labRequest.pims_created_accession_id || undefined,
    { skip: !isVetConnectRequest, fetchPolicy: 'network-only' }
  );

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    if (labResultStatus?.url) {
      setLocalLabUrl(labResultStatus?.url);
    }
  }, [labResultStatus?.url]);

  useEffect(() => {
    if (isVetConnectRequest) {
      refetch();
    }
    // eslint-disable-next-line
  }, [labRequest.id, isVetConnectRequest]);

  if (loading) {
    if (isFullscreenMode) {
      return null;
    }
    return <Loading />;
  }
  const labRequestContent = () => (
    <>
      {labUrl &&
        (labDeviceId === LabDeviceId.Antech ? (
          <AntechImmersive isDockable={false} url={labUrl} showComponent />
        ) : (
          <CustomIFrame src={labUrl} id={iframeId} />
        ))}

      {!labUrl && <PaddedDiv>{translations.recordList.labRequests.noResults}</PaddedDiv>}
    </>
  );

  const displayExternalLink = (withCloseButton = true): React.ReactElement => {
    return (
      <>
        {isVetConnectRequest ? (
          <BlackLink
            to={{
              pathname: route,
              search: `deviceId=${labDeviceId}&orderNumber=${labRequest.lab_created_order_number}&accessionNumber=${labRequest.pims_created_accession_id}`,
            }}
            target='blank'
          >
            <ExportOutlined />
          </BlackLink>
        ) : (
          labUrl && <ExportOutlined onClick={() => window.open(labUrl)} />
        )}
        {withCloseButton && <CloseButton onClick={onClose} />}
      </>
    );
  };

  return isFullscreenMode ? (
    <>
      <ListItemHeader
        item={record}
        onToggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        editableOptions={displayExternalLink(false)}
      />
      <Collapse bordered={false} activeKey={isCollapsed ? [] : ['1']} onChange={() => setIsCollapsed(!isCollapsed)}>
        <Collapse.Panel className='CollapsePanel' header='' key='1' showArrow={false}>
          {labRequestContent()}
        </Collapse.Panel>
      </Collapse>
    </>
  ) : (
    <Card
      title={`${translations.recordList.labRequests.labResult}: ${labRequest.lab_service_name ?? ''}`}
      extra={displayExternalLink()}
    >
      {labRequestContent()}
    </Card>
  );
};
