import { PaymentTypeReferenceDataType } from '../../constants/referenceData/paymentReferenceData';
import {
  ContactAlertTypeDto,
  ContactStatusTypeDto,
  FileTypeDto,
  GeneralLedgerDto,
  LedgerTypeDto,
  NoteTypeDto,
  OrganizationUserTypeDto,
  PatientAlertTypeDto,
  PatientBreedDto,
  PatientColorDto,
  PatientGenderDto,
  PatientSpeciesDto,
  QuickNoteDto,
  RefFileTypeRecordUpsert,
  RefNoteTypeRecordUpsert,
  ReminderStatusDto,
  TaxTypeDto,
} from '../../graph/types';

export const tabKeys = {
  species: 'species',
  breeds: 'breeds',
  colors: 'colors',
  genders: 'genders',
  taxTypes: 'taxTypes',
  userTypes: 'userTypes',
  contactStatus: 'contactStatus',
  fileTypes: 'fileTypes',
  noteTypes: 'noteTypes',
  quickNotes: 'quickNotes',
  generalLedger: 'generalLedger',
  ledgerTypes: 'ledgerTypes',
  reminderStatus: 'reminderStatuses',
  contactTypes: 'contactTypes',
  paymentTypes: 'paymentTypes',
  patientAlerts: 'patientAlerts',
  contactAlerts: 'contactAlerts',
};

export enum FileAndNoteTypes {
  Patient = 'patient',
  Contact = 'contact',
}

export type NoteOrFileRefDataType =
  | (FileTypeDto & Partial<RefFileTypeRecordUpsert>)
  | (NoteTypeDto & Partial<RefNoteTypeRecordUpsert>);

export type ReferenceDataType =
  | PatientSpeciesDto
  | PatientBreedDto
  | PatientColorDto
  | PatientGenderDto
  | TaxTypeDto
  | OrganizationUserTypeDto
  | ContactStatusTypeDto
  | NoteOrFileRefDataType
  | QuickNoteDto
  | GeneralLedgerDto
  | ReminderStatusDto
  | PaymentTypeReferenceDataType
  | PatientAlertTypeDto
  | ContactAlertTypeDto
  | LedgerTypeDto;

export type FilteredBy = {
  key: keyof ReferenceDataType;
  values: { value: string; label: string }[];
  placeholderText: string;
};

const addTypeMappingForNote = (types: NoteTypeDto[], type: FileAndNoteTypes) => {
  const isPatient = type === FileAndNoteTypes.Patient;
  const isContact = type === FileAndNoteTypes.Contact;
  return types.map((t) => ({
    ...t,
    patient: isPatient,
    contact: isContact,
    patient_print: t.patient_print ?? false,
    contact_print: t.contact_print ?? false,
    invoice_print: t.invoice_print ?? false,
  }));
};

const addTypeMappingForFile = (types: FileTypeDto[], type: FileAndNoteTypes) => {
  const isPatient = type === FileAndNoteTypes.Patient;
  const isContact = type === FileAndNoteTypes.Contact;
  return types.map((t) => ({
    ...t,
    patient: isPatient,
    contact: isContact,
  }));
};

const joinPatientAndContactTypes = (patientTypes: NoteOrFileRefDataType[], contactTypes: NoteOrFileRefDataType[]) => {
  const joinedTypes = [...patientTypes];
  contactTypes.forEach((contactType) => {
    const commonType = joinedTypes.find((patientType) => patientType.id === contactType.id);
    if (commonType) {
      commonType.contact = true;
    } else {
      joinedTypes.push(contactType);
    }
  });
  return joinedTypes;
};

export const getCombinedNoteAndFileTypes = (
  patientNoteTypes: NoteTypeDto[],
  contactNoteTypes: NoteTypeDto[],
  patientFileTypes: FileTypeDto[],
  contactFileTypes: FileTypeDto[]
) => {
  const mappedContactFileTypes = addTypeMappingForFile(contactFileTypes, FileAndNoteTypes.Contact);
  const mappedContactNoteTypes = addTypeMappingForNote(contactNoteTypes, FileAndNoteTypes.Contact);
  const mappedPatientFileTypes = addTypeMappingForFile(patientFileTypes, FileAndNoteTypes.Patient);
  const mappedPatientNoteTypes = addTypeMappingForNote(patientNoteTypes, FileAndNoteTypes.Patient);

  const combinedFileTypes = joinPatientAndContactTypes(mappedPatientFileTypes, mappedContactFileTypes);
  const combinedNoteTypes = joinPatientAndContactTypes(mappedPatientNoteTypes, mappedContactNoteTypes);

  return { combinedFileTypes, combinedNoteTypes };
};
