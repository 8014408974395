import React, { useMemo } from 'react';
import { Button } from 'antd';
import { translations } from '../../../constants/translations';
import { routes } from '../../../constants/routes';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Service } from '../../../graph/types';
import { addSortingPriorityTo } from '../../../util/filterAndSorting';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute } from '../../../hooks/route/navigationHooks';
import { useBasicServiceColumns } from './serviceColumns';
import { shouldShowTooManyResultsWarning } from '../../../hooks/setWarningIfTooManyResults';
import {
  TableFilterAndSortKey,
  TableKey,
  useTableColumnDisplayFilter,
  useTableResetFilterAndSort,
} from '../../../hooks/tableHooks';
import { servicePropertyNames } from '../../../constants/propertyNames';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';
import { mapKeysToColumnTitle } from '../../../util/mapUtil';
import { useGetServicesWithSearch } from '../../../hooks/ajax/service/serviceHooks';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { getGLAccountFilterOptions } from '../../../util/serviceFilterAndSorting';

export const ServicesOverview: React.FC = () => {
  const { navigateTo } = useNavigationToRoute();
  const organizationId = useGetOrganizationIdFromRoute();
  const basicServiceColumns = useBasicServiceColumns();

  const { services, servicesLoading, setSearchTerm: searchService } = useGetServicesWithSearch(organizationId);

  const glAccountFilterOptions: ColumnFilterItem[] = useMemo(
    () => getGLAccountFilterOptions(services ?? []),
    [services]
  );

  const { filteredValue, sortOrderMap, tableChangeHandler, resetFiltersAndSort, modifiedFields } =
    useTableResetFilterAndSort(TableFilterAndSortKey.ServicesOverview);

  const navigateToViewService = (serviceId: string) => () => {
    navigateTo(routes.viewService, { ':serviceId': serviceId });
  };

  const linkToViewService = (text: string, service: Service) => {
    return <TableCellLink onClick={navigateToViewService(service.id)}>{text}</TableCellLink>;
  };

  const addService = () => {
    navigateTo(routes.addService);
  };

  const optionalColumns: CustomColumnType<Service>[] = useMemo(
    () => [
      {
        ...basicServiceColumns.unit_name,
        filteredValue: filteredValue[servicePropertyNames.unit_name] ?? null,
        sortOrder: sortOrderMap[servicePropertyNames.unit_name],
        // space char won't affect display if nothing is before it
        render: (unit_name: string, record: Service) => {
          if (!unit_name) {
            return '';
          }

          let amountString = '';
          if (record.quantity_default && Number(record.quantity_default) > 0) {
            amountString = record.quantity_default + ' ';
          }

          return amountString + unit_name;
        },
        width: 100,
      },
      {
        ...basicServiceColumns.price,
        filteredValue: filteredValue[servicePropertyNames.price] ?? null,
        sortOrder: sortOrderMap[servicePropertyNames.price],
        width: 100,
      },
      {
        ...basicServiceColumns.general_ledger_name,
        filteredValue: filteredValue[servicePropertyNames.general_ledger_name] ?? null,
        sortOrder: sortOrderMap[servicePropertyNames.general_ledger_name],
        filters: glAccountFilterOptions,
        width: 100,
      },
      {
        ...basicServiceColumns.inactive,
        filteredValue: filteredValue[servicePropertyNames.inactive] ?? null,
        sortOrder: sortOrderMap[servicePropertyNames.inactive],
        width: 100,
      },
      {
        ...basicServiceColumns.tax_code_names,
        filteredValue: filteredValue[servicePropertyNames.tax_code_names] ?? null,
        sortOrder: sortOrderMap[servicePropertyNames.tax_code_names],
        width: 100,
      },
    ],
    [filteredValue, sortOrderMap, basicServiceColumns, glAccountFilterOptions]
  );

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter(TableKey.ServicesOverview, optionalColumns);

  const columns: CustomColumnType<Service>[] = [
    {
      ...basicServiceColumns.name,
      filteredValue: filteredValue[servicePropertyNames.name] ?? null,
      sortOrder: sortOrderMap[servicePropertyNames.name],
      render: (name: string, record: Service) => linkToViewService(name, record),
      width: 200,
    },
    ...displayedColumns,
  ];
  addSortingPriorityTo(columns);

  const handleOnClear = (key?: string) => {
    searchService('');
    resetFiltersAndSort(key);
    resetDisplayedColumnsToDefault();
  };

  return (
    <>
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.servicesPage.title}
            searchBoxPlaceholder={translations.servicesPage.searchPlaceholder}
            onSearchValueChange={(event) => searchService(event.target.value)}
            onClear={handleOnClear}
            loading={servicesLoading}
            showTooManyResultsWarning={shouldShowTooManyResultsWarning(services)}
            tags={mapKeysToColumnTitle(modifiedFields, columns)}
          />
        }
        extra={[
          <ColumnDisplayFilter
            key={'columnDisplayFilter'}
            initiallyDisplayedColumns={displayedColumnKeys}
            setDisplayedColumns={setDisplayedColumnKeys}
            columnKeyAndTitleList={columnKeyAndTitleList}
            resetDisplayedColumnsToDefault={resetDisplayedColumnsToDefault}
          />,
          <Button key='addService' type='primary' onClick={addService}>
            {translations.servicesPage.addServiceButton}
          </Button>,
        ]}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.ServicesOverview}
        columns={columns}
        dataSource={services}
        rowKey={'id'}
        loading={servicesLoading}
        onChange={tableChangeHandler}
      />
    </>
  );
};
