import { RxJsonSchema } from 'rxdb';
import { Organization } from '../../../graph/types';
import { Maybe, RxScalars } from '../databaseUtils';

export type RxOrganization = Partial<Organization>;

export const organizationSchema: RxJsonSchema<RxOrganization> = {
  version: 6,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    name: RxScalars.String,
    enable_offline: Maybe(RxScalars.Boolean),
    default_practice_id: Maybe(RxScalars.ID),
    default_practice_name: Maybe(RxScalars.String),
    created: RxScalars.String,
    updated: RxScalars.String,
    subscription: RxScalars.Object,
    practice: RxScalars.Array,
    maintenance: Maybe(RxScalars.Object),
    ref_contact: Maybe(RxScalars.Object),
    ref_patient: Maybe(RxScalars.Object),
    ref_system: Maybe(RxScalars.Object),
    logo: Maybe(RxScalars.Object),
    ref_demographics: Maybe(RxScalars.Object),
    ref_user: Maybe(RxScalars.Object),
    ref_financial: Maybe(RxScalars.Object),
    account_owner_id: Maybe(RxScalars.ID),
    phone: RxScalars.Array,
  },
  required: ['id'],
  indexes: ['updated'],
};
