import React, { useState } from 'react';
import { Popover } from 'antd';
import { PushpinTwoTone } from '@ant-design/icons';
import { PopoverWithNoteContent } from './PopoverWithNoteContent';

interface PopoverWithNoteProps {
  id: string;
  note_exists: boolean;
  onClick?: () => void;
}
export const pushpinTestId = 'pushpinTestId';

export const PopoverWithNote: React.FC<PopoverWithNoteProps> = ({ id, note_exists, onClick }) => {
  const [ledgerId, setLedgerId] = useState('');
  const getPopoverContent = () => <PopoverWithNoteContent ledgerId={ledgerId} />;

  return (
    <Popover destroyTooltipOnHide onOpenChange={() => setLedgerId(id)} content={getPopoverContent}>
      {note_exists ? <PushpinTwoTone onClick={onClick} data-testid={pushpinTestId} /> : <></>}
    </Popover>
  );
};
