import { Contact, ContactStatusType, ContactStatusTypeDto } from '../graph/types';
import { translations } from '../constants/translations';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useMemo } from 'react';
import { contactTypeConfigs, ContactTypeNameKey } from '../constants/referenceData/contactReferenceData';

export function getAvailableContactTypeFilters(contacts: Contact[]): ColumnFilterItem[] {
  const availableContactTypeKeys = getAvailableContactTypeKeys(contacts);
  return mapContactTypeKeysToFilterItems(availableContactTypeKeys);
}

function getAvailableContactTypeKeys(contacts: Contact[]) {
  const availableContactTypeKeys: Set<string> = new Set();
  contacts.forEach((contact) => {
    contact.type_name_keys?.forEach((contactTypeKey) => {
      availableContactTypeKeys.add(contactTypeKey);
    });
  });
  return availableContactTypeKeys;
}

export function getAvailableContactStatusTypeFilters(
  contactStatusTypes: ContactStatusType[],
  contacts: Contact[] = []
) {
  const filterOptions: { [value: string]: string } = {};

  contactStatusTypes.forEach((status) => {
    filterOptions[status.id] = status.name;
  });

  contacts.forEach((contact) => {
    if (!contact.contact_status_id || !contact.contact_status_name) {
      return;
    }

    if (!filterOptions[contact.contact_status_id]) {
      filterOptions[contact.contact_status_id] = translations.shared.getVoidRecord(contact.contact_status_name || '');
    }
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}

export function getAvailableContactStatusTypeFiltersDto(
  contactStatusTypes: ContactStatusTypeDto[],
  contacts: Contact[] = []
) {
  const filterOptions: { [value: string]: string } = {};

  contactStatusTypes.forEach((status) => {
    filterOptions[status.id] = status.name;
  });

  contacts.forEach((contact) => {
    if (!contact.contact_status_id || !contact.contact_status_name) {
      return;
    }

    if (!filterOptions[contact.contact_status_id]) {
      filterOptions[contact.contact_status_id] = translations.shared.getVoidRecord(contact.contact_status_name || '');
    }
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}

function mapContactTypeKeysToFilterItems(contactTypeKeys: Set<string>) {
  const filters: ColumnFilterItem[] = [];

  contactTypeKeys.forEach((contactTypeKey) => {
    const contactTypeConfig = contactTypeConfigs[contactTypeKey as ContactTypeNameKey];
    if (contactTypeConfig) {
      filters.push({ text: contactTypeConfig.label, value: contactTypeKey });
    }
  });

  return filters;
}

export function useGetContactTypeFilters(contacts: Contact[] | undefined) {
  return useMemo(() => {
    let filters: ColumnFilterItem[] = [];
    if (contacts) {
      filters = getAvailableContactTypeFilters(contacts);
    }
    return filters;
  }, [contacts]);
}
