import { Button, Form, Input, Layout } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { ComponentWithPracticeProps } from '../../components/WithPractice/WithPractice';
import {
  PaymentStripeAccountStatus,
  paymentStripeAccountStatusConfigs,
} from '../../constants/referenceData/paymentReferenceData';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import { useUpsertStripeTransaction } from '../../hooks/ajax/paymentGateway/paymentGatewayHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import styled from 'styled-components';
import { routes } from '../../constants/routes';
import { useGetStripeAccountStatus } from '../../hooks/useGetStripeAccountStatus';
import { MaxLengthFormItem } from '../../components/MaxLengthFormItem/MaxLengthFormItem';
import { getMinLengthRule, getRequiredRule, getValidRegexRule } from '../../util/forms';
import { useGetPracticeSettings } from '../Contacts/ViewContact/statementUtils';
import { isEmpty, uniq } from 'lodash';
import { stripeRequirementsConfig } from '../../constants/referenceData/stripeReferenceData';
import { getStripeErrorMessage } from '../Registration/RegistrationStripe/RegistrationStripe';

const { Content } = Layout;

const SectionWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
`;

const LastSectionWrapper = styled(SectionWrapper)`
  border-bottom: none;
`;

const SectionTitle = styled.h1`
  font-weight: 600;
`;

const StatusText = styled.p`
  margin-bottom: 1rem;
`;

const ReadyIcon = styled(CheckCircleFilled)`
  color: green;
`;

const NotFoundIcon = styled(WarningFilled)`
  color: orange;
`;

const NotSetupIcon = styled(WarningFilled)`
  color: orange;
`;

const InsideList = styled.ul`
  list-style-position: inside;
  margin: 1em 0;
`;

const SaveConfigurationButton = styled(Button)`
  margin-left: 3em;
`;

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 5 },
};

type AccountConfigurationFormType = {
  statementDescriptor: string;
};

export const StripeConfirmation: React.FC<ComponentWithPracticeProps> = ({ practice }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = practice.id;
  const [upsertStripeTransaction] = useUpsertStripeTransaction(organizationId);
  const {
    status: stripeStatus,
    loading: statusCheckLoading,
    checkStatus,
    eventuallyDue,
  } = useGetStripeAccountStatus(organizationId, practiceId);
  const { settings, refetch: refetchSettings } = useGetPracticeSettings(
    organizationId,
    practiceId,
    PracticeSettingsNameKey.StripePracticeSet
  );
  const existingStatementDescriptor = useMemo(
    () => JSON.parse(settings?.value ?? '{}')?.Payments?.PaymentsStatementDescriptor,
    [settings?.value]
  );
  const [loading, setLoading] = useState(false);
  const { navigateBack, navigateTo } = useNavigationToRoute();
  const [form] = Form.useForm();

  const initialAccountConfiguration: AccountConfigurationFormType = {
    statementDescriptor: existingStatementDescriptor,
  };

  const [saveChangesVisible, setSaveChangesVisible] = useState(false);

  useEffect(() => {
    if (!stripeStatus) {
      checkStatus();
    }
  }, [checkStatus, stripeStatus]);

  useEffect(() => {
    form.setFieldsValue({
      statementDescriptor: existingStatementDescriptor,
    });
  }, [existingStatementDescriptor, form]);

  const submitAccountConfiguration = async ({ statementDescriptor }: AccountConfigurationFormType) => {
    setLoading(true);

    try {
      const response = await upsertStripeTransaction({
        variables: {
          organizationId,
          practiceId,
          setupRecord: {
            payments: {
              payments_statement_descriptor: statementDescriptor,
            },
          },
        },
      });

      if (!response?.data?.upsertStripeTransaction?.success) {
        throw new Error(getStripeErrorMessage(response.data?.upsertStripeTransaction.message));
      }
      showSuccessMessage(translations.shared.saveSuccessMessage);
      refetchSettings();
      setSaveChangesVisible(false);
    } catch (e) {
      showErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  const sendLinkRequest = async (eventuallyDue = false) => {
    setLoading(true);
    try {
      const response = await upsertStripeTransaction({
        variables: {
          organizationId,
          practiceId,
          accountLinkRequestRecord: {
            refresh_url: window.location.href,
            return_url: window.location.href,
            collect: { eventuallyDue },
          },
        },
      });

      setLoading(false);
      if (response.data?.upsertStripeTransaction.url) {
        window.location.replace(response.data.upsertStripeTransaction.url);
      }
    } catch (e) {
      setLoading(false);
      showErrorMessage(e.message);
    }
  };

  const renderIcon = () => {
    switch (stripeStatus) {
      case PaymentStripeAccountStatus.Ready:
        return <ReadyIcon />;
      case PaymentStripeAccountStatus.NotFound:
        return <NotFoundIcon />;
      case PaymentStripeAccountStatus.NotSetup:
        return <NotSetupIcon />;
      default:
        return <></>;
    }
  };

  const renderSetupButton = () => {
    switch (stripeStatus) {
      case PaymentStripeAccountStatus.NotFound:
        return (
          <Button type='primary' onClick={() => navigateTo(routes.stripeRegistration)}>
            {translations.stripeLandingPage.setupButtonText}
          </Button>
        );
      case PaymentStripeAccountStatus.NotSetup:
      case PaymentStripeAccountStatus.Ready:
        return (
          <Button type='primary' onClick={() => sendLinkRequest()}>
            {translations.stripeLandingPage.setupButtonText}
          </Button>
        );
      default:
        return <></>;
    }
  };

  const handleFormValueChange = (values: any) => {
    if (!isEmpty(values.statementDescriptor)) {
      setSaveChangesVisible(true);
    }
  };

  const renderConfigurationSection = () => {
    if (stripeStatus !== PaymentStripeAccountStatus.Ready) {
      return <></>;
    }
    return (
      <SectionWrapper>
        <SectionTitle>{translations.stripeLandingPage.configurationTitle}</SectionTitle>
        <Form
          {...formLayout}
          form={form}
          initialValues={initialAccountConfiguration}
          onFinish={submitAccountConfiguration}
          onValuesChange={handleFormValueChange}
        >
          <MaxLengthFormItem
            name='statementDescriptor'
            label={translations.stripeLandingPage.fields.statementDescriptor}
            rules={[
              getRequiredRule(translations.stripeLandingPage.fields.statementDescriptor),
              getMinLengthRule(5),
              getValidRegexRule(/^[^<>\\'*"]*$/, translations.stripeLandingPage.fields.statementDescriptorPatternError),
              getValidRegexRule(/[a-zA-Z]/, translations.stripeLandingPage.fields.statementDescriptorLetterError),
            ]}
            maxLength={22}
            labelCol={{ span: 3.5 }} // Set label column width
            wrapperCol={{ span: 4 }} // Set wrapper column width
            tooltip={{
              title: translations.stripeLandingPage.fields.statementDescriptorTooltip,
            }}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          {saveChangesVisible && (
            <Form.Item>
              <SaveConfigurationButton type='primary' htmlType='submit'>
                {translations.shared.saveButtonText}
              </SaveConfigurationButton>
            </Form.Item>
          )}
        </Form>
      </SectionWrapper>
    );
  };

  return (
    <SaveSpinner
      isSaving={loading || statusCheckLoading}
      savingMessage={translations.stripeLandingPage.stripeStatusLoading}
    >
      <StyledPageHeaderWithMargin title={translations.stripeLandingPage.title} onBack={navigateBack} />
      <Content>
        <SectionWrapper>
          <div dangerouslySetInnerHTML={{ __html: translations.stripeLandingPage.marketingText }} />
        </SectionWrapper>
        <SectionWrapper>
          <SectionTitle>{translations.stripeLandingPage.supportTitle}</SectionTitle>
          <div dangerouslySetInnerHTML={{ __html: translations.stripeLandingPage.supportDetailsText }} />
        </SectionWrapper>
        {renderConfigurationSection()}
        {eventuallyDue && eventuallyDue.length > 0 && (
          <SectionWrapper>
            <SectionTitle>{translations.stripeLandingPage.eventuallyDue}</SectionTitle>
            <StatusText>{translations.stripeLandingPage.incompleteItemsDescription}</StatusText>
            <InsideList>
              {uniq(
                eventuallyDue.map((due) => {
                  // the function below is used to pull the nested values from the config object
                  let unidentifiedProperty: string;
                  const getValueFromConfig = (path: string, config: any) => {
                    const properties = path.split('.');
                    const value = properties.reduce((prev, curr, index) => {
                      if (index === 0 && !prev?.[curr]) {
                        unidentifiedProperty = `(${properties[0].replace('_', ' ')})`;
                        return stripeRequirementsConfig.individual;
                      }
                      return prev?.[curr];
                    }, config);
                    return value !== undefined ? `${value} ${unidentifiedProperty ?? ''}` : path;
                  };
                  return getValueFromConfig(due, stripeRequirementsConfig);
                })
              ).map((value) => (value ? <li key={value}>{value}</li> : null))}
            </InsideList>
            <Button type='primary' onClick={() => sendLinkRequest(true)}>
              {translations.stripeLandingPage.completeDue}
            </Button>
          </SectionWrapper>
        )}
        {stripeStatus && (
          <LastSectionWrapper>
            <SectionTitle>
              {translations.stripeLandingPage.statusTitle} {renderIcon()}
            </SectionTitle>
            <StatusText>{paymentStripeAccountStatusConfigs[stripeStatus].text}</StatusText>
            {renderSetupButton()}
          </LastSectionWrapper>
        )}
      </Content>
    </SaveSpinner>
  );
};
