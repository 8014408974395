import { gql } from '@apollo/client';
import { BasicInterestRunFields, InterestRunContactFields } from '../fragments/interestRunFragments';
import { InterestRunFields } from '../fragments';

export const UpsertInterestRun = gql`
  mutation upsertInterestRun($organizationId: ID!, $interestRun: InterestRunUpsert!) {
    upsertInterestRun(organizationId: $organizationId, interestRun: $interestRun) {
      ...BasicInterestRunFields
      contact {
        ...InterestRunContactFields
      }
    }
  }
  ${BasicInterestRunFields}
  ${InterestRunContactFields}
`;

export const GetInterestRunsPg = gql`
  query getInterestRunsPg($organizationId: ID!, $filter: InterestRunFilterOptions) {
    getInterestRunsPg(organizationId: $organizationId, filter: $filter) {
      ...InterestRunFields
    }
  }
  ${InterestRunFields}
`;
