import React from 'react';
import { initialState, offlineModeReducer, OfflineModeState } from './reducer';
import { OfflineModeAction } from './actions';

export const OfflineModeContext = React.createContext<{
  state: OfflineModeState;
  dispatch: React.Dispatch<OfflineModeAction>;
}>({ state: initialState } as any);

interface OfflineContextProviderProps {
  state?: Partial<OfflineModeState>;
}

export const OfflineContextProvider: React.FC<React.PropsWithChildren<OfflineContextProviderProps>> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };

  const [state, dispatch] = React.useReducer(offlineModeReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };

  return <OfflineModeContext.Provider value={value}>{props.children}</OfflineModeContext.Provider>;
};
