import { Patient } from '../../../graph/types';

export type PatientsAction = SetPatientsList | SetCurrentlyEditingIndex;

export enum PatientsActionType {
  setPatientsList = 'PATIENTS__SET_PATIENTS_LIST',
  setCurrentlyEditingIndex = 'PATIENTS__SET_EDITING_INDEX',
}

export interface SetPatientsList {
  type: PatientsActionType.setPatientsList;
  patientsList: Patient[];
}

export interface SetCurrentlyEditingIndex {
  type: PatientsActionType.setCurrentlyEditingIndex;
  editingIndex: number;
}

export const setPatientsList = (patientsList: Patient[]): SetPatientsList => ({
  type: PatientsActionType.setPatientsList,
  patientsList,
});

export const setCurrentlyEditingIndex = (editingIndex: number): SetCurrentlyEditingIndex => ({
  type: PatientsActionType.setCurrentlyEditingIndex,
  editingIndex,
});
