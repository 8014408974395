import { Form, Select, Switch, Tooltip } from 'antd';
import React from 'react';
import { TextEditor } from '../../components/TextEditor/TextEditor';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { translations } from '../../constants/translations';
import { w24 } from '../../globalStyles.style';
import { OrganizationReferenceData } from '../../graph/types';
import { getRequiredRule } from '../../util/forms';

export const NoteField = ({
  noteTypes,
  setText,
  text,
  name,
}: {
  noteTypes?: OrganizationReferenceData[];
  setText: (text: string, plaintext: string) => void;
  text: string;
  name: string;
}) => {
  const typesOptions = noteTypes?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <>
      {noteTypes && (
        <Form.Item
          shouldUpdate
          wrapperCol={{ offset: 4, span: 6 }}
          name={'type_id'}
          style={{ minWidth: 150, ...w24 }}
          rules={[getRequiredRule(translations.viewServicePage.fields.type)]}
        >
          <Select
            {...searchableSelectParams}
            options={typesOptions}
            placeholder={translations.viewServicePage.fields.typeDropdownPlaceholder}
            data-testid={'TypeSelect'}
          />
        </Form.Item>
      )}
      <Form.Item name={name} wrapperCol={{ offset: 4, span: 12 }} style={w24}>
        <TextEditor
          textContent={text}
          editMode
          setTextContent={setText}
          placeholder={translations.recordList.textFieldPlaceholder}
        />
      </Form.Item>
    </>
  );
};

interface ServiceNoteProps {
  onDefaultChange: (value: boolean) => void;
  onDefaultPrescriptionChange: (value: boolean) => void;
  noteComponent: JSX.Element;
  prescriptionNoteComponent: JSX.Element;
  isPrescriptionEnabled: boolean;
}

export const ServiceNote: React.FC<ServiceNoteProps> = ({
  noteComponent,
  prescriptionNoteComponent,
  onDefaultChange,
  onDefaultPrescriptionChange,
  isPrescriptionEnabled,
}) => {
  return (
    <>
      <Form.Item name='default' style={w24} label={translations.viewServicePage.fields.default} valuePropName='checked'>
        <Switch onChange={onDefaultChange} />
      </Form.Item>
      {noteComponent}
      <Form.Item
        name='defaultPrescription'
        style={w24}
        label={translations.viewServicePage.fields.defaultPrescriptionInstructions}
        valuePropName='checked'
      >
        {!isPrescriptionEnabled ? (
          <Tooltip title={translations.viewServicePage.prescriptionFieldMessage}>
            <Switch disabled={!isPrescriptionEnabled} onChange={onDefaultPrescriptionChange} />
          </Tooltip>
        ) : (
          <Switch onChange={onDefaultPrescriptionChange} />
        )}
      </Form.Item>

      {prescriptionNoteComponent}
    </>
  );
};
