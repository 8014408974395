import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TitleWithSearchBox } from '../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { translations } from '../../../constants/translations';
import { OrganizationList } from '../../../graph/types';
import { useGetOrganizationsList, useOrganizationsSearch } from '../../../hooks/ajax/organization/organizationHooks';
import { shouldShowTooManyResultsWarning } from '../../../hooks/setWarningIfTooManyResults';
import { TableKey, useTableColumnDisplayFilter, useTableResetFilterAndSort } from '../../../hooks/tableHooks';
import { useOrganizationSubscriptionColumns } from './organizationSubscriptionColumns';
import { organizationSubscriptionPropertyNames } from '../../../constants/propertyNames';
import ColumnDisplayFilter from '../../../components/ColumnDisplayFilter/ColumnDisplayFilter';

export const AdminSubscription: React.FC = () => {
  const { organizations, organizationsLoading } = useGetOrganizationsList();
  const [subscriptionModalOptions, setSubscriptionModalOptions] = useState<{ show: boolean; organizationId?: string }>({
    show: false,
  });
  const { organizations: filteredOrganizations, searchOrganization } = useOrganizationsSearch(organizations ?? []);

  const { filteredValue, sortOrderMap, tableChangeHandler, resetFiltersAndSort } = useTableResetFilterAndSort();

  const handleModalClose = () => setSubscriptionModalOptions({ show: false });
  const handleModalOpen = (organizationId: string) => setSubscriptionModalOptions({ show: true, organizationId });
  const organizationSubscriptionColumns = useOrganizationSubscriptionColumns();

  const optionalColumns: CustomColumnType<OrganizationList>[] = useMemo(
    () => [
      {
        ...organizationSubscriptionColumns.start_date,
        filteredValue: filteredValue[organizationSubscriptionPropertyNames.start_date] ?? null,
        sortOrder: sortOrderMap[organizationSubscriptionPropertyNames.start_date],
        width: 100,
      },
      {
        ...organizationSubscriptionColumns.next_bill_date,
        filteredValue: filteredValue[organizationSubscriptionPropertyNames.next_bill_date] ?? null,
        sortOrder: sortOrderMap[organizationSubscriptionPropertyNames.next_bill_date],
        width: 100,
      },
      {
        ...organizationSubscriptionColumns.last_billed_date,
        filteredValue: filteredValue[organizationSubscriptionPropertyNames.last_billed_date] ?? null,
        sortOrder: sortOrderMap[organizationSubscriptionPropertyNames.last_billed_date],
        width: 100,
      },
      {
        ...organizationSubscriptionColumns.frequency,
        filteredValue: filteredValue[organizationSubscriptionPropertyNames.frequency] ?? null,
        sortOrder: sortOrderMap[organizationSubscriptionPropertyNames.frequency],
        width: 100,
      },
    ],
    [filteredValue, sortOrderMap, organizationSubscriptionColumns]
  );

  const {
    displayedColumns,
    displayedColumnKeys,
    columnKeyAndTitleList,
    setDisplayedColumnKeys,
    resetDisplayedColumnsToDefault,
  } = useTableColumnDisplayFilter(TableKey.OrganizationSubscription, optionalColumns);

  const columns: CustomColumnType<OrganizationList>[] = [
    {
      ...organizationSubscriptionColumns.id,
      filteredValue: filteredValue.id ?? null,
      sortOrder: sortOrderMap.id,
      width: 100,
    },
    {
      ...organizationSubscriptionColumns.organization,
      filteredValue: filteredValue.organization ?? null,
      sortOrder: sortOrderMap.organization,
      render: (name, record) =>
        record.failed === false ? (
          <TableCellLink onClick={() => handleModalOpen(record.id)}>{name}</TableCellLink>
        ) : (
          name
        ),
      width: 100,
    },
    ...displayedColumns,
    {
      ...organizationSubscriptionColumns.action,
      render: (record) =>
        record.failed === false ? (
          <Button onClick={() => handleModalOpen(record.id)}>{translations.shared.editButtonText}</Button>
        ) : null,
      width: 100,
    },
  ];

  const handleOnClear = () => {
    searchOrganization('');
    resetFiltersAndSort();
    resetDisplayedColumnsToDefault();
  };

  return (
    <>
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.adminSubscription.title}
            loading={false}
            searchBoxPlaceholder={translations.adminSubscription.searchPlaceholder}
            onSearchValueChange={(e) => searchOrganization(e.target.value)}
            onClear={handleOnClear}
            showTooManyResultsWarning={shouldShowTooManyResultsWarning(filteredOrganizations)}
          />
        }
        extra={[
          <ColumnDisplayFilter
            key={'columnDisplayFilter'}
            initiallyDisplayedColumns={displayedColumnKeys}
            setDisplayedColumns={setDisplayedColumnKeys}
            columnKeyAndTitleList={columnKeyAndTitleList}
            resetDisplayedColumnsToDefault={resetDisplayedColumnsToDefault}
          />,
        ]}
      />
      <TableWithCustomFiltering
        tableKey={TableKey.OrganizationSubscription}
        dataSource={filteredOrganizations}
        loading={organizationsLoading}
        columns={columns}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
      {subscriptionModalOptions.show && (
        <SubscriptionUpdateModal onClose={handleModalClose} onSuccess={handleModalClose} />
      )}
    </>
  );
};
