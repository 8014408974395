import { useMemo } from 'react';
import { useGetReferenceData } from './ajax/referenceData/referenceDataHooks';
import { useGetOrganizationIdFromRoute } from './route/routeParameterHooks';
import { PrescriptionLabelNameKeys, prescriptionLabelNameKeyConfig } from '../constants/prescriptions';

export const usePrescriptionLabelDefaultOptions = (skip?: boolean) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { referenceData, dataLoading } = useGetReferenceData(organizationId, skip);

  const prescriptionLabelDefaultOptions = useMemo(() => {
    return referenceData?.ref_label?.map((label) => {
      return {
        value: label.id,
        label: prescriptionLabelNameKeyConfig[label.name_key as PrescriptionLabelNameKeys].label,
      };
    });
  }, [referenceData?.ref_label]);

  return {
    prescriptionLabelDefaultOptions,
    prescriptionLabelDefaultsLoading: dataLoading,
    prescriptionLabelDefaults: referenceData?.ref_label,
  };
};
