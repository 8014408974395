import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Contact } from '../../../../graph/types';
import { Records } from '../../../../components/Records/Records';
import { RecordItem } from '../../../../components/Records/recordUtils';
import { getRecordFromNote } from '../../../../components/RecordSingleView/Notes/noteUtils';
import { getRecordFromFile } from '../../../../util/fileUtils';
import { OwnerType, useGetRecords } from '../../../../hooks/ajax/recordHooks';
import { useContactSubscription } from '../../../../hooks/contactSubscriptionHook';
import { RecordsContext } from '../../../../components/Records/store/state';
import { selectHasUnsavedChanges, selectSelectedRecordId } from '../../../../components/Records/store/selectors';
import { SaveSpinner } from '../../../../components/SaveSpinner/SaveSpinner';

export interface ContactRecordsProps extends PropsWithChildren<unknown> {
  contact: Contact;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const ContactRecords: React.FC<ContactRecordsProps> = ({
  contact,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}) => {
  const { notes, files, loading, refetch } = useGetRecords({ id: contact.id }, OwnerType.Contact);
  const { state } = useContext(RecordsContext);
  const hasUnsavedChanges = selectHasUnsavedChanges(state);

  useContactSubscription(contact.id, refetch);

  const recordId = selectSelectedRecordId(state);

  useEffect(() => {
    if (recordId) {
      refetch();
    }
  }, [recordId, refetch]);

  useEffect(() => {
    if (handleIsEditing) {
      handleIsEditing(hasUnsavedChanges);
    }
  }, [handleIsEditing, hasUnsavedChanges]);

  const records = useMemo(() => {
    const records: RecordItem[] = [];
    records.push(...notes.map((note) => getRecordFromNote(note)));
    records.push(...files.map((file) => getRecordFromFile(file)));
    return records;
  }, [notes, files]);

  return (
    <SaveSpinner isSaving={loading}>
      <Records
        records={records}
        contact={contact}
        setShouldResetOnTabChange={setShouldResetOnTabChange}
        shouldResetOnTabChange={shouldResetOnTabChange}
      />
    </SaveSpinner>
  );
};
