import React, { useMemo, useState, useCallback } from 'react';
import { EMRConfigurationModal } from '../../../components/EMRConfigurationModal/EMRConfigurationModal';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { PracticeSettingsId, translations } from '../../../constants/translations';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import {
  Organization,
  OrganizationSubscriptionResult,
  OrganizationUpsert,
  Setting,
  SettingDto,
} from '../../../graph/types';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useGetOrganization, useUpdateOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { getSubscriptionUpsertErrorMessage } from '../../SubscriptionSettings/subscriptionErrorUtil';
import { AddOnCardEMR } from './AddOnCardEMR';
import { useForm } from 'antd/lib/form/Form';
import { getSettingUpsert, useUpsertSetting } from '../../../hooks/ajax/setting/settingHooks';
import { setPracticeSetting } from '../../../contexts/organization/action';
import { defaultPractice } from '../../../contexts/organization/selectors';
import { useOrganizationContext } from '../../../contexts/organization/state';

export enum OrganizationContacType {
  EMR = '8',
}
export interface AddOnCardEMRContainerProps {
  organizationId: string;
  organization?: Organization;
  refetchOrganization: () => void;
}

export const AddOnCardEMRContainer: React.FC<AddOnCardEMRContainerProps> = ({
  organizationId,
  organization,
  refetchOrganization,
}: AddOnCardEMRContainerProps) => {
  const [updateSubscription] = useUpdateSubscription(organizationId);
  const [tempSubscription, setTempSubscription] = useState<OrganizationSubscriptionResult>();
  const [showConfigurationModal, setShowConfigurationModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { organization: organizationContactTypes } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.ContactTypes
  );
  const { dispatch: orgContextDispatch, state } = useOrganizationContext();

  const practice = defaultPractice(state);
  const handleUpdateContext = (newSetting?: Setting) => {
    if (practice && newSetting) {
      orgContextDispatch(setPracticeSetting(practice.id, { ...newSetting, __typename: 'SettingDTO' } as SettingDto));
    }
  };

  const [upsertSetting] = useUpsertSetting(handleUpdateContext);

  const [upsertOrganization] = useMutationWithMessages(useUpdateOrganization);

  const [form] = useForm();

  const organizationContactTypeIds = useMemo(
    () => organizationContactTypes?.ref_contact?.type.map((x) => ({ child_id: x.type_id })),
    [organizationContactTypes]
  );

  const emrAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id
      ),
    [organization]
  );

  const addOnEnabled = useMemo(() => (emrAddon?.enabled ? true : false), [emrAddon]);

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  const activateEMRSubscription = useCallback(async () => {
    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: true,
          detail: [
            {
              level_id: organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id,
            },
          ],
        },
      },
    });

    await upsertSetting({
      variables: {
        organizationId,
        setting: getSettingUpsert(
          JSON.stringify({ home_region_id: Number(form.getFieldValue('defaultRegion')) }),
          PracticeSettingsId.EMRHomeRegion,
          undefined,
          undefined
        ),
      },
    });

    const upsertIds = organizationContactTypeIds?.concat({ child_id: OrganizationContacType.EMR });

    const upsert = {
      id: organizationId,
      contact_type: upsertIds,
    } as OrganizationUpsert;

    await upsertOrganization({
      options: {
        variables: { organizationId, organization: upsert },
      },
    });

    setIsSaving(false);
    return data;
  }, [organizationId, updateSubscription, upsertOrganization, organizationContactTypeIds, form, upsertSetting]);

  const getFutureChargesWithEmr = async () => {
    const { data } = await updateSubscription({
      variables: {
        organizationId,
        subscription: {
          fnProcess: false,
          detail: [
            {
              level_id: organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id,
            },
          ],
        },
      },
    });
    return data?.updateOrganizationSubscription;
  };

  const handleOpenConfigurationModal = async () => {
    setIsSaving(true);
    if (!addOnEnabled) {
      const subscriptionData = await getFutureChargesWithEmr();
      setTempSubscription(subscriptionData);
    }
    setIsSaving(false);
    setShowConfigurationModal(true);
  };

  const handleCloseConfigurationModal = () => {
    form.resetFields();
    setShowConfigurationModal(false);
  };

  const handleSaveConfiguration = async () => {
    setIsSaving(true);
    const data = await activateEMRSubscription();
    if (data) {
      const errorMessage = getSubscriptionUpsertErrorMessage(data!.updateOrganizationSubscription);
      if (errorMessage) {
        showErrorMessage(errorMessage);
      } else {
        setShowConfigurationModal(false);
        refetchOrganization();
        showSuccessMessage(translations.addOns.cards.emr.successMessage);
      }
    }
  };

  return (
    <SaveSpinner isSaving={isSaving} savingMessage={translations.loadingComponent.loading}>
      <AddOnCardEMR
        isAddOnEnabled={addOnEnabled}
        price={emrAddon?.cost}
        onClick={handleOpenConfigurationModal}
        countryId={countryId}
      />
      {showConfigurationModal && (
        <EMRConfigurationModal
          addOnEnabled={addOnEnabled}
          form={form}
          subscription={tempSubscription}
          onSave={handleSaveConfiguration}
          onClose={handleCloseConfigurationModal}
        />
      )}
    </SaveSpinner>
  );
};
