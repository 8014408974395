import React from 'react';
import { PhoneTableProperties, phoneTablePropertyNames } from './phoneTableContants';
import { CustomColumnType } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { translations } from '../../constants/translations';
import { generalFilteringAndSortingSettings } from '../../util/filterAndSorting';
import { Phone } from '../../graph/types';
import PhoneTableActionsCells from './components/PhoneTableActionsCells';
import PhoneTableTextCell from './components/PhoneTableTextCell';
import PhoneTablePrimaryCell from './components/PhoneTablePrimaryCell';
import PhoneTableTypeCell from './components/PhoneTableTypeCell';

const { phoneTable: t } = translations;

export default {
  primary: {
    title: t.columns.primary,
    key: phoneTablePropertyNames.primary,
    dataIndex: phoneTablePropertyNames.primary,
    render: (_, phone) => <PhoneTablePrimaryCell phone={phone} />,
  },
  phone_type_name: {
    title: t.columns.phone_type_name,
    key: phoneTablePropertyNames.phone_type_name,
    dataIndex: phoneTablePropertyNames.phone_type_name,
    render: (_, phone) => <PhoneTableTypeCell phone={phone} />,
  },
  description: {
    title: t.columns.description,
    key: phoneTablePropertyNames.description,
    dataIndex: phoneTablePropertyNames.description,
    ...generalFilteringAndSortingSettings,
    render: (_, phone) => <PhoneTableTextCell phone={phone} property={phoneTablePropertyNames.description} />,
  },
  phone: {
    title: t.columns.phone,
    key: phoneTablePropertyNames.phone,
    dataIndex: phoneTablePropertyNames.phone,
    ...generalFilteringAndSortingSettings,
    render: (_, phone) => <PhoneTableTextCell phone={phone} property={phoneTablePropertyNames.phone} />,
  },
  extension: {
    title: t.columns.extension,
    key: phoneTablePropertyNames.extension,
    dataIndex: phoneTablePropertyNames.extension,
    ...generalFilteringAndSortingSettings,
    render: (_, phone) => <PhoneTableTextCell phone={phone} property={phoneTablePropertyNames.extension} />,
  },
  actions: {
    title: t.columns.actions,
    key: phoneTablePropertyNames.actions,
    dataIndex: phoneTablePropertyNames.actions,
    render: (_, record) => <PhoneTableActionsCells phone={record} />,
  },
} as Record<PhoneTableProperties, CustomColumnType<Phone>>;
