import { Divider, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { getRequiredRule } from '../../util/forms';
import { FormInstance } from 'antd/lib';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { EMRRegionNameKeyTranslationMap } from './emrUtil';
import { searchableSelectParams } from '../../constants/searchableSelectParams';

export interface EMRRegionModalProps {
  onSave: () => void;
  onClose: () => void;
  form: FormInstance;
  defaultRegionValue?: string;
}

export const EMRRegionModal: React.FC<EMRRegionModalProps> = ({
  onSave,
  form,
  onClose,
  defaultRegionValue,
}: EMRRegionModalProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [defaultRegionSelected, setDefaultRegionSelected] = useState<string>();
  const [regionOptions, setRegionOptions] = useState<{ label: string; value: string }[]>();

  const organizationId = useGetOrganizationIdFromRoute();
  const { referenceData, dataLoading } = useGetReferenceData(organizationId);

  useEffect(() => {
    if (referenceData) {
      setRegionOptions(
        referenceData?.ref_3p_region?.map((r) => ({
          label: EMRRegionNameKeyTranslationMap[r.name_key],
          value: r.id,
        }))
      );
    }
  }, [referenceData]);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await onSave();
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <ModalWithCloseConfirm
      title={translations.emrRegionModal.title}
      open
      onCancel={onClose}
      onOk={handleSave}
      okText={translations.shared.saveButtonText}
      okButtonProps={{ disabled: (!defaultRegionValue && !defaultRegionSelected) || isSaving }}
      isSaving={dataLoading || isSaving}
      savingMessage={dataLoading ? translations.shared.loading : translations.loadingComponent.saving}
      width={500}
    >
      <span data-testid='emrEnableInstructionsTestId'>{translations.emrRegionModal.instructions}</span>
      <Divider />
      <Form form={form}>
        <Form.Item
          required
          requiredMark
          name={'defaultRegion'}
          label={translations.emrRegionModal.defaultRegionLabel}
          rules={!defaultRegionValue ? [getRequiredRule(translations.emrRegionModal.defaultRegionLabel)] : []}
        >
          <Select
            {...searchableSelectParams}
            onSelect={setDefaultRegionSelected}
            defaultValue={String(defaultRegionValue)}
            placeholder={translations.emrRegionModal.selectPlaceholder}
            data-testid='emrRegionModalSelectTestId'
          >
            {regionOptions?.map((o) => (
              <Select.Option key={o.value} label={o.label} value={o.value}>
                <span>{o.label}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </ModalWithCloseConfirm>
  );
};
