import React, { useMemo, useState } from 'react';
import { SignInContainer } from './SignInContainer';
import { Alert, Button } from 'antd';
import { NewPasswordFormItems } from '../../components/NewPasswordFormItems/NewPasswordFormItems';
import { Dictionary } from 'lodash';
import { useGetSetNewPasswordDataFromRoute } from '../../hooks/route/routeParameterHooks';
import { Auth } from 'aws-amplify';
import { translations } from '../../constants/translations';
import { routes } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { showSuccessMessage } from '../../components/Notification/notificationUtil';
import { FormWrapper } from './SetNewPasswordPageStyle';

function useDecodeUsername(encodedUsername: string) {
  return useMemo(() => atob(encodedUsername), [encodedUsername]);
}

export const SetNewPasswordPage: React.FC = () => {
  const history = useHistory();
  const { encodedUsername, code } = useGetSetNewPasswordDataFromRoute();
  const username = useDecodeUsername(encodedUsername);
  const [error, setError] = useState<string | null>(null);

  const handleOnFinish = async (values: Dictionary<string>) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, values.newPassword);
      showSuccessMessage(translations.shared.saveSuccessMessage);
      history.push(routes.login);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <SignInContainer>
      <FormWrapper labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} onFinish={handleOnFinish}>
        {error && <Alert message={error} type='error' showIcon style={{ marginBottom: '1rem' }} />}
        <NewPasswordFormItems />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button htmlType={'submit'}>{translations.shared.submit}</Button>
        </div>
      </FormWrapper>
    </SignInContainer>
  );
};
