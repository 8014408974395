import dayjs from 'dayjs';
import { CustomColumnType } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { OfflineUpsertResponseProperty, offlineUpsertResponsePropertyNames } from '../../constants/propertyNames';
import { translations, UpsertTypeNameKey, upsertTypeNameTranslation } from '../../constants/translations';
import { OfflineUpsertResponse } from '../../graph/types';
import {
  filterFunction,
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';

export const getEntityString = ({ type_name, instruction }: OfflineUpsertResponse) => {
  const entity = upsertTypeNameTranslation(type_name as UpsertTypeNameKey).log;
  const entityName = JSON.parse(instruction).record?.name;
  return `${entity} ${entityName ?? ''}`;
};

export const date30DaysAgo = (dateFormat: string) => dayjs().subtract(30, 'days').format(dateFormat);

export const basicSyncErrorColumns: Record<OfflineUpsertResponseProperty, CustomColumnType<OfflineUpsertResponse>> = {
  type_name: {
    title: translations.syncErrorPage.columns.type,
    key: offlineUpsertResponsePropertyNames.type_name,
    dataIndex: offlineUpsertResponsePropertyNames.type_name,
    ...generalFilteringAndSortingSettings,
    onFilter: getOnFilterFunctionFor(offlineUpsertResponsePropertyNames.type_name),
    sorter: getStringCompareFunctionFor(offlineUpsertResponsePropertyNames.type_name),
  },
  error_message: {
    title: translations.syncErrorPage.columns.actions,
    dataIndex: offlineUpsertResponsePropertyNames.error_message,
    key: offlineUpsertResponsePropertyNames.error_message,
  },
  created: {
    title: translations.syncErrorPage.columns.date,
    key: offlineUpsertResponsePropertyNames.created,
    dataIndex: offlineUpsertResponsePropertyNames.created,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.syncErrorPage.columns.date),
    onFilter: getOnFilterFunctionFor(offlineUpsertResponsePropertyNames.created),
    sorter: getDateCompareFunctionFor(offlineUpsertResponsePropertyNames.created),
  },
  instruction: {
    title: translations.syncErrorPage.columns.log,
    key: offlineUpsertResponsePropertyNames.instruction,
    dataIndex: offlineUpsertResponsePropertyNames.instruction,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.syncErrorPage.columns.log),
    onFilter: (value, record) => {
      const entityString = getEntityString(record);
      return filterFunction(value, entityString);
    },
    sorter: getStringCompareFunctionFor(offlineUpsertResponsePropertyNames.instruction),
  },
};
