import { RelatedContactEntryCurrent } from '../../../../../../graph/types';

export type Action = AddRelationship | EditRelationship | CloseRelationshipModal;

export const addRelationshipType = 'RELATIONSHIP__ADD_RELATIONSHIP';
export const editRelationshipType = 'RELATIONSHIP__EDIT_RELATIONSHIP';
export const closeRelationshipModalType = 'RELATIONSHIP__CLOSE_MODAL';

export interface AddRelationship {
  type: typeof addRelationshipType;
  contactTypeId: string;
}
export interface EditRelationship {
  type: typeof editRelationshipType;
  contactTypeId: string;
  previousRelationship: RelatedContactEntryCurrent;
}
export interface CloseRelationshipModal {
  type: typeof closeRelationshipModalType;
}

export const addRelationshipAction = (contactTypeId: string): AddRelationship => ({
  type: addRelationshipType,
  contactTypeId,
});

export const editRelationshipAction = (
  contactTypeId: string,
  previousRelationship: RelatedContactEntryCurrent
): EditRelationship => ({
  type: editRelationshipType,
  contactTypeId,
  previousRelationship,
});

export const closeRelationshipModalAction = (): CloseRelationshipModal => ({
  type: closeRelationshipModalType,
});
