import { ServiceBundleProperty, serviceBundlePropertyNames } from '../../../constants/propertyNames';
import { ColumnType } from 'antd/lib/table';
import { translations } from '../../../constants/translations';
import { BundleServiceType } from './ServiceBundleTab';

export const serviceBundleListColumns: Record<ServiceBundleProperty, ColumnType<BundleServiceType>> = {
  name: {
    title: translations.renderedServicesTable.columns.description,
    key: serviceBundlePropertyNames.name,
    dataIndex: serviceBundlePropertyNames.name,
  },
  unit_name: {
    title: translations.renderedServicesTable.columns.unit,
    key: serviceBundlePropertyNames.unit_name,
    dataIndex: serviceBundlePropertyNames.unit_name,
  },
  price: {
    title: translations.renderedServicesTable.columns.price,
    key: serviceBundlePropertyNames.price,
    dataIndex: serviceBundlePropertyNames.price,
  },
};
