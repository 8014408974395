import React from 'react';
import ReactDOM from 'react-dom';
import { translations } from '../constants/translations';
import AlertModal from '../components/AlertModal/AlertModal';

export const openTabWithPopupDetection = (url: string, alertMessage = translations.popupDetectionMessage.general) => {
  const popupWindow = window.open(url, '_blank');
  if (!popupWindow) {
    ReactDOM.render(
      <AlertModal
        message={alertMessage}
        okText={translations.popupAlert.retry}
        onOk={() => openTabWithPopupDetection(url, alertMessage)}
      />,
      document.getElementById('alert-container')
    );
  }
};
