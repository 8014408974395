import React from 'react';
import { ComponentWithPracticeProps } from '../../components/WithPractice/WithPractice';
import { Loading } from '../../components/Loading/Loading';
import { RegistrationStripe } from '../Registration/RegistrationStripe/RegistrationStripe';
import { PageHeader } from '@ant-design/pro-layout';
import { translations } from '../../constants/translations';
import styled from 'styled-components';
import { useOrganizationContext } from '../../contexts/organization/state';
import { PracticeDto } from '../../graph/types';

const StripeRegistrationFormWrapper = styled.div`
  min-width: 600px;
  max-width: 700px;
  margin-left: 20px;
`;

const StripeRegistration: React.FC<ComponentWithPracticeProps> = () => {
  const {
    state: { organization },
  } = useOrganizationContext();

  const practice = organization?.practice.find((p) => p.id === organization.default_practice_id);

  if (!organization) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title={translations.stripeRegistrationPage.title} />
      <StripeRegistrationFormWrapper>
        <RegistrationStripe<PracticeDto> organizationId={organization.id} practice={practice} />
      </StripeRegistrationFormWrapper>
    </>
  );
};

export default StripeRegistration;
