import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { PatientsOverview } from './PatientsOverview/PatientsOverview';
import { AddPatient } from './AddPatient/AddPatient';
import { routes } from '../../constants/routes';
import { ViewPatient } from './ViewPatient/ViewPatient';
import { WithPracticeDto } from '../../components/WithPractice/WithPractice';

export const Patients: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={routes.addPatient}>
          <WithPracticeDto Component={AddPatient} />
        </Route>
        <Route path={routes.viewPatient} component={ViewPatient} />
        <Route path={`${match.path}`} component={PatientsOverview} />
      </Switch>
    </>
  );
};
