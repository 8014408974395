import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetUserOrganizationWithTypes, useGetUserWithPermissions } from '../../../hooks/ajax/user/userHooks';
import { Loading } from '../../../components/Loading/Loading';
import { UserDetails } from '../UserDetails/UserDetails';
import { translations } from '../../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { WidthRestrictor } from '../../../components/Restrictors/WidthRestrictor';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import TabWrapper, { TabWrapperProps } from '../../../components/TabWrapper/TabWrapper';
import { UserPermissions } from '../UserPermissions/UserPermissions';
import { PageHeader } from '@ant-design/pro-layout';
import { useIsUserOrgOwner, useUserPermissions } from '../../../hooks/permissionsHooks';

interface ParamTypes {
  userId: string;
}

export const ViewUser: React.FC = () => {
  const history = useHistory();
  const [tabKey, setTabKey] = useState<string>('0');
  const { userId } = useParams<ParamTypes>();
  const organizationId = useGetOrganizationIdFromRoute();
  const { user, userLoading } = useGetUserWithPermissions(organizationId, userId);
  const { userOrganization: userOrganizations, userOrganizationLoading } = useGetUserOrganizationWithTypes(
    organizationId,
    user?.id ?? ''
  );
  const { isUserOrgOwner: isSelectedUserOrgOwner } = useIsUserOrgOwner(user);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  const { isOrgAdmin, isOrgOwner, isGlobalAdmin } = useUserPermissions();

  if (userLoading || userOrganizationLoading) {
    return <Loading />;
  }

  if (!user) {
    return <p>{translations.viewUserPage.missingUser}</p>;
  }

  const onBack = () => {
    history.goBack();
  };

  const tabContents: TabWrapperProps['tabContents'] = [
    {
      title: translations.userSettings.tabs.generalDetails,
      content: (
        <UserDetails
          user={user}
          userOrganizations={userOrganizations ?? undefined}
          setIsSaving={setIsSaving}
          isSaving={isSaving}
          hasUnsavedData={hasUnsavedData}
          setHasUnsavedData={setHasUnsavedData}
        />
      ),
    },
    ...((!isSelectedUserOrgOwner && (isOrgAdmin || isOrgOwner)) || isGlobalAdmin
      ? [
          {
            title: translations.userSettings.tabs.permissions.title,
            content: <UserPermissions user={user} setIsSaving={setIsSaving} />,
          },
        ]
      : []),
  ];

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasUnsavedData}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.viewUserPage.createdEntity)}
    >
      <WidthRestrictor>
        <PageHeader onBack={onBack} title={`${user.name}`} />
        <TabWrapper tabContents={tabContents} activeKey={tabKey} onTabClick={setTabKey} />
      </WidthRestrictor>
    </SaveSpinnerAndNavigationWarning>
  );
};
