import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { displayAsDate } from '../../constants/formats';
import { translations } from '../../constants/translations';
import { OrganizationSubscriptionResult } from '../../graph/types';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { getCurrencyFromSubscriptionBillingAddress } from '../../pages/SubscriptionSettings/subscriptionSettingsUtil';
import { getSubTotal } from '../SubscriptionUpdateModal/SubscriptionUpdateModal';
import SubscriptionCurrencyFormatter from '../CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

const SubscriptionInfoContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  border-top: 0.1rem solid black;
`;

const MonthlyCharges = styled.div`
  flex-grow: 1;
`;

const ProratedCharges = styled.div`
  flex-grow: 1;
`;

const { Title } = Typography;

export const INPUT_DATA_TEST_ID = 'inputDataTestId';

export interface SubscriptionChargesInfoProps {
  subscription?: OrganizationSubscriptionResult;
}

export const SubscriptionChargesInfo: React.FC<SubscriptionChargesInfoProps> = ({
  subscription,
}: SubscriptionChargesInfoProps) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const nextBillingDate = subscription?.organization?.subscription?.next_bill_date;
  const countryId = subscription?.organization?.subscription?.billing_address?.country_id;

  return (
    <SubscriptionInfoContainer>
      <MonthlyCharges>
        <Title level={5}>{translations.subscriptionUpdateModal.nextBillCycleText}:</Title>
        {(subscription?.monthlyTax?.length ?? 0) > 0 && (
          <div>
            {translations.subscriptionSettings.currentBillingCycleEstimate.subTotal}:{' '}
            <SubscriptionCurrencyFormatter
              total={Number(getSubTotal(subscription?.monthlyTax, subscription?.monthlyTotal))}
              countryId={countryId}
            />
          </div>
        )}
        {subscription?.monthlyTax?.map((tax) => (
          <div key={tax.name_key}>
            {translations.subscriptionSettings.currentBillingCycleEstimate.taxTotal} {tax.name_key}:{' '}
            <SubscriptionCurrencyFormatter total={Number(tax.amount)} countryId={countryId} />
          </div>
        ))}
        <Title level={4}>
          {getCurrencyFromSubscriptionBillingAddress(
            subscription?.organization?.subscription?.billing_address?.country_id
          )}{' '}
          <SubscriptionCurrencyFormatter total={Number(subscription?.monthlyTotal ?? 0)} countryId={countryId} />
        </Title>
        <Title level={5} style={{ marginTop: '0.5rem' }}>
          {translations.subscriptionUpdateModal.nextChargeText}:{' '}
          {nextBillingDate && displayAsDate(nextBillingDate, dateFormat)}
        </Title>
      </MonthlyCharges>
      <ProratedCharges>
        <Title level={5}>{translations.subscriptionUpdateModal.proratedChargeTodayText}:</Title>
        {Number(subscription?.cardCharge ?? 0) > 0 && (subscription?.cardChargeTax?.length ?? 0) > 0 && (
          <>
            <div>
              {translations.subscriptionSettings.currentBillingCycleEstimate.subTotal}:{' '}
              <SubscriptionCurrencyFormatter
                total={Number(getSubTotal(subscription?.cardChargeTax, subscription?.cardCharge))}
                countryId={countryId}
              />
            </div>
            {subscription?.cardChargeTax?.map((tax) => (
              <div key={tax.name_key}>
                {translations.subscriptionSettings.currentBillingCycleEstimate.taxTotal} {tax.name_key}:{' '}
                <SubscriptionCurrencyFormatter total={Number(tax.amount)} countryId={countryId} />
              </div>
            ))}
          </>
        )}
        <Title level={4}>
          {getCurrencyFromSubscriptionBillingAddress(
            subscription?.organization?.subscription?.billing_address?.country_id
          )}{' '}
          <SubscriptionCurrencyFormatter total={Number(subscription?.cardCharge ?? 0)} countryId={countryId} />
        </Title>
      </ProratedCharges>
    </SubscriptionInfoContainer>
  );
};
