import React, { useState } from 'react';
import { List, Popover } from 'antd';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { Loading } from '../Loading/Loading';
import { useGetServiceRenderedWithInfoAndText } from '../../hooks/ajax/service/serviceHooks';
import ClickableIcon, { IconType } from '../Icons/ClickableIcon';
import { ServiceRendered } from '../../graph/types';
import styled from 'styled-components';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';
import { HisaPopoverContentProps, useHisaPopoverContent } from './useHisaPopoverContent';
import { useOffline } from '../../util/offline/offlineUtil';

interface PopoverWithHisaInfoProps {
  serviceRenderedId: string;
  isEditable?: boolean;
  onClick: (serviceRendered?: ServiceRendered) => void;
  unsavedServiceRendered?: ServiceRendered;
}
export const hisaRequirementsIconTestId = 'hisaRequirementsIconTestId';

const ListItem = styled(List.Item)`
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
`;

const PopoverContent = ({ content, contentLoading }: HisaPopoverContentProps) =>
  contentLoading ? (
    <Loading />
  ) : (
    <List>
      {content.map((hisaValue) => (
        <ListItem key={hisaValue.keyAndTestId} data-testid={hisaValue.keyAndTestId}>
          <b>{hisaValue.label}</b>: {hisaValue.value}
        </ListItem>
      ))}
    </List>
  );

export const PopoverWithHisaInfo: React.FC<PopoverWithHisaInfoProps> = ({
  serviceRenderedId,
  isEditable,
  onClick,
  unsavedServiceRendered,
}) => {
  const [id, setId] = useState('');

  const { isOnline } = useOffline();

  const organizationId = useGetOrganizationIdFromRoute();
  const { data, loading: serviceRenderedLoading } = useGetServiceRenderedWithInfoAndText(organizationId, id);
  const { referenceData, dataLoading: referenceDataLoading } = useGetReferenceData(organizationId);

  const loading = serviceRenderedLoading || referenceDataLoading;

  const currentServiceRendered = isOnline ? data ?? unsavedServiceRendered : unsavedServiceRendered;
  return (
    <Popover
      onOpenChange={() => setId(serviceRenderedId)}
      content={
        <PopoverContent
          {...useHisaPopoverContent(
            loading,
            currentServiceRendered,
            referenceData?.ref_service_rendered_info_type_data
          )}
        />
      }
    >
      <ClickableIcon
        iconType={IconType.HisaRequirements}
        data-testid={hisaRequirementsIconTestId}
        onClick={() => isEditable && onClick(currentServiceRendered)}
      />
    </Popover>
  );
};
