import { Maybe, RxScalars, RxSchemaTypeWithUpsert } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';
import { Prescription, PrescriptionUpsert } from '../../../graph/types';

export type RxPrescription = RxSchemaTypeWithUpsert<Prescription, PrescriptionUpsert, 'prescription'>;

export const prescriptionSchema: RxJsonSchema<RxPrescription> = {
  version: 5,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: RxScalars.PrimaryID,
    organization_id: RxScalars.ID,
    practice_id: RxScalars.ID,
    patient_id: Maybe(RxScalars.ID),
    contact_id: Maybe(RxScalars.ID),
    service_id: Maybe(RxScalars.ID),
    number: Maybe(RxScalars.String),
    refills: Maybe(RxScalars.Int),
    instructions: Maybe(RxScalars.String),
    item_quantity: RxScalars.String,
    item_description: Maybe(RxScalars.String),
    item_unit: Maybe(RxScalars.String),
    fill_externally: RxScalars.Boolean,
    created_type_id: RxScalars.Int,
    prescription_expiry_date: RxScalars.String,
    product_expiry_date: Maybe(RxScalars.String),
    prescribed_date: RxScalars.String,
    prescribed_user_id: RxScalars.ID,
    prescribed_user_name: Maybe(RxScalars.String),
    updated: RxScalars.String,
    hidden: RxScalars.Boolean,
    patient_name: Maybe(RxScalars.String),
    patient_number: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    contact_number: Maybe(RxScalars.String),
    offline_id: Maybe(RxScalars.ID),
    created: RxScalars.String,
    created_user_id: RxScalars.String,
    offline_patient_id: Maybe(RxScalars.ID),
    offline_contact_id: Maybe(RxScalars.ID),
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
    is_filled: RxScalars.Boolean,
    available_fills: RxScalars.Int,
    filled_fills: RxScalars.Int,
    remaining_fills: RxScalars.Int,
    invoice_id: Maybe(RxScalars.String),
    updated_user_id: RxScalars.String,
  },
  required: ['id'],
  indexes: ['updated', 'prescribed_date'],
};
