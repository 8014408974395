import React from 'react';
import styled from 'styled-components';
import { translations } from '../../constants/translations';

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessageElement = styled.p`
  color: red;
`;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <ErrorMessageElement data-testid='error'>{message ?? translations.shared.generalErrorMessage}</ErrorMessageElement>
  );
};
