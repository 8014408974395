import { print } from 'graphql';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { OfflineUpsert } from '../../../graph/queries/general';
import { GetPrescriptionsFlattened, GetPrescriptionsOffline } from '../../../graph/queries/prescriptions';
import { Maybe, Prescription, PrescriptionUpsert } from '../../../graph/types';
import { getConnectionId } from '../../../hooks/authHooks';
import { RxPrescription } from '../schemas/prescriptionSchema';
import {
  getClearedRecord,
  getOfflineId,
  getUpdatedDate,
  OfflineUpsertProps,
  queryBuilder,
  replaceExistingRecords,
} from './queryUtils';
import { v4 as uuid } from 'uuid';
import { RxPatient } from '../schemas/patientSchema';
import { RxContact } from '../schemas/contactSchema';
import randomstring from 'randomstring';
import omit from 'lodash/omit';
import { addOfflinePrescriptionProperties } from '../../../util/offline/offlineQueryUtils';
import { RxPrescriptionFlattened } from '../schemas/prescriptionFlattenedSchema';

export const prescriptionQueryBuilder = (organizationId: string) =>
  queryBuilder<RxPrescription>(organizationId, GetPrescriptionsOffline, { sortByUpdated: true });

export const prescriptionFlattenedQueryBuilder = (organizationId: string) => {
  return queryBuilder<RxPrescriptionFlattened>(organizationId, GetPrescriptionsFlattened, { sortByUpdated: true });
};

export const getPrescriptionPushVariables = (organizationId: string, prescription: RxPrescription) => ({
  variables: {
    organizationId,
    upsert: {
      record: {
        type_name: 'upsertPrescription',
        offline_id: prescription.offline_id ?? getOfflineId(),
        connection_id: getConnectionId(),
        instruction: JSON.stringify({
          ...cleanUpsert(prescription.upsert ?? {}),
          id: prescription.is_new ? ID_FOR_OBJECT_CREATION : prescription.id,
        }),
      },
    },
  },
});

export const prescriptionPushBuilder = (organizationId: string) => (prescription: RxPrescription) => ({
  query: print(OfflineUpsert),
  ...getPrescriptionPushVariables(organizationId, prescription),
});

const cleanUpsert = (upsert: PrescriptionUpsert): PrescriptionUpsert => ({
  ...upsert,
  record: upsert.record && {
    ...upsert.record,
    ...getClearedRecord(upsert.record),
  },
});

export const getPrescriptionInsert = (
  prescriptionUpsert: PrescriptionUpsert,
  {
    organizationId,
    patient,
    contact,
  }: {
    patient?: Maybe<RxPatient>;
    contact?: Maybe<RxContact>;
    organizationId: string;
  }
): Promise<RxPrescription> => {
  const offlineId = getOfflineId();
  const upsert = { ...prescriptionUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
  }

  return new Promise<RxPrescription>((resolve) =>
    resolve({
      ...(upsert.record ?? {}),
      id: offlineId,
      offline_id: offlineId,
      practice_id: prescriptionUpsert.record?.practice_id,
      organization_id: organizationId,
      patient_id: patient?.id,
      patient_name: patient?.name,
      patient_number: patient?.number,
      offline_patient_id: patient?.offline_id,
      contact_id: contact?.id,
      contact_name: contact?.name,
      contact_number: contact?.number,
      offline_contact_id: contact?.offline_id,
      number: 'Temp-' + randomstring.generate(6),
      updated: getUpdatedDate(),
      upsert,
      upsert_offline_id: uuid(),
      hidden: false,
      is_new: true,
      remaining_fills: (prescriptionUpsert.record?.refills ?? 0) + 1,
    } as RxPrescription)
  );
};

export const getPrescriptionUpdate = (rxPrescription: RxPrescription, prescriptionUpsert: PrescriptionUpsert) => {
  const newUpsert = { ...rxPrescription.upsert, ...prescriptionUpsert };

  if (newUpsert.record) {
    newUpsert.record.contact_id = rxPrescription.contact_id ?? rxPrescription.upsert?.record?.contact_id;
    newUpsert.record.patient_id = rxPrescription.patient_id ?? rxPrescription.upsert?.record?.patient_id;
  }

  const propsToUpdate: OfflineUpsertProps<RxPrescription> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    ...omit(newUpsert.record ?? {}, 'contact_id', 'patient_id'),
    hidden: !!newUpsert.void,
    updated: getUpdatedDate(),
    remaining_fills: rxPrescription.remaining_fills + (newUpsert.record?.refills ?? 0) - (rxPrescription.refills ?? 0),
  };

  return propsToUpdate;
};

export const onPrescriptionUpdate = async (prescription: Prescription): Promise<RxPrescription> => {
  await replaceExistingRecords(prescription, 'prescription');
  return addOfflinePrescriptionProperties(prescription);
};
