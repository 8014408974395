import React from 'react';
import { CassadolLogo } from '../../images/images';
import styled from 'styled-components';

const signInFormWidth = 400;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FormContainer = styled.div`
  width: ${signInFormWidth};
`;

export const SignInContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <FormContainer>
        <CassadolLogo style={{ width: signInFormWidth, marginBottom: 30 }} />
        {children}
      </FormContainer>
    </Container>
  );
};
