import { Form, Select } from 'antd';
import styled from 'styled-components';

export const TopInputControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const FormItemLessMargin = styled(Form.Item)`
  margin-bottom: 12px;
  margin-right: 5px;
`;

export const Row = styled.div`
  display: flex;
`;

export const DateSentLabel = styled.div`
  font-weight: 500;
  margin-right: 5px;
`;

export const DateSentValue = styled.div`
  font-weight: 500;
  margin-left: 5px;
`;

export const DateSentContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const RedWarning = styled.p`
  color: red;
`;

export const FormItemSelect = styled(Select)`
  min-width: 8rem;
`;
