import { LedgerProperty, ledgerPropertyNames } from '../../../../constants/propertyNames';
import { CustomColumnType } from '../../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Ledger } from '../../../../graph/types';
import { translations } from '../../../../constants/translations';
import { displayAsDate } from '../../../../constants/formats';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getIdNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getPaymentTypesFilters,
  getStatusFilters,
  getStringCompareFunctionFor,
} from '../../../../util/filterAndSorting';
import {
  getInvoiceStatusTagForLedger,
  getLedgerPaymentType,
  getLedgerTypeFilters,
  getLedgerTypeValue,
} from '../../contactLedgerUtils';
import { useMemo } from 'react';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { invoiceStatusConfigs } from '../../../../constants/referenceData/invoiceReferenceData';
import { paymentTypeConfigs } from '../../../../constants/referenceData/paymentReferenceData';

export const useBasicLedgerColumns = (): Record<LedgerProperty, CustomColumnType<Ledger>> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(
    () => ({
      number: {
        title: translations.viewContactLedgerPage.columns.number,
        dataIndex: ledgerPropertyNames.number,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.number
        ),
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.number),
        sorter: getIdNumberCompareFunctionFor(ledgerPropertyNames.number),
      },
      patient_name: {
        title: translations.viewContactLedgerPage.columns.patient_name,
        dataIndex: ledgerPropertyNames.patient_name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.patient_name
        ),
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.patient_name),
        sorter: getStringCompareFunctionFor(ledgerPropertyNames.patient_name),
      },
      financial_date: {
        title: translations.viewContactLedgerPage.columns.financial_date,
        dataIndex: ledgerPropertyNames.financial_date,
        render: (financial_date: string) => {
          if (!financial_date) {
            return translations.viewContactLedgerPage.defaultValues.noFinancialDate;
          }
          return displayAsDate(financial_date, dateFormat);
        },
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.financial_date
        ),
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.financial_date),
        sorter: getDateCompareFunctionFor(ledgerPropertyNames.financial_date),
      },
      financial_outstanding: {
        title: translations.viewContactLedgerPage.columns.financial_outstanding,
        dataIndex: ledgerPropertyNames.financial_outstanding,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.financial_outstanding
        ),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.financial_outstanding, true, true),
        align: 'right',
      },
      invoice_status_name_key: {
        title: translations.viewContactLedgerPage.columns.status,
        render: getInvoiceStatusTagForLedger,
        onFilter: (value, record) => record.invoice_status_name_key === value,
        ...generalFilteringAndSortingSettings,
        filters: getStatusFilters(invoiceStatusConfigs),
      },
      type_name_key: {
        title: translations.viewContactLedgerPage.columns.type_name_key,
        onFilter: (value, record) => getLedgerTypeValue(record) === value,
        ...generalFilteringAndSortingSettings,
        filters: getLedgerTypeFilters(),
      },
      total: {
        title: translations.viewContactLedgerPage.columns.total,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.total
        ),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.total, true),
        align: 'right',
      },
      note_exists: {
        title: translations.viewContactLedgerPage.columns.note,
        align: 'center',
      },
      contact_name: {
        title: translations.viewContactLedgerPage.columns.contact_name,
        dataIndex: ledgerPropertyNames.contact_name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.viewContactLedgerPage.columns.contact_name
        ),
        onFilter: getOnFilterFunctionFor(ledgerPropertyNames.contact_name),
        sorter: getStringCompareFunctionFor(ledgerPropertyNames.contact_name),
      },
      payment_type_name_key: {
        title: translations.viewContactLedgerPage.columns.payment_type,
        render: getLedgerPaymentType,
        onFilter: (value, record) => record.payment_type_name_key === value,
        ...generalFilteringAndSortingSettings,
        filters: getPaymentTypesFilters(paymentTypeConfigs),
      },
    }),
    [dateFormat]
  );
};
