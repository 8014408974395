import { ColumnFilterItem } from 'antd/lib/table/interface';
import { PresetColorType } from 'antd/lib/_util/colors';
import { translations } from '../translations';
import { LedgerPaymentType, LedgerPaymentTypeDto, RefLedgerPaymentType, Scalars } from '../../graph/types';
import { LedgerPaymentElectronicTypeValue } from './ledgerReferenceData';

export interface paymentTypeConfig extends ColumnFilterItem {
  text: string;
  color: PresetColorType;
  creditCardType: boolean;
}

export enum PaymentStripeAccountStatus {
  NotFound = 'ACCOUNT_NOTFOUND',
  NotSetup = 'ACCOUNT_NOTSETUP',
  Ready = 'ACCOUNT_READY',
}

interface paymentStripeAccountStatusConfig {
  text: string;
}

export const paymentStripeAccountStatusConfigs: {
  [nameKey in PaymentStripeAccountStatus]: paymentStripeAccountStatusConfig;
} = {
  [PaymentStripeAccountStatus.NotFound]: {
    text: translations.stripeLandingPage.statuses.notFound,
  },
  [PaymentStripeAccountStatus.NotSetup]: {
    text: translations.stripeLandingPage.statuses.notSetup,
  },
  [PaymentStripeAccountStatus.Ready]: {
    text: translations.stripeLandingPage.statuses.ready,
  },
};

export type PaymentTypeReferenceDataType = LedgerPaymentType & {
  __typename?: 'PaymentTypeReferenceDataType';
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  uneditable?: Scalars['Boolean'];
  enabledUneditable?: Scalars['Boolean'];
};

export enum PaymentTypeNameKey {
  Cash = 'PT_CASH',
  Check = 'PT_CHECK',
  Visa = 'PT_VISA',
  Mastercard = 'PT_MASTERCARD',
  Amex = 'PT_AMEX',
  Discover = 'PT_DISCOVER',
  Debit = 'PT_DEBIT',
  Credit = 'PT_CREDIT',
  MoneyOrder = 'PT_MONEYORDER',
  DirectDeposit = 'PT_DRCTDEPOSIT',
  EmailTransfer = 'PT_EMAILXFER',
  Paypal = 'PT_PAYPAL',
  Venmo = 'PT_VENMO',
  WireTransfer = 'PT_WIRE',
  Zelle = 'PT_ZELLE',
  Other = 'PT_OTHER',
}

export const paymentTypeConfigs: { [nameKey in PaymentTypeNameKey]: paymentTypeConfig } = {
  [PaymentTypeNameKey.Cash]: {
    text: 'Cash',
    value: 1,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Check]: {
    text: 'Check',
    value: 2,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Visa]: {
    text: 'Visa',
    value: 3,
    color: 'blue',
    creditCardType: true,
  },
  [PaymentTypeNameKey.Mastercard]: {
    text: 'Mastercard',
    value: 4,
    color: 'red',
    creditCardType: true,
  },
  [PaymentTypeNameKey.Amex]: {
    text: 'Amex',
    value: 5,
    color: 'gold',
    creditCardType: true,
  },
  [PaymentTypeNameKey.Discover]: {
    text: 'Discover',
    value: 6,
    color: 'orange',
    creditCardType: true,
  },
  [PaymentTypeNameKey.Debit]: {
    text: 'Debit',
    value: 7,
    color: 'geekblue',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Credit]: {
    text: 'Card',
    value: 8,
    color: 'volcano',
    creditCardType: false,
  },
  [PaymentTypeNameKey.MoneyOrder]: {
    text: 'Money Order',
    value: 9,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.DirectDeposit]: {
    text: 'Direct Deposit',
    value: 10,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.EmailTransfer]: {
    text: 'Email Transfer',
    value: 11,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Paypal]: {
    text: 'PayPal',
    value: 12,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Venmo]: {
    text: 'Venmo',
    value: 13,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.WireTransfer]: {
    text: 'Wire Transfer',
    value: 14,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Zelle]: {
    text: 'Zelle',
    value: 15,
    color: 'green',
    creditCardType: false,
  },
  [PaymentTypeNameKey.Other]: {
    text: 'Other',
    value: 16,
    color: 'green',
    creditCardType: false,
  },
};

export const getPaymentTypesReferenceData = (
  orgPaymentTypes: LedgerPaymentTypeDto[],
  refPaymentTypes: RefLedgerPaymentType[],
  isQuickBooksAligned?: boolean,
  includeCreditCardSubTypes?: boolean
): PaymentTypeReferenceDataType[] => {
  const filteredPaymentTypeIds = refPaymentTypes
    .filter(
      (type) =>
        type.electronic_type_id === 0 ||
        (includeCreditCardSubTypes && type.electronic_type_id === LedgerPaymentElectronicTypeValue.CreditCard)
    )
    .map((type) => type.id);

  return Object.keys(paymentTypeConfigs)
    .filter((configKey) =>
      filteredPaymentTypeIds?.includes(String(paymentTypeConfigs[configKey as PaymentTypeNameKey].value))
    )
    .map((configKey) => {
      const existingType = orgPaymentTypes.find((type) => type.name_key === configKey);
      return {
        id: paymentTypeConfigs[configKey as PaymentTypeNameKey].value,
        electronic_type_id: existingType?.electronic_type_id,
        name_key: configKey,
        sort_order: existingType?.sort_order || 0,
        name: paymentTypeConfigs[configKey as PaymentTypeNameKey].text,
        enabled: existingType ? true : false,
        external_id: isQuickBooksAligned ? existingType?.external_id : undefined,
        general_ledger_id: existingType?.general_ledger_id,
        uneditable: existingType ? false : paymentTypeConfigs[configKey as PaymentTypeNameKey].creditCardType,
        enabledUneditable: existingType?.electronic_type_id === LedgerPaymentElectronicTypeValue.CreditCard,
      } as PaymentTypeReferenceDataType;
    });
};

export const getPaymentTypeReferenceData = (paymentType: LedgerPaymentType): PaymentTypeReferenceDataType => {
  return {
    ...paymentType,
    name: paymentTypeConfigs[paymentType.name_key as PaymentTypeNameKey].text,
  } as PaymentTypeReferenceDataType;
};
