import React, { useMemo } from 'react';
import { translations } from '../../../../constants/translations';
import { Invoice, ServiceRendered } from '../../../../graph/types';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { widthUntilTotalColumn } from '../../../../components/ServiceRendered/ServicesRenderedTable/ServicesRenderedTable';
import { PAGE_SIDER_WIDTH } from '../../../../constants/layout';
import { MailOutlined, PrinterOutlined, DollarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getWidthOfElementWithId } from '../../../../util/documentUtil';
import { mapRefDataName } from '../../../../util/refDataUtil';
import { hasValidEmailSettingsDto } from '../../../../util/email';
import { PriceValue } from '../../../../components/PriceValue/PriceValue';
import { useOffline } from '../../../../util/offline/offlineUtil';
import CurrencyFormatter from '../../../../components/CurrencyFormatter/CurrencyFormatter';
import { useOrganizationContext } from '../../../../contexts/organization/state';

interface TotalOrTaxLine {
  label: string;
  amount?: number;
  showAmountWhileEditing?: boolean;
}

interface TotalsAndTaxesProps {
  isEditing: boolean;
  servicesRendered: ServiceRendered[];
  unsavedServicesRendered: ServiceRendered[];
  invoice: Invoice;
  onEmailClick: () => void;
  onPrintClick: () => void;
  onPaymentClick?: () => void;
  hasPendingCharges?: boolean;
}

const useSubTotal = (
  servicesRendered: ServiceRendered[],
  unsavedServicesRendered: ServiceRendered[],
  isOnline: boolean
) =>
  useMemo(() => {
    if (isOnline) {
      return servicesRendered.reduce((sum, service) => sum + Number(service.total), 0);
    }
    return (
      servicesRendered.reduce((sum, service) => sum + Number(service.total), 0) +
      unsavedServicesRendered.reduce((sum, service) => sum + Number(service.total), 0)
    );
  }, [servicesRendered, unsavedServicesRendered, isOnline]);

export const TotalsAndTaxes: React.FC<TotalsAndTaxesProps> = ({
  isEditing,
  servicesRendered,
  unsavedServicesRendered,
  invoice,
  onEmailClick,
  onPrintClick,
  onPaymentClick,
  hasPendingCharges,
}) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const { windowWidth } = useWindowDimensions();
  const smtpSettings = organization?.smtp;
  const { isOnline } = useOffline();

  const viewInvoiceFooterWidth = getWidthOfElementWithId('ViewInvoiceFooter');

  const subTotal = useSubTotal(servicesRendered, unsavedServicesRendered, isOnline);

  const taxes = invoice?.tax ?? [];
  const activeTaxTypes = organization?.ref_financial?.tax_type ?? [];

  const subTotalLine: TotalOrTaxLine = {
    label: translations.invoicePage.subTotal,
    amount: subTotal,
    showAmountWhileEditing: true,
  };
  const taxLines: TotalOrTaxLine[] = taxes.map((tax) => ({
    label: mapRefDataName({ id: tax.tax_type_id, name: tax.tax_type_name }, activeTaxTypes),
    amount: Number(tax.total),
  }));
  const totalLine = { label: translations.invoicePage.total, amount: Number(invoice?.total) };

  const singleLineIfNoTaxes = {
    label: isOnline ? translations.invoicePage.total : translations.invoicePage.subTotal,
    amount: Number(subTotal),
    showAmountWhileEditing: true,
  };
  const totalOrTaxLines: TotalOrTaxLine[] =
    taxes.length === 0 ? [singleLineIfNoTaxes] : [subTotalLine, ...taxLines, totalLine];

  const footerLeftPadding = 16;
  const width =
    Math.min(widthUntilTotalColumn - footerLeftPadding, windowWidth - PAGE_SIDER_WIDTH - 20) - viewInvoiceFooterWidth;

  return (
    <div style={{ width, textAlign: 'right', display: 'flex', justifyContent: 'flex-end', paddingRight: 16 }}>
      <div>
        {totalOrTaxLines.map(({ label }, index) => (
          <p style={{ margin: 0 }} key={index}>
            {label}:
          </p>
        ))}
      </div>
      <div style={{ marginLeft: 20 }}>
        {totalOrTaxLines.map(({ label, amount, showAmountWhileEditing }, index) => (
          <p style={{ margin: 0 }} key={index}>
            {getAmountText(amount, label, isEditing, showAmountWhileEditing, hasPendingCharges, isOnline)}
          </p>
        ))}
      </div>
      {isOnline && (
        <div
          style={{
            marginLeft: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <Button
            size='small'
            style={{ marginBottom: '0.4rem' }}
            onClick={onEmailClick}
            disabled={!hasValidEmailSettingsDto(smtpSettings)}
          >
            <MailOutlined />
            {translations.invoicePage.email}
          </Button>
          <Button size='small' onClick={onPrintClick}>
            <PrinterOutlined />
            {translations.invoicePage.print}
          </Button>
          {onPaymentClick && (
            <Button size='small' style={{ marginTop: '0.4rem' }} onClick={onPaymentClick}>
              <DollarOutlined /> {translations.invoicePage.payment}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

interface SubTotalProps {
  servicesRendered: ServiceRendered[];
  isFullscreenView?: boolean;
  isLeftAligned?: boolean;
}

export const SubTotal: React.FC<SubTotalProps> = ({ servicesRendered, isFullscreenView, isLeftAligned }) => {
  return (
    <div
      style={{
        textAlign: isLeftAligned ? 'left' : 'right',
        display: 'block',
        justifyContent: 'right',
        fontSize: isFullscreenView ? 'larger' : undefined,
      }}
    >
      <div>
        <div style={{ display: 'inline', fontWeight: isFullscreenView ? 'bold' : undefined }}>
          {translations.invoicePage.total + ': '}
        </div>
        <CurrencyFormatter total={servicesRendered.reduce((sum, current) => sum + Number(current.total), 0)} />
      </div>
    </div>
  );
};

const getAmountText = (
  amount: number | undefined,
  label: string,
  isEditing: boolean,
  shouldShowWhileEditing?: boolean,
  hasPendingCharges?: boolean,
  isOnline?: boolean
) => {
  if (hasPendingCharges) {
    if (!isOnline && label === translations.invoicePage.subTotal) {
      return amount === undefined ? translations.invoicePage.amountNotAvailable : <PriceValue value={amount} />;
    }
    return (
      <i style={{ color: 'grey' }}>
        {isOnline
          ? translations.invoicePage.onlinePendingChargesIndicator
          : translations.invoicePage.offlinePendingChargesIndicator}
      </i>
    );
  }
  if (!isEditing || shouldShowWhileEditing) {
    return amount === undefined ? translations.invoicePage.amountNotAvailable : <PriceValue value={amount} />;
  }
  return <i style={{ color: 'grey' }}>{translations.invoicePage.editingIndicator}</i>;
};
