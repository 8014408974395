import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd/lib/table';
import { CustomColumnType } from '../components/TableWithCustomFiltering/TableWithCustomFiltering';
import isEmpty from 'lodash/isEmpty';
import { RecordType } from '../components/Records/recordUtils';
import { basicPatientColumns } from '../pages/Patients/PatientsOverview/patientColumns';
import { useUserContext } from '../contexts/user/state';

export enum TableKey {
  Default,
  UsersOverview,
  ServicesOverview,
  ContactsOverview,
  PatientsOverview,
  InvoicesOverview,
  ContactPatients,
  ContactLedger,
  CardConnectDevices,
  SubscriptionUpdate,
  SelectContactModal,
  SelectPatientModal,
  RefData,
  OrganizationSubscription,
  StatementRunTabContent,
  InterestRunTabContent,
  BillingHistory,
  Phone,
  SyncErrorLog,
  Reminders,
  RemindersRun,
  SubscriptionOfflineUsers,
  Prescriptions,
  ApprovalsOverview,
  HistoryOverview,
  Taxonomy,
  LabManagementOverview,
  ServiceMappingOverview,
  UnassignedLabs,
  PatientAlerts,
  ContactAlerts,
  OfflineDiagnostics,
  StripePayments,
  FinancialPeriods,
  StripePayouts,
  DepositRuns,
  DepositRunPaymentTypes,
  DepositRunDetails,
  HisaPatients,
}

const pageSizeKey = (key: TableKey) => `page_size_${key}`;
export const defaultPageSize = 10;

const getPageSizeFromLocalStorage = (key: TableKey) => localStorage.getItem(pageSizeKey(key)) || defaultPageSize;
const setPageSizeInLocalStorage = (key: TableKey, value: number) =>
  localStorage.setItem(pageSizeKey(key), value.toString());

export const usePageSize = (tableKey: TableKey) => {
  const [pageSize, setPageSize] = useState<number>(+getPageSizeFromLocalStorage(tableKey));

  const handleSetPageSize = (newPageSize: number) => {
    setPageSizeInLocalStorage(tableKey, newPageSize);
    setPageSize(newPageSize);
  };

  return { pageSize, setPageSize: handleSetPageSize };
};

const getSortOrderMap = (sorter: SorterResult<any> | SorterResult<any>[]) => {
  const sortOrderMap: { [key: string]: SorterResult<any>['order'] } = {};
  if (Array.isArray(sorter)) {
    sorter.forEach((sorterItem) => {
      if (sorterItem.columnKey) {
        sortOrderMap[sorterItem.columnKey] = sorterItem.order;
      }
    });
  } else if (sorter.columnKey) {
    sortOrderMap[sorter.columnKey] = sorter.order;
  }
  return sortOrderMap;
};

interface TableFilterSortState {
  filteredValue: Record<string, FilterValue | null>;
  sortOrderMap: ReturnType<typeof getSortOrderMap>;
}

export enum TableFilterAndSortKey {
  ContactsOverview = 1,
  InvoicesOverview,
  PatientsOverview,
  ServicesOverview,
  ApprovalsOverview,
  HistoryOverview,
  TaxonomyTable,
  StatementRunTabContent,
  LabManagementOverview,
  ContactPatients,
  InterestRunTabContent,
  StripePayments,
}

export const useTableResetFilterAndSort = (key?: TableFilterAndSortKey) => {
  const [{ filteredValue, sortOrderMap }, setFilterAndSortOrder] = useState<TableFilterSortState>({
    filteredValue: {},
    sortOrderMap: {},
  });
  const {
    state: { user },
  } = useUserContext();

  const tableFilterAndSortKey = key && `TFAS-${key}-${user?.id ?? ''}`;
  useEffect(() => {
    if (!tableFilterAndSortKey) {
      return;
    }

    const storedFilters = JSON.parse(localStorage.getItem(tableFilterAndSortKey) ?? '{}');
    if (!isEmpty(storedFilters)) {
      setFilterAndSortOrder((prev) => ({
        ...prev,
        filteredValue: storedFilters.filters ?? {},
      }));
    }
  }, [setFilterAndSortOrder, tableFilterAndSortKey]);

  const tableChangeHandler: TableProps<any>['onChange'] = useCallback(
    (
      _: any,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<RecordType> | SorterResult<RecordType>[]
    ) => {
      if (tableFilterAndSortKey) {
        localStorage.setItem(tableFilterAndSortKey, JSON.stringify({ filters }));
      }
      setFilterAndSortOrder({
        filteredValue: filters,
        sortOrderMap: getSortOrderMap(sorter),
      });
    },
    [setFilterAndSortOrder, tableFilterAndSortKey]
  );

  const resetFiltersAndSort = useCallback(
    (key?: string) => {
      setFilterAndSortOrder((prev) => {
        let newFilters = {};
        let newSortMap = {};
        if (key) {
          // Clear filtering for one column only
          newFilters = {
            ...prev.filteredValue,
            [key]: null,
          };
          newSortMap = prev.sortOrderMap;
        }

        if (tableFilterAndSortKey) {
          localStorage.setItem(tableFilterAndSortKey, JSON.stringify({ filters: newFilters }));
        }

        return {
          filteredValue: newFilters,
          sortOrderMap: newSortMap,
        };
      });
    },
    [setFilterAndSortOrder, tableFilterAndSortKey]
  );

  const modifiedFields = useMemo(
    () => Object.keys(filteredValue).filter((key) => !!filteredValue[key]),
    [filteredValue]
  );

  return {
    filteredValue,
    sortOrderMap,
    tableChangeHandler,
    resetFiltersAndSort,
    modifiedFields,
    setFilterAndSortOrder,
  };
};

const displayedColumnKey = (key: TableKey) => `displayed_cols_${key}`;

const getDisplayedColumnKeysFromLocalStorage = (key: TableKey) => {
  const storedValue = localStorage.getItem(displayedColumnKey(key));
  if (storedValue === '') {
    return [];
  }
  return storedValue?.split(',');
};

const setDisplayedColumnKeysInLocalStorage = (key: TableKey, value: string[]) =>
  localStorage.setItem(displayedColumnKey(key), value.length ? value.join(',') : '');

const removeDisplayedColumnKeysFromLocalStorage = (key: TableKey) => localStorage.removeItem(displayedColumnKey(key));

export interface OptionalColumnMap<T> {
  [key: string]: {
    column: CustomColumnType<T>;
    name: string;
  };
}

export const getDefaultColumnKeysForPatients = <T>(allOptionalColumns: CustomColumnType<T>[]) =>
  allOptionalColumns
    .map((col) => col.key as string)
    .filter((k) => k !== basicPatientColumns.name_2.key && k !== basicPatientColumns.name_3.key);

export const useTableColumnDisplayFilter = <T>(
  tableKey: TableKey,
  allOptionalColumns: CustomColumnType<T>[],
  defaultColumnKeys?: string[]
) => {
  const { columnMap, columnKeyAndTitleList } = useMemo(() => {
    const columnMap: { [key: string]: CustomColumnType<T> } = {};
    const columnKeyAndTitleList: { key: string; title: string }[] = [];
    allOptionalColumns.forEach((col) => {
      if (col.key) {
        columnMap[col.key] = col;
        columnKeyAndTitleList.push({
          key: col.key as string,
          title: typeof col.title === 'string' ? col.title : '',
        });
      } else {
        throw new Error('Column key not found for optional column');
      }
    });
    return { columnMap, columnKeyAndTitleList };
  }, [allOptionalColumns]);

  const parsedDefaultColumnKeys = useMemo(
    () => defaultColumnKeys || Object.keys(columnMap),
    [columnMap, defaultColumnKeys]
  );

  const [displayedColumnKeys, setDisplayedColumnKeys] = useState<string[]>([]);

  const displayedColumns = useMemo(
    () => displayedColumnKeys.map((colKey) => columnMap[colKey]),
    [columnMap, displayedColumnKeys]
  );

  useEffect(() => {
    const storedColumnKeys = getDisplayedColumnKeysFromLocalStorage(tableKey);
    let hasInvalidKeyInStorage = false;
    if (storedColumnKeys) {
      for (const storedKey of storedColumnKeys) {
        if (!columnMap[storedKey]) {
          hasInvalidKeyInStorage = true;
          removeDisplayedColumnKeysFromLocalStorage(tableKey);
          break;
        }
      }
    }
    if (!storedColumnKeys || hasInvalidKeyInStorage) {
      setDisplayedColumnKeys(parsedDefaultColumnKeys);
    } else {
      setDisplayedColumnKeys(storedColumnKeys);
    }
  }, [columnMap, allOptionalColumns, tableKey, parsedDefaultColumnKeys]);

  return useMemo(
    () => ({
      displayedColumns,
      displayedColumnKeys,
      columnKeyAndTitleList,
      setDisplayedColumnKeys: (columnKeys: string[]) => {
        setDisplayedColumnKeys(columnKeys);
        setDisplayedColumnKeysInLocalStorage(tableKey, columnKeys);
      },
      resetDisplayedColumnsToDefault: () => {
        setDisplayedColumnKeys(parsedDefaultColumnKeys);
        setDisplayedColumnKeysInLocalStorage(tableKey, parsedDefaultColumnKeys);
      },
    }),
    [columnKeyAndTitleList, displayedColumnKeys, displayedColumns, parsedDefaultColumnKeys, tableKey]
  );
};
