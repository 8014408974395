import { PaymentModalFormFields } from './PaymentForm/PaymentForm';
import {
  ElectronicContactRecordUpsert,
  CardConnectTransactionRecordUpsert,
  Contact,
  StripeTransactionRecordUpsert,
  Practice,
  PracticeDto,
} from '../../../graph/types';
import { CreditCardUpsertGenerator } from '../../../classes/upsertGenerators/CreditCardUpsertGenerator';
import { CreditCardTokenInfo } from '../../../hooks/stripeHooks';
import { UpsertTransactionVariables } from '../../../graph/queries/paymentGateway';

export enum TransactionType {
  AuthCapture = 1,
  Inquire = 2,
  Refund = 3,
  Void = 4,
  Pending = 5,
}

export function getCardTransactionRecordUpsert(
  formFields: PaymentModalFormFields,
  method: 'EMV' | 'TOKEN',
  creditCardToken?: string,
  creditCardExpiry?: string,
  isStripeProcessor?: boolean,
  currencyId?: string,
  isSavedCard?: boolean
): CardConnectTransactionRecordUpsert | StripeTransactionRecordUpsert {
  const sharedFields = {
    type_id: TransactionType.AuthCapture.toString(),
    amount: formFields.amount.toString(),
    card_note: formFields.cardNote,
    reversal_ledger_id: null,
    reversal_note: null,
    reversal_date: null,
  };

  return isStripeProcessor
    ? {
        ...sharedFields,
        save_credit_card: formFields.saveCard || false,
        currency_id: currencyId,
        ...(!isSavedCard ? { card_token: creditCardToken } : { card_id: creditCardToken }),
      }
    : {
        ...sharedFields,
        method,
        card_token: creditCardToken,
        expiry: creditCardExpiry,
        device_id: formFields.device,
        reference_number: null,
        save_card_record: formFields.saveCard || false,
        is_device_request: false,
        payment_option: formFields.paymentOption,
      };
}

export function getSubscriptionContactRecordUpsert(contact: Contact): ElectronicContactRecordUpsert {
  if (contact.address && contact.address.length > 0) {
    const address = contact.address[0];
    return CreditCardUpsertGenerator.mapToSubscriptionContactRecordUpsert(contact.id, contact.name, address);
  }
  return {
    contact_id: contact.id,
    name: contact.name,
  };
}

export function getCreditCardSaveVariables(
  practice: Practice,
  contact: Contact,
  isStripeProcessor: boolean,
  cc: CreditCardTokenInfo,
  primary: boolean,
  nickName?: string
): UpsertTransactionVariables {
  const propName = isStripeProcessor ? 'cardRecord' : 'card';
  return {
    organizationId: practice.organization_id,
    practiceId: practice.id,
    [propName]: {
      contact_id: contact.id,
      card_token: cc.token,
      card_type_id: cc.cardType,
      display_number: cc.displayNumber,
      expiry_month: cc.expiryMonth,
      expiry_year: cc.expiryYear,
      nick_name: nickName,
      primary,
    },
  };
}

export function getCreditCardSaveVariablesDto(
  practice: PracticeDto,
  contact: Contact,
  isStripeProcessor: boolean,
  cc: CreditCardTokenInfo,
  primary: boolean,
  nickName?: string
): UpsertTransactionVariables {
  const propName = isStripeProcessor ? 'cardRecord' : 'card';
  return {
    organizationId: practice.organization_id,
    practiceId: practice.id,
    [propName]: {
      contact_id: contact.id,
      card_token: cc.token,
      card_type_id: cc.cardType,
      display_number: cc.displayNumber,
      expiry_month: cc.expiryMonth,
      expiry_year: cc.expiryYear,
      nick_name: nickName,
      primary,
    },
  };
}
