import { StripeReqInfoType } from '../../util/stripeConnectUtils';
import { TagColor } from '../tagColor';
import { translations } from '../translations';

export interface StripeStatusConfig {
  text: string;
  color: string;
}

export interface StripePayoutTypeConfig {
  text: string;
  color: string;
}

interface StripeTransactionTypeConfig {
  text: string;
  pluralText: string;
}

export enum StripeStatusNameKey {
  RequiresPaymentMethod = 'requires_payment_method',
  RequiresConfirmation = 'requires_confirmation',
  RequiresAction = 'requires_action',
  Processing = 'processing',
  Succeeded = 'succeeded',
  Canceled = 'canceled',
  RequiresCapture = 'requires_capture',
  Failed = 'failed',
  PartiallyRefunded = 'partially_refunded',
  Blocked = 'blocked',
  Disputed = 'disputed',
  EarlyFraudWarning = 'early_fraud_warning',
  Incomplete = 'incomplete',
  Pending = 'pending',
  RefundPending = 'refund_pending',
  Refunded = 'refunded',
  Uncaptured = 'uncaptured',
  WaitingOnFunding = 'waiting_on_funding',
  CardDeclined = 'card_declined',
  ExpiredCard = 'expired_card',
}

enum StripeStatusText {
  RequiresPaymentMethod = 'Requires payment method',
  RequiresConfirmation = 'Requires confirmation',
  RequiresAction = 'Requires action',
  Processing = 'Processing',
  Succeeded = 'Succeeded',
  Canceled = 'Canceled',
  RequiresCapture = 'Requires capture',
  Failed = 'Payment failed',
  PartiallyRefunded = 'Partially refunded',
  Blocked = 'Blocked',
  Disputed = 'Disputed',
  EarlyFraudWarning = 'Early fraud warning',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  RefundPending = 'Refund pending',
  Refunded = 'Refunded',
  Uncaptured = 'Uncaptured',
  WaitingOnFunding = 'Waiting on funding',
  CardDeclined = 'Card declined',
  ExpiredCard = 'Expired Card',
}

export enum StripePayoutStatusNameKey {
  Paid = 'paid',
  Pending = 'pending',
  InTransit = 'in_transit',
  Canceled = 'canceled',
  Failed = 'failed',
}

enum StripePayoutStatusText {
  Paid = 'Paid',
  Pending = 'Pending',
  InTransit = 'In Transit',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export enum StripePayoutTypeNameKey {
  BankAccount = 'bank_account',
  Card = 'card',
}

export enum StripePayoutTypeText {
  BankAccount = 'Bank Account',
  Card = 'Card',
}

export enum StripeTransactionTypeNameKey {
  Adjustment = 'adjustment',
  Advance = 'advance',
  AdvanceFunding = 'advance_funding',
  AnticipationRepayment = 'anticipation_repayment',
  ApplicationFee = 'application_fee',
  ApplicationFeeRefund = 'application_fee_refund',
  Charge = 'charge',
  ConnectCollectionTransfer = 'connect_collection_transfer',
  Contribution = 'contribution',
  IssuingAuthorizationHold = 'issuing_authorization_hold',
  IssuingAuthorizationRelease = 'issuing_authorization_release',
  IssuingDispute = 'issuing_dispute',
  IssuingTransaction = 'issuing_transaction',
  ObligationInbound = 'obligation_inbound',
  ObligationOutbound = 'obligation_outbound',
  ObligationReversalInbound = 'obligation_reversal_inbound',
  ObligationReversalOutbound = 'obligation_reversal_outbound',
  ObligationPayout = 'obligation_payout',
  ObligationPayoutFailure = 'obligation_payout_failure',
  Payment = 'payment',
  PaymentFailureRefund = 'payment_failure_refund',
  PaymentRefund = 'payment_refund',
  PaymentReversal = 'payment_reversal',
  Payout = 'payout',
  PayoutCancel = 'payout_cancel',
  PayoutFailure = 'payout_failure',
  Refund = 'refund',
  RefundFailure = 'refund_failure',
  ReserveTransaction = 'reserve_transaction',
  ReservedFunds = 'reserved_funds',
  StripeFee = 'stripe_fee',
  StripeFxFee = 'stripe_fx_fee',
  TaxFee = 'tax_fee',
  Topup = 'topup',
  TopupReversal = 'topup_reversal',
  Transfer = 'transfer',
  TransferCancel = 'transfer_cancel',
  TransferFailure = 'transfer_failure',
  TransferRefund = 'transfer_refund',
}

export enum StripeTransactionTypeText {
  Adjustment = 'Adjustment',
  Advance = 'Advance',
  AdvanceFunding = 'Advance Funding',
  AnticipationRepayment = 'Anticipation Repayment',
  ApplicationFee = 'Application Fee',
  ApplicationFeeRefund = 'Application Fee Refund',
  Charge = 'Charge',
  ConnectCollectionTransfer = 'Connect Collection Transfer',
  Contribution = 'Contribution',
  IssuingAuthorizationHold = 'Issuing Authorization Hold',
  IssuingAuthorizationRelease = 'Issuing Authorization Release',
  IssuingDispute = 'Issuing Dispute',
  IssuingTransaction = 'Issuing Transaction',
  ObligationInbound = 'Obligation Inbound',
  ObligationOutbound = 'Obligation Outbound',
  ObligationReversalInbound = 'Obligation Reversal Inbound',
  ObligationReversalOutbound = 'Obligation Reversal Outbound',
  ObligationPayout = 'Obligation Payout',
  ObligationPayoutFailure = 'Obligation Payout Failure',
  Payment = 'Payment',
  PaymentFailureRefund = 'Payment Failure Refund',
  PaymentRefund = 'Payment Refund',
  PaymentReversal = 'Payment Reversal',
  Payout = 'Payout',
  PayoutCancel = 'Payout Cancel',
  PayoutFailure = 'Payout Failure',
  Refund = 'Refund',
  RefundFailure = 'Refund Failure',
  ReserveTransaction = 'Reserve Transaction',
  ReservedFunds = 'Reserved Funds',
  StripeFee = 'Stripe Fee',
  StripeFxFee = 'Stripe Fx Fee',
  TaxFee = 'Tax Fee',
  Topup = 'Topup',
  TopupReversal = 'Topup Reversal',
  Transfer = 'Transfer',
  TransferCancel = 'Transfer Cancel',
  TransferFailure = 'Transfer Failure',
  TransferRefund = 'Transfer Refund',
}

export enum StripeTransactionTypePluralText {
  Adjustment = 'Adjustments',
  Advance = 'Advances',
  AdvanceFunding = 'Advance Fundings',
  AnticipationRepayment = 'Anticipation Repayments',
  ApplicationFee = 'Application Fees',
  ApplicationFeeRefund = 'Application Fee Refunds',
  Charge = 'Charges',
  ConnectCollectionTransfer = 'Connect Collection Transfers',
  Contribution = 'Contributions',
  IssuingAuthorizationHold = 'Issuing Authorization Holds',
  IssuingAuthorizationRelease = 'Issuing Authorization Releases',
  IssuingDispute = 'Issuing Disputes',
  IssuingTransaction = 'Issuing Transactions',
  ObligationInbound = 'Obligation Inbounds',
  ObligationOutbound = 'Obligation Outbounds',
  ObligationReversalInbound = 'Obligation Reversal Inbounds',
  ObligationReversalOutbound = 'Obligation Reversal Outbounds',
  ObligationPayout = 'Obligation Payouts',
  ObligationPayoutFailure = 'Obligation Payout Failures',
  Payment = 'Payments',
  PaymentFailureRefund = 'Payment Failure Refunds',
  PaymentRefund = 'Payment Refunds',
  PaymentReversal = 'Payment Reversals',
  Payout = 'Payouts',
  PayoutCancel = 'Payout Cancellations',
  PayoutFailure = 'Payout Failures',
  Refund = 'Refunds',
  RefundFailure = 'Refund Failures',
  ReserveTransaction = 'Reserve Transactions',
  ReservedFunds = 'Reserved Funds',
  StripeFee = 'Stripe Fees',
  StripeFxFee = 'Stripe Fx Fees',
  TaxFee = 'Tax Fees',
  Topup = 'Topups',
  TopupReversal = 'Topup Reversals',
  Transfer = 'Transfers',
  TransferCancel = 'Transfer Cancellations',
  TransferFailure = 'Transfer Failures',
  TransferRefund = 'Transfer Refunds',
}

export const stripeTransactionTypeConfig: { [nameKey in StripeTransactionTypeNameKey]: StripeTransactionTypeConfig } = {
  [StripeTransactionTypeNameKey.Adjustment]: {
    text: StripeTransactionTypeText.Adjustment,
    pluralText: StripeTransactionTypePluralText.Adjustment,
  },
  [StripeTransactionTypeNameKey.Advance]: {
    text: StripeTransactionTypeText.Advance,
    pluralText: StripeTransactionTypePluralText.Advance,
  },
  [StripeTransactionTypeNameKey.AdvanceFunding]: {
    text: StripeTransactionTypeText.AdvanceFunding,
    pluralText: StripeTransactionTypePluralText.AdvanceFunding,
  },
  [StripeTransactionTypeNameKey.AnticipationRepayment]: {
    text: StripeTransactionTypeText.AnticipationRepayment,
    pluralText: StripeTransactionTypePluralText.AnticipationRepayment,
  },
  [StripeTransactionTypeNameKey.ApplicationFee]: {
    text: StripeTransactionTypeText.ApplicationFee,
    pluralText: StripeTransactionTypePluralText.ApplicationFee,
  },
  [StripeTransactionTypeNameKey.ApplicationFeeRefund]: {
    text: StripeTransactionTypeText.ApplicationFeeRefund,
    pluralText: StripeTransactionTypePluralText.ApplicationFeeRefund,
  },
  [StripeTransactionTypeNameKey.Charge]: {
    text: StripeTransactionTypeText.Charge,
    pluralText: StripeTransactionTypePluralText.Charge,
  },
  [StripeTransactionTypeNameKey.ConnectCollectionTransfer]: {
    text: StripeTransactionTypeText.ConnectCollectionTransfer,
    pluralText: StripeTransactionTypePluralText.ConnectCollectionTransfer,
  },
  [StripeTransactionTypeNameKey.Contribution]: {
    text: StripeTransactionTypeText.Contribution,
    pluralText: StripeTransactionTypePluralText.ConnectCollectionTransfer,
  },
  [StripeTransactionTypeNameKey.IssuingAuthorizationHold]: {
    text: StripeTransactionTypeText.IssuingAuthorizationHold,
    pluralText: StripeTransactionTypePluralText.IssuingAuthorizationHold,
  },
  [StripeTransactionTypeNameKey.IssuingAuthorizationRelease]: {
    text: StripeTransactionTypeText.IssuingAuthorizationRelease,
    pluralText: StripeTransactionTypePluralText.IssuingAuthorizationRelease,
  },
  [StripeTransactionTypeNameKey.IssuingDispute]: {
    text: StripeTransactionTypeText.IssuingDispute,
    pluralText: StripeTransactionTypePluralText.IssuingDispute,
  },
  [StripeTransactionTypeNameKey.IssuingTransaction]: {
    text: StripeTransactionTypeText.IssuingTransaction,
    pluralText: StripeTransactionTypePluralText.Adjustment,
  },
  [StripeTransactionTypeNameKey.ObligationInbound]: {
    text: StripeTransactionTypeText.ObligationInbound,
    pluralText: StripeTransactionTypePluralText.ObligationInbound,
  },
  [StripeTransactionTypeNameKey.ObligationOutbound]: {
    text: StripeTransactionTypeText.ObligationOutbound,
    pluralText: StripeTransactionTypePluralText.ObligationOutbound,
  },
  [StripeTransactionTypeNameKey.ObligationReversalInbound]: {
    text: StripeTransactionTypeText.ObligationReversalInbound,
    pluralText: StripeTransactionTypePluralText.ObligationReversalInbound,
  },
  [StripeTransactionTypeNameKey.ObligationReversalOutbound]: {
    text: StripeTransactionTypeText.ObligationReversalOutbound,
    pluralText: StripeTransactionTypePluralText.ObligationReversalOutbound,
  },
  [StripeTransactionTypeNameKey.ObligationPayout]: {
    text: StripeTransactionTypeText.ObligationPayout,
    pluralText: StripeTransactionTypePluralText.ObligationPayout,
  },
  [StripeTransactionTypeNameKey.ObligationPayoutFailure]: {
    text: StripeTransactionTypeText.ObligationPayoutFailure,
    pluralText: StripeTransactionTypePluralText.ObligationPayoutFailure,
  },
  [StripeTransactionTypeNameKey.Payment]: {
    text: StripeTransactionTypeText.Payment,
    pluralText: StripeTransactionTypePluralText.Payment,
  },
  [StripeTransactionTypeNameKey.PaymentFailureRefund]: {
    text: StripeTransactionTypeText.PaymentFailureRefund,
    pluralText: StripeTransactionTypePluralText.PaymentFailureRefund,
  },
  [StripeTransactionTypeNameKey.PaymentRefund]: {
    text: StripeTransactionTypeText.PaymentRefund,
    pluralText: StripeTransactionTypePluralText.PaymentRefund,
  },
  [StripeTransactionTypeNameKey.PaymentReversal]: {
    text: StripeTransactionTypeText.PaymentReversal,
    pluralText: StripeTransactionTypePluralText.PaymentReversal,
  },
  [StripeTransactionTypeNameKey.Payout]: {
    text: StripeTransactionTypeText.Payout,
    pluralText: StripeTransactionTypePluralText.Payout,
  },
  [StripeTransactionTypeNameKey.PayoutCancel]: {
    text: StripeTransactionTypeText.PayoutCancel,
    pluralText: StripeTransactionTypePluralText.PayoutCancel,
  },
  [StripeTransactionTypeNameKey.PayoutFailure]: {
    text: StripeTransactionTypeText.PayoutFailure,
    pluralText: StripeTransactionTypePluralText.PayoutFailure,
  },
  [StripeTransactionTypeNameKey.Refund]: {
    text: StripeTransactionTypeText.Refund,
    pluralText: StripeTransactionTypePluralText.Refund,
  },
  [StripeTransactionTypeNameKey.RefundFailure]: {
    text: StripeTransactionTypeText.RefundFailure,
    pluralText: StripeTransactionTypePluralText.RefundFailure,
  },
  [StripeTransactionTypeNameKey.ReserveTransaction]: {
    text: StripeTransactionTypeText.ReserveTransaction,
    pluralText: StripeTransactionTypePluralText.ReserveTransaction,
  },
  [StripeTransactionTypeNameKey.ReservedFunds]: {
    text: StripeTransactionTypeText.ReservedFunds,
    pluralText: StripeTransactionTypePluralText.ReservedFunds,
  },
  [StripeTransactionTypeNameKey.StripeFee]: {
    text: StripeTransactionTypeText.StripeFee,
    pluralText: StripeTransactionTypePluralText.StripeFee,
  },
  [StripeTransactionTypeNameKey.StripeFxFee]: {
    text: StripeTransactionTypeText.StripeFxFee,
    pluralText: StripeTransactionTypePluralText.StripeFxFee,
  },
  [StripeTransactionTypeNameKey.TaxFee]: {
    text: StripeTransactionTypeText.TaxFee,
    pluralText: StripeTransactionTypePluralText.TaxFee,
  },
  [StripeTransactionTypeNameKey.Topup]: {
    text: StripeTransactionTypeText.Topup,
    pluralText: StripeTransactionTypePluralText.Topup,
  },
  [StripeTransactionTypeNameKey.TopupReversal]: {
    text: StripeTransactionTypeText.TopupReversal,
    pluralText: StripeTransactionTypePluralText.TopupReversal,
  },
  [StripeTransactionTypeNameKey.Transfer]: {
    text: StripeTransactionTypeText.Transfer,
    pluralText: StripeTransactionTypePluralText.Transfer,
  },
  [StripeTransactionTypeNameKey.TransferCancel]: {
    text: StripeTransactionTypeText.TransferCancel,
    pluralText: StripeTransactionTypePluralText.TransferCancel,
  },
  [StripeTransactionTypeNameKey.TransferFailure]: {
    text: StripeTransactionTypeText.TransferFailure,
    pluralText: StripeTransactionTypePluralText.TransferFailure,
  },
  [StripeTransactionTypeNameKey.TransferRefund]: {
    text: StripeTransactionTypeText.TransferRefund,
    pluralText: StripeTransactionTypePluralText.TransferRefund,
  },
};

export const stripePaymentIntentStatusConfig: { [nameKey in StripeStatusNameKey]: StripeStatusConfig } = {
  [StripeStatusNameKey.Succeeded]: {
    text: StripeStatusText.Succeeded,
    color: TagColor.Color1,
  },
  [StripeStatusNameKey.RequiresPaymentMethod]: {
    text: StripeStatusText.RequiresPaymentMethod,
    color: TagColor.Color2,
  },
  [StripeStatusNameKey.RequiresConfirmation]: {
    text: StripeStatusText.RequiresConfirmation,
    color: TagColor.Color6,
  },
  [StripeStatusNameKey.RequiresAction]: {
    text: StripeStatusText.RequiresAction,
    color: TagColor.Color11,
  },
  [StripeStatusNameKey.Processing]: {
    text: StripeStatusText.Processing,
    color: TagColor.Color3,
  },
  [StripeStatusNameKey.Canceled]: {
    text: StripeStatusText.Canceled,
    color: TagColor.Color4,
  },
  [StripeStatusNameKey.RequiresCapture]: {
    text: StripeStatusText.RequiresCapture,
    color: TagColor.Color8,
  },
  [StripeStatusNameKey.Failed]: {
    text: StripeStatusText.Failed,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.PartiallyRefunded]: {
    text: StripeStatusText.PartiallyRefunded,
    color: TagColor.Color12,
  },
  [StripeStatusNameKey.Blocked]: {
    text: StripeStatusText.Blocked,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.Disputed]: {
    text: StripeStatusText.Disputed,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.EarlyFraudWarning]: {
    text: StripeStatusText.EarlyFraudWarning,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.Incomplete]: {
    text: StripeStatusText.Incomplete,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.Pending]: {
    text: StripeStatusText.Pending,
    color: TagColor.Color3,
  },
  [StripeStatusNameKey.RefundPending]: {
    text: StripeStatusText.RefundPending,
    color: TagColor.Color3,
  },
  [StripeStatusNameKey.Refunded]: {
    text: StripeStatusText.Refunded,
    color: TagColor.Color3,
  },
  [StripeStatusNameKey.Uncaptured]: {
    text: StripeStatusText.Uncaptured,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.WaitingOnFunding]: {
    text: StripeStatusText.WaitingOnFunding,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.CardDeclined]: {
    text: StripeStatusText.CardDeclined,
    color: TagColor.Color10,
  },
  [StripeStatusNameKey.ExpiredCard]: {
    text: StripeStatusText.ExpiredCard,
    color: TagColor.Color10,
  },
};

export const stripePayoutStatusConfig: { [nameKey in StripePayoutStatusNameKey]: StripeStatusConfig } = {
  [StripePayoutStatusNameKey.Paid]: {
    text: StripePayoutStatusText.Paid,
    color: TagColor.Color1,
  },
  [StripePayoutStatusNameKey.Pending]: {
    text: StripePayoutStatusText.Pending,
    color: TagColor.Color2,
  },
  [StripePayoutStatusNameKey.InTransit]: {
    text: StripePayoutStatusText.InTransit,
    color: TagColor.Color6,
  },
  [StripePayoutStatusNameKey.Canceled]: {
    text: StripePayoutStatusText.Canceled,
    color: TagColor.Color4,
  },
  [StripePayoutStatusNameKey.Failed]: {
    text: StripePayoutStatusText.Failed,
    color: TagColor.Color10,
  },
};

export const stripePayoutTypeConfig: { [nameKey in StripePayoutTypeNameKey]: StripePayoutTypeConfig } = {
  [StripePayoutTypeNameKey.BankAccount]: {
    text: StripePayoutTypeText.BankAccount,
    color: TagColor.Color3,
  },
  [StripePayoutTypeNameKey.Card]: {
    text: StripePayoutTypeText.Card,
    color: TagColor.Color4,
  },
};

const incompleteTranslations = translations.stripeLandingPage.incompleteItems;

const getAddress = (infoType?: StripeReqInfoType) => ({
  city: incompleteTranslations.address(infoType),
  line1: incompleteTranslations.address(infoType),
  postal_code: incompleteTranslations.address(infoType),
  state: incompleteTranslations.address(infoType),
});

const getDob = (infoType?: StripeReqInfoType) => ({
  day: incompleteTranslations.dob(infoType),
  month: incompleteTranslations.dob(infoType),
  year: incompleteTranslations.dob(infoType),
});

export const stripeRequirementsConfig = {
  business_profile: {
    mcc: incompleteTranslations.mcc,
    url: incompleteTranslations.url,
  },
  company: {
    owners_provided: incompleteTranslations.provideOwners,
    tax_id: incompleteTranslations.taxInformation(StripeReqInfoType.Company),
    address: getAddress(StripeReqInfoType.Company),
    directors_provided: incompleteTranslations.provideDirectors,
    executives_provided: incompleteTranslations.provideExecutives,
    registration_number: incompleteTranslations.provideRegistrationNumber,
  },
  external_account: incompleteTranslations.externalAccount,
  owners: {
    address: getAddress(StripeReqInfoType.Owner),
    dob: getDob(StripeReqInfoType.Owner),
    email: incompleteTranslations.email(StripeReqInfoType.Owner),
    first_name: incompleteTranslations.name(StripeReqInfoType.Owner),
    last_name: incompleteTranslations.name(StripeReqInfoType.Owner),
    id_number: incompleteTranslations.id(StripeReqInfoType.Owner),
    ssn_last_4: incompleteTranslations.taxInformation(StripeReqInfoType.Owner),
    phone: incompleteTranslations.phone(StripeReqInfoType.Owner),
    relationship: {
      title: incompleteTranslations.title(StripeReqInfoType.Owner),
      percent_ownership: incompleteTranslations.ownershipPercentage(StripeReqInfoType.Owner),
    },
    verification: {
      document: incompleteTranslations.document(StripeReqInfoType.Owner),
    },
  },
  representative: {
    address: getAddress(StripeReqInfoType.Representative),
    dob: getDob(StripeReqInfoType.Representative),
    email: incompleteTranslations.email(StripeReqInfoType.Representative),
    first_name: incompleteTranslations.name(StripeReqInfoType.Representative),
    last_name: incompleteTranslations.name(StripeReqInfoType.Representative),
    id_number: incompleteTranslations.id(StripeReqInfoType.Representative),
    ssn_last_4: incompleteTranslations.taxInformation(StripeReqInfoType.Representative),
    phone: incompleteTranslations.phone(StripeReqInfoType.Representative),
    relationship: {
      title: incompleteTranslations.title(StripeReqInfoType.Representative),
      executive: incompleteTranslations.relationship(StripeReqInfoType.Representative),
    },
    verification: {
      document: incompleteTranslations.document(StripeReqInfoType.Representative),
    },
  },
  individual: {
    address: getAddress(StripeReqInfoType.Default),
    dob: getDob(StripeReqInfoType.Default),
    email: incompleteTranslations.email(StripeReqInfoType.Default),
    first_name: incompleteTranslations.name(StripeReqInfoType.Default),
    last_name: incompleteTranslations.name(StripeReqInfoType.Default),
    id_number: incompleteTranslations.id(StripeReqInfoType.Default),
    ssn_last_4: incompleteTranslations.taxInformation(StripeReqInfoType.Default),
    phone: incompleteTranslations.phone(StripeReqInfoType.Default),
    relationship: {
      title: incompleteTranslations.title(StripeReqInfoType.Representative),
    },
    verification: {
      document: incompleteTranslations.document(StripeReqInfoType.Default),
    },
  },
  tos_acceptance: {
    date: incompleteTranslations.tos,
    ip: incompleteTranslations.tos,
  },
};
