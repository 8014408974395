import { Input, Tag } from 'antd';
import styled from 'styled-components';

export const FlexBox = styled.div({
  display: 'flex',
});

export const FilterTag = styled(Tag)({
  margin: 'auto 2px',
});

export const FilterText = styled.div({
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
});

export const FilterField = styled.div({
  width: '100%',
  minWidth: '100px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
});

export const FilterWrapper = styled.div`
  margin-left: 20px;
  display: flex;

  @media (max-width: 1024px) {
    margin-top: 10px;
  }
`;

export const InputSearchStyle = styled(Input.Search)`
  margin: 14px 14px;
  width: 300px;
`;

export const HeaderSpan = styled.span`
  font-size: 16px;
  font-weight: normal;
`;
