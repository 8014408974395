import { useForm } from 'antd/lib/form/Form';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { translations } from '../../../constants/translations';
import { Store } from 'antd/lib/form/interface';
import { RecordsContext } from '../../Records/store/state';
import { selectRecordAction, setHasUnsavedChangesAction } from '../../Records/store/actions';
import { SaveSpinnerAndNavigationWarning } from '../../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { selectHasUnsavedChanges } from '../../Records/store/selectors';
import { Note } from '../../../graph/types';
import dayjs from 'dayjs';
import { NoteCardContent } from './NoteCardContent';
import { RecordItem, sortRecordsByDate } from '../../Records/recordUtils';
import {
  getRecordFromNote,
  getUpdateNoteUpsert,
  getVoidNoteUpsert,
  NoteUpsertValues,
  useGetNoteRefData,
  useSendNoteUpsertToBackend,
} from './noteUtils';
import { EditAndDeleteIcons, getButtonRowForExistingRecord } from '../ButtonRow';
import { Card, Form } from 'antd';
import { CloseButton } from '../../CloseButton/CloseButton';
import { useLocation } from 'react-router-dom';
import { getRefDataWithVoid, mapToRefData } from '../../../util/refDataUtil';
import { ListItemHeader } from '../../Records/FullscreenRecords/ListItemHeader';
import { ListItemFooter } from '../../Records/FullscreenRecords/ListItemFooter';

interface RecordSingleViewNoteProps extends PropsWithChildren<unknown> {
  record?: RecordItem;
  recordId: string;
  note: Note;
  linkedIcon?: React.ReactNode;
  onClose: () => void;
  contactId?: string;
  patientId?: string;
  setCurrentRecords: (setter: (currentRecords: RecordItem[]) => RecordItem[]) => void;
  setEditing?: (editing: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
  fullScreenMode?: boolean;
}

export const RecordSingleViewNote: React.FC<RecordSingleViewNoteProps> = ({
  record,
  recordId,
  note,
  onClose,
  contactId,
  patientId,
  linkedIcon,
  setCurrentRecords,
  setEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
  fullScreenMode,
}: RecordSingleViewNoteProps) => {
  const location = useLocation();
  const { state, dispatch } = useContext(RecordsContext);
  const hasUnsavedChanges = selectHasUnsavedChanges(state);
  const [form] = useForm();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [textContent, setTextContent] = useState(note.value);
  const [plainTextContent, setPlainTextContent] = useState(note.preview);
  const sendNoteUpsertToBackend = useSendNoteUpsertToBackend({ noteId: note.id });
  const { noteRefData } = useGetNoteRefData(!!patientId);
  const refDataWithVoid = getRefDataWithVoid(mapToRefData(note), noteRefData);

  const setValuesFromNote = useCallback(() => {
    if (!hasUnsavedChanges) {
      form.setFields([
        { name: 'date', value: dayjs(note.date) },
        { name: 'type_id', value: note.type_id },
      ]);

      setTextContent(note.value);
      form.resetFields(['quick_note']);
      setEditMode(false);
    }
  }, [form, note, hasUnsavedChanges]);

  const onReset = useCallback(() => {
    setValuesFromNote();
    dispatch(setHasUnsavedChangesAction(false));
  }, [dispatch, setValuesFromNote]);

  useEffect(() => {
    if (!hasUnsavedChanges) {
      onReset();
    }
  }, [location, onReset, hasUnsavedChanges]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      onReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, onReset, setShouldResetOnTabChange]);

  useEffect(() => {
    setValuesFromNote();
  }, [recordId, setValuesFromNote]);

  const onFinishBackendRequest = () => {
    dispatch(setHasUnsavedChangesAction(false));
    setEditing?.(false);
    dispatch(selectRecordAction(undefined));
    setIsSaving(false);
    setEditMode(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    const haveFormFieldsChanged = !dayjs(note.date).isSame(allValues.date, 'day') || allValues.type_id !== note.type_id;
    const hasUnsavedChanges = haveFormFieldsChanged || editMode;
    if (!fullScreenMode) {
      dispatch(setHasUnsavedChangesAction(hasUnsavedChanges));
    }
    setEditing?.(hasUnsavedChanges);
  };

  const activateEditMode = () => {
    setEditMode(true);
    if (!fullScreenMode) {
      dispatch(setHasUnsavedChangesAction(true));
    }
    setEditing?.(true);
    return true;
  };

  const handleDeleteRecord = async () => {
    setIsSaving(true);
    await sendNoteUpsertToBackend(
      getVoidNoteUpsert(note),
      onFinishBackendRequest,
      translations.shared.deleteSuccessMessage
    );
    setCurrentRecords((currentRecords) => currentRecords.filter((record) => record.recordId !== recordId));
  };

  const handleSave = async (values: Store) => {
    setIsSaving(true);

    const noteValues: NoteUpsertValues = {
      type_id: values.type_id,
      date: values.date,
      textContent,
      plainTextContent,
    };

    const upsert = getUpdateNoteUpsert(note, noteValues, patientId, contactId);

    const updatedNote = await sendNoteUpsertToBackend(
      upsert,
      onFinishBackendRequest,
      translations.shared.saveSuccessMessage
    );
    if (updatedNote) {
      setCurrentRecords((currentRecords) =>
        sortRecordsByDate([
          ...currentRecords.filter((record) => record.recordId !== recordId),
          getRecordFromNote(updatedNote),
        ])
      );
    }
  };

  const buttonRow = getButtonRowForExistingRecord(
    fullScreenMode ? editMode : hasUnsavedChanges,
    onReset,
    translations.recordList.note,
    activateEditMode,
    handleDeleteRecord
  );

  const noteCardContent = () => (
    <NoteCardContent
      editMode={editMode}
      activateEditMode={activateEditMode}
      onClose={onClose}
      patientId={patientId}
      contactId={contactId}
      textContent={textContent}
      setTextContent={(text, plainText) => {
        setTextContent(text);
        setPlainTextContent(plainText);
      }}
      buttonRow={buttonRow}
      noteRefData={refDataWithVoid}
      linkedIcon={linkedIcon}
      form={form}
      fullScreenMode={fullScreenMode}
    />
  );

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasUnsavedChanges}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning('')}
    >
      <Form
        form={form}
        onFinish={handleSave}
        onValuesChange={handleValueChange}
        initialValues={{
          date: dayjs(note.date),
        }}
        autoComplete='off'
      >
        {fullScreenMode ? (
          <>
            {/* TODO: see if we can remove record prop and just use note */}
            {record ? (
              <ListItemHeader
                item={record}
                editableOptions={
                  <EditAndDeleteIcons
                    typeName={translations.recordList.note}
                    activateEditMode={activateEditMode}
                    handleDeleteRecord={handleDeleteRecord}
                  />
                }
              />
            ) : null}
            <Card size='small' bordered={false}>
              {noteCardContent()}
              <ListItemFooter item={record} />
            </Card>
          </>
        ) : (
          <Card title={note.type_name} extra={<CloseButton onClick={onClose} />}>
            {noteCardContent()}
          </Card>
        )}
      </Form>
    </SaveSpinnerAndNavigationWarning>
  );
};
