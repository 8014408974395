import { translations } from '../translations';

export enum JobStatusNameKey {
  ServiceBatch = 'JOB_SERVICEBATCH',
  StatementRun = 'JOB_STATEMENTRUN',
  StatementPrint = 'JOB_STATEMENTPRINT',
  JobComplete = 'JOB_COMPLETE',
  JobInvoiceCreate = 'JOB_INVOICECREATE',
  JobInvoiceCharge = 'JOB_INVOICECHARGE',
  JobInvoiceFinalize = 'JOB_INVOICEFINALIZE',
  JobPatientSync = 'JOB_PATIENTSYNC',
  JobPatientSyncInvoice = 'JOB_PATIENTSYNCINV',
  ReminderBatch = 'JOB_REMINDERBATCH',
  ReminderPrint = 'JOB_REMINDERPRINT',
}

export const JobStatusTranslations: { [nameKey in JobStatusNameKey]: string } = {
  [JobStatusNameKey.ServiceBatch]: translations.jobStatuses.serviceBatch,
  [JobStatusNameKey.StatementRun]: translations.jobStatuses.statementRun,
  [JobStatusNameKey.StatementPrint]: translations.jobStatuses.statementPrint,
  [JobStatusNameKey.JobComplete]: translations.jobStatuses.jobComplete,
  [JobStatusNameKey.JobInvoiceCreate]: translations.jobStatuses.jobInvoiceCreate,
  [JobStatusNameKey.JobInvoiceCharge]: translations.jobStatuses.jobInvoiceCharge,
  [JobStatusNameKey.JobInvoiceFinalize]: translations.jobStatuses.jobInvoiceFinalize,
  [JobStatusNameKey.JobPatientSync]: translations.jobStatuses.jobPatientSync,
  [JobStatusNameKey.JobPatientSyncInvoice]: translations.jobStatuses.jobPatientSyncInvoice,
  [JobStatusNameKey.ReminderBatch]: translations.jobStatuses.reminderBatch,
  [JobStatusNameKey.ReminderPrint]: translations.jobStatuses.reminderPrint,
};
