import { LDFlagNames } from '../constants/launchDarkly';
import { UserPermissionNameKey } from '../constants/referenceData/userPermissionReferenceData';
import { getRouteWithOrganizationId, mainPageSiderRoutes } from '../constants/routes';
import { useUserContext } from '../contexts/user/state';
import { getUserPermissions } from '../util/userPermissionUtil';
import { useUserPermissions } from './permissionsHooks';
import { useLDFlag } from './useLDHooks';

export const useRedirectRoute = (organizationId: string) => {
  const {
    state: { user },
  } = useUserContext();
  const superUser = useUserPermissions();
  const showDashboard = useLDFlag(LDFlagNames.Dashboard);
  const userPermissions = getUserPermissions(organizationId, user, superUser);
  const shouldBeDashboardRoot = userPermissions[UserPermissionNameKey.PERM_DSB_ALL].allowed;

  const route = getRouteWithOrganizationId(
    showDashboard && shouldBeDashboardRoot
      ? mainPageSiderRoutes.dashboard.path!
      : mainPageSiderRoutes.doctorOffice.path!,
    organizationId
  );
  return route;
};
