import { FetchResult, OperationVariables } from '@apollo/client/core';
import { MutationFunctionOptions, MutationHookOptions, MutationTuple } from '@apollo/client/react/types/types';
import { useCallback } from 'react';
import { RxDocument } from 'rxdb';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { Mutation } from '../../graph/types';

export const useMutationWithMessages = <TData = any, TVariables = OperationVariables>(
  useMutationHook: () => MutationTuple<TData, TVariables>
) => {
  return useMutationWithParameterWithMessages(useMutationHook, undefined);
};

export const useMutationWithParameterWithMessages = <TParam, TData = any, TVariables = OperationVariables>(
  useMutationHook: (
    mutationHookParameter: TParam,
    options?: MutationHookOptions<TData, TVariables>
  ) => MutationTuple<TData, TVariables>,
  mutationHookParameter: TParam
) => {
  const [mutationFunction] = useMutationHook(mutationHookParameter);

  const mutationFunctionWithMessages = useCallback(
    async ({
      onSuccess,
      onError,
      options,
      successMessage,
      shouldShowErrorMessage = true,
    }: {
      options?: MutationFunctionOptions<TData, TVariables>;
      successMessage?: string | ((data?: TData | null) => string | null | undefined);
      onSuccess?: (data?: TData | null) => void;
      onError?: (e?: any) => void;
      shouldShowErrorMessage?: boolean;
    }) => {
      try {
        const { data } = await mutationFunction(options);
        onSuccess?.(data);
        if (typeof successMessage === 'function') {
          showSuccessMessage(successMessage(data) || translations.shared.saveSuccessMessage);
        } else {
          showSuccessMessage(successMessage || translations.shared.saveSuccessMessage);
        }
        return { data };
      } catch (e) {
        onError?.(e);
        if (shouldShowErrorMessage) {
          showErrorMessage(getSaveErrorMessageFromError(e));
        }
        return {};
      }
    },
    [mutationFunction]
  );

  return [mutationFunctionWithMessages];
};

export const getSaveErrorMessageFromError = (error: any) => {
  if (error.message) {
    const tempMessage =
      translations.shared.errorMessage[error.message as keyof typeof translations.shared.errorMessage];
    if (tempMessage) {
      return tempMessage;
    }
    return error.message;
  }
  return translations.shared.saveErrorMessage;
};

export const useDeleteMutationWithMessages = (
  useDeleteMutationHook: (
    organizationId: string
  ) => (entityId: string) => Promise<FetchResult<Mutation>> | Promise<RxDocument<any>> | null | undefined,
  organizationId: string
) => {
  const deleteFunction = useDeleteMutationHook(organizationId);
  return useCallback(
    async ({
      entityId,
      onSuccess,
      successMessage,
      onError,
    }: {
      entityId: string;
      successMessage?: string;
      onSuccess?: () => void;
      onError?: (e: any) => void;
    }) => {
      try {
        await deleteFunction?.(entityId);
        await onSuccess?.();
        showSuccessMessage(successMessage || translations.shared.deleteSuccessMessage);
      } catch (e) {
        if (onError) {
          onError(e);
        } else {
          showErrorMessage((e as Error).message ?? translations.shared.deleteErrorMessage);
        }
      }
    },
    [deleteFunction]
  );
};
