import { useMemo } from 'react';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { displayAsDate } from '../../../constants/formats';
import {
  OrganizationSubscriptionProperty,
  organizationSubscriptionPropertyNames,
} from '../../../constants/propertyNames';
import { SubscriptionIntervalKey, subscriptionIntervalLabel, translations } from '../../../constants/translations';
import { OrganizationList } from '../../../graph/types';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import {
  generalFilteringAndSortingSettings,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
  stringCompareFunction,
  filterFunction,
  getNumberCompareFunctionFor,
} from '../../../util/filterAndSorting';

const organizationIdKey = 'id';
const organizationNameKey = 'name';
const getFrequencyValue = (organization: OrganizationList) =>
  subscriptionIntervalLabel[organization.frequency as SubscriptionIntervalKey];

export const useOrganizationSubscriptionColumns = (): Record<
  OrganizationSubscriptionProperty | 'id' | 'organization' | 'action',
  CustomColumnType<OrganizationList>
> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(
    () => ({
      id: {
        title: translations.adminSubscription.fields.id,
        key: 'id',
        dataIndex: organizationIdKey,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.adminSubscription.fields.id),
        onFilter: getOnFilterFunctionFor(organizationIdKey),
        sorter: getNumberCompareFunctionFor(organizationIdKey),
      },
      organization: {
        title: translations.adminSubscription.fields.organization,
        key: 'organization',
        dataIndex: organizationNameKey,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.adminSubscription.fields.organization
        ),
        onFilter: getOnFilterFunctionFor(organizationNameKey),
        sorter: getStringCompareFunctionFor(organizationNameKey),
      },
      start_date: {
        title: translations.adminSubscription.fields.startDate,
        key: organizationSubscriptionPropertyNames.start_date,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.adminSubscription.fields.startDate
        ),
        render: (record) => record.start_date && displayAsDate(record.start_date, dateFormat),
        onFilter: (value, record) => filterFunction(value, record.start_date),
        sorter: (a, b) => stringCompareFunction(a.start_date, b.start_date),
      },
      last_billed_date: {
        title: translations.adminSubscription.fields.lastBilled,
        key: organizationSubscriptionPropertyNames.last_billed_date,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.adminSubscription.fields.lastBilled
        ),
        render: (record) => record.last_billed_date && displayAsDate(record.last_billed_date, dateFormat),
        onFilter: (value, record) => filterFunction(value, record.last_billed_date),
        sorter: (a, b) => stringCompareFunction(a.last_billed_date, b.last_billed_date),
      },
      next_bill_date: {
        title: translations.adminSubscription.fields.nextBillingDate,
        key: organizationSubscriptionPropertyNames.next_bill_date,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.adminSubscription.fields.nextBillingDate
        ),
        render: (record) => record.next_bill_date && displayAsDate(record.next_bill_date, dateFormat),
        onFilter: (value, record) => filterFunction(value, record.next_bill_date),
        sorter: (a, b) => stringCompareFunction(a.next_bill_date, b.next_bill_date),
      },
      frequency: {
        title: translations.adminSubscription.fields.billingInterval,
        key: organizationSubscriptionPropertyNames.frequency,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.adminSubscription.fields.billingInterval
        ),
        render: getFrequencyValue,
        onFilter: (value, record) => filterFunction(value, getFrequencyValue(record)),
        sorter: (a, b) => stringCompareFunction(getFrequencyValue(a), getFrequencyValue(b)),
      },
      action: {
        title: translations.adminSubscription.fields.action,
      },
    }),
    [dateFormat]
  );
};
