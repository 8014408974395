import React, { ReactElement } from 'react';
import { TabsProps } from 'antd/lib/tabs';
import { omit } from 'lodash';
import { Tabs } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';

interface RouteProps {
  path: string;
  exact?: boolean;
  strict?: boolean;
}

export type RouteType = string | RouteProps;

export interface TabsWithRoutingProps extends TabsProps {
  routePerTabKey: { [tabKey: string]: RouteType };
  onClickPerTabKey?: { [tabKey: string]: () => void };
  noTabSelectedContent?: ReactElement | string;
  forcedRouteIfNoTabSelected?: RouteType;
  onTabClick?: (key: string) => void;
}

const getRoutePath = (route: RouteType) => {
  return (route as RouteProps)?.path ?? (route as string);
};

const TabsWithRouting: React.FC<TabsWithRoutingProps> = (props) => {
  const location = useLocation();
  const { navigateTo } = useNavigationToRoute();
  const tabsProps: TabsProps = omit(props, [
    'routePerTabKey',
    'noTabSelectedContent',
    'forcedRouteIfNoTabSelected',
    'onClickPerTabKey',
    'onTabClick',
    'children',
  ]);
  const activeTabKey = Object.keys(props.routePerTabKey).find((tabKey) =>
    matchPath(location.pathname, props.routePerTabKey[tabKey])
  );
  if (
    !activeTabKey &&
    props.forcedRouteIfNoTabSelected &&
    !matchPath(location.pathname, props.forcedRouteIfNoTabSelected)
  ) {
    navigateTo(getRoutePath(props.forcedRouteIfNoTabSelected));
  }
  return (
    <>
      <Tabs
        {...tabsProps}
        activeKey={activeTabKey ?? ''}
        onTabClick={(key: string) => {
          if (props.onTabClick) {
            props.onTabClick(key);
            return;
          }
          const onClick = props.onClickPerTabKey?.[key];
          if (onClick) {
            onClick();
          } else {
            navigateTo(getRoutePath(props.routePerTabKey[key]));
          }
        }}
      >
        {props.children}
      </Tabs>
      {!activeTabKey ? props.noTabSelectedContent : null}
    </>
  );
};

export default TabsWithRouting;
