import React from 'react';
import { FormRule } from 'antd';
import { getRequiredRule } from '../util/forms';
import { translations } from './translations';
import { InfoUpsert, PatientServiceValidation, Service } from '../graph/types';
import { HisaFieldsRequirement } from '../components/ServiceRendered/HisaRequirementsModal/HisaRequirementsContent';

export enum InputType {
  Input = 'input',
  Select = 'select',
  Checkbox = 'checkbox',
  Switch = 'switch',
}

export type HisaConfig = {
  name: string;
  label: string;
  placeholder?: string;
  rules: FormRule[];
  inputtype?: InputType;
  component?: React.FC;
  default?: string;
  nameKey: HisaRequirementsNameKey;
  typeId: HisaRequirementsTypeId;
  valuePropName?: string;
  onUpdate?: (value: string) => void;
  testid: string;
};

// Table is ref_service_rendered_info_type
export enum HisaRequirementsNameKey {
  Results = 'RESULTS',
  LimbTreated = 'SLIMB_TREATED',
  CondTreated = 'COND_TREATED',
  MedicalName = 'MEDICAL_NAME',
  TestsDescription = 'TESTS_DESCR',
  Time = 'TIME',
  TreatmentType = 'TREATMENT_TP',
  Description = 'DESCRIPTION',
  VaccineName = 'VACCINE_NAME',
  Dosage = 'DOSAGE',
  DrugName = 'DRUG_NAME',
  Structure = 'STRUCTURE',
  Modality = 'MODALITY',
}

export const HisaNameKeys = Object.values(HisaRequirementsNameKey);

export enum HisaRequirementsTypeId {
  Results = '4',
  LimbTreated = '1',
  CondTreated = '2',
  MedicalName = '3',
  TestsDescription = '5',
  Time = '6',
  TreatmentType = '7',
  Description = '8',
  VaccineName = '9',
  Dosage = '10',
  DrugName = '11',
  Structure = '12',
  Modality = '13',
}

export enum HisaFormField {
  CondTreated = 'hisaConditionTreated',
  Description = 'hisaDescription',
  DrugName = 'hisaDrugName',
  LimbTreated = 'hisaLimbTreated',
  MedicalName = 'hisaMedicalName',
  Dosage = 'hisaRequiresDosage',
  Results = 'hisaResult',
  TreatmentType = 'hisaTreatmentType',
  VaccineName = 'hisaVaccineName',
  TestsAndDiagnostics = 'hisaTestsAndDiagnostics',
  Time = 'hisaTime',
  Structure = 'hisaStructure',
  Modality = 'hisaModality',
}

export type HisaFormFieldsValues = {
  [K in HisaFormField]?: string;
};

export type HisaRequirementsProperties = keyof Pick<
  PatientServiceValidation,
  | 'requires_results'
  | 'requires_limb_treated'
  | 'requires_dosage'
  | 'requires_condition_treated'
  | 'requires_tests_and_diagnostics'
  | 'requires_treatment_type'
  | 'requires_time'
  | 'requires_medical_name'
  | 'requires_drug_name'
  | 'requires_description'
  | 'requires_vaccine_name'
  | 'requires_structure'
  | 'requires_modality'
>;

export const hisaValidationPropertyNames: Readonly<{ [K in HisaRequirementsProperties]: K }> = {
  requires_results: 'requires_results',
  requires_limb_treated: 'requires_limb_treated',
  requires_dosage: 'requires_dosage',
  requires_condition_treated: 'requires_condition_treated',
  requires_tests_and_diagnostics: 'requires_tests_and_diagnostics',
  requires_treatment_type: 'requires_treatment_type',
  requires_time: 'requires_time',
  requires_medical_name: 'requires_medical_name',
  requires_drug_name: 'requires_drug_name',
  requires_description: 'requires_description',
  requires_vaccine_name: 'requires_vaccine_name',
  requires_structure: 'requires_structure',
  requires_modality: 'requires_modality',
};

export enum HisaNameKeyFormFieldMap {
  'RESULTS' = HisaFormField.Results,
  'SLIMB_TREATED' = HisaFormField.LimbTreated,
  'COND_TREATED' = HisaFormField.CondTreated,
  'MEDICAL_NAME' = HisaFormField.MedicalName,
  'TESTS_DESCR' = HisaFormField.TestsAndDiagnostics,
  'TIME' = HisaFormField.Time,
  'TREATMENT_TP' = HisaFormField.TreatmentType,
  'DESCRIPTION' = HisaFormField.Description,
  'VACCINE_NAME' = HisaFormField.VaccineName,
  'DOSAGE' = HisaFormField.Dosage,
  'DRUG_NAME' = HisaFormField.DrugName,
  'STRUCTURE' = HisaFormField.Structure,
  'MODALITY' = HisaFormField.Modality,
}

export const hisaSelectFields: any = {
  LIMB_T_LF: translations.shared.hisaRequirementsModal.selectOptions.LF,
  LIMB_T_RF: translations.shared.hisaRequirementsModal.selectOptions.RF,
  LIMB_T_BF: translations.shared.hisaRequirementsModal.selectOptions.BF,
  LIMB_T_LH: translations.shared.hisaRequirementsModal.selectOptions.LH,
  LIMB_T_RH: translations.shared.hisaRequirementsModal.selectOptions.RH,
  LIMB_T_BH: translations.shared.hisaRequirementsModal.selectOptions.BH,
  LIMB_T_ALL4: translations.shared.hisaRequirementsModal.selectOptions.All4,
  LIMB_T_NA: translations.shared.hisaRequirementsModal.selectOptions.NA,
  MODALITY_BS: translations.shared.hisaRequirementsModal.selectOptions.BoneScan,
  MODALITY_CT: translations.shared.hisaRequirementsModal.selectOptions.CT,
  MODALITY_MRI: translations.shared.hisaRequirementsModal.selectOptions.MRI,
  MODALITY_OTHER: translations.shared.hisaRequirementsModal.selectOptions.Other,
  MODALITY_PET: translations.shared.hisaRequirementsModal.selectOptions.PET,
  MODALITY_RG: translations.shared.hisaRequirementsModal.selectOptions.Radiograph,
  MODALITY_US: translations.shared.hisaRequirementsModal.selectOptions.Ultrasound,
};

export const selectableInputTypes: HisaRequirementsNameKey[] = [
  HisaRequirementsNameKey.LimbTreated,
  HisaRequirementsNameKey.Modality,
];

export const hisaFormFieldsConfig: {
  [K in HisaFormField]: { nameKey: HisaRequirementsNameKey; typeId: HisaRequirementsTypeId };
} = {
  [HisaFormField.CondTreated]: {
    nameKey: HisaRequirementsNameKey.CondTreated,
    typeId: HisaRequirementsTypeId.CondTreated,
  },
  [HisaFormField.Description]: {
    nameKey: HisaRequirementsNameKey.Description,
    typeId: HisaRequirementsTypeId.Description,
  },
  [HisaFormField.DrugName]: { nameKey: HisaRequirementsNameKey.DrugName, typeId: HisaRequirementsTypeId.DrugName },
  [HisaFormField.LimbTreated]: {
    nameKey: HisaRequirementsNameKey.LimbTreated,
    typeId: HisaRequirementsTypeId.LimbTreated,
  },
  [HisaFormField.MedicalName]: {
    nameKey: HisaRequirementsNameKey.MedicalName,
    typeId: HisaRequirementsTypeId.MedicalName,
  },
  [HisaFormField.Dosage]: { nameKey: HisaRequirementsNameKey.Dosage, typeId: HisaRequirementsTypeId.Dosage },
  [HisaFormField.Results]: { nameKey: HisaRequirementsNameKey.Results, typeId: HisaRequirementsTypeId.Results },
  [HisaFormField.TreatmentType]: {
    nameKey: HisaRequirementsNameKey.TreatmentType,
    typeId: HisaRequirementsTypeId.TreatmentType,
  },
  [HisaFormField.VaccineName]: {
    nameKey: HisaRequirementsNameKey.VaccineName,
    typeId: HisaRequirementsTypeId.VaccineName,
  },
  [HisaFormField.TestsAndDiagnostics]: {
    nameKey: HisaRequirementsNameKey.TestsDescription,
    typeId: HisaRequirementsTypeId.TestsDescription,
  },
  [HisaFormField.Time]: {
    nameKey: HisaRequirementsNameKey.Time,
    typeId: HisaRequirementsTypeId.Time,
  },
  [HisaFormField.Structure]: {
    nameKey: HisaRequirementsNameKey.Structure,
    typeId: HisaRequirementsTypeId.Structure,
  },
  [HisaFormField.Modality]: {
    nameKey: HisaRequirementsNameKey.Modality,
    typeId: HisaRequirementsTypeId.Modality,
  },
};

const detailsTranslations = translations.patientPage.details;

export const hisaRequirementsConfig: {
  [nameKey in keyof HisaFieldsRequirement]:
    | HisaConfig
    | ((service?: Service | string, setConditionTreated?: (condition: string) => void) => HisaConfig);
} = {
  requiresResults: {
    name: HisaFormField.Results,
    label: detailsTranslations.results,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.results, 'enter'),
    rules: [getRequiredRule(detailsTranslations.results)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.Results,
    testid: HisaRequirementsNameKey.Results,
    typeId: HisaRequirementsTypeId.Results,
  },
  requiresLimbTreated: {
    name: HisaFormField.LimbTreated,
    label: detailsTranslations.limbTreated,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.limbTreated, 'enter'),
    rules: [getRequiredRule(detailsTranslations.limbTreated)],
    inputtype: InputType.Select,
    nameKey: HisaRequirementsNameKey.LimbTreated,
    testid: HisaRequirementsNameKey.LimbTreated,
    typeId: HisaRequirementsTypeId.LimbTreated,
  },
  requiresDosage: (service) => {
    let initialValue = '';
    if (typeof service !== 'string') {
      initialValue = service?.unit_name ?? '';
    }

    return {
      name: HisaFormField.Dosage,
      label: detailsTranslations.dosage,
      placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.dosage, 'enter'),
      rules: [getRequiredRule(detailsTranslations.dosage)],
      inputtype: InputType.Input,
      initialValue,
      nameKey: HisaRequirementsNameKey.Dosage,
      testid: HisaRequirementsNameKey.Dosage,
      typeId: HisaRequirementsTypeId.Dosage,
    };
  },
  requiresConditionTreated: (conditionTreated, setConditionTreated) => {
    let initialValue = '';
    if (typeof conditionTreated === 'string') {
      initialValue = conditionTreated;
    }
    return {
      name: HisaFormField.CondTreated,
      label: detailsTranslations.conditionTreated,
      placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.conditionTreated, 'enter'),
      rules: [getRequiredRule(detailsTranslations.conditionTreated)],
      inputtype: InputType.Input,
      initialValue,
      nameKey: HisaRequirementsNameKey.CondTreated,
      testid: HisaRequirementsNameKey.CondTreated,
      typeId: HisaRequirementsTypeId.CondTreated,
      onUpdate: setConditionTreated,
    };
  },
  requiresTestsAndDiagnostics: {
    name: HisaFormField.TestsAndDiagnostics,
    label: detailsTranslations.testsAndDiagnostics,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.testsAndDiagnostics, 'enter'),
    rules: [getRequiredRule(detailsTranslations.testsAndDiagnostics)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.TestsDescription,
    testid: HisaRequirementsNameKey.TestsDescription,
    typeId: HisaRequirementsTypeId.TestsDescription,
  },
  requiresTreatmentType: {
    name: HisaFormField.TreatmentType,
    label: detailsTranslations.treatmentType,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.treatmentType, 'enter'),
    rules: [getRequiredRule(detailsTranslations.treatmentType)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.TreatmentType,
    testid: HisaRequirementsNameKey.TreatmentType,
    typeId: HisaRequirementsTypeId.TreatmentType,
  },
  requiresTime: {
    name: HisaFormField.Time,
    label: detailsTranslations.time,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.time, 'enter'),
    rules: [getRequiredRule(detailsTranslations.time)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.Time,
    testid: HisaRequirementsNameKey.Time,
    typeId: HisaRequirementsTypeId.Time,
  },
  requiresMedicalName: {
    name: HisaFormField.MedicalName,
    label: detailsTranslations.medicalName,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.medicalName, 'enter'),
    rules: [getRequiredRule(detailsTranslations.medicalName)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.MedicalName,
    testid: HisaRequirementsNameKey.MedicalName,
    typeId: HisaRequirementsTypeId.MedicalName,
  },
  requiresDrugName: (service) => {
    let initialValue = '';
    if (typeof service !== 'string') {
      initialValue = service?.name ?? '';
    }
    return {
      name: HisaFormField.DrugName,
      label: detailsTranslations.drugName,
      placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.drugName, 'enter'),
      rules: [getRequiredRule(detailsTranslations.drugName)],
      inputtype: InputType.Input,
      initialValue,
      nameKey: HisaRequirementsNameKey.DrugName,
      testid: HisaRequirementsNameKey.DrugName,
      typeId: HisaRequirementsTypeId.DrugName,
    };
  },
  requiresDescription: (service) => {
    let initialValue = '';
    if (typeof service !== 'string') {
      initialValue = service?.name ?? '';
    }
    return {
      name: HisaFormField.Description,
      label: detailsTranslations.description,
      placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.description, 'enter'),
      rules: [getRequiredRule(detailsTranslations.description)],
      inputtype: InputType.Input,
      initialValue,
      nameKey: HisaRequirementsNameKey.Description,
      testid: HisaRequirementsNameKey.Description,
      typeId: HisaRequirementsTypeId.Description,
    };
  },
  requiresVaccineName: (service) => {
    let initialValue = '';
    if (typeof service !== 'string') {
      initialValue = service?.name ?? '';
    }
    return {
      name: HisaFormField.VaccineName,
      label: detailsTranslations.vaccineName,
      placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.vaccineName, 'enter'),
      rules: [getRequiredRule(detailsTranslations.vaccineName)],
      inputtype: InputType.Input,
      initialValue,
      nameKey: HisaRequirementsNameKey.VaccineName,
      testid: HisaRequirementsNameKey.VaccineName,
      typeId: HisaRequirementsTypeId.VaccineName,
    };
  },
  requiresStructure: {
    name: HisaFormField.Structure,
    label: detailsTranslations.structure,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.structure, 'enter'),
    rules: [getRequiredRule(detailsTranslations.structure)],
    inputtype: InputType.Input,
    nameKey: HisaRequirementsNameKey.Structure,
    testid: HisaRequirementsNameKey.Structure,
    typeId: HisaRequirementsTypeId.Structure,
  },
  requiresModality: {
    name: HisaFormField.Modality,
    label: detailsTranslations.modality,
    placeholder: detailsTranslations.hisaPlaceholder(detailsTranslations.modality, 'enter'),
    rules: [getRequiredRule(detailsTranslations.modality)],
    inputtype: InputType.Select,
    nameKey: HisaRequirementsNameKey.Modality,
    testid: HisaRequirementsNameKey.Modality,
    typeId: HisaRequirementsTypeId.Modality,
  },
};

export const buildHisaFormValuesFromUpsert = (info: InfoUpsert[]) => {
  const hisaFormValues: HisaFormFieldsValues = {};
  info.forEach((infoUpsert) => {
    if (
      infoUpsert.record &&
      Object.values(HisaRequirementsTypeId).includes(infoUpsert.record.type_id as HisaRequirementsTypeId)
    ) {
      const nameKey = Object.keys(HisaRequirementsTypeId).find(
        (key) => HisaRequirementsTypeId[key as keyof typeof HisaRequirementsNameKey] === infoUpsert.record?.type_id
      );
      const formField = HisaFormField[nameKey as keyof typeof HisaFormField];
      hisaFormValues[formField] = infoUpsert.record.value;
    }
  });
  return hisaFormValues;
};
