import React from 'react';
import { DatePicker, Form, Input } from 'antd';
import { translations } from '../../constants/translations';
import styled from 'styled-components';
import { getRequiredRule } from '../../util/forms';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import dayjs from 'dayjs';

export const reminderFormTestId = 'reminderFormTestId';
export const reminderFormDateTestId = 'reminderFormDateTestId';

export const TitleDiv = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export interface ReminderFormValues {
  reminderName?: string;
  date?: dayjs.Dayjs;
  note?: string;
}

interface ReminderFormFieldsProps {
  visible?: boolean;
  title?: string;
  autofocus?: boolean;
}

export const ReminderFormFields: React.FC<ReminderFormFieldsProps> = ({ visible, title, autofocus }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return visible ? (
    <>
      {title && <TitleDiv>{title}</TitleDiv>}
      <MaxLengthFormItem
        rules={[getRequiredRule(translations.shared.reminderModal.fields.reminderName)]}
        label={translations.shared.reminderModal.fields.reminderName}
        name='reminderName'
        maxLength={50}
      >
        <Input autoFocus={autofocus} />
      </MaxLengthFormItem>
      <Form.Item
        rules={[getRequiredRule(translations.shared.reminderModal.fields.date)]}
        label={translations.shared.reminderModal.fields.date}
        name='date'
        data-testid={reminderFormDateTestId}
      >
        <DatePicker format={dateFormat} />
      </Form.Item>
      <Form.Item label={translations.shared.reminderModal.fields.note} name='note'>
        <Input />
      </Form.Item>
    </>
  ) : null;
};
