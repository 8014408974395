import { Button, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { translations } from '../../../constants/translations';
import { getRequiredRule } from '../../../util/forms';
import { RegistrationButton } from '../RegistrationButton';
import { showSuccessMessage } from '../../../components/Notification/notificationUtil';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

const passwordFieldName = 'password';
const codeFieldName = 'code';

interface ConfirmationProps {
  setIsSaving: (value: boolean) => void;
  goToNextStep: () => void;
  email: string;
  code?: string | null;
  password?: string;
}

export const Confirmation: React.FC<ConfirmationProps> = ({ goToNextStep, code, email, password, setIsSaving }) => {
  const [form] = useForm();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const checkUserConfirmation = useCallback(async () => {
    if (password) {
      setIsSaving(true);
      try {
        await Auth.signIn(email, password);
        const user = await Auth.currentUserInfo();
        if (user.attributes.email_verified) {
          goToNextStep();
        }
      } catch (_) {}
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkUserConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmSignUp = useCallback(
    async (username: string, code: string) => {
      setIsSaving(true);
      try {
        await Auth.confirmSignUp(username, code);
        setIsEmailConfirmed(true);
      } catch (e) {
        setErrorMessage(e.message);
      }
      setIsSaving(false);
    },
    [setIsSaving]
  );

  useEffect(() => {
    if (code) {
      confirmSignUp(email, code);
    }
  }, [code, email, confirmSignUp]);

  const handleConfirm = async () => {
    setErrorMessage('');
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    setIsSaving(true);
    const code = form.getFieldValue(codeFieldName);
    try {
      await Auth.confirmSignUp(email, code);
      setIsEmailConfirmed(true);
      if (password) {
        await Auth.signIn(email, password);
      } else {
      }
      goToNextStep();
    } catch (e) {
      setErrorMessage(e.message);
    }
    setIsSaving(false);
  };

  const handleLogin = async () => {
    setErrorMessage('');
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    setIsSaving(true);
    const inputPassword = form.getFieldValue(passwordFieldName);
    try {
      await Auth.signIn(email, inputPassword);
      goToNextStep();
    } catch (e) {
      setErrorMessage(e.message);
    }
    setIsSaving(false);
  };

  const resendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      showSuccessMessage(translations.registration.confirmationStep.verficationEmailSentSuccessMessage);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  return (
    <Form {...layout} form={form}>
      {isEmailConfirmed && !password ? (
        <>
          <p>{translations.registration.confirmationStep.successMessage}</p>
          <Col span={24}>
            <Form.Item
              name={passwordFieldName}
              label={translations.registration.registerUserStep.labels.password}
              rules={[getRequiredRule(translations.registration.registerUserStep.labels.password)]}
              className='registration-field'
            >
              <Input.Password placeholder={translations.registration.registerUserStep.labels.password} />
            </Form.Item>
          </Col>
          <p className={'Registration_errorMessage'}>{errorMessage}</p>
          <RegistrationButton onClick={handleLogin} />
        </>
      ) : (
        <>
          <p>{translations.registration.confirmationStep.displayText}</p>
          <p>{translations.registration.confirmationStep.checkJunk}</p>
          <Row>
            <Button style={{ marginBottom: '32px' }} onClick={resendVerificationCode}>
              {translations.registration.confirmationStep.resendVerificationCode}
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={codeFieldName}
                label={translations.registration.confirmationStep.labels.code}
                rules={[getRequiredRule(translations.registration.confirmationStep.labels.code)]}
                className='registration-field'
              >
                <Input placeholder={translations.registration.confirmationStep.labels.codePlaceholder} />
              </Form.Item>
            </Col>
          </Row>
          <p className={'Registration_errorMessage'}>{errorMessage}</p>
          <RegistrationButton onClick={handleConfirm} text={translations.registration.confirmationStep.confirmButton} />
        </>
      )}
    </Form>
  );
};
