import { Form, Select } from 'antd';
import React from 'react';
import { OrganizationReferenceData } from '../../graph/types';
import { translations } from '../../constants/translations';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { getRequiredRule } from '../../util/forms';

export function getTypeDropdown(
  types: OrganizationReferenceData[],
  placeholder: string,
  disabled = false,
  setSelectedNoteType?: (value: string) => void
): JSX.Element {
  const typesOptions = types.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <Form.Item
      shouldUpdate
      name={'type_id'}
      style={{ minWidth: 150 }}
      rules={[getRequiredRule(translations.recordList.typeFieldName)]}
    >
      <Select
        {...searchableSelectParams}
        options={typesOptions}
        placeholder={placeholder}
        data-testid={'TypeSelect'}
        onSelect={setSelectedNoteType}
        disabled={disabled}
      />
    </Form.Item>
  );
}
