import { gql } from '@apollo/client';
import {
  LabClassFields,
  LabDeviceFields,
  LabDeviceFieldsWithTaxonomy,
  LabRequestFields,
  LabTaxonomyMapResultFields,
} from '../fragments/labLinkFragments';

export const GetLabRefServices = gql`
  query getLabRefServices($organizationId: ID!, $labRefFilter: QueryLabRefFilter) {
    getLabRefServices(organizationId: $organizationId, filter: $labRefFilter) {
      lab_service_number
      lab_service_name
    }
  }
`;

export const GetLabClass = gql`
  query getLabClass($organizationId: ID!, $classId: ID) {
    getLabClass(organizationId: $organizationId, classId: $classId) {
      ...LabClassFields
    }
  }
  ${LabClassFields}
`;

export const GetLabDevices = gql`
  query getLabDevices($organizationId: ID!, $practiceId: ID, $labClassId: ID, $labDeviceId: ID) {
    getLabDevices(
      organizationId: $organizationId
      practiceId: $practiceId
      labClassId: $labClassId
      labDeviceId: $labDeviceId
    ) {
      ...LabDeviceFields
    }
  }
  ${LabDeviceFields}
`;

export const GetLabRequestsForPatient = gql`
  query getLabRequests($organizationId: ID!, $patientId: ID!) {
    getLabRequests(organizationId: $organizationId, patientId: $patientId) {
      id
      doctor_name
      description
      lab_device_id
      lab_request_url
      lab_result_url
      lab_service_name
      status_id
      created
      updated
      request_group_id
      lab_device_name
      lab_created_order_number
      lab_created_accession_id
      pims_created_order_number
      pims_created_accession_id
      lab_class_id
    }
  }
`;

export const GetLabSpeciesMapping = gql`
  query getLabSpeciesMapping($organizationId: ID!, $labDeviceId: ID!) {
    getLabSpeciesMapping(organizationId: $organizationId, labDeviceId: $labDeviceId) {
      id
      cassadol_species_id
      cassadol_species_name
      lab_species_name
      lab_species_number
    }
  }
`;

export const GetLabBreedMapping = gql`
  query getLabBreedMapping($organizationId: ID!, $labDeviceId: ID!) {
    getLabBreedMapping(organizationId: $organizationId, labDeviceId: $labDeviceId) {
      id
      cassadol_species_id
      cassadol_species_name
      cassadol_breed_id
      cassadol_breed_name
      lab_breed_name
      lab_breed_number
      lab_species_number
      lab_species_name
    }
  }
`;

export const GetLabGenderMapping = gql`
  query getLabGenderMapping($organizationId: ID!, $labDeviceId: ID!) {
    getLabGenderMapping(organizationId: $organizationId, labDeviceId: $labDeviceId) {
      id
      cassadol_species_id
      cassadol_species_name
      cassadol_gender_id
      cassadol_gender_name
      lab_gender_name
      lab_gender_number
      lab_species_number
      lab_species_name
    }
  }
`;

export const GetLabServiceMapping = gql`
  query getLabServiceMapping($organizationId: ID!, $labDeviceId: ID!) {
    getLabServiceMapping(organizationId: $organizationId, labDeviceId: $labDeviceId) {
      id
      cassadol_service_id
      cassadol_service_name
      lab_service_name
      lab_service_number
    }
  }
`;

export const UpsertLabRequest = gql`
  mutation upsertLabRequest($organizationId: ID!, $labRequest: LabRequestUpsert!) {
    upsertLabRequest(organizationId: $organizationId, labRequest: $labRequest) {
      ...LabRequestFields
    }
  }
  ${LabRequestFields}
`;

export const UpsertLabDevice = gql`
  mutation upsertLabDevice($organizationId: ID!, $labDevice: LabDeviceUpsert!) {
    upsertLabDevice(organizationId: $organizationId, labDevice: $labDevice) {
      ...LabDeviceFieldsWithTaxonomy
    }
  }
  ${LabDeviceFieldsWithTaxonomy}
`;

export const UpsertServiceBatchLabResubmit = gql`
  mutation upsertServiceBatchLabResubmit($organizationId: ID!, $groupBatchResubmit: BatchGroupResubmissionUpsert!) {
    upsertServiceBatchLabResubmit(organizationId: $organizationId, groupBatchResubmit: $groupBatchResubmit) {
      success
    }
  }
`;

export const AutoMapLabTaxonomy = gql`
  mutation autoMapLabTaxonomy($organizationId: ID!, $instruction: AutoMapInstruction!) {
    autoMapLabTaxonomy(organizationId: $organizationId, instruction: $instruction) {
      ...LabTaxonomyMapResultFields
    }
  }
  ${LabTaxonomyMapResultFields}
`;

export const GetLabRefBreeds = gql`
  query getLabRefBreeds($organizationId: ID!, $labRefFilter: QueryLabRefFilter) {
    getLabRefBreeds(organizationId: $organizationId, filter: $labRefFilter) {
      lab_breed_name
      lab_breed_number
      lab_species_number
    }
  }
`;

export const GetLabRefGenders = gql`
  query getLabRefGenders($organizationId: ID!, $labRefFilter: QueryLabRefFilter) {
    getLabRefGenders(organizationId: $organizationId, filter: $labRefFilter) {
      lab_gender_name
      lab_gender_number
      lab_species_number
    }
  }
`;

export const GetLabRefSpecies = gql`
  # Named filter labRefFilter as our filter property is allocated to PGQuery filters in the hook
  query getLabRefSpecies($organizationId: ID!, $labRefFilter: QueryLabRefFilter) {
    getLabRefSpecies(organizationId: $organizationId, filter: $labRefFilter) {
      lab_species_name
      lab_species_number
    }
  }
`;

export const GetLabResultStatus = gql`
  query getLabResultStatus($organizationId: ID!, $labResultFilter: QueryLabResultFilter) {
    getLabResultStatus(organizationId: $organizationId, filter: $labResultFilter) {
      has_lab_results
      lab_result_message
      url
    }
  }
`;

export const UpsertLabSettingsAntech = gql`
  mutation upsertLabSettingsAntech($organizationId: ID!, $labSetting: LabSettingsAntechUpsert!) {
    upsertLabSettingsAntech(organizationId: $organizationId, labSetting: $labSetting) {
      account_id
      clinic_id
      lab_device_id
      password
      user_id
      antech_lab_id
    }
  }
`;

export const UpsertLabSettingsVetConnect = gql`
  mutation upsertLabSettingsVetConnect($organizationId: ID!, $labSetting: LabSettingsVetConnectUpsert!) {
    upsertLabSettingsVetConnect(organizationId: $organizationId, labSetting: $labSetting) {
      lab_account_id
      lab_device_id
      password
      username
      serial_number
      in_house
    }
  }
`;

export const GetLabSettingsAntech = gql`
  query getLabSettingsAntech($organizationId: ID!, $filter: QueryLabSettingFilter) {
    getLabSettingsAntech(organizationId: $organizationId, filter: $filter) {
      account_id
      clinic_id
      lab_device_id
      password
      user_id
      antech_lab_id
    }
  }
`;

export const GetLabSettingsVetConnect = gql`
  query getLabSettingsVetConnect($organizationId: ID!, $filter: QueryLabSettingFilter) {
    getLabSettingsVetConnect(organizationId: $organizationId, filter: $filter) {
      lab_account_id
      lab_device_id
      password
      username
      serial_number
      in_house
    }
  }
`;

export const GetLabManagementAntech = gql`
  query getLabManagementAntech($organizationId: ID!, $labFilter: QueryLabSettingFilter) {
    getLabManagementAntech(organizationId: $organizationId, filter: $labFilter) {
      account_token
      management_url
    }
  }
`;

export const GetBatchLabRequests = gql`
  query getBatchLabRequests($organizationId: ID!, $batchGroupId: ID!) {
    getBatchLabRequests(organizationId: $organizationId, batchGroupId: $batchGroupId) {
      ...LabRequestFields
    }
  }
  ${LabRequestFields}
`;

export const GetFailedBatchLabRequests = gql`
  query getFailedBatchLabRequests($organizationId: ID!, $batchGroupId: ID!) {
    getFailedBatchLabRequests(organizationId: $organizationId, batchGroupId: $batchGroupId) {
      ...LabRequestFields
    }
  }
  ${LabRequestFields}
`;

export const GetIvlsDevices = gql`
  query getIvlsDevices($organizationId: ID!, $filter: QueryLabSettingFilter!) {
    getIvlsDevices(organizationId: $organizationId, filter: $filter) {
      deviceSerialNumber
      displayName
      lastPolledCloudTime
      vcpActivatedStatus
    }
  }
`;
