import styled from 'styled-components';

export const StyledBalance = styled.div`
  margin-left: auto;
`;

export const BalanceTextWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const BalanceWrapper = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;
`;

export const BalanceText = styled.h2`
  margin: auto;
  margin-right: 10px;
`;
