import { TagColor } from '../tagColor';
import { translations } from '../translations';
import { ServiceThirdPartyId } from './serviceReferenceData';

export const communicationTypes = {
  statementRun: {
    value: 1,
    label: translations.viewContactPage.communicationTypes.statementRun,
  },
  reminderRun: {
    value: 2,
    label: translations.viewContactPage.communicationTypes.reminderRun,
  },
};

interface contactTypeConfig {
  label: string;
  color: string;
  thirdPartyReference?: ServiceThirdPartyId;
}

export enum ContactTypeNameKey {
  Owner = 'CT_OWNER',
  Trainer = 'CT_TRAINER',
  Boarding = 'CT_BOARDING',
  Farrier = 'CT_FARRIER',
  ReferralDoc = 'CT_REFERRALDOC',
  ReferralHosp = 'CT_REFERRALHOSP',
  ReferredBy = 'CT_REFERREDBY',
  EMR = 'CT_EMR',
  Doctor = 'CT_DOCTOR',
  Staff = 'CT_STAFF',
  FarmManager = 'CT_FARMMGR',
  BroodManager = 'CT_BROODMAREMGR',
  YearlingManager = 'CT_YEARLINGMGR',
  AssistantTrainer = 'CT_ASSISTTRNR',
  Leasee = 'CT_LEASEE',
  Leasor = 'CT_LEASOR',
}

export enum ContactTypeId {
  Owner = '1',
  ReferralDoc = '2',
  ReferralHosp = '3',
  Trainer = '4',
  Boarding = '5',
  Farrier = '6',
  ReferredBy = '7',
  EMR = '8',
  Doctor = '10',
  Staff = '11',
  FarmManager = '12',
  BroodManager = '13',
  YearlingManager = '14',
  AssistantTrainer = '15',
  Leasee = '16',
  Leasor = '17',
}

export const contactTypeConfigs: { [nameKey in ContactTypeNameKey]: contactTypeConfig } = {
  [ContactTypeNameKey.Owner]: {
    label: translations.referenceData.contactTypes.owner,
    color: TagColor.Color1,
  },
  [ContactTypeNameKey.Trainer]: {
    label: translations.referenceData.contactTypes.trainer,
    color: TagColor.Color2,
  },
  [ContactTypeNameKey.Boarding]: {
    label: translations.referenceData.contactTypes.boarding,
    color: TagColor.Color3,
  },
  [ContactTypeNameKey.Farrier]: {
    label: translations.referenceData.contactTypes.farrier,
    color: TagColor.Color4,
  },
  [ContactTypeNameKey.ReferralDoc]: {
    label: translations.referenceData.contactTypes.referralDoc,
    color: TagColor.Color5,
  },
  [ContactTypeNameKey.ReferralHosp]: {
    label: translations.referenceData.contactTypes.referralHosp,
    color: TagColor.Color6,
  },
  [ContactTypeNameKey.ReferredBy]: {
    label: translations.referenceData.contactTypes.referredBy,
    color: TagColor.Color7,
  },
  [ContactTypeNameKey.EMR]: {
    label: translations.referenceData.contactTypes.emr,
    color: TagColor.Color8,
    thirdPartyReference: ServiceThirdPartyId.EMR,
  },
  [ContactTypeNameKey.Doctor]: {
    label: translations.referenceData.contactTypes.doctor,
    color: TagColor.Color9,
  },
  [ContactTypeNameKey.Staff]: {
    label: translations.referenceData.contactTypes.staff,
    color: TagColor.Color10,
  },
  [ContactTypeNameKey.FarmManager]: {
    label: translations.referenceData.contactTypes.farmManager,
    color: TagColor.Color11,
  },
  [ContactTypeNameKey.BroodManager]: {
    label: translations.referenceData.contactTypes.broodManager,
    color: TagColor.Color12,
  },
  [ContactTypeNameKey.YearlingManager]: {
    label: translations.referenceData.contactTypes.yearlingManager,
    color: TagColor.Color13,
  },
  [ContactTypeNameKey.AssistantTrainer]: {
    label: translations.referenceData.contactTypes.assistantTrainer,
    color: TagColor.Color14,
  },
  [ContactTypeNameKey.Leasee]: {
    label: translations.referenceData.contactTypes.leasee,
    color: TagColor.Color15,
  },
  [ContactTypeNameKey.Leasor]: {
    label: translations.referenceData.contactTypes.leasor,
    color: TagColor.Color16,
  },
};

export const getContactStatusNameKeyFromId = (typeId: ContactTypeId): string => {
  switch (typeId) {
    case ContactTypeId.Owner:
      return ContactTypeNameKey.Owner;
    case ContactTypeId.ReferralDoc:
      return ContactTypeNameKey.ReferralDoc;
    case ContactTypeId.ReferralHosp:
      return ContactTypeNameKey.ReferralHosp;
    case ContactTypeId.Trainer:
      return ContactTypeNameKey.Trainer;
    case ContactTypeId.Boarding:
      return ContactTypeNameKey.Boarding;
    case ContactTypeId.Farrier:
      return ContactTypeNameKey.Farrier;
    case ContactTypeId.ReferredBy:
      return ContactTypeNameKey.ReferredBy;
    case ContactTypeId.EMR:
      return ContactTypeNameKey.EMR;
    case ContactTypeId.Doctor:
      return ContactTypeNameKey.Doctor;
    case ContactTypeId.Staff:
      return ContactTypeNameKey.Staff;
    case ContactTypeId.FarmManager:
      return ContactTypeNameKey.FarmManager;
    case ContactTypeId.BroodManager:
      return ContactTypeNameKey.BroodManager;
    case ContactTypeId.YearlingManager:
      return ContactTypeNameKey.YearlingManager;
    case ContactTypeId.AssistantTrainer:
      return ContactTypeNameKey.AssistantTrainer;
    case ContactTypeId.Leasee:
      return ContactTypeNameKey.Leasee;
    case ContactTypeId.Leasor:
      return ContactTypeNameKey.Leasor;

    default:
      return '';
  }
};

export const thirdPartyContactTypes = [ContactTypeId.EMR];
