import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import styled from 'styled-components';

export const StyledPageHeaderWithMargin = styled(PageHeader)<PageHeaderProps>({
  'border-bottom': '1px solid #f0f0f0',
  'margin-bottom': '10px',
});

export const StyledDescription = styled('div')`
  padding: 16px 24px;
  margin-bottom: 40px;
`;

export const StyledPageHeader = styled(PageHeader)<PageHeaderProps>({
  'border-bottom': '1px solid #f0f0f0',
  'padding-top': '0',
});

export const StyledPageHeaderDoctorOffice = styled(PageHeader)<PageHeaderProps>({
  'padding-top': '0',
});

export const StyledPageHeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledBalance = styled.div`
  margin-left: auto;
`;
