import { useEffect, useState } from 'react';

export function useTaskWithProgress<T>(onIteration: (value: T, onSuccess: () => void) => void, onFinish?: () => void) {
  const [progress, setProgress] = useState<number>();
  const [iterations, setIterations] = useState<T[]>([]);
  const [iteration, setIteration] = useState<number>(0);
  const [running, setRunning] = useState<boolean>(false);

  useEffect(() => {
    if (running && iterations[iteration] !== undefined) {
      onIteration(iterations[iteration], () => {
        setProgress(Math.floor(((iteration + 1) / iterations.length) * 100));
        setIteration(iteration + 1);
      });
    } else if (running && iteration >= iterations.length) {
      onFinish?.();
      stop();
    }
    // eslint-disable-next-line
  }, [running, iterations, iteration, setProgress]);

  const stop = () => {
    setRunning(false);
    setProgress(undefined);
  };

  const start = (iterations: T[]) => {
    setIterations(iterations);
    setIteration(0);
    setProgress(0);
    setRunning(true);
  };

  return { progress: running ? progress : undefined, start, stop };
}
