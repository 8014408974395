import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import Search from './Search';
import { SearchWrapper } from './Search.styles';
import { useGlobalSearch } from '../../../../hooks/ajax/search/searchHooks';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute } from '../../../../hooks/route/navigationHooks';

const SearchContainer = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [searchValue, setSearchValue] = useState<string>();
  const { searchResults, searchLoading } = useGlobalSearch(organizationId, searchValue);
  const { navigateTo } = useNavigationToRoute();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((val: string) => setSearchValue(val), 500),
    []
  );

  const onSelect = (_: any, option: any) => {
    const {
      children: {
        props: { route, parameter },
      },
    } = option;
    navigateTo(route, parameter);
    setSearchValue(undefined);
  };

  return (
    <SearchWrapper>
      <Search
        isLoading={searchLoading}
        onChange={debounceOnChange}
        options={searchResults ?? []}
        onSelect={onSelect}
        searchValue={searchValue}
      />
    </SearchWrapper>
  );
};

export default SearchContainer;
