import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { JobStatus } from '../../components/JobStatus/JobStatus';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { getRouteWithOrganizationId, routes } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { UpsertReminderBatch } from '../../graph/queries/reminders';
import { Job, JobUpsert, QueryRptGetReminderLetterArgs, ReminderBatch } from '../../graph/types';
import { useUpsertJob } from '../../hooks/ajax/job/jobHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { useDeleteReminderBatch, useGetRemindersBatchList } from '../../hooks/ajax/reminders/reminderHooks';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getJobRecordUpsert } from '../../util/jobUtil';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { ModalConfigureButton } from './Modals/ModalConfigureButton';
import { Container, StyledPageHeader } from './ReminderRuns.styles';
import TableContainer from './TableContainer/TableContainer';
import RunButton from './TopInputControls/RunButton';
import TopInputControls from './TopInputControls/TopInputControls';
import { FormChangeProp, ReminderForm, RUN_MODE_OPTIONS, RunMode, SelectedContact } from './types';
import { getUpsertVariablesByContacts } from './utils';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Reports, getReportData } from '../../util/reportUtils';
import { GetReminderLetter } from '../../graph/queries/reports';
import { useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';

const ReminderRuns = () => {
  const { state: routeState } = useLocation<{ tab?: ReminderBatch }>();
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { refetch } = useGetRemindersBatchList(organizationId);
  const deleteReminderBatch = useDeleteReminderBatch();
  const useActiveReports = useLDFlag(LDFlagNames.ActiveReportsReminderRun);
  const [bodyState, setBodyState] = useState<string>('');
  const [jobId, setJobId] = useState<string>();
  const [upsertJob] = useUpsertJob();
  const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([]);
  const [isUpsertLoading, setUpsertLoadingState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { navigateTo } = useNavigationToRoute();

  const navigateToReportViewer = useNavigateToReportViewer();

  const [state, setState] = useState<ReminderForm>({
    afterDate: dayjs(),
    beforeDate: dayjs().add(1, 'M'),
    runMode: RUN_MODE_OPTIONS[0]?.value,
  });

  useEffect(() => {
    setSelectedContacts([]);
  }, [routeState, state.afterDate, state.beforeDate]);

  const onChangeForm = (formProp: FormChangeProp) => {
    setState({ ...state, ...formProp });
  };

  const onClickConfigure = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = async () => {
    const { fnCancel, fnComplete, fnPrint, fnSendEmails } = getUpsertVariablesByContacts(
      state.runMode,
      selectedContacts
    );

    setUpsertLoadingState(true);

    try {
      const jobRecord = getJobRecordUpsert(UpsertReminderBatch, {
        organizationId,
        reminderBatch: {
          id: routeState?.tab?.id,
          fnPrint,
          fnCancel,
          fnComplete,
          fnSendEmails,
          record: {
            practice_id: routeState?.tab?.practice_id ?? practiceId,
            reminder_body: bodyState,
          },
        },
      });

      const job: JobUpsert = {
        record: jobRecord,
      };

      const jobVariables = {
        organizationId,
        job,
      };

      const { data: jobData } = await upsertJob({
        variables: jobVariables,
      });

      setJobId(jobData?.upsertJob?.id);
      setUpsertLoadingState(false);
    } catch (e) {
      setUpsertLoadingState(false);
      showErrorMessage(e?.message);
    }
  };

  const closeJobStatusModal = () => {
    setJobId(undefined);
    setSelectedContacts([]);
    showSuccessMessage(translations.shared.saveSuccessMessage);
  };
  const handleJobfinish = async ({ result: jobResult }: Job) => {
    if (jobResult) {
      try {
        if (
          useActiveReports &&
          (state.runMode === RunMode.PRINT ||
            state.runMode === RunMode.PRINT_AND_EMAIL ||
            (state.runMode === RunMode.PRINT_OR_EMAIL &&
              selectedContacts.some((c) => c.preference?.toLowerCase() === RunMode.PRINT)))
        ) {
          let reminderIds;
          if (state.runMode === RunMode.PRINT_OR_EMAIL) {
            reminderIds = selectedContacts
              ?.filter((c) => c.preference?.toLowerCase() === RunMode.PRINT)
              .map((c) => c.reminderId);
          } else {
            reminderIds = selectedContacts.map((c) => c.reminderId);
          }
          const reportData = await getReportData<'rptGetReminderLetter', QueryRptGetReminderLetterArgs>(
            GetReminderLetter,
            {
              organizationId,
              practiceId,
              reminderIds,
            }
          );
          refetch();
          navigateToReportViewer({
            reportName: Reports.ReminderLetter,
            data: reportData,
            nestedHeader: true,
          });
        } else {
          const { reportPointer, result } = await JSON.parse(jobResult);

          navigateTo(getRouteWithOrganizationId(`${routes.reminderRuns}/${result.id}`, organizationId));
          refetch();
          if (reportPointer) {
            openTabWithPopupDetection(reportPointer, translations.popupDetectionMessage.printReport);
          }
        }
      } catch (e) {
        showErrorMessage(e?.message);
      }
    }
    setSelectedContacts([]);
  };

  const onDeleteBatch = async (batchId: string) => {
    setUpsertLoadingState(true);
    try {
      await deleteReminderBatch({ organizationId, batchId });
      setUpsertLoadingState(false);
      navigateTo(getRouteWithOrganizationId(routes.reminderRuns, organizationId));
    } catch (e) {
      setUpsertLoadingState(false);
      showErrorMessage(e?.message);
    }
  };

  const onJobError = (errorMessage: string) => {
    setSelectedContacts([]);
    showErrorMessage(errorMessage);
  };

  return (
    <>
      <SaveSpinner isSaving={isUpsertLoading}>
        <StyledPageHeader title={translations.reminderRuns.title} />
        <Container>
          <TopInputControls
            afterDate={state.afterDate}
            beforeDate={state.beforeDate}
            runMode={state.runMode}
            onChange={onChangeForm}
            onClickConfigure={onClickConfigure}
            sentDate={routeState?.tab?.date_sent}
            runButton={
              <RunButton
                contacts={selectedContacts?.length}
                afterDate={state.afterDate}
                beforeDate={state.beforeDate}
                runMode={state.runMode}
                onSubmit={onSubmit}
              />
            }
          />
          <TableContainer
            organizationId={organizationId}
            afterDate={state.afterDate}
            beforeDate={state.beforeDate}
            selectedRows={selectedContacts}
            onSelect={setSelectedContacts}
            onDeleteBatch={onDeleteBatch}
          />
        </Container>
      </SaveSpinner>
      <ModalConfigureButton
        isModalOpen={isModalOpen}
        setModalOpen={onClickConfigure}
        organizationId={organizationId}
        bodyState={bodyState}
        setBodyState={setBodyState}
      />
      {jobId && (
        <JobStatus jobId={jobId} onError={onJobError} closeModal={closeJobStatusModal} onCompletion={handleJobfinish} />
      )}
    </>
  );
};

export default ReminderRuns;
