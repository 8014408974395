import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  useMutation,
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/client';
import { getOperationDefinition } from '@apollo/client/utilities';
import { FieldNode } from 'graphql';
import { useCallback, useContext, useMemo, useState } from 'react';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import ViewSubscriptionActions from '../../components/ViewSubscription/store/actions';
import { ViewSubscriptionContext } from '../../components/ViewSubscription/store/state';
import { MAX_NUMBER_OF_LIST_ITEMS } from '../../constants/queryConstants';
import { translations } from '../../constants/translations';
import {
  FinancialPeriodFilter,
  HisaPatientFilter,
  InterestRunFilterOptions,
  Mutation,
  Query,
  QueryInvoiceFilter,
  QueryLedgerFilter,
  QueryServiceFilter,
  QueryUserOrganizationCardFilter,
  QueryUserOrganizationFilter,
} from '../../graph/types';
import { checkConnection } from '../../util/connectionUtil';
import { isNetworkFetchError } from '../../util/errorUtil';
import { objToKey } from '../../util/objectComparisons';
import { useOffline } from '../../util/offline/offlineUtil';

export type PGQueryFilter = {
  filter?:
    | QueryLedgerFilter
    | QueryInvoiceFilter
    | QueryServiceFilter
    | HisaPatientFilter
    | FinancialPeriodFilter
    | QueryUserOrganizationCardFilter
    | QueryUserOrganizationFilter
    | InterestRunFilterOptions;
};

const getNeedsAnotherQuery = (list: any) => (list?.length ? list.length > 1 : false);

const getMoreData = async (data: any, fetchMore: any, options: any, setHasInitial: (value: boolean) => void) => {
  const entities = data && (Object.values(data)[0] as any[]);
  if (data === null || getNeedsAnotherQuery(entities)) {
    const { data: moreData } = await fetchMore({
      variables: {
        filter: {
          ...options?.variables?.filter,
          paging: {
            start_id: entities && entities[entities.length - 1].id,
            page_size: MAX_NUMBER_OF_LIST_ITEMS,
          },
        },
      },
    });
    await getMoreData(moreData, fetchMore, options, setHasInitial);
  } else {
    setHasInitial(false);
  }
};

export const useOfflineErrorSkipQuery = <T extends keyof Query, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<Pick<Query, T>, TVariables & PGQueryFilter>,
  skipPgCheck = false,
  removePagingFromFilter = false
) => {
  const { isOnline, setIsOnline, enabledAndOffline } = useOffline();
  const operation = getOperationDefinition(query);
  const queryName = (operation?.selectionSet.selections[0] as FieldNode).name.value;
  const isPGQuery = !skipPgCheck && queryName?.toLowerCase().includes('pg');
  const [hasInitial, setHasInitial] = useState(false);
  // IMPORTANT! Avoid to execute queries if offline
  const queryOptions = { ...options, skip: isOnline ? options?.skip : true, returnPartialData: true };
  const paging = useMemo(
    () =>
      options?.variables?.filter !== undefined && 'paging' in options?.variables?.filter
        ? options?.variables?.filter?.paging
        : undefined,
    [options]
  );

  const variables = useMemo(() => {
    if (removePagingFromFilter) {
      return {
        ...options?.variables,
        ...(isPGQuery
          ? {
              filter: {
                ...options?.variables?.filter,
              },
            }
          : {}),
      } as TVariables & PGQueryFilter;
    }
    return {
      ...options?.variables,
      ...(isPGQuery
        ? {
            filter: {
              ...options?.variables?.filter,
              paging: {
                ...paging,
                page_size: MAX_NUMBER_OF_LIST_ITEMS,
              },
            },
          }
        : {}),
    } as TVariables & PGQueryFilter;
  }, [isPGQuery, options?.variables, paging, removePagingFromFilter]);

  const {
    data,
    previousData,
    loading,
    fetchMore,
    refetch: apolloRefetch,
    ...rest
  } = useApolloQuery<Pick<Query, T>, TVariables & PGQueryFilter>(query, {
    ...queryOptions,
    onError: (error) => {
      if (!isNetworkFetchError(error)) {
        if (options?.onError) {
          options.onError(error);
        } else {
          showErrorMessage(error.message || translations.shared.loadErrorMessage);
        }
      } else {
        setIsOnline(checkConnection());
      }
    },
    onCompleted: (data) => {
      queryOptions.onCompleted?.(data);
      if (isPGQuery && !hasInitial) {
        setHasInitial(true);
        getMoreData(data, fetchMore, options, setHasInitial);
      }
      options?.onCompleted?.(data);
    },
    variables,
    nextFetchPolicy: queryOptions.nextFetchPolicy ?? 'cache-first',
  });

  const refetch = useCallback(
    (variables?: any) => {
      if (isPGQuery) {
        setHasInitial(true);
        return getMoreData(null, fetchMore, options, setHasInitial);
      }

      return apolloRefetch({ ...options?.variables, ...variables });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloRefetch, options && objToKey(options), fetchMore, isPGQuery]
  );

  return {
    data: data === undefined && !enabledAndOffline ? previousData : data,
    loading,
    refetch,
    ...rest,
  };
};

export const useOfflineErrorSkipLazyQuery = <
  T extends keyof Query,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<Pick<Query, T>, TVariables>
) => {
  return useApolloLazyQuery<Pick<Query, T>, TVariables>(query, {
    ...options,
    onError: (error) => {
      if (!isNetworkFetchError(error)) {
        if (options?.onError) {
          options.onError(error);
        } else {
          showErrorMessage(error.message || translations.shared.loadErrorMessage);
        }
      }
    },
  });
};

export const useOfflineErrorSkipMutation = <T extends keyof Mutation, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<Pick<Mutation, T>, TVariables>
) => {
  const { state, dispatch } = useContext(ViewSubscriptionContext);
  return useMutation<Pick<Mutation, T>, TVariables>(mutation, {
    ...options,
    onError: (error) => {
      if (!isNetworkFetchError(error)) {
        if (options?.onError) {
          options.onError(error);
        } else {
          throw error;
        }
      }
    },
    onCompleted: () => state?.isActive && dispatch(ViewSubscriptionActions.setIsInitiator(true)),
  });
};
