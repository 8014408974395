import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import layout from 'antd/lib/layout';
import React, { useEffect, useState } from 'react';
import { ROATypeId, translations } from '../../constants/translations';
import {
  CustomWidthFormItemWithNoMarginBottom,
  FlexRadioGroup,
  FlexedRadioButton,
  CustomWidthFormItem,
} from '../../pages/Services/ServiceForm/ServiceForm.style';
import { getRequiredRule } from '../../util/forms';
import { ROAOptions } from '../ServiceRendered/serviceRenderedUtils';
import { PatientFormItemProps } from '../PatientModalContent/PatientModalContent';

export const roaModalFormTestId = 'roaModalForm';
export const otherInputTestId = 'other-input';

export interface RoaModalFormValues {
  roa_id?: string;
  roa_other?: string;
}

type RoaModalContentType = {
  form?: FormInstance;
  visible?: boolean;
  autofocus?: boolean;
  patientFormItemProps?: PatientFormItemProps;
};

export const RoaModalContent: React.FC<RoaModalContentType> = ({ visible, form, autofocus, patientFormItemProps }) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const roaId = form?.getFieldValue(['roa_id']);

  useEffect(() => {
    setShowOtherInput(roaId === ROATypeId.Other);
  }, [visible, roaId]);

  return (
    <>
      <CustomWidthFormItemWithNoMarginBottom
        name='roa_id'
        {...layout}
        width={100}
        rules={[getRequiredRule(translations.shared.roaModal.title)]}
        labelCol={{ span: 24 }}
        {...patientFormItemProps}
      >
        <FlexRadioGroup margin={showOtherInput ? '0' : '0 0 24px 0'} buttonStyle='solid'>
          {ROAOptions.map(({ description, value }) => {
            return (
              <FlexedRadioButton
                key={value}
                value={value}
                onChange={() => setShowOtherInput(value === ROATypeId.Other)}
                autoFocus={autofocus}
              >
                {description}
              </FlexedRadioButton>
            );
          })}
        </FlexRadioGroup>
      </CustomWidthFormItemWithNoMarginBottom>
      {showOtherInput && (
        <CustomWidthFormItem
          rules={roaId === ROATypeId.Other ? [getRequiredRule(translations.shared.roaModal.other)] : undefined}
          width={100}
          name='roa_other'
        >
          <Input data-testid={patientFormItemProps?.testid ?? otherInputTestId} />
        </CustomWidthFormItem>
      )}
    </>
  );
};
