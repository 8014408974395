import { CountryId } from '../constants/countries';

export interface StageConfig {
  PUBLIC_API_URL: string;
  APPSYNC_URL: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_APP_CLIENT_ID: string;
  LAUNCHDARKLY_CLIEND_ID: string;
  STRIPE_PUBLISHABLE_KEY: string;
  STRIPE_PUBLISHABLE_KEY_CAD: string;
  APP_DATADOG_APPLICATION_ID: string;
  APP_DATADOG_CLIENT_TOKEN: string;
}

interface SharedConfig {
  REGION: string;
  USERPILOT_TOKEN: string;
  ACTIVEREPORTS_LICENSE_KEY: string;
}

type Config = SharedConfig & StageConfig;

function loadStageConfig(stage: string): StageConfig {
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires,
  const env = require('./' + stage + '.ts');
  return env.default;
}

const sharedConfig: SharedConfig = {
  REGION: 'us-east-2',
  USERPILOT_TOKEN: '36kj95b6',
  ACTIVEREPORTS_LICENSE_KEY:
    '*.software.vet,565857456255729#B1TMoEVqZFb4xEUCVTeCNnWthEaWpGUJZzRwd5avBHRsRGb8MVe9lzTypVYRVneP9EeytSQpNWNMZHeEZkTupUYRJHdupET8JnQXZ4d8tiRKJHTpRkYr3yQLFncw44VQp5QNhUMnRjTVJUWaFncvIEc4k6Q7Q5YNFWTspUVH5mQzc7QEtSYwMlbKt6MLhEcZ9UOxkjWRNlZkdHd9g7aUVmSv2mVH3ya4hmQ9k4U9J4bzVnMahmMxV5TMlUYVJGbYdGVpRDV5c7cqVzQ8RTOpV4avAlREBTailWcRl4c0dWMUVEbtF4ZGhDRDNEazVHOpNlVMN5SiojITJCLiIkQERkMBhTMiojIIJCL4EDN8EzM4EjM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsISOwcTNyEDIxIjMwQjMwIjI0ICdyNkIsICdlZnLlJXY7RnZvNnLqIiOiMXbEJCLiMnbvl6c5ZmbJByczVmbpNXdCJiOiEmTDJCLikjM7UTNyYTN4cTN8UjN5IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnV5Mx4mVsF6d6wmZJV4SMxmdlFXNGpkdBF5KB3Wdr3UQrgVNuBlbtFXMlhDWuB7S5hHWJRGcMJjSxJkRY9GWxUjTkZFTFtSMBBTOiJEeUZTc5VzYZtScuJ6Ril4NUBVjNuW',
};

const stageConfig = loadStageConfig(process.env.JEST_WORKER_ID ? 'prod' : process.env.REACT_APP_STAGE || 'dev');

const config: Config = {
  ...sharedConfig,
  ...stageConfig,
};

export const getStripePublishableKey = (countryId: string) =>
  countryId === CountryId.Canada ? config.STRIPE_PUBLISHABLE_KEY_CAD : config.STRIPE_PUBLISHABLE_KEY;

export default config;
