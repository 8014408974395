// Original colors
export const mainColor = '#44535e';
export const subMenuColor = '#3b4952';
export const secondColor = '#d9ebe9';
export const accentColor = '#798f8c';
export const fourthColor = '#0e1821';

export const lightBackground = '#FAFAFA';

// Additional colors
export const redColor = '#f5222d';
export const linkColor = '#1890ff';

export const washedGrey = 'rgba(0,0,0,0.65)';

export const cardDarkBackgroundColor = '#f4f4f4';

export const lightBackgroundColor = '#FAFAFA';

// Primary color palette
export const primaryPalette = {
  1: '#c2cfcb',
  2: '#b6c2bf',
  3: '#aab5b3',
  4: '#9ea8a6',
  5: '#929c9a',
  6: '#798f8c',
  7: '#546966',
  8: '#324241',
  9: '#141c1c',
};
