import { CountryNameKey } from './countries';

export const countryTranslations = {
  [CountryNameKey.AFG]: 'Afghanistan',
  [CountryNameKey.ALA]: 'Aland Islands',
  [CountryNameKey.ALB]: 'Albania',
  [CountryNameKey.DZA]: 'Algeria',
  [CountryNameKey.ASM]: 'American Samoa',
  [CountryNameKey.AND]: 'Andorra',
  [CountryNameKey.AGO]: 'Angola',
  [CountryNameKey.AIA]: 'Anguilla',
  [CountryNameKey.ATA]: 'Antarctica',
  [CountryNameKey.ATG]: 'Antigua And Barbuda',
  [CountryNameKey.ARG]: 'Argentina',
  [CountryNameKey.ARM]: 'Armenia',
  [CountryNameKey.ABW]: 'Aruba',
  [CountryNameKey.AUS]: 'Australia',
  [CountryNameKey.AUT]: 'Austria',
  [CountryNameKey.AZE]: 'Azerbaijan',
  [CountryNameKey.BHR]: 'Bahrain',
  [CountryNameKey.BGD]: 'Bangladesh',
  [CountryNameKey.BRB]: 'Barbados',
  [CountryNameKey.BLR]: 'Belarus',
  [CountryNameKey.BEL]: 'Belgium',
  [CountryNameKey.BLZ]: 'Belize',
  [CountryNameKey.BEN]: 'Benin',
  [CountryNameKey.BMU]: 'Bermuda',
  [CountryNameKey.BTN]: 'Bhutan',
  [CountryNameKey.BOL]: 'Bolivia',
  [CountryNameKey.BES]: 'Bonaire, Sint Eustatius and Saba',
  [CountryNameKey.BIH]: 'Bosnia and Herzegovina',
  [CountryNameKey.BWA]: 'Botswana',
  [CountryNameKey.BVT]: 'Bouvet Island',
  [CountryNameKey.BRA]: 'Brazil',
  [CountryNameKey.IOT]: 'British Indian Ocean Territory',
  [CountryNameKey.BRN]: 'Brunei',
  [CountryNameKey.BGR]: 'Bulgaria',
  [CountryNameKey.BFA]: 'Burkina Faso',
  [CountryNameKey.BDI]: 'Burundi',
  [CountryNameKey.KHM]: 'Cambodia',
  [CountryNameKey.CMR]: 'Cameroon',
  [CountryNameKey.Canada]: 'Canada',
  [CountryNameKey.CPV]: 'Cape Verde',
  [CountryNameKey.CYM]: 'Cayman Islands',
  [CountryNameKey.CAF]: 'Central African Republic',
  [CountryNameKey.TCD]: 'Chad',
  [CountryNameKey.CHL]: 'Chile',
  [CountryNameKey.CHN]: 'China',
  [CountryNameKey.CXR]: 'Christmas Island',
  [CountryNameKey.CCK]: 'Cocos (Keeling) Islands',
  [CountryNameKey.COL]: 'Colombia',
  [CountryNameKey.COM]: 'Comoros',
  [CountryNameKey.COG]: 'Congo',
  [CountryNameKey.COK]: 'Cook Islands',
  [CountryNameKey.CRI]: 'Costa Rica',
  [CountryNameKey.CIV]: "Cote D'Ivoire (Ivory Coast)",
  [CountryNameKey.HRV]: 'Croatia',
  [CountryNameKey.CUB]: 'Cuba',
  [CountryNameKey.CUW]: 'Curaçao',
  [CountryNameKey.CYP]: 'Cyprus',
  [CountryNameKey.CZE]: 'Czech Republic',
  [CountryNameKey.COD]: 'Democratic Republic of the Congo',
  [CountryNameKey.DNK]: 'Denmark',
  [CountryNameKey.DJI]: 'Djibouti',
  [CountryNameKey.DMA]: 'Dominica',
  [CountryNameKey.DOM]: 'Dominican Republic',
  [CountryNameKey.TLS]: 'East Timor',
  [CountryNameKey.ECU]: 'Ecuador',
  [CountryNameKey.EGY]: 'Egypt',
  [CountryNameKey.SLV]: 'El Salvador',
  [CountryNameKey.GNQ]: 'Equatorial Guinea',
  [CountryNameKey.ERI]: 'Eritrea',
  [CountryNameKey.EST]: 'Estonia',
  [CountryNameKey.ETH]: 'Ethiopia',
  [CountryNameKey.FLK]: 'Falkland Islands',
  [CountryNameKey.FRO]: 'Faroe Islands',
  [CountryNameKey.FJI]: 'Fiji Islands',
  [CountryNameKey.FIN]: 'Finland',
  [CountryNameKey.FRA]: 'France',
  [CountryNameKey.GUF]: 'French Guiana',
  [CountryNameKey.PYF]: 'French Polynesia',
  [CountryNameKey.ATF]: 'French Southern Territories',
  [CountryNameKey.GAB]: 'Gabon',
  [CountryNameKey.GMB]: 'Gambia The',
  [CountryNameKey.GEO]: 'Georgia',
  [CountryNameKey.DEU]: 'Germany',
  [CountryNameKey.GHA]: 'Ghana',
  [CountryNameKey.GIB]: 'Gibraltar',
  [CountryNameKey.GRC]: 'Greece',
  [CountryNameKey.GRL]: 'Greenland',
  [CountryNameKey.GRD]: 'Grenada',
  [CountryNameKey.GLP]: 'Guadeloupe',
  [CountryNameKey.GUM]: 'Guam',
  [CountryNameKey.GTM]: 'Guatemala',
  [CountryNameKey.GGY]: 'Guernsey and Alderney',
  [CountryNameKey.GIN]: 'Guinea',
  [CountryNameKey.GNB]: 'Guinea-Bissau',
  [CountryNameKey.GUY]: 'Guyana',
  [CountryNameKey.HTI]: 'Haiti',
  [CountryNameKey.HMD]: 'Heard Island and McDonald Islands',
  [CountryNameKey.HND]: 'Honduras',
  [CountryNameKey.HKG]: 'Hong Kong S.A.R.',
  [CountryNameKey.HUN]: 'Hungary',
  [CountryNameKey.ISL]: 'Iceland',
  [CountryNameKey.IND]: 'India',
  [CountryNameKey.IDN]: 'Indonesia',
  [CountryNameKey.IRN]: 'Iran',
  [CountryNameKey.IRQ]: 'Iraq',
  [CountryNameKey.IRL]: 'Ireland',
  [CountryNameKey.ISR]: 'Israel',
  [CountryNameKey.ITA]: 'Italy',
  [CountryNameKey.JAM]: 'Jamaica',
  [CountryNameKey.JPN]: 'Japan',
  [CountryNameKey.JEY]: 'Jersey',
  [CountryNameKey.JOR]: 'Jordan',
  [CountryNameKey.KAZ]: 'Kazakhstan',
  [CountryNameKey.KEN]: 'Kenya',
  [CountryNameKey.KIR]: 'Kiribati',
  [CountryNameKey.XKX]: 'Kosovo',
  [CountryNameKey.KWT]: 'Kuwait',
  [CountryNameKey.KGZ]: 'Kyrgyzstan',
  [CountryNameKey.LAO]: 'Laos',
  [CountryNameKey.LVA]: 'Latvia',
  [CountryNameKey.LBN]: 'Lebanon',
  [CountryNameKey.LSO]: 'Lesotho',
  [CountryNameKey.LBR]: 'Liberia',
  [CountryNameKey.LBY]: 'Libya',
  [CountryNameKey.LIE]: 'Liechtenstein',
  [CountryNameKey.LTU]: 'Lithuania',
  [CountryNameKey.LUX]: 'Luxembourg',
  [CountryNameKey.MAC]: 'Macau S.A.R.',
  [CountryNameKey.MKD]: 'Macedonia',
  [CountryNameKey.MDG]: 'Madagascar',
  [CountryNameKey.MWI]: 'Malawi',
  [CountryNameKey.MYS]: 'Malaysia',
  [CountryNameKey.MDV]: 'Maldives',
  [CountryNameKey.MLI]: 'Mali',
  [CountryNameKey.MLT]: 'Malta',
  [CountryNameKey.IMN]: 'Man (Isle of)',
  [CountryNameKey.MHL]: 'Marshall Islands',
  [CountryNameKey.MTQ]: 'Martinique',
  [CountryNameKey.MRT]: 'Mauritania',
  [CountryNameKey.MUS]: 'Mauritius',
  [CountryNameKey.MYT]: 'Mayotte',
  [CountryNameKey.MEX]: 'Mexico',
  [CountryNameKey.FSM]: 'Micronesia',
  [CountryNameKey.MDA]: 'Moldova',
  [CountryNameKey.MCO]: 'Monaco',
  [CountryNameKey.MNG]: 'Mongolia',
  [CountryNameKey.MNE]: 'Montenegro',
  [CountryNameKey.MSR]: 'Montserrat',
  [CountryNameKey.MAR]: 'Morocco',
  [CountryNameKey.MOZ]: 'Mozambique',
  [CountryNameKey.MMR]: 'Myanmar',
  [CountryNameKey.NAM]: 'Namibia',
  [CountryNameKey.NRU]: 'Nauru',
  [CountryNameKey.NPL]: 'Nepal',
  [CountryNameKey.NLD]: 'Netherlands',
  [CountryNameKey.NCL]: 'New Caledonia',
  [CountryNameKey.NZL]: 'New Zealand',
  [CountryNameKey.NIC]: 'Nicaragua',
  [CountryNameKey.NER]: 'Niger',
  [CountryNameKey.NGA]: 'Nigeria',
  [CountryNameKey.NIU]: 'Niue',
  [CountryNameKey.NFK]: 'Norfolk Island',
  [CountryNameKey.PRK]: 'North Korea',
  [CountryNameKey.MNP]: 'Northern Mariana Islands',
  [CountryNameKey.NOR]: 'Norway',
  [CountryNameKey.OMN]: 'Oman',
  [CountryNameKey.PAK]: 'Pakistan',
  [CountryNameKey.PLW]: 'Palau',
  [CountryNameKey.PSE]: 'Palestinian Territory Occupied',
  [CountryNameKey.PAN]: 'Panama',
  [CountryNameKey.PNG]: 'Papua new Guinea',
  [CountryNameKey.PRY]: 'Paraguay',
  [CountryNameKey.PER]: 'Peru',
  [CountryNameKey.PHL]: 'Philippines',
  [CountryNameKey.PCN]: 'Pitcairn Island',
  [CountryNameKey.POL]: 'Poland',
  [CountryNameKey.PRT]: 'Portugal',
  [CountryNameKey.PRI]: 'Puerto Rico',
  [CountryNameKey.QAT]: 'Qatar',
  [CountryNameKey.REU]: 'Reunion',
  [CountryNameKey.ROU]: 'Romania',
  [CountryNameKey.RUS]: 'Russia',
  [CountryNameKey.RWA]: 'Rwanda',
  [CountryNameKey.SHN]: 'Saint Helena',
  [CountryNameKey.KNA]: 'Saint Kitts And Nevis',
  [CountryNameKey.LCA]: 'Saint Lucia',
  [CountryNameKey.SPM]: 'Saint Pierre and Miquelon',
  [CountryNameKey.VCT]: 'Saint Vincent And The Grenadines',
  [CountryNameKey.BLM]: 'Saint-Barthelemy',
  [CountryNameKey.MAF]: 'Saint-Martin (French part)',
  [CountryNameKey.WSM]: 'Samoa',
  [CountryNameKey.SMR]: 'San Marino',
  [CountryNameKey.STP]: 'Sao Tome and Principe',
  [CountryNameKey.SAU]: 'Saudi Arabia',
  [CountryNameKey.SEN]: 'Senegal',
  [CountryNameKey.SRB]: 'Serbia',
  [CountryNameKey.SYC]: 'Seychelles',
  [CountryNameKey.SLE]: 'Sierra Leone',
  [CountryNameKey.SGP]: 'Singapore',
  [CountryNameKey.SXM]: 'Sint Maarten (Dutch part)',
  [CountryNameKey.SVK]: 'Slovakia',
  [CountryNameKey.SVN]: 'Slovenia',
  [CountryNameKey.SLB]: 'Solomon Islands',
  [CountryNameKey.SOM]: 'Somalia',
  [CountryNameKey.ZAF]: 'South Africa',
  [CountryNameKey.SGS]: 'South Georgia',
  [CountryNameKey.KOR]: 'South Korea',
  [CountryNameKey.SSD]: 'South Sudan',
  [CountryNameKey.ESP]: 'Spain',
  [CountryNameKey.LKA]: 'Sri Lanka',
  [CountryNameKey.SDN]: 'Sudan',
  [CountryNameKey.SUR]: 'Suriname',
  [CountryNameKey.SJM]: 'Svalbard And Jan Mayen Islands',
  [CountryNameKey.SWZ]: 'Swaziland',
  [CountryNameKey.SWE]: 'Sweden',
  [CountryNameKey.CHE]: 'Switzerland',
  [CountryNameKey.SYR]: 'Syria',
  [CountryNameKey.TWN]: 'Taiwan',
  [CountryNameKey.TJK]: 'Tajikistan',
  [CountryNameKey.TZA]: 'Tanzania',
  [CountryNameKey.THA]: 'Thailand',
  [CountryNameKey.BHS]: 'The Bahamas',
  [CountryNameKey.TGO]: 'Togo',
  [CountryNameKey.TKL]: 'Tokelau',
  [CountryNameKey.TON]: 'Tonga',
  [CountryNameKey.TTO]: 'Trinidad And Tobago',
  [CountryNameKey.TUN]: 'Tunisia',
  [CountryNameKey.TUR]: 'Turkey',
  [CountryNameKey.TKM]: 'Turkmenistan',
  [CountryNameKey.TCA]: 'Turks And Caicos Islands',
  [CountryNameKey.TUV]: 'Tuvalu',
  [CountryNameKey.UGA]: 'Uganda',
  [CountryNameKey.UKR]: 'Ukraine',
  [CountryNameKey.UAE]: 'UAE',
  [CountryNameKey.GBR]: 'United Kingdom',
  [CountryNameKey.USA]: 'USA',
  [CountryNameKey.UMI]: 'United States Minor Outlying Islands',
  [CountryNameKey.URY]: 'Uruguay',
  [CountryNameKey.UZB]: 'Uzbekistan',
  [CountryNameKey.VUT]: 'Vanuatu',
  [CountryNameKey.VAT]: 'Vatican City State (Holy See)',
  [CountryNameKey.VEN]: 'Venezuela',
  [CountryNameKey.VNM]: 'Vietnam',
  [CountryNameKey.VGB]: 'Virgin Islands (British)',
  [CountryNameKey.VIR]: 'Virgin Islands (US)',
  [CountryNameKey.WLF]: 'Wallis And Futuna Islands',
  [CountryNameKey.ESH]: 'Western Sahara',
  [CountryNameKey.YEM]: 'Yemen',
  [CountryNameKey.ZMB]: 'Zambia',
  [CountryNameKey.ZWE]: 'Zimbabwe',
};

export const countryProvStateTranslations = {
  PROV_ALBERTA: 'Alberta',
  PROV_BRITISHCOLUMBIA: 'British Columbia',
  PROV_MANITOBA: 'Manitoba',
  PROV_NEWBRUNSWICK: 'New Brunswick',
  PROV_NEWFOUNDLANDANDLABRADOR: 'Newfoundland and Labrador',
  PROV_NORTHWESTTERRITORIES: 'Northwest Territories',
  PROV_NOVASCOTIA: 'Nova Scotia',
  PROV_NUNAVUT: 'Nunavut',
  PROV_ONTARIO: 'Ontario',
  PROV_PRINCEEDWARDISLAND: 'Prince Edward Island',
  PROV_QUEBEC: 'Quebec',
  PROV_SASKATCHEWAN: 'Saskatchewan',
  PROV_YUKON: 'Yukon',
  PROV_ALABAMA: 'Alabama',
  PROV_ALASKA: 'Alaska',
  PROV_ARIZONA: 'Arizona',
  PROV_ARKANSAS: 'Arkansas',
  PROV_CALIFORNIA: 'California',
  PROV_COLORADO: 'Colorado',
  PROV_CONNECTICUT: 'Connecticut',
  PROV_DELAWARE: 'Delaware',
  PROV_DISTRICTOFCOLUMBIA: 'District Of Columbia',
  PROV_FLORIDA: 'Florida',
  PROV_GEORGIA: 'Georgia',
  PROV_HAWAII: 'Hawaii',
  PROV_IDAHO: 'Idaho',
  PROV_ILLINOIS: 'Illinois',
  PROV_INDIANA: 'Indiana',
  PROV_IOWA: 'Iowa',
  PROV_KANSAS: 'Kansas',
  PROV_KENTUCKY: 'Kentucky',
  PROV_LOUISIANA: 'Louisiana',
  PROV_MAINE: 'Maine',
  PROV_MARYLAND: 'Maryland',
  PROV_MASSACHUSETTS: 'Massachusetts',
  PROV_MICHIGAN: 'Michigan',
  PROV_MINNESOTA: 'Minnesota',
  PROV_MISSISSIPPI: 'Mississippi',
  PROV_MISSOURI: 'Missouri',
  PROV_MONTANA: 'Montana',
  PROV_NEBRASKA: 'Nebraska',
  PROV_NEVADA: 'Nevada',
  PROV_NEWHAMPSHIRE: 'New Hampshire',
  PROV_NEWJERSEY: 'New Jersey',
  PROV_NEWMEXICO: 'New Mexico',
  PROV_NEWYORK: 'New York',
  PROV_NORTHCAROLINA: 'North Carolina',
  PROV_NORTHDAKOTA: 'North Dakota',
  PROV_OHIO: 'Ohio',
  PROV_OKLAHOMA: 'Oklahoma',
  PROV_OREGON: 'Oregon',
  PROV_PENNSYLVANIA: 'Pennsylvania',
  PROV_RHODEISLAND: 'Rhode Island',
  PROV_SOUTHCAROLINA: 'South Carolina',
  PROV_SOUTHDAKOTA: 'South Dakota',
  PROV_TENNESSEE: 'Tennessee',
  PROV_TEXAS: 'Texas',
  PROV_USVI: 'US Virgin Islands',
  PROV_UTAH: 'Utah',
  PROV_VERMONT: 'Vermont',
  PROV_VIRGINIA: 'Virginia',
  PROV_WASHINGTON: 'Washington',
  PROV_WESTVIRGINIA: 'West Virginia',
  PROV_WISCONSIN: 'Wisconsin',
  PROV_WYOMING: 'Wyoming',
  PROV_ABU_DHABI: 'Abu Dhabi',
  PROV_DUBAI: 'Dubai',
  PROV_SHARJAH: 'Sharjah',
  PROV_AJMAN: 'Ajman',
  PROV_UMM_AL_QUWAIN: 'Umm Al-Quwain',
  PROV_FUJAIRAH: 'Furijah',
  PROV_EASTERN_CAPE: 'Eastern Cape',
  PROV_FREE_STATE: 'Free State',
  PROV_GAUTENG: 'Gauteng',
  PROV_KWAZULU_NATAL: 'KwaZulu-Natal',
  PROV_LIMPOPO: 'Limpopo',
  PROV_MPUMALANGA: 'Mpumalanga',
  PROV_NORTHERN_CAPE: 'Northern Cape',
  PROV_NORTH_WEST: 'North West',
  PROV_WESTERN_CAPE: 'Western Cape',
  PROV_AFG_BADAKHSHAN: 'Badakhshan',
  PROV_AFG_BADGHIS: 'Badghis',
  PROV_AFG_BAGHLAN: 'Baghlan',
  PROV_AFG_BALKH: 'Balkh',
  PROV_AFG_BAMYAN: 'Bamyan',
  PROV_AFG_DAYKUNDI: 'Daykundi',
  PROV_AFG_FARAH: 'Farah',
  PROV_AFG_FARYAB: 'Faryab',
  PROV_AFG_GHAZNI: 'Ghazni',
  PROV_AFG_GHR: 'Ghōr',
  PROV_AFG_HELMAND: 'Helmand',
  PROV_AFG_HERAT: 'Herat',
  PROV_AFG_JOWZJAN: 'Jowzjan',
  PROV_AFG_KABUL: 'Kabul',
  PROV_AFG_KANDAHAR: 'Kandahar',
  PROV_AFG_KAPISA: 'Kapisa',
  PROV_AFG_KHOST: 'Khost',
  PROV_AFG_KUNAR: 'Kunar',
  PROV_AFG_KUNDUZPROVINCE: 'Kunduz Province',
  PROV_AFG_LAGHMAN: 'Laghman',
  PROV_AFG_LOGAR: 'Logar',
  PROV_AFG_NANGARHAR: 'Nangarhar',
  PROV_AFG_NIMRUZ: 'Nimruz',
  PROV_AFG_NURISTAN: 'Nuristan',
  PROV_AFG_PAKTIA: 'Paktia',
  PROV_AFG_PAKTIKA: 'Paktika',
  PROV_AFG_PANJSHIR: 'Panjshir',
  PROV_AFG_PARWAN: 'Parwan',
  PROV_AFG_SAMANGAN: 'Samangan',
  PROV_AFG_SAREPOL: 'Sar-e Pol',
  PROV_AFG_TAKHAR: 'Takhar',
  PROV_AFG_UROZGAN: 'Urozgan',
  PROV_AFG_ZABUL: 'Zabul',
  PROV_ALA_NOPROVINCE: 'No Province',
  PROV_ALB_BERATCOUNTY: 'Berat County',
  PROV_ALB_DIBRCOUNTY: 'Dibër County',
  PROV_ALB_DURRSCOUNTY: 'Durrës County',
  PROV_ALB_ELBASANCOUNTY: 'Elbasan County',
  PROV_ALB_FIERCOUNTY: 'Fier County',
  PROV_ALB_GJIROKASTRCOUNTY: 'Gjirokastër County',
  PROV_ALB_KORCOUNTY: 'Korçë County',
  PROV_ALB_KUKSCOUNTY: 'Kukës County',
  PROV_ALB_LEZHCOUNTY: 'Lezhë County',
  PROV_ALB_SHKODRCOUNTY: 'Shkodër County',
  PROV_ALB_TIRANACOUNTY: 'Tirana County',
  PROV_ALB_VLORCOUNTY: 'Vlorë County',
  PROV_DZA_ADRAR: 'Adrar',
  PROV_DZA_ANDEFLA: 'Aïn Defla',
  PROV_DZA_ANTMOUCHENT: 'Aïn Témouchent',
  PROV_DZA_ALGIERS: 'Algiers',
  PROV_DZA_ANNABA: 'Annaba',
  PROV_DZA_BATNA: 'Batna',
  PROV_DZA_BCHAR: 'Béchar',
  PROV_DZA_BJAA: 'Béjaïa',
  PROV_DZA_BNIABBS: 'Béni Abbès',
  PROV_DZA_BISKRA: 'Biskra',
  PROV_DZA_BLIDA: 'Blida',
  PROV_DZA_BORDJBAJIMOKHTAR: 'Bordj Baji Mokhtar',
  PROV_DZA_BORDJBOUARRRIDJ: 'Bordj Bou Arréridj',
  PROV_DZA_BOURA: 'Bouïra',
  PROV_DZA_BOUMERDS: 'Boumerdès',
  PROV_DZA_CHLEF: 'Chlef',
  PROV_DZA_CONSTANTINE: 'Constantine',
  PROV_DZA_DJANET: 'Djanet',
  PROV_DZA_DJELFA: 'Djelfa',
  PROV_DZA_ELBAYADH: 'El Bayadh',
  PROV_DZA_ELMGHAIR: "El M'ghair",
  PROV_DZA_ELMENIA: 'El Menia',
  PROV_DZA_ELOUED: 'El Oued',
  PROV_DZA_ELTARF: 'El Tarf',
  PROV_DZA_GHARDAA: 'Ghardaïa',
  PROV_DZA_GUELMA: 'Guelma',
  PROV_DZA_ILLIZI: 'Illizi',
  PROV_DZA_INGUEZZAM: 'In Guezzam',
  PROV_DZA_INSALAH: 'In Salah',
  PROV_DZA_JIJEL: 'Jijel',
  PROV_DZA_KHENCHELA: 'Khenchela',
  PROV_DZA_LAGHOUAT: 'Laghouat',
  PROV_DZA_MSILA: "M'Sila",
  PROV_DZA_MASCARA: 'Mascara',
  PROV_DZA_MDA: 'Médéa',
  PROV_DZA_MILA: 'Mila',
  PROV_DZA_MOSTAGANEM: 'Mostaganem',
  PROV_DZA_NAAMA: 'Naama',
  PROV_DZA_ORAN: 'Oran',
  PROV_DZA_OUARGLA: 'Ouargla',
  PROV_DZA_OULEDDJELLAL: 'Ouled Djellal',
  PROV_DZA_OUMELBOUAGHI: 'Oum El Bouaghi',
  PROV_DZA_RELIZANE: 'Relizane',
  PROV_DZA_SADA: 'Saïda',
  PROV_DZA_STIF: 'Sétif',
  PROV_DZA_SIDIBELABBS: 'Sidi Bel Abbès',
  PROV_DZA_SKIKDA: 'Skikda',
  PROV_DZA_SOUKAHRAS: 'Souk Ahras',
  PROV_DZA_TAMANGHASSET: 'Tamanghasset',
  PROV_DZA_TBESSA: 'Tébessa',
  PROV_DZA_TIARET: 'Tiaret',
  PROV_DZA_TIMIMOUN: 'Timimoun',
  PROV_DZA_TINDOUF: 'Tindouf',
  PROV_DZA_TIPASA: 'Tipasa',
  PROV_DZA_TISSEMSILT: 'Tissemsilt',
  PROV_DZA_TIZIOUZOU: 'Tizi Ouzou',
  PROV_DZA_TLEMCEN: 'Tlemcen',
  PROV_DZA_TOUGGOURT: 'Touggourt',
  PROV_ASM_NOPROVINCE: 'No Province',
  PROV_AND_ANDORRALAVELLA: 'Andorra la Vella',
  PROV_AND_CANILLO: 'Canillo',
  PROV_AND_ENCAMP: 'Encamp',
  PROV_AND_ESCALDESENGORDANY: 'Escaldes-Engordany',
  PROV_AND_LAMASSANA: 'La Massana',
  PROV_AND_ORDINO: 'Ordino',
  PROV_AND_SANTJULIDELRIA: 'Sant Julià de Lòria',
  PROV_AGO_BENGOPROVINCE: 'Bengo Province',
  PROV_AGO_BENGUELAPROVINCE: 'Benguela Province',
  PROV_AGO_BIPROVINCE: 'Bié Province',
  PROV_AGO_CABINDAPROVINCE: 'Cabinda Province',
  PROV_AGO_CUANDOCUBANGOPROVINCE: 'Cuando Cubango Province',
  PROV_AGO_CUANZANORTEPROVINCE: 'Cuanza Norte Province',
  PROV_AGO_CUANZASUL: 'Cuanza Sul',
  PROV_AGO_CUNENEPROVINCE: 'Cunene Province',
  PROV_AGO_HUAMBOPROVINCE: 'Huambo Province',
  PROV_AGO_HULAPROVINCE: 'Huíla Province',
  PROV_AGO_LUANDAPROVINCE: 'Luanda Province',
  PROV_AGO_LUNDANORTEPROVINCE: 'Lunda Norte Province',
  PROV_AGO_LUNDASULPROVINCE: 'Lunda Sul Province',
  PROV_AGO_MALANJEPROVINCE: 'Malanje Province',
  PROV_AGO_MOXICOPROVINCE: 'Moxico Province',
  PROV_AGO_UGEPROVINCE: 'Uíge Province',
  PROV_AGO_ZAIREPROVINCE: 'Zaire Province',
  PROV_AGO_NAMIBEPROVINCE: 'Namibe Province',
  PROV_AIA_NOPROVINCE: 'No Province',
  PROV_ATA_NOPROVINCE: 'No Province',
  PROV_ATG_BARBUDA: 'Barbuda',
  PROV_ATG_REDONDA: 'Redonda',
  PROV_ATG_SAINTGEORGEPARISH: 'Saint George Parish',
  PROV_ATG_SAINTJOHNPARISH: 'Saint John Parish',
  PROV_ATG_SAINTMARYPARISH: 'Saint Mary Parish',
  PROV_ATG_SAINTPAULPARISH: 'Saint Paul Parish',
  PROV_ATG_SAINTPETERPARISH: 'Saint Peter Parish',
  PROV_ATG_SAINTPHILIPPARISH: 'Saint Philip Parish',
  PROV_ARG_BUENOSAIRES: 'Buenos Aires',
  PROV_ARG_CATAMARCA: 'Catamarca',
  PROV_ARG_CHACO: 'Chaco',
  PROV_ARG_CHUBUT: 'Chubut',
  PROV_ARG_CIUDADAUTNOMADEBUENOSAIRES: 'Ciudad Autónoma de Buenos Aires',
  PROV_ARG_CRDOBA: 'Córdoba',
  PROV_ARG_CORRIENTES: 'Corrientes',
  PROV_ARG_ENTREROS: 'Entre Ríos',
  PROV_ARG_FORMOSA: 'Formosa',
  PROV_ARG_JUJUY: 'Jujuy',
  PROV_ARG_LAPAMPA: 'La Pampa',
  PROV_ARG_LARIOJA: 'La Rioja',
  PROV_ARG_MENDOZA: 'Mendoza',
  PROV_ARG_MISIONES: 'Misiones',
  PROV_ARG_NEUQUN: 'Neuquén',
  PROV_ARG_RONEGRO: 'Río Negro',
  PROV_ARG_SALTA: 'Salta',
  PROV_ARG_SANJUAN: 'San Juan',
  PROV_ARG_SANLUIS: 'San Luis',
  PROV_ARG_SANTACRUZ: 'Santa Cruz',
  PROV_ARG_SANTAFE: 'Santa Fe',
  PROV_ARG_SANTIAGODELESTERO: 'Santiago del Estero',
  PROV_ARG_TIERRADELFUEGO: 'Tierra del Fuego',
  PROV_ARG_TUCUMN: 'Tucumán',
  PROV_ARM_ARAGATSOTNREGION: 'Aragatsotn Region',
  PROV_ARM_ARARATPROVINCE: 'Ararat Province',
  PROV_ARM_ARMAVIRREGION: 'Armavir Region',
  PROV_ARM_GEGHARKUNIKPROVINCE: 'Gegharkunik Province',
  PROV_ARM_KOTAYKREGION: 'Kotayk Region',
  PROV_ARM_LORIREGION: 'Lori Region',
  PROV_ARM_SHIRAKREGION: 'Shirak Region',
  PROV_ARM_SYUNIKPROVINCE: 'Syunik Province',
  PROV_ARM_TAVUSHREGION: 'Tavush Region',
  PROV_ARM_VAYOTSDZORREGION: 'Vayots Dzor Region',
  PROV_ARM_YEREVAN: 'Yerevan',
  PROV_ABW_NOPROVINCE: 'No Province',
  PROV_AUS_AUSTRALIANCAPITALTERRITORY: 'Australian Capital Territory',
  PROV_AUS_NEWSOUTHWALES: 'New South Wales',
  PROV_AUS_NORTHERNTERRITORY: 'Northern Territory',
  PROV_AUS_QUEENSLAND: 'Queensland',
  PROV_AUS_SOUTHAUSTRALIA: 'South Australia',
  PROV_AUS_TASMANIA: 'Tasmania',
  PROV_AUS_VICTORIA: 'Victoria',
  PROV_AUS_WESTERNAUSTRALIA: 'Western Australia',
  PROV_AUT_BURGENLAND: 'Burgenland',
  PROV_AUT_CARINTHIA: 'Carinthia',
  PROV_AUT_LOWERAUSTRIA: 'Lower Austria',
  PROV_AUT_SALZBURG: 'Salzburg',
  PROV_AUT_STYRIA: 'Styria',
  PROV_AUT_TYROL: 'Tyrol',
  PROV_AUT_UPPERAUSTRIA: 'Upper Austria',
  PROV_AUT_VIENNA: 'Vienna',
  PROV_AUT_VORARLBERG: 'Vorarlberg',
  PROV_AZE_ABSHERONDISTRICT: 'Absheron District',
  PROV_AZE_AGDAMDISTRICT: 'Agdam District',
  PROV_AZE_AGDASHDISTRICT: 'Agdash District',
  PROV_AZE_AGHJABADIDISTRICT: 'Aghjabadi District',
  PROV_AZE_AGSTAFADISTRICT: 'Agstafa District',
  PROV_AZE_AGSUDISTRICT: 'Agsu District',
  PROV_AZE_ASTARADISTRICT: 'Astara District',
  PROV_AZE_BABEKDISTRICT: 'Babek District',
  PROV_AZE_BAKU: 'Baku',
  PROV_AZE_BALAKANDISTRICT: 'Balakan District',
  PROV_AZE_BARDADISTRICT: 'Barda District',
  PROV_AZE_BEYLAGANDISTRICT: 'Beylagan District',
  PROV_AZE_BILASUVARDISTRICT: 'Bilasuvar District',
  PROV_AZE_DASHKASANDISTRICT: 'Dashkasan District',
  PROV_AZE_FIZULIDISTRICT: 'Fizuli District',
  PROV_AZE_GANJA: 'Ganja',
  PROV_AZE_GDBY: 'Gədəbəy',
  PROV_AZE_GOBUSTANDISTRICT: 'Gobustan District',
  PROV_AZE_GORANBOYDISTRICT: 'Goranboy District',
  PROV_AZE_GOYCHAY: 'Goychay',
  PROV_AZE_GOYGOLDISTRICT: 'Goygol District',
  PROV_AZE_HAJIGABULDISTRICT: 'Hajigabul District',
  PROV_AZE_IMISHLIDISTRICT: 'Imishli District',
  PROV_AZE_ISMAILLIDISTRICT: 'Ismailli District',
  PROV_AZE_JABRAYILDISTRICT: 'Jabrayil District',
  PROV_AZE_JALILABADDISTRICT: 'Jalilabad District',
  PROV_AZE_JULFADISTRICT: 'Julfa District',
  PROV_AZE_KALBAJARDISTRICT: 'Kalbajar District',
  PROV_AZE_KANGARLIDISTRICT: 'Kangarli District',
  PROV_AZE_KHACHMAZDISTRICT: 'Khachmaz District',
  PROV_AZE_KHIZIDISTRICT: 'Khizi District',
  PROV_AZE_KHOJALIDISTRICT: 'Khojali District',
  PROV_AZE_KURDAMIRDISTRICT: 'Kurdamir District',
  PROV_AZE_LACHINDISTRICT: 'Lachin District',
  PROV_AZE_LANKARAN: 'Lankaran',
  PROV_AZE_LANKARANDISTRICT: 'Lankaran District',
  PROV_AZE_LERIKDISTRICT: 'Lerik District',
  PROV_AZE_MARTUNI: 'Martuni',
  PROV_AZE_MASALLYDISTRICT: 'Masally District',
  PROV_AZE_MINGACHEVIR: 'Mingachevir',
  PROV_AZE_NAKHCHIVANAUTONOMOUSREPUBLIC: 'Nakhchivan Autonomous Republic',
  PROV_AZE_NEFTCHALADISTRICT: 'Neftchala District',
  PROV_AZE_OGHUZDISTRICT: 'Oghuz District',
  PROV_AZE_ORDUBADDISTRICT: 'Ordubad District',
  PROV_AZE_QABALADISTRICT: 'Qabala District',
  PROV_AZE_QAKHDISTRICT: 'Qakh District',
  PROV_AZE_QAZAKHDISTRICT: 'Qazakh District',
  PROV_AZE_QUBADISTRICT: 'Quba District',
  PROV_AZE_QUBADLIDISTRICT: 'Qubadli District',
  PROV_AZE_QUSARDISTRICT: 'Qusar District',
  PROV_AZE_SAATLYDISTRICT: 'Saatly District',
  PROV_AZE_SABIRABADDISTRICT: 'Sabirabad District',
  PROV_AZE_SADARAKDISTRICT: 'Sadarak District',
  PROV_AZE_SALYANDISTRICT: 'Salyan District',
  PROV_AZE_SAMUKHDISTRICT: 'Samukh District',
  PROV_AZE_SHABRANDISTRICT: 'Shabran District',
  PROV_AZE_SHAHBUZDISTRICT: 'Shahbuz District',
  PROV_AZE_SHAKI: 'Shaki',
  PROV_AZE_SHAKIDISTRICT: 'Shaki District',
  PROV_AZE_SHAMAKHIDISTRICT: 'Shamakhi District',
  PROV_AZE_SHAMKIRDISTRICT: 'Shamkir District',
  PROV_AZE_SHARURDISTRICT: 'Sharur District',
  PROV_AZE_SHIRVAN: 'Shirvan',
  PROV_AZE_SHUSHADISTRICT: 'Shusha District',
  PROV_AZE_SIAZANDISTRICT: 'Siazan District',
  PROV_AZE_SUMQAYIT: 'Sumqayit',
  PROV_AZE_TARTARDISTRICT: 'Tartar District',
  PROV_AZE_TOVUZDISTRICT: 'Tovuz District',
  PROV_AZE_UJARDISTRICT: 'Ujar District',
  PROV_AZE_YARDYMLIDISTRICT: 'Yardymli District',
  PROV_AZE_YEVLAKH: 'Yevlakh',
  PROV_AZE_YEVLAKHDISTRICT: 'Yevlakh District',
  PROV_AZE_ZANGILANDISTRICT: 'Zangilan District',
  PROV_AZE_ZAQATALADISTRICT: 'Zaqatala District',
  PROV_AZE_ZARDABDISTRICT: 'Zardab District',
  PROV_BHR_CAPITALGOVERNORATE: 'Capital Governorate',
  PROV_BHR_MUHARRAQGOVERNORATE: 'Muharraq Governorate',
  PROV_BHR_NORTHERNGOVERNORATE: 'Northern Governorate',
  PROV_BHR_SOUTHERNGOVERNORATE: 'Southern Governorate',
  PROV_BGD_BARISALDIVISION: 'Barisal Division',
  PROV_BGD_CHITTAGONGDIVISION: 'Chittagong Division',
  PROV_BGD_DHAKADIVISION: 'Dhaka Division',
  PROV_BGD_KHULNADIVISION: 'Khulna Division',
  PROV_BGD_MYMENSINGHDIVISION: 'Mymensingh Division',
  PROV_BGD_RAJSHAHIDIVISION: 'Rajshahi Division',
  PROV_BGD_RANGPURDIVISION: 'Rangpur Division',
  PROV_BGD_SYLHETDIVISION: 'Sylhet Division',
  PROV_BRB_CHRISTCHURCH: 'Christ Church',
  PROV_BRB_SAINTANDREW: 'Saint Andrew',
  PROV_BRB_SAINTGEORGE: 'Saint George',
  PROV_BRB_SAINTJAMES: 'Saint James',
  PROV_BRB_SAINTJOHN: 'Saint John',
  PROV_BRB_SAINTJOSEPH: 'Saint Joseph',
  PROV_BRB_SAINTLUCY: 'Saint Lucy',
  PROV_BRB_SAINTMICHAEL: 'Saint Michael',
  PROV_BRB_SAINTPETER: 'Saint Peter',
  PROV_BRB_SAINTPHILIP: 'Saint Philip',
  PROV_BRB_SAINTTHOMAS: 'Saint Thomas',
  PROV_BLR_BRESTREGION: 'Brest Region',
  PROV_BLR_GOMELREGION: 'Gomel Region',
  PROV_BLR_GRODNOREGION: 'Grodno Region',
  PROV_BLR_MINSK: 'Minsk',
  PROV_BLR_MINSKREGION: 'Minsk Region',
  PROV_BLR_MOGILEVREGION: 'Mogilev Region',
  PROV_BLR_VITEBSKREGION: 'Vitebsk Region',
  PROV_BEL_ANTWERP: 'Antwerp',
  PROV_BEL_BRUSSELSCAPITALREGION: 'Brussels-Capital Region',
  PROV_BEL_EASTFLANDERS: 'East Flanders',
  PROV_BEL_FLEMISHBRABANT: 'Flemish Brabant',
  PROV_BEL_HAINAUT: 'Hainaut',
  PROV_BEL_LIGE: 'Liège',
  PROV_BEL_LIMBURG: 'Limburg',
  PROV_BEL_LUXEMBOURG: 'Luxembourg',
  PROV_BEL_NAMUR: 'Namur',
  PROV_BEL_WALLOONBRABANT: 'Walloon Brabant',
  PROV_BEL_WESTFLANDERS: 'West Flanders',
  PROV_BLZ_BELIZEDISTRICT: 'Belize District',
  PROV_BLZ_CAYODISTRICT: 'Cayo District',
  PROV_BLZ_COROZALDISTRICT: 'Corozal District',
  PROV_BLZ_ORANGEWALKDISTRICT: 'Orange Walk District',
  PROV_BLZ_STANNCREEKDISTRICT: 'Stann Creek District',
  PROV_BLZ_TOLEDODISTRICT: 'Toledo District',
  PROV_BEN_ALIBORIDEPARTMENT: 'Alibori Department',
  PROV_BEN_ATAKORADEPARTMENT: 'Atakora Department',
  PROV_BEN_ATLANTIQUEDEPARTMENT: 'Atlantique Department',
  PROV_BEN_BORGOUDEPARTMENT: 'Borgou Department',
  PROV_BEN_COLLINESDEPARTMENT: 'Collines Department',
  PROV_BEN_DONGADEPARTMENT: 'Donga Department',
  PROV_BEN_KOUFFODEPARTMENT: 'Kouffo Department',
  PROV_BEN_LITTORALDEPARTMENT: 'Littoral Department',
  PROV_BEN_MONODEPARTMENT: 'Mono Department',
  PROV_BEN_OUMDEPARTMENT: 'Ouémé Department',
  PROV_BEN_PLATEAUDEPARTMENT: 'Plateau Department',
  PROV_BEN_ZOUDEPARTMENT: 'Zou Department',
  PROV_BMU_DEVONSHIREPARISH: 'Devonshire Parish',
  PROV_BMU_HAMILTONPARISH: 'Hamilton Parish',
  PROV_BMU_PAGETPARISH: 'Paget Parish',
  PROV_BMU_PEMBROKEPARISH: 'Pembroke Parish',
  PROV_BMU_SAINTGEORGESPARISH: "Saint George's Parish",
  PROV_BMU_SANDYSPARISH: 'Sandys Parish',
  PROV_BMU_SMITHSPARISH: "Smith's Parish,",
  PROV_BMU_SOUTHAMPTONPARISH: 'Southampton Parish',
  PROV_BMU_WARWICKPARISH: 'Warwick Parish',
  PROV_BTN_BUMTHANGDISTRICT: 'Bumthang District',
  PROV_BTN_CHUKHADISTRICT: 'Chukha District',
  PROV_BTN_DAGANADISTRICT: 'Dagana District',
  PROV_BTN_GASADISTRICT: 'Gasa District',
  PROV_BTN_HAADISTRICT: 'Haa District',
  PROV_BTN_LHUNTSEDISTRICT: 'Lhuntse District',
  PROV_BTN_MONGARDISTRICT: 'Mongar District',
  PROV_BTN_PARODISTRICT: 'Paro District',
  PROV_BTN_PEMAGATSHELDISTRICT: 'Pemagatshel District',
  PROV_BTN_PUNAKHADISTRICT: 'Punakha District',
  PROV_BTN_SAMDRUPJONGKHARDISTRICT: 'Samdrup Jongkhar District',
  PROV_BTN_SAMTSEDISTRICT: 'Samtse District',
  PROV_BTN_SARPANGDISTRICT: 'Sarpang District',
  PROV_BTN_THIMPHUDISTRICT: 'Thimphu District',
  PROV_BTN_TRASHIGANGDISTRICT: 'Trashigang District',
  PROV_BTN_TRONGSADISTRICT: 'Trongsa District',
  PROV_BTN_TSIRANGDISTRICT: 'Tsirang District',
  PROV_BTN_WANGDUEPHODRANGDISTRICT: 'Wangdue Phodrang District',
  PROV_BTN_ZHEMGANGDISTRICT: 'Zhemgang District',
  PROV_BOL_BENIDEPARTMENT: 'Beni Department',
  PROV_BOL_CHUQUISACADEPARTMENT: 'Chuquisaca Department',
  PROV_BOL_COCHABAMBADEPARTMENT: 'Cochabamba Department',
  PROV_BOL_LAPAZDEPARTMENT: 'La Paz Department',
  PROV_BOL_ORURODEPARTMENT: 'Oruro Department',
  PROV_BOL_PANDODEPARTMENT: 'Pando Department',
  PROV_BOL_POTOSDEPARTMENT: 'Potosí Department',
  PROV_BOL_SANTACRUZDEPARTMENT: 'Santa Cruz Department',
  PROV_BOL_TARIJADEPARTMENT: 'Tarija Department',
  PROV_BES_BONAIRE: 'Bonaire',
  PROV_BES_SABA: 'Saba',
  PROV_BES_SINTEUSTATIUS: 'Sint Eustatius',
  PROV_BIH_BRKODISTRICT: 'Brčko District',
  PROV_BIH_FEDERATIONOFBOSNIAANDHERZEGOVINA: 'Federation of Bosnia and Herzegovina',
  PROV_BIH_REPUBLIKASRPSKA: 'Republika Srpska',
  PROV_BWA_CENTRALDISTRICT: 'Central District',
  PROV_BWA_GHANZIDISTRICT: 'Ghanzi District',
  PROV_BWA_KGALAGADIDISTRICT: 'Kgalagadi District',
  PROV_BWA_KGATLENGDISTRICT: 'Kgatleng District',
  PROV_BWA_KWENENGDISTRICT: 'Kweneng District',
  PROV_BWA_NGAMILAND: 'Ngamiland',
  PROV_BWA_NORTHEASTDISTRICT: 'North-East District',
  PROV_BWA_NORTHWESTDISTRICT: 'North-West District',
  PROV_BWA_SOUTHEASTDISTRICT: 'South-East District',
  PROV_BWA_SOUTHERNDISTRICT: 'Southern District',
  PROV_BVT_NOPROVINCE: 'No Province',
  PROV_BRA_ACRE: 'Acre',
  PROV_BRA_ALAGOAS: 'Alagoas',
  PROV_BRA_AMAP: 'Amapá',
  PROV_BRA_AMAZONAS: 'Amazonas',
  PROV_BRA_BAHIA: 'Bahia',
  PROV_BRA_CEAR: 'Ceará',
  PROV_BRA_DISTRITOFEDERAL: 'Distrito Federal',
  PROV_BRA_ESPRITOSANTO: 'Espírito Santo',
  PROV_BRA_GOIS: 'Goiás',
  PROV_BRA_MARANHO: 'Maranhão',
  PROV_BRA_MATOGROSSO: 'Mato Grosso',
  PROV_BRA_MATOGROSSODOSUL: 'Mato Grosso do Sul',
  PROV_BRA_MINASGERAIS: 'Minas Gerais',
  PROV_BRA_PAR: 'Pará',
  PROV_BRA_PARABA: 'Paraíba',
  PROV_BRA_PARAN: 'Paraná',
  PROV_BRA_PERNAMBUCO: 'Pernambuco',
  PROV_BRA_PIAU: 'Piauí',
  PROV_BRA_RIODEJANEIRO: 'Rio de Janeiro',
  PROV_BRA_RIOGRANDEDONORTE: 'Rio Grande do Norte',
  PROV_BRA_RIOGRANDEDOSUL: 'Rio Grande do Sul',
  PROV_BRA_RONDNIA: 'Rondônia',
  PROV_BRA_RORAIMA: 'Roraima',
  PROV_BRA_SANTACATARINA: 'Santa Catarina',
  PROV_BRA_SOPAULO: 'São Paulo',
  PROV_BRA_SERGIPE: 'Sergipe',
  PROV_BRA_TOCANTINS: 'Tocantins',
  PROV_IOT_NOPROVINCE: 'No Province',
  PROV_BRN_BELAITDISTRICT: 'Belait District',
  PROV_BRN_BRUNEIMUARADISTRICT: 'Brunei-Muara District',
  PROV_BRN_TEMBURONGDISTRICT: 'Temburong District',
  PROV_BRN_TUTONGDISTRICT: 'Tutong District',
  PROV_BGR_BLAGOEVGRADPROVINCE: 'Blagoevgrad Province',
  PROV_BGR_BURGASPROVINCE: 'Burgas Province',
  PROV_BGR_DOBRICHPROVINCE: 'Dobrich Province',
  PROV_BGR_GABROVOPROVINCE: 'Gabrovo Province',
  PROV_BGR_HASKOVOPROVINCE: 'Haskovo Province',
  PROV_BGR_KARDZHALIPROVINCE: 'Kardzhali Province',
  PROV_BGR_KYUSTENDILPROVINCE: 'Kyustendil Province',
  PROV_BGR_LOVECHPROVINCE: 'Lovech Province',
  PROV_BGR_MONTANAPROVINCE: 'Montana Province',
  PROV_BGR_PAZARDZHIKPROVINCE: 'Pazardzhik Province',
  PROV_BGR_PERNIKPROVINCE: 'Pernik Province',
  PROV_BGR_PLEVENPROVINCE: 'Pleven Province',
  PROV_BGR_PLOVDIVPROVINCE: 'Plovdiv Province',
  PROV_BGR_RAZGRADPROVINCE: 'Razgrad Province',
  PROV_BGR_RUSEPROVINCE: 'Ruse Province',
  PROV_BGR_SHUMEN: 'Shumen',
  PROV_BGR_SILISTRAPROVINCE: 'Silistra Province',
  PROV_BGR_SLIVENPROVINCE: 'Sliven Province',
  PROV_BGR_SMOLYANPROVINCE: 'Smolyan Province',
  PROV_BGR_SOFIACITYPROVINCE: 'Sofia City Province',
  PROV_BGR_SOFIAPROVINCE: 'Sofia Province',
  PROV_BGR_STARAZAGORAPROVINCE: 'Stara Zagora Province',
  PROV_BGR_TARGOVISHTEPROVINCE: 'Targovishte Province',
  PROV_BGR_VARNAPROVINCE: 'Varna Province',
  PROV_BGR_VELIKOTARNOVOPROVINCE: 'Veliko Tarnovo Province',
  PROV_BGR_VIDINPROVINCE: 'Vidin Province',
  PROV_BGR_VRATSAPROVINCE: 'Vratsa Province',
  PROV_BGR_YAMBOLPROVINCE: 'Yambol Province',
  PROV_BFA_BOUCLEDUMOUHOUNREGION: 'Boucle du Mouhoun Region',
  PROV_BFA_CASCADESREGION: 'Cascades Region',
  PROV_BFA_CENTRE: 'Centre',
  PROV_BFA_CENTREESTREGION: 'Centre-Est Region',
  PROV_BFA_CENTRENORDREGION: 'Centre-Nord Region',
  PROV_BFA_CENTREOUESTREGION: 'Centre-Ouest Region',
  PROV_BFA_CENTRESUDREGION: 'Centre-Sud Region',
  PROV_BFA_ESTREGION: 'Est Region',
  PROV_BFA_HAUTSBASSINSREGION: 'Hauts-Bassins Region',
  PROV_BFA_NORDREGIONBURKINAFASO: 'Nord Region, Burkina Faso',
  PROV_BFA_PLATEAUCENTRALREGION: 'Plateau-Central Region',
  PROV_BFA_SAHELREGION: 'Sahel Region',
  PROV_BFA_SUDOUESTREGION: 'Sud-Ouest Region',
  PROV_BDI_BUBANZAPROVINCE: 'Bubanza Province',
  PROV_BDI_BUJUMBURAMAIRIEPROVINCE: 'Bujumbura Mairie Province',
  PROV_BDI_BUJUMBURARURALPROVINCE: 'Bujumbura Rural Province',
  PROV_BDI_BURURIPROVINCE: 'Bururi Province',
  PROV_BDI_CANKUZOPROVINCE: 'Cankuzo Province',
  PROV_BDI_CIBITOKEPROVINCE: 'Cibitoke Province',
  PROV_BDI_GITEGAPROVINCE: 'Gitega Province',
  PROV_BDI_KARUZIPROVINCE: 'Karuzi Province',
  PROV_BDI_KAYANZAPROVINCE: 'Kayanza Province',
  PROV_BDI_KIRUNDOPROVINCE: 'Kirundo Province',
  PROV_BDI_MAKAMBAPROVINCE: 'Makamba Province',
  PROV_BDI_MURAMVYAPROVINCE: 'Muramvya Province',
  PROV_BDI_MUYINGAPROVINCE: 'Muyinga Province',
  PROV_BDI_MWAROPROVINCE: 'Mwaro Province',
  PROV_BDI_NGOZIPROVINCE: 'Ngozi Province',
  PROV_BDI_RUMONGEPROVINCE: 'Rumonge Province',
  PROV_BDI_RUTANAPROVINCE: 'Rutana Province',
  PROV_BDI_RUYIGIPROVINCE: 'Ruyigi Province',
  PROV_KHM_BANTEAYMEANCHEY: 'Banteay Meanchey',
  PROV_KHM_BATTAMBANG: 'Battambang',
  PROV_KHM_KAMPONGCHAM: 'Kampong Cham',
  PROV_KHM_KAMPONGCHHNANG: 'Kampong Chhnang',
  PROV_KHM_KAMPONGSPEU: 'Kampong Speu',
  PROV_KHM_KAMPONGTHOM: 'Kampong Thom',
  PROV_KHM_KAMPOT: 'Kampot',
  PROV_KHM_KANDAL: 'Kandal',
  PROV_KHM_KEP: 'Kep',
  PROV_KHM_KOHKONG: 'Koh Kong',
  PROV_KHM_KRATIE: 'Kratie',
  PROV_KHM_MONDULKIRI: 'Mondulkiri',
  PROV_KHM_ODDARMEANCHEY: 'Oddar Meanchey',
  PROV_KHM_PAILIN: 'Pailin',
  PROV_KHM_PHNOMPENH: 'Phnom Penh',
  PROV_KHM_PREAHVIHEAR: 'Preah Vihear',
  PROV_KHM_PREYVENG: 'Prey Veng',
  PROV_KHM_PURSAT: 'Pursat',
  PROV_KHM_RATANAKIRI: 'Ratanakiri',
  PROV_KHM_SIEMREAP: 'Siem Reap',
  PROV_KHM_SIHANOUKVILLE: 'Sihanoukville',
  PROV_KHM_STUNGTRENG: 'Stung Treng',
  PROV_KHM_SVAYRIENG: 'Svay Rieng',
  PROV_KHM_TAKEO: 'Takeo',
  PROV_CMR_ADAMAWA: 'Adamawa',
  PROV_CMR_CENTRE: 'Centre',
  PROV_CMR_EAST: 'East',
  PROV_CMR_FARNORTH: 'Far North',
  PROV_CMR_LITTORAL: 'Littoral',
  PROV_CMR_NORTH: 'North',
  PROV_CMR_NORTHWEST: 'Northwest',
  PROV_CMR_SOUTH: 'South',
  PROV_CMR_SOUTHWEST: 'Southwest',
  PROV_CMR_WEST: 'West',
  PROV_CPV_BARLAVENTOISLANDS: 'Barlavento Islands',
  PROV_CPV_BOAVISTA: 'Boa Vista',
  PROV_CPV_BRAVA: 'Brava',
  PROV_CPV_MAIOMUNICIPALITY: 'Maio Municipality',
  PROV_CPV_MOSTEIROS: 'Mosteiros',
  PROV_CPV_PAUL: 'Paul',
  PROV_CPV_PORTONOVO: 'Porto Novo',
  PROV_CPV_PRAIA: 'Praia',
  PROV_CPV_RIBEIRABRAVAMUNICIPALITY: 'Ribeira Brava Municipality',
  PROV_CPV_RIBEIRAGRANDE: 'Ribeira Grande',
  PROV_CPV_RIBEIRAGRANDEDESANTIAGO: 'Ribeira Grande de Santiago',
  PROV_CPV_SAL: 'Sal',
  PROV_CPV_SANTACATARINA: 'Santa Catarina',
  PROV_CPV_SANTACATARINADOFOGO: 'Santa Catarina do Fogo',
  PROV_CPV_SANTACRUZ: 'Santa Cruz',
  PROV_CPV_SODOMINGOS: 'São Domingos',
  PROV_CPV_SOFILIPE: 'São Filipe',
  PROV_CPV_SOLOURENODOSRGOS: 'São Lourenço dos Órgãos',
  PROV_CPV_SOMIGUEL: 'São Miguel',
  PROV_CPV_SOVICENTE: 'São Vicente',
  PROV_CPV_SOTAVENTOISLANDS: 'Sotavento Islands',
  PROV_CPV_TARRAFAL: 'Tarrafal',
  PROV_CPV_TARRAFALDESONICOLAU: 'Tarrafal de São Nicolau',
  PROV_CYM_NOPROVINCE: 'No Province',
  PROV_CAF_BAMINGUIBANGORANPREFECTURE: 'Bamingui-Bangoran Prefecture',
  PROV_CAF_BANGUI: 'Bangui',
  PROV_CAF_BASSEKOTTOPREFECTURE: 'Basse-Kotto Prefecture',
  PROV_CAF_HAUTMBOMOUPREFECTURE: 'Haut-Mbomou Prefecture',
  PROV_CAF_HAUTEKOTTOPREFECTURE: 'Haute-Kotto Prefecture',
  PROV_CAF_KMOPREFECTURE: 'Kémo Prefecture',
  PROV_CAF_LOBAYEPREFECTURE: 'Lobaye Prefecture',
  PROV_CAF_MAMBRKAD: 'Mambéré-Kadéï',
  PROV_CAF_MBOMOUPREFECTURE: 'Mbomou Prefecture',
  PROV_CAF_NANAGRBIZIECONOMICPREFECTURE: 'Nana-Grébizi Economic Prefecture',
  PROV_CAF_NANAMAMBRPREFECTURE: 'Nana-Mambéré Prefecture',
  PROV_CAF_OMBELLAMPOKOPREFECTURE: "Ombella-M'Poko Prefecture",
  PROV_CAF_OUAKAPREFECTURE: 'Ouaka Prefecture',
  PROV_CAF_OUHAMPREFECTURE: 'Ouham Prefecture',
  PROV_CAF_OUHAMPENDPREFECTURE: 'Ouham-Pendé Prefecture',
  PROV_CAF_SANGHAMBAR: 'Sangha-Mbaéré',
  PROV_CAF_VAKAGAPREFECTURE: 'Vakaga Prefecture',
  PROV_TCD_BAHRELGAZEL: 'Bahr el Gazel',
  PROV_TCD_BATHAREGION: 'Batha Region',
  PROV_TCD_BORKOU: 'Borkou',
  PROV_TCD_ENNEDIREGION: 'Ennedi Region',
  PROV_TCD_ENNEDIEST: 'Ennedi-Est',
  PROV_TCD_ENNEDIOUEST: 'Ennedi-Ouest',
  PROV_TCD_GURAREGION: 'Guéra Region',
  PROV_TCD_HADJERLAMIS: 'Hadjer-Lamis',
  PROV_TCD_KANEMREGION: 'Kanem Region',
  PROV_TCD_LACREGION: 'Lac Region',
  PROV_TCD_LOGONEOCCIDENTALREGION: 'Logone Occidental Region',
  PROV_TCD_LOGONEORIENTALREGION: 'Logone Oriental Region',
  PROV_TCD_MANDOULREGION: 'Mandoul Region',
  PROV_TCD_MAYOKEBBIESTREGION: 'Mayo-Kebbi Est Region',
  PROV_TCD_MAYOKEBBIOUESTREGION: 'Mayo-Kebbi Ouest Region',
  PROV_TCD_MOYENCHARIREGION: 'Moyen-Chari Region',
  PROV_TCD_NDJAMENA: "N'Djamena",
  PROV_TCD_OUADDAREGION: 'Ouaddaï Region',
  PROV_TCD_SALAMATREGION: 'Salamat Region',
  PROV_TCD_SILAREGION: 'Sila Region',
  PROV_TCD_TANDJILREGION: 'Tandjilé Region',
  PROV_TCD_TIBESTIREGION: 'Tibesti Region',
  PROV_TCD_WADIFIRAREGION: 'Wadi Fira Region',
  PROV_CHL_AISNDELGENERALCARLOSIBAEZDELCAMPO: 'Aisén del General Carlos Ibañez del Campo',
  PROV_CHL_ANTOFAGASTA: 'Antofagasta',
  PROV_CHL_ARICAYPARINACOTA: 'Arica y Parinacota',
  PROV_CHL_ATACAMA: 'Atacama',
  PROV_CHL_BIOBO: 'Biobío',
  PROV_CHL_COQUIMBO: 'Coquimbo',
  PROV_CHL_LAARAUCANA: 'La Araucanía',
  PROV_CHL_LIBERTADORGENERALBERNARDOOHIGGINS: "Libertador General Bernardo O'Higgins",
  PROV_CHL_LOSLAGOS: 'Los Lagos',
  PROV_CHL_LOSROS: 'Los Ríos',
  PROV_CHL_MAGALLANESYDELAANTRTICACHILENA: 'Magallanes y de la Antártica Chilena',
  PROV_CHL_MAULE: 'Maule',
  PROV_CHL_UBLE: 'Ñuble',
  PROV_CHL_REGINMETROPOLITANADESANTIAGO: 'Región Metropolitana de Santiago',
  PROV_CHL_TARAPAC: 'Tarapacá',
  PROV_CHL_VALPARASO: 'Valparaíso',
  PROV_CHN_ANHUI: 'Anhui',
  PROV_CHN_BEIJING: 'Beijing',
  PROV_CHN_CHONGQING: 'Chongqing',
  PROV_CHN_FUJIAN: 'Fujian',
  PROV_CHN_GANSU: 'Gansu',
  PROV_CHN_GUANGDONG: 'Guangdong',
  PROV_CHN_GUANGXIZHUANG: 'Guangxi Zhuang',
  PROV_CHN_GUIZHOU: 'Guizhou',
  PROV_CHN_HAINAN: 'Hainan',
  PROV_CHN_HEBEI: 'Hebei',
  PROV_CHN_HEILONGJIANG: 'Heilongjiang',
  PROV_CHN_HENAN: 'Henan',
  PROV_CHN_HONGKONGSAR: 'Hong Kong SAR',
  PROV_CHN_HUBEI: 'Hubei',
  PROV_CHN_HUNAN: 'Hunan',
  PROV_CHN_INNERMONGOLIA: 'Inner Mongolia',
  PROV_CHN_JIANGSU: 'Jiangsu',
  PROV_CHN_JIANGXI: 'Jiangxi',
  PROV_CHN_JILIN: 'Jilin',
  PROV_CHN_LIAONING: 'Liaoning',
  PROV_CHN_MACAUSAR: 'Macau SAR',
  PROV_CHN_NINGXIAHUIZU: 'Ningxia Huizu',
  PROV_CHN_QINGHAI: 'Qinghai',
  PROV_CHN_SHAANXI: 'Shaanxi',
  PROV_CHN_SHANDONG: 'Shandong',
  PROV_CHN_SHANGHAI: 'Shanghai',
  PROV_CHN_SHANXI: 'Shanxi',
  PROV_CHN_SICHUAN: 'Sichuan',
  PROV_CHN_TAIWAN: 'Taiwan',
  PROV_CHN_TIANJIN: 'Tianjin',
  PROV_CHN_XINJIANG: 'Xinjiang',
  PROV_CHN_XIZANG: 'Xizang',
  PROV_CHN_YUNNAN: 'Yunnan',
  PROV_CHN_ZHEJIANG: 'Zhejiang',
  PROV_CXR_NOPROVINCE: 'No Province',
  PROV_CCK_NOPROVINCE: 'No Province',
  PROV_COL_AMAZONAS: 'Amazonas',
  PROV_COL_ANTIOQUIA: 'Antioquia',
  PROV_COL_ARAUCA: 'Arauca',
  PROV_COL_ARCHIPILAGODESANANDRSPROVIDENCIAYSANTACAT: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
  PROV_COL_ATLNTICO: 'Atlántico',
  PROV_COL_BOGOTDC: 'Bogotá D.C.',
  PROV_COL_BOLVAR: 'Bolívar',
  PROV_COL_BOYAC: 'Boyacá',
  PROV_COL_CALDAS: 'Caldas',
  PROV_COL_CAQUET: 'Caquetá',
  PROV_COL_CASANARE: 'Casanare',
  PROV_COL_CAUCA: 'Cauca',
  PROV_COL_CESAR: 'Cesar',
  PROV_COL_CHOC: 'Chocó',
  PROV_COL_CRDOBA: 'Córdoba',
  PROV_COL_CUNDINAMARCA: 'Cundinamarca',
  PROV_COL_GUAINA: 'Guainía',
  PROV_COL_GUAVIARE: 'Guaviare',
  PROV_COL_HUILA: 'Huila',
  PROV_COL_LAGUAJIRA: 'La Guajira',
  PROV_COL_MAGDALENA: 'Magdalena',
  PROV_COL_META: 'Meta',
  PROV_COL_NARIO: 'Nariño',
  PROV_COL_NORTEDESANTANDER: 'Norte de Santander',
  PROV_COL_PUTUMAYO: 'Putumayo',
  PROV_COL_QUINDO: 'Quindío',
  PROV_COL_RISARALDA: 'Risaralda',
  PROV_COL_SANTANDER: 'Santander',
  PROV_COL_SUCRE: 'Sucre',
  PROV_COL_TOLIMA: 'Tolima',
  PROV_COL_VALLEDELCAUCA: 'Valle del Cauca',
  PROV_COL_VAUPS: 'Vaupés',
  PROV_COL_VICHADA: 'Vichada',
  PROV_COM_ANJOUAN: 'Anjouan',
  PROV_COM_GRANDECOMORE: 'Grande Comore',
  PROV_COM_MOHLI: 'Mohéli',
  PROV_COG_BOUENZADEPARTMENT: 'Bouenza Department',
  PROV_COG_BRAZZAVILLE: 'Brazzaville',
  PROV_COG_CUVETTEDEPARTMENT: 'Cuvette Department',
  PROV_COG_CUVETTEOUESTDEPARTMENT: 'Cuvette-Ouest Department',
  PROV_COG_KOUILOUDEPARTMENT: 'Kouilou Department',
  PROV_COG_LKOUMOUDEPARTMENT: 'Lékoumou Department',
  PROV_COG_LIKOUALADEPARTMENT: 'Likouala Department',
  PROV_COG_NIARIDEPARTMENT: 'Niari Department',
  PROV_COG_PLATEAUXDEPARTMENT: 'Plateaux Department',
  PROV_COG_POINTENOIRE: 'Pointe-Noire',
  PROV_COG_POOLDEPARTMENT: 'Pool Department',
  PROV_COG_SANGHADEPARTMENT: 'Sangha Department',
  PROV_COK_NOPROVINCE: 'No Province',
  PROV_CRI_ALAJUELAPROVINCE: 'Alajuela Province',
  PROV_CRI_GUANACASTEPROVINCE: 'Guanacaste Province',
  PROV_CRI_HEREDIAPROVINCE: 'Heredia Province',
  PROV_CRI_LIMNPROVINCE: 'Limón Province',
  PROV_CRI_PROVINCIADECARTAGO: 'Provincia de Cartago',
  PROV_CRI_PUNTARENASPROVINCE: 'Puntarenas Province',
  PROV_CRI_SANJOSPROVINCE: 'San José Province',
  PROV_CIV_ABIDJAN: 'Abidjan',
  PROV_CIV_AGNBY: 'Agnéby',
  PROV_CIV_BAFINGREGION: 'Bafing Region',
  PROV_CIV_BASSASSANDRADISTRICT: 'Bas-Sassandra District',
  PROV_CIV_BASSASSANDRAREGION: 'Bas-Sassandra Region',
  PROV_CIV_COMODISTRICT: 'Comoé District',
  PROV_CIV_DENGULDISTRICT: 'Denguélé District',
  PROV_CIV_DENGULREGION: 'Denguélé Region',
  PROV_CIV_DIXHUITMONTAGNES: 'Dix-Huit Montagnes',
  PROV_CIV_FROMAGER: 'Fromager',
  PROV_CIV_GHDJIBOUADISTRICT: 'Gôh-Djiboua District',
  PROV_CIV_HAUTSASSANDRA: 'Haut-Sassandra',
  PROV_CIV_LACSDISTRICT: 'Lacs District',
  PROV_CIV_LACSREGION: 'Lacs Region',
  PROV_CIV_LAGUNESDISTRICT: 'Lagunes District',
  PROV_CIV_LAGUNESREGION: 'Lagunes region',
  PROV_CIV_MARAHOUREGION: 'Marahoué Region',
  PROV_CIV_MONTAGNESDISTRICT: 'Montagnes District',
  PROV_CIV_MOYENCAVALLY: 'Moyen-Cavally',
  PROV_CIV_MOYENCOMO: 'Moyen-Comoé',
  PROV_CIV_NZICOMO: "N'zi-Comoé",
  PROV_CIV_SASSANDRAMARAHOUDISTRICT: 'Sassandra-Marahoué District',
  PROV_CIV_SAVANESREGION: 'Savanes Region',
  PROV_CIV_SUDBANDAMA: 'Sud-Bandama',
  PROV_CIV_SUDCOMO: 'Sud-Comoé',
  PROV_CIV_VALLEDUBANDAMADISTRICT: 'Vallée du Bandama District',
  PROV_CIV_VALLEDUBANDAMAREGION: 'Vallée du Bandama Region',
  PROV_CIV_WOROBADISTRICT: 'Woroba District',
  PROV_CIV_WORODOUGOU: 'Worodougou',
  PROV_CIV_YAMOUSSOUKRO: 'Yamoussoukro',
  PROV_CIV_ZANZANREGION: 'Zanzan Region',
  PROV_HRV_BJELOVARBILOGORA: 'Bjelovar-Bilogora',
  PROV_HRV_BRODPOSAVINA: 'Brod-Posavina',
  PROV_HRV_DUBROVNIKNERETVA: 'Dubrovnik-Neretva',
  PROV_HRV_ISTRIA: 'Istria',
  PROV_HRV_KARLOVAC: 'Karlovac',
  PROV_HRV_KOPRIVNICAKRIEVCI: 'Koprivnica-Križevci',
  PROV_HRV_KRAPINAZAGORJE: 'Krapina-Zagorje',
  PROV_HRV_LIKASENJ: 'Lika-Senj',
  PROV_HRV_MEIMURJE: 'Međimurje',
  PROV_HRV_OSIJEKBARANJA: 'Osijek-Baranja',
  PROV_HRV_POEGASLAVONIA: 'Požega-Slavonia',
  PROV_HRV_PRIMORJEGORSKIKOTAR: 'Primorje-Gorski Kotar',
  PROV_HRV_IBENIKKNIN: 'Šibenik-Knin',
  PROV_HRV_SISAKMOSLAVINA: 'Sisak-Moslavina',
  PROV_HRV_SPLITDALMATIA: 'Split-Dalmatia',
  PROV_HRV_VARADIN: 'Varaždin',
  PROV_HRV_VIROVITICAPODRAVINA: 'Virovitica-Podravina',
  PROV_HRV_VUKOVARSYRMIA: 'Vukovar-Syrmia',
  PROV_HRV_ZADAR: 'Zadar',
  PROV_HRV_ZAGREBCOUNTY: 'Zagreb County',
  PROV_HRV_ZAGREB: 'Zagreb',
  PROV_CUB_ARTEMISAPROVINCE: 'Artemisa Province',
  PROV_CUB_CAMAGEYPROVINCE: 'Camagüey Province',
  PROV_CUB_CIEGODEVILAPROVINCE: 'Ciego de Ávila Province',
  PROV_CUB_CIENFUEGOSPROVINCE: 'Cienfuegos Province',
  PROV_CUB_GRANMAPROVINCE: 'Granma Province',
  PROV_CUB_GUANTNAMOPROVINCE: 'Guantánamo Province',
  PROV_CUB_HAVANAPROVINCE: 'Havana Province',
  PROV_CUB_HOLGUNPROVINCE: 'Holguín Province',
  PROV_CUB_ISLADELAJUVENTUD: 'Isla de la Juventud',
  PROV_CUB_LASTUNASPROVINCE: 'Las Tunas Province',
  PROV_CUB_MATANZASPROVINCE: 'Matanzas Province',
  PROV_CUB_MAYABEQUEPROVINCE: 'Mayabeque Province',
  PROV_CUB_PINARDELROPROVINCE: 'Pinar del Río Province',
  PROV_CUB_SANCTISPRITUSPROVINCE: 'Sancti Spíritus Province',
  PROV_CUB_SANTIAGODECUBAPROVINCE: 'Santiago de Cuba Province',
  PROV_CUB_VILLACLARAPROVINCE: 'Villa Clara Province',
  PROV_CUW_NOPROVINCE: 'No Province',
  PROV_CYP_FAMAGUSTADISTRICTMAUSA: 'Famagusta District (Mağusa)',
  PROV_CYP_KYRENIADISTRICTKERYNEIA: 'Kyrenia District (Keryneia)',
  PROV_CYP_LARNACADISTRICTLARNAKA: 'Larnaca District (Larnaka)',
  PROV_CYP_LIMASSOLDISTRICTLEYMASUN: 'Limassol District (Leymasun)',
  PROV_CYP_NICOSIADISTRICTLEFKOA: 'Nicosia District (Lefkoşa)',
  PROV_CYP_PAPHOSDISTRICTPAFOS: 'Paphos District (Pafos)',
  PROV_CZE_BENEOV: 'Benešov',
  PROV_CZE_BEROUN: 'Beroun',
  PROV_CZE_BLANSKO: 'Blansko',
  PROV_CZE_BECLAV: 'Břeclav',
  PROV_CZE_BRNOMSTO: 'Brno-město',
  PROV_CZE_BRNOVENKOV: 'Brno-venkov',
  PROV_CZE_BRUNTL: 'Bruntál',
  PROV_CZE_ESKLPA: 'Česká Lípa',
  PROV_CZE_ESKBUDJOVICE: 'České Budějovice',
  PROV_CZE_ESKKRUMLOV: 'Český Krumlov',
  PROV_CZE_CHEB: 'Cheb',
  PROV_CZE_CHOMUTOV: 'Chomutov',
  PROV_CZE_CHRUDIM: 'Chrudim',
  PROV_CZE_DN: 'Děčín',
  PROV_CZE_DOMALICE: 'Domažlice',
  PROV_CZE_FRDEKMSTEK: 'Frýdek-Místek',
  PROV_CZE_HAVLKVBROD: 'Havlíčkův Brod',
  PROV_CZE_HODONN: 'Hodonín',
  PROV_CZE_HRADECKRLOV: 'Hradec Králové',
  PROV_CZE_JABLONECNADNISOU: 'Jablonec nad Nisou',
  PROV_CZE_JESENK: 'Jeseník',
  PROV_CZE_JIN: 'Jičín',
  PROV_CZE_JIHLAVA: 'Jihlava',
  PROV_CZE_JIHOESKKRAJ: 'Jihočeský kraj',
  PROV_CZE_JIHOMORAVSKKRAJ: 'Jihomoravský kraj',
  PROV_CZE_JINDICHVHRADEC: 'Jindřichův Hradec',
  PROV_CZE_KARLOVARSKKRAJ: 'Karlovarský kraj',
  PROV_CZE_KARLOVYVARY: 'Karlovy Vary',
  PROV_CZE_KARVIN: 'Karviná',
  PROV_CZE_KLADNO: 'Kladno',
  PROV_CZE_KLATOVY: 'Klatovy',
  PROV_CZE_KOLN: 'Kolín',
  PROV_CZE_KRAJVYSOINA: 'Kraj Vysočina',
  PROV_CZE_KRLOVHRADECKKRAJ: 'Královéhradecký kraj',
  PROV_CZE_KROM: 'Kroměříž',
  PROV_CZE_KUTNHORA: 'Kutná Hora',
  PROV_CZE_LIBEREC: 'Liberec',
  PROV_CZE_LIBERECKKRAJ: 'Liberecký kraj',
  PROV_CZE_LITOMICE: 'Litoměřice',
  PROV_CZE_LOUNY: 'Louny',
  PROV_CZE_MLNK: 'Mělník',
  PROV_CZE_MLADBOLESLAV: 'Mladá Boleslav',
  PROV_CZE_MORAVSKOSLEZSKKRAJ: 'Moravskoslezský kraj',
  PROV_CZE_MOST: 'Most',
  PROV_CZE_NCHOD: 'Náchod',
  PROV_CZE_NOVJIN: 'Nový Jičín',
  PROV_CZE_NYMBURK: 'Nymburk',
  PROV_CZE_OLOMOUC: 'Olomouc',
  PROV_CZE_OLOMOUCKKRAJ: 'Olomoucký kraj',
  PROV_CZE_OPAVA: 'Opava',
  PROV_CZE_OSTRAVAMSTO: 'Ostrava-město',
  PROV_CZE_PARDUBICE: 'Pardubice',
  PROV_CZE_PARDUBICKKRAJ: 'Pardubický kraj',
  PROV_CZE_PELHIMOV: 'Pelhřimov',
  PROV_CZE_PSEK: 'Písek',
  PROV_CZE_PLZEJIH: 'Plzeň-jih',
  PROV_CZE_PLZEMSTO: 'Plzeň-město',
  PROV_CZE_PLZESEVER: 'Plzeň-sever',
  PROV_CZE_PLZESKKRAJ: 'Plzeňský kraj',
  PROV_CZE_PRACHATICE: 'Prachatice',
  PROV_CZE_PRAHAVCHOD: 'Praha-východ',
  PROV_CZE_PRAHAZPAD: 'Praha-západ',
  PROV_CZE_PRAHAHLAVNMSTO: 'Praha, Hlavní město',
  PROV_CZE_PEROV: 'Přerov',
  PROV_CZE_PBRAM: 'Příbram',
  PROV_CZE_PROSTJOV: 'Prostějov',
  PROV_CZE_RAKOVNK: 'Rakovník',
  PROV_CZE_ROKYCANY: 'Rokycany',
  PROV_CZE_RYCHNOVNADKNNOU: 'Rychnov nad Kněžnou',
  PROV_CZE_SEMILY: 'Semily',
  PROV_CZE_SOKOLOV: 'Sokolov',
  PROV_CZE_STRAKONICE: 'Strakonice',
  PROV_CZE_STEDOESKKRAJ: 'Středočeský kraj',
  PROV_CZE_UMPERK: 'Šumperk',
  PROV_CZE_SVITAVY: 'Svitavy',
  PROV_CZE_TBOR: 'Tábor',
  PROV_CZE_TACHOV: 'Tachov',
  PROV_CZE_TEPLICE: 'Teplice',
  PROV_CZE_TEB: 'Třebíč',
  PROV_CZE_TRUTNOV: 'Trutnov',
  PROV_CZE_UHERSKHRADIT: 'Uherské Hradiště',
  PROV_CZE_STECKKRAJ: 'Ústecký kraj',
  PROV_CZE_STNADLABEM: 'Ústí nad Labem',
  PROV_CZE_STNADORLIC: 'Ústí nad Orlicí',
  PROV_CZE_VSETN: 'Vsetín',
  PROV_CZE_VYKOV: 'Vyškov',
  PROV_CZE_RNADSZAVOU: 'Žďár nad Sázavou',
  PROV_CZE_ZLN: 'Zlín',
  PROV_CZE_ZLNSKKRAJ: 'Zlínský kraj',
  PROV_CZE_ZNOJMO: 'Znojmo',
  PROV_COD_BASUL: 'Bas-Uélé',
  PROV_COD_QUATEUR: 'Équateur',
  PROV_COD_HAUTKATANGA: 'Haut-Katanga',
  PROV_COD_HAUTLOMAMI: 'Haut-Lomami',
  PROV_COD_HAUTUL: 'Haut-Uélé',
  PROV_COD_ITURI: 'Ituri',
  PROV_COD_KASA: 'Kasaï',
  PROV_COD_KASACENTRAL: 'Kasaï Central',
  PROV_COD_KASAORIENTAL: 'Kasaï Oriental',
  PROV_COD_KINSHASA: 'Kinshasa',
  PROV_COD_KONGOCENTRAL: 'Kongo Central',
  PROV_COD_KWANGO: 'Kwango',
  PROV_COD_KWILU: 'Kwilu',
  PROV_COD_LOMAMI: 'Lomami',
  PROV_COD_LUALABA: 'Lualaba',
  PROV_COD_MAINDOMBE: 'Mai-Ndombe',
  PROV_COD_MANIEMA: 'Maniema',
  PROV_COD_MONGALA: 'Mongala',
  PROV_COD_NORDKIVU: 'Nord-Kivu',
  PROV_COD_NORDUBANGI: 'Nord-Ubangi',
  PROV_COD_SANKURU: 'Sankuru',
  PROV_COD_SUDKIVU: 'Sud-Kivu',
  PROV_COD_SUDUBANGI: 'Sud-Ubangi',
  PROV_COD_TANGANYIKA: 'Tanganyika',
  PROV_COD_TSHOPO: 'Tshopo',
  PROV_COD_TSHUAPA: 'Tshuapa',
  PROV_DNK_CAPITALREGIONOFDENMARK: 'Capital Region of Denmark',
  PROV_DNK_CENTRALDENMARKREGION: 'Central Denmark Region',
  PROV_DNK_NORTHDENMARKREGION: 'North Denmark Region',
  PROV_DNK_REGIONOFSOUTHERNDENMARK: 'Region of Southern Denmark',
  PROV_DNK_REGIONZEALAND: 'Region Zealand',
  PROV_DJI_ALISABIEHREGION: 'Ali Sabieh Region',
  PROV_DJI_ARTAREGION: 'Arta Region',
  PROV_DJI_DIKHILREGION: 'Dikhil Region',
  PROV_DJI_DJIBOUTI: 'Djibouti',
  PROV_DJI_OBOCKREGION: 'Obock Region',
  PROV_DJI_TADJOURAHREGION: 'Tadjourah Region',
  PROV_DMA_SAINTANDREWPARISH: 'Saint Andrew Parish',
  PROV_DMA_SAINTDAVIDPARISH: 'Saint David Parish',
  PROV_DMA_SAINTGEORGEPARISH: 'Saint George Parish',
  PROV_DMA_SAINTJOHNPARISH: 'Saint John Parish',
  PROV_DMA_SAINTJOSEPHPARISH: 'Saint Joseph Parish',
  PROV_DMA_SAINTLUKEPARISH: 'Saint Luke Parish',
  PROV_DMA_SAINTMARKPARISH: 'Saint Mark Parish',
  PROV_DMA_SAINTPATRICKPARISH: 'Saint Patrick Parish',
  PROV_DMA_SAINTPAULPARISH: 'Saint Paul Parish',
  PROV_DMA_SAINTPETERPARISH: 'Saint Peter Parish',
  PROV_DOM_AZUAPROVINCE: 'Azua Province',
  PROV_DOM_BAORUCOPROVINCE: 'Baoruco Province',
  PROV_DOM_BARAHONAPROVINCE: 'Barahona Province',
  PROV_DOM_DAJABNPROVINCE: 'Dajabón Province',
  PROV_DOM_DISTRITONACIONAL: 'Distrito Nacional',
  PROV_DOM_DUARTEPROVINCE: 'Duarte Province',
  PROV_DOM_ELSEIBOPROVINCE: 'El Seibo Province',
  PROV_DOM_ESPAILLATPROVINCE: 'Espaillat Province',
  PROV_DOM_HATOMAYORPROVINCE: 'Hato Mayor Province',
  PROV_DOM_HERMANASMIRABALPROVINCE: 'Hermanas Mirabal Province',
  PROV_DOM_INDEPENDENCIA: 'Independencia',
  PROV_DOM_LAALTAGRACIAPROVINCE: 'La Altagracia Province',
  PROV_DOM_LAROMANAPROVINCE: 'La Romana Province',
  PROV_DOM_LAVEGAPROVINCE: 'La Vega Province',
  PROV_DOM_MARATRINIDADSNCHEZPROVINCE: 'María Trinidad Sánchez Province',
  PROV_DOM_MONSEORNOUELPROVINCE: 'Monseñor Nouel Province',
  PROV_DOM_MONTECRISTIPROVINCE: 'Monte Cristi Province',
  PROV_DOM_MONTEPLATAPROVINCE: 'Monte Plata Province',
  PROV_DOM_PEDERNALESPROVINCE: 'Pedernales Province',
  PROV_DOM_PERAVIAPROVINCE: 'Peravia Province',
  PROV_DOM_PUERTOPLATAPROVINCE: 'Puerto Plata Province',
  PROV_DOM_SAMANPROVINCE: 'Samaná Province',
  PROV_DOM_SANCRISTBALPROVINCE: 'San Cristóbal Province',
  PROV_DOM_SANJOSDEOCOAPROVINCE: 'San José de Ocoa Province',
  PROV_DOM_SANJUANPROVINCE: 'San Juan Province',
  PROV_DOM_SANPEDRODEMACORS: 'San Pedro de Macorís',
  PROV_DOM_SNCHEZRAMREZPROVINCE: 'Sánchez Ramírez Province',
  PROV_DOM_SANTIAGOPROVINCE: 'Santiago Province',
  PROV_DOM_SANTIAGORODRGUEZPROVINCE: 'Santiago Rodríguez Province',
  PROV_DOM_SANTODOMINGOPROVINCE: 'Santo Domingo Province',
  PROV_DOM_VALVERDEPROVINCE: 'Valverde Province',
  PROV_TLS_AILEUMUNICIPALITY: 'Aileu municipality',
  PROV_TLS_AINAROMUNICIPALITY: 'Ainaro Municipality',
  PROV_TLS_BAUCAUMUNICIPALITY: 'Baucau Municipality',
  PROV_TLS_BOBONAROMUNICIPALITY: 'Bobonaro Municipality',
  PROV_TLS_COVALIMAMUNICIPALITY: 'Cova Lima Municipality',
  PROV_TLS_DILIMUNICIPALITY: 'Dili municipality',
  PROV_TLS_ERMERADISTRICT: 'Ermera District',
  PROV_TLS_LAUTMMUNICIPALITY: 'Lautém Municipality',
  PROV_TLS_LIQUIMUNICIPALITY: 'Liquiçá Municipality',
  PROV_TLS_MANATUTODISTRICT: 'Manatuto District',
  PROV_TLS_MANUFAHIMUNICIPALITY: 'Manufahi Municipality',
  PROV_TLS_VIQUEQUEMUNICIPALITY: 'Viqueque Municipality',
  PROV_ECU_AZUAY: 'Azuay',
  PROV_ECU_BOLVAR: 'Bolívar',
  PROV_ECU_CAAR: 'Cañar',
  PROV_ECU_CARCHI: 'Carchi',
  PROV_ECU_CHIMBORAZO: 'Chimborazo',
  PROV_ECU_COTOPAXI: 'Cotopaxi',
  PROV_ECU_ELORO: 'El Oro',
  PROV_ECU_ESMERALDAS: 'Esmeraldas',
  PROV_ECU_GALPAGOS: 'Galápagos',
  PROV_ECU_GUAYAS: 'Guayas',
  PROV_ECU_IMBABURA: 'Imbabura',
  PROV_ECU_LOJA: 'Loja',
  PROV_ECU_LOSROS: 'Los Ríos',
  PROV_ECU_MANAB: 'Manabí',
  PROV_ECU_MORONASANTIAGO: 'Morona-Santiago',
  PROV_ECU_NAPO: 'Napo',
  PROV_ECU_ORELLANA: 'Orellana',
  PROV_ECU_PASTAZA: 'Pastaza',
  PROV_ECU_PICHINCHA: 'Pichincha',
  PROV_ECU_SANTAELENA: 'Santa Elena',
  PROV_ECU_SANTODOMINGODELOSTSCHILAS: 'Santo Domingo de los Tsáchilas',
  PROV_ECU_SUCUMBOS: 'Sucumbíos',
  PROV_ECU_TUNGURAHUA: 'Tungurahua',
  PROV_ECU_ZAMORACHINCHIPE: 'Zamora Chinchipe',
  PROV_EGY_ALEXANDRIA: 'Alexandria',
  PROV_EGY_ASWAN: 'Aswan',
  PROV_EGY_ASYUT: 'Asyut',
  PROV_EGY_BEHEIRA: 'Beheira',
  PROV_EGY_BENISUEF: 'Beni Suef',
  PROV_EGY_CAIRO: 'Cairo',
  PROV_EGY_DAKAHLIA: 'Dakahlia',
  PROV_EGY_DAMIETTA: 'Damietta',
  PROV_EGY_FAIYUM: 'Faiyum',
  PROV_EGY_GHARBIA: 'Gharbia',
  PROV_EGY_GIZA: 'Giza',
  PROV_EGY_ISMAILIA: 'Ismailia',
  PROV_EGY_KAFRELSHEIKH: 'Kafr el-Sheikh',
  PROV_EGY_LUXOR: 'Luxor',
  PROV_EGY_MATROUH: 'Matrouh',
  PROV_EGY_MINYA: 'Minya',
  PROV_EGY_MONUFIA: 'Monufia',
  PROV_EGY_NEWVALLEY: 'New Valley',
  PROV_EGY_NORTHSINAI: 'North Sinai',
  PROV_EGY_PORTSAID: 'Port Said',
  PROV_EGY_QALYUBIA: 'Qalyubia',
  PROV_EGY_QENA: 'Qena',
  PROV_EGY_REDSEA: 'Red Sea',
  PROV_EGY_SHARQIA: 'Sharqia',
  PROV_EGY_SOHAG: 'Sohag',
  PROV_EGY_SOUTHSINAI: 'South Sinai',
  PROV_EGY_SUEZ: 'Suez',
  PROV_SLV_AHUACHAPNDEPARTMENT: 'Ahuachapán Department',
  PROV_SLV_CABAASDEPARTMENT: 'Cabañas Department',
  PROV_SLV_CHALATENANGODEPARTMENT: 'Chalatenango Department',
  PROV_SLV_CUSCATLNDEPARTMENT: 'Cuscatlán Department',
  PROV_SLV_LALIBERTADDEPARTMENT: 'La Libertad Department',
  PROV_SLV_LAPAZDEPARTMENT: 'La Paz Department',
  PROV_SLV_LAUNINDEPARTMENT: 'La Unión Department',
  PROV_SLV_MORAZNDEPARTMENT: 'Morazán Department',
  PROV_SLV_SANMIGUELDEPARTMENT: 'San Miguel Department',
  PROV_SLV_SANSALVADORDEPARTMENT: 'San Salvador Department',
  PROV_SLV_SANVICENTEDEPARTMENT: 'San Vicente Department',
  PROV_SLV_SANTAANADEPARTMENT: 'Santa Ana Department',
  PROV_SLV_SONSONATEDEPARTMENT: 'Sonsonate Department',
  PROV_SLV_USULUTNDEPARTMENT: 'Usulután Department',
  PROV_GNQ_ANNOBNPROVINCE: 'Annobón Province',
  PROV_GNQ_BIOKONORTEPROVINCE: 'Bioko Norte Province',
  PROV_GNQ_BIOKOSURPROVINCE: 'Bioko Sur Province',
  PROV_GNQ_CENTROSURPROVINCE: 'Centro Sur Province',
  PROV_GNQ_INSULARREGION: 'Insular Region',
  PROV_GNQ_KINTEMPROVINCE: 'Kié-Ntem Province',
  PROV_GNQ_LITORALPROVINCE: 'Litoral Province',
  PROV_GNQ_ROMUNI: 'Río Muni',
  PROV_GNQ_WELENZASPROVINCE: 'Wele-Nzas Province',
  PROV_ERI_ANSEBAREGION: 'Anseba Region',
  PROV_ERI_DEBUBREGION: 'Debub Region',
  PROV_ERI_GASHBARKAREGION: 'Gash-Barka Region',
  PROV_ERI_MAEKELREGION: 'Maekel Region',
  PROV_ERI_NORTHERNREDSEAREGION: 'Northern Red Sea Region',
  PROV_ERI_SOUTHERNREDSEAREGION: 'Southern Red Sea Region',
  PROV_EST_HARJUCOUNTY: 'Harju County',
  PROV_EST_HIIUCOUNTY: 'Hiiu County',
  PROV_EST_IDAVIRUCOUNTY: 'Ida-Viru County',
  PROV_EST_JRVACOUNTY: 'Järva County',
  PROV_EST_JGEVACOUNTY: 'Jõgeva County',
  PROV_EST_LNECOUNTY: 'Lääne County',
  PROV_EST_LNEVIRUCOUNTY: 'Lääne-Viru County',
  PROV_EST_PRNUCOUNTY: 'Pärnu County',
  PROV_EST_PLVACOUNTY: 'Põlva County',
  PROV_EST_RAPLACOUNTY: 'Rapla County',
  PROV_EST_SAARECOUNTY: 'Saare County',
  PROV_EST_TARTUCOUNTY: 'Tartu County',
  PROV_EST_VALGACOUNTY: 'Valga County',
  PROV_EST_VILJANDICOUNTY: 'Viljandi County',
  PROV_EST_VRUCOUNTY: 'Võru County',
  PROV_ETH_ADDISABABA: 'Addis Ababa',
  PROV_ETH_AFARREGION: 'Afar Region',
  PROV_ETH_AMHARAREGION: 'Amhara Region',
  PROV_ETH_BENISHANGULGUMUZREGION: 'Benishangul-Gumuz Region',
  PROV_ETH_DIREDAWA: 'Dire Dawa',
  PROV_ETH_GAMBELAREGION: 'Gambela Region',
  PROV_ETH_HARARIREGION: 'Harari Region',
  PROV_ETH_OROMIAREGION: 'Oromia Region',
  PROV_ETH_SOMALIREGION: 'Somali Region',
  PROV_ETH_SOUTHERNNATIONSNATIONALITIESANDPEOPLESREG: "Southern Nations, Nationalities, and Peoples' Region",
  PROV_ETH_TIGRAYREGION: 'Tigray Region',
  PROV_FLK_NOPROVINCE: 'No Province',
  PROV_FRO_NOPROVINCE: 'No Province',
  PROV_FJI_CENTRALDIVISION: 'Central Division',
  PROV_FJI_EASTERNDIVISION: 'Eastern Division',
  PROV_FJI_NORTHERNDIVISION: 'Northern Division',
  PROV_FJI_ROTUMA: 'Rotuma',
  PROV_FJI_WESTERNDIVISION: 'Western Division',
  PROV_FIN_LANDISLANDS: 'Åland Islands',
  PROV_FIN_CENTRALFINLAND: 'Central Finland',
  PROV_FIN_CENTRALOSTROBOTHNIA: 'Central Ostrobothnia',
  PROV_FIN_EASTERNFINLANDPROVINCE: 'Eastern Finland Province',
  PROV_FIN_FINLANDPROPER: 'Finland Proper',
  PROV_FIN_KAINUU: 'Kainuu',
  PROV_FIN_KYMENLAAKSO: 'Kymenlaakso',
  PROV_FIN_LAPLAND: 'Lapland',
  PROV_FIN_NORTHKARELIA: 'North Karelia',
  PROV_FIN_NORTHERNOSTROBOTHNIA: 'Northern Ostrobothnia',
  PROV_FIN_NORTHERNSAVONIA: 'Northern Savonia',
  PROV_FIN_OSTROBOTHNIA: 'Ostrobothnia',
  PROV_FIN_OULUPROVINCE: 'Oulu Province',
  PROV_FIN_PIJNNETAVASTIA: 'Päijänne Tavastia',
  PROV_FIN_PIRKANMAA: 'Pirkanmaa',
  PROV_FIN_SATAKUNTA: 'Satakunta',
  PROV_FIN_SOUTHKARELIA: 'South Karelia',
  PROV_FIN_SOUTHERNOSTROBOTHNIA: 'Southern Ostrobothnia',
  PROV_FIN_SOUTHERNSAVONIA: 'Southern Savonia',
  PROV_FIN_TAVASTIAPROPER: 'Tavastia Proper',
  PROV_FIN_UUSIMAA: 'Uusimaa',
  PROV_FRA_AUVERGNERHNEALPES: 'Auvergne-Rhône-Alpes',
  PROV_FRA_BOURGOGNEFRANCHECOMT: 'Bourgogne-Franche-Comté',
  PROV_FRA_BRETAGNE: 'Bretagne',
  PROV_FRA_CENTREVALDELOIRE: 'Centre-Val de Loire',
  PROV_FRA_CORSE: 'Corse',
  PROV_FRA_FRENCHGUIANA: 'French Guiana',
  PROV_FRA_GRANDEST: 'Grand-Est',
  PROV_FRA_GUADELOUPE: 'Guadeloupe',
  PROV_FRA_HAUTSDEFRANCE: 'Hauts-de-France',
  PROV_FRA_LEDEFRANCE: 'Île-de-France',
  PROV_FRA_LARUNION: 'La Réunion',
  PROV_FRA_MARTINIQUE: 'Martinique',
  PROV_FRA_MAYOTTE: 'Mayotte',
  PROV_FRA_NORMANDIE: 'Normandie',
  PROV_FRA_NOUVELLEAQUITAINE: 'Nouvelle-Aquitaine',
  PROV_FRA_OCCITANIE: 'Occitanie',
  PROV_FRA_PAYSDELALOIRE: 'Pays-de-la-Loire',
  PROV_FRA_PROVENCEALPESCTEDAZUR: 'Provence-Alpes-Côte-d’Azur',
  PROV_GUF_NOPROVINCE: 'No Province',
  PROV_PYF_NOPROVINCE: 'No Province',
  PROV_ATF_NOPROVINCE: 'No Province',
  PROV_GAB_ESTUAIREPROVINCE: 'Estuaire Province',
  PROV_GAB_HAUTOGOOUPROVINCE: 'Haut-Ogooué Province',
  PROV_GAB_MOYENOGOOUPROVINCE: 'Moyen-Ogooué Province',
  PROV_GAB_NGOUNIPROVINCE: 'Ngounié Province',
  PROV_GAB_NYANGAPROVINCE: 'Nyanga Province',
  PROV_GAB_OGOOUIVINDOPROVINCE: 'Ogooué-Ivindo Province',
  PROV_GAB_OGOOULOLOPROVINCE: 'Ogooué-Lolo Province',
  PROV_GAB_OGOOUMARITIMEPROVINCE: 'Ogooué-Maritime Province',
  PROV_GAB_WOLEUNTEMPROVINCE: 'Woleu-Ntem Province',
  PROV_GMB_BANJUL: 'Banjul',
  PROV_GMB_CENTRALRIVERDIVISION: 'Central River Division',
  PROV_GMB_LOWERRIVERDIVISION: 'Lower River Division',
  PROV_GMB_NORTHBANKDIVISION: 'North Bank Division',
  PROV_GMB_UPPERRIVERDIVISION: 'Upper River Division',
  PROV_GMB_WESTCOASTDIVISION: 'West Coast Division',
  PROV_GEO_ADJARA: 'Adjara',
  PROV_GEO_AUTONOMOUSREPUBLICOFABKHAZIA: 'Autonomous Republic of Abkhazia',
  PROV_GEO_GURIA: 'Guria',
  PROV_GEO_IMERETI: 'Imereti',
  PROV_GEO_KAKHETI: 'Kakheti',
  PROV_GEO_KHELVACHAURIMUNICIPALITY: 'Khelvachauri Municipality',
  PROV_GEO_KVEMOKARTLI: 'Kvemo Kartli',
  PROV_GEO_MTSKHETAMTIANETI: 'Mtskheta-Mtianeti',
  PROV_GEO_RACHALECHKHUMIANDKVEMOSVANETI: 'Racha-Lechkhumi and Kvemo Svaneti',
  PROV_GEO_SAMEGRELOZEMOSVANETI: 'Samegrelo-Zemo Svaneti',
  PROV_GEO_SAMTSKHEJAVAKHETI: 'Samtskhe-Javakheti',
  PROV_GEO_SENAKIMUNICIPALITY: 'Senaki Municipality',
  PROV_GEO_SHIDAKARTLI: 'Shida Kartli',
  PROV_GEO_TBILISI: 'Tbilisi',
  PROV_DEU_BADENWRTTEMBERG: 'Baden-Württemberg',
  PROV_DEU_BAVARIA: 'Bavaria',
  PROV_DEU_BERLIN: 'Berlin',
  PROV_DEU_BRANDENBURG: 'Brandenburg',
  PROV_DEU_BREMEN: 'Bremen',
  PROV_DEU_HAMBURG: 'Hamburg',
  PROV_DEU_HESSE: 'Hesse',
  PROV_DEU_LOWERSAXONY: 'Lower Saxony',
  PROV_DEU_MECKLENBURGVORPOMMERN: 'Mecklenburg-Vorpommern',
  PROV_DEU_NORTHRHINEWESTPHALIA: 'North Rhine-Westphalia',
  PROV_DEU_RHINELANDPALATINATE: 'Rhineland-Palatinate',
  PROV_DEU_SAARLAND: 'Saarland',
  PROV_DEU_SAXONY: 'Saxony',
  PROV_DEU_SAXONYANHALT: 'Saxony-Anhalt',
  PROV_DEU_SCHLESWIGHOLSTEIN: 'Schleswig-Holstein',
  PROV_DEU_THURINGIA: 'Thuringia',
  PROV_GHA_AHAFO: 'Ahafo',
  PROV_GHA_ASHANTI: 'Ashanti',
  PROV_GHA_BONO: 'Bono',
  PROV_GHA_BONOEAST: 'Bono East',
  PROV_GHA_CENTRAL: 'Central',
  PROV_GHA_EASTERN: 'Eastern',
  PROV_GHA_GREATERACCRA: 'Greater Accra',
  PROV_GHA_NORTHEAST: 'North East',
  PROV_GHA_NORTHERN: 'Northern',
  PROV_GHA_OTI: 'Oti',
  PROV_GHA_SAVANNAH: 'Savannah',
  PROV_GHA_UPPEREAST: 'Upper East',
  PROV_GHA_UPPERWEST: 'Upper West',
  PROV_GHA_VOLTA: 'Volta',
  PROV_GHA_WESTERN: 'Western',
  PROV_GHA_WESTERNNORTH: 'Western North',
  PROV_GIB_NOPROVINCE: 'No Province',
  PROV_GRC_ATTICAREGION: 'Attica Region',
  PROV_GRC_CENTRALGREECEREGION: 'Central Greece Region',
  PROV_GRC_CENTRALMACEDONIA: 'Central Macedonia',
  PROV_GRC_CRETEREGION: 'Crete Region',
  PROV_GRC_EASTMACEDONIAANDTHRACE: 'East Macedonia and Thrace',
  PROV_GRC_EPIRUSREGION: 'Epirus Region',
  PROV_GRC_IONIANISLANDSREGION: 'Ionian Islands Region',
  PROV_GRC_PELOPONNESEREGION: 'Peloponnese Region',
  PROV_GRC_SOUTHAEGEAN: 'South Aegean',
  PROV_GRC_WESTGREECEREGION: 'West Greece Region',
  PROV_GRC_WESTMACEDONIAREGION: 'West Macedonia Region',
  PROV_GRL_NOPROVINCE: 'No Province',
  PROV_GRD_CARRIACOUANDPETITEMARTINIQUE: 'Carriacou and Petite Martinique',
  PROV_GRD_SAINTANDREWPARISH: 'Saint Andrew Parish',
  PROV_GRD_SAINTDAVIDPARISH: 'Saint David Parish',
  PROV_GRD_SAINTGEORGEPARISH: 'Saint George Parish',
  PROV_GRD_SAINTJOHNPARISH: 'Saint John Parish',
  PROV_GRD_SAINTMARKPARISH: 'Saint Mark Parish',
  PROV_GRD_SAINTPATRICKPARISH: 'Saint Patrick Parish',
  PROV_GLP_NOPROVINCE: 'No Province',
  PROV_GUM_NOPROVINCE: 'No Province',
  PROV_GTM_ALTAVERAPAZDEPARTMENT: 'Alta Verapaz Department',
  PROV_GTM_BAJAVERAPAZDEPARTMENT: 'Baja Verapaz Department',
  PROV_GTM_CHIMALTENANGODEPARTMENT: 'Chimaltenango Department',
  PROV_GTM_CHIQUIMULADEPARTMENT: 'Chiquimula Department',
  PROV_GTM_ELPROGRESODEPARTMENT: 'El Progreso Department',
  PROV_GTM_ESCUINTLADEPARTMENT: 'Escuintla Department',
  PROV_GTM_GUATEMALADEPARTMENT: 'Guatemala Department',
  PROV_GTM_HUEHUETENANGODEPARTMENT: 'Huehuetenango Department',
  PROV_GTM_IZABALDEPARTMENT: 'Izabal Department',
  PROV_GTM_JALAPADEPARTMENT: 'Jalapa Department',
  PROV_GTM_JUTIAPADEPARTMENT: 'Jutiapa Department',
  PROV_GTM_PETNDEPARTMENT: 'Petén Department',
  PROV_GTM_QUETZALTENANGODEPARTMENT: 'Quetzaltenango Department',
  PROV_GTM_QUICHDEPARTMENT: 'Quiché Department',
  PROV_GTM_RETALHULEUDEPARTMENT: 'Retalhuleu Department',
  PROV_GTM_SACATEPQUEZDEPARTMENT: 'Sacatepéquez Department',
  PROV_GTM_SANMARCOSDEPARTMENT: 'San Marcos Department',
  PROV_GTM_SANTAROSADEPARTMENT: 'Santa Rosa Department',
  PROV_GTM_SOLOLDEPARTMENT: 'Sololá Department',
  PROV_GTM_SUCHITEPQUEZDEPARTMENT: 'Suchitepéquez Department',
  PROV_GTM_TOTONICAPNDEPARTMENT: 'Totonicapán Department',
  PROV_GGY_NOPROVINCE: 'No Province',
  PROV_GIN_BOKREGION: 'Boké Region',
  PROV_GIN_CONAKRY: 'Conakry',
  PROV_GIN_FARANAHREGION: 'Faranah Region',
  PROV_GIN_KANKANREGION: 'Kankan Region',
  PROV_GIN_LABREGION: 'Labé Region',
  PROV_GIN_MAMOUREGION: 'Mamou Region',
  PROV_GIN_NZRKORREGION: 'Nzérékoré Region',
  PROV_GNB_BAFAT: 'Bafatá',
  PROV_GNB_BIOMBOREGION: 'Biombo Region',
  PROV_GNB_BOLAMAREGION: 'Bolama Region',
  PROV_GNB_CACHEUREGION: 'Cacheu Region',
  PROV_GNB_GABREGION: 'Gabú Region',
  PROV_GNB_OIOREGION: 'Oio Region',
  PROV_GNB_QUINARAREGION: 'Quinara Region',
  PROV_GNB_TOMBALIREGION: 'Tombali Region',
  PROV_GUY_BARIMAWAINI: 'Barima-Waini',
  PROV_GUY_CUYUNIMAZARUNI: 'Cuyuni-Mazaruni',
  PROV_GUY_DEMERARAMAHAICA: 'Demerara-Mahaica',
  PROV_GUY_EASTBERBICECORENTYNE: 'East Berbice-Corentyne',
  PROV_GUY_ESSEQUIBOISLANDSWESTDEMERARA: 'Essequibo Islands-West Demerara',
  PROV_GUY_MAHAICABERBICE: 'Mahaica-Berbice',
  PROV_GUY_POMEROONSUPENAAM: 'Pomeroon-Supenaam',
  PROV_GUY_POTAROSIPARUNI: 'Potaro-Siparuni',
  PROV_GUY_UPPERDEMERARABERBICE: 'Upper Demerara-Berbice',
  PROV_GUY_UPPERTAKUTUUPPERESSEQUIBO: 'Upper Takutu-Upper Essequibo',
  PROV_HTI_ARTIBONITE: 'Artibonite',
  PROV_HTI_CENTRE: 'Centre',
  PROV_HTI_GRANDANSE: "Grand'Anse",
  PROV_HTI_NIPPES: 'Nippes',
  PROV_HTI_NORD: 'Nord',
  PROV_HTI_NORDEST: 'Nord-Est',
  PROV_HTI_NORDOUEST: 'Nord-Ouest',
  PROV_HTI_OUEST: 'Ouest',
  PROV_HTI_SUD: 'Sud',
  PROV_HTI_SUDEST: 'Sud-Est',
  PROV_HMD_NOPROVINCE: 'No Province',
  PROV_HND_ATLNTIDADEPARTMENT: 'Atlántida Department',
  PROV_HND_BAYISLANDSDEPARTMENT: 'Bay Islands Department',
  PROV_HND_CHOLUTECADEPARTMENT: 'Choluteca Department',
  PROV_HND_COLNDEPARTMENT: 'Colón Department',
  PROV_HND_COMAYAGUADEPARTMENT: 'Comayagua Department',
  PROV_HND_COPNDEPARTMENT: 'Copán Department',
  PROV_HND_CORTSDEPARTMENT: 'Cortés Department',
  PROV_HND_ELPARASODEPARTMENT: 'El Paraíso Department',
  PROV_HND_FRANCISCOMORAZNDEPARTMENT: 'Francisco Morazán Department',
  PROV_HND_GRACIASADIOSDEPARTMENT: 'Gracias a Dios Department',
  PROV_HND_INTIBUCDEPARTMENT: 'Intibucá Department',
  PROV_HND_LAPAZDEPARTMENT: 'La Paz Department',
  PROV_HND_LEMPIRADEPARTMENT: 'Lempira Department',
  PROV_HND_OCOTEPEQUEDEPARTMENT: 'Ocotepeque Department',
  PROV_HND_OLANCHODEPARTMENT: 'Olancho Department',
  PROV_HND_SANTABRBARADEPARTMENT: 'Santa Bárbara Department',
  PROV_HND_VALLEDEPARTMENT: 'Valle Department',
  PROV_HND_YORODEPARTMENT: 'Yoro Department',
  PROV_HKG_CENTRALANDWESTERNDISTRICT: 'Central and Western District',
  PROV_HKG_EASTERN: 'Eastern',
  PROV_HKG_ISLANDSDISTRICT: 'Islands District',
  PROV_HKG_KOWLOONCITY: 'Kowloon City',
  PROV_HKG_KWAITSING: 'Kwai Tsing',
  PROV_HKG_KWUNTONG: 'Kwun Tong',
  PROV_HKG_NORTH: 'North',
  PROV_HKG_SAIKUNGDISTRICT: 'Sai Kung District',
  PROV_HKG_SHATIN: 'Sha Tin',
  PROV_HKG_SHAMSHUIPO: 'Sham Shui Po',
  PROV_HKG_SOUTHERN: 'Southern',
  PROV_HKG_TAIPODISTRICT: 'Tai Po District',
  PROV_HKG_TSUENWANDISTRICT: 'Tsuen Wan District',
  PROV_HKG_TUENMUN: 'Tuen Mun',
  PROV_HKG_WANCHAI: 'Wan Chai',
  PROV_HKG_WONGTAISIN: 'Wong Tai Sin',
  PROV_HKG_YAUTSIMMONG: 'Yau Tsim Mong',
  PROV_HKG_YUENLONGDISTRICT: 'Yuen Long District',
  PROV_HUN_BCSKISKUN: 'Bács-Kiskun',
  PROV_HUN_BARANYA: 'Baranya',
  PROV_HUN_BKS: 'Békés',
  PROV_HUN_BKSCSABA: 'Békéscsaba',
  PROV_HUN_BORSODABAJZEMPLN: 'Borsod-Abaúj-Zemplén',
  PROV_HUN_BUDAPEST: 'Budapest',
  PROV_HUN_CSONGRDCOUNTY: 'Csongrád County',
  PROV_HUN_DEBRECEN: 'Debrecen',
  PROV_HUN_DUNAJVROS: 'Dunaújváros',
  PROV_HUN_EGER: 'Eger',
  PROV_HUN_RD: 'Érd',
  PROV_HUN_FEJRCOUNTY: 'Fejér County',
  PROV_HUN_GYR: 'Győr',
  PROV_HUN_GYRMOSONSOPRONCOUNTY: 'Győr-Moson-Sopron County',
  PROV_HUN_HAJDBIHARCOUNTY: 'Hajdú-Bihar County',
  PROV_HUN_HEVESCOUNTY: 'Heves County',
  PROV_HUN_HDMEZVSRHELY: 'Hódmezővásárhely',
  PROV_HUN_JSZNAGYKUNSZOLNOKCOUNTY: 'Jász-Nagykun-Szolnok County',
  PROV_HUN_KAPOSVR: 'Kaposvár',
  PROV_HUN_KECSKEMT: 'Kecskemét',
  PROV_HUN_KOMROMESZTERGOM: 'Komárom-Esztergom',
  PROV_HUN_MISKOLC: 'Miskolc',
  PROV_HUN_NAGYKANIZSA: 'Nagykanizsa',
  PROV_HUN_NGRDCOUNTY: 'Nógrád County',
  PROV_HUN_NYREGYHZA: 'Nyíregyháza',
  PROV_HUN_PCS: 'Pécs',
  PROV_HUN_PESTCOUNTY: 'Pest County',
  PROV_HUN_SALGTARJN: 'Salgótarján',
  PROV_HUN_SOMOGYCOUNTY: 'Somogy County',
  PROV_HUN_SOPRON: 'Sopron',
  PROV_HUN_SZABOLCSSZATMRBEREGCOUNTY: 'Szabolcs-Szatmár-Bereg County',
  PROV_HUN_SZEGED: 'Szeged',
  PROV_HUN_SZKESFEHRVR: 'Székesfehérvár',
  PROV_HUN_SZEKSZRD: 'Szekszárd',
  PROV_HUN_SZOLNOK: 'Szolnok',
  PROV_HUN_SZOMBATHELY: 'Szombathely',
  PROV_HUN_TATABNYA: 'Tatabánya',
  PROV_HUN_TOLNACOUNTY: 'Tolna County',
  PROV_HUN_VASCOUNTY: 'Vas County',
  PROV_HUN_VESZPRM: 'Veszprém',
  PROV_HUN_VESZPRMCOUNTY: 'Veszprém County',
  PROV_HUN_ZALACOUNTY: 'Zala County',
  PROV_HUN_ZALAEGERSZEG: 'Zalaegerszeg',
  PROV_ISL_CAPITALREGION: 'Capital Region',
  PROV_ISL_EASTERNREGION: 'Eastern Region',
  PROV_ISL_NORTHEASTERNREGION: 'Northeastern Region',
  PROV_ISL_NORTHWESTERNREGION: 'Northwestern Region',
  PROV_ISL_SOUTHERNPENINSULAREGION: 'Southern Peninsula Region',
  PROV_ISL_SOUTHERNREGION: 'Southern Region',
  PROV_ISL_WESTERNREGION: 'Western Region',
  PROV_ISL_WESTFJORDS: 'Westfjords',
  PROV_IND_ANDAMANANDNICOBARISLANDS: 'Andaman and Nicobar Islands',
  PROV_IND_ANDHRAPRADESH: 'Andhra Pradesh',
  PROV_IND_ARUNACHALPRADESH: 'Arunachal Pradesh',
  PROV_IND_ASSAM: 'Assam',
  PROV_IND_BIHAR: 'Bihar',
  PROV_IND_CHANDIGARH: 'Chandigarh',
  PROV_IND_CHHATTISGARH: 'Chhattisgarh',
  PROV_IND_DADRAANDNAGARHAVELIANDDAMANANDDIU: 'Dadra and Nagar Haveli and Daman and Diu',
  PROV_IND_DELHI: 'Delhi',
  PROV_IND_GOA: 'Goa',
  PROV_IND_GUJARAT: 'Gujarat',
  PROV_IND_HARYANA: 'Haryana',
  PROV_IND_HIMACHALPRADESH: 'Himachal Pradesh',
  PROV_IND_JAMMUANDKASHMIR: 'Jammu and Kashmir',
  PROV_IND_JHARKHAND: 'Jharkhand',
  PROV_IND_KARNATAKA: 'Karnataka',
  PROV_IND_KERALA: 'Kerala',
  PROV_IND_LADAKH: 'Ladakh',
  PROV_IND_LAKSHADWEEP: 'Lakshadweep',
  PROV_IND_MADHYAPRADESH: 'Madhya Pradesh',
  PROV_IND_MAHARASHTRA: 'Maharashtra',
  PROV_IND_MANIPUR: 'Manipur',
  PROV_IND_MEGHALAYA: 'Meghalaya',
  PROV_IND_MIZORAM: 'Mizoram',
  PROV_IND_NAGALAND: 'Nagaland',
  PROV_IND_ODISHA: 'Odisha',
  PROV_IND_PUDUCHERRY: 'Puducherry',
  PROV_IND_PUNJAB: 'Punjab',
  PROV_IND_RAJASTHAN: 'Rajasthan',
  PROV_IND_SIKKIM: 'Sikkim',
  PROV_IND_TAMILNADU: 'Tamil Nadu',
  PROV_IND_TELANGANA: 'Telangana',
  PROV_IND_TRIPURA: 'Tripura',
  PROV_IND_UTTARPRADESH: 'Uttar Pradesh',
  PROV_IND_UTTARAKHAND: 'Uttarakhand',
  PROV_IND_WESTBENGAL: 'West Bengal',
  PROV_IDN_ACEH: 'Aceh',
  PROV_IDN_BALI: 'Bali',
  PROV_IDN_BANTEN: 'Banten',
  PROV_IDN_BENGKULU: 'Bengkulu',
  PROV_IDN_DIYOGYAKARTA: 'DI Yogyakarta',
  PROV_IDN_DKIJAKARTA: 'DKI Jakarta',
  PROV_IDN_GORONTALO: 'Gorontalo',
  PROV_IDN_JAMBI: 'Jambi',
  PROV_IDN_JAWABARAT: 'Jawa Barat',
  PROV_IDN_JAWATENGAH: 'Jawa Tengah',
  PROV_IDN_JAWATIMUR: 'Jawa Timur',
  PROV_IDN_KALIMANTANBARAT: 'Kalimantan Barat',
  PROV_IDN_KALIMANTANSELATAN: 'Kalimantan Selatan',
  PROV_IDN_KALIMANTANTENGAH: 'Kalimantan Tengah',
  PROV_IDN_KALIMANTANTIMUR: 'Kalimantan Timur',
  PROV_IDN_KALIMANTANUTARA: 'Kalimantan Utara',
  PROV_IDN_KEPULAUANBANGKABELITUNG: 'Kepulauan Bangka Belitung',
  PROV_IDN_KEPULAUANRIAU: 'Kepulauan Riau',
  PROV_IDN_LAMPUNG: 'Lampung',
  PROV_IDN_MALUKU: 'Maluku',
  PROV_IDN_MALUKUUTARA: 'Maluku Utara',
  PROV_IDN_NUSATENGGARABARAT: 'Nusa Tenggara Barat',
  PROV_IDN_NUSATENGGARATIMUR: 'Nusa Tenggara Timur',
  PROV_IDN_PAPUA: 'Papua',
  PROV_IDN_PAPUABARAT: 'Papua Barat',
  PROV_IDN_RIAU: 'Riau',
  PROV_IDN_SULAWESIBARAT: 'Sulawesi Barat',
  PROV_IDN_SULAWESISELATAN: 'Sulawesi Selatan',
  PROV_IDN_SULAWESITENGAH: 'Sulawesi Tengah',
  PROV_IDN_SULAWESITENGGARA: 'Sulawesi Tenggara',
  PROV_IDN_SULAWESIUTARA: 'Sulawesi Utara',
  PROV_IDN_SUMATERABARAT: 'Sumatera Barat',
  PROV_IDN_SUMATERASELATAN: 'Sumatera Selatan',
  PROV_IDN_SUMATERAUTARA: 'Sumatera Utara',
  PROV_IRN_ALBORZ: 'Alborz',
  PROV_IRN_ARDABIL: 'Ardabil',
  PROV_IRN_BUSHEHR: 'Bushehr',
  PROV_IRN_CHAHARMAHALANDBAKHTIARI: 'Chaharmahal and Bakhtiari',
  PROV_IRN_EASTAZERBAIJAN: 'East Azerbaijan',
  PROV_IRN_FARS: 'Fars',
  PROV_IRN_GILAN: 'Gilan',
  PROV_IRN_GOLESTAN: 'Golestan',
  PROV_IRN_HAMADAN: 'Hamadan',
  PROV_IRN_HORMOZGAN: 'Hormozgan',
  PROV_IRN_ILAM: 'Ilam',
  PROV_IRN_ISFAHAN: 'Isfahan',
  PROV_IRN_KERMAN: 'Kerman',
  PROV_IRN_KERMANSHAH: 'Kermanshah',
  PROV_IRN_KHUZESTAN: 'Khuzestan',
  PROV_IRN_KOHGILUYEHANDBOYERAHMAD: 'Kohgiluyeh and Boyer-Ahmad',
  PROV_IRN_KURDISTAN: 'Kurdistan',
  PROV_IRN_LORESTAN: 'Lorestan',
  PROV_IRN_MARKAZI: 'Markazi',
  PROV_IRN_MAZANDARAN: 'Mazandaran',
  PROV_IRN_NORTHKHORASAN: 'North Khorasan',
  PROV_IRN_QAZVIN: 'Qazvin',
  PROV_IRN_QOM: 'Qom',
  PROV_IRN_RAZAVIKHORASAN: 'Razavi Khorasan',
  PROV_IRN_SEMNAN: 'Semnan',
  PROV_IRN_SISTANANDBALUCHESTAN: 'Sistan and Baluchestan',
  PROV_IRN_SOUTHKHORASAN: 'South Khorasan',
  PROV_IRN_TEHRAN: 'Tehran',
  PROV_IRN_WESTAZARBAIJAN: 'West Azarbaijan',
  PROV_IRN_YAZD: 'Yazd',
  PROV_IRN_ZANJAN: 'Zanjan',
  PROV_IRQ_ALANBARGOVERNORATE: 'Al Anbar Governorate',
  PROV_IRQ_ALMUTHANNAGOVERNORATE: 'Al Muthanna Governorate',
  PROV_IRQ_ALQDISIYYAHGOVERNORATE: 'Al-Qādisiyyah Governorate',
  PROV_IRQ_BABYLONGOVERNORATE: 'Babylon Governorate',
  PROV_IRQ_BAGHDADGOVERNORATE: 'Baghdad Governorate',
  PROV_IRQ_BASRAGOVERNORATE: 'Basra Governorate',
  PROV_IRQ_DHIQARGOVERNORATE: 'Dhi Qar Governorate',
  PROV_IRQ_DIYALAGOVERNORATE: 'Diyala Governorate',
  PROV_IRQ_DOHUKGOVERNORATE: 'Dohuk Governorate',
  PROV_IRQ_ERBILGOVERNORATE: 'Erbil Governorate',
  PROV_IRQ_KARBALAGOVERNORATE: 'Karbala Governorate',
  PROV_IRQ_KIRKUKGOVERNORATE: 'Kirkuk Governorate',
  PROV_IRQ_MAYSANGOVERNORATE: 'Maysan Governorate',
  PROV_IRQ_NAJAFGOVERNORATE: 'Najaf Governorate',
  PROV_IRQ_NINEVEHGOVERNORATE: 'Nineveh Governorate',
  PROV_IRQ_SALADINGOVERNORATE: 'Saladin Governorate',
  PROV_IRQ_SULAYMANIYAHGOVERNORATE: 'Sulaymaniyah Governorate',
  PROV_IRQ_WASITGOVERNORATE: 'Wasit Governorate',
  PROV_IRL_CARLOW: 'Carlow',
  PROV_IRL_CAVAN: 'Cavan',
  PROV_IRL_CLARE: 'Clare',
  PROV_IRL_CORK: 'Cork',
  PROV_IRL_DONEGAL: 'Donegal',
  PROV_IRL_DUBLIN: 'Dublin',
  PROV_IRL_GALWAY: 'Galway',
  PROV_IRL_KERRY: 'Kerry',
  PROV_IRL_KILDARE: 'Kildare',
  PROV_IRL_KILKENNY: 'Kilkenny',
  PROV_IRL_LAOIS: 'Laois',
  PROV_IRL_LIMERICK: 'Limerick',
  PROV_IRL_LONGFORD: 'Longford',
  PROV_IRL_LOUTH: 'Louth',
  PROV_IRL_MAYO: 'Mayo',
  PROV_IRL_MEATH: 'Meath',
  PROV_IRL_MONAGHAN: 'Monaghan',
  PROV_IRL_OFFALY: 'Offaly',
  PROV_IRL_ROSCOMMON: 'Roscommon',
  PROV_IRL_SLIGO: 'Sligo',
  PROV_IRL_TIPPERARY: 'Tipperary',
  PROV_IRL_WATERFORD: 'Waterford',
  PROV_IRL_WESTMEATH: 'Westmeath',
  PROV_IRL_WEXFORD: 'Wexford',
  PROV_IRL_WICKLOW: 'Wicklow',
  PROV_ISR_CENTRALDISTRICT: 'Central District',
  PROV_ISR_HAIFADISTRICT: 'Haifa District',
  PROV_ISR_JERUSALEMDISTRICT: 'Jerusalem District',
  PROV_ISR_NORTHERNDISTRICT: 'Northern District',
  PROV_ISR_SOUTHERNDISTRICT: 'Southern District',
  PROV_ISR_TELAVIVDISTRICT: 'Tel Aviv District',
  PROV_ITA_ABRUZZO: 'Abruzzo',
  PROV_ITA_AOSTAVALLEY: 'Aosta Valley',
  PROV_ITA_APULIA: 'Apulia',
  PROV_ITA_BASILICATA: 'Basilicata',
  PROV_ITA_CALABRIA: 'Calabria',
  PROV_ITA_CAMPANIA: 'Campania',
  PROV_ITA_EMILIAROMAGNA: 'Emilia-Romagna',
  PROV_ITA_FRIULIVENEZIAGIULIA: 'Friuli–Venezia Giulia',
  PROV_ITA_LAZIO: 'Lazio',
  PROV_ITA_LIGURIA: 'Liguria',
  PROV_ITA_LOMBARDY: 'Lombardy',
  PROV_ITA_MARCHE: 'Marche',
  PROV_ITA_MOLISE: 'Molise',
  PROV_ITA_PIEDMONT: 'Piedmont',
  PROV_ITA_SARDINIA: 'Sardinia',
  PROV_ITA_SICILY: 'Sicily',
  PROV_ITA_TRENTINOSOUTHTYROL: 'Trentino-South Tyrol',
  PROV_ITA_TUSCANY: 'Tuscany',
  PROV_ITA_UMBRIA: 'Umbria',
  PROV_ITA_VENETO: 'Veneto',
  PROV_JAM_CLARENDONPARISH: 'Clarendon Parish',
  PROV_JAM_HANOVERPARISH: 'Hanover Parish',
  PROV_JAM_KINGSTONPARISH: 'Kingston Parish',
  PROV_JAM_MANCHESTERPARISH: 'Manchester Parish',
  PROV_JAM_PORTLANDPARISH: 'Portland Parish',
  PROV_JAM_SAINTANDREW: 'Saint Andrew',
  PROV_JAM_SAINTANNPARISH: 'Saint Ann Parish',
  PROV_JAM_SAINTCATHERINEPARISH: 'Saint Catherine Parish',
  PROV_JAM_SAINTELIZABETHPARISH: 'Saint Elizabeth Parish',
  PROV_JAM_SAINTJAMESPARISH: 'Saint James Parish',
  PROV_JAM_SAINTMARYPARISH: 'Saint Mary Parish',
  PROV_JAM_SAINTTHOMASPARISH: 'Saint Thomas Parish',
  PROV_JAM_TRELAWNYPARISH: 'Trelawny Parish',
  PROV_JAM_WESTMORELANDPARISH: 'Westmoreland Parish',
  PROV_JPN_AICHIPREFECTURE: 'Aichi Prefecture',
  PROV_JPN_AKITAPREFECTURE: 'Akita Prefecture',
  PROV_JPN_AOMORIPREFECTURE: 'Aomori Prefecture',
  PROV_JPN_CHIBAPREFECTURE: 'Chiba Prefecture',
  PROV_JPN_EHIMEPREFECTURE: 'Ehime Prefecture',
  PROV_JPN_FUKUIPREFECTURE: 'Fukui Prefecture',
  PROV_JPN_FUKUOKAPREFECTURE: 'Fukuoka Prefecture',
  PROV_JPN_FUKUSHIMAPREFECTURE: 'Fukushima Prefecture',
  PROV_JPN_GIFUPREFECTURE: 'Gifu Prefecture',
  PROV_JPN_GUNMAPREFECTURE: 'Gunma Prefecture',
  PROV_JPN_HIROSHIMAPREFECTURE: 'Hiroshima Prefecture',
  PROV_JPN_HOKKAIDPREFECTURE: 'Hokkaidō Prefecture',
  PROV_JPN_HYGOPREFECTURE: 'Hyōgo Prefecture',
  PROV_JPN_IBARAKIPREFECTURE: 'Ibaraki Prefecture',
  PROV_JPN_ISHIKAWAPREFECTURE: 'Ishikawa Prefecture',
  PROV_JPN_IWATEPREFECTURE: 'Iwate Prefecture',
  PROV_JPN_KAGAWAPREFECTURE: 'Kagawa Prefecture',
  PROV_JPN_KAGOSHIMAPREFECTURE: 'Kagoshima Prefecture',
  PROV_JPN_KANAGAWAPREFECTURE: 'Kanagawa Prefecture',
  PROV_JPN_KCHIPREFECTURE: 'Kōchi Prefecture',
  PROV_JPN_KUMAMOTOPREFECTURE: 'Kumamoto Prefecture',
  PROV_JPN_KYTOPREFECTURE: 'Kyōto Prefecture',
  PROV_JPN_MIEPREFECTURE: 'Mie Prefecture',
  PROV_JPN_MIYAGIPREFECTURE: 'Miyagi Prefecture',
  PROV_JPN_MIYAZAKIPREFECTURE: 'Miyazaki Prefecture',
  PROV_JPN_NAGANOPREFECTURE: 'Nagano Prefecture',
  PROV_JPN_NAGASAKIPREFECTURE: 'Nagasaki Prefecture',
  PROV_JPN_NARAPREFECTURE: 'Nara Prefecture',
  PROV_JPN_NIIGATAPREFECTURE: 'Niigata Prefecture',
  PROV_JPN_ITAPREFECTURE: 'Ōita Prefecture',
  PROV_JPN_OKAYAMAPREFECTURE: 'Okayama Prefecture',
  PROV_JPN_OKINAWAPREFECTURE: 'Okinawa Prefecture',
  PROV_JPN_SAKAPREFECTURE: 'Ōsaka Prefecture',
  PROV_JPN_SAGAPREFECTURE: 'Saga Prefecture',
  PROV_JPN_SAITAMAPREFECTURE: 'Saitama Prefecture',
  PROV_JPN_SHIGAPREFECTURE: 'Shiga Prefecture',
  PROV_JPN_SHIMANEPREFECTURE: 'Shimane Prefecture',
  PROV_JPN_SHIZUOKAPREFECTURE: 'Shizuoka Prefecture',
  PROV_JPN_TOCHIGIPREFECTURE: 'Tochigi Prefecture',
  PROV_JPN_TOKUSHIMAPREFECTURE: 'Tokushima Prefecture',
  PROV_JPN_TOKYO: 'Tokyo',
  PROV_JPN_TOTTORIPREFECTURE: 'Tottori Prefecture',
  PROV_JPN_TOYAMAPREFECTURE: 'Toyama Prefecture',
  PROV_JPN_WAKAYAMAPREFECTURE: 'Wakayama Prefecture',
  PROV_JPN_YAMAGATAPREFECTURE: 'Yamagata Prefecture',
  PROV_JPN_YAMAGUCHIPREFECTURE: 'Yamaguchi Prefecture',
  PROV_JPN_YAMANASHIPREFECTURE: 'Yamanashi Prefecture',
  PROV_JEY_NOPROVINCE: 'No Province',
  PROV_JOR_AJLOUNGOVERNORATE: 'Ajloun Governorate',
  PROV_JOR_AMMANGOVERNORATE: 'Amman Governorate',
  PROV_JOR_AQABAGOVERNORATE: 'Aqaba Governorate',
  PROV_JOR_BALQAGOVERNORATE: 'Balqa Governorate',
  PROV_JOR_IRBIDGOVERNORATE: 'Irbid Governorate',
  PROV_JOR_JERASHGOVERNORATE: 'Jerash Governorate',
  PROV_JOR_KARAKGOVERNORATE: 'Karak Governorate',
  PROV_JOR_MAANGOVERNORATE: "Ma'an Governorate",
  PROV_JOR_MADABAGOVERNORATE: 'Madaba Governorate',
  PROV_JOR_MAFRAQGOVERNORATE: 'Mafraq Governorate',
  PROV_JOR_TAFILAHGOVERNORATE: 'Tafilah Governorate',
  PROV_JOR_ZARQAGOVERNORATE: 'Zarqa Governorate',
  PROV_KAZ_AKMOLAREGION: 'Akmola Region',
  PROV_KAZ_AKTOBEREGION: 'Aktobe Region',
  PROV_KAZ_ALMATY: 'Almaty',
  PROV_KAZ_ALMATYREGION: 'Almaty Region',
  PROV_KAZ_ATYRAUREGION: 'Atyrau Region',
  PROV_KAZ_BAIKONUR: 'Baikonur',
  PROV_KAZ_EASTKAZAKHSTANREGION: 'East Kazakhstan Region',
  PROV_KAZ_JAMBYLREGION: 'Jambyl Region',
  PROV_KAZ_KARAGANDAREGION: 'Karaganda Region',
  PROV_KAZ_KOSTANAYREGION: 'Kostanay Region',
  PROV_KAZ_KYZYLORDAREGION: 'Kyzylorda Region',
  PROV_KAZ_MANGYSTAUREGION: 'Mangystau Region',
  PROV_KAZ_NORTHKAZAKHSTANREGION: 'North Kazakhstan Region',
  PROV_KAZ_NURSULTAN: 'Nur-Sultan',
  PROV_KAZ_PAVLODARREGION: 'Pavlodar Region',
  PROV_KAZ_TURKESTANREGION: 'Turkestan Region',
  PROV_KAZ_WESTKAZAKHSTANPROVINCE: 'West Kazakhstan Province',
  PROV_KEN_BARINGO: 'Baringo',
  PROV_KEN_BOMET: 'Bomet',
  PROV_KEN_BUNGOMA: 'Bungoma',
  PROV_KEN_BUSIA: 'Busia',
  PROV_KEN_ELGEYOMARAKWET: 'Elgeyo-Marakwet',
  PROV_KEN_EMBU: 'Embu',
  PROV_KEN_GARISSA: 'Garissa',
  PROV_KEN_HOMABAY: 'Homa Bay',
  PROV_KEN_ISIOLO: 'Isiolo',
  PROV_KEN_KAJIADO: 'Kajiado',
  PROV_KEN_KAKAMEGA: 'Kakamega',
  PROV_KEN_KERICHO: 'Kericho',
  PROV_KEN_KIAMBU: 'Kiambu',
  PROV_KEN_KILIFI: 'Kilifi',
  PROV_KEN_KIRINYAGA: 'Kirinyaga',
  PROV_KEN_KISII: 'Kisii',
  PROV_KEN_KISUMU: 'Kisumu',
  PROV_KEN_KITUI: 'Kitui',
  PROV_KEN_KWALE: 'Kwale',
  PROV_KEN_LAIKIPIA: 'Laikipia',
  PROV_KEN_LAMU: 'Lamu',
  PROV_KEN_MACHAKOS: 'Machakos',
  PROV_KEN_MAKUENI: 'Makueni',
  PROV_KEN_MANDERA: 'Mandera',
  PROV_KEN_MARSABIT: 'Marsabit',
  PROV_KEN_MERU: 'Meru',
  PROV_KEN_MIGORI: 'Migori',
  PROV_KEN_MOMBASA: 'Mombasa',
  PROV_KEN_MURANGA: "Murang'a",
  PROV_KEN_NAIROBICITY: 'Nairobi City',
  PROV_KEN_NAKURU: 'Nakuru',
  PROV_KEN_NANDI: 'Nandi',
  PROV_KEN_NAROK: 'Narok',
  PROV_KEN_NYAMIRA: 'Nyamira',
  PROV_KEN_NYANDARUA: 'Nyandarua',
  PROV_KEN_NYERI: 'Nyeri',
  PROV_KEN_SAMBURU: 'Samburu',
  PROV_KEN_SIAYA: 'Siaya',
  PROV_KEN_TAITATAVETA: 'Taita–Taveta',
  PROV_KEN_TANARIVER: 'Tana River',
  PROV_KEN_THARAKANITHI: 'Tharaka-Nithi',
  PROV_KEN_TRANSNZOIA: 'Trans Nzoia',
  PROV_KEN_TURKANA: 'Turkana',
  PROV_KEN_UASINGISHU: 'Uasin Gishu',
  PROV_KEN_VIHIGA: 'Vihiga',
  PROV_KEN_WAJIR: 'Wajir',
  PROV_KEN_WESTPOKOT: 'West Pokot',
  PROV_KIR_GILBERTISLANDS: 'Gilbert Islands',
  PROV_KIR_LINEISLANDS: 'Line Islands',
  PROV_KIR_PHOENIXISLANDS: 'Phoenix Islands',
  PROV_XKX_AKOVICADISTRICTGJAKOVE: 'Đakovica District (Gjakove)',
  PROV_XKX_GJILANDISTRICT: 'Gjilan District',
  PROV_XKX_KOSOVSKAMITROVICADISTRICT: 'Kosovska Mitrovica District',
  PROV_XKX_PEDISTRICT: 'Peć District',
  PROV_XKX_PRISTINAPRITINE: 'Pristina (Priştine)',
  PROV_XKX_PRIZRENDISTRICT: 'Prizren District',
  PROV_XKX_UROEVACDISTRICTFERIZAJ: 'Uroševac District (Ferizaj)',
  PROV_KWT_ALAHMADIGOVERNORATE: 'Al Ahmadi Governorate',
  PROV_KWT_ALFARWANIYAHGOVERNORATE: 'Al Farwaniyah Governorate',
  PROV_KWT_ALJAHRAGOVERNORATE: 'Al Jahra Governorate',
  PROV_KWT_CAPITALGOVERNORATE: 'Capital Governorate',
  PROV_KWT_HAWALLIGOVERNORATE: 'Hawalli Governorate',
  PROV_KWT_MUBARAKALKABEERGOVERNORATE: 'Mubarak Al-Kabeer Governorate',
  PROV_KGZ_BATKENREGION: 'Batken Region',
  PROV_KGZ_BISHKEK: 'Bishkek',
  PROV_KGZ_CHUYREGION: 'Chuy Region',
  PROV_KGZ_ISSYKKULREGION: 'Issyk-Kul Region',
  PROV_KGZ_JALALABADREGION: 'Jalal-Abad Region',
  PROV_KGZ_NARYNREGION: 'Naryn Region',
  PROV_KGZ_OSH: 'Osh',
  PROV_KGZ_OSHREGION: 'Osh Region',
  PROV_KGZ_TALASREGION: 'Talas Region',
  PROV_LAO_ATTAPEUPROVINCE: 'Attapeu Province',
  PROV_LAO_BOKEOPROVINCE: 'Bokeo Province',
  PROV_LAO_BOLIKHAMSAIPROVINCE: 'Bolikhamsai Province',
  PROV_LAO_CHAMPASAKPROVINCE: 'Champasak Province',
  PROV_LAO_HOUAPHANHPROVINCE: 'Houaphanh Province',
  PROV_LAO_KHAMMOUANEPROVINCE: 'Khammouane Province',
  PROV_LAO_LUANGNAMTHAPROVINCE: 'Luang Namtha Province',
  PROV_LAO_LUANGPRABANGPROVINCE: 'Luang Prabang Province',
  PROV_LAO_OUDOMXAYPROVINCE: 'Oudomxay Province',
  PROV_LAO_PHONGSALYPROVINCE: 'Phongsaly Province',
  PROV_LAO_SAINYABULIPROVINCE: 'Sainyabuli Province',
  PROV_LAO_SALAVANPROVINCE: 'Salavan Province',
  PROV_LAO_SAVANNAKHETPROVINCE: 'Savannakhet Province',
  PROV_LAO_SEKONGPROVINCE: 'Sekong Province',
  PROV_LAO_VIENTIANEPREFECTURE: 'Vientiane Prefecture',
  PROV_LAO_VIENTIANEPROVINCE: 'Vientiane Province',
  PROV_LAO_XAISOMBOUN: 'Xaisomboun',
  PROV_LAO_XAISOMBOUNPROVINCE: 'Xaisomboun Province',
  PROV_LAO_XIANGKHOUANGPROVINCE: 'Xiangkhouang Province',
  PROV_LVA_AGLONAMUNICIPALITY: 'Aglona Municipality',
  PROV_LVA_AIZKRAUKLEMUNICIPALITY: 'Aizkraukle Municipality',
  PROV_LVA_AIZPUTEMUNICIPALITY: 'Aizpute Municipality',
  PROV_LVA_AKNSTEMUNICIPALITY: 'Aknīste Municipality',
  PROV_LVA_ALOJAMUNICIPALITY: 'Aloja Municipality',
  PROV_LVA_ALSUNGAMUNICIPALITY: 'Alsunga Municipality',
  PROV_LVA_ALKSNEMUNICIPALITY: 'Alūksne Municipality',
  PROV_LVA_AMATAMUNICIPALITY: 'Amata Municipality',
  PROV_LVA_APEMUNICIPALITY: 'Ape Municipality',
  PROV_LVA_AUCEMUNICIPALITY: 'Auce Municipality',
  PROV_LVA_BABTEMUNICIPALITY: 'Babīte Municipality',
  PROV_LVA_BALDONEMUNICIPALITY: 'Baldone Municipality',
  PROV_LVA_BALTINAVAMUNICIPALITY: 'Baltinava Municipality',
  PROV_LVA_BALVIMUNICIPALITY: 'Balvi Municipality',
  PROV_LVA_BAUSKAMUNICIPALITY: 'Bauska Municipality',
  PROV_LVA_BEVERNAMUNICIPALITY: 'Beverīna Municipality',
  PROV_LVA_BROCNIMUNICIPALITY: 'Brocēni Municipality',
  PROV_LVA_BURTNIEKIMUNICIPALITY: 'Burtnieki Municipality',
  PROV_LVA_CARNIKAVAMUNICIPALITY: 'Carnikava Municipality',
  PROV_LVA_CSISMUNICIPALITY: 'Cēsis Municipality',
  PROV_LVA_CESVAINEMUNICIPALITY: 'Cesvaine Municipality',
  PROV_LVA_CIBLAMUNICIPALITY: 'Cibla Municipality',
  PROV_LVA_DAGDAMUNICIPALITY: 'Dagda Municipality',
  PROV_LVA_DAUGAVPILS: 'Daugavpils',
  PROV_LVA_DAUGAVPILSMUNICIPALITY: 'Daugavpils Municipality',
  PROV_LVA_DOBELEMUNICIPALITY: 'Dobele Municipality',
  PROV_LVA_DUNDAGAMUNICIPALITY: 'Dundaga Municipality',
  PROV_LVA_DURBEMUNICIPALITY: 'Durbe Municipality',
  PROV_LVA_ENGUREMUNICIPALITY: 'Engure Municipality',
  PROV_LVA_RGIMUNICIPALITY: 'Ērgļi Municipality',
  PROV_LVA_GARKALNEMUNICIPALITY: 'Garkalne Municipality',
  PROV_LVA_GROBIAMUNICIPALITY: 'Grobiņa Municipality',
  PROV_LVA_GULBENEMUNICIPALITY: 'Gulbene Municipality',
  PROV_LVA_IECAVAMUNICIPALITY: 'Iecava Municipality',
  PROV_LVA_IKILEMUNICIPALITY: 'Ikšķile Municipality',
  PROV_LVA_ILKSTEMUNICIPALITY: 'Ilūkste Municipality',
  PROV_LVA_INUKALNSMUNICIPALITY: 'Inčukalns Municipality',
  PROV_LVA_JAUNJELGAVAMUNICIPALITY: 'Jaunjelgava Municipality',
  PROV_LVA_JAUNPIEBALGAMUNICIPALITY: 'Jaunpiebalga Municipality',
  PROV_LVA_JAUNPILSMUNICIPALITY: 'Jaunpils Municipality',
  PROV_LVA_JKABPILS: 'Jēkabpils',
  PROV_LVA_JKABPILSMUNICIPALITY: 'Jēkabpils Municipality',
  PROV_LVA_JELGAVA: 'Jelgava',
  PROV_LVA_JELGAVAMUNICIPALITY: 'Jelgava Municipality',
  PROV_LVA_JRMALA: 'Jūrmala',
  PROV_LVA_KANDAVAMUNICIPALITY: 'Kandava Municipality',
  PROV_LVA_KRSAVAMUNICIPALITY: 'Kārsava Municipality',
  PROV_LVA_EGUMSMUNICIPALITY: 'Ķegums Municipality',
  PROV_LVA_EKAVAMUNICIPALITY: 'Ķekava Municipality',
  PROV_LVA_KOCNIMUNICIPALITY: 'Kocēni Municipality',
  PROV_LVA_KOKNESEMUNICIPALITY: 'Koknese Municipality',
  PROV_LVA_KRSLAVAMUNICIPALITY: 'Krāslava Municipality',
  PROV_LVA_KRIMULDAMUNICIPALITY: 'Krimulda Municipality',
  PROV_LVA_KRUSTPILSMUNICIPALITY: 'Krustpils Municipality',
  PROV_LVA_KULDGAMUNICIPALITY: 'Kuldīga Municipality',
  PROV_LVA_LIELVRDEMUNICIPALITY: 'Lielvārde Municipality',
  PROV_LVA_LIEPJA: 'Liepāja',
  PROV_LVA_LGATNEMUNICIPALITY: 'Līgatne Municipality',
  PROV_LVA_LIMBAIMUNICIPALITY: 'Limbaži Municipality',
  PROV_LVA_LVNIMUNICIPALITY: 'Līvāni Municipality',
  PROV_LVA_LUBNAMUNICIPALITY: 'Lubāna Municipality',
  PROV_LVA_LUDZAMUNICIPALITY: 'Ludza Municipality',
  PROV_LVA_MADONAMUNICIPALITY: 'Madona Municipality',
  PROV_LVA_MLPILSMUNICIPALITY: 'Mālpils Municipality',
  PROV_LVA_MRUPEMUNICIPALITY: 'Mārupe Municipality',
  PROV_LVA_MAZSALACAMUNICIPALITY: 'Mazsalaca Municipality',
  PROV_LVA_MRSRAGSMUNICIPALITY: 'Mērsrags Municipality',
  PROV_LVA_NAUKNIMUNICIPALITY: 'Naukšēni Municipality',
  PROV_LVA_NERETAMUNICIPALITY: 'Nereta Municipality',
  PROV_LVA_NCAMUNICIPALITY: 'Nīca Municipality',
  PROV_LVA_OGREMUNICIPALITY: 'Ogre Municipality',
  PROV_LVA_OLAINEMUNICIPALITY: 'Olaine Municipality',
  PROV_LVA_OZOLNIEKIMUNICIPALITY: 'Ozolnieki Municipality',
  PROV_LVA_PRGAUJAMUNICIPALITY: 'Pārgauja Municipality',
  PROV_LVA_PVILOSTAMUNICIPALITY: 'Pāvilosta Municipality',
  PROV_LVA_PAVIASMUNICIPALITY: 'Pļaviņas Municipality',
  PROV_LVA_PREIIMUNICIPALITY: 'Preiļi Municipality',
  PROV_LVA_PRIEKULEMUNICIPALITY: 'Priekule Municipality',
  PROV_LVA_PRIEKUIMUNICIPALITY: 'Priekuļi Municipality',
  PROV_LVA_RAUNAMUNICIPALITY: 'Rauna Municipality',
  PROV_LVA_RZEKNE: 'Rēzekne',
  PROV_LVA_RZEKNEMUNICIPALITY: 'Rēzekne Municipality',
  PROV_LVA_RIEBIIMUNICIPALITY: 'Riebiņi Municipality',
  PROV_LVA_RIGA: 'Riga',
  PROV_LVA_ROJAMUNICIPALITY: 'Roja Municipality',
  PROV_LVA_ROPAIMUNICIPALITY: 'Ropaži Municipality',
  PROV_LVA_RUCAVAMUNICIPALITY: 'Rucava Municipality',
  PROV_LVA_RUGJIMUNICIPALITY: 'Rugāji Municipality',
  PROV_LVA_RJIENAMUNICIPALITY: 'Rūjiena Municipality',
  PROV_LVA_RUNDLEMUNICIPALITY: 'Rundāle Municipality',
  PROV_LVA_SALAMUNICIPALITY: 'Sala Municipality',
  PROV_LVA_SALACGRVAMUNICIPALITY: 'Salacgrīva Municipality',
  PROV_LVA_SALASPILSMUNICIPALITY: 'Salaspils Municipality',
  PROV_LVA_SALDUSMUNICIPALITY: 'Saldus Municipality',
  PROV_LVA_SAULKRASTIMUNICIPALITY: 'Saulkrasti Municipality',
  PROV_LVA_SJAMUNICIPALITY: 'Sēja Municipality',
  PROV_LVA_SIGULDAMUNICIPALITY: 'Sigulda Municipality',
  PROV_LVA_SKRVERIMUNICIPALITY: 'Skrīveri Municipality',
  PROV_LVA_SKRUNDAMUNICIPALITY: 'Skrunda Municipality',
  PROV_LVA_SMILTENEMUNICIPALITY: 'Smiltene Municipality',
  PROV_LVA_STOPIIMUNICIPALITY: 'Stopiņi Municipality',
  PROV_LVA_STRENIMUNICIPALITY: 'Strenči Municipality',
  PROV_LVA_TALSIMUNICIPALITY: 'Talsi Municipality',
  PROV_LVA_TRVETEMUNICIPALITY: 'Tērvete Municipality',
  PROV_LVA_TUKUMSMUNICIPALITY: 'Tukums Municipality',
  PROV_LVA_VAIODEMUNICIPALITY: 'Vaiņode Municipality',
  PROV_LVA_VALKAMUNICIPALITY: 'Valka Municipality',
  PROV_LVA_VALMIERA: 'Valmiera',
  PROV_LVA_VARAKNIMUNICIPALITY: 'Varakļāni Municipality',
  PROV_LVA_VRKAVAMUNICIPALITY: 'Vārkava Municipality',
  PROV_LVA_VECPIEBALGAMUNICIPALITY: 'Vecpiebalga Municipality',
  PROV_LVA_VECUMNIEKIMUNICIPALITY: 'Vecumnieki Municipality',
  PROV_LVA_VENTSPILS: 'Ventspils',
  PROV_LVA_VENTSPILSMUNICIPALITY: 'Ventspils Municipality',
  PROV_LVA_VIESTEMUNICIPALITY: 'Viesīte Municipality',
  PROV_LVA_VIAKAMUNICIPALITY: 'Viļaka Municipality',
  PROV_LVA_VINIMUNICIPALITY: 'Viļāni Municipality',
  PROV_LVA_ZILUPEMUNICIPALITY: 'Zilupe Municipality',
  PROV_LBN_AKKARGOVERNORATE: 'Akkar Governorate',
  PROV_LBN_BAALBEKHERMELGOVERNORATE: 'Baalbek-Hermel Governorate',
  PROV_LBN_BEIRUTGOVERNORATE: 'Beirut Governorate',
  PROV_LBN_BEQAAGOVERNORATE: 'Beqaa Governorate',
  PROV_LBN_MOUNTLEBANONGOVERNORATE: 'Mount Lebanon Governorate',
  PROV_LBN_NABATIEHGOVERNORATE: 'Nabatieh Governorate',
  PROV_LBN_NORTHGOVERNORATE: 'North Governorate',
  PROV_LBN_SOUTHGOVERNORATE: 'South Governorate',
  PROV_LSO_BEREADISTRICT: 'Berea District',
  PROV_LSO_BUTHABUTHEDISTRICT: 'Butha-Buthe District',
  PROV_LSO_LERIBEDISTRICT: 'Leribe District',
  PROV_LSO_MAFETENGDISTRICT: 'Mafeteng District',
  PROV_LSO_MASERUDISTRICT: 'Maseru District',
  PROV_LSO_MOHALESHOEKDISTRICT: "Mohale's Hoek District",
  PROV_LSO_MOKHOTLONGDISTRICT: 'Mokhotlong District',
  PROV_LSO_QACHASNEKDISTRICT: "Qacha's Nek District",
  PROV_LSO_QUTHINGDISTRICT: 'Quthing District',
  PROV_LSO_THABATSEKADISTRICT: 'Thaba-Tseka District',
  PROV_LBR_BOMICOUNTY: 'Bomi County',
  PROV_LBR_BONGCOUNTY: 'Bong County',
  PROV_LBR_GBARPOLUCOUNTY: 'Gbarpolu County',
  PROV_LBR_GRANDBASSACOUNTY: 'Grand Bassa County',
  PROV_LBR_GRANDCAPEMOUNTCOUNTY: 'Grand Cape Mount County',
  PROV_LBR_GRANDGEDEHCOUNTY: 'Grand Gedeh County',
  PROV_LBR_GRANDKRUCOUNTY: 'Grand Kru County',
  PROV_LBR_LOFACOUNTY: 'Lofa County',
  PROV_LBR_MARGIBICOUNTY: 'Margibi County',
  PROV_LBR_MARYLANDCOUNTY: 'Maryland County',
  PROV_LBR_MONTSERRADOCOUNTY: 'Montserrado County',
  PROV_LBR_NIMBA: 'Nimba',
  PROV_LBR_RIVERCESSCOUNTY: 'River Cess County',
  PROV_LBR_RIVERGEECOUNTY: 'River Gee County',
  PROV_LBR_SINOECOUNTY: 'Sinoe County',
  PROV_LBY_ALWAHATDISTRICT: 'Al Wahat District',
  PROV_LBY_BENGHAZI: 'Benghazi',
  PROV_LBY_DERNADISTRICT: 'Derna District',
  PROV_LBY_GHATDISTRICT: 'Ghat District',
  PROV_LBY_JABALALAKHDAR: 'Jabal al Akhdar',
  PROV_LBY_JABALALGHARBIDISTRICT: 'Jabal al Gharbi District',
  PROV_LBY_JAFARA: 'Jafara',
  PROV_LBY_JUFRA: 'Jufra',
  PROV_LBY_KUFRADISTRICT: 'Kufra District',
  PROV_LBY_MARJDISTRICT: 'Marj District',
  PROV_LBY_MISRATADISTRICT: 'Misrata District',
  PROV_LBY_MURQUB: 'Murqub',
  PROV_LBY_MURZUQDISTRICT: 'Murzuq District',
  PROV_LBY_NALUTDISTRICT: 'Nalut District',
  PROV_LBY_NUQATALKHAMS: 'Nuqat al Khams',
  PROV_LBY_SABHADISTRICT: 'Sabha District',
  PROV_LBY_SIRTEDISTRICT: 'Sirte District',
  PROV_LBY_TRIPOLIDISTRICT: 'Tripoli District',
  PROV_LBY_WADIALHAYAADISTRICT: 'Wadi al Hayaa District',
  PROV_LBY_WADIALSHATIIDISTRICT: 'Wadi al Shatii District',
  PROV_LBY_ZAWIYADISTRICT: 'Zawiya District',
  PROV_LIE_BALZERS: 'Balzers',
  PROV_LIE_ESCHEN: 'Eschen',
  PROV_LIE_GAMPRIN: 'Gamprin',
  PROV_LIE_MAUREN: 'Mauren',
  PROV_LIE_PLANKEN: 'Planken',
  PROV_LIE_RUGGELL: 'Ruggell',
  PROV_LIE_SCHAAN: 'Schaan',
  PROV_LIE_SCHELLENBERG: 'Schellenberg',
  PROV_LIE_TRIESEN: 'Triesen',
  PROV_LIE_TRIESENBERG: 'Triesenberg',
  PROV_LIE_VADUZ: 'Vaduz',
  PROV_LTU_ALYTUSCOUNTY: 'Alytus County',
  PROV_LTU_KAUNASCOUNTY: 'Kaunas County',
  PROV_LTU_KLAIPDACOUNTY: 'Klaipėda County',
  PROV_LTU_MARIJAMPOLCOUNTY: 'Marijampolė County',
  PROV_LTU_PANEVYSCOUNTY: 'Panevėžys County',
  PROV_LTU_IAULIAICOUNTY: 'Šiauliai County',
  PROV_LTU_TAURAGCOUNTY: 'Tauragė County',
  PROV_LTU_TELIAICOUNTY: 'Telšiai County',
  PROV_LTU_UTENACOUNTY: 'Utena County',
  PROV_LTU_VILNIUSCOUNTY: 'Vilnius County',
  PROV_LUX_CANTONOFCAPELLEN: 'Canton of Capellen',
  PROV_LUX_CANTONOFCLERVAUX: 'Canton of Clervaux',
  PROV_LUX_CANTONOFDIEKIRCH: 'Canton of Diekirch',
  PROV_LUX_CANTONOFECHTERNACH: 'Canton of Echternach',
  PROV_LUX_CANTONOFESCHSURALZETTE: 'Canton of Esch-sur-Alzette',
  PROV_LUX_CANTONOFGREVENMACHER: 'Canton of Grevenmacher',
  PROV_LUX_CANTONOFLUXEMBOURG: 'Canton of Luxembourg',
  PROV_LUX_CANTONOFMERSCH: 'Canton of Mersch',
  PROV_LUX_CANTONOFREDANGE: 'Canton of Redange',
  PROV_LUX_CANTONOFREMICH: 'Canton of Remich',
  PROV_LUX_CANTONOFVIANDEN: 'Canton of Vianden',
  PROV_LUX_CANTONOFWILTZ: 'Canton of Wiltz',
  PROV_LUX_DIEKIRCHDISTRICT: 'Diekirch District',
  PROV_LUX_GREVENMACHERDISTRICT: 'Grevenmacher District',
  PROV_LUX_LUXEMBOURGDISTRICT: 'Luxembourg District',
  PROV_MAC_NOPROVINCE: 'No Province',
  PROV_MKD_AERODROMMUNICIPALITY: 'Aerodrom Municipality',
  PROV_MKD_ARAINOVOMUNICIPALITY: 'Aračinovo Municipality',
  PROV_MKD_BEROVOMUNICIPALITY: 'Berovo Municipality',
  PROV_MKD_BITOLAMUNICIPALITY: 'Bitola Municipality',
  PROV_MKD_BOGDANCIMUNICIPALITY: 'Bogdanci Municipality',
  PROV_MKD_BOGOVINJEMUNICIPALITY: 'Bogovinje Municipality',
  PROV_MKD_BOSILOVOMUNICIPALITY: 'Bosilovo Municipality',
  PROV_MKD_BRVENICAMUNICIPALITY: 'Brvenica Municipality',
  PROV_MKD_BUTELMUNICIPALITY: 'Butel Municipality',
  PROV_MKD_AIRMUNICIPALITY: 'Čair Municipality',
  PROV_MKD_AKAMUNICIPALITY: 'Čaška Municipality',
  PROV_MKD_CENTARMUNICIPALITY: 'Centar Municipality',
  PROV_MKD_CENTARUPAMUNICIPALITY: 'Centar Župa Municipality',
  PROV_MKD_EINOVOOBLEEVOMUNICIPALITY: 'Češinovo-Obleševo Municipality',
  PROV_MKD_UERSANDEVOMUNICIPALITY: 'Čučer-Sandevo Municipality',
  PROV_MKD_DEBARCAMUNICIPALITY: 'Debarca Municipality',
  PROV_MKD_DELEVOMUNICIPALITY: 'Delčevo Municipality',
  PROV_MKD_DEMIRHISARMUNICIPALITY: 'Demir Hisar Municipality',
  PROV_MKD_DEMIRKAPIJAMUNICIPALITY: 'Demir Kapija Municipality',
  PROV_MKD_DOJRANMUNICIPALITY: 'Dojran Municipality',
  PROV_MKD_DOLNENIMUNICIPALITY: 'Dolneni Municipality',
  PROV_MKD_DRUGOVOMUNICIPALITY: 'Drugovo Municipality',
  PROV_MKD_GAZIBABAMUNICIPALITY: 'Gazi Baba Municipality',
  PROV_MKD_GEVGELIJAMUNICIPALITY: 'Gevgelija Municipality',
  PROV_MKD_GJOREPETROVMUNICIPALITY: 'Gjorče Petrov Municipality',
  PROV_MKD_GOSTIVARMUNICIPALITY: 'Gostivar Municipality',
  PROV_MKD_GRADSKOMUNICIPALITY: 'Gradsko Municipality',
  PROV_MKD_GREATERSKOPJE: 'Greater Skopje',
  PROV_MKD_ILINDENMUNICIPALITY: 'Ilinden Municipality',
  PROV_MKD_JEGUNOVCEMUNICIPALITY: 'Jegunovce Municipality',
  PROV_MKD_KARBINCI: 'Karbinci',
  PROV_MKD_KARPOMUNICIPALITY: 'Karpoš Municipality',
  PROV_MKD_KAVADARCIMUNICIPALITY: 'Kavadarci Municipality',
  PROV_MKD_KIEVOMUNICIPALITY: 'Kičevo Municipality',
  PROV_MKD_KISELAVODAMUNICIPALITY: 'Kisela Voda Municipality',
  PROV_MKD_KOANIMUNICIPALITY: 'Kočani Municipality',
  PROV_MKD_KONEMUNICIPALITY: 'Konče Municipality',
  PROV_MKD_KRATOVOMUNICIPALITY: 'Kratovo Municipality',
  PROV_MKD_KRIVAPALANKAMUNICIPALITY: 'Kriva Palanka Municipality',
  PROV_MKD_KRIVOGATANIMUNICIPALITY: 'Krivogaštani Municipality',
  PROV_MKD_KRUEVOMUNICIPALITY: 'Kruševo Municipality',
  PROV_MKD_KUMANOVOMUNICIPALITY: 'Kumanovo Municipality',
  PROV_MKD_LIPKOVOMUNICIPALITY: 'Lipkovo Municipality',
  PROV_MKD_LOZOVOMUNICIPALITY: 'Lozovo Municipality',
  PROV_MKD_MAKEDONSKAKAMENICAMUNICIPALITY: 'Makedonska Kamenica Municipality',
  PROV_MKD_MAKEDONSKIBRODMUNICIPALITY: 'Makedonski Brod Municipality',
  PROV_MKD_MAVROVOANDROSTUAMUNICIPALITY: 'Mavrovo and Rostuša Municipality',
  PROV_MKD_MOGILAMUNICIPALITY: 'Mogila Municipality',
  PROV_MKD_NEGOTINOMUNICIPALITY: 'Negotino Municipality',
  PROV_MKD_NOVACIMUNICIPALITY: 'Novaci Municipality',
  PROV_MKD_NOVOSELOMUNICIPALITY: 'Novo Selo Municipality',
  PROV_MKD_OHRIDMUNICIPALITY: 'Ohrid Municipality',
  PROV_MKD_OSLOMEJMUNICIPALITY: 'Oslomej Municipality',
  PROV_MKD_PEHEVOMUNICIPALITY: 'Pehčevo Municipality',
  PROV_MKD_PETROVECMUNICIPALITY: 'Petrovec Municipality',
  PROV_MKD_PLASNICAMUNICIPALITY: 'Plasnica Municipality',
  PROV_MKD_PRILEPMUNICIPALITY: 'Prilep Municipality',
  PROV_MKD_PROBITIPMUNICIPALITY: 'Probištip Municipality',
  PROV_MKD_RADOVIMUNICIPALITY: 'Radoviš Municipality',
  PROV_MKD_RANKOVCEMUNICIPALITY: 'Rankovce Municipality',
  PROV_MKD_RESENMUNICIPALITY: 'Resen Municipality',
  PROV_MKD_ROSOMANMUNICIPALITY: 'Rosoman Municipality',
  PROV_MKD_SARAJMUNICIPALITY: 'Saraj Municipality',
  PROV_MKD_SOPITEMUNICIPALITY: 'Sopište Municipality',
  PROV_MKD_STARONAGORIANEMUNICIPALITY: 'Staro Nagoričane Municipality',
  PROV_MKD_TIPMUNICIPALITY: 'Štip Municipality',
  PROV_MKD_STRUGAMUNICIPALITY: 'Struga Municipality',
  PROV_MKD_STRUMICAMUNICIPALITY: 'Strumica Municipality',
  PROV_MKD_STUDENIANIMUNICIPALITY: 'Studeničani Municipality',
  PROV_MKD_UTOORIZARIMUNICIPALITY: 'Šuto Orizari Municipality',
  PROV_MKD_SVETINIKOLEMUNICIPALITY: 'Sveti Nikole Municipality',
  PROV_MKD_TEARCEMUNICIPALITY: 'Tearce Municipality',
  PROV_MKD_TETOVOMUNICIPALITY: 'Tetovo Municipality',
  PROV_MKD_VALANDOVOMUNICIPALITY: 'Valandovo Municipality',
  PROV_MKD_VASILEVOMUNICIPALITY: 'Vasilevo Municipality',
  PROV_MKD_VELESMUNICIPALITY: 'Veles Municipality',
  PROV_MKD_VEVANIMUNICIPALITY: 'Vevčani Municipality',
  PROV_MKD_VINICAMUNICIPALITY: 'Vinica Municipality',
  PROV_MKD_VRANETICAMUNICIPALITY: 'Vraneštica Municipality',
  PROV_MKD_VRAPITEMUNICIPALITY: 'Vrapčište Municipality',
  PROV_MKD_ZAJASMUNICIPALITY: 'Zajas Municipality',
  PROV_MKD_ZELENIKOVOMUNICIPALITY: 'Zelenikovo Municipality',
  PROV_MKD_ELINOMUNICIPALITY: 'Želino Municipality',
  PROV_MKD_ZRNOVCIMUNICIPALITY: 'Zrnovci Municipality',
  PROV_MDG_ANTANANARIVOPROVINCE: 'Antananarivo Province',
  PROV_MDG_ANTSIRANANAPROVINCE: 'Antsiranana Province',
  PROV_MDG_FIANARANTSOAPROVINCE: 'Fianarantsoa Province',
  PROV_MDG_MAHAJANGAPROVINCE: 'Mahajanga Province',
  PROV_MDG_TOAMASINAPROVINCE: 'Toamasina Province',
  PROV_MDG_TOLIARAPROVINCE: 'Toliara Province',
  PROV_MWI_BALAKADISTRICT: 'Balaka District',
  PROV_MWI_BLANTYREDISTRICT: 'Blantyre District',
  PROV_MWI_CENTRALREGION: 'Central Region',
  PROV_MWI_CHIKWAWADISTRICT: 'Chikwawa District',
  PROV_MWI_CHIRADZULUDISTRICT: 'Chiradzulu District',
  PROV_MWI_CHITIPADISTRICT: 'Chitipa district',
  PROV_MWI_DEDZADISTRICT: 'Dedza District',
  PROV_MWI_DOWADISTRICT: 'Dowa District',
  PROV_MWI_KARONGADISTRICT: 'Karonga District',
  PROV_MWI_KASUNGUDISTRICT: 'Kasungu District',
  PROV_MWI_LIKOMADISTRICT: 'Likoma District',
  PROV_MWI_LILONGWEDISTRICT: 'Lilongwe District',
  PROV_MWI_MACHINGADISTRICT: 'Machinga District',
  PROV_MWI_MANGOCHIDISTRICT: 'Mangochi District',
  PROV_MWI_MCHINJIDISTRICT: 'Mchinji District',
  PROV_MWI_MULANJEDISTRICT: 'Mulanje District',
  PROV_MWI_MWANZADISTRICT: 'Mwanza District',
  PROV_MWI_MZIMBADISTRICT: 'Mzimba District',
  PROV_MWI_NKHATABAYDISTRICT: 'Nkhata Bay District',
  PROV_MWI_NKHOTAKOTADISTRICT: 'Nkhotakota District',
  PROV_MWI_NORTHERNREGION: 'Northern Region',
  PROV_MWI_NSANJEDISTRICT: 'Nsanje District',
  PROV_MWI_NTCHEUDISTRICT: 'Ntcheu District',
  PROV_MWI_NTCHISIDISTRICT: 'Ntchisi District',
  PROV_MWI_PHALOMBEDISTRICT: 'Phalombe District',
  PROV_MWI_RUMPHIDISTRICT: 'Rumphi District',
  PROV_MWI_SALIMADISTRICT: 'Salima District',
  PROV_MWI_SOUTHERNREGION: 'Southern Region',
  PROV_MWI_THYOLODISTRICT: 'Thyolo District',
  PROV_MWI_ZOMBADISTRICT: 'Zomba District',
  PROV_MYS_JOHOR: 'Johor',
  PROV_MYS_KEDAH: 'Kedah',
  PROV_MYS_KELANTAN: 'Kelantan',
  PROV_MYS_KUALALUMPUR: 'Kuala Lumpur',
  PROV_MYS_LABUAN: 'Labuan',
  PROV_MYS_MALACCA: 'Malacca',
  PROV_MYS_NEGERISEMBILAN: 'Negeri Sembilan',
  PROV_MYS_PAHANG: 'Pahang',
  PROV_MYS_PENANG: 'Penang',
  PROV_MYS_PERAK: 'Perak',
  PROV_MYS_PERLIS: 'Perlis',
  PROV_MYS_PUTRAJAYA: 'Putrajaya',
  PROV_MYS_SABAH: 'Sabah',
  PROV_MYS_SARAWAK: 'Sarawak',
  PROV_MYS_SELANGOR: 'Selangor',
  PROV_MYS_TERENGGANU: 'Terengganu',
  PROV_MDV_ADDUATOLL: 'Addu Atoll',
  PROV_MDV_ALIFALIFATOLL: 'Alif Alif Atoll',
  PROV_MDV_ALIFDHAALATOLL: 'Alif Dhaal Atoll',
  PROV_MDV_CENTRALPROVINCE: 'Central Province',
  PROV_MDV_DHAALUATOLL: 'Dhaalu Atoll',
  PROV_MDV_FAAFUATOLL: 'Faafu Atoll',
  PROV_MDV_GAAFUALIFATOLL: 'Gaafu Alif Atoll',
  PROV_MDV_GAAFUDHAALUATOLL: 'Gaafu Dhaalu Atoll',
  PROV_MDV_GNAVIYANIATOLL: 'Gnaviyani Atoll',
  PROV_MDV_HAAALIFATOLL: 'Haa Alif Atoll',
  PROV_MDV_HAADHAALUATOLL: 'Haa Dhaalu Atoll',
  PROV_MDV_KAAFUATOLL: 'Kaafu Atoll',
  PROV_MDV_LAAMUATOLL: 'Laamu Atoll',
  PROV_MDV_LHAVIYANIATOLL: 'Lhaviyani Atoll',
  PROV_MDV_MAL: 'Malé',
  PROV_MDV_MEEMUATOLL: 'Meemu Atoll',
  PROV_MDV_NOONUATOLL: 'Noonu Atoll',
  PROV_MDV_NORTHCENTRALPROVINCE: 'North Central Province',
  PROV_MDV_NORTHPROVINCE: 'North Province',
  PROV_MDV_RAAATOLL: 'Raa Atoll',
  PROV_MDV_SHAVIYANIATOLL: 'Shaviyani Atoll',
  PROV_MDV_SOUTHCENTRALPROVINCE: 'South Central Province',
  PROV_MDV_SOUTHPROVINCE: 'South Province',
  PROV_MDV_THAAATOLL: 'Thaa Atoll',
  PROV_MDV_UPPERSOUTHPROVINCE: 'Upper South Province',
  PROV_MDV_VAAVUATOLL: 'Vaavu Atoll',
  PROV_MLI_BAMAKO: 'Bamako',
  PROV_MLI_GAOREGION: 'Gao Region',
  PROV_MLI_KAYESREGION: 'Kayes Region',
  PROV_MLI_KIDALREGION: 'Kidal Region',
  PROV_MLI_KOULIKOROREGION: 'Koulikoro Region',
  PROV_MLI_MNAKAREGION: 'Ménaka Region',
  PROV_MLI_MOPTIREGION: 'Mopti Region',
  PROV_MLI_SGOUREGION: 'Ségou Region',
  PROV_MLI_SIKASSOREGION: 'Sikasso Region',
  PROV_MLI_TAOUDNITREGION: 'Taoudénit Region',
  PROV_MLI_TOMBOUCTOUREGION: 'Tombouctou Region',
  PROV_MLT_ATTARD: 'Attard',
  PROV_MLT_BALZAN: 'Balzan',
  PROV_MLT_BIRGU: 'Birgu',
  PROV_MLT_BIRKIRKARA: 'Birkirkara',
  PROV_MLT_BIREBBUA: 'Birżebbuġa',
  PROV_MLT_COSPICUA: 'Cospicua',
  PROV_MLT_DINGLI: 'Dingli',
  PROV_MLT_FGURA: 'Fgura',
  PROV_MLT_FLORIANA: 'Floriana',
  PROV_MLT_FONTANA: 'Fontana',
  PROV_MLT_GAJNSIELEM: 'Għajnsielem',
  PROV_MLT_GARB: 'Għarb',
  PROV_MLT_GARGUR: 'Għargħur',
  PROV_MLT_GASRI: 'Għasri',
  PROV_MLT_GAXAQ: 'Għaxaq',
  PROV_MLT_GUDJA: 'Gudja',
  PROV_MLT_GIRA: 'Gżira',
  PROV_MLT_AMRUN: 'Ħamrun',
  PROV_MLT_IKLIN: 'Iklin',
  PROV_MLT_KALKARA: 'Kalkara',
  PROV_MLT_KEREM: 'Kerċem',
  PROV_MLT_KIRKOP: 'Kirkop',
  PROV_MLT_LIJA: 'Lija',
  PROV_MLT_LUQA: 'Luqa',
  PROV_MLT_MARSA: 'Marsa',
  PROV_MLT_MARSASKALA: 'Marsaskala',
  PROV_MLT_MARSAXLOKK: 'Marsaxlokk',
  PROV_MLT_MDINA: 'Mdina',
  PROV_MLT_MELLIEA: 'Mellieħa',
  PROV_MLT_MARR: 'Mġarr',
  PROV_MLT_MOSTA: 'Mosta',
  PROV_MLT_MQABBA: 'Mqabba',
  PROV_MLT_MSIDA: 'Msida',
  PROV_MLT_MTARFA: 'Mtarfa',
  PROV_MLT_MUNXAR: 'Munxar',
  PROV_MLT_NADUR: 'Nadur',
  PROV_MLT_NAXXAR: 'Naxxar',
  PROV_MLT_PAOLA: 'Paola',
  PROV_MLT_PEMBROKE: 'Pembroke',
  PROV_MLT_PIET: 'Pietà',
  PROV_MLT_QALA: 'Qala',
  PROV_MLT_QORMI: 'Qormi',
  PROV_MLT_QRENDI: 'Qrendi',
  PROV_MLT_RABAT: 'Rabat',
  PROV_MLT_SAINTLAWRENCE: 'Saint Lawrence',
  PROV_MLT_SANWANN: 'San Ġwann',
  PROV_MLT_SANNAT: 'Sannat',
  PROV_MLT_SANTALUIJA: 'Santa Luċija',
  PROV_MLT_SANTAVENERA: 'Santa Venera',
  PROV_MLT_SENGLEA: 'Senglea',
  PROV_MLT_SIIEWI: 'Siġġiewi',
  PROV_MLT_SLIEMA: 'Sliema',
  PROV_MLT_STJULIANS: "St. Julian's",
  PROV_MLT_STPAULSBAY: "St. Paul's Bay",
  PROV_MLT_SWIEQI: 'Swieqi',
  PROV_MLT_TAXBIEX: "Ta' Xbiex",
  PROV_MLT_TARXIEN: 'Tarxien',
  PROV_MLT_VALLETTA: 'Valletta',
  PROV_MLT_VICTORIA: 'Victoria',
  PROV_MLT_XAGRA: 'Xagħra',
  PROV_MLT_XEWKIJA: 'Xewkija',
  PROV_MLT_XGAJRA: 'Xgħajra',
  PROV_MLT_ABBAR: 'Żabbar',
  PROV_MLT_EBBUGOZO: 'Żebbuġ Gozo',
  PROV_MLT_EBBUMALTA: 'Żebbuġ Malta',
  PROV_MLT_EJTUN: 'Żejtun',
  PROV_MLT_URRIEQ: 'Żurrieq',
  PROV_IMN_NOPROVINCE: 'No Province',
  PROV_MHL_RALIKCHAIN: 'Ralik Chain',
  PROV_MHL_RATAKCHAIN: 'Ratak Chain',
  PROV_MTQ_NOPROVINCE: 'No Province',
  PROV_MRT_ADRAR: 'Adrar',
  PROV_MRT_ASSABA: 'Assaba',
  PROV_MRT_BRAKNA: 'Brakna',
  PROV_MRT_DAKHLETNOUADHIBOU: 'Dakhlet Nouadhibou',
  PROV_MRT_GORGOL: 'Gorgol',
  PROV_MRT_GUIDIMAKA: 'Guidimaka',
  PROV_MRT_HODHECHCHARGUI: 'Hodh Ech Chargui',
  PROV_MRT_HODHELGHARBI: 'Hodh El Gharbi',
  PROV_MRT_INCHIRI: 'Inchiri',
  PROV_MRT_NOUAKCHOTTNORD: 'Nouakchott-Nord',
  PROV_MRT_NOUAKCHOTTOUEST: 'Nouakchott-Ouest',
  PROV_MRT_NOUAKCHOTTSUD: 'Nouakchott-Sud',
  PROV_MRT_TAGANT: 'Tagant',
  PROV_MRT_TIRISZEMMOUR: 'Tiris Zemmour',
  PROV_MRT_TRARZA: 'Trarza',
  PROV_MUS_AGALGA: 'Agaléga',
  PROV_MUS_BEAUBASSINROSEHILL: 'Beau Bassin-Rose Hill',
  PROV_MUS_CARGADOSCARAJOS: 'Cargados Carajos',
  PROV_MUS_CUREPIPE: 'Curepipe',
  PROV_MUS_FLACQDISTRICT: 'Flacq District',
  PROV_MUS_GRANDPORTDISTRICT: 'Grand Port District',
  PROV_MUS_MOKADISTRICT: 'Moka District',
  PROV_MUS_PAMPLEMOUSSESDISTRICT: 'Pamplemousses District',
  PROV_MUS_PLAINESWILHEMSDISTRICT: 'Plaines Wilhems District',
  PROV_MUS_PORTLOUIS: 'Port Louis',
  PROV_MUS_PORTLOUISDISTRICT: 'Port Louis District',
  PROV_MUS_QUATREBORNES: 'Quatre Bornes',
  PROV_MUS_RIVIREDUREMPARTDISTRICT: 'Rivière du Rempart District',
  PROV_MUS_RIVIRENOIREDISTRICT: 'Rivière Noire District',
  PROV_MUS_RODRIGUES: 'Rodrigues',
  PROV_MUS_SAVANNEDISTRICT: 'Savanne District',
  PROV_MUS_VACOASPHOENIX: 'Vacoas-Phoenix',
  PROV_MYT_NOPROVINCE: 'No Province',
  PROV_MEX_AGUASCALIENTES: 'Aguascalientes',
  PROV_MEX_BAJACALIFORNIA: 'Baja California',
  PROV_MEX_BAJACALIFORNIASUR: 'Baja California Sur',
  PROV_MEX_CAMPECHE: 'Campeche',
  PROV_MEX_CHIAPAS: 'Chiapas',
  PROV_MEX_CHIHUAHUA: 'Chihuahua',
  PROV_MEX_CIUDADDEMXICO: 'Ciudad de México',
  PROV_MEX_COAHUILADEZARAGOZA: 'Coahuila de Zaragoza',
  PROV_MEX_COLIMA: 'Colima',
  PROV_MEX_DURANGO: 'Durango',
  PROV_MEX_ESTADODEMXICO: 'Estado de México',
  PROV_MEX_GUANAJUATO: 'Guanajuato',
  PROV_MEX_GUERRERO: 'Guerrero',
  PROV_MEX_HIDALGO: 'Hidalgo',
  PROV_MEX_JALISCO: 'Jalisco',
  PROV_MEX_MICHOACNDEOCAMPO: 'Michoacán de Ocampo',
  PROV_MEX_MORELOS: 'Morelos',
  PROV_MEX_NAYARIT: 'Nayarit',
  PROV_MEX_NUEVOLEN: 'Nuevo León',
  PROV_MEX_OAXACA: 'Oaxaca',
  PROV_MEX_PUEBLA: 'Puebla',
  PROV_MEX_QUERTARO: 'Querétaro',
  PROV_MEX_QUINTANAROO: 'Quintana Roo',
  PROV_MEX_SANLUISPOTOS: 'San Luis Potosí',
  PROV_MEX_SINALOA: 'Sinaloa',
  PROV_MEX_SONORA: 'Sonora',
  PROV_MEX_TABASCO: 'Tabasco',
  PROV_MEX_TAMAULIPAS: 'Tamaulipas',
  PROV_MEX_TLAXCALA: 'Tlaxcala',
  PROV_MEX_VERACRUZDEIGNACIODELALLAVE: 'Veracruz de Ignacio de la Llave',
  PROV_MEX_YUCATN: 'Yucatán',
  PROV_MEX_ZACATECAS: 'Zacatecas',
  PROV_FSM_CHUUKSTATE: 'Chuuk State',
  PROV_FSM_KOSRAESTATE: 'Kosrae State',
  PROV_FSM_POHNPEISTATE: 'Pohnpei State',
  PROV_FSM_YAPSTATE: 'Yap State',
  PROV_MDA_ANENIINOIDISTRICT: 'Anenii Noi District',
  PROV_MDA_BLIMUNICIPALITY: 'Bălți Municipality',
  PROV_MDA_BASARABEASCADISTRICT: 'Basarabeasca District',
  PROV_MDA_BENDERMUNICIPALITY: 'Bender Municipality',
  PROV_MDA_BRICENIDISTRICT: 'Briceni District',
  PROV_MDA_CAHULDISTRICT: 'Cahul District',
  PROV_MDA_CLRAIDISTRICT: 'Călărași District',
  PROV_MDA_CANTEMIRDISTRICT: 'Cantemir District',
  PROV_MDA_CUENIDISTRICT: 'Căușeni District',
  PROV_MDA_CHIINUMUNICIPALITY: 'Chișinău Municipality',
  PROV_MDA_CIMILIADISTRICT: 'Cimișlia District',
  PROV_MDA_CRIULENIDISTRICT: 'Criuleni District',
  PROV_MDA_DONDUENIDISTRICT: 'Dondușeni District',
  PROV_MDA_DROCHIADISTRICT: 'Drochia District',
  PROV_MDA_DUBSARIDISTRICT: 'Dubăsari District',
  PROV_MDA_EDINEDISTRICT: 'Edineț District',
  PROV_MDA_FLETIDISTRICT: 'Fălești District',
  PROV_MDA_FLORETIDISTRICT: 'Florești District',
  PROV_MDA_GAGAUZIA: 'Gagauzia',
  PROV_MDA_GLODENIDISTRICT: 'Glodeni District',
  PROV_MDA_HNCETIDISTRICT: 'Hîncești District',
  PROV_MDA_IALOVENIDISTRICT: 'Ialoveni District',
  PROV_MDA_NISPORENIDISTRICT: 'Nisporeni District',
  PROV_MDA_OCNIADISTRICT: 'Ocnița District',
  PROV_MDA_ORHEIDISTRICT: 'Orhei District',
  PROV_MDA_REZINADISTRICT: 'Rezina District',
  PROV_MDA_RCANIDISTRICT: 'Rîșcani District',
  PROV_MDA_SNGEREIDISTRICT: 'Sîngerei District',
  PROV_MDA_OLDNETIDISTRICT: 'Șoldănești District',
  PROV_MDA_SOROCADISTRICT: 'Soroca District',
  PROV_MDA_TEFANVODDISTRICT: 'Ștefan Vodă District',
  PROV_MDA_STRENIDISTRICT: 'Strășeni District',
  PROV_MDA_TARACLIADISTRICT: 'Taraclia District',
  PROV_MDA_TELENETIDISTRICT: 'Telenești District',
  PROV_MDA_TRANSNISTRIAAUTONOMOUSTERRITORIALUNIT: 'Transnistria autonomous territorial unit',
  PROV_MDA_UNGHENIDISTRICT: 'Ungheni District',
  PROV_MCO_LACOLLE: 'La Colle',
  PROV_MCO_LACONDAMINE: 'La Condamine',
  PROV_MCO_MONEGHETTI: 'Moneghetti',
  PROV_MNG_ARKHANGAIPROVINCE: 'Arkhangai Province',
  PROV_MNG_BAYANLGIIPROVINCE: 'Bayan-Ölgii Province',
  PROV_MNG_BAYANKHONGORPROVINCE: 'Bayankhongor Province',
  PROV_MNG_BULGANPROVINCE: 'Bulgan Province',
  PROV_MNG_DARKHANUULPROVINCE: 'Darkhan-Uul Province',
  PROV_MNG_DORNODPROVINCE: 'Dornod Province',
  PROV_MNG_DORNOGOVIPROVINCE: 'Dornogovi Province',
  PROV_MNG_DUNDGOVIPROVINCE: 'Dundgovi Province',
  PROV_MNG_GOVIALTAIPROVINCE: 'Govi-Altai Province',
  PROV_MNG_GOVISMBERPROVINCE: 'Govisümber Province',
  PROV_MNG_KHENTIIPROVINCE: 'Khentii Province',
  PROV_MNG_KHOVDPROVINCE: 'Khovd Province',
  PROV_MNG_KHVSGLPROVINCE: 'Khövsgöl Province',
  PROV_MNG_MNGOVIPROVINCE: 'Ömnögovi Province',
  PROV_MNG_ORKHONPROVINCE: 'Orkhon Province',
  PROV_MNG_VRKHANGAIPROVINCE: 'Övörkhangai Province',
  PROV_MNG_SELENGEPROVINCE: 'Selenge Province',
  PROV_MNG_SKHBAATARPROVINCE: 'Sükhbaatar Province',
  PROV_MNG_TVPROVINCE: 'Töv Province',
  PROV_MNG_UVSPROVINCE: 'Uvs Province',
  PROV_MNG_ZAVKHANPROVINCE: 'Zavkhan Province',
  PROV_MNE_ANDRIJEVICAMUNICIPALITY: 'Andrijevica Municipality',
  PROV_MNE_BARMUNICIPALITY: 'Bar Municipality',
  PROV_MNE_BERANEMUNICIPALITY: 'Berane Municipality',
  PROV_MNE_BIJELOPOLJEMUNICIPALITY: 'Bijelo Polje Municipality',
  PROV_MNE_BUDVAMUNICIPALITY: 'Budva Municipality',
  PROV_MNE_DANILOVGRADMUNICIPALITY: 'Danilovgrad Municipality',
  PROV_MNE_GUSINJEMUNICIPALITY: 'Gusinje Municipality',
  PROV_MNE_KOLAINMUNICIPALITY: 'Kolašin Municipality',
  PROV_MNE_KOTORMUNICIPALITY: 'Kotor Municipality',
  PROV_MNE_MOJKOVACMUNICIPALITY: 'Mojkovac Municipality',
  PROV_MNE_NIKIMUNICIPALITY: 'Nikšić Municipality',
  PROV_MNE_OLDROYALCAPITALCETINJE: 'Old Royal Capital Cetinje',
  PROV_MNE_PETNJICAMUNICIPALITY: 'Petnjica Municipality',
  PROV_MNE_PLAVMUNICIPALITY: 'Plav Municipality',
  PROV_MNE_PLJEVLJAMUNICIPALITY: 'Pljevlja Municipality',
  PROV_MNE_PLUINEMUNICIPALITY: 'Plužine Municipality',
  PROV_MNE_PODGORICAMUNICIPALITY: 'Podgorica Municipality',
  PROV_MNE_ROAJEMUNICIPALITY: 'Rožaje Municipality',
  PROV_MNE_AVNIKMUNICIPALITY: 'Šavnik Municipality',
  PROV_MNE_TIVATMUNICIPALITY: 'Tivat Municipality',
  PROV_MNE_ULCINJMUNICIPALITY: 'Ulcinj Municipality',
  PROV_MNE_ABLJAKMUNICIPALITY: 'Žabljak Municipality',
  PROV_MSR_NOPROVINCE: 'No Province',
  PROV_MAR_BNIMELLALKHNIFRA: 'Béni Mellal-Khénifra',
  PROV_MAR_CASABLANCASETTAT: 'Casablanca-Settat',
  PROV_MAR_DAKHLAOUEDEDDAHABEH: 'Dakhla-Oued Ed-Dahab (EH)',
  PROV_MAR_DRATAFILALET: 'Drâa-Tafilalet',
  PROV_MAR_FSMEKNS: 'Fès-Meknès',
  PROV_MAR_GUELMIMOUEDNOUNEHPARTIAL: 'Guelmim-Oued Noun (EH-partial)',
  PROV_MAR_LORIENTAL: "L'Oriental",
  PROV_MAR_LAYOUNESAKIAELHAMRAEHPARTIAL: 'Laâyoune-Sakia El Hamra (EH-partial)',
  PROV_MAR_MARRAKESHSAFI: 'Marrakesh-Safi',
  PROV_MAR_RABATSALKNITRA: 'Rabat-Salé-Kénitra',
  PROV_MAR_SOUSSMASSA: 'Souss-Massa',
  PROV_MAR_TANGERTTOUANALHOCEMA: 'Tanger-Tétouan-Al Hoceïma',
  PROV_MOZ_CABODELGADOPROVINCE: 'Cabo Delgado Province',
  PROV_MOZ_GAZAPROVINCE: 'Gaza Province',
  PROV_MOZ_INHAMBANEPROVINCE: 'Inhambane Province',
  PROV_MOZ_MANICAPROVINCE: 'Manica Province',
  PROV_MOZ_MAPUTO: 'Maputo',
  PROV_MOZ_MAPUTOPROVINCE: 'Maputo Province',
  PROV_MOZ_NAMPULAPROVINCE: 'Nampula Province',
  PROV_MOZ_NIASSAPROVINCE: 'Niassa Province',
  PROV_MOZ_SOFALAPROVINCE: 'Sofala Province',
  PROV_MOZ_TETEPROVINCE: 'Tete Province',
  PROV_MOZ_ZAMBEZIAPROVINCE: 'Zambezia Province',
  PROV_MMR_AYEYARWADYREGION: 'Ayeyarwady Region',
  PROV_MMR_BAGO: 'Bago',
  PROV_MMR_CHINSTATE: 'Chin State',
  PROV_MMR_KACHINSTATE: 'Kachin State',
  PROV_MMR_KAYAHSTATE: 'Kayah State',
  PROV_MMR_KAYINSTATE: 'Kayin State',
  PROV_MMR_MAGWAYREGION: 'Magway Region',
  PROV_MMR_MANDALAYREGION: 'Mandalay Region',
  PROV_MMR_MONSTATE: 'Mon State',
  PROV_MMR_NAYPYIDAWUNIONTERRITORY: 'Naypyidaw Union Territory',
  PROV_MMR_RAKHINESTATE: 'Rakhine State',
  PROV_MMR_SAGAINGREGION: 'Sagaing Region',
  PROV_MMR_SHANSTATE: 'Shan State',
  PROV_MMR_TANINTHARYIREGION: 'Tanintharyi Region',
  PROV_MMR_YANGONREGION: 'Yangon Region',
  PROV_NAM_ERONGOREGION: 'Erongo Region',
  PROV_NAM_HARDAPREGION: 'Hardap Region',
  PROV_NAM_KARASREGION: 'Karas Region',
  PROV_NAM_KAVANGOEASTREGION: 'Kavango East Region',
  PROV_NAM_KAVANGOWESTREGION: 'Kavango West Region',
  PROV_NAM_KHOMASREGION: 'Khomas Region',
  PROV_NAM_KUNENEREGION: 'Kunene Region',
  PROV_NAM_OHANGWENAREGION: 'Ohangwena Region',
  PROV_NAM_OMAHEKEREGION: 'Omaheke Region',
  PROV_NAM_OMUSATIREGION: 'Omusati Region',
  PROV_NAM_OSHANAREGION: 'Oshana Region',
  PROV_NAM_OSHIKOTOREGION: 'Oshikoto Region',
  PROV_NAM_OTJOZONDJUPAREGION: 'Otjozondjupa Region',
  PROV_NAM_ZAMBEZIREGION: 'Zambezi Region',
  PROV_NRU_AIWODISTRICT: 'Aiwo District',
  PROV_NRU_ANABARDISTRICT: 'Anabar District',
  PROV_NRU_ANETANDISTRICT: 'Anetan District',
  PROV_NRU_ANIBAREDISTRICT: 'Anibare District',
  PROV_NRU_BAITIDISTRICT: 'Baiti District',
  PROV_NRU_BOEDISTRICT: 'Boe District',
  PROV_NRU_BUADADISTRICT: 'Buada District',
  PROV_NRU_DENIGOMODUDISTRICT: 'Denigomodu District',
  PROV_NRU_EWADISTRICT: 'Ewa District',
  PROV_NRU_IJUWDISTRICT: 'Ijuw District',
  PROV_NRU_MENENGDISTRICT: 'Meneng District',
  PROV_NRU_NIBOKDISTRICT: 'Nibok District',
  PROV_NRU_UABOEDISTRICT: 'Uaboe District',
  PROV_NRU_YARENDISTRICT: 'Yaren District',
  PROV_NPL_BAGMATI: 'Bagmati',
  PROV_NPL_GANDAKI: 'Gandaki',
  PROV_NPL_KARNALI: 'Karnali',
  PROV_NPL_LUMBINI: 'Lumbini',
  PROV_NPL_MADHESH: 'Madhesh',
  PROV_NPL_PROVINCENO1: 'Province No. 1',
  PROV_NPL_SUDURPASHCHIM: 'Sudurpashchim',
  PROV_NLD_BONAIRE: 'Bonaire',
  PROV_NLD_DRENTHE: 'Drenthe',
  PROV_NLD_FLEVOLAND: 'Flevoland',
  PROV_NLD_FRIESLAND: 'Friesland',
  PROV_NLD_GELDERLAND: 'Gelderland',
  PROV_NLD_GRONINGEN: 'Groningen',
  PROV_NLD_LIMBURG: 'Limburg',
  PROV_NLD_NORTHBRABANT: 'North Brabant',
  PROV_NLD_NORTHHOLLAND: 'North Holland',
  PROV_NLD_OVERIJSSEL: 'Overijssel',
  PROV_NLD_SABA: 'Saba',
  PROV_NLD_SINTEUSTATIUS: 'Sint Eustatius',
  PROV_NLD_SOUTHHOLLAND: 'South Holland',
  PROV_NLD_UTRECHT: 'Utrecht',
  PROV_NLD_ZEELAND: 'Zeeland',
  PROV_NCL_NOPROVINCE: 'No Province',
  PROV_NZL_AUCKLANDREGION: 'Auckland Region',
  PROV_NZL_BAYOFPLENTYREGION: 'Bay of Plenty Region',
  PROV_NZL_CANTERBURYREGION: 'Canterbury Region',
  PROV_NZL_CHATHAMISLANDS: 'Chatham Islands',
  PROV_NZL_GISBORNEDISTRICT: 'Gisborne District',
  PROV_NZL_HAWKESBAYREGION: "Hawke's Bay Region",
  PROV_NZL_MANAWATUWANGANUIREGION: 'Manawatu-Wanganui Region',
  PROV_NZL_MARLBOROUGHREGION: 'Marlborough Region',
  PROV_NZL_NELSONREGION: 'Nelson Region',
  PROV_NZL_NORTHLANDREGION: 'Northland Region',
  PROV_NZL_OTAGOREGION: 'Otago Region',
  PROV_NZL_SOUTHLANDREGION: 'Southland Region',
  PROV_NZL_TARANAKIREGION: 'Taranaki Region',
  PROV_NZL_TASMANDISTRICT: 'Tasman District',
  PROV_NZL_WAIKATOREGION: 'Waikato Region',
  PROV_NZL_WELLINGTONREGION: 'Wellington Region',
  PROV_NZL_WESTCOASTREGION: 'West Coast Region',
  PROV_NIC_BOACO: 'Boaco',
  PROV_NIC_CARAZO: 'Carazo',
  PROV_NIC_CHINANDEGA: 'Chinandega',
  PROV_NIC_CHONTALES: 'Chontales',
  PROV_NIC_ESTEL: 'Estelí',
  PROV_NIC_GRANADA: 'Granada',
  PROV_NIC_JINOTEGA: 'Jinotega',
  PROV_NIC_LEN: 'León',
  PROV_NIC_MADRIZ: 'Madriz',
  PROV_NIC_MANAGUA: 'Managua',
  PROV_NIC_MASAYA: 'Masaya',
  PROV_NIC_MATAGALPA: 'Matagalpa',
  PROV_NIC_NORTHCARIBBEANCOAST: 'North Caribbean Coast',
  PROV_NIC_NUEVASEGOVIA: 'Nueva Segovia',
  PROV_NIC_ROSANJUAN: 'Río San Juan',
  PROV_NIC_RIVAS: 'Rivas',
  PROV_NIC_SOUTHCARIBBEANCOAST: 'South Caribbean Coast',
  PROV_NER_AGADEZREGION: 'Agadez Region',
  PROV_NER_DIFFAREGION: 'Diffa Region',
  PROV_NER_DOSSOREGION: 'Dosso Region',
  PROV_NER_MARADIREGION: 'Maradi Region',
  PROV_NER_TAHOUAREGION: 'Tahoua Region',
  PROV_NER_TILLABRIREGION: 'Tillabéri Region',
  PROV_NER_ZINDERREGION: 'Zinder Region',
  PROV_NGA_ABIA: 'Abia',
  PROV_NGA_ABUJAFEDERALCAPITALTERRITORY: 'Abuja Federal Capital Territory',
  PROV_NGA_ADAMAWA: 'Adamawa',
  PROV_NGA_AKWAIBOM: 'Akwa Ibom',
  PROV_NGA_ANAMBRA: 'Anambra',
  PROV_NGA_BAUCHI: 'Bauchi',
  PROV_NGA_BAYELSA: 'Bayelsa',
  PROV_NGA_BENUE: 'Benue',
  PROV_NGA_BORNO: 'Borno',
  PROV_NGA_CROSSRIVER: 'Cross River',
  PROV_NGA_DELTA: 'Delta',
  PROV_NGA_EBONYI: 'Ebonyi',
  PROV_NGA_EDO: 'Edo',
  PROV_NGA_EKITI: 'Ekiti',
  PROV_NGA_ENUGU: 'Enugu',
  PROV_NGA_GOMBE: 'Gombe',
  PROV_NGA_IMO: 'Imo',
  PROV_NGA_JIGAWA: 'Jigawa',
  PROV_NGA_KADUNA: 'Kaduna',
  PROV_NGA_KANO: 'Kano',
  PROV_NGA_KATSINA: 'Katsina',
  PROV_NGA_KEBBI: 'Kebbi',
  PROV_NGA_KOGI: 'Kogi',
  PROV_NGA_KWARA: 'Kwara',
  PROV_NGA_LAGOS: 'Lagos',
  PROV_NGA_NASARAWA: 'Nasarawa',
  PROV_NGA_NIGER: 'Niger',
  PROV_NGA_OGUN: 'Ogun',
  PROV_NGA_ONDO: 'Ondo',
  PROV_NGA_OSUN: 'Osun',
  PROV_NGA_OYO: 'Oyo',
  PROV_NGA_PLATEAU: 'Plateau',
  PROV_NGA_RIVERS: 'Rivers',
  PROV_NGA_SOKOTO: 'Sokoto',
  PROV_NGA_TARABA: 'Taraba',
  PROV_NGA_YOBE: 'Yobe',
  PROV_NGA_ZAMFARA: 'Zamfara',
  PROV_NIU_NOPROVINCE: 'No Province',
  PROV_NFK_NOPROVINCE: 'No Province',
  PROV_PRK_CHAGANGPROVINCE: 'Chagang Province',
  PROV_PRK_KANGWONPROVINCE: 'Kangwon Province',
  PROV_PRK_NORTHHAMGYONGPROVINCE: 'North Hamgyong Province',
  PROV_PRK_NORTHHWANGHAEPROVINCE: 'North Hwanghae Province',
  PROV_PRK_NORTHPYONGANPROVINCE: 'North Pyongan Province',
  PROV_PRK_PYONGYANG: 'Pyongyang',
  PROV_PRK_RASON: 'Rason',
  PROV_PRK_RYANGGANGPROVINCE: 'Ryanggang Province',
  PROV_PRK_SOUTHHAMGYONGPROVINCE: 'South Hamgyong Province',
  PROV_PRK_SOUTHHWANGHAEPROVINCE: 'South Hwanghae Province',
  PROV_PRK_SOUTHPYONGANPROVINCE: 'South Pyongan Province',
  PROV_MNP_NOPROVINCE: 'No Province',
  PROV_NOR_AKERSHUS: 'Akershus',
  PROV_NOR_BUSKERUD: 'Buskerud',
  PROV_NOR_FINNMARK: 'Finnmark',
  PROV_NOR_HEDMARK: 'Hedmark',
  PROV_NOR_HORDALAND: 'Hordaland',
  PROV_NOR_JANMAYEN: 'Jan Mayen',
  PROV_NOR_MREOGROMSDAL: 'Møre og Romsdal',
  PROV_NOR_NORDTRNDELAG: 'Nord-Trøndelag',
  PROV_NOR_NORDLAND: 'Nordland',
  PROV_NOR_OPPLAND: 'Oppland',
  PROV_NOR_OSLO: 'Oslo',
  PROV_NOR_STFOLD: 'Østfold',
  PROV_NOR_ROGALAND: 'Rogaland',
  PROV_NOR_SOGNOGFJORDANE: 'Sogn og Fjordane',
  PROV_NOR_SRTRNDELAG: 'Sør-Trøndelag',
  PROV_NOR_SVALBARD: 'Svalbard',
  PROV_NOR_TELEMARK: 'Telemark',
  PROV_NOR_TROMS: 'Troms',
  PROV_NOR_TRNDELAG: 'Trøndelag',
  PROV_NOR_VESTAGDER: 'Vest-Agder',
  PROV_NOR_VESTFOLD: 'Vestfold',
  PROV_OMN_ADDAKHILIYAHGOVERNORATE: 'Ad Dakhiliyah Governorate',
  PROV_OMN_ADDHAHIRAHGOVERNORATE: 'Ad Dhahirah Governorate',
  PROV_OMN_ALBATINAHNORTHGOVERNORATE: 'Al Batinah North Governorate',
  PROV_OMN_ALBATINAHREGION: 'Al Batinah Region',
  PROV_OMN_ALBATINAHSOUTHGOVERNORATE: 'Al Batinah South Governorate',
  PROV_OMN_ALBURAIMIGOVERNORATE: 'Al Buraimi Governorate',
  PROV_OMN_ALWUSTAGOVERNORATE: 'Al Wusta Governorate',
  PROV_OMN_ASHSHARQIYAHNORTHGOVERNORATE: 'Ash Sharqiyah North Governorate',
  PROV_OMN_ASHSHARQIYAHREGION: 'Ash Sharqiyah Region',
  PROV_OMN_ASHSHARQIYAHSOUTHGOVERNORATE: 'Ash Sharqiyah South Governorate',
  PROV_OMN_DHOFARGOVERNORATE: 'Dhofar Governorate',
  PROV_OMN_MUSANDAMGOVERNORATE: 'Musandam Governorate',
  PROV_OMN_MUSCATGOVERNORATE: 'Muscat Governorate',
  PROV_PAK_AZADKASHMIR: 'Azad Kashmir',
  PROV_PAK_BALOCHISTAN: 'Balochistan',
  PROV_PAK_FEDERALLYADMINISTEREDTRIBALAREAS: 'Federally Administered Tribal Areas',
  PROV_PAK_GILGITBALTISTAN: 'Gilgit-Baltistan',
  PROV_PAK_ISLAMABADCAPITALTERRITORY: 'Islamabad Capital Territory',
  PROV_PAK_KHYBERPAKHTUNKHWA: 'Khyber Pakhtunkhwa',
  PROV_PAK_PUNJAB: 'Punjab',
  PROV_PAK_SINDH: 'Sindh',
  PROV_PLW_AIMELIIK: 'Aimeliik',
  PROV_PLW_AIRAI: 'Airai',
  PROV_PLW_ANGAUR: 'Angaur',
  PROV_PLW_HATOHOBEI: 'Hatohobei',
  PROV_PLW_KAYANGEL: 'Kayangel',
  PROV_PLW_KOROR: 'Koror',
  PROV_PLW_MELEKEOK: 'Melekeok',
  PROV_PLW_NGARAARD: 'Ngaraard',
  PROV_PLW_NGARCHELONG: 'Ngarchelong',
  PROV_PLW_NGARDMAU: 'Ngardmau',
  PROV_PLW_NGATPANG: 'Ngatpang',
  PROV_PLW_NGCHESAR: 'Ngchesar',
  PROV_PLW_NGEREMLENGUI: 'Ngeremlengui',
  PROV_PLW_NGIWAL: 'Ngiwal',
  PROV_PLW_PELELIU: 'Peleliu',
  PROV_PLW_SONSOROL: 'Sonsorol',
  PROV_PSE_NOPROVINCE: 'No Province',
  PROV_PAN_BOCASDELTOROPROVINCE: 'Bocas del Toro Province',
  PROV_PAN_CHIRIQUPROVINCE: 'Chiriquí Province',
  PROV_PAN_COCLPROVINCE: 'Coclé Province',
  PROV_PAN_COLNPROVINCE: 'Colón Province',
  PROV_PAN_DARINPROVINCE: 'Darién Province',
  PROV_PAN_EMBERWOUNAANCOMARCA: 'Emberá-Wounaan Comarca',
  PROV_PAN_GUNAYALA: 'Guna Yala',
  PROV_PAN_HERRERAPROVINCE: 'Herrera Province',
  PROV_PAN_LOSSANTOSPROVINCE: 'Los Santos Province',
  PROV_PAN_NGBEBUGLCOMARCA: 'Ngöbe-Buglé Comarca',
  PROV_PAN_PANAMOESTEPROVINCE: 'Panamá Oeste Province',
  PROV_PAN_PANAMPROVINCE: 'Panamá Province',
  PROV_PAN_VERAGUASPROVINCE: 'Veraguas Province',
  PROV_PNG_BOUGAINVILLE: 'Bougainville',
  PROV_PNG_CENTRALPROVINCE: 'Central Province',
  PROV_PNG_CHIMBUPROVINCE: 'Chimbu Province',
  PROV_PNG_EASTNEWBRITAIN: 'East New Britain',
  PROV_PNG_EASTERNHIGHLANDSPROVINCE: 'Eastern Highlands Province',
  PROV_PNG_ENGAPROVINCE: 'Enga Province',
  PROV_PNG_GULF: 'Gulf',
  PROV_PNG_HELA: 'Hela',
  PROV_PNG_JIWAKAPROVINCE: 'Jiwaka Province',
  PROV_PNG_MADANGPROVINCE: 'Madang Province',
  PROV_PNG_MANUSPROVINCE: 'Manus Province',
  PROV_PNG_MILNEBAYPROVINCE: 'Milne Bay Province',
  PROV_PNG_MOROBEPROVINCE: 'Morobe Province',
  PROV_PNG_NEWIRELANDPROVINCE: 'New Ireland Province',
  PROV_PNG_OROPROVINCE: 'Oro Province',
  PROV_PNG_PORTMORESBY: 'Port Moresby',
  PROV_PNG_SANDAUNPROVINCE: 'Sandaun Province',
  PROV_PNG_SOUTHERNHIGHLANDSPROVINCE: 'Southern Highlands Province',
  PROV_PNG_WESTNEWBRITAINPROVINCE: 'West New Britain Province',
  PROV_PNG_WESTERNHIGHLANDSPROVINCE: 'Western Highlands Province',
  PROV_PNG_WESTERNPROVINCE: 'Western Province',
  PROV_PRY_ALTOPARAGUAYDEPARTMENT: 'Alto Paraguay Department',
  PROV_PRY_ALTOPARANDEPARTMENT: 'Alto Paraná Department',
  PROV_PRY_AMAMBAYDEPARTMENT: 'Amambay Department',
  PROV_PRY_BOQUERNDEPARTMENT: 'Boquerón Department',
  PROV_PRY_CAAGUAZ: 'Caaguazú',
  PROV_PRY_CAAZAP: 'Caazapá',
  PROV_PRY_CANINDEY: 'Canindeyú',
  PROV_PRY_CENTRALDEPARTMENT: 'Central Department',
  PROV_PRY_CONCEPCINDEPARTMENT: 'Concepción Department',
  PROV_PRY_CORDILLERADEPARTMENT: 'Cordillera Department',
  PROV_PRY_GUAIRDEPARTMENT: 'Guairá Department',
  PROV_PRY_ITAPA: 'Itapúa',
  PROV_PRY_MISIONESDEPARTMENT: 'Misiones Department',
  PROV_PRY_EEMBUCDEPARTMENT: 'Ñeembucú Department',
  PROV_PRY_PARAGUARDEPARTMENT: 'Paraguarí Department',
  PROV_PRY_PRESIDENTEHAYESDEPARTMENT: 'Presidente Hayes Department',
  PROV_PRY_SANPEDRODEPARTMENT: 'San Pedro Department',
  PROV_PER_AMAZONAS: 'Amazonas',
  PROV_PER_NCASH: 'Áncash',
  PROV_PER_APURMAC: 'Apurímac',
  PROV_PER_AREQUIPA: 'Arequipa',
  PROV_PER_AYACUCHO: 'Ayacucho',
  PROV_PER_CAJAMARCA: 'Cajamarca',
  PROV_PER_CALLAO: 'Callao',
  PROV_PER_CUSCO: 'Cusco',
  PROV_PER_HUANCAVELICA: 'Huancavelica',
  PROV_PER_HUANUCO: 'Huanuco',
  PROV_PER_ICA: 'Ica',
  PROV_PER_JUNN: 'Junín',
  PROV_PER_LALIBERTAD: 'La Libertad',
  PROV_PER_LAMBAYEQUE: 'Lambayeque',
  PROV_PER_LIMA: 'Lima',
  PROV_PER_LORETO: 'Loreto',
  PROV_PER_MADREDEDIOS: 'Madre de Dios',
  PROV_PER_MOQUEGUA: 'Moquegua',
  PROV_PER_PASCO: 'Pasco',
  PROV_PER_PIURA: 'Piura',
  PROV_PER_PUNO: 'Puno',
  PROV_PER_SANMARTN: 'San Martín',
  PROV_PER_TACNA: 'Tacna',
  PROV_PER_TUMBES: 'Tumbes',
  PROV_PER_UCAYALI: 'Ucayali',
  PROV_PHL_ABRA: 'Abra',
  PROV_PHL_AGUSANDELNORTE: 'Agusan del Norte',
  PROV_PHL_AGUSANDELSUR: 'Agusan del Sur',
  PROV_PHL_AKLAN: 'Aklan',
  PROV_PHL_ALBAY: 'Albay',
  PROV_PHL_ANTIQUE: 'Antique',
  PROV_PHL_APAYAO: 'Apayao',
  PROV_PHL_AURORA: 'Aurora',
  PROV_PHL_AUTONOMOUSREGIONINMUSLIMMINDANAO: 'Autonomous Region in Muslim Mindanao',
  PROV_PHL_BASILAN: 'Basilan',
  PROV_PHL_BATAAN: 'Bataan',
  PROV_PHL_BATANES: 'Batanes',
  PROV_PHL_BATANGAS: 'Batangas',
  PROV_PHL_BENGUET: 'Benguet',
  PROV_PHL_BICOLREGION: 'Bicol Region',
  PROV_PHL_BILIRAN: 'Biliran',
  PROV_PHL_BOHOL: 'Bohol',
  PROV_PHL_BUKIDNON: 'Bukidnon',
  PROV_PHL_BULACAN: 'Bulacan',
  PROV_PHL_CAGAYAN: 'Cagayan',
  PROV_PHL_CAGAYANVALLEY: 'Cagayan Valley',
  PROV_PHL_CALABARZON: 'Calabarzon',
  PROV_PHL_CAMARINESNORTE: 'Camarines Norte',
  PROV_PHL_CAMARINESSUR: 'Camarines Sur',
  PROV_PHL_CAMIGUIN: 'Camiguin',
  PROV_PHL_CAPIZ: 'Capiz',
  PROV_PHL_CARAGA: 'Caraga',
  PROV_PHL_CATANDUANES: 'Catanduanes',
  PROV_PHL_CAVITE: 'Cavite',
  PROV_PHL_CEBU: 'Cebu',
  PROV_PHL_CENTRALLUZON: 'Central Luzon',
  PROV_PHL_CENTRALVISAYAS: 'Central Visayas',
  PROV_PHL_COMPOSTELAVALLEY: 'Compostela Valley',
  PROV_PHL_CORDILLERAADMINISTRATIVEREGION: 'Cordillera Administrative Region',
  PROV_PHL_COTABATO: 'Cotabato',
  PROV_PHL_DAVAODELNORTE: 'Davao del Norte',
  PROV_PHL_DAVAODELSUR: 'Davao del Sur',
  PROV_PHL_DAVAOOCCIDENTAL: 'Davao Occidental',
  PROV_PHL_DAVAOORIENTAL: 'Davao Oriental',
  PROV_PHL_DAVAOREGION: 'Davao Region',
  PROV_PHL_DINAGATISLANDS: 'Dinagat Islands',
  PROV_PHL_EASTERNSAMAR: 'Eastern Samar',
  PROV_PHL_EASTERNVISAYAS: 'Eastern Visayas',
  PROV_PHL_GUIMARAS: 'Guimaras',
  PROV_PHL_IFUGAO: 'Ifugao',
  PROV_PHL_ILOCOSNORTE: 'Ilocos Norte',
  PROV_PHL_ILOCOSREGION: 'Ilocos Region',
  PROV_PHL_ILOCOSSUR: 'Ilocos Sur',
  PROV_PHL_ILOILO: 'Iloilo',
  PROV_PHL_ISABELA: 'Isabela',
  PROV_PHL_KALINGA: 'Kalinga',
  PROV_PHL_LAUNION: 'La Union',
  PROV_PHL_LAGUNA: 'Laguna',
  PROV_PHL_LANAODELNORTE: 'Lanao del Norte',
  PROV_PHL_LANAODELSUR: 'Lanao del Sur',
  PROV_PHL_LEYTE: 'Leyte',
  PROV_PHL_MAGUINDANAO: 'Maguindanao',
  PROV_PHL_MARINDUQUE: 'Marinduque',
  PROV_PHL_MASBATE: 'Masbate',
  PROV_PHL_METROMANILA: 'Metro Manila',
  PROV_PHL_MIMAROPA: 'Mimaropa',
  PROV_PHL_MISAMISOCCIDENTAL: 'Misamis Occidental',
  PROV_PHL_MISAMISORIENTAL: 'Misamis Oriental',
  PROV_PHL_MOUNTAINPROVINCE: 'Mountain Province',
  PROV_PHL_NEGROSOCCIDENTAL: 'Negros Occidental',
  PROV_PHL_NEGROSORIENTAL: 'Negros Oriental',
  PROV_PHL_NORTHERNMINDANAO: 'Northern Mindanao',
  PROV_PHL_NORTHERNSAMAR: 'Northern Samar',
  PROV_PHL_NUEVAECIJA: 'Nueva Ecija',
  PROV_PHL_NUEVAVIZCAYA: 'Nueva Vizcaya',
  PROV_PHL_OCCIDENTALMINDORO: 'Occidental Mindoro',
  PROV_PHL_ORIENTALMINDORO: 'Oriental Mindoro',
  PROV_PHL_PALAWAN: 'Palawan',
  PROV_PHL_PAMPANGA: 'Pampanga',
  PROV_PHL_PANGASINAN: 'Pangasinan',
  PROV_PHL_QUEZON: 'Quezon',
  PROV_PHL_QUIRINO: 'Quirino',
  PROV_PHL_RIZAL: 'Rizal',
  PROV_PHL_ROMBLON: 'Romblon',
  PROV_PHL_SARANGANI: 'Sarangani',
  PROV_PHL_SIQUIJOR: 'Siquijor',
  PROV_PHL_SOCCSKSARGEN: 'Soccsksargen',
  PROV_PHL_SORSOGON: 'Sorsogon',
  PROV_PHL_SOUTHCOTABATO: 'South Cotabato',
  PROV_PHL_SOUTHERNLEYTE: 'Southern Leyte',
  PROV_PHL_SULTANKUDARAT: 'Sultan Kudarat',
  PROV_PHL_SULU: 'Sulu',
  PROV_PHL_SURIGAODELNORTE: 'Surigao del Norte',
  PROV_PHL_SURIGAODELSUR: 'Surigao del Sur',
  PROV_PHL_TARLAC: 'Tarlac',
  PROV_PHL_TAWITAWI: 'Tawi-Tawi',
  PROV_PHL_WESTERNVISAYAS: 'Western Visayas',
  PROV_PHL_ZAMBALES: 'Zambales',
  PROV_PHL_ZAMBOANGADELNORTE: 'Zamboanga del Norte',
  PROV_PHL_ZAMBOANGADELSUR: 'Zamboanga del Sur',
  PROV_PHL_ZAMBOANGAPENINSULA: 'Zamboanga Peninsula',
  PROV_PHL_ZAMBOANGASIBUGAY: 'Zamboanga Sibugay',
  PROV_PCN_NOPROVINCE: 'No Province',
  PROV_POL_GREATERPOLANDVOIVODESHIP: 'Greater Poland Voivodeship',
  PROV_POL_KUYAVIANPOMERANIANVOIVODESHIP: 'Kuyavian-Pomeranian Voivodeship',
  PROV_POL_LESSERPOLANDVOIVODESHIP: 'Lesser Poland Voivodeship',
  PROV_POL_LOWERSILESIANVOIVODESHIP: 'Lower Silesian Voivodeship',
  PROV_POL_LUBLINVOIVODESHIP: 'Lublin Voivodeship',
  PROV_POL_LUBUSZVOIVODESHIP: 'Lubusz Voivodeship',
  PROV_POL_DVOIVODESHIP: 'Łódź Voivodeship',
  PROV_POL_MASOVIANVOIVODESHIP: 'Masovian Voivodeship',
  PROV_POL_OPOLEVOIVODESHIP: 'Opole Voivodeship',
  PROV_POL_PODKARPACKIEVOIVODESHIP: 'Podkarpackie Voivodeship',
  PROV_POL_PODLASKIEVOIVODESHIP: 'Podlaskie Voivodeship',
  PROV_POL_POMERANIANVOIVODESHIP: 'Pomeranian Voivodeship',
  PROV_POL_SILESIANVOIVODESHIP: 'Silesian Voivodeship',
  PROV_POL_WITOKRZYSKIEVOIVODESHIP: 'Świętokrzyskie Voivodeship',
  PROV_POL_WARMIANMASURIANVOIVODESHIP: 'Warmian-Masurian Voivodeship',
  PROV_POL_WESTPOMERANIANVOIVODESHIP: 'West Pomeranian Voivodeship',
  PROV_PRT_AORES: 'Açores',
  PROV_PRT_AVEIRO: 'Aveiro',
  PROV_PRT_BEJA: 'Beja',
  PROV_PRT_BRAGA: 'Braga',
  PROV_PRT_BRAGANA: 'Bragança',
  PROV_PRT_CASTELOBRANCO: 'Castelo Branco',
  PROV_PRT_COIMBRA: 'Coimbra',
  PROV_PRT_VORA: 'Évora',
  PROV_PRT_FARO: 'Faro',
  PROV_PRT_GUARDA: 'Guarda',
  PROV_PRT_LEIRIA: 'Leiria',
  PROV_PRT_LISBON: 'Lisbon',
  PROV_PRT_MADEIRA: 'Madeira',
  PROV_PRT_PORTALEGRE: 'Portalegre',
  PROV_PRT_PORTO: 'Porto',
  PROV_PRT_SANTARM: 'Santarém',
  PROV_PRT_SETBAL: 'Setúbal',
  PROV_PRT_VIANADOCASTELO: 'Viana do Castelo',
  PROV_PRT_VILAREAL: 'Vila Real',
  PROV_PRT_VISEU: 'Viseu',
  PROV_PRI_ARECIBO: 'Arecibo',
  PROV_PRI_BAYAMON: 'Bayamon',
  PROV_PRI_CAGUAS: 'Caguas',
  PROV_PRI_CAROLINA: 'Carolina',
  PROV_PRI_GUAYNABO: 'Guaynabo',
  PROV_PRI_MAYAGEZ: 'Mayagüez',
  PROV_PRI_PONCE: 'Ponce',
  PROV_PRI_SANJUAN: 'San Juan',
  PROV_PRI_TOABAJA: 'Toa Baja',
  PROV_PRI_TRUJILLOALTO: 'Trujillo Alto',
  PROV_QAT_ALDAAYEN: 'Al Daayen',
  PROV_QAT_ALKHOR: 'Al Khor',
  PROV_QAT_ALRAYYANMUNICIPALITY: 'Al Rayyan Municipality',
  PROV_QAT_ALWAKRAH: 'Al Wakrah',
  PROV_QAT_ALSHAHANIYA: 'Al-Shahaniya',
  PROV_QAT_DOHA: 'Doha',
  PROV_QAT_MADINATASHSHAMAL: 'Madinat ash Shamal',
  PROV_QAT_UMMSALALMUNICIPALITY: 'Umm Salal Municipality',
  PROV_REU_NOPROVINCE: 'No Province',
  PROV_ROU_ALBA: 'Alba',
  PROV_ROU_ARADCOUNTY: 'Arad County',
  PROV_ROU_ARGES: 'Arges',
  PROV_ROU_BACUCOUNTY: 'Bacău County',
  PROV_ROU_BIHORCOUNTY: 'Bihor County',
  PROV_ROU_BISTRIANSUDCOUNTY: 'Bistrița-Năsăud County',
  PROV_ROU_BOTOANICOUNTY: 'Botoșani County',
  PROV_ROU_BRAILA: 'Braila',
  PROV_ROU_BRAOVCOUNTY: 'Brașov County',
  PROV_ROU_BUCHAREST: 'Bucharest',
  PROV_ROU_BUZUCOUNTY: 'Buzău County',
  PROV_ROU_CLRAICOUNTY: 'Călărași County',
  PROV_ROU_CARASEVERINCOUNTY: 'Caraș-Severin County',
  PROV_ROU_CLUJCOUNTY: 'Cluj County',
  PROV_ROU_CONSTANACOUNTY: 'Constanța County',
  PROV_ROU_COVASNACOUNTY: 'Covasna County',
  PROV_ROU_DMBOVIACOUNTY: 'Dâmbovița County',
  PROV_ROU_DOLJCOUNTY: 'Dolj County',
  PROV_ROU_GALAICOUNTY: 'Galați County',
  PROV_ROU_GIURGIUCOUNTY: 'Giurgiu County',
  PROV_ROU_GORJCOUNTY: 'Gorj County',
  PROV_ROU_HARGHITACOUNTY: 'Harghita County',
  PROV_ROU_HUNEDOARACOUNTY: 'Hunedoara County',
  PROV_ROU_IALOMIACOUNTY: 'Ialomița County',
  PROV_ROU_IAICOUNTY: 'Iași County',
  PROV_ROU_ILFOVCOUNTY: 'Ilfov County',
  PROV_ROU_MARAMURECOUNTY: 'Maramureș County',
  PROV_ROU_MEHEDINICOUNTY: 'Mehedinți County',
  PROV_ROU_MURECOUNTY: 'Mureș County',
  PROV_ROU_NEAMCOUNTY: 'Neamț County',
  PROV_ROU_OLTCOUNTY: 'Olt County',
  PROV_ROU_PRAHOVACOUNTY: 'Prahova County',
  PROV_ROU_SLAJCOUNTY: 'Sălaj County',
  PROV_ROU_SATUMARECOUNTY: 'Satu Mare County',
  PROV_ROU_SIBIUCOUNTY: 'Sibiu County',
  PROV_ROU_SUCEAVACOUNTY: 'Suceava County',
  PROV_ROU_TELEORMANCOUNTY: 'Teleorman County',
  PROV_ROU_TIMICOUNTY: 'Timiș County',
  PROV_ROU_TULCEACOUNTY: 'Tulcea County',
  PROV_ROU_VLCEACOUNTY: 'Vâlcea County',
  PROV_ROU_VASLUICOUNTY: 'Vaslui County',
  PROV_ROU_VRANCEACOUNTY: 'Vrancea County',
  PROV_RUS_ALTAIKRAI: 'Altai Krai',
  PROV_RUS_ALTAIREPUBLIC: 'Altai Republic',
  PROV_RUS_AMUROBLAST: 'Amur Oblast',
  PROV_RUS_ARKHANGELSK: 'Arkhangelsk',
  PROV_RUS_ASTRAKHANOBLAST: 'Astrakhan Oblast',
  PROV_RUS_BELGORODOBLAST: 'Belgorod Oblast',
  PROV_RUS_BRYANSKOBLAST: 'Bryansk Oblast',
  PROV_RUS_CHECHENREPUBLIC: 'Chechen Republic',
  PROV_RUS_CHELYABINSKOBLAST: 'Chelyabinsk Oblast',
  PROV_RUS_CHUKOTKAAUTONOMOUSOKRUG: 'Chukotka Autonomous Okrug',
  PROV_RUS_CHUVASHREPUBLIC: 'Chuvash Republic',
  PROV_RUS_IRKUTSK: 'Irkutsk',
  PROV_RUS_IVANOVOOBLAST: 'Ivanovo Oblast',
  PROV_RUS_JEWISHAUTONOMOUSOBLAST: 'Jewish Autonomous Oblast',
  PROV_RUS_KABARDINOBALKARREPUBLIC: 'Kabardino-Balkar Republic',
  PROV_RUS_KALININGRAD: 'Kaliningrad',
  PROV_RUS_KALUGAOBLAST: 'Kaluga Oblast',
  PROV_RUS_KAMCHATKAKRAI: 'Kamchatka Krai',
  PROV_RUS_KARACHAYCHERKESSREPUBLIC: 'Karachay-Cherkess Republic',
  PROV_RUS_KEMEROVOOBLAST: 'Kemerovo Oblast',
  PROV_RUS_KHABAROVSKKRAI: 'Khabarovsk Krai',
  PROV_RUS_KHANTYMANSIAUTONOMOUSOKRUG: 'Khanty-Mansi Autonomous Okrug',
  PROV_RUS_KIROVOBLAST: 'Kirov Oblast',
  PROV_RUS_KOMIREPUBLIC: 'Komi Republic',
  PROV_RUS_KOSTROMAOBLAST: 'Kostroma Oblast',
  PROV_RUS_KRASNODARKRAI: 'Krasnodar Krai',
  PROV_RUS_KRASNOYARSKKRAI: 'Krasnoyarsk Krai',
  PROV_RUS_KURGANOBLAST: 'Kurgan Oblast',
  PROV_RUS_KURSKOBLAST: 'Kursk Oblast',
  PROV_RUS_LENINGRADOBLAST: 'Leningrad Oblast',
  PROV_RUS_LIPETSKOBLAST: 'Lipetsk Oblast',
  PROV_RUS_MAGADANOBLAST: 'Magadan Oblast',
  PROV_RUS_MARIELREPUBLIC: 'Mari El Republic',
  PROV_RUS_MOSCOW: 'Moscow',
  PROV_RUS_MOSCOWOBLAST: 'Moscow Oblast',
  PROV_RUS_MURMANSKOBLAST: 'Murmansk Oblast',
  PROV_RUS_NENETSAUTONOMOUSOKRUG: 'Nenets Autonomous Okrug',
  PROV_RUS_NIZHNYNOVGORODOBLAST: 'Nizhny Novgorod Oblast',
  PROV_RUS_NOVGORODOBLAST: 'Novgorod Oblast',
  PROV_RUS_NOVOSIBIRSK: 'Novosibirsk',
  PROV_RUS_OMSKOBLAST: 'Omsk Oblast',
  PROV_RUS_ORENBURGOBLAST: 'Orenburg Oblast',
  PROV_RUS_ORYOLOBLAST: 'Oryol Oblast',
  PROV_RUS_PENZAOBLAST: 'Penza Oblast',
  PROV_RUS_PERMKRAI: 'Perm Krai',
  PROV_RUS_PRIMORSKYKRAI: 'Primorsky Krai',
  PROV_RUS_PSKOVOBLAST: 'Pskov Oblast',
  PROV_RUS_REPUBLICOFADYGEA: 'Republic of Adygea',
  PROV_RUS_REPUBLICOFBASHKORTOSTAN: 'Republic of Bashkortostan',
  PROV_RUS_REPUBLICOFBURYATIA: 'Republic of Buryatia',
  PROV_RUS_REPUBLICOFDAGESTAN: 'Republic of Dagestan',
  PROV_RUS_REPUBLICOFINGUSHETIA: 'Republic of Ingushetia',
  PROV_RUS_REPUBLICOFKALMYKIA: 'Republic of Kalmykia',
  PROV_RUS_REPUBLICOFKARELIA: 'Republic of Karelia',
  PROV_RUS_REPUBLICOFKHAKASSIA: 'Republic of Khakassia',
  PROV_RUS_REPUBLICOFMORDOVIA: 'Republic of Mordovia',
  PROV_RUS_REPUBLICOFNORTHOSSETIAALANIA: 'Republic of North Ossetia-Alania',
  PROV_RUS_REPUBLICOFTATARSTAN: 'Republic of Tatarstan',
  PROV_RUS_ROSTOVOBLAST: 'Rostov Oblast',
  PROV_RUS_RYAZANOBLAST: 'Ryazan Oblast',
  PROV_RUS_SAINTPETERSBURG: 'Saint Petersburg',
  PROV_RUS_SAKHAREPUBLIC: 'Sakha Republic',
  PROV_RUS_SAKHALIN: 'Sakhalin',
  PROV_RUS_SAMARAOBLAST: 'Samara Oblast',
  PROV_RUS_SARATOVOBLAST: 'Saratov Oblast',
  PROV_RUS_SEVASTOPOL: 'Sevastopol',
  PROV_RUS_SMOLENSKOBLAST: 'Smolensk Oblast',
  PROV_RUS_STAVROPOLKRAI: 'Stavropol Krai',
  PROV_RUS_SVERDLOVSK: 'Sverdlovsk',
  PROV_RUS_TAMBOVOBLAST: 'Tambov Oblast',
  PROV_RUS_TOMSKOBLAST: 'Tomsk Oblast',
  PROV_RUS_TULAOBLAST: 'Tula Oblast',
  PROV_RUS_TUVAREPUBLIC: 'Tuva Republic',
  PROV_RUS_TVEROBLAST: 'Tver Oblast',
  PROV_RUS_TYUMENOBLAST: 'Tyumen Oblast',
  PROV_RUS_UDMURTREPUBLIC: 'Udmurt Republic',
  PROV_RUS_ULYANOVSKOBLAST: 'Ulyanovsk Oblast',
  PROV_RUS_VLADIMIROBLAST: 'Vladimir Oblast',
  PROV_RUS_VOLGOGRADOBLAST: 'Volgograd Oblast',
  PROV_RUS_VOLOGDAOBLAST: 'Vologda Oblast',
  PROV_RUS_VORONEZHOBLAST: 'Voronezh Oblast',
  PROV_RUS_YAMALONENETSAUTONOMOUSOKRUG: 'Yamalo-Nenets Autonomous Okrug',
  PROV_RUS_YAROSLAVLOBLAST: 'Yaroslavl Oblast',
  PROV_RUS_ZABAYKALSKYKRAI: 'Zabaykalsky Krai',
  PROV_RWA_EASTERNPROVINCE: 'Eastern Province',
  PROV_RWA_KIGALIDISTRICT: 'Kigali district',
  PROV_RWA_NORTHERNPROVINCE: 'Northern Province',
  PROV_RWA_SOUTHERNPROVINCE: 'Southern Province',
  PROV_RWA_WESTERNPROVINCE: 'Western Province',
  PROV_SHN_NOPROVINCE: 'No Province',
  PROV_KNA_CHRISTCHURCHNICHOLATOWNPARISH: 'Christ Church Nichola Town Parish',
  PROV_KNA_NEVIS: 'Nevis',
  PROV_KNA_SAINTANNESANDYPOINTPARISH: 'Saint Anne Sandy Point Parish',
  PROV_KNA_SAINTGEORGEGINGERLANDPARISH: 'Saint George Gingerland Parish',
  PROV_KNA_SAINTJAMESWINDWARDPARISH: 'Saint James Windward Parish',
  PROV_KNA_SAINTJOHNCAPISTERREPARISH: 'Saint John Capisterre Parish',
  PROV_KNA_SAINTJOHNFIGTREEPARISH: 'Saint John Figtree Parish',
  PROV_KNA_SAINTKITTS: 'Saint Kitts',
  PROV_KNA_SAINTMARYCAYONPARISH: 'Saint Mary Cayon Parish',
  PROV_KNA_SAINTPAULCAPISTERREPARISH: 'Saint Paul Capisterre Parish',
  PROV_KNA_SAINTPAULCHARLESTOWNPARISH: 'Saint Paul Charlestown Parish',
  PROV_KNA_SAINTPETERBASSETERREPARISH: 'Saint Peter Basseterre Parish',
  PROV_KNA_SAINTTHOMASLOWLANDPARISH: 'Saint Thomas Lowland Parish',
  PROV_KNA_SAINTTHOMASMIDDLEISLANDPARISH: 'Saint Thomas Middle Island Parish',
  PROV_KNA_TRINITYPALMETTOPOINTPARISH: 'Trinity Palmetto Point Parish',
  PROV_LCA_ANSELARAYEQUARTER: 'Anse la Raye Quarter',
  PROV_LCA_CANARIES: 'Canaries',
  PROV_LCA_CASTRIESQUARTER: 'Castries Quarter',
  PROV_LCA_CHOISEULQUARTER: 'Choiseul Quarter',
  PROV_LCA_DAUPHINQUARTER: 'Dauphin Quarter',
  PROV_LCA_DENNERYQUARTER: 'Dennery Quarter',
  PROV_LCA_GROSISLETQUARTER: 'Gros Islet Quarter',
  PROV_LCA_LABORIEQUARTER: 'Laborie Quarter',
  PROV_LCA_MICOUDQUARTER: 'Micoud Quarter',
  PROV_LCA_PRASLINQUARTER: 'Praslin Quarter',
  PROV_LCA_SOUFRIREQUARTER: 'Soufrière Quarter',
  PROV_LCA_VIEUXFORTQUARTER: 'Vieux Fort Quarter',
  PROV_SPM_NOPROVINCE: 'No Province',
  PROV_VCT_CHARLOTTEPARISH: 'Charlotte Parish',
  PROV_VCT_GRENADINESPARISH: 'Grenadines Parish',
  PROV_VCT_SAINTANDREWPARISH: 'Saint Andrew Parish',
  PROV_VCT_SAINTDAVIDPARISH: 'Saint David Parish',
  PROV_VCT_SAINTGEORGEPARISH: 'Saint George Parish',
  PROV_VCT_SAINTPATRICKPARISH: 'Saint Patrick Parish',
  PROV_BLM_NOPROVINCE: 'No Province',
  PROV_MAF_NOPROVINCE: 'No Province',
  PROV_WSM_AANA: "A'ana",
  PROV_WSM_AIGAILETAI: 'Aiga-i-le-Tai',
  PROV_WSM_ATUA: 'Atua',
  PROV_WSM_FAASALELEAGA: "Fa'asaleleaga",
  PROV_WSM_GAGAEMAUGA: "Gaga'emauga",
  PROV_WSM_GAGAIFOMAUGA: "Gaga'ifomauga",
  PROV_WSM_PALAULI: 'Palauli',
  PROV_WSM_SATUPAITEA: "Satupa'itea",
  PROV_WSM_TUAMASAGA: 'Tuamasaga',
  PROV_WSM_VAAOFONOTI: "Va'a-o-Fonoti",
  PROV_WSM_VAISIGANO: 'Vaisigano',
  PROV_SMR_ACQUAVIVA: 'Acquaviva',
  PROV_SMR_BORGOMAGGIORE: 'Borgo Maggiore',
  PROV_SMR_CHIESANUOVA: 'Chiesanuova',
  PROV_SMR_DOMAGNANO: 'Domagnano',
  PROV_SMR_FAETANO: 'Faetano',
  PROV_SMR_FIORENTINO: 'Fiorentino',
  PROV_SMR_MONTEGIARDINO: 'Montegiardino',
  PROV_SMR_SANMARINO: 'San Marino',
  PROV_SMR_SERRAVALLE: 'Serravalle',
  PROV_STP_PRNCIPEPROVINCE: 'Príncipe Province',
  PROV_STP_SOTOMPROVINCE: 'São Tomé Province',
  PROV_SAU_ASIR: "'Asir",
  PROV_SAU_ALBAHAH: 'Al Bahah',
  PROV_SAU_ALJAWF: 'Al Jawf',
  PROV_SAU_ALMADINAH: 'Al Madinah',
  PROV_SAU_ALQASSIM: 'Al-Qassim',
  PROV_SAU_EASTERNPROVINCE: 'Eastern Province',
  PROV_SAU_HAIL: "Ha'il",
  PROV_SAU_JIZAN: 'Jizan',
  PROV_SAU_MAKKAH: 'Makkah',
  PROV_SAU_NAJRAN: 'Najran',
  PROV_SAU_NORTHERNBORDERS: 'Northern Borders',
  PROV_SAU_RIYADH: 'Riyadh',
  PROV_SAU_TABUK: 'Tabuk',
  PROV_SEN_DAKAR: 'Dakar',
  PROV_SEN_DIOURBELREGION: 'Diourbel Region',
  PROV_SEN_FATICK: 'Fatick',
  PROV_SEN_KAFFRINE: 'Kaffrine',
  PROV_SEN_KAOLACK: 'Kaolack',
  PROV_SEN_KDOUGOU: 'Kédougou',
  PROV_SEN_KOLDA: 'Kolda',
  PROV_SEN_LOUGA: 'Louga',
  PROV_SEN_MATAM: 'Matam',
  PROV_SEN_SAINTLOUIS: 'Saint-Louis',
  PROV_SEN_SDHIOU: 'Sédhiou',
  PROV_SEN_TAMBACOUNDAREGION: 'Tambacounda Region',
  PROV_SEN_THISREGION: 'Thiès Region',
  PROV_SEN_ZIGUINCHOR: 'Ziguinchor',
  PROV_SRB_BELGRADE: 'Belgrade',
  PROV_SRB_BORDISTRICT: 'Bor District',
  PROV_SRB_BRANIEVODISTRICT: 'Braničevo District',
  PROV_SRB_CENTRALBANATDISTRICT: 'Central Banat District',
  PROV_SRB_JABLANICADISTRICT: 'Jablanica District',
  PROV_SRB_KOLUBARADISTRICT: 'Kolubara District',
  PROV_SRB_MAVADISTRICT: 'Mačva District',
  PROV_SRB_MORAVICADISTRICT: 'Moravica District',
  PROV_SRB_NIAVADISTRICT: 'Nišava District',
  PROV_SRB_NORTHBAKADISTRICT: 'North Bačka District',
  PROV_SRB_NORTHBANATDISTRICT: 'North Banat District',
  PROV_SRB_PINJADISTRICT: 'Pčinja District',
  PROV_SRB_PIROTDISTRICT: 'Pirot District',
  PROV_SRB_PODUNAVLJEDISTRICT: 'Podunavlje District',
  PROV_SRB_POMORAVLJEDISTRICT: 'Pomoravlje District',
  PROV_SRB_RASINADISTRICT: 'Rasina District',
  PROV_SRB_RAKADISTRICT: 'Raška District',
  PROV_SRB_SOUTHBAKADISTRICT: 'South Bačka District',
  PROV_SRB_SOUTHBANATDISTRICT: 'South Banat District',
  PROV_SRB_SREMDISTRICT: 'Srem District',
  PROV_SRB_UMADIJADISTRICT: 'Šumadija District',
  PROV_SRB_TOPLICADISTRICT: 'Toplica District',
  PROV_SRB_VOJVODINA: 'Vojvodina',
  PROV_SRB_WESTBAKADISTRICT: 'West Bačka District',
  PROV_SRB_ZAJEARDISTRICT: 'Zaječar District',
  PROV_SRB_ZLATIBORDISTRICT: 'Zlatibor District',
  PROV_SYC_ANSEBOILEAU: 'Anse Boileau',
  PROV_SYC_ANSEROYALE: 'Anse Royale',
  PROV_SYC_ANSEAUXPINS: 'Anse-aux-Pins',
  PROV_SYC_AUCAP: 'Au Cap',
  PROV_SYC_BAIELAZARE: 'Baie Lazare',
  PROV_SYC_BAIESAINTEANNE: 'Baie Sainte Anne',
  PROV_SYC_BEAUVALLON: 'Beau Vallon',
  PROV_SYC_BELAIR: 'Bel Air',
  PROV_SYC_BELOMBRE: 'Bel Ombre',
  PROV_SYC_CASCADE: 'Cascade',
  PROV_SYC_GLACIS: 'Glacis',
  PROV_SYC_GRANDANSEMAH: "Grand'Anse Mahé",
  PROV_SYC_GRANDANSEPRASLIN: "Grand'Anse Praslin",
  PROV_SYC_LADIGUE: 'La Digue',
  PROV_SYC_LARIVIREANGLAISE: 'La Rivière Anglaise',
  PROV_SYC_LESMAMELLES: 'Les Mamelles',
  PROV_SYC_MONTBUXTON: 'Mont Buxton',
  PROV_SYC_MONTFLEURI: 'Mont Fleuri',
  PROV_SYC_PLAISANCE: 'Plaisance',
  PROV_SYC_POINTELARUE: 'Pointe La Rue',
  PROV_SYC_PORTGLAUD: 'Port Glaud',
  PROV_SYC_ROCHECAIMAN: 'Roche Caiman',
  PROV_SYC_SAINTLOUIS: 'Saint Louis',
  PROV_SYC_TAKAMAKA: 'Takamaka',
  PROV_SLE_EASTERNPROVINCE: 'Eastern Province',
  PROV_SLE_NORTHERNPROVINCE: 'Northern Province',
  PROV_SLE_SOUTHERNPROVINCE: 'Southern Province',
  PROV_SLE_WESTERNAREA: 'Western Area',
  PROV_SGP_CENTRALSINGAPORECOMMUNITYDEVELOPMENTCOUNC: 'Central Singapore Community Development Council',
  PROV_SGP_NORTHEASTCOMMUNITYDEVELOPMENTCOUNCIL: 'North East Community Development Council',
  PROV_SGP_NORTHWESTCOMMUNITYDEVELOPMENTCOUNCIL: 'North West Community Development Council',
  PROV_SGP_SOUTHEASTCOMMUNITYDEVELOPMENTCOUNCIL: 'South East Community Development Council',
  PROV_SGP_SOUTHWESTCOMMUNITYDEVELOPMENTCOUNCIL: 'South West Community Development Council',
  PROV_SXM_NOPROVINCE: 'No Province',
  PROV_SVK_BANSKBYSTRICAREGION: 'Banská Bystrica Region',
  PROV_SVK_BRATISLAVAREGION: 'Bratislava Region',
  PROV_SVK_KOICEREGION: 'Košice Region',
  PROV_SVK_NITRAREGION: 'Nitra Region',
  PROV_SVK_PREOVREGION: 'Prešov Region',
  PROV_SVK_TRENNREGION: 'Trenčín Region',
  PROV_SVK_TRNAVAREGION: 'Trnava Region',
  PROV_SVK_ILINAREGION: 'Žilina Region',
  PROV_SVN_AJDOVINAMUNICIPALITY: 'Ajdovščina Municipality',
  PROV_SVN_ANKARANMUNICIPALITY: 'Ankaran Municipality',
  PROV_SVN_BELTINCIMUNICIPALITY: 'Beltinci Municipality',
  PROV_SVN_BENEDIKTMUNICIPALITY: 'Benedikt Municipality',
  PROV_SVN_BISTRICAOBSOTLIMUNICIPALITY: 'Bistrica ob Sotli Municipality',
  PROV_SVN_BLEDMUNICIPALITY: 'Bled Municipality',
  PROV_SVN_BLOKEMUNICIPALITY: 'Bloke Municipality',
  PROV_SVN_BOHINJMUNICIPALITY: 'Bohinj Municipality',
  PROV_SVN_BOROVNICAMUNICIPALITY: 'Borovnica Municipality',
  PROV_SVN_BOVECMUNICIPALITY: 'Bovec Municipality',
  PROV_SVN_BRASLOVEMUNICIPALITY: 'Braslovče Municipality',
  PROV_SVN_BRDAMUNICIPALITY: 'Brda Municipality',
  PROV_SVN_BREICEMUNICIPALITY: 'Brežice Municipality',
  PROV_SVN_BREZOVICAMUNICIPALITY: 'Brezovica Municipality',
  PROV_SVN_CANKOVAMUNICIPALITY: 'Cankova Municipality',
  PROV_SVN_CERKLJENAGORENJSKEMMUNICIPALITY: 'Cerklje na Gorenjskem Municipality',
  PROV_SVN_CERKNICAMUNICIPALITY: 'Cerknica Municipality',
  PROV_SVN_CERKNOMUNICIPALITY: 'Cerkno Municipality',
  PROV_SVN_CERKVENJAKMUNICIPALITY: 'Cerkvenjak Municipality',
  PROV_SVN_CITYMUNICIPALITYOFCELJE: 'City Municipality of Celje',
  PROV_SVN_CITYMUNICIPALITYOFNOVOMESTO: 'City Municipality of Novo Mesto',
  PROV_SVN_RENOVCIMUNICIPALITY: 'Črenšovci Municipality',
  PROV_SVN_RNANAKOROKEMMUNICIPALITY: 'Črna na Koroškem Municipality',
  PROV_SVN_RNOMELJMUNICIPALITY: 'Črnomelj Municipality',
  PROV_SVN_DESTRNIKMUNICIPALITY: 'Destrnik Municipality',
  PROV_SVN_DIVAAMUNICIPALITY: 'Divača Municipality',
  PROV_SVN_DOBJEMUNICIPALITY: 'Dobje Municipality',
  PROV_SVN_DOBREPOLJEMUNICIPALITY: 'Dobrepolje Municipality',
  PROV_SVN_DOBRNAMUNICIPALITY: 'Dobrna Municipality',
  PROV_SVN_DOBROVAPOLHOVGRADECMUNICIPALITY: 'Dobrova–Polhov Gradec Municipality',
  PROV_SVN_DOBROVNIKMUNICIPALITY: 'Dobrovnik Municipality',
  PROV_SVN_DOLPRILJUBLJANIMUNICIPALITY: 'Dol pri Ljubljani Municipality',
  PROV_SVN_DOLENJSKETOPLICEMUNICIPALITY: 'Dolenjske Toplice Municipality',
  PROV_SVN_DOMALEMUNICIPALITY: 'Domžale Municipality',
  PROV_SVN_DORNAVAMUNICIPALITY: 'Dornava Municipality',
  PROV_SVN_DRAVOGRADMUNICIPALITY: 'Dravograd Municipality',
  PROV_SVN_DUPLEKMUNICIPALITY: 'Duplek Municipality',
  PROV_SVN_GORENJAVASPOLJANEMUNICIPALITY: 'Gorenja Vas–Poljane Municipality',
  PROV_SVN_GORINICAMUNICIPALITY: 'Gorišnica Municipality',
  PROV_SVN_GORJEMUNICIPALITY: 'Gorje Municipality',
  PROV_SVN_GORNJARADGONAMUNICIPALITY: 'Gornja Radgona Municipality',
  PROV_SVN_GORNJIGRADMUNICIPALITY: 'Gornji Grad Municipality',
  PROV_SVN_GORNJIPETROVCIMUNICIPALITY: 'Gornji Petrovci Municipality',
  PROV_SVN_GRADMUNICIPALITY: 'Grad Municipality',
  PROV_SVN_GROSUPLJEMUNICIPALITY: 'Grosuplje Municipality',
  PROV_SVN_HAJDINAMUNICIPALITY: 'Hajdina Municipality',
  PROV_SVN_HOESLIVNICAMUNICIPALITY: 'Hoče–Slivnica Municipality',
  PROV_SVN_HODOMUNICIPALITY: 'Hodoš Municipality',
  PROV_SVN_HORJULMUNICIPALITY: 'Horjul Municipality',
  PROV_SVN_HRASTNIKMUNICIPALITY: 'Hrastnik Municipality',
  PROV_SVN_HRPELJEKOZINAMUNICIPALITY: 'Hrpelje–Kozina Municipality',
  PROV_SVN_IDRIJAMUNICIPALITY: 'Idrija Municipality',
  PROV_SVN_IGMUNICIPALITY: 'Ig Municipality',
  PROV_SVN_IVANNAGORICAMUNICIPALITY: 'Ivančna Gorica Municipality',
  PROV_SVN_IZOLAMUNICIPALITY: 'Izola Municipality',
  PROV_SVN_JESENICEMUNICIPALITY: 'Jesenice Municipality',
  PROV_SVN_JEZERSKOMUNICIPALITY: 'Jezersko Municipality',
  PROV_SVN_JURINCIMUNICIPALITY: 'Juršinci Municipality',
  PROV_SVN_KAMNIKMUNICIPALITY: 'Kamnik Municipality',
  PROV_SVN_KANALOBSOIMUNICIPALITY: 'Kanal ob Soči Municipality',
  PROV_SVN_KIDRIEVOMUNICIPALITY: 'Kidričevo Municipality',
  PROV_SVN_KOBARIDMUNICIPALITY: 'Kobarid Municipality',
  PROV_SVN_KOBILJEMUNICIPALITY: 'Kobilje Municipality',
  PROV_SVN_KOEVJEMUNICIPALITY: 'Kočevje Municipality',
  PROV_SVN_KOMENMUNICIPALITY: 'Komen Municipality',
  PROV_SVN_KOMENDAMUNICIPALITY: 'Komenda Municipality',
  PROV_SVN_KOPERCITYMUNICIPALITY: 'Koper City Municipality',
  PROV_SVN_KOSTANJEVICANAKRKIMUNICIPALITY: 'Kostanjevica na Krki Municipality',
  PROV_SVN_KOSTELMUNICIPALITY: 'Kostel Municipality',
  PROV_SVN_KOZJEMUNICIPALITY: 'Kozje Municipality',
  PROV_SVN_KRANJCITYMUNICIPALITY: 'Kranj City Municipality',
  PROV_SVN_KRANJSKAGORAMUNICIPALITY: 'Kranjska Gora Municipality',
  PROV_SVN_KRIEVCIMUNICIPALITY: 'Križevci Municipality',
  PROV_SVN_KUNGOTA: 'Kungota',
  PROV_SVN_KUZMAMUNICIPALITY: 'Kuzma Municipality',
  PROV_SVN_LAKOMUNICIPALITY: 'Laško Municipality',
  PROV_SVN_LENARTMUNICIPALITY: 'Lenart Municipality',
  PROV_SVN_LENDAVAMUNICIPALITY: 'Lendava Municipality',
  PROV_SVN_LITIJAMUNICIPALITY: 'Litija Municipality',
  PROV_SVN_LJUBLJANACITYMUNICIPALITY: 'Ljubljana City Municipality',
  PROV_SVN_LJUBNOMUNICIPALITY: 'Ljubno Municipality',
  PROV_SVN_LJUTOMERMUNICIPALITY: 'Ljutomer Municipality',
  PROV_SVN_LOGDRAGOMERMUNICIPALITY: 'Log–Dragomer Municipality',
  PROV_SVN_LOGATECMUNICIPALITY: 'Logatec Municipality',
  PROV_SVN_LOKADOLINAMUNICIPALITY: 'Loška Dolina Municipality',
  PROV_SVN_LOKIPOTOKMUNICIPALITY: 'Loški Potok Municipality',
  PROV_SVN_LOVRENCNAPOHORJUMUNICIPALITY: 'Lovrenc na Pohorju Municipality',
  PROV_SVN_LUEMUNICIPALITY: 'Luče Municipality',
  PROV_SVN_LUKOVICAMUNICIPALITY: 'Lukovica Municipality',
  PROV_SVN_MAJPERKMUNICIPALITY: 'Majšperk Municipality',
  PROV_SVN_MAKOLEMUNICIPALITY: 'Makole Municipality',
  PROV_SVN_MARIBORCITYMUNICIPALITY: 'Maribor City Municipality',
  PROV_SVN_MARKOVCIMUNICIPALITY: 'Markovci Municipality',
  PROV_SVN_MEDVODEMUNICIPALITY: 'Medvode Municipality',
  PROV_SVN_MENGEMUNICIPALITY: 'Mengeš Municipality',
  PROV_SVN_METLIKAMUNICIPALITY: 'Metlika Municipality',
  PROV_SVN_MEICAMUNICIPALITY: 'Mežica Municipality',
  PROV_SVN_MIKLAVNADRAVSKEMPOLJUMUNICIPALITY: 'Miklavž na Dravskem Polju Municipality',
  PROV_SVN_MIRENKOSTANJEVICAMUNICIPALITY: 'Miren–Kostanjevica Municipality',
  PROV_SVN_MIRNAMUNICIPALITY: 'Mirna Municipality',
  PROV_SVN_MIRNAPEMUNICIPALITY: 'Mirna Peč Municipality',
  PROV_SVN_MISLINJAMUNICIPALITY: 'Mislinja Municipality',
  PROV_SVN_MOKRONOGTREBELNOMUNICIPALITY: 'Mokronog–Trebelno Municipality',
  PROV_SVN_MORAVEMUNICIPALITY: 'Moravče Municipality',
  PROV_SVN_MORAVSKETOPLICEMUNICIPALITY: 'Moravske Toplice Municipality',
  PROV_SVN_MOZIRJEMUNICIPALITY: 'Mozirje Municipality',
  PROV_SVN_MUNICIPALITYOFAPAE: 'Municipality of Apače',
  PROV_SVN_MUNICIPALITYOFCIRKULANE: 'Municipality of Cirkulane',
  PROV_SVN_MUNICIPALITYOFILIRSKABISTRICA: 'Municipality of Ilirska Bistrica',
  PROV_SVN_MUNICIPALITYOFKRKO: 'Municipality of Krško',
  PROV_SVN_MUNICIPALITYOFKOFLJICA: 'Municipality of Škofljica',
  PROV_SVN_MURSKASOBOTACITYMUNICIPALITY: 'Murska Sobota City Municipality',
  PROV_SVN_MUTAMUNICIPALITY: 'Muta Municipality',
  PROV_SVN_NAKLOMUNICIPALITY: 'Naklo Municipality',
  PROV_SVN_NAZARJEMUNICIPALITY: 'Nazarje Municipality',
  PROV_SVN_NOVAGORICACITYMUNICIPALITY: 'Nova Gorica City Municipality',
  PROV_SVN_ODRANCIMUNICIPALITY: 'Odranci Municipality',
  PROV_SVN_OPLOTNICA: 'Oplotnica',
  PROV_SVN_ORMOMUNICIPALITY: 'Ormož Municipality',
  PROV_SVN_OSILNICAMUNICIPALITY: 'Osilnica Municipality',
  PROV_SVN_PESNICAMUNICIPALITY: 'Pesnica Municipality',
  PROV_SVN_PIRANMUNICIPALITY: 'Piran Municipality',
  PROV_SVN_PIVKAMUNICIPALITY: 'Pivka Municipality',
  PROV_SVN_PODETRTEKMUNICIPALITY: 'Podčetrtek Municipality',
  PROV_SVN_PODLEHNIKMUNICIPALITY: 'Podlehnik Municipality',
  PROV_SVN_PODVELKAMUNICIPALITY: 'Podvelka Municipality',
  PROV_SVN_POLJANEMUNICIPALITY: 'Poljčane Municipality',
  PROV_SVN_POLZELAMUNICIPALITY: 'Polzela Municipality',
  PROV_SVN_POSTOJNAMUNICIPALITY: 'Postojna Municipality',
  PROV_SVN_PREBOLDMUNICIPALITY: 'Prebold Municipality',
  PROV_SVN_PREDDVORMUNICIPALITY: 'Preddvor Municipality',
  PROV_SVN_PREVALJEMUNICIPALITY: 'Prevalje Municipality',
  PROV_SVN_PTUJCITYMUNICIPALITY: 'Ptuj City Municipality',
  PROV_SVN_PUCONCIMUNICIPALITY: 'Puconci Municipality',
  PROV_SVN_RAEFRAMMUNICIPALITY: 'Rače–Fram Municipality',
  PROV_SVN_RADEEMUNICIPALITY: 'Radeče Municipality',
  PROV_SVN_RADENCIMUNICIPALITY: 'Radenci Municipality',
  PROV_SVN_RADLJEOBDRAVIMUNICIPALITY: 'Radlje ob Dravi Municipality',
  PROV_SVN_RADOVLJICAMUNICIPALITY: 'Radovljica Municipality',
  PROV_SVN_RAVNENAKOROKEMMUNICIPALITY: 'Ravne na Koroškem Municipality',
  PROV_SVN_RAZKRIJEMUNICIPALITY: 'Razkrižje Municipality',
  PROV_SVN_REICAOBSAVINJIMUNICIPALITY: 'Rečica ob Savinji Municipality',
  PROV_SVN_RENEVOGRSKOMUNICIPALITY: 'Renče–Vogrsko Municipality',
  PROV_SVN_RIBNICAMUNICIPALITY: 'Ribnica Municipality',
  PROV_SVN_RIBNICANAPOHORJUMUNICIPALITY: 'Ribnica na Pohorju Municipality',
  PROV_SVN_ROGAKASLATINAMUNICIPALITY: 'Rogaška Slatina Municipality',
  PROV_SVN_ROGAOVCIMUNICIPALITY: 'Rogašovci Municipality',
  PROV_SVN_ROGATECMUNICIPALITY: 'Rogatec Municipality',
  PROV_SVN_RUEMUNICIPALITY: 'Ruše Municipality',
  PROV_SVN_ALOVCIMUNICIPALITY: 'Šalovci Municipality',
  PROV_SVN_SELNICAOBDRAVIMUNICIPALITY: 'Selnica ob Dravi Municipality',
  PROV_SVN_SEMIMUNICIPALITY: 'Semič Municipality',
  PROV_SVN_EMPETERVRTOJBAMUNICIPALITY: 'Šempeter–Vrtojba Municipality',
  PROV_SVN_ENURMUNICIPALITY: 'Šenčur Municipality',
  PROV_SVN_ENTILJMUNICIPALITY: 'Šentilj Municipality',
  PROV_SVN_ENTJERNEJMUNICIPALITY: 'Šentjernej Municipality',
  PROV_SVN_ENTJURMUNICIPALITY: 'Šentjur Municipality',
  PROV_SVN_ENTRUPERTMUNICIPALITY: 'Šentrupert Municipality',
  PROV_SVN_SEVNICAMUNICIPALITY: 'Sevnica Municipality',
  PROV_SVN_SEANAMUNICIPALITY: 'Sežana Municipality',
  PROV_SVN_KOCJANMUNICIPALITY: 'Škocjan Municipality',
  PROV_SVN_KOFJALOKAMUNICIPALITY: 'Škofja Loka Municipality',
  PROV_SVN_SLOVENJGRADECCITYMUNICIPALITY: 'Slovenj Gradec City Municipality',
  PROV_SVN_SLOVENSKABISTRICAMUNICIPALITY: 'Slovenska Bistrica Municipality',
  PROV_SVN_SLOVENSKEKONJICEMUNICIPALITY: 'Slovenske Konjice Municipality',
  PROV_SVN_MARJEPRIJELAHMUNICIPALITY: 'Šmarje pri Jelšah Municipality',
  PROV_SVN_MARJEKETOPLICEMUNICIPALITY: 'Šmarješke Toplice Municipality',
  PROV_SVN_MARTNOOBPAKIMUNICIPALITY: 'Šmartno ob Paki Municipality',
  PROV_SVN_MARTNOPRILITIJIMUNICIPALITY: 'Šmartno pri Litiji Municipality',
  PROV_SVN_SODRAICAMUNICIPALITY: 'Sodražica Municipality',
  PROV_SVN_SOLAVAMUNICIPALITY: 'Solčava Municipality',
  PROV_SVN_OTANJMUNICIPALITY: 'Šoštanj Municipality',
  PROV_SVN_SREDIEOBDRAVI: 'Središče ob Dravi',
  PROV_SVN_STAREMUNICIPALITY: 'Starše Municipality',
  PROV_SVN_TOREMUNICIPALITY: 'Štore Municipality',
  PROV_SVN_STRAAMUNICIPALITY: 'Straža Municipality',
  PROV_SVN_SVETAANAMUNICIPALITY: 'Sveta Ana Municipality',
  PROV_SVN_SVETATROJICAVSLOVENSKIHGORICAHMUNICIPALIT: 'Sveta Trojica v Slovenskih Goricah Municipality',
  PROV_SVN_SVETIANDRAVSLOVENSKIHGORICAHMUNICIPALITY: 'Sveti Andraž v Slovenskih Goricah Municipality',
  PROV_SVN_SVETIJURIJOBAVNICIMUNICIPALITY: 'Sveti Jurij ob Ščavnici Municipality',
  PROV_SVN_SVETIJURIJVSLOVENSKIHGORICAHMUNICIPALITY: 'Sveti Jurij v Slovenskih Goricah Municipality',
  PROV_SVN_SVETITOMAMUNICIPALITY: 'Sveti Tomaž Municipality',
  PROV_SVN_TABORMUNICIPALITY: 'Tabor Municipality',
  PROV_SVN_TIINAMUNICIPALITY: 'Tišina Municipality',
  PROV_SVN_TOLMINMUNICIPALITY: 'Tolmin Municipality',
  PROV_SVN_TRBOVLJEMUNICIPALITY: 'Trbovlje Municipality',
  PROV_SVN_TREBNJEMUNICIPALITY: 'Trebnje Municipality',
  PROV_SVN_TRNOVSKAVASMUNICIPALITY: 'Trnovska Vas Municipality',
  PROV_SVN_TRIMUNICIPALITY: 'Tržič Municipality',
  PROV_SVN_TRZINMUNICIPALITY: 'Trzin Municipality',
  PROV_SVN_TURNIEMUNICIPALITY: 'Turnišče Municipality',
  PROV_SVN_VELIKAPOLANAMUNICIPALITY: 'Velika Polana Municipality',
  PROV_SVN_VELIKELAEMUNICIPALITY: 'Velike Lašče Municipality',
  PROV_SVN_VEREJMUNICIPALITY: 'Veržej Municipality',
  PROV_SVN_VIDEMMUNICIPALITY: 'Videm Municipality',
  PROV_SVN_VIPAVAMUNICIPALITY: 'Vipava Municipality',
  PROV_SVN_VITANJEMUNICIPALITY: 'Vitanje Municipality',
  PROV_SVN_VODICEMUNICIPALITY: 'Vodice Municipality',
  PROV_SVN_VOJNIKMUNICIPALITY: 'Vojnik Municipality',
  PROV_SVN_VRANSKOMUNICIPALITY: 'Vransko Municipality',
  PROV_SVN_VRHNIKAMUNICIPALITY: 'Vrhnika Municipality',
  PROV_SVN_VUZENICAMUNICIPALITY: 'Vuzenica Municipality',
  PROV_SVN_ZAGORJEOBSAVIMUNICIPALITY: 'Zagorje ob Savi Municipality',
  PROV_SVN_ALECMUNICIPALITY: 'Žalec Municipality',
  PROV_SVN_ZAVRMUNICIPALITY: 'Zavrč Municipality',
  PROV_SVN_ELEZNIKIMUNICIPALITY: 'Železniki Municipality',
  PROV_SVN_ETALEMUNICIPALITY: 'Žetale Municipality',
  PROV_SVN_IRIMUNICIPALITY: 'Žiri Municipality',
  PROV_SVN_IROVNICAMUNICIPALITY: 'Žirovnica Municipality',
  PROV_SVN_ZREEMUNICIPALITY: 'Zreče Municipality',
  PROV_SVN_UEMBERKMUNICIPALITY: 'Žužemberk Municipality',
  PROV_SLB_CENTRALPROVINCE: 'Central Province',
  PROV_SLB_CHOISEULPROVINCE: 'Choiseul Province',
  PROV_SLB_GUADALCANALPROVINCE: 'Guadalcanal Province',
  PROV_SLB_HONIARA: 'Honiara',
  PROV_SLB_ISABELPROVINCE: 'Isabel Province',
  PROV_SLB_MAKIRAULAWAPROVINCE: 'Makira-Ulawa Province',
  PROV_SLB_MALAITAPROVINCE: 'Malaita Province',
  PROV_SLB_RENNELLANDBELLONAPROVINCE: 'Rennell and Bellona Province',
  PROV_SLB_TEMOTUPROVINCE: 'Temotu Province',
  PROV_SLB_WESTERNPROVINCE: 'Western Province',
  PROV_SOM_AWDALREGION: 'Awdal Region',
  PROV_SOM_BAKOOL: 'Bakool',
  PROV_SOM_BANAADIR: 'Banaadir',
  PROV_SOM_BARI: 'Bari',
  PROV_SOM_BAY: 'Bay',
  PROV_SOM_GALGUDUUD: 'Galguduud',
  PROV_SOM_GEDO: 'Gedo',
  PROV_SOM_HIRAN: 'Hiran',
  PROV_SOM_LOWERJUBA: 'Lower Juba',
  PROV_SOM_LOWERSHEBELLE: 'Lower Shebelle',
  PROV_SOM_MIDDLEJUBA: 'Middle Juba',
  PROV_SOM_MIDDLESHEBELLE: 'Middle Shebelle',
  PROV_SOM_MUDUG: 'Mudug',
  PROV_SOM_NUGAL: 'Nugal',
  PROV_SOM_SANAAGREGION: 'Sanaag Region',
  PROV_SOM_TOGDHEERREGION: 'Togdheer Region',
  PROV_SGS_NOPROVINCE: 'No Province',
  PROV_KOR_GANGWONPROVINCE: 'Gangwon Province',
  PROV_KOR_GYEONGGIPROVINCE: 'Gyeonggi Province',
  PROV_KOR_JEJU: 'Jeju',
  PROV_KOR_NORTHCHUNGCHEONGPROVINCE: 'North Chungcheong Province',
  PROV_KOR_NORTHGYEONGSANGPROVINCE: 'North Gyeongsang Province',
  PROV_KOR_NORTHJEOLLAPROVINCE: 'North Jeolla Province',
  PROV_KOR_SOUTHCHUNGCHEONGPROVINCE: 'South Chungcheong Province',
  PROV_KOR_SOUTHGYEONGSANGPROVINCE: 'South Gyeongsang Province',
  PROV_KOR_SOUTHJEOLLAPROVINCE: 'South Jeolla Province',
  PROV_SSD_CENTRALEQUATORIA: 'Central Equatoria',
  PROV_SSD_EASTERNEQUATORIA: 'Eastern Equatoria',
  PROV_SSD_JONGLEISTATE: 'Jonglei State',
  PROV_SSD_LAKES: 'Lakes',
  PROV_SSD_NORTHERNBAHRELGHAZAL: 'Northern Bahr el Ghazal',
  PROV_SSD_UNITY: 'Unity',
  PROV_SSD_UPPERNILE: 'Upper Nile',
  PROV_SSD_WARRAP: 'Warrap',
  PROV_SSD_WESTERNBAHRELGHAZAL: 'Western Bahr el Ghazal',
  PROV_SSD_WESTERNEQUATORIA: 'Western Equatoria',
  PROV_ESP_ANDALUSIA: 'Andalusia',
  PROV_ESP_ARAGON: 'Aragon',
  PROV_ESP_ASTURIAS: 'Asturias',
  PROV_ESP_BALEARICISLANDS: 'Balearic Islands',
  PROV_ESP_BASQUECOUNTRY: 'Basque Country',
  PROV_ESP_CANARYISLANDS: 'Canary Islands',
  PROV_ESP_CANTABRIA: 'Cantabria',
  PROV_ESP_CASTILEANDLEN: 'Castile and León',
  PROV_ESP_CASTILLALAMANCHA: 'Castilla La Mancha',
  PROV_ESP_CATALONIA: 'Catalonia',
  PROV_ESP_CEUTA: 'Ceuta',
  PROV_ESP_EXTREMADURA: 'Extremadura',
  PROV_ESP_GALICIA: 'Galicia',
  PROV_ESP_LARIOJA: 'La Rioja',
  PROV_ESP_MADRID: 'Madrid',
  PROV_ESP_MELILLA: 'Melilla',
  PROV_ESP_MURCIA: 'Murcia',
  PROV_ESP_NAVARRA: 'Navarra',
  PROV_ESP_VALENCIA: 'Valencia',
  PROV_LKA_CENTRALPROVINCE: 'Central Province',
  PROV_LKA_EASTERNPROVINCE: 'Eastern Province',
  PROV_LKA_NORTHCENTRALPROVINCE: 'North Central Province',
  PROV_LKA_NORTHWESTERNPROVINCE: 'North Western Province',
  PROV_LKA_NORTHERNPROVINCE: 'Northern Province',
  PROV_LKA_SABARAGAMUWAPROVINCE: 'Sabaragamuwa Province',
  PROV_LKA_SOUTHERNPROVINCE: 'Southern Province',
  PROV_LKA_UVAPROVINCE: 'Uva Province',
  PROV_LKA_WESTERNPROVINCE: 'Western Province',
  PROV_SDN_ALJAZIRAH: 'Al Jazirah',
  PROV_SDN_ALQADARIF: 'Al Qadarif',
  PROV_SDN_BLUENILE: 'Blue Nile',
  PROV_SDN_CENTRALDARFUR: 'Central Darfur',
  PROV_SDN_EASTDARFUR: 'East Darfur',
  PROV_SDN_KASSALA: 'Kassala',
  PROV_SDN_KHARTOUM: 'Khartoum',
  PROV_SDN_NORTHDARFUR: 'North Darfur',
  PROV_SDN_NORTHKORDOFAN: 'North Kordofan',
  PROV_SDN_NORTHERN: 'Northern',
  PROV_SDN_REDSEA: 'Red Sea',
  PROV_SDN_RIVERNILE: 'River Nile',
  PROV_SDN_SENNAR: 'Sennar',
  PROV_SDN_SOUTHDARFUR: 'South Darfur',
  PROV_SDN_SOUTHKORDOFAN: 'South Kordofan',
  PROV_SDN_WESTDARFUR: 'West Darfur',
  PROV_SDN_WESTKORDOFAN: 'West Kordofan',
  PROV_SDN_WHITENILE: 'White Nile',
  PROV_SUR_BROKOPONDODISTRICT: 'Brokopondo District',
  PROV_SUR_COMMEWIJNEDISTRICT: 'Commewijne District',
  PROV_SUR_CORONIEDISTRICT: 'Coronie District',
  PROV_SUR_MAROWIJNEDISTRICT: 'Marowijne District',
  PROV_SUR_NICKERIEDISTRICT: 'Nickerie District',
  PROV_SUR_PARADISTRICT: 'Para District',
  PROV_SUR_PARAMARIBODISTRICT: 'Paramaribo District',
  PROV_SUR_SARAMACCADISTRICT: 'Saramacca District',
  PROV_SUR_SIPALIWINIDISTRICT: 'Sipaliwini District',
  PROV_SUR_WANICADISTRICT: 'Wanica District',
  PROV_SJM_NOPROVINCE: 'No Province',
  PROV_SWZ_HHOHHODISTRICT: 'Hhohho District',
  PROV_SWZ_LUBOMBODISTRICT: 'Lubombo District',
  PROV_SWZ_MANZINIDISTRICT: 'Manzini District',
  PROV_SWZ_SHISELWENIDISTRICT: 'Shiselweni District',
  PROV_SWE_BLEKINGE: 'Blekinge',
  PROV_SWE_DALARNACOUNTY: 'Dalarna County',
  PROV_SWE_GVLEBORGCOUNTY: 'Gävleborg County',
  PROV_SWE_GOTLANDCOUNTY: 'Gotland County',
  PROV_SWE_HALLANDCOUNTY: 'Halland County',
  PROV_SWE_JNKPINGCOUNTY: 'Jönköping County',
  PROV_SWE_KALMARCOUNTY: 'Kalmar County',
  PROV_SWE_KRONOBERGCOUNTY: 'Kronoberg County',
  PROV_SWE_NORRBOTTENCOUNTY: 'Norrbotten County',
  PROV_SWE_REBROCOUNTY: 'Örebro County',
  PROV_SWE_STERGTLANDCOUNTY: 'Östergötland County',
  PROV_SWE_SKNECOUNTY: 'Skåne County',
  PROV_SWE_SDERMANLANDCOUNTY: 'Södermanland County',
  PROV_SWE_STOCKHOLMCOUNTY: 'Stockholm County',
  PROV_SWE_UPPSALACOUNTY: 'Uppsala County',
  PROV_SWE_VRMLANDCOUNTY: 'Värmland County',
  PROV_SWE_VSTERBOTTENCOUNTY: 'Västerbotten County',
  PROV_SWE_VSTERNORRLANDCOUNTY: 'Västernorrland County',
  PROV_SWE_VSTMANLANDCOUNTY: 'Västmanland County',
  PROV_SWE_VSTRAGTALANDCOUNTY: 'Västra Götaland County',
  PROV_CHE_AARGAU: 'Aargau',
  PROV_CHE_APPENZELLAUSSERRHODEN: 'Appenzell Ausserrhoden',
  PROV_CHE_APPENZELLINNERRHODEN: 'Appenzell Innerrhoden',
  PROV_CHE_BASELLAND: 'Basel-Land',
  PROV_CHE_BASELSTADT: 'Basel-Stadt',
  PROV_CHE_BERN: 'Bern',
  PROV_CHE_FRIBOURG: 'Fribourg',
  PROV_CHE_GENEVA: 'Geneva',
  PROV_CHE_GLARUS: 'Glarus',
  PROV_CHE_GRAUBNDEN: 'Graubünden',
  PROV_CHE_JURA: 'Jura',
  PROV_CHE_LUCERNE: 'Lucerne',
  PROV_CHE_NEUCHTEL: 'Neuchâtel',
  PROV_CHE_NIDWALDEN: 'Nidwalden',
  PROV_CHE_OBWALDEN: 'Obwalden',
  PROV_CHE_SCHAFFHAUSEN: 'Schaffhausen',
  PROV_CHE_SCHWYZ: 'Schwyz',
  PROV_CHE_SOLOTHURN: 'Solothurn',
  PROV_CHE_STGALLEN: 'St. Gallen',
  PROV_CHE_THURGAU: 'Thurgau',
  PROV_CHE_TICINO: 'Ticino',
  PROV_CHE_URI: 'Uri',
  PROV_CHE_VALAIS: 'Valais',
  PROV_CHE_VAUD: 'Vaud',
  PROV_CHE_ZUG: 'Zug',
  PROV_CHE_ZRICH: 'Zürich',
  PROV_SYR_ALHASAKAHGOVERNORATE: 'Al-Hasakah Governorate',
  PROV_SYR_ALRAQQAHGOVERNORATE: 'Al-Raqqah Governorate',
  PROV_SYR_ALEPPOGOVERNORATE: 'Aleppo Governorate',
  PROV_SYR_ASSUWAYDAGOVERNORATE: 'As-Suwayda Governorate',
  PROV_SYR_DAMASCUSGOVERNORATE: 'Damascus Governorate',
  PROV_SYR_DARAAGOVERNORATE: 'Daraa Governorate',
  PROV_SYR_DEIREZZORGOVERNORATE: 'Deir ez-Zor Governorate',
  PROV_SYR_HAMAGOVERNORATE: 'Hama Governorate',
  PROV_SYR_HOMSGOVERNORATE: 'Homs Governorate',
  PROV_SYR_IDLIBGOVERNORATE: 'Idlib Governorate',
  PROV_SYR_LATAKIAGOVERNORATE: 'Latakia Governorate',
  PROV_SYR_QUNEITRAGOVERNORATE: 'Quneitra Governorate',
  PROV_SYR_RIFDIMASHQGOVERNORATE: 'Rif Dimashq Governorate',
  PROV_SYR_TARTUSGOVERNORATE: 'Tartus Governorate',
  PROV_TWN_CHANGHUA: 'Changhua',
  PROV_TWN_CHIAYI: 'Chiayi',
  PROV_TWN_CHIAYICOUNTY: 'Chiayi County',
  PROV_TWN_HSINCHUCOUNTY: 'Hsinchu County',
  PROV_TWN_HSINCHU: 'Hsinchu',
  PROV_TWN_HUALIEN: 'Hualien',
  PROV_TWN_KAOHSIUNG: 'Kaohsiung',
  PROV_TWN_KEELUNG: 'Keelung',
  PROV_TWN_KINMEN: 'Kinmen',
  PROV_TWN_LIENCHIANG: 'Lienchiang',
  PROV_TWN_MIAOLI: 'Miaoli',
  PROV_TWN_NANTOU: 'Nantou',
  PROV_TWN_NEWTAIPEI: 'New Taipei',
  PROV_TWN_PENGHU: 'Penghu',
  PROV_TWN_PINGTUNG: 'Pingtung',
  PROV_TWN_TAICHUNG: 'Taichung',
  PROV_TWN_TAINAN: 'Tainan',
  PROV_TWN_TAIPEI: 'Taipei',
  PROV_TWN_TAITUNG: 'Taitung',
  PROV_TWN_TAOYUAN: 'Taoyuan',
  PROV_TWN_YILAN: 'Yilan',
  PROV_TWN_YUNLIN: 'Yunlin',
  PROV_TJK_DISTRICTSOFREPUBLICANSUBORDINATION: 'districts of Republican Subordination',
  PROV_TJK_GORNOBADAKHSHANAUTONOMOUSPROVINCE: 'Gorno-Badakhshan Autonomous Province',
  PROV_TJK_KHATLONPROVINCE: 'Khatlon Province',
  PROV_TJK_SUGHDPROVINCE: 'Sughd Province',
  PROV_TZA_ARUSHA: 'Arusha',
  PROV_TZA_DARESSALAAM: 'Dar es Salaam',
  PROV_TZA_DODOMA: 'Dodoma',
  PROV_TZA_GEITA: 'Geita',
  PROV_TZA_IRINGA: 'Iringa',
  PROV_TZA_KAGERA: 'Kagera',
  PROV_TZA_KATAVI: 'Katavi',
  PROV_TZA_KIGOMA: 'Kigoma',
  PROV_TZA_KILIMANJARO: 'Kilimanjaro',
  PROV_TZA_LINDI: 'Lindi',
  PROV_TZA_MANYARA: 'Manyara',
  PROV_TZA_MARA: 'Mara',
  PROV_TZA_MBEYA: 'Mbeya',
  PROV_TZA_MOROGORO: 'Morogoro',
  PROV_TZA_MTWARA: 'Mtwara',
  PROV_TZA_MWANZA: 'Mwanza',
  PROV_TZA_NJOMBE: 'Njombe',
  PROV_TZA_PEMBANORTH: 'Pemba North',
  PROV_TZA_PEMBASOUTH: 'Pemba South',
  PROV_TZA_PWANI: 'Pwani',
  PROV_TZA_RUKWA: 'Rukwa',
  PROV_TZA_RUVUMA: 'Ruvuma',
  PROV_TZA_SHINYANGA: 'Shinyanga',
  PROV_TZA_SIMIYU: 'Simiyu',
  PROV_TZA_SINGIDA: 'Singida',
  PROV_TZA_SONGWE: 'Songwe',
  PROV_TZA_TABORA: 'Tabora',
  PROV_TZA_TANGA: 'Tanga',
  PROV_TZA_ZANZIBARNORTH: 'Zanzibar North',
  PROV_TZA_ZANZIBARSOUTH: 'Zanzibar South',
  PROV_TZA_ZANZIBARWEST: 'Zanzibar West',
  PROV_THA_AMNATCHAROEN: 'Amnat Charoen',
  PROV_THA_ANGTHONG: 'Ang Thong',
  PROV_THA_BANGKOK: 'Bangkok',
  PROV_THA_BUENGKAN: 'Bueng Kan',
  PROV_THA_BURIRAM: 'Buri Ram',
  PROV_THA_CHACHOENGSAO: 'Chachoengsao',
  PROV_THA_CHAINAT: 'Chai Nat',
  PROV_THA_CHAIYAPHUM: 'Chaiyaphum',
  PROV_THA_CHANTHABURI: 'Chanthaburi',
  PROV_THA_CHIANGMAI: 'Chiang Mai',
  PROV_THA_CHIANGRAI: 'Chiang Rai',
  PROV_THA_CHONBURI: 'Chon Buri',
  PROV_THA_CHUMPHON: 'Chumphon',
  PROV_THA_KALASIN: 'Kalasin',
  PROV_THA_KAMPHAENGPHET: 'Kamphaeng Phet',
  PROV_THA_KANCHANABURI: 'Kanchanaburi',
  PROV_THA_KHONKAEN: 'Khon Kaen',
  PROV_THA_KRABI: 'Krabi',
  PROV_THA_LAMPANG: 'Lampang',
  PROV_THA_LAMPHUN: 'Lamphun',
  PROV_THA_LOEI: 'Loei',
  PROV_THA_LOPBURI: 'Lop Buri',
  PROV_THA_MAEHONGSON: 'Mae Hong Son',
  PROV_THA_MAHASARAKHAM: 'Maha Sarakham',
  PROV_THA_MUKDAHAN: 'Mukdahan',
  PROV_THA_NAKHONNAYOK: 'Nakhon Nayok',
  PROV_THA_NAKHONPATHOM: 'Nakhon Pathom',
  PROV_THA_NAKHONPHANOM: 'Nakhon Phanom',
  PROV_THA_NAKHONRATCHASIMA: 'Nakhon Ratchasima',
  PROV_THA_NAKHONSAWAN: 'Nakhon Sawan',
  PROV_THA_NAKHONSITHAMMARAT: 'Nakhon Si Thammarat',
  PROV_THA_NAN: 'Nan',
  PROV_THA_NARATHIWAT: 'Narathiwat',
  PROV_THA_NONGBUALAMPHU: 'Nong Bua Lam Phu',
  PROV_THA_NONGKHAI: 'Nong Khai',
  PROV_THA_NONTHABURI: 'Nonthaburi',
  PROV_THA_PATHUMTHANI: 'Pathum Thani',
  PROV_THA_PATTANI: 'Pattani',
  PROV_THA_PATTAYA: 'Pattaya',
  PROV_THA_PHANGNGA: 'Phangnga',
  PROV_THA_PHATTHALUNG: 'Phatthalung',
  PROV_THA_PHAYAO: 'Phayao',
  PROV_THA_PHETCHABUN: 'Phetchabun',
  PROV_THA_PHETCHABURI: 'Phetchaburi',
  PROV_THA_PHICHIT: 'Phichit',
  PROV_THA_PHITSANULOK: 'Phitsanulok',
  PROV_THA_PHRANAKHONSIAYUTTHAYA: 'Phra Nakhon Si Ayutthaya',
  PROV_THA_PHRAE: 'Phrae',
  PROV_THA_PHUKET: 'Phuket',
  PROV_THA_PRACHINBURI: 'Prachin Buri',
  PROV_THA_PRACHUAPKHIRIKHAN: 'Prachuap Khiri Khan',
  PROV_THA_RANONG: 'Ranong',
  PROV_THA_RATCHABURI: 'Ratchaburi',
  PROV_THA_RAYONG: 'Rayong',
  PROV_THA_ROIET: 'Roi Et',
  PROV_THA_SAKAEO: 'Sa Kaeo',
  PROV_THA_SAKONNAKHON: 'Sakon Nakhon',
  PROV_THA_SAMUTPRAKAN: 'Samut Prakan',
  PROV_THA_SAMUTSAKHON: 'Samut Sakhon',
  PROV_THA_SAMUTSONGKHRAM: 'Samut Songkhram',
  PROV_THA_SARABURI: 'Saraburi',
  PROV_THA_SATUN: 'Satun',
  PROV_THA_SISAKET: 'Si Sa Ket',
  PROV_THA_SINGBURI: 'Sing Buri',
  PROV_THA_SONGKHLA: 'Songkhla',
  PROV_THA_SUKHOTHAI: 'Sukhothai',
  PROV_THA_SUPHANBURI: 'Suphan Buri',
  PROV_THA_SURATTHANI: 'Surat Thani',
  PROV_THA_SURIN: 'Surin',
  PROV_THA_TAK: 'Tak',
  PROV_THA_TRANG: 'Trang',
  PROV_THA_TRAT: 'Trat',
  PROV_THA_UBONRATCHATHANI: 'Ubon Ratchathani',
  PROV_THA_UDONTHANI: 'Udon Thani',
  PROV_THA_UTHAITHANI: 'Uthai Thani',
  PROV_THA_UTTARADIT: 'Uttaradit',
  PROV_THA_YALA: 'Yala',
  PROV_THA_YASOTHON: 'Yasothon',
  PROV_BHS_ACKLINS: 'Acklins',
  PROV_BHS_ACKLINSANDCROOKEDISLANDS: 'Acklins and Crooked Islands',
  PROV_BHS_BERRYISLANDS: 'Berry Islands',
  PROV_BHS_BIMINI: 'Bimini',
  PROV_BHS_BLACKPOINT: 'Black Point',
  PROV_BHS_CATISLAND: 'Cat Island',
  PROV_BHS_CENTRALABACO: 'Central Abaco',
  PROV_BHS_CENTRALANDROS: 'Central Andros',
  PROV_BHS_CENTRALELEUTHERA: 'Central Eleuthera',
  PROV_BHS_CROOKEDISLAND: 'Crooked Island',
  PROV_BHS_EASTGRANDBAHAMA: 'East Grand Bahama',
  PROV_BHS_EXUMA: 'Exuma',
  PROV_BHS_FREEPORT: 'Freeport',
  PROV_BHS_FRESHCREEK: 'Fresh Creek',
  PROV_BHS_GOVERNORSHARBOUR: "Governor's Harbour",
  PROV_BHS_GRANDCAY: 'Grand Cay',
  PROV_BHS_GREENTURTLECAY: 'Green Turtle Cay',
  PROV_BHS_HARBOURISLAND: 'Harbour Island',
  PROV_BHS_HIGHROCK: 'High Rock',
  PROV_BHS_HOPETOWN: 'Hope Town',
  PROV_BHS_INAGUA: 'Inagua',
  PROV_BHS_KEMPSBAY: 'Kemps Bay',
  PROV_BHS_LONGISLAND: 'Long Island',
  PROV_BHS_MANGROVECAY: 'Mangrove Cay',
  PROV_BHS_MARSHHARBOUR: 'Marsh Harbour',
  PROV_BHS_MAYAGUANADISTRICT: 'Mayaguana District',
  PROV_BHS_NEWPROVIDENCE: 'New Providence',
  PROV_BHS_NICHOLLSTOWNANDBERRYISLANDS: 'Nichollstown and Berry Islands',
  PROV_BHS_NORTHABACO: 'North Abaco',
  PROV_BHS_NORTHANDROS: 'North Andros',
  PROV_BHS_NORTHELEUTHERA: 'North Eleuthera',
  PROV_BHS_RAGGEDISLAND: 'Ragged Island',
  PROV_BHS_ROCKSOUND: 'Rock Sound',
  PROV_BHS_RUMCAYDISTRICT: 'Rum Cay District',
  PROV_BHS_SANSALVADORANDRUMCAY: 'San Salvador and Rum Cay',
  PROV_BHS_SANSALVADORISLAND: 'San Salvador Island',
  PROV_BHS_SANDYPOINT: 'Sandy Point',
  PROV_BHS_SOUTHABACO: 'South Abaco',
  PROV_BHS_SOUTHANDROS: 'South Andros',
  PROV_BHS_SOUTHELEUTHERA: 'South Eleuthera',
  PROV_BHS_SPANISHWELLS: 'Spanish Wells',
  PROV_BHS_WESTGRANDBAHAMA: 'West Grand Bahama',
  PROV_TGO_CENTRALEREGION: 'Centrale Region',
  PROV_TGO_KARAREGION: 'Kara Region',
  PROV_TGO_MARITIME: 'Maritime',
  PROV_TGO_PLATEAUXREGION: 'Plateaux Region',
  PROV_TGO_SAVANESREGION: 'Savanes Region',
  PROV_TKL_NOPROVINCE: 'No Province',
  PROV_TON_HAAPAI: 'Haʻapai',
  PROV_TON_EUA: 'ʻEua',
  PROV_TON_NIUAS: 'Niuas',
  PROV_TON_TONGATAPU: 'Tongatapu',
  PROV_TON_VAVAU: 'Vavaʻu',
  PROV_TTO_ARIMA: 'Arima',
  PROV_TTO_CHAGUANAS: 'Chaguanas',
  PROV_TTO_COUVATABAQUITETALPAROREGIONALCORPORATION: 'Couva-Tabaquite-Talparo Regional Corporation',
  PROV_TTO_DIEGOMARTINREGIONALCORPORATION: 'Diego Martin Regional Corporation',
  PROV_TTO_EASTERNTOBAGO: 'Eastern Tobago',
  PROV_TTO_PENALDEBEREGIONALCORPORATION: 'Penal-Debe Regional Corporation',
  PROV_TTO_POINTFORTIN: 'Point Fortin',
  PROV_TTO_PORTOFSPAIN: 'Port of Spain',
  PROV_TTO_PRINCESTOWNREGIONALCORPORATION: 'Princes Town Regional Corporation',
  PROV_TTO_RIOCLAROMAYAROREGIONALCORPORATION: 'Rio Claro-Mayaro Regional Corporation',
  PROV_TTO_SANFERNANDO: 'San Fernando',
  PROV_TTO_SANJUANLAVENTILLEREGIONALCORPORATION: 'San Juan-Laventille Regional Corporation',
  PROV_TTO_SANGREGRANDEREGIONALCORPORATION: 'Sangre Grande Regional Corporation',
  PROV_TTO_SIPARIAREGIONALCORPORATION: 'Siparia Regional Corporation',
  PROV_TTO_TUNAPUNAPIARCOREGIONALCORPORATION: 'Tunapuna-Piarco Regional Corporation',
  PROV_TTO_WESTERNTOBAGO: 'Western Tobago',
  PROV_TUN_ARIANAGOVERNORATE: 'Ariana Governorate',
  PROV_TUN_BENAROUSGOVERNORATE: 'Ben Arous Governorate',
  PROV_TUN_BIZERTEGOVERNORATE: 'Bizerte Governorate',
  PROV_TUN_GABSGOVERNORATE: 'Gabès Governorate',
  PROV_TUN_GAFSAGOVERNORATE: 'Gafsa Governorate',
  PROV_TUN_JENDOUBAGOVERNORATE: 'Jendouba Governorate',
  PROV_TUN_KAIROUANGOVERNORATE: 'Kairouan Governorate',
  PROV_TUN_KASSERINEGOVERNORATE: 'Kasserine Governorate',
  PROV_TUN_KASSRINE: 'Kassrine',
  PROV_TUN_KEBILIGOVERNORATE: 'Kebili Governorate',
  PROV_TUN_KEFGOVERNORATE: 'Kef Governorate',
  PROV_TUN_MAHDIAGOVERNORATE: 'Mahdia Governorate',
  PROV_TUN_MANOUBAGOVERNORATE: 'Manouba Governorate',
  PROV_TUN_MEDENINEGOVERNORATE: 'Medenine Governorate',
  PROV_TUN_MONASTIRGOVERNORATE: 'Monastir Governorate',
  PROV_TUN_SFAXGOVERNORATE: 'Sfax Governorate',
  PROV_TUN_SIDIBOUZIDGOVERNORATE: 'Sidi Bouzid Governorate',
  PROV_TUN_SILIANAGOVERNORATE: 'Siliana Governorate',
  PROV_TUN_SOUSSEGOVERNORATE: 'Sousse Governorate',
  PROV_TUN_TATAOUINEGOVERNORATE: 'Tataouine Governorate',
  PROV_TUN_TOZEURGOVERNORATE: 'Tozeur Governorate',
  PROV_TUN_TUNISGOVERNORATE: 'Tunis Governorate',
  PROV_TUN_ZAGHOUANGOVERNORATE: 'Zaghouan Governorate',
  PROV_TUR_ADANA: 'Adana',
  PROV_TUR_ADIYAMAN: 'Adıyaman',
  PROV_TUR_AFYONKARAHISAR: 'Afyonkarahisar',
  PROV_TUR_ARI: 'Ağrı',
  PROV_TUR_AKSARAY: 'Aksaray',
  PROV_TUR_AMASYA: 'Amasya',
  PROV_TUR_ANKARA: 'Ankara',
  PROV_TUR_ANTALYA: 'Antalya',
  PROV_TUR_ARDAHAN: 'Ardahan',
  PROV_TUR_ARTVIN: 'Artvin',
  PROV_TUR_AYDIN: 'Aydın',
  PROV_TUR_BALIKESIR: 'Balıkesir',
  PROV_TUR_BARTIN: 'Bartın',
  PROV_TUR_BATMAN: 'Batman',
  PROV_TUR_BAYBURT: 'Bayburt',
  PROV_TUR_BILECIK: 'Bilecik',
  PROV_TUR_BINGL: 'Bingöl',
  PROV_TUR_BITLIS: 'Bitlis',
  PROV_TUR_BOLU: 'Bolu',
  PROV_TUR_BURDUR: 'Burdur',
  PROV_TUR_BURSA: 'Bursa',
  PROV_TUR_ANAKKALE: 'Çanakkale',
  PROV_TUR_ANKIRI: 'Çankırı',
  PROV_TUR_ORUM: 'Çorum',
  PROV_TUR_DENIZLI: 'Denizli',
  PROV_TUR_DIYARBAKIR: 'Diyarbakır',
  PROV_TUR_DZCE: 'Düzce',
  PROV_TUR_EDIRNE: 'Edirne',
  PROV_TUR_ELAZI: 'Elazığ',
  PROV_TUR_ERZINCAN: 'Erzincan',
  PROV_TUR_ERZURUM: 'Erzurum',
  PROV_TUR_ESKIEHIR: 'Eskişehir',
  PROV_TUR_GAZIANTEP: 'Gaziantep',
  PROV_TUR_GIRESUN: 'Giresun',
  PROV_TUR_GMHANE: 'Gümüşhane',
  PROV_TUR_HAKKRI: 'Hakkâri',
  PROV_TUR_HATAY: 'Hatay',
  PROV_TUR_IDIR: 'Iğdır',
  PROV_TUR_ISPARTA: 'Isparta',
  PROV_TUR_ISTANBUL: 'Istanbul',
  PROV_TUR_ZMIR: 'İzmir',
  PROV_TUR_KAHRAMANMARA: 'Kahramanmaraş',
  PROV_TUR_KARABK: 'Karabük',
  PROV_TUR_KARAMAN: 'Karaman',
  PROV_TUR_KARS: 'Kars',
  PROV_TUR_KASTAMONU: 'Kastamonu',
  PROV_TUR_KAYSERI: 'Kayseri',
  PROV_TUR_KILIS: 'Kilis',
  PROV_TUR_KIRIKKALE: 'Kırıkkale',
  PROV_TUR_KIRKLARELI: 'Kırklareli',
  PROV_TUR_KIREHIR: 'Kırşehir',
  PROV_TUR_KOCAELI: 'Kocaeli',
  PROV_TUR_KONYA: 'Konya',
  PROV_TUR_KTAHYA: 'Kütahya',
  PROV_TUR_MALATYA: 'Malatya',
  PROV_TUR_MANISA: 'Manisa',
  PROV_TUR_MARDIN: 'Mardin',
  PROV_TUR_MERSIN: 'Mersin',
  PROV_TUR_MULA: 'Muğla',
  PROV_TUR_MU: 'Muş',
  PROV_TUR_NEVEHIR: 'Nevşehir',
  PROV_TUR_NIDE: 'Niğde',
  PROV_TUR_ORDU: 'Ordu',
  PROV_TUR_OSMANIYE: 'Osmaniye',
  PROV_TUR_RIZE: 'Rize',
  PROV_TUR_SAKARYA: 'Sakarya',
  PROV_TUR_SAMSUN: 'Samsun',
  PROV_TUR_ANLIURFA: 'Şanlıurfa',
  PROV_TUR_SIIRT: 'Siirt',
  PROV_TUR_SINOP: 'Sinop',
  PROV_TUR_SIVAS: 'Sivas',
  PROV_TUR_IRNAK: 'Şırnak',
  PROV_TUR_TEKIRDA: 'Tekirdağ',
  PROV_TUR_TOKAT: 'Tokat',
  PROV_TUR_TRABZON: 'Trabzon',
  PROV_TUR_TUNCELI: 'Tunceli',
  PROV_TUR_UAK: 'Uşak',
  PROV_TUR_VAN: 'Van',
  PROV_TUR_YALOVA: 'Yalova',
  PROV_TUR_YOZGAT: 'Yozgat',
  PROV_TUR_ZONGULDAK: 'Zonguldak',
  PROV_TKM_AHALREGION: 'Ahal Region',
  PROV_TKM_ASHGABAT: 'Ashgabat',
  PROV_TKM_BALKANREGION: 'Balkan Region',
  PROV_TKM_DAOGUZREGION: 'Daşoguz Region',
  PROV_TKM_LEBAPREGION: 'Lebap Region',
  PROV_TKM_MARYREGION: 'Mary Region',
  PROV_TCA_NOPROVINCE: 'No Province',
  PROV_TUV_FUNAFUTI: 'Funafuti',
  PROV_TUV_NANUMANGA: 'Nanumanga',
  PROV_TUV_NANUMEA: 'Nanumea',
  PROV_TUV_NIUTAOISLANDCOUNCIL: 'Niutao Island Council',
  PROV_TUV_NUI: 'Nui',
  PROV_TUV_NUKUFETAU: 'Nukufetau',
  PROV_TUV_NUKULAELAE: 'Nukulaelae',
  PROV_TUV_VAITUPU: 'Vaitupu',
  PROV_UGA_ABIMDISTRICT: 'Abim District',
  PROV_UGA_ADJUMANIDISTRICT: 'Adjumani District',
  PROV_UGA_AGAGODISTRICT: 'Agago District',
  PROV_UGA_ALEBTONGDISTRICT: 'Alebtong District',
  PROV_UGA_AMOLATARDISTRICT: 'Amolatar District',
  PROV_UGA_AMUDATDISTRICT: 'Amudat District',
  PROV_UGA_AMURIADISTRICT: 'Amuria District',
  PROV_UGA_AMURUDISTRICT: 'Amuru District',
  PROV_UGA_APACDISTRICT: 'Apac District',
  PROV_UGA_ARUADISTRICT: 'Arua District',
  PROV_UGA_BUDAKADISTRICT: 'Budaka District',
  PROV_UGA_BUDUDADISTRICT: 'Bududa District',
  PROV_UGA_BUGIRIDISTRICT: 'Bugiri District',
  PROV_UGA_BUHWEJUDISTRICT: 'Buhweju District',
  PROV_UGA_BUIKWEDISTRICT: 'Buikwe District',
  PROV_UGA_BUKEDEADISTRICT: 'Bukedea District',
  PROV_UGA_BUKOMANSIMBIDISTRICT: 'Bukomansimbi District',
  PROV_UGA_BUKWODISTRICT: 'Bukwo District',
  PROV_UGA_BULAMBULIDISTRICT: 'Bulambuli District',
  PROV_UGA_BULIISADISTRICT: 'Buliisa District',
  PROV_UGA_BUNDIBUGYODISTRICT: 'Bundibugyo District',
  PROV_UGA_BUNYANGABUDISTRICT: 'Bunyangabu District',
  PROV_UGA_BUSHENYIDISTRICT: 'Bushenyi District',
  PROV_UGA_BUSIADISTRICT: 'Busia District',
  PROV_UGA_BUTALEJADISTRICT: 'Butaleja District',
  PROV_UGA_BUTAMBALADISTRICT: 'Butambala District',
  PROV_UGA_BUTEBODISTRICT: 'Butebo District',
  PROV_UGA_BUVUMADISTRICT: 'Buvuma District',
  PROV_UGA_BUYENDEDISTRICT: 'Buyende District',
  PROV_UGA_CENTRALREGION: 'Central Region',
  PROV_UGA_DOKOLODISTRICT: 'Dokolo District',
  PROV_UGA_EASTERNREGION: 'Eastern Region',
  PROV_UGA_GOMBADISTRICT: 'Gomba District',
  PROV_UGA_GULUDISTRICT: 'Gulu District',
  PROV_UGA_IBANDADISTRICT: 'Ibanda District',
  PROV_UGA_IGANGADISTRICT: 'Iganga District',
  PROV_UGA_ISINGIRODISTRICT: 'Isingiro District',
  PROV_UGA_JINJADISTRICT: 'Jinja District',
  PROV_UGA_KAABONGDISTRICT: 'Kaabong District',
  PROV_UGA_KABALEDISTRICT: 'Kabale District',
  PROV_UGA_KABAROLEDISTRICT: 'Kabarole District',
  PROV_UGA_KABERAMAIDODISTRICT: 'Kaberamaido District',
  PROV_UGA_KAGADIDISTRICT: 'Kagadi District',
  PROV_UGA_KAKUMIRODISTRICT: 'Kakumiro District',
  PROV_UGA_KALANGALADISTRICT: 'Kalangala District',
  PROV_UGA_KALIRODISTRICT: 'Kaliro District',
  PROV_UGA_KALUNGUDISTRICT: 'Kalungu District',
  PROV_UGA_KAMPALADISTRICT: 'Kampala District',
  PROV_UGA_KAMULIDISTRICT: 'Kamuli District',
  PROV_UGA_KAMWENGEDISTRICT: 'Kamwenge District',
  PROV_UGA_KANUNGUDISTRICT: 'Kanungu District',
  PROV_UGA_KAPCHORWADISTRICT: 'Kapchorwa District',
  PROV_UGA_KASESEDISTRICT: 'Kasese District',
  PROV_UGA_KATAKWIDISTRICT: 'Katakwi District',
  PROV_UGA_KAYUNGADISTRICT: 'Kayunga District',
  PROV_UGA_KIBAALEDISTRICT: 'Kibaale District',
  PROV_UGA_KIBOGADISTRICT: 'Kiboga District',
  PROV_UGA_KIBUKUDISTRICT: 'Kibuku District',
  PROV_UGA_KIRUHURADISTRICT: 'Kiruhura District',
  PROV_UGA_KIRYANDONGODISTRICT: 'Kiryandongo District',
  PROV_UGA_KISORODISTRICT: 'Kisoro District',
  PROV_UGA_KITGUMDISTRICT: 'Kitgum District',
  PROV_UGA_KOBOKODISTRICT: 'Koboko District',
  PROV_UGA_KOLEDISTRICT: 'Kole District',
  PROV_UGA_KOTIDODISTRICT: 'Kotido District',
  PROV_UGA_KUMIDISTRICT: 'Kumi District',
  PROV_UGA_KWEENDISTRICT: 'Kween District',
  PROV_UGA_KYANKWANZIDISTRICT: 'Kyankwanzi District',
  PROV_UGA_KYEGEGWADISTRICT: 'Kyegegwa District',
  PROV_UGA_KYENJOJODISTRICT: 'Kyenjojo District',
  PROV_UGA_KYOTERADISTRICT: 'Kyotera District',
  PROV_UGA_LAMWODISTRICT: 'Lamwo District',
  PROV_UGA_LIRADISTRICT: 'Lira District',
  PROV_UGA_LUUKADISTRICT: 'Luuka District',
  PROV_UGA_LUWERODISTRICT: 'Luwero District',
  PROV_UGA_LWENGODISTRICT: 'Lwengo District',
  PROV_UGA_LYANTONDEDISTRICT: 'Lyantonde District',
  PROV_UGA_MANAFWADISTRICT: 'Manafwa District',
  PROV_UGA_MARACHADISTRICT: 'Maracha District',
  PROV_UGA_MASAKADISTRICT: 'Masaka District',
  PROV_UGA_MASINDIDISTRICT: 'Masindi District',
  PROV_UGA_MAYUGEDISTRICT: 'Mayuge District',
  PROV_UGA_MBALEDISTRICT: 'Mbale District',
  PROV_UGA_MBARARADISTRICT: 'Mbarara District',
  PROV_UGA_MITOOMADISTRICT: 'Mitooma District',
  PROV_UGA_MITYANADISTRICT: 'Mityana District',
  PROV_UGA_MOROTODISTRICT: 'Moroto District',
  PROV_UGA_MOYODISTRICT: 'Moyo District',
  PROV_UGA_MPIGIDISTRICT: 'Mpigi District',
  PROV_UGA_MUBENDEDISTRICT: 'Mubende District',
  PROV_UGA_MUKONODISTRICT: 'Mukono District',
  PROV_UGA_NAKAPIRIPIRITDISTRICT: 'Nakapiripirit District',
  PROV_UGA_NAKASEKEDISTRICT: 'Nakaseke District',
  PROV_UGA_NAKASONGOLADISTRICT: 'Nakasongola District',
  PROV_UGA_NAMAYINGODISTRICT: 'Namayingo District',
  PROV_UGA_NAMISINDWADISTRICT: 'Namisindwa District',
  PROV_UGA_NAMUTUMBADISTRICT: 'Namutumba District',
  PROV_UGA_NAPAKDISTRICT: 'Napak District',
  PROV_UGA_NEBBIDISTRICT: 'Nebbi District',
  PROV_UGA_NGORADISTRICT: 'Ngora District',
  PROV_UGA_NORTHERNREGION: 'Northern Region',
  PROV_UGA_NTOROKODISTRICT: 'Ntoroko District',
  PROV_UGA_NTUNGAMODISTRICT: 'Ntungamo District',
  PROV_UGA_NWOYADISTRICT: 'Nwoya District',
  PROV_UGA_OMORODISTRICT: 'Omoro District',
  PROV_UGA_OTUKEDISTRICT: 'Otuke District',
  PROV_UGA_OYAMDISTRICT: 'Oyam District',
  PROV_UGA_PADERDISTRICT: 'Pader District',
  PROV_UGA_PAKWACHDISTRICT: 'Pakwach District',
  PROV_UGA_PALLISADISTRICT: 'Pallisa District',
  PROV_UGA_RAKAIDISTRICT: 'Rakai District',
  PROV_UGA_RUBANDADISTRICT: 'Rubanda District',
  PROV_UGA_RUBIRIZIDISTRICT: 'Rubirizi District',
  PROV_UGA_RUKIGADISTRICT: 'Rukiga District',
  PROV_UGA_RUKUNGIRIDISTRICT: 'Rukungiri District',
  PROV_UGA_SEMBABULEDISTRICT: 'Sembabule District',
  PROV_UGA_SEREREDISTRICT: 'Serere District',
  PROV_UGA_SHEEMADISTRICT: 'Sheema District',
  PROV_UGA_SIRONKODISTRICT: 'Sironko District',
  PROV_UGA_SOROTIDISTRICT: 'Soroti District',
  PROV_UGA_TORORODISTRICT: 'Tororo District',
  PROV_UGA_WAKISODISTRICT: 'Wakiso District',
  PROV_UGA_WESTERNREGION: 'Western Region',
  PROV_UGA_YUMBEDISTRICT: 'Yumbe District',
  PROV_UGA_ZOMBODISTRICT: 'Zombo District',
  PROV_UKR_AUTONOMOUSREPUBLICOFCRIMEA: 'Autonomous Republic of Crimea',
  PROV_UKR_CHERKASKAOBLAST: 'Cherkaska oblast',
  PROV_UKR_CHERNIHIVSKAOBLAST: 'Chernihivska oblast',
  PROV_UKR_CHERNIVETSKAOBLAST: 'Chernivetska oblast',
  PROV_UKR_DNIPROPETROVSKAOBLAST: 'Dnipropetrovska oblast',
  PROV_UKR_DONETSKAOBLAST: 'Donetska oblast',
  PROV_UKR_IVANOFRANKIVSKAOBLAST: 'Ivano-Frankivska oblast',
  PROV_UKR_KHARKIVSKAOBLAST: 'Kharkivska oblast',
  PROV_UKR_KHERSONSKAOBLAST: 'Khersonska oblast',
  PROV_UKR_KHMELNYTSKAOBLAST: 'Khmelnytska oblast',
  PROV_UKR_KIROVOHRADSKAOBLAST: 'Kirovohradska oblast',
  PROV_UKR_KYIV: 'Kyiv',
  PROV_UKR_KYIVSKAOBLAST: 'Kyivska oblast',
  PROV_UKR_LUHANSKAOBLAST: 'Luhanska oblast',
  PROV_UKR_LVIVSKAOBLAST: 'Lvivska oblast',
  PROV_UKR_MYKOLAIVSKAOBLAST: 'Mykolaivska oblast',
  PROV_UKR_ODESKAOBLAST: 'Odeska oblast',
  PROV_UKR_POLTAVSKAOBLAST: 'Poltavska oblast',
  PROV_UKR_RIVNENSKAOBLAST: 'Rivnenska oblast',
  PROV_UKR_SUMSKAOBLAST: 'Sumska oblast',
  PROV_UKR_TERNOPILSKAOBLAST: 'Ternopilska oblast',
  PROV_UKR_VINNYTSKAOBLAST: 'Vinnytska oblast',
  PROV_UKR_VOLYNSKAOBLAST: 'Volynska oblast',
  PROV_UKR_ZAKARPATSKAOBLAST: 'Zakarpatska Oblast',
  PROV_UKR_ZAPORIZKAOBLAST: 'Zaporizka oblast',
  PROV_UKR_ZHYTOMYRSKAOBLAST: 'Zhytomyrska oblast',
  PROV_GBR_ABERDEEN: 'Aberdeen',
  PROV_GBR_ABERDEENSHIRE: 'Aberdeenshire',
  PROV_GBR_ANGUS: 'Angus',
  PROV_GBR_ANTRIM: 'Antrim',
  PROV_GBR_ANTRIMANDNEWTOWNABBEY: 'Antrim and Newtownabbey',
  PROV_GBR_ARDS: 'Ards',
  PROV_GBR_ARDSANDNORTHDOWN: 'Ards and North Down',
  PROV_GBR_ARGYLLANDBUTE: 'Argyll and Bute',
  PROV_GBR_ARMAGHCITYANDDISTRICTCOUNCIL: 'Armagh City and District Council',
  PROV_GBR_ARMAGHBANBRIDGEANDCRAIGAVON: 'Armagh, Banbridge and Craigavon',
  PROV_GBR_ASCENSIONISLAND: 'Ascension Island',
  PROV_GBR_BALLYMENABOROUGH: 'Ballymena Borough',
  PROV_GBR_BALLYMONEY: 'Ballymoney',
  PROV_GBR_BANBRIDGE: 'Banbridge',
  PROV_GBR_BARNSLEY: 'Barnsley',
  PROV_GBR_BATHANDNORTHEASTSOMERSET: 'Bath and North East Somerset',
  PROV_GBR_BEDFORD: 'Bedford',
  PROV_GBR_BELFASTDISTRICT: 'Belfast district',
  PROV_GBR_BIRMINGHAM: 'Birmingham',
  PROV_GBR_BLACKBURNWITHDARWEN: 'Blackburn with Darwen',
  PROV_GBR_BLACKPOOL: 'Blackpool',
  PROV_GBR_BLAENAUGWENTCOUNTYBOROUGH: 'Blaenau Gwent County Borough',
  PROV_GBR_BOLTON: 'Bolton',
  PROV_GBR_BOURNEMOUTH: 'Bournemouth',
  PROV_GBR_BRACKNELLFOREST: 'Bracknell Forest',
  PROV_GBR_BRADFORD: 'Bradford',
  PROV_GBR_BRIDGENDCOUNTYBOROUGH: 'Bridgend County Borough',
  PROV_GBR_BRIGHTONANDHOVE: 'Brighton and Hove',
  PROV_GBR_BUCKINGHAMSHIRE: 'Buckinghamshire',
  PROV_GBR_BURY: 'Bury',
  PROV_GBR_CAERPHILLYCOUNTYBOROUGH: 'Caerphilly County Borough',
  PROV_GBR_CALDERDALE: 'Calderdale',
  PROV_GBR_CAMBRIDGESHIRE: 'Cambridgeshire',
  PROV_GBR_CARMARTHENSHIRE: 'Carmarthenshire',
  PROV_GBR_CARRICKFERGUSBOROUGHCOUNCIL: 'Carrickfergus Borough Council',
  PROV_GBR_CASTLEREAGH: 'Castlereagh',
  PROV_GBR_CAUSEWAYCOASTANDGLENS: 'Causeway Coast and Glens',
  PROV_GBR_CENTRALBEDFORDSHIRE: 'Central Bedfordshire',
  PROV_GBR_CEREDIGION: 'Ceredigion',
  PROV_GBR_CHESHIREEAST: 'Cheshire East',
  PROV_GBR_CHESHIREWESTANDCHESTER: 'Cheshire West and Chester',
  PROV_GBR_CITYANDCOUNTYOFCARDIFF: 'City and County of Cardiff',
  PROV_GBR_CITYANDCOUNTYOFSWANSEA: 'City and County of Swansea',
  PROV_GBR_CITYOFBRISTOL: 'City of Bristol',
  PROV_GBR_CITYOFDERBY: 'City of Derby',
  PROV_GBR_CITYOFKINGSTONUPONHULL: 'City of Kingston upon Hull',
  PROV_GBR_CITYOFLEICESTER: 'City of Leicester',
  PROV_GBR_CITYOFLONDON: 'City of London',
  PROV_GBR_CITYOFNOTTINGHAM: 'City of Nottingham',
  PROV_GBR_CITYOFPETERBOROUGH: 'City of Peterborough',
  PROV_GBR_CITYOFPLYMOUTH: 'City of Plymouth',
  PROV_GBR_CITYOFPORTSMOUTH: 'City of Portsmouth',
  PROV_GBR_CITYOFSOUTHAMPTON: 'City of Southampton',
  PROV_GBR_CITYOFSTOKEONTRENT: 'City of Stoke-on-Trent',
  PROV_GBR_CITYOFSUNDERLAND: 'City of Sunderland',
  PROV_GBR_CITYOFWESTMINSTER: 'City of Westminster',
  PROV_GBR_CITYOFWOLVERHAMPTON: 'City of Wolverhampton',
  PROV_GBR_CITYOFYORK: 'City of York',
  PROV_GBR_CLACKMANNANSHIRE: 'Clackmannanshire',
  PROV_GBR_COLERAINEBOROUGHCOUNCIL: 'Coleraine Borough Council',
  PROV_GBR_CONWYCOUNTYBOROUGH: 'Conwy County Borough',
  PROV_GBR_COOKSTOWNDISTRICTCOUNCIL: 'Cookstown District Council',
  PROV_GBR_CORNWALL: 'Cornwall',
  PROV_GBR_COUNTYDURHAM: 'County Durham',
  PROV_GBR_COVENTRY: 'Coventry',
  PROV_GBR_CRAIGAVONBOROUGHCOUNCIL: 'Craigavon Borough Council',
  PROV_GBR_CUMBRIA: 'Cumbria',
  PROV_GBR_DARLINGTON: 'Darlington',
  PROV_GBR_DENBIGHSHIRE: 'Denbighshire',
  PROV_GBR_DERBYSHIRE: 'Derbyshire',
  PROV_GBR_DERRYCITYANDSTRABANE: 'Derry City and Strabane',
  PROV_GBR_DERRYCITYCOUNCIL: 'Derry City Council',
  PROV_GBR_DEVON: 'Devon',
  PROV_GBR_DONCASTER: 'Doncaster',
  PROV_GBR_DORSET: 'Dorset',
  PROV_GBR_DOWNDISTRICTCOUNCIL: 'Down District Council',
  PROV_GBR_DUDLEY: 'Dudley',
  PROV_GBR_DUMFRIESANDGALLOWAY: 'Dumfries and Galloway',
  PROV_GBR_DUNDEE: 'Dundee',
  PROV_GBR_DUNGANNONANDSOUTHTYRONEBOROUGHCOUNCIL: 'Dungannon and South Tyrone Borough Council',
  PROV_GBR_EASTAYRSHIRE: 'East Ayrshire',
  PROV_GBR_EASTDUNBARTONSHIRE: 'East Dunbartonshire',
  PROV_GBR_EASTLOTHIAN: 'East Lothian',
  PROV_GBR_EASTRENFREWSHIRE: 'East Renfrewshire',
  PROV_GBR_EASTRIDINGOFYORKSHIRE: 'East Riding of Yorkshire',
  PROV_GBR_EASTSUSSEX: 'East Sussex',
  PROV_GBR_EDINBURGH: 'Edinburgh',
  PROV_GBR_ENGLAND: 'England',
  PROV_GBR_ESSEX: 'Essex',
  PROV_GBR_FALKIRK: 'Falkirk',
  PROV_GBR_FERMANAGHANDOMAGH: 'Fermanagh and Omagh',
  PROV_GBR_FERMANAGHDISTRICTCOUNCIL: 'Fermanagh District Council',
  PROV_GBR_FIFE: 'Fife',
  PROV_GBR_FLINTSHIRE: 'Flintshire',
  PROV_GBR_GATESHEAD: 'Gateshead',
  PROV_GBR_GLASGOW: 'Glasgow',
  PROV_GBR_GLOUCESTERSHIRE: 'Gloucestershire',
  PROV_GBR_GWYNEDD: 'Gwynedd',
  PROV_GBR_HALTON: 'Halton',
  PROV_GBR_HAMPSHIRE: 'Hampshire',
  PROV_GBR_HARTLEPOOL: 'Hartlepool',
  PROV_GBR_HEREFORDSHIRE: 'Herefordshire',
  PROV_GBR_HERTFORDSHIRE: 'Hertfordshire',
  PROV_GBR_HIGHLAND: 'Highland',
  PROV_GBR_INVERCLYDE: 'Inverclyde',
  PROV_GBR_ISLEOFWIGHT: 'Isle of Wight',
  PROV_GBR_ISLESOFSCILLY: 'Isles of Scilly',
  PROV_GBR_KENT: 'Kent',
  PROV_GBR_KIRKLEES: 'Kirklees',
  PROV_GBR_KNOWSLEY: 'Knowsley',
  PROV_GBR_LANCASHIRE: 'Lancashire',
  PROV_GBR_LARNEBOROUGHCOUNCIL: 'Larne Borough Council',
  PROV_GBR_LEEDS: 'Leeds',
  PROV_GBR_LEICESTERSHIRE: 'Leicestershire',
  PROV_GBR_LIMAVADYBOROUGHCOUNCIL: 'Limavady Borough Council',
  PROV_GBR_LINCOLNSHIRE: 'Lincolnshire',
  PROV_GBR_LISBURNANDCASTLEREAGH: 'Lisburn and Castlereagh',
  PROV_GBR_LISBURNCITYCOUNCIL: 'Lisburn City Council',
  PROV_GBR_LIVERPOOL: 'Liverpool',
  PROV_GBR_LONDONBOROUGHOFBARKINGANDDAGENHAM: 'London Borough of Barking and Dagenham',
  PROV_GBR_LONDONBOROUGHOFBARNET: 'London Borough of Barnet',
  PROV_GBR_LONDONBOROUGHOFBEXLEY: 'London Borough of Bexley',
  PROV_GBR_LONDONBOROUGHOFBRENT: 'London Borough of Brent',
  PROV_GBR_LONDONBOROUGHOFBROMLEY: 'London Borough of Bromley',
  PROV_GBR_LONDONBOROUGHOFCAMDEN: 'London Borough of Camden',
  PROV_GBR_LONDONBOROUGHOFCROYDON: 'London Borough of Croydon',
  PROV_GBR_LONDONBOROUGHOFEALING: 'London Borough of Ealing',
  PROV_GBR_LONDONBOROUGHOFENFIELD: 'London Borough of Enfield',
  PROV_GBR_LONDONBOROUGHOFHACKNEY: 'London Borough of Hackney',
  PROV_GBR_LONDONBOROUGHOFHAMMERSMITHANDFULHAM: 'London Borough of Hammersmith and Fulham',
  PROV_GBR_LONDONBOROUGHOFHARINGEY: 'London Borough of Haringey',
  PROV_GBR_LONDONBOROUGHOFHARROW: 'London Borough of Harrow',
  PROV_GBR_LONDONBOROUGHOFHAVERING: 'London Borough of Havering',
  PROV_GBR_LONDONBOROUGHOFHILLINGDON: 'London Borough of Hillingdon',
  PROV_GBR_LONDONBOROUGHOFHOUNSLOW: 'London Borough of Hounslow',
  PROV_GBR_LONDONBOROUGHOFISLINGTON: 'London Borough of Islington',
  PROV_GBR_LONDONBOROUGHOFLAMBETH: 'London Borough of Lambeth',
  PROV_GBR_LONDONBOROUGHOFLEWISHAM: 'London Borough of Lewisham',
  PROV_GBR_LONDONBOROUGHOFMERTON: 'London Borough of Merton',
  PROV_GBR_LONDONBOROUGHOFNEWHAM: 'London Borough of Newham',
  PROV_GBR_LONDONBOROUGHOFREDBRIDGE: 'London Borough of Redbridge',
  PROV_GBR_LONDONBOROUGHOFRICHMONDUPONTHAMES: 'London Borough of Richmond upon Thames',
  PROV_GBR_LONDONBOROUGHOFSOUTHWARK: 'London Borough of Southwark',
  PROV_GBR_LONDONBOROUGHOFSUTTON: 'London Borough of Sutton',
  PROV_GBR_LONDONBOROUGHOFTOWERHAMLETS: 'London Borough of Tower Hamlets',
  PROV_GBR_LONDONBOROUGHOFWALTHAMFOREST: 'London Borough of Waltham Forest',
  PROV_GBR_LONDONBOROUGHOFWANDSWORTH: 'London Borough of Wandsworth',
  PROV_GBR_MAGHERAFELTDISTRICTCOUNCIL: 'Magherafelt District Council',
  PROV_GBR_MANCHESTER: 'Manchester',
  PROV_GBR_MEDWAY: 'Medway',
  PROV_GBR_MERTHYRTYDFILCOUNTYBOROUGH: 'Merthyr Tydfil County Borough',
  PROV_GBR_METROPOLITANBOROUGHOFWIGAN: 'Metropolitan Borough of Wigan',
  PROV_GBR_MIDANDEASTANTRIM: 'Mid and East Antrim',
  PROV_GBR_MIDULSTER: 'Mid Ulster',
  PROV_GBR_MIDDLESBROUGH: 'Middlesbrough',
  PROV_GBR_MIDLOTHIAN: 'Midlothian',
  PROV_GBR_MILTONKEYNES: 'Milton Keynes',
  PROV_GBR_MONMOUTHSHIRE: 'Monmouthshire',
  PROV_GBR_MORAY: 'Moray',
  PROV_GBR_MOYLEDISTRICTCOUNCIL: 'Moyle District Council',
  PROV_GBR_NEATHPORTTALBOTCOUNTYBOROUGH: 'Neath Port Talbot County Borough',
  PROV_GBR_NEWCASTLEUPONTYNE: 'Newcastle upon Tyne',
  PROV_GBR_NEWPORT: 'Newport',
  PROV_GBR_NEWRYANDMOURNEDISTRICTCOUNCIL: 'Newry and Mourne District Council',
  PROV_GBR_NEWRYMOURNEANDDOWN: 'Newry, Mourne and Down',
  PROV_GBR_NEWTOWNABBEYBOROUGHCOUNCIL: 'Newtownabbey Borough Council',
  PROV_GBR_NORFOLK: 'Norfolk',
  PROV_GBR_NORTHAYRSHIRE: 'North Ayrshire',
  PROV_GBR_NORTHDOWNBOROUGHCOUNCIL: 'North Down Borough Council',
  PROV_GBR_NORTHEASTLINCOLNSHIRE: 'North East Lincolnshire',
  PROV_GBR_NORTHLANARKSHIRE: 'North Lanarkshire',
  PROV_GBR_NORTHLINCOLNSHIRE: 'North Lincolnshire',
  PROV_GBR_NORTHSOMERSET: 'North Somerset',
  PROV_GBR_NORTHTYNESIDE: 'North Tyneside',
  PROV_GBR_NORTHYORKSHIRE: 'North Yorkshire',
  PROV_GBR_NORTHAMPTONSHIRE: 'Northamptonshire',
  PROV_GBR_NORTHERNIRELAND: 'Northern Ireland',
  PROV_GBR_NORTHUMBERLAND: 'Northumberland',
  PROV_GBR_NOTTINGHAMSHIRE: 'Nottinghamshire',
  PROV_GBR_OLDHAM: 'Oldham',
  PROV_GBR_OMAGHDISTRICTCOUNCIL: 'Omagh District Council',
  PROV_GBR_ORKNEYISLANDS: 'Orkney Islands',
  PROV_GBR_OUTERHEBRIDES: 'Outer Hebrides',
  PROV_GBR_OXFORDSHIRE: 'Oxfordshire',
  PROV_GBR_PEMBROKESHIRE: 'Pembrokeshire',
  PROV_GBR_PERTHANDKINROSS: 'Perth and Kinross',
  PROV_GBR_POOLE: 'Poole',
  PROV_GBR_POWYS: 'Powys',
  PROV_GBR_READING: 'Reading',
  PROV_GBR_REDCARANDCLEVELAND: 'Redcar and Cleveland',
  PROV_GBR_RENFREWSHIRE: 'Renfrewshire',
  PROV_GBR_RHONDDACYNONTAF: 'Rhondda Cynon Taf',
  PROV_GBR_ROCHDALE: 'Rochdale',
  PROV_GBR_ROTHERHAM: 'Rotherham',
  PROV_GBR_ROYALBOROUGHOFGREENWICH: 'Royal Borough of Greenwich',
  PROV_GBR_ROYALBOROUGHOFKENSINGTONANDCHELSEA: 'Royal Borough of Kensington and Chelsea',
  PROV_GBR_ROYALBOROUGHOFKINGSTONUPONTHAMES: 'Royal Borough of Kingston upon Thames',
  PROV_GBR_RUTLAND: 'Rutland',
  PROV_GBR_SAINTHELENA: 'Saint Helena',
  PROV_GBR_SALFORD: 'Salford',
  PROV_GBR_SANDWELL: 'Sandwell',
  PROV_GBR_SCOTLAND: 'Scotland',
  PROV_GBR_SCOTTISHBORDERS: 'Scottish Borders',
  PROV_GBR_SEFTON: 'Sefton',
  PROV_GBR_SHEFFIELD: 'Sheffield',
  PROV_GBR_SHETLANDISLANDS: 'Shetland Islands',
  PROV_GBR_SHROPSHIRE: 'Shropshire',
  PROV_GBR_SLOUGH: 'Slough',
  PROV_GBR_SOLIHULL: 'Solihull',
  PROV_GBR_SOMERSET: 'Somerset',
  PROV_GBR_SOUTHAYRSHIRE: 'South Ayrshire',
  PROV_GBR_SOUTHGLOUCESTERSHIRE: 'South Gloucestershire',
  PROV_GBR_SOUTHLANARKSHIRE: 'South Lanarkshire',
  PROV_GBR_SOUTHTYNESIDE: 'South Tyneside',
  PROV_GBR_SOUTHENDONSEA: 'Southend-on-Sea',
  PROV_GBR_STHELENS: 'St Helens',
  PROV_GBR_STAFFORDSHIRE: 'Staffordshire',
  PROV_GBR_STIRLING: 'Stirling',
  PROV_GBR_STOCKPORT: 'Stockport',
  PROV_GBR_STOCKTONONTEES: 'Stockton-on-Tees',
  PROV_GBR_STRABANEDISTRICTCOUNCIL: 'Strabane District Council',
  PROV_GBR_SUFFOLK: 'Suffolk',
  PROV_GBR_SURREY: 'Surrey',
  PROV_GBR_SWINDON: 'Swindon',
  PROV_GBR_TAMESIDE: 'Tameside',
  PROV_GBR_TELFORDANDWREKIN: 'Telford and Wrekin',
  PROV_GBR_THURROCK: 'Thurrock',
  PROV_GBR_TORBAY: 'Torbay',
  PROV_GBR_TORFAEN: 'Torfaen',
  PROV_GBR_TRAFFORD: 'Trafford',
  PROV_GBR_UNITEDKINGDOM: 'United Kingdom',
  PROV_GBR_VALEOFGLAMORGAN: 'Vale of Glamorgan',
  PROV_GBR_WAKEFIELD: 'Wakefield',
  PROV_GBR_WALES: 'Wales',
  PROV_GBR_WALSALL: 'Walsall',
  PROV_GBR_WARRINGTON: 'Warrington',
  PROV_GBR_WARWICKSHIRE: 'Warwickshire',
  PROV_GBR_WESTBERKSHIRE: 'West Berkshire',
  PROV_GBR_WESTDUNBARTONSHIRE: 'West Dunbartonshire',
  PROV_GBR_WESTLOTHIAN: 'West Lothian',
  PROV_GBR_WESTSUSSEX: 'West Sussex',
  PROV_GBR_WILTSHIRE: 'Wiltshire',
  PROV_GBR_WINDSORANDMAIDENHEAD: 'Windsor and Maidenhead',
  PROV_GBR_WIRRAL: 'Wirral',
  PROV_GBR_WOKINGHAM: 'Wokingham',
  PROV_GBR_WORCESTERSHIRE: 'Worcestershire',
  PROV_GBR_WREXHAMCOUNTYBOROUGH: 'Wrexham County Borough',
  PROV_UMI_NOPROVINCE: 'No Province',
  PROV_URY_ARTIGASDEPARTMENT: 'Artigas Department',
  PROV_URY_CANELONESDEPARTMENT: 'Canelones Department',
  PROV_URY_CERROLARGODEPARTMENT: 'Cerro Largo Department',
  PROV_URY_COLONIADEPARTMENT: 'Colonia Department',
  PROV_URY_DURAZNODEPARTMENT: 'Durazno Department',
  PROV_URY_FLORESDEPARTMENT: 'Flores Department',
  PROV_URY_FLORIDADEPARTMENT: 'Florida Department',
  PROV_URY_LAVALLEJADEPARTMENT: 'Lavalleja Department',
  PROV_URY_MALDONADODEPARTMENT: 'Maldonado Department',
  PROV_URY_MONTEVIDEODEPARTMENT: 'Montevideo Department',
  PROV_URY_PAYSANDDEPARTMENT: 'Paysandú Department',
  PROV_URY_RONEGRODEPARTMENT: 'Río Negro Department',
  PROV_URY_RIVERADEPARTMENT: 'Rivera Department',
  PROV_URY_ROCHADEPARTMENT: 'Rocha Department',
  PROV_URY_SALTODEPARTMENT: 'Salto Department',
  PROV_URY_SANJOSDEPARTMENT: 'San José Department',
  PROV_URY_SORIANODEPARTMENT: 'Soriano Department',
  PROV_URY_TACUAREMBDEPARTMENT: 'Tacuarembó Department',
  PROV_URY_TREINTAYTRESDEPARTMENT: 'Treinta y Tres Department',
  PROV_UZB_ANDIJANREGION: 'Andijan Region',
  PROV_UZB_BUKHARAREGION: 'Bukhara Region',
  PROV_UZB_FERGANAREGION: 'Fergana Region',
  PROV_UZB_JIZZAKHREGION: 'Jizzakh Region',
  PROV_UZB_KARAKALPAKSTAN: 'Karakalpakstan',
  PROV_UZB_NAMANGANREGION: 'Namangan Region',
  PROV_UZB_NAVOIYREGION: 'Navoiy Region',
  PROV_UZB_QASHQADARYOREGION: 'Qashqadaryo Region',
  PROV_UZB_SAMARQANDREGION: 'Samarqand Region',
  PROV_UZB_SIRDARYOREGION: 'Sirdaryo Region',
  PROV_UZB_SURXONDARYOREGION: 'Surxondaryo Region',
  PROV_UZB_TASHKENT: 'Tashkent',
  PROV_UZB_TASHKENTREGION: 'Tashkent Region',
  PROV_UZB_XORAZMREGION: 'Xorazm Region',
  PROV_VUT_MALAMPA: 'Malampa',
  PROV_VUT_PENAMA: 'Penama',
  PROV_VUT_SANMA: 'Sanma',
  PROV_VUT_SHEFA: 'Shefa',
  PROV_VUT_TAFEA: 'Tafea',
  PROV_VUT_TORBA: 'Torba',
  PROV_VAT_NOPROVINCE: 'No Province',
  PROV_VEN_AMAZONAS: 'Amazonas',
  PROV_VEN_ANZOTEGUI: 'Anzoátegui',
  PROV_VEN_APURE: 'Apure',
  PROV_VEN_ARAGUA: 'Aragua',
  PROV_VEN_BARINAS: 'Barinas',
  PROV_VEN_BOLVAR: 'Bolívar',
  PROV_VEN_CARABOBO: 'Carabobo',
  PROV_VEN_COJEDES: 'Cojedes',
  PROV_VEN_DELTAAMACURO: 'Delta Amacuro',
  PROV_VEN_DISTRITOCAPITAL: 'Distrito Capital',
  PROV_VEN_FALCN: 'Falcón',
  PROV_VEN_FEDERALDEPENDENCIESOFVENEZUELA: 'Federal Dependencies of Venezuela',
  PROV_VEN_GURICO: 'Guárico',
  PROV_VEN_LAGUAIRA: 'La Guaira',
  PROV_VEN_LARA: 'Lara',
  PROV_VEN_MRIDA: 'Mérida',
  PROV_VEN_MIRANDA: 'Miranda',
  PROV_VEN_MONAGAS: 'Monagas',
  PROV_VEN_NUEVAESPARTA: 'Nueva Esparta',
  PROV_VEN_PORTUGUESA: 'Portuguesa',
  PROV_VEN_SUCRE: 'Sucre',
  PROV_VEN_TCHIRA: 'Táchira',
  PROV_VEN_TRUJILLO: 'Trujillo',
  PROV_VEN_YARACUY: 'Yaracuy',
  PROV_VEN_ZULIA: 'Zulia',
  PROV_VNM_ANGIANG: 'An Giang',
  PROV_VNM_BRAVNGTU: 'Bà Rịa-Vũng Tàu',
  PROV_VNM_BCGIANG: 'Bắc Giang',
  PROV_VNM_BCKN: 'Bắc Kạn',
  PROV_VNM_BCLIU: 'Bạc Liêu',
  PROV_VNM_BCNINH: 'Bắc Ninh',
  PROV_VNM_BNTRE: 'Bến Tre',
  PROV_VNM_BNHDNG: 'Bình Dương',
  PROV_VNM_BNHNH: 'Bình Định',
  PROV_VNM_BNHPHC: 'Bình Phước',
  PROV_VNM_BNHTHUN: 'Bình Thuận',
  PROV_VNM_CMAU: 'Cà Mau',
  PROV_VNM_CNTH: 'Cần Thơ',
  PROV_VNM_CAOBNG: 'Cao Bằng',
  PROV_VNM_NNG: 'Đà Nẵng',
  PROV_VNM_KLK: 'Đắk Lắk',
  PROV_VNM_KNNG: 'Đắk Nông',
  PROV_VNM_INBIN: 'Điện Biên',
  PROV_VNM_NGNAI: 'Đồng Nai',
  PROV_VNM_NGTHP: 'Đồng Tháp',
  PROV_VNM_GIALAI: 'Gia Lai',
  PROV_VNM_HGIANG: 'Hà Giang',
  PROV_VNM_HNAM: 'Hà Nam',
  PROV_VNM_HNI: 'Hà Nội',
  PROV_VNM_HTNH: 'Hà Tĩnh',
  PROV_VNM_HIDNG: 'Hải Dương',
  PROV_VNM_HIPHNG: 'Hải Phòng',
  PROV_VNM_HUGIANG: 'Hậu Giang',
  PROV_VNM_HCHMINH: 'Hồ Chí Minh',
  PROV_VNM_HABNH: 'Hòa Bình',
  PROV_VNM_HNGYN: 'Hưng Yên',
  PROV_VNM_KHNHHA: 'Khánh Hòa',
  PROV_VNM_KINGIANG: 'Kiên Giang',
  PROV_VNM_KONTUM: 'Kon Tum',
  PROV_VNM_LAICHU: 'Lai Châu',
  PROV_VNM_LMNG: 'Lâm Đồng',
  PROV_VNM_LNGSN: 'Lạng Sơn',
  PROV_VNM_LOCAI: 'Lào Cai',
  PROV_VNM_LONGAN: 'Long An',
  PROV_VNM_NAMNH: 'Nam Định',
  PROV_VNM_NGHAN: 'Nghệ An',
  PROV_VNM_NINHBNH: 'Ninh Bình',
  PROV_VNM_NINHTHUN: 'Ninh Thuận',
  PROV_VNM_PHTH: 'Phú Thọ',
  PROV_VNM_PHYN: 'Phú Yên',
  PROV_VNM_QUNGBNH: 'Quảng Bình',
  PROV_VNM_QUNGNAM: 'Quảng Nam',
  PROV_VNM_QUNGNGI: 'Quảng Ngãi',
  PROV_VNM_QUNGNINH: 'Quảng Ninh',
  PROV_VNM_QUNGTR: 'Quảng Trị',
  PROV_VNM_SCTRNG: 'Sóc Trăng',
  PROV_VNM_SNLA: 'Sơn La',
  PROV_VNM_TYNINH: 'Tây Ninh',
  PROV_VNM_THIBNH: 'Thái Bình',
  PROV_VNM_THINGUYN: 'Thái Nguyên',
  PROV_VNM_THANHHA: 'Thanh Hóa',
  PROV_VNM_THATHINHU: 'Thừa Thiên-Huế',
  PROV_VNM_TINGIANG: 'Tiền Giang',
  PROV_VNM_TRVINH: 'Trà Vinh',
  PROV_VNM_TUYNQUANG: 'Tuyên Quang',
  PROV_VNM_VNHLONG: 'Vĩnh Long',
  PROV_VNM_VNHPHC: 'Vĩnh Phúc',
  PROV_VNM_YNBI: 'Yên Bái',
  PROV_VGB_NOPROVINCE: 'No Province',
  PROV_VIR_NOPROVINCE: 'No Province',
  PROV_WLF_NOPROVINCE: 'No Province',
  PROV_ESH_NOPROVINCE: 'No Province',
  PROV_YEM_ADANGOVERNORATE: "'Adan Governorate",
  PROV_YEM_AMRANGOVERNORATE: "'Amran Governorate",
  PROV_YEM_ABYANGOVERNORATE: 'Abyan Governorate',
  PROV_YEM_ALBAYDAGOVERNORATE: "Al Bayda' Governorate",
  PROV_YEM_ALHUDAYDAHGOVERNORATE: 'Al Hudaydah Governorate',
  PROV_YEM_ALJAWFGOVERNORATE: 'Al Jawf Governorate',
  PROV_YEM_ALMAHRAHGOVERNORATE: 'Al Mahrah Governorate',
  PROV_YEM_ALMAHWITGOVERNORATE: 'Al Mahwit Governorate',
  PROV_YEM_DHAMARGOVERNORATE: 'Dhamar Governorate',
  PROV_YEM_HADHRAMAUTGOVERNORATE: 'Hadhramaut Governorate',
  PROV_YEM_HAJJAHGOVERNORATE: 'Hajjah Governorate',
  PROV_YEM_IBBGOVERNORATE: 'Ibb Governorate',
  PROV_YEM_LAHIJGOVERNORATE: 'Lahij Governorate',
  PROV_YEM_MARIBGOVERNORATE: "Ma'rib Governorate",
  PROV_YEM_RAYMAHGOVERNORATE: 'Raymah Governorate',
  PROV_YEM_SAADAGOVERNORATE: 'Saada Governorate',
  PROV_YEM_SANAA: "Sana'a",
  PROV_YEM_SANAAGOVERNORATE: "Sana'a Governorate",
  PROV_YEM_SHABWAHGOVERNORATE: 'Shabwah Governorate',
  PROV_YEM_SOCOTRAGOVERNORATE: 'Socotra Governorate',
  PROV_YEM_TAIZZGOVERNORATE: "Ta'izz Governorate",
  PROV_ZMB_CENTRALPROVINCE: 'Central Province',
  PROV_ZMB_COPPERBELTPROVINCE: 'Copperbelt Province',
  PROV_ZMB_EASTERNPROVINCE: 'Eastern Province',
  PROV_ZMB_LUAPULAPROVINCE: 'Luapula Province',
  PROV_ZMB_LUSAKAPROVINCE: 'Lusaka Province',
  PROV_ZMB_MUCHINGAPROVINCE: 'Muchinga Province',
  PROV_ZMB_NORTHERNPROVINCE: 'Northern Province',
  PROV_ZMB_NORTHWESTERNPROVINCE: 'Northwestern Province',
  PROV_ZMB_SOUTHERNPROVINCE: 'Southern Province',
  PROV_ZMB_WESTERNPROVINCE: 'Western Province',
  PROV_ZWE_BULAWAYOPROVINCE: 'Bulawayo Province',
  PROV_ZWE_HARAREPROVINCE: 'Harare Province',
  PROV_ZWE_MANICALAND: 'Manicaland',
  PROV_ZWE_MASHONALANDCENTRALPROVINCE: 'Mashonaland Central Province',
  PROV_ZWE_MASHONALANDEASTPROVINCE: 'Mashonaland East Province',
  PROV_ZWE_MASHONALANDWESTPROVINCE: 'Mashonaland West Province',
  PROV_ZWE_MASVINGOPROVINCE: 'Masvingo Province',
  PROV_ZWE_MATABELELANDNORTHPROVINCE: 'Matabeleland North Province',
  PROV_ZWE_MATABELELANDSOUTHPROVINCE: 'Matabeleland South Province',
  PROV_ZWE_MIDLANDSPROVINCE: 'Midlands Province',
};
