import React, { CSSProperties } from 'react';
import { CloseOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';

export interface CloseButtonProps {
  onClick: () => void;
  style?: CSSProperties;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick, style }: CloseButtonProps) => {
  return <Button type={'text'} icon={<CloseOutlined />} onClick={onClick} style={style} data-testid={'closeButton'} />;
};
