import { Popconfirm } from 'antd';
import React, { useState } from 'react';
import { PrescriptionFlattened, ServiceRendered } from '../../../../graph/types';
import { translations } from '../../../../constants/translations';
import { DropdownButtonWithMenu, MenuItemProps } from '../../../DropdownButtonWithMenu/DropdownButtonWithMenu';
import { ServiceRenderedWithLocalFields } from '../../../../pages/Invoices/ViewInvoice/viewInvoiceUtil';
import { flushSync } from 'react-dom';

export interface InvoiceServicesActionCellProps {
  serviceRendered: ServiceRenderedWithLocalFields;
  editButtonMenu: MenuItemProps;
  deleteServiceRendered: (serviceRendered: ServiceRendered) => void;
  printPrescription?: (prescription: PrescriptionFlattened) => void;
}

export const ServiceRenderedTableActionCell: React.FC<InvoiceServicesActionCellProps> = ({
  editButtonMenu,
  serviceRendered,
  deleteServiceRendered,
  printPrescription,
}) => {
  const [showPopconfirm, setShowPopconfirm] = useState<boolean>(false);

  const deleteButtonOnClick = (event: any) => {
    event.stopPropagation();
    flushSync(() => setShowPopconfirm(true));
  };

  const deleteButtonMenu = {
    title: translations.shared.deleteButtonText,
    onClick: (event: any) => deleteButtonOnClick(event),
  };

  const printPrescriptionButtonMenu = {
    title: translations.patientPage.prescriptions.print.title,
    onClick: () => printPrescription?.(serviceRendered.prescriptions![0]),
    hidden: !printPrescription || !serviceRendered.prescriptions?.length,
  };

  const actionProps: MenuItemProps[] = [editButtonMenu, deleteButtonMenu, printPrescriptionButtonMenu];

  return (
    <Popconfirm
      open={showPopconfirm}
      onOpenChange={(visible) => setShowPopconfirm(visible)}
      placement={'topLeft'}
      title={translations.renderedServicesTable.deleteConfirmationText}
      okText={translations.shared.popconfirm.ok}
      cancelText={translations.shared.popconfirm.cancel}
      onConfirm={() => deleteServiceRendered(serviceRendered)}
      disabled={serviceRendered.cacheLoading ?? false}
    >
      <DropdownButtonWithMenu menuItemProps={actionProps} isLoading={serviceRendered.cacheLoading ?? false} />
    </Popconfirm>
  );
};
