import * as React from 'react';
import { ServicesRenderedAction } from './actions';
import { initialState, servicesRenderedReducer, ServicesRenderedState } from './reducer';

export const ServicesRenderedContext = React.createContext<{
  state: ServicesRenderedState;
  dispatch: React.Dispatch<ServicesRenderedAction>;
}>({} as any);

type ServicesRenderedContextProviderProps = {
  contactId?: string;
};

export const ServicesRenderedContextProvider: React.FC<
  React.PropsWithChildren<ServicesRenderedContextProviderProps>
> = (props) => {
  const fullInitialState = {
    ...initialState,
    contactId: props.contactId,
  };
  const [state, dispatch] = React.useReducer(servicesRenderedReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <ServicesRenderedContext.Provider value={value}>{props.children}</ServicesRenderedContext.Provider>;
};
