import { OrganizationSubscriptionResult } from '../../graph/types';
import { subscriptionErrorConfigs, SubscriptionErrorKey, translations } from '../../constants/translations';

export const getSubscriptionUpsertErrorMessage = (
  subscriptionResult: OrganizationSubscriptionResult
): string | undefined => {
  if (subscriptionResult.error_name_key) {
    const errorMessages = [
      subscriptionErrorConfigs[subscriptionResult.error_name_key as SubscriptionErrorKey]?.message ||
        translations.shared.generalErrorMessage,
    ];
    if (subscriptionResult.error_name_key === SubscriptionErrorKey.CardFailed && subscriptionResult.error_message) {
      errorMessages.push(subscriptionResult.error_message);
    }
    return errorMessages.join(', ');
  }
  return undefined;
};
