import { invoiceServicePropertyNames, InvoiceServiceTableColumn } from '../../../constants/propertyNames';
import { ServiceRendered } from '../../../graph/types';
import { ColumnType } from 'antd/lib/table';
import { translations } from '../../../constants/translations';

export const serviceRenderedColumns: Record<InvoiceServiceTableColumn, ColumnType<ServiceRendered>> = {
  date: {
    title: translations.renderedServicesTable.columns.date,
    key: invoiceServicePropertyNames.date,
  },
  doctor_name: {
    title: translations.renderedServicesTable.columns.doctor,
    key: invoiceServicePropertyNames.doctor_name,
  },
  name: {
    title: translations.renderedServicesTable.columns.description,
    key: invoiceServicePropertyNames.name,
  },
  quantity: {
    title: translations.renderedServicesTable.columns.quantity,
    key: invoiceServicePropertyNames.quantity,
    align: 'right',
  },
  unit_name: {
    title: translations.renderedServicesTable.columns.unit,
    key: invoiceServicePropertyNames.unit_name,
  },
  unit_price: {
    title: translations.renderedServicesTable.columns.price,
    key: invoiceServicePropertyNames.unit_price,
    align: 'right',
  },
  total: {
    title: translations.renderedServicesTable.columns.total,
    key: invoiceServicePropertyNames.total,
    align: 'right',
  },
};
