import compareVersions from 'compare-versions';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import pkg from '../../package.json';
import { closeNotification, showNotification } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';
import { useOffline } from '../util/offline/offlineUtil';

const CACHE_CHECK_INTERVAL = 1000 * 60 * 5;
const notificationKey = 'cache-update-notification';
const hasCacheUpdated = async () => {
  const response = await fetch('/meta.json', { cache: 'no-cache' });
  const meta = await response.json();
  const latestVersion = meta.version;
  const currentVersion = pkg.version;
  return compareVersions(latestVersion, currentVersion) > 0;
};

const checkForCacheUpdate = async (forceReload?: boolean) => {
  if (!window.navigator.onLine) {
    return;
  }
  try {
    if (await hasCacheUpdated()) {
      if (caches) {
        const names = await caches.keys();
        for (const name of names) {
          caches.delete(name);
        }
      }

      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const i in registrations) {
        await registrations[i].unregister();
      }

      if (forceReload) {
        window.location.reload();
      } else {
        closeNotification(notificationKey);
        showNotification({
          key: notificationKey,
          message: translations.shared.cacheUpdateNotification.refresh.message,
          description: translations.shared.cacheUpdateNotification.refresh.description,
          btn: {
            text: translations.shared.refreshButtonText,
            action: () => window.location.reload(),
          },
        });
      }
    }
  } catch (e) {}
};

export const useCacheBuster = () => {
  const { isOnline } = useOffline();
  const history = useHistory();

  useEffect(() => {
    if (!isOnline) {
      return undefined;
    }

    checkForCacheUpdate(true);
    const timer = setInterval(checkForCacheUpdate, CACHE_CHECK_INTERVAL);
    history?.listen((_) => checkForCacheUpdate());

    return () => clearInterval(timer);
  }, [isOnline, history]);
};
