import React, { useMemo } from 'react';
import { Ledger } from '../../graph/types';
import { useGetLedgerDataForPaymentModal } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { PaymentModal } from './PaymentModal/PaymentModal';
import { PaymentModalFormFields } from './PaymentModal/PaymentForm/PaymentForm';
import { LedgerUpsertGenerator } from '../../classes/upsertGenerators/LedgerUpsertGenerator';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';

export interface EditPaymentModalProps {
  onClose: () => void;
  onSuccess?: () => void;
  contactId: string;
  ledgerEntry: Ledger;
}

const EditPaymentModal: React.FC<EditPaymentModalProps> = ({ ledgerEntry, onClose, onSuccess, contactId }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { ledger, ledgerLoading } = useGetLedgerDataForPaymentModal({ ledgerId: ledgerEntry.id, organizationId });
  const finalLedgerEntry = useMemo(() => ledger || { ...ledgerEntry, info: [] }, [ledger, ledgerEntry]);

  const mapFormFieldsToLedgerUpsert = (formFields: PaymentModalFormFields) => {
    return LedgerUpsertGenerator.getPaymentUpsertForExistingContactPayment(formFields, finalLedgerEntry);
  };

  return (
    <PaymentModal
      contactId={contactId}
      practiceId={practiceId}
      onClose={onClose}
      onSuccess={onSuccess}
      mapFormFieldsToLedgerUpsert={mapFormFieldsToLedgerUpsert}
      ledger={{ loading: ledgerLoading, data: finalLedgerEntry }}
    />
  );
};

export default EditPaymentModal;
