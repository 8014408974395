import {
  RecordsAction,
  selectRecordType,
  setHasUnsavedChangesType,
  setSearchValueType,
  setSelectServiceRenderedType,
  setServicesRenderedType,
} from './actions';

export type selectedRecordIdType = string | { newRecord: 'file' | 'note' };

export type ServiceRenderedFragment = { id: string; note_id: string; date: string };
export interface RecordsState {
  selectedRecordId?: selectedRecordIdType;
  selectedServiceRendered?: ServiceRenderedFragment;
  servicesRendered?: ServiceRenderedFragment[];
  hasUnsavedChanges: boolean;
  searchValue?: string;
}

export const initialState: RecordsState = { hasUnsavedChanges: false, servicesRendered: [] };

export const recordsReducer = (state: RecordsState, action: RecordsAction): RecordsState => {
  switch (action.type) {
    case selectRecordType:
      return {
        ...state,
        selectedRecordId: action.recordId,
        selectedServiceRendered: undefined,
      };
    case setHasUnsavedChangesType:
      return {
        ...state,
        hasUnsavedChanges: action.hasUnsavedChanges,
      };
    case setSearchValueType:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case setSelectServiceRenderedType:
      return {
        ...state,
        selectedServiceRendered: action.selectedServiceRender,
        selectedRecordId: '',
      };
    case setServicesRenderedType:
      return {
        ...state,
        servicesRendered: action.servicesRender,
      };
  }
  return state;
};
