import { ContactProperty, contactPropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Contact } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  customFilterIcon,
  generalFilteringAndSortingSettings,
  getIdNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { getContactTags } from '../../../util/tags';

export const tagsProperty = 'tags';

export const basicContactColumns: Record<ContactProperty | 'tags', CustomColumnType<Contact>> = {
  name: {
    title: translations.contactsPage.columns.name,
    key: contactPropertyNames.name,
    dataIndex: contactPropertyNames.name,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.contactsPage.columns.name),
    onFilter: getOnFilterFunctionFor(contactPropertyNames.name),
    sorter: getStringCompareFunctionFor(contactPropertyNames.name),
  },
  contact_status_name: {
    title: translations.contactsPage.columns.status,
    key: contactPropertyNames.contact_status_name,
    dataIndex: contactPropertyNames.contact_status_name,
    ...generalFilteringAndSortingSettings,
    onFilter: (value, record) => record.contact_status_id.includes(value.toString()),
  },
  number: {
    title: translations.contactsPage.columns.number,
    key: contactPropertyNames.number,
    dataIndex: contactPropertyNames.number,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.contactsPage.columns.number),
    onFilter: getOnFilterFunctionFor(contactPropertyNames.number),
    sorter: getIdNumberCompareFunctionFor(contactPropertyNames.number),
  },
  email: {
    title: translations.contactsPage.columns.email,
    key: contactPropertyNames.email,
    dataIndex: contactPropertyNames.email,
    ...generalFilteringAndSortingSettings,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.contactsPage.columns.email),
    onFilter: getOnFilterFunctionFor(contactPropertyNames.email),
    sorter: getStringCompareFunctionFor(contactPropertyNames.email),
  },
  tags: {
    title: translations.contactsPage.columns.tags,
    key: tagsProperty,
    render: getContactTags,
    onFilter: (value, record) => record.type_name_keys?.includes(value.toString()) || false,
    filterIcon: customFilterIcon,
  },
  balance_posted: {
    title: translations.contactsPage.columns.balance,
    ...generalFilteringAndSortingSettings,
    key: contactPropertyNames.balance_posted,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.contactsPage.columns.balance),
    isForNumber: true,
    onFilter: getOnFilterFunctionFor('balance_posted', true),
    sorter: (contact1, contact2) => +contact1.balance_posted - +contact2.balance_posted,
  },
};
