import React, { useState } from 'react';
import { Form } from 'antd';
import { translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useGetReportParameters, useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';
import { Loading } from '../Loading/Loading';
import { useGetCurrentUserBasicFields } from '../../hooks/ajax/user/userHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { ReportParameterItem } from './ReportParameterItem';
import { SelectForReportParameter } from './SelectForReportParameter';
import { ParameterInputMapKeys, SelectDataKey } from './runReportWithParametersUtil';
import { getGetReportVariables, printReport } from '../../pages/GeneralReports/generalReportsUtils';
import { Report } from '../../graph/types';
import { ReportParameterItemWithData } from './ReportParameterItemWithData';
import dayjs from 'dayjs';
import { getFileNameForReport, getReportDataRetriever } from '../../util/reportUtils';
import { RunnerTypes } from '../../constants/reports';
import { ReportQueryKeys, buildReportVariables } from '../ActiveReportViewer/activeReportViewerUtils';
import { showErrorMessage } from '../Notification/notificationUtil';
import { upsertDateFormat } from '../../constants/formats';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface RunReportWithParametersModalProps {
  report: Report;
  visible: boolean;
  onClose: () => void;
}

export const RunReportWithParametersModal: React.FC<RunReportWithParametersModalProps> = ({
  report,
  visible,
  onClose,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { reportParameters, reportParametersLoading } = useGetReportParameters(organizationId, report.id);
  const practiceId = useDefaultPracticeId();

  const { user, userLoading } = useGetCurrentUserBasicFields();
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigateToReportViewer = useNavigateToReportViewer();

  const [form] = Form.useForm();

  const handleValueChange = () => {
    setHasChanges(true);
  };

  const handleClose = () => {
    setIsSaving(false);
    setHasChanges(false);
    form.resetFields();
    onClose();
  };

  const prepareValuesForReport = (values: Record<string, string>, useActiveReport: boolean) => {
    const preparedValues = { ...values };
    if (values[ParameterInputMapKeys.DateRange]) {
      const startDate = useActiveReport ? 'start_date' : 'startDate';
      const endDate = useActiveReport ? 'end_date' : 'endDate';
      preparedValues[startDate] = dayjs(values[ParameterInputMapKeys.DateRange][0]).format(upsertDateFormat);
      preparedValues[endDate] = dayjs(values[ParameterInputMapKeys.DateRange][1]).format(upsertDateFormat);
      delete preparedValues[ParameterInputMapKeys.DateRange];
    }
    if (values.effectiveDate) {
      preparedValues.effectiveDate = dayjs(values.effectiveDate).format(upsertDateFormat);
    }
    if (values.contactId) {
      const contactId = useActiveReport ? 'contact_id' : 'contactId';
      preparedValues[contactId] = values.contactId;
      useActiveReport && delete preparedValues.contactId;
    }
    if (values.serviceId) {
      const serviceId = useActiveReport ? 'service_id' : 'serviceId';
      preparedValues[serviceId] = values.serviceId;
      useActiveReport && delete preparedValues.serviceId;
    }
    return preparedValues;
  };

  const onFinish = async (values: Record<string, string>) => {
    setIsSaving(true);
    const useActiveReports = report.runner === RunnerTypes.ActiveReports;
    const parameters = prepareValuesForReport(values, useActiveReports);

    if (useActiveReports) {
      setHasChanges(false);

      if (!report.runner_function) {
        showErrorMessage(translations.reports.missingParams);
        setIsSaving(false);
        onClose();
        return;
      }

      const fnReportDataRetriever = getReportDataRetriever(report.runner_function as ReportQueryKeys);

      if (!fnReportDataRetriever) {
        showErrorMessage(translations.reports.missingParams);
        setIsSaving(false);
        onClose();
        return;
      }

      const reportVariables = buildReportVariables(
        report.runner_function as ReportQueryKeys,
        organizationId,
        practiceId,
        parameters
      );
      const reportData = await fnReportDataRetriever(reportVariables);

      navigateToReportViewer({
        reportName: report.file_name,
        data: reportData,
      });
    } else {
      const reportVariables = getGetReportVariables(
        parameters,
        report.file_name,
        practiceId,
        organizationId,
        getFileNameForReport(report.name, upsertDateFormat),
        true
      );

      await printReport(reportVariables);
    }

    setIsSaving(false);
    onClose();
  };

  const mapReportParametersToInputs = () => {
    if (!reportParameters || !user) {
      return undefined;
    }
    const inputs: JSX.Element[] = [];
    reportParameters.forEach((parameter, i) => {
      if (parameter) {
        if (
          parameter.input_type === SelectDataKey.SelectContact ||
          parameter.input_type === SelectDataKey.SelectPatient ||
          parameter.input_type === SelectDataKey.SelectService
        ) {
          inputs.push(<SelectForReportParameter key={i} reportParameter={parameter} organizationId={organizationId} />);
        } else if (
          parameter.input_type === ParameterInputMapKeys.Dropdown ||
          parameter.input_type === ParameterInputMapKeys.MultiSelect
        ) {
          inputs.push(
            <ReportParameterItemWithData
              key={i}
              reportParameter={parameter}
              userId={user.id}
              practiceId={practiceId}
              organizationId={organizationId}
            />
          );
        } else {
          inputs.push(<ReportParameterItem key={i} reportParameter={parameter} />);
        }
      }
    });
    return inputs;
  };
  return (
    <ModalWithCloseConfirm
      title={translations.runReportModal.title}
      onCancel={handleClose}
      open={visible}
      isSaving={isSaving}
      changesMade={hasChanges}
      okText={translations.runReportModal.run}
      onOk={form.submit}
    >
      {reportParametersLoading || userLoading ? (
        <Loading />
      ) : (
        <Form {...layout} onFinish={onFinish} onValuesChange={handleValueChange} form={form} autoComplete='off'>
          {/* TODO: test functionality */}
          {mapReportParametersToInputs()}
        </Form>
      )}
    </ModalWithCloseConfirm>
  );
};
