import React, { ReactElement } from 'react';
import { ContactsOutlined, DollarOutlined } from '@ant-design/icons';
import { routes } from '../../../../constants/routes';
import { translations } from '../../../../constants/translations';
import { Address, Contact, ContactType, GlobalSearchResult, Invoice, Patient, Phone } from '../../../../graph/types';
import {
  withContactIdParameter,
  withInvoiceIdParameter,
  withPatientIdParameter,
} from '../../../../hooks/route/navigationHooks';
import { patientMenuIcon } from '../../../../images/images';
import { getContactTags, getPatientRelationTags } from '../../../../util/tags';
import { getInvoiceContactTags } from '../../../Invoices/invoiceContactTags';
import { GlobalSearchOptionResultType } from './SearchOption';
import { SearchOptionType } from './types';
import { FlexContainer } from '../../../../globalStyles.style';
import { renderPatientOwnerTags } from '../../../Invoices/InvoicesOverview/util/patientOwnerTags';
import { TagsDivider } from './Search.styles';
import dayjs from 'dayjs';
import CurrencyFormatter from '../../../../components/CurrencyFormatter/CurrencyFormatter';
import { countryProvStateTranslations, countryTranslations } from '../../../../constants/countryTranslations';

export const isOfProvidedIndexType = (index: string, type: string) => {
  return index.includes(type);
};

export function getPatientDescription(args: string[]) {
  let string = '';
  for (const s of args) {
    string = string + ' ' + s;
  }
  return string;
}

export const getNormalizedGlobalSearchData = (
  data?: GlobalSearchResult[] | null,
  contactTypes?: ContactType[],
  dateFormat?: string
): SearchOptionType[] =>
  (data as GlobalSearchResult[])?.map(({ _index, _source, _id }) => {
    const { document } = _source;
    if (isOfProvidedIndexType(_index, 'contact')) {
      const address = document.address?.[0];
      const { type_name_keys, phone } = document;
      const primaryPhone = phone?.find((phone) => phone?.primary) ?? phone?.[0];
      const contact = { id: _id, name: document.name!, address, type_name_keys, phone: primaryPhone };
      return getNormalizedContact(contact);
    }

    if (isOfProvidedIndexType(_index, 'patient')) {
      const patient = {
        ...document,
        id: _id,
      } as Patient;
      return getNormalizedPatient(patient, contactTypes);
    }

    const invoice = { ...document, id: _id } as Invoice;
    return getNormalizedInvoice(invoice, invoice.total ?? '', dateFormat ?? '');
  });

export const getNormalizedContact = ({
  id,
  name,
  address,
  type_name_keys,
  phone,
}: {
  id: string;
  name: string;
  address?: Address | null;
  type_name_keys?: (string | null)[] | null;
  phone?: Phone | null;
}): SearchOptionType => {
  const country = countryTranslations[address?.country_name_key as keyof typeof countryTranslations];
  const prov =
    countryProvStateTranslations[address?.country_prov_state_name_key as keyof typeof countryProvStateTranslations];
  return {
    id: id ?? '',
    header: name ?? '',
    description: (
      <FlexContainer direction='column'>
        <span> {`${address?.address_1 ?? ''} ${address?.city ?? ''} ${prov ?? ''} ${country ?? ''}`}</span>
        <span>{phone?.phone}</span>
      </FlexContainer>
    ),
    icon: ContactsOutlined,
    route: routes.viewContact,
    parameter: withContactIdParameter(id ?? ''),
    tags: getContactTags({ type_name_keys } as Contact),
    type: GlobalSearchOptionResultType.Contact,
    rowKey: `${GlobalSearchOptionResultType.Contact}:${id}`,
  };
};

export const getNormalizedPatient = (patient: Patient, contactTypes?: ContactType[]): SearchOptionType => ({
  id: patient.id,
  header: patient.name ?? '',
  description: (
    <>
      <FlexContainer direction='column'>
        {getPatientDescription([
          patient.species_name ?? '',
          patient.breed_name ?? '',
          patient.gender_name ?? '',
          patient.color_name ?? '',
        ])}
        {renderPatientOwnerTags(patient, true)}
      </FlexContainer>
      <TagsDivider />
    </>
  ),
  icon: patientMenuIcon,
  tags: getPatientRelationTags(patient, contactTypes || []),
  route: routes.viewPatient,
  parameter: withPatientIdParameter(patient.id),
  type: GlobalSearchOptionResultType.Patient,
  rowKey: `${GlobalSearchOptionResultType.Patient}:${patient.id}`,
});

export const getNormalizedInvoice = (
  invoice: Invoice,
  total: ReactElement | string,
  dateFormat: string
): SearchOptionType => ({
  id: invoice.id,
  header: `${translations.invoicePage.invoice} ${invoice.number}` ?? '',
  description: (
    <FlexContainer direction='column'>
      <span>{invoice.patient_name}</span>
      <FlexContainer justifyContent='space-between'>
        <span>{dayjs(invoice.date).format(dateFormat)}</span>
        <h4>
          {translations.invoicePage.total}: <CurrencyFormatter total={invoice.total ?? total} />
        </h4>
      </FlexContainer>
    </FlexContainer>
  ),
  tags: getInvoiceContactTags(invoice),
  icon: DollarOutlined,
  route: routes.viewInvoice,
  parameter: withInvoiceIdParameter(invoice.id),
  type: GlobalSearchOptionResultType.Invoice,
  rowKey: `${GlobalSearchOptionResultType.Invoice}:${invoice.id}`,
});
