import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { translations } from '../../constants/translations';

export interface WithdrawalPeriodModalFormValues {
  withdrawal_period?: number;
}

const RightAlignedColumn = styled(Col)`
  text-align: right;
  padding: 5px 8px;
`;

const LeftAlignedColumn = styled(Col)`
  text-align: left;
  padding: 5px 8px;
`;

const MIN_PERIOD_VALUE = 1;
const MAX_PERIOD_VALUE = 10000;

export const withdrawalPeriodModalFormTestId = 'withdrawalPeriodTestId';
export const withdrawalFormInputTestId = 'withdrawalFormInputTestId';

export interface WithdrawalPeriodModalProps {
  autofocus?: boolean;
}

export const WithdrawalPeriodModalContent: React.FC<WithdrawalPeriodModalProps> = ({ autofocus }) => (
  <Row>
    <RightAlignedColumn span={9}>{translations.shared.withdrawalModal.fields.withdrawal} :</RightAlignedColumn>
    <Col span={10}>
      <Form.Item name='withdrawal_period'>
        <Input
          type='number'
          min={MIN_PERIOD_VALUE}
          max={MAX_PERIOD_VALUE}
          step='1'
          data-testid={withdrawalFormInputTestId}
          autoFocus={autofocus}
        />
      </Form.Item>
    </Col>
    <LeftAlignedColumn span={5}>{translations.shared.withdrawalModal.periodUnit}</LeftAlignedColumn>
  </Row>
);
