import { Form } from 'antd';
import React from 'react';
import {
  reportParameterLabelNameKeyConfigs,
  ReportParametersLabelNameKeys,
  translations,
} from '../../constants/translations';
import { ReportParameters } from '../../graph/types';
import { getRequiredRule } from '../../util/forms';
import {
  parameterInputMap,
  ParameterInputMapKeys,
  reportParameterDatePickerTestId,
} from './runReportWithParametersUtil';
import dayjs from 'dayjs';
import { useUserLocaleData } from '../../hooks/useUserLocale';

interface ReportParameterItemProps {
  reportParameter: ReportParameters;
}

const getDateRangeFromValue = (value: string) => {
  const [start, end] = value.split(',');
  return [dayjs(start), dayjs(end)];
};

export const ReportParameterItem: React.FC<ReportParameterItemProps> = ({ reportParameter }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const isCheckbox = reportParameter.input_type === ParameterInputMapKeys.Checkbox;
  const isDateRange = reportParameter.input_type === ParameterInputMapKeys.DateRange;
  const isDate = reportParameter.input_type === ParameterInputMapKeys.Date;

  const initialCheckbox = isCheckbox ? !!reportParameter.value : undefined;
  const initialStartDate =
    isDateRange && reportParameter.value ? getDateRangeFromValue(reportParameter.value) : undefined;
  const initialDate = isDate && reportParameter.value ? dayjs(reportParameter.value) : undefined;
  return (
    <Form.Item
      data-testid={isDateRange ? reportParameterDatePickerTestId : undefined}
      initialValue={initialCheckbox ?? initialStartDate ?? initialDate}
      valuePropName={isCheckbox ? 'checked' : undefined}
      rules={!isCheckbox ? [getRequiredRule(translations.runReportModal.field)] : undefined}
      name={reportParameter.parameter}
      label={reportParameterLabelNameKeyConfigs[reportParameter?.name_key as ReportParametersLabelNameKeys]}
    >
      {parameterInputMap(dateFormat)[reportParameter.input_type as ParameterInputMapKeys]}
    </Form.Item>
  );
};
