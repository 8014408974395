import { OrganizationDto, SettingDto } from '../../graph/types';

export type OrganizationAction = SetOrganization | SetPracticeSetting;

export const setOrganizationType = 'ORGANIZATION__SET_ORGANIZATION';
export const setPracticeSettingType = 'ORGANIZATION__SET_PRACTICE_SETTING';

export interface SetOrganization {
  type: typeof setOrganizationType;
  organization: OrganizationDto;
}

export interface SetPracticeSetting {
  type: typeof setPracticeSettingType;
  practiceId: string;
  setting: SettingDto;
}

export const setOrganization = (organization: OrganizationDto): SetOrganization => ({
  type: setOrganizationType,
  organization,
});

export const setPracticeSetting = (practiceId: string, setting: SettingDto): SetPracticeSetting => ({
  type: setPracticeSettingType,
  practiceId,
  setting,
});
