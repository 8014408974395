import gql from 'graphql-tag';
import { AddressFields, PhoneFields } from '../fragments';

export const GlobalSearchFields = gql`
  fragment GlobalSearchFields on GlobalSearchResult {
    _id
    _index
    _source {
      document {
        number
        name
        email
        contact_names
        contact_number
        patient_name
        patient_number
        total
        status_name_key
        contact_status_name
        phone {
          ...PhoneFields
        }
        name_2
        owner_names
        species_name
        breed_name
        gender_name
        address {
          ...AddressFields
        }
        type_name_keys
        color_name
        contact_ids
        contact_percentages
        owner_ids
        owner_percentages
        related_names
        related_ids
        related_types
        date
      }
    }
  }
  ${PhoneFields}
  ${AddressFields}
`;
