import React, { useMemo } from 'react';
import dayJs from 'dayjs';
import { useGetStripePayoutDetails } from '../../hooks/stripeHooks';
import {
  StripePayoutStatusNameKey,
  StripePayoutTypeConfig,
  StripePayoutTypeNameKey,
  StripeStatusConfig,
  StripeTransactionTypeNameKey,
  stripePayoutStatusConfig,
  stripePayoutTypeConfig,
  stripeTransactionTypeConfig,
} from '../../constants/referenceData/stripeReferenceData';
import { translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';
import { CustomFlexContainerMixin, CustomFlexContainerType, FlexContainer } from '../../globalStyles.style';
import { getTag } from '../../util/tags';
import { PriceValue } from '../../components/PriceValue/PriceValue';
import Table from 'antd/lib/table/Table';
import { useUserLocaleData } from '../../hooks/useUserLocale';

type StripePayoutDetailsModalProps = {
  organizationId: string;
  practiceId: string;
  payoutId: string;
  onClose: () => void;
  title: React.ReactElement;
};

type OverviewSectionData = {
  status: StripeStatusConfig;
  amount: number;
  type: StripePayoutTypeConfig;
  date: string;
};

type SummarySectionItem = {
  count: number;
  gross: number;
  fees: number;
  total: number;
};

type SummarySectionData = {
  [nameKey in StripeTransactionTypeNameKey]: SummarySectionItem;
};

type TransactionsSectionItem = {
  id: string;
  typeName: string;
  description: string;
  amount: number;
  ledgerId: string;
  ledgerNumber: string;
  ledgerFinancialDate: string;
  contactId: string;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
};

type TransactionsSectionData = {
  transactions: TransactionsSectionItem[];
};

const FlexContainerWithMargin = styled.div<{ noMargin?: boolean } & CustomFlexContainerType>`
  ${CustomFlexContainerMixin}
  margin: ${({ noMargin }) => (noMargin ? '0' : '6px 0')};
`;

const Section = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
`;

export const StripePayoutDetailsModal: React.FC<StripePayoutDetailsModalProps> = ({
  title,
  payoutId,
  organizationId,
  practiceId,
  onClose,
}) => {
  const { detailsLoading, payoutDetails: payoutDetailsResponse } = useGetStripePayoutDetails(
    organizationId,
    practiceId,
    payoutId
  );
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const { payout_details: payoutDetails, transaction_list: transactionList } = payoutDetailsResponse ?? {};

  const statusConfig = stripePayoutStatusConfig[payoutDetails?.status as StripePayoutStatusNameKey];
  const typeConfig = stripePayoutTypeConfig[payoutDetails?.type as StripePayoutTypeNameKey];

  const modalTranslations = translations.stripePayoutsModal;

  const overviewSection = useMemo(
    () =>
      detailsLoading || !payoutDetails
        ? undefined
        : ({
            status: statusConfig,
            amount: Number(payoutDetails?.amount),
            type: typeConfig,
            date: payoutDetails?.arrival_date ?? '',
          } as OverviewSectionData),
    [detailsLoading, payoutDetails, statusConfig, typeConfig]
  );

  const summarySection = useMemo(
    () =>
      (detailsLoading || !transactionList
        ? undefined
        : transactionList?.reduce(
            (previousResult, currentTransaction) => ({
              ...previousResult,
              [currentTransaction?.type as StripeTransactionTypeNameKey]: {
                count: (previousResult[currentTransaction?.type as StripeTransactionTypeNameKey]?.count ?? 0) + 1,
                fees:
                  (previousResult[currentTransaction?.type as StripeTransactionTypeNameKey]?.fees ?? 0) +
                  Number(currentTransaction?.fee),
                gross:
                  (previousResult[currentTransaction?.type as StripeTransactionTypeNameKey]?.gross ?? 0) +
                  Number(currentTransaction?.amount),
                total:
                  (previousResult[currentTransaction?.type as StripeTransactionTypeNameKey]?.total ?? 0) +
                  Number(currentTransaction?.net),
              } as SummarySectionItem,
            }),
            {} as SummarySectionData
          )) as SummarySectionData,
    [detailsLoading, transactionList]
  );

  const summarySectionRows = useMemo(
    () =>
      Object.keys(summarySection ?? {}).map((summaryItemKey) => ({
        id: summaryItemKey,
        title: stripeTransactionTypeConfig[summaryItemKey as StripeTransactionTypeNameKey].pluralText,
        count: summarySection[summaryItemKey as StripeTransactionTypeNameKey].count,
        gross: summarySection[summaryItemKey as StripeTransactionTypeNameKey].gross,
        fees: summarySection[summaryItemKey as StripeTransactionTypeNameKey].fees,
        total: summarySection[summaryItemKey as StripeTransactionTypeNameKey].total,
      })),
    [summarySection]
  );

  const summarySectionColumns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: modalTranslations.sections.summary.columns.count,
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: modalTranslations.sections.summary.columns.gross,
      dataIndex: 'gross',
      key: 'gross',
      render: (value: number) => <PriceValue value={value} />,
    },
    {
      title: modalTranslations.sections.summary.columns.fees,
      dataIndex: 'fees',
      key: 'fees',
      render: (value: number) => <PriceValue value={value} />,
    },
    {
      title: modalTranslations.sections.summary.columns.total,
      dataIndex: 'total',
      key: 'total',
      render: (value: number) => <PriceValue value={value} />,
    },
  ];

  const transactionsSection = useMemo(
    () =>
      (detailsLoading || !transactionList
        ? undefined
        : {
            transactions: transactionList?.map(
              (currentTransaction) =>
                ({
                  id: currentTransaction?.id,
                  amount: Number(currentTransaction?.amount),
                  description: currentTransaction?.description,
                  typeName: stripeTransactionTypeConfig[currentTransaction?.type as StripeTransactionTypeNameKey].text,
                  ledgerId: currentTransaction?.ledger_id,
                  ledgerNumber: currentTransaction?.ledger_number,
                  ledgerFinancialDate: currentTransaction?.ledger_financial_date,
                  contactId: currentTransaction?.contact_id,
                  contactName: currentTransaction?.contact_name,
                  contactNumber: currentTransaction?.contact_number,
                  contactEmail: currentTransaction?.contact_email,
                } as TransactionsSectionItem)
            ),
          }) as TransactionsSectionData,
    [detailsLoading, transactionList]
  );

  const transactionSectionsColumn = [
    {
      render: (currentTransaction: TransactionsSectionItem) => (
        <>
          <div>{currentTransaction.typeName}</div>
          <div>{currentTransaction.description}</div>
        </>
      ),
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => <PriceValue value={value} />,
    },
  ];

  return (
    <ModalWithCloseConfirm
      open
      title={title}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      isSaving={detailsLoading}
      savingMessage={translations.shared.loading}
    >
      {
        <>
          {overviewSection && (
            <Section>
              <Title level={5}>{modalTranslations.sections.overview.title}</Title>
              <FlexContainerWithMargin key='overview-status' direction='row' justifyContent='space-between'>
                <span>{modalTranslations.sections.overview.status}</span>
                <span>{getTag(overviewSection.status.color, overviewSection.status.text)}</span>
              </FlexContainerWithMargin>
              <FlexContainerWithMargin key='overview-amount' direction='row' justifyContent='space-between'>
                <span>{modalTranslations.sections.overview.amount}</span>
                <span>{<PriceValue value={overviewSection.amount} />}</span>
              </FlexContainerWithMargin>
              <FlexContainerWithMargin key='overview-type' direction='row' justifyContent='space-between'>
                <span>{modalTranslations.sections.overview.type}</span>
                <span>{getTag(overviewSection.type.color, overviewSection.type.text)}</span>
              </FlexContainerWithMargin>
              <FlexContainerWithMargin key='overview-date' direction='row' justifyContent='space-between'>
                <span>{modalTranslations.sections.overview.date}</span>
                <span>{dayJs(overviewSection.date).format('MMM DD, HH:mm A')}</span>
              </FlexContainerWithMargin>
            </Section>
          )}
          {summarySection && (
            <Section>
              <Title level={5}>{modalTranslations.sections.summary.title}</Title>
              <Table
                size='small'
                dataSource={summarySectionRows}
                columns={summarySectionColumns}
                rowKey='id'
                pagination={{ pageSize: 5 }}
              />
            </Section>
          )}
          {transactionsSection && (
            <Section>
              <Title level={5}>{modalTranslations.sections.transactions.title}</Title>
              <Table
                size='small'
                dataSource={transactionsSection.transactions}
                columns={transactionSectionsColumn}
                rowKey='id'
                pagination={{ pageSize: 5 }}
                bordered={false}
                expandable={{
                  expandedRowRender: (record) => (
                    <FlexContainer direction='column'>
                      <span>
                        <b>{modalTranslations.sections.transactions.expandable.contactTitle}</b>
                        <p>
                          {record.contactNumber} - {record.contactName} - {record.contactEmail}
                        </p>
                      </span>
                      <span>
                        <b>{modalTranslations.sections.transactions.expandable.ledgerTitle}</b>
                        <p>
                          {record.ledgerNumber} - {dayJs(record.ledgerFinancialDate).format(dateFormat)}
                        </p>
                      </span>
                    </FlexContainer>
                  ),
                  rowExpandable: (record) => record.ledgerId.length > 0,
                }}
              />
            </Section>
          )}
        </>
      }
    </ModalWithCloseConfirm>
  );
};
