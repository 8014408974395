import styled from 'styled-components';
import { primaryPalette } from '../../colorScheme';

export const StyledClickableIcon = styled.div<{ opaque?: boolean }>`
  padding: 4px;
  margin-right: 2px;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: ${({ opaque = false }) => primaryPalette[opaque ? 1 : 7]};
  &:hover {
    color: ${({ opaque = false }) => primaryPalette[opaque ? 3 : 9]};
  }

  &.red {
    color: red;
  }
`;
