import { Form, Input } from 'antd';
import { Store } from 'antd/es/form/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { TextEditor } from '../../../components/TextEditor/TextEditor';
import { PracticeSettingsId, translations } from '../../../constants/translations';
import { Setting, SettingDto, SettingUpsert } from '../../../graph/types';
import { useMutationWithParameterWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { getSettingUpsert, useUpsertSetting } from '../../../hooks/ajax/setting/settingHooks';
import { useFormChanges } from '../../../hooks/useFormChanges';
import { getRequiredRule } from '../../../util/forms';
import { getConfigurationByPractice } from '../utils';
import { setPracticeSetting } from '../../../contexts/organization/action';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { defaultPractice } from '../../../contexts/organization/selectors';

type ReminderRunConfigModalProps = {
  setModalOpen: (state: boolean) => void;
  organizationId: string;
  isModalOpen: boolean;
  bodyState: string;
  setBodyState: (value: string) => void;
};

export const ModalConfigureButton: React.FC<ReminderRunConfigModalProps> = ({
  setModalOpen,
  organizationId,
  isModalOpen,
  bodyState,
  setBodyState,
}) => {
  const handleUpdateContext = (newSetting?: Setting) => {
    if (practice && newSetting) {
      orgContextDispatch(setPracticeSetting(practice.id, { ...newSetting, __typename: 'SettingDTO' } as SettingDto));
    }
  };
  const [upsertSetting] = useMutationWithParameterWithMessages(useUpsertSetting, handleUpdateContext);
  const { state, dispatch: orgContextDispatch } = useOrganizationContext();
  const practice = defaultPractice(state);
  const practiceId = practice?.id;
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const { subject, subjectSetting, reminderBody, reminderBodySetting } = useMemo(
    () => getConfigurationByPractice(practice),
    [practice]
  );

  const initialValues = {
    subject,
    body: bodyState,
  };

  const { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange } = useFormChanges(
    form,
    initialValues
  );

  useEffect(() => {
    setBodyState(reminderBody);
  }, [reminderBody, setBodyState]);

  const reminderBodyValue = (value: string) => {
    form.setFieldValue('body', value);
    setBodyState(value);

    if (value !== reminderBody) {
      setHasDataChanged(true);
    } else {
      setHasDataChanged(false);
    }
  };

  const handleFinish = async (value: Store) => {
    setIsSaving(true);
    const subjectSettingUpsert = getSettingUpsert(
      value.subject,
      PracticeSettingsId.ReminderSubject,
      subjectSetting?.id,
      practiceId
    );

    const footerSettingUpsert = getSettingUpsert(
      bodyState,
      PracticeSettingsId.ReminderBody,
      reminderBodySetting?.id,
      practiceId
    );

    const getUpsertWithMessages = (upsert: SettingUpsert) => ({
      options: {
        variables: {
          organizationId,
          setting: upsert,
        },
      },
    });

    await Promise.all([
      upsertSetting(getUpsertWithMessages(subjectSettingUpsert)),
      upsertSetting(getUpsertWithMessages(footerSettingUpsert)),
    ]);

    setIsSaving(false);
    setHasDataChanged(false);
    setModalOpen(false);
  };

  if (!practice) {
    return <Loading />;
  }

  return (
    <ModalWithCloseConfirm
      title={translations.reminderRuns.configurationModal.title}
      onCancel={() => {
        setModalOpen(false);
        if (hasDataChanged) {
          handleOnReset();
        }
      }}
      isSaving={isSaving}
      onOk={form.submit}
      changesMade={hasDataChanged}
      open={isModalOpen}
      okButtonProps={{
        disabled: !hasDataChanged,
      }}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleFinish}
        labelCol={{ span: 8 }}
        autoComplete='off'
      >
        <Form.Item
          name='subject'
          label={translations.reminderRuns.configurationModal.fields.emailSubject}
          rules={[getRequiredRule(translations.reminderRuns.configurationModal.fields.emailSubject)]}
        >
          <Input autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          name='body'
          label={translations.reminderRuns.configurationModal.fields.reminderBody}
          rules={[getRequiredRule(translations.reminderRuns.configurationModal.fields.reminderBody)]}
        >
          <TextEditor editMode textContent={bodyState} setTextContent={reminderBodyValue} />
        </Form.Item>
      </Form>
    </ModalWithCloseConfirm>
  );
};
