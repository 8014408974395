import { print } from 'graphql';
import { omit } from 'lodash';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { OfflineUpsert } from '../../../graph/queries/general';
import { GetNotes } from '../../../graph/queries/notes';
import { Note, NoteUpsert, NoteType } from '../../../graph/types';
import { getConnectionId } from '../../../hooks/authHooks';
import { RxNote } from '../schemas/noteSchema';
import { RxPatient } from '../schemas/patientSchema';
import { getOfflineId, getUpdatedDate, OfflineUpsertProps, queryBuilder, replaceExistingRecords } from './queryUtils';
import { v4 as uuid } from 'uuid';
import { RxContact } from '../schemas/contactSchema';
import { addOfflineNoteProperties } from '../../../util/offline/offlineQueryUtils';

export const noteQueryBuilder = (organizationId: string) => queryBuilder<Note>(organizationId, GetNotes);

export const getNotePushVariables = (organizationId: string, note: RxNote) => ({
  variables: {
    organizationId,
    upsert: {
      record: {
        type_name: 'upsertNote',
        offline_id: note.upsert_offline_id,
        connection_id: getConnectionId(),
        instruction: JSON.stringify({
          ...note.upsert,
          id: note.is_new ? ID_FOR_OBJECT_CREATION : note.id,
        }),
      },
    },
  },
});

export const notePushBuilder = (organizationId: string) => (note: RxNote) => ({
  query: print(OfflineUpsert),
  ...getNotePushVariables(organizationId, note),
});

export const onNoteUpdate = async (note: Note): Promise<RxNote> => {
  await replaceExistingRecords(note, 'note');
  return addOfflineNoteProperties(note);
};

export const getNoteInsert = (
  noteUpsert: NoteUpsert,
  refData?: NoteType[],
  additionalFields?: {
    patient?: RxPatient;
    contact?: RxContact;
    createdUserName?: string;
  }
): RxNote => {
  const offlineId = getOfflineId();
  const upsert = { ...noteUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
    if (additionalFields?.patient?.is_new) {
      upsert.record.patient_id = undefined;
      upsert.record.offline_patient_id = additionalFields.patient.offline_id;
    }
    if (additionalFields?.contact?.is_new) {
      upsert.record.contact_id = undefined;
      upsert.record.offline_contact_id = additionalFields.contact.offline_id;
    }
  }
  const currentDate = getUpdatedDate();
  const type_name = refData?.find(({ id }) => id === upsert.record?.type_id)?.name;

  return {
    id: offlineId,
    offline_id: offlineId,
    upsert,
    upsert_offline_id: uuid(),
    contact_id: additionalFields?.contact?.id || '',
    offline_contact_id: additionalFields?.contact?.offline_id,
    patient_id: additionalFields?.patient?.id || '',
    offline_patient_id: additionalFields?.patient?.offline_id,
    preview: upsert.record?.preview,
    value: upsert.record?.value,
    locked: false,
    hidden: false,
    date: upsert.record?.date,
    created_user_name: additionalFields?.createdUserName,
    updated: currentDate,
    is_new: true,
    type_id: upsert.record?.type_id,
    type_name,
  };
};

export const getNoteUpdate = (rxNote: RxNote, noteUpsert: NoteUpsert) => {
  const newUpsert = { ...rxNote.upsert, ...noteUpsert };

  if (newUpsert.record) {
    newUpsert.record.offline_id = rxNote.upsert?.record?.offline_id;
    newUpsert.record.patient_id = rxNote.upsert?.record?.patient_id;
    newUpsert.record.offline_patient_id = rxNote.upsert?.record?.offline_patient_id;
  }

  const currentDate = getUpdatedDate();

  const propsToUpdate: OfflineUpsertProps<RxNote> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    ...omit(newUpsert.record ?? {}, 'draft', 'contact_id', 'patient_id'),
    hidden: !!newUpsert.void,
    updated: currentDate,
  };

  return propsToUpdate;
};
