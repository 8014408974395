import {
  PatientOwnershipOwnerUpsert,
  PatientOwnershipUpsert,
  PatientUpsert,
  RelatedOwner,
  RelatedOwnershipEntryAll,
} from '../../../../graph/types';

const buildOwnerInsert = (owner: RelatedOwner) => {
  return {
    related_contact_id: isNaN(+(owner.contact_id ?? '.')) ? undefined : owner.contact_id,
    offline_related_contact_id: owner.offline_contact_id,
    percentage: owner.percentage || '0',
    primary: owner.primary || false,
    void: false,
  };
};

const createNewOwnershipToInsert = (ownership: RelatedOwnershipEntryAll) => {
  const owners = ownership.owner || [];
  const ownerInserts: PatientOwnershipOwnerUpsert[] = owners.map(buildOwnerInsert);

  return {
    owner: ownerInserts,
    effective_date: ownership.effective_date,
    void: false,
  };
};

const createOwnershipToDelete = (ownership: RelatedOwnershipEntryAll) => {
  return {
    syndicate_id: ownership.syndicate_id,
    void: true,
  };
};

const createPatientUpsert = (patientId: string, ownerships: PatientOwnershipUpsert[]) => {
  return {
    id: patientId,
    ownership: ownerships,
  };
};

const buildPatientUpsertForUpdatingOwnership = (
  ownership: RelatedOwnershipEntryAll,
  patientId: string
): PatientUpsert => {
  const newOwnershipToInsert = createNewOwnershipToInsert(ownership);
  const oldOwnershipToDelete = createOwnershipToDelete(ownership);

  return createPatientUpsert(patientId, [oldOwnershipToDelete, newOwnershipToInsert]);
};

const buildPatientUpsertForCreatingNewOwnership = (
  ownership: RelatedOwnershipEntryAll,
  patientId: string
): PatientUpsert => {
  const newOwnershipToInsert = createNewOwnershipToInsert(ownership);

  return createPatientUpsert(patientId, [newOwnershipToInsert]);
};

export const buildPatientUpsertForDeletingOwnership = (
  ownership: RelatedOwnershipEntryAll,
  patientId: string
): PatientUpsert => {
  const ownershipToDelete = createOwnershipToDelete(ownership);
  return createPatientUpsert(patientId, [ownershipToDelete]);
};

export const buildPatientUpsert = (ownership: RelatedOwnershipEntryAll, patientId: string): PatientUpsert => {
  return ownership.syndicate_id
    ? buildPatientUpsertForUpdatingOwnership(ownership, patientId)
    : buildPatientUpsertForCreatingNewOwnership(ownership, patientId);
};
