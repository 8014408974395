import React, { useEffect, useState } from 'react';
import { getTiffImageSrc } from '../../../util/tiffUtil';
import { Loading } from '../../Loading/Loading';
import { ImagePreview } from '../../../globalStyles.style';

export const TiffPreview: React.FC<{ url: string }> = ({ url }) => {
  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    const generateImageSrc = async () => {
      const src = await getTiffImageSrc(url);
      setImageSrc(src);
    };
    generateImageSrc();
  }, [url, setImageSrc]);

  if (imageSrc === undefined) {
    return <Loading />;
  }

  return <ImagePreview src={imageSrc} />;
};
