import { Auth } from 'aws-amplify';
import { translations } from '../constants/translations';
import { User } from '../graph/types';

export const isUserActive = async (user?: User) => {
  if (user?.inactive) {
    await Auth.signOut();
    throw new Error(translations.login.inactiveUserError);
  }
  return true;
};
