import { UpsertPaymentPortal } from '../../../graph/queries/paymentPortal';
import { MutationCreatePaymentPortalArgs, PaymentPortalResult } from '../../../graph/types';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';

export const useCreatePaymentPortal = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'createPaymentPortal', MutationCreatePaymentPortalArgs>(
    UpsertPaymentPortal
  );

  const createPaymentPortal = async (organizationId: string, practiceId: string, subdomain: string) => {
    const response = await mutateFunction({
      variables: {
        organizationId,
        practiceId,
        paymentPortalInfo: {
          subdomain,
          confirm_only: false,
        },
      },
    });

    return response?.data?.createPaymentPortal as PaymentPortalResult;
  };

  return createPaymentPortal;
};

export const useValidatePaymentPortal = () => {
  const [mutateFunction] = useOfflineErrorSkipMutation<'createPaymentPortal', MutationCreatePaymentPortalArgs>(
    UpsertPaymentPortal
  );

  const validatePaymentPortal = async (organizationId: string, practiceId: string, subdomain: string) => {
    const response = await mutateFunction({
      variables: {
        organizationId,
        practiceId,
        paymentPortalInfo: {
          subdomain,
          confirm_only: true,
        },
      },
    });

    return response?.data?.createPaymentPortal as PaymentPortalResult;
  };

  return validatePaymentPortal;
};
