import { useEffect } from 'react';
import { useLogout } from '../../hooks/authHooks';

export const Logout = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};
