import { Tooltip } from 'antd';
import React from 'react';

interface TextListWithDotsProps {
  textList: string[];
  displayingElements?: number;
}

export const TextListWithDots: React.FC<TextListWithDotsProps> = ({ textList, displayingElements = 3 }) => {
  const tooltipText = textList.join(', ');
  const displayingText =
    textList.length > displayingElements ? textList.slice(0, displayingElements).join(', ') + '...' : tooltipText;
  return <Tooltip title={tooltipText}>{displayingText}</Tooltip>;
};
