import { Contact, ContactStatusType, ContactStatusTypeDto } from '../../../graph/types';
import { TagColor } from '../../../constants/tagColor';
import { getTag } from '../../../util/tags';
import { getStringCompareFunctionFor } from '../../../util/filterAndSorting';
import { translations } from '../../../constants/translations';

export class ContactStatusHelper {
  readonly statusTypeMap: { [id: string]: ContactStatusType };
  readonly tagColors: TagColor[];

  constructor(status_type?: ContactStatusType[]) {
    this.tagColors = Object.values(TagColor);
    this.statusTypeMap = {};
    (status_type ?? []).forEach((statusType) => {
      this.statusTypeMap[statusType.id] = statusType;
    });
  }

  getTag(contact: { contact_status_id: string; contact_status_name: string }, activeTags: ContactStatusType[]) {
    const tagColor = this.tagColors[+contact.contact_status_id % this.tagColors.length];
    const tagLabel =
      activeTags.find((tag) => tag.id === contact.contact_status_id)?.name ??
      translations.shared.getVoidRecord(contact.contact_status_name);
    return getTag(tagColor, tagLabel, contact.contact_status_id);
  }

  getSortOrder = (contact: Contact) => {
    const contactStatusType = this.statusTypeMap[contact.contact_status_id];
    return contactStatusType?.sort_order ?? -1;
  };

  sorter = (contact1: Contact, contact2: Contact) => {
    const sortOrder1 = this.getSortOrder(contact1);
    const sortOrder2 = this.getSortOrder(contact2);

    let order = sortOrder1 - sortOrder2;
    if (!order) {
      order = getStringCompareFunctionFor<Contact>('contact_status_name')(contact1, contact2);
    }
    return order;
  };
}

export class ContactStatusHelperDto {
  readonly statusTypeMap: { [id: string]: ContactStatusTypeDto };
  readonly tagColors: TagColor[];

  constructor(status_type?: ContactStatusTypeDto[]) {
    this.tagColors = Object.values(TagColor);
    this.statusTypeMap = {};
    (status_type ?? []).forEach((statusType) => {
      this.statusTypeMap[statusType.id] = statusType;
    });
  }

  getTag(contact: { contact_status_id: string; contact_status_name: string }, activeTags: ContactStatusTypeDto[]) {
    const tagColor = this.tagColors[+contact.contact_status_id % this.tagColors.length];
    const tagLabel =
      activeTags.find((tag) => tag.id === contact.contact_status_id)?.name ??
      translations.shared.getVoidRecord(contact.contact_status_name);
    return getTag(tagColor, tagLabel, contact.contact_status_id);
  }

  getSortOrder = (contact: Contact) => {
    const contactStatusType = this.statusTypeMap[contact.contact_status_id];
    return contactStatusType?.sort_order ?? -1;
  };

  sorter = (contact1: Contact, contact2: Contact) => {
    const sortOrder1 = this.getSortOrder(contact1);
    const sortOrder2 = this.getSortOrder(contact2);

    let order = sortOrder1 - sortOrder2;
    if (!order) {
      order = getStringCompareFunctionFor<Contact>('contact_status_name')(contact1, contact2);
    }
    return order;
  };
}
