import React from 'react';
import {
  ReplicationData,
  getReplicationDataWithUnsyncedData,
  useOffline,
  useUnsyncedData,
} from '../../util/offline/offlineUtil';
import { TableWithCustomFiltering } from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import { translations } from '../../constants/translations';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import { TableCellLink } from '../../components/TableLink/TableCellLink';
import styled from 'styled-components';
import { FlexContainer } from '../../globalStyles.style';
import { ContainerWithMargin } from '../../hooks/alertHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import { Tooltip } from 'antd';
import { getContactPushVariables } from '../../services/LocalDatabaseService/queries/contactQueries';
import { getPatientPushVariables } from '../../services/LocalDatabaseService/queries/patientQueries';
import { getNotePushVariables } from '../../services/LocalDatabaseService/queries/noteQueries';
import { ReplicationCollection } from '../../services/LocalDatabaseService/collections';
import { getReminderPushVariables } from '../../services/LocalDatabaseService/queries/reminderQueries';
import { getInvoicePushVariables } from '../../services/LocalDatabaseService/queries/invoiceQueries';
import { getPrescriptionPushVariables } from '../../services/LocalDatabaseService/queries/prescriptionQueries';
import { OfflineUpsert } from '../../graph/queries/general';

const StatusIndicator = styled.div<{ color?: string }>`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: black;
  height: 20px;
  width: 20px;
  background-color: ${({ color = 'white' }) => color};
`;

export const OfflineDiagnostics: React.FC = () => {
  const { enabled } = useOffline();
  const { unsyncedData, hasUnsyncedData } = useUnsyncedData();
  const organizationId = useGetOrganizationIdFromRoute();
  const replicationDataSource = getReplicationDataWithUnsyncedData(unsyncedData);

  const runCorrection = async (collection: string) => {
    const erroredDocs: any[] | undefined = unsyncedData?.[collection];
    const promises: Promise<any>[] = [];
    let upsert: any;

    switch (collection) {
      case ReplicationCollection.Note:
        upsert = getNotePushVariables;
        break;
      case ReplicationCollection.Patient:
        upsert = getPatientPushVariables;
        break;
      case ReplicationCollection.Contact:
        upsert = getContactPushVariables;
        break;
      case ReplicationCollection.Reminder:
        upsert = getReminderPushVariables;
        break;
      case ReplicationCollection.Invoice:
        upsert = getInvoicePushVariables;
        break;
      case ReplicationCollection.Prescription:
        upsert = getPrescriptionPushVariables;
        break;
      default:
        break;
    }

    try {
      erroredDocs?.forEach((document) => {
        const upsertToUse = upsert(organizationId, document);
        promises.push(
          AppSyncService.client.mutate({
            mutation: OfflineUpsert,
            ...upsertToUse,
          })
        );
      });
      await Promise.all(promises);
      showSuccessMessage(translations.offlineDiagnostics.resyncCompleted);
    } catch (e) {
      showErrorMessage(e.message || translations.shared.errorMessage);
    }
  };

  const getStatusIndicator = (property: boolean, record: ReplicationData) => {
    let color: string;
    if (property) {
      color = 'green';
    } else {
      color = 'red';
    }
    if (!enabled) {
      color = 'white';
    }

    const errorMessage = record.error;

    return (
      <FlexContainer alignItems='center'>
        <Tooltip title={errorMessage ?? undefined}>
          <StatusIndicator color={color} />
        </Tooltip>
      </FlexContainer>
    );
  };

  const columns = [
    {
      title: translations.offlineDiagnostics.table.columns.collection,
      width: 100,
      dataIndex: 'collection',
    },
    {
      title: translations.offlineDiagnostics.table.columns.initialLoaded,
      dataIndex: 'initialLoaded',
      width: 100,
      render: getStatusIndicator,
    },
    {
      title: translations.offlineDiagnostics.table.columns.indexesBuilt,
      dataIndex: 'indexesBuilt',
      width: 100,
      render: getStatusIndicator,
    },
    {
      title: translations.offlineDiagnostics.table.columns.error,
      dataIndex: 'error',
      width: 100,
      render: (error: string | boolean, record: ReplicationData) => getStatusIndicator(!error, record),
    },
    ...(hasUnsyncedData
      ? [
          {
            title: translations.offlineDiagnostics.table.columns.action,
            key: 'action',
            width: 200,
            render: (record: ReplicationData) => {
              return (
                record.unsyncedData && (
                  <TableCellLink onClick={() => runCorrection(record.collection)}>
                    {translations.offlineDiagnostics.table.runCorrection}
                  </TableCellLink>
                )
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <StyledPageHeader title={translations.offlineDiagnostics.title} />
      <TableWithCustomFiltering
        columns={columns}
        dataSource={replicationDataSource}
        tableKey={TableKey.OfflineDiagnostics}
        pagination={false}
        rowKey={'collection'}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {record.unsyncedData?.map((uData: any) => {
                return (
                  <ContainerWithMargin margin='1em .4em' key={uData.id}>
                    <span>{`${uData.id}:`}</span>
                    <span> {JSON.stringify(uData)} </span>
                  </ContainerWithMargin>
                );
              })}
            </>
          ),
          rowExpandable: (record) => !!record.unsyncedData,
        }}
      />
    </>
  );
};
