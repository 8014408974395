import React, { useContext, useMemo, useState } from 'react';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import { Button, DatePicker, Form, Tabs } from 'antd';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getRequiredRule } from '../../util/forms';
import TabsWithRouting, { TabsWithRoutingProps } from '../../components/TabsWithRouting/TabsWithRouting';
import { getRouteWithOrganizationId, routes } from '../../constants/routes';
import styled from 'styled-components';
import { useDefaultPracticeId, useGetPracticeWithSettings } from '../../hooks/ajax/practice/practiceHooks';
import InterestRunTabContent from './InterestRunTabContent';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { InterestRunConfigModal } from './InterestRunConfigModal';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getPracticeSetting } from '../Contacts/ViewContact/statementUtils';
import { useGetInterestRunsPg, useUpsertInterestRun } from '../../hooks/ajax/interestRuns/interestRunHooks';
import { InterestRun, InterestRunContacts, Mutation, MutationUpsertInterestRunArgs } from '../../graph/types';
import { InterestRunsContext, InterestRunsContextProvider } from './store/state';
import { setSelectedContactRuns } from './store/actions';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { InterestSettingValue } from '../../components/InterestSettingsForm/InterestSettingsForm';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { upsertDateFormat } from '../../constants/formats';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ApolloCache, FetchResult, Reference } from '@apollo/client';
import { InterestRunFields } from '../../graph/fragments';
import { getInterestSettingValueFromJson } from '../../components/InterestSettingsForm/InterestSettingsFormUtils';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Container = styled.div`
  padding: 0 1rem;
`;

export interface InterestRunsDateForm {
  date: dayjs.Dayjs;
}

interface TabData {
  practiceId: string;
  date: dayjs.Dayjs;
  key: string;
  interestRunId: string;
}

const InterestRuns: React.FC = () => {
  const [form] = Form.useForm();
  const practiceId = useDefaultPracticeId();
  const organizationId = useGetOrganizationIdFromRoute();
  const { practice } = useGetPracticeWithSettings(organizationId, practiceId);
  const { interestRuns } = useGetInterestRunsPg(organizationId, practiceId);
  const [interestRunUpsert] = useUpsertInterestRun(organizationId, practiceId);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const { navigateTo } = useNavigationToRoute();

  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const { state, dispatch } = useContext(InterestRunsContext);

  const deleteInterestRun = async (id: string) => {
    setIsSaving(true);
    await interestRunUpsert({
      variables: { organizationId, interestRun: { id, void: true } },
      update: (cache: ApolloCache<any>, _, options: { variables?: MutationUpsertInterestRunArgs }) => {
        cache.modify({
          fields: {
            getInterestRunsPg(interestRunsReferences: Reference[] = []) {
              const updated = interestRunsReferences.filter((run) => {
                const [_, runId] = run.__ref.split(':');
                return runId !== options.variables?.interestRun.id;
              });
              return updated;
            },
          },
        });
        navigateTo(
          getRouteWithOrganizationId(`${routes.interestRuns}/${practiceId}/${tabData[1].interestRunId}`, organizationId)
        );
      },
    });
    setIsSaving(false);
  };

  const dateFormInitialValues: InterestRunsDateForm = {
    date: dayjs(),
  };

  const tabData: TabData[] = useMemo(
    () =>
      interestRuns
        ?.map((run) => {
          return {
            practiceId,
            date: dayjs(run.run_date),
            key: run.id ?? '0',
            interestRunId: run.id ?? '0',
          };
        })
        .sort((a: TabData, b: TabData) => b.date.valueOf() - a.date.valueOf()) ?? [],
    [practiceId, interestRuns]
  );

  const routePerTabKey: TabsWithRoutingProps['routePerTabKey'] = {};
  tabData.forEach((tabDataItem) => {
    routePerTabKey[tabDataItem.key] = `${routes.interestRuns}/${practiceId}/${tabDataItem.key}`;
  });

  const handleTabClick = (key: string) => {
    setHasCompleted(false);
    dispatch(setSelectedContactRuns([]));
    navigateTo(routePerTabKey[key] as string);
  };

  const isDateOverlap = (selectedDate: dayjs.Dayjs): boolean => {
    const hasLaterDate = (interestRun: InterestRun) => dayjs(interestRun.run_date).isSameOrAfter(selectedDate, 'day');
    return !!interestRuns && interestRuns.some(hasLaterDate);
  };

  const createInterestRun = async (values: InterestRunsDateForm, modalValues: InterestSettingValue) => {
    setIsSaving(true);
    if (isDateOverlap(values.date)) {
      setIsSaving(false);
      showErrorMessage(translations.interestRuns.overlap);
      return;
    }

    const response = await interestRunUpsert({
      variables: {
        organizationId,
        interestRun: {
          record: {
            run_date: values.date.format(upsertDateFormat),
            practice_id: practiceId,
          },
          configRecord: {
            rate: modalValues.interestRate.toString(),
            compound: modalValues.isCompounded,
            charge_free: modalValues.chargeFreePeriod,
          },
        },
      },
      update: (
        cache: ApolloCache<any>,
        response: Omit<FetchResult<Pick<Mutation, 'upsertInterestRun'>>, 'context'>,
        options: { variables?: MutationUpsertInterestRunArgs }
      ) => {
        const createdInterestRun = response.data?.upsertInterestRun;
        const settings = getInterestSettingValueFromJson(interestSetting.value);
        const referenceData: InterestRun = {
          id: createdInterestRun?.id,
          run_date: options.variables?.interestRun.record?.run_date ?? dayjs().format(),
          rate: options.variables?.interestRun.configRecord?.rate ?? settings.interestRate.toString(),
          compound: createdInterestRun?.compound ?? settings.isCompounded,
          charge_free: options.variables?.interestRun.configRecord?.charge_free ?? settings.chargeFreePeriod,
          has_charge: createdInterestRun?.has_charge ?? false,
          number: createdInterestRun?.number,
          __typename: 'InterestRun',
        };
        cache.modify({
          fields: {
            getInterestRunsPg(interestRunsReferences: Reference[] = []) {
              const newInterestRunReference = cache.writeFragment({
                data: referenceData,
                fragment: InterestRunFields,
                fragmentName: 'InterestRunFields',
              });
              return [newInterestRunReference, ...interestRunsReferences];
            },
          },
        });
      },
    });

    if (response) {
      const tabId = response.data?.upsertInterestRun?.id;
      navigateTo(getRouteWithOrganizationId(`${routes.interestRuns}/${practiceId}/${tabId}`, organizationId));
    }

    setIsSaving(false);
  };

  const interestSetting = getPracticeSetting(PracticeSettingsNameKey.Interest, practice);

  const completeRun = async (runToBeCompleted: InterestRunContacts) => {
    setIsSaving(true);
    const selectedIds = state.selectedRuns.map(({ contact_id }) => contact_id);
    await interestRunUpsert({
      variables: {
        organizationId,
        interestRun: {
          id: runToBeCompleted?.id,
          fnCharge: selectedIds,
        },
      },
    });

    setHasCompleted(true);
    dispatch(setSelectedContactRuns([]));
    setIsSaving(false);
    state?.refetchRun?.();
  };

  const lastRunDate = interestRuns?.length ? interestRuns?.[0].run_date : dayjs().subtract(10, 'years');

  return (
    <SaveSpinner isSaving={isSaving}>
      <StyledPageHeaderWithMargin title={translations.interestRuns.title} />
      <Container>
        <Form
          onFinish={() => setIsModalOpen(true)}
          form={form}
          initialValues={dateFormInitialValues}
          layout='inline'
          style={{ marginBottom: '0.5rem' }}
          autoComplete='off'
        >
          <Form.Item
            label={translations.interestRuns.dateSelectLabel}
            name='date'
            rules={[
              getRequiredRule(translations.interestRuns.selectDate),
              {
                validator: (_, value: dayjs.Dayjs) => {
                  const isBeforeRunDate = value?.isSameOrBefore(lastRunDate, 'day');
                  if (isBeforeRunDate) {
                    return Promise.reject(translations.interestRuns.overlap);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              placeholder={translations.interestRuns.selectDate}
              disabledDate={(date) => date?.isSameOrBefore(lastRunDate, 'day') || date?.isAfter(dayjs())}
            />
          </Form.Item>
          <Form.Item>
            <Button type={'primary'} htmlType={'submit'}>
              {translations.interestRuns.createRun}
            </Button>
          </Form.Item>
          {interestSetting && (
            <InterestRunConfigModal
              isModalOpen={isModalOpen}
              setModalOpen={(value) => setIsModalOpen(value)}
              interestSettings={interestSetting}
              afterConfigFunction={(values) => createInterestRun(form.getFieldsValue(), values)}
              isCreating
            />
          )}
        </Form>

        {tabData.length ? (
          <TabsWithRouting
            routePerTabKey={routePerTabKey}
            type='card'
            hideAdd
            forcedRouteIfNoTabSelected={routes.interestRuns}
            onTabClick={handleTabClick}
          >
            {tabData.map((tabDataItem, index) => (
              <Tabs.TabPane tab={tabDataItem.date.format(dateFormat)} key={tabDataItem.key}>
                <InterestRunTabContent
                  interestRunId={tabDataItem.interestRunId}
                  isDeletable={!index}
                  deleteInterestRun={deleteInterestRun}
                  completeRun={completeRun}
                  hasCompleted={hasCompleted}
                />
              </Tabs.TabPane>
            ))}
          </TabsWithRouting>
        ) : null}
      </Container>
    </SaveSpinner>
  );
};

const InterestRunsWithContext = () => (
  <InterestRunsContextProvider>
    <InterestRuns />
  </InterestRunsContextProvider>
);

export default InterestRunsWithContext;
