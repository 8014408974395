import { Checkbox, Input, Modal } from 'antd';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import StripeTokenizer, { StripeTokenizerExtraDataProps } from '../StripeTokenizer/StripeTokenizer';
import { CardConnectTokenizer } from '../CardConnectTokenizer/CardConnectTokenizer';
import { CreditCardTokenInfo } from '../../hooks/stripeHooks';
import { CardConnectManualSettings, DemographicReferenceData } from '../../graph/types';
import { translations } from '../../constants/translations';
import { ExclamationCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';
import { useOrganizationContext } from '../../contexts/organization/state';
import { AddressType } from '../../classes/upsertGenerators/AddressUpsertGenerator';

const WarningIcon = styled(ExclamationCircleFilled)`
  margin-right: 12px;
  color: #faad14;
  font-size: 16pt;
`;

const JustifiedText = styled.p`
  text-align: justify;
`;

interface AddCardModalProps {
  isStripeProcessor: boolean;
  visible: boolean;
  onClose: () => void;
  onSave: (cc: CreditCardTokenInfo, primary: boolean, cardNote?: string) => void;
  onError?: (err?: string) => void;
  tokenizerExtraData: StripeTokenizerExtraDataProps;
  cardConnectManual?: CardConnectManualSettings;
  addressDemographics?: DemographicReferenceData;
  errorMessage?: string;
}

const AddCardModal: React.FC<AddCardModalProps> = ({
  isStripeProcessor,
  visible,
  onSave,
  onClose,
  onError,
  tokenizerExtraData,
  cardConnectManual,
  addressDemographics,
  errorMessage,
}: AddCardModalProps) => {
  const [creditCard, setCreditCard] = useState<CreditCardTokenInfo>();
  const [hasPermissionToSave, setHasPermissionToSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [cardNote, setCardNote] = useState<string>();

  const {
    state: { organization },
  } = useOrganizationContext();
  const practice = organization?.practice?.find((p) => p.id === organization.default_practice_id);
  const practiceAddress = useMemo(
    () => practice?.address?.find((a) => a.address_type_id === AddressType.Physical),
    [practice?.address]
  );

  const canSave = useMemo(() => creditCard && hasPermissionToSave, [creditCard, hasPermissionToSave]);

  useEffect(() => {
    if (visible) {
      onError?.(undefined);
      resetFields();
    }
  }, [visible, onError]);

  const resetFields = () => {
    setCreditCard(undefined);
    setHasPermissionToSave(false);
    setIsPrimary(false);
    setCardNote(undefined);
  };

  const handleSave = async () => {
    if (creditCard) {
      setIsSaving(true);
      await onSave(creditCard, isPrimary, cardNote);
      setIsSaving(false);
    }
  };

  const handleHasPermissionChange = (e: CheckboxChangeEvent) => {
    setHasPermissionToSave(e.target.checked);
  };

  const handlePrimaryChange = (e: CheckboxChangeEvent) => {
    setIsPrimary(e.target.checked);
  };

  const handleCardNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardNote(e.target.value);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={translations.viewContactPage.storedCardsModal.addCardModal.title}
      open={visible}
      zIndex={6000}
      onCancel={handleCancel}
      onOk={handleSave}
      okText={translations.viewContactPage.storedCardsModal.addCardModal.okButton}
      okButtonProps={{
        disabled: !canSave,
      }}
      destroyOnClose
    >
      <SaveSpinner isSaving={visible && isSaving}>
        {errorMessage && <i style={{ color: 'red' }}>{errorMessage}</i>}
        <JustifiedText>
          <span>
            <WarningIcon />
          </span>
          <span>{translations.paymentModal.fields.saveCardConfirmMessage}</span>
        </JustifiedText>
        <JustifiedText>
          <Checkbox checked={hasPermissionToSave} onChange={handleHasPermissionChange}>
            {translations.paymentModal.fields.saveCardConfirmCheckboxMessage}
          </Checkbox>
        </JustifiedText>
        <JustifiedText>
          <Checkbox checked={isPrimary} onChange={handlePrimaryChange}>
            {translations.viewContactPage.storedCardsModal.addCardModal.isPrimary}
          </Checkbox>
        </JustifiedText>
        <MaxLengthFormItem
          valuePropName='value'
          maxLength={100}
          label={translations.viewContactPage.storedCardsModal.addCardModal.cardNote}
        >
          <Input value={cardNote} onChange={handleCardNoteChange} />
        </MaxLengthFormItem>
        {isStripeProcessor ? (
          <StripeTokenizer
            stripeTokenizerCountryId={practiceAddress?.country_id || undefined}
            setCreditCard={setCreditCard}
            tokenizerExtraData={tokenizerExtraData}
            addressDemographics={addressDemographics}
            onError={onError}
          />
        ) : (
          cardConnectManual && (
            <CardConnectTokenizer
              setCreditCard={setCreditCard}
              site={cardConnectManual.manualCardSite}
              port={cardConnectManual.manualCardPort}
            />
          )
        )}
      </SaveSpinner>
    </Modal>
  );
};

export default AddCardModal;
