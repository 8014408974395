import gql from 'graphql-tag';

import { LabAssignmentResultFields, UnassignedLabResultsFields } from '../fragments/labLinkFragments';

export const GetUnassignedLabResults = gql`
  query getUnassignedLabs($organizationId: ID!) {
    getUnassignedLabs(organizationId: $organizationId) {
      ...UnassignedLabResultsFields
    }
  }
  ${UnassignedLabResultsFields}
`;

export const UpsertLabAssignments = gql`
  mutation upsertLabAssignments($organizationId: ID!, $labAssignments: LabRequestAssignmentUpsert!) {
    upsertLabAssignments(organizationId: $organizationId, labAssignments: $labAssignments) {
      ...LabAssignmentResultFields
    }
  }
  ${LabAssignmentResultFields}
`;
