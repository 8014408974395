import { translations } from './translations';

const postalZipPatterns = {
  nnnn: /^\d{4}$/,
  nnnnn: /^\d{5}$/,
  ad_method: /^AD\d{3}$/,
  nnnn_or_annnnaaa: /^(\d{4})|([a-zA-Z]\d{4}[a-zA-Z]{3})$/,
  az_method: /^AZ\s\d{4}$/,
  nnn_or_nnnn: /^(\d{3})|(\d{4})$/,
  bb_method: /^BB\d{5}$/,
  nnnnnn: /^\d{6}$/,
  aasnn_or_aasaa: /^([a-zA-Z]{2}\s\d{2})|([a-zA-Z]{2}\s\[a-zA-Z]{2})$/,
  nnnnn_nnn: /^\d{5}-\d{3}$/,
  aannnn: /^[a-zA-Z]{4}\d{4}$/,
  anasnan: /^[a-zA-Z]\d[a-zA-Z]\s\d[a-zA-Z]\d$/,
  ky_method: /^KY-\d{4}$/,
  nnnnnnn: /^\d{7}$/,
  nnnsnn: /^\d{3}\s\d{2}$/,
  fo_method: /^FO\d{3}$/,
  aa_nnnn_nnnn: /^[a-zA-Z]{2}-\d{4}-\d{4}$/,
  aansnaa_or_aannsnaa: /^([a-zA-Z]{2}\d\s\d[a-zA-Z]{2})|([a-zA-Z]{2}\d{2}\s\d[a-zA-Z]{2})$/,
  nnn: /^\d{3}$/,
  aannnn_or_nnnnn: /^([a-zA-Z]{2}\d{4})|(\d{4})$/,
  nnnnnn_or_nnnsnnn: /^(\d{6})|(\d{6}\s\d{6})$/,
  nnnnn_nnnnn: /^\d{5}-\d{5}$/,
  ie_method: /^[a-zA-Z]\d{2}\s([a-zA-Z]|\d){4}$/,
  nn: /^\d{2}$/,
  nnn_nnnn: /^\d{3}-\d{4}$/,
  je_method: /^(JE\d\s\d[a-zA-Z]{2})|(JE\d{2}\s\d[a-zA-Z]{2})$/,
  lv_method: /^LV-\d{4}$/,
  nnnnn_or_nnnnsnnnn: /^\d{5}|(\d{4}\s\d{4})$/,
  lt_method: /^LT\d{5}$/,
  aaasnnnn: /^[a-zA-Z]{3}\s\d{4}$/,
  im_method: /^(IM\d\s\d[a-zA-Z]{2})|(IM\d{2}\s\d[a-zA-Z]{2})$/,
  md_method: /^MD\d{4}$/,
  nnnnsaa: /^\d{4}\s[a-zA-Z]{2}$/,
  nn_nnn: /^\d{2}-\d{3}$/,
  nnnn_nnn: /^\d{4}-\d{3}$/,
  aaaasnaa: /^[a-zA-Z]{4}\s\d[a-zA-Z]{2}$/,
  kn_method: /^KN\d{4}$/,
  lc_method: /^LC\d{2}\s\d{3}$/,
  vc_method: /^VC\d{4}$/,
  ws_method: /^WS\d{4}$/,
  nnnnn_nnnn_or_nnnnn: /^(\d{5}-\d{4})|(\d{5})$/,
  aasnnnnn: /^[a-zA-Z]{2}\s\d{5}$/,
  annn: /^[a-zA-Z]\d{3}$/,
  tw_method: /^(\d{3})|(\d{3}-\d{2})|(\d{3}-\d{3})$/,
  uk_method:
    /^([a-zA-Z]\d)|([a-zA-Z]\d{2})|([a-zA-Z]{2}\d)|([a-zA-Z]{2}\d{2})|([a-zA-Z]\d[a-zA-Z])|([a-zA-Z]{2}\d[a-zA-Z])|([a-zA-Z]{3})$/,
  vg_method: /^VG\d{4}$/,
};

export type CountryFormKey = keyof typeof countryFormValues;

export const countryFormValues = {
  CTRY_AFG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ALA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ALB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_DZA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ASM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_AND: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.ad_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_ad_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_AGO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_AIA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ATA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ATG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish_or_dependency,
      placeholder: translations.addressBlockComponent.placeholder.parish_or_dependency,
    },
  },
  CTRY_ARG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn_or_annnnaaa,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn_or_annnnaaa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ARM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_ABW: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_AUS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state_or_territory,
      placeholder: translations.addressBlockComponent.placeholder.state_or_territory,
    },
  },
  CTRY_AUT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_AZE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.az_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_az_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district_or_city,
      placeholder: translations.addressBlockComponent.placeholder.district_or_city,
    },
  },
  CTRY_BHR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn_or_nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn_or_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_BGD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_BRB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.bb_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_bb_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_BLR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.oblast,
      placeholder: translations.addressBlockComponent.placeholder.oblast,
    },
  },
  CTRY_BEL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_BLZ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_BEN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_BMU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aasnn_or_aasaa,
      message: translations.addressBlockComponent.validationMessage.postalCode_aasnn_or_aasaa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_BTN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_BOL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_BES: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.special_municipality,
      placeholder: translations.addressBlockComponent.placeholder.special_municipality,
    },
  },
  CTRY_BIH: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.entity,
      placeholder: translations.addressBlockComponent.placeholder.entity,
    },
  },
  CTRY_BWA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_BVT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_BRA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.cep,
      pattern: postalZipPatterns.nnnnn_nnn,
      message: translations.addressBlockComponent.validationMessage.cep_nnnnn_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_IOT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_BRN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aannnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_aannnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_BGR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_BFA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_BDI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_KHM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CMR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_CANADA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.anasnan,
      message: translations.addressBlockComponent.validationMessage.canada,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CPV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_CYM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.ky_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_ky_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CAF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.prefecture,
      placeholder: translations.addressBlockComponent.placeholder.prefecture,
    },
  },
  CTRY_TCD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CHL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_CHN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province_or_region,
      placeholder: translations.addressBlockComponent.placeholder.province_or_region,
    },
  },
  CTRY_CXR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CCK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_COL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_COM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.island,
      placeholder: translations.addressBlockComponent.placeholder.island,
    },
  },
  CTRY_COG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_COK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CRI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CIV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_HRV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_CUB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CUW: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_CYP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_CZE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalRoutingNumber,
      pattern: postalZipPatterns.nnnsnn,
      message: translations.addressBlockComponent.validationMessage.postalRoutingNumber_nnnsnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_COD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_DNK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_DJI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_DMA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_DOM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_TLS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_ECU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_EGY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_SLV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_GNQ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ERI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_EST: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_ETH: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.regional_state,
      placeholder: translations.addressBlockComponent.placeholder.regional_state,
    },
  },
  CTRY_FLK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_FRO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.fo_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_fo_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_FJI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_FIN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_FRA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_GUF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PYF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ATF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GAB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GMB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_GEO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_DEU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.plz,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.plz_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_GHA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aa_nnnn_nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_aa_nnnn_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_GIB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GRC: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnsnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnsnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.administrative_region,
      placeholder: translations.addressBlockComponent.placeholder.administrative_region,
    },
  },
  CTRY_GRL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GRD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish_or_dependency,
      placeholder: translations.addressBlockComponent.placeholder.parish_or_dependency,
    },
  },
  CTRY_GLP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GUM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GTM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_GGY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aansnaa_or_aannsnaa,
      message: translations.addressBlockComponent.validationMessage.postalCode_aansnaa_or_aannsnaa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_GIN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.administrative_region,
      placeholder: translations.addressBlockComponent.placeholder.administrative_region,
    },
  },
  CTRY_GNB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_GUY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_HTI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_HMD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_HND: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aannnn_or_nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_aannnn_or_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_HKG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_HUN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county_or_city,
      placeholder: translations.addressBlockComponent.placeholder.county_or_city,
    },
  },
  CTRY_ISL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_IND: {
    postalZip: {
      label: translations.addressBlockComponent.fields.pinCode,
      pattern: postalZipPatterns.nnnnnn_or_nnnsnnn,
      message: translations.addressBlockComponent.validationMessage.pinCode_nnnnnn_or_nnnsnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state_or_territory,
      placeholder: translations.addressBlockComponent.placeholder.state_or_territory,
    },
  },
  CTRY_IDN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_IRN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn_nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_IRQ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_IRL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalAddress,
      pattern: postalZipPatterns.ie_method,
      message: translations.addressBlockComponent.validationMessage.postalAddress_ie_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_ISR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_ITA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.cap,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.cap_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_JAM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_JPN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn_nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.prefecture,
      placeholder: translations.addressBlockComponent.placeholder.prefecture,
    },
  },
  CTRY_JEY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.je_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_je_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_JOR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_KAZ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_KEN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_KIR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.island_group,
      placeholder: translations.addressBlockComponent.placeholder.island_group,
    },
  },
  CTRY_XKX: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_KWT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_KGZ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_LAO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_LVA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.lv_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_lv_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_LBN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn_or_nnnnsnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn_or_nnnnsnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_LSO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_LBR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_LBY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.popularate,
      placeholder: translations.addressBlockComponent.placeholder.popularate,
    },
  },
  CTRY_LIE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_LTU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.lt_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_lt_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_LUX: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.canton,
      placeholder: translations.addressBlockComponent.placeholder.canton,
    },
  },
  CTRY_MAC: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MKD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_MDG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MWI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_MYS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state_or_territory,
      placeholder: translations.addressBlockComponent.placeholder.state_or_territory,
    },
  },
  CTRY_MDV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.atoll,
      placeholder: translations.addressBlockComponent.placeholder.atoll,
    },
  },
  CTRY_MLI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_MLT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aaasnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_aaasnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.local_council,
      placeholder: translations.addressBlockComponent.placeholder.local_council,
    },
  },
  CTRY_IMN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.im_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_im_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MHL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.island_group,
      placeholder: translations.addressBlockComponent.placeholder.island_group,
    },
  },
  CTRY_MTQ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MRT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_MUS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_MYT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MEX: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_FSM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_MDA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.md_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_md_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_MCO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_MNG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MNE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_MSR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MAR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_MOZ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MMR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_NAM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_NRU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_NPL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_NLD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnsaa,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnsaa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_NCL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_NZL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_NIC: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_NER: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_NGA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_NIU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_NFK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PRK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MNP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_NOR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_OMN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_PAK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PLW: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_PSE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PAN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PNG: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PRY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_PER: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_PHL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_PCN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_POL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nn_nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nn_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.voivodeship,
      placeholder: translations.addressBlockComponent.placeholder.voivodeship,
    },
  },
  CTRY_PRT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn_nnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn_nnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_PRI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_QAT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_REU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ROU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_RUS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.federal_subject,
      placeholder: translations.addressBlockComponent.placeholder.federal_subject,
    },
  },
  CTRY_RWA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SHN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aaaasnaa,
      message: translations.addressBlockComponent.validationMessage.postalCode_aaaasnaa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_KNA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.kn_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_kn_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_LCA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.lc_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_lc_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SPM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_VCT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.vc_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_vc_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.parish,
      placeholder: translations.addressBlockComponent.placeholder.parish,
    },
  },
  CTRY_BLM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_MAF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_WSM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.ws_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_ws_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SMR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.cap,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.cap_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_STP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SAU: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn_nnnn_or_nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn_nnnn_or_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_SEN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.cp,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.cp_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_SRB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.pak,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.pak_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SYC: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SLE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SGP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SXM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SVK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnsnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnsnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_SVN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.municipality,
      placeholder: translations.addressBlockComponent.placeholder.municipality,
    },
  },
  CTRY_SLB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SOM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.aasnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_aasnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_ZAF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.southAfrica,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SGS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_KOR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SSD: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_ESP: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.autonomous_community,
      placeholder: translations.addressBlockComponent.placeholder.autonomous_community,
    },
  },
  CTRY_LKA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SDN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_SUR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_SJM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_SWZ: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.annn,
      message: translations.addressBlockComponent.validationMessage.postalCode_annn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_SWE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnsnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnsnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county,
      placeholder: translations.addressBlockComponent.placeholder.county,
    },
  },
  CTRY_CHE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.canton,
      placeholder: translations.addressBlockComponent.placeholder.canton,
    },
  },
  CTRY_SYR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_TWN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.tw_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_tw_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.county_or_city,
      placeholder: translations.addressBlockComponent.placeholder.county_or_city,
    },
  },
  CTRY_TJK: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_TZA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_THA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_BHS: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_TGO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_TKL: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_TON: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_TTO: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_TUN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_TUR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_TKM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_TCA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_TUV: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.council,
      placeholder: translations.addressBlockComponent.placeholder.council,
    },
  },
  CTRY_UGA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.district,
      placeholder: translations.addressBlockComponent.placeholder.district,
    },
  },
  CTRY_UKR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.oblast,
      placeholder: translations.addressBlockComponent.placeholder.oblast,
    },
  },
  CTRY_UAE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.emirate,
      placeholder: translations.addressBlockComponent.placeholder.emirate,
    },
  },
  CTRY_GBR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.uk_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_uk_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.division,
      placeholder: translations.addressBlockComponent.placeholder.division,
    },
  },
  CTRY_USA: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_UMI: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_URY: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.department,
      placeholder: translations.addressBlockComponent.placeholder.department,
    },
  },
  CTRY_UZB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.region,
      placeholder: translations.addressBlockComponent.placeholder.region,
    },
  },
  CTRY_VUT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_VAT: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_VEN: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.state,
      placeholder: translations.addressBlockComponent.placeholder.state,
    },
  },
  CTRY_VNM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_VGB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.vg_method,
      message: translations.addressBlockComponent.validationMessage.postalCode_vg_method,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_VIR: {
    postalZip: {
      label: translations.addressBlockComponent.fields.zipCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.zipCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_WLF: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ESH: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_YEM: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.governorate,
      placeholder: translations.addressBlockComponent.placeholder.governorate,
    },
  },
  CTRY_ZMB: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnnn,
      message: translations.addressBlockComponent.validationMessage.postalCode_nnnnn,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
  CTRY_ZWE: {
    postalZip: {
      label: translations.addressBlockComponent.fields.postalCode,
      pattern: postalZipPatterns.nnnn,
      message: translations.addressBlockComponent.validationMessage.usa,
    },
    provState: {
      label: translations.addressBlockComponent.fields.province,
      placeholder: translations.addressBlockComponent.placeholder.province,
    },
  },
};
