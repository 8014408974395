import React, { SVGProps } from 'react';
import patientLogo from './patient_img.png';
import CassadolLogoFull from './CassadolLogoFull';
import cardConnectLogo from './CardConnect-Logo.png';

export const patientMenuIcon = () => <img src={patientLogo} alt='' />;

export const CassadolLogo = (props: SVGProps<SVGSVGElement>) => <CassadolLogoFull {...props} />;

export const CardConnectLogo = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) => <img src={cardConnectLogo} alt={'CardConnect'} {...props} />;
