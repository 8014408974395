import React from 'react';
import { Button, Tag } from 'antd';
import { translations } from '../../constants/translations';
import { joinPatientOrContactNameAndNumber } from '../../util/displaying';
import { Entity } from '../SelectEntityModalTable/SelectEntityModalTable';

interface MultiSelectTableFooterProps<T> {
  selectedEntities: T[];
  onClearAll: () => void;
  onSelectAll: () => void;
  onRemovePatient: (entity: T) => void;
  entity?: string;
}

type ComponentProps<T> = React.PropsWithChildren<MultiSelectTableFooterProps<T>>;

export const MultiSelectTableFooter = <T extends Entity>({
  selectedEntities,
  onClearAll,
  onSelectAll,
  onRemovePatient,
  entity,
}: ComponentProps<T>) => {
  const getEntityTag = (entity: T) => {
    return (
      <Tag
        style={{ marginBottom: '2px', marginTop: '2px' }}
        key={entity.id}
        color={'geekblue'}
        closable
        onClose={() => onRemovePatient(entity)}
        data-testid={'tag' + entity.id}
      >
        {joinPatientOrContactNameAndNumber(entity)}
      </Tag>
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button id='btnCheck' style={{ minWidth: '100px' }} onClick={onSelectAll}>
        {translations.shared.selectPatientModal.checkAll}
      </Button>
      <Button
        id='btnClear'
        disabled={selectedEntities.length === 0}
        style={{ minWidth: '100px', marginLeft: 6 }}
        onClick={onClearAll}
      >
        {translations.shared.selectPatientModal.clear}
      </Button>
      <div style={{ marginLeft: '10px' }}>
        {selectedEntities.length === 0 ? (
          <i>{translations.shared.selectEntityModal.selectOneOrMore(entity)}</i>
        ) : (
          selectedEntities.map((patient) => getEntityTag(patient as any))
        )}
      </div>
    </div>
  );
};
