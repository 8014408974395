export enum ViewSubscriptionActionType {
  setRenderKey = 'VIEW_SUBSCRIPTION_SET_RENDER_KEY',
  incrementRenderKey = 'VIEW_SUBSCRIPTION_INC_RENDER_KEY',
  setEditing = 'VIEW_SUBSCRIPTION_SET_EDIT_MODE',
  setIsInitiator = 'VIEW_SUBSCRIPTION_SET_IS_INITIATOR',
  setIsActive = 'VIEW_SUBSCRIPTION_SET_IS_ACTIVE',
}
export interface SetRenderKey {
  type: ViewSubscriptionActionType.setRenderKey;
  renderKey: number;
}

export interface IncrementRenderKey {
  type: ViewSubscriptionActionType.incrementRenderKey;
}

export interface SetEditing {
  type: ViewSubscriptionActionType.setEditing;
  editing: boolean;
}

export interface SetIsInitiator {
  type: ViewSubscriptionActionType.setIsInitiator;
  isInitiator: boolean;
}

export interface SetIsActive {
  type: ViewSubscriptionActionType.setIsActive;
  isActive: boolean;
}

const ViewSubscriptionActions = {
  setRenderKey: (renderKey: number): SetRenderKey => ({
    type: ViewSubscriptionActionType.setRenderKey,
    renderKey,
  }),
  incrementRenderKey: (): IncrementRenderKey => ({
    type: ViewSubscriptionActionType.incrementRenderKey,
  }),
  setEditing: (editing: boolean): SetEditing => ({
    type: ViewSubscriptionActionType.setEditing,
    editing,
  }),
  setIsInitiator: (isInitiator: boolean): SetIsInitiator => ({
    type: ViewSubscriptionActionType.setIsInitiator,
    isInitiator,
  }),
  setIsActive: (isActive: boolean): SetIsActive => ({
    type: ViewSubscriptionActionType.setIsActive,
    isActive,
  }),
};

export type ViewSubscriptionActionsTypes =
  | SetRenderKey
  | IncrementRenderKey
  | SetEditing
  | SetIsInitiator
  | SetIsActive;

export default ViewSubscriptionActions;
