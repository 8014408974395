import { Form, Input } from 'antd';
import React, { useState } from 'react';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../../constants/translations';
import { getRequiredRule } from '../../../util/forms';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useUpdateOrganizationReferenceData } from '../../../hooks/ajax/organization/organizationHooks';
import { buildReferenceDataUpsert } from '../../ReferenceData/RefDataTable/refDataTableUpsertUtils';
import { FormProps } from 'antd/lib/form';
import { isMatch } from '../../../util/objectComparisons';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { tabKeys } from '../../ReferenceData/refDataUtils';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

enum Fields {
  Number = 'gl_number',
  Name = 'name',
  Is_Receivables_Account = 'is_receivables_account',
}

interface ServiceGLAccountModalProps {
  initialName?: string;
  onSuccess?: (glAccount: string) => void;
  onCancel?: () => void;
}

export const ServiceGLAccountModal: React.FC<ServiceGLAccountModalProps> = ({ initialName, onSuccess, onCancel }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertOrganization] = useMutationWithMessages(useUpdateOrganizationReferenceData);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [changesMade, setChangesMade] = useState(false);
  const initialData = {
    [Fields.Name]: initialName,
  };

  const handleOnCancel = () => {
    if (!isSaving) {
      onCancel?.();
    }
  };

  const handleValuesChange: FormProps['onValuesChange'] = (_, values) => {
    setChangesMade(!isMatch(initialData, values));
  };

  const handleFinish: FormProps['onFinish'] = async (values) => {
    setIsSaving(true);
    const upsert = buildReferenceDataUpsert(
      organizationId,
      {
        id: '0',
        sort_order: 0,
        practice_id: null,
        gl_number: values[Fields.Number],
        name: values[Fields.Name],
        is_receivables_account: false,
      },
      tabKeys.generalLedger
    );

    await upsertOrganization({
      options: {
        variables: { organizationId, organization: upsert },
      },
      onSuccess: (data) => {
        const glAccount = data?.upsertOrganization?.ref_financial?.general_ledger?.find(
          (gl) => gl.name === values[Fields.Name]
        );
        setIsSaving(false);
        onSuccess?.(glAccount?.id || '');
      },
      onError: () => {
        setIsSaving(false);
      },
    });
  };

  return (
    <ModalWithCloseConfirm
      title={translations.serviceGLAccountModal.title}
      onCancel={handleOnCancel}
      onOk={form.submit}
      open
      isSaving={isSaving}
      changesMade={changesMade}
      warningMessageOnRouteChange={translations.shared.getUnsavedDataNavigationWarning(
        translations.serviceGLAccountModal.GLAccount
      )}
    >
      <Form
        {...layout}
        form={form}
        onFinish={handleFinish}
        initialValues={initialData}
        onValuesChange={handleValuesChange}
        autoComplete='off'
      >
        <MaxLengthFormItem
          label={translations.serviceGLAccountModal.fields.name}
          name={Fields.Name}
          rules={[getRequiredRule(translations.serviceGLAccountModal.fields.name)]}
          maxLength={50}
        >
          <Input autoFocus />
        </MaxLengthFormItem>
        <MaxLengthFormItem
          label={translations.serviceGLAccountModal.fields.number}
          name={Fields.Number}
          rules={[getRequiredRule(translations.serviceGLAccountModal.fields.name)]}
          maxLength={17}
        >
          <Input />
        </MaxLengthFormItem>
      </Form>
    </ModalWithCloseConfirm>
  );
};
