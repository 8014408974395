import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Contact,
  ContactAlert,
  ContactAlertType,
  Maybe,
  OrganizationDto,
  Patient,
  PatientAlert,
  PatientAlertType,
  PatientContactAlert,
} from '../graph/types';
import { useLDFlag } from './useLDHooks';
import { LDFlagNames } from '../constants/launchDarkly';
import { AlertsModal } from '../components/AlertsModal/AlertsModal';
import { translations } from '../constants/translations';
import { Divider } from 'antd';
import styled from 'styled-components';
import { CustomMarginMixin } from '../globalStyles.style';

export const ContainerWithMargin = styled.div`
  ${CustomMarginMixin}
`;

const TitleDiv = styled.div`
  display: inline-flex;
  align-items: center;
`;

const AlertNameSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const RelatedNameSpan = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const SeparatorSpan = styled.span`
  font-size: 14px;
  font-weight: bold;
  padding: 0px 3px;
`;

export const useAlerts = <T extends PatientAlertType | ContactAlertType>(
  typeKey: keyof T,
  patient?: Maybe<Patient>,
  organization?: Maybe<OrganizationDto>
) => {
  const [contactMap, setContactMap] = useState<Record<string, string>>({});
  const usesAlerts = useLDFlag(LDFlagNames.AlertSystem);
  const patientReferenceData = organization?.ref_patient;
  const contactReferenceData = organization?.ref_contact;
  const patientAlertTypeIds = useMemo(
    () =>
      patientReferenceData?.alert_type
        ?.map((alertType) => (alertType[typeKey as keyof PatientAlertType] ? alertType.id : undefined))
        .filter((v) => v),
    [patientReferenceData?.alert_type, typeKey]
  );

  const alerts: (PatientAlert | PatientContactAlert)[] = useMemo(
    () =>
      (patient?.alert?.filter(({ type_id }) => patientAlertTypeIds?.includes(type_id)) ?? []).concat(
        (patient?.contact_alert ?? []) as any
      ),
    [patient?.alert, patientAlertTypeIds, patient?.contact_alert]
  );

  const showAlertModal = alerts.length > 0 && usesAlerts;

  const [alertsModalOpen, setAlertsModalOpen] = useState(showAlertModal);

  useEffect(() => {
    if (
      (patient?.contact_alert && typeKey === 'prompt_patient') ||
      (patient?.contact_invoice_alert && typeKey === 'prompt_invoice')
    ) {
      const contactMap: Record<string, string> = {};
      patient.owner_ids?.forEach((id, i) => {
        contactMap[id] = patient?.owner_names?.[i] ?? '';
      });
      patient.related_ids?.forEach((id, i) => {
        contactMap[id] = patient.related_names?.[i] ?? '';
      });

      setContactMap(contactMap);
    }
  }, [patient, typeKey]);

  const getContactAlerts = (contact?: Maybe<Contact>) => {
    const contactAlertTypeIds = contactReferenceData?.alert_type
      ?.map((alertType) => (alertType[typeKey as keyof ContactAlertType] ? alertType.id : undefined))
      .filter((v) => v);

    return contact?.alert?.filter(({ type_id }) => contactAlertTypeIds?.includes(type_id)) ?? [];
  };

  const renderAlert = (
    alerTitle: string,
    relatedName: string,
    note: Maybe<string | undefined>,
    i: number,
    alertLength: number,
    key: string
  ) => (
    <React.Fragment key={key}>
      <TitleDiv key={key}>
        <AlertNameSpan>{alerTitle}</AlertNameSpan>
        <SeparatorSpan>-</SeparatorSpan>
        <RelatedNameSpan>{relatedName}</RelatedNameSpan>
      </TitleDiv>
      <ContainerWithMargin margin='0 0 0 20px'>{note}</ContainerWithMargin>
      {i !== alertLength - 1 && <Divider />}
    </React.Fragment>
  );

  const renderContactAlertsModal = (
    contactAlerts: ContactAlert[],
    contact: Contact,
    onOk = () => setAlertsModalOpen(undefined)
  ) => {
    if (!contactReferenceData) {
      return <p>{translations.shared.loadErrorMessage}</p>;
    }

    return (
      <AlertsModal onOk={onOk} title={translations.patientPage.alerts.title}>
        {contactAlerts.map(({ id, note, type_id }, i) => {
          const alertTypeName =
            contactReferenceData.alert_type.find(({ id }) => id === type_id)?.name ??
            translations.patientPage.alerts.genericTitle;

          return renderAlert(alertTypeName, contact.name, note, i, contactAlerts.length, id);
        })}
      </AlertsModal>
    );
  };

  const renderPatientsAlertsModal = useCallback(() => {
    if (!alertsModalOpen) {
      return null;
    }
    if (!patientReferenceData || !patient) {
      return <p>{translations.patientPage.unknownPatient}</p>;
    }
    return (
      <AlertsModal onOk={() => setAlertsModalOpen(undefined)} title={translations.patientPage.alerts.title}>
        {alerts.map((alert, i) => {
          const contactlertTypeName =
            contactReferenceData?.alert_type.find(({ id }) => id === alert?.type_id)?.name ??
            translations.patientPage.alerts.genericTitle;
          const alertTypeName =
            patientReferenceData.alert_type.find(({ id }) => id === alert?.type_id)?.name ??
            translations.patientPage.alerts.genericTitle;

          const alerName = 'contact_id' in alert ? contactlertTypeName : alertTypeName;

          const relatedNameName = 'contact_id' in alert ? contactMap[alert.contact_id] : patient.name;

          return renderAlert(alerName, relatedNameName ?? '', alert.note, i, alerts.length, alert.id);
        })}
      </AlertsModal>
    );
  }, [alerts, alertsModalOpen, contactReferenceData?.alert_type, contactMap, patient, patientReferenceData]);

  return {
    contactMap,
    setContactMap,
    showAlertModal,
    alertsModalOpen,
    setAlertsModalOpen,
    alerts,
    patientReferenceData,
    contactReferenceData,
    usesAlerts,
    renderPatientsAlertsModal,
    getContactAlerts,
    renderContactAlertsModal,
  };
};

export const useAlertsDto = <T extends PatientAlertType | ContactAlertType>(
  typeKey: keyof T,
  patient?: Maybe<Patient>,
  organization?: Maybe<OrganizationDto>
) => {
  const [contactMap, setContactMap] = useState<Record<string, string>>({});
  const usesAlerts = useLDFlag(LDFlagNames.AlertSystem);
  const patientReferenceData = organization?.ref_patient;
  const contactReferenceData = organization?.ref_contact;
  const patientAlertTypeIds = patientReferenceData?.alert_type
    ?.map((alertType) => (alertType[typeKey as keyof PatientAlertType] ? alertType.id : undefined))
    .filter((v) => v);

  let alerts: (PatientAlert | PatientContactAlert)[] =
    patient?.alert?.filter(({ type_id }) => patientAlertTypeIds?.includes(type_id)) ?? [];
  alerts = alerts.concat(patient?.contact_alert ?? []);
  const showAlertModal = alerts.length > 0 && usesAlerts;

  const [alertsModalOpen, setAlertsModalOpen] = useState(showAlertModal);

  useEffect(() => {
    if (
      (patient?.contact_alert && typeKey === 'prompt_patient') ||
      (patient?.contact_invoice_alert && typeKey === 'prompt_invoice')
    ) {
      const contactMap: Record<string, string> = {};
      patient.owner_ids?.forEach((id, i) => {
        contactMap[id] = patient?.owner_names?.[i] ?? '';
      });
      patient.related_ids?.forEach((id, i) => {
        contactMap[id] = patient.related_names?.[i] ?? '';
      });

      setContactMap(contactMap);
    }
  }, [patient, typeKey]);

  const getContactAlerts = (contact?: Maybe<Contact>) => {
    const contactAlertTypeIds = contactReferenceData?.alert_type
      ?.map((alertType) => (alertType[typeKey as keyof ContactAlertType] ? alertType.id : undefined))
      .filter((v) => v);

    return contact?.alert?.filter(({ type_id }) => contactAlertTypeIds?.includes(type_id)) ?? [];
  };

  const renderAlert = (
    alerTitle: string,
    relatedName: string,
    note: Maybe<string | undefined>,
    i: number,
    alertLength: number,
    key: string
  ) => (
    <>
      <TitleDiv key={key}>
        <AlertNameSpan>{alerTitle}</AlertNameSpan>
        <SeparatorSpan>-</SeparatorSpan>
        <RelatedNameSpan>{relatedName}</RelatedNameSpan>
      </TitleDiv>
      <ContainerWithMargin margin='0 0 0 20px'>{note}</ContainerWithMargin>
      {i !== alertLength - 1 && <Divider />}
    </>
  );

  const renderContactAlertsModal = (
    contactAlerts: ContactAlert[],
    contact: Contact,
    onOk = () => setAlertsModalOpen(undefined)
  ) => {
    if (!contactReferenceData) {
      return <p>{translations.shared.loadErrorMessage}</p>;
    }

    return (
      <AlertsModal onOk={onOk} title={translations.patientPage.alerts.title}>
        {contactAlerts.map(({ id, note, type_id }, i) => {
          const alertTypeName =
            contactReferenceData.alert_type.find(({ id }) => id === type_id)?.name ??
            translations.patientPage.alerts.genericTitle;

          return renderAlert(alertTypeName, contact.name, note, i, contactAlerts.length, id);
        })}
      </AlertsModal>
    );
  };

  const renderPatientsAlertsModal = () => {
    if (!alertsModalOpen) {
      return null;
    }
    if (!patientReferenceData || !patient) {
      return <p>{translations.patientPage.unknownPatient}</p>;
    }
    return (
      <AlertsModal onOk={() => setAlertsModalOpen(undefined)} title={translations.patientPage.alerts.title}>
        {alerts.map((alert, i) => {
          const contactlertTypeName =
            contactReferenceData?.alert_type.find(({ id }) => id === alert?.type_id)?.name ??
            translations.patientPage.alerts.genericTitle;
          const alertTypeName =
            patientReferenceData.alert_type.find(({ id }) => id === alert?.type_id)?.name ??
            translations.patientPage.alerts.genericTitle;

          const alerName = 'contact_id' in alert ? contactlertTypeName : alertTypeName;

          const relatedNameName = 'contact_id' in alert ? contactMap[alert.contact_id] : patient.name;

          return renderAlert(alerName, relatedNameName ?? '', alert.note, i, alerts.length, alert.id);
        })}
      </AlertsModal>
    );
  };

  return {
    contactMap,
    setContactMap,
    showAlertModal,
    alertsModalOpen,
    setAlertsModalOpen,
    alerts,
    patientReferenceData,
    contactReferenceData,
    usesAlerts,
    renderPatientsAlertsModal,
    getContactAlerts,
    renderContactAlertsModal,
  };
};
