import React from 'react';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { DatePicker, Form, Input, Select, SelectProps, Switch } from 'antd';
import { PatientFormItemProps } from '../PatientModalContent/PatientModalContent';
import dayjs from 'dayjs';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { RoaModalContent } from '../RoaModalContent/RoaModalContent';
import { defaultFont } from '../../theme';

type RequirementsComponentProps = {
  itemProps: PatientFormItemProps;
  selectProps?: SelectProps;
  placeholder?: string;
  onInputChange?: (value: string) => void;
};

export const RequirementsComponent: React.FC<RequirementsComponentProps> = ({
  itemProps,
  selectProps,
  placeholder,
  onInputChange,
}) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  let input;

  switch (itemProps.inputtype) {
    case 'select':
      input = (
        <Select {...searchableSelectParams} placeholder={placeholder} data-testid={itemProps.testid}>
          {selectProps?.options?.map((o) => (
            <Select.Option value={o.value} title={o.label} label={o.label} key={o.value}>
              {<span style={{ fontFamily: defaultFont }}>{o.label}</span>}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case 'datePicker':
      input = (
        <DatePicker
          format={dateFormat}
          disabledDate={(date) => date.isAfter(dayjs())}
          autoFocus={itemProps.autoFocus}
          data-testid={itemProps.testid}
        />
      );
      break;
    case 'input':
      input = (
        <Input
          placeholder={placeholder}
          onChange={({ target: { value } }) => onInputChange?.(value)}
          data-testid={itemProps.testid}
        />
      );
      break;
    case 'checkbox':
      return <RoaModalContent patientFormItemProps={itemProps} />;
    case 'switch':
      input = <Switch data-testid={itemProps.testid} />;
      break;
    default:
      return null;
  }

  return (
    <Form.Item {...itemProps} shouldUpdate style={{ width: '100%' }}>
      {input}
    </Form.Item>
  );
};
