import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { StyledPageHeaderWithMargin } from '../../../components/PageHeader/PageHeader.style';
import { routes } from '../../../constants/routes';
import { translations } from '../../../constants/translations';
import { UserInput } from '../../../graph/types';
import { useCreateUser } from '../../../hooks/ajax/user/userHooks';
import { isMatchIgnoringEmptyStrings } from '../../../util/objectComparisons';
import { SaveSpinnerAndNavigationWarning } from '../../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute, withUserIdParameter } from '../../../hooks/route/navigationHooks';
import { Loading } from '../../../components/Loading/Loading';
import { useMutationWithParameterWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { getRequiredRule, getValidEmailRule } from '../../../util/forms';
import { SubscriptionUpdateModal } from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { noop } from 'lodash';
import { UserRoleSelect } from '../UserRoleSelect';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import { emailFieldName, nameFieldName, typesFieldName } from '../../../constants/fields/userFields';
import { flushSync } from 'react-dom';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

export interface UserInputFields {
  [nameFieldName]: string;
  [emailFieldName]: string;
  [typesFieldName]?: string[];
}

export const CreateUser: React.FC = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const organizationId = useGetOrganizationIdFromRoute();
  const [createUser] = useMutationWithParameterWithMessages(useCreateUser, organizationId);
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.Subscription
  );
  const { organization: organizationWithUserTypes, organizationLoading: organizationWithUserTypesLoading } =
    useGetOrganization(organizationId, AdditionalOrganizationField.UserTypes);
  const { navigateBack, navigateTo } = useNavigationToRoute();
  const [unsavedData, setUnsavedData] = useState(false);
  const [subscriptionUpdateModalParams, setSubscriptionUpdateModalParams] = useState<{
    show: boolean;
    onSuccess: () => void;
  }>({
    show: false,
    onSuccess: noop,
  });

  if (organizationLoading || organizationWithUserTypesLoading) {
    return <Loading />;
  }

  const userTypes = organizationWithUserTypes?.ref_user?.type ?? [];

  function handleValueChange(_changedValues: Partial<UserInputFields>, allValues: UserInputFields) {
    setUnsavedData(!isMatchIgnoringEmptyStrings(allValues, null));
  }

  const preFinishCheck = async (values: UserInputFields) => {
    if ((organization?.subscription?.detail?.[0]?.qty_available ?? 0) > 0) {
      await onFinish(values);
    } else {
      setSubscriptionUpdateModalParams({
        show: true,
        onSuccess: () => {
          setSubscriptionUpdateModalParams({
            show: false,
            onSuccess: noop,
          });
          onFinish(values);
        },
      });
    }
  };

  const onFinish = async (values: UserInputFields) => {
    setIsSaving(true);
    const userTypeRecords =
      values[typesFieldName]?.map((type_id) => ({
        type_id,
      })) || [];
    const userValues: UserInput = {
      name: values.name,
      email: values.email,
      organizationId,
      userTypeRecords,
    };
    await createUser({
      options: { variables: { user: userValues } },
      successMessage: translations.createUserPage.getSuccessMessage(values.name),
      onSuccess: (data?) => {
        flushSync(() => {
          setUnsavedData(false);
        });
        navigateTo(routes.viewUser, withUserIdParameter(data?.createUser.id || ''));
      },
    });
    setIsSaving(false);
  };

  return (
    <>
      <SaveSpinnerAndNavigationWarning
        isSaving={isSaving}
        showNavigationWarning={unsavedData}
        warningMessage={translations.shared.getCreationNavigationWarning(translations.createUserPage.createdEntity)}
      >
        <StyledPageHeaderWithMargin onBack={navigateBack} title={translations.createUserPage.title} />
        <Form {...layout} name='basic' onFinish={preFinishCheck} onValuesChange={handleValueChange} autoComplete='off'>
          <MaxLengthFormItem
            label={translations.createUserPage.fields.name.label}
            name={nameFieldName}
            rules={[getRequiredRule(translations.createUserPage.fields.name.label)]}
            maxLength={50}
          >
            <Input autoComplete='new-password' />
          </MaxLengthFormItem>
          <MaxLengthFormItem
            validateTrigger='onBlur'
            label={translations.createUserPage.fields.email.label}
            name={emailFieldName}
            rules={[getRequiredRule(translations.createUserPage.fields.email.label), getValidEmailRule()]}
            maxLength={255}
          >
            <Input />
          </MaxLengthFormItem>
          <UserRoleSelect userTypes={userTypes} />
          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit'>
              {translations.createUserPage.submitButtonText}
            </Button>
          </Form.Item>
        </Form>
      </SaveSpinnerAndNavigationWarning>
      {subscriptionUpdateModalParams.show && (
        <SubscriptionUpdateModal
          onClose={() => setSubscriptionUpdateModalParams({ show: false, onSuccess: noop })}
          onSuccess={subscriptionUpdateModalParams.onSuccess}
        />
      )}
    </>
  );
};
