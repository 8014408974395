import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Contact, RelatedOwner, RelatedOwnershipEntryAll } from '../../../../graph/types';
import { Button } from 'antd';
import { translations } from '../../../../constants/translations';
import { showWarningMessage } from '../../../../components/Notification/notificationUtil';
import {
  addOwnerAndSetDefault,
  calculateTotalPercentage,
  hasExactlyOnePrimaryOwner,
  isNewOwnership,
  ownerExists,
  removeOwnerAndSetDefault,
  unsavedChangesMessageIfDisabled,
} from './ownershipUtils';
import { SelectContactModal } from '../../../../components/SelectContactModal/SelectContactModal';
import { ButtonWithPopconfirm } from '../../../../components/ButtonWithPopconfirm/ButtonWithPopconfirm';
import { PercentageTable } from '../../../../components/PercentageTable/PercentageTable';

export const ownershipNumber = (ownershipIndex: number) => {
  return ownershipIndex + 1;
};

export interface OwnershipPanelProps extends PropsWithChildren<unknown> {
  originalOwnership: RelatedOwnershipEntryAll;
  ownershipIndex: number;
  isCurrentOwnership: boolean;
  upsertOwnershipCallback: (ownership: RelatedOwnershipEntryAll, ownershipIndex: number) => void;
  deleteOwnershipCallback: (ownership: RelatedOwnershipEntryAll, ownershipIndex: number) => void;
  handleEditClick: (ownershipIndex: number) => void;
  handleResetCallback: () => void;
  currentlyEditingIndex: number;
  showContacts: boolean;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const OwnershipPanelContent: React.FC<OwnershipPanelProps> = ({
  isCurrentOwnership,
  originalOwnership,
  ownershipIndex,
  upsertOwnershipCallback,
  deleteOwnershipCallback,
  handleEditClick,
  handleResetCallback,
  currentlyEditingIndex,
  showContacts,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}: OwnershipPanelProps) => {
  const [owners, setOwners] = useState<RelatedOwner[]>(originalOwnership.owner);
  const [modalVisible, setModalVisible] = useState<boolean>(showContacts && currentlyEditingIndex === 0);

  useEffect(() => {
    setOwners(originalOwnership.owner);
  }, [originalOwnership.owner]);

  const isEditMode = currentlyEditingIndex === ownershipIndex;
  const someOwnershipBeingEdited = currentlyEditingIndex !== -1;

  const onReset = useCallback(() => {
    setOwners(originalOwnership.owner);
    handleResetCallback();
  }, [setOwners, handleResetCallback, originalOwnership.owner]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      onReset();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, onReset, setShouldResetOnTabChange]);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const addContactAsOwner = (contact: Contact) => {
    if (!ownerExists(owners, contact)) {
      const newOwners = addOwnerAndSetDefault(owners, contact);
      setOwners(newOwners);
    } else {
      showWarningMessage(translations.patientPage.ownership.addOwnerModal.messageWhenAddingDuplicateContact);
    }
    setModalVisible(false);
  };

  const removeOwner = (ownerIndex: number) => {
    const newOwners = removeOwnerAndSetDefault(owners, ownerIndex);
    setOwners(newOwners);
  };

  const onSaveClicked = () => {
    const updatedOwnership = { ...originalOwnership };
    updatedOwnership.owner = owners;
    upsertOwnershipCallback(updatedOwnership, ownershipIndex);
  };

  const showSaveButton = isEditMode && calculateTotalPercentage(owners) === 100 && hasExactlyOnePrimaryOwner(owners);

  const saveButton = (
    <Button style={{ marginRight: '20px' }} type={'primary'} onClick={onSaveClicked}>
      {isNewOwnership(originalOwnership)
        ? translations.patientPage.ownership.buttons.saveNewOwnership
        : translations.patientPage.ownership.buttons.save}
    </Button>
  );

  const resetButton = (
    <Button onClick={() => onReset()} style={{ marginRight: '10px' }}>
      {translations.patientPage.ownership.buttons.reset}
    </Button>
  );

  const editButton = (
    <ButtonWithPopconfirm
      isDisabled={someOwnershipBeingEdited}
      buttonTitle={unsavedChangesMessageIfDisabled}
      onClickCallback={() => handleEditClick(ownershipIndex)}
      buttonText={translations.patientPage.ownership.buttons.edit}
      popconfirmTitle={translations.patientPage.ownership.editOwnershipConfirmation}
      buttonStyle={{ marginRight: '10px' }}
      buttonType={'primary'}
    />
  );

  const deleteOwnershipButton = (
    <ButtonWithPopconfirm
      onClickCallback={() => {
        deleteOwnershipCallback(originalOwnership, ownershipIndex);
      }}
      danger
      isDisabled={!isEditMode && someOwnershipBeingEdited}
      buttonText={translations.patientPage.ownership.buttons.deleteOwnership}
      popconfirmTitle={translations.patientPage.ownership.deleteOwnershipConfirmation}
      popconfirmOkText={translations.patientPage.ownership.buttons.deleteOwnership}
      buttonTestId={'Delete ownership'}
      buttonTitle={unsavedChangesMessageIfDisabled}
      buttonStyle={{ marginRight: '10px' }}
      buttonType={'primary'}
    />
  );

  const modalTitle = translations.patientPage.ownership.addOwnerModal.modalTitle(
    ownershipNumber(ownershipIndex),
    isCurrentOwnership
  );

  const ownershipTableDisplayText = {
    percentageCheckFailed: translations.patientPage.ownership.ownershipCheck.failed,
    primaryCheckFailed: translations.patientPage.ownership.primaryCheckFailed,
    validationPassed: translations.patientPage.ownership.ownershipCheck.passed,
    totalMessage: translations.patientPage.ownership.ownershipSum,
    nameColumn: translations.patientPage.ownership.columns.owner,
    primaryColumn: translations.patientPage.ownership.columns.primary,
  };

  return (
    <>
      <PercentageTable
        setModalVisible={setModalVisible}
        stakeholders={owners}
        isEditMode={isEditMode}
        removeOwner={removeOwner}
        setStakeholders={setOwners}
        displayText={ownershipTableDisplayText}
        linkOnContactName
      />
      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {isEditMode ? null : editButton}
        {!isNewOwnership(originalOwnership) ? deleteOwnershipButton : null}
        {isEditMode ? resetButton : null}
        {showSaveButton ? saveButton : null}
      </div>
      {modalVisible && (
        <SelectContactModal
          title={modalTitle}
          visible
          onCancel={handleCancel}
          selectContact={addContactAsOwner}
          excludedIds={owners.map((owner) => owner.contact_id ?? '')}
        />
      )}
    </>
  );
};
