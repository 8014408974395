import { omit } from 'lodash';
import dayjs from 'dayjs';
import { ArBuckets, ContactsPatientsCount, Invoiced, Maybe, MonthlyPayment } from '../../graph/types';

export enum DashboardColors {
  GREY = '#647698',
  YELLOW = '#F6C022',
  PURPLE = '#7565F9',
  GREEN = '#61D9AB',
  BLUE = '#6294F9',
}

// Calculate monthly breakdown
// Get all 12 past months in order
const monthsFromNow = [...Array(12)].map((_, index) => dayjs().subtract(index, 'month')).reverse();
// Get year period keys for the legends
const currentYear = dayjs().year();
export const currentPeriod = `${currentYear - 1}-${currentYear.toString().substring(2)}`;
export const prevPeriod = `${currentYear - 2}-${(currentYear - 1).toString().substring(2)}`;

export const getMonthlyBreakdown = (monthlyInvoiced?: Maybe<Maybe<Invoiced>[]>) => {
  // Get data for each month
  return (
    monthsFromNow
      .map((dateMark) => {
        // Data for current period
        const currentPeriodMetrics = monthlyInvoiced?.filter(
          (metric) =>
            metric?.month && metric.month === dateMark.format('MMM') && metric.year === dateMark.format('YYYY')
        );
        // Data for previous period
        const prevPeriodMetrics = monthlyInvoiced?.filter(
          (metric) =>
            metric?.month &&
            metric.month === dateMark.format('MMM') &&
            metric.year === dayjs(dateMark).subtract(1, 'year').format('YYYY')
        );
        // Check existance and return
        return currentPeriodMetrics?.length || prevPeriodMetrics?.length
          ? {
              name: dateMark.format('MMM'),
              [currentPeriod]: currentPeriodMetrics?.reduce((sum, metric) => sum + +(metric?.invoiced_amount ?? 0), 0),
              [prevPeriod]: prevPeriodMetrics?.reduce((sum, metric) => sum + +(metric?.invoiced_amount ?? 0), 0),
              years: [dateMark.clone().subtract(1, 'year').format('YYYY'), dateMark.clone().format('YYYY')],
            }
          : { void: 1 };
      })
      // Filter void data
      .filter((b) => !b.void)
  );
};

export const getYearlyBreakdown = (yearlyInvoiced?: Maybe<Maybe<Invoiced>[]>) => {
  return (
    yearlyInvoiced?.map((metric) => ({
      name: metric?.year,
      value: +(metric?.invoiced_amount ?? 0),
    })) ?? []
  );
};

const getBucketInfo = (bucket: string) => {
  switch (bucket) {
    case 'bucket0':
      return { name: '0-30', color: DashboardColors.GREY };
    case 'bucket31':
      return { name: '31-60', color: DashboardColors.YELLOW };
    case 'bucket61':
      return { name: '61-90', color: DashboardColors.PURPLE };
    case 'bucket91':
      return { name: '91-120', color: DashboardColors.GREEN };
    default:
      return { name: '121+', color: DashboardColors.BLUE };
  }
};

export const getArBreakdown = (accountsReceivable?: Maybe<ArBuckets>) => {
  return Object.keys(omit(accountsReceivable, ['__typename']) ?? {})
    .map((metric) => {
      const { name, color } = getBucketInfo(metric);
      return {
        name,
        color,
        value: +(accountsReceivable?.[metric as keyof ArBuckets] ?? 0),
      };
    })
    .filter(({ name }) => name);
};

export const getMonthlyPayments = (monthlyPayments?: Maybe<Maybe<MonthlyPayment>[]>) => {
  if (!monthlyPayments) {
    return undefined;
  }

  // Changes to show proper YTD values for the 2 years in the payment chart
  const foundIndex = monthlyPayments.findIndex((entry) => entry?.month === monthsFromNow[0].format('MMM YYYY'));

  // These are the entries for the previous year prior to the month in which the chart starts
  const entriesBeforeChart = foundIndex >= 0 ? monthlyPayments.slice(0, foundIndex) : [];

  let currentYearSum = 0;
  // Here we are summing the totals from all of the month entries previous to the chart so that
  // we start off with the sum of the months prior to the chart.
  let previousYearSum = entriesBeforeChart.reduce((sum, entry) => sum + (entry ? +entry?.total : 0), 0);

  return monthsFromNow.map((m) => {
    const formattedMonth = m.format('MMM YYYY');
    const found = monthlyPayments?.find((entry) => entry?.month === formattedMonth);
    if (!found) {
      return {
        name: m.format('MMM'),
        label: formattedMonth,
        value: 0,
        ytd: formattedMonth.includes(currentYear.toString()) ? currentYearSum : previousYearSum,
      };
    }

    if (formattedMonth.includes(currentYear.toString())) {
      currentYearSum = currentYearSum + +found.total;
      return {
        name: m.format('MMM'),
        label: formattedMonth,
        value: +found?.total,
        ytd: currentYearSum,
      };
    }

    previousYearSum = previousYearSum + +found.total;
    return {
      name: m.format('MMM'),
      label: formattedMonth,
      value: +found?.total,
      ytd: previousYearSum,
    };
  });
};

export const getContactsAndPatients = (contactsAndPatients?: Maybe<Maybe<ContactsPatientsCount>[]>) => {
  return monthsFromNow.map((m) => {
    const formattedMonth = m.format('MMM YYYY');
    const found = contactsAndPatients?.find((entry) => entry?.month === formattedMonth);
    if (!found) {
      return {
        name: m.format('MMM'),
        label: formattedMonth,
        Contacts: 0,
        Patients: 0,
      };
    }

    return {
      name: m.format('MMM'),
      label: formattedMonth,
      Contacts: +found?.contact_count,
      Patients: +found?.patient_count,
    };
  });
};
