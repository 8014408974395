import React from 'react';
import { Button, Card, Tooltip } from 'antd';
import { RecordItem } from '../recordUtils';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { translations } from '../../../constants/translations';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { lightBackgroundColor } from '../../../colorScheme';
import { getTag } from '../../../util/tags';
import { TagColor } from '../../../constants/tagColor';
import {
  FlexCenteredDiv,
  FlexDivContainer,
  FlexHeaderDivContainer,
  FlexItemContainer,
  LabelSpan,
} from '../../../globalStyles.style';
import styled from 'styled-components';

export const DateContainerDiv = styled.div`
  margin-bottom: 7px;
`;
export const ListItemHeader: React.FC<{
  item?: RecordItem;
  isCollapsed?: boolean;
  onToggleCollapse?: () => void;
  editableOptions?: React.ReactElement;
}> = ({ item, isCollapsed, onToggleCollapse, editableOptions }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const itemInfo = () => {
    if (item?.originalObject.__typename === 'ServiceActivity') {
      return (
        <>
          <LabelSpan>{translations.recordList.doctors}</LabelSpan>
          <span>{item.originalObject.doctor_names.join(', ')}</span>
        </>
      );
    } else if (item?.originalObject.__typename === 'LabRequest') {
      return <span>{item?.originalObject.lab_service_name}</span>;
    }
    return null;
  };
  const collapseIcon = isCollapsed ? <CaretRightOutlined /> : <CaretDownOutlined />;

  return (
    <Card size='small'>
      <FlexHeaderDivContainer>
        <div>
          <DateContainerDiv>
            <LabelSpan>{translations.recordList.date}</LabelSpan>
            <span>{item?.date.format(dateFormat)}</span>
          </DateContainerDiv>
          <FlexCenteredDiv>
            {item?.getTag?.()}
            {getTag(TagColor.Color17, item?.typeName ?? '')}
          </FlexCenteredDiv>
        </div>
        <div>{editableOptions}</div>
      </FlexHeaderDivContainer>

      <FlexDivContainer>
        <FlexItemContainer>{itemInfo()}</FlexItemContainer>
        {onToggleCollapse ? (
          <FlexItemContainer>
            <Tooltip mouseEnterDelay={2} title={translations.recordList.collapse}>
              <Button
                style={{ backgroundColor: lightBackgroundColor }}
                onClick={onToggleCollapse}
                type='default'
                shape='round'
                icon={collapseIcon}
              />
            </Tooltip>
          </FlexItemContainer>
        ) : null}
      </FlexDivContainer>
    </Card>
  );
};
