import React, { PropsWithChildren, useState } from 'react';
import { translations } from '../../constants/translations';
import { Button, Popconfirm, Popover, Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { useOffline } from '../../util/offline/offlineUtil';

interface UploadDeletePopoverProps extends PropsWithChildren<unknown> {
  accept?: string;
  uploadRequest: (options: RcCustomRequestOptions) => void;
  deleteFunction: () => void;
  showDeleteButton: boolean;
  deleteButtonText: string;
  deleteConfirmQuestion: string;
  uploadButtonText: string;
  isSaving: boolean;
}

export const UploadDeletePopover: React.FC<UploadDeletePopoverProps> = (props) => {
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { enabledAndOffline } = useOffline();

  const content = (
    <>
      <Upload customRequest={props.uploadRequest} showUploadList={false} accept={props.accept}>
        <Button icon={<UploadOutlined />} style={{ display: 'block', width: '100%' }}>
          {props.uploadButtonText}
        </Button>
      </Upload>
      {props.showDeleteButton ? (
        <Popconfirm
          placement={'topLeft'}
          title={props.deleteConfirmQuestion}
          okText={translations.shared.popconfirm.ok}
          onConfirm={props.deleteFunction}
          onOpenChange={(visible) => setPopconfirmVisible(visible)}
          cancelText={translations.shared.popconfirm.cancel}
        >
          <Button icon={<DeleteOutlined />} style={{ display: 'block', width: '100%', marginTop: '5px' }}>
            {props.deleteButtonText}
          </Button>
        </Popconfirm>
      ) : null}
    </>
  );

  return (
    <SaveSpinner isSaving={props.isSaving}>
      {!enabledAndOffline ? (
        <Popover
          content={content}
          placement={'left'}
          trigger='hover'
          open={popoverVisible || popconfirmVisible}
          onOpenChange={(visible) => setPopoverVisible(visible)}
        >
          <div>{props.children}</div>
        </Popover>
      ) : (
        <div>{props.children}</div>
      )}
    </SaveSpinner>
  );
};
