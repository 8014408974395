import React from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';

export interface AddOnCardCloverConnectProps {
  onConfigureClick: () => void;
}

export const AddOnCardCloverConnect: React.FC<AddOnCardCloverConnectProps> = ({
  onConfigureClick,
}: AddOnCardCloverConnectProps) => {
  const configureButtonProps = {
    label: translations.addOns.cards.cloverConnect.configure,
    onClick: onConfigureClick,
  };

  return (
    <SettingsCard title={translations.addOns.cards.cloverConnect.cardTitle} leftButton={configureButtonProps}>
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.cloverConnect.text}</TextWithMargin>
      </FlexContainer>
    </SettingsCard>
  );
};
