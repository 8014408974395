import React from 'react';
import { ViewInvoiceAction } from './actions';
import { initialState, viewInvoiceReducer, ViewInvoiceState } from './reducer';
import { ContextProviderFC } from '../../../../util/ContextProvider';

export const ViewInvoiceContext = React.createContext<{
  state: ViewInvoiceState;
  dispatch: React.Dispatch<ViewInvoiceAction>;
}>({} as any);

export const ViewInvoiceContextProvider: ContextProviderFC<React.PropsWithChildren> = (props) => {
  const fullInitialState = {
    ...initialState,
  };
  const [state, dispatch] = React.useReducer(viewInvoiceReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <ViewInvoiceContext.Provider value={value}>{props.children}</ViewInvoiceContext.Provider>;
};
