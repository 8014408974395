import styled from 'styled-components';
import Select from 'antd/lib/select';
import Icon from '@ant-design/icons';
import { Divider } from 'antd';

export const SearchWrapper = styled.div`
  width: 32rem;
  margin: auto;
`;

export const FullWidthSearch = styled(Select<string>)`
  width: 100%;
  color: gray;
`;

export const Container = styled.div`
  display: flex;
  padding: 5px 0px;
  overflow: hidden;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #44535e;
  width: 30px;
  height: 30px;
`;

export const IconSider = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2em;
`;

export const Image = styled.img`
  width: 30px;
  height: 30px;
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  padding-right: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

// fix color
export const Description = styled.span`
  margin-top: 10px;
  color: grey;
`;

export const TagsContainer = styled.div`
  display: flex;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagsDivider = styled(Divider)`
  margin: 5px 0;
`;

export const TagContainer = styled.div`
  display: flex;
`;
