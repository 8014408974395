import { gql } from '@apollo/client';
import { MetricFields } from '../fragments/metricFragments';

export const GetMetrics = gql`
  query getMetrics($organizationId: ID!, $practiceId: ID!, $options: MetricsOptions) {
    getMetrics(organizationId: $organizationId, practiceId: $practiceId, options: $options) {
      ...MetricFields
    }
  }
  ${MetricFields}
`;
