import React, { useMemo } from 'react';
import { InvoiceProperty, invoicePropertyNames } from '../../../constants/propertyNames';
import { CustomColumnType } from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { Invoice } from '../../../graph/types';
import { translations } from '../../../constants/translations';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getIdNumberCompareFunctionFor,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStatusFilters,
  getStatusTag,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { displayAsDate } from '../../../constants/formats';
import { renderInvoiceContactTags } from '../invoiceContactTags';
import { getInvoiceStatusText, invoiceStatusConfigs } from '../../../constants/referenceData/invoiceReferenceData';
import { PriceValue } from '../../../components/PriceValue/PriceValue';
import { useUserLocaleData } from '../../../hooks/useUserLocale';

export const useBasicInvoiceColumns = (): Record<InvoiceProperty, CustomColumnType<Invoice>> => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return useMemo(() => {
    return {
      number: {
        title: translations.invoicesPage.columns.number,
        key: invoicePropertyNames.number,
        dataIndex: invoicePropertyNames.number,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.invoicesPage.columns.number),
        onFilter: getOnFilterFunctionFor(invoicePropertyNames.number),
        sorter: getIdNumberCompareFunctionFor(invoicePropertyNames.number),
      },
      date: {
        title: translations.invoicesPage.columns.date,
        key: invoicePropertyNames.date,
        dataIndex: invoicePropertyNames.date,
        render: (date: string) => displayAsDate(date, dateFormat),
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.invoicesPage.columns.date),
        onFilter: getOnFilterFunctionFor(invoicePropertyNames.date),
        sorter: getDateCompareFunctionFor(invoicePropertyNames.date),
      },
      total: {
        title: translations.invoicesPage.columns.total,
        key: invoicePropertyNames.total,
        dataIndex: invoicePropertyNames.total,
        render: (total: string) => <PriceValue value={+total} />,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(translations.invoicesPage.columns.total),
        isForNumber: true,
        onFilter: getOnFilterFunctionFor(invoicePropertyNames.total, true),
        sorter: getNumberCompareFunctionFor(invoicePropertyNames.total),
      },
      contact_names: {
        title: translations.invoicesPage.columns.contact_names,
        key: invoicePropertyNames.contact_names,
        render: renderInvoiceContactTags,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.invoicesPage.columns.contact_names
        ),
        onFilter: getOnFilterFunctionFor(invoicePropertyNames.contact_names),
      },
      status_name_key: {
        title: translations.invoicesPage.columns.status_name_key,
        key: invoicePropertyNames.status_name_key,
        render: (invoice: Invoice) => getStatusTag(invoice.status_name_key, invoiceStatusConfigs),
        ...generalFilteringAndSortingSettings,
        filters: getStatusFilters(invoiceStatusConfigs),
        onFilter: (value, record) => record.status_name_key === value,
        sorter: (a: Invoice, b: Invoice) => getInvoiceStatusText(a).localeCompare(getInvoiceStatusText(b)),
      },
      patient_name: {
        title: translations.invoicesPage.columns.patient_name,
        key: invoicePropertyNames.patient_name,
        dataIndex: invoicePropertyNames.patient_name,
        ...generalFilteringAndSortingSettings,
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.invoicesPage.columns.patient_name
        ),
        onFilter: getOnFilterFunctionFor(invoicePropertyNames.patient_name),
        sorter: getStringCompareFunctionFor(invoicePropertyNames.patient_name),
      },
    };
  }, [dateFormat]);
};
