import { isEmpty } from 'lodash';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { PaymentTypeReferenceDataType } from '../../../constants/referenceData/paymentReferenceData';
import { translations } from '../../../constants/translations';
import {
  ContactAlertType,
  ContactStatusType,
  FileType,
  GeneralLedger,
  LedgerType,
  NoteType,
  OrganizationUpsert,
  OrganizationUserType,
  PatientAlertType,
  PatientBreed,
  PatientColor,
  PatientGender,
  PatientSpecies,
  QuickNoteDto,
  RefDataUpsert,
  RefFileTypeRecordUpsert,
  RefNoteTypeRecordUpsert,
  ReminderStatus,
  TaxType,
} from '../../../graph/types';
import { getReminderStatusUpsert, hasOneOfEachDefaultType, hasOneOfEachType } from '../../../util/reminderStatusUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { ReferenceDataType, tabKeys } from '../refDataUtils';

export function buildReferenceDataUpsert(
  organizationId: string,
  data: ReferenceDataType,
  type: string,
  isVoid?: boolean,
  allValues?: ReferenceDataType[]
): OrganizationUpsert {
  const { id, sort_order } = data;
  let upsert: OrganizationUpsert;

  switch (type) {
    case tabKeys.species:
      upsert = {
        id: organizationId,
        ref_species: [getBasicRefDataUpsert(id, (data as PatientSpecies).name, sort_order, isVoid)],
      };
      break;
    case tabKeys.breeds:
      const breedData = data as PatientBreed;
      upsert = {
        id: organizationId,
        ref_species: [
          {
            id: breedData.species_id,
            ref_species_breed: [
              {
                id: id ?? '0',
                record: {
                  name: breedData.name,
                  sort_order: sort_order ?? 0,
                },
                void: isVoid,
              },
            ],
          },
        ],
      };

      break;
    case tabKeys.colors:
      upsert = {
        id: organizationId,
        ref_color: [getBasicRefDataUpsert(id, (data as PatientColor).name, sort_order, isVoid)],
      };
      break;
    case tabKeys.genders:
      const genderData = data as PatientGender;
      upsert = {
        id: organizationId,
        ref_species: [
          {
            id: genderData.species_id,
            ref_species_gender: [
              {
                id: id ?? 0,
                record: {
                  name: genderData.name,
                  sort_order: sort_order ?? 0,
                },
                void: isVoid,
              },
            ],
          },
        ],
      };
      break;
    case tabKeys.taxTypes:
      const taxTypeData = data as TaxType;
      upsert = {
        id: organizationId,
        ref_tax_type: [
          {
            id: id ?? '0',
            record: {
              name: taxTypeData.name,
              sort_order: sort_order ?? 0,
              percentage: taxTypeData.percentage?.toString(),
              compounded: taxTypeData.compounded ?? false,
              default: taxTypeData.default ?? false,
              code: taxTypeData.code,
              general_ledger_id: isEmpty(taxTypeData.general_ledger_id) ? null : taxTypeData.general_ledger_id,
              external_tax_id: taxTypeData.external_tax_id,
            },
            void: isVoid,
          },
        ],
      };
      break;
    case tabKeys.generalLedger:
      const generalLedgerData = data as GeneralLedger;
      const mainGLData = {
        id: id ?? '0',
        record: {
          name: generalLedgerData.name,
          sort_order: sort_order ?? 0,
          gl_number: generalLedgerData.gl_number,
          practice_id: generalLedgerData.practice_id,
          external_gl_id: generalLedgerData.external_gl_id,
          is_receivables_account: !!generalLedgerData.is_receivables_account,
        },
        void: isVoid,
      };
      const extraGLData = !generalLedgerData.is_receivables_account
        ? []
        : (allValues ?? [])
            .filter((gl) => (gl as GeneralLedger).is_receivables_account && gl.id !== id)
            .map((gl) => {
              const data = gl as GeneralLedger;
              return {
                id: data.id,
                record: {
                  name: data.name,
                  sort_order: data.sort_order,
                  gl_number: data.gl_number,
                  practice_id: data.practice_id,
                  external_gl_id: data.external_gl_id,
                  is_receivables_account: false,
                },
                void: isVoid,
              };
            });
      upsert = {
        id: organizationId,
        ref_general_ledger: [mainGLData, ...extraGLData],
      };
      break;

    case tabKeys.userTypes:
      const userTypeData = data as OrganizationUserType;
      upsert = {
        id: organizationId,
        ref_user_type: [
          {
            id: id ?? '0',
            record: {
              name: userTypeData.name,
              sort_order: sort_order ?? 0,
              doctor: userTypeData.doctor ?? false,
              caregiver: userTypeData.caregiver ?? false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.contactStatus:
      const contactStatusData = data as ContactStatusType;
      upsert = {
        id: organizationId,
        ref_contact_status: [
          {
            id: id ?? '0',
            record: {
              name: contactStatusData.name,
              sort_order: sort_order ?? 0,
              active: contactStatusData.active ?? false,
              warning: contactStatusData.warning ?? false,
              stop: contactStatusData.stop ?? false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.fileTypes:
      const fileData = data as FileType & Partial<RefFileTypeRecordUpsert>;
      upsert = {
        id: organizationId,
        ref_file_type: [
          {
            id: id ?? '0',
            record: {
              name: fileData.name,
              sort_order: sort_order ?? 0,
              contact: fileData.contact || false,
              patient: fileData.patient || false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.noteTypes:
      const noteData = data as NoteType & Partial<RefNoteTypeRecordUpsert>;
      const { contact, patient, contact_print, patient_print, invoice_print } = noteData;
      upsert = {
        id: organizationId,
        ref_note_type: [
          {
            id: id ?? '0',
            record: {
              name: noteData.name,
              sort_order: sort_order ?? 0,
              contact: contact || false,
              patient: patient || false,
              contact_print: contact_print || false,
              patient_print: patient_print || false,
              invoice_print: invoice_print || false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.quickNotes:
      const quickNoteData = data as QuickNoteDto;
      upsert = {
        id: organizationId,
        ref_note_type: [
          {
            id: quickNoteData.note_type_id,
            quick_note: [
              {
                id: quickNoteData.id ?? '0',
                record: {
                  organization_id: organizationId,
                  name: quickNoteData.name,
                  note: quickNoteData.note,
                  sort_order: quickNoteData.sort_order ?? 0,
                },
                void: isVoid,
              },
            ],
            void: false,
          },
        ],
      };
      break;

    case tabKeys.reminderStatus:
      upsert = {
        id: organizationId,
        ref_reminder_status: getReminderStatusUpsert(data as ReminderStatus, allValues as ReminderStatus[], isVoid),
      };
      break;

    case tabKeys.contactTypes:
      upsert = {
        id: organizationId,
        contact_type: [
          {
            child_id: id,
            ...(!(data as ReferenceDataType & { enabled: boolean }).enabled && { void: true }),
          },
        ],
      };
      break;

    case tabKeys.paymentTypes:
      const paymentType = data as PaymentTypeReferenceDataType;
      upsert = {
        id: organizationId,
        ledger_payment_type: [
          {
            child_id: id,
            general_ledger_id: isEmpty(paymentType.general_ledger_id) ? null : paymentType.general_ledger_id,
            ...(!(data as ReferenceDataType & { enabled: boolean }).enabled && { void: true }),
          },
        ],
      };
      break;

    case tabKeys.ledgerTypes:
      const ledgerTypeData = data as LedgerType;

      upsert = {
        id: organizationId,
        ledger_type: [
          {
            child_id: id,
            general_ledger_id: isEmpty(ledgerTypeData.general_ledger_id) ? null : ledgerTypeData.general_ledger_id,
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.patientAlerts:
      const patientAlertData = data as PatientAlertType;
      upsert = {
        id: organizationId,
        ref_patient_alert_type: [
          {
            id: id ?? '0',
            record: {
              name: patientAlertData.name,
              sort_order: sort_order ?? 0,
              prompt_invoice: patientAlertData.prompt_invoice ?? false,
              prompt_patient: patientAlertData.prompt_patient ?? false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    case tabKeys.contactAlerts:
      const contactAlertData = data as ContactAlertType;
      upsert = {
        id: organizationId,
        ref_contact_alert_type: [
          {
            id: id ?? '0',
            record: {
              name: contactAlertData.name,
              sort_order: sort_order ?? 0,
              prompt_invoice: contactAlertData.prompt_invoice ?? false,
              prompt_patient: contactAlertData.prompt_patient ?? false,
              prompt_contact: contactAlertData.prompt_contact ?? false,
            },
            void: isVoid,
          },
        ],
      };
      break;

    default:
      showErrorMessage(translations.shared.generalErrorMessage);
      upsert = {
        id: organizationId,
      };
  }
  return upsert;
}

const getBasicRefDataUpsert = (
  id: string,
  name: string,
  sort_order: number,
  isVoid: boolean | undefined
): RefDataUpsert => ({
  id: id ?? '0',
  record: {
    name,
    sort_order: sort_order ?? 0,
  },
  void: isVoid,
});

export const isRefDataValid = (
  data: ReferenceDataType,
  allValues: ReferenceDataType[],
  type: string,
  isVoid?: boolean
) => {
  switch (type) {
    case tabKeys.reminderStatus:
      if (!hasOneOfEachType(data as ReminderStatus, allValues as ReminderStatus[], isVoid)) {
        showErrorMessage(translations.referenceData.reminderStatusSystemStatusError);
        return false;
      }

      if (!hasOneOfEachDefaultType(data as ReminderStatus, allValues as ReminderStatus[], isVoid)) {
        showErrorMessage(translations.referenceData.reminderStatusSystemStatusDefaultError);
        return false;
      }
      break;
    default:
      break;
  }
  return true;
};

export const getPluralTaxonomyType = (type: TaxonomyType) => {
  if (type !== TaxonomyType.Species) {
    return `${type}s`;
  }
  return type;
};
