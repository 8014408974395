import { translations } from '../constants/translations';
import { OrganizationReferenceData } from '../graph/types';

export const mapToRefData = (record: {
  type_id: string | null | undefined;
  type_name: string | null | undefined;
}): OrganizationReferenceData => ({ id: record.type_id || '', name: record.type_name || '', sort_order: 0 });

export const mapRefData = (key: string | null | undefined, activeRecords: OrganizationReferenceData[] = []) => {
  if (!key) {
    return undefined;
  }

  return activeRecords.find((record) => record.id === key);
};

export const mapRefDataForSelect = (
  refData: { id: string; name: string },
  activeRecords: OrganizationReferenceData[] = []
) => {
  return mapRefData(refData.id, activeRecords)?.id ?? translations.shared.getVoidRecord(refData.name);
};

export const mapRefDataName = (
  refData: { id: string; name: string },
  activeRecords: OrganizationReferenceData[] = []
) => {
  return mapRefData(refData.id, activeRecords)?.name ?? translations.shared.getVoidRecord(refData.name);
};

export const getRefDataWithVoid = (
  refData: OrganizationReferenceData,
  activeRecords: OrganizationReferenceData[] = []
) => {
  if (!mapRefData(refData.id, activeRecords)) {
    return [
      ...activeRecords,
      {
        id: refData.id,
        name: translations.shared.getVoidRecord(refData.name),
        sort_order: 0,
      },
    ];
  }

  return activeRecords;
};
