import { QueryHookOptions } from '@apollo/client';
import dayjs from 'dayjs';
import { GetMetrics } from '../../../graph/queries/dashboard';
import { Maybe } from '../../../graph/types';
import { LOCALES } from '../../../util/locales';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';

export const useGetMetrics = (organizationId?: string, practiceId?: Maybe<string>, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getMetrics'>(GetMetrics, {
    variables: {
      organizationId,
      practiceId,
      options: {
        start_date: dayjs().subtract(1, 'year').startOf('year').format(LOCALES.CTRY_CANADA.dateFormat),
        end_date: dayjs().format(LOCALES.CTRY_CANADA.dateFormat),
      },
    },
    skip: !organizationId || !practiceId,
    fetchPolicy: 'no-cache',
    ...options,
  });

  return {
    metrics: data?.getMetrics,
    loading,
    error,
    metricsRefetch: refetch,
  };
};
