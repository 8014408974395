import { Maybe, RxScalars, RxSchemaTypeWithUpsert } from '../databaseUtils';
import { RxJsonSchema } from 'rxdb';
import { PrescriptionFlattened, PrescriptionUpsert } from '../../../graph/types';

export type RxPrescriptionFlattened = RxSchemaTypeWithUpsert<
  PrescriptionFlattened,
  PrescriptionUpsert,
  'prescription_flattened'
>;

export const prescriptionFlattenedSchema: RxJsonSchema<RxPrescriptionFlattened> = {
  version: 1,
  type: 'object',
  primaryKey: 'fill_id',
  properties: {
    id: RxScalars.ID,
    organization_id: RxScalars.ID,
    practice_id: RxScalars.ID,
    patient_id: Maybe(RxScalars.ID),
    offline_patient_id: Maybe(RxScalars.ID),
    contact_id: Maybe(RxScalars.ID),
    offline_contact_id: Maybe(RxScalars.ID),
    service_id: Maybe(RxScalars.ID),
    number: Maybe(RxScalars.String),
    refills: Maybe(RxScalars.Int),
    instructions: Maybe(RxScalars.String),
    item_quantity: RxScalars.String,
    item_description: Maybe(RxScalars.String),
    item_unit: Maybe(RxScalars.String),
    fill_externally: RxScalars.Boolean,
    created_type_id: RxScalars.Int,
    prescription_expiry_date: RxScalars.String,
    product_expiry_date: Maybe(RxScalars.String),
    prescribed_date: RxScalars.String,
    prescribed_user_id: RxScalars.ID,
    created: RxScalars.String,
    created_user_id: RxScalars.String,
    updated: RxScalars.String,
    updated_user_id: RxScalars.String,
    hidden: RxScalars.Boolean,
    offline_id: Maybe(RxScalars.ID),
    prescribed_user_name: Maybe(RxScalars.String),
    patient_name: Maybe(RxScalars.String),
    patient_number: Maybe(RxScalars.String),
    contact_name: Maybe(RxScalars.String),
    contact_number: Maybe(RxScalars.String),
    is_filled: RxScalars.Boolean,
    available_fills: RxScalars.Int,
    filled_fills: RxScalars.Int,
    remaining_fills: RxScalars.Int,
    invoice_id: Maybe(RxScalars.ID),
    fill_id: RxScalars.PrimaryID,
    filled_service_rendered_id: Maybe(RxScalars.ID),
    filled_item_din: Maybe(RxScalars.String),
    filled_item_lot_number: Maybe(RxScalars.String),
    filled_item_serial_number: Maybe(RxScalars.String),
    filled_item_bottle_number: Maybe(RxScalars.String),
    filled_item_manufacturer: Maybe(RxScalars.String),
    filled_item_instructions: Maybe(RxScalars.String),
    filled_date: Maybe(RxScalars.String),
    filled_user_id: Maybe(RxScalars.ID),
    filled_user_name: Maybe(RxScalars.String),
    upsert: Maybe(RxScalars.Object),
    upsert_offline_id: Maybe(RxScalars.String),
    is_new: RxScalars.Boolean,
  },
  required: ['id'],
  indexes: ['updated', 'prescribed_date'],
};
