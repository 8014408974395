import React from 'react';
import { StyledPageHeader } from '../../../../components/PageHeader/PageHeader.style';
import { renderInvoiceContactTags } from '../../invoiceContactTags';
import { translations } from '../../../../constants/translations';
import { Invoice } from '../../../../graph/types';
import './ViewInvoiceHeader.css';
import { Button } from 'antd';
import { withPatientIdParameter } from '../../../../hooks/route/navigationHooks';
import { routes } from '../../../../constants/routes';
import { isCompletedInvoiceStatus } from '../../../../constants/referenceData/invoiceReferenceData';
import { TagWithNavigation } from '../../../../components/TagWithNavigation/TagWithNavigation';
import { TagColor } from '../../../../constants/tagColor';
import Color from 'color';
import { useOffline } from '../../../../util/offline/offlineUtil';

const getInvoiceSubtitle = (invoice: Invoice, patientId: string) => {
  const color = TagColor.Color5;
  return (
    <TagWithNavigation
      displayText={translations.invoicesPage.addInvoiceModal.patientTag(
        invoice.patient_name ?? '',
        invoice.patient_number ?? ''
      )}
      navigationParameter={withPatientIdParameter(patientId)}
      navigationRoute={routes.viewPatient}
      style={{
        color,
        borderColor: Color(color).lightness(80).hex(),
        backgroundColor: Color(color).lightness(96).hex(),
      }}
    />
  );
};

export const viewInvoiceHeaderTestId = 'viewInvoiceHeader';
export const billToButtonTestId = 'billToButton';

export const ViewInvoiceHeader: React.FC<{
  invoice: Invoice;
  onBack: () => void;
  onModalOpen: () => void;
}> = ({ invoice, onBack, onModalOpen }) => {
  const title = `#${invoice.number} ${translations.invoicePage.invoice}`;
  const { isOnline } = useOffline();

  return (
    <StyledPageHeader
      className={'ViewInvoiceHeader'}
      title={title}
      onBack={onBack}
      footer={getInvoiceSubtitle(invoice, invoice.patient_id!)}
      tags={
        <div style={{ display: 'flex', alignItems: 'center' }} data-testid={viewInvoiceHeaderTestId}>
          {(isOnline || isNaN(+invoice.id)) && (
            <Button
              onClick={onModalOpen}
              disabled={isCompletedInvoiceStatus(invoice.status_id)}
              style={{ margin: '0 25px' }}
              type='primary'
              data-testid={billToButtonTestId}
            >
              {translations.invoicesPage.addInvoiceModal.fields.billTo}
            </Button>
          )}
          {renderInvoiceContactTags(invoice)}
        </div>
      }
    />
  );
};
