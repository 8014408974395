import {
  ApolloClient,
  ApolloError,
  DocumentNode,
  MutationFunctionOptions,
  OperationVariables,
  SubscriptionHookOptions,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import { SubscriptionUpsertGenerator } from '../../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import { UpsertAcceptEula, UpsertRetrySubscription, UpsertSubscription } from '../../../graph/queries/subscription';
import {
  DataConversionUpsert,
  Mutation,
  MutationAcceptEulaArgs,
  MutationRetrySubscriptionArgs,
  MutationUpdateOrganizationSubscriptionArgs,
  OrganizationSubscriptionLevelUpsert,
  OrganizationSubscriptionResult,
} from '../../../graph/types';
import { DEBOUNCE_TIME_FOR_FETCH_SUBSCRIPTION_CHARGE } from '../../../constants/queryConstants';
import { GetDataConversion } from '../../../graph/queries/dataConversion';
import { useOfflineErrorSkipMutation } from '../useOfflineErrorSkip';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { objToKey } from '../../../util/objectComparisons';
import { useOffline } from '../../../util/offline/offlineUtil';
import { ViewSubscriptionContext } from '../../../components/ViewSubscription/store/state';
import ViewSubscriptionActions from '../../../components/ViewSubscription/store/actions';
import { isEmpty } from 'lodash';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { getOrgDTOFromOrg } from '../../../util/organizationUtils';
import { setOrganization } from '../../../contexts/organization/action';

type OnSubscriptionData<TData> = (options: OnSubscriptionDataOptions<TData>) => any;

interface OnSubscriptionDataOptions<TData> {
  client: ApolloClient<any>;
  subscriptionData: SubscriptionHookResult<TData>;
}

export interface SubscriptionHookResult<TData> {
  data?: TData;
  error?: ApolloError;
  loading: boolean;
}

export const useSubscription = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options: SubscriptionHookOptions<TData, TVariables> = {}
) => {
  const client = useApolloClient();
  const [result, setResult] = useState<SubscriptionHookResult<TData>>({
    loading: true,
  });
  const { isOnline } = useOffline();
  const [ready, setReady] = useState<boolean>();
  const onSubscriptionDataRef = useRef<OnSubscriptionData<TData> | null | undefined>(null);
  const { state, dispatch } = useContext(ViewSubscriptionContext);

  onSubscriptionDataRef.current = options.onSubscriptionData;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!ready && isOnline) {
      // This delays initiating websocket connections.
      // Without the delay, you will see errors in any browser and the application will crash in FireFox.
      timeout = setTimeout(() => {
        setReady(true);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [isOnline, ready]);

  useEffect(() => {
    if (options.skip === true || !ready || state?.isInitiator) {
      dispatch?.(ViewSubscriptionActions.setIsInitiator(false));
      return undefined;
    }

    const subscription = client
      .subscribe({
        ...options,
        query,
      })
      .subscribe(
        (nextResult) => {
          const newResult = {
            data: nextResult.data,
            error: undefined,
            loading: false,
          };
          setResult(newResult);
          if (onSubscriptionDataRef.current) {
            onSubscriptionDataRef.current({
              client,
              subscriptionData: newResult,
            });
          }
        },
        ({ errors }) => {
          if (errors?.[0]?.message === 'Connection closed') {
            setReady(false);
          }
        }
      );

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, query, options && objToKey(options), ready, state?.isInitiator]);

  return result;
};

export const useUpdateSubscription = (organizationId: string, refetchDataConversion = false) => {
  const {
    state: { organization },
    dispatch,
  } = useOrganizationContext();
  return useMutation<Mutation, MutationUpdateOrganizationSubscriptionArgs>(UpsertSubscription, {
    onCompleted: (data) => {
      if (data?.updateOrganizationSubscription && organization) {
        const updatedOrganization = getOrgDTOFromOrg(data?.updateOrganizationSubscription.organization, organization);
        dispatch(setOrganization({ ...organization, ...updatedOrganization }));
      }
    },
    refetchQueries: [refetchDataConversion ? { query: GetDataConversion, variables: { organizationId } } : ''],
  });
};

export const useRetrySubscription = () => {
  return useMutation<Mutation, MutationRetrySubscriptionArgs>(UpsertRetrySubscription);
};

function fetchSubscriptionPricingAfterTimeout(
  organizationId: string,
  fetchData: (options?: MutationFunctionOptions<any, any>) => Promise<any>,
  details: OrganizationSubscriptionLevelUpsert[]
) {
  return setTimeout(
    () =>
      fetchData({
        variables: {
          organizationId,
          subscription: SubscriptionUpsertGenerator.generatePriceFetchUpsert({
            detail: details,
          }),
        },
      }),
    DEBOUNCE_TIME_FOR_FETCH_SUBSCRIPTION_CHARGE
  );
}

export const useFetchSubscriptionPricing = (
  organizationId: string,
  skipInitialLoad = false,
  loadOffline = false,
  loadQuickBooks = false,
  loadHisa = false
) => {
  const [fetchData, { data, loading }] = useOfflineErrorSkipMutation<
    'updateOrganizationSubscription',
    MutationUpdateOrganizationSubscriptionArgs
  >(UpsertSubscription);
  const [displayedData, setDisplayedData] = useState<OrganizationSubscriptionResult>();
  const [fetchDetails, setFetchDetails] = useState<OrganizationSubscriptionLevelUpsert[]>();

  useEffect(() => {
    if (organizationId && !skipInitialLoad) {
      const detail = [
        ...(loadOffline
          ? [
              {
                level_id:
                  organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.OfflineLevel].level_id,
              },
            ]
          : []),
        ...(loadQuickBooks
          ? [
              {
                level_id:
                  organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.QuickBooksLevel].level_id,
              },
            ]
          : []),
        ...(loadHisa
          ? [
              {
                level_id:
                  organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.HisaLevel].level_id,
              },
            ]
          : []),
      ];
      fetchData({
        variables: {
          organizationId,
          subscription: SubscriptionUpsertGenerator.generateUpsert({
            ...(!isEmpty(detail) && {
              detail,
            }),
            fnProcess: false,
          }),
        },
      });
    }
  }, [fetchData, organizationId, skipInitialLoad, loadOffline, loadQuickBooks, loadHisa]);

  useEffect(() => {
    if (data) {
      setDisplayedData(data?.updateOrganizationSubscription);
    }
  }, [data, setDisplayedData]);

  useEffect(() => {
    if (organizationId && fetchDetails) {
      const timeout = fetchSubscriptionPricingAfterTimeout(organizationId, fetchData, fetchDetails);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [fetchDetails, fetchData, organizationId, skipInitialLoad]);

  const fetchOrganizationSubscriptionLevelPrice = (
    upsertData: OrganizationSubscriptionLevelUpsert = { level_id: '' }
  ) => {
    if (!displayedData?.charge) {
      setFetchDetails([upsertData]);
    } else {
      const charges = displayedData?.charge ?? [];
      setFetchDetails(charges.map((charge) => SubscriptionUpsertGenerator.mapChargeToUpsert(charge, upsertData)));
    }
  };

  const fetchDataConversionPrice = (dataConversionProperties: DataConversionUpsert) => {
    fetchData({
      variables: {
        organizationId,
        subscription: SubscriptionUpsertGenerator.generatePriceFetchUpsert({
          dataConversionProperties,
          fnProcess: false,
        }),
      },
    });
  };

  return { data: displayedData, loading, fetchOrganizationSubscriptionLevelPrice, fetchDataConversionPrice };
};

export const useAcceptEula = () => {
  return useOfflineErrorSkipMutation<'acceptEULA', MutationAcceptEulaArgs>(UpsertAcceptEula);
};
