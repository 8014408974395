import styled from 'styled-components';

export const MessageWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
`;

export const Message = styled.p`
  margin-bottom: 1rem;
`;

export const MessageWrapperText = styled.div`
  p {
    font-weight: 600;
  }
`;
