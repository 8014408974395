import { Contact, RelatedContactEntryCurrent, RelatedOwner, RelatedOwnershipEntryAll } from '../../../../graph/types';
import { translations } from '../../../../constants/translations';
import { PatientContactRelationTypeNameKey } from '../../../../constants/referenceData/patientReferenceData';
import { BillToContext } from '../../../../components/BillTo/BillTo';

export const calculateTotalPercentage = (owners: readonly RelatedOwner[]) => {
  let totalPercentage = 0;
  owners.forEach(({ percentage }) => {
    totalPercentage += Number(percentage || 0);
  });
  return Number(totalPercentage.toFixed(2));
};

export const hasExactlyOnePrimaryOwner = (owners: readonly RelatedOwner[]): boolean => {
  return owners.filter((owner) => owner.primary).length === 1;
};

export const unsavedChangesMessageIfDisabled = (isDisabled: boolean | undefined) => {
  return isDisabled ? translations.patientPage.ownership.unsavedChangesInOtherOwnership : '';
};

export const tooltipToIndicateEditMode = (isDisabled: boolean) => {
  return isDisabled ? translations.patientPage.ownership.tooltipToIndicateEditMode : '';
};

const truncateDecimals = (numberToTruncate: number, decimalsToKeep: number) => {
  const numberAsString = numberToTruncate.toString();
  const separatorIndex = numberAsString.indexOf('.');
  if (separatorIndex === -1) {
    return numberToTruncate;
  }
  return Number(numberAsString.substring(0, separatorIndex + decimalsToKeep + 1));
};

export const removeOwnerAndSetDefault = (owners: RelatedOwner[], ownerIndex: number) => {
  let newOwners = [...owners];
  newOwners.splice(ownerIndex, 1);
  if (newOwners.length === 1) {
    const newSingleOwner: RelatedOwner = { ...newOwners[0], percentage: '100', primary: true };
    newOwners = [newSingleOwner];
  }
  return newOwners;
};

export const ownerExists = (owners: RelatedOwner[], contactToCheck: Contact) => {
  return owners.find((owner) => owner.contact_id === contactToCheck.id);
};

export const getDefaultRelationship = (
  defaultRelationshipId: string,
  relationships: RelatedContactEntryCurrent[],
  context: BillToContext = 'other'
) => {
  if (context === 'invoice') {
    return translations.invoicePage.editBillingModal.current;
  }
  return (
    (relationships.find((relationship) => relationship.contact_type_id === defaultRelationshipId)
      ?.contact_type_name_key as PatientContactRelationTypeNameKey) ?? PatientContactRelationTypeNameKey.Owner
  );
};

export const addOwnerAndSetDefault = (owners: RelatedOwner[], contactToAddAsOwner: Contact) => {
  const newRelatedOwner: RelatedOwner = buildRelatedOwnerFromContact(contactToAddAsOwner);
  if (owners.length === 0) {
    newRelatedOwner.percentage = '100';
    newRelatedOwner.primary = true;
  }
  return [...owners, newRelatedOwner];
};

const buildRelatedOwnerFromContact = (contact: Contact) => {
  return {
    contact_id: contact.id,
    offline_contact_id: contact.offline_id,
    name: contact.name,
    number: contact.number,
    percentage: '0',
    primary: false,
  };
};

export const setPrimaryOwner = (owners: RelatedOwner[], primaryIndex: number) => {
  const newOwners = owners.map((owner) => {
    return { ...owner, primary: false };
  });
  newOwners[primaryIndex].primary = true;
  return newOwners;
};

export const updatePercentageValue = (
  owners: RelatedOwner[],
  ownerIndex: number,
  input: number | string | null | undefined
) => {
  const newValue = truncateDecimals(Number(input), 2).toString();
  const updatedOwner = { ...owners[ownerIndex], percentage: newValue };
  const updatedOwners = [...owners];
  updatedOwners[ownerIndex] = updatedOwner;
  return updatedOwners;
};

export const isNewOwnership = (ownership: RelatedOwnershipEntryAll) => {
  return !ownership.syndicate_id;
};
