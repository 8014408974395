import { SearchOutlined } from '@ant-design/icons';
import { Input, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { useContext, useEffect, useState } from 'react';
import { match, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Loading } from '../../components/Loading/Loading';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { SelectContactModal } from '../../components/SelectContactModal/SelectContactModal';
import { Entity } from '../../components/SelectEntityModalTable/SelectEntityModalTable';
import SelectPatientModal from '../../components/SelectPatientModal/SelectPatientModal';
import TabsWithRouting, { TabsWithRoutingProps } from '../../components/TabsWithRouting/TabsWithRouting';
import { ViewSubscriptionContext } from '../../components/ViewSubscription/store/state';
import { routes } from '../../constants/routes';
import { translations } from '../../constants/translations';
import { PaddedRadio } from '../../globalStyles.style';
import {
  Contact,
  Patient,
  UserOrganizationCardsUpsert,
  UserOrganizationContactCard,
  UserOrganizationPatientCard,
} from '../../graph/types';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { useGetUserOrganizationCards, useUpsertUserOrganizationCards } from '../../hooks/ajax/user/userHooks';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { ContactIdParam, PatientIdParam, useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useFilterByKeysWithStringValues } from '../../util/filterUtil';
import { useOffline } from '../../util/offline/offlineUtil';
import { InvoicesContextProvider } from '../Invoices/InvoicesOverview/store/state';
import './DoctorOffice.less';
import {
  getUserUpsertForAddCard,
  getUpsertForAddedAndRemovedCards,
  getUserUpsertForRemoveEntityCard,
} from './doctorOfficeCardUpsertUtil';
import { DoctorOfficeContactTab } from './DoctorOfficeContactTab/DoctorOfficeContactTab';
import { DoctorOfficeHeaderFields } from './DoctorOfficeHeaderFields';
import { DoctorOfficeOverview } from './DoctorOfficeOverview/DoctorOfficeOverview';
import { DoctorOfficePatientTab } from './DoctorOfficePatientTab/DoctorOfficePatientTab';
import {
  contactCardGeneralRoute,
  EntityCard,
  getCardTabKey,
  getDoctorOfficeType,
  getRoutePerTabKeyForCards,
  joinCardNameAndNumber,
  OfficeType,
  patientCardGeneralRoute,
  setDoctorOfficeType,
} from './doctorOfficeUtil';
import { useUserContext } from '../../contexts/user/state';

const { TabPane } = Tabs;

const officeOptions = [
  { label: translations.doctorOffice.officeType.patients, value: OfficeType.patients },
  { label: translations.doctorOffice.officeType.contacts, value: OfficeType.contacts },
];

const StyledTabsWithRouting = styled(TabsWithRouting)<TabsWithRoutingProps>`
  overflow: visible;
`;

const getInitialOfficeType = (
  patientRoute: match<PatientIdParam> | null,
  contactRoute: match<ContactIdParam> | null
) => {
  if (patientRoute?.params?.patientId) {
    return OfficeType.patients;
  } else if (contactRoute?.params?.contactId) {
    return OfficeType.contacts;
  }
  const storedOfficeType = getDoctorOfficeType();
  if (storedOfficeType) {
    return storedOfficeType;
  }
  return OfficeType.patients;
};

export const DoctorOffice: React.FC = () => {
  const contactRoute = useRouteMatch<ContactIdParam>(contactCardGeneralRoute);
  const patientRoute = useRouteMatch<PatientIdParam>(patientCardGeneralRoute);
  const {
    state: { renderKey },
  } = useContext(ViewSubscriptionContext);
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { user },
  } = useUserContext();
  const userOrganizationId = user?.organization?.find(({ id }) => id === organizationId)?.id;
  const { userOrganizationCards, cardsLoading, cardsRefetch } = useGetUserOrganizationCards(
    organizationId,
    user?.id,
    userOrganizationId
  );
  const { navigateTo } = useNavigationToRoute();

  const [searchValue, setSearchValue] = useState<string>();
  const [officeType, setOfficeType] = useState<OfficeType>(getInitialOfficeType(patientRoute, contactRoute));
  const [updateUserCards] = useMutationWithMessages(useUpsertUserOrganizationCards);

  const [overviewActive, setOverviewActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [entityModalVisible, setEntityModalVisible] = useState(false);
  const userOrganization = user?.organization?.find((userOrg) => userOrg.organization_id === organizationId);

  const patientCards = userOrganizationCards?.patient_card || [];
  const openPatientIds = patientCards.map((patientCard) => patientCard.patient_id);

  const contactCards = userOrganizationCards?.contact_card ?? [];
  const openContactIds = contactCards.map((card) => card.contact_id);

  const filteredPatientCards = useFilterByKeysWithStringValues(patientCards, searchValue, [
    'patient_name',
    'patient_number',
  ]);

  const filteredContactCards = useFilterByKeysWithStringValues(contactCards, searchValue, [
    'contact_name',
    'contact_number',
  ]);

  const isPatientBased = officeType === OfficeType.patients;

  const entityCards: EntityCard[] = isPatientBased ? patientCards : contactCards;
  const filteredEntityCards: EntityCard[] = isPatientBased ? filteredPatientCards : filteredContactCards;
  const openEntityIds = isPatientBased ? openPatientIds : openContactIds;
  const { isOnline } = useOffline();

  useEffect(() => {
    const runEffect = async () => {
      if (renderKey > 1) {
        try {
          await cardsRefetch();
        } catch (_) {}
      }
    };

    runEffect();
  }, [renderKey, cardsRefetch]);

  useEffect(() => {
    setDoctorOfficeType(officeType);
  }, [officeType]);

  if (cardsLoading) {
    return <Loading />;
  }

  if (!isOnline) {
    return <p>{translations.doctorOffice.offline}</p>;
  }

  if (!user || !userOrganization) {
    return <p>{translations.shared.errorLoadingPage}</p>;
  }

  const handleChangeInPatientSelect = async (patientId: string) => {
    if (openPatientIds.includes(patientId)) {
      showErrorMessage(translations.doctorOffice.errorMessageCardAlreadyOpen);
    } else {
      const userUpsert = getUserUpsertForAddCard(userOrganization?.id, patientId, officeType);
      await sendCardsUpsertToBackend(userUpsert, translations.doctorOffice.addCardSuccessMessage(officeType));
    }
  };

  const closeEntityCard = async (cardId: string) => {
    const userUpsert = getUserUpsertForRemoveEntityCard(userOrganization?.id, cardId, officeType);
    await sendCardsUpsertToBackend(userUpsert, translations.doctorOffice.removeCardSuccessMessage(officeType));
  };

  const handleRemoveTab = async (event: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
    if (action === 'remove' && typeof event === 'string') {
      await closeEntityCard(event);
    }
  };

  const handleChangeTabsViaModal = async (entities: Entity[]) => {
    const selectedEntityIds = entities.map((e) => e.id);

    const newlyAddedIds = selectedEntityIds.filter((id) => !openEntityIds.includes(id));
    const removedCardIds = entityCards
      .filter((card) => {
        return !selectedEntityIds.includes(
          isPatientBased
            ? (card as UserOrganizationPatientCard).patient_id
            : (card as UserOrganizationContactCard).contact_id
        );
      })
      .map((card) => card.id);

    const userUpsert = getUpsertForAddedAndRemovedCards(userOrganization.id, newlyAddedIds, removedCardIds, officeType);
    await sendCardsUpsertToBackend(userUpsert, translations.doctorOffice.updateCardSuccessMessage(officeType));
  };

  const closeAllTabs = async () => {
    const openCardIds = entityCards.map((card) => card.id);
    const userUpsert = getUpsertForAddedAndRemovedCards(userOrganization.id, [], openCardIds, officeType);
    await sendCardsUpsertToBackend(userUpsert, translations.doctorOffice.closedAllCardsSuccessMessage(officeType));
  };

  const sendCardsUpsertToBackend = async (cardsUpsert: UserOrganizationCardsUpsert, successMessage: string) => {
    setIsSaving(true);
    await updateUserCards({
      options: {
        variables: { organizationId, cards: cardsUpsert },
      },
      successMessage,
    });
    setIsSaving(false);
  };

  const renderEntitySelectModal = () => {
    return officeType === OfficeType.patients ? (
      <SelectPatientModal
        title={translations.shared.selectPatientModal.title}
        canSelectMultiple
        initialSelectedPatients={patientCards.map(
          (card) => ({ id: card.patient_id, name: card.patient_name, number: card.patient_number } as Patient)
        )}
        finishSelectPatients={async (patients: Patient[]) => {
          setEntityModalVisible(false);
          await handleChangeTabsViaModal(patients);
        }}
        onCancel={() => setEntityModalVisible(false)}
      />
    ) : (
      <SelectContactModal
        title={translations.shared.selectContactModal.title}
        onCancel={() => setEntityModalVisible(false)}
        finishSelectContacts={async (contacts: Contact[]) => {
          setEntityModalVisible(false);
          await handleChangeTabsViaModal(contacts);
        }}
        canSelectMultiple
        initialSelectedContacts={contactCards.map(
          (card) => ({ id: card.contact_id, name: card.contact_name, number: card.contact_number } as Contact)
        )}
        visible
      />
    );
  };

  const overviewTabTitle = (
    <div
      className={`DoctorOffice__OverviewTabTitle ${overviewActive ? 'DoctorOffice__OverviewTabTitle--selected' : ''}`}
      onClick={() => navigateTo(routes.doctorOffice)}
      key={'doctorOfficeOverviewTabTitle'}
    >
      {translations.doctorOffice.overviewTab}
    </div>
  );

  const searchBox = (
    <Input
      size={'small'}
      style={{ width: 200, marginLeft: 10, marginRight: 10 }}
      placeholder={translations.doctorOffice.searchFieldPlaceholder(officeType)}
      allowClear
      onChange={(event) => setSearchValue(event.target.value)}
      suffix={<SearchOutlined />}
      key={'doctorOfficeSearchBox'}
    />
  );

  return (
    <SaveSpinnerAndNavigationWarning isSaving={isSaving} showNavigationWarning={false}>
      <PageHeader
        title={translations.doctorOffice.getTitle(user)}
        extra={
          <DoctorOfficeHeaderFields
            handleChangeInPatientSelect={handleChangeInPatientSelect}
            closeAllTabs={closeAllTabs}
            setSelectedPatientModalVisible={setEntityModalVisible}
            officeType={officeType}
          />
        }
      />
      <PaddedRadio
        buttonStyle='solid'
        optionType='button'
        options={officeOptions}
        value={officeType}
        onChange={({ target: { value } }) => setOfficeType(value)}
      />
      {entityModalVisible && renderEntitySelectModal()}

      <StyledTabsWithRouting
        type='editable-card'
        hideAdd
        tabBarExtraContent={{ left: [overviewTabTitle, searchBox] }}
        routePerTabKey={getRoutePerTabKeyForCards(filteredEntityCards)}
        noTabSelectedContent={
          <DoctorOfficeOverview
            entityCards={filteredEntityCards}
            onActiveChange={setOverviewActive}
            closeEntityCard={closeEntityCard}
            officeType={officeType}
          />
        }
        forcedRouteIfNoTabSelected={{ path: routes.doctorOffice, exact: true }}
        onEdit={handleRemoveTab}
      >
        {filteredEntityCards.map((card) => (
          <TabPane tab={joinCardNameAndNumber(card)} key={getCardTabKey(card)}>
            {isPatientBased ? (
              <InvoicesContextProvider>
                <DoctorOfficePatientTab
                  patientCard={card as UserOrganizationPatientCard}
                  closeEntityCard={closeEntityCard}
                />
              </InvoicesContextProvider>
            ) : (
              <InvoicesContextProvider>
                <DoctorOfficeContactTab
                  contactCard={card as UserOrganizationContactCard}
                  closeEntityCard={closeEntityCard}
                />
              </InvoicesContextProvider>
            )}
          </TabPane>
        ))}
      </StyledTabsWithRouting>
    </SaveSpinnerAndNavigationWarning>
  );
};
