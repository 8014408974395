import { print } from 'graphql';
import {
  Contact,
  ContactAlertUpsert,
  ContactTaxType,
  ContactUpsert,
  Country,
  InfoTextUpsert,
  Maybe,
  Organization,
  OrganizationDto,
  PhoneUpsert,
  SystemReferenceDataTypes,
} from '../../../graph/types';
import {
  elasticQueryBuilder,
  getOfflineId,
  getUpdatedDate,
  OfflineUpsertProps,
  replaceExistingRecords,
} from './queryUtils';
import { GetContactsForOffline } from '../../../graph/queries/contacts';
import { RxContact } from '../schemas/contactSchema';
import {
  mapAddressUpsertToAddress,
  mapAddressUpsertToAddressDto,
} from '../../../classes/upsertGenerators/AddressUpsertGenerator';
import { mapPhoneUpsertToPhone } from '../../../classes/upsertGenerators/PhoneUpsertGenerator';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import pick from 'lodash/pick';
import { excludeExisting } from '../../../util/filterUtil';
import { sendTypes } from '../../../constants/referenceData/statementRunReferenceData';
import { getConnectionId } from '../../../hooks/authHooks';
import { OfflineUpsert } from '../../../graph/queries/general';
import { v4 as uuid } from 'uuid';
import { RxInfoText } from '../schemas/infoTextSchema';
import { communicationTypes } from '../../../constants/referenceData/contactReferenceData';
import { addOfflineContactProperties, getContactDocument } from '../../../util/offline/offlineQueryUtils';

export const contactQueryBuilder = (organizationId: string) =>
  elasticQueryBuilder<Contact>(organizationId, GetContactsForOffline);

export const getContactPushVariables = (organizationId: string, contact: RxContact) => ({
  variables: {
    organizationId,
    upsert: {
      record: {
        type_name: 'upsertContact',
        offline_id: contact.upsert_offline_id,
        connection_id: getConnectionId(),
        instruction: JSON.stringify({
          ...cleanUpsert(contact.upsert ?? {}),
          id: contact.is_new ? ID_FOR_OBJECT_CREATION : contact.id,
        }),
      },
    },
  },
});

export const contactPushBuilder = (organizationId: string) => (contact: RxContact) => ({
  query: print(OfflineUpsert),
  ...getContactPushVariables(organizationId, contact),
});

const cleanUpsert = (upsert: ContactUpsert) => ({
  ...upsert,
  phone: upsert.phone?.map((p) => ({
    ...p,
    id: !p.id || isNaN(+p.id) ? ID_FOR_OBJECT_CREATION : p.id,
  })),
  alert: upsert.alert?.map((a) => ({
    ...a,
    id: !a.id || isNaN(+a.id) ? ID_FOR_OBJECT_CREATION : a.id,
  })),
});

export const getContactInsert = (
  contactUpsert: ContactUpsert,
  organization: Organization,
  referenceData?: Maybe<SystemReferenceDataTypes> | undefined
): RxContact => {
  const offlineId = getOfflineId();
  const upsert = { ...contactUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
  }
  if (upsert.phone) {
    upsert.phone = upsert.phone.map(mapToUUID);
  }
  const contact_status_id = upsert.record?.contact_status_id ?? '';
  const currentDate = getUpdatedDate();

  const refDemographics = organization.ref_demographics;
  const refContact = organization.ref_contact;

  const contact: RxContact = {
    id: offlineId,
    upsert,
    upsert_offline_id: uuid(),
    organization_id: organization.id,
    name: upsert.record?.name ?? '',
    email: upsert.record?.email,
    phone: upsert.phone?.length ? mapPhoneUpsertToPhone(upsert.phone.map(mapToUUID)) : [],
    contact_status_id,
    contact_status_name: refContact?.status_type?.find((status) => status.id === contact_status_id)?.name ?? '',
    balance_unposted: '0',
    balance_posted: '0',
    info: [],
    text: upsert.text?.[0].record ? [{ id: upsert.text[0].record.offline_id }] : [],
    type: [],
    tag: [],
    send_type: upsert.record?.send_type ?? sendTypes.print.value,
    statement_ignore: !!upsert.record?.statement_ignore,
    interest_ignore: !!upsert.record?.interest_ignore,
    tax_exempt: upsert.record?.tax_exempt ? [dummyTaxExemptForOffline] : undefined,
    address: upsert.address?.length && refDemographics ? mapAddressUpsertToAddress(upsert.address, referenceData) : [],
    offline_id: offlineId,
    updated: currentDate,
    hidden: false,
    is_new: true,
    document: '',
    communication: [
      {
        id: ID_FOR_OBJECT_CREATION,
        communication_type_id: communicationTypes.statementRun.value,
        send_type: 1,
      },
      {
        id: ID_FOR_OBJECT_CREATION,
        communication_type_id: communicationTypes.reminderRun.value,
        send_type: 1,
      },
    ],
  };

  contact.document = getContactDocument(contact);

  return contact;
};

export const getContactInsertDto = (
  contactUpsert: ContactUpsert,
  organization: OrganizationDto,
  country?: Maybe<Country[]> | undefined
): RxContact => {
  const offlineId = getOfflineId();
  const upsert = { ...contactUpsert };
  if (upsert.record) {
    upsert.record.offline_id = offlineId;
  }
  if (upsert.phone) {
    upsert.phone = upsert.phone.map(mapToUUID);
  }
  const contact_status_id = upsert.record?.contact_status_id ?? '';
  const currentDate = getUpdatedDate();

  const refContact = organization.ref_contact;

  const contact: RxContact = {
    id: offlineId,
    upsert,
    upsert_offline_id: uuid(),
    organization_id: organization.id,
    name: upsert.record?.name ?? '',
    email: upsert.record?.email,
    phone: upsert.phone?.length ? mapPhoneUpsertToPhone(upsert.phone.map(mapToUUID)) : [],
    contact_status_id,
    contact_status_name: refContact?.status_type?.find((status) => status.id === contact_status_id)?.name ?? '',
    balance_unposted: '0',
    balance_posted: '0',
    info: [],
    text: upsert.text?.[0].record ? [{ id: upsert.text[0].record.offline_id }] : [],
    type: [],
    tag: [],
    send_type: upsert.record?.send_type ?? sendTypes.print.value,
    statement_ignore: !!upsert.record?.statement_ignore,
    interest_ignore: !!upsert.record?.interest_ignore,
    tax_exempt: upsert.record?.tax_exempt ? [dummyTaxExemptForOffline] : undefined,
    address: upsert.address?.length && country ? mapAddressUpsertToAddressDto(upsert.address, country) : [],
    offline_id: offlineId,
    updated: currentDate,
    hidden: false,
    is_new: true,
    document: '',
    communication: [
      {
        id: ID_FOR_OBJECT_CREATION,
        communication_type_id: communicationTypes.statementRun.value,
        send_type: 1,
      },
      {
        id: ID_FOR_OBJECT_CREATION,
        communication_type_id: communicationTypes.reminderRun.value,
        send_type: 1,
      },
    ],
  };

  contact.document = getContactDocument(contact);

  return contact;
};

export const getOfflineContactTextUpsert = (textUpsert: InfoTextUpsert, offlineId?: string | null): RxInfoText => {
  const { id, record } = textUpsert;

  return {
    id: offlineId ?? id ?? '',
    value: record?.value ?? '',
    type_id: '1',
    updated: getUpdatedDate(),
    offline_id: offlineId ?? null,
  };
};

const dummyTaxExemptForOffline: ContactTaxType = {
  id: ID_FOR_OBJECT_CREATION,
  name: 'Dummy',
  compounded: false,
  default: true,
  percentage: '100',
  sort_order: 0,
};

export const mapToUUID = <T extends PhoneUpsert | ContactAlertUpsert>(obj: T): T => ({
  ...obj,
  id: obj.id === ID_FOR_OBJECT_CREATION ? uuid() : obj.id,
});

export const getContactUpdate = (
  rxContact: RxContact,
  contactUpsert: ContactUpsert,
  referenceData?: Maybe<SystemReferenceDataTypes> | undefined
) => {
  const { phone, address, text, ...contactUpsertRest } = contactUpsert;
  const newUpsert = { ...rxContact.upsert, ...contactUpsertRest };
  if (phone) {
    if (newUpsert.phone) {
      if (phone.find((p) => p.primary)) {
        // Set all other phones to be non-primary
        newUpsert.phone = newUpsert.phone.map((p) => ({ ...p, primary: false }));
      }
      newUpsert.phone = [...excludeExisting(phone, newUpsert.phone), ...phone.map(mapToUUID)];
    } else {
      newUpsert.phone = phone.map(mapToUUID);
    }
  }
  if (address) {
    if (newUpsert.address) {
      newUpsert.address = [...excludeExisting(address, newUpsert.address), ...address];
    } else {
      newUpsert.address = address;
    }
  }

  if (newUpsert.record) {
    newUpsert.record.offline_id = rxContact.upsert?.record?.offline_id;
  }

  if (text) {
    if (rxContact.upsert?.text) {
      newUpsert.text = rxContact.upsert.text.filter((t) => t.void).concat(text.filter((t) => !t.void));
    } else {
      newUpsert.text = text;
    }
  }

  const updatedText = text?.find((t) => !t.void)?.record?.offline_id;

  const propsToUpdate: OfflineUpsertProps<RxContact> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    tax_exempt: newUpsert.record?.tax_exempt ? [dummyTaxExemptForOffline] : undefined,
    ...(updatedText
      ? {
          text: [
            {
              id: text?.find((t) => !t.void)?.record?.offline_id,
            },
          ],
        }
      : {}),
  };

  if (newUpsert.record) {
    Object.assign(
      propsToUpdate,
      pick(newUpsert.record, 'name', 'contact_status_id', 'email', 'send_type', 'statement_ignore', 'interest_ignore')
    );
    propsToUpdate.document = getContactDocument(propsToUpdate as RxContact);
  }
  if (newUpsert.phone?.length) {
    let existingPhones = rxContact.phone ?? [];
    if (newUpsert.phone.find((p) => p.primary)) {
      // Set all other phones to be non-primary
      existingPhones = existingPhones.map((p) => ({ ...p, primary: false }));
    }
    propsToUpdate.phone = mapPhoneUpsertToPhone(newUpsert.phone, existingPhones);
  }
  if (newUpsert.address?.length) {
    propsToUpdate.address = mapAddressUpsertToAddress(newUpsert.address, referenceData, rxContact.address ?? []);
  }
  if (newUpsert.communication) {
    propsToUpdate.communication = newUpsert.communication.map((c) => {
      const communicationEntry = rxContact.communication?.find(
        (com) => com.communication_type_id === c.record?.communication_type_id
      );
      return {
        id: c.id ?? communicationEntry?.id ?? '',
        communication_type_id: c.record?.communication_type_id ?? 1,
        send_type: c.record?.send_type ?? communicationEntry?.send_type ?? 1,
        primary_address: c.record?.primary_address,
        secondary_address: c.record?.secondary_address,
      };
    });
  }

  if (newUpsert.text && newUpsert.text?.[0].id === ID_FOR_OBJECT_CREATION) {
    // Updating for when contact note added offline to existing contact
    propsToUpdate.text = [{ id: newUpsert.text[0].record?.offline_id }];
  }

  if (newUpsert.third_party) {
    if (newUpsert.third_party[0].void) {
      propsToUpdate.third_party = (rxContact.third_party ?? []).filter(
        ({ id }) => id !== newUpsert.third_party?.[0].id
      );
    } else {
      propsToUpdate.third_party = [
        ...(rxContact.third_party ?? []),
        { id: uuid(), third_party_id: newUpsert.third_party[0].record?.third_party_id ?? '', contact_id: rxContact.id },
      ];
    }
  }

  return propsToUpdate;
};

export const getContactUpdateDto = (
  rxContact: RxContact,
  contactUpsert: ContactUpsert,
  country?: Maybe<Country[]> | undefined
) => {
  const { phone, address, text, alert, ...contactUpsertRest } = contactUpsert;
  const newUpsert = { ...rxContact.upsert, ...contactUpsertRest };
  if (phone) {
    if (newUpsert.phone) {
      if (phone.find((p) => p.primary)) {
        // Set all other phones to be non-primary
        newUpsert.phone = newUpsert.phone.map((p) => ({ ...p, primary: false }));
      }
      newUpsert.phone = [...excludeExisting(phone, newUpsert.phone), ...phone.map(mapToUUID)];
    } else {
      newUpsert.phone = phone.map(mapToUUID);
    }
  }
  if (address) {
    if (newUpsert.address) {
      newUpsert.address = [...excludeExisting(address, newUpsert.address), ...address];
    } else {
      newUpsert.address = address;
    }
  }
  if (alert) {
    if (newUpsert.alert) {
      newUpsert.alert = [...excludeExisting(alert, newUpsert.alert), ...alert].reverse().map(mapToUUID);
    } else {
      newUpsert.alert = alert.map(mapToUUID);
    }
  }

  if (newUpsert.record) {
    newUpsert.record.offline_id = rxContact.upsert?.record?.offline_id;
  }

  if (text) {
    if (rxContact.upsert?.text) {
      newUpsert.text = rxContact.upsert.text.filter((t) => t.void).concat(text.filter((t) => !t.void));
    } else {
      newUpsert.text = text;
    }
  }

  const updatedText = text?.find((t) => !t.void)?.record?.offline_id;
  const currentDate = getUpdatedDate();

  const propsToUpdate: OfflineUpsertProps<RxContact> = {
    upsert: newUpsert,
    upsert_offline_id: uuid(),
    tax_exempt: newUpsert.record?.tax_exempt ? [dummyTaxExemptForOffline] : undefined,
    ...(updatedText
      ? {
          text: [
            {
              id: text?.find((t) => !t.void)?.record?.offline_id,
            },
          ],
        }
      : {}),
    updated: currentDate,
  };

  if (newUpsert.record) {
    Object.assign(
      propsToUpdate,
      pick(newUpsert.record, 'name', 'contact_status_id', 'email', 'send_type', 'statement_ignore', 'interest_ignore')
    );
    propsToUpdate.document = getContactDocument(propsToUpdate as RxContact);
  }
  if (newUpsert.phone?.length) {
    let existingPhones = rxContact.phone ?? [];
    if (newUpsert.phone.find((p) => p.primary)) {
      // Set all other phones to be non-primary
      existingPhones = existingPhones.map((p) => ({ ...p, primary: false }));
    }
    propsToUpdate.phone = mapPhoneUpsertToPhone(newUpsert.phone, existingPhones);
  }
  if (newUpsert.address?.length) {
    propsToUpdate.address = mapAddressUpsertToAddressDto(newUpsert.address, country, rxContact.address ?? []);
  }
  if (newUpsert.communication) {
    propsToUpdate.communication = newUpsert.communication.map((c) => {
      const communicationEntry = rxContact.communication?.find(
        (com) => com.communication_type_id === c.record?.communication_type_id
      );
      return {
        id: c.id ?? communicationEntry?.id ?? '',
        communication_type_id: c.record?.communication_type_id ?? 1,
        send_type: c.record?.send_type ?? communicationEntry?.send_type ?? 1,
        primary_address: c.record?.primary_address,
        secondary_address: c.record?.secondary_address,
      };
    });
  }

  if (newUpsert.text && newUpsert.text?.[0].id === ID_FOR_OBJECT_CREATION) {
    // Updating for when contact note added offline to existing contact
    propsToUpdate.text = [{ id: newUpsert.text[0].record?.offline_id }];
  }

  if (newUpsert.third_party) {
    if (newUpsert.third_party[0].void) {
      propsToUpdate.third_party = (rxContact.third_party ?? []).filter(
        ({ id }) => id !== newUpsert.third_party?.[0].id
      );
    } else {
      propsToUpdate.third_party = [
        ...(rxContact.third_party ?? []),
        { id: uuid(), third_party_id: newUpsert.third_party[0].record?.third_party_id ?? '', contact_id: rxContact.id },
      ];
    }
  }

  if (newUpsert.alert?.length) {
    const existingAlerts = rxContact.alert ?? [];

    if (newUpsert.alert?.[0].void) {
      propsToUpdate.alert = existingAlerts.filter(({ id }) => id !== newUpsert.alert?.[0].id);
    } else {
      propsToUpdate.alert = existingAlerts
        .filter(({ id }) => id !== newUpsert.alert?.[0].id)
        .concat([
          {
            id: newUpsert.alert[0].id ?? '',
            type_id: newUpsert.alert[0].record?.type_id ?? '',
            note: newUpsert.alert[0].record?.note,
            contact_id: rxContact.id,
            organization_id: rxContact.organization_id,
          },
        ]);
    }
  }

  return propsToUpdate;
};

export const onContactUpdate = async (contact: Contact): Promise<RxContact> => {
  await replaceExistingRecords(contact, 'contact');
  return addOfflineContactProperties(contact);
};
