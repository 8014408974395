import { Modal, Table } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { JournalDetails } from '../../graph/types';
import { translations } from '../../constants/translations';
import { PriceValue } from '../../components/PriceValue/PriceValue';
import { useOrganizationContext } from '../../contexts/organization/state';
import { useGetQuickbooksAccounts } from '../../hooks/ajax/quickBooks/quickBooksHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useQuickBooksAligned } from '../../util/thirdPartyUtil';
import { LoadingOutlined } from '@ant-design/icons';
import { QuickBooksAuth } from '../../components/QuickBooksAuth/QuickBooksAuth';

type JournalModalProps = {
  onClose: () => void;
  details: JournalDetails[];
  journalNumber: string;
};

export const JournalModal: React.FC<JournalModalProps> = ({ details, journalNumber, onClose }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();

  const { isQuickBooksAligned } = useQuickBooksAligned(organization);
  const [needsQbAuth, setNeedsQbAuth] = useState(false);

  const practiceId = organization?.default_practice_id;

  const { quickbooksAccounts, accountsLoading, refetchQBAccounts } = useGetQuickbooksAccounts(
    organizationId,
    !isQuickBooksAligned,
    practiceId ?? undefined,
    useCallback(() => setNeedsQbAuth(true), [])
  );

  const QuickBooksAuthComponent = useMemo(
    () => <QuickBooksAuth organizationId={organizationId} onClose={refetchQBAccounts} onFinish={refetchQBAccounts} />,
    [refetchQBAccounts, organizationId]
  );

  const getAmount = (total: string) => total && <PriceValue value={Math.abs(+total)} />;
  const calculateTotal = (details: JournalDetails[], condition: (amt: number) => boolean) => {
    return (
      details?.reduce((acc, detail) => {
        return condition(+detail.amount) ? acc + Math.abs(+detail.amount) : acc;
      }, 0) ?? 0
    );
  };

  const debitTotal = calculateTotal(details, (amount: number) => amount >= 0);
  const creditTotal = calculateTotal(details, (amount: number) => amount < 0);

  const qbAccountsMap = new Map(
    quickbooksAccounts?.data
      ?.filter(({ id }) => id)
      .map((account) => [account.id, `${account.id} ${account.name ? `- ${account.name}` : ''}`])
  );

  const glAccountsMap = new Map(
    organization?.ref_financial.general_ledger?.map((gl) => [gl.id, `${gl.gl_number} ${gl.name ? `- ${gl.name}` : ''}`])
  );

  const columns = [
    {
      title: translations.financialPeriods.viewJournalModal.columns.generalLedger,
      dataIndex: 'general_ledger_id',
      key: 'general_ledger_id',
      render: (id: string) => {
        return id === translations.financialPeriods.viewJournalModal.columns.total ? (
          <b>{id}</b>
        ) : (
          glAccountsMap.get(id)
        );
      },
      width: 300,
    },
    {
      title: translations.financialPeriods.viewJournalModal.columns.externalAccount,
      dataIndex: 'external_gl_id',
      key: 'external_gl_id',
      render: (id: string) => (accountsLoading && !!id ? <LoadingOutlined /> : qbAccountsMap.get(id)),
      width: 300,
    },
    {
      title: translations.financialPeriods.viewJournalModal.columns.description,
      dataIndex: 'description',
      key: 'description',
      width: 300,
    },
    {
      title: translations.financialPeriods.viewJournalModal.columns.debit,
      dataIndex: 'debit',
      key: 'debit',
      render: getAmount,
    },
    {
      title: translations.financialPeriods.viewJournalModal.columns.credit,
      dataIndex: 'credit',
      key: 'credit',
      render: getAmount,
    },
  ];

  const journalDetails = details
    .map((detail) => {
      return {
        ...detail,
        key: detail.id,
        debit: +detail.amount > 0 && +detail.amount,
        credit: +detail.amount < 0 && Math.abs(+detail.amount),
      };
    })
    .sort((a, b) => {
      return a.line_number - b.line_number;
    })
    .concat([
      {
        id: 'total',
        key: 'total',
        general_ledger_id: translations.financialPeriods.viewJournalModal.columns.total,
        external_gl_id: '',
        journal_id: '',
        line_number: 0,
        description: '',
        debit: debitTotal,
        credit: creditTotal,
        amount: '0',
      },
    ]);

  return (
    <>
      <Modal
        title={translations.financialPeriods.viewJournalModal.title(journalNumber)}
        onCancel={onClose}
        open
        closable={false}
        okButtonProps={{ hidden: true }}
        width={900}
      >
        <Table<JournalDetails>
          columns={columns}
          dataSource={journalDetails}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 10,
          }}
        />
      </Modal>
      {needsQbAuth && QuickBooksAuthComponent}
    </>
  );
};
