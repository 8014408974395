import { useEffect } from 'react';
import { Userpilot } from 'userpilot';
import { useHistory } from 'react-router-dom';
import config from '../../config/config';
import { useGetCurrentUserWithOrganization } from '../ajax/user/userHooks';
import { Organization } from '../../graph/types';
import { useOffline } from '../../util/offline/offlineUtil';

export const useUserpilot = (organization?: Organization) => {
  const history = useHistory();
  const { user } = useGetCurrentUserWithOrganization();
  const { isOnline } = useOffline();

  useEffect(() => {
    if (isOnline) {
      Userpilot.initialize(config.USERPILOT_TOKEN);
    }
  }, [isOnline]);

  useEffect(() => {
    if (isOnline && user && organization) {
      Userpilot.identify(user.id, {
        name: user.name,
        email: user.email,
        created_at: user.created,
        company: { id: organization.id, name: organization.name, created_at: organization.created },
      });
    }
  }, [organization, user, isOnline]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }
    history.listen((location) => {
      Userpilot.reload(location.pathname);
    });
  }, [history, isOnline]);
};
