import React, { useEffect, useMemo, useState } from 'react';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { CreateTokenCardData, loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { StripeElement } from './StripeTokenizer.style';
import { CreditCardTokenInfo, useGetStripeToken } from '../../hooks/stripeHooks';
import { DemographicReferenceData } from '../../graph/types';
import { countryProvStateTranslations } from '../../constants/countryTranslations';
import { countryCurrency } from '../../constants/countryCurrencies';
import { CountryId } from '../../constants/countries';
import { getCardTypeByStripeName } from '../../constants/referenceData/ledgerReferenceData';
import { showErrorMessage } from '../Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { getStripePublishableKey } from '../../config/config';

export type StripeTokenizerExtraDataProps = {
  payer?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  countryId?: string;
  provStateId?: string;
  postalZip?: string;
};

type StripeTokenizerProps = {
  stripeTokenizerCountryId: string | undefined;
  setCreditCard: (card: CreditCardTokenInfo | undefined) => void;
  addressDemographics?: DemographicReferenceData;
  tokenizerExtraData?: StripeTokenizerExtraDataProps;
  onError?: (err?: string) => void;
};

type StripeCardElementProps = {
  setCreditCard: (card: CreditCardTokenInfo | undefined) => void;
  addressDemographics?: DemographicReferenceData;
  tokenizerExtraData?: StripeTokenizerExtraDataProps;
  onError?: (err?: string) => void;
};

const StripeCardElement: React.FC<StripeCardElementProps> = ({
  setCreditCard,
  tokenizerExtraData,
  addressDemographics,
  onError,
}) => {
  const getToken = useGetStripeToken();
  const [cardElementComplete, setCardElementComplete] = useState(false);

  useEffect(() => {
    if (cardElementComplete) {
      updateCardToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenizerExtraData]);

  const createTokenData = useMemo((): CreateTokenCardData => {
    const countryId = tokenizerExtraData?.countryId ?? CountryId.Canada;
    const countryValue = addressDemographics?.country?.find(({ id }) => countryId === id)?.iso_code2 || undefined;
    const provStateNameKey = addressDemographics?.prov_state?.find(
      ({ id }) => tokenizerExtraData?.provStateId === id
    )?.name_key;
    const provStateValue = provStateNameKey
      ? countryProvStateTranslations[provStateNameKey as keyof typeof countryProvStateTranslations]
      : '';
    return {
      name: tokenizerExtraData?.payer || '',
      address_line1: tokenizerExtraData?.addressLine1 || '',
      address_line2: tokenizerExtraData?.addressLine2 || '',
      address_city: tokenizerExtraData?.city || '',
      address_country: countryValue || '',
      address_state: provStateValue || '',
      address_zip: tokenizerExtraData?.postalZip || '',
      currency: countryCurrency[countryId as CountryId],
    };
  }, [addressDemographics, tokenizerExtraData]);

  const updateCardToken = async () => {
    const tokenResult = await getToken(CardElement, createTokenData);
    if (tokenResult?.error) {
      onError?.(tokenResult.error.message);
      setCreditCard(undefined);
    }
    if (tokenResult?.tokenId && tokenResult.expMonth && tokenResult.expYear) {
      setCreditCard({
        token: tokenResult.tokenId,
        expiryDate: `${tokenResult.expMonth.toString().padStart(2, '0')}${tokenResult.expYear.toString().substring(2)}`,
        cardType: getCardTypeByStripeName(tokenResult.brand),
        displayNumber: tokenResult.last4 ?? '',
        expiryYear: String(tokenResult.expYear).substring(2, 4),
        expiryMonth: String(tokenResult.expMonth),
      });
    }
  };

  const handleOnCardChange = (event: StripeCardElementChangeEvent) => {
    const { complete } = event;
    setCardElementComplete(complete);
    if (complete) {
      updateCardToken();
    } else {
      setCreditCard(undefined);
    }
  };

  return (
    <StripeElement
      onChange={handleOnCardChange}
      options={{
        hidePostalCode: true,
      }}
    />
  );
};

const StripeTokenizer: React.FC<StripeTokenizerProps> = ({
  stripeTokenizerCountryId,
  setCreditCard,
  tokenizerExtraData,
  addressDemographics,
  onError,
}) => {
  const publishableKey = useMemo(() => {
    return stripeTokenizerCountryId ? getStripePublishableKey(stripeTokenizerCountryId) : undefined;
  }, [stripeTokenizerCountryId]);
  const stripePromise = useMemo(() => (publishableKey ? loadStripe(publishableKey) : undefined), [publishableKey]);

  if (!stripePromise) {
    showErrorMessage(translations.stripeTokenizer.noCountryError);
    return <></>;
  }

  return (
    <Elements stripe={stripePromise} options={{ locale: 'en' }}>
      <StripeCardElement
        setCreditCard={setCreditCard}
        tokenizerExtraData={tokenizerExtraData}
        addressDemographics={addressDemographics}
        onError={onError}
      />
    </Elements>
  );
};

export default StripeTokenizer;
