import React, { KeyboardEvent as ReactKeyboardEvent, useContext, useEffect, useState } from 'react';
import { BundleChildPromptModeId, translations } from '../../../constants/translations';
import { Button, DatePicker, Form, Popconfirm, Select, Tooltip, Modal } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons/lib';
import dayjs from 'dayjs';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { useForm } from 'antd/lib/form/Form';
import { getRequiredRule } from '../../../util/forms';
import { ServicesRenderedContext } from '../store/state';
import { SERVICE_DATE, SERVICE_DOCTOR } from '../../../constants/sessionStorageKeys';
import { Store } from 'antd/lib/form/interface';
import {
  DefaultNote,
  InfoTextUpsert,
  InvoiceContact,
  PatientUpsert,
  Service,
  ServiceRendered,
  ServiceUpsert,
} from '../../../graph/types';
import { getDefaultServiceDate } from '../../../pages/Invoices/ViewInvoice/viewInvoiceUtil';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { SelectOptions } from '../../DependentDropdown/DependentDropdown';
import { addPatientDeceaseInfo, clearIsSavingAction, setCashedServices, setIsSavingAction } from '../store/actions';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../Notification/notificationUtil';
import { VaccineDefaultData, VaccineModalFormValues } from '../../VaccineModalContent/VaccineModalContent';
import { ControlledDrugModalFormValue } from '../../ControlledDrugContent/ControlledDrugContent';
import { MicrochipModalFormValues } from '../../MicrochipModalContent/MicrochipModalContent';
import { useGetPatient, usePatientOfflineUpdate, useUpdatePatient } from '../../../hooks/ajax/patients/patientHooks';
import { Loading } from '../../Loading/Loading';
import { ownerRelationTypeId, PatientInfoType } from '../../../constants/referenceData/patientReferenceData';
import { getInfoUpsert } from '../../../classes/upsertGenerators/infoMappingUtil';
import ServiceBundleModal, {
  ServiceBundleModalProps,
} from '../../../pages/Services/ServiceBundleModal/ServiceBundleModal';
import {
  getServicePrescriptionInstruction,
  getServicesFromServiceWithBundle,
  getServiceVaccineDefaults,
  getServiceWithBundle,
  getServiceWithReminder,
} from './addServiceFormUtil';
import { ReminderFormValues } from '../../ReminderFormFields/ReminderFormFields';
import {
  ServiceOptionsModal,
  ServiceOptionsModalProps,
  ServiceOptionsOnClose,
} from '../ServiceOptionsModal/ServiceOptionsModal';
import { RoaModalFormValues } from '../../RoaModalContent/RoaModalContent';
import { PrescriptionModalFormValues } from '../../PrescriptionModalContent/PrescriptionModalContent.types';
import { toNumber } from 'lodash';
import { useGetServicesWithSearch, useUpsertService } from '../../../hooks/ajax/service/serviceHooks';
import { useOffline } from '../../../util/offline/offlineUtil';
import {
  SERVICE_NOTE_NAME,
  ServiceNoteId,
  ServiceThirdPartyId,
} from '../../../constants/referenceData/serviceReferenceData';
import { RabiesTagModalFormValues } from '../../RabiesTagModalContent/RabiesTagModalContent';
import { useRxCollection } from 'rxdb-hooks';
import { RxInfoText } from '../../../services/LocalDatabaseService/schemas/infoTextSchema';
import { StyledOptionContent } from '../../../globalStyles.style';
import { PatientModalFormValues } from '../../PatientModalContent/PatientModalContent';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { ReasonModalFormValues } from '../../ReasonModalContent/ReasonModalContent';
import { WithdrawalPeriodModalFormValues } from '../../WithdrawalPeriodModalContent/WithdrawalPeriodModalContent';
import { getServicePatientRequirements, getPatientLinkedToHisa } from '../serviceRenderedUtils';
import { DeceasePatientsInfo } from '../store/reducer';
import { DiscountModalFormValues } from '../../DiscountModalContent/DiscountModalContent';
// eslint-disable-next-line
import type { RefSelectProps } from 'antd';
import { LabelsAboveItem } from './AddServiceForm.style';
import { upsertDateFormat } from '../../../constants/formats';
import { useCurrencyAndDateSetting } from '../../../hooks/currencyAndDateHooks';
import { isMatch } from '../../../util/objectComparisons';
import { findServiceTextIds } from '../../../pages/Services/serviceUtils';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { HisaFormFieldsValues } from '../../../constants/hisaRequirements';
import { isBeforeToday } from '../../../util/datetimeUtil';

export const addServiceSelectTestId = 'addService-select';
export const btnNoSaveDefaultVaccineInfo = 'btn-save-default-no';

export const serviceFieldName = 'addService';
export const dateFieldName = 'date';
export const doctorFieldName = 'doctor';

const { Option } = Select;

export const successMessageOption = {
  onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
};

export interface AddServiceFormValues {
  [dateFieldName]: dayjs.Dayjs;
  [doctorFieldName]: string;
  [serviceFieldName]: string;
}

export type ServiceOptionsFormValues = PatientModalFormValues &
  VaccineModalFormValues &
  ControlledDrugModalFormValue &
  ReminderFormValues &
  MicrochipModalFormValues &
  RabiesTagModalFormValues &
  PrescriptionModalFormValues &
  RoaModalFormValues &
  ReasonModalFormValues &
  WithdrawalPeriodModalFormValues & { quantity?: string } & DiscountModalFormValues &
  HisaFormFieldsValues;

const fixedHeightIncludingWarningForInvalidInput = 83;
const addServiceFormStyle = {
  marginTop: '10px',
  marginLeft: '10px',
  minHeight: `${fixedHeightIncludingWarningForInvalidInput}px`,
};

export type NewService = {
  formValues: AddServiceFormValues;
  service: Service;
  vaccineSpecificValues?: VaccineModalFormValues;
  controlledDrugSpecificValues?: ControlledDrugModalFormValue;
  reminderSpecificValues?: ReminderFormValues;
  prescriptionSpecificValues?: PrescriptionModalFormValues;
  quantity?: string;
  roaId?: string;
  roaOther?: string;
  reason?: string;
  withdrawal_period?: number;
  withdrawal_prompt?: boolean;
  hisaValues?: HisaFormFieldsValues;
};

export interface AddServiceFormProps {
  practiceId: string;
  doctorOptions: SelectOptions[];
  initialDoctor: string | undefined | null;
  servicesList: Service[];
  saveNewService: (newServiceProperties: NewService[]) => Promise<any>;
  jumpToEditColumnOfActiveService: (column: keyof ServiceRendered) => void;
  serviceSelectReference: React.RefObject<RefSelectProps>;
  invoiceContacts?: InvoiceContact[];
  patientId?: string;
  removedForRapidBilling?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onChargeDuplicationCheck?: (formValues: AddServiceFormValues) => boolean;
  userId: string;
  hasHISAWarningAlreadyShown?: boolean;
  setHasHISAWarningAlreadyShown: (value: boolean) => void;
  isForHISAPatient?: boolean;
}

export interface ServicePromptModalProps {
  titleSuffix?: string;
  visible: boolean;
  isSaving?: boolean;
  onSave?: (value: ServiceOptionsFormValues) => void;
  onClose?: () => void;
  initialValues: ServiceOptionsFormValues;
  hasReminder?: boolean;
}

export const addServiceFormSubmitBtn = 'addServiceFormSubmitBtn';

export const serviceDateTestId = 'serviceDateTestId';

export const AddServiceForm: React.FC<AddServiceFormProps> = ({
  practiceId,
  doctorOptions,
  initialDoctor,
  servicesList,
  saveNewService,
  jumpToEditColumnOfActiveService,
  serviceSelectReference,
  invoiceContacts,
  patientId,
  removedForRapidBilling,
  disabled,
  disabledMessage,
  onChargeDuplicationCheck,
  userId,
  hasHISAWarningAlreadyShown,
  setHasHISAWarningAlreadyShown,
  isForHISAPatient,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { patient, patientLoading } = useGetPatient(patientId ?? '', organizationId, {
    additionalPatientField: 'all',
  });

  const {
    state: { organization },
  } = useOrganizationContext();
  const practiceData = organization?.practice.find(({ id }) => id === organization.default_practice_id);
  const rxExpiryOffset = practiceData?.setting?.find(
    (setting) => setting.setting_name_key === 'RX_EXPIRY_OFFSET'
  )?.value;
  const { state, dispatch } = useContext(ServicesRenderedContext);
  const { services, servicesLoading, setSearchTerm } = useGetServicesWithSearch(organizationId);
  const [originalServices, setOriginalServices] = useState<Service[]>();
  const [updatePatient] = useUpdatePatient();
  const [form] = useForm();

  const [serviceModalProps, setServiceModalProps] = useState<ServiceOptionsModalProps>();
  const [serviceBundleProps, setServiceBundleProps] = useState<ServiceBundleModalProps>();
  const [visiblePopconfirm, setVisiblePopconfirm] = useState<boolean>(false);
  const [popconfirmFormValues, setPopconfirmFormValues] = useState<AddServiceFormValues>();
  const { isOnline, canUseCollection } = useOffline('patient');
  const offlinePatientUpdate = usePatientOfflineUpdate(patientId ?? '');
  const serviceTextCollection = useRxCollection<RxInfoText>('service_text');
  const [shouldSetFocusOnSelectService, setShouldSetFocusOnSelectService] = useState(false);
  const [upsertService] = useUpsertService(organizationId);

  const defaultVaccineInfoEnabled = useLDFlag(LDFlagNames.DefaultVaccineInfo);

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const { rememberDate } = useCurrencyAndDateSetting(userId);

  useEffect(() => {
    if (services && services.length > 0) {
      dispatch(setCashedServices(services));
    }
  }, [services, dispatch]);

  useEffect(() => {
    if (services && !originalServices) {
      setOriginalServices(services);
    }
  }, [services, originalServices]);

  useEffect(() => {
    if (services && !originalServices) {
      setOriginalServices(services);
    }
  }, [services, originalServices]);

  useEffect(() => {
    if (shouldSetFocusOnSelectService && !state.savingState.isSaving) {
      serviceSelectReference.current?.focus();
      setShouldSetFocusOnSelectService(false);
    }
  }, [shouldSetFocusOnSelectService, state.savingState.isSaving, serviceSelectReference]);

  const serviceOptionsWithTooltip = (services ?? [])
    .filter((service) => !service.inactive)
    .map((service) => (
      <Option key={service.id} value={service.id}>
        <Tooltip title={service.name} placement='right'>
          <StyledOptionContent>{service.name}</StyledOptionContent>
        </Tooltip>
      </Option>
    ));

  const handleValuesChange = (_: Store, allValues: AddServiceFormValues) => {
    sessionStorage.setItem(SERVICE_DATE, allValues[dateFieldName]?.toString());
    sessionStorage.setItem(SERVICE_DOCTOR, allValues[doctorFieldName]);
  };

  const handleKeyDownInForm = async (event: ReactKeyboardEvent<HTMLFormElement>) => {
    if (!state.savingState.isSaving) {
      if (event.key === 'Enter') {
        event.preventDefault();
        await checkDuplicateCharges(form.getFieldsValue());
      } else if (event.key === 'Tab') {
        event.preventDefault();
        jumpToEditColumnOfActiveService('quantity');
      }
    }
  };

  const handleChargeDuplicationConfirm = async () => {
    setVisiblePopconfirm(false);
    popconfirmFormValues && (await addNewService(popconfirmFormValues));
  };

  const handleChargeDuplicationCancel = () => {
    form.setFieldsValue({ [serviceFieldName]: undefined });
    setVisiblePopconfirm(false);
  };

  const checkDuplicateCharges = async (formValues: AddServiceFormValues) => {
    const isChargeDuplicated = onChargeDuplicationCheck ? onChargeDuplicationCheck(formValues) : false;
    if (isChargeDuplicated) {
      setPopconfirmFormValues(formValues);
      setVisiblePopconfirm(true);
    } else {
      await addNewService(formValues);
    }
  };

  const addNewService = async (formValues: AddServiceFormValues) => {
    dispatch(setIsSavingAction(translations.invoicePage.addingService));
    try {
      await form.validateFields();
    } catch (e) {
      dispatch(clearIsSavingAction());
      return;
    }
    setSearchTerm('');

    const service = (services ?? []).find((service) => service.id === formValues[serviceFieldName]);

    if (!service) {
      showErrorMessage(translations.invoicePage.errors.serviceNotFound);
      dispatch(clearIsSavingAction());
      return;
    }

    const serviceList = [service];

    if (!service.bundle_prompt && service.bundled_service_ids && service.bundled_service_ids.length > 0) {
      if (isOnline) {
        await getServiceWithBundle(
          organizationId,
          {
            id: service.id,
          },
          (serviceWithBundle, returnedServices) => {
            serviceWithBundle?.bundle?.forEach((bundle) => {
              const srv = returnedServices.find((s) => s.id === bundle.bundled_service_id);
              if (srv) {
                serviceList.push({
                  ...srv,
                  quantity_default: bundle?.quantity,
                  quantity_prompt: Number(bundle?.quantity) === 0 || service.quantity_prompt,
                });
              }
            });
          }
        );
      } else {
        for (const bundle of service.bundle || []) {
          const bundledService = originalServices?.find(({ id }) => id === bundle.bundled_service_id);
          if (bundledService) {
            serviceList.push({
              ...bundledService,
              quantity_default: bundle.quantity,
              quantity_prompt: Number(bundle?.quantity) === 0 || service.quantity_prompt,
            });
          }
        }
      }
    }

    const addServices = async (index = 0, newServices: NewService[] = []) => {
      const service = serviceList[index];
      if (!service) {
        form.setFieldsValue({ [serviceFieldName]: undefined });
        dispatch(clearIsSavingAction());
        await saveNewService(newServices);
        setShouldSetFocusOnSelectService(true);
        return;
      }

      const isHisaPatient = isForHISAPatient || getPatientLinkedToHisa(patient);

      const isHisaService = service.third_party?.some(
        ({ third_party_id }) => third_party_id === ServiceThirdPartyId.HISA
      );

      const isBackDated = isBeforeToday(formValues[dateFieldName]);
      let showHisaBackDateWarning = false;
      if (isHisaPatient && isHisaService && isBackDated) {
        showHisaBackDateWarning = true;
      }

      const shouldShowServiceBundle = formValues[serviceFieldName] === service.id && service.bundle_prompt;
      const defaultReminderValues: ReminderFormValues = {};
      if (service.reminder_ids?.length) {
        dispatch(setIsSavingAction());
        let serviceWithReminder: Service | undefined = service;
        if (!canUseCollection) {
          serviceWithReminder = await getServiceWithReminder(organizationId, { id: service.id });
        }
        defaultReminderValues.reminderName = service.name;
        defaultReminderValues.date = dayjs().add(serviceWithReminder?.reminder?.[0].duration ?? 0, 'days');
        dispatch(clearIsSavingAction());
      }

      const defaultVaccineValues: VaccineModalFormValues = {};
      const vaccineDefaultInfoText = service.text?.find((t) => t.name_key === SERVICE_NOTE_NAME.VACCINATION_DEFAULTS);

      if (vaccineDefaultInfoText) {
        dispatch(setIsSavingAction());
        if (!canUseCollection) {
          const defaultVaccine = await getServiceVaccineDefaults(vaccineDefaultInfoText.id ?? '', organizationId);
          defaultVaccineValues.manufacturer = defaultVaccine?.manufacturer;
          defaultVaccineValues.lotNumber = defaultVaccine?.lot_number;
          defaultVaccineValues.serialNumber = defaultVaccine?.serial;
          defaultVaccineValues.expiryDate = defaultVaccine?.expiry_date && dayjs(defaultVaccine.expiry_date);
        } else {
          if (vaccineDefaultInfoText.id) {
            await serviceTextCollection
              ?.findOne(vaccineDefaultInfoText.id)
              .exec()
              .then((text) => {
                if (!text?.value) {
                  return;
                }
                const defaultVaccine: VaccineDefaultData = JSON.parse(text?.value).vaccinationDefaults;
                defaultVaccineValues.manufacturer = defaultVaccine.manufacturer;
                defaultVaccineValues.lotNumber = defaultVaccine.lot_number;
                defaultVaccineValues.serialNumber = defaultVaccine.serial;
                defaultVaccineValues.expiryDate = defaultVaccine.expiry_date && dayjs(defaultVaccine.expiry_date);
              });
          }
        }
        dispatch(clearIsSavingAction());
      }

      const defaultPrescriptionValues: PrescriptionModalFormValues = {
        contactId: invoiceContacts?.find((contact) => !!contact.primary)?.contact_id,
        prescribedUserId: initialDoctor || undefined,
        prescribedDate: dayjs(),
        prescriptionExpiryDate: dayjs().add(toNumber(rxExpiryOffset) || 0, 'days'),
        itemDescription: service?.name,
        itemQuantity: '1.00',
        itemUnit: service?.unit_name || undefined,
        refills: 0,
      };

      const defaultPrescriptionInstruction = service.text?.find(
        (t) => t.name_key === SERVICE_NOTE_NAME.PRESCRIPTION_INSTRUCTIONS
      );
      if (defaultPrescriptionInstruction) {
        dispatch(setIsSavingAction());
        if (!canUseCollection) {
          const prescriptionInstructions = await getServicePrescriptionInstruction(
            defaultPrescriptionInstruction.id ?? '',
            organizationId
          );
          defaultPrescriptionValues.instructions = prescriptionInstructions;
        } else {
          if (defaultPrescriptionInstruction.id) {
            await serviceTextCollection
              ?.findOne(defaultPrescriptionInstruction.id)
              .exec()
              .then((text) => {
                if (!text?.value) {
                  return;
                }
                const defaultNote: DefaultNote = JSON.parse(text?.value);
                defaultPrescriptionValues.instructions = defaultNote.noteText;
              });
          }
        }
        dispatch(clearIsSavingAction());
      }

      const { hasMissingPatientFields, missingPatientFields, missingHisaFields, hasMissingHisaFields } =
        getServicePatientRequirements(service, patient, isForHISAPatient);
      const deceasedDateVisible = (service.decease_prompt && !patient?.deceased) || false;

      const shouldShowOptionsModal =
        (hasMissingPatientFields && !removedForRapidBilling) ||
        service.vaccine ||
        service.controlled ||
        service.reminder_ids?.length ||
        (service.microchip && !removedForRapidBilling) ||
        (service.rabies_tag_prompt && !removedForRapidBilling) ||
        (deceasedDateVisible && !removedForRapidBilling) ||
        service.prescription_prompt ||
        service.quantity_prompt ||
        service.roa_prompt ||
        service.reason_prompt ||
        service.withdrawal_prompt ||
        (hasMissingHisaFields && !removedForRapidBilling);

      if (showHisaBackDateWarning && !hasHISAWarningAlreadyShown) {
        setHasHISAWarningAlreadyShown(true);
        showWarningMessage(translations.shared.hisaBackDateWarningMessage, { top: 15 });
      }

      if (shouldShowOptionsModal) {
        const initialValues = {
          quantity:
            service.quantity_default === '0' || !service.quantity_default ? undefined : service.quantity_default,
          date: defaultReminderValues.date,
          reminderName: defaultReminderValues.reminderName,
          ...defaultPrescriptionValues,
          ...defaultVaccineValues,
        };

        const showWarnings = (service: Service, hasMissingPatientFields: boolean) => {
          if (service.microchip && removedForRapidBilling) {
            showWarningMessage(translations.shared.microchipModal.rapidBillingWarning);
          } else if (service.rabies_tag_prompt && removedForRapidBilling) {
            showWarningMessage(translations.shared.rabiesTagModal.rapidBillingWarning);
          } else if (deceasedDateVisible && removedForRapidBilling) {
            showWarningMessage(translations.shared.deceasedDateModal.rapidBillingWarning);
          } else if (removedForRapidBilling && hasMissingPatientFields) {
            showWarningMessage(translations.shared.servicePatientModal.rapidBillingWarning, { maxCount: 3 });
          } else if (removedForRapidBilling && hasMissingHisaFields) {
            showWarningMessage(translations.shared.hisaRequirementsModal.rapidBillingWarning, { maxCount: 3 });
          }
        };

        setServiceModalProps({
          initialValues,
          visible: true,
          service,
          removedForRapidBilling,
          invoiceContacts,
          doctorOptions,
          patientName: patient?.name || undefined,
          patientId,
          patientSpecies: patient?.species_id || undefined,
          hasMissingPatientFields,
          hasMissingHisaFields,
          deceasedDateVisible,
          requiredPatientFields: missingPatientFields,
          requiredHisaFields: missingHisaFields,
          patientRefData: organization?.ref_patient,
          patientDOB: patient?.dob || undefined,
          servicesList,
          onClose: async ({ roaId, roaOther, discontinueSave }: ServiceOptionsOnClose) => {
            setServiceModalProps({ ...serviceModalProps!, visible: false });
            showWarnings(service, hasMissingPatientFields);
            if (!discontinueSave) {
              await continueSave(
                { ...defaultReminderValues, roa_id: roaId, roa_other: roaOther },
                shouldShowServiceBundle
              );
            }
          },
          onSave: async (values) => {
            if (defaultVaccineInfoEnabled) {
              const initialVaccineData = (({ manufacturer, lotNumber, serialNumber, expiryDate }) => ({
                manufacturer,
                lotNumber,
                serialNumber,
                expiryDate,
              }))(initialValues);
              const newVaccineData = (({ manufacturer, lotNumber, serialNumber, expiryDate }) => ({
                manufacturer,
                lotNumber,
                serialNumber,
                expiryDate,
              }))(values);

              if (!isMatch(initialVaccineData, newVaccineData)) {
                Modal.confirm({
                  width: 500,
                  okText: translations.invoicePage.confirmSaveVaccineDefault.okButtonLabel,
                  cancelText: translations.invoicePage.confirmSaveVaccineDefault.cancelButtonLabel,
                  cancelButtonProps: { id: btnNoSaveDefaultVaccineInfo },
                  content: translations.invoicePage.confirmSaveVaccineDefault.text,
                  title: translations.invoicePage.confirmSaveVaccineDefault.title,
                  async onOk() {
                    showWarnings(service, hasMissingPatientFields);
                    await continueSave(values, shouldShowServiceBundle, true);
                  },
                  async onCancel() {
                    showWarnings(service, hasMissingPatientFields);
                    await continueSave(values, shouldShowServiceBundle);
                  },
                });
              } else {
                showWarnings(service, hasMissingPatientFields);
                await continueSave(values, shouldShowServiceBundle);
              }
            } else {
              showWarnings(service, hasMissingPatientFields);
              await continueSave(values, shouldShowServiceBundle);
            }
          },
        });
        dispatch(clearIsSavingAction());
      } else if (service.microchip && removedForRapidBilling) {
        showWarningMessage(translations.shared.microchipModal.rapidBillingWarning);
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      } else if (service.rabies_tag_prompt && removedForRapidBilling) {
        showWarningMessage(translations.shared.rabiesTagModal.rapidBillingWarning);
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      } else if (deceasedDateVisible && removedForRapidBilling) {
        showWarningMessage(translations.shared.deceasedDateModal.rapidBillingWarning);
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      } else if (hasMissingPatientFields && removedForRapidBilling) {
        showWarningMessage(translations.shared.servicePatientModal.rapidBillingWarning);
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      } else if (hasMissingHisaFields && removedForRapidBilling) {
        showWarningMessage(translations.shared.hisaRequirementsModal.rapidBillingWarning);
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      } else {
        await continueSave(defaultReminderValues, shouldShowServiceBundle);
      }

      async function continueSave(
        optionsValues: ServiceOptionsFormValues,
        showServiceBundleModal?: boolean,
        shouldUpdateVaccineDefaults?: boolean
      ) {
        const {
          manufacturer,
          lotNumber,
          serialNumber,
          expiryDate,
          bottle_number,
          reminderName,
          date,
          note,
          microchip,
          rabiesTag,
          quantity,
          roa_id,
          roa_other,
          contactId,
          prescribedUserId,
          prescribedDate,
          prescriptionExpiryDate,
          productExpiryDate,
          refills,
          itemDescription,
          itemQuantity,
          itemUnit,
          instructions,
          breed_id,
          gender_id,
          dob,
          reason,
          withdrawal_period,
          deceased_date,
        } = optionsValues;

        const hisaValues = Object.entries(optionsValues)
          .filter(([key]) => key.startsWith('hisa'))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        dispatch(setIsSavingAction());
        const vaccine = { manufacturer, lotNumber, serialNumber, expiryDate };
        const controlledDrug = { bottle_number };
        const reminder = { reminderName, date, note };
        const prescription = service.prescription_prompt
          ? {
              contactId,
              prescriptionPatientId: patientId,
              prescribedUserId,
              practiceId,
              prescribedDate,
              prescriptionExpiryDate,
              productExpiryDate,
              refills,
              itemDescription,
              itemQuantity,
              itemUnit,
              instructions,
            }
          : undefined;

        try {
          if (breed_id || gender_id || dob || deceased_date) {
            if (!patient) {
              showErrorMessage(translations.patientPage.unknownPatient);
            } else {
              const patientUpsert = {
                id: patient?.id,
                defaultBillToRecord: {
                  default_bill_to_id: patient?.default_bill_to_id ?? ownerRelationTypeId,
                },
                record: {
                  name: patient?.name,
                  name_2: patient?.name_2,
                  name_3: patient?.name_3,
                  species_id: patient?.species_id,
                  gender_id: gender_id ?? patient.gender_id,
                  breed_id: breed_id ?? patient.breed_id,
                  color_id: patient?.color_id,
                  dob: dob ? dob?.format(upsertDateFormat) : patient.dob,
                  deceased: patient?.deceased,
                  deceased_date: patient?.deceased_date,
                  inactive: patient?.inactive,
                  created_practice_id: patient?.created_practice_id,
                  offline_id: patient?.offline_id,
                },
              } as PatientUpsert;

              if (deceased_date) {
                const patientDeceaseInfo: DeceasePatientsInfo = {
                  patientId: patient.id,
                  deceaseDate: deceased_date?.format(upsertDateFormat),
                };
                dispatch(addPatientDeceaseInfo(patientDeceaseInfo));
              }

              if (!canUseCollection) {
                await updatePatient({
                  ...successMessageOption,
                  variables: {
                    organizationId: patient?.organization_id,
                    patient: patientUpsert,
                  },
                });
              } else {
                await offlinePatientUpdate?.(patientUpsert);
              }
            }
          }

          if (microchip) {
            if (!patient) {
              showErrorMessage(translations.patientPage.unknownPatient);
            } else {
              const microchipInfoType = organization?.ref_patient.info_type.find(
                ({ name_key }) => name_key === PatientInfoType.Microchip
              );
              const patientUpsert = {
                id: patientId,
                info: getInfoUpsert(microchipInfoType?.id ?? '', microchip ?? '', patient.info ?? []),
              };

              const patientOptions = {
                ...successMessageOption,
                variables: {
                  organizationId,
                  patient: patientUpsert,
                },
              };

              if (!canUseCollection) {
                await updatePatient(patientOptions);
              } else {
                await offlinePatientUpdate?.(patientUpsert);
              }
            }
          }

          if (rabiesTag) {
            if (!patient) {
              showErrorMessage(translations.patientPage.unknownPatient);
            } else {
              const rabiesTagInfoType = organization?.ref_patient.info_type.find(
                ({ name_key }) => name_key === PatientInfoType.RabiesTag
              );

              const patientUpsert = {
                id: patientId,
                info: getInfoUpsert(rabiesTagInfoType?.id ?? '', rabiesTag ?? '', patient.info ?? []),
              };

              const patientOptions = {
                ...successMessageOption,
                variables: {
                  organizationId,
                  patient: patientUpsert,
                },
              };

              if (!canUseCollection) {
                await updatePatient(patientOptions);
              } else {
                await offlinePatientUpdate?.(patientUpsert);
              }
            }
          }

          if (shouldUpdateVaccineDefaults) {
            const { vaccinationTypeId } = findServiceTextIds(service?.text ?? []);

            const serviceInfoTextUpsert: InfoTextUpsert = {
              id: vaccinationTypeId,
              record: {
                type_id: ServiceNoteId.VACCINATION_DEFAULTS,
                value: JSON.stringify({
                  vaccinationDefaults: {
                    manufacturer,
                    serial: serialNumber,
                    lot_number: lotNumber,
                    expiry_date: expiryDate ? dayjs(expiryDate).format(upsertDateFormat) : null,
                  },
                }),
              },
            };

            const serviceUpsert: ServiceUpsert = {
              id: service.id,
              text: [serviceInfoTextUpsert],
            };

            await upsertService({
              variables: {
                organizationId: organization?.id,
                service: serviceUpsert,
              },
            });
          }

          newServices.push({
            formValues,
            service,
            vaccineSpecificValues: vaccine,
            controlledDrugSpecificValues: controlledDrug,
            reminderSpecificValues: reminder,
            prescriptionSpecificValues: prescription,
            quantity,
            roaId: roa_id,
            roaOther: roa_other,
            reason,
            withdrawal_period,
            hisaValues,
          });
          setServiceModalProps({ ...serviceModalProps!, visible: false });

          if (showServiceBundleModal) {
            setServiceBundleProps({
              show: true,
              organizationId,
              serviceId: service.id,
              onClose: async (_) => {
                setServiceBundleProps({
                  ...(serviceBundleProps as ServiceBundleModalProps),
                  show: false,
                  isUserAware: true,
                });
                dispatch(setIsSavingAction(translations.invoicePage.addingService));
                await addServices(index + 1, newServices);
              },
              onSave: async (serviceOnSave) => {
                const servicesToAdd = (serviceOnSave?.bundle || []).filter(
                  (bundle) =>
                    BundleChildPromptModeId.Checked === bundle.prompt_mode ||
                    BundleChildPromptModeId.Forced === bundle.prompt_mode
                );

                if (isOnline) {
                  dispatch(setIsSavingAction());
                  const bundledServices = await getServicesFromServiceWithBundle(organizationId, {
                    id: service.id,
                  });

                  servicesToAdd?.forEach((bundle) => {
                    const theService = bundledServices?.find((s) => bundle.bundled_service_id === s.id);
                    if (theService) {
                      serviceList.push({ ...theService, quantity_default: bundle?.quantity });
                    }
                  });

                  dispatch(clearIsSavingAction());
                } else {
                  for (const bundle of servicesToAdd || []) {
                    const bundledService = originalServices?.find(({ id }) => id === bundle.bundled_service_id);
                    if (bundledService) {
                      serviceList.push({ ...bundledService, quantity_default: bundle.quantity });
                    }
                  }
                }
                setServiceBundleProps({
                  ...(serviceBundleProps as ServiceBundleModalProps),
                  isUserAware: true,
                  show: false,
                });
                dispatch(setIsSavingAction(translations.invoicePage.addingService));
                await addServices(index + 1, newServices);
              },
            });
          } else {
            await addServices(index + 1, newServices);
          }
        } catch (e) {
          setServiceModalProps({ ...serviceModalProps!, visible: false });
          showErrorMessage((e as Error).message ?? translations.shared.saveErrorMessage);
        }
        dispatch(clearIsSavingAction());
      }
    };
    dispatch(setIsSavingAction(translations.invoicePage.addingService));
    await addServices();
  };

  const formDisabled = !!state.editingServiceRenderedId || state.savingState.isSaving || disabled;

  if (patientLoading) {
    return <Loading />;
  }

  return (
    <>
      <Form
        form={form}
        initialValues={{ [dateFieldName]: getDefaultServiceDate(rememberDate), [doctorFieldName]: initialDoctor }}
        onValuesChange={handleValuesChange}
        onKeyDown={handleKeyDownInForm}
        requiredMark={false}
        colon={false}
        labelAlign={'left'}
        layout='inline'
        style={addServiceFormStyle}
        className={'labelsAboveItems'}
        onFinish={checkDuplicateCharges}
        id={'AddServiceForm'}
        title={disabled ? disabledMessage : undefined}
        autoComplete='off'
      >
        <LabelsAboveItem
          name={dateFieldName}
          rules={[getRequiredRule(translations.invoicePage.fields.date)]}
          label={translations.invoicePage.fields.date}
          data-testid={serviceDateTestId}
        >
          <DatePicker disabled={formDisabled} disabledDate={(date) => date.isAfter(dayjs())} format={dateFormat} />
        </LabelsAboveItem>
        <LabelsAboveItem
          name={doctorFieldName}
          rules={[getRequiredRule(translations.invoicePage.fields.doctor)]}
          label={translations.invoicePage.fields.doctor}
        >
          <Select
            {...searchableSelectParams}
            options={doctorOptions}
            placeholder={translations.invoicePage.doctorPlaceholder}
            style={{ width: 200 }}
            data-testid={'doctor-select'}
            disabled={formDisabled}
          />
        </LabelsAboveItem>
        <LabelsAboveItem
          name={serviceFieldName}
          rules={[getRequiredRule(translations.invoicePage.fields.addService)]}
          label={translations.invoicePage.fields.addService}
        >
          <Select
            ref={serviceSelectReference}
            {...searchableSelectParams}
            loading={servicesLoading}
            onSearch={setSearchTerm}
            filterOption={false}
            placeholder={translations.invoicePage.serviceFieldPlaceholder}
            autoFocus
            style={{ width: 275 }}
            suffixIcon={servicesLoading ? <LoadingOutlined /> : <SearchOutlined />}
            data-testid={addServiceSelectTestId}
            disabled={formDisabled}
          >
            {serviceOptionsWithTooltip}
          </Select>
        </LabelsAboveItem>
        <Form.Item name='addServiceButton'>
          <>
            <Button
              type='primary'
              htmlType='submit'
              disabled={formDisabled}
              style={{ marginTop: '27px' }}
              data-testid={addServiceFormSubmitBtn}
            >
              {translations.invoicePage.serviceFieldPlaceholder}
            </Button>
            <Popconfirm
              title={translations.invoicePage.alreadyExistingServiceMessage}
              okText={translations.shared.popconfirm.ok}
              cancelText={translations.shared.popconfirm.cancel}
              placement={'topLeft'}
              open={visiblePopconfirm}
              onConfirm={handleChargeDuplicationConfirm}
              onCancel={handleChargeDuplicationCancel}
              onOpenChange={(visible, e) => !visible && e === undefined && handleChargeDuplicationCancel()}
            />
          </>
        </Form.Item>
      </Form>
      {serviceModalProps?.visible && <ServiceOptionsModal {...serviceModalProps} isAddingNewServiceRendered />}
      {serviceBundleProps?.show && <ServiceBundleModal {...serviceBundleProps} isSaving={state.savingState.isSaving} />}
    </>
  );
};
