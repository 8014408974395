import { Store } from 'antd/lib/form/interface';
import { getInfoUpsert } from '../classes/upsertGenerators/infoMappingUtil';
import { PatientInfoType } from '../constants/referenceData/patientReferenceData';
import { PatientUpsert, Patient, InfoUpsert, PatientRecordUpsert, OrganizationDto } from '../graph/types';

export const getPatientUpserts = (
  values: Store,
  patients: Patient[],
  organization: OrganizationDto,
  dateFormat: string
) => {
  const patientUpserts = patients.reduce<PatientUpsert[]>((acc, patient) => {
    const infoUpserts: InfoUpsert[] = [];
    const valueKeys = Object.keys(values);

    valueKeys
      .filter((k) => k.includes('_microchip'))
      .forEach((key) => {
        const [pId] = key.split('_');
        if (pId === patient.id && values[key]) {
          const microchipInfoType = organization?.ref_patient.info_type.find(
            ({ name_key }) => name_key === PatientInfoType.Microchip
          );
          const microchipUpsert = getInfoUpsert(microchipInfoType?.id ?? '', values[key] ?? '', patient?.info ?? []);
          if (microchipUpsert !== undefined) {
            infoUpserts.push(...microchipUpsert);
          }
        }
      });

    valueKeys
      .filter((k) => k.includes('_rabiesTag'))
      .forEach((key) => {
        const [pId] = key.split('_');
        if (pId === patient.id && values[key]) {
          const rabiesTagInfoType = organization?.ref_patient.info_type.find(
            ({ name_key }) => name_key === PatientInfoType.RabiesTag
          );
          const rabiesTagUpsert = getInfoUpsert(rabiesTagInfoType?.id ?? '', values[key] ?? '', patient.info ?? []);
          if (rabiesTagUpsert !== undefined) {
            infoUpserts.push(...rabiesTagUpsert);
          }
        }
      });

    const deceased = values[`${patient.id}_deceasedDate`];

    if (
      infoUpserts.length > 0 ||
      deceased ||
      valueKeys.some(
        (vK) => [`breed_id_${patient.id}`, `gender_id_${patient.id}`, `dob_${patient.id}`].indexOf(vK) >= 0
      )
    ) {
      const patientUpsert: PatientUpsert = {
        id: patient.id,
        record: {
          name: patient.name ?? '',
          name_2: patient.name_2,
          name_3: patient.name_3,
          species_id: patient.species_id ?? '',
          breed_id: values[`breed_id_${patient.id}`] ?? patient.breed_id,
          gender_id: values[`gender_id_${patient.id}`] ?? patient.gender_id,
          dob: values[`dob_${patient.id}`] ?? patient.dob,
          color_id: patient.color_id,
          inactive: patient.inactive,
          created_practice_id: patient.created_practice_id,
          offline_id: patient.offline_id,
          deceased: deceased ? true : false,
          deceased_date: deceased ? deceased.format(dateFormat) : undefined,
        },
        ...(infoUpserts.length > 0 ? { info: infoUpserts } : undefined),
      };

      acc.push(patientUpsert);
    }

    return acc;
  }, []);

  return patientUpserts;
};

export const getPatientRecordFromPatient = (patient: Patient): PatientRecordUpsert => {
  return {
    name: patient.name ?? '',
    name_2: patient.name_2,
    name_3: patient.name_3,
    species_id: patient.species_id ?? '',
    gender_id: patient.gender_id,
    breed_id: patient.breed_id,
    color_id: patient.color_id,
    dob: patient.dob,
    deceased: patient.deceased,
    deceased_date: patient.deceased_date,
    inactive: patient.inactive,
    created_practice_id: patient.created_practice_id,
    offline_id: patient.offline_id,
  };
};
