import { PracticeSettingsNameKey } from '../constants/translations';
import { OrganizationDto } from '../graph/types';
import { getPracticeSetting } from '../pages/Contacts/ViewContact/statementUtils';

export const getEmbeddedComponentsAppearance = () => {
  return {
    colorPrimary: '#798f8c',
    colorPrimaryButtonBackground: '#798f8c',
    colorPrimaryButtonBorder: '#798f8c',
    colorPrimaryButtonText: '#fff',
    colorSecondaryButtonBackground: '#fff',
    colorSecondaryButtonBorder: '#000',
    colorSecondaryButtonText: '#000',
    borderRadius: '0px',
    borderRadiusButton: '0px',
    borderRadiusForm: '0px',
    borderRadiusBadge: '0px',
  };
};

export const getIsStripeProcessor = (organization?: OrganizationDto) => {
  const practice =
    organization?.practice.find((p) => p.id === organization.default_practice_id) || organization?.practice[0];
  const setting = getPracticeSetting(PracticeSettingsNameKey.StripePracticeSet, practice)?.value;
  const stripeSetting = JSON.parse(setting || '{}');
  return !!stripeSetting?.FullyVerified;
};

export enum StripeReqInfoType {
  Owner = "Owner's ",
  Representative = "Representative's ",
  Company = "Company's ",
  Default = '',
}
