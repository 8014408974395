import React, { useCallback } from 'react';
import CurrencyFormat from 'react-currency-format';
import { getShortenedValue } from '../../../hooks/currencyAndDateHooks';

interface Props {
  total: number | string;
  chartAxis?: boolean;
  isPriceValue?: boolean;
  currencySettings?: Record<any, any>;
}

export default function BaseCurrencyFormatter({ total, chartAxis, isPriceValue, currencySettings = {} }: Props) {
  const { decimalSymbol, currencySymbol, isPrefix, decimalDigits, groupingSymbol, negativeSymbol } = currencySettings;

  const customNegative = useCallback(
    (value: string) => {
      if (total < 0) {
        return isPriceValue && negativeSymbol !== '-' ? `(${value})` : `${negativeSymbol}${value}`;
      }

      return value;
    },
    [isPriceValue, negativeSymbol, total]
  );

  return (
    <CurrencyFormat
      value={Math.abs(Number(total))}
      prefix={isPrefix ? currencySymbol : ''}
      suffix={!isPrefix ? currencySymbol : ''}
      decimalSeparator={decimalSymbol}
      thousandSeparator={groupingSymbol}
      decimalScale={decimalDigits}
      fixedDecimalScale={decimalDigits > 0}
      displayType='text'
      renderText={(value) => <>{!chartAxis ? customNegative(value) : getShortenedValue(Math.abs(Number(value)))}</>}
      allowNegative
    />
  );
}
