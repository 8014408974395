import { DatePicker, Input, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ColumnWithEditableField,
  TableWithInlineEdit,
} from '../../../../components/TableWithInlineEdit/TableWithInlineEdit';
import { ReminderPropertyNames } from '../../../../constants/propertyNames';
import { translations } from '../../../../constants/translations';
import { Reminder } from '../../../../graph/types';
import { useDefaultPracticeId } from '../../../../hooks/ajax/practice/practiceHooks';
import { OwnerType } from '../../../../hooks/ajax/recordHooks';
import { useGetReminders } from '../../../../hooks/ajax/reminders/reminderHooks';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { TableKey, useTableResetFilterAndSort } from '../../../../hooks/tableHooks';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../../util/filterAndSorting';
import { mapRefDataForSelect } from '../../../../util/refDataUtil';
import { useSendReminderUpsertToBackend } from './ReminderUtils';
import './PatientReminders.less';
import { useOrganizationContext } from '../../../../contexts/organization/state';

export const statusDropdownTestId = 'statusDropdownTestId';

interface PatientRemindersProps {
  patientId: string;
  handleIsEditing?: (value: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const PatientReminders: React.FC<PatientRemindersProps> = ({
  patientId,
  handleIsEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();

  const [saving, setSaving] = useState(false);
  const upsertOfflineReminder = useSendReminderUpsertToBackend({ patientId });
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const practiceId = useDefaultPracticeId();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const statusOptions = organization?.ref_patient?.reminder_status?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const patientReferenceData = useMemo(() => organization?.ref_patient, [organization]);

  const variables = useMemo(() => ({ id: patientId }), [patientId]);

  const { reminders, remindersLoading } = useGetReminders(organizationId, {
    params: variables,
    type: OwnerType.Patient,
    fetchPolicy: 'cache-and-network',
  });

  const { filteredValue, sortOrderMap, tableChangeHandler } = useTableResetFilterAndSort();

  const showRiminders = useMemo(
    () =>
      reminders
        ?.filter((field, index, self) => self.indexOf(field) === index && !field.hidden)
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1)),
    [reminders]
  );

  const onStatusChange = useCallback(
    async (statusId: string, reminder: Reminder) => {
      const statusUpsert = {
        id: reminder.id,
        status_record: {
          status_id: statusId,
        },
      };

      setSaving(true);
      await upsertOfflineReminder(statusUpsert, translations.shared.saveSuccessMessage);
      setSaving(false);
    },
    [upsertOfflineReminder, setSaving]
  );

  const handleUpsert = async (reminder: Reminder) => {
    if (!validateData(reminder) || !patientId) {
      return false;
    }

    const reminderUpsert = {
      id: reminder.id,
      record: {
        patient_id: patientId,
        practice_id: reminder.practice_id ?? practiceId,
        name: reminder.name,
        date: reminder.date,
        note: reminder.note,
      },
      status_record: {
        status_id: reminder.status_id,
      },
    };

    setSaving(true);
    await upsertOfflineReminder(reminderUpsert, translations.shared.saveSuccessMessage);
    setSaving(false);

    return true;
  };

  const reminderStatusFilters = useMemo(() => {
    return statusOptions?.map(({ value, label }) => ({ value, text: label }));
  }, [statusOptions]);

  const validateData = (data: Reminder) => {
    if (!data.name || !data.date) {
      setErrors({
        name: !data.name,
        date: !data.date,
      });
      return false;
    }

    return true;
  };

  const columns: ColumnWithEditableField<Reminder>[] = useMemo(
    () => [
      {
        title: translations.patientPage.reminders.columns.name,
        key: ReminderPropertyNames.name,
        dataIndex: ReminderPropertyNames.name,
        ...generalFilteringAndSortingSettings,
        filteredValue: filteredValue[ReminderPropertyNames.name],
        sortOrder: sortOrderMap[ReminderPropertyNames.name],
        onFilter: getOnFilterFunctionFor(ReminderPropertyNames.name),
        sorter: getStringCompareFunctionFor(ReminderPropertyNames.name),
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.patientPage.reminders.columns.name
        ),
        renderEdit: (onChange: (key: string, value: string) => void) => (name: string) =>
          (
            <>
              <Input
                placeholder={translations.patientPage.reminders.columns.name}
                defaultValue={name}
                onChange={({ target: { value } }) => onChange(ReminderPropertyNames.name, value)}
                maxLength={50}
              />
              {errors.name && (
                <div className='ref-table header--error'>
                  {translations.shared.getIsRequiredMessage(translations.patientPage.reminders.columns.name)}
                </div>
              )}
            </>
          ),
        width: 200,
      },
      {
        title: translations.patientPage.reminders.columns.dueDate,
        key: ReminderPropertyNames.date,
        dataIndex: ReminderPropertyNames.date,
        ...generalFilteringAndSortingSettings,
        filteredValue: filteredValue[ReminderPropertyNames.date],
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.patientPage.reminders.columns.dueDate
        ),
        sortOrder: sortOrderMap[ReminderPropertyNames.date],
        onFilter: getOnFilterFunctionFor(ReminderPropertyNames.date),
        sorter: getDateCompareFunctionFor(ReminderPropertyNames.date),
        renderEdit: (onChange: (key: string, value: string) => void) => (date: string) =>
          (
            <>
              <DatePicker
                defaultValue={date ? dayjs(date) : undefined}
                format={dateFormat}
                onChange={(_: Dayjs | null, value: string) => onChange(ReminderPropertyNames.date, value)}
                disabledDate={(date) => date.isBefore(dayjs())}
              />
              {errors.date && (
                <div className='ref-table header--error'>
                  {translations.shared.getIsRequiredMessage(translations.patientPage.reminders.columns.dueDate)}
                </div>
              )}
            </>
          ),
        width: 200,
      },

      {
        title: translations.patientPage.reminders.columns.note,
        key: ReminderPropertyNames.note,
        dataIndex: ReminderPropertyNames.note,
        ...generalFilteringAndSortingSettings,
        filteredValue: filteredValue[ReminderPropertyNames.note],
        sortOrder: sortOrderMap[ReminderPropertyNames.note],
        onFilter: getOnFilterFunctionFor(ReminderPropertyNames.note),
        sorter: getStringCompareFunctionFor(ReminderPropertyNames.note),
        filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
          translations.patientPage.reminders.columns.note
        ),
        renderEdit: (onChange: (key: string, value: string) => void) => (note: string) =>
          (
            <Input
              placeholder={translations.patientPage.reminders.columns.note}
              defaultValue={note}
              onChange={({ target: { value } }) => onChange(ReminderPropertyNames.note, value)}
            />
          ),
        width: 200,
      },
      {
        title: translations.patientPage.reminders.columns.status,
        key: ReminderPropertyNames.status_id,
        dataIndex: ReminderPropertyNames.status_id,
        ...generalFilteringAndSortingSettings,
        filteredValue: filteredValue[ReminderPropertyNames.status_id],
        sortOrder: sortOrderMap[ReminderPropertyNames.status_id],
        render: (_: string, reminder: Reminder) => {
          return (
            <Select
              key={statusDropdownTestId}
              data-testid={statusDropdownTestId}
              options={statusOptions}
              value={mapRefDataForSelect(
                { id: reminder?.status_id, name: reminder?.status_name },
                patientReferenceData?.reminder_status
              )}
              onClick={(e) => e.stopPropagation()}
              onChange={(value: string) => onStatusChange(value, reminder)}
            />
          );
        },
        renderEdit: (onChange: (key: string, value: string) => void) => (statusId: string, reminder: Reminder) => {
          if (!statusId) {
            // force set a default statusId
            onChange(ReminderPropertyNames.status_id, statusOptions?.[0]?.value ?? '');
          }
          return (
            <Select
              key={`${statusDropdownTestId}-editable`}
              data-testid={statusDropdownTestId}
              options={statusOptions}
              defaultValue={
                statusId
                  ? mapRefDataForSelect(
                      { id: reminder?.status_id, name: reminder?.status_name },
                      patientReferenceData?.reminder_status
                    )
                  : statusOptions?.[0]?.value
              }
              onChange={(value: string) => onChange(ReminderPropertyNames.status_id, value)}
            />
          );
        },
        onFilter: (value, record) => record.status_id === value,
        filters: reminderStatusFilters,
        width: 200,
      },
    ],
    [
      statusOptions,
      filteredValue,
      sortOrderMap,
      reminderStatusFilters,
      errors,
      onStatusChange,
      patientReferenceData,
      dateFormat,
    ]
  );

  const handleDeleteReminder = async (reminder: Reminder) => {
    setSaving(true);
    await upsertOfflineReminder(
      { id: reminder.id, void: true },
      translations.patientsPage.deleteReminderSuccessMessage
    );
    setSaving(false);
  };

  return (
    <TableWithInlineEdit
      tableKey={TableKey.Reminders}
      dataSource={showRiminders}
      columns={columns}
      loading={remindersLoading || saving}
      rowKey={'id'}
      onChange={tableChangeHandler}
      onSave={handleUpsert}
      onEditModeChange={() => setErrors({})}
      onDelete={handleDeleteReminder}
      hideAddButton={false}
      className='reminder-table'
      handleIsEditing={handleIsEditing}
      setShouldResetOnTabChange={setShouldResetOnTabChange}
      shouldResetOnTabChange={shouldResetOnTabChange}
    />
  );
};
