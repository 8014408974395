import gql from 'graphql-tag';
import { FinancialPeriodFields } from '../fragments/financialFragments';

export const UpsertFinancialPeriod = gql`
  mutation UpsertFinancialPeriod($financialPeriod: FinancialPeriodUpsert!, $organizationId: ID!) {
    upsertFinancialPeriod(financialPeriod: $financialPeriod, organizationId: $organizationId) {
      ...FinancialPeriodFields
    }
  }
  ${FinancialPeriodFields}
`;

export const GetFinancialPeriods = gql`
  query getFinancialPeriods($organizationId: ID!, $practiceId: ID!, $filter: FinancialPeriodFilter!) {
    getFinancialPeriods(organizationId: $organizationId, practiceId: $practiceId, filter: $filter) {
      ...FinancialPeriodFields
    }
  }
  ${FinancialPeriodFields}
`;
