export const sendTypes = {
  print: {
    value: 1,
    label: 'Print',
  },
  email: {
    value: 2,
    label: 'Email',
  },
};
export const defaultSendType = sendTypes.print.value;
export const defaultStatementIgnore = false;
export const defaultInterestIgnore = false;
export const defaultTaxExempt = false;
