import { SavingProps } from '../../SaveSpinner/SaveSpinner';
import { TextConfirmModalProps } from '../../TextConfirmModal/TextConfirmModal';
import { UtilsAction, setSaveSpinnerType, setTextConfirmType, setOrganizationIdType } from './actions';

export interface UtilsState {
  saveSpinnerProps: SavingProps;
  textConfirmModalProps: TextConfirmModalProps;
  organizationId?: string;
}

export const initialState: UtilsState = {
  saveSpinnerProps: {
    isSaving: false,
  },
  textConfirmModalProps: {
    show: false,
  },
};

export const utilsReducer = (state: UtilsState, action: UtilsAction): UtilsState => {
  switch (action.type) {
    case setSaveSpinnerType:
      return {
        ...state,
        saveSpinnerProps: {
          ...state.saveSpinnerProps,
          ...action.payload,
        },
      };
    case setTextConfirmType:
      return {
        ...state,
        textConfirmModalProps: {
          ...state.textConfirmModalProps,
          ...action.payload,
        },
      };
    case setOrganizationIdType:
      return {
        ...state,
        organizationId: action.payload,
      };
  }
  return state;
};
