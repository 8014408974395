import { translations } from '../constants/translations';
import { Patient, PatientBreed, PatientGender, PatientReferenceDataDto, PatientSpecies } from '../graph/types';
import { getStatus, getStatusText } from './statusUtils';

export function getFilteredPatients(searchInput: string | undefined, patients: Patient[] | undefined) {
  if (!searchInput) {
    return patients;
  }
  const searchInputs = searchInput.split(' ').map((input) => input.toLowerCase());
  return patients?.filter((patient) =>
    searchInputs.every(
      (input) =>
        patient.name?.toLowerCase()?.includes(input) ||
        patient.number?.toLowerCase()?.includes(input) ||
        getStatusText(getStatus(patient)).toLowerCase().includes(input)
    )
  );
}

export function sortPatientRefByName(
  patientAFieldId: string | undefined | null,
  patientBFieldId: string | undefined | null,
  patientRefFields: patientRefSearchField[]
) {
  const nameA = patientRefFields.find((x) => x.id === patientAFieldId)?.name ?? '';
  const nameB = patientRefFields.find((x) => x.id === patientBFieldId)?.name ?? '';
  return nameA.localeCompare(nameB);
}

type patientRefSearchField = PatientSpecies | PatientBreed | PatientGender;

export function getSpeciesFilterOptions(patientReferenceData: PatientReferenceDataDto, patients: Patient[] = []) {
  const filterOptions: { [value: string]: string } = {};

  patientReferenceData.species.forEach((species) => {
    filterOptions[species.id] = species.name;
  });

  patients.forEach((patient) => {
    if (!patient.species_id || !patient.species_name) {
      return;
    }

    if (!filterOptions[patient.species_id]) {
      filterOptions[patient.species_id] = translations.shared.getVoidRecord(patient.species_name);
    }
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}

export function getSpeciesFilterOptionsDto(patientReferenceData: PatientReferenceDataDto, patients: Patient[] = []) {
  const filterOptions: { [value: string]: string } = {};

  patientReferenceData.species.forEach((species) => {
    filterOptions[species.id] = species.name;
  });

  patients.forEach((patient) => {
    if (!patient.species_id || !patient.species_name) {
      return;
    }

    if (!filterOptions[patient.species_id]) {
      filterOptions[patient.species_id] = translations.shared.getVoidRecord(patient.species_name);
    }
  });

  return Object.keys(filterOptions).map((value) => ({ text: filterOptions[value], value }));
}
