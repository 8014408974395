import { ApolloCache, MutationFunctionOptions, Reference } from '@apollo/client';
import {
  Contact,
  ContactCommunicationLog,
  ContactTypeDto,
  ContactUpsert,
  Mutation,
  MutationUpsertContactArgs,
  Query,
  QueryGetContactCommunicationLogArgs,
  QueryGetContactEsArgs,
  Subscription,
  SubscriptionOnContactUpsertArgs,
} from '../../../graph/types';
import {
  GetContact,
  GetContactAddresses,
  GetContactCommunicationLog,
  GetContactCreditCards,
  GetContacts,
  GetContactText,
  UpsertContact,
} from '../../../graph/queries/contacts';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { ContactDeleteConflict, translations } from '../../../constants/translations';
import { ContactFields } from '../../../graph/fragments/contactFragments';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useGetOrganization } from '../organization/organizationHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryWithElasticSearch, useOfflineQuery, useGetOfflineSelector } from '../useOfflineQuery';
import { GetUserOrganizationCardsPg } from '../../../graph/queries/users';
import { useGetOrganizationIdFromRoute } from '../../route/routeParameterHooks';
import {
  getContactInsertDto,
  getContactUpdateDto,
  getOfflineContactTextUpsert,
} from '../../../services/LocalDatabaseService/queries/contactQueries';
import { useOfflineDelete, useOfflineInsert, useOfflineUpdate } from '../../localDatabaseHooks';
import { useRxCollection } from 'rxdb-hooks';
import { RxContact } from '../../../services/LocalDatabaseService/schemas/contactSchema';
import { isUuid, useOffline } from '../../../util/offline/offlineUtil';
import { OnContactUpsert } from '../../../graph/subscription/contact';
import { RxInfoText } from '../../../services/LocalDatabaseService/schemas/infoTextSchema';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { filterByDocument } from '../../../util/searchUtil';
import { useSubscription } from '../subscription/subscriptionHooks';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { useGetConnectionId } from '../../authHooks';
import { useGetReferenceData } from '../referenceData/referenceDataHooks';

export const useGetContactsWithSearch = (organizationId: string, searchValue = '') => {
  const variables = useMemo(() => ({ organizationId }), [organizationId]);
  const [searchTerm, setSearchTerm] = useState<string>(searchValue);
  const { enabledAndOffline } = useOffline();
  const searchParams = useMemo(
    () => ({
      fields: ['document.name', 'document.number', 'document.email'],
      query: searchTerm,
    }),
    [searchTerm]
  );

  const { data, loading, refetch } = useQueryWithElasticSearch<'getContactsEs'>({
    query: GetContacts,
    collection: 'contact',
    variables,
    searchParams,
  });

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  const contacts = useMemo(() => {
    if (enabledAndOffline && searchTerm) {
      return data?.filter(filterByDocument(searchTerm));
    }
    return data;
  }, [data, enabledAndOffline, searchTerm]);

  return {
    contacts,
    contactsLoading: loading,
    refetchContacts: refetch,
    refetchCurrentSearch: refetch,
    setSearchTerm,
  };
};

export const useGetContact = ({ contactId, organizationId }: { contactId: string; organizationId: string }) => {
  const selector = useGetOfflineSelector(contactId);
  const { data, loading, error, refetch } = useOfflineQuery<'getContactEs'>({
    query: GetContact,
    collection: 'contact',
    options: {
      variables: { contactId, organizationId },
      skip: !contactId || !organizationId,
      fetchPolicy: 'cache-first',
    },
    selector,
  });

  if (data?.hidden) {
    showErrorMessage(translations.viewContactPage.unknownContact);
    return {
      contact: null,
    };
  }

  return {
    contact: data,
    contactLoading: loading,
    contactError: error,
    refetchContact: refetch,
  };
};

export const useGetContactCreditCards = ({
  contactId,
  organizationId,
}: {
  contactId: string;
  organizationId: string;
}) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getContactEs', QueryGetContactEsArgs>(
    GetContactCreditCards,
    {
      variables: { contactId, organizationId },
    }
  );

  return {
    contact: data?.getContactEs,
    contactLoading: loading,
    contactError: error,
  };
};

export const useGetContactAddresses = ({
  contactId,
  organizationId,
}: {
  contactId: string;
  organizationId: string;
}) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<'getContactEs', QueryGetContactEsArgs>(
    GetContactAddresses,
    {
      variables: { contactId, organizationId },
      fetchPolicy: 'no-cache',
    }
  );

  return {
    contact: data?.getContactEs,
    contactLoading: loading,
    contactError: error,
  };
};

export const useGetContactReferenceData = ({ organizationId }: { organizationId: string }) => {
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.ContactReferenceData
  );

  const contactReferenceData = useMemo(() => organization?.ref_contact, [organization]);

  return {
    contactReferenceData,
    contactReferenceDataLoading: organizationLoading,
  };
};

export const useGetContactType = (): { contactTypes: ContactTypeDto[] | undefined } => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const contactTypes = useMemo(() => organization?.ref_contact?.type, [organization]);

  return {
    contactTypes,
  };
};

export const useGetContactText = (organizationId: string, textId?: string | null, skip = false) => {
  const selector = useMemo(() => ({ _id: textId }), [textId]);

  const { data, loading, error } = useOfflineQuery<'getContactText'>({
    query: GetContactText,
    collection: 'contact_text',
    options: {
      variables: {
        organizationId,
        id: textId,
      },
      skip: !textId || isUuid(textId) || skip,
      fetchPolicy: 'cache-first',
    },
    selector,
  });

  return {
    contactText: data,
    contactTextLoading: loading,
    error,
  };
};

export const useInsertContact = () => {
  return useOfflineErrorSkipMutation<'upsertContact', MutationUpsertContactArgs>(UpsertContact, {
    update(cache: any, { data }: any) {
      const upsertContact = data?.upsertContact;
      cache.modify({
        fields: {
          getContactsEs(contactReferences: Reference[] = []) {
            const newContactReference = cache.writeFragment({
              data: upsertContact,
              fragment: ContactFields,
              fragmentName: 'ContactFields',
            });
            return [newContactReference, ...contactReferences];
          },
        },
      });
    },
  });
};

export const useUpdateContact = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const connectionId = useGetConnectionId();
  const [upsertContactMutation] = useOfflineErrorSkipMutation<'upsertContact', MutationUpsertContactArgs>(
    UpsertContact,
    {
      update(cache: ApolloCache<any>, { data }: any) {
        try {
          const currentUserData = cache.readQuery<Pick<Query, 'getUserOrganizationCardsPg'>>({
            query: GetUserOrganizationCardsPg,
            variables: { organizationId },
          });
          const card = currentUserData?.getUserOrganizationCardsPg?.contact_card.find(
            ({ contact_id }) => contact_id === data?.upsertContact?.id
          );

          if (card && data?.upsertContact?.address) {
            cache.modify({
              id: cache.identify({ __typename: 'UserOrganizationContactCard', id: card.id }),
              fields: {
                contact_address() {
                  return data?.upsertContact?.address;
                },
              },
            });
          }
        } catch {}
      },
    }
  );

  const returnFunction = useCallback(
    (options: MutationFunctionOptions<Pick<Mutation, 'upsertContact'>, MutationUpsertContactArgs>) => {
      const upsertWithConnectionId: MutationFunctionOptions<
        Pick<Mutation, 'upsertContact'>,
        MutationUpsertContactArgs
      > = {
        ...options,
        onError: (e) => showErrorMessage(e.message || translations.shared.saveErrorMessage),
        variables: {
          ...options.variables,
          contact: {
            ...options.variables?.contact,
            connection_id: connectionId,
          },
        } as MutationUpsertContactArgs,
        onCompleted: () => showSuccessMessage(translations.shared.saveSuccessMessage),
      };

      return upsertContactMutation(upsertWithConnectionId);
    },
    [connectionId, upsertContactMutation]
  );

  return [returnFunction];
};

export const useContactOfflineInsert = () => {
  const offlineTextInsert = useOfflineInsert<RxInfoText>('contact_text');
  const {
    state: { organization },
  } = useOrganizationContext();
  const offlineInsert = useOfflineInsert<RxContact>('contact');

  if (!organization || !offlineInsert) {
    return undefined;
  }

  return async (upsert: ContactUpsert) => {
    if (upsert.text?.[0].record?.offline_id) {
      const textInsert = getOfflineContactTextUpsert(upsert.text[0], upsert.text[0].record?.offline_id);
      await offlineTextInsert(textInsert);
    }
    const obj = getContactInsertDto(upsert, organization);
    return (await offlineInsert(obj)) as Contact;
  };
};

export const useContactOfflineUpdate = (contactId: string) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [contact, setContact] = useState<RxContact | null>();
  const contactCollection = useRxCollection<RxContact>('contact');
  const offlineUpdate = useOfflineUpdate<RxContact>('contact');
  const offlineTextInsert = useOfflineInsert<RxInfoText>('contact_text');
  const offlineTextDelete = useOfflineDelete<RxInfoText>('contact_text');
  const { referenceData } = useGetReferenceData(organizationId);

  useEffect(() => {
    if (!contactCollection) {
      return;
    }

    const getRecord = async () => {
      setContact(await contactCollection.findOne({ selector: { id: contactId } }).exec());
    };

    getRecord();
  }, [contactCollection, setContact, contactId]);

  const update = useCallback(
    async (upsert: ContactUpsert) => {
      const voidedText = upsert.text?.find((text) => text.void);
      const textUpsert = upsert.text?.find((text) => !text.void);
      if (contact && referenceData) {
        const obj = getContactUpdateDto(contact, upsert, referenceData?.country);
        if (voidedText) {
          await offlineTextDelete(voidedText.id ?? '');
        }
        if (textUpsert?.record) {
          const contactTextInsert = getOfflineContactTextUpsert(textUpsert, textUpsert?.record?.offline_id);
          await offlineTextInsert(contactTextInsert);
        }
        return (await offlineUpdate(contactId, obj)) as Contact;
      }

      return undefined;
    },
    [contact, offlineUpdate, referenceData, contactId, offlineTextInsert, offlineTextDelete]
  );

  if (!referenceData || !contact || !offlineUpdate) {
    return undefined;
  }

  return update;
};

export const useDeleteContact = (organizationId: string) => {
  const [deleteContact] = useOfflineErrorSkipMutation<'upsertContact', MutationUpsertContactArgs>(UpsertContact);

  return async ({
    contactId,
    onSuccess,
    onConflictError,
    successMessage,
  }: {
    contactId: string;
    onSuccess: () => void;
    onConflictError: () => void;
    successMessage: string;
  }) => {
    try {
      await deleteContact({
        variables: {
          organizationId,
          contact: { id: contactId, void: true },
        },
        update: (cache: any) => {
          cache.evict({
            id: cache.identify({ __typename: 'Contact', id: contactId }),
          });
          cache.evict({ fieldName: 'getCurrentUser' });
          cache.gc();
        },
      });
      showSuccessMessage(successMessage);
      onSuccess();
    } catch (e) {
      if (
        (e as Error).message === ContactDeleteConflict.Ledger ||
        (e as Error).message === ContactDeleteConflict.Relationship
      ) {
        onConflictError();
      } else {
        showErrorMessage((e as Error).message);
      }
    }
  };
};

export const useContactUpsertSubscription = (contactId: string, skip?: boolean) => {
  const { data, loading, error } = useSubscription<Subscription, SubscriptionOnContactUpsertArgs>(OnContactUpsert, {
    variables: { id: contactId },
    skip: skip || !contactId,
  });
  return { record: data?.onContactUpsert, loading, error };
};

export const useGetContactCommunicationLog = ({
  contactId,
  organizationId,
}: {
  contactId: string;
  organizationId: string;
}) => {
  const { data, loading, error } = useOfflineErrorSkipQuery<
    'getContactCommunicationLog',
    QueryGetContactCommunicationLogArgs
  >(GetContactCommunicationLog, {
    variables: { contactId, organizationId },
  });

  return {
    communicationLog: data?.getContactCommunicationLog as ContactCommunicationLog[],
    communicationLogLoading: loading,
    communicationLogError: error,
  };
};
