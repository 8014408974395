import { Ledger, QueryGetPaymentReceiptReportArgs } from '../../graph/types';
import { ledgerEntryConfigs, LedgerEntryNameKey } from '../../constants/referenceData/ledgerReferenceData';
import { paymentTypeConfigs, PaymentTypeNameKey } from '../../constants/referenceData/paymentReferenceData';
import { translations } from '../../constants/translations';
import {
  getMenuItemPropsWithOnCancelPopconfirm,
  MenuItemProps,
} from '../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { isNil } from 'lodash';
import AppSyncService from '../../services/AppSyncService/AppSyncService';
import { GetLedgerReceipt } from '../../graph/queries/ledgers';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { Attachment } from '../../components/EmailModal/EmailModal';
import { openTabWithPopupDetection } from '../../util/popupUtil';
import { getStatusTag } from '../../util/filterAndSorting';
import { invoiceStatusConfigs } from '../../constants/referenceData/invoiceReferenceData';
import { ElectronicPaymentType } from '../../constants/referenceData/paymentProcessorTypeReferenceData';

export function getLedgerEntryType(entry: Ledger) {
  const invoiceNumber = entry?.invoice_number;
  const typeNameKey = entry.type_name_key as LedgerEntryNameKey;
  const paymentTypeNameKey = entry.payment_type_name_key as PaymentTypeNameKey;
  let entryType = '';
  if (entry.reversal) {
    entryType = '(Reversal) ';
  }
  entryType += ledgerEntryConfigs[typeNameKey]?.text || '';
  if (typeNameKey === LedgerEntryNameKey.Payment && paymentTypeNameKey && paymentTypeConfigs[paymentTypeNameKey]) {
    entryType += ' - ' + paymentTypeConfigs[paymentTypeNameKey].text;
    if (paymentTypeNameKey === PaymentTypeNameKey.Check && entry.check_number) {
      entryType += ' #' + entry.check_number;
    }
  }
  if (invoiceNumber) {
    entryType += ' #' + invoiceNumber;
  }
  return entryType;
}

export function getInvoiceStatusTagForLedger(entry: Ledger) {
  if (entry.invoice_id && entry.invoice_status_name_key) {
    return getStatusTag(entry.invoice_status_name_key, invoiceStatusConfigs);
  }
  return '';
}

export function getLedgerPaymentType(entry: Ledger) {
  return paymentTypeConfigs[entry.payment_type_name_key as PaymentTypeNameKey]?.text;
}

export function getLedgerTypeValue(entry: Ledger) {
  return ledgerEntryConfigs[entry.type_name_key as LedgerEntryNameKey]?.value;
}

export function getLedgerTypeFilters() {
  return Object.values(ledgerEntryConfigs);
}

export const getMenuItemsForCreditOrAccountChargeOrPaymentActions = ({
  ledgerEntry,
  editLedgerEntry,
  removeAssignment,
  handleDeleteLedgerEntry,
  openReversePaymentModal,
  printLedgerEntry,
  emailLedgerEntry,
  disableEmailOption,
  openDetailsModal,
}: {
  ledgerEntry: Ledger;
  editLedgerEntry: (ledger: Ledger) => void;
  removeAssignment: () => Promise<void>;
  handleDeleteLedgerEntry: (ledgerEntry: Ledger) => Promise<void>;
  openReversePaymentModal: (ledgerEntry: Ledger) => void;
  printLedgerEntry: (ledgerEntry: Ledger) => void;
  emailLedgerEntry: (ledgerEntry: Ledger) => void;
  disableEmailOption: boolean;
  openDetailsModal: (ledgerEntry: Ledger) => void;
}) => {
  const editAction = () => editLedgerEntry(ledgerEntry);
  const deleteLedgerEntry = () => handleDeleteLedgerEntry(ledgerEntry);
  const showDelete = !Number(ledgerEntry.total_reversed) && isNil(ledgerEntry.payment_electronic_id);
  const editButtonDisabled = Number(ledgerEntry.total_assigned) !== 0;
  const showRemoveAssignment =
    ledgerEntry.type_name_key === LedgerEntryNameKey.AccountCharge && Number(ledgerEntry.total_assigned) !== 0;
  const ledgerTypeForDeleteConfirm = ledgerEntry.type_name_key as LedgerEntryNameKey;

  const canReversePayment =
    ledgerEntry.type_name_key === LedgerEntryNameKey.Payment &&
    !ledgerEntry.reversal &&
    (ledgerEntry.financial_amount || 0) < 0;

  const values = Array<MenuItemProps>();
  values.push({
    title: translations.shared.editButtonText,
    onClick: editAction,
    disabled: editButtonDisabled,
  });
  if (showDelete) {
    values.push(
      getMenuItemPropsWithOnCancelPopconfirm(
        {
          title: translations.shared.deleteButtonText,
          onClick: deleteLedgerEntry,
        },
        translations.viewContactLedgerPage.deleteLedgerEntryConfirmationMessage(ledgerTypeForDeleteConfirm)
      )
    );
  }
  if (showRemoveAssignment && removeAssignment) {
    values.push({
      title: translations.shared.removeAssignment,
      onClick: removeAssignment,
    });
  }
  if (canReversePayment) {
    values.push({
      title: translations.reversePaymentModal.buttonText,
      onClick: () => openReversePaymentModal(ledgerEntry),
    });
  }
  if (Number(ledgerEntry.financial_amount) < 0) {
    values.push(
      ...getPrintEmailAndStripeDetailsMenuItems({
        ledgerEntry,
        printLedgerEntry,
        emailLedgerEntry,
        disableEmailOption,
        openDetailsModal,
      })
    );
  }
  return values;
};

export const getPrintEmailAndStripeDetailsMenuItems = ({
  ledgerEntry,
  printLedgerEntry,
  emailLedgerEntry,
  disableEmailOption,
  openDetailsModal,
}: {
  ledgerEntry: Ledger;
  printLedgerEntry: (ledgerEntry: Ledger) => void;
  emailLedgerEntry: (ledgerEntry: Ledger) => void;
  disableEmailOption: boolean;
  openDetailsModal: (ledgerEntry: Ledger) => void;
}) => {
  const isStripePayment =
    ledgerEntry.type_name_key === LedgerEntryNameKey.Payment &&
    ledgerEntry.processor_type_id === ElectronicPaymentType.Stripe;

  return [
    {
      title: translations.shared.email,
      onClick: () => emailLedgerEntry(ledgerEntry),
      disabled: disableEmailOption,
    },
    {
      title: translations.shared.print,
      onClick: () => printLedgerEntry(ledgerEntry),
    },
    ...(isStripePayment
      ? [
          {
            title: translations.viewContactLedgerPage.actionItems.details,
            onClick: () => openDetailsModal(ledgerEntry),
          },
        ]
      : []),
  ];
};

export const mapLedgerToReportQueryArgs = (
  ledger: Ledger,
  organizationId: string,
  practiceId: string
): QueryGetPaymentReceiptReportArgs => {
  return {
    contactId: ledger.contact_id || '',
    ledgerId: ledger.id,
    request: {
      returnPreSignedUrl: 'true',
      fileName: `${ledger.number}_Receipt.pdf`,
      practiceId,
      organizationId,
    },
  };
};

export const getLedgerReceiptAsEmailAttachment = async (variables: QueryGetPaymentReceiptReportArgs) => {
  let attachment: Attachment | undefined = undefined;
  await getLedgerReceiptUrl(variables, (url) => {
    attachment = {
      file_pointer: url,
      file_name: variables.request.fileName ?? '',
    };
  });
  return attachment;
};

export const printLedgerReceipt = async (variables: QueryGetPaymentReceiptReportArgs) => {
  await getLedgerReceiptUrl(variables, (url) => openTabWithPopupDetection(url));
};

const getLedgerReceiptUrl = async (variables: QueryGetPaymentReceiptReportArgs, onSuccess: (url: string) => void) => {
  try {
    const { data } = await AppSyncService.client.query({
      query: GetLedgerReceipt,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.getPaymentReceiptReport?.pointer) {
      const pointer = data.getPaymentReceiptReport.pointer;

      onSuccess(pointer);
    } else {
      showErrorMessage(translations.viewContactLedgerPage.errorGeneratingReport);
    }
  } catch (err) {
    showErrorMessage(err.message ? err.message : translations.viewContactLedgerPage.errorGeneratingReport);
  }
};
