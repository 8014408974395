import { ContactInterestEntry } from '../../../graph/types';

export type RunsAction = SetSelectedContactRun | SetRunActionLoading | SetRefetchRun;

export const setSelectedRunsType = 'RUNS__SET';
export const setActionLoadingType = 'RUNS_ACTION_LOADING___SET';
export const setRefetchRunType = 'RUNS_REFETCH__SET';

export interface SetSelectedContactRun {
  type: typeof setSelectedRunsType;
  runs: ContactInterestEntry[];
}

export const setSelectedContactRuns = (runs: ContactInterestEntry[]): SetSelectedContactRun => ({
  type: setSelectedRunsType,
  runs,
});

export interface SetRunActionLoading {
  type: typeof setActionLoadingType;
  loading: boolean;
}

export const setRunActionLoading = (loading: boolean): SetRunActionLoading => ({
  type: setActionLoadingType,
  loading,
});

export interface SetRefetchRun {
  type: typeof setRefetchRunType;
  refetchRun: () => void;
}

export const setRefetchRun = (refetchRun: () => void): SetRefetchRun => ({
  type: setRefetchRunType,
  refetchRun,
});
