import React from 'react';
import { SelectPersonModalTableProps } from '../SelectContactModal/SelectContactModalTable';
import { CustomColumnType, TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import { Contact, Invoice, Patient } from '../../graph/types';
import { TableCellLink } from '../TableLink/TableCellLink';
import { addSortingPriorityTo } from '../../util/filterAndSorting';
import { Checkbox } from 'antd';
import { translations } from '../../constants/translations';
import { TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { MultiSelectTableFooter } from '../SelectPatientModal/MultiSelectTableFooter';
import { removeEntity, selectAll, toggleEntity } from './selectEntityModalTableUtil';

const maxSelections = 100;

export type Entity = Patient | Contact | Invoice;

interface SelectEntityModalTableProps<T> extends SelectPersonModalTableProps {
  setSelectedEntities: (entity: T[]) => void;
  selectedEntities: T[];
  entities?: T[];
  loading?: boolean;
  canSelectMultiple?: boolean;
  filterAndSortTableValues: ReturnType<typeof useTableResetFilterAndSort>;
  columns: CustomColumnType<T>[];
  entity?: string;
}

type ComponentProps<T> = React.PropsWithChildren<SelectEntityModalTableProps<T>>;

export const SelectEntityModalTable = <T extends Entity>({
  setSelectedEntities,
  selectedEntities,
  entities,
  loading,
  canSelectMultiple,
  filterAndSortTableValues,
  columns,
  entity,
}: ComponentProps<T>) => {
  const { tableChangeHandler } = filterAndSortTableValues;

  const selectEntityLink = (text: string, entity: T) => {
    return <TableCellLink onClick={() => setSelectedEntities([entity])}>{text}</TableCellLink>;
  };

  const getColumns = (): CustomColumnType<T>[] => {
    const allColumns = Array<CustomColumnType<T>>();

    if (canSelectMultiple) {
      allColumns.push({
        key: 'selected',
        render: (entity: T) => {
          return (
            <Checkbox
              id={'chk' + entity.id}
              data-testid={'chk' + entity.id}
              checked={selectedEntities.filter((selectedEntity) => selectedEntity.id === entity.id).length > 0}
              onChange={(e) =>
                setSelectedEntities(toggleEntity<T>(e.target.checked, entity, selectedEntities, maxSelections))
              }
            />
          );
        },
        width: 40,
      });
    }

    allColumns.push(...columns);

    if (!canSelectMultiple) {
      allColumns.push({
        title: translations.shared.selectContactModal.tableColumnAction,
        key: 'actions',
        render: (entity: T) => selectEntityLink(translations.shared.selectContactModal.select, entity),
        width: 90,
      });
    }

    return allColumns;
  };

  addSortingPriorityTo(columns);

  const onRemoveSelectedEntity = (entity: T) => {
    setSelectedEntities(removeEntity(entity, selectedEntities));
  };

  const getTableFooter = () => {
    return (
      canSelectMultiple && (
        <MultiSelectTableFooter
          selectedEntities={selectedEntities}
          onClearAll={() => setSelectedEntities([])}
          onSelectAll={() => setSelectedEntities(selectAll(entities, selectedEntities, maxSelections))}
          onRemovePatient={onRemoveSelectedEntity}
          entity={entity}
        />
      )
    );
  };

  return (
    <TableWithCustomFiltering
      tableKey={TableKey.SelectPatientModal}
      className={`SelectPatientModalTable ${canSelectMultiple ? 'SelectPatientModalTable--multiple' : ''}`}
      dataSource={entities}
      columns={getColumns()}
      rowKey={'id'}
      style={{ marginBottom: '-24px' }}
      footer={getTableFooter}
      loading={loading}
      onChange={tableChangeHandler}
    />
  );
};
