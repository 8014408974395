import { useForm } from 'antd/lib/form/Form';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { translations } from '../../../constants/translations';
import { SaveSpinnerAndNavigationWarning } from '../../SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { FileCard, FileUpsertValues } from './FileCard';
import { selectRecordAction, setHasUnsavedChangesAction } from '../../Records/store/actions';
import { RecordsContext } from '../../Records/store/state';
import { formats } from '../../../constants/formats';
import dayjs from 'dayjs';
import { File } from '../../../graph/types';
import { FileData } from '../../../util/fileUpload';
import { Store } from 'antd/lib/form/interface';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { RecordItem } from '../../Records/recordUtils';
import {
  getRecordFromFile,
  getUpdateFileUpsert,
  useGetFileRefData,
  useSendFileUpsertToBackend,
} from '../../../util/fileUtils';
import { getButtonRowForNewRecord } from '../ButtonRow';

interface AddFileRecordProps extends PropsWithChildren<unknown> {
  contactId?: string;
  patientId?: string;
  insertNewRecordInState: (recordItem: RecordItem) => void;
  setEditing?: (editing: boolean) => void;
  setShouldResetOnTabChange?: (value: boolean) => void;
  shouldResetOnTabChange?: boolean;
}

export const AddFileRecord: React.FC<AddFileRecordProps> = ({
  patientId,
  contactId,
  insertNewRecordInState,
  setEditing,
  setShouldResetOnTabChange,
  shouldResetOnTabChange,
}: AddFileRecordProps) => {
  const {
    state: { hasUnsavedChanges },
    dispatch,
  } = useContext(RecordsContext);

  const [form] = useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileData>();
  const [textContent, setTextContent] = useState<string>('');
  const sendFileUpsertToBackend = useSendFileUpsertToBackend();
  const { fileRefData } = useGetFileRefData(!!(patientId || contactId));

  const onCancel = () => {
    resetPage();
  };

  useEffect(() => {
    setEditing?.(hasUnsavedChanges);
  }, [hasUnsavedChanges, setEditing]);

  const resetPage = useCallback(() => {
    dispatch(selectRecordAction(undefined));
    dispatch(setHasUnsavedChangesAction(false));
    setEditing?.(false);
  }, [setEditing, dispatch]);

  useEffect(() => {
    if (shouldResetOnTabChange && setShouldResetOnTabChange) {
      resetPage();
      setShouldResetOnTabChange(false);
    }
  }, [shouldResetOnTabChange, resetPage, setShouldResetOnTabChange]);

  const defaultFile: File = {
    id: '',
    type_id: '',
    type_name: translations.recordList.newFileTitle,
    name: '',
    size: 0,
    pointer: '',
    date: dayjs().format(formats.upsertDate),
    created_user_name: '',
    created: dayjs().format(formats.upsertDate),
    created_user_id: '',
    hidden: false,
    locked: false,
    description: translations.recordList.textFieldPlaceholder,
    updated: dayjs().format(formats.upsertDate),
  };

  const handleSave = async (values: Store) => {
    if (!fileData?.pointer) {
      showErrorMessage(translations.recordList.noFileError);
      return;
    }

    setIsSaving(true);

    const fileValues: FileUpsertValues = {
      type_id: values.type_id,
      date: values.date,
      pointer: fileData?.pointer,
      name: fileData?.name,
      size: fileData?.size,
      description: textContent === translations.recordList.textFieldPlaceholder ? undefined : textContent,
    };

    const upsert = getUpdateFileUpsert(defaultFile, fileValues, patientId, contactId);
    setEditing?.(false);
    const file = await sendFileUpsertToBackend(upsert, resetPage, translations.shared.saveSuccessMessage);
    if (file) {
      insertNewRecordInState(getRecordFromFile(file));
    }
  };

  const buttonRow = getButtonRowForNewRecord(onCancel);

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.patientPage.entity)}
    >
      <FileCard
        setIsSaving={setIsSaving}
        file={defaultFile}
        editMode
        onSave={handleSave}
        onClose={onCancel}
        form={form}
        fileData={fileData}
        setFileData={setFileData}
        isNewRecord
        textContent={textContent}
        setTextContent={setTextContent}
        buttonRow={buttonRow}
        fileRefData={fileRefData}
      />
    </SaveSpinnerAndNavigationWarning>
  );
};
