import axios from 'axios';
import { GetChatBotAuthToken } from '../../../graph/queries/chatBot';
import { useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { translations } from '../../../constants/translations';

export interface ChatBotQuestionRequest {
  question: string;
  user_id: string;
  user_email: string;
  user_name: string;
}

export interface ChatBotQuestionResponse {
  response: string;
}

export const useGetChatBotAuthToken = ({
  organizationId,
  practiceId,
}: {
  organizationId: string;
  practiceId: string;
}) => {
  const { data, loading, error, refetch } = useOfflineErrorSkipQuery<'getChatBotAuthToken'>(GetChatBotAuthToken, {
    query: GetChatBotAuthToken,
    variables: { organizationId, practiceId },
  });
  return {
    chatBotAuthToken: data?.getChatBotAuthToken,
    chatBotAuthTokenLoading: loading,
    chatBotAuthTokenError: error,
    chatBotAuthTokenRefetch: refetch,
  };
};

export const sendChatBotQuestion = async (endpoint: string, jwtToken: string, request: ChatBotQuestionRequest) => {
  const headers = {
    'X-Auth-Token': jwtToken,
  };
  let response = '';
  await axios
    .post<ChatBotQuestionResponse>(`${endpoint}/chatBot_v2`, request, { headers })
    .then((data) => {
      response = data.data.response;
    })
    .catch(() => {
      showErrorMessage(translations.support.errorMessage);
      response = translations.support.responseErrorMessage;
    });
  return response;
};
