import axios from 'axios';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { translations } from '../constants/translations';
import { FileS3Url } from '../graph/types';
import { fetchS3UploadUrl } from './fileUtils';

export const megabyteInBytes = (2 ** 10) ** 2;

export const uploadFile = (uploadUrl: FileS3Url, file: RcFile, onProgress?: (percent: number) => void) => {
  return axios.request({
    method: 'PUT',
    url: uploadUrl.url,
    data: file,
    onUploadProgress: (progress) => {
      onProgress?.(progress.loaded / progress.total);
    },
  });
};

export interface FileData {
  pointer: string;
  name: string;
  size: number;
  isChangedFile?: boolean;
}

export const getUploadRequestToS3 =
  (
    organizationId: string,
    setIsSaving: (isSaving: boolean) => void,
    setFileData: (fileData: FileData) => void,
    maximalFileSizeInMegaBytes = 100,
    filePath = '',
    onProgress?: (percentage: number) => void
  ) =>
  async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
    const rcFile = file as RcFile;
    try {
      if (rcFile.size > maximalFileSizeInMegaBytes * megabyteInBytes) {
        showErrorMessage(translations.shared.getMaximumFileSizeError(`${maximalFileSizeInMegaBytes} MB`));
        return;
      }
      setIsSaving(true);
      const uploadUrl = await fetchS3UploadUrl(organizationId, filePath);
      const response = await uploadFile(uploadUrl, rcFile, onProgress);
      await setFileData({ pointer: uploadUrl.filePointer, name: rcFile.name, size: rcFile.size, isChangedFile: true });
      // TODO: double check functionality of below
      onSuccess?.(response);
    } catch (err) {
      onError?.(err as Error);
      showErrorMessage((err as Error).message ?? err);
    }
    setIsSaving(false);
  };

export const mapFileDataToCommunicationLogAttachmentUpsert = (file: FileData) => {
  return {
    file_name: file.name,
    file_pointer: file.pointer,
  };
};
