import React from 'react';
import { StyledPageHeader } from '../../../../components/PageHeader/PageHeader.style';
import { getProfileImagePlaceholder } from '../../../Patients/ViewPatient/PatientProfileImage/profileImagePlaceholders/profileImagePlaceholders';
import { Button, Descriptions, Dropdown } from 'antd';
import { getPatientDescription, getRelationshipTags } from '../../doctorOfficeDisplayUtil';
import { JobUpsert, Patient, UserOrganizationPatientCard } from '../../../../graph/types';
import { translations } from '../../../../constants/translations';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useGetPatient } from '../../../../hooks/ajax/patients/patientHooks';
import { useGetUrlForFileDownload } from '../../../../hooks/ajax/fileUrl/fileUrlHooks';
import { getOwnerTagsFromArrays } from '../../../Patients/ViewPatient/PatientOwnership/OwnershipTags/ownershipTagsUtil';
import { buildPatientVaxRptVariables, ModalTypes } from '../../../Patients/patientUtils';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useGetSmtpSettings } from '../../../../hooks/ajax/email/emailHooks';
import { hasValidEmailSettings } from '../../../../util/email';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { useDefaultPracticeId } from '../../../../hooks/ajax/practice/practiceHooks';
import { getJobRecordUpsert } from '../../../../util/jobUtil';
import { SyncPatient } from '../../../../graph/queries/patients';
import { showErrorMessage } from '../../../../components/Notification/notificationUtil';
import { useUpsertJob } from '../../../../hooks/ajax/job/jobHooks';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import { useLDFlag } from '../../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../../constants/launchDarkly';
import { ActionButtonsForPatient } from '../../../Patients/ActionButtonsForPatient';

const FloatRightDiv = styled.div`
  margin-left: auto;
  padding: 0 2rem;
`;

interface DoctorOfficePatientHeaderProps {
  patientCard: UserOrganizationPatientCard;
  setIsModalOpen: (value: ModalTypes) => void;
  handlePatientDelete: () => Promise<void> | void;
  handlePatientMerge: () => void;
  setLoading: (value: boolean) => void;
  setJobId: (value: string | undefined) => void;
  setIsGeneralSync: (value: boolean) => void;
  patient: Patient;
}

export const useGetPatientProfileImageFileUrl = (organizationId: string, patientId: string) => {
  const { patient } = useGetPatient(patientId, organizationId);

  const { fileUrl } = useGetUrlForFileDownload({
    organizationId: patient?.organization_id || '',
    fileName: patient?.profile_image?.name || '',
    filePointer: patient?.profile_image?.pointer || '',
  });
  return fileUrl;
};

const fixAlignmentOfTag = (Tag: JSX.Element[] | JSX.Element) => <div style={{ marginTop: -2 }}>{Tag}</div>;

const DoctorOfficePatientHeader: React.FC<DoctorOfficePatientHeaderProps> = ({
  patientCard,
  setIsModalOpen,
  handlePatientDelete,
  handlePatientMerge,
  patient,
  setLoading,
  setJobId,
  setIsGeneralSync,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const profileImageFileUrl = useGetPatientProfileImageFileUrl(organizationId, patientCard.patient_id);

  const ownershipNames = patientCard.patient_owner_names ?? [];
  const percentages = patientCard.patient_owner_percentages ?? [];
  const ownershipIds = patientCard.patient_owner_ids ?? [];
  const ownershipTags = getOwnerTagsFromArrays(ownershipNames, percentages, ownershipIds);
  const smtpSettings = useGetSmtpSettings();
  const { enabledAndOffline } = useOffline();
  const practiceId = useDefaultPracticeId();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const { relatedContactsTags } = getRelationshipTags(patientCard);

  const subTitle = getPatientDescription(patientCard, dateFormat);

  const [upsertJob] = useUpsertJob();
  const usesActiveReports = useLDFlag(LDFlagNames.ARPatientVaxReport);

  return (
    <StyledPageHeader
      title={`${patientCard.patient_number} ${patientCard.patient_name}`}
      subTitle={subTitle}
      avatar={{
        src: profileImageFileUrl?.url || getProfileImagePlaceholder(patientCard.patient_species_name),
        shape: 'square',
      }}
    >
      <Descriptions column={3} size={'small'}>
        <Descriptions.Item label={translations.doctorOffice.patientView.ownership}>
          {fixAlignmentOfTag(ownershipTags)}
        </Descriptions.Item>
        <Descriptions.Item label={translations.doctorOffice.patientView.relationships}>
          {fixAlignmentOfTag(relatedContactsTags)}
        </Descriptions.Item>
        <Descriptions.Item>
          <FloatRightDiv>
            {!enabledAndOffline && (
              <Dropdown
                overlay={
                  <ActionButtonsForPatient
                    setIsModalOpen={setIsModalOpen}
                    disableMailOption={!hasValidEmailSettings(smtpSettings)}
                    handlePatientDelete={handlePatientDelete}
                    handlePatientMerge={handlePatientMerge}
                    handlePatientSync={async () => {
                      const variables = {
                        organizationId,
                        patient: { id: patient.id },
                      };
                      const jobRecord = getJobRecordUpsert(SyncPatient, variables);
                      const job: JobUpsert = {
                        record: jobRecord,
                      };
                      setLoading(true);
                      try {
                        const { data } = await upsertJob({
                          variables: {
                            organizationId,
                            job,
                          },
                        });

                        setJobId(data?.upsertJob?.id);
                        setIsGeneralSync(true);
                      } catch (e) {
                        showErrorMessage((e as Error).message);
                      }
                    }}
                    vaccineReportVariables={buildPatientVaxRptVariables({
                      patient,
                      organizationId,
                      practiceId,
                      usesActiveReports,
                      returnPreSignedUrl: true,
                    })}
                  />
                }
              >
                <Button>
                  {translations.patientsPage.actions} <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </FloatRightDiv>
        </Descriptions.Item>
      </Descriptions>
    </StyledPageHeader>
  );
};

export default DoctorOfficePatientHeader;
