import { ApolloClient, ApolloLink, FieldFunctionOptions, InMemoryCache, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { AUTH_TYPE } from 'aws-appsync-auth-link/lib';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { get } from 'lodash';
import config from '../../config/config';
import introspectionResult from '../../graph/possibleTypes.json';
import { getJWTToken } from '../../util/authUtil';

interface UrlInfo {
  url: string;
  region: string;
  auth: AuthOptions;
}

const getCombinedDataWithDuplicatesRemoved = (
  existing: any[] = [],
  incoming: any[],
  options: FieldFunctionOptions<Record<string, any>, Record<string, any>>
) => {
  if (options?.args?.filter?.id) {
    return incoming;
  }
  const incomingRefs = incoming.map(({ __ref }) => __ref);
  return [...existing.filter(({ __ref }) => incomingRefs.indexOf(__ref) === -1), ...incoming];
};

class AppSyncService {
  public client: ApolloClient<any>;

  private static appSyncService: AppSyncService;

  public static getInstance() {
    if (!this.appSyncService) {
      this.appSyncService = new AppSyncService();
    }
    return this.appSyncService;
  }

  private typeDefs = gql`
    extend type ServiceRendered {
      cacheLoading: Boolean #ONLY TO BE USED IN FRONT END TO DENOTE CACHE LOADING STATE
    }

    extend type Mutation {
      upsertInvoice(organizationId: ID!, invoice: InvoiceUpsert!): Invoice
    }
  `;

  private constructor() {
    this.client = new ApolloClient({
      link: this.getLink(),
      cache: new InMemoryCache({
        possibleTypes: introspectionResult.possibleTypes,
        typePolicies: {
          Practice: {
            merge: true,
          },
          Organization: {
            merge: true,
          },
          //disable type from being identified by id for caching
          ReferenceData: {
            keyFields: false,
          },
          InfoType: {
            keyFields: false,
          },
          UserPermission: {
            keyFields: false,
          },
          GlobalSearchResult: {
            keyFields: false,
          },
          OrganizationSubscriptionAddOn: {
            keyFields: ['level_id'],
          },
          LabSpeciesMapping: { keyFields: ['cassadol_species_id'] },
          LabBreedMapping: { keyFields: ['cassadol_breed_id'] },
          LabGenderMapping: { keyFields: ['cassadol_gender_id'] },
          LabServiceMapping: { keyFields: ['cassadol_service_id', 'id'] },
          UnassignedLabResults: { keyFields: ['lab_request_id'] },
          PrescriptionFlattened: { keyFields: ['fill_id'] },
          ServiceRendered: {
            fields: {
              cacheLoading: {
                read: (existing) => {
                  if (!existing) {
                    return false;
                  }
                  return existing;
                },
              },
            },
          },
          Query: {
            fields: {
              getInvoicesPg: {
                // Concatenate the incoming list items with
                // the existing list items.
                merge(existing = [], incoming, options) {
                  return getCombinedDataWithDuplicatesRemoved(existing, incoming, options);
                },
                keyArgs(args) {
                  if (args?.filter.contact_id) {
                    return ['filter', ['contact_id']];
                  }
                  if (args?.filter.id) {
                    return ['filter', ['id']];
                  }
                  return undefined;
                },
              },
              getLedgersPg: {
                merge(existing: any[] = [], incoming: any[], options) {
                  return getCombinedDataWithDuplicatesRemoved(existing, incoming, options);
                },
                keyArgs(args) {
                  if (args?.filter.contact_id) {
                    return ['filter', ['contact_id']];
                  }
                  if (args?.filter.invoice_id) {
                    return ['filter', ['invoice_id']];
                  }
                  if (args?.filter.id) {
                    return ['filter', ['id']];
                  }
                  return undefined;
                },
              },
              getServicesPg: {
                merge(existing = [], incoming, options) {
                  return getCombinedDataWithDuplicatesRemoved(existing, incoming, options);
                },
                keyArgs(args) {
                  if (args?.filter.searchText) {
                    return ['filter', ['searchText']];
                  }
                  if (args?.filter.id) {
                    return ['filter', ['id']];
                  }
                  return undefined;
                },
              },
            },
          },
        },
      }),
      typeDefs: this.typeDefs,
    });
  }

  private getLink() {
    const errorLink = onError(({ networkError }) => {
      if (networkError) {
        const serverMessage = get(networkError, 'result.message');
        if (serverMessage) {
          networkError.message = serverMessage;
        }
      }
    });

    const authProps: UrlInfo = {
      url: config.APPSYNC_URL,
      region: config.REGION,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: getJWTToken,
      },
    };

    const subscriptionHandshakeLink = createSubscriptionHandshakeLink(authProps);

    // remove the casts when AWS merges this PR:
    // https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/561
    return ApolloLink.from([
      errorLink,
      createAuthLink(authProps) as unknown as ApolloLink,
      subscriptionHandshakeLink as unknown as ApolloLink,
    ]);
  }
}

export default AppSyncService.getInstance();
