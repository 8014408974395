import { InvoiceUpsert, ServiceRendered } from '../../../graph/types';
import {
  getUpsertForNewServiceRenderedForBatchCreation,
  getUpsertForUpdatedServiceRendered,
} from '../../../components/ServiceRendered/serviceRenderedUpsertUtil';

export const isUpdatedServiceRenderedValid = (updatedServiceRendered: ServiceRendered) => {
  return !!(
    !isNaN(+(updatedServiceRendered.quantity ?? 1)) &&
    !isNaN(+updatedServiceRendered.total) &&
    updatedServiceRendered.name &&
    updatedServiceRendered.date &&
    updatedServiceRendered.doctor_id
  );
};

export const isNonZeroNumber = (value: number) => {
  return value && !isNaN(value);
};

export const getInvoiceUpsertForUpdatingRenderedService = (
  updatedServiceRendered: ServiceRendered,
  dateFormat: string
): InvoiceUpsert => {
  const serviceRenderedUpsert = getUpsertForUpdatedServiceRendered(updatedServiceRendered, dateFormat);
  return {
    id: updatedServiceRendered.invoice_id,
    item: [serviceRenderedUpsert],
  };
};

export const getInvoiceUpsertForUpdatingMultipleRenderedService = (
  invoiceId: string,
  updatedServicesRendered: ServiceRendered[],
  dateFormat: string
): InvoiceUpsert => {
  return {
    id: invoiceId,
    item: updatedServicesRendered.map((i) => getUpsertForUpdatedServiceRendered(i, dateFormat)),
  };
};

export const getInvoiceUpsertForAddServices = (
  invoiceId: string,
  servicesRendered: ServiceRendered[],
  dateFormat: string
): InvoiceUpsert => {
  return {
    id: invoiceId,
    item: servicesRendered.map((serviceRendered) =>
      getUpsertForNewServiceRenderedForBatchCreation(serviceRendered, dateFormat)
    ),
  };
};
