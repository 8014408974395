import { HisaNameKeyFormFieldMap, HisaRequirementsNameKey, HisaNameKeys } from '../../../constants/hisaRequirements';
import { ServiceRendered } from '../../../graph/types';

export const getInitialHisaValuesFromServiceRendered = (initialValues: any, serviceRendered?: ServiceRendered) => {
  if (serviceRendered?.info) {
    serviceRendered.info.forEach((info) => {
      if (HisaNameKeys.includes(info.name_key as HisaRequirementsNameKey)) {
        const formKey = HisaNameKeyFormFieldMap[info.name_key as HisaRequirementsNameKey];
        initialValues[formKey] = info.value;
      }
    });
  }
  if (serviceRendered?.text) {
    serviceRendered.text.forEach((text) => {
      if (HisaNameKeys.includes(text.name_key as HisaRequirementsNameKey)) {
        const formKey = HisaNameKeyFormFieldMap[text.name_key as HisaRequirementsNameKey];
        initialValues[formKey] = text.value;
      }
    });
  }
  return initialValues;
};
