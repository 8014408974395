import { OrganizationDto } from '../../graph/types';
import { OrganizationAction, setOrganizationType, setPracticeSettingType } from './action';

export interface OrganizationState {
  organization?: OrganizationDto;
}

export const initialState: OrganizationState = {
  organization: undefined,
};

export const organizationReducer = (
  state: OrganizationState = initialState,
  action: OrganizationAction
): OrganizationState => {
  switch (action.type) {
    case setOrganizationType:
      return {
        ...state,
        organization: action.organization,
      };
    case setPracticeSettingType:
      return state.organization
        ? {
            ...state,
            organization: {
              ...state.organization,
              practice: state?.organization?.practice?.map((currentPractice) =>
                currentPractice.id === action.practiceId
                  ? {
                      ...currentPractice,
                      setting: currentPractice.setting.some(
                        (currentSetting) => currentSetting.setting_id === action.setting.setting_id
                      )
                        ? currentPractice.setting.map((currentSetting) =>
                            currentSetting.setting_id === action.setting.setting_id ? action.setting : currentSetting
                          )
                        : [...currentPractice.setting, action.setting],
                    }
                  : currentPractice
              ),
            },
          }
        : {};
    default:
      return state;
  }
};
