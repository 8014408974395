import React, { useCallback, useContext, useMemo } from 'react';
import { TableWithCustomFiltering } from '../TableWithCustomFiltering/TableWithCustomFiltering';
import phoneTableColumns from './phoneTableColumns';
import { TableKey } from '../../hooks/tableHooks';
import { Button } from 'antd';
import { translations } from '../../constants/translations';
import styled from 'styled-components';
import { ButtonProps } from 'antd/es/button';
import { phoneTableActions, PhoneTableContext, PhoneTableContextProvider } from './phoneTableState';
import { getNewEntryRowForPhoneTable } from './phoneTableUtil';
import { ViewSubscriptionContext } from '../ViewSubscription/store/state';
import ViewSubscriptionActions from '../ViewSubscription/store/actions';

export type TempPhone = {
  id: string;
  primary: boolean;
  type_name: string;
  description: string;
  phone: string;
  extension: string;
};

const StyledButton = styled(Button)<ButtonProps>`
  margin-bottom: 1rem;
`;

export default function PhoneTable({ newContact }: { newContact?: boolean }) {
  const { state, dispatch } = useContext(PhoneTableContext);
  const { dispatch: dispatchViewContact } = useContext(ViewSubscriptionContext);

  const tableData = useMemo(
    () =>
      state.currentEditingPhone?.isNew && state.currentEditingPhone?.phone
        ? [state.currentEditingPhone.phone, ...state.phoneTableData]
        : state.phoneTableData,
    [state.currentEditingPhone, state.phoneTableData]
  );

  const handleOnAddNewEntry = useCallback(() => {
    if (!newContact) {
      dispatchViewContact?.(ViewSubscriptionActions.setEditing(true));
    }
    if (state.currentEditingPhone) {
      return;
    }
    dispatch(
      phoneTableActions.setCurrentEditing({
        isNew: true,
        phone: getNewEntryRowForPhoneTable(!state.phoneTableData.length),
      })
    );
  }, [dispatch, dispatchViewContact, state.currentEditingPhone, state.phoneTableData.length, newContact]);

  return (
    <PhoneTableContainer>
      <StyledButton onClick={handleOnAddNewEntry} disabled={!!state.currentEditingPhone}>
        {translations.phoneTable.addNewEntry}
      </StyledButton>
      <TableWithCustomFiltering
        tableKey={TableKey.Phone}
        rowKey={'id'}
        dataSource={tableData}
        columns={Object.values(phoneTableColumns)}
        pagination={tableData.length > 10}
      />
    </PhoneTableContainer>
  );
}

export function PhoneTableWithContext() {
  return (
    <PhoneTableContextProvider>
      <PhoneTable />
    </PhoneTableContextProvider>
  );
}

const PhoneTableContainer = styled.div`
  overflow: auto;
  width: 100%;
`;
