import { useOrganizationContext } from '../contexts/organization/state';
import { useUserContext } from '../contexts/user/state';
import { User } from '../graph/types';
import { useFindUserOrganization } from './useFindUserOrganization';

export const useUserPermissions = (user?: User | null) => {
  const {
    state: { user: currentUser },
  } = useUserContext();
  const selectedUser = user ? user : currentUser;
  const userOrganization = useFindUserOrganization(selectedUser);
  const isOrgAdmin = userOrganization?.organization_administrator;
  const isGlobalAdmin = selectedUser?.global_admin;
  const isOfflineAble = userOrganization?.enable_offline;

  const { isUserOrgOwner: isOrgOwner } = useIsUserOrgOwner(selectedUser);

  return { isOrgAdmin, isOrgOwner, isGlobalAdmin, isOfflineAble };
};

export const useIsUserOrgOwner = (user?: User | null) => {
  const {
    state: { organization },
  } = useOrganizationContext();

  return { isUserOrgOwner: organization?.account_owner_id === user?.id };
};
