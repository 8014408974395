import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Button, Form, Image, Input, InputNumber, Radio, Select, Space } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { linkColor, washedGrey } from './colorScheme';
import Title from 'antd/lib/typography/Title';

const antDGridColumnCount = 24;
const antdAfterLabelColonMargin = 8;

// MIXINS
export type CustomWidthComponent = { width: number };
export const CustomWidthMixin = css<CustomWidthComponent>`
  width: ${({ width }) => width}%;
`;

export type CustomMarginComponent = { margin: string };
export const CustomMarginMixin = css<CustomMarginComponent>`
  margin: ${({ margin }) => margin};
`;

export type FlexAlignItem = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';

export type CustomFlexContainerType = {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: string;
  alignItems?: FlexAlignItem;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  columnGap?: string;
  rowGap?: string;
};

export const CustomFlexContainerMixin = css<CustomFlexContainerType>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'inherit' }) => alignItems};
  flex-wrap: ${({ flexWrap = 'nowrap' }) => flexWrap};
  column-gap: ${({ columnGap = '0px' }) => columnGap};
  row-gap: ${({ rowGap = '0px' }) => rowGap};
`;

export type CustomFlexItemType = { flexGrow?: number; flexShrink?: number; alignSelf?: FlexAlignItem };
export const CustomFlexItemMixin = css<CustomFlexItemType>`
  flex-grow: ${({ flexGrow = 0 }) => flexGrow};
  flex-shrink: ${({ flexShrink = 0 }) => flexShrink};
  align-self: ${({ alignSelf = 'auto' }) => alignSelf};
`;

export const LinkMixin = css`
  color: ${linkColor};
`;

export const OffsetMixin = css<{ offset?: number }>`
  width: calc(${({ offset }) => (offset ? (offset / antDGridColumnCount) * 100 : 0)}% - ${antdAfterLabelColonMargin}px);
  display: flex;
  justify-content: flex-end;
`;

// CONTAINER
export const W12Container = styled.div`
  width: 50%;
`;

export const WidthContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
`;

export const FlexContainer = styled.div`
  ${CustomFlexContainerMixin}
`;

export const FlexItem = styled.div`
  ${CustomFlexItemMixin}
`;

export const flexWrapContainer: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: 'auto',
  width: '100%',
};

export const OffsetContainer = styled.div`
  ${OffsetMixin}
`;

export const OffsetTitle = styled(Title)`
  ${OffsetMixin}
`;

// GRID
export const w24 = {
  width: '100%',
};

export const w18 = {
  width: '75%',
};

export const w16 = {
  width: '66%',
};

export const w12 = {
  width: '50%',
};
export const w8 = {
  width: '33%',
};

export const w6 = {
  width: '25%',
};

// SPACING

export const offset_3 = {
  marginLeft: '12.5%',
};

export const offset_4 = {
  marginLeft: '16.67%',
};

export const center = {
  margin: 'auto',
};

export const ExtraContentSpace = styled(Space)`
  margin-right: 10px;
`;

// FORM
export const FormWithFlexWrap: typeof Form = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
`;

// COMPONENTS
export const CustomIFrame = styled.iframe<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  margin: auto;
`;

export const CustomWidthInput = styled(Input)`
  ${CustomWidthMixin}
`;

export const CustomWidthInputNumber = styled(InputNumber)`
  ${CustomWidthMixin}
`;

export const PaddedRadio = styled(Radio.Group)`
  padding: 0 0 20px 24px;
`;

export const NoBottomMarginFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const SelectWithWidth = styled(Select)<SelectProps<number | string>>`
  && {
    width: 200px;
  }
`;

export const ErrorText = styled.p`
  color: red;
`;

export const TitleContainer = styled.div`
  font-size: 1.2em;
  margin-bottom: 0.2rem;
`;

export const InstructionContainer = styled.p`
  font-weight: 400;
`;

export const StyledOptionContent = styled.span`
  display: inline-block;
  width: 245px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleDiv = styled.div<{ bold?: boolean }>`
  line-height: 22px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  margin-bottom: 20px;
`;

export const LinkButton = styled(Button)`
  ${LinkMixin}
`;

export const ImagePreview = styled(Image)`
  height: 100%;
  width: auto;
  max-height: 200px;
  max-width: 100%;
`;

export const FlexCenteredDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelSpan = styled.span`
  font-weight: bold;
  margin-right: 4px;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexHeaderDivContainer = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;
`;

export const FlexDivContainer = styled(FlexDiv)`
  width: 100%;
  justify-content: start;
  align-items: center;
`;

export const FlexItemContainer = styled.div`
  margin: 0 8px;
  display: flex;
`;

export const MetadataSpan = styled.span`
  font-size: small;
  color: ${washedGrey};
  margin-right: 20px;
`;

export const CenterAlignFlexDiv = styled(FlexDiv)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// MISC
export const hiddenStyle = {
  display: 'none',
};
