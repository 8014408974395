import React from 'react';
import { useGetPatient } from '../../../hooks/ajax/patients/patientHooks';
import { Loading } from '../../../components/Loading/Loading';
import { translations } from '../../../constants/translations';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { DoctorOfficePatientTabForContact } from '../DoctorOfficePatientTab/DoctorOfficePatientTab';

const offsetFromTop = '22.8em';
export const maxHeight = `calc(100vh - ${offsetFromTop})`;
export const borderTop = '1px solid #f0f0f0';

interface DoctorOfficeContactPatientTabProps {
  patientId: string;
  organizationId: string;
  contactCardRoute: string;
}

const DoctorOfficeContactPatientTab: React.FC<DoctorOfficeContactPatientTabProps> = ({
  patientId,
  organizationId,
  contactCardRoute,
}) => {
  const { organization, organizationLoading } = useGetOrganization(
    organizationId,
    AdditionalOrganizationField.PatientDemographics
  );
  const { patient, patientLoading, patientRefetch } = useGetPatient(patientId, organizationId, {
    fetchPolicy: 'cache-and-network',
  });

  if (!organization && organizationLoading) {
    return <Loading height={maxHeight} />;
  }
  if (!organization) {
    return <p>{translations.doctorOfficeContactPatientTab.failedToLoadOrganization}</p>;
  }
  if (!patient && patientLoading) {
    return <Loading height={maxHeight} />;
  }

  if (!patient) {
    return <p>{translations.doctorOfficeContactPatientTab.failedToLoadPatient}</p>;
  }

  return (
    <DoctorOfficePatientTabForContact
      contactCardRoute={contactCardRoute}
      patient={patient}
      patientLoading={!!patientLoading}
      patientRefetch={patientRefetch}
    />
  );
};

export default DoctorOfficeContactPatientTab;
