export type ContactLedgerAction =
  | SetPaymentModalVisible
  | SetCreditModalVisible
  | SetAccountChargeModalVisible
  | SetNoteEditModalVisible
  | SetReversePaymentModalVisible
  | SetShouldEnableAssignAllBtn
  | SetShouldRefetchLedger
  | SetStripeDetailsModalVisible;

export enum ContactLedgerActionType {
  SetPaymentModalVisible = 'SET_PAYMENT_MODAL_VISIBLE',
  SetCreditModalVisible = 'SET_CREDIT_MODAL_VISIBLE',
  SetAccountChargeModalVisible = 'SET_ACCOUNT_CHARGE_MODAL_VISIBLE',
  SetNoteEditModalVisible = 'SET_NOTE_EDIT_MODAL_VISIBLE',
  SetReversePaymentModalVisible = 'SET_REVERSE_PAYMENT_MODAL_VISIBLE',
  SetShouldEnableAssignAllBtn = 'SET_SHOULD_DISPLAY_ASSIGN_ALL_BTN',
  SetShouldRefetchLedger = 'SET_SHOULD_REFETCH_LEDGER',
  SetStripeDetailsModalVisible = 'SET_STRIPE_DETAILS_MODAL_VISIBLE',
}

interface SetPaymentModalVisible {
  type: ContactLedgerActionType.SetPaymentModalVisible;
  paymentModalVisible: boolean;
}

interface SetCreditModalVisible {
  type: ContactLedgerActionType.SetCreditModalVisible;
  creditModalVisible: boolean;
}

interface SetAccountChargeModalVisible {
  type: ContactLedgerActionType.SetAccountChargeModalVisible;
  accountChargeModalVisible: boolean;
}

interface SetNoteEditModalVisible {
  type: ContactLedgerActionType.SetNoteEditModalVisible;
  noteEditModalVisible: boolean;
}

interface SetReversePaymentModalVisible {
  type: ContactLedgerActionType.SetReversePaymentModalVisible;
  reversePaymentModalVisible: boolean;
}

interface SetShouldEnableAssignAllBtn {
  type: ContactLedgerActionType.SetShouldEnableAssignAllBtn;
  isAssignAllEnabled: boolean;
}

interface SetShouldRefetchLedger {
  type: ContactLedgerActionType.SetShouldRefetchLedger;
  shouldRefetchLedger: boolean;
}

interface SetStripeDetailsModalVisible {
  type: ContactLedgerActionType.SetStripeDetailsModalVisible;
  stripeDetailsModalVisible: boolean;
}

export const setPaymentModalVisible = (paymentModalVisible: boolean): SetPaymentModalVisible => ({
  type: ContactLedgerActionType.SetPaymentModalVisible,
  paymentModalVisible,
});

export const setCreditModalVisible = (creditModalVisible: boolean): SetCreditModalVisible => ({
  type: ContactLedgerActionType.SetCreditModalVisible,
  creditModalVisible,
});

export const setAccountChargeModalVisible = (accountChargeModalVisible: boolean): SetAccountChargeModalVisible => ({
  type: ContactLedgerActionType.SetAccountChargeModalVisible,
  accountChargeModalVisible,
});

export const setNoteEditModalVisible = (noteEditModalVisible: boolean): SetNoteEditModalVisible => ({
  type: ContactLedgerActionType.SetNoteEditModalVisible,
  noteEditModalVisible,
});

export const setReversePaymentModalVisible = (reversePaymentModalVisible: boolean): SetReversePaymentModalVisible => ({
  type: ContactLedgerActionType.SetReversePaymentModalVisible,
  reversePaymentModalVisible,
});

export const setShouldEnableAssignAll = (isAssignAllEnabled: boolean): SetShouldEnableAssignAllBtn => ({
  type: ContactLedgerActionType.SetShouldEnableAssignAllBtn,
  isAssignAllEnabled,
});

export const setShouldRefetchLedger = (shouldRefetchLedger: boolean): SetShouldRefetchLedger => ({
  type: ContactLedgerActionType.SetShouldRefetchLedger,
  shouldRefetchLedger,
});

export const setStripeDetailsModalVisible = (stripeDetailsModalVisible: boolean): SetStripeDetailsModalVisible => ({
  type: ContactLedgerActionType.SetStripeDetailsModalVisible,
  stripeDetailsModalVisible,
});
