import { useCallback } from 'react';
import { showErrorMessage, showSuccessMessage } from '../../../../components/Notification/notificationUtil';
import { ReminderUpsert, Reminder } from '../../../../graph/types';
import { useMutationWithMessages } from '../../../../hooks/ajax/generalMutationHooks';
import { useReminderOfflineMutation, useUpsertReminder } from '../../../../hooks/ajax/reminders/reminderHooks';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useOffline } from '../../../../util/offline/offlineUtil';

export const useSendReminderUpsertToBackend = ({ patientId }: { patientId?: string }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertReminder] = useMutationWithMessages(useUpsertReminder);
  const { enabledAndOffline } = useOffline();
  const offlineMutation = useReminderOfflineMutation({ patientId: patientId ?? '' });

  return useCallback(
    async (upsert: ReminderUpsert, successMessage?: string) => {
      try {
        if (!enabledAndOffline) {
          const reminderResult = await upsertReminder({
            options: {
              variables: {
                organizationId,
                reminder: upsert,
              },
            },
            successMessage,
          });
          return reminderResult.data?.upsertReminder as Reminder;
        }

        await offlineMutation?.(upsert);
        successMessage && showSuccessMessage(successMessage);
      } catch (err) {
        showErrorMessage((err as any).message);
      }
      return null;
    },
    [enabledAndOffline, offlineMutation, organizationId, upsertReminder]
  );
};
