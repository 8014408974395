import { AddressType } from '../../classes/upsertGenerators/AddressUpsertGenerator';
import { countryProvStateTranslations, countryTranslations } from '../../constants/countryTranslations';
import { displayAsDate } from '../../constants/formats';
import { translations } from '../../constants/translations';
import { UserOrganizationContactCard, UserOrganizationPatientCard } from '../../graph/types';
import { getRelatedContactFromArrays, getTag } from '../../util/tags';

export const getPatientDescription = (patientCard: UserOrganizationPatientCard, dateFormat: string) => {
  const descriptionElements = [
    patientCard.patient_species_name,
    patientCard.patient_gender_name,
    patientCard.patient_breed_name,
    patientCard.patient_color_name,
    patientCard.patient_dob ? displayAsDate(patientCard.patient_dob, dateFormat) : '',
  ];
  return descriptionElements.filter((element) => element).join(' - ');
};

export const getContactDescription = ({ contact_address }: UserOrganizationContactCard) => {
  const address = contact_address?.find(({ address_type_id }) => address_type_id === AddressType.Physical);
  const country =
    address?.country_name_key && countryTranslations[address.country_name_key as keyof typeof countryTranslations];
  const provState =
    address?.country_prov_state_name_key &&
    countryProvStateTranslations[address.country_prov_state_name_key as keyof typeof countryProvStateTranslations];
  return {
    address: address?.address_1 || translations.doctorOffice.noAddress,
    city: address?.city || translations.doctorOffice.noCity,
    country: country || translations.doctorOffice.noCountry,
    provState: provState ? `${provState},` : '',
    postal_zip: address?.postal_zip || translations.doctorOffice.noZipCode,
  };
};

export const getRelationshipTags = (patientCard: UserOrganizationPatientCard, tagWidthInCard = 200) => {
  const relatedNames = patientCard.patient_related_names ?? [];
  const relatedTypeNameKeys = patientCard.patient_related_type_name_keys ?? [];
  const patientContactIds = patientCard.patient_related_ids ?? [];
  const relatedContacts = getRelatedContactFromArrays(relatedNames, relatedTypeNameKeys);

  const relatedContactsTags = relatedContacts.map(({ contactTypeColor, contactTypeLabel, contactName }, index) =>
    getTag(
      contactTypeColor,
      `${contactTypeLabel}: ${contactName}`,
      contactTypeLabel,
      tagWidthInCard,
      patientContactIds[index]
    )
  );
  const relatedContactsTooltip = relatedContacts
    .map(({ contactTypeLabel, contactName }) => `${contactTypeLabel}: ${contactName}`)
    .join(', ');
  return { relatedContactsTags, relatedContactsTooltip };
};
