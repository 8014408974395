import React, { createRef, PropsWithChildren, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnFilterItem,
  ColumnTitle,
  ColumnType,
  CompareFn,
  ExpandableConfig,
  FilterDropdownProps,
  GetRowKey,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { Button, Checkbox, ConfigProvider, InputRef, Table, TooltipProps } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';
import { range } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { checkValidityOfColumn, weaveInBrCallback } from './tableWithCustomFilteringUtil';
import { PanelRender } from 'rc-table/lib/interface';
import { translations } from '../../constants/translations';
import { TableProps } from 'antd/lib/table';
import { defaultPageSize, TableKey, usePageSize } from '../../hooks/tableHooks';
import ColumnFreeTextFilter from './ColumnFreeTextFilter';
import { FilterValue } from 'antd/es/table/interface';
import { Breakpoint } from 'antd/es/_util/responsiveObserver';

// Styles
import './TableWithCustomFiltering.less';
export interface TableWithCustomFilteringType<T> extends PropsWithChildren<unknown> {
  tableKey: TableKey;
  dataSource: T[] | undefined;
  columns: CustomColumnType<T>[];
  rowKey?: string | GetRowKey<T>;
  rowSelection?: TableProps<T>['rowSelection'];
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => void;
  footer?: PanelRender<T>;
  pagination?: boolean;
  onRowClick?: (record: T) => void;
  setCurrentDataSource?: (data: T[]) => void;
  expandable?: ExpandableConfig<T>;
}

export type CustomColumnType<T> =
  | ColumnTypeWithFreeTextFiltering<T>
  | ColumnTypeWithFixedOptionsFiltering<T>
  | BasicColumnType<T>;

export interface ColumnTypeWithFreeTextFiltering<T> extends BasicColumnType<T> {
  filterInputPlaceholder: string;
  width: number | string;
  isForNumber?: boolean;
}

export interface ColumnTypeWithFixedOptionsFiltering<T> extends BasicColumnType<T> {
  filters?: ColumnFilterItem[];
  width?: number | string;
}

export interface BasicColumnType<T> extends ColumnType<T> {
  title?: ColumnTitle<T>;
  sorter?:
    | boolean
    | CompareFn<T>
    | {
        compare?: CompareFn<T>;
        multiple?: number;
      };
  sortOrder?: SortOrder;
  defaultSortOrder?: SortOrder;
  sortDirections?: SortOrder[];
  showSorterTooltip?: TooltipProps | boolean;
  filtered?: boolean;
  filterMultiple?: boolean;
  filteredValue?: FilterValue | null;
  defaultFilteredValue?: FilterValue | null;
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
  onFilter?: (value: string | number | boolean, record: T) => boolean;
  responsive?: Breakpoint[];
  hidden?: boolean;
}

export function TableWithCustomFiltering<T extends Record<string, unknown>>({
  tableKey,
  columns,
  dataSource,
  rowKey,
  rowSelection,
  style,
  loading,
  className,
  onChange,
  footer,
  pagination,
  onRowClick,
  setCurrentDataSource,
  expandable,
}: TableWithCustomFilteringType<T>): JSX.Element {
  const [filterInputRefs, setFilterInputRefs] = useState<React.RefObject<InputRef>[]>([]);
  const [filterDropdownRefs, setFilterDropdownRefs] = useState<RefObject<HTMLDivElement>[]>([]);
  const [filterSettingsVisibilities, setFilterSettingsVisibilities] = useState<boolean[]>([]);
  const { pageSize, setPageSize } = usePageSize(tableKey);

  const displayedColumns = useMemo(() => columns.filter((column) => !column.hidden), [columns]);

  const isFilterVisible = filterSettingsVisibilities.includes(true);

  const componentIsMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setCurrentDataSource?.(dataSource ?? []);
  }, [dataSource, setCurrentDataSource]);

  const numberOfColumnsWithFiltering = displayedColumns.filter(
    (column) =>
      (column as ColumnTypeWithFreeTextFiltering<T>).filterInputPlaceholder ||
      (column as ColumnTypeWithFixedOptionsFiltering<T>).filters
  ).length;
  const numberOfColumnsWithFreeTextFiltering = displayedColumns.filter(
    (column) => (column as ColumnTypeWithFreeTextFiltering<T>).filterInputPlaceholder
  ).length;

  useEffect(() => {
    const filterIndices = range(numberOfColumnsWithFiltering);
    setFilterDropdownRefs((filterDropdownRefs: RefObject<HTMLDivElement>[]) =>
      filterIndices.map((_, i) => filterDropdownRefs[i] || createRef())
    );
    setFilterSettingsVisibilities((filterSettingsVisibilities: boolean[]) =>
      filterIndices.map((_, i) => filterSettingsVisibilities[i] ?? false)
    );
  }, [numberOfColumnsWithFiltering]);

  useEffect(() => {
    const freeTextFilterIndices = range(numberOfColumnsWithFreeTextFiltering);
    setFilterInputRefs((filterInputRefs: React.RefObject<InputRef>[]) =>
      freeTextFilterIndices.map((_, i) => filterInputRefs[i] || createRef())
    );
  }, [numberOfColumnsWithFreeTextFiltering]);

  const filterDropdowns = Array.from(document.querySelectorAll<HTMLElement>('.ant-table-filter-dropdown'));
  const dropdownHeight = filterDropdowns?.reduce(
    (tallest, el) => (el.offsetHeight > tallest ? el.offsetHeight : tallest),
    0
  );

  useEffect(() => {
    if (isFilterVisible) {
      const table = document.querySelector<HTMLElement>('.ant-table-content');
      if (table && dropdownHeight) {
        table.style.minHeight = `${dropdownHeight + 100}px`;
      }
    }
  }, [isFilterVisible, dropdownHeight]);

  const handleClick = (event: MouseEvent) => {
    const indicesOfFiltersToHide: number[] = [];
    for (let i = 0; i < filterDropdownRefs.length; i++) {
      if (
        filterDropdownRefs[i] &&
        filterSettingsVisibilities[i] &&
        !filterDropdownRefs[i].current?.contains(event.target as Node)
      ) {
        indicesOfFiltersToHide.push(i);
      }
    }
    setTimeout(() => {
      if (componentIsMountedRef.current) {
        setFilterSettingsVisibilities((filterSettingsVisibilities: boolean[]) =>
          filterSettingsVisibilities.map((value, index) => (indicesOfFiltersToHide.includes(index) ? false : value))
        );
      }
    }, 50);
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setFilterSettingsVisibilities(Array(filterSettingsVisibilities.length).fill(false));
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    document.addEventListener('keydown', handleKeydown, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('keydown', handleKeydown, true);
    };
  });

  const setFilterSettingsVisibilityFor = (indexToSet: number) => (visible: boolean) => {
    setFilterSettingsVisibilities((filterSettingsVisibilities: boolean[]) =>
      filterSettingsVisibilities.map((value, index) => (indexToSet === index ? visible : value))
    );
  };

  function getColumnFreeTextFilterProps<T>(
    freeTextFilterIndex: number,
    filterIndex: number,
    filterInputPlaceholder: string,
    isForNumber = false
  ): ColumnType<T> {
    return {
      filterDropdown: (filterDropdownProps: FilterDropdownProps) => (
        <ColumnFreeTextFilter
          containerRef={filterDropdownRefs[filterIndex]}
          name={`freeTextFilterInput${freeTextFilterIndex}`}
          buttonName={`filterReset${filterIndex}`}
          inputProps={{
            ref: filterInputRefs[freeTextFilterIndex],
            placeholder: filterInputPlaceholder,
            onPressEnter: () => setFilterSettingsVisibilityFor(freeTextFilterIndex)(false),
          }}
          filterDropdownProps={filterDropdownProps}
          isForNumber={isForNumber}
        />
      ),
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setFilterSettingsVisibilityFor(filterIndex)(true);
          setTimeout(() => filterInputRefs[freeTextFilterIndex], 100);
        }
      },
      filterDropdownOpen: filterSettingsVisibilities[filterIndex],
    };
  }

  function getColumnFixedOptionsFilterProps<T>(filterIndex: number): ColumnType<T> {
    return {
      filterDropdown: ({
        clearFilters,
        confirm,
        filters,
        selectedKeys,
        setSelectedKeys,
        visible,
      }: FilterDropdownProps) => {
        if (!visible) {
          return null;
        }

        const getCheckboxFromFilter = (filter: ColumnFilterItem, index: number) => {
          const filterValue = filter.value as React.Key;
          const filterText = filter.text;

          const addFilter = () => {
            if (!selectedKeys.includes(filterValue)) {
              setSelectedKeys([...selectedKeys, filterValue]);
              confirm();
            }
          };
          const removeFilter = () => {
            const newSelectedKeys = [...selectedKeys];
            const keyIndex = newSelectedKeys.indexOf(filterValue);
            if (keyIndex !== -1) {
              newSelectedKeys.splice(keyIndex, 1);
              setSelectedKeys(newSelectedKeys);
              confirm();
            }
          };

          const onCheckboxChange = ({ target: { checked: checkboxChecked } }: CheckboxChangeEvent) => {
            if (checkboxChecked) {
              addFilter();
            } else {
              removeFilter();
            }
          };

          return (
            <Checkbox
              key={`filter${filterValue}Checkbox${index}`}
              checked={selectedKeys.includes(filterValue)}
              onChange={onCheckboxChange}
            >
              {filterText}
            </Checkbox>
          );
        };

        return (
          <div ref={filterDropdownRefs[filterIndex]} style={{ padding: '7px' }}>
            {filters?.map(getCheckboxFromFilter).reduce(weaveInBrCallback(), [])}
            <br />
            <Button
              onClick={() => {
                clearFilters?.();
                confirm();
              }}
              style={{ width: '100%', marginTop: '6px' }}
              size={'small'}
              name={`filterReset${filterIndex}`}
            >
              {translations.shared.resetButtonText}
            </Button>
          </div>
        );
      },
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setFilterSettingsVisibilityFor(filterIndex)(true);
        }
      },
      filterDropdownOpen: filterSettingsVisibilities[filterIndex],
    };
  }

  const getModifiedColumnsWithCustomFilters = () => {
    const modifiedColumnsWithCustomFilters = cloneDeep(displayedColumns);
    let filterCounter = 0;
    let freeTextFilterCounter = 0;
    let previousColumnsAllHaveFixedWidth = true;
    for (let i = 0; i < modifiedColumnsWithCustomFilters.length; i++) {
      const currentColumn = modifiedColumnsWithCustomFilters[i];
      checkValidityOfColumn(currentColumn, previousColumnsAllHaveFixedWidth);
      if ((currentColumn as ColumnTypeWithFreeTextFiltering<T>).filterInputPlaceholder) {
        const filterInputPlaceholder =
          (currentColumn as ColumnTypeWithFreeTextFiltering<T>).filterInputPlaceholder || '';
        const filterInputIsForNumber = (currentColumn as ColumnTypeWithFreeTextFiltering<T>).isForNumber;
        modifiedColumnsWithCustomFilters[i] = {
          ...currentColumn,
          ...getColumnFreeTextFilterProps(
            freeTextFilterCounter,
            filterCounter,
            filterInputPlaceholder,
            filterInputIsForNumber
          ),
        };
        filterCounter++;
        freeTextFilterCounter++;
      } else if ((currentColumn as ColumnTypeWithFixedOptionsFiltering<T>).filters) {
        modifiedColumnsWithCustomFilters[i] = {
          ...currentColumn,
          ...getColumnFixedOptionsFilterProps(filterCounter),
        };
        filterCounter++;
      }
      if (!currentColumn.width) {
        previousColumnsAllHaveFixedWidth = false;
      }
    }
    return modifiedColumnsWithCustomFilters;
  };

  return (
    <ConfigProvider getPopupContainer={(trigger) => trigger?.parentElement ?? document.body}>
      <Table<T>
        expandable={expandable}
        className={`${className} ${isFilterVisible ? 'spaced-table' : ''}`}
        dataSource={dataSource}
        columns={getModifiedColumnsWithCustomFilters()}
        rowKey={rowKey}
        rowSelection={rowSelection}
        style={style}
        loading={loading}
        scroll={{ x: true }}
        onChange={(pagination, filters, sorter, extra) => {
          onChange?.(pagination, filters, sorter, extra);
          setCurrentDataSource?.(extra.currentDataSource);
        }}
        pagination={
          pagination === false
            ? false
            : {
                style: { marginRight: '10px' },
                pageSize,
                onChange: (_, value) => setPageSize(value ?? defaultPageSize),
              }
        }
        footer={footer}
        onRow={(record) => ({
          onClick: () => onRowClick?.(record),
        })}
      />
    </ConfigProvider>
  );
}
