import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getTranslatedSubscriptionErrorMessage, translations } from '../../../constants/translations';
import {
  getSubTotal,
  SubscriptionUpdateModal,
} from '../../../components/SubscriptionUpdateModal/SubscriptionUpdateModal';
import { useRetrySubscription, useUpdateSubscription } from '../../../hooks/ajax/subscription/subscriptionHooks';
import { SubscriptionUpsertGenerator } from '../../../classes/upsertGenerators/SubscriptionUpsertGenerator';
import { displayAsDate } from '../../../constants/formats';
import { Spin } from 'antd';
import { OrganizationDto } from '../../../graph/types';
import { getCurrencyFromSubscriptionBillingAddress } from '../subscriptionSettingsUtil';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { CurrencyEntryTotal } from '../SubscriptionSettings.styles';
import { ErrorText } from '../../../globalStyles.style';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';

export const subscriptionCurrentBillingCycleTest = {
  taxTotal: 'subscriptionCurrentBillingCycleTest_taxTotal',
};

export interface SubscriptionCurrentBillingCycleEstimateCardProps {
  organization: OrganizationDto;
}

const SubscriptionCurrentBillingCycleEstimateCard: React.FC<SubscriptionCurrentBillingCycleEstimateCardProps> = ({
  organization,
}) => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [retryPerforming, setRetryPerforming] = useState(false);
  const [fetchSubscription, { data: subscriptionUpsertResult, loading: subscriptionUpsertLoading }] =
    useUpdateSubscription(organization.id);
  const [retrySubscription] = useRetrySubscription();
  const loading = subscriptionUpsertLoading || retryPerforming;
  const subscription = subscriptionUpsertResult?.updateOrganizationSubscription;
  const nextBillingDate = subscription?.organization?.subscription?.next_bill_date;
  const subscriptionId = subscription?.organization?.subscription?.id;
  const canRetry = !!subscription?.organization?.subscription?.failed;
  const error = getTranslatedSubscriptionErrorMessage(subscription?.error_name_key);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const refetchSubscription = useCallback(() => {
    if (organization.id) {
      fetchSubscription({
        variables: {
          organizationId: organization.id,
          subscription: SubscriptionUpsertGenerator.generatePriceFetchUpsert(),
        },
      });
    }
  }, [organization.id, fetchSubscription]);

  useEffect(() => {
    refetchSubscription();
  }, [refetchSubscription]);

  const onEditMaxUsersClick = () => {
    setShowSubscriptionModal(true);
  };

  const handleSubscriptionUpdate = () => {
    refetchSubscription();
    setShowSubscriptionModal(false);
  };

  const onRetrySubscriptionClick = async () => {
    if (organization.id && subscriptionId) {
      setRetryPerforming(true);
      const retrySubscriptionResult = await retrySubscription({
        variables: {
          organizationId: organization.id,
          request: {
            id: subscriptionId,
          },
        },
      });
      if (retrySubscriptionResult?.data?.retrySubscription?.success) {
        showSuccessMessage(translations.subscriptionSettings.currentBillingCycleEstimate.retryResponseMessages.success);
      } else {
        showErrorMessage(translations.subscriptionSettings.currentBillingCycleEstimate.retryResponseMessages.error);
      }
      await refetchSubscription();
      setRetryPerforming(false);
    }
  };

  const countryId = useMemo(() => organization?.subscription?.billing_address?.country_id, [organization]);

  return (
    <SettingsCard
      title={translations.subscriptionSettings.currentBillingCycleEstimate.title}
      leftButton={{
        label: translations.subscriptionSettings.currentBillingCycleEstimate.buttons.editMaxUsers,
        onClick: onEditMaxUsersClick,
      }}
      rightButton={
        canRetry
          ? {
              label: translations.subscriptionSettings.currentBillingCycleEstimate.buttons.retrySubscription,
              onClick: onRetrySubscriptionClick,
            }
          : undefined
      }
    >
      <Spin spinning={loading}>
        {(subscription?.monthlyTax?.length ?? 0) > 0 && (
          <div>
            {translations.subscriptionSettings.currentBillingCycleEstimate.subTotal}:{' '}
            <SubscriptionCurrencyFormatter
              total={Number(getSubTotal(subscription?.monthlyTax, subscription?.monthlyTotal))}
              countryId={countryId}
            />
          </div>
        )}
        {subscription?.monthlyTax?.map((tax) => (
          <div data-testid={subscriptionCurrentBillingCycleTest.taxTotal} key={tax.name_key}>
            {translations.subscriptionSettings.currentBillingCycleEstimate.taxTotal} {tax.name_key}:{' '}
            <SubscriptionCurrencyFormatter total={Number(tax.amount)} countryId={countryId} />
          </div>
        ))}
        <CurrencyEntryTotal>
          {getCurrencyFromSubscriptionBillingAddress(organization?.subscription?.billing_address?.country_id)}{' '}
          <SubscriptionCurrencyFormatter total={Number(subscription?.monthlyTotal ?? 0)} countryId={countryId} />
        </CurrencyEntryTotal>
        <div>
          {translations.subscriptionSettings.currentBillingCycleEstimate.content.nextCharge}:{' '}
          {nextBillingDate && displayAsDate(nextBillingDate, dateFormat)}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </Spin>
      {showSubscriptionModal && (
        <SubscriptionUpdateModal
          onClose={() => setShowSubscriptionModal(false)}
          onSuccess={handleSubscriptionUpdate}
          canReduceUserCount
        />
      )}
    </SettingsCard>
  );
};

export default SubscriptionCurrentBillingCycleEstimateCard;
