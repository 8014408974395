import React, { useMemo } from 'react';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';

const defaultColor = 'black';

interface PriceValueProps {
  component?: 'span' | 'h2';
  value: number;
  colorNegative?: string;
  colorPositive?: string;
  colorZero?: string;
}

export const PriceValue: React.FC<PriceValueProps> = ({
  value,
  colorNegative = defaultColor,
  colorPositive = defaultColor,
  colorZero = defaultColor,
  component = 'span',
}) => {
  const textColor = useMemo(() => {
    if (value > 0) {
      return colorPositive;
    } else if (value < 0) {
      return colorNegative;
    }
    return colorZero;
  }, [colorNegative, colorPositive, colorZero, value]);

  return (
    <PriceComponent component={component} style={{ color: textColor, margin: 'auto' }}>
      <CurrencyFormatter total={value} isPriceValue />
    </PriceComponent>
  );
};

interface PriceCompProps extends React.DetailedHTMLProps<React.HTMLAttributes<any>, any> {
  component: 'span' | 'h2';
}

const PriceComponent: React.FC<PriceCompProps> = ({ component, children, ...otherProps }) => {
  return component === 'span' ? <span {...otherProps}>{children}</span> : <h2 {...otherProps}>{children}</h2>;
};
