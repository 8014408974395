import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import ModalWithCloseConfirm from '../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../constants/translations';
import { Setting } from '../../graph/types';
import { useFormChanges } from '../../hooks/useFormChanges';
import { FormProps } from 'antd/es/form';
import {
  InterestRunConfigFormFields,
  InterestSettingsFields,
  InterestSettingsForm,
  InterestSettingValue,
} from '../../components/InterestSettingsForm/InterestSettingsForm';
import {
  getChargeFreePeriodValue,
  getInitialValues,
} from '../../components/InterestSettingsForm/InterestSettingsFormUtils';
import { flushSync } from 'react-dom';

const offset = 10;

export type InterestRunConfigModalProps = {
  setModalOpen: (state: boolean) => void;
  isModalOpen: boolean;
  interestSettings: Setting;
  isCreating: boolean;
  afterConfigFunction: (values: InterestSettingValue) => void;
};

export const InterestRunConfigModal: React.FC<InterestRunConfigModalProps> = ({
  setModalOpen,
  isModalOpen,
  interestSettings,
  isCreating,
  afterConfigFunction,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const initialValues = getInitialValues(interestSettings);

  const { hasDataChanged, setHasDataChanged, handleOnReset, handleOnValuesChange } = useFormChanges(
    form,
    initialValues
  );

  useEffect(() => {
    if (isModalOpen) {
      handleOnReset();
    }
  }, [handleOnReset, isModalOpen]);

  const closeModal = () => {
    setIsSaving(false);
    setHasDataChanged(false);
    setModalOpen(false);
  };

  const handleFinish: FormProps<InterestRunConfigFormFields>['onFinish'] = (value) => {
    const interestSettingValue: InterestSettingValue = {
      interestRate: value[InterestSettingsFields.interestRate],
      isCompounded: !!value[InterestSettingsFields.isCompounded],
      chargeFreePeriod: getChargeFreePeriodValue(value, isCreating),
    };

    flushSync(() => {
      setHasDataChanged(false);
    });
    afterConfigFunction(interestSettingValue);
    return closeModal();
  };

  return (
    <ModalWithCloseConfirm
      title={translations.interestRuns.modal.creatingTitle}
      onCancel={closeModal}
      isSaving={isSaving}
      onOk={form.submit}
      okText={translations.interestRuns.modal.create}
      changesMade={hasDataChanged}
      open={isModalOpen}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleFinish}
        labelCol={{ span: offset }}
        autoComplete='off'
      >
        <InterestSettingsForm isCreating={isCreating} offset={offset} />
      </Form>
    </ModalWithCloseConfirm>
  );
};
