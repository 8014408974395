import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  InputRef,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ID_FOR_OBJECT_CREATION } from '../../../classes/upsertGenerators/commonUpsertConstants';
import { DropdownButtonWithMenu } from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { translations } from '../../../constants/translations';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useUpdateOrganizationReferenceData } from '../../../hooks/ajax/organization/organizationHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { defaultPageSize, TableKey, usePageSize } from '../../../hooks/tableHooks';
import { getFormattedDecimalString } from '../../../util/displaying';
import {
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStringCompareFunctionFor,
} from '../../../util/filterAndSorting';
import { FilteredBy, NoteOrFileRefDataType, ReferenceDataType, tabKeys } from '../refDataUtils';
import { buildReferenceDataUpsert, isRefDataValid } from './refDataTableUpsertUtils';
import { getPlaceholderForSelect, getRefDataColumnHeadersByType, sortOrderHeader } from './referenceDataColumns';
import FilterDropdown from './FilterDropdown';
import { Container, Error, FixedWidthSelect } from './RefDataTable.styles';
import { noop } from 'lodash';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import { PaymentTypeReferenceDataType } from '../../../constants/referenceData/paymentReferenceData';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { QuickNoteModal } from '../../../components/QuickNoteModal/QuickNoteModal';
import { useQuickNoteModal } from '../../../components/QuickNoteModal/useQuickNoteModal';
import { FlexContainer } from '../../Services/ServiceForm/ServiceForm.style';
import { QuickNoteDto } from '../../../graph/types';
import { TextEditor } from '../../../components/TextEditor/TextEditor';
import { useHistory } from 'react-router-dom';
import { organizationIdParameter, referenceDataParameter, routes } from '../../../constants/routes';

type RefDataTableProps = {
  values: ReferenceDataType[];
  setIsSaving: (value: boolean) => void;
  type: string;
  filterOptions?: FilteredBy[];
  unchangeable?: boolean;
  isQuickBooksAligned?: boolean;
  onFilterChange?: (value: string | undefined) => void;
  currentNoteTypeId?: string;
};

enum EntryType {
  Input = 'input',
  IntegerInput = 'integerInput',
  FloatInput = 'floatInput',
  Select = 'select',
  ClearableSelect = 'clearableSelect',
  SearchableSelect = 'searchableSelect',
  Boolean = 'boolean',
  CannotDisableBoolean = 'cannotDisableBoolean',
  Quill = 'quill',
}

const refDataColumnTypes: Record<string, EntryType> = {
  name: EntryType.Input,
  [sortOrderHeader]: EntryType.IntegerInput,
  species_id: EntryType.Select,
  percentage: EntryType.FloatInput,
  code: EntryType.Input,
  gl_number: EntryType.Input,
  system_status_id: EntryType.Select,
  external_id: EntryType.SearchableSelect,
  external_tax_id: EntryType.ClearableSelect,
  external_gl_id: EntryType.SearchableSelect,
  general_ledger_id: EntryType.SearchableSelect,
  is_receivables_account: EntryType.CannotDisableBoolean,
  note: EntryType.Quill,
  note_type_id: EntryType.IntegerInput,
};

const isBooleanType = (entryType: EntryType) =>
  entryType === EntryType.Boolean || entryType === EntryType.CannotDisableBoolean;

const getHeaderTranslation = (header: string) =>
  translations.referenceData.columnNames[header as keyof typeof translations.referenceData.columnNames];

export const quickbooksAlignmentColumns = [
  'external_id',
  'external_tax_id',
  'external_gl_id',
  'is_receivables_account',
];
export const editableSelectTestId = 'editable-select';

export const RefDataTable: React.FC<RefDataTableProps> = ({
  values,
  setIsSaving,
  type,
  filterOptions,
  unchangeable = false,
  isQuickBooksAligned = false,
  onFilterChange,
  currentNoteTypeId,
}) => {
  const [editingEntry, setEditingEntry] = useState<ReferenceDataType>();
  const [deletableId, setDeletableId] = useState<string>();
  const [checkPatientOrContactError, setCheckPatientOrContactError] = useState<string>();
  const [editValidationErrors, setEditValidationErrors] = useState<string[]>([]);
  const [hasRowForNewEntry, setHasRowForNewEntry] = useState(false);
  const [showQuickNoteModal, setShowQuickNoteModal] = useState(false);
  const { pageSize, setPageSize } = usePageSize(TableKey.RefData);
  const inputEl = useRef<InputRef>(null);
  const isQuickBooksFlagOn = useLDFlag(LDFlagNames.QuickBooks);

  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertOrganization] = useMutationWithMessages(useUpdateOrganizationReferenceData);

  const columnData = getRefDataColumnHeadersByType(type);

  const onValueCellClick = (entry?: ReferenceDataType) => {
    if (!editingEntry && type !== tabKeys.quickNotes) {
      setEditingEntry(entry);
    }
  };

  const history = useHistory();

  const deleteReferenceEntry = async (dataEntry: ReferenceDataType) => {
    if (!isRefDataValid(dataEntry, values, type, true)) {
      return;
    }

    setIsSaving(true);
    const upsert = buildReferenceDataUpsert(organizationId, dataEntry, type, true, values);
    await upsertOrganization({
      options: {
        variables: { organizationId, organization: upsert },
      },
      successMessage: translations.shared.deleteSuccessMessage,
      shouldShowErrorMessage: false,
      onError: (error) => {
        if (error.message) {
          const message = error.message.replace(
            'CANT_VOID_GENERAL_LEDGER_WITH_ACTIVE_RELATIONS',
            translations.referenceData.errors.cannotDeleteLinkedGlAccount
          );
          showErrorMessage(message, 5);
        } else {
          showErrorMessage(error);
        }
      },
    });

    setIsSaving(false);
  };

  const upsertReferenceEntry = async () => {
    setEditValidationErrors([]);
    const validationErrors = columnData
      .filter(({ header, mandatory }) => mandatory && !editingEntry?.[header as keyof ReferenceDataType])
      .map(({ header }) => header);
    if (validationErrors.length > 0) {
      return setEditValidationErrors(validationErrors);
    }

    if (editingEntry && !isRefDataValid(editingEntry, values, type)) {
      return noop;
    }

    if (editingEntry && [tabKeys.fileTypes, tabKeys.noteTypes].includes(type)) {
      const { patient, contact } = editingEntry as NoteOrFileRefDataType;
      if (!patient && !contact) {
        return setCheckPatientOrContactError(translations.referenceData.errors.patientOrContactError);
      }
    }

    if (editingEntry) {
      setIsSaving(true);
      const upsert = buildReferenceDataUpsert(organizationId, editingEntry, type, false, values);

      try {
        await upsertOrganization({
          options: {
            variables: { organizationId, organization: upsert },
          },
        });
      } catch (e) {
        showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
      } finally {
        setEditingEntry(undefined);
        setHasRowForNewEntry(false);
        setIsSaving(false);
      }
    }
    return undefined;
  };

  const handleQuickNoteSave = async (name: string, note: string, sortOrder: number, id?: string) => {
    const data = {
      id,
      name,
      note,
      note_type_id: currentNoteTypeId,
      sort_order: sortOrder,
    } as ReferenceDataType;

    const upsert = buildReferenceDataUpsert(organizationId, data, type, false, values);

    try {
      await upsertOrganization({
        options: {
          variables: { organizationId, organization: upsert },
        },
      });
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
    } finally {
      setShowQuickNoteModal(false);
      setIsSaving(false);
      setEditingEntry(undefined);
    }
  };
  const handleQuickNoteModalCancel = () => {
    setShowQuickNoteModal(false);
    setEditingEntry(undefined);
  };

  const editField = (columnName: string, value: string | number | boolean) => {
    if (!editingEntry) {
      return;
    }

    setEditingEntry({ ...editingEntry, [columnName]: value });
  };

  const getFieldBasedOnColumn = (
    column: {
      columnName: string;
      addableType: EntryType;
    },
    defaultValue?: string | boolean | number,
    dataEntry?: ReferenceDataType
  ) => {
    const { columnName, addableType } = column;
    if (columnName === 'name' && unchangeable) {
      return <div>{defaultValue}</div>;
    }

    if (isBooleanType(addableType)) {
      const cannotDisable = addableType === EntryType.CannotDisableBoolean;
      const checkboxDisabled =
        (cannotDisable && (defaultValue as boolean)) ||
        (unchangeable && tabKeys.paymentTypes !== type && (defaultValue as boolean)) ||
        (dataEntry as PaymentTypeReferenceDataType).enabledUneditable;

      const checkbox = (
        <Checkbox
          defaultChecked={defaultValue as boolean}
          onChange={(event) => {
            if (checkPatientOrContactError) {
              setCheckPatientOrContactError(undefined);
            }
            editField(columnName, event.target.checked);
          }}
          disabled={checkboxDisabled}
        />
      );

      const checkboxContainer = (dataEntry as PaymentTypeReferenceDataType).enabledUneditable ? (
        <Tooltip title={translations.referenceData.creditCardCannotEnableWarning}>{checkbox}</Tooltip>
      ) : (
        checkbox
      );

      return (
        <div className='boolean-field'>
          {checkboxContainer}

          {columnName === 'patient' && checkPatientOrContactError && <Error>{checkPatientOrContactError}</Error>}
        </div>
      );
    }

    if (
      addableType === EntryType.Select ||
      addableType === EntryType.ClearableSelect ||
      addableType === EntryType.SearchableSelect
    ) {
      const isSelectDisabled = quickbooksAlignmentColumns.includes(columnName) && !isQuickBooksAligned;
      return renderSelectMenu(
        filterOptions?.find((f) => f.key === columnName)?.values || [],
        (value) => editField(columnName, value),
        defaultValue?.toString(),
        addableType === EntryType.ClearableSelect || addableType === EntryType.SearchableSelect,
        addableType === EntryType.SearchableSelect,
        isSelectDisabled,
        getPlaceholderForSelect(columnName)
      );
    }

    if (column.addableType === EntryType.IntegerInput || column.addableType === EntryType.FloatInput) {
      const numberFormatter = (value?: number | string) =>
        column.addableType === EntryType.FloatInput ? getFormattedDecimalString(value + '') : Number(value);
      const valueFromEditingEntry = editingEntry?.[columnName as keyof ReferenceDataType];

      return (
        <InputNumber
          defaultValue={defaultValue !== undefined ? Number(defaultValue) : undefined}
          value={valueFromEditingEntry ? Number(valueFromEditingEntry) : undefined}
          onChange={(value) => editField(columnName, numberFormatter(value ?? 0))}
          placeholder={getHeaderTranslation(columnName)}
          min={0}
        />
      );
    }

    return (
      <Input
        placeholder={getHeaderTranslation(columnName)}
        defaultValue={defaultValue?.toString()}
        onChange={({ target: { value } }) => editField(columnName, value)}
        maxLength={columnName === 'code' ? 10 : 50}
      />
    );
  };

  const renderEditableCell = (
    value: string | number | boolean,
    data: ReferenceDataType,
    columnName: string,
    entryType: EntryType
  ) => {
    const dropDownColumns = [
      'species_id',
      'system_status_id',
      'external_id',
      'general_ledger_id',
      'external_tax_id',
      'external_gl_id',
    ];

    let displayValue = '';

    const entry = type === tabKeys.quickNotes ? undefined : editingEntry;

    if (entry?.id === data.id && (columnName !== 'species_id' || isNewEntry(data))) {
      return (
        <>
          {getFieldBasedOnColumn({ columnName, addableType: entryType }, value, entry)}
          {editValidationErrors.includes(columnName) && (
            <Error>
              {translations.referenceData.errors.requiredFieldMissing(
                translations.referenceData.columnNames[
                  columnName as keyof typeof translations.referenceData.columnNames
                ]
              )}
            </Error>
          )}
        </>
      );
    }

    if (isBooleanType(entryType)) {
      return value && <CheckOutlined style={{ color: '#52c41a', fontSize: '16px' }} data-testid={'checkbox-icon'} />;
    } else if (entryType === EntryType.FloatInput) {
      displayValue = getFormattedDecimalString(value?.toString());
    } else if (entryType === EntryType.Quill) {
      const textContent = value as string;
      return (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: textContent }}></div>}>
          <div>
            <TextEditor
              editMode={false}
              readOnly
              textContent={textContent.substring(0, 100) + (textContent.length > 100 ? '...' : '')}
              setTextContent={() => {
                return null;
              }}
            />
          </div>
        </Tooltip>
      );
    } else {
      for (const col of dropDownColumns) {
        if (
          col in data &&
          columnName === col &&
          filterOptions?.some((f) => f.key === (col as keyof ReferenceDataType))
        ) {
          displayValue =
            filterOptions
              .find((f) => f.key === (col as keyof ReferenceDataType))
              ?.values?.find(({ value }) => value === data[col as keyof ReferenceDataType])?.label ?? '';
          break;
        }
      }

      if (!displayValue) {
        displayValue = value?.toString();
      }
    }

    return <div onClick={() => onValueCellClick(data)}>{displayValue}</div>;
  };

  function renderActionCell(_: any, dataEntry: ReferenceDataType) {
    if (editingEntry?.id === dataEntry.id && type !== tabKeys.quickNotes) {
      return (
        <Space direction={'horizontal'}>
          <Button type='link' onClick={upsertReferenceEntry}>
            {isNewEntry(dataEntry) ? translations.shared.addButtonText : translations.shared.saveButtonText}
          </Button>
          <Button
            type='link'
            onClick={() => {
              setCheckPatientOrContactError(undefined);
              setEditValidationErrors([]);
              setEditingEntry(undefined);
              setHasRowForNewEntry(false);
            }}
          >
            {translations.shared.cancelButtonText}
          </Button>
        </Space>
      );
    }

    const onEditClick = () => {
      setEditingEntry(dataEntry);
      if (tabKeys.quickNotes === type) {
        setShowQuickNoteModal(true);
      }
    };

    const editButtonMenu = {
      title: translations.shared.editButtonText,
      onClick: onEditClick,
      disabled: !!editingEntry || (dataEntry as PaymentTypeReferenceDataType).uneditable,
    };

    const deleteButtonMenu = {
      title: translations.shared.deleteButtonText,
      onClick: () => setDeletableId(dataEntry.id),
      disabled: !!editingEntry,
    };

    const actionProps = [editButtonMenu, ...(!unchangeable ? [deleteButtonMenu] : [])];

    return (
      <Popconfirm
        open={dataEntry.id === deletableId}
        onOpenChange={() => setDeletableId(undefined)}
        placement={'topLeft'}
        title={translations.referenceData.deleteReferenceEntryText}
        okText={translations.shared.popconfirm.ok}
        cancelText={translations.shared.popconfirm.cancel}
        onConfirm={() => deleteReferenceEntry(dataEntry)}
      >
        <DropdownButtonWithMenu
          menuItemProps={actionProps}
          simpleItemWrapperText={
            (dataEntry as PaymentTypeReferenceDataType).uneditable
              ? translations.referenceData.creditCardCannotEditWarning
              : undefined
          }
        />
      </Popconfirm>
    );
  }

  const getColumns = () => {
    const editableColumns = columnData
      .filter(({ header }) => {
        return !isQuickBooksFlagOn ? !quickbooksAlignmentColumns.includes(header) : true;
      })
      .map(({ header }) => {
        const entryType = refDataColumnTypes[header] ?? EntryType.Boolean;
        const title =
          header === sortOrderHeader ? (
            <div title={translations.referenceData.sortOrderTooltip}>{getHeaderTranslation(header)}</div>
          ) : (
            getHeaderTranslation(header)
          );

        let sorter;
        let onFilter;
        let filters;
        let filterInputPlaceholder;

        switch (entryType) {
          case EntryType.CannotDisableBoolean:
          case EntryType.Boolean:
            sorter = getNumberCompareFunctionFor(header);
            onFilter = (value: boolean, record: any) => value === record[header];
            filters = [
              { text: translations.referenceData.filters.checked, value: true },
              { text: translations.referenceData.filters.unchecked, value: false },
            ];
            break;
          case EntryType.FloatInput:
          case EntryType.IntegerInput:
            sorter = getNumberCompareFunctionFor(header);
            onFilter = getOnFilterFunctionFor(header, true);
            filterInputPlaceholder = translations.shared.getFilterInputPlaceholder(getHeaderTranslation(header));
            break;
          case EntryType.Select:
          case EntryType.ClearableSelect:
          case EntryType.SearchableSelect:
            sorter = getStringCompareFunctionFor(header);
            onFilter = (value: boolean, record: any) => value === record[header];
            filters = filterOptions
              ?.find((f) => f.key === header)
              ?.values.map(({ value, label }) => ({ text: label, value }));
            break;
          case EntryType.Quill:
          case EntryType.Input:
            sorter = getStringCompareFunctionFor(header);
            onFilter = getOnFilterFunctionFor(header);
            filterInputPlaceholder = translations.shared.getFilterInputPlaceholder(getHeaderTranslation(header));
            break;
        }

        return {
          title,
          columnName: header,
          dataIndex: header,
          key: header,
          render: (value: string, data: ReferenceDataType) => renderEditableCell(value, data, header, entryType),
          addableType: entryType,
          align: isBooleanType(entryType) ? 'center' : ('left' as 'center' | 'left'),
          width: isBooleanType(entryType) ? 50 : 200,
          sorter,
          onFilter,
          ...(filters && { filters }),
          ...(filterInputPlaceholder && {
            filterDropdown: inputEl && FilterDropdown(inputEl, filterInputPlaceholder ?? ''),
          }),
        };
      });

    const actionsColumn = {
      title: translations.referenceData.columnNames['actions' as keyof typeof translations.referenceData.columnNames],
      dataIndex: 'actions',
      key: 'actions',
      render: renderActionCell,
      columnName: 'actions',
      width: 200,
    };

    return [...editableColumns, actionsColumn];
  };

  const columns = getColumns();

  const measureLargestDropdownItem = useCallback((options: { value: string; label: string }[]) => {
    const measureElement = document.createElement('span');
    measureElement.style.visibility = 'hidden';
    document.body.appendChild(measureElement);
    let maxWidth = 0;
    options.forEach((option) => {
      measureElement.textContent = option.label;
      maxWidth = Math.max(maxWidth, measureElement.offsetWidth);
    });
    document.body.removeChild(measureElement);
    return maxWidth + 32;
  }, []);

  const renderSelectMenu = (
    options: { value: string; label: string }[],
    changeFunction: (value: string) => void,
    defaultValue?: string | number,
    allowClear?: boolean,
    allowSearch?: boolean,
    disabled?: boolean,
    placeholder?: string
  ) => {
    const largestItemWidth = measureLargestDropdownItem(options);
    const select = (
      <FixedWidthSelect
        {...(allowSearch && { ...searchableSelectParams })}
        options={options}
        defaultValue={defaultValue}
        onChange={(value) => changeFunction((value ?? '').toString())}
        allowClear={allowClear}
        disabled={disabled}
        placeholder={placeholder ?? ''}
        data-testid={editableSelectTestId}
        style={{ minWidth: largestItemWidth }}
      />
    );
    return disabled ? <Tooltip title={translations.referenceData.accountingNotConfigured}>{select}</Tooltip> : select;
  };

  const isNewEntry = useCallback((data: ReferenceDataType) => data.id === ID_FOR_OBJECT_CREATION, []);

  const getNewEntryRow = (): ReferenceDataType => {
    const newEntryRow = {
      id: ID_FOR_OBJECT_CREATION,
      name: '',
      sort_order: 0,
    };

    return newEntryRow;
  };

  const dataSource = hasRowForNewEntry ? [getNewEntryRow(), ...values] : values;

  const quickNoteModalProps = useQuickNoteModal({
    onCancel: handleQuickNoteModalCancel,
    onSave: handleQuickNoteSave,
    editingEntry: editingEntry as QuickNoteDto,
    defaultNoteType:
      filterOptions
        ?.find((f) => f.key === ('note_type_id' as keyof ReferenceDataType))
        ?.values?.find((v) => v.value === currentNoteTypeId)?.label ?? '',
  });

  useEffect(() => {
    if (type === tabKeys.quickNotes) {
      onFilterChange?.(
        filterOptions?.find((f) => f.key === ('note_type_id' as keyof ReferenceDataType))?.values[0].value
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNoteType = () =>
    history.push(
      routes.referenceDataTypes
        .replace(referenceDataParameter, 'noteTypes')
        .replace(organizationIdParameter, organizationId)
    );

  return (
    <>
      <Container style={tabKeys.quickNotes === type ? { alignItems: 'center', justifyContent: 'start' } : undefined}>
        {tabKeys.quickNotes === type && (
          <FlexContainer style={{ marginRight: '12px' }} width={25}>
            <Form.Item style={{ margin: 0 }} name={'note_type'} label={translations.referenceData.noteTypeLabel}>
              <Select
                data-testid={'note_type_id'}
                allowClear
                defaultValue={
                  filterOptions?.find((f) => f.key === ('note_type_id' as keyof ReferenceDataType))?.values[0].value
                }
                {...searchableSelectParams}
                options={
                  filterOptions?.find((f) => f.key === ('note_type_id' as keyof ReferenceDataType))?.values || []
                }
                placeholder={translations.referenceData.placeholders.note_type_id}
                onChange={(value) => {
                  onFilterChange?.(value);
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Button style={{ width: '100%' }} type='link' icon={<PlusOutlined />} onClick={addNoteType}>
                      {translations.referenceData.placeholders.addNoteType}
                    </Button>
                  </>
                )}
              />
            </Form.Item>
          </FlexContainer>
        )}

        {!unchangeable && (
          <Button
            onClick={() => {
              if (tabKeys.quickNotes === type) {
                setEditingEntry(undefined);
                setShowQuickNoteModal(true);
              } else {
                setHasRowForNewEntry(true);
                setEditingEntry(getNewEntryRow());
              }
            }}
          >
            {translations.referenceData.addNewEntry}
          </Button>
        )}
      </Container>
      <Table
        key={type}
        dataSource={dataSource}
        columns={columns as ColumnsType<ReferenceDataType>}
        rowKey='id'
        pagination={{ pageSize, onChange: (_, value) => setPageSize(value ?? defaultPageSize) }}
      />
      {showQuickNoteModal && <QuickNoteModal {...quickNoteModalProps}></QuickNoteModal>}
    </>
  );
};
