import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { translations } from '../../constants/translations';
import { getRequiredRule } from '../../util/forms';

export interface ReasonModalFormValues {
  reason?: string;
}

export interface ReasonModalFormProps {
  autofocus?: boolean;
}

export const reasonModalFormTestId = 'reasonModalForm';

export const ReasonModalContent: React.FC<ReasonModalFormProps> = ({ autofocus }) => (
  <Form.Item
    label={translations.shared.reasonModal.fields.reason}
    name='reason'
    rules={[getRequiredRule(translations.shared.reasonModal.fields.reason)]}
  >
    <TextArea
      autoSize={{ minRows: 3 }}
      maxLength={200}
      placeholder={translations.shared.reasonModal.placeholder}
      autoFocus={autofocus}
    />
  </Form.Item>
);
