import { Form, Select } from 'antd';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import React, { useRef } from 'react';
import { translations } from '../../constants/translations';
import { OrganizationReferenceData } from '../../graph/types';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { getIfUserIsDoctor } from './userUtil';
import { typesFieldName } from '../../constants/fields/userFields';
import type { BaseSelectRef } from 'rc-select';

interface UserRoleSelectProps {
  userTypes: OrganizationReferenceData[];
  setIsDoctor?: (value: boolean) => void;
}

const mapRefDataToSelectOptions = (refData: OrganizationReferenceData[]) => {
  return refData.map((userType) => ({
    value: userType.id,
    label: userType.name,
  }));
};

export const UserRoleSelect: React.FC<UserRoleSelectProps> = ({ userTypes, setIsDoctor }) => {
  const selectRef = useRef<BaseSelectRef>(null);

  const handleOnChange: SelectProps<SelectValue>['onChange'] = (value) => {
    setIsDoctor?.(getIfUserIsDoctor(userTypes, value as string[]));
    selectRef.current?.blur();
  };

  return (
    <Form.Item label={translations.viewUserPage.fields.types.label} name={typesFieldName} rules={[{ type: 'array' }]}>
      <Select
        ref={selectRef}
        {...searchableSelectParams}
        mode={'multiple'}
        options={mapRefDataToSelectOptions(userTypes)}
        onChange={handleOnChange}
      />
    </Form.Item>
  );
};
