import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Form, Input, InputRef, Select, Space } from 'antd';
import { getNumberAndOperatorValue, NumberDropdownValue } from './tableWithCustomFilteringUtil';
import { FormProps } from 'antd/lib/form';
import { translations } from '../../constants/translations';
import styled from 'styled-components';

const PaddedDiv = styled.div`
  padding: 7px;
`;

const FormItemNoMargin = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledSelect = styled(Select)`
  min-width: 110px;
`;

const StyledInput = styled(Input)`
  width: 188px;
  display: block;
`;

const StyledButton = styled(Button)`
  width: 90px;
`;

export const columnFreeTextSelectTestId = 'columnFreeTextSelectTestId';

export const selectDropdownOptions = [
  { value: NumberDropdownValue.equal, label: translations.columnFreeTextFilter.selectDropdownLabel.equal },
  { value: NumberDropdownValue.greaterThan, label: translations.columnFreeTextFilter.selectDropdownLabel.greater },
  { value: NumberDropdownValue.lessThan, label: translations.columnFreeTextFilter.selectDropdownLabel.less },
];

interface ColumnFreeTextFilterProps {
  filterDropdownProps: FilterDropdownProps;
  isForNumber?: boolean;
  name: string;
  containerRef: React.RefObject<HTMLDivElement>;
  inputProps: {
    ref: React.RefObject<InputRef>;
    placeholder: string;
    onPressEnter: () => void;
  };
  buttonName: string;
}

type InitialValues = {
  operatorVal: NumberDropdownValue;
  value: React.Key;
};

const ColumnFreeTextFilter: React.FC<ColumnFreeTextFilterProps> = ({
  filterDropdownProps: { clearFilters, confirm, selectedKeys, setSelectedKeys, visible },
  isForNumber,
  name,
  containerRef,
  inputProps,
  buttonName,
}) => {
  const [form] = Form.useForm();

  const initialValues: InitialValues = useMemo(
    () =>
      isForNumber
        ? getNumberAndOperatorValue(selectedKeys[0] as NumberDropdownValue)
        : {
            operatorVal: NumberDropdownValue.equal,
            value: selectedKeys[0],
          },
    [isForNumber, selectedKeys]
  );

  const handleFormValuesChange: FormProps['onValuesChange'] = useCallback(
    (_: any, values: InitialValues) => {
      const valueToSet = isForNumber ? [values.operatorVal, values.value].join(',') : values.value;
      if (values.value) {
        setSelectedKeys([valueToSet]);
      } else {
        clearFilters?.();
      }
      confirm();
    },
    [clearFilters, confirm, isForNumber, setSelectedKeys]
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  if (!visible) {
    return null;
  }

  return (
    <PaddedDiv ref={containerRef}>
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleFormValuesChange}
        name={name}
        onFinish={inputProps.onPressEnter}
        autoComplete='off'
      >
        <Space>
          {isForNumber && (
            <FormItemNoMargin name={'operatorVal'} data-testid={columnFreeTextSelectTestId}>
              <StyledSelect
                options={selectDropdownOptions}
                getPopupContainer={containerRef?.current ? () => containerRef.current! : undefined}
                onChange={() => inputProps.ref?.current?.focus()}
              />
            </FormItemNoMargin>
          )}
          <FormItemNoMargin name={'value'}>
            <StyledInput {...inputProps} type={isForNumber ? 'number' : 'text'} />
          </FormItemNoMargin>
          <FormItemNoMargin>
            <StyledButton
              onClick={() => {
                clearFilters?.();
                confirm();
                setTimeout(form.resetFields);
              }}
              name={buttonName}
            >
              {translations.shared.clearButtonText}
            </StyledButton>
          </FormItemNoMargin>
        </Space>
      </Form>
    </PaddedDiv>
  );
};

export default React.memo(ColumnFreeTextFilter);
