import React, { useEffect, useState } from 'react';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { Loading } from '../../Loading/Loading';
import { Form } from 'antd';
import { translations } from '../../../constants/translations';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { Store } from 'antd/lib/form/interface';
import { Note, ServiceRendered } from '../../../graph/types';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { dummyNoteIdForRapidBilling, useGetNote } from '../../../hooks/ajax/note/noteHooks';
import { NoteCardContent } from '../../RecordSingleView/Notes/NoteCardContent';
import { NoteUpsertValues, useGetNoteRefData } from '../../RecordSingleView/Notes/noteUtils';
import { NoteChangeRequest, NoteChangeRequestType } from './noteChangeUtil';
import { getRefDataWithVoid, mapToRefData } from '../../../util/refDataUtil';
import { getButtonRowForExistingRecord } from '../../RecordSingleView/ButtonRow';
import { SaveSpinner } from '../../SaveSpinner/SaveSpinner';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface NoteViewWrapperProps {
  noteId: string;
  closeModal: () => void;
  serviceRendered?: ServiceRendered;
  changeNote: (noteRequest: NoteChangeRequest) => void;
  existingNote?: Note | null;
  haveNoteValuesChanged: boolean;
  setHaveNoteValuesChanged: (value: boolean) => void;
  isEditable: boolean;
}

const setFormFieldsFromNote = (form: FormInstance, note: Note | null | undefined) => {
  form.setFields([{ name: 'type_id', value: note?.type_id }]);
};

export const NoteViewWrapper: React.FC<NoteViewWrapperProps> = ({
  noteId,
  closeModal,
  serviceRendered,
  changeNote,
  existingNote,
  haveNoteValuesChanged,
  setHaveNoteValuesChanged,
  isEditable = true,
}: NoteViewWrapperProps) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { note: noteFromDB, noteLoading } = useGetNote({ organizationId, noteId });
  const { noteRefData } = useGetNoteRefData(true);
  const note = noteId === dummyNoteIdForRapidBilling ? existingNote : noteFromDB;
  const refDataWithVoid = note ? getRefDataWithVoid(mapToRefData(note), noteRefData) : noteRefData;

  const [textContent, setTextContent] = useState<string>('');
  const [plainTextContent, setPlainTextContent] = useState<string | undefined>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = useForm();

  useEffect(() => {
    if (!noteLoading && !haveNoteValuesChanged) {
      setFormFieldsFromNote(form, note);
      setTextContent(note?.value || '');
      setPlainTextContent(note?.preview || '');
      if (isEditable) {
        setEditMode(!note);
      }
    }
  }, [form, note, noteLoading, serviceRendered, noteRefData, haveNoteValuesChanged, isEditable]);

  const onReset = () => {
    form.resetFields(['quick_note']);
    setFormFieldsFromNote(form, note);
    setTextContent(note?.value || '');
    setPlainTextContent(note?.preview || '');
    setEditMode(false);
    setHaveNoteValuesChanged(false);
  };

  useEffect(() => {
    return () => onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (noteLoading) {
    return <Loading />;
  }

  if (!serviceRendered) {
    return <p>{translations.shared.errorLoadingPage}</p>;
  }

  const handleValuesChange = (_: Store, allValues: Store) => {
    const haveFormFieldsChanged = allValues.type_id !== note?.type_id;
    setHaveNoteValuesChanged(haveFormFieldsChanged);
    if (isEditable) {
      setEditMode(haveFormFieldsChanged || editMode);
    }
  };

  const sendRequestAndHandleError = async (noteChangeRequest: NoteChangeRequest) => {
    try {
      await changeNote(noteChangeRequest);
      closeModal();
    } catch (error) {
      showErrorMessage((error as Error).message ?? error);
    }
  };

  const handleFinish = async (values: Store) => {
    if (!textContent || textContent === '') {
      showErrorMessage(translations.recordList.emptyTextError);
    }
    setIsSaving(true);
    const noteUpsertValues: NoteUpsertValues = {
      type_id: values.type_id,
      date: dayjs(serviceRendered.date),
      textContent,
      plainTextContent,
    };
    const noteRequest: NoteChangeRequest = note
      ? {
          existingNote: note,
          noteUpsertValues,
          requestType: NoteChangeRequestType.UPDATE,
          service: serviceRendered,
        }
      : {
          noteUpsertValues,
          requestType: NoteChangeRequestType.CREATE,
          service: serviceRendered,
        };
    await sendRequestAndHandleError(noteRequest);
    setHaveNoteValuesChanged(false);
    setIsSaving(false);
  };

  const deleteNote = async () => {
    setIsSaving(true);
    const noteChangeRequest: NoteChangeRequest = {
      noteId: serviceRendered.note_id!,
      service: serviceRendered,
      requestType: NoteChangeRequestType.DELETE,
    };
    await sendRequestAndHandleError(noteChangeRequest);
    setIsSaving(false);
    setHaveNoteValuesChanged(false);
  };

  const activateEditMode = () => {
    if (isEditable) {
      setEditMode(true);
    }
    return true;
  };

  const handleSetTextContent = (
    textContent: string,
    plainTextContent: string | undefined,
    source?: 'api' | 'user' | 'silent'
  ) => {
    setTextContent(textContent);
    setPlainTextContent(plainTextContent);
    if (source === 'user') {
      const haveFormFieldsChanged = textContent !== note?.value;
      setHaveNoteValuesChanged(haveFormFieldsChanged);
    }
  };

  const buttonRowForExistingNotes = getButtonRowForExistingRecord(
    editMode,
    onReset,
    translations.recordList.file,
    activateEditMode,
    deleteNote
  );

  return (
    <SaveSpinner isSaving={isSaving}>
      <Form form={form} onValuesChange={handleValuesChange} onFinish={handleFinish} autoComplete='off'>
        <NoteCardContent
          editMode={editMode}
          activateEditMode={activateEditMode}
          textContent={textContent}
          setTextContent={handleSetTextContent}
          buttonRow={isEditable ? buttonRowForExistingNotes : <></>}
          onClose={closeModal}
          showDateField={false}
          noteRefData={refDataWithVoid}
          form={form}
        />
      </Form>
      {!isEditable && (
        <span style={{ fontSize: '12px' }}>
          <ExclamationCircleOutlined style={{ color: 'red', fontSize: '15px' }} />{' '}
          {translations.renderedServicesTable.note.offlineWarning}
        </span>
      )}
    </SaveSpinner>
  );
};
