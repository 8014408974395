import dayjs from 'dayjs';
import { Note, NoteInterface, NoteType, NoteUpsert } from '../../../graph/types';
import { formats } from '../../../constants/formats';
import { RecordItem, RecordType, renderBottomLineForNotesAndFiles } from '../../Records/recordUtils';
import { TextEditorPreview } from '../../TextEditor/TextEditorPreview';
import { translations } from '../../../constants/translations';
import React from 'react';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { useMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useNoteOfflineInsert, useNoteOfflineUpdate, useUpsertNote } from '../../../hooks/ajax/note/noteHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { TagColor } from '../../../constants/tagColor';
import { getTag } from '../../../util/tags';
import { createPreviewTextForNote } from '../../Records/noteUpsertUtils';
import { useOffline } from '../../../util/offline/offlineUtil';
import { useOrganizationContext } from '../../../contexts/organization/state';

export const getRecordFromNote = (noteInterface: NoteInterface): RecordItem => {
  return {
    originalObject: noteInterface,
    recordId: 'Note' + noteInterface.id,
    type: RecordType.typeNote,
    typeName: noteInterface.type_name,
    date: dayjs(noteInterface.date),
    created: dayjs(noteInterface.created),
    renderBottomLine: () => renderBottomLineForNotesAndFiles(noteInterface),
    renderDescription: () => <TextEditorPreview textContent={noteInterface.preview || ''} />,
    getTag: () => getTag(TagColor.Color1, translations.recordList.note),
  };
};

export interface NoteUpsertValues {
  date: dayjs.Dayjs;
  type_id: string;
  textContent: string;
  plainTextContent?: string | null;
}

export const getVoidNoteUpsert = (note: Note): NoteUpsert => {
  return {
    id: note.id,
    void: true,
  };
};

export const getUpdateNoteUpsert = (
  note: Note,
  noteValues: NoteUpsertValues,
  patientId?: string,
  contactId?: string
): NoteUpsert => {
  return {
    id: note.id,
    record: {
      type_id: noteValues.type_id,
      value: noteValues.textContent,
      preview: createPreviewTextForNote(noteValues?.plainTextContent),
      date: noteValues.date.format(formats.upsertDate),
      hidden: !!note.hidden,
      locked: note.locked,
      draft: false,
      contact_id: contactId,
      patient_id: patientId,
    },
  };
};

export const useSendNoteUpsertToBackend = ({
  noteId,
  patientId,
  contactId,
}: {
  noteId?: string;
  patientId?: string;
  contactId?: string;
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [upsertNote] = useMutationWithMessages(useUpsertNote);
  const { enabledAndOffline } = useOffline();
  const offlineUpdate = useNoteOfflineUpdate(noteId ?? '');
  const offlineInsert = useNoteOfflineInsert({
    patientId: patientId ?? '',
    contactId: contactId ?? '',
  });

  return async (upsert: NoteUpsert, onSuccess?: () => void, successMessage?: string, refData?: NoteType[]) => {
    try {
      if (!enabledAndOffline) {
        const noteResult = await upsertNote({
          options: {
            variables: {
              organizationId,
              note: upsert,
            },
            update: (cache) => {
              if (upsert.void) {
                cache.evict({
                  id: cache.identify({
                    __typename: 'Note',
                    id: noteId,
                  }),
                });
              }
            },
          },
          onSuccess,
          successMessage,
        });
        return noteResult.data?.upsertNote as Note;
      }

      let note: Note;
      if (noteId) {
        note = (await offlineUpdate?.(upsert)) as Note;
      } else {
        note = (await offlineInsert?.(upsert, refData)) as Note;
      }
      onSuccess?.();
      return note;
    } catch (err) {
      showErrorMessage((err as Error).message ?? err);
    }
    return null;
  };
};

export const useGetNoteRefData = (isPatient: boolean) => {
  const {
    state: { organization },
  } = useOrganizationContext();

  return {
    noteRefData: (isPatient ? organization?.ref_patient?.note_type : organization?.ref_contact?.note_type) || [],
  };
};

const stripHtml = (html: string) => {
  html = html.replace(/<br\s*\/?>/gi, '\n');
  html = html.replace(/<p>\s*<br\s*\/?>\s*>\/p>/gi, '\n');
  html = html.replace(/<\/(p|div|li|h[1-6]|pre|blockquote)>/gi, '\n');

  const tempDoc = new DOMParser().parseFromString(html, 'text/html');
  return tempDoc.body.textContent || '';
};

export const appendOrReplaceNote = ({
  textToAdd,
  plainTextToAdd,
  setText,
  existingText,
  existingPlainText,
  shouldAppend,
}: {
  textToAdd: string;
  plainTextToAdd: string;
  setText: (text: string, plaintText: string) => void;
  existingText?: string;
  existingPlainText?: string;
  shouldAppend: boolean;
}) => {
  if (shouldAppend) {
    setText((existingText || '') + '\n' + textToAdd, stripHtml((existingPlainText || '') + '\n' + plainTextToAdd));
  } else {
    setText(textToAdd, stripHtml(plainTextToAdd));
  }
};
