import { AddressType } from '../classes/upsertGenerators/AddressUpsertGenerator';

export const getPracticeUpsertInfos = (
  indexOfPhysicalAddress: number,
  indexOfMailingAddress: number,
  deleteAddress: boolean
) => [
  {
    index: indexOfPhysicalAddress,
    type: AddressType.Physical,
  },
  {
    index: indexOfMailingAddress,
    type: AddressType.Mailing,
    deleteAddress,
  },
];
