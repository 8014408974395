import React from 'react';
import { Button, Popconfirm } from 'antd';
import { Dayjs } from 'dayjs';
import { translations } from '../../../constants/translations';
import { RunMode } from '../types';
import RunMessage from './RunMessage';

type Props = {
  runMode: RunMode;
  contacts: number;
  afterDate: Dayjs;
  beforeDate: Dayjs;
  onSubmit: () => void;
};

const RunButton = ({ contacts, runMode, afterDate, beforeDate, onSubmit }: Props) => {
  const isButtonDisabled = !contacts;

  return (
    <Popconfirm
      disabled={isButtonDisabled}
      placement='top'
      title={<RunMessage runMode={runMode} afterDate={afterDate} beforeDate={beforeDate} contacts={contacts} />}
      cancelText={translations.reminderRuns.actions.cancel}
      okText={translations.reminderRuns.actions.yes}
      onConfirm={onSubmit}
    >
      <Button type='primary' disabled={isButtonDisabled}>
        {translations.reminderRuns.actions.run}
      </Button>
    </Popconfirm>
  );
};

export default RunButton;
