export type ViewInvoiceAction = SetIsBillToOpenAction;

export const SetIsBillToOpen = 'VIEW_INVOICE_ISBILLTO_SET';

export interface SetIsBillToOpenAction {
  type: typeof SetIsBillToOpen;
  isOpen: boolean;
}

export const setIsBillToOpen = (isOpen: boolean): SetIsBillToOpenAction => ({
  type: SetIsBillToOpen,
  isOpen,
});
