import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { translations } from '../../../constants/translations';
import { getMaxLengthRule, getRequiredRule, getValidEmailRule, getValidPasswordRule } from '../../../util/forms';
import { RegistrationButton } from '../RegistrationButton';
import { routes } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';

const errorStyle = { color: 'red', height: '100%', margin: 'auto 0' };

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

type RegisterUserFormProps = {
  goToNextStep: () => void;
  setIsSaving: (value: boolean) => void;
  setCredentials: ({ email, password }: { email: string; password: string }) => void;
};

type RegisterUserFormType = {
  email: string;
  password: string;
  name: string;
  practiceName: string;
};

export const RegisterUserForm: React.FC<RegisterUserFormProps> = ({ goToNextStep, setIsSaving, setCredentials }) => {
  const [form] = useForm();
  const [errorMessage, setErrorMessage] = useState<string>();
  const history = useHistory();
  const registerUserTranslations = translations.registration.registerUserStep;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async ({ email, password, name, practiceName }: RegisterUserFormType) => {
    if (!executeRecaptcha) {
      setErrorMessage(translations.registration.registerUserStep.recaptchaError);
      return;
    }
    const token = await executeRecaptcha();
    const validationData = { Token: token };

    setCredentials({ email, password });
    setIsSaving(true);
    try {
      await Auth.signUp({
        validationData,
        password,
        username: email,
        attributes: {
          email,
          'custom:signupData': JSON.stringify({
            name,
            practiceName,
          }),
        },
      });
      setIsSaving(false);
      goToNextStep();
    } catch (e) {
      if ((e as any).message === 'An account with the given email already exists.') {
        setErrorMessage((e as any).message + ' Redirecting...');
        setTimeout(() => history.push(routes.login, { email }), 3000);
      } else if ((e as any).code === 'InvalidParameterException' || (e as any).code === 'InvalidPasswordException') {
        form.setFields([
          {
            name: 'password',
            errors: [translations.registration.registerUserStep.passwordRequirements],
          },
        ]);
      } else {
        setErrorMessage((e as any).message);
      }
      setIsSaving(false);
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      onChange={() => setErrorMessage('')}
      validateTrigger='onBlur'
      autoComplete='off'
    >
      <>
        {errorMessage && <p style={errorStyle}>{errorMessage}</p>}
        <Row>
          <Col span={24}>
            <MaxLengthFormItem
              name='name'
              label={registerUserTranslations.labels.name}
              rules={[getRequiredRule(registerUserTranslations.labels.name), getMaxLengthRule(50)]}
              className='registration-field'
              maxLength={50}
            >
              <Input placeholder={registerUserTranslations.labels.name} autoComplete='new-password' />
            </MaxLengthFormItem>
          </Col>
          <Col span={24}>
            <MaxLengthFormItem
              name='practiceName'
              label={registerUserTranslations.labels.practiceName}
              rules={[getRequiredRule(registerUserTranslations.labels.practiceName), getMaxLengthRule(50)]}
              className='registration-field'
              maxLength={50}
            >
              <Input placeholder={registerUserTranslations.labels.practiceName} autoComplete='new-password' />
            </MaxLengthFormItem>
          </Col>
          <Col span={24}>
            <MaxLengthFormItem
              name='email'
              label={registerUserTranslations.labels.email}
              rules={[getRequiredRule(registerUserTranslations.labels.email), getValidEmailRule()]}
              className='registration-field'
              maxLength={255}
            >
              <Input placeholder={registerUserTranslations.labels.email} autoComplete='new-password' />
            </MaxLengthFormItem>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password'
              label={registerUserTranslations.labels.password}
              rules={[getRequiredRule(registerUserTranslations.labels.password), getValidPasswordRule()]}
              className='registration-field'
            >
              <Input.Password placeholder={registerUserTranslations.labels.password} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='confirmPassword'
              label={registerUserTranslations.labels.confirmPassword}
              dependencies={['password']}
              rules={[
                getRequiredRule(registerUserTranslations.labels.confirmPassword),
                ({ getFieldValue }) => ({
                  validator(_rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(translations.auth.confirmPassword.nonMatchingErrorMessage);
                  },
                }),
              ]}
              className='registration-field'
            >
              <Input.Password placeholder={registerUserTranslations.labels.confirmPassword} />
            </Form.Item>
          </Col>
        </Row>
        <RegistrationButton onClick={form.submit} text={registerUserTranslations.button} />
      </>
    </Form>
  );
};
