import React from 'react';
import { Form, Input } from 'antd';
import { translations } from '../../constants/translations';
import { getRequiredRule } from '../../util/forms';

export interface CardConnectFormValues {
  merchantId: string;
  userId: string;
  password: string;
}

export const CardConnectFormItems: React.FC<{ additionalClassname?: string; disabled?: boolean }> = ({
  additionalClassname,
  disabled = false,
}) => {
  return (
    <>
      <Form.Item
        label={translations.cardConnectSettings.merchantId}
        name='merchantId'
        rules={[getRequiredRule(translations.cardConnectSettings.merchantId)]}
        className={additionalClassname}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={translations.cardConnectSettings.userId}
        name='userId'
        rules={[getRequiredRule(translations.cardConnectSettings.userId)]}
        className={additionalClassname}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={translations.cardConnectSettings.password}
        name='password'
        rules={[getRequiredRule(translations.cardConnectSettings.password)]}
        className={additionalClassname}
      >
        <Input.Password disabled={disabled} />
      </Form.Item>
    </>
  );
};
