import gql from 'graphql-tag';
import { ReminderFields, ReminderBatchListFields, ReminderBatchDetailFields } from '../fragments';

export const GetReminders = gql`
  query getReminders($organizationId: ID!, $patientId: ID, $contactId: ID, $filter: ReminderFilter) {
    getReminders(organizationId: $organizationId, patientId: $patientId, contactId: $contactId, filter: $filter) {
      ...ReminderFields
    }
  }
  ${ReminderFields}
`;

export const UpsertReminder = gql`
  mutation upsertReminder($organizationId: ID!, $reminder: ReminderUpsert!) {
    upsertReminder(organizationId: $organizationId, reminder: $reminder) {
      ...ReminderFields
    }
  }
  ${ReminderFields}
`;

export const GetRemindersBatch = gql`
  query getReminderBatch($organizationId: ID!, $reminderBatchId: ID!) {
    getReminderBatch(organizationId: $organizationId, reminderBatchId: $reminderBatchId) {
      ...ReminderBatchDetailFields
    }
  }
  ${ReminderBatchDetailFields}
`;

export const GetRemindersBatchList = gql`
  query getReminderBatchList($organizationId: ID!) {
    getReminderBatchList(organizationId: $organizationId) {
      ...ReminderBatchListFields
    }
  }
  ${ReminderBatchListFields}
`;

export const UpsertReminderBatch = gql`
  mutation upsertReminderBatch($organizationId: ID!, $reminderBatch: ReminderBatchUpsert!) {
    upsertReminderBatch(organizationId: $organizationId, reminderBatch: $reminderBatch) {
      ...ReminderBatchListFields
    }
  }
  ${ReminderBatchListFields}
`;
