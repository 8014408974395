import styled from 'styled-components';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';

export const Container = styled.div`
  padding: 0px 24px 24px;
`;

export const StyledPageHeader = styled(StyledPageHeaderWithMargin)`
  margin-bottom: 0.5rem;
`;
