import { translations } from '../translations';

export enum ThirdPartyApprovalTypeNameKey {
  Add = 'THIRD_PARTY_ADD',
  Update = 'THIRD_PARTY_UPDATE',
  Delete = 'THIRD_PARTY_DELETE',
}

export const ThirdPartyApprovalTypeConfigs: { [nameKey in ThirdPartyApprovalTypeNameKey]: string } = {
  [ThirdPartyApprovalTypeNameKey.Add]: translations.approvalsPage.approvalTypeName.add,
  [ThirdPartyApprovalTypeNameKey.Update]: translations.approvalsPage.approvalTypeName.update,
  [ThirdPartyApprovalTypeNameKey.Delete]: translations.approvalsPage.approvalTypeName.delete,
};
