import React, { useCallback, useContext, useMemo } from 'react';
import { phoneTableActions, PhoneTableContext } from '../phoneTableState';
import { Phone } from '../../../graph/types';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import { showErrorMessage } from '../../Notification/notificationUtil';
import { translations } from '../../../constants/translations';

const CenterDiv = styled.div`
  text-align: center;
`;

type PhoneTablePrimaryCellProps = {
  phone: Phone;
};

export const phoneTablePrimaryInputTestId = 'PHONE_TABLE_PRIMARY_INPUT_TEST_ID';

export default function PhoneTablePrimaryCell({ phone }: PhoneTablePrimaryCellProps) {
  const {
    state: { currentEditingPhone, phoneTableData },
    dispatch,
  } = useContext(PhoneTableContext);

  const canBeUnchecked = useMemo(
    () => !(!phoneTableData.length || (!currentEditingPhone?.isNew && phone.primary)),
    [currentEditingPhone, phone.primary, phoneTableData.length]
  );

  const handleOnCheckClick: CheckboxProps['onClick'] = useCallback(() => {
    if (!currentEditingPhone) {
      return;
    }
    if (!canBeUnchecked) {
      showErrorMessage(translations.phoneTable.lastPrimaryUncheckCheck);
      return;
    }
    dispatch(
      phoneTableActions.setCurrentEditing({
        ...currentEditingPhone,
        phone: { ...currentEditingPhone.phone, primary: !currentEditingPhone.phone.primary },
      })
    );
  }, [canBeUnchecked, currentEditingPhone, dispatch]);

  const primaryCell = useMemo(
    () =>
      phone.primary ? (
        <CenterDiv>
          <Checkbox checked />
        </CenterDiv>
      ) : null,
    [phone.primary]
  );

  const editingCell = useMemo(
    () => (
      <CenterDiv>
        <Checkbox
          checked={!!currentEditingPhone?.phone?.primary}
          onClick={handleOnCheckClick}
          data-testid={phoneTablePrimaryInputTestId}
        />
      </CenterDiv>
    ),
    [currentEditingPhone, handleOnCheckClick]
  );
  return currentEditingPhone?.phone?.id === phone.id ? editingCell : primaryCell;
}
