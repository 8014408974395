import * as React from 'react';
import { Action } from './actions';
import { initialState, relationshipReducer, RelationshipState } from './reducer';

export const RelationshipContext = React.createContext<{ state: RelationshipState; dispatch: React.Dispatch<Action> }>(
  {} as any
);

export const RelationshipContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const fullInitialState = {
    ...initialState,
  };
  const [state, dispatch] = React.useReducer(relationshipReducer, fullInitialState);
  const value = {
    state,
    dispatch,
  };
  return <RelationshipContext.Provider value={value}>{props.children}</RelationshipContext.Provider>;
};
