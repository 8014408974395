import React from 'react';
import { ContactCommunicationLog } from '../../../../graph/types';
import { Card } from 'antd';
import styled from 'styled-components';
import { useUserLocaleData } from '../../../../hooks/useUserLocale';
import dayjs from 'dayjs';
import { translations } from '../../../../constants/translations';

type CommunicationLogCardProps = {
  log: ContactCommunicationLog;
};

const CommCard = styled(Card)`
  margin: 3px 10px;
`;

export const CommunicationLogCard: React.FC<CommunicationLogCardProps> = ({ log }) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const sentAt = log.message_sent ? dayjs(log.message_sent).format(`${dateFormat} hh:mm:ss`) : '';

  return (
    <CommCard
      key={log.id}
      size='small'
      extra={sentAt ? `${translations.viewContactPage.communicationLog.sentAt}: ${sentAt}` : ''}
      title={log.subject}
    >
      <p>
        <strong>{translations.viewContactPage.communicationLog.recipients}:</strong> {log.recipients}
      </p>
      {log.cc && (
        <p>
          <strong>{translations.viewContactPage.communicationLog.cc}:</strong> {log.cc}
        </p>
      )}
      {log.bcc && (
        <p>
          <strong>{translations.viewContactPage.communicationLog.bcc}:</strong> {log.bcc}
        </p>
      )}
      <p dangerouslySetInnerHTML={{ __html: log.body ?? '' }} />
    </CommCard>
  );
};
