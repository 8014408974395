import { Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { translations } from '../../constants/translations';
import { OrganizationSubscriptionResult } from '../../graph/types';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { ErrorText, InstructionContainer } from '../../globalStyles.style';
import { SubscriptionChargesInfo } from '../SubscriptionChargesInfo/SubscriptionChargesInfo';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { getRequiredRule } from '../../util/forms';
import { FormInstance } from 'antd/lib';
import { useGetReferenceData } from '../../hooks/ajax/referenceData/referenceDataHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { EMRRegionNameKeyTranslationMap } from './emrUtil';
import { searchableSelectParams } from '../../constants/searchableSelectParams';

export interface EMRConfigurationModalProps {
  addOnEnabled: boolean;
  subscription?: OrganizationSubscriptionResult;
  onSave: () => void;
  onClose: () => void;
  form: FormInstance;
}

export const EMRConfigurationModal: React.FC<EMRConfigurationModalProps> = ({
  addOnEnabled,
  subscription,
  onSave,
  onClose,
  form,
}: EMRConfigurationModalProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [defaultRegionSelected, setDefaultRegionSelected] = useState();
  const [regionOptions, setRegionOptions] = useState<{ label: string; value: string }[]>();

  const organizationId = useGetOrganizationIdFromRoute();
  const { referenceData, dataLoading } = useGetReferenceData(organizationId);

  useEffect(() => {
    if (referenceData) {
      setRegionOptions(
        referenceData?.ref_3p_region?.map((r) => ({
          label: EMRRegionNameKeyTranslationMap[r.name_key],
          value: r.id,
        }))
      );
    }
  }, [referenceData]);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setErrorMessage(undefined);
      await onSave();
      setIsSaving(false);
    } catch (err) {
      if ((err as any).message === 'Network Error') {
        setErrorMessage(translations.shared.networkError);
      } else {
        setErrorMessage((err as any).message);
      }
      setIsSaving(false);
    }
  };

  return (
    <ModalWithCloseConfirm
      title={translations.emrConfigurationModal.title}
      open
      onCancel={onClose}
      onOk={handleSave}
      okText={translations.emrConfigurationModal.saveButtonText}
      okButtonProps={{ disabled: !defaultRegionSelected || isSaving }}
      isSaving={dataLoading || isSaving}
      savingMessage={dataLoading ? translations.shared.loading : translations.loadingComponent.saving}
      width={500}
    >
      <SaveSpinner isSaving={isSaving} savingMessage={translations.loadingComponent.loading}></SaveSpinner>
      {errorMessage && (
        <Row>
          <Col>
            <ErrorText>{errorMessage}</ErrorText>
          </Col>
        </Row>
      )}
      <Col offset={2} span={20}>
        <InstructionContainer>{translations.emrRegionModal.instructions}</InstructionContainer>
        <Form form={form}>
          <Form.Item
            required
            requiredMark
            name={'defaultRegion'}
            label={translations.emrRegionModal.defaultRegionLabel}
            rules={[getRequiredRule(translations.emrRegionModal.defaultRegionLabel)]}
          >
            <Select {...searchableSelectParams} style={{ zIndex: 4 }} onSelect={setDefaultRegionSelected}>
              {regionOptions?.map((o) => (
                <Select.Option key={o.value} label={o.label} value={o.value}>
                  <span>{o.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      {!addOnEnabled && (
        <Row>
          <Col offset={2} span={20}>
            <SubscriptionChargesInfo subscription={subscription} />
          </Col>
        </Row>
      )}
    </ModalWithCloseConfirm>
  );
};
