import React, { useContext } from 'react';
import { selectRecordIfNoUnsavedChanges } from '../recordUtils';
import { translations } from '../../../constants/translations';
import { Button } from 'antd';
import { setHasUnsavedChangesAction } from '../store/actions';
import { RecordsContext } from '../store/state';
import { useOffline } from '../../../util/offline/offlineUtil';
import { ExtraContentSpace } from '../../../globalStyles.style';

export const AddRecordButtons = () => {
  const { state, dispatch } = useContext(RecordsContext);
  const { enabledAndOffline } = useOffline();

  const handleAddRecord = (recordType: 'note' | 'file') => {
    selectRecordIfNoUnsavedChanges(state, dispatch, { newRecord: recordType });
    dispatch(setHasUnsavedChangesAction(true));
  };

  return (
    <ExtraContentSpace>
      {!enabledAndOffline && (
        <Button onClick={() => handleAddRecord('file')}>{translations.recordList.addFileButtonText}</Button>
      )}
      <Button onClick={() => handleAddRecord('note')}>{translations.recordList.addNoteButtonText}</Button>
    </ExtraContentSpace>
  );
};
