import { gql } from '@apollo/client';

export const GetChatBotAuthToken = gql`
  query GetChatBotAuthToken($organizationId: ID!, $practiceId: ID!) {
    getChatBotAuthToken(organizationId: $organizationId, practiceId: $practiceId) {
      jwt
      message
      chatBotEndpoint
    }
  }
`;
