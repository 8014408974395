import { DatePicker, Form, Input, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { PatientRefSelection } from '../../components/PatientRefSelection/PatientRefSelection';
import { translations } from '../../constants/translations';
import { FormWithFlexWrap, WidthContainer } from '../../globalStyles.style';
import { getRequiredRule } from '../../util/forms';
import dayjs from 'dayjs';
import { MaxLengthFormItem } from '../../components/MaxLengthFormItem/MaxLengthFormItem';
import { useOrganizationContext } from '../../contexts/organization/state';

export const statusActive = 'active';
export const statusInactive = 'inactive';
export const statusDeceased = 'deceased';

type AddPatientFormProps = {
  form: FormInstance;
  handleValueChange: (_: Store, allValues: Store) => void;
  initialValues: Store;
  dateFormat: string;
};

export const AddPatientForm: React.FC<AddPatientFormProps> = ({
  initialValues,
  handleValueChange,
  form,
  dateFormat,
}) => {
  const {
    state: { organization },
  } = useOrganizationContext();
  const patientReferenceData = organization?.ref_patient;

  if (!patientReferenceData) {
    return <p>{translations.shared.missingPatientReferenceData}</p>;
  }

  return (
    <FormWithFlexWrap
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      onValuesChange={handleValueChange}
      autoComplete='new-password'
    >
      <WidthContainer width={50}>
        <MaxLengthFormItem
          label={translations.addPatientPage.fields.name.label}
          name='name'
          rules={[getRequiredRule(translations.addPatientPage.fields.name.label)]}
          maxLength={50}
        >
          <Input placeholder={translations.addPatientPage.fields.name.label} autoComplete='new-password' />
        </MaxLengthFormItem>
        <Form.Item label={translations.addPatientPage.fields.dateOfBirth.label} name={'dateOfBirth'}>
          <DatePicker disabledDate={(date) => date.isAfter(dayjs())} format={dateFormat} />
        </Form.Item>
        <Form.Item label={translations.addPatientPage.fields.status.label} name={'status'}>
          <Select>
            <Select.Option value={statusActive}>Active</Select.Option>
            <Select.Option value={statusInactive}>Inactive</Select.Option>
            <Select.Option value={statusDeceased}>Deceased</Select.Option>
          </Select>
        </Form.Item>
      </WidthContainer>
      <WidthContainer width={50}>
        <PatientRefSelection patientRef={patientReferenceData} form={form} />
      </WidthContainer>
    </FormWithFlexWrap>
  );
};
