import React, { useState } from 'react';
import { DatePicker, Form, Select } from 'antd';
import { translations } from '../../constants/translations';
import { getRequiredRule } from '../../util/forms';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import {
  Patient,
  QueryGetPatientHistoryReportArgs,
  QueryRptGetPatientHistoryArgs,
  RelatedOwner,
  RelatedOwnershipEntryAll,
} from '../../graph/types';
import { recipientsFieldName, subjectFieldName } from './StandardEmailFormFields';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import { sortBy, uniq, uniqBy } from 'lodash';
import {
  displayOneDayEarlierIfSet,
  getFileNameForPatientHistory,
  getOwnershipEnd,
  getOwnershipStart,
  buildPatientHistoryRptVariables,
  getPatientReportAsEmailAttachment,
  getReportForContactOptions,
  generateReportAttachment,
} from '../../pages/Patients/patientUtils';
import { EmailModal } from './EmailModal';
import { FormInstance } from 'antd/lib/form';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Reports } from '../../util/reportUtils';
import { GetPatientHistoryReportAR } from '../../graph/queries/reports';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';

interface EmailModalForPatientHistoryProps {
  onClose: () => void;
  patient: Patient;
}

const contactIdFieldName = 'contactId';
const startEndDatesFieldName = 'startEndDate';

export const datePickerTestId = 'datePickerTestId';

export const EmailModalForPatientHistory: React.FC<EmailModalForPatientHistoryProps> = ({ onClose, patient }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { displayCurrency, displayDate } = useCurrencyAndDateSetting();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const usesActiveReportForPatientHistory = useLDFlag(LDFlagNames.ARPatientHistoryReport);

  const ownershipAll: RelatedOwnershipEntryAll[] = sortBy(patient.ownership_all, ['effective_date']) || [];
  let owners: RelatedOwner[] = [];
  ownershipAll.forEach((ownership) => {
    owners = owners.concat(ownership.owner);
  });
  const all = 'All';
  const reportForList = [{ value: all, label: all }].concat(getReportForContactOptions(owners));
  const reportForOptions = uniqBy([...reportForList], 'value');

  // TODO - test functionality after removing useCallback
  const getRecipients = (contactId?: string) =>
    uniq(
      owners
        .filter((owner) => owner.email && (contactId === undefined || owner.contact_id === contactId))
        .map((owner) => owner.email)
    );

  const initialValues = {
    [contactIdFieldName]: reportForOptions[0].value,
    [subjectFieldName]: `Patient History - ${patient?.name}`,
    [recipientsFieldName]: getRecipients(),
    [startEndDatesFieldName]: [dayjs(ownershipAll[0]?.effective_date), dayjs()],
  };
  const [fileName, setFileName] = useState(() =>
    getFileNameForPatientHistory(
      patient.number,
      initialValues[startEndDatesFieldName][0]?.format(dateFormat) ?? '',
      initialValues[startEndDatesFieldName][1]?.format(dateFormat) ?? ''
    )
  );

  const handleContactChange = (contactId: string, form: FormInstance) => {
    let ownershipStart: RelatedOwnershipEntryAll | undefined;
    let ownershipEnd: RelatedOwnershipEntryAll | undefined;

    if (contactId === all) {
      ownershipStart = ownershipAll[0];
      ownershipEnd = ownershipAll[ownershipAll.length - 1];
    } else {
      ownershipStart = getOwnershipStart(ownershipAll, contactId);
      ownershipEnd = getOwnershipEnd(ownershipAll, contactId);
    }

    const ownershipEndIndex = ownershipAll.findIndex(({ syndicate_id }) => syndicate_id === ownershipEnd?.syndicate_id);

    const startDate = dayjs(ownershipStart?.effective_date);
    const endDate = displayOneDayEarlierIfSet(ownershipAll[ownershipEndIndex + 1]?.effective_date) ?? dayjs();

    form.setFields([
      {
        name: recipientsFieldName,
        value: getRecipients(contactId === all ? undefined : contactId),
      },
      {
        name: startEndDatesFieldName,
        value: [startDate, endDate],
      },
    ]);

    setFileName(
      getFileNameForPatientHistory(
        patient.number,
        startDate?.format(dateFormat) ?? '',
        endDate?.format(dateFormat) ?? ''
      )
    );
  };

  const handleValueChange = (changedValue: Store, form: FormInstance) => {
    if (changedValue[contactIdFieldName]) {
      handleContactChange(changedValue[contactIdFieldName], form);
    }
    if (changedValue[startEndDatesFieldName]) {
      setFileName(
        getFileNameForPatientHistory(
          patient.number,
          changedValue[startEndDatesFieldName][0].format(dateFormat),
          changedValue[startEndDatesFieldName][1].format(dateFormat)
        )
      );
    }
  };

  const fetchAttachments = async (formValues: Store) => {
    if (!patient) {
      return [];
    }

    const patientHistoryVariables = buildPatientHistoryRptVariables({
      patient,
      startDate: formValues[startEndDatesFieldName][0].format(dateFormat),
      endDate: formValues[startEndDatesFieldName][1].format(dateFormat),
      practiceId,
      organizationId,
      usesActiveReports: usesActiveReportForPatientHistory,
    });

    let patientHistoryReport;

    if (usesActiveReportForPatientHistory) {
      patientHistoryReport = await generateReportAttachment(
        GetPatientHistoryReportAR,
        patientHistoryVariables as QueryRptGetPatientHistoryArgs,
        fileName,
        displayCurrency,
        displayDate,
        Reports.PatientHistory
      );
    } else {
      patientHistoryReport = await getPatientReportAsEmailAttachment(
        patientHistoryVariables as QueryGetPatientHistoryReportArgs
      );
    }

    if (!patientHistoryReport) {
      return [];
    }

    return [patientHistoryReport];
  };

  return (
    <EmailModal
      title={translations.emailModalForPatientHistory.title}
      visible
      onClose={onClose}
      extraFormFields={
        <>
          <Form.Item
            name={contactIdFieldName}
            label={translations.emailModalForPatientHistory.fields.contactFieldLabel}
            rules={[getRequiredRule(translations.emailModalForPatientHistory.fields.contactFieldLabel)]}
          >
            <Select options={reportForOptions} />
          </Form.Item>
          <Form.Item
            label={translations.emailModalForPatientHistory.fields.date}
            name={startEndDatesFieldName}
            rules={[getRequiredRule(translations.emailModalForPatientHistory.fields.date)]}
            data-testid={datePickerTestId}
          >
            <DatePicker.RangePicker
              format={dateFormat}
              placeholder={[
                translations.emailModalForPatientHistory.fields.startDate,
                translations.emailModalForPatientHistory.fields.endDate,
              ]}
              disabledDate={(date) => date.isAfter(dayjs())}
            />
          </Form.Item>
        </>
      }
      attachments={[fileName]}
      initialValues={initialValues}
      onAttachmentsFetch={fetchAttachments}
      onChange={handleValueChange}
    />
  );
};
