import { gql } from '@apollo/client';
import {
  InvoiceContactPaymentFields,
  NoteFields,
  PrescriptionFlattenedFields,
  ServiceRenderedInfoFields,
  ServiceRenderedTextFields,
} from '../fragments';
import { LabRequestFields, LabStatusFields } from './labLinkFragments';
import { LedgerTaxFields } from './ledgerFragments';

export const BasicSingleInvoiceFields = gql`
  fragment BasicSingleInvoiceFields on Invoice {
    id
    number
    date
    total
    contact_names
    contact_percentages
    contact_ids
    patient_name
    patient_number
    patient_id
    status_name_key
    status_id
    contact_id
    practice_id
    labTaxonomyMapping {
      lab_device_id
      lab_device_name
      breed_id
      breed_name
      gender_id
      gender_name
      species_id
      species_name
      requires_breed_map
      requires_gender_map
      requires_species_map
      lab_species_number
    }
    thirdPartyTaxonomyMapping {
      third_party_id
      third_party_name_key
      third_party_desc_key
      organization_id
      gender_id
      gender_name
      requires_gender_map
    }
  }
`;

export const BasicServicesRenderedFields = gql`
  fragment BasicServicesRenderedFields on ServiceRendered {
    id
    name
    service_id
    service_name
    doctor_id
    doctor_name
    date
    quantity
    unit_price
    unit_name
    total
    note_id
    invoice_id
    business_center_id
    location_id
    list_unit_price
    list_total
    hidden
    vaccine
    controlled
    microchip
    rabies_tag
    manufacturer_name
    lot_number
    serial_number
    expiry_date
    bottle_number
    roa_id
    roa_other
    reason
    withdrawal_period
    withdrawal_prompt
    discount
    discount_percent
    prescriptions {
      ...PrescriptionFlattenedFields
    }
    sort_order
    lab_request {
      ...LabRequestFields
    }
    lab_status {
      ...LabStatusFields
    }
    hide_discount
    grouping_id
    is_parent
    third_party {
      id
      organization_id
      service_rendered_id
      third_party_id
      status_id
      sent
      failed_count
      failed_reason
      requires_gender_map
    }
  }
  ${PrescriptionFlattenedFields}
  ${LabRequestFields}
  ${LabStatusFields}
`;

export const SingleInvoiceFields = gql`
  fragment SingleInvoiceFields on Invoice {
    ...BasicSingleInvoiceFields
    item {
      ...BasicServicesRenderedFields
      cacheLoading @client
    }
    tax {
      ...LedgerTaxFields
    }
    text {
      id
      type_id
      category
      name_key
    }
  }
  ${BasicSingleInvoiceFields}
  ${BasicServicesRenderedFields}
  ${LedgerTaxFields}
`;

export const UpsertInvoiceReturnFields = gql`
  fragment UpsertInvoiceReturnFields on Invoice {
    ...SingleInvoiceFields
    contact {
      ...InvoiceContactPaymentFields
    }
  }
  ${SingleInvoiceFields}
  ${InvoiceContactPaymentFields}
`;

export const InvoiceFieldsForServiceNotes = gql`
  fragment InvoiceFieldsForServiceNotes on Invoice {
    id
    item {
      id
      note_id
      note {
        ...NoteFields
      }
    }
  }
  ${NoteFields}
`;

export const InvoiceListFields = gql`
  fragment InvoiceListFields on Invoice {
    id
    number
    contact_names
    contact_percentages
    contact_ids
    date
    status_id
    status_name_key
    total
    patient_name
    contact_id
    changed_date
    hidden
  }
`;

export const InvoiceOfflineFields = gql`
  fragment InvoiceOfflineFields on Invoice {
    id
    offline_id
    organization_id
    updated
    practice_id
    number
    contact_id
    contact_number
    contact_name
    contact_ids
    contact_names
    contact_percentages
    split
    hidden
    date
    status_id
    status_name_key
    total_no_tax
    total_tax
    total
    patient_count
    patient_id
    patient_number
    patient_name
    patient_syndicate_id
    changed_date
    item {
      ...BasicServicesRenderedFields
      ...ServiceRenderedInfoFields
      ...ServiceRenderedTextFields
    }
    doctor_names
    tax {
      ...LedgerTaxFields
    }
    contact {
      id
      organization_id
      contact_id
      contact_number
      contact_name
      contact_email
      percentage
      primary
      ledger_id
    }
  }
  ${BasicServicesRenderedFields}
  ${ServiceRenderedInfoFields}
  ${ServiceRenderedTextFields}
  ${LedgerTaxFields}
`;
