import { selectedRecordIdType, ServiceRenderedFragment } from './reducer';

export type RecordsAction =
  | SelectRecord
  | SetHasUnsavedChanges
  | SetSearchValue
  | SetSelectServiceRenderedValue
  | SetServicesRenderedValue;

export const selectRecordType = 'RECORDS__SELECT_RECORD';
export const setHasUnsavedChangesType = 'RECORDS__SET_HAS_UNSAVED_CHANGES';
export const setSearchValueType = 'RECORDS__SET_SEARCH_VALUE';
export const setSelectServiceRenderedType = 'RECORDS__SET_SELECT_SERVICE_RENDERED';
export const setServicesRenderedType = 'RECORDS__SET_SERVICES_RENDERED';

export interface SelectRecord {
  type: typeof selectRecordType;
  recordId?: selectedRecordIdType;
}

export const selectRecordAction = (recordId?: selectedRecordIdType): SelectRecord => ({
  type: selectRecordType,
  recordId,
});

export interface SetHasUnsavedChanges {
  type: typeof setHasUnsavedChangesType;
  hasUnsavedChanges: boolean;
}

export const setHasUnsavedChangesAction = (hasUnsavedChanges: boolean): SetHasUnsavedChanges => ({
  type: setHasUnsavedChangesType,
  hasUnsavedChanges,
});

export interface SetSearchValue {
  type: typeof setSearchValueType;
  searchValue: string;
}

export const setSearchValueAction = (searchValue: string): SetSearchValue => ({
  type: setSearchValueType,
  searchValue,
});

export interface SetSelectServiceRenderedValue {
  type: typeof setSelectServiceRenderedType;
  selectedServiceRender: ServiceRenderedFragment;
}

export const setServiceRenderAction = (
  selectedServiceRender: ServiceRenderedFragment
): SetSelectServiceRenderedValue => ({
  type: setSelectServiceRenderedType,
  selectedServiceRender,
});

export interface SetServicesRenderedValue {
  type: typeof setServicesRenderedType;
  servicesRender: ServiceRenderedFragment[];
}

export const setServiceRenderedAction = (servicesRender: ServiceRenderedFragment[]): SetServicesRenderedValue => ({
  type: setServicesRenderedType,
  servicesRender,
});
