import { translations } from '../../../constants/translations';
import { OfflineUser } from '../util';
import { getOnFilterFunctionFor, getStringCompareFunctionFor } from '../../../util/filterAndSorting';
import { SortOrder } from 'antd/lib/table/interface';
export type UsersProperties = keyof Pick<OfflineUser, 'name' | 'userId' | 'enableOffline' | 'roles'>;

export const usersTablePropertyNames: Readonly<{ [K in UsersProperties]: K }> = {
  name: 'name',
  userId: 'userId',
  enableOffline: 'enableOffline',
  roles: 'roles',
};

const columns = {
  name: {
    title: translations.subscriptionUpdateModal.usersTable.name,
    key: usersTablePropertyNames.name,
    dataIndex: usersTablePropertyNames.name,
    filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
      translations.subscriptionUpdateModal.usersTable.name
    ),
    onFilter: getOnFilterFunctionFor('name'),
    sorter: getStringCompareFunctionFor('name'),
    width: 400,
    defaultSortOrder: 'ascend' as SortOrder,
  },
  roles: {
    title: translations.subscriptionUpdateModal.usersTable.role,
    key: usersTablePropertyNames.roles,
    dataIndex: usersTablePropertyNames.roles,
    width: 250,
    onFilter: getOnFilterFunctionFor('roles'),
  },
  enableOffline: {
    title: translations.subscriptionUpdateModal.usersTable.allowOffline,
    key: usersTablePropertyNames.enableOffline,
    dataIndex: usersTablePropertyNames.enableOffline,
    filters: [
      { text: translations.shared.enabled, value: true },
      { text: translations.shared.disabled, value: false },
    ],
    onFilter: getOnFilterFunctionFor('enableOffline'),
    sorter: getStringCompareFunctionFor('enableOffline'),
    width: 80,
  },
};

export default columns;
