import { ContainerOutlined, ExperimentOutlined, LineChartOutlined, PlusSquareOutlined } from '@ant-design/icons';
import {
  AuditOutlined,
  BankOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileDoneOutlined,
  HomeOutlined,
  MailOutlined,
  MedicineBoxOutlined,
  PercentageOutlined,
  SecurityScanOutlined,
  UnorderedListOutlined,
  UserOutlined,
  DatabaseFilled,
  /*WarningOutlined,*/
} from '@ant-design/icons/lib';
import { route } from '../components/PageSider/PageSider';
import { patientMenuIcon } from '../images/images';
import { isMobileDevice } from '../util/deviceUtil';
import { LDFlagNames } from './launchDarkly';
import { UserPermissionNameKey } from './referenceData/userPermissionReferenceData';
import { translations } from './translations';

export const contactIdParameter = ':contactId';
export const invoiceIdParameter = ':invoiceId';
export const organizationIdParameter = ':organizationId';
export const patientIdParameter = ':patientId';
export const serviceIdParameter = ':serviceId';
export const userIdParameter = ':userId';
export const encodedUserNameParameter = ':encodedUsername';
export const codeParameter = ':code';
export const referenceDataParameter = ':referenceDataType';

const getUrlWithOrganizationId = (url: string) => `/organization/${organizationIdParameter}${url}`;

export const routes = {
  root: '/',
  baseOrganization: getUrlWithOrganizationId(''),
  dashboard: getUrlWithOrganizationId('/dashboard'),
  doctorOffice: getUrlWithOrganizationId('/doctorOffice'),
  contacts: getUrlWithOrganizationId('/contacts'),
  addContact: getUrlWithOrganizationId('/contacts/addContact'),
  viewContact: getUrlWithOrganizationId(`/contacts/${contactIdParameter}`),
  patients: getUrlWithOrganizationId('/patients'),
  addPatient: getUrlWithOrganizationId('/patients/addPatient'),
  viewPatient: getUrlWithOrganizationId(`/patients/${patientIdParameter}`),
  invoices: getUrlWithOrganizationId('/invoices'),
  addInvoice: getUrlWithOrganizationId('/invoices/addInvoice'),
  viewInvoice: getUrlWithOrganizationId(`/invoices/${invoiceIdParameter}`),
  practice: getUrlWithOrganizationId('/practice'),
  practiceSettings: getUrlWithOrganizationId('/practice/practiceSettings'),
  practiceUsers: getUrlWithOrganizationId('/practice/users'),
  createUser: getUrlWithOrganizationId('/practice/users/createUser'),
  viewUser: getUrlWithOrganizationId(`/practice/users/${userIdParameter}`),
  userSettings: getUrlWithOrganizationId('/userSettings'),
  emailSettings: getUrlWithOrganizationId('/practice/emailSettings'),
  interestSettings: getUrlWithOrganizationId('/practice/interestSettings'),
  services: getUrlWithOrganizationId('/practice/services'),
  viewService: getUrlWithOrganizationId(`/practice/services/${serviceIdParameter}`),
  addService: getUrlWithOrganizationId('/practice/services/addService'),
  cardConnectSettings: getUrlWithOrganizationId('/practice/cardConnectSettings'),
  referenceData: getUrlWithOrganizationId('/practice/referenceData'),
  referenceDataTypes: getUrlWithOrganizationId(`/practice/referenceData/${referenceDataParameter}`),
  currencySettings: getUrlWithOrganizationId('/practice/currencySettings'),
  rapidBilling: getUrlWithOrganizationId('/rapidBilling'),
  subscriptionSettings: getUrlWithOrganizationId('/practice/subscription'),
  addOnSettings: getUrlWithOrganizationId('/practice/addOns'),
  billingHistoryPage: getUrlWithOrganizationId('/practice/subscription/billingHistory'),
  statementRunsReport: getUrlWithOrganizationId('/reports/statementRuns'),
  thirdPartyDataApprovals: getUrlWithOrganizationId('/3p/approvals'),
  thirdPartyDataHistory: getUrlWithOrganizationId('/3p/history'),
  adminSubscription: getUrlWithOrganizationId('/practice/admin/subscription'),
  dataConversion: getUrlWithOrganizationId('/practice/dataConversion'),
  generalReports: getUrlWithOrganizationId('/reports/generalReports'),
  reportViewer: getUrlWithOrganizationId('/reports/viewer'),
  reportViewerPractice: getUrlWithOrganizationId('/practice/reports/viewer'),
  reportSettings: getUrlWithOrganizationId('/reports/settings'),
  interestRuns: getUrlWithOrganizationId('/reports/interestRuns'),
  reminderRuns: getUrlWithOrganizationId('/reports/reminder-runs'),
  syncErrorLog: getUrlWithOrganizationId('/practice/syncErrorLog'),
  labManagement: getUrlWithOrganizationId('/labManagement'),
  financialPeriods: getUrlWithOrganizationId('/financials/financialPeriods'),
  depositRuns: getUrlWithOrganizationId('/financials/depositRuns'),
  stripeConfirmation: getUrlWithOrganizationId('/practice/stripeConfirmation'),
  stripeRegistration: getUrlWithOrganizationId('/practice/stripeRegistration'),
  login: '/login',
  logout: '/logout',
  pin: '/pin',
  signup: '/signup',
  resetPassword: '/resetPassword',
  setNewPassword: `/setNewPassword/${encodedUserNameParameter}/${codeParameter}`,
  adminRedirection: '/admin',
  maintenance: '/maintenance',
  vetConnectResultHandler: getUrlWithOrganizationId('/vetConnectResultHandler'),
  offlineDiagnostics: getUrlWithOrganizationId('/offlineDiagnostics'),
  reportPayments: getUrlWithOrganizationId('/reports/payments'),
  quickBooksCallback: '/quickBookCallback',
  support: getUrlWithOrganizationId('/support'),
};

export const getRouteWithParameter = (route: string, parameter: string, value: string) => {
  return route.replace(parameter, encodeURIComponent(value));
};

export const getRouteWithOrganizationId = (route: string, organizationId: string) =>
  getRouteWithParameter(route, organizationIdParameter, organizationId);

export const mainPageSiderRoutes: { [key: string]: route } = {
  dashboard: {
    order: 1,
    path: routes.dashboard,
    label: translations.mainPage.dashboard,
    icon: LineChartOutlined,
    disabledOffline: true,
    ldFlags: ['Page_Dashboard'],
  },
  doctorOffice: {
    order: 2,
    path: routes.doctorOffice,
    label: translations.mainPage.doctorOffice,
    icon: MedicineBoxOutlined,
    disabledOffline: true,
  },
  rapidBilling: {
    order: 3,
    path: routes.rapidBilling,
    label: translations.mainPage.rapidBilling,
    icon: ContainerOutlined,
  },
  contacts: {
    order: 4,
    path: routes.contacts,
    label: translations.mainPage.contacts,
    icon: ContactsOutlined,
  },
  patients: {
    order: 5,
    path: routes.patients,
    label: translations.mainPage.patients,
    icon: patientMenuIcon,
  },
  invoices: {
    order: 6,
    path: routes.invoices,
    label: translations.mainPage.invoices,
    icon: DollarOutlined,
    disabledOffline: isMobileDevice,
  },
  reports: {
    order: 7,
    disabledOffline: true,
    menu: {
      statementRuns: {
        order: 1,
        path: routes.statementRunsReport,
        label: translations.mainPage.statementRuns,
        icon: null,
      },
      interestRuns: {
        order: 2,
        path: routes.interestRuns,
        label: translations.mainPage.interestRuns,
        icon: null,
      },
      reminderRuns: {
        order: 3,
        path: routes.reminderRuns,
        label: translations.mainPage.reminderRuns,
        icon: null,
      },
      generalReports: {
        order: 4,
        path: routes.generalReports,
        label: translations.mainPage.generalReports,
        icon: null,
      },
      reportPayments: {
        order: 5,
        path: routes.reportPayments,
        label: translations.mainPage.reportPayments,
        icon: null,
        disabledOffline: true,
      },
      reportSettings: {
        order: 6,
        path: routes.reportSettings,
        label: translations.mainPage.reportSettings,
        icon: null,
      },
    },
    label: translations.mainPage.reports,
    icon: FileDoneOutlined,
  },
  thirdPartyData: {
    order: 8,
    disabledOffline: true,
    menu: {
      approvals: {
        order: 1,
        path: routes.thirdPartyDataApprovals,
        label: translations.mainPage.approvals,
        icon: null,
      },
      history: {
        order: 2,
        path: routes.thirdPartyDataHistory,
        label: translations.mainPage.history,
        icon: null,
      },
    },
    label: translations.mainPage.thirdPartyData,
    icon: DatabaseFilled,
    ldFlags: [LDFlagNames.ThirdPartyData],
  },
  lab: {
    order: 9,
    menu: {
      labManagement: {
        order: 1,
        path: routes.labManagement,
        label: translations.mainPage.labManagement,
        icon: null,
      },
      launchAntech: {
        order: 2,
        label: translations.mainPage.launchAntech,
        icon: null,
        id: 'dockable-button',
        isAntech: true,
      },
    },
    label: translations.mainPage.lab,
    icon: ExperimentOutlined,
    disabledOffline: true,
    ldFlags: [LDFlagNames.LabManagement],
  },
  financials: {
    order: 10,
    menu: {
      financialPeriods: {
        order: 1,
        path: routes.financialPeriods,
        label: translations.mainPage.financialPeriods,
        icon: null,
      },
      depositRuns: {
        order: 2,
        path: routes.depositRuns,
        label: translations.mainPage.depositRuns,
        icon: null,
        ldFlags: [LDFlagNames.DepositRun],
      },
    },
    label: translations.mainPage.financials,
    icon: BankOutlined,
    disabledOffline: true,
    ldFlags: [LDFlagNames.Financials],
  },
};

export const settingRoutingPaths = {
  home: {
    order: 0,
    path: routes.root,
    label: translations.settingsPage.home,
    icon: HomeOutlined,
  },
  practiceSettings: {
    order: 1,
    path: routes.practiceSettings,
    label: translations.settingsPage.practiceSettings,
    icon: BankOutlined,
  },
  user: {
    order: 2,
    path: routes.practiceUsers,
    label: translations.settingsPage.users,
    icon: UserOutlined,
    disabledOffline: true,
  },
  services: {
    order: 3,
    path: routes.services,
    label: translations.settingsPage.services,
    icon: AuditOutlined,
    disabledOffline: true,
  },
  emailSettings: {
    order: 4,
    path: routes.emailSettings,
    label: translations.emailSettingsPage.title,
    icon: MailOutlined,
    disabledOffline: true,
  },
  interestSettings: {
    order: 5,
    path: routes.interestSettings,
    label: translations.settingsPage.interestSettings,
    icon: PercentageOutlined,
    disabledOffline: true,
  },
  subscription: {
    order: 6,
    path: routes.subscriptionSettings,
    label: translations.settingsPage.subscription,
    icon: CreditCardOutlined,
    disabledOffline: true,
  },
  addOns: {
    order: 7,
    path: routes.addOnSettings,
    label: translations.settingsPage.addOns,
    icon: PlusSquareOutlined,
    disabledOffline: true,
    ldFlags: [LDFlagNames.Addons],
  },
  adminSubscription: {
    order: 8,
    path: routes.adminSubscription,
    label: translations.settingsPage.adminSubscription,
    icon: SecurityScanOutlined,
    adminOnly: true,
    disabledOffline: true,
  },
  dataConversion: {
    order: 9,
    path: routes.dataConversion,
    label: translations.settingsPage.dataConversion,
    icon: DatabaseOutlined,
    disabledOffline: true,
  },
  referenceData: {
    order: 10,
    path: routes.referenceData,
    label: translations.settingsPage.referenceData,
    icon: UnorderedListOutlined,
    disabledOffline: true,
  },
  // Temporarily hidden
  /* syncErrorLog: {
    order: 11,
    path: routes.syncErrorLog,
    label: translations.settingsPage.syncErrorLog,
    icon: WarningOutlined,
    disabledOffline: true,
  },*/
  currencySettings: {
    order: 12,
    path: routes.currencySettings,
    label: translations.settingsPage.currencySettings,
    icon: DollarOutlined,
    disabledOffline: true,
  },
};

export const menuKeyPermissionMap: { [key: string]: UserPermissionNameKey } = {
  dashboard: UserPermissionNameKey.PERM_DSB_ALL,
  generalReports: UserPermissionNameKey.PERM_RPT_ALL,
  subscription: UserPermissionNameKey.PERM_SET_SUBSCRIPTION,
  addOns: UserPermissionNameKey.PERM_SET_SUBSCRIPTION,
};
