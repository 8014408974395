import { translations } from '../../constants/translations';
import React, { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Store } from 'antd/lib/form/interface';
import { isMatch } from '../../util/objectComparisons';
import { useGetLedgerNote, useUpsertLedger } from '../../hooks/ajax/ledger/ledgerHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { Loading } from '../Loading/Loading';
import { LedgerUpsertGenerator } from '../../classes/upsertGenerators/LedgerUpsertGenerator';
import { useMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';

interface NoteEditModalProps {
  onClose: () => void;
  title?: string;
  onSuccess?: () => void;
  ledgerId: string;
  successMessage?: string;
}

export interface NoteEditFormFields {
  note?: string;
}

export const datePickerTestId = 'CreditModalDatePicker';

export const NoteEditModal: React.FC<NoteEditModalProps> = ({
  onClose,
  title,
  ledgerId,
  successMessage,
  onSuccess,
}) => {
  const [form] = useForm();
  const [unsavedData, setUnsavedData] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [upsertLedger] = useMutationWithMessages(useUpsertLedger);

  const organizationId = useGetOrganizationIdFromRoute();

  const { ledgerNote, ledgerNoteLoading } = useGetLedgerNote({ ledgerId, organizationId });

  const initialData: NoteEditFormFields = {
    note: ledgerNote?.value,
  };
  const handleValueChange = (_: Store, allValues: Store) => {
    setUnsavedData(!isMatch(null, allValues));
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  const onFinish = async (values: NoteEditFormFields) => {
    setIsSaving(true);
    const ledgerUpsert = LedgerUpsertGenerator.getExistingLedgerUpsertToUpdateNote(ledgerId, values.note);
    await upsertLedger({
      options: {
        variables: {
          organizationId,
          ledger: ledgerUpsert,
        },
      },
      successMessage,
      onSuccess: () => {
        setUnsavedData(false);
        if (onSuccess) {
          onSuccess();
        }
        setIsSaving(false);
        onClose();
      },
    });
  };

  return (
    <ModalWithCloseConfirm
      title={title}
      onCancel={onClose}
      onOk={() => form.submit()}
      open
      isSaving={isSaving}
      changesMade={unsavedData}
      warningMessageOnRouteChange={translations.shared.getUnsavedDataNavigationWarning(translations.noteEditModal.note)}
    >
      {ledgerNoteLoading ? (
        <Loading />
      ) : (
        <Form
          {...layout}
          form={form}
          initialValues={initialData}
          onFinish={onFinish}
          onValuesChange={handleValueChange}
          autoComplete='off'
        >
          <Form.Item name='note' label={translations.noteEditModal.note}>
            <TextArea autoSize={{ maxRows: 8 }} />
          </Form.Item>
        </Form>
      )}
    </ModalWithCloseConfirm>
  );
};
