import React, { useContext, useState } from 'react';
import { TaxonomySpecies } from './TaxonomySpecies';
import { TaxonomyBreeds } from './TaxonomyBreeds';
import { TaxonomyGenders } from './TaxonomyGenders';
import { MappingTabHeader } from '../MappingTabHeader';
import { Button, Tabs } from 'antd';
import { ComponentWithPracticeProps } from '../../../components/WithPractice/WithPractice';
import { translations } from '../../../constants/translations';
import { useAutoMapTaxonomy } from '../../../hooks/ajax/lab/labHooks';
import { taxonomyTranslations } from '../../../util/labUtil';
import { TaxonomyType } from '../../../util/taxonomyUtil';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { ExtraContentSpace } from '../../../globalStyles.style';
import { LabManagementContext } from '../store/state';
import { setChangesMade } from '../store/actions';
import { TabChangeWarning } from '../../../components/TabChangeWarning/TabChangeWarning';
import { useTabChangeWarningProps } from '../../../hooks/tabChangeWarning';

export const labSelectTestId = 'lab-select-test-id';

const { TabPane } = Tabs;

export const TaxonomyTab: React.FC<ComponentWithPracticeProps> = ({ practice }) => {
  const [activeTab, setActiveTab] = useState<string>(TaxonomyType.Species);
  const [autoMapTaxonomy] = useAutoMapTaxonomy();
  const [isSaving, setIsSaving] = useState(false);
  const {
    state: { hasChanges },
    dispatch,
  } = useContext(LabManagementContext);

  const { handleTabClick, tabChangeWarningProps } = useTabChangeWarningProps(hasChanges);

  const handleAutoMap = async (selectedLab: string, organizationId: string) => {
    setIsSaving(true);
    try {
      await autoMapTaxonomy({
        variables: {
          organizationId,
          instruction: {
            lab_device_id: selectedLab as string,
          },
        },
      });
    } catch (e) {
      showErrorMessage(e.message ?? translations.shared.saveErrorMessage);
    }
    setIsSaving(false);
  };

  return (
    <MappingTabHeader
      practice={practice}
      instructions={taxonomyTranslations.instructions}
      render={(selectedLab: string, organizationId: string) => (
        <>
          <Tabs
            activeKey={activeTab}
            type='card'
            onTabClick={(key) =>
              handleTabClick(() => {
                dispatch(setChangesMade(false));
                setActiveTab(key);
              })
            }
            tabBarExtraContent={
              <ExtraContentSpace>
                <Button onClick={() => handleAutoMap(selectedLab, organizationId)}>
                  {taxonomyTranslations.autoMap}
                </Button>
              </ExtraContentSpace>
            }
            destroyInactiveTabPane
          >
            <TabPane tab={taxonomyTranslations.tabs.species.title} key={TaxonomyType.Species}>
              <TaxonomySpecies
                labId={selectedLab as string}
                organizationId={organizationId}
                autoMapLoading={isSaving}
              />
            </TabPane>
            <TabPane tab={taxonomyTranslations.tabs.breeds.title} key={TaxonomyType.Breed}>
              <TaxonomyBreeds labId={selectedLab as string} organizationId={organizationId} autoMapLoading={isSaving} />
            </TabPane>
            <TabPane tab={taxonomyTranslations.tabs.gender.title} key={TaxonomyType.Gender}>
              <TaxonomyGenders
                labId={selectedLab as string}
                organizationId={organizationId}
                autoMapLoading={isSaving}
              />
            </TabPane>
          </Tabs>

          <TabChangeWarning {...tabChangeWarningProps} />
        </>
      )}
    />
  );
};
