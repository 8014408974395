import { Button } from 'antd';
import { translations } from '../../../../constants/translations';
import React, { useContext } from 'react';
import { InvoicesContext } from '../../../Invoices/InvoicesOverview/store/state';
import { ContactLedgerContext } from './store/state';
import { showSelectPatientModalAction } from '../../../Invoices/InvoicesOverview/store/actions';
import { setAccountChargeModalVisible, setCreditModalVisible, setPaymentModalVisible } from './store/actions';
import { useOffline } from '../../../../util/offline/offlineUtil';
import { ExtraContentSpace } from '../../../../globalStyles.style';

export const AddLedgerEntryButtons: React.FC = () => {
  const { enabledAndOffline } = useOffline();
  const { dispatch: invoicesDispatch } = useContext(InvoicesContext);
  const { dispatch: contactLedgerDispatch } = useContext(ContactLedgerContext);
  return !enabledAndOffline ? (
    <ExtraContentSpace>
      <Button key='addInvoice' onClick={() => invoicesDispatch(showSelectPatientModalAction())}>
        {translations.invoicesPage.buttons.addInvoice}
      </Button>
      <Button key='addPayment' onClick={() => contactLedgerDispatch(setPaymentModalVisible(true))}>
        {translations.invoicesPage.buttons.addPayment}
      </Button>
      <Button key='addCredit' onClick={() => contactLedgerDispatch(setCreditModalVisible(true))}>
        {translations.invoicesPage.buttons.addCredit}
      </Button>
      <Button key='addAccountCharge' onClick={() => contactLedgerDispatch(setAccountChargeModalVisible(true))}>
        {translations.invoicesPage.buttons.addAccountCharge}
      </Button>
    </ExtraContentSpace>
  ) : null;
};
