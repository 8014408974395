import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DepositRunStatusId, depositRunStatusIdTranslation, translations } from '../../constants/translations';
import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { useOrganizationContext } from '../../contexts/organization/state';
import {
  useDeleteDepositRunFromQuickBooks,
  useGetDepositRun,
  useSendDepositRunToQuickBooks,
  useUpsertDepositRun,
} from '../../hooks/ajax/depositRun/depositRunHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Loading } from '../../components/Loading/Loading';
import { Container } from '../ReminderRuns/ReminderRuns.styles';
import { Button } from 'antd';
import { SpaceBetweenWrapper } from '../StatementRuns/StatementRunsTabContent.style';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey } from '../../hooks/tableHooks';
import { DepositRun, DepositRunPaymentType, Ledger } from '../../graph/types';
import { displayAsDate, upsertDateFormat } from '../../constants/formats';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { basicDepositRunColumns } from './depositRunColumns';
import { PaymentTypeNameKey, paymentTypeConfigs } from '../../constants/referenceData/paymentReferenceData';
import { TextListWithDots } from '../../components/TextListWithDots/TextListWithDots';
import { isEmpty } from 'lodash';
import {
  CreateDepositRunModal,
  DepositRunFormValues,
} from '../../components/CreateDepositRunModal/CreateDepositRunModal';
import { useQuickBooksAligned } from '../../util/thirdPartyUtil';
import { DropdownButtonWithMenu, MenuItemProps } from '../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { useUpsertQuickBooksAuth } from '../../hooks/ajax/quickBooks/quickBooksHooks';
import { DepositRunDetailsModal } from '../../components/DepositRunDetailsModal/DepositRunDetailsModal';
import useQuickBooksAuth from '../../components/QuickBooksAuth/QuickBooksAuthUtils';
import { getAvailablePaymentTypes } from '../../util/paymentsUtils';
import { QuickBooksAuth } from '../../components/QuickBooksAuth/QuickBooksAuth';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const DepositRuns: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { needsQbAuth, cancelQuickBooksAuth, setNeedsQbAuth, setQbAuthCancelled, qbAuthCancelled } =
    useQuickBooksAuth();
  const [isCloseAndSendAction, setIsCloseAndSendAction] = useState(false);
  const [depositRunToSend, setDepositRunToSend] = useState<DepositRun | undefined>(undefined);
  const [createDepositModalVisible, setCreateDepositModalVisible] = useState(false);
  const [depositRunToShowDetailsModal, setDepositRunToShowDetailsModal] = useState<DepositRun | undefined>(undefined);
  const {
    state: { organization },
  } = useOrganizationContext();

  const [handleFinish, setHandleFinish] = useState(false);

  const organizationPaymentTypes = useMemo(
    () => getAvailablePaymentTypes(organization, organization?.default_practice_id || ''),
    [organization]
  );

  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const organizationId = useGetOrganizationIdFromRoute();
  const { depositRun, depositRunLoading, depositRunRefetch } = useGetDepositRun(
    organizationId,
    organization?.default_practice_id || ''
  );
  const [upsertDepositRun] = useUpsertDepositRun();
  const [sendToQuickBooks] = useSendDepositRunToQuickBooks();
  const [deleteFromQuickbooks] = useDeleteDepositRunFromQuickBooks();

  const quickBooksAuth = useUpsertQuickBooksAuth();
  const { isQuickBooksAligned } = useQuickBooksAligned(organization);

  const renderPaymentTypes = useCallback(
    (paymentTypes: DepositRunPaymentType[]) => {
      const paymentTypeNames = paymentTypes
        ?.map((pt) =>
          organizationPaymentTypes
            ? paymentTypeConfigs[
                (organizationPaymentTypes?.find((opt) => opt.id === pt.payment_type_id) ?? { name_key: '' })
                  ?.name_key as PaymentTypeNameKey
              ]?.text
            : ''
        )
        .filter((ptn) => !isEmpty(ptn));
      if (paymentTypes?.length) {
        return <TextListWithDots textList={paymentTypeNames} />;
      }
      return null;
    },
    [organizationPaymentTypes]
  );

  const handleViewDepositRun = useCallback((depositRun: DepositRun) => setDepositRunToShowDetailsModal(depositRun), []);

  const handleCreateDepositRun = useCallback(
    async (values: DepositRunFormValues, paymentTypeIds: string[]) => {
      setIsSaving(true);
      const response = await upsertDepositRun({
        variables: {
          organizationId,
          depositRun: {
            record: {
              practice_id: organization?.default_practice_id,
              end_date: values.endDate.format(upsertDateFormat),
            },
            paymentType: paymentTypeIds.map((pt) => ({ payment_type_id: pt })),
            statusRecord: {
              status_id: DepositRunStatusId.Pending,
            },
          },
        },
      });

      if (!response.errors) {
        setCreateDepositModalVisible(false);
        await depositRunRefetch();
      }

      setIsSaving(false);
    },
    [depositRunRefetch, organization?.default_practice_id, organizationId, upsertDepositRun]
  );

  const handleUpdateDepositRunStatus = useCallback(
    async (depositRun: DepositRun, statusId: DepositRunStatusId, avoidFinishing = false) => {
      setIsSaving(true);
      await upsertDepositRun({
        variables: {
          organizationId,
          depositRun: {
            id: depositRun.id,
            statusRecord: {
              status_id: statusId,
            },
          },
        },
      });

      if (!avoidFinishing) {
        await depositRunRefetch();
        setIsSaving(false);
      }
    },
    [depositRunRefetch, organizationId, upsertDepositRun]
  );

  const handleDeleteDepositRun = useCallback(
    async (depositRun: DepositRun) => {
      setIsSaving(true);
      if (depositRun.status_id === DepositRunStatusId.Sent) {
        await deleteFromQuickbooks({
          variables: {
            organizationId,
            practiceId: organization?.default_practice_id,
            actionInput: {
              depositRunId: depositRun.id,
            },
          },
        });
      } else {
        await upsertDepositRun({
          variables: {
            organizationId,
            depositRun: {
              id: depositRun.id,
              void: true,
            },
          },
        });
      }

      await depositRunRefetch();
      setIsSaving(false);
    },
    [depositRunRefetch, organizationId, upsertDepositRun, deleteFromQuickbooks, organization]
  );

  const handleCloseDepositRun = useCallback(
    (depositRun: DepositRun, avoidFinishing = false) => {
      handleUpdateDepositRunStatus(depositRun, DepositRunStatusId.Closed, avoidFinishing);
    },
    [handleUpdateDepositRunStatus]
  );

  const handleSendToQuickBooks = useCallback(
    async (depositRun: DepositRun) => {
      setNeedsQbAuth(false);
      setQbAuthCancelled(false);
      setDepositRunToSend(undefined);
      setIsSaving(true);
      const response = await sendToQuickBooks({
        variables: {
          organizationId,
          practiceId: organization?.default_practice_id,
          actionInput: {
            depositRunId: depositRun.id,
          },
        },
      });
      if (response.data?.sendDepositRunQuickbooks?.errorMessage) {
        setIsCloseAndSendAction(false);
        showErrorMessage(response.data?.sendDepositRunQuickbooks?.errorMessage);
      } else if (response.data?.sendDepositRunQuickbooks?.redirectUrl) {
        setNeedsQbAuth(true);
        setDepositRunToSend(depositRun);
      } else if (!response.errors) {
        setIsCloseAndSendAction(false);
        await depositRunRefetch();
      }
      setIsSaving(false);
    },
    [
      depositRunRefetch,
      organization?.default_practice_id,
      organizationId,
      sendToQuickBooks,
      setNeedsQbAuth,
      setQbAuthCancelled,
    ]
  );

  const handleCloseDepositRunAndSendToQuickBooks = useCallback(
    async (depositRun: DepositRun) => {
      setIsCloseAndSendAction(true);
      setIsSaving(true);
      const qbAuthResponse = await quickBooksAuth(organizationId);
      if (qbAuthResponse.redirectUrl) {
        setDepositRunToSend(depositRun);
        setNeedsQbAuth(true);
        setIsSaving(false);
      } else {
        await handleCloseDepositRun(depositRun, true);
        await handleSendToQuickBooks(depositRun);
      }
    },
    [handleCloseDepositRun, handleSendToQuickBooks, organizationId, quickBooksAuth, setNeedsQbAuth]
  );

  const handleReopenDepositRun = useCallback(
    (depositRun: DepositRun) => {
      handleUpdateDepositRunStatus(depositRun, DepositRunStatusId.Pending);
    },
    [handleUpdateDepositRunStatus]
  );

  const handleCloseQbAuthModal = useCallback(() => {
    cancelQuickBooksAuth();
    setDepositRunToSend(undefined);
    setIsCloseAndSendAction(false);
  }, [cancelQuickBooksAuth]);

  const handleFinishQbAuth = useCallback(() => {
    setHandleFinish(true);
  }, []);

  const QuickBooksAuthComponent = useMemo(
    () => (
      <QuickBooksAuth organizationId={organizationId} onClose={handleCloseQbAuthModal} onFinish={handleFinishQbAuth} />
    ),
    [handleCloseQbAuthModal, handleFinishQbAuth, organizationId]
  );

  useEffect(() => {
    if (handleFinish) {
      if (depositRunToSend) {
        if (isCloseAndSendAction) {
          handleCloseDepositRunAndSendToQuickBooks(depositRunToSend);
        } else if (!qbAuthCancelled) {
          handleSendToQuickBooks(depositRunToSend);
        }
      }
      handleCloseQbAuthModal();
    }
    setHandleFinish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFinish, qbAuthCancelled]);

  const renderActionCell = useCallback(
    (depositRun: DepositRun) => {
      const canClose = depositRun.status_id === DepositRunStatusId.Pending;
      const canCloseAndSend = isQuickBooksAligned && depositRun.status_id === DepositRunStatusId.Pending;
      const canSend = isQuickBooksAligned && depositRun.status_id === DepositRunStatusId.Closed;
      const canReopen = depositRun.status_id === DepositRunStatusId.Closed;
      const canDelete = isQuickBooksAligned;

      const buttons: MenuItemProps[] = [
        {
          title: translations.depositRuns.buttons.view,
          onClick: () => handleViewDepositRun(depositRun),
        },
        {
          title: translations.depositRuns.buttons.close,
          disabled: !canClose,
          onClick: () => handleCloseDepositRun(depositRun),
        },
        {
          title: translations.depositRuns.buttons.closeAndSend,
          disabled: !canCloseAndSend,
          onClick: () => handleCloseDepositRunAndSendToQuickBooks(depositRun),
        },
        {
          title: translations.depositRuns.buttons.send,
          disabled: !canSend,
          onClick: () => handleSendToQuickBooks(depositRun),
        },
        {
          title: translations.depositRuns.buttons.reopen,
          disabled: !canReopen,
          onClick: () => handleReopenDepositRun(depositRun),
        },
        {
          title: translations.depositRuns.buttons.delete,
          disabled: !canDelete,
          onClick: () => handleDeleteDepositRun(depositRun),
          popconfirmProps: {
            title: translations.depositRuns.buttons.deleteDepositText,
            okText: translations.shared.popconfirm.ok,
            cancelText: translations.shared.popconfirm.no,
          },
        },
      ];

      return <DropdownButtonWithMenu menuItemProps={buttons} />;
    },
    [
      handleCloseDepositRun,
      handleCloseDepositRunAndSendToQuickBooks,
      handleDeleteDepositRun,
      handleReopenDepositRun,
      handleSendToQuickBooks,
      handleViewDepositRun,
      isQuickBooksAligned,
    ]
  );

  const columns: CustomColumnType<DepositRun>[] = useMemo(
    () => [
      {
        ...basicDepositRunColumns.number,
        dataIndex: 'number',
        width: 90,
      },
      {
        ...basicDepositRunColumns.end_date,
        render: (date: string) => displayAsDate(date, dateFormat),
        width: 90,
        defaultSortOrder: 'descend',
      },
      {
        ...basicDepositRunColumns.paymentType,
        render: (paymentTypes: DepositRunPaymentType[]) => renderPaymentTypes(paymentTypes),
        width: 90,
      },
      {
        ...basicDepositRunColumns.status_id,
        render: (statusId: string) => depositRunStatusIdTranslation(statusId),
        width: 90,
      },
      {
        title: translations.depositRuns.columns.actions,
        key: 'actions',
        render: (depositRun: DepositRun) => renderActionCell(depositRun),
        width: 90,
      },
    ],
    [dateFormat, renderActionCell, renderPaymentTypes]
  );

  const isDateOverlap = useCallback(
    (selectedDate: dayjs.Dayjs): boolean => {
      const hasLaterDate = (deposit: DepositRun) => dayjs.utc(deposit.end_date).isAfter(selectedDate, 'day');
      return !!depositRun && depositRun.some(hasLaterDate);
    },
    [depositRun]
  );

  const handleDeleteDepositDetail = useCallback(
    async (depositRun: DepositRun, ledgerEntry: Ledger) => {
      setIsSaving(true);

      await upsertDepositRun({
        variables: {
          organizationId,
          depositRun: {
            id: depositRun.id,
            fnRemovePaymentLedgerId: [ledgerEntry.id],
          },
        },
      });

      await depositRunRefetch();
      setDepositRunToShowDetailsModal(undefined);
      setIsSaving(false);
    },
    [depositRunRefetch, organizationId, upsertDepositRun]
  );

  const enabledDepositRunsPage = useLDFlag(LDFlagNames.DepositRun);
  if (!enabledDepositRunsPage) {
    return <p>{translations.depositRuns.pageNotEnabled}</p>;
  }

  if (depositRunLoading) {
    return <Loading />;
  }

  return (
    <SaveSpinner isSaving={isSaving || depositRunLoading}>
      <StyledPageHeaderWithMargin title={translations.depositRuns.title} />
      <Container>
        {(!depositRun || depositRun.length === 0) && <p>{translations.depositRuns.instructions}</p>}
        <Button
          disabled={isSaving || depositRunLoading}
          htmlType={'submit'}
          type={'primary'}
          onClick={() => setCreateDepositModalVisible(true)}
        >
          {translations.depositRuns.createDeposit}
        </Button>
      </Container>
      <SpaceBetweenWrapper>
        <TableWithCustomFiltering<DepositRun>
          tableKey={TableKey.DepositRuns}
          columns={columns}
          dataSource={depositRun || undefined}
          rowKey={'id'}
          style={{ width: '100%' }}
        />
      </SpaceBetweenWrapper>
      {createDepositModalVisible && organizationPaymentTypes && (
        <CreateDepositRunModal
          paymentTypes={organizationPaymentTypes}
          isSaving={isSaving}
          isDateOverlap={isDateOverlap}
          onCreate={handleCreateDepositRun}
          onClose={() => setCreateDepositModalVisible(false)}
        />
      )}
      {depositRunToShowDetailsModal && (
        <DepositRunDetailsModal
          depositRun={depositRunToShowDetailsModal}
          onDelete={handleDeleteDepositDetail}
          isSaving={isSaving}
          onClose={() => setDepositRunToShowDetailsModal(undefined)}
        />
      )}
      {needsQbAuth && QuickBooksAuthComponent}
    </SaveSpinner>
  );
};
