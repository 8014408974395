import { Store } from 'antd/lib/form/interface';
import { translations } from '../constants/translations';
import { GetLabRefBreeds, GetLabRefGenders, GetLabRefSpecies } from '../graph/queries/labLink';
import {
  LabBreedMapping,
  LabDevice,
  LabDeviceTaxonomy,
  LabDeviceUpsert,
  LabGenderMapping,
  LabServiceMapping,
  LabSpeciesMapping,
  Query,
} from '../graph/types';
import AppSyncService from '../services/AppSyncService/AppSyncService';
import { OptionsWithSpeciesNumber, SelectOptions } from '../components/DependentDropdown/DependentDropdown';
import { ID_FOR_OBJECT_CREATION } from '../classes/upsertGenerators/commonUpsertConstants';
import { showErrorMessage } from '../components/Notification/notificationUtil';
import { TaxonomyType } from '../util/taxonomyUtil';

export type LabTaxonomyData = {
  cassadolValue: string;
  cassadolValueName: string;
  requiresMap: boolean;
  type: TaxonomyType;
  lab: string;
  labSpeciesNumber?: string | null;
};

export type TaxonomyTabProps = {
  labId: string;
  organizationId: string;
  autoMapLoading: boolean;
};

export type CombinedTaxonomy = LabSpeciesMapping | LabBreedMapping | LabGenderMapping | LabServiceMapping;

export const taxonomyTranslations = translations.labManagementPage.screens.taxonomyMapping;

export const serviceMappingTranslations = translations.labManagementPage.screens.serviceMapping;

export const labTabsHeadingTranslations = translations.labManagementPage.labTabsHeading;

export const getLabDeviceUpsert = (values: Store, labDeviceIds: string[]) => {
  const labDeviceUpserts = labDeviceIds.reduce<LabDeviceUpsert[]>((acc, deviceId) => {
    const labDeviceUpsert: LabDeviceUpsert = {
      id: deviceId,
    };
    Object.keys(values)
      .filter((k) => k.includes('lab_taxonomy') && !k.includes('lab_name'))
      .forEach((key) => {
        const [labId, taxonomyType, cassadolId] = key.split('_');
        if (labId === deviceId) {
          if (taxonomyType === TaxonomyType.Breed) {
            const record = {
              cassadol_breed_id: cassadolId,
              lab_breed_name: values[`${key}_lab_name`],
              lab_breed_number: values[key],
            };
            if (labDeviceUpsert.lab_breed_map) {
              labDeviceUpsert.lab_breed_map.push({ record });
            } else {
              labDeviceUpsert.lab_breed_map = [{ record }];
            }
          }
          if (taxonomyType === TaxonomyType.Gender) {
            const record = {
              cassadol_gender_id: cassadolId,
              lab_gender_name: values[`${key}_lab_name`],
              lab_gender_number: values[key],
            };
            if (labDeviceUpsert.lab_gender_map) {
              labDeviceUpsert.lab_gender_map.push({ record });
            } else {
              labDeviceUpsert.lab_gender_map = [{ record }];
            }
          }
          if (taxonomyType === TaxonomyType.Species) {
            const record = {
              cassadol_species_id: cassadolId,
              lab_species_name: values[`${key}_lab_name`],
              lab_species_number: values[key],
            };
            if (labDeviceUpsert.lab_species_map) {
              labDeviceUpsert.lab_species_map.push({ record });
            } else {
              labDeviceUpsert.lab_species_map = [{ record }];
            }
          }
          if (taxonomyType === TaxonomyType.Service) {
            const record = {
              service_id: cassadolId,
              lab_service_name: values[`${key}_lab_name`],
              lab_service_number: values[key],
            };
            if (labDeviceUpsert.lab_service_map) {
              labDeviceUpsert.lab_service_map.push({ record });
            } else {
              labDeviceUpsert.lab_service_map = [{ record }];
            }
          }
        }
      });

    acc.push(labDeviceUpsert);
    return acc;
  }, []);

  return labDeviceUpserts;
};

export const mapTaxonomyByDevice = (
  taxonomy: LabDeviceTaxonomy[],
  taxonomyByDevice: Record<string, LabTaxonomyData[]>
) => {
  taxonomy.forEach((tax) => {
    const deviceArray = taxonomyByDevice[tax.lab_device_id];

    if (!deviceArray.find(({ cassadolValue }) => cassadolValue === tax.breed_id) && tax.requires_breed_map) {
      deviceArray.push({
        cassadolValue: tax.breed_id ?? '',
        cassadolValueName: `${translations.taxonomyModal.columns.cassadol.breed} ${tax.breed_name ?? ''}`,
        requiresMap: true,
        type: TaxonomyType.Breed,
        lab: tax.lab_device_id,
        labSpeciesNumber: tax.lab_species_number,
      });
    }
    if (!deviceArray.find(({ cassadolValue }) => cassadolValue === tax.gender_id) && tax.requires_gender_map) {
      deviceArray.push({
        cassadolValue: tax.gender_id ?? '',
        cassadolValueName: `${translations.taxonomyModal.columns.cassadol.gender} ${tax.gender_name ?? ''}`,
        requiresMap: true,
        type: TaxonomyType.Gender,
        lab: tax.lab_device_id,
      });
    }
    if (!deviceArray.find(({ cassadolValue }) => cassadolValue === tax.species_id) && tax.requires_species_map) {
      deviceArray.unshift({
        cassadolValue: tax.species_id ?? '',
        cassadolValueName: `${translations.taxonomyModal.columns.cassadol.species} ${tax.species_name ?? ''}`,
        requiresMap: true,
        type: TaxonomyType.Species,
        lab: tax.lab_device_id,
      });
    }
  });
};

export const fillLabOptionsMap = async (
  tableData: LabTaxonomyData[],
  organizationId: string,
  setOptionsMap: (initialOptionsMap: Record<string, Record<string, OptionsWithSpeciesNumber[]>>) => void,
  handleCloseModal: () => void,
  setIsLoading: (isSaving: boolean) => void
) => {
  const handleError = (e: Error) => {
    showErrorMessage(e.message);
    handleCloseModal();
    setIsLoading(false);
  };
  setIsLoading(true);
  let initialOptionsMap: Record<string, Record<string, OptionsWithSpeciesNumber[]>> = {};
  for (const taxonomy of tableData) {
    if (taxonomy.type === TaxonomyType.Species) {
      try {
        const species = await AppSyncService.client.query<Pick<Query, 'getLabRefSpecies'>>({
          query: GetLabRefSpecies,
          variables: { organizationId, labRefFilter: { lab_device_id: taxonomy.lab } },
        });

        initialOptionsMap = {
          ...initialOptionsMap,
          [taxonomy.lab]: {
            ...initialOptionsMap[taxonomy.lab],
            [TaxonomyType.Species]:
              species.data?.getLabRefSpecies?.map(({ lab_species_number, lab_species_name }) => ({
                label: lab_species_name,
                value: lab_species_number,
              })) ?? [],
          },
        };
      } catch (e) {
        handleError(e);
      }
    }
    if (taxonomy.type === TaxonomyType.Breed) {
      try {
        const breeds = await AppSyncService.client.query<Pick<Query, 'getLabRefBreeds'>>({
          query: GetLabRefBreeds,
          variables: { organizationId, labRefFilter: { lab_device_id: taxonomy.lab } },
        });
        const breedOptions =
          breeds.data?.getLabRefBreeds?.map(({ lab_breed_number, lab_breed_name, lab_species_number }) => ({
            label: lab_breed_name,
            value: lab_breed_number,
            speciesnumber: lab_species_number,
          })) ?? [];

        initialOptionsMap = {
          ...initialOptionsMap,
          [taxonomy.lab]: {
            ...initialOptionsMap[taxonomy.lab],
            [TaxonomyType.Breed]: taxonomy.labSpeciesNumber
              ? breedOptions.filter((option) => option.speciesnumber === taxonomy.labSpeciesNumber)
              : breedOptions,
            allBreeds: breedOptions,
          },
        };
      } catch (e) {
        handleError(e);
      }
    }
    if (taxonomy.type === TaxonomyType.Gender) {
      try {
        const genders = await AppSyncService.client.query<Pick<Query, 'getLabRefGenders'>>({
          query: GetLabRefGenders,
          variables: { organizationId, labRefFilter: { lab_device_id: taxonomy.lab } },
        });

        initialOptionsMap = {
          ...initialOptionsMap,
          [taxonomy.lab]: {
            ...initialOptionsMap[taxonomy.lab],
            [TaxonomyType.Gender]:
              genders.data?.getLabRefGenders?.map(({ lab_gender_number, lab_gender_name }) => ({
                label: lab_gender_name,
                value: lab_gender_number,
              })) ?? [],
          },
        };
      } catch (e) {
        handleError(e);
      }
    }
  }
  if (Object.keys(initialOptionsMap).length) {
    setOptionsMap(initialOptionsMap);
  }
  setIsLoading(false);
};

export const getTypeProperties = (type: TaxonomyType) => {
  return {
    labTypeName: `lab_${type}_name`,
    labTypeNumber: `lab_${type}_number` as keyof CombinedTaxonomy,
    cassadolTypeId: `cassadol_${type}_id` as keyof CombinedTaxonomy,
    cassadolTypeName: `cassadol_${type}_name` as keyof CombinedTaxonomy,
    typeMap: `lab_${type}_map`,
  };
};

export const getDeviceUpsert = (
  deviceId: string,
  typeMap: string,
  entry: CombinedTaxonomy,
  cassadolTypeId: string,
  labTypeName: string,
  labTypeNumber: keyof CombinedTaxonomy,
  selectedSpecies: SelectOptions,
  editingId?: string,
  type?: TaxonomyType
): LabDeviceUpsert => ({
  id: deviceId,
  [typeMap]: [
    {
      id: entry.id ?? ID_FOR_OBJECT_CREATION,
      record: {
        lab_device_id: deviceId,
        ...(type === TaxonomyType.Service ? { service_id: editingId } : { [cassadolTypeId]: editingId }),
        [labTypeName]: selectedSpecies.label,
        [labTypeNumber]: selectedSpecies.value,
      },
    },
  ],
});

export const getLabOptions = (labDevices: LabDevice[]) => {
  return labDevices.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
};
