import React from 'react';
import { createRoot } from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { OfflineContextProvider } from './util/offline/store/state';
import { checkConnection } from './util/connectionUtil';
import { UserContextProvider } from './contexts/user/state';

const isInitiallyOnline = checkConnection();
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <OfflineContextProvider state={{ isOnline: isInitiallyOnline }}>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </OfflineContextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
