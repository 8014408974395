import React from 'react';
import ActionButtonOptions from '../../../components/ActionButtonOptions/ActionButtonOptions';
import { StyledPageHeaderDoctorOffice, StyledPageHeaderWrapper } from '../../../components/PageHeader/PageHeader.style';
import { UserOrganizationContactCard } from '../../../graph/types';
import { joinNameAndNumber } from '../../../util/displaying';
import { getCardTags } from '../../../util/tags';
import './DoctorOfficeContactTab.less';

interface DoctorOfficeContactTabHeaderProps {
  contactCard: UserOrganizationContactCard;
  closeEntityCard?: (cardId: string) => Promise<void>;
}

const DoctorOfficeContactTabHeader: React.FC<DoctorOfficeContactTabHeaderProps> = ({
  contactCard,
  closeEntityCard,
}) => {
  const cardTags = contactCard ? getCardTags((contactCard as any)?.contact_type_name_keys) : null;

  return (
    <>
      <StyledPageHeaderWrapper>
        <StyledPageHeaderDoctorOffice
          title={joinNameAndNumber({ name: contactCard.contact_name, number: contactCard.contact_number })}
        />
        {cardTags}
        <ActionButtonOptions contactCard={contactCard} closeEntityCard={closeEntityCard} />
      </StyledPageHeaderWrapper>
    </>
  );
};

export default DoctorOfficeContactTabHeader;
