import { translations } from '../../../constants/translations';
import { ServiceRendered3pHistory } from '../../../graph/types';
import React, { useCallback, useMemo } from 'react';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { useGetHistory, useGetThirdPartyTypes } from '../../../hooks/ajax/thirdPartyData/thirdPartyDataHooks';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { Loading } from '../../../components/Loading/Loading';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { basicHistoryColumns } from './historyColumns';
import { TableKey } from '../../../hooks/tableHooks';
import { stringCompareFunction } from '../../../util/filterAndSorting';
import { getThirdPartyStatusTag, ThirdPartyStatus } from '../../../util/statusUtils';
import {
  ThirdPartyApprovalTypeConfigs,
  ThirdPartyApprovalTypeNameKey,
} from '../../../constants/referenceData/thirdPartyReferenceData';
import {
  DropdownButtonWithMenu,
  MenuItemProps,
} from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { useNavigationToRoute, withInvoiceIdParameter } from '../../../hooks/route/navigationHooks';
import { routes } from '../../../constants/routes';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { displayAsDate } from '../../../constants/formats';

export const HistoryOverview: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { history, historyLoading } = useGetHistory(organizationId);
  const { thirdPartyTypes, loading: thirdPartyLoading } = useGetThirdPartyTypes(organizationId);
  const thirdPartyTypesMap = useMemo(() => new Map(thirdPartyTypes?.map((t) => [t.id, t])), [thirdPartyTypes]);
  const { navigateTo } = useNavigationToRoute();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const thirdPartyFilter = useCallback(
    (value: string | number | boolean, record: ServiceRendered3pHistory) => {
      const matchingTypeIds = thirdPartyTypes
        ?.filter((t) => t?.name_key?.toLowerCase().includes(value.toString().toLowerCase()))
        ?.map((t) => t.id);
      return !!matchingTypeIds?.includes(record?.third_party_id);
    },
    [thirdPartyTypes]
  );

  const statusFilter = useCallback((value: string | number | boolean, record: ServiceRendered3pHistory) => {
    const matchingStatuses = [
      ThirdPartyStatus.NotApproved,
      ThirdPartyStatus.Queued,
      ThirdPartyStatus.Sent,
      ThirdPartyStatus.Error,
    ].filter((s) => ThirdPartyStatus[s]?.toLowerCase().includes(value.toString().toLowerCase()));
    return !!matchingStatuses?.includes(record?.status_id);
  }, []);

  const linkHistoryMenu = (serviceRendered: ServiceRendered3pHistory) => {
    const invoiceActions = [
      {
        title: translations.shared.editButtonText,
        onClick: () => navigateTo(routes.viewInvoice, withInvoiceIdParameter(serviceRendered.invoice_id ?? '')),
      },
    ] as MenuItemProps[];

    return <DropdownButtonWithMenu menuItemProps={invoiceActions} />;
  };

  if (historyLoading || thirdPartyLoading) {
    return <Loading />;
  }

  const columns: CustomColumnType<ServiceRendered3pHistory>[] = [
    {
      ...basicHistoryColumns.third_party,
      render: (_, history: ServiceRendered3pHistory) => thirdPartyTypesMap?.get(history?.third_party_id)?.name_key,
      onFilter: thirdPartyFilter,
      sorter: (a, b) =>
        stringCompareFunction(
          thirdPartyTypesMap?.get(a?.third_party_id)?.name_key,
          thirdPartyTypesMap?.get(b?.third_party_id)?.name_key
        ),
      width: 120,
    },
    {
      ...basicHistoryColumns.date_sent,
      render: (_, history: ServiceRendered3pHistory) => (history?.sent ? displayAsDate(history?.sent, dateFormat) : ''),
      onFilter: (value, record) => !!record?.sent?.split(' ')?.[0]?.includes(value.toString().toLowerCase()),
      width: 120,
    },
    {
      ...basicHistoryColumns.doctor_name,
      width: 200,
    },
    {
      ...basicHistoryColumns.patient_name,
      width: 200,
    },
    {
      ...basicHistoryColumns.info,
      render: (_, record: ServiceRendered3pHistory) => {
        const key = record.info as ThirdPartyApprovalTypeNameKey;
        return <div>{ThirdPartyApprovalTypeConfigs[key] ?? record.info}</div>;
      },
      width: 200,
    },
    {
      ...basicHistoryColumns.service_name,
      width: 200,
    },
    {
      ...basicHistoryColumns.quantity,
      width: 100,
      render: (value) => Number(value).toFixed(2),
    },
    {
      ...basicHistoryColumns.status,
      render: (_, history: ServiceRendered3pHistory) => getThirdPartyStatusTag(history?.status_id),
      onFilter: statusFilter,
      sorter: (a, b) => stringCompareFunction(ThirdPartyStatus[a?.status_id], ThirdPartyStatus[b?.status_id]),
      width: 100,
    },
    {
      title: translations.contactsPage.columns.actions,
      key: 'actions',
      render: (record: ServiceRendered3pHistory) => linkHistoryMenu(record),
    },
  ];

  return (
    <>
      <StyledPageHeader title={translations.historyPage.title} />
      <TableWithCustomFiltering
        tableKey={TableKey.HistoryOverview}
        columns={columns}
        dataSource={history || undefined}
        rowKey={'id'}
      />
    </>
  );
};
