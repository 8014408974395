import { Patient } from '../../graph/types';
import { showErrorMessage } from '../Notification/notificationUtil';
import { translations } from '../../constants/translations';
import { useMemo } from 'react';

export function togglePatient(
  addPatient: boolean,
  patient: Patient,
  allPatients: Patient[],
  maxSelections: number
): Patient[] {
  if (addPatient) {
    const newSelectedPatients = allPatients.concat(patient);
    if (newSelectedPatients.length > maxSelections) {
      showMaxSelectError(maxSelections);
      return allPatients;
    }
    return newSelectedPatients;
  }
  return removePatient(patient, allPatients);
}

export const removePatient = (patient: Patient, allPatients: Patient[]): Patient[] => {
  return allPatients.filter((existing) => existing.id !== patient.id);
};

export const showMaxSelectError = (maxSelections: number) => {
  showErrorMessage(translations.shared.selectPatientModal.exceededSelectionsWarning(maxSelections.toString()));
};

export const selectAll = (
  currentVisiblePatients: Patient[] | undefined,
  selectedPatients: Patient[],
  maxSelections: number
): Patient[] => {
  if (!currentVisiblePatients) {
    return selectedPatients;
  }
  const newPatients = currentVisiblePatients.filter(
    (visiblePatient) => !selectedPatients.find((selectedPatient) => selectedPatient.id === visiblePatient.id)
  );
  if (newPatients.length === 0) {
    return selectedPatients;
  }
  const newSelectedPatients = selectedPatients.concat(newPatients);
  if (newSelectedPatients.length > maxSelections) {
    showMaxSelectError(maxSelections);
    return selectedPatients;
  }
  return newSelectedPatients;
};

export const getFilteredPatients = (patients: Patient[], idToExclude?: string) =>
  patients.filter((patient) => patient.id !== idToExclude);

export const useGetFilteredPatients = (patients: Patient[], idToExclude?: string) =>
  useMemo(() => patients.filter((patient) => patient.id !== idToExclude), [idToExclude, patients]);
