import React from 'react';
import { DatePicker, InputNumber, Form } from 'antd';
import { translations } from '../../constants/translations';
import { getPositiveValueRule, getRequiredRule } from '../../util/forms';
import TextArea from 'antd/lib/input/TextArea';
import { w24 } from '../../globalStyles.style';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { MIN_INPUT_AMOUNT } from '../../constants/referenceData/ledgerReferenceData';
import dayjs from 'dayjs';
import { useUserLocaleData } from '../../hooks/useUserLocale';

export interface LedgerChargeFormFields {
  date: dayjs.Dayjs;
  amount?: number;
  note?: string;
}

export const datePickerTestId = 'CreditModalDatePicker';

interface LedgerChargeFormProps {
  form: FormInstance;
  initialData: LedgerChargeFormFields;
  handleValueChange: (_: Store, values: Store) => void;
  onFinish: (values: LedgerChargeFormFields) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export const LedgerChargeForm = ({ form, initialData, onFinish, handleValueChange }: LedgerChargeFormProps) => {
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  return (
    <Form
      {...layout}
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
      onValuesChange={handleValueChange}
      autoComplete='off'
    >
      <Form.Item
        name='date'
        label={translations.BasicLedgerChargeModal.fields.date}
        rules={[getRequiredRule(translations.BasicLedgerChargeModal.fields.date)]}
        data-testid={datePickerTestId}
      >
        <DatePicker format={dateFormat} disabledDate={(date) => date.isAfter(dayjs())} />
      </Form.Item>
      <Form.Item
        name='amount'
        label={translations.BasicLedgerChargeModal.fields.amount}
        rules={[
          getRequiredRule(translations.BasicLedgerChargeModal.fields.amount),
          getPositiveValueRule(translations.BasicLedgerChargeModal.fields.amount),
        ]}
      >
        <InputNumber min={MIN_INPUT_AMOUNT} precision={2} style={w24} />
      </Form.Item>
      <Form.Item name='note' label={translations.BasicLedgerChargeModal.fields.note}>
        <TextArea autoSize={{ maxRows: 8 }} />
      </Form.Item>
    </Form>
  );
};
