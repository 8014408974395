import React, { useContext, useEffect, useMemo, useState } from 'react';

import { StyledPageHeaderWithMargin } from '../../components/PageHeader/PageHeader.style';
import { translations } from '../../constants/translations';
import { Button, DatePicker, Form, Select, Space, Tabs } from 'antd';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { FormProps } from 'antd/lib/form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { getRequiredRule } from '../../util/forms';
import TabsWithRouting, { TabsWithRoutingProps } from '../../components/TabsWithRouting/TabsWithRouting';
import { getRouteWithOrganizationId, routes } from '../../constants/routes';
import styled from 'styled-components';
import StatementRunsTabContent from './StatementRunsTabContent';
import find from 'lodash/find';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import {
  useDeleteStatementRun,
  useGetStatementRuns,
  useUpsertStatementRun,
} from '../../hooks/ajax/statementRuns/statementRunHooks';
import { showErrorMessage } from '../../components/Notification/notificationUtil';
import { StatementRun } from '../../graph/types';
import { StatementsContext, StatementsContextProvider } from './store/state';
import { setSelectedStatements } from './store/actions';
import { useNavigationToRoute } from '../../hooks/route/navigationHooks';
import { useDeleteMutationWithMessages } from '../../hooks/ajax/generalMutationHooks';
import { FlexContainer } from '../../globalStyles.style';
import { StatementRunConfigModal } from './StatementRunConfigModal';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { upsertDateFormat } from '../../constants/formats';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Container = styled.div`
  padding: 0 1rem;
`;

export interface StatementRunsPracticeDateForm {
  practiceId: string;
  date: dayjs.Dayjs;
  subject: string;
  body: string;
  footer: string;
}

export interface StatementRunsPrintEmailForm {
  method: string;
}

interface TabData {
  practiceId: string;
  date: string;
  key: string;
  statementRunId: string;
}

const StatementRuns: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const [formPracticeAndDate] = Form.useForm<StatementRunsPracticeDateForm>();
  const [upsertStatement] = useUpsertStatementRun();
  const deleteStatement = useDeleteMutationWithMessages(useDeleteStatementRun, organizationId);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  // TODO - should create practiceOptions from a list of practices and label should probably be the name of the practice instead
  const practiceOptions = useMemo(
    () => [{ label: 'Default Practice', value: practiceId, key: practiceId }],
    [practiceId]
  );

  const practiceDateFormInitialValues = {
    practiceId,
    date: dayjs(),
  };

  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { state, dispatch } = useContext(StatementsContext);
  const { navigateTo } = useNavigationToRoute();
  const { statements, statementsLoading } = useGetStatementRuns(organizationId, practiceId);

  const [selectedStatementRun, setSelectedStatementRun] = useState<StatementRun>();

  useEffect(() => {
    formPracticeAndDate.setFieldsValue({ practiceId });
  }, [formPracticeAndDate, practiceId]);

  useEffect(() => {
    setSelectedStatementRun(statements?.[0]);
  }, [statements]);

  const tabData: TabData[] =
    statements?.map((statement) => ({
      practiceId,
      // Don Note: this change needs reviewed in ticket CAS-2794, as changing to UTC will cause the display to be incorrect,
      // however .utc was recently added so I don't want to directly change it.
      date: dayjs.utc(statement.end_date)?.toString(),
      key: statement.id,
      statementRunId: statement.id,
    })) ?? [];

  const statementOverlap = (selectedDate: dayjs.Dayjs): boolean => {
    const hasLaterDate = (statement: StatementRun) => dayjs.utc(statement.end_date).isSameOrAfter(selectedDate, 'day');
    return !!statements && statements.some(hasLaterDate);
  };

  const routePerTabKey: TabsWithRoutingProps['routePerTabKey'] = {};
  tabData.forEach((tabDataItem) => {
    routePerTabKey[tabDataItem.key] = `${routes.statementRunsReport}/${practiceId}/${tabDataItem.key}`;
  });

  const handleNewRunOnFinish: FormProps['onFinish'] = async (value: StatementRunsPracticeDateForm) => {
    setIsSaving(true);
    if (statementOverlap(value.date)) {
      setIsSaving(false);
      showErrorMessage(translations.statementRun.statementOverlap);
      return;
    }
    try {
      const currentDate = value.date.format(upsertDateFormat);
      const response = await upsertStatement({
        variables: {
          organizationId,
          statementRun: {
            record: {
              end_date: currentDate,
              practice_id: value.practiceId,
            },
          },
        },
      });

      if (response) {
        const tabId = response.data?.upsertStatementRun?.id;
        navigateTo(getRouteWithOrganizationId(`${routes.statementRunsReport}/${practiceId}/${tabId}`, organizationId));
      }
    } catch (err) {
      showErrorMessage(err.message ? err.message : err);
    }
    setIsSaving(false);
  };

  const handleDeleteStatementRun = async (statementRunId: string) => {
    setIsSaving(true);
    await deleteStatement({
      entityId: statementRunId,
      successMessage: translations.statementRun.deleteSuccessMessage,
    });
    setIsSaving(false);
  };

  const getTabTitle = (practiceId: string, date: string) => {
    const formattedDate = dayjs.utc(new Date(date)).format(dateFormat);

    if (practiceOptions.length > 1) {
      const practiceName = find(practiceOptions, { value: practiceId })?.label;
      if (practiceName) {
        return `${practiceName} - ${formattedDate}`;
      }
    }
    return formattedDate;
  };

  const handleTabClick = (key: string) => {
    dispatch?.(setSelectedStatements([]));
    navigateTo(routePerTabKey[key] as string);
    const selectedStatement = statements?.find((run) => run.id === key);
    setSelectedStatementRun(selectedStatement);
  };

  return (
    <SaveSpinner isSaving={isSaving || statementsLoading || state.actionLoading}>
      <StyledPageHeaderWithMargin title={translations.statementRun.title} />
      <Container>
        <Form
          form={formPracticeAndDate}
          initialValues={practiceDateFormInitialValues}
          onFinish={handleNewRunOnFinish}
          layout='inline'
          style={{ marginBottom: '0.5rem' }}
          autoComplete='off'
        >
          <FlexContainer>
            {/*TODO - To be introduced later, remove "display: 'none'" then*/}
            <Form.Item
              style={{ display: 'none' }}
              label={translations.statementRun.practice}
              name='practiceId'
              rules={[getRequiredRule(translations.statementRun.selectPractice)]}
            >
              <Select options={practiceOptions} placeholder={translations.statementRun.selectPractice} />
            </Form.Item>
            <Form.Item
              label={translations.statementRun.dateSelectLabel}
              name='date'
              rules={[getRequiredRule(translations.statementRun.selectDate)]}
            >
              <DatePicker
                format={dateFormat}
                placeholder={translations.statementRun.selectDate}
                disabledDate={(date) => date.isAfter(dayjs())}
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button disabled={isSaving} htmlType={'submit'} type={'primary'}>
                  {translations.statementRun.createRun}
                </Button>
                <Button
                  onClick={() => {
                    formPracticeAndDate.resetFields();
                    setModalOpen(true);
                  }}
                >
                  {translations.statementRun.configureButton}
                </Button>
              </Space>
            </Form.Item>
          </FlexContainer>
          <StatementRunConfigModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            organizationId={organizationId}
          />
        </Form>
        {!tabData.length && translations.statementRun.instructions}
        {tabData.length && selectedStatementRun ? (
          <TabsWithRouting
            routePerTabKey={routePerTabKey}
            type='card'
            hideAdd
            forcedRouteIfNoTabSelected={routes.statementRunsReport}
            onTabClick={handleTabClick}
          >
            {tabData.map((tabDataItem, index) => (
              <Tabs.TabPane
                active={index === 0}
                tab={getTabTitle(tabDataItem.practiceId, tabDataItem.date)}
                key={tabDataItem.key}
              >
                <StatementRunsTabContent
                  active={tabDataItem.statementRunId === selectedStatementRun.id}
                  practiceId={tabDataItem.practiceId}
                  statementRunId={tabDataItem.statementRunId}
                  isDeletable={!index}
                  onDelete={handleDeleteStatementRun}
                />
              </Tabs.TabPane>
            ))}
          </TabsWithRouting>
        ) : null}
      </Container>
    </SaveSpinner>
  );
};

const StatementRunsWithContext = () => (
  <StatementsContextProvider>
    <StatementRuns />
  </StatementsContextProvider>
);

export default StatementRunsWithContext;
