import React from 'react';
import { InvoicesContextProvider } from './store/state';
import { InvoicesOverview } from './InvoicesOverview';

export const InvoicesOverviewContainer: React.FC = () => {
  return (
    <InvoicesContextProvider>
      <InvoicesOverview />
    </InvoicesContextProvider>
  );
};
