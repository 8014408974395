import { translations } from '../translations';

export enum PatientContactRelationTypeNameKey {
  Owner = 'CT_OWNER',
  ReferralDoctor = 'CT_REFERRALDOC',
  ReferralHospital = 'CT_REFERRALHOSP',
  Trainer = 'CT_TRAINER',
  Boarding = 'CT_BOARDING',
  Farrier = 'CT_FARRIER',
  ReferredBy = 'CT_REFERREDBY',
  EMR = 'CT_EMR',
  Doctor = 'CT_DOCTOR',
  Staff = 'CT_STAFF',
  FarmManager = 'CT_FARMMGR',
  BroodManager = 'CT_BROODMAREMGR',
  YearlingManager = 'CT_YEARLINGMGR',
  AssistantTrainer = 'CT_ASSISTTRNR',
  Leasee = 'CT_LEASEE',
  Leasor = 'CT_LEASOR',
}

export enum PatientInfoType {
  Microchip = 'PINFO_MICRO',
  Tattoo = 'PINFO_TATOO',
  RabiesTag = 'PINFO_RABIESTAG',
  Text = 'PTEXT_NOTE',
  GVLID = 'PID_GVL',
  RightForeMark = 'PMARK_RIGHTFORE',
  RightHindMark = 'PMARK_RIGHTHIND',
  LeftForeMark = 'PMARK_LEFTFORE',
  LeftHindMark = 'PMARK_LEFTHIND',
  HeadMark = 'PMARK_HEAD',
  OtherMark = 'PMARK_OTHER',
  BrandMark = 'PMARK_BRAND',
  NeckBodyMark = 'PMARK_NECKBODY',
  HISA = 'PINFO_HISAID',
}

export const patientInfoTypeId = {
  [PatientInfoType.Microchip]: '1',
  [PatientInfoType.Text]: '2',
  [PatientInfoType.Tattoo]: '3',
  [PatientInfoType.GVLID]: '4',
  [PatientInfoType.RightForeMark]: '5',
  [PatientInfoType.RightHindMark]: '6',
  [PatientInfoType.LeftForeMark]: '7',
  [PatientInfoType.LeftHindMark]: '8',
  [PatientInfoType.HeadMark]: '9',
  [PatientInfoType.OtherMark]: '10',
  [PatientInfoType.BrandMark]: '11',
  [PatientInfoType.NeckBodyMark]: '12',
  [PatientInfoType.RabiesTag]: '13',
  [PatientInfoType.HISA]: '14',
};

export const ownerRelationTypeId = '1';

export const patientContactRelationTypeConfigs: {
  [nameKey in PatientContactRelationTypeNameKey]: string;
} = {
  [PatientContactRelationTypeNameKey.Owner]: translations.referenceData.contactTypes.owner,
  [PatientContactRelationTypeNameKey.ReferralDoctor]: translations.referenceData.contactTypes.referralDoc,
  [PatientContactRelationTypeNameKey.ReferralHospital]: translations.referenceData.contactTypes.referralHosp,
  [PatientContactRelationTypeNameKey.Trainer]: translations.referenceData.contactTypes.trainer,
  [PatientContactRelationTypeNameKey.Boarding]: translations.referenceData.contactTypes.boarding,
  [PatientContactRelationTypeNameKey.Farrier]: translations.referenceData.contactTypes.farrier,
  [PatientContactRelationTypeNameKey.ReferredBy]: translations.referenceData.contactTypes.referredBy,
  [PatientContactRelationTypeNameKey.EMR]: translations.referenceData.contactTypes.emr,
  [PatientContactRelationTypeNameKey.Doctor]: translations.referenceData.contactTypes.doctor,
  [PatientContactRelationTypeNameKey.Staff]: translations.referenceData.contactTypes.staff,
  [PatientContactRelationTypeNameKey.FarmManager]: translations.referenceData.contactTypes.farmManager,
  [PatientContactRelationTypeNameKey.BroodManager]: translations.referenceData.contactTypes.broodManager,
  [PatientContactRelationTypeNameKey.YearlingManager]: translations.referenceData.contactTypes.yearlingManager,
  [PatientContactRelationTypeNameKey.AssistantTrainer]: translations.referenceData.contactTypes.assistantTrainer,
  [PatientContactRelationTypeNameKey.Leasee]: translations.referenceData.contactTypes.leasee,
  [PatientContactRelationTypeNameKey.Leasor]: translations.referenceData.contactTypes.leasor,
};
