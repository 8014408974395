import { DocumentNode, useMutation } from '@apollo/client';
import {
  GetPractice,
  GetPracticeAddress,
  GetPracticeForOffline,
  GetPracticeSettings,
  GetPracticeWithInfoAndPhone,
  UpsertPractice,
  UpsertPracticeCardConnectSettings,
  UpsertPracticeWithInfoAndPhone,
} from '../../../graph/queries/practices';
import { useGetOrganizationIdFromRoute } from '../../route/routeParameterHooks';
import { useOfflineQuery } from '../useOfflineQuery';
import { useMemo } from 'react';
import { useOfflineErrorSkipMutation, useOfflineErrorSkipQuery } from '../useOfflineErrorSkip';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { Practice } from '../../../graph/types';

export const useUpsertPractice = (withInfoAndPhone = false, onUpdatePractice?: (practice: Practice) => void) => {
  return useMutation(withInfoAndPhone ? UpsertPracticeWithInfoAndPhone : UpsertPractice, {
    update: (_cache, result, _context) =>
      result?.data?.upsertPractice && onUpdatePractice?.(result.data.upsertPractice),
  });
};

export const useGetPractice = (
  organizationId: string,
  practiceId: string,
  { withInfoAndPhone = false, query }: { withInfoAndPhone?: boolean; query?: DocumentNode } = {}
) => {
  const selector = useMemo(() => ({ _id: practiceId }), [practiceId]);
  const { data, loading, error } = useOfflineQuery<'getPractice'>({
    query: query ?? (withInfoAndPhone ? GetPracticeWithInfoAndPhone : GetPractice),
    collection: 'practice',
    options: {
      variables: { organizationId, practiceId },
      skip: practiceId === '',
    },
    selector,
  });
  return {
    practice: data,
    practiceLoading: loading,
    practiceError: error,
  };
};

export const useGetPracticeWithSettings = (organizationId: string, practiceId: string) => {
  const selector = useMemo(() => ({ _id: practiceId }), [practiceId]);

  const { data, loading, error, refetch } = useOfflineQuery<'getPractice'>({
    query: GetPracticeSettings,
    collection: 'practice',
    options: { variables: { organizationId, practiceId }, skip: practiceId === '' },
    selector,
  });
  return {
    practice: data,
    practiceLoading: loading,
    practiceError: error,
    refetch,
  };
};

export const useUpsertPracticeCardConnectSettings = () => {
  return useOfflineErrorSkipMutation(UpsertPracticeCardConnectSettings);
};

export const useDefaultPracticeId = () => {
  // TODO remove all usages of this hook
  const {
    state: { organization },
  } = useOrganizationContext();

  return organization?.default_practice_id ?? '';
};

export const useGetPracticeForOffline = (organizationId?: string, practiceId?: string) => {
  const { data, loading } = useOfflineErrorSkipQuery<'getPractice'>(GetPracticeForOffline, {
    variables: { organizationId, practiceId },
    skip: !organizationId || !practiceId,
  });
  return {
    practice: data?.getPractice,
    practiceLoading: loading,
  };
};

export const useGetPracticeAddress = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();

  return useGetPractice(organizationId, practiceId, { query: GetPracticeAddress });
};
