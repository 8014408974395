import React, { useEffect, useState } from 'react';
import { Contact, ContactCommunicationLog } from '../../../../graph/types';
import { Empty, Space } from 'antd';
import { useGetOrganizationIdFromRoute } from '../../../../hooks/route/routeParameterHooks';
import { useGetContactCommunicationLog } from '../../../../hooks/ajax/contact/contactHooks';
import { Loading } from '../../../../components/Loading/Loading';
import dayjs from 'dayjs';
import { StyledPageHeader } from '../../../../components/PageHeader/PageHeader.style';
import { TitleWithSearchBox } from '../../../../components/TitleWithSearchBox/TitleWithSearchBox';
import { translations } from '../../../../constants/translations';
import { CommunicationLogCard } from './CommunicationLogCard';

type ContactCommunicationProps = {
  contact: Contact;
};

export const ContactCommunications: React.FC<ContactCommunicationProps> = ({ contact }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { communicationLog, communicationLogLoading } = useGetContactCommunicationLog({
    contactId: contact.id,
    organizationId,
  });
  const [searchValue, setSearchValue] = useState<string>();
  const [filteredLogs, setFilteredLogs] = useState<ContactCommunicationLog[]>();

  const handleOnClear = (key?: string) => {
    if (!key) {
      setSearchValue('');
    }
  };

  useEffect(() => {
    if (!communicationLog) {
      setFilteredLogs([]);
      return;
    }

    const searchLowered = searchValue?.toLowerCase();
    const filteredLogs = !searchLowered
      ? [...communicationLog]
      : communicationLog?.filter(
          (log) =>
            log.subject?.toLowerCase().includes(searchLowered) ||
            log.body?.toLowerCase().includes(searchLowered) ||
            log.recipients?.toLowerCase().includes(searchLowered) ||
            log.cc?.toLowerCase().includes(searchLowered) ||
            log.bcc?.toLowerCase().includes(searchLowered) ||
            log.message_sent?.toLowerCase().includes(searchLowered)
        );

    const filteredWithoutDate = filteredLogs.filter((log) => !log.message_sent);
    const filteredWithDateAndSorted = filteredLogs
      .filter((log) => log.message_sent)
      .sort((a, b) => {
        return dayjs(a.message_sent).isBefore(dayjs(b.message_sent)) ? 1 : -1;
      });

    setFilteredLogs([...filteredWithDateAndSorted, ...filteredWithoutDate]);
  }, [searchValue, communicationLog]);

  if (communicationLogLoading) {
    return <Loading />;
  }

  return (
    <>
      <StyledPageHeader
        title={
          <TitleWithSearchBox
            title={translations.viewContactPage.communicationLog.searchLabel}
            searchBoxPlaceholder={translations.viewContactPage.communicationLog.searchPlaceholder}
            onSearchValueChange={(event) => setSearchValue(event.target.value)}
            onClear={handleOnClear}
          />
        }
      />

      {!filteredLogs?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      {!!filteredLogs?.length && (
        <Space direction='vertical' size='small' style={{ display: 'flex' }}>
          {filteredLogs.map((log) => (
            <CommunicationLogCard log={log} key={log.id} />
          ))}
        </Space>
      )}
    </>
  );
};
