import { ColumnType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import React from 'react';
import { CustomColumnType } from '../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { ColumnFilterItem, CompareFn } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { isNil, toNumber } from 'lodash';
import {
  getNumberAndOperatorValue,
  NumberDropdownValue,
} from '../components/TableWithCustomFiltering/tableWithCustomFilteringUtil';
import { getTag } from './tags';
import { PaymentTypeNameKey, paymentTypeConfig } from '../constants/referenceData/paymentReferenceData';

type ValueType = string | number | boolean;

export const customFilterIcon = (filtered: boolean) => (
  <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: 'medium' }} />
);

export const generalFilteringAndSortingSettings: ColumnType<any> = {
  filterIcon: customFilterIcon,
  sortDirections: ['ascend', 'descend'],
};

export function filterFunction(value: ValueType, record: ValueType | null | undefined) {
  return !!record?.toString().toLowerCase().includes(value.toString().toLowerCase());
}

export function filterFunctionForNumber(
  value: string,
  record: ValueType | null | undefined,
  reverseNegativeValues = false
) {
  const parsedValue = getNumberAndOperatorValue(value);
  if (parsedValue.value === '') {
    return true;
  }
  const recordNumber = reverseNegativeValues ? -toNumber(record) : toNumber(record);
  const valueNumber = toNumber(parsedValue.value);
  switch (parsedValue.operatorVal) {
    case NumberDropdownValue.greaterThan:
      return recordNumber > valueNumber;
    case NumberDropdownValue.lessThan:
      return recordNumber < valueNumber;
    default:
      return recordNumber === valueNumber;
  }
}

export function getOnFilterFunctionFor<T extends Record<any, any>>(
  key: keyof T,
  isForNumber = false,
  reverseNegativeValues = false
) {
  return (value: string | number | boolean, record: T) =>
    isForNumber
      ? filterFunctionForNumber(value as string, record[key], reverseNegativeValues)
      : filterFunction(value, record[key]);
}

export function stringCompareFunction(a: ValueType | null | undefined, b: ValueType | null | undefined) {
  if (isNil(b)) {
    return -1;
  }

  if (isNil(a)) {
    return 1;
  }

  return (a.toString() || '').localeCompare(b.toString() || '');
}

export function getStringCompareFunctionFor<T extends Record<any, any>>(key: keyof T) {
  return (a: T, b: T) => stringCompareFunction(a[key], b[key]);
}

export function getNumberCompareFunctionFor<T extends Record<any, any>>(key: keyof T) {
  return (a: T, b: T) => a[key] - b[key];
}

export function getIdNumberCompareFunctionFor<T extends Record<any, any>>(key: keyof T) {
  return (a: T, b: T) =>
    Number.parseInt(a[key]?.toString().substr(1) || 0) - Number.parseInt(b[key]?.toString().substr(1) || 0);
}

export function getDateCompareFunctionFor<T extends Record<any, any>>(key: keyof T) {
  return (a: T, b: T) => {
    const firstDate = dayjs(a[key]);
    const secondDate = dayjs(b[key]);
    if (firstDate.isSame(secondDate)) {
      return 0;
    }
    return firstDate.isAfter(secondDate) ? 1 : -1;
  };
}

function getColumnsWithSorterFunction<T>(columns: CustomColumnType<T>[]) {
  return columns.filter((column) => typeof column.sorter === 'function');
}

export function addSortingPriorityTo<T>(columns: CustomColumnType<T>[]) {
  const columnsWithSorter = getColumnsWithSorterFunction(columns);
  for (let i = 0; i < columnsWithSorter.length; ++i) {
    columnsWithSorter[i].sorter = {
      compare: columnsWithSorter[i].sorter as CompareFn<T>,
      multiple: columnsWithSorter.length - i,
    };
  }
}

type ItemWithSortOrder = { sort_order: number };
export const SORT_ORDER_STEP = 10000;
export const moveItemsWithSortOrder = (items: ItemWithSortOrder[], oldIndex: number, newIndex: number) => {
  // make items mutable
  items = items.map((obj) => Object.assign({}, obj));

  const item = items[oldIndex];
  items.splice(oldIndex, 1);

  if (newIndex === 0) {
    const newSortOrder = items[0]?.sort_order ?? 0;
    item.sort_order = newSortOrder + SORT_ORDER_STEP;
    return [item, ...items];
  }

  if (newIndex === items.length) {
    const newSortOrder = items[items.length - 1]?.sort_order ?? 0;
    item.sort_order = newSortOrder - SORT_ORDER_STEP;
    return [...items, item];
  }

  const newSortOrder = items[newIndex - 1].sort_order - items[newIndex].sort_order;
  if (newSortOrder > 1) {
    item.sort_order = items[newIndex].sort_order + Math.floor(newSortOrder / 2);
    return [...items.slice(0, newIndex), item, ...items.slice(newIndex, items.length)];
  }

  // reset all sort_order properties
  items.splice(newIndex, 0, item);
  let sortOrder = SORT_ORDER_STEP;
  for (let i = items.length - 1; i >= 0; i--) {
    items[i].sort_order = sortOrder;
    sortOrder += SORT_ORDER_STEP;
  }
  return items;
};

export const sortBySortOrder = (a: ItemWithSortOrder, b: ItemWithSortOrder) => b.sort_order - a.sort_order;

export interface StatusConfig {
  text: string;
  color: string;
}

export function getStatusTag(statusId: string | number, config: { [key: string]: StatusConfig }) {
  const status = config[statusId];
  return status ? getTag(status.color, status.text, statusId) : undefined;
}

export function getStatusFilters(statusConfig: { [key: string]: StatusConfig }): ColumnFilterItem[] {
  return Object.entries(statusConfig).map(([nameKey, statusConfig]) => ({
    text: statusConfig.text,
    value: nameKey,
  }));
}

export function getPaymentTypesFilters(paymentTypesConfig: { [nameKey in PaymentTypeNameKey]: paymentTypeConfig }) {
  return Object.entries(paymentTypesConfig).map(([nameKey, paymentTypeConfig]) => ({
    text: paymentTypeConfig.text,
    value: nameKey,
  }));
}
