import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { EmailModalForPatientHistory } from '../../../components/EmailModal/EmailModalForPatientHistory';
import { JobStatus } from '../../../components/JobStatus/JobStatus';
import { Loading } from '../../../components/Loading/Loading';
import { MergePatients } from '../../../components/MergePatients/MergePatients';
import { showErrorMessage, showSuccessMessage } from '../../../components/Notification/notificationUtil';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';
import TabsWithRouting from '../../../components/TabsWithRouting/TabsWithRouting';
import { setSaveSpinnerAction, setTextConfirmAction } from '../../../components/UtilsProvider/store/actions';
import { UtilsContext } from '../../../components/UtilsProvider/store/state';
import { ViewSubscriptionContext } from '../../../components/ViewSubscription/store/state';
import { InvoiceStatusId } from '../../../constants/referenceData/invoiceReferenceData';
import { getRouteWithOrganizationId, organizationIdParameter, routes } from '../../../constants/routes';
import { PatientDeleteNameKeyTranslation, translations } from '../../../constants/translations';
import { SyncPatient } from '../../../graph/queries/patients';
import {
  Invoice,
  Job,
  JobUpsert,
  Patient,
  PatientInvoiceOwnershipIssue,
  UserOrganizationPatientCard,
} from '../../../graph/types';
import { useDeleteMutationWithMessages } from '../../../hooks/ajax/generalMutationHooks';
import { useGetInvoicesForPatient } from '../../../hooks/ajax/invoice/invoiceHooks';
import { useUpsertJob } from '../../../hooks/ajax/job/jobHooks';
import { useDeletePatient, useGetPatient } from '../../../hooks/ajax/patients/patientHooks';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { getJobRecordUpsert } from '../../../util/jobUtil';
import { AddInvoiceModal } from '../../Invoices/AddInvoice/AddInvoiceModal';
import { closeModalsAction, showAddInvoiceModalAction } from '../../Invoices/InvoicesOverview/store/actions';
import { selectShowAddInvoiceModal } from '../../Invoices/InvoicesOverview/store/selectors';
import { InvoicesContext } from '../../Invoices/InvoicesOverview/store/state';
import { ModalTypes, usePatientSubscription } from '../../Patients/patientUtils';
import { PrintPatientHistoryReportModal } from '../../Patients/PrintPatientHistoryModal';
import { InvoiceSync } from '../../Patients/ViewPatient/PatientOwnership/InvoiceSync/InvoiceSync';
import { getPatientCardRouteWithFilledInPatientIdParameter } from '../doctorOfficeUtil';
import DoctorOfficePatientHeader from './DoctorOfficePatientHeader/DoctorOfficePatientHeader';
import './DoctorOfficePatientTab.less';
import {
  getOnClickPerTabKey,
  getPatientCardFromPatient,
  getRoutePerTabKey,
  getRouteToInvoiceTab,
  invoicesSubRoute,
  useGetTabs,
} from './doctorOfficePatientTabUtil';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { EmailModalForPatientVaccine } from '../../../components/EmailModal/EmailModalForPatientVaccine';
import { useAlerts } from '../../../hooks/alertHooks';

const { TabPane } = Tabs;

interface DoctorOfficePatientTabProps {
  patientCard: UserOrganizationPatientCard;
  closeEntityCard?: (cardId: string) => Promise<void>;
}

const DoctorOfficePatientContent: React.FC<
  DoctorOfficePatientTabProps & {
    cardRoute: string;
    patient: Patient;
    patientLoading: boolean;
    patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
  }
> = ({ patientCard, cardRoute, closeEntityCard, patient, patientLoading, patientRefetch }) => {
  const { state, dispatch: dispatchInvoice } = useContext(InvoicesContext);
  const { dispatch: dispatchUtils } = useContext(UtilsContext);
  const history = useHistory();
  const {
    state: { organization },
  } = useOrganizationContext();

  const { showAlertModal, renderPatientsAlertsModal } = useAlerts('prompt_patient', patient, organization);

  const [isModalOpen, setIsModalOpen] = useState<ModalTypes | undefined>(
    showAlertModal ? ModalTypes.PatientAlerts : undefined
  );
  const [patientsMergeVisible, setPatientsMergeVisible] = useState<boolean>(false);

  const organizationId = useGetOrganizationIdFromRoute();

  const { invoices, invoicesLoading, refetchInvoices } = useGetInvoicesForPatient(
    organizationId,
    patientCard.patient_id,
    InvoiceStatusId.Closed
  );
  const [invoiceIssues, setInvoiceIssues] = useState<PatientInvoiceOwnershipIssue[]>();
  const [jobId, setJobId] = useState<string>();
  const [syncLoading, setSyncLoading] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const deletePatient = useDeleteMutationWithMessages(useDeletePatient, organizationId);
  const onNewInvoice = () => dispatchInvoice(showAddInvoiceModalAction());
  const [upsertJob] = useUpsertJob();
  const {
    state: { renderKey },
  } = useContext(ViewSubscriptionContext);
  const tabs = useGetTabs(
    organization,
    patient,
    refetchInvoices,
    invoices,
    cardRoute,
    onNewInvoice,
    setInvoiceIssues,
    () => setIsModalOpen(ModalTypes.SyncPatient),
    renderKey,
    patientLoading,
    patientRefetch
  );

  const [shouldDisplaySyncPatientModal, setShouldDisplaySyncPatientModal] = useState(false);

  const [isGeneralSync, setIsGeneralSync] = useState(false);

  useEffect(() => {
    if (shouldDisplaySyncPatientModal) {
      setIsModalOpen(ModalTypes.SyncPatient);
    }
  }, [shouldDisplaySyncPatientModal]);

  if (invoicesLoading) {
    return <Loading height={'60vh'} />;
  }

  if (!organization) {
    return <p>{translations.shared.missingOrganization}</p>;
  }

  const invoicesRedirectRoute = getRouteWithOrganizationId(
    invoices?.[0] ? `${cardRoute}${invoicesSubRoute}/${invoices[0].id}` : cardRoute,
    organizationId
  );

  const handleModalClose = () => {
    setIsModalOpen(undefined);
    setShouldDisplaySyncPatientModal(false);
  };

  const handlePatientDelete = async () => {
    await closeEntityCard?.(patientCard.id);
    dispatchUtils(setSaveSpinnerAction({ isSaving: true }));
    await deletePatient({
      entityId: patient.id,
      successMessage: translations.patientsPage.deleteSuccessMessage,
      onSuccess: () => history.push(routes.doctorOffice.replace(organizationIdParameter, organizationId)),
      onError: (err: any) => showErrorMessage(PatientDeleteNameKeyTranslation((err as any).message)),
    });
    dispatchUtils(setSaveSpinnerAction({ isSaving: false }));
  };

  const handlePatientDeleteSelect = () => {
    dispatchUtils(
      setTextConfirmAction({
        show: true,
        onConfirm: handlePatientDelete,
        title: translations.patientPage.deletePatientModalConfirmationTitle,
        description: translations.patientPage.deletePatientConfirm(patient.name ?? undefined),
      })
    );
  };

  const handlePatientSync = async () => {
    const selectedInvoiceIds = selectedInvoices.map(({ id }) => id);
    const variables = {
      organizationId,
      patient: { id: patient.id, fnInvoiceId: selectedInvoiceIds },
    };
    const jobRecord = getJobRecordUpsert(SyncPatient, variables);
    const job: JobUpsert = {
      record: jobRecord,
    };
    setSyncLoading(true);
    try {
      const { data } = await upsertJob({
        variables: {
          organizationId,
          job,
        },
      });

      setJobId(data?.upsertJob?.id);
      setIsGeneralSync(false);
    } catch (e) {
      showErrorMessage(e.message);
    }
    setSyncLoading(false);
  };

  const handleJobFinish = async ({ result }: Job) => {
    if (result && isGeneralSync) {
      const invoiceIssues = await JSON.parse(result).invoice_issue;
      if (invoiceIssues?.length) {
        setInvoiceIssues(invoiceIssues as PatientInvoiceOwnershipIssue[]);
        setShouldDisplaySyncPatientModal(true);
      }
      setSyncLoading(false);
    }
  };

  const closeJobStatusModal = () => {
    setJobId(undefined);
    setIsModalOpen(undefined);
    showSuccessMessage(translations.shared.saveSuccessMessage);
  };

  return (
    <SaveSpinner isSaving={syncLoading}>
      <Switch>
        <Route exact path={`${cardRoute}${invoicesSubRoute}`}>
          <Redirect to={invoicesRedirectRoute} />
        </Route>
        <Route path={cardRoute}>
          <DoctorOfficePatientHeader
            patientCard={patientCard}
            setIsModalOpen={setIsModalOpen}
            handlePatientDelete={handlePatientDeleteSelect}
            handlePatientMerge={() => setPatientsMergeVisible(true)}
            patient={patient}
            setLoading={setSyncLoading}
            setJobId={setJobId}
            setIsGeneralSync={setIsGeneralSync}
          />
          <TabsWithRouting
            className={'DoctorOfficePatientSubTabs'}
            tabPosition={'left'}
            routePerTabKey={getRoutePerTabKey(tabs)}
            onClickPerTabKey={getOnClickPerTabKey(tabs)}
            forcedRouteIfNoTabSelected={{ path: cardRoute, exact: true }}
            tabBarStyle={{ width: 150 }}
          >
            {tabs.map((tab) => (
              <TabPane tab={tab.title} key={tab.key}>
                {tab.content}
              </TabPane>
            ))}
          </TabsWithRouting>
        </Route>
      </Switch>
      {selectShowAddInvoiceModal(state) && (
        <AddInvoiceModal
          visible
          closeModal={() => dispatchInvoice(closeModalsAction())}
          onSuccess={refetchInvoices}
          selectedPatient={patient}
          redirectionRoute={(invoiceId: string) => getRouteToInvoiceTab(cardRoute, invoiceId)}
          organization={organization}
        />
      )}
      {isModalOpen === ModalTypes.EmailReport && (
        <EmailModalForPatientHistory onClose={handleModalClose} patient={patient} />
      )}
      {isModalOpen === ModalTypes.PrintReport && (
        <PrintPatientHistoryReportModal patient={patient} onClose={handleModalClose} />
      )}
      {isModalOpen === ModalTypes.SyncPatient && invoiceIssues && (
        <InvoiceSync
          invoiceIssues={invoiceIssues}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
          isLoading={syncLoading}
          closeModal={handleModalClose}
          syncInvoices={handlePatientSync}
        />
      )}
      {isModalOpen === ModalTypes.EmailVaccineReport && (
        <EmailModalForPatientVaccine patient={patient} onClose={handleModalClose} />
      )}
      {jobId && (
        <JobStatus
          jobId={jobId}
          onError={showErrorMessage}
          closeModal={closeJobStatusModal}
          onCompletion={handleJobFinish}
        />
      )}
      <MergePatients visible={patientsMergeVisible} patient={patient} onClose={() => setPatientsMergeVisible(false)} />
      {renderPatientsAlertsModal()}
    </SaveSpinner>
  );
};

export const DoctorOfficePatientTab: React.FC<DoctorOfficePatientTabProps> = ({ patientCard, closeEntityCard }) => {
  const patientCardRoute = getPatientCardRouteWithFilledInPatientIdParameter(patientCard);
  const organizationId = useGetOrganizationIdFromRoute();
  const { patient, patientLoading, patientRefetch } = useGetPatient(patientCard.patient_id, organizationId);
  usePatientSubscription(patient, patientRefetch);

  if (patientLoading) {
    return <Loading height={'60vh'} />;
  }

  if (!patient) {
    return <p>{translations.patientPage.unknownPatient}</p>;
  }

  return (
    <DoctorOfficePatientContent
      patientCard={patientCard}
      cardRoute={patientCardRoute}
      closeEntityCard={closeEntityCard}
      patient={patient}
      patientLoading={!!patientLoading}
      patientRefetch={patientRefetch}
    />
  );
};

interface DoctorOfficePatientTabForContactProps {
  contactCardRoute: string;
  patient: Patient;
  patientLoading: boolean;
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined;
}

export const DoctorOfficePatientTabForContact: React.FC<DoctorOfficePatientTabForContactProps> = ({
  contactCardRoute,
  patient,
  patientLoading,
  patientRefetch,
}) => {
  return (
    <DoctorOfficePatientContent
      patientCard={getPatientCardFromPatient(patient)}
      cardRoute={contactCardRoute}
      patient={patient}
      patientLoading={patientLoading}
      patientRefetch={patientRefetch}
    />
  );
};
