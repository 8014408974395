import { Phone } from '../../graph/types';
import { getEventListeners } from '../../event';

export enum PhoneTableEventTypes {
  INPUT_ERROR = 'PHONE_TABLE_EVENT_INPUT_ERROR',
}

export type PhoneTableInputErrorArg = { inputName: keyof Pick<Phone, 'phone'>; message: string };

export const PhoneTableEvent = {
  inputError: getEventListeners<PhoneTableInputErrorArg>(PhoneTableEventTypes.INPUT_ERROR),
};
