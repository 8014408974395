import React, { useMemo, useState } from 'react';
import { Button, Progress, Upload } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { translations } from '../../constants/translations';
import { FileData, getUploadRequestToS3 } from '../../util/fileUpload';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import styled from 'styled-components';
import { RcFile } from 'antd/es/upload';

interface FileUploadProps {
  organizationId: string;
  title?: string;
  onRemove?: (filePointer: string) => void;
  onUpload: (file: FileData) => void;
  multiple?: boolean;
  filePath?: string;
  maxFileSize?: number;
  showProgress?: boolean;
  accept?: string;
}

interface UploadFileWithPointer {
  pointer: string;
  file: UploadFile;
}

const UploadWithoutMargin = styled(Upload)`
  display: flex;

  .ant-upload-list-item {
    margin-top: 0;
  }
`;

export const FileUpload: React.FC<FileUploadProps> = ({
  organizationId,
  title,
  onRemove,
  onUpload,
  multiple,
  filePath,
  maxFileSize,
  showProgress,
  accept,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFileWithPointer[]>([]);
  const UploadComponent = useMemo(() => {
    return multiple ? Upload : UploadWithoutMargin;
  }, [multiple]);

  const handleRemove = (file: UploadFile) => {
    const fileToDelete = uploadedFiles.find((uploadedFile) => uploadedFile.file.uid === file.uid);
    if (!fileToDelete) {
      return;
    }
    setUploadedFiles(uploadedFiles.filter((uploadedFile) => uploadedFile.file.uid !== file.uid));
    onRemove?.(fileToDelete.pointer);
  };

  const handleUpload = (uploadedFile: UploadFile) => (file: FileData) => {
    setUploadedFiles([
      ...(multiple ? uploadedFiles : []),
      {
        file: uploadedFile,
        pointer: file.pointer,
      },
    ]);
    setIsUploading(false);
    onUpload?.(file);
  };

  const handleCustomRequest = ({ file, ...options }: RcCustomRequestOptions) => {
    getUploadRequestToS3(
      organizationId,
      setIsUploading,
      handleUpload(file as RcFile),
      maxFileSize ?? 100,
      filePath ?? '',
      setPercentage
    )({ file, ...options });
  };

  return (
    <UploadComponent
      onRemove={handleRemove}
      customRequest={handleCustomRequest}
      fileList={uploadedFiles.map((f) => f.file)}
      accept={accept}
    >
      {!multiple && uploadedFiles.length > 0 ? null : (
        <Button icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />} disabled={isUploading}>
          {title ?? translations.emailModal.fields.attachments.label}
        </Button>
      )}
      {showProgress && isUploading && <Progress percent={Math.round(percentage * 100)} status='active' />}
    </UploadComponent>
  );
};
