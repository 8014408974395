import React, { PropsWithChildren, useState } from 'react';
import { Button, Form } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Dictionary } from 'lodash';
import { translations } from '../../../constants/translations';
import {
  NewPasswordFormItems,
  newPasswordTemplateName,
} from '../../../components/NewPasswordFormItems/NewPasswordFormItems';
import { TOTAL_NUMBER_OF_COLUMNS } from '../../../constants/layout';
import { SaveSpinner } from '../../../components/SaveSpinner/SaveSpinner';

const labelWidth = 9;
const formLayout = {
  labelCol: { span: labelWidth },
  wrapperCol: { span: TOTAL_NUMBER_OF_COLUMNS - labelWidth },
};
const submitButtonLayout = {
  wrapperCol: { offset: labelWidth, span: TOTAL_NUMBER_OF_COLUMNS - labelWidth },
};

interface NewPasswordFormProps extends PropsWithChildren<unknown> {
  onSubmit: (newPassword: string) => Promise<void>;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: Dictionary<string>) => {
    try {
      setIsLoading(true);
      const newPassword = values[newPasswordTemplateName];
      await onSubmit(newPassword);
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage(translations.login.generalErrorMessage);
      }
    }
  };

  return (
    <SaveSpinner isSaving={isLoading} savingMessage={translations.login.loadingMessage}>
      <PageHeader title={translations.login.newPasswordTitle} style={{ paddingLeft: 0 }} />
      <Form {...formLayout} onFinish={onFinish} validateTrigger='onBlur'>
        <NewPasswordFormItems />
        <Form.Item {...submitButtonLayout} validateStatus={'error'} help={errorMessage}>
          <Button type='primary' htmlType='submit'>
            {translations.login.newPasswordButtonText}
          </Button>
        </Form.Item>
      </Form>
    </SaveSpinner>
  );
};
