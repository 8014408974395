import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AddService } from './AddService/AddService';
import { ServicesOverview } from './ServicesOverview/ServicesOverview';
import { routes } from '../../constants/routes';
import { ViewService } from './ViewService/ViewService';
import { WithPractice } from '../../components/WithPractice/WithPractice';
import { ServicesContextProvider } from './store/state';

export const Services: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route path={routes.addService}>
          <ServicesContextProvider>
            <WithPractice Component={AddService} />
          </ServicesContextProvider>
        </Route>
        <Route path={routes.viewService}>
          <ServicesContextProvider>
            <WithPractice Component={ViewService} />
          </ServicesContextProvider>
        </Route>
        <Route path={`${match.path}`} component={ServicesOverview} />
      </Switch>
    </>
  );
};
