import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from '../../constants/translations';
import { refreshLastActive } from '../../hooks/useIdleTimer';
import { getRequiredRule } from '../../util/forms';
import { SignInContainer } from '../SignIn/SignInContainer';
import { useOffline } from '../../util/offline/offlineUtil';
import { useGetCurrentUser } from '../../hooks/ajax/user/userHooks';
import { Loading } from '../../components/Loading/Loading';

interface PinFields {
  pin: string;
}

type PinLocationState = {
  from: Location;
};

export const PinPage = () => {
  const history = useHistory();
  const location = useLocation<PinLocationState>();
  const { user, userLoading } = useGetCurrentUser();
  const [form] = useForm<PinFields>();
  const { isOnline } = useOffline();

  const redirectToIndentedPage = useCallback(() => {
    const { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
  }, [history, location.state]);

  useEffect(() => {
    if (isOnline) {
      redirectToIndentedPage();
    }
  }, [isOnline, redirectToIndentedPage]);

  const handleOnFinish = (values: { pin: string }) => {
    if (btoa(values.pin) !== user?.pin) {
      form.setFields([
        {
          name: 'pin',
          errors: [translations.shared.pin.invalidPin],
        },
      ]);
      return;
    }
    refreshLastActive();
    redirectToIndentedPage();
  };

  if (userLoading) {
    return <Loading />;
  }

  return (
    <SignInContainer>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        style={{ width: '100%' }}
        onFinish={handleOnFinish}
      >
        <Form.Item
          label={translations.shared.pin.fields.pin}
          name={'pin'}
          rules={[getRequiredRule(translations.shared.pin.fields.pin)]}
        >
          <Input.Password />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button htmlType={'submit'}>{translations.shared.confirmButtonText}</Button>
        </div>
      </Form>
    </SignInContainer>
  );
};
