import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { ColumnsType } from 'antd/lib/table';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
export const draggableColumns = (columns: ColumnsType<any>) => [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  ...columns,
];

const SortableBody = SortableContainer(
  (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <tbody {...props} />
  )
);
type RecordType = { id: string; sort_order: number }[];
type OnSortEventType = (oldIndex: number, newIndex: number) => void;
type OnSortStartEventType = () => void;
type DraggableProps = {
  data: RecordType;
  onSort: OnSortEventType;
  onSortStart?: OnSortStartEventType;
};
const DraggableContainer =
  (onSort: OnSortEventType, onSortStart?: OnSortStartEventType) =>
  ({ ...props }) =>
    (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass='row-dragging'
        onSortEnd={({ oldIndex, newIndex }) => onSort(oldIndex, newIndex)}
        onSortStart={onSortStart}
        {...props}
      />
    );

const SortableItem = SortableElement(
  (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => <tr {...props} />
);
const DraggableRow =
  (data: RecordType) =>
  ({ ...props }) => {
    // uses Table rowKey field to assign an index to each data entry
    const index = data.findIndex((x) => x.id === props['data-row-key']);
    return <SortableItem index={index} {...props} />;
  };

export const getDraggableComponents = ({ data, onSort, onSortStart }: DraggableProps) => ({
  body: {
    wrapper: DraggableContainer(onSort, onSortStart),
    row: DraggableRow(data),
  },
});
