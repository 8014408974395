import React from 'react';
import { Button, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import Text from 'antd/lib/typography/Text';
import { translations } from '../../../constants/translations';
import { useGetPractice } from '../../../hooks/ajax/practice/practiceHooks';
import {
  useGetCardConnectLink,
  useGetHideCardConnectSettings,
} from '../../CardConnectSettings/cardConnectSettingsUtil';
import { organizationIdParameter, routes } from '../../../constants/routes';
import { isIntOrStaging } from '../../../util/checkStageUtil';
import { showErrorMessage } from '../../../components/Notification/notificationUtil';
import { CardConnectLogo } from '../../../images/images';
import styled from 'styled-components';
import { openTabWithPopupDetection } from '../../../util/popupUtil';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-bottom: 1rem;
`;

const DivWithPadding = styled.div`
  padding: 0 0 1rem 0;
  width: 100%;
`;

const BoldDiv = styled.div`
  font-weight: bold;
`;

const UnavailableMessage = styled.h2`
  font-weight: bold;
`;

const SupportInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SupportInfoBox = styled.div`
  width: 50%;
  padding-bottom: 1rem;
  padding-right: 1rem;
`;

interface RegistrationCardConnectProps {
  organizationId: string;
  practiceId: string;
}

export const RegistrationCardConnect: React.FC<RegistrationCardConnectProps> = ({ organizationId, practiceId }) => {
  const history = useHistory();
  const { practice, practiceLoading } = useGetPractice(organizationId, practiceId);
  const cardConnectLink = useGetCardConnectLink(practice?.address);
  const hideCardConnectSettings = useGetHideCardConnectSettings(practice?.address);

  if (practiceLoading) {
    return <Loading height={200} />;
  }

  const handleOnSkip = () => {
    history.push(routes.doctorOffice.replace(organizationIdParameter, organizationId));
  };

  const handleCardConnectRedirect = () =>
    isIntOrStaging()
      ? showErrorMessage(translations.shared.cardConnectEnvRedirectError)
      : cardConnectLink && openTabWithPopupDetection(cardConnectLink);

  return (
    <Container>
      <CardConnectLogo width={300} />
      {hideCardConnectSettings && (
        <UnavailableMessage>{translations.cardConnectSettings.unavailableMessage}</UnavailableMessage>
      )}
      {translations.registration.cardConnect.descriptions.map((description, i) => (
        <p key={i}>{description}</p>
      ))}

      <Divider />

      <DivWithPadding>
        <h2>{translations.registration.cardConnect.supportTitle}</h2>
        <SupportInfoContainer>
          {translations.registration.cardConnect.supportInfo.map((entry, i) => (
            <SupportInfoBox key={i}>
              <BoldDiv>{entry.title}</BoldDiv>
              <div>{entry.description}</div>
              {entry.website && (
                <div>
                  <a href={`https://${entry.website}`} target='_blank' rel='noopener noreferrer'>
                    {entry.website}
                  </a>
                </div>
              )}
              {entry.email && (
                <div>
                  e:{' '}
                  <a href={`mailto://${entry.email}`} target='_blank' rel='noopener noreferrer'>
                    {entry.email}
                  </a>
                </div>
              )}
              {entry.phone && <div>p: {entry.phone}</div>}
            </SupportInfoBox>
          ))}
        </SupportInfoContainer>
      </DivWithPadding>

      <Divider />

      <ButtonContainer>
        <Button
          size='large'
          type='primary'
          style={{ marginBottom: '1rem' }}
          onClick={handleCardConnectRedirect}
          disabled={hideCardConnectSettings || !cardConnectLink}
        >
          {translations.registration.cardConnect.setupCardConnect}
        </Button>
        <Button size={'large'} onClick={handleOnSkip}>
          {translations.registration.cardConnect.skipButton}
        </Button>
      </ButtonContainer>
      <Text style={{ fontSize: '1.1em' }}>{translations.registration.cardConnect.skipMsg}</Text>
    </Container>
  );
};
