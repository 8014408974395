import React, { Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { ActiveReportViewerProps } from './activeReportViewerUtils';
import { Core } from '@grapecity/activereports';
import config from '../../config/config';
import { useCurrencyAndDateSetting } from '../../hooks/currencyAndDateHooks';

const ViewerModule = lazy(async () => {
  const module = await import('./ActiveReportViewer');
  return { default: module.ActiveReportViewer };
});

export const ReportViewer: React.FC = () => {
  const { state } = useLocation<ActiveReportViewerProps>();
  const { displayCurrency, displayDate } = useCurrencyAndDateSetting();

  const customARFunctions = [
    {
      name: 'DisplayCurrency',
      body: displayCurrency,
    },
    {
      name: 'DisplayDate',
      body: displayDate,
    },
  ];

  Core.setLicenseKey(config.ACTIVEREPORTS_LICENSE_KEY);
  Core.CustomCode.registerFunctions(customARFunctions);

  return (
    <Suspense fallback={<Loading />}>
      <ViewerModule {...state} />
    </Suspense>
  );
};
