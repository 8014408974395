import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { useState } from 'react';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { TextWithMargin } from '../../../components/SettingsCard/SettingsCard.style';
import { translations } from '../../../constants/translations';
import { FlexContainer } from '../../../globalStyles.style';
import SubscriptionCurrencyFormatter from '../../../components/CurrencyFormatter/SubscriptionCurrencyFormatter/SubscriptionCurrencyFormatter';

export interface AddOnCardPaymentPortalProps {
  isChecked?: boolean;
  canEnable?: boolean;
  price?: string;
  onClick: () => void;
  onConfigureClick?: () => void;
  countryId?: string | null;
  isStripeProcessor: boolean;
}

export const AddOnCardPaymentPortal: React.FC<AddOnCardPaymentPortalProps> = ({
  isChecked,
  canEnable,
  price,
  onClick,
  onConfigureClick,
  countryId,
  isStripeProcessor,
}: AddOnCardPaymentPortalProps) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const onClickToggle = () => {
    if (isChecked || !canEnable) {
      setDisplayAlert(true);
    } else {
      onClick();
    }
  };

  const buttonProps = {
    label: translations.addOns.cards.paymentPortal.label,
    onClick: onClickToggle,
    isToggle: true,
    isChecked,
    popconfirm: {
      title:
        !isChecked && !canEnable
          ? translations.addOns.cards.paymentPortal.cannotEnableMessage(isStripeProcessor)
          : translations.addOns.cards.paymentPortal.cannotDisableMessage,
      okText: translations.shared.okButtonText,
      cancelText: translations.shared.popconfirm.cancel,
      placement: 'top',
      open: displayAlert,
      onConfirm: () => {
        setDisplayAlert(false);
      },
      onCancel: () => {
        setDisplayAlert(false);
      },
    } as PopconfirmProps,
  };

  const configureButtonProps = isChecked
    ? {
        label: translations.addOns.cards.paymentPortal.configure,
        onClick: onConfigureClick || (() => undefined),
      }
    : undefined;

  return (
    <SettingsCard
      title={translations.addOns.cards.paymentPortal.cardTitle}
      leftButton={buttonProps}
      rightButton={configureButtonProps}
    >
      <FlexContainer direction='column' justifyContent='space-between'>
        <TextWithMargin>{translations.addOns.cards.paymentPortal.text}</TextWithMargin>
        {price && (
          <TextWithMargin isBold>
            {translations.addOns.cards.paymentPortal.subText1}{' '}
            <SubscriptionCurrencyFormatter total={Number(price)} countryId={countryId} />{' '}
            {translations.addOns.cards.paymentPortal.subText2}
          </TextWithMargin>
        )}
      </FlexContainer>
    </SettingsCard>
  );
};
