import {
  Invoice,
  OrganizationDto,
  Patient,
  PatientAlertTypeDto,
  PatientInvoiceOwnershipIssue,
  UserOrganizationPatientCard,
} from '../../../graph/types';
import { translations } from '../../../constants/translations';
import React, { ReactNode, useMemo } from 'react';
import { ServicesRenderedContextProvider } from '../../../components/ServiceRendered/store/state';
import { ViewInvoice } from '../../Invoices/ViewInvoice/ViewInvoice';
import { RouteType } from '../../../components/TabsWithRouting/TabsWithRouting';
import { RecordsContextProvider } from '../../../components/Records/store/state';
import { AddRecordButtons } from '../../../components/Records/AddRecordButtons/AddRecordButtons';
import { PatientRecords } from '../../Patients/ViewPatient/PatientRecords/PatientRecords';
import DoctorOfficePatientDetails from './DoctorOfficePatientDetails/DoctorOfficePatientDetails';
import { PatientOwnership } from '../../Patients/ViewPatient/PatientOwnership/PatientOwnership';
import AppSyncService from '../../../services/AppSyncService/AppSyncService';
import { SyncPatient } from '../../../graph/queries/patients';
import { PatientReminders } from '../../Patients/ViewPatient/PatientReminders/PatientReminders';
import { PatientAlerts } from '../../Patients/ViewPatient/PatientAlerts/PatientAlerts';
import { useAlerts } from '../../../hooks/alertHooks';

export interface TabDefinition {
  key: string;
  route: string;
  title: string;
  content: ReactNode;
  onClick?: () => void;
}

export const invoicesSubRoute = '/invoices';
export function getRouteToInvoiceTab(patientCardRoute: string, invoiceId: string) {
  return `${patientCardRoute}${invoicesSubRoute}/${invoiceId}`;
}
export const getRoutePerTabKey = (tabs: TabDefinition[], exact = true) => {
  const routePerTabKey: { [tabKey: string]: RouteType } = {};
  tabs.forEach((tab) => (routePerTabKey[tab.key] = { path: tab.route, exact }));
  return routePerTabKey;
};

export const getOnClickPerTabKey = (tabs: TabDefinition[]) => {
  const onClickPerTabKey: { [tabKey: string]: () => void } = {};
  tabs.forEach((tab) => {
    if (tab.onClick) {
      onClickPerTabKey[tab.key] = tab.onClick;
    }
  });
  return onClickPerTabKey;
};

export const useGetTabs = (
  organization: OrganizationDto | undefined,
  patient: Patient | null | undefined,
  refetchInvoices: () => Promise<any>,
  invoices: Invoice[] | undefined,
  patientCardRoute: string,
  onNewInvoice: () => void,
  setInvoiceIssues: (issues: PatientInvoiceOwnershipIssue[]) => void,
  openSyncModal: () => void,
  renderKey: number,
  patientLoading: boolean,
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined
) => {
  const { usesAlerts, patientReferenceData } = useAlerts('prompt_patient', patient, organization);
  const tabs = useMemo(() => {
    if (!organization || !patient) {
      return [];
    }

    const afterInvoiceDeleteCallback = async () => {
      await refetchInvoices();
    };

    const invoicesTabs: TabDefinition[] = invoices
      ? getInvoicesTabs(invoices, patientCardRoute, afterInvoiceDeleteCallback, renderKey)
      : [];

    const standardTabs: TabDefinition[] = getStandardTabs(
      patientCardRoute,
      organization,
      patient,
      setInvoiceIssues,
      openSyncModal,
      renderKey,
      patientLoading,
      patientRefetch,
      usesAlerts ? patientReferenceData?.alert_type : undefined
    );
    const newInvoiceTab: TabDefinition[] = getNewInvoiceTab(patientCardRoute, onNewInvoice, renderKey);

    return standardTabs.concat(newInvoiceTab).concat(invoicesTabs);
  }, [
    refetchInvoices,
    invoices,
    patient,
    organization,
    patientCardRoute,
    onNewInvoice,
    setInvoiceIssues,
    openSyncModal,
    renderKey,
    patientLoading,
    patientRefetch,
    patientReferenceData?.alert_type,
    usesAlerts,
  ]);
  return tabs;
};

export const getInvoicesTabs = (
  invoices: Invoice[],
  patientCardRoute: string,
  afterInvoiceDeleteCallback: () => Promise<void>,
  renderKey: number
) =>
  invoices.map((invoice) => ({
    key: `invoice-${invoice.id} ${renderKey}`,
    route: getRouteToInvoiceTab(patientCardRoute, invoice.id),
    title: getInvoiceTabTitle(invoice),
    content: (
      <ServicesRenderedContextProvider>
        <ViewInvoice
          invoiceId={invoice.id}
          showHeader={false}
          onDeleteNavigateToRoute={patientCardRoute}
          afterInvoiceDeletedCallback={afterInvoiceDeleteCallback}
        />
      </ServicesRenderedContextProvider>
    ),
  }));

export const getInvoiceTabTitle = (invoice: Invoice) =>
  `${translations.doctorOffice.invoiceTabPrefix} - ${invoice.number}`;

export const getStandardTabs = (
  patientCardRoute: string,
  organization: OrganizationDto,
  patient: Patient,
  setInvoiceIssues: (issues: PatientInvoiceOwnershipIssue[]) => void,
  openSyncModal: () => void,
  renderKey: number,
  patientLoading: boolean,
  patientRefetch: ((variables?: Record<string, any> | undefined) => Promise<void>) | undefined,
  patientAlertData?: PatientAlertTypeDto[]
) => [
  {
    key: `details ${renderKey}`,
    route: patientCardRoute,
    title: translations.patientPage.details.title,
    content: (
      <DoctorOfficePatientDetails
        organization={organization}
        patient={patient}
        renderKey={renderKey}
        patientLoading={patientLoading}
        patientRefetch={patientRefetch}
      />
    ),
  },
  {
    key: `ownership ${renderKey}`,
    route: `${patientCardRoute}/ownership`,
    title: translations.patientPage.ownership.title,
    content: (
      <PatientOwnership
        patient={patient}
        getInvoiceIssues={() => getInvoiceIssues(organization.id, patient, setInvoiceIssues)}
        openSyncModal={openSyncModal}
      />
    ),
  },
  {
    key: `records ${renderKey}`,
    route: `${patientCardRoute}/records`,
    title: translations.patientPage.medicalRecords.title,
    content: (
      <RecordsContextProvider>
        <div style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
          <AddRecordButtons />
        </div>
        <PatientRecords patient={patient} />
      </RecordsContextProvider>
    ),
  },
  {
    key: `reminders ${renderKey}`,
    route: `${patientCardRoute}/reminders`,
    title: translations.patientPage.reminders.title,
    content: <PatientReminders patientId={patient.id} />,
  },
  ...(patientAlertData
    ? [
        {
          key: `alerts ${renderKey}`,
          route: `${patientCardRoute}/alerts`,
          title: translations.patientPage.alerts.title,
          content: <PatientAlerts patient={patient} alertReferenceData={patientAlertData} />,
        },
      ]
    : []),
];

export const getNewInvoiceTab = (patientCardRoute: string, onNewInvoice: () => void, renderKey: number) => [
  {
    key: `newInvoice ${renderKey}`,
    title: translations.invoicesPage.buttons.addInvoice,
    route: `${patientCardRoute}/newInvoice`,
    content: <div />,
    onClick: onNewInvoice,
  },
];

export const getPatientCardFromPatient = (patient: Patient, user_id = ''): UserOrganizationPatientCard => ({
  __typename: 'UserOrganizationPatientCard',
  id: '',
  organization_id: patient.organization_id,
  user_id,
  patient_id: patient.id,
  type_name_key: '',
  sort_order: 0,
  patient_number: patient.number,
  patient_name: patient.name,
  patient_species_name: patient.species_name,
  patient_breed_name: patient.breed_name,
  patient_gender_name: patient.gender_name,
  patient_color_name: patient.color_name,
  patient_dob: patient.dob,
  patient_deceased: patient.deceased,
  patient_inactive: patient.inactive,
  patient_owner_ids: patient.owner_ids,
  patient_owner_names: patient.owner_names,
  patient_owner_percentages: patient.owner_percentages,
  patient_related_ids: patient.related_ids,
  patient_related_names: patient.related_names,
  patient_related_type_name_keys: (patient.related_current ?? []).map(
    (relatedContact) => relatedContact.contact_type_name_key
  ),
  patient_hidden: false,
});

export const getInvoiceIssues = async (
  organizationId: string,
  patient: Patient,
  setInvoiceIssues: (issues: PatientInvoiceOwnershipIssue[]) => void
) => {
  const { data: syncData } = await AppSyncService.client.mutate({
    mutation: SyncPatient,
    variables: {
      organizationId,
      patient: {
        id: patient.id,
      },
    },
  });

  const invoiceIssues = syncData?.syncPatientInvoice.invoice_issue;

  if (invoiceIssues?.length) {
    setInvoiceIssues(invoiceIssues);
  }

  return invoiceIssues;
};
