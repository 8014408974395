import { ColumnDisplayFilterProps } from '../ColumnDisplayFilter';
import { ColumnDisplayFilterAction, ColumnDisplayFilterActionType } from './actions';

export interface ColumnDisplayFilterState {
  columnDisplayFilterProps?: ColumnDisplayFilterProps;
}

export const columnDisplayFilterInitialState: ColumnDisplayFilterState = {
  columnDisplayFilterProps: undefined,
};

export const columnDisplayFilterReducer = (
  state: ColumnDisplayFilterState,
  action: ColumnDisplayFilterAction
): ColumnDisplayFilterState => {
  switch (action.type) {
    case ColumnDisplayFilterActionType.SetColumnDisplayFilterProps:
      return { columnDisplayFilterProps: action.payload };
    default:
      return state;
  }
};
