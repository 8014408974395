import { Contact, File, Invoice, Note, Patient, Prescription, Reminder, ServiceActivity } from '../../graph/types';
import { RxContact } from '../../services/LocalDatabaseService/schemas/contactSchema';
import { RxFile } from '../../services/LocalDatabaseService/schemas/fileSchema';
import { RxInvoice } from '../../services/LocalDatabaseService/schemas/invoiceSchema';
import { RxNote } from '../../services/LocalDatabaseService/schemas/noteSchema';
import { RxPatient } from '../../services/LocalDatabaseService/schemas/patientSchema';
import { RxPrescription } from '../../services/LocalDatabaseService/schemas/prescriptionSchema';
import { RxReminder } from '../../services/LocalDatabaseService/schemas/reminderSchema';
import { RxServiceActivity } from '../../services/LocalDatabaseService/schemas/serviceActivititySchema';

const offlineProperties = {
  upsert: null,
  upsert_offline_id: null,
  is_new: false,
};

export const addOfflineContactProperties = (contact: Contact): RxContact => ({
  ...contact,
  ...offlineProperties,
  document: getContactDocument(contact),
});

export const getContactDocument = (contact: Contact) => {
  const { number, name, email } = contact;
  return (number ?? '') + name + email;
};

export const addOfflineFileProperties = (file: File): RxFile => ({
  ...file,
  patient_id: file.patient_id || '',
  contact_id: file.contact_id || '',
});

export const addOfflineInvoiceProperties = (invoice: Invoice): RxInvoice => ({
  ...invoice,
  ...offlineProperties,
  offline_services: undefined,
  document: getInvoiceDocument(invoice),
});

export const getInvoiceDocument = (invoice: Invoice) => {
  const { number, status_name_key, date, patient_name, contact_names, total } = invoice;

  return '' + number + status_name_key + date + number + contact_names?.join() + total + patient_name ?? '';
};

export const addOfflineNoteProperties = (note: Note): RxNote => ({
  ...note,
  ...offlineProperties,
  patient_id: note.patient_id || '',
  contact_id: note.contact_id || '',
});

export const addOfflinePatientProperties = (patient: Patient): RxPatient => ({
  ...patient,
  ...offlineProperties,
  document: getPatientDocument(patient),
  image: patient.image ?? [],
});

export const getPatientDocument = (patient: Patient | RxPatient) => {
  const { name, name_2, name_3, number, species_name, breed_name, gender_name, owner_names, related_names } = patient;

  return (
    '' +
    name +
    name_2 +
    name_3 +
    number +
    species_name +
    breed_name +
    gender_name +
    owner_names?.join() +
    related_names?.join()
  );
};

export const addOfflinePrescriptionProperties = (prescription: Prescription): RxPrescription => {
  return {
    ...prescription,
    ...offlineProperties,
  };
};

export const addOfflineServiceActivityProperties = (serviceActivity: ServiceActivity): RxServiceActivity => ({
  ...serviceActivity,
  id: `${serviceActivity.patient_id},${serviceActivity.date}`,
});

export const addOfflineReminderProperties = (reminder: Reminder): RxReminder => ({
  ...reminder,
  upsert: null,
  upsert_offline_id: null,
  is_new: false,
});
