import { Input } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';
import { MaxLengthFormItem } from '../MaxLengthFormItem/MaxLengthFormItem';

export interface MicrochipModalFormValues {
  microchip?: string;
}

export const microchipModalFormTestId = 'microchipModalForm';

export const MicrochipModalContent: React.FC<{ patientId?: string; autofocus?: boolean }> = ({
  patientId,
  autofocus,
}) => (
  <>
    <MaxLengthFormItem
      label={translations.shared.microchipModal.fields.microchip}
      name={`${patientId ? `${patientId}_` : ''}microchip`}
      maxLength={255}
    >
      <Input autoFocus={autofocus} />
    </MaxLengthFormItem>
  </>
);
