import dayjs from 'dayjs';
import { Setting } from '../../graph/types';
import { InterestRunConfigFormFields, InterestSettingsFields, InterestSettingValue } from './InterestSettingsForm';

export const getInterestSettingValueFromJson = (jsonStr?: string | null) => {
  let parsedValue: InterestSettingValue;
  try {
    parsedValue = JSON.parse(jsonStr ?? '');
  } catch {
    parsedValue = {
      interestRate: 0.001,
      isCompounded: false,
      chargeFreePeriod: 30,
    };
  }
  return parsedValue;
};

export const getChargeFreePeriodValue = (value: InterestRunConfigFormFields, isCreating?: boolean) => {
  if (isCreating) {
    return Math.abs(
      value[InterestSettingsFields.outstandingSinceDate]!.startOf('day').diff(dayjs().startOf('day'), 'days')
    );
  }
  return value[InterestSettingsFields.chargeFreePeriod]!;
};

export const getInitialValues = (interestSettings: Setting) => {
  const { value } = interestSettings;
  const { interestRate, isCompounded, chargeFreePeriod } = getInterestSettingValueFromJson(value);

  return {
    [InterestSettingsFields.interestRate]: interestRate,
    [InterestSettingsFields.isCompounded]: isCompounded,
    [InterestSettingsFields.chargeFreePeriod]: chargeFreePeriod,
    [InterestSettingsFields.outstandingSinceDate]: chargeFreePeriod
      ? dayjs().startOf('day').subtract(chargeFreePeriod, 'days')
      : dayjs(),
  } as InterestRunConfigFormFields;
};
