import React, { useEffect, useMemo, useState } from 'react';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { PaymentModalWrapper } from './PaymentModal/PaymentModal';
import { PaymentModalFormFields } from './PaymentModal/PaymentForm/PaymentForm';
import { useGetInvoiceContactInfo } from '../../hooks/ajax/invoice/invoiceHooks';
import { LedgerUpsertGenerator } from '../../classes/upsertGenerators/LedgerUpsertGenerator';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { InvoiceContact } from '../../graph/types';
import { ViewSubscriptionContextProvider } from '../ViewSubscription/store/state';
import { useGetInvoiceLedger } from '../../hooks/ajax/ledger/ledgerHooks';
import { InvoiceStatusNameKey } from '../../constants/referenceData/invoiceReferenceData';

export interface InvoicePaymentModalProps {
  onClose: () => void;
  onSuccess?: () => void;
  invoiceId: string;
  contactId?: string;
  skipUseGetInvoiceLedgerCall?: boolean;
}

export const InvoicePaymentModal: React.FC<InvoicePaymentModalProps> = ({
  onClose,
  onSuccess,
  contactId,
  invoiceId,
  skipUseGetInvoiceLedgerCall = false,
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const { invoice } = useGetInvoiceContactInfo({ invoiceId, organizationId });
  const { ledgers } = useGetInvoiceLedger({ invoiceId, organizationId, skip: skipUseGetInvoiceLedgerCall });
  const [contactInvoice, setContactInvoice] = useState<InvoiceContact>();
  const contactsHavingFinancialOutstanding = useMemo(() => {
    const contactIds =
      ledgers
        ?.filter(({ financial_outstanding }) => Number(financial_outstanding ?? 0) > 0)
        .map(({ contact_id }) => contact_id) ?? [];
    return invoice?.contact?.filter((c) => contactIds?.indexOf(c.contact_id) >= 0);
  }, [invoice, ledgers]);
  const primaryContact = useMemo(
    () =>
      contactsHavingFinancialOutstanding?.find((contact) => contact.primary) ?? contactsHavingFinancialOutstanding?.[0],
    [contactsHavingFinancialOutstanding]
  );

  useEffect(() => {
    if (contactId && invoice) {
      setContactInvoice(invoice.contact?.filter((contact) => contact.contact_id === contactId)[0]);
    }
  }, [invoice, contactId]);

  const ledgerWithfinancialOutstanding = useMemo(() => {
    const contactId = contactInvoice?.contact_id ?? primaryContact?.contact_id ?? 0;
    return ledgers?.find(({ contact_id }) => contact_id === contactId);
  }, [contactInvoice, primaryContact, ledgers]);

  const mapFormFieldsToLedgerUpsert = (formFields: PaymentModalFormFields) => {
    return LedgerUpsertGenerator.getNewPaymentUpsertForInvoice(formFields, {
      contactId: contactInvoice?.contact_id ?? primaryContact!.contact_id,
      practiceId,
      invoiceContactLedgerId: ledgerWithfinancialOutstanding?.id ?? '',
      invoiceFinancialOutstanding: Number(ledgerWithfinancialOutstanding?.financial_outstanding ?? 0),
    });
  };

  return (
    <ViewSubscriptionContextProvider>
      <PaymentModalWrapper
        contactId={contactInvoice?.contact_id ?? primaryContact?.contact_id}
        ledgers={ledgers ?? undefined}
        contacts={contactsHavingFinancialOutstanding ?? invoice?.contact ?? undefined}
        practiceId={practiceId}
        onClose={onClose}
        onSuccess={onSuccess}
        defaultPaymentAmount={
          ledgerWithfinancialOutstanding?.invoice_status_name_key === InvoiceStatusNameKey.PartiallyPaid ||
          !contactsHavingFinancialOutstanding
            ? undefined
            : Number(ledgerWithfinancialOutstanding?.financial_outstanding ?? 0)
        }
        mapFormFieldsToLedgerUpsert={mapFormFieldsToLedgerUpsert}
        setSelectedContact={setContactInvoice}
      />
    </ViewSubscriptionContextProvider>
  );
};
