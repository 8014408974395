import React from 'react';
import {
  AimOutlined,
  BellOutlined,
  FileTextOutlined,
  MedicineBoxOutlined,
  TagOutlined,
  ProjectOutlined,
  SafetyCertificateOutlined,
  ExperimentOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  PercentageOutlined,
  ReloadOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
import { omit } from 'lodash';
import { StyledClickableIcon } from './ClickableIcon.style';

export enum IconType {
  Vaccine,
  ServiceNote,
  Reminder,
  Prescription,
  Microchip,
  RabiesTag,
  Roa,
  Lab,
  Reason,
  Withdrawal,
  Discount,
  RefetchDashboard,
  HisaRequirements,
}

interface ClickableIconProps {
  iconType: IconType;
  opaque?: boolean;
  onClick?: () => void;
  color?: 'red';
}

const ClickableIcon: React.FC<ClickableIconProps> = (props) => {
  const iconProps = omit(props, ['iconType', 'opaque', 'children']);
  return (
    // <div className={`ClickableIcon ${props.opaque ? 'ClickableIcon--opaque' : ''} ${props.color}`} {...iconProps}>
    //   {getIcon(props.iconType)}
    // </div>
    <StyledClickableIcon className={props.color} opaque={props.opaque} {...iconProps}>
      {getIcon(props.iconType)}
    </StyledClickableIcon>
  );
};

const getIcon = (iconType: IconType) => {
  switch (iconType) {
    case IconType.ServiceNote:
      return <FileTextOutlined />;
    case IconType.Vaccine:
      return <SafetyCertificateOutlined />;
    case IconType.Reminder:
      return <BellOutlined />;
    case IconType.Prescription:
      return <MedicineBoxOutlined />;
    case IconType.Microchip:
      return <ProjectOutlined />;
    case IconType.RabiesTag:
      return <TagOutlined />;
    case IconType.Roa:
      return <AimOutlined />;
    case IconType.Lab:
      return <ExperimentOutlined />;
    case IconType.Reason:
      return <InfoCircleOutlined />;
    case IconType.Withdrawal:
      return <ClockCircleOutlined />;
    case IconType.Discount:
      return <PercentageOutlined />;
    case IconType.RefetchDashboard:
      return <ReloadOutlined />;
    case IconType.HisaRequirements:
      return <SecurityScanOutlined />;
    default:
      return null;
  }
};

export default ClickableIcon;
