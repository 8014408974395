import moment from 'moment-timezone';
import { PhoneTableProperties } from '../components/PhoneTable/phoneTableContants';
import { TaxonomyType } from '../util/taxonomyUtil';
import { ServiceRendered, User } from '../graph/types';
import { OfficeType } from '../pages/DoctorOffice/doctorOfficeUtil';
import { ledgerEntryConfigs, LedgerEntryNameKey, MAX_INPUT_AMOUNT } from './referenceData/ledgerReferenceData';
import { StripeReqInfoType } from '../util/stripeConnectUtils';

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const stripeRequirementsStringBuilder = (str: string) => (infoType?: StripeReqInfoType) => infoType + str;

export const translations = {
  phoneTable: {
    columns: {
      name: 'Name',
      phone: 'Phone',
      primary: 'Primary',
      phone_type_name: 'Type',
      description: 'Description',
      extension: 'Extension',
      actions: 'Actions',
    } as Record<PhoneTableProperties, string>,
    inputs: {
      type: 'Type',
      phone: 'Phone',
      description: 'Description',
      extension: 'Extension',
      errors: {
        phone: {
          required: 'Required',
          invalid: 'Invalid phone number',
        },
      },
    },
    cannotDeletePrimary: 'Cannot delete primary phone record. Please make a different phone primary first',
    lastPrimaryUncheckCheck:
      'Cannot make the only primary phone non-primary. Please make another phone primary instead first.',
    addNewEntry: 'Add new entry',
    editAction: 'Edit',
    deletePhoneConfirm: 'Delete phone record?',
  },
  columnFreeTextFilter: {
    selectDropdownLabel: {
      equal: 'Equal to',
      greater: 'Greater than',
      less: 'Less than',
    },
  },
  createUserPage: {
    title: 'Create new User',
    fields: {
      email: {
        label: 'Email Address',
      },
      name: {
        label: 'Name',
      },
      types: {
        label: 'Roles',
      },
    },
    submitButtonText: 'Create',
    getSuccessMessage: (userName: string) => `User ${userName} created successfully`,
    createdEntity: 'user',
  },
  noteEditModal: {
    note: 'Note',
    getEditNoteTitle: (number: string) => `Edit note for: ${number}`,
    getEditingSuccessMessageForNoteUpdate: (number: string) => `Updated note for ${number} successfully`,
  },
  textConfirmModal: {
    title: 'Confirm Action',
    okText: 'Confirm',
    validationText: 'Confirm',
    description: 'Are you sure you want to continue?',
    actionText: (validationText: string) => `To proceed, please type "${validationText}" in the box below.`,
  },
  subscriptionUpdateModal: {
    title: 'Update Subscription',
    saveButtonText: 'Upgrade',
    tableColumns: {
      subscription: 'Subscription',
      currentQty: 'Current Qty',
      additionalQty: 'Additional Qty',
      price: 'Price',
      total: 'New Total',
    },
    allowOffline: 'Offline Users',
    admin: 'Standard Users',
    paymentPortal: 'Payment Portal',
    antechLab: 'Antech Lab',
    emr: 'Equine MediRecord',
    vetConnect: 'IDEXX Laboratories',
    quickbooks: 'QuickBooks Online',
    hisa: 'HORSERACING INTEGRITY and SAFETY AUTHORITY (HISA)',
    nextBillCycleText: 'Next Billing Cycle Total',
    nextChargeText: 'Next Charge',
    proratedChargeTodayText: 'Prorated Charges Today',
    upgradeConfirmWarningText: [
      'Are you sure you want to upgrade your subscription?',
      'Your card on file will be charged for the prorated amount today.',
    ],
    usersTable: {
      name: 'Name',
      role: 'Role',
      allowOffline: 'Allow Offline',
    },
  },
  paymentPortalConfigurationModal: {
    title: 'Payment Portal Configuration',
    availabilityMessage:
      'Please note the Payment Portal site may take upwards of 15 minutes after configuration to be available.',
    noSuffixMessage: '(Please enter a suffix to generate your Payment Portal URL)',
    url: 'URL',
    howToUse: {
      title: 'How to use within you website',
      iframe: {
        header: 'Embedded I-frame',
        description: (
          url: string
        ) => `Inside the body of the desired html document, place an <iframe></iframe> element and set the source to the payment portal URL as seen above. The various style settings (width, height, e.c.t.) will be left to your website builder.

Example:
    <html>
      <head>
        …
      </head>
      <body>
        <iframe src="${url}" height="800" width="600">
        </iframe>
      </body>
    </html>`,
      },
      html: {
        header: 'Site Redirection (HTML Hyperlink)',
        description: (
          url: string
        ) => `Inside the body of the desired html document, place a <a></a> element and set the href property to the payment portal URL as seen above. The various style settings will be left to your website builder.

Example:
    <html>
      <head>
        …
      </head>
      <body>
        <a href="${url}" style="…">Payment Portal</a>
      </body>
    </html>`,
      },
    },
    saveButtonText: 'Save',
    validateButtonText: 'Validate',
  },
  quickNoteModal: {
    title: 'Quick Note',
    note: 'Note',
    noteType: 'Note Type',
    quickNoteName: 'Quick Note Name',
    sortOrder: 'Sort Order',
    selectNoteTypePlaceholder: 'Select Note Type',
    descriptionPlaceholder: 'Description',
  },
  antechLabConfigurationModal: {
    title: 'Antech Online Configuration',
    instructionMessage1: 'Please complete the form with your Antech Diagnostic account information.',
    instructionMessage2:
      'If you need assistance with finding this information please contact Antech support: e: <a href="mailto:support@antechdiagnostics.com" target="_blank" rel="noopener noreferrer">support@antechdiagnostics.com</a> or p: 844-674-4422',
    instructionMessage3:
      'If you are an existing Antech Reference Lab customer, we would need to make sure that your previous historical data is available to display. Please email: <a href="mailto:PISupport@antechdiagnostics.com" target="_blank" rel="noopener noreferrer">PISupport@antechdiagnostics.com</a> with the subject line: Cassadol Data load request',
    saveButtonText: 'Save',
    fields: {
      accountId: 'Antech Account Id',
      clinicId: 'Antech Clinic Id',
      username: 'Antech Username',
      password: 'Antech Password',
      antechLabId: 'Antech Lab Id',
    },
  },
  emrRegions: {
    ireland: 'Ireland',
  },
  emrConfigurationModal: {
    title: 'Equine MediRecord Configuration',
    saveButtonText: 'Save',
  },
  emrRegionModal: {
    instructions: 'Please select the Equine MediRecord region for this contact.',
    defaultRegionLabel: 'EMR Region',
    title: 'Equine MediRecord',
    selectPlaceholder: 'Select a default region',
  },
  vetConnectLabConfigurationModal: {
    title: 'IDEXX Laboratories Configuration',
    saveButtonText: 'Save',
    editSerialNumberText: 'Edit Serial Number',
    resetText:
      'Changes made to Account ID, Username or Password will be reset by clicking OK. In order to save these changes and update the Device Serial Number, click cancel on this popup then click Save. Device serial number options should become available at that point.',
    fields: {
      labAccountId: 'IDEXX Laboratories Lab Account Id',
      username: 'IDEXX Laboratories Username',
      password: 'IDEXX Laboratories Password',
      serialNumber: 'VetLab Station Serial Number',
      inHouse: 'In House Device',
    },
  },
  quickbooksConfigurationModal: {
    title: 'QuickBooks Online Configuration',
    description:
      'Clicking Authenticate QuickBooks Online will take you to a website outside of Cassadol to log into your QuickBooks Online account. This authenticates and links Cassadol to your QuickBooks Online account.',
    saveButtonText: 'Save',
    authenticateButtonText: 'Authenticate',
    fields: {
      account: 'QuickBooks Online Account',
      realmId: 'Account ID (Realm ID)',
      receivablesAccount: 'Receivables Account',
    },
    errors: {
      receivableAccountNotFound: 'The selected receivable account does not exist.',
    },
  },
  dataConversion: {
    title: 'Data Conversion',
    description:
      'Prior to uploading data please ensure you have prepared your existing software for conversion. Depending on the previous software options you will want to ensure all financials are closed. This may mean posting or finalizing invoices and/or closing financial periods.',
    desciptionQuestions: (email: string) =>
      `If you have any questions please reach out to <a target="_blank" href="mailto:${email}">${email}</a> for assistance.`,
    uploadButtonText: 'Upload',
    otherSoftwareMessage:
      "If you'd like, you can upload a backup of your data for compatability with our Data Conversion process. Someone will reach out. Please note, that conversions from software not listed can take 4 to 6 weeks initially where we'll provide a trial look at the data before completing a final conversion to go live with.",
    fields: {
      name: 'Name of Contact at Practice',
      email: 'Contact Email',
      nameOfPreviousSoftware: 'Name of Previous Software',
      versionNumber: 'Version # of Previous Software',
      database: 'Database',
      ARReport: 'AR Report',
      attachedFiles: 'Attached Files',
      specialInstructions: 'Special Instructions',
      generalNotes: 'General Notes',
      nameOfContactAtPracticeTooltip:
        'Name of the primary person responsible at your practice for communication and questions during the data conversion process.',
      contactEmailTooltip: 'Email address for the primary contact person.',
    },
    total: 'Conversion Cost',
    paymentConfirmationModal: {
      title: 'Would you like to process this payment?',
      okText: 'Confirm',
    },
    reUploadButton: 'Re-Upload',
    otherSoftware: 'Other',
    successMessage: 'Data conversion request has been submitted',
    fetchingPrices: 'Fetching Prices',
    requestDataConversion: 'Request Data Conversion',
  },
  patientReport: {
    errorGeneratingReport: 'Error when generating the patient report',
  },
  viewUserPage: {
    fields: {
      email: {
        label: 'Email Address',
      },
      name: {
        label: 'Name',
      },
      phone: {
        label: 'Phone',
      },
      types: {
        label: 'Roles',
        placeholder: 'Select user role',
      },
      license: {
        label: 'License',
      },
      abbreviation: {
        label: 'Abbreviation',
      },
      active: {
        label: 'Active',
      },
      rememberDate: {
        label: 'Remember Date of Service',
      },
      hisaValidation: {
        label: 'HISA Number',
        extra: 'Change HISA Number',
        placeholder: 'Unavailable',
      },
    },
    options: 'Options',
    getSuccessMessage: (userName: string) => `User ${userName} updated successfully`,
    missingUser: 'The selected user does not exist',
    createdEntity: 'user',
    missingUserTypes: 'Failed to load user roles',
    rememberDateTooltip:
      "Selecting this option will allow you to keep adding items to invoices using the same date that the previous item as added on. If this is set to false, the date for the line item would revert back to today's date.",
    hisaModal: {
      title: 'HISA Options',
      fields: {
        id: 'HISA ID',
        firstName: 'First Name',
        lastName: 'Last Name',
      },
      errors: {
        name: 'Please verify the name that matches the HISA record.',
        id: 'There are no HISA records matching that ID, please verify HISA.',
      },
    },
  },
  protectedUserRoute: {
    error: {
      failedToLoadUser: 'Failed to load user',
    },
  },
  mainHeader: {
    search: {
      placeholder: 'Search...',
    },
    error: {
      failedToLoadUser: 'Failed to load user',
    },
  },
  mainPage: {
    dashboard: 'Dashboard',
    doctorOffice: 'Doctor Office',
    contacts: 'Contacts',
    patients: 'Patients',
    invoices: 'Invoices',
    reports: 'Reports',
    thirdPartyData: 'Partner Data',
    lab: 'Lab',
    labManagement: 'Lab Management',
    launchAntech: 'Antech HealthTracks™',
    logout: 'Logout',
    userSettings: 'User Settings',
    organizationIdNotLoaded: 'Error: could not load organization id!',
    rapidBilling: 'Rapid Billing',
    generalReports: 'General Reports',
    reportSettings: 'Report Settings',
    statementRuns: 'Statement Runs',
    interestRuns: 'Interest Runs',
    reminderRuns: 'Reminder Runs',
    approvals: 'Approvals',
    history: 'History',
    errorPageTitle: 'An error occurred',
    errorPageSubtitle: 'It seems the page could not be loaded. Please contact us if the error persists.',
    redirectToContacts: 'Redirect to Contacts',
    reportPayments: 'Payments',
    reportPaymentsTooltip:
      'This feature is related to Business Infusions Payments only. Please enable Business Infusions Payments to access this section.',
    financials: 'Financials',
    financialPeriods: 'Financial Periods',
    depositRuns: 'Deposit Runs',
  },
  reportsPage: {
    title: 'General Reports',
    searchFieldPlaceholder: 'Search for report',
    loadingError: 'Error: Could not load Reports',
    run: 'Run',
  },
  reportPaymentsPage: {
    title: 'Payments',
    tabs: {
      all: {
        title: 'All',
        columns: {
          date: 'Date',
          from: 'From',
          amount: 'Amount',
          status: 'Status',
          openDetails: 'Details',
        },
        actions: {
          viewDetails: 'View',
        },
      },
      payouts: {
        title: 'Payouts',
        columns: {
          arrival_date: 'Date',
          destination: 'Destination',
          amount: 'Amount',
          status: 'Status',
          openDetails: 'Details',
        },
        actions: {
          viewDetails: 'View',
        },
      },
    },
  },
  stripeDetailsModal: {
    paymentDetails: 'Payment Details',
    status: 'Status',
    dateReceived: 'Date Received',
    amount: 'Amount',
    amountRefunded: 'Refunded',
    processingFee: 'Processing Fee',
    platformFee: 'Platform Fee',
    net: 'Net',
    timeline: 'Timeline',
  },
  stripePayoutsModal: {
    sentTo: (destination: string) => `Sent to ${destination}`,
    sections: {
      overview: {
        title: 'Overview',
        status: 'Status',
        amount: 'Amount Paid',
        type: 'Type',
        date: 'Date Paid',
      },
      summary: {
        title: 'Summary',
        column: {
          count: 'Count',
          gross: 'Gross',
          fees: 'Fees',
          Total: 'Total',
        },
        columns: {
          count: 'Count',
          gross: 'Gross',
          fees: 'Fees',
          total: 'Total',
        },
      },
      transactions: {
        title: 'Transactions',
        expandable: {
          contactTitle: 'Contact',
          ledgerTitle: 'Ledger',
        },
      },
    },
  },
  stripeEmbedded: {
    error: 'Something went wrong configuring Stripe embedded components.',
    loadError: 'Something went wrong loading Stripe embedded components.',
  },
  stripeTokenizer: {
    noCountryError: 'Stripe tokenizer cannot be loaded because country is not available',
  },
  reportSettingsPage: {
    title: 'Report Settings',
    tabs: {
      settings: 'Settings',
    },
    invoiceSubjectLabel: 'Invoice Subject',
    invoiceBodyLabel: 'Invoice Body',
    invoiceFooterLabel: 'Invoice Footer',
    prescriptionLabel: 'Prescription Label',
    prescriptionLabelTooltip:
      'Currently Cassadol only supports Dymo label printers with the available label sizes to select from. In order to print labels you must install the Dymo Connect software, this can be downloaded for free directly from Dymo. ',
    prescriptionLabelTitles: {
      small: 'Dymo (2187328) - 2 1/8" x 2 3/4"',
      medium: 'Dymo (30323) - 2 1/8" x 4"',
    },
    placeholders: {
      footer: 'Invoice Footer',
      body: 'Invoice Email Body',
      subject: 'Invoice Email Subject',
      label: 'Select Label Size',
    },
    optionsHeader: 'Options',
  },
  doctorOffice: {
    getTitle: (user?: User) =>
      `${user?.organization?.find((userOrg) => userOrg.doctor) ? user?.name : 'Doctor'}'s Office`,
    closeAllButtonText: 'Close All',
    getSelectPlaceholder: (type: OfficeType) => `Add Single ${type} Tab`,
    overviewTab: 'Overview',
    searchFieldPlaceholder: (type: OfficeType) => `Search open ${type.toLowerCase()}s`,
    invoicingButtonText: 'Invoicing',
    medicalRecordsButtonText: 'Medical Records',
    ownershipPlaceholder: '(no ownership set)',
    contactsPlaceholder: '(no related contacts set)',
    addCardSuccessMessage: (type: OfficeType) => `${type} card added`,
    removeCardSuccessMessage: (type: OfficeType) => `${type} card removed`,
    updateCardSuccessMessage: (type: OfficeType) => `${type} card updated`,
    closedAllCardsSuccessMessage: (type: OfficeType) => `All ${type.toLowerCase()} cards closed`,
    errorMessageCardAlreadyOpen: 'The selected card is already open',
    closeAllPopConfirmText: 'Are you sure you want to close all cards?',
    noCardsFound: 'No open cards found',
    invoiceTabPrefix: 'INV',
    patientView: {
      ownership: 'Ownership',
      relationships: 'Relationships',
    },
    officeType: {
      patients: 'Patients',
      contacts: 'Contacts',
    },
    contactRecord: 'Contact Record',
    contactTab: 'Contact Tab',
    noAddress: 'Missing Address',
    noCity: 'Missing City',
    noCountry: 'Missing Country',
    noZipCode: 'Missing Zip/Postal code',
    offline: 'The Doctor Office is not available while disconnected from the network.',
  },
  doctorOfficeContactPatientTab: {
    failedToLoadOrganization: 'Failed to load Organization details',
    failedToLoadPatient: 'Failed to load Patient details',
  },
  statementRun: {
    title: 'Statement Run',
    selectPractice: 'Select Practice',
    selectDate: 'Select date',
    createRun: 'Create Run',
    run: 'Run',
    resend: 'Resend',
    outstanding: 'Outstanding',
    practice: 'Practice',
    refresh: 'Refresh',
    processed: 'Processed',
    select: 'Select',
    dateSelectLabel: 'Last statement run to:',
    contact: 'Contact',
    amountDue: 'Amount Due',
    status: 'Status',
    print: 'Print',
    printAndEmail: 'Print and Email',
    printOrEmail: 'Print or Email',
    statementOverlap: 'A statement run with the selected end date exists, please select a later date.',
    deleteSuccessMessage: 'Statement Run deleted successfully',
    deleteConfirmMessage: 'Are you sure you want to delete this statement run?',
    deleteButtonText: 'Delete Run',
    runSuccessMessage: 'Statement Run submitted successfully',
    tabContent: {
      outstanding: 'Outstanding',
      processed: 'Processed',
    },
    columns: {
      contact: 'Contact',
      sendStatus: 'Send Status',
      email: 'Email',
      preference: 'Preference',
      amountDue: 'Amount Due',
      status: 'Status',
    },
    configureButton: 'Configure',
    configurationModal: {
      title: 'Configure Statements',
      fields: {
        subject: 'Subject',
        emailBody: 'Email Body',
        statementFooter: 'Statement Footer',
        emailSubject: 'Email Subject',
      },
      placeholders: {
        subject: 'Enter custom email subject',
        emailBody: 'Enter custom email body',
        statementFooter: 'Enter custom statement footer',
      },
    },
    instructions:
      'Please create an initial statement run to generate an opening balance / balance forward. This statement run should be dated for the end of your normal billing cycle prior to using Cassadol for real billing.  This initial statement run should be generated and then left alone. This will allow a proper starting/ending date for your first real billing cycle within Cassadol. ',
  },
  interestRuns: {
    title: 'Interest Runs',
    dateSelectLabel: 'Interest Posting Date:',
    selectDate: 'Select date',
    createRun: 'Start New Run',
    chargeInterest: 'Charge Interest',
    configure: 'Configure',
    tabs: {
      selectContacts: 'Select Contacts',
      chargedContacts: 'Charged Contacts',
    },
    overlap: 'An Interest run with this date exists.',
    deleteButtonText: 'Delete',
    deleteConfirmMessage: 'Are you sure you want to delete this interest run?',
    modal: {
      title: 'Interest Configure',
      creatingTitle: 'Interest Confirmation',
      completeModalMsg:
        'Clicking complete will add an interest charge on all selected contacts. You can only complete the run once.',
      getDayText: (days: number) => (days === 1 ? 'day' : 'days'),
      create: 'Create',
    },
    columns: {
      contact: 'Contact',
      amountDue: 'Amount Due',
      status: 'Status',
      email: 'Email',
      bucket0: '0-30',
      bucket31: '31-60',
      bucket61: '61-90',
      bucket91: '91-120',
      bucket121: '121+',
      interest: 'Interest Fee',
    },
  },
  interestSettings: {
    title: 'Interest Settings',
    entity: 'interest setting',
    fields: {
      getInterestRateTitle: (isModal?: boolean) => (isModal ? 'Percent Charge' : 'Interest Rate'),
      isCompounded: 'Compound Interest',
      outstandingDate: 'Outstanding Since Date',
      chargeFreePeriod: 'Outstanding Days',
    },
  },
  patientPage: {
    patient: 'Patient',
    details: {
      title: 'Details',
      name: 'Name',
      name_2: 'Secondary',
      name_3: 'Tertiary',
      dateOfBirth: 'Date of birth',
      inactive: 'Inactive',
      deceased: 'Deceased',
      deceasedDate: 'Deceased date',
      deceasedDateBeforeDateOfBirth: 'Deceased date cannot be before the date of birth!',
      species: 'Species',
      breed: 'Breed',
      gender: 'Sex',
      color: 'Color',
      speciesPlaceholder: 'Please select a species',
      speciesFirstPlaceholder: 'Please select a species first',
      breedPlaceholder: 'Please select a breed',
      genderPlaceholder: 'Please select a sex',
      colorPlaceholder: 'Please select a color',
      relationships: 'Relationships',
      identifications: 'Additional Patient Identification',
      email: 'Email',
      roa: 'Route of Administration:',
      reason: 'Reason',
      results: 'Results',
      limbTreated: 'Limb Treated',
      dosage: 'Dosage',
      conditionTreated: 'Condition Treated',
      testsAndDiagnostics: 'Tests and Diagnostics',
      treatmentType: 'Treatment Type',
      time: 'Time',
      medicalName: 'Medical Name',
      drugName: 'Drug Name',
      description: 'Description',
      vaccineName: 'Vaccine Name',
      structure: 'Structure',
      hisaPlaceholder: (parameter: string, type: 'enter' | 'select') => `Please ${type} a ${parameter}`,
      modality: 'Modality',
      hisaTable: {
        name: 'HISA Name',
        number: 'HISA Number',
        action: 'Actions',
      },
    },
    fullscreenMode: 'Fullscreen:',
    ownership: {
      title: 'Ownership',
      columns: {
        owner: 'Owner',
        percentage: '%',
        primary: 'Primary Owner',
        actions: 'Actions',
      },
      ownershipSum: 'Ownership Total',
      ownershipCheck: {
        passed: 'Ownership matches 100%',
        failed: "Ownership isn't matching 100%",
      },
      primaryCheckFailed: 'Must have exactly one primary owner',
      unsavedChangesInOtherOwnership: 'Please save your changes before editing another ownership',
      tooltipToIndicateEditMode: 'Please click on "Edit" to modify this ownership ',
      deleteOwnershipConfirmation: 'Are you sure you want to delete this ownership?',
      editOwnershipConfirmation: 'Are you sure you want to edit this ownership syndicate?',
      changeIndicator: 'Was:',
      buttons: {
        delete: 'Remove',
        deleteOwnership: 'Delete Ownership',
        edit: 'Edit',
        save: 'Save changes',
        saveNewOwnership: 'Save new ownership',
        reset: 'Reset',
      },
      panels: {
        header: (ownershipNumber: number, isCurrent: boolean) =>
          `Ownership ${isCurrent ? ' (current)' : ownershipNumber}`,
        effective_date_undefined: 'No effective date found!',
        end_date_today: 'today',
        dateRangePickerTooltip: 'The end date of the previous ownership will adjust automatically',
      },
      addOwnershipModal: {
        openModalButton: 'Set new ownership',
        title: 'Set new ownership',
        effectiveDateLabel: 'Ownership effective from',
        effectiveDatePlaceholder: 'Effective date',
        description: 'The previous ownership will end one day before the effective date you enter below',
        cancelButton: 'Cancel',
        createButton: 'Create',
        effectiveDateBeforePreviousOwnershipErrorMessage:
          'Please select a date after the effective date of the previous ownership',
      },
      addOwnerModal: {
        openModalButtonText: '+ Add Owner',
        modalTitle: (ownershipNumber: number, isCurrent: boolean) =>
          `Add new Owner to ${translations.patientPage.ownership.panels.header(ownershipNumber, isCurrent)}`,
        messageWhenAddingDuplicateContact: 'The selected contact is already present',
      },
      promptForSync: {
        title: 'Ownership Invoice Sync',
        description: 'Would you like to synchronize previously billed invoices with these ownership changes?',
      },
      invoiceSync: {
        title: 'Synchronize Invoices',
        columns: {
          current: 'Current Billing',
          new: 'New Billing',
        },
        syncing: 'Syncing invoices',
      },
      offlineWarning:
        'Invoice ownership differences may exist. Invoice ownership sync is not available while offline, please manually sync invoices when you return online.',
    },
    relationships: {
      columns: {
        type: 'Type',
        contact: 'Contact',
        date: 'Date',
        action: 'Action',
      },
      buttons: {
        add: 'Add',
        edit: 'Change',
        remove: 'Remove',
      },
      modalTitle: 'Select Contact',
      popconfirm: {
        title: 'Are you sure you want to remove this contact?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    identification: {
      types: {
        PINFO_MICRO: 'Microchip',
        PTEXT_NOTE: 'Text',
        PINFO_TATOO: 'Tattoo',
        PINFO_RABIESTAG: 'Rabies Tag',
        PINFO_HISAID: 'HISA',
      },
      columns: {
        type: 'Identification Type',
      },
      notSet: 'Not set',
      hisaInputPlaceholder: 'Add HISA Number',
      hisaSearchPlaceholder: 'Search HISA Patient',
    },
    medicalRecords: {
      title: 'Medical Records',
      search: 'Search Records',
      searchWarning: 'Searching will cause you to lose the changes made to your current record edits',
      serviceRenderedTooltip: 'This item is linked to a service rendered on an invoice',
      endOfHistory: 'End of history',
      collapseAll: 'Collapse All',
      expandAll: 'Expand All',
    },
    reminders: {
      title: 'Reminders',
      columns: {
        name: 'Name',
        dueDate: 'Due Date',
        note: 'Note',
        status: 'Status',
      },
    },
    prescriptions: {
      title: 'Prescriptions',
      contact: 'Contact:',
      instructions: 'Instructions:',
      create: 'Create Prescription',
      addModal: 'Add Prescription',
      editModal: 'Edit Prescription',
      deleteMessage: 'Are you sure you want to delete this prescription?',
      fills: 'Fills:',
      fillsInstructions: 'Fill Instructions:',
      invoice: 'Invoice',
      editPrescriptionWarning:
        'You are editing the prescription record only and not existing fills. If you need existing fills changed you will need to edit those as well.',
      columns: {
        number: 'Rx Number',
        date: 'Rx Date',
        expiryDate: 'Rx Expiry Date',
        description: 'Description',
        quantity: 'Quantity',
        unit: 'Unit',
        actions: 'Actions',
        filled: 'Filled',
        refills: 'Initial Refills',
        remaining_fills: 'Remaining Fills',
      },
      filters: {
        filled: 'Filled',
        unfilled: 'Unfilled',
      },
      print: {
        printerSoftwareNotConfigured: `Your printer software is not installed. Please download and install the DYMO software for your PC at this <a href="https://www.dymo.com/support?cfid=user-guide" target="_blank">link</a> and try again once installation has completed.`,
        title: 'Print Prescription',
        selectPrinter: 'Select a Printer',
        browserNotSupported: 'Your browser does not support prescription printing. Please use a different browser.',
      },
      editPrescriptionButtonDisabledTooltip: 'Prescription created offline can be updated on the invoice.',
      editFillButtonDisabledTooltip: 'Fills created offline can be updated on the invoice.',
    },
    alerts: {
      title: 'Alerts',
      deleteAlertText: 'Are you sure you want to delete this alert?',
      columns: {
        alert: 'Alert',
        note: 'Note',
        actions: 'Actions',
      },
      genericTitle: 'Alert',
    },
    unknownPatient: 'The selected patient does not exist!',
    entity: 'patient',
    profileImage: {
      alt: 'patient profile image',
      delete: 'Delete profile image',
      uploadNew: 'Upload new profile image',
      uploadFirst: 'Upload profile image',
      successfullyUploaded: 'Profile image successfully uploaded',
      successfullyRemoved: 'Profile image successfully removed',
      deleteConfirm: 'Are you sure you want to remove the profile image?',
    },
    deletePatientModalConfirmationTitle: 'Delete Patient',
    deletePatientConfirm: (patientName = 'this patient') => `Are you sure you want to delete "${patientName}"?`,
    selectPatientModal: 'Select patient to merge into',
    mergePatientModalTitle: 'Merge Patients',
    mergePatientsModalDescription: ['Completing this action will merge', 'into', 'Do you want to proceed?'],
    newRefModal: {
      title: (type: TaxonomyType) => `New ${taxonomyTypeNameTranslation(type)}`,
    },
  },
  paymentModal: {
    title: 'New Payment',
    entity: 'payment',
    editTitle: 'Edit Payment',
    processTitle: 'Would you like to process this payment?',
    manualTitle: 'Manual Payment',
    swipeTitle: 'Waiting for payment',
    methodPlaceholder: 'Please select a payment method',
    devicePlaceholder: 'Please select a payment device',
    paymentOptionPlaceHolder: 'Please select a transaction type',
    cardHolderTransactionLabel: 'Cardholder-Initiated Transaction',
    merchantTransactionLabel: 'Merchant-Initiated Transaction',
    cardHolderTransactionTooltip: {
      title:
        'A Cardholder-Initiated Transaction is any transaction in which the cardholder is actively participating in the transaction (by phone, online, or in-person) using stored credential and payment details, for example:',
      bullet1:
        '• A cardholder creating a standing order for a recurring, fixed-amount payment for goods or services (for example, a scheduled rent payment).',
      bullet2: '• A cardholder authorizing a payment on an account over the phone.',
    },
    merchantTransactionTooltip: {
      title:
        'A Merchant-Initiated Transaction is any transaction in which the cardholder is not available to participate:',
      bullet1: '• A follow-up payment after an initial cardholder-initiated transaction.',
      bullet2:
        ' • A direct deposit agreement for the payment of goods or services (for example, a recurring bill payment for a streaming service subscription).',
    },
    invalidTransaction: 'Invalid transaction properties',
    electronicTransactionComplete: 'Transaction processed successfully',
    electronicTransactionUnknownError: 'An unknown error occurred while processing the transaction!',
    electronicTransactionNotSettledError: `The refund could not be completed. The original transaction has not been settled yet.
      Please wait until settled and/or reverse the payment in full and do a new payment for the correct amount.`,
    swiping: {
      connecting: 'Connecting to device...',
      waiting: 'Waiting on device...',
      noResultFromConnect: 'Did not receive a result during connect',
      noErrorMessage: 'ERROR: NO MESSAGE',
      cancelling: 'Cancelled issued, please wait...',
      completeSuccess: 'Successfully finished transaction',
    },
    buttons: {
      processButton: 'Process',
      swipeButton: 'Swipe',
      manualButton: 'Manual',
    },
    fields: {
      contact: 'Payment for Contact',
      date: 'Date',
      payer: 'Payer',
      amount: 'Amount',
      method: 'Method',
      device: 'Device',
      saveCard: 'Save Card',
      saveCardConfirm: 'Card confirmation',
      saveCardConfirmMessage:
        'Cardholder permission is required to store payment card information for future use. If the cardholder did not provide permission, their card cannot be saved.',
      saveCardConfirmCheckboxMessage:
        'Cardholder has given verbal or written permission to save profile and all associated cards at all locations.',
      saveCardConfirmCheckboxValidationMessage: 'You need explicit permission to save the card',
      check: 'Check Number',
      card: 'Card',
      cardNote: 'Card Note',
      paymentOption: 'Transaction Type',
    },
    creditCards: {
      errorLoadingRecords: 'Error loading credit card list',
      columns: {
        type: 'Card Type',
        displayNumber: 'Number',
        expiry: 'Expiry',
        primary: 'Primary',
        note: 'Note',
        actions: 'Actions',
      },
      deleteConfirmationText: 'Are you sure you want to delete this card?',
      noCardsMessage: 'There are no cards configured yet',
    },
    validation: {
      checkFieldLength: 'Check field must be an alphanumeric input of 3-10 characters',
      checkFieldNumbersOnly: 'Only numbers and letters allowed',
    },
  },
  runReportModal: {
    title: 'Run Report',
    run: 'Run',
    startDate: 'Start Date',
    endDate: 'End Date',
    field: 'Field',
  },
  chargeModal: {
    title: 'New Account Charge',
    succcessMessage: 'Charge created successfully',
    getEditingTitleForCharge: (ledgerNumber: string) => `Edit Account Charge ${ledgerNumber}`,
    getEditingSuccessMessageForCharge: (ledgerNumber: string) =>
      `Charge ${ledgerNumber} has been updated successfully.`,
  },
  creditModal: {
    title: 'New Credit',
    succcessMessage: 'Credit added successfully',
    getEditingTitleForCredit: (ledgerNumber: string) => `Edit Credit ${ledgerNumber}`,
    getEditingSuccessMessageForCredit: (ledgerNumber: string) =>
      `Credit ${ledgerNumber} has been updated successfully.`,
  },
  BasicLedgerChargeModal: {
    fields: {
      date: 'Date',
      amount: 'Amount',
      note: 'Note',
    },
  },
  reversePaymentModal: {
    buttonText: 'Reverse',
    title: 'Reverse Payment',
    fields: {
      originalAmount: 'Original Amount',
      availableForReversal: 'Available for Reversal',
      refundTo: 'Refund To',
      date: 'Date',
      amount: 'Amount',
      note: 'Note',
    },
    successMessage: 'Payment successfully reversed',
    savePopConfirmMessage: 'Are you sure you would like to refund the credit card charged?',
  },
  patientsPage: {
    title: 'Patients',
    actions: 'Actions',
    emailHistoryReport: 'Email History Report',
    printHistoryReport: 'Print History Report',
    deleteButton: 'Delete Patient',
    syncInvoices: 'Sync Invoices',
    mergeButton: 'Merge',
    emailVaccineReport: 'Email Vaccine Report',
    printVaccineReport: 'Print Vaccine Report',
    columns: {
      name: 'Name',
      name_2: 'Secondary Name',
      name_3: 'Tertiary Name',
      number: 'Number',
      relationship: 'Relationship',
      status: 'Status',
      actions: 'Actions',
      species: 'Species',
      breed: 'Breed',
      gender: 'Sex',
      owner: 'Owner',
    },
    buttons: {
      addPatient: 'Create Patient',
    },
    status: {
      active: 'Active',
      inactive: 'Inactive',
      deceased: 'Deceased',
    },
    searchPlaceholder: 'Search for patient',
    deleteSuccessMessage: 'Patient successfully deleted',
    deleteReminderSuccessMessage: 'Reminder successfully deleted',
  },
  practiceSettings: {
    title: 'Practice Settings',
    subTitle: 'Please enter the details of your practice',
    fields: {
      name: 'Name',
      taxId: 'Tax ID',
      email: 'Email',
      website: 'Website',
    },
    noPractice: 'Error: no practice associated with your organization',
    entity: 'practice',
    logoUpload: 'Upload new logo',
    firstLogoUpload: 'Upload logo',
    practiceLogo: 'practice logo',
    logoSuccessfullyUploaded: 'Logo successfully uploaded',
    logoSuccessfullyRemoved: 'Logo successfully removed',
    removeLogoConfirm: 'Are you sure you want to delete the practice logo?',
    practiceLogoPlaceholder: 'practice logo placeholder',
    removeLogo: 'Remove logo',
  },
  subscriptionSettings: {
    title: 'Billing Subscription',
    currentBillingCycleEstimate: {
      title: 'Current Billing Cycle Estimate',
      subTotal: 'Sub-total',
      taxTotal: 'Total',
      discount: 'Discount',
      content: {
        nextCharge: 'Next Charge',
        viewCurrentBill: 'View Current Bill',
      },
      buttons: {
        editMaxUsers: 'Edit Subscription',
        retrySubscription: 'Retry Subscription',
      },
      retryResponseMessages: {
        success: 'Subscription updated successfully',
        error: 'Subscription retry failed',
      },
    },
    cardDetails: {
      title: 'Billing Details',
      buttons: {
        editCard: 'Edit Billing Details',
        cancelSub: 'Cancel Subscription',
      },
      noData: 'No card on file',
      expires: 'Expires',
      modal: {
        title: 'Edit Billing Details',
        labels: {
          nameOnCard: 'Cardholders Name',
          email: 'Email',
          phone: 'Phone',
        },
      },
    },
    billingHistory: {
      title: 'Billing History',
      content: {
        viewFullHistory: 'View Full History',
      },
    },
    billingContact: {
      title: 'Billing Contact',
      buttons: {
        editContact: 'Edit Contact',
      },
    },
  },
  addOns: {
    title: 'Add Ons',
    cards: {
      offline: {
        cardTitle: 'Offline Mode',
        label: 'Enable Offline',
        text: 'Allow users to work while disconnected from a network connection. Data will automatically sync once network connection has been reestablished',
        subText1: 'Add On Charge -',
        subText2: 'per allowed user',
        configure: 'Configure Allowed Users',
        disableMessage: 'Are you sure you wish to disable Offline mode for your organization?',
      },
      paymentPortal: {
        cardTitle: 'Payment Portal',
        label: 'Enable Payment Portal',
        text: 'Set up a payment portal for your clients to easily and securely pay their balance via Credit Card online.',
        subText1: 'Add On Charge -',
        subText2: 'per month',
        configure: 'Configure Portal',
        cannotDisableMessage: 'Payment Portal cannot be disabled by user. Please contact support to disable it.',
        cannotEnableMessage: (isStripeProcessor: boolean) =>
          `In order to setup and use the Payment Portal, you must have ${
            isStripeProcessor ? 'Business Infusions Payments' : 'Clover Connect'
          } Setup. Please setup ${isStripeProcessor ? 'Business Infusions Payments' : 'Clover Connect'} first.`,
      },
      antechLab: {
        cardTitle: 'Antech Diagnostics/HealthTracks™',
        label: 'Enable Antech',
        text: 'Connect Cassadol and your Antech account. This will allow you to create lab requests for Antech reference labs and receive the results automatically within the patient record.',
        subText1: 'Add On Charge: ',
        subText2: 'Per Month',
        configure: 'Configure Antech',
        cannotDisableMessage: 'Please contact Cassadol support to disable this integration.',
        successMessage: 'Antech Online Add On successfully enabled.',
      },
      emr: {
        cardTitle: 'Equine MediRecord',
        label: 'Enable EMR',
        text: 'Provide your trainers approved treatment data through the Equine MediRecord integration.',
        subText1: 'Add On Charge: ',
        subText2: 'Per Month',
        configure: 'Configure Equine MediRecord',
        cannotDisableMessage: 'Please contact Cassadol support to disable this Add On.',
        successMessage: 'Equine MediRecord Add On successfully enabled.',
      },
      vetConnectLab: {
        cardTitle: 'IDEXX Laboratories',
        label: 'Enable IDEXX Laboratories',
        text: 'Connect Cassadol and your IDEXX Laboratories account. This will allow you to create lab requests for both IDEXX in-house and reference labs and receive the results automatically within the patient record.',
        subText1: 'Add On Charge: ',
        subText2: 'Per Month',
        configure: 'Configure IDEXX',
        cannotDisableMessage: 'Please contact Cassadol support to disable this integration.',
        successMessage: 'IDEXX Laboratories Add On successfully enabled.',
      },
      cloverConnect: {
        cardTitle: 'Clover Connect',
        text: 'Cassadol has partnered with Clover Connect to provide credit card processing for your practice. Clover Connect is PCI compliant so you do not need to worry about credit card theft and liability to your practice.',
        configure: 'Configure Clover Connect',
      },
      stripe: {
        cardTitle: 'Business Infusions Payments',
        text: 'Provide online, PCI compliant credit card processing for your practice with Business Infusions Payments, powered by Stripe.',
        configure: 'Configure',
        enable: 'Enable',
        cannotDisableMessage: 'Please contact Cassadol support to disable this integration.',
      },
      quickBooks: {
        cardTitle: 'QuickBooks Online',
        label: 'Enable',
        text: 'Connect Cassadol and your QuickBooks Online account. This will allow you to sync summary level financial information from Cassadol into QuickBooks Online.',
        subText1: 'Add On Charge: ',
        subText2: 'Per Month',
        configure: 'Configure',
        cannotDisableMessage: 'Please contact Cassadol support to disable this integration.',
        successMessage: 'QuickBooks Online Add On successfully enabled.',
      },
      hisa: {
        cardTitle: 'HORSERACING INTEGRITY and SAFETY AUTHORITY (HISA)',
        label: 'Enable',
        text: 'Link your Cassadol account to HISA and submit treatment data directly to HISA.',
        subText1: 'Add On Charge: ',
        subText2: 'Per Month',
        configure: 'Configure',
        cannotDisableMessage: 'Please contact Cassadol support to disable this integration.',
      },
    },
  },
  recordList: {
    date: 'Date: ',
    collapse: 'Collapse',
    created_by: 'Created by: ',
    updated_by: 'Updated by: ',
    doctors: 'Doctors: ',
    file: 'File',
    note: 'Note',
    serviceActivity: (offline = false) => `${offline ? 'Offline ' : ''}Services Rendered`,
    labRequests: {
      labResult: 'Lab Result',
      status: 'Status:',
      noResults: 'Waiting on Results from Lab...',
      accession: 'Accession No:',
    },
    downloadFile: 'Download file',
    errorOnTiffRequest: 'Unable to get the file',
    noteTypeDropdownPlaceholder: 'Note Type',
    fileTypeDropdownPlaceholder: 'File Type',
    unsavedChangesWarning: 'Please save or reset your changes first',
    deleteConfirmation: (typeName: string) => `Are you sure you want to delete this ${typeName}?`,
    addFileButtonText: 'Attach File',
    changeFileButtonText: 'Change File',
    addNoteButtonText: 'New Note',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    newNoteTitle: 'New Note',
    newFileTitle: 'Upload File',
    dateFieldName: 'Date',
    typeFieldName: 'Type',
    emptyTextError: 'Please fill the text',
    noFileError: 'Please upload a file first',
    uploadButtonText: 'Upload',
    textFieldPlaceholder: 'Description',
    offlineFileUnavailable: 'Viewing file attachments is unavailable in offline mode',
    replaceOrAppendPopover: 'Would you like to replace the current note or append to it?',
    append: 'Append',
    replace: 'Replace',
    selectQuickNoteTypePlaceholder: 'Add a quick note',
  },
  shared: {
    close: 'Close',
    note: {
      loadingError: 'Failed to load note',
      placeholder: 'Contact Note',
    },
    pin: {
      modalTitle: 'Set New PIN',
      fields: {
        pin: 'Pin',
      },
      invalidPin: 'Invalid PIN',
    },
    selectContactModal: {
      title: 'Select contact',
      tableColumnNumber: 'Number',
      tableColumnName: 'Name',
      tableColumnTags: 'Tags',
      tableColumnAction: 'Action',
      select: 'Select',
      modalCancelButton: 'Cancel',
      createContact: 'Create Contact',
    },
    selectPatientModal: {
      title: 'Select patient',
      searchPlaceholder: 'Search for patient',
      modalCancelButton: 'Cancel',
      modalSelectButton: 'Select',
      checkAll: 'Check All',
      clear: 'Clear All',
      clearPopConfirmTitle: 'Are you sure you want to remove all selected patients from your batch?',
      exceededSelectionsWarning: (maximum: string) => 'You can only select up to a maximum of ' + maximum + ' patients',
      createPatient: 'Create Patient',
      currentlyEditingOwnersPopConfirm: 'Are you sure want to continue without saving your ownership changes?',
    },
    servicePatientModal: {
      title: (patientName: string) => `Patient Details - ${patientName}`,
      description:
        'This service code is linked to a 3rd party that requires specific patient details before accepting.',
      formTitle: 'Please complete the following fields:',
      rapidBillingWarning:
        'This Service is linked to a lab and some patient information will need to be entered before creating lab request.',
    },
    selectEntityModal: {
      selectOneOrMore: (entity = 'patients') => `Please select one or more ${entity} from the list...`,
      entities: {
        contacts: 'contacts',
        invoices: 'invoices',
      },
    },
    hisaRequirementsModal: {
      title: 'HISA Requirements',
      selectOptions: {
        LF: 'Left Front',
        RF: 'Right Front',
        BF: 'Both Front',
        LH: 'Left Hind',
        RH: 'Right Hind',
        BH: 'Both Hind',
        All4: 'All 4',
        NA: 'N/A',
        BoneScan: 'Bone Scan',
        CT: 'CT',
        MRI: 'MRI',
        Other: 'Other',
        PET: 'PET',
        Radiograph: 'Radiograph',
        Ultrasound: 'Ultrasound',
      },
      rapidBillingWarning: 'You will be able to enter HISA required data on the next screen.',
    },
    vaccineModal: {
      title: 'Vaccine Details',
      fields: {
        manufacturer: 'Manufacturer',
        lotNumber: 'Lot number',
        serialNumber: 'Serial number',
        expiryDate: 'Expiry date',
      },
    },
    controlledDrugModal: {
      title: 'Controlled Drug Details',
      fields: {
        bottle_number: 'Bottle number',
      },
    },
    reminderModal: {
      title: 'Reminder Details',
      fields: {
        reminderName: 'Reminder Name',
        date: 'Date',
        note: 'Note',
      },
    },
    microchipModal: {
      title: 'Microchip Details',
      fields: {
        microchip: 'Microchip',
      },
      rapidBillingWarning:
        'You will be able to enter the microchip number in the next screen. If you do not have the microchip number, you can enter it later in the patient record',
    },
    rabiesTagModal: {
      title: 'Rabies Tag Details',
      fields: {
        rabiesTag: 'Rabies Tag Number',
      },
      rapidBillingWarning:
        'You will be able to enter the rabies tag number in the next screen. If you do not have the rabies tag number, you can enter it later in the patient record',
    },
    roaModal: {
      title: 'Route of Administration',
      intravenous: 'Intravenous',
      intramuscular: 'Intramuscular',
      oral: 'Oral',
      topical: 'Topical',
      other: 'Other',
      cancelText:
        'Route of administration for this service will be set to method that is currently selected. Do you still wish to continue?',
    },
    prescriptionModal: {
      title: 'Prescription Details',
      number_autogenerated_text: 'Auto Generated',
      refillsDisabledMessage:
        'This patient already has a prescription with remaining fills linked to this service with the same quantity.',
      multiPatientRefillsCaution:
        'A refill will be consumed for any patients with existing prescriptions for this service.',
      fields: {
        number: 'Rx Number',
        contact: 'Contact',
        patient: 'Patient Name',
        prescribedUser: 'Prescribed By',
        prescribedDate: 'Prescription Date',
        prescriptionExpiryDate: 'Prescription Expiry Date',
        productExpiryDate: 'Product Expiry Date',
        refills: 'Refills',
        itemDescription: 'Description',
        itemQuantity: 'Quantity',
        itemUnit: 'Unit',
        instructions: 'Instructions',
      },
      fillInstructionsWarning:
        'You are editing the instructions for this fill only. These changes will not be propagated to previous or future fills of this prescription.',
    },
    reasonModal: {
      title: 'Reason Details',
      fields: {
        reason: 'Reason',
      },
      placeholder: 'Maximum length is 200 characters',
    },
    withdrawalModal: {
      title: 'Withdrawal Details',
      fields: {
        withdrawal: 'Withdrawal',
      },
      periodUnit: 'Days',
    },
    deceasedDateModal: {
      rapidBillingWarning: 'You will be able to enter the deceased date in the next screen',
    },
    discountModal: {
      title: (serviceTitle: string, date: string) => `Discount ${serviceTitle} on ${date}`,
      fields: {
        listPrice: 'List Price',
        discountAmount: 'Discount Amount',
        discountPercentage: 'Discount Percent',
        calculation: 'Calculation',
        hideDiscount: 'Do Not Print Discount',
      },
      discountMaxWarning: (max: string) => `Discount can not be greater than total list amount ($${max}).`,
      discountPercentWarning: 'Discount percent cannot be greater than 100.',
    },
    popconfirm: {
      ok: 'Yes',
      cancel: 'Cancel',
      no: 'No',
    },
    modalClosePopConfirm: {
      confirmMessage: 'Are you sure you want to cancel?',
      footer: {
        okText: 'Save',
        cancelText: 'Cancel',
      },
    },
    identification: {
      title: 'Type',
      value: 'Value',
      action: 'Action',
    },
    errorMessage: {
      EMAIL_ERROR_DUPLICATE: 'Email Address already taken. Please pick a another one.',
      invoiceSync: 'An error occured while syncing invoices',
    },
    discountPopover: {
      originalPrice: 'List Price',
      discountAmount: 'Discount Amount',
      discountPercent: 'Discount Percentage',
    },
    saveSuccessMessage: 'Saved successfully',
    deleteSuccessMessage: 'Record was successfully deleted from system',
    saveErrorMessage: 'Failed to save item',
    generalErrorMessage: 'Error occurred',
    loadErrorMessage: 'Failed to load data',
    saveChangesButtonText: 'Save changes',
    saveButtonText: 'Save',
    addButtonText: 'Add',
    okButtonText: 'Ok',
    resetButtonText: 'Reset',
    clearButtonText: 'Clear',
    clearAllButtonText: 'Clear All',
    filterText: 'Filters:',
    cancelButtonText: 'Cancel',
    closeButtonText: 'Close',
    backButtonText: 'Back',
    editButtonText: 'Edit',
    deleteButtonText: 'Delete',
    confirmButtonText: 'Confirm',
    nextButtonText: 'Next',
    noResults: 'No results',
    none: 'None',
    removeAssignment: 'Remove Assignment',
    print: 'Print',
    email: 'Email',
    takePaymentButtonText: 'Take Payment',
    cardConnectEnvRedirectError:
      'The Clover Connect application is disabled on this system. If you were on a live system you would be taken through the Clover Connect application process.',
    deleting: 'Deleting ...',
    loading: 'Loading ...',
    assign: 'Assign',
    unassign: 'Unassign',
    deleteErrorMessage: 'Failed to delete item.',
    notePopoverTitle: 'Note:',
    hisaBackDateWarningMessage: 'Warning - HISA requires treatments to be entered within 24hrs of treatment.',
    getIsRequiredMessage: (label: string) => label + ' is required',
    getPositiveValueMessage: (label: string) => `${label} must be positive`,
    getFilterInputPlaceholder: (label: string) => 'Filter ' + label,
    getCreationNavigationWarning: (createdEntity: string) =>
      `Are you sure you wish to cancel this ${createdEntity} creation?`,
    getUnsavedDataNavigationWarning: (unsavedEntity: string) =>
      `There is unsaved ${unsavedEntity} data, are you sure you wish to leave?`,
    emailIsNotValid: 'Invalid email address',
    websiteIsNotValid: 'Invalid website',
    missingOrganization:
      'Failed to load organization details. Please reload the page or contact support if the problem persists',
    missingInvoice: 'Failed to load invoice details. Please reload the page or contact support if the problem persists',
    missingPatientReferenceData:
      'Failed to load Patients reference data. Please reload the page or contact support if the problem persists',
    getMaximumFileSizeError: (maximumSize: string) => `Maximum file size is ${maximumSize}`,
    getPlaceholderAlt: (originalAlt: string) => `${originalAlt} placeholder`,
    errorLoadingPage: 'Error loading page',
    submit: 'Submit',
    back: 'Back',
    tooManyResults: 'Showing only the first 1000 results. Please further refine your search.',
    getMaxInputLengthMessage: (maxLength: number) => `Maximum length for this field is ${maxLength} characters`,
    getMinInputLengthMessage: (minLength: number) => `Minimum length for this field is ${minLength} characters`,
    getVoidRecord: (value: string) => `(Void) ${value}`,
    select: 'Select',
    offlineModeOn: 'Disable Offline',
    offlineModeOff: 'Enable Offline',
    offlineModalTitle: 'Network Connection',
    enabled: 'Enabled',
    disabled: 'Disabled',
    offlineModalDescription:
      'It seems as though you have lost your network connection. Please reconnect to the internet to continue using Cassadol.',
    offlineDisableDescription:
      '<center>Turning offline mode off will delete the downloaded data from your local computer and you will need to sync again.<br /> <br /><b>Caution you will lose all unsynced data if you continue with disabling offline mode.</b><br /> <br /></center>',
    offlineEnableDescription:
      '<center>Offline data may take some time (30+ minutes) to completely download and sync, but you may continue using Cassadol during download. The icon in the top right corner will identify when data download is completed. <br /> <br /><b>Do not go offline or shutdown your device until the icon is green and sync is complete.</b><br /> <br />Click OK to continue.</center>',
    logoutWithOffline:
      '<center>Logging out with offline mode enabled may cause your downloaded data to be deleted. For future offline functionality you will need to resync your data in full. <br /> <br />Click OK to continue.</center>',
    networkError:
      'The server failed to respond to the request. Refresh your browser and retry your action. If the problem persists, please contact support.',
    syncInvoicesError: 'An error occurred while attempting to sync invoices',
    refreshButtonText: 'Refresh',
    updateNotification: {
      refresh: {
        message: 'Update received',
        description: (entity: GeneralNames) =>
          `There are incoming changes for this ${entity}. Make sure to save your changes before clicking "Refresh" to update.`,
      },
    },
    cacheUpdateNotification: {
      refresh: {
        message: 'New content available',
        description: 'New content is available and will be used after refreshing the page.',
      },
    },
    statusInformation: {
      white: {
        color: 'White',
        display: 'Offline not enabled',
      },
      blue: {
        color: 'Blue',
        display: 'Unsynced changes have been made',
      },
      red: {
        color: 'Red',
        display: 'Errors with data sync',
      },
      yellow: {
        color: 'Yellow',
        display: 'Data sync is in progress',
      },
      green: {
        color: 'Green',
        display: 'Data is fully synced for offline use',
      },
    },
    communicatingWithLabMessage: 'Communicating with Lab',
    unknown: 'Unknown',
    tagsHeader: 'Tags',
    quickBooks: {
      auth: {
        title: 'QuickBooks Authentication',
        urlLoading: 'Retrieving QuickBooks OAuth URL',
        urlLoaded: 'Waiting to finish QuickBooks OAuth process',
        urlErrorMessage: (error: string) => `Error retrieving QuickBooks OAuth URL: ${error}`,
        callbackCodeErrorMessage: (error: string) => `Error syncing QuickBooks with Cassadol: ${error}`,
        loadingMessage: 'Syncing QuickBooks with Cassadol',
        tokenSent: 'Token successfully sent to the server. You can now close this window and continue where you left.',
        closingIn: (time: number) => `Closing window in ${time} seconds...`,
      },
    },
  },
  hisaNameKeys: {
    RESULTS: 'Results',
    SLIMB_TREATED: 'Limb treated',
    COND_TREATED: 'Condition treated',
    MEDICAL_NAME: 'Medical name',
    TESTS_DESCR: 'Tests description',
    TIME: 'Time',
    TREATMENT_TP: 'Treatment type',
    DESCRIPTION: 'Description',
    VACCINE_NAME: 'Vaccine name',
    DOSAGE: 'Dosage',
    DRUG_NAME: 'Drug name',
    STRUCTURE: 'Structure',
    MODALITY: 'Modality',
  },
  login: {
    title: 'Log in to your Cassadol account',
    newPasswordTitle: 'Set a new password',
    fields: {
      email: {
        placeholder: 'Email Address',
      },
    },
    learnMore: 'Learn more',
    referral: 'Refer and Get Paid!',
    loginButtonText: 'Sign In',
    signUpButtonText: 'Sign Up',
    resetPasswordButtonText: 'Reset Password',
    newPasswordButtonText: 'Submit New Password',
    generalErrorMessage: 'Login failed. Please try again.',
    loadingMessage: 'Signing In...',
    inactiveUserError: 'Incorrect Username or password',
  },
  passwordReset: {
    title: 'Reset Password',
    successMessage: 'Password reset email successfully sent',
    errorMessage: 'There was a issue sending the reset email. Please contact support if the problem persists.',
  },
  maintenance: {
    maintenancePage: {
      messageText: {
        message: 'Cassadol is currently unavailable and undergoing maintenance.\nWe estimate to be online again in',
        upcoming: 'Cassadol is currently unavailable and undergoing maintenance.\nWe estimate to be online again in',
        messageCloud:
          'We are currently experiencing technical difficulties with our cloud service provider. Our team is actively working to resolve the issue as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience during this time.\nPlease check back frequently for updates.\nThank you for your understanding.',
        upcomingCloud:
          'We will be soon experiencing technical difficulties with our cloud service provider. Our team is actively working to resolve the issue as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience during this time.\nPlease check back frequently for updates.\nThank you for your understanding.',
      },
    },
    maintenanceBanner: {
      getWarningMainText: (start: string, end: string) =>
        `Scheduled Maintenance: ${moment(start).tz(userTimezone).format('dddd, MMMM Do, h:mm A z')} to ${moment(end)
          .tz(userTimezone)
          .format('dddd, MMMM Do, h:mm A z')}`,
      messageText: {
        message:
          'Cassadol is currently within a maintenance window, please use with caution.\nIf you are using Cassadol offline, it is recommended to connect to the internet and allow Cassadol to sync prior to the scheduled maintenance Date/Time.',
        upcoming:
          'Planned maintenance is scheduled for the time mentioned above, during which Cassadol may be unavailable.\nIf you are using Cassadol offline, it is recommended to connect to the internet and allow Cassadol to sync prior to the scheduled maintenance Date/Time.',
        messageCloud:
          'We are currently experiencing technical difficulties with our cloud service provider. Our team is actively working to resolve the issue as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience during this time.\nPlease check back frequently for updates.\nThank you for your understanding.',
        upcomingCloud:
          'We will be soon experiencing technical difficulties with our cloud service provider. Our team is actively working to resolve the issue as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience during this time.\nPlease check back frequently for updates.\nThank you for your understanding.',
      },
    },
  },
  offlineWarning: {
    mainText: 'This computer has been using Cassadol while offline for longer than 24hrs',
    subText: 'We recommend connecting to the internet and allowing Cassadol to sync your data ASAP.',
  },
  nonProdWarning: {
    title: 'Non Production Environment',
    message: `Caution - You are logged into a non-production environment, do not enter any personal data. Data entered can be reset at anytime with or without notice. <br /> 
      <strong> If you wish to register for Cassadol, please visit <a href='https://cassadol.software.vet'>https://cassadol.software.vet</a> <strong>`,
  },
  paymentPortal: {
    messages: {
      success: 'Payment Portal was created successfully',
      validationSuccess: 'The selected Payment Portal URL is available',
      errors: {
        validationError: 'The selected Payment Portal URL is already in use',
      },
    },
  },
  registration: {
    registerUserStep: {
      title: 'Create User',
      cardTitle: 'User Login Info',
      labels: {
        name: 'Name',
        practiceName: 'Practice Name',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
      },
      recaptchaError: 'Error verifying recaptcha',
      passwordRequirements:
        'Passwords require minimum 8 characters, with at least 1 uppercase, lowercase, special and number character',
      button: 'Create User Account',
    },
    confirmationStep: {
      title: 'Confirm Email',
      confirmButton: 'Confirm',
      labels: {
        code: 'Code',
        codePlaceholder: 'Enter Verification Code',
      },
      successMessage:
        'Thank you for confirming your email. Please enter your password to login to continue the registration process.',
      displayText: 'A verification code has been sent to your email account. Please enter that code below.',
      checkJunk:
        "If you didn't receive a verification code within a few minutes of signing up, please be sure to check your junk mail folder.",
      resendVerificationCode: 'Resend Verification Code',
      verficationEmailSentSuccessMessage: 'A new verification code has been sent',
    },
    addPracticeInfoStep: {
      title: 'Practice Information',
      billingAddress: 'Billing Address',
      labels: {
        cardTitle: 'Practice Info',
        address1: 'Address 1',
        address2: 'Address 2',
        phone: 'Phone',
        email: 'Email',
        city: 'City',
        country: 'Country',
        state: 'State',
        zip: 'Zip',
        addressCheckbox: 'Mailing same as physical address',
        howDidYouHear: {
          title: 'Referral Source',
          placeholder: 'How did you hear about us?',
        },
      },
      stripeBusinessType: {
        title: 'Business Type',
        selectPlaceholder: 'Select Business Type',
        types: {
          company: 'Company',
          individual: 'Individual',
        },
      },
      subTitle: {
        physicalAddress: 'Physical Address',
        mailingAddress: 'Mailing Address',
      },
      errorLoadingPage: 'Failed to load page',
      organizationSetupMessage:
        'Please wait while we setup your organization. This may take a few minutes, please do not click back or refresh your browser until done.',
    },
    subscriptionStep: {
      title: 'Subscription Settings',
      labels: {
        cardTitle: 'Subscription Info',
      },
    },
    cardConnect: {
      title: 'Clover Connect',
      tableTitle: 'Clover Connect Device List',
      skipButton: 'Skip',
      setupCardConnect: 'Clover Connect Application',
      skipMsg: 'You can setup Clover Connect later from the settings',
      loadingError: 'Failed to load practice data',
      refreshButton: 'Refresh Device List',
      descriptions: [
        'Cassadol has partnered with Clover Connect to provide credit card processing for your practice. Clover Connect provides processing services for thousands of veterinary practices and is PCI compliant so you do not need to worry about credit card theft and liability to your practice. When you click’ Clover Connect Application’ you will be re-directed to the Clover Connect website where there is more information about their services. Close to the bottom go the page is the link to submit your name, email, phone and company.',
        'You can skip this step and set up your Clover Connect under the ‘Settings’ tab inside Cassadol. Clover Connect is the only card processor Cassadol works with. In order to receive payments and have them flow through to the accounts receivable reporting within Cassadol, you must use Clover Connect. Rates are 2.9% plus $0.25 per transaction for all cards and funds are deposited to the account you designate on the next business day.',
        'Once your Clover Connect application has been approved Clover Connect will reach out to you directly and provide your MerchantId, Username and Password to be entered on the Clover Connect settings page. You can also purchase equipment from Clover Connect to be able to swipe credit cards in addition to manual entry.',
      ],
      supportTitle: 'Clover Connect Support',
      supportInfo: [
        {
          title: 'Merchant Support',
          description: 'This team will assist you with account updates and statement questions.',
          phone: '877.828.0720',
        },
        {
          title: 'Technical Support',
          description: 'This team will assist you with any troubleshooting Clover Connect products such as swipers.',
          email: 'cardpointesupport@fiserv.com',
          phone: '484.581.7690',
        },
        {
          title: 'Equipment Orders',
          description: 'For any questions related to equipment orders, such as swipers.',
          email: 'deployments@cardconnect.com',
        },
        {
          title: 'Secure Trust - PCI Compliance',
          description: 'For help in gaining PCI compliance.',
          website: 'pci.securetrust.com/cardpointe',
          email: 'ccsupport@securetrust.com',
          phone: '877.257.0239',
        },
      ],
    },
    stripe: {
      title: 'Business Infusions Payments',
      tooltip: ['US & Canada Formats: (XXX) XXX-XXXX or XXX-XXX-XXXX', 'International: +XXX XXXXXXXXXX'],
      marketingText: `<p>Provide online, PCI compliant credit card processing for your practice with Business Infusions Payments.</p>
        <p>Cassadol credit card processing, called Business Infusions Payments, is powered by Stripe, Inc. Stripe is a payment processing company that is expected to surpass $1 trillion dollars of payment processing volume in 2024.  Cassadol chose Stripe as our payment processor because of their security, processing volume and reputation.  More information about Stripe can be found by visiting this link <a href="https://stripe.com/en-ca/newsroom/information" target="_blank" rel="noopener noreferrer">https://stripe.com/en-ca/newsroom/information</a>.</p>
        <p>Using an integrated credit card processing service will allow you to easily have your payments flow into your accounts receivable reporting within Cassadol.</p>
        <p>Before proceeding please take note of a few items:</p>
        <ul style='margin-left: 2em;'>
          <li>Rates are an industry competitive 2.95% plus $0.30 per transaction, with no monthly statement fee.</li>
          <li>Payouts will occur weekly on Mondays. The first payout can take upwards of 7 business days to process and follow Stripe's timeline for processing and paying out amounts.</li>
          <li>You are responsible for all payments processed on your connect account, both valid and fraudulent.</li>
          <li>You are responsible for additional fees associated to the connect account as Stripe may charge us for additional activity such as dispute fees, additional payout fees, or any other fees beyond regular services.</li>
          <li>Business Infusions is authorized to debit the Stripe connected account or if insufficient funds are in the connected account to debit the associated bank account for any disputes, including associated fees as soon as a dispute is received.</li>
        </ul>
        <p>Submitting this form will redirect you to Stripe to collect information about your business. Once you have finished the application you will be automatically redirected back to Cassadol.</p>
        <p>Before you begin, you should ensure you have the following information available to make the process as smooth as possible. In order to setup an account, you are required to go through a verification process as required by financial regulators.</p>
        <ul style='margin-left: 2em;'>
          <li>Practice details including legal structure, address, phone numbers and tax identification numbers.</li>
          <li>Ownership information about your practice, including details for any owners with 25% or more ownership. Owner information includes date of birth, address, tax identification numbers, and phone number.</li>
          <li>Banking information to be used for payouts.</li>
          <li>In some cases additional documentation may be required if automatic verification fails.</li>
        </ul>`,
      skipButton: 'Skip',
      unavailableMessage:
        'Business Infusions Payments is not currently available in your country. Please check back in the future for other options.',
    },
    finishButton: 'Finish',
    nextButton: 'Next',
    previousButton: 'Back',
    warningMessage: `The system you are in is a test and demo system only. The information you enter is not transferable to any live account. If you intend to register for a live account please do so at https://cassadol.software.vet.`,
  },
  auth: {
    currentPassword: {
      label: 'Current Password',
      errorMessage: 'Please input your current password',
    },
    password: {
      label: 'Password',
    },
    newPassword: {
      label: 'New Password',
      errorMessage: 'Please input a new password',
    },
    confirmPassword: {
      label: 'Confirm Password',
      errorMessage: 'Please confirm your password!',
      nonMatchingErrorMessage: 'The two passwords that you entered do not match!',
    },
    currentPin: {
      label: 'Current PIN',
      errorMessage: 'Please input your current PIN',
    },
    newPin: {
      label: 'New PIN',
      errorMessage: 'Please input a new PIN',
    },
    confirmPin: {
      label: 'Confirm PIN',
      errorMessage: 'Please confirm your PIN!',
      nonMatchingErrorMessage: 'The two PINs that you entered do not match!',
    },
    passwordChangeErrorMessage: 'Changing password failed. Please try again.',
    changePassword: 'Change Password',
    changePin: 'Change PIN',
    savePinMessage: 'PIN successfully changed',
    errorSavingPinMessage: 'There was an error saving the PIN',
  },
  support: {
    title: 'Support',
    supportNotEnabledText: 'Support page is not enabled. Please contact support.',
    pageDescription: `<p>Need Help? Submit a question to our AI powered ChatBot, called “Cassy”. If “Cassy” cannot answer your question our support team will still be available to assist. To reach our support team submit a ticket by emailing <a href="mailto:cassadol@businessinfusions.com" target="_blank" rel="noopener noreferrer">cassadol@businessinfusions.com</a>.
      <p>Cassadol is beginning research and development into Artificial Intelligence and “Cassy” the ChatBot is the first step in our learning and testing. Our entire team is aware of the perception of Chatbots’ and we share many of the same apprehensions. However, Artificial Intelligence is here to stay and we want to provide optimal support to Cassadol as we learn about how it can benefit our growing customer base internationally. Please be patient with this as we further enhance accuracy and please provide feedback on Cassy, good or bad, directly to <a href="mailto:cassadol@businessinfusions.com" target="_blank" rel="noopener noreferrer">cassadol@businessinfusions.com</a></p>`,
    submitText: 'Submit',
    requestMessage: 'Requesting data to Cassy',
    errorMessage: 'Something went wrong with your request, please try again.',
    responseErrorMessage:
      'Something went wrong with your request, please try again. If the error persists contact support.',
    fields: {
      chatInput: 'chat-input',
    },
  },
  settingsPage: {
    home: 'Home',
    users: 'Users',
    practiceSettings: 'Practice Settings',
    interestSettings: 'Interest Settings',
    services: 'Services',
    subscription: 'Subscription',
    addOns: 'Add Ons',
    adminSubscription: 'Admin Subscription',
    dataConversion: 'Data Conversion',
    cardConnectSettings: 'Clover Connect',
    referenceData: 'Reference Data',
    syncErrorLog: 'Sync Error Log',
    currencySettings: 'Currency and Date',
  },
  cardConnectSettings: {
    title: 'Clover Connect Settings',
    subtitle:
      'Once your Clover Connect account has been approved, these credentials will be generated for you. These are separate from your normal Clover Connect or Cassadol credentials.',
    merchantId: 'Merchant ID',
    userId: 'User ID',
    password: 'Password',
    columns: {
      name: 'Name',
      serialNumber: 'Serial Number',
      actions: 'Actions',
    },
    unavailableMessage:
      'Clover Connect is not currently available in your country. Please check back in the future for other options.',
    cardConnectInstructionTitle: 'In order to get set up with Clover Connect, please follow these instructions:',
    cardConnectInstructions: [
      'Cassadol has partnered with Clover Connect to provide credit card processing for your practice. Clover Connect provides processing services for thousands of veterinary practices and is PCI compliant so you do not need to worry about credit card theft and liability to your practice. When you click’ Clover Connect Application’ you will be re-directed to the Clover Connect website where there is more information about their services.',
      'In order to receive payments and have them flow through to the accounts receivable reporting within Cassadol, you must use Clover Connect. Rates are 2.9% plus $0.25 per transaction for all cards and a monthly statement fee. The funds are deposited to the account you designate on the next business day.',
      'Once your application has been submitted and approved Clover Connect will reach out to you directly and provide your MerchantId, Username and Password to entered below. You can also purchase equipment from Clover Connect to be able to swipe credit cards in addition to manual entry.',
    ],
    cardConnectSupportTitle: 'Clover Connect Support',
    cardConnectSupportInfo: [
      {
        title: 'Merchant Support',
        description: 'This team will assist you with account updates and statement questions.',
        phone: '877.828.0720',
      },
      {
        title: 'Technical Support',
        description: 'This team will assist you with any troubleshooting Clover Connect products such as swipers.',
        email: 'cardpointesupport@fiserv.com',
        phone: '484.581.7690',
      },
      {
        title: 'Equipment Orders',
        description: 'For any questions related to equipment orders, such as swipers.',
        email: 'deployments@cardconnect.com',
      },
      {
        title: 'Secure Trust - PCI Compliance',
        description: 'For help in gaining PCI compliance.',
        website: 'pci.securetrust.com/cardpointe',
        email: 'ccsupport@securetrust.com',
        phone: '877.257.0239',
      },
    ],
  },
  organizationSubscriptionSettings: {
    nameOnCard: 'Name on Card',
    billingAddress: 'Billing Address',
    physicalAddress: 'Physical Address',
    physicalAddressInfo: 'Must be a physical address, cannot be a PO Box',
    creditCardInfo: 'Credit Card Info',
    totalChargeForUsers: 'Total User Charge',
    numberOfUsers: 'Number of Users',
    couponCode: 'Coupon Code',
    update: 'Update',
    title: 'Subscription Settings',
    subscriptionLevel: 'Subscription Level',
    subscription: 'subscription',
    subscriptionSettings: 'Subscription',
    successMessage: 'Subscription updated successfully',
    completeSignup: 'Complete Signup',
    invalidCreditCard: 'Please enter a valid card',
    loadingError: 'Failed to load practice data',
    couponCodeExplanation:
      'Please note that if you are using a coupon code your credit card information will be captured but will NOT be charged until the end of your special offer or term.  If you decide to cancel your subscription before your special offer ends, no charges will be applied to your credit card. The subscription charges are in CAD for Canada and USD for all others.',
    textAboveCharge:
      'Your credit card will be charged in the amount above upon completion of the registration process. You will then be charged each month for your subscription. The subscription charges are in CAD for Canada and USD for all others.',
    textBelowCharge:
      'In order to complete the sign up, you will need to review and accept the Terms and Conditions of Use by clicking ‘Accept’ at the bottom of the text.  Click on the ‘> Terms and Conditions…’ to review and Accept.',
    accept: 'Accept',
    error: {
      acceptChargesValidationMsg: 'Accept Charges',
    },
    eula: {
      label: 'Terms and Conditions...',
      getStatus: (isAccepted: boolean) => (isAccepted ? 'Accepted' : 'Not Accepted'),
      accept: 'Accept',
      doNotAccept: 'Do not accept',
      errMsg: 'Please Accept the Terms and Conditions first',
      acceptExceptionMsg: 'Failed to accept terms and conditions',
      printBtnText: 'Print',
    },
  },
  emailSettingsPage: {
    title: 'Email Settings',
    message: [
      'Your SMTP server / service provider must be able to accept inbound SMTP emails from our servers located within Amazon Web Services, if they are unable to accept emails, then you will need to use our servers to send emails. All email sent via our server will come from a “@software.vet” address with a reply-to address of your choice.',
      'Your SMTP server may impose rate limits on the number of emails that can be sent at one time. Cassadol has no ability to' +
        ' control or bypass those rate limits if imposed.',
    ],
    recommendation: {
      title: 'Recommended Email Settings:',
    },
    gmail: {
      title: 'Gmail',
      description: (link: string) => [
        'Server Name = smtp.gmail.com',
        'Port = 587',
        'SSL Required - Enabled',
        'Username - Full email account',
        `Password - App Password is required. Learn more here - <a target="_blank" href=${link} rel="noopener noreferrer">Sign in with App Passwords - Google Account Help</a>`,
      ],
    },
    outlook: {
      title: 'Outlook / Hotmail',
      description: (link: string) => [
        'Server Name = smtp-mail.outlook.com',
        'Port = 587',
        'SSL Required - Enabled',
        `Password - App Password is required. Learn more here - <a target="_blank" href=${link} rel="noopener noreferrer">Using app passwords with apps that don't support two-step verification</a>`,
      ],
    },
    yahoo: {
      title: 'Yahoo',
      description: (link: string) => [
        'Yahoo imposes rate limiting, which may impact sending of emails from Cassadol.',
        'Server Name = smtp.mail.yahoo.com',
        'Port = 587',
        'SSL Required - Enabled',
        'Username - Full email account',
        `Password - An App password is required. Learn More Here - <a target="_blank" href=${link}>Generate and manage third-party app passwords | Yahoo Help</a>`,
      ],
    },
    aol_mail: {
      title: 'AOL Mail',
      description: (link: string) => [
        'AOL Mail imposes rate limiting, which may impact sending of emails from Cassadol.',
        'Server Name =smtp.aol.com',
        'Port = 587',
        'SSL Required - Enabled.',
        `Password - An App password is required. Learn more here - <a target="_blank" href=${link}>Create and manage third-party app passwords</a>`,
      ],
    },
    office365: {
      title: 'Office 365',
      description: (link1: string, link2: string, link3: string) => [
        'Server Name = smtp.office365.com',
        'Port = 587',
        'SSL Required - Enabled',
        'Username - Authentication email address',
        'Password - User Password – OR – if using two-factor authentication; an app password.',
        `Note: "SMTP Auth" must be enabled for your office 365 tenant. Include this link - <a target="_blank" href=${link1} rel="noopener noreferrer">Enable or disable SMTP AUTH in Exchange Online</a>`,
        `If two factor authentication is turned on you will need to use an App password - <a target="_blank" href=${link2} rel="noopener noreferrer">Manage 2FA App Passwords</a>`,
        `Setup Assistance - <a target="_blank" href=${link3} rel="noopener noreferrer">How to set up a multifunction device or application to send emails using Microsoft 365 or Office 365</a>`,
      ],
    },
    custom_domains: {
      title: 'Custom Domains',
      description: [
        'For custom email domains, you will need to contact the party responsible for providing the custom email domain to provide the appropriate settings.',
      ],
    },
    fields: {
      from: {
        label: 'Email',
      },
      displayName: {
        label: 'Display Name',
      },
      replyToEmail: {
        label: 'Reply To Email',
      },
      serverName: {
        label: 'Server Name',
      },
      portNumber: {
        label: 'Port Number',
      },
      requiresSSL: {
        label: 'SSL Required',
      },
      username: {
        label: 'Username',
      },
      password: {
        label: 'Password',
      },
    },
    createdEntity: 'email settings',
    testButtonText: 'Test Email',
    useSESRadioOptions: {
      yourSmtpSettings: 'Your Email Settings',
      ourSmtpSettings: 'Our Email Settings',
    },
    sendingErrorMessage: 'Error when sending email',
  },
  adminSubscription: {
    title: 'Subscription Processing',
    searchPlaceholder: 'Search for Organization',
    fields: {
      id: 'ID',
      organization: 'Organization',
      startDate: 'Start Date',
      nextBillingDate: 'Next Billing Date',
      lastBilled: 'Last Billed',
      billingInterval: 'Billing Interval',
      action: 'Action',
    },
  },
  addPatientPage: {
    title: 'Create a Patient',
    fields: {
      name: {
        label: 'Name',
        errorMessage: 'Please enter the name of the patient',
      },
      number: {
        label: 'Number',
      },
      dateOfBirth: {
        label: 'Date of birth',
      },
      status: {
        label: 'Status',
      },
    },
    buttons: {
      submit: 'Save',
    },
    getSuccessMessage: (patientNumber: string) => `Patient ${patientNumber} created successfully`,
    createdEntity: 'patient',
  },
  contactsPage: {
    title: 'Contacts',
    columns: {
      name: 'Name',
      status: 'Status',
      number: 'Number',
      email: 'Email',
      actions: 'Actions',
      tags: 'Tags',
      balance: 'Balance',
    },
    buttons: {
      addContact: 'Create Contact',
    },
    searchPlaceholder: 'Search for contact',
  },
  viewContactPage: {
    accountBalance: 'Account Balance',
    actions: 'Actions',
    emailStatement: 'Email Statement',
    printStatement: 'Print Statement',
    mergeStatement: 'Merge',
    storedCards: 'Stored Cards',
    deleteButton: 'Delete Contact',
    deleteContactModalConfirmTitle: 'Delete Contact',
    deleteContactConfirm: (contactName = 'this contact') => `Are you sure you want to delete "${contactName}"?`,
    deleteSuccessMessage: 'Contact successfully deleted',
    assignAll: 'Assign All',
    phoneUpdateSuccess: "Contact's phone saved",
    phoneDeleteSuccess: "Contact's phone deleted",
    noProcessorMessage: 'Storing credit cards is only available with credit card processing enabled.',
    selectContactModal: {
      selectSearchTitle: 'Select contact to merge into',
      searchPlaceholder: 'Search for contact',
      createContact: 'Create Contact',
      mergeContactModalTitle: 'Merge Contacts',
      mergeContactsModalDescription: ['Completing this action will merge', 'into', 'Do you want to proceed?'],
      currentlyEditingOwnersPopConfirm: 'Are you sure want to continue without saving your ownership changes?',
      modalCancelButton: 'Cancel',
      modalSelectButton: 'Select',
    },
    storedCardsModal: {
      title: 'Credit Cards',
      addNewCard: 'Add New Card',
      addCardModal: {
        title: 'Add Card',
        okButton: 'Verify & Add',
        isPrimary: 'Set as Primary',
        cardNote: 'Card Note',
      },
    },
    tabs: {
      details: 'Details',
      options: 'Options',
      properties: 'Properties',
      records: 'Records',
      ledger: 'Ledger',
      patients: 'Patients',
      alerts: 'Alerts',
      communicationLog: 'Communication Log',
    },
    alerts: {
      columns: {
        alert: 'Alert',
        note: 'Note',
        actions: 'Actions',
      },
      deleteAlertText: 'Are you sure you want to delete this alert?',
    },
    searchPatient: 'Search Patient:',
    unknownContact: 'The selected contact does not exist!',
    getSuccessMessage: (contactName: string) => `Contact ${contactName} has been updated successfully`,
    entity: 'contact',
    details: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      status: 'Status',
      updated_by: 'Updated By: ',
    },
    records: {
      errorLoadingRecords: 'Error: Could not load contact records!',
    },
    identification: {
      title: 'Additional Contact Identification',
      types: {
        CID_GVL: 'GVL Number',
      },
    },
    properties: {
      sendType: 'Communication Preference',
      statementIgnore: 'Ignore on Statement Run',
      taxExempt: 'Tax Exempt',
      chargeInterest: 'Charge Interest',
      emr: 'EMR',
    },
    patients: {
      patientRelation: 'Relationship',
    },
    unknownReferenceData: "The contact's ref data does not exist",
    createPatient: 'Create Patient',
    headers: {
      options: 'Options',
      note: 'Info',
      communication: 'Communication Preferences',
    },
    communicationTypes: {
      statementRun: 'Statement Run',
      reminderRun: 'Reminder Run',
    },
    communicationTable: {
      headers: {
        commType: 'Communication Type',
        method: 'Method',
        to: 'To',
        cc: 'CC',
      },
    },
    communicationLog: {
      searchLabel: 'Search',
      searchPlaceholder: 'Search for logs',
      sentAt: 'Sent At',
      recipients: 'RECIPIENTS',
      cc: 'CC',
      bcc: 'BCC',
    },
    conflictWarningMessage: (contactName = 'contact') =>
      `The delete of ${contactName} has been prevented due to existing relationships. Please inactivate the record instead. `,
    emailModal: {
      title: 'Email Requirement',
      instruction: 'An email address is required for this contact in order to set this preference.',
    },
    thirdPartyEmailValidationFailed: (thirdPartyName: string) =>
      `This contact's email is not valid for use with ${thirdPartyName}`,
  },
  printStatementModal: {
    title: 'Print Statement',
    labels: {
      date: 'Date',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
    printButton: 'Print',
    errorGeneratingReport: 'Error when generating the statement report',
  },
  emailModal: {
    title: 'Email',
    fields: {
      to: {
        label: 'To',
      },
      subject: {
        label: 'Subject',
      },
      body: {
        label: 'Body',
      },
      attachments: {
        label: 'Add File',
      },
    },
    fixedAttachmentLabel: 'Attachment:',
    sendButtonText: 'Send Email',
    successMessage: 'Email Sent',
  },
  emailModalForInvoiceReport: {
    title: 'Send Invoice Report',
  },
  emailModalForLedgerReceipt: {
    title: 'Send Receipt',
    errorGeneratingReport: 'Error when generating the receipt report',
  },
  emailModalForContactStatement: {
    title: 'Send Statement',
    subject: 'Statement for',
    fields: {
      date: 'Date',
      startDate: 'Start date',
      endDate: 'End date',
    },
  },
  emailModalForPatientHistory: {
    title: 'Send Patient History',
    fields: {
      contactFieldLabel: 'Report for Contact',
      startDate: 'Start date',
      endDate: 'End date',
      date: 'Date',
    },
  },
  emailModalForPatientVaccine: {
    title: 'Send Patient Vaccine Report',
  },
  printModalForPatientHistory: {
    title: 'Select Contact for Patient History Report',
    contactFieldLabel: 'Report for Contact',
    print: 'Print',
    fields: {
      startDate: 'Start Date',
      endDate: 'End Date',
      date: 'Date',
    },
  },
  serviceGLAccountModal: {
    title: 'New General Ledger Account',
    GLAccount: 'GL Account',
    fields: {
      number: 'Number',
      name: 'Name',
    },
  },
  viewContactLedgerPage: {
    title: 'Ledger',
    columns: {
      number: 'Number',
      financial_date: 'Date',
      patient_name: 'Patient',
      type_name_key: 'Reference',
      balance: 'Balance',
      total: 'Total',
      status: 'Status',
      financial_outstanding: 'Due',
      note: 'Note',
      contact_name: 'Contact',
      payment_type: 'Payment Type',
      rollingTotal: 'Balance after',
      actions: 'Actions',
    },
    actionItems: {
      details: 'Details',
    },
    errorGeneratingReport: 'Error when generating the receipt',
    defaultValues: {
      noFinancialDate: 'Now',
    },
    unknownContact: 'The selected contact does not exist!',
    getLedgerUpdateSuccessMessage: (ledgerEntryNumber: string) => `${ledgerEntryNumber} has been updated successfully`,
    getSuccessMessage: (contactName: string) => `Contact ${contactName} has been updated successfully`,
    deleteLedgerEntryConfirmationMessage: (nameKey: LedgerEntryNameKey, reversal?: boolean) =>
      `Are you sure you wish to delete this ${ledgerEntryConfigs[nameKey].text}${reversal ? ' reversal' : ''}?`,
    deleteLedgerEntrySuccessMessage: (nameKey: LedgerEntryNameKey) =>
      `${ledgerEntryConfigs[nameKey].text} successfully deleted`,
    entity: 'contact',
    invoiceNumber: 'Invoice - ',
    detailsLoading: 'Loading Details...',
  },
  billingHistoryPage: {
    title: 'Billing History',
    columns: {
      date: 'Date',
      invoiceNumber: 'Invoice number',
      billingPeriod: 'Billing period',
      amount: 'Amount',
      actions: 'Actions',
    },
    actions: {
      download: 'Download',
    },
    errorMessage: {
      organizationLoad: 'Failed to load billing history',
    },
    report: {
      errorMsg: 'Error when generating Billing History Report',
    },
  },
  invoicesPage: {
    title: 'Invoices',
    errorClosing: 'Error closing Invoices',
    columns: {
      number: 'Number',
      date: 'Date',
      total: 'Total',
      contact_names: 'Billing',
      status_name_key: 'Status',
      patient_name: 'Patient',
      actions: 'Actions',
      paymentApply: 'Payment',
    },
    buttons: {
      addInvoice: 'Create Invoice',
      addCredit: 'New Credit',
      addPayment: 'New Payment',
      addAccountCharge: 'New Account Charge',
      startBatch: 'Start Batch Close',
      closeBatch: 'Close Batch',
      cancel: 'Cancel',
      confirmation: 'Are you sure you want to complete the selected invoices?',
    },
    searchPlaceholder: 'Search for invoice',
    addInvoiceModal: {
      title: 'Create Invoice',
      patientTag: (name: string, number: string) => `Patient: ${number && '#' + number} ${name}`,
      fields: {
        patient: 'Patient:',
        billTo: 'Bill To:',
        details: 'Details:',
      },
      noOwnershipError: 'You cannot create an invoice for a patient without current ownership.',
      ownershipCancelText:
        'You will need to set the ownership for this patient prior to invoicing, do you want to continue?',
      getSuccessMessage: (invoiceNumber: string) => `Invoice ${invoiceNumber} has been created successfully`,
      other: 'Other',
      stakeholder: 'Contact',
      errorSelectingContact: 'Error selecting contact',
      primary: 'Primary',
      percentCheck: {
        passed: 'Split matches 100%',
        failed: "Split doesn't match 100%",
      },
      primaryCheckFailed: 'Primary contact missing',
      invoiceTotal: 'Split Total',
    },
  },
  invoicePage: {
    invoice: 'Invoice',
    loadingError:
      'Error loading the selected invoice. Please click refresh to attempt to reload the invoice. If this issue persists then please contact Cassadol support with the specific invoice details.',
    refresh: 'Refresh',

    invalidItemMove:
      'You cannot move this item outside of the date it is currently on. If you want to change the date of service, please edit it.',
    unsavedCharges: 'Unsaved Charges',
    existingCharges: 'Existing Charges',
    saveCharges: 'Save Charges',
    inoviceFooter: {
      title: 'Invoice Footer',
      placeholder: 'Invoice footer content',
    },
    serviceFieldPlaceholder: 'Add Service',
    doctorPlaceholder: 'Select a doctor',
    errors: {
      serviceNotFound: 'The selected service does not exist',
      businessCenterLocationNotSet: 'Please set a business center and location for the invoice first',
    },
    fields: {
      date: 'Date',
      doctor: 'Doctor',
      addService: 'Service',
    },
    subTotal: 'Sub Total',
    total: 'Total',
    splitTotalsHeader: 'Split Details:',
    amountNotAvailable: 'N/A',
    editingIndicator: '(updated after editing)',
    onlinePendingChargesIndicator: 'Save the pending charges to see an updated total',
    offlinePendingChargesIndicator: 'Please Go online to process invoice changes and to see updated totals',
    addingService: 'Adding service...',
    deleteInvoice: 'Delete Invoice',
    deleteTooltip:
      'Invoices in a completed state cannot be deleted. If you wish to delete this invoice you must reopen it.',
    deleteInvoiceConfirm: 'Are you sure you want to delete this invoice?',
    completed: 'Completed',
    complete: 'Complete',
    invoiceCompleted: 'Invoice has been set to completed',
    invoiceOpened: 'Invoice has been reopened',
    deleteInvoiceSuccessMessage: 'Invoice successfully deleted',
    fillConsumed: ' - A fill was consumed from: ',
    deletingInvoice: 'Deleting invoice ...',
    editBillingModal: {
      title: 'Edit Invoice Billing',
      current: 'Current',
    },
    email: 'Email',
    print: 'Print',
    payment: 'Payment',
    offlineReadOnlyMessage: 'While offline invoices are read-only',
    alreadyExistingServiceMessage:
      'This item with the same date of service already exists on the invoice. Do you want to still add this item?',
    confirmSaveVaccineDefault: {
      title: 'Vaccine default data',
      text: 'Do you wish to save the changes as default vaccine data?',
      okButtonLabel: 'Yes',
      cancelButtonLabel: 'No',
    },
  },
  reports: {
    errorOpeningReport: 'Unable to open report',
    errorGeneratingReport: 'Error generating selected report.',
    missingParams: 'Error generating report. Missing required params',
    info: {
      title: 'Cassadol Reports',
      author: 'Cassadol',
      subject: 'Report',
    },
  },
  invoiceReport: {
    printInvoiceReportModal: {
      title: 'Select Contact for Invoice Report',
      getPdfButton: 'Open Invoice Report',
      contactFieldLabel: 'Report for Contact',
    },
    errorGeneratingReport: 'Error when generating the invoice report',
  },
  statementReport: {
    errorGeneratingReport: 'Error when generating the statement report',
  },
  addContactPage: {
    title: 'Create new contact',
    mailAddressTrigger: 'Same Mailing Address',
    fields: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      status: 'Status',
    },
    buttons: {
      save: 'Save',
      clear: 'Clear',
      select: 'Select',
    },
    contactStatusesNotLoaded: 'Error: could not load possible contact statuses!',
    getSuccessMessage: (contactName: string) => `Contact ${contactName} created successfully`,
    createdEntity: 'contact',
  },
  renderedServicesTable: {
    columns: {
      date: 'Date',
      doctor: 'Doctor',
      description: 'Description',
      unit: 'Unit',
      quantity: 'Qty',
      price: 'Price',
      total: 'Total',
      actions: 'Actions',
      note: 'Note',
    },
    controlledDrugPopover: {
      bottleNumber: 'Bottle Number',
    },
    vaccinationPopover: {
      manufacturerName: 'Manufacturer',
      lotNumber: 'Lot number',
      serialNumber: 'Serial number',
      expiryDate: 'Expiry date',
    },
    reminderPopover: {
      name: 'Name',
      date: 'Date',
      note: 'Note',
    },
    prescriptionPopover: {
      number: 'Rx Number',
      contact: 'Contact',
      patient: 'Patient Name',
      prescribedUser: 'Prescribed By',
      prescribedDate: 'Prescription Date',
      prescriptionExpiryDate: 'Prescription Expiry Date',
      productExpiryDate: 'Product Expiry Date',
      refills: 'Refills',
      itemDescription: 'Description',
      itemQuantity: 'Quantity',
      itemUnit: 'Unit',
      instructions: 'Instructions',
    },
    deleteConfirmationText: 'Are you sure you want to delete this service?',
    deleteSuccessMessage: (serviceName: string) => `Service ${serviceName} was deleted successfully`,
    missingValuesErrorMessage: 'Please fill in missing values',
    negativeTotalErrorMessage: 'Total value cannot be negative',
    minTotalErrorMessage: `Total value cannot be below -${MAX_INPUT_AMOUNT}`,
    maxTotalErrorMessage: `Total value cannot be over ${MAX_INPUT_AMOUNT}`,
    editNoteModalTitle: (service: ServiceRendered) => `${service.name} - ${service.date}`,
    addNoteModalTitle: (service: ServiceRendered) => `Add note to ${service.name} on ${service.date}`,
    titleError: 'Error loading the title. Please refresh the page.',
    note: {
      offlineWarning: 'You have gone offline while editing a note, please go online to finish saving your changes',
    },
  },
  servicesPage: {
    title: 'Services',
    addServiceButton: 'Create Service',
    searchPlaceholder: 'Search for service',
    columns: {
      name: 'Name',
      unit_name: 'Unit',
      price: 'Price',
      glAccount: 'GL Account',
      inactive: 'Status',
      tax: 'Tax',
    },
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  servicePage: {
    tabs: {
      details: 'Details',
      notes: 'Notes',
      bundle: 'Bundle',
      labLink: 'Lab Link',
      hisa: 'HISA',
    },
  },
  serviceBundlePage: {
    addService: 'Add Service',
    saveBundle: 'Save Bundle',
    promptForItems: 'Prompt for items',
    columns: {
      quantity: 'Quantity',
      actions: 'Actions',
      prompt: 'Prompt',
      total: 'Total',
      behavior: 'Behavior',
    },
    behaviorOptions: {
      notPackaged: 'Not Packaged',
      package: 'Package',
      packageAndPrint: 'Package & Print',
    },
    tooltips: {
      unchecked: 'This item will be un-checked during the bundle prompt',
      checked: 'This item will be checked off during the bundle prompt',
      force: 'This item will be checked and unable to be unchecked during the billing prompt',
      notPackagedBehavior: 'This item will not be packaged under the parent item.',
      packageBehavior:
        "This item will packaged under the parent item and the total rolled up into the parent item's total. This item will not print on the invoice.",
      packageAndPrintBehavior:
        "This item will packaged under the parent item and the total rolled up into the parent item. This item will print on the invoice without it's line item total.",
    },
    radioButtonTitles: {
      unchecked: 'Unchecked',
      checked: 'Checked',
      force: 'Force',
    },
    modal: {
      title: 'Bundle Details',
      submitButton: 'Save',
    },
    errorDelete: 'Error deleting service.',
    bundleRemoved: 'Service removed.',
    errorSaving: 'Error saving the bundle.',
    service: 'Service',
    savedSuccessfully: 'Service bundle saved successfully.',
  },
  labLinkPage: {
    fields: {
      lab: {
        title: 'Lab',
        placeholder: 'Please select a lab',
      },
      test: {
        title: 'Test',
        placeholder: 'Please select a test',
      },
      actions: {
        title: 'Actions',
      },
    },
    deleteEntryConfirm: 'Delete lab entry?',
    addNewEntry: 'Add new entry',
    addNewEntryDisabled: 'You can only have one lab associated to a service. Please remove the current lab first.',
  },
  addServicePage: {
    title: 'Create new Service',
    save: 'Add',
    saveAnother: 'Add and create another',
    fields: {
      name: 'Name',
      glAccount: 'GL Account',
      unit: 'Unit',
      quantity_default: 'Default Quantity',
      price: 'Price',
      cost: 'Cost',
      markup: 'Markup %',
      active: 'Active',
      type: 'Type',
      type_standard: 'Standard',
      type_controlled: 'Controlled',
      type_vaccine: 'Vaccine',
      microchip: 'Microchip',
      prescription_prompt: 'Prescription',
      reminder: 'Reminder',
      duration: 'Duration',
      tax: 'Tax',
      quantity_prompt: 'Prompt for Quantity',
      roa: 'Route of Administration',
      rabies_tag: 'Rabies Tag',
      reason: 'Require Reason',
      enable_emr: 'Enable EMR',
      options: 'Options:',
      withdrawal: 'Withdrawal',
      note: 'Info',
      notePlaceholder: 'Service Note',
      autoDecease: 'Auto Decease Patient',
      vaccineDetails: 'Vaccine Details',
      manufacturer: 'Manufacturer',
      lotNumber: 'Lot Number',
      serialNumber: 'Serial Number',
      expiryDate: 'Expiry Date',
    },
    markupWarning: 'Changing the price negates the service markup',
    createdEntity: 'service',
    getSuccessMessage: (serviceName: string) => `Service ${serviceName} created successfully`,
    addEntry: 'Add New',
  },
  viewServicePage: {
    missingService: 'The selected service does not exist!',
    entity: 'service',
    getSuccessMessage: (serviceName: string) => `Service ${serviceName} updated successfully`,
    fields: {
      default: 'Default Note',
      defaultPrescriptionInstructions: 'Default Prescription Instructions',
      type: 'Type',
      typeDropdownPlaceholder: 'Note Type',
      content: 'Note content',
    },
    prescriptionFieldMessage:
      'Please enable prescriptions on this billing code before setting a default prescription note.',
    noteDeleteConfirmation: 'Note will be permanently deleted. Are you sure you want to proceed?',
  },
  hisaServicePage: {
    fields: {
      hisaCategory: 'HISA Category',
    },
    hisaDisabledTooltip: 'Please enable HISA to enable this option',
    selectPlaceholder: 'Select Category',
    missingCategories: 'HISA Categories Not Found',
    categoryRequirements: {
      requires_dob: 'Requires DOB',
      requires_gender: 'Requires Gender',
      requires_microchip: 'Requires Microchip',
      requires_email: 'Requires Email',
      requires_roa: 'Requires ROA',
      requires_reason: 'Requires Reason',
      requires_results: 'Requires Result',
      requires_limb_treated: 'Requires Limb Treated',
      requires_dosage: 'Requires Dosage',
      requires_condition_treated: 'Requires Condition Treated',
      requires_tests_and_diagnostics: 'Requires Tests and Diagnostics',
      requires_treatment_type: 'Requires Treatment Type',
      requires_time: 'Requires Time',
      requires_medical_name: 'Requires Medical Name',
      requires_drug_name: 'Requires Drug Name',
      requires_description: 'Requires Description',
      requires_vaccine_name: 'Requires Vaccine Name',
    },
  },
  addressBlockComponent: {
    validationMessage: {
      canada: 'Postal Code must be in the following format A9A 9A9',
      usa: 'Zip code must be 5 digits',
      southAfrica: 'Postal code must be 4 digits',
      postalCode_nnnn: 'Postal code must be 4 digits',
      postalCode_nnnnn: 'Postal code must be 5 digits',
      postalCode_ad_method: 'Postal code must be in format AD999',
      postalCode_nnnn_or_annnnaaa: 'Postal code must be in format 9999 or A9999AAA',
      postalCode_az_method: 'Postal code must be in format AZ 9999',
      postalCode_nnn_or_nnnn: 'Postal code must be 3 or 4 digits',
      postalCode_bb_method: 'Postal code must be in format BB99999',
      postalCode_nnnnnn: 'Postal code must be 6 digits',
      postalCode_aasnn_or_aasaa: 'Postal code must be in format AA XX',
      postalCode_aannnn: 'Postal code must be in format AA9999',
      postalCode_anasnan: 'Postal code must be in format A9A 9A9',
      postalCode_ky_method: 'Postal code must be in format KY-9999',
      postalCode_nnnnnnn: 'Postal code must be 7 digits',
      postalCode_nnnsnn: 'Postal code must be in format 999 99',
      postalCode_fo_method: 'Postal code must be in format FO-999',
      postalCode_aa_nnnn_nnnn: 'Postal code must be in format AA-9999-9999',
      postalCode_aansnaa_or_aannsnaa: 'Postal code must be in format AA9 9AA or AA99 9AA',
      postalCode_nnn: 'Postal code must be 3 digits',
      postalCode_aannnn_or_nnnnn: 'Postal code must be in format AA9999 or 99999',
      postalCode_nnnnn_nnnnn: 'Postal code must be in format 99999-99999',
      postalCode_nn: 'Postal code must be 2 digits',
      postalCode_nnn_nnnn: 'Postal code must be in format 999-9999',
      postalCode_je_method: 'Postal code must be in format JE9 9AA or JE99 9AA',
      postalCode_lv_method: 'Postal code must be in format LV-9999',
      postalCode_nnnnn_or_nnnnsnnnn: 'Postal code must be in format 99999 or 9999 9999',
      postalCode_lt_method: 'Postal code must be in format LT-99999',
      postalCode_aaasnnnn: 'Postal code must be in format AAA 9999',
      postalCode_im_method: 'Postal code must be in format IM9 9AA or IM99 9AA',
      postalCode_md_method: 'Postal code must be in format MD9999',
      postalCode_nnnnsaa: 'Postal code must be in format 9999 AA',
      postalCode_nn_nnn: 'Postal code must be in format 99-999',
      postalCode_nnnn_nnn: 'Postal code must be in format 9999-999',
      postalCode_aaaasnaa: 'Postal code must be in format AAAA 9AA',
      postalCode_kn_method: 'Postal code must be in format KN9999',
      postalCode_lc_method: 'Postal code must be in format LC99 999',
      postalCode_vc_method: 'Postal code must be in format VC9999',
      postalCode_ws_method: 'Postal code must be in format WS9999',
      postalCode_nnnnn_nnnn_or_nnnnn: 'Postal code must be in format 99999-9999 or 99999',
      postalCode_aasnnnnn: 'Postal code must be in format AA 99999',
      postalCode_annn: 'Postal code must be in format A999',
      postalCode_tw_method: 'Postal code must be in format 999, 999-99 or 999-999',
      postalCode_uk_method: 'Postal code must be in format A9, A99, AA9, AA99, A9A, AA9A or AAA',
      postalCode_vg_method: 'Postal code must be in format VG9999',
      zipCode_nnnn: 'Zipcode must be 4 digits',
      zipCode_nnnnn: 'Zipcode must be 5 digits',
      cep_nnnnn_nnn: 'CEP must be in format 99999-999',
      postalRoutingNumber_nnnsnn: 'Postal Routing Number must be in format 999 99',
      plz_nnnnn: 'PLZ must be in format 99999',
      pinCode_nnnnnn_or_nnnsnnn: 'PIN Code must be in format 999999 or 999 999',
      postalAddress_ie_method: 'Postal Address must be in format A99 XXXX',
      cap_nnnnn: 'CAP must be 5 digits',
      cp_nnnnn: 'CP must be 5 digits',
      pak_nnnnn: 'PAK must be 5 digits',
    },
    fields: {
      name: 'Name',
      addressType: 'Address Type',
      address_1: 'Address 1',
      address_2: 'Address 2',
      address_3: 'Address 3',
      postal_zip: 'Zip',
      postalCode: 'Postal Code',
      zipCode: 'Zip Code',
      cep: 'CEP',
      postalRoutingNumber: 'Postal Routing Number',
      plz: 'PLZ',
      pinCode: 'PIN Code',
      postalAddress: 'Postal Address',
      cap: 'CAP',
      cp: 'CP',
      pak: 'PAK',
      city: 'City',
      state: 'State',
      province: 'Province',
      country: 'Country',
      emirate: 'Emirate',
      county: 'County',
      parish: 'Parish',
      parish_or_dependency: 'Parish or dependency',
      region: 'Region',
      state_or_territory: 'State or region',
      district_or_city: 'District or city',
      governorate: 'Governorate',
      division: 'Division',
      oblast: 'Oblast',
      district: 'District',
      department: 'Department',
      special_municipality: 'Special municipality',
      entity: 'Entity',
      municipality: 'Municipality',
      prefecture: 'Prefecture',
      province_or_region: 'Province or region',
      island: 'Island',
      regional_state: 'Regional state',
      administrative_region: 'Administrative region',
      county_or_city: 'County or city',
      island_group: 'Island group',
      popularate: 'Popularate',
      canton: 'Canton',
      atoll: 'Atoll',
      local_council: 'Local council',
      voivodeship: 'Voivodeship',
      federal_subject: 'Federal subject',
      autonomous_community: 'Autonomous community',
      council: 'Council',
    },
    placeholder: {
      country: 'Select a country',
      state: 'Select a state',
      province: 'Select a province',
      emirate: 'Select an emirate',
      county: 'Select a county',
      parish: 'Select a parish',
      parish_or_dependency: 'Select a parish or dependency',
      region: 'Select a region',
      state_or_territory: 'Select a state or region',
      district_or_city: 'Select a district or city',
      governorate: 'Select a governorate',
      division: 'Select a division',
      oblast: 'Select an oblast',
      district: 'Select a district',
      department: 'Select a department',
      special_municipality: 'Select a special municipality',
      entity: 'Select an entity',
      municipality: 'Select a municipality',
      prefecture: 'Select a prefecture',
      province_or_region: 'Select a province or region',
      island: 'Select an island',
      regional_state: 'Select a regional state',
      administrative_region: 'Select an administrative region',
      county_or_city: 'Select a county or city',
      island_group: 'Select an island group',
      popularate: 'Select a popularate',
      canton: 'Select a canton',
      atoll: 'Select an atoll',
      local_council: 'Select a local council',
      voivodeship: 'Select a voivodeship',
      federal_subject: 'Select a federal subject',
      autonomous_community: 'Select an autonomous community',
      council: 'Select a council',
    },
    checkBoxSameAddressesText: 'Same Mailing Address',
    title: 'Address',
    titleMailingAddress: 'Mailing Address',
  },
  userTablePage: {
    title: 'Users',
    columns: {
      name: 'Name',
      roles: 'Roles',
      email: 'Email',
      phone: 'Phone',
      status: 'Status',
      actions: 'Actions',
    },
    buttons: {
      addUser: 'Create User',
    },
  },
  userSettings: {
    title: 'User Settings',
    tabs: {
      generalDetails: 'General Details',
      permissions: {
        title: 'User Permissions',
        fields: {
          administrator: 'Administrator',
          settings: 'Settings',
          subscription: 'Subscription',
          generalReports: 'General Reports',
          allowOffline: 'Allow Offline',
          dashboard: 'Dashboard',
        },
      },
    },
  },
  approvalsPage: {
    title: 'Partner Data - Approvals',
    columns: {
      date: 'Date',
      patient: 'Patient',
      doctor: 'Doctor',
      quantity: 'Quantity',
      ownership: 'Ownership',
      relationship: 'Relationship',
      service_name: 'Service Rendered',
      validation_failed: 'Validation Errors',
      info: 'Info',
    },
    buttons: {
      approve: 'Approve & Send',
      editValidationDetails: 'Edit Validation Details',
      editValidationDetailsTooltip: 'This approval requires additional information prior to submission.',
    },
    validationError: 'Failed Validation',
    editValidationModal: {
      title: 'Edit Validation Details',
      fields: {
        email: {
          label: 'Contact Email',
          title: (contactName: string) => `Contact Details - ${contactName}`,
        },
        dob: {
          label: 'Date of birth',
        },
        gender: {
          label: 'Sex',
        },
      },
      confirm: 'Save',
    },
    approvalTypeName: {
      add: 'Add',
      delete: 'Delete',
      update: 'Update',
    },
    errorApproving: 'Error occurred during Approval',
  },
  historyPage: {
    title: 'Partner Data - History',
    columns: {
      thirdParty: 'Partner',
      date: 'Date Sent',
      doctor: 'Doctor',
      patient: 'Patient',
      owner: 'Owner',
      info: 'Info',
      service_name: 'Service Rendered',
      quantity: 'Quantity',
      status: 'Status',
    },
    status: {
      notApproved: 'Pending',
      queued: 'Sending',
      sent: 'Sent',
      error: 'Error',
    },
  },
  referenceData: {
    filters: {
      checked: 'Checked',
      unchecked: 'Unchecked',
    },
    contactTypes: {
      owner: 'Owner',
      trainer: 'Trainer',
      boarding: 'Boarding',
      farrier: 'Farrier',
      referralDoc: 'Referral Doc',
      referralHosp: 'Referral Hosp',
      referredBy: 'Referred by',
      emr: 'EMR',
      doctor: 'Doctor',
      staff: 'Staff',
      farmManager: 'Farm Manager',
      broodManager: 'Brood Manager',
      yearlingManager: 'Yearling Manager',
      assistantTrainer: 'Assistant Trainer',
      leasee: 'Leasee',
      leasor: 'Leasor',
    },
    tabs: {
      species: 'Species',
      breeds: 'Breeds',
      colors: 'Colors',
      genders: 'Sex',
      taxTypes: 'Tax Types',
      userTypes: 'User Types',
      contactStatus: 'Contact Status',
      fileTypes: 'File Types',
      noteTypes: 'Note Types',
      quickNotes: 'Quick Notes',
      infoTypes: 'Info Types',
      generalLedger: 'General Ledger',
      ledgerTypes: 'Ledger Types',
      reminderStatus: 'Reminder Statuses',
      contactTypes: 'Contact Types',
      paymentTypes: 'Payment Types',
      patientAlerts: 'Patient Alerts',
      contactAlerts: 'Contact Alerts',
    },
    columnNames: {
      name: 'Name',
      sort_order: 'Sort Order',
      percentage: 'Percentage',
      compounded: 'Compounded',
      code: 'Code',
      default: 'Default',
      doctor: 'Doctor',
      caregiver: 'Caregiver',
      active: 'Active',
      warning: 'Warning',
      stop: 'Stop',
      actions: 'Actions',
      species_id: 'Species',
      patient: 'Patient Note',
      contact: 'Contact Note',
      patient_print: 'Patient Print',
      contact_print: 'Contact Print',
      invoice_print: 'Invoice Print',
      gl_number: 'Number',
      system_status_id: 'System status',
      is_default: 'Default',
      contactTypes: 'Contact Types',
      enabled: 'Enabled',
      prompt_invoice: 'Prompt on Invoice',
      prompt_patient: 'Prompt on Patient',
      prompt_contact: 'Prompt on Contact',
      external_gl_id: 'External Account',
      external_id: 'External Payment',
      external_tax_id: 'External Tax',
      general_ledger_id: 'General Ledger',
      is_receivables_account: 'Receivables Account',
      note: 'Note',
    },
    placeholders: {
      external_id: 'Select Payment',
      external_tax_id: 'Select Tax',
      general_ledger_id: 'Select Account',
      note_type_id: 'Select Note Type',
      addNoteType: 'Add Note Type',
    },
    errors: {
      patientOrContactError: 'One or both of contact or patient must be chosen',
      requiredFieldMissing: (fieldName: string) => `${fieldName} is required`,
      cannotDeleteLinkedGlAccount:
        'Before deleting, please ensure that the following entities are unlinked from the general ledger account: ',
    },
    deleteReferenceEntryText: 'Are you sure you want to delete this reference entry?',
    deleteReminderText: 'Are you sure you want to delete this reminder?',
    sortOrderTooltip: 'The larger the value, the higher it appears in the list',
    addNewEntry: 'Add new entry',
    filterLabel: 'Filter:',
    noteTypeLabel: 'Note Type',
    reminderStatusSystemStatusError: 'At least 1 of each system status must exist.',
    reminderStatusSystemStatusDefaultError: 'There must be exactly 1 default for each system status.',
    accountingNotConfigured: 'This organization does not have an external accounting system configured.',
    creditCardCannotEnableWarning:
      'This payment type is enabled automatically with payment processing and cannot be disabled.',
    creditCardCannotEditWarning:
      'This payment type is tied to credit card processing, to enable please configure payment processing through Cassadol.',
    stripePaymentsNotEnabledWarning:
      'This feature is related to Business Infusions Payments only and is currently disabled. Please enable Business Infusions Payments in the Add Ons section of Settings.',
  },
  loadingComponent: {
    loading: 'Loading...',
    saving: 'Saving...',
    processingTransaction: 'Processing Transaction...',
  },
  missingSpecies: 'Failed to load Species from the database, please contact support to ensure the data is valid',
  missingAddress: 'Failed to load address data from the database, please contact support to ensure the data is valid',
  missingCountryPlaceholder: 'Please select a Country first',
  navigation: {
    unsaved: 'Are you sure you wish to leave the unfinished changes?',
  },
  tabChangeWarning: {
    title: 'Unsaved changes',
    message: 'Changing tabs without saving will lead to data loss. Are you sure you wish to move to a different tab?',
    confirmButton: 'Confirm',
  },
  rapidBilling: {
    pageTitle: 'Rapid Billing',
    patientSelectPlaceholder: 'Select patients',
    defaultPatientsText: 'Patients for next service',
    servicePatientsText: 'Patients for selected service',
    getSelectPatientsErrorMessage: (serviceNames: string) => `Please select patients for ${serviceNames}`,
    invalidPatientsErrorMessage: (patientNames: string) =>
      `You cannot create an invoice for a patient without current ownership: ${patientNames}`,
    searchPatientsButton: 'Search Patients',
    deleteBatchConfirm: 'Are you sure you want to delete this batch?',
    deleteButtonText: 'Delete Batch',
    sendButtonText: 'Send Batch to Invoices',
    sendButtonOfflineText: 'Save Batch to Queue',
    saveChangesButtonText: 'Save Changes to Queue',
    viewQueuedBatchesButton: 'View Queued Batches',
    hideQueuedBatchesButton: 'Hide Queued Batches',
    batchWithPendingChanges: 'There are unsaved changes in this Batch.',
    options: {
      completeInvoices: 'Complete Invoices',
      newInvoices: 'Create new Invoices',
      addToInvoices: 'Add to In-Progress Invoices',
    },
    error: {
      serviceInfoAndText: 'Failed to load Service Info and Text',
      serviceText: 'Failed to fetch Service Text',
      service: 'Failed to fetch Bundled Service',
    },
    selectPatientMessage: 'Select a patient',
  },
  adminRedirection: {
    title: 'Your are acting as global admin',
    subTitle: 'Please input an organization id to continue',
    inputPlaceholder: 'Organization Id',
  },
  columnDisplayFilter: {
    title: 'Columns',
    done: 'Done',
    search: 'Search',
    restoreDefaults: 'Restore Defaults',
  },
  popupAlert: {
    retry: 'Retry',
  },
  popupDetectionMessage: {
    printReport:
      'You currently have a popup blocker enabled which is blocking your ability to print a report. Please adjust your browser settings to allow pop ups from Cassadol.',
    general:
      'You currently have a popup blocker enable which is blocking Cassadol from opening a new tab. Please adjust your browser settings to allow pop ups from Cassadol.',
  },
  jobStatusModal: {
    title: 'Process Status',
    error: (type = 'processing') => `There was an error ${type} the job`,
    defaultJobStatusNameKey: 'Processing Job. Please wait...',
    noTypeNameError: 'No type name found for job',
    estimatingTime: (minutes: number) =>
      `The estimated time to generate the selected statements is ${minutes} minutes.`,
    estimatingTime5Min: 'The estimated time to generate the selected statements will be less than 5 minutes.',
    startedTime: (date: string) => `Started at ${date}.`,
  },
  jobStatuses: {
    serviceBatch: 'Service Batch',
    statementRun: 'Statement Run',
    statementPrint: 'Statement Print',
    jobComplete: 'Job is complete',
    jobInvoiceCreate: 'Creating invoices',
    jobInvoiceCharge: 'Charging invoices',
    jobInvoiceFinalize: 'Finalizing invoices',
    jobPatientSync: 'Starting patients sync',
    jobPatientSyncInvoice: 'Syncing invoices',
    reminderBatch: 'Reminder Batch',
    reminderPrint: 'Reminder Print',
  },
  serviceOptionsModal: {
    title: (serviceName: string) => `Service Details - ${serviceName}`,
    fields: {
      quantity: 'Quantity',
    },
  },
  serviceErrorsModal: {
    title: 'Errors on save',
    message:
      'The following patients had an error when updating their records. Please visit the patient records and manually update the details.',
  },
  patientOptionsModal: {
    title: 'Add Patient Information',
    confirm: 'Save',
  },
  offline: {
    errorMessage:
      'Error occured that might affect your offline experience. Please try refreshing the browser. If the problem persists, please contact support.',
    offlineWithoutReplication:
      'It seems you have gone offline before syncing could be completed. To fully access offline features, please return online to complete sync.',
  },
  syncErrorPage: {
    title: 'Sync Error Log',
    columns: {
      type: 'Type',
      log: 'Log',
      actions: 'Actions',
      date: 'Date',
    },
    syncFailedLog: (entity: string) => `Sync failed for ${entity}`,
    modalTitle: 'Sync Error Details',
    viewData: 'View Data',
    fromDate: 'Display Errors Since',
  },
  systemStatusFilteredOption: [
    {
      id: '1',
      name: 'Outstanding',
      sort_order: 100,
    },
    {
      id: '2',
      name: 'Complete',
      sort_order: 90,
    },
    {
      id: '3',
      name: 'Cancelled',
      sort_order: 10,
    },
  ],
  reminderRuns: {
    title: 'Reminder Runs',
    labels: {
      afterDate: 'Reminders Due After',
      beforeDate: 'Reminders Due Before',
      sent: 'Reminders Sent',
    },
    statusSelect: {
      print: 'Print',
      printOrEmail: 'Print or Email',
      printAndEmail: 'Print and Email',
      Cancel: 'Cancel',
      Complete: 'Complete',
    },
    sentTypes: {
      print: 'Print',
      email: 'Email',
      cancel: 'Cancel',
      complete: 'Complete',
    },
    tabs: {
      reminders: 'Reminders',
      run: 'Run',
      tooltip: (runLabel: string, date: string) => `${runLabel} sent on ${date}`,
    },
    actions: {
      run: 'Run',
      configure: 'Configure',
      delete: 'Delete Run',
      cancel: 'Cancel',
      yes: 'Yes',
    },
    table: {
      row: {
        contact: 'Contact',
        patient: 'Patient',
        reminder: 'Reminder',
        dueDate: 'Due Date',
        note: 'Note',
        status: 'Status',
        preference: 'Preference',
      },
    },
    configurationModal: {
      title: 'Configure Reminder',
      fields: {
        emailSubject: 'Email Subject',
        reminderBody: 'Reminder Body',
      },
    },
    popconfirm: {
      run: {
        email: (startDate: string, endDate: string, contacts: number) =>
          `You are emailing reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}. Reminders will not be completed, are you sure you want to continue?`,
        print: (startDate: string, endDate: string, contacts: number) =>
          `You are printing reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}. Reminders will not be completed, are you sure you want to continue?`,
        printOrEmail: (startDate: string, endDate: string, contacts: number) =>
          `You are printing/emailing reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}. Reminders will not be completed, are you sure you want to continue?`,
        printAndEmail: (startDate: string, endDate: string, contacts: number) =>
          `You are printing and emailing reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}. Reminders will not be completed, are you sure you want to continue?`,
        warning: 'Please note you are sending reminders greater than 90 days in the future.',
        cancel: (startDate: string, endDate: string, contacts: number) =>
          `You are canceling reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}, are you sure you want to continue?`,
        complete: (startDate: string, endDate: string, contacts: number) =>
          `You are completing reminders for ${contacts} Contact(s) with a due date between ${startDate} - ${endDate}, are you sure you want to continue?`,
        cancelAdditional: 'Please note you have selected reminders in the future to cancel.',
        completeAdditional: 'Please note you have selected reminders in the future to complete.',
      },
      delete: {
        title1: 'Deleting a reminder run will not delete or alter processed reminders.',
        title2: 'Are you sure you want to delete this reminder run?',
      },
    },
  },
  errorMessages: {
    required: 'This field is required',
  },
  dashboard: {
    monthly: {
      title: 'Monthly Invoiced',
    },
    yearly: {
      title: 'Yearly Invoiced',
    },
    accountsReceivable: {
      title: 'Aged Accounts Receivable Summary',
    },
    payments: {
      title: 'Total Monthly Payments',
      ytd: 'Year to Date:',
    },
    contactsAndPatients: {
      title: 'New Contacts and Patients',
    },
    missingMetrics:
      'Failed to load dashboard data from the database, please contact support to ensure the data is valid',
    offline: 'The Dashboard is not available while disconnected from the network.',
    noData: (metric: string) => `No ${metric} data to display`,
    lastUpdated: 'Last Updated',
    refetch: 'Refetching dashboard data',
  },
  labRequestModal: {
    title: 'Lab Request',
    instructions:
      'Please review and submit the lab request below. Once submitted a requisition form will open in a new tab, please print the form and include with the lab samples to be submitted to the lab.',
    successSavingLabRequest: 'Lab requests updated',
    errorSavingLabRequest: 'Error saving lab requests',
    status: 'Status',
    requiresTaxonomyMap: 'Taxonomy mapping is required',
    cancelMessage: 'You have not completed the lab request yet, are you sure you wish to cancel?',
  },
  labResultModal: {
    title: 'Lab Results',
    noResults: 'Lab Results Unavailable',
    seeRequest: 'Would you like to see the request?',
  },
  taxonomyModal: {
    title: (labName: string) => `Taxonomy Mapping - ${labName}`,
    description:
      'In order to submit this lab request to the lab you will need to align patient data to the lab’s values. Once you have aligned the data between the two systems, you will not need to do this again for these values. You can visit the lab management page to bulk align all data ahead of time.',
    instruction: "Please align the following fields to the Lab's values",
    description3p:
      'To submit data to the third-party system, you will need to align patient data to the third-party values. Once you have aligned the data between the two systems, you will not need to do this again for these values.',
    instruction3p: 'Please align the following fields to the third-party values:',
    columns: {
      cassadol: {
        title: 'Cassadol Value',
        species: 'Species:',
        breed: 'Breed:',
        gender: 'Gender:',
      },
      thirdPartyValue: (name: string) => `${name} Value`,
    },
    mapping: 'Mapping',
    dropdownPlaceholder: (type: TaxonomyType) => `Please select ${taxonomyTypeNameTranslation(type)} mapping`,
  },
  currencySettingsPage: {
    title: 'Currency and Date Settings',
    headers: {
      currency: 'Currency Settings',
      date: 'Date Settings',
    },
    date: 'Date',
    note1:
      'Please note that currently only the currency Symbol and Prefix/Suffix settings are displayed within printed reports.',
    note2:
      'Please review and confirm your currency display settings. Once you have confirmed the settings are correct please toggle the confirm switch.',
    note3:
      ' These settings are for display purposes within Cassadol and reports only. Changing these settings will not alter currency exchange rates.',
    currency: 'Currency',
    currencySymbol: 'Currency symbol',
    currencySymbolIsPrefix: 'Currency symbol prefix/suffix',
    prefix: 'Prefix',
    suffix: 'Suffix',
    decimalDigits: 'Number of digits after decimal',
    decimalSymbol: 'Decimal symbol',
    groupingSymbol: 'Digit grouping symbol',
    negativeSymbol: 'Negative sign symbol',
    confirm: {
      title: 'Confirmed',
      areYouSureMessage: 'Confirming these settings will lock them. Are you sure they are correct?',
      lockedMessage:
        'These settings have been confirmed and locked. If you need to alter these settings please reach out to Cassadol Support.',
    },
    rememberDate: {
      title: 'Remember Date of Service',
      tooltip:
        'If this is selected, when adding services to invoice, the date of the last service added will be remembered for the next service added.',
    },
    preview: {
      title: 'Display Examples:',
      positiveDisplay: 'Positive Display: ',
      negativeDisplay: 'Negative Display: ',
      dateDisplay: 'Date Display: ',
    },
    decimalAndGroupingSymbolRule: 'Decimal symbol and grouping symbol cannot be equal',
  },
  labManagementPage: {
    vetConnectlabName: 'IDEXX Laboratories',
    entity: 'lab',
    screens: {
      labReports: {
        title: 'Lab Reports',
        searchPlaceholder: 'Search for Lab',
        radioTitle: 'Labs',
      },
      unassignedLabs: {
        title: 'Unassigned Labs',
        radioTitle: 'Unassigned Labs',
        bulkAlignLabel: 'Bulk Assign',
        alignButtonLabel: 'Assign',
        view: 'View Raw Data',
        columns: {
          result_date: 'Request Date',
          contact_name: 'Contact Name',
          patient_name: 'Patient Name',
          lab_device_name: 'Lab',
          lab_tests: 'Lab Test(s)',
          lab_created_accession_id: 'Accession No.',
          status_id: 'Status',
          status_name_key: 'Status Name',
          actions: 'Actions',
        },
      },
      taxonomyMapping: {
        title: 'Laboratory Taxonomy Mapping',
        radioTitle: 'Taxonomy',
        tabs: {
          species: {
            title: 'Species',
            labType: 'Lab Species',
            selectPlaceholder: 'Select Species',
          },
          breeds: {
            title: 'Breeds',
            labType: 'Lab Breed',
            selectPlaceholder: 'Select Breed',
          },
          gender: {
            title: 'Gender',
            labType: 'Lab Gender',
            selectPlaceholder: 'Select Gender',
          },
          service: {
            title: 'Service',
            labType: 'Lab Test',
            selectPlaceholder: 'Select Service',
          },
          action: 'Action',
        },
        instructions: `
          Laboratories require your patient demographic data to be aligned to their specific data. This screen will allow you to align that data ahead of time. <br />
          Otherwise, you will be prompted for the alignment at time of billing. <br />
          <br />
          <b><u>Instructions:</u></b>
          <br />
          <br />
          <ul class="mapping-list">
            <li>Select A Lab from the drop down menu</li>
            <li>Go to Species Tab - You want to align all species first.</li>
            <li>You will see all the species you have in Cassadol listed in the left column</li>
            <li>
              In the right column is the Lab Specific value, if blank you will need to align
              <ul class="mapping-list">
                <li>Click edit on the row you wish to assign and then click into the drop-down menu. This will display a list of the lab’s tests available to be assigned.</li>
                <li>Select the closest match for the row you are on.</li>
                <li>Repeat until all Species are aligned.</li>
              </ul>
            </li>
            <li>
              If available, go to the breed tab - Please note not all laboratories require breed alignment
              <ul class="mapping-list">
                <li>Align your list breeds to the lab’s list of breeds as done previously with species.</li>
              </ul>
            </li>
            <li>
              If available, go to the genders tab - Please note not all laboratories require gender alignment.
              <ul class="mapping-list">
                <li>Align your list of genders to the lab’s list of genders as done previously with species and breeds.</li>
              </ul>
            </li>
          </ul>
          <br />
        `,
        autoMap: 'Auto Map',
      },
      serviceMapping: {
        title: 'Laboratory Services Mapping',
        radioTitle: 'Service Map',
        instructions: `
          Laboratories require your billing codes to be aligned to their list of tests. This screen will allow you to align that data ahead of time to submit lab requests to the lab. <br />
          <br />
          <b><u>Instructions:</u></b><br /><br />
          <ul class="mapping-list">
            <li>Select a lab from the drop down menu</li>
            <li>You will see all the services you have in Cassadol listed in the left column.</li>
            <li>
              In the right column will display the aligned lab test, if blank and a lab you will want to align it.
              <ul class="mapping-list">
                <li>Click edit on the row you wish to assign and then click into the drop-down menu. This will display a list of the lab’s tests available to be assigned.</li>
                <li>Select the closest match for the row you are on.</li>
                <li>Repeat until all lab tests are aligned.</li>
              </ul>
            </li>
            <li>Click the Edit button to change an existing lab test alignment.</li>
          </ul>
          <br />
        `,
      },
    },
    labTabsHeading: {
      labSelectPlaceholder: 'Please Select a Lab',
      noLabsMessage: 'No Labs to Map',
    },
    columns: {
      request_date: 'Request Date',
      contact_name: 'Contact Name',
      patient_name: 'Patient Name',
      lab_device_name: 'Lab',
      service_list: 'Lab Test(s)',
      lab_created_accession_id: 'Lab Accession',
      pims_created_accession_id: 'Cassadol Accession',
      lab_created_order_number: 'Lab Order No.',
      pims_created_order_number: 'Cassadol Order No.',
      status_id: 'Status',
      status_name_key: 'Status Name',
      view: 'View',
    },
    immersionButton: 'Launch Antech Lab Management',
  },
  stripeLandingPage: {
    title: 'Business Infusions Payments',
    statusError: 'Sorry there an error loading account status',
    stripeStatusLoading: 'Checking payments configuration status…',
    statusTitle: 'Account Status',
    pleaseCompleteMessage: 'Please complete your account setup by clicking the button below. Thanks.',
    setupButtonText: 'Update Account',
    completeDue: 'Complete Items',
    marketingText: `<p>Provide online, PCI compliant credit card processing for your practice with Business Infusions Payments.</p>
    <p>Cassadol credit card processing, called Business Infusions Payments, is powered by Stripe, Inc. Stripe is a payment processing company that is expected to surpass $1 trillion dollars of payment processing volume in 2024.  Cassadol chose Stripe as our payment processor because of their security, processing volume and reputation.  More information about Stripe can be found by visiting this link <a href="https://stripe.com/en-ca/newsroom/information" target="_blank" rel="noopener noreferrer">https://stripe.com/en-ca/newsroom/information</a>.</p>
    <p>Using an integrated credit card processing service will allow you to easily have your payments flow into your accounts receivable reporting within Cassadol.</p>
    <ul style='margin-left: 2em;'>
      <li>Rates are an industry competitive 2.95% plus $0.30 per transaction, with no monthly statement fee.</li>
      <li>Payouts will occur weekly on Mondays. The first payout can take upwards of 7 business days to process and follow Stripe's timeline for processing and paying out amounts.</li>
      <li>You are responsible for all payments processed on your connect account, both valid and fraudulent.</li>
      <li>You are responsible for additional fees associated to the connect account as Stripe may charge us for additional activity such as dispute fees, additional payout fees, or any other fees beyond regular services.</li>
      <li>Business Infusions is authorized to debit the Stripe connected account or if insufficient funds are in the connected account to debit the associated bank account for any disputes, including associated fees as soon as a dispute is received.</li>
    </ul>
    `,
    supportTitle: 'Business Infusions Payments Support',
    eventuallyDue: 'Incomplete Items',
    supportDetailsText: `
      <p>If you have any questions or problems with setting up or using Business Infusions Payments with Cassadol please reach out to our support team who will be happy to assist you.</p>
      <p style='margin-left: 2em;'>e. <a href="mailto:cassadol@businessinfusions.com" target="_blank" rel="noopener noreferrer">cassadol@businessinfusions.com</a></p>
    `,
    configurationTitle: 'Account Configuration',
    fields: {
      statementDescriptor: 'Credit Card Statement Descriptor',
      statementDescriptorTooltip:
        'The statement descriptor will appear on your client’s bank or credit card statements.',
      statementDescriptorPatternError: 'Invalid character. The characters <, >, \\, \', ", * are not allowed.',
      statementDescriptorLetterError: 'Must contain at least one letter.',
    },
    statuses: {
      ready:
        'Congratulations your Business Infusions Payment account is active and ready to begin processing credit card payments.',
      notFound:
        'Your Business Infusions Payments account is not yet created. Please complete your account setup by clicking the button below. We will first confirm some information about your practice and then you will be redirectd to a site outside of Cassadol to completed your account setup through Stripe. Once you have finished you will be redirected back to Cassadol.',
      notSetup:
        'Your Business Infusions Payments account has not been finalized. Please complete your account setup by clicking the button below. You will be redirected to a site outside of Cassadol to finish gathering the required information within Stripe. Once you have finished you will be redirected back to Cassadol.',
    },
    eventuallDueDescription:
      'The following items remain incomplete and are required to finalize your Business Infusions Payments account. Please click the complete items button below to finish setting up your account.',
    incompleteItems: {
      mcc: 'Merchant Category Code',
      url: 'URL',
      productDescription: 'Product Description',
      supportPhone: 'Support Phone',
      statementDescriptors: 'Credit Card Statement Descriptor',
      tos: 'Terms of Service',
      externalAccount: 'External Account',
      companyName: 'Company Name',
      companyAddress: 'Company Address',
      companyTaxId: 'Company Tax ID',
      name: stripeRequirementsStringBuilder('Name'),
      dob: stripeRequirementsStringBuilder(`Date of Birth`),
      address: stripeRequirementsStringBuilder('Address'),
      email: stripeRequirementsStringBuilder('Email'),
      phone: stripeRequirementsStringBuilder('Phone'),
      taxInformation: stripeRequirementsStringBuilder('Tax Information'),
      provideOwners: 'List of Owners',
      id: stripeRequirementsStringBuilder('ID'),
      title: stripeRequirementsStringBuilder('Title'),
      document: stripeRequirementsStringBuilder('Document Verification'),
      provideDirectors: 'List of Directors',
      provideExecutives: 'List of Executives',
      provideRegistrationNumber: 'Registration Number',
      ownershipPercentage: stripeRequirementsStringBuilder('Ownership Percentage'),
      relationship: stripeRequirementsStringBuilder('Relationship'),
    },
    incompleteItemsDescription:
      'The following items remain incomplete and are required to finalize your Business Infusions Payments account. Please click the complete items button below to finish setting up your account.',
  },
  stripeRegistrationPage: {
    title: 'Enable Business Infusions Payments',
  },
  contactTypeToggle: {
    staff: 'Staff',
    doctor: 'Doctor',
    farmManager: 'Farm Manager',
    broodMareManager: 'Brood Mare Manager',
    yearlingManager: 'Yearling Manager',
    assistantTrainer: 'Assistant Trainer',
    leasee: 'Leasee',
    leasor: 'Leasor',
  },
  offlineDiagnostics: {
    title: 'Offline Diagnostics',
    table: {
      columns: {
        collection: 'Collection Name',
        initialLoaded: 'Initial Loaded',
        indexesBuilt: 'Indexes Built',
        error: 'Error',
        action: 'Action',
      },
      runCorrection: 'Run Correction',
    },
    resyncCompleted: 'The unsynced documents have been resent',
  },
  editFillInstructionsModal: {
    title: 'Fill Instructions',
  },
  financialPeriods: {
    title: 'Financial Periods',
    explainerVerbiageParagraphs: [
      'A financial period denotes a set time frame within which an organization monitors and documents its financial operations. At the conclusion of each period, financial information is consolidated into journal entries to facilitate reporting.',
      'Financial periods and journal entries are intricately linked in accounting:',
      'Recording Transactions: Journal entries are utilized to record financial transactions as they transpire throughout a financial period. Each entry includes details like date, affected accounts, and corresponding amounts.',
      'End-of-Period Adjustments: As a financial period concludes, additional journal entries may be necessary to account for accrued expenses, prepaid expenses, depreciation, or other adjustments essential for precise financial reporting.',
      "Summarizing for Reporting: Once all transactions and adjustments are recorded for the period, the journal entries are amalgamated into financial statements like the income statement, balance sheet, and cash flow statement. These statements furnish a comprehensive overview of the organization's financial performance during the period.",
      'Ultimately, journal entries function as the primary mechanism for documenting financial transactions within a specific financial period, serving as the foundation for accurate financial reporting and analysis.',
    ],
    startDateLabel: 'Start Date',
    endDateLabel: 'End Date',
    selectDate: 'Select date',
    createPeriod: 'Create Period',
    overlap: 'A Financial Period with the selected end date exists, please select a later date.',
    pageNotEnabled: 'Financial Periods page is not enabled.',
    journalEntries: {
      title: 'Journal Entries',
      columns: {
        endDate: 'End Date',
        number: 'Number',
        note: 'Note',
        status: 'Status',
        sentDate: 'Sent On',
        actions: 'Actions',
      },
    },
    createAndSend: 'Create & Send',
    createJournal: 'Create Journal',
    sendJournal: 'Send Journal',
    deleteJournal: 'Delete Journal',
    viewJournal: 'View',
    deletePeriodDisabled: 'You cannot delete a financial period that has journal entries associated to it.',
    deletePeriodConfirm: 'Are you sure you wish to delete this financial period?',
    deletePeriod: 'Delete Financial Period',
    createAndSendTooltip: 'Create & Send a journal entry to an external accounting system',
    noExternalAccountingTooltip:
      'In order to Create & Send journal entries you must have an external accounting system configured',
    deleteJournalPopConfirm: {
      pending: 'Are you sure you wish to delete this journal entry?',
      sent: 'Are you sure you wish to delete this journal entry? Doing so will also delete this journal entry from your external accounting system.',
    },
    deleteJournalDisabled: 'You cannot delete this journal because QuickBooks is not configured.',
    columns: {
      begin_date: 'Begin Date',
      end_date: 'End Date',
      status_name_key: 'Status',
      actions: 'Actions',
    },
    cantVoidPriorThanLastPeriodException: "Can't delete prior than last period.",
    DATA_MISSING_GL_ACCOUNTS: 'Services must be linked to general ledger accounts. Please review your services setup.',
    DATA_MISSING_TAX_GL_ACCOUNTS:
      'Taxes must be linked to general ledger accounts. Please review your tax types setup.',
    MISSING_GL_WITH_AR_FLAG:
      'A general ledger account flagged as accounts receivable is required. Please review your general ledger account setup.',
    FINANCIAL_PERIOD_JOURNAL_NO_ROWS: 'No journal entries are needed at this time.',
    viewJournalModal: {
      title: (number: string) => `Journal Details - ${number}`,
      columns: {
        generalLedger: 'General Ledger',
        externalAccount: 'External Account',
        description: 'Description',
        debit: 'Debit',
        credit: 'Credit',
        total: 'Total',
      },
      noDetails: 'Sorry no details are available for this journal entry',
    },
  },
  depositRuns: {
    title: 'Deposit Runs',
    pageNotEnabled: 'Deposit Runs page is not enabled.',
    createDeposit: 'Create Deposit',
    overlap: 'A Deposit Run with the selected end date exists, please select a later date.',
    instructions:
      'Please create an initial deposit run that encompasses all payments that have already been reconciled and reviewed. All subsequent deposit runs can then be reconciled and reviewed.',
    buttons: {
      view: 'View',
      close: 'Close',
      closeAndSend: 'Close & Send',
      send: 'Send',
      reopen: 'Reopen',
      delete: 'Delete',
      deleteDepositText: 'This will delete the deposit run. Are you sure you wish to delete the run?',
    },
    columns: {
      number: 'Number',
      endDate: 'Date',
      paymentTypes: 'Payment Types',
      status: 'Status',
      actions: 'Actions',
    },
    createModal: {
      title: 'Create Deposit',
      savingMessage: 'Saving Deposit...',
      labels: {
        endDate: 'Deposit Payments Prior To',
        paymentTypes: 'Select Payment Types To Deposit',
      },
      columns: {
        paymentTypes: 'Payment Types',
      },
    },
    detailsModal: {
      title: (depositNumber: string) => `Deposit Run Details - ${depositNumber}`,
      noLedgersText: 'There are no ledgers associated with this deposit run',
      loadLedgersError: (errorMessage: string) => `Error loading ledgers: ${errorMessage}`,
      total: 'Total',
      columns: {
        date: 'Date',
        number: 'Ledger Ref',
        contact: 'Contact',
        paymentType: 'Type',
        total: 'Amount',
        actions: 'Action',
      },
      buttons: {
        delete: 'Delete',
        deleteDetailText:
          'This action will remove the selected payment from this deposit run. The payment will appear on subsequent deposit runs until included or deleted from the contact’s ledger. Are you sure you wan to remove this payment?',
      },
    },
  },
};

export const settingTranslations = {
  categories: {
    LOCALE: 'Language/Display preferences',
  },
  names: {
    LOCALE_DATE: 'Date Format',
    LOCALE_DATE_TIME: 'Date/Time Format',
    LOCALE_CURRENCY: 'Currency Format',
    LOCALE_LANGUAGE: 'Language',
  },
  descriptions: {
    LOCALE_DESC_DATE: 'The format in which to display date only values',
    LOCALE_DESC_DATE_TIME: 'The format in which to display date and time values',
    LOCALE_DESC_CURRENCY: 'The format to display currency',
    LOCALE_DESC_LANGUAGE: 'The language to use',
  },
};

export enum SubscriptionErrorKey {
  NoDemographics = 'SUBERRORNODEMOGRAPHICS',
  System = 'SUBERRORSYSTEM',
  SubscriptionNotReady = 'SUBERRORSUBSCRIPTIONNOTREADY',
  ExcessUsersViolation = 'SUBERROREXCESSUSERSVIOLATION',
  CardFailed = 'SUBERRORCARDFAILED',
  CannotProcessWithProblems = 'SUBERRORCANNOTPROCESSWITHPROBLEMS',
  UserLevelViolations = 'SUBERRORUSERLEVELVIOLATIONS',
  InvalidCoupon = 'INVALID_DISCOUNT_CODE',
}

interface subscriptionErrorConfig {
  message: string;
}

export const getTranslatedSubscriptionErrorMessage = (errorNameKey: string | undefined | null): string | undefined => {
  if (!errorNameKey) {
    return undefined;
  }
  return (
    subscriptionErrorConfigs[errorNameKey as SubscriptionErrorKey]?.message || translations.shared.generalErrorMessage
  );
};

export const subscriptionErrorConfigs: { [nameKey in SubscriptionErrorKey]: subscriptionErrorConfig } = {
  [SubscriptionErrorKey.NoDemographics]: {
    message: 'Missing required information',
  },
  [SubscriptionErrorKey.System]: {
    message: 'Internal System Error',
  },
  [SubscriptionErrorKey.SubscriptionNotReady]: {
    message: 'Card validation failed',
  },
  [SubscriptionErrorKey.ExcessUsersViolation]: {
    message: 'Subscription user amount cannot be less than number of current users',
  },
  [SubscriptionErrorKey.CardFailed]: {
    message: 'Unable to process the card',
  },
  [SubscriptionErrorKey.CannotProcessWithProblems]: {
    message: 'Unable to process transaction due to subscription problems',
  },
  [SubscriptionErrorKey.UserLevelViolations]: {
    message: 'Users per level limit reached',
  },
  [SubscriptionErrorKey.InvalidCoupon]: {
    message: 'Invalid Coupon Code',
  },
};

export enum SubscriptionIntervalKey {
  Monthly,
}

export const subscriptionIntervalLabel: { [nameKey in SubscriptionIntervalKey]: string } = {
  [SubscriptionIntervalKey.Monthly]: 'Monthly',
};

export enum StatementRunActionStatus {
  Sent = 'SENT',
  Printed = 'PRINTED',
  GeneratePdf = 'GENERATE_PDF',
  Queued = 'QUEUED',
  Error = 'ERROR',
}

export const statementRunActionConfigs: { [nameKey in StatementRunActionStatus]: string } = {
  [StatementRunActionStatus.Sent]: 'Emailed',
  [StatementRunActionStatus.Printed]: 'Printed',
  [StatementRunActionStatus.GeneratePdf]: 'Generating PDF',
  [StatementRunActionStatus.Queued]: 'Queued',
  [StatementRunActionStatus.Error]: 'Error',
};

export enum StatementRunActionErrors {
  Error = 'ERROR',
  EmailError = "Unable to cast object of type 'System.DBNull' to type 'System.String'.",
}

export const statementRunActionErrorConfigs: { [nameKey in StatementRunActionErrors]: string } = {
  [StatementRunActionErrors.Error]: 'Error',
  [StatementRunActionErrors.EmailError]: 'Error sending email to given address.',
};

export enum StatementRunPreference {
  Print = '1',
  Email = '2',
}

export const statementRunPreferenceConfigs: { [nameKey in StatementRunPreference]: string } = {
  [StatementRunPreference.Print]: 'Print',
  [StatementRunPreference.Email]: 'Email',
};

export enum PracticeSettingCategorykey {
  Locale = 'LOCALE',
  CardConnectBI = 'CARD_CON_BI',
  Statement = 'STATEMENT',
  Subscriptions = 'SUBSCRIPTIONS',
  Invoice = 'INVOICE',
  CardConnect = 'CARD_CONNECT',
  SMTP = 'SMTP',
  Interest = 'INTEREST',
  Notification = 'NOTIFICATION',
  Currency = 'CURRENCY',
  StripeBI = 'STRIPE_BI',
  StripePractice = 'STRIPE_PRACTICE',
  Reminder = 'REMINDER',
  Prescription = 'PRESCRIPTION',
  Lab = 'LAB',
  PaymentPortal = 'PAYMENTPORTAL',
  GVL = 'GVL',
  Receipt = 'RECEIPT',
  EMR = 'EMR',
}

export enum PracticeSettingsNameKey {
  LocaleDate = 'LOCALE_DATE',
  LocaleRememberDate = 'LOCALE_REMEMBER_DATE',
  StatementSubject = 'STATEMENT_SUBJECT',
  StatementBody = 'STATEMENT_BODY',
  StatementFooter = 'STATEMENT_FOOTER',
  Interest = 'INTEREST_CONFIG',
  InvoiceFooter = 'INVOICE_FOOTER',
  InvoiceHideDiscount = 'INVOICE_HIDE_DISC',
  InvoiceSubject = 'INVOICE_SUBJECT',
  InvoiceBody = 'INVOICE_BODY',
  ReminderBody = 'REMINDER_BODY',
  ReminderSubject = 'REMINDER_SUBJECT',
  PaymentPortalUrl = 'PAYMENT_PORTAL_URL',
  CurrencyId = 'CURRENCY_ID',
  CurrencyDisplay = 'CURRENCY_DISPLAY',
  CurrencyIsPrefix = 'CURRENCY_ISPREFIX',
  CurrencySettings = 'CURRENCY_SETTINGS',
  QuickBooksRealmId = 'QB_REALMID',
  StripePracticeSet = 'STRIPE_PRACTICE_SET',
  PrescriptionLabel = 'LABEL_PRESC',
}

export enum PracticeSettingsId {
  LocaleDate = '1',
  LocaleRememberDate = '11',
  StatementSubject = '7',
  StatementBody = '8',
  StatementFooter = '9',
  Interest = '68',
  InvoiceFooter = '20',
  InvoiceHideDiscount = '21',
  InvoiceSubject = '22',
  InvoiceBody = '23',
  CurrencyDisplay = '102',
  CurrencyIsPrefix = '103',
  CurrencySettings = '104',
  StripePracticeSet = '154',
  ReminderSubject = '160',
  ReminderFooter = '161',
  ReminderBody = '162',
  QuickBooksRealmId = '215',
  PrescriptionLabel = '240',
  EMRHomeRegion = '220',
}

export enum ReportParametersLabelNameKeys {
  DateRange = 'DATE_RANGE',
  Date = 'DATE_SINGLE',
  SelectContact = 'CONTACT_SELECT',
  SelectPatient = 'PATIENT_SELECT',
  SelectService = 'SERVICE_SELECT',
  StartDate = 'DATE_START',
  EndDate = 'DATE_END',
}

export const reportParameterLabelNameKeyConfigs: { [nameKey in ReportParametersLabelNameKeys]: string } = {
  [ReportParametersLabelNameKeys.DateRange]: 'Date Range',
  [ReportParametersLabelNameKeys.Date]: 'Date',
  [ReportParametersLabelNameKeys.SelectContact]: 'Select Contact',
  [ReportParametersLabelNameKeys.SelectPatient]: 'Select Patient',
  [ReportParametersLabelNameKeys.SelectService]: 'Select Service',
  [ReportParametersLabelNameKeys.StartDate]: 'Start Date',
  [ReportParametersLabelNameKeys.EndDate]: 'End Date',
};

export enum InvoiceSyncActions {
  OwnerMissing = 'SYNC_OWNER_MISSING',
  OwnerPercent = 'SYNC_OWNER_PERCENT',
  OwnerExtra = 'SYNC_OWNER_EXTRA',
}

export const invoiceSyncActionsLabel: { [nameKey in InvoiceSyncActions]: string } = {
  [InvoiceSyncActions.OwnerMissing]: 'Add Contact',
  [InvoiceSyncActions.OwnerPercent]: 'Change Percentage',
  [InvoiceSyncActions.OwnerExtra]: 'Remove Contact',
};

export const invoiceSyncActionsColor: { [nameKey in InvoiceSyncActions]: string } = {
  [InvoiceSyncActions.OwnerMissing]: 'green',
  [InvoiceSyncActions.OwnerPercent]: 'goldenrod',
  [InvoiceSyncActions.OwnerExtra]: 'red',
};

export enum ContactDeleteConflict {
  Relationship = 'CONTACT_VOID_RELATION_CONFLICT',
  Ledger = 'CONTACT_VOID_LEDGER_CONFLICT',
}

export enum LabErrorNameKeys {
  LabAuthenticationError = 'LAB_AUTHENTICATION_ERROR',
}

export const LabErrorNameKeyTranslation = (errorNameKey?: LabErrorNameKeys | string | null) => {
  switch (errorNameKey) {
    case LabErrorNameKeys.LabAuthenticationError:
      return 'Authorization Failed.  Please verify your credentials.';
    default:
      return 'Error occured communicating with Lab.';
  }
};

export enum PatientInvoiceSyncErrorNameKeys {
  PatientOwnershipSyndicateNotFound = 'PATIENT_OWNERSHIP_SYNDICATE_NOT_FOUND',
  PatientOwnershipMultipleSyndicates = 'PATIENT_OWNERSHIP_MULTIPLE_SYNDICATES',
  PatientOwnershipSyndicateNoOwners = 'PATIENT_OWNERSHIP_SYNDICATE_NO_OWNERS',
  PatientOwnershipSyndicateNoEffective = 'PATIENT_OWNERSHIP_SYNDICATE_NO_EFFECTIVE',
  PatientOwnershipNoCurrent = 'PATIENT_OWNERSHIP_NO_CURRENT',
  PatientOwnershipMultipleCurrent = 'PATIENT_OWNERSHIP_MULTIPLE_CURRENT',
  PatientOwnershipDateRangeOverlap = 'PATIENT_OWNERSHIP_DATE_RANGE_OVERLAP',
  PatientOwnershipInvalidSequence = 'PATIENT_OWNERSHIP_INVALID_SEQUENCE',
  PatientOwnershipPercentageNot100 = 'PATIENT_OWNERSHIP_PERCENTAGE_NOT_100',
  PatientOwnershipPercentageLessThan0 = 'PATIENT_OWNERSHIP_PERCENTAGE_LESS_THAN_0',
  PatientOwnershipEndDateNotGreaterThanEffectiveDate = 'PATIENT_OWNERSHIP_END_DATE_NOT_GREATER_THAN_EFFECTIVE_DATE',
  PatientExistingSyndicateViolation = 'PATIENT_EXISTING_SYNDICATE_VIOLATION',
  PatientExistingSyndicateNotSpecified = 'PATIENT_EXISTING_SYNDICATE_NOT_SPECIFIED',
  PatientRelatedPreviousDateViolation = 'PATIENT_RELATED_PREVIOUS_DATE_VIOLATION',
}

export const patientInvoiceSyncErrorNameKeyTranslation = (
  errorNameKey?: PatientInvoiceSyncErrorNameKeys | string | null
) => {
  switch (errorNameKey) {
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipSyndicateNotFound:
      return 'The specified ownership syndicate could not be found';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipMultipleSyndicates:
      return 'You can only modify one patient ownership syndicate at a time';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipSyndicateNoOwners:
      return 'No owners were specified as part of the syndicate';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipSyndicateNoEffective:
      return 'No effective date was specified for the ownership syndicate';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipNoCurrent:
      return 'Patient ownership required to sync invoices';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipMultipleCurrent:
      return 'Multiple current ownership syndicates found for patient';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipDateRangeOverlap:
      return 'Ownership syndicates date ranges overlap';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipInvalidSequence:
      return 'Ownership syndicates have effective dates not in sequence';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipPercentageNot100:
      return 'Ownership total does not equal 100 percent';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipPercentageLessThan0:
      return 'Ownership cannot have a percentage less than zero';
    case PatientInvoiceSyncErrorNameKeys.PatientOwnershipEndDateNotGreaterThanEffectiveDate:
      return 'Ownership end date must be greater then the effective date';
    case PatientInvoiceSyncErrorNameKeys.PatientExistingSyndicateViolation:
      return 'You cannot update an existing syndicate';
    case PatientInvoiceSyncErrorNameKeys.PatientExistingSyndicateNotSpecified:
      return 'You must specify the syndicate_id to void';
    case PatientInvoiceSyncErrorNameKeys.PatientRelatedPreviousDateViolation:
      return 'You cannot edit an existing rows effective date to a previous value';
    default:
      return translations.shared.errorMessage.invoiceSync;
  }
};

export enum PatientDeleteErrorNameKeys {
  DeleteWithServiceRenderedError = 'PATIENT_DELETE_WITH_SERVICE_RENDERED_EXCEPTION',
  DeleteWithNoteError = 'PATIENT_DELETE_WITH_NOTE_EXCEPTION',
  DeleteWithFileError = 'PATIENT_DELETE_WITH_FILE_EXCEPTION',
}

export const PatientDeleteNameKeyTranslation = (errorNameKey?: PatientDeleteErrorNameKeys | string | null) => {
  switch (errorNameKey) {
    case PatientDeleteErrorNameKeys.DeleteWithServiceRenderedError:
      return 'The delete of this patient has been prevented due to existing relationships. Please inactivate the patient instead.';
    case PatientDeleteErrorNameKeys.DeleteWithNoteError:
      return 'The delete of this patient has been prevented due to existing notes. Please inactivate the patient instead.';
    case PatientDeleteErrorNameKeys.DeleteWithFileError:
      return 'The delete of this patient has been prevented due to existing files. Please inactivate the patient instead.';
    default:
      return 'An error occured while trying to delete the patient.';
  }
};

export enum GeneralNames {
  Patient = 'patient',
  Contact = 'contact',
}

export enum UpsertTypeNameKey {
  Contact = 'upsertContact',
  Patient = 'upsertPatient',
  Note = 'upsertNote',
}

export enum BundleChildPromptModeId {
  Forced = 1,
  Checked = 2,
  Unchecked = 3,
}

export const upsertTypeNameTranslation = (upsertName: UpsertTypeNameKey) => {
  switch (upsertName) {
    case UpsertTypeNameKey.Contact:
      return { type: 'Contact Sync', log: 'contact' };
    case UpsertTypeNameKey.Patient:
      return { type: 'Patient Sync', log: 'patient' };
    case UpsertTypeNameKey.Note:
      return { type: 'Note Sync', log: 'note' };
    default:
      return { type: '', log: '' };
  }
};

export enum ROATypeId {
  Intravenous = '1',
  Intramuscular = '2',
  Oral = '3',
  Topical = '4',
  Other = '5',
}

export const taxonomyTypeNameTranslation = (taxonomyType: TaxonomyType) => {
  switch (taxonomyType) {
    case TaxonomyType.Breed:
      return 'breed';
    case TaxonomyType.Gender:
      return 'gender';
    case TaxonomyType.Species:
      return 'species';
    case TaxonomyType.Service:
      return 'service';
    case TaxonomyType.Color:
      return 'color';
    default:
      return '';
  }
};

export enum FinancialPeriodStatusNameKeys {
  Pending = 'FPSTATS_PENDING',
  Closed = 'FPSTATS_CLOSED',
}

export const financialPeriodsStatusNameKeyTranslation = (key?: FinancialPeriodStatusNameKeys | string | null) => {
  switch (key) {
    case FinancialPeriodStatusNameKeys.Pending:
      return 'Pending';
    case FinancialPeriodStatusNameKeys.Closed:
      return 'Closed';
    default:
      return 'None';
  }
};

export enum DepositRunStatusId {
  Pending = '1',
  Closed = '2',
  Sent = '3',
}

export const depositRunStatusIdTranslation = (key?: DepositRunStatusId | string | null) => {
  switch (key) {
    case DepositRunStatusId.Pending:
      return 'Pending';
    case DepositRunStatusId.Closed:
      return 'Closed';
    case DepositRunStatusId.Sent:
      return 'Sent';
    default:
      return 'None';
  }
};

export interface MaintenanceMessageTextKeys {
  message: string;
  upcoming: string;
  messageCloud: string;
  upcomingCloud: string;
}
