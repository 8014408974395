import {
  DeleteOutlined,
  MailOutlined,
  MergeCellsOutlined,
  PrinterOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import React from 'react';
import { translations } from '../../constants/translations';
import { useGetSmtpSettings } from '../../hooks/ajax/email/emailHooks';
import { hasValidEmailSettings } from '../../util/email';
import { getIsStripeProcessor } from '../../util/stripeConnectUtils';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';

interface ActionButtonOptionsProps {
  emailStatementHandler?: () => void;
  printStatementHandler?: () => void;
  handleOpenDeleteConfirm?: () => void;
  showMergeContactPopup?: () => void;
  showStoredCardsPopup?: () => void;
  onMouseLeave?: () => void;
}

export const menuItemsStyle = {
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    height: '25px',
  },
};

export const ActionButtonOptionsValue: React.FC<React.PropsWithChildren<ActionButtonOptionsProps>> = ({
  emailStatementHandler,
  printStatementHandler,
  handleOpenDeleteConfirm,
  showMergeContactPopup,
  showStoredCardsPopup,
  children,
  onMouseLeave,
}) => {
  const { state } = useOrganizationContext();

  const organization = state.organization;
  const practice = defaultPractice(state);
  const smtpSettings = useGetSmtpSettings();

  const isStripeProcessor = getIsStripeProcessor(organization);
  const isCardConnectProcessor = practice?.card_connect?.merchantId && practice?.card_connect?.merchantId !== '';

  const noCardProcessorIsEnabled = !isStripeProcessor && !isCardConnectProcessor;

  const renderStoredCardsOption = () => {
    if (noCardProcessorIsEnabled) {
      return (
        <Tooltip title={translations.viewContactPage.noProcessorMessage} placement='right'>
          {translations.viewContactPage.storedCards}
        </Tooltip>
      );
    }
    return translations.viewContactPage.storedCards;
  };

  return (
    <Menu onMouseLeave={onMouseLeave}>
      <Menu.Item
        key='1'
        icon={<MailOutlined />}
        onClick={emailStatementHandler}
        disabled={!hasValidEmailSettings(smtpSettings)}
        style={menuItemsStyle.menuItem}
      >
        {translations.viewContactPage.emailStatement}
      </Menu.Item>
      <Menu.Item key='2' icon={<PrinterOutlined />} onClick={printStatementHandler} style={menuItemsStyle.menuItem}>
        {translations.viewContactPage.printStatement}
      </Menu.Item>
      <Menu.Item key='3' icon={<DeleteOutlined />} onClick={handleOpenDeleteConfirm} style={menuItemsStyle.menuItem}>
        {translations.viewContactPage.deleteButton}
      </Menu.Item>
      <Menu.Item key='4' icon={<MergeCellsOutlined />} onClick={showMergeContactPopup} style={menuItemsStyle.menuItem}>
        {translations.viewContactPage.mergeStatement}
      </Menu.Item>
      <Menu.Item
        key='5'
        icon={<CreditCardOutlined />}
        onClick={showStoredCardsPopup}
        style={menuItemsStyle.menuItem}
        disabled={noCardProcessorIsEnabled}
      >
        {renderStoredCardsOption()}
      </Menu.Item>
      {children}
    </Menu>
  );
};
