import { useGetOrganizationIdFromRoute } from './routeParameterHooks';
import {
  contactIdParameter,
  getRouteWithParameter,
  invoiceIdParameter,
  organizationIdParameter,
  patientIdParameter,
  serviceIdParameter,
  userIdParameter,
} from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useCallback } from 'react';

export const getRouteWithParameters = (route: string, parameters: Record<string, string>) => {
  let routeWithParameters = route;
  Object.entries(parameters).forEach(([parameter, value]) => {
    routeWithParameters = getRouteWithParameter(routeWithParameters, parameter, value);
  });
  return routeWithParameters;
};

export const useNavigationToRoute = () => {
  const history = useHistory();
  const organizationId = useGetOrganizationIdFromRoute();

  const navigateTo = useCallback(
    (route: string, parameters: Record<string, string> = {}, state?: History.LocationState, replaceCurrent = false) => {
      const routeWithParameters = getRouteWithParameters(route, {
        ...parameters,
        [organizationIdParameter]: organizationId,
      });
      if (replaceCurrent) {
        history.replace(routeWithParameters, state);
      } else {
        history.push(routeWithParameters, state);
      }
    },
    [history, organizationId]
  );

  const navigateBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return { navigateBack, navigateTo };
};

export const withContactIdParameter = (contactId: string) => ({ [contactIdParameter]: contactId });
export const withInvoiceIdParameter = (invoiceId: string) => ({ [invoiceIdParameter]: invoiceId });
export const withPatientIdParameter = (patientId: string) => ({ [patientIdParameter]: patientId });
export const withServiceIdParameter = (serviceId: string) => ({ [serviceIdParameter]: serviceId });
export const withUserIdParameter = (userId: string) => ({ [userIdParameter]: userId });
