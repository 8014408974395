import { gql } from '@apollo/client';

export const ServiceBundleFields = gql`
  fragment ServiceBundleFields on Service {
    bundle {
      id
      bundled_service_id
      quantity
      name
      price
      unit_name
      prompt_mode
      sort_order
      package
      print
    }
  }
`;
