import React, { ReactNode, useEffect, useState } from 'react';
import { Form } from 'antd';
import { PracticeSettingsNameKey, translations } from '../../constants/translations';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useSendEmail } from '../../hooks/ajax/email/emailHooks';
import { FileData, mapFileDataToCommunicationLogAttachmentUpsert } from '../../util/fileUpload';
import { FileUpload } from '../FileUpload/FileUpload';
import {
  bodyFieldName,
  recipientsFieldName,
  StandardEmailFormFields,
  subjectFieldName,
} from './StandardEmailFormFields';
import { showErrorMessage, showSuccessMessage } from '../Notification/notificationUtil';
import styled from 'styled-components';
import { Loading } from '../Loading/Loading';
import { Store } from 'antd/es/form/interface';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { FormInstance } from 'antd/lib/form';
import { ColProps } from 'antd/lib/col';
import { defaults } from 'lodash';
import { formatStringWithTemplatedFieldsForStatements } from '../../util/emailUtil';
import { getPracticeSetting } from '../../pages/Contacts/ViewContact/statementUtils';
import { useOrganizationContext } from '../../contexts/organization/state';
import { defaultPractice } from '../../contexts/organization/selectors';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const AttachmentItem = styled.div`
  margin: 5px 0;
`;

export interface Attachment {
  file_name: string;
  file_pointer: string;
}

interface EmailModalProps {
  layout?: { labelCol: ColProps; wrapperCol: ColProps };
  title?: string;
  visible: boolean;
  onClose: () => void;
  initialValues?: Store;
  attachments?: string[];
  loading?: boolean;
  extraFormFields?: ReactNode;
  onAttachmentsFetch?: (values: Store) => Promise<(Attachment | undefined)[]>;
  staticContactOptions?: { value: string; label: string }[];
  onChange?: (changedValue: Store, formInstance: FormInstance) => void;
  replaceTags?: (str: string) => string;
  dates?: { start: string; end: string };
  contactName?: string;
}

const attachmentsFieldName = 'attachment_list';

export const EmailModal: React.FC<EmailModalProps> = ({
  onClose,
  onAttachmentsFetch,
  dates,
  contactName,
  ...props
}) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const practiceId = useDefaultPracticeId();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [bodyText, setBodyText] = useState<string>(props.initialValues?.[bodyFieldName] ?? '');
  const [attachmentsList, setAttachmentsList] = useState<FileData[]>([]);
  const [sendEmail] = useSendEmail();
  const [form] = Form.useForm();
  const { state } = useOrganizationContext();
  const practice = defaultPractice(state);
  const dateFormatSetting = getPracticeSetting(PracticeSettingsNameKey.LocaleDate, practice);

  useEffect(() => {
    if (form) {
      const subject = form.getFieldValue(subjectFieldName);
      if (subject && dates?.start && dates?.end) {
        const subjectValue = formatStringWithTemplatedFieldsForStatements(
          subject,
          dates.start,
          dates.end,
          contactName,
          dateFormatSetting?.value
        );
        form.setFieldValue(subjectFieldName, subjectValue);
      }
      if (bodyText && dates?.start && dates?.end) {
        const bodyValue = formatStringWithTemplatedFieldsForStatements(
          bodyText,
          dates.start,
          dates.end,
          contactName,
          dateFormatSetting?.value
        );
        setBodyText(bodyValue || bodyText);
      }
    }
  }, [form, dates, bodyText, contactName, dateFormatSetting]);

  const handleValueChange = (changedValue: Store) => {
    setHasChanges(true);
    props.onChange?.(changedValue, form);
  };

  const onFinish = async (values: Store) => {
    setIsSaving(true);

    try {
      const attachmentListUpsert = onAttachmentsFetch
        ? await onAttachmentsFetch(values)
        : attachmentsList.map(mapFileDataToCommunicationLogAttachmentUpsert);

      const { data } = await sendEmail({
        variables: {
          organizationId,
          practiceId,
          email: {
            recipients: values[recipientsFieldName].join(';'),
            subject: props.replaceTags?.(values[subjectFieldName]) ?? values[subjectFieldName],
            body: props.replaceTags?.(bodyText) ?? bodyText,
            mailFormat: 'html',
            [attachmentsFieldName]: attachmentListUpsert,
          },
        },
      });

      if (data?.sendEmail?.success) {
        showSuccessMessage(translations.emailModal.successMessage);
        handleClose();
      } else {
        showErrorMessage(data?.sendEmail?.message || translations.emailSettingsPage.sendingErrorMessage);
        setIsSaving(false);
      }
    } catch (e) {
      showErrorMessage(e.message ?? translations.emailSettingsPage.sendingErrorMessage);
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    setHasChanges(false);
    form.resetFields();
    setBodyText('');
    setAttachmentsList([]);
    onClose();
  };

  const onAttachmentRemove = (filePointer: string) => {
    setAttachmentsList(attachmentsList.filter((attachment) => attachment.pointer !== filePointer));
  };

  const onAttachmentUpload = (fileData: FileData) => {
    setHasChanges(true);
    setAttachmentsList([...attachmentsList, fileData]);
  };

  return (
    <ModalWithCloseConfirm
      width={700}
      title={props.title ?? translations.emailModal.title}
      onCancel={handleClose}
      open={props.visible}
      isSaving={isSaving}
      changesMade={hasChanges}
      okText={translations.emailModal.sendButtonText}
      onOk={form.submit}
    >
      {props.loading ? (
        <Loading />
      ) : (
        <Form
          {...(props.layout ?? layout)}
          onFinish={onFinish}
          onValuesChange={handleValueChange}
          form={form}
          initialValues={defaults(props.initialValues, { [recipientsFieldName]: [] })}
          validateTrigger={'onBlur'}
        >
          {props.extraFormFields}

          <StandardEmailFormFields
            bodyText={bodyText}
            setBodyText={setBodyText}
            staticContactOptions={props.staticContactOptions}
          />

          {props.attachments ? (
            props.attachments.length > 0 && (
              <Form.Item label={translations.emailModal.fixedAttachmentLabel}>
                {props.attachments.map((attachment, key) => (
                  <AttachmentItem key={key}>{attachment}</AttachmentItem>
                ))}
              </Form.Item>
            )
          ) : (
            <Form.Item name={attachmentsFieldName} wrapperCol={{ offset: 4 }}>
              <FileUpload
                organizationId={organizationId}
                onRemove={onAttachmentRemove}
                onUpload={onAttachmentUpload}
                multiple
              />
            </Form.Item>
          )}
        </Form>
      )}
    </ModalWithCloseConfirm>
  );
};
