import { Store } from 'antd/lib/form/interface';
import {
  CardConnectTransactionRecordUpsert,
  Ledger,
  LedgerReversal,
  StripeTransactionRecordUpsert,
} from '../../graph/types';
import { TransactionType } from '../PaymentModal/PaymentModal/creditCardMappingUtil';
import { paymentTypeConfigs, PaymentTypeNameKey } from '../../constants/referenceData/paymentReferenceData';
import { StripeActionType } from '../../constants/referenceData/paymentProcessorTypeReferenceData';
import { UpsertCardConnectVariables, UpsertStripeTransactionVariables } from '../../graph/queries/paymentGateway';
// CHANGE
export const createLedgerUpsertForPaymentReversal = (
  values: Store,
  ledgerEntry: Ledger,
  dateFormat: string
): LedgerReversal => {
  const noteUpsert = values.note ? { value: values.note } : undefined;

  return {
    id: ledgerEntry.id,
    amount: values.amount?.toString(),
    financial_date: values.date.format(dateFormat),
    note: noteUpsert,
  };
};

export const getTransactionRecordUpsertForReversal = (
  values: Store,
  ledgerEntry: Ledger,
  dateFormat: string,
  currencyId?: string,
  isStripeProcessor = false
): CardConnectTransactionRecordUpsert | StripeTransactionRecordUpsert => {
  const stripeTransactionRecord = {
    amount: values.amount?.toString(),
    reversal_ledger_id: ledgerEntry.id,
    reversal_date: values.date.format(dateFormat),
    reversal_note: values.note,
    currency_id: currencyId,
  };
  const cardConnectTransactionRecord = {
    ...stripeTransactionRecord,
    is_device_request: false,
    save_card_record: false,
    type_id: TransactionType.Refund.toString(),
  };

  return isStripeProcessor ? stripeTransactionRecord : cardConnectTransactionRecord;
};

export const getTransactionVariablesForReversal = (
  isStripeProcessor: boolean,
  organizationId: string,
  practiceId: string,
  // clientRequestToken: string,
  transactionRecordUpsert: StripeTransactionRecordUpsert | CardConnectTransactionRecordUpsert
  // payment: LedgerUpsert,
  // contact: Contact
): UpsertStripeTransactionVariables | UpsertCardConnectVariables => {
  if (isStripeProcessor) {
    return {
      organizationId,
      practiceId,
      paymentRecord: {
        action: StripeActionType.REFUND,
        // clientToken: clientRequestToken,
        transaction: transactionRecordUpsert,
        // payment,
        // contact_id: contact.id,
      },
    };
  }
  return {
    organizationId,
    practiceId,
    transaction: transactionRecordUpsert as CardConnectTransactionRecordUpsert,
  };
};

export const getRefundToDisplay = (ledgerEntry: Ledger): string | undefined => {
  const paymentTypeDisplay = paymentTypeConfigs[ledgerEntry.payment_type_name_key as PaymentTypeNameKey]?.text;
  if (ledgerEntry.card_display_number) {
    return `${paymentTypeDisplay} ${ledgerEntry.card_display_number}`;
  } else if (ledgerEntry.card_expiry_month && ledgerEntry.card_expiry_year) {
    return `${paymentTypeDisplay} ${ledgerEntry.card_expiry_month}/${ledgerEntry.card_expiry_year}`;
  }
  return undefined;
};
