import { Form, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { searchableSelectParams } from '../../constants/searchableSelectParams';
import { translations } from '../../constants/translations';
import { FlexContainer, NoBottomMarginFormItem, SelectWithWidth, TitleDiv } from '../../globalStyles.style';
import { Invoice, LabDeviceTaxonomy, ThirdPartyTaxonomy } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { getRequiredRule } from '../../util/forms';
import { OptionsWithSpeciesNumber } from '../DependentDropdown/DependentDropdown';
import ModalWithCloseConfirm from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { fillLabOptionsMap, LabTaxonomyData, mapTaxonomyByDevice } from '../../util/labUtil';
import { TaxonomyType } from '../../util/taxonomyUtil';
import { mapTaxonomyByThirdParty, ThirdPartyTaxonomyData } from '../../util/thirdPartyUtil';
import { ThirdPartyTaxonomyMapRow } from './ThirdPartyTaxonomyMapRow';
import { useGetDeviceTaxonomyUpsert } from '../../hooks/useGetDeviceTaxonomyUpsert';

type TaxonomyMappingModalProps = {
  labTaxonomy: LabDeviceTaxonomy[];
  thirdPartyTaxonomy: ThirdPartyTaxonomy[];
  handleCloseModal: (invoice?: Invoice) => void;
  invoiceId?: string;
};

const getLabFieldName = (entry: LabTaxonomyData, type?: TaxonomyType) => {
  return `${entry.lab}_${type ?? entry.type}_${entry.cassadolValue}_lab_taxonomy`;
};

export const TaxonomyMappingModal: React.FC<TaxonomyMappingModalProps> = ({
  labTaxonomy,
  thirdPartyTaxonomy,
  handleCloseModal,
  invoiceId,
}) => {
  const [form] = useForm();
  const organizationId = useGetOrganizationIdFromRoute();
  const [labOptionsMap, setLabOptionsMap] = useState<Record<string, Record<string, OptionsWithSpeciesNumber[]>>>({});
  const { isSaving, updateTaxonomy } = useGetDeviceTaxonomyUpsert(organizationId, invoiceId);
  const taxonomyByDevice: Record<string, LabTaxonomyData[]> = {};
  const taxonomyByThirdParty: Record<string, ThirdPartyTaxonomyData[]> = {};
  const [isLoading, setIsLoading] = useState(false);

  //#region Lab related functions
  let labName = '';

  uniqBy(labTaxonomy, 'lab_device_id').forEach((tax) => {
    taxonomyByDevice[tax.lab_device_id] = [];
    const deviceName = tax.lab_device_name.split(' ')[0];
    labName = labName.length ? `${labName}, ${deviceName}` : deviceName;
  });

  const handleOnCancel = () => {
    handleCloseModal();
  };

  mapTaxonomyByDevice(labTaxonomy, taxonomyByDevice);

  const labTableData = Object.keys(taxonomyByDevice).reduce<LabTaxonomyData[]>((acc, key) => {
    return acc.concat(taxonomyByDevice[key]);
  }, []);

  //#endregion

  //#region Third Party related functions
  let thirdPartyName = '';

  uniqBy(thirdPartyTaxonomy, 'third_party_id').forEach((tax) => {
    taxonomyByThirdParty[tax.third_party_id] = [];
    thirdPartyName = thirdPartyName.length
      ? `${thirdPartyName}, ${tax.third_party_name_key}`
      : tax.third_party_name_key;
  });

  mapTaxonomyByThirdParty(thirdPartyTaxonomy, taxonomyByThirdParty);

  //#endregion

  const handleFinish = (values: Store) => {
    updateTaxonomy(values, taxonomyByDevice, taxonomyByThirdParty, handleCloseModal);
  };

  const filterBreedsBySpecies = (entryLab: string, selectedSpecies: string) => {
    setLabOptionsMap((prevMap) => {
      return {
        ...prevMap,
        [entryLab]: {
          ...prevMap[entryLab],
          [TaxonomyType.Breed]: prevMap[entryLab]?.allBreeds?.filter(
            (breed) => breed.speciesnumber === selectedSpecies
          ),
        },
      };
    });
  };

  useEffect(() => {
    if (Object.keys(labOptionsMap).length === 0) {
      fillLabOptionsMap(labTableData, organizationId, setLabOptionsMap, handleCloseModal, setIsLoading);
    }
  }, [labOptionsMap, labTableData, organizationId, handleCloseModal]);

  const onSelectChange = (value: any, option: any, fieldName: string, entry: LabTaxonomyData) => {
    form.setFields([
      {
        name: `${fieldName}_lab_name`,
        value: option.label,
      },
    ]);

    if (entry.type === TaxonomyType.Species) {
      const breedFieldName = Object.keys(form.getFieldsValue()).find((field) =>
        field.includes(`${entry.lab} ${TaxonomyType.Breed}`)
      );
      const genderFieldName = Object.keys(form.getFieldsValue()).find((field) =>
        field.includes(`${entry.lab} ${TaxonomyType.Gender}`)
      );
      form.setFields([
        ...(breedFieldName
          ? [
              {
                name: breedFieldName,
                value: undefined,
              },
              {
                name: `${breedFieldName}_lab_name`,
                value: undefined,
              },
            ]
          : []),
        ...(genderFieldName
          ? [
              {
                name: genderFieldName,
                value: undefined,
              },
              {
                name: `${genderFieldName}_lab_name`,
                value: undefined,
              },
            ]
          : []),
      ]);
      filterBreedsBySpecies(entry.lab, value);
    }
  };

  const labColumns = [
    {
      title: translations.taxonomyModal.columns.cassadol.title,
      dataIndex: 'cassadolValueName',
      render: (value: string) => <span key={value}>{value}</span>,
    },
    {
      title: translations.taxonomyModal.columns.thirdPartyValue(labName),
      dataIndex: 'requiresMap',
      render: (_: boolean, entry: LabTaxonomyData) => {
        const fieldName = getLabFieldName(entry);
        const options = labOptionsMap?.[entry.lab]?.[entry.type] ?? [];

        return (
          <>
            <NoBottomMarginFormItem
              key={fieldName}
              name={fieldName}
              rules={[getRequiredRule(translations.taxonomyModal.mapping)]}
            >
              <SelectWithWidth
                {...searchableSelectParams}
                placeholder={translations.taxonomyModal.dropdownPlaceholder(entry.type)}
                options={options}
                onChange={(value, option) => onSelectChange(value, option, fieldName, entry)}
                data-testid={fieldName}
              />
            </NoBottomMarginFormItem>
            {/* noStyle item was a workaround as it seems trying to set the value for a field that doesn't exist when the form is first created doesn't work */}
            <Form.Item noStyle name={`${fieldName}_lab_name`} />
          </>
        );
      },
    },
  ];

  return (
    <ModalWithCloseConfirm
      title={
        labTaxonomy.length > 0
          ? translations.taxonomyModal.title(labName)
          : translations.taxonomyModal.title(thirdPartyName)
      }
      open
      cancelButtonProps={{ hidden: true }}
      onOk={form.submit}
      isSaving={isSaving || isLoading}
      savingMessage={isLoading ? translations.shared.communicatingWithLabMessage : undefined}
      onCancel={handleOnCancel}
    >
      <Form onFinish={handleFinish} form={form}>
        {labTableData.length > 0 && (
          <>
            <FlexContainer direction='column'>
              <TitleDiv>{translations.taxonomyModal.description}</TitleDiv>
              <TitleDiv>{translations.taxonomyModal.instruction}</TitleDiv>
            </FlexContainer>
            <Table dataSource={labTableData} columns={labColumns} pagination={false} />
          </>
        )}
        <ThirdPartyTaxonomyMapRow
          showTaxonomyModalTitle={labTableData.length > 0}
          form={form}
          thirdPartyTaxonomy={thirdPartyTaxonomy}
        />
      </Form>
    </ModalWithCloseConfirm>
  );
};
