import {
  Caregiver,
  InvoiceContactUpsert,
  InvoiceContext,
  InvoiceUpsert,
  RelatedContactEntryCurrent,
  RelatedOwner,
} from '../../../graph/types';
import { SelectOptions } from '../../../components/DependentDropdown/DependentDropdown';
import dayjs from 'dayjs';

const MAX_BILL_PERCENTAGE = '100';

export function getDoctorOptions(invoiceContext: InvoiceContext | undefined): SelectOptions[] {
  if (!invoiceContext) {
    return [];
  }
  const doctors = invoiceContext.caregiver.filter((caregiver) => caregiver.doctor);
  return doctors.map((doctor) => ({
    value: doctor.id,
    label: doctor.name,
  }));
}

export function getDoctor(invoiceContext: InvoiceContext, doctorId: string): Caregiver | undefined {
  return invoiceContext.caregiver.find((caregiver) => caregiver.id === doctorId);
}
// CHANGE
export const buildInvoiceUpsert = (
  patientId: string,
  practiceId: string,
  stakeholders: RelatedOwner[],
  dateFormat: string,
  syndicateId?: string | null,
  offlinePatientId?: string | null
) => {
  const invoiceContactUpsert: InvoiceContactUpsert[] = stakeholders.map(
    (stakeholder): InvoiceContactUpsert => ({
      record: {
        contact_id: stakeholder.contact_id ?? '',
        offline_contact_id: stakeholder.offline_contact_id,
        percentage: stakeholder.percentage ?? '0',
        primary: stakeholder.primary ?? false,
      },
    })
  );

  const invoiceUpsert: InvoiceUpsert = {
    record: {
      practice_id: practiceId,
      patient_id: patientId,
      offline_patient_id: offlinePatientId,
      date: dayjs().format(dateFormat),
      patient_syndicate_id: syndicateId,
    },
    contact: invoiceContactUpsert,
  };

  return invoiceUpsert;
};

export const buildInvoiceUpsertForContact = (
  patientId: string,
  practiceId: string,
  relatedContactEntry: RelatedContactEntryCurrent,
  dateFormat: string,
  offlinePatientId?: string | null
) => {
  const invoiceContactUpsert: InvoiceContactUpsert[] = [
    {
      record: {
        contact_id: relatedContactEntry.contact_id ?? '',
        percentage: MAX_BILL_PERCENTAGE,
        primary: relatedContactEntry.primary ?? false,
      },
    },
  ];

  const invoiceUpsert: InvoiceUpsert = {
    record: {
      practice_id: practiceId,
      offline_patient_id: offlinePatientId,
      patient_id: patientId,
      date: dayjs().format(dateFormat),
    },
    contact: invoiceContactUpsert,
  };

  return invoiceUpsert;
};
