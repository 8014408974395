import { Action, addRelationshipType, closeRelationshipModalType, editRelationshipType } from './actions';
import { RelatedContactEntryCurrent } from '../../../../../../graph/types';

export const relationshipModalAddMode = 'add';
export const relationshipModalEditMode = 'edit';

export interface RelationshipState {
  showModal: boolean;
  mode?: typeof relationshipModalAddMode | typeof relationshipModalEditMode;
  contactTypeId?: string;
  previousRelationship?: RelatedContactEntryCurrent;
}

export const initialState: RelationshipState = {
  showModal: false,
};

export const relationshipReducer = (state: RelationshipState, action: Action): RelationshipState => {
  switch (action.type) {
    case addRelationshipType:
      return {
        ...state,
        showModal: true,
        contactTypeId: action.contactTypeId,
        mode: relationshipModalAddMode,
      };
    case editRelationshipType:
      return {
        ...state,
        showModal: true,
        contactTypeId: action.contactTypeId,
        previousRelationship: action.previousRelationship,
        mode: relationshipModalEditMode,
      };
    case closeRelationshipModalType:
      return {
        ...initialState,
      };
  }
  return state;
};
