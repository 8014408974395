import React, { useMemo, useState } from 'react';
import { useGetReports } from '../../hooks/ajax/report/reportHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { Loading } from '../../components/Loading/Loading';
import { translations } from '../../constants/translations';
import { StyledPageHeader } from '../../components/PageHeader/PageHeader.style';
import { Report } from '../../graph/types';
import { List } from 'antd';
import styled from 'styled-components';
import { RunReportWithParametersModal } from '../../components/RunReportWithParametersModal/RunReportWithParametersModal';
import { LinkButton } from '../../globalStyles.style';

const VerticalCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const PaddedListItem = styled(List.Item)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ReportsPage: React.FC = () => {
  const practiceId = useDefaultPracticeId();
  if (!practiceId) {
    return <Loading />;
  }
  return <ReportsPageContent practiceId={practiceId} />;
};

const ReportsPageContent: React.FC<{ practiceId: string }> = ({ practiceId }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const { reports, reportsLoading } = useGetReports(organizationId, practiceId);

  const [selectedReport, setSelectedReport] = useState<Report | undefined>();

  const renderedList = useMemo(() => {
    if (reportsLoading) {
      return <Loading />;
    }
    if (!reports) {
      return <p>{translations.reportsPage.loadingError}</p>;
    }
    return (
      <List
        dataSource={reports}
        renderItem={(report) => <ReportItem report={report!} setSelectedReport={setSelectedReport} />}
        pagination={{ defaultPageSize: 10 }}
        itemLayout={'vertical'}
        style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
      />
    );
  }, [reports, reportsLoading]);

  return (
    <>
      <StyledPageHeader title={translations.reportsPage.title} />
      {renderedList}
      {!!selectedReport && (
        <RunReportWithParametersModal
          report={selectedReport}
          visible={!!selectedReport}
          onClose={() => setSelectedReport(undefined)}
        />
      )}
    </>
  );
};

interface ReportItemProps {
  report: Report;
  setSelectedReport: (value: Report) => void;
}

const ReportItem: React.FC<ReportItemProps> = ({ report, setSelectedReport }) => {
  const handleReportRunClick = () => {
    setSelectedReport(report);
  };
  const renderedRunButton = (
    <VerticalCenterWrapper>
      <LinkButton type={'text'} onClick={handleReportRunClick}>
        {translations.reportsPage.run}
      </LinkButton>
    </VerticalCenterWrapper>
  );

  return (
    <PaddedListItem key={report.id} extra={renderedRunButton}>
      <List.Item.Meta title={report.name} description={report.description} />
    </PaddedListItem>
  );
};

export default ReportsPage;
