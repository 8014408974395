import styled from 'styled-components';

export const OverviewCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTags = styled.div`
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
`;
