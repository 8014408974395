import { StatementContactInterface } from '../../../graph/types';

export type StatementsAction = SetSelectedStatements | SetStatementsActionLoading | SetRefetchStatement;

export const setSelectedStatementsType = 'STATEMENTS__SET';
export const setActionLoadingType = 'STATEMENTS_ACTION_LOADING___SET';
export const setRefetchStatementType = 'STATEMENT_REFETCH__SET';

export interface SetSelectedStatements {
  type: typeof setSelectedStatementsType;
  statements: StatementContactInterface[];
}

export const setSelectedStatements = (statements: StatementContactInterface[]): SetSelectedStatements => ({
  type: setSelectedStatementsType,
  statements,
});

export interface SetStatementsActionLoading {
  type: typeof setActionLoadingType;
  loading: boolean;
}

export const setStatementActionLoading = (loading: boolean): SetStatementsActionLoading => ({
  type: setActionLoadingType,
  loading,
});

export interface SetRefetchStatement {
  type: typeof setRefetchStatementType;
  refetchStatement: () => void;
}

export const setRefetchStatement = (refetchStatement: () => void): SetRefetchStatement => ({
  type: setRefetchStatementType,
  refetchStatement,
});
