import { ColumnDisplayFilterState } from './reducer';

export type ColumnDisplayFilterAction = SetColumnDisplayFilterProps;

export enum ColumnDisplayFilterActionType {
  SetColumnDisplayFilterProps = 'SET_COLUMN_DISPLAY_FILTER_PROPS',
}

export interface SetColumnDisplayFilterProps {
  type: ColumnDisplayFilterActionType.SetColumnDisplayFilterProps;
  payload: ColumnDisplayFilterState['columnDisplayFilterProps'];
}

export const setColumnDisplayFilterProps = (
  value: ColumnDisplayFilterState['columnDisplayFilterProps']
): SetColumnDisplayFilterProps => ({
  type: ColumnDisplayFilterActionType.SetColumnDisplayFilterProps,
  payload: value,
});
