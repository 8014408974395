import React, { PropsWithChildren } from 'react';
import { SaveSpinner } from '../SaveSpinner/SaveSpinner';
import { NavigationWarning } from '../NavigationWarning/NavigationWarning';

interface SaveSpinnerAndNavigationWarningProps extends PropsWithChildren<unknown> {
  isSaving: boolean;
  showNavigationWarning: boolean;
  warningMessage?: string;
  savingMessage?: string;
  progress?: number;
}

export const SaveSpinnerAndNavigationWarning: React.FC<SaveSpinnerAndNavigationWarningProps> = (
  props: SaveSpinnerAndNavigationWarningProps
) => {
  return (
    <>
      <NavigationWarning showNavigationWarning={props.showNavigationWarning} warningMessage={props.warningMessage} />
      <SaveSpinner isSaving={props.isSaving} savingMessage={props.savingMessage} progress={props.progress}>
        {props.children}
      </SaveSpinner>
    </>
  );
};
