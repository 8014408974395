import React, { PropsWithChildren } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['clean'],
  ],
};

interface TextEditorProps extends PropsWithChildren<unknown> {
  editMode: boolean;
  textContent?: string;
  setTextContent: (textContent: string, plainText: string, source: 'api' | 'user' | 'silent') => void;
  placeholder?: string;
  readOnly?: boolean;
  testId?: string;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  editMode,
  textContent,
  setTextContent,
  placeholder,
  readOnly,
  testId,
}) => {
  return (
    <div
      style={{
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid={testId}
      className={`TextEditor ${editMode ? 'TextEditor_editMode' : 'TextEditor_viewMode'}`}
    >
      <ReactQuill
        style={{
          marginBottom: 10,
        }}
        preserveWhitespace
        theme='snow'
        value={textContent || ''}
        onChange={(content, _delta, source, editor) => setTextContent(content, editor.getText(), source)}
        modules={modules}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};
