import React from 'react';
import { translations } from '../../constants/translations';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ButtonWithPopconfirm } from '../../components/ButtonWithPopconfirm/ButtonWithPopconfirm';
import { DoctorOfficeSelect } from './DoctorOfficeSelect/DoctorOfficeSelect';
import { OfficeType } from './doctorOfficeUtil';

interface DoctorOfficeHeaderFieldsProps {
  handleChangeInPatientSelect: (patientId: string) => Promise<void>;
  closeAllTabs: () => Promise<void>;
  setSelectedPatientModalVisible: (visible: boolean) => void;
  officeType: OfficeType;
}

export const DoctorOfficeHeaderFields: React.FC<DoctorOfficeHeaderFieldsProps> = ({
  handleChangeInPatientSelect,
  closeAllTabs,
  setSelectedPatientModalVisible,
  officeType,
}) => {
  return (
    <>
      <DoctorOfficeSelect handleChangeInSelect={handleChangeInPatientSelect} officeType={officeType} />
      <Button
        style={{ marginLeft: 0, marginRight: 10 }}
        icon={<SearchOutlined />}
        type={'primary'}
        onClick={() => setSelectedPatientModalVisible(true)}
        data-testid={'select-patient-modal-button'}
      />
      <ButtonWithPopconfirm
        onClickCallback={closeAllTabs}
        buttonText={translations.doctorOffice.closeAllButtonText}
        popconfirmTitle={translations.doctorOffice.closeAllPopConfirmText}
      />
    </>
  );
};
