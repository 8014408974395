import { Button } from 'antd';
import { translations } from '../../../constants/translations';
import { Phone, User } from '../../../graph/types';
import React from 'react';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { StyledPageHeader } from '../../../components/PageHeader/PageHeader.style';
import { routes } from '../../../constants/routes';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { getPrimaryPhoneNumber } from '../../../util/phone';
import { useGetOrganizationIdFromRoute } from '../../../hooks/route/routeParameterHooks';
import { useNavigationToRoute, withUserIdParameter } from '../../../hooks/route/navigationHooks';
import { basicUserColumns } from './userColumns';
import { getUserTypeDtoTagsWithVoid, getUserTypesFilterOptionsDto } from '../userUtil';
import { TableKey } from '../../../hooks/tableHooks';
import { getStatus, getStatusTag } from '../../../util/statusUtils';
import { useGetUsers } from '../../../hooks/ajax/user/userHooks';
import { useOrganizationContext } from '../../../contexts/organization/state';
import { Loading } from '../../../components/Loading/Loading';

export const UsersOverview: React.FC = () => {
  const organizationId = useGetOrganizationIdFromRoute();
  const {
    state: { organization },
  } = useOrganizationContext();
  const { navigateTo } = useNavigationToRoute();
  const { users, usersLoading } = useGetUsers(organization?.id);

  const activeUserTypes = organization?.ref_user?.type ?? [];
  const userTypeFilters = getUserTypesFilterOptionsDto(activeUserTypes, users, organizationId);

  if (usersLoading) {
    return <Loading />;
  }

  const addUser = () => {
    navigateTo(routes.createUser);
  };

  const navigateToViewUser = (userId: string) => () => {
    navigateTo(routes.viewUser, withUserIdParameter(userId));
  };

  const linkToViewUser = (text: string, user: User) => {
    return <TableCellLink onClick={navigateToViewUser(user.id)}>{text}</TableCellLink>;
  };

  const columns: CustomColumnType<User>[] = [
    {
      ...basicUserColumns.name,
      render: (name: string, record: User) => linkToViewUser(name, record),
      width: 200,
    },
    {
      ...basicUserColumns.roles,
      filters: userTypeFilters,
      render: getUserTypeDtoTagsWithVoid(activeUserTypes, organizationId),
      width: 100,
    },
    {
      ...basicUserColumns.email,
      width: 100,
    },
    {
      ...basicUserColumns.phone,
      render: (phone: Phone[]) => {
        if (!phone) {
          return <span />;
        }

        return <span>{getPrimaryPhoneNumber(phone)}</span>;
      },
      width: 100,
    },
    {
      ...basicUserColumns.inactive,
      render: (record: User) => <>{getStatusTag(getStatus(record))}</>,
    },
    {
      title: translations.userTablePage.columns.actions,
      key: 'action',
      render: (user: User) => {
        return (
          <TableCellLink onClick={navigateToViewUser(user.id)}>{translations.shared.editButtonText}</TableCellLink>
        );
      },
      width: 100,
    },
  ];

  return (
    <>
      <StyledPageHeader
        title={translations.userTablePage.title}
        extra={
          <Button key='addContact' type='primary' onClick={addUser}>
            {translations.userTablePage.buttons.addUser}
          </Button>
        }
      />
      <TableWithCustomFiltering tableKey={TableKey.UsersOverview} columns={columns} dataSource={users} rowKey={'id'} />
    </>
  );
};
