import React, { useState } from 'react';
import { Popconfirm } from 'antd';
import {
  DropdownButtonWithMenu,
  MenuItemProps,
} from '../../../components/DropdownButtonWithMenu/DropdownButtonWithMenu';
import { translations } from '../../../constants/translations';

interface ServiceBundleActionCellProps {
  serviceId: string;
  setEditServiceId: (id: string) => void;
  removeServiceFromBundle: (id: string) => void;
}

export const ServiceBundleActionCell: React.FC<ServiceBundleActionCellProps> = ({
  serviceId,
  setEditServiceId,
  removeServiceFromBundle,
}) => {
  const editButtonMenu: MenuItemProps = {
    title: translations.shared.editButtonText,
    onClick: () => setEditServiceId(serviceId),
  };
  const deleteButtonMenu: MenuItemProps = {
    title: translations.shared.deleteButtonText,
    onClick: () => setShowPopconfirm(true),
  };

  const [showPopconfirm, setShowPopconfirm] = useState<boolean>(false);

  const actionProps: MenuItemProps[] = [editButtonMenu, deleteButtonMenu];

  return (
    <Popconfirm
      open={showPopconfirm}
      onOpenChange={(visible) => setShowPopconfirm(visible)}
      placement={'topLeft'}
      title={translations.renderedServicesTable.deleteConfirmationText}
      okText={translations.shared.popconfirm.ok}
      cancelText={translations.shared.popconfirm.cancel}
      onConfirm={() => removeServiceFromBundle(serviceId)}
    >
      <DropdownButtonWithMenu menuItemProps={actionProps} />
    </Popconfirm>
  );
};
