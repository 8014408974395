import React, { useMemo, useState, useEffect } from 'react';
import { LabRequestStatusId, LabClassId } from '../../constants/referenceData/labReferenceData';
import { translations } from '../../constants/translations';
import { FlexContainer } from '../../globalStyles.style';
import { LabResultRecord } from '../../graph/types';
import { useGetLabResultStatus } from '../../hooks/ajax/lab/labHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { LabModal } from '../LabModal/LabModal';
import { Loading } from '../Loading/Loading';
import ModalWithCloseConfirm, { ModalWithCloseConfirmProps } from '../ModalWithCloseConfirm/ModalWithCloseConfirm';
import { TableCellLink } from '../TableLink/TableCellLink';

interface LabResultModalProps extends ModalWithCloseConfirmProps {
  labResult: LabResultRecord;
  refetchResults?: () => void;
}

export const LabResultModal: React.FC<LabResultModalProps> = ({ labResult, refetchResults, ...modalProps }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const isVetConnectRequest = labResult.lab_class_id === LabClassId.VetConnect;
  const { labResultStatus, loading, refetch } = useGetLabResultStatus(
    organizationId,
    labResult.lab_device_id,
    ((isVetConnectRequest ? labResult.lab_created_order_number : labResult.pims_created_order_number) ?? '') ||
      undefined,
    // Use lab_created_accession_id to get the results
    labResult.lab_created_accession_id || undefined
  );
  const { windowWidth } = useWindowDimensions();
  const [showRequest, setShowRequest] = useState(false);

  const url = useMemo(() => {
    return labResultStatus?.url ?? labResult?.lab_request_url;
  }, [labResultStatus, labResult]);

  useEffect(() => {
    refetch?.();
  }, [refetch]);

  const getContent = () => {
    const modal = (
      <LabModal
        {...modalProps}
        title={showRequest ? translations.labRequestModal.title : translations.labResultModal.title}
        width={windowWidth >= 1200 ? 1200 : 1000}
        url={url ?? ''}
        isLoading={loading}
        onOk={(e) => {
          if (labResult.status_id !== LabRequestStatusId.Complete) {
            refetchResults?.();
          }
          modalProps.onOk?.(e);
        }}
        displayBlurb={false}
      />
    );
    if (labResultStatus?.has_lab_results) {
      return <>{modal}</>;
    }

    const renderSeeRequest = () =>
      (labResult?.lab_request_url || labResultStatus?.url) && (
        <TableCellLink onClick={() => setShowRequest(true)}>{translations.labResultModal.seeRequest}</TableCellLink>
      );

    return (
      <>
        {!showRequest ? (
          <ModalWithCloseConfirm {...modalProps} okText={translations.shared.closeButtonText}>
            {loading ? (
              <Loading labelText={translations.shared.communicatingWithLabMessage} />
            ) : (
              <FlexContainer direction='column' justifyContent='center' alignItems='center'>
                <p>{labResultStatus?.lab_result_message ?? translations.labResultModal.noResults}</p>
                {renderSeeRequest()}
              </FlexContainer>
            )}
          </ModalWithCloseConfirm>
        ) : (
          <>{modal}</>
        )}
      </>
    );
  };
  return <>{getContent()}</>;
};
