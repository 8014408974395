import gql from 'graphql-tag';
import { SettingDefinitionFields, SettingFields } from '../fragments';
import { FinancialGeneralLedgerFields, PaymentDeviceTypeFields } from '../fragments/organizationReferenceDataFragments';

export const getSettingDefinitions = gql`
  query getOrganizations($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      ref_system {
        setting {
          ...SettingDefinitionFields
        }
      }
    }
  }
  ${SettingDefinitionFields}
`;

export const getUserSettings = gql`
  query getUserSettings($organizationId: ID!, $practiceId: ID!) {
    getUserSettings(organizationId: $organizationId, practiceId: $practiceId) {
      ...SettingFields
    }
  }
  ${SettingFields}
`;

export const getOrganizationSettings = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      setting {
        ...SettingFields
      }
    }
  }
  ${SettingFields}
`;

export const getOrganizationSettingsAndGeneralLedgerRefData = gql`
  query getOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      setting {
        ...SettingFields
      }
      ref_financial {
        id
        ...FinancialGeneralLedgerFields
      }
    }
  }
  ${SettingFields}
  ${FinancialGeneralLedgerFields}
`;

export const getPracticeSettings = gql`
  query getPracticeSettings($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      practice {
        setting {
          ...SettingFields
        }
      }
    }
  }
  ${SettingFields}
`;

export const UpsertSetting = gql`
  mutation upsertSetting($organizationId: ID!, $setting: SettingUpsert!) {
    upsertSetting(organizationId: $organizationId, setting: $setting) {
      ...SettingFields
    }
  }
  ${SettingFields}
`;

export const UpsertSmtpEmail = gql`
  mutation UpsertSmtpEmail($organizationId: ID!, $practiceId: ID!, $email: SMTPMessage!) {
    sendEmail(email: $email, organizationId: $organizationId, practiceId: $practiceId) {
      id
      success
      message
    }
  }
`;

export const GetSmtpSettings = gql`
  query getSmtpSettings($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      smtp {
        accountName
        displayName
        fromAddress
        portNumber
        replyToAddress
        requiresSSL
        serverName
        useSES
      }
    }
  }
`;

export const UpsertSmtpSettings = gql`
  mutation UpsertSmtpSettings($organizationId: ID!, $smtp: SMTPSettingsUpsert!) {
    upsertOrganization(organization: { id: $organizationId, smtp: $smtp }, organizationId: $organizationId) {
      id
      smtp {
        accountName
        displayName
        fromAddress
        portNumber
        replyToAddress
        requiresSSL
        serverName
        useSES
      }
    }
  }
`;

export const UpsertCardConnectSettings = gql`
  mutation UpsertCardConnectSettings($practiceId: ID!, $card_connect: CardConnectSettingsUpsert!) {
    upsertPractice(practice: { id: $practiceId, card_connect: $card_connect }, organizationId: $organizationId) {
      id
      card_connect {
        merchantId
        userId
        password
      }
    }
  }
`;

export const UpsertCardConnectDevice = gql`
  mutation UpsertCardConnectDevice($organizationId: ID!, $devices: [RefPaymentDeviceTypeUpdate!]) {
    upsertOrganization(
      organization: { id: $organizationId, ref_payment_device_type: $devices }
      organizationId: $organizationId
    ) {
      id
      ref_financial {
        id
        ...PaymentDeviceTypeFields
      }
    }
  }
  ${PaymentDeviceTypeFields}
`;
