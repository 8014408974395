import { DatePicker, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo, useState } from 'react';
import {
  getReportForContactOptions,
  displayOneDayEarlierIfSet,
  getOwnershipStart,
  getOwnershipEnd,
  printPatientHistoryReport,
  buildPatientHistoryRptVariables,
} from './patientUtils';
import { useDefaultPracticeId } from '../../hooks/ajax/practice/practiceHooks';
import { Patient, RelatedOwner, RelatedOwnershipEntryAll } from '../../graph/types';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import ModalWithCloseConfirm from '../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import { translations } from '../../constants/translations';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { getRequiredRule } from '../../util/forms';
import { sortBy, uniqBy } from 'lodash';
import { useUserLocaleData } from '../../hooks/useUserLocale';
import { upsertDateFormat } from '../../constants/formats';
import { useLDFlag } from '../../hooks/useLDHooks';
import { LDFlagNames } from '../../constants/launchDarkly';
import { Reports } from '../../util/reportUtils';
import { useNavigateToReportViewer } from '../../hooks/ajax/report/reportHooks';

interface PrintPatientHistoryReportModalProps {
  patient: Patient;
  onClose: () => void;
}

const contactIdFieldName = 'contactId';
const startEndDatesFieldName = 'startEndDate';

interface PrintPatientHistoryInputFields {
  [contactIdFieldName]: string;
  [startEndDatesFieldName]: Dayjs[];
}

const span = 8;

export const PrintPatientHistoryReportModal: React.FC<PrintPatientHistoryReportModalProps> = ({ patient, onClose }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const practiceId = useDefaultPracticeId();
  const [form] = useForm();
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();

  const usesActiveReportForPatientHistory = useLDFlag(LDFlagNames.ARPatientHistoryReport);
  const navigateToReportViewer = useNavigateToReportViewer();

  const ownershipAll: RelatedOwnershipEntryAll[] = useMemo(
    () => (patient.ownership_all ? sortBy(patient.ownership_all, ['effective_date']) : []),
    [patient.ownership_all]
  );
  const owners: RelatedOwner[] = useMemo(() => {
    const tempOwners: RelatedOwner[] = [];
    ownershipAll.forEach((ownership) => {
      tempOwners.push(...ownership.owner);
    });
    return tempOwners;
  }, [ownershipAll]);

  const all = 'All';
  const reportForList = [{ value: all, label: all }].concat(getReportForContactOptions(owners));
  const reportForOptions = uniqBy([...reportForList], 'value');

  const handleFinish = async (values: PrintPatientHistoryInputFields) => {
    setIsReportLoading(true);

    const variables = buildPatientHistoryRptVariables({
      patient,
      startDate: values[startEndDatesFieldName][0]!.format(upsertDateFormat),
      endDate: values[startEndDatesFieldName][1]!.format(upsertDateFormat),
      practiceId,
      organizationId,
      usesActiveReports: usesActiveReportForPatientHistory,
      returnPreSignedUrl: true,
    });

    await printPatientHistoryReport(
      variables,
      usesActiveReportForPatientHistory,
      Reports.PatientHistory,
      navigateToReportViewer
    );
    setIsReportLoading(false);
    onClose();
  };

  const initialPrintPatientHistoryValues: PrintPatientHistoryInputFields = {
    [contactIdFieldName]: reportForOptions[0].value,
    [startEndDatesFieldName]: [dayjs(ownershipAll[0]?.effective_date), dayjs()],
  };

  const handleReportForOnChange = (value: string) => {
    if (value === all) {
      form.setFieldsValue({
        [startEndDatesFieldName]: [dayjs(ownershipAll[0]?.effective_date), dayjs()],
      });
      return;
    }
    const ownershipStart = getOwnershipStart(ownershipAll, value);

    const ownershipEnd = getOwnershipEnd(ownershipAll, value);

    const ownershipEndIndex = ownershipAll.findIndex(({ syndicate_id }) => syndicate_id === ownershipEnd?.syndicate_id);

    if (ownershipStart) {
      form.setFieldsValue({
        [startEndDatesFieldName]: [
          dayjs(ownershipStart.effective_date),
          displayOneDayEarlierIfSet(ownershipAll[ownershipEndIndex + 1]?.effective_date) ?? dayjs(),
        ],
      });
    }
  };

  return (
    <ModalWithCloseConfirm
      open
      onCancel={onClose}
      title={translations.printModalForPatientHistory.title}
      onOk={form.submit}
      okText={translations.printModalForPatientHistory.print}
      okButtonProps={{ disabled: isReportLoading }}
      cancelText={translations.shared.cancelButtonText}
    >
      <SaveSpinner isSaving={isReportLoading}>
        <Form initialValues={initialPrintPatientHistoryValues} onFinish={handleFinish} form={form} autoComplete='off'>
          <Form.Item
            name={contactIdFieldName}
            label={translations.emailModalForPatientHistory.fields.contactFieldLabel}
            labelCol={{ span }}
            rules={[getRequiredRule(translations.emailModalForPatientHistory.fields.contactFieldLabel)]}
          >
            <Select onChange={handleReportForOnChange} options={reportForOptions} />
          </Form.Item>
          <Form.Item
            label={translations.printModalForPatientHistory.fields.date}
            name={startEndDatesFieldName}
            labelCol={{ span }}
          >
            <DatePicker.RangePicker
              format={dateFormat}
              placeholder={[
                translations.printModalForPatientHistory.fields.startDate,
                translations.printModalForPatientHistory.fields.endDate,
              ]}
              disabledDate={(date) => date.isAfter(dayjs())}
            />
          </Form.Item>
        </Form>
      </SaveSpinner>
    </ModalWithCloseConfirm>
  );
};
