import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
} from 'antd';
import { translations } from '../../../constants/translations';
import { getRequiredRule } from '../../../util/forms';
import { Organization, Service, ServiceTaxTypeUpsert } from '../../../graph/types';
import './ServiceForm.css';
import { CheckboxOptionType } from 'antd/es/checkbox';
import { FormInstance } from 'antd/lib/form';
import { MAX_INPUT_AMOUNT, MIN_INPUT_AMOUNT } from '../../../constants/referenceData/ledgerReferenceData';
import { searchableSelectParams } from '../../../constants/searchableSelectParams';
import { ServiceGLAccountModal } from '../ServiceGLAccountModal/ServiceGLAccountModal';
import { MAX_SMALL_INTEGER } from '../../../constants/queryConstants';
import { getFormattedDecimalString } from '../../../util/displaying';
import {
  ClickableParagraph,
  CustomWidthFormItem,
  FlexContainer,
  CustomWidthMaxLengthFormItem,
  RightMarginPlusOutlined,
  CustomWidthFormItemWithMarginBottom,
  LessBottomMargin,
  CustomWidthContainerWithLeftMargin,
  DurationFormItem,
} from './ServiceForm.style';
import { CustomWidthInputNumber, NoBottomMarginFormItem } from '../../../globalStyles.style';
import { useLDFlag } from '../../../hooks/useLDHooks';
import { LDFlagNames } from '../../../constants/launchDarkly';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import {
  organizationSubscriptionLevelConfigs,
  OrganizationSubscriptionLevelNameKeys,
} from '../../../constants/referenceData/organizationSubscriptionReferenceData';
import { useGetOrganization } from '../../../hooks/ajax/organization/organizationHooks';
import { AdditionalOrganizationField } from '../../../graph/queries/organizations';
import { useUserLocaleData } from '../../../hooks/useUserLocale';
import { useGetHisaCategories } from '../../../hooks/ajax/service/serviceHooks';
import { showWarningMessage } from '../../../components/Notification/notificationUtil';

const addNewGLValue = 'add-new-gl';

export const LABEL_COL = { xs: 5, xl: 3 };
export const THIRD_LABEL_COL = { xs: 15, xl: 9 };
export const SWITCH_LABEL_COL = { xs: 18, xl: 10 };
const REMINDER_LABEL_COL = { xs: 18, xl: 20 };

const MetadataSpan = styled.span`
  size: 10px;
  color: #c0c0c0;
`;

const NoSpacedColumn = styled(Col)`
  padding-right: 0;
`;

export const SERVICE_REMINDER_SWITCH_TEST_ID = 'serviceReminderSwitchTestId';
export const SERVICE_VACCINE_DETAILS_EXPIRY_DATE_TEST_ID = 'expiryDateDatePicker';

export interface ServiceFormProps extends PropsWithChildren<unknown> {
  service?: Service;
  organization: Organization | undefined;
  form: FormInstance;
  setUnsavedData: (hasUnsavedData: boolean) => void;
  serviceInfoUpdateLine?: string;
}

export interface ServiceUpsertFormFields {
  name: string;
  unit_name?: string;
  inactive: boolean;
  type: ServiceType;
  price: string;
  quantity_default?: string;
  tax: ServiceTaxTypeUpsert[];
  generalLedgerId: string;
  microchip: boolean;
  quantity_prompt: boolean;
  duration?: number;
  roa_prompt: boolean;
  prescription_prompt: boolean;
  rabies_tag_prompt: boolean;
  reason_prompt?: boolean;
  enable_emr: boolean;
  withdrawal_prompt?: boolean;
  decease_prompt?: boolean;
  manufacturer?: string;
  lotNumber?: string;
  serialNumber?: string;
  expiryDate?: Date;
  hisa_category?: string;
  material_cost?: string;
  markup_percentage?: string;
}

export interface ServiceFormChangeFields {
  active: boolean;
  tax_ids: string[];
}

export enum ServiceType {
  controlled = 'controlled',
  vaccine = 'vaccine',
  standard = 'standard',
}

export const ServiceForm: React.FC<ServiceFormProps> = ({
  service,
  organization,
  form,
  setUnsavedData,
  serviceInfoUpdateLine,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [initialName, setInitialName] = useState<string>();
  const [reminderEnabled, setReminderEnabled] = useState<boolean>(!!service?.reminder?.[0]);
  const [isEmrEnabled, setIsEmrEnabled] = useState<boolean>(form.getFieldValue('enable_emr'));
  const [isUnitOfMeasureRequired, setIsUnitOfMeasureRequired] = useState<boolean>(form.getFieldValue('enable_emr'));
  const [isVaccineTypeSelected, setIsVaccineTypeSelected] = useState<boolean>(service?.vaccine ?? false);

  const { organization: orgAddOn } = useGetOrganization(organization?.id ?? '', AdditionalOrganizationField.AddOn);
  const {
    localeData: { dateFormat },
  } = useUserLocaleData();
  const hisaFlagEnabled = useLDFlag(LDFlagNames.Hisa);
  const emrFlagEnabled = useLDFlag(LDFlagNames.EMR);
  const showDefaultVaccineInfo = useLDFlag(LDFlagNames.DefaultVaccineInfo);
  const showMarkup = useLDFlag(LDFlagNames.Markup);
  const hisaAddon = useMemo(
    () =>
      organization?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.HisaLevel].level_id
      ),
    [organization]
  );

  const { hisaCategories } = useGetHisaCategories(organization?.id, hisaFlagEnabled && hisaAddon?.enabled);

  const categoryOptions = hisaCategories?.map((category) => ({
    value: category.ref_3p_ref_data_id,
    label: category.ref_value,
    key: category.ref_3p_ref_data_id,
  }));

  const emrAddon = useMemo(
    () =>
      orgAddOn?.subscription?.addon?.find(
        (item) =>
          item.level_id ===
          organizationSubscriptionLevelConfigs[OrganizationSubscriptionLevelNameKeys.EMRLevel].level_id
      ),
    [orgAddOn]
  );

  const emrEnabled = useMemo(() => emrAddon?.enabled && emrFlagEnabled, [emrAddon, emrFlagEnabled]);

  const taxTypeOptions: CheckboxOptionType[] = [];
  organization?.ref_financial?.tax_type.forEach((tax) => {
    const taxLabel = tax.name + ' - ' + tax.code + ' (' + getFormattedDecimalString(tax.percentage) + '%)';
    taxTypeOptions.push({
      value: tax.id,
      label: taxLabel,
    });
  });

  const glAccounts = organization?.ref_financial?.general_ledger ?? [];

  const isVoidGLAccount = service?.general_ledger_id && !glAccounts.find((gl) => gl.id === service.general_ledger_id);

  function decimalFieldChange(fieldName: string) {
    const value = form.getFieldValue(fieldName);
    form.setFields([{ name: fieldName, value: getFormattedDecimalString(value) }]);
  }

  const checkPriceChangeForMarkup = () => {
    const price = +form.getFieldValue('price');
    const markup = +form.getFieldValue('markup_percentage');
    const materialCost = +form.getFieldValue('material_cost');
    const priceDifference = +price !== (markup / 100) * materialCost + materialCost;
    if (markup && priceDifference && showMarkup) {
      showWarningMessage(translations.addServicePage.markupWarning);
      form.setFieldValue('markup_percentage', '');
    }
  };

  const handleMarkupCalculation = ({
    target: { value: markupPercentage },
  }: React.FocusEvent<HTMLInputElement, Element>) => {
    const materialCost = +form.getFieldValue('material_cost');
    const cleanMarkupPercentage = markupPercentage.replace(/,/g, '');
    const parsedMarkupPercentage = parseFloat(cleanMarkupPercentage);
    if (materialCost && parsedMarkupPercentage > 0) {
      const price = (parsedMarkupPercentage / 100) * materialCost + materialCost;
      form.setFields([{ name: 'price', value: getFormattedDecimalString(price) }]);
    }
  };

  const handleGLAccountSelect = (value: string) => {
    if (value === addNewGLValue) {
      setModalOpen(true);
      form.setFieldsValue({ generalLedgerId: '' });
    }
  };

  const handleGLAccountAdd = (generalLedgerId: string) => {
    setInitialName('');
    form.setFieldsValue({ generalLedgerId });
    setUnsavedData(true);
    setModalOpen(false);
  };

  const updateRabiesTag = (value: boolean) => {
    if (value) {
      form.setFieldsValue({ type: ServiceType.vaccine });
    }
  };

  const updateEmr = (value: boolean) => {
    if (value) {
      form.setFieldsValue({ reason_prompt: true, roa_prompt: true, withdrawal_prompt: true });
    }
    setIsEmrEnabled(value);
    setIsUnitOfMeasureRequired(value);
  };

  const getHisaCategory = (id: string) => hisaCategories?.find((category) => category.ref_3p_ref_data_id === id);

  const onHisaUpdate = (category: string) => {
    const hisaCategory = getHisaCategory(category);
    if (hisaCategory?.requires_roa) {
      form.setFieldsValue({ roa_prompt: true });
    }
    if (hisaCategory?.requires_reason) {
      form.setFieldsValue({ reason_prompt: true });
    }
  };

  const updateReminder = (value: boolean) => {
    form.setFieldsValue({ reminder: value });
    setReminderEnabled(value);
  };

  useEffect(() => {
    if (!isUnitOfMeasureRequired) {
      form.validateFields(['unit_name']);
    }
  }, [isUnitOfMeasureRequired, form]);

  const RenderLabel = (label: string) => (
    <div style={{ overflow: 'hidden', whiteSpace: 'initial' }} title={label}>
      {label}
    </div>
  );

  const handleRadioGroupechange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    if (ServiceType.vaccine === value) {
      setIsVaccineTypeSelected(true);
    } else {
      setIsVaccineTypeSelected(false);
    }
  };

  const isSwitchDisabled = (key: 'requires_roa' | 'requires_reason') => {
    const categoryId = form.getFieldValue('hisa_category');
    const category = getHisaCategory(categoryId);
    if (category?.[key]) {
      return true;
    }
    return false;
  };

  return (
    <>
      <CustomWidthMaxLengthFormItem
        name='name'
        width={100}
        labelCol={LABEL_COL}
        label={translations.addServicePage.fields.name}
        rules={[getRequiredRule(translations.addServicePage.fields.name)]}
        maxLength={100}
      >
        <Input />
      </CustomWidthMaxLengthFormItem>
      <FlexContainer width={33}>
        <Form.Item
          name='unit_name'
          labelCol={THIRD_LABEL_COL}
          label={translations.addServicePage.fields.unit}
          rules={[
            {
              required: isUnitOfMeasureRequired,
              message: translations.shared.getIsRequiredMessage(translations.addServicePage.fields.unit),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='quantity_default'
          labelCol={THIRD_LABEL_COL}
          label={translations.addServicePage.fields.quantity_default}
        >
          <Input
            type='number'
            min={MIN_INPUT_AMOUNT}
            max={MAX_INPUT_AMOUNT}
            step='any'
            width={100}
            onSubmit={() => decimalFieldChange('quantity_default')}
            onBlur={() => decimalFieldChange('quantity_default')}
          />
        </Form.Item>
        <Form.Item
          name='price'
          labelCol={THIRD_LABEL_COL}
          label={translations.addServicePage.fields.price}
          rules={[getRequiredRule(translations.addServicePage.fields.price)]}
        >
          <CustomWidthInputNumber
            min={MIN_INPUT_AMOUNT}
            max={MAX_INPUT_AMOUNT}
            width={100}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onSubmit={() => {
              decimalFieldChange('price');
              checkPriceChangeForMarkup();
            }}
            onBlur={() => {
              decimalFieldChange('price');
              checkPriceChangeForMarkup();
            }}
          />
        </Form.Item>
        {showMarkup && (
          <>
            <Form.Item name='material_cost' labelCol={THIRD_LABEL_COL} label={translations.addServicePage.fields.cost}>
              <CustomWidthInputNumber
                min={MIN_INPUT_AMOUNT}
                max={MAX_INPUT_AMOUNT}
                width={100}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onSubmit={() => decimalFieldChange('material_cost')}
                onBlur={() => decimalFieldChange('material_cost')}
              />
            </Form.Item>
            <Form.Item
              name='markup_percentage'
              labelCol={THIRD_LABEL_COL}
              label={translations.addServicePage.fields.markup}
            >
              <CustomWidthInputNumber
                min={MIN_INPUT_AMOUNT}
                max={MAX_INPUT_AMOUNT}
                width={100}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onSubmit={handleMarkupCalculation}
                onBlur={handleMarkupCalculation}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name='generalLedgerId'
          labelCol={THIRD_LABEL_COL}
          wrapperCol={{ xs: 9, xl: 18 }}
          label={translations.addServicePage.fields.glAccount}
        >
          <Select
            onSearch={(value) => setInitialName(value)}
            onSelect={handleGLAccountSelect}
            {...searchableSelectParams}
            filterOption={(input: string, option: any) =>
              option.value === addNewGLValue || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value={addNewGLValue}>
              <ClickableParagraph onClick={() => setModalOpen(true)}>
                <RightMarginPlusOutlined />
                {translations.addServicePage.addEntry}
              </ClickableParagraph>
            </Select.Option>
            {service && isVoidGLAccount && (
              <Select.Option value={service?.general_ledger_id || ''}>
                {translations.shared.getVoidRecord(
                  `${service?.general_ledger_name} - ${service?.general_ledger_number}`
                )}
              </Select.Option>
            )}
            {glAccounts.map((glAccount, key) => (
              <Select.Option key={key} value={glAccount.id}>
                {`${glAccount.gl_number} - ${glAccount.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {hisaFlagEnabled && (
          <Form.Item
            name={'hisa_category'}
            label={translations.hisaServicePage.fields.hisaCategory}
            labelCol={THIRD_LABEL_COL}
            tooltip={!hisaAddon?.enabled && { title: translations.hisaServicePage.hisaDisabledTooltip }}
          >
            <Select
              allowClear
              {...searchableSelectParams}
              options={categoryOptions}
              placeholder={translations.hisaServicePage.selectPlaceholder}
              data-testid={'TypeSelect'}
              onChange={onHisaUpdate}
              disabled={!hisaAddon?.enabled}
            />
          </Form.Item>
        )}
        <CustomWidthFormItemWithMarginBottom
          name='type'
          labelCol={THIRD_LABEL_COL}
          width={100}
          label={translations.addServicePage.fields.type}
        >
          <Radio.Group buttonStyle='solid' onChange={handleRadioGroupechange}>
            <Radio.Button value={ServiceType.standard}>{translations.addServicePage.fields.type_standard}</Radio.Button>
            <Radio.Button value={ServiceType.controlled}>
              {translations.addServicePage.fields.type_controlled}
            </Radio.Button>
            <Radio.Button value={ServiceType.vaccine}>{translations.addServicePage.fields.type_vaccine}</Radio.Button>
          </Radio.Group>
        </CustomWidthFormItemWithMarginBottom>

        {showDefaultVaccineInfo && isVaccineTypeSelected && (
          <>
            <LessBottomMargin wrapperCol={{ offset: 4 }}>
              <h3>
                <span>{translations.addServicePage.fields.vaccineDetails}</span>
              </h3>
            </LessBottomMargin>
            <Form.Item
              name='manufacturer'
              labelCol={THIRD_LABEL_COL}
              label={translations.addServicePage.fields.manufacturer}
            >
              <Input />
            </Form.Item>
            <Form.Item name='lotNumber' labelCol={THIRD_LABEL_COL} label={translations.addServicePage.fields.lotNumber}>
              <Input />
            </Form.Item>
            <Form.Item
              name='serialNumber'
              labelCol={THIRD_LABEL_COL}
              label={translations.addServicePage.fields.serialNumber}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='expiryDate'
              labelCol={THIRD_LABEL_COL}
              label={translations.addServicePage.fields.expiryDate}
            >
              <DatePicker data-testid={SERVICE_VACCINE_DETAILS_EXPIRY_DATE_TEST_ID} format={dateFormat} />
            </Form.Item>
          </>
        )}

        <LessBottomMargin wrapperCol={{ offset: 6 }}>
          <h4>
            <b>{translations.addServicePage.fields.options}</b>
          </h4>
        </LessBottomMargin>
        <LessBottomMargin
          labelCol={SWITCH_LABEL_COL}
          name='quantity_prompt'
          label={RenderLabel(translations.addServicePage.fields.quantity_prompt)}
          valuePropName='checked'
          className='service_option_form_item'
        >
          <Switch defaultChecked />
        </LessBottomMargin>

        <LessBottomMargin
          labelCol={SWITCH_LABEL_COL}
          name='microchip'
          label={RenderLabel(translations.addServicePage.fields.microchip)}
          valuePropName={'checked'}
          className='service_option_form_item'
        >
          <Switch />
        </LessBottomMargin>

        <Row wrap>
          <NoSpacedColumn style={{ paddingRight: 0 }} sm={24} xl={12}>
            <LessBottomMargin
              name='reminder'
              label={RenderLabel(translations.addServicePage.fields.reminder)}
              labelCol={REMINDER_LABEL_COL}
              valuePropName='checked'
              className='service_option_form_item'
            >
              <Switch onChange={updateReminder} data-testid={SERVICE_REMINDER_SWITCH_TEST_ID} />
            </LessBottomMargin>
          </NoSpacedColumn>
          <Col sm={24} xl={12}>
            {reminderEnabled && (
              <DurationFormItem
                name='duration'
                label={RenderLabel(translations.addServicePage.fields.duration)}
                className='service_reminder_form_item'
                labelCol={{ sm: 24, xl: 15 }}
              >
                <InputNumber type='number' min={1} max={MAX_SMALL_INTEGER} />
              </DurationFormItem>
            )}
          </Col>
        </Row>

        <LessBottomMargin
          name='prescription_prompt'
          label={RenderLabel(translations.addServicePage.fields.prescription_prompt)}
          labelCol={SWITCH_LABEL_COL}
          valuePropName={'checked'}
          className='service_option_form_item'
        >
          <Switch />
        </LessBottomMargin>

        <LessBottomMargin
          name='roa_prompt'
          labelCol={SWITCH_LABEL_COL}
          label={RenderLabel(translations.addServicePage.fields.roa)}
          valuePropName={'checked'}
          className='service_option_form_item'
        >
          <Switch disabled={isEmrEnabled || isSwitchDisabled('requires_roa')} />
        </LessBottomMargin>

        <LessBottomMargin
          name='rabies_tag_prompt'
          labelCol={SWITCH_LABEL_COL}
          label={RenderLabel(translations.addServicePage.fields.rabies_tag)}
          valuePropName={'checked'}
          className='service_option_form_item'
        >
          <Switch onChange={updateRabiesTag} />
        </LessBottomMargin>

        <LessBottomMargin
          name='decease_prompt'
          labelCol={SWITCH_LABEL_COL}
          label={RenderLabel(translations.addServicePage.fields.autoDecease)}
          valuePropName={'checked'}
          className='service_option_form_item'
        >
          <Switch />
        </LessBottomMargin>

        {emrEnabled && (
          <>
            <LessBottomMargin
              name='enable_emr'
              labelCol={SWITCH_LABEL_COL}
              label={RenderLabel(translations.addServicePage.fields.enable_emr)}
              valuePropName='checked'
              className='service_option_form_item'
            >
              <Switch onChange={updateEmr} />
            </LessBottomMargin>

            <LessBottomMargin
              name='reason_prompt'
              labelCol={SWITCH_LABEL_COL}
              label={RenderLabel(translations.addServicePage.fields.reason)}
              valuePropName={'checked'}
              className='service_option_form_item'
            >
              <Switch disabled={isEmrEnabled || isSwitchDisabled('requires_reason')} />
            </LessBottomMargin>
            <LessBottomMargin
              name='withdrawal_prompt'
              labelCol={SWITCH_LABEL_COL}
              label={RenderLabel(translations.addServicePage.fields.withdrawal)}
              valuePropName={'checked'}
              shouldUpdate
              className='service_option_form_item'
            >
              <Switch disabled={isEmrEnabled} />
            </LessBottomMargin>
          </>
        )}
      </FlexContainer>

      <CustomWidthContainerWithLeftMargin width={50}>
        <h4>{translations.addServicePage.fields.tax}:</h4>
        <CustomWidthFormItem width={100} className='service_checkboxs' name='tax_ids' labelCol={LABEL_COL}>
          <Checkbox.Group options={taxTypeOptions} />
        </CustomWidthFormItem>

        <h3>{translations.addServicePage.fields.note}</h3>
        <NoBottomMarginFormItem name='info'>
          <TextArea
            autoSize={{ minRows: 3 }}
            maxLength={2000}
            placeholder={translations.addServicePage.fields.notePlaceholder}
          />
        </NoBottomMarginFormItem>

        <MetadataSpan>
          {translations.viewContactPage.details.updated_by}
          {serviceInfoUpdateLine}
        </MetadataSpan>
      </CustomWidthContainerWithLeftMargin>

      {isModalOpen && (
        <ServiceGLAccountModal
          onCancel={() => {
            setInitialName('');
            setModalOpen(false);
          }}
          onSuccess={handleGLAccountAdd}
          initialName={initialName}
        />
      )}
    </>
  );
};
