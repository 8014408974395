import gql from 'graphql-tag';
import { JobFields } from '../fragments/jobFragments';

export const UpsertJob = gql`
  mutation upsertJob($organizationId: ID!, $job: JobUpsert) {
    upsertJob(organizationId: $organizationId, job: $job) {
      ...JobFields
    }
  }
  ${JobFields}
`;

export const GetJob = gql`
  query getJob($organizationId: ID!, $jobId: ID!) {
    getJob(organizationId: $organizationId, jobId: $jobId) {
      ...JobFields
    }
  }
  ${JobFields}
`;
