import React from 'react';
import { UserState, initialState, userReducer } from './reducer';
import { UserAction } from './action';
import { ContextProviderFC } from '../../util/ContextProvider';

export const UserContext = React.createContext<{
  state: UserState;
  dispatch: React.Dispatch<UserAction>;
}>({} as any);

interface UserContextProviderProps {
  state?: Partial<UserState>;
}

export const UserContextProvider: ContextProviderFC<UserContextProviderProps> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };

  const [state, dispatch] = React.useReducer(userReducer, fullInitialState);

  const value = {
    state,
    dispatch,
  };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const userContext = React.useContext(UserContext);

  if (!userContext?.state) {
    throw new Error('You need to use this hook inside a context provider');
  }

  return userContext;
};
