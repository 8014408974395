import React from 'react';
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { ChartContainer } from './Dashboard.style';
import { TitleContainer } from '../../globalStyles.style';
import { LayoutType } from 'recharts/types/util/types';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { translations } from '../../constants/translations';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';
import { getShortenedValue } from '../../hooks/currencyAndDateHooks';
import CurrencyFormatter from '../../components/CurrencyFormatter/CurrencyFormatter';

type MonthlyInvoicedChartProps = {
  title: string;
  data: Record<string, string | string[] | number | undefined>[];
  bar: JSX.Element;
  layout?: LayoutType;
  showTooltipAsText?: boolean;
  includeLegend?: boolean;
  legendPayload?: Payload[];
  customToolTip?: ContentType<ValueType, NameType>;
};

export const InvoicedChart: React.FC<MonthlyInvoicedChartProps> = ({
  title,
  data,
  bar,
  layout,
  showTooltipAsText,
  includeLegend = true,
  legendPayload,
  customToolTip,
}) => {
  return (
    <ChartContainer>
      <TitleContainer>{title}</TitleContainer>
      <ResponsiveContainer width='100%' height={400}>
        {!data.length ? (
          <p>{translations.dashboard.noData(title)}</p>
        ) : (
          <BarChart
            width={500}
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 20,
            }}
            layout={layout}
          >
            <CartesianGrid vertical={false} />
            <Tooltip
              formatter={(value: any) => (showTooltipAsText ? value : <CurrencyFormatter total={value} />)}
              content={customToolTip}
              filterNull
            />
            <XAxis
              type={layout ? 'number' : 'category'}
              dataKey={layout ? undefined : 'name'}
              tickFormatter={layout ? (value) => getShortenedValue(value) : undefined}
            />
            <YAxis
              tick={{ fontSize: 12 }}
              type={layout ? 'category' : 'number'}
              dataKey={layout ? 'name' : undefined}
              tickFormatter={layout ? undefined : (value) => getShortenedValue(value)}
            />
            {includeLegend && (
              <Legend verticalAlign='top' height={36} iconType='circle' align='left' payload={legendPayload} />
            )}
            {bar}
          </BarChart>
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};
