import { Form } from 'antd';
import styled from 'styled-components';

export const LabelsAboveItem = styled(Form.Item)`
  & > .ant-form-item-row {
    flex-direction: column;

    & > .ant-form-item-label {
      margin-bottom: -4px;
    }
  }
`;
