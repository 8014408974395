import React from 'react';
import { Button, Popconfirm, Space, Switch, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { translations } from '../../../../constants/translations';
import { useOffline } from '../../../../util/offline/offlineUtil';

export const deleteButtonTestId = 'deleteButton';

interface ViewInvoiceFooterProps {
  toggleInvoiceCompleted: (checked: boolean) => Promise<void>;
  isCompleted: boolean;
  handleDeleteInvoice: () => Promise<void>;
  deleteInvoiceDisabled: boolean;
  completeInvoiceDisabled: boolean;
}

export const ViewInvoiceFooter: React.FC<ViewInvoiceFooterProps> = ({
  handleDeleteInvoice,
  deleteInvoiceDisabled,
  toggleInvoiceCompleted,
  isCompleted,
  completeInvoiceDisabled,
}) => {
  const { isOnline } = useOffline();

  if (!isOnline) {
    return null;
  }

  const deleteButton = (
    <Button
      type={'primary'}
      icon={<DeleteOutlined />}
      data-testid={deleteButtonTestId}
      disabled={deleteInvoiceDisabled}
    >
      {translations.invoicePage.deleteInvoice}
    </Button>
  );

  return (
    <div id='ViewInvoiceFooter'>
      <Space direction='horizontal'>
        {deleteInvoiceDisabled ? (
          <Tooltip title={translations.invoicePage.deleteTooltip}>{deleteButton}</Tooltip>
        ) : (
          <Popconfirm
            title={translations.invoicePage.deleteInvoiceConfirm}
            onCancel={handleDeleteInvoice}
            cancelText={translations.shared.popconfirm.ok}
            okText={translations.shared.popconfirm.no}
          >
            {deleteButton}
          </Popconfirm>
        )}
        <Switch
          checkedChildren={translations.invoicePage.completed}
          unCheckedChildren={translations.invoicePage.complete}
          onClick={toggleInvoiceCompleted}
          checked={isCompleted}
          disabled={completeInvoiceDisabled}
          data-testid={'completed-toggle'}
        />
      </Space>
    </div>
  );
};
