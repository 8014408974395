import {
  CardConnectTransactionRecordUpsert,
  Contact,
  Ledger,
  LedgerPaymentTypeDto,
  LedgerUpsert,
  StripeTransactionRecordUpsert,
} from '../../../graph/types';
import {
  LedgerPaymentElectronicTypeValue,
  LedgerPaymentTypeValue,
} from '../../../constants/referenceData/ledgerReferenceData';
import { UpsertCardConnectVariables, UpsertStripeTransactionVariables } from '../../../graph/queries/paymentGateway';
import { StripeActionType } from '../../../constants/referenceData/paymentProcessorTypeReferenceData';
import { getSubscriptionContactRecordUpsert } from './creditCardMappingUtil';

export const findPaymentType = (availablePaymentMethods: LedgerPaymentTypeDto[], paymentTypeId: string) => {
  return availablePaymentMethods.find((ledgerPaymentType) => ledgerPaymentType.id === paymentTypeId);
};

export const getPaymentDisplayType = (paymentMethods: LedgerPaymentTypeDto[], ledger: Ledger) => {
  if (ledger.payment_type_id) {
    const paymentElectronicId = Number(ledger.payment_electronic_id);
    const isCredit = paymentElectronicId && paymentElectronicId >= LedgerPaymentElectronicTypeValue.Generic;
    return findPaymentType(paymentMethods, isCredit ? LedgerPaymentTypeValue.Credit : ledger.payment_type_id);
  }
  return undefined;
};

export const getTransactionVariables = (
  isStripeProcessor: boolean,
  organizationId: string,
  practiceId: string,
  clientRequestToken: string,
  transactionRecordUpsert: StripeTransactionRecordUpsert | CardConnectTransactionRecordUpsert,
  payment: LedgerUpsert,
  contact: Contact
): UpsertStripeTransactionVariables | UpsertCardConnectVariables => {
  if (isStripeProcessor) {
    return {
      organizationId,
      practiceId,
      paymentRecord: {
        action: StripeActionType.CHARGE,
        clientToken: clientRequestToken,
        transaction: transactionRecordUpsert,
        payment,
        contact_id: contact.id,
      },
    };
  }
  return {
    organizationId,
    practiceId,
    contact: getSubscriptionContactRecordUpsert(contact),
    clientToken: clientRequestToken,
    transaction: transactionRecordUpsert as CardConnectTransactionRecordUpsert,
    payment,
  };
};
