import React, { PropsWithChildren } from 'react';
import { translations } from '../../constants/translations';
import { AddressBlock } from '../AddressBlock/AddressBlock';
import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';

interface PhysicalAndMailingAddressProps extends PropsWithChildren<unknown> {
  indexOfPhysicalAddress: number;
  indexOfMailingAddress: number;
  hideMailingAddress?: boolean;
  setHideMailingAddress?: (value: boolean) => void;
  allFieldsOptional?: boolean;
  form: FormInstance;
}
export const checkBoxSameAddressesName = 'checkBoxSameAddresses';

export const PhysicalAndMailingAddress: React.FC<PhysicalAndMailingAddressProps> = ({
  indexOfPhysicalAddress,
  indexOfMailingAddress,
  hideMailingAddress,
  setHideMailingAddress,
  allFieldsOptional,
  form,
}: PhysicalAndMailingAddressProps) => {
  return (
    <>
      <RowStyled>
        <Col xs={10} offset={2}>
          <Row justify='space-between'>
            <Col>
              <h3>{translations.addressBlockComponent.title}</h3>
            </Col>
            <Col>
              <Form.Item name={checkBoxSameAddressesName} valuePropName='checked' labelCol={{ span: 22 }}>
                <Checkbox onChange={(e) => setHideMailingAddress?.(e.target.checked)}>
                  {translations.addressBlockComponent.checkBoxSameAddressesText}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {!hideMailingAddress && (
          <Col xs={10} offset={2}>
            <h3>{translations.addressBlockComponent.titleMailingAddress}</h3>
          </Col>
        )}
      </RowStyled>
      <Col xs={12}>
        <AddressBlock form={form} addressNumber={indexOfPhysicalAddress} allFieldsOptional={allFieldsOptional} />
      </Col>
      {!hideMailingAddress && (
        <Col xs={12}>
          <AddressBlock form={form} addressNumber={indexOfMailingAddress} allFieldsOptional={allFieldsOptional} />
        </Col>
      )}
    </>
  );
};

const RowStyled = styled(Row)`
  width: 100%;
`;
