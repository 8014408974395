import { GetServiceActivities, GetServicesForOffline, GetServicesRendered } from '../../../graph/queries/services';
import { ServiceActivity, ServiceRendered, Service } from '../../../graph/types';
import { elasticQueryBuilder, queryBuilder } from './queryUtils';

export const serviceRenderedQueryBuilder = (organizationId: string) =>
  queryBuilder<ServiceRendered>(organizationId, GetServicesRendered);

export const serviceActivityQueryBuilder = (organizationId: string) =>
  queryBuilder<ServiceActivity>(organizationId, GetServiceActivities);

export const serviceQueryBuilder = (organizationId: string) =>
  elasticQueryBuilder<Service>(organizationId, GetServicesForOffline);
