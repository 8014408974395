import React, { useEffect, useState } from 'react';
import { translations } from '../../constants/translations';
import dayjs from 'dayjs';
import { Loading } from '../../components/Loading/Loading';
import { useGetStripePaymentIntents } from '../../hooks/stripeHooks';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { TableKey, useTableResetFilterAndSort } from '../../hooks/tableHooks';
import { PriceValue } from '../../components/PriceValue/PriceValue';
import { getTag } from '../../util/tags';
import {
  StripeStatusNameKey,
  stripePaymentIntentStatusConfig,
} from '../../constants/referenceData/stripeReferenceData';
import { Organization, StripePaymentIntent } from '../../graph/types';
import { TableCellLink } from '../../components/TableLink/TableCellLink';
import { StripePaymentDetailsModal } from './StripePaymentDetailsModal';
import {
  generalFilteringAndSortingSettings,
  getDateCompareFunctionFor,
  getNumberCompareFunctionFor,
  getOnFilterFunctionFor,
  getStatusFilters,
  getStringCompareFunctionFor,
} from '../../util/filterAndSorting';
import { stripePaymentIntentPropertyNames } from '../../constants/propertyNames';

interface ConnectPaymentsProps {
  organization?: Organization;
}

export const ConnectPayments: React.FC<ConnectPaymentsProps> = ({ organization }) => {
  const { paymentIntents, intentsLoading, refetchPaymentIntents } = useGetStripePaymentIntents(
    organization?.id ?? '',
    organization?.default_practice_id ?? undefined
  );
  const [selectedPayment, setSelectedPayment] = useState<StripePaymentIntent>();

  const { filteredValue, sortOrderMap, tableChangeHandler } = useTableResetFilterAndSort();

  useEffect(() => {
    refetchPaymentIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (intentsLoading) {
    return <Loading />;
  }

  const openIntentModal = (intent: StripePaymentIntent) => {
    setSelectedPayment(intent);
  };

  const getCustomerName = (nameStr: string) => nameStr.split(':')[1];

  const columns: CustomColumnType<StripePaymentIntent>[] = [
    {
      title: translations.reportPaymentsPage.tabs.all.columns.date,
      key: stripePaymentIntentPropertyNames.created,
      dataIndex: stripePaymentIntentPropertyNames.created,
      width: 200,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap[stripePaymentIntentPropertyNames.created],
      filteredValue: filteredValue.created ?? null,
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.reportPaymentsPage.tabs.all.columns.date
      ),
      onFilter: getOnFilterFunctionFor(stripePaymentIntentPropertyNames.created),
      sorter: getDateCompareFunctionFor(stripePaymentIntentPropertyNames.created),
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: translations.reportPaymentsPage.tabs.all.columns.from,
      key: stripePaymentIntentPropertyNames.customer_name,
      dataIndex: stripePaymentIntentPropertyNames.customer_name,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap[stripePaymentIntentPropertyNames.customer_name],
      filteredValue: filteredValue[stripePaymentIntentPropertyNames.customer_name],
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.reportPaymentsPage.tabs.all.columns.from
      ),
      onFilter: getOnFilterFunctionFor(stripePaymentIntentPropertyNames.customer_name),
      sorter: getStringCompareFunctionFor(stripePaymentIntentPropertyNames.customer_name),
      render: getCustomerName,
      width: 500,
    },
    {
      title: translations.reportPaymentsPage.tabs.all.columns.amount,
      key: stripePaymentIntentPropertyNames.amount,
      dataIndex: stripePaymentIntentPropertyNames.amount,
      width: 200,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap.amount,
      filteredValue: filteredValue.amount ?? null,
      filterInputPlaceholder: translations.shared.getFilterInputPlaceholder(
        translations.reportPaymentsPage.tabs.all.columns.amount
      ),
      isForNumber: true,
      onFilter: getOnFilterFunctionFor(stripePaymentIntentPropertyNames.amount, true),
      sorter: getNumberCompareFunctionFor(stripePaymentIntentPropertyNames.amount),
      render: (amount: string) => <PriceValue value={Number(amount)} />,
    },
    {
      title: translations.reportPaymentsPage.tabs.all.columns.status,
      key: stripePaymentIntentPropertyNames.status,
      dataIndex: stripePaymentIntentPropertyNames.status,
      width: 150,
      ...generalFilteringAndSortingSettings,
      sortOrder: sortOrderMap.status,
      filteredValue: filteredValue.status ?? null,
      filters: getStatusFilters(stripePaymentIntentStatusConfig),
      onFilter: (value, record) => record.status === value,
      sorter: (a: StripePaymentIntent, b: StripePaymentIntent) =>
        (a.status_text ?? '').localeCompare(b.status_text ?? ''),
      render: (status: string) => {
        const statusConfig = stripePaymentIntentStatusConfig[status as StripeStatusNameKey];
        return getTag(statusConfig.color, statusConfig.text);
      },
    },
    {
      title: translations.reportPaymentsPage.tabs.all.columns.openDetails,
      key: 'actions',
      render: (record: StripePaymentIntent) =>
        record.status !== StripeStatusNameKey.Incomplete && (
          <TableCellLink onClick={() => openIntentModal(record)}>
            {translations.reportPaymentsPage.tabs.all.actions.viewDetails}
          </TableCellLink>
        ),
    },
  ];

  return (
    <>
      <TableWithCustomFiltering<StripePaymentIntent>
        tableKey={TableKey.StripePayments}
        dataSource={paymentIntents || []}
        columns={columns}
        loading={intentsLoading}
        rowKey={'id'}
        onChange={tableChangeHandler}
      />
      {selectedPayment?.id && organization?.default_practice_id && (
        <StripePaymentDetailsModal
          title={
            <>
              <PriceValue value={Number(selectedPayment.amount ?? 0)} />
              {` ${selectedPayment.currency} - ${getCustomerName(selectedPayment.customer_name)}`}
            </>
          }
          organizationId={organization.id}
          practiceId={organization?.default_practice_id}
          intentId={selectedPayment.id}
          onClose={() => setSelectedPayment(undefined)}
        />
      )}
    </>
  );
};
