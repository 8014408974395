import React, { useState } from 'react';
import { Col, Divider, Form, Input, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Store } from 'antd/lib/form/interface';
import { translations } from '../../constants/translations';
import { flexWrapContainer } from '../../globalStyles.style';
import './PracticeSettings.css';
import { buildFullPracticeUpsertFromPracticeDto, getPracticeFormInfoValuesFromPracticeInfoDto } from './practiceUtils';
import { useUpsertPractice } from '../../hooks/ajax/practice/practiceHooks';
import { isMatch } from '../../util/objectComparisons';
import { PracticeUpsertGenerator } from '../../classes/upsertGenerators/PracticeUpsertGenerator';
import { ComponentWithPracticeDtoProps } from '../../components/WithPractice/WithPractice';
import { showErrorMessage, showSuccessMessage } from '../../components/Notification/notificationUtil';
import {
  checkBoxSameAddressesName,
  PhysicalAndMailingAddress,
} from '../../components/PhysicalAndMailingAddress/PhysicalAndMailingAddress';
import {
  getIndicesOfAddressTypes,
  hasInitialMailingAddress,
} from '../../components/PhysicalAndMailingAddress/physicalAndMailingAddressUtil';
import { SaveAndResetButton } from '../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinnerAndNavigationWarning } from '../../components/SaveSpinnerAndNavigationWarning/SaveSpinnerAndNavigationWarning';
import { PracticeLogo } from './PracticeLogo/PracticeLogo';
import { WidthRestrictor } from '../../components/Restrictors/WidthRestrictor';
import { getPracticeUpsertInfos } from '../../util/practiceUpsertUtils';
import { PracticeInfoType } from '../../constants/referenceData/practiceReferenceData';
import { MaxLengthFormItem } from '../../components/MaxLengthFormItem/MaxLengthFormItem';
import { PhoneTableContextProvider } from '../../components/PhoneTable/phoneTableState';
import { ViewSubscriptionContextProvider } from '../../components/ViewSubscription/store/state';
import PracticeSettingsPhoneTable from './PracticeSettingsPhoneTable';
import styled from 'styled-components';
import { getValidEmailRule, getValidWebsiteRule } from '../../util/forms';
import { useOrganizationContext } from '../../contexts/organization/state';
import { Practice, PracticeDto } from '../../graph/types';
import { setOrganization } from '../../contexts/organization/action';

const PracticeSettingsContent: React.FC<ComponentWithPracticeDtoProps> = ({ practice }) => {
  const {
    dispatch: orgContextDispatch,
    state: { organization },
  } = useOrganizationContext();
  const handleUpdateContext = (practice: Practice) => {
    if (organization) {
      const newOrg = {
        ...organization,
        practice: organization?.practice?.map((currentPractice) =>
          currentPractice.id === practice.id ? ({ ...practice } as PracticeDto) : currentPractice
        ),
      };
      orgContextDispatch(setOrganization(newOrg));
    }
  };
  const [upsertPractice] = useUpsertPractice(true, handleUpdateContext);

  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  const initialPracticeUpsert = buildFullPracticeUpsertFromPracticeDto(practice);
  const { indexOfPhysicalAddress, indexOfMailingAddress } = getIndicesOfAddressTypes(initialPracticeUpsert);
  const initiallyHasMailingAddress = hasInitialMailingAddress(initialPracticeUpsert);
  const [hideMailingAddress, setHideMailingAddress] = useState<boolean>(!initiallyHasMailingAddress);

  const initialFormValues = {
    ...initialPracticeUpsert,
    info: getPracticeFormInfoValuesFromPracticeInfoDto(practice.info),
    [checkBoxSameAddressesName]: !initiallyHasMailingAddress,
  };

  const onFinish = async (values: Store) => {
    setIsSaving(true);
    const addressUpsertInfos = getPracticeUpsertInfos(
      indexOfPhysicalAddress,
      indexOfMailingAddress,
      initiallyHasMailingAddress && values[checkBoxSameAddressesName]
    );

    const practiceUpsert = new PracticeUpsertGenerator(initialPracticeUpsert).generateFromUpdatedValuesDto(
      values,
      addressUpsertInfos,
      practice.info
    );

    try {
      await upsertPractice({ variables: { practice: practiceUpsert, organizationId: practice.organization_id } });
      showSuccessMessage(translations.shared.saveSuccessMessage);
      setHasChanged(false);
    } catch (e) {
      showErrorMessage(e.message ? e.message : translations.shared.saveErrorMessage);
    }

    setIsSaving(false);
  };

  const onReset = () => {
    form.resetFields();
    updateHideMailingAddressState(form.getFieldsValue());
    setHasChanged(false);
  };

  const handleValueChange = (_: Store, allValues: Store) => {
    updateHideMailingAddressState(allValues);
    setHasChanged(!isMatch(initialFormValues, allValues));
  };

  const updateHideMailingAddressState = (allValues: Store) => {
    setHideMailingAddress(allValues[checkBoxSameAddressesName]);
  };

  return (
    <SaveSpinnerAndNavigationWarning
      isSaving={isSaving}
      showNavigationWarning={hasChanged}
      warningMessage={translations.shared.getUnsavedDataNavigationWarning(translations.practiceSettings.entity)}
    >
      <PageHeader title={translations.practiceSettings.title} />
      <WidthRestrictor>
        <Form
          labelCol={{ xs: 6, xl: 4 }}
          wrapperCol={{ xs: 6, xl: 8 }}
          form={form}
          initialValues={initialFormValues}
          onFinish={onFinish}
          onValuesChange={handleValueChange}
          className='PracticeSettingForm'
          style={{ ...flexWrapContainer, ...{ padding: '2rem' } }}
          autoComplete='off'
        >
          <RowStyled gutter={[16, 16]}>
            <Col xs={12}>
              <MaxLengthFormItem
                name={['record', 'name']}
                label={translations.practiceSettings.fields.name}
                rules={[{ required: true }]}
                maxLength={50}
              >
                <Input autoComplete='new-password' />
              </MaxLengthFormItem>
              <MaxLengthFormItem
                name={['info', PracticeInfoType.TAX_ID]}
                label={translations.practiceSettings.fields.taxId}
                maxLength={255}
              >
                <Input />
              </MaxLengthFormItem>
              <MaxLengthFormItem
                name={['record', 'email']}
                label={translations.practiceSettings.fields.email}
                rules={[getValidEmailRule()]}
                maxLength={50}
              >
                <Input autoComplete='new-password' />
              </MaxLengthFormItem>
              <MaxLengthFormItem
                name={['record', 'website']}
                label={translations.practiceSettings.fields.website}
                rules={[getValidWebsiteRule()]}
                maxLength={50}
              >
                <Input autoComplete='new-password' />
              </MaxLengthFormItem>
            </Col>
            <Col xs={12}>
              <PracticeLogo practice={practice} />
            </Col>
            <Col xs={12}>
              <PracticeSettingsPhoneTable practice={practice} />
            </Col>
            <Divider />
            <PhysicalAndMailingAddress
              indexOfPhysicalAddress={indexOfPhysicalAddress}
              indexOfMailingAddress={indexOfMailingAddress}
              hideMailingAddress={hideMailingAddress}
              form={form}
            />
            {hasChanged && (
              <Col xs={20} offset={2}>
                <SaveAndResetButton onReset={onReset} offset={0} />
              </Col>
            )}
          </RowStyled>
        </Form>
      </WidthRestrictor>
    </SaveSpinnerAndNavigationWarning>
  );
};

export const PracticeSettings = (props: ComponentWithPracticeDtoProps) => (
  <ViewSubscriptionContextProvider>
    <PhoneTableContextProvider>
      <PracticeSettingsContent {...props} />
    </PhoneTableContextProvider>
  </ViewSubscriptionContextProvider>
);

const RowStyled = styled(Row)`
  width: 100%;
`;
