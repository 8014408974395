import React from 'react';
import { OrganizationState, initialState, organizationReducer } from './reducer';
import { OrganizationAction } from './action';
import { ContextProviderFC } from '../../util/ContextProvider';

export const OrganizationContext = React.createContext<{
  state: OrganizationState;
  dispatch: React.Dispatch<OrganizationAction>;
}>({} as any);

interface OrganizationContextProviderProps {
  state?: Partial<OrganizationState>;
}

export const OrganizationContextProvider: ContextProviderFC<OrganizationContextProviderProps> = (props) => {
  const fullInitialState = {
    ...initialState,
    ...props.state,
  };

  const [state, dispatch] = React.useReducer(organizationReducer, fullInitialState);

  const value = {
    state,
    dispatch,
  };

  return <OrganizationContext.Provider value={value}>{props.children}</OrganizationContext.Provider>;
};

export const useOrganizationContext = () => {
  const organizationContext = React.useContext(OrganizationContext);

  if (!organizationContext) {
    throw new Error('You need to use this hook inside a context provider');
  }

  return organizationContext;
};
