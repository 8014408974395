import { Card } from 'antd';
import styled from 'styled-components';

export const AddressLabel = styled.h4`
  margin: 12px 0;
`;

export const AddressPostal = styled.span`
  margin-bottom: 12px;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CardStyles = styled(Card)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 380px;
  margin: 10px;
  height: 100%;
`;
