import gql from 'graphql-tag';
import { AddressFields, InfoTextFields } from '../fragments';
import {
  BasicContactFields,
  ContactCommunicationLogFields,
  ContactCreditCardFields,
  ContactFields,
  ContactFieldsForListView,
  OfflineContactFields,
} from '../fragments/contactFragments';

export const GetContacts = gql`
  query getContacts($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $queryString: ElasticQueryString) {
    getContactsEs(organizationId: $organizationId, take: $take, sort: $sort, queryString: $queryString) {
      ...ContactFieldsForListView
    }
  }
  ${ContactFieldsForListView}
`;

export const GetContactsForOffline = gql`
  query getContacts($organizationId: ID!, $take: Int!, $sort: ElasticQuerySort!, $searchAfter: [ID!]!) {
    getContactsEs(
      organizationId: $organizationId
      take: $take
      sort: $sort
      searchAfter: $searchAfter
      returnHidden: true
    ) {
      ...OfflineContactFields
    }
  }
  ${OfflineContactFields}
`;

export const GetContact = gql`
  query getContact($organizationId: ID!, $contactId: ID!) {
    getContactEs(organizationId: $organizationId, contactId: $contactId) {
      ...ContactFields
    }
  }
  ${ContactFields}
`;

export const UpsertContact = gql`
  mutation upsertContact($organizationId: ID!, $contact: ContactUpsert!) {
    upsertContact(organizationId: $organizationId, contact: $contact) {
      ...ContactFields
    }
  }
  ${ContactFields}
`;

export const GetContactCreditCards = gql`
  query getContact($organizationId: ID!, $contactId: ID!) {
    getContactEs(organizationId: $organizationId, contactId: $contactId) {
      id
      ...ContactCreditCardFields
    }
  }
  ${ContactCreditCardFields}
`;

export const GetContactAddresses = gql`
  query getContact($organizationId: ID!, $contactId: ID!) {
    getContactEs(organizationId: $organizationId, contactId: $contactId) {
      ...BasicContactFields
      address {
        ...AddressFields
      }
    }
  }
  ${BasicContactFields}
  ${AddressFields}
`;

export const GetContactText = gql`
  query getContactText($organizationId: ID!, $id: ID!) {
    getContactText(organizationId: $organizationId, id: $id) {
      ...InfoTextFields
    }
  }
  ${InfoTextFields}
`;

export const GetContactTexts = gql`
  query getContactTexts($organizationId: ID!, $filter: HistorySimpleFilter) {
    getContactTexts(organizationId: $organizationId, filter: $filter) {
      ...InfoTextFields
    }
  }
  ${InfoTextFields}
`;

export const GetContactCommunicationLog = gql`
  query getContactCommunicationLog($organizationId: ID!, $contactId: ID!) {
    getContactCommunicationLog(organizationId: $organizationId, contactId: $contactId) {
      ...ContactCommunicationLogFields
    }
  }
  ${ContactCommunicationLogFields}
`;
