import { organizationQueryBuilder } from './queries/organizationQueries';
import { onPatientUpdate, patientPushBuilder, patientQueryBuilder } from './queries/patientQueries';
import { contactPushBuilder, contactQueryBuilder, onContactUpdate } from './queries/contactQueries';
import { notePushBuilder, noteQueryBuilder, onNoteUpdate } from './queries/noteQueries';
import {
  serviceActivityQueryBuilder,
  serviceQueryBuilder,
  serviceRenderedQueryBuilder,
} from './queries/serviceQueries';
import { fileQueryBuilder } from './queries/fileQueries';
import { ReplicationCollection } from './collections';
import { ledgerQueryBuilder } from './queries/ledgerQueries';
import { onReminderUpdate, reminderPushBuilder, reminderQueryBuilder } from './queries/reminderQueries';
import { invoicePushBuilder, invoiceQueryBuilder, onInvoiceUpdate } from './queries/invoiceQueries';
import { contactTextQueryBuilder } from './queries/contactTextQueries';
import { isMobileDevice } from '../../util/deviceUtil';
import {
  onPrescriptionUpdate,
  prescriptionFlattenedQueryBuilder,
  prescriptionPushBuilder,
  prescriptionQueryBuilder,
} from './queries/prescriptionQueries';
import { serviceTextQueryBuilder } from './queries/serviceTextQueries';
import { upsertPushBuilder } from './queries/upsertQueries';
import { RxGraphQLReplicationQueryBuilder } from 'rxdb/dist/types/types';
import { addOfflineFileProperties, addOfflineServiceActivityProperties } from '../../util/offline/offlineQueryUtils';

export type ReplicationType = {
  pullQuery?: RxGraphQLReplicationQueryBuilder;
  pushQuery?: RxGraphQLReplicationQueryBuilder;
  onUpdate?: (doc: any) => Promise<any> | any;
};

type OrganizationReplicationType = {
  pullQuery: RxGraphQLReplicationQueryBuilder;
};

export const organizationReplication: OrganizationReplicationType = {
  pullQuery: organizationQueryBuilder,
};

export const getReplications = (organizationId = ''): Partial<Record<ReplicationCollection, ReplicationType>> => ({
  patient: {
    pullQuery: patientQueryBuilder(organizationId),
    pushQuery: patientPushBuilder(organizationId),
    onUpdate: onPatientUpdate,
  },
  contact: {
    pullQuery: contactQueryBuilder(organizationId),
    pushQuery: contactPushBuilder(organizationId),
    onUpdate: onContactUpdate,
  },
  note: {
    pullQuery: !isMobileDevice ? noteQueryBuilder(organizationId) : undefined,
    pushQuery: notePushBuilder(organizationId),
    onUpdate: onNoteUpdate,
  },
  service_rendered: {
    pullQuery: !isMobileDevice ? serviceRenderedQueryBuilder(organizationId) : undefined,
  },
  service_activity: {
    pullQuery: !isMobileDevice ? serviceActivityQueryBuilder(organizationId) : undefined,
    onUpdate: addOfflineServiceActivityProperties,
  },
  file: {
    pullQuery: !isMobileDevice ? fileQueryBuilder(organizationId) : undefined,
    onUpdate: addOfflineFileProperties,
  },
  ledger: {
    pullQuery: ledgerQueryBuilder(organizationId),
  },
  reminder: {
    pullQuery: reminderQueryBuilder(organizationId),
    pushQuery: reminderPushBuilder(organizationId),
    onUpdate: onReminderUpdate,
  },
  invoice: {
    pushQuery: invoicePushBuilder(organizationId),
    pullQuery: !isMobileDevice ? invoiceQueryBuilder(organizationId) : undefined,
    onUpdate: onInvoiceUpdate,
  },
  contact_text: {
    pullQuery: contactTextQueryBuilder(organizationId),
  },
  service: {
    pullQuery: serviceQueryBuilder(organizationId),
  },
  service_text: {
    pullQuery: serviceTextQueryBuilder(organizationId),
  },
  prescription: {
    pullQuery: prescriptionQueryBuilder(organizationId),
    pushQuery: prescriptionPushBuilder(organizationId),
    onUpdate: onPrescriptionUpdate,
  },
  upsert: {
    pushQuery: upsertPushBuilder(organizationId),
  },
  prescription_flattened: {
    pullQuery: prescriptionFlattenedQueryBuilder(organizationId),
  },
});
