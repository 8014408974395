import React from 'react';
import { Tabs } from 'antd';
import { flexWrapContainer } from '../../globalStyles.style';

const { TabPane } = Tabs;

interface TabWrapperTabContent {
  title: string;
  content: JSX.Element;
}

export interface TabWrapperProps {
  tabContents: TabWrapperTabContent[];
  activeKey?: string;
  onTabClick?: (activeKey: string) => void;
  extra?: React.ReactNode;
}

const TabWrapper: React.FC<TabWrapperProps> = ({ tabContents, ...props }) => {
  return (
    <>
      <Tabs tabBarExtraContent={props.extra} defaultActiveKey={'0'} type={'card'} {...props}>
        {tabContents.map((tabContent, index) => (
          <TabPane tab={tabContent.title} key={`${index}`} style={{ width: '100%' }}>
            <div style={{ ...flexWrapContainer, ...{ padding: '2rem' } }}>{tabContent.content}</div>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default TabWrapper;
