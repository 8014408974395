import { Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useMemo, useState } from 'react';
import { FileUpload } from '../../components/FileUpload/FileUpload';
import { MaxLengthFormItem } from '../../components/MaxLengthFormItem/MaxLengthFormItem';
import { showSuccessMessage } from '../../components/Notification/notificationUtil';
import { SaveAndResetButton } from '../../components/SaveAndResetButton/SaveAndResetButton';
import { SaveSpinner } from '../../components/SaveSpinner/SaveSpinner';
import { translations } from '../../constants/translations';
import { DataConversion, DataConversionAttachmentUpsert } from '../../graph/types';
import {
  useGetDataConversionFileTypes,
  useRequestDataConversion,
} from '../../hooks/ajax/dataConversion/dataConversionHooks';
import { useGetOrganizationIdFromRoute } from '../../hooks/route/routeParameterHooks';
import { useFormChanges } from '../../hooks/useFormChanges';
import { FileData } from '../../util/fileUpload';
import { getRequiredRule, getValidEmailRule } from '../../util/forms';
import { Fields, formLayout, offset } from './dataConversionUtils';

const maxFileSize = 5000;

interface FormFields {
  [Fields.Name]: string;
  [Fields.Email]: string;
  [Fields.SoftwareID]: string;
  [Fields.SoftwareName]: string;
  [Fields.VersionNumber]: string;
  [Fields.Attachments]: string;
  [Fields.SpecialInstructions]: string;
  [Fields.GeneralNotes]: string;
}

export enum FileTypeKey {
  Database = 'DATABASE',
  AR_Report = 'AR_REPORT',
  Attachment = 'ATTACHMENT',
}

interface DataConversionFormProps {
  dataConversion: DataConversion;
}

export const DataConversionForm: React.FC<DataConversionFormProps> = ({ dataConversion }) => {
  const organizationId = useGetOrganizationIdFromRoute();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<DataConversionAttachmentUpsert[]>([]);
  const [form] = Form.useForm();
  const [requestDataConversion] = useRequestDataConversion();
  const { fileTypes } = useGetDataConversionFileTypes();
  const FileType = useMemo(
    () => ({
      Database: (fileTypes ?? []).find((type) => type.name_key === FileTypeKey.Database),
      AR_Report: (fileTypes ?? []).find((type) => type.name_key === FileTypeKey.AR_Report),
      Attachment: (fileTypes ?? []).find((type) => type.name_key === FileTypeKey.Attachment),
    }),
    [fileTypes]
  );

  const filePath = useMemo(() => `conversion/${organizationId}/`, [organizationId]);

  const initialData = { ...dataConversion };
  const { hasDataChanged, handleOnReset, setHasDataChanged, handleOnValuesChange } = useFormChanges(form, initialData);

  const handleFinish = async (values: FormFields) => {
    setIsSaving(true);

    const record = {
      ...values,
      id: dataConversion.id,
      software_name: dataConversion.software_name,
      software_id: dataConversion.software_id,
      organization_id: organizationId,
      [Fields.Attachments]: attachments,
    };

    const response = await requestDataConversion({
      variables: {
        organizationId,
        record,
      },
    });

    setIsSaving(false);

    if (!response) {
      return;
    }

    handleReset();

    showSuccessMessage(translations.dataConversion.successMessage);
  };

  const handleReset = () => {
    handleOnReset();
    setAttachments([]);
  };

  const handleFileRemove = (filePointer: string) => {
    setAttachments(attachments.filter((attachment) => attachment.pointer !== filePointer));
  };

  const handleFileUpload = (fileTypeId: string) => (fileData: FileData) => {
    setAttachments([
      ...attachments,
      {
        name: fileData.name,
        pointer: fileData.pointer,
        size: fileData.size,
        file_type_id: +fileTypeId,
      },
    ]);
    setHasDataChanged(true);
  };

  return (
    <SaveSpinner isSaving={isSaving}>
      <Form
        {...formLayout}
        initialValues={initialData}
        onFinish={handleFinish}
        form={form}
        onValuesChange={handleOnValuesChange}
        autoComplete='off'
      >
        <MaxLengthFormItem
          label={translations.dataConversion.fields.name}
          name={Fields.Name}
          rules={[getRequiredRule(translations.dataConversion.fields.name)]}
          maxLength={100}
          tooltip={translations.dataConversion.fields.nameOfContactAtPracticeTooltip}
        >
          <Input />
        </MaxLengthFormItem>

        <MaxLengthFormItem
          label={translations.dataConversion.fields.email}
          name={Fields.Email}
          rules={[getRequiredRule(translations.dataConversion.fields.email), getValidEmailRule()]}
          maxLength={100}
          tooltip={translations.dataConversion.fields.contactEmailTooltip}
        >
          <Input />
        </MaxLengthFormItem>

        <Form.Item label={translations.dataConversion.fields.nameOfPreviousSoftware}>
          {dataConversion.software_name}
        </Form.Item>

        <MaxLengthFormItem
          maxLength={50}
          label={translations.dataConversion.fields.versionNumber}
          name={Fields.VersionNumber}
        >
          <Input />
        </MaxLengthFormItem>

        <Form.Item label={translations.dataConversion.fields.database} name={'database'}>
          <FileUpload
            organizationId={organizationId}
            title={translations.dataConversion.uploadButtonText}
            onRemove={handleFileRemove}
            onUpload={handleFileUpload(FileType.Database?.id ?? '0')}
            accept={FileType.Database?.type ?? ''}
            multiple={FileType.Database?.multiple}
            filePath={filePath}
            maxFileSize={maxFileSize}
            showProgress
          />
        </Form.Item>

        <Form.Item label={translations.dataConversion.fields.ARReport} name={'ar-report'}>
          <FileUpload
            organizationId={organizationId}
            title={translations.dataConversion.uploadButtonText}
            onRemove={handleFileRemove}
            onUpload={handleFileUpload(FileType.AR_Report?.id ?? '0')}
            accept={FileType.AR_Report?.type ?? ''}
            multiple={FileType.AR_Report?.multiple}
            filePath={filePath}
            maxFileSize={maxFileSize}
            showProgress
          />
        </Form.Item>

        <Form.Item label={translations.dataConversion.fields.attachedFiles} name={Fields.Attachments}>
          <FileUpload
            organizationId={organizationId}
            onRemove={handleFileRemove}
            onUpload={handleFileUpload(FileType.Attachment?.id ?? '0')}
            filePath={filePath}
            maxFileSize={maxFileSize}
            accept={FileType.Attachment?.type ?? ''}
            multiple={FileType.Attachment?.multiple}
            showProgress
          />
        </Form.Item>

        <MaxLengthFormItem
          maxLength={2000}
          label={translations.dataConversion.fields.specialInstructions}
          name={Fields.SpecialInstructions}
        >
          <TextArea />
        </MaxLengthFormItem>

        <MaxLengthFormItem
          maxLength={2000}
          label={translations.dataConversion.fields.generalNotes}
          name={Fields.GeneralNotes}
        >
          <TextArea />
        </MaxLengthFormItem>

        {hasDataChanged && (
          <Row>
            <Col span={24}>
              <SaveAndResetButton onReset={handleReset} offset={offset} />
            </Col>
          </Row>
        )}
      </Form>
    </SaveSpinner>
  );
};
