import React from 'react';

import { RecordItem } from '../recordUtils';

import { translations } from '../../../constants/translations';
import { FlexDivContainer, MetadataSpan } from '../../../globalStyles.style';

export const ListItemFooter: React.FC<{
  item?: RecordItem;
}> = ({ item }) => {
  return (
    <>
      {item?.originalObject.__typename === 'Note' || item?.originalObject.__typename === 'File' ? (
        <FlexDivContainer>
          <MetadataSpan>
            <span>{translations.recordList.created_by}</span>
            <span>{item.originalObject.created_user_name}</span>
          </MetadataSpan>
          <MetadataSpan>
            <span>{translations.recordList.updated_by}</span>
            <span>{item.originalObject.updated_user_name}</span>
          </MetadataSpan>
        </FlexDivContainer>
      ) : null}
    </>
  );
};
