import React, { useEffect } from 'react';
import { initialState, viewSubscriptionReducer, ViewSubscriptionState } from './reducer';
import ViewSubscriptionActions, { ViewSubscriptionActionsTypes } from './actions';
import { ContextProviderFC, createContextFor } from '../../../util/ContextProvider';

export const ViewSubscriptionContext = createContextFor<ViewSubscriptionState, ViewSubscriptionActionsTypes>();

export const ViewSubscriptionContextProvider: ContextProviderFC<React.PropsWithChildren> = (props) => {
  const [state, dispatch] = React.useReducer(viewSubscriptionReducer, initialState);
  const value = {
    state,
    dispatch,
  };
  useEffect(() => {
    return () => {
      dispatch(ViewSubscriptionActions.setIsActive(false));
    };
  }, []);

  return <ViewSubscriptionContext.Provider value={value}>{props.children}</ViewSubscriptionContext.Provider>;
};
