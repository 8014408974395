import { Button, Form, Input, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import React, { Fragment, useState } from 'react';
import { MaxLengthFormItem } from '../../../components/MaxLengthFormItem/MaxLengthFormItem';
import ModalWithCloseConfirm from '../../../components/ModalWithCloseConfirm/ModalWithCloseConfirm';
import {
  PatientFieldsRequirement,
  PatientModalContent,
} from '../../../components/PatientModalContent/PatientModalContent';
import { translations } from '../../../constants/translations';
import { TitleDiv } from '../../../globalStyles.style';
import { OrganizationDto, Patient, PatientReferenceData, Service, ServiceRendered } from '../../../graph/types';
import { DeceasedDatePickerFormItem } from '../../Patients/ViewPatient/PatientDetails/DeceasedDatePickerFormItem/DeceasedDatePickerFormItem';
import { PatientsByServiceRenderedId } from '../RapidBilling';
import { HisaFieldsRequirement } from '../../../components/ServiceRendered/HisaRequirementsModal/HisaRequirementsContent';
import { PatientInfoType } from '../../../constants/referenceData/patientReferenceData';

export interface PatientOptionsModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: (value: Store) => Promise<void>;
  patients?: Patient[];
  progress?: number;
  patientFieldsRequired?: Record<string, PatientFieldsRequirement>;
  organization?: OrganizationDto;
  patientsByServiceRenderedIds?: PatientsByServiceRenderedId;
  services?: Service[];
  servicesRendered?: ServiceRendered[];
  hisaFieldsRequired?: Record<string, Record<string, HisaFieldsRequirement>>;
}

export const PatientOptionsModal: React.FC<PatientOptionsModalProps> = ({
  visible,
  onClose,
  onSave,
  patients,
  progress,
  patientFieldsRequired,
  organization,
  patientsByServiceRenderedIds,
  services,
  servicesRendered,
  hisaFieldsRequired,
}) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const handleOnCancel = () => {
    if (saving) {
      return;
    }
    onClose();
  };

  const handleSave = async (values: Store) => {
    setSaving(true);
    await onSave(values);
    setSaving(false);
    onClose();
  };

  const findServicesForPatient = (patientId: string, data: PatientsByServiceRenderedId | undefined) => {
    if (data === undefined) {
      return [];
    }
    const services = Object.keys(data).filter((serviceId) =>
      data[serviceId].some((patient) => patient.id === patientId)
    );
    return services;
  };

  const getModalContentForRapidBilling = () => {
    const content: JSX.Element[] = [];
    patients?.forEach((patient) => {
      const filteredServicesRendered = servicesRendered?.filter((s) =>
        findServicesForPatient(patient.id, patientsByServiceRenderedIds).includes(s.id)
      );

      const patientServices = services?.filter((s) => filteredServicesRendered?.some((sr) => sr.service_id === s.id));

      const PatientDeceasedService = patientServices?.some((x) => x.decease_prompt);
      const patientMicrochipService = patientServices?.some((x) => x.microchip);
      const patientRabiesTagService = patientServices?.some((x) => x.rabies_tag_prompt);

      const deceasedDateVisible = PatientDeceasedService && !patient?.deceased;
      const patientHasRequiredFields = Object.keys(patientFieldsRequired ?? {}).includes(patient.id);
      const patientHasRequiredHisaFields = Object.keys(hisaFieldsRequired ?? {}).includes(patient.id);

      if (
        patientMicrochipService ||
        patientRabiesTagService ||
        patientHasRequiredFields ||
        deceasedDateVisible ||
        patientHasRequiredHisaFields
      ) {
        content.push(
          <TitleDiv bold key={patient.id}>
            {patient.name}
          </TitleDiv>
        );
      }
      if (patientMicrochipService) {
        content.push(
          <Fragment key={`${patient.id}_microchip`}>
            <MaxLengthFormItem
              label={translations.shared.microchipModal.fields.microchip}
              name={`${patient.id}_microchip`}
              maxLength={255}
            >
              <Input
                defaultValue={patient.info?.find((i) => i?.name_key === PatientInfoType.Microchip)?.value || undefined}
              />
            </MaxLengthFormItem>
          </Fragment>
        );
      }
      if (patientRabiesTagService) {
        content.push(
          <Fragment key={`${patient.id}_rabiesTag`}>
            <MaxLengthFormItem
              label={translations.shared.rabiesTagModal.fields.rabiesTag}
              name={`${patient.id}_rabiesTag`}
              maxLength={255}
            >
              <Input
                defaultValue={patient.info?.find((i) => i.name_key === PatientInfoType.RabiesTag)?.value || undefined}
              />
            </MaxLengthFormItem>
          </Fragment>
        );
      }
      if (patientHasRequiredHisaFields) {
        services?.forEach((service) => {
          if (hisaFieldsRequired?.[patient.id]?.[service.id]) {
            content.push(
              <Fragment key={`${patient.id}_patientFields_${service.id}`}>
                <TitleDiv key={service.id}>{service.name}</TitleDiv>
                <PatientModalContent
                  missingFields={patientFieldsRequired?.[patient.id]}
                  patientRef={organization?.ref_patient as any as PatientReferenceData}
                  speciesId={patient.species_id ?? ''}
                  patientId={patient.id}
                  form={form}
                  requiredHisaFields={hisaFieldsRequired?.[patient.id][service.id]}
                  service={service}
                  isForRapidBilling
                />
              </Fragment>
            );
          }
        });
      }
      if (deceasedDateVisible) {
        const getDateOfBirth = () => {
          return patient.dob ? dayjs(patient.dob) : undefined;
        };

        content.push(
          <Fragment key={`${patient.id}_deceasedDate`}>
            <DeceasedDatePickerFormItem
              getDateOfBirth={getDateOfBirth}
              deceaseDateFieldName={`${patient.id}_deceasedDate`}
            />
          </Fragment>
        );
      }
    });
    return content;
  };

  return (
    <ModalWithCloseConfirm
      title={translations.patientOptionsModal.title}
      open={visible}
      onCancel={handleOnCancel}
      isSaving={saving}
      progress={progress !== undefined ? Math.round(progress ?? 0) : undefined}
      footer={null}
    >
      <Form form={form} labelCol={{ span: 9 }} onFinish={handleSave} autoComplete='off'>
        {getModalContentForRapidBilling()}
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type={'primary'} htmlType='submit' loading={saving}>
            {translations.patientOptionsModal.confirm}
          </Button>
        </Row>
      </Form>
    </ModalWithCloseConfirm>
  );
};
