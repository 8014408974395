import { DocumentNode } from '@apollo/client';
import {
  ReportData,
  ActiveReportViewerProps,
  ReportDataQueryParam,
  ReportQueryKeys,
} from '../components/ActiveReportViewer/activeReportViewerUtils';
import {
  Query,
  QueryRptGetArArgs,
  QueryRptGetCommunicationLogArgs,
  QueryRptGetContactPaymentArgs,
  QueryRptGetControlledDrugLogArgs,
  QueryRptGetDailyInvoiceActivityArgs,
  QueryRptGetGeneralLedgerArgs,
  QueryRptGetInvoiceActivityArgs,
  QueryRptGetInvoiceActivityByTrainerArgs,
  QueryRptGetLedgerEntryArgs,
  QueryRptGetNewContactsAndPatientsArgs,
  QueryRptGetPatientProcedureArgs,
  QueryRptGetPatientProcedureByContactArgs,
  QueryRptGetPaymentsArgs,
  QueryRptGetProductionArgs,
  QueryRptGetRemindersByContactArgs,
  QueryRptGetSubscriptionPaymentsArgs,
  QueryRptGetSubscriptionsArgs,
  QueryRptGetTaxArgs,
  QueryRptGetTopSalesArgs,
  QueryRptGetVaccineActivityArgs,
  QueryRptGetVaccineByContactArgs,
  RptPracticeHeaderInterface,
} from '../graph/types';
import AppSyncService from '../services/AppSyncService/AppSyncService';
import { fetchS3DownloadUrl, blobToBase64 } from './fileUtils';
import {
  GetARReport,
  GetCommunicationLogReport,
  GetContactPaymentARReport,
  GetControlledDrugLogReport,
  GetDailyInvoiceActivityReport,
  GetGeneralLedgerReport,
  GetInvoiceActivityByTrainerReport,
  GetInvoiceActivityReport,
  GetLedgerEntryReport,
  GetNewContactsAndPatientsReport,
  GetPatientProcedureByContactReport,
  GetPatientProcedureReport,
  GetPaymentsReport,
  GetProductionReport,
  GetRemindersByContactReport,
  GetSubscriptionPaymentsReport,
  GetSubscriptionsReport,
  GetTaxReport,
  GetTopSalesReport,
  GetVaccineActivityReport,
  GetVaccineByContactReport,
} from '../graph/queries/reports';
import dayjs from 'dayjs';

export enum Reports {
  Statement = 'Statement',
  PatientVax = 'Patient_VaccineCertificate',
  Invoice = 'Invoice',
  ContactReceipt = 'Contact_Receipt',
  SubscriptionInvoice = 'Subscription_Invoice',
  PatientHistory = 'Patient_History',
  ReminderLetter = 'Reminder_Letter',
}

export const getReport = async (reportName: string) => {
  const report = await import(`../reports/${reportName}.json`);
  return report.default;
};

export const getFileNameForReport = (name: string, dateFormat: string) =>
  `${name.trim()}_${dayjs().format(dateFormat)}.pdf`;

export const genericReportsFunctionMap = new Map<
  ReportQueryKeys,
  (variables: ReportDataQueryParam) => Promise<ReportData | undefined>
>([
  [
    'rptGetProduction',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetProduction', QueryRptGetProductionArgs>(
        GetProductionReport,
        variables as QueryRptGetProductionArgs
      );
    },
  ],
  [
    'rptGetCommunicationLog',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetCommunicationLog', QueryRptGetCommunicationLogArgs>(
        GetCommunicationLogReport,
        variables as QueryRptGetCommunicationLogArgs
      );
    },
  ],
  [
    'rptGetControlledDrugLog',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetControlledDrugLog', QueryRptGetControlledDrugLogArgs>(
        GetControlledDrugLogReport,
        variables as QueryRptGetControlledDrugLogArgs
      );
    },
  ],
  [
    'rptGetGeneralLedger',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetGeneralLedger', QueryRptGetGeneralLedgerArgs>(
        GetGeneralLedgerReport,
        variables as QueryRptGetGeneralLedgerArgs
      );
    },
  ],
  [
    'rptGetInvoiceActivity',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetInvoiceActivity', QueryRptGetInvoiceActivityArgs>(
        GetInvoiceActivityReport,
        variables as QueryRptGetInvoiceActivityArgs
      );
    },
  ],
  [
    'rptGetLedgerEntry',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetLedgerEntry', QueryRptGetLedgerEntryArgs>(
        GetLedgerEntryReport,
        variables as QueryRptGetLedgerEntryArgs
      );
    },
  ],
  [
    'rptGetPayments',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetPayments', QueryRptGetPaymentsArgs>(
        GetPaymentsReport,
        variables as QueryRptGetPaymentsArgs
      );
    },
  ],
  [
    'rptGetSubscriptions',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetSubscriptions', QueryRptGetSubscriptionsArgs>(
        GetSubscriptionsReport,
        variables as QueryRptGetSubscriptionsArgs
      );
    },
  ],
  [
    'rptGetTax',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetTax', QueryRptGetTaxArgs>(GetTaxReport, variables as QueryRptGetTaxArgs);
    },
  ],
  [
    'rptGetVaccineActivity',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetVaccineActivity', QueryRptGetVaccineActivityArgs>(
        GetVaccineActivityReport,
        variables as QueryRptGetVaccineActivityArgs
      );
    },
  ],
  [
    'rptGetAR',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetAR', QueryRptGetArArgs>(GetARReport, variables as QueryRptGetArArgs);
    },
  ],
  [
    'rptGetContactPayment',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetContactPayment', QueryRptGetContactPaymentArgs>(
        GetContactPaymentARReport,
        variables as QueryRptGetContactPaymentArgs
      );
    },
  ],
  [
    'rptGetSubscriptionPayments',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetSubscriptionPayments', QueryRptGetSubscriptionPaymentsArgs>(
        GetSubscriptionPaymentsReport,
        variables as QueryRptGetSubscriptionPaymentsArgs
      );
    },
  ],
  [
    'rptGetRemindersByContact',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetRemindersByContact', QueryRptGetRemindersByContactArgs>(
        GetRemindersByContactReport,
        variables as QueryRptGetRemindersByContactArgs
      );
    },
  ],
  [
    'rptGetPatientProcedureByContact',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetPatientProcedureByContact', QueryRptGetPatientProcedureByContactArgs>(
        GetPatientProcedureByContactReport,
        variables as QueryRptGetPatientProcedureByContactArgs
      );
    },
  ],
  [
    'rptGetPatientProcedure',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetPatientProcedure', QueryRptGetPatientProcedureArgs>(
        GetPatientProcedureReport,
        variables as QueryRptGetPatientProcedureArgs
      );
    },
  ],
  [
    'rptGetNewContactsAndPatients',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetNewContactsAndPatients', QueryRptGetNewContactsAndPatientsArgs>(
        GetNewContactsAndPatientsReport,
        variables as QueryRptGetNewContactsAndPatientsArgs
      );
    },
  ],
  [
    'rptGetDailyInvoiceActivity',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetDailyInvoiceActivity', QueryRptGetDailyInvoiceActivityArgs>(
        GetDailyInvoiceActivityReport,
        variables as QueryRptGetDailyInvoiceActivityArgs
      );
    },
  ],
  [
    'rptGetInvoiceActivityByTrainer',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetInvoiceActivityByTrainer', QueryRptGetInvoiceActivityByTrainerArgs>(
        GetInvoiceActivityByTrainerReport,
        variables as QueryRptGetInvoiceActivityByTrainerArgs
      );
    },
  ],
  [
    'rptGetVaccineByContact',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetVaccineByContact', QueryRptGetVaccineByContactArgs>(
        GetVaccineByContactReport,
        variables as QueryRptGetVaccineByContactArgs
      );
    },
  ],
  [
    'rptGetTopSales',
    (variables: ReportDataQueryParam) => {
      return getReportData<'rptGetTopSales', QueryRptGetTopSalesArgs>(
        GetTopSalesReport,
        variables as QueryRptGetTopSalesArgs
      );
    },
  ],
  // Add an entry here for a new created report running in ActiveReports
]);

export const getReportDataRetriever = (runnerFunction: ReportQueryKeys) => {
  return genericReportsFunctionMap.get(runnerFunction);
};

export const getReportData = async <T extends ReportQueryKeys, TVariables extends ReportDataQueryParam>(
  query: DocumentNode,
  variables: TVariables
) => {
  const { data } = await AppSyncService.client.query<Pick<Query, T>, TVariables>({
    query,
    variables,
    fetchPolicy: 'no-cache',
  });
  return data as ReportData;
};

export const getReportWithData = async ({ reportName, data }: ActiveReportViewerProps) => {
  const reportJSON = await getReport(reportName);
  const dataWithLogo = await getReportDataWithLogo(data);
  reportJSON.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify({ data: dataWithLogo });
  return reportJSON;
};

export const getReportDataWithLogo = async (data: Record<string, any>) => {
  const reportValues = Object.values(data)[0];
  const nestedHeaderKey = 'report_header';
  const dataKey = Object.keys(data)[0];
  const nestedHeader = Object.keys(data[dataKey]).includes(nestedHeaderKey);
  const practiceLogo = nestedHeader ? data[dataKey][nestedHeaderKey]?.practice_logo : data[dataKey].practice_logo;
  const reportHeader: RptPracticeHeaderInterface = nestedHeader ? reportValues.report_header : reportValues;

  if (!practiceLogo) {
    return data;
  }

  const fileUrl = await fetchS3DownloadUrl({
    organizationId: reportHeader.organization_id,
    fileName: reportHeader.practice_name,
    filePointer: practiceLogo,
  });

  const response = await fetch(fileUrl?.url ?? '');
  const responseImageBlob = await response.blob();
  const imageBlob = await blobToBase64(responseImageBlob);

  if (!imageBlob) {
    return data;
  }

  if (nestedHeader) {
    data[dataKey][nestedHeaderKey].practice_logo = imageBlob;
  } else {
    data[dataKey].practice_logo = imageBlob;
  }

  return data;
};
