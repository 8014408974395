import { Patient } from '../../../../graph/types';

export type InvoicesAction = ShowSelectPatientModal | ShowAddInvoiceModal | SelectPatient | CloseModals;

export const showSelectPatientModalType = 'INVOICES__SHOW_SELECT_PATIENT_MODAL';
export const showAddInvoiceModalType = 'INVOICES__SHOW_ADD_INVOICE_MODAL';
export const selectPatientType = 'INVOICES__SELECT_PATIENT';
export const closeModalsType = 'INVOICES__CLOSE_MODALS';

export interface ShowSelectPatientModal {
  type: typeof showSelectPatientModalType;
}

export interface ShowAddInvoiceModal {
  type: typeof showAddInvoiceModalType;
}

export interface SelectPatient {
  type: typeof selectPatientType;
  selectedPatient: Patient;
}

export interface CloseModals {
  type: typeof closeModalsType;
}

export const showSelectPatientModalAction = (): ShowSelectPatientModal => ({
  type: showSelectPatientModalType,
});

export const showAddInvoiceModalAction = (): ShowAddInvoiceModal => ({
  type: showAddInvoiceModalType,
});

export const selectPatientAction = (patient: Patient): SelectPatient => ({
  type: selectPatientType,
  selectedPatient: patient,
});

export const closeModalsAction = (): CloseModals => ({
  type: closeModalsType,
});
