import React, { useState, useMemo } from 'react';
import { useGetRemindersBatch } from '../../../hooks/ajax/reminders/reminderHooks';
import {
  CustomColumnType,
  TableWithCustomFiltering,
} from '../../../components/TableWithCustomFiltering/TableWithCustomFiltering';
import { ReminderBatchDetail } from '../../../graph/types';
import { useBasicRemindersBatchColumns } from './columns';
import { TableKey } from '../../../hooks/tableHooks';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useNavigationToRoute, withContactIdParameter } from '../../../hooks/route/navigationHooks';
import { TableCellLink } from '../../../components/TableLink/TableCellLink';
import { routes } from '../../../constants/routes';
import { SelectedContact, SEND_TYPES_OPTIONS } from '../types';

interface Props {
  organizationId: string;
  selectedRows: SelectedContact[];
  deleteButton: React.ReactNode;
  onSelect: (selected: SelectedContact[]) => void;
  reminderBatchId?: string;
}

const RemindersBatchTable = ({ organizationId, selectedRows, onSelect, reminderBatchId, deleteButton }: Props) => {
  const [dataSource, setDataSource] = useState<ReminderBatchDetail[]>([]);

  const { data, loading } = useGetRemindersBatch(organizationId, reminderBatchId ?? '');
  const { navigateTo } = useNavigationToRoute();
  const basicRemindersBatchColumns = useBasicRemindersBatchColumns();

  const getColumns: () => CustomColumnType<ReminderBatchDetail>[] = () => {
    const allColumns = Array<CustomColumnType<ReminderBatchDetail>>();
    const {
      contact_name,
      patient_name,
      reminder_name,
      reminder_note,
      reminder_status_name,
      reminder_sent_type_name,
      reminder_date,
    } = basicRemindersBatchColumns;
    const owingColumns = [
      contact_name,
      patient_name,
      reminder_name,
      reminder_date,
      reminder_note,
      reminder_status_name,
      reminder_sent_type_name,
    ];

    for (const col of owingColumns) {
      if (col.dataIndex === 'contact_name') {
        allColumns.push({
          ...col,
          width: 200,
          render: (contactName: string, record: ReminderBatchDetail) => (
            <TableCellLink
              onClick={() => {
                navigateTo(routes.viewContact, withContactIdParameter(record.contact_id ?? ''));
              }}
            >
              {contactName}
            </TableCellLink>
          ),
        });
      } else if (col.dataIndex === 'reminder_sent_type_name') {
        allColumns.push({
          ...col,
          filters: SEND_TYPES_OPTIONS,
          onFilter: (value, record) => value === String(record.reminder_sent_type_name),
          width: 200,
        });
      } else if (col.dataIndex === 'reminder_status_name') {
        allColumns.push({
          ...col,
          filters: statuses,
          onFilter: (value, record) => value === String(record.reminder_status_name),
          width: 200,
        });
      } else {
        allColumns.push({
          ...col,
          width: 200,
        });
      }
    }

    return allColumns;
  };

  const handleOnRowCheckedChange: TableRowSelection<ReminderBatchDetail>['onChange'] = (_, rows) => {
    onSelect(rows.map((row) => ({ id: row.id, reminderId: row.reminder_id, preference: row.reminder_sent_type_name })));
  };

  const handleOnAllRowsSelect: TableRowSelection<ReminderBatchDetail>['onSelectAll'] = (selected) => {
    onSelect(
      selected
        ? dataSource.map((row) => ({
            id: row.id,
            reminderId: row.reminder_id,
            preference: row.reminder_sent_type_name,
          }))
        : []
    );
  };

  const statuses = useMemo(() => {
    return [...new Set(dataSource.map((item) => item.reminder_status_name))]
      .filter((status) => status)
      .map((status) => ({
        text: status as string,
        value: status as string,
      }));
  }, [dataSource]);

  return (
    <>
      {deleteButton}
      <TableWithCustomFiltering
        tableKey={TableKey.RemindersRun}
        columns={getColumns()}
        dataSource={data}
        loading={loading}
        rowKey='id'
        rowSelection={{
          type: 'checkbox',
          onChange: handleOnRowCheckedChange,
          selectedRowKeys: selectedRows.map((row) => row.id),
          onSelectAll: handleOnAllRowsSelect,
        }}
        setCurrentDataSource={setDataSource}
      />
    </>
  );
};

export default RemindersBatchTable;
